
import {InputTextarea} from "primereact/inputtextarea";
import React from "react";

export const BreakLineElement = ({item,index}) => (
    <>
        <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
            <hr className={" harmony-h-1 harmony-hr "}
                readOnly={item.properties.isReadOnly}
                disabled={item.properties.isReadOnly}
            />
        </div>

    </>
);