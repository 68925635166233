import React, {useMemo, useState} from "react";

import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {TabMenu} from "../../../../Commans/UIParts/TabMenu";
import {useTab} from "./useTab";
import {ManagerPrivacy} from "./ManagerPrivacy";
import {UserPrivacy} from "./UserPrivacy";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";

export const HandleSecuritySetting = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {handleTabId,tabs,setTabId,tabId}=useTab()


    return (
        <>
            <div className=" space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                            icon={"pi-cog"}
                                            title={t("settings")}
                                            tooltipTitle={t("SettingsPage")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Domain`)}
                                            icon={"pi-globe"}
                                            title={t("Domain")}
                                            tooltipTitle={t("Domain")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Script`)}
                                            icon={"pi-code"}
                                            title={t("Script")}
                                            tooltipTitle={t("AdditionalScript")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/ShortcutKey`)}
                                            icon={"pi-key"}
                                            title={t("Shortcut")}
                                            tooltipTitle={t("ShortcutKey")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/BackUp`)}
                                            icon={"pi-server"}
                                            title={t("support")}
                                            tooltipTitle={t("SupportAndRecovery")}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                <div className="post box bg-white dark:bg-themeDarkSalt700">
                    <TabMenu activeTab={tabId} handleTabClick={handleTabId}  tabs={tabs} />
                    <div className={"post__content tab-content "}>
                        <div className={`tab-content__pane p-2  ${ tabId === "managerPrivacy" ? "active" : "" }`} id="managerPrivacy">
                            {
                                tabId === 'managerPrivacy'?
                                    <ManagerPrivacy/>:null
                            }
                        </div>
                        <div className={`tab-content__pane space-y-5 p-2  ${ tabId === "userPrivacy" ? "active" : ""}`} id="userPrivacy">
                            {
                                tabId === 'userPrivacy'?
                                    <UserPrivacy/>
                                    :null
                            }


                        </div>
                    </div>
                </div>

            </div>



        </>




    );
};