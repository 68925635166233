import React, {useEffect, useState} from "react";
import DatePicker, {DateObject} from "react-multi-date-picker"
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputIcon from "react-multi-date-picker/components/input_icon"
import {useDateTimePicker} from "./useDateTimePicker";

export const DateTimePicker = (props)=>{
    const [value, setValue] = useState(new Date());
    const {locale,setLocale} = useState("");


    const {handleCalendarView,handleCalendarLanguage, handleCalendarType}=useDateTimePicker();

    useEffect(() => {
        if (props.value) {
            setValue(new Date(props.value));
        } else {
            setValue(null);
        }
    }, [props.value]);


    const handleValue = (value) => {
        if (value instanceof DateObject) {
            value = value.toDate();
            props.handleDateTime(value,props.fakeId,props.name,props.realId);
        }
        else if (value === null) {
            setValue(null);
            props.handleDateTime(null,props.fakeId,props.name,props.realId);
        }
    };

    // console.log(value);
    const language = localStorage.getItem('language');

    return (
        <>

            <DatePicker
                calendar={handleCalendarType()}
                locale={handleCalendarLanguage()}
                format={props.format}
                className={'p-component dark:bg-themeDarkPostInput'}
                value={value}
                disabled={false}
                editable={handleCalendarView()}
                buttons={false}
                onChange={handleValue}
                plugins={props.isContainsTime?[<TimePicker position="top"  />]:null}
                zIndex={props.zIndex}
                fixMainPosition={true}

            />
        </>
    )
}