import Joi from "joi";
//this is schema validation of login to be used for yup and reat-hook-form

export const secretarySchema = Joi.object({
    pattern: Joi.string().required().messages({
        'any.required': ' الگو الزامیست',
        'string.empty': ' الگو الزامیست',
        'string.base':  ' الگو الزامیست',
    }),
    message: Joi.string().required().messages({
        'any.required': 'پیام الزامیست',
        'string.empty': 'پیام الزامیست',
        'string.base':  'پیام الزامیست',
    })
});