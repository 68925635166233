import React, {useMemo, useState} from "react";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";

import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";


export const HandleListRoom = () => {


    const [modal, setModal] = useState({ seefeature: false });

    const seefeatureOnHide = () => {
        setModal((prevState) => ({ ...prevState, seefeature: !modal.seefeature }));
    };


    const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

    const columnDefs = [
        { headerName: 'ردیف', field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: 'نام ساختمان',minWidth: 160,
            cellRenderer:parameters=>
                <h2 class="line-clamp-1">
                    هتل اسپیناس پالاس
                </h2>
        },
        { headerName: 'وضعیت', maxWidth: 140,
            cellRenderer:parameters=>
                <h2 className="">
                    فعال و غیرفعال
                </h2>
        },
        { headerName: 'نام/شماره',minWidth: 110,maxWidth: 150,
            cellRenderer:parameters=>
                <h2>
                    2001
                </h2>
        },
        { headerName: 'طبقه',minWidth: 110,maxWidth: 150,
            cellRenderer:parameters=>
                <h2>
                    10
                </h2>
        },
        { headerName: 'تعداد تخت',maxWidth: 110,
            cellRenderer:parameters=>
                <h2>
                    6
                </h2>
        },

        { headerName: 'امکانات',maxWidth:120,
            cellRenderer:parameters=>
              <div>
                  <button type="button" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white text-xs" onClick={() => setModal({ seefeature: true })}>
                      <HPrimeIcon icon={'eye'} />
                      مشاهده
                  </button>
              </div>
        },
        { headerName: 'مبلغ',minWidth:120,
            cellRenderer:parameters=>
            <h2>2.000.000.000 تومان</h2>
        },


        { headerName: 'تنظیمات', maxWidth: 120,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-2 '} >

                    <button className="text-themeSuccess" ><HPrimeIcon  icon={'edit-3'} /></button>
                    <button className="text-themeDanger" ><HPrimeIcon  icon={'trash-2'} /></button>
                </div>
            }

        },

    ];

    const rowData = [

        { id:1, date:'1402/10/3'},
        { id:2, date:'1402/10/3'},
        { id:3, date:'1402/10/3'},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };





    return(
      <>
          <div class=" space-y-5">
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">

                      <ListSearch label={null} icon={null} >
                          <div class="space-y-2">
                              <label className="text-xs dark:text-gray-300">نام ساختمان:</label>
                              <MultiDataSelect placeholder={'انتخاب'} />
                          </div>
                          <div class="space-y-2">
                              <label className="text-xs dark:text-gray-300"> نام/شماره اتاق:</label>
                              <InputText type={"text"} className={" input w-full"} />
                          </div>
                          <div class="flex items-end gap-3  ">
                              <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
            <HPrimeIcon icon={"search"}/>
        </span>
                              </div>
                              <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
              <HPrimeIcon icon={"list"}/>
        </span>
                              </div>
                          </div>
                      </ListSearch>
                      <ListFilter label={null} icon={null} divClassName={"grid space-y-2"}>
                          <div className="overflow-x-auto scrollbar">
                              <div className="flex gap-5 p-3 filter_min_w_7">
                                  <div className=" space-y-2 flex flex-col items-center">
                                      <lable className="text-xs dark:text-gray-300">شش ماه به انقضاء</lable>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                      <lable className="text-xs dark:text-gray-300">یک سال به انقضاء</lable>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                      <lable className="text-xs dark:text-gray-300">موجود</lable>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                                  <div class="space-y-2 flex flex-col items-center">
                                      <lable className="text-xs dark:text-gray-300">ناموجود</lable>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                      <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                      <lable className="text-xs dark:text-gray-300">محصولات پرفروش</lable>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                      <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                              </div>
                          </div>
                      </ListFilter>

              </div>
              <div>
                  <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                             enableRtl={true}
                             pagination={true}
                             getRowHeight={getRowHeight}
                             onGridReady={onGridReady}
                             quickFilterText={quickFilterText}
                             style={{ width: '100%', height: '100%' ,position:'relative' }}
                  />
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
                      <div className="sm:col-span-2">
                          <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                              <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Pdf"></span>
                                  <h2>خروجی (Pdf)</h2>
                              </Button>
                              <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Excel"></span>
                                  <h2>خروجی (Exel)</h2>
                              </Button>
                              <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Word"></span>
                                  <h2>خروجی (Word)</h2>

                              </Button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

          <FantasyModal openModal={modal.seefeature}  closeModal={seefeatureOnHide} title={"مشخصات"}>
              <div>
                  <div class="flex gap-2 items-center p-2 rounded-t-md bg-gray-200 dark:bg-themeDarkSalt700 dark:text-gray-300">
                      <div>
                          <h3 className="text-xs dark:text-gray-300">
                              هتل اسپیناس
                          </h3>
                      </div>
                      <div>/</div>
                      <div className="flex text-themeInformation dark:text-themeDarkInformation">
                          اتاق:
                          <h3>
                              b3-10
                          </h3>
                      </div>
                  </div>
                  <div className="p-3">
                      <div class="p-2 text-xs dark:text-gray-300">
                          <h2>مشخصات:</h2>
                      </div>
                      <div class="p-2">
                          <ul class="flex gap-2  flex-wrap">
                              <li className=" p-2 bg-themeInformation rounded-md text-white dark:text-gray-300 text-xs">
                                  <h3>یخچال</h3>
                              </li>
                              <li className=" p-2 bg-themeInformation rounded-md text-white dark:text-gray-300 text-xs">
                                  <h3>تلویزیون</h3>
                              </li>
                              <li className=" p-2  bg-themeInformation rounded-md text-white dark:text-gray-300 text-xs">
                                  <h3>یسیتم سرمایش</h3>
                              </li>
                              <li className=" p-2  bg-themeInformation rounded-md text-white dark:text-gray-300 text-xs">
                                  <h3>تخت اضافی</h3>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div class=" flex justify-center items-center gap-3 p-3">
                      <button type="button" className="button w-24 bg-themeDanger dark:bg-themeSecondary text-white">بستن
                      </button>
                  </div>
              </div>
          </FantasyModal>
      </>

  )

}