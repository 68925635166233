import {AgGridEnglish, AgGridPersion} from "../Translations/Resources";
const language = localStorage.getItem('language');
export const GridSetting = {
    pageSize:10,
    paginationItems:[10,15,20],
    gridStyle:{ width: '100%', height: '100%' ,position:'relative' },
    pagination:false,
    enableRtl:language === "fa-IR"?true:false,
    domLayout:false,
    localeText:language === "fa-IR"?AgGridPersion:AgGridEnglish,
    rowSelection:'multiple',
}

 export const TimeSetting={
    dateFormat:language === "fa-IR"?'jYYYY/jMM/jDD':'YYYY/MM/DD',
    dateTimeFormat:language === "fa-IR"?'jYYYY/jMM/jDD HH:mm:ss':'YYYY/MM/DD HH:mm:ss',
 }
 export const toasts={
     position: "bottom-left",
     notifySuccess:2000,
     notifyError:2000,
     notifyWarning:5000,
     hideProgressBar: false,
     closeOnClick: false,
     pauseOnHover: false,
     draggable: true,
     progress: undefined,
     theme: "colored",
     rtl:true
 }

 export const fileManager={
    permittedFiles:['png', 'jpg', 'svg', 'zip', 'txt','pdf',' xlsx','html','css','js'],
     separator:'+'
 }
 export const AlertSetting={
     TailWindButton: {
         title: language === "fa-IR" ?'اطمینان از حذف دارید ؟':'Are you sure ? ',
         text:  language === "fa-IR"?"امکان برگشت وجود نخواهد داشت":"There is no way back",
         icon: 'error',
         showCancelButton: true,
         confirmButtonText: language === "fa-IR"? 'اره حذفش کن!':"Yes Delete it",
         cancelButtonText: language === "fa-IR"? 'نه انصراف میدم':"No , Cancel it",
         reverseButtons: true
     },
     customClass:{
         confirmButton: 'button text-white bg-themeInformation',
         cancelButton: ' button text-white bg-themeDanger',
         buttonsStyling: false
     }
 }
