import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import queryString from "query-string";

export const useProductTypeAttach=(initialSource)=>{
    const[files,setFiles] = useState([]);
    const { t } = useTranslation();
    const [totalSize,setTotalSize] = useState(0); // State to track total size of files
    const queryParams = queryString.parse(window.location.search);

    const fileTypes = {
        fileTypeIcons: {
            'application/pdf': 'fa-file-pdf', // Font Awesome class for PDF files
            'image/jpeg': 'fa-file-image', // Font Awesome class for JPEG images
            'image/png': 'fa-file-image', // Font Awesome class for PNG images
            'application/msword': 'fa-file-word', // Font Awesome class for Word documents
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word', // Font Awesome class for Word documents
            'text/plain': 'fa-file-alt', // Font Awesome class for text files
            'default': 'fa-file' // Default Font Awesome class for other file types
        }
    };
    const getFileTypeIcon = (fileType) => {
        const { fileTypeIcons } = fileTypes;
        return fileTypeIcons[fileType] || fileTypeIcons['default'];
    };


    // Function to calculate total size of files
    const calculateTotalSize = (files) => {
        let total = 0;
        files.forEach((file) => {
            if (file.file) {
                total += file.file.size;
            }
        });
        return total;
    };
    const handleFileChange = (index, event) => {
        const newFile = event.target.files[0];
        if (newFile) {
            const updatedCards = [...files];
            const newFilePath = URL.createObjectURL(newFile);
            updatedCards[index] = {
                ...updatedCards[index],
                image: newFile,
                imagePath: newFilePath,
                // fileType:newFile.name.split('.').pop().toLowerCase()
                fileType:newFile.type
            };
            setFiles(updatedCards);
            setTotalSize(calculateTotalSize(updatedCards)); // Update total size
        }
    };
    const triggerFileInput = (index) => {
        document.getElementById(`file-input-${index}`).click();
    };

    const handleFieldChange = (index, field, value) => {
        const updatedCards = [...files];
        updatedCards[index][field] = value;
        setFiles(updatedCards);
    };

    const deleteCard = (index,productId) => {
        if(productId === queryParams.productId){
            return;
        }
        if (files.length === 1) {
            handleInitialCards(initialSource.propertyTypes,initialSource.users,initialSource.SendingStates)
            return;
        }
        const updatedCards = files.filter((_, idx) => idx !== index);
        setFiles(updatedCards);
        setTotalSize(calculateTotalSize(updatedCards)); // Update total size
    };

    const addNewCard = () => {
        setFiles([
            ...files,
            {
                productId:null,
                image:null,
                imagePath:null,
                propertyType:initialSource.propertyTypes[0],
                propertyValue:'',
                excerpt:null,
                price:0,
                wholesalePrice:0,
                productCode:'',
                iranCode:'',
                barcode:'',
                producerId:null,
                supplierId:null,
                filterId:null,
                stateShow:true,
                stateGoogle:false,
                stateAndroid:false,
                isAuto:true,
                sendingStates:[initialSource.SendingStates[0]],
            },
        ]);
    };

    const handleInitialCards=(propertyTypes,users,SendingStates)=>{
        setFiles([
            {
                productId:null,
                image:null,
                imagePath:null,
                propertyType:propertyTypes[0],
                propertyValue:'',
                excerpt:null,
                price:0,
                wholesalePrice:0,
                productCode:null,
                iranCode:null,
                barcode:null,
                producerId:null,
                supplierId:null,
                filterId:null,
                stateShow:true,
                stateGoogle:false,
                stateAndroid:false,
                isAuto:true,
                sendingStates:[SendingStates[0]],
            },
        ]);

    }

    const handleGetAllCards=(cards,propertyTypes,users,SendingStates,filters)=>{
        const documentCards = cards.map((item)=>({
            productId:item.productId,
            image:item.image,
            imagePath:item.imagePath,
            propertyType:propertyTypes.find((type)=>type.value === item.value)??null,
            propertyValue:item.propertyValue,
            excerpt:item.excerpt,
            price:item.price.amount,
            wholesalePrice:item.wholesalePrice.amount,
            productCode:item.productCode,
            iranCode:item.iranCode,
            barcode:item.barcode,
            producerId:users.find((type)=>type.value === item.value)??null,
            supplierId:users.find((type)=>type.value === item.value)??null,
            filterId:filters.find((type)=>type.value === item.value)??null,
            stateShow:item.stateShow,
            stateGoogle:item.stateGoogle,
            stateAndroid:item.stateAndroid,
            isAuto:true,
            sendingStates:item.sendingStates.map((value) => SendingStates.find((item) => item.value === value) ?? value) ?? [],
        }))
        return documentCards
    }

    return {
        addNewCard,
        deleteCard,
        handleFieldChange,
        handleFileChange,
        triggerFileInput,
        files,
        setFiles,
        handleInitialCards,
        handleGetAllCards,
        totalSize
    }

}