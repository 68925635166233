import React, {useState} from "react";

import {InputText} from "primereact/inputtext";
import { Calendar } from "react-multi-date-picker";
import Select from "react-select";

import {ListFilter} from "../Commans/Grid/ListFilter";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";


import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../Commans/UIParts/ButtonSubmitAndCancel";

import {SettingButton} from "../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../Commans/Dates/DateTimePicker";

import {InputNumber} from "primereact/inputnumber";
import {PickList} from "primereact/picklist";
import {TabMenuTwo} from "../Commans/UIParts/TabMenuTwo";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";


export const HandleAttendanceWorkGroup = () => {
    const { t } = useTranslation();

    const redirectTo = useNavigate();

    // const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();


    const [dates, setDates] = useState([]);

    const [activeTab, setActiveTab] = useState("tab_one");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
    const tabs = [
        {
            id: "tab_one",
            label: t("WorkGroup"),
            tooltipTitle:t("WorkGroup"),

        },
        {
            id: "tab_two",
            label: t("WeeklyVacation"),
            tooltipTitle:t("WeeklyVacation"),

        },
        {
            id: "tab_three",
            label: t("businessDays"),
            tooltipTitle:t("businessDays"),
        },

        {
            id: "tab_four",
            label: t("DetermineTheLimit"),
            tooltipTitle:t("DetermineTheLimit"),

        },
        {
            id: "tab_five",
            label: t("PersonnelAllocation"),
            tooltipTitle:t("PersonnelAllocation"),

        },

    ];



    return(
        <>
            <div class="space-y-5 ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Personnel")}
                                            onClick={()=>redirectTo(`/Attendance/Personnel`)}
                                            tooltipTitle={t("Personnel")}

                                            icon={"users"}
                                        />

                                        <ButtonPlus
                                            icon={"plus-square"}
                                            title={t("Device")}
                                            onClick={()=>redirectTo("/Attendance/Add")}
                                            tooltipTitle={t("RegisterDevice")}

                                        />

                                        <ButtonPlus
                                            title={t("FinancialRules")}
                                            icon={"book"}
                                            onClick={()=>redirectTo("/Attendance/FinancialRules")}
                                            tooltipTitle={t("FinancialRules")}

                                        />

                                        <ButtonPlus
                                            title={t("Leave")}
                                            primIcon={"pi-calendar-clock"}
                                            onClick={()=>redirectTo("/Attendance/LeaveAndMission")}
                                            tooltipTitle={t("LeaveAndMission")}

                                        />

                                        <ButtonPlus
                                            title={t("Shift")}
                                            icon={"layers"}
                                            onClick={()=>redirectTo("/Attendance/ShiftWork/List")}
                                            tooltipTitle={t("ShiftWork")}

                                        />

                                        <ButtonPlus
                                            title={t("WorkGroup")}
                                            icon={"users"}
                                            onClick={()=>redirectTo("/Attendance/WorkGroup/List")}
                                            tooltipTitle={t("WorkGroup")}

                                        />

                                        <ButtonPlus
                                            title={t("archive")}
                                            icon={"archive"}
                                            onClick={()=>redirectTo("/Attendance/Archive")}
                                            tooltipTitle={t("ArchiveLeaveAndMission")}

                                        />


                                        <ButtonPlus
                                            title={t("Reports")}
                                            primIcon={"pi-chart-bar"}
                                            onClick={()=>redirectTo("/Attendance/List/Reports")}
                                            tooltipTitle={t("GeneralReport")}
                                        />


                                    </>
                                }

                    />

                </div>
                <div>
                    <div class="relative grid grid-cols-2 lg:grid-cols-7 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-3">
                        <TabMenuTwo activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                    </div>
                    <div class="post__content tab-content ">
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2 space-y-5 ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3  post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">عنوان</label>
                                        <InputText className={"input w-full"}/>
                                    </div>
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">کد گروه</label>
                                        <InputText className={"input w-full"}/>
                                    </div>
                                    <div class=" space-y-1">
                                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                            <SettingButton
                                                // onClick={()=>handleSetting('GeneralSetting')}
                                                tooltipTitle={t("Calendarsettings")}
                                            />
                                            {t("تاریخ معیار")}
                                        </label>
                                        <DateTimePicker  format={'YYYY/MM/DD'} />
                                    </div>
                                    <div className={"flex gap-2 items-center"}>
                                        <div className={"space-y-1 flex-1 w-full"}>
                                            <label className="text-xs dark:text-gray-300">{t("حکم مالی")}</label>
                                            <Select
                                                placeholder={t("selection")}
                                                classNamePrefix={"h_multi_select"}
                                                menuPosition={"fixed"}
                                            />

                                        </div>
                                        <div class="space-y-1 w-24 flex-shrink-0">
                                            <label className="text-xs dark:text-gray-300">{t("بازه تکرار")}</label>
                                            <InputNumber showButtons/>
                                        </div>
                                    </div>

                                </div>
                            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-3 "}>
                                <div className={"border post-input p-2 post-input-design dark:bg-themeDarkPostInput space-y-5"}>
                                        <div className={"bg-white box p-2 flex items-center justify-between dark:bg-themeDarkSalt700"}>
                                            <div className={"flex gap-2 items-center"}>
                                                <span className={"dark:text-gray-300"}>روز1</span>
                                                <span className={"text-themeInformation dark:text-themeDarkInformation"}>(شنبه)</span>
                                            </div>

                                        </div>
                                        <div className={"space-y-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("ShiftWork")}</label>
                                            <Select
                                                placeholder={t("selection")}
                                                classNamePrefix={"h_multi_select"}
                                                menuPosition={"fixed"}
                                            />
                                        </div>
                                        <div className={"flex gap-2 items-center flex-wrap"}>
                                            <div className={"flex items-end gap-3 flex-1 w-full"}>
                                                <div>
                                                    <label className={"flex gap-2 items-center cursor-pointer"}>
                                                        <input name={"1"} type={"radio"}></input>
                                                        <p className={"text-xs dark:text-gray-300"}>{t("Presence")}</p>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className={"flex gap-2 items-center cursor-pointer"}>
                                                        <input name={"1"} type={"radio"}></input>
                                                        <p className={"text-xs dark:text-gray-300"}>{t("Closed")}</p>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className={"flex gap-2 items-center cursor-pointer"}>
                                                        <input name={"1"} type={"radio"}></input>
                                                        <p className={"text-xs dark:text-gray-300"}>{t("WorkingHoliday")}</p>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="flex items-end gap-3 justify-end">
                                                    <button  type="button" className="button bg-themeDanger text-white">
                                                        <HPrimeIcon icon={"minus"} />
                                                    </button>
                                                    <button  type="button" className="button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                                        <HPrimeIcon icon={"plus"} />
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2 space-y-5 ${activeTab === "tab_two" ? "active" : ""}`} id="tab_two" >
                            <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                <div className="flex items-center gap-2">
                                    <label className="text-xs dark:text-gray-300">{t("TypeOfVacation")}</label>
                                    <div class="can-toggle demo-rebrand-1 text-xs w-40">
                                        <input id="swich" type="checkbox"/>
                                        <label htmlFor="swich">
                                            <div class="can-toggle__switch" data-checked={t("OfficialCalendar")} data-unchecked={t("Custom")}></div>
                                        </label>
                                    </div>
                                </div>
                                {/*درصورت انتخاب کشور نمایش داده شود*/}
                                <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3"}>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("country")}</label>
                                        <Select
                                            placeholder={t("selection")}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>

                                </div>

                            </div>
                            <div className={"overflow-x-auto grid grid-cols-1 scrollbar p-2 post-input dark:bg-themeDarkPostInput post-input-design"}>
                                <Calendar
                                    multiple
                                    value={dates}
                                    onChange={setDates}
                                    calendar={persian}
                                    locale={persian_fa}
                                    className="p-component dark:bg-themeDarkPostInput" // Class for full-screen mobile layout
                                    numberOfMonths={6}
                                    disableMonthPicker
                                    disableYearPicker
                                    shadow={false}

                                />

                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2 ${activeTab === "tab_three" ? "active" : ""}`} id="tab_three" >
                            <div className={"overflow-x-auto grid grid-cols-1 scrollbar p-2 post-input dark:bg-themeDarkPostInput post-input-design"}>
                                <Calendar
                                    multiple
                                    value={dates}
                                    onChange={setDates}
                                    calendar={persian}
                                    locale={persian_fa}
                                    className="p-component dark:bg-themeDarkPostInput" // Class for full-screen mobile layout
                                    numberOfMonths={6}
                                    disableMonthPicker
                                    disableYearPicker
                                    shadow={false}
                                />

                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700 p-2 ${ activeTab === "tab_four" ? "active" : "" }`} id="tab_four">
                            <div class=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("AllowedEarlyDeparturePeriod")}</label>
                                        <InputText className="input w-full" placeholder={t("minute")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumPaidLeave")}</label>
                                        <InputText className="input w-full" placeholder={t("Day")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumLeaveWithoutPay")}</label>
                                        <InputText className="input w-full" placeholder={t("Day")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumHourlyLeavePerMonth")}</label>
                                        <InputText className="input w-full" placeholder={t("hour")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumSickLeavePerMonth")}</label>
                                        <InputText className="input w-full" placeholder={t("Day")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumOvertimePerMonth")}</label>
                                        <InputText className="input w-full" placeholder={t("hour")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumMissionPerMonth")}</label>
                                        <InputText className="input w-full" placeholder={t("Day")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700 p-2  space-y-5 ${ activeTab === "tab_five" ? "active" : "" }`} id="tab_five" >
                            <div style={{direction:"ltr"}} class="p-3 box bg-white dark:bg-themeDarkSalt700">
                                <PickList
                                    filter
                                    filterBy="name"
                                    sourceHeader={t("FreelancePersonnel")}
                                    targetHeader={t("ActivePersonnelTheGroup")}
                                    sourceStyle={{ height: "30rem" }}
                                    targetStyle={{ height: "30rem" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ButtonSubmitAndCancel
                />
            </div>


            {/*<FantasyModal openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >*/}
            {/*    <div>*/}
            {/*        {*/}
            {/*            setting === 'GeneralSetting'?*/}
            {/*                <HandleGeneralSetting/>:null*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</FantasyModal>*/}
        </>

  )
}