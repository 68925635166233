import React from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import ArrowLeftIcon from "../../../HIcon/ArrowLeftIcon";

export const SingleQuestion=({question,singleItems,handleAddSurveyQuestion,surveyId,index})=>{
    const { t } = useTranslation();

    const onChangeComment=(text)=>{
        handleAddSurveyQuestion(
            {
                surveyItemId: singleItems[0].id,
                surveyId:surveyId,
                textSurvey:text
            },
            index
        )
    }


    return (

        <>
            <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                    {index+1}
                </div>
                <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                    <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">
                        {question}
                    </h2>
                </div>
                <div>
                    <span className={"arrow_design_add_site"}>
                         <ArrowLeftIcon/>
                    </span>
                </div>
            </div>
            <div className={"px-2"}>
                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2">
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("RegisterYourComment")}</label>
                        <InputTextarea
                            rows={5}
                            cols={30}
                            className={"input w-full "}
                            onChange={(data)=>onChangeComment(data.target.value)}

                        />
                    </div>
                </div>
            </div>

        </>

    )
}