// ExtraHostTab.js

import React, {useEffect, useState} from "react";
import { AccordionTab } from "primereact/accordion";
import { InputNumber } from "primereact/inputnumber";
import { useTranslation } from "react-i18next";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTabServices} from "../Hooks/useTabServices";
import Select from "react-select";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {useCalculateService} from "../Hooks/useCalculateService";

export const ExtraMailHost = ({paymentMethods,showSpinner,hideSpinner,currencies,upgrade,handleValue,currentPath,handleSelectValue,handleDateTime,discountTypes}) => {
    const { t } = useTranslation();
    const [extraMailHost,setExtraMailHost] = useState({upgradeType: 0,paymentMethod:0, finalPrice: {amount: 0, currency: 0, moneyType: 0}, discount: {amount: 0, discountType: 0, from:null, to: null}, firstPrice: {amount: 0, currency: 0, moneyType: 0}, siteSpace: 0, proposedSpace: 0});
    const {handleShowPrice,handleGetCurrentType,handleGetPaymentMethod} = useTabServices();

    const {handleCalculateExtraMailHostHostPrice,data,setData} = useCalculateService(currentPath,upgrade,handleValue,hideSpinner);


    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Upgrades/ExtraMailHost`,{},'single');
            setExtraMailHost(response.data);
            handleCalculateExtraMailHostHostPrice();
            if(response.status === 200){
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }catch (e) {
            hideSpinner();
        }
    }

    useEffect(() => {
        fetchData();
    }, []); //

    const handleKeyDown = (e) => {
        e.preventDefault();
    };// I

    return (
        <>
            <div className="space-y-2">
                <div className="space-y-3 ">
                    <div className={"box shadow p-2 bg-white dark:bg-themeDarkPostInput space-y-1"}>
                        <div className={"flex justify-between items-center flex-wrap"}>
                            <div>
                                <span className={"text-xs dark:text-gray-300"}>{t("TariffPrice")} </span>
                                <span className={"text-xs dark:text-gray-300"}>{handleGetPaymentMethod(paymentMethods,extraMailHost.paymentMethod)}</span>
                            </div>
                            <del className={"text-xs text-themeDanger"}>{handleShowPrice(extraMailHost.firstPrice.amount,currencies,extraMailHost.firstPrice.currency)}</del>
                        </div>
                        <div className={"text-end"}>
                            <span className={"text-xs dark:text-gray-300"}>{handleShowPrice(extraMailHost.finalPrice.amount,currencies,extraMailHost.finalPrice.currency)}</span>
                        </div>
                    </div>

                    <div className={"box shadow p-2 space-y-1 bg-white dark:bg-themeDarkPostInput"}>
                        <div className={"flex justify-between items-center"}>
                            <h2 className={
                                "text-xs dark:text-gray-300"}>{t("Yourcurrentspace")}</h2>
                            <span className={"text-xs dark:text-gray-300 H-direction-ltr"}>{extraMailHost.siteSpace} MB</span>
                        </div>
                        <div className={"flex justify-between items-center"}>
                            <h2 className={"dark:text-gray-300 text-xs"}>{t("Suggestedspace")}</h2>
                            <span className={"dark:text-gray-300 text-xs"}>{extraMailHost.proposedSpace}</span>
                        </div>

                    </div>
                    <div className={"box p-2 shadow bg-white dark:bg-themeDarkPostInput flex justify-between items-center"}>
                        <h2 className={"text-xs dark:text-gray-300"}>{t("Theamountofpurchases")}</h2>
                        <span className={"dark:text-gray-300 text-xs H-direction-ltr"}>{upgrade.extraMailHost.extraMailHost} MB</span>
                    </div>
                    <div className={"Service_Upgrade "}>
                        <InputNumber inputId="horizontal-buttons"  onKeyDown={handleKeyDown}  value={upgrade.extraMailHost.minValue} onChange={(e) => handleCalculateExtraMailHostHostPrice(e.value)}  showButtons min={upgrade.extraMailHost.minValue} max={upgrade.extraMailHost.maxValue} suffix={'MB'} buttonLayout="horizontal" step={upgrade.extraMailHost.step}
                                     decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                     mode="decimal"  />
                    </div>

                </div>
                <div>
                    {
                        currentPath === '/Admin/ServiceUpgrade'?
                            <div className={"space-y-1"}>
                                <label className="dark:text-gray-300 text-xs">{t("Price")}</label>
                                    <InputNumber type={"text"} name={'price'}
                                                 min={0}
                                                 value={upgrade.extraMailHost.price}
                                                 onChange={(e) => handleValue('extraMailHost', { price: e.value })}
                                                 suffix={handleGetCurrentType(currencies,extraMailHost.firstPrice.currency)}
                                    />

                            </div>

                            :
                            <h2 className="dark:text-gray-300">
                                {t("Price")}:
                                <span className="text-themeInformation dark:text-themeDarkInformation">
                                    {handleShowPrice(data.finalPrice.amount,currencies,data.finalPrice.currency)}
                             </span>
                            </h2>
                    }
                </div>
            </div>
        </>
    );
};

