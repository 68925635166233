// با فراخوانی این تابع formData  مان را برای سرور میسازیم
// دوتا پارامتر file و mode  را هم بهش پاس میدیم
import {handleFailureMessage} from "../../../Services/Globals/Errors/NotificationService";

export const createFormData = (files, mode, path) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append("Files", files[i]);
    }
    formData.append("Path", path);
    formData.append("Exists", mode);
    return formData;
};

// تابعی که چک میکنه با api  فولدر تکراری هست یا خیر
export const handleExistedFolder = (response) => {
    const count = response.data.filter(
        (item) => item.isExisted === true
    ).length;
    var thereIsNoRepeat = true;
    if (count !== 0) {
        handleFailureMessage("فولدری با این نام وجود دارد . لطفا نام دیگری انتخاب کنید");
        return;
    }
    return thereIsNoRepeat;
};
