import EmojiPicker from "emoji-picker-react";
import EmojiIcon from "./SocialIcon/EmojiIcon";
import SurveyIcon from "./SocialIcon/SurveyIcon";
import SaveIcon from "./SocialIcon/SaveIcon";
import ItalicIcon from "./SocialIcon/ItalicIcon";
import BoldIcon from "./SocialIcon/BoldIcon";
import React, { useState, useCallback, useEffect, useRef } from 'react';
import {useTranslation} from "react-i18next";

import MagicIcon from "./SocialIcon/MagicIcon";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

const TextEditor = ({openFileManager,openAutomaticCaption,openSurvey,openCaption}) => {
    const { t } = useTranslation();
    const [isRtl, setIsRtl] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const editorRef = useRef(null);
    const emojiPickerRef = useRef(null);



    const [activeButtons, setActiveButtons] = useState({
        bold: false,
        italic: false,
    });

    const handleInputChange = useCallback((e) => {
        const text = e.target.textContent;
        if (text.length <= 500) {
            setIsRtl(isRtlText(text));
            setCharCount(text.length);
        } else {
            e.target.textContent = text.substring(0, 500);
        }
    }, []);

    const isRtlText = (text) => {
        const rtlChars = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
        return rtlChars.test(text);
    };

    const handleKeyDown = (e) => {
        const text = editorRef.current.textContent;
        if (e.key === 'Enter') {
            // Prevent default behavior (new line or div)
            e.preventDefault();
            // Insert a new paragraph if under 500 characters
            if (text.length < 500) {
                document.execCommand('insertHTML', false, '<p><br></p>');
            }
        } else if (text.length >= 500 && e.key !== 'Backspace' && e.key !== 'Delete' && !e.ctrlKey && !e.metaKey) {
            e.preventDefault();
        }
    };

    const handleButtonClick = (command) => {
        document.execCommand(command, false, '');
        setActiveButtons((prev) => ({
            ...prev,
            [command]: !prev[command],
        }));
    };

    const getProgressStyle = () => {
        const percentage = Math.min((charCount / 500) * 100, 100);
        return {
            backgroundImage: `conic-gradient(rgb(56,189,248) ${percentage}%, rgba(0, 0, 0, 0) ${percentage}%)`,
        };
    };

    const [showPicker, setShowPicker] = useState(false);

    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    const onEmojiClick = (event, emojiObject) => {
        setShowPicker(false); // Close EmojiPicker
        // Append the emoji to the contentEditable div
        const editor = editorRef.current;
        const emoji = emojiObject.emoji;
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        if (editor.textContent.length < 500) {
            range.deleteContents();
            range.insertNode(document.createTextNode(emoji));
            setCharCount(editor.textContent.length);
        }
    };

    useEffect(() => {
        const editor = editorRef.current;
        if (charCount === 0) {
            editor.classList.add("body-editor-after");
        } else {
            editor.classList.remove("body-editor-after");
        }
    }, [charCount]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) && !event.target.closest('.button_editor')) {
                setShowPicker(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [emojiPickerRef]);

    return (
        <div>
            <div className={"social_editor border dark:border-themeDarkColorLine rounded"}>
                <div className={"header_editor flex flex-col md:flex-row gap-2 items-center justify-between border-b p-2 dark:border-themeDarkColorLine"}>
                    <div className={"flex gap-2 items-center"}>
                        <button className={"button bg-themeInformation dark:bg-themeDarkInformation text-white text-xs flex gap-2 items-center"} onClick={()=>openFileManager()}>
                            <HPrimeIconSmall
                            icon={"pi-folder-open"}
                            />
                            {t("Files")}
                        </button>
                        <button className="button bg-themeInformation dark:bg-themeDarkInformation text-white text-xs flex gap-2 items-center" onClick={()=>openSurvey()}>
                            <SurveyIcon/>
                            {t("Survey")}
                        </button>
                        <button className="button_editor" onClick={()=>openAutomaticCaption()}>
                            <MagicIcon/>
                        </button>
                    </div>
                    <div className={"flex gap-2 items-center"} >
                        <button className="button_editor" onClick={()=>openCaption()}>
                            <SaveIcon/>
                        </button>
                        <button className={`button_editor ${activeButtons.bold ? 'active' : ''}`} onClick={() => handleButtonClick('bold')}>
                            <BoldIcon/>
                        </button>
                        <button className={`button_editor ${activeButtons.italic ? 'active' : ''}`} onClick={() => handleButtonClick('italic')}>
                            <ItalicIcon/>
                        </button>
                        <div className={"relative"}>
                            <button onClick={togglePicker} className={"button_editor "}>
                                <EmojiIcon/>
                            </button>
                            {showPicker && (
                                <div ref={emojiPickerRef} >
                                    <EmojiPicker onEmojiClick={onEmojiClick} style={{ position: 'absolute' }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="relative size-8 items-center grid place-content-center rounded-full bg-gray-300 dark:bg-themeDarkPostInput">
                            <div className="absolute size-full rounded-full" style={getProgressStyle()}></div>
                            <span className="z-10 size-5 rounded-full bg-white dark:bg-themeDarkSalt700 flex items-center justify-center text-xs dark:text-gray-300">
                                {charCount}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    ref={editorRef}
                    className="body-editor body-editor-after dark:text-gray-300 dark:bg-themeDarkPostInput"
                    data={t("EnterTheText")}
                    contentEditable="true"
                    onInput={handleInputChange}
                    onKeyDown={handleKeyDown}
                    style={{ direction: isRtl ? 'rtl' : 'ltr', textAlign: isRtl ? 'right' : 'left' }}
                    suppressContentEditableWarning={true}
                >
                </div>
            </div>
        </div>
    );
};

export default TextEditor;
