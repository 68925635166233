import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import Select from "react-select";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import queryString from "query-string";
import {convertAddReminder, convertEditReminder} from "./ReminderService";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {Export} from "../../../Commans/Grid/Export";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {useCustomReminder} from "./useCustomReminder";
import {useReminderGrid} from "./useReminderGrid";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {reminderSchema} from "./reminderSchema";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";



export const HandleUserReminder = () => {


  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [IsShowSpinner,setShowSpinner] = useState(false);
  const [refresh,setRefresh] = useState(false);
  const [isEdit,setIsEdit] = useState(false);
  const [userId,setUserId] =useState(null);
  const [errors, setErrors] = useState({});
  const [initialSource,setInitialSource] = useState({sendingStates:[],types:[],reminderStates:[]});
  const [disable,setDisable] = useState(true);
  const [IsOpen, setIsOpen] = useState(false);

  const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
  const {handleSelectNoteType,handleValue,reminder,setReminders,handleSelectSendingState,handleDateReminder} = useCustomReminder(setErrors,setDisable);

  const showSpinner=()=>{
    setShowSpinner(true);
  }
  const hideSpinner=()=>{
    setShowSpinner(false);
  }
  const Refresh=()=>{
    setRefresh(prevState => !prevState);
  }

  const onSetErrors=(errors)=>{
    setErrors(errors)
  }
  const handleClearErrors=()=>{
    setErrors({})
  }


  const handleOpenReminder=()=>{
    setReminders((prevState)=>({...prevState,enSendingStates:null,description: null,type:initialSource.types[0],message: '',title: '',reminderDateUtc: null}));
    setIsOpen(true);
  }

  const handleCloseReminder=()=>{
    setIsOpen(false);
    setIsEdit(false);
    setDisable(true)
    handleClearErrors();
    setReminders((prevState)=>({...prevState,enSendingStates:null,description: null,type:initialSource.types[0],message: '',title: '',reminderDateUtc: null}));
    redirectTo(`/CRM/User/List/Reminder/Add?userId=${userId}`)
  }


  const handleInitialSource=(sendingStates,noteTypes,jobStates)=>{
    const sendingStatesData = convertSelectDataHandler(sendingStates);
    const noteTypesData = convertSelectDataHandler(noteTypes);
    const jobStatesData = convertSelectDataHandler(jobStates);
    setInitialSource({
      sendingStates: sendingStatesData,
      types: noteTypesData,
      reminderStates: jobStatesData
    })
  }

  const fetchData = async ()=>{
    try {
      showSpinner();
      const ResponseSendingStates = await  GET(`/Enumerations/EnMessengers`,{},'IEnumerable');
      const reminderResponse = await  GET(`/Enumerations/EnNoteType`,{},'IEnumerable');
      const stateResponse = await GET(`/Enumerations/EnBackgroundJobState`);
      if (ResponseSendingStates.status === 200 && reminderResponse.status === 200&&stateResponse.status === 200 )
      {
        const queryParams = queryString.parse(window.location.search);
        handleInitialSource(ResponseSendingStates.data,reminderResponse.data,stateResponse.data);
        hideSpinner()
        setUserId(queryParams.userId);
      }
      else {
        handleApiError(ResponseSendingStates,hideSpinner);
      }
    } catch (error) {
      hideSpinner()
    }
  }






  const handleAddReminder =async (e)=> {
    e.preventDefault();
    const currentUtcTime = new Date().toISOString();
    const isValid = validate(reminderSchema,reminder,onSetErrors);
    if (!isValid) {
      return;
    }
    try {
      if(isEdit){
        if((reminder.type.value ===1 || reminder.type.value === 2) && reminder.enSendingStates === null ){
          handleFailureMessage(t('PleaseSelectNotificationMethod'))
          return;
        }
        if((reminder.type.value ===1 || reminder.type.value === 2) && reminder.reminderDateUtc === null ){
          handleFailureMessage(t('PleaseSelectDateTime'))
          return;
        }
        if ( reminder.type.value ===1 && new Date(reminder.reminderDateUtc) < new Date(currentUtcTime)) {
          handleFailureMessage(t('ReminderDateCannotBeInThePast'));
          return;
        }
        showSpinner();
        const queryParams = queryString.parse(window.location.search);
        const reminders = convertEditReminder(reminder,queryParams.userId);
        const response = await PUT(`/UserNotes`,reminders,false);
        if (response.status === 200) {
          hideSpinner();
          Refresh();
          handleCloseReminder();
          handleSuccessMessage(t("Reminderhasbeensuccessfullyedited"));
        } else {
          handleApiError(response,hideSpinner)
        }
      }
      else{
        if((reminder.type.value ===1 || reminder.type.value === 2) && reminder.enSendingStates === null ){
          handleFailureMessage(t('PleaseSelectNotificationMethod'))
          return;
        }
        if((reminder.type.value ===1 || reminder.type.value === 2) && reminder.reminderDateUtc === null ){
          handleFailureMessage(t('PleaseSelectDateTime'))
          return;
        }
        if (reminder.type.value ===1 && new Date(reminder.reminderDateUtc) < new Date(currentUtcTime)) {
          handleFailureMessage(t('ReminderDateCannotBeInThePast'));
          return;
        }
        const queryParams = queryString.parse(window.location.search);
        showSpinner()
        const reminders = convertAddReminder(reminder,queryParams.userId);
        const response = await POST(`/UserNotes`,reminders,false);
        if (response.status === 200) {
          hideSpinner();
          Refresh()
          handleCloseReminder();
          handleSuccessMessage(t("Reminderhasbeensuccessfullyrecorded"));
        } else {
          handleApiError(response,hideSpinner)
        }
      }
    }
    catch (e) {
      hideSpinner();
    }
  }

  useEffect(()=>{
     fetchData();
  },[])

const {filter,handleNextPage,handleCurrentPage,handleSearch,search,handlePreviousPage,setFilter,setSearch,handlePageSizeCount,handleResetFilters,handleToggleFilter,SwitchFilters,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination}=useReminderGrid(setIsEdit,setShowSpinner,setDisable,setReminders,setIsOpen,hideSpinner,initialSource,userId,Refresh,refresh,fetchData)






  return(
      <>
        <div class="space-y-5 relative">
          {

            IsShowSpinner?
                <div className=" huploader_loading" >
                  <div className="relative">
                    <LoadingHarmony/>
                  </div>
                </div>
                :
                null
          }
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>
                            <ButtonPlus
                                onClick={() => handleOpenReminder()}
                                tooltipTitle={t("CreateReminder")}
                            />
                          </>
                        }
                        childrenTwo={
                          <>
                            <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                          </>
                        }
            />


          </div>
          <div class="space-y-2 H-body-ag-grid" >

            <GridTable
                columnDefs={columnDefs}
                rowSelection={GridSetting.rowSelection}
                rowData={rowData}
                style={GridSetting.gridStyle}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                pagination={GridSetting.pagination}
                enableRtl={GridSetting.enableRtl}
                domLayout={GridSetting.domLayout}
                paginationPageSize={GridSetting.pageSize}
                getRowHeight={getRowHeight}
                localeText={AgGridPersion}
            />
            <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
          </div>

          <Export/>

        </div>

        <FantasyModal openModal={IsOpen}  closeModal={handleCloseReminder} title={t("CreateNote")}>
          <div>
            <form onSubmit={handleAddReminder} method={'post'} className={"space-y-2"}>
              <div className="grid grid-cols-1 md:grid-cols-12 gap-3">
                <div class="space-y-1 md:col-span-8">
                  <div className="flex justify-between items-center">
                    <label className="text-xs dark:text-gray-300">
                      {t("NoteTitle")}
                    <RequiredIcon/>
                    </label>
                    <DisplayError message={errors?.title}/>
                  </div>
                  <InputText placeholder={t("Title")} type={"text"} value={reminder.title} onInput={(e)=>handleValue(e)} name={'title'} className={" input w-full"} />
                </div>
                <div class="space-y-1  md:col-span-4">

                  <div className="space-y-1 ">
                    <h2 className="text-xs dark:text-gray-300">{t("NoteStatus")}</h2>
                    <Select
                        options={initialSource.types}
                        isMulti={false}
                        isSearchable={true}
                        onChange={(data)=>handleSelectNoteType(data)}
                        placeholder={t("selection")}
                        value={reminder.type}
                        menuPosition={"fixed"}
                        classNamePrefix={"h_multi_select"}
                    />
                  </div>
                </div>
                <div class="space-y-1 md:col-span-8">
                  <label className="text-xs dark:text-gray-300">{t("NotificationMethod")}</label>
                  <Select
                      options={initialSource.sendingStates}
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      isDisabled={disable}
                      onChange={(data)=>handleSelectSendingState(data)}
                      name={'enSendingStates'}
                      placeholder={t("selection")}
                      value={reminder.enSendingStates}
                      className={"select-group"}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className={"space-y-1  md:col-span-4"}>
                <label className="text-xs dark:text-gray-300">

                    <SettingButton
                        onClick={()=>handleSetting('GeneralSetting')}
                        tooltipTitle={t("Calendarsettings")}
                    />
                    {t("ReminderDataTime")}
                  </label>
                  <DateTimePicker isContainsTime={true}  disabled={disable} handleDateTime={handleDateReminder} format={'YYYY/MM/DD hh:mm:ss'} value={reminder.reminderDateUtc}/>
                </div>

                <div class="md:col-span-12">
                  <InputTextarea value={reminder.message} onInput={(e)=>handleValue(e)} placeholder={t("NoteText")} name={'message'} rows={5} cols={30} className={"input w-full"} />
                </div>
              </div>
              <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("register")}</button>
            </form>
          </div>
        </FantasyModal>

        <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
          <div>
            {
              setting === 'GeneralSetting'?
                  <HandleGeneralSetting/>:null
            }
          </div>
        </FantasyModal>
        <ToastContainer />

      </>



  )
}