import { useState } from "react";
export const useContract = (setErrors) => {
    const [contract, setContract] = useState({
        userId:null,
        contractId:null,
        description:null,
        contractType:null,
        contractAttach:null,
        startContract: null,
        endContract: null,
        contractAttachPath:null
    })
    const handleContractStartDate = (dateTime) => {
        setContract({ ...contract, startContract: dateTime })
    }
    const handleContractEndDate = (dateTime) => {
        setContract({ ...contract, endContract: dateTime })
    }
   
    const handleSelectValue=(data,name)=>{
        setContract({ ...contract, [name]: data });
    }
    const handleRemoveContractAttachment=()=>{
        setContract({ ...contract, contractAttachPath: contract.contractAttach });
    }

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setContract({...contract,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }




    //.....

    return {
        contract,
        setContract,
        handleContractStartDate,
        handleContractEndDate,
        handleSelectValue,
        handleValue,
        handleRemoveContractAttachment
    }

}