import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

export const ChartHarmony=(props)=>{

    const {options,apiUrl,className,style} = props;

    const chartContainerRef = useRef(null);
    useEffect(() => {
        const chartContainer = chartContainerRef.current;

        // Initialize the ECharts instance
        const chart = echarts.init(chartContainer);

        // Configure the chart options
        // Set the options and render the chart
        chart.setOption(options);

        // Cleanup the chart when component is unmounted
        return () => {
            chart.dispose();
        };
    }, []);

    return (<div ref={chartContainerRef} className={className} style={style} ></div>);

}