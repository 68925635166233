import React, {useState} from "react";
import {GET} from "../../AxiosService/AxiosApiService";
import {handleApiError} from "../Errors/handleApiError";

export const useSlugValidation=(onApiError,data)=>{

    const [IsSlugValid,setSlugValid] = useState(true);
    const handleValidateSlug = async (e) => {
        const { name,value } = e.target;
        try {
            if (name === "title") {
                const response = await GET('/Public/SlugValidation',{NewSlug:data.slug,OldSlug:data.oldSlug},'single');
                if(response.status === 200){
                    setSlugValid(response.data.isSlugValid);
                    return;
                }
                handleApiError(response,onApiError);
            }
            else{
                const response = await GET('/Public/SlugValidation',{NewSlug:value,OldSlug:data.oldSlug},'single');
                if(response.status === 200){
                    setSlugValid(response.data.isSlugValid);
                    return;
                }
                handleApiError(response,onApiError);
            }
        }catch (e) {
            console.log(e)
        }
    };
    return {
        IsSlugValid,
        setSlugValid,
        handleValidateSlug
    }

}