import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";

import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {useTranslation} from "react-i18next";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {Accordion, AccordionTab} from "primereact/accordion";
import Select from "react-select";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

// import 'react-tooltip/dist/react-tooltip.css'

export const HandleAddPaymentGateways=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();

    const [modal, setModal] = useState({ ChangePrice: false, });

    const ChangePriceOnHide = () => {
        setModal((prevState) => ({ ...prevState, ChangePrice: !modal.ChangePrice }));
    };




    const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: t("Icon"),width: 130, maxWidth: 120,

            cellRenderer:parameters=>
                <>تصویر</>
                        },
        { headerName: t("Title"),
            cellRenderer:parameters=>
                <>
                    شلوار جین
                </>
        },
        { headerName: t("BankName"), minWidth: 170,

            cellRenderer:parameters=>
                    <>www.arian team.com</>

        },
        { headerName: t("BankAccountNumber"), minWidth: 180,

            cellRenderer:parameters=>
                <>www.arian team.com</>

        },
        { headerName: t("cardNumber"), minWidth: 180,

            cellRenderer:parameters=>
                <>www.arian team.com</>

        },


        { headerName: t("StaticData"), maxWidth: 120,
            cellRenderer:parameters=>
                <div>
                 mmmm
                </div>
        },


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>
                </div>
            }
        },

    ];

    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };







    return(<>
        <div class=" space-y-5">
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                            <InputText type={"search"} className={" input w-full"} placeholder={t("search")} />
                        </div>
                    <ButtonSearchList/>
                    </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => setModal({ ChangePrice: true })}
                                        tooltipTitle={t("Operator")}
                                        tooltipId={4}
                                    />

                                </>
                            }
                            childrenTwo={
                                <>
                                    <div className=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">***</lable>
                                        <input className="input input--switch border bg-white" type="checkbox"/>
                                    </div>
                                    <div class=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">***</lable>
                                        <input className="input input--switch border bg-white" type="checkbox"/>
                                    </div>
                                    <div class=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">***</lable>
                                        <input className="input input--switch border bg-white" type="checkbox"/>
                                    </div>
                                    <div class="space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">***</lable>
                                        <input className="input input--switch border bg-white" type="checkbox"/>
                                    </div>
                                </>
                            }
                />

            </div>
            <div>
                <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                           enableRtl={true}
                           pagination={true}
                           getRowHeight={getRowHeight}
                           onGridReady={onGridReady}
                           quickFilterText={quickFilterText}
                           style={{ width: '100%', height: '100%' ,position:'relative' }}
                />
            </div>
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
                    <div className="sm:col-span-2">
                        <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                            <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                <span className="bg_Pdf"></span>
                                <h2>خروجی (Pdf)</h2>
                            </Button>
                            <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                <span className="bg_Excel"></span>
                                <h2>خروجی (Exel)</h2>
                            </Button>
                            <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                <span className="bg_Word"></span>
                                <h2>خروجی (Word)</h2>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FantasyModal openModal={modal.ChangePrice}  closeModal={ChangePriceOnHide} title={t("Operator")}>
            <div>
                <div class="grid grid-cols-1 gap-2">
                    <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                            <InputText className={"input w-full"} />
                    </div>
                    <div className={"flex items-center gap-2"}>
                        <div class="space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">{t("BankName")}</label>
                            <MultiDataSelect placeholder={t("selection")} />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Icon")}</label>
                            <label htmlFor="uploadTwo" className="button bg-themeSecondary text-white flex justify-center items-center gap-2">
                                <HPrimeIcon icon={'pi-upload'}/>
                                {t("Icon")}
                                <input multiple type="file" id="uploadTwo"  className="hidden" />
                            </label>
                        </div>
                    </div>
                    <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("BankAccountNumber")}</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                    <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("cardNumber")}</label>
                            <InputText type={"text"} className={"input w-full"} />
                    </div>
                    <div class="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("BankID")}</label>
                        <InputText type={"text"} className={"input w-full"} />
                    </div>
                    <div class="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("StaticData")}</label>
                        <InputText type={"text"} className={"input w-full"} />
                    </div>
                    <Accordion >
                        <AccordionTab
                            header={
                                <div>
                                    {t("Parameters")}
                                </div>
                            }

                        >

                                <div className="p-1 grid grid-cols-1 md:grid-cols-2 gap-1">
                                    <div className="flex gap-1 items-end">
                                        <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                                                1
                                            </div>
                                        <div className="space-y-1 flex-1">
                                            <label className="text-xs dark:text-gray-300">{ t("Title")}</label>
                                            <Select placeholder={t("selection")}
                                                    classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex gap-1 items-end">
                                        <div className="space-y-1">
                                            <label className="text-xs dark:text-gray-300">{t("VariableName")}</label>
                                            <InputText className={"input w-full"} />
                                        </div>
                                        <div className="flex items-end gap-1">
                                            <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white flex items-center gap-1">
                                                <HPrimeIcon icon={'pi-plus'}/>
                                            </button>

                                            <button type="button" className="button small_button bg-themeDanger text-white flex items-center gap-1">
                                                <HPrimeIcon icon={'pi-minus'} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                        </AccordionTab>
                    </Accordion>
                    <div class="">
                        <button className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                    </div>
                </div>
            </div>
        </FantasyModal>

       </>)
}