import React, {useMemo, useState} from "react";

import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {GridTable} from "../Commans/Grid/GridTable";
import {Button} from "primereact/button";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {Dialog} from "primereact/dialog";
import Select from "react-select";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";
import CreatableSelect from "react-select/creatable";

export const HandleSendBoxEmail = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [modal, setModal] = useState({ sendMail: false,see:false, });


    const sendMailOnHide = () => {
        setModal((prevState) => ({ ...prevState, sendMail: !modal.sendMail }));
    };




    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("Sender"), maxWidth: 170,
            cellRenderer:parameters=>
                <>مسعود احمدی محمدآبادی</>
        },
        { headerName: t("EmailText"),
            cellRenderer:parameters=>
                <div className={"cursor-pointer"} onClick={() => handleShowMessage(true,)} >
                    <p className={"line-clamp-1"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit itaque rem velit. Aliquam aliquid delectus excepturi illum nobis pariatur quia sequi veniam? Amet distinctio eius eligendi eos eveniet molestiae nisi nobis perspiciatis quasi voluptas? Ad animi consectetur cum dignissimos doloremque eaque earum fugiat illo in ipsam iusto laudantium magnam maiores minima molestiae mollitia officiis omnis quae quisquam, veritatis! A cumque dolor eos, illo in incidunt ipsa maxime mollitia sit temporibus? Cumque, ea enim inventore ipsam iste iure iusto nemo non odit, porro quasi quisquam rem tenetur ullam voluptas. Aut consequatur deleniti dignissimos dolorum earum ex excepturi labore optio veniam vitae?</p>
                </div>
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button className="text-themeInformation dark:text-themeDarkInformation" ><HPrimeIcon size={20} icon={'star'}  /></button>
                    <button className="text-themeInformation dark:text-themeDarkInformation" ><HPrimeIcon size={20} icon={'bookmark'}  /></button>
                    <GridButtonDelete
                    />
                </div>
            }

        },

    ];

    const rowData = [

        { id:1, orderReciever: 'Camry',date:'1402/10/3',code:'84732085', Totalprice: 25000,comment:'راضی ام'},
        { id:2, orderReciever: 'Camry',date:'1402/10/3',code:'84732085', Totalprice: 25000,comment:'راضی ام'},
        { id:3, orderReciever: 'Camry',date:'1402/10/3',code:'40000', Totalprice: 25000,comment:'راضی ام'},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };


    const [visible, setVisible] = useState(false);


    const handleShowMessage =(visible,message)=>{
        setVisible(visible);

    }




    return(

      <>

          <Dialog header={t("MessageText")} visible={visible} className={"Dialog-ShowText"} onHide={() => setVisible(false)} >
          </Dialog>


          <div class="space-y-5">
              <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                  <ListSearch label={null} icon={null} >
                      <div class="space-y-2 ">
                          <label className="text-xs dark:text-gray-300">{t("SearchByNameMessage")}</label>
                          <InputText type={"text"} className={" input w-full"} placeholder={t("search")}/>
                      </div>
                      <ButtonSearchList
                      />
                  </ListSearch>
                  <ListFilter label={null} icon={null}
                              showChildrenTwo={false}
                              childrenOne={
                                  <>
                                      <ButtonPlus
                                          title={t("Email")}
                                          icon={"pi-send"}
                                          onClick={()=>redirectTo("/EmailMarketing/Compose")}
                                          tooltipTitle={t("SendEmail")}
                                      />

                                      <ButtonPlus
                                          title={t("Email")}
                                          icon={"pi-pen-to-square"}
                                          onClick={()=>redirectTo("/EmailMarketing/Email/Add")}
                                          tooltipTitle={t("AddEmail")}
                                      />

                                      <ButtonPlus
                                          title={t("Draft")}
                                          icon={"pi-file-edit"}
                                          onClick={()=>redirectTo("/EmailMarketing/Draft")}
                                          tooltipTitle={t("Draft")}
                                      />

                                      <ButtonPlus
                                          title={t("Receive")}
                                          icon={"pi-inbox"}
                                          onClick={()=>redirectTo("/EmailMarketing/Inbox")}
                                          tooltipTitle={t("receivingBox")}
                                      />

                                      <ButtonPlus
                                          title={t("Receive")}
                                          icon={"pi-inbox"}
                                          onClick={()=>redirectTo("/EmailMarketing/Inbox")}
                                          tooltipTitle={t("receivingBox")}
                                      />

                                      <ButtonPlus
                                          title={t("trash")}
                                          icon={"pi-trash"}
                                          onClick={()=>redirectTo("/EmailMarketing/Trash")}
                                          tooltipTitle={t("trash")}

                                      />


                                  </>
                              }

                  />
              </div>
              <div className={"H-body-ag-grid space-y-2"}>
                  <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                             enableRtl={true}
                             pagination={true}
                             getRowHeight={getRowHeight}
                             onGridReady={onGridReady}
                             quickFilterText={quickFilterText}
                  />
              </div>
          </div>



      </>)
}