export const handleCreateGeneralSettings=(settings)=>{
    const data = {
        title:settings.title === null || settings.title === undefined || settings.title === ''?null:settings.title,
        keywords:settings.keywords,
        editor:settings.editor === null || settings.editor === undefined?null:settings.editor.value,
        theme:settings.theme === null || settings.theme === undefined ? null : settings.theme.value,
        direction : settings.direction === null || settings.direction === undefined?null:settings.direction.value,
        fontFamily:settings.fontFamily === null || settings.fontFamily === undefined?null:settings.fontFamily.value,
        fontSize:settings.fontSize === null || settings.fontSize === undefined?null:settings.fontSize.value,
        description:settings.description === null || settings.description === undefined?null:settings.description,
        calendarType:settings.calendarType === null || settings.calendarType === undefined?null:settings.calendarType.value,
        calendarView:settings.calendarView === null || settings.calendarView === undefined?null:settings.calendarView.value,
        calendarLanguage:settings.calendarLanguage === null || settings.calendarLanguage === undefined ? null: settings.calendarLanguage.value,
        firstDayOfWeek:settings.firstDayOfWeek === null || settings.firstDayOfWeek === undefined?null:settings.firstDayOfWeek.value,
        countryId:settings.countryId === null || settings.countryId === undefined?null:settings.countryId.value,
        watermarkActivation:settings.watermarkActivation,
        watermarkText:settings.watermarkText === null || settings.watermarkText === undefined ? null:settings.watermarkText,
        watermarkFontColor:settings.watermarkFontColor,
        watermarkBackgroundColor:settings.watermarkBackgroundColor,
        watermarkFontSize:settings.watermarkFontSize === null || settings.watermarkFontSize === undefined?1:settings.watermarkFontSize,
        watermarkPosition:settings.watermarkPosition === null || settings.watermarkPosition === undefined?null:settings.watermarkPosition.value,
        currency:settings.currency === null || settings.currency === undefined?null:settings.currency.value,
        culture:settings.culture === null || settings.culture === undefined?null:settings.culture.value,
        concurrencyStamp:settings.concurrencyStamp
    }
    return data;
}