import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../UIParts/HPrimeIconSmall";



export const  GridButtonEdit =({icon, onClick,iconClass,className}) => {


    const { t } = useTranslation();



    return(
      <>

          <button className={`hbtn_edit grid place-content-center ${className} `}
              onClick={onClick}
          >
              <HPrimeIcon icon={`${icon ? icon:"pi-pencil"}`} iconClass={"text-themeSuccess"}  />
          </button>


  </>)
}