import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import client from "../../../Services/AxiosService/axiosClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { handleFailureMessage } from "../../../Services/Globals/Errors/NotificationService";

export const MultiDataSelect = (props) => {
    // const redirectTo = useNavigate();
    //
    // const { Ismulti, className, apiUrl, placeholder, name, filterKey } = props;
    //
    //  const formatOptionLabel = ({ label }) => <div>{label}</div>;
    //  const getOptionValue = (option) => (option ? option.value : null);
    //
    // const handleChange = (options) => {
    //     props.onSelection(options, name, Ismulti);
    // };
    //
    // const [options, setOptions] = useState([]);
    // const [selectedValue, setSelectedValue] = useState(null);
    // const [isLoading, setIsLoading] = useState(false);
    //
    //
    // const convertSelectDataHandler = (data)=>{
    //     const options  = data.map(item => ({
    //         label: item.value,
    //         value: item.key
    //     }));
    //     return options;
    // }
    // const responseHandler = (resp) => {
    //     setIsLoading(false);
    //     if (resp.status === 200) {
    //         const data = convertSelectDataHandler(resp.data)
    //         setOptions(data);
    //     } else if (resp.status === 400) {
    //         handleErrorResponse(resp.error);
    //     } else if (resp.status === 401) {
    //         redirectTo("/");
    //     } else if (resp.status === 403) {
    //         redirectTo("/");
    //     } else if (resp.status === 404) {
    //         handleFailureMessage("اطلاعاتی یافت نشد");
    //     } else if (resp.status === 500) {
    //         handleFailureMessage("اختلال در برقراری ارتباط با سرور");
    //     } else {
    //         handleErrorResponse(resp.error);
    //     }
    // };
    //
    // const handleErrorResponse = (error) => {
    //     const message = error.errors.map((item) => item.message).join("");
    //     handleFailureMessage(message);
    // };
    //
    // const dataApiHandler = async () => {
    //     try {
    //         setIsLoading(true);
    //         const response = await client.get(apiUrl);
    //         responseHandler(response);
    //     } catch (error) {
    //         responseHandler(error.response);
    //     }
    // };
    //
    // useEffect(() => {
    //     dataApiHandler();
    // }, []);
    //
    //
    // useEffect(() => {
    //     let selectedOptions = null;
    //     if (Array.isArray(props.value)) {
    //         selectedOptions = props.value.map((id) =>
    //             options.find((option) => option?.key === id)
    //         );
    //     } else {
    //         selectedOptions = options.find((option) => option?.key === props.value);
    //     }
    //     setSelectedValue(selectedOptions || "");
    // }, [props.value,options]);
    //
    //

    return (

        <div className={"w-full"}>

                    <Select
                        isMulti={null}
                        name={null}
                        options={null}
                        className={"w-full"}
                        isSearchable={true}
                        value={null}
                         getOptionValue={null}
                        placeholder={null}
                        isDisabled={true}
                        onChange={(e)=>console.log(e)}
                        formatOptionLabel={null}
                        autoFocus={true}
                        isClearable={true}
                    />
            </div>

    );
};