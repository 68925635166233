import React, {useEffect} from "react";
import {useState} from "react";
import {ChartHarmony} from "../ChartHarmony";

import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDefaultDashboardWidgets} from "../DefaultDashboards/useDefaultDashboardWidgets";
import {Sale} from "../DefaultDashboards/DefaultWidgets/Sale";
import {useDashboard} from "../useDashboard";
import {UserComment} from "../DefaultDashboards/DefaultWidgets/UserComment";
import {UserNote} from "../DefaultDashboards/DefaultWidgets/UserNote";
import {Tooltip} from "react-tooltip";
import {EntryAndExitListPersonnel} from "../EntryAndExitListPersonnel";
import {DashboardProjects} from "./DashboardProjects";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";


export const HandleSuperAdminDashboardArian=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();




    return (

        <div className={"relative min-h-screen"}>
            <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                </button>
                <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                    <div className="space-y-10">
                        <div>
                            <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                <h2 className="text-base dark:text-gray-300 -intro-x">
                                    {t("GeneralReport")}
                                </h2>
                                <Link to={"#"} className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                    <HPrimeIcon
                                        icon={"pi-sync"}
                                    />
                                    {t("Reload")}
                                </Link>
                            </div>
                            <div className={`grid gap-x-5 gap-y-10 py-3 grid-cols-2 sm:grid-cols-2 xl:grid-cols-5 ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link to={"#"}  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" >
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-center text-base"}>
                                                            تعداد نمایندگان
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-users"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-center text-base"}>
                                                            {t("Sellers")}
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-face-smile"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>
                                                            10
                                                        </p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                            {t("ActiveWebsite")}
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-globe"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                            {t("Expiring")}
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-calendar-clock"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                            {t("expired")}
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-calendar-clock"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className="text-themeInformation dark:text-themeDarkInformation text-xl text-center">10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                            {t("ActiveServers")}
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-server"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>

                                                </div>
                                                    <div>
                                                        <p className="text-themeInformation dark:text-themeDarkInformation text-xl text-center">10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-base text-center"}>{t("HardSpace")}</h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-server"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                            {t("Requests")}
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-arrow-right-arrow-left"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                            دامین ها
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-globe"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className="text-themeInformation dark:text-themeDarkInformation text-xl text-center">10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div className={"sm:flex justify-between items-center"}>
                                                    <div>
                                                        <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                         شارژ پیامک
                                                        </h2>
                                                    </div>
                                                    <div className={"hidden sm:block"}>
                                                        <HPrimeIcon
                                                            icon={"pi-mobile"}
                                                            iconClass={"dark:text-gray-300"}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <p className="text-themeInformation dark:text-themeDarkInformation text-xl text-center">10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`grid grid-cols-1 md:grid-cols-2 gap-3 ${classesToggled ? '' : 'lg:grid-cols-1'} `}>
                            <div className={"-intro-x"}>
                                <div className="flex items-center relative justify-between">
                                    <h2 className="text-base  dark:text-gray-300">
                                        {t("statisticsAndVisits")}
                                    </h2>
                                    <Link to="/Statistics" className="text-themeInformation  dark:text-themeDarkInformation">({t("viewing")})</Link>
                                </div>
                                <div className="py-3">
                                    <div className="box bg-white dark:bg-themeDarkSalt700  p-5 ">
                                        <div className="flex justify-around items-center">
                                            <div className="flex-1 text-center">
                                                <div className="text-gray-500 text-center dark:text-gray-300">{t("MonthlyStatistics")}</div>
                                                        <div className="text-themeInformation dark:text-themeDarkInformation text-lg xl:text-xl  dark:text-gray-300">
                                                            {dashboard.statistics.monthly}
                                                        </div>
                                                    </div>
                                                    <div className="border-x border-dashed border-gray-300 flex-1 text-center">
                                                        <div
                                                            className="text-gray-500 text-center dark:text-gray-300">{t("Daily")}</div>
                                                        <div className="text-gray-500 text-lg xl:text-xl  dark:text-gray-300">
                                                            {dashboard.statistics.daily}
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 text-center ">
                                                        <div className="text-gray-500 text-center dark:text-gray-300 ">{t("Weekly")}</div>
                                                        <div className="text-gray-500 text-lg xl:text-xl  dark:text-gray-300">
                                                            {dashboard.statistics.weekly}
                                                        </div>
                                                    </div>
                                                </div>
                                                <ChartHarmony options={chartData}  apiUrl={''} className={''} style={{ width: '100%', height: '350px' }} />
                                            </div>
                                        </div>
                                    </div>
                            <div>
                                <div className="-intro-x flex items-center justify-between ">
                                    <h2 className="text-base dark:text-gray-300">
                                        {t("LatestCustomersWebsites")}
                                    </h2>
                                    <Link to="/CRM/User/List" className="text-themeInformation dark:text-themeDarkInformation"> ({t("viewing")})</Link>
                                </div>
                                <div className={"height_32rem overflow-y-auto scrollbar-hidden py-3"}>
                                    <div class={`grid gap-2 grid-cols-1 ${classesToggled ? 'xl:grid-cols-2' : 'lg:grid-cols-2'}`}>
                                        <div class="box dark:bg-themeDarkSalt700 bg-white relative flex items-center p-3 gap-3 -intro-x">
                                            <div class="size-12 flex-shrink-0 bg-white dark:bg-themeDarkSalt700 border shadow rounded-full">
                                                <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                            </div>
                                            <div class="flex-1 flex justify-between">
                                                <div>
                                                    <div>
                                                        <p className="dark:text-gray-300">masoud ahmadi<span className={"text-xs"}></span></p>
                                                    </div>
                                                    <div class="text-xs line-clamp-1 text-gray-500">
                                                        <div class="text-xs dark:text-gray-300 font-light">
                                                            کاربر سایت
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"flex gap-1 items-center"}>
                                                    <Link to={"#"} className={"tooltip_class_generalManager"}>
                                                        <HPrimeIcon icon={"pi-users"} iconClass={"dark:text-themeDarkInformation"} />
                                                        <Tooltip anchorSelect={".tooltip_class_generalManager"} content={t("generalManager")} />
                                                    </Link>
                                                    <Link to={`/Support/OnlineSupport?userId=`} className={"tooltip_class_Conversations"}>
                                                        <HPrimeIcon icon={"pi-comment"} iconClass={"dark:text-themeDarkInformation"}/>
                                                        <Tooltip anchorSelect={".tooltip_class_Conversations"} content={t("Conversations")} />
                                                    </Link>
                                                    <Link to={`/Support/Ticket/List?userId=`} className={"tooltip_class_Tickets"}>
                                                        <HPrimeIcon icon={"pi-ticket"} iconClass={"dark:text-themeDarkInformation"}/>
                                                        <Tooltip anchorSelect={".tooltip_class_Tickets"} content={t("Tickets")} />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={`grid grid-cols-2 md:grid-cols-3  gap-y-5 gap-1 ${classesToggled ? 'xl:grid-cols-6' : ''}`}>
                                <Sale
                                    title={'totalsales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-wallet'}
                                    data={dashboard.sales.totalSales}
                                />
                                <Sale
                                    title={'Thisyearsales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-calendar-clock'}
                                    data={dashboard.sales.currentYearSales}
                                />
                                <Sale
                                    title={'Quarterlysales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-calendar-clock'}
                                    data={dashboard.sales.threeMonthsSales}
                                />
                                <Sale
                                    title={'Currentmonthsales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-calendar-clock'}
                                    data={dashboard.sales.currentMonthSales}
                                />
                                <Sale
                                    title={'Thisweeksales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-calendar-clock'}
                                    data={dashboard.sales.currentWeekSales}
                                />
                                <Sale
                                    title={'TodaySales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-calendar-clock'}
                                    data={dashboard.sales.todaySales}
                                />
                            </div>
                        </div>
                        <div>
                            <EntryAndExitListPersonnel
                                rowData={dashboard.SalesData}
                                GetCurrencyType={GetCurrencyType}
                            />
                        </div>
                        <div >
                            <DashboardProjects
                            />
                        </div>
                    </div>
                </div>
                <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("Notes")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            {
                                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                    <UserNote
                                        key={index}
                                        fullName={item.fullName}
                                        id={item.id}
                                        message={item.message}
                                        createdAt={item.createdAt}
                                    />
                                ))
                            }
                            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("UserComments")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 dashboard-note height_21rem overflow-y-auto scrollbar-hidden "}>
                                {
                                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                        <UserComment
                                            key={index}
                                            fullName={item.fullName}
                                            id={item.id}
                                            message={item.message}
                                            createdAt={item.createdAt}
                                            state={item.state}
                                        />
                                    ))
                                }
                            </div>
                            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-between items-center gap-2">
                            <p className={"text-lg dark:text-gray-300"}> {t("ReleaseQueue")}</p>
                            <span className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                    <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                    </div>
                                    <div class="flex-1">
                                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("Draft")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}