import Joi from "joi";
export const dataHighlightSchema = Joi.object({
    title: Joi.string().required().messages({
        'any.required': 'عنوان  الزامیست',
        'string.empty': 'عنوان  الزامیست',
        'string.base': 'عنوان   الزامیست ',
    }),
    data: Joi.string().required().messages({
        'any.required': 'متن کد الزامیست',
        'string.empty': 'متن کد الزامیست',
        'string.base': 'متن کد الزامیست ' ,
    }),
});