import Joi from "joi";
export const surveySchema = Joi.object({
    title: Joi.string().required().trim().messages({
        'any.required': 'عنوان نظرسنجی الزامیست',
        'string.empty': 'عنوان نظرسنجی الزامیست',
        'string.base': 'عنوان نظرسنجی الزامیست'
    }),
    slug: Joi.string().required().trim().messages({
        'any.required': 'ادرس نظرسنجی الزامیست',
        'string.empty': 'ادرس نظرسنجی الزامیست',
        'string.base': 'ادرس نظرسنجی الزامیست'
    }),
});
