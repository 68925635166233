import React from "react";


import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "./HPrimeIcon";
import {HPrimeIconSmall} from "./HPrimeIconSmall";



export const  ModalFileUpload = ({ icon, onChange, classStyle, functionClass, title, accept, multiple, type, id }) => {
    const { t } = useTranslation();


    return (
        <>
            <label htmlFor={id} className={`Modal_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-2 flex-col min-w-[6.5rem] min-h-[6.5rem] justify-center ${functionClass} ${!classStyle ? 'bg-themeInformation dark:bg-themeDarkInformation text-white' : classStyle}`}>
                <HPrimeIconSmall
                    icon={icon ? icon : "pi-upload"}
                />
                <span className={"text-xs"}>
                   {title ? title : t("Upload")}
                </span>

              <input
                  multiple={multiple}
                  type={type}
                  id={id}
                  onChange={onChange}
                  accept={accept}
                  className="hidden"
                  />
            </label>
        </>

    );
};