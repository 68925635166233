import Joi from "joi";

export const addDiscountSchema = Joi.object({
    caption: Joi.string().required().messages({
        'any.required': 'عنوان تخفیف الزامیست',
        'string.empty': 'عنوان تخفیف الزامیست',
        'string.base': 'عنوان تخفیف الزامیست',
    }),
    code: Joi.string().required().messages({
        'any.required': 'کد تخفیف الزامیست',
        'string.empty': 'کد تخفیف الزامیست',
        'string.base': 'کد تخفیف الزامیست',
    }),
});