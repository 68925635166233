import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useCustomTabs=()=>{
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("content");
    const handleChangeTabId = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = [
        {
            id: "content",
            label: t("Contents"),
            icon: "pi-file-edit",
        },
        {
            id: "link",
            label: t("Links"),
            icon: "pi-link",
        },
        {
            id: "file",
            label: t("File"),
            icon: "pi-paperclip",
        },
    ];

    return {tabs,activeTab,setActiveTab,handleChangeTabId}
}