export const convertInsuranceData = (obj) => {
    const insurance = {
        userId: obj.userId ?? null,
        insuranceType: obj.insuranceType === null || obj.insuranceType === undefined?null:obj.insuranceType.value,
        startDate: obj.startDate === null || obj.startDate === undefined?null:obj.startDate,
        endDate: obj.endDate === null || obj.endDate === undefined?null:obj.endDate,
        description: obj.description === null || obj.description === undefined || obj.description === ''?null:obj.description,
        insurancePosition: obj.insurancePosition === null || obj.insurancePosition === undefined || obj.insurancePosition === ''?null:obj.insurancePosition,
        insuranceNumber: obj.insuranceNumber === null                                  || obj.insuranceNumber === undefined || obj.insuranceNumber === ''?null:obj.insuranceNumber
    };
    return insurance;
};

export const convertInsurancePutData = (obj) => {
    const insurance = {
        insuranceId:obj.insuranceId ?? null,
        insuranceType: obj.insuranceType === null || obj.insuranceType === undefined?null:obj.insuranceType.value,
        startDate: obj.startDate === null || obj.startDate === undefined?null:obj.startDate,
        endDate: obj.endDate === null || obj.endDate === undefined?null:obj.endDate,
        description: obj.description === null || obj.description === undefined || obj.description === ''?null:obj.description,
        insurancePosition: obj.insurancePosition === null || obj.insurancePosition === undefined || obj.insurancePosition === ''?null:obj.insurancePosition,
        insuranceNumber: obj.insuranceNumber === null || obj.insuranceNumber === undefined || obj.insuranceNumber === ''?null:obj.insuranceNumber
    };
    return insurance;
};