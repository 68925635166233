import React, {useMemo, useState} from "react";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {GridTable} from "../../Commans/Grid/GridTable";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {useNavigate, useParams} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {useInformationGrid} from "./useInformationGrid";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import Swal from "sweetalert2";
import {DELETE} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {Export} from "../../Commans/Grid/Export";

export const HandleInformationListForm = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const {formId}=useParams();
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }




    const {autoGroupColumnDef,switchFilters, handleSwitchFilters,columnDefs,defaultColDef,filter,setFilter,getRowHeight,handleCurrentPage,handleNextPage,handlePreviousPage,handleSearch,handlePageSizeCount,handleResetFilters,rowData,pagination}=useInformationGrid(showSpinner,hideSpinner,refresh,Refresh);


    const handleDeleteFormDataGroup = async ()=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Forms/${formId}/DataGroups`,);
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    return(
        <>
            <div class="space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                                <InputText className="input w-full"/>
                            </div>
                        <ButtonSearchList
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Form/create`)}
                                            functionClass={"harmony-creation"}
                                            tooltipTitle={t("CreatingForm")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Form/RoadMap`)}
                                            tooltipTitle={t("FormWorkflow")}
                                            title={t("Workflow")}
                                            icon={"pi-sync"}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Form/Organize`)}
                                            tooltipTitle={t("OrganizeForms")}
                                            title={t("Organize")}
                                            icon={"pi-sort-alpha-down"}
                                        />

                                        <ButtonPlus
                                            onClick={()=>handleDeleteFormDataGroup()}
                                            tooltipTitle={t("DeleteAll")}
                                            title={t('DeleteAll')}
                                            icon={'pi-trash'}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchFilters} OnValueSelection={handleSwitchFilters} />
                                    </>
                                }
                    />


                </div>
                <div className={"H-body-ag-grid space-y-2 "}>
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export/>

            </div>
            {/*<FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >*/}
            {/*    <div className="grid grid-cols-3 md:grid-cols-4 gap-3">*/}
            {/*        <ModalButtonOperations*/}
            {/*            onClick={()=>redirectTo(`/Form/List/Information`)}*/}
            {/*            title={t("information")}*/}
            {/*            icon={"database"}*/}
            {/*        />*/}
            {/*        <ModalButtonOperations*/}
            {/*            onClick={()=>redirectTo(`/Form/List/DataEntrance`)}*/}
            {/*            title={t("InputData")}*/}
            {/*            icon={"download-cloud"}*/}
            {/*        />*/}
            {/*        <ModalButtonOperations*/}
            {/*            onClick={"#"}*/}
            {/*            title={t("ExportExcel")}*/}
            {/*            icon={"upload"}*/}
            {/*        />*/}
            {/*        <ModalButtonOperations*/}
            {/*            onClick={"#"}*/}
            {/*            title={t("ExportWord")}*/}
            {/*            icon={"upload"}*/}
            {/*        />*/}
            {/*        <ModalButtonOperations*/}
            {/*            onClick={"#"}*/}
            {/*            title={t("ExportPDF")}*/}
            {/*            icon={"upload"}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</FantasyModal>*/}


        </>
    )
};
