import React, {useEffect, useState} from "react";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

export const useUserGrid=(refresh,Refresh,showSpinner,hideSpinner)=>{
    const { t } = useTranslation();
    const [rowData, setRowData] = useState([]);

    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);



    const handleUserPin = async (userId)=>{
        try {
            showSpinner();
            const response = await PUT(`/Users/EditUserIsPin`,{id:userId});
            if(response.status === 200){
                Refresh()
            }else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner()
        }
    }
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };
    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const [filter,setFilter] = useState({search:'',GroupIds:[],ActivationStatus:null,RegisterFromDate:null,RegisterToDate:null,OneMonthToExpire:null,IncompleteCase:null,IsExpired:null,PhoneNoConfirmed:null,LegalEntity:null,NaturalEntity:null,RoleId:null,filterId:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:search}))
    }

    const handleSwitchFilters = (data, name) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: data ? data : null
        }));
    }

    const SwitchFilters = [
        {name:'OneMonthToExpire',lable:t("OneMonthToExpire"),value:filter.OneMonthToExpire},
        {name:'IsExpired',lable:t("IsExpired"),value:filter.IsExpired},
        {name:'IncompleteCase',lable:t("IncompleteCase"),value:filter.IncompleteCase},
        {name:'PhoneNoConfirmed',lable:t("PhoneNoConfirmed"),value:filter.PhoneNoConfirmed},
        {name:'NaturalEntity',lable:t("NaturalEntity"),value:filter.NaturalEntity},
        {name:'LegalEntity',lable:t("LegalEntity"),value:filter.LegalEntity},
    ]

    const handleResetFilters = () => {
        setFilter(prevFilter => ({
            ...prevFilter,
            search: '',
            RegisterFromDate: null,
            RegisterToDate: null,
            PhoneNoConfirmed: null,
            IsExpired: null,
            IncompleteCase: null,
            LegalEntity: null,
            NaturalEntity: null,
            OneMonthToExpire: null,  // Explicitly resetting switch filters
            filterId: null,  // Reset filterId
            GroupIds: [],     // Reset GroupIds
            RoleId: null,     // Reset RoleId
            ActivationStatus: null, // Reset ActivationStatus
        }));
        setSearch(''); // Reset search state as well
    }

    const handleSelectFilterUserGroupIds = (data)=>{
        // if(!data){
        //     return;
        // }
        // const userGroupIds = data.map(groupId => groupId.value).join('&');
        setFilter(({...filter,GroupIds: data}));
    }

    const handleSelectFilterValue = (data,name)=>{
        if(!data){
            return;
        }
        setFilter(({...filter,[name]:data}));
    }

    const handleRegisterToDateTime = (dateTime)=> setFilter((prevState)=>({...prevState,RegisterToDate: dateTime}));

    const handleRegisterFromDateTime = (dateTime)=> setFilter((prevState)=>({...prevState,RegisterFromDate: dateTime}));





    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Users`, {
                PageNumber: currentPage,
                PageSize: data.pageSize,
                Search: filter.search
                ,GroupIds:filter.GroupIds.length>0?filter.GroupIds.map(groupId => groupId.value).join('&'):filter.GroupIds
                ,InCompleteCase:filter.IncompleteCase
                ,IsExpired:filter.IsExpired
                ,LegalEntity:filter.LegalEntity
                ,NaturalEntity:filter.NaturalEntity
                ,RegisterToDate:filter.RegisterToDate
                ,RegisterFromDate:filter.RegisterFromDate
                ,OneMonthToExpire:filter.OneMonthToExpire
                ,PhoneNoConfirmed:filter.PhoneNoConfirmed
                ,ActivationStatus:filter.ActivationStatus !== null ? filter.ActivationStatus.value:filter.ActivationStatus
                , RoleId: filter.RoleId !== null?filter.RoleId.value:filter.RoleId
                ,FilterId:filter.filterId !== null?filter.filterId.value:filter.filterId
            }, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            hideSpinner()
        } finally {
            hideSpinner();
        }
    };

    useEffect(() => {
        OnGridReady();
    }, [refresh,filter.search,filter.filterId,filter.GroupIds,data.pageSize,currentPage,filter.IncompleteCase,filter.IsExpired,filter.NaturalEntity,filter.LegalEntity,filter.RegisterToDate,filter.RegisterFromDate,filter.OneMonthToExpire,filter.PhoneNoConfirmed,filter.ActivationStatus,filter.RoleId]);



    return {
        handleNextPage,
        handlePageSizeCount,
        handleCurrentPage,
        handlePreviousPage,
        handleUserPin,
        rowData,
        data,
        currentPage,
        handleRegisterToDateTime,
        handleSearch,
        search,
        setSearch,
        filter,
        setFilter,
        handleRegisterFromDateTime,
        handleSelectFilterValue,
        handleSelectFilterUserGroupIds,
        handleResetFilters,
        handleSwitchFilters,
        SwitchFilters
    }

}