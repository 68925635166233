import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import queryString from "query-string";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {useNavigate} from "react-router-dom";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";

import {useCustomDocument} from "./useCustomDocument";
import {DocumentAttach} from "./DocumentAttach";
import {handleCreateUserFormDataDocument} from "./userDocumentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";

export const HandleDocument = (props)=>{

    const { t } = useTranslation();
    const queryParams = queryString.parse(window.location.search);
    const [refresh,setRefresh] = useState(false);
    const [initialSource,setInitialSource]=useState({attachTypes:[]})
    const redirectTo = useNavigate();
    const [IsEdit,setIsEdit]=useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [concurrencyStamp,setConcurrencyStamp] = useState(null);

    const Refresh=()=>{
        setRefresh(prevState => !prevState)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const {triggerFileInput,setFiles,handleDeleteDocumentAttachment,deleteCard,files,addNewCard,handleFileChange,handleFieldChange,handleGetAllCards,handleInitialCards,totalSize}=useCustomDocument(initialSource,showSpinner,hideSpinner,Refresh)


    const handleInitialData=(attachTypes)=>{
        const attachTypesData = convertSelectDataHandler(attachTypes);
        setInitialSource({
            attachTypes:attachTypesData
        })
        return {attachTypesData}
    }
    const fetchData=async()=>{
        try {
            showSpinner();
            const responseAttach = await GET('/Enumerations/EnAttachmentType',{},'IEnumerable');
            if(responseAttach.status === 200){
                const {attachTypesData} = handleInitialData(responseAttach.data);

                if(files.length === 0){
                    handleInitialCards(attachTypesData);
                }
                hideSpinner()

            }
            if(queryParams.id){
                try {
                    setIsEdit(true);
                    const response = await GET(`/Users/Documents/${queryParams.id}`,{},'single');
                    if (response.status === 200) {
                        const {attachTypesData} = handleInitialData(responseAttach.data);
                        setConcurrencyStamp(response.data.concurrencyStamp)
                        if(response.data.documents.length === 0){
                            handleInitialCards(attachTypesData);
                        }
                        else{
                            const fileCards = handleGetAllCards(response.data.documents,attachTypesData);
                            setFiles(fileCards);
                        }
                    }
                }
                catch (e) {
                    hideSpinner();
                }
            }
        }
        catch (e) {
            hideSpinner();
        }
    }

    useEffect(()=>{
       fetchData()
    },[refresh])

    const handleUploadDocument=async()=>{
        try {
              showSpinner();
              const formData =  handleCreateUserFormDataDocument(queryParams.id,concurrencyStamp,files,IsEdit);
              const response= await POST(`/Users/Documents/Add`,formData,true);
              if(response.status === 200){
                hideSpinner();
                handleSuccessMessage(t('SuccessDone'));
                Refresh();
              }
              else{
                  handleApiError(response,hideSpinner);
              }
        }
        catch (e) {
            hideSpinner();
        }
    }





    return (
        <>

            <div className={"relative space-y-5"}>
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="space-y-3">
                    <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation flex items-center gap-2">
                        <h2 className={"dark:text-gray-300"}>
                            { t("DocumentUpload")}
                        </h2>
                    </div>
                    <div className={"px-2"}>
                        <DocumentAttach
                            data={files}
                            IsEdit={IsEdit}
                            showSpinner={showSpinner}
                            hideSpinner={hideSpinner}
                            triggerFileInput={triggerFileInput}
                            setCards={setFiles}
                            cards={files}
                            deleteCard={deleteCard}
                            addNewCard={addNewCard}
                            handleFieldChange={handleFieldChange}
                            handleFileChange={handleFileChange}
                            initialSource={initialSource}
                            handleDeleteDocumentAttachment={handleDeleteDocumentAttachment}
                            totalSize={totalSize}
                        />
                    </div>

                </div>
            </div>


            <div class=" flex flex-wrap items-center justify-center lg:justify-end gap-3">
                <button onClick={()=>props.handleTabClick("event")} className="button w-24  text-white bg-themeDanger">{t("previous")}</button>
                {/*<button onClick={()=>redirectTo('/CRM/User/List')} className="button  text-white bg-themeInformation dark:bg-themeDarkInformation">{t("finalRegistration")}</button>*/}
                <button onClick={()=>handleUploadDocument()} className="button  text-white bg-themeInformation dark:bg-themeDarkInformation">{t("DataRegistration")}</button>
            </div>

        </>
    )
}