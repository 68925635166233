import React, {useState} from "react";
export const useCustomUserBank=(initialSource)=>{

    const [bankList,setBankList] = useState([]);
    const [concurrencyStamp,setConcurrencyStamp] = useState(null);
    const handleAddRow = () => {
        const newRow = {
            id: new Date().getTime(),
            userBankId: null, // Set bankId to null initially
            bankId:initialSource.Banks[0],
            accountNumber: null,
            cardNumber: null,
            shebaNumber: null,
            isDefault: bankList.length === 0,
        };

        setBankList((prevList) => [...prevList, newRow]);
    };

    const handleRemoveRow = (id, userBankId) => {
        if (bankList.length === 1) {
            const newRow = {
                id: new Date().getTime(),
                userBankId: null, // Set bankId to null initially
                bankId:initialSource.Banks[0],
                accountNumber: '',
                cardNumber: '',
                shebaNumber: '',
                isDefault:false,
            };
            setBankList((prevList) => [newRow]);
            return;
        }
        setBankList((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.userBankId === userBankId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };


    const handleChange = (id, field, value, userBankId) => {
        setBankList((prevList) => {
            const updatedList = prevList.map((row) => {
                if ((userBankId && row.userBankId === userBankId) || (!userBankId && id && row.id === id)) {
                    // If the current row is being updated
                    if (field === 'isDefault' && value === true) {
                        // Set isDefault to true only for the current row and false for others
                        return { ...row, [field]: true };
                    } else {
                        return { ...row, [field]: value };
                    }
                }
                // If it's not the current row, reset isDefault to false
                //return { ...row, isDefault: false };
                return { ...row };
            });

            return updatedList;
        });
    };

return {
    bankList,
    setBankList,
    handleChange,
    handleAddRow,
    handleRemoveRow,
    concurrencyStamp,
    setConcurrencyStamp
}
}