import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useHelp=(setErrors)=>{
    const [help,setHelp] = useState({harmonyHelpId:null,image:null,title:null,slug:null,description:null,content:null,video:null,videoUrl:null,document:null,keywords:[],imagePath:null,isInternal:false,documentUrl:null})
    const { t } = useTranslation();
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setHelp({...help,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleChangeToggle=(name,value)=>{
        if(value === true){
            setHelp({...help,[name]:value,videoUrl: '',documentUrl: ''});
        }
        else{
            setHelp({...help,[name]:value,video: null,document: null});
        }
    }
    const handleKeywordsChange = (keywords) => {
        setHelp((prevState) => ({ ...prevState,keywords: keywords }));
    };
    const handleRemoveImagePath=(path,name)=>setHelp((prevState)=>({...prevState,[name]:path}))
    const handleResetImages = () => {
        setHelp((prevState)=>({...prevState,image: null, imagePath: null}));
    }
    const handleChangeImage =(data,name)=>{
        setHelp((prevState) => ({ ...prevState, [name]: data }));
    }

    const handleChangeContent=(content)=>{
        setHelp({...help,content: content});
    }
    const handleSelectValue=(data,name)=>{
        setHelp({ ...help, [name]: data });
    }
    const handleFileChange = (event,name) => {
        const file = event.target.files[0];
        setHelp({...help,[name]: file});
    };
    const displayFile=(filePath)=>{
        window.open(filePath);
    }

    return {
        handleSelectValue,
        handleValue,
        handleKeywordsChange,
        help,
        setHelp,
        handleChangeContent,
        handleRemoveImagePath,
        handleChangeImage,
        handleResetImages,
        handleChangeToggle,
        handleFileChange,
        displayFile
    }
}