import React, {useEffect, useState} from "react";

import {Link} from "react-router-dom";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";


// this is a compoent in dashboard (گزارشات کلی) it renders for 4 times with diffrent or same urls .
// containing couple of props which we pass to it from  dashboard or we can do that straightly right from api .
export const GeneralReport=(props)=>{

    const {temppercentage, iconName,lable,tempval,apiUrl ,className,to} = props;
   // const [data,SetData] = useState(null);
   // const {value,percentage} = data;

    // we should use an api function and then populate our data using its response .
    const handleData = ()=>{

    }
    // after every render , it calls the api .
    useEffect(()=>{
        handleData();
    })


    return (

        <div className="col-span-12 sm:col-span-6 xl:col-span-3">
            <div className="report-box zoom-in ">
                <Link to={to} className="box bg-white p-5 dark:bg-themeDarkSalt700 block">
                    <div className="flex justify-between">
                        <div>
                            <div className={`report-box__indicator gap-2  ${temppercentage > 10?" bg-themeSuccess ":"bg-themeDanger"} tooltip cursor-pointer`}
                                title="2% Lower than last month"> {temppercentage + '%'}
                                <HPrimeIcon icon="pi-chevron-up"/>
                            </div>
                        </div>
                        <div>
                            <HPrimeIcon icon={iconName} iconClass={className}/>
                        </div>
                    </div>
                    <div className="text-3xl font-bold leading-8 mt-6 dark:text-gray-300">{tempval}</div>
                    <div className="font-bold text-gray-500 mt-1 dark:text-gray-300">{lable}</div>
                </Link>
            </div>
        </div>

    )
}