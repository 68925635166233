import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const MultiSelectElement=({index,item,handleChangeData})=>{
    const { t } = useTranslation();


    return (
        <>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <div className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
                <Select
                    options={item.properties.items}
                    onChange={(data)=>handleChangeData(index,'items',data)}
                    placeholder={t("selection")}
                    className={"w-full"}
                    isMulti={true}
                    menuPosition={"fixed"}
                    value={item?.data?.items}
                    classNamePrefix={"h_multi_select"}
                />
            </div>

        </>
    )
}