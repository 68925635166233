import {Image} from "primereact/image";

import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import React, {useEffect, useMemo, useState} from "react";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../Contents/ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {GridButtonOprations} from "../../../Commans/Grid/GridButtonOprations";

export const useGrid=(setSpinner,result,setResult,onApiError)=>{

    const{t}= useTranslation();
    const redirectTo = useNavigate();
    const [IsOpen,setIsOpen] = useState(false);
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [colId,setColId]=useState(null);
    const [pollId,setPollId] = useState('');
    const handleIsOpen=(pollId)=>{
        setPollId(pollId);
        setIsOpen(true);
    }
    const handleCloseIsOpen=()=>{
        setIsOpen(false);
    }

    const handleChangeState = async(surveyGroupId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            setColId(surveyGroupId);
            const response = await PUT(`/Surveys/SurveyGroup/StateShow`,{surveyGroupId:surveyGroupId},false);
            if (response.status === 200) {
                setWait(false)
                setResult({...response.status})
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            onApiError();
        }
    }
    const handleRemoveSurveyGroup = async (surveyGroupId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {

                    setSpinner(true)
                    const response = await DELETE(`/SurveyGroups/${surveyGroupId}`);
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("TitleSurvey"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.title}
                </>

        },
        { headerName: t("NumberofParticipants"), field: 'orderReciever',  maxWidth: 170,
            cellRenderer:parameters=>
                <>
                  {parameters.data.attendeesNumber}
                </>
        },



        { headerName: t("Status"),field: 'stateShow', maxWidth: 115, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && colId === params.data.id ? (
                        <TinyLoading   />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeState(params.data.id,params.colDef.headerName)}
                            icon={"pi-check-square"}
                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeState(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>
        },






        { headerName: t("FromDate"), maxWidth: 140,field: 'fromDate',cellRenderer:params => {
                return <GridDateFomartter date={params.data.fromDate} hasTime={false} />
            }},
        { headerName: t("ToDate"), maxWidth: 140,field: 'toDate',cellRenderer:params => {
                return <GridDateFomartter date={params.data.toDate} hasTime={false} />
            }},


        { headerName: t("operations"), maxWidth: 140,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button type={"button"} className="tooltip_class_answer text-themeInformation dark:text-themeDarkInformation  relative" onClick={()=>redirectTo(`/CMS/Surveys/List/DataSource?surveyGroupId=${params.data.id}`)}>
                        <HPrimeIcon icon={'pi-star'}  />
                        <Tooltip anchorSelect={".tooltip_class_answer"} content={t("AnswerToTheSurvey")} positionStrategy={"fixed"}	 />
                    </button>
                    <GridButtonEdit
                        onClick={()=>redirectTo(`/CMS/Surveys/Add?surveyGroupId=${params.data.id}`)}
                    />
                    <GridButtonDelete
                        onClick={async()=>await handleRemoveSurveyGroup(params.data.id)}
                    />

                    <GridButtonOprations
                        onClick={()=>handleIsOpen(params.data.id)}
                    />
                </div>
            }

        },

    ];




    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);



    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };


    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,

        };
    }, []);



    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',fromDate:null,toDate:null,isActive:null,isDeActive:null,hasCycle:null,isExpired:null,hasStatistics:null,hasIpLimitation:null});
    const [search,setSearch]= useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:search}));
    }
    const handleFromDateTime=(dateTime)=> setFilter((prevState)=>({...prevState,fromDate:dateTime}));
    const handleToDateTime=(dateTime)=>setFilter((prevState)=>({...prevState,toDate:dateTime}));


    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const resetFilters = ()=>{
        setFilter(({...filter,search:'',fromDate:null,toDate:null,isActive:null,isDeActive:null,hasCycle:null,isExpired:null,hasStatistics:null,hasIpLimitation:null}));
        setSearch('');
    }






    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                   //
                }
                else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [result, currentPage, filter.search, data.pageSize,filter.toDate, filter.isExpired,filter.isActive,filter.isDeActive,filter.fromDate,filter.hasStatistics,filter.hasIpLimitation,filter.hasCycle]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/Surveys/GetAllSurveysGroups`, {Search:search,PageSize:data.pageSize,PageNumber:currentPage,FromDate:filter.fromDate,ToDate:filter.toDate,IsActive:filter.isActive,IsDeActive:filter.isDeActive,IsExpired:filter.isExpired,HasCycle:filter.hasCycle,HasStatistics:filter.hasStatistics,HasIpLimitation:filter.hasIpLimitation}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };



    const switchData = [
        {name:'isActive',lable:t('isActive'),value:filter.isActive},
        {name:'isDeActive',lable:t('isNotActive'),value:filter.isDeActive},
        {name:'isExpired',lable:t('expired'),value:filter.isExpired},
        {name:'hasStatistics',lable:t('hasStatistics'),value:filter.hasStatistics},
        {name:'hasCycle',lable:t('hasCycle'),value:filter.hasCycle},
        {name:'hasIpLimitation',lable:t('hasIpLimitation'),value:filter.hasIpLimitation},
    ]

    return {
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        data,
        setData,
        currentPage,
        setCurrentPage,
        handlePreviousPage,
        handleCurrentPage,
        handlePageSizeCount,
        handleNextPage,
        handleSearch,
        filterValueHandler,
        resetFilters,
        rowData,
        switchData,
        filter,
        search,
        setSearch,
        setFilter,
        IsOpen,
        handleCloseIsOpen,
        handleIsOpen,
        handleFromDateTime,
        handleToDateTime,
        pollId
    };
}