
export const handleCreateOrganizationGroup = (obj) => {

    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('caption', obj.caption );
    appendIfNotNull('rank',obj.rank );
    appendIfNotNull('iconPath', obj.iconPath);

    if (obj.icon) {
        appendIfNotNull('icon',obj.icon);
    }
    return formData;
};



export const handleEditOrganizationGroup = (obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('LinkOrganizationGroupId', obj.linkOrganizationGroupId );
    appendIfNotNull('caption', obj.caption );
    appendIfNotNull('rank',obj.rank );
    appendIfNotNull('iconPath', obj.iconPath);

    if (obj.icon) {
        appendIfNotNull('icon',obj.icon);
    }
    return formData;
    return formData;
}

