import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {
    useWithdrawlRequestGrid
} from "../../../Commans/Profile/WalletCharge/withdrawalRequests/useWithdrawlRequestGrid";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {UnconfirmedRequestItem} from "./UnconfirmedRequestItem";
import {useCustomUnconfirmedGrid} from "./useCustomUnconfirmedGrid";
import {NoDataHarmony} from "../../../Commans/NoDataHarmony";

export const UnConfirmedRequest=()=>{
    const { t } = useTranslation();
    const [refresh,setRefresh] = useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({currencies:[],paymentStates:[]})
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const handleInitialSource=(currencies,paymentStates)=>{
        const currencyData = convertSelectDataHandler(currencies);
        const paymentStateData = convertSelectDataHandler(paymentStates);
        setInitialSource({
            currencies: currencyData,
            paymentStates: paymentStateData,
        })
    }
    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
            const responsePaymentState = await GET(`/Enumerations/EnPaymentStatus`,{},'IEnumerable');

            if(response.status === 200 && responsePaymentState.status === 200 ){
                handleInitialSource(response.data,responsePaymentState.data);
                hideSpinner();
            }
            else {
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[refresh])

    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }

    const GetPaymentState = (paymentStatus) => {
        if (!initialSource.paymentStates) {
            return;
        }
        const data = initialSource.paymentStates.find((item) => item.value === paymentStatus);
        return data ? data.label : '';
    }




    const {rowData,pagination,getRowHeight,autoGroupColumnDef,defaultColDef,handleNextPage,handlePreviousPage,handleCurrentPage,handlePageSizeCount} = useCustomUnconfirmedGrid(showSpinner,hideSpinner,refresh);

    return (
        <>
            <div className={'relative'}>
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }


                {rowData.length > 0 ? rowData.map((item, index) => (
                    <UnconfirmedRequestItem key={index} item={item} GetPaymentState={GetPaymentState}   GetCurrencyType={GetCurrencyType} showSpinner={showSpinner} hideSpinner={hideSpinner} Refresh={Refresh} />
                )):

                <NoDataHarmony/>

                }
            </div>
            {
                rowData.length !== 0 ?
            <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    :
                    null
            }

            <ToastContainer/>
        </>
    )
}