import React, {useState} from "react";

export const useNotifications=(initialSource)=>{
    const [notifications,setNotifications]=useState([])
    const handleFieldChange = (index, field, value) => {
        const updatedNotifications = [...notifications];
        updatedNotifications[index][field] = value;
        setNotifications(updatedNotifications);
    };
    const handleDeleteNotification = (index) => {
        if (notifications.length === 1) {
            handleInitialNotifications(initialSource.patterns)
            return;
        }
        const updatedNotifications = notifications.filter((_, idx) => idx !== index);
        setNotifications(updatedNotifications);
    };

    const handleAddPattern = () => {
        setNotifications([
            ...notifications,
            {
                notificationId: null,
                patternId: initialSource.patterns[0],
                event: initialSource.events[0],
                stateShow:false,
            },
        ]);
    };

    const handleInitialNotifications=(patterns,events)=>{

        setNotifications([
            {
                notificationId: null,
                patternId:patterns[0],
                event:events[0],
                stateShow:false,
            },
        ]);

    }

    return  {
        handleFieldChange,
        handleInitialNotifications,
        handleAddPattern,
        handleDeleteNotification,
        notifications,
        setNotifications
    }

}