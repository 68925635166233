import React, {useEffect, useState} from "react";
import {TabMenu} from "../../../Commans/UIParts/TabMenu";
import {useNavigate} from "react-router-dom";


import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";

import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";

import {Roles} from "./Roles";
import {Access} from "./Access";


export const HandleUserAccess = () => {
    const [activeTab, setActiveTab] = useState("tab_one");
    const {t} = useTranslation();
    const redirectTo = useNavigate();

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = [
        {
            id: "tab_one",
            subLabel:t("Role"),
            icon: "pi-star",
        },
        {
            id: "tab_two",
            subLabel:t("SpecialAccess"),
            icon: "pi-th-large",
        },
    ];







    return(
        <>
            <div className=" space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/Add`)}

                                            tooltipTitle={t("UsersRegistration")}
                                            title={t("personnel")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/List`)}
                                            icon={"pi-list"}
                                            title={t("list")}
                                            tooltipTitle={t("ListOfPersons")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/Group`)}
                                            icon={"pi-users"}
                                            title={t("grouping")}
                                            tooltipTitle={t("UsersGrouping")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/Role`)}
                                            icon={"pi-star"}
                                            title={t("CreatingRole")}
                                            tooltipTitle={t("CreatingRole")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/Reminder/List`)}
                                            icon={"pi-clock"}
                                            title={t("Reminders")}
                                            tooltipTitle={t("Reminders")}

                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                <div class="post box bg-white dark:bg-themeDarkSalt700">
                    <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                    <div class="post__content tab-content p-2">
                        {
                            activeTab === "tab_one"?
                                <div className={`tab-content__pane space-y-5 tab_Special_Access ${ activeTab === "tab_one" ? "active" : "" }`}  id="tab_one">

                                    <Roles/>

                                </div>
                                :
                                <div className={`tab-content__pane space-y-5 ${ activeTab === "tab_two" ? "active" : "" }`}  id="tab_two">

                                    <Access/>

                                </div>

                        }



                    </div>

                </div>
            </div>



        </>


    )
}