
import React, {useEffect, useState} from "react";
import DatePicker, {DateObject} from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import arabic_ar from "react-date-object/locales/arabic_ar";
import indian_hi from "react-date-object/locales/indian_hi";
import persian_fa from "react-date-object/locales/persian_fa";
import arabic from "react-date-object/calendars/arabic";
import persian from "react-date-object/calendars/persian";
import indian from "react-date-object/calendars/indian";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const DateTimeElement = ({ itemCount,handleDefaultValueChange,SortDescending,SortAccending,index, element, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement }) =>{

    const handleCalendarView=()=>{
        switch (element.properties.calendarView?.value) {
            case 0:
                return false
            case 1 :
                return true
            case 2:
                return true
        }
    }

    const handleCalendarLanguage=()=>{
        switch (element.properties.calendarLanguage?.value) {
            case 0:
                return null
            case 1 :
                return arabic_ar
            case 2:
                return indian_hi
            case 3 :
                return  persian_fa
        }
    }
    const handleCalendarType=()=>{
        switch (element.properties.calendarType?.value) {
            case 0:
                return persian
            case 1 :
                return arabic
            case 2:
                return null
            case 3:
                return indian
        }
    }




    return (
        <>
            <>

                <div onClick={() => handleSelectTabAfterElementSelection(index)}>
                    <DatePicker
                        calendar={handleCalendarType()}
                        locale={handleCalendarLanguage()}
                        editable={handleCalendarView()}
                        format={element.properties.format}
                        name={element.properties.name}
                        className={`harmony-input harmony-w-full ${index === selectedElementIndex ? 'border-themeInformation' : ''}`}
                        value={element.properties?.defaultValue?.value}
                        disabled={element.properties.isReadOnly}
                        placeholder={element?.properties?.description}
                        id={element.properties.id}
                        buttons={false}
                        type={'Calendar'}
                        onChange={(value) => handleDefaultValueChange(index, 'value', value)}
                        plugins={[
                            <TimePicker />
                        ]}
                        //zIndex={props.zIndex}
                    />
                </div>

                <div className={"harmony_body_button"}>
                    <button
                        type="button"
                        className="button bg-themeDanger text-white harmony_button_form"
                        onClick={() => deleteElement(index)}
                    >
                        <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
                    </button>

                    {
                        index===0?null:
                            <button
                                type="button"
                                className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                                onClick={() => SortAccending(index)}

                            >
                                <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                            </button>

                    }


                    {
                        index <itemCount - 1?
                            <button
                                type="button"
                                className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                                onClick={() => SortDescending(index)}

                            >
                                <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                            </button>:
                            null
                    }

                </div>

            </>

        </>
    )
}

