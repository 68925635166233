import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {Button} from "primereact/button";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {Accordion, AccordionTab} from "primereact/accordion";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {UserFastRegister} from "../../Commans/Globals/UserFastRegister";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../../Commans/Grid/GridButtonOprations";

export const HandleBasketProduct=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();

    const [modal, setModal] = useState({ invoiceAdd:false,fastRegister:false });

    const invoiceAddOnHide = () => {
        setModal((prevState) => ({ ...prevState, invoiceAdd: !modal.invoiceAdd }));

    };

    const fastRegisterOnHide = () => {
        setModal((prevState) => ({ ...prevState, fastRegister: !modal.fastRegister }));
    };



    const [isMenuOpen,setMenuOpen] = useState(false);
    const [userId,setUserId] = useState('');
    const handleOpenMenu = () => {
        setMenuOpen(true);

    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }


    const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("CreateFile"), maxWidth: 120,
            cellRenderer:parameters=>
              <div>
                  <HPrimeIcon icon={'pi-check-square'} iconClass="text-themeSuccess"/>
              </div>
        },
        { headerName: t("Orderer"),minWidth: 160,
            cellRenderer:parameters=>
                <>مسعود احمدی محمد آبادی</>
        },

        { headerName: t("ProductName"), minWidth: 180,
            cellRenderer:parameters=>
                <>خودکار محصوص آبی بیک</>
        },
        { headerName: t("count"), minWidth: 100,
            cellRenderer:parameters=>
                <>20</>
        },
        { headerName: t("TrackingCode"), minWidth: 160,
            cellRenderer:parameters=>
                <>100000004050</>
        },
        { headerName: t("TotalAmount"),minWidth: 170,
            cellRenderer:parameters=>
                <>2.000.000.000 تومان</>
        },
        { headerName: t("UserDescriptions"),minWidth: 170,
            cellRenderer:parameters=>
                <>پرداخت تمدید سالانه</>
        },

        { headerName: t("OnlinePayment"), maxWidth: 130,
            cellRenderer:parameters=>
                <div>
                    <HPrimeIcon icon={'pi-check-square'} iconClass="text-themeSuccess"/>
                </div>
        },
        { headerName: t("PackageDispatch"), maxWidth: 130,

            cellRenderer:parameters=>
                <div>
                    <HPrimeIcon icon={'pi-check-square'} iconClass="text-themeSuccess"/>
                </div>

        },
        { headerName: t("settings"), maxWidth: 120,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>invoiceAddOnHide()}
                    />
                    <GridButtonDelete/>
                    <GridButtonOprations
                        onClick={()=>handleOpenMenu()}
                    />
                </div>
            }

        },

    ];

    const rowData = [

        { id:1, orderReciever: 'Camry',date:'1402/10/3',code:'84732085', Totalprice: 25000,comment:'راضی ام'},
        { id:2, orderReciever: 'Camry',date:'1402/10/3',code:'84732085', Totalprice: 25000,comment:'راضی ام'},
        { id:3, orderReciever: 'Camry',date:'1402/10/3',code:'40000', Totalprice: 25000,comment:'راضی ام'},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };



    return(
        <>
            <div class=" space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByNameProductCode")}</label>
                                <InputText className="input w-full" placeholder={t("search")}/>
                            </div>
                        <ButtonSearchList
                        />

                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>invoiceAddOnHide()}
                                            tooltipTitle={t("CreateInvoice")}

                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <div className=" space-y-2 flex flex-col items-center">
                                            <lable className="text-xs dark:text-gray-300">{t("SuccessfulPayment")}</lable>
                                            <input className="input input--switch " type="checkbox"/>
                                        </div>
                                        <div class=" space-y-2 flex flex-col items-center">
                                            <lable className="text-xs dark:text-gray-300">{t("UnsuccessfulPayment")}</lable>
                                            <input className="input input--switch " type="checkbox"/>
                                        </div>
                                        <div class=" space-y-2 flex flex-col items-center">
                                            <lable className="text-xs dark:text-gray-300">{t("TodaySales")}</lable>
                                            <input className="input input--switch " type="checkbox"/>
                                        </div>
                                        <div class="space-y-2 flex flex-col items-center">
                                            <lable className="text-xs dark:text-gray-300">{t("Sent")}</lable>
                                            <input className="input input--switch " type="checkbox"/>
                                        </div>
                                        <div class=" space-y-2 flex flex-col items-center">
                                            <lable className="text-xs dark:text-gray-300" >{t("NotSent")}</lable>
                                            <input className="input input--switch " type="checkbox"/>
                                        </div>
                                    </>
                                }
                    />


                </div>
                <div className={"space-y-2 H-body-ag-grid"}>
                    <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                               enableRtl={true}
                               pagination={true}
                               getRowHeight={getRowHeight}
                               onGridReady={onGridReady}
                               quickFilterText={quickFilterText}
                               style={{ width: '100%', height: '100%' ,position:'relative' }}
                    />
                </div>
            </div>
            <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu}>
                <div className=" grid grid-cols-3 md:grid-cols-4 gap-3">
                    <ModalButtonOperations
                        icon={"pi-file"}
                        title={t("InvoiceDetail")}
                        onClick={()=>redirectTo(`/Shop/Product/Cart/Items`)}
                    />
                    <ModalButtonOperations
                        icon={"pi-file-edit"}
                        title={t("Bill")}
                        onClick={()=>redirectTo(``)}
                    />

                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.invoiceAdd}  closeModal={invoiceAddOnHide} title={t("CreateInvoice")} customeClassName={"modal-x"}>
                <div class=" space-y-2">
                    <div class={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2"}>
                        <div class="space-y-2 ">
                            <div class="flex justify-between items-center">
                                <label className="text-xs dark:text-gray-300">{t("CustomerName")}</label>
                                <div className="text-themeInformation dark:text-themeDarkInformation text-xs cursor-pointer" onClick={()=>fastRegisterOnHide()}>({t("UserRegistration")})</div>
                            </div>
                            <Select
                                placeholder={t("selection")}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={'fixed'}

                            />
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("RequestStatus")}</label>
                            <Select
                                placeholder={t("selection")}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={'fixed'}
                            />
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("ShippingStatus")}</label>
                            <Select
                                placeholder={t("selection")}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={'fixed'}
                            />
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("PaymentType")}</label>
                            <Select
                                placeholder={t("selection")}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={'fixed'}
                            />
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("BankSelection")}</label>
                            <Select
                                placeholder={t("selection")}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={'fixed'}
                            />
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("OnlinePaymentAmount")}</label>
                            <InputText className="input w-full"/>
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("WalletDeduction")}</label>
                            <InputText className="input w-full"/>
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("DiscountCode")}</label>
                            <InputText className="input w-full"/>
                        </div>
                    </div>
                    <Accordion >
                        <AccordionTab
                            header={
                                <div>
                                    {t("Products")}
                                </div>
                            }

                        >

                            <div className="space-y-2">
                                <div className="p-2 space-y-5 ">
                                    <ul  className="flex gap-2">
                                        <li className="mt-auto">
                                            <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                                                1
                                            </div>
                                        </li>
                                        <li>
                                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                                                <div className="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("product")}</label>
                                                    <Select
                                                        placeholder={t("selection")}
                                                        className={"select-group"}
                                                        classNamePrefix={"h_multi_select"}
                                                        menuPosition={'fixed'}
                                                    />
                                                </div>
                                                <div className="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("Count")}</label>
                                                    <Select
                                                        placeholder={t("selection")}
                                                        className={"select-group"}
                                                        classNamePrefix={"h_multi_select"}
                                                        menuPosition={'fixed'}

                                                    />
                                                </div>
                                                <div className="flex items-end gap-2">
                                                    <div class="flex-1">
                                                        <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                                        <input type="text" className="input w-full" />
                                                    </div>
                                                    <div className="flex gap-2">
                                                        <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                                            <HPrimeIcon icon={'pi-plus'}  />
                                                        </button>
                                                        <button type="button" className="button small_button bg-themeDanger text-white "> <HPrimeIcon icon={'pi-minus'}  />
                                                        </button>

                                                        {/*{row.id === relations.length && (*/}
                                                        {/*   */}
                                                        {/*)}*/}

                                                    </div>
                                                </div>

                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </AccordionTab>

                    </Accordion>
                    <button type={"button"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("register")}</button>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.fastRegister}  closeModal={fastRegisterOnHide} title={t("UserRegistration")}>
                <div>
                   {/*<UserFastRegister/>*/}
                </div>
            </FantasyModal>
        </>

    )
}