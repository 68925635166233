import React, {useState,useContext} from "react";
import { FileHandler } from "./FileHandler";
import { useNavigate } from "react-router-dom";
import { FileSearch } from "./FileSearch";


import {FileContext} from "./FileProvider";
import {
  DeleteAllFilesOrFolders, DeleteAllFilesOrFoldersLogically, DeleteAllFilesOrFoldersLogicallyPhyzically,
  ExistFileHandler,

} from "../../../ApiServices/Public/FtpController";
import {ToastContainer } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { fileSchema } from "./FileValidation";
import { InputText } from "primereact/inputtext";
import { DisplayError } from "../../Commans/Errors/DisplayError";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {FilterFileUpload} from "../../Commans/UIParts/FilterbuttonUpload";
import {AlertSetting, fileManager} from "../../../Services/Globals/Public/AppSetting";
import {validate} from "../../../Services/Globals/validate";
import {createFormData} from "./FtpService";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {ExceptionHandlerService} from "../../../Services/Globals/Errors/ExceptionHandlerService";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {ModalSubmitButton} from "../../Commans/UIParts/ModalSubmitButton";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";

// // سناریو
// مدیریت فایل های ftp  از چندتا کامپوننت مستقل fileDisplayer , fileHandler , fileProvider , fileSearch استفاده میشه
// از useContenxt و global states  برای مدیریت این قسمت استفده شده
// فایل ها لود میشن و عملیات های مثل کات کپی  کپی در کلیپ بورد انتقال دانلود حذف و فشرده سازی انجام میشه
// سرچ و فیلتر کردن فایل به دو حالت سرچ باکس و انتخاب از روی منو انجام میشه
// کامپوننت fileDisplayer  فرزند کامپوننت fileHandler برای نمایش فایل هاست
// کامپوننت فایل FileSearch فرزند همین کامپوننت برای سرچ فایل هاست

// // سناریو ایجاد فایل
// برای ساخت فایل یه تایع فراخوانی که چک میکنه این فایل تکراری هست یا نه اگر بود یه مودال باز میشه که از سه نا حالت overwrite skip rename رو میگیره و ادامه کار انجام میشه اگرم نبود فایل ایجاد میشه

// // سناریو ساخت فولدر
// این هم دقیقا شبیه فایل هست

////سناریو انتقال فایل و فولدر
// مشایه ایجاد فایل و فولدر هست
// ما یه تابع برای برای فراخانی سرویس های انتقال و کپی داریم
// و اینکه هر با چه سرویسی برای چه عملیاتی و برای فایل یا فولدر صدا بزنه , یه    action و destination داریم به عنوان یه state global
// با این دوتا میفهمیم که چه عملیاتی باید برای قایل یا فولدر هندل بشه
export const HandleFileManager = ({handleGetFolderSource,inUse,handleCloseFtp}) => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [errors,setErrors] = useState({});
  const [activeCategory,setActiveCategory] = useState("");
  const [modal,setModal] = useState({ fileModal: false, dirModal: false });
  const [data, setData] = useState({ files: null, repeatCount: 0, mode: 0 });
  const [wait, setWait] = useState(false);
  const [folder,setFolder] = useState({ name: '' });
  const [modalMove,setModalMove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [IsBtnLoading,setIsBtnLoading] = useState(false);

  const showBtnLoading=()=>setIsBtnLoading(true)
  const hideBtnLoading=()=>setIsBtnLoading(false)


  const {setPattern, spinner, setSpinner, path, setPath, setResult, destination, fileColletionName, action, setAction, mission, setMission, clipBoard,setClipBoard} = useContext(FileContext);

  const handleValue = (e) => {
    const { name, value } = e.target;
    setFolder({ ...folder, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };


  const onApiError = () => {
    setSpinner(false);
  };
  const onSetErrors=(errors)=>{
    setErrors(errors)
  }

  const handleLinkClick = (keyword) => {
    setActiveCategory(keyword);
    setPattern(keyword);
  };


  // مودال های فایل و فولدر را نشان میدهیم
  // مدیریت مودال فایل
  const FileHandleOnHide = () => {
    setModal((prevState) => ({ ...prevState, fileModal: !modal.fileModal }));
  };
  // مدیریت مودال فولدر
  const DirHandleOnHide = () => {
    setModal((prevState) => ({ ...prevState, dirModal: !modal.dirModal }));
    setErrors({});
    setFolder({name: ''});
  };

  // فایل ها رو بهش میدیم و تعداد فایل های تکراری رو و mode رو


  // creating a format of file data path like this ['images+...png',images+profiles+...jpg];
  // to be sent for ExistFileHandler api and tells us which this file exists or not .

  // برای اپلود فایل ها باید لیست از file path  ها را با فرمت  ['images+...png',images+profiles+...jpg];برای سرور ارسال کنیم
  // با فراخانی این تابع این فرمت ساخته مییشه
  const getFilePathes = (files) => {
    let fileArr = Array.from(files);
    setData({ files: files });
    var paths = fileArr.map((item) => {
      return path + fileManager.separator + item.name;
    });
    return paths;
  };

  // فراخانی api  که میگه فایل ها تکراری هستن یا خیر
  const handleExistedFiles = (data) => {
    const count = data.filter((item) => item.isExisted === true).length;
    setData((prevState) => ({ ...prevState, repeatCount: count }));
    var thereIsNoRepeat = true;
    if (count !== 0) {
      setModal({ fileModal: true });
      return;
    }
    return thereIsNoRepeat;
  };


  // مدیریت server responses  بعد از اپلود



  // دراوردن ارور های server messages
  // با فراخوانی این تابع در رویداد onChange سناریو های اپلود به سرور و api ها انجام  میشه
  // در همین تایع فایل ها انتخاب میشن

  const [key,setKey] = useState(null);
  const handleFileChange = async (e) => {
    setLoading(true);
    const paths = getFilePathes(e.target.files);
      const response = await ExistFileHandler(paths);
      if (response.status === 200) {
        const data = response.data.map((item)=>item.isExisted);
        setKey(({data}));
        setLoading(false);
        const thereIsNoRepeat = handleExistedFiles(response.data);
        if (thereIsNoRepeat) {
          try {
            setSpinner(true);
            const formData = createFormData(e.target.files, data.mode,path);
            const resp = await POST('/Ftp/Files',formData,true);
            if(resp.status === 200){
              handleSuccessMessage(t("Theoperationwassuccessfully"));
              //await navigator.clipboard.writeText('');
              setResult({ ...resp.status });
              setKey(data);
            }
            else{
              setKey(data);
              handleApiError(resp,onApiError);
            }
          }
          catch (e) {
            console.log(e);
          }
        }
      }
      else{
        handleApiError(response,onApiError)
      }
  };

  // نشان دادن لودر در مودال بعد از فراخانی api


  // فایل ها رو از سناریو مودال اپلود میکنه
  const uploadMuilFilesHandler = async (mode) => {
    try {
      setWait(true);
      let files = data.files === null ? null : data.files;
      const formData = createFormData(files, mode,path);
      const response = await POST('/Ftp/Files',formData,true);
      if(response.status === 200){
        setWait(false);
        setModal({ fileModal: false });
        handleSuccessMessage(t("Theoperationwassuccessfully"));
        await navigator.clipboard.writeText('');
        setResult({ ...response.status });
      }else{
        handleApiError(response,onApiError)
      }
    }catch (e) {
      console.log(e);
    }
  };


  // ذخیره سازی نام فولدر

  // تابعی که نام فولدر را برای برای ذخیره سازی هندل میکنه




  //فولدر رو با فراخوانی  api  ارسال میکنه به سرور برای هندل کردن سناریو های توضیح داده شده

  const createDirectoryApiHandler= async (e)=>{
    e.preventDefault();
    const isValid = validate(fileSchema,folder,onSetErrors);
    if (!isValid) {
      return;
    }
    const { name } = folder;
    const folderPath = path + fileManager.separator + name;
    const lstPath = [folderPath];
    try {
      showBtnLoading()
      const responseExist = await POST('/Ftp/Directory/Exist',{path:lstPath},false);
      if(responseExist.status === 200){
        if(responseExist.isExisted){
          hideBtnLoading();
          setModal({ dirModal: false });
          handleFailureMessage(t("Thefolderisduplicated"));
        }else{
          const response = await POST('/Ftp/Directory',{path:folderPath},false);
          if(response.status === 200){
            setModal({ dirModal: false });
            setResult({...response.status});
            hideBtnLoading();
            setFolder('');
            handleSuccessMessage(t("Thefolderhasbeencreatedsuccessfully"));
          }
          else{
            hideBtnLoading();
            setModal({ dirModal: false });
            handleApiError(response,onApiError);
          }
        }
      }else{
        handleApiError(responseExist,onApiError)
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  // مودالی که برای سناریوی فولدر تکراری نشان داده میشه
  // مدیریت مودالی که برای هندل کردن سناریوی اپلود فولدر تکراری از رو مودال انجام میشه
  const FileMoveHandleOnHide = () => {
    setModalMove(!modalMove);
  };
  // ارسال درخواست به سرور که چک کنه فولدر تکراری هست یا خیر
  const handleExistedDirectory = async (response) => {

    const parts = clipBoard.split(fileManager.separator);
    const fileNameWithExtension = parts.pop();
    const oldPath = parts.join(fileManager.separator);

       if(oldPath === path){
         if(action === 'copy'){
           try {
             const response = await POST('/Ftp/Copy', {oldPath:clipBoard,newPath:path,exists:0},false);
             if(response.status === 200){
               handleSuccessMessage(t("Theoperationwassuccessfully"));
               setResult({...response.status});
             }
             else{
               handleApiError(response,onApiError)
             }
           }catch (e) {
             console.log(e);
           }
           return;
         }
       }



    const count = response.data.filter(
      (item) => item.isExisted === true
    ).length;
    var thereIsNoRepeat = true;
    if (count !== 0) {
      setModalMove(true);
      return;
    }
    return thereIsNoRepeat;
  };

  // سناریو های move , copy رو هندل میکنه
  const MoveCopyApiHandler = async () => {
    if (action === "move") {
      setMission("");
      if (destination === "Directory") {
        if (clipBoard === "") {
          handleFailureMessage(t("Yourclipboardisempty"));
          return;
        }
        const filePath = path + fileManager.separator + fileColletionName;
        const responseExist = await POST('/Ftp/Directory/Exist',{path:[filePath]},false);
        if (responseExist.status === 200) {
          const thereIsNoRepeat = await handleExistedDirectory(responseExist);
          if (thereIsNoRepeat) {
            try {
              const response = await POST('/Ftp/Directory/Move', {oldPath:clipBoard,newPath:path,exists:0},false);
              setMission('');
              if(response.status === 200){
                handleSuccessMessage(t("Theoperationwassuccessfully"));
                setResult({ ...response.status });
                await navigator.clipboard.writeText("");

              }else{
                handleApiError(response,onApiError);
              }
            }
            catch (e) {
              console.log(e);
            }
          }
        }
        else{
          handleApiError(responseExist,onApiError);
        }
      }
      //move file
      else {
        setMission("");
        if (clipBoard === "") {
          handleFailureMessage(t("Yourclipboardisempty"));
          return;
        }
        const filePath = path + fileManager.separator + fileColletionName;
        const responseExist= await POST('/Ftp/Files/Exist',{paths:[filePath]},false);
        if (responseExist.status === 200) {
         try {
           const thereIsNoRepeat = await handleExistedDirectory(responseExist);
           if (thereIsNoRepeat) {
             const response = await POST('/Ftp/Files/Move', {oldPath:clipBoard,newPath:path,exists:0},false);
             setMission('');
             if(response.status === 200){
               setResult({...response.status});
               handleSuccessMessage(t("Theoperationwassuccessfully"));
               await navigator.clipboard.writeText("");

             }
             else{
               handleApiError(response,onApiError)
             }
           }
         }
         catch (e) {
           console.log(e);
         }
        }
        else{
          handleApiError(responseExist,onApiError);
        }
      }
    }
    else{
      CopyFolderFile();
    }
  };


  const CopyFolderFile=async()=>{
    try {
      if(destination === "Directory"){
        setMission("");
        if (clipBoard === "") {
          handleFailureMessage(t("Yourclipboardisempty"));
          return;
        }
        const filePath = path + fileManager.separator + fileColletionName;
        const responseExist = await POST('/Ftp/Directory/Exist',{path:[filePath]},false);
        if (responseExist.status === 200) {
           try {
             const thereIsNoRepeat = await handleExistedDirectory(responseExist);
             if (thereIsNoRepeat) {
               setMission("");
               const response = await POST('/Ftp/Copy', {oldPath:clipBoard,newPath:path,exists:0},false);
               if(response.status === 200){
                 handleSuccessMessage(t("Theoperationwassuccessfully"));
                 setResult({...response.status});
                 await navigator.clipboard.writeText("");

               }else{
                 handleApiError(response,onApiError);
               }
             }
           }catch (e) {
             console.log(e);
           }
        }
        else{
          handleApiError(responseExist,onApiError)
        }
      }
      else{
        setMission("");
        if (clipBoard === "") {
          handleFailureMessage(t("Yourclipboardisempty"));
          return;
        }
        const filePath = path + fileManager.separator + fileColletionName;
        try {
          const responseExist = await POST('/Ftp/Files/Exist',{paths:[filePath]},false);
          if (responseExist.status === 200) {
            const thereIsNoRepeat = await handleExistedDirectory(responseExist);
            if (thereIsNoRepeat) {
              setMission("");
              const response = await POST('/Ftp/Copy', {oldPath:clipBoard,newPath:path,exists:0},false);
              if(response.status === 200){
                handleSuccessMessage(t("Theoperationwassuccessfully"));
                setResult({...response.status});
                await navigator.clipboard.writeText("");

              }
              else{
                handleApiError(response,onApiError)
              }
            }
          }
          else{
            handleApiError(responseExist,onApiError)
          }
        }catch (e) {
          console.log(e);
        }
      }
    }catch (e) {
      console.log(e);
    }
  }


















  //سناریو های  کپی و انتقال رو با انتخاب مود از رو مودال هندل میکنه
  const CompleteMoveCopyProcess = async (mode) => {
    setWait(true);
    if (action === "move") {
      setMission("");
      if (destination === "Directory") {
        // const prePath = await navigator.clipboard.readText();
        if (clipBoard === "") {
          handleFailureMessage(t("Yourclipboardisempty"));
          return;
        }
        try {
          // await navigator.clipboard.writeText("");
          setClipBoard("");
          const response = await POST('/Ftp/Directory/Move', {oldPath:clipBoard,newPath:path,exists:mode},false);
          if(response.status === 200){
            setWait(false);
            setModalMove(false);
            setResult({...response.status});
            handleSuccessMessage(t("Theoperationwassuccessfully"));
            await navigator.clipboard.writeText("");
          }
          else{
            setWait(false);
            setModalMove(false);
            handleApiError(response,onApiError)
          }
        }catch (e) {
          console.log(e);
        }
      } else {
        setMission("");
        // const prePath = await navigator.clipboard.readText();
        if (clipBoard === "") {
          handleFailureMessage(t("Yourclipboardisempty"));
          return;
        }
        try {
          // await navigator.clipboard.writeText("");
          setClipBoard("")
          const response = await POST('/Ftp/Files/Move', {oldPath:clipBoard,newPath:path,exists:mode},false);
          if(response.status === 200){
            setWait(false);
            setModalMove(false);
            setResult({...response.status});
            handleSuccessMessage(t("Theoperationwassuccessfully"));
            await navigator.clipboard.writeText("");

          }else {
            setWait(false);
            setModalMove(false);
            handleApiError(response,onApiError)
          }

        }catch (e) {
          console.log(e);
        }
      }
    } else {
      if (destination === "Directory") {
        // const prePath = await navigator.clipboard.readText();

        if (clipBoard === "") {
          handleFailureMessage(t("Yourclipboardisempty"));
          return;
        }
        try {
          // await navigator.clipboard.writeText("");
          setClipBoard("")
          const response = await POST('/Ftp/Copy', {oldPath:clipBoard,newPath:path,exists:mode},false);
          if(response.status === 200){
            setWait(false);
            setModalMove(false);
            setResult({...response.status});
            await navigator.clipboard.writeText("");

          }else{
            setWait(false);
            setModalMove(false);
            handleApiError(response,onApiError)
          }


        }catch (e) {
          console.log(e);
        }
      } else {
        // const prePath = await navigator.clipboard.readText();

        if (clipBoard === "") {
          handleFailureMessage(t("Yourclipboardisempty"));
          return;
        }
        try {
          const response = await POST('/Ftp/Copy', {oldPath:clipBoard,newPath:path,exists:mode},false);
          if(response.status === 200){
            setWait(false);
            setModalMove(false);
            setResult({...response.status});
            handleSuccessMessage(t("Theoperationwassuccessfully"));
            await navigator.clipboard.writeText("");

          }else{
            setWait(false);
            setModalMove(false);
            handleApiError(response,onApiError)
          }
        }catch (e) {
          console.log(e);
        }
      }
    }
  };


  const handleDeleteAll = async ()=>{

    if(path.includes(`${fileManager.separator}Trash`)){

      const TailWindButton = Swal.mixin({
        customClass: {
          confirmButton: AlertSetting.customClass.confirmButton,
          cancelButton: AlertSetting.customClass.cancelButton
        },
        buttonsStyling: false
      })
      TailWindButton.fire({
        title:AlertSetting.TailWindButton.title,
        text: AlertSetting.TailWindButton.text,
        icon:AlertSetting.TailWindButton.icon,
        showCancelButton:AlertSetting.TailWindButton.showCancelButton,
        confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
        cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
        reverseButtons: AlertSetting.TailWindButton.reverseButtons
      }).then( async (result) =>{
        if (result.isConfirmed) {
          try {
            setSpinner(true)
            const response = await DeleteAllFilesOrFoldersLogicallyPhyzically(path);
            if (response.status === 200) {
              setSpinner(false)
              setResult({...response.status});
              handleSuccessMessage(t("Theinformationsuccessfullydeleted"))
            }
            else {
              handleApiError(response,onApiError)
            }
          }catch (error) {
            return ExceptionHandlerService(error);
          }
        }
        else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
        ) {
          return;
        }
      })


    }
    else{
      const TailWindButton = Swal.mixin({
        customClass: {
          confirmButton: AlertSetting.customClass.confirmButton,
          cancelButton: AlertSetting.customClass.cancelButton
        },
        buttonsStyling: false
      })
      TailWindButton.fire({
        title:AlertSetting.TailWindButton.title,
        text: AlertSetting.TailWindButton.text,
        icon:AlertSetting.TailWindButton.icon,
        showCancelButton:AlertSetting.TailWindButton.showCancelButton,
        confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
        cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
        reverseButtons: AlertSetting.TailWindButton.reverseButtons
      }).then( async (result) =>{
        if (result.isConfirmed) {
          try {
            setSpinner(true)
            const response = await DeleteAllFilesOrFoldersLogically(path);
            if (response.status === 200) {
              setSpinner(false)
              setResult({...response.status});
              handleSuccessMessage(t("Theinformationsuccessfullydeleted"))
            }
            else {
              handleApiError(response,onApiError)
            }
          }catch (error) {
            return ExceptionHandlerService(error);
          }
        }
        else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
        ) {
          return;
        }
      })
    }
  }

  return (
    <>
        <div class="space-y-5 relative">
          {
            inUse?null
                :
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                  <ListSearch label={null} icon={null}>
                    <FileSearch />
                    <ButtonSearchList
                        onClickReset={()=>setPattern('')}
                    />
                  </ListSearch>

                  <ListFilter
                      childrenOne={
                        <>
                          <ButtonPlus
                              onClick={() => setPath("")}
                              icon={"pi-home"}
                              title={t("Homepage")}
                              tooltipTitle={t("ReturntoHomePage")}
                          />
                          {mission && clipBoard ? (
                              <ButtonPlus
                                  onClick={() => MoveCopyApiHandler()}
                                  icon={"pi-clipboard"}
                                  title={t("Paste")}
                                  tooltipTitle={t("Paste")}
                              />
                          ) : null}
                          <ButtonPlus
                              onClick={() => setModal({ dirModal: true })}
                              icon={"pi-folder-plus"}
                              title={t("Folder")}
                              tooltipTitle={t("CreateFolder")}

                          />
                          <FilterFileUpload
                              id={"upload"}
                              icon={"pi-cloud-upload"}
                              onChange={(e) => handleFileChange(e)}
                              key={key}
                              type={"file"}
                              title={t("Upload")}
                              multiple={"true"}
                              tooltipTitle={t("FileUpload")}
                          />

                          {
                            path !== ''?
                                <ButtonPlus
                                            onClick={()=>handleDeleteAll()}
                                            icon={"pi-trash"}
                                            title={t("DeleteAll")}
                                            tooltipTitle={t("DeleteAll")}
                                />:
                                null
                          }
                        </>
                      }
                  />


                </div>

          }
          {
            inUse?
                <ButtonPlus
                    onClick={() => setModal({ dirModal: true })}
                    icon={"pi-folder-plus"}
                    tooltipTitle={t("CreateFolder")}
                    isTitleHidden={true}
                    positionClass={"absolute bottom-3 start-2 z-10"}

                />
                :null
          }
          <div className={`${inUse?'':'grid grid-cols-12 gap-5 '}`}>
            <div class="col-span-12 lg:col-span-2 ">
              <div class="box bg-white dark:bg-themeDarkSalt700">
                {
                  inUse?null:
                      <div className="flex flex-nowrap overflow-x-auto scrollbar gap-2 lg:block p-2 lg:space-y-3">
                        <button type={"button"} className={`flex rounded-md items-center w-full px-3 py-2 text-nowrap hover:bg-themeHoverInformation hover:text-white dark:text-gray-300 ${
                            activeCategory === "" ? "bg-themeInformation dark:bg-themeDarkInformation text-white duration-300 hover:bg-themeHoverInformation"
                                : ""
                        }  gap-2`}
                                onClick={() => handleLinkClick("")} >
                          <HPrimeIcon
                              icon={"pi-box"}
                          />
                          {t("AllItems")}
                        </button>
                        <button type={"button"} className={`flex rounded-md  items-center w-full px-3 py-2 text-nowrap hover:bg-themeHoverInformation hover:text-white dark:text-gray-300 ${
                            activeCategory === "*.jpg,*.png,*.tiff,*.psd,*.pdf"
                                ? "bg-themeInformation dark:bg-themeDarkInformation text-white duration-300 hover:bg-themeHoverInformation"
                                : ""
                        }  gap-2`}
                                onClick={() =>
                                    handleLinkClick("*.jpg,*.png,*.tiff,*.psd,*.pdf")
                                }
                        >
                          <HPrimeIcon
                              icon={"pi-image"}
                          />
                          {t("Images")}
                        </button>
                        <button type={"button"} className={`flex rounded-md  items-center w-full px-3 py-2 text-nowrap hover:bg-themeHoverInformation hover:text-white dark:text-gray-300  ${
                            activeCategory === "*.mp4,*.mov,*.wmv,*.flv"
                                ? "bg-themeInformation dark:bg-themeDarkInformation text-white duration-300 hover:bg-themeHoverInformation "
                                : ""
                        } gap-2`}
                                onClick={() => handleLinkClick("*.mp4,*.mov,*.wmv,*.flv")}
                        >
                          <HPrimeIcon
                              icon={"pi-video"}
                          />
                          {t("Videos")}
                        </button>
                        <button type={"button"} className={`flex rounded-md  items-center w-full px-3 py-2 text-nowrap hover:bg-themeHoverInformation hover:text-white dark:text-gray-300 ${
                            activeCategory ===
                            "*.pdf,*.docx,*.docxf,*.xlsx,*.pptx,*.html,*.odt"
                                ? "bg-themeInformation dark:bg-themeDarkInformation text-white duration-300 hover:bg-themeHoverInformation"
                                : ""
                        } gap-2`}
                                onClick={() =>
                                    handleLinkClick(
                                        "*.pdf,*.docx,*.docxf,*.xlsx,*.pptx,*.html,*.odt"
                                    )
                                }
                        >
                          <HPrimeIcon
                              icon={"pi-file"}
                          />
                          {t("Documents")}
                        </button>
                        <button type={"button"} className={`flex rounded-md  items-center w-full px-3 py-2 text-nowrap hover:bg-themeHoverInformation hover:text-white dark:text-gray-300 ${
                            activeCategory === "share"
                                ? "bg-themeInformation dark:bg-themeDarkInformation text-white duration-300 hover:bg-themeHoverInformation "
                                : ""
                        } gap-2`}
                                onClick={() => handleLinkClick("share")}
                        >
                          <HPrimeIcon
                              icon={"pi-share-alt"}
                          />
                          {t("Share")}
                        </button>

                        <div  className="py-2 lg:border-t dark:border-themeDarkColorLine ">
                          <button type={"button"} className={`flex rounded-md  items-center w-full px-3 py-2 text-nowrap hover:bg-themeHoverInformation hover:text-white dark:text-gray-300 ${
                              activeCategory === "trash"
                                  ? "bg-themeInformation dark:bg-themeDarkInformation text-white duration-300 hover:bg-themeHoverInformation"
                                  : ""
                          } gap-2`}
                              // onClick={() => handleLinkClick("trash")}
                                  onClick={()=>setPath("+Trash")}
                          >
                            <HPrimeIcon
                                icon={"pi-trash"}
                            />

                            {t("trash")}
                          </button>
                        </div>
                      </div>
                }
              </div>
            </div>
            <div class="col-span-12 lg:col-span-10">

              <div>
                <FileHandler  handleGetFolderSource={handleGetFolderSource} handleCloseFtp={handleCloseFtp} inUse={inUse}/>
              </div>

              <FantasyModal openModal={modal.fileModal} closeModal={FileHandleOnHide} title={t("DuplicateFile")}>
                <div className="box overflow-hidden bg-white dark:bg-themeDarkSalt700 p-2 space-y-5">
                  <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                    <p className={"text-xs dark:text-gray-300"}>{t("DuplicateFileTitle")}</p>
                  </div>
                  <div className="px-2">
                        <div className={"space-y-2 dark:text-gray-300 post-input p-2 post-input-design "}>
                          <h2 className="text-xs">
                            {t("Upload")}
                            <span>
                                {" "}
                                  ({" "}
                                    {data.files !== null && data.files.length > 0
                                      ? data.files.length
                                      : 0}{" "}
                                      ){" "}
                            </span>{" "}
                              {t("FiletoPath")}
                                {" "}
                            <span>
                              {" "}
                                ( {path === ""
                                  ? "/"
                                  : path.replace(/\+/g, " / ")} ){" "}
                            </span>
                          </h2>
                          <h2 className="text-themeInformation dark:text-themeDarkInformation">
                            {t("Count")}
                            <span>
                               ( {data.repeatCount} )
                            </span>
                            {t("Thefileisduplicated")}
                          </h2>
                        </div>
                  </div>
                  <div className={"grid grid-cols-1 md:grid-cols-3 gap-3"}>
                    <button type={"button"} className={"button bg-themeInformation justify-center dark:bg-themeDarkInformation text-white relative flex gap-2 items-center"}
                            onClick={() => uploadMuilFilesHandler(1)}
                    >
                      <HPrimeIconSmall
                          icon={"pi-sync"}
                      />
                      {t("ReplaceFile")}
                      {wait ? (
                          <TinyLoading
                          />
                      ) : null}

                    </button>
                    <button
                        onClick={() => uploadMuilFilesHandler(0)}
                        className={"text-white button bg-themeSuccess justify-center relative flex gap-2 items-center"
                        }
                    >
                      <HPrimeIconSmall
                          icon={"pi-pen-to-square"}
                      />
                      {t("RenameFile")}

                      {wait ? (
                          <TinyLoading
                          />
                      ) : null}

                    </button>
                    <button
                        onClick={() => uploadMuilFilesHandler(2)}
                        className={"text-white button bg-themeDanger justify-center dark:bg-themeDarkInformation relative flex gap-2 items-center"
                        }
                    >
                      <HPrimeIconSmall
                          icon={"pi-times-circle"}
                      />
                      {t("Cancel")}

                      {wait ? (
                          <TinyLoading
                          />
                      ) : null}
                    </button>
                  </div>

                </div>
              </FantasyModal>

              <FantasyModal openModal={modal.dirModal} closeModal={DirHandleOnHide} title={t("CreateFolder")} customeClassName={'modal-x'}>

                <div>
                      <form method={"post"} onSubmit={createDirectoryApiHandler} >
                        <div className="space-y-5">
                          <div class="space-y-1">
                                  <div className={"flex gap-2 justify-between"}>
                                    <label className="text-xs dark:text-gray-300">
                                      {t("FolderName")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.name} />
                                  </div>
                                  <InputText onInput={(e) => handleValue(e)}
                                             value={folder.name}
                                             type="text"
                                             className={
                                               "input w-full"
                                             }
                                             name="name"

                                  />
                                </div>
                          <ModalSubmitButton
                              IsBtnLoading={IsBtnLoading}
                          />
                        </div>
                      </form>
                    </div>

              </FantasyModal>


              <FantasyModal openModal={modalMove} closeModal={FileMoveHandleOnHide} title={t("DuplicateFile")} >
                <div className="box box bg-white dark:bg-themeDarkSalt700 p-2">
                  <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                    <p className={"text-xs dark:text-gray-300"}>
                      {t("DuplicateFileTitle")}
                    </p>
                  </div>

                  { destination === "Directory" ? (
                      <div className="space-y-3 px-5 py-3 ">
                        <button
                            onClick={() => CompleteMoveCopyProcess(1)}
                            className="post-input zoom-in flex w-full items-center gap-2 rounded p-2 hover:bg-themeHoverFileUploader dark:bg-themeDarkPostInput"
                        >
                          <HPrimeIcon icon="pi-check-square" />
                          {t("ReplaceFile")}
                          {wait ? (
                              <TinyLoading
                              />
                          ) : null}
                        </button>
                        <button
                            onClick={() => CompleteMoveCopyProcess(0)}
                            className="post-input zoom-in flex w-full items-center gap-2 rounded p-2 text-themeSuccess hover:bg-themeHoverFileUploader dark:bg-themeDarkPostInput relative"
                        >
                          <HPrimeIcon icon="pi-file-edit" />
                          {t("RenameFile")}
                          {wait ? (
                              <TinyLoading
                              />
                          ) : null}
                        </button>
                        <button
                            onClick={() => CompleteMoveCopyProcess(2)}
                            className="post-input zoom-in flex w-full items-center gap-2 rounded p-2 text-themeDanger hover:bg-themeHoverFileUploader dark:bg-themeDarkPostInput relative"
                        >
                          <HPrimeIcon icon="pi-reply" />
                          {t("Cancel")}
                          {wait ? (
                              <TinyLoading
                              />
                          ) : null}
                        </button>
                      </div>
                  ) : (
                      <div className="space-y-3 px-5 py-3 ">
                        <button
                            onClick={() => CompleteMoveCopyProcess(1)}
                            className="post-input zoom-in flex w-full items-center gap-2 rounded p-2 hover:bg-themeHoverFileUploader dark:bg-themeDarkPostInput dark:text-gray-300 relative"
                        >
                          <HPrimeIcon icon="pi-check-square" />
                         {t("ReplaceFile")}
                          {wait ? (
                              <TinyLoading
                              />
                          ) : null}
                        </button>
                        <button
                            onClick={() => CompleteMoveCopyProcess(0)}
                            className="post-input zoom-in flex w-full items-center gap-2 rounded p-2 text-themeSuccess hover:bg-themeHoverFileUploader dark:bg-themeDarkPostInput relative"
                        >
                          <HPrimeIcon icon="pi-file-edit" />
                          {t("RenameFile")}
                          {wait ? (
                              <TinyLoading
                              />
                          ) : null}
                        </button>
                        <button
                            onClick={() => CompleteMoveCopyProcess(2)}
                            className="post-input zoom-in flex w-full items-center gap-2 rounded p-2 text-themeDanger hover:bg-themeHoverFileUploader dark:text-gray-300 relative"
                        >
                          <HPrimeIcon icon="pi-reply" />
                          {t("Cancel")}
                          {wait ? (
                              <TinyLoading
                              />
                          ) : null}
                        </button>
                      </div>
                  )}
                </div>
              </FantasyModal>

              <ToastContainer />
            </div>
          </div>
        </div>


    </>
  );
};
