import React, {useEffect, useState} from "react";
// import '../../../../Styles/Internal/setting.css'

import {MultiDataSelect} from "../../../Commans/UIParts/MultiDataSelect";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import Select from "react-select";
import {useGeneralSettings} from "./useGeneralSettings";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {InputNumber} from "primereact/inputnumber";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {redirect, useNavigate} from "react-router-dom";
import {handleCreateAdminSettings} from "./GeneralSettingService";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
export const HandleGeneralManager = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const[errros,setErrors]=useState({});
    const [refresh,setRefresh]=useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const {handleNumberValue,handleSelectValue,handleValue,setting,setSetting}=useGeneralSettings(setErrors)
    const [initialSource,setInitialSource]=useState({TimeUnits:[]});
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const handleInitialData=(timeUnits)=>{
        const timeUnitsData = convertSelectDataHandler(timeUnits);
        setInitialSource({TimeUnits: timeUnitsData});
        return {
            timeUnitsData
        }
    }
    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Enumerations/EnTimeUnit`,{},'IEnumerable');
            const responseSetting = await GET(`/Settings/ArianTeamSetting`,{},'single');
            if(response.status === 200 && responseSetting.status === 200){
                const {timeUnitsData}=handleInitialData(response.data);
                setSetting(prevState => ({...prevState,
                    packagePricePercentage: responseSetting.data.packagePricePercentage,
                    incrementStepHostPurchaseAmount: responseSetting.data.incrementStepHostPurchaseAmount,
                    incrementStepMailHostPurchaseAmount: responseSetting.data.incrementStepMailHostPurchaseAmount,
                    maximumHostAllowedPurchaseAmount: responseSetting.data.maximumHostAllowedPurchaseAmount,
                    maximumMailHostAllowedPurchaseAmount: responseSetting.data.maximumMailHostAllowedPurchaseAmount,
                    minimumHostAllowedPurchaseAmount: responseSetting.data.minimumHostAllowedPurchaseAmount,
                    minimumMailHostAllowedPurchaseAmount: responseSetting.data.minimumMailHostAllowedPurchaseAmount,
                    webSiteExpirationNotificationTime: responseSetting.data.webSiteExpirationNotificationTime,
                    defaultHostPurchaseAmount:responseSetting.data.defaultHostPurchaseAmount,
                    defaultMailHostPurchaseAmount:responseSetting.data.defaultMailHostPurchaseAmount,
                    webSiteExpirationNotificationTimeInterval: timeUnitsData.find((item)=>item.value === responseSetting.data.webSiteExpirationNotificationTimeInterval),
                }))
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
                handleApiError(responseSetting,hideSpinner);
            }
        }
        catch(e) {
            hideSpinner();
        }
    }
    useEffect(()=>{
      fetchData();
    },[refresh]);


    const handleSubmitAdminSetting=async(e)=>{
        e.preventDefault();
        try {
            showSpinner();
            const dataSettings = handleCreateAdminSettings(setting);
            const response = await POST(`/Settings/ArianTeamSetting`,dataSettings,false);
            if(response.status === 200){
                hideSpinner();
                Refresh();
                handleSuccessMessage(t('SuccessDone'));
            }else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }


    return(
        <>
            <form method={'post'} onSubmit={handleSubmitAdminSetting} className={"relative"}>

                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="space-y-5">
                    <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                        <ListFilter label={null} icon={null}
                                    childrenOne={
                                        <>

                                        </>
                                    }
                                    childrenTwo={
                                        <>

                                        </>
                                    }
                        />

                    </div>
                    <div className={""}>
                        <div className="box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3">
                            <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation flex items-center gap-2">
                                <h2 className={"text-xs dark:text-gray-300"}> {t("generalManagerSettings")}</h2>
                            </div>
                            <div className={"px-3"}>
                                <div class=" grid grid-cols-1 md:grid-cols-2 gap-3">
                                    <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                        <div class="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                            <p className={"dark:text-gray-300 text-xs"}>{t("TheAmountOfPurchasesIsAllowed")}</p>
                                        </div>
                                        <div className={"grid grid-cols-1 md:grid-cols-4 items-end gap-2"}>
                                            <div className={"space-y-1"}>
                                                <label className={"text-xs dark:text-gray-300"}>{t("DefaultValue")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber min={setting.defaultHostPurchaseAmount}  value={setting.defaultHostPurchaseAmount} name={'defaultHostPurchaseAmount'} onChange={(e)=>handleNumberValue(e.value,'defaultHostPurchaseAmount')} />
                                                    <span className="p-inputgroup-addon text-xs">
                                                 {t("MB")}
                                            </span>
                                                </div>
                                            </div>
                                            <div className={"space-y-1"}>
                                                <label className={"text-xs dark:text-gray-300"}>{t("Minimum")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber min={setting.minimumHostAllowedPurchaseAmount} value={setting.minimumHostAllowedPurchaseAmount} name={'minimumHostAllowedPurchaseAmount'} onChange={(e)=>handleNumberValue(e.value,'minimumHostAllowedPurchaseAmount')} />
                                                    <span className="p-inputgroup-addon text-xs">
                                                 {t("MB")}
                                            </span>
                                                </div>
                                            </div>
                                            <div className={"space-y-1"}>
                                                <label className={"text-xs dark:text-gray-300"}>{t("Maximum")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber name={'maximumHostAllowedPurchaseAmount'}  value={setting.maximumHostAllowedPurchaseAmount} onChange={(e)=>handleNumberValue(e.value,'maximumHostAllowedPurchaseAmount')}  />
                                                    <span className="p-inputgroup-addon text-xs">
                                                 {t("MB")}
                                            </span>
                                                </div>
                                            </div>
                                            <div className={"space-y-1"}>
                                                <label className="text-xs dark:text-gray-300">{t("StepIncrease")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber value={setting.incrementStepHostPurchaseAmount} name={'incrementStepHostPurchaseAmount'} onChange={(e)=>handleNumberValue(e.value,'incrementStepHostPurchaseAmount')}  />
                                                    <span className="p-inputgroup-addon text-xs">
                                                  {t("MB")}
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                        <div class="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                            <p className={"dark:text-gray-300 text-xs"}>{t("TheAllowedAmountOfBuyingMailHostIs")}</p>
                                        </div>
                                        <div className={"grid grid-cols-1 md:grid-cols-4 items-end gap-2"}>
                                            <div className={"space-y-1"}>
                                                <label className={"text-xs dark:text-gray-300"}>{t("DefaultValue")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber value={setting.defaultMailHostPurchaseAmount} onChange={(e)=>handleNumberValue(e.value,'defaultMailHostPurchaseAmount')} name={'defaultMailHostPurchaseAmount'}  />
                                                    <span className="p-inputgroup-addon text-xs">
                                                 {t("MB")}
                                            </span>
                                                </div>
                                            </div>
                                            <div className={"space-y-1"}>
                                                <label className={"text-xs dark:text-gray-300"}>{t("Minimum")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber min={setting.minimumMailHostAllowedPurchaseAmount} value={setting.minimumMailHostAllowedPurchaseAmount} name={'minimumMailHostAllowedPurchaseAmount'} onChange={(e)=>handleNumberValue(e.value,'minimumMailHostAllowedPurchaseAmount')} />
                                                    <span className="p-inputgroup-addon text-xs">
                                                 {t("MB")}
                                            </span>
                                                </div>
                                            </div>
                                            <div className={"space-y-1"}>
                                                <label className={"text-xs dark:text-gray-300"}>{t("Maximum")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber name={'maximumMailHostAllowedPurchaseAmount'}  value={setting.maximumMailHostAllowedPurchaseAmount} onChange={(e)=>handleNumberValue(e.value,'maximumMailHostAllowedPurchaseAmount')}  />
                                                    <span className="p-inputgroup-addon text-xs">
                                                {t("MB")}
                                            </span>
                                                </div>
                                            </div>
                                            <div className={"space-y-1"}>
                                                <label className="text-xs dark:text-gray-300">{t("StepIncrease")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber value={setting.incrementStepMailHostPurchaseAmount} name={'incrementStepMailHostPurchaseAmount'} onChange={(e)=>handleNumberValue(e.value,'incrementStepMailHostPurchaseAmount')}  />
                                                    <span className="p-inputgroup-addon text-xs">
                                                {t("MB")}
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                        <div class="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                            <p className={"dark:text-gray-300 text-xs"}>{t("CalculationOfWebsiteLanguagePriceTariff")}</p>
                                        </div>
                                        <div className={"grid grid-cols-1 md:grid-cols-2 items-end gap-2"}>
                                            <div class="space-y-1">
                                                <label className="dark:text-gray-300 text-xs">{t("ComparedToThePriceOfThePackage")}</label>
                                                <div className="p-inputgroup">
                                                    <InputNumber max={100} min={setting.packagePricePercentage} value={setting.packagePricePercentage} name={'packagePricePercentage'} onChange={(e)=>handleNumberValue(e.value,'packagePricePercentage')}  />
                                                    <span className="p-inputgroup-addon">
                                                 <HPrimeIconSmall icon={'pi-percentage'}/>
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                        <div class="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                            <p className={"dark:text-gray-300 text-xs"}>{t("RenewalBillReminderDate")}</p>
                                        </div>
                                        <div className={"flex gap-2 items-end "}>
                                                <div className={"flex flex-1 gap-2 items-center"}>
                                                    <div class="space-y-1 flex-1">
                                                        <label className="dark:text-gray-300 text-xs">{t("Value")}</label>
                                                        <InputNumber value={setting.webSiteExpirationNotificationTime} name={'webSiteExpirationNotificationTime'} onChange={(e)=>handleNumberValue(e.value,'webSiteExpirationNotificationTime')}  />
                                                    </div>
                                                    <div class="space-y-1 flex-1">
                                                        <label className="dark:text-gray-300 text-xs">{t("ReminderInterval")}</label>
                                                        <Select
                                                            options={initialSource.TimeUnits}
                                                            placeholder={t("selection")}
                                                            value={setting.webSiteExpirationNotificationTimeInterval}
                                                            onChange={(data)=>handleSelectValue(data,'webSiteExpirationNotificationTimeInterval')}
                                                            menuPosition={"fixed"}
                                                            classNamePrefix={"h_multi_select"}

                                                        />
                                                    </div>
                                                </div>
                                                <div className={"flex-shrink-0"}>
                                                    <span className={"text-xs dark:text-gray-300"}>{t("ToExpiration")}</span>
                                                </div>

                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ButtonSubmitAndCancel
                     onCancelClick={()=>redirectTo('/Dashboard')}
                    />
                </div>
            </form>
            <ToastContainer/>
        </>

    )
}