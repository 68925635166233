import Joi from "joi";
export const mobileSchema = Joi.object({
    username: Joi.string()
        .required()
        .pattern(/^(\+98|0)?9\d{9}$/)
        .messages({
            "any.required": "شماره موبایل الزامیست",
            "string.empty":"شماره موبایل الزامیست",
            "string.pattern.base": "فرمت شماره موبایل صحیح نمیباشد",

        }),
});

export const emailSchema = Joi.object({
    username: Joi.string()
        .required()
        .pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/)
        .messages({
            "any.required": "ایمیل الزامیست",
            "string.empty":" ایمیل الزامیست",
            "string.pattern.base": "فرمت ایمیل وارد شده صحیح نمیباشد",
        }),
});


export const codeSchema = Joi.object({
    otpCode: Joi.string()
        .required()
        .pattern(/^\d+$/)
        .messages({
            "any.required": "کد ارسالی الزامیست",
            "string.empty": "کد ارسالی الزامیست",
            "string.pattern.base": "فقط ارقام مجاز هستن",
        }),
});

export const PasswordSchema = Joi.object({
    password: Joi.string().required().messages({
        'any.required': 'کلمه عبور الزامیست',
        'string.empty': 'کلمه عبور الزامیست',
        'string.base': 'کلمه عبور الزامیست',
    }),
    confirmPassword: Joi.string()
        .required()
        .valid(Joi.ref("password"))
        .messages({
            "any.required": "تکرار کلمه عبور الزامیست",
            "string.empty": "تکرار کلمه عبور الزامیست",
            "any.only": "تکرار کلمه عبور با کلمه عبور مطابقت ندارد",
        }),
});