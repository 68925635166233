import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import queryString from "query-string";
import {Tooltip} from "react-tooltip";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";
import {GridButtonEdit} from "../../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../../Commans/Grid/GridButtonDelete";

export const useWebsiteUpgradeGrid=(handleOpenPayment,showSpinner,hideSpinner,refresh,initialSource)=>{
    const { t } = useTranslation();
    const queryParams = queryString.parse(window.location.search);
    const [rowData, setRowData] = useState([]);

    const GetPaymentState = (paymentStatus) => {
        if (!initialSource.paymentStates) {
            return;
        }
        const data = initialSource.paymentStates.find((item) => item.value === paymentStatus);
        return data ? data.label : '';
    }
    const GetTransactionState = (transactionState) => {
        if (!initialSource.transactionStates) {
            return;
        }
        const data = initialSource.transactionStates.find((item) => item.value === transactionState);
        return data ? data.label : '';
    }

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Name"),minWidth: 200,
            cellRenderer:parameters=>
                <>
                    {parameters.data.userFullName}
                </>
        },
        { headerName: t("Title"),minWidth: 180,
            cellRenderer:parameters=>
                <>
                    {parameters.data.webSiteTitle}
                </>
        },

        { headerName: t("TrackingCode"), minWidth: 140,

            cellRenderer:parameters=>
                <>
                    {parameters.data.trackingCode}
                </>
        },
        { headerName: t("PaymentStatus"), minWidth: 170,
            cellRenderer:parameters=>
                <>
                    {GetPaymentState(parameters.data.paymentStatus)}
                </>
        },
        { headerName: t("TransactionState"), minWidth: 200,
            cellRenderer:parameters=>
                <>
                    {GetTransactionState(parameters.data.transactionState)}
                </>
        },
        { headerName: t("count"), minWidth: 150,
            cellRenderer:parameters=>
                <>
                    {parameters.data.upgradeItemCount}
                </>
        },

        { headerName: t("details"), minWidth: 190,
            cellRenderer:parameters=>
                <div className={"flex justify-center items-center gap-2"}>
                    <button className="table_Btn bg-themeInformation dark:bg-themeDarkInformation text-white text-xs tooltip_class_Billcreation" onClick={()=>handleOpenPayment(parameters.data.upgradeId)} >
                        <HPrimeIconSmall icon={'pi-file-edit'} />
                        {t("Bill")}
                        <Tooltip anchorSelect={".tooltip_class_Billcreation"} content={t("Billcreation")} positionStrategy={"fixed"}	 />
                    </button>

                </div>
        },


        { headerName: t("operations"),minWidth: 140,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-2 '} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>
                </div>
            }

        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',fromDate:null,toDate:null});



    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',fromDate:null,toDate:null}));
        setSearch('');
    }


    const handleFromDate=(dateTime)=>{
        setFilter(({...filter,fromDate:dateTime}));
    }
    const handleToDate=(dateTime)=>{
        setFilter(({...filter,toDate:dateTime}));
    }


    useEffect(() => {

        OnGridReady();

    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.fromDate,filter.toDate]);

    const OnGridReady = async () => {
        try {
           showSpinner()
            const response =  await GET(`/Upgrades`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,FromDate:filter.fromDate,ToDate:filter.toDate}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };



    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleFromDate,
        handleToDate
    }

}