import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useFormBuilderTab=()=>{
    const { t } = useTranslation();

    const [tabId,setTabId] = useState("field");
    const handleChangeTab = (tabId) => {
        setTabId(tabId);
    };

    const tabs = [
        {
            id: "field",
            label:t("CreateAField"),
            icon: "pi-plus-circle",
        },
        {
            id: "property",
            label: t("Attributes"),
            icon: "pi-cog",
        },

    ];

    return {
        tabs,
        tabId,
        handleChangeTab,
        setTabId,
    }
}