import Joi from 'joi';

export const MetaDataGroupSchema = Joi.object({

    groupName: Joi.string().required().messages({
        'any.required': 'نام  گروه الزامیست',
        'string.empty': 'نام  گروه الزامیست',
        'string.base': 'نام  گروه الزامیست',
    }),
});
