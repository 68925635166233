import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {useNavigate} from "react-router-dom";
import {handleSuccessMessage, handleWarningMessage} from "../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import queryString from "query-string";
import { useDispatch } from 'react-redux';
import Select from "react-select";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {TemplateSchema} from "./TemplateSchema";
import {convertAddTemplateMaster, convertTemplateMaster, createTemplateFormData} from "./TemplateService";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";

import Swal from "sweetalert2";
import {GridTable} from "../../Commans/Grid/GridTable";
import {AgGridPersion} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {InputNumber} from "primereact/inputnumber";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {validate} from "../../../Services/Globals/validate";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {Image} from "primereact/image";
import {Return} from "../../Commans/Globals/Return";
import {AlertSetting, fileManager, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {BrowserDownloader, DownloadFile} from "../../../ApiServices/Public/FtpController";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {TagsInput} from "react-tag-input-component";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {GridButtonOprations} from "../../Commans/Grid/GridButtonOprations";

export const HandleAddMasterTemplate = () => {

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const[spinner,setSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const[result,setResult] = useState(0);
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [coldId,setColId]=useState(null);
    const [isMenuOpen,setMenuOpen] = useState(false);
    const [templateId,setTemplateId] = useState("");
    const [rootFolder,setRootFolder] = useState("");
    const [modal, setModal] = useState({ AddTemplateMater: false });
    const [template,setTemplate] = useState({Id:null,Name:null,DemoUrl:null,Banner:null,Type:null,RootFolder:null,StateShow:null,File:null,BannerPath:null,Keywords:[]})
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setTemplate({...template,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue=(value,name)=>{
        setTemplate({...template,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleKeywordsChange = (keywords) => {
        setTemplate((prevContent) => ({ ...prevContent,Keywords:keywords }))
    };
    const handleSelectValue=(data,name)=>{
        setTemplate({ ...template, [name]: data });
    }

    const handleFile =(data,name)=>{
        setTemplate((prevContent) => ({ ...prevContent, [name]: data }));
    }
    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }

    const handleOpenModal=()=>{
        setModal({ AddTemplateMater: true });
        setTemplate({Type:selectOptions.templateTypes[0],StateShow: {label:t("Active"),value:true},DemoUrl: '#',Keywords: []})
    }

    const [selectOptions,setSelectOptions] = useState({templateTypes:null,stateShows:[{label:t("isActive"),value:true},{label:t("isNotActive"),value:false}]})
    const [isEdit,setIsEdit] = useState(false);
    const handleFetchData = async () => {
        try {
            setSpinner(true);

            const fetchTemplateType = GET('/Enumerations/EnTypeTemplate', {}, 'IEnumerable');

            const [responseTemplateType] = await Promise.all([
                fetchTemplateType,
            ]);

            if (responseTemplateType.status === 200 ) {
                const templateTypes = convertSelectDataHandler(responseTemplateType.data);
                setSelectOptions((prevOptions) => ({
                    ...prevOptions,
                    templateTypes: templateTypes,
                }));
                setSpinner(false);
            } else {
                handleApiError(responseTemplateType, onApiError);
            }
        } catch (error) {
            setSpinner(false);
        }
    };
    useEffect(()=>{
       handleFetchData()
    },[])
    const handleGetTemplateMaster = async(recordId) => {
        try {
            setIsEdit(true);
            setSpinner(true)
            const response = await GET(`/TemplateMasterPages/GetTemplateMasterById?Id=${recordId}`,{},'single');
            if(response.status === 200){
                setTemplate(prevState => ({...prevState,
                    Id: response.data.id,
                    Name: response.data.name,
                    DemoUrl: response.data.demoUrl,
                    Type: selectOptions.templateTypes.find((item) => item.value === response.data.type),
                    RootFolder: response.data.rootFolder,
                    StateShow: selectOptions.stateShows.find((item) => item.value === response.data.stateShow),
                    Banner: response.data.banner,
                    Keywords:response.data.keywords
                }));
                redirectTo(`/Admin/MasterTemplate/Add?id=${recordId}`);
                setSpinner(false)
                setModal({AddTemplateMater: true})
            }
            else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
            onApiError()
        }

    };




    const handleAddTemplateMasterSubmit =async (e)=>{
        e.preventDefault();
        if(isEdit){
            const isValid = validate(TemplateSchema,template,onSetErrors);
            if (!isValid) {
                return;
            }
            try {
                setSpinner(true)
                const queryParams = queryString.parse(window.location.search);
                const convertedTemplate = convertTemplateMaster(template,queryParams.id);
                const formData = createTemplateFormData(convertedTemplate);
                const response = await PUT('/TemplateMasters/EditTemplate', formData,true);

                if (response.status === 200) {

                    if(response.data.failedFiles){
                        handleWarningMessage(`(${response.data.failedFiles})با مشکلاتی مواجه شد`)
                    }
                    setSpinner(false);
                    setIsEdit(false);
                    setResult({...response.status});
                    redirectTo('/Admin/MasterTemplate/Add');
                    handleCloseMaster();

                    handleSuccessMessage(t("Templatehasbeensuccessfullyedited"));
                } else {
                    handleApiError(response,onApiError)
                }
            }catch (e) {
                onApiError()
            }
        }else{
            const isValid = validate(TemplateSchema,template,onSetErrors);
            if(!isValid){
                return;
            }
           try {
               const convertedTemplate = convertAddTemplateMaster(template);

               setSpinner(true);
               const formData = createTemplateFormData(convertedTemplate);
               const response = await POST(`/TemplateMasters`, formData);
               if(response.status === 200){
                   if(response.data.failedFiles){
                       handleWarningMessage(`  (${response.data.failedFiles}) با مشکلاتی مواجه شد`)
                   }
                   setSpinner(false);
                   setResult({...response.status});
                   handleCloseMaster();
                   handleSuccessMessage(t("Templatehasbeensuccessfullyregistered"))
               }else{
                   handleApiError(response,onApiError)
               }
           }catch (e) {
               console.log(e);
               onApiError();
           }
        }

    }


    const handleChangeStateShow = async(templateId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            setColId(templateId);
            const response = await PUT(`/TemplateMasterPages/EditTemplateMasterActivation`,{id:templateId},false);
            if (response.status === 200) {
                setWait(false)
                setResult({...response.status})
            }
            else{
                handleApiError(response,setWait(false))
            }
        }catch (e) {
            setWait(false)
        }
    }

    const handleChangeDefault = async(templateId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            setColId(templateId);
            const response = await PUT(`/TemplateMasterPages/EditMasterPageIsDefault`,{id:templateId},false);
            if (response.status === 200) {
                setWait(false)
                setResult({...response.status})
            }
            else{
                handleApiError(response,setWait(false))
            }
        }catch (e) {
            setWait(false)
        }
    }



    const handleDownloadTemplateMaster=async(rootFolder)=>{
            const filePath = `${fileManager.separator}${rootFolder}`;
            await BrowserDownloader(filePath,'/TemplateMasters/DownloadTemplateMaster')
    }











    const removeFile=(path,name)=>setTemplate((prevState)=>({...prevState,[name]:path}))



    const handleMenuOpen = (templateId,rootFolder)=>{
        setMenuOpen(true);
        setRootFolder(rootFolder);
        setTemplateId(templateId);
    }
    const handleCloseMenu = ()=>{
        setMenuOpen(!isMenuOpen)
    }

    const removeTemplateHandler = async (templateId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
               try {
                   setSpinner(true)
                   const response = await DELETE('/TemplateMasters/RemoveTemplate',{id:templateId});
                   if (response.status === 200) {
                       setSpinner(false)
                       setResult({...response.status});
                       handleSuccessMessage(t("Templatehasbeensuccessfullydeleted"));
                   }
                   else {
                       handleApiError(response,onApiError)
                   }
               }catch (e) {
                   onApiError()
               }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const ConvertTemplateType = (type) => {
        if (!selectOptions.templateTypes) {
            return;
        }
        const data = selectOptions.templateTypes.find((item) => item.value === type);
        return data ? data.label : '';
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("Image"),field: 'banner', maxWidth: 140,
            cellRenderer:params => {
                return params.data.banner !== null?
                    <Image src={params.data.banner} zoomSrc={params.data.banner} preview />:
                    <HPrimeIcon
                    icon={"pi-times"}
                    iconClass={"text-themeDanger"}
                    />

            }
        },
        { headerName: t("TemplateTitle"),field: 'name', minWidth: 150},

        { headerName: t("TemplateType"),field: 'type', minWidth: 150,cellRenderer:params => {
                return ConvertTemplateType(params.data.type)
        }},



        { headerName: t("DisplayStatus"),field: 'stateShow',maxWidth: 120, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && params.data.id === coldId ? (
                        <TinyLoading />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                            onClick={async () => await handleChangeStateShow(params.data.id,params.colDef.headerName)}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeStateShow(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>

        },

        { headerName: t("DefaultTemplate"),field: 'isDefault', maxWidth: 130, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && params.data.id === coldId? (
                        <TinyLoading  />
                    ) : params.data.isDefault ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                            onClick={async () => await handleChangeDefault(params.data.id,params.colDef.headerName)}

                        />
                    ) : (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                            onClick={() => handleChangeDefault(params.data.id,params.colDef.headerName)}

                        />
                    )}
                </div>
        },

        { headerName: t("operations"), maxWidth: 140,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={() =>  handleGetTemplateMaster(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>removeTemplateHandler(params.data.id)}
                    />
                    <GridButtonOprations
                        onClick={()=>handleMenuOpen(params.data.id,params.data.rootFolder)}
                    />
                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setData(()=>({...data,pageSize: value}));
        setCurrentPage(1);
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',type:null,IsActive:null,IsDeActive:null,templateType:null,templateTypeValue:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));

    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const filterSelectValue = (data,name)=>{
        setFilter(({...filter,[name]: data.value,templateTypeValue: data}));
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search: '',templateType: null,IsActive: null,IsDeActive:null,templateTypeValue: null}));
        setTemplate(prevState => ({...prevState,Type: null}));
        setSearch('');
    }

    useEffect(() => {

            OnGridReady();

    }, [result,currentPage,data.pageSize,filter.type,filter.search,filter.templateType,filter.IsActive,filter.IsDeActive,filter.RTL,filter.LTR]);


    const [rowData, setRowData] = useState([]);


    const OnGridReady = async () => {
        try {

            const response =  await GET(`/TemplateMasterPages/GetTemplateMasterList`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,Type:filter.templateType, IsActive: filter.IsActive, IsDeActive: filter.IsDeActive}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            onApiError()
        } finally {
            setSpinner(false);
        }
    };


    const handleCloseMaster=()=>{
        setModal({AddTemplateMater: false});
        setErrors({})
        setTemplate({Type:selectOptions.templateTypes[0],StateShow: {label:'فعال',value:true},DemoUrl: '#',Name: '',RootFolder: '',Banner: null,File: null,Keywords: []});
        redirectTo('/Admin/MasterTemplate/Add');
        setIsEdit(false);
    }

    const switchData = [
        {name:'IsActive',lable:t("isActive"),value:filter.IsActive},
        {name:'IsDeActive',lable:t("isNotActive"),value:filter.IsDeActive},
    ]




    return(

     <div class=" space-y-5 relative">
         {console.log(template)}


       <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
           <ListSearch label={null} icon={null} >
               <div class="space-y-2 ">
                   <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                   <InputText  onChange={(e)=>setSearch(e.target.value)}  value={search} type="text" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" />
               </div>
               <div class="space-y-2 ">
                   <label className="text-xs dark:text-gray-300">{t("TemplateType")}</label>
                   <Select
                       options={selectOptions.templateTypes}
                       isMulti={false}
                       isSearchable={true}
                       onChange={(data)=>filterSelectValue(data,'templateType')}
                       placeholder={t("selection")}
                       value={filter.templateTypeValue}
                       classNamePrefix={"h_multi_select"}
                   />
               </div>
               <ButtonSearchList
                   onClickSearch={()=>handleSearch()}
                   onClickReset={()=>resetFilters()}
               />
           </ListSearch>
           <ListFilter label={null} icon={null}
                       childrenOne={
                           <>
                               <ButtonPlus
                                   onClick={() => handleOpenModal()}
                                   tooltipTitle={t("TemplateCreation")}
                                   tooltipId={5}
                               />

                           </>
                       }
                       childrenTwo={
                           <>
                               <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                           </>
                       }
           />

       </div>

         <div className={"bg-white dark:bg-themeDarkSalt700 p-3 space-y-2 box relative"}>
             {

                 spinner?
                     <div className=" huploader_loading" >
                         <div className="relative">
                             <LoadingHarmony/>
                         </div>
                     </div>
                     :
                     null
             }
             <GridTable
                 columnDefs={columnDefs}
                 rowSelection={GridSetting.rowSelection}
                 rowData={rowData}
                 style={GridSetting.gridStyle}
                 defaultColDef={defaultColDef}
                 autoGroupColumnDef={autoGroupColumnDef}
                 pagination={GridSetting.pagination}
                 enableRtl={GridSetting.enableRtl}
                 domLayout={GridSetting.domLayout}
                 paginationPageSize={GridSetting.pageSize}
                 getRowHeight={getRowHeight}
                 localeText={AgGridPersion}
             />
             <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
         </div>
         <ToastContainer />

         <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu}>
             <div className="p-1 grid grid-cols-3 md:grid-cols-4 gap-3">
                 <ModalButtonOperations
                 icon={"layout"}
                 onClick={()=>redirectTo(`/Admin/MasterTemplate/Page/Add?templateMasterId=${templateId}&rootFolder=${rootFolder}`)}
                 title={t("PageCreation")}
                 />
                 <ModalButtonOperations
                     icon={"download"}
                     title={t("Download")}
                     onClick={()=>handleDownloadTemplateMaster(rootFolder)}
                 />
             </div>
         </FantasyModal>

         <FantasyModal openModal={modal.AddTemplateMater}  closeModal={handleCloseMaster} title={t("TemplateCreation")}>
             <form method={'post'} onSubmit={handleAddTemplateMasterSubmit}  className="space-y-3">
                 <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                     <div class="space-y-2">
                         <div className="flex justify-between">
                             <label className="text-xs dark:text-gray-300">
                                 {t("TemplateTitle")}
                                 <RequiredIcon/>
                             </label>
                             <DisplayError message={errors.Name}/>
                         </div>
                         <InputText className="input w-full" name={'Name'} value={template.Name} onInput={(e)=>handleValue(e)}/>
                     </div>
                     <div class=" space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("TemplateType")}</label>
                         <Select
                             options={selectOptions.templateTypes}
                                     isMulti={false}
                                     isSearchable={true}
                                     isClearable={true}
                                     onChange={(data)=>handleSelectValue(data,'Type')}
                                     placeholder={t("selection")}
                                    value={template.Type}
                                    classNamePrefix={"h_multi_select"}
                                 />
                             </div>
                     <div class=" space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("WebsiteDirection")}</label>
                         <Select
                             options={selectOptions.dirOptions}
                             isMulti={false}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'Direction')}
                             placeholder={t("selection")}
                             value={template.Direction}
                             classNamePrefix={"h_multi_select"}
                         />
                     </div>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("DemoAddress")}</label>
                         <InputText placeholder={t("Address")} className="input w-full" name={'DemoUrl'} value={template.DemoUrl} onInput={(e)=>handleValue(e)} />
                     </div>
                     <div class=" space-y-2">
                         <div className="flex justify-between">
                             <label className="text-xs dark:text-gray-300">
                                 {t("TemplateFolderName")}
                                 <RequiredIcon/>
                             </label>
                             <DisplayError message={errors.RootFolder}/>
                         </div>
                         <InputText  className="input w-full" name={'RootFolder'} value={template.RootFolder} onInput={(e)=>handleValue(e) }/>
                     </div>
                     <div class=" space-y-1 ">
                             <label className="text-xs dark:text-gray-300">{t("Status")}</label>
                             <Select
                                 options={selectOptions.stateShows}
                                 isMulti={false}
                                 isSearchable={true}
                                 onChange={(data) => handleSelectValue(data, 'StateShow')}
                                 placeholder={t("selection")}
                                 value={template.StateShow}
                                 classNamePrefix={"h_multi_select"}
                             />
                         </div>
                 </div>
                 <div>
                     <TagsInput classNames={'input'} onChange={handleKeywordsChange} value={template.Keywords}  name={"Keywords"} placeHolder={t("Keywords")}/>
                 </div>

                 <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                     <div className={"space-y-1"}>
                         <label className="text-xs dark:text-gray-300">
                             <SettingButton
                                 onClick={()=>handleSetting('waterMark')}
                                 tooltipTitle={t("WatermarkText")}
                                 tooltipId={3}
                             />
                             {t("TemplateImage")}</label>
                         <div className="w-full h-32">
                             <UploadImage isEdit={isEdit} removeFile={removeFile} removeName={'BannerPath'} accept={'image/*'} isClosed={modal.AddTemplateMater} OnValueSelection={handleFile} value={template.Banner ? template.Banner:null} name={'Banner'} />
                         </div>
                         <div><h2 className="line-clamp-1 text-xs">{template.Banner ?template.Banner.name:null}</h2></div>
                     </div>
                     <div className={"space-y-1"}>
                         <label className="text-xs dark:text-gray-300">
                             <SettingButton
                                 onClick={()=>handleSetting('waterMark')}
                                 tooltipTitle={t("WatermarkText")}
                             />
                             {t("FileUpload")}</label>
                         <div class="w-full h-32">
                             <UploadImage accept={'.zip'} OnValueSelection={handleFile} name={'File'} onChange={(e)=>handleFile(e.target.files[0],"File")} value={template.File ? template.File:null} className="button "/>
                         </div>
                         <div><h2 className="line-clamp-1 text-xs"> {template.File ?template.File.name:null}</h2></div>
                     </div>
                 </div>
                 <div>
                     <button type={'submit'} className="button text-white w-full bg-themeInformation dark:bg-themeDarkInformation">
                         {t("register")}
                     </button>
                 </div>
             </form>
         </FantasyModal>

         <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
             <div>
                 {
                     setting === 'calendar'?
                         <HandleGeneralSetting/>:
                         setting === 'waterMark'?
                             <HandlePicturesSettings/>:null
                 }
             </div>
         </FantasyModal>



     </div>
  )
}