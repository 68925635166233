import React from "react";
export const LoadingSpinner = ()=>{
    return  (

            <div className="hbg_loading">
                <div className='hloading mx-auto' >
                    <div className='loading-inner loading-one'></div>
                    <div className='loading-inner loading-two'></div>
                    <div className='loading-inner loading-three'></div>
                </div>
                <div className="loading flex H-direction-ltr items-center justify-center">
                    <h2 className="text-xl font-bold ">H</h2>
                    <ul className="loading_ul flex items-center justify-center">
                        <li>a</li>
                        <li>r</li>
                        <li>m</li>
                        <li>o</li>
                        <li>n</li>
                        <li>y</li>
                    </ul>
                </div>


        </div>

    )
}