import React from "react";
import {useTranslation} from "react-i18next";

export const ProfileData=()=>{


    const { t } = useTranslation();

    return (
        <>

            <div>
                <ul className={"space-y-2"}>
                    <li >
                        <p className={"text-themeInformation dark:text-themeDarkInformation"}>{t("nationality")}</p>
                        <span className="line-clamp-1 text-gray-500 text-xs">جمهوری اسلامی ایران</span>
                    </li>
                    <li>
                        <p className={"text-themeInformation dark:text-themeDarkInformation"}>{t("MobilePhone")}</p>
                        <span className="line-clamp-1 text-gray-500 text-xs">09103822616</span>
                    </li>
                    <li>
                        <p className={"text-themeInformation  dark:text-themeDarkInformation"}>{t("Email")}</p>
                        <span className="line-clamp-1 text-gray-500 text-xs">masoud@yahoo.com</span>
                    </li>
                    <li >
                        <p className={"text-themeInformation dark:text-themeDarkInformation"}>{t("DateofBirth")}</p>
                        <span className="line-clamp-1 text-gray-500 text-xs">1400.02.02</span>
                    </li>
                </ul>
            </div>
        </>
    )
}