import React, { useEffect, useState } from "react";
import { ListSearch } from "../../../Commans/Grid/ListSearch";
import { InputText } from "primereact/inputtext";
import { ListFilter } from "../../../Commans/Grid/ListFilter";
import { GridTable } from "../../../Commans/Grid/GridTable";
import { useTranslation } from "react-i18next";
import { ButtonSearchList } from "../../../Commans/UIParts/ButtonSearchList";
import { ButtonPlus } from "../../../Commans/UIParts/ButtonPlus";
import { useNavigate } from "react-router-dom";
import { Export } from "../../../Commans/Grid/Export";
import { InputSwitchListFilter } from "../../../Commans/Filters/InputSwitchListFilter";
import { useHandelExpiredTickets } from "./useHandelExpiredTickets";
import { GridSetting } from "../../../../Services/Globals/Public/AppSetting";
import { AgGridEnglish } from "../../../../Services/Globals/Translations/Resources";
import { Pagination } from "../../../Commans/Grid/Pagination";
import { LoadingHarmony } from "../../../Commans/Loadings/LoadingHarmony";
import { GET } from "../../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import Select from "react-select";


export const HandleExpiredTickets = () => {
  const { t } = useTranslation();
  const [IsShowSpinner, setIsShowSpinner] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const redirectTo = useNavigate();
  const [initialSource, setInitialSource] = useState({ departments: [] }); // and ...
  const Refresh = () => {
    setRefresh((prevstate) => !prevstate);
  }
  const showSpinner = () => {
    setIsShowSpinner(true)
  }
  const hideSpinner = () => {
    setIsShowSpinner(false);
  }

  //handle initial Data source
  const handleInitialSource = (departments) => {
    const sources = {
      departments: departments
    };
    const sourceData = Object.keys(sources).reduce((acc, id) => {
      acc[id] = departments.map(item => ({ value: item.id, label: item.value }));
      return acc;
    }, {});
    setInitialSource(sourceData);
  };
  // get TicketDepartment
  const fetchData = async () => {
    try {
      showSpinner();
      const res = await GET('/Tickets/Departments/Selection', {}, 'IEnumerable');
      if (res.status === 200) {
        hideSpinner();
        handleInitialSource(res.data);
      } else {
        handleApiError(res, hideSpinner);
      }
    } catch {
      hideSpinner();
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  // handel grid
  const { handleNextPage, handleCurrentPage, handleSearch, handlePreviousPage, handlePageSizeCount, filter, setFilter, columnDefs, autoGroupColumnDef, defaultColDef, rowData, getRowHeight, pagination, switchData, checkBoxFilterValueHandler, handleFilterValue, resetFilters } = useHandelExpiredTickets(showSpinner, hideSpinner, refresh, Refresh);


  return (
    <>
      <div class="relative space-y-5">
        {
          IsShowSpinner ?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony />
              </div>
            </div>
            :
            null
        }
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
          <ListSearch label={null} icon={null} >
            <div class="space-y-2">
              <label className="text-xs dark:text-gray-300">{t('search')} (****)</label>
              <InputText onChange={(e) => setFilter(() => ({ ...filter, searchValue: e.target.value }))} value={filter.searchValue} onKeyDown={(e) => e.key === 'Enter' ? setFilter(() => ({ ...filter, search: e.target.value })) : null} type={"search"} className={" input w-full"} />
            </div>
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("TicketDepartment")}</label>
              <Select
                options={initialSource.departments}
                value={filter.DepartmentId}
                isSearchable={true}
                isMulti={false}
                placeholder={t("selection")}
                menuPosition={"fixed"}
                classNamePrefix={"h_multi_select"}
                onChange={(data) => handleFilterValue(data, 'DepartmentId')}
              />
            </div>
            <ButtonSearchList
              onClickSearch={handleSearch}
              onClickReset={resetFilters}
            />
          </ListSearch>
          <ListFilter label={null} icon={null}
            childrenOne={
              <>


                <ButtonPlus
                  onClick={() => redirectTo(`/Support/Department`)}
                  title={t("list")}
                  tooltipTitle={t("DepartmentList")}
                  icon={"pi-sitemap"}
                />

                <ButtonPlus
                  onClick={() => redirectTo("/Support/Ticket")}
                  title={t("Ticket")}
                  tooltipTitle={t("NewTicket")}
                  icon={"pi-ticket"}
                />

                <ButtonPlus
                  onClick={() => redirectTo("/Support/Ticket/List")}
                  title={t("list")}
                  tooltipTitle={t("TicketList")}
                  icon={"pi-list"}
                />


              </>
            }
            childrenTwo={
              <>
                <InputSwitchListFilter switchData={switchData} OnValueSelection={checkBoxFilterValueHandler} />
              </>
            }
          />


        </div>
        <div className="H-body-ag-grid space-y-2">
          <GridTable
            columnDefs={columnDefs}
            rowSelection={GridSetting.rowSelection}
            rowData={rowData}
            style={GridSetting.gridStyle}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={GridSetting.pagination}
            enableRtl={GridSetting.enableRtl}
            domLayout={GridSetting.domLayout}
            paginationPageSize={GridSetting.pageSize}
            getRowHeight={getRowHeight}
            localeText={AgGridEnglish}
          />
          <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount} />
        </div>
        <Export />

      </div>
    </>




  )
}