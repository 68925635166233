import React, {useState} from "react";

import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {ProfileCommunication} from "./ProfileCommunication";
import {Link} from "react-router-dom";
import InstagramIcon from "../../SocialMarketing/Messengers/SocialIcon/InstagramIcon";
import TelegramIcon from "../../SocialMarketing/Messengers/SocialIcon/TelegramIcon";
import YouTubeIcon from "../../SocialMarketing/Messengers/SocialIcon/YouTubeIcon";
import LinkedInIcon from "../../SocialMarketing/Messengers/SocialIcon/LinkedInIcon";
import WhatsAppIcon from "../../SocialMarketing/Messengers/SocialIcon/WhatsAppIcon";

export const ProfileMedia=()=>{

    const { t } = useTranslation();

    const [IsMedia,setIsMedia] = useState(false);
    const handleCloseMedia=()=>{
        setIsMedia(false);
    }
    const handleOpenMedia=()=>{
        setIsMedia(true);
    }

    const socialMediaLinks = [
        { url: "#", icon: <InstagramIcon sizeStyle={"size-7"}/>, title: "Instagram" },
        { url: "#", icon: <TelegramIcon sizeStyle={"size-7"}/>, title: "Facebook" },
        { url: "#", icon: <YouTubeIcon sizeStyle={"size-7"} />, title: "Telegram" },
        { url: "#", icon: <LinkedInIcon sizeStyle={"size-7"} />, title: "YouTube" },
        { url: "#", icon: <WhatsAppIcon sizeStyle={"size-7"} />, title: "YouTube" },
    ];



    return (
        <>

        <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className={"w-full"}>
                <h3 className="line-clamp-1 text-xl text-themeInformation dark:text-themeDarkInformation">مسعود احمدی محمدابادی</h3>
                <p className="text-gray-500 text-xs">مهندس مکانیک </p>
            </div>
            <ul className={"w-full flex items-center gap-2 overflow-auto scrollbar p-2 order-first lg:order-none"}>

                {socialMediaLinks.map((to, index) => (
                    <li key={index}>
                        <Link to={to.url} className="zoom-in block" target={"_blank"}>
                            {to.icon}
                        </Link>
                    </li>
                ))}

            </ul>
        </div>

            <FantasyModal openModal={IsMedia}  closeModal={handleCloseMedia} customeClassName={" modal-x"} title={t("listOfContactMethods")}>
              <ProfileCommunication/>
            </FantasyModal>
    </>
    )
}