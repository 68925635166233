import React, {useEffect, useMemo, useState} from "react";

import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import Select from "react-select";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {DELETE, GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {FilterSchema} from "./filterSchema";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {Export} from "../../../Commans/Grid/Export";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {useNavigate} from "react-router-dom";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import Swal from "sweetalert2";
import {handleAddFilter, handleConvertFilterItems} from "./filterService";
import {validate} from "../../../../Services/Globals/validate";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
export const HandleSettingsFilter = () => {
    const [modal, setModal] = useState({ AddItem: false, AddFilter:false });
    const [Apiname,setApiName] = useState('');
    const { t } = useTranslation();
    const [filter,setFilter] = useState({table:null,title:null})
    const [filterItems,setFilterItems] = useState([]);
    const [result,setResult] = useState(0);
    const [options,setOptions] = useState({tables:[],operations:[],previousOperations:[],filterTables:[],fields:[],relationalOperators:[],conditions:[],values:[]});
    const [isDefault,setIsDefault]= useState(false);
    const [errors, setErrors] = useState({});
    const[spinner,setSpinner] = useState(false);
    const [IsEdit,setIsEdit] = useState(false);
    const redirectTo=useNavigate()
    const [IsOpenFastfilter, setOpenFastfilter] = useState(false);



    const openFastfilter=()=>{
        setOpenFastfilter(true)
    }

    const closeFastfilter=()=>{
        setOpenFastfilter(false)
    }

    const handleCloseFilter=()=>{
        setModal({AddFilter: false});
        setFilter((prevState)=>({...prevState,table: options.tables[0]}));
        setFilter((prevState)=>({...prevState,title: ''}));
        setFilterItems([]);
        setErrors({})
        setIsEdit(false)
    }
    const handleOpenFilter=()=>{
        setModal({AddFilter: true});
        setFilter((prevState)=>({...prevState,table: options.tables[0]}));
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setFilter({...filter,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue= async (data,name)=>{
        setFilter({ ...filter, [name]: data });
        try {
            const {fields} = data;
            const filterFields = fields.map(item => ({label: item.value, value: item.key, typeName: item.typeName}));
            setOptions((prevState) => ({...prevState, fields: filterFields}));
        }
        catch (e) {
            console.log(e);
        }
    }
    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }


    const handleFetchData=async () => {
        try {
            setSpinner(true)
            const responseTable = await GET(`/EnTables/Selection`,{},'Enumerable');
            const responsePreviousOperations = await GET(`/Enumerations/EnLogicalOperator`,{},'Enumerable');
            const responseRelationalOperators = await GET(`/EnRelationalOperators/Selection`,{},'Enumerable');
            if(responseTable.status === 200 && responsePreviousOperations.status === 200){
                const tables = responseTable.data.map((item)=>({
                    label:item.value,
                    value:item.key,
                    fields:item.fields
                }))
                const previousOperators = convertSelectDataHandler(responsePreviousOperations.data);
                const relationalOperators = responseRelationalOperators.data.map(item => ({label: item.value, value: item.key,types:item.types}));
                setOptions((prevState)=>({...prevState,tables: tables,previousOperations: previousOperators,relationalOperators: relationalOperators}));
            }
            else{
                handleApiError(responseTable,onApiError)
                handleApiError(responsePreviousOperations,onApiError)
            }

        } catch (e) {
            console.log(e);
        }
    }

    const handleAddRow=()=>{
        if(filterItems.length === 0){
            const newRow = {
                id: new Date().getTime(),
                table: options.filterTables[0],
                operation:options.conditions[0],
                previousOperation:options.previousOperations[0],
                field:options.fields[0],
                value: null,
                order:1,
                isDisable:true,
                filterItemId:null,
                isList:false,
                hasFk:true,
                foreignKey:null
            };
            setIsDefault(false);
            setFilterItems(prevList => [...prevList, newRow]);
        }
        else{
            const newRow = {
                id: new Date().getTime(),
                table: options.filterTables[0],
                operation:options.conditions[0],
                previousOperation:options.previousOperations[0],
                field:options.fields[0],
                value: null,
                order:filterItems.length >0 ? filterItems[filterItems.length-1].order + 1 : 0,
                isDisable:false,
                filterItemId:null,
                isList: false,
                hasFk:true,
                foreignKey:null

            };
            setIsDefault(false);
            setFilterItems(prevList => [...prevList, newRow]);
        }
    }

    const handleRemoveRow = (id,filterItemId) => {
        if(filterItems.length === 1){
            const newRow = {
                id: new Date().getTime(),
                table: options.filterTables[0],
                operation:null,
                previousOperation:null,
                field:null,
                value: '',
                order:1,
                isDisable:true,
                filterItemId:null,
                isList:false,
                hasFk:true,
                foreignKey:null

            };
            setFilter((prevState)=>({...prevState,title: ''}))
            setOptions(prevState => ({...prevState,conditions: [],fields: [],filterTables: []}))
            setIsDefault(true);
            setFilterItems(prevList => [newRow]);
            return;
        }
        setFilterItems((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.filterItemId === filterItemId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleChange = (id, field, value, filterItemId) => {
        setFilterItems((prevRelations) => {
            const updatedRelations = prevRelations.map( (meta) => {

                if ((filterItemId && meta.filterItemId === filterItemId) || (!filterItemId && id && meta.id === id)) {

                    // if (field === 'table') {
                    //     const {fields} = value;
                    //     const filterFields = fields.map(item => ({
                    //         label: item.value,
                    //         value: item.key,
                    //         typeName: item.typeName
                    //     }));
                    //     setOptions((prevState) => ({...prevState, fields: filterFields}));
                    // }
                    if (field === 'field') {

                        const {typeName} = value;
                        const conditions = options.relationalOperators.filter(item => GetIncludeTypes(item.types, typeName));
                        setOptions((prevState) => ({...prevState, conditions: conditions}));

                        meta.isList =   IsEnumerableType(typeName).IsEnumerable;
                        meta.hasFk =   IsEnumerableType(typeName).HasFk;

                    }
                    if(field === 'foreignKey' && meta.hasFk === false){
                        const {typeName} = value;
                        const conditions = options.relationalOperators.filter(item => GetIncludeTypes(item.types, typeName));
                        setOptions((prevState) => ({...prevState, conditions: conditions}));
                        meta.isList =   IsEnumerableType(typeName).IsEnumerable;
                    }
                    return {...meta, [field]: value};
                } else {
                    return meta;
                }
            });
            return updatedRelations;
        });
        setIsDefault(false);
    };

    const GetIncludeTypes=(types,typeName)=>{
        const NullablePattern = /Nullable<(.*?)>$/;
        const DbSetPattern = /DbSet<(.*?)>$/;
        const SmartEnumPattern = /SmartEnum<(.*?)>$/;
        const EnumPattern = /Enum<(.*?)>$/;
        const ListPattern = /List<(.*?)>$/;
        // NUllAble<*>
        if(NullablePattern.test(typeName) ){
            const matches = typeName.match(NullablePattern);
            return types.includes(matches[1]);
        }
        // DbSetPattern<*>
        else if(DbSetPattern.test(typeName) )
        {
            const matches = typeName.match(DbSetPattern);
            return types.includes("DbSet");
        }
        // SmartEnum<*>
        else if(SmartEnumPattern.test(typeName))
        {
            const matches = typeName.match(SmartEnumPattern);
            return types.includes("SmartEnum");
        }
        // Enum<*>
        else if(EnumPattern.test(typeName))
        {
            const matches = typeName.match(EnumPattern);
            return types.includes("Enum");
        }
            // else if(ListPattern.test(typeName)){
            //     const matches = typeName.match(ListPattern);
            //
            //     return types.includes("List");
            // }
        //  *
        else{
            return types.includes(typeName);
        }
    }

    const IsEnumerableType= (fieldTypeName)=>{
        const NullablePattern = /Nullable<(.*?)>$/;
        const DbSetPattern = /DbSet<(.*?)>$/;
        const SmartEnumPattern = /SmartEnum<(.*?)>$/;
        const EnumPattern = /Enum<(.*?)>$/;
        const ListPattern = /List<(.*?)>$/;
        const FkPattern = /Fk<(.*?)>$/;
        const result = {IsEnumerable:false,HasFk:true}
        if(DbSetPattern.test(fieldTypeName)){
            const matches = fieldTypeName.match(DbSetPattern);
            switch (matches[1]) {
                case 'UserGroups':
                    setApiName('/UserGroups');
                    break;
                case 'Roles':
                    setApiName('/Roles/Selection');
                    break;
                case 'ProductGroups':
                    setApiName('/ProductGroups/Selection');
                    break;

                case 'ContentGroups':
                    setApiName('/ContentGroups/Selection');
                    break;
            }
            result.IsEnumerable = true;
            return  result;
        }
        else if (SmartEnumPattern.test(fieldTypeName)){
            const matches = fieldTypeName.match(SmartEnumPattern);
            setApiName(matches[1]);
            result.IsEnumerable = true;
            return  result;
        }
        else if(EnumPattern.test(fieldTypeName))
        {
            const matches = fieldTypeName.match(EnumPattern);
            setApiName(`/Enumerations/${matches[1]}`)
            result.IsEnumerable = true;
            return  result;
        }
        else if (FkPattern.test(fieldTypeName)){
            const matches = fieldTypeName.match(FkPattern);
            const targetId = parseInt(matches[1]);
            const rowTable = options.tables.find(table => table.value ===targetId);
            const {fields} = rowTable;
            const filterTables = fields.map((item)=>({label:item.value, value:item.key, typeName:item.typeName}))
            setOptions((prevState)=>({...prevState,filterTables: filterTables}))
            result.HasFk = false;
            return  result;
        }
            // else if (ListPattern.test(fieldTypeName)){
            //     return  true;
        // }
        else{
            setApiName('');
            return result;
        }
    }

    const GetAllOperations=async (apiName)=>{
        try {
            if(apiName === '' || apiName === null){
                return;
            }
            setSpinner(true)
            const response = await GET(apiName,{},'Enumerable');
            if(response.status === 200){
                const data= convertSelectDataHandler(response.data)
                setOptions((prevState)=>({...prevState,values:data}));
                setSpinner(false);
            }else{
                setSpinner(false);
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            console.log(e)
        }
    }






    useEffect(()=>{
        handleFetchData();
    },[result])

    useEffect(() => {
        if (filterItems.length === 0) {
            handleAddRow();
        }
    }, [filterItems]);




    const handleReportSubmit=async(e)=>{
        e.preventDefault();

        if(IsEdit)
        {
            const isValid = validate(FilterSchema,filter,onSetErrors)
            if(!isValid){
                return;
            }

        }
        else
        {
            const isValid = validate(FilterSchema,filter,onSetErrors)
            if(!isValid){
                return;
            }
            const  ItemsAreNull = filterItems.every((item) => item.field !== null && item.operation !== null && item.value !== null)
            if(!ItemsAreNull){
                handleFailureMessage(t("FieldConditionValueIsEmpty"));
                return;
            }
            const convertedFilters = handleAddFilter(filter,filterItems);
            try {
                setSpinner(true)
                const response = await POST(`/Filters`,convertedFilters,false);
                if(response.status === 200){
                    setSpinner(false);
                    handleCloseFilter();
                    handleSuccessMessage(t("FilterRegisteredSuccessfully"));
                    setResult({...response.status});
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                onApiError()
            }

        }
    }





    const handleRemoveFilter = async (filterId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`/Filters/${filterId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                        handleSuccessMessage(t("FilterRemovedSuccessfully"))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("ReportTitleFilter"),field: 'title',width: 120, minWidth: 150},
        { headerName: t("CreationDate"),width: 120, maxWidth: 150,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.creationDate} /></>
            }
        },


        { headerName: t("settings"),width: 130, maxWidth: 130,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-2'} >
                    <GridButtonDelete
                        onClick={()=>handleRemoveFilter(params.data.filterId)}

                    />
                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };
    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);
        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filters,setFilters] = useState({search:'',public:null,custom:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilters((prevState)=>({...prevState,search: search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilters(({...filters,[name]: null}));
        }
        else{
            setFilters(({...filters,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilters(({...filters,search: '',public: null,custom: null}));
        setSearch('');
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response,onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [result, currentPage, filters.search, data.pageSize, filters.public, filters.custom]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/Filters`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filters.search,Public:filters.public,Custom:filters.custom}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };


    const switchData = [
        {name:'public',lable:t("Public"),value:filters.public},
        {name:'custom',lable:t("Custom"),value:filters.custom}
    ]





    return(
        <>
            <div class="space-y-5 relative">
                {
                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilters(()=>({...filters,search: e.target.value})):null}  className=" input w-full"  />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleOpenFilter()}
                                            icon={"pi-filter"}
                                            title={t("CreateFilter")}
                                            tooltipTitle={t("CreateFilter")}
                                        />
                                        <ButtonPlus
                                            onClick={() => openFastfilter()}
                                            icon={"pi-filter-slash"}
                                            title={t("QuickFilter")}
                                            tooltipTitle={t("QuickFilter")}
                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />


                </div>
                <div className="space-y-2 H-body-ag-grid">

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>

                <ToastContainer />

                <Export filters={filter} apiUrl={'/Packages/PackageListExport'}/>
            </div>


            <FantasyModal openModal={modal.AddFilter}  closeModal={handleCloseFilter}  customeClassName={"modal-x"} title={t("CreateFilter")}>
                <>
                    <div className=" space-y-2 relative ">
                        {

                            spinner?
                                <div className=" huploader_loading" >
                                    <div className="relative">
                                        <LoadingHarmony/>
                                    </div>
                                </div>
                                :
                                null
                        }

                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                            <div class="space-y-2">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">{t("FilterTitle")}</label>
                                    <DisplayError message={errors.title}/>
                                </div>
                                <div>
                                    <InputText value={filter.title} onInput={(e)=>handleValue(e)} name={'title'}  className="input w-full" />
                                </div>
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("Part")}</label>
                                <div>
                                    <Select
                                        options={options.tables}
                                        isSearchable={true}
                                        isClearable={true}
                                        onChange={(data)=>handleSelectValue(data,'table')}
                                        placeholder={t("selection")}
                                        value={filter.table}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}

                                    />
                                </div>
                            </div>
                        </div>

                        <form method={'post'} onSubmit={handleReportSubmit} >
                            <div className="space-y-2">
                                <div className={"box bg-white dark:bg-themeDarkPostInput p-2 space-y-2 max_height30"}>
                                    {filterItems.map((row,index) => (
                                        <div className="post-input dark:bg-themeDarkPostInput rounded-md p-2 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <div className="flex gap-1">
                                                <div className={"mt-auto"}>
                                                    <span className="number_pluse text-white">{index+1}</span>
                                                </div>
                                                <div class="space-y-1 flex-1">
                                                    <label className="text-xs dark:text-gray-300">{t("Operator")}</label>
                                                    <Select
                                                        options={options.previousOperations}
                                                        isSearchable={true}
                                                        isClearable={false}
                                                        isDisabled={row.isDisable}
                                                        onChange={(data)=>handleChange(row.id,'previousOperation',data,row.filterItemId)}
                                                        placeholder={t("Operator")}
                                                        value={row.previousOperation}
                                                        menuPosition={'fixed'}
                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                </div>
                                            </div>

                                            <div class="space-y-1 ">
                                                <label className="text-xs dark:text-gray-300">{t("Field")}</label>
                                                <Select
                                                    options={options.fields}
                                                    isSearchable={true}
                                                    isClearable={false}
                                                    onChange={(data)=>handleChange(row.id,'field',data,row.filterItemId)}
                                                    placeholder={t("Field")}
                                                    onBlur={async () => await GetAllOperations(Apiname)}
                                                    value={row.field}
                                                    menuPosition={'fixed'}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>

                                            {
                                                !row.hasFk?
                                                    <div class="space-y-1 ">
                                                        <label className="text-xs dark:text-gray-300">کلید خارجی</label>
                                                        <Select
                                                            options={options.filterTables}
                                                            isSearchable={true}
                                                            isClearable={false}
                                                            isDisabled={row.hasFk}
                                                            onChange={(data)=>handleChange(row.id,'foreignKey',data,row.filterItemId)}
                                                            placeholder={t("Table")}
                                                            value={row.tables}
                                                            menuPosition={'fixed'}
                                                            classNamePrefix={"h_multi_select"}
                                                        />
                                                    </div>:
                                                    null

                                            }

                                            <div class="space-y-1 ">
                                                <label className="text-xs dark:text-gray-300">{t("Condition")}</label>
                                                <Select
                                                    options={options.conditions}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    onChange={(data)=>handleChange(row.id,'operation',data,row.filterItemId)}
                                                    placeholder={t("Condition")}
                                                    value={row.operation}
                                                    menuPosition={'fixed'}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>

                                            <div className="flex gap-2">
                                                <div className="space-y-1 flex-1">
                                                    <label className="text-xs dark:text-gray-300">{t("Value")}</label>

                                                    {
                                                        row.isList ?
                                                            <Select
                                                                options={options.values}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                onChange={(data)=>handleChange(row.id,'value',data,row.filterItemId)}
                                                                placeholder={t("Condition")}
                                                                value={row.value}
                                                                menuPosition={'fixed'}
                                                                classNamePrefix={"h_multi_select"}
                                                            />
                                                            :

                                                            <input type="text" className="input w-full" value={row.value} onChange={(e) => handleChange(row.id, 'value', e.target.value,row.filterItemId)}/>

                                                    }

                                                </div>

                                                <div className={"mt-auto flex gap-2"}>
                                                    <button onClick={()=>handleAddRow()} type="button" className="button small_button bg-themeInformation text-white dark:bg-themeDarkInformation">
                                                        <HPrimeIcon icon="pi-plus" />
                                                    </button>
                                                    <button onClick={()=>handleRemoveRow(row.id,row.filterItemId)} type="button" className="button small_button bg-themeDanger text-white">
                                                        <HPrimeIcon icon="pi-minus" />
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                <button type={'submit'} className={'button w-full bg-themeInformation text-white dark:bg-themeDarkInformation'}>
                                    {t('register')}
                                </button>
                            </div>

                        </form>
                    </div>
                </>
                {console.log(filterItems)}
            </FantasyModal>
            <FantasyModal openModal={IsOpenFastfilter} closeModal={closeFastfilter} title={t("FileManagement")} customeClassName={"modal-xl"}>

            </FantasyModal>
            <ToastContainer/>
        </>
    )
};
