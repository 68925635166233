import React, {useEffect, useState} from "react";
import {ListFilter} from "../../Commans/Grid/ListFilter";

import {useNavigate} from "react-router-dom";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {useCustomTabs} from "./useCustomTabs";
import {MainMenuTab} from "./MainMenuTab";
import {convertSelectDataHandler, convertToTree} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import SortableTree from "@nosferatu500/react-sortable-tree";
import { handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {
    deleteNode,
    handleAddDefaultType, handleAddNodeToTree, handleConvertKeyValuesToTreeSource,
    handleGetSpecificNode, handleUpdateNodeTree,
    processJsonData
} from "./MainMenuService";
import {Tooltip} from "react-tooltip";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {useMenuBinder} from "./useMenuBinder";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import queryString from "query-string";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const HandleMainMenu = ()=>{

    const {tabs}=useCustomTabs();
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [activeTab, setActiveTab] = useState("content");
    const [showSpinner,setShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({mainMenuSlugs:[],targets:[]});
    const [IsDialog,setIsDialog] = useState(false);
    const [refresh,setRefresh]=useState(0);
    const [Slug,setSlug]=useState(null);
    const [source, setSource] = useState([]);
    const [IsEdit,setIsEdit] = useState(false);
    const [target, setTarget] = useState([]);
    const {handleValue,handleSelectValue,nodeData,setNodeData} = useMenuBinder()

    // opens dialog to add a child
    const handleOpenDialog=(rowInfo)=>{
        const parentId = rowInfo.node.id
        setIsDialog(true);
        setNodeData({slug: '',title: '',targetType: initialSource.targets[0],id:'',parentId: parentId})
    }

    // opens dialog to add
    const handleOpenDialogToAddNode=()=>{
        setIsDialog(true);
        setNodeData({slug: '',title: '',targetType: initialSource.targets[0],id:'',parentId: null})
    }


    // closes dialog
    const handleCloseDialog=()=>{
        setIsDialog(false);
        setIsEdit(false);
        setNodeData({slug: '',title: '',targetType: initialSource.targets[0],id: ''})
    }


    // updates dataSource
    const handleChangeSource=(data)=>{
        setSource(data);
    }

    // updates dataTarget
    // adds a default type 0 to json target
    const handleChangeTarget=(data)=>{
        const defaultData = handleAddDefaultType(data);
        setTarget(defaultData);
    }


    // an callback to hide spinner .
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // renders component again to fetch all data
    const Refresh=(state)=>{
        setRefresh({...state});
    }
    //Initialize required primary data and choose the first item as initial one
    const handleInitialData=(slugData,targetData)=>{
        const slugs = convertSelectDataHandler(slugData);
        const targets = convertSelectDataHandler(targetData);
        setInitialSource({mainMenuSlugs:slugs,targets: targets});
        setSlug(slugs[0])
    }
    //updates slugs
    const handleChangeSlug=(data)=>{
        setSlug(data);
    }

    //changing tabs based on tabId to call an api for fetching main menu slugs ...
    const handleChangeTab = async (tabId) => {
        setActiveTab(tabId);
        switch (tabId) {
            case 'content':
                try {
                    setShowSpinner(true);
                    const response = await GET(`/ContentGroups/GetAllMainMenuContentGroups`, {}, 'IEnumerable');
                    const responseGroup = await GET(`/ContentGroups`,{},'IEnumerable');
                    const responseTarget = await GET(`/Enumerations/EnTargetType`,{},'IEnumerable');

                    if (response.status === 200 && responseGroup.status === 200 && responseTarget.status === 200) {
                        handleInitialData(response.data,responseTarget.data);
                        const tree = convertToTree(responseGroup.data);
                        setSource(tree);
                        setShowSpinner(false);
                    }
                    else {
                        handleApiError(response, onApiError);
                    }
                } catch (e) {
                    onApiError();
                }
                break;
            case 'survey':
                try {
                    setShowSpinner(true);
                    const response = await GET(`/Surveys/GetAllMainMenuSurveys`, {}, 'IEnumerable');
                    const responseTarget = await GET(`/Enumerations/EnTargetType`,{},'IEnumerable');

                    if (response.status === 200 && responseTarget.status === 200) {
                        handleInitialData(response.data,responseTarget.data);
                        const valueSource = handleConvertKeyValuesToTreeSource(response.data);
                        const tree = convertToTree(valueSource);
                        setSource(tree)
                        setShowSpinner(false);
                    } else {
                        handleApiError(response, onApiError);
                    }
                } catch (e) {
                    onApiError();
                }
                break;
            case 'form':
                try {
                    setShowSpinner(true);
                    const response = await GET(`/Forms/GetAllMainMenuForms`, {}, 'IEnumerable');
                    const responseTarget = await GET(`/Enumerations/EnTargetType`,{},'IEnumerable');

                    if (response.status === 200 && responseTarget.status === 200) {
                        handleInitialData(response.data,responseTarget.data);
                        const valueSource = handleConvertKeyValuesToTreeSource(response.data);
                        const tree = convertToTree(valueSource);
                        setSource(tree);
                        setShowSpinner(false);
                    } else {
                        handleApiError(response, onApiError);
                    }
                } catch (e) {
                    onApiError();                }
                break;
            case 'shop':
                try {
                    setShowSpinner(true);
                    const response = await GET(`/ProductGroups/MainMenu`, {}, 'IEnumerable');
                    const responseTarget = await GET(`/Enumerations/EnTargetType`,{},'IEnumerable');
                    if (response.status === 200 && responseTarget.status === 200) {
                        handleInitialData(response.data,responseTarget.data);
                        setShowSpinner(false);
                    } else {
                        handleApiError(response, onApiError);
                    }
                }
                catch (e) {
                    onApiError();
                }
                break;
            default:
                break;
        }
    };

    //fetching all initial data from
    const fetchData= async ()=>{
        try {
            const queryParams = queryString.parse(window.location.search);
            setShowSpinner(true);
            const response = await GET(`/ContentGroups/GetAllMainMenuContentGroups`,{},'IEnumerable');
            const responseGroup = await GET(`/ContentGroups`,{},'IEnumerable');
            const responseTarget = await GET(`/Enumerations/EnTargetType`,{},'IEnumerable');
            const responseData = await GET(`/MainMenus/GetMainMenuById`,{MainMenuGroupId:queryParams.mainMenuGroupId},'IEnumerable');
            if(response.status === 200 && responseGroup.status === 200 && responseTarget.status === 200 && responseData.status === 200){
                handleInitialData(response.data,responseTarget.data);
                const tree = convertToTree(responseGroup.data);
                setSource(tree);
                const treeTarget = convertToTree(responseData.data)
                setTarget(treeTarget)
                setShowSpinner(false);
            }else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(()=>{
        fetchData()
    },[refresh])






    // edits a node
    const handleEditNode=(rowInfo)=>{
        const nodeId = rowInfo.node.id;
        setIsEdit(true);
        setIsDialog(true)
        const desiredData = handleGetSpecificNode(target,nodeId)
        const {slug,title,targetType} = desiredData;
        setNodeData({
            id:nodeId,
            slug: slug,
            title: title,
            targetType:initialSource.targets.find((item)=>item.value === targetType)
        })
    }



    //adds or edit new node
    const handleUpdateTreeData=()=>{
        if(IsEdit){
            const data = handleUpdateNodeTree(target,nodeData.id,nodeData.targetType.value,nodeData.title,nodeData.slug);
            setTarget(data);
            handleCloseDialog()
        }
        else
        {
            const data = handleAddNodeToTree(nodeData.parentId,target,nodeData.targetType.value,nodeData.title,nodeData.slug);
            setTarget(data);
            handleCloseDialog();
        }
    }

    //deletes  a node
    const handleDeleteNode=(rowInfo)=>{
        const nodeId = rowInfo.node.id;
        const data = deleteNode(target,nodeId);
        setTarget(data);
    }

    const handleMoveNode = async(e) => {
        const newParentId = e.nextParentNode?.id;
        const movedNode = e.node;

        // Update the parentId of the moved node
        movedNode.parentId = newParentId !== undefined ? newParentId : null;

        // Assuming 'target' is your state variable holding the tree data
        setTarget((prevTarget) => {
            // Clone the previous state to trigger a re-render
            const updatedTarget = [...prevTarget];

            // Function to update the parentId in the cloned tree data
            const updateParentId = (tree) => {
                for (const node of tree) {
                    if (node.id === movedNode.id) {
                        // Update the parentId of the moved node
                        node.parentId = movedNode.parentId;
                        return;
                    }

                    if (node.children && node.children.length > 0) {
                        updateParentId(node.children);
                    }
                }
            };

            // Call the function to update parentId
            updateParentId(updatedTarget);

            return updatedTarget;
        });
    }











    //calling api to save changes for target tree
    const handleSave=async()=>{
        try {
            const queryParams = queryString.parse(window.location.search);
            const data = processJsonData(target);
            const treeJson = {
                mainMenus:data,
                mainMenuGroupId:queryParams.mainMenuGroupId
            }
            const response = await POST(`/MainMenus`,treeJson,false);
            if(response.status === 200){
               handleSuccessMessage(t("MenuHasBeenSuccessfullyRegistered"));
               Refresh(response.status);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }







    return (


        <div className={"space-y-5 relative"}>
            {
                showSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }

            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>handleOpenDialogToAddNode()}
                                        title={t("Group")}
                                        tooltipTitle={t("GroupCreation")}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Public/MainMenu/List`)}
                                        icon={"pi-list"}
                                        title={t("menu")}
                                        tooltipTitle={t("MenuManagement")}
                                    />
                                </>
                            }
                />

            </div>
            <div class="grid grid-cols-1 lg:grid-cols-12 relative gap-5 hscreen p-3">

                <div className="lg:col-span-5 order-2 md:order-none Mainmenu-tree ">
                    <MainMenuTab tabs={tabs}
                                 tabId={activeTab}
                                 initialSlug={Slug}
                                 handleChangeSlug={handleChangeSlug}
                                 mainMenuSlugs={initialSource.mainMenuSlugs}
                                 activeTab={activeTab}
                                 handleChangeTab={handleChangeTab}
                                 source={source}
                                 onChangeSource={handleChangeSource}
                    />
                </div>
                <div className="lg:col-span-7 box bg-white dark:bg-themeDarkSalt700 order-1 md:order-none Mainmenu-tree ">
                    <SortableTree
                        treeData={target}
                        onMoveNode={(e)=>handleMoveNode(e)}
                        onChange={(dataTarget) => handleChangeTarget(dataTarget)}
                        dndType={'yourNodeType'}
                        shouldCopyOnOutsideDrop={false}
                        className={"size-full"}
                        generateNodeProps={(rowInfo) => ({
                            // title: rowInfo.node.label,
                            // subtitle: rowInfo.node.subTitle,
                            buttons: [
                                <div className="flex space-x-2 items-center">
                                    <button onClick={(event) => handleOpenDialog(rowInfo)} className="button tree_button tooltip_class_group bg-themeInformation dark:bg-themeDarkInformation text-white" >
                                        <HPrimeIconSmall icon={'pi-plus-circle'} />
                                        <Tooltip anchorSelect={".tooltip_class_group"} content={t("GroupCreation")} />
                                    </button>
                                    <button onClick={(event) => handleEditNode(rowInfo)} className="button tree_button tooltip_class_Edit bg-themeSuccess text-white"  >
                                        <HPrimeIconSmall  icon={'pi-pen-to-square'} />
                                        <Tooltip anchorSelect={".tooltip_class_Edit"} content={t("edit")} />
                                    </button>
                                    <button onClick={(event) => handleDeleteNode(rowInfo)} className="button tree_button tooltip_class_delete bg-themeDanger text-white"  >
                                        <HPrimeIconSmall icon={'pi-trash'} />
                                        <Tooltip anchorSelect={".tooltip_class_delete"} content={t("delete")} />
                                    </button>
                                </div>
                            ],
                        })}
                    />
                </div>
            </div>
            <div>
                <ButtonSubmitAndCancel
                    onRegisterClick={()=>handleSave()}
                    showCancelButton={true}
                />
            </div>

            <ToastContainer/>

            <FantasyModal openModal={IsDialog}   closeModal={handleCloseDialog} title={t("GroupCreation")}>
                <div className="space-y-5">
                        <div className="grid grid-cols-2 gap-2">
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                                <InputText type={'text'}  name={'title'} value={nodeData.title} onInput={(e)=>handleValue(e)} className={'input w-full'}/>
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("WebsiteTitle")}</label>
                                <InputText type={'text'} className={' input w-full '} value={nodeData.slug} name={'slug'} onInput={(e)=>handleValue(e)}  />
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("type")}</label>
                                <Select
                                    options={initialSource.targets}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data)}
                                    name={'targetType'}
                                    placeholder={t("selection")}
                                    value={nodeData.targetType}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                                />
                            </div>
                        </div>
                        <div >
                            <button type={'button'} onClick={()=>handleUpdateTreeData()} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}>{t('insertion')}</button>
                        </div>
                </div>
            </FantasyModal>

        </div>
    )
}