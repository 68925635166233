import React, {useMemo, useState} from "react";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {ListFilter} from "../Commans/Grid/ListFilter";


import {useTranslation} from "react-i18next";

import {DateTimePicker} from "../Commans/Dates/DateTimePicker";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useSetting} from "../Commans/Hooks/useSetting";
import {SettingButton} from "../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";


import {InputText} from "primereact/inputtext";

import Select from "react-select";

import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {ListSearch} from "../Commans/Grid/ListSearch";

import {GridTable} from "../Commans/Grid/GridTable";
import {Export} from "../Commans/Grid/Export";

import {ColorPicker} from "primereact/colorpicker";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";

export const HandleAttendanceListShiftWork = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const [IsAddShift,setIsAddShift] = useState(false);

    const handleOpenAddShift=()=>{
        setIsAddShift(true);
    }
    const handleCloseAddShift=()=>{
        setIsAddShift(false);
    }


    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 110},
        { headerName:t("FirstNameAndLastName"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>

        },

        { headerName:t("PersonnelCode"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>

        },

        { headerName:t("Date"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>

        },

        { headerName:t("ArrivalTime"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    تهران
                </>

        },
        { headerName:t("DepartureTime"),minWidth: 140,
            cellRenderer:parameters=>
                <>
                    65654
                </>
        },

        { headerName: t("TaskWork"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    5454545
                </>
        },
        { headerName: "کارکرد ثبتی", maxWidth: 160,
            cellRenderer:parameters=>
                <>
                    54545454545
                </>

        },
        {
            headerName: t("Delay"), maxWidth: 110,
            cellRenderer: parameters =>
                <>
                    14121
                </>

        },
        { headerName: t("Hastening"), maxWidth: 130,
            cellRenderer: parameters =>
                <>
                    14
                </>

        },

        { headerName: t("LowTimeWork"), minWidth: 140,
            cellRenderer: parameters =>
                <div>

                </div>

        },
        { headerName: t("CompulsoryOvertime"), minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },

        { headerName: t("UnauthorizedOvertime"), minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },

        { headerName: 'اضافه کار اول وقت', minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },

        { headerName: 'اضافه کار آخر وقت', minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },

        { headerName: t("TotalOvertime"), minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },

        { headerName: t("HourlyLeave"), minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },
        { headerName: t("HourlyMission"), minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },

        { headerName: t("NightWork"), minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },
        { headerName: t("DelayService"), minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },

        { headerName: t("description"), minWidth: 140,
            cellRenderer: parameters =>
                <>

                </>

        },


        { headerName: t("operations"),width: 110, maxWidth: 120,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-2 '} >
                    <button type={"button"}  className="text-themeSuccess" ><HPrimeIcon  icon={'edit-3'} /></button>
                </div>
            }

        },

    ];

    const rowData = [

        { id:1, date:'1402/10/3', },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };

    const [value, setValue] = useState(new Date());


    return (
        <>
            <div className="space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByName")}</label>
                            <InputText  type="text"  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("AccountType")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <ButtonSearchList
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>handleOpenAddShift()}
                                            tooltipTitle={t("AddShift")}
                                        />

                                        <ButtonPlus
                                            title={t("Personnel")}
                                            onClick={()=>redirectTo(`/Attendance/Personnel`)}
                                            tooltipTitle={t("Personnel")}
                                            icon={"users"}
                                        />

                                        <ButtonPlus
                                            icon={"plus-square"}
                                            title={t("Device")}
                                            onClick={()=>redirectTo("/Attendance/Add")}
                                            tooltipTitle={t("RegisterDevice")}
                                        />

                                        <ButtonPlus
                                            title={t("FinancialRules")}
                                            icon={"book"}
                                            onClick={()=>redirectTo("/Attendance/FinancialRules")}
                                            tooltipTitle={t("FinancialRules")}
                                        />

                                        <ButtonPlus
                                            title={t("Leave")}
                                            primIcon={"pi-calendar-clock"}
                                            onClick={()=>redirectTo("/Attendance/LeaveAndMission")}
                                            tooltipTitle={t("LeaveAndMission")}
                                        />

                                        <ButtonPlus
                                            title={t("Shift")}
                                            icon={"layers"}
                                            onClick={()=>redirectTo("/Attendance/ShiftWork/List")}
                                            tooltipTitle={t("ShiftWork")}
                                        />

                                        <ButtonPlus
                                            title={t("WorkGroup")}
                                            icon={"users"}
                                            onClick={()=>redirectTo("/Attendance/WorkGroup/List")}
                                            tooltipTitle={t("WorkGroup")}
                                        />

                                        <ButtonPlus
                                            title={t("archive")}
                                            icon={"archive"}
                                            onClick={()=>redirectTo("/Attendance/Archive")}
                                            tooltipTitle={t("ArchiveLeaveAndMission")}
                                        />


                                        <ButtonPlus
                                            title={t("Reports")}
                                            primIcon={"pi-chart-bar"}
                                            onClick={()=>redirectTo("/Attendance/List/Reports")}
                                            tooltipTitle={t("GeneralReport")}
                                        />



                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>

                <div className={"space-y-2 H-body-ag-grid"}>

                    <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                               enableRtl={true}
                               pagination={true}
                               getRowHeight={getRowHeight}
                               onGridReady={onGridReady}
                               quickFilterText={quickFilterText}
                    />
                </div>
                <Export  />

            </div>


            <FantasyModal openModal={IsAddShift} closeModal={handleCloseAddShift} customeClassName={"modal-x"} title={t("AddAccount")}>
                <div class="space-y-5">
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("ShiftName")}</label>
                            <InputText className={"input w-full"}/>
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("ShiftCode")}</label>
                            <InputText className={"input w-full"}/>
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("ProductionManager")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Supervisor")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>

                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("ShiftType")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div className={"space-y-1"}>
                            <label className="text-xs dark:text-gray-300">
                                {t("ColorCode")}
                            </label>
                            <div className={"flex gap-2 items-center"}>
                                <InputText value={setting.watermarkBackgroundColor} name={'watermarkBackgroundColor'}  maxLength="7" />
                                <ColorPicker  inputId="cp-hex" format={'hex'} value={setting.watermarkBackgroundColor}  className="w-10 "/>
                            </div>
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ShiftStartTime")}
                            </label>
                            <DateTimePicker
                                onChange={setValue}
                                value={value}
                                format="HH:mm"
                                disableClock={true} // مخفی کردن آیکون ساعت
                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                isClockOpen={false} // نمایش انتخابگر ساعت
                            />
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("EndShiftTime")}
                            </label>
                            <DateTimePicker
                                onChange={setValue}
                                value={value}
                                format="HH:mm"
                                disableClock={true} // مخفی کردن آیکون ساعت
                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                isClockOpen={false} // نمایش انتخابگر ساعت
                            />
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("BreakTime")}
                            </label>
                            <DateTimePicker
                                onChange={setValue}
                                value={value}
                                format="HH:mm"
                                disableClock={true} // مخفی کردن آیکون ساعت
                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                isClockOpen={false} // نمایش انتخابگر ساعت

                            />
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("EndTimeRest")}
                            </label>
                            <DateTimePicker
                                onChange={setValue}
                                value={value}
                                format="HH:mm"
                                disableClock={true} // مخفی کردن آیکون ساعت
                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                isClockOpen={false} // نمایش انتخابگر ساعت

                            />
                        </div>
                        <div class="space-y-1 flex-1 w-full">
                            <label className="text-xs dark:text-gray-300">{t("AllowedEntryPeriod")}</label>
                            <DateTimePicker
                                onChange={setValue}
                                value={value}
                                format="HH:mm"
                                disableClock={true} // مخفی کردن آیکون ساعت
                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                isClockOpen={false} // نمایش انتخابگر ساعت

                            />
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("AllowedExitPeriod")}</label>
                            <DateTimePicker
                                onChange={setValue}
                                value={value}
                                format="HH:mm"
                                disableClock={true} // مخفی کردن آیکون ساعت
                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                isClockOpen={false} // نمایش انتخابگر ساعت

                            />
                        </div>

                    </div>
                    <div>
                        <button  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                    </div>
                </div>
            </FantasyModal>


            <FantasyModal openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>


        </>
    );
};
