import React, {useEffect, useState} from "react";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {InputText} from "primereact/inputtext";

import Select from "react-select";
import {Tooltip} from "react-tooltip";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {LoginRequiredAccount} from "./LoginRequiredAccount";
import {NoLoginRequiredAccount} from "./NoLoginRequiredAccount";
import {NoDataHarmony} from "../../Commans/NoDataHarmony";

export const HandleAccountList = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [accounts,setAccounts]=useState([]);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }

    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const fetchData=async()=>{
        try {
           showSpinner();
           const response = await GET(`/SocialAccounts/Accounts`,{NoLoginRequired:false},'IEnumerable');
           if(response.status === 200){
               setAccounts(response.data);
               hideSpinner();
           }
           else{
               handleApiError(response,hideSpinner);
           }
        }
        catch (e) {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[refresh])





    return (
        <>
            <div className="space-y-5 relative">

                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByName")}</label>
                            <InputText  type="text"  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("AccountType")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <ButtonSearchList
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Connect")}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/List/ConnectAccounts")}
                                            tooltipTitle={t("ConnectAccounts")}
                                        />

                                        <ButtonPlus
                                            title={t("list")}
                                            icon={"pi-list"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublicationList")}
                                            tooltipTitle={t("PublicationList")}
                                        />
                                        <ButtonPlus
                                            title={t("Publishing")}
                                            icon={"pi-share-alt"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublishingContent")}
                                            tooltipTitle={t("PublishingContent")}
                                        />
                                        <ButtonPlus
                                            title={t("comments")}
                                            icon={"pi-comments"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/Comments")}
                                            tooltipTitle={t("comments")}
                                            notification={true}
                                        />

                                        <ButtonPlus
                                            title={t("Draft")}
                                            icon={"pi-file-edit"}
                                            onClick={()=>redirectTo("/SocialMarketing/Draft")}
                                            tooltipTitle={t("Draft")}
                                        />


                                    </>
                                }

                    />

                </div>

                <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 box bg-white dark:bg-themeDarkSalt700 p-2">
                    {
                        accounts.length > 0 ? accounts.map((account, index) =>
                                account.loginRequired ?
                                    <LoginRequiredAccount key={index}   {...account}  />
                                    :
                                    <NoLoginRequiredAccount key={index} {...account} hideSpinner={hideSpinner} showSpinner={showSpinner} Refresh={Refresh}  />
                            )
                            :
                            <NoDataHarmony/>
                    }
                </div>
            </div>



            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
        </>
    );
};
