export const handleCreateProduct=(obj,files)=>{
    const data = {
        productId: obj.productId ?? null,
        name: obj.name ?? null,
        slug: obj.slug ?? null,
        excerpt: obj.excerpt ?? null,
        editor: obj.editor ?? null,
        seoTitle: obj.seoTitle ?? null,
        seoKeyWords: obj.seoKeyWords ?? null,
        seoDescription: obj.seoDescription ?? null,
        productSuggestionIds: obj.productSuggestionIds === null || obj.productSuggestionIds.length === 0?null:obj.productSuggestionIds.map(item => item.value),
        releaseDateTime: obj.releaseDateTime ?? null,
        discountOrCommissionType: obj.discountOrCommissionType === null || obj.discountOrCommissionType === undefined?null:obj.discountOrCommissionType.value,
        discountAmount:obj.discountAmount??null,
        discountFrom:obj.discountFrom??null,
        discountTo:obj.discountTo??null,
        wholeSaleDiscountAmount:obj.wholeSaleDiscountAmount??null,
        wholeSaleDiscountOrCommissionType: obj.wholeSaleDiscountOrCommissionType === null || obj.wholeSaleDiscountOrCommissionType === undefined?null:obj.wholeSaleDiscountOrCommissionType.value,
        wholeSaleDiscountFrom:obj.wholeSaleDiscountFrom??null,
        wholeSaleDiscountTo:obj.wholeSaleDiscountTo??null,
        displayOrderMaster:obj.displayOrderMaster??null,
        displayOrderSlave:obj.displayOrderSlave??null,
        inventoryMin:obj.inventoryMin??null,
        inventoryMax:obj.inventoryMax??null,
        affiliateScript:obj.affiliateScript??null,
        price:obj.price??null,
        wholeSalePrice:obj.wholeSalePrice??null,
        productCode:obj.productCode??null,
        iranCode:obj.iranCode??null,
        barCode:obj.barCode??null,
        productGroupIds: obj.productGroupIds === null || obj.productGroupIds.length === 0?null:obj.productGroupIds.map(item => item.value),
        metaDataGroupIds: obj.metaDataGroupIds === null || obj.metaDataGroupIds.length === 0?null:obj.metaDataGroupIds.map(item => item.value),
        productTransportIds: obj.productTransportIds === null || obj.productTransportIds.length === 0?null:obj.productTransportIds.map(item => item.value),
        type: obj.type === null || obj.type === undefined?null:obj.type.value,
        producerId: obj.producerId === null || obj.producerId === undefined?null:obj.producerId.value,
        supplierId: obj.supplierId === null || obj.supplierId === undefined?null:obj.supplierId.value,
        templatePage: obj.templatePage === null || obj.templatePage === undefined?null:obj.templatePage.value,
        maxContent:obj.maxContent??null,
        stateShow:obj.stateShow??null,
        stateAndroid:obj.stateAndroid??null,
        sendingStates: obj.sendingStates === null || obj.sendingStates.length === 0?null:obj.sendingStates.map(item => item.value),
        stateGoogle:obj.stateGoogle??null,
        isInventory:obj.isInventory,
        stateFreePost:obj.stateFreePost??null,
        image:obj.image??null,
        transportInsuranceCost:obj.transportInsuranceCost??null,
        transportLength:obj.transportLength??null,
        transportWidth:obj.transportWidth??null,
        transportHeight:obj.transportHeight??null,
        transportWeight:obj.transportWeight??null,
        isMetaDataGroupChanged:obj.isMetaDataGroupChanged??null,
        filterId: obj.filterId === null || obj.filterId === undefined?null:obj.filterId.value,
        attachments:files??null,
        imagePath:obj.imagePath??null,
        concurrencyStamp:obj.concurrencyStamp??null,
        surveyGroupId: obj.surveyGroupId === null || obj.surveyGroupId === undefined?null:obj.surveyGroupId.value,
    };
    return data
}

export const handleCreateProductFormData=(obj,isEdit)=>{
    const formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    for (const key in obj) {
        if (key === 'seoKeyWords' && obj[key] !== null) {
            obj[key].forEach((keyword) => {
                appendIfNotNull(key,keyword);
            });
        }
        else if(key === 'attachments'){
            if(isEdit){
                for (let i = 0; i < obj.attachments.length; i++) {
                    if(obj.attachments[i].file === null && obj.attachments[i].productAttachId === null){

                    }
                    else
                    {
                        if(obj.attachments[i].file !== null && obj.attachments[i].productAttachId !== null ){
                            formData.append(`Attachments[${i}].filePath`,obj.files[i].filePath);
                        }
                        appendIfNotNull(`Attachments[${i}].file`,obj.attachments[i].file);
                        appendIfNotNull(`Attachments[${i}].attachType`,  obj.attachments[i].attachType.value);
                        appendIfNotNull(`Attachments[${i}].caption`, obj.attachments[i]?.caption );
                        appendIfNotNull(`Attachments[${i}].groupOrder`, obj.attachments[i]?.groupOrder );
                        appendIfNotNull(`Attachments[${i}].productAttachId`, obj.attachments[i].productAttachId );
                    }
                }
            }
            else{
                for (let i = 0; i < obj.attachments.length; i++) {
                    if(obj.attachments[i].file !== null){
                        appendIfNotNull(`Attachments[${i}].file`,obj.attachments[i].file)
                        appendIfNotNull(`Attachments[${i}].attachType`, obj.attachments[i].attachType.value);
                        appendIfNotNull(`Attachments[${i}].caption`, obj.attachments[i]?.caption);
                        appendIfNotNull(`Attachments[${i}].groupOrder`, obj.attachments[i]?.groupOrder);
                    }else{

                    }
                }
            }

        }
        else if(key === 'releaseDateTime' ||
            key === 'discountFrom' ||
            key === 'discountTo' ||
            key === 'wholeSaleDiscountFrom' ||
            key === 'wholeSaleDiscountTo'){
            const date = new Date(obj[key]).toISOString();
            appendIfNotNull(key,date);
        }
        else if(
               key === 'sendingStates'
            || key === 'productTransportIds'
            || key === 'metaDataGroupIds'
            || key === 'productGroupIds'
            || key ===  'productSuggestionIds'
        )
        {
            if(obj[key]){
                obj[key].forEach((item,index) => {
                    appendIfNotNull(`${key}[${index}]`,item);
                });
            }

        }
        else if(key === 'concurrencyStamp'){
            if(isEdit){
                appendIfNotNull(key,obj[key]);
            }
        }

        else{
              appendIfNotNull(key,obj[key]);
        }

    }
    return formData;
}

export const handleCreateSlug = (title) => {
    const slug = title
        .replace(/[^\p{Letter}\d\s]/gu, "-") // غیر حرف و غیر عددی رو با dash جایگزین میکنه
        .replace(/\s+/g, "-") // فضای خالی رو با dash عوض میکنه
        .replace(/-+/g, "-"); // چندین dash  رو با یک dash  جابجا میکنه

    return slug;
};