import React, {useEffect, useState} from "react";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
export const PasswordFields=({t,index,element,handlePropertyChange})=>{
    const [initialSource, setInitialSource] = useState({
        difficultyTypes: [],
    });
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [responseDifficulty] = await Promise.all([
                GET(`/Enumerations/EnDifficulty`, {}, "IEnumerable"),
            ]);

            if (
                responseDifficulty.status === 200
            ) {
                const difficultyTypes = convertSelectDataHandler(responseDifficulty.data);
                setInitialSource({
                    difficultyTypes,
                });

                handlePropertyChange(index, "difficulty", difficultyTypes[0]);
            }else{
                handleApiError(responseDifficulty, setLoading(false))
            }
        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
    return (<>


        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">

            <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>
                <InputNumber
                    type="text"
                    showButtons
                    min={0}
                    value={element.properties.min}
                    onChange={(e) => handlePropertyChange(index, 'min', e.value)}
                />
            </div>
            <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>
                <InputNumber
                    type="text"
                    min={0}
                    showButtons
                    value={element.properties.max}
                    onChange={(e) => handlePropertyChange(index, 'max', e.value)}
                />
            </div>


            <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("نوع سختی")}</label>
                <Select
                    options={initialSource.difficultyTypes}
                    isClearable={false}
                    isSearchable={true}
                    placeholder={t("selection")}
                    classNamePrefix={"h_multi_select"}
                    menuPosition={"fixed"}
                    value={element.properties.difficulty}
                    onChange={(data) => handlePropertyChange(index, "difficulty", data)}
                />
            </div>

        </div>
    </>)

}