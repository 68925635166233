import React, { useState, useEffect, useContext } from "react";
import { MenuBarMobile } from "../SideBar/MenuBar-Mobile";

import { TopBar } from "../NavBar/TopBar";



import "primeicons/primeicons.css";
import "../../../Styles/dist/css/IR_app.css";
import { StateContext } from "../../Authentication/StateProvider";
import {MenuBarHorizontal} from "../SideBar/MenuBarHorizontal";
import {useCustomQuickAccess} from "../NavBar/QuickAccess/useCustomQuickAccess";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleGetUserWebsites} from "./masterLayoutService";
import {handleConvertQuickAccess} from "../NavBar/QuickAccess/quickAccessService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {useCustomRoutes} from "../Hooks/useCustomRoutes";
import {useTranslation} from "react-i18next";
import {FantasyModal} from "../UIParts/FantasyModal";
import Select from "react-select";

import {Tooltip} from "react-tooltip";
import {QuickAccessItem} from "../NavBar/QuickAccess/QuickAccessItem";
import {Link} from "react-router-dom";
import {BadgeNotification} from "../BadgeNotification";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";


//as it's pretty clear , the independent components are handled inside of this .
export const MasterLayoutHorizontal = (props) => {
    const { t } = useTranslation();
  const { children } = props;

    const [IsOpen,setIsOpen] = useState(false);
    const [initialSource,setInitialSource] = useState({targetTypes:[],listItems:[],notes:[],paymentNotification:0,websites:[]});
    const {allRoutes} = useCustomRoutes();
    const {handleSelectValue,quickAccess,setQuickAccess,handleDeleteSpeedLink}=useCustomQuickAccess(setInitialSource)


    const handleIsOpen=()=>{
        setIsOpen(true)
    }
    const handleCloseIsOpen=()=>{   setIsOpen(false);
        setQuickAccess({target: initialSource.targetTypes[0],slug:allRoutes[0],icon: '',title: ''});
    }

    const fetchData = async () => {
        try {
            // showSpinner();
            const [response, responseSpeedlinK,responseMainlayout] = await Promise.all([
                GET(`/Enumerations/EnTargetType`, {}, 'IEnumerable'),
                GET(`/UserSpeedLink/Links`, {}, 'IEnumerable'),
                GET(`/MainLayout`,{},'single')

            ]);

            if (response.status === 200 && responseSpeedlinK.status === 200 && responseMainlayout.status === 200) {
                const targets = convertSelectDataHandler(response.data);
                const {notes,paymentNotification,webSites}=responseMainlayout.data;
                const userWebsites = handleGetUserWebsites(webSites)
                setInitialSource({ targetTypes: targets, listItems: responseSpeedlinK.data,notes:notes,paymentNotification: paymentNotification,websites: userWebsites});
                setQuickAccess({ target: targets[0], slug: allRoutes[0], title: allRoutes[0].label, icon: allRoutes[0].icon });
            }
            // hideSpinner();
        } catch (e) {
            // hideSpinner();
        }
    }
    const handleSubmitQuickAccess=async ()=>{
        try {
            // showSpinner();
            const quickAccessData = handleConvertQuickAccess(quickAccess)
            const response = await POST(`/UserSpeedLink`,quickAccessData,false);
            if(response.status === 200){
                handleCloseIsOpen();
                // hideSpinner();
                const responseSpeedlinK = await GET(`/UserSpeedLink/Links`,{},'IEnumerable');
                if(responseSpeedlinK.status === 200){
                    setInitialSource(prevState => ({...prevState,listItems: responseSpeedlinK.data}))
                }else {
                    handleApiError(responseSpeedlinK);
                }
            }
            else {
                handleApiError(response);
            }

        }catch (e) {
            // hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[])




    const { lang } = useContext(StateContext);
    useEffect(() => {
        const body = document.body;
        if (lang === "en-US") {
            // import("../../../Styles/dist/css/En_app.css");
            body.classList.add("h-ltr");
        } else {
            body.classList.remove("h-ltr");
        }

        return () => {
            body.classList.remove("h-ltr");
        };
    }, [lang]);


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




  return (
      <>
          <div className={"harmony_body"}>
              <div className="app main-page dark:bg-themeDarkApp">
                  {windowWidth < 1280 && <MenuBarMobile />}
                  <MenuBarHorizontal/>
                  <div className="content dark:bg-themeDarkContent space-y-4">
                      <TopBar
                          notes={initialSource.notes}
                      />
                      { windowWidth > 767 && <>
                          <div className="flex justify-end items-center min-h-12 gap-3  py-1">

                              { windowWidth > 1280 && <>
                                  <div className={"flex-shrink-0 flex gap-2 items-center"}>
                                      <button type={"button"} className={"rounded-md bg-themeInformation dark:bg-themeDarkInformation text-white p-1 tooltip_class_quick_access hover:bg-themeHoverInformation duration-300"} onClick={() => handleIsOpen()}>
                                          <HPrimeIcon icon={"pi-plus"}/>
                                          <Tooltip anchorSelect={".tooltip_class_quick_access"} content={t("QuickAccess")} positionStrategy={"fixed"} />
                                      </button>
                                  </div>
                                  <div className={"flex-1 overflow-y-auto scrollbar-hidden scrollbar py-1 grid grid-cols-12"}>
                                      <div className={"flex gap-1 items-center col-span-12"}>
                                          {initialSource.listItems.length > 0 ? initialSource.listItems.map((item) => (
                                              <QuickAccessItem handleDeleteSpeedLink={handleDeleteSpeedLink} target={item.target} speedLinkId={item.speedLinkId} icon={item.icon} slug={item.slug} title={item.title}/>
                                          )):null
                                          }
                                      </div>
                                  </div>
                              </>
                              }

                              <div className={"flex gap-2 items-center"}>
                                  <Link to="/ServiceUpgrade"
                                        className="flex items-center gap-2 justify-center rounded-md bg-themeInformation dark:bg-themeDarkInformation hover:bg-themeHoverInformation text-white p-1 w-28">
                                      <HPrimeIcon icon={'pi-sparkles'}/>
                                      {t("Upgrade")}
                                  </Link>
                                  <Link to="/InvoiceRequest"
                                        className="flex items-center relative gap-2 justify-center rounded-md bg-themeInformation dark:bg-themeDarkInformation hover:bg-themeHoverInformation text-white p-1 w-28">
                                      <BadgeNotification
                                          count={initialSource.paymentNotification}
                                      />
                                      <HPrimeIcon icon={'pi-file-edit'}/>
                                      <h2>{t("Bill")}</h2>
                                  </Link>
                              </div>
                          </div>
                      </>
                      }
                      {children}
                  </div>
              </div>
          </div>

          <FantasyModal openModal={IsOpen} closeModal={handleCloseIsOpen} title={t("QuickAccess")}>
              <div className="space-y-3">
                  <div className={"flex items-center gap-2"}>
                      <div class=" space-y-1 flex-1">
                          <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                          <Select
                              options={allRoutes}
                              isMulti={false}
                              isSearchable={true}
                              onChange={(data)=>handleSelectValue(data,'slug')}
                              placeholder={t("selection")}
                              value={quickAccess.slug}
                              menuPosition={"fixed"}
                              classNamePrefix={"h_multi_select"}
                          />

                      </div>
                      <div class=" space-y-1 flex-1">
                          <label className="text-xs dark:text-gray-300">{t("DisplayStatus")}</label>
                          <Select
                              options={initialSource.targetTypes}
                              isMulti={false}
                              isSearchable={true}
                              onChange={(data)=>handleSelectValue(data,'target')}
                              placeholder={t("selection")}
                              value={quickAccess.target}
                              menuPosition={"fixed"}
                              classNamePrefix={"h_multi_select"}
                          />
                      </div>
                  </div>
                  <button type={'button'} onClick={()=>handleSubmitQuickAccess()} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("register")}</button>
              </div>
          </FantasyModal>
      </>


  );
};
