import React, {useState} from "react";

export const useExchangeBinder=(setErrors)=>{

    const [exchange,setExchange] = useState({exchangeMoneyId:null,currency:null,rateOnDollar:0,symbol:'',fee:0,feeCurrency:null,destinationCurrency:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setExchange({...exchange,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setExchange({...exchange,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue=(data,name)=>{
        setExchange({...exchange,[name]:data});
    }



    return {
        handleValue,
        handleNumberValue,
        handleSelectValue,
        exchange,
        setExchange
    }
}