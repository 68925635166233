import React from "react";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {useTranslation} from "react-i18next";


export const SlugItem=({item,index,handleFieldChange,handleCheckboxChange,siteMapFrequencies})=>{
    const { t } = useTranslation();

    const handleChange = (e) => {
        handleCheckboxChange(item.slugType,e.target.checked);
    };

    return (<>

        <div className={"grid grid-cols-1 gap-3 md:grid-cols-3 p-1 post-input dark:bg-themeDarkPostInput post-input-design"}>
            <div className={"flex gap-2 items-center md:col-span-2"}>
                <label htmlFor={item.label} className={"flex items-center flex-1  cursor-pointer"}>
                    <div>
                        <input type="checkbox" checked={item.isChecked} onChange={handleChange} className={"input-BackUp hidden"} id={item.label} />
                        <div className={"flex items-center gap-2 connect-BackUp "}>
                            <div className={"flex items-center gap-1"}>
                                <span className={"input-BackUp-span"}></span>
                            </div>
                            <div>
                                <h2 className={"text-xs dark:text-gray-300"}>{item.label}</h2>
                            </div>
                        </div>
                    </div>
                </label>
                <div className={"flex-shrink-0 w-16"}>
                    <InputText
                        value={item.priority}
                        placeholder={t("Priority")}
                        className={"input w-full"}
                        onInput={(e)=>handleFieldChange(item.slugType,'priority',e.target.value)}
                    />
                </div>
            </div>
            <div className={"md:col-span-1"}>
                <Select
                    options={siteMapFrequencies}
                    isMulti={false}
                    isSearchable={true}
                    onChange={(data)=>handleFieldChange(item.slugType,'siteMapFrequency',data)}
                    placeholder={t("selection")}
                    value={item.siteMapFrequency}
                    menuPosition={"fixed"}
                    classNamePrefix={"h_multi_select"}
                />
            </div>
        </div>

</>)
}