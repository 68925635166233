import { createSlice } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
    name: 'loading',
    initialState: { IsLoading: false },
    reducers: {
        setIsLoading: (state, action) => {
            state.IsLoading = action.payload; // Fix this line
        },
    },
});

export const { setIsLoading } = loadingSlice.actions;
export default loadingSlice.reducer;