import React, { useEffect, useState } from "react";



import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard } from "../../../Components/Dashboard/Dashboard";
import {Provider} from "react-redux";
import {Store} from '../../Redux/Store';
import { Logout } from "../../../Components/Authentication/Logout/Logout";
import { Notfound } from "../../../Components/Authentication/NotFounds/Notfound";
import Charts from "../../../Components/charts";
import { MasterAuth } from "../../../Components/Commans/Layouts/MasterAuth";
import { MasterLayout } from "../../../Components/Commans/Layouts/MasterLayout";
import { FileManager } from "../../../Components/Public/FtpManager/FileManager";
import { LanguageProvider } from "../Translations/LanguageProvider";
import { HandleAddTemplate } from "../../../Components/CMS/TemplateManagement/Templates/HandleAddTemplate";
import { HandleConfirmed } from "../../../Components/CMS/UserComment/HandleConfirmed";
import { HandleChartProduct } from "../../../Components/Shop/Product/ProductCharts/HandleChartProduct";
import { StateProvider } from "../../../Components/Authentication/StateProvider";
import {HandleAddPageMasterTemplate} from "../../../Components/GeneralManagement/TemplateMasters/HandleAddPageMasterTemplate";
import {HandleSourceCodePage} from "../../../Components/GeneralManagement/HandleSourceCodePage";
import {HandleCreateProperty} from "../../../Components/CMS/Contents/HandleCreateProperty";
import Test from "../../../Test";
import {AgTest} from "../../../AgTest";
import {HandleProfile} from "../../../Components/Public/Profile/HandleProfile";
import {HandleGeneralSetting} from "../../../Components/Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandleMetaCreateProperty} from "../../../Components/CMS/MetaData/HandleMetaCreateProperty";
import {HandleDetailContent} from "../../../Components/CMS/Contents/HandleDetailContent";
import {HandleShortcutKeySetting} from "../../../Components/Public/HarmonySetting/Main/ShortCuts/HandleShortcutKeySetting";
import {HandlePicturesSettings} from "../../../Components/Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {HandleScriptSetting} from "../../../Components/Public/HarmonySetting/Main/Scripts/HandleScriptSetting";
import {HandleServiceUpgrade} from "../../../Components/Public/HandleBuyPackageSetting/HandleServiceUpgrade";
import {HandlePaymentPortSetting} from "../../../Components/Public/HarmonySetting/ShopManagement/Gateway/HandlePaymentPortSetting";
import {HandleDomainMainSetting} from "../../../Components/Public/HarmonySetting/Main/DomainsSettings/HandleDomainMainSetting";
import {HandleSecuritySetting} from "../../../Components/Public/HarmonySetting/Main/Securities/HandleSecuritySetting";
import {HandleMetaTagExtraSetting} from "../../../Components/SEO/Highliter/HandleMetaTagExtraSetting";
import {HandleBackUpSetting} from "../../../Components/Public/HarmonySetting/Main/Backups/HandleBackUpSetting";
import {HandleCleanTempSetting} from "../../../Components/Public/HarmonySetting/Main/DataCleans/HandleCleanTempSetting";
import {HandleListTransactionsBank} from "../../../Components/Public/InvoiceRequest/Transactions/HandleListTransactionsBank";
import {HandleAddSmsNumber} from "../../../Components/Public/SMSManagement/AddingNumbers/HandleAddSmsNumber";
import {CallBack} from "../../../Components/Authentication/CallBacks/CallBack";
import {HandleAPIPostSetting} from "../../../Components/Public/HarmonySetting/ShopManagement/ProductTransports/HandleAPIPostSetting";
import {HandleShopGeneralSetting} from "../../../Components/Public/HarmonySetting/ShopManagement/ShopGeneralSettings/HandleShopGeneralSetting";
import {HandleRoutingSetting} from "../../../Components/Public/HarmonySetting/Main/Routings/HandleRoutingSetting";
import {HandleUserAccess} from "../../../Components/CRM/Users/UserAccess/HandleUserAccess";
import {MasterLayoutHorizontal} from "../../../Components/Commans/Layouts/MasterLayoutHorizontal";
import {HandleUserReminder} from "../../../Components/CRM/HandleUserEvents/UserReminer/HandleUserReminder";
import {HandleUploadDocumentUser} from "../../../Components/CRM/ReminderPersonsManagement/HandleUploadDocumentUser";
import {HandleResultPoll} from "../../../Components/CMS/Poll/PollResults/HandleResultPoll";
import {HandleResultDetailsPoll} from "../../../Components/CMS/Poll/PollResults/HandleResultDetailsPoll";
import {HandleDomain} from "../../../Components/GeneralManagement/WebSite/WebsiteEdit/Domains/HandleDomain";
import {HandleServer} from "../../../Components/GeneralManagement/WebSite/WebsiteEdit/Servers/HandleServer";
import {HandleInvoiceCartProduct} from "../../../Components/Shop/CartItemDetails/HandleInvoiceCartProduct";
import {HandleAddPageTemplate} from "../../../Components/CMS/TemplateManagement/Templates/HandleAddPageTemplate";
import {HandleMainMenu} from "../../../Components/Public/MenuManager/HandleMainMenu";
import {HandleUserInteractions} from "../../../Components/CRM/Users/HandleUserInteractions";
import {HandleAddTypeProduct} from "../../../Components/Shop/Product/ProductTypes/HandleAddTypeProduct";
import {HandleFormOrganize} from "../../../Components/Form/Organizations/OrganizationGroups/HandleFormOrganize";
import {HandleRoadMapTree} from "../../../Components/Form/RoadMaps/RoadMapsTree/HandleRoadMapTree";
import {HandleEditProfile} from "../../../Components/Public/Profile/ProfileEdit/HandleEditProfile";
import {HandleLogUser} from "../../../Components/CRM/PersonsManagement/Logs/HandleLogUser";
import {HandleDepartmentUser} from "../../../Components/OnlineSupport/Departments/HandleDepartmentUser";
import {NotPermitted} from "../../../Components/Authentication/Forbidden/NotPermitted";
import {HandleLogin} from "../../../Components/Authentication/Login/HandleLogin";
import {HandleForgetPassword} from "../../../Components/Authentication/ForgetPassword/HandleForgetPassword";
import {HandlePackageAdd} from "../../../Components/GeneralManagement/Packages/HandlePackageAdd";
import {HandleAddSite} from "../../../Components/GeneralManagement/WebSite/WebsiteCreate/websiteAdd/HandleAddSite";
import {HandleListSite} from "../../../Components/GeneralManagement/WebSite/WebsiteList/HandleListSite";
import {HandleAddMasterTemplate} from "../../../Components/GeneralManagement/TemplateMasters/HandleAddMasterTemplate";
import {HandlePersonManager} from "../../../Components/CRM/PersonsManagement/HandlePersonManager";
import {HandleListPersonManagement} from "../../../Components/CRM/PersonsManagement/UserList/HandleListPersonManagement";
import {HandleUserGroup} from "../../../Components/CRM/Users/Groups/HandleUserGroup";
import {HandleUserRole} from "../../../Components/CRM/Users/UserRoles/HandleUserRole";
import {HandleUserPermission} from "../../../Components/CRM/Users/RolesPermissions/HandleUserPermission";
import {HandleReminderManagement} from "../../../Components/CRM/ReminderPersonsManagement/Remindars/HandleReminderManagement"
import {HandleMenuManagement} from "../../../Components/Public/MenuManager/HandleMenuManagement";
import {HandleContentAdd} from "../../../Components/CMS/Contents/ContentAdd/HandleContentAdd";
import {HandleContentList} from "../../../Components/CMS/Contents/ContentList/HandleContentList";
import {HandleContentGroup} from "../../../Components/CMS/Contents/ContentGroups/HandleContentGroup";
import {HandleInboxComment} from "../../../Components/CMS/UserComment/HandleInboxComment";
import {HandleNoConfirmedComment} from "../../../Components/CMS/UserComment/HandleNoConfirmedComment";
import {HandleTrashComment} from "../../../Components/CMS/UserComment/HandleTrashComment";
import {HandleTemplateShortCode} from "../../../Components/CMS/TemplateManagement/ShortCodes/HandleTemplateShortCode";
import {HandleChooseTemplate} from "../../../Components/CMS/TemplateManagement/TemplatePurchase/HandleChooseTemplate"
import {HandleAddAdvertise} from "../../../Components/CMS/Advertisement/Add/HandleAddAdvertise";
import {HandleListAdvertise} from "../../../Components/CMS/Advertisement/List/HandleListAdvertise";
import {HandleAddMetaData} from "../../../Components/CMS/MetaData/HandleAddMetaData";
import {HandleAddProduct} from "../../../Components/Shop/Product/Add/HandleAddProduct";
import {HandleListProduct} from "../../../Components/Shop/ProductList/HandleListProduct";
import {HandleGroupProduct} from "../../../Components/Shop/Product/Groups/HandleGroupProduct";
import {HandleCartProduct} from "../../../Components/Shop/ProductCart/HandleCartProduct";
import {HandleAddGuarrantyCustomer} from "../../../Components/Guarranty/HandleAddGuarrantyCustomer";
import {HandleGuarrantyProduct} from "../../../Components/Guarranty/HandleGuarrantyProduct";
import {HandleDiscount} from "../../../Components/Discount/DiscountCampagin/HandleDiscount";
import {HandleAddDiscount} from "../../../Components/Discount/DiscountCodes/HandleAddDiscount";
import {HandleCommission} from "../../../Components/Commission/HandleCommission";
import {HandleBuildForm} from "../../../Components/Form/CreateForm/FormBuilders/HandleBuildForm";
import {HandleRoadMapForm} from "../../../Components/Form/RoadMaps/HandleRoadMapForm";
import {HandleSettingsFilter} from "../../../Components/Public/HarmonySetting/Filter/HandleSettingsFilter";
import {HandleAddShopMetaData} from "../../../Components/Shop/Product/MetaData/HandleAddShopMetaData";
import {HandleAddRedirect} from "../../../Components/SEO/Redirects/HandleAddRedirect";
import {HandleStatistics} from "../../../Components/Statistics/HandleStatistics";
import {HandlePublicOnlineSupport} from "../../../Components/OnlineSupport/Tickets/HandlePublicOnlineSupport";
import {HandleDepartment} from "../../../Components/OnlineSupport/Departments/HandleDepartment";
import {HandlePublicOnlineTicket} from "../../../Components/OnlineSupport/Tickets/HandlePublicOnlineTicket";
import {HandleSendSms} from "../../../Components/Public/SMSManagement/SendingSms/HandleSendSms";
import {HandleInboxSms} from "../../../Components/Public/SMSManagement/InboxMessages/HandleInboxSms";
import {HandleSmsSecretery} from "../../../Components/Public/SMSManagement/Secretery/HandleSmsSecretery";
import {HandleSendBoxSms} from "../../../Components/Public/SMSManagement/MessageSentList/HandleSendBoxSms";
import {HandleMasterSetting} from "../../../Components/Public/HarmonySetting/MasterSetting/HandleMasterSetting";
import {HandleAddServer} from "../../../Components/GeneralManagement/Servers/HandleAddServer";
import {HandleInformationListForm} from "../../../Components/Form/FormInformations/HandleInformationListForm";
import {HandleDataEntranceForm} from "../../../Components/Form/DataEntrance/HandleDataEntranceForm";
import {HandleHistoryGuarranty} from "../../../Components/Guarranty/HandleHistoryGuarranty";
import {Wallet} from "../../../Components/Commans/Profile/WalletCharge/Wallet";
import {HandlePaymentGateways} from "../../../Components/GeneralManagement/GateWay/GateWayAdd/HandlePaymentGateways";
import {HandleWithdrawalRequest} from "../../../Components/GeneralManagement/withdrawal/HandleWithdrawalRequest";
import {HandleConnectAccounts} from "../../../Components/SocialMarketing/SocialAccounts/HandleConnectAccounts";
import {HandleDraft} from "../../../Components/SocialMarketing/Messengers/HandleDraft";
import {HandleBaseTariff} from "../../../Components/GeneralManagement/Tariffs/HandleBaseTariff";
import {HandleBackup} from "../../../Components/GeneralManagement/Backup/HandleBackup";
import {HandleInvoice} from "../../../Components/Public/InvoiceRequest/HandleInvoice";
import {HandleGoogleSiteMap} from "../../../Components/SEO/GoogleSiteMap/HandleGoogleSiteMap";
import {HandleBasketProduct} from "../../../Components/Public/Profile/HandleBasketProduct";
import {HandleFavoriteListProducts} from "../../../Components/Public/Profile/HandleFavoriteListProducts";
import {Conversation} from "../../../Components/OnlineSupport/Tickets/Conversation";
import {TicketGrid} from "../../../Components/OnlineSupport/Tickets/TicketGrid";
import {OnlineSupportMaster} from "../../../Components/OnlineSupport/Tickets/OnlineSupportMaster";
import {HandleTicketRegistration} from "../../../Components/OnlineSupport/Tickets/NewTicketRegistration/HandleTicketRegistration";
import {QrCode} from "../../../QrCode";
import {
  HandleSmartNotificationSetting
} from "../../../Components/Public/HarmonySetting/Main/Notifications/HandleSmartNotificationSetting";
import {HandleDataEntrancePoll} from "../../../Components/CMS/Poll/PollParticipation/HandleDataEntrancePoll";
import {HandleAddSurveys} from "../../../Components/CMS/Poll/HandleAddSurveys";
import {HandleListSurveys} from "../../../Components/CMS/Poll/HandleListPoll";
import {HandleExchangeRate} from "../../../Components/GeneralManagement/Exchange/HandleExchangeRate";
import {HandleSmsPattern} from "../../../Components/Public/SMSManagement/MessagePattern/HandleSmsPattern";
import {HandleWebSitePackages} from "../../../Components/GeneralManagement/WebSite/WebsiteEdit/Packages/HandleWebSitePackages";
import {HandleAdminCleaner} from "../../../Components/GeneralManagement/DataClean/HandleAdminCleaner";
import {HandleWebsiteTemplate} from "../../../Components/GeneralManagement/WebSite/WebsiteEdit/Templates/HandleWebsiteTemplate";
import {HandleWebsitetUpgrade} from "../../../Components/GeneralManagement/WebSite/WebsiteUpgrage/List/HandleWebsitetUpgrade";
import {Verify} from "../../../Components/Public/InvoiceRequest/Verify";
import {HandleProjectDocument} from "../../../Components/GeneralManagement/Documents/Add/HandleProjectDocument";
import {HandleAddBankAccount} from "../../../Components/GeneralManagement/BankAccounts/HandleAddBankAccount";
import {HandleListProjectDocument} from "../../../Components/GeneralManagement/Documents/DocumentList/HandleListProjectDocument";
import {HandleHistoryProjectDocument} from "../../../Components/GeneralManagement/Documents/History/HandleHistoryProjectDocument";
import {HandleGuideProject} from "../../../Components/GeneralManagement/Helps/Add/HandleGuideProject";
import {HandleGuideListProject} from "../../../Components/GeneralManagement/Helps/List/HandleGuideListProject";
import {HandleGeneralManager} from "../../../Components/Public/HarmonySetting/GeneralManager/HandleGeneralManager";
import {HandleWebsiteUpgradeDetails } from "../../../Components/GeneralManagement/WebSite/WebsiteUpgrage/Details/HandleWebsiteUpgradeDetails";
import {HandleProductGroupDetail} from "../../../Components/Shop/Product/Groups/GroupDetails/HandleProductGroupDetail";
import {HandleProductMeta} from "../../../Components/Shop/Product/ProductMeta/HandleProductMeta";
import {HandlePaymentPort} from "../../../Components/GeneralManagement/GateWay/GatewayPort/HandlePaymentPort";
import {Raw} from "../../../Raw";
import {HandleAccountList} from "../../../Components/SocialMarketing/SocialAccountLists/HandleAccountList";
import {HandleMessengerPublishContent} from "../../../Components/SocialMarketing/Messengers/HandleMessengerPublishContent";
import {HandleAddMailAccount} from "../../../Components/EmailMarketing/HandleAddMailAccount";
import {HandleDashboardSetting} from "../../../Components/Public/HarmonySetting/Main/Dashboards/HandleDashboardSetting";
import {HandleMessengerPublicationList} from "../../../Components/SocialMarketing/Messengers/HandleMessengerPublicationList";
import {HandleMessengerComments} from "../../../Components/SocialMarketing/Messengers/HandleMessengerComments";
import {HandleAccountPage} from "../../../Components/SocialMarketing/SocialAccountLists/HandleAccountPage";
import {HandleChangePassword} from "../../../Components/Public/HarmonySetting/Main/PasswordsChange/HandleChangePassword";
import {HandleUserLogin} from "../../../Components/Users/HandleUserLogin";
import {HandleCustomField} from "../../../Components/CRM/HandleCustomField/HandleCustomField";
import {HandleUserAddress} from "../../../Components/CRM/Addresses/HandleUserAddress";
import {HandleSuperAdminDashboardArian} from "../../../Components/Dashboard/DashboardSuperAdminArian/HandleSuperAdminDashboardArian";
import {LogErrors} from "../../../Components/GeneralManagement/LogError/LogErrors";
import {HandleCreateForm} from "../../../Components/Form/CreateForm/HandleCreateForm";
import {HandleFormOrganizeItems} from "../../../Components/Form/Organizations/HandleFormOrganizeItems";
import {HandleDashboardDeveloperManagement} from "../../../Components/Dashboard/DashboardDeveloperManagement/HandleDashboardDeveloperManagement";
import {HandleMetaTagSettings} from "../../../Components/Public/HarmonySetting/Main/MetaTag/HandleMetaTagSettings";
import {HandleschemaSettings} from "../../../Components/Public/HarmonySetting/Main/Schema/HandleschemaSettings";
import {HandleUserDetails} from "../../../Components/CRM/PersonsManagement/UserDetails/HandleUserDetails";
import {HandleFrequentlyUsedNumbers} from "../../../Components/Public/SMSManagement/FrequentlyNumbers/HandleFrequentlyUsedNumbers";
import {HandleCommissionIndividualClams} from "../../../Components/Commission/HandleCommissionIndividualClams";
import {HandleUnansweredTicket} from "../../../Components/OnlineSupport/Tickets/HandleUnansweredTicket";
import {HandleExpiredTickets} from "../../../Components/OnlineSupport/Tickets/ExpiredTickets/HandleExpiredTickets";
import {HandleShipping} from "../../../Components/Shop/Shipping/HandleShipping";
import {HandleListCartable} from "../../../Components/UserPanel/Cartable/HandleListCartable";
import {HandleInboxEmail} from "../../../Components/EmailMarketing/HandleInboxEmail";
import {HandleDraftEmail} from "../../../Components/EmailMarketing/HandleDraftEmail";
// import {HandleSentBoxEmail} from "../../../Components/EmailMarketing/HandleSentBoxEmail";
import {HandleTrashEmail} from "../../../Components/EmailMarketing/HandleTrashEmail";
import {HandlePackageAddRole} from "../../../Components/GeneralManagement/Packages/HandlePackageAddRole";
import {HandleShipmentTracking} from "../../../Components/UserPanel/HandleShipmentTracking";
import {HandleFormAnswer} from "../../../Components/Form/DataEntrance/HandleFormAnswer";
import {HandleAddClass} from "../../../Components/Dashboard2/LMS/Class/HandleAddClass";
import {HandleListClass} from "../../../Components/Dashboard2/LMS/Class/HandleListClass";
import {HandleAddStudent} from "../../../Components/Dashboard2/LMS/Student/HandleAddStudent";
import {HandleAddGoods} from "../../../Components/Dashboard2/ACC/GoodsServices/HandleAddGoods";
import {HandleListGoods} from "../../../Components/Dashboard2/ACC/GoodsServices/HandleListGoods";
import {HandleListServices} from "../../../Components/Dashboard2/ACC/GoodsServices/HandleListServices";
import {HandleAddAccount} from "../../../Components/Dashboard2/ACC/Account/HandleAddAccount";
import {HandleAddInvoice} from "../../../Components/Dashboard2/ACC/InvoiceManagament/HandleAddInvoice";
import {HandleListInvoice} from "../../../Components/Dashboard2/ACC/InvoiceManagament/HandlListInvoice";
import {HandleAddAutomatic} from "../../../Components/Dashboard2/ACC/Document/HandleAddAutomatic";
import {HandleAddDocument} from "../../../Components/Dashboard2/ACC/Document/HandleAddDocument";
import {HandleListDocument} from "../../../Components/Dashboard2/ACC/Document/HandleListDocument";
import {HandleReportOther} from "../../../Components/Dashboard2/ACC/Report/HandleReportOther";

import {
  HandleReportListInterbankTransactionBank
} from "../../../Components/Dashboard2/ACC/Report/HandleReportListInterbankTransactionBank";
import {
  HandleAddInterbankTransfer
} from "../../../Components/Dashboard2/ACC/AutomaticRegistration/HandleAddInterbankTransfer";
import {HandleRegisteredChegue} from "../../../Components/Dashboard2/ACC/Chegue/HandleRegisteredChegue";
import {HandlePassingListChegue} from "../../../Components/Dashboard2/ACC/Chegue/HandlePassingListChegue";
import {HandleFinancialHeadline} from "../../../Components/Dashboard2/ACC/Basic/HandleFinancialHeadline";
import {HandleMasterSetting2} from "../../../Components/Dashboard2/Public/HarmonySetting2/MasterSetting2/HandleMasterSetting2";
import {HandleHRMListPersonManagement} from "../../../Components/Dashboard2/HRM/Personnel/HandleHRMListPersonManagement";
import {HandleHRMAddPersonManagement} from "../../../Components/Dashboard2/HRM/Personnel/HandleHRMAddPersonManagement";
import {HandleHRMPersonnelRules} from "../../../Components/Dashboard2/HRM/Personnel/HandleHRMPersonnelRules";
import { HandelUserContract } from "../../../Components/CRM/Users/UserContracts/HandelUserContract";
import {HandleUserFamily} from "../../../Components/CRM/UserInsurance/FamilyInsurance/HandleUserFamily";
import {HandlePackageGroup} from "../../../Components/GeneralManagement/PackageGroups/HandlePackageGroup";
import {HarmonyTickets} from "../../../Components/HarmonySupport/OnlineTicket/Tickets/HarmonyTickets";
import {HarmonyTicketGrid} from "../../../Components/HarmonySupport/OnlineTicket/Tickets/HarmonyTicketGrid";
import {HandlePackageGroupRole} from "../../../Components/GeneralManagement/PackageGroups/HandlePackageGroupRole";
import {
  HandleSupportExpiredTickets
} from "../../../Components/HarmonySupport/OnlineTicket/Tickets/HandleSupportExpiredTickets";
import {HandleHarmonyOnlineTicket} from "../../../Components/HarmonySupport/OnlineTicket/Tickets/HandleHarmonyOnlineTicket";
import {HandleSendBoxEmail} from "../../../Components/EmailMarketing/HandleSendBoxEmail";
import {HarmonyHelp} from "../../../Components/HarmonyHelp/HarmonyHelp";
import {HandleFormDetails} from "../../../Components/Form/FormDetails/HandleFormDetails";
import {HandleUserSignatureForm} from "../../../Components/UserPanel/Cartable/CartableTabs/HandleUserSignatureForm";
import {Dashboard2} from "../../../Components/Dashboard2/Dashboard2";
import {MasterLayout2} from "../../../Components/Dashboard2/MasterLayout2";
import {HandleCommission2} from "../../../Components/Dashboard2/Commission2/HandleCommission2";
import {HandleDiscount2} from "../../../Components/Dashboard2/Discount2/DiscountCampagin/HandleDiscount2";
import {HandleKeyWords} from "../../../Components/Dashboard2/SEO/KeyWords/HandleKeyWords";
import {HandleReviewKeywords} from "../../../Components/Dashboard2/SEO/ReviewWords/HandleReviewKeywords";
import {HandleContentCreationMap} from "../../../Components/Dashboard2/SEO/ContentCreationMap/HandleContentCreationMap";
import {HandleMonthlyReportSEO} from "../../../Components/Dashboard2/SEO/MonthlyReport/HandleMonthlyReportSEO";
import {HandleAddPaymentGateways} from "../../../Components/Dashboard2/GeneralManagement/HandleAddPaymentGateways";
import {HandleTypeInventory} from "../../../Components/Inventory/HandleTypeInventory";
import {HandleAddInventory} from "../../../Components/Inventory/HandleAddInventory";
import {HandleInventoryCommunications} from "../../../Components/Inventory/HandleInventoryCommunications";
import {HandleInventoryProductionSeries} from "../../../Components/Inventory/HandleInventoryProductionSeries";
import {HandleAddGoodsInventory} from "../../../Components/Inventory/HandleAddGoodsInventory";
import {HandleInventoryReturnPurchase} from "../../../Components/Inventory/HandleInventoryReturnPurchase";
import {HandleTransferBetweenInventory} from "../../../Components/Inventory/HandleTransferBetweenInventory";
import {HandleInventoryExitRequest} from "../../../Components/Inventory/HandleInventoryExitRequest";
import {HandleInventoryEntryDocument} from "../../../Components/Inventory/HandleInventoryEntryDocument";
import {HandleInventoryExitDocument} from "../../../Components/Inventory/HandleInventoryExitDocument";
import {HandleInventoryReport} from "../../../Components/Inventory/HandleInventoryReport";
import {HandleAttendanceAdd} from "../../../Components/Attendance/HandleAttendanceAdd";
import {HandleAttendanceFinancialRules} from "../../../Components/Attendance/HandleAttendanceFinancialRules";
import {HandleAttendanceFinancialRulesAdd} from "../../../Components/Attendance/HandleAttendanceFinancialRulesAdd";
import {HandleAttendanceFinancePersonnelAdd} from "../../../Components/Attendance/HandleAttendanceFinancePersonnelAdd";
import {HandleAttendanceLeaveAndMission} from "../../../Components/Attendance/HandleAttendanceLeaveAndMission";
import {HandleAttendanceArchive} from "../../../Components/Attendance/HandleAttendanceArchive";
import {HandleAttendanceListShiftWork} from "../../../Components/Attendance/HandleAttendanceListShiftWork";
import {HandleAttendanceListWorkGroup} from "../../../Components/Attendance/HandleAttendanceListWorkGroup";
import {HandleAttendanceWorkGroup} from "../../../Components/Attendance/HandleAttendanceWorkGroup";
import {HandleAttendanceIndividualReport} from "../../../Components/Attendance/HandleAttendanceIndividualReport";
import {HandleAttendanceMonthlyReport} from "../../../Components/Attendance/HandleAttendanceMonthlyReport";
import {HandleAttendanceReports} from "../../../Components/Attendance/HandleAttendanceReports";
import {HandleComposePaperLess} from "../../../Components/PaperLess/HandleComposePaperLess";
import {HandleInboxPaperLess} from "../../../Components/PaperLess/HandleInboxPaperLess";
import {HandleReplayPaperLess} from "../../../Components/PaperLess/HandleReplayPaperLess";
import {HandleSendboxPaperLess} from "../../../Components/PaperLess/HandleSendboxPaperLess";
import {HandleArchivePaperLess} from "../../../Components/PaperLess/HandleArchivePaperLess";
import {HandleCreateCenter} from "../../../Components/Dashboard2/Reservation/HandleCreateCenter";
import {HandleListHotel} from "../../../Components/Dashboard2/Reservation/HandleListHotel";
import {HandleReservationHotel} from "../../../Components/Dashboard2/Reservation/HandleReservationHotel";
import {HandleListReservationHotel} from "../../../Components/Dashboard2/Reservation/HandleListReservationHotel";
import {HandleAddRoom} from "../../../Components/Dashboard2/Reservation/HandleAddRoom";
import {HandleListRoom} from "../../../Components/Dashboard2/Reservation/HandleListRoom";
import {HandleAddProject} from "../../../Components/Dashboard2/ProjectManagement/HandleAddProject";
import {HandleDetailsProject} from "../../../Components/Dashboard2/ProjectManagement/HandleDetailsProject";
import {HandleReportDetailsProject} from "../../../Components/Dashboard2/ProjectManagement/HandleReportDetailsProject";
import {HandleInstructionsProject} from "../../../Components/Dashboard2/ProjectManagement/HandleInstructionsProject";
import {
  HandleGeneralPeperlessSetting
} from "../../../Components/Dashboard2/Public/PeperlessManagement/HandleGeneralPeperlessSetting";
import {
  HandleGeneralReservationSetting
} from "../../../Components/Dashboard2/Public/Reservation/HandleGeneralReservationSetting";
import {HandleGeneralAccSetting} from "../../../Components/Dashboard2/Public/AccManagement/HandleGeneralAccSetting";
import {
  HandleAttendanceSettings
} from "../../../Components/Dashboard2/Public/HarmonySetting2/AttendanceManagement/HandleAttendanceSettings";
import {HandleUserInsurance} from "../../../Components/CRM/UserInsurance/HandleUserInsurance";
import {HandleQuickSetup, Install} from "../../../Components/QuickSetup/HandleQuickSetup";
import {
  HandleAdminDashboardArian
} from "../../../Components/Dashboard2/Dashboard22/DashboardAdminArian/HandleAdminDashboardArian";
import {
  HandleDashboardCRMManagement
} from "../../../Components/Dashboard2/Dashboard22/DashboardCRMManagement/HandleDashboardCRMManagement";
import {
  HandleDashboardSalesManagement
} from "../../../Components/Dashboard2/Dashboard22/DashboardSalesManagement/HandleDashboardSalesManagement";
import {
  HandleDashboardProductionManagement
} from "../../../Components/Dashboard2/Dashboard22/DashboardProductionManagement/HandleDashboardProductionManagement";
import {
  HandleDashboardCommerceManagement
} from "../../../Components/Dashboard2/Dashboard22/DashboardCommerceManagement/HandleDashboardCommerceManagement";
import {
  HandleDashboardHRMManagement
} from "../../../Components/Dashboard2/Dashboard22/DashboardHRMManagement/HandleDashboardHRMManagement";
import {
  HandleDashboardSupport
} from "../../../Components/Dashboard2/Dashboard22/DashboardSupport/HandleDashboardSupport";
import {
  HandleDashboardDigitalMarketing
} from "../../../Components/Dashboard2/Dashboard22/DashboardDigitalMarketing/HandleDashboardDigitalMarketing";
import {
  HandleDashboardInventoryManagement
} from "../../../Components/Dashboard2/Dashboard22/DashboardInventoryManagement/HandleDashboardInventoryManagement";
import {HandleBusinessSettings} from "../../../Components/Public/HarmonySetting/Main/Business/HandleBusinessSettings";

// import {HandlerFtpManager} from "../../../Components/Public/FtpManager/Ftp/HandlerFtpManager";

export const RoutesPath = () => {
  return (


        <Provider store={Store}>
          <LanguageProvider>
          <StateProvider>
          <BrowserRouter>
            <Routes>


              {/*داشبورد2*/}
              <Route path="/Dashboard2" element={ <MasterLayout2><Dashboard2 /></MasterLayout2> }/>
              <Route path="/Dashboard/SuperAdmin/Arian2" element={ <MasterLayout2><HandleSuperAdminDashboardArian /></MasterLayout2> }/>
              <Route path="/Dashboard/Admin/Arian2" element={ <MasterLayout2><HandleAdminDashboardArian /></MasterLayout2> }/>
              <Route path="/Dashboard/CRM/Management2" element={ <MasterLayout2><HandleDashboardCRMManagement /></MasterLayout2> }/>
              <Route path="/Dashboard/Sales/Management2" element={ <MasterLayout2><HandleDashboardSalesManagement /></MasterLayout2> }/>
              <Route path="/Dashboard/Production/Management2" element={ <MasterLayout2><HandleDashboardProductionManagement /></MasterLayout2> }/>
              <Route path="/Dashboard/Commerce/Management2" element={ <MasterLayout2><HandleDashboardCommerceManagement /></MasterLayout2> }/>
              <Route path="/Dashboard/HRM/Management2" element={ <MasterLayout2><HandleDashboardHRMManagement/></MasterLayout2> }/>
              <Route path="/Dashboard/Support2" element={ <MasterLayout2><HandleDashboardSupport /></MasterLayout2> }/>
              <Route path="/Dashboard/SocialMedia2" element={ <MasterLayout2><HandleDashboardDigitalMarketing /></MasterLayout2> }/>
              <Route path="/Dashboard/Developer2" element={ <MasterLayout2><HandleDashboardDeveloperManagement /></MasterLayout2> }/>
              <Route path="/Dashboard/Inventory2" element={ <MasterLayout2><HandleDashboardInventoryManagement /></MasterLayout2> }/>
              <Route path="/Commission2" element={<MasterLayout2><HandleCommission2/></MasterLayout2>}  />
              <Route path="/Discount2" element={<MasterLayout2><HandleDiscount2/></MasterLayout2>}  />


              //سئو
              <Route path="/SEO/KeyWords" element={ <MasterLayout2> <HandleKeyWords/> </MasterLayout2>} />
              <Route path="/SEO/ReviewKeyWords" element={ <MasterLayout2> <HandleReviewKeywords/> </MasterLayout2>} />
              <Route path="/SEO/CreationMap" element={ <MasterLayout2> <HandleContentCreationMap/> </MasterLayout2>} />
              <Route path="/SEO/MonthlyReport" element={ <MasterLayout2> <HandleMonthlyReportSEO/> </MasterLayout2>} />
              //سئو

              //مدیرکل
              <Route path="/Admin/PaymentGateways/Add" element={ <MasterLayout2><HandleAddPaymentGateways /></MasterLayout2>}/>
              //مدیرکل


              //مدیریت انبار
              <Route path="/Inventory/Type" element={<MasterLayout2><HandleTypeInventory/></MasterLayout2>}/>
              <Route path="/Inventory/Add" element={<MasterLayout2><HandleAddInventory/></MasterLayout2>}/>
              <Route path="/Inventory/Add/Communications" element={<MasterLayout2><HandleInventoryCommunications/></MasterLayout2>}/>
              <Route path="/Inventory/ProductionSeries" element={<MasterLayout2><HandleInventoryProductionSeries/></MasterLayout2>}/>
              <Route path="/Inventory/Goods/Add" element={<MasterLayout2><HandleAddGoodsInventory/></MasterLayout2>}/>
              <Route path="/Inventory/Goods/ReturnRequest" element={<MasterLayout2><HandleInventoryReturnPurchase/></MasterLayout2>}/>
              <Route path="/Inventory/TransferBetween" element={<MasterLayout2><HandleTransferBetweenInventory/></MasterLayout2>}/>
              <Route path="/Inventory/Goods/ExitRequest" element={<MasterLayout2><HandleInventoryExitRequest/></MasterLayout2>}/>
              <Route path="/Inventory/EntryDocument" element={<MasterLayout2><HandleInventoryEntryDocument/></MasterLayout2>}/>
              <Route path="/Inventory/ExitDocument" element={<MasterLayout2><HandleInventoryExitDocument/></MasterLayout2>}/>
              <Route path="/Inventory/Report" element={<MasterLayout2><HandleInventoryReport/></MasterLayout2>}/>
              //مدیریت انبار


              //سیستم حضور غیاب

              <Route path="/Attendance/Personnel" element={<MasterLayout2><HandleHRMListPersonManagement/></MasterLayout2>}/>
              <Route path="/Attendance/Personnel/Add" element={<MasterLayout2><HandleHRMAddPersonManagement/></MasterLayout2>}/>
              <Route path="/Attendance/Personnel/Rules" element={<MasterLayout2><HandleHRMPersonnelRules/></MasterLayout2>}/>
              {/*<Route path="/Attendance/Personnel/Contracts" element={<MasterLayout2><HandleHRMListContrac/></MasterLayout2>}/>*/}
              {/*<Route path="/Attendance/Personnel/FamilyInformation" element={<MasterLayout2><HandleHRMListFamilyInformatio/></MasterLayout2>}/>*/}
              <Route path="/Attendance/Add" element={<MasterLayout2><HandleAttendanceAdd/></MasterLayout2>}/>
              <Route path="/Attendance/FinancialRules" element={<MasterLayout2><HandleAttendanceFinancialRules/></MasterLayout2>}/>
              <Route path="/Attendance/FinancialRules/Add" element={<MasterLayout2><HandleAttendanceFinancialRulesAdd/></MasterLayout2>}/>
              <Route path="/Attendance/FinancialRules/Personnel" element={<MasterLayout2><HandleAttendanceFinancePersonnelAdd/></MasterLayout2>}/>
              <Route path="/Attendance/LeaveAndMission" element={<MasterLayout2><HandleAttendanceLeaveAndMission/></MasterLayout2>}/>
              <Route path="/Attendance/Archive" element={<MasterLayout2><HandleAttendanceArchive/></MasterLayout2>}/>
              <Route path="/Attendance/ShiftWork/List" element={<MasterLayout2><HandleAttendanceListShiftWork/></MasterLayout2>}/>
              <Route path="/Attendance/WorkGroup/List" element={<MasterLayout2><HandleAttendanceListWorkGroup/></MasterLayout2>}/>
              <Route path="/Attendance/WorkGroup" element={<MasterLayout2><HandleAttendanceWorkGroup/></MasterLayout2>}/>
              <Route path="/Attendance/List/IndividualReport" element={<MasterLayout2><HandleAttendanceIndividualReport/></MasterLayout2>}/>
              <Route path="/Attendance/List/MonthlyReport" element={<MasterLayout2><HandleAttendanceMonthlyReport/></MasterLayout2>}/>
              <Route path="/Attendance/List/Reports" element={<MasterLayout2><HandleAttendanceReports/></MasterLayout2>}/>


              //سیستم حضور غیاب

              {/*بخش جدا*/}


              //مدیریت مکاتبات
              <Route path="/PaperLess/Compose" element={ <MasterLayout2> <HandleComposePaperLess /> </MasterLayout2> } />
              <Route path="/PaperLess/Inbox" element={ <MasterLayout2> <HandleInboxPaperLess /> </MasterLayout2> } />
              <Route path="/PaperLess/Inbox/Replay" element={ <MasterLayout2> <HandleReplayPaperLess /> </MasterLayout2> } />
              <Route path="/PaperLess/SendBox" element={ <MasterLayout2> <HandleSendboxPaperLess /> </MasterLayout2> } />
              <Route path="/PaperLess/Archive" element={ <MasterLayout2> <HandleArchivePaperLess /> </MasterLayout2> } />
              //مدیریت مکاتبات



              //رزرواسیون
              <Route path="/Reservation/Center/Add" element={ <MasterLayout2> <HandleCreateCenter/> </MasterLayout2>} />
              <Route path="/Reservation/Center/List" element={ <MasterLayout2> <HandleListHotel/> </MasterLayout2>} />
              <Route path="/Reservation/Hotel/Reservation" element={ <MasterLayout2> <HandleReservationHotel/> </MasterLayout2>} />
              <Route path="/Reservation/Hotel/ListReservation" element={ <MasterLayout2> <HandleListReservationHotel/> </MasterLayout2>} />
              <Route path="/Reservation/Hotel/AddRoom" element={ <MasterLayout2> <HandleAddRoom/> </MasterLayout2>} />
              <Route path="/Reservation/Hotel/ListRoom" element={ <MasterLayout2> <HandleListRoom/> </MasterLayout2>} />
              //رزرواسیون


              //مدیریت حسابداری
              <Route path="/ACC/Goods_Services/GoodsAdd" element={ <MasterLayout2><HandleAddGoods /></MasterLayout2>}/>
              <Route path="/ACC/Goods_Services/GoodsList" element={<MasterLayout><HandleListGoods /></MasterLayout>}/>
              <Route path="/ACC/Goods_Services/ServicesList" element={<MasterLayout2> <HandleListServices /></MasterLayout2>} />
              <Route path="/ACC/Account/Add" element={ <MasterLayout2><HandleAddAccount /></MasterLayout2>}/>
              <Route path="/ACC/Invoice/Add" element={ <MasterLayout2><HandleAddInvoice /></MasterLayout2>}/>
              <Route path="/ACC/Invoice/List" element={<MasterLayout2><HandleListInvoice /></MasterLayout2>}/>
              <Route path="/ACC/Document/AutomaticAdd" element={<MasterLayout2><HandleAddAutomatic /></MasterLayout2>}/>
              <Route path="/ACC/Document/Add" element={ <MasterLayout2><HandleAddDocument /></MasterLayout2>}/>
              <Route path="/ACC/Document/List" element={ <MasterLayout2><HandleListDocument /></MasterLayout2>}/>
              {/*<Route path="/ACC/Report/General" element={<MasterLayout><FastRegister/></MasterLayout>}  />*/}
              {/*<Route path="/ACC/Report/Debtor" element={<MasterLayout><FastRegister/></MasterLayout>}  />*/}
              {/*<Route path="/ACC/Report/Creditor" element={<MasterLayout><FastRegister/></MasterLayout>}  />*/}
              <Route path="/ACC/Report/Other" element={<MasterLayout2><HandleReportOther /></MasterLayout2> }  />
              <Route path="/ACC/Report/InterbankTransactions/List" element={<MasterLayout2><HandleReportListInterbankTransactionBank /></MasterLayout2>}/>
              <Route path="/ACC/InterbankTransfer/Add" element={ <MasterLayout2><HandleAddInterbankTransfer /></MasterLayout2>}/>
              <Route path="/ACC/Cheque/Registered" element={ <MasterLayout2> <HandleRegisteredChegue /> </MasterLayout2> }/>
              {/*<Route path="/ACC/Cheque/Reminder" element={ <MasterLayout> <HandleReminderChegue /> </MasterLayout> }/>*/}
              <Route path="/ACC/Cheque/Passing" element={ <MasterLayout2> <HandlePassingListChegue /> </MasterLayout2> } />
              {/*<Route path="/ACC/Cheque/Reminder" element={ <MasterLayout2> <HandleReminderManagement /> </MasterLayout2> }/>*/}
              {/*<Route path="/ACC/Bank/Account" element={<MasterLayout><FastRegister/></MasterLayout>}  />*/}
              {/*<Route path="/ACC/Bank/List" element={<MasterLayout><FastRegister/></MasterLayout>}  />*/}
              <Route path="/ACC/Financial/Headline" element={ <MasterLayout2> <HandleFinancialHeadline />  </MasterLayout2>} />
              //مدیریت حسابداری


              //مدیریت پروژه
              <Route path="/Project/Add" element={ <MasterLayout2> <HandleAddProject/> </MasterLayout2> } />
              <Route path="/Project/Add/Details" element={ <MasterLayout2> <HandleDetailsProject /> </MasterLayout2> } />
              <Route path="/Project/ReportDetails" element={ <MasterLayout2> <HandleReportDetailsProject /> </MasterLayout2> } />
              <Route path="/Project/InstructionsProject" element={ <MasterLayout2> <HandleInstructionsProject /> </MasterLayout2> } />
              //مدیریت پروژه


              //مدیریت آموزشگاه
              <Route path="/LMS/Class/Add" element={ <MasterLayout2> <HandleAddClass /> </MasterLayout2> }/>
              <Route path="/LMS/Student/List/Add" element={ <MasterLayout2> <HandleAddStudent/> </MasterLayout2>} />
              <Route path="/LMS/Class/List" element={ <MasterLayout2> <HandleListClass /> </MasterLayout2>} />
              //مدیریت آموزشگاه



              {/*settings2*/}
              <Route path="/Harmony/Master/setting2" element={ <MasterLayout2> <HandleMasterSetting2 /> </MasterLayout2> } />

              {/*مدیریت نامه نگاری*/}
              <Route path="/Harmony/Setting/Peperless/General" element={ <MasterLayout2> <HandleGeneralPeperlessSetting /> </MasterLayout2> } />
              {/*مدیریت نامه نگاری*/}

              {/*مدیریت پروژه*/}
              <Route path="/Harmony/Setting/Project/General" element={ <MasterLayout2> <HandleGeneralPeperlessSetting /> </MasterLayout2> } />
              {/*مدیریت پروژه*/}

              {/*مدیریت رزرواسیون*/}
              <Route path="/Harmony/Setting/Reservation/General" element={ <MasterLayout2> <HandleGeneralReservationSetting /> </MasterLayout2> } />
              {/*مدیریت رزرواسیون*/}


              {/*مدیریت حسابداری*/}
              <Route path="/Harmony/Setting/Acc/General" element={ <MasterLayout2> <HandleGeneralAccSetting /> </MasterLayout2> } />
              {/*مدیریت حسابداری*/}

              {/*حضورغیاب*/}
              <Route path="/Harmony/Setting/Attendance" element={ <MasterLayout2> <HandleAttendanceSettings /> </MasterLayout2> } />
              {/*حضورغیاب*/}

              <Route path="/Harmony/Setting/Main/MetaTag" element={ <MasterLayout> <HandleMetaTagSettings /> </MasterLayout> } />

              <Route path="/Harmony/Setting/Main/Schema" element={ <MasterLayout> <HandleschemaSettings /> </MasterLayout> } />


              {/*settings2*/}



              {/*داشبورد2*/}



              //اعتبار سنجی
              <Route path="/" element={ <MasterAuth> <HandleLogin /> </MasterAuth> } />
              <Route path="/User/Login" element={ <HandleUserLogin />  } />
              <Route path="/QuickSetup" element={ <HandleQuickSetup />  } />
              <Route path="/Authentication/logout" element={<Logout />} />
              <Route path="/Authentication/ForgetPassword" element={ <MasterAuth> <HandleForgetPassword /> </MasterAuth> } />
              <Route path="*" element={<Notfound />} />
              <Route path="/NotPermitted" element={<NotPermitted />} />
              <Route path="/CallBack" element={<MasterLayout><CallBack /></MasterLayout> } />

              //اعتبار سنجی //داشبورد

              <Route path="/Dashboard" element={ <MasterLayout><Dashboard /></MasterLayout> }/>
              <Route path="/Dashboard/SuperAdmin/Arian" element={ <MasterLayout><HandleSuperAdminDashboardArian /></MasterLayout> }/>
              <Route path="/Dashboard/Admin/Arian" element={ <MasterLayout><HandleAdminDashboardArian /></MasterLayout> }/>
              <Route path="/Dashboard/CRM/Management" element={ <MasterLayout><HandleDashboardCRMManagement /></MasterLayout> }/>
              <Route path="/Dashboard/Sales/Management" element={ <MasterLayout><HandleDashboardSalesManagement /></MasterLayout> }/>
              <Route path="/Dashboard/Production/Management" element={ <MasterLayout><HandleDashboardProductionManagement /></MasterLayout> }/>
              <Route path="/Dashboard/Commerce/Management" element={ <MasterLayout><HandleDashboardCommerceManagement /></MasterLayout> }/>
              <Route path="/Dashboard/HRM/Management" element={ <MasterLayout><HandleDashboardHRMManagement/></MasterLayout> }/>
              <Route path="/Dashboard/Support" element={ <MasterLayout><HandleDashboardSupport /></MasterLayout> }/>
              <Route path="/Dashboard/SocialMedia" element={ <MasterLayout><HandleDashboardDigitalMarketing /></MasterLayout> }/>
              <Route path="/Dashboard/Developer" element={ <MasterLayout><HandleDashboardDeveloperManagement /></MasterLayout> }/>
              <Route path="/Dashboard/Inventory" element={ <MasterLayout><HandleDashboardInventoryManagement /></MasterLayout> }/>
              <Route path="/Dashboard/Memeber" element={ <MasterLayout>< HandleProfile/></MasterLayout> }/>


              //تست
              <Route path="/QrCode" element={<QrCode/>}/>
              <Route path="/chart" element={<Charts />} />
              <Route path="/test" element={ <MasterLayout> <Test/> </MasterLayout> } />
              <Route path="/AgTest" element={ <MasterLayout> <AgTest/> </MasterLayout> } />
              //تست


              //داشبورد

              //امکانات کاربران
              <Route path="/UserPanel/Cartable" element={<MasterLayout><HandleListCartable/> </MasterLayout> } />
              <Route path="/UserPanel/Cartable/Form/Answer/:formId/:formDataGroupId" element={<MasterLayout><HandleUserSignatureForm/></MasterLayout>}  />
              <Route path="/UserPanel/Website/List" element={ <MasterLayout> <HandleListSite/></MasterLayout> } />
              <Route path="/UserPanel/FavoriteProduct" element={ <MasterLayout> <HandleFavoriteListProducts/></MasterLayout> } />
              <Route path="/UserPanel/Basket" element={ <MasterLayout> <HandleBasketProduct/></MasterLayout> } />
              <Route path="/UserPanel/Edit" element={ <MasterLayout> <HandleEditProfile/></MasterLayout> } />
              <Route path="/UserPanel/Wallet" element={ <MasterLayout> <Wallet currentPath={'/UserPanel/Wallet'}/></MasterLayout> } />
              <Route path="/UserPanel/ShipmentTracking" element={ <MasterLayout> <HandleShipmentTracking/></MasterLayout> } />
              //امکانات کاربران

              //مدیریت کل
              {/*<Route path="/Admin/Role/Permission" element={<MasterLayout><HandlePermission/> </MasterLayout> } />*/}
              <Route path="/Admin/Package/Add" element={<MasterLayout><HandlePackageAdd/> </MasterLayout> } />
              <Route path="/Admin/Package/Group" element={ <MasterLayout><HandlePackageGroup/></MasterLayout>}/>
              <Route path="/Admin/Package/Group/Role/:packageGroupId" element={ <MasterLayout><HandlePackageGroupRole/></MasterLayout>}/>
              <Route path="/Admin/Package/List/Role/:packageId" element={<MasterLayout><HandlePackageAddRole/> </MasterLayout> } />
              <Route path="/Admin/Server/Add" element={ <MasterLayout><HandleAddServer /></MasterLayout>} />
              <Route path="/Admin/MasterTemplate/Add" element={ <MasterLayout><HandleAddMasterTemplate /></MasterLayout>}/>
              <Route path="/Admin/MasterTemplate/Page/Add" element={ <MasterLayout><HandleAddPageMasterTemplate/></MasterLayout>}/>
              <Route path="/OnlineEditor" element={ <MasterLayoutHorizontal><HandleSourceCodePage/></MasterLayoutHorizontal>}/>
              <Route path="/Admin/Website/Add" element={ <MasterLayout><HandleAddSite /></MasterLayout>}/>
              <Route path="/Admin/Website/List" element={ <MasterLayout><HandleListSite /></MasterLayout>}/>
              <Route path="/Admin/Website/List/Payment/Upgrade" element={ <MasterLayout><HandleWebsitetUpgrade /></MasterLayout>}/>
              <Route path="/Admin/Website/List/Payment/Upgrade/Details" element={ <MasterLayout><HandleWebsiteUpgradeDetails /></MasterLayout>}/>
              <Route path="/Admin/ServiceUpgrade" element={ <MasterLayout> <HandleServiceUpgrade currentPath={'/Admin/ServiceUpgrade'} /> </MasterLayout> } />
              <Route path="/Admin/Website/List/Template/Add" element={ <MasterLayout><HandleWebsiteTemplate /></MasterLayout>}/>
              <Route path="/Admin/Website/List/Packages" element={ <MasterLayout><HandleWebSitePackages /></MasterLayout>}/>
              <Route path="/Admin/Website/List/Domain" element={ <MasterLayout><HandleDomain /></MasterLayout>}/>
              <Route path="/Admin/Website/List/Server/:websiteId" element={ <MasterLayout><HandleServer /></MasterLayout>}/>
              <Route path="/Admin/PaymentGateways" element={ <MasterLayout><HandlePaymentGateways mode={'add'} /></MasterLayout>}/>
              <Route path="/Admin/PaymentGateways/PaymentPort" element={ <MasterLayout> <HandlePaymentPort/> </MasterLayout> } />
              <Route path="/Admin/Add/BankAccount" element={ <MasterLayout><HandleAddBankAccount /></MasterLayout>}/>
              <Route path="/Admin/PaymentGateways/Edit" element={ <MasterLayout><HandlePaymentGateways mode={'edit'} /></MasterLayout>}/>
              <Route path="/Admin/ExchangeRate/Add" element={ <MasterLayout><HandleExchangeRate/></MasterLayout>}/>
              <Route path="/Admin/BaseTariff/Add" element={ <MasterLayout><HandleBaseTariff/></MasterLayout>}/>
              <Route path="/Admin/Cleaner" element={ <MasterLayout><HandleAdminCleaner/></MasterLayout>}/>
              <Route path="/Admin/Backup" element={ <MasterLayout><HandleBackup/></MasterLayout>}/>
              <Route path="/Admin/ProjectDocument" element={ <MasterLayout><HandleProjectDocument/></MasterLayout>}/>
              <Route path="/Admin/ProjectDocument/List" element={ <MasterLayout><HandleListProjectDocument/></MasterLayout>}/>
              <Route path="/Admin/ProjectDocument/List/History" element={ <MasterLayout><HandleHistoryProjectDocument/></MasterLayout>}/>
              <Route path="/Admin/Guide" element={ <MasterLayout><HandleGuideProject/></MasterLayout>}/>
              <Route path="/Admin/Guide/List" element={ <MasterLayout><HandleGuideListProject/></MasterLayout>}/>
              <Route path="/Admin/LogError" element={ <MasterLayoutHorizontal><LogErrors/></MasterLayoutHorizontal>}/>
              //مدیریت کل

              //مدیریت اشخاص
              <Route path="/CRM/User/Add" element={<MasterLayout><HandlePersonManager /></MasterLayout>}/>
              <Route path="/CRM/User/Group" element={ <MasterLayout> <HandleUserGroup/></MasterLayout> } />
              <Route path="/CRM/User/Role" element={ <MasterLayout> <HandleUserRole/></MasterLayout> } />
              <Route path="/CRM/User/List" element={<MasterLayout><HandleListPersonManagement /></MasterLayout>}/>
              <Route path="/CRM/User/List/Details" element={<MasterLayout><HandleUserDetails/></MasterLayout>}/>
              <Route path="/CRM/User/List/Address" element={<MasterLayout><HandleUserAddress /></MasterLayout>}/>
              <Route path="/CRM/User/List/Log" element={<MasterLayout><HandleLogUser /></MasterLayout>}/>
              <Route path="/CRM/User/List/Insurance/:userId/:fullName" element={<MasterLayout><HandleUserInsurance/></MasterLayout>}/>
              <Route path="/CRM/User/List/Insurance/Family/:insuranceId/:fullName" element={<MasterLayout><HandleUserFamily/></MasterLayout>}/>
              <Route path="/CRM/User/Role/Permission" element={ <MasterLayout> <HandleUserPermission /></MasterLayout> } />
              <Route path="/CRM/User/List/Access" element={ <MasterLayout> <HandleUserAccess/> </MasterLayout> }/>
              <Route path="/CRM/User/List/UploadDocument" element={<MasterLayout><HandleUploadDocumentUser/></MasterLayout>}/>
              <Route path="/CRM/User/List/UserInteractions" element={<MasterLayout><HandleUserInteractions/></MasterLayout>}/>
              <Route path="/CRM/User/Wallet" element={<MasterLayout><Wallet currentPath={'/CRM/User/Wallet'} /></MasterLayout>}/>
              <Route path="/CRM/User/WithdrawalRequest" element={<MasterLayout><HandleWithdrawalRequest/></MasterLayout>}/>
              <Route path="/CRM/User/Reminder/List" element={ <MasterLayout><HandleReminderManagement /></MasterLayout>}/>
              <Route path="/CRM/User/List/Reminder/Add" element={<MasterLayout><HandleUserReminder/></MasterLayout>}/>
              <Route path="/CRM/User/CustomField" element={<MasterLayout><HandleCustomField/></MasterLayout>}/>
              <Route path="/CRM/User/Contract/:userId/:fullName" element={<MasterLayout><HandelUserContract/></MasterLayout>}/>

              {/*<Route path="/CRM/User/Group" element={ <MasterLayout> <GroupPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/Report/CelebrationDate" element={<MasterLayout><FastRegister/></MasterLayout>}  />*/}
              {/*<Route path="/CRM/Report/Task" element={<MasterLayout><FastRegister/></MasterLayout>}  />*/}
              {/*<Route path="/CRM/Report/OnExpired" element={<MasterLayout><FastRegister/></MasterLayout>}  />*/}
              {/*<Route path="/CRM/User/List/Surveys" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/OnlineConversation" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/ChangePass" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/SpecialPermission" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/ChangeRoll" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/Reservasion" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/Comment" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/Cart" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/AccDocument" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/ChangeState" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/PaperLess" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/Email" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/Chat" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              {/*<Route path="/CRM/User/List/Garranty" element={<MasterLayout><ListPersonManagement /></MasterLayout>}/>*/}
              //مدیریت اشخاص //CRM//


              //مدیریت منو
              <Route path="/Public/MainMenu/List" element={ <MasterLayout> <HandleMenuManagement /></MasterLayout> } />
              <Route path="/Public/MainMenu/List/Add" element={ <MasterLayout> <HandleMainMenu /></MasterLayout> } />
              //مدیریت منو

              //CMS// //مدیریت محتوا
              <Route path="/CMS/Content/Add" element={ <MasterLayout><HandleContentAdd /> </MasterLayout>} />
              <Route path="/CMS/Content/List" element={ <MasterLayout><HandleContentList /> </MasterLayout> } />
              <Route path="/CMS/Content/List/AddProperty" element={ <MasterLayout><HandleCreateProperty/></MasterLayout>}/>
              <Route path="/CMS/Content/Group" element={ <MasterLayout> <HandleContentGroup /> </MasterLayout>} />
              <Route path="/CMS/Content/Group/Detail" element={ <MasterLayout> <HandleDetailContent/> </MasterLayout>} />
              <Route path="/CMS/MetaData/Add" element={ <MasterLayout> <HandleAddMetaData /> </MasterLayout>}/>
              <Route path="/CMS/MetaData/Add/AddProperty" element={ <MasterLayout> <HandleMetaCreateProperty url={'/CMS/MetaData/Add'}  /> </MasterLayout>}/>


              {/*نظرات کاربران*/}
              <Route path="/CMS/Comment/Inbox" element={ <MasterLayout> <HandleInboxComment /> </MasterLayout> } />
              <Route path="/CMS/Comment/Confirmed" element={ <MasterLayout> <HandleConfirmed /> </MasterLayout> } />
              <Route path="/CMS/Comment/NoConfirmed" element={ <MasterLayout> <HandleNoConfirmedComment /> </MasterLayout> } />
              <Route path="/CMS/Comment/Trash" element={ <MasterLayout> <HandleTrashComment /> </MasterLayout> } />
              {/*<Route path="/CMS/User/Group" element={<MasterLayout><AddAdvertise/></MasterLayout>}  />*/}
              {/*نظرات کاربران*/}


              {/*مدیریت قالب*/}
              <Route path="/CMS/Template/Add" element={ <MasterLayout> <HandleAddTemplate /> </MasterLayout> } />
              <Route path="/CMS/Template/Add/page" element={ <MasterLayout> < HandleAddPageTemplate/> </MasterLayout> } />
              <Route path="/CMS/Template/Choose" element={ <MasterLayout> <HandleChooseTemplate /> </MasterLayout> } />
              <Route path="/CMS/Template/ShortCode" element={ <MasterLayout> <HandleTemplateShortCode /> </MasterLayout> } />


              {/*تکلیفش معاوم نیست*/}
              {/*<Route path="/CMS/Template/Edit/OnlineEditor" element={<MasterLayoutHorizontal><HandleOnlineHTMLEditor /></MasterLayoutHorizontal>  }/>*/}
              {/*تکلیفش معاوم نیست*/}
              {/*مدیریت قالب*/}

              {/*نظرسنجی*/}
              <Route path="/CMS/Surveys/Add" element={ <MasterLayout> <HandleAddSurveys /> </MasterLayout> }  />
              <Route path="/CMS/Surveys/List" element={ <MasterLayout> <HandleListSurveys /> </MasterLayout> } />
              <Route path="/CMS/Surveys/List/DataSource" element={ <MasterLayout> <HandleDataEntrancePoll /> </MasterLayout> } />
              <Route path="/CMS/Surveys/List/Result" element={ <MasterLayout> < HandleResultPoll/> </MasterLayout> } />
              <Route path="/CMS/Surveys/List/ResultDetails" element={ <MasterLayout> < HandleResultDetailsPoll/> </MasterLayout> } />
              {/*نظرسنجی*/}

              {/*تبلیغات*/}
              <Route path="/CMS/Advertise/Add" element={ <MasterLayout> <HandleAddAdvertise /> </MasterLayout> } />
              <Route path="/CMS/Advertise/List" element={ <MasterLayout> <HandleListAdvertise /> </MasterLayout> } />

              {/*<Route path="/CMS/Properties/List" element={<MasterLayout><ListAdvertise/></MasterLayout>}  />*/}
              {/*تبلیغات*/}
              //مدیریت محتوا
              //CMS//

              {/*مدیریت فرم*/}
              <Route path="/Form/RoadMap" element={<MasterLayout><HandleRoadMapForm/></MasterLayout>}  />
              <Route path="/Form/Process/RodMap/:roadMapId" element={<MasterLayout><HandleRoadMapTree/></MasterLayout>}  />
              <Route path="/Form/Create" element={<MasterLayout><HandleCreateForm/></MasterLayout>}  />
              <Route path="/Form/Create/Build/:formId" element={<MasterLayout><HandleBuildForm/></MasterLayout>}  />
              <Route path="/Form/Organize" element={<MasterLayout><HandleFormOrganize/></MasterLayout>}  />
              <Route path="/Form/Organize/Items" element={<MasterLayout><HandleFormOrganizeItems/></MasterLayout>}  />
              <Route path="/Form/Answer/:formId/:formDataGroupId" element={<MasterLayout><HandleFormAnswer/></MasterLayout>}  />
              {/*حذف از لیست*/}
              {/*<Route path="/Form/DataSource" element={<MasterLayout><HandleDataSourceForm/></MasterLayout>}  />*/}
              {/*<Route path="/Form/DataSource/List" element={<MasterLayout><HandleListDataSourceForm/></MasterLayout>}  />*/}
              {/*حذف از لیست*/}
              <Route path="/Form/List/DataEntrance/:formId" element={<MasterLayout><HandleDataEntranceForm/></MasterLayout>}  />
              <Route path="/Form/List/Information/:formId" element={<MasterLayout><HandleInformationListForm/></MasterLayout>}  />
              <Route path="/Form/List/Information/Details/:formDataGroupId" element={<MasterLayout><HandleFormDetails/></MasterLayout>}  />

              {/*مدیریت فرم*/}



              //shop
              <Route path="/Shop/Product/Add" element={ <MasterLayout> <HandleAddProduct /></MasterLayout>}/>
              <Route path="/Shop/MetaData/Add" element={<MasterLayout><HandleAddShopMetaData /></MasterLayout>}/>
              <Route path="/Shop/MetaData/Add/AddProperty" element={ <MasterLayout> <HandleMetaCreateProperty url={'/Shop/MetaData/Add'} /> </MasterLayout>}/>
              <Route path="/Shop/Product/List" element={<MasterLayout><HandleListProduct /></MasterLayout>}/>
              <Route path="/Shop/Product/List/ProductMeta" element={<MasterLayout><HandleProductMeta/></MasterLayout>}/>
              <Route path="/Shop/Product/Type" element={ <MasterLayout> <HandleAddTypeProduct/></MasterLayout>}/>
              <Route path="/Shop/Product/List/Chart" element={<MasterLayout><HandleChartProduct /></MasterLayout>}/>
              <Route path="/Shop/Product/Group" element={<MasterLayout><HandleGroupProduct/></MasterLayout>}/>
              <Route path="/Shop/Product/Group/Detail" element={<MasterLayout><HandleProductGroupDetail/></MasterLayout>}/>
              <Route path="/Shop/Product/Cart" element={<MasterLayout><HandleCartProduct/></MasterLayout>}/>
              <Route path="/Shop/Product/Cart/Items" element={<MasterLayout><HandleInvoiceCartProduct/></MasterLayout>}/>
              <Route path="Shop/Shipping" element={<MasterLayout><HandleShipping/></MasterLayout>}/>

              <Route path="/Verify" element={<MasterLayout><Verify/></MasterLayout>}/>
              {/*<Route path="/Shop/Product/Report" element={<MasterLayout><AddAdvertise/></MasterLayout>}  />*/}
              //shop



              {/*ایجاد گارانتی*/}
              <Route path="/Guarantee/Add" element={<MasterLayout><HandleGuarrantyProduct/></MasterLayout>}/>
              <Route path="/Guarantee/Customer" element={<MasterLayout><HandleAddGuarrantyCustomer/></MasterLayout>}/>
              <Route path="/Guarantee/Customer/History" element={<MasterLayout><HandleHistoryGuarranty/></MasterLayout>}/>
              {/*ایجاد گارانتی*/}

              {/*تخفیف وجایزه*/}
              <Route path="/Discount/Add" element={<MasterLayout><HandleAddDiscount/></MasterLayout>}  />
              <Route path="/Discount" element={<MasterLayout><HandleDiscount/></MasterLayout>}  />

              {/*تخفیف وجایزه*/}
              {/*پورسانت*/}
              <Route path="/Commission" element={<MasterLayout><HandleCommission/></MasterLayout>}  />
              <Route path="/Commission/IndividualClaims" element={<MasterLayout><HandleCommissionIndividualClams/></MasterLayout>}  />


              {/*پورسانت*/}


              //CRM//


              //دیجیتال مارکتینگ

              <Route path="/SocialMarketing/Messengers/List" element={ <MasterLayout> <HandleAccountList/> </MasterLayout>} />
              <Route path="/SocialMarketing/Messengers/List/ConnectAccounts" element={ <MasterLayout> <HandleConnectAccounts/> </MasterLayout>} />
              <Route path="/SocialMarketing/Messengers/List/Page" element={ <MasterLayout> <HandleAccountPage/> </MasterLayout>} />
              <Route path="/SocialMarketing/Messengers/PublicationList" element={ <MasterLayout> <HandleMessengerPublicationList/> </MasterLayout>} />
              <Route path="/SocialMarketing/Messengers/PublishingContent" element={ <MasterLayoutHorizontal> <HandleMessengerPublishContent/> </MasterLayoutHorizontal>} />
              <Route path="/SocialMarketing/Messengers/Comments" element={ <MasterLayoutHorizontal> <HandleMessengerComments/> </MasterLayoutHorizontal>} />
              <Route path="/SocialMarketing/Draft" element={ <MasterLayout> <HandleDraft/> </MasterLayout>} />

              //دیجیتال مارکتینگ


              //ایمیل مارکتینگ
              <Route path="/EmailMarketing/Email/Add" element={ <MasterLayout> <HandleAddMailAccount/> </MasterLayout>} />
              <Route path="/EmailMarketing/Compose" element={ <MasterLayout> <HandleInboxEmail/> </MasterLayout>} />
              <Route path="/EmailMarketing/Draft" element={ <MasterLayout> <HandleDraftEmail/> </MasterLayout>} />
               <Route path="/EmailMarketing/SendBox" element={ <MasterLayout> <HandleSendBoxEmail/> </MasterLayout>} />
              <Route path="/EmailMarketing/Inbox" element={ <MasterLayout> <HandleInboxEmail/> </MasterLayout>} />
              <Route path="/EmailMarketing/Trash" element={ <MasterLayout> <HandleTrashEmail/> </MasterLayout>} />
              //ایمیل مارکتینگ



              //سامانه پیام کوتاه
              <Route path="/SmsManagment/AddSmsNumber" element={ <MasterLayout> <HandleAddSmsNumber/> </MasterLayout> } />
              <Route path="/SmsManagment/Send" element={ <MasterLayout> <HandleSendSms/> </MasterLayout>} />
              <Route path="/SmsManagment/Inbox" element={ <MasterLayout> <HandleInboxSms/> </MasterLayout>} />
              <Route path="/SmsManagment/SendBoxSms" element={ <MasterLayout> <HandleSendBoxSms/> </MasterLayout>} />
              <Route path="/SmsManagment/SmsSecretery" element={ <MasterLayout> <HandleSmsSecretery/> </MasterLayout>} />
              <Route path="/SmsManagment/SmsPattern" element={ <MasterLayout> <HandleSmsPattern/> </MasterLayout>} />
              <Route path="/SmsManagment/FrequentlyNumbers" element={ <MasterLayout> <HandleFrequentlyUsedNumbers/> </MasterLayout>} />

              //سامانه پیام کوتاه


              //سئو
              {/*<Route path="/SEO/KeyWords" element={ <MasterLayout> <HandleKeyWords/> </MasterLayout>} />*/}
              {/*<Route path="/SEO/ReviewKeyWords" element={ <MasterLayout> <HandleReviewKeywords/> </MasterLayout>} />*/}
              {/*<Route path="/SEO/CreationMap" element={ <MasterLayout> <HandleContentCreationMap/> </MasterLayout>} />*/}
              <Route path="/SEO/GoogleSiteMap" element={ <MasterLayout> <HandleGoogleSiteMap/> </MasterLayout>} />
              <Route path="/SEO/Redirect/Add" element={ <MasterLayout> <HandleAddRedirect/> </MasterLayout>} />
              {/*<Route path="/SEO/MonthlyReport" element={ <MasterLayout> <HandleMonthlyReportSEO/> </MasterLayout>} />*/}
              <Route path="/SEO/MetaTagextra" element={ <MasterLayout> <HandleMetaTagExtraSetting /> </MasterLayout> } />
              //سئو

              //پشتیبانی
              <Route path="/Support/Department" element={ <MasterLayout> < HandleDepartment/> </MasterLayout> } />
              <Route path="/Support/Ticket" element={<MasterLayout><HandleTicketRegistration /></MasterLayout>}/>
              <Route path="/Support/Ticket/List" element={<MasterLayout><TicketGrid /></MasterLayout>}/>
              <Route path="/Support/Ticket/Unanswered" element={ <MasterLayout> < HandleUnansweredTicket/> </MasterLayout> } />
              <Route path="/Support/ExpiredTickets" element={ <MasterLayout> < HandleExpiredTickets/> </MasterLayout> } />
              <Route path="/Support/Ticket/Conversation" element={<MasterLayout><Conversation /></ MasterLayout>}/>
              <Route path="/Support/OnlineSupport" element={ <MasterLayout> <HandlePublicOnlineSupport /> </MasterLayout> } />
              <Route path="/Support/OnlineTicket" element={ <MasterLayout>< HandlePublicOnlineTicket/> </MasterLayout> } />
              <Route path="/Support/Department/User" element={ <MasterLayout> < HandleDepartmentUser/> </MasterLayout> } />
              //پشتیبانی



              //پشتیبانی هارمونی

              <Route path="/Harmony/Support/Ticket" element={<MasterLayout><HarmonyTickets /></MasterLayout>}/>
              <Route path="/Harmony/Support/Ticket/List" element={<MasterLayout><HarmonyTicketGrid /></MasterLayout>}/>
              <Route path="/Harmony/Support/ExpiredTickets" element={ <MasterLayout> < HandleSupportExpiredTickets/> </MasterLayout> } />
              <Route path="/Harmony/Support/OnlineTicket" element={ <MasterLayoutHorizontal>< HandleHarmonyOnlineTicket/> </MasterLayoutHorizontal> } /><Route path="/Harmony/Support/OnlineTicket" element={ <MasterLayoutHorizontal>< HandleHarmonyOnlineTicket/> </MasterLayoutHorizontal> } />
              //پشتیبانی هارمونی

              //help
              <Route path="/Harmony/Help" element={ <MasterLayout> <HarmonyHelp/> </MasterLayout>} />
              //help

              {/*امرو بازدید*/}
              <Route path="/Statistics" element={ <MasterLayout> <HandleStatistics/> </MasterLayout>} />
              {/*امرو بازدید*/}

              //خبرنامه

              //لیست تراکنش ها
              <Route path="/Bank/Transaction/list" element={ <MasterLayout> <HandleListTransactionsBank/> </MasterLayout>} />
              //لیست تراکنش ها

              <Route path="/Authentication/FileManager" element={ <MasterLayoutHorizontal> <FileManager /> </MasterLayoutHorizontal> } />
              {/*<Route path="/FtpManager" element={ <MasterLayoutHorizontal> <HandlerFtpManager /> </MasterLayoutHorizontal> } />*/}
              {/*<Route path="/Public/MainMenu/Item" element={<MasterLayout><MenuManagement/></MasterLayout>}/>*/}

              <Route path="/ServiceUpgrade" element={ <MasterLayout> <HandleServiceUpgrade currentPath={'/ServiceUpgrade'} /> </MasterLayout> } />
              <Route path="/InvoiceRequest" element={ <MasterLayout> <HandleInvoice currentPath={'/InvoiceRequest'} /> </MasterLayout> } />


              //عمومی
              {/*تنظیمات عمومی*/}
              <Route path="/Harmony/Master/setting" element={ <MasterLayout> <HandleMasterSetting /> </MasterLayout> } />

              {/*public*/}
              <Route path="/Harmony/Setting/Main/Dashboard" element={ <MasterLayout> <HandleDashboardSetting /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/Business" element={ <MasterLayout> <HandleBusinessSettings/> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/General" element={ <MasterLayout> <HandleGeneralSetting/> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/Pictures" element={ <MasterLayout> <HandlePicturesSettings /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/Routing" element={ <MasterLayout> <HandleRoutingSetting /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/CleanTemp" element={ <MasterLayout> <HandleCleanTempSetting /> </MasterLayout> } />

              {/*public*/}

              {/*professional*/}
              <Route path="/Harmony/Setting/Main/Domain" element={ <MasterLayout> <HandleDomainMainSetting /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/Script" element={ <MasterLayout> <HandleScriptSetting/> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/ShortcutKey" element={ <MasterLayout> <HandleShortcutKeySetting /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/Security" element={ <MasterLayout> <HandleSecuritySetting /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/BackUp" element={ <MasterLayout> <HandleBackUpSetting /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Filter/Builder" element={<MasterLayout><HandleSettingsFilter/></MasterLayout>}  />
              {/*professional*/}

              {/*تنظیمات عمومی*/}
              <Route path="/Harmony/Setting/Main/ChangePassword" element={ <MasterLayout> <HandleChangePassword/> </MasterLayout> } />
              <Route path="/Harmony/Setting/Main/SmartNotification" element={ <MasterLayout> <HandleSmartNotificationSetting /> </MasterLayout> } />
              {/*تنظیمات عمومی*/}



              {/*تنظیمات مدیر کل*/}
              <Route path="/Admin/Settings" element={ <MasterLayout> <HandleGeneralManager /> </MasterLayout> } />
              {/*تنظیمات مدیر کل*/}

              {/*مدیریت فروشگاه*/}
              <Route path="/Harmony/Setting/Shop/General" element={ <MasterLayout> <HandleShopGeneralSetting /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Shop/ApiPost" element={ <MasterLayout> <HandleAPIPostSetting /> </MasterLayout> } />
              <Route path="/Harmony/Setting/Shop/PaymentPort" element={ <MasterLayout> <HandlePaymentPortSetting/> </MasterLayout> } />
              {/*مدیریت فروشگاه*/}


              {/*test*/}

              <Route path="/Raw" element={ <MasterLayout><Raw /></MasterLayout>}/>
              {/*test*/}

            </Routes>
          </BrowserRouter>
            </StateProvider>
          </LanguageProvider>
        </Provider>

  );
};