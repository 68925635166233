import React from "react";
import Select from "react-select";
import {Link} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const Notification=({item,index,handleChangeField,handleAddNotification,handleDeleteNotification,initialSource})=>{
    const { t } = useTranslation();



    return (<>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
            <div className="flex gap-2">
                <div  className={"mt-auto"}>
                    <div className="number_pluse bg-themeInformation dark:bg-themeDarkInformation text-white">
                        {index +1}
                    </div>
                </div>
                <div className="flex-1">
                    <div class="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("event")}</label>
                        <Select
                            options={initialSource.events}
                            placeholder={t("selection")}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                            value={item.event}
                            onChange={(data)=>handleChangeField(index,'event',data)}
                        />
                    </div>
                </div>
            </div>
            <div class="space-y-2 lg:col-span-2">
                <label className="text-xs dark:text-gray-300 flex justify-between">
                    {t("ChooseTemplate")}
                    <Link to={"/SmsManagment/SmsPattern"} className="text-themeInformation text-xs dark:text-themeDarkInformation cursor-pointer">({t("Add")})</Link>
                </label>
                <Select
                    options={initialSource.patterns}
                    placeholder={t("selection")}
                    classNamePrefix={"h_multi_select"}
                    menuPosition={"fixed"}
                    value={item.patternId}
                    onChange={(data)=>handleChangeField(index,'patternId',data)}
                />
            </div>
            <div className={"flex gap-2 items-end"}>
                <div class="space-y-1 flex-1">
                    <label className="text-xs dark:text-gray-300">{t("Status")}</label>
                    <input checked={item.stateShow} className="input input--switch " onChange={(e) => handleChangeField(index,'stateShow',e.target.checked)} type="checkbox"/>
                </div>
                <div className="flex gap-2">
                    <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white" >
                        <HPrimeIcon icon={"pi-plus"} onClick={()=>handleAddNotification()}/>
                    </button>
                    <button type="button" className="button bg-themeDanger small_button text-white" >
                        <HPrimeIcon icon={"pi-minus"} onClick={()=>handleDeleteNotification(index)}  />
                    </button>
                </div>
            </div>
        </div>


    </>)
}