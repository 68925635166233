import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {DELETE, GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";

import {AlertSetting, GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {GetDynamicPropertiesFromServer} from "./paymentGatewayService";
import queryString from "query-string";
import {GridButtonEdit} from "../../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../../Commans/Grid/GridButtonDelete";

export const usePaymentGatewayGrid=(setShowSpinner,fetchData,refresh,hideSpinner,initialSource,setGateway,setIsOpen,setIsEdit,Refresh,setProperties)=>{
    const redirectTo = useNavigate();
    const {t} = useTranslation();
    const [rowData, setRowData] = useState([]);
    const queryParams = queryString.parse(window.location.search);


    const handleGetPaymentGateway= async(pspAccountId)=>{
        try {
            setShowSpinner(true);
            setIsOpen(true);
            setIsEdit(true);
            const response = await GET(`/Payments/PSPAccount/${pspAccountId}`,{},'single');
            const properties = GetDynamicPropertiesFromServer(response.data.properties)
            setProperties(properties);
            const pspType = parseInt(queryParams.pspType);
            if(response.status === 200){
                setGateway({
                    pspAccountId:response.data.pspAccountId,
                    pspId: initialSource.paymentGateways.find((item)=>item.pspType === pspType),
                    name:response.data.name,
                    accountNumber:response.data.accountNumber,
                    cardNumber:response.data.cardNumber,
                    shebaNumber:response.data.shebaNumber,
                    isActive:response.data.isActive,
                    order:response.data.order,
                    bankId: initialSource.banks.find((item)=>item.value === response.data.bankId),
                    //properties
                })
                setShowSpinner(false);
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleDeletePspAccount = async (pspId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setShowSpinner(true)
                    const response = await DELETE(`/Payments/PSPAccount/${pspId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh()
                        handleSuccessMessage('با موفقیت حذف شد')
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("BankGateway"), minWidth: 120,
            cellRenderer:parameters=>
             <>
                {parameters.data.name}
             </>
        },

        { headerName: t("BankAccountNumber"),minWidth: 120,
            cellRenderer:parameters=>
                <>
                    {parameters.data.accountNumber??'-'}
                </>
        },


        { headerName: t("BankID"), minWidth: 170,
            cellRenderer:parameters=>
               <>
               {parameters.data.shebaNumber??'-'}
               </>
        },
        { headerName: t("cardNumber"), minWidth: 170,
            cellRenderer:parameters=>
           <>
               {parameters.data.cardNumber??'-'}
           </>
        },
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetPaymentGateway(params.data.pspAccountId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeletePspAccount(params.data.pspAccountId)}
                    />
                </div>
            }

        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',fromDate:null,toDate:null,IsRial:null,IsToman:null,IsDollar:null,IsEuro:null,IsPound:null,IsCNY:null,IsActive:null,IsDeActive:null});

    const SwitchFilters = [
        {name:'IsRial',lable:t("Rial"),value:filter.IsRial},
        {name:'IsToman',lable:t("Toman"),value:filter.IsToman},
        {name:'IsDollar',lable:t("Dollar"),value:filter.IsDollar},
        {name:'IsEuro',lable:t("Euro"),value:filter.IsEuro},
        {name:'IsPound',lable:t("Pound"),value:filter.IsPound},
        {name:'IsCNY',lable:t("Yuan"),value:filter.IsCNY},
        {name:'IsActive',lable:t("isActive"),value:filter.IsActive},
        {name:'IsDeActive',lable:t("isNotActive"),value:filter.IsDeActive},
    ]

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',fromDate:null,toDate:null,IsActive: null,IsDeActive: null,IsCNY: null,IsPound: null,IsEuro: null,IsDollar: null,IsToman: null,IsRial: null}));
        setSearch('');
    }

    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data.value}));
    }

    const handleFromDate=(dateTime)=>{
        setFilter(({...filter,fromDate:dateTime}));
    }
    const handleToDate=(dateTime)=>{
        setFilter(({...filter,toDate:dateTime}));
    }


    useEffect(() => {
        const loadData = async () => {
            try {
                setShowSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    fetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, hideSpinner);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setShowSpinner(false);
            }
        };
        loadData();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.fromDate,filter.toDate,filter.IsCNY,filter.IsRial,filter.IsDollar,filter.IsEuro,filter.IsPound,filter.IsToman,filter.IsActive,filter.IsDeActive]);

    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/Payments/PSPAccounts`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,FromDate:filter.fromDate,ToDate:filter.toDate,IsActive:filter.IsActive,IsDeActive:filter.IsDeActive,IsRial:filter.IsRial,IsCNY:filter.IsCNY,IsDollar:filter.IsDollar,IsEuro:filter.IsEuro,IsPound:filter.IsPound,IsToman:filter.IsToman,PSPId:queryParams.pspId}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter,
        handleFromDate,
        handleToDate
    }



}