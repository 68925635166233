import Joi from 'joi';

export const userGroupSchema = Joi.object({
    Title: Joi.string().required().messages({
        'any.required': 'عنوان گروه الزامیست',
        'string.empty': 'عنوان گروه الزامیست',
        'string.base':'عنوان گروه الزامیست',
    }),
}).options({ abortEarly: false });

