import React, {useEffect, useMemo, useState} from "react";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE, GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

import {Tooltip} from "react-tooltip";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {separateText} from "./shortCodeService";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";

export const useShortCodeGrid=(showSpinner,hideSpinner,refresh,initialSource,setShortCode,setIsEdit,setIsOpen,Refresh)=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [rowData, setRowData] = useState([]);

    const [visible, setVisible] = useState(false);

    const[message,setMessage]=useState('');
    const handleShowMessage =(visible,message)=>{
        setVisible(visible);
        setMessage(message);
    }



    const handleGetShortCode =async (recordId) => {
        try {
            setIsEdit(true);
            showSpinner();
            const response = await GET(`/Templates/GetSnippetCodeById`,{Id:recordId},'single')
            if(response.status === 200){
                const text = separateText(response.data.name);
                setShortCode({
                    id: response.data.id,
                    name:text,
                    type: response.data.type,
                    templateId: response.data.templateId === null?null: initialSource.templates.find((item) => item.value === response.data.templateId),
                    value :response.data.value
                });
                hideSpinner();
                setIsOpen(true)
            }else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    };





    const handleRemoveShortCode = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                showSpinner();
                const response = await DELETE(`/Templates/RemoveSnippetCode?Id=${id}`);
                if (response.status === 200) {
                    hideSpinner();
                    Refresh();
                    handleSuccessMessage(t("Templatehasbeensuccessfullydeleted"));
                }
                else {
                    handleApiError(response,hideSpinner);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const footerContent = (
        <div>
            <button type={"button"} className=" button flex w-28 tooltip_Copy gap-1 items-center bg-themeInformation dark:bg-themeDarkInformation hover:bg-themeHoverInformation text-white">
                <p>{t("Copyingthetext")}</p>
                <HPrimeIconSmall icon={'copy'} />
                <Tooltip anchorSelect=".tooltip_Copy" content={t("Copyingthetext")} />
            </button>
        </div>

    );



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("Title"),field: 'name', maxWidth: 140},
        { headerName: t("TagContent"),field: 'value',minWidth: 150,cellRenderer:params => {
                return <>
                    <div className={'cursor-pointer line-clamp-1'}  onClick={() => handleShowMessage(true,params.data.value)} >{params.data.value}</div>
                </>
            }},
        { headerName: t("LastEdit"),minWidth:170 ,maxWidth: 200,field: 'modificationDate',
            cellRenderer:params => {
                return <GridDateFomartter date={params.data.modificationDate} hasTime={true} />

            }
        },

        { headerName: t("نوع کد"),maxWidth: 120,field: 'modificationDate',
            cellRenderer:params => {
                return <>
                html&text
                </>

            }
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={() => handleGetShortCode(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleRemoveShortCode(params.data.id)}
                    />

                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',Type:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const resetFilters = ()=>{
        setFilter(({...filter,search:'',Type: null}));
        setSearch('');
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh,currentPage,data.pageSize,filter.search,filter.Type]);

    const OnGridReady = async () => {
        showSpinner();
        const response = await GET(`/Templates/GetSnippetCodeList`,{PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search},'PagedList');
        try {
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
                hideSpinner();
            }
            else {
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner()
        }
    }


return {
        rowData,
    handleShowMessage,
    message,
    visible,
    footerContent,
    columnDefs,
    autoGroupColumnDef,
    getRowHeight,
    handleSearch,
    filterValueHandler,
    filter,
    setSearch,
    search,
    currentPage,
    data,
    handlePreviousPage,
    handleCurrentPage,
    handlePageSizeCount,
    resetFilters,
    handleNextPage,
    defaultColDef,
    setVisible,
    setFilter


}




}