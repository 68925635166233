import React, {useState} from "react";

import {InputTextarea} from "primereact/inputtextarea";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../Commans/UIParts/MultiDataSelect";
import {TabMenu} from "../Commans/UIParts/TabMenu";
import {FileUploader} from "../Commans/Uploaders/FileUploader";
export const HandleComposePaperLess = () => {
  const [activeTab, setActiveTab] = useState("tab_one");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
  const tabs = [
    {
      id: "tab_one",
      label: "محتویات اصلی",
      icon: "file-text",
    },
    {
      id: "tab_two",
      label: "فایل ضمیمه",
      icon: "paperclip",
    },
  ];


  return(

        <div class="space-y-5">
          <div className="grid grid-cols-12 gap-5">
            <div class="col-span-12 lg:col-span-8">
              <div class="post box bg-white dark:bg-themeDarkSalt700">
                <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                <div class="post__content tab-content">
                  <div className={`tab-content__pane space-y-5 p-1 md:p-5  ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <div class=" space-y-2">
                        <label className="text-xs dark:text-gray-300">عنوان پیام:</label>
                        <InputText  className={"input w-full "} />
                      </div>
                      <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">گیرندگان:</label>
                        <MultiDataSelect placeholder={"انتخاب"} className={"w-full"} />
                      </div>
                    </div>
                    <div className="post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">متن پیام:</label>
                        <InputTextarea name={"excerpt"} rows={3} cols={30} className={"input w-full "} />
                      </div>
                    </div>
                  </div>
                  <div className={`tab-content__pane space-y-5 p-1 md:p-5  ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                    <div class="space-y-2">
                      <FileUploader
                          message={
                        "فایل ها را اینجا رها کنید یا برای آپلود کلیک کنید"
                      }
                          mode={"image"}
                          accept={"images/*"}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <div class="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-5">
                  <div class="space-y-1">
                    <label className="text-xs dark:text-gray-300">تنظیم کننده:</label>
                    <div>
                      <MultiDataSelect placeholder={"انتخاب"} className={"w-full"} />
                    </div>
                  </div>
                  <div class="space-y-1">
                    <label className="text-xs dark:text-gray-300">تاریخ پیام:</label>
                    <InputText type={"text"} className={"input w-full "} />
                  </div>
                  <div class="space-y-1">
                    <label className="text-xs dark:text-gray-300">زمان: </label>
                    <InputText type={"text"} className={"input w-full "} />
                  </div>
                  <div class="space-y-1">
                    <label className="text-xs dark:text-gray-300">اندیکاتور: </label>
                    <InputText type={"text"} className={"input w-full "} />
                  </div>
                  <div class="space-y-1">
                    <label  className="text-xs dark:text-gray-300"> ارجاع:</label>
                    <MultiDataSelect placeholder={"انتخاب"} className={"w-full"} />
                    {/*<div>*/}
                    {/*  <select className="select2 w-full">*/}
                    {/*    <option value="1">...</option>*/}
                    {/*    <option value="2">محمد اکبری</option>*/}
                    {/*    <option value="3">فرم پرداخت</option>*/}
                    {/*    <option value="3">فرم نمایندگی</option>*/}
                    {/*  </select>*/}
                    {/*</div>*/}
                  </div>
                  <div class=" space-y-1">
                    <label className="text-xs dark:text-gray-300"> فرم:</label>
                    <MultiDataSelect placeholder={"انتخاب"} className={"w-full"} />
                      {/*<select className="select2 w-full">*/}
                      {/*  <option value="1">...</option>*/}
                      {/*  <option value="2">فرم استخدام</option>*/}
                      {/*  <option value="3">فرم پرداخت</option>*/}
                      {/*  <option value="3">فرم نمایندگی</option>*/}
                      {/*</select>*/}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center lg:justify-end gap-3">
            <button className=" button w-24 bg-themeDanger  text-white ">انصراف</button>
            <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation ">ارسال</button>
          </div>
        </div>
  )
}