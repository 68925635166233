import Joi from "joi";
export const productSchema = Joi.object({
    name: Joi.string().required().trim().messages({
        'any.required': 'نام  محصول الزامیست',
        'string.empty': 'نام  محصول الزامیست',
        'string.base': 'نام  محصول  الزامیست '
    }),
    slug: Joi.string().required().trim().messages({
        'any.required': 'ادرس  محصول الزامیست',
        'string.empty': 'ادرس  محصول الزامیست',
        'string.base': 'ادرس  محصول  الزامیست '
    }),
});
