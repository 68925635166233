import React from "react";
import {useDispatch, useSelector} from 'react-redux';

import {
    setSelectedTheme,
    setCalendarView,
    setCalendarLanguage,
    setCalendarType,
    setDashboardType,
    setFontSize,
    setEditorType,
} from '../Redux/SettingReducer'; // Import your actions
import {setWebsitePermissions} from "./PermissionsReducer";

export const useDynamicDispatch = () => {
    const dispatch = useDispatch();

    const actionMap = {
        setSelectedTheme,
        setCalendarView,
        setCalendarLanguage,
        setCalendarType,
        setDashboardType,
        setFontSize,
        setEditorType,
        setWebsitePermissions
    };

    const handleDispatch = (actionType, value) => {
        const action = actionMap[actionType];
        if (action) {
            dispatch(action(value));
        }
        else {
            console.warn(`Action type ${actionType} is not defined in the actionMap.`);
        }
    };

    const handleGetReduxDispatch=(reducerName,stateName)=>{
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const output = useSelector((state) => state[reducerName][stateName]);
        return output;
    }


    return {handleDispatch,handleGetReduxDispatch};
};