import {InputNumber} from "primereact/inputnumber";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {Checkbox} from "primereact/checkbox";

export const UrlFields = ({ index, element, handlePropertyChange, t }) => {


    return (
        <>


            <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("Pattern")}</label>
                <InputText
                    type="text"
                    value={element.properties.regex}
                    className={"input w-full"}
                    onChange={(e) => handlePropertyChange(index, 'regex', e.target.value)}
                />
            </div>

        </>
    )
}



