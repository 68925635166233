import {fileManager} from "../../../Services/Globals/Public/AppSetting";

export const handleAddForm=(form)=>{
    const data = {
        name:form.name === null || form.name === undefined || form.name === ''?null:form.name,
        slug:form.slug === null || form.slug === undefined || form.slug === ''?null:form.slug,
        msgSuccess:form.msgSuccess === null || form.slug === undefined || form.msgSuccess === ''?null:form.msgSuccess,
        msgError:form.msgError === null || form.msgError === undefined || form.msgError === ''?null:form.msgError,
        description:form.description === null || form.description === undefined || form.description === ''?null:form.description,
        rootFolder:form.rootFolder === null || form.rootFolder === undefined || form.rootFolder === ''?null:form.rootFolder.replace(/\//g, fileManager.separator).replace(/\s+/g, '').trim(),
        startDate:form.startDate === null || form.startDate === undefined?null:form.startDate,
        expirationDate:form.expirationDate === null || form.expirationDate === undefined?null:form.expirationDate,
        access:form.access === null || form.access === undefined?null:form.access.value,
        roadMapId:form.roadMapId === null || form.roadMapId === undefined?null:form.roadMapId.value,
        templateId:form.templateId === null || form.templateId === undefined?null:form.templateId.value,
        eBankIds: form.eBankIds.length === 0 || form.eBankIds === null?[]: form.eBankIds.map(item => item.value)

    }
    return data;
}
export const handleEditForm=(form)=>{
    const data = {
        formId:form.formId,
        name:form.name === null || form.name === undefined || form.name === ''?null:form.name,
        slug:form.slug === null || form.slug === undefined || form.slug === ''?null:form.slug,
        msgSuccess:form.msgSuccess === null || form.slug === undefined || form.msgSuccess === ''?null:form.msgSuccess,
        msgError:form.msgError === null || form.msgError === undefined || form.msgError === ''?null:form.msgError,
        description:form.description === null || form.description === undefined || form.description === ''?null:form.description,
        rootFolder:form.rootFolder === null || form.rootFolder === undefined || form.rootFolder === ''?null:form.rootFolder.replace(/\//g, fileManager.separator).replace(/\s+/g, '').trim(),
        startDate:form.startDate === null || form.startDate === undefined?null:form.startDate,
        expirationDate:form.expirationDate === null || form.expirationDate === undefined?null:form.expirationDate,
        access:form.access === null || form.access === undefined?null:form.access.value,
        roadMapId:form.roadMapId === null || form.roadMapId === undefined?null:form.roadMapId.value,
        templateId:form.templateId === null || form.templateId === undefined?null:form.templateId.value,
        eBankIds: form.eBankIds.length === 0 || form.eBankIds === null?[]: form.eBankIds.map(item => item.value)

    }
    return data;
}