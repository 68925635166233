export const handleGetAllProductMeta=(productMetas,metaTypes)=>{
    const filteredMeta = productMetas.map(item => ({
        ...item,
        type: metaTypes.find(typeItem => typeItem.value === item.type)
    }));
    return filteredMeta;
}

export const handleCreateProductMeta=(productId,concurrencyStamp,ProductMetas)=>{
    const metas={
        productId:productId,
        concurrencyStamp:concurrencyStamp,
        productMetas: ProductMetas.map((item) => ({
            productMetaId:item.productMetaId,
            name: item.name === null || item.name === ''?'-':item.name,
            value: item.value === null || item.value === ''?'-':item.value,
            type: item.type === null || item.type === undefined?0:item.type.value,
            order:item.order
        }))
    }
    return metas;
}