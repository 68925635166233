
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const TextareaElement = ({ itemCount,handleDefaultValueChange,SortDescending,SortAccending,index, element, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement }) => (
    <>
        <textarea
            maxLength={element.properties.max}
            minLength={element.properties.min}
            readOnly={element.properties.isReadOnly}
            disabled={element.properties.isReadOnly}
            id={element.properties.id}
            onChange={(e) => handleDefaultValueChange(index, 'value', e.target.value)}
            value={element.properties?.defaultValue?.value}
            onClick={() => handleSelectTabAfterElementSelection(index)}
            placeholder={element?.properties?.description}
            className={`harmony-input harmony-w-full ${index === selectedElementIndex ? 'border-themeInformation' : ''}`}
        />

        <div className={"harmony_body_button"}>
            <button
                type="button"
                className="button bg-themeDanger text-white harmony_button_form"
                onClick={() => deleteElement(index)}
            >
                <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
            </button>

            {
                index === 0?null:
                    <button
                        type="button"
                        className="button bg-themeInformation text-white harmony_button_form"
                        onClick={() => SortAccending(index)}

                    >
                        <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                    </button>

            }

            {
                index < itemCount -1 ?
                    <button
                        type="button"
                        className="button bg-themeInformation text-white harmony_button_form"
                        onClick={() => SortDescending(index)}

                    >
                        <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                    </button>:
                    null
            }

        </div>




    </>
);