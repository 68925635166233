import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCustomBankAccount} from "./useCustomBankAccount";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleCreateBankAccount} from "./BankAccountService";
import {LoadingHarmony} from "../../Loadings/LoadingHarmony";
import {DisplayError} from "../../Errors/DisplayError";
import {InputText} from "primereact/inputtext";
import {Tooltip} from "react-tooltip";
import Select from "react-select";
import {InputTextarea} from "primereact/inputtextarea";
import {FantasyModal} from "../../UIParts/FantasyModal";
import {BankCreation} from "../Banks/BankCreation";
import {TinyLoading} from "../../Loadings/TinyLoading";

export const BankAccountCreation=(props)=>{
    const {t}=useTranslation();
    const [IsshowSpinner,setIsShowSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [refresh,setRefresh] = useState(false);
    const [IsOpenBank,setIsOpenBank] = useState(false);
    const [butLoading,setBtnLoading] = useState(false);
    const {handleValue,bankAccount,setBankAccount,handleSelectValue} = useCustomBankAccount(setErrors);
    const [initialSource,setInitialSource] = useState({banks:[]})
    const handleClearErrors=()=>{
        setErrors({});
    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }


    const fetchData=async (IsOpen)=>{
        if(IsOpen){
            try {
                const response = await GET(`/Banks`,{},'IEnumerable');
                if(response.status === 200){
                    const banksData = convertSelectDataHandler(response.data);
                    setInitialSource({banks: banksData});
                    setBankAccount({accountNumber: '',cardNumber: '',description: '',shebaNumber: '',title: '',bankId:banksData[0]});
                    handleClearErrors();
                }
                else{
                    handleApiError(response,hideSpinner);
                }
            }
            catch (e) {
                console.log(e);
                hideSpinner();
            }

        }
    }
    useEffect(()=>{
        fetchData(props.IsOpen);
    },[refresh,props.IsOpen]);

    const handleSubmitBankAccount=async(e)=>{
        e.preventDefault();
        if(bankAccount.bankId === null){
            return;
        }
        try {
            showSpinner();
            const bankAccountData = handleCreateBankAccount(bankAccount);
            const response = await POST(`/Accountings/BankAccounts`,bankAccountData,false);
            if(response.status === 200){
                props.handleSuccess(true);
                hideSpinner();
            }
            else{
                handleApiError(response);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleOpenBank=()=>{
        setIsOpenBank(true);

    }
    const handleCloseBank=()=>{
        setIsOpenBank(false);
    }

    const handleSuccess=async(isSuccess)=>{
        if(isSuccess){
            const response = await GET(`/Banks`,{},'IEnumerable');
            if(response.status === 200){
                const banks = convertSelectDataHandler(response.data);
                setBankAccount((prevState)=>({...prevState,bankId: banks[banks.length-1] }));
                setInitialSource(prevState => ({...prevState, banks:banks}));
                handleCloseBank();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
    }




    return (
    <>
        <form method="post" onSubmit={handleSubmitBankAccount} >
            {/*{*/}
            {/*    IsshowSpinner?*/}
            {/*        <div className=" huploader_loading" >*/}
            {/*            <div className="relative">*/}
            {/*                <LoadingHarmony/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        :*/}
            {/*        null*/}
            {/*}*/}
            <div className={"space-y-3"}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                        <InputText name={'title'} value={bankAccount.title} onInput={(e)=>handleValue(e)} className={"input w-full"} />
                    </div>
                    <div className="space-y-2">
                        <div className={"flex justify-between items-center"}>
                            <label className="text-xs dark:text-gray-300">{t("BankName")}</label>
                            <button onClick={()=>handleOpenBank()} type="button"
                                    className="text-themeInformation dark:text-themeDarkInformation text-xs tooltip_class_Add_Bank">
                                ({t("BankCreation")})
                                <Tooltip anchorSelect={".tooltip_class_Add_Bank"} content={t("AddNewBanktoList")} positionStrategy={"fixed"}/>
                            </button>
                        </div>
                        <Select
                            options={initialSource.banks}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(data) => handleSelectValue(data, 'bankId')}
                            placeholder={t("selection")}
                            value={bankAccount.bankId}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={'fixed'}
                        />
                    </div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("BankAccountNumber")}</label>
                        <InputText type={"text"} name={'accountNumber'} onInput={(e)=>handleValue(e)} value={bankAccount.accountNumber} className={"input w-full"} />
                    </div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("cardNumber")}</label>
                        <InputText  name={'cardNumber'} value={bankAccount.cardNumber} onInput={(e)=>handleValue(e)} type={"text"} className={"input w-full"}/>
                    </div>
                    <div className="space-y-1 md:col-span-2">
                        <label className="text-xs dark:text-gray-300">{t("BankID")}</label>
                        <InputText type={"text"}  name={'shebaNumber'} value={bankAccount.shebaNumber} onInput={(e)=>handleValue(e)} className={"input w-full"}/>
                    </div>
                    <div className="md:col-span-2">
                        <InputTextarea className={"input "} name={'description'} value={bankAccount.description} onInput={(e)=>handleValue(e)} placeholder={t("Explanation")}/>
                    </div>
                </div>
                <div>
                    <button type="submit" className="button bg-themeInformation dark:bg-themeDarkInformation w-full text-white relative">
                        {t("register")}
                        {butLoading ? (
                            <TinyLoading/>
                        ) : (
                            null
                        )}
                    </button>
                </div>
            </div>
        </form>

        <FantasyModal  openModal={IsOpenBank}  closeModal={handleCloseBank}  title={t("BankCreation")} >

            <BankCreation
                IsOpen={IsOpenBank}
                handleSuccess={handleSuccess}
            />

        </FantasyModal>

    </>
)


}