export const handleCreateTransport=(obj)=>{
    if(obj.transportType === true){
        const data={
            title:obj.title === null || obj.title === undefined?null:obj.title,
            description:obj.description === null || obj.description === undefined?null:obj.description,
            transportType:true,
            fixedAmount:obj.fixedAmount === null || obj.fixedAmount === undefined?0:obj.fixedAmount,
            calculationType:obj.calculationType === null || obj.calculationType === undefined?null:obj.calculationType.value,
            shippingMethod:obj.shippingMethod === null || obj.shippingMethod === undefined?null:obj.shippingMethod.value,
            stateShow:true
        }
        return data
    }else{
        const data={
            title:obj.title === null || obj.title === undefined?null:obj.title,
            description:obj.description === null || obj.description === undefined?null:obj.description,
            transportType:false,
            fixedAmount:obj.fixedAmount === null || obj.fixedAmount === undefined?0:obj.fixedAmount,
            calculationType:obj.calculationType === null || obj.calculationType === undefined?null:obj.calculationType.value,
            shippingMethod:null,
            stateShow:true
        }
        return data
    }
}

export const handleEditTransport=(obj)=>{
    if(obj.transportType === true){
        const data={
            id:obj.id,
            title:obj.title === null || obj.title === undefined?null:obj.title,
            description:obj.description === null || obj.description === undefined?null:obj.description,
            transportType:true,
            fixedAmount:obj.fixedAmount === null || obj.fixedAmount === undefined?0:obj.fixedAmount,
            calculationType:obj.calculationType === null || obj.calculationType === undefined?null:obj.calculationType.value,
            shippingMethod:obj.shippingMethod === null || obj.shippingMethod === undefined?null:obj.shippingMethod.value,
            stateShow:true
        }
        return data
    }else{
        const data={
            id:obj.id,
            title:obj.title === null || obj.title === undefined?null:obj.title,
            description:obj.description === null || obj.description === undefined?null:obj.description,
            transportType:false,
            fixedAmount:obj.fixedAmount === null || obj.fixedAmount === undefined?0:obj.fixedAmount,
            calculationType:obj.calculationType === null || obj.calculationType === undefined?null:obj.calculationType.value,
            shippingMethod:null,
            stateShow:true
        }
        return data
    }
}