import React, { useEffect, useMemo, useState } from "react";
import { DELETE, GET } from "../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import { AlertSetting, GridSetting } from "../../../Services/Globals/Public/AppSetting";
import { GridDateFomartter } from "../../Commans/Grid/GridDateFomartter";
import { useTranslation } from "react-i18next";
import { GridButtonEdit } from "../../Commans/Grid/GridButtonEdit";
import { GridButtonDelete } from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {useNavigate} from "react-router-dom";


export const usePackageGroupGrid = (showSpinner, hideSpinner, setIsOpen, setIsEdit, refresh, Refresh, setPackageGroup, handleOpenMenu) => {
    const { t } = useTranslation();
    const [rowData, setRowData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 0, page: 0, pageSize: GridSetting.pageSize, totalCount: 0, hasNextPage: false, hasPreviousPage: false, lastPage: 0, currentPage: 1 })
    const [filter, setFilter] = useState({ search: '', searchValue: '' });
    const redirectTo = useNavigate();


    // Operations
    const handleGetPackageGroup = async (packageGroupId) => {
        try {
            showSpinner();
            setIsEdit(true)
            const response = await GET(`/PackageGroups/${packageGroupId}`, {}, 'single');
            if (response.status === 200) {
                setPackageGroup({
                    packageGroupId: response.data.packageGroupId,
                    name: response.data.name,
                    concurrencyStamp: response.data.concurrencyStamp
                })
                hideSpinner();
                setIsOpen(true);
            }
            else {
                handleApiError(response, hideSpinner);
            }
        }
        catch (e) {
            hideSpinner();
        }
    }

    const handleDeletePackageGroup = async (packageGroupId,concurrencyStamp) => {
        // show sweetalert
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/PackageGroups/${packageGroupId}?ConcurrencyStamp=${concurrencyStamp}`);
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(response, hideSpinner)
                    }
                } catch (e) {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    // Display data to the user
    const columnDefs = [
        { headerName: t("row"), field: 'id', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110, hide: true },
        { headerName: t("row"), field: 'idSequence', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110 },
        {
            headerName: t("GroupName"), minWidth: 200,
            cellRenderer: parameters =>
                <>
                    {parameters.data.name}
                </>
        },
        {
            headerName: t("CreationDate"), minWidth: 200,
            cellRenderer: params => {
                return <>  <GridDateFomartter date={params.data.creationDate} hasTime={true} /></>

            }
        },

        {
            headerName: t("operations"), maxWidth: 150, pinned: "left", lockPinned: true, cellClass: "lock-pinned",
            cellRenderer: params => {
                return <div className={'flex justify-center items-center gap-3 '} >
                    <button type={"button"}  onClick={()=>redirectTo(`/Admin/Package/Group/Role/${params.data.packageGroupId}`)}>
                        <HPrimeIcon
                            icon={"pi-star"}
                            iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                        />

                    </button>

                    <GridButtonEdit
                        onClick={() => handleGetPackageGroup(params.data.packageGroupId)}
                    />
                    <GridButtonDelete
                        onClick={() => handleDeletePackageGroup(params.data.packageGroupId,params.data.concurrencyStamp)}
                    />
                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    // Pagination handel
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({ ...pagination, pageSize: pageSize }))
    }


    // Search handel
    const handleSearch = () => {
        setFilter((prevState) => ({ ...prevState, search: prevState.searchValue }));
    }
    const handleResetFilters = () => {
        setFilter((prevState) => ({ ...prevState, search: '', searchValue: '' }));
    }
    const Paginate = (items) => {
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize]);


    const OnGridReady = async () => {
        try {
            showSpinner();
            const response = await GET(`/PackageGroups`, { PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search }, 'PagedList');
            if (response && response.items !== null) {
                setPagination({ ...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount });
                const items = Paginate(response.items);
                setRowData(items);
            }
            else {
                handleApiError(response, hideSpinner);
            }
        } catch {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };

    return {
        handleGetPackageGroup,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeletePackageGroup,
        handleSearch,
        handleResetFilters,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
        filter,
        setFilter,
    }
}