import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE, GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {Link, useNavigate} from "react-router-dom";

import {Image} from "primereact/image";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
export const useHelpGrid=(showSpinner,hideSpinner,refresh,Refresh)=>{

    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const queryParams = queryString.parse(window.location.search);
    const [rowData, setRowData] = useState([]);

    const handleDeleteHelp = async (helpId) => {
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    showSpinner()
                    const response = await DELETE(`/HarmonyHelp/${helpId}`, {headers: {'Content-Type': 'application/json'}},)
                    if (response.status === 200) {
                        Refresh()
                        hideSpinner()
                        handleSuccessMessage(t("SuccessDone"));
                    } else {
                        handleApiError(response, hideSpinner)
                    }
                } catch (e) {
                    console.log(e);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const GetAllkeywords = (keywords) => {
        if(  keywords === null){
            return "-"
        }
        const keywordsString = keywords.join(', ');
        return keywordsString
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Image"),field: 'image', minWidth: 140,
            cellRenderer:params => {
                return params.data.image !== null?
                    <Image src={params.data.image} zoomSrc={params.data.image}  preview />:
                    <HPrimeIcon
                        icon={'pi-times'} iconClass={"text-themeDanger"}
                    />

            }
        },

        {
            headerName: t("Title"), minWidth: 170,
            cellRenderer: parameters =>
                <>
                    {parameters.data.title}
                </>
        },
        {
            headerName: t("Address"),minWidth: 150,
            cellRenderer: parameters =>
                <>
                    {parameters.data.slug}

                </>
        },

        {
            headerName: t("Keywords"), minWidth: 170,
            cellRenderer: parameters =>
                <>
                    {GetAllkeywords(parameters.data.keyWords)}
                </>
        },
        {
            headerName: t("Content"),minWidth: 150,
            cellRenderer: parameters =>
                <>
                    {parameters.data.content}

                </>
        },
        {
            headerName: t("VideoUrl"),minWidth: 150,
            cellRenderer: parameters =>
                <>
                    {parameters.data.videoUrl?parameters.data.videoUrl:'-'}

                </>
        },
        {
            headerName: t("DocumentUrl"),minWidth: 150,
            cellRenderer: parameters =>
                <>
                    {parameters.data.documentUrl?parameters.data.documentUrl:'-'}

                </>
        },


        {
            headerName: t("operations"), maxWidth: 140,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer: params => {
                return <div className={'flex justify-center items-center gap-3 '}>
                    <GridButtonEdit
                        onClick={()=>redirectTo(`/Admin/Guide?harmonyHelpId=${params.data.harmonyHelpId}`)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteHelp(params.data.harmonyHelpId)}
                    />
                </div>
            }

        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination, setPagination] = useState({
        totalPages: 0,
        page: 0,
        pageSize: GridSetting.pageSize,
        totalCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        lastPage: 0,
        currentPage: 1
    })
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({...pagination, pageSize: pageSize}))
    }


    const [filter, setFilter] = useState({
        search: '',
        isInternal: null,
        isExternal: null,
    });

    const SwitchFilters = [
        {name: 'isInternal', lable: t("Internal"), value: filter.isInternal},
        {name: 'isExternal', lable: t("External"), value: filter.isExternal},
    ]

    const [search, setSearch] = useState('');
    const handleSearch = () => {
        setFilter((prevState) => ({...prevState, search: search}));
    }
    const handleToggleFilter = (data, name) => {
        data ? setFilter(({...filter, [name]: data})) : setFilter(({...filter, [name]: null}));
    }
    const handleResetFilters = () => {
        setFilter(({
            ...filter,
            search: '',
            isExternal: null,
            isInternal: null,
        }));
        setSearch('');
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize, filter.isExternal,filter.isInternal]);

    const OnGridReady = async () => {
        try {
            showSpinner()
            const response = await GET(`/HarmonyHelp`, {
                PageNumber: pagination.currentPage,
                PageSize: pagination.pageSize,
                Search: filter.search,
                IsInternal:filter.isInternal,
                IsExternal:filter.isExternal
            }, 'PagedList');
            if (response && response.items !== null) {
                setPagination({
                    ...pagination,
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPreviousPage: response.hasPreviousPage,
                    page: response.page,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount
                });
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            } else {
                handleApiError(response, hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    }

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,

    }
}