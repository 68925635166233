import React from 'react';
import {useTranslation} from "react-i18next";

export const CheckBoxSection = ({ data, onChange }) => {
    const { t } = useTranslation();

    return (
        <ul className={"space-y-2 px-2"}>
            {data.map((item) => (
                <li key={item.value}>
                    <label htmlFor={item.value} className={"flex justify-between items-center gap-2 px-2 py-3 post-input dark:bg-themeDarkPostInput post-input-design cursor-pointer"}>
                        <h2 className={"text-xs dark:text-gray-300 line-clamp-1"}>{item.label}</h2>
                        <div className={"flex items-center gap-1"}>
                                <input
                                    type="checkbox"
                                    className={"input-cleanUp "}
                                    id={item.value}
                                    onChange={() => onChange(item)}
                                />

                            </div>
                    </label>
                </li>
            ))}
        </ul>
    );
};

