import React from "react";

import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {useTranslation} from "react-i18next";
import {HPrimeIconSmall} from "../UIParts/HPrimeIconSmall";

export const Pagination = (props)=>{
    const { t } = useTranslation();



    return (
        <div className={"flex px-2 md:flex-row flex-col-reverse justify-between items-center post-input dark:bg-themeDarkPostInput p-1 post-input-design "}>
            <div>
                <h2 className={"underline text-xs dark:text-gray-300"}>{t("TotalNumber")}:{props.totalCount}</h2>
            </div>
            <div className="flex gap-2 items-center justify-between lg:justify-end">
                <div>
                    <p className="dark:text-gray-300 text-xs text-nowrap">
                        {t("Count")} {props.length} {t("Of")} {props.totalCount}
                    </p>
                </div>
                <div className="flex gap-2 ">
                    <button type={"button"}  onClick={()=>props.handleCurrentPage(props.totalPages)}>
                        <HPrimeIconSmall icon={'pi-angle-double-right'} iconClass="dark:text-gray-300"/>
                    </button>
                    <button type={"button"}  disabled={!props.hasNextPage} onClick={props.handleNextPage}>
                        <HPrimeIconSmall icon={'pi-angle-right'} iconClass="dark:text-gray-300"/>
                        {/*Next*/}
                    </button>
                    <p className="dark:text-gray-300 text-xs text-nowrap">
                        {t("page")} {props.currentPage} {t("Of")} {props.totalPages}
                    </p>
                    <button type={"button"} disabled={!props.hasPreviousPage} onClick={props.handlePreviousPage}>
                        <HPrimeIconSmall icon={'pi-angle-left'} iconClass="dark:text-gray-300"/>
                        {/*Previous*/}
                    </button>
                    <button type={"button"} onClick={()=>props.handleCurrentPage(1)}>
                        <HPrimeIconSmall icon={'pi-angle-double-left'} iconClass="dark:text-gray-300"/>
                    </button>
                </div>
                <select className="input dark:text-gray-300" onChange={(e)=>props.handlePageSizeCount(e.target.value)} >
                    {
                        GridSetting.paginationItems.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>


    )
}