import React, { useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import { useNavigate} from "react-router-dom";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {useCustomGateway} from "./useCustomGateway";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {ToggleSwitch} from "../../../Commans/UIParts/ToggleSwitch";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useGatewayGrid} from "./useGatewayGrid";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {Export} from "../../../Commans/Grid/Export";
import Select from "react-select";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {validate} from "../../../../Services/Globals/validate";
import {gatewaySchema} from "./gatewaySchema";
import {handleConvertAddGateways, handleConvertEditGateways, handleCreateGateway} from "./gatewayService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

// import 'react-tooltip/dist/react-tooltip.css'

export const HandlePaymentGateways=()=>{

    const redirectTo = useNavigate();
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [IsEdit,setIsEdit] = useState(false);
    const [showSpinner,setShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({currencies:[],gatewayTypes:[]});
    const [IsOpen, setIsOpen] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [errors,setErrors] = useState({});

    const {setGateway,gateway,handleValue,handleNumberValue,handleSelectValue,handleChangeToggle,handleFileChange} = useCustomGateway(setErrors,fileInputRef);
    const handleResetGateways=()=>{
        setGateway({currencyType:initialSource.currencies[0],gatewayType: initialSource.gatewayTypes[0],name: 'ZarinPal',isActive:true,icon: null})
    }
    const handleOpenModal=()=>{
        setGateway({currencyType:initialSource.currencies[0],gatewayType: initialSource.gatewayTypes[0],name: 'ZarinPal',isActive:true,icon: null})
        setIsOpen(true);
    }
    const handleCloseModal=()=>{
        handleResetGateways();
        handleClearErrors();
        setIsEdit(false);
        redirectTo(`/Admin/PaymentGateways`);
        setIsOpen(false);
    }
    // handles spinner in api response
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // handles errors
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    //refreshes component when it is called
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    // resets all errors to nothing as initial state
    const handleClearErrors=()=>{
        setErrors({});
    }

    const handleInitialData=(currencies,gatewayTypes)=>{
        const currencyData = convertSelectDataHandler(currencies);
        const gatewayData = convertSelectDataHandler(gatewayTypes);
        setInitialSource({currencies:currencyData,gatewayTypes:gatewayData});
    }

    const fetchData = async () => {
        try {
            setShowSpinner(true);
            const [responseCurrency,responseGateway] =
                await Promise.all([
                    GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable'),
                    GET(`/Enumerations/EnPSPType`, {}, 'IEnumerable'),
                ]);
            if (
                responseCurrency.status === 200 &&
                responseGateway.status === 200
            ) {
                handleInitialData(
                    responseCurrency.data,
                    responseGateway.data,
                );
                setShowSpinner(false);
            } else {
                handleApiError(responseCurrency, onApiError);
                handleApiError(responseGateway, onApiError);
            }
        } catch (e) {
            onApiError();
            console.error(e);
        }
    };

    const handleSubmitGateway=async(e)=>{
        e.preventDefault();
        const isValid=validate(gatewaySchema,gateway,onSetErrors);
        if(!isValid){
            return;
        }
        try {
            if(IsEdit){
                setShowSpinner(true);
                const gateways = handleConvertEditGateways(gateway);
                const formData = handleCreateGateway(gateways)

                const response = await PUT(`/Payments/PSP`,formData,true);
                if(response.status === 200){
                    setShowSpinner(false);
                    handleCloseModal();
                    handleSuccessMessage(t("Successfullyedited"));
                    Refresh();
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            else{
              setShowSpinner(true);
              const gateways = handleConvertAddGateways(gateway);
              const formData = handleCreateGateway(gateways)
              const response = await POST(`/Payments/PSP`,formData,true);
              if(response.status === 200){
                  setShowSpinner(false);
                  handleCloseModal();
                  handleSuccessMessage(t("SuccessfullyCreated"));
                  Refresh();
              }
              else{
                  handleApiError(response,onApiError);
              }
            }
        }
        catch (e) {
            onApiError();
            console.log(e);
        }
    }




    const {handleNextPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount,columnDefs,defaultColDef,autoGroupColumnDef,rowData,getRowHeight,pagination} = useGatewayGrid(setShowSpinner,fetchData,refresh,onApiError,initialSource,setGateway,setIsOpen,setIsEdit,Refresh);


    return(<>

        <div class="space-y-5 relative">
            {
                showSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => handleOpenModal()}
                                        tooltipTitle={t("CreateOperator")}

                                    />
                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />

            </div>

            <div className="space-y-2 H-body-ag-grid">

                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridEnglish}
                />
                <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
            </div>
            <Export apiUrl={''} />
        </div>

        <FantasyModal openModal={IsOpen}  closeModal={handleCloseModal} title={t("CreateOperator")}>
            <form method={'post'} onSubmit={handleSubmitGateway}>
            <div className="space-y-5">
                <div class="grid grid-cols-1 gap-3">
                    <div class="space-y-1">
                        <label className="text-xs dark:text-gray-300">
                            {t("OperatorName")}
                        <RequiredIcon/>
                        </label>
                        <DisplayError message={errors.name}/>
                        <InputText name={'name'} value={gateway.name} onInput={(e)=>handleValue(e)} className={"input w-full"} />
                    </div>
                    <div className={"flex gap-2"}>
                        <div class="space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">{t("CurrencyUnit")}</label>
                            <div>
                                <Select
                                    options={initialSource.currencies}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'currencyType')}
                                    placeholder={t("selection")}
                                    value={gateway.currencyType}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                                />
                            </div>

                        </div>
                        <div class="space-y-1 flex-shrink-0">
                            <label className="text-xs dark:text-gray-300">{t("Icon")}</label>
                            <label htmlFor="uploadTwo" className="button bg-themeSecondary text-white flex justify-center items-center w-20 gap-2">
                                <HPrimeIcon
                                icon={"pi-upload"}
                                />
                                {t("Icon")}
                                <input  type="file" id="uploadTwo"  ref={fileInputRef} onChange={handleFileChange} className="hidden" />
                            </label>
                            <span className={'text-xs dark:text-gray-300 w-20 line-clamp-1 text-center'}>
                                {
                                    gateway.icon ?gateway.icon.name:null
                                }
                            </span>
                        </div>
                    </div>
                    <div class="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("GatewayType")}</label>
                        <Select
                            options={initialSource.gatewayTypes}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(data)=>handleSelectValue(data,'gatewayType')}
                            placeholder={t("selection")}
                            value={gateway.gatewayType}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                        />
                    </div>
                    <div class="flex items-center gap-2">
                        {/*<label className="text-xs dark:text-gray-300">{t("Status")}</label>*/}
                        <ToggleSwitch tag={'active'} value={gateway.isActive} handleToggleSwitchValue={handleChangeToggle} name={'isActive'} checked={t("Active")} unchecked={t("Inactive")} customClass={"w-32"} />
                    </div>
                </div>
                <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
            </div>
        </form>
        </FantasyModal>

        <ToastContainer/>

       </>)
}