import React, {useEffect, useMemo, useState} from "react";


import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {useSetting} from "../../../../Commans/Hooks/useSetting";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {handleAddCleanDataFormat} from "./DataCleanService";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {CheckBoxSection} from "../../../../Commans/Globals/CheckBoxSection";
import {SettingButton} from "../../../../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../../../../Commans/Dates/DateTimePicker";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {HandleGeneralSetting} from "../GeneralSettings/HandleGeneralSetting";
import {ToastContainer} from "react-toastify";
import {useDataClean} from "./useDataClean";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const HandleCleanTempSetting = () => {
    const [chartData,setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['A', 'B', 'C'],
            datasets: [
                {
                    data: [300, 50, 100],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '80%'
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [initialSource,setInitialSource] = useState({sections:[]});
    const queryParams = queryString.parse(window.location.search);
    const [showSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(0)
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {handleChangeSection,handleToDate,handleFromDate,setClean,cleanData,ResetCleanData} = useDataClean();

    // handles loading spinner in api request
    const onApiError=()=>{
        setShowSpinner(false);
    }

    const Refresh=(state)=>{
        setRefresh({...state});
    }

    const handleInitialData=(sections)=>{
        const sectionData = convertSelectDataHandler(sections);
        setInitialSource({
            sections: sectionData
        })
    }

    // fetches all data from database in initial time of component
    const fetchData=async()=>{
        try {
            setShowSpinner(true)
            const responseSection = await GET(`/Enumerations/EnWebsiteFilesSections`,{},'IEnumerable');
            if(responseSection.status === 200){
                handleInitialData(responseSection.data);
                setShowSpinner(false);
            }
            else{
                handleApiError(responseSection,onApiError);
            }
        }
        catch (e) {
            setShowSpinner(false)
        }
    }

    useEffect(()=>{
        fetchData()
    },[refresh])


    const handleSubmitDelete=async()=>{
        try {
            setShowSpinner(true)
            if(cleanData.section.length === 0){
                handleFailureMessage(t("PleaseSelectSection"));
                setShowSpinner(false)
                return;
            }
            const dataJson = handleAddCleanDataFormat(cleanData);
            const response = await POST(`/Settings/CleanTempFiles`,dataJson,false);
            if(response.status === 200){
                handleSuccessMessage(t("DataCleanupCompletedSuccessfully"));
                ResetCleanData();
                Refresh();
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError();
        }
    }





    return (
        <>

            <div class="  space-y-5">

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Dashboard`)}
                                            icon={"pi-user"}
                                            title={t("Personal")}
                                            tooltipTitle={t("PersonalSettings")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/General`)}
                                            icon={"pi-objects-column"}
                                            title={t("Public")}
                                            tooltipTitle={t("GeneralSettings")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Pictures`)}
                                            icon={"pi-image"}
                                            title={t("Images")}
                                            tooltipTitle={t("ImageSettings")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Routing`)}
                                            icon={"pi-code"}
                                            title={t("Routing")}
                                            tooltipTitle={t("Routing")}
                                        />

                                    </>
                                }

                    />

                </div>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-3 relative"}>
                    {

                        showSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-y-5"}>
                        <div className={"space-y-5"}>
                            <div className={"bg-white dark:bg-themeDarkSalt700 space-y-3"}>
                                <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                                    <h2 className={"dark:text-gray-300 text-xs"}>{t("Sectionselection")}</h2>
                                </div>
                                <CheckBoxSection
                                    data={initialSource.sections}
                                    onChange={handleChangeSection}
                                />
                            </div>
                            <div className={"bg-white dark:bg-themeDarkSalt700 space-y-3"}>
                                <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                                    <h2 className={"dark:text-gray-300 text-xs"}>{t("TimeInterval")}</h2>
                                </div>
                                <div class={"px-2"}>
                                    <div className={'flex gap-2 item-center p-2 post-input dark:bg-themeDarkPostInput post-input-design'}>
                                        <div className="flex-1 space-y-1">
                                            <label className="text-xs dark:text-gray-300 flex gap-2 items-center">
                                                <SettingButton
                                                    onClick={()=>handleSetting('GeneralSetting')}
                                                    tooltipTitle={t("Calendarsettings")}
                                                />
                                                {t("FromDate")}
                                            </label>
                                            <DateTimePicker value={cleanData.fromDate} handleDateTime={handleFromDate}
                                                            format={'YYYY/MM/DD'}/>
                                        </div>
                                        <div className="flex-1 space-y-1">
                                            <label className="text-xs dark:text-gray-300 flex gap-2 items-center">
                                                <SettingButton
                                                    onClick={()=>handleSetting('GeneralSetting')}
                                                    tooltipTitle={t("Calendarsettings")}
                                                />
                                                {t("ToDate")}
                                            </label>
                                            <DateTimePicker value={cleanData.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'} zIndex={"1000"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type={"button"} onClick={() => handleSubmitDelete()}
                                    className={"button flex gap-2 items-center justify-center bg-themeDanger  w-full text-white"}>
                                <HPrimeIconSmall icon={"pi-trash"} />
                                {t("DataCleanup")}
                            </button>
                        </div>
                        <div>
                            <img src="/Assets/Image/bg-img/deleteData.svg" alt="backup" className={"size-full "}/>
                        </div>
                    </div>
                </div>
            </div>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>
                            :
                            null
                    }
                </div>
            </FantasyModal>

            <ToastContainer/>
        </>

    );
};