import React, { useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";


//This is a textArea which is widely used
export default function TextArea(props) {
    const [value, setValue] = useState('');

    return (
        <div >
            <InputTextarea value={value} onChange={(e) => setValue(e.target.value)} rows={5} cols={30} className="input w-full" />
        </div>
    )
}