import React, {useEffect, useRef, useState} from "react";

import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import { Accordion, AccordionTab } from 'primereact/accordion';
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import queryString from "query-string";
import {useNavigate} from "react-router-dom";
import {useSetting} from "../../../../Commans/Hooks/useSetting";
import {useCustomTab} from "../../../../GeneralManagement/Helps/Add/useCustomTab";
import {usePicture} from "./usePicture";
import {GET, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {Image} from "primereact/image";
import {usePictureAttach} from "./usePictureAttach";
import {AttachCard} from "../../../../GeneralManagement/Documents/Add/AttachCard";
import {PictureAttachCard} from "./PictureAttachCard";
import {CreateConvertPicture, EditFormDataPictureSettings} from "./pictureService";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";

export const HandlePicturesSettings=({isInUse,handleCloseSetting})=>{

    const queryParams = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}= useSetting();
    const {tabs,setActiveTab,activeTab,handleChangeTabId} = useCustomTab();
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [errors,setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({});
    const [IsEdit,setIsEdit] = useState(false);
    const [IsOpen,setIsOpen] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const {setPicture,picture,handleFileChange,displayFile}=usePicture();

    const LogoHeaderRef = useRef(null);
    const LogoFooterRef = useRef(null);
    const IconRef = useRef(null);
    const DefaultRef = useRef(null);
    const ManRef = useRef(null);
    const WomanRef = useRef(null);
    const NoneRef = useRef(null);

    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const {triggerFileInput,setFiles,files,deleteCard,addNewCard,handleFieldChange,handleFileChanges,handleInitialCards,handleGetAllCards,handleDeleteDocumentAttachment,totalSize} = usePictureAttach(initialSource,showSpinner,hideSpinner,Refresh);

    const fetchData=async()=>{
        try {
            const addTimestamp = (url) => {
                const timestamp = new Date().getTime();
                return `${url}?timestamp=${timestamp}`;
            };
            showSpinner();
            const response = await GET(`/Settings/Images`,{},'single');
            if(response.status === 200){
                if(response.data.bannerImages.length === 0){
                    handleInitialCards();
                }
                else{
                    const fileCards = handleGetAllCards(response.data.bannerImages);
                    setFiles(fileCards);
                }
                setPicture((prevState) => ({
                    ...prevState,
                    icon: addTimestamp(response.data.favicon),
                    logoFooter: addTimestamp(response.data.logoFooter),
                    logoHeader: addTimestamp(response.data.logoHeader),
                    defaultImage: addTimestamp(response.data.defaultImage),
                    man: addTimestamp(response.data.man),
                    woman: addTimestamp(response.data.woman),
                    none: addTimestamp(response.data.none),
                    concurrencyStamp: response.data.concurrencyStamp // Assuming this doesn't need a timestamp
                }));
                hideSpinner();
            }
            else{
              handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }
    useEffect(()=>{
        fetchData();
    },[refresh])

    const handleShowImage=(propName,pathName)=>{
        return (<>
            {

                picture[pathName] !== null ?
                      <Image src={picture[pathName]}   zoomSrc={picture[pathName]} alt="Image" preview/>
                    :
                      <Image src={picture[propName]}   zoomSrc={picture[propName]} alt="Image"   preview/>
            }
        </>

        )
    }


    const handleSubmit=async(e)=>{
        e.preventDefault();
        try {
            showSpinner();
            const picturesData = CreateConvertPicture(picture,files);
            const formData = EditFormDataPictureSettings(picturesData);
            const response = await PUT(`/Settings/EditWebsiteImages`,formData,true);
            if(response.status === 200){
                hideSpinner();
                if (LogoHeaderRef.current) {
                    LogoHeaderRef.current.value = '';
                }
                if (LogoFooterRef.current) {
                    LogoFooterRef.current.value = '';
                }
                if (IconRef.current) {
                    IconRef.current.value = '';
                }
                if (DefaultRef.current) {
                    DefaultRef.current.value = '';
                }

                if (ManRef.current) {
                    ManRef.current.value = '';
                }
                if (WomanRef.current) {
                    WomanRef.current.value = '';
                }
                if (NoneRef.current) {
                    NoneRef.current.value = '';
                }

                Refresh();
                handleSuccessMessage(t('SuccessDone'))
                if(isInUse){
                    handleCloseSetting()
                }
            }
            else{
              handleApiError(response,hideSpinner)    ;
            }
        }
        catch (e) {
            hideSpinner();
        }
    }






    return(
        <>
            {console.log(picture)}
            <div class="space-y-5">
                {
                    isInUse?
                        null:
                        <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                            <ListFilter label={null} icon={null}
                                        showChildrenTwo={false}
                                        childrenOne={
                                            <>
                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/Dashboard`)}
                                                    icon={"pi-image"}
                                                    title={t("Personal")}
                                                    tooltipTitle={t("PersonalSettings")}
                                                />

                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/General`)}
                                                    icon={"pi-objects-column"}
                                                    title={t("Public")}
                                                    tooltipTitle={t("GeneralSettings")}
                                                />


                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/Routing`)}
                                                    icon={"pi-code"}
                                                    title={t("Routing")}
                                                    tooltipTitle={t("Routing")}
                                                />
                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/CleanTemp`)}
                                                    icon={"pi-trash"}
                                                    title={t("DataCleanup")}
                                                    tooltipTitle={t("TemporaryDataCleanup")}
                                                />



                                            </>
                                        }

                            />

                        </div>

                }
                <form onSubmit={handleSubmit} method={'post'} className={"relative"}>
                    {
                        IsShowSpinner?
                            <div className="huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }

                    <div className={"space-y-5"}>
                        <div >
                            <Accordion activeIndex={0} className={"box bg-white dark:bg-themeDarkSalt700 rounded-md p-2 space-y-3"}>
                                <AccordionTab header={t("DefaultImages")}>
                                    <div class="grid grid-cols-1 lg:grid-cols-3 gap-3">
                                        <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design flex flex-col sm:flex-row gap-2 items-center">
                                            <div className={"size-20 flex-shrink-0 bg-white border-2 border-dashed setting-img-default dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                                {
                                                    handleShowImage('logoHeader','logoHeaderPath')
                                                }
                                            </div>
                                            <div className="space-y-1 flex-1 w-full">
                                                <label className="text-xs dark:text-gray-300">{t("HeaderLogo")}</label>
                                                <input type="file"  accept=".jpeg,.png"  ref={LogoHeaderRef}  onChange={(e)=>handleFileChange(e,'logoHeader','logoHeaderPath')} className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                                            </div>
                                        </div>
                                        <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design flex flex-col sm:flex-row gap-2 items-center">
                                            <div className={"size-20 flex-shrink-0 bg-white border-2 border-dashed setting-img-default dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                                {
                                                    handleShowImage('logoFooter','logoFooterPath')
                                                }
                                            </div>
                                            <div className="space-y-1 flex-1 w-full">
                                                <label className="text-xs dark:text-gray-300">{t("FooterLogo")}</label>
                                                <input type="file"  accept=".jpeg,.png"  ref={LogoFooterRef}   onChange={(e)=>handleFileChange(e,'logoFooter','logoFooterPath')} className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                                            </div>
                                        </div>
                                        <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design flex flex-col sm:flex-row gap-2 items-center">
                                            <div className={"size-20 flex-shrink-0 bg-white border-2 border-dashed setting-img-default dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                                {
                                                    handleShowImage('icon','iconPath')
                                                }
                                            </div>
                                            <div className="space-y-1 flex-1 w-full">
                                                <label className="text-xs dark:text-gray-300">{t("SiteIcon")}</label>
                                                <input type="file"  ref={IconRef}  accept=".ico"   onChange={(e)=>handleFileChange(e,'icon','iconPath')} className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                                            </div>
                                        </div>
                                        <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design flex flex-col sm:flex-row gap-2 items-center">
                                            <div className={"size-20 flex-shrink-0 bg-white border-2 border-dashed setting-img-default dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                                {
                                                    handleShowImage('defaultImage','defaultImagePath')
                                                }
                                            </div>
                                            <div className="space-y-1 flex-1 w-full">
                                                <label className="text-xs dark:text-gray-300">{t("DefaultArticleImage")}</label>
                                                <input type="file" ref={DefaultRef}  accept=".svg"  onChange={(e)=>handleFileChange(e,'defaultImage','defaultImagePath')} className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                                <AccordionTab header={t("ProfilePicture")}>
                                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-3">
                                        <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">
                                            <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                                <h2 className={"text-xs dark:text-gray-300"}>{t("MensPhotos")}</h2>
                                            </div>
                                            <div className={"flex gap-2 items-center flex-col sm:flex-row"}>
                                                <div className={"size-20 flex-shrink-0 bg-white border-2 border-dashed setting-img-default dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                                    {
                                                        handleShowImage('man','manPath')
                                                    }
                                                </div>
                                                <div className="flex-1 w-full">
                                                    <input type="file" ref={ManRef} accept=".svg" onChange={(e)=>handleFileChange(e,'man','manPath')} className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">
                                            <div className=" bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                                <h2 className={"text-xs dark:text-gray-300"}>{t("WomensPhotos")}</h2>
                                            </div>
                                            <div className={"flex gap-2 items-center flex-col sm:flex-row"}>
                                                <div className={"size-20 flex-shrink-0 bg-white border-2 border-dashed setting-img-default dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                                    {
                                                        handleShowImage('woman','womanPath')
                                                    }
                                                </div>
                                                <div className="w-full flex-1">
                                                    <input type="file" ref={WomanRef} accept=".svg"  onChange={(e)=>handleFileChange(e,'woman','womanPath')} className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">
                                            <div className=" bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                                <h2 className={"text-xs dark:text-gray-300"}>{t("OtherGenders")}</h2>
                                            </div>
                                            <div className={"flex gap-2 items-center flex-col sm:flex-row"}>
                                                <div className={"size-20 flex-shrink-0 bg-white border-2 border-dashed setting-img-default dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                                    {
                                                        handleShowImage('none','nonePath')
                                                    }
                                                </div>
                                                <div className="flex-1 w-full">
                                                    <input type="file" ref={NoneRef} accept=".svg"  onChange={(e)=>handleFileChange(e,'none','nonePath')} className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </AccordionTab>
                                <AccordionTab header={t("Slider")}>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 ">
                                        <PictureAttachCard
                                            data={files}
                                            IsEdit={true}
                                            showSpinner={showSpinner}
                                            hideSpinner={hideSpinner}
                                            triggerFileInput={triggerFileInput}
                                            setCards={setFiles}
                                            cards={files}
                                            deleteCard={deleteCard}
                                            addNewCard={addNewCard}
                                            handleFieldChange={handleFieldChange}
                                            handleFileChange={handleFileChanges}
                                            initialSource={initialSource}
                                            handleDeleteDocumentAttachment={handleDeleteDocumentAttachment}
                                            totalSize={totalSize}
                                        />
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <ButtonSubmitAndCancel
                            onCancelClick={()=>
                                isInUse
                                    ?
                                    handleCloseSetting()
                                    :
                                    redirectTo('/Harmony/Master/setting')
                        }
                        />
                    </div>


                </form>
            </div>
            <ToastContainer/>
        </>

    )
}