import React, {useEffect, useMemo, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import Select from "react-select";
import {ToastContainer} from "react-toastify";
import {Tooltip} from "react-tooltip";
import {InputTextarea} from "primereact/inputtextarea";
import {useCustomBankAccount} from "../../Commans/Accounting/BankAccounts/useCustomBankAccount";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {handleCreateBankAccount, handleEditBankAccount} from "../../Commans/Accounting/BankAccounts/BankAccountService";
import {useCustomBankAccountGrid} from "./useCustomBankAccountGrid";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {BankCreation} from "../../Commans/Accounting/Banks/BankCreation";
import {BankAccountCreation} from "../../Commans/Accounting/BankAccounts/BankAccountCreation";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";

// import 'react-tooltip/dist/react-tooltip.css'

export const HandleAddBankAccount=()=>{

    const {t}=useTranslation();
    const [IsshowSpinner,setIsShowSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [refresh,setRefresh] = useState(false);
    const [IsOpen,setIsOpen] = useState(false);
    const [initialSource,setInitialSource] = useState({banks:[]});
    const [IsEdit,setIsEdit] = useState(false);
    const [IsOpenBankAccount,setOpenBankAccount] = useState(false);
    const handleClearErrors=()=>{
        setErrors({});
    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const handleResetBankAccount=()=>{
        setBankAccount({accountNumber: '',cardNumber: '',description: '',shebaNumber: '',title: '',bankId:initialSource.banks[0]});
    }

    const handleOpenBankAccount=async()=>{
        try {
            setBankAccount({accountNumber: '',cardNumber: '',description: '',shebaNumber: '',title: '',bankId:initialSource.banks[0]});
            setIsOpen(true)
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    const handleCloseBankAccount=()=>{
        setIsOpen(false);
        setIsEdit(false);
        handleResetBankAccount();
        handleClearErrors();
    }

    const handleOpenAccount=()=>{
        setOpenBankAccount(true);
        setBankAccount((prevState)=>({...prevState,bankId:initialSource.banks[initialSource.banks.length-1] }));
    }

    const handleCloseAccount=()=>{
        setOpenBankAccount(false);
    }

    const handleSuccess=async(isSuccess)=>{
        if(isSuccess){
            const response = await GET(`/Banks`,{},'IEnumerable');
            if(response.status === 200){
                const banks = convertSelectDataHandler(response.data);
                setBankAccount((prevState)=>({...prevState,bankId: banks[banks.length-1] }));
                setInitialSource(prevState => ({...prevState, banks:banks}));
                handleCloseAccount();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
    }

    const fetchData = async()=>{
        try {
            showSpinner();
            const response = await GET(`/Banks`,{},'IEnumerable');
            if(response.status === 200){
                const banksData = convertSelectDataHandler(response.data);
                setInitialSource({banks: banksData});
                setBankAccount({accountNumber: '',cardNumber: '',description: '',shebaNumber: '',title: '',bankId:banksData[0]});
                hideSpinner();
                handleClearErrors();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }
    useEffect(()=>{
        fetchData();
    },[])

    const handleSubmitBankAccount=async(e)=>{
        e.preventDefault();
        if(bankAccount.bankId === null){
            return;
        }
        try {
            if(IsEdit){
                showSpinner();
                const bankAccountData = handleEditBankAccount(bankAccount);
                const response = await PUT(`/Accountings/BankAccounts`,bankAccountData,false);
                if(response.status === 200){
                    Refresh();
                    hideSpinner();
                    handleCloseBankAccount()
                    handleSuccessMessage(t('Successfullyregistered'));
                }
                else{
                    handleApiError(response);
                }
            }
            else{
                showSpinner();
                const bankAccountData = handleCreateBankAccount(bankAccount);
                const response = await POST(`/Accountings/BankAccounts`,bankAccountData,false);
                if(response.status === 200){
                    Refresh();
                    hideSpinner();
                    handleCloseBankAccount()
                    handleSuccessMessage(t('Successfullyregistered'));
                }
                else{
                    handleApiError(response);
                }
            }

        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    const {handleValue,bankAccount,setBankAccount,handleSelectValue} = useCustomBankAccount(setErrors);

    const {autoGroupColumnDef,columnDefs,defaultColDef,filter,setFilter,getRowHeight,handleSearch,setSearch,search,handleNextPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount,handleToggleFilter,handleResetFilters,handleSelectFilter,rowData,pagination,}=useCustomBankAccountGrid(showSpinner,hideSpinner,refresh,Refresh,setIsOpen,setBankAccount,setIsEdit,initialSource);




    return(<>

        <div class=" space-y-5 relative">
            {
                IsshowSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => handleOpenBankAccount()}
                                        tooltipTitle={t("BankAccountCreation")}

                                    />

                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />

            </div>

            <div className="space-y-2 H-body-ag-grid">

                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridEnglish}
                />
                <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />


            </div>

        </div>

        <FantasyModal openModal={IsOpen}  closeModal={handleCloseBankAccount} title={t("BankAccountCreation")}>
            <form  method={'post'} onSubmit={handleSubmitBankAccount}>
                <div className={"space-y-2"}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                            <InputText name={'title'} value={bankAccount.title} onInput={(e)=>handleValue(e)} className={"input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <div className={"flex justify-between items-center"}>
                                <label className="text-xs dark:text-gray-300">{t("BankName")}</label>
                                <button onClick={()=>handleOpenAccount()} type="button"
                                        className="text-themeInformation dark:text-themeDarkInformation text-xs tooltip_class_Add_Bank">
                                    ({t("BankCreation")})
                                    <Tooltip anchorSelect={".tooltip_class_Add_Bank"} content={t("AddNewBanktoList")} positionStrategy={"fixed"} />
                                </button>
                            </div>
                            <Select
                                options={initialSource.banks}
                                isMulti={false}
                                isSearchable={true}
                                onChange={(data) => handleSelectValue(data, 'bankId')}
                                placeholder={t("selection")}
                                value={bankAccount.bankId}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("BankAccountNumber")}</label>
                            <InputText type={"text"} name={'accountNumber'} onInput={(e)=>handleValue(e)} value={bankAccount.accountNumber} className={"input w-full"} />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("cardNumber")}</label>
                            <InputText name={'cardNumber'} onInput={(e)=>handleValue(e)} value={bankAccount.cardNumber}  type={"text"} className={"input w-full"}/>
                        </div>
                        <div className="space-y-1 md:col-span-2">
                            <label className="text-xs dark:text-gray-300">{t("BankID")}</label>
                            <InputText type={"text"}  name={'shebaNumber'} value={bankAccount.shebaNumber} onInput={(e)=>handleValue(e)}  className={"input w-full"}/>
                        </div>
                        <div className="space-y-1 md:col-span-2">
                            <InputTextarea className={"input "} name={'description'} value={bankAccount.description} onInput={(e)=>handleValue(e)} placeholder={t("Explanation")}/>
                        </div>
                    </div>
                    <div>
                        <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                    </div>
                </div>

            </form>
        </FantasyModal>

        <FantasyModal  openModal={IsOpenBankAccount}  closeModal={handleCloseAccount}  title={t("BankCreation")} >

            <BankCreation
                IsOpen={IsOpenBankAccount}
                handleSuccess={handleSuccess}
            />

        </FantasyModal>

        <ToastContainer/>

    </>)
}