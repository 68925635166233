
import {InputTextarea} from "primereact/inputtextarea";
import {InputText} from "primereact/inputtext";
import React from "react";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const EmailElement = ({ item,index,handleChangeData}) => (
    <>
        <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
            <label className="dark:text-gray-300 harmony-text-xs">
                {item.properties.caption}
            </label>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <InputText
                maxLength={item.properties.max}
                minLength={item.properties.min}
                placeholder={item.properties.description}
                readOnly={item.properties.isReadOnly}
                disabled={item.properties.isReadOnly}
                name={item.properties.name}
                id={item.properties.id}
                onChange={(e) => handleChangeData(index, 'value', e.target.value)}
                value={item.data?.value}
            />
        </div>


    </>
);