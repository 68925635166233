import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";

import Select from "react-select";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {AddFilter} from "../../Commans/Filters/AddFilter";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {useNavigate} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";

import {InputSwitchComponent} from "../../Commans/Filters/InputSwitchComponent";
import {ToastContainer} from "react-toastify";

import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {useDataBinders} from "./SurveyHooks/useDataBinders";
import {useModal} from "./SurveyHooks/useModal";
import {useSlugValidation} from "./SurveyHooks/useSlugValidation";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {useSurvey} from "./SurveyHooks/useSurvey";
import {validate} from "../../../Services/Globals/validate";
import {surveySchema} from "./surveySchema";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {
    handleCreateEditSurvey,
    handleCreatePoll,
    handleCreateSurvey
    , handleFilterSurveys
} from "./SurveyService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../Contents/ContentService";
import {Single} from "./SurveyTypes/Single";
import {Multi} from "./SurveyTypes/Multi";
import {Emoji} from "./SurveyTypes/Emoji";
import {Range} from "./SurveyTypes/Range";
import queryString from "query-string";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {InputTextarea} from "primereact/inputtextarea";
import {Accordion, AccordionTab} from "primereact/accordion";
import {InputNumber} from "primereact/inputnumber";
import {SlugGenerator} from "../../Commans/Globals/AiFeatures/SlugGenerator";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import ArrowLeftIcon from "../../HIcon/ArrowLeftIcon";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";


export const HandleAddSurveys=()=>{

    const [activeTabId, setActiveTabId] = useState(1);
    const handleActiveTabId=(tabId)=>{
        if(tabId === 2){
            if(surveyData.title === null || surveyData.title === undefined || surveyData.title === ''){
                return handleFailureMessage(t('EnterTitleAddressTheSurvey'))
            }
            if(surveyData.slug === null ||  surveyData.slug === undefined || surveyData.slug === ''){
                return handleFailureMessage(t('EnterTitleAddressTheSurvey'))
            }

            setActiveTabId(tabId);
        }else{
            setActiveTabId(tabId);

        }
    }



    const [isEdit,setIsEdit] = useState(false);
    const[result,setResult] = useState(false);
    const [options,setOptions] = useState({surTypes:[],accessTypes:[],limitTypes:[],sendingStates:[],templates:[],surveyTypes:[]})
    const queryParams = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {spinner,errors,setErrors,onApiError,handleCloseFilter,setModal,modal,setSpinner,onSetErrors} = useModal();
    const {handleSelectValue,handleToDate,handleValue,handleFromDate,InputSwitchValueHandler,setSurveyData,surveyData,switchData} = useDataBinders(setErrors);
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {handleCheckContentSlug,setOldSlug,oldSlug,setContentIsSlugValid,isContentSlugValid} = useSlugValidation(surveyData,onApiError);

    const {handleValidateSurvey,setSurvey,survey,handleAddSurvey,handleChangeSurvey,handleRemoveSurvey,handleChangeSurveyItems,handleAddSurveyItem,handleRemoveSurveyItem,handleUpdateRangeSurvey,handleShowEmoji,validateSurveyItems} = useSurvey(options,setResult,onApiError);
    const handleFetchData = async () => {
        try {
            setSpinner(true);

            // Initiate all GET requests simultaneously
            const [responseSurveyTypes, responseAccessTypes, responseLimitType, responseSendingState, responseTemplates] = await Promise.all([
                GET('Enumerations/EnSurveyType', {}, 'IEnumerable'),
                GET('Enumerations/EnAccess', {}, 'IEnumerable'),
                GET('Enumerations/EnLimitationType', {}, 'IEnumerable'),
                GET('/EnSendingStates', {}, 'IEnumerable'),
                GET(`/TemplatePages/selection?Type=${13}&Type=${18}`, {}, 'IEnumerable')
            ]);

            // Check if all responses are successful
            if (responseSurveyTypes.status === 200 && responseAccessTypes.status === 200 && responseLimitType.status === 200 && responseSendingState.status === 200 && responseTemplates.status === 200) {
                const accessTypes = convertSelectDataHandler(responseAccessTypes.data);
                const limitTypes = convertSelectDataHandler(responseLimitType.data);
                const sendingStates = convertSelectDataHandler(responseSendingState.data);
                const templates = convertSelectDataHandler(responseTemplates.data);
                const surveyTypes = convertSelectDataHandler(responseSurveyTypes.data);

                setOptions({ accessTypes, limitTypes, sendingStates, templates, surveyTypes });
                setSurveyData({ ...surveyData, limitVote: 0, fromDate: new Date(), sendingStates: [sendingStates[0]], limitationType: limitTypes[0], access: accessTypes[0], stateShow: true, stateShowResult: true });
                setSurvey({
                    surveys: [
                        {
                        id: null,
                        question: '',
                        type: surveyTypes[0],
                        row: 1,
                        fakeId: new Date().getTime(),
                        surveyItems: [{
                            fakeId: new Date().getTime(),
                            id: null,
                            surveyItemName: '',
                            surveyItemValue:0,
                            linkedSurveyRaw: 0,
                            nextLinkedSurveyRaw: 0,
                            row: 1,
                            IsShowEmoji: false
                        }
                        ]
                    }]
                });

                if (queryParams.surveyGroupId) {
                    try {
                        const response = await GET(`/Surveys/${queryParams.surveyGroupId}`, {}, 'single');
                        if (response.status === 200) {
                            setOldSlug(response.data.slug);
                            setIsEdit(true);

                            if (response.data.surveys.length !== 0) {
                                const surveys = handleFilterSurveys(response.data.surveys, surveyTypes);
                                setSurvey((prevSurvey) => ({
                                    ...prevSurvey,
                                    surveys: surveys.map((item,index) => ({
                                        ...item,
                                        fakeId: new Date().getTime()+index,
                                        surveyItems: item.surveyItems.map((surveyItemInner) => ({
                                            ...surveyItemInner,
                                        }))
                                    }))
                                }));
                            } else {
                                setSurvey({
                                    surveys: [{
                                        id: null,
                                        question: '',
                                        type: surveyTypes[0],
                                        row: 1,
                                        fakeId: new Date().getTime(),
                                        surveyItems: [{
                                            fakeId: new Date().getTime(),
                                            id: null,
                                            surveyItemName: '',
                                            surveyItemValue: '',
                                            linkedSurveyRaw: 0,
                                            nextLinkedSurveyRaw: 0,
                                            row: 1,
                                            IsShowEmoji: false
                                        }]
                                    }]
                                });
                            }

                            setSurveyData({
                                ...surveyData,
                                access: accessTypes.find((item) => item.value === response.data.access),
                                filterId: null,
                                fromDate: response.data.fromDate,
                                toDate: response.data.toDate,
                                id: response.data.id,
                                slug: response.data.slug,
                                limitVote: response.data.limitVote,
                                limitationType: limitTypes.find((item) => item.value === response.data.limitationType),
                                sendingStates: response.data.sendingStates && sendingStates ? response.data.sendingStates.map((value) => sendingStates.find((item) => item.value === value)) : [],
                                stateAndroid: response.data.stateAndroid,
                                stateGoogle: response.data.stateGoogle,
                                stateShow: response.data.stateShow,
                                title: response.data.title,
                                stateShowResult: response.data.stateShowResult,
                                templateId: response.data.templateId ? templates.find((item) => item.value === response.data.templateId) : null,
                            });

                        }
                        else {
                            handleApiError(response, onApiError);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                setSpinner(false);
            } else {
                handleApiError(responseAccessTypes, onApiError);
                handleApiError(responseLimitType, onApiError);
                handleApiError(responseSendingState, onApiError);
            }
        } catch (e) {
            console.log(e);
        }
    };


    useEffect(()=>{
        handleFetchData();
    },[result])


















    const handleSubmitPoll=async (e)=>{
        e.preventDefault();
        const isValid = validate(surveySchema,surveyData,onSetErrors);
        if(!isValid){
            return;
        }
        if(isEdit){
            try {
                setSpinner(true);
                const surveyGroup = handleCreateEditSurvey(survey.surveys);
                const updatedSurveyGroup = handleUpdateRangeSurvey(surveyGroup);
                const pollData = handleCreatePoll(surveyData,updatedSurveyGroup);
                const isSurveyValid = handleValidateSurvey(surveyGroup);
                if(!isSurveyValid){
                    handleFailureMessage(t("Thesurveyquestiontextoroptionsareempty"));
                    setSpinner(false);
                    return;
                }

                const isSurveyItemValid = validateSurveyItems(surveyGroup);
                if(!isSurveyItemValid){
                    handleFailureMessage(t("ChooseTheNextQuestionsFromTheAvailableQuestions"));
                    setSpinner(false);
                    return;
                }
                const response = await PUT(`/Surveys`,pollData,false);
                if(response.status === 200){
                    handleSuccessMessage(t("Thesurveyhasbeensuccessfullyedited"));
                    setSpinner(false);
                    redirectTo(`/CMS/Surveys/List`);
                }
                else{
                    handleApiError(response,onApiError);
                }
            }catch (e) {
                onApiError()
            }
        }
        else{
            try {

                setSpinner(true);
                const surveyGroup = handleCreateSurvey(survey.surveys);
                const updatedSurveyGroup = handleUpdateRangeSurvey(surveyGroup);
                const pollData = handleCreatePoll(surveyData,updatedSurveyGroup);
                const isSurveyValid = handleValidateSurvey(updatedSurveyGroup);
                if(!isSurveyValid){
                    handleFailureMessage(t("Thesurveyquestiontextoroptionsareempty"));
                    setSpinner(false);
                    return;
                }

                const isSurveyItemValid = validateSurveyItems(surveyGroup);
                if(!isSurveyItemValid){
                    handleFailureMessage(t("ChooseTheNextQuestionsFromTheAvailableQuestions"));
                    setSpinner(false);
                    return;
                }

                const response = await POST(`/Surveys`,pollData,false);
                if(response.status === 200){
                    handleSuccessMessage(t("Thesurveyhasbeensuccessfullysubmitted"));
                    setSpinner(false);
                    redirectTo(`/CMS/Surveys/List`);
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                onApiError()
            }
        }
    }






    return(
        <>
            {console.log(survey)}
            <div className="space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/Surveys/List`)}
                                            icon={"pi-list"}
                                            title={t("list")}
                                            tooltipTitle={t("ListOfSurveys")}
                                        />
                                    </>
                                }
                    />

                </div>
                <form method={'post'} className="space-y-5 relative" onSubmit={handleSubmitPoll} >
                    {

                        spinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className={"post__content tab-content p-2 bg-white dark:bg-themeDarkSalt700"}>
                        <div className={`tab-content__pane  space-y-5 ${activeTabId === 1 ? 'active' : ''} `}>
                            <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                                <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                                    1
                                </div>
                                <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                                    <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">
                                        {t("InitialSurveySettings")}
                                    </h2>
                                </div>
                                <div>
                                <span className={"arrow_design_add_site"}>
                                    <ArrowLeftIcon/>
                                </span>
                                </div>
                            </div>
                            <div className={"p-2 space-y-5"}>
                                <div className={"space-y-5 "}>
                                    <div className={"flex items-center gap-3 post-input px-2 py-5 post-input-design post-input dark:bg-themeDarkPostInput"}>
                                        <div className={"flex-1 w-full "}>
                                            <div className={"float-input p-float-label relative"}>
                                                <InputText onInput={handleValue}  name={'title'} value={surveyData.title} onBlur={handleCheckContentSlug} type="text"  className="input w-full input-float-label" />
                                                <label className={"text-xs dark:text-gray-300"}>
                                                    {t("TitleSurvey")}
                                                    <RequiredIcon/>
                                                </label>
                                                <div className={"absolute end-2 -top-2"}>
                                                    <DisplayError message={errors.title}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"flex-shrink-0 grid place-content-center"}>
                                            <SlugGenerator
                                                data={surveyData}
                                                showSpinner={()=>setSpinner(true)}
                                                hideSpinner={()=>setSpinner(false)}
                                                setData={setSurveyData}
                                            />
                                        </div>
                                        <div className={"flex-1 w-full"}>
                                            <div className={"float-input p-float-label relative"}>
                                                <InputText onBlur={handleCheckContentSlug} name={'slug'} value={surveyData.slug} onInput={handleValue} type="text" className={`input w-full input-float-label  ${isContentSlugValid && isContentSlugValid === false ? "InputError" : ""}`} />
                                                <label className={"text-xs dark:text-gray-300"}>
                                                    {t("SurveyAddress")}
                                                    <RequiredIcon/>
                                                </label>
                                                <div className={"absolute end-2 -top-2"}>
                                                    <DisplayError message={errors.slug}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-5"}>
                                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-3 ">
                                                <div class="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("type")}</label>
                                                    <Select
                                                        options={options.limitTypes}
                                                        isMulti={null}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(data)=>handleSelectValue(data,'limitationType')}
                                                        name={'limitationType'}
                                                        value={surveyData.limitationType}
                                                        placeholder={t("selection")}
                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                </div>
                                                <div class="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("ShowResult")}</label>
                                                    <Select
                                                        options={options.accessTypes}
                                                        isMulti={null}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(data)=>handleSelectValue(data,'access')}
                                                        name={'access'}
                                                        value={surveyData.access}
                                                        placeholder={t("selection")}
                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                </div>
                                                <div class="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("VotingLimit")}</label>
                                                    <InputNumber type={'text'} showButtons={true} min={0} name={'limitVote'} value={surveyData.limitVote} className={'w-full'} onInput={(e)=>handleValue(e.value)}/>
                                                </div>
                                                <div class="space-y-1 ">
                                                    <label className="text-xs dark:text-gray-300">{t("template")}</label>
                                                    <Select
                                                        options={options.templates}
                                                        isMulti={null}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(data)=>handleSelectValue(data,'templateId')}
                                                        name={'templateId'}
                                                        value={surveyData.templateId}
                                                        placeholder={t("selection")}
                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                </div>
                                                <div class="space-y-1">
                                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                                        <SettingButton
                                                            onClick={()=>handleSetting('GeneralSetting')}
                                                            tooltipTitle={t("Calendarsettings")}
                                                        />
                                                        {t("FromDate")}
                                                    </label>
                                                    <DateTimePicker handleDateTime={handleFromDate} name={'fromDate'} value={surveyData.fromDate} />
                                                </div>
                                                <div class="space-y-1 ">
                                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                                        <SettingButton
                                                            onClick={()=>handleSetting('GeneralSetting')}
                                                            tooltipTitle={t("Calendarsettings")}
                                                        />
                                                        {t("ToDate")}
                                                    </label>
                                                    <DateTimePicker handleDateTime={handleToDate} name={'toDate'} value={surveyData.toDate} />
                                                </div>
                                            </div>

                                            <Accordion className={"bg-white dark:bg-themeDarkSalt700"}>
                                                <AccordionTab
                                                    header={
                                                        <div className="flex items-center gap-2">
                                                            <p>{t("Publishing")}</p>
                                                        </div>
                                                    }
                                                >

                                                    <div className={"post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                                                        <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                                            <div className={"flex flex-col md:flex-row items-center gap-3"}>
                                                                <div className="space-y-1 w-full flex-1">
                                                                    <label className="text-xs dark:text-gray-300">
                                                                        {t("ReleaseFor")}
                                                                        <button type="button" className="text-themeInformation text-xs ms-2 dark:text-themeDarkInformation" onClick={() => setModal({ HandleAddFilter: true })}>({t("CreateFilter")}) </button>
                                                                    </label>
                                                                    <Select
                                                                        isMulti={null}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        name={'contentPermission'}
                                                                        placeholder={t("selection")}
                                                                        classNamePrefix={"h_multi_select"}
                                                                        menuPosition={"fixed"}
                                                                    />
                                                                </div>
                                                                <div className={"space-y-1 w-full flex-1"}>
                                                                    <label className="text-xs dark:text-gray-300">
                                                                        {t("CommunicationMedium")}
                                                                    </label>
                                                                    <Select
                                                                        options={options.sendingStates}
                                                                        isMulti={true}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={(data)=>handleSelectValue(data,'sendingStates')}
                                                                        name={'sendingStates'}
                                                                        value={surveyData.sendingStates}
                                                                        placeholder={t("selection")}
                                                                        className={"select-group"}
                                                                        classNamePrefix={"h_multi_select"}
                                                                        menuPosition={"fixed"}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={"flex items-center justify-around"}>
                                                                <InputSwitchComponent OnValueSelection={InputSwitchValueHandler} switchData={switchData} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </AccordionTab>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                                <ButtonSubmitAndCancel
                                    // onCancelClick={() => setActiveDiv(1)}
                                    registerText={t("Next")}
                                    type={"button"}
                                    onRegisterClick={() => handleActiveTabId(2)}
                                    showCancelButton={true}
                                    // cancelText={t("previous")}
                                />
                            </div>
                        </div>
                        <div className={`tab-content__pane space-y-5 ${activeTabId === 2 ? 'active' : ''} `}>
                            <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                                <div class="rounded-full size-16 lg:size-16 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                                    2
                                </div>
                                <div class="flex-1 md:border-4 border-dashed lg:p-5 ">
                                    <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">
                                        {surveyData.title}
                                    </h2>
                                </div>
                                <div>
                                <span className={"arrow_design_add_site"}>
                                    <ArrowLeftIcon/>
                                </span>
                                </div>
                            </div>
                            <div className={"p-2 space-y-5"}>
                                <div className=" space-y-5 harmony_survey">
                                    { survey.surveys.map((item, index) => (
                                        <div key={index} className="harmony_survey_question border dark:border-themeDarkColorLine relative">
                                            <div className={"absolute end-2 z-10 top-0 -translate-y-1/2"}>
                                                <div className={"flex items-center gap-2"}>
                                                    {
                                                        index === survey.surveys.length - 1?
                                                            <>
                                                                <div>
                                                                    <button type="button" onClick={() => handleAddSurvey(index + 2)} className="button text-white bg-themeInformation dark:bg-themeDarkInformation text-xs">
                                                                        {/*<HPrimeIconSmall icon={"pi-plus"}/>*/}
                                                                        {t("NewQuestion")}
                                                                    </button>
                                                                </div>
                                                            </>:null
                                                    }

                                                    <div>
                                                        <button type="button" onClick={() => handleRemoveSurvey(item.fakeId, item.id, true)} className="button text-white bg-themeDanger text-xs">
                                                            {/*<HPrimeIconSmall icon={"pi-minus"}/>*/}
                                                            {t("DeleteQuestion")}
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="p-2 space-y-10">
                                                <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                                                    <div className="md:col-span-1 ">
                                                        <div className="flex items-end gap-2">
                                                            <InputText
                                                                value={item.row}
                                                                name={'row'}
                                                                placeholder={t("Numeric")}
                                                                onChange={(e) => handleChangeSurvey(item.fakeId, 'row', e.target.value, item.id)}
                                                                type={'text'}
                                                                className="w-12 input text-white text-center"
                                                            />

                                                            <div className="space-y-1 flex-1">
                                                                <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                                                                <Select
                                                                    options={options.surveyTypes}
                                                                    isMulti={null}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    onChange={(data) => {
                                                                        handleChangeSurvey(item.fakeId, 'type', data, item.id);
                                                                    }}
                                                                    name={'limitationType'}
                                                                    value={item.type}
                                                                    placeholder={t("selection")}
                                                                    menuPosition={'fixed'}
                                                                    classNamePrefix={"h_multi_select"}

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1 md:col-span-2">
                                                        <label className="text-xs dark:text-gray-300 ">
                                                            {t("Question")}
                                                        <RequiredIcon/>
                                                        </label>
                                                        <InputText
                                                            className="input w-full"
                                                            placeholder={t("QuestionText")}
                                                            name={'question'}
                                                            onChange={(e) => handleChangeSurvey(item.fakeId, 'question', e.target.value, item.id)}
                                                            value={item.question}
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    item.type !== null && item.type.value === 1 ? (
                                                        item.surveyItems.map((singleItem, singleIndex) => (
                                                            <Single
                                                                item={item}
                                                                handleChangeSurveyItems={handleChangeSurveyItems}
                                                                singleIndex={singleIndex}
                                                                singleItem={singleItem}
                                                            />
                                                        ))
                                                    ) : item.type !== null && item.type.value === 0 ? (
                                                            item.surveyItems.map((multiItem, multiIndex) => (
                                                                <Multi
                                                                    item={item}
                                                                    handleChangeSurveyItems={handleChangeSurveyItems}
                                                                    multiItem={multiItem}
                                                                    handleAddSurveyItem={handleAddSurveyItem}
                                                                    multiIndex={multiIndex}
                                                                    handleRemoveSurveyItem={handleRemoveSurveyItem}
                                                                />
                                                            ))
                                                        ) :
                                                        item.type !== null && item.type.value === 3?
                                                            item.surveyItems.map((emojiItem, emojiIndex) => (
                                                                <Emoji
                                                                    emojiIndex={emojiIndex}
                                                                    emojiItem={emojiItem}
                                                                    handleAddSurveyItem={handleAddSurveyItem}
                                                                    item={item}
                                                                    handleChangeSurveyItems={handleChangeSurveyItems}
                                                                    handleRemoveSurveyItem={handleRemoveSurveyItem}
                                                                    handleShowEmoji={handleShowEmoji}
                                                                />
                                                            ))
                                                            :
                                                            item.type !== null && item.type.value === 2 ? (
                                                                item.surveyItems.map((rangeItem, rangeIndex) => (
                                                                    <Range
                                                                        handleAddSurveyItem={handleAddSurveyItem}
                                                                        handleRemoveSurveyItem={handleRemoveSurveyItem}
                                                                        handleChangeSurveyItems={handleChangeSurveyItems}
                                                                        item={item}
                                                                        rangeIndex={rangeIndex}
                                                                        rangeItem={rangeItem}
                                                                    />
                                                                ))
                                                            ) : null

                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <ButtonSubmitAndCancel
                                        onCancelClick={() => handleActiveTabId(1)}
                                        cancelText={t("previous")}

                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                </form>
            </div>

            <FantasyModal openModal={modal.HandleAddFilter} closeModal={handleCloseFilter} title={t("CreateFilter")} customeClassName={"modal-xl"}>
                <div>
                    <AddFilter/>
                </div>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>

            <ToastContainer/>
        </>

    )
}