import React, {useState} from "react";
import {InputText} from "primereact/inputtext";




import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {TabMenu} from "../../../Commans/UIParts/TabMenu";

import Select from "react-select";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const HandleAddGoods=()=>{

    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("tab_one");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
    const tabs = [
        {
            id: "tab_one",
            label: "عمومی",
            icon: "code",
        },
        {
            id: "tab_two",
            label: "موجودی",
            icon: "align-left",
        },
        {
            id: "tab_three",
            label: "مالیات",
            icon: "divide",
        },
    ];

    return(
        <div className=" space-y-5">
            <div class=" grid grid-cols-12 gap-5 ">
                <div class=" col-span-12 lg:col-span-8 space-y-3">
                    <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                        <div class="flex gap-5 items-center">
                            <label className="text-xs dark:text-gray-300">نوع محصول:</label>
                            <div class="can-toggle demo-rebrand-1 text-xs">
                                <input id="swich" type="checkbox"/>
                                <label htmlFor="swich">
                                    <div class="can-toggle__switch" data-checked="خدمات" data-unchecked="کالا"></div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="box bg-white dark:bg-themeDarkSalt700 p-3 grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div class="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <label className="text-xs dark:text-gray-300">نام محصول:</label>
                            <label className="hidden text-xs dark:text-gray-300">نام خدمات:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <div class="flex gap-5 items-center">
                                <label className="text-xs dark:text-gray-300">کد حسابداری:</label>
                                <div class="can-toggle demo-rebrand-1 text-xs">
                                    <input id="swich2" type="checkbox"/>
                                    <label htmlFor="swich2">
                                        <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                    </label>
                                </div>
                            </div>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <label className="text-xs dark:text-gray-300">کدمحصول: </label>
                            <label className="hidden text-xs dark:text-gray-300">کد خدمات:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <label className="text-xs dark:text-gray-300">بارکد:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <label className="text-xs dark:text-gray-300">قیمت فروش:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <label className="text-xs dark:text-gray-300">قیمت خرید:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                    </div>
                    <div class="post box bg-white dark:bg-themeDarkSalt700">
                        <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />

                        <div class="post__content tab-content">
                            <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                                <div className="post-input dark:bg-themeDarkPostInput space-y-2 rounded-md p-2">
                                    <div class="grid grid-cols-2 gap-5  ">
                                        <div class="space-y-2 ">
                                            <label className="text-xs dark:text-gray-300"> واحداصلی :</label>
                                            <Select
                                                placeholder={t("selection")}
                                                classNamePrefix={"h_multi_select"}
                                                menuPosition={"fixed"}

                                            />
                                            {/*<select  className="select2 w-full" data-hide-search="true">*/}
                                            {/*    <option value="0">...</option>*/}
                                            {/*    <option value="1">عدد</option>*/}
                                            {/*    <option value="2">بسته</option>*/}
                                            {/*    <option value="2">کارتن</option>*/}
                                            {/*</select>*/}
                                        </div>
                                        <div class="space-y-2 ">
                                            <label className="text-xs dark:text-gray-300"> واحد جزء:</label>
                                            <Select
                                                placeholder={t("selection")}
                                                classNamePrefix={"h_multi_select"}
                                                menuPosition={"fixed"}

                                            />
                                            {/*<select name="" className="select2 w-full" data-hide-search="true">*/}
                                            {/*    <option value="0">...</option>*/}
                                            {/*    <option value="1">عدد</option>*/}
                                            {/*    <option value="2">بسته</option>*/}
                                            {/*</select>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                                <div className=" post-input dark:bg-themeDarkPostInput space-y-2 rounded p-2">
                                    <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                                        <div class=" space-y-2">
                                            <label className="text-xs dark:text-gray-300"> یاد آوری سفارش:</label>
                                            <InputText className="input w-full"/>
                                        </div>
                                        <div class=" space-y-2">
                                            <label className="text-xs dark:text-gray-300">حداقل سفارش:</label>
                                            <InputText className="input w-full"/>
                                        </div>
                                        <div class=" space-y-2">
                                            <label className="text-xs dark:text-gray-300">زمان تحویل(روز):</label>
                                            <InputText className="input w-full"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_three" ? "active" : "" }`} id="tab_three">
                                <div className=" post-input dark:bg-themeDarkPostInput space-y-2 rounded p-2">
                                    <div class="grid grid-cols-2 gap-2 ">
                                        <div class=" space-y-2 ">
                                            <label className="text-xs dark:text-gray-300">مالیات فروش:(درصد)</label>
                                            <InputText className={"input w-full "} />
                                        </div>
                                        <div class="space-y-2">
                                            <label className="text-xs dark:text-gray-300"> مالیات خرید:(درصد)</label>
                                            <InputText className={"input w-full "} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-4 ">
                    <div class="box space-y-5 bg-white p-3 dark:bg-themeDarkSalt700">
                        <div class=" space-y-3">
                            <div className="w-full h-48 ">
                                <UploadImage/>
                            </div>
                            <div class="grid grid-cols-2 gap-2">
                                <div className="space-y-2">
                                    <label className="text-xs dark:text-gray-300">تاریخ تولید</label>
                                    <InputText type={"text"} className={"input w-full "} />
                                </div>
                                <div className="space-y-2">
                                    <label className="text-xs dark:text-gray-300">تاریخ انقضاء</label>
                                    <InputText type={"text"} className={"input w-full "} />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <label className="text-xs dark:text-gray-300">
                                    گروه بندی
                                    <a href="#" className="text-themeInformation dark:text-themeDarkInformation text-xs me-2"> ( ایجاد گروه)
                                    </a>
                                </label>
                                    <Select
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                    {/*<select data-placeholder="Select your favorite actors" className="select2 w-full"*/}
                                    {/*        multiple>*/}
                                    {/*    <option value="1" selected>اطلاع رسانی</option>*/}
                                    {/*</select>*/}
                            </div>
                            <div class="post-input rounded-md p-3 dark:bg-themeDarkPostInput ">
                                <div className="space-y-2">
                                    <label className="text-xs dark:text-gray-300"> وضعیت نمایش درفروشگاه وب سایت</label>
                                    <div >
                                        <input className="input input--switch border bg-white" type="checkbox"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div >
                            <button className="bg-themeInformation text-white py-3 w-full rounded-md flex items-center justify-center gap-2 text-lg hover:bg-themeHoverInformation dark:bg-themeDarkInformation">
                                <HPrimeIcon icon={'bookmark'}/>
                                ثبت
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
