import React, {useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {saveAccessToken, saveRefreshToken} from "../../../Services/AxiosService/JwtService";
import { InputText } from "primereact/inputtext";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LoadingSpinner } from "../../Commans/Loadings/LoadingSpinner";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import { POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../Services/Globals/validate";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {LanguageChanger} from "../../Commans/Globals/LanguageChanger";
import {UseRememberMe} from "../../Commans/Hooks/UseRememberMe";
import {loginSchema} from "./loginSchema";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";


export const  HandleLogin=()=> {
  //const { handleChange, selectedObject } = RegxSchema();
  const {rememberMe, handleRememberMe, saveCredentials,savedCredentials} = UseRememberMe();
  const [spinner, setSpinner] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const redirectTo = useNavigate();

  const {t} = useTranslation();
  const [users, SetUsers] = useState({username: "", password: ""});
  const [errors, setErrors] = useState({});
  const [modal, setModalOpen] = useState(false);

  // const [initialSource,setInitialSource]=useState({urls:[
  //   {value:'https://core.harmonycms.ir/api',label:'https://core.harmonycms.ir/api'}
  //   ,{value:'http://192.168.168.39:45457/api',label:'http://192.168.168.39:45457/api'}
  //   ,{value:'http://192.168.168.39:45456/api',label:'http://192.168.168.39:45456/api'}
  //   ,{value:'http://192.168.168.23:45455/api',label:'http://192.168.168.23:45455/api'}
  // ]})
  // const [baseUrl,setBaseUrl]=useState({url:{value:'https://core.harmonycms.ir/api',label:'https://core.harmonycms.ir/api'}});



  const handleValue = (e) => {
    const {name, value} = e.target;
    SetUsers({...users, [name]: value});
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const onApiError = () => {
    setSpinner(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };
  const handleClose = () => {
    setModalOpen(false);
  }
  const OnSetErrors = (errors) => {
    setErrors(errors);
  }

  useEffect(() => {
    if (rememberMe && savedCredentials) {
      SetUsers((prevUsers) => ({
        ...prevUsers,
        username: savedCredentials.username,
        password: savedCredentials.password,
      }));
    }
  }, [rememberMe, savedCredentials]);


  const LoginApiHandler = async (e) => {
    e.preventDefault();
    var isValid = validate(loginSchema,users,OnSetErrors)
    if(!isValid){
      return;
    }

  try {
    saveCredentials(users.username,users.password);
    setSpinner(true);
    const {username, password} = users;
    const response = await POST('/authentication/login', {username,element:{password:password},authenticationMode:1}, false);
    if (response.status === 200) {
      saveAccessToken(response.data.accessToken);
      saveRefreshToken(response.data.refreshToken);
      // if(response.data.isFirstLogin){
      //
      // }
      // else{
      //
      // }
      window.location = '/Dashboard';
    }
    else {
      handleApiError(response,onApiError);
    }
  }
  catch (e) {
    onApiError();
  }
  };





  return (
      <>
        {spinner ? <LoadingSpinner/> : null}

        <div className="h-ltr flex flex-col items-center justify-center gap-5 ">
          <div className="flex items-center xl:hidden">
            <img alt="harmony2023" className="w-48 h-auto" src="http://cpanel.harmonycms.ir/ArmDark.svg"/>
          </div>
          <div className="lg:w-[23rem] space-y-5 bg-white p-5 dark:bg-themeDarkSalt700 border border-gray-300 rounded-lg max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)]">
            <div>
              <div className="flex justify-items-start">
                <h2 className="text-2xl xl:text-3xl dark:text-gray-300">
                  {t("LogIn")}
                </h2>
              </div>
              <div className="text-justify xl:hidden ">
                <p className={"text-neutral-500 text-xs dark:text-gray-300"}>{t("SignInTitle")}</p>
              </div>
            </div>

            <form method="post" onSubmit={LoginApiHandler}>
              <div className="space-y-5 ">
                <div className="space-y-2">

                  <div className="relative float-input p-float-label ">
                    <div className={"absolute -top-2 end-2"}>
                      <DisplayError message={errors.username}/>
                    </div>
                    <span className={"absolute end-2 top-1/2 -translate-y-1/2"}>
                      <HPrimeIconSmall
                      icon={"pi-user"}
                      iconClass={"dark:text-gray-300"}
                      />
                    </span>
                   <InputText onInput={(e) => handleValue(e)}
                             value={users.username}
                             // placeholder={t("userName")}
                             type="text"
                             className={" input w-full input-float-label"}
                             name="username"/>
                    <label className={"text-xs "}>{t("userName")}</label>

                </div>

                </div>
                <div className={"relative float-input p-float-label"}>
                  <div className={"absolute -top-2 end-2"}>
                    <DisplayError message={errors.password}/>
                  </div>
                  <HPrimeIconSmall
                      iconClass={"cursor-pointer absolute dark:text-gray-300 end-2 top-1/2 -translate-y-1/2"}
                      icon={
                    showPassword
                        ? "pi pi-eye"
                        : "pi pi-eye-slash"
                  }
                     onClick={() => setShowPassword(!showPassword)}>

                  </HPrimeIconSmall>

                  <InputText type={showPassword ? "text" : "password"}
                             className=" input w-full input-float-label"
                             // placeholder={t("OtpAuthentication")}
                             value={users.password}
                             onInput={(e) => handleValue(e)}
                             name="password"
                      //onKeyDown={(event)=>event.key === 'Enter' ? SubmitHandlerApi():''}
                  />
                  <label className={"text-xs "}>{t("Password")}</label>
                </div>
                <div >

                  <LanguageChanger/>

              {/*    <Select*/}
              {/*  */}
              {/*  options={initialSource.urls}*/}
              {/*  placeholder={t("selection")}*/}
              {/*  classNamePrefix={"h_multi_select"}*/}
              {/*  menuPosition={"fixed"}*/}
              {/*  value={baseUrl.url}*/}
              {/*  onChange={(data)=>setBaseUrl(()=>({...baseUrl,url:data}))}*/}
              {/*/>*/}
              

                </div>
                <div className="flex justify-between  ">
                  <div className="flex items-center gap-x-2">
                    <input type={"checkbox"} onChange={handleRememberMe} checked={rememberMe} />
                    <label className="cursor-pointer dark:text-gray-300 text-xs" htmlFor="remember-me">
                      {" "}
                      {t("rememberMe")}
                    </label>
                  </div>
                  <Link
                      to={"/Authentication/ForgetPassword"}
                      className="text-themeInformation text-xs dark:text-themeDarkInformation">
                    {t("forgetPassword")}
                  </Link>
                </div>
                <div>
                  <button type={'submit'} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}>
                    {t('LogIn')}
                  </button>
                </div>
              </div>


            </form>

            <div className="flex gap-1 items-center text-xs justify-center cursor-pointer" onClick={() => openModal(true)}>
                <p className="text-themeInformation dark:text-themeDarkInformation">
                  {t("conditions")}
                </p>
                &
                <p className=" text-themeInformation dark:text-themeDarkInformation">
                  {t("PrivacyPolicy")}
                </p>
              </div>

          </div>
        </div>
        <div className="h-ltr hidden xl:flex flex-col items-center justify-center gap-5 ">
          <img alt="harmony2023" className=" w-1/2" src="/Assets/Image/Design/illustration.svg"/>
          <div className={"max-w-[27rem] lg:min-w-[23rem] space-y-2"}>
            <p className={"text-4xl text-white dark:text-gray-300"}>
              {t("Right_SideSignInTitle")}
            </p>
            <p className={" text-4xl text-white dark:text-gray-300"}>
              {t("Right_SideSignInTitleTwo")}
            </p>
            <span className="text-white dark:text-gray-300">{t("SignInTitle")}</span>
          </div>
        </div>
        <FantasyModal openModal={modal} closeModal={handleClose} >
          <div>
            <h2>{t("conditions")}</h2>
          </div>
          <div>
            <p className="p-3 text-justify leading-8">
              سیاست حفظ حریم خصوصی وب سایت معمولاً شامل موارد زیر است: - توضیحی در
              مورد انواع اطلاعات شخصی که جمع آوری می شود، مانند نام، آدرس ایمیل،
              شماره تلفن و سابقه مرور. -نحوه جمع‌آوری اطلاعات شخصی، از جمله از
              طریق کوکی‌ها یا فرم‌های آنلاین. - توضیح نحوه استفاده از اطلاعات
              شخصی، مانند اهداف بازاریابی یا بهبود عملکرد وب‌سایت.
            </p>
          </div>
        </FantasyModal>

        <ToastContainer/>

      </>
  );
}