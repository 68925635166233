import React from "react";
import {Link} from "react-router-dom";

import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const UserComment=({key,id,message,fullName, createdAt,state})=>{

    return (
        <>
            <Link to={"#"} className={"relative flex items-center gap-x-2 dashboard-note-Link -intro-x"}>
                <span className={"bg-themeSecondary dark:bg-themeDarkSalt700 size-7 rounded-full dashboard-note-design"}></span>
                <div class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 flex-1">
                    <div>
                        <HPrimeIcon icon={"pi-file-edit"} iconClass={"dark:text-gray-300"}/>
                    </div>
                    <div class="flex-1">
                        <div className="line-clamp-1 dark:text-gray-300">{fullName}</div>
                        <div className="line-clamp-1 ">{message} </div>
                        <div className="line-clamp-1 ">{state}</div>
                        <div class="text-gray-500 text-xs dark:text-gray-300">{ <GridDateFomartter date={createdAt} hasTime={false} />}</div>
                    </div>
                </div>
            </Link>
        </>
    )
}