
export const handleCovertEmployee=(employee,userId)=>{
  return {
      attendanceType:employee.attendanceType === null || employee.attendanceType === undefined?null:employee.attendanceType.value,
      bloodType:employee.bloodType === null || employee.bloodType === undefined?null:employee.bloodType.value,
      militaryServiceStatus:employee.militaryServiceStatus === null || employee.militaryServiceStatus === undefined?null:employee.militaryServiceStatus.value,
      housingStatus:employee.housingStatus === null || employee.housingStatus === undefined?null:employee.housingStatus.value,
      maritalStatus:employee.housingStatus === null || employee.maritalStatus === undefined?null:employee.maritalStatus.value,
      insurancePosition:employee.insurancePosition,
      signature:employee.signature,
      signaturePath:employee.signaturePath,
      marriageDate:employee.marriageDate === null || employee.marriageDate === undefined?null:employee.marriageDate,
      userId:userId,
  }
}
export const handleCreateEmployee = (employee,userId) => {
    const formData = new FormData();
    const employeeData = handleCovertEmployee(employee,userId);
    Object.entries(employeeData).forEach(([key, value]) => {
        if (key === 'marriageDate' && value instanceof Date) {
            formData.append(key, value.toISOString());
        } else {
            formData.append(key, value);
        }
    });
    return formData;
};