export const RoutingOption = ({ option, settingValue, handleSelectValue }) => {
    return (
        <label className="flex flex-wrap cursor-pointer items-center gap-5 w-max" key={option.value}>
            <input
                type="radio" name="routing"
                id={option.value}
                value={option.value}
                checked={settingValue === option.value}
                onChange={(e) => handleSelectValue(parseInt(e.target.value), 'value')}
            />

            <p className="dark:text-gray-300 text-xs">{option.label}</p>
        </label>
    );
};