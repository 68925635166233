import React from 'react';

const WhatsAppIcon = ({sizeStyle,fillStyle}) => (
    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="whatsapp" className={`${sizeStyle ? sizeStyle:"w-10 h-10"} ${fillStyle ? fillStyle:"text-themeWhatsApp"} dark:text-gray-300 `} role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M23.993 0C10.762 0 0 10.765 0 23.9998C0 29.2485 1.69266 34.116 4.57003 38.0669L1.5796 46.9838L10.8044 44.0355C14.5986 46.547 19.1264 48 23.993 48C37.2377 48 48 37.2343 48 24.0002C48 10.7657 37.2377 0.000398 23.993 0.000398L23.993 0ZM17.2929 12.1908C16.8275 11.0763 16.4748 11.0341 15.7698 11.0054C15.5297 10.9915 15.2622 10.9775 14.9656 10.9775C14.0485 10.9775 13.0895 11.2455 12.511 11.838C11.806 12.5576 10.0568 14.2364 10.0568 17.6792C10.0568 21.122 12.5676 24.4518 12.9059 24.9176C13.2586 25.3827 17.8008 32.5503 24.8533 35.4715C30.3684 37.7571 32.0049 37.5453 33.2601 37.2773C35.0937 36.8823 37.393 35.5272 37.9714 33.891C38.5498 32.254 38.5498 30.8572 38.3803 30.5609C38.2111 30.2647 37.7453 30.0958 37.0403 29.7426C36.3353 29.3898 32.9074 27.6967 32.2585 27.4709C31.6235 27.2312 31.0173 27.316 30.538 27.9933C29.8608 28.9387 29.198 29.8983 28.6618 30.4765C28.2386 30.9281 27.5471 30.9846 26.9691 30.7445C26.1933 30.4203 24.0213 29.6578 21.341 27.2734C19.2674 25.4253 17.8569 23.1258 17.4481 22.4345C17.0389 21.7293 17.4059 21.3195 17.7299 20.9389C18.0827 20.5012 18.421 20.191 18.7737 19.7817C19.1264 19.3727 19.3239 19.1609 19.5496 18.6811C19.7896 18.2156 19.6201 17.7357 19.4509 17.3829C19.2817 17.0301 17.8713 13.5873 17.2929 12.1908Z"></path>
        </g>
    </svg>
);

export default WhatsAppIcon;
