export const convertAddDiscount =(obj)=>{
    const newObj = {
        caption:obj.caption === null || obj.caption === undefined?null:obj.caption,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        code:obj.code === null || obj.code === undefined ?null:obj.code,
        isActive:obj.isActive === null || obj.isActive === undefined?null:obj.isActive,
        fromDate:obj.fromDate === null || obj.fromDate === undefined ? null:obj.fromDate,
        toDate:obj.toDate === null || obj.toDate === undefined ? null:obj.toDate,
        targetTypeId:obj.targetTypeId === null || obj.targetTypeId === undefined ? null:obj.targetTypeId.value,
        discountValue:obj.discountValue === null || obj.discountValue === undefined ? null:obj.discountValue,
        maxDiscountValue:obj.maxDiscountValue === null || obj.maxDiscountValue === undefined ? null:obj.maxDiscountValue,
        minBuyValue:obj.minBuyValue === null || obj.minBuyValue === undefined ? null:obj.minBuyValue,
        consumptionCount:obj.consumptionCount === null || obj.consumptionCount === undefined ? null:obj.consumptionCount,
        discountOrCommissionType:obj.discountOrCommissionType === null || obj.discountOrCommissionType === undefined ? null:obj.discountOrCommissionType.value,
    }
    return newObj;
}
export const convertEditDiscount = (obj,id)=>{
    const newObj = {
        id:id,
        caption:obj.caption === null || obj.caption === undefined?null:obj.caption,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        code:obj.code === null || obj.code === undefined ?null:obj.code,
        isActive:obj.isActive === null || obj.isActive === undefined?null:obj.isActive,
        fromDate:obj.fromDate === null || obj.fromDate === undefined ? null:obj.fromDate,
        toDate:obj.toDate === null || obj.toDate === undefined ? null:obj.toDate,
        targetTypeId:obj.targetTypeId === null || obj.targetTypeId === undefined ? null:obj.targetTypeId.value,
        discountValue:obj.discountValue === null || obj.discountValue === undefined ? null:obj.discountValue,
        maxDiscountValue:obj.maxDiscountValue === null || obj.maxDiscountValue === undefined ? null:obj.maxDiscountValue,
        minBuyValue:obj.minBuyValue === null || obj.minBuyValue === undefined ? null:obj.minBuyValue,
        consumptionCount:obj.consumptionCount === null || obj.consumptionCount === undefined ? null:obj.consumptionCount,
        discountOrCommissionType:obj.discountOrCommissionType === null || obj.discountOrCommissionType === undefined ? null:obj.discountOrCommissionType.value,
    }
    return newObj;
}
