import React, {useState} from "react";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../Commans/UIParts/ButtonSubmitAndCancel";

import {PickList} from "primereact/picklist";

export const HandleAttendanceFinancePersonnelAdd = () => {
    const { t } = useTranslation();

    const redirectTo = useNavigate();
    const [isOpen,setOpen] = useState(false);
    // const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const handleOpenModal = ()=>{
        setOpen(true);
    }
    const handleCloseModal = ()=>{
        setOpen(false);
    }

    const [dates, setDates] = useState([]);


    return(
        <>
            <div class="space-y-5 ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Personnel")}
                                            onClick={()=>redirectTo(`/Attendance/Personnel`)}
                                            tooltipTitle={t("Personnel")}
                                            icon={"users"}
                                        />

                                        <ButtonPlus
                                            icon={"plus-square"}
                                            title={t("Device")}
                                            onClick={()=>redirectTo("/Attendance/Add")}
                                            tooltipTitle={t("RegisterDevice")}
                                        />

                                        <ButtonPlus
                                            title={t("FinancialRules")}
                                            icon={"book"}
                                            onClick={()=>redirectTo("/Attendance/FinancialRules")}
                                            tooltipTitle={t("FinancialRules")}
                                        />

                                        <ButtonPlus
                                            title={t("Leave")}
                                            primIcon={"pi-calendar-clock"}
                                            onClick={()=>redirectTo("/Attendance/LeaveAndMission")}
                                            tooltipTitle={t("LeaveAndMission")}
                                        />

                                        <ButtonPlus
                                            title={t("Shift")}
                                            icon={"layers"}
                                            onClick={()=>redirectTo("/Attendance/ShiftWork/List")}
                                            tooltipTitle={t("ShiftWork")}
                                        />

                                        <ButtonPlus
                                            title={t("WorkGroup")}
                                            icon={"users"}
                                            onClick={()=>redirectTo("/Attendance/WorkGroup/List")}
                                            tooltipTitle={t("WorkGroup")}
                                        />

                                        <ButtonPlus
                                            title={t("archive")}
                                            icon={"archive"}
                                            onClick={()=>redirectTo("/Attendance/Archive")}
                                            tooltipTitle={t("ArchiveLeaveAndMission")}
                                        />


                                        <ButtonPlus
                                            title={t("Reports")}
                                            primIcon={"pi-chart-bar"}
                                            onClick={()=>redirectTo("/Attendance/List/Reports")}
                                            tooltipTitle={t("GeneralReport")}

                                        />


                                    </>
                                }

                    />

                </div>
                <div>
                    <div style={{direction:"ltr"}} class="p-3 box bg-white dark:bg-themeDarkSalt700">
                        <PickList
                            filter
                            filterBy="name"
                            sourceHeader={t("پرسنل آزاد")}
                            targetHeader={t("پرسنل فعال گروه")}
                            sourceStyle={{ height: "30rem" }}
                            targetStyle={{ height: "30rem" }}
                        />
                    </div>
                </div>
                <ButtonSubmitAndCancel
                />
            </div>

        </>

  )
}