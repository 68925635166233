import React from "react";

import { InputNumber } from "primereact/inputnumber";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const PriceElement = ({ itemCount,handleDefaultValueChange, SortDescending, SortAccending, index, element, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement }) => {


    return (
        <>
            <InputNumber
                placeholder={element?.properties?.description}
                className={` harmony-w-full ${index === selectedElementIndex ? 'border-themeInformation' : ''}`}
                id={element.properties.id}
                onClick={() => handleSelectTabAfterElementSelection(index)}
                max={element.properties.max}
                min={element.properties.min}
                readOnly={element.properties.isReadOnly}
                disabled={element.properties.isReadOnly}
                value={element.properties?.defaultValue?.value}
                onChange={(e)=>handleDefaultValueChange(index, 'value', e.value)}
                useGrouping={element.properties.useGrouping}
            />

            <div className={"harmony_body_button"}>
                <button
                    type="button"
                    className="button bg-themeDanger text-white harmony_button_form"
                    onClick={() => deleteElement(index)}
                >
                    <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
                </button>
                {index !== 0 && (
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortAccending(index)}
                    >
                        <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                    </button>
                )}
                {index < itemCount - 1 && (
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortDescending(index)}
                    >
                        <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                    </button>
                )}
            </div>


        </>
    );
};
