export const handleCreateRecovery=(recovery)=>{
    const formData = new FormData();

    recovery.products.forEach((file, index) => {
        formData.append(`ProductFiles`, file);
    });

    recovery.contents.forEach((file, index) => {
        formData.append(`ContentFiles`, file);
    });

    recovery.users.forEach((file, index) => {
        formData.append(`UserFiles`, file);
    });
    for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
    }
    return formData
}