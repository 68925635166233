import React, {useEffect, useState} from "react";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import ButtonVideo from "../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {SuccessPay} from "./SuccessPay";
import {ErrorPay} from "./ErrorPay";
import queryString from "query-string";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";

export const CallBack=()=>{

    const queryParams = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [payment,setPayment] = useState({amount:null,isSuccess:false,message:'',trackingCode:'',transactionCode:'',currencyName:'',transactionDate:null,userFullName:'',userAvatar:null,websiteName:'',webSiteLogo:null});
    const [IsShowSpinner,setIsShowSpinner] = useState(false)
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const Verify=async()=>{
        try {
            const url = "/Payments/Pay/Callback";
            const queryString = new URLSearchParams(queryParams).toString();
            const finalUrl = url + "?" + queryString;
            const response = await GET(finalUrl,{},'single');
            const responseCurrency = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
            if(response.status === 200 && responseCurrency.status === 200){
                const {amount,isSuccess,message,trackingCode,transactionCode,transactionDate,userFullName,userAvatar,webSiteName,webSiteLogo} = response.data;
                const currencies = convertSelectDataHandler(responseCurrency.data);
                const currency = currencies.find((item) => item.value === amount.currency);
                setPayment({amount: amount,trackingCode: trackingCode,transactionCode: transactionCode,isSuccess: isSuccess,message: message,currencyName: currency.label,transactionDate:transactionDate,userFullName: userFullName,userAvatar: userAvatar,webSiteLogo: webSiteLogo,websiteName: webSiteName});
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }
    useEffect(()=>{
        Verify();
    },[])


    return (
        <>
            <div className={"space-y-5"}>
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                childrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Dashboard`)}
                                            icon={"pi-desktop"}
                                            title={t("Dashboard")}
                                            tooltipTitle={t("Dashboard")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Bank/Transaction/list`)}
                                            icon={"pi-list"}
                                            title={t("transactions")}
                                            tooltipTitle={t("ListOfTransactions")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/InvoiceRequest`)}
                                            icon={"pi-file-edit"}
                                            title={t("Bill")}
                                            tooltipTitle={t("Bill")}
                                        />

                                    </>
                                }

                    />

                </div>

                {
                    payment.isSuccess?
                        <SuccessPay transactionCode={payment.transactionCode} trackingCode={payment.trackingCode} amount={payment.amount} currencyName={payment.currencyName} transactionDate={payment.transactionDate} userFullName={payment.userFullName} userAvatar={payment.userAvatar} websiteName={payment.websiteName} webSiteLogo={payment.webSiteLogo}   />
                        :
                        <ErrorPay transactionCode={payment.transactionCode} trackingCode={payment.trackingCode} amount={payment.amount} currencyName={payment.currencyName} transactionDate={payment.transactionDate} userFullName={payment.userFullName} userAvatar={payment.userAvatar} websiteName={payment.websiteName} webSiteLogo={payment.webSiteLogo}     />

                }

            </div>
        </>

    )
}