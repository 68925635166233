import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {Button} from "primereact/button";
import { Tooltip } from 'react-tooltip'
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {Image} from "primereact/image";
import Select from "react-select";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../../Commans/Grid/GridButtonOprations";
import {Export} from "../../Commans/Grid/Export";

export const HandleFavoriteListProducts=()=>{

    const { t } = useTranslation();


    const [modal, setModal] = useState({ ChangePrice: false, });

    const ChangePriceOnHide = () => {
        setModal((prevState) => ({ ...prevState, ChangePrice: !modal.ChangePrice }));
    };

    const [isMenuOpen,setMenuOpen] = useState(false);
    const [userId,setUserId] = useState('');
    const handleOpenMenu = () => {
        setMenuOpen(true);

    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }


    const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 110},

        { headerName: t("ProductImage"), minWidth: 150,
            cellRenderer:parameters=>
                <>
                    <Image src={""}  alt="Image" />:
                    <HPrimeIcon icon={'pi-times'} iconClass={"text-themeDanger"} />
                </>

        },


        { headerName: t("ProductName"), minWidth: 170,
            cellRenderer:parameters=>
                <>
                    شلوار جین
                </>
        },

        { headerName: t("FinalPrice"),minWidth: 150,

            cellRenderer:parameters=>

                <>2.000.000.000 تومان</>

        },


        { headerName: 'QrCode', minWidth: 140,
            cellRenderer:parameters=>
                < >
                    54545454545454
                </>
        },


        { headerName: t("DisplayOrder"), minWidth: 120,

            cellRenderer:parameters=>
                <>2</>
        },

        { headerName: t("AvailableUnavailable"), minWidth: 160,
            cellRenderer:parameters=>
                <div>
                    <HPrimeIcon icon={'pi-check-square'} iconClass="text-themeSuccess" />
                </div>
        },

        { headerName: t("Display"), minWidth: 120,
            cellRenderer:parameters=>
                <div>
                    <HPrimeIcon icon={'pi-check-square'} iconClass="text-themeSuccess" />
                </div>
        },


        { headerName: t("settings"), maxWidth: 130,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>
                    <GridButtonOprations
                        onClick={()=>handleOpenMenu()}
                    />
                </div>
            }
        },

    ];

    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };


    const redirectTo = useNavigate();




    return(<>
        <div class=" space-y-5">
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByNameProductCode")}</label>
                            <InputText type={"search"} className={" input w-full search"} placeholder={t("search")} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                            <Select
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                                placeholder={t("selection")}
                            />
                        </div>
                    <ButtonSearchList
                    />
                    </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => setModal({ ChangePrice: true })}
                                    />
                                </>
                            }
                            childrenTwo={
                                <>
                                    <div className=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">{t("SixMonthsToExpiration")}</lable>
                                        <input className="input input--switch " type="checkbox"/>
                                    </div>
                                    <div class=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">{t("OneYeartoExpiration")}</lable>
                                        <input className="input input--switch " type="checkbox"/>
                                    </div>
                                    <div class=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">{t("Available")}</lable>
                                        <input className="input input--switch " type="checkbox"/>
                                    </div>
                                    <div class="space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">{t("Unavailable")}</lable>
                                        <input className="input input--switch " type="checkbox"/>
                                    </div>
                                    <div class=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">{t("PurchaseAlert")}</lable>
                                        <input className="input input--switch " type="checkbox"/>
                                    </div>
                                    <div class=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">{t("BestsellingProducts")}</lable>
                                        <input className="input input--switch " type="checkbox"/>
                                    </div>
                                    <div class=" space-y-2 flex flex-col items-center ">
                                        <lable className="text-xs dark:text-gray-300">{t("Draft")}</lable>
                                        <input className="input input--switch " type="checkbox"/>
                                    </div>
                                </>
                            }
                />

            </div>
            <div className={"space-y-2 H-body-ag-grid "}>
                <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                           enableRtl={true}
                           pagination={true}
                           getRowHeight={getRowHeight}
                           onGridReady={onGridReady}
                           quickFilterText={quickFilterText}
                />
            </div>
            <Export/>
        </div>
        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
            <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
                <ModalButtonOperations
                title={t("SimilarInsertion")}
                icon={"plus-square"}
                />
                <ModalButtonOperations
                    title={t("SizeAndDimensions")}
                    icon={'edit'}
                    onClick={()=>redirectTo(`/Shop/Product/Type`)}
                />
                <ModalButtonOperations
                    title={t("ChartManagement")}
                    icon={'pie-chart'}
                    onClick={()=>redirectTo(`/Shop/Product/List/Chart`)}
                />

            </div>
        </FantasyModal>
        <FantasyModal openModal={modal.ChangePrice}  closeModal={ChangePriceOnHide} title={t("groupPriceChange")}>
            <div className={"space-y-5"}>
                <div class="grid grid-cols-2 gap-3 ">
                    <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("ProductGroup")}</label>
                            <Select
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                                placeholder={t("selection")}

                            />
                            {/*<select className="select2 w-full">*/}
                            {/*    <option value="-1" selected>...</option>*/}
                            {/*    <option value="1">1000 شانه</option>*/}
                            {/*    <option value="2">700 شانه</option>*/}
                            {/*</select>*/}
                        </div>
                    <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("operations")}:</label>
                            <Select

                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                                placeholder={t("selection")}

                            />
                            {/*<select className="select2 w-full">*/}
                            {/*    <option value="1" selected>...</option>*/}
                            {/*    <option value="2">افزایش قیمت</option>*/}
                            {/*    <option value="3">کاهش قیمت</option>*/}
                            {/*    <option value="4">افزایش درصدی</option>*/}
                            {/*    <option value="4">کاهش درصدی</option>*/}
                            {/*</select>*/}
                        </div>
                    <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Price")}</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                    <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("DiscountAmount")}</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                    <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("DiscountType")}</label>
                            <Select
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                                placeholder={t("selection")}


                            />
                            {/*<div>*/}
                            {/*    <select multiple="" data-placeholder="Select your favorite actors" className="select2 w-full">*/}
                            {/*        <option value="1">درصد</option>*/}
                            {/*        <option value="1">مبلغ ثابت</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                        </div>
                </div>
                <div>
                    <button className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                </div>
            </div>
        </FantasyModal>

       </>)
}