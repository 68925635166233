import React, {useState} from "react";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import TextArea from "../../Commans/UIParts/TextArea";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {Accordion, AccordionTab} from "primereact/accordion";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {SocialMediaPlatformAccount} from "../SocialAccounts/SocialMediaPlatformAccount";
import {InputText} from "primereact/inputtext";

import Select from "react-select";
import TelegramIcon from "../Messengers/SocialIcon/TelegramIcon";
import FacebookIcon from "../Messengers/SocialIcon/FacebookIcon";
import LinkedInIcon from "../Messengers/SocialIcon/LinkedInIcon";
import TwitterIcon from "../Messengers/SocialIcon/TwitterIcon";
import YouTubeIcon from "../Messengers/SocialIcon/YouTubeIcon";
import WhatsAppIcon from "../Messengers/SocialIcon/WhatsAppIcon";
import {HandleTelegramQuickPurchase} from "../Messengers/HandleTelegramQuickPurchase";
import {HandleInstagramQuickPurchase} from "../Messengers/HandleInstagramQuickPurchase";
import {Tooltip} from "react-tooltip";

export const HandleAccountPage = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();





    return (
        <>
            <div className="space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Connect")}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/List/ConnectAccounts")}
                                            tooltipTitle={t("ConnectAccounts")}
                                        />

                                        <ButtonPlus
                                            title={t("Accounts")}
                                            icon={"pi-link"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/List")}
                                            tooltipTitle={t("Accounts")}
                                        />

                                        <ButtonPlus
                                            title={t("list")}
                                            icon={"pi-list"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublicationList")}
                                            tooltipTitle={t("PublicationList")}

                                        />
                                        <ButtonPlus
                                            title={t("Publishing")}
                                            icon={"pi-share-alt"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublishingContent")}
                                            tooltipTitle={t("PublishingContent")}

                                        />
                                        <ButtonPlus
                                            title={t("comments")}
                                            icon={"pi-comments"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/Comments")}
                                            tooltipTitle={t("comments")}
                                            notification={true}
                                        />

                                        <ButtonPlus
                                            title={t("Draft")}
                                            icon={"pi-file-edit"}
                                            onClick={()=>redirectTo("/SocialMarketing/Draft")}
                                            tooltipTitle={t("Draft")}
                                        />

                                        <ButtonPlus
                                            title={t("Draft")}
                                            icon={"pi-file-edit"}
                                            onClick={()=>redirectTo("/SocialMarketing/Draft")}
                                            tooltipTitle={t("Draft")}
                                        />

                                    </>
                                }

                    />

                </div>

                <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 box bg-white dark:bg-themeDarkSalt700 p-3">
                    <div className={"flex flex-col justify-between post-input dark:bg-themeDarkPostInput messenger_accounts overflow-hidden post-input-design"}>
                        <div className=" flex flex-col items-center gap-5 px-8 py-8">
                            <div class="flex items-center">
                                <div className={"size-20 flex-shrink-0 rounded-full border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine overflow-hidden"}>
                                    <img className="size-full object-cover" alt="harmony" src="/Assets/Image/Avatar/man.svg"/>
                                </div>
                            </div>
                            <div className={"space-y-1 text-center"}>
                                <h2 class="dark:text-themeDarkInformation line-clamp-1">Harmony ErpNews</h2>
                                <p class=" text-gray-500 dark:text-gray-300 font-light line-clamp-1">توضیحات</p>
                            </div>
                        </div>
                        <div class="w-full p-1">
                            <button className={"w-full button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded "}>
                                {t("selection")}
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </>
    );
};
