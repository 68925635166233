
export const handleCreateSiteAdminToMember = (obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            if (Array.isArray(value) && value.length > 0) {
                value.forEach((item, index) => {
                    formData.append(`${key}[${index}]`, item);
                });
            } else {
                formData.append(key, value);
            }
        }
    }
    appendIfNotNull('Topic', obj.topic);
    appendIfNotNull('Type', obj.type !== null ? obj.type.value : obj.type);
    appendIfNotNull('Message', obj.message);
    appendIfNotNull('MemberId', obj.memberId !== null ? obj.memberId.value : obj.memberId);
    appendIfNotNull('DepartmentId', obj.departmentId !== null ? obj.departmentId : obj.departmentId);
    appendIfNotNull('Files', obj.files);
    return formData
}
export const handleCreateSuperAdminToSiteAdmin = (obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            if (Array.isArray(value) && value.length > 0) {
                value.forEach((item, index) => {
                    formData.append(`${key}[${index}]`, item);
                });
            } else {
                formData.append(key, value);
            }
        }
    }
    appendIfNotNull('Topic', obj.topic);
    appendIfNotNull('Type', obj.type !== null ? obj.type.value : obj.type);
    appendIfNotNull('Message', obj.message);
    appendIfNotNull('SiteAdminId', obj.siteAdminId !== null ? obj.siteAdminId.value : obj.siteAdminId);
    appendIfNotNull('DepartmentId', obj.departmentId !== null ? obj.departmentId : obj.departmentId);
    appendIfNotNull('Files', obj.files);
    return formData
}


export const handleCreateSiteAdminToSuperAdmin = (obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            if (Array.isArray(value) && value.length > 0) {
                value.forEach((item, index) => {
                    formData.append(`${key}`, item);
                });
            } else {
                formData.append(key, value);
            }
        }
    }
    appendIfNotNull('Topic', obj.topic);
    appendIfNotNull('Type', obj.type !== null ? obj.type.value : obj.type);
    appendIfNotNull('Message', obj.message);
    appendIfNotNull('DepartmentId', obj.departmentId !== null ? obj.departmentId : obj.departmentId);
    appendIfNotNull('Files', obj.files);
    return formData
}

export const handleCreateMembershipToSiteAdmin = (obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            if (Array.isArray(value) && value.length > 0) {
                value.forEach((item, index) => {
                    formData.append(`${key}[${index}]`, item);
                });
            } else {
                formData.append(key, value);
            }
        }
    }
    appendIfNotNull('Topic', obj.topic);
    appendIfNotNull('Type', obj.type !== null ? obj.type.value : obj.type);
    appendIfNotNull('Message', obj.message);
    appendIfNotNull('DepartmentId', obj.departmentId !== null ? obj.departmentId : obj.departmentId);
    appendIfNotNull('Files', obj.files);
    return formData
}


export const handleCreateReplyMessage = (obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            if (Array.isArray(value) && value.length > 0) {
                value.forEach((item, index) => {
                    formData.append(`${key}`, item);
                });
            } else {
                formData.append(key, value);
            }
        }
    }
    appendIfNotNull('TicketId', obj.ticketId);
    appendIfNotNull('ConcurrencyStamp', obj.concurrencyStamp !== null ? obj.concurrencyStamp : obj.concurrencyStamp);
    appendIfNotNull('Message', obj.message);
    appendIfNotNull('ParentId', obj.parentId !== null ? obj.parentId : obj.parentId);
    appendIfNotNull('Files', obj.files);
    return formData
}

