import React from "react";
import { AgGridReact } from 'ag-grid-react';
import {AgGridPersion} from "../../../Services/Globals/Translations/Resources";
// the AG Grid React Component
// import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import '../../../Styles/dist/css/AgGrid/Custome_AgGrid.css';
// import '../../../Styles/dist/css/AgGrid/Ag-grid.css';
// import '../../../Styles/dist/css/AgGrid/ag-theme-alpine.css';

import {GridSetting} from "../../../Services/Globals/Public/AppSetting";


export const GridTable=(props)=>{

    const{columnDefs,onCellClicked,quickFilterText,cacheBlockSize,paginationPageSize,rowModelType,gridRef,onGridReady,getRowHeight,enableRtl,pagination,rowData,defaultColDef,onCellValueChanged,rowSelection,autoGroupColumnDef,onSelectionChanged} = props;

    var French = {
        // French translations for AgGrid context menu
        copy: 'Copier',
        copyAsCsv: "Copier sous forme de CSV",
        copyWithHeaders: "Copier avec les en-têtes",
        paste: "Coller",
    }

    // const getRowHeight = (params) => {
    //     // Specify your custom logic to determine the row height based on row data or other conditions
    //     return 45; // Return the desired row height in pixels
    // };
    return (

        <div className="ag-theme-alpine" >
            <AgGridReact columnDefs={columnDefs}  rowData={rowData} animateRows={true} ref={gridRef} rowSelection={rowSelection} style={{ width: '100%', height: '100%' }}

                // onRowClicked={(row)=>console.log(row.data)}
                // onCellClicked={(row)=>console.log(row.event)}
                // enableRangeSelection={true}
                // enableRangeHandle={true}
                         onCellValueChanged={(e)=>onCellValueChanged(e)}
                         allowDragFromColumnsToolPanel={true}
                         defaultColDef={defaultColDef}
                         autoGroupColumnDef={autoGroupColumnDef}
                         pagination={pagination}
                         onSelectionChanged={onSelectionChanged}
                         enableRtl={enableRtl}
                         domLayout={'autoHeight'}
                         rowModelType={rowModelType}
                         paginationPageSize={paginationPageSize}
                         cacheBlockSize={cacheBlockSize}
                         getRowHeight={getRowHeight}
                         onCellClicked={onCellClicked}
                         onGridReady={onGridReady}
                         quickFilterText={quickFilterText}
                         localeText={GridSetting.localeText}

            />
        </div>


    )
}