import React, {useState} from "react";
import {GetInitialProperties} from "./paymentGatewayService";
export const useCustomPaymentGateway=(setErrors,setFields,setProperties)=>{
    const [gateway,setGateway] = useState({pspAccountId:null,pspId:null,name:null,accountNumber:null,isActive:false,cardNumber:null,shebaNumber:null,order:0,properties:{},bankAccountId:null});
    const handleChangeToggle=(name,value)=>{
        setGateway({...gateway,[name]:value});
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setGateway({...gateway,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setGateway({...gateway,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue=(data,name)=>{
        setGateway({...gateway,[name]:data});
        if(name === 'pspId'){
            const properties= GetInitialProperties(data.fields);
            setFields(properties);
            setProperties([])
        }
    }


    return {
        handleValue,
        handleNumberValue,
        handleSelectValue,
        gateway,
        setGateway,
        handleChangeToggle,
    }

}