import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {DELETE, GET} from "../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../Services/Globals/Public/AppSetting";

import {Tooltip} from "react-tooltip";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";

export const useMailAccountGrid=(showSpinner, hideSpinner, setIsOpen, setIsEdit, refresh, Refresh,initialSource, setMailAccount, mailAccount)=>{
    const { t } = useTranslation();
    const [rowData, setRowData] = useState([]);
    const redirectTo = useNavigate();
    const handleGetMailAccount= async(mailAccountId)=>{
        try {
            showSpinner();
            setIsEdit(true)
            const response = await GET(`/MailAccounts/${mailAccountId}`,{},'single');
            if(response.status === 200){
                setMailAccount({
                    mailAccountId:response.data.mailAccountId,
                    email:response.data.email,
                    password:response.data.password,
                    incomingServer:response.data.incomingServer,
                    outgoingServer:response.data.outgoingServer,
                    incomingPort:response.data.incomingPort,
                    outgoingPort:response.data.outgoingPort,
                    isDefault:response.data.isDefault,
                    incomingEncryptionMethod:initialSource.encryptionMethods.find((item)=>item.value === response.data.incomingEncryptionMethod)??null,
                    outgoingEncryptionMethod:initialSource.encryptionMethods.find((item)=>item.value=== response.data.outgoingEncryptionMethod)??null,
                    connectionType:initialSource.connectionTypes.find((item)=>item.value === response.data.connectionType)??null,
                })
                hideSpinner();
                setIsOpen(true);
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner()
        }
    }

    const handleDeleteMailAccount = async (mailAccountId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/MailAccounts/${mailAccountId}`,);
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const handleGetConnectonType=(connectionType)=>{
        const connection = initialSource.connectionTypes.find((item)=>item.value === connectionType);
        if(!connection){
            return;
        }
        return connection.label;
    }

    const handleGetEncryption=(method)=>{
        const encryption = initialSource.encryptionMethods.find((item)=>item.value === method);
        if(!encryption){
            return;
        }
        return encryption.label;
    }





    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110,hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: t("Connect"),minWidth: 170,
            cellRenderer:parameters=>
                <>
                    {handleGetConnectonType(parameters.data.connectionType)}
                </>
        },
        { headerName: t("ReceiveEncryption"),minWidth: 170,
            cellRenderer:parameters=>
                <>
                    {handleGetEncryption(parameters.data.incomingEncryptionMethod)}
                </>
        },
        { headerName: t("SendEncryption"),minWidth: 170,
            cellRenderer:parameters=>
                <>
                    {handleGetEncryption(parameters.data.outgoingEncryptionMethod)}
                </>
        },
        { headerName: t("EmailAddress"),minWidth: 200,

            cellRenderer:parameters=>

                <>
                    {parameters.data.email}
                </>

        },
        { headerName: t("IncomingServer"),minWidth: 120,
            cellRenderer:parameters=>
                <>
                    {parameters.data.incomingServer}
                </>

        },
        { headerName: t("OutgoingServer"),minWidth: 120,
            cellRenderer:parameters=>
                <>
                    {parameters.data.outgoingServer}
                </>

        },
        {
            headerName: t("ReceivingPort"), minWidth: 110,
            cellRenderer: parameters =>
                <>
                    {parameters.data.incomingPort}

                </>
        },
        {
            headerName: t("SendPort"), minWidth: 110,
            cellRenderer: parameters =>
                <>
                    {parameters.data.outgoingPort}

                </>
        },
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button className="tooltip_class_ConnectionTest text-themeInformation dark:bg-themeDarkInformation relative" >
                        <HPrimeIcon  icon={'pi-link'} />
                        <Tooltip anchorSelect={".tooltip_class_ConnectionTest"} content={t("ConnectionTest")} positionStrategy={"fixed"}	 />
                    </button>
                    <GridButtonEdit
                        onClick={()=>handleGetMailAccount(params.data.mailAccountId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteMailAccount(params.data.mailAccountId)}
                    />

                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    const [filter,setFilter]=useState({search:'',searchValue:''});
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: prevState.searchValue}));
    }
    const handleResetFilters=()=>{
        setFilter((prevState)=>({...prevState,search: '',searchValue: ''}));
    }
    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }


    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize]);


    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/mailAccounts`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search:filter.search}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const items = Paginate(response.items);
                setRowData(items);
            }
            else {
                handleApiError(response,hideSpinner);
            }
        } catch (error) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };


    return {
        handleGetMailAccount,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteMailAccount,
        handleSearch,
        handleResetFilters,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
        filter,
        setFilter,
    }




}