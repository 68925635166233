export const handleCreatePayment=(payment)=>{
    const data = {
        paymentId:payment.paymentId,
        pspAccountId:payment.pspAccountId === null || payment.pspAccountId === undefined?null:payment.pspAccountId.value,
        pspMoney:payment.amount,
        walletMoney:payment.walletAmount
    }
    return data;
}

export const handleCreateManualPayment=(payment)=>{
    const data = {
        paymentId:payment.paymentId,
        bankAccountId:payment.bankAccountId === null || payment.bankAccountId === undefined?null:payment.bankAccountId.value,
        pspMoney:payment.amount === null || payment.amount === undefined?0:payment.amount,
        walletMoney:payment.walletAmount === null || payment.walletAmount === undefined?0:payment.walletAmount,
        transactionCode:payment.recieptCode === null || payment.recieptCode === undefined?"0":payment.recieptCode.toString(),
        transactionDate:payment.billDate === null || payment.billDate === undefined?new Date():payment.billDate,
        description:payment.description === null || payment.description === undefined?0:payment.description,
        bankId:payment.bankId === null || payment.bankId === undefined?null:payment.bankId.value,

    }
    return data;
}


export const handleValidateAmount = (amount, walletAmount, totalAmount) => {
    if (walletAmount < 0 || amount < 0) {
        return false;
    }
    return amount + walletAmount === totalAmount;
};
export const handleValidateWallet = (walletBalance,walletAmount) => walletBalance >= walletAmount;
