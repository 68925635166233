import React from "react";
import moment from 'moment-jalaali';
import commaNumber from "comma-number";
//به عنوان پارامتر یک فرمت داخلی میگیره یه فرمت خارجی و تاریخ و زمان فارسی
export const ConvertJaliliDateTimeToGrogia=(dateTime,Informat,Outformat)=>{
    const christianDateTime = moment(dateTime, 'jYYYY/jM/jD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    return christianDateTime;
}
export const handleConvertGregoriToJalili=(dateTime,isContainsTime)=>{
    // moment.locale('fa', { useGregorianParser: true });
    // moment('2018-04-04').format();// it would be in jalali system
    const time = moment(dateTime).format(`${isContainsTime?'jYYYY/jM/jD HH:mm:ss':'jYYYY/jM/jD'}`);// it would be
    return time
}

export const handleConvertPriceToCommaNumbers=(price)=>{
    const formattedPrice = commaNumber(price);
    return formattedPrice;
}
export const DisplayGrogiaToJalili = (dateTime)=>{
    const time = moment(dateTime).format('jYYYY/jM/jD HH:mm:ss');// it would be
    return time
}
export const GetCurrentDateTime=(isCurrentDateTime,yearCount)=>{
    if(isCurrentDateTime)
        return new Date();
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + yearCount).toString();
    return expirationDate;
}
