import React, {useEffect, useMemo, useState} from "react";
import { InputText } from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {useDataHighliter} from "./useDataHighliter";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import Select from "react-select";
import {validate} from "../../../Services/Globals/validate";
import {dataHighlightSchema} from "./dataHighlightSchema";
import {handleCreateDataHighLight} from "./dataHighLightService";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {useDataHighliterGrid} from "./useDataHighliterGrid";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {ToastContainer} from "react-toastify";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";

export const HandleMetaTagExtraSetting = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const [errors,setErrors]=useState({});
    const [IsOpen,setIsOpen]=useState(false);
    const [IsEdit,setIsEdit]=useState(false);
    const [IsView,setIsView]=useState(false);
    const [IsOpenParameters, setOpenParameters] = useState(false);

    const openParameters=()=>{
        setOpenParameters(true)
    }

    const closeParameters=()=>{
        setOpenParameters(false)
    }


    const[initialSource,setInitialSource]=useState({
        metaTypes:[]
    });
    const {handleNumberValue, handleSelectValue,setting,setSetting, handleValue,}=useDataHighliter(setErrors);
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleOpenView=(dataMessage)=>{
        setSetting((prevState)=>({...prevState,data: dataMessage}))
        setIsView(true)
    }
    const handleCloseView=()=>{
        setIsView(false);
    }
    const handleIsOpen=()=>{
        setSetting((prevState)=>({...prevState,type: initialSource.metaTypes[0],isActive: true}))
        setIsOpen(true)
    }
    const handleCloseIsOpen=()=>{
        setSetting((prevState)=>({...prevState,type: initialSource.metaTypes[0],isActive: true,title: '',data: '',targetPage: ''}))
        setErrors({});
        setIsEdit(false)
        setIsOpen(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }
    const handleInitialDataSource=(responseMetaTypes)=>{
        const metaTypesData = convertSelectDataHandler(responseMetaTypes);
        setInitialSource({
            metaTypes: metaTypesData,
        })
        return {
            metaTypesData,
        }
    }
    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Enumerations/EnMetaType`,{},'IEnumerable');
            if(response.status === 200){
                handleInitialDataSource(response.data);
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }
    useEffect(()=>{
        fetchData();
    },[])


const handleSubmit=async (e)=>{
    e.preventDefault();
    const isValid = validate(dataHighlightSchema,setting,onSetErrors)
    if(!isValid){
        return;
    }
    try {
        showSpinner();
        if(IsEdit){
            const data = handleCreateDataHighLight(setting,IsEdit);
            const response = await PUT(`/Settings/MetaTag`,data,false);
            if(response.status === 200){
                hideSpinner();
                handleCloseIsOpen()
                Refresh()
                handleSuccessMessage(t('SuccessDone'));
            }else{
                handleApiError(response,hideSpinner);
            }
        }
        else{
            const data = handleCreateDataHighLight(setting,IsEdit);
            const response = await POST(`/Settings/MetaTagActivation`,data,false);
            if(response.status === 200){
                hideSpinner();
                handleCloseIsOpen()
                Refresh()
                handleSuccessMessage(t('SuccessDone'));
            }else{
                handleApiError(response,hideSpinner);
            }
        }
    }catch(e) {
        hideSpinner();
    }
}
const {handleDeleteMetaTag,handleGetMetaTag,filter,handleSearch,search,setFilter,setSearch,handleNextPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount,handleResetFilters,handleToggleFilter,SwitchFilters,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination}=useDataHighliterGrid(showSpinner,hideSpinner,setIsOpen,setIsEdit,initialSource,setting,setSetting,Refresh,refresh,handleOpenView);




    return (
        <>
            <div className="space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                                <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                            </div>
                            <ButtonSearchList
                                onClickSearch={()=>handleSearch()}
                                onClickReset={()=>handleResetFilters()}
                            />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleIsOpen()}
                                            tooltipTitle={t("DataHighlight")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SEO/Redirect/Add`)}
                                            icon={"pi-link"}
                                            title={t("redirect")}
                                            tooltipTitle={t("redirect")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SEO/KeyWords`)}
                                            icon={"pi-book"}
                                            title={t("Keywords")}
                                            tooltipTitle={t("Keywords")}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                                    </>
                                }
                    />

                </div>
                <div className={"space-y-2 H-body-ag-grid "} >

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export apiUrl={''} filters={filter}/>
            </div>

            <FantasyModal openModal={IsOpen} closeModal={handleCloseIsOpen} title={t("DataHighlight")}>
               <form onSubmit={handleSubmit} method={'post'}>
                   <div className={"space-y-5"}>

                       <div class="space-y-3">
                           <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                               <div class=" space-y-2">
                                   <div className={"flex justify-between items-center gap-1"}>
                                       <label className="text-xs dark:text-gray-300">
                                           {t("Title")}
                                           <RequiredIcon/>
                                       </label>
                                       <DisplayError message={errors.title}/>
                                   </div>
                                   <InputText name={'title'} onInput={(e)=>handleValue(e)} value={setting.title} placeholder={t("selection")} className="w-full input" />
                               </div>
                               <div class="space-y-1">
                                   <label className="text-xs dark:text-gray-300">{t("DataType")}</label>
                                   <Select
                                       options={initialSource.metaTypes}
                                       placeholder={t("selection")}
                                       className={"w-full "}
                                       classNamePrefix={"h_multi_select"}
                                       value={setting.type}
                                       onChange={(data)=>handleSelectValue(data,'type')}
                                   />
                               </div>
                               <div class=" space-y-1 md:col-span-2">
                                   <label className="text-xs dark:text-gray-300">{t("LandingPage")}</label>
                                   <Select
                                       placeholder={t("selection")}
                                       className={"w-full "}
                                       classNamePrefix={"h_multi_select"}
                                   />
                                   {/*<InputText name={'targetPage'} onInput={(e)=>handleValue(e)} value={setting.targetPage}  className="w-full input" />*/}
                               </div>
                               <div class="space-y-1 md:col-span-2">
                                   <div className={"flex gap-2 items-center justify-between"}>
                                       <div className={"flex justify-between items-center gap-1"}>
                                           <label className="text-xs dark:text-gray-300">
                                               {t("CodeText")}
                                               <RequiredIcon/>
                                           </label>
                                           <DisplayError message={errors.data}/>
                                       </div>
                                       <div>
                                           <button type={"button"} className="text-themeInformation text-xs dark:text-themeDarkInformation cursor-pointer" onClick={() => openParameters()}>({t("Parameters")})</button>
                                       </div>
                                   </div>
                                   <InputTextarea
                                       onInput={(e)=>handleValue(e)}
                                       name={'data'}
                                       value={setting.data}
                                       rows={3}
                                       className={"input"}
                                   />
                               </div>
                           </div>
                           <div class="space-y-1 flex justify-items-start">
                               <InputSwitch value={setting.isActive} OnValueSelection={handleSelectValue}  name={'isActive'} lable={t("Status")} />
                           </div>
                       </div>
                       <div>
                           <button className="button bg-themeInformation dark:bg-themeDarkInformation w-full text-white ">{t("register")}</button>
                       </div>
                   </div>
               </form>
            </FantasyModal>

            <FantasyModal openModal={IsView} closeModal={handleCloseView} title={t("DataHighlight")}>
                <div class="space-y-2">
                    <div className="flex gap-2 items-center">
                        <div><h2>{t("Title")}</h2></div>
                        <div>
                            <h2 className="text-themeInformation dark:text-themeDarkInformation">صفحه اصلی</h2>
                        </div>
                    </div>
                    <div className="">
                        <div >
                            <InputTextarea value={setting.data} disabled={true} name={"excerpt"} rows={15} cols={30} className={"input w-full"} />
                        </div>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenParameters} closeModal={closeParameters} title={t("Parameters")} customeClassName={"modal-sm"}>
                <div className={"space-y-5"}>
                    <div className="grid grid-cols-2 border dark:border-themeDarkColorLine bg-white dark:bg-themeDarkSalt700 rounded-md">
                        <div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-center">عنوان</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">عنوان</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">عنوان سئو</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">توضیحات</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">توضیحات سئو</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">لینک مطلب</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">قیمت</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">تاریخ انتشار</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">ویرایش</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">تصویر</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">نویسنده</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">رتبه</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">گروه بندی</h2>
                            </div>
                            <div className="border-e border-b dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">نوع محتوا</h2>
                            </div>
                            <div className="border-e dark:border-themeDarkColorLine p-2">
                                <h2 className="dark:text-gray-300 text-xs">شناسه ISBN</h2>
                            </div>

                        </div>
                        <div className={"H-direction-ltr"}>
                            <div className="p-2 border-b dark:border-themeDarkColorLine ">
                                <h2  className="dark:text-gray-300 text-center" >کد متناظر</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[title]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[titleseo]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[description]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[descriptionseo]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[link]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[price]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[publisheddate]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[datemodified]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[image]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[author]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[rate]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[Category]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[contenttype]</h2>
                            </div>
                            <div className="p-2 border-b dark:border-themeDarkColorLine">
                                <h2  className="dark:text-gray-300 text-xs">[Identifiers]</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </FantasyModal>
            <ToastContainer/>

        </>

    );
};