import React, {useEffect, useState} from "react";

import {ListFilter} from "../Commans/Grid/ListFilter";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {Accordion, AccordionTab} from "primereact/accordion";
import {DateTimePicker} from "../Commans/Dates/DateTimePicker";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Chart} from "primereact/chart";
import {useStatisticsChart} from "./useStatisticsChart";
import {useStatistics} from "./useStatistics";
import {GET} from "../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../Services/Globals/Errors/handleApiError";
import {TopVisitedPage} from "./topVisitedPage";
import {TopReferrerUrls} from "./topReferrerUrls";
import {BrowserStatistics} from "./BrowserStatistics";
import {CountryStatistics} from "./CountryStatistics";
import {CityStatistics} from "./CityStatistics";
import {PlatformStatistics} from "./PlatformStatistics";
import {LoadingHarmony} from "../Commans/Loadings/LoadingHarmony";
import {GeneralStatistics} from "./GeneralStatistics";
import {HPrimeIconSmall} from "../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";


export const HandleStatistics = () => {
    const {chartOptions,chartData,setChartData,setChartOptions,handleGetChartData}=useStatisticsChart();
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const Refresh=()=>{
        setRefresh(prevState => !prevState)
    }
    const {handleResetDates,handleToDate,handleFromDate,report,setStatistics,statistics}=useStatistics(Refresh);
    const { t } = useTranslation();
    const redirectTo = useNavigate();


    const [activeIndices, setActiveIndices] = useState({ one: 0, two: 0, three: 0 });

    const handleTabChange = (name, index) => {
        setActiveIndices(prevState => ({ ...prevState, [name]: index }));
    };


    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Seo/ViewStatistics`,{FromDate:report.fromDate,ToDate:report.toDate},'single');
            if(response.status === 200){
                const {dailyVisitors,weeklyVisitors,monthlyVisitors,annualVisitors,topVisitedPages,topReferrerUrls,browserStatistics,countryStatistics,platformStatistics,cityStatistics,dailyVisitorsStats,uniqueVisitorsInRange,totalPageViews,numberOfDaysInRange,averagePageViews}=response.data;
                setStatistics({
                    dailyVisitors: dailyVisitors,
                    weeklyVisitors: weeklyVisitors,
                    monthlyVisitors: monthlyVisitors,
                    annualVisitors: annualVisitors,
                    topVisitedPages: topVisitedPages,
                    topReferrerUrls: topReferrerUrls,
                    browserStatistics: browserStatistics,
                    countryStatistics: countryStatistics,
                    platformStatistics: platformStatistics,
                    cityStatistics: cityStatistics,
                    dailyVisitorsStats: dailyVisitorsStats,
                    uniqueVisitorsInRange: uniqueVisitorsInRange,
                    totalPageViews: totalPageViews,
                    numberOfDaysInRange: numberOfDaysInRange,
                    averagePageViews: averagePageViews
                });
                handleGetChartData(dailyVisitorsStats);

                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }
    useEffect(()=>{
        fetchData()
    },[refresh])






  return(
      <div class="space-y-5 relative">
          {

              IsShowSpinner?
                  <div className="huploader_loading" >
                      <div className="relative">
                          <LoadingHarmony/>
                      </div>
                  </div>
                  :
                  null
          }
          <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
              <ListFilter label={null} icon={null}
                          showChildrenTwo={false}
                          childrenOne={
                              <>

                              </>
                          }

              />

          </div>
          <div className={"bg-white p-3 box space-y-5 dark:bg-themeDarkSalt700"}>
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                  <Accordion activeIndex={activeIndices.one} onTabChange={(e) => handleTabChange('one',e.index)}>
                      <AccordionTab className={"post-input dark:bg-themeDarkSalt700"}
                                    header={
                                        <div class="flex gap-2 items-center  text-xs" onClick={() => handleTabChange('one', 0)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("YesterdayViews")}
                                        </div>
                                    }

                      >
                          <div class="space-y-3">
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("NumberVisitors")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">{statistics.dailyVisitors.uniqueVisitors}</div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("PagesVisited")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.dailyVisitors.uniquePages}
                                  </div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("AverageVisit")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.dailyVisitors.averageViews}
                                  </div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("TotalViews")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.dailyVisitors.totalViews}
                                  </div>
                              </div>
                          </div>
                      </AccordionTab>

                  </Accordion>
                  <Accordion activeIndex={activeIndices.one} onTabChange={(e) => handleTabChange('one',e.index)}>
                      <AccordionTab className={"bg-white dark:bg-themeDarkSalt700"}
                                    header={
                                        <div class="flex gap-2 items-center text-xs" onClick={() => handleTabChange('one', 0)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("LastWeek")}
                                        </div>
                                    }

                      >
                          <div class="space-y-3">
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("NumberVisitors")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">{statistics.weeklyVisitors.uniqueVisitors}</div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("PagesVisited")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.weeklyVisitors.uniquePages}
                                  </div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("AverageVisit")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.weeklyVisitors.averageViews}
                                  </div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("TotalViews")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.weeklyVisitors.totalViews}
                                  </div>
                              </div>
                          </div>
                      </AccordionTab>
                  </Accordion>
                  <Accordion activeIndex={activeIndices.one} onTabChange={(e) => handleTabChange('one',e.index)}>
                      <AccordionTab className={"post-input dark:bg-themeDarkSalt700"}
                                    header={
                                        <div class="flex gap-2 items-center text-xs" onClick={() => handleTabChange('one', 0)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("LastMonth")}
                                        </div>
                                    }

                      >
                          <div class="space-y-3">
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("NumberVisitors")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">{statistics.monthlyVisitors.uniqueVisitors}</div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("PagesVisited")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.monthlyVisitors.uniquePages}
                                  </div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("AverageVisit")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.monthlyVisitors.averageViews}
                                  </div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("TotalViews")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.monthlyVisitors.totalViews}
                                  </div>
                              </div>
                          </div>
                      </AccordionTab>
                  </Accordion>
                  <Accordion activeIndex={activeIndices.one} onTabChange={(e) => handleTabChange('one',e.index)}>
                      <AccordionTab className={"bg-white dark:bg-themeDarkSalt700"}
                                    header={
                                        <div class="flex gap-2 items-center text-xs" onClick={() => handleTabChange('one', 0)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("LastYear")}
                                        </div>
                                    }
                      >
                          <div class="space-y-3">
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("NumberVisitors")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">{statistics.annualVisitors.uniqueVisitors}</div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("PagesVisited")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">

                                      {statistics.annualVisitors.uniquePages}
                                  </div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("AverageVisit")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.annualVisitors.averageViews}
                                  </div>
                              </div>
                              <div class="flex items-center">
                                  <h2 className="text-xs dark:text-gray-300">{t("TotalViews")}</h2>
                                  <div class="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                                  <div class="p-1 min-w-8 text-xs rounded text-white bg-themeInformation dark:bg-themeDarkInformation text-center">
                                      {statistics.annualVisitors.totalViews}
                                  </div>
                              </div>
                          </div>
                      </AccordionTab>
                  </Accordion>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                  <Accordion activeIndex={activeIndices.two} onTabChange={(e) => handleTabChange('two',e.index)} className={"statistics_height"}>
                      <AccordionTab className={"box bg-white dark:bg-themeDarkSalt700 "}
                                    header={
                                        <div className=" flex items-center gap-2" onClick={() => handleTabChange('one', 1)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("MostVisitedPages")}
                                        </div>
                                    }
                      >
                          <div className="space-y-3">
                              {
                                  statistics.topVisitedPages && statistics.topVisitedPages.length > 0 ? (
                                      statistics.topVisitedPages.map((item,index) => (
                                          <TopVisitedPage key={index} pageUrl={item.pageUrl} pageViews={item.pageViews} />
                                      ))
                                  ) : (
                                      ''
                                  )
                              }
                          </div>
                      </AccordionTab>
                  </Accordion>
                  <Accordion activeIndex={activeIndices.two} onTabChange={(e) => handleTabChange('two',e.index)} className={"statistics_height"}>
                      <AccordionTab className={"post-input dark:bg-themeDarkSalt700"}
                                    header={
                                        <div className=" flex items-center gap-2" onClick={() => handleTabChange('one', 1)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("SelectedReferrers")}
                                        </div>
                                    }
                      >
                          <div className="space-y-3">
                              {
                                  statistics.topReferrerUrls && statistics.topReferrerUrls.length > 0 ? (
                                      statistics.topReferrerUrls.map((item,index) => (
                                          <TopReferrerUrls key={index} referrerUrl={item.referrerUrl} referrals={item.referrals} />
                                      ))
                                  ) : (
                                      ''
                                  )
                              }
                          </div>
                      </AccordionTab>
                  </Accordion>
                  <Accordion activeIndex={activeIndices.two} onTabChange={(e) => handleTabChange('two',e.index)} className={"statistics_height"}>
                      <AccordionTab className={"box bg-white dark:bg-themeDarkSalt700"}
                                    header={
                                        <div className=" flex items-center gap-2" onClick={() => handleTabChange('one', 1)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("BrowserStatistics")}
                                        </div>
                                    }
                      >

                          <div className="space-y-3">
                              {
                                  statistics.browserStatistics && statistics.browserStatistics.length > 0 ? (
                                      statistics.browserStatistics.map((item,index) => (
                                          <BrowserStatistics key={index} browser={item.browser} visits={item.visits} />
                                      ))
                                  ) : (
                                      ''
                                  )
                              }
                          </div>

                      </AccordionTab>
                  </Accordion>
                  <Accordion activeIndex={activeIndices.two} onTabChange={(e) => handleTabChange('two',e.index)} className={"statistics_height"}>
                      <AccordionTab className={"post-input dark:bg-themeDarkSalt700"}
                                    header={
                                        <div className=" flex items-center gap-2" onClick={() => handleTabChange('one', 1)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("StatisticsCountries")}
                                        </div>
                                    }
                      >
                          <div className="space-y-3">
                              {
                                  statistics.countryStatistics && statistics.countryStatistics.length > 0 ? (
                                      statistics.countryStatistics.map((item,index) => (
                                          <CountryStatistics key={index} country={item.country} visits={item.visits} />
                                      ))
                                  ) : (
                                      ''
                                  )
                              }
                          </div>
                      </AccordionTab>
                  </Accordion>
                  <Accordion activeIndex={activeIndices.three} onTabChange={(e) => handleTabChange('three',e.index)} className={"statistics_height"}>
                      <AccordionTab className={"box bg-white dark:bg-themeDarkSalt700"}
                                    header={
                                        <div className=" flex items-center gap-2" onClick={() => handleTabChange('one', 2)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("StatisticsCities")}
                                        </div>
                                    }
                      >
                          <div className="space-y-3">
                              {
                                  statistics.cityStatistics && statistics.cityStatistics.length > 0 ? (
                                      statistics.cityStatistics.map((item,index) => (
                                          <CityStatistics key={index} city={item.city} visits={item.visits} />
                                      ))
                                  ) : (
                                      ''
                                  )
                              }

                          </div>
                      </AccordionTab>
                  </Accordion>
                  <Accordion activeIndex={activeIndices.three} onTabChange={(e) => handleTabChange('three',e.index)} className={"statistics_height"}>
                      <AccordionTab className={"post-input dark:bg-themeDarkSalt700"}
                                    header={
                                        <div className=" flex items-center gap-2" onClick={() => handleTabChange('one', 2)}>
                                            <HPrimeIconSmall icon={'pi-desktop'}/>
                                            {t("Platform")}
                                        </div>
                                    }
                      >
                          <div className="space-y-3">
                              {
                                  statistics.platformStatistics && statistics.platformStatistics.length > 0 ? (
                                      statistics.platformStatistics.map((item,index) => (
                                          <PlatformStatistics key={index} platform={item.platform} visits={item.visits} />
                                      ))
                                  ) : (
                                      ''
                                  )
                              }
                          </div>
                      </AccordionTab>
                  </Accordion>
              </div>
          </div>
          <div className={"bg-white p-3 box space-y-5 dark:bg-themeDarkSalt700"}>
              <div class="p-2 post-input dark:bg-themeDarkPostInput post-input-design grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 items-center">
                  <div className={"flex items-center gap-3"}>
                      <span class="text-sm dark:text-gray-300 text-nowrap">{t("reportFrom")}</span>
                      <DateTimePicker   handleDateTime={handleFromDate} format={'YYYY/MM/DD'} value={report.fromDate}/>
                  </div>
                  <div className={"flex items-center gap-3"}>
                      <div className={"flex items-center gap-3 flex-1"}>
                          <span className={"text-sm dark:text-gray-300"}>{t("To")}</span>
                          <DateTimePicker   handleDateTime={handleToDate} format={'YYYY/MM/DD'} value={report.toDate}/>
                      </div>

                      <div className={"flex items-center gap-2"}>
                          <button onClick={()=>Refresh()} type={"button"} className="button small_button bg-themeInformation dark:bg-themeDarkInformation">
                              <HPrimeIcon icon={'pi-sync'} iconClass={"text-white"} />
                          </button>
                          <button onClick={()=>handleResetDates()} type={"button"} className="button small_button bg-themeInformation dark:bg-themeDarkInformation ">
                              <HPrimeIcon icon={'pi-list'} iconClass={"text-white"} />
                          </button>
                      </div>
                  </div>
              </div>
              <div class="grid grid-cols-2 lg:grid-cols-4 gap-1 ">

                  <GeneralStatistics color={'bg-themeSecondary'} data={statistics.uniqueVisitorsInRange} label={'Visitors'} icon={'pi-user'}/>

                  <GeneralStatistics color={'bg-themeInformation dark:bg-themeDarkInformation'} data={statistics.totalPageViews} label={'PageViews'} icon={'pi-user'} />

                  <GeneralStatistics color={'bg-themeSecondary'} data={statistics.averagePageViews} label={'AverageVisit'} icon={'pi-user'}/>

                  <GeneralStatistics color={'bg-themeInformation dark:bg-themeDarkInformation'} data={statistics.numberOfDaysInRange} label={'NumberDays'} icon={'pi-user'}/>


              </div>
              <div>
                  <Chart type="bar" data={chartData} options={chartOptions} />
              </div>
          </div>

      </div>
  )
}