export function handleEditWebsiteDomains(domains,websiteId,concurrencyStamp){
    const data={
        domains:domains,
        websiteId:websiteId,
        concurrencyStamp:concurrencyStamp
    }
    return data;
}


