import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {TabMenu} from "../../../Commans/UIParts/TabMenu";
import {TagsInput} from "react-tag-input-component";
import Select from "react-select";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {AddFilter} from "../../../Commans/Filters/AddFilter";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {convertSelectDataHandler, convertSlugHandler} from "../../Contents/ContentService";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {useTranslation} from "react-i18next";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {advertiseSchema} from "./advertiseSchema";
import {
    convertAddAdvertise,
    convertAttachmentHandler,
    convertCommunicationHandler,
    convertEditAdvertise,
    handleAddAdvertiseFormData,
    handleEditAdvertiseFormData,
} from "./AdvertiseService";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {AdvertisementFile} from "../../../Commans/Uploaders/AdvertisementFile";
import Swal from "sweetalert2";
import {TinyEditor} from "../../../Commans/UIParts/TinyEditor";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {AttachCard} from "../../../GeneralManagement/Documents/Add/AttachCard";
import {AdvertiseAttach} from "./AdvertiseAttach";
import {useAdvertiseAttach} from "./useAdvertiseAttach";
import {SlugGenerator} from "../../../Commans/Globals/AiFeatures/SlugGenerator";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const HandleAddAdvertise = ()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [oldSlug,setOldSlug] = useState('');
    const [isContentSlugValid, setContentIsSlugValid] = useState(null);
    const[result,setResult] = useState(0);
    const[IsEdit,setIsEdit] = useState(false);
    const [spinner,setSpinner] = useState(false);
    const [modal, setModal] = useState({ HandleAddFilter: false });
    const [options,setOptions] = useState({permissions:[],sendingStates:[],communicationTypes:[],communicationModes:[],advertiseType:[],positions:[],users:[],countries:[],cities:[],provinces:[],provinceValue:null,cityValue:null,countryValue:null,templates:[],filters:[]});
    const [advertisement,setAdvertisement] = useState({Id:null,Title:null,Excerpt:null,Editor:null,SeoTitle:null,SeoDescription:null,Seo_Keyword:[],CoverImage:null,Slug:null,StartDate:null,ExpirationDate:null,CityId:null,SendingStates:[],Attachments:[],Communications:[],StateAndroid:false,StateGoogle:false,StateShow:false,SponsorId:null,TemplateId:null,IframeMap:null,Longitude:null,Latitude:null,FilterId:null,CoverImagePath:null});

    //const [attachment,setAttachment] = useState([{id:new Date().getTime(),caption:null,file:null,position:{label:t("Right"),value:0},width:0,height:0,type:{label:t("Image"),value:0}}]);

    const {addNewCard,deleteCard,handleFieldChange,handleFileChange,handleGetAllCards,setFiles,handleInitialCards,triggerFileInput,totalSize,files} = useAdvertiseAttach(options)



    const [communication,setCommunication] = useState([{id:new Date().getTime(),communicationId:null,mode:null,value:null,description:null}]);

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const [errors, setErrors] = useState({});

    const HandleAddFilterOnHide = () => {setModal((prevState) => ({ ...prevState, HandleAddFilter: !modal.HandleAddFilter }));};
    const [activeTab, setActiveTab] = useState("tab_one");
    const handleTabClick = (tabId) => {setActiveTab(tabId);};
    const tabs = [{id: "tab_one",  label: t("Specifications"), icon: "pi-file-check",}, {id: "tab_two",label: t("AdvertisingFile"), icon: "pi-paperclip",}, {id: "tab_three", label: t("MetaTag"), icon: "pi-tag",}, {id: "tab_four",  label: t("communications"), icon: "pi-phone",},];
    const [geoActiveTab, setGeoActiveTab] = useState("location");
    const handleGeoTabClick = (tabId) => {setGeoActiveTab(tabId);};
    const geoTab = [{id: "location", label: t("GeographicLongitudeandLatitude"), icon: "pi-map",}, {id: "address",  label: t("IFrame"), icon: "pi-code",},];

    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const onApiErrors = ()=>{
        setSpinner(false);
    }


    const handleValue = (e) => {
        const { name, value } = e.target;
        if(advertisement.Id === null){
            if (name === "Title") {
                handleSlugValue(e); // Update slug value when title changes
                setAdvertisement((prevState) => ({ ...prevState, SeoTitle: value }));
            }
            setAdvertisement((prevContent) => ({ ...prevContent, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }else{
            const { name, value } = e.target;
            setAdvertisement((prevContent) => ({ ...prevContent, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }

    };


    const handleSlugValue = (e) => {
        const { value } = e.target;
        const slugValue = convertSlugHandler(value);
        setAdvertisement((prevContent) => ({ ...prevContent, Slug: slugValue }));
    };
    const handleSelectValue=(data,name)=> setAdvertisement({ ...advertisement, [name]: data });
    const handleStartDate=(dateTime)=> setAdvertisement({ ...advertisement,StartDate: dateTime });
    const handleExpireDate=(dateTIme)=> setAdvertisement({ ...advertisement,ExpirationDate: dateTIme });
    const handleKeywordsChange = (keywords) => setAdvertisement((prevContent) => ({ ...prevContent,Seo_Keyword: keywords}));

    const handleCoverImageFile = (file,name)=>{
        setAdvertisement({...advertisement,[name]: file});
    }
    const handleSimpleValue =(data,name)=>{setAdvertisement((prevContent) => ({ ...prevContent, [name]: data }))}
    const InputSwitchValueHandler = (data,name)=>{setAdvertisement((prevState) => ({...prevState, [name]: data,}))}


    const removeFile=(path,name)=>setAdvertisement((prevState)=>({...prevState,[name]:path}))


    const handleFetchData = async()=>{
        try {
            const queryParams = queryString.parse(window.location.search);
            setSpinner(true);
            const responseAdvertiseTypes = await GET('/Enumerations/EnAdvertisingType',{},'IEnumerable');
            const responsePosition = await GET('/Enumerations/EnPosition',{},'IEnumerable');
            const responseSponsor= await GET('/Users/Selection',{},'IEnumerable');
            const responseCountry= await GET('/Countries/Selection',{},'IEnumerable');
            const responsePermission= await GET('/Contents/GetAllEnContentPermission',{},'IEnumerable');
            const responseCommunicationType = await GET('/Communications/Selection',{},'IEnumerable');
            const responseCommunicationMode = await GET('/Enumerations/EnCommunicationMode',{},'IEnumerable');
            const responseSendingState= await GET('/EnSendingStates',{},'IEnumerable');
            const responseTemplate= await GET(`/TemplatePages/selection?Type=${17}&Type=${13}`,{},'IEnumerable');
            const responseFilters = await GET(`/Filters/Selection?Table=${1}`,{},'IEnumerable');

            if(responseAdvertiseTypes.status === 200 && responsePosition.status === 200 && responseSponsor.status === 200 && responseCountry.status === 200 && responsePermission.status === 200 && responseSendingState.status === 200 && responseTemplate.status === 200 && responseCommunicationType.status === 200 && responseCommunicationMode.status === 200 && responseFilters.status === 200){

                const advertisementTypes = convertSelectDataHandler(responseAdvertiseTypes.data);
                const positions = convertSelectDataHandler(responsePosition.data);
                const sponsors = convertSelectDataHandler(responseSponsor.data);
                const countries = convertSelectDataHandler(responseCountry.data);
                const communicationType = convertSelectDataHandler(responseCommunicationType.data);
                const communicationMode = convertSelectDataHandler(responseCommunicationMode.data);
                const sendingStates = convertSelectDataHandler(responseSendingState.data);
                const templates = convertSelectDataHandler(responseTemplate.data);
                const permissions = convertSelectDataHandler(responsePermission.data);
                const filters = convertSelectDataHandler(responseFilters.data);

                setOptions((prevState)=>({...prevState,sendingStates: sendingStates,communicationModes: communicationMode,communicationTypes: communicationType,templates: templates,advertiseType: advertisementTypes,countries: countries,users: sponsors,positions: positions,permissions:permissions,filters: filters}));
                if(files.length === 0){
                    handleInitialCards(advertisementTypes,positions);
                }
                setSpinner(false);
                setCommunication([{ ...communication[0], communicationId: communicationType[0], mode: communicationMode[0] }])

                if(queryParams.id){
                    setIsEdit(true);
                    const response = await GET(`/Advertisings/${queryParams.id}`,{},'single');
                    if(response.status === 200){
                        setOldSlug(response.data.slug);

                        if(response.data.attachments.length === 0){
                            handleInitialCards(advertisementTypes,positions);
                        }
                        else{
                            const fileCards = handleGetAllCards(response.data.attachments,advertisementTypes,positions);
                            setFiles(fileCards);
                        }



                        if(response.data.cityId === null){




                            const filteredCommunications = convertCommunicationHandler(response.data.communications,communicationType,communicationMode);
                            setCommunication(filteredCommunications)


                            setAdvertisement({...advertisement,
                                Id: response.data.id,
                                Title: response.data.title,
                                Slug: response.data.slug,
                                Excerpt: response.data.excerpt,
                                templateId: templates.find((item)=>item.value === response.data.templateId),
                                Editor:response.data.editor,
                                SeoTitle: response.data.seoTitle,
                                SendingStates:  response.data.sendingStates && sendingStates ? response.data.sendingStates.map((value) => sendingStates.find((item) => item.value === value)) : [],
                                Latitude: response.data.latitude,
                                Longitude: response.data.longitude,
                                StateShow: response.data.stateShow,
                                IframeMap: response.data.iframeMap,
                                StateAndroid: response.data.stateAndroid,
                                StateGoogle: response.data.stateGoogle,
                                SeoDescription: response.data.seoDescription,
                                FilterId: permissions.find((item)=>item.value === response.data.filterId),
                                StartDate:response.data.startDate,
                                ExpirationDate: response.data.expirationDate,
                                CoverImage: response.data.coverImage,
                                Seo_Keyword: response.data.seo_Keyword ?? [],
                                Communications:filteredCommunications.length === 0?[]:filteredCommunications,
                                CityId:null,
                                contentAttaches: response.data.contentAttaches,
                                SponsorId:sponsors.find((item)=>item.value === response.data.sponsorId),
                            });

                            if(filteredCommunications.length === 0){
                                setCommunication([{ ...communication[0], communicationId: communicationType[0], mode: communicationMode[0] }])
                            }

                        }
                        else{
                            const filteredCountry = countries.find((item) => item.value === response.data.countryId);

                            const responseProvince = await GET(`/Countries/${response.data.countryId}/StateProvinces/Selection`,{},'IEnumerable');
                            const provinces = convertSelectDataHandler(responseProvince.data);
                            const filteredProvince = provinces.find((item) => item.value === response.data.stateProvinceId);
                            setOptions((prevOptions) => ({...prevOptions, provinceValue: filteredProvince,countryValue: filteredCountry}));
                            const responseCity = await GET(`/Countries/${response.data.stateProvinceId}/Cities/Selection`,{},'IEnumerable');
                            const cities = convertSelectDataHandler(responseCity.data);
                            const filteredCity = cities.find((item) => item.value === response.data.cityId);

                            const filteredCommunications = convertCommunicationHandler(response.data.communications,communicationType,communicationMode);
                            setCommunication(filteredCommunications)
                            setAdvertisement({...advertisement,
                                Id: response.data.id,
                                Title: response.data.title,
                                Slug: response.data.slug,
                                Excerpt: response.data.excerpt,
                                templateId: templates.find((item)=>item.value === response.data.templateId),
                                Editor:response.data.editor,
                                SeoTitle: response.data.seoTitle,
                                SendingStates:  response.data.sendingStates && sendingStates ? response.data.sendingStates.map((value) => sendingStates.find((item) => item.value === value)) : [],
                                Latitude: response.data.latitude,
                                Longitude: response.data.longitude,
                                StateShow: response.data.stateShow,
                                IframeMap: response.data.iframeMap,
                                StateAndroid: response.data.stateAndroid,
                                StateGoogle: response.data.stateGoogle,
                                SeoDescription: response.data.seoDescription,
                                FilterId: permissions.find((item)=>item.value === response.data.filterId),
                                StartDate:response.data.startDate,
                                ExpirationDate: response.data.expirationDate,
                                CoverImage: response.data.coverImage,
                                Seo_Keyword: response.data.seo_Keyword ?? [],
                                Communications:filteredCommunications.length === 0?[]:filteredCommunications,
                                CityId:filteredCity,
                                contentAttaches: response.data.contentAttaches,
                                SponsorId:sponsors.find((item)=>item.value === response.data.sponsorId),
                            });

                            if(filteredCommunications.length === 0){
                                setCommunication([{ ...communication[0], communicationId: communicationType[0], mode: communicationMode[0] }])
                            }
                        }

                    }
                }
            }else{
                handleApiError(responseAdvertiseTypes,onApiErrors);
                handleApiError(responsePosition,onApiErrors);
                handleApiError(responseSponsor,onApiErrors);
                handleApiError(responseCountry,onApiErrors);
                handleApiError(responsePermission,onApiErrors);
                handleApiError(responseCommunicationMode,onApiErrors);
                handleApiError(responseSendingState,onApiErrors);
                handleApiError(responseCommunicationType,onApiErrors);
                handleApiError(responseTemplate,onApiErrors);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        handleFetchData();
    }, [result]);


    const handleCheckContentSlug = async (e) => {
        const {name,value } = e.target;
        if (name === "Title") {
            if(advertisement.Id === null){
                const response = await GET('/Public/SlugValidation', {NewSlug:advertisement.Slug,OldSlug:null},'single');
                if(response.status === 200){
                    setContentIsSlugValid(response.data.isSlugValid);
                }else{
                    handleApiError(response,onApiErrors);
                }
            }
            else{
                const response = await GET('/Public/SlugValidation', {NewSlug:advertisement.Slug,OldSlug:oldSlug},'single');
                if(response.status === 200){
                    setContentIsSlugValid(response.data.isSlugValid);
                }else{
                    handleApiError(response,onApiErrors);
                }
            }
        }
        else{
            if(advertisement.Id === null){
                const response = await GET('/Public/SlugValidation', {NewSlug:value,OldSlug:null},'single');
                if(response.status === 200){
                    setContentIsSlugValid(response.data.isSlugValid);
                }else{
                    handleApiError(response,onApiErrors);
                }
            }
            else{
                const response = await GET('/Public/SlugValidation', {NewSlug:value,OldSlug:oldSlug},'single');

                if(response.status === 200){
                    setContentIsSlugValid(response.data.isSlugValid);
                }else{
                    handleApiError(response,onApiErrors);
                }
            }
        }
    };















    const handleAddCommunication = () => {
        const newRow = {
            id:new Date().getTime(),
            communicationId: options.communicationTypes[0], // Use the default value from options
            mode: options.communicationModes[0], // Use the default value from options
            value: null,
            description: null
        };
        setCommunication(prevList => [...prevList, newRow]);
    };
    const handleRemoveCommunication = (id,advertisingCommunicationId) => {
        if(communication.length === 1){
            const newRow = {
                id:new Date().getTime(),
                communicationId: options.communicationTypes[0], // Use the default value from options
                mode: options.communicationModes[0], // Use the default value from options
                value: null,
                description: null
            };
            setCommunication(prevList => [newRow]);
            return;
        }
        setCommunication((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.advertisingCommunicationId === advertisingCommunicationId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleCommunicationChange = (id, field, value, advertisingCommunicationId) => {
        setCommunication((preCommunication) => {
            const updatedCommunication = preCommunication.map((communication) => {
                if (
                    (advertisingCommunicationId && communication.advertisingCommunicationId === advertisingCommunicationId) || (!advertisingCommunicationId && id && communication.id === id)
                ) {
                    return { ...communication, [field]: value };
                } else {
                    return communication; // Keep other rows unchanged
                }
            });
            return updatedCommunication;
        });
    };
//---------------------------------------------------------------------------------------------------------------

    const handleChangeCounty= async (data)=>{
        if (!data) {
            return;
        }
        setSpinner(true)
        const response  = await GET(`/Countries/${data.value}/StateProvinces/Selection`,{},'IEnumerable');
        const provinces = convertSelectDataHandler(response.data)
        if(response.status === 200){
            setOptions((prevOptions) => ({...prevOptions,provinces: provinces }));
            setSpinner(false);
            setResult({...response.status})
        }else{
            handleApiError(response,onApiErrors);
        }

        setOptions((prevOptions) => ({
            ...prevOptions,
            countryValue: data,
        }));
    }
    const handleChangeProvince=async (data)=>{
        if (!data) {
            return;
        }
        setSpinner(true)
        const response = await GET(`/Countries/${data.value}/Cities/Selection`,{},'IEnumerable');
        const cities = convertSelectDataHandler(response.data)
        if(response.status === 200){
            setOptions((prevOptions) => ({...prevOptions, cities: cities }));
            setSpinner(false);
            setResult({...response.status})
        }else{
            handleApiError(response,onApiErrors);
        }
        setOptions((prevOptions) => ({
            ...prevOptions,
            provinceValue: data,
        }));
    }



    const handleSubmitAdvertisement = async(e)=>{
        e.preventDefault();
        const isValid = validate(advertiseSchema,advertisement,onSetErrors);
        if (!isValid) {
            return;
        }
        if(IsEdit){
            try {
                setSpinner(true)
                advertisement.Communications = communication.filter((row) => (row.mode && row.communicationId && row.value) || row.description);
                const preparedAdvertise = convertEditAdvertise(advertisement,files);
                const formData = handleEditAdvertiseFormData(preparedAdvertise);
                const response = await PUT(`/Advertisings/EditAdvertising`,formData,true);
                if(response.status === 200){
                    setSpinner(false)
                    setResult({...response.status});
                    handleSuccessMessage(t("Advertisementshavebeensuccessfullyedited"));
                    redirectTo(`/CMS/Advertise/List?isSuccessEdit=${true}`);
                }
                else{
                    handleApiError(response,onApiErrors);
                }
            }
            catch (e) {
                console.log(e)
            }
        }
        else{
            try {
                setSpinner(true);
                advertisement.Communications = communication.filter((row) =>  (row.mode && row.communicationId && row.value) || row.description);
                const preparedAdvertise = convertAddAdvertise(advertisement,files);
                const formData = handleAddAdvertiseFormData(preparedAdvertise);
                const response = await POST(`/Advertisings`,formData,true);
                if(response.status === 200){
                    setSpinner(false);
                    setResult({...response.status});
                    handleSuccessMessage(t("Advertisementshavebeensuccessfullyregistered"));
                    redirectTo(`/CMS/Advertise/List?isSuccess=${true}`);
                }
                else{
                    handleApiError(response,onApiErrors);
                }
            }
            catch (e) {
                console.log(e)
            }
        }
    }










    return (
        <div className="space-y-5">
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/Advertise/List`)}
                                        icon={"pi-list"}
                                        title={t("list")}
                                        tooltipTitle={t("ListAdvertisements")}
                                    />

                                </>
                            }
                />

            </div>

            <form method={'post'}  onSubmit={handleSubmitAdvertisement}>
                <div class="space-y-5 relative">
                    {
                        spinner?
                            <div className="huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div class=" grid grid-cols-12 gap-5">
                        <div class=" relative col-span-12 lg:col-span-8">
                            <div className={"flex flex-col sm:flex-row gap-3 items-center "}>
                                <div className={"flex-1 space-y-2 w-full"}>
                                    <div className="float-input p-float-label relative">
                                        <InputText onInput={handleValue}  name={'Title'} value={advertisement.Title} onBlur={handleCheckContentSlug} type="text" className="input w-full input-float-label" />
                                        <label className={"text-xs dark:text-gray-300"}>
                                            {t("Advertisement")}
                                        <RequiredIcon/>
                                        </label>
                                        <div className={"absolute end-2 -top-2"}>
                                            <DisplayError message={errors.Title}/>
                                        </div>
                                    </div>
                                </div>
                                <SlugGenerator
                                    data={advertisement}
                                    showSpinner={()=>setSpinner(true)}
                                    hideSpinner={()=>setSpinner(false)}
                                    setData={setAdvertisement}
                                />
                                <div className={"flex-1 space-y-2 w-full"}>
                                    <div className="float-input p-float-label relative">
                                        <InputText onBlur={handleCheckContentSlug} name={'Slug'} value={advertisement.Slug} onInput={handleValue} type="text" className={`input w-full input-float-label ${
                                            isContentSlugValid && isContentSlugValid === false ? "InputError" : ""}`} />
                                        <label className={"text-xs dark:text-gray-300"}>
                                            {t("Address")}
                                            <RequiredIcon/>
                                        </label>
                                        <div className={"absolute end-2 -top-2"}>
                                            <DisplayError message={errors.Slug}/>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="post mt-5  ">
                                <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                                <div class="post__content tab-content box bg-white dark:bg-themeDarkSalt700 p-2">
                                    <div className={`tab-content__pane ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                                        <div className="space-y-5">
                                            <div class=" post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                                                <label className="text-xs dark:text-gray-300">{t("SummaryExplanation")}</label>
                                                <InputTextarea name={"Excerpt"} value={advertisement.Excerpt} onInput={(e) => handleValue(e)} rows={3} cols={30} className={"input w-full "}/>
                                            </div>
                                            <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                                    <SettingButton
                                                        onClick={()=>handleSetting('GeneralSetting')}
                                                        tooltipTitle={t("EditorType")}
                                                    />
                                                    {t("FullText")}</label>
                                                <div className={"custom-editor-class"}>
                                                    <TinyEditor content={advertisement.Editor} name={'Editor'}  handleEditorContent={handleSimpleValue}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`tab-content__pane ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                                        <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                            <h2 className={"dark:text-gray-300 text-xs"}>
                                                {t("AdvertisingFile")}
                                            </h2>
                                        </div>
                                        <AdvertiseAttach
                                            data={files}
                                            IsEdit={IsEdit}
                                            triggerFileInput={triggerFileInput}
                                            setCards={setFiles}
                                            cards={files}
                                            deleteCard={deleteCard}
                                            addNewCard={addNewCard}
                                            handleFieldChange={handleFieldChange}
                                            handleFileChange={handleFileChange}
                                            initialSource={options}
                                            totalSize={totalSize}
                                        />

                                    </div>
                                    <div className={`tab-content__pane space-y-3 ${ activeTab === "tab_three" ? "active" : "" }`} id="tab_thre">
                                        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                                            <label className="dark:text-gray-300 text-xs">{t("WindowTitle")}</label>
                                            <InputText value={advertisement.SeoTitle} name={"SeoTitle"} onInput={(e)=>handleValue(e)} type={"text"} className={"input w-full"} />
                                        </div>
                                        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                                            <label className="dark:text-gray-300 text-xs">{t("Keywords")}</label>
                                            <TagsInput value={advertisement.Seo_Keyword} onChange={handleKeywordsChange}  name={"seo_Keywords"} placeHolder={t("Keywords")}/>
                                        </div>
                                        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                                            <label className="dark:text-gray-300 text-xs">{t("MetaTagDescriptions")}</label>
                                            <InputText name={"SeoDescription"} value={advertisement.SeoDescription}  onInput={(e)=>handleValue(e)}  type={"text"} className={"input w-full"}/>
                                        </div>
                                        <div className={"post "}>
                                            <TabMenu activeTab={geoActiveTab} handleTabClick={handleGeoTabClick} tabs={geoTab}/>
                                            <div className={"post__content tab-content p-2"}>
                                                <div className={`tab-content__pane ${geoActiveTab === "location" ? "active" : ""}`}
                                                     id="location">
                                                    <div className="post-input dark:bg-themeDarkPostInput grid grid-cols-1 gap-5 post-input-design p-2 md:grid-cols-2 ">
                                                        <div className="space-y-1">
                                                            <label className="dark:text-gray-300 text-xs">
                                                                {t("Length")}
                                                            </label>
                                                            <InputText value={advertisement.Longitude} name={'Longitude'} onInput={(e)=>handleValue(e)} type={"text"} className={"input w-full"} placeholder={t("Length")}/>
                                                        </div>
                                                        <div className="space-y-1">
                                                            <label className="dark:text-gray-300 text-xs">
                                                                {t("Width")}
                                                            </label>
                                                            <InputText value={advertisement.Latitude} name={'Latitude'} onInput={(e)=>handleValue(e)} type={"text"} className={"input w-full "} placeholder={t("Width")}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`tab-content__pane ${geoActiveTab === "address" ? "active" : ""}`} id="address">
                                                    <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                                                        <label className="dark:text-gray-300 text-xs">
                                                            {t("Address")}
                                                        </label>
                                                        <InputText value={advertisement.IframeMap} onInput={(e)=>handleValue(e)} name={'IframeMap'} type={"text"} className={"input w-full "} placeholder={t("Address")}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`tab-content__pane ${ activeTab === "tab_four" ? "active" : "" }`} id="tab_four">
                                        <div >
                                            <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                                <h2 className={"dark:text-gray-300 text-xs"}>
                                                    {t("listOfContactMethods")}
                                                </h2>
                                            </div>
                                            <div className="p-2 space-y-5 max_height85vh">
                                                {communication.map((row,index) => (
                                                    <div key={row.id} className="grid grid-cols-1 gap-2 p-2 md:grid-cols-2 post-input dark:bg-themeDarkPostInput post-input-design ">
                                                        <div className="flex gap-2">
                                                            <div className="mt-auto">
                                                                <div className="number_pluse bg-themeInformation text-white dark:text-gray-300">
                                                                    {index+1}
                                                                </div>
                                                            </div>
                                                            <div className="space-y-1 flex-1">
                                                                <label className="text-xs dark:text-gray-300">{ t("CommunicationType")}</label>
                                                                <Select
                                                                    options={options.communicationTypes}
                                                                    isMulti={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    onChange={(data) => handleCommunicationChange(row.id, 'communicationId',data,row.advertisingCommunicationId)}
                                                                    placeholder={t("selection")}
                                                                    value={row.communicationId}
                                                                    menuPosition={'fixed'}
                                                                    classNamePrefix={"h_multi_select"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div >
                                                            <div className="space-y-1">
                                                                <label className="text-xs dark:text-gray-300">{t("TypeIndividualLegal")}</label>
                                                                <Select
                                                                    options={options.communicationModes}
                                                                    isMulti={false}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    onChange={(data) => handleCommunicationChange(row.id, 'mode',data,row.advertisingCommunicationId)}
                                                                    placeholder={t("selection")}
                                                                    value={row.mode}
                                                                    menuPosition={'fixed'}
                                                                    classNamePrefix={"h_multi_select"}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="space-y-1">
                                                            <label className="text-xs dark:text-gray-300">{t("ContactAddress")}</label>
                                                            <input type="text" className="input w-full" value={row.value} onChange={(e) => handleCommunicationChange(row.id, 'value', e.target.value,row.advertisingCommunicationId)}/>
                                                        </div>
                                                        <div className={"flex items-end gap-1"}>
                                                            <div className="space-y-1 flex-1">
                                                                <label className="text-xs dark:text-gray-300 ">{t("description")}</label>
                                                                <input type="text" className="input w-full"
                                                                       value={row.description}
                                                                       onChange={(e) => handleCommunicationChange(row.id, 'description', e.target.value,row.advertisingCommunicationId)}
                                                                />
                                                            </div>
                                                            <div className="flex items-end gap-2">
                                                                <button type="button" className="button bg-themeInformation dark:bg-themeDarkInformation text-white small_button"
                                                                        onClick={handleAddCommunication}>
                                                                    <HPrimeIcon icon={'pi-plus'} />
                                                                </button>
                                                                <button type="button" className="button bg-themeDanger text-white small_button"
                                                                        onClick={() => handleRemoveCommunication(row.id,row.advertisingCommunicationId)}>
                                                                    <HPrimeIcon icon={'pi-minus'}  />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-12 lg:col-span-4">
                            <div class="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700 birder dark:border-themeDarkColorLine">
                                <div className="w-full h-48">
                                    <UploadImage isEdit={IsEdit}  removeFile={removeFile} removeName={'CoverImagePath'} accept={'image/*'} OnValueSelection={handleCoverImageFile} value={advertisement.CoverImage} name={'CoverImage'} />
                                </div>
                                <div class="grid grid-cols-2 gap-3">
                                    <div class="space-y-1 col-span-2">
                                        <label className="text-xs dark:text-gray-300">{t("Sponsor")}</label>
                                        <Select
                                            options={options.users}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data) =>handleSelectValue(data,"SponsorId")}
                                            placeholder={t("selection")}
                                            value={advertisement.SponsorId}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>

                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                            <SettingButton
                                                onClick={()=>handleSetting('GeneralSetting')}
                                                tooltipTitle={t("Calendarsettings")}
                                            />
                                            {t("StartDate")}
                                        </label>
                                        <DateTimePicker  value={advertisement.StartDate} handleDateTime={handleStartDate} format={'YYYY/MM/DD'}  />
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                            <SettingButton
                                                onClick={()=>handleSetting('GeneralSetting')}
                                                tooltipTitle={t("Calendarsettings")}
                                            />
                                            {t("EndDate")}
                                        </label>
                                        <DateTimePicker  handleDateTime={handleExpireDate} format={'YYYY/MM/DD'} name={'ExpirationDate'} value={advertisement.ExpirationDate}/>
                                    </div>

                                    <div class=" space-y-1 col-span-2">
                                        <label className="text-xs dark:text-gray-300">{t("country")}</label>
                                        <Select
                                            options={options.countries}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleChangeCounty(data)}
                                            placeholder={t("selection")}
                                            value={options.countryValue}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                    </div>

                                    <div class=" space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("province")}</label>
                                        <Select
                                            options={options.provinces}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleChangeProvince(data)}
                                            name={'Provinces'}
                                            placeholder={t("selection")}
                                            value={options.provinceValue}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                    </div>
                                    <div class=" space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("city")}</label>
                                        <Select
                                            options={options.cities}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'CityId')}
                                            name={'CityId'}
                                            placeholder={t("selection")}
                                            value={advertisement.CityId}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div class=" space-y-1 col-span-2">
                                        <label className="text-xs dark:text-gray-300">{t("template")}</label>
                                        <Select
                                            options={options.templates}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'TemplateId')}
                                            name={'TemplateId'}
                                            placeholder={t("selection")}
                                            value={advertisement.TemplateId}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                </div>
                                <div className="post-input col-span-2 grid grid-cols-2 md:grid-cols-3 gap-2 gap-y-4 post-input-design p-3 dark:bg-themeDarkPostInput">
                                    <div className="col-span-2 space-y-2 md:col-span-3">
                                        <label className="text-xs dark:text-gray-300">
                                            {t("ReleaseFor")}
                                            <button type="button" className="text-themeInformation text-xs ms-2 dark:text-themeDarkInformation" onClick={() =>redirectTo('/Form/Report/Builder')}>( {t("CreateFilter")} )</button>
                                        </label>
                                        <Select
                                            options={options.filters}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'FilterId')}
                                            name={'FilterId'}
                                            placeholder={t("selection")}
                                            value={advertisement.FilterId}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div className="col-span-2 md:col-span-3 space-y-2">
                                        <div className="space-y-2">
                                            <label className="text-xs dark:text-gray-300">
                                                {t("SocialMediaSharing")}
                                            </label>
                                            <div className="space-y-1">
                                                <Select
                                                    options={options.sendingStates}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    isMulti={true}
                                                    onChange={(data)=>handleSelectValue(data,'SendingStates')}
                                                    name={'TemplateId'}
                                                    placeholder={t("selection")}
                                                    value={advertisement.SendingStates}
                                                    className={"select-group"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="col-span-2">
                                            <InputSwitch value={advertisement.StateShow} OnValueSelection={InputSwitchValueHandler} lable={t("DisplayStatus")} name={'StateShow'}/>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <InputSwitch value={advertisement.StateAndroid} OnValueSelection={InputSwitchValueHandler} lable={t("SendToAndroid")} name={'StateAndroid'}/>
                                        </div>
                                    </div>
                                    <div>
                                        <InputSwitch value={advertisement.StateGoogle} OnValueSelection={InputSwitchValueHandler} lable={t("GoogleMap")} name={'StateGoogle'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ButtonSubmitAndCancel
                    onCancelClick={()=>redirectTo('/CMS/Advertise/List')}
                    />
                    {/*<div class="flex justify-center lg:justify-end gap-3">*/}
                    {/*    <button type={'button'} onClick={()=>redirectTo('/CMS/Advertise/List')} className="button w-24 bg-themeDanger text-white">{t("Cancel")}</button>*/}
                    {/*    <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>*/}
                    {/*</div>*/}
                </div>
            </form>
            <FantasyModal openModal={modal.HandleAddFilter} closeModal={HandleAddFilterOnHide} title={t("CreateFilter")} customeClassName={'modal-xl'}>
                <div>
                    <AddFilter/>
                </div>
            </FantasyModal>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>
            <ToastContainer/>
        </div>

    )
}


