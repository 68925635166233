import React, {useEffect, useState} from "react";
import {UseRememberMe} from "../../Commans/Hooks/UseRememberMe";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useUserLogin} from "../Authentications/useUserLogin";
import {LoadingSpinner} from "../../Commans/Loadings/LoadingSpinner";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {InputText} from "primereact/inputtext";
import {useUserSignup} from "./useUserSignup";
import {validate} from "../../../Services/Globals/validate";
import {signupSchema} from "./signupSchema";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const SignUp=({handleChangeState})=>{
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const redirectTo = useNavigate();
    const {setUsers,users,handleValue}=useUserSignup(setErrors)

    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors = (errors) => {
        setErrors(errors);
    }

    const handleUserSignup= async(e)=>{
        e.preventDefault();
        try {
           const isValid = validate(signupSchema,users,onSetErrors);
           if(!isValid){
               return;
           }
           showSpinner();
           const response = await POST(`/`,users,false);
           if(response.status === 200){
               hideSpinner();
               // verification Process is done here ...
           }
           else{
               handleApiError(response,hideSpinner);
           }
        }
        catch (e) {
            hideSpinner();
        }
    }

    return (<>


        <form onSubmit={handleUserSignup} method={'post'} className={'relative'}>
            {IsShowSpinner ? <LoadingSpinner/> : null}

            <div className={"space-y-4 relative"}>

                <p class="dark:text-gray-300">ثبت نام حساب کاربری</p>
                <div>
                    <div className="relative float-input p-float-label ">
                        <div className={"absolute -top-2 end-2"}>
                            <DisplayError message={errors.fullName}/>
                        </div>
                        <span className={"absolute end-2 top-1/2 -translate-y-1/2"}>
                             <HPrimeIconSmall
                                 icon={"pi-user"}
                                 iconClass={"dark:text-gray-300"}
                             />
                        </span>

                        <InputText onInput={(e) => handleValue(e)}
                                   value={users.fullName}
                                   type="text"
                                   className={" input w-full input-float-label"}
                                   name="fullName"
                        />
                        <label className={"text-xs dark:text-gray-300"}>{t("FirstNameAndLastName")}</label>
                    </div>
                </div>
                <div>
                    <div className="relative float-input p-float-label ">
                        <div className={"absolute -top-2 end-2"}>
                            <DisplayError message={errors.phoneNumber}/>
                        </div>
                        <span className={"absolute end-2 top-1/2 -translate-y-1/2"}>
                            <HPrimeIconSmall
                            icon={"pi-phone"}
                            iconClass={"dark:text-gray-300"}
                            />
                        </span>
                        <InputText onInput={(e) => handleValue(e)}
                                   value={users.phoneNumber}
                                   type="text"
                                   className={" input w-full input-float-label"}
                                   name="phoneNumber"
                        />
                        <label className={"text-xs dark:text-gray-300"}>{t("MobilePhone")}</label>
                    </div>
                </div>

                <div>
                    <div className="relative float-input p-float-label ">
                        <div className={"absolute -top-2 end-2"}>
                            <DisplayError message={errors.userName}/>
                        </div>
                        <span className={"absolute end-2 top-1/2 -translate-y-1/2"}>
                            <HPrimeIconSmall
                            icon={"pi-user"}
                            iconClass={"dark:text-gray-300"}
                            />
                        </span>
                        <InputText onInput={(e) => handleValue(e)}
                                   value={users.userName}
                                   type="text"
                                   className={" input w-full input-float-label"}
                                   name="userName"
                        />
                        <label className={"text-xs"}>{t("UsernameOrEmail")}</label>
                    </div>
                </div>


                <div className={"relative float-input p-float-label"}>
                    <div className={"absolute -top-2 end-2"}>
                        <DisplayError message={errors.password}/>
                    </div>
                    <HPrimeIconSmall
                        iconClass={"cursor-pointer absolute dark:text-gray-300 end-2 top-1/2 -translate-y-1/2"}
                        icon={
                        showPassword
                            ? " pi-eye "
                            : "pi-eye-slash "
                    }
                       onClick={() => setShowPassword(!showPassword)}>

                    </HPrimeIconSmall>
                    <InputText type={showPassword ? "text" : "password"}
                               className=" input w-full input-float-label"
                               value={users.password}
                               onInput={(e) => handleValue(e)}
                               name="password"
                               autoSave={'off'}
                               autoComplete={'off'}

                    />
                    <label className={"text-xs "}>{t("Password")}</label>
                </div>

                <div>
                    <button className="button w-full  user_login_gradiant text-white" type="submit">
                        {t("signup")}
                    </button>
                </div>
            </div>
        </form>


    </>)
}