
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const ImageElement = ({ itemCount,SortDescending,SortAccending,index, element, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement }) => (
    <>
        <input
            type="file"
            placeholder={element?.properties?.description}
            accept={element.properties.extensions === null || element.properties.extensions === ''? 'image/*': element.properties.extensions}
            className={` harmony-file dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine file:dark:bg-themeDarkInformation dark:text-gray-300  ${index === selectedElementIndex ? 'border-themeInformation' : ''}`}
            onClick={() => handleSelectTabAfterElementSelection(index)}
            maxLength={element.properties.max}
            minLength={element.properties.min}
            readOnly={element.properties.isReadOnly}
            disabled={element.properties.isReadOnly}
            id={element.properties.id}
            value={element.properties?.defaultValue?.value}
        />

        <div className={"harmony_body_button"}>
            <button
                type="button"
                className="button bg-themeDanger text-white harmony_button_form"
                onClick={() => deleteElement(index)}
            >
                <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
            </button>
            {
                index===0?null:
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortAccending(index)}

                    >
                        <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                    </button>

            }

            {
                index <itemCount - 1?
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortDescending(index)}

                    >
                        <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                    </button>:
                    null
            }

        </div>



    </>
);