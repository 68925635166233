import React from 'react';

const MagicIcon = ({sizeStyle,fillStyle}) => (
    <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="magic" className={`${sizeStyle ? sizeStyle:"size-7"} ${fillStyle ? fillStyle:""} dark:text-gray-300 `} role="img">
        <path fill="currentColor"
            d="M244,152a4.0002,4.0002,0,0,1-4,4H220v20a4,4,0,0,1-8,0V156H192a4,4,0,0,1,0-8h20V128a4,4,0,0,1,8,0v20h20A4.0002,4.0002,0,0,1,244,152ZM64,64H80V80a4,4,0,0,0,8,0V64h16a4,4,0,0,0,0-8H88V40a4,4,0,0,0-8,0V56H64a4,4,0,0,0,0,8ZM184,196H172V184a4,4,0,0,0-8,0v12H152a4,4,0,0,0,0,8h12v12a4,4,0,0,0,8,0V204h12a4,4,0,0,0,0-8Zm-5.17236-81.1709L72.48535,221.17187a12.01686,12.01686,0,0,1-16.9707,0L34.8291,200.48535a11.998,11.998,0,0,1,0-16.9707l148.68555-148.686a12.01526,12.01526,0,0,1,16.9707-.00049L221.1709,55.51465a11.998,11.998,0,0,1,0,16.9707l-42.34229,42.34229-.00048.00048ZM149.65674,80,176,106.34326l39.51465-39.51465v-.00049a3.99855,3.99855,0,0,0,0-5.65625L194.8291,40.48535a4.00622,4.00622,0,0,0-5.6582.00049Zm20.68652,32L144,85.65674,40.48535,189.17187a3.99855,3.99855,0,0,0,0,5.65625L61.1709,215.51465a4.00621,4.00621,0,0,0,5.6582,0Z"></path>
    </svg>

);

export default MagicIcon;
