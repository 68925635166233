import {useState} from "react";

export const useCustomUnConfirmed=(setErrors)=>{
    const [request,setRequest] = useState({bankAccountId:null,description:null,IsVerified:false,withdrawalWalletRequestId:null,transactionCode:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setRequest({...request,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setRequest({...request,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setRequest({...request,[name]:data});
    }
    return {
        request,
        setRequest,
        handleValue,
        handleSelectValue,
        handleNumberValue
    }
}