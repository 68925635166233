import React, {useMemo, useState} from "react";

import {ListSearch} from "../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";

import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {TabMenuTwo} from "../../Commans/UIParts/TabMenuTwo";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useCartableTab} from "./CartableTabs/useCartableTab";
import {FormsTab} from "./CartableTabs/FormsTab";
import {NotesTab} from "./CartableTabs/NotesTab";
import {ReminderTab} from "./CartableTabs/ReminderTab";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {useCartableFilters} from "./useCartableFilters";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {Complaint} from "../../CRM/PersonsManagement/UserDetails/Complaint";
import {ComplaintsTab} from "./CartableTabs/ComplaintsTab";
import {FeedBacksTab} from "./CartableTabs/FeedBacksTab";

export const HandleListCartable = () => {
    const { t } = useTranslation();
    const {handleToggleFilter,handleResetFilters,SwitchFilters,setFilter,filter,handleSearch,setSearch,search}=useCartableFilters();
    const {handleChangeTabId,tabs, tabId}=useCartableTab(handleResetFilters,);



    return(
        <>
            <div class="space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <ButtonSearchList
                            onClickReset={()=>handleResetFilters()}
                            onClickSearch={()=>handleSearch()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenOne={false}

                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />

                                    </>
                                }
                    />
                </div>
                <div class="relative grid grid-cols-2 lg:grid-cols-7 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-3">
                    <TabMenuTwo activeTab={tabId} handleTabClick={handleChangeTabId} tabs={tabs} />
                </div>
                <div className={"post__content tab-content"}>
                    <div className={`tab-content__pane ${ tabId === "form" ? "active" : "" }`}  id="form">
                        <div>
                            {
                                tabId === 'form'?
                                    <FormsTab filter={filter}/>
                                    :null
                            }
                        </div>

                    </div>
                    <div className={`tab-content__pane ${ tabId === "notes" ? "active" : "" }`}  id="notes">
                        <div>
                            {
                                tabId === 'notes'?
                                    <NotesTab
                                     filter={filter}
                                    />
                                    :null
                            }
                        </div>
                    </div>
                    <div className={`tab-content__pane ${ tabId === "reminder" ? "active" : "" }`}  id="reminder">
                        <div>
                            {
                                tabId === 'reminder'?
                                    <ReminderTab
                                      filter={filter}
                                    />
                                    :null
                            }
                        </div>
                    </div>
                    <div className={`tab-content__pane ${ tabId === "Complaint" ? "active" : "" }`}  id="Complaint">
                        <div>
                            {
                                tabId === 'Complaint'?
                                    <ComplaintsTab
                                        filter={filter}
                                    />
                                    :null
                            }
                        </div>
                    </div>
                    <div className={`tab-content__pane ${ tabId === "Feedback" ? "active" : "" }`}  id="Feedback">
                        <div>
                            {
                                tabId === 'Feedback'?
                                    <FeedBacksTab
                                        filter={filter}
                                    />
                                    :null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer/>
        </>
    )
};
