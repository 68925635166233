import React, { useEffect, useState } from "react";
import { PickList } from "primereact/picklist";
import queryString from "query-string";
import { LoadingHarmony } from "../../../Commans/Loadings/LoadingHarmony";
import { handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useNavigate} from "react-router-dom";

import {ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import Select from "react-select";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {InputText} from "primereact/inputtext";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";

export const HandleUserPermission = () => {
    const { t } = useTranslation();

  const [spinner,setSpinner] = useState(false);
  const redirectTo = useNavigate();
    const onApiError = () => {
        setSpinner(false);
    };
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [permissionId,setPermissionId] = useState(null);
  const [options,setOptions] = useState({accesses:[],permissionGroups:[],allFilteredPermissions:[],allSelectedPermissions:[]})
  const [result, setResult] = useState(0);
  const [concurrencyStamp,SetConcurrencyStamp] = useState(null);
  const [modal, setModal] = useState({ HandleAddAccess: false });
  const [permissionGroupId,setPermissionGroupId] = useState(null);
  const[selectedPermissionGroupId,setSelectedPermissionGroupId] = useState(null)


    const HandleAddAccessOnHide = () => {
        setModal((prevState) => ({ ...prevState, HandleAddAccess: !modal.HandleAddAccess }));
    };


    const handleChangeData = (event) => {
        setSource(event.source);
        setTarget(event.target);
    };




    //these parameters are directly from api
    const  handleFilterSelectedPermissionsInAllPermissions=(allPermissions,rolePermission)=>{
        const permissionList = allPermissions.data.map(permission => ({ ...permission, dataAccess: 0 }));
        const selectedPermissions = permissionList.filter(item => rolePermission.data.permissions.some(sourceItem => sourceItem.permissionId === item.id));
        const filteredPermissions = permissionList.filter(role => !selectedPermissions.some(selectedPermission => selectedPermission.id === role.id));
        const updatedSelectedPermissions = selectedPermissions.map(selectedPermission => ({
            ...selectedPermission,
            dataAccess: rolePermission.data.permissions.find(sourceItem => sourceItem.permissionId === selectedPermission.id)?.dataAccess || 0
        }));
        return {filteredPermissions,updatedSelectedPermissions}
    }
    // Initialize all fields,DropDowns
    const handleInitialData=(dataAccess,permissionGroups)=>{
        const accesses = convertSelectDataHandler(dataAccess);
        const groups = convertSelectDataHandler(permissionGroups);
        groups.push({label:'-', value: '*'})
        setOptions({accesses: accesses,permissionGroups: groups});
    }

    // Gets All Data from server
    const handleFetchData = async () => {
 try {
     setSpinner(true);
     const allDataAccess = await GET(`/Enumerations/EnDataAccess`,{},'IEnumerable');
     const queryParams = queryString.parse(window.location.search);
     const allPermissions = await GET(`/Permissions`,{},'IEnumerable');
     const rolePermission = await GET(`/Roles/${queryParams.roleId}/Permissions`,{},'IEnumerable');
     const allPermissionGroups = await GET(`/Permissions/PermissionGroups`,{},'IEnumerable');

   if (allDataAccess.status === 200 && allPermissions.status === 200 && rolePermission.status === 200 && allPermissionGroups.status === 200) {
       handleInitialData(allDataAccess.data,allPermissionGroups.data)
       SetConcurrencyStamp(rolePermission.data.concurrencyStamp);
       const {filteredPermissions,updatedSelectedPermissions} = handleFilterSelectedPermissionsInAllPermissions(allPermissions,rolePermission)
       setSource(filteredPermissions);
       setTarget(updatedSelectedPermissions);
       setOptions(prevState => ({...prevState,allFilteredPermissions: filteredPermissions,allSelectedPermissions: updatedSelectedPermissions}))
       setSpinner(false);
   }
 }catch (e) {
     console.log(e)
 }
  };


  useEffect(() => {
    handleFetchData();
  }, [result]);


  const itemTemplate = (item) => {
    return (
        <div className="flex items-center gap-1 border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkInformation p-2 rounded ">
            <div>
                <div className={`p-2 rounded ${item.dataAccess === 0 ?'bg-themeSecondary':'bg-themeInformation dark:bg-themeDarkInformation'}`}>
                    <HPrimeIconSmall icon={'pi-user'} iconClass={'text-white'}/>
                </div>
            </div>
            <span className="font-bold line-clamp-1 w-full">{item.name}</span>
            <div className={"flex gap-2 items-center flex-shrink-0"}>
                <button type={"button"}>
                    <HPrimeIconSmall icon={"pi-list"} size={18} iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
                </button>
                {
                    IsPermissionInTarget(item.id)?
                        <button type={"button"}  onClick={() => handleOpenAccess(item.id)}>
                            <HPrimeIconSmall icon={"pi-key"} iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
                        </button>
                        :null
                }
            </div>
        </div>
    );
  };
    const handleOpenAccess=(id)=>{
        setModal({ HandleAddAccess: true });
        setPermissionId(id)
    }
    //checks if a permission is in first dataSource as all permissions
    const IsPermissionInTarget=(id)=>{
        return target.some((targetItem) => targetItem.id === id);
    }

    //updates dataAccess for any permissions in target dataSource as selected permissions
    const handleChangePermission=async (data,name,id)=>{
        const queryParams = queryString.parse(window.location.search);
        const isInTarget = IsPermissionInTarget(id);
        if(isInTarget){
            try {
                setSpinner(true)
                const permission = target.find(item=>item.id === id);
                const model={
                    roleId: queryParams.roleId,
                    concurrencyStamp: concurrencyStamp,
                    permissionId:permission.id,
                    dataAccess:data.value
                }
                const response = await PUT(`/Authentications/RolePermissionDataAccess`,model,false);
                if(response.status === 200){
                    setSpinner(false);
                    handleSuccessMessage(t("PermissionsEditedSuccessfully"))
                    setResult({...response.status});
                    setModal({HandleAddAccess: false})
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                onApiError()
            }
        }
    }
    // sends data to left side from source to target to select permissions
    // همینه
    const handleSubmitPermissions=async()=>{
        try {
            const queryParams = queryString.parse(window.location.search);
            setSpinner(true);
            const permissions = {
                roleId:queryParams.roleId,
                concurrencyStamp: concurrencyStamp,
                permissionIds: target.map((item) => ({
                    permissionId: item.id,
                    dataAccess: item.dataAccess  // You can set a default value if needed
                })),
            };
            const response = await POST(`/Authentications/RolePermissions`,permissions,false);
            if(response.status === 200){
                setModal({HandleAddAccess: false})
                setSpinner(false);
                handleSuccessMessage(t("Permissionsbeensuccessfully"))
                setResult({...response.status});
            }
            else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
            onApiError()
        }
    }



    // filters all filtered Permissions based on group Id
    const handleGetAllFilteredPermissionByGroupId=(groupId)=>{
        const groups = options.allFilteredPermissions.filter(item=>item.permissionGroupId === groupId);
        return groups;
    }

    //filters all filtered permissions in left side from source based on groups
    // saves permissionGroupId to be used in search box handler
    const handleFilterAllSourceByGroup=(permissionGroupId)=>{
         setPermissionGroupId(permissionGroupId.value)
         if(permissionGroupId.value === '*'){
             setSource(options.allFilteredPermissions);
         }
         else{
             const groupedPermissions = handleGetAllFilteredPermissionByGroupId(permissionGroupId.value)
             if(groupedPermissions.length > 0){
                 setSource(groupedPermissions);
             }else{
                 setSource([])
             }
         }
    }
    // filters source from all filtered permissions to include group Name
    // checks 2 situations , if we have empty search box we get back to selected group
    // second situation is checked when we have all permissions when permissionGroupId is *
    const handleFilterSourceByName = (groupName) => {
        if ((groupName === '' || groupName === null || groupName === undefined) ) {
            if(permissionGroupId === '*'){
                setSource(options.allFilteredPermissions);
                return;
            }else{
                const previousSelectedGroups = handleGetAllFilteredPermissionByGroupId(permissionGroupId);
                setSource(previousSelectedGroups);
                return;
            }
        }
        const lowerCaseGroupName = groupName.toLowerCase();
        const permissions = options.allFilteredPermissions.filter(item => item.name.toLowerCase().includes(lowerCaseGroupName));
        setSource(permissions);
    }








    // filters all filtered SelectedPermissions based on group Id
    const handleGetAllFilteredSelectedByGroupId=(groupId)=>{
        const groups = options.allSelectedPermissions.filter(item=>item.permissionGroupId === groupId);
        return groups;
    }

    //filters all filtered Selected permissions in right side from target based on groups
    // saves selectedPermissionGroupId to be used in search box handler
    const handleFilterAllTargetByGroup=(selectedPermissionGroupId)=>{
        setSelectedPermissionGroupId(selectedPermissionGroupId.value)
        if(selectedPermissionGroupId.value === '*'){
            setTarget(options.allSelectedPermissions);
        }
        else{
            const groupedPermissions = handleGetAllFilteredSelectedByGroupId(selectedPermissionGroupId.value)
            if(groupedPermissions.length > 0){
                setTarget(groupedPermissions);
            }else{
                setTarget([]);
            }
        }
    }
    // filters target from all filtered permissions to include group Name
    // checks 2 situations , if we have empty search box we get back to selected group
    // second situation is checked when we have all permissions when selectedPermissionGroup is *
    const handleFilterTargetByName = (groupName) => {
        if ((groupName === '' || groupName === null || groupName === undefined) ) {
            if(selectedPermissionGroupId === '*'){
                setTarget(options.allSelectedPermissions);
                return;
            }else{
                const previousSelectedGroups = handleGetAllFilteredSelectedByGroupId(selectedPermissionGroupId);
                setTarget(previousSelectedGroups);
                return;
            }
        }
        const lowerCaseGroupName = groupName.toLowerCase();
        const permissions = options.allSelectedPermissions.filter(item => item.name.toLowerCase().includes(lowerCaseGroupName));
        setTarget(permissions);
    }






    return (
      <>

          <div className=" space-y-5  ">
              <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                  <ListFilter label={null} icon={null}
                              showChildrenTwo={false}
                              childrenOne={
                                  <>
                                      <ButtonPlus
                                          onClick={()=>redirectTo(`/CRM/User/Add`)}
                                          tooltipTitle={t("UsersRegistration")}
                                          title={t("personnel")}
                                      />

                                      <ButtonPlus
                                          onClick={()=>redirectTo(`/CRM/User/Group`)}
                                          icon={"pi-users"}
                                          title={t("grouping")}
                                          tooltipTitle={t("UsersGrouping")}

                                      />

                                      <ButtonPlus
                                          onClick={()=>redirectTo('/CRM/User/Role')}
                                          icon={"pi-star"}
                                          title={t("Role")}
                                          tooltipTitle={t("CreatingRole")}
                                      />


                                      <ButtonPlus
                                          onClick={()=>redirectTo(`/CRM/User/List`)}
                                          icon={"pi-list"}
                                          title={t("list")}
                                          tooltipTitle={t("ListOfPersons")}

                                      />



                                  </>

                              }

                  />


              </div>
              <div class="relative">
                  {spinner ? (
                      <div className="huploader_loading">
                          <div className="relative">
                              <LoadingHarmony />
                          </div>
                      </div>
                  ) : null}
                  <div style={{direction:"ltr"}} class="p-3 box bg-white dark:bg-themeDarkSalt700">
                      <PickList
                          target={target}
                          source={source}
                          onChange={handleChangeData}
                          filter
                          filterBy="name"
                          sourceFilterTemplate={
                             <div className={'space-y-2'}>
                                 <InputText onChange={(e)=>handleFilterSourceByName(e.target.value)} className={'input w-full'} placeholder={t("search")}/>
                                 <Select
                                     options={options.permissionGroups}
                                     isSearchable={true}
                                     placeholder={t("selection")}
                                     onChange={(data)=>handleFilterAllSourceByGroup(data)}
                                     menuPosition={"fixed"}
                                     classNamePrefix={"h_multi_select"}
                                 />

                             </div>
                          }
                          targetFilterTemplate={
                              <div className={'space-y-2'}>
                                  <InputText  onChange={(e)=>handleFilterTargetByName(e.target.value)} className={'input w-full'} placeholder={t("search")}/>
                                  <Select
                                      options={options.permissionGroups}
                                      isSearchable={true}
                                      placeholder={t("selection")}
                                      onChange={(data)=>handleFilterAllTargetByGroup(data)}
                                      menuPosition={"fixed"}
                                      classNamePrefix={"h_multi_select"}

                                  />
                              </div>

                          }
                          sourceHeader={t("Unauthorized")}
                          targetHeader={t("Authorized")}
                          sourceStyle={{ height: "30rem" }}
                          targetStyle={{ height: "30rem" }}
                          sourceFilterPlaceholder={t("search")}
                          targetFilterPlaceholder={t("search")}
                          itemTemplate={itemTemplate}

                      />
                  </div>
              </div>
              <ButtonSubmitAndCancel
                  onRegisterClick={()=>handleSubmitPermissions()}
              />
              <FantasyModal openModal={modal.HandleAddAccess} closeModal={HandleAddAccessOnHide} title={t("AccessToInformation")} >
                  <div className="space-y-1">
                      <label className={"text-xs dark:text-gray-300"}>{t("AccessLevel")}</label>
                      <Select
                          options={options.accesses}
                          isSearchable={true}
                          onChange={(data)=>handleChangePermission(data,'dataAccess',permissionId)}
                          placeholder={t("selection")}
                          menuPosition={"fixed"}
                          classNamePrefix={"h_multi_select"}

                          //value={options.accessType}
                      />
                  </div>
              </FantasyModal>
          </div>

          <ToastContainer/>

      </>

  );
};
