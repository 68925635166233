import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {fileManager} from "../../../../Services/Globals/Public/AppSetting";

export const handleCreateFormItems = (formId, elements) => {
    const data = {
        formId: formId,
        formItems: elements.map((item) => {
            let formItem = {};

                switch (item.type) {
                    case 0: // TextBox
                        formItem = {
                            min: item.properties.min,
                            max: item.properties.max,
                            order: item.properties.order,
                            name: item.properties.name,
                            isReadOnly: item.properties.isReadOnly,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 1: // TextArea
                        formItem = {
                            min: item.properties.min,
                            max: item.properties.max,
                            order: item.properties.order,
                            name: item.properties.name,
                            isReadOnly: item.properties.isReadOnly,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 2: // Numeric
                        formItem = {
                            min: item.properties.min,
                            max: item.properties.max,
                            order: item.properties.order,
                            name: item.properties.name,
                            isReadOnly: item.properties.isReadOnly,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 3: // MultiSelect
                        formItem = {
                            order: item.properties.order,
                            name: item.properties.name,
                            items: item.properties.items.map(subItem => ({
                                key: subItem.label,
                                value: subItem.value,
                                parentId: subItem.parentId,
                                order: subItem.order
                            })),
                            isReadOnly: item.properties.isReadOnly,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: {items:item.properties.defaultValue.value.map(val => val.value)},
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 4: // Select
                        formItem = {
                            order: item.properties.order,
                            name: item.properties.name,
                            items: item.properties.items.map(subItem => ({
                                key: subItem.label,
                                value: subItem.value,
                                parentId: subItem.parentId,
                                order: subItem.order
                            })),
                            isReadOnly: item.properties.isReadOnly,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 5: // Radio
                        formItem = {
                            order: item.properties.order,
                            name: item.properties.name,
                            items: item.properties.items.map(subItem => ({
                                key: subItem.label,
                                value: subItem.value,
                                parentId: subItem.parentId,
                                order: subItem.order
                            })),
                            isReadOnly: item.properties.isReadOnly,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 6: // CheckBox
                        formItem = {
                            order: item.properties.order,
                            name: item.properties.name,
                            items: item.properties.items.map(subItem => ({
                                key: subItem.label,
                                value: subItem.value,
                                parentId: subItem.parentId,
                                order: subItem.order
                            })),
                            isReadOnly: item.properties.isReadOnly,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: {items:item.properties.defaultValue.value.map(val => val.value)},
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 7: // SelectionBreak
                        formItem = {
                            isReadOnly: item.properties.isReadOnly,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 8: // File
                        formItem = {
                            isReadOnly: item.properties.isReadOnly,
                            extensions: item.properties.extensions,
                            name: item.properties.name,
                            folderSource: item.properties.folderSource.replace(/\//g, fileManager.separator).replace(/\s+/g, '').trim(),
                            min: item.properties.min,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 9: // Location
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            countries: item.properties.countries,
                            states: item.properties.states,
                            cities: item.properties.cities,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: {
                                stateProvinceId: item.properties.defaultValue.stateProvinceId,
                                countryId: item.properties.defaultValue.countryId,
                                cityId: item.properties.defaultValue.cityId,
                                address:item.properties.defaultValue.address
                            },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 10: // Email
                        formItem = {
                            order: item.properties.order,
                            name:item.properties.name,
                            isReadOnly: item.properties.isReadOnly,
                            regex: item.properties.regex,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 11: // Phone
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            name:item.properties.name,
                            regex: item.properties.regex,
                            isRequired: item.properties.isRequired,
                            preNumbers: item.properties.preNumbers.map(preNumber => ({
                                key: preNumber.label,
                                value: preNumber.value,
                                parentId: preNumber.parentId,
                                order: preNumber.order
                            })),
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: {
                                value: item.properties.defaultValue.value,
                                preNumber: item.properties.defaultValue.preNumber.value
                            },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 12: // Image
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            extensions: item.properties.extensions,
                            name:item.properties.name,
                            folderSource: item.properties.folderSource.replace(/\//g, fileManager.separator).replace(/\s+/g, '').trim(),
                            min: item.properties.min,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 13: // Video
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            extensions: item.properties.extensions,
                            folderSource: item.properties.folderSource.replace(/\//g, fileManager.separator).replace(/\s+/g, '').trim(),
                            name:item.properties.name,
                            min: item.properties.min,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;

                    case 14: // Audio
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            extensions: item.properties.extensions,
                            folderSource: item.properties.folderSource.replace(/\//g, fileManager.separator).replace(/\s+/g, '').trim(),
                            min: item.properties.min,
                            name:item.properties.name,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;
                    case 15: //price
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            format:item.properties.format,
                            name:item.properties.name,
                            min: item.properties.min,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;
                    case 16: //Slider Range
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            min: item.properties.min,
                            name:item.properties.name,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;
                    case 17: // Date

                        formItem = {
                            order: item.properties.order,
                            format:item.properties.format,
                            calendarView:item.properties.calendarView.value,
                            calendarType:item.properties.calendarType.value,
                            calendarLanguage:item.properties.calendarLanguage.value,
                            isReadOnly: item.properties.isReadOnly,
                            min: item.properties.min,
                            name:item.properties.name,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value},
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;
                    case 18: //Time
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            format:item.properties.format,
                            min: item.properties.min,
                            name:item.properties.name,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                     break;
                    case 19: //DateTime
                        formItem = {
                            order: item.properties.order,
                            format:item.properties.format,
                            calendarView:item.properties.calendarView.value,
                            calendarType:item.properties.calendarType.value,
                            calendarLanguage:item.properties.calendarLanguage.value,
                            isReadOnly: item.properties.isReadOnly,
                            min: item.properties.min,
                            name:item.properties.name,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value},
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;
                    case 20:  //Url
                        formItem = {
                            order: item.properties.order,
                            name:item.properties.name,
                            isReadOnly: item.properties.isReadOnly,
                            regex: item.properties.regex,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;
                    case 21 : //Color
                        formItem = {
                            order: item.properties.order,
                            isReadOnly: item.properties.isReadOnly,
                            name:item.properties.name,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties.defaultValue.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;
                    case 22: //Password
                        formItem = {
                            order: item.properties.order,
                            difficulty:item.properties.difficulty.value,
                            isReadOnly: item.properties.isReadOnly,
                            name:item.properties.name,
                            min: item.properties.min,
                            max: item.properties.max,
                            isRequired: item.properties.isRequired,
                            caption: item.properties.caption,
                            description: item.properties.description,
                            className: item.properties.className,
                            defaultValue: { value: item.properties?.defaultValue?.value },
                            size: item.properties.size.value,
                            id: item.properties.id,
                        };
                        break;
                }

            return {
                 type: item.type,
                 formItemId: item.formItemId,
                 order: item.properties.order,
                 formItem: formItem
            };
        }),
    };
    return data;
};













export const handleGetJsonElementFromServer = (
    formItems,
    calendarLanguagesData,
    calendarTypesData,
    calendarViewsData,
    sizeParameters
) => {
    return formItems.map(item => {
        const typeMap = {
            0: 'TextBox', 1: 'TextArea', 2: 'Numeric', 3: 'MultiSelect',
            4: 'Select', 5: 'Radio', 6: 'CheckBox', 7: 'SelectionBreak',
            8: 'File', 9: 'Location', 10: 'Email', 11: 'Phone',
            12: 'Image', 13: 'Video', 14: 'Audio', 15: 'Price',
            16: 'Slider', 17: 'Date', 18: 'Time', 19: 'DateTime',
            20: 'Url', 21: 'Color', 22: 'Password',
        };

        const typeName = typeMap[item.type] || '';

        if (!typeName) {
            console.error(`Unknown type for item: ${item.type}`);
            return null; // Skip unknown types
        }

        const exclusiveProperties = handleGetExclusiveProperties(typeName);

        const retrievedSize = sizeParameters?.find(sizeParam => sizeParam.value === item.size) || exclusiveProperties.size;

        const mappedElement = {
            type:item.type,
            typeName: typeName,
            formItemId: item.formItemId,
            properties: {
                ...exclusiveProperties,
                max: item.max ?? exclusiveProperties.max,
                min: item.min ?? exclusiveProperties.min,
                name: item.name ?? exclusiveProperties.name,
                caption: item.caption ?? exclusiveProperties.caption,
                description: item.description ?? exclusiveProperties.description,
                className: item.className ?? exclusiveProperties.className,
                defaultValue: item.defaultValue ?? exclusiveProperties.defaultValue,
                size: retrievedSize,
                order: item.order ?? exclusiveProperties.order,
                isReadOnly: item.isReadOnly ?? exclusiveProperties.isReadOnly,
                isRequired: item.isRequired ?? exclusiveProperties.isRequired,
                id: item.id || exclusiveProperties.id,
            }
        };

        switch (typeName) {
            case 'Select':
            case 'Radio':
                // Handle Select and Radio where defaultValue is a single object
                mappedElement.properties.items = (item.items || exclusiveProperties.items).map(item => ({
                    label: item.key || item.label || '', // Use 'key' if available, otherwise 'label'
                    value: item.value || '',
                    parentId: item.parentId || null,
                    order: item.order || 1
                }));

                if (item.defaultValue && item.defaultValue.value !== undefined) {
                    const foundItem = item.items?.find(x => x.value === item.defaultValue.value) || item.defaultValue;
                    const transformedItem = {
                        label: foundItem.key,
                        value:foundItem.value,
                        parentId:foundItem.parentId,
                        order:foundItem.order
                    };
                    mappedElement.properties.defaultValue = { value: transformedItem };
                }
                break;

            case 'MultiSelect':
            case 'CheckBox':
                // Handle MultiSelect and CheckBox where defaultValue is an array of objects
                mappedElement.properties.items = (item.items || exclusiveProperties.items).map(item => ({
                    label: item.key || item.label || '', // Use 'key' if available, otherwise 'label'
                    value: item.value || '',
                    parentId: item.parentId || null,
                    order: item.order || 1
                }));

                if (Array.isArray(item.defaultValue?.items)) {
                    mappedElement.properties.defaultValue = {
                        value: item.defaultValue.items.map(defaultValueItem => ({
                            label: defaultValueItem, // Set label to the same value as the item
                            value: defaultValueItem,
                            parentId: null,
                            order: 1
                        }))
                    };
                } else {
                    mappedElement.properties.defaultValue = exclusiveProperties.defaultValue;
                }
                break;

            case 'File':
            case 'Image':
            case 'Video':
            case 'Audio':
                mappedElement.properties.folderSource = item.folderSource
                    .replace(new RegExp(`\\${fileManager.separator}`, 'g'), '/')  // Correctly replace the separator with '/'
                    .replace(/\s+/g, '')                                         // Remove all whitespace
                    .trim() || exclusiveProperties.folderSource;
                break;
            case 'Location':


                mappedElement.properties.stateProvinceId = item.stateProvinceId ?? exclusiveProperties.stateProvinceId;
                mappedElement.properties.countryId = item.countryId ?? exclusiveProperties.countryId;
                mappedElement.properties.cityId = item.cityId ?? exclusiveProperties.cityId;
                mappedElement.properties.address = item.address ?? exclusiveProperties.address;

                break;
            case 'Price':
                mappedElement.properties.format = item.format ?? exclusiveProperties.format;
                break;
            case 'Email':
            case 'Url':
                mappedElement.properties.regex = item.regex ?? exclusiveProperties.regex;
                break;
            case 'Phone':

                mappedElement.properties.regex = item.regex ?? exclusiveProperties.regex;
                mappedElement.properties.preNumbers = (item.preNumbers || exclusiveProperties.preNumbers).map(item => ({
                    label: item.key ,
                    value: item.value || '',
                    parentId: item.parentId || null,
                    order: item.order || 1
                }));
                if (Array.isArray(item.properties?.preNumbers)) {
                    mappedElement.properties.preNumbers = item.properties.preNumbers.map(defaultValueItem => ({
                        label: defaultValueItem.key, // Set label to the same value as the item
                        value: defaultValueItem.value,
                        parentId: null,
                        order: defaultValueItem.order || 1
                    }));
                }
                mappedElement.properties.defaultValue = {
                    value: item.defaultValue.value,
                    preNumber: item.preNumbers.find((x)=>x.value === item.defaultValue.preNumber) || null
                };

                break;
            case 'Date':
            case 'DateTime':
                mappedElement.properties.calendarView = calendarViewsData?.find(x => x.value === item.calendarView) || exclusiveProperties.calendarView;
                mappedElement.properties.calendarType = calendarTypesData?.find(x => x.value === item.calendarType) || exclusiveProperties.calendarType;
                mappedElement.properties.calendarLanguage = calendarLanguagesData?.find(x => x.value === item.calendarLanguage) || exclusiveProperties.calendarLanguage;
                mappedElement.properties.format = item.format ?? exclusiveProperties.format;
                mappedElement.properties.defaultValue = item.defaultValue || exclusiveProperties.defaultValue ;
            break;
            case 'Time':
                var currentDate = new Date().toISOString().split('T')[0];
                var fullDateValue = `${currentDate}T${item.defaultValue || exclusiveProperties.defaultValue}`;
                var date = new Date(fullDateValue);
                mappedElement.properties.defaultValue = date ;
                break;
            default:
                break;
        }

        return mappedElement;
    }).filter(Boolean); // Filter out nulls for unknown types
};







const handleGetExclusiveProperties = (typeName) => {
    switch (typeName) {
        case 'TextBox':
            return {
                min: 0,
                max: 0,
                order: 0,
                name: '',
                isReadOnly: false,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: '' },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'TextArea':
            return {
                min: 0,
                max: 0,
                order: 0,
                name: '',
                isReadOnly: false,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: '' },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Numeric':
            return {
                min: 0,
                max: 0,
                order: 0,
                name: '',
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: 0 },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'MultiSelect':
            return {
                order: 0,
                name: '',
                items: [{ label: null, value: null, parentId: null, order: 1 }],
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: [] },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Select':
            return {
                order: 0,
                name: '',
                items: [{ label: null, value: null, parentId: null, order: 1 }],
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'CheckBox':
            return {
                order: 0,
                name: '',
                items: [{ label: null, value: null, parentId: null, order: 1 }],
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Radio':
            return {
                order: 0,
                name: '',
                items: [{ label: null, value: null, parentId: null, order: 1 }],
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'SelectionBreak':
            return {
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'File':
            return {
                isReadOnly: false,
                extensions: '',
                folderSource: '',
                min: 0,
                max: 0,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Location':
            return {
                order: 0,
                isReadOnly: false,
                countries: [],
                states: [],
                cities: [],
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { stateProvinceId: null, countryId: null, cityId: null,address:null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Email':
            return {
                order: 0,
                isReadOnly: false,
                regex: '',
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: '' },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Phone':
            return {
                order: 0,
                isReadOnly: false,
                regex: '',
                isRequired: false,
                preNumbers: [{ label: null, value: null, parentId: null, order: 1 }],
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: '', preNumber: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Image':
            return {
                order: 0,
                isReadOnly: false,
                extensions: '',
                folderSource: '',
                isRequired: false,
                min: 0,
                max: 0,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Video':
            return {
                order: 0,
                isReadOnly: false,
                extensions: '',
                folderSource: '',
                min: 0,
                max: 0,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Audio':
            return {
                order: 0,
                isReadOnly: false,
                extensions: '',
                folderSource: '',
                min: 0,
                max: 0,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Price':
            return {
                order: 0,
                isReadOnly: false,
                format: '',
                max: 0,
                min: 0,
                useGrouping: false,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: 0 },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Slider':
            return {
                order: 0,
                isReadOnly: false,
                max: 0,
                min: 0,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: 0 },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Date':
            return {
                order: 0,
                format: 'YYYY/MM/DD',
                calendarView: null,
                calendarType: null,
                calendarLanguage: null,
                isReadOnly: false,
                max: 0,
                min: 0,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: '' },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: '',
                name: ''
            };
        case 'Time':
            return {
                order: 0,
                format: 'HH:mm:ss',
                isReadOnly: false,
                max: 0,
                min: 0,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: '' },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: '',
                name: ''
            };
        case 'DateTime':
            return {
                order: 0,
                format: 'YYYY/MM/DD HH:mm:ss',
                calendarView: null,
                calendarType: null,
                calendarLanguage: null,
                isReadOnly: false,
                max: 0,
                min: 0,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: '' },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: '',
                name: ''
            };
        case 'Url':
            return {
                order: 0,
                isReadOnly: false,
                regex: '',
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: { value: null },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Color':
            return {
                order: 0,
                isReadOnly: false,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: '' },
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Password':
            return {
                order: 0,
                isReadOnly: false,
                max: 0,
                min: 0,
                isRequired: false,
                caption:'Title',
                description: '',
                className: '',
                defaultValue: { value: '' },
                difficulty: null,
                size: { value: 0, label: 'کوچک', className: 'harmony-col-span-1' },
                id: ''
            };
        default:
            return {};
    }
};

