
import React from "react";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const ItemFile=({index,handleRemove,ref,file,contentType})=>{

    return (<>

        <h2 key={index} className={"box bg-white flex gap-2 justify-between p-2 items-center"}>
            <p className={"text-xs dark:text-gray-300 line-clamp-1"}>{file.name}</p>
            <button type={'button'}  onClick={()=>handleRemove(index,contentType,ref)}>
                <HPrimeIcon icon={'pi-times'}  />
            </button>
        </h2>

    </>)
}