import React from "react";


export const HandleGeneralReservationSetting=()=>{

    return(

        <div class="grid grid-cols-12 ">
            عمومی
        </div>

    )

}