import React, {useState} from "react";
export const useContent=()=>{
    const [errors, setErrors] = useState({});
    const [content,setContent] = useState({id:null,title:null,slug:null,concurrencyStamp:null,oldSlug:null});
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const handleValue = (e) => {
        const { name, value } = e.target;
        setContent((prevContent) => ({ ...prevContent, [name]: value }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    return {
        errors,
        setErrors,
        onSetErrors,
        handleValue,
        content,
        setContent
    }


}