import React, {useEffect, useMemo, useState} from "react";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";

export const useDepositeGrid=(showSpinner,hideSpinner,wallet,currentPath)=>{
    const [rowData, setRowData] = useState([]);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    useEffect(() => {
        // if (wallet.userId !== null) {
        //     OnGridReady();
        // }
        OnGridReady();
    }, [pagination.currentPage,pagination.pageSize,wallet.userId]);

    const OnGridReady = async () => {
        try {
          showSpinner();
            const url = currentPath === `/Profile/Wallet`? `/Wallets/Transactions/Deposit`: `/Wallets/${wallet.userId.value}/Transactions/Deposit`;

            const response =  await GET(url, {PageNumber: pagination.currentPage, PageSize:pagination.pageSize}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
    }
}