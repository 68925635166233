import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../../../Commans/UIParts/MultiDataSelect";
import { InputNumber } from 'primereact/inputnumber';

import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import Select from "react-select";

export const HandleGeneralAccSetting=()=>{
    const [value2, setValue2] = useState(10.50);



    return(

        <div class="space-y-5 ">
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>

                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />

            </div>
                <div className="flex gap-2 items-center justify-between box bg-white dark:bg-themeDarkSalt700 p-3">
                    <div class="pos">
                        <div class="pos__tabs nav-tabs justify-center flex gap-3 post-input dark:bg-themeDarkSalt600 px-2 py-1 rounded">
                            <a data-toggle="tab" data-target="#tab_one" href="javascript:;" className="w-24 px-2 py-2 dark:text-gray-300 rounded-md flex justify-center items-center active">پروفایل</a>
                            <a data-toggle="tab" data-target="#tab_two" href="javascript:;" className="w-24 px-2 py-2 dark:text-gray-300 rounded-md flex justify-center items-center">کسب وکار</a>
                            <a data-toggle="tab" data-target="#tab_three" href="javascript:;" className="w-24 px-2 py-2 dark:text-gray-300 rounded-md flex justify-center items-center">حسابداری</a>
                        </div>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="tab-content__pane space-y-5 box bg-white dark:bg-themeDarkSalt700  active" id="tab_one">
                        <div class="grid grid-cols-12 gap-5 p-3">
                            <div class="col-span-12 lg:col-span-8">
                                <div class="grid grid-cols-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md gap-5">
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">نام:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">نام خانوادگی:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300"> ایمیل*</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">تلفن همراه *</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4">
                                <div className="w-full h-32 lg:h-full ">
                                        <UploadImage />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content__pane space-y-5 box bg-white dark:bg-themeDarkSalt700  " id="tab_two">
                        <div class="grid grid-cols-12 gap-2">
                            <div class=" col-span-12 lg:col-span-8 space-y-3 p-3">
                                <div class="post-input dark:bg-themeDarkPostInput rounded-md border dark:border-themeDarkColorLine">
                                    <div className="p-2 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md">
                                        <p>اطلاعات کسب وکار</p>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 p-3 gap-5">
                                        <div class="space-y-2">
                                            <label className="text-xs dark:text-gray-300">نام کسب و کار</label>
                                            <InputText type={"text"} className={"input w-full"} />
                                        </div>
                                        <div class="space-y-2">
                                            <label className="text-xs dark:text-gray-300">نام قانونی</label>
                                            <InputText type={"text"} className={"input w-full"} />
                                        </div>
                                        <div class="space-y-2">
                                            <label className="text-xs dark:text-gray-300"> نوع کسب و کار</label>
                                            <MultiDataSelect placeholder={'انتخاب'} />
                                        </div>
                                        <div class="space-y-2">
                                            <label className="text-xs dark:text-gray-300">زمینه فعالیت</label>
                                            <InputText type={"text"} className={"input w-full"} />
                                        </div>
                                    </div>
                                </div>
                                <div class=" post-input dark:bg-themeDarkPostInput rounded-md border dark:border-themeDarkColorLine">
                                    <div className="p-2 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md">
                                        <p >اطلاعات اقتصادی</p>
                                    </div>
                                    <div className="grid grid-cols-2 p-3 gap-5">
                                        <div className="space-y-2">
                                            <label className="text-xs dark:text-gray-300">کد اقتصادی</label>
                                            <InputText type={"text"} className={"input w-full"} />
                                        </div>
                                        <div className="space-y-2">
                                            <label className="text-xs dark:text-gray-300">شناسه ملی</label>
                                            <InputText type={"text"} className={"input w-full"} />
                                        </div>
                                        <div className="space-y-2">
                                            <label className="text-xs dark:text-gray-300">شماره ثبت</label>
                                            <InputText type={"text"} className={"input w-full"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4">
                                <div class="relative p-3">
                                    <div class="post-input dark:bg-themeDarkPostInput rounded-md border dark:border-themeDarkColorLine">
                                        <div className="p-2 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md">
                                            <p>اطلاعات تماس</p>
                                        </div>
                                        <div class="grid grid-cols-1 md:grid-cols-2 gap-2 p-3">
                                            <div class="space-y-2">
                                                <label className="text-xs dark:text-gray-300">استان</label>
                                                <Select placeholder={'انتخاب'} classNamePrefix={"h_multi_select"} />
                                                {/*<select name="" id="" className="select2 w-full">*/}
                                                {/*    <option value="">اصفهان</option>*/}
                                                {/*</select>*/}
                                            </div>
                                            <div class="space-y-2">
                                                <label className="text-xs dark:text-gray-300">شهر</label>
                                                <Select placeholder={'انتخاب'} classNamePrefix={"h_multi_select"} />
                                                {/*<select name="" id="" className="select2 w-full">*/}
                                                {/*    <option value="">کاشان</option>*/}
                                                {/*</select>*/}
                                            </div>
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">تلفن</label>
                                                <InputText type={"text"} className={"input w-full"} />
                                            </div>
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">کدپستی</label>
                                                <InputText type={"text"} className={"input w-full"} />
                                            </div>
                                            <div class="space-y-2 col-span-1 md:col-span-2">
                                                <label className="text-xs dark:text-gray-300">آدرس</label>
                                                <textarea name="" id="" className="w-full input border"></textarea>
                                            </div>
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">ایمیل</label>
                                                <InputText type={"text"} className={"input w-full"} />
                                            </div>
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">وبسایت</label>
                                                <InputText type={"text"} className={"input w-full"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content__pane space-y-5 box bg-white dark:bg-themeDarkSalt700  " id="tab_three">
                        <div class="grid grid-cols-12 gap-2">
                            <div class=" col-span-12 lg:col-span-8 space-y-3 p-3">
                                <div className="post-input dark:bg-themeDarkPostInput rounded-md overflow-hidden border dark:border-themeDarkColorLine">
                                    <div className="p-3 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300">
                                        <p>سیستم انبارداری</p>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-2">
                                        <div className="space-y-2">
                                            <label className="text-xs dark:text-gray-300">سیستم حسابداری انبار</label>
                                            <Select placeholder={'انتخاب'} classNamePrefix={"h_multi_select"} />
                                            {/*<select name="" id="" className="select2 w-full">*/}
                                            {/*    <option value="">ادواری</option>*/}
                                            {/*</select>*/}
                                        </div>
                                        <div className="space-y-2">
                                            <label className="text-xs dark:text-gray-300">روش ارزشیابی انبار</label>
                                            <Select placeholder={'انتخاب'} classNamePrefix={"h_multi_select"} />
                                            {/*<select name="" id="" className="select2 w-full">*/}
                                            {/*    <option value="">fifo</option>*/}
                                            {/*</select>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput rounded-md overflow-hidden border dark:border-themeDarkColorLine">
                                    <div className=" p-3 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300">
                                        <p>سیستم چند ارزی</p>
                                    </div>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 p-2">
                                        <div class="space-y-2">
                                            <label className="text-xs dark:text-gray-300">واحد پول اصلی</label>
                                            <Select placeholder={'انتخاب'} classNamePrefix={"h_multi_select"} />
                                            {/*<select name="" id="" className="select2 w-full">*/}
                                            {/*    <option value="">ریال</option>*/}
                                            {/*    <option value="">دلار</option>*/}
                                            {/*    <option value="">دینار</option>*/}
                                            {/*</select>*/}
                                        </div>
                                        <div class="space-y-2">
                                            <label className="text-xs dark:text-gray-300">واحد پول فرعی</label>
                                            <Select placeholder={'انتخاب'} classNamePrefix={"h_multi_select"} />
                                            {/*<select name="" id="" className="select2 w-full">*/}
                                            {/*    <option value="">fifo</option>*/}
                                            {/*</select>*/}
                                        </div>
                                    </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput rounded-md overflow-hidden border dark:border-themeDarkColorLine">
                                    <div className=" p-3 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300">
                                        <p>سیستم مالیات</p>
                                    </div>
                                    <div class="grid grid-cols-1 md:grid-cols-2 gap-5 p-2">
                                        <div className="">
                                            <label className="text-xs dark:text-gray-300">مالیات بر ارزش افزوده</label>
                                            <InputNumber inputId="horizontal-buttons" value={value2} onValueChange={(e) => setValue2(e.value)} showButtons buttonLayout="horizontal" step={0.25} decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                                         mode="currency" currency="EUR" />
                                        </div>
                                        <div>
                                            <div class="">
                                                <h2 class="text-xs dark:text-gray-300">عوارض</h2>
                                            </div>
                                            <InputNumber inputId="horizontal-buttons" value={value2} onValueChange={(e) => setValue2(e.value)} showButtons buttonLayout="horizontal" step={0.25} decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                                         mode="currency" currency="EUR" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4">
                                <div class="p-3">
                                    <div class="post-input dark:bg-themeDarkPostInput rounded-md overflow-hidden">
                                        <div className=" p-3 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300">
                                            <p>سالی مالی</p>
                                        </div>
                                        <div class="grid grid-cols-2 gap-2 p-2">
                                            <div class="col-span-2 space-y-2">
                                                <label className="text-xs dark:text-gray-300">عنون سال مالی</label>
                                                <InputText type={"text"} className={"input w-full"} />
                                            </div>
                                            <div class="col-span-2 lg:col-span-1 space-y-2">
                                                <label className="text-xs dark:text-gray-300">تاریخ شروع</label>
                                                <InputText type={"text"} className={"input w-full"} />
                                            </div>
                                            <div class="col-span-2 lg:col-span-1 space-y-2">
                                                <label className="text-xs dark:text-gray-300">تاریخ پایان</label>
                                                <InputText type={"text"} className={"input w-full"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" flex justify-center lg:justify-end gap-3 ">
                    <button className="w-24 button bg-themeDanger text-white ">
                        انصراف
                    </button>
                    <button className="w-24 button bg-themeInformation dark:bg-themeDarkInformation text-white  ">
                        ثبت
                    </button>
                </div>
            </div>
    )

}