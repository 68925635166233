import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ListSearch } from "../../Commans/Grid/ListSearch";
import { InputText } from "primereact/inputtext";
import { ButtonSearchList } from "../../Commans/UIParts/ButtonSearchList";
import { ListFilter } from "../../Commans/Grid/ListFilter";
import { GridTable } from "../../Commans/Grid/GridTable";
import { FantasyModal } from "../../Commans/UIParts/FantasyModal";
import { ButtonPlus } from "../../Commans/UIParts/ButtonPlus";
import { Export } from "../../Commans/Grid/Export";
import { Pagination } from "../../Commans/Grid/Pagination";
import { POST, PUT } from "../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import { usePackageGroupGrid } from "./usePackageGroupGrid";
import { GridSetting } from "../../../Services/Globals/Public/AppSetting";
import { AgGridEnglish } from "../../../Services/Globals/Translations/Resources";
import { usePackageGroup } from "./usePackageGroup";
import { packageGroupSchema } from "./packageGroupSchema";
import { DisplayError } from "../../Commans/Errors/DisplayError";
import { validate } from "../../../Services/Globals/validate";
import { ModalSubmitButton } from "../../Commans/UIParts/ModalSubmitButton";
import { LoadingHarmony } from "../../Commans/Loadings/LoadingHarmony";
import { handleSuccessMessage } from "../../../Services/Globals/Errors/NotificationService";
import { ToastContainer } from "react-toastify";
import { handleAddPackageGroup, handleEditPackageGroup } from "./packageGroupService";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";

export const HandlePackageGroup = () => {
    const { t } = useTranslation();
    const [isEdit, setIsEdit] = useState(false);
    const [IsOpen, setIsOpen] = useState(false);
    const [IsOpenMenu, setIsOpenMenu] = useState(false);
    const [IsShowSpinner, setIsShowSpinner] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [errors, setErrors] = useState({});
    const [IsBtnLoading, setIsBtnLoading] = useState(false);




    const { packageGroup, setPackageGroup, handleValue } = usePackageGroup(setErrors);

    const showBtnLoading = () => setIsBtnLoading(true)
    const hideBtnLoading = () => setIsBtnLoading(false)


    const handleOpenMenu = (packageGroupId) => {
        setPackageGroup({ ...packageGroup, packageGroupId: packageGroupId });
        setIsOpenMenu(true)
    }


    const onSetErrors = (errors) => {
        setErrors(errors)
    }
    const Refresh = () => {
        setRefresh((prevstate) => !prevstate);
    }
    const handleResetErros = () => {
        setErrors({});
    }
    const showSpinner = () => {
        setIsShowSpinner(true)
    }
    const hideSpinner = () => {
        setIsShowSpinner(false);
    }

    const handleOpenModalPackageGroup = () => {
        setIsOpen(true)
    }

    const handleCloseModalPackageGroup = () => {
        handleResetErros();
        setPackageGroup({ name: '' })
        setIsEdit(false);
        setIsOpen(false)
    }


    const handelPackageGroupSubmit = async (e) => {
        e.preventDefault();
        const isValid = validate(packageGroupSchema, packageGroup, onSetErrors);
        if (!isValid) {
            return;
        }

        try {
            if (isEdit) {
                showBtnLoading();
                const packageGroupData = handleEditPackageGroup(packageGroup);
                const res = await PUT('/PackageGroups', packageGroupData, false)
                if (res.status === 200) {
                    hideBtnLoading();
                    handleCloseModalPackageGroup();
                    Refresh();
                    handleSuccessMessage(t('SuccessDone'));
                }
                else {
                    handleApiError(res, hideBtnLoading);
                }
            } else {
                showBtnLoading();
                const packageGroupData = handleAddPackageGroup(packageGroup);
                const res = await POST('/PackageGroups', packageGroupData, false)
                if (res.status === 200) {
                    hideBtnLoading();
                    handleCloseModalPackageGroup();
                    Refresh();
                    handleSuccessMessage(t('SuccessDone'));
                }
                else {
                    handleApiError(res, hideBtnLoading);
                }
            }
        } catch (e) {
            hideBtnLoading();
        }
    }

    // handel grid
    const { handleNextPage, handleCurrentPage, handleSearch, handlePreviousPage, handlePageSizeCount, handleResetFilters, filter, setFilter, columnDefs, autoGroupColumnDef, defaultColDef, rowData, getRowHeight, pagination } = usePackageGroupGrid(showSpinner, hideSpinner, setIsOpen, setIsEdit, refresh, Refresh, setPackageGroup, handleOpenMenu);


    return (

        <>
            <div class="relative space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                            <InputText onChange={(e) => setFilter(() => ({ ...filter, searchValue: e.target.value }))} value={filter.searchValue} onKeyDown={(e) => e.key === 'Enter' ? setFilter(() => ({ ...filter, search: e.target.value })) : null} type={"search"} className={" input w-full"} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={() => handleSearch()}
                            onClickReset={() => handleResetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                        childrenOne={
                            <>
                                <ButtonPlus
                                    onClick={() => handleOpenModalPackageGroup()}
                                    tooltipTitle={t("GroupCreation")}
                                />
                            </>
                        }

                    />


                </div>
                <div className="H-body-ag-grid space-y-2">
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount} />
                </div>
                <Export />
            </div>

            <FantasyModal openModal={IsOpen} closeModal={handleCloseModalPackageGroup} title={t("GroupCreation")}>
                <form onSubmit={handelPackageGroupSubmit} method="Post">
                    <div className={"space-y-5"}>
                        <div className="space-y-2">
                            <div className={"flex gap-2 items-center justify-between"}>
                                <label className="text-xs dark:text-gray-300">
                                    {t("Category")}
                                    <RequiredIcon/>
                                </label>
                                <DisplayError message={errors.name} />
                            </div>

                            <InputText value={packageGroup.name} name={'name'} placeholder={t("selection")} classNamePrefix={"h_multi_select"} onInput={(e) => handleValue(e)} />
                        </div>
                        <div>
                            <ModalSubmitButton
                                IsBtnLoading={IsBtnLoading}
                            />
                        </div>
                    </div>
                </form>
            </FantasyModal>
            <ToastContainer />
        </>



    )
}