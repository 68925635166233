import React from 'react';

const LinkedInIcon = ({sizeStyle,fillStyle}) => (

    <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube"  className={`${sizeStyle ? sizeStyle:"w-10 h-10"} ${fillStyle ? fillStyle:"text-themeYouTube"} dark:text-gray-300 `}  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
        <path fill="currentColor" d="M549.7 124.1c-6.281-23.65-24.79-42.28-48.28-48.6C458.8 64 288 64 288 64S117.2 64 74.63 75.49c-23.5 6.322-42 24.95-48.28 48.6-11.41 42.87-11.41 132.3-11.41 132.3s0 89.44 11.41 132.3c6.281 23.65 24.79 41.5 48.28 47.82C117.2 448 288 448 288 448s170.8 0 213.4-11.49c23.5-6.321 42-24.17 48.28-47.82 11.41-42.87 11.41-132.3 11.41-132.3s0-89.44-11.41-132.3zm-317.5 213.5V175.2l142.7 81.21-142.7 81.2z"></path>
    </svg>
);

export default LinkedInIcon;
