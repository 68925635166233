import React, {useState} from "react";

export const useCustomCommunication=(setErrors)=>{
    const [comminicatiion,setCommunication]=useState({title:null,order:0,description:null,stateShow:true});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setCommunication({...comminicatiion,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setCommunication({...comminicatiion,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleStateShow=(name,value)=>{
        setCommunication({...comminicatiion,[name]:value});
    }

    const handleSelectValue=(data,name)=>{
        setCommunication({...comminicatiion,[name]:data});

    }
    return {
        handleValue,
        handleNumberValue,
        handleSelectValue,
        comminicatiion,
        setCommunication,
        handleStateShow
    }
}