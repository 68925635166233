import React, {useMemo, useState} from "react";
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../Commans/Grid/ListFilter";

import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
export const HandleUserInteractions = ()=>{
    const { t } = useTranslation();
    const events = [
        { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
        { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
        { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
        { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
    ];

    const customizedMarker = (item) => {
        return (
            <span className="flex size-10 items-center justify-center text-white border z-1 shadow rounded" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const customizedContent = (item) => {

        return (
            <Card title={item.status} subTitle={item.date}>
                { item.image && <img src={`https://primefaces.org/cdn/primereact/images/product/${item.image}`} alt={item.name} width={200} className="shadow" />}
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!</p>
                <Button label="Read more" className="text-themeInformation dark:text-themeDarkInformation"></Button>
            </Card>
        );
    };



    return (
        <>
            <div class="space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                        <ListSearch label={null} icon={null} >
                            <div class="space-y-2 ">
                                <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                                <InputText type={"text"} className={" input w-full"} />
                            </div>
                            <div class=" space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("FromDate")}</label>
                                <InputText type={"text"} className={" input w-full"} />
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("ToDate")}</label >
                                <InputText type={"text"} className={" input w-full"} />
                            </div>
                            <ButtonSearchList
                            />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <div class=" space-y-2 flex flex-col items-center">
                                            <lable className="text-xs dark:text-gray-300">**</lable>
                                            <input className="input input--switch border bg-white" type="checkbox"/>
                                        </div>

                                    </>
                                }
                    />



                </div>
                <h2>از قسمت note , reminder ,survey اطلاعات می آید</h2>
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 ">
                    <Timeline value={events} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                </div>
            </div>

        </>

    )
}