import React, {useState} from "react";
import {useDynamicDispatch} from "../../../../../Services/Redux/useDynamicaDispatch";

export const useDashboard=(setErrors)=>{
    const handleGetValueTheme=(theme)=>{
        switch (theme) {
            case 0:
                return false
            case 1:
                return true
            default:
                return false
        }
    }

    const [setting, setSetting] = useState({
        fontFamily: null,
        fontSize: 0,
        editor: 0,
        theme: 0,
        dashboard:null,
        concurrencyStamp:null
    });
     const handleGetDashboards = (dashboards)=>{
        const dashboardsData  = dashboards.map(item => ({label: item.value, value: item.key,picture:item.picture}));
        return dashboardsData;
    }

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }))
    }
    const handleSelectValue=(data,name)=>{
         setSetting({...setting,[name]: data});
    }

    return {
        setting,
        setSetting,
        handleValue,
        handleNumberValue,
        handleSelectValue,
        handleGetDashboards,
        handleGetValueTheme
    }
}