import React, {useEffect, useMemo, useState} from "react";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../../../Commans/Grid/GridButtonOprations";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {Image} from "primereact/image";
import {Tooltip} from "react-tooltip";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";

export const useContentGrid=(showSpinner, hideSpinner, refresh, Refresh, handleOpenMenu, handleOpenContent)=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [rowContents,setRowContents]=useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [colId,setColdId] = useState(false);
    const [headerName,setHeaderName] = useState('');
    const [filter,setFilter]=useState({search:'',searchValue:'',hasForm: null,isPublished: null,hasTable: null,isDraft: null,inPublicationQueue:null,singlePage: null,hasTemplate:null,isInMainPage:null,contentGroupIds:[],filterId:null});
    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const showLoading=()=>setIsLoading(true)
    const hideLoading=()=>setIsLoading(false)

    const handleDeleteContent = async (contentId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                   showSpinner();
                    const response = await DELETE(`/Contents/${contentId}`, {headers: { 'Content-Type': 'application/json' }});
                    if (response.status === 200) {
                       hideSpinner();
                        Refresh();
                        handleSuccessMessage(t('SuccessDone'))
                    }
                    else {
                        handleApiError(response, hideSpinner)
                    }
                }catch (e) {
                    hideSpinner()
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const handleChangeStateShow=async (contentId,rowName,concurrencyStamp)=>{
        try {
            showLoading();
            setHeaderName(rowName);
            setColdId(contentId)
            const response = await PUT('/Contents/EditActivation', { id: contentId,concurrencyStamp:concurrencyStamp },false);
            if (response.status === 200) {
                hideLoading();
                Refresh();
                handleSuccessMessage(t('SuccessDone'));
            }else{
               hideLoading();
               handleApiError(response,hideLoading);
            }
        }catch (e) {
            hideLoading();
        }
    }
    //در صفحه اصلی باشه یا نه
    const handleChangeMasterDisplay = async(contentId,rowName,concurrencyStamp)=>{
        try {
            showLoading();
            setHeaderName(rowName);
            setColdId(contentId)
            const response = await PUT('Contents/EditDisplayOrderMasterActivation', { id: contentId,concurrencyStamp:concurrencyStamp });
            if (response.status === 200) {
                hideLoading();
                Refresh();
                handleSuccessMessage(t('SuccessDone'));
            }else{
                hideLoading()
                handleApiError(response,hideLoading)
            }
        }catch (e) {
            hideLoading()
        }
    }

    const handleChangeOrderSlave = async (e)=>{
        try {
            showSpinner();
            const {id,displayOrderSlave,concurrencyStamp} = e.data;
            const response = await PUT('Contents/EditDisplayOrderSlave', { id: id,Order:displayOrderSlave,concurrencyStamp:concurrencyStamp })
            if(response.status === 200){
                Refresh();
                hideSpinner();
                handleSuccessMessage(t('SuccessDone'));
            }
        }catch (e) {
            hideSpinner();
        }
    }





    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, minWidth: 120},

        { headerName: t("Image"), maxWidth: 140,
            cellRenderer:parameters=>{
                return <Image src={parameters.data.thumbnail} zoomSrc={parameters.data.thumbnail} alt="Image"  preview />
            }
        },

        { headerName: t("ArticleTitle"), field: 'title', minWidth:300,cellRenderer:params =>

                <div className="cursor-pointer" onClick={ ()=> handleOpenContent(params.data.id,params.data.title,params.data.slug,params.data.concurrencyStamp)}   >
                    <span className={"line-clamp-1 text-themeInformation dark:text-themeDarkInformation tooltip_class_Edit"}>
                        {params.data.title}
                    </span>
                    <Tooltip anchorSelect={".tooltip_class_Edit"} content={t("EditArticleTitle")} positionStrategy={"fixed"}	 />
                </div>
        },

        { headerName: t("CreationDate"),minWidth: 170,
            cellRenderer:params => {
                return <>  <GridDateFomartter date={params.data.createdOn} hasTime={true} /></>

            }
        },
        { headerName: t("PublicationDate"), minWidth: 170,field: 'url',
            cellRenderer:params => {
                return <><GridDateFomartter date={params.data.releaseDateTime} hasTime={true} /></>

            }
        },



        { headerName: t("VisitCount"), field: 'visitCount', minWidth: 140,},

        { headerName: t("Author"), field: 'authorFullName', minWidth: 140,},


        { headerName: t("Homepage"), minWidth: 120 ,field: 'displayOrderMaster',cellRenderer:params =>
                <div className={"relative"}>
                    {isLoading &&  headerName === params.colDef.headerName && colId === params.data.id ?(
                        <TinyLoading  />
                    ) : params.data.displayOrderMaster ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                            onClick={async () => await handleChangeMasterDisplay(params.data.id,params.colDef.headerName,params.data.concurrencyStamp)}
                        />
                    ) : (
                        <HPrimeIcon
                            onClick={async () => await handleChangeMasterDisplay(params.data.id,params.colDef.headerName,params.data.concurrencyStamp)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>

        },

        { headerName: t("DisplayPriority"),field: 'stateShow',minWidth: 130, cellRenderer:params=>

                <div className={"relative"}>
                    {isLoading && headerName === params.colDef.headerName && colId === params.data.id? (
                        <TinyLoading  />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                            onClick={async () => await handleChangeStateShow(params.data.id,params.colDef.headerName,params.data.concurrencyStamp)}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeStateShow(params.data.id,params.colDef.headerName,params.data.concurrencyStamp)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>

        },
        { headerName: t("DisplayOrder"),field: 'displayOrderSlave', minWidth: 130,editable:true},




        { headerName: t("operations"),maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={()=>redirectTo(`/CMS/Content/Add?contentId=${params.data.id}&isEdit=${true}`)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteContent(params.data.id)}
                    />

                    <GridButtonOprations
                        onClick={()=>handleOpenMenu(params.data.id,params.data.concurrencyStamp)}
                    />

                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    // Pagination handel
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    // Search handel
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: prevState.searchValue}));
    }
    const handleSwitchFilters = (data, name) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: data ? data : null
        }));
    }

    const switchFilters = [
        {name:'isInMainPage',lable:t("Homepage"),value:filter.isInMainPage},
        {name:'hasForm',lable:t("WithForm"),value:filter.hasForm},
        {name:'hasTemplate',lable:t("WithTemplate"),value:filter.hasTemplate},
        {name:'hasTable',lable:t("WithTable"),value:filter.hasTable},
        {name:'singlePage',lable:t("StandalonePage"),value:filter.singlePage},
        {name:'inPublicationQueue',lable:t("ReleaseQueue"),value:filter.inPublicationQueue},
        {name:'isPublished',lable:t("Published"),value:filter.isPublished},
    ]


    const handleResetFilters = () => {
        setFilter(prevFilter => ({
            ...prevFilter,
            search: '',
            searchValue: '',
            singlePage: null,
            hasTemplate: null,
            contentGroupIds: [],
            hasForm: null,
            hasTable: null,
            isDraft: null,
            isPublished: null,
            isInMainPage: null,
            inPublicationQueue: null,
            filterId: null,

        }));
    }

    const handleSelectFilterContentGroups = (data)=>{
        setFilter(prevFilter => ({
            ...prevFilter,
            contentGroupIds: data
        }));
    }

    const handleSelectFilterValue = (data,name)=>{
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: data
        }));
    }



    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.singlePage,filter.isInMainPage,filter.hasForm,filter.hasTemplate,filter.hasTable,filter.inPublicationQueue,filter.isPublished,filter.contentGroupIds,filter.filterId]);


    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Contents`,
                {
                    PageNumber: pagination.currentPage,
                     PageSize: pagination.pageSize,
                     Search:filter.search,
                    ContentGroupIds:filter.contentGroupIds.length>0?filter.contentGroupIds.map(groupId => groupId.value).join('&'):filter.contentGroupIds,
                    IsDraft:filter.isDraft,
                    IsInMainPage:filter.isInMainPage,
                    HasForm:filter.hasForm,
                    HasTemplate:filter.hasTemplate,
                    HasTable:filter.hasTable,
                    SinglePage:filter.singlePage,
                    InPublicationQueue:filter.inPublicationQueue,
                    IsPublished:filter.isPublished,
                    FilterId:filter.filterId !== null?filter.filterId?.value:null
        }, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const items = Paginate(response.items);
                setRowContents(items);
            }
            else {
                handleApiError(response,hideSpinner);
            }
        } catch (error) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };

    return {
        handleCurrentPage,
        handleNextPage,
        handleSelectFilterContentGroups,
        handleSelectFilterValue,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteContent,
        handleSearch,
        handleResetFilters,
        columnDefs,
        autoGroupColumnDef,
        switchFilters,
        handleSwitchFilters,
        handleChangeOrderSlave,
        getRowHeight,
        defaultColDef,
        rowContents,
        pagination,
        filter,
        setFilter,

    }

}