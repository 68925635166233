const containsEmptyObject = (arr) => {
    return arr.some(obj => Object.keys(obj).length === 0 && obj.constructor === Object);
};

export const handleCreateShopSettings = (obj, locationList) => {
    const FilteredfreeTransportations =  locationList.filter(loc => loc.countryId !== null && loc.countryId !== "");
    const freeTransportations = FilteredfreeTransportations.map((loc) => ({
            countryId: loc.countryId === null || loc.countryId === undefined?null:loc.countryId.value,
            stateProvinceId: loc.stateProvinceId === null || loc.stateProvinceId === undefined?null:loc.stateProvinceId.value,
            cityId: loc.cityId === null || loc.cityId === undefined?null:loc.cityId.value,
        }));
    const data = {
        productCartLifeTime: {
            timeUnit: obj.productCartLifeTime.timeUnit === null || obj.productCartLifeTime.timeUnit === undefined ? 0 : obj.productCartLifeTime.timeUnit.value,
            value: obj.productCartLifeTime.value ?? 0
        },
        paymentLifeTime: {
            timeUnit: obj.paymentLifeTime.timeUnit === null || obj.paymentLifeTime.timeUnit === undefined ? 0 : obj.paymentLifeTime.timeUnit.value,
            value: obj.paymentLifeTime.value ?? 0
        },
        productReturnPeriod: {
            timeUnit: obj.productReturnPeriod.timeUnit === null || obj.productReturnPeriod.timeUnit === undefined ? 0 : obj.productReturnPeriod.timeUnit.value,
            value: obj.productReturnPeriod.value ?? 0
        },
        maximumPurchaseLimitation: {
            valueType: obj.maximumPurchaseLimitation.valueType === null || obj.maximumPurchaseLimitation.valueType === undefined ? 0 : obj.maximumPurchaseLimitation.valueType.value,
            value: obj.maximumPurchaseLimitation.value ?? 0
        },
        minimumPurchaseLimitation: {
            valueType: obj.minimumPurchaseLimitation.valueType === null || obj.minimumPurchaseLimitation.valueType === undefined ? 0 : obj.minimumPurchaseLimitation.valueType.value,
            value: obj.minimumPurchaseLimitation.value ?? 0
        },
        productCodeDigitCount: obj.productCodeDigitCount ?? 7,
        freeTransportations: freeTransportations,
        isTaxActive: obj.isTaxActive,
        tax: obj.tax ?? 0,
        toll: obj.toll ?? 0
    };
    return data;
};
export const handleReverseConvertToKeyValue=(settingsArray)=> {
    const obj = settingsArray.reduce((acc, setting) => {
        const { name, value } = setting;
        let parsedValue;
        if (value === "true") {
            parsedValue = true;
        } else if (value === "false") {
            parsedValue = false;
        } else if (!isNaN(value)) {
            parsedValue = Number(value);
        }
        else {
            parsedValue = value;
        }
        acc[name] = parsedValue;
        return acc;
    }, {});
    return obj;
}
