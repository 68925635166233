export function handleAddPackageGroup(obj){
    const data={
        name:obj.name === null || obj.name === undefined || obj.name === ''?null:obj.name,
    }
    return data;
}
export function handleEditPackageGroup(obj){
    const data={
        packageGroupId:obj.packageGroupId,
        name:obj.name === null || obj.name === undefined || obj.name === ''?null:obj.name,
        concurrencyStamp:obj.concurrencyStamp === null || obj.concurrencyStamp === undefined?null:obj.concurrencyStamp,
    }
    return data;
}

export const handelConverPackageGroupRoles = (data)=>{
    const options  = data.map(item => ({name: item.value, id: item.key}));
    return options;
}