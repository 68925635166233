import Joi from "joi";

// Regular expression for validating Iranian phone numbers

export const signupSchema = Joi.object({
    password: Joi.string().required().messages({
        'any.required': 'کلمه عبور الزامیست',
        'string.empty': 'کلمه عبور الزامیست',
        'string.base':'کلمه عبور الزامیست'
    }),
    phoneNumber: Joi.string().allow('').allow(null)
        .pattern(/^0[0-9]{2,}[0-9]{7,}$/)
        .messages({
            'string.pattern.base': 'فرمت شماره ثابت صحیح نمیباشد',
        }),
    fullName: Joi.string()
        .required()
        .messages({
            'any.required': 'نام و نام خانوادگی الزامیست',
            'string.empty': 'نام و نام خانوادگی الزامیست',
            'string.base': 'نام و نام خانوادگی الزامیست',
        }),
    userName: Joi.string()
        .required()
        .messages({
            'any.required': 'نام کاربری الزامیست',
            'string.empty': 'نام کاربری الزامیست',
            'string.base': 'نام کاربری الزامیست' ,
        }),
});
