import React, {useState} from "react";


import {InputText} from "primereact/inputtext";

import {ListFilter} from "../../../Commans/Grid/ListFilter";

import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {Accordion, AccordionTab} from "primereact/accordion";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";



export const HandleHRMPersonnelRules = () => {
    const { t } = useTranslation();

    const redirectTo = useNavigate();


    const [dates, setDates] = useState([]);





    return(
        <>
            <div class="space-y-5 ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Personnel")}
                                            onClick={()=>redirectTo(`/Attendance/Personnel`)}
                                            tooltipTitle={t("Personnel")}
                                            icon={"users"}
                                        />

                                        <ButtonPlus
                                            icon={"plus-square"}
                                            title={t("Device")}
                                            onClick={()=>redirectTo("/Attendance/Add")}
                                            tooltipTitle={t("RegisterDevice")}
                                        />

                                        <ButtonPlus
                                            title={t("FinancialRules")}
                                            icon={"book"}
                                            onClick={()=>redirectTo("/Attendance/FinancialRules")}
                                            tooltipTitle={t("FinancialRules")}
                                        />

                                        <ButtonPlus
                                            title={t("Leave")}
                                            primIcon={"pi-calendar-clock"}
                                            onClick={()=>redirectTo("/Attendance/LeaveAndMission")}
                                            tooltipTitle={t("LeaveAndMission")}
                                        />

                                        <ButtonPlus
                                            title={t("Shift")}
                                            icon={"layers"}
                                            onClick={()=>redirectTo("/Attendance/ShiftWork/List")}
                                            tooltipTitle={t("ShiftWork")}
                                        />

                                        <ButtonPlus
                                            title={t("WorkGroup")}
                                            icon={"users"}
                                            onClick={()=>redirectTo("/Attendance/WorkGroup/List")}
                                            tooltipTitle={t("WorkGroup")}
                                        />

                                        <ButtonPlus
                                            title={t("archive")}
                                            icon={"archive"}
                                            onClick={()=>redirectTo("/Attendance/Archive")}
                                            tooltipTitle={t("ArchiveLeaveAndMission")}
                                        />


                                        <ButtonPlus
                                            title={t("Reports")}
                                            primIcon={"pi-chart-bar"}
                                            onClick={()=>redirectTo("/Attendance/List/Reports")}
                                            tooltipTitle={t("GeneralReport")}
                                        />


                                    </>
                                }

                    />
                </div>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5"}>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("DailyWage")}</label>
                                <InputText className="input w-full" />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("HourlyWage")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("SeverancePay")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("ChildAllowance")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("PersonnelInsuranceContribution")}</label>
                                <InputText className="input w-full" />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("EmployersInsuranceContribution")}</label>
                                <InputText className="input w-full" />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("UnemploymentInsuranceContribution")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("SupplementaryInsuranceContribution")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("SalaryTax")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("حق مدیریت")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("حق سرپرستی")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("راندمان")}</label>
                                <InputText className="input w-full"  />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Accordion activeIndex={0} className={"bg-white dark:bg-themeDarkSalt700"}>
                            <AccordionTab
                                header={
                                    <div className="flex items-center gap-2">
                                        <HPrimeIcon icon={'pi-user'} />
                                        <p>{t("DefineCustomRules")}</p>
                                    </div>
                                }
                            >
                                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 max_height85vh">
                                    {/*این قسمت از تنظیمات خوانده و نمایش داده میشود*/}
                                    <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                        <div class="space-y-1">
                                            <label className={"text-xs dark:text-gray-300"}>{t("DailyWage")}</label>
                                            <InputText className="input w-full" />
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
                <ButtonSubmitAndCancel
                />
        </div>

        </>

  )
}