import React, {useMemo, useState} from "react";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {useTranslation} from "react-i18next";
import {Export} from "../../../Commans/Grid/Export";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {useGrid} from "./useGrid";
import Select from "react-select";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";



export const HarmonyTicketGrid=()=>{

    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const {spinner,options,filter,autoGroupColumnDef,data,columnDefs,currentPage,defaultColDef,filterValueHandler,handleFilterValue,handleCurrentPage,handleNextPage,handlePreviousPage,handleSearch,handlePageSizeCount,resetFilters,rowData,getRowHeight,onSelectionChanged,selectedRows,setCurrentPage,setFilter,setData,switchData,setSearch,search} = useGrid()

    const[btnLoading,setBtnLoading] = useState(false);


    const handleBtnLoading=(data)=>{
        setBtnLoading(data);
    }
    const onApiError = ()=>{
        setBtnLoading(false);
    }


    return (
        <>
            <div className="space-y-5">
                    <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                        <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByNumber")}</label>
                                <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("TicketDepartment")}</label>
                                <Select
                                    options={options.departments}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleFilterValue(data,'DepartmentId')}
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>

                            <ButtonSearchList
                                onClickSearch={()=>handleSearch()}
                                onClickReset={()=>resetFilters()}
                            />
                        </ListSearch>
                        <ListFilter label={null} icon={null}
                                    childrenOne={
                                        <>

                                            <ButtonPlus
                                                onClick={()=>redirectTo(`/Support/Department`)}
                                                title={t("list")}
                                                tooltipTitle={t("DepartmentList")}
                                                icon={"pi-sitemap"}
                                            />


                                            <ButtonPlus
                                                onClick={()=>redirectTo("/Support/Ticket")}
                                                title={t("Ticket")}
                                                tooltipTitle={t("NewTicket")}
                                                icon={"pi-ticket"}
                                            />

                                            <ButtonPlus
                                                onClick={()=>redirectTo("/Support/Ticket/TicketGrid")}
                                                title={t("list")}
                                                tooltipTitle={t("TicketList")}
                                                icon={"pi-list"}
                                            />


                                            <ButtonPlus
                                                onClick={()=>redirectTo("/Support/ExpiredTickets")}
                                                title={t("Terminated")}
                                                tooltipTitle={t("ExpiredTickets")}
                                                icon={"pi-check-square"}
                                            />




                                        </>
                                    }
                                    childrenTwo={
                                        <>
                                            <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                        </>
                                    }
                        />

                    </div>
                    <div className={"space-y-2 H-body-ag-grid relative"} >
                        {
                            spinner?
                                <div className=" huploader_loading" >
                                    <div className="relative">
                                        <LoadingHarmony/>
                                    </div>
                                </div>
                                :
                                null
                        }

                        <GridTable
                            columnDefs={columnDefs}
                            rowSelection={GridSetting.rowSelection}
                            rowData={rowData}
                            style={GridSetting.gridStyle}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            pagination={GridSetting.pagination}
                            enableRtl={GridSetting.enableRtl}
                            domLayout={GridSetting.domLayout}
                            paginationPageSize={GridSetting.pageSize}
                            getRowHeight={getRowHeight}
                            localeText={AgGridEnglish}
                        />
                        <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    </div>
                    <Export filters={filter} apiUrl={'/Packages/PackageListExport'}/>
            </div>
            <ToastContainer />
        </>
    )
}