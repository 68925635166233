import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import queryString from "query-string";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {PickList} from "primereact/picklist";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import Select from "react-select";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {InputText} from "primereact/inputtext";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const Access=()=>{
    const { t } = useTranslation();
    const [spinner,setSpinner] = useState(false);
    const [result, setResult] = useState(0);
    const redirectTo = useNavigate();
    const [accessSource, setAccessSource] = useState([]);
    const [accessTarget, setAccessTarget] = useState([]);
    const [permissionId,setPermissionId] = useState(null);

    const[permissionStamp,setPermissionStamp] = useState(null);
    const [permissionGroupId,setPermissionGroupId] = useState(null);
    const[selectedPermissionGroupId,setSelectedPermissionGroupId] = useState(null)
    const [modal, setModal] = useState({HandleAddAccess:false });
    const [options,setOptions] = useState({accesses:[],toggles:[],permissionGroups:[],allFilteredPermissions:[],allSelectedPermissions:[]})

    const [user,setUser] = useState({userId:null,permissionId:null,concurrencyStamp:null,permissionToggle:0,dataAccess:0})

    const HandleAddAccessOnHide = () => {
        setModal((prevState) => ({ ...prevState, HandleAddAccess: !modal.HandleAddAccess }));
    };
    const onApiError = ()=>{
        setSpinner(false);
    }

    const handleChangeData = (event) => {
        setAccessSource(event.source);
        setAccessTarget(event.target);
    };


    //these parameters are directly from api
    const  handleFilterSelectedPermissionsInAllPermissions=(allPermissions,userPermission)=>{
        const permissionList = allPermissions.data.map(permission => ({ ...permission, dataAccess: 0,permissionToggle:0 }));
        const selectedPermissions = permissionList.filter(item => userPermission.data.permissions.some(sourceItem => sourceItem.permissionId === item.id));
        const filteredPermissions = permissionList.filter(role => !selectedPermissions.some(selectedPermission => selectedPermission.id === role.id));
        const updatedSelectedPermissions = selectedPermissions.map(selectedPermission => ({
            ...selectedPermission,
            dataAccess: userPermission.data.permissions.find(sourceItem => sourceItem.permissionId === selectedPermission.id)?.dataAccess || 0,
            permissionToggle:userPermission.data.permissions.find(sourceItem => sourceItem.permissionId === selectedPermission.id)?.permissionToggle || 0
        }));
        return {filteredPermissions,updatedSelectedPermissions}
    }
    // Initialize all fields,DropDowns
    const handleInitialData=(dataAccess,toggles,permissionGroups)=>{
        const accesses = convertSelectDataHandler(dataAccess);
        const toggle = convertSelectDataHandler(toggles);
        const groups = convertSelectDataHandler(permissionGroups);
        groups.push({label:'-', value: '*'})
        setOptions({accesses: accesses,toggles: toggle,permissionGroups: groups});
    }
    //updates dataAccess or togglePermission
    const handleUpdateStatus=(data,name)=>{
        setUser(prevState => ({...prevState,[name]:data}))
    }


    const handleFetchData = async () => {
        try {
            setSpinner(true);
            const allDataAccess = await GET(`/Enumerations/EnDataAccess`,{},'IEnumerable');
            const allTogglePermissions = await GET(`/Enumerations/EnPermissionToggle`,{},'IEnumerable');
            const allPermissionGroups = await GET(`/Permissions/PermissionGroups`,{},'IEnumerable');

            const allPermissions = await GET(`/Permissions`);
            const queryParams = queryString.parse(window.location.search);
            const userPermissions = await GET(`/Users/${queryParams.userId}/Permissions`);

            if ( allDataAccess.status === 200 && allTogglePermissions.status === 200 && allPermissionGroups.status && allPermissions.status === 200 && userPermissions.status === 200) {
                setPermissionStamp(userPermissions.data.concurrencyStamp);
                handleInitialData(allDataAccess.data,allTogglePermissions.data,allPermissionGroups.data)
                const {filteredPermissions,updatedSelectedPermissions} =  handleFilterSelectedPermissionsInAllPermissions(allPermissions,userPermissions)
                setAccessSource(filteredPermissions);
                setAccessTarget(updatedSelectedPermissions)
                setOptions(prevState => ({...prevState,allFilteredPermissions: filteredPermissions,allSelectedPermissions: updatedSelectedPermissions}))
                setSpinner(false);

            }else{
                handleApiError(allPermissions,onApiError)
                handleApiError(userPermissions,onApiError)
            }
        }catch (e) {
            onApiError()

        }
    };

    useEffect(()=>{
        handleFetchData();
    },[result])


    //opens modal to set dataAccess and togglePermssion
    const handleOpenAccess=(id)=>{
        setModal({ HandleAddAccess: true });
        setPermissionId(id)
    }
    //checks if a permission is in first dataSource as all permissions
    const IsPermissionInTarget=(id)=>{
        return accessTarget.some((targetItem) => targetItem.id === id);
    }
    const itemTemplate = (item) => {
        return (
            <div className="flex items-center gap-1 border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkInformation p-2 rounded ">
                <div>
                    <div className={`p-2 rounded ${item.dataAccess === 0 ?'bg-themeSecondary':'bg-themeInformation dark:bg-themeDarkInformation'}`}>
                        <HPrimeIcon icon={'pi-user'} iconClass={'text-white'}/>
                    </div>
                </div>
                <span className="font-bold line-clamp-1 w-full">{item.name}</span>
                <div className={`flex gap-2 items-center shrink-0`}>
                    <button type={"button"}>
                        <HPrimeIcon icon={"pi-list"} iconClass={`text-themeInformation dark:text-themeDarkInformation`}/>
                    </button>
                    {
                        IsPermissionInTarget(item.id)?
                            <button type={"button"}  onClick={() => handleOpenAccess(item.id)}>
                                <HPrimeIcon icon={"pi-key"}  iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
                            </button>
                            :null
                    }
                </div>
            </div>
        );
    };
    //submits data for togglePermission and dataAccess
    const handleUpdateUserPermission= async (e)=>{
        const queryParams = queryString.parse(window.location.search);
        e.preventDefault();
        try {
            setSpinner(true)
            var isInTarget = IsPermissionInTarget(permissionId);
            if(isInTarget){
                const permission = accessTarget.find(item=>item.id === permissionId)
                const model = {
                    userId:queryParams.userId,
                    concurrencyStamp: permissionStamp,
                    permissionId:permission.id,
                    permissionToggle:user.permissionToggle.value,
                    dataAccess:user.dataAccess.value
                }
                const response = await PUT(`/Authentications/UserPermissionDataAccess`,model,false);
                if(response.status === 200){
                    setSpinner(false);
                    setResult(({...response.status}));
                    handleSuccessMessage(t("Permissionsbeensuccessfully"));
                    setModal({HandleAddAccess: false})
                }
                else{
                   handleApiError(response,onApiError)
                }
            }
        }
        catch (e) {
         console.log(e);
        }
    }


    // filters all filtered Permissions based on group Id
    const handleGetAllFilteredPermissionByGroupId=(groupId)=>{
        const groups = options.allFilteredPermissions.filter(item=>item.permissionGroupId === groupId);
        return groups;
    }
    //filters all filtered permissions in left side from source based on groups
    // saves permissionGroupId to be used in search box handler
    const handleFilterAllSourceByGroup=(permissionGroupId)=>{
        setPermissionGroupId(permissionGroupId.value)
        if(permissionGroupId.value === '*'){
            setAccessSource(options.allFilteredPermissions);
        }
        else{
            const groupedPermissions = handleGetAllFilteredPermissionByGroupId(permissionGroupId.value)
            if(groupedPermissions.length > 0){
                setAccessSource(groupedPermissions);
            }else{
                setAccessSource([]);

            }
        }
    }

    // filters source from all filtered permissions to include group Name
    // checks 2 situations , if we have empty search box we get back to selected group
    // second situation is checked when we have all permissions when permissionGroupId is *
    const handleFilterSourceByName = (groupName) => {
        if ((groupName === '' || groupName === null || groupName === undefined) ) {
            if(permissionGroupId === '*'){
                setAccessSource(options.allFilteredPermissions);
                return;
            }else{
                const previousSelectedGroups = handleGetAllFilteredPermissionByGroupId(permissionGroupId);
                setAccessSource(previousSelectedGroups);
                return;
            }
        }
        const lowerCaseGroupName = groupName.toLowerCase();
        const permissions = options.allFilteredPermissions.filter(item => item.name.toLowerCase().includes(lowerCaseGroupName));
        setAccessSource(permissions);
    }

















    // filters all filtered SelectedPermissions based on group Id
    const handleGetAllFilteredSelectedByGroupId=(groupId)=>{
        const groups = options.allSelectedPermissions.filter(item=>item.permissionGroupId === groupId);
        return groups;
    }

    //filters all filtered Selected permissions in right side from target based on groups
    // saves selectedPermissionGroupId to be used in search box handler
    const handleFilterAllTargetByGroup=(selectedPermissionGroupId)=>{
        setSelectedPermissionGroupId(selectedPermissionGroupId.value)
        if(selectedPermissionGroupId.value === '*'){
            setAccessTarget(options.allSelectedPermissions);
        }
        else{
            const groupedPermissions = handleGetAllFilteredSelectedByGroupId(selectedPermissionGroupId.value)
            if(groupedPermissions.length > 0){
                setAccessTarget(groupedPermissions);
            }else{
                setAccessTarget([]);
            }
        }
    }
    // filters target from all filtered permissions to include group Name
    // checks 2 situations , if we have empty search box we get back to selected group
    // second situation is checked when we have all permissions when selectedPermissionGroup is *
    const handleFilterTargetByName = (groupName) => {
        if ((groupName === '' || groupName === null || groupName === undefined) ) {
            if(selectedPermissionGroupId === '*'){
                setAccessTarget(options.allSelectedPermissions);
                return;
            }else{
                const previousSelectedGroups = handleGetAllFilteredSelectedByGroupId(selectedPermissionGroupId);
                setAccessTarget(previousSelectedGroups);
                return;
            }
        }
        const lowerCaseGroupName = groupName.toLowerCase();
        const permissions = options.allSelectedPermissions.filter(item => item.name.toLowerCase().includes(lowerCaseGroupName));
        setAccessTarget(permissions);
    }




    // sends data to left side from source to target to select permissions
    const handleSubmitPermissions=async()=>{
        try {
            const queryParams = queryString.parse(window.location.search);
            setSpinner(true);
            const permissions = {
                userId:queryParams.userId,
                concurrencyStamp:permissionStamp,
                permissionIds : accessTarget.map(item => ({
                    permissionId: item.id,
                    permissionToggle: item.permissionToggle,
                    dataAccess: item.dataAccess
                }))
            }
            const response = await POST(`/Authentications/User/Permissions`,permissions,false);
            if(response.status === 200){
                setModal({HandleAddAccess: false})
                setSpinner(false);
                handleSuccessMessage(t("Permissionsbeensuccessfully"))
                setResult({...response.status});
            }
            else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
          onApiError();
        }
    }



    return (
        <>
            {spinner ? (
                <div className="huploader_loading">
                    <div className="relative">
                        <LoadingHarmony />
                    </div>
                </div>
            ) : null}
            <div style={{direction:"ltr"}}>
                <PickList source={accessSource}
                          target={accessTarget}
                          onChange={handleChangeData}
                          filter filterBy="name"
                          sourceHeader={t("Access")}
                          targetHeader={t("AuthorizedAccess")}
                          sourceStyle={{ height: '30rem' }}
                          targetStyle={{ height: '30rem' }}
                          sourceFilterPlaceholder={t("Accesses")}
                          itemTemplate={itemTemplate}
                          targetFilterPlaceholder={t("AuthorizedAccess")}
                          sourceFilterTemplate={
                              <div className={'space-y-2'}>
                                  <InputText onChange={(e)=>handleFilterSourceByName(e.target.value)} className={'input w-full text-end'} placeholder={t("search")}/>
                                  <Select
                                      options={options.permissionGroups}
                                      isSearchable={true}
                                      placeholder={t("selection")}
                                      onChange={(data)=>handleFilterAllSourceByGroup(data)}
                                      className={"text-end"}
                                      classNamePrefix={"h_multi_select"}
                                  />

                              </div>
                          }


                          targetFilterTemplate={
                              <div className={'space-y-2'}>
                                  <InputText  onChange={(e)=>handleFilterTargetByName(e.target.value)} className={'input w-full text-end'} placeholder={t("search")}/>
                                  <Select
                                      options={options.permissionGroups}
                                      isSearchable={true}
                                      placeholder={t("selection")}
                                      onChange={(data)=>handleFilterAllTargetByGroup(data)}
                                      className={"text-end"}
                                      classNamePrefix={"h_multi_select"}

                                  />
                              </div>

                          }
                />
            </div>
            <ButtonSubmitAndCancel
                onCancelClick={() => redirectTo('/CRM/User/List')}
               onRegisterClick={()=>handleSubmitPermissions()}
            />
            <FantasyModal openModal={modal.HandleAddAccess} closeModal={HandleAddAccessOnHide} title={t("AccessToInformation")} >
                <form method={"post"} onSubmit={handleUpdateUserPermission}>
                        <div className="space-y-5">
                            <div className={"space-y-3"}>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("Group")}</label>
                                    <div>
                                        <Select
                                            options={options.accesses}
                                            isSearchable={true}
                                            placeholder={t("selection")}
                                            onChange={(data)=>handleUpdateStatus(data,'dataAccess')}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("type")}</label>
                                    <Select
                                        options={options.toggles}
                                        isSearchable={true}
                                        placeholder={t("selection")}
                                        onChange={(data)=>handleUpdateStatus(data,'permissionToggle')}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                            </div>
                            <div>
                                <button type={"submit"} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("insertion")}</button>
                            </div>
                        </div>
                    </form>
            </FantasyModal>

        </>
    )

}