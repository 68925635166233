import React, {useEffect, useMemo, useState} from "react";
import {Image} from "primereact/image";

import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET} from "../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {PriceFormatterComponent} from "../../Commans/Accounting/PriceFormatterComponent";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";

export const useGridTariff=(setShowSpinner,fetchData,refresh,onApiError,initialSource,setTariff,setModal,setIsEdit,Refresh,setValueService)=>{

    const {t} = useTranslation();


    const handleEditTariff= async(tariffId)=>{
        try {
          setShowSpinner(true);
          setModal({ isTariffModal: true });
          setIsEdit(true);

          const response = await GET(`/Tariffs/${tariffId}`,{},'single');
          if(response.status === 200){

             setTariff({
                 tariffId:response.data.tariffId,
                 upgradeType:initialSource.upgradeTypes.find((item)=>item.value === response.data.upgradeType)??null,
                 countryId:initialSource.countries.find((item)=>item.value === response.data.countryId),
                 price:response.data.price.amount,
                 currencyType:initialSource.currencies.find((item)=>item.value === response.data.price.currency),
                 discountType:initialSource.discountTypes.find((item)=>item.value === response.data.discount.discountType),
                 discountAmount:response.data.discount.amount,
                 discountFrom:response.data.discount.from,
                 discountTo:response.data.discount.to,
                 paymentMethod:initialSource.paymentMethods.find((item)=>item.value === response.data.paymentMethod),
                 packageId:initialSource.packages.find((item)=>item.value === response.data.packageId)??null,
                 templateMasterId:initialSource.templateMasters.find((item)=>item.value === response.data.templateMasterId)??null,
             })
              setValueService(response.data.upgradeType !== null?1:response.data.packageId !== null?2:response.data.templateMasterId !== null?3:0);

              setShowSpinner(false);
          }
          else{
              handleApiError(response,onApiError);
          }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleRemoveTariff = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setShowSpinner(true)
                    const response = await DELETE(`/Tariffs/${id}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh(response.status)
                        handleSuccessMessage(t('TariffRemovedSuccessfully'))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    const handleGetCurrencyName = (currency) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === currency);
        return data ? data.label : '';
    }
    const handleGetUpgradeName=(upgrade)=>{

        if (!initialSource.upgradeTypes) {
            return;
        }
        const data = initialSource.upgradeTypes.find((item) => item.value === upgrade);
        return data ? data.label : '';
    }

    const handleGetPaymentMethod = (method) => {
        if (!initialSource.paymentMethods) {
            return;
        }
        const data = initialSource.paymentMethods.find((item) => item.value === method);
        return data ? data.label : '';
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},


        { headerName: t("country"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.countryName}
                </>
        },

        { headerName:t("UpgradeType"),minWidth: 250,
            cellRenderer:parameters=>
                <>
                    {
                        parameters.data.upgradeType?
                            handleGetUpgradeName(parameters.data.upgradeType)
                            :
                            parameters.data.packageId?
                                parameters.data.packageName
                                :
                                '-'

                    }
                </>

        },


        { headerName: t("Price"),

            cellRenderer:parameters=>
                    <>
                        <PriceFormatterComponent price={parameters.data.price}/>
                    </>

        },
        { headerName:t("CurrencyUnit"), maxWidth: 140,
            cellRenderer:parameters=>
                <>
                    {handleGetCurrencyName(parameters.data.currencyType)}
                </>
        },

        { headerName:t("ServiceModule"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    {
                        parameters.data.upgradeType?
                            t("Modules"):
                          parameters.data.packageId?
                            t("Package")
                             :
                            '-'

                    }
                </>

        },
        { headerName:t("PaymentMethod"), maxWidth: 150,
            cellRenderer:parameters=>
                <>
                    {handleGetPaymentMethod(parameters.data.paymentMethod)}
                </>
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={() => handleEditTariff(params.data.tariffId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleRemoveTariff(params.data.tariffId)}
                    />

                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({currencyType:'',search:'',currencyValue:null,countryValue:null,countryId:'',upgradeValue:null,upgradeType:''});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search: '',currencyType:'',currencyValue: null,countryId: '',countryValue: null,upgradeValue: null,upgradeType: ''}));
        setSearch('');
    }

    const handleFilterValue=(data,name,valueName)=>{
        setFilter(({...filter,[name]:data.value,[valueName]: data}));
    }


    useEffect(() => {
         OnGridReady();
    }, [refresh, currentPage, filter.search, data.pageSize,filter.currencyType,filter.countryId,filter.upgradeType]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/Tariffs`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,CurrencyType:filter.currencyType,CountryId:filter.countryId,UpgradeType:filter.upgradeType}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };
    const SwitchFilters = [
        {name:'IsActive',lable:t("isActive"),value:filter.IsActive},
        {name:'IsDeActive',lable:t("isNotActive"),value:filter.IsDeActive}
    ]


return {
        handleEditTariff,
    handleCurrentPage,
    handleNextPage,
    handlePageSizeCount,
    handlePreviousPage,
    handleRemoveTariff,
    handleSearch,
    SwitchFilters,
    resetFilters,
    filterValueHandler,
    columnDefs,
    autoGroupColumnDef,
    getRowHeight,
    defaultColDef,
    search,
    setSearch,
    rowData,
    data,
    filter,
    currentPage,
    setFilter,
    handleFilterValue

}




}