import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {AlertSetting} from "../../Services/Globals/Public/AppSetting";
import {GET} from "../../Services/AxiosService/AxiosApiService";
import {useNavigate} from "react-router-dom";

export const useDashboard=()=>{
    const redirectTo = useNavigate();

    const [classesToggled, setClassesToggled] = useState(true);
    const handleToggleDashboard = () => {
        // Toggle the state
        setClassesToggled(!classesToggled);
    };
    const showAlert = async ()=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.cancelButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:'عدم هماهنگی زمان سیستم شما',
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: 'همگام سازی',
            cancelButtonText: 'عدم همگام سازی',
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                return;
            }
            else if (

                result.dismiss === Swal.DismissReason.cancel
            ) {
                redirectTo('/');
            }
        })
    }
    const ServerTime = async () => {
        try {
            const response = await GET('/Utility/ServerDateTimeUtc',{},'single');
            if (response.status === 200) {
                const serverTimeUTC = new Date(response.data.serverDateTimeUtc);
                const clientTimeUTC = new Date().toUTCString();
                const timeDifference = Math.abs(serverTimeUTC - new Date(clientTimeUTC));
                if (timeDifference > 300000) {
                    showAlert();
                }
            }
        } catch (error) {
            console.error("Error fetching server time:", error);
        }
    };






    useEffect(()=>{
        ServerTime();
    },[])

    return {classesToggled,handleToggleDashboard}
}