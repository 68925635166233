import React, {useEffect, useState} from "react";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";


export const useCustomChooseTemplateGrid=(fetchData, setShowSpinner, onApiError, refresh, setTemplates,handleSelectValue,setUpdate,upgrade)=>{
    const { t } = useTranslation();

    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };
    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);
        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',type:'',templateTypeValue:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:search}))
    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const resetFilters = ()=>{
        setFilter(({...filter,search:'',type:null,templateTypeValue:null}));
        setSearch('');
    }
    const filterSelectValue = (data,name)=>{
        setFilter(({...filter,[name]: data.value,templateTypeValue: data}));
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh,filter.search,upgrade.currencyType,filter.type]);

    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response = await GET('/TemplateMasters/GetTemplateMasterForTemplateSelection',{PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,Type:filter.type,CurrencyType:upgrade.currencyType.value},'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setTemplates(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
        } catch (error) {
            setShowSpinner(false);
        } finally {
            setShowSpinner(false);
        }
    };


    const switchData = [
        // {name:'IsPrivate',lable:t("BasedOnPrice"),value:filter.IsPrivate},
        // {name:'IsPublic',lable:t("BasedOnDiscount"),value:filter.IsPublic},
        // {name:'IsShow',lable:t("React Project"),value:filter.IsShow},
        // {name:'IsHide',lable:t("TailwindCSSProject"),value:filter.IsHide},
        // {name:'IsHide',lable:t("TailwindCSSProject"),value:filter.IsHide},
        // {name:'IsHide',lable:t("BootstrapProject"),value:filter.IsHide},
        // {name:'IsHide',lable:t("RTL"),value:filter.IsHide},
        // {name:'IsHide',lable:t("LTR"),value:filter.IsHide},
    ]

    return {
        switchData,
        resetFilters,
        filterValueHandler,
        handleSearch,
        handleCurrentPage,
        handleNextPage,
        handlePreviousPage,
        handlePageSizeCount,
        setSearch,
        search,
        data,
        currentPage,
        filter,
        setFilter,
        filterSelectValue
    }

}