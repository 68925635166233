export const convertEditCommissionRequest = (commissions) => {
    return commissions.map(item => ({
        commissionId:item.commissionId,
        discountOrCommissionType: item.discountOrCommissionType === null || item.discountOrCommissionType === undefined  ? null: item.discountOrCommissionType.value,
        quantityOrAmount: item.quantityOrAmount === null || item.quantityOrAmount === undefined ?null: item.quantityOrAmount.value,
        commissionValue: item.commissionValue,
        stepValue: item.stepValue,
        minValue: item.minValue,
        maxValue: item.maxValue,
    }));
};
export const convertEditCommissionGroup=(obj,commissionRequest)=>{
    const newObj={
        commissionGroupId:obj.commissionGroupId === null || obj.commissionGroupId === undefined?null:obj.commissionGroupId,
        caption:obj.caption === null || obj.caption === undefined?null:obj.caption,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        fromDate:obj.fromDate === null || obj.fromDate === undefined ? null:obj.fromDate,
        toDate:obj.toDate === null || obj.toDate === undefined ? null:obj.toDate,
        stateShow:obj.stateShow === null || obj.stateShow === undefined ? null:obj.stateShow,
        targetTypeId:obj.targetTypeId === null || obj.targetTypeId === undefined ? null:obj.targetTypeId.value,
        computationBase:obj.computationBase === null || obj.computationBase === undefined ? null:obj.computationBase.value,
        commissions:commissionRequest
    }
    return newObj;
}


export const convertAddCommissionRequest = (commission) => {
    return commission.map(item => ({
        discountOrCommissionType: item.discountOrCommissionType === null || item.discountOrCommissionType === undefined  ? null: item.discountOrCommissionType.value,
        quantityOrAmount: item.quantityOrAmount === null || item.quantityOrAmount === undefined ?null: item.quantityOrAmount.value,
        commissionValue: item.commissionValue,
        stepValue: item.stepValue,
        minValue: item.minValue,
        maxValue: item.maxValue,
    }));
};
export const convertAddCommissionGroup=(obj,commission)=>{
    const newObj={
        caption:obj.caption === null || obj.caption === undefined?null:obj.caption,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        fromDate:obj.fromDate === null || obj.fromDate === undefined ? null:obj.fromDate,
        toDate:obj.toDate === null || obj.toDate === undefined ? null:obj.toDate,
        stateShow:obj.stateShow === null || obj.stateShow === undefined ? null:obj.stateShow,
        targetTypeId:obj.targetTypeId === null || obj.targetTypeId === undefined ? null:obj.targetTypeId.value,
        computationBase:obj.computationBase === null || obj.computationBase === undefined ? null:obj.computationBase.value,
        commissions:commission
    }
    return newObj;
}

export const convertCommissions = (requestObject,discountOrCommissionType,quantityOrAmount)=>{
    const filteredDiscounts = requestObject.map(item => ({
        ...item,
        discountOrCommissionType:discountOrCommissionType.find(typeItem => typeItem.value === item.discountOrCommissionType),
        quantityOrAmount:quantityOrAmount.find(typeItem => typeItem.value === item.quantityOrAmount),
    }));
    return filteredDiscounts;
}



