import React from "react";
import {useTranslation} from "react-i18next";

export const BuyerInformation=({buyer})=>{
    const {t} = useTranslation();
    return (
        <>
            <fieldset className=" post-input dark:bg-themeDarkPostInput post-input-design p-5 mt-3">
                <legend className="border dark:border-themeDarkColorLine p-1 px-3 dark:bg-themeDarkPostInput text-white rounded-md H_legend_class text-xs">{t("BuyerInformation")}</legend>
                <div className="flex flex-wrap items-center justify-between gap-5">
                    <div class="flex gap-2 items-center">
                        <label className="dark:text-gray-300 text-xs text-nowrap">{t("FirstNameAndLastName")}:</label>
                        <p className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1">{buyer && buyer.fullName}</p>
                    </div>
                    <div class="flex gap-2 items-center ">
                        <label className="dark:text-gray-300 text-xs text-nowrap">{t("NationalEconomicCode")}:</label>
                        <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1">{buyer && buyer.nationalCode}</h2>
                    </div>
                    <div class=" flex gap-2 items-center ">
                        <label className=" dark:text-gray-300 text-xs text-nowrap">{t("PhoneNumber")}:</label>
                        <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1">{buyer && buyer.homePhoneNumber}</h2>
                    </div>
                    <div class=" flex gap-2 items-center ">
                        <label className=" dark:text-gray-300 text-xs text-nowrap">{t("MobilePhone")}:</label>
                        <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1">{buyer && buyer.mobilePhoneNumber}</h2>
                    </div>
                    <div class="flex gap-2 items-center">
                        <label className=" dark:text-gray-300 text-xs text-nowrap">{t("Address")}:</label>
                        <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1">{buyer && (buyer.address === null ? '***' : buyer.address)}</h2>
                    </div>
                </div>
            </fieldset>

        </>
    )
}