import React, {useState} from "react";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {handleCreateSlug} from "../Product/Add/productService";

export const useCustomProduct=(setErrors)=>{
    const [product,setProducts] = useState({id:null,title:null,slug:null,oldSlug:null,concurrencyStamp:null,price:0,wholeSalePrice:0,productGroupId:null,operation:null});

    const handleUpdateSlug = (e) => {
        const { value } = e.target;
        const slug = handleCreateSlug(value);
        setProducts((prevState) => ({ ...prevState, slug: slug }));
    };

    const handleValue = (e) => {
            const { name, value } = e.target;
            if (name === "title") {
                handleUpdateSlug(e);
            }
           setProducts((prevState) => ({ ...prevState, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
    }
    const handleNumberValue = (value,name)=>{
        setProducts({...product,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }


    const handleSelectValue=(data,name)=>{
        setProducts({...product,[name]: data});
    }

    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency){
            return '-'
        }
        return currency.label;
    }

    return {
        handleValue,
        handleNumberValue,
        product,
        setProducts,
        handleGetCurrencyType,
        handleSelectValue
    }
}