import React, {useEffect, useMemo, useState} from "react";

import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {Link, useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {useGridLog} from "./useGridLog";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import Select from "react-select";
import {Pagination} from "../../../Commans/Grid/Pagination";


export const HandleLogUser = () => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [IsShowSpinner,setIsShowSpinner]=useState(false);
  const [initialSource,setInitialSource] = useState({httpMethods:[],browserTypes:[],users:[]});


  const showSpinner=()=>{
    setIsShowSpinner(true);
  }
  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }


  const handleInitialDate=(httpMethods,browsers,responseUser)=> {
    const httpsData = convertSelectDataHandler(httpMethods);
    const browserData = convertSelectDataHandler(browsers);
    const usersData = convertSelectDataHandler(responseUser);
    setInitialSource({httpMethods:httpsData,browserTypes: browserData,users: usersData});
    return {
      httpsData,
      browserData,
      usersData
    }
  }

  const fetchData = async () => {
    try {
      // Make both GET requests concurrently
      const [responseHttpMethods, responseBrowser,responseUser] = await Promise.all([
        GET(`/Enumerations/EnHttpMethod`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnBrowser`, {}, 'IEnumerable'),
        GET(`/Users/Selection`, {}, 'IEnumerable')
      ]);
      if (responseHttpMethods.status === 200 && responseBrowser.status === 200 && responseUser.status === 200) {
        handleInitialDate(responseHttpMethods.data, responseBrowser.data,responseUser.data);
      } else {
        handleApiError(responseHttpMethods, hideSpinner);
        handleApiError(responseBrowser, hideSpinner);
      }
    } catch (e) {
      hideSpinner();
    }
  };
  useEffect(()=>{
    fetchData();
  },[])

  const {autoGroupColumnDef,columnDefs,defaultColDef,filter,getRowHeight,handleCurrentPage,handleNextPage,handlePageSizeCount, handlePreviousPage,handleSearch,handleResetFilters,handleToggleFilter,setFilter,rowData,setSearch, search,pagination,handleSelectFilterByUser,handleSelectFilterByHttpMethod}=useGridLog(showSpinner,hideSpinner,initialSource)





  return(<>
    <div class=" space-y-5">
      <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
          <ListSearch label={null} icon={null} >
            <div class="space-y-1">
              <label className="text-xs dark:text-gray-300">{t("search")}</label>
              <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
            </div>
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("BrowserType")}</label>
              <Select
                  options={initialSource.httpMethods}
                  isSearchable={true}
                  onChange={(data)=>handleSelectFilterByHttpMethod(data,'httpMethod')}
                  placeholder={t("selection")}
                  value={filter.httpMethodValue}
                  classNamePrefix={"h_multi_select"}
                  menuPosition={"fixed"}
              />
            </div>
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300">{t('users')}</label>
              <Select
                  options={initialSource.users}
                  isSearchable={true}
                  onChange={(data)=>handleSelectFilterByUser(data,'user')}
                  placeholder={t("selection")}
                  value={filter.userId}
                  classNamePrefix={"h_multi_select"}
                  menuPosition={"fixed"}
              />
            </div>
            <ButtonSearchList
                onClickSearch={()=>handleSearch()}
                onClickReset={()=>handleResetFilters()}
            />
          </ListSearch>
        <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={
                      <>
                        <ButtonPlus
                            onClick={()=>redirectTo(`/CRM/User/Add`)}
                            tooltipTitle={t("UsersRegistration")}
                            title={t("personnel")}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo(`/CRM/User/List`)}
                            icon={"pi-list"}
                            title={t("list")}
                            functionClass={"harmony-return-list"}
                            tooltipTitle={t("ListOfPersons")}

                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/CRM/User/Group`)}
                            icon={"pi-users"}
                            title={t("grouping")}
                            tooltipTitle={t("UsersGrouping")}

                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/CRM/User/Role`)}
                            icon={"pi-star"}
                            title={t("CreatingRole")}
                            tooltipTitle={t("CreatingRole")}

                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/CRM/User/Reminder/List`)}
                            icon={"pi-clock"}
                            title={t("Reminders")}
                            tooltipTitle={t("Reminders")}
                        />

                      </>
                    }

        />


      </div>
      <div className={"space-y-2 H-body-ag-grid relative"}>
        {
          IsShowSpinner?
              <div className=" huploader_loading" >
                <div className="relative">
                  <LoadingHarmony/>
                </div>
              </div>
              :
              null
        }

        <GridTable
            columnDefs={columnDefs}
            rowSelection={GridSetting.rowSelection}
            rowData={rowData}
            style={GridSetting.gridStyle}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={GridSetting.pagination}
            enableRtl={GridSetting.enableRtl}
            domLayout={GridSetting.domLayout}
            paginationPageSize={GridSetting.pageSize}
            getRowHeight={getRowHeight}
            localeText={AgGridEnglish}
        />
        <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
      </div>
    </div>


  </>)
}