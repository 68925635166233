
export const handleCreateComment=(obj,commentId)=>{
    const newObj = {
        slug:obj.slug === null || obj.slug === undefined ? null : obj.slug.value,
        parentId:commentId === null || commentId === undefined?null:commentId,
        state:obj.state === null || obj.state === undefined ? null : obj.state.value,
        fullName:obj.fullName === null || obj.fullName === undefined?null:obj.fullName,
        message:obj.message === null || obj.message === undefined?null:obj.message,
        likeCounts:obj.likeCounts === null || obj.likeCounts === undefined ?null:obj.likeCounts,
        dislikeCounts:obj.dislikeCounts === null || obj.dislikeCounts === undefined ?null:obj.dislikeCounts,
        rateCommenting:obj.rateCommenting === null || obj.rateCommenting === undefined ?null:obj.rateCommenting,
        adminReply:obj.adminReply === null || obj.adminReply === undefined ?null:obj.adminReply,
        adminReplyRateCommenting:obj.adminReplyRateCommenting === null || obj.adminReplyRateCommenting === undefined ?null:obj.adminReplyRateCommenting,
        adminLikeCounts:obj.adminLikeCounts === null || obj.adminLikeCounts === undefined ?null:obj.adminLikeCounts,
        adminDislikeCounts:obj.adminDislikeCounts === null || obj.adminDislikeCounts === undefined ?null:obj.adminDislikeCounts,
        communications:obj.communications.map(item => ({
            communicationId:item.communicationId.value,
            communicationMode:item.communicationMode.value,
            communicationValue:item.communicationValue,
            description:item.description
        }))
    }
    return newObj;
}
export const handleEditComment=(obj,commentId)=>{
    const newObj = {
        id:obj.id === null || obj.id === undefined ? null :obj.id,
        slug:obj.slug === null || obj.slug === undefined ? null : obj.slug.value,
        parentId:commentId === null || commentId === undefined?null:commentId,
        state:obj.state === null || obj.state === undefined ? null : obj.state.value,
        fullName:obj.fullName === null || obj.fullName === undefined?null:obj.fullName,
        message:obj.message === null || obj.message === undefined?null:obj.message,
        likeCounts:obj.likeCounts === null || obj.likeCounts === undefined ?null:obj.likeCounts,
        dislikeCounts:obj.dislikeCounts === null || obj.dislikeCounts === undefined ?null:obj.dislikeCounts,
        rateCommenting:obj.rateCommenting === null || obj.rateCommenting === undefined ?null:obj.rateCommenting,
        communications:obj.communications.map(item => ({
            id:item.id,
            communicationId:item.communicationId.value,
            communicationMode:item.communicationMode.value,
            communicationValue:item.communicationValue,
            description:item.description
        }))
    }
    return newObj;
}
export const handleFilterCommunication = (communications,types,modes)=>{
    const filteredCommunications = communications.map(item => ({
        ...item,
        communicationId: types.find(typeItem => typeItem.value === item.communicationId),
        communicationMode: modes.find(typeItem => typeItem.value === item.communicationMode)
    }));
    return filteredCommunications;
}