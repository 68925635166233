import React, {useState} from "react";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {Accordion, AccordionTab} from "primereact/accordion";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useSetting} from "../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {Tooltip} from "react-tooltip";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {HPrimeIconSmall} from "../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";


export const HandleAttendanceAdd = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [IsConnected,setIsConnected] = useState(false);


    const handleOpenConnect=()=>{
        setIsConnected(true);
    }
    const handleCloseConnect=()=>{
        setIsConnected(false);
    }


    return (
        <>
            <div className="space-y-5">

                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByName")}</label>
                            <InputText  type="text"  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("AccountType")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <ButtonSearchList
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Connect")}
                                            onClick={()=>handleOpenConnect()}
                                            tooltipTitle={t("ConnectAccounts")}

                                        />

                                        <ButtonPlus
                                            title={t("Personnel")}
                                            onClick={()=>redirectTo(`/Attendance/Personnel`)}
                                            tooltipTitle={t("Personnel")}
                                            icon={"users"}
                                        />

                                        <ButtonPlus
                                            icon={"plus-square"}
                                            title={t("Device")}
                                            onClick={()=>redirectTo("/Attendance/Add")}
                                            tooltipTitle={t("RegisterDevice")}
                                        />

                                        <ButtonPlus
                                            title={t("FinancialRules")}
                                            icon={"book"}
                                            onClick={()=>redirectTo("/Attendance/FinancialRules")}
                                            tooltipTitle={t("FinancialRules")}
                                        />

                                        <ButtonPlus
                                            title={t("Leave")}
                                            primIcon={"pi-calendar-clock"}
                                            onClick={()=>redirectTo("/Attendance/LeaveAndMission")}
                                            tooltipTitle={t("LeaveAndMission")}
                                        />

                                        <ButtonPlus
                                            title={t("Shift")}
                                            icon={"layers"}
                                            onClick={()=>redirectTo("/Attendance/ShiftWork/List")}
                                            tooltipTitle={t("ShiftWork")}
                                        />

                                        <ButtonPlus
                                            title={t("WorkGroup")}
                                            icon={"users"}
                                            onClick={()=>redirectTo("/Attendance/WorkGroup/List")}
                                            tooltipTitle={t("WorkGroup")}
                                        />

                                        <ButtonPlus
                                            title={t("archive")}
                                            icon={"archive"}
                                            onClick={()=>redirectTo("/Attendance/Archive")}
                                            tooltipTitle={t("ArchiveLeaveAndMission")}
                                        />


                                        <ButtonPlus
                                            title={t("Reports")}
                                            primIcon={"pi-chart-bar"}
                                            onClick={()=>redirectTo("/Attendance/List/Reports")}
                                            tooltipTitle={t("GeneralReport")}
                                        />


                                    </>
                                }

                    />

                </div>

                <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 box bg-white dark:bg-themeDarkSalt700 p-3 max_height85vh scrollbar">
                    <div className={"flex flex-col justify-between post-input dark:bg-themeDarkPostInput Attendance_add overflow-hidden post-input-design"} >
                        <div className="flex flex-col items-center gap-5 px-8 py-8">
                            <div class="flex items-center">
                                <div className={"h-20 w-20 flex-shrink-0 border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <img className="size-full object-cover" alt="harmony" src="/Assets/Image/bg-img/Attendance-Device.png"/>
                                </div>
                            </div>
                            <div className={"space-y-1 text-center"}>
                                <h2 class="dark:text-themeDarkInformation">HarmonyErpNews</h2>
                                <p class="text-xs text-gray-500 dark:text-gray-300">12132453</p>
                                <p class="text-xs text-gray-500 dark:text-gray-300 font-light">454545</p>
                            </div>
                        </div>
                        <div class=" w-full flex items-center p-1 gap-1 bgwhite dark:bg-themeDarkPostInput">
                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded zoom-in tooltip_class_test"}>
                                <HPrimeIconSmall icon={"repeat"} />
                                <Tooltip anchorSelect={".tooltip_class_test"} content={t("ConnectionTest")} positionStrategy={"fixed"}	 />
                            </button>
                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded  zoom-in tooltip_class_repeat"}>
                                <HPrimeIconSmall icon={"pie-chart"} />
                                <Tooltip anchorSelect={".tooltip_class_repeat"} content={t("Report")} positionStrategy={"fixed"}	 />
                            </button>
                            <button className={"flex-1 button grid place-content-center bg-themeInformation dark:bg-themeDarkInformation text-white rounded zoom-in tooltip_class_delete"}>
                                <HPrimeIconSmall icon={"pi-trash"} />
                                <Tooltip anchorSelect={".tooltip_class_delete"} content={t("DeleteAccount")} positionStrategy={"fixed"}	 />
                            </button>

                        </div>
                    </div>
                </div>

            </div>

            <FantasyModal openModal={IsConnected} closeModal={handleCloseConnect} title={t("AddAccount")}>
                <div class="space-y-5">
                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("DeviceName")}</label>
                            <InputText className={"input w-full"}/>
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("DeviceModel")}</label>
                            <Select
                                placeholder={t("selection")}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("DeviceNumber")}</label>
                            <InputText className={"input w-full"}/>
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Password")}</label>
                            <InputText className={"input w-full"}/>
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("CommunicationType")}</label>
                            <Select
                                placeholder={t("selection")}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <div className={"flex gap-2 items-center"}>
                            <div class="space-y-1 flex-1 w-full">
                                <label className="text-xs dark:text-gray-300">{t("IP")}</label>
                                <InputText className={"input w-full"}/>
                            </div>
                            <div class=" space-y-1 w-14">
                                <label className="text-xs dark:text-gray-300">{t("Port")}</label>
                                <InputText className={"input w-full"}/>
                            </div>
                        </div>

                    </div>
                    <Accordion  className={"bg-white dark:bg-themeDarkSalt700"}>
                        <AccordionTab
                            header={
                                <div className="flex items-center gap-2">
                                    <HPrimeIcon icon={'pi-user'} />
                                    <p>{t("DetermineSystemManagement")}</p>
                                </div>
                            }
                        >
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-2 post-input p-2 dark:bg-themeDarkPostInput post-input-design">
                                <div class="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("SystemManager")}</label>
                                    <Select
                                        placeholder={t("selection")}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />

                                </div>
                                <div class="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("Password")}</label>
                                    <InputText className={"input w-full"}/>
                                </div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <div>
                        <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                    </div>
                </div>
            </FantasyModal>


            <FantasyModal openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
        </>
    );
};
