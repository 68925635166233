import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {toggleExpandedForAll} from "@nosferatu500/react-sortable-tree";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleExpandAllNodes} from "../RoadMapTreeService";
import {useNavigate, useParams} from "react-router-dom";

export const useMapTree=(showSpinner,hideSpinner,setIsOpen,initialSource,setInitialSource,setRoadMapTree,roadMapTree,setIsEdit,Refresh,handleCloseRoadmap)=>{
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState("");
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [searchFoundCount, setSearchFoundCount] = useState(null);
    const [treeData, setTreeData] = useState([]);
    const redirectTo = useNavigate();
    const {roadMapId}=useParams();



    function updateTreeData(treeData) {
        setTreeData(treeData);
    }
    function expand(expanded) {
        setTreeData(
            toggleExpandedForAll({
                treeData,
                expanded,
            })
        );
    }
    function expandAll() {
        expand(true);
    }
    function collapseAll() {
        expand(false);
    }
    const selectPrevMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
                : searchFoundCount - 1
        );
    };
    const selectNextMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
        );
    };

    const getNodeKey = ({ treeIndex }) => treeIndex;
    const customSearchMethod = ({ node, searchQuery }) => {
        return node.name.toLowerCase().includes(searchQuery.toLowerCase());
    };





    const fetchSelectionData = async (personEntityState) => {
        try {
            switch (personEntityState) {
                case 0:
                    return await GET('/Users/Selection', {}, 'IEnumerable');
                case 1:
                    return await GET('/UserGroups/Selection', {}, 'IEnumerable');
                case 2:
                    return await GET('/Roles/Selection', {}, 'IEnumerable');
                default:
                    handleFailureMessage('دیتای نامعتبر');
                    break;
            }
        } catch (e) {
            hideSpinner();
        }
    };


    async function showConfirmationDialog(roadMapId) {
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        });
        const result = await TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        });
        if (result.isConfirmed) {
            redirectTo(`/Form/Create?roadMapId=${roadMapId}`);
        }
        else if (
            result.dismiss === Swal.DismissReason.cancel
        ) {
            return;
        }
    }

    async function handleGetDataNode(node) {
        if(node.personEntity === null){
            setIsEdit(true);
            const {id,access,description,isApproved,parentId,roadMapStepId,title,operation,personEntity,personEntityId} = node;
            setRoadMapTree((prevStates)=>({...prevStates,
                title: title,
                roadMapStepId:roadMapStepId,
                operation: initialSource.operations.find((item)=>item.value === operation)??null,
                access: initialSource.dataAccess.find((item)=>item.value === access)??null,
                personEntity: personEntity,
                personEntityId:personEntityId,
                description: description,
                isApproved: initialSource.confirmationStates.find((item)=>item.value === isApproved)??null,
                parentId: initialSource.parents.find((item)=>item.value === parentId)
            }))
            setIsOpen(true)
        }
        else{
            try {
                showSpinner()
                setIsEdit(true);
                const {access,description,isApproved,parentId,roadMapStepId,title,operation,personEntity,personEntityId} = node;

                const response = await fetchSelectionData(node.personEntity);
                if(response.status === 200){
                    const types = convertSelectDataHandler(response.data);
                    setRoadMapTree((prevState) => ({ ...prevState,
                        personEntityId:types.find((item)=>item.value === personEntityId)??null,
                        personEntity:initialSource.entityTypes.find((item)=>item.value === personEntity)??null,
                        title: title,
                        operation: initialSource.operations.find((item)=>item.value === operation)??null,
                        access: initialSource.dataAccess.find((item)=>item.value === access)??null,
                        description: description,
                        isApproved: initialSource.confirmationStates.find((item)=>item.value === isApproved)??null,
                        parentId: initialSource.parents.find((item)=>item.value === parentId)??null,
                        roadMapStepId:roadMapStepId,
                    }));

                    setInitialSource((prevStates)=>({...prevStates,types:types}));
                    setIsOpen(true)
                    hideSpinner()
                }
                else{
                    handleApiError(response,hideSpinner);
                }
            }
            catch (e) {
                hideSpinner();
            }
        }
    }

    const handleGetNode = async(node)=>{
        try {
            showSpinner();
            const resp = await GET(`/Forms/RoadMaps/HasDataGroup?roadMapId=${roadMapId}`,{},'single');
            if(resp.status === 200){
                hideSpinner();
                if(resp.data.hasFormDataGroup === true){
                    // handleCloseRoadmap();
                    await showConfirmationDialog(roadMapId);
                }
                else{
                    await handleGetDataNode(node)
                }
            }
            else{
                handleApiError(resp,hideSpinner);
            }
        }
        catch (e) {
          hideSpinner();
        }




    }




    function handleAddNodeChild(node) {
        const parentId = initialSource.parents.find((item) => item.value === node.roadMapStepId)??null;
        setRoadMapTree(prevState => ({...prevState,title: '',access: initialSource.dataAccess[0],description: '',isApproved: initialSource.confirmationStates[0],operation: initialSource.operations[0],parentId: parentId,personEntity: null,personEntityId: null}))
        setIsOpen(true);
    }

    const handleDeleteNode = async (roadMapId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                showSpinner()
                const response  = await DELETE(`/Forms/RoadMapSteps/${roadMapId}`,{headers: { 'Content-Type': 'application/json' }});
                if(response.status === 200){
                    hideSpinner();
                    Refresh();
                    handleSuccessMessage(t("SuccessDone"));
                }else{
                    handleApiError(response,hideSpinner);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }
    const handleMoveNode = async(e) => {
        try {
            const Id = e.nextParentNode?.id;
            e.node.parentId = Id !== undefined ? Id : null;
            showSpinner()
            const response = await PUT('/Roles/Parent', { Id: e.node.id,parentId:e.node.parentId });
            if(response.status === 200){
                hideSpinner();
                expandAll();
                Refresh();
                handleSuccessMessage(t("SuccessDone"))
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }
        catch (e) {
            hideSpinner();
        }
    }


    const handleSetExpandedFalse = () => {
        const updatedData = handleExpandAllNodes(treeData,false);
        setTreeData(updatedData);
    };
    const handleSetExpandedTrue = () => {
        const updatedData = handleExpandAllNodes(treeData,true);
        setTreeData(updatedData);
    };




    return{
        searchString,
        setSearchString,
        searchFocusIndex,
        setSearchFocusIndex,
        searchFoundCount,
        setSearchFoundCount,
        treeData,
        setTreeData,
        updateTreeData,
        expandAll,
        collapseAll,
        selectPrevMatch,
        selectNextMatch,
        getNodeKey,
        handleAddNodeChild,
        handleDeleteNode,
        handleMoveNode,
        customSearchMethod,
        handleGetNode,
        handleSetExpandedFalse,
        handleSetExpandedTrue

    }
}