import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {convertAddWebsite, convertAddWebsiteFormData, extractTemplateMaster} from "../websiteService";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import ArrowLeftIcon from "../../../../HIcon/ArrowLeftIcon";
export const Templates=({handleChangeTab,onChangeTemplate,values})=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const[spinner,setSpinner] = useState(false);
    const [selectedTemplates, setSelectedTemplates] = useState({template:null}); // To store selected packages
    const [templates,setTemplates] = useState([]);
    const [selectOptions,setSelectOptions] = useState({templateTypes:null})


    const onApiError = ()=>{
        setSpinner(false);
    }
    const GoToLastPage=(tabId)=>{
        handleChangeTab(tabId)
    }



    const handleChangeViewTab = (tabId) => {
        if(selectedTemplates.template === null){
            handleFailureMessage(t("Pleaseselectleasttemplate"));
            return;
        }
        handleChangeTab(tabId)
    };




    const handleCheckboxChange = (id,isChecked) => {
        handleSuccessMessage(t("Templateselected"));
        onChangeTemplate(id,isChecked);
        if (isChecked) {
            setSelectedTemplates((prevSite) => ({ ...prevSite, template: id }));
        } else {
            setSelectedTemplates((prevSite) => ({ ...prevSite,template: null}));
        }
    };


    const handleFetchData = async ()=>{
        try {
            setSpinner(true)
            const responseTemplateType = await GET('/Enumerations/EnTypeTemplate',{},'IEnumerable');
            const response = await GET(`/TemplateMasters/WebSite?ItemCount=${100}`,{},'IEnumerable');
            if(response.status === 200 && responseTemplateType.status === 200){
                setSpinner(false)
                const templateTypes = convertSelectDataHandler(responseTemplateType.data);
                setSelectOptions((prevOptions) => ({...prevOptions, templateTypes: templateTypes}));
                setTemplates(response.data);
            }else{
                handleApiError(responseTemplateType,onApiError)
            }
        }catch (e) {
            console.log(e);
        }
    }
    useEffect(()=>{
        handleFetchData();
    },[])


    const ConvertTemplateType = (type) => {
        if (!selectOptions.templateTypes) {
            return;
        }
        const data = selectOptions.templateTypes.find((item) => item.value === type);
        return data ? data.label : '';
    }


    const handleSubmitWebsite = async()=>{
       try {
           setSpinner(true)
           const data = convertAddWebsite(values);
           const website = convertAddWebsiteFormData(data);
           const response = await POST(`/WebSites`,website,true);
           if(response.status === 200){
               handleSuccessMessage(t("Yourwebsitehasbeencreated"));
               redirectTo('/Admin/Website/List');
           }
           else
           {
               handleApiError(response,onApiError)
           }
       }catch (e) {
           onApiError()
       }
    }

    // Check if values.TemplateMasterId is not null before calling includes
    const handleIsTemplateChecked = (id) => {
        return values.TemplateMasterId && values.TemplateMasterId.includes(id);
    };


    return (<>

            <div>
                <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                    <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                        6
                    </div>
                    <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                        <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">{t("TemplateSelection")}</h2>
                    </div>
                    <div>
                        <span className={"arrow_design_add_site"}>
                           <ArrowLeftIcon/>
                        </span>
                    </div>
                </div>
            </div>
            <div className={"mt-5 space-y-5 relative"}>
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-3 max_height65 py-5 px-2" >
                    {
                        templates  && templates.length > 0 ?
                            templates.map((item,index)=>{return (
                                <div key={index}  className={` relative space-y-4 web_template ${handleIsTemplateChecked(item.id) ?'web_template_class':''}`}>
                                    {/*templateId === item.id*/}
                                    <div className="w-full H_aspect_15">
                                        <img src={item.banner} alt="" className="w-full h-full object-cover absolute inset-0" />
                                    </div>
                                    <div className="px-3 space-y-2">
                                        <div >
                                            <h2 className="text-themeInformation">{item.name}</h2>
                                        </div>
                                        <div className="flex border-b pb-3 border-dashed dark:border-themeDarkColorLine text-xs text-gray-500">
                                            <h2 className={"text-themeInformation"}>{t("Category")}:</h2>
                                            <h2>{ConvertTemplateType(item.type)}</h2>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center pb-3 gap-2 ">
                                        <label htmlFor={`content-${item.id}`} className={'block button bg-themeInformation w-28 text-nowrap dark:bg-themeDarkInformation text-white'}>
                                            <input type="radio" className="absolute top-0 end-0 hidden"
                                                   onChange={(e) => handleCheckboxChange(item.id,e.target.checked)}
                                                   data-hm="website"
                                                   name={"jj"}
                                                   id={`content-${item.id}`} // Unique id for each checkbox
                                                   value={item.id}
                                            />
                                            {t("selection")}
                                        </label>
                                        <button className="button bg-themeSecondary w-28 text-white ">{t("Preview")}</button>
                                    </div>
                                </div>
                            )}):
                            t("Loading")
                    }
                </div>
                <div class="flex flex-wrap items-center justify-center md:justify-end gap-2">
                    <button type={'button'} onClick={()=>GoToLastPage('servers')} className="block button w-28  bg-themeDanger text-white">{t("previous")}</button>
                    <button type={'button'} onClick={()=>handleSubmitWebsite()} className="block button  bg-themeInformation w-28 dark:bg-themeDarkInformation text-white">{t("Register")}</button>
                </div>
            </div>

    </>)
}





