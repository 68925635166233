import React, { useEffect, useRef, useState } from 'react';

import {HPrimeIconSmall} from "../UIParts/HPrimeIconSmall";

export const UploadImage = (props) => {
    const { OnValueSelection, name, value } = props;
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);

    function runAfterImageDelete() {
        // handleFailureMessage('فایل حذف شد')

        if(props.isEdit){
            setFile(null);
            OnValueSelection(null, name);
            props.removeFile(value,props.removeName);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Reset the file input value
            }
        }else{
            setFile(null);
            OnValueSelection(null, name);
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Reset the file input value
            }
        }


    }

    const onFileChangeHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageUrl = e.target.result;
                setFile(imageUrl);
                OnValueSelection(file, name);

                //handleSuccessMessage('فایل انتخاب شد');
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (value === null) {
            setFile(null);
        }
    }, [value]);

    const handleChangeMode = () => {
        if (file) {
            return (
                <>
                    <div className="absolute size-full inset-0 z-1 bg-white dark:bg-themeDarkSalt700">
                        {props.accept === '.zip' ? (
                            // Display a different icon or content for zip files with data
                            <img src="/Assets/Image/bg-img/zip.svg" alt="Profile" className="size-full" />

                        ) : (
                            <img src={file} alt="Profile" className="size-full object-contain" />
                        )}
                    </div>
                    <div className="absolute bottom-2 end-2 z-2">
                        <button type={"button"} onClick={runAfterImageDelete} className="button size-7 grid place-content-center bg-themeDanger text-white">
                            <HPrimeIconSmall icon={'pi-trash'} />
                        </button>
                    </div>
                </>
            );
        } else if (value && props.accept === 'image/*') {
            return (
                <>
                    <div className={`absolute size-full inset-0 z-2 bg-white dark:bg-themeDarkSalt700 ${file ? 'hidden' : ''}`}>
                        <img src={value} alt="Profile" className="size-full object-contain" />
                    </div>
                    <div className="absolute bottom-2 end-2 z-30">
                    <button type={"button"} onClick={runAfterImageDelete} className="button size-7 grid place-content-center bg-themeDanger text-white">
                       <HPrimeIconSmall icon={'pi-trash'} />
                    </button>
                    </div>
                </>
            );
        }
        // else if (!file && props.accept === 'image/*') {
        //     return (
        //         <div className={`absolute inset-0 ${file ? 'hidden' : ''}`}>
        //             <img src="/Assets/images/add-photo.jpg" alt="Profile" className="w-full h-full object-contain" />
        //         </div>
        //     );
        // }
    };

    // const handleDefault = () => {
    //     if (props.accept === 'image/*') {
    //         return <img src="/Assets/images/add-photo.jpg" alt="Profile" className="w-full h-full object-contain" />;
    //     } else if (props.accept === '.zip') {
    //         return <img src="/Assets/images/AddZip.png" alt="Profile" className="w-full h-full object-contain" />;
    //     }
    // };

    return (
        <div className={` ${props.borderStyle ? props.borderStyle: `border border-dashed border-gray-300`} ${props.bgStyle ? props.bgStyle: ``} size-full relative overflow-hidden dark:border-themeDarkColorLine`}>
            {handleChangeMode()}

            <label htmlFor={props.name} className={`absolute inset-0 size-full cursor-pointer  ${props.hpBgPosition ? props.hpBgPosition :'hpcover'}  ${props.accept === 'image/*'?(props.bgMulti ? props.bgMulti:'bg_uploader_image'):'bg_uploader_zip'}`}>
                <input
                    type="file"
                    accept={props.accept}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={onFileChangeHandler}
                    id={props.name}
                    className={'cursor-pointer'}
                />

                {/*<div className="absolute inset-0 ">*/}
                {/*    <button*/}
                {/*        className={`w-full h-full ${value ? 'hidden' : ''}`}*/}
                {/*        onClick={() => fileInputRef.current.click()}*/}
                {/*        type={'button'}*/}
                {/*    >*/}
                {/*        {handleDefault()}*/}
                {/*    </button>*/}
                {/*</div>*/}
            </label>
        </div>
    );
};
