import React, {useEffect, useState} from "react";

import {Link, redirect, useNavigate} from "react-router-dom";

import {useTranslation} from "react-i18next";


import {InputText} from "primereact/inputtext";

import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import TextArea from "../Commans/UIParts/TextArea";
import {ModalSubmitButton} from "../Commans/UIParts/ModalSubmitButton";
import {Tooltip} from "react-tooltip";
import {HPrimeIconSmall} from "../Commans/UIParts/HPrimeIconSmall";



export const HandleShipmentTracking = () => {


    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [isMenuOpen,setMenuOpen] = useState(false);

    const handleOpenMenu = () => {
        setMenuOpen(true);
    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }

const notification = [
    {date:"1403/12/1",text:"تخفیف باور نکردنی"},
    {date:"1403/12/1",text:"تخفیف باور نکردنی"}
]


        return(
        <>
            <div class="space-y-5">
                <div className={"p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation "}>
                    <div className={"flex gap-2"}>
                        <div className="space-y-2 flex-1 ">
                            <div className={"flex gap-2 items-center"}>
                                <label className="text-xs dark:text-gray-300">{t("PleaseEnterYourTrackingNumber")}</label>
                                <button type={"button"} className={"text-themeInformation dark:text-themeDarkInformation text-xs"} onClick={()=> handleOpenMenu()}>({t("AddComplaint")})</button>
                            </div>
                            <InputText type="search" className="input w-full"  />
                        </div>
                        <ButtonSearchList
                        />
                    </div>

                </div>
                <div className="space-y-5">
                    <div className={"box bg-white px-2 py-3 overflow-x-auto light-shadow dark:border-themeDarkColorLine dark:bg-themeDarkSalt700"}>
                        <div class="process-wrap active-step1 ">
                            <div class="process-main">
                                <div class="col-3">
                                    <div class="process-step-cont tooltip_class_Bill">
                                        <Tooltip anchorSelect={".tooltip_class_Bill"}  positionStrategy={"fixed"}>
                                            <div>
                                                <ul className={"space-y-2"}>
                                                    <li>
                                                        <div className={"flex gap-2 items-center"}>
                                                            <HPrimeIconSmall
                                                            icon={"pi-verified"}
                                                            iconClass={"text-themeSuccess"}
                                                            />
                                                            <p>ایجاد صورت حساب</p>
                                                        </div>
                                                        <div className={"flex gap-2 items-center"}>
                                                            <HPrimeIconSmall
                                                                icon={"pi-sync"}
                                                                iconClass={"pi-spin"}
                                                            />
                                                            <p>درانتضار صورتحساب</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </Tooltip>
                                        <div class="process-step step-1"></div>
                                        <span class="process-label">
                                        <h2 className={"text-xs dark:text-gray-300"}>{t("Bill")}</h2>
                                    </span>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="process-step-cont tooltip_class_pay">
                                        <Tooltip anchorSelect={".tooltip_class_pay"}  positionStrategy={"fixed"}>
                                            <div>
                                                <ul className={"space-y-2"}>
                                                    <li>
                                                        <div className={"flex gap-2 items-center"}>
                                                            <HPrimeIconSmall
                                                                icon={"pi-verified"}
                                                                iconClass={"text-themeSuccess"}
                                                            />
                                                            <p>پرداخت موفق</p>
                                                        </div>
                                                        <div className={"flex gap-2 items-center"}>
                                                            <HPrimeIconSmall
                                                                icon={"pi-sync"}
                                                                iconClass={"pi-spin"}
                                                            />
                                                            <p>درانتظار پرداخت</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </Tooltip>
                                        <div class="process-step step-2"></div>
                                        <span class="process-label">
                                        <h2 className={"text-xs dark:text-gray-300"}>{t("Paid")}</h2>
                                    </span>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="process-step-cont tooltip_class_Packaging">

                                        <Tooltip anchorSelect={".tooltip_class_Packaging"}  positionStrategy={"fixed"}>
                                            <div>
                                                <ul className={"space-y-2"}>
                                                    <li>
                                                        <div className={"flex gap-2 items-center"}>

                                                            <HPrimeIconSmall
                                                                icon={"pi-verified"}
                                                                iconClass={"text-themeSuccess"}
                                                            />
                                                            <p>بسته بندی</p>
                                                        </div>
                                                        <div className={"flex gap-2 items-center"}>
                                                            <HPrimeIconSmall
                                                                icon={"pi-sync"}
                                                                iconClass={"pi-spin"}
                                                            />
                                                            <p>در حال بسته بندی</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </Tooltip>
                                        <div class="process-step step-3"></div>
                                        <span class="process-label">
                                        <h2 className={"text-xs dark:text-gray-300"}>{t("Packaging")}</h2>
                                    </span>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="process-step-cont tooltip_class_post">
                                        <Tooltip anchorSelect={".tooltip_class_post"}  positionStrategy={"fixed"}>
                                            <div>
                                                <ul className={"space-y-2"}>
                                                    <li>
                                                        <div className={"flex gap-2 items-center"}>
                                                            <HPrimeIconSmall
                                                                icon={"pi-verified"}
                                                                iconClass={"text-themeSuccess"}
                                                            />
                                                            <p>تحویل پست</p>
                                                        </div>
                                                        <div className={"flex gap-2 items-center"}>
                                                            <HPrimeIconSmall
                                                                icon={"pi-sync"}
                                                                iconClass={"pi-spin"}
                                                            />
                                                            <p>درانتظار تحویل</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </Tooltip>
                                        <div class="process-step step-4"></div>
                                        <span class="process-label">
                                        <h2 className={"text-xs dark:text-gray-300"}>{t("PostDelivery")}</h2>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-y-5">
                    <div className="text-themeInformation dark:text-themeDarkInformation border-b-2 border-themeInformation dark:border-themeDarkColorLine">{t("سبد خرید")}</div>
                    <div>
                        <div class="max_height20 space-y-2 p-3 bg-white box dark:bg-themeDarkSalt700">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                {notification.map((item, index) => (
                                    <div>
                                        <div className="post-input dark:bg-themeDarkPostInput  post-input-design p-2  ">
                                            <div className={"flex items-center gap-2"}>
                                                <div className={"flex-shrink-0"}>
                                                    <p>1</p>
                                                </div>
                                                    <div className={"flex-1"}>
                                                        <p className="text-xs line-clamp-1 dark:text-gray-300">{item.text}</p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} title={t("AddComplaint")} >
                <div className={"space-y-5"}>
                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                        <div className="space-y-1 md:col-span-2">
                            <label className="text-xs dark:text-gray-300">{t("NumberTracking")}</label>
                            <InputText type="search" className="input w-full"  />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("FirstNameAndLastName")}</label>
                            <InputText type="search" className="input w-full"  />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("PhoneNumber")}</label>
                            <InputText type="search" className="input w-full"  />
                        </div>
                        <div className="space-y-1 md:col-span-2">
                            <label className="text-xs dark:text-gray-300">{t("TitleComplaint")}</label>
                            <InputText type="search" className="input w-full"  />
                        </div>
                        <div className="space-y-1 md:col-span-2">
                            <label className="text-xs dark:text-gray-300">{t("TextComplaint")}</label>
                            <TextArea
                            className={"input w-full"}
                            />
                        </div>
                    </div>
                    <div>
                        <ModalSubmitButton
                            // IsBtnLoading={true}
                        />
                    </div>
                </div>
            </FantasyModal>
        </>

  )
}