import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {useNavigate} from "react-router-dom";
import {Image} from "primereact/image";

export const useFormGrid=(filter,refresh,showSpinner,hideSpinner)=>{

    const { t } = useTranslation();
    const [forms,setForms] = useState([]);
    const redirectTo = useNavigate();







    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},
        { headerName: t("Title"),minWidth: 140,
            cellRenderer:parameters=>
                <>
                    {parameters.data.name}
                </>
        },
        { headerName: t("کاربر"),minWidth: 140,

            cellRenderer:parameters=>

                <>
                    {parameters.data.user?.fullName}
                </>

        },
        { headerName: t("Image"),field: 'picture', maxWidth: 140,
            cellRenderer:params => {
                return params.data.user.picture !== null?
                    <Image src={params.data.user.picture} zoomSrc={params.data.user.picture} alt="Image" preview/>:
                    <HPrimeIcon
                        iconClass={"text-themeDanger"}
                        icon={"pi-times"}
                    />

            }
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button type={"button"} onClick={()=>redirectTo(`/UserPanel/Cartable/Form/Answer/${params.data.formId}/${params.data.formDataGroupId}`)}>

                        <HPrimeIcon
                            icon={"pi-eye"}
                            iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                        />
                    </button>
                </div>
            }
        },
    ];





    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems;
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh,pagination.currentPage,pagination.pageSize,filter.today,filter.thisWeek,filter.thisMonth,filter.search]);

    const OnGridReady = async () => {
        try {
            showSpinner(true);
            const response =  await GET(`/Forms/MyCarTable`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize,search:filter.search}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const modifiedItems = Paginate(response.items);
                setForms(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };


    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        pagination,
        forms
    }
}