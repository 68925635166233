import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";

import {InputTextarea} from "primereact/inputtextarea";
import {TextEditor} from "../../Commans/UIParts/TextEditor";
import {TabMenu} from "../../Commans/UIParts/TabMenu";
import {FileUploader} from "../../Commans/Uploaders/FileUploader";
import {InputNumber} from "primereact/inputnumber";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandleCreateCenter = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [activeTab, setActiveTab] = useState("tab_one");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
    const tabs = [
        {
            id: "tab_one",
            // label: "محتویات ",
            subLabel:t("Contents"),
            icon: "file-text",
        },
        {
            id: "tab_two",
            // label: "امکانات",
            subLabel:t("Possibilities"),
            icon: "trello",
        },
        {
            id: "tab_three",

            // label: "تگ",
            subLabel:t("Tag"),
            icon: "code",
        },
        {
            id: "tab_four",

            // label: "ارتباطات",
            subLabel:t("communications"),
            icon: "phone-call",
        },
        {
            id: "tab_five",
            // label: "گالری",
            subLabel:t("Gallery"),
            icon: "image",
        },
    ];

  return(
      <div class="  space-y-5">
          <div class="grid grid-cols-12 gap-5 ">
              <div class="col-span-12 lg:col-span-8">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                      <div className="">
                          <InputText  className="input w-full" placeholder="نام مرکز"/>
                      </div>
                      <div className="">
                          <InputText className="input w-full" placeholder="آدرس صفحه"/>
                      </div>
                  </div>
                  <div class="post box bg-white mt-5 dark:bg-themeDarkSalt700">
                      <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                      <div class="post__content tab-content">
                          <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                              <div className="space-y-5">
                                  <div className="post-input dark:bg-themeDarkPostInput p-2 rounded-md space-y-1">
                                      <label className="text-xs dark:text-gray-300">خلاصه متن:</label>
                                      <InputTextarea rows={3} cols={30} className={"input w-full"} />
                                  </div>
                                  <div>
                                      <TextEditor />
                                  </div>
                              </div>
                          </div>
                          <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                              <div className="rounded-md border dark:border-themeDarkColorLine">
                                  <div class=" p-2 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md">
                                      <p>امکانات ساختمان</p>
                                  </div>
                                  <div className="bg-white dark:bg-themeDarkSalt700 p-2">
                                      <div className="flex flex-col lg:flex-row gap-2 p-2 post-input rounded-md dark:bg-themeDarkPostInput">
                                          <div className="space-y-1 ">
                                              <label className="text-xs dark:text-gray-300">ردیف:</label>
                                              <div className="input bg-themeInformation text-white dark:text-gray-300 text-center">
                                                  1
                                              </div>
                                          </div>
                                          <div className="flex-1 w-full">
                                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                                  <div className="space-y-1">
                                                      <label className="text-xs dark:text-gray-300">نام:</label>
                                                      <InputText className="input w-full" />
                                                  </div>
                                                  <div className="space-y-1">
                                                      <label className="text-xs dark:text-gray-300">نوع:</label>
                                                      <Select placeholder={'انتخاب'}/>
                                                  </div>
                                                  <div className="space-y-1">
                                                      <label className="text-xs dark:text-gray-300">مقدار:</label>
                                                      <input type="text" className="input w-full" />
                                                  </div>
                                                  <div className="space-y-1 ">
                                                      <label className="text-xs dark:text-gray-300">رتبه:</label>
                                                      <div>
                                                          <InputNumber className="input_number_plus w-full"  mode="decimal" showButtons min={0} max={100}/>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="flex items-end justify-end gap-2">
                                              <button type="button" className="button small_button bg-themeDanger text-white">
                                                  <HPrimeIcon icon={'pi-trash'} />
                                              </button>
                                              <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white ">
                                                  <HPrimeIcon icon={'pi-plus'}  />
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className={`tab-content__pane p-1 md:p-3 space-y-5 ${ activeTab === "tab_three" ? "active" : "" }`} id="tab_three">
                              <div className="space-y-3">
                                  <div className="post-input dark:bg-themeDarkPostInput rounded-md p-2 space-y-2">
                                      <label className="text-xs dark:text-gray-300">عنوان سایت</label>
                                      <InputText className={"input w-full"} />
                                  </div>
                                  <div className="post-input dark:bg-themeDarkPostInput rounded-md p-2 space-y-2">
                                      <label className="text-xs dark:text-gray-300">کلمات کلیدی</label>
                                      <InputText className={"input w-full"} />
                                  </div>
                                  <div className="post-input dark:bg-themeDarkPostInput rounded-md p-2 space-y-2">
                                      <label className="text-xs dark:text-gray-300">توضیحات در سایت</label>
                                      <InputTextarea rows={3} cols={30} className={"input w-full"} />
                                  </div>
                              </div>
                              <div className="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md">
                                  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 p-2 ">
                                      <div className=" space-y-2">
                                          <label className="text-xs dark:text-gray-300">تاریخ انتشار:</label>
                                          <InputText className={"input w-full"} />
                                      </div>
                                      <div className=" space-y-2">
                                          <label className="text-xs dark:text-gray-300"> انتشار برای:</label>
                                          <Select



                                          />
                                          {/*<div class="mt-2">*/}
                                          {/*    <select className="select2 w-full">*/}
                                          {/*        <option value="1">...</option>*/}
                                          {/*        <option value="2">همه افراد</option>*/}
                                          {/*        <option value="3">فقط اعضای سایت</option>*/}
                                          {/*    </select>*/}
                                          {/*</div>*/}
                                      </div>
                                      <div className=" space-y-2">
                                          <label className="text-xs dark:text-gray-300">زمان:</label>
                                          <InputText className={"input w-full"} />
                                      </div>
                                  </div>
                                  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 p-2">
                                      <div className=" space-y-2">
                                          <label className="text-xs dark:text-gray-300">طول جغرافیایی:</label>
                                          <InputText className={"input w-full"} />
                                      </div>
                                      <div className=" space-y-2">
                                          <label className="text-xs dark:text-gray-300"> عرض جغرافیایی:</label>
                                          <InputText className={"input w-full"} />
                                      </div>
                                      <div className=" space-y-2">
                                          <label className="text-xs dark:text-gray-300">آدرس نقشه:</label>
                                          <InputText className={"input w-full"} />
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_four" ? "active" : "" }`} id="tab_four">
                              <div className="rounded-md border dark:border-themeDarkColorLine">
                                  <div class=" p-2 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md">
                                      <p>ارتباطات</p>
                                  </div>
                                  <div className="bg-white dark:bg-themeDarkSalt700 p-2">
                                      <div className="flex flex-col lg:flex-row gap-2 p-2 post-input rounded-md dark:bg-themeDarkPostInput">
                                          <div className="space-y-1">
                                              <label className="text-xs dark:text-gray-300">ردیف:</label>
                                              <div className="input bg-themeInformation text-white dark:text-gray-300 text-center">
                                                  1
                                              </div>
                                          </div>
                                          <div className="flex">
                                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                                  <div className="space-y-1">
                                                      <label className="text-xs dark:text-gray-300">نوع ارتباط:</label>
                                                      <Select
                                                          placeholder={t("selection")}
                                                          classNamePrefix={"h_multi_select"}
                                                          menuPosition={"fixed"}

                                                      />
                                                  </div>
                                                  <div className="space-y-1">
                                                      <label className="text-xs dark:text-gray-300">نوع (حقیقی/حقوقی)</label>
                                                      <Select
                                                          placeholder={t("selection")}
                                                          classNamePrefix={"h_multi_select"}
                                                          menuPosition={"fixed"}
                                                      />
                                                  </div>
                                                  <div className="space-y-1">
                                                      <label className="text-xs dark:text-gray-300">آدرس ارتباط:</label>
                                                      <input type="text" className="input w-full" />
                                                  </div>
                                                  <div className="space-y-1 ">
                                                      <label className="text-xs dark:text-gray-300">توضیحات:</label>
                                                      <input type="text" className="input w-full"/>
                                                  </div>
                                              </div>
                                          </div>
                                          <div className="flex items-end justify-end gap-2">
                                              <button type="button" className="button small_button bg-themeDanger text-white ">
                                                  <HPrimeIcon icon={'pi-trash'}  />
                                              </button>

                                              <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white ">
                                                  <HPrimeIcon icon={'pi-plus'} size={'20'} />
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className={`tab-content__pane p-1 md:p-5 ${ activeTab === "tab_five" ? "active" : "" }`} id="tab_five">
                              <FileUploader message={ "فایل ها را اینجا رها کنید یا برای آپلود کلیک کنید" } mode={"image"} accept={"images/*"}/>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-span-12 lg:col-span-4">
                  <div className="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 gap-y-5">

                          <div className="space-y-2">
                              <label className="text-xs dark:text-gray-300"> ظرفیت:</label>
                              <InputText className="input w-full" />
                          </div>
                          <div className="space-y-2">
                              <label className="text-xs dark:text-gray-300"> متصدی:</label>
                              <Select
                                  placeholder={t("selection")}
                                  classNamePrefix={"h_multi_select"}
                                  menuPosition={"fixed"}
                              />
                              {/*<select name="" id="" className="select2">*/}
                              {/*    <option value="">کاشان</option>*/}
                              {/*</select>*/}
                          </div>

                          <div class="space-y-2 -intro-x">
                              <label className="text-xs dark:text-gray-300"> استان:</label>
                              <Select
                                  placeholder={t("selection")}
                                  classNamePrefix={"h_multi_select"}
                                  menuPosition={"fixed"}
                              />
                              {/*<select name="" id="" className="select2 ">*/}
                              {/*    <option value="">اصفهان</option>*/}
                              {/*</select>*/}
                          </div>
                          <div class="space-y-2 -intro-x">
                              <label className="text-xs dark:text-gray-300"> شهر:</label>
                              <Select
                                  placeholder={t("selection")}
                                  classNamePrefix={"h_multi_select"}
                                  menuPosition={"fixed"}
                              />
                              {/*<select name="" id="" className="select2">*/}
                              {/*    <option value="">اصفهان</option>*/}
                              {/*</select>*/}
                          </div>
                          <div class="space-y-2 -intro-x">
                              <label className="text-xs dark:text-gray-300"> منطقه:</label>
                              <Select
                                  placeholder={t("selection")}
                                  classNamePrefix={"h_multi_select"}
                                  menuPosition={"fixed"}

                              />
                              {/*<select name="" id="" className="select2 ">*/}
                              {/*    <option value="">اصفهان</option>*/}
                              {/*</select>*/}
                          </div>

                          <div class="-intro-x space-y-2 sm:col-span-2">
                              <label className="text-xs dark:text-gray-300">قالب:</label>
                              <Select
                                  placeholder={t("selection")}
                                  classNamePrefix={"h_multi_select"}
                                  menuPosition={"fixed"}
                              />
                                  {/*<select className="select2 w-full">*/}
                                  {/*    <option value="1">...</option>*/}
                                  {/*    <option value="2">فرم استخدام</option>*/}
                                  {/*    <option value="3">فرم پرداخت</option>*/}
                                  {/*    <option value="3">فرم نمایندگی</option>*/}
                                  {/*</select>*/}
                          </div>
                          <div class="sm:col-span-2 grid grid-cols-2 post-input dark:bg-themeDarkPostInput p-3 rounded">
                              <div class="space-y-2">
                                  <label className="text-xs dark:text-gray-300">وضعیت نمایش</label>
                                  <div>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                              </div>
                              <div class="space-y-2">
                                  <label className="text-xs dark:text-gray-300"> نماش درنقشه</label>
                                  <div>
                                      <input className="input input--switch border bg-white" type="checkbox"/>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class=" flex justify-center lg:justify-end gap-5">
              <button className="button text-white bg-themeDanger  w-24">انصراف</button>
              <button className="button text-white bg-themeInformation dark:bg-themeDarkInformation w-24">ثبت</button>
          </div>
      </div>
  )
}