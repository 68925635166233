import React from "react";
import {handleFailureMessage} from "../../../../../Services/Globals/Errors/NotificationService";
export const handleGetUserBanks = (requestObject,requiredObject)=>{
    const userBanks = requestObject.map(item => ({
        ...item,
        bankId: requiredObject.find(typeItem => typeItem.value === item.bankId)
    }));
    return userBanks;
}
export const handleConvertUserBanks = (obj,concurrencyStamp)=>{
    const userBanks = {
        concurrencyStamp:concurrencyStamp,
        userBanks: obj.map(item => ({
            bankId: item.bankId !== null? item.bankId.value:null,
            cardNumber:  item.cardNumber,
            shebaNumber: item.shebaNumber,
            accountNumber: item.accountNumber,
            isDefault:   item.isDefault,
            userBankId: item.userBankId
        }))
    };
    return userBanks
}
export const handleGetNoneNullUserBanks=(userBanks)=>{
    const nonNullRows = userBanks.filter((row) => row.shebaNumber && row.cardNumber && row.accountNumber && row.bankId);
    return nonNullRows;
}
export const handleCheckDefaultUserBanks = (userBanks) => {
    const hasDefaultBank = userBanks.filter(bank => bank.isDefault === true).length;

    return hasDefaultBank;
}

export const handleCheckDefaultBankId = (userBanks) => {
    const banksWithNullBankId = userBanks.every(bank => bank.bankId !== null && bank.bankId !== undefined);
    return banksWithNullBankId;
}