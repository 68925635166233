import React, {useState} from "react";

export const useProductChart=(setErrors)=>{
    const [productChart,setProductChart]=useState({date:null,price:0,currency:0,productName:null});
    const [filter,setFilters]=useState({fromDate:null,toDate:null,timeInterval:null})
    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency)
        {
            return '-'
        }
        return currency.label;
    }
    const handleNumberValue = (value,name)=>{
        setProductChart({...productChart,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setProductChart({...productChart,[name]: data});
    }

    const handleFilterFromDate = (toDate)=>{
        setFilters({...filter,fromDate: toDate});
    }
    const handleFilterToDate = (toDate)=>{
        setFilters({...filter,toDate: toDate});
    }


    const handleSelectFilterValue=(data,name)=>{
        setFilters({...filter,[name]: data});
    }
    const handleResetFilters = ()=>{
        setFilters(({...filter,fromDate: null,toDate: null,timeInterval: 0}));
    }
    const handleDate = (date)=>{
        setProductChart({...productChart,date: date});
    }



    return {
        productChart,
        setProductChart,
        handleNumberValue,
        handleSelectValue,
        handleFilterFromDate,
        handleFilterToDate,
        handleDate,
        handleSelectFilterValue,
        handleResetFilters,
        filter,
        handleGetCurrencyType

    }
}