import React, { useEffect, useMemo, useState } from "react";
import { InputText } from "primereact/inputtext";

import { handleSuccessMessage } from "../../../../Services/Globals/Errors/NotificationService";
import Select from "react-select";
import { DisplayError } from "../../../Commans/Errors/DisplayError";
import { LoadingHarmony } from "../../../Commans/Loadings/LoadingHarmony";
import { ToastContainer } from "react-toastify";
import { FantasyModal } from "../../../Commans/UIParts/FantasyModal";
import { useTranslation } from "react-i18next";
import { ListFilter } from "../../../Commans/Grid/ListFilter";
import { ButtonPlus } from "../../../Commans/UIParts/ButtonPlus";
import { Tooltip } from "react-tooltip";
import SortableTree from "@nosferatu500/react-sortable-tree";
import { useNavigate } from "react-router-dom";
import { convertSelectDataHandler } from "../../../CMS/Contents/ContentService";
import { useRole } from "./useRole";
import { validate } from "../../../../Services/Globals/validate";
import { GET, POST, PUT } from "../../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import { roleSchema } from "./roleSchema";
import { handleConvertAddUserRole, handleConvertEditUserRole, handleConvertToTree } from "./RoleService";
import { useRoleTree } from "./useRoleTree";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { ButtonSearchList } from "../../../Commans/UIParts/ButtonSearchList";
import { RequiredIcon } from "../../../Commans/Errors/RequiredIcon";
import { HPrimeIconSmall } from "../../../Commans/UIParts/HPrimeIconSmall";
import { OrganizationChart } from "primereact/organizationchart";
export const HandleUserRole = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [IsShowSpinner, setIsShowSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [IsOpen, setIsOpen] = useState(false);
    const [initialSource, setInitialSource] = useState({ roles: [] });
    const [IsEdit, setIsEdit] = useState(false);
    const [butLoading, setBtnLoading] = useState(false);
    const { handleSelectValue, setRole, role, handleValue, handleNumberValue } = useRole(setErrors);
    const handleIsOpen = () => {
        setRole(prevState => ({ ...prevState, name: '', parentId: initialSource.roles[0] }));
        setIsOpen(true);
    }
    const handleIsOpenClose = () => {
        setRole(prevState => ({ ...prevState, name: '', parentId: initialSource.roles[0], id: null }));
        setIsEdit(false);
        setErrors({});
        setIsOpen(false);
    }
    const onSetErrors = (errors) => {
        setErrors(errors);
    }
    const Refresh = () => {
        setRefresh(prevState => !prevState);
    }
    const ShowSpinner = () => {
        setIsShowSpinner(true);
    }
    const hideSpinner = () => {
        setIsShowSpinner(false);
    }
    const handleShowLoader = () => {
        setBtnLoading(true);
    }
    const handleHideLoader = () => {
        setBtnLoading(false);
    }
    const handleInitialSource = (rolesArray) => {
        const rolesData = convertSelectDataHandler(rolesArray);
        setInitialSource({
            roles: rolesData
        })
        return {
            rolesData
        }
    }
    const fetchData = async () => {
        try {
            ShowSpinner();
            const [responseRoleSelection, response] = await Promise.all([
                GET('/Roles/Selection', {}, 'IEnumerable'),
                GET(`/Roles`, {}, 'IEnumerable'),
            ]);
            if (responseRoleSelection.status === 200 && response.status === 200) {
                const { roles } = handleInitialSource(responseRoleSelection.data);
                expandAll();
                const tree = handleConvertToTree(response.data);
                setTreeData(tree);
            }
            else {
                handleApiError(responseRoleSelection, hideSpinner);
                handleApiError(response, hideSpinner);
            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };


    useEffect(() => {
        fetchData()
    }, [refresh])

    const { searchFocusIndex, searchFoundCount, searchString, setSearchFocusIndex, setSearchFoundCount, setSearchString, setTreeData, treeData, updateTreeData, expandAll, collapseAll, getNodeKey, selectNextMatch, selectPrevMatch, handleDeleteRole, customSearchMethod, handleMoveNode, handleAddNodeChild, handleGetNode, handleActivationProductGroup } = useRoleTree(ShowSpinner, hideSpinner, setIsOpen, initialSource, setInitialSource, setRole, role, setIsEdit, Refresh);


    const handleRoleSubmit = async (e) => {
        e.preventDefault();
        const isValid = validate(roleSchema, role, onSetErrors);
        if (!isValid) {
            return;
        }
        try {
            if (IsEdit) {
                handleShowLoader();
                const data = handleConvertEditUserRole(role);
                const response = await PUT(`/Roles`, data, false);
                if (response.status === 200) {
                    handleHideLoader();
                    expandAll();
                    Refresh();
                    handleIsOpenClose();
                    handleSuccessMessage(t('SuccessDone'));
                }
                else {
                    handleApiError(response, handleHideLoader)
                }
            }
            else {
                handleShowLoader();
                const data = handleConvertAddUserRole(role);
                const response = await POST(`/Roles`, data, false);
                if (response.status === 200) {
                    handleHideLoader();
                    expandAll();
                    Refresh();
                    handleIsOpenClose();
                    handleSuccessMessage(t('SuccessDone'));
                }
                else {
                    handleApiError(response, handleHideLoader)
                }
            }
        }
        catch (e) {
            handleHideLoader();
        }
    }


    const [testTree, setTestTree] = useState({
        key: '0',
        label: 'Documents',
        data: 'Documents Folder',
        icon: 'pi pi-fw pi-inbox',
        children: [
            {
                key: '0-0',
                label: 'Work',
                data: 'Work Folder',
                icon: 'pi pi-fw pi-cog',
                children: [
                    { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
                    { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
                ]
            },
            {
                key: '0-1',
                label: 'Home',
                data: 'Home Folder',
                icon: 'pi pi-fw pi-home',
                children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
            }
        ]
    });

    // const nodeTemplate = (node) => {
    //     return (
    //         <React.Fragment>
    //             <i className={node.icon}></i>
    //             <span>{node.name}</span>
    //         </React.Fragment>
    //     );
    // };

    const nodeTemplate = (node) => {
        return (
            <>
                <div className={'bg-themeInformation px-3 border dark:border-themeDarkColorLine py-5 rounded-md dark:bg-themeDarkPostInput space-y-3'}>
                    <div className="space-y-3">
                        <div>
                            <p className={"text-white text-xs dark:text-gray-300"}>{node.name}</p>
                        </div>
                    </div>
                    <div className={"flex gap-2 items-center post-input post-input-design dark:bg-themeDarkSalt700 p-1 w-max mx-auto"}>
                        <button type={'button'} onClick={() => handleAddNodeChild(node.id)} className="button bg-themeInformation dark:bg-themeDarkInformation">
                            <HPrimeIconSmall icon={'pi-plus'} iconClass={"text-white"} />
                        </button>
                        <button type={'button'} onClick={async () => await handleGetNode(node.id)} className="button bg-themeSuccess ">
                            <HPrimeIconSmall icon={'pi-pen-to-square'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async () => await handleDeleteRole(node.id)} className="button bg-themeDanger">
                            <HPrimeIconSmall icon={'pi-trash'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button onClick={() => redirectTo(`/CRM/User/Role/Permission?roleId=${node.id}`)} className="button tree_button tooltip_class_information bg-themeSecondary text-dark "  >
                            <HPrimeIconSmall icon={'pi-link'} />
                            <Tooltip anchorSelect={".tooltip_class_information"} content={t("Accesses")} />
                        </button>

                    </div>
                </div>
            </>
        )
    };







    return (
        <div className="space-y-5 relative ">
            {

                IsShowSpinner ?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony />
                        </div>
                    </div>
                    :
                    null
            }

            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={
                        <>
                            <ButtonPlus
                                onClick={() => handleIsOpen()}
                                tooltipTitle={t("CreatingRole")}
                            />

                            <ButtonPlus
                                onClick={() => redirectTo(`/CRM/User/Add`)}
                                tooltipTitle={t("UsersRegistration")}
                                icon={"pi-user-plus"}
                                title={t("personnel")}
                            />

                            <ButtonPlus
                                onClick={() => redirectTo(`/CRM/User/List`)}
                                icon={"pi-list"}
                                title={t("list")}
                                tooltipTitle={t("CustomerList")}
                            />
                            <ButtonPlus
                                onClick={() => redirectTo(`/CRM/User/Group`)}
                                icon={"pi-users"}
                                title={t("Group")}
                                tooltipTitle={t("CustomerGrouping")}
                            />

                        </>
                    }

                />


            </div>
            <div class="p-2 box bg-white dark:bg-themeDarkSalt700 ">
                <div className={"grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 post-input dark:bg-themeDarkPostInput post-input-design"}>
                    <div className={"p-2 flex gap-2 items-center"}>
                        <InputText type="search" className="input " placeholder={t("search")} value={searchString} onChange={(e) => setSearchString(e.target.value)} />
                        <ButtonSearchList
                        />
                    </div>
                </div>
            </div>

            <div>


                {/*<div className="h-screen bg-white box dark:bg-themeDarkSalt700 ">*/}

                {/*  <SortableTree*/}
                {/*      treeData={treeData}*/}
                {/*      onMoveNode={(e)=>handleMoveNode(e)}*/}
                {/*      onChange={(treeData) => updateTreeData(treeData)}*/}
                {/*      searchMethod={customSearchMethod}*/}
                {/*      searchQuery={searchString}*/}
                {/*      searchFocusOffset={searchFocusIndex}*/}
                {/*      searchFinishCallback={(matches) => {*/}
                {/*        setSearchFoundCount(matches.length);*/}
                {/*        setSearchFocusIndex(*/}
                {/*            matches.length > 0 ? searchFocusIndex % matches.length : 0*/}
                {/*        );*/}
                {/*      }}*/}
                {/*      canDrag={({ node }) => !node.dragDisabled}*/}
                {/*      generateNodeProps={(rowInfo) => ({*/}

                {/*        buttons: [*/}
                {/*          <div className="flex space-x-2 items-center">*/}
                {/*            <button onClick={(event) => handleAddNodeChild(rowInfo)} className="button tree_button tooltip_class_group bg-themeInformation dark:bg-themeDarkInformation text-white " >*/}
                {/*                <HPrimeIconSmall*/}
                {/*                icon={"pi-plus-circle"}*/}
                {/*                />*/}
                {/*              <Tooltip anchorSelect={".tooltip_class_group"} content={t("GroupCreation")} />*/}
                {/*            </button>*/}

                {/*              {*/}
                {/*                 rowInfo.node.isPrime?null:*/}
                {/*                     <>*/}
                {/*                         <button onClick={(event) => handleGetNode(rowInfo)} className="button tree_button tooltip_class_Edit bg-themeSuccess text-white "  >*/}
                {/*                             <HPrimeIconSmall icon={'pi-pen-to-square'} />*/}
                {/*                             <Tooltip anchorSelect={".tooltip_class_Edit"} content={t("edit")} />*/}
                {/*                         </button>*/}

                {/*                         <button onClick={(event) => handleDeleteRole(rowInfo.node.id)} className="button tree_button tooltip_class_delete bg-themeDanger text-white "  ><HPrimeIconSmall icon={'pi-trash'} />*/}
                {/*                             <Tooltip anchorSelect={".tooltip_class_delete"} content={t("delete")} />*/}
                {/*                         </button>*/}
                {/*                     </>*/}

                {/*              }*/}

                {/*           <button onClick={()=>redirectTo(`/CRM/User/Role/Permission?roleId=${rowInfo.node.id}`)} className="button tree_button tooltip_class_information bg-themeSuccess text-white "  ><HPrimeIconSmall icon={'pi-link'} />*/}
                {/*              <Tooltip anchorSelect={".tooltip_class_information"} content={t("Accesses")} />*/}
                {/*            </button>*/}
                {/*          </div>
          {/*        ],*/}
                {/*        style: {*/}
                {/*          height: "50px"*/}
                {/*        }*/}
                {/*      })}*/}
                {/*  />*/}
                {/*</div>*/}



                <div className="grid-cols-1 grid h-screen overflow-auto  bg-white dark:bg-themeDarkSalt700 box p-2">
                    {
                        treeData.length === 0 ? null :
                            <OrganizationChart
                                onChange={(e) => updateTreeData(e.value)}
                                value={treeData}
                                nodeTemplate={nodeTemplate}

                            />
                    }

                </div>


            </div>









            <ToastContainer />



            <FantasyModal openModal={IsOpen} closeModal={handleIsOpenClose} title={t("CreatingRole")}>
                <form method={'post'} onSubmit={handleRoleSubmit} className=" space-y-5">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 items-end">
                        <div class="space-y-1">
                            <div className="flex justify-between">
                                <label className="dark:text-gray-300 text-xs">
                                    {t("RoleTitle")}
                                    <RequiredIcon />
                                </label>
                                <DisplayError message={errors.name} />
                            </div>
                            <InputText placeholder={t("RoleTitle")} type={"text"} name={'name'} value={role.name} onInput={(e) => handleValue(e)} className={" input w-full"} />
                        </div>
                        <div class="space-y-1">
                            <label className="dark:text-gray-300 text-xs">{t("RoleWorkgroup")}</label>
                            <Select
                                options={
                                    role.id === null ?
                                        initialSource.roles
                                        :
                                        initialSource.roles.filter((item) => item.value !== role.id)

                                }
                                isMulti={false}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data) => handleSelectValue(data, 'parentId')}
                                placeholder={t("selection")}
                                value={role.parentId}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                    </div>
                    <div>
                        <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">
                            {t("register")}
                        </button>
                    </div>
                </form>
            </FantasyModal>

        </div>
    )
}