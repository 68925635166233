export function handleConvertAddCart(obj,productCartItems) {
    const data = {
        userId: obj.userId === null || obj.undefined ? null : obj.userId.value,
        priceType: obj.priceType === null || obj.priceType === undefined ? null : obj.priceType.value,
        creationDate: obj.creationDate === null || obj.creationDate === undefined ? null : obj.creationDate,
        userAddressId: obj.userAddressId === null || obj.userAddressId === undefined ? null : obj.userAddressId.value,
        address: obj.address === null || obj.address === undefined ? null : obj.address,
        detailsOfAddress: obj.detailsOfAddress === null || obj.detailsOfAddress === undefined ? null : obj.detailsOfAddress,
        postalCode: obj.postalCode === null || obj.postalCode === undefined ? null : obj.postalCode.toString(),
        countryId: obj.countryId === null || obj.countryId === undefined ? null : obj.countryId.value,
        stateProvinceId: obj.stateProvinceId === null || obj.stateProvinceId === undefined ? null : obj.stateProvinceId.value,
        cityId: obj.cityId === null || obj.cityId === undefined ? null : obj.cityId.value,
        isAddressSaved: obj.IsAddressSaved,
        productCartItems: productCartItems.map((item, index) => ({
            productId: item.productId.value,
            productTransportId:item.productTransportId === null || item.productTransportId === undefined?null:item.productTransportId.value,
            quantity: item.quantity === null || item.quantity === undefined ? 0 : item.quantity,
            cartItemType: item.cartItemType.value,
            description: item.description === null || item.description === undefined || item.description === '' ? null : item.description
        })),
    }
    return data;
}
export const handleGetUserAddress = (data) => {
    return data.map(item => ({
        label: `${item.countryName}   ${item.stateProvinceName}   ${item.cityName}`,
        value: item.key,
        stateProvinceId: item.stateProvinceId,
        countryId: item.countryId,
        cityId: item.cityId,
        address: item.address,
        postalCode: item.postalCode
    }));
};
export function handleConvertEditCart(obj,productCartItems){
    const data = {
        productCartId:obj.id,
        userId:obj.userId === null || obj.undefined?null:obj.userId.value,
        priceType:obj.priceType === null || obj.priceType === undefined?null:obj.priceType.value,
        creationDate:obj.creationDate === null || obj.creationDate === undefined?null:obj.creationDate,
        userAddressId:obj.userAddressId === null || obj.userAddressId === undefined?null:obj.userAddressId.value,
        address:obj.address === null || obj.address === undefined?null:obj.address,
        detailsOfAddress:obj.detailsOfAddress === null || obj.detailsOfAddress === undefined ?null: obj.detailsOfAddress,
        postalCode:obj.postalCode === null || obj.postalCode === undefined?null:obj.postalCode.toString(),
        countryId:obj.countryId === null || obj.countryId === undefined?null:obj.countryId.value,
        stateProvinceId:obj.stateProvinceId === null || obj.stateProvinceId === undefined ?null:obj.stateProvinceId.value,
        cityId:obj.cityId === null || obj.cityId === undefined ?null:obj.cityId.value,
        isAddressSaved:obj.IsAddressSaved,
        productCartItems: productCartItems.map((item, index) => ({
            productId: item.productId.value,
            productTransportId:item.productTransportId === null || item.productTransportId === undefined?null:item.productTransportId.value,
            quantity: item.quantity === null || item.quantity === undefined ? 0 : item.quantity,
            cartItemType: item.cartItemType.value,
            description: item.description === null || item.description === undefined || item.description === '' ? null : item.description
        })),
    }
    return data;
}

export function handleFilterProductCartItems(productCartItems,productTypes,cartTypes,productTransports) {
    const data = productCartItems.map((cartItem,index) => ({
        id: cartItem.id,
        productId: productTypes.find((product) => product.value === cartItem.productId),
        quantity: cartItem.quantity === null || cartItem.quantity === undefined?0:cartItem.quantity,
        cartItemType: cartTypes.find((cartType) => cartType.value === cartItem.cartItemType),
        productTransportId:productTransports.find((trans) => trans.value === cartItem.productTransportId),
        description: cartItem.description === null || cartItem.description === undefined || cartItem.description === '' ? null : cartItem.description
    }));
    return data;
}

export function handleCreateBill(obj) {
    const data = {
        productCartId: obj.productCartId,
        creationDate: obj.creationDate,
        expirationDate: obj.expirationDate,
        applyDiscount: obj.applyDiscount,
        applyTax:obj.applyTax,
        applyOffers:obj.applyOffers,
        priceType:0
    }
    return data;
}

export function validateQuantity(obj) {
    for (const item of obj.productCartItems) {
        if (item.quantity < 1) {
            return false;
        }
    }
    return true;
}
export function handleConvertProductCartItems(productCartItemId,productCartItems,cart){
    const data = {
        productCartId:productCartItemId,
        userId:cart.userId === null || cart.undefined?null:cart.userId.value,
        priceType:cart.priceType === null || cart.priceType === undefined?null:cart.priceType.value,
        creationDate:cart.creationDate === null || cart.creationDate === undefined?null:cart.creationDate,
        productCartItems: productCartItems.map((item, index) => ({
            productId:item.productId.value,
            quantity:item.quantity === null || item.quantity === undefined?0:item.quantity,
            cartItemType:item.cartItemType.value,
            description:item.description === null || item.description === undefined || item.description === ''?null:item.description
        })),
    }
    return data;
}
export function handleCheckProductTransportId(productCartItems) {
    return !productCartItems.some(item => item.productTransportId === null);
}
