import React from "react";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import Select from "react-select";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";

export const ProductTypeAttach=({totalSize, IsEdit, showSpinner, hideSpinner, setCards, deleteCard, addNewCard, handleFieldChange, handleFileChange, triggerFileInput, cards, initialSource,currency,handleGetCurrencyType})=>{
    const { t } = useTranslation();

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const getFileIcon = (fileType,filePath) => {
        return filePath
    };

    const displayFile=(filePath)=>{
        window.open(filePath);
    }

    return (
        <>

            <div className={"space-y-5"}>
                {cards.map((card, index) => (

                    <div className="post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-3" key={index}>
                        <div className={"flex flex-col md:flex-row items-center gap-3 "}>
                            <div className={"w-full md:w-40 h-40 flex-shrink-0"}>
                                <div className="size-full relative border-2 bg-white dark:bg-themeDarkPostInput rounded-md border-dashed border-gray-300 dark:border-themeDarkColorLine ">
                                    <input
                                        type="file"
                                        id={`file-input-${index}`}
                                        style={{ display: "none" }}
                                        onChange={(e) => handleFileChange(index, e)}
                                    />
                                    <label
                                        htmlFor={`file-input-${index}`}
                                        className="size-full flex items-center justify-center cursor-pointer relative"
                                    >
                                        {card.imagePath ? (
                                            <>

                                                <img
                                                    src={getFileIcon(card.fileType,card.imagePath)}
                                                    alt="File preview"
                                                    className="size-full object-cover"
                                                />

                                            </>
                                        ) : (
                                            <>

                                                <img
                                                    src="/Assets/Image/bg-img/add-photo.png"
                                                    alt="Default"
                                                    className="size-full object-cover"
                                                />

                                            </>
                                        )}
                                        { card.imagePath  ?

                                                <button
                                                    className={"absolute w-full bottom-0 end-0 start-0 bg-themeInformation dark:bg-themeDarkInformation p-1 grid place-content-center"}
                                                    onClick={()=>displayFile(card.imagePath)}
                                                    type={"button"}
                                                >
                                                    <HPrimeIconSmall icon={"pi-eye"} iconClass={`text-white  tooltip_class_${index}`} />
                                                    <Tooltip anchorSelect={`.tooltip_class_${index}`} content={t("ViewFile")}  positionStrategy={"fixed"}	 />
                                                </button>


                                            :null
                                        }

                                    </label>

                                </div>
                            </div>
                            <div className={"flex-1"}>
                                <div className={"grid grid-cols-2 lg:grid-cols-4 gap-y-5 gap-x-3"}>
                                    <div className={"space-y-1"}>
                                        <label className={"text-xs dark:text-gray-300"}>{t("Featuretype")}</label>
                                        <Select
                                            options={initialSource.propertyTypes}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data) => handleFieldChange(index, "propertyType", data)}
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                            value={card.propertyType}
                                        />
                                    </div>
                                    <div className={"space-y-1"}>
                                        <label className={"text-xs dark:text-gray-300"}>{t("Featurevalue")}</label>
                                        <input
                                            type="text"
                                            value={card.propertyValue}
                                            onChange={(e) =>
                                                handleFieldChange(index, "propertyValue", e.target.value)
                                            }
                                            className="input w-full"
                                        />
                                    </div>
                                    <div className=" space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("UnitPrice")}</label>
                                        <InputNumber
                                            value={card.price}
                                            onChange={(e) =>
                                                handleFieldChange(index, "price", e.value)
                                            }
                                            min={0}
                                            suffix={handleGetCurrencyType(currency,initialSource.currencies)}
                                            placeholdert={t('UnitPrice')}
                                        />
                                    </div>
                                    <div className=" space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("WholesalePrice")}</label>
                                        <InputNumber
                                            value={card.wholesalePrice}
                                            onChange={(e) =>
                                                handleFieldChange(index, "wholesalePrice", e.value)
                                            }
                                            min={0}
                                            suffix={handleGetCurrencyType(currency,initialSource.currencies)}
                                            placeholdert={t('WholesalePrice')}
                                        />
                                    </div>
                                    <div className=" space-y-1 col-span-2 md:col-span-1">
                                        <div className="flex items-center gap-2">
                                            <label className="text-xs dark:text-gray-300">{t("ProductCode")}</label>
                                            <div class="can-toggle demo-rebrand-1 text-xs w-32">
                                                <input id={index} type="checkbox" checked={card.isAuto}  onChange={(e) =>
                                                    handleFieldChange(index, "isAuto", e.target.checked)}  className={"input w-full "}  />
                                                <label htmlFor={index}>
                                                    <div class="can-toggle__switch" data-checked={t("automatic")} data-unchecked={t("manual")}></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <InputText type={"text"} disabled={card.isAuto}   onChange={(e) =>
                                                handleFieldChange(index, "productCode", e.target.value)}  className={"input w-full "}
                                            />
                                        </div>
                                    </div>
                                    <div className=" space-y-1 col-span-2 md:col-span-1">
                                        <label className="text-xs dark:text-gray-300">{t("IranCode")}</label>
                                        <InputText type={"text"}    onChange={(e) =>
                                            handleFieldChange(index, "iranCode", e.target.value)}  className={"input w-full "}
                                        />
                                    </div>
                                    <div className=" space-y-1 col-span-2 md:col-span-1">
                                        <label className="text-xs dark:text-gray-300">{t("ProductBarcode")}</label>
                                        <InputText type={"text"}    onChange={(e) => handleFieldChange(index, "barCode", e.target.value)}  className={"input w-full "}/>
                                    </div>
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("Supplier")}</label>
                                        <Select
                                            options={initialSource.users}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data) => handleFieldChange(index, "supplierId", data)}
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                            value={card.supplierId}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"flex items-center gap-3"}>
                            <div className={"flex-1"}>
                                <div className={"md:col-span-2 space-y-4"}>
                                    <div className={"space-y-3"}>
                                        <div className={"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-5 gap-x-3"}>
                                            <div className="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("Manufacturer")}</label>
                                                    <Select
                                                        options={initialSource.users}
                                                        isMulti={false}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(data) => handleFieldChange(index, "producerId", data)}
                                                        placeholder={t("selection")}
                                                        classNamePrefix={"h_multi_select"}
                                                        menuPosition={"fixed"}
                                                        value={card.producerId}
                                                    />
                                                </div>
                                            <div className=" space-y-1 col-span-2 md:col-span-3 ">
                                                    <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                                    <InputText type={"text"}   onChange={(e) =>
                                                        handleFieldChange(index, "excerpt", e.target.value)}  className={"input w-full "}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                    <div className={"flex items-center gap-2 "}>
                                        <div className={"grid grid-cols-2 md:grid-cols-3 gap-2 flex-1"}>
                                            <div className="space-y-1">
                                                <label className="text-xs dark:text-gray-300">{t("ReleaseFor")}</label>
                                                <Select
                                                    options={initialSource.filters}
                                                    isMulti={false}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(data) => handleFieldChange(index, "filterId", data)}
                                                    placeholder={t("selection")}
                                                    classNamePrefix={"h_multi_select"}
                                                    menuPosition={"fixed"}
                                                    value={card.filterId}
                                                />
                                            </div>
                                            <div className="space-y-1 ">
                                                <label className="text-xs dark:text-gray-300">{t("SocialMediaSharing")}</label>
                                                <Select
                                                    options={initialSource.SendingStates}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(data) => handleFieldChange(index, "sendingStates", data)}
                                                    placeholder={t("selection")}
                                                    classNamePrefix={"h_multi_select"}
                                                    menuPosition={"fixed"}
                                                    value={card.sendingStates}
                                                />
                                            </div>
                                            <div className={"flex gap-2 items-center justify-around"}>
                                                <div className=" space-y-1 text-center">
                                                    <label className="text-xs dark:text-gray-300">
                                                        {t("DisplayStatus")}
                                                    </label>
                                                    <div>
                                                        <input checked={card.stateShow} name={'stateShow'} onChange={(e) =>
                                                            handleFieldChange(index, "stateShow", e.target.checked)}   className="input  input--switch border bg-white" type="checkbox"/>
                                                    </div>
                                                </div>
                                                <div className=" space-y-1 text-center">
                                                    <label className="text-xs dark:text-gray-300">
                                                        {t("SendToAndroid")}
                                                    </label>
                                                    <div>
                                                        <input checked={card.stateAndroid} name={'stateAndroid'} onChange={(e) =>
                                                            handleFieldChange(index, "stateAndroid", e.target.checked)}   className="input  input--switch border bg-white" type="checkbox"/>                                                    </div>
                                                </div>
                                                <div className=" space-y-1 text-center">
                                                    <label className="text-xs dark:text-gray-300">
                                                        {t("stateGoogle")}
                                                    </label>
                                                    <div>
                                                        <input checked={card.stateGoogle} name={'stateGoogle'} onChange={(e) =>
                                                            handleFieldChange(index, "stateGoogle", e.target.checked)}   className="input  input--switch border bg-white" type="checkbox"/>                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"flex gap-2 justify-end"}>

                                        <button
                                            type={"button"}
                                            className="button bg-themeDanger small_button text-white"
                                            onClick={() => deleteCard(index,card.productId)}>
                                            <HPrimeIcon icon={"pi-minus"} />
                                        </button>

                                        <button
                                                className="button bg-themeInformation small_button text-white dark:bg-themeDarkInformation"
                                                onClick={addNewCard}
                                                type={"button"}
                                            >
                                                <HPrimeIcon icon={"pi-plus"} />
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                ))}

            </div>

        </>
    )
}