import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TabMenuTwo} from "../../Commans/UIParts/TabMenuTwo";
import {useLogErrorsTab} from "./useLogErrorsTab";
import {Log} from "./Logs/Log";
import {StackTrace} from "./Logs/StackTrace";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {LogDetails} from "./Logs/LogDetails";
import Select from "react-select";
import {InnerException} from "./Logs/InnerException";

export const LogErrorsBody=({logData,logError,handleSelectLogData,logId})=>{

    const { t } = useTranslation();
    const {tabId,tabs, handleChangeTabId,setTabs}=useLogErrorsTab();



    return <>

        <div className={"H-direction-ltr space-y-5"}>
            <div class="p-2 box bg-white dark:bg-themeDarkSalt700 ">
                <div
                    class="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 post-input dark:bg-themeDarkPostInput post-input-design p-2">
                    <div class="space-y-1">
                        <label className="text-xs dark:text-gray-300">Domain Filter</label>
                        <Select
                            placeholder="selection"
                            classNamePrefix={"h_multi_select"}
                        />

                    </div>
                </div>
            </div>
            <div className={"space-y-3 bg-themeInformation tabs_index box p-3"}>
                <div className={'relative grid grid-cols-2 lg:grid-cols-5 gap-3 '}>
                    <div>
                        <span className={"text-white"}>Creation Date:</span>
                        <p className={"text-white text-xs"}>{ logData && <GridDateFomartter date={logData.logDate} format={'jYYYY/jMM/jDD HH:mm:ss'} />}</p>
                    </div>
                    <div>
                        <span className={"text-white"}>Url:</span>
                        <p className={'text-white text-xs line-clamp-1'} >{logData && logData.serverVariable && logData.serverVariable.RequestPath}</p>
                    </div>
                    <div>
                        <span className={"text-white"}>Client IP:</span>
                        <p className={'text-white text-xs line-clamp-1'} >{logData && logData.serverVariable && logData.serverVariable.IpAddress}</p>
                    </div>
                    <div>
                        <span className={"text-white"}>Source:</span>
                        <p className={"text-white text-xs line-clamp-1"}>{logData && logData.source}</p>
                    </div>
                    <div>
                        <span className={"text-white"}>User:</span>
                        <p className={"text-white text-xs line-clamp-1"}>{logData && logData.serverVariable && logData.serverVariable.User}</p>
                    </div>
                </div>
                <div>
                    <p className={"text-xs text-white"}>{logData &&  logData.message}</p>
                </div>
                {
                    logData?.innerExceptionMessage?
                    <div>
                        <p className={"text-xs text-white"}>{logData &&  logData?.innerExceptionMessage}</p>
                    </div>
                        :null

                }
            </div>

                <div class="relative grid grid-cols-2 lg:grid-cols-7 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-3">
                    <TabMenuTwo activeTab={tabId} handleTabClick={handleChangeTabId} tabs={tabs} />
                </div>

                <div className={'grid grid-cols-12 gap-3 '}>
                    <div className={'col-span-12 xl:col-span-2 p-2 space-y-2 bg-white dark:bg-themeDarkSalt700 max_height40'}>
                        <div className={"overflow-x-auto"}>
                            <div className={"flex xl:flex-col gap-2"}>
                                {
                                    logError.map((log, index) => (
                                        <Log
                                            key={index}
                                            setTabs={setTabs}
                                            log={log}
                                            isActive={logId === log.id}
                                            handleSelectLogData={handleSelectLogData}
                                        />
                                    ))
                                }
                            </div>

                        </div>

                    </div>

                    <div className={'col-span-12 xl:col-span-10  max_height40'}>

                        <div class="post__content tab-content box bg-white dark:bg-themeDarkSalt700 p-2">
                            <div class={`tab-content__pane ${ tabId === "StackTrace" ? "active" : "" }`} id="StackTrace">
                                {
                                    logData !== null && tabId === 'StackTrace'?
                                        <StackTrace message={logData.stackTrace} />:null
                                }
                            </div>
                            <div class={`tab-content__pane space-y-5 ${tabId === "Header" ? "active" : ""}`} id="Header" >
                                {
                                    logData !== null && tabId === 'Header'?
                                        <LogDetails details={logData.requestHeader} />:null
                                }
                            </div>
                            <div class={`tab-content__pane ${tabId === "Connection" ? "active" : ""}`} id="Connection" >
                                {
                                    logData !== null && tabId === 'Connection'?
                                        <LogDetails details={logData.connection} />:null
                                }
                            </div>
                            <div class={`tab-content__pane ${ tabId === "Variables" ? "active" : "" }`} id="Variables">
                                {
                                    logData !== null && tabId === 'Variables'?
                                        <LogDetails details={logData.serverVariable} />:null
                                }
                            </div>
                            <div class={`tab-content__pane ${ tabId === "innerException" ? "active" : "" }`} id="innerException">
                                {
                                    logData !== null && tabId === 'innerException'?
                                        <InnerException message={logData.innerExceptionStackTrace} />:null
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>

    </>
}