import React, {useState} from "react";

export const usePackageService=(setUpgrade,initialSource)=>{
    const handleAddOrDeletePackage = (packageId,name) => {
        setUpgrade(prevState => {
            const packageIds = [...prevState.extraPackages.packageIds]; // Clone the array
            const index = packageIds.findIndex(pkg => pkg.packageId === packageId);

            if (index !== -1) {
                // Remove the package if it exists
                packageIds.splice(index, 1);
            }
            else {
                // Add a new package if it doesn't exist
                const newRow = {
                    packageId: packageId,
                    price: 0,
                    name:name
                };
                packageIds.push(newRow);
            }

            // Return updated state
            return {
                ...prevState,
                extraPackages: {
                    ...prevState.extraPackages,
                    packageIds: packageIds
                }
            };
        });
    };
    const handleChangePackage = (packageId, field, value) => {
        setUpgrade(prevState => {
            const updatedPackageIds = prevState.extraPackages.packageIds.map(pkg => {
                if (pkg.packageId === packageId) {
                    return { ...pkg, [field]: value };
                }
                return pkg;
            });

            return {
                ...prevState,
                extraPackages: {
                    ...prevState.extraPackages,
                    packageIds: updatedPackageIds
                }
            };
        });
    };



    const handleChangePackageDateTime = (value,packageId,field) => {
        setUpgrade(prevState => {
            const updatedPackageIds = prevState.extraPackages.packageIds.map(pkg => {
                if (pkg.packageId === packageId) {
                    return { ...pkg, [field]: value };
                }
                return pkg;
            });

            return {
                ...prevState,
                extraPackages: {
                    ...prevState.extraPackages,
                    packageIds: updatedPackageIds
                }
            };
        });
    };

    return {
        handleAddOrDeletePackage,
        handleChangePackage,
        handleChangePackageDateTime
    }
}