import Joi from "joi";
export const settingPasswordSchema = Joi.object({
    newPassword: Joi.string()
        .required()
        .messages({
            "any.required": "تکرار کلمه عبور الزامیست",
            "string.empty": "تکرار کلمه عبور الزامیست",
            'string.base': 'کلمه عبور الزامیست',
        }),
    confirmPassword: Joi.string()
        .required()
        .valid(Joi.ref("newPassword"))
        .messages({
            "any.required": "تکرار کلمه عبور الزامیست",
            "string.empty": "تکرار کلمه عبور الزامیست",
            'string.base': 'تکرار کلمه عبور الزامیست',
            "any.only": "تکرار کلمه عبور با کلمه عبور مطابقت ندارد",
        }),

});
