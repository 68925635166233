import React, {useEffect, useMemo, useState} from "react";
import {GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";

export const useGridLog=(showSpinner,hideSpinner,initialSource)=>{
    const {t} = useTranslation();
    const queryParams = queryString.parse(window.location.search);


    const handleGetHttpMethod = (httpMethod) => {
        if (!initialSource.httpMethods) {
            return;
        }
        const data = initialSource.httpMethods.find((item) => item.value === httpMethod);
        return data ? data.label : '';
    }
    const handleGetBrowserName = (browser) => {
        if (!initialSource.httpMethods) {
            return;
        }
        const data = initialSource.browserTypes.find((item) => item.value === browser);
        return data ? data.label : '';
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Address"),

            cellRenderer:parameters=>
                <>
                    {parameters.data.referrerUrl}
                </>
        },
        { headerName: t("BrowserType"), maxWidth: 120,
            cellRenderer:parameters=>
                <>
                    {handleGetBrowserName(parameters.data.browser)}
                </>
        },

        { headerName: t("TypeOfRequest"), maxWidth: 150,
            cellRenderer:parameters=>
                <>
                    {handleGetHttpMethod(parameters.data.httpMethod)}

                </>
        },
        { headerName: t("user"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.username === null?"-":parameters.data.username}
                </>
        },

        { headerName: t("CreationDate"),maxWidth: 150,
            cellRenderer:parameters=>
                <> <GridDateFomartter date={parameters.data.createdOnUtc} hasTime={false} /></>
        },
        { headerName: "Controller",
            cellRenderer:parameters=>
                <>{parameters.data.controller}</>
        },
        { headerName: "Action",
            cellRenderer:parameters=>
                <>{parameters.data.action}</>
        },
        { headerName: "Ip",
            cellRenderer:parameters=>
                <>{parameters.data.ip}</>
        },
    ];


    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',httpMethod:'',httpMethodValue:null,user:'',userId:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',httpMethod:'',httpMethodValue: null,user: '',userId: null}));
        setSearch('');
    }

    const handleSelectFilterByHttpMethod=(data,name)=>{
        console.log(data);
        setFilter(({...filter,[name]:data.value,httpMethodValue: data}));
    }
    const handleSelectFilterByUser=(data,name)=>{
        console.log(data);
        setFilter(({...filter,[name]:data.value,userId:data}));
    }


    useEffect(() => {
        OnGridReady();
    }, [pagination.currentPage, filter.search, pagination.pageSize,filter.httpMethod,filter.user]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            showSpinner();
            const userId = queryParams.userId?queryParams.userId:filter.user
            const response =  await GET(`/Users/Logs`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,HttpMethod:filter.httpMethod,userId:userId}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };




    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilterByHttpMethod,
        handleSelectFilterByUser

    }
}