import Joi from 'joi';

export const userReminderSchema = Joi.object({
    title: Joi.string().required().messages({
        'any.required': 'عنوان یادآور الزامیست',
        'string.empty': 'عنوان یادآور الزامیست',
        'string.base':'عنوان یادآور الزامیست',
    }),
}).options({ abortEarly: false });

