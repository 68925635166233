import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
    handleFailureMessage,
    handleSuccessMessage,
} from "../../../Services/Globals/Errors/NotificationService";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Select from "react-select";
import {LoadingHarmony} from "../Loadings/LoadingHarmony";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";

//// سناریو
//وقتی کاربر فایل یا عکسی رو انتخاب کرد یه لیست از uploads  ها ساخته میشه و همه ی اون ها به تعداد فایل های انتخابی false  هستن
// وقتی کاربر فایلی ها اپلود میکنه به طور متناطر ارسالی ها true  میشن
// برای انتخاب مجدد فایل  و ارسال ان ها فقط ان هایی false هستن به formData اضافه میشن
// برای همین سناریو یه لیست از فایل های edit شده هم داریم که موقع انتخاب فایل false  بعد از ویرایش true میشن
// زمانی که داریم formData  رو میسازیم باید چک کنیم فایل هایی uploads  ها true  و edit list  ان ها هم true  هستن باید api ویرایش captions vرو صدا بزنیم
// برای ارسال captions های عکس ها دقیقا همین سناریو رو داریم به ازای فایل ها یه لیست متناطز از captions ها ساخته میشه



export const FileUploader = (props) => {

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const { message,changeResult} = props;
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [attachmentType, setAttachmentType] = useState([]);
    const [spinner,setSpinner] = useState(false);
    const onApiError = () => {
        setSpinner(false);
    };
    const[attachOptions,setAttachOptions] = useState({attachs:[]})
    const handleFetchData = async () => {
        try {
            const queryParams = queryString.parse(window.location.search);
            const responseAttach = await GET('/Enumerations/EnContentAttachType',{},'IEnumerable');
            if (responseAttach.status === 200) {
                const attachTypes = convertSelectDataHandler(responseAttach.data);
                setAttachOptions((prevOptions) => ({ ...prevOptions, attachs: attachTypes }));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(()=>{
        handleFetchData();
    },[])

    const handleClear = ()=>{
        setFiles([]);
        fileUploadRef.current.clear(); // Clear the files in the FileUpload component
        // Reset totalSize state
    }
    const onTemplateSelect = (e) => {
        let _totalSize = 0; // Initialize _totalSize as 0
        let selectedFiles = e.files;
        console.log(e)
        let newAttachmentTypes = Array(selectedFiles.length).fill(null);
        setAttachmentType(newAttachmentTypes);
        Object.keys(selectedFiles).forEach((key) => {
            _totalSize += selectedFiles[key].size || 0;
        });
        setFiles(selectedFiles);
        setTotalSize(_totalSize);

    };

    const createFormData = (files) => {
        if(props.contentId && props.contentId !== null){
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(`Files[${i}].file`, files[i]);
                formData.append(`Files[${i}].dataType`, attachmentType[i].value);
                formData.append(`Files[${i}].caption`, attachmentType[i]?.caption || ''); // Include caption
                formData.append(`Files[${i}].groupOrder`, attachmentType[i]?.group || ''); // Include caption
            }
            formData.append('Id',props.contentId);
            formData.append('concurrencyStamp',props.concurrencyStamp);
            return formData;
        }
        else{
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append(`Files[${i}].file`, files[i]);
                formData.append(`Files[${i}].dataType`, attachmentType[i].value);
                formData.append(`Files[${i}].caption`, attachmentType[i]?.caption || '');
                formData.append(`Files[${i}].groupOrder`, attachmentType[i]?.group || '');// Include caption
            }
            formData.append('Id',null);
            //formData.append('concurrencyStamp',null);
            return formData;
        }
    };
    //.....

    const onTemplateUpload = async (e) => {
        e.preventDefault();
        if(props.contentId && props.contentId !== null){
            try {
                if(files.length === 0){
                    handleFailureMessage(t("PleaseSelectFile"));
                    return;
                }
                let _totalSize = 0;
                const fileData = createFormData(files);
                setSpinner(true);
                const response = await POST('/Contents/BuildContentViaFileUpload',fileData,true);
                if(response.status === 200){
                    handleSuccessMessage(t("YourFilesHaveBeenSentSuccessfully"));
                    props.handleReload(response.data.id);
                    handleClear();
                    setSpinner(false);
                }else{
                    handleApiError(response,onApiError);
                }
                setTotalSize(_totalSize);
            }catch (e) {
                console.log(e);
            }
        }
        else{
            try {
                if(files.length === 0){
                    handleFailureMessage(t("PleaseSelectFile"));
                    return;
                }
                let _totalSize = 0;
                const fileData = createFormData(files);
                setSpinner(true);
                const response = await POST('/Contents/BuildContentViaFileUpload',fileData,true);
                if(response.status === 200){
                    handleSuccessMessage(t("فایل های شما با موفقیت ارسال شد"));
                  //  changeResult(response.status,response.data.id);
                    props.handleReload(response.data.id);
                    handleClear();
                    setSpinner(false);
                }else{
                    handleApiError(response,onApiError);
                }
                setTotalSize(_totalSize);
            }catch (e) {
                console.log(e);
            }
        }

    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };
    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        {console.log(files)}
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';
        const value = totalSize / 10000;
        return (
            <div className={className} class={'p-2 md:p-5'}>

                <div className="flex flex-col md:flex-row justify-between items-center w-full px-2 -intro-x gap-5">
                    <div className="flex gap-5">
                        {chooseButton}
                        <button className={'p-button p-component custom-upload-btn p-button-success p-button-rounded p-button-outlined p-button-icon-only '} onClick={onTemplateUpload}>
                            <HPrimeIcon icon={'pi-upload'} />
                        </button>
                        {cancelButton}
                    </div>
                    <div className="flex flex-col items-center gap-2 dark:text-gray-300">
                        <ProgressBar value={value} showValue={true} style={{ width: '15rem', height: '15px' }}></ProgressBar>
                        <span className="text-xs">{formatedValue} / 1 MB</span>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        const fileIndex = files.findIndex((f) => f.name === file.name);
        const handleAttachmentTypeChange = (index, newValue) => {
            let updatedAttachmentTypes = [...attachmentType];
            updatedAttachmentTypes[index] = { ...newValue, caption: file.caption };
            setAttachmentType(updatedAttachmentTypes);
        };

        const handleCaptionChange = (e) => {
            let updatedAttachmentTypes = [...attachmentType];
            updatedAttachmentTypes[fileIndex] = {
                ...(attachmentType[fileIndex] || {}),
                caption: e.target.value,
            };
            setAttachmentType(updatedAttachmentTypes);
        };
        const handleGroupChange = (index, e) => {
            let updatedAttachmentTypes = [...attachmentType];
            updatedAttachmentTypes[index] = {
                ...(attachmentType[index] || {}),
                group: e.target.value,
            };
            setAttachmentType(updatedAttachmentTypes);
        };

        const handleRemove = (file,callback) => {
            // Remove the item from the arrays based on its index
            const index = files.findIndex((prevFile) => prevFile.name === file.name);
            if (index !== -1) {
                const removedSize = files[index].size || 0;
                setTotalSize((prevTotalSize) => prevTotalSize - removedSize);
                setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.name !== file.name));
                setAttachmentType((prevCaptions) => {
                    const index = files.findIndex((prevFile) => prevFile.name === file.name);
                    const newAttachmentTypes = [...prevCaptions];
                    newAttachmentTypes.splice(index, 1);
                    return newAttachmentTypes;
                });
            }
            callback();
            return false;
        }


        return (
            <>

                <div className="hh-uplader  post-input dark:bg-themeDarkPostInput border dark:border-themeDarkColorLine p-1 rounded-md relative">
                    <div className="flex flex-col md:flex-row items-center gap-2 p-2">
                        <div className="flex-shrink-0">
                            <img alt={file.name} role="presentation" src={file.objectURL} className="hh-uplader-img " />
                        </div>
                        <div className=" h-full w-full space-y-2">
                            <div className="">
                                <Select
                                    options={attachOptions.attachs}
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data) =>  handleAttachmentTypeChange(fileIndex,data)}
                                    placeholder={t("selection")}
                                    className={"w-full"}
                                    menuPosition={"fixed"}
                                    //value={attachmentType[fileIndex]}
                                />
                            </div>
                            <div >
                                <input
                                    type="text"
                                    className="p-inputtext p-component input w-full"
                                    placeholder="Caption"
                                    value={(attachmentType[fileIndex] || {}).caption || ''}
                                    onChange={handleCaptionChange}
                                />
                            </div>
                            <div>
                                <input
                                    type={'number'}
                                    className="p-inputtext p-component input w-full"
                                    placeholder="group"
                                    min={0}
                                    value={(attachmentType[fileIndex] || {}).group || ''}
                                    onChange={(e) => handleGroupChange(fileIndex, e)}
                                />
                            </div>
                            <div className="flex gap-4 items-center">
                                <Tag value={props.formatSize} severity="warning" className="text-xs rounded-md bg-themeInformation dark:bg-themeDarkInformation" />
                                <span className="block text-xs dark:text-gray-300">{new Date().toLocaleDateString()} </span>
                            </div>
                            <Button type="button" icon="pi pi-times"
                                    className="hh-uploader-buttom-remove bg-themeDanger "
                                    onClick={() => handleRemove(file,props.onRemove)}
                            />
                        </div>
                    </div>
                </div>

            </>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex items-center justify-center -intro-x gap-5 dark:text-gray-300">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span className="my-5">{message}</span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            {

                spinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <Tooltip target=".custom-choose-btn" content={t("selection")} position="bottom" />
            <Tooltip target=".custom-cancel-btn" content={t("DeleteAll")} position="bottom" />
            <div>
                <FileUpload
                    multiple
                    accept={"image"}
                    maxFileSize={10000000}
                    onUpload={onTemplateUpload}
                    onSelect={onTemplateSelect}
                    // onRemove={onTemplateRemove}
                    onError={onTemplateClear}
                    onClear={onTemplateClear}
                    headerTemplate={headerTemplate}
                    itemTemplate={itemTemplate}
                    emptyTemplate={emptyTemplate}
                    chooseOptions={chooseOptions}
                    cancelOptions={cancelOptions}
                    ref={fileUploadRef}
                />
            </div>
        </div>
    );

};