import {useState} from "react";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";

export const UseCustomTransaction=(setErrors,showSpinner,hideSpinner)=>{
    const [wallet,setWallet] = useState({amount:0,userId:null,description:null,walletBalance:0,currencyType:0});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setWallet({...wallet,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setWallet({...wallet,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=async (data,name)=>{
        setWallet({ ...wallet,[name]: data });
    }

    return {
        wallet,
        setWallet,
        handleValue,
        handleNumberValue,
        handleSelectValue
    }
}