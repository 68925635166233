import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {regexSetting} from "../../../Services/Globals/Public/Regexes";
export const useCustomServerGrid=(showSpinner,hideSpinner,server,setServer,initialSource,setInitialSource,setIsEdit,refresh,Refresh,setIsOpen,timer,setIpValid,handleOpenWebsites,handleCloseWebsites)=>{

    const redirectTo = useNavigate();
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const { t } = useTranslation();
    const [rowData, setRowData] = useState([]);
    const [reset, setReset] = useState(false);
    const spinner = true;

    const handleGetServerType = (serverType) => {
        if (!initialSource.serverTypes) {
            return;
        }
        const data = initialSource.serverTypes.find((item) => item.value === serverType);
        return data ? data.label : '';
    }

    const handleGetPrivacyType = (privacy) => {
        if (!initialSource.privacyTypes) {
            return;
        }
        const data = initialSource.privacyTypes.find((item) => item.value === privacy);
        return data ? data.label : '';
    }

    const handleChangeActivation = async(serverId)=>{
        try {
            showSpinner();
            const response = await PUT(`/Servers/EditServerActivation`,{serverId:serverId},false);
            if (response.status === 200) {
                hideSpinner();
                Refresh();
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }catch (e) {
            hideSpinner()
        }
    }

    const handleServerPin = async (serverId)=>{
        try {
            const response = await PUT(`/Servers/Pin`,{serverId:serverId});
            if(response.status === 200){
                Refresh()
            }else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner();
        }
    }

    const handleRestartIis = async (siteName,serverId)=>{
        try {
            const response = await POST(`/Servers/IIs`,{siteName:siteName,serverId:serverId});
            if(response.status === 200){
                handleCloseWebsites();
                handleSuccessMessage(t('SuccessDone'));
                Refresh()
            }else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner();
        }
    }
    const handleGetAllWebsites=async(serverId)=>{
        try {
            showSpinner()
           const response = await GET(`/Servers/IISSites`,{serverId:serverId},'IEnumerable');
           if(response.status === 200){
               hideSpinner();
               handleOpenWebsites();
               setServer((prevStates)=>({...prevStates,websites:response.data,serverId:serverId}));
           }
           else{
               handleApiError(response,hideSpinner);
           }
        }
        catch (e) {
            hideSpinner();
        }

    }















    const handleRestartServer = async (serverId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await POST(`/Servers/Restart`,{serverId:serverId},false);
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else{
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    console.log(e);
                    hideSpinner()
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const handleDeleteServer = async (serverId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Servers/${serverId}`, {headers: { 'Content-Type': 'application/json' }});
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh()
                        handleSuccessMessage(t("Yourserverhasbeensuccessfullyregistered"))
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                    hideSpinner()
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const handleGetServer = async(serverId) => {
        try {
            setIsEdit(true);
            showSpinner();
            const response = await GET(`/Servers/${serverId}`,{},'single');
            const responseProvince = await GET(`/Countries/${response.data.countryId}/StateProvinces/Selection`,{},'IEnumerable');
            const provinces = convertSelectDataHandler(responseProvince.data);
            const filteredProvince = provinces.find((item) => item.value === response.data.stateProvinceId);
            const filteredCountry = initialSource.countries.find((item)=>item.value === response.data.countryId);
            var ipRegex = regexSetting.Ip
            if (ipRegex.test(response.data.ip)) {
                setIpValid(true);
            } else {
                setIpValid(false)
            }
            if(response.status === 200){
                setServer({
                    id:response.data.id,
                    name: response.data.name,
                    ssd: response.data.ssd,
                    ram: response.data.ram,
                    cpu: response.data.cpu,
                    hdd: response.data.hdd,
                    capacity: response.data.capacity,
                    bandwidth: response.data.bandwidth,
                    userName: response.data.userName,
                    stateShow: response.data.stateShow,
                    ip: response.data.ip,
                    stateProvinceId: filteredProvince,
                    countryId: filteredCountry,
                    connectionString: response.data.connectionString,
                    type: initialSource.serverTypes.find((item) => item.value === response.data.type),
                    privacy: initialSource.privacyTypes.find((item) => item.value === response.data.privacy),
                    description: response.data.description,
                    password:response.data.password,
                    isConfigured:response.data.isConfigured,
                    ns1:response.data.ns1,
                    ns2:response.data.ns2,
                    ns3:response.data.ns3,
                    ns4:response.data.ns4,
                });
                setIsOpen(true);
                hideSpinner()
            }else{
                handleApiError(response,hideSpinner)
            }
        }catch (error) {
            hideSpinner();
        }
    };






    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination, setPagination] = useState({
        totalPages: 0,
        page: 0,
        pageSize: GridSetting.pageSize,
        totalCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        lastPage: 0,
        currentPage: 1
    })
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({...pagination, pageSize: pageSize}))
    }


    const [filter, setFilter] = useState({
        search: '',
        isConfigured:null,
        IsPrivate:null,
        IsPublic:null,
        IsShow:null,
        IsHide:null,
        HighLoadCpu:null,
        HighLoadMemory:null,
        HighLoadDisk:null,
        countryId:'',
        countryValue:null,
        stateProvinceId:'',
        stateProvinceValue:null
    });

    const SwitchFilters = [
        {name:'isConfigured',lable:t("isConfigured"),value:filter.isConfigured},
        {name:'IsPrivate',lable:t("Private"),value:filter.IsPrivate},
        {name:'IsPublic',lable:t("Public"),value:filter.IsPublic},
        {name:'IsShow',lable:t("Active"),value:filter.IsShow},
        {name:'IsHide',lable:t("Inactive"),value:filter.IsHide},
        {name:'HighLoadCpu',lable:t('HighLoadCpu'),value:filter.HighLoadCpu},
        {name:'HighLoadMemory',lable:t('HighLoadMemory'),value:filter.HighLoadMemory},
        {name:'HighLoadDisk',lable:t('HighLoadDisk'),value:filter.HighLoadDisk},
    ]


    const [search, setSearch] = useState('');
    const handleSearch = () => {
        setFilter((prevState) => ({...prevState, search: search}));
    }
    const handleToggleFilter = (data, name) => {
        data ? setFilter(({...filter, [name]: data})) : setFilter(({...filter, [name]: null}));
    }
    const handleResetFilters = () => {
        setFilter(({
            ...filter,
            search: '',
            IsHide: null,
            IsPrivate: null,
            IsPublic: null,
            IsShow: null,
            HighLoadCpu: null,
            HighLoadDisk: null,
            HighLoadMemory: null,
            countryId: '',
            countryValue: null,
            stateProvinceId: '',
            stateProvinceValue: null,
            isConfigured: null
        }));
        setSearch('');
    }

    const handleSelectFilter =async (data,name,valueName,isCountry) => {
        if(isCountry)
        {
            try {
                setFilter(({...filter, [name]: data.value,[valueName]: data}));
                const response = await GET(`/Countries/${data.value}/StateProvinces/Selection`,{},'IEnumerable');
                if(response.status === 200){
                    const provinces = convertSelectDataHandler(response.data);
                    setInitialSource(prevState => ({...prevState,provinces:provinces}));
                }
                else{
                    handleApiError(response,hideSpinner);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        else{
            setFilter(({...filter, [name]: data.value,[valueName]: data}));
        }
    }



    useEffect(() => {
        const fetchData = async () => {
            try {
                // Your API call logic here
                OnGridReady();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if(timer.isActive){
            const intervalMilliseconds = calculateIntervalMilliseconds(timer.time.value,timer.time.timeUnit);
            const timerId = setInterval(() => {
                // Set refresh to trigger useEffect
                setReset(prevRefresh => !prevRefresh);
            }, intervalMilliseconds);

            // Call fetchData initially
            fetchData();

            // Cleanup function to clear interval when component unmounts
            return () => clearInterval(timerId);
        }else{
            fetchData();
        }

    }, [timer.isActive,refresh,reset, pagination.currentPage, filter.search, pagination.pageSize,filter.IsShow,filter.IsPublic,filter.IsPrivate,filter.IsHide,filter.HighLoadCpu,filter.HighLoadDisk,filter.HighLoadMemory,filter.countryId,filter.stateProvinceId,filter.isConfigured]);
    const calculateIntervalMilliseconds = (interval, timeUnit) => {
        switch (timeUnit) {
            case 'second':
                return interval * 1000; // Convert seconds to milliseconds
            case 'minute':
                return interval * 60 * 1000; // Convert minutes to milliseconds
            case 'hour':
                return interval * 60 * 60 * 1000; // Convert hours to milliseconds
            default:
                return 0; // Default to 0 milliseconds if time unit is not recognized
        }
    };

    const OnGridReady = async () => {
        try {
            showSpinner()
            const response = await GET(`/Servers`, {
                PageNumber: pagination.currentPage,
                PageSize: pagination.pageSize,
                Search: filter.search,
                IsShow:filter.IsShow,
                IsPublic:filter.IsPublic,
                IsPrivate:filter.IsPrivate,
                IsHide:filter.IsHide,
                HighLoadDisk:filter.HighLoadDisk,
                HighLoadCpu:filter.HighLoadCpu,
                HighLoadMemory:filter.HighLoadMemory,
                CountryId:filter.countryId,
                StateProvinceId:filter.stateProvinceId,
                IsConfigured:filter.isConfigured


            }, 'PagedList');
            if (response && response.items !== null) {
                setPagination({
                    ...pagination,
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPreviousPage: response.hasPreviousPage,
                    page: response.page,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount
                });
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            } else {
                handleApiError(response, hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    }
    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        handleToggleFilter,
        //columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter,
        SwitchFilters,
        handleGetPrivacyType,
        handleGetServerType,
        handleChangeActivation,
        handleRestartServer,
        handleGetServer,
        handleDeleteServer,
        handleServerPin,
        handleRestartIis,
        handleGetAllWebsites
    }
}