import React, { useEffect, useState } from "react";

import { ListFilter } from "../../Commans/Grid/ListFilter";
import { useNavigate } from "react-router-dom";
import { convertSelectDataHandler } from "../../CMS/Contents/ContentService";
import queryString from "query-string";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import { handleFailureMessage, handleSuccessMessage } from "../../../Services/Globals/Errors/NotificationService";
import { DELETE, GET, POST } from "../../../Services/AxiosService/AxiosApiService";
import { LoadingHarmony } from "../../Commans/Loadings/LoadingHarmony";
import { PickList } from "primereact/picklist";
import { ToastContainer } from "react-toastify";
import { Return } from "../../Commans/Globals/Return";
import { ButtonPlus } from "../../Commans/UIParts/ButtonPlus";
import { useTranslation } from "react-i18next";
import ButtonVideo from "../../Commans/UIParts/ButtonVideo";
import { HPrimeIcon } from "../../Commans/UIParts/HPrimeIcon";
import { ButtonSubmitAndCancel } from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
export const HandleDepartmentUser = () => {
  const { t } = useTranslation();



  const [spinner, setSpinner] = useState(false);
  const redirectTo = useNavigate();

  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [result, setResult] = useState(0);

  const handleFetchData = async () => {
    try {
      setSpinner(true);
      const queryParams = queryString.parse(window.location.search);
      const allUsers = await GET('/Users/Selection', {}, 'IEnumerable');
      const response = await GET(`Tickets/Departments/${queryParams.departmentId}/Users`);
      if (allUsers.status === 200 && response.status === 200) {
        setSpinner(false);
        const convertedUsers = convertSelectDataHandler(allUsers.data);
        const users = convertedUsers.map((user) => ({ name: user.label, id: user.value }));
        const selectedUsers = response.data.length > 0 ? users.filter((item) => response.data.some((sourceItem) => sourceItem.id === item.id)) : [];
        const filteredUsers = users.filter(user => !selectedUsers.some(selectedUser => selectedUser.id === user.id));
        setSource(filteredUsers);
        setTarget(selectedUsers);
      } else {
        handleApiError(allUsers, onApiError);
        handleApiError(response, onApiError);
      }

    } catch (e) {
      redirectTo('/')
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [result]);
  const onApiError = () => {
    setSpinner(false);
  }


  const onChange = (event) => {
    setSource(event.source);
    setTarget(event.target);
  };




  const itemTemplate = (item) => {
    return (
      <div className="flex flex-wrap items-center gap-3 bg-white dark:bg-themeDarkSalt700 p-2 box">
        <div className="flex-1 flex flex-column items-center gap-2 ">
          <HPrimeIconSmall icon={'pi-user'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
          <span className="font-bold dark:text-gray-300">{item.name}</span>
        </div>
      </div>
    );
  };



  const handleTarget = async (event) => {
    try {
      const queryParams = queryString.parse(window.location.search);
      setSpinner(true);
      setTarget(prevState => [...prevState, event.value]);
      const updatedSource = source.filter(item => item.id === event.value.id);
      setSource(updatedSource);
      const data = {
        departmentId: queryParams.departmentId,
        userIds: [event.value[0].id]
      }
      const response = await POST(`/Tickets/Departments/User`, data, false);
      if (response.status === 200) {
        setSpinner(false);
        handleSuccessMessage(t("UserRegisteredSuccessfully"));
        setResult({ ...response.status });
      }
      else {
        handleApiError(response, onApiError)
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSource = async (event) => {

    try {
      const queryParams = queryString.parse(window.location.search);
      setSource(prevState => [...prevState, event.value]);
      const updatedTarget = target.filter(item => item.id === event.value.id);
      setTarget(updatedTarget);
      const data = {
        departmentId: queryParams.departmentId,
        userIds: [event.value[0].id]
      }
      const response = await DELETE(`/Tickets/Departments/User`, data);
      if (response.status === 200) {
        setSpinner(false);
        handleSuccessMessage(t("UserDeletedSuccessfully"));
        setResult({ ...response.status });
      }
      else {
        handleApiError(response, onApiError);
      }

    }
    catch (e) {
      console.log(e);
    }
  }


  const handleAllTarget = async (event) => {
    try {

      const queryParams = queryString.parse(window.location.search);
      setSpinner(true);
      setTarget(prevState => [...prevState, event.value]);
      const updatedSource = source.filter(item => item.id === event.value.id);

      setSource(updatedSource);
      const data = {
        departmentId: queryParams.departmentId,
        userIds: event.value.map(item => item.id)
      }
      const response = await POST(`/Tickets/Departments/User`, data, false);
      if (response.status === 200) {
        setSpinner(false);
        handleSuccessMessage(t("UserRegisteredSuccessfully"));
        setResult({ ...response.status });
      }
      else {
        handleApiError(response, onApiError)
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleAllSource = async (event) => {
    try {
      const queryParams = queryString.parse(window.location.search);
      setSource(prevState => [...prevState, event.value]);
      const updatedTarget = target.filter(item => item.id === event.value.id);
      setTarget(updatedTarget);
      const data = {
        departmentId: queryParams.departmentId,
        userIds: event.value.map(item => item.id)
      }
      const response = await DELETE(`/Tickets/Departments/User`, data);
      if (response.status === 200) {
        setSpinner(false);
        handleSuccessMessage(t("UserDeletedSuccessfully"));
        setResult({ ...response.status });
      }
      else {
        handleApiError(response, onApiError);
      }

    }
    catch (e) {
      console.log(e);
    }
  }


  const handleAccessAdd = async () => {
    const departmentId = queryString.parse(window.location.search).departmentId;
    // return
    try {
      if (target.length === 0) {
        handleFailureMessage(t("Pleaseselectpermissions"));
        return;
      }
      setSpinner(true);
      const userIds = target.map((item) => item.id);


      const response = await POST(`/Tickets/Departments/Users`, { departmentId, userIds }, false);
      if (response.status === 200) {
        handleSuccessMessage(t("Permissionsbeensuccessfully"));
        redirectTo('/Support/Department');
      } else {
        handleApiError(response, onApiError);
      }
    } catch (e) {
      onApiError();
    } finally {
      setSpinner(false);
    }
  };






  return (
    <>
      <div className={"space-y-5"}>
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
          <ListFilter label={null} icon={null}
                      showChildrenTwo={false}
                      childrenOne={
                      <>
                        <ButtonPlus
                          onClick={() => redirectTo('/Support/Department')}
                          title={t("Department")}
                          tooltipTitle={t("NewDepartment")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo("/Support/Ticket")}
                            title={t("Ticket")}
                            tooltipTitle={t("NewTicket")}
                            icon={"pi-ticket"}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo("/Support/Ticket/List")}
                            title={t("list")}
                            tooltipTitle={t("TicketList")}
                            icon={"pi-list"}
                        />


                        <ButtonPlus
                            onClick={()=>redirectTo("/Support/ExpiredTickets")}
                            title={t("archive")}
                            tooltipTitle={t("ExpiredTickets")}
                            icon={"pi-database"}
                        />


                      </>
            }
          />

        </div>
        <div className="space-y-5 relative ">
          {spinner ? (
            <div className="huploader_loading">
              <div className="relative">
                <LoadingHarmony />
              </div>
            </div>
          ) : null}
          <div style={{ direction: "ltr" }} className="p-3 box bg-white dark:bg-themeDarkSalt700">
            <PickList
              target={target}
              source={source}
              onChange={onChange}
              filter
              filterBy="name"
              sourceHeader={t("Unauthorized")}
              targetHeader={t("Authorized")}
              sourceStyle={{ height: "30rem" }}
              targetStyle={{ height: "30rem" }}
              sourceFilterPlaceholder={t("search")}
              targetFilterPlaceholder={t("search")}
              itemTemplate={itemTemplate}
            />
          </div>
          <ToastContainer />
        </div>
        <ButtonSubmitAndCancel
          onRegisterClick={() => handleAccessAdd()}
          onCancelClick={() => redirectTo(`/Support/Department`)}
        />
        <ToastContainer />
      </div>


    </>

  )
}