import React, {useState} from "react";

export const useShortCode=(setErrors)=>{
    const [shortCode,setShortCode] = useState({id:null,name:null,value:null,type:0,templateId:null})


    const handleValue=(e)=>{
        const {name,value} = e.target;
        setShortCode({...shortCode,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue=(data,name)=>{
        setShortCode({ ...shortCode, [name]: data });
    }
const handleEditorChange=(value)=>{
    setShortCode({ ...shortCode,value: value  });

}
    const handleIsHtml = (data)=>{
        if(data === true){
            setShortCode({...shortCode,type: 1});
        }else{
            setShortCode({...shortCode,type: 0});
        }
    }
return {
    handleValue,
    handleSelectValue,
    handleIsHtml,
    shortCode,
    setShortCode,
    handleEditorChange
}
}