import React from "react";
import {Slider} from "primereact/slider";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
export const SliderRange=({item,index,handleChangeData})=>{

    return (
        <>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
                <label className="dark:text-gray-300 harmony-text-xs">
                    {item.properties.caption}
                </label>
                <Slider
                    type={'color'}
                    onChange={(e) => handleChangeData(index,'value',e.value)}
                    value={item.data?.value}
                    placeholder={item.properties.description}
                    minLength={item.properties.min}
                    maxLength={item.properties.max}
                    readOnly={item.properties.isReadOnly}
                    required={item.properties.isReadOnly}
                    id={item.properties.id}
                    step={item.properties.max}
                    min={item.properties.min}
                    name={item.properties.name}
                />

            </div>
        </>
    )
}