
import React, {useEffect, useMemo, useState} from "react";
import {DELETE, GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {useTranslation} from "react-i18next";

import queryString from "query-string";
import {Image} from "primereact/image";
import {handleFilterProductCartItems} from "../ProductCart/CartService";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
export const useCartItemsDetailGrid=(setShowSpinner,setIsOpen,onApiError,refresh,Refresh,setCartItems,setBuyer,initialSource,fetchData,setCost,setProductCartItems,setCart)=>{

    const [rowData, setRowData] = useState([]);
    const {t} = useTranslation();

    const handleGetCartItems= async()=>{
        try {
            const queryParams = queryString.parse(window.location.search);
            setShowSpinner(true);
            setIsOpen(true);
            const response = await GET(`/ProductCarts/${queryParams.productCartItemId}`,{},'single');
            if(response.status === 200){
                const cartItems = handleFilterProductCartItems(response.data.productItems,initialSource.productTypes,initialSource.cartTypes);
                setProductCartItems(cartItems);
                setCart({
                    id:response.data.id,
                    userId:initialSource.users.find((item)=>item.value === response.data.userId),
                    creationDate:response.data.creationDate,
                    priceType:initialSource.factors.find((item)=>item.value === response.data.priceType),
                })
                setShowSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError()
        }
    }





    const handleGetCurrencyName = (currency) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === currency);
        return data ? data.label : '';
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        {
            headerName: t("ProductName"),
            field: 'title',
            cellRenderer: (params) => (
                <>{params.data.product.title}</>
            ),
        },

        {
            headerName: t("Count"),
            maxWidth: 100,
            cellRenderer: (params) => <>{params.data.quantity}</>,
        },
        {
            headerName: "QrCode",
            maxWidth: 120,
            cellRenderer: (params) => (
                <>{params.data.product.barcode}</>
            ),
        },

        { headerName: t("ProductImage"),field: 'thumbnailImage',width: 120, maxWidth: 120,
            cellRenderer:params => {
                return params.data.image !== null?
                    <Image src={params.data.image} zoomSrc={params.data.image}  preview />:
                    <HPrimeIcon icon={'pi-times'} iconClass={"text-themeDanger"} />
            }
        },

        {
            headerName: t("MainPrice"),
            minWidth: 140,
            cellRenderer: (params) => (
                <>{params.data.product.firstPrice.amount } {handleGetCurrencyName(params.data.product.firstPrice.currency)}</>
            ),
        },
        {
            headerName: t("DiscountedPrice"),
            width: 130,
            cellRenderer: (params) => (
                <>{params.data.product.finalPrice.amount} {handleGetCurrencyName(params.data.product.firstPrice.currency)}</>
            ),
        },

    ];


    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);






    useEffect(() => {
        OnGridReady();
    }, [refresh]);

    const OnGridReady = async () => {
        try {
            const queryParams = queryString.parse(window.location.search);
            setShowSpinner(true);
            const response =  await GET(`/ProductCarts/${queryParams.productCartItemId}`, {}, 'single');
            if (response && response.data.productItems.length > 0 ) {
                var idSequence = 1;
                const modifiedItems = response.data.productItems.map((item) => ({
                    ...item,
                    idSequence:idSequence++
                }));
                const {user,transportPrice,firstPrice,finalPrice} = response.data;
                setBuyer(user);
                const currencyName = handleGetCurrencyName(firstPrice.currency)
                setCost({transportPrice:transportPrice.amount,payablePrice:firstPrice.amount,totalPrice:finalPrice.amount,currencyName:currencyName})
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };





    return {
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        handleGetCartItems
    }
}