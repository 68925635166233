import React, {useEffect, useMemo, useState} from "react";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {Export} from "../../../Commans/Grid/Export";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import queryString from "query-string";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {convertSelectDataHandler} from "../../Contents/ContentService";
import Select from "react-select";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {Dialog} from "primereact/dialog";
import {Tooltip} from "react-tooltip";


export const HandleResultDetailsPoll=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [modal, setModal] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [showSpinner,setShowSpinner] = useState(false)
    const [text,setText] = useState({question:'',answer:''});
    const [initialSource,setInitialSource] = useState({surveyTypes:[],surveys:[],survey:null})
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const [surveyItemValue,setMessage] = useState('');
    const [visible, setVisible] = useState(false);
    const handleCloseMessage = ()=>{
        setVisible(false);
    }
    const handleShowMessage =(visible,surveyItemValue)=>{
        setVisible(visible);
        setMessage(surveyItemValue)
    }


    const onApiError=()=>{
        setShowSpinner(false);
    }
    // gets a print of poll results
    const handlePrint = () => {
        window.print();
    };

    // refresh component
    const Refresh=(state)=>{
        setRefresh({...state});
    }
    // opens modal in grid to show comments
    const handleOpenModal=(question,answer)=>{
        setModal(true);
        setText({question: question,answer: answer});
    }
    // closes modal
    const handleCloseModal=()=>{
        setModal(false);
    }


    // handles some initial data source in fetchData function
    const handleInitialSource=(types,surveys)=>{
        const surveyTypes = convertSelectDataHandler(types);
        const survey = convertSelectDataHandler(surveys);
        setInitialSource({surveyTypes:surveyTypes,surveys:survey});
    }

    // Gets Some initial data while loading component
    const fetchData= async ()=>{
        try {
            const queryParams = queryString.parse(window.location.search);

            const responseSurveyType = await GET(`/Enumerations/EnSurveyType`,{},'IEnumerable');
           const responseSurvey =  await GET(`/Surveys/${queryParams.surveyGroupId}/Questions`,{},'IEnumerable');
           if(responseSurveyType.status === 200 && responseSurvey.status === 200){
               handleInitialSource(responseSurveyType.data,responseSurvey.data);
           }
           else{
               handleApiError(responseSurveyType,onApiError);
           }
        }
        catch (e) {
            console.log(e);
        }
    }


    //Gets Survey Type name
    const GetSurveyTypeName = (type) => {
        if (!initialSource.surveyTypes) {
            return;
        }
        const data = initialSource.surveyTypes.find((item) => item.value === type);
        return data ? data.label : '';
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},
        { headerName: t("Question"),
            cellRenderer:parameters=>
                <div>
                    <button type={"button"} className="tooltip_class_question"  onClick={() => handleShowMessage(true,parameters.data.question)} >
                        <p className="line-clamp-1 text-themeInformation dark:text-themeDarkInformation">{parameters.data.question}</p>
                        <Tooltip anchorSelect={".tooltip_class_question"} content={t("viewing")} positionStrategy={"fixed"}	 />
                    </button>

                </div>
        },
        { headerName: t("Answer"),
            cellRenderer:parameters=>
                <button type={"button"} className="tooltip_class_See"  onClick={() => handleShowMessage(true,parameters.data.surveyItemValue)} >
                    <p className="line-clamp-1 text-themeInformation dark:text-themeDarkInformation">{parameters.data.surveyItemValue}</p>
                    <Tooltip anchorSelect={".tooltip_class_See"} content={t("viewing")} positionStrategy={"fixed"}	 />
                </button>
        },
        { headerName: t("QuestionType"),maxWidth: 160,
            cellRenderer:parameters=>
                <>{GetSurveyTypeName(parameters.data.type)}</>
        },
        { headerName: t("Participants"),maxWidth: 160,
            cellRenderer:parameters=>
                <>{parameters.data.totalAttendee}</>
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }
    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',surveyId:'',fromDate:null,toDate:null});
    const[search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const filterSelectValue = async(data,name)=>{
        setFilter(({...filter,[name]: data.value}));
    }
    const handleFromDateTime=(dateTime)=> setFilter((prevState)=>({...prevState,fromDate:dateTime}));
    const handleToDateTime=(dateTime)=>setFilter((prevState)=>({...prevState,toDate:dateTime}));
    const resetFilters = async()=>{
        setFilter((prevState)=>({...prevState,search: '',surveyId: '',fromDate: null,toDate: null}));
        setSearch('');
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setShowSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    fetchData();
                }
                else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setShowSpinner(false);
            }
        };
        loadData();
    }, [refresh,currentPage,filter.search,data.pageSize,filter.toDate,filter.fromDate,filter.surveyId]);


    const [rowData, setRowData] = useState([]);

    const OnGridReady = async (surveyId) => {
        try {
            setShowSpinner(true);
            const queryParams = queryString.parse(window.location.search);
            const response =  await GET(`/Surveys/${queryParams.surveyGroupId}/Result`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,FromDate:filter.fromDate,ToDate:filter.toDate,SurveyId:filter.surveyId}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };





    return(
        <>

            <Dialog header={t("UserComment")} visible={visible}  className={"Dialog-ShowText"} onHide={() => setVisible(false)}>
                {surveyItemValue}
            </Dialog>


            <div class=" space-y-5 relative">
                {

                    showSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SurveyTitle")}</label>
                                <Select
                                    options={initialSource.surveys}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>filterSelectValue(data,'surveyId')}
                                    placeholder={t("selection")}
                                    value={initialSource.survey}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleFromDateTime} value={filter.fromDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}
                            </label>
                            <DateTimePicker value={filter.toDate} handleDateTime={handleToDateTime} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            functionClass={"harmony-creation"}
                                            onClick={()=>redirectTo(`/CMS/Surveys/Add`)}
                                            tooltipTitle={t("CreatingASurvey")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>handlePrint()}
                                            title={t("PrintComments")}
                                            icon={"pi-print"}
                                            tooltipTitle={t("PrintComments")}
                                        />
                                    </>
                                }

                    />

                </div>
                <div class="space-y-2 H-body-ag-grid ">

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <ToastContainer />
                <Export filters={filter} apiUrl={''}/>
            </div>


            <FantasyModal openModal={modal}  closeModal={handleCloseModal} title={t("UserSuggestion")}>
                <div class=" p-2 space-y-2">
                    <div class="flex items-center border-b">
                        <div>
                            <ul class="flex gap-2 items-center justify-center p-2">
                                <li>
                                    <img src="/Assets/Image/Avatar/man.svg" className="size-10 block rounded-full overflow-hidden object-contain"
                                         alt="user" title="user"/>
                                </li>
                                <li className="flex gap-1">
                                    <h3 className="text-xs dark:text-gray-300">مسعود احمدی</h3>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className=" space-y-2">
                        <div>
                            <h2  className="text-themeInformation dark:bg-themeDarkInformation">{text.question}</h2>
                        </div>
                        <div className="max_height20 overflow-y-auto">
                            <p class="text-xs dark:text-gray-300 text-justify leading-7">
                                {text.answer}
                            </p>
                        </div>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>
        </>

    )

}