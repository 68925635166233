import React, {useEffect, useMemo, useState} from "react";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {InputText} from "primereact/inputtext";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {Export} from "../../Commans/Grid/Export";
import {useCustomRoadMap} from "./useCustomRoadMap";
import {validate} from "../../../Services/Globals/validate";
import {roadMapSchema} from "./roadMapSchema";
import {POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {ToastContainer} from "react-toastify";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {useCustomRoadMapGrid} from "./useCustomRoadMapGrid";
import {GridTable} from "../../Commans/Grid/GridTable";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";

export const HandleRoadMapForm = () => {
    const { t } = useTranslation();
    const [IsOpen,setIsOpen] = useState(false);
    const [errors,setErrors]=useState({});
    const [IsEdit,setIsEdit]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);

    const {setRoadMap,roadMap,handleSelectValue,handleValue}= useCustomRoadMap(setErrors);
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const handleResetErrors=()=>{
        setErrors({});
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleOpenRoadmap = () => {
        setRoadMap((prevState)=>({...prevState,name: '',description: ''}));
        setIsOpen(true);
    };
    const handleCloseRoadmap = ()=>{
        setRoadMap((prevState)=>({...prevState,name: '',description: ''}));
        setIsOpen(false);
        setIsEdit(false);
        handleResetErrors();
    }

    const handleSubmitRoadMap=async(e)=>{
        e.preventDefault();
        const isValid = validate(roadMapSchema,roadMap,onSetErrors);
        if(!isValid)return;
        try {
            showSpinner();
            if(IsEdit){
                const response = await PUT(`/Forms/RoadMaps`,roadMap,false);
                if(response.status === 200){
                    handleCloseRoadmap();
                    Refresh();
                    handleSuccessMessage(t('SuccessDone'));

                }else{
                    handleApiError(response,hideSpinner)
                }
            }
            else{
                const response = await POST(`/Forms/RoadMaps`,roadMap,false);
                if(response.status === 200){
                    handleCloseRoadmap();
                    Refresh();
                    handleSuccessMessage(t('SuccessDone'));

                }else{
                    handleApiError(response,hideSpinner)
                }
            }
        }
        catch (e){
            hideSpinner();
        }
    }

    const {handleDeleteRoadMap,handleGetRoadMap,filter, setFilter, handleSearch,handleNextPage, handleCurrentPage,handlePreviousPage,handleResetFilters,handlePageSizeCount,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination}=useCustomRoadMapGrid(showSpinner,hideSpinner,setIsOpen,setIsEdit,refresh,Refresh,setRoadMap,roadMap);







    return (
        <>

            <div className="space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5"}>
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                            <InputText onChange={(e)=>setFilter(({...filter,searchValue:e.target.value}))} value={filter.searchValue} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>handleResetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>handleOpenRoadmap()}
                                            tooltipTitle={t("FormWorkflow")}
                                        />
                                    </>
                                }
                    />

                </div>
                <div className={"space-y-2 H-body-ag-grid"}>

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
               <Export filters={filter}/>
            </div>

            <FantasyModal openModal={IsOpen} closeModal={handleCloseRoadmap} title={t("FormWorkflow")} >
                <form onSubmit={handleSubmitRoadMap} method={'post'}>
                    <div className="space-y-5 ">
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className="space-y-2">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">
                                        {t("Title")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.name}/>
                                </div>

                                <InputText name={'name'} value={roadMap.name} onInput={(e)=>handleValue(e)} type={"text"} className={"input w-full"} />
                            </div>
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("Explanation")}
                                </label>
                                <InputText name={'description'} value={roadMap.description} onInput={(e)=>handleValue(e)} type={"text"} className={"input  w-full"} />
                            </div>

                        </div>

                        <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("Register")}</button>
                    </div>
                </form>
            </FantasyModal>
            <ToastContainer/>

        </>

    );
};
