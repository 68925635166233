import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {UseRememberMe} from "../../Commans/Hooks/UseRememberMe";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useVerification} from "./useVerification";
import {LoadingSpinner} from "../../Commans/Loadings/LoadingSpinner";
import {validate} from "../../../Services/Globals/validate";
import {codeSchema} from "../../Authentication/ForgetPassword/forgetPasswordSchema";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import Countdown from "react-countdown";
import {InputOtp} from "primereact/inputotp";

export const Verification=({handleChangeState})=>{
    const [IsPlay,setIsPlay] = useState(true);
    const {rememberMe, handleRememberMe, saveCredentials,savedCredentials} = UseRememberMe();
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const redirectTo = useNavigate();
    const {setVerification,verification,handleValue,handleNumberValue}=useVerification(setErrors)

    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors = (errors) => {
        setErrors(errors);
    }

    const ValidateSubmitOtp=async (e)=>{
        e.preventDefault()
        const isValid = validate(codeSchema,verification,onSetErrors);
        if(!isValid){
            return;
        }
    }



    const Resend=()=>{
        setIsPlay(true)
    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if(completed){
            setIsPlay(false)
        }
        else{
            return <span className={'text-themeDanger'}>{minutes}:{seconds}</span>;
        }
    };

    return (<>

        <form method={'post'} onSubmit={ValidateSubmitOtp} className="space-y-2 relative">
            {IsShowSpinner ? <LoadingSpinner/> : null}

            <div className=" p-2 flex justify-between items-center ">
                <div className="flex justify-items-start">
                    <h2 className="text-gray-700 ">
                        {t('Enter_Your_Code')}
                    </h2>
                </div>
                <div>
                    {
                        IsPlay?
                            <Countdown
                                date={Date.now() + 50000}
                                renderer={renderer}
                            />
                            :
                            <span className="cursor-pointer text-themeSuccess text-x" onClick={async()=> await Resend()}>{t('Resend')}</span>
                    }

                </div>
            </div>

            <div className="space-y-5">
                <DisplayError message={errors.otpCode}/>
                <div className={'H-direction-ltr'}>
                    <InputOtp length={5}  onChange={(e)=>handleNumberValue(e.value.toString(),'otpCode')} name={'otpCode'} value={verification.otpCode}  integerOnly/>
                </div>
                <div className="flex gap-2">
                    <button onClick={()=>handleChangeState('signup')} type={'button'}  className={'button w-full  bg-themeDanger text-white'} >{t('PreviousLevel')} </button>
                    <button className="button w-full  user_login_gradiant text-white" type="submit">
                        {t('codeConfirmtion')}
                    </button>
                </div>
            </div>

        </form>

    </>)
}