import React, {useEffect, useState} from "react";
import queryString from "query-string";
import { handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {
  convertSelectDataHandler,
  convertToTree
} from "../../../CMS/Contents/ContentService";

import {useNavigate} from "react-router-dom";

import SortableTree, {addNodeUnderParent, toggleExpandedForAll} from "@nosferatu500/react-sortable-tree";
import Swal from "sweetalert2";
import {InputText} from "primereact/inputtext";

import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {ToastContainer} from "react-toastify";
import {userGroupSchema} from "./userGroupSchema";
import {Image} from "primereact/image";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useDispatch} from "react-redux";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {Tooltip} from "react-tooltip";
import {InputTextarea} from "primereact/inputtextarea";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";

export const HandleUserGroup = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.isEdit === "true") {
      handleSuccessMessage(t("UserGrouphasbeensuccessfullyedited"));
    }
  }, [window.location.search]);



  const [searchString, setSearchString] = useState("");
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [butLoading,setBtnLoading] = useState(false);
  const [result,setResult] = useState(0);
  const [options,setOptions] = useState({groups:[]})

  const superValueHandler=(data,name)=>{
    setUserGroup({ ...userGroup, [name]: data });
  }
  const onApiError=()=>{
    setSpinner(false);
  }
  const onSetErrors=(errors)=>{
    setErrors(errors);
  }

  const fetchData = async () => {
    try {
      setSpinner(true);

      // Use Promise.all to call both APIs concurrently
      const [response,responseAllGroup] = await Promise.all([
        GET(`/UserGroups`, {}, 'IEnumerable'),
        GET(`/UserGroups/Selection`, {}, 'IEnumerable')
      ]);
      if (response.status === 200 && responseAllGroup.status) {
        const userGroups = convertSelectDataHandler(responseAllGroup.data);
        setOptions((prevOptions) => ({
          ...prevOptions,
          groups: userGroups,
        }));
        const tree = convertToTree(response.data);
        setTreeData(tree);
        setSpinner(false);
      } else {
        handleApiError(response, onApiError);
        handleApiError(responseAllGroup, onApiError);
      }
    } catch (e) {
      onApiError();
    }
  };


  useEffect(()=>{
    fetchData()
  },[result])




  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    resetUserGroupForm();
  };

  const [spinner,setSpinner] = useState(false);
  const redirectTo = useNavigate();



  const [userGroup,setUserGroup] = useState({Id:null,Title:'',Description: '',ParentId:null,Order:0,Image:null,StateShow:false});
  const [errors, setErrors] = useState({});
  const handleValue=(e)=>{
    const {name,value} = e.target;
    setUserGroup({...userGroup,[name]:value});
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  }
  const handleFileValue = (file)=>{
    setUserGroup({...userGroup,Image: file});
  }
  const resetUserGroupForm = ()=>{
    setUserGroup((prevState)=>({...prevState,Id:null,Title:'',Description: '',ParentId:null,Order:0,StateShow: false,Image: null}));
    setErrors({})
  }


  const handleChangeStateShow= (data,name)=>{
      setUserGroup(({...userGroup,[name]: data}));
  }
  const [isEdit,setIsEdit] = useState(false);
  const groupSubmitApiHandler = async (e)=>{
    e.preventDefault();
    if(isEdit){
     try {
       var isValid = validate(userGroupSchema,userGroup,onSetErrors);
       if(!isValid)return;
       const formData = new FormData();
       formData.append('Id',userGroup.Id);
       formData.append('ParentId',userGroup.ParentId === undefined || userGroup.ParentId === null ?null:userGroup.ParentId.value);
       formData.append('Image',userGroup.Image === undefined || userGroup.Image === null ? null:userGroup.Image);
       formData.append('Title',userGroup.Title === undefined || userGroup.Title ===  null ? null: userGroup.Title);
       formData.append('Order',userGroup.Order);
       formData.append('Description',userGroup.Description === undefined || userGroup.Description === null ? null:userGroup.Description);
       formData.append('StateShow',userGroup.StateShow);
       setBtnLoading(true)
       //const convertedUserGroup = convertUserGroup(userGroup);
       setSpinner(true)
       //const response = await EditUserGroupHandler(formData);
       const response  = await PUT(`/UserGroups`,formData,true);
       if (response.status === 200){
         setIsEdit(false)
         setBtnLoading(false)
         setResult({...response.status})
         closeModal()
         setSpinner(false)
         handleSuccessMessage(t("UserGrouphasbeensuccessfullyedited"));
         resetUserGroupForm();
       }
       else{
         setBtnLoading(false)
         handleApiError(response,onApiError);
       }
     }catch (e) {
        console.log(e);
     }
    }
    else{
      try {
        var isValid = validate(userGroupSchema,userGroup,onSetErrors);
        if(!isValid)return;
        const formData = new FormData();
        formData.append('ParentId',userGroup.ParentId === undefined || userGroup.ParentId === null ?null:userGroup.ParentId.value);
        formData.append('Image',userGroup.Image === undefined || userGroup.Image === null ? null:userGroup.Image);
        formData.append('Title',userGroup.Title === undefined || userGroup.Title ===  null ? null: userGroup.Title);
        formData.append('Order',userGroup.Order);
        formData.append('Description',userGroup.Description === undefined || userGroup.Description === null ? null:userGroup.Description);
        formData.append('StateShow',userGroup.StateShow);
        setSpinner(true)
        setBtnLoading(true)
        const response  = await POST(`/UserGroups`,formData,true);
        if (response.status === 200) {
          setBtnLoading(false)
          closeModal()
          setSpinner(false)
          handleSuccessMessage(t("Usergrouphasbeensuccessfullycreated"));
          setResult({...response.status})
          resetUserGroupForm();
        }
        else{
          setBtnLoading(false)
          handleApiError(response,onSetErrors);

        }
      }catch (e) {
       console.log(e)
      }
    }
  }

  function updateTreeData(treeData) {
    setTreeData(treeData);
  }
  function expand(expanded) {
    setTreeData(
        toggleExpandedForAll({
          treeData,
          expanded,
        })
    );
  }

  function expandAll() {
    expand(true);
  }

  function collapseAll() {
    expand(false);
  }

  const selectPrevMatch = () => {
    setSearchFocusIndex(
        searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1
    );
  };

  const selectNextMatch = () => {
    setSearchFocusIndex(
        searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
    );
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;


  const updateNode = async(rowInfo)=>{
    try {
      setSpinner(true);
      setBtnLoading(true)
      const response = await GET(`/UserGroups/${rowInfo.node.id}`);
      if(response.status === 200){
        setBtnLoading(false)
        setSpinner(false);
        setIsEdit(true)
        const {id,title,description,order,parentId,image} = response.data;
        const parents = options.groups.find((item) => item.value === parentId)??null;
        setUserGroup(({Id:id,Title:title,Description:description,Order: order,ParentId:parents,StateShow: false,Image:image }))
        openModal();
      }else{
        handleApiError(response,onApiError);
      }
    }catch (e) {
      console.log(e);
    }
  }

  function addNodeChild(rowInfo) {
    const Parent = options.groups.find((item) => item.value === rowInfo.node.id);
    setUserGroup({ ...userGroup, ParentId: Parent[0] });
    openModal();
  }
  function addNodeSibling(rowInfo) {
    const { path } = rowInfo;
    const { Title, Order, Description } = userGroup;

    if (userGroup === null) {
      return;
    }
    const newNode = {
      Title,
      Order,
      parentId: path[path.length - 2],
    };
    setTreeData((prevTreeData) =>
        addNodeUnderParent({
          treeData: prevTreeData,
          parentKey: path[path.length - 2],
          expandParent: true,
          getNodeKey,
          newNode,
        }).treeData
    );
    resetUserGroupForm();
  }

  const removeUserGroupHandelr = async (id)=>{

    //const response = await checkUserGroupChildren(id);
    const response = await GET(`/UserGroups/HasChildren?Id=${id}`,{},'single');
    if(response.status === 200){
      if(response.data.hasBranch){
        const TailWindButton = Swal.mixin({
          customClass: {
            confirmButton: AlertSetting.customClass.confirmButton,
            cancelButton: AlertSetting.customClass.cancelButton
          },
          buttonsStyling: false
        })
        TailWindButton.fire({
          title:AlertSetting.TailWindButton.title,
          text: AlertSetting.TailWindButton.text,
          icon:AlertSetting.TailWindButton.icon,
          showCancelButton:AlertSetting.TailWindButton.showCancelButton,
          confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
          cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
          reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
          if (result.isConfirmed) {
            try {
              setSpinner(true);
             const response = await DELETE(`/UserGroups/${id}`, {headers: { 'Content-Type': 'application/json' }});
              if(response.status === 200){
                setSpinner(false);
                setResult({...response.status});
                handleSuccessMessage(t("Grouphasbeensuccessfullydeleted"));
              }else{
                handleApiError(response,onApiError);
              }
            }catch (e) {
              console.log(e);
            }
          }
          else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
          ) {
            return;
          }
        })
      }else{
        const TailWindButton = Swal.mixin({
          customClass: {
            confirmButton: AlertSetting.customClass.confirmButton,
            cancelButton: AlertSetting.customClass.cancelButton
          },
          buttonsStyling: false
        })
        TailWindButton.fire({
          title:AlertSetting.TailWindButton.title,
          text: AlertSetting.TailWindButton.text,
          icon:AlertSetting.TailWindButton.icon,
          showCancelButton:AlertSetting.TailWindButton.showCancelButton,
          confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
          cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
          reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
          if (result.isConfirmed) {
            try {
              setSpinner(true);
              const response = await DELETE(`/UserGroups/${id}`, {headers: { 'Content-Type': 'application/json' }});
              if(response.status === 200){
                setSpinner(false);
                setResult({...response.status});
                handleSuccessMessage(t("Grouphasbeensuccessfullydeleted"));
              }else{
                handleApiError(response,onApiError);
              }
            }catch (e) {
              console.log(e);
            }
          }
          else if (
              /* Read more about handling dismissals below */
              result.dismiss === Swal.DismissReason.cancel
          ) {
            return;
          }
        })
      }

    }else{
     handleApiError(response,onApiError);
    }



  }

  const removeNode = async (rowInfo)=>{

    await removeUserGroupHandelr(rowInfo.node.id);
  }


  const MoveNodeHandler = async(e) => {
    const Id = e.nextParentNode?.id;
    e.node.parentId = Id !== undefined ? Id : null;
    setSpinner(true)
    const response  = await PUT('/UserGroups/SetParent', { id: e.node.id,parentId:e.node.parentId },false)
    if(response.status === 200){
      setSpinner(false);
      setResult({...response.status});
      handleSuccessMessage(t("Editingwassuccessfullydone"))
    }else{
      handleApiError(response,onApiError)
    }
  }
  const orderValueHandler =(data)=>{
    setUserGroup((prevState) => ({ ...prevState, Order: data }));
  }

  const alertNodeInfo = ({ node, path, treeIndex }) => {
    const objectString = Object.keys(node)
        .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
        .join(",\n   ");

    window.alert(
        "Info passed to the icon and button generators:\n\n" +
        `node: {\n   ${objectString}\n},\n` +
        `path: [${path.join(", ")}],\n` +
        `treeIndex: ${treeIndex}`
    );
  };

  const dispatch = useDispatch();




  const customSearchMethod = ({ node, searchQuery }) => {
    return node.title.toLowerCase().includes(searchQuery.toLowerCase());
  };


  return (
      <>

        <div className=" space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
            <ListFilter label={null} icon={null}
                        showChildrenTwo={false}
                        childrenOne={
                          <>
                            <ButtonPlus
                                onClick={() => openModal()}
                                tooltipTitle={t("GroupCreation")}
                            />

                            <ButtonPlus
                                onClick={expandAll}
                                title={t("View")}
                                icon={"pi-arrow-up-right-and-arrow-down-left-from-center"}
                                tooltipTitle={t("ViewSubcategories")}
                            />
                            <ButtonPlus
                                onClick={collapseAll}
                                title={t("Close")}
                                icon={"pi-arrow-down-left-and-arrow-up-right-to-center"}
                                tooltipTitle={t("CloseSubcategories")}
                            />

                            <ButtonPlus
                                onClick={()=>redirectTo(`/CRM/User/Add`)}
                                tooltipTitle={t("UsersRegistration")}
                                icon={"pi-user-plus"}
                                title={t("personnel")}
                            />
                            <ButtonPlus
                                onClick={()=>redirectTo(`/CRM/User/Role`)}
                                icon={"pi-star"}
                                title={t("Role")}
                                tooltipTitle={t("CreatingRole")}
                            />

                            <ButtonPlus
                                onClick={()=>redirectTo(`/CRM/User/List`)}
                                icon={"pi-list"}
                                title={t("list")}
                                functionClass={"harmony-return-list"}
                                tooltipTitle={t("CustomerList")}
                            />


                          </>
                        }
            />

          </div>
          <div>
            <div class="p-2 box bg-white dark:bg-themeDarkSalt700 ">
              <div className={"grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 post-input dark:bg-themeDarkPostInput post-input-design"}>
                <div className={"p-2 flex gap-2 items-center"}>
                  <InputText type="search" className="input " placeholder={t("search")} value={searchString} onChange={(e) => setSearchString(e.target.value)}/>
                  <ButtonSearchList
                  />
                </div>
              </div>
            </div>


            {/*<form*/}
            {/*    */}
            {/*    onSubmit={(event) => {*/}
            {/*      event.preventDefault();*/}
            {/*    }}*/}
            {/*>*/}

            {/*<button*/}
            {/*    type="button"*/}
            {/*    disabled={!searchFoundCount}*/}
            {/*    onClick={selectPrevMatch}*/}
            {/*>*/}
            {/*</button>*/}

            {/*<button*/}
            {/*    type="submit"*/}
            {/*    disabled={!searchFoundCount}*/}
            {/*    onClick={selectNextMatch}*/}
            {/*>*/}
            {/*</button>*/}

            {/*</form>*/}
          </div>
          <div className={"h-screen bg-white rounded-md dark:bg-themeDarkSalt700 relative"}>
            {
              spinner?
                  <div className=" huploader_loading" >
                    <div className="relative">
                      <LoadingHarmony/>
                    </div>
                  </div>
                  :
                  null
            }
            <SortableTree
                treeData={treeData}
                onMoveNode={(e)=>MoveNodeHandler(e)}
                onChange={(treeData) => updateTreeData(treeData)}
                searchMethod={customSearchMethod}
                searchQuery={searchString}
                searchFocusOffset={searchFocusIndex}
                searchFinishCallback={(matches) => {
                  setSearchFoundCount(matches.length);
                  setSearchFocusIndex(
                      matches.length > 0 ? searchFocusIndex % matches.length : 0
                  );
                }}
                canDrag={({ node }) => !node.dragDisabled}
                generateNodeProps={(rowInfo) => ({
                  // title: rowInfo.node.label,
                  // subtitle: rowInfo.node.subTitle,
                  buttons: [
                    <div className="flex items-center space-x-2">

                      <button onClick={(event) => addNodeChild(rowInfo)} className="button tree_button tooltip_class_group bg-themeInformation dark:bg-themeDarkInformation text-white"  >
                        <HPrimeIconSmall icon={'pi-plus-circle'} />
                        <Tooltip anchorSelect={".tooltip_class_group"} content={t("GroupCreation")} />
                      </button>
                      <button onClick={(event) => updateNode(rowInfo)} className="button tree_button tooltip_class_Edit bg-themeSuccess text-white"  >
                        <HPrimeIconSmall icon={'pi-pen-to-square'} />
                        <Tooltip anchorSelect={".tooltip_class_Edit"} content={t("edit")} />
                      </button>
                      <button onClick={(event) => removeNode(rowInfo)} className="button tree_button tooltip_class_delete bg-themeDanger text-white"  >
                        <HPrimeIconSmall icon={'pi-trash'} />
                        <Tooltip anchorSelect={".tooltip_class_delete"} content={t("delete")} />
                      </button>
                      {
                        rowInfo.node.image === null? null:
                            <div className={"size-10"}>
                              <Image src={rowInfo.node.image} zoomSrc={rowInfo.node.image} alt="Image" preview />
                            </div>

                      }
                      {/*<button*/}
                      {/*    label="Alert"*/}
                      {/*    onClick={(event) => alertNodeInfo(rowInfo)}*/}
                      {/*>*/}
                      {/*  Info*/}
                      {/*</button>*/}
                    </div>
                  ],
                  style: {
                    height: "50px"
                  }
                })}
            />
          </div>
        </div>
        <FantasyModal openModal={isModalOpen} closeModal={closeModal} title={t("GroupCreation")}>
          <form method={'post'} onSubmit={groupSubmitApiHandler}>
              <div className="space-y-3 ">
                <div className="space-y-1">
                  <div className="flex justify-between items-center">
                    <label className="text-xs dark:text-gray-300">
                      {t("Title")}
                    <RequiredIcon/>
                    </label>
                    <DisplayError message={errors.Title}/>
                  </div>
                  <InputText type={'text'} name={'Title'} value={userGroup.Title} onInput={(e)=>handleValue(e)} className={'input w-full'}/>
                </div>
                <div className={"flex gap-3 items-center"}>
                  <div className="space-y-1 flex-1">
                    <label className="text-xs dark:text-gray-300">{t("Group")}</label>
                    <Select options={
                      userGroup.Id === null?
                      options.groups
                          :
                          options.groups.filter((item)=>item.value !== userGroup.Id)
                    }
                            isClearable={true}
                            isSearchable={true}
                            onChange={(data)=>superValueHandler(data,'ParentId')}
                            name={'parentId'}
                            placeholder={t("selection")}
                            value={userGroup.ParentId}
                            menuPosition={"fixed"}
                            classNamePrefix={"h_multi_select"}
                    />
                  </div>
                  <div class="space-y-1 flex-1">
                    <label className="text-xs dark:text-gray-300">{t("rank")}</label>
                    <InputNumber className="w-full " name={'Order'}  onValueChange={(e)=>orderValueHandler(e.target.value)} value={userGroup.Order} placeholder={t("selection")} mode="decimal" showButtons min={0} max={100}/>
                  </div>
                </div>
                <div >
                  <InputTextarea className={' input w-full '} value={userGroup.Description} name={'Description'} placeholder={t("description")} rows={3} cols={30} onInput={(e)=>handleValue(e)}  />
                  {/*<InputText type={'text'} className={' input w-full '} value={userGroup.Description} name={'Description'} placeholder={t("description")} onInput={(e)=>handleValue(e)}  />*/}
                </div>

                <div className="w-full h-40 bg-white dark:bg-themeDarkPostInput">
                      {/*<button type="button" className="button cursor-pointer bg-themeSecondary text-white w-24">*/}
                      {/*  {t("FileUpload")}*/}
                      {/*</button>*/}
                      {/*<input type="file" onChange={(e)=>handleFileValue(e.target.files[0])} className="w-full h-full top-0 end-0 absolute opacity-0 "/>*/}
                      <UploadImage accept={'image/*'}  OnValueSelection={handleFileValue} value={userGroup.Image} name={'Image'}/>
                </div>
                <div class="space-y-1 flex items-start">
                  <InputSwitch OnValueSelection={handleChangeStateShow} name={'StateShow'} lable={t("Display")} />
                </div>

                <div>
                      <button type={"submit"} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative"} >
                        {t("insertion")}
                        {butLoading ? (
                            <TinyLoading/>
                        ) : (
                            null
                        )}
                      </button>
                </div>
              </div>
            </form>
        </FantasyModal>

        <ToastContainer />

      </>

  )

}