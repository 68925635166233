import React, {useEffect} from "react";
import {RemoveAccessToken} from "../../../Services/AxiosService/JwtService";
import {useNavigate} from "react-router-dom";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";

export const Logout = () => {
    const redirectTo = useNavigate();
    const LogoutHandler = async () => {
       try {
           const response = await POST('/Authentication/Logout',{},false);
           if(response.status === 200){
               RemoveAccessToken();
               redirectTo('/');
           }
           else{
               RemoveAccessToken();
               redirectTo('/');
           }
       }catch (e) {
           console.log(e);
       }
    };
    useEffect(() => {
        LogoutHandler();
    }, []);
};