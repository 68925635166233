import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useNavigate} from "react-router-dom";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import Select from "react-select";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {Return} from "../../../Commans/Globals/Return";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {Export} from "../../../Commans/Grid/Export";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ModalButtonOperations} from "../../../Commans/UIParts/ModalButtonOperations";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {useCustomWebsiteGrid} from "./useCustomWebsiteGrid";
import {useCustomWebsite} from "./useCustomWebsite";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {HandleFastRegister} from "../../../CRM/PersonsManagement/UserRegisteration/HandleFastRegister";
import {validate} from "../../../../Services/Globals/validate";
import {websiteSchema} from "./websiteSchema";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";




export const HandleListSite = ()=>{
    const { t } = useTranslation();
    const [modal, setModal] = useState({ SupportType: false ,WebsiteState:false, ChangeOwnership:false,ChangePassword:false,Bill:false,IsDeleteWebsite:false,IsUserOpen:false,ChangeRepresentative: false});
    const [showSpinner,setShowSpinner] = useState(false);
    const [isMenuOpen,setMenuOpen] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const redirectTo = useNavigate();
    const [butLoading,setBtnLoading] = useState(false);
    const [initialSource,setInitialSource] = useState({webTypes:[],users:[],websiteFilters:[]})
    const [errors,setErrors]=useState({});
    const [siteId,setSiteId] = useState("");

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {website,handleSelectValue,setWebsite,handleValue,setShowPassword,IsShowPassword,setCurrentShowPassword,IsShowCurrentPassword} = useCustomWebsite(setErrors);

    // opens a fast modal to register user in necessary situations .
    const handleIsOpenUser=()=>{
        setModal({IsUserOpen: true});
    }
    const handleIsCloseUser=()=>{
        setModal({IsUserOpen: false});
    }
    const handleSuccessUserFastRegister=(isSuccess)=>{
        if(isSuccess){
            handleIsCloseUser();
            Refresh();
        }
    }

    const handleClosePassword=()=>{
        setErrors({})
        setWebsite({password: '',newPassword: '',id: '',concurrencyStamp: ''});
        setModal({ChangePassword:false});
    }
    const handleOpenPassword = (websiteId,concurrencyStamp) => {
        setWebsite({websiteId: websiteId,concurrencyStamp: concurrencyStamp});
        setModal({ChangePassword: true});
    };




    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }

    const handleOpenOwnerShip=(websiteId,ownerName)=>{
        setModal({ChangeOwnership: true});
        setWebsite({id: websiteId,ownerName: ownerName,userId: initialSource.users[0]});
    }
    const handleCloseOwnerShip=()=>{
        setModal({ChangeOwnership: false});
        setWebsite({id: '',ownerName: '',userId: initialSource.users[0]});
    }


    const handleOpenChangeRepresentative=(websiteId,ownerName)=>{
        setModal({ChangeRepresentative: true});
        // setWebsite({id: websiteId,ownerName: ownerName,userId: initialSource.users[0]});
    }
    const handleCloseChangeRepresentative=()=>{
        setModal({ChangeRepresentative: false});
        // setWebsite({id: '',ownerName: '',userId: initialSource.users[0]});
    }




    const handleOpenMenu=(siteId)=>{
        setSiteId(siteId);
        setMenuOpen(true);
    }
    const onApiError = ()=>{
        setShowSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const Refresh=()=>{
        setRefresh(prevState =>!prevState);
    }

    const handleGetWebsiteState=(state)=>{
       return initialSource.webTypes.find((item)=>item.value === state);
    }
    const handleOpenWebsiteState = (siteId,domainToken,state)=>{
        setModal({ WebsiteState: true });
        const currentWebsiteState = handleGetWebsiteState(state)
        setWebsite((prevState)=>({...prevState,id: siteId,domainToken: domainToken,type:currentWebsiteState}));
    }

    const handleCloseSiteState = ()=>{
        setBtnLoading(false);
        setModal({ WebsiteState: false });
    }
    // updates website status
    const handleWebsiteState = async(e)=>{
      try {
          e.preventDefault();
          if(website.type === null){
              handleFailureMessage(t("Pleaseselectstatus"));
              return;
          }
          try {
              setBtnLoading(true);
              const response = await PUT(`/WebSites/Status`, {status:website.type.value,websiteId:website.id},false)
              if(response.status === 200){
                  Refresh();
                  handleCloseSiteState();
                  handleSuccessMessage(t("Successfullyedited"));
              }
          }catch (error) {
              console.error('Error fetching data:', error);
          }
      }catch (e) {
          onApiError();
          console.log(e);
      }
    }

    const handleOpenDeleteWebsite=(siteId,domainToken)=>{
        setModal({IsDeleteWebsite: true});
        setWebsite({id: siteId,domainToken: domainToken});
    }
    const handleCloseDeleteWebsite=()=>{
        setModal({IsDeleteWebsite: false});
        setWebsite({domainToken: '',type: initialSource.webTypes[0],password: '',id:''})
    }
    // deletes website .
    const handleDeleteWebsite=async(e)=>{
        e.preventDefault();
        try {
            setBtnLoading(true);
            const response = await DELETE(`/Websites/${website.id}`,{superAdminPassword:website.password},);
            if(response.status === 200){
                setBtnLoading(false);
                handleCloseDeleteWebsite();
                Refresh();
                handleSuccessMessage(t("RemovedSuccessfully"))
            }
            else{
                setBtnLoading(false);
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError();
            console.log(e);
        }
    }

    // updates the owners of website

    const handleSubmitOwner=async(e)=>{
        e.preventDefault();
        try {
            setBtnLoading(true);
            const response = await PUT(`/Websites/Ownership`,{websiteId:website.id,newOwnerUserId:website.userId.value});
            if(response.status === 200){
                setBtnLoading(false);
                handleCloseOwnerShip();
                Refresh();
                handleSuccessMessage(t("Successfullyedited"));
            }
            else {
                setBtnLoading(false)
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            setBtnLoading(false);
            console.log(e);
        }
    }

    //handle submit password change
    const handleSubmitPassword=async(e)=>{
        e.preventDefault();
        const isValid = validate(websiteSchema,website,onSetErrors);
        if(!isValid){
            return;
        }
        try {
           setBtnLoading(true);
           const response = await PUT(`/Websites/SuperAdminPassword`,{websiteId:website.id,concurrencyStamp:website.concurrencyStamp,currentPassword:website.password,newPassword:website.newPassword});
           if(response.status === 200){
              setBtnLoading(false);
              handleSuccessMessage('Successfullyedited');
              handleClosePassword();
              Refresh();
           }
           else{
               setBtnLoading(false);
               handleApiError(response,onApiError);
           }
        }
        catch (e){
            setBtnLoading(false);
            onApiError();
            console.log(e);
        }
    }


    const handleInitialData=(websiteStatus,users,responseFilters)=>{
        const websiteStatusData = convertSelectDataHandler(websiteStatus);
        const usersData = convertSelectDataHandler(users);
        const filters = convertSelectDataHandler(responseFilters);
        setInitialSource({
            webTypes:websiteStatusData,
            users: usersData,
            websiteFilters: filters
        })
    }

    const fetchData = async () => {
        try {
            const [responseWebsiteStatus, responseUser,responseFilter] = await Promise.all([
                GET('/Enumerations/EnWebSiteStatus', {}, 'IEnumerable'),
                GET('/Users/Selection', {}, 'IEnumerable'),
                GET(`/Filters/Selection?Table=${2}`, {}, 'IEnumerable')

            ]);

            if (responseWebsiteStatus.status === 200 && responseUser.status === 200 && responseFilter.status === 200) {
                handleInitialData(responseWebsiteStatus.data, responseUser.data,responseFilter.data);
            } else {
                handleApiError(responseWebsiteStatus, onApiError);
                handleApiError(responseUser, onApiError);
                handleApiError(responseFilter, onApiError);
            }
        } catch (e) {
            onApiError();
            console.log(e);
        }
    }

    useEffect(()=>{
       fetchData()
    },[])
    const {autoGroupColumnDef,columnDefs,currentPage,data,defaultColDef,handleCurrentPage,handleNextPage,handleSearch,handlePreviousPage,handlePageSizeCount,search,setSearch,handleToDateHandler,handleFromDateHandler,rowData,getRowHeight,resetFilters,setFilter,filterValueHandler,filter,handleSelectFilter,SwitchFilters,handleSwitchFilters} = useCustomWebsiteGrid(setShowSpinner,refresh,onApiError,initialSource,Refresh,handleOpenPassword,handleOpenOwnerShip,handleOpenWebsiteState,handleOpenMenu,handleOpenDeleteWebsite,handleOpenChangeRepresentative);





    return (
        <>
            <div className="space-y-5 relative">
                {

                    showSpinner?
                        <div className="huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div className="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("search")}</label>
                                <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full"  />
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("FromDate")}</label>
                                <DateTimePicker value={filter.ExpirationFrom} handleDateTime={handleFromDateHandler}  format={'YYYY/MM/DD'} />
                            </div>
                            <div class="space-y-2 ">
                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("ToDate")}</label>
                                <DateTimePicker value={filter.ExpirationTo} handleDateTime={handleToDateHandler} format={'YYYY/MM/DD'} />
                            </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("Filter")}</label>
                            <Select
                                options={initialSource.websiteFilters}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={true}
                                onChange={(data)=>handleSelectFilter(data,'filterId')}
                                placeholder={t("selection")}
                                value={filter.filterId}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo('/Admin/Website/Add')}
                                            functionClass={"harmony-creation"}
                                            tooltipTitle={t("websiteBuilder")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>handleSetting('FastRegister')}
                                            functionClass={"harmony-creation"}
                                            tooltipTitle={t("QuickRegistrationPeople")}
                                            title={t('fastRegistration')}
                                            icon={"pi-user-plus"}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleSwitchFilters} />

                                    </>
                                }
                    />

                </div>
                <div className={"space-y-2 H-body-ag-grid "}>

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}

                    />

                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <ToastContainer />
                <Export filters={filter} apiUrl={'/Websites/WebsiteListExport'}/>
            </div>

            {/*<FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >*/}
            {/*    <div className="p-1 grid grid-cols-3 md:grid-cols-4 gap-3">*/}
            {/*        <ModalButtonOperations*/}
            {/*            onClick={()=>redirectTo("/Support/Ticket")}*/}
            {/*            icon={"message-square"}*/}
            {/*            title={t("Tickets")}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</FantasyModal>*/}
            {/*<FantasyModal openModal={modal.SupportType}  closeModal={SupportTypeOnHide} title={t("support")}>*/}
            {/*    <div className="P-3">*/}
            {/*       <div className="grid grid-cols-2 gap-3 ">*/}
            {/*           <div className="space-y-2">*/}
            {/*               <label className="text-xs dark:text-gray-300">{t("SupportType")}</label>*/}
            {/*               /!*<MultiDataSelect placeholder={'انتخاب'} />*!/*/}
            {/*           </div>*/}
            {/*           <div class="flex items-center gap-2">*/}
            {/*               <Checkbox ></Checkbox>*/}
            {/*               <label className="text-xs dark:text-gray-300">{t("CreateInvoice")}</label>*/}
            {/*           </div>*/}
            {/*           <div className="col-span-2">*/}
            {/*               <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("register")}</button>*/}
            {/*           </div>*/}
            {/*       </div>*/}
            {/*    </div>*/}
            {/*</FantasyModal>*/}

            <FantasyModal openModal={modal.ChangeOwnership}  closeModal={handleCloseOwnerShip} title={t("ChangeOfOwnership")}>
                <form onSubmit={handleSubmitOwner} method={'post'}>
                    <div className={"space-y-5"}>
                        <div className="grid grid-cols-2 gap-3 ">
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("CurrentOwner")}</label>
                                <InputText className={"input"} disabled={true} value={website.ownerName}/>
                            </div>
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("NewOwner")}</label>
                                <Select
                                    options={initialSource.users}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'userId')}
                                    placeholder={t("selection")}
                                    value={website.userId}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                                />
                            </div>
                        </div>
                        <div>
                            <button type="submit" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("register")}</button>
                        </div>
                    </div>
                </form>
            </FantasyModal>
            <FantasyModal openModal={modal.ChangeRepresentative}  closeModal={handleCloseChangeRepresentative} title={t("ChangeOfOwnership")}>
                <div className={"space-y-5"}>
                    <div className="grid grid-cols-2 gap-3">
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("CurrentRepresentative")}</label>
                            <InputText className={"input"} disabled={true} value={website.ownerName}/>
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("NewRepresentative")}</label>
                            <Select
                                options={initialSource.users}
                                isMulti={false}
                                isSearchable={true}
                                onChange={(data)=>handleSelectValue(data,'userId')}
                                placeholder={t("selection")}
                                value={website.userId}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                    </div>
                    <div>
                        <button type="submit" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("register")}</button>
                    </div>
                </div>

            </FantasyModal>

            <FantasyModal openModal={modal.ChangePassword}  closeModal={handleClosePassword} title={t("ChangePassword")}>
               <form onSubmit={handleSubmitPassword} method={'post'}>
                   <div className="space-y-5">
                       <div className={"space-y-3"}>
                           <div className="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("CurrentPassword")}</label>
                               <DisplayError message={errors.password}/>
                               <span className="p-input-icon-left block w-full">

                                    <i className={
                                        IsShowPassword
                                            ? "pi pi-eye cursor-pointer"
                                            : "pi pi-eye-slash cursor-pointer"
                                    }
                                       onClick={() => setShowPassword(!IsShowPassword)}>
                                    </i>
                                   <InputText type={IsShowPassword?'text':'password'}
                                              className=" input w-full"
                                              placeholder={t("CurrentPassword")}
                                              value={website.password}
                                              onInput={(e) => handleValue(e)}
                                              name="password"
                                   />
                               </span>
                           </div>
                           <div className="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("NewPassword")}</label>
                               <DisplayError message={errors.newPassword}/>
                               <span className="p-input-icon-left block w-full">
                                   <i className={
                                       IsShowCurrentPassword
                                           ? "pi pi-eye cursor-pointer"
                                           : "pi pi-eye-slash cursor-pointer"
                                   }
                                      onClick={() => setCurrentShowPassword(!IsShowCurrentPassword)}>
                                    </i>
                                    <InputText type={IsShowCurrentPassword?'text':'password'}
                                               className=" input w-full"
                                               placeholder={t("NewPassword")}
                                               value={website.newPassword}
                                               onInput={(e) => handleValue(e)}
                                               name="newPassword"
                                    />
                               </span>

                           </div>
                       </div>
                       <div>
                           <button type="submit" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative">
                                   {t("register")}
                                   {butLoading ? (
                                       <TinyLoading
                                       />
                                   ) : (
                                       null
                                   )}
                               </button>
                       </div>
                   </div>
               </form>
            </FantasyModal>


            <FantasyModal openModal={modal.WebsiteState}  closeModal={handleCloseSiteState} title={t("SiteStatus")}>
                <form method={'post'} onSubmit={handleWebsiteState}>
                    <div className="space-y-5">
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("SiteStatus")}</label>
                            <Select
                                options={initialSource.webTypes}
                                isSearchable={true}
                                onChange={(data)=>handleSelectValue(data,'type')}
                                placeholder={t("selection")}
                                value={website.type}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        {/*<div className="space-y-2">*/}
                        {/*    <label className="text-xs dark:text-gray-300">نحوه اطلاع رسانی:</label>*/}
                        {/*    <MultiDataSelect placeholder={'انتخاب'} />*/}
                        {/*</div>*/}
                        <div>
                            <button type="submit" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative">
                                {t("register")}
                                {butLoading ? (
                                    <TinyLoading
                                    />
                                ) : (
                                    null
                                )}
                            </button>

                        </div>
                    </div>
                </form>
            </FantasyModal>


            <FantasyModal openModal={modal.IsDeleteWebsite}  closeModal={handleCloseDeleteWebsite} title={t("DeleteSite")}>
                <form method={'post'} onSubmit={handleDeleteWebsite}>
                    <div className="space-y-5">
                        <div className="relative float-input p-float-label">
                            <div className={"absolute -top-2 end-2"}>
                                <DisplayError message={errors.password}/>
                            </div>
                             <i className={
                                IsShowPassword
                                    ? "pi pi-eye cursor-pointer absolute dark:text-gray-300 end-2 top-1/2 -translate-y-1/2"
                                    : "pi pi-eye-slash cursor-pointer dark:text-gray-300 absolute end-2 top-1/2 -translate-y-1/2"
                                }
                                onClick={() => setShowPassword(!IsShowPassword)}>
                             </i>
                             <InputText type={IsShowPassword ? "text" : "password"}
                                 className=" input w-full"
                                placeholder={t("Password")}
                                value={website.password}
                                onInput={(e) => handleValue(e)}
                                name="password"
                                //onKeyDown={(event)=>event.key === 'Enter' ? SubmitHandlerApi():''}
                             />


                        </div>
                        <div>
                                <button type="submit" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative">
                                    {t("register")}
                                    {butLoading ? (
                                        <TinyLoading  />
                                    ) : (
                                        null
                                    )}
                                </button>
                        </div>

                    </div>
                </form>

            </FantasyModal>


            {/*<FantasyModal openModal={modal.IsUserOpen}  closeModal={handleIsCloseUser} title={t("SiteStatus")} customeClassName={"modal-xl"}>*/}
            {/*    {*/}
            {/*        modal.IsUserOpen?*/}
            {/*            <HandleFastRegister*/}
            {/*                IsDefault={false}*/}
            {/*                handleSuccess={handleSuccessUserFastRegister}*/}
            {/*                IsOpen={modal.IsUserOpen}*/}

            {/*            />*/}
            {/*            :null*/}
            {/*    }*/}
            {/*</FantasyModal>*/}

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>
                            :
                            'FastRegister'?
                            <HandleFastRegister
                                IsDefault={false}
                                handleSuccess={handleSuccessUserFastRegister}
                                IsOpen={modal.IsUserOpen}

                            />
                            :null
                    }
                </div>
            </FantasyModal>

        </>

    )
}