import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {NoteBox} from "./UserNotes/NoteBox";

export const Complaint=({complaints,type})=>{
    const { t } = useTranslation();

    return (
        <>
            {complaints && complaints.length > 0 ? (
                complaints.map((item, index) =>

                    item.type === type?
                        <NoteBox key={index} index={index} {...item} />
                        :null
                )
            ) : null}
        </>
    )
}