import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useTab=()=>{
    const { t } = useTranslation();
    const [tabId,setTabId] = useState("managerPrivacy");
    const handleTabId = (tabId) => {
        setTabId(tabId);
    };
    const tabs = [
        {
            id: "managerPrivacy",
            target: "#managerPrivacy",
            label: t("AdminPrivacy"),
            icon: "shield",
        },
        {
            id: "userPrivacy",
            target: "#userPrivacy",
            label: t("UserPrivacy"),
            icon: "shield",
        },
    ];
    return {
        tabs,
        tabId,
        setTabId,
        handleTabId
    }

}