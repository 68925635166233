import Joi from "joi";
//this is schema validation of login to be used for yup and reat-hook-form

export const websiteSchema = Joi.object({
    password: Joi.string().required().messages({
        'any.required': 'پسورد جاری الزامیست',
        'string.empty': 'پسورد جاری الزامیست',
        'string.base': 'پسورد جاری الزامیست',
    }),
    newPassword: Joi.string().required().messages({
        'any.required': 'پسورد جدید الزامیست',
        'string.empty': 'پسورد جدید الزامیست',
        'string.base': 'پسورد جدید الزامیست',
    }),
});