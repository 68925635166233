import React, {useMemo, useState} from "react";

import {useTranslation} from "react-i18next";
import {ProgressBar} from "primereact/progressbar";
import HarmonyProgressBar from "../../Commans/HarmonyProgressBar";



export const DashboardProjects=({rowData,GetCurrencyType})=>{
    const { t } = useTranslation();

    const [progress, setProgress] = useState(40); // مقدار اولیه درصد پیشرفت

    const getProgressStyle = () => {
        const percentage = Math.min((progress / 100) * 100, 100);
        return {
            width: `${percentage}%`,
        };
    };

    return(
        <>
            <div class="space-y-2 box bg-white dark:bg-themeDarkSalt700 p-2">
                <div className={"p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                    <h2 className={"dark:text-gray-300 text-xs"}>{t("ProjectManagement")}</h2>
                </div>
                <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 px-2"}>
                    <div className="p-2 space-y-3 justify-between post-input dark:bg-themeDarkPostInput post-input-design">
                        <div className={"p-2 bg-white box dark:bg-themeDarkSalt700"}>
                            <h2 className={"text-xs line-clamp-1 dark:text-gray-300"}>پروژه آرین</h2>
                        </div>
                        <div className={"flex gap-3 items-center flex-shrink-0"}>
                            <div className={"flex-shrink-0 overflow-hidden size-16 bg-white dark:bg-themeDarkSalt700"}>
                                <img src="dist/images/profile-15.jpg" className="size-full object-cover" alt=""/>
                            </div>
                            <div className={"w-full flex-1"}>
                                <div class="space-y-1">
                                    <div class="flex justify-between">
                                        <h2 class="text-xs dark:text-gray-300">درصد پیشرفت</h2>
                                    </div>
                                    <div class="w-full h-3 bg-gray-400 rounded relative ">
                                            <HarmonyProgressBar
                                                initialProgress={80}
                                            />
                                    </div>

                                    </div>
                                </div>
                        </div>
                        <div class="flex gap-2 items-center justify-between rounded">
                            <div className={"flex bg-white p-2 box dark:bg-themeDarkSalt700"}>
                                <h3 class="text-xs dark:text-gray-300">{t("StartDate")}:</h3>
                                <span class="text-xs dark:text-gray-300">1401/21/01</span>
                            </div>
                            <div class="flex bg-white p-2 box dark:bg-themeDarkSalt700">
                                <h3 class="text-xs dark:text-gray-300">{t("EndDate")}:</h3>
                                <span class="text-xs dark:text-gray-300">1400/21/01</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>

    )
}