import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useCustomServers} from "./useCustomServers";
import {GET, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import queryString from "query-string";
import Select from "react-select";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {ConnectionStringBuilder} from "../../../../Commans/Globals/ConnectionStringBuilder";
import {TagsInput} from "react-tag-input-component";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {ToastContainer} from "react-toastify";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";
import {handleConvertWebsiteServer} from "./websiteService";

export const HandleServer = ()=>{
    const { websiteId } = useParams();

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsOpen,setIsOpen]=useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [initialSource,setInitialSource] = useState({ hosts: [], webservers: [], dbServers: [],adminPanels:[],mails:[],dedicatedServers:[],coreServers:[]})
    const {handleSelectValue,handleValue,servers,setServer,handleToggleChange,setToggle,toggle,ipValidationStatus,isValidIp, setIpValidationStatus} = useCustomServers(initialSource);
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleIsOpen=()=>{
        setIsOpen(true)
    }
    const handleCloseIsOpen=()=>{
        setIsOpen(false);
    }

    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }

    // const handleInitialSource=(dbServers,hostServers,webServers,mailServers,adminPanelServers,dedicatedServers,coreServers)=>{
    //     const dbServerData = convertSelectDataHandler(dbServers);
    //     const hostServerData = convertSelectDataHandler(hostServers)
    //     const webServerData = convertSelectDataHandler(webServers);
    //     const mailServerData = convertSelectDataHandler(mailServers);
    //     const adminPanelData = convertSelectDataHandler(adminPanelServers);
    //     const dedicatedServerData = convertSelectDataHandler(dedicatedServers);
    //     const coreServerData = convertSelectDataHandler(coreServers);
    //     setInitialSource({
    //         dbServers: dbServerData,
    //         dedicatedServers: dedicatedServerData,
    //         adminPanelServers: adminPanelData,
    //         hostServers: hostServerData,
    //         mailServers: mailServerData,
    //         webServers: webServerData,
    //         coreServers: coreServerData
    //     })
    //     return {
    //         dbServerData,
    //         hostServerData,
    //         webServerData,
    //         mailServerData,
    //         adminPanelData,
    //         dedicatedServerData,
    //         coreServerData
    //     }
    // }

    const handleInitialSource = (dbServers, hostServers, webServers, mailServers, adminPanelServers, dedicatedServers, coreServers
    ) => {
        const serverTypes = {
            dbServers,
            hostServers,
            webServers,
            mailServers,
            adminPanelServers,
            dedicatedServers,
            coreServers,
        };
        const initialSource = Object.keys(serverTypes).reduce((acc, key) => {
            acc[key] = convertSelectDataHandler(serverTypes[key]);
            return acc;
        }, {});
        setInitialSource(initialSource);
        const {
            dbServers: dbServerData,
            hostServers: hostServerData,
            webServers: webServerData,
            mailServers: mailServerData,
            adminPanelServers: adminPanelData,
            dedicatedServers: dedicatedServerData,
            coreServers: coreServerData,
        } = initialSource;
        return {
            dbServerData,
            hostServerData,
            webServerData,
            mailServerData,
            adminPanelData,
            dedicatedServerData,
            coreServerData,
        };
    };

    const fetchData = async () => {
        try {
            showSpinner();
            const [
                responseHost,
                responseDbServer,
                responseWebserver,
                responseAdminPanel,
                responseMail,
                responseDedicated,
                responseCoreServer,
                response
            ] = await Promise.all([
                GET(`/Servers/Selection?Type=${1}`, {}, "IEnumerable"),
                GET(`/Servers/Selection?Type=${0}`, {}, "IEnumerable"),
                GET(`/Servers/Selection?Type=${2}`, {}, "IEnumerable"),
                GET(`/Servers/Selection?Type=${3}`, {}, "IEnumerable"),
                GET(`/Servers/Selection?Type=${4}`, {}, "IEnumerable"),
                GET(`/Servers/Selection?Type=${5}`, {}, "IEnumerable"),
                GET(`/Servers/Selection?Type=${6}`, {}, "IEnumerable"),

                GET(`/Websites/Servers`, { WebsiteId: websiteId}, 'IEnumerable')
            ]);
            if (responseHost.status === 200 && responseDbServer.status === 200 && responseWebserver.status === 200 &&
                responseAdminPanel.status === 200 && responseMail.status === 200 && responseDedicated.status === 200 &&
                responseCoreServer.status === 200 &&

                response.status === 200) {

                const { adminPanelData, dbServerData, dedicatedServerData, hostServerData, mailServerData, webServerData ,coreServerData} =
                    handleInitialSource(responseDbServer.data, responseHost.data, responseWebserver.data,
                        responseMail.data, responseAdminPanel.data, responseDedicated.data,responseCoreServer.data);

                setServer({
                    dbServerId: response.data.dbServerId ? dbServerData.find(item => item.value === response.data.dbServerId) : dbServerData[0]??null,
                    dbServerIp: response.data.dbServerIp ?? null,

                    hostServerId: response.data.hostServerId ? hostServerData.find(item => item.value === response.data.hostServerId) : hostServerData[0]??null,
                    hostServerIp: response.data.hostServerIp ?? null,

                    adminPanelServerId: response.data.adminPanelServerId ? adminPanelData.find(item => item.value === response.data.adminPanelServerId) : adminPanelData[0]??null,
                    adminPanelServerIp: response.data.adminPanelServerIp ?? null,

                    mailServerId: response.data.mailServerId ? mailServerData.find(item => item.value === response.data.mailServerId) : mailServerData[0]??null,
                    mailServerIp: response.data.mailServerIp ?? null,

                    webServerId: response.data.webServerId ? webServerData.find(item => item.value === response.data.webServerId) : webServerData[0]??null,
                    webServerIp: response.data.webServerIp ?? null,

                    dedicatedServerId: response.data.dedicatedServerId ? dedicatedServerData.find(item => item.value === response.data.dedicatedServerId) : dedicatedServerData[0] ?? null,
                    dedicatedServerIp: response.data.dedicatedServerIp || null,


                    coreServerIp: response.data.coreServerIp??null,
                    coreServerId: response.data.coreServerId? coreServerData.find((item)=>item.value === response.data.coreServerId):null,

                    connectionString: response.data.connectionString??null,
                    allowIps: response.data.allowIps??[],
                    websiteId:response.data.websiteId,
                    concurrencyStamp: response.data.concurrencyStamp
                });

                hideSpinner();
            } else {
                handleApiError(response, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(()=>{
        fetchData();
    },[refresh])

    const handleSubmitServers=async(e)=>{
        e.preventDefault();
        try {

            if(
                !ipValidationStatus.isHostServerIpValid ||
                !ipValidationStatus.isWebServerIpValid ||
                !ipValidationStatus.isAdminPanelServerIpValid ||
                !ipValidationStatus.isCoreServerIpValid ||
                !ipValidationStatus.isMailServerIpValid ||
                !ipValidationStatus.isDedicatedServerIpValid ||
                !ipValidationStatus.isDbServerIpValid
            ){
                handleFailureMessage(t("PleaseenteravalidserverIP"));
                return;
            }
            showSpinner();
            const websiteServer = handleConvertWebsiteServer(servers);
            const response = await PUT(`/Websites/Server`,websiteServer,false);
            if(response.status === 200){
                handleSuccessMessage(t('SuccessDone'));
                hideSpinner();
                redirectTo('/Admin/Website/List');
            }
            else{
                handleApiError(response,hideSpinner);
            }

        }
        catch (e) {
            hideSpinner();
        }
    }


    return (
        <>

            <div className={"box bg-white dark:bg-themeDarkSalt700 p-3"}>
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <></>
                            }
                />
            </div>
            <div className={"relative"}>

                <form method={'post'} onSubmit={handleSubmitServers} className={'space-y-5  relative '}>
                    {
                        IsShowSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className={"grid grid-cols-1 lg:grid-cols-2 bg-white dark:bg-themeDarkSalt700 box p-3"}>
                        <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-2 space-y-5"}>
                            <div>
                                <div class="can-toggle demo-rebrand-1 text-xs w-32">
                                    <input id="pub" type="checkbox" checked={toggle.isPublic} name={'isPublic'} onChange={(e)=>handleToggleChange(e.target.checked,'isPublic')} />
                                    <label htmlFor="pub">
                                        <div class="can-toggle__switch" data-checked={t("Public")} data-unchecked={t("Private")}></div>
                                    </label>
                                </div>
                            </div>
                            {
                                toggle.isPublic?
                                    <>
                                        <div class="col-span-12 lg:col-span-6 space-y-5">
                                            <div className="grid grid-cols-1 gap-4">

                                                <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                    {
                                                        toggle.isCoreServer?
                                                            <div className="md:col-span-3 ">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 w-20 flex items-center justify-between gap-1"}>
                                                                <span>
                                                                     <HPrimeIcon
                                                                         icon={"pi-server"}
                                                                     />
                                                                </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>{t("core")}</span>
                                                                    </div>
                                                                    <InputText  className={`input w-full ${ipValidationStatus.isCoreServerIpValid?'h-border-success':'h-border-danger'}`} onInput={(e)=>handleValue(e)} name={'coreServerIp'} value={servers.coreServerIp}  placeholder={t("CoreServerIp")}   />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="md:col-span-3">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 w-20 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>{t("core")}</span>
                                                                    </div>
                                                                    <Select
                                                                        options={initialSource.coreServers}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={(data)=>handleSelectValue(data,'coreServerId')}
                                                                        placeholder={t("CoreServer")}
                                                                        value={servers.coreServerId}
                                                                        className={'w-full multi_rounded_none'}
                                                                        classNamePrefix={"h_multi_select"}
                                                                        menuPosition={"fixed"}
                                                                    />
                                                                </div>
                                                            </div>
                                                    }
                                                    <div className="md:col-span-1">
                                                        <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                            <input id="swich6" type="checkbox" checked={toggle.isCoreServer}  onChange={(e)=>handleToggleChange(e.target.checked,'isCoreServer')} />
                                                            <label htmlFor="swich6">
                                                                <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>




                                                <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                    {
                                                        toggle.isHost?
                                                            <div className="md:col-span-3 ">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>host</span>
                                                                    </div>
                                                                    <InputText  className={`input w-full ${ipValidationStatus.isHostServerIpValid?'h-border-success':'h-border-danger'}`} onInput={(e)=>handleValue(e)} name={'hostServerIp'} value={servers.hostServerIp}  placeholder={t("IPServerHOST")}   />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="md:col-span-3">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>host</span>
                                                                    </div>
                                                                    <Select
                                                                        options={initialSource.hosts}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={(data)=>handleSelectValue(data,'hostServerId')}
                                                                        placeholder={t("ServerHOST")}
                                                                        value={servers.hostServerId}
                                                                        className={'w-full multi_rounded_none'}
                                                                        classNamePrefix={"h_multi_select"}
                                                                        menuPosition={"fixed"}
                                                                    />
                                                                </div>
                                                            </div>
                                                    }
                                                    <div className="md:col-span-1">
                                                        <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                            <input id="swich20" type="checkbox" checked={toggle.isHost} onChange={(e)=>handleToggleChange(e.target.checked,'isHost')} />
                                                            <label htmlFor="swich20">
                                                                <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                    {
                                                        toggle.isAdminPanel?
                                                            <div className="md:col-span-3 ">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>cpanel</span>
                                                                    </div>
                                                                    <InputText  className={`input w-full ${ipValidationStatus.isAdminPanelServerIpValid?'h-border-success':'h-border-danger'}`}   onInput={(e)=>handleValue(e)} name={'adminPanelServerIp'} value={servers.adminPanelServerIp}  placeholder={t("AdminPanelServerIp")}   />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="md:col-span-3">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>cpanel</span>
                                                                    </div>
                                                                    <Select
                                                                        options={initialSource.adminPanels}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={(data)=>handleSelectValue(data,'adminPanelServerId')}
                                                                        placeholder={t("AdminPanelServer")}
                                                                        value={servers.adminPanelServerId}
                                                                        className={'w-full multi_rounded_none'}
                                                                        classNamePrefix={"h_multi_select"}
                                                                        menuPosition={"fixed"}
                                                                    />
                                                                </div>
                                                            </div>
                                                    }
                                                    <div className="md:col-span-1">
                                                        <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                            <input id="adminPanel" type="checkbox" checked={toggle.isAdminPanel} onChange={(e)=>handleToggleChange(e.target.checked,'isAdminPanel')} />
                                                            <label htmlFor="adminPanel">
                                                                <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                    {
                                                        toggle.isMail?
                                                            <div className="md:col-span-3 ">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>mail</span>
                                                                    </div>
                                                                    <InputText  className={`input w-full ${ipValidationStatus.isMailServerIpValid?'h-border-success':'h-border-danger'}`}   onInput={(e)=>handleValue(e)} name={'mailServerIp'} value={servers.mailServerIp}  placeholder={t("MailServer")}   />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="md:col-span-3">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>mail</span>
                                                                    </div>
                                                                    <Select
                                                                        options={initialSource.mails}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={(data)=>handleSelectValue(data,'mailServerId')}
                                                                        placeholder={t("MailServer")}
                                                                        value={servers.mailServerId}
                                                                        className={'w-full multi_rounded_none'}
                                                                        classNamePrefix={"h_multi_select"}
                                                                        menuPosition={"fixed"}
                                                                    />
                                                                </div>
                                                            </div>
                                                    }
                                                    <div className="md:col-span-1">
                                                        <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                            <input id="mail" type="checkbox" checked={toggle.isMail} onChange={(e)=>handleToggleChange(e.target.checked,'isMail')} />
                                                            <label htmlFor="mail">
                                                                <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>



                                                <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">

                                                    {
                                                        toggle.isWebserver?
                                                            <div className="md:col-span-3">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>site</span>
                                                                    </div>
                                                                    <InputText  className={`input w-full ${ipValidationStatus.isWebServerIpValid?'h-border-success':'h-border-danger'}`}   onInput={(e)=>handleValue(e)} name={'webServerIp'} value={servers.webServerIp}  placeholder={t("IPServerSITE")}  />

                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="md:col-span-3">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>site</span>
                                                                    </div>
                                                                    <Select
                                                                        options={initialSource.webservers}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={(data)=>handleSelectValue(data,'webServerId')}
                                                                        placeholder={t("SeverSITE")}
                                                                        value={servers.webServerId}
                                                                        className={'w-full multi_rounded_none'}
                                                                        classNamePrefix={"h_multi_select"}
                                                                        menuPosition={"fixed"}

                                                                    />
                                                                </div>
                                                            </div>

                                                    }
                                                    <div className="md:col-span-1">
                                                        <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                            <input id="swich30" type="checkbox" checked={toggle.isWebserver} onChange={(e)=>handleToggleChange(e.target.checked,'isWebserver')} />
                                                            <label htmlFor="swich30">
                                                                <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                    {
                                                        toggle.isDbServer?
                                                            <div className="md:col-span-3 ">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>db</span>
                                                                    </div>

                                                                    {/*<InputText value={values.SqlServerIp}   placeholder={'IP-سرور SQL'} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'SqlServerIp')}  />*/}
                                                                    <InputText  className={`input w-full ${ipValidationStatus.isDbServerIpValid?'h-border-success':'h-border-danger'}`}  onInput={(e)=>handleValue(e)} name={'dbServerIp'} value={servers.dbServerIp}    placeholder={t("IPServerSQL")} />

                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="md:col-span-3 ">
                                                                <div className="p-inputgroup">
                                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 flex w-20 items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                        <span className={"text-xs dark:text-gray-300"}>db</span>
                                                                    </div>
                                                                    <Select
                                                                        options={initialSource.dbServers}
                                                                        isClearable={true}
                                                                        isSearchable={true}
                                                                        onChange={(data)=>handleSelectValue(data,'dbServerId')}
                                                                        placeholder={'Server Sql'}
                                                                        value={servers.dbServerId}
                                                                        className={'w-full multi_rounded_none'}
                                                                        classNamePrefix={"h_multi_select"}
                                                                        menuPosition={"fixed"}
                                                                    />
                                                                </div>
                                                            </div>
                                                    }


                                                    <div className="md:col-span-1">
                                                        <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                            <input id="swich10" checked={toggle.isDbServer} type="checkbox" onChange={(e)=>handleToggleChange(e.target.checked,'isDbServer')} />
                                                            <label htmlFor="swich10">
                                                                <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                            {
                                                toggle.isDbServer?
                                                    <div className="grid grid-cols-1">
                                                        <div className="space-y-2">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>{t("ConnectionSQL")}</span>
                                                                </div>
                                                                <InputText value={servers.connectionString} placeholder={t("ServerHOST")} className="input w-full" name={'connectionString'} onInput={(e)=>handleValue(e)} onClick={() => handleIsOpen()} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <div className="grid grid-cols-1">
                                                <div className="space-y-2">
                                                    <div className="p-inputgroup w-full ">
                                                        <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 flex items-center justify-between gap-1"}>
                                                <span>
                                                     <HPrimeIcon
                                                         icon={"pi-server"}
                                                     />
                                                </span>
                                                            <span className={"text-xs dark:text-gray-300"}>allowed ip</span>
                                                        </div>
                                                        <TagsInput value={servers.allowIps} onChange={(data)=>handleSelectValue(data,'allowIps')} name={"allowIps"} placeHolder={t("ValidIPs")}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                    :

                                    <div class="space-y-5 ">
                                        <div className={"grid grid-cols-3 lg:grid-cols-4 items-center gap-2"}>
                                            {
                                                toggle.isDedicated?
                                                    <div className="col-span-3">
                                                        <div className="p-inputgroup">
                                                            <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 w-20 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                <span className={"text-xs dark:text-gray-300"}>server</span>
                                                            </div>
                                                            <InputText  className={`input w-full ${ipValidationStatus.isDedicatedServerIpValid?'h-border-success':'h-border-danger'}`}   onInput={(e)=>handleValue(e)} name={'dedicatedServerIp'} value={servers.dedicatedServerIp}  placeholder={t("DedicatedServer")}   />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-span-3">
                                                        <div className="p-inputgroup">
                                                            <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 w-20 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                <span className={"text-xs dark:text-gray-300"}>server</span>
                                                            </div>
                                                            <Select
                                                                options={initialSource.dedicatedServers}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={(data)=>handleSelectValue(data,'dedicatedServerId')}
                                                                placeholder={t("MailServer")}
                                                                value={servers.dedicatedServerId}
                                                                className={'w-full multi_rounded_none'}
                                                                classNamePrefix={"h_multi_select"}
                                                                menuPosition={"fixed"}
                                                            />
                                                        </div>
                                                    </div>
                                            }
                                            <div className="col-span-1">
                                                <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                    <input id="dedicated" type="checkbox" checked={toggle.isDedicated} onChange={(e)=>handleToggleChange(e.target.checked,'isDedicated')} />
                                                    <label htmlFor="dedicated">
                                                        <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                toggle.isDedicated?
                                                    <div className="col-span-3 lg:col-span-4">
                                                        <div className="p-inputgroup">
                                                            <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                             <HPrimeIcon
                                                                 icon={"pi-server"}
                                                             />
                                                          </span>
                                                                <span className={"text-xs dark:text-gray-300"}>{t("ConnectionSQL")}</span>
                                                            </div>
                                                            <InputText value={servers.connectionString} placeholder={t("ServerHOST")} className="input w-full" name={'connectionString'} onInput={(e)=>handleValue(e)} onClick={() => handleIsOpen()} />
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>

                                    </div>

                            }

                        </div>
                        <div class="hidden lg:grid place-content-center">
                            <img src="/Assets/Image/template/Host.png" alt="design" className="max-w-3/4 mx-auto"/>
                        </div>
                        <ButtonSubmitAndCancel
                            onCancelClick={()=>redirectTo('/Admin/Website/List')}
                        />
                    </div>

                </form>
            </div>


            <FantasyModal
                openModal={IsOpen}
                closeModal={handleCloseIsOpen}
                title="sql-server-connection-string"
                customeClassName={"dir-ltr modal-x"}>

                <ConnectionStringBuilder
                    OnValueChange={handleSelectValue}
                    name={'connectionString'}
                    handleCloseModal={handleCloseIsOpen}
                />
            </FantasyModal>


            <ToastContainer/>
        </>
    )
}