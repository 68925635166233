//ساخت نظرسنجی اصلی
export const handleCreatePoll=(obj,surveys)=>{
    return {
        id: obj.id === null || obj.id === undefined ? null : obj.id,
        filterId: obj.filterId === null || obj.filterId === undefined ? null : obj.filterId.value,
        title: obj.title === null || obj.title === undefined === null ? null : obj.title,
        slug: obj.slug === null || obj.slug === undefined === null ? null : obj.slug,
        access: obj.access === null || obj.access === undefined ? null : obj.access.value,
        limitationType: obj.limitationType === null || obj.limitationType === undefined ? null : obj.limitationType.value,
        limitVote: obj.limitVote === null || obj.limitVote === undefined ? null : parseInt(obj.limitVote),
        fromDate: obj.fromDate === null || obj.fromDate === undefined ? null : obj.fromDate,
        toDate: obj.toDate === null || obj.toDate === undefined ? null : obj.toDate,
        sendingStates: obj.sendingStates.length === 0 || false || false ? [] : obj.sendingStates.map((item) => item.value),
        stateAndroid: obj.stateAndroid,
        stateGoogle: obj.stateGoogle,
        stateShow: obj.stateShow,
        stateShowResult: obj.stateShowResult,
        templateId: obj.templateId === null || obj.templateId === undefined ? null : obj.templateId.value,
        surveys: surveys
    }
}
//ساخت گروه و ایتم های نظر سنجی
export const handleCreateSurvey = (surveys) => {
    if(surveys.length > 0){
        return surveys.map((survey) => {
            const mappedSurvey = {
               id: survey.id === null || survey.id === undefined?null:survey.id,
                question: survey.question,
                type: survey.type.value, // Extracting the 'value' property from the 'type' object
                row: parseInt(survey.row),
                surveyItems: survey.surveyItems.map((surveyItem) => ({
                    id: surveyItem.id === null || surveyItem.id === undefined?null:surveyItem.id,
                    surveyItemName: surveyItem.surveyItemName === null || surveyItem.surveyItemName === undefined || surveyItem.surveyItemName === ''?  null:surveyItem.surveyItemName,
                    surveyItemValue: surveyItem.surveyItemValue === null || surveyItem.surveyItemValue === undefined || surveyItem.surveyItemName === ''? null:surveyItem.surveyItemValue.toString(),
                    linkedSurveyRaw: surveyItem.linkedSurveyRaw === null || surveyItem.linkedSurveyRaw === undefined || surveyItem.surveyItemName === ''? null:parseInt(surveyItem.linkedSurveyRaw),
                    nextLinkedSurveyRaw: surveyItem.nextLinkedSurveyRaw === null || surveyItem.nextLinkedSurveyRaw === undefined || surveyItem.nextLinkedSurveyRaw === ''? null:parseInt(surveyItem.nextLinkedSurveyRaw),
                    row: parseInt(surveyItem.row),
                })),
            };
            return mappedSurvey;
        });
    }else{
        return []
    }
};

export const handleCreateEditSurvey = (surveys) => {
    if(surveys.length > 0){
        return surveys.map((survey) => {
            const mappedSurvey = {
                id: survey.id === null || survey.id === undefined?null:survey.id,
                question: survey.question,
                type: survey.type.value, // Extracting the 'value' property from the 'type' object
                row: parseInt(survey.row),
                surveyItems: survey.surveyItems.map((surveyItem) => ({
                    id: surveyItem.id === null || surveyItem.id === undefined?null:surveyItem.id,
                    surveyItemName: surveyItem.surveyItemName === null || surveyItem.surveyItemName === undefined || surveyItem.surveyItemName === ''?  null:surveyItem.surveyItemName,
                    surveyItemValue: surveyItem.surveyItemValue === null || surveyItem.surveyItemValue === undefined || surveyItem.surveyItemName === ''? null:surveyItem.surveyItemValue.toString(),
                    linkedSurveyRaw: surveyItem.linkedSurveyRaw === null || surveyItem.linkedSurveyRaw === undefined || surveyItem.surveyItemName === ''? null:parseInt(surveyItem.linkedSurveyRaw),
                    nextLinkedSurveyRaw: surveyItem.nextLinkedSurveyRaw === null || surveyItem.nextLinkedSurveyRaw === undefined || surveyItem.nextLinkedSurveyRaw === ''? null:parseInt(surveyItem.nextLinkedSurveyRaw),
                    row: parseInt(surveyItem.row),
                })),
            };
            return mappedSurvey;
        });
    }else{
        return []
    }
};





 //    export const handleFilterSurveys = (surveys,surveyTypes) => {
 //        return surveys.map((survey) => {
 //            const mappedSurvey = {
 //                id: survey.id === null || survey.id === undefined?null:survey.id,
 //                question: survey.question,
 //                type: surveyTypes.find((item)=>item.value === survey.type),
 //                row: survey.row,
 //                surveyItems: survey.surveyItems.map((surveyItem) => ({
 //                    id: surveyItem.id === null || surveyItem.id === undefined?null:surveyItem.id,
 //                    surveyItemName: surveyItem.surveyItemName === null || surveyItem.surveyItemName === undefined || surveyItem.surveyItemName === ''?  null:surveyItem.surveyItemName,
 //                    surveyItemValue: surveyItem.surveyItemValue === null || surveyItem.surveyItemValue === undefined || surveyItem.surveyItemName === ''? null:surveyItem.surveyItemValue.toString(),
 //                    linkedSurveyRaw: surveyItem.linkedSurveyRaw === null || surveyItem.linkedSurveyRaw === undefined || surveyItem.surveyItemName === ''? null:surveyItem.linkedSurveyRaw,
 //                    row:surveyItem.row,
 //                })),
 //            };
 //            return mappedSurvey;
 //        });
 //    };
 //

export const handleFilterSurveys = (surveys, surveyTypes) => {
    return surveys.map((survey) => {
        if (survey.type === 2) {
            // Find the surveyItem with row === 1 and row === 2
            const surveyItemRow1 = survey.surveyItems.find(
                (surveyItem) => surveyItem.row === 1
            );

            const surveyItemRow2 = survey.surveyItems.find(
                (surveyItem) => surveyItem.row === 2
            );

            // Update the first surveyItem with row === 1
            if (surveyItemRow1 && surveyItemRow2) {
                // If nextLinkedSurveyRaw doesn't exist, create it
                if (!surveyItemRow1.nextLinkedSurveyRaw) {
                    surveyItemRow1.nextLinkedSurveyRaw = surveyItemRow2.linkedSurveyRaw;
                }
            }

            // Filter out surveyItems with row === 2
            const filteredSurveyItems = survey.surveyItems.filter(
                (surveyItem) => surveyItem.row !== 2
            );

            // Update the survey object
            const mappedSurvey = {
                id: survey.id === null || survey.id === undefined ? null : survey.id,
                question: survey.question,
                type: surveyTypes.find((item) => item.value === survey.type),
                row: survey.row,
                isShowEmoji:false,
                surveyItems: filteredSurveyItems.map((surveyItem) => ({
                    id:
                        surveyItem.id === null || surveyItem.id === undefined
                            ? null
                            : surveyItem.id,
                    surveyItemName:
                        surveyItem.surveyItemName === null ||
                        surveyItem.surveyItemName === undefined ||
                        surveyItem.surveyItemName === ''
                            ? null
                            : surveyItem.surveyItemName,
                    surveyItemValue:
                        surveyItem.surveyItemValue === null ||
                        surveyItem.surveyItemValue === undefined ||
                        surveyItem.surveyItemName === ''
                            ? null
                            : surveyItem.surveyItemValue.toString(),
                    linkedSurveyRaw:
                        surveyItem.linkedSurveyRaw === null ||
                        surveyItem.linkedSurveyRaw === undefined ||
                        surveyItem.surveyItemName === ''
                            ? null
                            : surveyItem.linkedSurveyRaw,
                    nextLinkedSurveyRaw: surveyItem.nextLinkedSurveyRaw, // Preserve existing field
                    row: surveyItem.row,
                })),
            };

            return mappedSurvey;
        }

        // For other types, keep the original mapping logic
        const mappedSurvey = {
            id: survey.id === null || survey.id === undefined ? null : survey.id,
            question: survey.question,
            type: surveyTypes.find((item) => item.value === survey.type),
            row: survey.row,
            surveyItems: survey.surveyItems.map((surveyItem) => ({
                id:
                    surveyItem.id === null || surveyItem.id === undefined
                        ? null
                        : surveyItem.id,
                surveyItemName:
                    surveyItem.surveyItemName === null ||
                    surveyItem.surveyItemName === undefined ||
                    surveyItem.surveyItemName === ''
                        ? null
                        : surveyItem.surveyItemName,
                surveyItemValue:
                    surveyItem.surveyItemValue === null ||
                    surveyItem.surveyItemValue === undefined ||
                    surveyItem.surveyItemName === ''
                        ? null
                        : surveyItem.surveyItemValue.toString(),
                linkedSurveyRaw:
                    surveyItem.linkedSurveyRaw === null ||
                    surveyItem.linkedSurveyRaw === undefined ||
                    surveyItem.surveyItemName === ''
                        ? 0
                        : surveyItem.linkedSurveyRaw,
                nextLinkedSurveyRaw: surveyItem.nextLinkedSurveyRaw, // Preserve existing field
                row: surveyItem.row,
            })),
        };

        return mappedSurvey;
    });
};