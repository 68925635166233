import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import ButtonVideo from "../../Commans/UIParts/ButtonVideo";
import {useNavigate} from "react-router-dom";

import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {GridTable} from "../../Commans/Grid/GridTable";
import Select from "react-select";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {Accordion, AccordionTab} from "primereact/accordion";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {SiteMap} from "./SiteMap";
import {useGoogleSiteMap} from "./useGoogleSiteMap";
import {SlugItem} from "./SlugItem";
import {handleConvertAddSiteMap, handleConvertEditSiteMap, handleGetSlugData} from "./GoogleMapService";
import {FilterType} from "./FilterType";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {validate} from "../../../Services/Globals/validate";
import {googleSiteMapSchema} from "./googleSiteMapSchema";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {useGoogleSiteMapGrid} from "./useGoogleSiteMapGrid";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {ToastContainer} from "react-toastify";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
export const HandleGoogleSiteMap=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsOpen, setIsOpen] = useState(false);
    const [IsShowSpinner,setIsShowSpinner]= useState(false);
    const [errors,setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({siteMapTypes:[],slugFilterTypes:[],ScheduleFrequencies:[],slugs:[],siteMapFrequencies:[]});
    const [IsEdit,setIsEdit] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const {handleCheckValue,handleValue,siteMap,setSiteMap,handleCheckboxChange,handleFieldChange,handleSelectValue}= useGoogleSiteMap(setErrors,initialSource);

    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const handleResetErrors=()=>{
        setErrors({});
    }
    const handleOpenSiteMap=()=>{
        const convertedSlugData = handleGetSlugData(initialSource.slugs,initialSource.siteMapFrequencies);
        setSiteMap({siteMapType:initialSource.siteMapTypes[0].value,items:convertedSlugData,slugFilterType:0,isDefault: true,scheduleFrequency:initialSource.ScheduleFrequencies[0]})
        setIsOpen(true)
    }
    const handleCloseSiteMap=()=>{
        handleResetErrors();
        const convertedSlugData = handleGetSlugData(initialSource.slugs,initialSource.siteMapFrequencies);
        setSiteMap({siteMapType:initialSource.siteMapTypes[0].value,items:convertedSlugData,slugFilterType:0,isDefault: true,scheduleFrequency:initialSource.ScheduleFrequencies[0],name: ''})
        setIsOpen(false);
        setIsEdit(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const showSpinner=()=>{

        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleInitialSource=(SiteMapTypes,SlugFilterTypes,ScheduleFrequencies,responseSlugs,SiteMapFrequencies)=>{
        const siteMapTypeData = convertSelectDataHandler(SiteMapTypes)
        const SlugFilterTypesData = convertSelectDataHandler(SlugFilterTypes)
        const ScheduleFrequenciesData = convertSelectDataHandler(ScheduleFrequencies)
        const SlugsData = convertSelectDataHandler(responseSlugs)
        const SiteMapFrequenciesData = convertSelectDataHandler(SiteMapFrequencies)
        return {siteMapTypeData,SlugFilterTypesData,ScheduleFrequenciesData,SlugsData,SiteMapFrequenciesData}
    }


    const fetchData = async () => {
        try {
            showSpinner();

            const [responseSiteMapType,responseSlugFilterType,responseScheduleFrequency,responseSlugs,responseSiteMapFrequency] = await Promise.all([
                GET(`/Enumerations/EnSiteMapType`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnSlugFilterType`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnScheduleFrequency`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnSlugType?Keys=${0}&Keys=${1}&Keys=${2}&Keys=${4}&Keys=${5}&Keys=${6}`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnSiteMapFrequency`, {}, 'IEnumerable'),
            ]);

            if (
                responseSiteMapType.status === 200 &&
                responseSlugFilterType.status === 200 &&
                responseScheduleFrequency.status === 200 &&
                responseSlugs.status === 200 &&
                responseSiteMapFrequency.status === 200
            ) {
                const {siteMapTypeData,SlugFilterTypesData,ScheduleFrequenciesData,SlugsData,SiteMapFrequenciesData} = handleInitialSource(responseSiteMapType.data,responseSlugFilterType.data,responseScheduleFrequency.data,responseSlugs.data,responseSiteMapFrequency.data)
                setInitialSource({
                    siteMapTypes:siteMapTypeData,
                    slugs:SlugsData,
                    siteMapFrequencies:SiteMapFrequenciesData,
                    ScheduleFrequencies: ScheduleFrequenciesData,
                    slugFilterTypes: SlugFilterTypesData
                });

                hideSpinner();
            } else {
                handleApiError(responseSiteMapType, hideSpinner);
                handleApiError(responseSlugFilterType, hideSpinner);
                handleApiError(responseScheduleFrequency, hideSpinner);
                handleApiError(responseSlugs, hideSpinner);
                handleApiError(responseSiteMapFrequency, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };


useEffect(()=>{
    fetchData()
},[])

    const handleSiteMapSubmit=async(e)=>{
       e.preventDefault();
       const isValid = validate(googleSiteMapSchema,siteMap,onSetErrors);
       if(!isValid){
           return;
       }
       try {
         if(IsEdit){
             showSpinner();
             const data = handleConvertEditSiteMap(siteMap);
             const response = await PUT('/Seo/EditSiteMapPattern',data,false);
             if(response.status === 200){
                 handleCloseSiteMap();
                 hideSpinner();
                 Refresh();
                 handleSuccessMessage(t('SuccessDone'));
             }
             else{
                 handleApiError(response,hideSpinner);
             }
         }
         else{
             showSpinner();
             const data = handleConvertAddSiteMap(siteMap);
             const response = await POST('/Seo/SiteMapPattern',data,false);
             if(response.status === 200){
                 handleCloseSiteMap();
                 hideSpinner();
                 Refresh();
                 handleSuccessMessage(t('SuccessDone'));
             }
             else{
                 handleApiError(response,hideSpinner);
             }
         }
       }
       catch (e) {
           hideSpinner();
       }
    }



const {autoGroupColumnDef,columnDef,defaultColDef, filter,setFilter,getRowHeight,handleSearch,setSearch,search,handleCurrentPage,handleNextPage,handlePreviousPage,handlePageSizeCount,handleResetFilters,handleToggleFilter,SwitchFilters, rowData,pagination} = useGoogleSiteMapGrid(showSpinner,hideSpinner,refresh,Refresh,setIsEdit,setIsOpen,setSiteMap,initialSource);

    return(
        <>
            <div className={"space-y-5 relative"}>

                {
                    IsShowSpinner?
                        <div className="huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }


                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByName")}</label>
                            <InputText type={"search"} className={" input w-full search"} />
                        </div>
                        <ButtonSearchList
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleOpenSiteMap()}
                                            tooltipTitle={t("introductionToGoogle")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SEO/Redirect/Add`)}
                                            icon={"pi-link"}
                                            title={t("redirect")}
                                            tooltipTitle={t("redirect")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SEO/KeyWords`)}
                                            icon={"pi-book"}
                                            title={t("Keywords")}
                                            tooltipTitle={t("Keywords")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Authentication/FileManager`)}
                                            icon={"pi-folder"}
                                            title={t("FileManagement")}
                                            tooltipTitle={t("FileManagement")}

                                        />


                                    </>
                                }
                    />

                </div>
                <div className={"space-y-2 H-body-ag-grid"} >

                    <GridTable
                        columnDefs={columnDef}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export apiUrl={''} filters={filter}/>

            </div>

            <FantasyModal openModal={IsOpen}  closeModal={handleCloseSiteMap}  title={t("introductionToGoogle")}>
             <form onSubmit={handleSiteMapSubmit} method={'post'} >
                 <div className={"space-y-5"}>
                     <div className={"space-y-2"}>
                         <div className={"space-y-1"}>
                             <div className={"flex items-center justify-between gap-1"}>
                                 <label className={"text-xs dark:text-gray-300"}>
                                     {t("Title")}
                                     <RequiredIcon/>
                                 </label>
                                 <DisplayError message={errors.name}/>
                             </div>

                             <InputText onInput={handleValue} value={siteMap.name} name={'name'} className={"input w-full"}/>
                         </div>
                         <div className={"bg-white box dark:bg-themeDarkSalt700 p-2 space-y-2"}>
                             <div className={"bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput p-2 border-themeInformation dark:border-themeDarkInformation"}>
                                 <p className={"dark:text-gray-300 text-xs"}>{t("Manufacturingmethod")}</p>
                             </div>
                             <div className={"px-2 grid grid-cols-1 md:grid-cols-2 gap-3"}>
                                 {
                                     initialSource.siteMapTypes.map((item, index) => (
                                         <SiteMap siteMap={siteMap}
                                                  index={index}
                                                  handleCheckValue={handleCheckValue}
                                                  item={item}
                                         />
                                     ))
                                 }
                             </div>
                         </div>
                         <div className={"bg-white box p-2 dark:bg-themeDarkSalt700 space-y-2"}>
                             <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput p-2 border-themeInformation dark:border-themeDarkInformation">
                                 <p className={"dark:text-gray-300 text-xs"}>{t("InformationFilter")}</p>
                             </div>
                             <div className={"px-2 grid grid-cols-1 md:grid-cols-2 gap-3"}>
                                 {
                                     initialSource.slugFilterTypes.map((item,index) => (
                                         <FilterType
                                             siteMap={siteMap}
                                             index={index}
                                             handleCheckValue={handleCheckValue}
                                             item={item}
                                         />
                                     ))
                                 }

                             </div>
                         </div>
                         <Accordion className={"bg-white dark:bg-themeDarkSalt700"}>
                             <AccordionTab
                                 header={
                                     <div>
                                         <p>{t("Averagechanges")} (changefreq)</p>
                                     </div>
                                 }
                             >
                                 <div>
                                     <div className={"space-y-2"}>
                                         {
                                             siteMap.items.map((item, index) => (
                                                 <SlugItem
                                                     item={item}
                                                     index={index}
                                                     handleCheckboxChange={handleCheckboxChange}
                                                     handleFieldChange={handleFieldChange}
                                                     siteMapFrequencies={initialSource.siteMapFrequencies}
                                                 />
                                             ))
                                         }
                                     </div>
                                 </div>
                             </AccordionTab>
                         </Accordion>
                         <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                             <div className={"space-y-1"}>
                                 <label className={"text-xs dark:text-gray-300"}>{t("Timing")}</label>
                                 <Select
                                     options={initialSource.ScheduleFrequencies}
                                     isMulti={false}
                                     isClearable={false}
                                     isSearchable={true}
                                     onChange={(data)=>handleSelectValue('scheduleFrequency',data)}
                                     name={'scheduleFrequency'}
                                     placeholder={t("selection")}
                                     value={siteMap.scheduleFrequency}
                                     className={"select-group"}
                                     classNamePrefix={"h_multi_select"}
                                     menuPosition={'fixed'}
                                 />
                             </div>

                             <div>
                                 <InputSwitch value={siteMap.isDefault} OnValueSelection={handleCheckValue} lable={t("default")} name={'isDefault'}/>
                             </div>

                         </div>
                     </div>
                     <div>
                         <button type={'submit'} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white "} >{t("creation")}</button>
                     </div>
                 </div>

             </form>
            </FantasyModal>
            <ToastContainer/>
        </>
    )
}