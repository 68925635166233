import React, { useEffect, useState } from "react";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {PickList} from "primereact/picklist";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
export const HandlePackageAddRole = () => {
    const { t } = useTranslation();
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const redirectTo = useNavigate();
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [initialSource,setInitialSource] = useState({accesses:[],permissionGroups:[],allFilteredPermissions:[],allSelectedPermissions:[]})
    const [refresh, setRefresh] = useState(false);
    const [permissionGroupId,setPermissionGroupId] = useState(null);
    const[selectedPermissionGroupId,setSelectedPermissionGroupId] = useState(null)
    const { packageId } = useParams();
    const showSpinner = () => {
        setIsShowSpinner(true);
    };
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const handleChangeData = (event) => {
        setSource(event.source);
        setTarget(event.target);
    };

    //these parameters are directly from api
    const  handleFilterSelectedPermissionsInAllPermissions=(allPermissions,packagePermissions)=>{
        const permissionList = allPermissions.data.map(permission => ({ ...permission}));
        const selectedPermissions = permissionList.filter(item => packagePermissions.data.permissionIds.some(sourceItem => sourceItem === item.id));
        const filteredPermissions = permissionList.filter(permission => !selectedPermissions.some(selectedPermission => selectedPermission.id === permission.id));
        const updatedSelectedPermissions = selectedPermissions.map(selectedPermission => ({
            ...selectedPermission
        }));
        return {filteredPermissions,updatedSelectedPermissions}
    }

    // Initialize all fields,DropDowns
    const handleInitialDataSource=(permissionGroups)=>{
        const groups = convertSelectDataHandler(permissionGroups);
        groups.push({label:'-', value: '*'})
        setInitialSource({permissionGroups: groups});
    }

    // Gets All Data from server
    const fetchData = async () => {
        try {
           showSpinner()
            const allPermissions = await GET(`/Permissions`,{},'IEnumerable');
            const packagePermission = await GET(`/Packages/${packageId}/Permissions`,{},'IEnumerable');
            const allPermissionGroups = await GET(`/Permissions/PermissionGroups`,{},'IEnumerable');
            if (allPermissions.status === 200 && packagePermission.status === 200 && allPermissionGroups.status === 200) {
                handleInitialDataSource(allPermissionGroups.data)
                const {filteredPermissions,updatedSelectedPermissions} = handleFilterSelectedPermissionsInAllPermissions(allPermissions,packagePermission)
                setSource(filteredPermissions);
                setTarget(updatedSelectedPermissions);
                setInitialSource(prevState => ({...prevState,allFilteredPermissions: filteredPermissions,allSelectedPermissions: updatedSelectedPermissions}))
               hideSpinner();
            }
        }catch (e) {
            hideSpinner();
        }
    };


    useEffect(() => {
        fetchData();
    }, [refresh]);


    const itemTemplate = (item) => {
        return (
            <div className="flex items-center gap-1 border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkInformation p-2 rounded ">
                <div>
                    <div className={`p-2 rounded ${item.dataAccess === 0 ?'bg-themeSecondary':'bg-themeInformation dark:bg-themeDarkInformation'}`}>
                        <HPrimeIconSmall icon={'pi-user'} iconClass={'text-white'}/>
                    </div>
                </div>
                <span className="font-bold line-clamp-1 w-full dark:text-gray-300">{item.name}</span>
                <div className={"flex gap-2 items-center flex-shrink-0"}>
                    <button type={"button"}>
                        <HPrimeIconSmall icon={"pi-list"} size={18} iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
                    </button>
                </div>
            </div>
        );
    };

    // filters all filtered Permissions based on group Id
    const handleGetAllFilteredPermissionByGroupId=(groupId)=>{
        const groups = initialSource.allFilteredPermissions.filter(item=>item.permissionGroupId === groupId);
        return groups;
    }

    //filters all filtered permissions in left side from source based on groups
    // saves permissionGroupId to be used in search box handler
    const handleFilterAllSourceByGroup=(permissionGroupId)=>{
        setPermissionGroupId(permissionGroupId.value)
        if(permissionGroupId.value === '*'){
            setSource(initialSource.allFilteredPermissions);
        }
        else{
            const groupedPermissions = handleGetAllFilteredPermissionByGroupId(permissionGroupId.value)
            if(groupedPermissions.length > 0){
                setSource(groupedPermissions);
            }else{
                setSource([])
            }
        }
    }
    // filters source from all filtered permissions to include group Name
    // checks 2 situations , if we have empty search box we get back to selected group
    // second situation is checked when we have all permissions when permissionGroupId is *
    const handleFilterSourceByName = (groupName) => {
        if ((groupName === '' || groupName === null || groupName === undefined) ) {
            if(permissionGroupId === '*'){
                setSource(initialSource.allFilteredPermissions);
                return;
            }else{
                const previousSelectedGroups = handleGetAllFilteredPermissionByGroupId(permissionGroupId);
                setSource(previousSelectedGroups);
                return;
            }
        }
        const lowerCaseGroupName = groupName.toLowerCase();
        const permissions = initialSource.allFilteredPermissions.filter(item => item.name.toLowerCase().includes(lowerCaseGroupName));
        setSource(permissions);
    }








    // filters all filtered SelectedPermissions based on group Id
    const handleGetAllFilteredSelectedByGroupId=(groupId)=>{
        const groups = initialSource.allSelectedPermissions.filter(item=>item.permissionGroupId === groupId);
        return groups;
    }

    //filters all filtered Selected permissions in right side from target based on groups
    // saves selectedPermissionGroupId to be used in search box handler
    const handleFilterAllTargetByGroup=(selectedPermissionGroupId)=>{
        setSelectedPermissionGroupId(selectedPermissionGroupId.value)
        if(selectedPermissionGroupId.value === '*'){
            setTarget(initialSource.allSelectedPermissions);
        }
        else{
            const groupedPermissions = handleGetAllFilteredSelectedByGroupId(selectedPermissionGroupId.value)
            if(groupedPermissions.length > 0){
                setTarget(groupedPermissions);
            }else{
                setTarget([]);
            }
        }
    }
    // filters target from all filtered permissions to include group Name
    // checks 2 situations , if we have empty search box we get back to selected group
    // second situation is checked when we have all permissions when selectedPermissionGroup is *
    const handleFilterTargetByName = (groupName) => {
        if ((groupName === '' || groupName === null || groupName === undefined) ) {
            if(selectedPermissionGroupId === '*'){
                setTarget(initialSource.allSelectedPermissions);
                return;
            }else{
                const previousSelectedGroups = handleGetAllFilteredSelectedByGroupId(selectedPermissionGroupId);
                setTarget(previousSelectedGroups);
                return;
            }
        }
        const lowerCaseGroupName = groupName.toLowerCase();
        const permissions = initialSource.allSelectedPermissions.filter(item => item.name.toLowerCase().includes(lowerCaseGroupName));
        setTarget(permissions);
    }



    const handleSubmitPackagePermissions=async()=>{
        try {
            showSpinner();
            const permissions = {
                packageId: packageId,
                permissionIds: target.map((item) => (item.id)),
            };
            const response = await POST(`/Packages/Permissions`,permissions,false);
            if(response.status === 200){
                hideSpinner();
                handleSuccessMessage(t("Permissionsbeensuccessfully"))
                Refresh();
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }
        catch (e) {
            hideSpinner();
        }
    }



    return (
        <>

            <div className=" space-y-5  ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/Add`)}
                                            tooltipTitle={t("UsersRegistration")}
                                            title={t("personnel")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/Group`)}
                                            icon={"pi-users"}
                                            title={t("grouping")}
                                            tooltipTitle={t("CustomerGrouping")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo('/CRM/User/Role')}
                                            icon={"pi-star"}
                                            title={t("Role")}
                                            tooltipTitle={t("CreatingRole")}
                                        />


                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/List`)}
                                            icon={"pi-list"}
                                            title={t("list")}
                                            tooltipTitle={t("PersonList")}

                                        />



                                    </>

                                }

                    />


                </div>
                <div class="relative">
                    {IsShowSpinner ? (
                        <div className="huploader_loading">
                            <div className="relative">
                                <LoadingHarmony />
                            </div>
                        </div>
                    ) : null}
                    <div style={{direction:"ltr"}} class="p-3 box bg-white dark:bg-themeDarkSalt700">
                        <PickList
                            target={target}
                            source={source}
                            onChange={handleChangeData}
                            filter
                            filterBy="name"
                            sourceFilterTemplate={
                                <div className={'space-y-2'}>
                                    <InputText onChange={(e)=>handleFilterSourceByName(e.target.value)} className={'input w-full'} placeholder={t("search")}/>
                                    <Select
                                        options={initialSource.permissionGroups}
                                        isSearchable={true}
                                        placeholder={t("selection")}
                                        onChange={(data)=>handleFilterAllSourceByGroup(data)}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />

                                </div>
                            }
                            targetFilterTemplate={
                                <div className={'space-y-2'}>
                                    <InputText  onChange={(e)=>handleFilterTargetByName(e.target.value)} className={'input w-full'} placeholder={t("search")}/>
                                    <Select
                                        options={initialSource.permissionGroups}
                                        isSearchable={true}
                                        placeholder={t("selection")}
                                        onChange={(data)=>handleFilterAllTargetByGroup(data)}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}

                                    />
                                </div>
                            }
                            sourceHeader={t("Unauthorized")}
                            targetHeader={t("Authorized")}
                            sourceStyle={{ height: "30rem" }}
                            targetStyle={{ height: "30rem" }}
                            sourceFilterPlaceholder={t("search")}
                            targetFilterPlaceholder={t("search")}
                            itemTemplate={itemTemplate}

                        />
                    </div>
                </div>
                <ButtonSubmitAndCancel
                    onRegisterClick={()=>handleSubmitPackagePermissions()}
                />

            </div>

            <ToastContainer/>

        </>

    );
};
