import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../../../Commans/UIParts/MultiDataSelect";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";



export const HandleAddInvoice=()=>{

    const [modal, setModal] = useState({ addBranch: false, addProject: false , addGoods:false , addSupplier:false ,addFastRegister:false });

    const addProjectOnHide = () => {
        setModal((prevState) => ({ ...prevState, addProject: !modal.addProject }));
    };

    const addBranchOnHide = () => {
        setModal((prevState) => ({ ...prevState, addBranch: !modal.addBranch }));
    };

    const addGoodsOnHide = () => {
        setModal((prevState) => ({ ...prevState, addGoods: !modal.addGoods }));
    };
    const addSupplierOnHide = () => {
        setModal((prevState) => ({ ...prevState, addSupplier: !modal.addSupplier }));
    };
    const addFastRegisterOnHide = () => {
        setModal((prevState) => ({ ...prevState, addFastRegister: !modal.addFastRegister }));
    };


    return(
        <div class=" space-y-5">
            <div class="flex gap-2 items-center justify-between box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="flex items-center gap-4">
                    <div className="dark:text-gray-300">نوع فاکتور</div>
                    <div class="pos">
                        <div class="pos__tabs nav-tabs justify-center flex gap-3 post-input dark:bg-themeDarkSalt600 px-2 py-1 rounded">
                            <a data-toggle="tab" data-target="#tab_one" href="javascript:;" className="flex-1 lg:w-24 px-3 py-2 rounded-md flex justify-center items-center dark:text-gray-300 active">فروش</a>
                            <a data-toggle="tab" data-target="#tab_two" href="javascript:;" className="flex-1 lg:w-24 px-3 py-2 rounded-md flex justify-center items-center dark:text-gray-300">خرید</a>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center lg:justify-end items-center gap-3">
                    <div class="flex justify-center gap-2">
                        <input type="checkbox" name="" className="w-5 h-5"/>
                        <label className="text-xs dark:text-gray-300">پیش فاکتور</label>
                    </div>
                    <div class="flex justify-center gap-2">
                        <input type="checkbox" name="" className="w-5 h-5"/>
                        <label className="text-xs dark:text-gray-300">فاکتور مرجوعی</label>
                    </div>
                </div>
            </div>
            <div class="post__content tab-content">
                <div class="tab-content__pane space-y-5  p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700 " id="tab_one">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 post-input dark:bg-themeDarkPostInput p-3 rounded-md">
                        <div className="space-y-2">
                            <div class="flex items-center gap-2">
                                <label className="text-xs dark:text-gray-300">کد حسابداری:</label>
                                <div class="can-toggle demo-rebrand-1 text-xs">
                                    <input id="swich2" type="checkbox"/>
                                    <label htmlFor="swich2">
                                        <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <InputText type={"text"} className={" input w-full"} />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div class="flex justify-between">
                                <label className="text-xs dark:text-gray-300">مشتری:</label>
                                <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFastRegister: true })}>
                                    + افزودن
                                </button>
                            </div>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" className="select2 w-full" data-placeholder="مشتری">*/}
                            {/*    <option value="">حسن</option>*/}
                            {/*    <option value="">عباس</option>*/}
                            {/*    <option value="">رضا</option>*/}
                            {/*</select>*/}
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">عنوان:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">ارجاع:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ سررسید:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <div class="flex justify-between">
                                <label className="text-xs dark:text-gray-300">شعبه:</label>
                                <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBranch: true })}>
                                    + افزودن
                                </button>
                            </div>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" className="select2 w-full" data-placeholder="شعبه">*/}
                            {/*    <option value="">حسن</option>*/}
                            {/*    <option value="">عباس</option>*/}
                            {/*    <option value="">رضا</option>*/}
                            {/*</select>*/}
                        </div>
                        <div className="space-y-2">
                            <div class="flex justify-between">
                                <label className="text-xs dark:text-gray-300">پروژه:</label>
                                <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addProject: true })}>
                                    + افزودن
                                </button>
                            </div>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" className="select2 w-full" data-placeholder="پروژه">*/}
                            {/*    <option value="">حسن</option>*/}
                            {/*    <option value="">عباس</option>*/}
                            {/*    <option value="">رضا</option>*/}
                            {/*</select>*/}
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">مسئول فروش:</label>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" className="select2 w-full" data-placeholder="مسئول فروش">*/}
                            {/*    <option value="">حسن</option>*/}
                            {/*    <option value="">عباس</option>*/}
                            {/*    <option value="">رضا</option>*/}
                            {/*</select>*/}
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">درصد:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">واحد پول:</label>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" className="select2 w-full">*/}
                            {/*    <option value="" selected>ریال</option>*/}
                            {/*    <option value="">دلار</option>*/}
                            {/*    <option value="">دینار</option>*/}
                            {/*</select>*/}
                        </div>
                        <div className="flex items-end">
                            <button type="button" className=" text-center w-24 bg-themeInformation dark:bg-themeDarkInformation text-white button" onClick={() => setModal({ addGoods: true })}>ثبت کالا</button>
                        </div>
                    </div>
                </div>
                <div class="tab-content__pane space-y-5  p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700 active" id="tab_two">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 post-input dark:bg-themeDarkPostInput p-3 rounded-md">
                        <div className=" space-y-2">
                            <div class="flex items-center gap-2">
                                    <label className="text-xs dark:text-gray-300">کد حسابداری:</label>
                                    <div class="can-toggle demo-rebrand-1 text-xs">
                                        <input id="swich" type="checkbox"/>
                                        <label htmlFor="swich">
                                            <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                        </label>
                                    </div>
                                </div>
                            <div>
                                    <InputText type={"text"} className={"input w-full"} />
                                </div>
                        </div>
                        <div class="space-y-2">
                            <div class="flex justify-between">
                                <label className="text-xs dark:text-gray-300">تامین کننده:</label>
                                <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addSupplier: true })}>
                                    + افزودن
                                </button>
                            </div>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" className="select2 w-full" data-placeholder="مشتری">*/}
                            {/*    <option value="">حسن</option>*/}
                            {/*    <option value="">عباس</option>*/}
                            {/*    <option value="">رضا</option>*/}
                            {/*</select>*/}
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">عنوان:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">ارجاع:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ سررسید:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <div class="flex justify-between">
                                <label className="text-xs dark:text-gray-300">شعبه:</label>
                                <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBranch: true })}>
                                        + افزودن
                                </button>
                                </div>
                                <MultiDataSelect placeholder={'انتخاب'} />
                                {/*<select name="" className="select2 w-full" data-placeholder="شعبه">*/}
                                {/*    <option value="">حسن</option>*/}
                                {/*    <option value="">عباس</option>*/}
                                {/*    <option value="">رضا</option>*/}
                                {/*</select>*/}
                            </div>
                            <div class="space-y-2">
                                <div class="flex justify-between">
                                    <label className="text-xs dark:text-gray-300">پروژه:</label>
                                    <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addProject: true })}>
                                        + افزودن
                                    </button>
                                </div>
                                <MultiDataSelect placeholder={'انتخاب'} />
                                {/*<select name="" className="select2 w-full" data-placeholder="پروژه">*/}
                                {/*    <option value="">حسن</option>*/}
                                {/*    <option value="">عباس</option>*/}
                                {/*    <option value="">رضا</option>*/}
                                {/*</select>*/}
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">واحد پول:</label>
                                <MultiDataSelect placeholder={'انتخاب'} />
                                {/*<select name="" className="select2 w-full">*/}
                                {/*    <option value="" selected>ریال</option>*/}
                                {/*    <option value="">دلار</option>*/}
                                {/*    <option value="">دینار</option>*/}
                                {/*</select>*/}
                            </div>
                            <div class="flex items-end">
                                <button className=" text-center w-24 bg-themeInformation dark:bg-themeDarkInformation text-white button" onClick={() => setModal({ addGoods: true })}>ثبت کالا</button>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <div class="overflow-x-auto">
                        <div class="inline-block min-w-full py-1 sm:px-3">
                            <div class="">
                                <table
                                    className="min-w-full overflow-x-auto text-sm font-light CloneDataThree table-add">
                                    <thead class="border-b  dark:border-neutral-500 bg-gray-200">
                                    <tr>
                                        <th className="px-4 p1 whitespace-no-wrap border">#</th>
                                        <th className="px-4 p1 whitespace-no-wrap border">محصول</th>
                                        <th className="px-4 p1 whitespace-no-wrap border">شرح</th>
                                        <th className="px-4 p1 whitespace-no-wrap border">واحد</th>
                                        <th className="px-4 p1 whitespace-no-wrap border">تعداد</th>
                                        <th className="px-4 p1 whitespace-no-wrap border">قیمت واحد</th>
                                        <th className="px-4 p1 whitespace-no-wrap border">تخفیف</th>
                                        <th className="px-4 p1 whitespace-no-wrap border">مالیات</th>
                                        <th className="px-4 p whitespace-no-wrap border">قیمت کل</th>
                                        <th className="px-4 p whitespace-no-wrap border">عملیات</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="text-center p-2 border">1</td>
                                        <td className=" td-tbody p-2 border">
                                            <h3 class="line-clamp-1 text-xs">
                                                2
                                            </h3>
                                        </td>
                                        <td className="td-tbody p-2 border">
                                            <p class="line-clamp-1 text-xs">3</p>
                                        </td>
                                        <td className="td-tbody-two p-2 border">
                                            <h3>4 </h3>
                                        </td>
                                        <td className="td-tbody-two p-2 border">
                                            <h3>
                                                5
                                            </h3>
                                        </td>
                                        <td className="td-tbody-two p-2 border">
                                            <div>
                                                6
                                            </div>
                                        </td>
                                        <td className="td-tbody-two p-2 border">
                                            <div class="relative"><h3>7</h3>
                                                <div class="absolute le-0 top-0 bottom-0 flex items-center justify-center text-gray-600">%
                                                </div>
                                            </div>
                                        </td>
                                        <td className="td-tbody-two p-2 border">
                                            <div class="relative ">
                                                <h3>8</h3>
                                                <div class="absolute end-0 top-0 bottom-0 flex items-center justify-center text-gray-600">
                                                    %
                                                </div>
                                            </div>
                                        </td>
                                        <td className="td-tbody p-2 border text-center">
                                            <h3>9</h3>
                                        </td>
                                        <td className="p-2 border">
                                            <div class="flex justify-center items-center gap-2 text-xs">
                                                <a className="flex items-center bg-theme-9 text-white gap-1 px-2 py-1 text-white rounded"
                                                   href="#"> <i data-feather="check-square"
                                                                class="w-4 h-4"></i>ویرایش</a>
                                                <a className="flex items-center bg-theme-6 text-white gap-1 px-2 py-1 text-white rounded removeTwo"
                                                   href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف </a>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="grid grid-cols-1 lg:grid-cols-3 gap-5 ">
                <div class="box bg-white dark:bg-themeDarkSalt700">
                    <div class="border-b-2 p-3 text-themeInformation dark:text-themeDarkInformation">
                        سایر هزینه ها
                    </div>
                    <div class="space-y-4 p-4">
                        <div class="flex justify-between">
                            <label className="text-xs dark:text-gray-300">تخفیف:</label>
                            <input className="input input--switch border bg-white" type="checkbox"/>
                        </div>
                        <div class="flex justify-between">
                            <label className="text-xs dark:text-gray-300">مالیات:</label>
                            <input className="input input--switch border bg-white" type="checkbox"/>
                        </div>
                        <div class="flex justify-between">
                            <label className="text-xs dark:text-gray-300">حمل و نقل:</label>
                            <input className="input input--switch border bg-white" type="checkbox"/>
                        </div>
                    </div>
                </div>
                <div class="box bg-white dark:bg-themeDarkSalt700 flex flex-col justify-between">
                        <div>
                            <div class="border-b-2 p-3 flex justify-between">
                                <h3 className="text-themeInformation dark:text-themeDarkInformation">اتوماتیک</h3>
                                <h3 className="text-themeInformation dark:text-themeDarkInformation">1401/01/21</h3>
                            </div>
                            <div className="p-3">
                                <div className="post-input dark:bg-themeDarkSalt600 rounded-md overflow-hidden ">
                                    <div className=" p-3 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300">
                                        <p>اطلاعات مشتری</p>
                                    </div>
                                    <div className="p-2 space-y-2">
                                        <div class=" flex gap-5 items-center">
                                            <span class="w-12 h-12 block rounded-full overflow-hidden ">
                                                <img src="/Assets/Image/user/person1.png" className="w-full h-full object-cover" alt=""/>
                                            </span>
                                            <h3 className="line-clamp-1 dark:text-gray-300">مسعود احمدی</h3>
                                        </div>
                                        <div>
                                            <h3 className="dark:text-gray-300">پروژه</h3>
                                            <div class="flex gap-5 items-center">
                                                <span class="w-8 h-8 block rounded-full overflow-hidden bg-gray-200">
                                                <img src="/Assets/Image/user/person1.png" className="w-full h-full" alt=""/>
                                            </span>
                                                <h3 className="line-clamp-1 dark:text-gray-300">مسعود احمدی</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-3">
                            <button className="bg-themeInformation dark:bg-themeDarkInformation text-white py-3 w-full rounded-md flex items-center justify-center gap-2">
                                <HPrimeIcon icon={'bookmark'}/>
                                ثبت فاکتور
                            </button>
                        </div>
                    </div>
                <div class="box bg-white dark:bg-themeDarkSalt700">
                        <div>
                            <div class="border-b-2 p-3 flex justify-between">
                                <h3 class="text-themeInformation dark:text-themeDarkInformation">سررسید</h3>
                                <h3 class="text-themeInformation dark:text-themeDarkInformation">1401/01/21</h3>
                            </div>
                        </div>
                        <div class="p-3 ">
                            <div class="post-input dark:bg-themeDarkPostInput p-3 rounded-md space-y-5 ">
                                <div class="flex justify-between">
                                    <h3 className="text-xs dark:text-gray-300">تعداد:</h3>
                                    <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                                    <h3 className="text-xs dark:text-gray-300">0</h3>
                                </div>
                                <div class="flex justify-between">
                                    <h3 className="text-xs dark:text-gray-300">مجموع:</h3>
                                    <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                                    <h3 className="text-xs dark:text-gray-300"> 0 ریال</h3>
                                </div>
                                <div class="flex justify-between">
                                    <h3 className="text-xs dark:text-gray-300">تخفیف:</h3>
                                    <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                                    <h3 className="text-xs dark:text-gray-300">0 ریال</h3>
                                </div>
                                <div class="flex justify-between">
                                    <h3 className="text-xs dark:text-gray-300">مالیات:</h3>
                                    <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                                    <h3 className="text-xs dark:text-gray-300">0 ریال</h3>
                                </div>
                                <div class="flex justify-between">
                                    <h3 className="text-xs dark:text-gray-300">سایر هزینه ها:</h3>
                                    <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                                    <h3 className="text-xs dark:text-gray-300">0 ریال</h3>
                                </div>
                                <div class="flex justify-between box p-2 text-themeSuccess bg-gray-200 ">
                                    <h3 className="text-xs">مبلغ کل(ریال):</h3>
                                    <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                                    <h3>0</h3>
                                </div>
                            </div>
                        </div>
                        <div class="p-3">
                            <button className="bg-themeInformation dark:bg-themeDarkInformation text-white py-3 w-full rounded-md flex items-center justify-center gap-2">
                                <HPrimeIcon icon={'corner-up-left'}/>
                                ذخیره و ادامه
                            </button>
                        </div>
                    </div>
            </div>



            <FantasyModal openModal={modal.addFastRegister}  closeModal={addFastRegisterOnHide}>
                <div class=" space-y-3" >
                    <div class=" grid grid-cols-1 md:grid-cols-3 gap-2 ">
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300"> نوع کاربری:</label>
                            <MultiDataSelect />
                                {/*<select data-hide-search="true" className="select2 w-full">*/}
                                {/*    <option value="2" selected>مشتری</option>*/}
                                {/*    <option value="3">نماینده</option>*/}
                                {/*    <option value="4">بازاریاب</option>*/}
                                {/*    <option value="4">پرسنل</option>*/}
                                {/*    <option value="4">مدیر سایت</option>*/}
                                {/*</select>*/}
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">تاریخ ثبت نام:</label>
                            <InputText className="input w-full" placeholder={'شماره موبایل خود'}  />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">تاریخ تمدید:</label>
                            <InputText className="input w-full" placeholder={'شماره موبایل خود'} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">آقای/خانم:</label>
                            <MultiDataSelect />
                            {/*<select data-hide-search="true" className="select2 w-full">*/}
                            {/*    <option value="1" selected>آقای</option>*/}
                            {/*    <option value="2">خانم</option>*/}
                            {/*</select>*/}
                        </div>
                        <div class="md:col-span-3 space-y-1">
                            <label className="text-xs dark:text-gray-300">نام و نام خانوادگی:</label>
                            <InputText className="input w-full"  />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2 ">
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">تلفن همراه: </label>
                            <InputText className="input w-full"  />
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300"> تلفن ثابت:</label>
                            <InputText className="input w-full" />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">ایمیل:</label>
                            <InputText className="input w-full"   />
                        </div>
                        <div class=" space-y-1">
                            <label className="text-xs dark:text-gray-300">آی دی تلگرام:</label>
                            <InputText className="input w-full"  />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">طریقه آشنایی:</label>
                            <MultiDataSelect />
                            {/*<select data-hide-search="true" className="select2 w-full">*/}
                            {/*    <option value="1" selected>اینستاگرام</option>*/}
                            {/*    <option value="2">وب سایت</option>*/}
                            {/*    <option value="3">کانال تلگرام</option>*/}
                            {/*    <option value="4">دوستان</option>*/}
                            {/*    <option value="4">تبلیغات</option>*/}
                            {/*    <option value="4">سایر</option>*/}
                            {/*</select>*/}
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">معرف:</label>
                            <InputText className="input w-full"  />
                        </div>
                    </div>
                    <div class="flex items-center justify-center gap-2">
                        <button className="button w-24 bg-themeDanger  text-white">انصراف</button>
                        <button className="button w-24 bg-themeInformation dark:bg-themeDarkInformation text-white">ثبت</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addGoods}  closeModal={addGoodsOnHide}>
                <div class=" space-y-3" >
                    <h2 class="p-2 border-b border-dashed dark:border-themeDarkColorLine text-xs dark:text-gray-300">مشخصات محصول را وارد کنید</h2>
                    <div class="grid grid-cols-2 gap-2">
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">نام محصول:</label>
                            <MultiDataSelect placeholder={'انتخاب'} />
                                {/*<select name="" className="nameProduct select2 w-full">*/}
                                {/*    <option value="1">...</option>*/}
                                {/*    <option value="2">کالا</option>*/}
                                {/*</select>*/}
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">شرح:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        </div>
                    <div class="grid grid-cols-2 gap-2 ">
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">واحد</label>
                                <MultiDataSelect placeholder={'انتخاب'} />
                                {/*<select name="" className="select2 w-full unit" data-hide-search="true">*/}
                                {/*    <option value="1">عدد</option>*/}
                                {/*    <option value="2">بسته</option>*/}
                                {/*    <option value="3">باکس</option>*/}
                                {/*</select>*/}
                            </div>
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">تعداد</label>
                                <InputText type={"text"} className={"input w-full"} />
                                {/*<input type="text" name="" className="Number input w-full hnumber">*/}
                            </div>
                        </div>
                    <div class="grid grid-cols-2 gap-2 ">
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">قیمت واحد:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">تخفیف:</label>
                            <div class="relative">
                                <InputText type={"text"} className={"input w-full"} />
                                <div class="absolute end-2 top-1/2 -translate-y-1/2 text-gray-300">
                                    %
                                </div>
                            </div>
                        </div>
                        </div>
                    <div class="grid grid-cols-2 gap-2 ">
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">مالیات:</label>
                            <div class="relative">
                                <InputText type={"text"} className={"input w-full"} />
                                <div class="absolute end-2 top-1/2 -translate-y-1/2 text-gray-300">
                                    %
                                </div>
                            </div>
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">قیمت کل:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                    </div>
                    <div class="flex justify-center items-center gap-3">
                        <button className="button w-24 bg-themeDanger  text-white" > انصراف</button>
                        <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation"> ثبت</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addBranch}  closeModal={addBranchOnHide} title={"ایجاد شعبه"}>
                <div class=" space-y-5">
                    <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">نام شعبه:</label>
                        <InputText type={"text"} className={"input w-full "} />
                    </div>
                    <div class=" flex gap-3 justify-center">
                        <button className="button w-24 bg-themeDanger text-white " > انصراف</button>
                        <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation ">ایجاد</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addProject}  closeModal={addProjectOnHide} title={"ایجاد پروژه"}>
                <div class=" space-y-3" >
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">نام پروژه:</label>
                        <InputText type={"text"} className={"input w-full "} />
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ شروع:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ پایان:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                    </div>
                    <div class="space-y-2">
                        <div class="flex flex-wrap gap-2 items-center">
                            <label className="text-xs dark:text-gray-300">رنگ دلخواه:</label>
                            <span class="bg-red-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-red-700 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-blue-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-gray-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-yellow-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                        </div>
                    </div>
                    <div class="space-y-2">
                        <div class="flex justify-between items-center p-2 box shadow rounded">
                            <div class="flex flex-row items-center">
                                <label className="text-xs dark:text-gray-300">تصویر دلخواه:</label>
                                <input type="file" className="huploader"/>
                            </div>
                            <div>
                                <button className="button p-1  bg-theme-6 text-white"><span class="w-5 h-5 flex items-center justify-center"> <i data-feather="trash"class="w-4 h-4"></i> </span>
                                </button>
                                <button className="button p-1  bg-theme-9 text-white"><span class="w-5 h-5 flex items-center justify-center"> <i data-feather="eye" class="w-4 h-4"></i> </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className="text-xs dark:text-gray-300">انتخاب اعضاء:</label>
                        <div >
                            <MultiDataSelect />
                            {/*<select className="select2 w-full" multiple>*/}
                            {/*    <option value="1">حسن</option>*/}
                            {/*    <option value="1">عباس</option>*/}
                            {/*    <option value="1">رضا</option>*/}
                            {/*    <option value="1">حسین</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>
                    <div class=" flex gap-3 justify-center">
                        <button className="button w-24 bg-themeDanger text-white  "> انصراف </button>
                        <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation ">ایجاد</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addSupplier}  closeModal={addSupplierOnHide}>
                <div class="" >
                </div>
            </FantasyModal>
        </div>



    )

}
