import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DELETE, GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";

import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
export const useFieldGrid=(showSpinner,hideSpinner,Refresh,refresh,setRefresh,setIsEdit,setIsFieldOpen,setField)=>{
    const {t} = useTranslation();
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [rowData, setRowData] = useState([]);
    const showLoader=()=>{
        setWait(true)
    }
    const hideLoader=()=>{
        setWait(false)
    }



    const handleGetCommunication= async(communicationId)=>{
        try {
            showSpinner()
            setIsFieldOpen(true);
            setIsEdit(true);
            const response = await GET(`/Communications/${communicationId}`,{},'single');
            if(response.status === 200){
                setField({
                    communicationId:response.data.communicationId,
                    title:response.data.title,
                    order:response.data.order,
                    stateShow:response.data.stateShow,
                    description:response.data.username,
                })
               hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner();
        }
    }


    const handleDeleteCommunication = async (communicationId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                   showSpinner();
                    const response = await DELETE(`/Communications/${communicationId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh()
                        handleSuccessMessage(t('SuccessDone'))
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    hideSpinner()
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const handleChangeActivation = async(communicationId,rowName)=>{
        try {
            showLoader()
            setHeaderName(rowName)
            const response = await PUT(`/Communications/Activation?CommunicationId=${communicationId}`,{},false);
            if (response.status === 200) {
                hideLoader();
                Refresh();
            }
            else{
                handleApiError(response,hideLoader)
            }
        }catch (e) {
            hideLoader()
        }
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Title"),minWidth: 170,
            cellRenderer:parameters=>
                <>
                {parameters.data.title}
                </>
        },
        {
            headerName: t("description") ,minWidth: 150,
            cellRenderer: parameters =>
                <>
                    {parameters.data.description}
                </>

        },
        {
            headerName: t("DisplayOrder") ,minWidth: 150,
            cellRenderer: parameters =>
                <>
                    {parameters.data.order}
                </>

        },

        { headerName: t("Status"),field: 'stateShow', maxWidth: 140, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeActivation(params.data.communicationId,params.colDef.headerName)}
                            icon={"pi-check-square"}
                        />
                    ) : (
                        <HPrimeIcon
                            onClick={async() =>await handleChangeActivation(params.data.communicationId,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={()=>handleGetCommunication(params.data.communicationId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteCommunication(params.data.communicationId)}
                    />
                </div>
            }
        },

    ];


    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',isActive:null,isDeActive:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',isActive: null,isDeActive: null}));
        setSearch('');
    }



    useEffect(() => {
        OnGridReady()
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.isActive,filter.isDeActive]);

    const OnGridReady = async () => {
        try {
           showSpinner();
            const response =  await GET(`/Communications`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,IsActive:filter.isActive,IsDeActive:filter.isDeActive}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
        } catch (error) {
            hideSpinner()
        } finally {
            hideSpinner()
        }
    };


    const SwitchFilters = [
        {name:'isActive',lable:t("isActive"),value:filter.isActive},
        {name:'isDeActive',lable:t("isNotActive"),value:filter.isDeActive}
    ]


    return {
        handleGetCommunication,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteCommunication,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
    }
}