import {useState} from "react";
import {GetInitialProperties} from "./NumbersService";

export const UseAddNumbersDataBinders=(setErrors,setFields,setProperties)=>{


    const [numbers,setNumbers] = useState({id:null,name:'',password:'',operator:null,fromNumber:'',username:'',stateShow:false,panelUrl:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setNumbers({...numbers,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setNumbers({...numbers,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setNumbers({...numbers,[name]:data});
        if(name === 'operator'){
            const properties= GetInitialProperties(data.fields);
            setFields(properties);
            setProperties([])
        }
    }



    return {
        handleNumberValue,
        handleValue,
        handleSelectValue,
        numbers,
        setNumbers
    }
}