import React, {useState} from "react";

export const useEmployee=()=>{
    const [employee,setEmployee]=useState({
        userId:null,
        attendanceType:null,
        bloodType:null,
        militaryServiceStatus:null,
        housingStatus:null,
        marriageDate:null,
        insurancePosition:null,
        maritalStatus:null,
        signature:null,
        signatureSrc:null,
        signaturePath:null
    });
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setEmployee({...employee,[name]:value});
    }
    const handleSelectValue =(data,name)=>{
        setEmployee((prevState) => ({ ...prevState,[name]:data }));
    }
    const handleChangeMarriageDateTime=(data)=>{
        setEmployee({ ...employee, marriageDate: data});
    }

    const handleSignatureFile = (event,name,pathField) => {
        const file = event.target.files[0];
        const newFilePath = URL.createObjectURL(file);
        setEmployee({...employee,[name]: file,[pathField]:newFilePath});
    };

    const handleRemoveSignature=()=>{
        if (typeof employee.signature === 'string') {
            // If the signature is a string, set signaturePath with the current signature value
            setEmployee({
                ...employee,
                signaturePath: employee.signature
            });
        } else {
            // If the signature is not a string (e.g., it's a File object), reset signature and signatureSrc
            setEmployee({
                ...employee,
                signature: null,
                signatureSrc: null
            });
        }
    }


    return {
        employee,
        setEmployee,
        handleChangeMarriageDateTime,
        handleValue,
        handleSelectValue,
        handleSignatureFile,
        handleRemoveSignature
    }

}