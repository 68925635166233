import React, { useState } from "react";
import { GridTable } from "../../Commans/Grid/GridTable";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {BuyerInformation} from "./BuyerInformation";
import {AccountingOperations} from "./AccountingOperations";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {useCartItemsDetailGrid} from "./useCartItemsDetailGrid";
import {useCustomCartItem} from "./useCustomCartItem";
import {GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {Product} from "../ProductCart/Product";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {useCartItems} from "../ProductCart/useCartItems";
import {handleConvertProductCartItems} from "../ProductCart/CartService";
import queryString from "query-string";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import Select from "react-select";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {Accordion, AccordionTab} from "primereact/accordion";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {useSetting} from "../../Commans/Hooks/useSetting";

export const HandleInvoiceCartProduct = () => {
    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const[showSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(false)
    const [IsOpen,setIsOpen] = useState(false);
    const [errors,setErrors] = useState({});
    const {cart,handleSelectValue,handleNumberValue,handleValue,setCart,handleCreateDateTime} = useCustomCartItem(setErrors);
    const [initialSource,setInitialSource] = useState({currencies:[],cartTypes:[],productTypes:[],users:[],factors:[]});
    const [buyer,setBuyer] = useState(null);
    const [cost,setCost] = useState({transportPrice:0,payablePrice:0,totalPrice:0,currencyName:''});
    const [ProductCartItems,setProductCartItems] = useState([]);
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {handleChange,handleRemoveRow,handleAddRow,handleResetProductCartItems} = useCartItems(initialSource,setProductCartItems,ProductCartItems);

    //handles api response error
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // refreshes components .
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const handleCloseModal=()=>{
        setIsOpen(false);
    }

    const operations = [
        { label:t("Billcreation") },
        { label: t("ShippingOrder") },
        { label: t("ContactOrder") },
        { label: t("InventoryReceiptPrinting")},
        { label: t("AccountingDocumentPrinting")},
        { label: t("DocumentRegistration") }
    ];

    const handleInitialData=(currecies,cartTypes,productTypes,factors,users)=>{
        const currencyData = convertSelectDataHandler(currecies);
        const cartTypesData = convertSelectDataHandler(cartTypes);
        const productTypesData = convertSelectDataHandler(productTypes);
        const factorsData = convertSelectDataHandler(factors);
        const usersData = convertSelectDataHandler(users);
        setInitialSource({
            currencies: currencyData,
            cartTypes: cartTypesData,
            productTypes: productTypesData,
            factors:factorsData,
            users: usersData
        })
    }

    // gets data from server in first initial request
    const fetchData=async()=>{
        try {
              setShowSpinner(true);
            const [responseCurrency, responseCartType, responseProduct,responseFactor,responseUser] =
                await Promise.all([
                    GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable'),
                    GET(`/Enumerations/EnCartItemType`, {}, 'IEnumerable'),
                    GET('/Products/Selection', {}, 'IEnumerable'),
                    GET(`/Enumerations/EnPriceType`, {}, 'IEnumerable'),
                    GET(`/Users/Selection`, {}, 'IEnumerable'),

                ]);
            if (
                responseCurrency.status === 200 &&
                responseCartType.status === 200 &&
                responseProduct.status === 200 &&
                responseFactor.status === 200 &&
                responseUser.status === 200
            ) {
                handleInitialData(
                    responseCurrency.data,
                    responseCartType.data,
                    responseProduct.data,
                    responseFactor.data,
                    responseUser.data
                );
                setShowSpinner(false);
            } else {
                handleApiError(responseCurrency, onApiError);
                handleApiError(responseCartType, onApiError);
                handleApiError(responseProduct, onApiError);
                handleApiError(responseFactor, onApiError);
                handleApiError(responseUser, onApiError);
            }
        }
        catch (e) {
            onApiError();
        }
    }

    const handleSubmitCartItems=async(e)=>{
        const queryParams = queryString.parse(window.location.search);
        e.preventDefault();
        try {
            setShowSpinner(true);
            const cartItems = handleConvertProductCartItems(queryParams.productCartItemId,ProductCartItems,cart);
            const response = await PUT(`/ProductCarts`,cartItems,false);
            if(response.status === 200){
                setShowSpinner(false);
                setIsOpen(false);
                Refresh();
                handleSuccessMessage(t("Successfullyedited"));
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch(e){
            onApiError();
        }
    }





    const {autoGroupColumnDef,columnDefs,defaultColDef,rowData,getRowHeight,handleGetCartItems} = useCartItemsDetailGrid(setShowSpinner,setIsOpen,onApiError,refresh,Refresh,setCart,setBuyer,initialSource,fetchData,setCost,setProductCartItems,setCart)

    return (
        <>
            <div class=" space-y-5 relative">

                {
                    showSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 ">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>handleGetCartItems()}
                                            icon={"pi-file-edit"}
                                            title={t("Edit")}
                                            tooltipTitle={t("Edit")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Add")}
                                            icon="pi-pen-to-square"
                                            title={t("product")}
                                            tooltipTitle={t("NewProduct")}
                                        />


                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/MetaData/Add")}
                                            icon="pi-star"
                                            title={t("feature")}
                                            tooltipTitle={t("CreatingAFeature")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Group")}
                                            icon="pi-objects-column"
                                            title={t("grouping")}
                                            tooltipTitle={t("ProductCategorization")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/List")}
                                            icon="pi-list"
                                            title={t("list")}
                                            tooltipTitle={t("ListOfProducts")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/InvoiceRequest")}
                                            icon="pi-file-edit"
                                            title={t("Bill")}
                                            tooltipTitle={t("Bills")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Cart")}
                                            icon="pi-shopping-bag"
                                            title={t("ShoppingCart")}
                                            tooltipTitle={t("ShoppingCart")}

                                        />

                                    </>
                                }

                    />



                </div>

                <div class={"box bg-white dark:bg-themeDarkSalt700 p-3"}>
                    <BuyerInformation buyer={buyer}/>
                    {/*<SellerInformation/>*/}
                </div>


                <div className={"space-y-2 H-body-ag-grid"} >

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />

                    <div className="flex items-center justify-between flex-wrap gap-2">
                        <div className="flex gap-2 flex-wrap">
                            <div className="p-2 rounded bg-themeSecondary text-white text-xs">{t("ShippingCost")}: {cost.transportPrice} {cost.currencyName}</div>
                            <div className="p-2 rounded bg-themeInformation dark:bg-themeDarkInformation text-white text-xs">{t("TotalPurchaseAmount")}: {cost.payablePrice} {cost.currencyName}</div>
                        </div>
                        <div className="p-2 rounded bg-themeSuccess text-white text-xs">{t("PaidAmount")}: {cost.totalPrice} {cost.currencyName}</div>
                    </div>

                </div>
                {/*<AccountingOperations operations={operations}/>*/}
            </div>
            <ToastContainer/>


            <FantasyModal openModal={IsOpen}  closeModal={handleCloseModal} title={t("ManualShoppingCart")} customeClassName={"modal-x"}>
                <form onSubmit={handleSubmitCartItems}>
                    <div class=" space-y-5">
                        <div className={"space-y-3"}>
                            <div class={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2"}>
                                <div class="space-y-1 ">
                                    <label className={"text-xs dark:text-xs"}>{t("userName")}</label>
                                    <Select
                                        options={initialSource.users}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'userId')}
                                        placeholder={t("selection")}
                                        value={cart.userId}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div class="space-y-1 ">
                                    <label className="text-xs dark:text-gray-300">{t("InvoiceType")}</label>
                                    <Select
                                        options={initialSource.factors}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'priceType')}
                                        placeholder={t("selection")}
                                        value={cart.priceType}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div class="space-y-1 ">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            tooltipTitle={t("Calendarsettings")}
                                            onClick={()=>handleSetting('GeneralSetting')}
                                        />
                                        {t("Date")}
                                    </label>
                                    <DateTimePicker handleDateTime={handleCreateDateTime} value={cart.creationDate} format={'YYYY/MM/DD'} />
                                </div>
                            </div>
                            <Accordion className={"bg-white dark:bg-themeDarkSalt700"}>
                                <AccordionTab header={<div>{t("Products")}</div>}>
                                    <div>
                                        <div className="p-1 space-y-3 ">
                                            {
                                                ProductCartItems.length > 0
                                                    ? ProductCartItems.map((item, index) => (
                                                        <Product index={index} initialSource={initialSource} handleAddRow={handleAddRow}
                                                                 handleRemoveRow={handleRemoveRow}
                                                                 handleChange={handleChange}
                                                                 item={item}
                                                        />
                                                    ))
                                                    : null
                                            }
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                        <button type={"submit"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("register")}</button>
                    </div>

                </form>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }

                </div>
            </FantasyModal>

        </>
    );
};
