import React, {useState} from "react";

export const useReminder=(setErrors,setDisable)=>{
    const [reminder,setReminders] = useState({id:null,title:null,description:null,message:null,reminderDateUtc:null,type:null,userId:null,enSendingStates:null});

    const handleDateReminder =(data)=>{
        setReminders((prevContent) => ({ ...prevContent,reminderDateUtc: data }));
    }

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setReminders({...reminder,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue=(data,name)=>{
        setReminders({...reminder,[name]: data});
    }
    const handleSelectNoteType=(data)=>{
        if(data.value === 1 || data.value === 2){
            setReminders({ ...reminder,type: data });
            setDisable(false);
        }
        else{
            setReminders({ ...reminder,reminderDateUtc: null,enSendingStates: null,type: data});
            setDisable(true);
        }
    }

    const handleSelectSendingState=(data)=>{
        setReminders({ ...reminder, enSendingStates: data});
    }

    return {
        reminder,
        setReminders,
        handleValue,
        handleSelectSendingState,
        handleSelectNoteType,
        handleDateReminder,
        handleSelectValue
    }
}