import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTabServices} from "../Hooks/useTabServices";
import queryString from "query-string";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {InputNumber} from "primereact/inputnumber";
export const TemplateTab = ({
                               showSpinner,
                               hideSpinner,
                               item,
                               handleChangeTemplate,
                               currentPath,
                               currencies,
                               paymentMethods
                           }) => {
    const { t } = useTranslation();
    const [extraTemplate, setExtraTemplate] = useState({
        templateMasterId: null,
        paymentMethod:0,
        finalPrice: { amount: 0, currency: 0, moneyType: 0 },
        discount: { amount: 0, discountType: 0, from: null, to: null },
        firstPrice: { amount: 0, currency: 0, moneyType: 0 },
        siteSpace: 0,
        proposedSpace: 0
    });
    const { handleShowPrice, handleGetCurrentType,handleGetPaymentMethod, } = useTabServices();
    const queryParams = queryString.parse(window.location.search);

    const fetchData = async () => {
        try {
            showSpinner();
            const url =
                currentPath === '/Admin/ServiceUpgrade'
                    ? `/Upgrades/ExtraTemplates/${item.templateMasterId}?WebSiteId=${queryParams.websiteId}`
                    : `/Upgrades/ExtraTemplates/${item.templateMasterId}`;

            const response = await GET(url, {}, 'single');
            if (response.status === 200) {
                setExtraTemplate(response.data);
                handleChangeTemplate(item.templateMasterId, 'price', response.data.firstPrice.amount);
                hideSpinner();
            } else {
                handleApiError(response, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const renderPriceInput = () => {
        if (currentPath === '/Admin/ServiceUpgrade') {
            return (
                <h2 className="dark:text-gray-300">
                    {t("Price")}:
                    <InputNumber
                        type={"text"}
                        name={'price'}
                        min={0}
                        value={item.price}
                        onChange={(e) => handleChangeTemplate(item.templateMasterId, 'price', e.value)}
                        suffix={handleGetCurrentType(currencies,extraTemplate.firstPrice.currency)}
                    />
                </h2>
            );
        } else {
            return (
                <h2 className="dark:text-gray-300">
                    {t("Price")}:
                    <span className="text-themeInformation dark:text-themeDarkInformation">
                        {handleShowPrice(extraTemplate.finalPrice.amount, currencies, extraTemplate.finalPrice.currency)}
                    </span>
                </h2>
            );
        }
    };

    return (
        <>
            <div className="space-y-2">
                <div className={"flex justify-between items-center"}>
                    <h2 className={"text-xs dark:text-gray-300 text-xs"}>{t("TariffPrice")} {handleGetPaymentMethod(paymentMethods,extraTemplate.paymentMethod)}:</h2>
                    <del  className={"text-themeDanger text-xs"}>{handleShowPrice(extraTemplate.firstPrice.amount,currencies,extraTemplate.firstPrice.currency)}</del>
                    <span className={"text-gray-300 text-xs"}>{handleShowPrice(extraTemplate.finalPrice.amount,currencies,extraTemplate.finalPrice.currency)}</span>
                </div>

                <div className="p-2">
                    {renderPriceInput()}
                </div>
            </div>
        </>
    );
};