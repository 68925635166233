import React from "react";
export const InputSwitch =(props)=>{

    const handleValueSelection = (e)=>{
        const isChecked = e.target.checked;
        props.OnValueSelection(isChecked,props.name)
    }

    return (
        <div className={`flex gap-1 items-center ${props.className ? props.className : 'flex-col'} `}>
            <lable className="text-xs dark:text-gray-300 text-nowrap">{props.lable}</lable>
            <input checked={props.value} className="input input--switch " onChange={(e)=>handleValueSelection(e)} type="checkbox"/>
        </div>
    )
}