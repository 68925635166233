import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DELETE, GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";

import {Image} from "primereact/image";
import {useNavigate} from "react-router-dom";
import {PriceFormatterComponent} from "../../Commans/Accounting/PriceFormatterComponent";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {GridButtonOprations} from "../../Commans/Grid/GridButtonOprations";

export const useCustomProductGrid=(setShowSpinner,fetchData,refresh,onApiError,initialSource,setProducts,setIsProductOpen,Refresh,handleOpenMenu,setOpenPrice)=>{

    const {t} = useTranslation();
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const redirectTo = useNavigate();

    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency){
            return '-'
        }
        return currency.label;
    }

    const handleGetProduct= async(productId)=>{
        try {
            setShowSpinner(true);
            setIsProductOpen(true);
            const response = await GET(`/Products/${productId}`,{},'single');
            if(response.status === 200){
                setProducts({
                    id:response.data.id,
                    title:response.data.title,
                    slug:response.data.slug
                })
                setShowSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError();
        }
    }

    const handleDeleteProduct = async (productId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                //remove list of contents by IDs arrays .
                try {
                    //const Ids = products.map(item=>item.id);
                    //const Ids = products.map(item => `Ids=${encodeURIComponent(item.id)}`).join('&');
                    setShowSpinner(true);
                    const response = await DELETE(`/Products`,{ids:[productId]});
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh();
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    onApiError();
                    console.log(e)
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const handleChangeStateShow = async(productId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            const response = await PUT(`/Products/EditActivation`,{id:productId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh();
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            setWait(false);
            onApiError();
        }
    }

    const handleDisplayOrderMasterActivation = async(productId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            const response = await PUT(`/Products/EditDisplayOrderMasterActivation`,{id:productId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh();
            }
            else
            {
                handleApiError(response,onApiError)
            }
        }catch (e) {
            setWait(false);
            onApiError();
        }
    }

    const handleGetPrices= async(productId)=>{
        try {
            setShowSpinner(true);
            setOpenPrice(true);
            const response = await GET(`/Products/${productId}`,{},'single');
            if(response.status === 200){
                setProducts({
                    id:response.data.id,
                    price:response.data.userPrice.amount,
                    wholeSalePrice:response.data.wholeSalePrice.amount
                })
                setShowSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("ProductImage"),field: 'thumbnailImage',minWidth: 140,
            cellRenderer:params => {
                return params.data.image !== null?
                    <Image src={params.data.image} zoomSrc={params.data.image}  preview />:

                    <HPrimeIcon icon={'pi-times'} iconClass={"text-themeDanger"} />
            }
        },
        { headerName: t("ProductName"),minWidth: 200,
            cellRenderer:parameters=>
                <div onClick={()=>handleGetProduct(parameters.data.id)} className="line-clamp-1 cursor-pointer hover:underline hover:text-themeInformation dark:hover:text-themeDarkInformation">
                    {parameters.data.title}
                </div>
        },

        { headerName: t("ProductCode"),minWidth: 200,
            cellRenderer:parameters=>
                <>
                    {parameters.data.productCode}
                </>
        },

        { headerName: t("UnitPrice"),minWidth: 160,

            cellRenderer:parameters=>
                <div className={'cursor-pointer hover:underline hover:text-themeInformation dark:hover:text-themeDarkInformation'} onClick={()=>handleGetPrices(parameters.data.id)}>{<PriceFormatterComponent price={parameters.data.userPrice.amount}/>} {handleGetCurrencyType(parameters.data.userPrice.currency,initialSource.currencies)}</div>
        },
        { headerName: t("WholesalePrice"),minWidth: 160,

            cellRenderer:parameters=>
                <div className={'cursor-pointer hover:underline hover:text-themeInformation dark:hover:text-themeDarkInformation'} onClick={()=>handleGetPrices(parameters.data.id)}>{<PriceFormatterComponent price={parameters.data.wholeSalePrice.amount}/>} {handleGetCurrencyType(parameters.data.wholeSalePrice.currency,initialSource.currencies)}</div>
        },

        { headerName: t("DisplayOrder"),field: 'displayOrderSlave', minWidth: 130,editable:true},


        { headerName: t("DisplayPriority"),field: 'displayOrderMaster', minWidth: 115, cellRenderer:params=>
                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading />
                    ) : params.data.displayOrderMaster ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            onClick={async () => await handleDisplayOrderMasterActivation(params.data.id,params.colDef.headerName)}
                            icon={'pi-check-square'}
                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleDisplayOrderMasterActivation(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'pi-times-circle'}
                        />
                    )}
                </div>
        },

        { headerName: t("DisplayStatus"),field: 'stateShow', minWidth: 115, cellRenderer:params=>
                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            onClick={async () => await handleChangeStateShow(params.data.id,params.colDef.headerName)}
                            icon={'pi-check-square'}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeStateShow(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'pi-times-circle'}

                        />
                    )}
                </div>
        },



        { headerName: t("ProductBarcode"),minWidth: 160,

            cellRenderer:parameters=>

                <>{parameters.data.barcode === null?'-':parameters.data.barcode}</>
        },
        { headerName: t("operations"), maxWidth: 140,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>redirectTo(`/Shop/Product/Add?productId=${params.data.id}`)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteProduct(params.data.id)}

                    />
                    <GridButtonOprations
                        onClick={()=>handleOpenMenu(params.data.id)}
                    />
                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',ProductGroupIds:[],SixMonthsToBeExpired:null,OneYearToBeExpired:null,IsAvailableInInventory:null,IsNotAvailableInInventory:null,PurchaseWarning:null,IsTopSelling:null,IsDraft:null,productGroupId:[],filterId:null});

    const SwitchFilters = [
        {name:'IsDraft',lable:t("Draft"),value:filter.IsDraft},
        {name:'IsTopSelling',lable:t("BestsellingProducts"),value:filter.IsTopSelling},
        {name:'PurchaseWarning',lable:t("PurchaseAlert"),value:filter.PurchaseWarning},
        {name:'IsNotAvailableInInventory',lable:t("Unavailable"),value:filter.IsNotAvailableInInventory},
        {name:'IsAvailableInInventory',lable:t("Available"),value:filter.IsAvailableInInventory},
        {name:'OneYearToBeExpired',lable:t("OneYeartoExpiration"),value:filter.OneYearToBeExpired},
        {name:'SixMonthsToBeExpired',lable:t("SixMonthsToExpiration"),value:filter.SixMonthsToBeExpired},
    ]
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',IsAvailableInInventory: null,IsNotAvailableInInventory:null,IsDraft: null,IsTopSelling: null,OneYearToBeExpired: null,ProductGroupIds: [],PurchaseWarning: null,SixMonthsToBeExpired: null,productGroupId:[],filterId:null}));
        setSearch('');
    }
    const handleFilterByGroupName = (data)=>{
        if(!data){
            return;
        }
        const productGroupIds = data.map(groupId => groupId.value).join('&');
        setFilter(({...filter,ProductGroupIds:productGroupIds,productGroupId: data}));
    }




    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data}));
    }


    useEffect(() => {
        OnGridReady();
    }, [refresh,filter.filterId, pagination.currentPage, filter.search, pagination.pageSize,filter.SixMonthsToBeExpired,filter.IsDraft,filter.ProductGroupIds,filter.IsTopSelling,filter.PurchaseWarning,filter.OneYearToBeExpired,filter.IsAvailableInInventory,filter.IsNotAvailableInInventory]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/Products`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,SixMonthsToBeExpired:filter.SixMonthsToBeExpired,IsDraft:filter.IsDraft,ProductGroupIds:filter.ProductGroupIds.length>0?filter.ProductGroupIds.map(groupId => groupId.value).join('&'):filter.ProductGroupIds,IsTopSelling:filter.IsTopSelling,PurchaseWarning:filter.PurchaseWarning,OneYearToBeExpired:filter.OneYearToBeExpired,IsAvailableInInventory:filter.IsAvailableInInventory,IsNotAvailableInInventory:filter.IsNotAvailableInInventory,FilterId:filter.filterId !== null?filter.filterId.value:filter.filterId}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };





    return {
        handleGetProduct,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteProduct,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter,
        handleFilterByGroupName
    }

}