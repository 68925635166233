import React, {useEffect, useRef, useState} from "react";

import {InputText} from "primereact/inputtext";
import {ToggleSwitch} from "../../../Commans/UIParts/ToggleSwitch";
import Select from "react-select";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {FastRegisterSchema} from "./FastRegisterSchema";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useLocation, useNavigate} from "react-router-dom";
import { Accordion, AccordionTab } from 'primereact/accordion';
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import queryString from "query-string";
import {useTranslation} from "react-i18next";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {convertFastRegisterHandler, handleConvertUserRoles, validatePresenterCode} from "./registerService";
import {InputNumber} from "primereact/inputnumber";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
export const HandleFastRegister = (props)=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const location = useLocation();
    const [showPassword,setShowPassword] = useState(false);
    const[spinner,setSpinner] = useState(false);
    const [isAuto,setAuto] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [errors, setErrors] = useState({});
    const [result,setResult] = useState(0);

    const [selectOptions, setSelectOptions] = useState({
        genders: null,
        roles: null,
        methods: null,
        leadsType:[]

    });

    const [users,setUser] = useState({id:null,userCode:null,registerDate:null,renewalDate:null,sex:null,fullName:null,mobilePhoneNumber:null,
        homePhoneNumber:null,email:null,introductionMethod:null,username:null,password:null,
        roles:[],presenterCode:null,nationalCode:null,concurrencyStamp:null,
        leadsType:null
    })

    const handleToggleSwitchValue=data=>setAuto(data);


    const handleValue=(e)=>{
        const {name,value} = e.target;
        if(value === ""){
            setUser({...users,[name]:null});
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
            return;
        }
        setUser({...users,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const onApiError = () => {
        setSpinner(false);
    };
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }


    const selectValueHandler = (data, name) => {
        if (name === 'roles') {
            const isContains = data.some((item) => item.roleType === 'CrmLeads');
            if (isContains) {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: data,
                    leadsType: selectOptions.leadsType[0]
                }));
            } else {
                setUser((prevState) => ({
                    ...prevState,
                    [name]: data,
                    leadsType: null
                }));
            }
        }
        else {
            setUser((prevState) => ({
                ...prevState,
                [name]: data
            }));
        }
    };
    const registerDateTimeHandler = (dateTime)=>{
        setUser({...users,registerDate: dateTime})
    }
    const renewDateTimeHandler =(dateTime)=>{
        setUser({...users,renewalDate: dateTime})
    }


    const handleDisplayUserLeads=(roleType)=>{
        const isContains = users.roles.some((item)=>item.roleType === roleType);
        if(isContains){
            return (<>
                <label className="dark:text-gray-300 text-xs">{t("نوع سرنخ")}</label>
                <Select
                    options={selectOptions.leadsType}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    onChange={(data)=>selectValueHandler(data,'leadsType')}
                    name={'leadsType'}
                    placeholder={t("selection")}
                    value={users.leadsType}
                    className={"select-group"}
                    menuPosition={"fixed"}
                    classNamePrefix={"h_multi_select"}
                />

            </>)
        }else{

            return (<>

            </>)
        }
    }

    const handleFetchData = async (IsOpen) => {
        try {
            setSpinner(true);
            const responseGenders = await GET('/Enumerations/EnGenderType',{},'IEnumerable');
            const responseRoleTypes = await GET(`/Roles/Selection`,{},'IEnumerable');
            const responseMethods = await GET('/Enumerations/EnIntroductionMethod',{},'IEnumerable');
            const responseLeads = await GET('/Enumerations/EnLeadsType',{},'IEnumerable');
            if (responseGenders.status === 200 && responseRoleTypes.status === 200 && responseMethods.status === 200 && responseLeads.status === 200)
            {
                const genders = convertSelectDataHandler(responseGenders.data);
                const roles = handleConvertUserRoles(responseRoleTypes.data);
                const methods = convertSelectDataHandler(responseMethods.data);
                const leadsType = convertSelectDataHandler(responseLeads.data);
                setSelectOptions({genders: genders, roles: roles, methods: methods,leadsType: leadsType});
                setAuto(true);
                setUser({registerDate: new Date(),renewalDate: null,username: null,sex:genders[2],roles: [],introductionMethod: methods[1]});
                setSpinner(false);

                if(IsOpen){
                    setUser({registerDate: new Date(),renewalDate: null,username: null,sex:genders[2],roles: [],introductionMethod: methods[1]});
                    setPresentorValid(true);
                        setErrors({});
                }

                const queryParams = queryString.parse(window.location.search);
                if (queryParams.id) {
                        setSpinner(true);
                        setIsEdit(true)
                        const response = await GET(`/Users/Information/${queryParams.id}`,{},'single');
                        if (response.status === 200) {
                            setUser({
                                id: response.data.id,
                                email: response.data.email,
                                renewalDate: response.data.renewalDate,
                                registerDate: response.data.registerDate,
                                username: response.data.userName,
                                password: response.data.password,
                                fullName: response.data.fullName,
                                homePhoneNumber: response.data.homePhoneNumber,
                                nationalCode: response.data.nationalCode,
                                mobilePhoneNumber: response.data.mobilePhoneNumber,
                                userCode: response.data.userCode,
                                presenterCode: response.data.presenterCode,
                                roles: response.data.roles.map((value) => roles.find((item) => item.value === value)),
                                introductionMethod: methods.find((item) => item.value === response.data.introductionMethod),
                                sex: genders.find((item) => item.value === response.data.sex),
                                concurrencyStamp:response.data.concurrencyStamp,
                                leadsType:leadsType.find((item)=>item.value === response.data.leadsType)??null
                            });
                            setSpinner(false)
                        } else {
                            handleApiError(response,onApiError);
                        }
                }
            } else {
                handleApiError(responseGenders,onApiError); // Handle errors for any of the fetch requests
                handleApiError(responseRoleTypes,onApiError); // Handle errors for any of the fetch requests
                handleApiError(responseMethods,onApiError); // Handle errors for any of the fetch requests
            }
        } catch (error) {
            onApiError()
        }
    };




    const [isPresentorCodeValid,setPresentorValid] = useState(true);
    const handleUserCodeValidation = async () => {
        try {
            if(users.presenterCode === null || users.presenterCode === '' || users.presenterCode === undefined){
                setPresentorValid(true);
            }
            else{
                var isValid = validatePresenterCode(users.presenterCode);
                if (!isValid) {
                    handleFailureMessage(t("Referralcodemustnumericalvalue"));
                    setPresentorValid(false);
                    return;
                }
                const response = await GET(`/Users/UserCode/Validation`,{UserCode:users.presenterCode,UserName:users.username},'single');
                if (response.status === 200) {
                    setPresentorValid(response.data.isValid);
                } else {
                    handleApiError(response,onApiError);
                }
            }
        }
        catch (e) {

        }
    }


    useEffect(() => {
        handleFetchData(props.IsOpen)
    }, [result, props.IsOpen]);






    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const formRef = useRef(null);
    const handleSubmitFastRegisterDataHandler =async (e)=>{

        e.preventDefault();

        if(!isPresentorCodeValid){
            handleFailureMessage(t("Referralcodenotvalid"));
            return;
        }
        const isValid = validate(FastRegisterSchema,users,onSetErrors);
        if(!isValid){
            return;
        }

        if(users.roles.length === 0){handleFailureMessage(
            t("Specifyusertype"));
            return;
        }

           const convertedUsers = convertFastRegisterHandler(users);
           setSpinner(true);
           const {id,userCode,registerDate,renewalDate,sex,fullName,mobilePhoneNumber,concurrencyStamp,homePhoneNumber,email,nationalCode,introductionMethod,postalCode, username,password,roles,presenterCode,leadsType} = convertedUsers;
           const response = await POST('/Users/Information/Add',{id,userCode,registerDate,renewalDate,sex,fullName,mobilePhoneNumber,concurrencyStamp,homePhoneNumber,email,nationalCode,introductionMethod,postalCode, username,password,roles,presenterCode,leadsType},false);

           const queryParams = queryString.parse(window.location.search);

        if(response.status === 200){
               setSpinner(false)
               if(document.activeElement === formRef.current.elements.fast){
                   if(location.pathname === '/CRM/User/Add'){
                       redirectTo('/CRM/User/List');
                   }
               }else if (document.activeElement === formRef.current.elements.tab) {
                   redirectTo(`${location.pathname}?id=${response.data.id}`)
                   props.handleTabClick("personal");
                   handleSuccessMessage(t("Informationhassuccessfully"));
               }
               else if(document.activeElement === formRef.current.elements.register){
                  if(queryParams.id){
                      setUser({sex:selectOptions.genders[0],mobilePhoneNumber: '',nationalCode: '',presenterCode: '',password: '',username: '',homePhoneNumber: '',email: '',userCode: '',introductionMethod: selectOptions.methods[0],fullName: '',
                          renewalDate: null,registerDate: null,roles:[]
                      })
                      redirectTo('/CRM/User/Add');
                      handleSuccessMessage(t("Informationhassuccessfully"));
                      setResult({...response.status});
                  }
                  else{
                      if(!props.IsDefault){
                          props.handleSuccess(true);
                          setUser({sex:selectOptions.genders[0],mobilePhoneNumber: '',nationalCode: '',presenterCode: '',password: '',username: '',homePhoneNumber: '',email: '',userCode: '',introductionMethod: selectOptions.methods[0],fullName: '',
                              renewalDate: null,registerDate: null,roles:[]
                          })
                          handleSuccessMessage(t("Informationhassuccessfully"));
                          setResult({...response.status});
                      }
                      else{
                          setUser({sex:selectOptions.genders[0],mobilePhoneNumber: '',nationalCode: '',presenterCode: '',password: '',username: '',homePhoneNumber: '',email: '',userCode: '',introductionMethod: selectOptions.methods[0],fullName: '',
                              renewalDate: null,registerDate: null,roles:[]
                          })
                          handleSuccessMessage(t("Informationhassuccessfully"));
                          setResult({...response.status});
                      }


                  }
               }
           }
           else{
               handleApiError(response,onApiError);
           }
    }






    return (
        <form method={'post'} className={"relative"}  onSubmit={handleSubmitFastRegisterDataHandler}  ref={formRef} onKeyDown={handleKeyDown}>
            {

                spinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div className="space-y-5">
                <div className="space-y-3">
                    <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                        <h2 className={"dark:text-gray-300"}>
                            {t("PrivetData")}
                        </h2>
                    </div>
                    <div className={"px-2"}>
                        <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-4">

                            <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3'}>
                                <div class="space-y-1 lg:col-span-3">
                                    <label className="dark:text-gray-300 text-xs">{t("userType")}</label>
                                    <Select
                                        options={selectOptions.roles}
                                        isClearable={true}
                                        isSearchable={true}
                                        isMulti={true}
                                        onChange={(data)=>selectValueHandler(data,'roles')}
                                        name={'roleType'}
                                        placeholder={t("selection")}
                                        value={users.roles}
                                        className={"select-group"}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div class="space-y-1 lg:col-span-1">

                                    {
                                        handleDisplayUserLeads('CrmLeads')
                                    }

                            </div>
                            </div>



                            <div className="flex flex-col md:flex-row gap-3">
                                <div class="space-y-1 w-full md:w-36 flex-shrink-0">
                                        <label className="dark:text-gray-300 text-xs">{t("Mr") +"/"+ t("Miss")}</label>
                                        <Select
                                            options={selectOptions.genders}
                                            isSearchable={true}
                                            onChange={(data)=>selectValueHandler(data,'sex')}
                                            name={'sex'}
                                            placeholder={t("selection")}
                                            value={users.sex}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-3 flex-1">
                                    <div class=" space-y-2">
                                        <div className="flex justify-between">
                                            <label className="dark:text-gray-300 text-xs">
                                                {t("FirstNameAndLastName")}
                                            <RequiredIcon/>
                                            </label>
                                            <DisplayError message={errors.fullName}/>
                                        </div>
                                        <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-user"} />
                                        </span>
                                            <InputText  className="input w-full" name={'fullName'} value={users.fullName} onInput={(e)=>handleValue(e)} />
                                        </div>
                                    </div>
                                    <div className="space-y-2">
                                        <div className="flex justify-between">
                                            <label className="dark:text-gray-300 text-xs">
                                                {t("MobilePhone")}
                                                <RequiredIcon/>
                                            </label>
                                            <DisplayError message={errors.mobilePhoneNumber}/>
                                        </div>
                                        <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-mobile"} />
                                        </span>
                                            <InputText className="input w-full"  value={users.mobilePhoneNumber} name={'mobilePhoneNumber'} onInput={(e)=>handleValue(e)} />
                                        </div>
                                    </div>
                                    <div className="space-y-2">
                                        <div className="flex justify-between">
                                            <label className="dark:text-gray-300 text-xs">
                                                {t("LandlinePhone")}
                                            </label>
                                            <DisplayError message={errors.homePhoneNumber}/>
                                        </div>
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                <HPrimeIcon icon={"pi-phone"} />
                                            </span>
                                            <InputText className="input w-full" name={'homePhoneNumber'} onInput={(e)=>handleValue(e)}  value={users.homePhoneNumber} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-3">
                                <div class="space-y-1 w-full md:w-36 flex-shrink-0">
                                    <label className="dark:text-gray-300 text-xs">{t("methodOfIntroduction")}</label>
                                    <Select
                                        options={selectOptions.methods}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data)=>selectValueHandler(data,'introductionMethod')}
                                        name={'introductionMethod'}
                                        placeholder={t("selection")}
                                        value={users.introductionMethod}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className={"grid grid-cols-1 md:grid-cols-3 gap-3 flex-1"}>
                                    <div class="space-y-1">
                                        <div class="relative space-y-2">
                                            <div className="flex justify-between">
                                                <label className="dark:text-gray-300 text-xs">
                                                    {t("ReferralCode")}
                                                <RequiredIcon/>
                                                </label>
                                                <DisplayError message={errors.presenterCode}/>
                                            </div>
                                            <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-code"} />
                                            </span>
                                                <InputText  onBlur={handleUserCodeValidation}  className={` input_number_plus w-full ${ isPresentorCodeValid === false ? "border-2 border-red-500" : '' }`}    name={'presenterCode'} onInput={(e)=>handleValue(e)} value={users.presenterCode} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="space-y-2">
                                        <div className="flex justify-between">
                                            <label className="dark:text-gray-300 text-xs">{t("Email")}</label>
                                            <DisplayError message={errors.email}/>
                                        </div>
                                        <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-envelope"}/>
                                        </span>
                                            <InputText className="input w-full"  autoComplete={'off'} autoSave={'off'}   value={users.email} name={'email'} onInput={(e)=>handleValue(e)}  />
                                        </div>
                                    </div>
                                    <div class="space-y-2">
                                        <div class="relative space-y-2">
                                            <div className="flex justify-between">
                                                <label className="dark:text-gray-300 text-xs">{t("NationalIdNumber")}</label>
                                                {/*<DisplayError message={errors.nationalCode}/>*/}
                                            </div>
                                            <div className="p-inputgroup">
                                                 <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-id-card"}/>
                                                 </span>
                                                <InputText className="input w-full" name={'nationalCode'} onInput={(e)=>handleValue(e)}  value={users.nationalCode} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                {
                    props.IsDefault?
                        <div className="bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine ">
                            <Accordion>
                                <AccordionTab header={t("PrivetData")}>
                                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 p-2  post-input dark:bg-themeDarkPostInput post-input-design">
                                        <div class=" space-y-1">

                                            <div className="flex items-center gap-2">
                                                <label className="dark:text-gray-300 text-xs">{t("UserCode")}</label>
                                                {
                                                    isEdit?null
                                                        :
                                                        <>

                                                            <ToggleSwitch checked={t("automatic")} unchecked= {t("manual")} handleToggleSwitchValue={(data)=>handleToggleSwitchValue(null,data)} value={isAuto} customClass={"w-32"} />

                                                        </>
                                                }
                                            </div>

                                            <div className="p-inputgroup number_input">
                                                 <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-code"}/>
                                                 </span>
                                                <InputNumber className={'w-full'}  min={0} type={'text'} useGrouping={false} value={users.userCode} showButtons={true} onInput={(e)=>handleValue(e.value)} name={'userCode'} disabled={isAuto}  />
                                            </div>
                                            {
                                                !isAuto?  <DisplayError message={errors.userCode}/>:null
                                            }
                                        </div>
                                        <div class="space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("userName")}</label>
                                            <div className="p-inputgroup">
                                                 <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-user"} />
                                                 </span>
                                                <InputText className="input w-full" onInput={(e)=>handleValue(e)} name={'username'} value={users.username} />
                                            </div>
                                        </div>
                                        <div class=" space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("Password")}</label>
                                            <div className="p-inputgroup relative">
                                                 <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-key"}/>
                                                 </span>
                                                <i className={`absolute top-1/2 -translate-y-1/2 end-2 dark:text-gray-300 ${ showPassword === true? "pi pi-eye cursor-pointer" : "pi pi-eye-slash cursor-pointer" }`}
                                                   onClick={() => setShowPassword(!showPassword)} >
                                                </i>
                                                <InputText
                                                    type={showPassword ? "text" : "password"}
                                                    className=" input  w-full"
                                                    value={users.password}
                                                    onInput={(e) => handleValue(e)}
                                                    name="password"
                                                    autoComplete={'off'}
                                                    autoSave={'off'}
                                                />
                                            </div>
                                        </div>
                                        <div class=" space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("RegistrationDate")}</label>
                                            <div className="p-inputgroup time_input">
                                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-calendar"} />
                                                </span>
                                                <DateTimePicker isContainsTime={false} handleDateTime={registerDateTimeHandler} format={'YYYY/MM/DD'} value={users.registerDate}/>
                                            </div>
                                        </div>
                                        <div class=" space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("RenewalDate")}</label>
                                            <div className="p-inputgroup time_input">
                                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                        <HPrimeIcon icon={"pi-calendar-clock"} />
                                    </span>
                                                <DateTimePicker isContainsTime={false} handleDateTime={renewDateTimeHandler} format={'YYYY/MM/DD'} value={users.renewalDate}/>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>
                        :null
                }
                <div class=" flex flex-wrap items-center justify-center sm:justify-end  gap-3">
                    <button type={'submit'} name={'register'} className="button w-28  bg-themeSuccess text-white">{t("fastRegistration")}</button>
                    {
                        props.IsDefault?
                            <>
                                <button type={'submit'} name={'fast'} className="button w-28 bg-themeSuccess text-white">{t("finalRegistration")}</button>
                                <button type={'submit'} name={'tab'} className="button w-28 text-white bg-themeInformation dark:bg-themeDarkInformation">{t("Continue")}</button>
                            </>
                            :null

                    }

                </div>
            </div>
        </form>

    )
}