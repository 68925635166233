
export const handleAddUpgradeService=(upgrade,display)=>{
    const data = {
        currencyType:upgrade.currencyType === null || upgrade.undefined?null:upgrade.currencyType.value,
        extraHost:display.IsOpenExtraHost ?{
                extraHost:upgrade.extraHost.extraHost=== 0 || upgrade.extraHost.extraHost === undefined?null: upgrade.extraHost.extraHost,
            }:null,

        extraMailHost:display.IsOpenExtraMailHost?{
                extraMailHost:upgrade.extraMailHost.extraMailHost=== 0 || upgrade.extraMailHost.extraMailHost === undefined?null: upgrade.extraMailHost.extraMailHost,
            }:null,

        extraPackages: upgrade.extraPackages.packageIds.length === 0
            ? null
            : {
                packageIds: upgrade.extraPackages.packageIds.map(item => ({
                    packageId: item.packageId,
                }))
            },
        extraTemplates: upgrade.extraTemplates.templateMasterIds.length === 0
            ? null
            : {
                templateMasterIds: upgrade.extraTemplates.templateMasterIds.map(item => ({
                    templateMasterId: item.templateMasterId,
                }))
            }
    }

    return data;
}

export const handleAdminAddUpgradeService=(upgrade,display)=>{
    const data = {
        currencyType:upgrade.currencyType === null || upgrade.undefined?null:upgrade.currencyType.value,
        creationDate:upgrade.creationDate === null || upgrade.creationDate === undefined?null:upgrade.creationDate,

        extraHost: display.IsOpenExtraHost ? {
            extraHost: upgrade.extraHost && upgrade.extraHost.extraHost !== 0 && upgrade.extraHost.extraHost !== undefined ? upgrade.extraHost.extraHost : null,
            price: upgrade.extraHost && upgrade.extraHost.price !== 0 && upgrade.extraHost.price !== undefined ? upgrade.extraHost.price : 0,
        } : null,

        extraMailHost:display.IsOpenExtraMailHost?
            {
                extraMailHost: upgrade.extraMailHost && upgrade.extraMailHost.extraMailHost !== 0 && upgrade.extraMailHost.extraMailHost !== undefined ? upgrade.extraMailHost.extraMailHost : null,
                price: upgrade.extraMailHost && upgrade.extraMailHost.price !== 0 && upgrade.extraMailHost.price !== undefined ? upgrade.extraMailHost.price : 0,
            }:null,

        extraPackages: upgrade.extraPackages.packageIds.length === 0
            ? null
            : {
                packageIds: upgrade.extraPackages.packageIds.map(item => ({
                    packageId: item.packageId,
                    price: item.price
                }))
            },

        extraTemplates: upgrade.extraTemplates.templateMasterIds.length === 0
            ? null
            : {
                templateMasterIds: upgrade.extraTemplates.templateMasterIds.map(item => ({
                    templateMasterId: item.templateMasterId,
                    price: item.price
                }))
            }
    }
    return data;
}