export const handleCreateShortcuts = (hotkeys) => {
    const shortCuts = hotkeys.map((item) => ({
        startKey: item.keyCombo[0] === 'Ctrl' ?0 : item.keyCombo[0] === 'Alt' ? 1 : '-',
        isShift: item.isShift,
        key: item.key === "" ? "-" : item.key,
        process: item.process === null || item.process === undefined ? null : item.process.value,
        to: item.to === '' ? '-' : item.to
    }));
    return { shortCuts };
};
export const handleGetShortCuts = (hotkeys,processData) => {
    const data = hotkeys.map((item) => {
        const keyCombo = [];
        // Determine the first key based on startKey
        if (item.startKey === 1) {
            keyCombo.push('Alt');
        } else if (item.startKey === 0) {
            keyCombo.push('Ctrl');
        }
        // If isShift is true, add 'Shift' and the key to the end of keyCombo
        if (item.isShift) {
            keyCombo.push('Shift', item.key);
        } else {
            keyCombo.push(item.key);
        }
        return {
            keyCombo,
            isShift: item.isShift,
            key: item.key,
            process: processData.find((c) => c.value === item.process),
            to: item.to,
            value: keyCombo.join(' + ') // Construct the value string
        };
    });
    return data;
};

export function validateValueShouldNotBeEmpty(arr) {
    return arr.every(obj => obj.value !== null && obj.value !== '');
}
export function validateAreValuesUnique(arr) {
    const valueSet = new Set();
    // Iterate over each object in the array
    for (const obj of arr) {
        const value = obj.value;

        // Check if value is unique
        if (valueSet.has(value)) {
            return false;
        }

        // Add the value to the set
        valueSet.add(value);
    }

    return true;
}
export function validateSlugsShouldNotBeEmpty(arr) {
    return arr.every(obj => obj.to !== null && obj.to !== '');
}