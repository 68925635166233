import React from "react";
export const ToggleSwitch = (props)=>{

    return(<>
        <div class={`can-toggle demo-rebrand-1 ${props.customClass} text-xs`}>
            <input id={props.tag} type="checkbox" checked={props.value} onChange={(e)=>props.handleToggleSwitchValue(props.name,e.target.checked)}/>
            <label htmlFor={props.tag}>
                <div class="can-toggle__switch" data-checked={props.checked} data-unchecked={props.unchecked}></div>
            </label>
        </div>
    </>)
}