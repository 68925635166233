import React from "react";


export const handleCreateTemplatePurchase = (upgrade,templateId) => {
    const data = {
        currencyType: upgrade.currencyType.value,
        extraTemplates: {
            templateMasterIds: [
                { templateMasterId: templateId }
            ]
        }
    };
    return data;
};