import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useVerification=(setErrors)=>{

    const {t} = useTranslation();
    const [verification, setVerification] = useState({otpCode:null});
    const handleValue = (e) => {
        const {name, value} = e.target;
        setVerification({...verification, [name]: value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };
    const handleNumberValue = (value,name)=>{
        setVerification({...verification,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }


    return {
        handleValue,
        verification,
        setVerification,
        handleNumberValue
    }
}