import React, {useEffect, useMemo, useState} from "react";
import {DELETE, GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {Image} from "primereact/image";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {Tooltip} from "react-tooltip";

export const useCustomePackageGrid=(setDisable,setSpinner,refresh,onApiError,initialSource,setPackage,setModal,setIsEdit,Refresh)=>{
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [colId,setColId]=useState(null);
    const { t } = useTranslation();

    const handleEditPackage = async(recordId) => {
        try {
            setIsEdit(true);
            setSpinner(true);
            const response = await GET(`/Packages/${recordId}`,{},'single');
            if(response.status === 200){
                if(response.data.price === 0){
                    setDisable(true);
                }else{
                    setDisable(false);
                }
                setPackage({
                    Id:response.data.id,
                    Name: response.data.name,
                    Description: response.data.description,
                    PackageGroupId: response.data.packageGroupId === null ? [] : initialSource.packageGroups.find((item) => item.value === response.data.packageGroupId),
                    ThumbnailImage: response.data.thumbnailImage,
                    BannerImage: response.data.bannerImage,
                    StateShow:response.data.stateShow
                });
                setModal({AddPackage: true})
                setSpinner(false)

            }else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
            onApiError();
        }
    };


    const handleRemovePackage = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`/Packages/${id}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setSpinner(false)
                        Refresh(response.status)
                        handleSuccessMessage(t("Yourpackagehasdeleted"))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    onApiError()
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const handleChangeActivation = async(packageId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            setColId(packageId);
            const response = await PUT(`/Packages/EditPackageActivation`,{packageId:packageId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh(response.status)
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            setWait(false)
        }
    }

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Banner"),field: 'bannerImage', maxWidth: 140,
            cellRenderer:params => {
                return params.data.bannerImage !== null?
                    <Image src={params.data.bannerImage} zoomSrc={params.data.bannerImage} alt="Image" preview/>:
                    <HPrimeIcon
                        iconClass={"text-themeDanger"}
                    icon={"pi-times"}
                    />

            }
        },

        { headerName: t("Image"),field: 'thumbnailImage', maxWidth: 140,
            cellRenderer:params =>
            <>
                <div>
                    { params.data.thumbnailImage !== null?
                        <Image src={params.data.thumbnailImage} zoomSrc={params.data.thumbnailImage}  preview />:
                        <HPrimeIcon
                            iconClass={"text-themeDanger"}
                            icon={"pi-times"}
                        />
                    }
                </div>
            </>

        },
        { headerName: t("PackageName"),field: 'name', minWidth: 120},


        { headerName: t("Packagerole"),field: 'roleTarget', minWidth: 150},



        { headerName: t("Status"),field: 'stateShow', maxWidth: 130, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && colId === params.data.id ? (
                        <TinyLoading />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                           onClick={async () => await handleChangeActivation(params.data.id,params.colDef.headerName)}

                        />
                    ) : (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                            onClick={async () => await handleChangeActivation(params.data.id,params.colDef.headerName)}

                        />
                    )}
                </div>
        },


        // { headerName: 'توضیحات',field: 'description',width: 120, minWidth: 140,cellRenderer:params => {
        //     return <h2 className={'line-clamp-1'}>{params.data.description}</h2>
        //   }},

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >

                    <Link to={`/Admin/Package/List/Role/${params.data.id}`} className={"text-themeInformation dark:text-themeDarkInformation relative tooltip_class_Role"} >
                        <HPrimeIcon icon={"pi-star"} />
                        <Tooltip anchorSelect={".tooltip_class_Role"} content={t("Access")} positionStrategy={"fixed"} />
                    </Link>

                    <GridButtonEdit
                        onClick={() => handleEditPackage(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleRemovePackage(params.data.id)}
                    />
                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',IsActive:null,IsDeActive:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search: '',IsDeActive: null,IsActive: null}));
        setSearch('');
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh, currentPage, filter.search, data.pageSize, filter.IsActive, filter.IsDeActive]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/Packages`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search, IsActive: filter.IsActive, IsDeActive: filter.IsDeActive}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };




    const switchData = [
        {name:'IsActive',lable:t("isActive"),value:filter.IsActive},
        {name:'IsDeActive',lable:t("isNotActive"),value:filter.IsDeActive}
    ]

return {

    handleEditPackage,
    handleCurrentPage,
    handleNextPage,
    handlePageSizeCount,
    handlePreviousPage,
    handleRemovePackage,
    handleSearch,
    switchData,
    resetFilters,
    filterValueHandler,
    columnDefs,
    autoGroupColumnDef,
    getRowHeight,
    defaultColDef,
    search,
    setSearch,
    rowData,
    data,
    filter,
    currentPage,
    setFilter,
}

}