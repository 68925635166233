import React from "react";

export function GetPaymentGatewaysData(paymentGateways) {
    const paymentGateway = paymentGateways.map(item => ({
        label: item.value,
        value: item.key,
        pspType:item.pspType,
        fields: item.fields.map((field, index) => ({...field, fakeId: new Date().getTime() + index}))}));
    return paymentGateway;
}
export function GetInitialProperties(fields){
    const properties = fields.map((fieldItem) => ({
        id:null,
        fakeId: fieldItem.fakeId,
        [fieldItem.name]: null,
        type:fieldItem.type,
        name:fieldItem.name,
        isRequired:fieldItem.isRequired
    }));
    return properties;
}

export function GetDynamicPropertiesFromServer(data){
    const initialProperties = Object.keys(data|| {}).map((key) => ({
        name: key,
        value: data[key]
    }));
    return initialProperties;
}

export function handleGetDynamicProperties(fields){
    const dynamicProperties = {};
    fields.forEach(item => {
        dynamicProperties[item.name] = item[item.name];
    });
    return dynamicProperties;
}

export function CreateDynamicProperties(properties){
    const dynamicProperties = {};
    properties.forEach(item => {
        dynamicProperties[item.name] = item.value;
    });
    return dynamicProperties;
}



export function validateDynamicRequiredProperties(fields) {
    for (const item of fields) {
        if (item.isRequired && !item[item.name]) {
            return false;
        }
    }
    return true;
}

export function handleConvertPaymentGateways(paymentGateways,properties){
    const paymentGatewaysData = {
        pspId:paymentGateways.pspId === null || paymentGateways.pspId === undefined?null:paymentGateways.pspId.value,
        name:paymentGateways.name === null || paymentGateways.name === undefined || paymentGateways.name=== '' ?null:paymentGateways.name,
        accountNumber:paymentGateways.accountNumber === null || paymentGateways.accountNumber === undefined || paymentGateways.accountNumber ==='' ?null:paymentGateways.accountNumber,
        cardNumber:paymentGateways.cardNumber === null || paymentGateways.cardNumber === undefined || paymentGateways.cardNumber === ''?null:paymentGateways.cardNumber,
        shebaNumber:paymentGateways.shebaNumber === null || paymentGateways.shebaNumber === undefined || paymentGateways.shebaNumber === '' ?null:paymentGateways.shebaNumber,
        isActive:paymentGateways.isActive,
        bankAccountId:paymentGateways.bankAccountId === null || paymentGateways.bankAccountId === undefined?null:paymentGateways.bankAccountId.value,
        properties:properties
    }
    return paymentGatewaysData;
}
export function handleEditConvertPaymentGateways(paymentGateways,properties){
    const paymentGatewaysData = {
        pspAccountId:paymentGateways.pspAccountId,
        pspId:paymentGateways.pspId === null || paymentGateways.pspId === undefined?null:paymentGateways.pspId.value,
        name:paymentGateways.name === null || paymentGateways.name === undefined || paymentGateways.name=== '' ?null:paymentGateways.name,
        accountNumber:paymentGateways.accountNumber === null || paymentGateways.accountNumber === undefined || paymentGateways.accountNumber ==='' ?null:paymentGateways.accountNumber,
        cardNumber:paymentGateways.cardNumber === null || paymentGateways.cardNumber === undefined || paymentGateways.cardNumber === ''?null:paymentGateways.cardNumber,
        shebaNumber:paymentGateways.shebaNumber === null || paymentGateways.shebaNumber === undefined || paymentGateways.shebaNumber === '' ?null:paymentGateways.shebaNumber,
        isActive:paymentGateways.isActive,
        bankAccountId:paymentGateways.bankAccountId === null || paymentGateways.bankAccountId === undefined?null:paymentGateways.bankAccountId.value,
        properties:properties
    }
    return paymentGatewaysData;
}
