import React from "react";


import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";


export const ListSearch =({ icon, label, children, fieldsetClassName, legendClassName,iconClass,sizeIcon}) => {
    const { t } = useTranslation();

  return(
      <>
        <fieldset className={`p-2 post-input dark:bg-themeDarkPostInput post-input-design pt-4 mt-3 grid grid-ols-1 sm:grid-ols-2 md:grid-ols-3 lg:grid-ols-4 xl:grid-cols-5 gap-2 items-end grid-container ${fieldsetClassName}`}>
            <legend className={`border dark:border-themeDarkColorLine p-1 px-3 flex gap-1 items-center dark:bg-themeDarkPostInput text-white rounded-md dark:text-gray-300 H_legend_class ${legendClassName}`}>
                <HPrimeIcon icon={` text-xs ${icon ? icon:"pi-search"}`} iconClass={iconClass} sizeIcon={sizeIcon} />
                {/*<i className={`pi text-xs ${icon?icon:"pi-search"}`}></i>*/}
                    <p className={"text-xs"}>
                        {
                        label?label:t("search")
                        }
                    </p>
            </legend>

                {children}

        </fieldset>
  </>)
}