import {useState} from "react";
import {regexSetting} from "../../../Services/Globals/Public/Regexes";
import {useTranslation} from "react-i18next";

export const useCustomServer=(setErrors,initialSource)=>{
    const [server,setServer] = useState({id:null,name:null,ip:null,userName:null,password:null,cpu:0,ram:0,hdd:0,ssd:0,bandwidth:0,stateShow:false,type:null,privacy:null,connectionString:null,stateProvinceId:null,isConfigured:false,countryId:null,description:null,capacity:0,websites:[],serverId:null,
      ns1:null,
      ns2:null,
      ns3:null,
      ns4:null,

    })
    const { t } = useTranslation();

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setServer({...server,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const OnConnectionStringBuilderChangeValue=(value,name)=>{
        setServer({...server,[name]:value});
    }
    const [isIpValid,setIpValid] = useState(false);
    const handleIpValue=(data)=>{
        var ipRegex = regexSetting.Ip
        if (ipRegex.test(data)) {
            setIpValid(true);
        } else {
            setIpValid(false)
        }
        setServer({...server,ip: data});
    }
    const handleNumberValue = (value,name)=>{
        setServer({...server,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setServer({ ...server, [name]: data });
    }

    const handleStateShow = (data,name)=>{
        setServer(({...server,[name]: data}));
    }
    const handleGetInitialCountry=(name)=>{
        const defaultCountry = initialSource.countries.find((item)=>item.label === name);
        return defaultCountry;
    }


    const timerArray =  [
        {label:5+t('second'),value:5,timeUnit: 'second'},
        {label:15+t('second'),value:15,timeUnit: 'second'},
        {label:30+t('second'),value:30,timeUnit: 'second'},
        {label:1+t('minute'),value:1,timeUnit:'minute'},
    ]
    const[timer,setTimer] = useState({time:{label:5+t('second'),value:5,timeUnit: 'second'},isActive:false});
    const handleDataTimer=(value,name)=>{
         setTimer(prevState => ({...prevState,[name]:value}));
    }

    return {
        handleValue,
        OnConnectionStringBuilderChangeValue,
        isIpValid,
        handleNumberValue,
        handleSelectValue,
        handleStateShow,
        handleGetInitialCountry,
        server,
        setServer,
        handleIpValue,
        timerArray,
        timer,
        handleDataTimer,
        setTimer,
        setIpValid
    }

}