import React, {useState} from "react";

export const usePattern=(setErrors)=>{
    const [pattern,setPattern] = useState({id:null,title:null,body:null,accountId:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setPattern({...pattern,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setPattern({...pattern,[name]:data});
    }
    return {setPattern,pattern,handleValue,handleSelectValue}

}