
import {InputText} from "primereact/inputtext";
import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import Select from "react-select";
import {InputTextarea} from "primereact/inputtextarea";

import {companySchema} from "./companySchema";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import queryString from "query-string";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {useTranslation} from "react-i18next";

import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {convertCompanyHandler} from "./companyService";


export const HandleCompany = (props)=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const location = useLocation();

    const [errors, setErrors] = useState({});
    const formRef = useRef(null);
    const [nextTab,setNextTab] = useState("");
    const [selectOptions,setSelectOptions] = useState({Types:null,Countries:[],Provinces:[],Cities:[],countryValue:null,provinceValue:null})
    const[spinner,setSpinner] = useState(false);
    const [result,setResult] = useState(0);
    const initial= { id:null,name:null,brand:null,hasBrandConfirmed:false,postalCode:null,address:null,
        registrationNumber:null,nationalCode:null,type:null,description:null,cityId:null}
    const [company,setCompany] = useState(initial)

    const confirmBrandHandler= (data,name)=>{
        setCompany(({...company,[name]: data}));
        setChanged(true)
    }

    const [isChanged,setChanged] = useState(false);
    const handleValue=(e)=>{
        const {name,value} = e.target;
        const newValue = value.trim() === "" ? null : value;
        setCompany({...company,[name]:newValue});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));

        setChanged(true);

    }
    const selectValueHandler=(data,name)=>{
        setCompany({ ...company, [name]: data });
        setChanged(true)
    }

    const onApiError = () => {
        setSpinner(false);
    };
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }



    const handleFetchData=async()=>{
        try {
            setSpinner(true)
            const responseTypes = await GET(`/CompanyTypes/Selection`,{},'IEnumerable');
            const responseCountry = await GET(`/Countries/Selection`,{},'IEnumerable');
            if(responseTypes.status === 200 && responseCountry.status === 200){

                const Types = convertSelectDataHandler(responseTypes.data);
                const countries = convertSelectDataHandler(responseCountry.data);
                const favourite = countries.find((item)=>item.label === 'Iran' || item.label === t("Iran"));
                const responseProvince = await GET(`/Countries/${favourite.value}/StateProvinces/Selection`,{},'IEnumerable');
                const provinces =  convertSelectDataHandler(responseProvince.data);
                const responseCity = await GET(`/Countries/${provinces[0].value}/Cities/Selection`);
                const cities = convertSelectDataHandler(responseCity.data);
                setSelectOptions((prevOptions) => ({...prevOptions, Types: Types,Countries:countries,countryValue: favourite,provinceValue:provinces[0]}));

                setCompany({type: Types[0],cityId: cities[0],hasBrandConfirmed: false});
                setSpinner(false)

                const queryParams = queryString.parse(window.location.search);
                try {
                    if(queryParams.id){
                        const response = await GET(`/Users/Company/${queryParams.id}`,{},'single');
                        if(response.status === 200){
                            if(response.data.countryId){
                                const filteredCountry = countries.find(
                                    (item) => item.value === response.data.countryId
                                );
                                const responseProvince = await GET(`/Countries/${response.data.countryId}/StateProvinces/Selection`,{},'IEnumerable');
                                const provinces = convertSelectDataHandler(responseProvince.data);
                                const filteredProvince = provinces.find(
                                    (item) => item.value === response.data.stateProvinceId
                                );
                                setSelectOptions((prevOptions) => ({
                                    ...prevOptions,
                                    provinceValue: filteredProvince,
                                    countryValue: filteredCountry,
                                }));

                                const responseCity = await GET(`/Countries/${response.data.stateProvinceId}/Cities/Selection`);
                                const cities = convertSelectDataHandler(responseCity.data);
                                const filteredCity = cities.find(
                                    (item) => item.value === response.data.cityId
                                );
                                setCompany({
                                    id: queryParams.id,
                                    address: response.data.address,
                                    brand: response.data.brand,
                                    postalCode: response.data.postalCode,
                                    name: response.data.companyName,
                                    nationalCode: response.data.companyNationalCode,
                                    hasBrandConfirmed: response.data.hasBrandConfirmed,
                                    registrationNumber: response.data.registrationNumber,
                                    description: response.data.description,
                                    concurrencyStamp:response.data.concurrencyStamp,
                                    cityId: filteredCity,
                                    type: Types.find((item) => item.value === response.data.type),

                                })
                                // setSpinner(false);
                            }else{
                                setCompany({
                                    id: queryParams.id,
                                    address: response.data.address,
                                    brand: response.data.brand,
                                    postalCode: response.data.postalCode,
                                    name: response.data.companyName,
                                    nationalCode: response.data.companyNationalCode,
                                    hasBrandConfirmed: response.data.hasBrandConfirmed,
                                    registrationNumber: response.data.registrationNumber,
                                    description: response.data.description,
                                    concurrencyStamp:response.data.concurrencyStamp,
                                    cityId: null,
                                    type: Types.find((item) => item.value === response.data.type),
                                })
                            }
                        }
                        else{
                            handleApiError(response,onApiError);
                        }
                    }
                }catch (e) {
                    console.log(e);
                }
            }
            else {
                handleApiError(responseTypes,onApiError);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        handleFetchData();
    },[result])


    const handleCompanySubmit = async (e)=>{
        e.preventDefault();
        const isValid = validate(companySchema,company,onSetErrors);
        if(!isValid){
            return;
        }
        if(isChanged === true){
            try {
                setSpinner(true);
                if(company.hasBrandConfirmed && company.name === null || company.name === ''){
                    handleFailureMessage(t("Pleasechoosecompanyname"));
                    setSpinner(false);

                    return;
                }
                if(company.hasBrandConfirmed && company.brand === null || company.brand === ''){
                    handleFailureMessage(t("Pleaseselectbrandname"));
                    setSpinner(false);

                    return;
                }
                const queryParams = queryString.parse(window.location.search);
                const convertedData = convertCompanyHandler(company,queryParams.id || null)
                const response = await POST(`/Users/Company/Add`,convertedData,false);
                if(response.status === 200){
                    setSpinner(false);
                    setResult({...response.status})
                    handleSuccessMessage(t("Informationhassuccessfully"));

                    if(document.activeElement === formRef.current.elements.register){
                        if(location.pathname === '/CRM/User/Add'){
                            redirectTo('/CRM/User/List');
                        }
                    }
                    if(document.activeElement === formRef.current.elements.previous){
                        props.handleTabClick(nextTab);
                        return;
                    }
                    if(document.activeElement === formRef.current.elements.next){
                        props.handleTabClick(nextTab);
                        return;
                    }

                }else{
                    handleApiError(response,onApiError);
                }
            }catch (e) {
                console.log(e)
            }
        }else{
            if(document.activeElement === formRef.current.elements.register){
                redirectTo('/CRM/User/List');
                return;
            }
            if(document.activeElement === formRef.current.elements.previous){
                props.handleTabClick(nextTab);
                return;
            }
            if(document.activeElement === formRef.current.elements.next){
                props.handleTabClick(nextTab);
                return;
            }
        }
        props.handleTabClick(nextTab)
    }


    const handleChangeCounty= async (data)=>{
        if (!data) {
            return;
        }
       try {
           setSpinner(true)
           const response = await GET(`/Countries/${data.value}/StateProvinces/Selection`,{},'IEnumerable');
           const provinces = convertSelectDataHandler(response.data)
           if(response.status === 200){
               setSelectOptions((prevOptions) => ({...prevOptions,Provinces: provinces }));
               setSpinner(false);
               //setResult({...response.status})
           }else{
               handleApiError(response,onApiError);
           }
           setChanged(true)
           setSelectOptions((prevOptions) => ({
               ...prevOptions,
               countryValue: data,
           }));
       }catch (e) {
           console.log(e);
       }
    }
    const handleChangeProvince=async (data)=>{
        if (!data) {
            return;
        }
        try {
            setSpinner(true)
            const response = await GET(`/Countries/${data.value}/Cities/Selection`,{},'IEnumerable');
            const cities = convertSelectDataHandler(response.data)
            if(response.status === 200){
                setSelectOptions((prevOptions) => ({...prevOptions, Cities: cities }));
                setSpinner(false);
               // setResult({...response.status})
            }else{
                handleApiError(response,onApiError)
            }
            setChanged(true);

            setSelectOptions((prevOptions) => ({
                ...prevOptions,
                provinceValue: data,
            }));
        }catch (e) {
            console.log(e);
        }
    }




    return(
        <>
            {console.log(company)}
            <form method={'post'} onSubmit={handleCompanySubmit} className={'relative'} ref={formRef}>
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className={"space-y-5"}>
                    <div className="space-y-3">
                        <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                            <h2 className={"dark:text-gray-300"}>
                                {t("CompanyData")}
                            </h2>
                        </div>
                        <div className={"px-2"}>
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("CompanyType")}</label>
                                    <Select
                                        options={selectOptions.Types}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data)=>selectValueHandler(data,'type')}
                                        name={'type'}
                                        placeholder={t("selection")}
                                        value={company.type}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("companyName")}</label>
                                    <InputText className="input w-full" name={'name'} value={company.name} onInput={(e)=>handleValue(e)} />
                                </div>
                                <div className="space-y-2">
                                    <div className="flex justify-between">
                                        <label className="text-xs dark:text-gray-300">{t("companyNationalIdNumber")}</label>
                                        {/*<DisplayError message={errors.nationalCode}/>*/}
                                    </div>
                                    <InputText name={'nationalCode'} value={company.nationalCode} className="input w-full"  onInput={(e)=>handleValue(e)} />
                                </div>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("BrandName")}</label>
                                    <InputText className="input w-full" name={'brand'} value={company.brand}  onInput={(e)=>handleValue(e)} />
                                </div>

                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("LandlinePhone")}</label>
                                    <InputText type={'text'} className="input w-full" name={'registrationNumber'} value={company.registrationNumber} onInput={(e)=>handleValue(e)}  />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("country")}</label>
                                    <Select
                                        options={selectOptions.Countries}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data)=>handleChangeCounty(data)}
                                        placeholder={t("selection")}
                                        value={selectOptions.countryValue}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("province")}</label>
                                    <Select
                                        options={selectOptions.Provinces}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data)=>handleChangeProvince(data,'Provinces')}
                                        name={'Provinces'}
                                        placeholder={t("selection")}
                                        value={selectOptions.provinceValue}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("city")}</label>
                                    <Select
                                        options={selectOptions.Cities}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data)=>selectValueHandler(data,'cityId')}
                                        name={'CityId'}
                                        placeholder={t("selection")}
                                        value={company.cityId}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div>
                                    <InputSwitch value={company.hasBrandConfirmed} OnValueSelection={confirmBrandHandler} name={'hasBrandConfirmed'} lable={t("OfficialRegistration")} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-3">
                        <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                            <h2 className={"dark:text-gray-300"}>
                                {t("communications")}
                            </h2>
                        </div>
                        <div className={"px-2"}>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                <div class="space-y-1 lg:col-span-4">
                                    <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                                    <InputText className="input w-full"  name={'address'} value={company.address} onInput={(e)=>handleValue(e)} />
                                </div>
                                <div class="space-y-1 lg:col-span-1">
                                    <label className="text-xs dark:text-gray-300">{t("postalCode")}</label>
                                    <InputText name={'postalCode'} type={'text'} className="input w-full"  value={company.postalCode} onInput={(e)=>handleValue(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"space-y-3"}>
                        <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                            <h2 className={"dark:text-gray-300"}>
                                {t("additionalDetails")}
                            </h2>
                        </div>
                        <div className={"px-2"}>
                            <div className="post-input dark:bg-themeDarkPostInput post-input-design">
                                <div className="p-2">
                                    <InputTextarea name={"description"} value={company.description} onInput={(e) => handleValue(e)} rows={3} cols={30} className={"input w-full "}/>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex flex-wrap items-center justify-center lg:justify-end gap-2">
                        <button name={'previous'} onClick={()=>setNextTab("personal")} className="button w-28 text-white bg-themeDanger ">{t("previous")}</button>
                        <button type={'submit'} name={'register'} className="button w-24 text-white bg-themeSuccess">{t("register")}</button>
                        <button name={'next'} type={'submit'} onClick={()=>setNextTab("bank")} className="button  text-white bg-themeInformation dark:bg-themeDarkInformation">{t("registerAndNext")}</button>
                    </div>
                </div>

            </form>
        </>
    )
}