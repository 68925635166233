export const handleCreateMailAccount=(mailAccount)=>{
    const data = {
        email:mailAccount.email === null || mailAccount.email === undefined || mailAccount.email === ''?null:mailAccount.email,
        password:mailAccount.password === null || mailAccount.password === undefined || mailAccount.password === ''?null:mailAccount.password,
        isDefault:mailAccount.isDefault,
        incomingServer:mailAccount.incomingServer === null || mailAccount.incomingServer === undefined || mailAccount.incomingServer === ''?null:mailAccount.incomingServer,
        outgoingServer:mailAccount.outgoingServer === null || mailAccount.outgoingServer === undefined || mailAccount.outgoingServer === ''?null:mailAccount.outgoingServer,
        incomingPort:mailAccount.incomingPort === null || mailAccount.incomingPort === undefined ?0:mailAccount.incomingPort,
        incomingEncryptionMethod:mailAccount.incomingEncryptionMethod === null || mailAccount.incomingEncryptionMethod === undefined ?0:mailAccount.incomingEncryptionMethod.value,
        outgoingEncryptionMethod:mailAccount.outgoingEncryptionMethod === null || mailAccount.outgoingEncryptionMethod === undefined ?0:mailAccount.outgoingEncryptionMethod.value,
        connectionType:mailAccount.connectionType === null || mailAccount.connectionType === undefined ?0:mailAccount.connectionType.value,
        outgoingPort:mailAccount.outgoingPort === null || mailAccount.outgoingPort === undefined ?0:mailAccount.outgoingPort,


    }
    return data;
}

export const handleEditMailAccount=(mailAccount)=>{
    const data = {
        mailAccountId:mailAccount.mailAccountId,
        email:mailAccount.email === null || mailAccount.email === undefined || mailAccount.email === ''?null:mailAccount.email,
        password:mailAccount.password === null || mailAccount.password === undefined || mailAccount.password === ''?null:mailAccount.password,
        isDefault:mailAccount.isDefault,
        incomingServer:mailAccount.incomingServer === null || mailAccount.incomingServer === undefined || mailAccount.incomingServer === ''?null:mailAccount.incomingServer,
        outgoingServer:mailAccount.outgoingServer === null || mailAccount.outgoingServer === undefined || mailAccount.outgoingServer === ''?null:mailAccount.outgoingServer,
        incomingPort:mailAccount.incomingPort === null || mailAccount.incomingPort === undefined ?0:mailAccount.incomingPort,
        incomingEncryptionMethod:mailAccount.incomingEncryptionMethod === null || mailAccount.incomingEncryptionMethod === undefined ?0:mailAccount.incomingEncryptionMethod.value,
        outgoingEncryptionMethod:mailAccount.outgoingEncryptionMethod === null || mailAccount.outgoingEncryptionMethod === undefined ?0:mailAccount.outgoingEncryptionMethod.value,
        connectionType:mailAccount.connectionType === null || mailAccount.connectionType === undefined ?0:mailAccount.connectionType.value,
        outgoingPort:mailAccount.outgoingPort === null || mailAccount.outgoingPort === undefined ?0:mailAccount.outgoingPort,
    }
    return data;
}