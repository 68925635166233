import React, {useState} from "react";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleFailureMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

export const useRoadMap=(setErrors,setInitialSource,showLoading,hideLoading)=>{

    const [roadMapTree, setRoadMapTree] = useState({
        title: null,
        operation: null,
        access: null,
        personEntity: null,
        personEntityId:null,
        description: null,
        roadMapId:null,
        isApproved: true,
        parentId: null,
        roadMapStepId:null
    });

    const handleSelectValue = async (data, name) => {
        if (name === 'personEntity' && data !== null) {
            setRoadMapTree((prevState) => ({ ...prevState, [name]: data }));

            const fetchSelectionData = async (url) => {
                try {
                    showLoading();
                    const response = await GET(url, {}, 'IEnumerable');
                    if (response.status === 200) {

                        const types = convertSelectDataHandler(response.data);
                        setInitialSource((prevStates) => ({ ...prevStates,types:types}));
                        setRoadMapTree((prevState) => ({ ...prevState, personEntityId: types[0] }));
                        hideLoading();

                    } else {
                        handleApiError(response,hideLoading)
                    }
                } catch (e) {
                    hideLoading();
                }
            };

            switch (data.value) {
                case 0:
                    await fetchSelectionData('/Users/Selection');
                    break;
                case 1:
                    await fetchSelectionData('/UserGroups/Selection');
                    break;
                case 2:
                    await fetchSelectionData('/Roles/Selection');
                    break;
                default:
                     handleFailureMessage('دیتای نامعتبر');
                    break;
            }
        }
        else {
            setRoadMapTree((prevState) => ({ ...prevState, [name]: data }));
        }
    };

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setRoadMapTree({...roadMapTree,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setRoadMapTree({...roadMapTree,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    return {roadMapTree,setRoadMapTree,handleSelectValue,handleValue,handleNumberValue}
}