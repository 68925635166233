import React from "react";

export const handleConvertAddTariff=(obj,valueService)=>{
    const tariff = {
        upgradeType: valueService !== 1 || obj.upgradeType === null || obj.upgradeType === undefined?null:obj.upgradeType.value,
        price:obj.price === null || obj.price === undefined?0:obj.price,
        currencyType:obj.currencyType === null || obj.currencyType === undefined ?null:obj.currencyType.value,
        countryId:obj.countryId === null || obj.countryId === undefined?null:obj.countryId.value,
        discountAmount:obj.discountAmount === null || obj.discountAmount === undefined ? null:obj.discountAmount,
        discountType:obj.discountType === null || obj.discountType === undefined ? null:obj.discountType.value,
        discountTo:obj.discountTo === null || obj.discountTo === undefined ? null:obj.discountTo,
        discountFrom:obj.discountFrom === null || obj.discountFrom === undefined ? null:obj.discountFrom,
        paymentMethod:obj.paymentMethod === null || obj.paymentMethod === undefined ?null:obj.paymentMethod.value,
        packageId:valueService !== 2  ||  obj.packageId === null || obj.packageId === undefined ?null:obj.packageId.value,
        templateMasterId:valueService !== 3 ||  obj.templateMasterId === null || obj.templateMasterId === undefined ?null:obj.templateMasterId.value
    }
    return tariff;
}
export const handleConvertEditTariff=(obj,valueService)=>{
    const tariff = {
        tariffId:obj.tariffId??null,
        upgradeType: valueService !== 1 || obj.upgradeType === null || obj.upgradeType === undefined?null:obj.upgradeType.value,
        price:obj.price === null || obj.price === undefined?0:obj.price,
        currencyType:obj.currencyType === null || obj.currencyType === undefined ?null:obj.currencyType.value,
        countryId:obj.countryId === null || obj.countryId === undefined?null:obj.countryId.value,
        discountAmount:obj.discountAmount === null || obj.discountAmount === undefined ? null:obj.discountAmount.value,
        discountType:obj.discountType === null || obj.discountType === undefined ? null:obj.discountType.value,
        discountTo:obj.discountTo === null || obj.discountTo === undefined ? null:obj.discountTo,
        discountFrom:obj.discountFrom === null || obj.discountFrom === undefined ? null:obj.discountFrom,
        paymentMethod:obj.paymentMethod === null || obj.paymentMethod === undefined ?null:obj.paymentMethod.value,
        packageId:valueService !== 2  ||  obj.packageId === null || obj.packageId === undefined ?null:obj.packageId.value,
        templateMasterId:valueService !== 3 ||  obj.templateMasterId === null || obj.templateMasterId === undefined ?null:obj.templateMasterId.value
    }
    return tariff;
}



