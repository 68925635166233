import React, {useEffect, useMemo, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";

import {useNavigate} from "react-router-dom";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import Swal from "sweetalert2";
import Select from "react-select";
import {useTranslation} from "react-i18next";

import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";

import {GetSpecialRole} from "../../../../Services/AxiosService/JwtService";
import {useSetting} from "../../../Commans/Hooks/useSetting";


export const HandleHRMListPersonManagement = () => {

    const { t } = useTranslation();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const redirectTo = useNavigate();
    const [refresh,setRefresh] = useState(false);

    const [IsOpenContact, setIsOpenContact] = useState(false);
    const [userContact,setUserContact] = useState(null)

    const [initialSource,setInitialSource] = useState({groupOptions:[],statusOptions:null,roles:null,types:[],filters:[]})
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [IsShowPersonalInformation,setIsShowPersonalInformation] = useState(false);

    // const {filter,setFilter, setSearch, search,handleSearch,handleRegisterFromDateTime,handleRegisterToDateTime, handleResetFilters, handleSwitchFilters, handleSelectFilterUserGroupIds,handleSelectFilterValue}=useUserGridFilter()
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner = ()=>{
        setIsShowSpinner(false);
    }

    const showPersonalInformation=()=>{
        setIsShowPersonalInformation(true)
    }
    const hidePersonalInformation = ()=>{
        setIsShowPersonalInformation(false);
    }

    const handleCloseContact =()=>{
        setIsOpenContact(false);
    }
    const handleOpenContact=()=>{
        setIsOpenContact(true);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }





    const fetchData = async () => {
        try {
            showSpinner();

            const requests = [
                GET('/Communications/Selection', {}, 'IEnumerable'),
                GET(`/Enumerations/EnActivationStatus`, {}, 'IEnumerable'),
                GET('/UserGroups', {}, 'IEnumerable'),
                GET('/Roles/Selection', {}, 'IEnumerable'),
                GET(`/Filters/Selection?Table=${1}`, {}, 'IEnumerable')
            ];

            const [
                responseCommunicationType,
                responseStatus,
                responseGroup,
                responseRole,
                responseFilters
            ] = await Promise.all(requests);

            if (
                responseCommunicationType.status === 200 &&
                responseStatus.status === 200 &&
                responseGroup.status === 200 &&
                responseRole.status === 200 &&
                responseFilters.status === 200
            ) {
                hideSpinner();
                const statusOptions = convertSelectDataHandler(responseStatus.data);
                const groupOptions = convertSelectDataHandler(responseGroup.data);
                const roleOptions = convertSelectDataHandler(responseRole.data);
                const types = convertSelectDataHandler(responseCommunicationType.data);
                const filters = convertSelectDataHandler(responseFilters.data);

                setInitialSource((prevOptions) => ({
                    ...prevOptions,
                    statusOptions: statusOptions,
                    groupOptions: groupOptions,
                    roles: roleOptions,
                    types: types,
                    filters: filters
                }));
            } else {
                handleApiError(responseStatus, hideSpinner);
                handleApiError(responseGroup, hideSpinner);
                handleApiError(responseRole, hideSpinner);
                handleApiError(responseCommunicationType, hideSpinner);
                handleApiError(responseFilters, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(()=>{
        fetchData();
    },[])




    const handleGetAllUserContacts = async (id) => {
        try {
            showSpinner()
            const response = await GET(`/Users/GetUserContactInformation`,{Id:id},'single');
            if (response.status === 200) {
                hideSpinner();
                const communications = response.data.communications.map((communication) => {
                    const matchingType = initialSource.types.find((type) => type.value === communication.communicationId);
                    return {
                        type: communication.type,
                        value: communication.value,
                        label: matchingType ? matchingType.label : null,
                    };
                });
                const updatedUserContact = {
                    name: response.data.name,
                    picture: response.data.picture,
                    communications: communications,
                };
                setUserContact(updatedUserContact);
                handleOpenContact()
            } else {
                handleApiError(response,hideSpinner);
            }
        } catch (e) {
            hideSpinner()
        }
    };

    const handleDeleteUser = async (userId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Users?Id=${userId}`,{},);
                    if (response.status === 200) {
                        hideSpinner()
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }










    const itemOperations = [
        {
            icon: 'phone-call',
            tooltip: t("AdditionalInformation"),
            onClick: (id) => handleGetAllUserContacts(id),
            isShow: true,
            primeIcon: false,
            iconClass:'text-themeInformation dark:text-themeDarkInformation'

        },
        {
            icon: 'pi-hammer',
            tooltip: t("LegalProvisions"),
            onClick: (id) => redirectTo(`/Attendance/Personnel/Rules?id=${id}`),
            isShow: true,
            primeIcon: true,
            iconClass:'text-themeInformation dark:text-themeDarkInformation'
        },
        {
            icon: 'watch',
            tooltip: t("Report"),
            onClick: (id) => redirectTo(`/Attendance/List/IndividualReport?id=${id}`),
            isShow: true,
            primeIcon: false,
            iconClass:'text-themeInformation dark:text-themeDarkInformation'
        },
        {
            icon: 'info',
            tooltip: t("اطلاعات پرسنلی"),
            onClick: () => showPersonalInformation(),
            isShow: true,
            primeIcon: false,
            iconClass:'text-themeInformation dark:text-themeDarkInformation'
        },
        {
            icon: 'smile',
            tooltip: t("اطلاعات خانواده"),
            onClick: (id) => redirectTo(`/Attendance/Personnel/FamilyInformation?id=${id}`),
            isShow: true,
            primeIcon: false,
            iconClass:'text-themeInformation dark:text-themeDarkInformation'

        },
        {
            icon: 'pi-file-edit',
            tooltip: t("Contracts"),
            onClick: (id) => redirectTo(`/Attendance/Personnel/Contracts?id=${id}`),
            isShow: true,
            primeIcon: true,
            iconClass:'text-themeInformation dark:text-themeDarkInformation'

        }
    ];



    const role = GetSpecialRole();

    return(

        <div class=" space-y-5">
            {/*<div className="box bg-white dark:bg-themeDarkSalt700 p-3 ">*/}
            {/*    <div className="space-y-5">*/}
            {/*        <ListSearch label={null} icon={null} >*/}
            {/*            <div class=" col-span-1 sm:col-span-4 space-y-2">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("grouping")}</label>*/}
            {/*                <Select*/}
            {/*                    options={initialSource.groupOptions}*/}
            {/*                    isClearable={true}*/}
            {/*                    isMulti={true}*/}
            {/*                    isSearchable={true}*/}
            {/*                    onChange={(data)=>handleSelectFilterUserGroupIds(data)}*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    className={"select-group"}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class=" col-span-1 md:col-span-2 lg:col-span-1 space-y-2">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("FilterTitle")}</label>*/}
            {/*                <Select*/}
            {/*                    options={initialSource.filters}*/}
            {/*                    isMulti={false}*/}
            {/*                    isClearable={true}*/}
            {/*                    isSearchable={true}*/}
            {/*                    onChange={(data)=>handleSelectFilterValue(data,'filterId')}*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class=" col-span-1 md:col-span-2 lg:col-span-1 space-y-2">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("search")}</label>*/}
            {/*                <InputText type="text" onChange={(e)=>setSearch(e.target.value)} value={search} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-2">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("userType")}</label>*/}
            {/*                <Select*/}
            {/*                    options={initialSource.roles}*/}
            {/*                    isMulti={false}*/}
            {/*                    isClearable={true}*/}
            {/*                    isSearchable={true}*/}
            {/*                    onChange={(data)=>handleSelectFilterValue(data,'RoleId')}*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-2">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("Status")}</label>*/}
            {/*                <Select*/}
            {/*                    options={initialSource.statusOptions}*/}
            {/*                    isMulti={false}*/}
            {/*                    isClearable={true}*/}
            {/*                    isSearchable={true}*/}
            {/*                    onChange={(data)=>handleSelectFilterValue(data,'ActivationStatus')}*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class=" space-y-2">*/}
            {/*                <label className="text-xs dark:text-gray-300">*/}
            {/*                    <SettingButton*/}
            {/*                        onClick={()=>handleSetting('GeneralSetting')}*/}
            {/*                        tooltipTitle={t("Calendarsettings")}*/}
            {/*                    />*/}
            {/*                    {t("FromDate")}*/}
            {/*                </label>*/}
            {/*                <DateTimePicker handleDateTime={handleRegisterFromDateTime} value={filter.RegisterFromDate} format={'YYYY/MM/DD'} />*/}
            {/*            </div>*/}
            {/*            <div class=" space-y-2">*/}
            {/*                <label className="text-xs dark:text-gray-300">*/}
            {/*                    <SettingButton*/}
            {/*                        onClick={()=>handleSetting('GeneralSetting')}*/}
            {/*                        tooltipTitle={t("Calendarsettings")}*/}
            {/*                    />*/}
            {/*                    {t("ToDate")}*/}
            {/*                </label>*/}
            {/*                <DateTimePicker value={filter.RegisterToDate} handleDateTime={handleRegisterToDateTime} format={'YYYY/MM/DD'} />*/}
            {/*            </div>*/}
            {/*            <ButtonSearchList*/}
            {/*                onClickSearch={()=>handleSearch()}*/}
            {/*                onClickReset={()=>handleResetFilters()}*/}
            {/*            />*/}
            {/*        </ListSearch>*/}
            {/*        <ListFilter label={null} icon={null}*/}
            {/*                    childrenOne={*/}
            {/*                        <>*/}
            {/*                            <ButtonPlus*/}
            {/*                                onClick={()=>redirectTo(`/Attendance/Personnel/Add`)}*/}
            {/*                                tooltipTitle={t("UsersRegistration")}*/}
            {/*                                title={t("personnel")}*/}
            {/*                            />*/}

            {/*                            <ButtonPlus*/}
            {/*                                onClick={()=>redirectTo(`/CRM/User/Group`)}*/}
            {/*                                icon={"users"}*/}
            {/*                                title={t("grouping")}*/}
            {/*                                tooltipTitle={t("CustomerGrouping")}*/}
            {/*                            />*/}
            {/*                            <ButtonPlus*/}
            {/*                                onClick={()=>redirectTo(`/CRM/User/Role`)}*/}
            {/*                                icon={"star"}*/}
            {/*                                title={t("Role")}*/}
            {/*                                tooltipTitle={t("CreatingRole")}*/}
            {/*                            />*/}

            {/*                            <ButtonPlus*/}
            {/*                                onClick={()=>redirectTo(`/CRM/User/List`)}*/}
            {/*                                icon={"list"}*/}
            {/*                                title={t("list")}*/}
            {/*                                functionClass={"harmony-return-list"}*/}
            {/*                                tooltipTitle={t("ListOfPersons")}*/}
            {/*                            />*/}
            {/*                            <ButtonPlus*/}
            {/*                                onClick={()=>redirectTo(`/CRM/User/Reminder/List`)}*/}
            {/*                                icon={"clock"}*/}
            {/*                                title={t("Reminders")}*/}
            {/*                                tooltipTitle={t("Reminders")}*/}

            {/*                            />*/}

            {/*                        </>*/}
            {/*                    }*/}
            {/*                    childrenTwo={*/}
            {/*                        <>*/}
            {/*                            <InputSwitch OnValueSelection={handleSwitchFilters} name={'OneMonthToExpire'} lable={t("MonthToExpired")} />*/}
            {/*                            <InputSwitch OnValueSelection={handleSwitchFilters} name={'IsExpired'} lable={t("expired")} />*/}
            {/*                            <InputSwitch OnValueSelection={handleSwitchFilters} name={'IncompleteCase'} lable={t("incompleteFile")} />*/}
            {/*                            <InputSwitch OnValueSelection={handleSwitchFilters} name={'PhoneNoConfirmed'}   lable={t("phoneNumberVerification")} />*/}
            {/*                            <InputSwitch OnValueSelection={handleSwitchFilters} name={'NaturalEntity'} lable={t("Individuals")} />*/}
            {/*                            <InputSwitch OnValueSelection={handleSwitchFilters} name={'LegalEntity'} lable={t("LegalEntities")} />*/}
            {/*                            {*/}
            {/*                                role === 'SuperAdmin'?*/}
            {/*                                    <InputSwitch OnValueSelection={handleSwitchFilters} name={'hasList'} lable={'دارای سایت'} />*/}
            {/*                                    :null*/}
            {/*                            }*/}
            {/*                        </>*/}
            {/*                    }*/}
            {/*        />*/}

            {/*    </div>*/}
            {/*</div>*/}
            {/*    <div className={"space-y-5"}>*/}
            {/*        <HandleHrmListManagement*/}
            {/*            filter={filter}*/}
            {/*            hideSpinner={hideSpinner}*/}
            {/*            handleSettingClose={handleSettingClose}*/}
            {/*            IsSettingOpen={IsSettingOpen}*/}
            {/*            initialSource={initialSource}*/}
            {/*            IsAllowExport={true}*/}
            {/*            showSpinner={showSpinner}*/}
            {/*            showPersonalInformation={showPersonalInformation}*/}
            {/*            setting={setting}*/}
            {/*            IsShowSpinner={IsShowSpinner}*/}
            {/*            IsShowPersonalInformation={IsShowPersonalInformation}*/}
            {/*            IsDisplayMenu={false}*/}
            {/*            IsAllowUserPin={true}*/}
            {/*            itemOperations={itemOperations}*/}
            {/*            userContact={userContact}*/}
            {/*            refresh={refresh}*/}
            {/*            IsOpenContact={IsOpenContact}*/}
            {/*            Refresh={Refresh}*/}
            {/*            handleCloseContact={handleCloseContact}*/}
            {/*            handleOpenContact={handleOpenContact}*/}
            {/*            setUserContact={setUserContact}*/}
            {/*            mode={''}*/}
            {/*            role={role}*/}
            {/*        />*/}
            {/*    </div>*/}
            <FantasyModal openModal={IsShowPersonalInformation}  closeModal={hidePersonalInformation} title={t("PersonalInformation")} >
                <div className={"space-y-5"}>
                    <div className={"bg-themeLightGray p-2 flex items-center gap-2 box"}>
                        <div className="w-12 h-12 bg-white dark:bg-themeDarkPostInput flex-shrink-0 rounded-full overflow-hidden">
                            <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="w-full h-full object-cover" />
                        </div>
                        <div className={"flex gap-3 items-center justify-between flex-1 w-full flex-wrap"}>
                            <div className={"flex gap-1 items-center"}>
                                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Name")}:</h3>
                                <h2 className="line-clamp-1 text-xs dark:text-gray-300">
                                    مسعود احمدی محمد آبادی
                                </h2>
                            </div>
                            <div className={"flex gap-1 items-center"}>
                                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("PersonnelCode")}:</h3>
                                <h2 className="line-clamp-1 text-xs dark:text-gray-300">
                                    12346546548674
                                </h2>
                            </div>

                        </div>

                    </div>

                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 "}>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("TypeInsurance")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("InsuranceCode")}</label>
                            <InputText  type="text"  className=" input"  />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("InsurancePosition")}</label>
                            <InputText  type="text"  className=" input"  />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("AttendanceType")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("bloodType")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("HousingSituation")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("MaritalStatus")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("DateMarriage")}</label>
                            <InputText  type="text"  className=" input"  />
                        </div>
                    </div>

                    <div >
                        <button type={'button'} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white "} >{t("creation")}</button>
                    </div>
                </div>
            </FantasyModal>


        </div>
    )
}