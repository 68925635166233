import { createSlice } from '@reduxjs/toolkit';

const permissionSlice = createSlice({
    name: 'permissions',
    initialState: {websitePermissions:null},
    reducers: {
        setWebsitePermissions: (state, action) => {
            state.websitePermissions = action.payload;
        },
    },
});

export const { setWebsitePermissions } = permissionSlice.actions;
export default permissionSlice.reducer;