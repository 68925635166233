import React, {useState} from "react";

export const useForgetPassword=(setErrors)=>{
    const [authData, setAuthData] = useState({ username: '', id: '', code: '', password: '', confirmPassword: '', otpCode: '',method:null });
    const handleSelectValue=(data,name)=>{
        setAuthData({...authData,[name]:data});
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setAuthData({...authData,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    return {
        authData,
        setAuthData,
        handleValue,
        handleSelectValue
    }
}