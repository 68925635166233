import React from "react";

import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const WebsiteServer=({siteName,serverId,handleRestartIis})=>{

    return (
        <>
            <div className={"flex items-center text-nowrap gap-1 border border-dashed dark:border-themeDarkColorLine box bg-white justify-center  dark:bg-themeDarkSalt700 p-1"}>
                <div className={"flex gap-1 items-center"}>
                    <HPrimeIconSmall
                    icon={"pi-server"}
                    iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                    />
                    <span className={" text-xs text-themeInformation dark:text-themeDarkInformation"}>{siteName} :</span>
                </div>

                <button  onClick={()=>handleRestartIis(siteName,serverId)}>
                    <HPrimeIconSmall
                    icon={"pi-sync"}
                    iconClass={"text-themeDanger"}
                    />
                </button>

            </div>

        </>
    )
}