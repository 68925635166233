
// export const handleTrimeAdvertise = (inputObject)=>{
//     const updatedObject = {...inputObject};
//     updatedObject.Attachments = updatedObject.Attachments.map((attachment) => {const { id, ...rest } = attachment;return { ...rest }});
//     updatedObject.Communications = updatedObject.Communications.map((communication) => {
//         const { id, ...rest } = communication;
//         return { ...rest };
//     });
//     return updatedObject;
// }
// export const handleTrimeAdvertise = (inputObject,IsEdit)=>{
//     if(IsEdit){
//         const updatedObject = {...inputObject};
//         updatedObject.Attachments = updatedObject.Attachments
//             .filter(attachment => attachment.isDefault === false || attachment.attachId) // filter by isDefault property
//             .map((attachment) => {
//                 const { id, isDefault, ...rest } = attachment; // remove id and isDefault properties
//                 return { ...rest };
//             });
//         updatedObject.Communications = updatedObject.Communications
//             .filter(communication => communication.isDefault === false) // filter by isDefault property
//             .map((communication) => {
//                 const { id, isDefault, ...rest } = communication; // remove id and isDefault properties
//                 return { ...rest };
//             });
//         return updatedObject;
//     }
//     else{
//         const updatedObject = {...inputObject};
//         updatedObject.Attachments = updatedObject.Attachments
//             .filter(attachment => attachment.isDefault === false) // filter by isDefault property
//             .map((attachment) => {
//                 const { id, isDefault, ...rest } = attachment; // remove id and isDefault properties
//                 return { ...rest };
//             });
//         updatedObject.Communications = updatedObject.Communications
//             .filter(communication => communication.isDefault === false) // filter by isDefault property
//             .map((communication) => {
//                 const { id, isDefault, ...rest } = communication; // remove id and isDefault properties
//                 return { ...rest };
//             });
//         return updatedObject;
//     }
//
// }




export const convertAddAdvertise = (obj,files)=>{
    const newObj = {
        Title: obj.Title === null || obj.Title === undefined  ? null:obj.Title,
        CoverImagePath: obj.CoverImagePath === null || obj.CoverImagePath === undefined ? null :obj.CoverImagePath,
        Excerpt: obj.Excerpt === null || obj.Excerpt === undefined ?null:obj.Excerpt,
        Seo_Keyword:  obj.Seo_Keyword === null || obj.Seo_Keyword === undefined?null: obj.Seo_Keyword ,
        Editor: obj.Editor === null || obj.Editor === undefined ? null : obj.Editor,
        SeoTitle:obj.SeoTitle === null || obj.SeoTitle === undefined?null:obj.SeoTitle,
        SeoDescription:obj.SeoDescription === null || obj.SeoDescription === undefined ? null:obj.SeoDescription,
        CoverImage:obj.CoverImage === null ||obj.CoverImage === undefined ? null : obj.CoverImage,
        Slug:obj.Slug === null || obj.Slug === undefined ?null: obj.Slug,
        StartDate: obj.StartDate??null,
        ExpirationDate:obj.ExpirationDate??null,
        CityId:obj.CityId === null || obj.CityId === undefined? null : obj.CityId.value,
        SendingStates: obj.SendingStates.map(item => item.value),
        files: files??null,
        Communications: obj.Communications.map(item => item),
        StateAndroid:obj.StateAndroid,
        StateGoogle:obj.StateGoogle,
        StateShow:obj.StateShow,
        SponsorId:obj.SponsorId === null || obj.SponsorId === undefined ?null : obj.SponsorId.value,
        TemplateId:obj.TemplateId === null ||obj.TemplateId === undefined ?null : obj.TemplateId.value,
        IframeMap:obj.IframeMap === null || obj.IframeMap === undefined ? null : obj.IframeMap,
        Longitude:obj.Longitude === null || obj.Longitude === undefined ? null: obj.Longitude,
        Latitude:obj.Latitude === null || obj.Latitude === undefined ? null: obj.Latitude,
        FilterId:obj.FilterId === null || obj.FilterId === undefined ? null : obj.FilterId.value,
    }
    return newObj
}

export const handleAddAdvertiseFormData = (obj) => {

    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Title', obj.Title);
    appendIfNotNull('CoverImagePath', obj.CoverImagePath);
    appendIfNotNull('Excerpt', obj.Excerpt);
    appendIfNotNull('CityId', obj.CityId);
    appendIfNotNull('Editor', obj.Editor);
    appendIfNotNull('SeoTitle', obj.SeoTitle);
    appendIfNotNull('SeoDescription', obj.SeoDescription);
    appendIfNotNull('Slug', obj.Slug);
    if(obj.StartDate){
        const date = new Date(obj.StartDate).toISOString();
        appendIfNotNull('StartDate',date);
    }
    if(obj.ExpirationDate){
        const date = new Date(obj.ExpirationDate).toISOString();
        appendIfNotNull('ExpirationDate',date);
    }
    appendIfNotNull('StateAndroid', obj.StateAndroid);
    appendIfNotNull('StateGoogle', obj.StateGoogle);
    appendIfNotNull('StateShow', obj.StateShow);
    appendIfNotNull('SponsorId', obj.SponsorId);
    appendIfNotNull('TemplateId', obj.TemplateId);
    appendIfNotNull('IframeMap', obj.IframeMap);
    appendIfNotNull('Longitude', obj.Longitude);
    appendIfNotNull('Latitude', obj.Latitude);
    appendIfNotNull('FilterId', obj.FilterId);
    if (obj.CoverImage) {
        appendIfNotNull('CoverImage', obj.CoverImage);
    }
    if (obj.Seo_Keyword) {
        obj.Seo_Keyword.forEach((keyword, index) => {
            appendIfNotNull(`Seo_Keyword[${index}]`, keyword);
        });
    }
    if (obj.SendingStates) {
        obj.SendingStates.forEach((state, index) => {
            appendIfNotNull(`SendingStates[${index}]`, state); // Use an empty key to make it an array
        });
    }
    if (obj.files) {
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file !== null){
                formData.append(`Attachments[${i}].file`,obj.files[i].file);
                formData.append(`Attachments[${i}].type`,  obj.files[i].type.value);
                formData.append(`Attachments[${i}].caption`, obj.files[i]?.caption );
                formData.append(`Attachments[${i}].position`, obj.files[i]?.position.value );
                formData.append(`Attachments[${i}].width`, obj.files[i].width );
                formData.append(`Attachments[${i}].height`, obj.files[i].height );
            }else{

            }
        }
    }
    if (obj.Communications) {
        obj.Communications.forEach((item, index) => {
            appendIfNotNull(`Communications[${index}].communicationId`, item.communicationId.value);
            appendIfNotNull(`Communications[${index}].mode`, item.mode.value);
            appendIfNotNull(`Communications[${index}].value`, item.value);
            appendIfNotNull(`Communications[${index}].description`, item.description);
        });
    }

    return formData;
};


export const convertEditAdvertise=(obj,files)=>{
    const newObj = {
        Id:obj.Id,
        CoverImagePath: obj.CoverImagePath === null || obj.CoverImagePath === undefined ? null :obj.CoverImagePath,
        Title: obj.Title === null || obj.Title === undefined ? null :obj.Title,
        Excerpt: obj.Excerpt === null || obj.Excerpt === undefined ?null:obj.Excerpt,
        Seo_Keyword:  obj.Seo_Keyword === null || obj.Seo_Keyword === undefined?null: obj.Seo_Keyword ,
        Editor: obj.Editor === null || obj.Editor === undefined ? null : obj.Editor,
        SeoTitle:obj.SeoTitle === null || obj.SeoTitle === undefined?null:obj.SeoTitle,
        SeoDescription:obj.SeoDescription === null || obj.SeoDescription === undefined ? null:obj.SeoDescription,
        CoverImage:obj.CoverImage === null ||obj.CoverImage === undefined ? null : obj.CoverImage,
        Slug:obj.Slug === null || obj.Slug === undefined ?null: obj.Slug,
        StartDate: obj.StartDate??null,
        ExpirationDate:obj.ExpirationDate??null,
        CityId:obj.CityId === null || obj.CityId === undefined? null : obj.CityId.value,
        SendingStates: obj.SendingStates.map(item => item.value),
        files: files??null,
        Communications: obj.Communications.map(item => item),
        StateAndroid:obj.StateAndroid,
        StateGoogle:obj.StateGoogle,
        StateShow:obj.StateShow,
        SponsorId:obj.SponsorId === null || obj.SponsorId === undefined ?null : obj.SponsorId.value,
        TemplateId:obj.TemplateId === null ||obj.TemplateId === undefined ?null : obj.TemplateId.value,
        IframeMap:obj.IframeMap === null || obj.IframeMap === undefined ? null : obj.IframeMap,
        Longitude:obj.Longitude === null || obj.Longitude === undefined ? null: obj.Longitude,
        Latitude:obj.Latitude === null || obj.Latitude === undefined ? null: obj.Latitude,
        FilterId:obj.FilterId === null || obj.FilterId === undefined ? null : obj.FilterId.value,
    }
    return newObj
}

export const handleEditAdvertiseFormData = (obj)=>{
    const formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Id', obj.Id);
    appendIfNotNull('Title', obj.Title);
    appendIfNotNull('CoverImagePath', obj.CoverImagePath);
    appendIfNotNull('Excerpt', obj.Excerpt);
    appendIfNotNull('CityId', obj.CityId);
    appendIfNotNull('Editor', obj.Editor);
    appendIfNotNull('SeoTitle', obj.SeoTitle);
    appendIfNotNull('SeoDescription', obj.SeoDescription);
    appendIfNotNull('Slug', obj.Slug);
    if(obj.StartDate){
        const date = new Date(obj.StartDate).toISOString();
        appendIfNotNull('StartDate',date);
    }
    if(obj.ExpirationDate){
        const date = new Date(obj.ExpirationDate).toISOString();
        formData.append('ExpirationDate',date);
    }
    appendIfNotNull('StateAndroid', obj.StateAndroid);
    appendIfNotNull('StateGoogle', obj.StateGoogle);
    appendIfNotNull('StateShow', obj.StateShow);
    appendIfNotNull('SponsorId', obj.SponsorId);
    appendIfNotNull('TemplateId', obj.TemplateId);
    appendIfNotNull('IframeMap', obj.IframeMap);
    appendIfNotNull('Longitude', obj.Longitude);
    appendIfNotNull('Latitude', obj.Latitude);
    appendIfNotNull('FilterId', obj.FilterId);
    if (obj.CoverImage) {
        appendIfNotNull('CoverImage', obj.CoverImage);
    }
    if (obj.Seo_Keyword) {
        obj.Seo_Keyword.forEach((keyword, index) => {
            appendIfNotNull(`Seo_Keyword[${index}]`, keyword);
        });
    }
    if (obj.SendingStates) {
        obj.SendingStates.forEach((state, index) => {
            appendIfNotNull(`SendingStates[${index}]`, state); // Use an empty key to make it an array
        });
    }

    if (obj.files) {
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file === null && obj.files[i].attachId === null){
            }
            else{
                if(obj.files[i].file !== null && obj.files[i].attachId !== null ){
                    formData.append(`Attachments[${i}].FilePath`,obj.files[i].filePath);
                }
                formData.append(`Attachments[${i}].file`,obj.files[i].file);
                formData.append(`Attachments[${i}].type`,  obj.files[i].type.value);
                formData.append(`Attachments[${i}].caption`, obj.files[i]?.caption );
                formData.append(`Attachments[${i}].position`, obj.files[i]?.position.value );
                formData.append(`Attachments[${i}].attachId`, obj.files[i].attachId );
                formData.append(`Attachments[${i}].width`, obj.files[i].width );
                formData.append(`Attachments[${i}].height`, obj.files[i].height );
            }
        }
    }


    if (obj.Communications) {
        obj.Communications.forEach((item, index) => {
            if(item.advertisingCommunicationId){
                appendIfNotNull(`Communications[${index}].advertisingCommunicationId`, item.advertisingCommunicationId);
                appendIfNotNull(`Communications[${index}].communicationId`, item.communicationId.value);
                appendIfNotNull(`Communications[${index}].mode`, item.mode.value);
                appendIfNotNull(`Communications[${index}].value`, item.value);
                appendIfNotNull(`Communications[${index}].description`, item.description);
            }
            else{
                appendIfNotNull(`Communications[${index}].communicationId`, item.communicationId.value);
                appendIfNotNull(`Communications[${index}].mode`, item.mode.value);
                appendIfNotNull(`Communications[${index}].value`, item.value);
                appendIfNotNull(`Communications[${index}].description`, item.description);
            }

        });
    }
    return formData;
}

export const convertAttachmentHandler = (attachData,advertiseTypes,positionTypes)=>{
    const attaches = attachData.map(item => ({
        ...item,
        type: advertiseTypes.find(typeItem => typeItem.value === item.type),
        position: positionTypes.find(typeItem => typeItem.value === item.type),
    }));
    return attaches;
}

export const convertCommunicationHandler = (requestObject,requiredObject,noneRequired)=>{
    const filteredMeta = requestObject.map(item => ({
        ...item,
        communicationId: requiredObject.find(typeItem => typeItem.value === item.communicationId),
        mode: noneRequired.find(typeItem => typeItem.value === item.mode)
    }));
    return filteredMeta;
}