import React from "react";
export const NoDataHarmony = ({classImg,classStyle})=>{
    return (
        <>
            <div className={`w-full h-full p-2 grid place-content-center items-center ${classStyle}`}>
                <img src="/Assets/Image/bg-img/Nodata.svg" alt="" className={`w-40 h-40 ${classImg}`}/>
            </div>
        </>

    )
}