import React from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {TinyEditor} from "../../../Commans/UIParts/TinyEditor";
import {useTranslation} from "react-i18next";
export  const ContentTab=({handleSetting,handleValue,document,handleChangeContent})=>{
    const { t } = useTranslation();

    return (

        <>
            <div className="space-y-4">
                <div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                    <label className="dark:text-gray-300 text-xs">
                        {t("TextSummary")}
                    </label>
                    <InputTextarea name={"description"}
                                   rows={3} cols={30} className={"input w-full "}
                                   onInput={(e)=>handleValue(e)}
                                   value={document.description}
                    />
                </div>
                <div className=" post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                    <div>
                        <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                            <SettingButton
                                onClick={() => handleSetting('GeneralSetting')}
                                tooltipTitle={t("EditorType")}
                                tooltipId={5}
                            />
                            {t("FullText")}
                        </label>
                    </div>
                    <div className={"custom-editor-class"}>
                        <TinyEditor
                            name={'content'}
                            handleEditorContent={handleChangeContent}
                            content={document.content??''}
                        />
                    </div>

                </div>
            </div>

        </>
    )
}