import React, {useState} from "react";

export const useCustomBank=(setErrors)=>{
    const [bank,setBank] = useState({name:null,description:null,isDefault:false});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setBank({...bank,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleDefaultBank=(data,name)=>{
        setBank(({...bank,[name]: data}));
    }

    return {
        handleValue,
        handleDefaultBank,
        bank,
        setBank
    }

}