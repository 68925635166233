export const convertPackage = (obj)=>{
    const newObj = {
        Id:obj.Id ?? null,
        Name:obj.Name === null || obj.Name === undefined ? null:obj.Name,
        Description:obj.Description === null || obj.Description === undefined? null : obj.Description,
        PackageGroupId: obj.PackageGroupId === null ||obj.PackageGroupId=== undefined ? null :obj.PackageGroupId.value,
        ThumbnailImage: obj.ThumbnailImage === null || obj.ThumbnailImage === undefined ? null:obj.ThumbnailImage,
        BannerImage:obj.BannerImage === null || obj.BannerImage === undefined ? null:obj.BannerImage,
        ThumbnailImagePath: obj.ThumbnailImagePath === null || obj.ThumbnailImagePath === undefined ? null:obj.ThumbnailImagePath,
        BannerImagePath:obj.BannerImagePath === null || obj.BannerImagePath === undefined ? null:obj.BannerImagePath,
        StateShow:obj.StateShow
    }
    return newObj
}

export const createPackageFormData = (obj) => {
    const formData = new FormData();
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
            if (key === "DiscountFrom" || key === "DiscountTo") {
                const date = new Date(obj[key]).toISOString();
                formData.append(key, date);
            } else {
                formData.append(key, obj[key]);
            }
        }
    }
    return formData;
}
