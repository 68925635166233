import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {InputTextarea} from "primereact/inputtextarea";
import {Dialog} from "primereact/dialog";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {useSecretary} from "./useSecretary";
import {useSecretaryGrid} from "./useSecretaryGrid";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {Export} from "../../../Commans/Grid/Export";
import {validate} from "../../../../Services/Globals/validate";
import {patternSchema} from "../MessagePattern/patternSchema";
import {handleConvertAddPattern} from "../MessagePattern/PatternService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {secretarySchema} from "./secretarySchema";
import {handleConvertAddSecretary, handleConvertEditSecretary} from "./secretaryService";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import Select from "react-select";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const HandleSmsSecretery=()=>{


  const redirectTo = useNavigate();
  const { t } = useTranslation();
  const [IsEdit,setIsEdit] = useState(false);
  const [IsShowSpinner,setIsShowSpinner] = useState(false);
  const [IsOpen,setIsOpen] = useState(false);
  const [refresh,setRefresh] = useState(false);
  const [errors,setErrors] = useState({});
  const [initialSource,setInitialSource] = useState({accounts:[]})

  const {sec,handleSelectValue,handleValue,setSec} = useSecretary(setErrors);


  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }
  const showSpinner=()=>{
    setIsShowSpinner(true);
  }
  // handles errors
  const onSetErrors=(errors)=>{
    setErrors(errors);
  }
  //refreshes component when it is called
  const Refresh=()=>{
    setRefresh(prevState => !prevState);
  }
  // resets all errors to nothing as initial state
  const handleClearErrors=()=>{
    setErrors({});
  }
  const handleOpenSecretary=()=>{
    setIsOpen(true);
    setSec({accountId: initialSource.accounts[0]});
  }
  const handleCloseSecretary=()=>{
    setIsOpen(false);
    setIsEdit(false);
    handleClearErrors();
    setSec({accountId: initialSource.accounts[0],message: '',pattern: ''});
  }

  const handleInitialData=(accounts)=>{
    const accountData = convertSelectDataHandler(accounts);
    return {accountData}
  }

  const fetchData=async ()=>{
    try {

      const responseAccount = await GET(`/Messaging/Accounts/ActiveAccounts`,{},'IEnumerable');
      if(responseAccount.status === 200){
        const {accountData}=  handleInitialData(responseAccount.data);
        setInitialSource({
          accounts: accountData
        })
      }

    }catch (e) {
      hideSpinner();
    }
  }
  useEffect(()=>{
    fetchData()
  },[])




  const handleSubmitSecretary=async(e)=>{
    e.preventDefault();
    const isValid = validate(secretarySchema,sec,onSetErrors);
    if(!isValid){
      return;
    }
    try {
      if(IsEdit){
        showSpinner();
        const secData = handleConvertEditSecretary(sec)
        const response = await PUT(`/Messaging/Secretaries`,secData,false);
        if(response.status === 200){
          handleCloseSecretary();
          handleSuccessMessage(t('SuccessDone'));
          Refresh();
        }
        else{
          handleApiError(response,hideSpinner);
        }
      }
      else{
        showSpinner();
        const secData = handleConvertAddSecretary(sec)
        const response = await POST(`/Messaging/Secretaries`,secData,false);
        if(response.status === 200){
          handleCloseSecretary();
          handleSuccessMessage(t('SuccessDone'));
          Refresh();
        }
        else{
          handleApiError(response,hideSpinner);
        }
      }
    }
    catch (e) {
      hideSpinner();
    }
  }













const {autoGroupColumnDef,columnDefs,defaultColDef,getRowHeight,handleCurrentPage,handleNextPage,handlePreviousPage,handlePageSizeCount,handleShowMessage,message,rowData,visible,setVisible,pagination} = useSecretaryGrid(showSpinner,hideSpinner,refresh,Refresh,setIsEdit,setSec,initialSource,setIsOpen);


  return(<>

    <Dialog header={t("MessageText")} visible={visible} className={"Dialog-ShowText"} onHide={() => setVisible(false)} >
      {message}
    </Dialog>

    <div class="space-y-5 relative">
      {
        IsShowSpinner?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony/>
              </div>
            </div>
            :
            null
      }
      <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
        <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={
                      <>
                        <ButtonPlus
                            onClick={() => handleOpenSecretary()}
                            title={t("Secretary")}
                            tooltipTitle={t("CreateSecretary")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/Send`)}
                            tooltipTitle={t("sendingMessage")}
                            title={t("sending")}
                            icon={"pi-send"}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/Inbox`)}
                            icon={"pi-inbox"}
                            title={t("Receive")}
                            tooltipTitle={t("receivingBox")}

                        />

                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SendBoxSms`)}
                            icon={"pi-inbox"}
                            title={t("sending")}
                            tooltipTitle={t("sendingBox")}

                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SmsPattern`)}
                            icon={"pi-code"}
                            title={t("Pattern")}
                            tooltipTitle={t("creatingATemplate")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                            icon={"pi-bolt"}
                            title={t("Charge")}
                            tooltipTitle={t("PanelRecharge")}

                        />

                      </>
                    }

        />

      </div>
      <div className={"space-y-2 H-body-ag-grid"} >


        <GridTable
            columnDefs={columnDefs}
            rowSelection={GridSetting.rowSelection}
            rowData={rowData}
            style={GridSetting.gridStyle}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={GridSetting.pagination}
            enableRtl={GridSetting.enableRtl}
            domLayout={GridSetting.domLayout}
            paginationPageSize={GridSetting.pageSize}
            getRowHeight={getRowHeight}
            localeText={AgGridEnglish}
        />
        <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
      </div>
    </div>

    <FantasyModal openModal={IsOpen}  closeModal={handleCloseSecretary} title={t("CreateSecretary")} >
      <form onSubmit={handleSubmitSecretary} method={'post'}>
        <div class="space-y-5">
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
              <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("senderNumber")}</label>
                <Select
                    options={initialSource.accounts}
                    isSearchable={true}
                    onChange={(data)=>handleSelectValue(data,'accountId')}
                    placeholder={t("selection")}
                    value={sec.accountId}
                    classNamePrefix={"h_multi_select"}
                />
              </div>
              <div class="space-y-2">
                <div className={"flex justify-between items-center "}>
                  <label className="text-xs dark:text-gray-300">
                    {t("MessageCode")}
                  <RequiredIcon/>
                  </label>
                  <DisplayError message={errors.pattern}/>
                </div>

                <InputText onInput={(e)=>handleValue(e)} value={sec.pattern} name={'pattern'} type={"text"} className={"input w-full"} />
              </div>
            </div>
            <div class="space-y-2">
              <div className={"flex justify-between items-center"}>
                <label className="text-xs dark:text-gray-300">
                  {t("DefaultText")}
                <RequiredIcon/>
                </label>
                <DisplayError message={errors.message}/>
              </div>
              <InputTextarea onInput={(e)=>handleValue(e)} value={sec.message} name={'message'}  rows={3} cols={30} className={"input w-full"}/>
            </div>
            <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
          </div>
      </form>
    </FantasyModal>

  </>)
}