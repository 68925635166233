import React from "react";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

export const Properties = ({ item, index, handleChange, handleRemove, IsEdit }) => {
    const { t } = useTranslation();

    return (
        <div>
            {IsEdit ? (
                <>
                    {typeof item.value === 'boolean' ? (
                        // Render checkbox input for boolean values
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{item.name}</label>
                            <div>
                                <input
                                    className="input input--switch border bg-white"
                                    onChange={(e) => handleChange(item.name, e.target.checked)}
                                    type="checkbox"
                                    checked={item.value}
                                />
                            </div>
                        </div>
                    ) : typeof item.value === 'number' ? (
                        // Render number input for number values
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{item.name}</label>
                            <div>
                                <input
                                    className="input w-full border bg-white"
                                    onChange={(e) => handleChange(item.name, parseFloat(e.target.value))}
                                    type="number"
                                    value={item.value}
                                />
                            </div>
                        </div>
                    ) : typeof item.value === 'string' ? (
                        // Render text input for string values (including single-character strings)
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{item.name}</label>
                            <div>
                                <input
                                    className="input w-full border bg-white"
                                    onChange={(e) => handleChange(item.name, e.target.value)}
                                    type="text"
                                    value={item.value}
                                />
                            </div>
                        </div>
                    ) : (
                        // Handle other unexpected types if necessary
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{item.name}</label>
                            <div>
                                <input
                                    className="input w-full border bg-white"
                                    onChange={(e) => handleChange(item.name, e.target.value)}
                                    type="text"
                                    value={item.value}
                                />
                            </div>
                        </div>
                    )}
                </>
            ) : (
                item.type === 'string' ? (
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{item.name}</label>
                        <InputText
                            className={"input w-full"}
                            onChange={(e) => handleChange(item.fakeId, item.name, e.target.value, item.id)}
                        />
                    </div>
                ) : item.type === 'bool' ? (
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{item.name}</label>
                        <div>
                            <input
                                className="input input--switch border bg-white"
                                onChange={(e) => handleChange(item.fakeId, item.name, e.target.checked, item.id)}
                                type="checkbox"
                            />
                        </div>
                    </div>
                ) : item.type === 'long' || item.type === 'int' || item.type === 'decimal' ? (
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{item.name}</label>
                        <div>
                            <input
                                className="input input--switch border bg-white"
                                onChange={(e) => {
                                    const value = parseInt(e.target.value, 10);
                                    if (!isNaN(value)) {
                                        handleChange(item.fakeId, item.name, value, item.id);
                                    } else {
                                        // Handle invalid input case, if necessary
                                    }
                                }}
                                type="number"
                            />
                        </div>
                    </div>
                ) : null
            )}
        </div>
    );
};
