import React, {useEffect} from "react";
import {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {useDefaultDashboardWidgets} from "../../../Dashboard/DefaultDashboards/useDefaultDashboardWidgets";
import {useDashboard} from "../../../Dashboard/useDashboard";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {Sale} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/Sale";
import {DashboardInvoiceRequest} from "../../../Dashboard/DashboardInvoiceRequest";
import {UserNote} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserNote";
import {UserComment} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserComment";
import {TabMenu} from "primereact/tabmenu";


export const HandleDashboardSalesManagement=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();

    const [activeTab, setActiveTab] = useState("tab_one");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = [
        {
            id: "tab_one",
            label:t("Agent"),
            icon: "users",
        },
        {
            id: "tab_two",
            label: t("Seller"),
            icon: "user",
        },
        {
            id: "tab_three",
            label: t("Marketer"),
            icon: "meh",
        },

    ];




    return (

        <div className={"relative min-h-screen"}>
            <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                </button>
                <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                    <div className="space-y-10">
                        <div>
                            <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    {t("GeneralReport")}
                                </h2>
                                <Link to="#" className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                    <HPrimeIcon
                                        icon={"pi-sync"}
                                    />
                                    {t("Reload")}
                                </Link>
                            </div>
                            <div className={`grid gap-x-5 gap-y-10 py-3 grid-cols-1 sm:grid-cols-2 ${classesToggled ? 'lg:grid-cols-4' : 'lg:grid-cols-3'}`}>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("ProductCounts")}
                                                    </h2>
                                                </div>
                                                <div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("NumberCustomers")}
                                                    </h2>
                                                </div>
                                                <div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>
                                                        10
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                       تعداد سرنخ ها
                                                    </h2>
                                                </div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        نمایندگان
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Sellers")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Marketers")}
                                                    </h2>
                                                </div>
                                                <div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        صورتحساب های امروز
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Paid")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("CancellationPurchase")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("SMSCharge")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        پیامک های ارسالی
                                                    </h2>
                                                </div>
                                                <div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        شبکه های مجازی فعال
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`grid grid-cols-1 sm:grid-cols-2 gap-3 ${classesToggled ? '' : 'lg:grid-cols-1'}`}>
                            <div>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>{t("MostVisitedPages")}</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className="max_height_25rem overflow-y-auto scrollbar-hidden space-y-3 p-3 box bg-white dark:bg-themeDarkSalt700">
                                        <Link to={"#"} target={"_blank"} className="flex items-center justify-between H-direction-ltr p-2 post-input post-input-design dark:bg-themeDarkPostInput">
                                            <div className={"max_width85"}>
                                                <p className="text-themeInformation line-clamp-1 text-xs dark:text-themeDarkInformation">www.arianteam.com</p>
                                            </div>
                                            <div className="flex items-center flex-shrink-0">
                                                <p className=" text-xs dark:text-gray-300">(100)</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>کاربران راکد (3ماهه)</h2>
                                </h2>
                                <div className={"py-3 "}>
                                    <div className={"post"}>
                                        <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                                        <div className={"post__content tab-content box bg-white dark:bg-themeDarkSalt700 p-2"}>
                                            <div className={`tab-content__pane space-y-5 ${ activeTab === "tab_one" ? "active" : "" }`}  id="tab_one">
                                                <div className={"max_height_20rem overflow-y-auto scrollbar-hidden"}>
                                                    <div className={"grid gap-3 grid-cols-1 lg:grid-cols-2 "}>
                                                        <div className="post-input post-input-design dark:bg-themeDarkPostInput flex items-center p-2 gap-3 ">
                                                            <div class="size-12 flex-shrink-0 rounded-full bg-white dark:bg-themeDarkSalt700 box overflow-hidden">
                                                                <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                                            </div>
                                                            <div class="flex-1 flex justify-between">
                                                                <div>
                                                                    <div>
                                                                        <p className="dark:text-gray-300">masoud ahmadi<span className={"text-xs"}></span></p>
                                                                    </div>
                                                                    <div class="text-xs line-clamp-1 text-gray-500">
                                                                        <div class="text-xs dark:text-gray-300 font-light">
                                                                            کاربر سایت
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={"flex gap-1 items-center"}>
                                                                    <Link to={"#"} className={"tooltip_class_generalManager"}>
                                                                        <HPrimeIcon icon={"pi-users"} iconClass={"dark:text-themeDarkInformation"}/>
                                                                        <Tooltip anchorSelect={".tooltip_class_generalManager"} content={t("generalManager")} />
                                                                    </Link>
                                                                    <Link to={`/Support/OnlineSupport?userId=`} className={"tooltip_class_Conversations"}>
                                                                        <HPrimeIcon icon={"pi-comments"} iconClass={"dark:text-themeDarkInformation"} />
                                                                        <Tooltip anchorSelect={".tooltip_class_Conversations"} content={t("Conversations")} />
                                                                    </Link>
                                                                    <Link to={`/Support/Ticket/List?userId=`} className={"tooltip_class_Tickets"}>
                                                                        <HPrimeIcon icon={"pi-file"} iconClass={"dark:text-themeDarkInformation"}/>
                                                                        <Tooltip anchorSelect={".tooltip_class_Tickets"} content={t("Tickets")} />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`tab-content__pane space-y-5 ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                                                <div className={"max_height_20rem overflow-y-auto scrollbar-hidden"}>
                                                    <div className={"grid gap-3 grid-cols-1 lg:grid-cols-2"}>
                                                        <div className="post-input post-input-design dark:bg-themeDarkPostInput flex items-center p-2 gap-3 ">
                                                            <div class="size-12 flex-shrink-0 rounded-full bg-white dark:bg-themeDarkSalt700 box overflow-hidden">
                                                                <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                                            </div>
                                                            <div class="flex-1 flex justify-between">
                                                                <div>
                                                                    <div>
                                                                        <p className="dark:text-gray-300">masoud ahmadi<span className={"text-xs"}></span></p>
                                                                    </div>
                                                                    <div class="text-xs line-clamp-1 text-gray-500">
                                                                        <div class="text-xs dark:text-gray-300 font-light">
                                                                            کاربر سایت
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={"flex gap-1 items-center"}>
                                                                    <Link to={"#"} className={"tooltip_class_generalManager"}>
                                                                        <HPrimeIcon icon={"pi-users"} iconClass={"dark:text-themeDarkInformation"}/>
                                                                        <Tooltip anchorSelect={".tooltip_class_generalManager"} content={t("generalManager")} />
                                                                    </Link>
                                                                    <Link to={`/Support/OnlineSupport?userId=`} className={"tooltip_class_Conversations"}>
                                                                        <HPrimeIcon icon={"pi-comments"} iconClass={"dark:text-themeDarkInformation"} />
                                                                        <Tooltip anchorSelect={".tooltip_class_Conversations"} content={t("Conversations")} />
                                                                    </Link>
                                                                    <Link to={`/Support/Ticket/List?userId=`} className={"tooltip_class_Tickets"}>
                                                                        <HPrimeIcon icon={"pi-file"} iconClass={"dark:text-themeDarkInformation"}/>
                                                                        <Tooltip anchorSelect={".tooltip_class_Tickets"} content={t("Tickets")} />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`tab-content__pane space-y-5 ${ activeTab === "tab_three" ? "active" : "" }`} id="tab_three">
                                                <div className={"max_height_20rem overflow-y-auto scrollbar-hidden"}>
                                                    <div className={"grid gap-3 grid-cols-1 lg:grid-cols-2"}>
                                                        <div className="post-input post-input-design dark:bg-themeDarkPostInput flex items-center p-2 gap-3 ">
                                                            <div class="size-12 flex-shrink-0 rounded-full bg-white dark:bg-themeDarkSalt700 box overflow-hidden">
                                                                <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                                            </div>
                                                            <div class="flex-1 flex justify-between">
                                                                <div>
                                                                    <div>
                                                                        <p className="dark:text-gray-300">masoud ahmadi<span className={"text-xs"}></span></p>
                                                                    </div>
                                                                    <div class="text-xs line-clamp-1 text-gray-500">
                                                                        <div class="text-xs dark:text-gray-300 font-light">
                                                                            کاربر سایت
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={"flex gap-1 items-center"}>
                                                                    <Link to={"#"} className={"tooltip_class_generalManager"}>
                                                                        <HPrimeIcon icon={"pi-users"} iconClass={"dark:text-themeDarkInformation"}/>
                                                                        <Tooltip anchorSelect={".tooltip_class_generalManager"} content={t("generalManager")} />
                                                                    </Link>
                                                                    <Link to={`/Support/OnlineSupport?userId=`} className={"tooltip_class_Conversations"}>
                                                                        <HPrimeIcon icon={"pi-comments"} iconClass={"dark:text-themeDarkInformation"} />
                                                                        <Tooltip anchorSelect={".tooltip_class_Conversations"} content={t("Conversations")} />
                                                                    </Link>
                                                                    <Link to={`/Support/Ticket/List?userId=`} className={"tooltip_class_Tickets"}>
                                                                        <HPrimeIcon icon={"pi-file"} iconClass={"dark:text-themeDarkInformation"}/>
                                                                        <Tooltip anchorSelect={".tooltip_class_Tickets"} content={t("Tickets")} />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={`grid grid-cols-2 md:grid-cols-3  gap-y-5 gap-1 ${classesToggled ? 'xl:grid-cols-6' : ''}`}>
                                <Sale
                                    title={'totalsales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-wallet'}
                                    data={dashboard.sales.totalSales}
                                />

                                <Sale
                                    title={'Thisyearsales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-credit-card'}
                                    data={dashboard.sales.currentYearSales}
                                />

                                <Sale
                                    title={'Quarterlysales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-arrow-right-arrow-left'}
                                    data={dashboard.sales.threeMonthsSales}
                                />
                                <Sale
                                    title={'Currentmonthsales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-calendar-plus'}
                                    data={dashboard.sales.currentMonthSales}
                                />
                                <Sale
                                    title={'Thisweeksales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-calendar'}
                                    data={dashboard.sales.currentWeekSales}
                                />
                                <Sale
                                    title={'TodaySales'}
                                    handleGetCurrency={GetCurrencyType}
                                    icon={'pi-dollar'}
                                    data={dashboard.sales.todaySales}
                                />

                        </div>
                        <div className={"-intro-x"}>
                            <div className="">
                                <h2 className="text-lg dark:text-gray-300">
                                   آخرین صورتحساب ها
                                </h2>
                            </div>
                            <div className={"py-3"}>
                                <DashboardInvoiceRequest/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("Notes")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            {
                                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                    <UserNote
                                        key={index}
                                        fullName={item.fullName}
                                        id={item.id}
                                        message={item.message}
                                        createdAt={item.createdAt}
                                    />
                                ))
                            }
                            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("UserComments")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 dashboard-note height_21rem overflow-y-auto scrollbar-hidden "}>
                                {
                                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                        <UserComment
                                            key={index}
                                            fullName={item.fullName}
                                            id={item.id}
                                            message={item.message}
                                            createdAt={item.createdAt}
                                            state={item.state}
                                        />
                                    ))
                                }
                            </div>
                            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center ">
                            <h2 className="text-lg dark:text-gray-300">
                                {t("ReleaseQueue")}
                                <span className={"text-xs dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                    <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                    </div>
                                    <div class="flex-1">
                                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("Draft")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}