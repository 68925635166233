import React, {useState} from "react";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSlugHandler} from "../../../CMS/Contents/ContentService";
import {handleCreateSlug} from "./productService";

export const useProduct=(setErrors,IsEdit)=>{

    const [product,setProduct]=useState({
        productId:null,
        name:null,
        slug:null,
        excerpt:null,
        editor:'',
        seoTitle:null,
        seoDescription:null,
        seoKeyWords:[],
        productSuggestionIds:[],
        releaseDateTime:null,
        discountOrCommissionType:null,
        discountAmount:0,
        discountFrom:null,
        discountTo:null,
        wholeSaleDiscountAmount:0,
        wholeSaleDiscountOrCommissionType:null,
        wholeSaleDiscountFrom:null,
        wholeSaleDiscountTo:null,
        displayOrderMaster:true,
        displayOrderSlave:0,
        inventoryMin:0,
        inventoryMax:0,
        affiliateScript:null,
        price:0,
        wholeSalePrice:0,
        purchasePrice:0,
        productCode:null,
        iranCode:null,
        barCode:null,
        productGroupIds:[],
        metaDataGroupIds:[],
        productTransportIds:[],
        type:null,
        maxContent:0,
        producerId:null,
        supplierId:null,
        templatePage:0,
        stateShow:false,
        stateAndroid:false,
        sendingStates:[],
        stateGoogle:false,
        isInventory:false,
        stateFreePost:false,
        image:null,
        imagePath:null,
        transportInsuranceCost:0,
        transportLength:0,
        transportWidth:0,
        transportHeight:0,
        transportWeight:0,
        isMetaDataGroupChanged:false,
        filterId:null,
        applyAuto:true,
        concurrencyStamp:null,
        currency:0,
        surveyGroupId:null
    })

    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency)
        {
            return '-'
        }
        return currency.label;
    }

    const handleUpdateSlug = (e) => {
        const { value } = e.target;
        const slug = handleCreateSlug(value);
        setProduct((prevState) => ({ ...prevState, slug: slug }));
    };
    const handleValue = (e) => {
        if(!IsEdit){
            const { name, value } = e.target;
            if (name === "name") {
                handleUpdateSlug(e);
                setProduct((prevState) => ({ ...prevState, seoTitle: value }));
            }
            setProduct((prevState) => ({ ...prevState, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }
        else{
            const { name, value } = e.target;
            setProduct((prevState) => ({ ...prevState, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }
    };

    const handleNumberValue = (value,name)=>{
        setProduct({...product,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setProduct({...product,[name]: data});
    }

    const handleRemoveImagePath=(path,name)=>setProduct((prevState)=>({...prevState,[name]:path}))
    const handleResetImages = () => {
        setProduct((prevState)=>({...prevState,image: null, imagePath: null}));
    }
    const handleChangeImage =(data,name)=>{
        setProduct((prevState) => ({ ...prevState, [name]: data }));
    }
    const handleKeywordsChange = (keywords) => {
        setProduct((prevState) => ({ ...prevState,seoKeyWords: keywords }));
    };
    const handleReleaseDateTime = (releaseDateTime)=>{
        setProduct({...product,releaseDateTime: releaseDateTime});
    }
    const handleDiscountFromDate = (fromDate)=>{
        setProduct({...product,discountFrom: fromDate});
    }
    const handleDiscountToDate = (toDate)=>{
        setProduct({...product,discountTo: toDate});
    }
    const handleWholeSaleDiscountFromDate = (fromDate)=>{
        setProduct({...product,wholeSaleDiscountFrom: fromDate});
    }
    const handleWholeSaleDiscountToDate = (toDate)=>{
        setProduct({...product,wholeSaleDiscountTo: toDate});
    }

    const handleChangeContent=(content)=>{
        setProduct({...product,editor: content});
    }

    const handleApplyIsAuto=(name,value)=>{
        if(IsEdit){
            setProduct({...product,[name]:value});
        }
        else{
            setProduct({...product,[name]:value,productCode: value === true?'':product.productCode});
        }
    }

    return {
        product,
        setProduct,
        handleValue,
        handleSelectValue,
        handleNumberValue,
        handleChangeContent,
        handleKeywordsChange,
        handleRemoveImagePath,
        handleChangeImage,
        handleResetImages,
        handleDiscountFromDate,
        handleDiscountToDate,
        handleReleaseDateTime,
        handleApplyIsAuto,
        handleWholeSaleDiscountToDate,
        handleWholeSaleDiscountFromDate,
        handleGetCurrencyType

    }
}