import React from "react";
import {InputText} from "primereact/inputtext";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const NsServer=({label,handleValue,name,value,key})=>{

    return (
        <>
            <div key={key} className={" space-y-1"}>
                <label className={"text-xs dark:text-gray-300"}>{label}</label>
                <div className={"relative"}>
                    <InputText className={"input w-full"} onInput={(e)=>handleValue(e)} name={name} value={value}  />
                    <div className={"absolute top-1/2 -translate-y-1/2 end-2"}>
                        <button  className={"button bg-themeInformation"}>
                            <HPrimeIconSmall
                                icon={"pi-copy"}
                                iconClass={"text-white"}
                            />
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}