import React from "react";
import Joi from "joi";
export const productSchema = Joi.object({
    title: Joi.string().required().trim().messages({
        'any.required': 'عنوان محصول الزامیست',
        'string.empty': 'عنوان محصول الزامیست',
        'string.base': 'عنوان محصول الزامیست'
    }),
    slug: Joi.string().required().trim().messages({
        'any.required': 'ادرس محصول الزامیست',
        'string.empty': 'ادرس محصول الزامیست',
        'string.base': 'ادرس محصول الزامیست'
    }),
});
