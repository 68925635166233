import React, {

} from 'react';


// import 'ag-grid-enterprise';
// import 'ag-grid-community/styles/ag-grid.css';
// import 'ag-grid-community/styles/ag-theme-alpine.css';
import Select from "react-select";

const Charts = () => {

    const countryOptions = [
        { value: 'us',  icon: <div className={'bg_Fa'} /> },
        { value: 'gb',  icon: <div className={'bg_En'} /> },
        // Add more countries with their respective icons
    ];
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            padding: 10,
            display: 'flex',
            alignItems: 'center',
        }),
        // Add more styles as needed
    };


        return (
            <div>
                <Select
                    options={countryOptions.map((option) => ({
                        ...option,
                        label: (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {option.icon}
                                <span style={{ marginLeft: '8px' }}>{option.label}</span>
                            </div>
                        ),
                    }))}
                    styles={customStyles}
                   />

            </div>


        );




}
export default Charts;
