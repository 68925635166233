import React, {useState} from "react";


import {HPrimeIconSmall} from "./HPrimeIconSmall";
import {HPrimeIcon} from "./HPrimeIcon";

// this is a TabMenu component .
// handling just a tags as its menu .
// receives an object of data based on counts of contents .

export const TabMenu = ({ tabs, activeTab, handleTabClick,className }) => {
    return (
        <div className="post__tabs nav-tabs flex overflow-x-auto scrollbar">
            {tabs.map((tab) => (
                <div key={tab.id} title={tab.title}  className={`tab_header w-full flex-1 py-3 text-center flex cursor-pointer justify-center items-center md:gap-2 text-nowrap  ${className}  ${ activeTab === tab.id ? "active" : ""}`}
                    onClick={() => handleTabClick(tab.id)} >
                    <HPrimeIcon iconClass={'hidden md:flex '} icon={tab.icon}/>
                    {
                        tab.label?
                             <p className={"text-xs"}>
                                {tab.label}
                            </p>
                            :
                            <p className="min-w-[5rem] text-xs md:min-w-max">{tab.subLabel}</p>}
                </div>
            ))}
        </div>
    );
};

