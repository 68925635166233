import React from "react";

import {Link, useNavigate} from "react-router-dom";
import {HPrimeIconSmall} from "../../UIParts/HPrimeIconSmall";
export const QuickAccessItemTwo=({title,icon,slug,handleDeleteSpeedLink,speedLinkId,index,target})=>{
    const navigateTo = useNavigate();

    const redirectTo=(url,target)=>{
        if(target === 0){
            navigateTo(url)
        }else if(target === 1){
            window.open(url,'_blank');
        }
    }

    return (<>

        <li key={title} className={"relative"}>
            <button
                onClick={()=>redirectTo(slug,target)}
                className="menu"
                style={{ animationDelay: `${(index+1) * 0.1}s`} }
            >
                <div className="side-menu__icon">
                    <HPrimeIconSmall icon={icon} />
                </div>
                <div className="side-menu__title">{title}</div>
            </button>


            <button onClick={()=>handleDeleteSpeedLink(speedLinkId)} className={"absolute top-1/2 -translate-y-1/2 end-2"}>
                <HPrimeIconSmall icon={"pi-times"}  />
            </button>
        </li>

    </>)
}