export const convertBankDataHandler = (obj,id,concurrencyStamp)=>{
    const newData = {
       // userId:id,
        concurrencyStamp:concurrencyStamp,
        userBanks: obj.map(item => ({
            bankId: item.bankName !== null? item.bankName.value:null,
            cardNumber:  item.cardNumber,
            shebaNumber: item.shebaNumber,
            accountNumber: item.accountNumber,
            isDefault:   item.isDefault,
            userBankId: item.userBankId
        }))
    };
    return newData
}