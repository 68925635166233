import React, {useState} from "react";
import {GET} from "../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../CMS/Contents/ContentService";
import {handleApiError} from "../../Services/Globals/Errors/handleApiError";
import {handleFailureMessage} from "../../Services/Globals/Errors/NotificationService";

export const useMailAccount=(setErrors)=>{
    const [mailAccount,setMailAccount]=useState({mailAccountId:null,email:null,password:null,incomingServer:null,incomingPort:0,incomingEncryptionMethod:null,
        outgoingServer:null,outgoingPort:0,outgoingEncryptionMethod:null,connectionType:null,isDefault:false
    });
    const handleSelectValue = async (data, name) => {
        setMailAccount((prevState) => ({ ...prevState, [name]: data }));
    };

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setMailAccount({...mailAccount,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setMailAccount({...mailAccount,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    return {
        handleValue,
        handleNumberValue,
        handleSelectValue,
         mailAccount,
         setMailAccount
    }
}