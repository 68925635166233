import React from "react";
import {useTranslation} from "react-i18next";
export const ContactUs=({userContact})=>{
    const { t } = useTranslation();
    return (
        <>
            <div >
                <div class="flex items-center border-b dark:border-themeDarkColorLine">
                    <div>
                        <ul class="flex gap-2 items-center justify-center p-2">
                            <li>
                                <img src={userContact && userContact.picture?userContact.picture:"/Assets/images/user/person1.png"} className="size-10 bg-white dark:bg-themeDarkSalt700 block rounded-full overflow-hidden object-cover shadow"
                                     alt="user" title="user"/>
                            </li>
                            <li className="flex gap-1">
                                <h3 className="text-xs dark:text-gray-300">{userContact && userContact.name}</h3>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="p-2 space-y-2 max_height30">
                    {userContact && userContact.communications ? (
                        userContact.communications.map((item, index) => (
                            <div key={index}  className="flex items-center gap-2 border dark:border-themeDarkColorLine p-3 bg-white dark:bg-themeDarkSalt700 rounded-md">
                                <div className="w-20 border-e dark:border-themeDarkColorLine">
                                    <h3 className=" text-xs dark:text-gray-300">{item.label}</h3>
                                </div>
                                <div className="flex-1">
                                    <a href={`mailto:${item.value}`} className="text-xs text-themeInformation dark:text-themeDarkInformation" target="_blank">{item.value}</a>
                                </div>
                            </div>
                        ))
                    ) : (
                        t("Loading")
                    )}
                </div>

            </div>
        </>
    )
}