import { useState } from "react";
export const useInsurance = (setErrors) => {
    const [insurance, setInsurance] = useState({
        userId: null,
        insuranceId: null,
        employeeId: null,
        insurancePosition: null,
        insuranceType: null,
        insuranceNumber: null,
        startDate: null,
        endDate: null,
        description: null,
    })
    const handleInsuranceStartDate = (dateTime) => {
        setInsurance({ ...insurance, startDate: dateTime })
    }
    const handleInsuranceEndDate = (dateTime) => {
        setInsurance({ ...insurance, endDate: dateTime })
    }

    const handleSelectValue = (data, name) => {
        setInsurance({ ...insurance, [name]: data });
    }

    const handleValue = (e) => {
        const { name, value } = e.target;
    
        if (name === 'insuranceNumber') {
            const numericValue = value.replace(/\D/g, '');
            setInsurance({ ...insurance, [name]: numericValue });
        } else {
            setInsurance({ ...insurance, [name]: value });
        }
    
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };


    //.....

    return {
        insurance,
        setInsurance,
        handleInsuranceStartDate,
        handleInsuranceEndDate,
        handleSelectValue,
        handleValue
    }

}