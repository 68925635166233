import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useCustomUserBank} from "./useCustomUserBank";
import {UserBank} from "./UserBank";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {
    handleCheckDefaultBankId,
    handleCheckDefaultUserBanks,
    handleConvertUserBanks,
    handleGetNoneNullUserBanks,
    handleGetUserBanks
} from "./userBankService";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";

export const Banks=()=>{
    const { t } = useTranslation();
    const [refresh,setRefresh] = useState(false);
    const [initialSource,setInitialSource] = useState({Banks:[]})
    const {bankList,handleRemoveRow,handleAddRow,handleChange,setBankList,setConcurrencyStamp,concurrencyStamp} = useCustomUserBank(initialSource);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const handleInitialSource=(banks)=>{
        const bankData = convertSelectDataHandler(banks);
        setInitialSource({
            Banks: bankData
        })
        return {bankData}
    }
    const fetchData=async()=>{
        try {
            showSpinner();
            const userBanks = await GET(`/Banks`,{},'IEnumerable');
            const responseUserBank = await GET(`/Users/Banks`,{},'single');
            if(userBanks.status === 200 && responseUserBank.status === 200){
                const {bankData} = handleInitialSource(userBanks.data);
                if(responseUserBank.data.userBanks.length === 0){
                    setConcurrencyStamp(responseUserBank.data.concurrencyStamp);
                    setBankList([{ id: new Date().getTime(), userBankId: null, bankId:bankData[0], accountNumber: null, cardNumber: null, shebaNumber: null, isDefault: false,}])
                }
                else{
                    setConcurrencyStamp(responseUserBank.data.concurrencyStamp);
                    const userBanks = handleGetUserBanks(responseUserBank.data.userBanks,bankData);
                    setBankList(userBanks.map((row) => ({ ...row, userBankId:row.userBankId})));
                }
                hideSpinner();
            }
            else{
                handleApiError(userBanks,hideSpinner)
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    const handleSubmitUserBank=async()=>{
      try {

          const hasDefaultBank =handleCheckDefaultUserBanks(bankList);
          const allBanksHaveBankId = handleCheckDefaultBankId(bankList);
          const noneNullUserBanks = handleGetNoneNullUserBanks(bankList);
          if(!allBanksHaveBankId){
              handleFailureMessage(t("Choosethebankname"));
              return;
          }
          if (hasDefaultBank !== 1) {
              handleFailureMessage(t("Selectdefaultbank"));
              return;
          }
          showSpinner();
          const userBanks = handleConvertUserBanks(noneNullUserBanks,concurrencyStamp);
          const response = await POST(`/Users/Banks`,userBanks,false);
          if(response.status === 200){
                 Refresh();
                 hideSpinner();
                 handleSuccessMessage(t('SuccessDone'))
          }
          else{
              handleApiError(response,hideSpinner);
          }
      }
      catch (e) {
          console.log(e);
          hideSpinner();
      }
    }

    useEffect(()=>{
        fetchData();
    },[refresh])

    return (
        <>
            <div className={"space-y-3 relative"}>
            {
                IsShowSpinner ?
                    <div className="huploader_loading">
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }

            <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                <p className={"text-xs dark:text-gray-300"}>{t("ListOfBanks")}</p>
            </div>
            <div className="px-2 space-y-5"> {/* Change to flex-col */}
                {bankList.length > 0 ? bankList.map((item,index) => (
                    <UserBank
                        index={index}
                        row={item}
                        handleChange={handleChange}
                        handleRemoveRow={handleRemoveRow}
                        handleAddRow={handleAddRow}
                        initialSource={initialSource}
                    />
                )) : null}
                {/*<button onClick={handleSubmitUserBank} className="button w-24 text-white bg-themeInformation mt-2 md:mt-0 md:mr-2 self-start"> /!* Change to self-start *!/*/}
                {/*    {t("register")}*/}
                {/*</button>*/}
                <ButtonSubmitAndCancel
                    onRegisterClick={()=>handleSubmitUserBank()}
                    showCancelButton={true}
                />
            </div>

        </div>
            <ToastContainer/>
        </>
    )
}

