import React, {useEffect} from "react";
import {useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Tooltip} from "react-tooltip";

import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {ChartHarmony} from "../../../Dashboard/ChartHarmony";
import {EntryAndExitListPersonnel} from "../../../Dashboard/EntryAndExitListPersonnel";
import {DashboardInvoiceRequest} from "../../../Dashboard/DashboardInvoiceRequest";
import {UserNote} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserNote";
import {UserComment} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserComment";
import {useDefaultDashboardWidgets} from "../../../Dashboard/DefaultDashboards/useDefaultDashboardWidgets";
import {useDashboard} from "../../../Dashboard/useDashboard";

export const HandleAdminDashboardArian=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();




    return (

        <div className={"min-h-screen relative"}>
            <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 ">
                <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                </button>
                <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                    <div className="space-y-10">
                        <div>
                            <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                <h2 className="text-base dark:text-gray-300 -intro-x">
                                    {t("GeneralReport")}
                                </h2>
                                <Link to={"#"} className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                    <HPrimeIcon
                                        icon={"pi-sync"}
                                    />
                                    {t("Reload")}
                                </Link>
                            </div>
                            <div className={`grid gap-x-5 gap-y-10 py-3 grid-cols-1 sm:grid-cols-2 ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("ExpiringDomains")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("ExpiringWebsites")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>
                                                            10
                                                        </p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("SMSCharge")}
                                                    </h2>
                                                </div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Expiring")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`grid grid-cols-1 sm:grid-cols-2 gap-3 ${classesToggled ? '' : 'lg:grid-cols-1'} `}>
                            <div className={"-intro-x"}>
                                <div className="flex items-center relative justify-between">
                                    <h2 className="text-base dark:text-gray-300">
                                        آمارو بازدید سایت
                                    </h2>
                                    <Link to="/Statistics" className="text-themeInformation  dark:text-themeDarkInformation">({t("viewing")})</Link>
                                </div>
                                <div className="py-3">
                                    <div className="box bg-white dark:bg-themeDarkSalt700 p-5 ">
                                        <div className="flex justify-around items-center">
                                            <div className="flex-1 text-center">
                                                <div className=" text-center dark:text-gray-300">{t("MonthlyStatistics")}</div>
                                                <div className="text-themeInformation dark:text-themeDarkInformation text-base">
                                                    {dashboard.statistics.monthly}
                                                </div>
                                            </div>
                                            <div className="border-x border-dashed border-gray-300 flex-1 text-center">
                                                <div className="text-center dark:text-gray-300">{t("Daily")}</div>
                                                <div className="text-themeInformation dark:text-themeDarkInformation text-base">
                                                    {dashboard.statistics.daily}
                                                </div>
                                            </div>
                                            <div className="flex-1 text-center ">
                                                <div className="text-center dark:text-gray-300">{t("Weekly")}</div>
                                                <div className="text-themeInformation dark:text-themeDarkInformation text-base">
                                                    {dashboard.statistics.weekly}
                                                </div>
                                            </div>
                                        </div>
                                        <ChartHarmony options={chartData}  apiUrl={''} className={''} style={{ width: '100%', height: '350px' }} />
                                    </div>
                                </div>
                            </div>
                            <div className={"-intro-x"}>
                                <div className="flex items-center justify-between ">
                                    <h2 className="text-base dark:text-gray-300">
                                        {t("LastCustomers")}
                                    </h2>
                                    <Link to="/CRM/User/List" className="text-themeInformation dark:text-themeDarkInformation"> ({t("viewing")})</Link>
                                </div>
                                <div className={"height_32rem overflow-y-auto scrollbar-hidden py-3"}>
                                    <div class={`grid gap-2 grid-cols-1 ${classesToggled ? 'xl:grid-cols-2' : 'lg:grid-cols-2'}`}>
                                        <div class="box dark:bg-themeDarkSalt700 bg-white relative flex items-center p-2 gap-3 ">
                                            <div class="size-12 flex-shrink-0 bg-white dark:bg-themeDarkSalt700  border shadow rounded-full">
                                                <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                            </div>
                                            <div class="flex-1 flex justify-between">
                                                <div>
                                                    <div>
                                                        <p className="dark:text-gray-300">masoud ahmadi<span className={"text-xs"}></span></p>
                                                    </div>
                                                    <div class="text-xs line-clamp-1 text-neutral-500">
                                                        <div class="text-xs dark:text-gray-300 font-light">
                                                            کاربر سایت
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"flex gap-1 items-center"}>
                                                    <Link to={"#"} className={"tooltip_class_generalManager"}>
                                                        <HPrimeIcon
                                                        icon={"pi-user"}
                                                        iconClass={"dark:text-themeDarkInformation"}
                                                        />
                                                        <Tooltip anchorSelect={".tooltip_class_generalManager"} content={t("generalManager")} />
                                                    </Link>
                                                    <Link to={`/Support/OnlineSupport?userId=`} className={"tooltip_class_Conversations"}>
                                                        <HPrimeIcon
                                                            icon={"pi-comment"}
                                                            iconClass={"dark:text-themeDarkInformation"}
                                                        />
                                                        <Tooltip anchorSelect={".tooltip_class_Conversations"} content={t("Conversations")} />
                                                    </Link>
                                                    <Link to={`/Support/Ticket/List?userId=`} className={"tooltip_class_Tickets"}>
                                                        <HPrimeIcon
                                                            icon={"pi-ticket"}
                                                            iconClass={"dark:text-themeDarkInformation"}
                                                        />
                                                        <Tooltip anchorSelect={".tooltip_class_Tickets"} content={t("Tickets")} />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"-intro-x"}>
                            <div className="flex items-center justify-between ">
                                <h2 className="text-base dark:text-gray-300">
                                    {t("LatestWebsites")}
                                </h2>
                                <Link to="/CRM/User/List" className="text-themeInformation dark:text-themeDarkInformation"> ({t("viewing")})</Link>
                            </div>
                            <div className={"height_17rem overflow-y-auto scrollbar-hidden py-3 "}>
                                <div class={`grid gap-2 grid-cols-1  ${classesToggled ? 'lg:grid-cols-3 xl:grid-cols-4' : 'lg:grid-cols-3'}  `}>
                                    <div class="box dark:bg-themeDarkSalt700 bg-white relative flex items-center p-3 gap-3 ">
                                        <div class="flex-1 flex justify-between">
                                            <div>
                                                <div>
                                                    <Link to={"#"} target={"_blank"} className="text-themeInformation dark:text-themeDarkInformation hover:underline">www.raygan.com</Link>
                                                </div>
                                                <div class="text-xs line-clamp-1 text-neutral-500">
                                                    <div class="text-xs dark:text-gray-300 font-light">
                                                        سینا رایگان
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"flex gap-1 items-center"}>
                                                <Link to={"#"} className={"tooltip_class_History"}>
                                                    <HPrimeIcon icon={"pi-clock"} iconClass={"dark:text-themeDarkInformation"} />
                                                    <Tooltip anchorSelect={".tooltip_class_History"} content={t("History")} />
                                                </Link>
                                                <Link to={"#"} className={"tooltip_class_Upgrade"}>
                                                    <HPrimeIcon icon={"pi-comments"} iconClass={"dark:text-themeDarkInformation"} />
                                                    <Tooltip anchorSelect={".tooltip_class_Upgrade"} content={t("Conversations")} />
                                                </Link>
                                                <Link to={""} className={"tooltip_class_bill"}>
                                                    <HPrimeIcon icon={"pi-file-edit"} iconClass={"dark:text-themeDarkInformation"}/>
                                                    <Tooltip anchorSelect={".tooltip_class_bill"} content={t("Bill")} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"-intro-x"}>
                            <div className={" flex items-center relative justify-between"}>
                                <div className={"text-base dark:text-gray-300"}>
                                    <h2>ورود خروج پرسنل</h2>
                                </div>
                            </div>
                            <div className={"py-3"}>
                                <EntryAndExitListPersonnel/>
                            </div>
                        </div>
                        <div className={"-intro-x"}>
                            <div>
                                <h2 className="text-base dark:text-gray-300">
                                   آخرین صورتحساب ها
                                </h2>
                            </div>
                            <div className={"py-3"}>
                                <DashboardInvoiceRequest/>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                    <div>
                        <div>
                            <h2 className="text-base dark:text-gray-300">
                                {t("Notes")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            {
                                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                    <UserNote
                                        key={index}
                                        fullName={item.fullName}
                                        id={item.id}
                                        message={item.message}
                                        createdAt={item.createdAt}
                                    />
                                ))
                            }
                            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("UserComments")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 dashboard-note h-height300 overflow-y-auto scrollbar-hidden "}>
                                {
                                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                        <UserComment
                                            key={index}
                                            fullName={item.fullName}
                                            id={item.id}
                                            message={item.message}
                                            createdAt={item.createdAt}
                                            state={item.state}
                                        />
                                    ))
                                }
                            </div>
                            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center ">
                            <h2 className="text-lg dark:text-gray-300">
                                {t("ReleaseQueue")}
                                <span className={"text-xs dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                    <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                    </div>
                                    <div class="flex-1">
                                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("Draft")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}