import React from 'react';

const TwitterIcon = ({sizeStyle,fillStyle}) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className={`${sizeStyle ? sizeStyle:"w-10 h-10"} ${fillStyle ? fillStyle:"text-themeTwitter"} dark:text-gray-300 `}  role="img" >
        <g clipPath="url(#clip0_401_5)">
            <path fill="currentColor"
                d="M40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20Z"

            />
            <path className={"fill-white dark:fill-black"}
                d="M21.6649 18.6601L28.1796 11.25H26.6359L20.9791 17.684L16.461 11.25H11.25L18.0822 20.9794L11.25 28.75H12.7939L18.7676 21.9554L23.539 28.75H28.75L21.6645 18.6601H21.6649ZM19.5503 21.0651L18.8581 20.0963L13.3502 12.3872H15.7215L20.1664 18.6087L20.8587 19.5775L26.6366 27.6645H24.2653L19.5503 21.0655V21.0651Z"

            />
        </g>
    </svg>

    // <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className={`${sizeStyle ? sizeStyle:"w-10 h-10"} ${fillStyle ? fillStyle:"text-themeTwitter"} dark:text-gray-300 `}  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    //     <path fill="currentColor" d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"></path>
    // </svg>
);

export default TwitterIcon;
