export const handleConvertAddUserRole = (obj)=>{
    const newObj = {
        name: obj.name === null || obj.name === undefined?null:obj.name,
        parentId: obj.parentId === null || obj.parentId === undefined ? null:obj.parentId.value,
    }
    return newObj
}
export const handleConvertEditUserRole = (obj)=>{
    const newObj = {
        id:obj.id,
        name: obj.name === null || obj.name === undefined?null:obj.name,
        concurrencyStamp: obj.concurrencyStamp === null || obj.concurrencyStamp === undefined?null:obj.concurrencyStamp,
        parentId: obj.parentId === null || obj.parentId === undefined ? null:obj.parentId.value,
    }
    return newObj
}
export const handleConvertToTree = (data) => {
    const tree = [];
    const map = data.reduce((acc, obj) => {
        obj.children = [];
        acc[obj.id] = obj;
        obj.subtitle = obj.name;
        obj.expanded = true;
        return acc;
    }, {});
    data.forEach((obj) => {
        const parent = map[obj.parentId];
        if (parent) {
            parent.children.push(obj);
        }
        else {
            tree.push(obj);
        }
    });
    return tree;
};
export const setExpandedById = (treeData, parentId) => {
    for (let node of treeData) {
        if (node.id === parentId) {
            node.expanded = true;
            return true;
        }
        if (node.children) {
            if (setExpandedById(node.children, parentId)) {
                node.expanded = true; // Expand the parent node
                return true;
            }
        }
    }
    return false;
};
