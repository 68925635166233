import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import {DateObject} from "react-multi-date-picker";

export const useFormBuilder = (handleChangeTab) => {
    const { t } = useTranslation();
    const [elements, setElements] = useState([]);
    const [selectedElementIndex, setSelectedElementIndex] = useState(null);

    const fields=[
        { categoryName: t('Standard'),category:true ,isButton:false},
        { type: 0, label: t('SingleLineText'),typeName:'TextBox',isButton:true},
        { type: 1, label: t('Paragraph') ,typeName:'TextArea',isButton:true},
        { type: 2, label: t('Numeric'),typeName:'Numeric' ,isButton:true},
        { type: 3, label: t('MultiSelect'),typeName:'MultiSelect',isButton:true},
        { type: 4, label: t('Select'),typeName:'Select',isButton:true},
        { type: 5, label: t('Radio'),typeName:'Radio',isButton:true},
        { type: 6, label: t('CheckBox'),typeName:'CheckBox',isButton:true},
        { type: 7, label: t('SelectionBreak'),typeName:'SelectionBreak',isButton:true},
        { type: 8, label: t('File'),typeName:'File',isButton:true},
        { categoryName: t('special'),category:true ,isButton:false},
        { type:17, label: t('Date'),typeName:'Date',isButton:true},
        { type:18, label: t('Time'),typeName:'Time',isButton:true},
        { type:19, label: t('DateTime'),typeName:'DateTime',isButton:true},
        { type:20, label: t('Url'),typeName:'Url',isButton:true},
        { type:10, label: t('Email'),typeName:'Email',isButton:true},
        { type:11, label: t('Phone'),typeName:'Phone',isButton:true},
        { type:12, label: t('Image'),typeName:'Image',isButton:true},
        { type:13, label: t('Video'),typeName:'Video',isButton:true},
        { type:14, label: t('Audio'),typeName:'Audio',isButton:true},
        { type:16, label: t('Slider'),typeName:'Slider',isButton:true},
        { type: 9,  label: t('Location'),typeName:'Location',isButton:true},
        { type:15, label: t('Price'),typeName:'Price',isButton:true},
        { type:21, label: t('Color'),typeName:'Color',isButton:true},
        { type:22, label: t('Password'),typeName:'Password',isButton:true},
    ];

    const addElement = (type,typeName) => {
        const newOrder = elements.length > 0 ? Math.max(...elements.map(el => el.properties.order)) + 1 : 0;
        const newElement = {
            type: type,
            typeName:typeName,
            formItemId: null,
            properties: { ...handleGetExclusiveProperties(typeName), order: newOrder },
        };

        const updatedElements = [...elements, newElement].sort((a, b) => a.properties.order - b.properties.order);
        setElements(updatedElements);

        handleChangeTab('property')
        setSelectedElementIndex(updatedElements.length -1)
    };

    const deleteElement = (index) => {
        setElements((prevElements) => {
            const updatedElements = prevElements.filter((_, idx) => idx !== index);

            setSelectedElementIndex((prevSelectedIndex) => {
                if (updatedElements.length === 0) {
                    return null;
                } else if (prevSelectedIndex === index) {
                    return null;
                } else if (prevSelectedIndex > index) {
                    return prevSelectedIndex - 1;
                }
                return prevSelectedIndex;
            });

            return updatedElements;
        });
    };

    const handlePropertyChange = (index, property, value) => {
        setElements(prevElements => {
            const newElements = [...prevElements];
            newElements[index] = {
                ...newElements[index],
                properties: {
                    ...newElements[index].properties,
                    [property]:value,
                },
            };
            return newElements;
        });
    };
    const handleDefaultValueChange = (index, property, value) => {
        if(value instanceof DateObject){
            value = value.toDate();
            setElements(prevElements => {
                const newElements = [...prevElements];
                newElements[index] = {
                    ...newElements[index],
                    properties: {
                        ...newElements[index].properties,
                        defaultValue: {
                            ...newElements[index].properties.defaultValue,
                            [property]: value,
                        },
                    },
                };
                return newElements;
            });

        }
        else{
            setElements(prevElements => {
                const newElements = [...prevElements];
                newElements[index] = {
                    ...newElements[index],
                    properties: {
                        ...newElements[index].properties,
                        defaultValue: {
                            ...newElements[index].properties.defaultValue,
                            [property]: value,
                        },
                    },
                };
                return newElements;
            });
        }

    };
    const SortAccending = (index) => {
        if (index > 0) {
            const updatedElements = [...elements];
            const tempOrder = updatedElements[index].properties.order;
            updatedElements[index].properties.order = updatedElements[index - 1].properties.order;
            updatedElements[index - 1].properties.order = tempOrder;
            updatedElements.sort((a, b) => a.properties.order - b.properties.order);
            setElements(updatedElements);
        }
    };
    const SortDescending = (index) => {
        if (index < elements.length - 1) {
            const updatedElements = [...elements];
            const tempOrder = updatedElements[index].properties.order;
            updatedElements[index].properties.order = updatedElements[index + 1].properties.order;
            updatedElements[index + 1].properties.order = tempOrder;
            updatedElements.sort((a, b) => a.properties.order - b.properties.order);
            setElements(updatedElements);
        }
    };

    const handleGetExclusiveProperties = (typeName) => {
        switch (typeName) {
            case 'TextBox':
                return { min: 0,max: 0,order: 0, name: '', isReadOnly: false, isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:''}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'TextArea':
                return { min: 0, max: 0, order: 0, name: '', isReadOnly: false, isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:''}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Numeric':
                return { min: 0, max: 0, order: 0, name: '', isReadOnly: false, isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:0}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'MultiSelect':
                return {order: 0, name: '',items:[{label:null,value:null,parentId:null,order:1}], isReadOnly: false, isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:[]}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Select':
                return {order: 0, name: '',items:[{label:null,value:null,parentId:null,order:1}], isReadOnly: false, isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'CheckBox':
                return {order: 0, name: '',items:[{label:null,value:null,parentId:null,order:1}], isReadOnly: false, isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Radio':
                return {order: 0, name: '',items:[{label:null,value:null,parentId:null,order:1}], isReadOnly: false, isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'SelectionBreak':
                return {isReadOnly:false, isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'File':
                return {isReadOnly: false,extensions:'',folderSource:'',min:0,max:0, isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Location':
                return {order: 0,isReadOnly:false,countries:[],states:[],cities:[],isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{stateProvinceId:null,countryId:null,cityId:null,address:''}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Email':
                return {order: 0,isReadOnly:false,regex:'',isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Phone':
                return {order: 0,isReadOnly:false,regex:'',isRequired: false,preNumbers:[{label:null,value:null,parentId:null,order:1}], caption: t('Title'), description: '', className: '',defaultValue:{value:'',preNumber:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Image':
                return {order: 0,isReadOnly:false,extensions:'',folderSource:'',isRequired: false,min:0,max:0, caption: t('Title'), description: '', className: '',defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Video':
                return {order: 0,isReadOnly:false,extensions:'',folderSource:'',min:0,max:0,isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Audio':
                return {order: 0,isReadOnly:false,extensions:'',folderSource:'',min:0,max:0,isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Price':
                return {order: 0,isReadOnly:false,format:'',max:0,min:0,useGrouping:false,isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:0}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Slider':
                return {order: 0,isReadOnly:false,max:0,min:0,isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:0}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Date':
                return {order: 0,format: 'YYYY/MM/DD',calendarView:null,calendarType:null,calendarLanguage:null,isReadOnly:false,max:0,min:0,isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:new Date()}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '',name:'' };
            case 'Time':
                return {order: 0,format:'HH:mm:ss',isReadOnly:false,max:0,min:0,isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:new Date()}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '',name:'' };
            case 'DateTime':
                return {order: 0,format:'YYYY/MM/DD HH:mm:ss',calendarView:null,calendarType:null,calendarLanguage:null,isReadOnly:false,max:0,min:0,isRequired: false, caption: t('Title'), description: '', className: '',defaultValue:{value:new Date()}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '',name:'' };
            case 'Url':
                return {order: 0,isReadOnly:false,regex:'',isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:null}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Color':
                return {order: 0,isReadOnly:false,isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:''}, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            case 'Password':
                return {order: 0,isReadOnly:false,max:0,min:0,isRequired: false, caption: t('Title'), description: '', className: '', defaultValue:{value:''},difficulty:null, size: {value:0,label:t("Small"),className:'harmony-col-span-1'}, id: '' };
            default:
                return {};
        }
    };
    const addCheckBoxItem = (elementIndex, propertyName) => {
        const currentItems = elements[elementIndex].properties[propertyName];
        const newOrder = currentItems.length > 0 ? Math.max(...currentItems.map(item => item.order)) + 1 : 1;
        const newItems = [...currentItems, { label: null, value: null, parentId: null, order: newOrder }];
        handlePropertyChange(elementIndex, propertyName, newItems);
    }

    const deleteCheckBoxItem = (elementIndex, itemIndex, propertyName) => {
        const currentItems = elements[elementIndex].properties[propertyName];
        if (currentItems.length > 1) {
            const newItems = currentItems
                .filter((_, idx) => idx !== itemIndex)
                .map((item, idx) => ({ ...item, order: idx + 1 }));
            handlePropertyChange(elementIndex, propertyName, newItems);
        } else if (currentItems.length === 1) {
            const nullifiedItem = { label: '', value: '', parentId: null, order: 1 };
            handlePropertyChange(elementIndex, propertyName, [nullifiedItem]);
        }
    };

    const handleItemPropertyChange = (elementIndex, itemIndex, property, value, propertyName) => {
        if(propertyName === 'preNumbers'){
            const newItems = elements[elementIndex].properties[propertyName].map((item, idx) =>
                idx === itemIndex ? { ...item, value:value,label:value} : item
            );
            handlePropertyChange(elementIndex, propertyName, newItems);
        }
        else{
            const newItems = elements[elementIndex].properties[propertyName].map((item, idx) =>
                idx === itemIndex ? { ...item, [property]: value } : item
            );
            handlePropertyChange(elementIndex, propertyName, newItems);
        }

    };





    return {
        addCheckBoxItem,
        deleteCheckBoxItem,
        elements,
        setElements,
        addElement,
        fields,
        deleteElement,
        handlePropertyChange,
        selectedElementIndex,
        setSelectedElementIndex,
        SortAccending,
        handleItemPropertyChange,
        SortDescending,
        handleDefaultValueChange
    };
};
