import React from "react";
    export const handleGetTelegramAccount=(platform,socialAccount)=>{
        const accoountData = {
            platform:platform,
            properties:{
                ChatId:socialAccount.telegram.chatId === null || socialAccount.telegram.chatId === ''?null:socialAccount.telegram.chatId,
                BotToken:socialAccount.telegram.botToken === null || socialAccount.telegram.botToken === ''?null:socialAccount.telegram.botToken,
            }
        }
        return accoountData;
    }
