import React, {useEffect, useState} from "react";
// import '../../../../Styles/Internal/setting.css'
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
import {DashboardItem} from "./DashboardItem";
import {useNavigate} from "react-router-dom";
import {useDashboard} from "./useDashboard";
import {convertSelectDataHandler, fonts, fontSizes} from "../../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {handleCreateDashboardSetting} from "./dashboardSettingService";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {ToastContainer} from "react-toastify";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {useDynamicDispatch} from "../../../../../Services/Redux/useDynamicaDispatch";
export const HandleDashboardSetting = () => {
    const handleDispatch = useDynamicDispatch();
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh,setRefresh]=useState(false);
    const [errors,setErrors]=useState({});
    const[initialSource,setInitialSource]=useState({
        cultures:[],
        directions:[],
        editors:[],
        coloThemes:[],
        dashboards:[]
    });
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const {setting,handleSelectValue,handleGetValueTheme,handleNumberValue,setSetting, handleValue,handleGetDashboards}=useDashboard(setErrors);

    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }

    const handleInitialDataSource=(colorThemes,cultures,editors,responseDashboard)=>{
        const colorThemesData = convertSelectDataHandler(colorThemes);
        const culturesData = convertSelectDataHandler(cultures);
        const editorsData = convertSelectDataHandler(editors);
        const dashboards = handleGetDashboards(responseDashboard);
        setInitialSource({
            cultures: culturesData,
            editors: editorsData,
            colorThemes: colorThemesData,
            dashboards: dashboards
        })
        return {
            culturesData,
            editorsData,
            colorThemesData,
        }
    }

    const fetchData=async ()=>{
        try {
            showSpinner();
            const [responseCulture, responseEditor,responseColorTheme,responseDashboard,responseSetting
            ] = await Promise.all([
                GET(`/Enumerations/EnCultureType`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnEditor`,{},'IEnumerable'),
                GET(`/Enumerations/EnTheme`,{},'IEnumerable'),
                GET(`/Dashboards`,{},'IEnumerable'),

                GET(`/Settings/UserPersonalSettings`,{},'IEnumerable'),
            ]);
            const allResponses = [responseCulture, responseEditor,responseColorTheme,responseDashboard,responseSetting];
            const allResponseStatusAre200 = allResponses.every(response => response.status === 200);
            if(allResponseStatusAre200){
                const {culturesData,colorThemesData,editorsData} =handleInitialDataSource(responseColorTheme.data,responseCulture.data,responseEditor.data,responseDashboard.data);
                setSetting(prevState => ({...prevState,
                    editor: editorsData.find((item)=>item.value === responseSetting.data.editor),
                    theme: colorThemesData.find((item)=>item.value === responseSetting.data.theme),
                    fontFamily: fonts.find((item)=>item.value === responseSetting.data.fontFamily),
                    fontSize:fontSizes.find((item)=>item.value === responseSetting.data.fontSize),
                    dashboard:responseSetting.data.dashboard
                }));
            }
            else{
                allResponses.forEach((response)=>{
                    handleApiError(response,hideSpinner);
                })
            }
            hideSpinner();
        }catch (e) {
            hideSpinner();
        }
    }
    useEffect(()=>{
        fetchData();
    },[refresh])

    const handleSubmitDashboardSettings=async(e)=>{
        e.preventDefault();
        try {
            showSpinner();
            const dashboardData = handleCreateDashboardSetting(setting);
            const response = await POST(`/Settings/UserPersonalSettings`,dashboardData,false);
            if(response.status === 200){
              hideSpinner();
              Refresh();
              handleSuccessMessage(t('SuccessDone'));
                const theme =  handleGetValueTheme(setting.theme.value)
                handleDispatch('setSelectedTheme',theme);
                handleDispatch('setDashboardType',setting.dashboard);
                handleDispatch('setFontSize',setting.fontSize.value);

                if(setting.fontSize.value === 'harmony-default-font'){
                    document.documentElement.removeAttribute('class');
                }
                else
                {
                    document.documentElement.removeAttribute('class');
                    document.documentElement.classList.add(setting.fontSize.value);
                }

            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }




  return(
      <>


        <div class="space-y-5">
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
            <ListFilter label={null} icon={null}
                        showChildrenTwo={false}
                        childrenOne={
                          <>
                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                  icon={"pi-cog"}
                                  title={t("settings")}
                                  tooltipTitle={t("SettingsPage")}
                              />

                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Setting/Main/General`)}
                                  icon={"pi-objects-column"}
                                  title={t("Public")}
                                  tooltipTitle={t("GeneralSettings")}
                              />

                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Setting/Main/Pictures`)}
                                  icon={"pi-image"}
                                  title={t("Images")}
                                  tooltipTitle={t("ImageSettings")}
                              />
                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Setting/Main/Routing`)}
                                  icon={"pi-code"}
                                  title={t("Routing")}
                                  tooltipTitle={t("Routing")}
                              />
                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Setting/Main/CleanTemp`)}
                                  icon={"pi-trash"}
                                  title={t("DataCleanup")}
                                  tooltipTitle={t("TemporaryDataCleanup")}
                              />
                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Setting/Main/MetaTagextra`)}
                                  icon={"pi-globe"}
                                  title={t("DataHighlight")}
                                  tooltipTitle={t("MetaTagsAndDataHighlighter")}
                              />

                          </>
                        }
            />

          </div>
           <form method={'post'} onSubmit={handleSubmitDashboardSettings} className={"relative"}>
               {
                   IsShowSpinner?
                       <div className="huploader_loading" >
                           <div className="relative">
                               <LoadingHarmony/>
                           </div>
                       </div>
                       :
                       null
               }
               <div class="space-y-5">
                   <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3"}>
                       <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                           <h2 className={"dark:text-gray-300 text-xs"}>{t("SelectDashboard")}</h2>
                       </div>
                       <DashboardItem
                           dashboardData={initialSource.dashboards}
                           handleSelectValue={handleSelectValue}
                           selectedDashboard={setting.dashboard}
                       />
                   </div>
                   <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3"}>
                       <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                           <h2 className={"dark:text-gray-300 text-xs"}>{t("ShellSettings")}</h2>
                       </div>
                       <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 px-2">
                           <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                               <div class="space-y-1">
                                   <label className="text-xs dark:text-gray-300">{t("ChooseColorTheme")}</label>
                                   <Select
                                       options={initialSource.colorThemes}
                                       placeholder={t("selection")}
                                       className={"w-full "}
                                       classNamePrefix={"h_multi_select"}
                                       value={setting.theme}
                                       onChange={(data)=>handleSelectValue(data,'theme')}
                                   />
                               </div>
                           </div>
                           <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                               <div className={"grid grid-cols-2 gap-2"}>
                                   <div class="space-y-1 ">
                                       <label className="text-xs dark:text-gray-300">{t("SelectFont")}</label>
                                       <Select
                                           options={fonts}
                                           placeholder={t("selection")}
                                           classNamePrefix={"h_multi_select"}
                                           menuPosition={"fixed"}
                                           value={setting.fontFamily}
                                           onChange={(data)=>handleSelectValue(data,'fontFamily')}
                                       />
                                   </div>
                                   <div class="space-y-1 ">
                                       <label className="text-xs dark:text-gray-300">{t("FontSize")}</label>
                                       <Select
                                           options={fontSizes}
                                           placeholder={t("selection")}
                                           classNamePrefix={"h_multi_select"}
                                           menuPosition={"fixed"}
                                           value={setting.fontSize}
                                           onChange={(data)=>handleSelectValue(data,'fontSize')}
                                       />
                                       {/*چهارتا سایز فونت انتخاب شود*/}
                                       {/*<div className={"w-24"}>*/}
                                       {/*    <InputNumber value={setting.fontSize}  onChange={(e)=>handleNumberValue(e.value,'fontSize')} suffix={'rem'} step={0.25}  placeholder={t("selection")} mode="decimal" useGrouping={false} showButtons min={0} max={100}/>*/}
                                       {/*</div>*/}
                                   </div>
                               </div>
                           </div>
                           <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                               <div className={"space-y-1"}>
                                   <label className="text-xs dark:text-gray-300">
                                       {t("EditorType")}
                                   </label>
                                   <Select
                                       options={initialSource.editors}
                                       placeholder={t("selection")}
                                       classNamePrefix={"h_multi_select"}
                                       menuPosition={"fixed"}
                                       value={setting.editor}
                                       onChange={(data)=>handleSelectValue(data,'editor')}
                                   />
                               </div>
                           </div>
                       </div>
                   </div>
                   <ButtonSubmitAndCancel
                       onCancelClick={()=>redirectTo('/Harmony/Master/setting')}
                   />
               </div>

           </form>
            <ToastContainer/>
        </div>

      </>

  )
}