import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
export const useProductChartGrid=(handleGetCurrencyType,productChart,currencies,filter)=>{

    const { t } = useTranslation();

    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: t("Value"),
            cellRenderer:parameters=>
                    <h2>{<PriceFormatterComponent price={parameters.data.price}/>} {handleGetCurrencyType(productChart.currency,currencies)}</h2>
        },
        { headerName: t("Date"),maxWidth: 150,
            cellRenderer:parameters=>
                <> <GridDateFomartter date={parameters.data.date} format={filter.timeInterval === null?'jYYYY/jMM/jDD':filter.timeInterval.value === 2?'jYYYY/jMM':filter.timeInterval.value === 3?'jYYYY':0} /></>
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);

    return {
        defaultColDef,
        getRowHeight,
        autoGroupColumnDef,
        columnDefs
    }

}