import React, {useEffect} from "react";
import {GeneralReport} from "../GeneralReport";
import {useState} from "react";
import { CartProduct} from "./DefaultWidgets/CartProduct";
import {ChartHarmony} from "../ChartHarmony";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDefaultDashboardWidgets} from "./useDefaultDashboardWidgets";
import {Statistics} from "./DefaultWidgets/Statistics";
import {LatestUsers} from "./DefaultWidgets/LatestUsers";
import {Sale} from "./DefaultWidgets/Sale";
import {useDashboard} from "../useDashboard";
import {UserComment} from "./DefaultWidgets/UserComment";
import {UserNote} from "./DefaultWidgets/UserNote";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
// this is the main dashboard to handle a bunch of components and navigate to other parts of app .
// we have menubar, topbar,datepicker,general reports ,basket,menubar for mobile , last duties or activities , and also charts ,.

export const DefaultDashboard=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();





    return (

        <div className={"relative min-h-screen"}>
            {
                IsLoading?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                        <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                            <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                                <HPrimeIcon
                                    icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                                />
                            </span>
                        </button>
                        <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                            <div className="space-y-5">
                                <div>
                                    <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                        <h2 className="text-lg dark:text-gray-300 -intro-x">
                                            {t("GeneralReport")}
                                        </h2>
                                        <Link to={"src/Components/Dashboard#"} className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                            <HPrimeIcon
                                            icon={"pi-sync"}
                                            />
                                            <p> {t("Reload")}</p>
                                        </Link>
                                    </div>
                                    <div className={`grid gap-5 py-3 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                        <div className={"-intro-x"}>
                                            <Statistics
                                                title={t("Wallet")}
                                                data={dashboard.userWallet}
                                                icon={'pi-wallet'}
                                                name={'userWallet'}
                                                url={'/CRM/User/Wallet'}
                                                handleGetCurrency={GetCurrencyType}
                                                objName={'balance'}
                                            />
                                        </div>

                                        <div className={"-intro-x"}>
                                            <Statistics
                                                title={t("Deposittoday")}
                                                data={dashboard.todayDeposits}
                                                icon={'pi-credit-card'}
                                                name={'todayDeposits'}
                                                url={'/Bank/Transaction/list'}
                                                handleGetCurrency={GetCurrencyType}
                                                objName={'totalDeposit'}
                                            />
                                        </div>
                                        <div className={"-intro-x"}>
                                            <Statistics
                                                title={t("WithdrawalRequest")}
                                                data={dashboard.withdrawalRequestStatistics}
                                                icon={'pi-arrow-right-arrow-left'}
                                                name={'WithdrawalRequestStatistics'}
                                                url={'/CRM/User/WithdrawalRequest'}
                                                handleGetCurrency={GetCurrencyType}
                                                objName={'totalWithdrawalAmount'}
                                            />
                                        </div>
                                        <div className={"-intro-x"}>
                                            <Statistics
                                                title={t("SMSCharge")}
                                                data={dashboard.SmsAccountCredit}
                                                icon={'pi-mobile'}
                                                name={'SmsAccountCredit'}
                                                url={'/SmsManagment/AddSmsNumber'}
                                                handleGetCurrency={GetCurrencyType}
                                                objName={'credit'}
                                                SmsAccountOutputCount={dashboard.SmsAccountOutputCount}
                                                SmsAccountInboxCount={dashboard.SmsAccountInboxCount}

                                            />
                                        </div>
                                        {/*<GeneralReport lable={"کیف پول"} tempval={152.000} iconName={"briefcase"} temppercentage={28} className={"text-themeInformation dark:text-themeDarkInformation"} />*/}
                                    </div>
                                </div>
                                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-3"}>
                                    <div className={"-intro-x"}>
                                        <div className="flex items-center relative justify-between">
                                            <h2 className="text-lg dark:text-gray-300">
                                                {t("statisticsAndVisits")}
                                            </h2>
                                            <Link to="/Statistics" className="text-themeInformation  dark:text-themeDarkInformation">({t("viewing")})</Link>
                                        </div>
                                        <div className="py-3">
                                            <div className="box bg-white dark:bg-themeDarkSalt700 p-5 ">
                                                <div className="flex justify-around items-center">
                                                    <div className="flex-1 text-center">
                                                        <div className="text-gray-500 text-center dark:text-gray-300">{t("MonthlyStatistics")}</div>
                                                        <div className="text-themeInformation dark:text-themeDarkInformation text-lg xl:text-xl  dark:text-gray-300">
                                                            {dashboard.statistics.monthly}
                                                        </div>
                                                    </div>
                                                    <div className="border-x border-dashed border-gray-300 flex-1 text-center">
                                                        <div className="text-gray-500 text-center dark:text-gray-300">{t("Daily")}</div>
                                                        <div className="text-gray-500 text-lg xl:text-xl  dark:text-gray-300">
                                                            {dashboard.statistics.daily}
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 text-center ">
                                                        <div className="text-gray-500 text-center dark:text-gray-300 ">{t("Weekly")}</div>
                                                        <div className="text-gray-500 text-lg xl:text-xl  dark:text-gray-300">
                                                            {dashboard.statistics.weekly}
                                                        </div>
                                                    </div>
                                                </div>
                                                <ChartHarmony options={chartData}  apiUrl={''} className={''} style={{ width: '100%', height: '350px' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="-intro-x flex items-center justify-between ">
                                            <h2 className="text-lg dark:text-gray-300">
                                                {t("Latestusers")}
                                            </h2>
                                            <Link to="/CRM/User/List" className="text-themeInformation dark:text-themeDarkInformation"> ({t("viewing")})</Link>
                                        </div>
                                        <div className={"height_32rem overflow-y-auto scrollbar-hidden py-3"}>
                                            <div class={`grid gap-2 grid-cols-1 ${classesToggled ? 'xl:grid-cols-2' : ''}`}>
                                                {
                                                    dashboard && dashboard.users.length > 0 && dashboard.users.map((item, index) => (
                                                        <LatestUsers
                                                            key={index}
                                                            userId={item.userId}
                                                            fullName={item.fullName}
                                                            companyName={item.companyName}
                                                            userAvatar={item.userAvatar}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={"space-y-5"}>
                                    <div className={`grid grid-cols-2 md:grid-cols-3  gap-y-5 gap-1 ${classesToggled ? 'xl:grid-cols-6' : ''}`}>

                                        <Sale
                                            title={'totalsales'}
                                            handleGetCurrency={GetCurrencyType}
                                            icon={'pi-wallet'}
                                            data={dashboard.sales.totalSales}
                                        />

                                        <Sale
                                            title={'Thisyearsales'}
                                            handleGetCurrency={GetCurrencyType}
                                            icon={'pi-calendar'}
                                            data={dashboard.sales.currentYearSales}
                                        />

                                        <Sale
                                            title={'Quarterlysales'}
                                            handleGetCurrency={GetCurrencyType}
                                            icon={'pi-calendar'}
                                            data={dashboard.sales.threeMonthsSales}
                                        />
                                        <Sale
                                            title={'Currentmonthsales'}
                                            handleGetCurrency={GetCurrencyType}
                                            icon={'pi-calendar'}
                                            data={dashboard.sales.currentMonthSales}
                                        />
                                        <Sale
                                            title={'Thisweeksales'}
                                            handleGetCurrency={GetCurrencyType}
                                            icon={'pi-calendar'}
                                            data={dashboard.sales.currentWeekSales}
                                        />
                                        <Sale
                                            title={'TodaySales'}
                                            handleGetCurrency={GetCurrencyType}
                                            icon={'pi-calendar'}
                                            data={dashboard.sales.todaySales}
                                        />


                                    </div>
                                    <CartProduct
                                        rowData={dashboard.SalesData}
                                        GetCurrencyType={GetCurrencyType}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                            <div>
                                <div>
                                    <h2 className="text-lg dark:text-gray-300">
                                        {t("Notes")}
                                    </h2>
                                </div>
                                <div className={"space-y-3 py-3"}>
                                    {
                                        dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                            <UserNote
                                                key={index}
                                                fullName={item.fullName}
                                                id={item.id}
                                                message={item.message}
                                                createdAt={item.createdAt}
                                            />
                                        ))
                                    }
                                    <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h2 className="text-lg dark:text-gray-300">
                                        {t("UserComments")}
                                    </h2>
                                </div>
                                <div className={"space-y-3 py-3"}>
                                    <div className={"space-y-3 dashboard-note height_21rem overflow-y-auto scrollbar-hidden "}>
                                        {
                                            dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                                <UserComment
                                                    key={index}
                                                    fullName={item.fullName}
                                                    id={item.id}
                                                    message={item.message}
                                                    createdAt={item.createdAt}
                                                    state={item.state}
                                                />
                                            ))
                                        }
                                    </div>
                                    <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                                </div>
                            </div>
                            <div>
                                <div className="flex items-center ">
                                    <h2 className="text-lg dark:text-gray-300">
                                        {t("ReleaseQueue")}
                                        <span className={"text-xs dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                                    </h2>
                                </div>
                                <div className={"space-y-3 py-3"}>
                                    <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                        <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                            <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                                <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                            </div>
                                            <div class="flex-1">
                                                <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                                <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                            </div>
                                        </Link>
                                    </div>
                                    <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("Draft")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>

    )
}