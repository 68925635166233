import React, {useEffect} from "react";

import {useState} from "react";
import {Sidebar} from "primereact/sidebar";


import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useCustomRoutes} from "../Hooks/useCustomRoutes";
import {QuickAccessItemTwo} from "../NavBar/QuickAccess/QuickAccessItemTwo";
import {useCustomQuickAccess} from "../NavBar/QuickAccess/useCustomQuickAccess";
import {FantasyModal} from "../UIParts/FantasyModal";
import Select from "react-select";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleConvertQuickAccess} from "../NavBar/QuickAccess/quickAccessService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {useDispatch} from "react-redux";
import {setIsLoading} from "../../../Services/Redux/loadingReducer";
import {HPrimeIconSmall} from "../UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";

export const MenuBarMobile=()=>{
    const { t } = useTranslation();
    const [initialSource,setInitialSource] = useState({targetTypes:[],listItems:[]});
    const dispatch = useDispatch();
    const [visibleRight, setVisibleRight] = useState(false);
    const [IsOpen,setIsOpen] = useState(false);
    const {allRoutes} = useCustomRoutes();
    const [activeSubmenu, setActiveSubmenu] = useState("");
    const handleSubmenuClick = (submenuId) => {
        if (activeSubmenu === submenuId) {
            // Clicked on the active submenu, close it
            setActiveSubmenu("");
        } else {
            // Clicked on a different submenu, close the active submenu and open the clicked submenu
            setActiveSubmenu(submenuId);
        }
    };
    const closeSidebar = () => {
        setVisibleRight(false);
    };

    const {handleSelectValue,quickAccess,setQuickAccess,handleDeleteSpeedLink}=useCustomQuickAccess(setInitialSource)

    const handleIsOpen=()=>{
        setIsOpen(true)
    }
    const handleCloseIsOpen=()=>{
        setIsOpen(false);
        setQuickAccess({target: initialSource.targetTypes[0],slug:allRoutes[0],icon: '',title: ''});
    }

    const showSpinner=()=>{
        dispatch(setIsLoading(true));
    }
    const hideSpinner=()=>{
        dispatch(setIsLoading(false));
    }


    const fetchData=async()=>{
        try {

            showSpinner();
            const response = await GET(`/Enumerations/EnTargetType`,{},'IEnumerable');
            const responseSpeedlinK = await GET(`/UserSpeedLink/Links`,{},'IEnumerable');
            if(response.status === 200 && responseSpeedlinK.status === 200 ){
                const targets = convertSelectDataHandler(response.data);
                setInitialSource({targetTypes: targets,listItems: responseSpeedlinK.data});
                setQuickAccess({target: targets[0],slug:allRoutes[0],title:allRoutes[0].label,icon:allRoutes[0].icon});
                hideSpinner();
            }
        }
        catch (e) {
            hideSpinner();
        }
    }

    const handleSubmitQuickAccess=async ()=>{
        try {
            showSpinner();
            const quickAccessData = handleConvertQuickAccess(quickAccess)
            const response = await POST(`/UserSpeedLink`,quickAccessData,false);
            if(response.status === 200){
                handleCloseIsOpen();
                hideSpinner();
                const responseSpeedlinK = await GET(`/UserSpeedLink/Links`,{},'IEnumerable');
                if(responseSpeedlinK.status === 200){
                    setInitialSource(prevState => ({...prevState,listItems: responseSpeedlinK.data}))
                }else {
                    handleApiError(responseSpeedlinK,hideSpinner);
                }
            }
            else {
                handleApiError(response,hideSpinner);
            }

        }catch (e) {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[])



    const {seo,advertise,CMS,Comment,SocialMarketing,EmailMarketing,DiscountCode,Commission,form,Guarantee,shop,manager,shortMessageService,PersonManagement,support,template}=useCustomRoutes()

    const SidebarLink = ({ to, icon, title, onClick ,target =false,isInRole=null ,index }) => (
        <li>
            <Link to={to} target={target?'_blank':null} className="menu" onClick={()=>{onClick(); window.scrollTo(0, 0);}}
                  style={{ animationDelay: `${(index+1) * 0.1}s`} }
            >
                <div className="menu__icon">

                        <HPrimeIconSmall icon={`${icon} `}></HPrimeIconSmall>

                </div>
                <div className="menu__title">{title}</div>
            </Link>
        </li>
    );

    const SubMenu = ({ submenuKey,title, links, activeSubmenu, handleSubmenuClick, closeSidebar,icon }) => (
        <li>
            <div  className={`menu cursor-pointer ${activeSubmenu === submenuKey ? "menu--open" : ""}`} onClick={() => handleSubmenuClick(submenuKey)}>
                <div className="menu__icon">
                    <HPrimeIcon icon={`${icon}`}></HPrimeIcon>
                </div>
                <div className="menu__title">
                    {title}
                    <HPrimeIconSmall icon={activeSubmenu === submenuKey ? 'pi-chevron-up' : 'pi-chevron-down'}  />
                </div>
            </div>
            <ul className={`animate_menu ${activeSubmenu === submenuKey ? "menu__sub-open" : ""}`} style={{ display: activeSubmenu === submenuKey ? "block" : "none" }}>
                {links.map((link, index) => (
                    <SidebarLink index={index} {...link} onClick={closeSidebar} />
                ))}
            </ul>
        </li>
    );

    const SubQuickAccess = ({ submenuKey, icon, title, links,activeSubmenu, handleSubmenuClick, closeSidebar, }) => (
        <li>
            <div className={`menu cursor-pointer ${activeSubmenu === submenuKey ? "menu--open" : ""}`} onClick={() => handleSubmenuClick(submenuKey)}>
                <div className="menu__icon">
                    <HPrimeIcon icon={"pi-link"} />
                </div>
                <div className="menu__title">
                    {title}
                    <HPrimeIconSmall icon={activeSubmenu === submenuKey ? 'pi-chevron-up' : 'pi-chevron-down'}  />
                </div>
            </div>
            <ul className={`animate_menu ${activeSubmenu === submenuKey ? "menu__sub-open" : ""}`} style={{ display: activeSubmenu === submenuKey ? "block" : "none" }}>
                <li>
                    <div
                        className={"menu cursor-pointer"}
                        onClick={() => handleIsOpen()}
                        style={{ animationDelay: ".04s"} }
                    >
                        <div className="menu__icon">
                            <HPrimeIconSmall icon={"pi-plus-circle"} />
                        </div>
                        <div className="menu__title">{t("Add")}</div>
                    </div>
                </li>
                {initialSource.listItems.length > 0 ? initialSource.listItems.map((item, index) => (
                    <QuickAccessItemTwo handleDeleteSpeedLink={handleDeleteSpeedLink} target={item.target} speedLinkId={item.speedLinkId} icon={item.icon} slug={item.slug} title={item.title} index={index}/>
                )):null
                }
            </ul>
        </li>
    );


    return (

        <div className="mobile-menu">
            <div className="mobile-menu-bar ">
                <div className="block cursor-pointer" onClick={() => setVisibleRight(true)} >
                    <span className={"menuBar size-10 block"}></span>
                </div>
                <Link to="https://cpanel.harmonycms.ir/DashBoard">
                    <img src="http://cpanel.harmonycms.ir/ArmDark.svg" className="w-32 h-full object-contain" alt="harmony" title="harmony"/>
                </Link>
            </div>

            <Sidebar visible={visibleRight}   position="right" onHide={() => setVisibleRight(false)}>
                <div className="w-full mobile-menu">

                    <ul className="menu_mobile ">
                        <li>
                            <Link to="/DashBoard" className="menu menu--active" onClick={()=>{closeSidebar();window.scrollTo(0, 0);}}>
                                <div className="menu__icon">
                                    <HPrimeIcon icon="pi-desktop"  />
                                </div>
                                <div className="menu__title">{t("Dashboard")}</div>
                            </Link>
                        </li>

                        <li className="side-nav__devider"></li>

                        <SubQuickAccess
                            submenuKey="QuickAccess"
                            icon="zap"
                            title={t("QuickAccess")}
                            initialSource={{ listItems: [] }} // Pass actual initialSource propةة
                            links={manager}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider"></li>

                        <SubMenu
                            submenuKey={manager.submenuKey}
                            icon={manager.icon}
                            title={manager.title}
                            links={manager.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />


                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={PersonManagement.submenuKey}
                            icon={PersonManagement.icon}
                            title={PersonManagement.title}
                            links={PersonManagement.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>

                        <li>
                            <Link to="/Public/MainMenu/List" className="menu " onClick={()=>{closeSidebar();window.scrollTo(0, 0);}}>
                                <div className="menu__icon"><HPrimeIcon icon="pi-bars"/></div>
                                <div className="menu__title">{t("MenuManagement")}</div>
                            </Link>
                        </li>

                        <li className="side-nav__devider "></li>


                        <SubMenu
                            submenuKey={CMS.submenuKey}
                            icon={CMS.icon}
                            title={CMS.title}
                            links={CMS.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={Comment.submenuKey}
                            icon={Comment.icon}
                            title={Comment.title}
                            links={Comment.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />



                        <SubMenu
                            submenuKey={template.submenuKey}
                            icon={template.icon}
                            title={template.title}
                            links={template.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={advertise.submenuKey}
                            icon={advertise.icon}
                            title={advertise.title}
                            links={advertise.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />
                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={form.submenuKey}
                            icon={form.icon}
                            title={form.title}
                            links={form.links}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={shop.submenuKey}
                            icon={shop.icon}
                            title={shop.title}
                            links={shop.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />



                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={Guarantee.submenuKey}
                            icon={Guarantee.icon}
                            title={Guarantee.title}
                            links={Guarantee.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>


                        <SubMenu
                            submenuKey={DiscountCode.submenuKey}
                            icon={DiscountCode.icon}
                            title={DiscountCode.title}
                            links={DiscountCode.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={Commission.submenuKey}
                            icon={Commission.icon}
                            title={Commission.title}
                            links={Commission.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />



                        <li className="side-nav__devider"></li>

                        <SubMenu
                            submenuKey={SocialMarketing.submenuKey}
                            icon={SocialMarketing.icon}
                            title={SocialMarketing.title}
                            links={SocialMarketing.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>
                        <SubMenu
                            submenuKey={EmailMarketing.submenuKey}
                            icon={EmailMarketing.icon}
                            title={EmailMarketing.title}
                            links={EmailMarketing.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />
                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={shortMessageService.submenuKey}
                            icon={shortMessageService.icon}
                            title={shortMessageService.title}
                            links={shortMessageService.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={seo.submenuKey}
                            icon={seo.icon}
                            title={seo.title}
                            links={seo.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>

                        <SubMenu
                            submenuKey={support.submenuKey}
                            icon={support.icon}
                            title={support.title}
                            links={support.links}
                            activeSubmenu={activeSubmenu}
                            handleSubmenuClick={handleSubmenuClick}
                            closeSidebar={closeSidebar}
                        />

                        <li className="side-nav__devider "></li>

                        <li>
                            <Link to="/Authentication/FileManager" className="menu " onClick={()=>{closeSidebar();window.scrollTo(0, 0);}}>
                                <div className="menu__icon"><HPrimeIcon icon="pi-folder-open" /></div>
                                <div className="menu__title">{t("FileManagement")}</div>
                            </Link>
                        </li>



                        <li className="side-nav__devider "></li>
                        <li>
                            <Link to="/Support/OnlineSupport" className="menu" onClick={()=>{closeSidebar();window.scrollTo(0, 0);}}>
                                <div className="menu__icon"><HPrimeIcon icon="pi-comments"/></div>
                                <div className="menu__title">{t("onlineChat")}</div>
                            </Link>
                        </li>
                    </ul>
                </div>

            </Sidebar>

            <FantasyModal openModal={IsOpen} closeModal={handleCloseIsOpen} title={t("QuickAccess")}>
                <div className="space-y-3">
                    <div className={"flex items-center gap-2"}>
                        <div class=" space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                            <Select
                                options={allRoutes}
                                isMulti={false}
                                isSearchable={true}
                                onChange={(data)=>handleSelectValue(data,'slug')}
                                placeholder={t("selection")}
                                value={quickAccess.slug}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />

                        </div>
                        <div class=" space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">{t("DisplayStatus")}</label>
                            <Select
                                options={initialSource.targetTypes}
                                isMulti={false}
                                isSearchable={true}
                                onChange={(data)=>handleSelectValue(data,'target')}
                                placeholder={t("selection")}
                                value={quickAccess.target}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                    </div>
                    <button type={'button'} onClick={()=>handleSubmitQuickAccess()} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("register")}</button>
                </div>
            </FantasyModal>

        </div>
    )
}