import React, {useState} from "react";

export const useCustomGateway=(setErrors,fileInput)=>{

    const [gateway,setGateway] = useState({id:null,name:null,gatewayType:null,isActive:false,currencyType:null,icon:null});
    const handleChangeToggle=(name,value)=>{
        setGateway({...gateway,[name]:value});
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setGateway({...gateway,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setGateway({...gateway,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue=(data,name)=>{
        setGateway({...gateway,[name]:data});
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setGateway({...gateway,icon:file});
        fileInput.current.value = '';
    };


    return {
        handleValue,
        handleNumberValue,
        handleSelectValue,
        gateway,
        setGateway,
        handleChangeToggle,
        handleFileChange
    }

}