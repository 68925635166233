import React from "react";

import Select from "react-select";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";
export const MultiSelectElement=({element,handleDefaultValueChange,handlePropertyChange,index,deleteElement,selectedElementIndex,handleSelectTabAfterElementSelection,itemCount,SortAccending,SortDescending})=>{

    return (
        <>

            <Select
                options={element?.properties?.items}
                onChange={(data) => handleDefaultValueChange(index, "value", data)}
                placeholder={element?.properties?.description}
                className={`harmony-w-full ${index === selectedElementIndex ? 'border-themeInformation' : ''}`}
                isMulti={true}
                name={element.properties.name}
                id={element.properties.id}
                isDisabled={element.properties.isReadOnly}
                menuPosition={"fixed"}
                value={element?.properties?.defaultValue?.value}
                classNamePrefix={"h_multi_select"}
            />

            <div className={"harmony_body_button"}>

                <button
                    type="button"
                    className="button bg-themeDanger text-white harmony_button_form"
                    onClick={() => deleteElement(index)}
                >
                    <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
                </button>
                {
                    index===0?null:
                        <button
                            type="button"
                            className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                            onClick={() => SortAccending(index)}

                        >
                            <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                        </button>

                }
                {
                    index <itemCount - 1?
                        <button
                            type="button"
                            className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                            onClick={() => SortDescending(index)}

                        >
                            <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                        </button>:
                        null
                }


            </div>

        </>
    )
}