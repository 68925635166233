import React, {useState} from "react";
import {MultiDataSelect} from "../../../Commans/UIParts/MultiDataSelect";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const HandleAddInterbankTransfer=()=>{
    const [modal, setModal] = useState({ addFunds: false, addProject: false , addBank:false , addFinanceBox:false  });

    const addProjectOnHide = () => {
        setModal((prevState) => ({ ...prevState, addProject: !modal.addProject }));
    };

    const addBankOnHide = () => {
        setModal((prevState) => ({ ...prevState, addBank: !modal.addBank }));
    };

    const addFundsOnHide = () => {
        setModal((prevState) => ({ ...prevState, addFunds: !modal.addFunds }));
    };

    const addFinanceBoxOnHide = () => {
        setModal((prevState) => ({ ...prevState, addFinanceBox: !modal.addFinanceBox }));
    };


    return(
        <>
            <div className=" space-y-5">
            <div class="grid grid-cols-12 gap-5">
                <div class="col-span-12 lg:col-span-8 space-y-5 ">
                    <div class="box bg-white dark:bg-themeDarkSalt700 p-3 grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div className="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <div class="flex justify-between">
                                <label className="text-xs dark:text-gray-300">پروژه:</label>
                                <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addProject: true })}>
                                    + افزودن
                                </button>
                            </div>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" id="" className="select2 w-full">*/}
                            {/*    <option value="">حسن</option>*/}
                            {/*    <option value="">عباس</option>*/}
                            {/*    <option value="">رضا</option>*/}
                            {/*</select>*/}
                        </div>
                        <div className="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <div class="flex items-center gap-2">
                                <label className="text-xs dark:text-gray-300">کد حسابداری:</label>
                                <div class="can-toggle demo-rebrand-1 text-xs">
                                    <input id="swich" type="checkbox"/>
                                    <label htmlFor="swich">
                                        <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <InputText type={"text"} className={"input w-full"} />
                            </div>
                        </div>
                        <div className="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <label className="text-xs dark:text-gray-300">شرح:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div className="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <label className="text-xs dark:text-gray-300">تاریخ:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div className="flex items-end gap-1 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                            <div>
                                <MultiDataSelect placeholder={'انتخاب'}  />
                                {/*<select name="" id="" className="select2" data-hide-search="true">*/}
                                {/*    <option value="">ریال</option>*/}
                                {/*    <option value="">تومان</option>*/}
                                {/*</select>*/}
                            </div>
                            <div className="space-y-2 flex-1">
                                <label className="text-xs dark:text-gray-300">مبلغ انتقال:</label>
                                <InputText type={"text"} className={"input w-full"} />
                            </div>

                        </div>
                    </div>
                    <div className="space-y-3">
                        <div class="flex gap-2 items-center box bg-white dark:bg-themeDarkSalt700 p-3">
                            <div className="text-xs dark:text-gray-300">نوع مبدا:</div>
                            <div class="pos">
                                <div class="pos__tabs nav-tabs justify-center flex gap-1 post-input dark:bg-themeDarkSalt600 px-2 py-1 rounded">
                                    <a data-toggle="tab" data-target="#tab_one" href="javascript:;" className="lg:w-24 p-2 rounded-md flex justify-center items-center active dark:text-gray-300">بانک</a>
                                    <a data-toggle="tab" data-target="#tab_two" href="javascript:;" className="lg:w-24 p-2 rounded-md flex justify-center items-center dark:text-gray-300">تنخواه
                                        گردان</a>
                                    <a data-toggle="tab" data-target="#tab_three" href="javascript:;" className="lg:w-24 p-2 rounded-md flex justify-center items-center dark:text-gray-300">صندوق</a>
                                    <a data-toggle="tab" data-target="#tab_four" href="javascript:;" className="lg:w-24 p-2 rounded-md flex justify-center items-center dark:text-gray-300">چک</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content">
                            <div class="tab-content__pane space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700  active" id="tab_one">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-3 post-input dark:bg-themeDarkPostInput rounded-md">
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">بانک:</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBank: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <MultiDataSelect placeholder={'انتخاب'} />
                                        {/*<select name="" id="" className="select2 w-full">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">کارمزد خدمات بانکی:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300" >ارجاع:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content__pane space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700  " id="tab_two">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-3 post-input bg-themeDarkPostInput rounded-md">
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">صندوق :</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFinanceBox: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <MultiDataSelect placeholder={'انتخاب'} />
                                        {/*<select name="" id="" className="select2 w-full">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">ارجاع:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content__pane space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700  " id="tab_three">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-3 post-input bg-themeDarkPostInput rounded-md">
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">صندوق:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFinanceBox: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <MultiDataSelect placeholder={'انتخاب'} />
                                        {/*    <select name="" id="" className="select2 w-full">*/}
                                        {/*        <option value="">حسن</option>*/}
                                        {/*        <option value="">عباس</option>*/}
                                        {/*        <option value="">رضا</option>*/}
                                        {/*    </select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">ارجاع:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content__pane space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700  " id="tab_four">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-3 post-input bg-themeDarkPostInput rounded-md">
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">بانک:</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBank: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <MultiDataSelect placeholder={'انتخاب'} />
                                        {/*<select name="" id="" className="select2 w-full">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">انتخاب چک:</label>
                                        <MultiDataSelect placeholder={'انتخاب'} />
                                        {/*<select name="" id="" className="select2 w-full">*/}
                                        {/*    <option value="1">12345678</option>*/}
                                        {/*    <option value="2">45678912</option>*/}
                                        {/*    <option value="3">4874454</option>*/}
                                        {/*</select>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-3">
                        <div class="flex gap-2 items-center box dark:bg-themeDarkSalt700 p-3 bg-themeGreen600 ">
                            <h2 className="text-xs dark:text-gray-300" >نوع مقصد:</h2>
                            <div class="pos">
                                <div class="pos__tabs nav-tabs justify-center flex gap-1 post-input dark:bg-themeDarkSalt600 px-2 py-1 rounded">
                                    <a data-toggle="tab" data-target="#tab_five" href="javascript:;" className="lg:w-24 p-2 rounded-md flex justify-center items-center active dark:text-gray-300">بانک</a>
                                    <a data-toggle="tab" data-target="#tab_six" href="javascript:;" className="lg:w-24 p-2 rounded-md flex justify-center items-center dark:text-gray-300">تنخواه
                                        گردان</a>
                                    <a data-toggle="tab" data-target="#tav_seven" href="javascript:;" className="lg:w-24 p-2 rounded-md flex justify-center items-center dark:text-gray-300">صندوق</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-content">
                            <div class="tab-content__pane space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700  active" id="tab_five">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-3 post-input dark:bg-themeDarkPostInput rounded-md">
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">بانک:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBank: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <MultiDataSelect placeholder={'انتخاب'} />
                                        {/*<select name="" id="" className="select2 w-full">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">ارجاع:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content__pane space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700  " id="tab_six">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-3 post-input dark:bg-themeDarkPostInput rounded-md">
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">تنخواه گردان:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFunds: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <MultiDataSelect placeholder={'انتخاب'} />
                                        {/*<select name="" id="" className="select2 w-full">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">ارجاع</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content__pane space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700 " id="tab_seven">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-3 post-input dark:bg-themeDarkPostInput rounded-md">
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">صندوق:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFinanceBox: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <MultiDataSelect placeholder={'انتخاب'} />
                                        {/*<select name="" id="" className="select2 w-full">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">ارجاع:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-4">
                    <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                            <div class="space-y-3 ">
                                <div class="card_bank max-w-sm mx-auto">
                                    <div class="front">
                                        <div class="investor">شرکت نرم افزاری آرین</div>
                                        <div class="chip">
                                            <div class="chip-line"></div>
                                            <div class="chip-line"></div>
                                            <div class="chip-line"></div>
                                            <div class="chip-line"></div>
                                            <div class="chip-main"></div>
                                        </div>
                                        <div class="card-number">
                                            <div class="flex justify-between text-xl">
                                                <h2>
                                                    مبلغ:
                                                    (ریال)
                                                </h2>
                                                <div>
                                                    <h3>1.000.000.000</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="px-5 flex justify-between">تاریخ:<span>1401/12/21</span></div>
                                    </div>
                                </div>
                                <div class="rounded relative">
                                    <span class="change-money">
                                        <HPrimeIcon icon={'repeat'} className="w-8 h-8 text-white absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rotate-90"/>
                                    </span>
                                    <div class="h-12 rounded-t-md flex justify-between items-center px-3 py-5 bg-themeInformation text-white">
                                        <div>
                                            از:
                                            بانک ملت
                                        </div>
                                        <div>
                                            <HPrimeIcon icon={'credit-card'} className="w-5 h-5 text-white "/>
                                        </div>
                                    </div>
                                    <div class="h-12 rounded-b-md flex justify-between items-center px-3 py-5 bg-themeSuccess text-white">
                                        <div>
                                            به:
                                            تنخواه گردان
                                        </div>
                                        <div>
                                            <HPrimeIcon icon={'credit-card'} className="w-5 h-5 text-white "/>
                                        </div>
                                    </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput dark:text-gray-300 p-3 rounded">
                                    <div>
                                        پروژه:
                                        آرین
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button className="bg-themeInformation dark:bg-themeDarkInformation text-white button w-full rounded-md flex items-center justify-center gap-2 text-lg hover:bg-themeHoverInformation">
                                    <HPrimeIcon icon={'bookmark'}/>
                                    ثبت
                                </button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
            <FantasyModal openModal={modal.addProject}  closeModal={addProjectOnHide} title={"افزودن پروژ]"}>
                <div class="space-y-3" >
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">نام پروژه:</label>
                        <InputText type={"text"} className={"input w-full "} />
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ شروع:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ پایان:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                    </div>
                    <div class="space-y-2">
                        <div class="flex flex-wrap gap-2 items-center">
                            <label className="text-xs dark:text-gray-300">رنگ دلخواه:</label>
                            <span class="bg-red-500 size-7 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-red-700 size-7 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-blue-500 size-7 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-gray-500 size-7 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-yellow-500 size-7 rounded-full cursor-pointer color-change"></span>
                        </div>
                    </div>
                    <div class="space-y-2">
                        <div class="flex justify-between items-center p-2 box shadow rounded">
                            <div class="flex flex-row items-center">
                                <label className="text-xs dark:text-gray-300">تصویر دلخواه:</label>
                                <input type="file" className="huploader"/>
                            </div>
                            <div>
                                <button className="button p-1  bg-theme-6 text-white"><span class="w-5 h-5 flex items-center justify-center"> <i data-feather="trash"class="w-4 h-4"></i> </span>
                                </button>
                                <button className="button p-1  bg-theme-9 text-white"><span class="w-5 h-5 flex items-center justify-center"> <i data-feather="eye" class="w-4 h-4"></i> </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className="text-xs dark:text-gray-300">انتخاب اعضاء:</label>
                        <div >
                            <MultiDataSelect />
                            {/*<select className="select2 w-full" multiple>*/}
                            {/*    <option value="1">حسن</option>*/}
                            {/*    <option value="1">عباس</option>*/}
                            {/*    <option value="1">رضا</option>*/}
                            {/*    <option value="1">حسین</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>
                    <div class=" flex gap-3 justify-center">
                        <button className="button w-24 bg-themeDanger dark:bg-themeSecondary text-white  "> انصراف </button>
                        <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation ">ایجاد</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addBank}  closeModal={addBankOnHide}>
                <div class="space-y-3" >
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div class="space-y-2">
                            <div class="flex gap-2">
                                <h2 className="text-xs dark:text-gray-300">کد حسابداری:</h2>
                                <div class="can-toggle demo-rebrand-1 text-xs">
                                    <input id="swich2" type="checkbox"/>
                                    <label htmlFor="swich2">
                                        <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                    </label>
                                </div>
                            </div>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">نام بانک:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">شماره حساب:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">شماره کارت:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300"> شماره شبا:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300"> واحد پول:</label>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" className="select2 w-full">*/}
                            {/*    <option value="1">ریال</option>*/}
                            {/*    <option value="1">تومان</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>
                    <div class="flex items-center justify-center gap-2">
                        <button className="button w-24 bg-themeDanger  text-white">انصراف</button>
                        <button className="button w-24 bg-themeInformation dark:bg-themeDarkInformation text-white">ثبت</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addFinanceBox}  closeModal={addFinanceBoxOnHide}>
                <div class="space-y-3" >
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div class="space-y-2">
                            <div class="flex gap-3">
                                <h2 className="text-xs dark:text-gray-300">کد حسابداری:</h2>
                                <div class="can-toggle demo-rebrand-1 text-xs">
                                    <input id="swich2" type="checkbox"/>
                                    <label htmlFor="swich2">
                                        <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                    </label>
                                </div>
                            </div>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">نام صندوق:</label>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" className="select2 w-full">*/}
                            {/*    <option value="">مسعود</option>*/}
                            {/*</select>*/}
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">واحد پول:</label>
                            <MultiDataSelect placeholder={'انتخاب'} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">توضیحات:</label>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                    </div>
                    <div class="flex items-center justify-center gap-2">
                        <button className="button w-24 bg-themeDanger  text-white">انصراف</button>
                        <button className="button w-24 bg-themeInformation dark:bg-themeDarkInformation text-white">ثبت</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addFunds}  closeModal={addFundsOnHide}>
                <div class=" space-y-3" >
                    <div class="grid grid-cols-2 gap-3">
                        <div class="space-y-1">
                            <div class="flex gap-3">
                                <h2 className="text-xs dark:text-gray-300">کد حسابداری:</h2>
                                <div class="can-toggle demo-rebrand-1 text-xs">
                                    <input id="swich2" type="checkbox"/>
                                    <label htmlFor="swich2">
                                        <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                    </label>
                                </div>
                            </div>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">نام تنخواه گردان:</label>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" id="" className="select2 w-full">*/}
                            {/*    <option value="">مسعود</option>*/}
                            {/*</select>*/}
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">واحد پول:</label>
                            <MultiDataSelect placeholder={'انتخاب'} />
                            {/*<select name="" id="" className="select2 w-full" data-hide-search="true">*/}
                            {/*    <option value="1">ریال</option>*/}
                            {/*    <option value="1">تومان</option>*/}
                            {/*</select>*/}
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">توضیحات</label>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                    </div>
                    <div class="">
                        <button className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">ثبت</button>
                    </div>
                </div>
            </FantasyModal>
        </>

    )

}
