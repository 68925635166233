import React, { useEffect, useMemo, useState } from "react";
import { DELETE, GET } from "../../../../Services/AxiosService/AxiosApiService";
import { useTranslation } from "react-i18next";
import { GridDateFomartter } from "../../../Commans/Grid/GridDateFomartter";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";

import { GridButtonEdit } from "../../../Commans/Grid/GridButtonEdit";
import { GridButtonDelete } from "../../../Commans/Grid/GridButtonDelete";
import Swal from "sweetalert2";
import { AlertSetting, GridSetting } from "../../../../Services/Globals/Public/AppSetting";
import {Link, useParams} from "react-router-dom";
import { HPrimeIcon } from "../../../Commans/UIParts/HPrimeIcon";
import {DownloadFile, DownloadFileHandler} from "../../../../ApiServices/Public/FtpController";

export const useContractGrid = (showSpinner, hideSpinner,setContract, refresh, Refresh, initialSource, setIsEdit,setIsShowModalContracts,setFiles,handleInitialCards,handleGetAllCards) => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const [rowData, setRowData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 0, page: 0, pageSize: GridSetting.pageSize, totalCount: 0, hasNextPage: false, hasPreviousPage: false, lastPage: 0, currentPage: 1 })
    const [filter, setFilter] = useState({
        contractType:null,
        startContract: null,
        endContract: null,
    });




    const handleGetContract= async(contractId)=>{
        try {
            showSpinner();
            const response = await GET(`/Users/Contracts/${contractId}`, {}, 'single');
            if(response.status === 200){

                if (response.data.files.length === 0) {
                    handleInitialCards();
                }
                else {
                    const fileCards = handleGetAllCards(response.data.files);
                    setFiles(fileCards);
                }

                setContract({
                    userId:response.data.userId,
                    contractId:response.data.contractId,
                    description:response.data.description,
                    contractType: initialSource.contractTypes.find((item)=>item.value === response.data.contractType) ?? null,
                    contractAttach: response.data.contractAttach,
                    startContract: response.data.startContract,
                    endContract: response.data.endContract
                })
                hideSpinner();
                setIsShowModalContracts(true);
                setIsEdit(true);
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner()
        }
    }

    const handleDownloadContractAttachment=async(contractId)=>{
        try {
            showSpinner();
          const response = await GET(`/Users/Contracts/${contractId}/Download`,{},'single');
          if(response.status === 200){
              hideSpinner();
              await DownloadFile(null,'contracts',`/Users/Contracts/${contractId}/Download`)
          }
          else{
              handleApiError(response,hideSpinner);
          }
        }
        catch (e) {
            hideSpinner();
        }
    }

    const handelDeleteDataGrid = async (contractId) => {
        // show sweetalert
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const res = await DELETE(`/Users/Contracts/${contractId}`);
                    if (res.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(res, hideSpinner)
                    }
                } catch {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })

    }

    const handleGetContractType=(type)=>{
        const contractType = initialSource.contractTypes.find((item)=>item.value === type);
        if(!contractType){
            return ;
        }
        return contractType.label ?? '-'
    }

    // Display data to the user
    const columnDefs = [
        { headerName: t("row"), field: 'id', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110, hide: true },
        { headerName: t("row"), field: 'idSequence', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110 },
        {
            headerName: t("ContractType"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {handleGetContractType(parameters.data.contractType)}
                </>

        },

        {
            headerName: t("StartDate"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {<GridDateFomartter date={parameters.data.startContract} hasTime={true} />}
                </>

        },

        {
            headerName: t("EndDate"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {<GridDateFomartter date={parameters.data.endContract} hasTime={true} />}
                </>

        },
        {
            headerName: t("ContractDescription"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.description}
                </>
        },

        {
            headerName: t("operations"), width: 110, maxWidth: 120, pinned: "left", lockPinned: true, cellClass: "lock-pinned",
            cellRenderer: parameters => {
                return <div className={'flex justify-center items-center gap-3'} >
                    <button type={"button"} onClick={async()=>handleDownloadContractAttachment(parameters.data.contractId)} className="text-themeInformation" ><HPrimeIcon icon={'pi-download'} /></button>
                    <GridButtonEdit
                        onClick={() => handleGetContract(parameters.data.contractId)}
                    />
                    <GridButtonDelete
                        onClick={() => handelDeleteDataGrid(parameters.data.contractId)}
                    />
                </div>
            }

        },

    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    // Pagination handel
    const Paginate = (items) => {
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }

    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({ ...pagination, pageSize: pageSize }))
    }


    // Search handel
    const handleSearch = () => {
        setFilter((prevState) => ({ ...prevState }));
    }
    const handleResetFilters = () => {
        setFilter((prevState) => ({ ...prevState, contractType: null,startContract:null,endContract:null }));
    }
    const handleFilterContractStartDate = (dateTime) => {
        setFilter({ ...filter, startContract: dateTime })
    }
    const handleFilterContractEndDate = (dateTime) => {
        setFilter({ ...filter, endContract: dateTime })
    }
    const handleSelectFilterValue = (data,name)=>{
        if(!data){
            return;
        }
        setFilter(({...filter,[name]:data}));
    }




    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage,pagination.pageSize, filter.contractType, filter.startContract, filter.endContract])

    const OnGridReady = async () => {
        try {
            showSpinner();
            const res = await GET(`/Users/Contracts`, {
                UserId: userId,
                StartContract: filter.startContract,
                endContract: filter.endContract,
                contractType:filter.contractType !== null?filter.contractType.value:filter.contractType,
                PageNumber: pagination.currentPage,
                PageSize: pagination.pageSize,
            }, 'PagedList');
            if (res && res.items !== null) {
                setPagination({ ...pagination, totalPages: res.totalPages, hasNextPage: res.hasNextPage, hasPreviousPage: res.hasPreviousPage, page: res.page, pageSize: res.pageSize, totalCount: res.totalCount });
                const items = Paginate(res.items);
                setRowData(items);
            }
            else {
                handleApiError(res, hideSpinner);
            }
        } catch {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };

    return {
        columnDefs,
        defaultColDef,
        getRowHeight,
        autoGroupColumnDef,
        rowData,
        pagination,
        handlePreviousPage,
        handleNextPage,
        handleCurrentPage,
        handlePageSizeCount,
        handleFilterContractStartDate,
        handleFilterContractEndDate,
        filter,
        setFilter,
        handleSelectFilterValue,
        handleSearch,
        handleResetFilters,
    }
}