import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const DashboardItem=({dashboardData,handleSelectValue,selectedDashboard})=>{
    const { t } = useTranslation();
    const handleSelection = (id) => {
        handleSelectValue(id,'dashboard');
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 px-2">
            {dashboardData.map(item => (
                <div

                    key={item.value}
                    className={`box post-input post-input-design dark:bg-themeDarkPostInput overflow-hidden ${selectedDashboard === item.value ? ' border border-themeInformation dark:border-themeDarkColorLine bg-themeLightGray dark:bg-themeDarkInformation' : ''} `}
                >
                    <div className="w-full H_aspect_15 ">
                        <img src={item.picture} alt="" className="size-full absolute inset-0" />
                    </div>
                    <div className="p-2 space-y-2">
                        <div>
                            <h2 className="text-themeInformation dark:text-gray-300">{item.label}</h2>
                        </div>
                        <div>
                            <label
                                htmlFor={`content-${item.value}`}
                                className={`block button bg-themeInformation w-full text-nowrap  text-white ${selectedDashboard === item.value ? 'dark:bg-themeDarkPostInput' : 'dark:bg-themeDarkInformation'} `}
                                onClick={() => handleSelection(item.value)}
                            >
                                <input
                                    type="radio"
                                    className="hidden"
                                    data-hm="website"
                                    name="jj"
                                    id={`content-${item.value}`}
                                    checked={selectedDashboard === item.value}
                                    onChange={() => handleSelection(item.value)}
                                />
                                {t("selection")}
                            </label>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}