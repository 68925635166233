import React, {useEffect} from "react";
import Prism from 'prismjs'
import 'prismjs/components/prism-csharp'
import 'prismjs/themes/prism-tomorrow.css'
export const InnerException=({message})=>{

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return (<>
        <pre>
            <code className={'language-csharp'}>
                 {message}
            </code>
        </pre>
    </>)
}