import React, {useEffect} from "react";
import {useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {DashboardInvoiceRequest} from "../../../Dashboard/DashboardInvoiceRequest";
import {useDefaultDashboardWidgets} from "../../../Dashboard/DefaultDashboards/useDefaultDashboardWidgets";
import {useDashboard} from "../../../Dashboard/useDashboard";
import {UserNote} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserNote";
import {UserComment} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserComment";

export const HandleDashboardSupport=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();


    return (

        <div className={"relative min-h-screen"}>
            <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                </button>
                <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                    <div className="space-y-10">
                        <div>
                            <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                <h2 className="text-base dark:text-gray-300 -intro-x">
                                    {t("GeneralReport")}
                                </h2>
                                <Link to="#" className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                    <HPrimeIcon
                                        icon={"pi-sync"}
                                    />
                                    {t("Reload")}
                                </Link>
                            </div>
                            <div className={`grid gap-x-5 gap-y-10 py-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("NewTicket")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        تیکت های پاسخ داده شده(امروز)
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>
                                                            10
                                                        </p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                       درانتظار پاسخ مشتری
                                                    </h2>
                                                </div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        کل تیکت های سیستم
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-5 gap-2"}>
                            <div className={"-intro-x"}>
                                <div className="sell report-box">
                                    <div className="box bg-themeInformation p-2 dark:bg-themeDarkSalt700">
                                        <div className="space-y-2 text-center">
                                            <h2 className={"text-xs text-white dark:text-gray-300"}>تیکت های جاری</h2>
                                            <h2 className="text-white dark:text-gray-300">100</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="sell report-box">
                                    <div className="box bg-themeInformation p-2 dark:bg-themeDarkSalt700">
                                        <div className="space-y-2 text-center">
                                            <h2 className={"text-xs text-white dark:text-gray-300"}>تیکت های مهم</h2>
                                            <h2 className="text-white dark:text-gray-300">100</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="sell report-box">
                                    <div className="box bg-themeInformation p-2 dark:bg-themeDarkSalt700">
                                        <div className="space-y-2 text-center">
                                            <h2 className={"text-xs text-white dark:text-gray-300"}>تیکت های محرمانه</h2>
                                            <h2 className="text-white dark:text-gray-300">100</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="sell report-box">
                                    <div className="box bg-themeInformation p-2 dark:bg-themeDarkSalt700 ">
                                        <div className="space-y-2 text-center">
                                            <h2 className={"text-xs text-white dark:text-gray-300"}>تیکت های اضطراری</h2>
                                            <h2 className="text-white dark:text-gray-300">100</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"-intro-x space-y-3"}>
                            <div>
                                <h2 className="text-base dark:text-gray-300">
                                    آخرین تیکت ها
                                </h2>
                            </div>
                            <div>
                                <DashboardInvoiceRequest/>
                            </div>
                        </div>
                        <div>
                            <div className="bg-white box dark:bg-themeDarkSalt700 space-y-2">
                                <div className="border-b-2 p-2 dark:border-themeDarkColorLine ">
                                        <div className={"post-input p-2 post-input-design dark:bg-themeDarkPostInput "}>
                                            <div className={"flex items-center gap-2"}>
                                                <div class="size-14 bg-white dark:bg-themeDarkSalt700 border relative shadow rounded dark:border-themeDarkColorLine overflow-hidden">
                                                    <img className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                                    <div class="w-3 h-3 bg-themeNotif absolute end-0 bottom-0 rounded-full border-2 border-white"></div>
                                                </div>
                                                <div class="dark:text-gray-300">{t("OnlineSupport")}</div>
                                            </div>
                                        </div>
                                    </div>
                                <div className={"p-2 height_32rem overflow-y-auto scrollbar"}>
                                        <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-5"}>
                                            <Link to={"#"} className="zoom-in overflow-hidden post-input post-input-design  dark:bg-themeDarkPostInput block">
                                                <div class="flex">
                                                    <div class="p-3 bg-themeInformation grid place-content-center dark:bg-themeDarkInformation">
                                                        <span className={"text-white"}>1</span>
                                                    </div>
                                                    <div className={"space-y-2 p-2"}>
                                                        <div>
                                                            <p className="text-xs line-clamp-1 text-themeInformation dark:text-themeDarkInformation">لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</p>
                                                        </div>
                                                        <div>
                                                            <h2 className={"text-xs dark:text-gray-300"}>مسعود احمدی محمدآبادی</h2>
                                                            <p className={"font-light dark:text-gray-300"}>شرکت آرین</p>
                                                        </div>

                                                        <div className={"flex justify-between items-center gap-2 bg-white p-2 box dark:bg-themeDarkSalt700"}>
                                                            <span className={"block text-xs dark:text-themeDarkInformation"}>{<GridDateFomartter hasTime={true} />}</span>
                                                            <HPrimeIconSmall icon={"pi-paperclip"} iconClass={"text-themeInformation dark:text-gray-300"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                    <div>
                        <div>
                            <h2 className="text-base dark:text-gray-300">
                                {t("Notes")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            {
                                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                    <UserNote
                                        key={index}
                                        fullName={item.fullName}
                                        id={item.id}
                                        message={item.message}
                                        createdAt={item.createdAt}
                                    />
                                ))
                            }
                            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("UserComments")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 dashboard-note height_21rem overflow-y-auto scrollbar-hidden "}>
                                {
                                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                        <UserComment
                                            key={index}
                                            fullName={item.fullName}
                                            id={item.id}
                                            message={item.message}
                                            createdAt={item.createdAt}
                                            state={item.state}
                                        />
                                    ))
                                }
                            </div>
                            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-between items-center gap-2">
                            <p className={"text-lg dark:text-gray-300"}> {t("ReleaseQueue")}</p>
                            <span className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                    <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                    </div>
                                    <div class="flex-1">
                                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("Draft")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}