import Joi from 'joi';

export const companySchema = Joi.object({

    name: Joi.string().allow('').allow(null),
    // nationalCode: Joi.when('name', {
    //     is: Joi.string().not('').not(null).required(),
    //     then: Joi.string().required().pattern(/^[0-9]{11}$/).messages({
    //         'string.pattern.base': 'فرمت کد ملی صحیح نمیباشد',
    //         'any.required': 'کد ملی  شرکت الزامیست',
    //         'string.empty': 'کد ملی  شرکت الزامیست',
    //         'string.base': 'کد ملی  شرکت الزامیست'
    //     }),
    //     otherwise: Joi.string().allow('').allow(null),
    // }),


    // Other properties in your schema...
    // postalCode: Joi.string().allow('').allow(null)
    //     .pattern(/^\d{5}-\d{5}$/)
    //     .messages({
    //         'string.pattern.base': 'فرمت کد پستی صحیح نمیباشد',
    //     }),
    // registrationNumber: Joi.string().allow('').allow(null)
    //     .pattern(/^0[0-9]{2,}[0-9]{7,}$/)
    //     .messages({
    //         'string.pattern.base': 'فرمت شماره ثابت صحیح نمیباشد',
    //     }),
}).options({ abortEarly: false });

