import React from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import {TinyEditor} from "../../../../Commans/UIParts/TinyEditor";

export const SpecificationTab=({product,handleValue,handleChangeContent})=>{

    const { t } = useTranslation();

    return (
        <>
            <div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                <label className="dark:text-gray-300 text-xs">
                    {t("TextSummary")}
                </label>
                <InputTextarea  name={"excerpt"} value={product.excerpt} onInput={(e)=>handleValue(e)} rows={3} cols={30} className={"input w-full"} />
            </div>
            <div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                <label  className="dark:text-gray-300 text-xs">
                    {t("FullText")}
                </label>
                <div className={"custom-editor-class"}>
                    <TinyEditor
                        name={'content'}
                        handleEditorContent={handleChangeContent}
                        content={product.editor??''}
                    />
                </div>

            </div>


    </>
    )
}