import React from "react";
import {DefaultDashboard} from "../Dashboard/DefaultDashboards/DefaultDashboard";
import {HandleDashboard2} from "./Dashboard22/HandleDashboard2";



export const Dashboard2=()=>{

    return (
        <HandleDashboard2/>
    )
}