import React, {useState} from "react";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

export const useCustomBankAccount=(setErrors)=>{
    const [bankAccount,setBankAccount] = useState({bankAccountId:null,title:null,accountNumber:null,cardNumber:null,shebaNumber:null,description:null,bankId:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setBankAccount({...bankAccount,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=async (data,name)=>{
        try {
            setBankAccount({ ...bankAccount, [name]: data});

        }catch (e) {
            console.log(e);
        }

    }

    return {
        handleValue,
        bankAccount,
        setBankAccount,
        handleSelectValue
    }
}