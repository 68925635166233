import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
// import 'primereact/dialog/dialog.min.css';
import {Dialog} from "primereact/dialog";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {Export} from "../../../Commans/Grid/Export";
import {GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import Select from "react-select";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {useCustomSentBoxGrid} from "./useCustomSentBoxGrid";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";


export const HandleSendBoxSms=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [showSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(0);
    const [visible, setVisible] = useState(false);
    const [initialSource,setInitialSource]=useState({accounts:[]});
    const [message,setMessage]  = useState('');

    const handleShowSpinner=()=>{
        setShowSpinner(true);
    }
    const handleHideSpinner=()=>{
        setShowSpinner(false);
    }

    //refreshes components
    const Refresh=(state)=>{
        setRefresh({...state})
    }
    // handles loading spinner in responsne time
    const onApiError=()=>{
        setShowSpinner(false);
    }
    const handleInitialData=(accounts)=>{
        const accountData = convertSelectDataHandler(accounts);
        return {accountData}
    }

    const fetchData=async ()=>{
        try {
           handleShowSpinner();
            const responseAccount = await GET(`/Messaging/Accounts/ActiveAccounts`,{},'IEnumerable');
            if(responseAccount.status === 200){
                const {accountData}=  handleInitialData(responseAccount.data);
                setInitialSource({
                    accounts: accountData
                })
              handleHideSpinner();
            }
        }catch (e) {
            onApiError();
            console.log(e);
        }
    }
    useEffect(()=>{
        fetchData()
    },[])


    const handleShowMessage =async(visible,message,messageId,isRead)=>{
        try {
            if(isRead){
                setVisible(visible);
                setMessage(message);
                return;
            }
            handleShowSpinner()
            const response = await PUT(`/Messaging/SMS/Read`,{messageId:messageId},'single');
            if(response.status === 200){
                handleHideSpinner();
                setVisible(visible);
                setMessage(message);
                Refresh();
            }else{
                handleApiError(response,handleHideSpinner);
            }
        }
        catch (e) {
            handleHideSpinner();
        }
    }


    const {autoGroupColumnDef,handleSelectFilter,pagination,handleResetFilters,filter,columnDefs,handleCurrentPage,handlePreviousPage,handleSearch,handleNextPage,handlePageSizeCount,defaultColDef,setFilter,search,setSearch,rowData,getRowHeight,handleToDateTime,handleFromDateTime} = useCustomSentBoxGrid(setShowSpinner,fetchData,refresh,onApiError,initialSource,Refresh,handleShowMessage)





    return(
        <>

            <Dialog header={t("MessageText")} visible={visible} className={"Dialog-ShowText"} onHide={() => setVisible(false)} >
                {message}
            </Dialog>

            <div class=" space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByNumber")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("senderNumber")}</label>
                            <Select
                                options={initialSource.accounts}
                                isSearchable={true}
                                onChange={(data)=>handleSelectFilter(data,'operator')}
                                placeholder={t("selection")}
                                value={filter.operatorValue}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">
                                {t("FromDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleFromDateTime} value={filter.fromDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">
                                {t("ToDate")}
                            </label>
                            <DateTimePicker value={filter.toDate} handleDateTime={handleToDateTime} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>handleResetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/Send`)}
                                            tooltipTitle={t("sendingMessage")}
                                            title={t("sending")}
                                            icon={"pi-send"}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/Inbox`)}
                                            icon={"pi-inbox"}
                                            title={t("Receive")}
                                            tooltipTitle={t("receivingBox")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                                            icon={"pi-user"}
                                            title={t("Secretary")}
                                            tooltipTitle={t("smsSecretary")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                                            icon={"pi-code"}
                                            title={t("Pattern")}
                                            tooltipTitle={t("creatingATemplate")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                                            icon={"pi-bolt"}
                                            title={t("Charge")}
                                            tooltipTitle={t("PanelRecharge")}

                                        />
                                    </>
                                }

                    />


                </div>
                <div>
                    <div className={"space-y-2 H-body-ag-grid relative"} >
                        {
                            showSpinner?
                                <div className=" huploader_loading" >
                                    <div className="relative">
                                        <LoadingHarmony/>
                                    </div>
                                </div>
                                :
                                null
                        }

                        <GridTable
                            columnDefs={columnDefs}
                            rowSelection={GridSetting.rowSelection}
                            rowData={rowData}
                            style={GridSetting.gridStyle}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            pagination={GridSetting.pagination}
                            enableRtl={GridSetting.enableRtl}
                            domLayout={GridSetting.domLayout}
                            paginationPageSize={GridSetting.pageSize}
                            getRowHeight={getRowHeight}
                            localeText={AgGridEnglish}
                        />
                        <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    </div>
                    <Export apiUrl={''} filters={filter}/>
                </div>
            </div>
        </>
    )

}