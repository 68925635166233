import React from "react";

export const PackageItem=({item,index,handleAddOrDeletePackage,packageId,name,logo,description})=>{

    const handleChange = () => {
        handleAddOrDeletePackage(packageId,name);
    };

    return (<>
        <div  className="add_web border-animation overflow-hidden dark:bg-themeDarkPostInput post-input post-input-design">
            <label htmlFor={packageId} className="cursor-pointer">
                <input type="checkbox" className="checkbox-input hidden"
                       id={packageId}
                       onChange={handleChange}
                />
                <div className="checkbox-tile checkbox-wrapper size-full flex gap-2 p-2 items-center">
                    <div className="size-16 flex-shrink-0  rounded-md overflow-hidden">
                        <img src={logo} alt="" className="size-full object-cover" />
                    </div>
                    <div className="flex-1 p-1 checkbox-label">
                        <h2>{name}</h2>
                        <p className="text-xs">{description}</p>
                    </div>
                </div>
            </label>
        </div>
    </>)
}