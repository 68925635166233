import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";

import {Button} from "primereact/button";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {InputTextarea} from "primereact/inputtextarea";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Accordion, AccordionTab} from "primereact/accordion";
import Select from "react-select";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import queryString from "query-string";
import {useFetchData} from "./useFetchData";
import {useDataBinder} from "./useDataBinder";
import {useCommunication} from "./useCommunication";
import {useModal} from "./useModal";
import {useGrid} from "./useGrid";
import {commentSchema} from "./commentSchema";
import {handleCreateComment, handleEditComment} from "./CommentService";
import {POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {Export} from "../../Commans/Grid/Export";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {InputNumber} from "primereact/inputnumber";
import {ContactUs} from "../../Commans/Globals/ContactUs";

import {Dialog} from "primereact/dialog";
// import 'primereact/dialog/dialog.min.css';
import {validate} from "../../../Services/Globals/validate";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandleConfirmed = () => {

    const redirectTo = useNavigate();
    const [IsEdit,setIsEdit] = useState(false);
    const queryParams = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const [result,setResult] = useState(0);
    const {options,setOptions,handleFetchData,spinner,setSpinner,onApiError} = useFetchData();
    const {comments,setComments,errors,handleNumberValue,handleValue,setErrors,handleSelectValue,onSetErrors}=useDataBinder();
    const {setCommunication,communication,handleAddCommunication,handleRemoveCommunication,handleCommunicationChange} = useCommunication(options,setComments);
    const {handleCloseComment,handleOpenComment,handleOpenCommunication,handleCloseCommunication,modal,setModal} = useModal(setErrors,options,setComments,setCommunication,setIsEdit,'/CMS/Comment/Confirmed');
    const {autoGroupColumnDef,switchData,rowData,resetFilters,data,handleSearch,filterValueHandler,handleNextPage,handlePageSizeCount,handleCurrentPage,handlePreviousPage,setCurrentPage,currentPage,setData,defaultColDef,getRowHeight,columnDefs,filter,selectedRows,onSelectionChanged,search,setFilter,setSearch,handleFilterValue,contact,handleCloseMessage,message,visible,setVisible} = useGrid(setSpinner,handleFetchData,onApiError,result,setResult,setIsEdit,setModal,setComments,options,setCommunication,'/CMS/Comment/Confirmed',1,'/comments/Reject','/Logically');


    const handleSumbitComment=async (e) => {
        e.preventDefault();
        if(IsEdit) {
            try {
                const isValid = validate(commentSchema,comments,onSetErrors);
                if (!isValid) {
                    return;
                }
                setSpinner(true);
                comments.communications = communication.filter((row) =>  (row.communicationMode && row.communicationId && row.communicationValue) || row.description);
                const convertedComments = handleEditComment(comments,queryParams.commentId);
                const {id,fullName,adminDislikeCounts,adminLikeCounts,adminReply,adminReplyRateCommenting,dislikeCounts,likeCounts,rateCommenting,message,slug,state,communications,parentId} = convertedComments;
                const response = await PUT(`/comments`,{id,fullName,adminDislikeCounts,adminLikeCounts,adminReply,adminReplyRateCommenting,dislikeCounts,likeCounts,rateCommenting,message,slug,state,communications,parentId},false)
                if(response.status === 200){
                    setIsEdit(false);
                    handleCloseComment();
                    setResult({...response.status});
                    handleSuccessMessage(t('CommentEditSuccess'));
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                console.log(e);
            }

        }
        else {
            try {
                const isValid = validate(commentSchema,comments,onSetErrors);
                if (!isValid) {
                    return;
                }
                setSpinner(true);
                comments.communications = communication.filter((row) =>  (row.communicationMode && row.communicationId && row.communicationValue) || row.description);
                const convertedComments = handleCreateComment(comments,queryParams.commentId);
                const {fullName,adminDislikeCounts,adminLikeCounts,adminReply,adminReplyRateCommenting,dislikeCounts,likeCounts,rateCommenting,message,slug,state,communications,parentId} = convertedComments;
                const response = await POST(`/Comments`,{fullName,adminDislikeCounts,adminLikeCounts,adminReply,adminReplyRateCommenting,dislikeCounts,likeCounts,rateCommenting,message,slug,state,communications,parentId},false);
                if(response.status === 200){
                    setSpinner(false);
                    setModal({commentAdd: false});
                    setResult({...response.status});
                    handleCloseComment();
                    handleSuccessMessage(t('CommentAddSuccess'));
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
    }





    const handleRejectAll = async ()=>{
        if(selectedRows.length === 0){
            return;
        }
        const data = {commentIds: selectedRows.map((item) => item.id)};

        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:t('AreyouSuretoRejectAll'),
            text: t('ThereIsNowayback'),
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText:t('Reject'),
            cancelButtonText:t('Cancel'),
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true);

                    const response = await PUT(`/comments/Reject`,data,false);
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                        handleSuccessMessage(t('CommentsRejectedSuccessfully'))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    return (
        <>

            <Dialog header="Header" visible={visible}  className={"Dialog-ShowText"} onHide={() => setVisible(false)}>
                {message}
            </Dialog>



            <div className="space-y-5 relative">
                {
                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("SearchByNameMessage")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("ArticleAddress")}</label>
                            <Select
                                options={options.slugs}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={false}
                                onChange={(data)=>handleFilterValue(data,'slug')}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>handleOpenComment()}
                                            tooltipTitle={t("AddComment")}
                                        />
                                        {
                                            queryParams.commentId?
                                                <ButtonPlus
                                                    onClick={()=>redirectTo('/CMS/Comment/Confirmed')}
                                                    icon={"pi-inbox"}
                                                    title={t("BacktoHome")}
                                                    tooltipTitle={t("BacktoHome")}

                                                />:
                                                null
                                        }
                                        <ButtonPlus
                                            onClick={()=>handleRejectAll()}
                                            icon={"pi-times-circle"}
                                            title={t("Disapprove")}
                                            tooltipTitle={t("DisapproveSelectedComments")}
                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />


                </div>
                <div className={"space-y-2 H-body-ag-grid"} >

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        onSelectionChanged={onSelectionChanged}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}

                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <ToastContainer />
                <Export filters={filter} apiUrl={'/Packages/PackageListExport'}/>

            </div>


            <FantasyModal openModal={modal.communication}  closeModal={handleCloseCommunication} title={t("listOfContactMethods")}>

                <ContactUs userContact={contact}/>

            </FantasyModal>


            <FantasyModal openModal={modal.commentAdd}  closeModal={handleCloseComment} title={t("Comment")} customeClassName={"modal-x"}>
                <form onSubmit={handleSumbitComment} method={'post'}>
                    <div class="space-y-5">
                        <div className={"space-y-2"}>
                            <div class={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2"}>
                                <div class="space-y-2 ">
                                    <div className={"flex justify-between items-center"}>
                                     <label className="text-xs dark:text-gray-300">
                                        {t("userName")}
                                         <RequiredIcon/>
                                    </label>
                                        <DisplayError message={errors.fullName}/>
                                    </div>
                                    <InputText value={comments.fullName} name={'fullName'}  onInput={(e)=>handleValue(e)} type={"text"} className={" input w-full"} />
                                </div>
                                <div class="space-y-1 ">
                                    <label className="text-xs dark:text-gray-300">{t("PageAddress")}</label>
                                    <Select
                                        options={options.slugs ?? null}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'slug')}
                                        placeholder={t("selection")}
                                        value={comments.slug}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>

                                <div>
                                    <div className={"flex gap-2"}>
                                        <div class="space-y-1 flex-1">
                                            <label className="text-xs dark:text-gray-300">{t("Rating")}</label>
                                            <div>
                                                <InputNumber showButtons min={0} className={'text-center'}  onValueChange={(e)=>handleNumberValue(e.value,'rateCommenting')} value={comments.rateCommenting}  />
                                            </div>
                                        </div>
                                        <div class="space-y-1 flex-1">
                                            <label className="text-xs dark:text-gray-300">{t("Like")}</label>
                                            <div>
                                                <InputNumber showButtons  min={0} className={'text-center'}  onValueChange={(e)=>handleNumberValue(e.value,'likeCounts')} value={comments.likeCounts}  />
                                            </div>
                                        </div>
                                        <div class="space-y-1 flex-1">
                                            <label className="text-xs dark:text-gray-300">{t("Dislike")}</label>
                                            <div>
                                                <InputNumber showButtons  min={0} className={'  text-center'}   onValueChange={(e)=>handleNumberValue(e.value,'dislikeCounts')} value={comments.dislikeCounts}  />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="space-y-1 ">
                                    <label className="text-xs dark:text-gray-300">{t("Status")}</label>
                                    <Select
                                        options={options.states}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'state')}
                                        placeholder={t("selection")}
                                        value={comments.state}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("CommentText")}</label>
                            <InputTextarea rows={5} onInput={(e)=>handleValue(e)} name={'message'} value={comments.message}  type={"text"}  className={" input w-full"} />
                        </div>
                                <Accordion className={"space-y-3"}>
                                    {
                                        IsEdit?null:
                                    <AccordionTab className={"bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}
                                        header={
                                            <div>
                                                {t("ManagementResponse")}
                                            </div>
                                        }

                                    >
                                        <div className={"post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                                            <div class="space-y-2 ">
                                                <label className="text-xs dark:text-gray-300">{t("ManagementResponse")}</label>
                                                <InputTextarea onInput={(e)=>handleValue(e)} name={'adminReply'} value={comments.adminReply}  type={"text"}  className={" input w-full"} />
                                            </div>
                                            <div className={"max-w-sm flex gap-2"}>
                                                <div class="space-y-1 flex-1">
                                                    <label className="text-xs dark:text-gray-300">{t("Rating")}</label>
                                                    <div>
                                                        <InputNumber showButtons  min={0} className={' text-center'}   onValueChange={(e)=>handleNumberValue(e.value,'adminReplyRateCommenting')} value={comments.adminReplyRateCommenting}  />
                                                    </div>
                                                </div>
                                                <div class="space-y-1 flex-1">
                                                    <label className="text-xs dark:text-gray-300">{t("Like")}</label>
                                                    <div>
                                                        <InputNumber showButtons  min={0} className={'  text-center'}   onValueChange={(e)=>handleNumberValue(e.value,'adminLikeCounts')} value={comments.adminLikeCounts}  />
                                                    </div>
                                                </div>
                                                <div class="space-y-1 flex-1">
                                                    <label className="text-xs dark:text-gray-300 ">{t("Dislike")}</label>
                                                    <div>
                                                        <InputNumber showButtons  min={0} className={'  text-center'}   onValueChange={(e)=>handleNumberValue(e.value,'adminDislikeCounts')} value={comments.adminDislikeCounts}  />
                                                    </div>
                                                </div>
                                            </div>
                                            {/*درهنگام ادیت این قسمت disable*/}
                                        </div>



                                    </AccordionTab>
                                    }

                                    <AccordionTab className={"bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}
                                        header={
                                            <div>
                                                {t("listOfContactMethods")}
                                            </div>
                                        }
                                    >

                                        <div className="space-y-3 ">
                                            {communication.map((row,index) => (
                                                <div key={row.fakeId} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 p-2 post-input dark:bg-themeDarkSalt700 post-input-design ">
                                                    <div className={"flex items-center gap-1"}>
                                                        <div className="mt-auto">
                                                            <div className="number_pluse bg-themeInformation text-white">
                                                                {index+1}
                                                            </div>
                                                        </div>
                                                        <div className="space-y-1 flex-1">
                                                            <label className="text-xs dark:text-gray-300">{ t("CommunicationType")}</label>
                                                            <Select
                                                                options={options.types}
                                                                isMulti={false}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={(data) => handleCommunicationChange(row.fakeId, 'communicationId',data,row.id)}
                                                                placeholder={t("selection")}
                                                                value={row.communicationId}
                                                                classNamePrefix={"h_multi_select"}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label className="text-xs dark:text-gray-300">{t("TypeIndividualLegal")}</label>
                                                        <Select
                                                            options={options.modes}
                                                            isMulti={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={(data) => handleCommunicationChange(row.fakeId, 'communicationMode',data,row.id)}
                                                            placeholder={t("selection")}
                                                            value={row.communicationMode}
                                                            menuPosition={'fixed'}
                                                            classNamePrefix={"h_multi_select"}
                                                        />
                                                    </div>
                                                    <div className="space-y-1">
                                                        <label className="text-xs dark:text-gray-300">{t("ContactAddress")}</label>
                                                        <input type="text" className="input w-full" value={row.communicationValue} onChange={(e) => handleCommunicationChange(row.fakeId, 'communicationValue', e.target.value,row.id)}/>
                                                    </div>
                                                    <div className="flex gap-1 items-end">
                                                        <div className="space-y-1 flex-1">
                                                            <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                                            <input type="text" className="input w-full"
                                                                   value={row.description}
                                                                   onChange={(e) => handleCommunicationChange(row.fakeId, 'description', e.target.value,row.id)}
                                                            />
                                                        </div>
                                                        <button type="button" className="small_button button bg-themeInformation dark:bg-themeDarkInformation text-white"
                                                                onClick={handleAddCommunication}>
                                                            <HPrimeIcon icon={'pi-plus'}  />
                                                        </button>
                                                        <button type="button" className="button small_button bg-themeDanger text-white"
                                                                onClick={() => handleRemoveCommunication(row.fakeId,row.id)}>
                                                            <HPrimeIcon icon={'pi-minus'}  />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionTab>
                                </Accordion>

                        <button type={"submit"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("register")}</button>
                    </div>
                </form>
            </FantasyModal>
        </>
    )
}