import React, {useState} from "react";


import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "./HPrimeIcon";


let tooltipCounter = 0; // Global counter for tooltips
export const  ButtonNotification =({icon, onClick, sizeIcon,iconClass,tooltipTitle,}) => {
    const { t } = useTranslation();

    const [tooltipId] = useState(() => {
        tooltipCounter += 2;
        return `tooltip_${tooltipCounter}`;
    });


    return(
      <>
          <div className={` inline-block ${tooltipTitle ? `tooltip_class_${tooltipId}`:'tooltip_help'}`}>
              <HPrimeIcon icon={icon?icon:"pi-exclamation-circle"} iconClass={`cursor-pointer text-themeInformation dark:text-themeDarkInformation ${iconClass}` }  onClick={onClick} >
              </HPrimeIcon>
              <Tooltip anchorSelect={tooltipTitle ? `.tooltip_class_${tooltipId}` : ".tooltip_help"} content={tooltipTitle ? tooltipTitle : t("YourGuide")} positionStrategy={"fixed"}/>
          </div>

      </>)
}