import React, {useEffect, useState} from "react";
import {CheckBoxSection} from "../../../../Commans/Globals/CheckBoxSection";

import {useNavigate} from "react-router-dom";
import {useCustomBackup} from "./useCustomBackup";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {handleProcessBackupData} from "./backupService";
import {useTranslation} from "react-i18next";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {ListSearch} from "../../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ButtonSearchList} from "../../../../Commans/UIParts/ButtonSearchList";
import {GridTable} from "../../../../Commans/Grid/GridTable";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../../Commans/Grid/Pagination";
import {useBackGrid} from "./useBackGrid";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {InputSwitchListFilter} from "../../../../Commans/Filters/InputSwitchListFilter";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";

export const BackupTab=({handleTabClick})=>{

    const { t } = useTranslation();

    const redirectTo = useNavigate();
    const [initialSource,setInitialSource] = useState({sections:[]});
    const [IsShowSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(false)
    const {handleChangeSection,cleanData,ResetCleanData} = useCustomBackup();

    // handles loading spinner in api request

    const showSpinner=()=>{
        setShowSpinner(true)
    }
    const hideSpinner=()=>{
        setShowSpinner(false)
    }

    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }

    const handleInitialData=(sections)=>{
        const sectionData = convertSelectDataHandler(sections);
        const filteredData = sectionData.filter(item => item.value !== 1);
        setInitialSource({
            sections: filteredData
        })
    }

    // fetches all data from database in initial time of component
    const fetchData=async()=>{
        try {
            setShowSpinner(true)
            const responseSection = await GET(`/Enumerations/EnWebsiteFilesSections`,{},'IEnumerable');
            if(responseSection.status === 200 ){
                handleInitialData(responseSection.data);
            }
            else{
                handleApiError(responseSection,hideSpinner);
            }
            setShowSpinner(false);

        }
        catch (e) {
            setShowSpinner(false);
        }
    }

    useEffect(()=>{
        fetchData()
    },[refresh])


    const handleSubmitBackup=async()=>{
        try {
            setShowSpinner(true)
            if(cleanData.section.length === 0){
                handleFailureMessage(t("PleaseSelectSection"));
                setShowSpinner(false)
                return;
            }
            const dataJson = handleProcessBackupData(cleanData);
            const response = await POST(`/Settings/BackupDataSetting`,dataJson,false);
            if(response.status === 200){
                handleSuccessMessage(t("DataBackupWasDoneSuccessfully"));
                ResetCleanData();
                Refresh();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner();
        }
    }




    const {autoGroupColumnDef,columnDefs,defaultColDef,filter,setFilter,handleResetFilters,search,setSearch,pagination, handleSearch, getRowHeight, handleCurrentPage,handleNextPage,handlePageSizeCount,handlePreviousPage,rowData}=useBackGrid(showSpinner,hideSpinner,refresh,Refresh,initialSource.sections);


    return (<>

        <div className={"space-y-10"}>

            {console.log(initialSource.sections)}
            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-y-5"}>
                <div className={"space-y-5"}>
                    <div className={"bg-white dark:bg-themeDarkSalt700 space-y-3"}>
                        <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                            <h2 className={"dark:text-gray-300 text-xs"}>{t("Sectionselection")}</h2>
                        </div>
                        <CheckBoxSection
                            data={initialSource.sections}
                            onChange={handleChangeSection}
                        />
                    </div>
                    <button type={"button"} onClick={() => handleSubmitBackup()} className={"button flex gap-2 items-center justify-center bg-themeInformation dark:bg-themeDarkInformation w-full text-white"}>
                        {t("Register")}
                    </button>
                </div>
                <div className="grid place-content-center">
                    <img src="/Assets/Image/bg-img/Backup.svg" alt="backup" className={"size-full"}/>
                </div>

            </div>
            <div className={"space-y-5"}>
                <ListSearch label={null} icon={null} >
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("search")}</label>
                        <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                    </div>
                    <ButtonSearchList
                        onClickSearch={()=>handleSearch()}
                        onClickReset={()=>handleResetFilters()}
                    />
                </ListSearch>
                <ListFilter label={null} icon={null}
                            showVideo={false}
                            showReturn={false}
                            showChildrenOne={false}
                            childrenOne={
                                <>


                                </>
                            }
                            childrenTwo={
                                <>
                                    <div className="space-y-2 ">
                                        <lable className="text-xs dark:text-gray-300">{t("product")}</lable>
                                        <div>
                                            <input className="input input--switch  " type="checkbox"/>
                                        </div>
                                    </div>
                                    <div className="space-y-2 ">
                                        <lable className="text-xs dark:text-gray-300">{t("Content")}</lable>
                                        <div>
                                            <input className="input input--switch hite" type="checkbox"/>
                                        </div>
                                    </div>
                                    <div className="space-y-2 ">
                                        <lable className="text-xs dark:text-gray-300">{t("personnel")}</lable>
                                        <div>
                                            <input className="input input--switch hite" type="checkbox"/>
                                        </div>
                                    </div>
                                </>
                            }
                />

                <div className={'space-y-2 relative'}>
                    {
                        IsShowSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
            </div>

        </div>
        <ToastContainer/>
    </>)
}