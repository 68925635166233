import Joi from "joi";
//this is schema validation of login to be used for yup and reat-hook-form

export const unConfirmedSchemaVerify = Joi.object({
    transactionCode: Joi.number().integer().required().messages({
        'any.required': 'کد تراکنش الزامیست',
        'number.base': 'کد تراکنش  الزامیست',
        'number.integer': 'کد تراکنش  الزامیست'
    }),
});
export const unConfirmedSchemaReject = Joi.object({
    description: Joi.string().required().messages({
        'any.required': 'دلیل عدم تایید الزامیست',
        'string.empty': 'دلیل عدم تایید الزامیست',
        'string.base': 'دلیل عدم تایید الزامیست',
    }),
});