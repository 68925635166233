import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {GridTable} from "../Commans/Grid/GridTable";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {GET, PUT} from "../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../CMS/Contents/ContentService";
import {handleApiError} from "../../Services/Globals/Errors/handleApiError";
import {DateTimePicker} from "../Commans/Dates/DateTimePicker";
import Select from "react-select";
import {Export} from "../Commans/Grid/Export";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../Commans/Loadings/LoadingHarmony";
import {Pagination} from "../Commans/Grid/Pagination";
import {InputSwitchListFilter} from "../Commans/Filters/InputSwitchListFilter";
import {useTranslation} from "react-i18next";
import {GridDateFomartter} from "../Commans/Grid/GridDateFomartter";
import {GridSetting} from "../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../Services/Globals/Translations/Resources";
import queryString from "query-string";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {useSetting} from "../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {SettingButton} from "../Commans/UIParts/SettingButton";

export const HandleHistoryGuarranty=()=>{

    const { t } = useTranslation();

    const redirectTo = useNavigate();
    const [spinner,setSpinner] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [extend,setExtend] = useState(false);
    const [errors, setErrors] = useState({});
    const [result,setResult] = useState(0);
    const [options,setOptions] = useState({operations:[],searchMethods:[]});

    const [gurantee,setGurantee] = useState({active:true,buyerId:null,productGuranteeId:null,timeUnit:null,time:null,date:null,productCode:null,productName:null,guranteeCode:null});
    const [expand,setExpand] = useState({productGuranteeId:null,extendTime:null});

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const onApiError = ()=>{
        setSpinner(false);
    }


    const handleFetchData = async ()=>{
        try {
            setSpinner(true);
            const responseSearchMethods = await GET(`/Enumerations/EnSearchMethod`,{},'IEnumerable');
            const responseOperation = await GET('/Enumerations/EnGuaranteeOperation',{},'IEnumerable');
            if(responseOperation.status === 200 && responseSearchMethods.status === 200){
               const operations = convertSelectDataHandler(responseOperation.data);
               const searchMethods = convertSelectDataHandler(responseSearchMethods.data);
                setOptions({operations: operations,searchMethods: searchMethods});
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setSpinner(false)
        }
    }















    const handleGuranteeOperation = (operation) => {
        if (!options.operations) {
            return;
        }
        const data = options.operations.find((item) => item.value === operation);
        return data ? data.label : '';

    }



    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 110,hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 110},
        { headerName: t("CustomerName"), field: 'buyerName', minWidth: 200,},
        { headerName: t("ProductName"), field: 'productName',minWidth: 200,},
        { headerName: t("OperationDate"), minWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.creationDate} /></>
            }
        },
        { headerName: t("ExtensionDate"), minWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.extendDate} /></>
            }
        },

        { headerName: t("GuaranteeExtensionDuration"), minWidth: 150,
            cellRenderer:params => {
                return <>{params.data.newAddedDuration? params.data.newAddedDuration + " " + t("Day"):null}</>
            }
        },


        { headerName: t("OperationType"), minWidth: 140,
            cellRenderer:params => {
                return <>{handleGuranteeOperation(params.data.operation)}</>
            }
        },


        { headerName: t("ExpirationDate"), minWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.newExpirationDate} /></>
            }
        },

    ];



    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',creation:null,extension:null,toPending:null,activation:null,deActivation:null,DateSearchMethod:null,fromDate:null,toDate:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...filter,search: search}));
    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const filterSelectValueHandler= (data,name)=>{
            setFilter(({...filter,[name]: data.value}));
    }

    const resetFilters = ()=>{
        setFilter(({...filter,activation: null,deActivation: null,extension: null,creation: null,search: '',toPending: null,DateSearchMethod: null,fromDate: null,toDate: null}));
        setSearch('');
    }

    const handleRegisterToDateTime = (dateTime)=>{
        setFilter({...filter,toDate: dateTime})
    }
    const handleRegisterFromDateTime = (dateTime)=>{
        setFilter({...filter,fromDate: dateTime})
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };

        loadData();
    }, [result, currentPage,filter.search,data.pageSize,filter.extension,filter.creation,filter.toPending,filter.deActivation,filter.activation,filter.fromDate,filter.toDate]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const queryParams = queryString.parse(window.location.search);
            const response =  await GET(`/ProductGuarantees/GetProductGuaranteeHistoryList`, {PageNumber: currentPage, PageSize:data.pageSize, Search: filter.search,productGuaranteeId:queryParams.productGuaranteeId, Activation: filter.activation,FromDate:filter.fromDate,ToDate:filter.toDate,DateSearchMethod:filter.DateSearchMethod, DeActivation: filter.deActivation,Extension:filter.extension,ToPending:filter.toPending,Creation:filter.creation}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };




    const switchData = [
        {name:'creation',lable:t("creation"),value:filter.creation},
        {name:'extension',lable:t("extension"),value:filter.extension},
        {name:'toPending',lable:t("SentToProductionLine"),value:filter.toPending},
        {name:'activation',lable:t("isActive"),value:filter.activation},
        {name:'deActivation',lable:t("isNotActive"),value:filter.deActivation},
    ]


    return(
        <>

            {console.log(expand)}
            <div class=" space-y-5 ">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2 ">
                                <label className="text-xs dark:text-gray-300">{t("search")}</label>
                                <InputText type="search" value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full"   />
                            </div>

                        <div className={'space-y-2'}>
                            <label className="text-xs dark:text-gray-300">{t("BasedOnTheDate")}</label>

                            <Select
                                options={options.searchMethods}
                                isMulti={false}
                                isSearchable={true}
                                onChange={(data)=>filterSelectValueHandler(data,'DateSearchMethod')}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}

                            />
                        </div>


                                <div class=" space-y-2">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("FromDate")}</label>
                                    <DateTimePicker handleDateTime={handleRegisterFromDateTime} value={filter.fromDate} format={'YYYY/MM/DD'} />
                                </div>
                                <div class=" space-y-2">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("ToDate")} </label>
                                    <DateTimePicker value={filter.toDate} handleDateTime={handleRegisterToDateTime} format={'YYYY/MM/DD'} />
                                </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />

                </div>
                <div className="relative space-y-2 H-body-ag-grid">
                    {
                        spinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export/>
            </div>
            <ToastContainer/>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>

        </>

    )
}