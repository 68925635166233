import React, {useEffect, useMemo, useState} from "react";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {Link} from "react-router-dom";

import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";
import {BasicUrl} from "../../../../Services/AxiosService/axiosClient";
import {Tooltip} from "react-tooltip";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const useCustomWebsiteGrid=(setShowSpinner,refresh,onApiError,initialSource,Refresh,handleOpenPassword,handleOpenOwnerShip,handleSiteState,handleOpenMenu,handleOpenDeleteWebsite,handleOpenChangeRepresentative)=>{
    const { t } = useTranslation();






    // logins to customar website
    const handleSignInToAdminPanel=async(websiteId)=>{
        try {
            const url = `${BasicUrl.baseURL}/Websites/RedirectToWebsiteAdminPanel?WebsiteId=${websiteId}`
            window.open(url, "_blank");
        }
        catch (e) {
            console.log(e);
        }
    }






    const ConvertSiteTypes = (type) => {
        if (!initialSource.webTypes) {
            return;
        }
        const data = initialSource.webTypes.find((item) => item.value === type);
        return data ? data.label : '';
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("WebsiteTitle"),field: 'title',width: 120, minWidth: 140},

        { headerName: t("OwnerCompany"),field: 'ownerName', minWidth: 200,cellRenderer:params => {
                return <>{params.data.ownerName + (params.data.companyName ? ` - (${params.data.companyName})` : '')}</>
            }},
        { headerName: t("RegistrationDate"),field: 'createdOn',width: 120, minWidth: 140,cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.createdOn}  /></>
            }},
        { headerName: t("ExpirationDate"),field: 'expirationDate',width: 120, minWidth: 140,cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.expirationDate}  /></>
            }},
        { headerName: t("Status"),field: 'webSiteState', minWidth: 200,cellRenderer:params => {
                return ConvertSiteTypes(params.data.webSiteState)
            }},

        { headerName: t("HostingSpace"),field: 'siteSpace',width: 120, minWidth: 140},
        { headerName: t("MailServerSpace"),field: 'mailSpace',width: 120, minWidth: 140},
        { headerName: t("Wallet"),field: 'createdOn',width: 120, minWidth: 140,cellRenderer:params => {
                return <>
                    این دیتا فیک است
                </>
            }},

        { headerName: t("settings"), minWidth: 460,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <Link className="tooltip_class_History text-themeInformation dark:text-themeDarkInformation relative" to={`/Admin/Website/List/Payment/Upgrade/Details?websiteId=${params.data.id}`} >
                        <HPrimeIcon
                            icon={'pi-clock'}
                        />
                        <Tooltip anchorSelect={".tooltip_class_History"} content={t("History")} positionStrategy={"fixed"}	 />
                    </Link>
                    <button type={"button"} onClick={()=>handleSignInToAdminPanel(params.data.id)}  className={"tooltip_class_web text-themeInformation dark:text-themeDarkInformation relative"} >
                        <HPrimeIcon
                            icon={'pi-desktop'}
                        />
                    <Tooltip anchorSelect={".tooltip_class_web"} content={t("Website")} positionStrategy={"fixed"}/>
                    </button>
                    <Link to={`/Admin/Website/List/Payment/Upgrade?websiteId=${params.data.id}`} className={"tooltip_class_Upgrade text-themeInformation dark:text-themeDarkInformation relative"} >
                        <HPrimeIcon icon={'pi-sparkles'}  />
                        <Tooltip anchorSelect={".tooltip_class_Upgrade"} content={t("Upgrade")} positionStrategy={"fixed"}/>
                    </Link>

                    <Link to={`/InvoiceRequest?websiteId=${params.data.id}`} className={"tooltip_class_bill text-themeInformation dark:text-themeDarkInformation relative"} >
                        <HPrimeIcon icon={'pi-file-check'}  />
                        <Tooltip anchorSelect={".tooltip_class_bill"} content={t("Bill")} positionStrategy={"fixed"}/>
                    </Link>

                    <Link to={`/Admin/Website/List/Packages?websiteId=${params.data.id}`} className={"text-themeInformation dark:text-themeDarkInformation relative tooltip_class_SelectPackage"}>
                        <HPrimeIcon icon={"pi-box"} />
                        <Tooltip anchorSelect={".tooltip_class_SelectPackage"} content={t("SelectPackage")} positionStrategy={"fixed"}/>
                    </Link>
                    <button type={"button"} className={"text-themeInformation dark:text-themeDarkInformation relative tooltip_class_ChangePassword"} onClick={() => handleOpenPassword(params.data.id,params.data.websiteConcurrencyStamp)} >
                        <HPrimeIcon icon={'pi-lock'}  />
                        <Tooltip anchorSelect={".tooltip_class_ChangePassword"} content={t("ChangePassword")} positionStrategy={"fixed"}/>
                    </button>
                    <button type={"button"} className={"text-themeInformation dark:text-themeDarkInformation relative tooltip_class_ChangeOfOwnership"} onClick={() => handleOpenOwnerShip(params.data.id,params.data.ownerName)} >
                        <HPrimeIcon icon={'pi-users'}  />
                        <Tooltip anchorSelect={".tooltip_class_ChangeOfOwnership"} content={t("ChangeOfOwnership")} positionStrategy={"fixed"}/>
                    </button>
                    <button type={"button"} className={"text-themeInformation dark:text-themeDarkInformation relative tooltip_class_ChangeRepresentative"} onClick={() => handleOpenChangeRepresentative(params.data.id,params.data.ownerName)} >
                        <HPrimeIcon icon={'pi-face-smile'}  />
                        <Tooltip anchorSelect={".tooltip_class_ChangeRepresentative"} content={t("ChangeRepresentative")} positionStrategy={"fixed"}/>
                    </button>
                    <Link to={`/Admin/Website/List/Template/Add?websiteId=${params.data.id}`} className={"text-themeInformation dark:text-themeDarkInformation relative tooltip_class_AllowedTemlpates"} >
                        <HPrimeIcon icon={"pi-th-large"} />
                        <Tooltip anchorSelect={".tooltip_class_AllowedTemlpates"} content={t("AllowedTemlpates")} positionStrategy={"fixed"} />
                    </Link>
                    <Link to={`/Admin/Website/List/Server/${params.data.id}`} className={"text-themeInformation dark:text-themeDarkInformation relative tooltip_class_servers"} >
                        <HPrimeIcon icon={"pi-server"} />
                        <Tooltip anchorSelect={".tooltip_class_servers"} content={t("servers")} positionStrategy={"fixed"} />
                    </Link>
                    <Link to={`/Admin/Website/List/Domain?websiteId=${params.data.id}&websiteConcurrencyStamp=${params.data.websiteConcurrencyStamp}`} className={"text-themeInformation relative dark:text-themeDarkInformation tooltip_class_Domain"}>
                        <HPrimeIcon icon={'pi-globe'}  />
                        <Tooltip anchorSelect={".tooltip_class_Domain"} content={t("Domain")} positionStrategy={"fixed"} />
                    </Link>
                    <button type={"button"} className={"text-themeSuccess tooltip_class_SiteStatus relative tooltip_class_SiteStatus"}  onClick={() => handleSiteState(params.data.id,params.data.domainToken,params.data.webSiteState)} >
                        <HPrimeIcon icon={'pi-arrow-right-arrow-left'} />
                        <Tooltip anchorSelect={".tooltip_class_SiteStatus"} content={t("SiteStatus")} positionStrategy={"fixed"} />
                    </button>

                    <Link to={`/Support/Ticket?websiteId=${params.data.id}`} className={"text-themeInformation dark:text-themeDarkInformation relative tooltip_class_Ticket"} >
                        <HPrimeIcon icon={'pi-comments'} />
                        <Tooltip anchorSelect={".tooltip_class_Ticket"} content={t("Tickets")} positionStrategy={"fixed"} />
                    </Link>

                    <GridButtonDelete
                        onClick={()=>handleOpenDeleteWebsite(params.data.id,params.data.domainToken)}
                    />

                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',OneMonthToExpiration:null,IsPending:null,IsSuspended:null,IsExpired:null,IsDeleted:null,IsUpdated:null,ExpirationFrom:null,ExpirationTo:null,filterId:null});
    const[search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleFromDateHandler = (dateTime)=>{
        setFilter((prevState)=>({...prevState,ExpirationFrom: dateTime}));
    }
    const handleToDateHandler = (dateTime)=>{
        setFilter((prevState)=>({...prevState,ExpirationTo: dateTime}));
    }


    const handleSwitchFilters = (data, name) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: data ? data : null
        }));
    }

    const resetFilters = ()=>{
        setFilter(({...filter,IsDeleted: null, IsUpdated: null,IsExpired: null,IsSuspended: null,IsPending: null,OneMonthToExpiration: null,search:'',ExpirationFrom:null,ExpirationTo:null,filterId: null}));
        setSearch('');
    }

    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data}));
    }


    const SwitchFilters = [
        {name:'OneMonthToExpiration',lable:t("MonthToExpired"),value:filter.OneMonthToExpiration},
        {name:'IsPending',lable:t("Suspended"),value:filter.IsPending},
        {name:'IsSuspended',lable:t("Blocked"),value:filter.IsSuspended},
        {name:'IsExpired',lable:t("expired"),value:filter.IsExpired},
        {name:'IsDeleted',lable:t("Deleted"),value:filter.IsDeleted},
        {name:'IsUpdated',lable:t("Updating"),value:filter.IsUpdated},
    ]



    useEffect(() => {
        OnGridReady();
    }, [refresh,currentPage,filter.search,data.pageSize,filter.OneMonthToExpiration,filter.IsPending,filter.IsSuspended,filter.IsExpired,filter.IsDeleted,filter.IsUpdated,filter.ExpirationFrom,filter.ExpirationTo]);

    const [rowData,setRowData] = useState([]);

    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/Websites/GetAllWebSites`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search, OneMonthToExpiration: filter.OneMonthToExpiration, IsPending: filter.IsPending,IsSuspended:filter.IsSuspended,IsExpired:filter.IsExpired,IsDeleted:filter.IsDeleted,IsUpdated:filter.IsUpdated,ExpirationFrom:filter.ExpirationFrom,ExpirationTo:filter.ExpirationTo,filterId:filter.filterId !== null?filter.filterId.value:filter.filterId}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            setShowSpinner(false);
        } finally {
            setShowSpinner(false);
        }
    };

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        resetFilters,
        handleSwitchFilters,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        data,
        filter,
        currentPage,
        setFilter,handleFromDateHandler,
        handleToDateHandler,
        handleSelectFilter,
        SwitchFilters

    }


}