import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {useProductGroup} from "./useProductGroup";
import {useProductGroupTree} from "./useProductGroupTree";
import {handleAddConvertProductGroup, handleConvertToTree, handleEditConvertProductGroup} from "./ProductGroupService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {productGroupSchema} from "./productGroupSchema";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import ButtonVideo from "../../../Commans/UIParts/ButtonVideo";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import SortableTree from "@nosferatu500/react-sortable-tree";

import {Tooltip} from "react-tooltip";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";

import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const HandleGroupProduct=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh,setRefresh] = useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [errors,setErrors] = useState({});
    const [IsOpen,setIsOpen] = useState(false);
    const [initialSource,setInitialSource] = useState({productGroups:[],productTypes:[],templates:[]});
    const [IsEdit,setIsEdit]=useState(false);
    const [butLoading,setBtnLoading] = useState(false);
    const {handleSelectValue,setProductGroup,productGroup,handleValue,handleNumberValue}=useProductGroup(setErrors);
    const handleIsOpen=()=>{
        setProductGroup({parentId: null,excerpt: '',order: 0,seO_Description: '',seO_Title: '',title: '',productGroupId: '',slug: '',type: initialSource.productTypes[0],stateShow: true,stateGoogle: true,})
        setIsOpen(true);
    }
    const handleIsOpenClose=()=>{
        setProductGroup({slug: '',parentId: null,excerpt: '',order: 0,seO_Description: '',seO_Title: '',title: '',productGroupId: null,type: initialSource.productTypes[0],stateShow: true,stateGoogle: true});
        setIsEdit(false);
        setErrors({});
        setIsOpen(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const ShowSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleShowLoader=()=>{
        setBtnLoading(true);
    }
    const handleHideLoader=()=>{
        setBtnLoading(false);
    }
    const handleInitialSource=(productGroups,productTypes,templates)=>{
        const productGroup = convertSelectDataHandler(productGroups);
        const productTypesData = convertSelectDataHandler(productTypes);
        const templatesData = convertSelectDataHandler(templates);
        return {productGroup,productTypesData,templatesData}
    }
    const {searchFocusIndex,searchFoundCount,searchString,setSearchFocusIndex,setSearchFoundCount,setSearchString,setTreeData, treeData,updateTreeData, expandAll,collapseAll,getNodeKey,selectNextMatch,selectPrevMatch,handleDeleteProductGroup,customSearchMethod,handleMoveNode,handleAddNodeChild,handleGetNode,handleActivationProductGroup}=useProductGroupTree(ShowSpinner,hideSpinner,setIsOpen,initialSource,setInitialSource,setProductGroup,productGroup,setIsEdit,Refresh);


    const fetchData = async () => {
        try {
            ShowSpinner();
            const [responseProductGroup, response,responseProductType,responseTemplate] = await Promise.all([
                GET('/ProductGroups/Selection', {}, 'IEnumerable'),
                GET('/ProductGroups', {}, 'IEnumerable'),
                GET('/Enumerations/EnProductType', {}, 'IEnumerable'),
                GET(`/TemplatePages/selection?Type=${1}&Type=${13}`, {}, 'IEnumerable'),
            ]);
            if ( responseProductGroup.status === 200 && response.status === 200 && responseProductType.status === 200 && responseTemplate.status === 200) {
                const { productGroup,productTypesData,templatesData } = handleInitialSource( responseProductGroup.data,responseProductType.data,responseTemplate.data);
                setInitialSource({
                    productGroups: productGroup,
                    productTypes: productTypesData,
                    templates: templatesData
                })
                expandAll();
                const tree = handleConvertToTree(response.data);
                setTreeData(tree);
            }else {
                handleApiError(responseProductGroup, hideSpinner);
                handleApiError(response, hideSpinner);
                handleApiError(responseProductType, hideSpinner);
            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };
    useEffect(()=>{
        fetchData()
    },[refresh])





    const handleSubmitProductGroup=async(e)=>{
        e.preventDefault();
        try {
            const isValid = validate(productGroupSchema,productGroup,onSetErrors);
            if(!isValid){
                return;
            }
            if(IsEdit){
                handleShowLoader();
                const data = handleEditConvertProductGroup(productGroup);
                const response = await PUT(`/ProductGroups`,data,false);
                if(response.status === 200){
                    handleHideLoader();
                    expandAll();
                    Refresh();
                    handleIsOpenClose();
                    handleSuccessMessage(t('SuccessDone'));
                }
                else{
                    handleApiError(response,handleHideLoader)
                }
            }
            else{
                handleShowLoader();
                const data = handleAddConvertProductGroup(productGroup);
                const response = await POST(`/ProductGroups/CreateProductGroup`,data,false);
                if(response.status === 200){
                    handleHideLoader();
                    expandAll();
                    Refresh();
                    handleIsOpenClose();
                    handleSuccessMessage(t('SuccessDone'));
                }
                else{
                    handleApiError(response,handleHideLoader)
                }
            }
        }
        catch (e) {
            handleHideLoader();
        }
    }


    return(
        <>
            <div className=" space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleIsOpen()}
                                            tooltipTitle={t("GroupCreation")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Add")}
                                            icon="pi-pen-to-square"
                                            title={t("product")}
                                            tooltipTitle={t("NewProduct")}
                                        />


                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/MetaData/Add")}
                                            icon="pi-star"
                                            title={t("feature")}
                                            tooltipTitle={t("CreatingAFeature")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Group")}
                                            icon="pi-objects-column"
                                            title={t("grouping")}
                                            tooltipTitle={t("ProductCategorization")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/List")}
                                            icon="pi-list"
                                            title={t("list")}
                                            tooltipTitle={t("ListOfProducts")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/InvoiceRequest")}
                                            icon="pi-file-edit"
                                            title={t("Bill")}
                                            tooltipTitle={t("Bills")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Cart")}
                                            icon="pi-shopping-bag"
                                            title={t("ShoppingCart")}
                                            tooltipTitle={t("ShoppingCart")}

                                        />

                                        <ButtonPlus
                                            onClick={expandAll}
                                            icon={"pi-arrow-up-right-and-arrow-down-left-from-center"}
                                            title={t("View")}
                                            tooltipTitle={t("ViewSubcategories")}

                                        />
                                        <ButtonPlus
                                            onClick={collapseAll}
                                            icon={"pi-arrow-down-left-and-arrow-up-right-to-center"}
                                            title={t("Close")}
                                            tooltipTitle={t("CloseSubcategories")}
                                        />

                                    </>
                                }

                    />

                </div>
            </div>

            <div className={'relative space-y-5'}>
                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                {/*<div>*/}

                {/*    <form style={{ display: "inline-block" }} onSubmit={(event) => {event.preventDefault();}}*/}
                {/*    >*/}
                {/*        <button type="button" disabled={!searchFoundCount}*/}
                {/*                onClick={selectPrevMatch}>*/}
                {/*        </button>*/}

                {/*        <button type="submit"*/}
                {/*                disabled={!searchFoundCount}*/}
                {/*                onClick={selectNextMatch} >*/}
                {/*        </button>*/}
                {/*    </form>*/}
                {/*</div>*/}

                <div class="p-2 box bg-white dark:bg-themeDarkSalt700">
                    <div className={"grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 post-input dark:bg-themeDarkPostInput post-input-design"}>
                        <div className={"p-2 flex gap-2 items-center"}>
                            <InputText type="search" className="input max-w-sm " placeholder={t("search")} value={searchString} onChange={(e) => setSearchString(e.target.value)}/>
                            <ButtonSearchList/>
                        </div>
                    </div>
                </div>
                <div className="h-screen bg-white rounded-md dark:bg-themeDarkSalt700 ">

                    <SortableTree
                        treeData={treeData}
                       onMoveNode={(e)=>handleMoveNode(e)}
                        onChange={(treeData) => updateTreeData(treeData)}
                        searchMethod={customSearchMethod}
                        searchQuery={searchString}
                        searchFocusOffset={searchFocusIndex}
                        searchFinishCallback={(matches) => {
                            setSearchFoundCount(matches.length);
                            setSearchFocusIndex(
                                matches.length > 0 ? searchFocusIndex % matches.length : 0
                            );
                        }}
                        canDrag={({ node }) => !node.dragDisabled}
                        generateNodeProps={(rowInfo) => ({
                            // title: rowInfo.node.label,
                            // subtitle: rowInfo.node.subTitle,
                            buttons: [
                                <div className="flex space-x-2 items-center">
                                    <button onClick={(event) => handleAddNodeChild(rowInfo)} className="button tree_button tooltip_class_group bg-themeInformation dark:bg-themeDarkInformation text-white " >
                                        <HPrimeIconSmall icon={"pi-plus-circle"} />
                                        <Tooltip anchorSelect={".tooltip_class_group"} content={t("GroupCreation")} />
                                    </button>
                                    <button onClick={(event) => handleGetNode(rowInfo)} className="button tree_button tooltip_class_Edit bg-themeSuccess text-white  "  >
                                        <HPrimeIconSmall  icon={'pi-pen-to-square'} />
                                        <Tooltip anchorSelect={".tooltip_class_Edit"} content={t("edit")} />
                                    </button>
                                    <button onClick={(event) => handleDeleteProductGroup(rowInfo.node.id)} className="button tree_button tooltip_class_delete bg-themeDanger text-white "  >
                                        <HPrimeIconSmall icon={'pi-trash'} />
                                        <Tooltip anchorSelect={".tooltip_class_delete"} content={t("delete")} />
                                    </button>
                                    <button onClick={()=>redirectTo(`/Shop/Product/Group/Detail?productGroupId=${rowInfo.node.id}`)} className="button tree_button tooltip_class_information bg-themeSecondary text-white "  >
                                        <HPrimeIconSmall icon={'pi-external-link'} />
                                        <Tooltip anchorSelect={".tooltip_class_information"} content={t("Addinginformation")} />
                                    </button>

                                    {
                                            rowInfo.node.stateShow? (
                                                <button type={"button"} onClick={async () => await handleActivationProductGroup(rowInfo.node.id)}>
                                                    <HPrimeIconSmall
                                                        iconClass={'text-themeSuccess'}
                                                        icon={'pi-check-square'}
                                                    />
                                                </button>

                                            ) : (
                                                <button className={""} onClick={async () => await handleActivationProductGroup(rowInfo.node.id)}>
                                                    <HPrimeIconSmall
                                                        iconClass={' text-themeDanger'}
                                                        icon={'pi-times-circle'}
                                                    />
                                                </button>

                                            )
                                    }

                                </div>
                            ],
                            style: {
                                height: "50px"
                            }
                        })}
                    />
                </div>


            </div>



            <FantasyModal openModal={IsOpen}   closeModal={handleIsOpenClose} title={t("GroupCreation")}>
                <div>
                    <form method={'post'} onSubmit={handleSubmitProductGroup} className="space-y-5">
                        <div className="grid grid-cols-2 gap-3">
                            <div className="space-y-1">
                                <div className="flex justify-between items-center">
                                    <label className="text-xs dark:text-gray-300">
                                        {t("Title")}
                                        <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.title}/>
                                </div>
                                <InputText type={'text'} name={'title'} value={productGroup.title} onInput={(e)=>handleValue(e)} className={'input w-full'}/>
                            </div>
                            <div className="space-y-1">
                                <div className="flex justify-between items-center">
                                    <label className="text-xs dark:text-gray-300">
                                        {t("ArticleAddress")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.slug}/>
                                </div>
                                <InputText type={'text'}  value={productGroup.slug} name={'slug'} onInput={(e)=>handleValue(e)} className={' input w-full '}/>
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("WebsiteTitle")}</label>
                                <InputText type={'text'} className={' input w-full '} value={productGroup.seO_Title} name={'seO_Title'} onInput={(e)=>handleValue(e)}  />
                            </div>
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("Group")}</label>
                                <Select
                                    options={
                                    productGroup.productGroupId === null?
                                    initialSource.productGroups
                                        :
                                        initialSource.productGroups.filter((item)=>item.value !== productGroup.productGroupId)

                                }
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'parentId')}
                                    name={'parentId'}
                                    placeholder={t("selection")}
                                    value={productGroup.parentId}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div className="space-y-1 ">
                                <label className="text-xs dark:text-gray-300">{t("type")}</label>
                                <Select
                                    options={initialSource.productTypes}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'type')}
                                    name={'type'}
                                    placeholder={t("selection")}
                                    value={productGroup.type}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div className="space-y-1 ">
                                <label className="text-xs dark:text-gray-300">{t("template")}</label>
                                <Select
                                    options={initialSource.templates}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'templateId')}
                                    name={'templateId'}
                                    placeholder={t("selection")}
                                    value={productGroup.templateId}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("rank")}</label>
                                <InputNumber className="w-full " name={'order'}  onValueChange={(e)=>handleNumberValue(e.target.value,'order')} value={productGroup.order} placeholder={t("selection")} mode="decimal" showButtons min={0} max={100}/>
                            </div>
                            <div class="col-span-2">
                                <InputTextarea name={'excerpt'} placeholder={t("Explanation")} value={productGroup.excerpt} onInput={(e)=>handleValue(e)} rows={3} cols={30}  className={'input w-full'}/>
                            </div>
                            <div className={"flex justify-start items-center gap-5"}>
                                <InputSwitch value={productGroup.stateShow} OnValueSelection={handleSelectValue} lable={t("DisplayStatus")} name={'stateShow'}/>

                                <InputSwitch value={productGroup.stateGoogle} OnValueSelection={handleSelectValue} lable={t("stateGoogle")} name={'stateGoogle'}/>
                            </div>


                        </div>
                        <div >
                            <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation place-content-center text-white relative"} >
                                {t("insertion")}
                                {butLoading ? (
                                    <TinyLoading
                                    />
                                ) : (
                                    null
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </FantasyModal>

            <ToastContainer/>


        </>



    )
}