import React, {useState} from "react";

export const useDataBinder=()=>{
    const [errors, setErrors] = useState({});
    const [comments,setComments] = useState({
        id:null,
        fullName:null,
        message:null,
        slug:null,
        state:null,
        parentId:null,
        rateCommenting:null,
        likeCounts:0,
        dislikeCounts:0,
        adminReply:null,
        adminReplyRateCommenting:null,
        adminLikeCounts:0,
        adminDislikeCounts:0,
        communications:[]
    })
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setComments({...comments,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setComments({...comments,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setComments({ ...comments, [name]: data });
    }

    const onSetErrors=(errors)=>{
        setErrors(errors)
    }



    return {
    errors,
    setErrors,
    comments,
     setComments,
    handleValue,
    handleNumberValue,
    handleSelectValue,
        onSetErrors
 }
}