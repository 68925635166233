import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {useCustomProductGrid} from "./useCustomProductGrid";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import Select from "react-select";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {useCustomProduct} from "./useCustomProduct";
import {useSlugValidation} from "../../../Services/Globals/Hooks/useSlugValidation";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
  import {validate} from "../../../Services/Globals/validate";
import {productSchema} from "./productSchema";
import Swal from "sweetalert2";
import {InputNumber} from "primereact/inputnumber";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {ToastContainer} from "react-toastify";
import {usePrintPrice} from "./usePrintPrice";
import {BrowserDownloader, DownloadFile, ExportDownloader} from "../../../ApiServices/Public/FtpController";
import {BasicUrl} from "../../../Services/AxiosService/axiosClient";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";

export const HandleListProduct=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh,setRefresh] = useState(false);
    const [isMenuOpen,setMenuOpen] = useState(false);
    const [errors,setErrors] = useState({});
    const [modal, setModal] = useState(false);
    const [modalThree, setModalThree] = useState({ ListPrice: false, });

    const [showSpinner,setShowSpinner] = useState(false);
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource] = useState({productGroups:[],currencies:[],operators:[],productFilters:[]});
    const {setProducts,product,handleValue,handleNumberValue,handleGetCurrencyType,handleSelectValue} = useCustomProduct(setErrors);
    const [selectedRows,setSelectedRows] = useState([]);
    const [IsOPenPrice,setOpenPrice] = useState(false);
    const [IsOpenGroupPrice, setGroupPrice] = useState(false);

    const [websiteCurrency,setWebsiteCurrency]=useState(0);

    const handleOpenMenu = (productId,concurrencyStamp) => {
        setProducts({id: productId,concurrencyStamp: concurrencyStamp});
        setMenuOpen(true);
    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }
    const handleCloseModal = () => {
       setModal(false)
    };


    const {setPrint,print,handleSelectPrintPriceValue}=usePrintPrice();
    const handleOpenListPrice=()=>{
        setPrint((prevState)=>({...prevState,GroupIds: initialSource.productGroups,retailPrice: false,wholeSalePrice: false}));
        setModalThree({ ListPrice: true });
    }
    const handleCloseListPrice =()=>{
        setModalThree({ListPrice:false});
    }
    const handleDownload=async()=>{
        try {
            const filters = {
                RetailPrice: print.retailPrice,
                WholeSalePrice: print.wholeSalePrice,
                GroupIds: print.GroupIds?.map(group => group.value).join('&') || ''
            };
           await  DownloadFile(filters,null,`/Products/ExportPrice`)
        }
        catch (e) {

        }
    }


    const onApiError=()=>{
        setShowSpinner(false);
    }
    const onError=()=>{
        setIsLoading(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const handleInitialData=(productGroups,responseCurrencies,responseOperator,responseProductFilters)=>{
        const productGroupData=convertSelectDataHandler(productGroups);
        const currencies=convertSelectDataHandler(responseCurrencies);
        const operators=convertSelectDataHandler(responseOperator);
        const filters=convertSelectDataHandler(responseProductFilters);
        setInitialSource({
            productGroups: productGroupData,
            currencies: currencies,
            operators: operators,
            productFilters: filters
        })
    }
    // selects rows of grids using checkboxes
    const onSelectionChanged = (row) => {
        setSelectedRows(row.api.getSelectedRows());
    };

    // gets data from server for the first time
    const fetchData = async () => {
        try {
            setShowSpinner(true);
            const [response,responseCurrencies,responseWebsiteCurrency,responseOperator,responseProductFilter] = await Promise.all([
                GET(`/ProductGroups/Selection`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable'),
                GET(`/Websites/Currency`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnEditProductPriceByProductGroupOperator`, {}, 'IEnumerable'),
                GET(`/Filters/Selection?Table=${3}`, {}, 'IEnumerable')
            ]);
            if (response.status === 200 && responseCurrencies.status === 200 && responseCurrencies.status === 200 && responseOperator.status === 200) {
                handleInitialData(response.data,responseCurrencies.data,responseOperator.data,responseProductFilter.data);
                setWebsiteCurrency(responseWebsiteCurrency.data.currencyType)
                setShowSpinner(false);
            } else {

                handleApiError(response, onApiError);
                handleApiError(responseCurrencies, onApiError);
                handleApiError(responseWebsiteCurrency, onApiError);
                handleApiError(responseOperator, onApiError);
                handleApiError(responseProductFilter, onApiError);
            }
        } catch (e) {
            onApiError();
        }
    };

    useEffect(()=>{
        fetchData()
    },[])

    //changes displayOrder of products
    const onCellValueChanged = async (e)=>{
        const {column} = e;
        const {colId} = column;
        if(colId === 'displayOrderSlave'){
            try {
                const {id,displayOrderSlave} = e.data;
                const response = await PUT('/Products/EditDisplayOrderSlave', { id: id,displayOrderSlave:displayOrderSlave})
                if(response.status === 200){
                    Refresh();
                    handleSuccessMessage(t("Successfullyedited"));
                }
            }
            catch (e) {
                onApiError();
            }
        }
    }


    // edits title and slug of product .
    const handleEditTitleSlug=async(e)=>{
        e.preventDefault();
        const isValid = validate(productSchema,product,onSetErrors);
        if(!isValid){
          return;
        }
        if(!IsSlugValid){
            handleFailureMessage(t("TheURLIsNotValid"));
            return;
        }
        try {
           setIsLoading(true);
           const response =await PUT(`/Products/EditTitle`,{id:product.id,title:product.title,slug:product.slug},false);
           if(response.status === 200){
              setIsLoading(false);
              handleSuccessMessage(t("Successfullyedited"));
               handleCloseModal();
              Refresh();
           }
           else{
               handleApiError(response,onError);
           }
        }
        catch (e) {
            onError()
        }

    }

    // Deletes all products
    const handleDeleteAllProducts = async ()=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    const ids = selectedRows.map(item=>item.id);
                    setShowSpinner(true);
                    const response = await DELETE(`/Products`,{ids:ids});
                    if (response.status === 200) {
                        setShowSpinner(false);
                        setSelectedRows([])
                        Refresh();
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    onApiError();
                    console.log(e)
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const handleClosePrice=()=>{
        setOpenPrice(false);
        setProducts({id: null,price:0,wholeSalePrice: 0});
    }
    const handleSubmitQuickPrice=async(e)=>{
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await PUT(`/Products/Price`,{productId:product.id,retailPrice:product.price === null?0:product.price,wholeSalePrice:product.wholeSalePrice === null?0:product.wholeSalePrice});
            if(response.status === 200){
               onError();
               handleSuccessMessage(t('SuccessDone'));
               Refresh();
               handleClosePrice();
            }
            else{
                handleApiError(response,onError);
            }
        }
        catch (e) {
            onError();
        }
    }

    const {handleValidateSlug,setSlugValid,IsSlugValid} = useSlugValidation(onApiError,product);
    const {filter,handleFilterByGroupName,handleDeleteProduct,handleGetProduct,handleSearch,search,setFilter,setSearch,handleNextPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount,handleSelectFilter,handleToggleFilter,handleResetFilters,SwitchFilters,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination} = useCustomProductGrid(setShowSpinner,fetchData,refresh,onApiError,initialSource,setProducts,setModal,Refresh,handleOpenMenu,setOpenPrice)

    const handleOpenGroupPrice=()=>{
        setProducts((prevState)=>({...prevState,productGroupId: initialSource.productGroups[0],operation: initialSource.operators[0],price: 0}))
        setGroupPrice(true);
    }
    const handleCloseGroupPrice =()=>{
        setProducts((prevState)=>({...prevState,productGroupId: initialSource.productGroups[0],operation: initialSource.operators[0],price: 0}))
        setGroupPrice(false);
    }
    const handleSubmitGroupPrice=async (e)=>{
        e.preventDefault();
        try {
            setIsLoading(true);
            const response = await PUT(`/Products/Price/ProductGroup`,
                {
                        value : product.price === null?0:product.price,
                        productGroupId:product.productGroupId === null || product.productGroupId === undefined?null:product.productGroupId.value,
                        operator:product.operation === null || product.operation === undefined?null:product.operation.value
                      }
            ,false);
            if(response.status === 200){
                onError();
                handleSuccessMessage(t('SuccessDone'));
                Refresh();
                handleCloseGroupPrice();
            }
            else{
                handleApiError(response,onError);
            }
        }
        catch (e) {
            onError();
        }
    }




    return(<>
        <div class=" space-y-5 relative">
            {
                showSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByNameProductCode")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                            <Select
                                options={initialSource.productGroups}
                                isMulti={true}
                                isSearchable={true}
                                isClearable={true}
                                onChange={(data)=>handleSelectFilter(data,'ProductGroupIds')}
                                placeholder={t("selection")}
                                value={filter.ProductGroupIds}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                    <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("Filter")}</label>
                        <Select
                            options={initialSource.productFilters}
                            isMulti={false}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(data)=>handleSelectFilter(data,'filterId')}
                            placeholder={t("selection")}
                            value={filter.filterId}
                            className={"select-group"}
                            classNamePrefix={"h_multi_select"}
                        />
                    </div>
                    <ButtonSearchList
                        onClickSearch={()=>handleSearch()}
                        onClickReset={()=>handleResetFilters()}
                    />
                    </ListSearch>
                <ListFilter label={null} icon={null}
                    childrenOne={
                    <>
                        <ButtonPlus
                            onClick={() => redirectTo('/Shop/Product/Add')}
                            tooltipTitle={t("AddProduct")}
                        />

                        <ButtonPlus
                            onClick={() =>handleOpenGroupPrice()}
                            tooltipTitle={t("groupPriceChange")}
                            title={t("Price")}
                            icon={"pi-dollar"}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo("/Shop/MetaData/Add")}
                            icon="pi-star"
                            title={t("feature")}
                            tooltipTitle={t("CreatingAFeature")}
                        />


                        <ButtonPlus
                            onClick={()=>redirectTo("/Shop/Product/Group")}
                            icon="pi-objects-column"
                            title={t("grouping")}
                            tooltipTitle={t("ProductCategorization")}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo("/Shop/Product/List")}
                            icon="pi-list"
                            title={t("list")}
                            tooltipTitle={t("ListOfProducts")}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo("/InvoiceRequest")}
                            icon="pi-file-edit"
                            title={t("Bill")}
                            tooltipTitle={t("Bills")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo("/Shop/Product/Cart")}
                            icon="pi-shopping-bag"
                            title={t("ShoppingCart")}
                            tooltipTitle={t("ShoppingCart")}
                        />


                        <ButtonPlus
                            onClick={() =>handleOpenListPrice()}
                            tooltipTitle={t("PrintPriceList")}
                            title={t("Print")}
                            icon={"pi-print"}

                        />

                        {
                            selectedRows.length > 0 ?
                                <ButtonPlus
                                    onClick={()=>handleDeleteAllProducts()}
                                    classStyle={"bg-themeDanger text-white"}
                                    icon={"pi-trash"}
                                    title={t("DeleteAll")}
                                    tooltipTitle={t("DeleteAll")}
                                />:
                                null
                        }

                    </>
                }
                    childrenTwo={
                    <>
                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                    </>
                }
                    />

            </div>
            <div className={"space-y-2 H-body-ag-grid"} >


                <GridTable
                    columnDefs={columnDefs}
                    onSelectionChanged={onSelectionChanged}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    onCellValueChanged={onCellValueChanged}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridEnglish}
                />
                <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
            </div>
            <Export apiUrl={''} filters={filter}/>
        </div>
        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
            <div className="grid grid-cols-3 md:grid-cols-4 gap-3">

                <ModalButtonOperations
                    title={t("feature")}
                    icon={"pi-star"}
                    onClick={() => redirectTo(`/Shop/Product/List/ProductMeta?productId=${product.id}`)}
                />
                <ModalButtonOperations
                    title={t("SimilarInsertion")}
                    icon={"pi-pen-to-square"}
                    onClick={() => redirectTo(`/Shop/Product/Add?productId=${product.id}&IsSameInsert=${true}`)}
                />

                <ModalButtonOperations
                    title={t("SizeAndDimensions")}
                    icon={'pi-window-minimize'}
                    onClick={()=>redirectTo(`/Shop/Product/Type?productId=${product.id}`)}
                />
                <ModalButtonOperations
                    title={t("ChartManagement")}
                    icon={'pi-chart-bar'}
                    onClick={()=>redirectTo(`/Shop/Product/List/Chart?productId=${product.id}`)}
                />

            </div>
        </FantasyModal>

        <FantasyModal openModal={modal} closeModal={handleCloseModal} title={t("ArticleTitle")}>
            <div>
                <form method="post" onSubmit={handleEditTitleSlug} className="relative" // Adding margin top to move the form down
                >
                    <div className="space-y-5 ">
                        <div className="space-y-2">
                            <div className="space-y-2">
                                <div className={"flex justify-between items-center gap-2"}>
                                    <label className="dark:text-gray-300 text-xs">
                                        {t("ArticleTitle")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.title}/>
                                </div>

                                <InputText onInput={(e) => handleValue(e)}
                                           value={product.title}
                                           type="text"
                                           className="input w-full"
                                           name="title"
                                           onBlur={handleValidateSlug} />

                            </div>
                            <div className="space-y-2">
                                <div className={"flex justify-between items-center gap-2"}>
                                    <label className="dark:text-gray-300 text-xs">
                                        {t("ArticleAddress")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError
                                    message={errors.slug}
                                    />
                                </div>

                                <InputText onInput={(e) => handleValue(e)}
                                           value={product.slug}
                                           type="text"
                                           className="input w-full"
                                           name="slug"
                                           onBlur={handleValidateSlug} />
                            </div>
                        </div>
                        <div>
                            <button type="submit" className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full relative">
                                {t("SaveEdit")}
                                {IsLoading ? (
                                    <TinyLoading  />
                                    ) : (
                                        null
                                    )}
                            </button>

                        </div>
                    </div>
                </form>
            </div>
        </FantasyModal>

        <FantasyModal openModal={IsOPenPrice} closeModal={handleClosePrice} title={t("QuickPriceChange")}>
            <div>
                <form method="post" onSubmit={handleSubmitQuickPrice}>
                    <div className="space-y-5 ">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="space-y-2">
                                <label className="dark:text-gray-300 text-xs">{t("UnitPrice")}</label>
                                <InputNumber  onChange={(e)=>handleNumberValue(e.value,'price')} suffix={handleGetCurrencyType(websiteCurrency,initialSource.currencies)}  min={0} value={product.price}  />
                            </div>
                            <div className="space-y-2">
                                <label className="dark:text-gray-300 text-xs">{t("WholesalePrice")}</label>
                                <InputNumber onChange={(e)=>handleNumberValue(e.value,'wholeSalePrice')} suffix={handleGetCurrencyType(websiteCurrency,initialSource.currencies)}  min={0} value={product.wholeSalePrice} />
                            </div>
                        </div>
                        <div>
                            <button type="submit" className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full relative">
                                {t("SaveEdit")}
                                {IsLoading ? (
                                    <TinyLoading  />
                                ) : (
                                    null
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </FantasyModal>

        <FantasyModal openModal={IsOpenGroupPrice}  closeModal={handleCloseGroupPrice} title={t("groupPriceChange")}>
         <form method={'post'} onSubmit={handleSubmitGroupPrice}>
             <div className={"space-y-5"}>
                 <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                     <div class="space-y-1">
                         <label className="dark:text-gray-300 text-xs">{t("ProductGroup")}</label>
                         <Select
                             options={initialSource.productGroups}
                             isMulti={false}
                             isClearable={false}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'productGroupId')}
                             name={'productGroupId'}
                             placeholder={t("selection")}
                             value={product.productGroupId}
                             className={"select-group"}
                             classNamePrefix={"h_multi_select"}
                             menuPosition={'fixed'}
                         />
                     </div>
                     <div class="space-y-1">
                         <label className="dark:text-gray-300 text-xs">{t("OperationType")}</label>
                         <Select
                             options={initialSource.operators}
                             isMulti={false}
                             isClearable={false}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'operation')}
                             name={'operation'}
                             placeholder={t("selection")}
                             value={product.operation}
                             className={"select-group"}
                             classNamePrefix={"h_multi_select"}
                             menuPosition={'fixed'}
                         />
                     </div>
                     <div class="space-y-1">
                         <label className="dark:text-gray-300 text-xs">{t('Value')}</label>
                         <InputNumber  type={"text"} onChange={(e)=>handleNumberValue(e.value,'price')} name={'price'} value={product.price}    />
                     </div>
                 </div>
                 <div>
                     <button type="submit" className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full relative">
                         {t("SaveEdit")}
                         {IsLoading ? (
                             <TinyLoading  />
                         ) : (
                             null
                         )}
                     </button>
                 </div>
             </div>
         </form>
        </FantasyModal>

        <FantasyModal openModal={modalThree.ListPrice}  closeModal={handleCloseListPrice} title={t("PrintPriceList")}>

              <div className={"space-y-5"}>
                  <div class="space-y-1">
                      <label className="dark:text-gray-300 text-xs">{t("ProductGroup")}</label>
                      <Select
                          options={initialSource.productGroups}
                          isMulti={true}
                          isClearable={false}
                          isSearchable={true}
                          onChange={(data)=>handleSelectPrintPriceValue(data,'GroupIds')}
                          name={'GroupIds'}
                          placeholder={t("selection")}
                          value={print.GroupIds}
                          className={"select-group"}
                          classNamePrefix={"h_multi_select"}
                          menuPosition={'fixed'}
                      />

                  </div>
                  <div className="flex flex-wrap  items-center justify-around gap-3">
                      <div class="space-y-1 flex gap-2 items-center">
                          <input type={'checkbox'} name={'retailPrice'} checked={print.retailPrice} onChange={(e)=>handleSelectPrintPriceValue(e.target.checked,'retailPrice')}/>
                          <label className="dark:text-gray-300 text-xs">{t("UnitPrice")}</label>
                      </div>
                      <div class="space-y-1 flex gap-2 items-center">
                          <input type={'checkbox'} name={'wholeSalePrice'} checked={print.wholeSalePrice} onChange={(e)=>handleSelectPrintPriceValue(e.target.checked,'wholeSalePrice')}/>
                          <label className="dark:text-gray-300 text-xs">{t("WholesalePrice")}</label>
                      </div>
                  </div>
                  <div>
                      <button type={"button"} onClick={()=>handleDownload()} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">
                          {t("Download")}
                      </button>
                  </div>
              </div>
        </FantasyModal>

        <ToastContainer/>
    </>)
}