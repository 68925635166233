import React, {useEffect, useState} from "react";

export const useStatisticsChart=()=>{
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'تعداد بازدید',
                backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                borderColor: documentStyle.getPropertyValue('--blue-500'),
                data: []
            },
        ]
    });

    const [chartOptions, setChartOptions] = useState({
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    fontColor: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary,
                    font: {
                        weight: 500
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder,
                    drawBorder: false
                }
            }
        }
    });

    const handleGetChartData=(statisticsData)=>{
        const data = statisticsData.map((item)=>item.uniqueVisitors);
        const dates = statisticsData.map((item)=>item.date);
        setChartData((prevChartData) => ({
            ...prevChartData,
            labels: dates,
            datasets: prevChartData.datasets.map((dataset, index) => ({
                ...dataset,
                data: index === 0 ? data : dataset.data // Update the first dataset's data
            }))
        }));
    }


   return {
       chartData,
       chartOptions,
       setChartData,
       setChartOptions,
       handleGetChartData
   }



}