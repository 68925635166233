export const convertAddSeoRedirect = (obj)=>{
    const newData = {
        newUrl:obj.newUrl === null || obj.newUrl === undefined?null:obj.newUrl,
        type:obj.type === null || obj.type === undefined?null:obj.type.value,
        oldUrl: obj.oldUrl === null || obj.oldUrl === undefined?null:obj.oldUrl
    };
    return newData
}

export const convertEditSeoRedirect = (obj,id)=>{
    const newData = {
        id:id,
        newUrl:obj.newUrl === null || obj.newUrl === undefined?null:obj.newUrl,
        type:obj.type === null || obj.type === undefined?null:obj.type.value,
        oldUrl: obj.oldUrl === null || obj.oldUrl === undefined?null:obj.oldUrl
    };
    return newData
}

