import {TextInputElement} from "./TextInputElement";
import {TextareaElement} from "./TextareaElement";
import {MultiSelectElement} from "./MultiSelectElement";
import {SelectElement} from "./SelectElement";
import {RadioElement} from "./RadioElement";
import {CheckBoxElement} from "./CheckBoxElement";
import {NumberInputElement} from "./NumberInputElement";
import {SelectionBreak} from "./SelectionBreak";
import {FileUploadElement} from "./FileUploadElement";
import {LocationElement} from "./LocationElement";
import {EmailElement} from "./EmailElement";
import {PhoneElement} from "./PhoneElement";
import {ImageElement} from "./ImageElement";
import {VideoElement} from "./VideoElement";
import {AudioElement} from "./AudioElement";
import {PriceElement} from "./PriceElement";
import {SliderRange} from "./SliderRange";
import {UrlElement} from "./UrlElement";
import {ColorElement} from "./ColorElement";
import {PasswordElement} from "./PasswordElement";
import {DateElement} from "./DateElement";
import {TimeElement} from "./TimeElement";
import {DateTimeElement} from "./DateTimeElement";

export const FormElements = ({ addCheckBoxItem,handleDefaultValueChange, deleteCheckBoxItem, SortDescending, elements, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement, SortAccending }) => (
    <>
        {elements && elements.length > 0 && elements.map((element, index) => (
            <div key={index} onClick={() => handleSelectTabAfterElementSelection(index)} className={`harmony-space-y-1 relative ${element.properties.size !== null ? element.properties.size.className : ''}`}>
                <label className="dark:text-gray-300 harmony-text-xs harmony-line-clamp-1">{element.properties.caption}</label>
                {element.typeName === 'TextBox' ?
                    <TextInputElement
                        index={index}
                        element={element}
                        selectedElementIndex={selectedElementIndex}
                        handlePropertyChange={handlePropertyChange}
                        handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                        deleteElement={deleteElement}
                        SortAccending={SortAccending}
                        SortDescending={SortDescending}
                        itemCount={elements.length}
                        handleDefaultValueChange={handleDefaultValueChange}
                    />
                    : element.typeName === 'TextArea' ?
                        <TextareaElement
                            index={index}
                            element={element}
                            selectedElementIndex={selectedElementIndex}
                            handlePropertyChange={handlePropertyChange}
                            handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                            handleDefaultValueChange={handleDefaultValueChange}
                            deleteElement={deleteElement}
                            SortAccending={SortAccending}
                            SortDescending={SortDescending}
                            itemCount={elements.length}
                        />
                        : element.typeName === 'Numeric' ?
                            <NumberInputElement
                                index={index}
                                element={element}
                                selectedElementIndex={selectedElementIndex}
                                handlePropertyChange={handlePropertyChange}
                                handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                deleteElement={deleteElement}
                                SortAccending={SortAccending}
                                SortDescending={SortDescending}
                                itemCount={elements.length}
                                handleDefaultValueChange={handleDefaultValueChange}
                            />
                            : element.typeName === 'MultiSelect' ?
                                <MultiSelectElement
                                    index={index}
                                    element={element}
                                    selectedElementIndex={selectedElementIndex}
                                    handlePropertyChange={handlePropertyChange}
                                    handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                    deleteElement={deleteElement}
                                    SortAccending={SortAccending}
                                    SortDescending={SortDescending}
                                    itemCount={elements.length}
                                    addCheckBoxItem={addCheckBoxItem}
                                    deleteCheckBoxItem={deleteCheckBoxItem}
                                    handleDefaultValueChange={handleDefaultValueChange}
                                />
                                : element.typeName === 'Select' ?
                                    <SelectElement
                                        index={index}
                                        element={element}
                                        selectedElementIndex={selectedElementIndex}
                                        handlePropertyChange={handlePropertyChange}
                                        handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                        deleteElement={deleteElement}
                                        SortAccending={SortAccending}
                                        SortDescending={SortDescending}
                                        itemCount={elements.length}
                                        addCheckBoxItem={addCheckBoxItem}
                                        deleteCheckBoxItem={deleteCheckBoxItem}
                                        handleDefaultValueChange={handleDefaultValueChange}

                                    />
                                    : element.typeName === 'Radio' ?
                                        <RadioElement
                                            index={index}
                                            element={element}
                                            selectedElementIndex={selectedElementIndex}
                                            handlePropertyChange={handlePropertyChange}
                                            handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                            deleteElement={deleteElement}
                                            SortAccending={SortAccending}
                                            SortDescending={SortDescending}
                                            itemCount={elements.length}
                                            addCheckBoxItem={addCheckBoxItem}
                                            deleteCheckBoxItem={deleteCheckBoxItem}
                                        />
                                        : element.typeName === 'CheckBox' ?
                                            <CheckBoxElement
                                                index={index}
                                                element={element}
                                                selectedElementIndex={selectedElementIndex}
                                                handlePropertyChange={handlePropertyChange}
                                                handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                deleteElement={deleteElement}
                                                SortAccending={SortAccending}
                                                SortDescending={SortDescending}
                                                itemCount={elements.length}
                                                addCheckBoxItem={addCheckBoxItem}
                                                deleteCheckBoxItem={deleteCheckBoxItem}
                                            />
                                            :
                                            element.typeName === 'SelectionBreak' ?
                                                <SelectionBreak
                                                    index={index}
                                                    element={element}
                                                    selectedElementIndex={selectedElementIndex}
                                                    handlePropertyChange={handlePropertyChange}
                                                    handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                    deleteElement={deleteElement}
                                                    SortAccending={SortAccending}
                                                    SortDescending={SortDescending}
                                                    itemCount={elements.length}
                                                    addCheckBoxItem={addCheckBoxItem}
                                                    deleteCheckBoxItem={deleteCheckBoxItem}
                                                />
                                                :element.typeName === 'File' ?
                                                    <FileUploadElement
                                                        index={index}
                                                        element={element}
                                                        selectedElementIndex={selectedElementIndex}
                                                        handlePropertyChange={handlePropertyChange}
                                                        handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                        deleteElement={deleteElement}
                                                        SortAccending={SortAccending}
                                                        SortDescending={SortDescending}
                                                        itemCount={elements.length}
                                                        addCheckBoxItem={addCheckBoxItem}
                                                        deleteCheckBoxItem={deleteCheckBoxItem}
                                                    />
                                                :
                                                element.typeName === 'Location'?
                                                    <LocationElement
                                                        index={index}
                                                        element={element}
                                                        selectedElementIndex={selectedElementIndex}
                                                        handlePropertyChange={handlePropertyChange}
                                                        handleDefaultValueChange={handleDefaultValueChange}
                                                        handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                        deleteElement={deleteElement}
                                                        SortAccending={SortAccending}
                                                        SortDescending={SortDescending}
                                                        itemCount={elements.length}
                                                        addCheckBoxItem={addCheckBoxItem}
                                                        deleteCheckBoxItem={deleteCheckBoxItem}
                                                    />
                                                    :
                                                    element.typeName === 'Email'?
                                                        <EmailElement
                                                            index={index}
                                                            element={element}
                                                            selectedElementIndex={selectedElementIndex}
                                                            handlePropertyChange={handlePropertyChange}
                                                            handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                            deleteElement={deleteElement}
                                                            SortAccending={SortAccending}
                                                            SortDescending={SortDescending}
                                                            itemCount={elements.length}
                                                            handleDefaultValueChange={handleDefaultValueChange}
                                                        />
                                                        :
                                                        element.typeName === 'Phone'?
                                                            <PhoneElement
                                                                index={index}
                                                                element={element}
                                                                selectedElementIndex={selectedElementIndex}
                                                                handlePropertyChange={handlePropertyChange}
                                                                handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                deleteElement={deleteElement}
                                                                SortAccending={SortAccending}
                                                                SortDescending={SortDescending}
                                                                itemCount={elements.length}
                                                                handleDefaultValueChange={handleDefaultValueChange}
                                                            />:
                                                            element.typeName === 'Image'?
                                                                <ImageElement
                                                                    index={index}
                                                                    element={element}
                                                                    selectedElementIndex={selectedElementIndex}
                                                                    handlePropertyChange={handlePropertyChange}
                                                                    handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                    deleteElement={deleteElement}
                                                                    SortAccending={SortAccending}
                                                                    SortDescending={SortDescending}
                                                                    itemCount={elements.length}
                                                                />
                                                                :
                                                                element.typeName === 'Video'?
                                                                    <VideoElement
                                                                        index={index}
                                                                        element={element}
                                                                        selectedElementIndex={selectedElementIndex}
                                                                        handlePropertyChange={handlePropertyChange}
                                                                        handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                        deleteElement={deleteElement}
                                                                        SortAccending={SortAccending}
                                                                        SortDescending={SortDescending}
                                                                        itemCount={elements.length}
                                                                    />
                                                                    :
                                                                    element.typeName === 'Audio'?
                                                                        <AudioElement
                                                                            index={index}
                                                                            element={element}
                                                                            selectedElementIndex={selectedElementIndex}
                                                                            handlePropertyChange={handlePropertyChange}
                                                                            handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                            deleteElement={deleteElement}
                                                                            SortAccending={SortAccending}
                                                                            SortDescending={SortDescending}
                                                                            itemCount={elements.length}
                                                                        />:
                                                                        element.typeName === 'Price'?
                                                                            <PriceElement
                                                                                index={index}
                                                                                element={element}
                                                                                selectedElementIndex={selectedElementIndex}
                                                                                handlePropertyChange={handlePropertyChange}
                                                                                handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                                deleteElement={deleteElement}
                                                                                SortAccending={SortAccending}
                                                                                SortDescending={SortDescending}
                                                                                itemCount={elements.length}
                                                                                handleDefaultValueChange={handleDefaultValueChange}
                                                                            />
                                                                            :
                                                                            element.typeName === 'Slider'?
                                                                                <SliderRange
                                                                                    index={index}
                                                                                    element={element}
                                                                                    selectedElementIndex={selectedElementIndex}
                                                                                    handlePropertyChange={handlePropertyChange}
                                                                                    handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                                    deleteElement={deleteElement}
                                                                                    SortAccending={SortAccending}
                                                                                    SortDescending={SortDescending}
                                                                                    itemCount={elements.length}
                                                                                    handleDefaultValueChange={handleDefaultValueChange}
                                                                                />
                                                                            :
                                                                            element.typeName === 'Date'?
                                                                                <DateElement
                                                                                    index={index}
                                                                                    element={element}
                                                                                    selectedElementIndex={selectedElementIndex}
                                                                                    handleDefaultValueChange={handleDefaultValueChange}
                                                                                    handlePropertyChange={handlePropertyChange}
                                                                                    handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                                    deleteElement={deleteElement}
                                                                                    SortAccending={SortAccending}
                                                                                    SortDescending={SortDescending}
                                                                                    itemCount={elements.length}
                                                                                />
                                                                                :
                                                                                element.typeName === 'Time'?
                                                                                    <TimeElement
                                                                                        index={index}
                                                                                        element={element}
                                                                                        selectedElementIndex={selectedElementIndex}
                                                                                        handleDefaultValueChange={handleDefaultValueChange}
                                                                                        handlePropertyChange={handlePropertyChange}
                                                                                        handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                                        deleteElement={deleteElement}
                                                                                        SortAccending={SortAccending}
                                                                                        SortDescending={SortDescending}
                                                                                        itemCount={elements.length}
                                                                                    />:
                                                                                    element.typeName === 'DateTime'?
                                                                                        <DateTimeElement
                                                                                            index={index}
                                                                                            handleDefaultValueChange={handleDefaultValueChange}
                                                                                            element={element}
                                                                                            selectedElementIndex={selectedElementIndex}
                                                                                            handlePropertyChange={handlePropertyChange}
                                                                                            handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                                            deleteElement={deleteElement}
                                                                                            SortAccending={SortAccending}
                                                                                            SortDescending={SortDescending}
                                                                                            itemCount={elements.length}
                                                                                        />
                                                                                    :
                                                                                element.typeName === 'Url'?
                                                                                    <UrlElement
                                                                                        index={index}
                                                                                        element={element}
                                                                                        selectedElementIndex={selectedElementIndex}
                                                                                        handlePropertyChange={handlePropertyChange}
                                                                                        handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                                        deleteElement={deleteElement}
                                                                                        SortAccending={SortAccending}
                                                                                        SortDescending={SortDescending}
                                                                                        itemCount={elements.length}
                                                                                        handleDefaultValueChange={handleDefaultValueChange}
                                                                                    />
                                                                                    :
                                                                                    element.typeName === 'Color'?
                                                                                        <ColorElement
                                                                                            index={index}
                                                                                            element={element}
                                                                                            selectedElementIndex={selectedElementIndex}
                                                                                            handlePropertyChange={handlePropertyChange}
                                                                                            handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                                            deleteElement={deleteElement}
                                                                                            SortAccending={SortAccending}
                                                                                            SortDescending={SortDescending}
                                                                                            itemCount={elements.length}
                                                                                            handleDefaultValueChange={handleDefaultValueChange}
                                                                                        />
                                                                                        :
                                                                                        element.typeName === 'Password'?
                                                                                            <PasswordElement
                                                                                                index={index}
                                                                                                element={element}
                                                                                                selectedElementIndex={selectedElementIndex}
                                                                                                handlePropertyChange={handlePropertyChange}
                                                                                                handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                                                                                deleteElement={deleteElement}
                                                                                                SortAccending={SortAccending}
                                                                                                SortDescending={SortDescending}
                                                                                                itemCount={elements.length}
                                                                                                handleDefaultValueChange={handleDefaultValueChange}
                                                                                            />


                                                                        :
                                                null
                }
            </div>
        ))}
    </>
);
