import React, {useState} from "react";

export const useRoutingOption=()=>{
    const [setting, setSetting] = useState({
        value:0
    });

    const handleSelectValue=(data,name)=>{
        setSetting({...setting,[name]: data});
    }
    return {
        setSetting,
        setting,
        handleSelectValue
    }
}