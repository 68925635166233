import React from "react";

export const convertSlugHandler = (title) => {
    const slug = title
        .replace(/[^\p{Letter}\d\s]/gu, "-") // غیر حرف و غیر عددی رو با dash جایگزین میکنه
        .replace(/\s+/g, "-") // فضای خالی رو با dash عوض میکنه
        .replace(/-+/g, "-"); // چندین dash  رو با یک dash  جابجا میکنه

    return slug;
};
export const convertSelectDataHandler = (data)=>{
    const options  = data.map(item => ({label: item.value, value: item.key}));
    return options;
}

export const handleGetSettingValue=(name,settingArray)=>{
    const setting = settingArray.find((item)=>item.name === name);
    return setting.value;
}
export const createSettingsUrl = (baseUrl, settingNames) => {
    const queryString = settingNames.map(name => `Name=${encodeURIComponent(name)}`).join('&');
    return `${baseUrl}?${queryString}`;
};
export const fonts=[
    {label:'Vazir',value:'Vazir'},
    {label:'IranSans',value:'IranSanz'},
    {label:'Arial',value:'Arial'},
    {label:'Shabnam',value:'Shabnam'},
]
export const fontSizes=[
    {label:'14px',value:'harmony-default-font'},
    {label:'16px',value:'harmony-small-font'},
    {label:'18px',value:'harmony-medium-font'},
    // {label:'harmony-large-font ',value:'harmony-large-font '},
    {label:'20px',value:'harmony-large-font'},
]


export const convertToTree = (data) => {
    const tree = [];
    const map = data.reduce((acc, obj) => {
        obj.children = [];
        acc[obj.id] = obj;
        obj.subtitle = obj.slug;
        obj.expanded = true;
        return acc;
    }, {});
    data.forEach((obj) => {
        const parent = map[obj.parentId];
        if (parent) {
            parent.children.push(obj);
        }
        else {
            tree.push(obj);
        }
    });
    return tree;
};

export const convertContentGroup = (obj)=>{
    const newObj = {
        id:obj.id !== null ? obj.id:null,
        title: obj.title === null || obj.title === undefined ? null:obj.title,
        seoTitle: obj.seoTitle === null || obj.seoTitle === undefined? null:obj.seoTitle,
        slug: obj.slug === null || obj.slug === undefined ? null : obj.slug,
        parentId: obj.parentId === null || obj.parentId === undefined?null: obj.parentId.value,
        templateId: obj.templateId === null || obj.templateId === undefined ? null :  obj.templateId.value,
        type: obj.type === null || obj.type === undefined? null : obj.type.value,
        order: obj.order,
        excerpt: obj.excerpt === null || obj.excerpt === undefined ? null : obj.excerpt,
        stateShow:obj.stateShow??null,
        stateGoogle:obj.stateGoogle??false,
    }
    return newObj
}
export const convertContentGroupHandler = (obj)=>{
    const newObj = {
        title: obj.title === null || obj.title === undefined ? null:obj.title,
        seoTitle: obj.seoTitle === null || obj.seoTitle === undefined? null:obj.seoTitle,
        slug: obj.slug === null || obj.slug === undefined ? null : obj.slug,
        parentId: obj.parentId === null || obj.parentId === undefined?null: obj.parentId.value,
        templateId: obj.templateId === null || obj.templateId === undefined ? null :  obj.templateId.value,
        type: obj.type === null || obj.type === undefined? null : obj.type.value,
        order: obj.order,
        excerpt: obj.excerpt === null || obj.excerpt === undefined ? null : obj.excerpt,
    }
    return newObj
}



export const handleCreateContentConvertor=(obj,files)=>{
    const convertedValue = {
        id: obj.id ?? null,
        title: obj.title ?? null,
        slug: obj.slug ?? null,
        excerpt: obj.excerpt ?? null,
        editor: obj.editor ?? null,
        seo_Title: obj.seo_Title ?? null,
        seo_Keywords: obj.seo_Keywords ?? null,
        lead: obj.lead ?? null,
        suggestionContentIds: obj.suggestionContentIds.map(item => item.value),
        releaseDateTime: obj.releaseDateTime ?? null,
        writingDate: obj.writingDate ?? null,
        longitude: obj.longitude ?? null,
        thumbnail: obj.thumbnail ?? null,
        authorId: obj.authorId?.value ?? null,
        type: obj.type?.value ?? null,
        contentGroupIds: obj.contentGroupIds.map(item => item.value),
        formId: obj.formId?.value??null,
        filterId:obj.filterId?.value??null,
        templateId: obj.templateId?.value ?? null,
        iFrame: obj.iFrame ?? null,
        metaDataGroupIds: obj.metaDataGroupIds.map(item => item.value),
        isMetaDataGroupChanged: obj.isMetaDataGroupChanged,
        stateShow: obj.stateShow ?? null,
        stateAndroid: obj.stateAndroid ?? null,
        stateGoogle: obj.stateGoogle ?? null,
        sendingStates: obj.sendingStates.map(item => item.value),
        concurrencyStamp:obj.concurrencyStamp??null,
        files:files??null
    };
    return convertedValue
}
export const handleCreateContentFormData=(obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Title',obj.title);
    appendIfNotNull('Slug',obj.slug);
    appendIfNotNull('Excerpt',obj.excerpt);
    appendIfNotNull('Editor',obj.editor);
    appendIfNotNull('Seo_Title',obj.seo_Title);
    appendIfNotNull('Lead',obj.lead);
    appendIfNotNull('Longitude',obj.longitude);
    appendIfNotNull('Latitude',obj.latitude);
    appendIfNotNull('Thumbnail',obj.thumbnail);
    appendIfNotNull('AuthorId',obj.authorId);
    appendIfNotNull('Type',obj.type);
    appendIfNotNull('FormId',obj.formId);
    appendIfNotNull('FilterId',obj.filterId);
    appendIfNotNull('TemplateId',obj.templateId);
    appendIfNotNull('IFrame',obj.iFrame);
    appendIfNotNull('StateShow',obj.stateShow);
    appendIfNotNull('StateAndroid',obj.stateAndroid);
    appendIfNotNull('StateGoogle',obj.stateGoogle);
    if(obj.releaseDateTime){
        const date = new Date(obj.releaseDateTime).toISOString();
        appendIfNotNull('ReleaseDateTime',date);
    }
    if(obj.writingDate){
        const date = new Date(obj.writingDate).toISOString();
        appendIfNotNull('WritingDate',date);
    }
    if (obj.seo_Keywords) {
        obj.seo_Keywords.forEach((keyword, index) => {
            appendIfNotNull(`Seo_Keywords[${index}]`, keyword);
        });
    }
    if (obj.suggestionContentIds) {
        obj.suggestionContentIds.forEach((suggestion, index) => {
            appendIfNotNull(`SuggestionContentIds[${index}]`, suggestion);
        });
    }
    if (obj.contentGroupIds) {
        obj.contentGroupIds.forEach((group, index) => {
            appendIfNotNull(`ContentGroupIds[${index}]`, group);
        });
    }
    if (obj.metaDataGroupIds) {
        obj.metaDataGroupIds.forEach((metaId, index) => {
            appendIfNotNull(`MetaDataGroupIds[${index}]`, metaId);
        });
    }
    if (obj.sendingStates) {
        obj.sendingStates.forEach((state, index) => {
            appendIfNotNull(`SendingStates[${index}]`, state);
        });
    }
    if (obj.files) {
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file !== null){
                formData.append(`Attachments[${i}].file`,obj.files[i].file)
                formData.append(`Attachments[${i}].attachType`, obj.files[i].attachType.value);
                formData.append(`Attachments[${i}].caption`, obj.files[i]?.caption);
                formData.append(`Attachments[${i}].groupOrder`, obj.files[i]?.groupOrder);
            }else{

            }
        }
    }
    return formData;
}

export const handleEditContentConvertor=(obj,files)=>{
    const convertedValue = {
        id: obj.id ?? null,
        thumbnailPath: obj.thumbnailPath ?? null,
        title: obj.title ?? null,
        slug: obj.slug ?? null,
        excerpt: obj.excerpt ?? null,
        editor: obj.editor ?? null,
        seo_Title: obj.seo_Title ?? null,
        seo_Keywords: obj.seo_Keywords ?? null,
        lead: obj.lead ?? null,
        suggestionContentIds: obj.suggestionContentIds.map(item => item.value),
        releaseDateTime: obj.releaseDateTime ?? null,
        writingDate: obj.writingDate ?? null,
        longitude: obj.longitude ?? null,
        thumbnail: obj.thumbnail ?? null,
        authorId: obj.authorId?.value ?? null,
        surveyGroupId: obj.surveyGroupId?.value ?? null,
        type: obj.type?.value ?? null,
        contentGroupIds: obj.contentGroupIds.map(item => item.value),
        formId: obj.formId?.value??null,
        filterId:obj.filterId?.value??null,
        templateId: obj.templateId?.value ?? null,
        iFrame: obj.iFrame ?? null,
        metaDataGroupIds: obj.metaDataGroupIds.map(item => item.value),
        isMetaDataGroupChanged: obj.isMetaDataGroupChanged,
        stateShow: obj.stateShow ?? null,
        stateAndroid: obj.stateAndroid ?? null,
        stateGoogle: obj.stateGoogle ?? null,
        sendingStates: obj.sendingStates.map(item => item.value),
        concurrencyStamp:obj.concurrencyStamp??null,
        files:files??null
    };
    return convertedValue
}

export const handleEditContentFormData=(obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Id',obj.id);
    appendIfNotNull('Title',obj.title);
    appendIfNotNull('Slug',obj.slug);
    appendIfNotNull('Excerpt',obj.excerpt);
    appendIfNotNull('Editor',obj.editor);
    appendIfNotNull('Seo_Title',obj.seo_Title);
    appendIfNotNull('Lead',obj.lead);
    appendIfNotNull('Longitude',obj.longitude);
    appendIfNotNull('Latitude',obj.latitude);
    appendIfNotNull('Thumbnail',obj.thumbnail);
    appendIfNotNull('AuthorId',obj.authorId);
    appendIfNotNull('SurveyGroupId',obj.surveyGroupId);
    appendIfNotNull('Type',obj.type);
    appendIfNotNull('FormId',obj.formId);
    appendIfNotNull('FilterId',obj.filterId);
    appendIfNotNull('TemplateId',obj.templateId);
    appendIfNotNull('IFrame',obj.iFrame);
    appendIfNotNull('StateShow',obj.stateShow);
    appendIfNotNull('StateAndroid',obj.stateAndroid);
    appendIfNotNull('StateGoogle',obj.stateGoogle);
    appendIfNotNull('ConcurrencyStamp',obj.concurrencyStamp);
    appendIfNotNull('ThumbnailPath',obj.thumbnailPath);
    if(obj.releaseDateTime){
        const date = new Date(obj.releaseDateTime).toISOString();
        appendIfNotNull('ReleaseDateTime',date);
    }
    if(obj.writingDate){
        const date = new Date(obj.writingDate).toISOString();
        appendIfNotNull('WritingDate',date);
    }
    if (obj.seo_Keywords) {
        obj.seo_Keywords.forEach((keyword, index) => {
            appendIfNotNull(`Seo_Keywords[${index}]`, keyword);
        });
    }
    if (obj.suggestionContentIds) {
        obj.suggestionContentIds.forEach((suggestion, index) => {
            appendIfNotNull(`SuggestionContentIds[${index}]`, suggestion);
        });
    }
    if (obj.contentGroupIds) {
        obj.contentGroupIds.forEach((group, index) => {
            appendIfNotNull(`ContentGroupIds[${index}]`, group);
        });
    }
    if (obj.metaDataGroupIds) {
        obj.metaDataGroupIds.forEach((metaId, index) => {
            appendIfNotNull(`MetaDataGroupIds[${index}]`, metaId);
        });
    }
    if (obj.sendingStates) {
        obj.sendingStates.forEach((state, index) => {
            appendIfNotNull(`SendingStates[${index}]`, state);
        });
    }

    if (obj.files) {
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file === null && obj.files[i].contentAttachId === null){

            }
            else{
                if(obj.files[i].file !== null && obj.files[i].contentAttachId !== null ){
                    formData.append(`Attachments[${i}].filePath`,obj.files[i].filePath);
                }
                formData.append(`Attachments[${i}].file`,obj.files[i].file);
                formData.append(`Attachments[${i}].attachType`,  obj.files[i].attachType.value);
                formData.append(`Attachments[${i}].caption`, obj.files[i]?.caption );
                formData.append(`Attachments[${i}].groupOrder`, obj.files[i]?.groupOrder );
                formData.append(`Attachments[${i}].contentAttachId`, obj.files[i].contentAttachId );
            }

        }
    }

    return formData
}


export const handleFilterContentMetas=(contentMetas,metaTypes)=>{
    const filteredMeta = contentMetas.map(item => ({
        ...item,
        type: metaTypes.find(typeItem => typeItem.value === item.type)
    }));
    return filteredMeta;
}
export const handleCreateContentMetas=(contentId,concurrencyStamp,contentMeta)=>{
    const metas={
        contentId:contentId,
        concurrencyStamp:concurrencyStamp,
        contentMetas: contentMeta.map((item) => ({
            contentMetaId:item.contentMetaId,
            name: item.name,
            value:item.value,
            type:item.type.value,
            order:item.order
        }))
    }
    return metas;
}

export const handleCreateMetaGroupMetas=(metaGroupId,contentMetas)=>{
    const metas={
        metaDataGroupId:metaGroupId,
        metaDatas: contentMetas.map((item) => ({
            metaDataId:item.metaDataId,
            name: item.name === null || item.name === ''?'-':item.name,
            value:item.value === null || item.value === ''?'-':item.value,
            type:item.type.value,
            order:item.order
        }))
    }
    return metas;
}
export const handleValidateMetaData = (data) => {
    for (let item of data) {
        if (item.name === null || item.value === null) {
            return false;
        }
    }
    return true;
};


export const handleCreateContentDetailFormData=(obj)=>{
    const formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    for (const key in obj) {
        if (key === 'seoKeywords') {
            obj[key].forEach((keyword) => {
                appendIfNotNull(key, keyword);
                //formData.append(key, keyword);
            });
        }
        else {
            appendIfNotNull(key,obj[key]);
            //formData.append(key,obj[key]);
        }
    }
    return formData;
}
export const handleContentAttaches=(contentAttaches,fileTypes)=>{
    const uploadedFiles = contentAttaches.map((item) => {
        return {
            contentAttachId: item.contentAttachId,
            caption: item.caption,
            fileName: item.fileName,
            path: item.path,
            fileSize: item.fileSize,
            modifiedDate: item.modifiedDate,
            groupOrder: item.groupOrder,
            fileType: fileTypes.find((attachType) => attachType.value === item.fileType),
        };
    });
    return uploadedFiles;
}








