import React, {useEffect, useMemo, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {useNavigate} from "react-router-dom";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import Select from "react-select";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {GetSpecialRole} from "../../../../Services/AxiosService/JwtService";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {useUserGrid} from "./useUserGrid";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import UserRow from "./UserRow";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {Export} from "../../../Commans/Grid/Export";
import {ModalButtonOperations} from "../../../Commans/UIParts/ModalButtonOperations";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {ToastContainer} from "react-toastify";
import {HandleUserDetails} from "../UserDetails/HandleUserDetails";
import {Employee} from "../Employees/Employee";
import {Roles} from "../../Users/UserAccess/Roles";

export const HandleListPersonManagement = () => {

    const role = GetSpecialRole();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [refresh,setRefresh] = useState(false);
    const [IsOpenContact,setIsOpenContact] = useState(false);
    const [initialSource,setInitialSource] = useState({groupOptions:[],statusOptions:null,roles:null,types:[],filters:[]})
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [IsShowPersonalInformation,setIsShowPersonalInformation] = useState(false);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [user,setUser] = useState({userId:null,fullName:null});
    const [isMenuOpen,setMenuOpen] = useState(false);
    const handleOpenMenu = (userId,fullName) => {
        setMenuOpen(true);
        setUser({userId: userId,fullName: fullName});
    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner = ()=>{
        setIsShowSpinner(false);
    }



    const handleCloseContact =()=>{
        setIsOpenContact(false);
    }
    const handleOpenContact=(userId)=>{
        setIsOpenContact(true);
        setUser({userId: userId});
    }


    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }

    const handleOpenPersonalInformation=(userId,fullName)=>{
        setUser({userId: userId,fullName: fullName});
        setIsShowPersonalInformation(true);
    }
    const handleClosePersonalInformation = ()=>{
        setIsShowPersonalInformation(false);
    }
    const fetchData = async () => {
        try {
            showSpinner();

            const requests = [
                GET('/Communications/Selection', {}, 'IEnumerable'),
                GET(`/Enumerations/EnActivationStatus`, {}, 'IEnumerable'),
                GET('/UserGroups/Selection', {}, 'IEnumerable'),
                GET('/Roles/Selection', {}, 'IEnumerable'),
                GET(`/Filters/Selection?Table=${1}`, {}, 'IEnumerable')
            ];

            const [
                responseCommunicationType,
                responseStatus,
                responseGroup,
                responseRole,
                responseFilters
            ] = await Promise.all(requests);

            if (
                responseCommunicationType.status === 200 &&
                responseStatus.status === 200 &&
                responseGroup.status === 200 &&
                responseRole.status === 200 &&
                responseFilters.status === 200
            ) {
                hideSpinner();
                const statusOptions = convertSelectDataHandler(responseStatus.data);
                const groupOptions = convertSelectDataHandler(responseGroup.data);
                const roleOptions = convertSelectDataHandler(responseRole.data);
                const types = convertSelectDataHandler(responseCommunicationType.data);
                const filters = convertSelectDataHandler(responseFilters.data);

                setInitialSource((prevOptions) => ({
                    ...prevOptions,
                    statusOptions: statusOptions,
                    groupOptions: groupOptions,
                    roles: roleOptions,
                    types: types,
                    filters: filters
                }));
            } else {
                handleApiError(responseStatus, hideSpinner);
                handleApiError(responseGroup, hideSpinner);
                handleApiError(responseRole, hideSpinner);
                handleApiError(responseCommunicationType, hideSpinner);
                handleApiError(responseFilters, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(()=>{
        fetchData();
    },[])





    const handleDeleteUser = async (userId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Users?Id=${userId}`,{},);
                    if (response.status === 200) {
                        hideSpinner()
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const itemOperations = [
        {
            icon: 'pi-folder-open',
            tooltip: t("AdditionalInformation"),
            // onClick: (id) => handleGetAllUserContacts(id),
            //onClick: (id) => redirectTo(`/CRM/User/List/Details/${id}`) ,
            onClick: (userId) => handleOpenContact(userId) ,
            isShow: true,

        },

        {
            icon: 'pi-wave-pulse',
            tooltip: t("UserInteractions"),
            onClick: (id) => redirectTo(`/CRM/User/List/Log?userId=${id}`),
            isShow: true,
        },
        {
            icon: 'pi-wallet',
            tooltip: t("Wallet"),
            onClick: (id) => redirectTo(`/CRM/User/Wallet?userId=${id}`),
            isShow: true,
        },


        {
            icon: 'pi-book',
            tooltip: t("Contracts"),
            onClick: (id,fullName) => redirectTo(`/CRM/User/Contract/${id}/${fullName}`),
            isShow: true,


        },

        {
            icon: 'pi-id-card',
            tooltip: t("PersonalInformation"),
            onClick: (userId,fullName) => handleOpenPersonalInformation(userId,fullName),
            isShow: false

        },

        {
            icon: 'pi-shield',
            tooltip: t("InsurancePolicy"),
            onClick: (userId,fullName) => redirectTo(`/CRM/User/List/Insurance/${userId}/${fullName}`),
            isShow: false,


        },
        {
            icon: 'pi-pencil',
            tooltip: t("EditUser"),
            onClick: (id) => redirectTo(`/CRM/User/Add?id=${id}`),
            isShow: true,

        },
        {
            icon: 'pi-trash',
            tooltip: t("DeleteUser"),
            onClick: (id) => handleDeleteUser(id),
            isShow: true,

        },
    ];

    const {rowData,handleCurrentPage,currentPage,handleNextPage,handleUserPin,handlePreviousPage, handlePageSizeCount,data,filter,setFilter,handleSearch,handleRegisterToDateTime,handleRegisterFromDateTime,handleResetFilters,handleSwitchFilters,handleSelectFilterValue,handleSelectFilterUserGroupIds,SwitchFilters,setSearch,search}=useUserGrid(refresh,Refresh,showSpinner,hideSpinner)


    return(
        <>
            <div class="space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 ">
                    <div className="space-y-5">
                        <ListSearch label={null} icon={null} >
                            <div class="col-span-1 sm:col-span-4 space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                                <Select
                                    options={initialSource.groupOptions}
                                    isClearable={true}
                                    isMulti={true}
                                    value={filter.GroupIds}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectFilterUserGroupIds(data)}
                                    placeholder={t("selection")}
                                    className={"select-group"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class=" col-span-1 md:col-span-2 lg:col-span-1 space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("FilterTitle")}</label>
                                <Select
                                    options={initialSource.filters}
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    value={filter.filterId}
                                    onChange={(data)=>handleSelectFilterValue(data,'filterId')}
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class=" col-span-1 md:col-span-2 lg:col-span-1 space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("search")}</label>
                                <InputText type="text" onChange={(e)=>setSearch(e.target.value)} value={search} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" />
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("userType")}</label>
                                <Select
                                    options={initialSource.roles}
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectFilterValue(data,'RoleId')}
                                    value={filter.RoleId}
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("Status")}</label>
                                <Select
                                    options={initialSource.statusOptions}
                                    isMulti={false}
                                    isClearable={true}
                                    value={filter.ActivationStatus}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectFilterValue(data,'ActivationStatus')}
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class=" space-y-2">
                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("FromDate")}
                                </label>
                                <DateTimePicker handleDateTime={handleRegisterFromDateTime} value={filter.RegisterFromDate} format={'YYYY/MM/DD'} />
                            </div>
                            <div class=" space-y-2">
                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("ToDate")}
                                </label>
                                <DateTimePicker value={filter.RegisterToDate} handleDateTime={handleRegisterToDateTime} format={'YYYY/MM/DD'} />
                            </div>
                            <ButtonSearchList
                                onClickSearch={()=>handleSearch()}
                                onClickReset={()=>handleResetFilters()}
                            />
                        </ListSearch>
                        <ListFilter label={null} icon={null}
                                    childrenOne={
                                        <>

                                            <ButtonPlus
                                                onClick={()=>redirectTo(`/CRM/User/Add`)}
                                                tooltipTitle={t("UsersRegistration")}
                                                title={t("personnel")}
                                            />

                                            <ButtonPlus
                                                onClick={()=>redirectTo(`/CRM/User/Group`)}
                                                icon={"pi-users"}
                                                title={t("grouping")}
                                                tooltipTitle={t("UsersGrouping")}
                                            />
                                            <ButtonPlus
                                                onClick={()=>redirectTo(`/CRM/User/Role`)}
                                                icon={"pi-star"}
                                                title={t("Role")}
                                                tooltipTitle={t("CreatingRole")}
                                            />

                                            <ButtonPlus
                                                onClick={()=>redirectTo(`/CRM/User/List`)}
                                                icon={"pi-list"}
                                                title={t("list")}
                                                functionClass={"harmony-return-list"}
                                                tooltipTitle={t("PersonList")}
                                            />
                                            <ButtonPlus
                                                onClick={()=>redirectTo(`/CRM/User/Reminder/List`)}
                                                icon={"pi-clock"}
                                                title={t("Reminders")}
                                                tooltipTitle={t("Reminders")}
                                            />

                                        </>
                                    }
                                    childrenTwo={
                                        <>

                                                <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleSwitchFilters} />


                                            {
                                                role === 'SuperAdmin'?
                                                    <InputSwitch OnValueSelection={handleSwitchFilters} name={'hasList'} lable={'دارای سایت'} />
                                                    :null
                                            }
                                        </>
                                    }
                        />

                    </div>
                </div>

                <div class={"space-y-5"}>
                    <div className="relative bg-white dark:bg-themeDarkSalt700 p-3 box list_user">
                        {
                            IsShowSpinner?
                                <div className=" huploader_loading" >
                                    <div className="relative">
                                        <LoadingHarmony/>
                                    </div>
                                </div>
                                :
                                null
                        }
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

                            {rowData && rowData.length > 0 ?
                                rowData.map((row, index) => (
                                    <UserRow
                                        key={row.id}
                                        row={row}
                                        index={index}
                                        handleUserPin={handleUserPin}
                                        handleDeleteUser={handleDeleteUser}
                                        handleOpenMenu={handleOpenMenu}
                                        itemOperations={itemOperations}
                                        t={t}
                                    />
                                )) :
                                t("Loading")}

                        </div>
                        {
                            data.hasNextPage ?
                                <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                                :null
                        }
                    </div>
                    <div class={"grid grid-cols-1 lg:grid-cols-12 bg-white dark:bg-themeDarkSalt700"}>
                        <div className={"lg:col-span-9 "}>
                            <Export filters={filter} apiUrl={'/Users/ExportUserList'}/>
                        </div>

                        <div className="lg:col-span-3 flex gap-1 items-center justify-center p-2 ">
                            <div className={"flex-1"}>
                                <Select
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                    placeholder={t("selection")}
                                />
                            </div>
                            <button className="button small_button bg-themeInformation text-white dark:bg-themeDarkInformation">
                                {t("sending")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>



            <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu}>
                <div className="p-1 grid grid-cols-3 md:grid-cols-4 gap-3">
                    <ModalButtonOperations
                        icon={"alert-circle"}
                        title={t("Access")}
                        onClick={()=>redirectTo(`/CRM/User/List/Access?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"message-circle"}
                        title={t("Chat")}
                        onClick={()=>redirectTo(`/Support/Ticket?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"file-plus"}
                        title={t("Documents")}
                        onClick={()=>redirectTo(`/CRM/User/List/UploadDocument?userId=${user.userId}`)}
                    />

                    <ModalButtonOperations
                        icon={"message-square"}
                        title={t("Note")}
                        onClick={()=>redirectTo(`/CRM/User/List/Reminder/Add?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"trending-up"}
                        title={t("Survey")}
                        onClick={()=>redirectTo(`/CMS/Surveys/List/ResultDetails?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"award"}
                        title={t("Guarantee")}
                        onClick={()=>redirectTo(`/Guarantee/Customer?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"shopping-cart"}
                        title={t("ShoppingCart")}
                        onClick={()=>redirectTo(`/Shop/Product/Cart?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"file"}
                        title={t("حضور غیاب")}
                        onClick={()=>redirectTo(`/Attendance/List/IndividualReport?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"book-open"}
                        title={t("Addresses")}
                        onClick={()=>redirectTo(`/CRM/User/List/Address?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"message-square"}
                        title={t("comments")}
                        onClick={()=>redirectTo(`/CMS/Comment/Inbox?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"headphones"}
                        title={t("Ticket")}
                        onClick={()=>redirectTo(`/Support/OnlineTicket?userId=${user.userId}`)}
                    />
                    <ModalButtonOperations
                        icon={"user"}
                        title={t("Interactions")}
                        onClick={()=>redirectTo(`/CRM/User/List/UserInteractions?userId=${user.userId}`)}
                    />

                </div>
            </FantasyModal>



            <FantasyModal openModal={IsOpenContact}  customeClassName={"modal-xl"}  closeModal={handleCloseContact} title={t("AdditionalInformation")}>
                {
                    IsOpenContact?
                        <HandleUserDetails IsOpen={IsOpenContact} searchType={0} searchValue={user.userId} />
                        :
                        null
                }
            </FantasyModal>

            <FantasyModal openModal={IsShowPersonalInformation}  closeModal={handleClosePersonalInformation} title={t("PersonalInformation")} >
                {
                    IsShowPersonalInformation?
                        <Employee
                            user={user}
                            handleClosePersonalInformation={handleClosePersonalInformation}
                        />
                        :null

                }

            </FantasyModal>


            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>

            <ToastContainer />

        </>

    )
}