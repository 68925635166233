import React, {useEffect, useMemo, useState} from "react";

import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {useCustomTariffBinder} from "./useCustomTariffBinder";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {useGridTariff} from "./useGridTariff";
import {handleConvertAddTariff, handleConvertEditTariff} from "./TariffService";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {ToastContainer} from "react-toastify";
import {Accordion, AccordionTab} from "primereact/accordion";
import {GetCurrentDateTime} from "../../../Services/Globals/Public/TimeServiceManager";
import {SelectButton} from "primereact/selectbutton";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";


export const HandleBaseTariff=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [modal,setModal] = useState({ isTariffModal: false });
    const [showSpinner,setShowSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [refresh,setRefresh] = useState(0);
    const [IsEdit,setIsEdit] = useState(false);
    const [initialSource,setInitialSource] = useState({countries:[],currencies:[],discountTypes:[],upgradeTypes:[],paymentMethods:[],packages:[],templateMasters:[]});

    const {handleGetInitialCountry,handleNumberValue,handleValue,tariff,setTariff,handleSelectValue,handleFromDate,handleToDate, setValueService,valueService,services,handleChangeService} = useCustomTariffBinder(setErrors,initialSource);
    // closes Tariff modal
    const handleCloseTariff = () => {
        setModal({isTariffModal: false});
        handleClearErrors();
        handleResetTariff();
        redirectTo('/Admin/BaseTariff/Add');
        setIsEdit(false);
    };
    // opens up modal to track tariff data
    const handleOpenTariff=()=>{
        setModal({isTariffModal: true});
        const toDate = GetCurrentDateTime(false,1);
        const country = handleGetInitialCountry('Iran');
        handleChangeService(1)
        setTariff({countryId: country,currencyType: initialSource.currencies[0],upgradeType:initialSource.upgradeTypes[0],discountType:initialSource.discountTypes[0],price: 0,discountAmount: 0,discountFrom: new Date(),discountTo: null,paymentMethod:initialSource.paymentMethods[0]??null,packageId: initialSource.packages[0]??null,isService: true,templateMasterId: initialSource.templateMasters[0]})
    }
    // a callback for handle loading spinner
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // resets all errors to nothing as initial state
    const handleClearErrors=()=>{
        setErrors({});
    }
    //resets tariff json model to initial state
    const handleResetTariff=()=>{
        const country = handleGetInitialCountry('Iran');
        setTariff({countryId:country,currencyType: initialSource.countries[0],price: 0,upgradeType: initialSource.upgradeTypes[0],paymentMethod:initialSource.paymentMethods[0]??null,packageId: initialSource.packages[0]??null,templateMasterId: initialSource.templateMasters[0]})
    }
    // refreshes component
    const Refresh=(state)=>{
        setRefresh({...state});
    }
    //handle initial Data source
    const handleInitialSource = (countries, currencies, discountTypes, upgrades, paymentMethods, packages, templateMasters) => {
        const sources = {
            countries: countries,
            currencies: currencies,
            discountTypes: discountTypes,
            upgradeTypes: upgrades,
            paymentMethods: paymentMethods,
            packages: packages,
            templateMasters: templateMasters
        };
        const sourceData = Object.keys(sources).reduce((acc, key) => {
            acc[key] = convertSelectDataHandler(sources[key]);
            return acc;
        }, {});
        setInitialSource(sourceData);
    };
    // fetches all data initially from server
    const fetchData = async () => {
        try {
            setShowSpinner(true);

            const [
                responseCountry,
                responseCurrency,
                responseDiscount,
                responseUpgrade,
                responsePayment,
                responsePackage,
                responseTemplateMaster
            ] = await Promise.all([
                GET(`/Countries/Selection`, {}, 'IEnumerable'),
                GET(`/CurrencyTypes/Selection`, {}, 'IEnumerable'),
                GET('/Enumerations/EnDiscountOrCommissionType', {}, 'IEnumerable'),
                GET(`/Enumerations/EnUpgradeType?keys=${7}&&keys=${8}&&keys=${11}`, {}, 'IEnumerable'),
                GET(`Enumerations/EnPaymentMethod`, {}, 'IEnumerable'),
                GET(`/Packages/Selection`, {}, 'IEnumerable'),
                GET(`/TemplateMasters/GetTemplateMastersSelection`, {}, 'IEnumerable')
            ]);

            if (
                responseCountry.status === 200 &&
                responseCurrency.status === 200 &&
                responseDiscount.status === 200 &&
                responsePayment.status === 200 &&
                responsePackage.status === 200 &&
                responseTemplateMaster.status === 200
            ) {
                handleInitialSource(
                    responseCountry.data,
                    responseCurrency.data,
                    responseDiscount.data,
                    responseUpgrade.data,
                    responsePayment.data,
                    responsePackage.data,
                    responseTemplateMaster.data
                );
            } else {
                handleApiError(responseCountry, onApiError);
                handleApiError(responseCurrency, onApiError);
                handleApiError(responseDiscount, onApiError);
                //handleApiError for other responses if needed
            }
            setShowSpinner(false);
        } catch (e) {
            onApiError();
        }
    };

    useEffect(()=>{
       fetchData()
    },[])

    const handleSubmitTariff=async(e)=>{
        e.preventDefault();
        try {
         if(IsEdit){
             const tariffs = handleConvertEditTariff(tariff,valueService);
             const response = await PUT(`/Tariffs`,tariffs,true);
             if(response.status === 200){
                 handleSuccessMessage(t('TariffEditedSuccessfully'));
                 handleCloseTariff();
                 Refresh(response.status);
             }
             else{
                 handleApiError(response,onApiError);
             }
         }
         else{
             const tariffs = handleConvertAddTariff(tariff,valueService);
             const response = await POST(`/Tariffs`,tariffs,true);
             if(response.status === 200){
                 handleSuccessMessage(t('TariffRegisteredSuccessfully'));
                 handleCloseTariff();
                 Refresh(response.status);
             }
             else{
                 handleApiError(response,onApiError);
             }
         }

        }
        catch (e) {
            onApiError();
        }
    }







    const {rowData,currentPage,setFilter,filter,data,search,setSearch,columnDefs,defaultColDef,getRowHeight,autoGroupColumnDef,filterValueHandler,resetFilters,handleSearch,handleRemoveTariff,handleEditTariff,handlePreviousPage,handleNextPage,handlePageSizeCount,handleCurrentPage,SwitchFilters,handleFilterValue} = useGridTariff(setShowSpinner,fetchData,refresh,onApiError,initialSource,setTariff,setModal,setIsEdit,Refresh,setValueService);






    return(<>

        <div class="space-y-5 relative">
            {
                showSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null}>
                    <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("CurrencyType")}</label>
                        <Select
                            options={initialSource.currencies}
                            isSearchable={true}
                            onChange={(data) => handleFilterValue(data, 'currencyType','currencyValue')}
                            placeholder={t("selection")}
                            value={filter.currencyValue}
                            classNamePrefix={"h_multi_select"}
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("ByCountry")}</label>
                        <Select
                            options={initialSource.countries}
                            isSearchable={true}
                            onChange={(data) => handleFilterValue(data, 'countryId','countryValue')}
                            placeholder={t("selection")}
                            value={filter.countryValue}
                            classNamePrefix={"h_multi_select"}
                        />
                    </div>
                    <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("TypeOfService")}</label>
                        <Select
                            options={initialSource.upgradeTypes}
                            isSearchable={true}
                            onChange={(data) => handleFilterValue(data, 'upgradeType','upgradeValue')}
                            placeholder={t("selection")}
                            value={filter.upgradeValue}
                            classNamePrefix={"h_multi_select"}
                        />
                    </div>

                    <ButtonSearchList
                        onClickSearch={() => handleSearch()}
                        onClickReset={() => resetFilters()}
                    />
                </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => handleOpenTariff()}
                                        tooltipTitle={t("Tariffcreation")}
                                    />
                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />

            </div>

            <div className={"space-y-2 H-body-ag-grid"} >


                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridEnglish}
                />
                <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
            </div>
            <Export apiUrl={''} filters={filter}/>
        </div>

        <FantasyModal openModal={modal.isTariffModal}  closeModal={handleCloseTariff} title={t("Tariffcreation")} >
            <form onSubmit={handleSubmitTariff} method={'post'}>
                <div className={"space-y-5"}>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3 ">
                        <div class="space-y-2">
                            <div >
                                <div className="flex justify-content-center  h-select-button ">
                                    <SelectButton value={valueService} onChange={(e) => handleChangeService(e.value)} optionLabel="name" options={services}  />
                                </div>
                            </div>

                            {
                                valueService === 1?
                                    <Select
                                        options={initialSource.upgradeTypes}
                                        isSearchable={true}
                                        onChange={(data) => handleSelectValue(data, 'upgradeType')}
                                        placeholder={t("selection")}
                                        value={tariff.upgradeType}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />:
                                   valueService === 2 ?
                                    <Select
                                        options={initialSource.packages}
                                        isSearchable={true}
                                        onChange={(data) => handleSelectValue(data, 'packageId')}
                                        placeholder={t("selection")}
                                        value={tariff.packageId}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                       :
                                       valueService === 3?
                                           <Select
                                               options={initialSource.templateMasters}
                                               isSearchable={true}
                                               onChange={(data) => handleSelectValue(data, 'templateMasterId')}
                                               placeholder={t("selection")}
                                               value={tariff.templateMasterId}
                                               menuPosition={"fixed"}
                                               classNamePrefix={"h_multi_select"}
                                           />:null

                            }


                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("country")}</label>
                            <Select
                                options={initialSource.countries}
                                isSearchable={true}
                                onChange={(data) => handleSelectValue(data, 'countryId')}
                                placeholder={t("selection")}
                                value={tariff.countryId}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                    </div>
                    <div className={"flex items-center gap-2"}>
                        <div class="space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">{t("Price")}</label>
                            <InputNumber type={"text"} name={'price'} min={0} value={tariff.price}
                                         onChange={(e) => handleNumberValue(e.value, 'price')}/>
                        </div>
                        <div class="space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">{t("CurrencyUnit")}</label>
                            <Select
                                options={initialSource.currencies}
                                isSearchable={true}
                                onChange={(data) => handleSelectValue(data, 'currencyType')}
                                placeholder={t("selection")}
                                value={tariff.currencyType}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />

                        </div>
                        <div class="space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">{t("CalculationMethod")}</label>
                            <Select
                                options={initialSource.paymentMethods}
                                isSearchable={true}
                                onChange={(data) => handleSelectValue(data, 'paymentMethod')}
                                placeholder={t("selection")}
                                value={tariff.paymentMethod}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />

                        </div>
                    </div>
                    <div>
                        <Accordion  className={"bg-white dark:bg-themeDarkSalt700 md:col-span-2"}>
                            <AccordionTab
                                header={
                                    <div className="flex align-items-center gap-2">
                                        <HPrimeIcon icon={'percent'}/>
                                        <p>{t("discount")}</p>
                                    </div>
                                }
                            >
                                <div
                                    class="grid grid-cols-1 md:grid-cols-2 gap-2 post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("DiscountAmount")}</label>
                                        <InputNumber type={"text"} name={'discountAmount'} min={0}
                                                     value={tariff.discountAmount}
                                                     onChange={(e) => handleNumberValue(e.value, 'discountAmount')}/>
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("DiscountType")}</label>
                                        <Select
                                            options={initialSource.discountTypes}
                                            isSearchable={true}
                                            onChange={(data) => handleSelectValue(data, 'discountType')}
                                            placeholder={t("selection")}
                                            value={tariff.discountType}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                            <SettingButton
                                                onClick={() => handleSetting('GeneralSetting')}
                                                tooltipTitle={t("Calendarsettings")}
                                                tooltipId={4}
                                            />
                                            {t("FromDate")}
                                        </label>
                                        <DateTimePicker value={tariff.discountFrom} handleDateTime={handleFromDate}
                                                        format={'YYYY/MM/DD'}/>
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                            <SettingButton
                                                onClick={() => handleSetting('GeneralSetting')}
                                                tooltipTitle={t("Calendarsettings")}
                                                tooltipId={5}
                                            />
                                            {t("ToDate")}
                                        </label>
                                        <DateTimePicker value={tariff.discountTo} handleDateTime={handleToDate}
                                                        format={'YYYY/MM/DD'}/>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div>
                        <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                    </div>
                </div>
            </form>
        </FantasyModal>


        <FantasyModal openModal={IsSettingOpen} closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")}>
            <div>
                {
                    setting === 'GeneralSetting' ?
                        <HandleGeneralSetting/> :
                        setting === 'waterMark' ?
                            <HandlePicturesSettings/> : null
                }
            </div>
        </FantasyModal>
        <ToastContainer/>
    </>)
}