import {useState} from "react";

export const handleGetOrganizationGroups=(groups)=>{
    const options  = groups.map(item => ({label: item.caption, value: item.linkOrganizationGroupId,}));
    return options;
}
export const handleCreateOrganization = (obj) => {

    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('caption', obj.caption );
    appendIfNotNull('rank',obj.rank );
    appendIfNotNull('iconPath', obj.iconPath);
    appendIfNotNull('target',obj.target.value);
    appendIfNotNull('linkOrganizationGroupId', obj.linkOrganizationGroupId);
    if (obj.icon) {
        appendIfNotNull('icon',obj.icon);
    }

    if(obj.linkOrganizationType === 0 || obj.linkOrganizationType=== 1){
        appendIfNotNull('linkOrganizationType',obj.linkOrganizationType);
        appendIfNotNull('link',obj.link.value);
    }else{
        appendIfNotNull('linkOrganizationType',obj.linkOrganizationType);
        appendIfNotNull('link',obj.link);
    }
    return formData;
};

export const handleEditOrganization = (obj) => {

    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('linkOrganizationId', obj.linkOrganizationId );
    appendIfNotNull('caption', obj.caption );
    appendIfNotNull('rank',obj.rank );
    appendIfNotNull('iconPath', obj.iconPath);
    appendIfNotNull('target',obj.target.value);
    appendIfNotNull('linkOrganizationGroupId', obj.linkOrganizationGroupId);
    if (obj.icon) {
        appendIfNotNull('icon',obj.icon);
    }

    if(obj.linkOrganizationType === 0 || obj.linkOrganizationType=== 1){
        appendIfNotNull('linkOrganizationType',obj.linkOrganizationType);
        appendIfNotNull('link',obj.link.value);
    }else{
        appendIfNotNull('linkOrganizationType',obj.linkOrganizationType);
        appendIfNotNull('link',obj.link);
    }
    return formData;
};