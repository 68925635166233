import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCustomContentGroup} from "./useCustomContentGroup";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {convertContentGroupHandler, convertSelectDataHandler} from "../ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {validate} from "../../../../Services/Globals/validate";
import {contentGroupSchema} from "../contentGroupSchema";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";


export const ContentGroup=(props)=>{
    const {t}=useTranslation();
    const [IsshowSpinner,setIsShowSpinner] = useState(false);
    const [butLoading,setBtnLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [refresh,setRefresh] = useState(false);
    const [initialSource,setInitialSource] = useState({contentGroups:[],contentTypes:[]});

    const handleInitialSource=(contentGroups,contentTypes)=>{
        const contentGroupData = convertSelectDataHandler(contentGroups)
        const contentTypeData = convertSelectDataHandler(contentTypes)
        setInitialSource({
            contentGroups:contentGroupData,
            contentTypes: contentTypeData
        })
        return {contentGroupData,contentTypeData}
    }
    const handleClearErrors=()=>{
        setErrors({});
    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const {validateSlug,group,setGroup,handleValue,handleSelectValue} = useCustomContentGroup(setErrors,hideSpinner);

    const fetchData = async (IsOpen) => {
        if (IsOpen) {
            try {
                // Use Promise.all to call both APIs concurrently
                const [responseType, responseGroup] = await Promise.all([
                    GET('/Enumerations/EnContentType', {}, 'IEnumerable'),
                    GET('/ContentGroups/Selection', {}, 'IEnumerable')
                ]);

                if (responseType.status === 200 && responseGroup.status === 200) {
                    const { contentGroupData, contentTypeData } = handleInitialSource(responseGroup.data, responseType.data);
                    setGroup({
                        slug: '',
                        displayOrder: 1,
                        excerpt: '',
                        parentId: contentGroupData[0],
                        type: contentTypeData[0],
                        seoTitle: '',
                        title: ''
                    });
                    handleClearErrors();
                } else {
                    handleApiError(responseType, hideSpinner);
                    handleApiError(responseGroup, hideSpinner);
                }
            } catch (e) {
                hideSpinner();
            }
        }
    };

    useEffect(()=>{
        fetchData(props.IsOpen);
    },[refresh,props.IsOpen]);

    const handleSubmitContentGroup=async (e)=>{
        e.preventDefault();
        const isValid = validate(contentGroupSchema,group,onSetErrors);
        if(!isValid){
            return;
        }
        try {
            showSpinner();
           const contentGroup = convertContentGroupHandler(group);
           const response = await POST(`/ContentGroups`,contentGroup,false);
           if(response.status === 200){
               hideSpinner()
               props.handleSuccess(true);
               handleSuccessMessage(t("Contentgrouphasbeensuccessfullysubmitted"));
           }
           else{
               handleApiError(response,hideSpinner);
           }
        }
        catch (e) {

            hideSpinner();
        }
    }


    return (

        <>
            <div >
                {/*{*/}
                {/*    IsshowSpinner?*/}
                {/*        <div className=" huploader_loading" >*/}
                {/*            <div className="relative">*/}
                {/*                <LoadingHarmony/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        :*/}
                {/*        null*/}
                {/*}*/}
                <form method={"post"} onSubmit={handleSubmitContentGroup} className={"space-y-5"}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div className="space-y-2">
                            <div className={"flex justify-between items-center"}>
                                <label className="dark:text-gray-300 text-xs">{t("Title")}</label>
                                <DisplayError message={errors.title} />
                            </div>
                            <InputText type={"text"}  name={"title"} value={group.title} onInput={(e) => handleValue(e)} className={" input w-full"}
                                       onBlur={validateSlug} />
                        </div>
                        <div className="space-y-1">
                            <label className="dark:text-gray-300 text-xs">{t("WebsiteTitle")}</label>
                            <InputText type={"text"} name={"seoTitle"} value={group.seoTitle} onInput={(e) => handleValue(e)}
                                       className={" input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <div className={"flex justify-between items-center"}>
                                <label className="dark:text-gray-300 text-xs">{t("ArticleAddress")}</label>
                                <DisplayError message={errors.slug} />
                            </div>

                            <InputText type={"text"} name={"slug"}
                                       value={group.slug}
                                       onInput={(e) => handleValue(e)}
                                       className={" input w-full"}
                                       onBlur={validateSlug} />
                        </div>
                        <div className="space-y-1">
                            <label className="dark:text-gray-300 text-xs">{t("Group")}</label>
                            <div>
                                <Select options={initialSource.contentGroups} // Use an empty array if selectOptions.type is null or undefined
                                        isMulti={null}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data) =>handleSelectValue(data, 'parentId')}
                                        name={'parentId'}
                                        placeholder={t("selection")}
                                        value={group.parentId}
                                        menuPosition={'fixed'}
                                        classNamePrefix={"h_multi_select"}

                                />

                            </div>
                        </div><div
                        className="space-y-1">
                            <label className="dark:text-gray-300 text-xs">{t("type")}</label>
                            <Select options={initialSource.contentTypes} // Use an empty array if selectOptions.type is null or undefined
                                    isMulti={null}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data) =>handleSelectValue(data, 'type')}
                                    name={'type'}
                                    placeholder={t("selection")}
                                    value={group.type}
                                    menuPosition={'fixed'}
                                    classNamePrefix={"h_multi_select"}

                            />

                        </div>
                    </div>
                    <div>
                        <button type={"submit"} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative"}>
                            {t("insertion")}
                            {butLoading ? (
                                <TinyLoading
                                />
                            ) : (
                                null
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )

}