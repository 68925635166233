import React, { useState, useEffect, useContext } from "react";
import { MenuBarMobile } from "../Commans/SideBar/MenuBar-Mobile";
import { MenuBar } from "../Commans/SideBar/MenuBar";
import { TopBar } from "../Commans/NavBar/TopBar";



import "primeicons/primeicons.css";
// import '../../Styles/dist/css/app.css';
// import "../../Styles/dist/css/style.css";
// import "../../Styles/dist/css/Darkmode.css";

import "../../Styles/dist/css/IR_app.css";
// import '../../Styles/dist/css/En_app.css';
// import "../../../Styles/dist/css/output.css";
import { StateContext } from "../Authentication/StateProvider";
import {MenuBar2} from "./MenuBar2";
import {Link} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";

//as it's pretty clear , the independent components are handled inside of this .
export const MasterLayout2 = (props) => {
  const { t } = useTranslation();
  const { children } = props;

  const { lang } = useContext(StateContext);

  useEffect(() => {
    if (lang === "fa") {
      import("../../Styles/dist/css/IR_app.css");
    } else if (lang === "en") {
      import("../../Styles/dist/css/En_app.css");
    }
  }, [lang]);

  return (
      <div className={"harmony_body"}>
          <div className="app dark:bg-themeDarkApp">
          <MenuBarMobile />

          <div className="main-page flex">
            <MenuBar2/>

            <div className="content dark:bg-themeDarkContent">
              <div className={"space-y-2 p-2"}>
                {/*<TopBar />*/}
                <div className="hidden md:flex items-center min-h-12 justify-center md:justify-end flex-wrap gap-y-3">
                  <div className={"flex gap-2 items-center"}>
                    <Link to="/ServiceUpgrade" className="flex items-center gap-2 justify-center rounded-md bg-themeInformation dark:bg-themeDarkInformation text-white p-2 w-28">
                      <HPrimeIcon icon={'pi-sparkles'}/>
                      {t("Upgrade")}
                    </Link>
                    <Link to="/InvoiceRequest" className="flex items-center relative gap-2 justify-center rounded-md bg-themeInformation dark:bg-themeDarkInformation text-white p-2 w-28">
                      <span className={"bill_notification"}>20</span>
                      <HPrimeIcon icon={'pi-file-edit'}/>
                      <h2>{t("Bill")}</h2>
                    </Link>
                  </div>
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>

  );
};
