import React, {useEffect, useState} from "react";
import {TabMenu} from "../../../Commans/UIParts/TabMenu";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useNavigate, useParams} from "react-router-dom";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import "../../../../Styles/Internal/Form.css"
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {useFormBuilderTab} from "./Tabs/useFormBuilderTab";
import {Fields} from "./Tabs/Fields";
import {Properties} from "./Tabs/Properties";
import {useFormBuilder} from "./useFormBuilder";
import {FormElements} from "./Elements/FormElements";
import {handleFailureMessage,handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {handleCreateFormItems, handleGetJsonElementFromServer} from "./formBuilderService";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {ToastContainer} from "react-toastify";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {NoDataHarmony} from "../../../Commans/NoDataHarmony";
export const HandleBuildForm = () => {
    const handleSelectTabAfterElementSelection=(index)=>{
        handleChangeTab('property');
        setSelectedElementIndex(index);
    }
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {handleChangeTab,tabs,tabId}=useFormBuilderTab()
    const { deleteElement,elements,fields,handleDefaultValueChange,addElement,handlePropertyChange,selectedElementIndex,setSelectedElementIndex,SortAccending,SortDescending,addCheckBoxItem,deleteCheckBoxItem,handleItemPropertyChange,setElements} = useFormBuilder(handleChangeTab);
    const { formId } = useParams();
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const [initialSource,setInitialSource]=useState({
        sizeParameters:[],
        calendarViews:[],
        calendarTypes:[],
        calendarLanguages:[],
    })
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleInitialDataSource=(currencies,calendarTypes,calendarLanguages,calendarViews)=>{
        const calendarTypesData = convertSelectDataHandler(calendarTypes)
        const calendarLanguagesData = convertSelectDataHandler(calendarLanguages)
        const calendarViewsData = convertSelectDataHandler(calendarViews)
        const sizeParameters = [{value:0,label:t("Small"),className:'harmony-col-span-1'},{value:1,label:t("Medium"),className:'harmony-col-span-1 harmony-md-col-span-2'},{value:2,label:t("AlmostBig"),className:'harmony-col-span-1 harmony-md-col-span-3'},{value:3,label:t("Large"),className:'harmony-col-span-1 harmony-md-col-span-4'}];
        setInitialSource({
            calendarTypes: calendarTypesData,
            calendarLanguages: calendarLanguagesData,
            calendarViews: calendarViewsData,
            sizeParameters: sizeParameters
        });
        return {
            calendarTypesData,
            calendarLanguagesData,
            calendarViewsData,
            sizeParameters
        }
    }

    const fetchData = async () => {
        try {
            showSpinner();
            const [
                responseCurrency,
                responseCalendarType,
                responseCalendarLanguage,
                responseCalendarView,
                responseForm
            ] = await Promise.all([
                GET(`/Enumerations/EnCurrencyType`, 'IEnumerable'),
                GET(`/Enumerations/EnCalendarType`),
                GET(`/Enumerations/EnCalendarLangType`),
                GET(`/Enumerations/EnCalendarView`),
                GET(`/Forms/${formId}`, {}, 'single')
            ]);
            if (
                responseCurrency.status === 200 &&
                responseCalendarType.status === 200 &&
                responseCalendarLanguage.status === 200 &&
                responseCalendarView.status === 200 &&
                responseForm.status === 200
            ) {
                const {

                    calendarLanguagesData,
                    calendarTypesData,
                    calendarViewsData,
                    sizeParameters
                } = handleInitialDataSource(
                    responseCurrency.data,
                    responseCalendarType.data,
                    responseCalendarLanguage.data,
                    responseCalendarView.data
                );
                const {formItems}=responseForm.data;
                const retrievedElements =  handleGetJsonElementFromServer(
                    formItems,
                    calendarLanguagesData,
                    calendarTypesData,
                    calendarViewsData,
                    sizeParameters
                );
                setElements(retrievedElements);
            } else {
                handleApiError(responseForm, hideSpinner);
            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };


    useEffect(()=>{
        fetchData();
    },[refresh])





const handleSubmitFormItem=async()=>{
        if(elements.length === 0){
            handleFailureMessage('لطفا المنت انتخاب کنید');
            return;
        }
        try {
            showSpinner();
            const formItems = handleCreateFormItems(formId,elements);
            const response = await POST(`/FormItems`,formItems,false);
           if(response.status === 200){
               hideSpinner();
               handleSuccessMessage(t('SuccessDone'));
               redirectTo('/Form/Create');
               Refresh();
           }
           else{
               handleApiError(response,hideSpinner);
           }
        }
        catch (e) {
            hideSpinner();
        }
}

    return(
        <>
                <div className="space-y-5 relative">
                    {
                        IsShowSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div>
                        <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                            <ListFilter label={null} icon={null}
                                        childrenTwo={false}
                                        childrenOne={
                                            <>
                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Form/Create`)}
                                                    tooltipTitle={t("CreatingForm")}

                                                />

                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Form/RoadMap`)}
                                                    tooltipTitle={t("FormWorkflow")}
                                                    title={t("Workflow")}
                                                    icon={"pi-sync"}
                                                />
                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Form/Organize`)}
                                                    tooltipTitle={t("OrganizeForms")}
                                                    title={t("Organize")}
                                                    icon={"pi-sort-alpha-down"}
                                                />

                                                <ButtonPlus
                                                    icon={"pi-eye"}
                                                    title={t("viewing")}
                                                    tooltipTitle={t("Viewtheform")}
                                                />
                                                <ButtonPlus
                                                    icon={"pi-server"}
                                                    title={t("DataSource")}
                                                    tooltipTitle={t("DataSource")}
                                                />
                                            </>
                                        }
                            />

                        </div>
                    </div>
                    <div className={"grid grid-cols-1 lg:grid-cols-12 gap-5"}>
                        <div className={"lg:col-span-4"}>
                            <div class="post ">
                                <TabMenu activeTab={tabId} handleTabClick={handleChangeTab} tabs={tabs}  />
                                <div class="post__content tab-content max_height65 bg-white dark:bg-themeDarkSalt700 p-2">
                                    <div className={`tab-content__pane max_height65 scrollbar space-y-2 ${ tabId === "field" ? "active" : "" }`} id="field">
                                        {
                                            tabId === 'field'?
                                                <Fields
                                                    fields={fields}
                                                    addElement={addElement}
                                                    lastIndexOfElement={elements.length-1}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                    <div className={`tab-content__pane max_height65 scrollbar space-y-2 ${ tabId === "property" ? "active" : "" }`} id="property">
                                        {
                                            tabId === 'property' && selectedElementIndex !== null?
                                                <Properties
                                                    elements={elements}
                                                    fields={fields}
                                                    deleteElement={deleteElement}
                                                    handlePropertyChange={handlePropertyChange}
                                                    selectedElementIndex={selectedElementIndex}
                                                    addCheckBoxItem={addCheckBoxItem}
                                                    deleteCheckBoxItem={deleteCheckBoxItem}
                                                    handleItemPropertyChange={handleItemPropertyChange}
                                                    handleDefaultValueChange={handleDefaultValueChange}

                                                />:
                                                <NoDataHarmony/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lg:col-span-8 box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5 min-h-screen">
                            <div className="dark:bg-themeDarkPostInput harmony-form post-input post-input-design " >
                                <div class={"harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-4 harmony-gap-x-3 harmony-gap-y-4 harmony-add-form"}>
                                    <FormElements
                                        elements={elements}
                                        selectedElementIndex={selectedElementIndex}
                                        handlePropertyChange={handlePropertyChange}
                                        handleSelectTabAfterElementSelection={handleSelectTabAfterElementSelection}
                                        deleteElement={deleteElement}
                                        SortAccending={SortAccending}
                                        SortDescending={SortDescending}
                                        addCheckBoxItem={addCheckBoxItem}
                                        deleteCheckBoxItem={deleteCheckBoxItem}
                                        handleDefaultValueChange={handleDefaultValueChange}
                                    />
                                </div>
                            </div>
                        </div>


                        {/*<div class="lg:col-span-8 box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5 min-h-screen">*/}
                        {/*    <div className="dark:bg-themeDarkPostInput harmony-form" >*/}
                        {/*        <div class={"harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-4 harmony-gap-3 harmony-add-form"}>*/}
                                    {/*موبایل*/}
                                    {/*<div className="harmony-space-y-1 harmonu-col-span-1">*/}
                                    {/*    <label className="dark:text-gray-300 harmony-text-xs">*/}
                                    {/*        موبایل :*/}
                                    {/*    </label>*/}
                                    {/*    <div className={"harmony-flex"}>*/}
                                    {/*        <input type={"text"} className={"harmony-input harmony-w-full harmony-flex-1"} />*/}
                                    {/*        <input type={"text"} className={"harmony-form-x"} />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*موبایل*/}

                        {/*            /!*تک خط*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <input type={"text"} className={"harmony-input harmony-w-full"} />*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <input type={"text"} className={"harmony-input harmony-w-full"} />*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <input type={"text"} className={"harmony-input harmony-w-full"} />*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <input type={"text"} className={"harmony-input harmony-w-full"} />*/}
                        {/*            </div>*/}
                        {/*            /!*تک خط*!/*/}

                        {/*            /!*پاراگراف*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <textarea rows={5} cols={2}  className={"harmony-input harmony-w-full"} />*/}
                        {/*            </div>*/}
                        {/*            /!*پاراگراف*!/*/}

                        {/*            /!*چند گزینه ای*!/*/}
                        {/*            /!*4تایی*!/*/}
                        {/*            <div className={"harmony-space-y-3 harmony-col-span-1 harmony-md-col-span-4"}>*/}
                        {/*                <h2 className="harmony-text-xs ">  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</h2>*/}
                        {/*                <div className="harmony-grid harmony-grid-cols-2 harmony-md-grid-cols-4 harmony-gap-2">*/}
                        {/*                    <label htmlFor={"1"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"checkbox"} id={"1"} ></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"2"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"checkbox"} id={"2"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"3"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"checkbox"} id={"3"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"4"} className="harmony-flex harmony-items-center harmoy-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"checkbox"} id={"4"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*4تایی*!/*/}
                        {/*            /!*2تایی*!/*/}
                        {/*            <div className={"harmony-space-y-3 harmony-col-span-1 harmony-md-col-span-4"}>*/}
                        {/*                <h2 className="harmony-text-xs ">  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</h2>*/}
                        {/*                <div className="harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-2 harmony-gap-2">*/}
                        {/*                    <label htmlFor={"5"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"checkbox"} id={"5"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"6"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"checkbox"} id={"6"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"7"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"checkbox"} id={"7"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"8"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"checkbox"} id={"8"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*2تایی*!/*/}
                        {/*            /!*چند گزینه ای*!/*/}

                        {/*            /!*یک گزینه ای*!/*/}
                        {/*            /!*4تایی*!/*/}
                        {/*            <div className={"harmony-space-y-3 harmony-col-span-1 harmony-md-col-span-4"}>*/}
                        {/*                <h2 className="harmony-text-xs ">  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</h2>*/}
                        {/*                <div className="harmony-grid harmony-grid-cols-2 harmony-md-grid-cols-4 harmony-gap-2">*/}
                        {/*                    <label htmlFor={"60"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"radio"} id={"60"} name={"3"} className={"harmony-input"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"70"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"radio"} id={"70"} name={"3"} className={"harmony-input"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"80"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"radio"} id={"80"} name={"3"} className={"harmony-input"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"90"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"radio"} id={"90"} name={"3"} className={"harmony-input"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*4تایی*!/*/}
                        {/*            /!*2تایی*!/*/}
                        {/*            <div className={"harmony-space-y-3 harmony-col-span-1 harmony-md-col-span-4"}>*/}
                        {/*                <h2 className="harmony-text-xs ">  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</h2>*/}
                        {/*                <div className="harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-2 harmony-gap-x-2 harmony-gap-y-3">*/}
                        {/*                    <label htmlFor={"10"}  className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"radio"} id={"10"} name={"2"} className={"harmony-input"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"20"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"radio"} id={"20"} name={"2"} className={"harmony-input"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"30"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"radio"} id={"30"} name={"2"} className={"harmony-input"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                    <label htmlFor={"40"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*                        <input type={"radio"} id={"40"} name={"2"} className={"harmony-input"}></input>*/}
                        {/*                        <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*                    </label>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*2تایی*!/*/}
                        {/*            /!*یک گزینه ای*!/*/}

                        {/*            /!*انتخاب چنتایی از لیست*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    چند نفر را انتخاب کنید :*/}
                        {/*                </label>*/}
                        {/*                <select name="" id="" className={"harmony-input harmony-w-full"} multiple={true}>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                </select>*/}
                        {/*            </div>*/}
                        {/*            /!*انتخاب چنتایی از لیست*!/*/}

                        {/*            /!*انتخاب  از لیست*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                </select>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                </select>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony--md-col-span-3">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                </select>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*                    تک خط :*/}
                        {/*                </label>*/}
                        {/*                <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                    <option value="">مسعود</option>*/}
                        {/*                </select>*/}
                        {/*            </div>*/}
                        {/*            /!*انتخاب از لیست*!/*/}

                        {/*            /!*عددی*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">تعداد</label>*/}
                        {/*                <input type={"number"} min={0} max={100} className={"harmony-input harmony-w-full"} />*/}

                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">تعداد</label>*/}
                        {/*                <input type={"number"} min={0} max={100} className={"harmony-input harmony-w-full"} />*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">تعداد</label>*/}
                        {/*                <input type={"number"} min={0} max={100} className={"harmony-input harmony-w-full"} />*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">تعداد</label>*/}
                        {/*                <input type={"number"} min={0} max={100} className={"harmony-input harmony-w-full"} />*/}
                        {/*            </div>*/}
                        {/*            /!*عددی*!/*/}
                        {/*            /!*جدا کننده*!/*/}
                        {/*            <hr className={"harmony-my-2 harmony-bg-color harmony-col-span-1 harmony-md-col-span-4 harmony-h-1 "}/>*/}
                        {/*            <h4 className="harmony-px-2 harmony-border-b-2 harmony-border-color harmony-col-span-1 harmony-md-col-span-4"> آدرس</h4>*/}
                        {/*            /!*جدا کننده*!/*/}

                        {/*            /!*فایل*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs" >فایل</label>*/}
                        {/*                <input type={"file"} className={"harmony-input harmony-w-full harmony-border-gray-300 harmony-shadow"}></input>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">فایل</label>*/}
                        {/*                <div>*/}
                        {/*                    <input type={"file"} className="harmony-input harmony-w-full harmony-border-gray-300 harmony-shadow"></input>*/}
                        {/*                </div>*/}

                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">فایل</label>*/}
                        {/*                <div>*/}
                        {/*                    <input type={"file"} className={"harmony-input harmony-w-full harmony-border-gray-300 harmony-shadow"}></input>*/}
                        {/*                </div>*/}

                        {/*            </div>*/}
                        {/*            /!*فایل*!/*/}

                        {/*            /!*تاریخ*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 ">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">تاریخ</label>*/}
                        {/*                <input type={"date"} className={"harmony-input harmony-w-full"}></input>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">تاریخ</label>*/}
                        {/*                <div>*/}
                        {/*                    <input type={"date"} className="harmony-input harmony-w-full"></input>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*تاریخ*!/*/}

                        {/*            /!*ساعت*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 ">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">ساعت</label>*/}
                        {/*                <input type={"time"} className={"harmony-input harmony-w-full"}></input>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">ساعت</label>*/}
                        {/*                <div>*/}
                        {/*                    <input type={"time"} className="harmony-input harmony-w-full"></input>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*ساعت*!/*/}

                        {/*            /!*تاریخ ساعت*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 ">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">تاریخ ساعت</label>*/}
                        {/*                <input type={"time"} className={"harmony-input harmony-w-full"}></input>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">تاریخ ساعت</label>*/}
                        {/*                <div>*/}
                        {/*                    <input type={"time"} className="harmony-input harmony-w-full"></input>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*تاریخ و ساعت*!/*/}

                        {/*            /!*لینک وب سایت*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">لینک</label>*/}
                        {/*                <input type={"url"} className={"input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">لینک</label>*/}
                        {/*                <input type={"url"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">لینک</label>*/}
                        {/*                <input type={"url"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">لینک</label>*/}
                        {/*                <input type={"url"} className={"input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            /!*لینک وب سایت*!/*/}

                        {/*            /!*ایمیل*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">ایمیل</label>*/}
                        {/*                <input type={"email"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">ایمیل</label>*/}
                        {/*                <input type={"email"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">ایمیل</label>*/}
                        {/*                <input type={"email"} className={"input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">ایمیل</label>*/}
                        {/*                <input type={"email"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            /!*ایمیل*!/*/}

                                    {/*ایمیل*/}
                                    {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                                    {/*    <label  className="dark:text-gray-300 harmony-text-xs">تلفن</label>*/}
                                    {/*    <input type={"tel"} className={"harmony-input harmony-w-full"}/>*/}
                                    {/*</div>*/}
                                    {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                                    {/*    <label  className="dark:text-gray-300 harmony-text-xs">تلفن</label>*/}
                                    {/*    <input type={"tel"} className={"harmony-input harmony-w-full"}/>*/}
                                    {/*</div>*/}
                                    {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                                    {/*    <label  className="dark:text-gray-300 harmony-text-xs">تلفن</label>*/}
                                    {/*    <input type={"tel"} className={"harmony-input harmony-w-full"}/>*/}
                                    {/*</div>*/}
                                    {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                                    {/*    <label  className="dark:text-gray-300 harmony-text-xs">تلفن</label>*/}
                                    {/*    <input type={"tel"} className={"harmony-input harmony-w-full"}/>*/}
                                    {/*</div>*/}
                                    {/*ایمیل*/}


                        {/*            /!*رنج*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">رنج</label>*/}
                        {/*                <div className="">*/}
                        {/*                    <input type="range"  min="1" max="100"  className="harmony-w-full" />*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">رنج</label>*/}
                        {/*                <div className="px-2">*/}
                        {/*                    <input type="range"  min="1" max="100" className="harmony-w-full" />*/}
                        {/*                </div>*/}

                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label className="dark:text-gray-300 harmony-text-xs">رنج</label>*/}
                        {/*                <div>*/}
                        {/*                    <input type="range"  min="1" max="100" className="harmony-w-full" />*/}
                        {/*                </div>*/}

                        {/*            </div>*/}
                        {/*            /!*رنج*!/*/}

                        {/*            /!*رنگ*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">رنگ</label>*/}
                        {/*                <div>*/}
                        {/*                    <input type={"color"} className={"harmony-form-x"}/>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            /!*رنگ*!/*/}

                        {/*            /!*پسورد*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">پسورد</label>*/}
                        {/*                <input type={"password"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">پسورد</label>*/}
                        {/*                <input type={"password"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">پسورد</label>*/}
                        {/*                <input type={"password"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">پسورد</label>*/}
                        {/*                <input type={"password"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            /!*پسورد*!/*/}

                        {/*            /!*قیمت*!/*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">قیمت</label>*/}
                        {/*                <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">قیمت</label>*/}
                        {/*                <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">قیمت</label>*/}
                        {/*                <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <label  className="dark:text-gray-300 harmony-text-xs">قیمت</label>*/}
                        {/*                <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*            </div>*/}
                        {/*            /!*قیمت*!/*/}

                        {/*            /!*آدرس*!/*/}
                        {/*            <h4 className="harmony-px-2 harmony-border-b-2 harmony-border-color harmony-col-span-1 harmony-md-col-span-4  "> ادرس خود را وارد کنید</h4>*/}
                        {/*            <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*                <div className="harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-3 harmony-gap-2">*/}
                        {/*                    <div className="harmony-space-y-1">*/}
                        {/*                        <label  className="dark:text-gray-300 harmony-text-xs">کشور</label>*/}
                        {/*                        <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*                            <option value="" selected>ایران</option>*/}
                        {/*                            <option value="">امریکا</option>*/}
                        {/*                            <option value="">المان</option>*/}
                        {/*                        </select>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="harmony-space-y-1">*/}
                        {/*                        <label  className="dark:text-gray-300 harmony-text-xs">استان</label>*/}
                        {/*                        <select name="" id="" className={"input w-full"} >*/}
                        {/*                            <option value="" selected>ایران</option>*/}
                        {/*                            <option value="">امریکا</option>*/}
                        {/*                            <option value="">المان</option>*/}
                        {/*                        </select>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="harmony-space-y-1">*/}
                        {/*                        <label  className="dark:text-gray-300 harmony-text-xs">شهر</label>*/}
                        {/*                        <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*                            <option value="" selected>ایران</option>*/}
                        {/*                            <option value="">امریکا</option>*/}
                        {/*                            <option value="">المان</option>*/}
                        {/*                        </select>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*                        <label  className="dark:text-gray-300 harmony-text-xs">آدرس تکمیلی</label>*/}
                        {/*                        <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*                    </div>*/}

                        {/*                </div>*/}

                        {/*            </div>*/}
                        {/*            /!*آدرس*!/*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <ButtonSubmitAndCancel
                        onRegisterClick={()=>handleSubmitFormItem()}
                        onCancelClick={()=>redirectTo('/Form/Create')}
                    />
                </div>

            <ToastContainer/>

        </>
  )
}