import React from 'react';

const EmojiIcon = ({sizeStyle,fillStyle}) => (
    <svg viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className={`${sizeStyle ? sizeStyle:"w-4 h-4"} ${fillStyle ? fillStyle:""} dark:text-gray-300 `}>
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
        <line x1="9" y1="9" x2="9.01" y2="9"></line>
        <line x1="15" y1="9" x2="15.01" y2="9"></line>
    </svg>

);

export default EmojiIcon;
