import React, {useEffect, useMemo, useState} from "react";

import {Button} from "primereact/button";
import {MetaDataGroupSchema} from "../../../CMS/MetaData/metaDataGroupSchema";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import Swal from "sweetalert2";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import moment from "moment-jalaali";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import { handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {validate} from "../../../../Services/Globals/validate";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {InputNumber} from "primereact/inputnumber";
import {useTranslation} from "react-i18next";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {Tooltip} from "react-tooltip";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";


export const HandleAddShopMetaData = () => {
   const { t } = useTranslation();

   const [modal, setModal] = useState({ AddFeature: false, });
   const redirectTo = useNavigate();
   const [result,setResult] = useState(0);
   const [metaGroup,setMetaGroup] =  useState({id:null,order:0,groupName:null})
   const [spinner,setSpinner] = useState(false);
   const [errors, setErrors] = useState({});
   const [isEdit,setIsEdit] = useState(false);

   const handleCloseFeature=()=>{
    setMetaGroup({groupName: '',id:null,order: 0});
    setModal({AddFeature: false});
   }


   const handleValue = (e) => {
      const { name, value } = e.target;
      setMetaGroup({ ...metaGroup, [name]: value });
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: undefined,
      }));
   };

   const orderValueHandler =(data)=>{
      setMetaGroup((prevState) => ({ ...prevState, order: data }));
   }


   const onSetErrors=(errors)=>{
      setErrors(errors);
   }
   const onApiErrors = ()=>{
      setSpinner(false);
   }


   const HandleMetaGroupSubmit = async (e)=>{
      e.preventDefault();
      var isValid = validate(MetaDataGroupSchema,metaGroup,onSetErrors);
      if(!isValid){
         return;
      }
      if(isEdit){
         const {id,groupName,order} = metaGroup;
         setSpinner(true);
         const response = await PUT('/MetaDataGroup',{id: id,name:groupName,order: order},false);
         if(response.status === 200){
            handleCloseFeature();
            setIsEdit(false);
            setSpinner(false);
            setResult({...response.status});
            handleSuccessMessage(t("Featuregrouphasbeensuccessfullyedited"));
         }
      }
      else{
         try {
            setSpinner(true);
            const {groupName,order} = metaGroup;
            const response = await POST('/MetaDataGroup',{name:groupName,order:order},false);
            if(response.status === 200){
               setSpinner(false);
               setMetaGroup('');
               setResult({...response.status});
               handleCloseFeature()
               handleSuccessMessage(t("Advertisementshavebeensuccessfullyadded"))
            }
            else{
               handleApiError(response,onApiErrors)
            }
         }catch (e) {
            console.log(e)
         }
      }

   }

   const handleEditRecord= async(metaId,name,order) =>{
      try {
           //setSpinner(true);
           setIsEdit(true);
           setMetaGroup((prevState)=>({...prevState,id: metaId,groupName: name,order: order}));
           setModal({ AddFeature: true })
      }
      catch (e) {
         console.log(e);
      }
   }









   const filterValueHandler= (data,name)=>{
      setMetaSearch(({...metaSearch,[name]: data}));
   }





   const[data,setData]=useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})

   const [currentPage, setCurrentPage] = useState(1);
   const handlePreviousPage = () => {
      setCurrentPage((prevPage) => prevPage - 1);
   };
   const handleCurrentPage=(page)=>{
      setCurrentPage(page);
   }
   const handlePageSizeCount = (value)=>{
      setCurrentPage(1);

      setData(()=>({...data,pageSize: value}))
   }
   const handleNextPage = () => {
      setCurrentPage((prevPage) => prevPage + 1);
   };

   const [metaSearch,setMetaSearch] = useState({search:'',IsActive:null,IsDeActive:null});
   const [search,setSearch] = useState('');
   const handleSearch=()=>{
      setMetaSearch((prevState)=>({...prevState,search: search}));
   }

   const resetFilters=()=>{
      setMetaSearch((prevState)=>({...prevState,search:'',IsDeActive: null,IsActive: null}));
      setSearch('');
   }

   useEffect(() => {
      const loadData = async () => {
         try {
            setSpinner(true);
            const response = await OnGridReady();
            if (response && response.items !== null ) {

            } else {
               handleApiError(response,onApiErrors);
            }
         } catch (error) {
            console.error("Error in loadData:", error);
         } finally {
            setSpinner(false);
         }
      };
      loadData();
   }, [result,data.pageSize,currentPage,metaSearch.search,metaSearch.IsActive,metaSearch.IsDeActive]);

   const [rowData, setRowData] = useState([]);

   const OnGridReady = async () => {
      setSpinner(true)
      try {
         setSpinner(true);
         const response = await GET(`/MetaDataGroups`,{Search:metaSearch.search,PageSize:data.pageSize,PageNumber:currentPage,IsActive:metaSearch.IsActive,IsDeActive:metaSearch.IsDeActive},'PagedList');
         if (response && response.items !== null ) {
            setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
            var idSequence = 1;
            const modifiedItems = response.items.map((item) => ({
               ...item,
               idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
            }));
            setRowData(modifiedItems);
         }
         else {
            handleApiError(response,onApiErrors);
         }
         return response;
      } catch (error) {
         console.error(error);
      } finally {
         setSpinner(false);
      }
   }













   const columnDefs = [
      { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
      { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

      { headerName: t("TagTitle"), field: 'name',},
      { headerName: t("CreationDate"), field: 'createdOn', maxWidth: 170,cellRenderer:params => {

         return <>{moment(params.data.createdOn).format('jYYYY/jM/jD HH:mm:ss')}</>

         }
      },
      { headerName: t("Numberoffeatures"), field: 'metaDataCount', maxWidth: 150},
      { headerName: t("rank"), field: 'order', maxWidth: 140},


      { headerName: t("Status"), maxWidth: 140,cellRenderer:params => {

            return <div className={"relative"}>
               {wait && haederName === params.colDef.headerName ? (
                      <TinyLoading  />
               ) : params.data.isActive ? (
                   <HPrimeIcon
                       iconClass={'cursor-pointer text-themeSuccess'}
                       onClick={async () => await handleActiveChange(params.data.id,params.colDef.headerName)}
                       icon={'pi-check-square'}

                   />
               ) : (
                   <HPrimeIcon
                       onClick={async () => await handleActiveChange(params.data.id,params.colDef.headerName)}
                       iconClass={'cursor-pointer text-themeDanger'}
                       icon={'pi-times-circle'}
                   />
               )}
            </div>
         }
      },




      { headerName: t("operations"), maxWidth: 150,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
         cellRenderer:params => {
            return <div className={'flex justify-center items-center gap-3'}>
               <button className=" text-themeInformation dark:text-themeDarkInformation tooltip_class_SetValue" onClick={()=>redirectTo(`/Shop/MetaData/Add/AddProperty?metaId=${params.data.id}`)}>
                  <HPrimeIconSmall
                  icon={"pi-star"}
                  />
                  <Tooltip anchorSelect={".tooltip_class_SetValue"} content={t("SetValue")} positionStrategy={"fixed"}	 />
               </button>
               <GridButtonEdit
                   onClick={() =>handleEditRecord(params.data.id,params.data.name,params.data.order)}
               />
               <GridButtonDelete
                   onClick={()=>removeMetaHandler(params.data.id)}
               />
            </div>
         }
      },

   ];




   const autoGroupColumnDef = useMemo(() => {
      return {
         headerCheckboxSelection: true,
         cellRendererParams: {
            checkbox: true,
         },
      };
   }, []);


   const getRowHeight = (params) => {
      // Specify your custom logic to determine the row height based on row data or other conditions
      return 45; // Return the desired row height in pixels
   };

   const defaultColDef = useMemo(() => {
      return {
         flex: 1,
         minWidth: 90,
         resizable: true,
         // editable: true,
         filter: true,
      };
   }, []);




   const [wait,setWait] = useState(false);
const [haederName,setHeaderName] = useState('');
   const handleActiveChange=async (metaId,rowName)=>{
      setWait(true);
      setHeaderName(rowName)
      const response = await PUT('/MetaDataGroup/ChangeActivation',{Id:metaId},false);
      if(response.status === 200){
         setWait(false)
         setResult({...response.status});
         handleSuccessMessage(t("Statushasbeensuccessfullychanged"))
      }else{
         handleApiError(response,onApiErrors)
      }
   }




   const removeMetaHandler = async (metaId)=>{
      const TailWindButton = Swal.mixin({
         customClass: {
            confirmButton: AlertSetting.customClass.confirmButton,
            cancelButton: AlertSetting.customClass.cancelButton
         },
         buttonsStyling: false
      })
      TailWindButton.fire({
         title:AlertSetting.TailWindButton.title,
         text: AlertSetting.TailWindButton.text,
         icon:AlertSetting.TailWindButton.icon,
         showCancelButton:AlertSetting.TailWindButton.showCancelButton,
         confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
         cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
         reverseButtons: AlertSetting.TailWindButton.reverseButtons
      }).then( async (result) =>{
         if (result.isConfirmed) {
            const response = await DELETE(`/MetaDataGroup?Id=${metaId}`);
            if(response.status === 200){
               setResult({...response.status})
            }else{
               handleApiError(response,onApiErrors)
            }
         }
         else if (
             /* Read more about handling dismissals below */
             result.dismiss === Swal.DismissReason.cancel
         ) {
            return;
         }
      })
   }





   return(
       <>
          <div class="space-y-5 relative">
             {

                spinner?
                    <div className=" huploader_loading" >
                       <div className="relative">
                          <LoadingHarmony/>
                       </div>
                    </div>
                    :
                    null
             }
             <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                   <ListSearch label={null} icon={null} >
                      <div class="space-y-2">
                         <label className="text-xs dark:text-gray-300">{t("search")}</label>
                         <InputText type="search" onChange={(e)=>setSearch(e.target.value)} value={search} onKeyDown={(e)=>e.key ==='Enter'?setMetaSearch(()=>({...metaSearch,search: e.target.value})):null}  className="input w-full"  />
                      </div>
                      <ButtonSearchList
                          onClickSearch={()=>handleSearch()}
                          onClickReset={()=>resetFilters()}
                      />
                   </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                               <>
                                  <ButtonPlus
                                      onClick={() => setModal({ AddFeature: true })}
                                      tooltipTitle={t("CreatingAFeature")}
                                  />
                                  <ButtonPlus
                                      onClick={()=>redirectTo("/Shop/Product/Add")}
                                      icon="pi-pen-to-square"
                                      title={t("product")}
                                      tooltipTitle={t("NewProduct")}

                                  />
                                  <ButtonPlus
                                      onClick={()=>redirectTo("/Shop/Product/Group")}
                                      icon="pi-objects-column"
                                      title={t("grouping")}
                                      tooltipTitle={t("ProductCategorization")}
                                  />
                                  <ButtonPlus
                                      onClick={()=>redirectTo("/InvoiceRequest")}
                                      icon="pi-file-edit"
                                      title={t("Bill")}
                                      tooltipTitle={t("Bills")}
                                  />
                                  <ButtonPlus
                                      onClick={()=>redirectTo("/Shop/Product/List")}
                                      icon="pi-list"
                                      title={t("list")}
                                      tooltipTitle={t("ListOfProducts")}
                                  />

                                  <ButtonPlus
                                      onClick={()=>redirectTo("/Shop/Product/Cart")}
                                      icon="pi-shopping-bag"
                                      title={t("ShoppingCart")}
                                      tooltipTitle={t("ShoppingCart")}
                                  />


                               </>
                            }
                            childrenTwo={
                               <>
                                  <InputSwitch OnValueSelection={filterValueHandler} name={'IsActive'} lable={t("isActive")} />
                                  <InputSwitch OnValueSelection={filterValueHandler} name={'IsDeActive'} lable={t("isNotActive")} />
                               </>
                            }
                />

                <ToastContainer />
             </div>

             <div className="space-y-2 H-body-ag-grid " >

                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={'multiple'}
                    rowData={rowData}
                    // onCellValueChanged={(row) => onCellValueChanged(row)}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={false}
                    // onSelectionChanged={onSelectionChanged}
                    enableRtl={true}
                    paginationPageSize={data.pageSize}
                    getRowHeight={getRowHeight}
                    //onGridReady={onGridReady} // Pass onGridReady directly
                    //  quickFilterText={quickFilterText}
                    localeText={AgGridPersion}
                />
                <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
             </div>

          </div>


          <FantasyModal openModal={modal.AddFeature}  closeModal={handleCloseFeature} title={t("CreatingAFeature")}>
             <div>
                <form method={'post'} onSubmit={HandleMetaGroupSubmit} className="space-y-5">
                   <div className={"flex gap-3 items-center"}>
                      <div className="space-y-1 w-full flex-1">
                         <div className="flex justify-between items-center">
                            <label className="text-xs dark:text-gray-300">
                               {t("TagTitle")}
                            <RequiredIcon/>
                            </label>
                            <DisplayError message={errors.groupName}/>
                         </div>
                         <InputText placeholder={t("WriteYourMetaGroup")}  type="text" name={'groupName'} value={metaGroup.groupName} onInput={(e)=>handleValue(e)} className="input w-full"/>
                      </div>
                      <div className="space-y-1 w-32 flex-shrink-0">
                         <div className="flex justify-between items-center">
                            <label className="text-xs dark:text-gray-300">{t("rank")}</label>
                         </div>
                         <InputNumber className="w-full " name={'order'}  onValueChange={(e)=>orderValueHandler(e.target.value)} value={metaGroup.order} placeholder={t("selection")} mode="decimal" showButtons min={0} max={100}/>
                      </div>
                   </div>
                       <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">
                          {t("register")}
                       </button>
                </form>
             </div>
          </FantasyModal>

       </>

   )
}