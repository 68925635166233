import React, {useEffect} from "react";

import {useState} from "react";

import Swal from "sweetalert2";
import {AlertSetting} from "../../../Services/Globals/Public/AppSetting";
import {GeneralReport} from "../../Dashboard/GeneralReport";
import {ChartHarmony} from "../../Dashboard/ChartHarmony";
import {CartProduct} from "../../Dashboard/DefaultDashboards/DefaultWidgets/CartProduct";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
// this is the main dashboard to handle a bunch of components and navigate to other parts of app .
// we have menubar, topbar,datepicker,general reports ,basket,menubar for mobile , last duties or activities , and also charts ,.

export const HandleDashboard2=()=>{

    const showAlert = async ()=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: 'هشدار تاریخ و زمان',
            text: "لطفا زمان سیستم خود را بروز کنید",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'فهمیدم!',
            cancelButtonText: 'نه ',
            reverseButtons: true
        }).then( async (result) =>{
            if (result.isConfirmed) {
                return;
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const ServerTime = async()=>{
    const response = await GET('/Utility/ServerDateTimeUtc',{},'single');
    if(response.status === 200){
        const serverTime = new Date(response.data.serverDateTimeUtc);
        const clientTime = new Date();
        const difference = serverTime - clientTime;
        if(difference > 300000){
            showAlert();
        }
    }
}

useEffect(()=>{
    ServerTime();
},[])












    const options = {
        title: {
            text: '',
            subtext: '',
            left: 'right'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'horizontal',
            left: 'left'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: [
                    { value: 1048,name:'chrome'},
                    { value: 735,name:'firefox'},
                    { value: 580,name:'microsoft edge' },
                    { value: 48 ,name:'mozilla' },
                    { value: 300 ,name:'brave'}
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

   const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['75%', '60%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 30,
                        fontWeight: 'bold',
                        fontFamily:'shabnam'
                    }
                },
                labelLine: {
                    show: true
                },
                data: [
                    { value: 1048, name: 'android' },
                    { value: 735, name: 'ios' },
                    { value: 580, name: 'windows' },
                    { value: 484, name: 'mac' },
                ]
            }
        ]
    };

   const lineOption = {
       xAxis: {
           type: 'category',
           data: ['شنبه', 'یکشنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنج شنبه', '05/01']
       },
       yAxis: {
           type: 'value'
       },
       series: [
           {
               data: [820, 932, 901, 934, 1290, 1330, 1320],
               type: 'line',
               smooth: true
           }
       ]
   };



    const lineOptionmEMO  = {
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [150, 230, 224, 218, 135, 147, 260],
                type: 'line'
            }
        ]
    };

    const [calendarValue, setCalendarValue] = useState(new Date())



    return (

        <>

            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 grid grid-cols-12 gap-5 gap-y-8">
                    <div className="col-span-12">
                        <div className=" flex justify-between items-center h-10 ">
                            <h2 className="text-lg truncate ms-5 dark:text-themeDarkSalt400 ">
                                گزارش کلی
                            </h2>
                            <a href="#" className="flex items-center gap-2 text-themeInformation  dark:text-themeDarkInformation">
                                <HPrimeIcon icon="refresh-cw" />
                                بارگذاری مجدد داده ها
                            </a>
                        </div>
                        <div className="grid grid-cols-12 gap-6 mt-5 ">
                            <GeneralReport lable={'تعداد محصولات'} tempval={4.510} iconName={"shopping-cart"} temppercentage={33} className={" text-themeInformation dark:text-themeDarkInformation"} />

                            <GeneralReport lable={"شارژ پیامک"} tempval={3.521} iconName={"credit-card"} temppercentage={2} className={"text-themeInformation dark:text-themeDarkInformation"} />

                            <GeneralReport lable={"تعداد اطلاعات"} tempval={2.145} iconName={"monitor"} temppercentage={2} className={" text-themeInformation dark:text-themeDarkInformation"} />

                            <GeneralReport lable={"بازدید امروز/کاربران"} tempval={152.000} iconName={"user"} temppercentage={28} className={"text-themeInformation dark:text-themeDarkInformation"} />
                        </div>

                    </div>
                    <div className="col-span-12 lg:col-span-6 -intro-x">
                        <div className=" flex items-center h-10 gap-2 relative justify-between">
                            <h2 className="text-lg  truncate ms-5 dark:text-themeDarkSalt400">آمار بازدید
                            </h2>
                            {/*<div className={"focus:border-red-500 index_statistics"}>*/}
                            {/*    <DatePicker onChange={(e) => console.log(e.value)}  />*/}
                            {/*</div>*/}
                            <a href="#" className="text-themeInformation truncate dark:text-themeDarkInformation">(مشاهده)</a>
                        </div>

                        <div className="">
                            <div className="box bg-white dark:bg-themeDarkSalt700 zoom-in p-5 mt-5">
                                <div className="flex justify-around items-center ">
                                    <div className=" border-l border-dashed border-gray-300 flex-1 text-center">
                                        <div className="text-gray-600 text-center dark:text-gray-300">آمارماهانه</div>
                                        <div className="text-themeInformation dark:text-themeDarkInformation text-lg xl:text-xl font-bold dark:text-gray-300">$15,000</div>
                                    </div>
                                    <div className="border-l border-dashed border-gray-300 flex-1  text-center">
                                        <div className="text-gray-600 text-center dark:text-gray-300">آمار سه ماه</div>
                                        <div className="text-gray-600 text-lg xl:text-xl  dark:text-gray-300">$10,000</div>
                                    </div>
                                    <div className="flex-1 text-center ">
                                        <div className="text-gray-600 text-center dark:text-gray-300 ">آمار شش ماه</div>
                                        <div className="text-gray-600 text-lg xl:text-xl  dark:text-gray-300">$10,000</div>
                                    </div>
                                </div>
                                <ChartHarmony options={lineOption} apiUrl={''} className={''} style={{ width: '100%', height: '350px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 lg:col-span-3">
                        <div className="-intro-x flex items-center justify-between h-10">
                            <h2 className="text-lg  truncate ms-5 dark:text-themeDarkSalt400">
                                مرورگرهای متصل
                            </h2>
                            <a href="#" className="text-themeInformation truncate dark:text-themeDarkInformation">(مشاهده)</a>
                        </div>

                        <div className="" >
                            <div className="box bg-white dark:bg-themeDarkSalt700 p-5 mt-5 zoom-in ">
                                <ChartHarmony options={options} apiUrl={''} className={''} style={{ width: '100%', height: '400px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-6 lg:col-span-3 ">
                        <div className="-intro-x flex items-center justify-between h-10">
                            <h2 className="text-lg font-medium truncate ms-5 dark:text-themeDarkSalt400">
                                دستگاه متصل
                            </h2>
                            <a href="" className=" text-themeInformation truncate dark:text-themeDarkInformation"> (مشاهده)</a>
                        </div>
                        <div className="">
                            <div className="box bg-white dark:bg-themeDarkSalt700 zoom-in p-5 mt-5 ">
                                <ChartHarmony options={option} apiUrl={''} className={''} style={{ width: '100%', height: '400px' }} />
                            </div>
                        </div>
                    </div>

                </div>
                <CartProduct/>
            </div>
        </>

    )
}