import React from "react";

export function handleConvertAddGuidance(help) {
    const data = {
        title: help.title ?? null,
        slug: help.slug ?? null,
        content: help.content ?? null,
        description: help.description ?? null,
        keywords: help.keywords ?? null,
        image:help.image ?? null,
        video:help.video ?? null,
        document:help.document ?? null,
        documentUrl:help.documentUrl??null,
        videoUrl:help.videoUrl??null
    }
    return data;
}
export function handleConvertEditGuidance(help) {
    const data = {
        harmonyHelpId: help.harmonyHelpId ?? null,
        title: help.title ?? null,
        slug: help.slug ?? null,
        content: help.content ?? null,
        description: help.description ?? null,
        keywords: help.keywords ?? null,
        image:help.image ?? null,
        video:help.video ?? null,
        document:help.document ?? null,
        documentUrl:help.documentUrl??null,
        imagePath:help.imagePath,
        videoUrl:help.videoUrl??null
    }
    return data;
}

export const handleCreateFormDataGuidance=(obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Title',obj.title);
    appendIfNotNull('Slug',obj.slug);
    appendIfNotNull('Description',obj.description);
    appendIfNotNull('Content',obj.content);
    appendIfNotNull('VideoUrl',obj.videoUrl);
    appendIfNotNull('DocumentUrl',obj.documentUrl);
    if (obj.image) {
        formData.append('Image', obj.image);
    }

    if (obj.video) {
        formData.append('Video', obj.video);
    }

    if (obj.document) {
        formData.append('Document', obj.document);
    }

    if (obj.keywords) {
        obj.keywords.forEach((keyword, index) => {
            appendIfNotNull(`keywords[${index}]`,keyword);
        });
    }
    return formData
}
export const handleEditFormDataGuidance=(obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('HarmonyHelpId',obj.harmonyHelpId);
    appendIfNotNull('Title',obj.title);
    appendIfNotNull('Slug',obj.slug);
    appendIfNotNull('Description',obj.description);
    appendIfNotNull('Content',obj.content);
    appendIfNotNull('VideoUrl',obj.videoUrl);
    appendIfNotNull('DocumentUrl',obj.documentUrl);
    appendIfNotNull('Image',obj.image);
    appendIfNotNull('ImagePath',obj.imagePath);
    if (obj.keywords) {
        obj.keywords.forEach((keyword, index) => {
            appendIfNotNull(`keywords[${index}]`,keyword);
        });
    }
    appendIfNotNull(`Video`,obj.video)
    appendIfNotNull(`Document`,obj.document)
    return formData
}
