import React from "react";
import Select from "react-select";
import {useTranslation} from "react-i18next";

export const ProfileCommunication=()=>{

    const { t } = useTranslation();

    return (<>

        <div class=" p-1 space-y-3" >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{ t("CommunicationType")}:</label>
                    <Select placeholder={t("selection")}/>
                </div>
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("TypeIndividualLegal")}</label>
                    <Select placeholder={t("selection")}/>
                </div>
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("ContactAddress")}:</label>
                    <input type="text" className="input w-full" />
                </div>
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("description")}:</label>
                    <input type="text" className="input w-full"/>
                </div>
            </div>
            <div >
                <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation w-full text-white ">{t("creation")}</button>
            </div>
            <div className={"flex items-center gap-3 justify-end"}>
                <button type={'submit'} className="button bg-themeDanger w-24 text-white ">{t("trash")}</button>
                <button type={'submit'} className="button bg-themeSuccess w-24 text-white ">{t("edit")}</button>
            </div>
        </div>

    </>)
}