import React, {useEffect} from "react";

import { InputText } from "primereact/inputtext";
import { useState } from "react";

import { TabMenu } from "../../../Commans/UIParts/TabMenu";
import {  ToastContainer } from "react-toastify";
import {useNavigate} from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import queryString from "query-string";



import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {useTranslation} from "react-i18next";

import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {validate} from "../../../../Services/Globals/validate";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {useSetting} from "../../../Commans/Hooks/useSetting";
// import 'primereact/dialog/dialog.min.css';
import {useCustomTab} from "./useCustomTab";
import {useHelp} from "./useHelp";
import {helpSchema} from "./helpSchema";
import {ContentTab} from "./ContentTab";
import {ToggleSwitch} from "../../../Commans/UIParts/ToggleSwitch";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {
  handleConvertAddGuidance,
  handleConvertEditGuidance,
  handleCreateFormDataGuidance,
  handleEditFormDataGuidance
} from "./helpService";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";


export const HandleGuideProject = () => {
  const queryParams = queryString.parse(window.location.search);
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const {setting,handleSetting,handleSettingClose,IsSettingOpen}= useSetting();
  const [IsShowSpinner,setIsShowSpinner] = useState(false);
  const [errors,setErrors] = useState({});
  const [initialSource,setInitialSource] = useState({});
  const [IsEdit,setIsEdit] = useState(false);
  const [IsOpen,setIsOpen] = useState(false);
  const [refresh,setRefresh] = useState(false);
  const {handleKeywordsChange,handleValue,handleSelectValue,help,setHelp,handleChangeContent,handleResetImages,handleRemoveImagePath,handleChangeImage,handleChangeToggle,handleFileChange,displayFile}=useHelp(setErrors)



  const handleIsOpen=()=>{
    setIsOpen(true);

  }
  const handleCloseIsOpen=()=>{
    setIsOpen(false);
  }

  const Refresh=()=>{
    setRefresh(prevState => !prevState);
  }
  const onSetErrors=(errors)=>{
    setErrors(errors)
  }
  const showSpinner=()=>{
    setIsShowSpinner(true)
  }
  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }

  const fetchData=async()=>{
     try {

       setHelp({isInternal: true,keywords: [],imagePath: null,videoUrl: '',documentUrl: '',image: null});
       if(queryParams.harmonyHelpId){
         showSpinner();
         setIsEdit(true);
         try {
           const response = await GET(`/HarmonyHelp/${queryParams.harmonyHelpId}`,'single');
           if(response.status === 200){

             let isInternal = false;
             if (response.data.video !== null || response.data.document !== null) {
               isInternal = true;
             } else if (response.data.videoUrl !== null || response.data.documentUrl !== null) {
               isInternal = false;
             }
             setHelp({
               content: response.data.content,
               description: response.data.description,
               title: response.data.title,
               harmonyHelpId: response.data.harmonyHelpId,
               image: response.data.image,
               videoUrl: response.data.videoUrl,
               documentUrl: response.data.documentUrl,
               video: response.data.video,
               document: response.data.document,
               keywords: response.data.keyWords??[],
               slug: response.data.slug,
               isInternal: isInternal
             })
             hideSpinner();
           }
           else{
             handleApiError(response,hideSpinner);
           }
         }
         catch (e) {
           hideSpinner();
         }
       }

     }
     catch (e) {
       hideSpinner();
     }
  }

  useEffect(()=>{
    fetchData()
  },[])

  const handleSubmitHelp=async (e)=>{
    e.preventDefault();
    const isValid = validate(helpSchema,help,onSetErrors);
    console.log(isValid)
    if(!isValid){
      return;
    }
    try {
      if(IsEdit){
        showSpinner();
        const helpData = handleConvertEditGuidance(help);
        const formData = handleEditFormDataGuidance(helpData);
        const response = await POST(`/HarmonyHelp`,formData,true);
        if(response.status === 200){
          redirectTo('/Admin/Guide/List');
          hideSpinner();
        }
        else{
          handleApiError(response,hideSpinner);
        }
      }
      else{
         showSpinner();
         const helpData = handleConvertAddGuidance(help);
         const formData = handleCreateFormDataGuidance(helpData);
         const response = await POST(`/HarmonyHelp`,formData,true);
         if(response.status === 200){
              redirectTo('/Admin/Guide/List');
              hideSpinner();
         }
         else{
           handleApiError(response,hideSpinner);
         }
      }

    }
    catch (e) {
      hideSpinner();
    }
  }

const handleShowPlayVideo=(propName)=>{
    return (<>
      {
        IsEdit && help[propName] !== null && typeof  help[propName] !== 'object'?
            <button onClick={()=>displayFile(help[propName])}  type={'button'}>
              <HPrimeIcon
              icon={"pi-play-circle"}
              />
            </button>
            :null
      }
    </>)
}

  return (
      <>

        <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
          <ListFilter label={null} icon={null}
                      showChildrenTwo={false}
                      childrenOne={
                        <>
                          <ButtonPlus
                              onClick={()=>redirectTo(`/Admin/Guide/List`)}
                              icon={"pi-list"}
                              title={t("list")}
                              tooltipTitle={t("ListGuide")}
                          />

                        </>
                      }
          />

        </div>
        <form onSubmit={handleSubmitHelp} method={'post'} className={"relative"} >
          {

            IsShowSpinner?
                <div className="huploader_loading" >
                  <div className="relative">
                    <LoadingHarmony/>
                  </div>
                </div>
                :
                null
          }
          <div className=" space-y-5">
            <div className="grid grid-cols-12 gap-5">
              <div className="  col-span-12 lg:col-span-8">
                <div className="grid grid-cols-1 gap-2 md:grid-cols-2 ">
                  <div>
                    <div className={"float-input p-float-label relative"}>
                      <InputText onInput={handleValue}  name={'title'} value={help.title}  type="text" className="input w-full input-float-label"/>
                      <label className={"text-xs dark:text-gray-300"}>
                        {t("Title")}
                      <RequiredIcon/>
                      </label>
                      <div className={"absolute end-2 -top-2"}>
                        <DisplayError message={errors.title}/>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={"float-input p-float-label relative"}>
                    <InputText onInput={handleValue}  name={'slug'} value={help.slug}  type="text" className="input w-full input-float-label" />
                      <label className={"text-xs dark:text-gray-300"}>
                        {t("Address")}
                        <RequiredIcon/>
                      </label>
                      <div className={"absolute end-2 -top-2"}>
                        <DisplayError message={errors.slug}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5  space-y-3">
                  <div class={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                    <h2 className={"dark:text-gray-300 text-xs"}>  {t("Contents")}</h2>
                  </div>
                  <div class={"px-2 box bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine p-2"}>
                    <ContentTab handleSetting={handleSetting}
                                handleValue={handleValue}
                                help={help}
                                handleChangeContent={handleChangeContent}
                    />
                  </div>

                </div>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <div className="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                  <div>
                    <div className="space-y-1">
                      <div className="w-full h-48 ">
                        <UploadImage isEdit={IsEdit} hpBgPosition={"hpcontain"}  removeFile={handleRemoveImagePath} removeName={'imagePath'} accept={'image/*'} resetImages={handleResetImages} OnValueSelection={handleChangeImage} value={help.image} name={'image'} />
                      </div>
                    </div>
                  </div>
                  <div className={"flex items-center gap-3"}>
                    <div className="space-y-2 flex-1">
                      <div className={"flex gap-2 items-center"}>
                        <label className="text-xs dark:text-gray-300">
                          {t('grouping')}
                        </label>
                        <span onClick={()=>handleIsOpen()} className={"cursor-pointer text-xs text-themeInformation dark:text-themeDarkInformation"}>
                         ({(t('GroupCreation'))})
                        </span>
                      </div>
                      <div>
                        <Select
                            options={initialSource.documentGroups}
                            isMulti={null}
                            isClearable={false}
                            isSearchable={true}
                            name={'documentGroupId'}
                            placeholder={t("selection")}
                            value={document.documentGroupId}
                            classNamePrefix={"h_multi_select"}
                        />

                      </div>
                    </div>
                    <div className="space-y-1 w-20 flex-shrink-0">
                      <label className="dark:text-gray-300 text-xs">{t("Order")}</label>
                      <InputNumber
                          type={"text"} name={"order"}
                          value={document.order}
                          // onChange={(e) => handleNumberValue(e.value,'order')}
                          showButtons
                          min={0}
                      />
                    </div>
                  </div>
                  <div>
                    <ToggleSwitch tag={'swich'} customClass={"w-32"} value={help.isInternal} handleToggleSwitchValue={handleChangeToggle} name={'isInternal'} checked={t("Internal")} unchecked={t("External")} />
                  </div>

                  {
                    help.isInternal?
                        <div className={"space-y-3"}>
                          <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2">
                            <div className="space-y-1 ">
                              {
                                handleShowPlayVideo('video')
                              }
                              <label className="text-xs dark:text-gray-300">{t("Video")}</label>
                              <input type="file"  onChange={(e)=>handleFileChange(e,'video')} className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                            </div>
                          </div>
                          <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2">
                            <div className="space-y-1">
                              {
                                handleShowPlayVideo('document')
                              }
                              <label className="text-xs dark:text-gray-300">{t("Documentation")}</label>
                              <input type="file"  onChange={(e)=>handleFileChange(e,'document')}  className="block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                            </div>
                          </div>
                        </div>
                        :
                        <div className="space-y-3">
                          <div className="space-y-1 post-input dark:bg-themeDarkPostInput post-input-design p-2">
                            <label className="text-xs dark:text-gray-300">
                              {t("TrainingVideoLink")}
                            </label>
                            <div>
                              <InputText
                                  value={help.videoUrl}
                                  name={'videoUrl'}
                                  onInput={(e)=>handleValue(e)}
                              />

                            </div>
                          </div>
                          <div className="space-y-1 post-input dark:bg-themeDarkPostInput post-input-design p-2">
                            <label className="text-xs dark:text-gray-300">
                              {t("AuxiliaryLink")}
                            </label>
                            <div>
                              <InputText
                                  value={help.documentUrl}
                                  name={'documentUrl'}
                                  onInput={(e)=>handleValue(e)}
                              />
                            </div>
                          </div>

                        </div>

                  }

                  <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                    <label className="dark:text-gray-300 text-xs"> {t("Keywords")}</label>
                    <TagsInput value={help.keywords} onChange={handleKeywordsChange}
                               name={"seo_Keywords"} placeHolder={t("Keywords")}/>
                  </div>
                </div>
              </div>
            </div>

            <ButtonSubmitAndCancel
                onCancelClick={() => redirectTo('/Admin/Guide/List')}
            />
          </div>
        </form>

        <FantasyModal openModal={IsOpen} closeModal={handleCloseIsOpen} title={t("GroupCreation")}>
          <form method={"post"} className={"relative space-y-5"}>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="space-y-2">
                <div className={"flex justify-between items-center"}>
                  <label className="dark:text-gray-300 text-xs">
                    {t("Title")}
                    <RequiredIcon/>
                  </label>
                  {/*<DisplayError message={GroupErrors.title} />*/}
                </div>
                <InputText type={"text"}  name={"title"}
                           className={" input w-full"}
                />

              </div>
              <div className="space-y-1">
                <label className="dark:text-gray-300 text-xs">{t("Order")}</label>
                <InputNumber
                    type={"text"} name={"order"}

                    showButtons
                    min={0}
                />

              </div>
              <div className="space-y-1 col-span-2">
                <label className="dark:text-gray-300 text-xs">{t("description")}</label>
                <InputText type={"text"} name={"description"}
                           className={" input w-full"} />
              </div>
            </div>
            <div>
              <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}>{t("insertion")}</button>
            </div>
          </form>
        </FantasyModal>
        <ToastContainer />
      </>
  );
};
