import React, { useMemo, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";

import { FantasyModal } from "../../../Commans/UIParts/FantasyModal";
import { GridTable } from "../../../Commans/Grid/GridTable";
import { useTranslation } from "react-i18next";
import { ListSearch } from "../../../Commans/Grid/ListSearch";
import { ListFilter } from "../../../Commans/Grid/ListFilter";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Export } from "../../../Commans/Grid/Export";
import { ButtonPlus } from "../../../Commans/UIParts/ButtonPlus";
import { useSetting } from "../../../Commans/Hooks/useSetting";
import { HandleGeneralSetting } from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import { HandlePicturesSettings } from "../../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import { ButtonSearchList } from "../../../Commans/UIParts/ButtonSearchList";
import { InputTextarea } from "primereact/inputtextarea";
import { GridSetting } from "../../../../Services/Globals/Public/AppSetting";
import { useUserFamilyGrid } from "./useUserFamilyGrid";
import { GET, POST, PUT } from "../../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import { handleSuccessMessage } from "../../../../Services/Globals/Errors/NotificationService";
import { Pagination } from "../../../Commans/Grid/Pagination";
import { AgGridEnglish } from "../../../../Services/Globals/Translations/Resources";
import { useUserFamily } from "./useUserFamily";
import { convertFamilyData, convertFamilyPutData } from "./userFamilyServices";
import { convertSelectDataHandler } from "../../../CMS/Contents/ContentService";
import { LoadingHarmony } from "../../../Commans/Loadings/LoadingHarmony";
import { ModalSubmitButton } from "../../../Commans/UIParts/ModalSubmitButton";
import { ToastContainer } from "react-toastify";
import { InputSwitchListFilter } from "../../../Commans/Filters/InputSwitchListFilter";


export const HandleUserFamily = () => {
  const { t } = useTranslation();
  const [IsShowModalInformation, setIsShowModalInformation] = useState(false);
  const [IsShowSpinner, setIsShowSpinner] = useState(false);
  const { setting, handleSetting, handleSettingClose, IsSettingOpen } = useSetting();
  const [initialSource, setInitialSource] = useState({ insuranceRelations: [], genderTypes: [] }); // and ...
  const [IsBtnLoading, setIsBtnLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { insuranceId, fullName } = useParams();


  const { userFamilyInfo, setUserFamilyInfo, handleSelectValue, handleValue } = useUserFamily();

  // hide and show btn loading
  const showBtnLoading = () => setIsBtnLoading(true)
  const hideBtnLoading = () => setIsBtnLoading(false)

  const Refresh = () => {
    setRefresh((prevstate) => !prevstate);
  }

  // show and hide modal
  const showFamilyInformation = () => {
    setIsShowModalInformation(true);
    setUserFamilyInfo(
      {
        ...userFamilyInfo,
        insuranceId: insuranceId,
        insuranceRelation: null,
        genderType: null,
        fullName: '',
        nationalCode: '',
        mobilePhoneNumber: '',
        homePhoneNumber: '',
        description: ''
      }
    );
  }
  const hideFamilyInformation = () => {
    setIsShowModalInformation(false);
    setIsEdit(false);
    setUserFamilyInfo(
      {
        insuranceId: null,
        insuranceRelation: null,
        genderType: null,
        fullName: '',
        nationalCode: '',
        mobilePhoneNumber: '',
        homePhoneNumber: '',
        description: ''
      }
    );
  }


  // hide and show spinner
  const showSpinner = () => {
    setIsShowSpinner(true);
  }
  const hideSpinner = () => {
    setIsShowSpinner(false);
  }


  // get data selection and set in the InitialSource state
  const handleInitialDataSource = (insuranceRelation, genderTypes) => {
    const genderTypesData = convertSelectDataHandler(genderTypes);
    const insuranceRelationData = convertSelectDataHandler(insuranceRelation);
    setInitialSource({ insuranceRelations: insuranceRelationData, genderTypes: genderTypesData });
  }
  const fetchData = async () => {
    try {
      showSpinner();

      const [insuranceRelationResponse, genderTypesResponse] = await Promise.all([
        GET(`/Enumerations/EnInsuranceRelation`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnGenderType`, {}, 'IEnumerable')
      ]);
      if (insuranceRelationResponse.status === 200 && genderTypesResponse.status === 200) {
        handleInitialDataSource(insuranceRelationResponse.data, genderTypesResponse.data);
        hideSpinner();
      } else {
        handleApiError(insuranceRelationResponse, hideBtnLoading);
        handleApiError(genderTypesResponse, hideBtnLoading);
      }
    } catch {
      hideSpinner();
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  // post data
  const handleSubmitUserFamily = async (e) => {
    e.preventDefault();
    showBtnLoading();
    try {
      if (isEdit) {
        const data = convertFamilyPutData(userFamilyInfo);
        const res = await PUT('/Users/Insurance/Member', data, false);
        if (res.status === 200) {
          hideBtnLoading();
          hideFamilyInformation();
          Refresh();
          handleSuccessMessage(t('SuccessDone'))
        } else {
          handleApiError(res, hideBtnLoading);
        }
      } else {
        const data = convertFamilyData(userFamilyInfo);
        const res = await POST('/Users/Insurance/Member', data, false);
        if (res.status === 200) {
          hideBtnLoading();
          hideFamilyInformation();
          Refresh();
          handleSuccessMessage(t('SuccessDone'))
        } else {
          handleApiError(res, hideBtnLoading);
        }
      }
    } catch {
      hideSpinner();
    }
  }

  const {
    columnDefs,
    defaultColDef,
    getRowHeight,
    autoGroupColumnDef,
    rowData,
    pagination,
    handlePreviousPage,
    handleNextPage,
    handleCurrentPage,
    handlePageSizeCount,
    filter,
    setFilter,
    handleSearch,
    handleResetFilters,
    switchData,
    handleSwitchFilters } = useUserFamilyGrid(showSpinner, hideSpinner, userFamilyInfo, setUserFamilyInfo, refresh, Refresh, initialSource, setIsEdit, setIsShowModalInformation);


  return (
    <>

      <div className="relative space-y-5">
        {
          IsShowSpinner ?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony />
              </div>
            </div>
            :
            null
        }
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
          <ListSearch label={null} icon={null} >
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("search")}</label>
              <InputText onChange={(e) => setFilter(() => ({ ...filter, searchValue: e.target.value }))} value={filter.searchValue} onKeyDown={(e) => e.key === 'Enter' ? setFilter(() => ({ ...filter, search: e.target.value })) : null} type={"search"} className=" input w-full" />
            </div>
            <ButtonSearchList
              onClickReset={() => handleResetFilters()}
              onClickSearch={() => handleSearch()}
            />
          </ListSearch>
          <ListFilter label={null} icon={null}
            childrenOne={
              <>
                <ButtonPlus
                  onClick={() => showFamilyInformation()}
                  tooltipTitle={t("FamilyInformation")}
                />

              </>
            }
            childrenTwo={
              <>
                <InputSwitchListFilter switchData={switchData} OnValueSelection={handleSwitchFilters}  />
              </>
            }
          />


        </div>
        <div className={"box bg-white dark:bg-themeDarkSalt700 p-2"}>
          <div className={"post-input post-input-design p-2 flex items-center gap-2 dark:bg-themeDarkPostInput"}>
            <div className="w-12 h-12 bg-white dark:bg-themeDarkSalt700 flex-shrink-0 rounded-full overflow-hidden">
              <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="w-full h-full object-cover" />
            </div>
            <div className={"flex gap-3 items-center flex-1 w-full flex-wrap"}>
              <div className={"flex gap-1 items-center"}>
                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Name")}:</h3>
                <h2 className="line-clamp-1 text-xs dark:text-gray-300">
                  {fullName}
                </h2>
              </div>

            </div>

          </div>
        </div>
        <div className={"H-body-ag-grid space-y-2"}>
          <GridTable
            columnDefs={columnDefs}
            rowSelection={GridSetting.rowSelection}
            rowData={rowData}
            style={GridSetting.gridStyle}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={GridSetting.pagination}
            enableRtl={GridSetting.enableRtl}
            domLayout={GridSetting.domLayout}
            paginationPageSize={GridSetting.pageSize}
            getRowHeight={getRowHeight}
            localeText={AgGridEnglish}
          />
          <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount} />
        </div>
        <Export />
      </div>

      <FantasyModal openModal={IsShowModalInformation} closeModal={hideFamilyInformation} title={t("FamilyInformation")} >
        <form onSubmit={handleSubmitUserFamily} method="post" >
          <div className={"space-y-5"}>
            <div className={"flex gap-2 items-center"}>
              <div className="space-y-1 w-36 flex-shrink-0">
                <label className="text-xs dark:text-gray-300">{t("userType")}</label>
                <Select
                  options={initialSource.insuranceRelations}
                  placeholder={t("selection")}
                  classNamePrefix={"h_multi_select"}
                  menuPosition={"fixed"}
                  value={userFamilyInfo.insuranceRelation}
                  onChange={((data) => handleSelectValue(data, 'insuranceRelation'))}
                />
              </div>
              <div className={"flex w-full gap-2 items-center"}>
                <div className="space-y-1 w-32 flex-shrink-0">
                  <label className="text-xs dark:text-gray-300">{t("MrMiss")}</label>
                  <Select
                    options={initialSource.genderTypes}
                    placeholder={t("selection")}
                    classNamePrefix={"h_multi_select"}
                    menuPosition={"fixed"}
                    value={userFamilyInfo.genderType}
                    onChange={((data) => handleSelectValue(data, 'genderType'))}
                  />
                </div>
                <div className="space-y-1 flex-1 w-full">
                  <label className="text-xs dark:text-gray-300">{t("FirstNameAndLastName")}</label>
                  <InputText type="text" className=" input"
                    name="fullName"
                    value={userFamilyInfo.fullName}
                    onInput={(e) => handleValue(e)} />
                </div>
              </div>

            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 "}>
              <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("NationalIdNumber")}</label>
                <InputText type="text" className=" input"
                  name="nationalCode"
                  value={userFamilyInfo.nationalCode}
                  onInput={(e) => handleValue(e)} />
              </div>
              <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("MobilePhone")}</label>
                <InputText type="text" className=" input"
                  name="mobilePhoneNumber"
                  value={userFamilyInfo.mobilePhoneNumber}
                  onInput={(e) => handleValue(e)} />
              </div>
              <div className="space-y-1">
                <label className="text-xs dark:text-gray-300" >{t("LandlinePhone")}</label>
                <InputText type="text" className=" input"
                  name="homePhoneNumber"
                  value={userFamilyInfo.homePhoneNumber}
                  onInput={(e) => handleValue(e)} />
              </div>

            </div>
            <div className="">
              <InputTextarea
                className={"input "}
                placeholder={"توضیحات"}
                name="description"
                value={userFamilyInfo.description}
                onInput={(e) => handleValue(e)}
              />

            </div>

            <div>
              <ModalSubmitButton
                IsBtnLoading={IsBtnLoading}
              />
            </div>
          </div>
        </form>
      </FantasyModal>

      <FantasyModal openModal={IsSettingOpen} closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
        <div>
          {
            setting === 'GeneralSetting' ?
              <HandleGeneralSetting /> :
              setting === 'waterMark' ?
                <HandlePicturesSettings /> : null
          }
        </div>
      </FantasyModal>

      <ToastContainer />
    </>

  )
}