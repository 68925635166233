import React from "react";
import {Link} from "react-router-dom";

import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const LatestUsers=({key,userId,fullName,companyName,userAvatar})=>{
    const { t } = useTranslation();
    return (<>
        <div class="box dark:bg-themeDarkSalt700 bg-white relative flex items-center p-3 gap-3 -intro-x">
            <div class="size-12 flex-shrink-0 bg-white dark:bg-themeDarkSalt700 border shadow rounded-full">
                <img alt="harmony" className="size-full object-cover" src={userAvatar}/>
            </div>
            <div class="flex-1 flex justify-between">
                <div>
                    <div>
                        <p className="dark:text-gray-300">{fullName} <span className={"text-xs"}>{companyName?({companyName}):null}</span></p>
                    </div>
                    <div class="text-xs line-clamp-1 text-gray-500">
                        <div class="text-xs dark:text-gray-300 font-light">
                            کاربر سایت
                        </div>
                    </div>
                </div>
                <div className={"flex gap-1 items-center"}>
                    <Link to={"#"} className={"tooltip_class_generalManager"}>
                        <HPrimeIcon
                        icon={"pi-users"}
                        iconClass={"dark:text-themeDarkInformation"}
                        />
                        <Tooltip anchorSelect={".tooltip_class_generalManager"} content={t("generalManager")} />
                    </Link>
                    <Link to={`/Support/OnlineSupport?userId=${userId}`} className={"tooltip_class_Conversations"}>
                        <HPrimeIcon
                            icon={"pi-comment"}
                            iconClass={"dark:text-themeDarkInformation"}
                        />
                        <Tooltip anchorSelect={".tooltip_class_Conversations"} content={t("Conversations")} />
                    </Link>
                    <Link to={`/Support/Ticket/List?userId=${userId}`} className={"tooltip_class_Tickets"}>
                        <HPrimeIcon
                            icon={"pi-ticket"}
                            iconClass={"dark:text-themeDarkInformation"}
                        />
                        <Tooltip anchorSelect={".tooltip_class_Tickets"} content={t("Tickets")} />
                    </Link>
                </div>
            </div>
        </div>

    </>)
}