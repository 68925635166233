import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import React, {useState} from "react";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {FileManager} from "../../../../Public/FtpManager/FileManager";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";
export const FileUploadFields = ({ index, element, handlePropertyChange, t })=>{
    const [IsFtpOpen,setOpenFtp]=useState(false);
    const handleOpenFtp=()=>{
        setOpenFtp(true)
    }
    const handleCloseFtp=()=>{
        setOpenFtp(false);
    }
    const handleGetFolderSource=(path)=>{
        handlePropertyChange(index, 'folderSource', path);
        handleCloseFtp();
    }

        return (
        <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("FileExtension")}</label>
                    <InputText
                        type="text"
                        value={element.properties.extensions}
                        onChange={(e) => handlePropertyChange(index, 'extensions', e.target.value)}
                        className={"input w-full"}
                    />
                </div>
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("FolderAddress")}</label>
                    <div className={"flex gap-1 items-center"}>
                        <button type={"button"} className={"button small_button bg-themeInformation flex-shrink-0"} onClick={()=>handleOpenFtp()}>
                            <HPrimeIconSmall
                                icon={"pi-folder-open"}
                                iconClass={'text-white'}
                            />
                        </button>
                        <InputText
                            type="text"
                            value={element.properties.folderSource}
                            onChange={(e) => handlePropertyChange(index, 'folderSource', e.target.value)}
                            className={"input H-direction-ltr w-full"}
                        />
                    </div>

                </div>
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>
                    <InputNumber
                        type="text"
                        min={0}
                        value={element.properties.min}
                        onChange={(e) => handlePropertyChange(index, 'min', e.value)}
                    />
                </div>
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>
                    <InputNumber
                        type="text"
                        min={0}
                        value={element.properties.max}
                        onChange={(e) => handlePropertyChange(index, 'max', e.value)}
                    />
                </div>
            </div>

            <FantasyModal openModal={IsFtpOpen} closeModal={handleCloseFtp} customeClassName={'modal-xl'} class title={t("GroupCreation")}>
                <div className={"relative"}>
                    {
                        IsFtpOpen?
                            <FileManager
                                handleGetFolderSource={handleGetFolderSource}
                                inUse={true}
                                handleCloseFtp={handleCloseFtp}
                            />:null
                    }
                </div>

            </FantasyModal>

        </>
    )
}

