import React, {useState} from "react";

export const useCommunication=(options)=>{
    const [communication,setCommunication]=useState([]);
    const handleAddCommunication = () => {
        const newRow = {
            fakeId:new Date().getTime(),
            communicationId: options.types[0], // Use the default value from options
            communicationMode: options.modes[0], // Use the default value from options
            communicationValue: null,
            description: null
        };
        setCommunication(prevList => [...prevList, newRow]);
    };
    const handleRemoveCommunication = (fakeId,id) => {
        if(communication.length === 1){
            const newRow = {
                fakeId:new Date().getTime(),
                communicationId: options.types[0], // Use the default value from options
                communicationMode: options.modes[0], // Use the default value from options
                communicationValue: null,
                description: null
            };
            setCommunication(prevList => [newRow]);
            return;
        }
        setCommunication((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.fakeId === fakeId && row.id === id
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };
    const handleCommunicationChange = (fakeId, field, value, id) => {
        setCommunication((preCommunication) => {
            const updatedCommunication = preCommunication.map((communication) => {
                if (
                    (id && communication.id === id) || (!id && fakeId && communication.fakeId === fakeId)
                ) {
                    return { ...communication, [field]: value };
                } else {
                    return communication; // Keep other rows unchanged
                }
            });
            return updatedCommunication;
        });
    };



    return {
        communication,
        setCommunication,
        handleCommunicationChange,
        handleRemoveCommunication,
        handleAddCommunication
    }
}