import React, {useState} from "react";

export const useGroupDetail=(setErrors)=>{
    const [productGroupDetail,setProductGroupDetail] = useState({
        id:null,
        title: null,
        slug: null,
        excerpt: null,
        editor: null,
        seoTitle: null,
        seoKeywords: [],
        seoDescription:null,
        image:null,
        banner:null,
        icon:null,
        stateShow:false,
        type:null,
        parentId:null,
        order:0,
        imagePath:null,
        bannerPath:null,
        iconPath:null,
        stateGoogle:false
    });

    const handleValue = (e) => {
        const { name, value } = e.target;
        setProductGroupDetail((prevContent) => ({ ...prevContent, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }

    const handleRemoveAttach=(path,name)=>setProductGroupDetail((prevState)=>({...prevState,[name]:path}))

    const handleResetAttach = () => {
        setProductGroupDetail((prevState)=>({...prevState,image:null,imagePath: null,icon: null,iconPath: null,banner: null,bannerPath: null}));
    }
    const handleChangeAttach =(data,name)=>{
        setProductGroupDetail((prevContent) => ({ ...prevContent, [name]: data }));
    }
    const handleSelectValue =(data,name)=>{
        setProductGroupDetail((prevState) => ({ ...prevState, [name]: data }));
    }
    const handleChangeKeywords = (keywords) => {
        setProductGroupDetail((prevContent) => ({ ...prevContent, seoKeywords: keywords }));
    };


return {
    productGroupDetail,
    setProductGroupDetail,
    handleValue,
    handleResetAttach,
    handleChangeAttach,
    handleSelectValue,
    handleChangeKeywords,
    handleRemoveAttach
}



}