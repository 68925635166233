import React from "react";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const RadioElement = ({ item, index, handleChangeData }) => {
    const handleRadioChange = (value) => {
        var selectedValue = item.properties.items.find((s)=>s.value === value)
        handleChangeData(index, "value", selectedValue);
    };

    return (
        <div className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
            <h2 className="harmony-text-xs">
                {item.properties.caption}
            </h2>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <div className="harmony-grid harmony-grid-cols-2 harmony-md-grid-cols-4 harmony-gap-2">
                {item.properties.items.map((radioItem, radioIndex) => (
                    <label
                        key={radioIndex}
                        htmlFor={`radio-${index}-${radioIndex}`}
                        className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer"
                    >
                        <input
                            type="radio"
                            // id={`radio-${index}-${radioIndex}`}
                            // name={`radio-group-${index}`}
                            placeholder={item?.properties?.description}
                            readOnly={item.properties.isReadOnly}
                            disabled={item.properties.isReadOnly}
                            id={item.properties.id}
                            name={item.properties.name}
                            className="harmony-input"
                            value={radioItem.value}
                            checked={radioItem.value === item.data.value.value}
                            onChange={() => handleRadioChange(radioItem.value)}
                        />
                        <h2>{radioItem.label}</h2>
                    </label>
                ))}
            </div>
        </div>
    );
};
