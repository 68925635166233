import React, {useEffect, useState} from "react";

import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import Select from "react-select";
import {Link, redirect, useNavigate} from "react-router-dom";
import {useNotifications} from "./useNotifications";
import {Notification} from "./Notification";
import {NoDataHarmony} from "../../../../Commans/NoDataHarmony";
export const HandleSmartNotificationSetting = () => {
  const { t } = useTranslation();
  const [initialSource,setInitialSource]=useState({patterns:[],events:[]});
  const [IsShowSpinner,setIsShowSpinner]=useState(false);
  const {setNotifications,notifications,handleFieldChange,handleAddPattern, handleDeleteNotification,handleInitialNotifications}=useNotifications(initialSource)

  const showSpinner=()=>{
    setIsShowSpinner(true);
  }
  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }
  const handleInitialSource=(patterns,events)=>{

  }

  const fetchData=async ()=>{
    try {

    }catch (e) {

    }
  }




  useEffect(()=>{
    fetchData()
  },[])




  return(
      <>

        <div class="space-y-5 ">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>

                          </>
                        }
                        childrenTwo={
                          <>

                          </>
                        }
            />

          </div>
          <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
            <div className={"space-y-5"}>
              <div className={"space-y-2"}>
                <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                  <h2 className={"text-xs dark:text-gray-300"}>{t("CreateSMS")}</h2>
                </div>
                <div className="space-y-5 px-2">
                  {
                    notifications && notifications.length > 0 ? (
                        notifications.map((item, index) => (
                            <Notification
                                item={item}
                                initialSource={initialSource}
                                index={index}
                                handleAddNotification={handleAddPattern}
                                handleChangeField={handleFieldChange}
                                handleDeleteNotification={handleDeleteNotification}
                            />
                        ))
                    ) : (
                        <NoDataHarmony/>
                    )
                  }
                </div>
              </div>
              <div class="space-y-2">
                <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                  <h2 className={"text-xs dark:text-gray-300"}>
                    {t("Remotemanagement")}
                  </h2>
                </div>
                <div class="space-y-5">
                  <div class="p-2 ">
                    <div class="grid grid-cols-1 md:grid-cols-3 post-input dark:bg-themeDarkPostInput rounded-md gap-2 p-2">
                      <div class="space-y-1">
                        <label className="text-xs dark:text-gray-300">
                          {t("MobilePhone")}
                        </label>
                        <InputText className="w-full input" />
                      </div>
                      <div class="space-y-1 md:col-span-2">
                        <label  className="text-xs dark:text-gray-300">
                          {t("Messengers")}
                        </label>
                        <Select
                            isMulti={true}
                            placeholder={t("selection")}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                            className={"select-group"}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


          </div>
          <ButtonSubmitAndCancel/>
        </div>


      </>

  )
}