import React from "react";

export const SiteMap=({item,index,handleCheckValue,siteMap})=>{



    return (<>

        <div>
                <label htmlFor={item.value} className={"block"} key={index}>
                    <input
                        type="radio"
                        name="siteMapType"
                        className="input-BackUp hidden"
                        id={item.value}
                        value={item.value}
                        checked={siteMap.siteMapType === item.value}
                        onChange={()=>handleCheckValue(item.value,'siteMapType')}
                    />
                    <div className="flex items-center gap-2 p-2  post-input dark:bg-themeDarkPostInput cursor-pointer connect-BackUp post-input-design">
                        <div className="flex items-center gap-1">
                            <span className="input-BackUp-span"></span>
                        </div>
                        <div>
                            <h2 className="text-xs dark:text-gray-300">{item.label}</h2>
                        </div>
                    </div>
                </label>
        </div>

    </>)
}