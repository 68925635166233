import React from "react";
import {InputText} from "primereact/inputtext";
import {useTranslation} from "react-i18next";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";

export const TelegramAccount=({handleUpdateSocialAccount,socialAccount,errors})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className={"space-y-2"}>
                <div className={"p-2 box bg-white dark:bg-themeDarkSalt700"}>
                    <p className={"text-xs dark:text-gray-300 text-justify"}>ابتدا وارد تنظیمات کانال تلگرام خود شوید
                        در بخش admins گزینه add admin را کلیک کنید
                        ربات @HarmonyAppBot را جستجو و سپس به عنوان مدیر به کانال خود اضافه کنید
                        در مرحله آخر آی دی کانال خود را در کادر زیر وارد کرده و گزینه افزودن اکانت را کلیک کنید
                        اگر نیاز به راهنمایی بیشتر دارید راهنمای کامل را ببینید
                    </p>
                </div>
                <div className={"space-y-1"}>
                    <label className={"text-xs dark:text-gray-300"}>
                        {t("userName")}
                        <RequiredIcon/>
                    </label>

                    <DisplayError message={errors.chatId}/>
                    <InputText className="input"
                               name={'chatId'}
                               value={socialAccount.telegram.chatId}
                               onInput={(e)=>handleUpdateSocialAccount('telegram','chatId',e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor={"robot"} className="text-xs dark:text-gray-300 flex items-center gap-2 ">
                        <input checked={socialAccount.telegram.IsExclusiveToken} onChange={(e)=>handleUpdateSocialAccount('telegram','IsExclusiveToken',e.target.checked)} className="input input--switch border bg-white"  type="checkbox" id={"robot"}/>
                        {t("DedicatedRobot")}
                    </label>
                </div>
                {
                    socialAccount.telegram.IsExclusiveToken?
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>{t("RobotAPICode")}</label>
                                <InputText value={socialAccount.telegram.botToken} onInput={(e)=>handleUpdateSocialAccount('telegram','botToken',e.target.value)} className="input"  />
                            </div>
                        </div>:
                        null
                }

            </div>
        </>
    )
}