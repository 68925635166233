import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {RequestItem} from "./RequestItem";
import {useWithdrawGrid} from "../Withdrawals/useWithdrawGrid";
import {useWithdrawlRequestGrid} from "./useWithdrawlRequestGrid";
import {LoadingHarmony} from "../../../Loadings/LoadingHarmony";
import {Pagination} from "../../../Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {NoDataHarmony} from "../../../NoDataHarmony";
import {HPrimeIcon} from "../../../UIParts/HPrimeIcon";

export const WithdrawRequest=({currentPath})=>{
    const { t } = useTranslation();
    const [refresh,setRefresh] = useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({currencies:[],paymentStates:[],userBanks:[],bankAccounts:[]})
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const handleInitialSource=(currencies,paymentStates,userBanks,bankAccounts)=>{
        const currencyData = convertSelectDataHandler(currencies);
        const paymentStateData = convertSelectDataHandler(paymentStates);
        const userBankData = convertSelectDataHandler(userBanks);
        const bankAccountData = convertSelectDataHandler(bankAccounts);
        setInitialSource({
            currencies: currencyData,
            paymentStates: paymentStateData,
            userBanks: userBankData,
            bankAccounts: bankAccountData
        })
    }
    const fetchData=async()=>{
        try {
          showSpinner();
          const response = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
          const responsePaymentState = await GET(`/Enumerations/EnPaymentStatus`,{},'IEnumerable');
          const responseUserBank = await GET(`/Users/Banks/Selection`);
            const responseBankAccount = await GET(`/Accountings/BankAccounts/Selection`,{},'IEnumerable');

            if(response.status === 200 && responsePaymentState.status === 200 && responseUserBank.status === 200 && responseBankAccount.status === 200){
                handleInitialSource(response.data,responsePaymentState.data,responseUserBank.data,responseBankAccount.data);
                hideSpinner();
            }
            else {
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[refresh])

    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }
    const GetUserBank = (userBankId) => {
        if (!initialSource.userBanks) {
            return;
        }
        const data = initialSource.userBanks.find((item) => item.value === userBankId);
        return data ? data.label : '';
    }
    const GetUserBankAccount=(bankAccountId)=>{
        if (!initialSource.bankAccounts) {
            return;
        }
        const data = initialSource.bankAccounts.find((item) => item.value === bankAccountId);
        return data ? data.label : '';
    }
    const GetTransactionType=(type)=>{
        return (<>
            {
                type === 1?
                    t('Deposit'):
                    t('Withdrawal')

            }
        </>)
    }
    const GetTransactionIcon=(type)=>{
        return (<>
            {
                type === 1?
                    <div className={"flex-shrink-0 bg-themeSuccess h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-arrow-right"} iconClass="text-white"/>
                    </div>
                    :
                    <div className={"flex-shrink-0 bg-themeDanger h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-arrow-left"} iconClass="text-white"/>
                    </div>
            }

        </>)
    }
    const GetPaymentState = (paymentStatus) => {
        if (!initialSource.paymentStates) {
            return;
        }
        const data = initialSource.paymentStates.find((item) => item.value === paymentStatus);
        return data ? data.label : '';
    }



    const {rowData,pagination,getRowHeight,autoGroupColumnDef,defaultColDef,handleNextPage,handlePreviousPage,handleCurrentPage,handlePageSizeCount} = useWithdrawlRequestGrid(showSpinner,hideSpinner,currentPath,refresh);


    return (
        <>
            <div className={'p-2 box bg-white dark:bg-themeDarkSalt700 space-y-5 '}>
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                {rowData.length > 0 ? rowData.map((item, index) => (
                    <RequestItem key={index} GetUserBankAccount={GetUserBankAccount} GetUserBank={GetUserBank} item={item} GetPaymentState={GetPaymentState} GetTransactionType={GetTransactionType} GetTransactionIcon={GetTransactionIcon}  GetCurrencyType={GetCurrencyType} showSpinner={showSpinner} hideSpinner={hideSpinner} Refresh={Refresh} />
                )):
                    <NoDataHarmony/>
                }
            </div>
            {
                rowData.length !== 0 ?
            <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    :
                    null
            }
            <ToastContainer/>
        </>
    )
}