import React, {useRef, useState} from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BasicUrl } from "../../../Services/AxiosService/axiosClient";
import {useDynamicDispatch} from "../../../Services/Redux/useDynamicaDispatch";
import {InputTextarea} from "primereact/inputtextarea";
// import CustomButtonPlugin from './CustomButtonPlugin';
export const TinyEditor = (props) => {
    const {handleDispatch,handleGetReduxDispatch} = useDynamicDispatch();
    const editorType =  handleGetReduxDispatch('setting','editorType')


    const imageUrls = useRef([]);
    const handleInit = (editor) => {
        console.log("Editor is ready to use!", editor);
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
            new MyUploadAdapter(loader);
    };


    const handleEditorChange = (event, editor) => {
        const newData = editor.getData();
        props.handleEditorContent(newData,props.name);
        updateImageList(newData);
    };

    const updateImageList = (htmlString) => {
        const currentImages = extractImageUrls(htmlString);
        const deletedImages = imageUrls.current.filter(
            (url) => !currentImages.includes(url)
        );

        deletedImages.forEach(deleteImage);
        imageUrls.current = currentImages; // Update the ref directly with current image URLs
    };

    const extractImageUrls = (htmlString) => {
        const doc = new DOMParser().parseFromString(htmlString, "text/html");
        return Array.from(doc.querySelectorAll("img")).map((img) =>
            img.getAttribute("src")
        );
    };

    const deleteImage = (url) => {
        // Check if the URL is not null, undefined, or empty
        if (!url) {
            console.warn("Attempted to delete an image with no valid URL.");
            return; // Exit the function if the URL is not valid
        }

        console.log(`Deleting image: ${url}`);
        fetch(`${BasicUrl.baseURL}/Ftp/DeleteByAddress?address=${url}`, {
            method: "DELETE",
        })
            .then((response) => {
                if (response.ok) {
                    console.log("Image deleted successfully:", url);
                } else {
                    console.error("Failed to delete image:", url);
                }
            })
            .catch((error) => {
                console.error("Error deleting image:", url, error);
            });
    };

    class MyUploadAdapter {
        constructor(loader) {
            this.loader = loader;
        }

        upload() {
            return this.loader.file.then((file) => {
                const data = new FormData();
                data.append("file", file);
                data.append("exists", "0");
                data.append("path", "+editor");
                return fetch(`${BasicUrl.baseURL}/Ftp/Editor`, {
                    method: "POST",
                    body: data,
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then((data) => {
                        imageUrls.current.push(data.fileUrl); // Add new URL to ref immediately upon upload
                        return { default: data.fileUrl };
                    })
                    .catch((error) => {
                        console.error("Failed to upload image:", error);
                        return { error: { message: "Failed to upload image." } };
                    });
            });
        }
        abort() {
            console.log("Upload aborted by the user");
        }
    }








    return (
        <>
            {
                editorType === 0?
                    <>
                        <InputTextarea value={props.content} name={props.name} rows={props.rows ? props.rows :'20'}  onInput={(e)=>props.handleEditorContent(e.target.value,props.name)}/>

                    </>
                    :
                    editorType === 1?
                        <>
                            <CKEditor
                                editor={ClassicEditor}
                                data={props.content}
                                // config={{
                                //     extraPlugins: [CustomButtonPlugin],
                                //     toolbar: ['customButton', '|', 'bold', 'italic', 'link']
                                // }}
                                onReady={handleInit}
                                onChange={handleEditorChange}
                            />
                        </>
                        :null

            }

        </>
    );
};
