import React, {useContext, useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {InputNumber} from "primereact/inputnumber";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import {departmentSchema} from "./departmentSchema";
import {handleCreateFormDataDepartment} from "./DepartmentService";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {validate} from "../../../Services/Globals/validate";
import {useDispatch} from "react-redux";
import {Tooltip} from "react-tooltip";

export const FastDepartment=(props,updateData)=>{

    const { t } = useTranslation();
    const[btnLoading,setBtnLoading] = useState(false);
    const [errors,setErrors] = useState([])
    const [department,setDepartment] = useState({name:null,description:null,picture:null,order:0,stateShow:false});
    const onApiError=()=>{
        setBtnLoading(false);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setDepartment({...department,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setDepartment({...department,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleValueSelection =(data,name)=>{
        setDepartment((prevState) => ({ ...prevState, [name]: data }));
    }

    const dispatch = useDispatch();

    const handleDispatchChangeResult =()=>{
       // dispatch(setResult(result));
    }

    const handleDepartmentSubmit=async (e) => {
        e.preventDefault();

        const isValid = validate(departmentSchema,department,onSetErrors);
        if (!isValid) {
            return;
        }
        try {
                setBtnLoading(true);
                const formData = handleCreateFormDataDepartment(department);
                const response = await POST(`/Tickets/Departments`,formData,true);
                if(response.status === 200){
                    setBtnLoading(false)
                    handleCloseModal();
                    props.handleLoading(false)
                    props.updateDataDepartment();
                    handleSuccessMessage(t("Thedepartmentbeensuccessfullyregistered"));
                    handleDispatchChangeResult(response.status)
                    ResetModal();
                }
                else{
                    handleApiError(response,onApiError);
                }
            } catch (e) {
                console.log(e);
            }

    }
    const handleCloseModal = () => {
        ResetModal();
        props.handleCloseModal();
    };
    const ResetModal =()=>{
        setDepartment((prevState)=>({...prevState,stateShow: true,name: '',description: '',picture: null,order: 0}));
        setErrors({})
    }
    useEffect(()=>{
        setDepartment((prevState)=>({...prevState,stateShow: true}))
    },[])
    useEffect(() => {
        if (!props.isOpen) {
            ResetModal();
        }
    }, [props.isOpen]);


    return (


        <>
            <form onSubmit={handleDepartmentSubmit} method={'post'}>
                <div className={"space-y-2"}>
                    <div className={"flex flex-col items-center gap-3"}>
                        <div className={"w-full h-36 tooltip_class_icon"}>
                            <UploadImage isEdit={false} accept={'image/*'} OnValueSelection={handleValueSelection} value={department.picture ? department.picture :null} name={'picture'} />
                            <Tooltip anchorSelect={".tooltip_class_icon"} content={t("Icon")} positionStrategy={"fixed"}	 />
                        </div>
                         <div className={"flex-1 w-full flex items-center gap-2"}>
                            <div className="space-y-2 w-full flex-1">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">{t("NameDepartment")}</label>
                                    <DisplayError message={errors.name}/>
                                </div>
                                <InputText name={'name'} value={department.name} onInput={(e) => handleValue(e)}
                                           type={"text"} className={" input w-full"}/>
                            </div>
                            <div className="space-y-1 flex-shrink-0 w-24">
                                <label className="text-xs dark:text-gray-300">{t("rank")}</label>
                                <div>
                                    <InputNumber className="input_number_plus" value={department.order}
                                                 onValueChange={(e) => handleNumberValue(e.value, 'order')}
                                                 placeholder={t("DisplayPriority")}
                                                 mode="decimal"
                                                 showButtons
                                                 min={0}
                                                 max={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <InputTextarea value={department.description} onInput={(e) => handleValue(e)} placeholder={t("Explanation")} name={'description'} rows={5} cols={30} className="input w-full text-xs"/>


                    <div className={"flex justify-items-start gap-5"}>
                        <InputSwitch OnValueSelection={handleValueSelection} value={department.stateShow} name={'stateShow'} lable={t("DisplayStatus")}/>
                        <InputSwitch  lable={t("دپارتمان داخلی")}/>
                    </div>


                    <div>
                        <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation relative">
                            {t("register")}
                            {btnLoading ? (
                                <TinyLoading />
                            ) : (
                                null
                            )}
                            </button>
                    </div>
                </div>
            </form>
















        </>
    )
}