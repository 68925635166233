import React, {useEffect, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";

import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import queryString from "query-string";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {convertSelectDataHandler} from "../../Contents/ContentService";
import Select from "react-select";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";

import {useSetting} from "../../../Commans/Hooks/useSetting";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";

import {NoDataHarmony} from "../../../Commans/NoDataHarmony";


export const HandleResultPoll=()=>{

    const { t } = useTranslation();
    const [spinner,setSpinner] = useState(false);
    const [options,setOptions] = useState({surveyTypes:[]});

    const [surveyDetails,setSurveyDetails] = useState({title:'',totalAttendees:0,totalSurveysCount:0})
    const [refresh,setRefresh] = useState(0);
    const [surveyData,setSurveyData] = useState(null);
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    // handles loading spinner when request fails
    const onApiError = () => {
        setSpinner(false);
    };
    // gets a print of poll results
    const handlePrint = () => {
        window.print();
    };
    //refreshes component
    const Refresh=(state)=>{
        setRefresh({...state});
    }
    // const chartContainerRef = useRef(null);
    // const [chartOptions, setChartOptions] = useState({
    //     tooltip: {
    //         trigger: 'item'
    //     },
    //     legend: {
    //         top: '5%',
    //         left: 'center'
    //     },
    //     series: [
    //         {
    //             name: t('Survey'),
    //             type: 'pie',
    //             radius: ['75%', '60%'],
    //             avoidLabelOverlap: false,
    //             label: {
    //                 show: false,
    //                 position: 'center'
    //             },
    //             emphasis: {
    //                 label: {
    //                     show: true,
    //                     fontSize: 30,
    //                     fontWeight: 'bold',
    //                     fontFamily: 'shabnam'
    //                 }
    //             },
    //             labelLine: {
    //                 show: true
    //             },
    //             data:[
    //
    //             ]
    //         }
    //     ]
    // });


    // Gets Data chart
    const handleGetDataChart=(surveys)=>{
        const chartData = surveys.flatMap(survey =>
            survey.surveyItems.map(item => ({
                value: parseInt(item.totalAttendees, 10),
                name: item.surveyItemName
            }))
        );
        return chartData;
    }

    // puts data in chart and initialize chart
    // const handleChartData=(chartData)=>{
    //     const updatedChartOptions = {
    //         ...chartOptions,
    //         series: [
    //             {
    //                 ...chartOptions.series[0], // Keep other properties of the series
    //                 data: chartData
    //             }
    //         ]
    //     };
    //     setChartOptions(updatedChartOptions);
    //     const chartContainer = chartContainerRef.current;
    //     const chart = echarts.init(chartContainer);
    //     chart.setOption(updatedChartOptions);
    // }

    // fetches all data from server while loading component
    const fetchData=async()=>{
        try {
            const responseSurveyTypes = await GET('/Enumerations/EnSurveyType',{},'IEnumerable');
            if(responseSurveyTypes.status === 200){
                const surveyTypes = convertSelectDataHandler(responseSurveyTypes.data);
                setOptions({surveyTypes: surveyTypes});
            }
            else{
                handleApiError(responseSurveyTypes,onApiError)
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const [filter,setFilter] = useState({search:'',type:null,fromDate:null,toDate:null});
    const [search,setSearch]= useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:search}));
    }
    const handleFromDateTime=(dateTime)=> setFilter((prevState)=>({...prevState,fromDate:dateTime}));
    const handleToDateTime=(dateTime)=>setFilter((prevState)=>({...prevState,toDate:dateTime}));

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const handleFilterValue=(data,name)=>{
        setFilter(({...filter,[name]: data.value}));
    }
    const resetFilters = ()=>{
        setFilter(({...filter,search:'',fromDate: null,toDate: null,type: null}));
        setSearch('');
    }

    useEffect(() => {
        const loadData = async () => {
            try {

                setSpinner(true);
                const response = await OnGridReady();
                console.log(response);
                if (response !== null ) {
                    fetchData();

                }
                else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();

    }, [refresh,filter.search,filter.fromDate,filter.toDate,filter.type]);






    const OnGridReady = async () => {
        try {

            setSpinner(true);
            const queryParams = queryString.parse(window.location.search);
            const response = await GET(`/Surveys/${queryParams.surveyGroupId}/Charts`, { Type: filter.type, Search: filter.search, FromDate: filter.fromDate, ToDate: filter.toDate }, 'IEnumerable');
            if (response.data !== null) {
                setSurveyData(response.data);
                const { title, totalAttendees, totalSurveysCount } = response.data;
                setSurveyDetails({ title, totalAttendees, totalSurveysCount });

                const chartData = handleGetDataChart(response.data.surveys);
                // handleChartData(chartData);

                return response;
            } else {
                handleApiError(response, onApiError);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };












    return(
        <>
            <div class="space-y-4">
                <div className="space-y-5 box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListSearch label={null} icon={null} >
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("QuestionTitle")}</label>
                            <InputText value={search} onChange={(e)=>setSearch(e.target.value)} type="search" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className="input w-full"  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex items-center gap-1">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleFromDateTime} value={filter.fromDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}
                            </label>
                            <DateTimePicker value={filter.toDate} handleDateTime={handleToDateTime} format={'YYYY/MM/DD'} />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("Answer")}</label>
                            <Select
                                options={options.surveyTypes}
                                isMulti={null}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleFilterValue(data,'type')}
                                name={'type'}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/Surveys/Add`)}
                                            functionClass={"harmony-creation"}
                                            tooltipTitle={t("CreatingASurvey")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>handlePrint()}
                                            title={t("PrintComments")}
                                            icon={"pi-print"}
                                            tooltipTitle={t("PrintComments")}
                                        />
                                    </>
                                }
                    />
                </div>
                <div className={"relative space-y-4"}>

                    {

                        spinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className={"space-y-5"}>
                        <div className={"box bg-white dark:bg-themeDarkSalt700 p-2"}>
                            <div className="bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation p-2 flex items-center justify-between gap-2 ">
                                <div className={"flex items-center gap-2 overflow-x-auto"}>
                                    <span className={" text-nowrap text-themeInformation dark:text-themeDarkInformation"}>{t("SurveyTitle")}:</span>
                                    <span className="dark:text-gray-300 text-nowrap"> {surveyDetails.title}</span>
                                </div>
                                <div className={"flex-shrink-0 post-input p-2 post-input-design"}>
                                    <p className="dark:text-gray-300 text-xs">{t("TotalVote")}:{surveyDetails.totalSurveysCount}</p>
                                </div>
                            </div>
                        </div>
                        <div className="box bg-white dark:bg-themeDarkSalt700 p-2">
                            <div className={" space-y-5"}>
                                    { surveyData !== null ? surveyData.surveys.map((item, index) => (
                                        <div className={"px-2 post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                                            <div key={index} className={"space-y-3"}>
                                                <div className="bg-themeLightGray post-input-design-x border-themeInformation dark:border-themeDarkInformation p-2 flex flex-col sm:flex-row gap-2 items-center">
                                                    <div className={"flex-1 w-full flex gap-2 items-center"}>
                                                        <span className={"text-xs dark:text-gray-300 text-nowrap"}>
                                                            {t("QuestionTitle")} :
                                                        </span>
                                                        <p className={" text-xs text-themeInformation dark:text-themeDarkInformation"}>{item.question}</p>
                                                    </div>
                                                    <div className={"flex-shrink-0 post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                                                        <p className={"text-xs dark:text-gray-300"}>
                                                            {t("TotalVote")}: {item.totalAttendees}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                                                    { item.surveyItems !== null || item.surveyItems.length > 0? item.surveyItems.map((option,index) => (
                                                        <div key={index} className="flex items-center gap-2">
                                                            <div className="number_pluse bg-themeInformation flex-shrink-0 text-white text-center dark:bg-themeDarkInformation">
                                                                {index+1}
                                                            </div>
                                                            <div className={"bg-white dark:bg-themeDarkSalt700 box p-2 w-full flex-1 space-y-2"}>
                                                                <div className=" post-input p-2 flex justify-between items-center gap-2 post-input-design">
                                                                    <p className={"text-xs "}>{t("تعداد شرکت کننده")}:{option.totalAttendees}</p>
                                                                    <p className="text-themeInformation dark:text-themeDarkInformation text-xs">{option.surveyItemValue}</p>
                                                                </div>
                                                                <div>
                                                                    <h2 className={"text-xs dark:text-gray-300"}>{option.surveyItemName}</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )):null}
                                                </div>
                                            </div>
                                        </div>

                                        )):
                                        <NoDataHarmony/>
                                    }
                                </div>
                        </div>
                    </div>

                </div>

            </div>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>
        </>




    )

}