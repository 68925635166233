import React, {useEffect, useMemo, useState} from "react";


import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {Return} from "../../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {Domains} from './Domains';
import {useCustomDomains} from "./useCustomDomains";
import queryString from "query-string";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {handleEditWebsiteDomains} from "./domainService";
import {GET, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {handleFailureMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {validDomainPattern} from "../../../../../Services/Globals/Public/Regexes";
export const HandleDomain = ()=>{
    const queryParams = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsSpinnerShow,setIsShowSpinner] = useState(false);
    const onApiError=()=>{
        setIsShowSpinner(false);
    }
    const ShowSpinner=()=>setIsShowSpinner(true);
    const hideSpinner=()=>setIsShowSpinner(false);

    const {handleAddRow,domains,setDomains,handleRemoveRow,handleChange,handleChangeDateTime,validateDomains,validateMasterDomains,validateNotEmptyDomains} = useCustomDomains();

    const handleSubmitDomains=async()=>{
        const count = domains.filter((item) => item.domain === null || item.domain === '').length;
        if (count > 0) {
            handleFailureMessage(t("Dearuserpleaseenterthedomainname"));
            return;
        }

        const domainArray = domains.map((item) => validDomainPattern.test(item.domain));
        const domainArrayCount = domainArray.filter((item) => item === false).length;
        if (domainArrayCount > 0) {
            handleFailureMessage(
                t("Enteravaliddomainname")
            );
            return;
        }

        const masterCount = domains.filter((item) => item.isMaster === true).length;
        if(masterCount !== 1){
            handleFailureMessage(t("Pleasechooseadefaultdomain"));
            return;
        }
        try {
            ShowSpinner();
           const createdDomains = handleEditWebsiteDomains(domains,queryParams.websiteId,queryParams.websiteConcurrencyStamp);
           const response = await PUT(`/Websites/Domains`,createdDomains,false);
           if(response.status === 200){
               hideSpinner();
              redirectTo('/Admin/Website/List');
           }
           else{
               handleApiError(response,onApiError);
           }
        }
        catch (e) {
            hideSpinner();
        }
    }


    const fetchData =async()=>{
        try {
            ShowSpinner();
            const response = await GET(`/Websites/Domains`,{websiteId:queryParams.websiteId},'single');
            if(response.status === 200){
                hideSpinner();
                setDomains(response.data.domains);
            }

        }
        catch (e){
            console.log(e);
        }
    }
    useEffect(()=>{
        fetchData()
    },[])



    return (
        <>
            <div className=" space-y-5">
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-3"}>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Admin/Website/List`)}
                                            icon={"pi-list"}
                                            tooltipTitle={t("ViewList")}
                                            title={t("list")}
                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                <div className="bg-white box dark:bg-themeDarkSalt700 p-3 relative">

                    {

                        IsSpinnerShow?
                            <div className="huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }

                    <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                        <h2 className={"text-xs dark:text-gray-300"}>{t("domains")}</h2>
                    </div>
                    <div className="max_height85vh">

                        {domains.map((item, index) => (
                            <Domains
                                handleAddRow={handleAddRow}
                                item={item}
                                index={index}
                                handleChange={handleChange}
                                handleDeleteRow={handleRemoveRow}
                                handleChangeDateTime={handleChangeDateTime}
                            />
                        ))}


                    </div>
                </div>
                <ButtonSubmitAndCancel
                    onCancelClick={()=>redirectTo('/Admin/Website/List')}
                    onRegisterClick={()=>handleSubmitDomains()}
                />
            </div>

        </>

    )
}