import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {Link, useNavigate} from "react-router-dom";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import Swal from "sweetalert2";
import {DELETE, GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";

export const useDocumentHistoryGrid=(showSpinner,hideSpinner,refresh,Refresh,initialSource)=> {

    const {t} = useTranslation();
    const queryParams = queryString.parse(window.location.search);
    const [rowData, setRowData] = useState([]);
    const redirectTo = useNavigate();


    const handleDeleteDocument = async (documentId) => {
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    showSpinner()
                    const response = await DELETE(`/HarmonyDocument/${documentId}`, {headers: {'Content-Type': 'application/json'}},)
                    if (response.status === 200) {
                        Refresh()
                        hideSpinner()
                        handleSuccessMessage(t("Discountcodehasbeensuccessfullydeleted"));
                    } else {
                        handleApiError(response, hideSpinner)
                    }
                } catch (e) {
                    console.log(e);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const GetAllkeywords = (keywords) => {
        if(  keywords === null){
            return "-"
        }
        const keywordsString = keywords.join(', ');
        return keywordsString
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},


        {
            headerName: t("Title"), minWidth: 160,
            cellRenderer: parameters =>
                <>
                    {parameters.data.title}
                </>
        },
        {
            headerName: t("Address"), minWidth: 160,
            cellRenderer: parameters =>
                <>
                    {parameters.data.slug}

                </>
        },

        {
            headerName: t("Group"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.documentGroupName}
                </>
        },
        {
            headerName: t("Keywords"), minWidth: 160,
            cellRenderer: parameters =>
                <>
                    {GetAllkeywords(parameters.data.keywords)}
                </>
        },
        {
            headerName: t("Author"), minWidth: 150,
            cellRenderer: parameters =>
                <>{parameters.data.author}</>
        },
        {
            headerName: t("PublicationDate"), minWidth: 120,
            cellRenderer: parameters =>
                <GridDateFomartter date={parameters.data.createdAt} />
        },

        {
            headerName: t("UpdateDate"), minWidth: 140,
            cellRenderer: parameters =>
                <GridDateFomartter date={parameters.data.updatedAt} />
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer: params => {
                return <div className={'flex justify-center items-center gap-3 '}>
                    <GridButtonEdit
                    onClick={()=>redirectTo(`/Admin/ProjectDocument?documentId=${params.data.harmonyDocumentId}`)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteDocument(params.data.harmonyDocumentId)}
                    />
                </div>
            }

        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination, setPagination] = useState({
        totalPages: 0,
        page: 0,
        pageSize: GridSetting.pageSize,
        totalCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        lastPage: 0,
        currentPage: 1
    })
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({...pagination, pageSize: pageSize}))
    }


    const [filter, setFilter] = useState({
        search: '',
        IsBackEnd: null,
        IsFrontend: null,
        IsFrontProgramming: null,
        harmonyDocumentGroupId: '',
        harmonyDocumentGroupIdValue: null
    });

    const SwitchFilters = [
        {name: 'IsBackEnd', lable: t("IsBackEnd"), value: filter.IsBackEnd},
        {name: 'IsFrontend', lable: t("IsFrontEnd"), value: filter.IsFrontend},
        {name: 'IsFrontProgramming', lable: t("IsFrontProgramming"), value: filter.IsFrontProgramming}
    ]

    const [search, setSearch] = useState('');
    const handleSearch = () => {
        setFilter((prevState) => ({...prevState, search: search}));
    }
    const handleToggleFilter = (data, name) => {
        data ? setFilter(({...filter, [name]: data})) : setFilter(({...filter, [name]: null}));
    }
    const handleResetFilters = () => {
        setFilter(({
            ...filter,
            search: '',
            IsBackEnd: null,
            IsFrontend: null,
            IsFrontProgramming: null,
            harmonyDocumentGroupIdValue: null,
            harmonyDocumentGroupId: ''
        }));
        setSearch('');
    }

    const handleSelectFilter = (data, name) => {
        setFilter(({...filter, [name]: data.value, harmonyDocumentGroupIdValue: data}));
    }


    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize, filter.IsFrontend, filter.IsBackEnd, filter.IsFrontProgramming, filter.harmonyDocumentGroupId]);

    const OnGridReady = async () => {
        try {
            showSpinner()
            const response = await GET(`/HarmonyDocumentBySlug`, {
                PageNumber: pagination.currentPage,
                PageSize: pagination.pageSize,
                Search: filter.search,
                IsFrontend: filter.IsFrontend,
                IsBackEnd: filter.IsBackEnd,
                IsFrontProgramming: filter.IsFrontProgramming,
                HarmonyDocumentGroupId: filter.harmonyDocumentGroupId,
                Slug:queryParams.slug
            }, 'PagedList');
            if (response && response.items !== null) {
                setPagination({
                    ...pagination,
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPreviousPage: response.hasPreviousPage,
                    page: response.page,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount
                });
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            } else {
                handleApiError(response, hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    }

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter,

    }
}