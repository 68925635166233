import React, {useState} from "react";
import {useTranslation} from "react-i18next";
export const useOrganization=(setErrors,initialSource)=>{
    const { t } = useTranslation();
    const targetItems = [
        { name: t("Form"), value: 0 },
        { name: t("Report"), value: 1 },
        { name: t("ExternalFrame"), value: 2 }
    ];

    const [organization,setOrganization]=useState({linkOrganizationId:null,linkOrganizationGroupId:null,rank:0,caption:null,link:null,icon:null,iconPath:null,linkOrganizationType:1,target:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setOrganization({...organization,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue=(value,name)=>{
        setOrganization({...organization,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue = async (data, name) => {
        if (name === 'linkOrganizationType') {
            let newLinkValue = '';

            if (data === 0 && initialSource.forms.length > 0) {
                newLinkValue = initialSource.forms[0];
            } else if (data === 1 && initialSource.reports.length > 0) {
                newLinkValue = initialSource.reports[0];
            }

            setOrganization({
                ...organization,
                [name]: data,
                link: newLinkValue
            });
        } else {
            setOrganization({ ...organization, [name]: data });
        }
    }


    const handleRemoveImagePath=(path,name)=>setOrganization((prevState)=>({...prevState,[name]:path}))
    const handleResetImages = () => {
        setOrganization((prevState)=>({...prevState,icon: null, iconPath: null}));
    }
    const handleChangeImage =(data,name)=>{
        setOrganization((prevState) => ({ ...prevState, [name]: data }));
    }


    return {
        handleValue,
        handleSelectValue,
        organization,
        setOrganization,
        targetItems,
        handleNumberValue,
        handleRemoveImagePath,
        handleResetImages,
        handleChangeImage
    }
}