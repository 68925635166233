import React from "react";

export const UserRoleBox=({name})=>{

    return (
        <>
            <span className={"text-nowrap border border-dashed dark:border-themeDarkColorLine box bg-themeSecondary text-white dark:bg-themeDarkSalt700 justify-center p-1 text-xs dark:text-gray-300"}>{name}</span>

        </>
    )
}