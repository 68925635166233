import React, {useState} from "react";


import {Link} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {useCustomRoutes} from "../Hooks/useCustomRoutes";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../UIParts/HPrimeIconSmall";

//this is sidebar and handling other components inside of it .
export const MenuBarHorizontal=()=>{

    const { t } = useTranslation();


    const {seo,advertise,CMS,Comment,SocialMarketing,EmailMarketing,DiscountCode,form,Guarantee,poll,shop,manager,shortMessageService,PersonManagement,support,template}=useCustomRoutes()

    const SidebarLink = ({ to, icon, title, target =false,isInRole=null}) => (
        <li>
            <Link to={to} target={target?'_blank':null}   className="top-menu">
                <div className="top-menu__icon">
                    <HPrimeIconSmall icon={icon}   />
                </div>
                <div className="top-menu__title">{title}</div>
            </Link>
        </li>
    );

    const SubMenu = ({ title, links,}) => (
        <>
            {links.map((link, index) => (
                <SidebarLink key={index} {...link} onClick={onclick} />
            ))}
        </>

    );


    return (

        <nav class="top-nav hidden lg:block">
            <ul>
                <li>
                    <Link to="/Dashboard" className="top-menu top-menu--active">
                        <div class="top-menu__icon">
                            <HPrimeIconSmall icon={'pi-desktop'} iconClass={"dark:text-themeDarkInformation"}/>
                        </div>
                        <div class="top-menu__title">{t("Dashboard")}</div>
                    </Link>
                </li>
                <li>
                    <div className="top-menu cursor-pointer">
                        <div class="top-menu__icon">
                            <HPrimeIconSmall icon={'pi-user'} />
                        </div>
                        <div class="top-menu__title">
                            {t("generalManager")}
                            <HPrimeIconSmall icon={'pi-angle-down'}  iconClass="top-menu__sub-icon"/>
                        </div>
                    </div>
                    <ul class="top-menu_ul">
                    <SubMenu
                        links={manager.links}
                    />
                    </ul>

                </li>
                <li>
                    <div className="top-menu cursor-pointer">
                        <div class="top-menu__icon">
                            <HPrimeIconSmall icon={'pi-users'} />
                        </div>
                        <div class="top-menu__title">
                            {t("CRM")}
                            <HPrimeIconSmall icon={'pi-angle-down'} iconClass="top-menu__sub-icon"  />
                        </div>
                    </div>
                    <ul class="top-menu_ul">
                        <SubMenu
                            links={PersonManagement.links}
                        />
                    </ul>
                </li>

                <li>
                    <div className="top-menu cursor-pointer">
                        <div class="top-menu__icon">
                            <HPrimeIcon icon={'pi-globe'} />
                        </div>
                        <div class="top-menu__title">
                            {t("Website")}
                            <HPrimeIconSmall icon={'pi-angle-down'} iconClass="top-menu__sub-icon"  />
                        </div>
                    </div>
                    <ul class="top-menu_ul">
                        <li>
                            <Link to="/Public/MainMenu/List" className="top-menu">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'align-right'} />
                                </div>
                                <div class="top-menu__title">{t("MenuManagement")}</div>
                            </Link>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'send'} />
                                </div>
                                <div class="top-menu__title">
                                    {t("CMS")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={CMS.links}
                                />
                            </ul>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'message-square'} />
                                </div>
                                <div class="top-menu__title">
                                    {t("UserComments")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={Comment.links}
                                />
                            </ul>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'edit'} />
                                </div>
                                <div class="top-menu__title">
                                    {t("Template")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={template.links}
                                />
                            </ul>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'activity'} />
                                </div>
                                <div class="top-menu__title">
                                   {t("Survey")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={poll.links}
                                />
                            </ul>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'tv'} />
                                </div>
                                <div class="top-menu__title">
                                   {t("Advertising")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={advertise.links}
                                />
                            </ul>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'award'} />
                                </div>
                                <div class="top-menu__title">
                                    {t("seoAndTraffic")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={seo.links}
                                />
                            </ul>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <Link to="/Authentication/FileManager" className="top-menu">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'package'} />
                                </div>
                                <div class="top-menu__title">{t("FileManagement")}</div>
                            </Link>
                        </li>
                    </ul>

                </li>

                <li>
                    <div className="top-menu cursor-pointer">
                        <div class="top-menu__icon">
                            <HPrimeIconSmall icon={'pi-shopping-cart'} />
                        </div>
                        <div class="top-menu__title">
                            {t("shop")}
                            <HPrimeIconSmall icon={'pi-angle-down'} iconClass="top-menu__sub-icon"  />
                        </div>
                    </div>
                    <ul class="top-menu_ul">
                        <SubMenu
                            links={shop.links}
                        />
                        <li className="side-nav__devider"></li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'award'} />
                                </div>
                                <div class="top-menu__title">
                                    {t("Guarantee")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={Guarantee.links}
                                />
                            </ul>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'activity'} />
                                </div>
                                <div class="top-menu__title">
                                    {t("DiscountsAndPrizes")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={DiscountCode.links}
                                />
                            </ul>
                        </li>
                        <li className="side-nav__devider"></li>
                        <li>
                            <Link to="/Commission" className="top-menu">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'thumbs-up'} />
                                </div>
                                <div class="top-menu__title">{t("Commission")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li>
                    <div className="top-menu cursor-pointer">
                        <div class="top-menu__icon">
                            <HPrimeIconSmall icon={'pi-bullseye'} />
                        </div>
                        <div class="top-menu__title">
                            {t("Marketing")}
                            <HPrimeIconSmall icon={'pi-angle-down'} iconClass="top-menu__sub-icon"  />
                        </div>
                    </div>
                    <ul class="top-menu_ul">
                        <li>
                            <a to="javascript:;" className="top-menu">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'message-circle'} />
                                </div>
                                <div class="top-menu__title">
                                    {t("SMS")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </a>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={shortMessageService.links}
                                />
                            </ul>
                        </li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'instagram'} />
                                </div>
                                <div class="top-menu__title">
                                   {t("digitalMarketing")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={SocialMarketing.links}
                                />
                            </ul>
                        </li>
                        <li>
                            <div className="top-menu cursor-pointer">
                                <div class="top-menu__icon">
                                    <HPrimeIconSmall icon={'mail'} />
                                </div>
                                <div class="top-menu__title">
                                    {t("EmailMarketing")}
                                    <HPrimeIconSmall icon={'chevron-left'}  iconClass={"top-menu__sub-icon"}/>
                                </div>
                            </div>
                            <ul class="top-menu_ul_ul">
                                <SubMenu
                                    links={EmailMarketing.links}
                                />
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <div className="top-menu cursor-pointer">
                        <div class="top-menu__icon">
                            <HPrimeIconSmall icon={'pi-objects-column'} />
                        </div>
                        <div class="top-menu__title">
                            {t("Form")}
                            <HPrimeIconSmall icon={'pi-angle-down'} iconClass="top-menu__sub-icon"  />
                        </div>
                    </div>
                    <ul class="top-menu_ul">
                        <SubMenu
                            links={form.links}
                        />
                    </ul>
                </li>
            </ul>
        </nav>
    )
}