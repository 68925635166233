



export const handleCreateUserFormDataDocument=(userId,concurrencyStamp,files,IsEdit)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Id',userId);
    appendIfNotNull('ConcurrencyStamp',concurrencyStamp);

    if(IsEdit){
        if (files) {
            for (let i = 0; i < files.length; i++) {
                if(files[i].file === null && files[i].userAttachId === null){

                }
                else{
                    if(files[i].file !== null && files[i].userAttachId !== null ){
                        appendIfNotNull(`Files[${i}].fileUrl`,files[i].fileUrl);
                    }
                    appendIfNotNull(`Files[${i}].file`,files[i].file);
                    appendIfNotNull(`Files[${i}].attachmentType`, files[i].attachmentType.value);
                    appendIfNotNull(`Files[${i}].caption`, files[i].caption);
                    appendIfNotNull(`Files[${i}].userAttachId`,files[i].userAttachId );
                }

            }
        }
    }
    else{

        if (files) {
            for (let i = 0; i < files.length; i++) {
                if(files[i].file !== null){
                    appendIfNotNull(`Files[${i}].file`,files[i].file);
                    appendIfNotNull(`Files[${i}].caption`, files[i].caption);
                    appendIfNotNull(`Files[${i}].attachmentType`,files[i].attachmentType.value);
                }else{

                }
            }
        }
    }

    return formData
}
