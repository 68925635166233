import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../Services/Globals/validate";
import {FilterSchema} from "../../Public/HarmonySetting/Filter/filterSchema";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {handleAddFilter} from "../../Public/HarmonySetting/Filter/filterService";
import Select from "react-select";

import {LoadingHarmony} from "../Loadings/LoadingHarmony";
import {DisplayError} from "../Errors/DisplayError";
import {InputText} from "primereact/inputtext";
import {NewReactComponent} from "ag-grid-react/lib/shared/newReactComponent";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";

export const Filter=(props)=>{

    const [modal, setModal] = useState({ AddItem: false, AddFilter:false });
    const [Apiname,setApiName] = useState('');
    const { t } = useTranslation();
    const [filter,setFilter] = useState({table:null,title:null})
    const [filterItems,setFilterItems] = useState([]);
    const [refresh,setRefresh] = useState(false);
    const [options,setOptions] = useState({tables:[],operations:[],previousOperations:[],filterTables:[],fields:[],relationalOperators:[],conditions:[],values:[]});
    const [errors, setErrors] = useState({});
    const[spinner,setSpinner] = useState(false);

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setFilter({...filter,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue= async (data,name)=>{
        setFilter({ ...filter, [name]: data });
        try {
            const {fields} = data;
            const filterFields = fields.map(item => ({label: item.value, value: item.key, typeName: item.typeName}));
            setOptions((prevState) => ({...prevState, fields: filterFields}));
        }
        catch (e) {
            console.log(e);
        }
    }


   const Refresh=()=>{
       setRefresh((prevState)=>!prevState);
   }
    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }


    const handleFetchData=async (IsOpen) => {
        try {

            if(IsOpen){
                if (filterItems.length === 0) {
                    handleAddRow();
                }
                setSpinner(true)
                const responseTable = await GET(`/EnTables/Selection`,{},'Enumerable');
                const responsePreviousOperations = await GET(`/Enumerations/EnLogicalOperator`,{},'Enumerable');
                const responseRelationalOperators = await GET(`/EnRelationalOperators/Selection`,{},'Enumerable');
                if(responseTable.status === 200 && responsePreviousOperations.status === 200){
                    const tables = responseTable.data.map((item)=>({
                        label:item.value,
                        value:item.key,
                        fields:item.fields
                    }))
                    const previousOperators = convertSelectDataHandler(responsePreviousOperations.data);
                    const relationalOperators = responseRelationalOperators.data.map(item => ({label: item.value, value: item.key,types:item.types}));
                    setOptions((prevState)=>({...prevState,tables: tables,previousOperations: previousOperators,relationalOperators: relationalOperators}));
                    setFilter({title: '',table: tables[0]});
                    setErrors({})
                    setSpinner(false)
                }
                else{
                    handleApiError(responseTable,onApiError)
                    handleApiError(responsePreviousOperations,onApiError)
                }
            }


        } catch (e) {
            onApiError()
        }
    }

    const handleAddRow=()=>{
        if(filterItems.length === 0){
            const newRow = {
                id: new Date().getTime(),
                table: options.filterTables[0],
                operation:options.conditions[0],
                previousOperation:options.previousOperations[0],
                field:options.fields[0],
                value: null,
                order:1,
                isDisable:true,
                filterItemId:null,
                isList:false,
                hasFk:true,
                foreignKey:null
            };
            setFilterItems(prevList => [...prevList, newRow]);
        }
        else{
            const newRow = {
                id: new Date().getTime(),
                table: options.filterTables[0],
                operation:options.conditions[0],
                previousOperation:options.previousOperations[0],
                field:options.fields[0],
                value: null,
                order:filterItems.length >0 ? filterItems[filterItems.length-1].order + 1 : 0,
                isDisable:false,
                filterItemId:null,
                isList: false,
                hasFk:true,
                foreignKey:null

            };
            setFilterItems(prevList => [...prevList, newRow]);
        }
    }

    const handleRemoveRow = (id,filterItemId) => {
        if(filterItems.length === 1){
            const newRow = {
                id: new Date().getTime(),
                table: options.filterTables[0],
                operation:null,
                previousOperation:null,
                field:null,
                value: '',
                order:1,
                isDisable:true,
                filterItemId:null,
                isList:false,
                hasFk:true,
                foreignKey:null

            };
            setFilter((prevState)=>({...prevState,title: ''}))
            setOptions(prevState => ({...prevState,conditions: [],fields: [],filterTables: []}))
            setFilterItems(prevList => [newRow]);
            return;
        }
        setFilterItems((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.filterItemId === filterItemId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };


    const handleInitialFilter=(filterTables,conditions,previousOperations,fields)=>{
        const newRow = {
            id: new Date().getTime(),
            table: filterTables[0],
            operation:conditions[0],
            previousOperation:previousOperations[0],
            field:fields[0],
            value: null,
            order:1,
            isDisable:true,
            filterItemId:null,
            isList:false,
            hasFk:true,
            foreignKey:null
        };
        setFilterItems([newRow]);
    }


    const handleChange = (id, field, value, filterItemId) => {
        setFilterItems((prevRelations) => {
            const updatedRelations = prevRelations.map( (meta) => {

                if ((filterItemId && meta.filterItemId === filterItemId) || (!filterItemId && id && meta.id === id)) {

                    if (field === 'field') {

                        const {typeName} = value;
                        const conditions = options.relationalOperators.filter(item => GetIncludeTypes(item.types, typeName));
                        setOptions((prevState) => ({...prevState, conditions: conditions}));

                        meta.isList =   IsEnumerableType(typeName).IsEnumerable;
                        meta.hasFk =   IsEnumerableType(typeName).HasFk;

                    }
                    if(field === 'foreignKey' && meta.hasFk === false){
                        const {typeName} = value;
                        const conditions = options.relationalOperators.filter(item => GetIncludeTypes(item.types, typeName));
                        setOptions((prevState) => ({...prevState, conditions: conditions}));
                        meta.isList =   IsEnumerableType(typeName).IsEnumerable;
                    }
                    return {...meta, [field]: value};
                } else {
                    return meta;
                }
            });
            return updatedRelations;
        });
    };

    const GetIncludeTypes=(types,typeName)=>{
        const NullablePattern = /Nullable<(.*?)>$/;
        const DbSetPattern = /DbSet<(.*?)>$/;
        const SmartEnumPattern = /SmartEnum<(.*?)>$/;
        const EnumPattern = /Enum<(.*?)>$/;
        const ListPattern = /List<(.*?)>$/;

        if(NullablePattern.test(typeName) ){
            const matches = typeName.match(NullablePattern);
            return types.includes(matches[1]);
        }
        else if(DbSetPattern.test(typeName) )
        {
            const matches = typeName.match(DbSetPattern);
            return types.includes("DbSet");
        }
        // SmartEnum<*>
        else if(SmartEnumPattern.test(typeName))
        {
            const matches = typeName.match(SmartEnumPattern);
            return types.includes("SmartEnum");
        }
        // Enum<*>
        else if(EnumPattern.test(typeName))
        {
            const matches = typeName.match(EnumPattern);
            return types.includes("Enum");
        }
        else{
            return types.includes(typeName);
        }
    }

    const IsEnumerableType= (fieldTypeName)=>{
        const NullablePattern = /Nullable<(.*?)>$/;
        const DbSetPattern = /DbSet<(.*?)>$/;
        const SmartEnumPattern = /SmartEnum<(.*?)>$/;
        const EnumPattern = /Enum<(.*?)>$/;
        const ListPattern = /List<(.*?)>$/;
        const FkPattern = /Fk<(.*?)>$/;
        const result = {IsEnumerable:false,HasFk:true}
        if(DbSetPattern.test(fieldTypeName)){
            const matches = fieldTypeName.match(DbSetPattern);
            switch (matches[1]) {
                case 'UserGroups':
                    setApiName('/UserGroups');
                    break;
                case 'Roles':
                    setApiName('/Roles/Selection');
                    break;
                case 'ProductGroups':
                    setApiName('/ProductGroups/Selection');
                    break;

                case 'ContentGroups':
                    setApiName('/ContentGroups/Selection');
                    break;
            }
            result.IsEnumerable = true;
            return  result;
        }
        else if (SmartEnumPattern.test(fieldTypeName)){
            const matches = fieldTypeName.match(SmartEnumPattern);
            setApiName(matches[1]);
            result.IsEnumerable = true;
            return  result;
        }
        else if(EnumPattern.test(fieldTypeName))
        {
            const matches = fieldTypeName.match(EnumPattern);
            setApiName(`/Enumerations/${matches[1]}`)
            result.IsEnumerable = true;
            return  result;
        }
        else if (FkPattern.test(fieldTypeName)){
            const matches = fieldTypeName.match(FkPattern);
            const targetId = parseInt(matches[1]);
            const rowTable = options.tables.find(table => table.value ===targetId);
            const {fields} = rowTable;
            const filterTables = fields.map((item)=>({label:item.value, value:item.key, typeName:item.typeName}))
            setOptions((prevState)=>({...prevState,filterTables: filterTables}))
            result.HasFk = false;
            return  result;
        }
            // else if (ListPattern.test(fieldTypeName)){
            //     return  true;
        // }
        else{
            setApiName('');
            return result;
        }
    }

    const GetAllOperations=async (apiName)=>{
        try {
            if(apiName === '' || apiName === null){
                return;
            }
            setSpinner(true)
            const response = await GET(apiName,{},'Enumerable');
            if(response.status === 200){
                const data= convertSelectDataHandler(response.data)
                setOptions((prevState)=>({...prevState,values:data}));
                setSpinner(false);
            }else{
                setSpinner(false);
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError()
        }
    }





    useEffect(()=>{
        handleFetchData(props.IsOpen);
    },[props.IsOpen,refresh])


    const handleReportSubmit=async(e)=>{
        e.preventDefault();
            const isValid = validate(FilterSchema,filter,onSetErrors)
            if(!isValid){
                return;
            }

        try {
            const  ItemsAreNull = filterItems.every((item) => item.field !== null && item.operation !== null && item.value !== null)
            if(!ItemsAreNull){
                handleFailureMessage(t("FieldConditionValueIsEmpty"));
                return;
            }
            const convertedFilters = handleAddFilter(filter,filterItems);
                setSpinner(true)
                const response = await POST(`/Filters`,convertedFilters,false);
                if(response.status === 200){
                    setSpinner(false);
                    props.handleSuccess(true);
                    setFilterItems([])
                    handleSuccessMessage(t("FilterRegisteredSuccessfully"));
                    Refresh();
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                onApiError()
            }
    }







    return (
        <>

            <form onSubmit={handleReportSubmit} method={'post'} className={'relative'}>
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className={"space-y-5"}>
                    <div className={"space-y-3"}>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                            <div class="space-y-2">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">{t("FilterTitle")}</label>
                                    <DisplayError message={errors.title}/>
                                </div>
                                <div>
                                    <InputText value={filter.title} onInput={(e)=>handleValue(e)} name={'title'}  className="input w-full" />
                                </div>
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("Part")}</label>
                                <div>
                                    <Select
                                        options={options.tables}
                                        isSearchable={true}
                                        isClearable={true}
                                        onChange={(data)=>handleSelectValue(data,'table')}
                                        placeholder={t("selection")}
                                        value={filter.table}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"box bg-white dark:bg-themeDarkPostInput p-2 space-y-2 max_height20"}>
                            {filterItems.map((row,index) => (
                                <div className="post-input dark:bg-themeDarkPostInput rounded-md p-2 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-2">
                                    <div className="flex gap-1">
                                        <div className={"mt-auto"}>
                                            <span className="number_pluse text-white">{index+1}</span>
                                        </div>
                                        <div class="space-y-1 flex-1">
                                            <label className="text-xs dark:text-gray-300">{t("Operator")}</label>
                                            <Select
                                                options={options.previousOperations}
                                                isSearchable={true}
                                                isClearable={false}
                                                isDisabled={row.isDisable}
                                                onChange={(data)=>handleChange(row.id,'previousOperation',data,row.filterItemId)}
                                                placeholder={t("Operator")}
                                                value={row.previousOperation}
                                                menuPosition={'fixed'}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>

                                    <div class="space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("Field")}</label>
                                        <Select
                                            options={options.fields}
                                            isSearchable={true}
                                            isClearable={false}
                                            onChange={(data)=>handleChange(row.id,'field',data,row.filterItemId)}
                                            placeholder={t("Field")}
                                            onBlur={async () => await GetAllOperations(Apiname)}
                                            value={row.field}
                                            menuPosition={'fixed'}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>

                                    {
                                        !row.hasFk?
                                            <div class="space-y-1 ">
                                                <label className="text-xs dark:text-gray-300">کلید خارجی</label>
                                                <Select
                                                    options={options.filterTables}
                                                    isSearchable={true}
                                                    isClearable={false}
                                                    isDisabled={row.hasFk}
                                                    onChange={(data)=>handleChange(row.id,'foreignKey',data,row.filterItemId)}
                                                    placeholder={t("Table")}
                                                    value={row.tables}
                                                    menuPosition={'fixed'}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>:
                                            null

                                    }

                                    <div class="space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("Condition")}</label>
                                        <Select
                                            options={options.conditions}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={(data)=>handleChange(row.id,'operation',data,row.filterItemId)}
                                            placeholder={t("Condition")}
                                            value={row.operation}
                                            menuPosition={'fixed'}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>

                                    <div className="flex gap-2">
                                        <div className="space-y-1 flex-1">
                                            <label className="text-xs dark:text-gray-300">{t("Value")}</label>

                                            {
                                                row.isList ?
                                                    <Select
                                                        options={options.values}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={(data)=>handleChange(row.id,'value',data,row.filterItemId)}
                                                        placeholder={t("Condition")}
                                                        value={row.value}
                                                        menuPosition={'fixed'}
                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                    :

                                                    <input type="text" className="input w-full" value={row.value} onChange={(e) => handleChange(row.id, 'value', e.target.value,row.filterItemId)}/>

                                            }

                                        </div>

                                        <div className={"mt-auto flex gap-2"}>
                                            <button onClick={()=>handleAddRow()} type="button" className="button small_button bg-themeInformation text-white dark:bg-themeDarkInformation">
                                                <HPrimeIcon icon="pi-plus" />
                                            </button>
                                            <button onClick={()=>handleRemoveRow(row.id,row.filterItemId)} type="button" className="button small_button bg-themeDanger text-white">
                                                <HPrimeIcon icon="pi-minus" />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                    <button type={'submit'} className={'button w-full bg-themeInformation text-white dark:bg-themeDarkInformation'}>
                        {t('register')}
                    </button>
                </div>

            </form>
        </>
    )
}