import { useState } from "react";
export const useUserFamily = () => {
    const [userFamilyInfo, setUserFamilyInfo] = useState({
        insuranceId: null,
        insuranceMemberId: null,
        insuranceRelation: null,
        genderType: null,
        fullName: null,
        nationalCode: null,
        mobilePhoneNumber: null,
        homePhoneNumber: null,
        description: null
    })
    const handleInsuranceStartDate = (dateTime) => {
        setUserFamilyInfo({ ...userFamilyInfo, startDate: dateTime })
    }
    const handleInsuranceEndDate = (dateTime) => {
        setUserFamilyInfo({ ...userFamilyInfo, endDate: dateTime })
    }

    const handleSelectValue = (data, name) => {
        setUserFamilyInfo({ ...userFamilyInfo, [name]: data });
    }

    const handleValue = (e) => {
        const { name, value } = e.target;

        if (name === 'homePhoneNumber' || name === 'mobilePhoneNumber' || name === 'nationalCode') {
            const numericValue = value.replace(/\D/g, '');
            setUserFamilyInfo({ ...userFamilyInfo, [name]: numericValue });
        } else {
            setUserFamilyInfo({ ...userFamilyInfo, [name]: value });
        }
    };



    //.....

    return {
        userFamilyInfo,
        setUserFamilyInfo,
        handleSelectValue,
        handleValue
    }

}