import React from "react";
export const InputSwitchComponent = (props)=>{

    const handleValueSelection = (e, name) => {
        const isChecked = e.target.checked;
        props.OnValueSelection(isChecked, name);
    };

    return (
            <>
                {props.switchData.map((data, index) => (
                    <div key={index} className="space-y-2 text-center">
                        <label className="text-xs dark:text-gray-300">{data.label}</label>
                        <div>
                            <input
                                checked={data.value}
                                className="input input--switch border bg-white"
                                onChange={(e) => handleValueSelection(e,data.name)}
                                type="checkbox"
                            />
                        </div>
                    </div>
                ))}
            </>




   )
}