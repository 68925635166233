export const separateText = (text) => {
    const tag = "(tag";
    const startIndex = text.indexOf(tag);
    if (startIndex !== -1) {
        const endIndex = text.indexOf(')', startIndex);
        if (endIndex !== -1) {
            const word = text.substring(startIndex + tag.length, endIndex);
            return word;
        } else {
            console.log("No closing parenthesis found.");
        }
    } else {
        console.log("Tag not found.");
    }
}


export const handleCreateShortCode = (obj)=>{
    const newData = {
        name:`(tag${obj.name})`,
        type:obj.type === null|| obj.type === undefined?null:obj.type,
        value: obj.value === null || obj.value === undefined?null:obj.value,
        templateId:obj.templateId === null || obj.templateId === undefined?null: obj.templateId.value ,
    };
    return newData
}
export const handleEditShortCode = (obj)=>{
    const newData = {
        id:obj.id !== null?obj.id:null,
        name:`(tag${obj.name})`,
        type:obj.type === null|| obj.type === undefined?null:obj.type,
        value: obj.value === null || obj.value === undefined?null:obj.value,
        templateId:obj.templateId === null || obj.templateId === undefined?null: obj.templateId.value ,
    };
    return newData
}

