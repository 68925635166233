import React from "react";

export const useCartItems=(initialSource,setProductCartItems,productCartItems)=>{

    const handleAddRow=()=>{
        const newRow = {
            fakeId: new Date().getTime(),
            productId: initialSource.productTypes[0], // Use the default value from options
            cartItemType: initialSource.cartTypes[0],
            productTransportId:initialSource.productTransports[0],
            id:null,
            quantity: 1,
            description:null,
        };
        setProductCartItems(prevList => [...prevList, newRow]);
    }
    const handleRemoveRow = (fakeId,id) => {
        if(productCartItems.length === 1){
            const newRow = {
                fakeId: new Date().getTime(),
                id:null,
                productId: initialSource.productTypes[0], // Use the default value from options
                cartItemType: initialSource.cartTypes[0],
                productTransportId:initialSource.productTransports[0],
                quantity: 1,
                description:'',
            };
            setProductCartItems(prevList => [newRow]);
            return;
        }
        setProductCartItems((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.fakeId === fakeId && row.id === id
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0,indexToRemove),...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleChange = (fakeId, field, value,id) => {
        if (field === 'quantity' && value < 1) {
            return;
        }
        setProductCartItems((prevRelations) => {
            const updatedRelations = prevRelations.map((meta) => {
                if (
                    (id && meta.id === id) || (!id && fakeId && meta.fakeId === fakeId)
                ) {
                    return { ...meta, [field]: value };
                } else {
                    return meta;
                }
            });
            return updatedRelations;
        });
    };

    const handleResetProductCartItems=()=>{
        setProductCartItems([]);
    }


    return {
        handleAddRow,
        handleRemoveRow,
        handleChange,
        handleResetProductCartItems
    }
}