import {useTranslation} from "react-i18next";
import {useState} from "react";

export const useUserLogin=(setErrors)=>{

    const {t} = useTranslation();
    const [users, setUsers] = useState({username: null, password: null,otpCode:null,id:null});
    const handleValue = (e) => {
        const {name, value} = e.target;
        setUsers({...users, [name]: value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };
    const handleNumberValue = (value,name)=>{
        setUsers({...users,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const authenticationModes = ['none','password','otpCode','towFactor'];

    return {
        handleValue,
        users,
        setUsers,
        authenticationModes,
        handleNumberValue
    }
}