import React, {useMemo, useState} from "react";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {useTranslation} from "react-i18next";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Link} from "react-router-dom";

export const CartProduct=({rowData,GetCurrencyType})=>{
    const { t } = useTranslation();

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Orderer"),minWidth: 160,
            cellRenderer:parameters=>
                <>{parameters.data.userFullName}</>
        },
        { headerName: t("count"), maxWidth: 100,
            cellRenderer:parameters=>
                <>{parameters.data.productItemCount}</>
        },
        { headerName: t("TrackingCode"), maxWidth: 160,
            cellRenderer:parameters=>
                <>{parameters.data.trackingCode}</>
        },
        { headerName: t("TotalAmount"),minWidth: 170,
            cellRenderer:parameters=>
                <> {<PriceFormatterComponent price={parameters.data.amount.amount}/>}{GetCurrencyType(parameters.data.amount.currency)}</>
        },
        { headerName: t("TransactionState"),minWidth: 170,
            cellRenderer:parameters=>
                <>{parameters.data.enTransactionState}</>
        },
        { headerName: t("InvoiceType"),minWidth: 170, cellRenderer:parameters=> <>{parameters.data.enPriceType}</>},
        { headerName: t("ShippingStatus"),minWidth: 170, cellRenderer:parameters=> <>{parameters.data.enSendType}</>},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);



    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };




    return(
        <>
            <div class=" space-y-5 box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className={"p-2 dark:border-themeDarkColorLine post-input dark:bg-themeDarkPostInput post-input-design flex justify-between items-center"}>
                    <h2 className={"text-themeInformation"}>{t("TodaySales")}</h2>
                   <Link to={"#"} className={"text-themeInformation  dark:text-themeDarkInformation"}>({t("View")})</Link>
                </div>
                <div>
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                </div>
            </div>

        </>

    )
}