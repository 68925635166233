
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {useCustomUnConfirmed} from "./useCustomUnConfirmed";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {InputNumber} from "primereact/inputnumber";
import {handleReject, handleVerify} from "./useUnConfirmedService";
import {validate} from "../../../../Services/Globals/validate";
import {packageSchema} from "../../Packages/packageSchema";
import {unConfirmedSchemaReject, unConfirmedSchemaVerify} from "./unConfirmedSchema";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";

export const UnconfirmedRequestItem=({item,GetTransactionType,GetTransactionIcon,GetCurrencyType,GetPaymentState,GetUserBank,GetUserBankAccount,showSpinner,hideSpinner,Refresh})=>{
    const { t } = useTranslation();
    const [IsVerifyOpen,setIsVerifyOpen] = useState(false);
    const [initialSource,setInitialSource] = useState({bankAccounts:[]});
    const [errors,setErrors] = useState({});
    const {handleSelectValue,request,handleValue,setRequest,handleNumberValue}=useCustomUnConfirmed(setErrors)

    const onSetErrors=(errors)=>{
        setErrors(errors)
    }

    const handleOpenVerify=async(withdrawalWalletRequestId,isVerified)=>{
        try {
            if(isVerified){
                showSpinner();
                const response = await GET(`/Accountings/BankAccounts/Selection`,{},'IEnumerable');
                if(response.status === 200){
                    const bankAccounts = convertSelectDataHandler(response.data);
                    setInitialSource({
                        bankAccounts: bankAccounts
                    })
                    setRequest(prevState => ({...prevState,IsVerified: isVerified, bankAccountId:bankAccounts[0],withdrawalWalletRequestId: withdrawalWalletRequestId,transactionCode: null,description:''}));
                    setIsVerifyOpen(true);
                    hideSpinner();
                }
                else{
                    handleApiError(response,hideSpinner);
                }
            }
            else{
                setRequest(prevState => ({...prevState,IsVerified: isVerified,withdrawalWalletRequestId: withdrawalWalletRequestId,transactionCode: '',description:''}));
                setIsVerifyOpen(true);
            }
        }
        catch (e) {
            hideSpinner();
            console.log(e);
        }
    }
    const handleCloseVerify=()=>{
        setIsVerifyOpen(false);
        setRequest({bankAccountId: initialSource.bankAccounts[0],IsVerified: false,transactionCode:'',description: '',withdrawalWalletRequestId:null});
        setErrors({})
    }
    useEffect(()=>{
        setRequest({IsVerified: false});
    },[])
    const handleVerifyRequest=async(e)=>{
        e.preventDefault();
        try {
            if(request.IsVerified){
                const isValid = validate(unConfirmedSchemaVerify,request,onSetErrors);
                if (!isValid) {
                    return;
                }
                showSpinner();
                const verifiedRequest = handleVerify(request);
                const response = await POST(`/Wallets/Withdraw/Verify`,verifiedRequest,false);
                if(response.status === 200){
                       Refresh();
                       handleSuccessMessage(t('SuccessDone'))
                }
                else {
                    handleApiError(response,hideSpinner)
                }
            }
            else{
                const isValid = validate(unConfirmedSchemaReject,request,onSetErrors);
                if (!isValid) {
                    return;
                }
                const rejectedRequest = handleReject(request);
                const response = await POST(`/Wallets/Withdraw/Verify`,rejectedRequest,false);
                if(response.status === 200){
                    Refresh();
                    handleSuccessMessage(t('SuccessDone'))
                }
                else {
                    handleApiError(response,hideSpinner)
                }
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    const Verify=()=>{
        return (<>

            <div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("BankAccount")}</label>
                        <Select
                            options={initialSource.bankAccounts}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(data) => handleSelectValue(data, 'bankAccountId')}
                            placeholder={t("selection")}
                            value={request.bankAccountId}
                            menuPosition={'fixed'}
                            classNamePrefix={"h_multi_select"}
                        />
                    </div>
                    <div class="space-y-1">
                        <div className={"flex justify-between items-center gap-2"}>
                            <label className="text-xs dark:text-gray-300">
                                {t("ReceiptNumber")}
                                <RequiredIcon/>
                            </label>
                            <DisplayError message={errors.transactionCode}/>
                        </div>

                        <InputNumber type="text" min={0} useGrouping={false} value={request.transactionCode} onChange={(e)=>handleNumberValue(e.value,'transactionCode')}/>
                    </div>
                </div>
            </div>
        </>)
    }
    const Reject=()=>{
        return (<>
            <div class="space-y-1">
                <div className={"flex justify-between items-center gap-2"}>
                    <label className="text-xs dark:text-gray-300">
                        {t("description")}
                        <RequiredIcon/>
                    </label>
                    <DisplayError message={errors.description}/>
                </div>

                <InputText type="text"  value={request.description} name={'description'} onInput={(e)=>handleValue(e)}/>
            </div>

        </>)
    }

    return (
        <>
            <div className="rounded-md post-input dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine">
                <div className={"p-2 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md"}>
                    <h2 className={"dark:text-gray-300"}>{t("AwaitingConfirmation")}</h2>
                </div>
                <div>
                    <ul className={"grid grid-cols-1 xl:grid-cols-2 gap-5 p-2"}>
                        <li className={" box bg-white dark:bg-themeDarkPostInput  rounded-md overflow-hidden"}>
                            <div className={"flex flex-col lg:flex-row h-full "}>
                                <div className={"flex-shrink-0 bg-themeSecondary h-12 w-full grid place-content-center lg:w-12 lg:h-full"}>
                                    <HPrimeIcon icon={"pi-clock"} iconClass="text-white"/>
                                </div>
                                <div className={"flex-1 grid grid-cols-1 md:grid-cols-2  border dark:border-themeDarkColorLine "}>
                                    <div className={" flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>{item.userFullName}</p>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("BankAccountNumber")}:</p>
                                        <span className={"line-clamp-1 text-xs  dark:text-gray-300"}>{item.userAccountNumber}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("cardNumber")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{item.userCardNumber}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("BankID")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300 "}>{item.userShebaNumber}</span>
                                    </div>

                                    <div className={"flex gap-2 flex-col md:flex-row border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionNumber")}:</p>
                                        <span className={"line-clamp-1 text-xs  dark:text-gray-300"}>{item.trackingCode}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionDate")}:</p>
                                        <span className={"text-xs dark:text-gray-300"}>{<GridDateFomartter date={item.creationDate} hasTime={true}/>}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionType")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{t('Withdrawal')}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row items-center gap-2 border p-1 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionAmount")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}><PriceFormatterComponent price={item.amount.amount}/> {GetCurrencyType(item.amount.currency)}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row items-center gap-2 border p-1 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("Status")}:</p>
                                        <span className={`line-clamp-1 text-xs  text-themeSecondary`}>{GetPaymentState(item.paymentStatus)}</span>
                                    </div>
                                    <div className={"flex gap-2 border px-1 py-2 dark:border-themeDarkColorLine justify-end"}>
                                        <button onClick={()=>handleOpenVerify(item.withdrawalWalletRequestId,false)} type={"button"} className={" p-2 text-xs bg-themeDanger dark:bg-themeDarkInformation text-white w-full rounded flex place-content-center gap-2"}>
                                            <HPrimeIconSmall
                                            icon={"pi-times"}
                                            />

                                            {t("Cancel")}
                                        </button>

                                        <button onClick={()=>handleOpenVerify(item.withdrawalWalletRequestId,true)} type={"button"} className={" p-2 text-xs bg-themeInformation dark:bg-themeDarkInformation text-white w-full rounded flex place-content-center gap-2"}>
                                            <HPrimeIconSmall icon={"pi-check-circle"} />
                                            {t("WithdrawalConfirmation")}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>


            <FantasyModal  openModal={IsVerifyOpen}  closeModal={handleCloseVerify}  title={t("Fastsettings")} >
                <form method="post" onSubmit={handleVerifyRequest} className="relative">
                    {
                        request.IsVerified?
                            Verify()
                            :
                            Reject()
                    }
                    <div className="flex justify-between items-center">
                        <button className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                    </div>
                </form>
            </FantasyModal>

        </>
    )
}