import React, {useState} from "react";
import {LoadingSpinner} from "../../Commans/Loadings/LoadingSpinner";
import Countdown from "react-countdown";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {InputOtp} from "primereact/inputotp";
import {useTranslation} from "react-i18next";
import {validate} from "../../../Services/Globals/validate";
import {codeSchema} from "../../Authentication/ForgetPassword/forgetPasswordSchema";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {useUserLogin} from "./useUserLogin";
import {useNavigate} from "react-router-dom";

export const OtpAuthentication=()=>{


    const [IsPlay,setIsPlay] = useState(true);
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const redirectTo = useNavigate();
    const {setUsers,users,handleValue,handleNumberValue}=useUserLogin(setErrors)

    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const ValidateSubmitOtp = async (e) => {
        e.preventDefault();
    };
    const ResendCode = async () => {
        try {
            setIsPlay(true)
        }catch (e) {

        }
    };


    const renderer = ({ hours, minutes, seconds, completed }) => {
        if(completed){
            setIsPlay(false)
        }
        else{
            return <span className={'text-themeDanger'}>{minutes}:{seconds}</span>;
        }
    };
    return (
        <form method={'post'} onSubmit={ValidateSubmitOtp} className="space-y-2 relative">
            {IsShowSpinner ? <LoadingSpinner/> : null}

            <div className=" p-2 flex justify-between items-center ">
                <div className="flex justify-items-start">
                    <h2 className="text-gray-700 ">
                        {t('Enter_Your_Code')}
                    </h2>
                </div>
                <div>
                    {
                        IsPlay?
                            <Countdown
                                date={Date.now() + 50000}
                                renderer={renderer}
                            />
                            :
                            <span className="cursor-pointer text-themeSuccess text-x" onClick={async()=> await ResendCode()}>{t('Resend')}</span>
                    }

                </div>
            </div>

            <div className="space-y-5">
                <DisplayError message={errors.otpCode}/>

                <div className={'H-direction-ltr'}>
                    <InputOtp length={5}  onChange={(e)=>handleNumberValue(e.value.toString(),'otpCode')} name={'otpCode'} value={users.otpCode}  integerOnly/>
                </div>

                <div className="flex gap-2">
                    <button className="button w-full  user_login_gradiant text-white" type="submit">
                        {t('codeConfirmtion')}
                    </button>
                </div>
            </div>


        </form>
    )
}