import React, {useState} from "react";

export const useOrganizationGroup=(setErrors)=>{

    const [organizationGroup,setOrganizationGroup]=useState({linkOrganizationGroupId:null,caption:null,rank:0,icon:null,iconPath:null});
    const handleValueOrganizationGroup=(e)=>{
        const {name,value} = e.target;
        setOrganizationGroup({...organizationGroup,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValueOrganizationGroup=(value,name)=>{
        setOrganizationGroup({...organizationGroup,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }


    const handleRemoveImagePath=(path,name)=>setOrganizationGroup((prevState)=>({...prevState,[name]:path}))
    const handleResetImages = () => {
        setOrganizationGroup((prevState)=>({...prevState,icon: null, iconPath: null}));
    }
    const handleChangeImage =(data,name)=>{
        setOrganizationGroup((prevState) => ({ ...prevState, [name]: data }));
    }



    return {
        handleValueOrganizationGroup,
        handleNumberValueOrganizationGroup,
        organizationGroup,
        setOrganizationGroup,
        handleRemoveImagePath,
        handleResetImages,
        handleChangeImage
    }
}