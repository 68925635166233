import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../Components/Commans/UIParts/FantasyModal";
import {ListSearch} from "../../Components/Commans/Grid/ListSearch";
import {ListFilter} from "../../Components/Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";

import {ButtonSearchList} from "../../Components/Commans/UIParts/ButtonSearchList";
import Select from "react-select";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import {useMailAccount} from "./useMailAccount";
import {convertSelectDataHandler} from "../CMS/Contents/ContentService";
import {GET, POST, PUT} from "../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../Services/Globals/Errors/handleApiError";
import {TinyLoading} from "../Commans/Loadings/TinyLoading";
import {InputNumber} from "primereact/inputnumber";
import {InputSwitch} from "../Commans/Filters/InputSwitch";
import {handleCreateMailAccount, handleEditMailAccount} from "./mailAccountService";
import {handleSuccessMessage} from "../../Services/Globals/Errors/NotificationService";
import {useMailAccountGrid} from "./useMailAccountGrid";
import {GridTable} from "../Commans/Grid/GridTable";
import {GridSetting} from "../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../Services/Globals/Translations/Resources";
import {Pagination} from "../Commans/Grid/Pagination";
import {LoadingHarmony} from "../Commans/Loadings/LoadingHarmony";
import {GoogleIcon} from "../HIcon/GoogleIcon";
import {YahooIcon} from "../HIcon/YahooIcon";
import {OutlookIcon} from "../HIcon/OutlookIcon";
import {MailIcon} from "../HIcon/MailIcon";
export const HandleAddMailAccount = ()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsOpen,setIsOpen] = useState(false);
    const [errors,setErrors]=useState(false);
    const [initialSource,setInitialSource]=useState({encryptionMethods:[],connectionTypes:[],});
    const [IsEdit,setIsEdit]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [IsLoading,setIsLoading]=useState(false);
    const {mailAccount,setMailAccount,handleSelectValue, handleNumberValue,handleValue}=useMailAccount(setErrors);

    const showLoading=()=>{
        setIsLoading(true);
    }
    const hideLoading=()=>{
        setIsLoading(false);
    }

    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const handleResetErrors=()=>{
        setErrors({});
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const handleOpenEmail = () => {
        setMailAccount((prevState)=>({...prevState,connectionType: initialSource.connectionTypes[0],email: '',password: '',incomingServer: '',incomingEncryptionMethod: initialSource.encryptionMethods[0],incomingPort: 0,isDefault: true,outgoingEncryptionMethod: initialSource.encryptionMethods[0],outgoingPort: 0,outgoingServer: ''}))
        setIsOpen(true);
    }
    const handleCloseEmail = ()=>{
        setMailAccount((prevState)=>({...prevState,connectionType: initialSource.connectionTypes[0],email: '',password: '',incomingServer: '',incomingEncryptionMethod: initialSource.encryptionMethods[0],incomingPort: 0,isDefault: true,outgoingEncryptionMethod: initialSource.encryptionMethods[0],outgoingPort: 0,outgoingServer: ''}))
        handleResetErrors();
        setIsOpen(false);
        setIsEdit(false);
    }

    const handleInitialSource=(encryptionMethods,connectionTypes)=>{
        const encryptionMethodsData = convertSelectDataHandler(encryptionMethods)
        const connectionTypesData = convertSelectDataHandler(connectionTypes)
        setInitialSource({
            encryptionMethods: encryptionMethodsData,
            connectionTypes: connectionTypesData,
        })
        return {
            encryptionMethodsData,
            connectionTypesData,
        }
    }
    const fetchData = async () => {
        try {
            showSpinner()
            const [responseEncryption,responseConnection] = await Promise.all([
                GET('/Enumerations/EncryptionMethod', {}, 'IEnumerable'),
                GET('/Enumerations/EnConnectionType', {}, 'IEnumerable'),
            ]);
            if (responseEncryption.status === 200 && responseConnection.status === 200)
            {
                const {connectionTypesData,encryptionMethodsData} =
                    handleInitialSource(responseEncryption.data,responseConnection.data);

            }
            else {
                handleApiError(responseEncryption,hideSpinner);
                handleApiError(responseConnection,hideSpinner);

            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };


    useEffect(()=>{
        fetchData()
    },[refresh])

    const handleSubmitMailServer=async(e)=>{
        e.preventDefault();
        try {
           if(IsEdit){
               showLoading();
               const emailData = handleEditMailAccount(mailAccount);
               const response = await PUT(`/MailAccounts`,emailData,false);
               if(response.status === 200){
                   hideLoading();
                   Refresh();
                   handleSuccessMessage(t('SuccessDone'));
                   handleCloseEmail();
               }
               else{
                   handleApiError(response,onSetErrors);
               }
           }
           else{
               showLoading();
               const emailData = handleCreateMailAccount(mailAccount);
               const response = await POST(`/MailAccounts`,emailData,false);
               if(response.status === 200){
                   hideLoading();
                   Refresh();
                   handleSuccessMessage(t('SuccessDone'));
                   handleCloseEmail();
               }
               else{
                   handleApiError(response,onSetErrors);
               }
           }
        }
        catch (e) {
            hideLoading();
        }
    }


    const {handleDeleteMailAccount,handleGetMailAccount, handleSearch, columnDefs, autoGroupColumnDef,defaultColDef,handleCurrentPage, handleNextPage,handlePageSizeCount,handlePreviousPage,handleResetFilters,filter,setFilter,rowData,getRowHeight,pagination}=useMailAccountGrid(showSpinner,hideSpinner,setIsOpen,setIsEdit,refresh,Refresh,initialSource,setMailAccount,mailAccount)





    return (
        <div class=" space-y-5 relative">
            {
                IsShowSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("SearchByAddress")}</label>
                        <InputText type={"text"} className={" input w-full"} />
                    </div>
                    <ButtonSearchList />
                </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => handleOpenEmail()}
                                        tooltipTitle={t("AddEmail")}
                                    />

                                    <ButtonPlus
                                        title={t("Email")}
                                        icon={"pi-send"}
                                        onClick={()=>redirectTo("/EmailMarketing/Compose")}
                                        tooltipTitle={t("SendEmail")}
                                    />

                                    <ButtonPlus
                                        title={t("Draft")}
                                        icon={"pi-file-edit"}
                                        onClick={()=>redirectTo("/EmailMarketing/Draft")}
                                        tooltipTitle={t("Draft")}
                                    />

                                    <ButtonPlus
                                        title={t("sending")}
                                        icon={"pi-inbox"}
                                        onClick={()=>redirectTo("/EmailMarketing/SendBox")}
                                        tooltipTitle={t("sendingBox")}
                                    />

                                    <ButtonPlus
                                        title={t("Receive")}
                                        icon={"pi-inbox"}
                                        onClick={()=>redirectTo("/EmailMarketing/Inbox")}
                                        tooltipTitle={t("receivingBox")}
                                    />

                                    <ButtonPlus
                                        title={t("trash")}
                                        icon={"pi-trash"}
                                        onClick={()=>redirectTo("/EmailMarketing/Trash")}
                                        tooltipTitle={t("trash")}
                                    />


                                </>
                            }
                            childrenTwo={
                                <>
                                    <div class=" space-y-2">
                                        <lable className="text-xs dark:text-gray-300">{t("IMAP")}</lable>
                                        <div>
                                            <input className="input input--switch" type="checkbox"/>
                                        </div>
                                    </div>
                                    <div class="space-y-2">
                                        <lable className="text-xs dark:text-gray-300">{t("POP")}</lable>
                                        <div>
                                            <input className="input input--switch " type="checkbox"/>
                                        </div>
                                    </div>

                                </>
                            }
                />


            </div>
            <div className={"H-body-ag-grid space-y-2 "}>

                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridEnglish}
                />
                <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
            </div>

            <FantasyModal openModal={IsOpen} closeModal={handleCloseEmail} title={t("AddEmail")} customeClassName={"modal-x"}>
                <div className={"grid grid-cols-1"}>
                    <div className={"bg-white dark:bg-themeDarkSalt700 border p-3 shadow dark:border-themeDarkColorLine space-y-3"}>
                        <div className={"flex gap-3 items-center flex-wrap"}>
                            <button type={"button"} className={"button zoom-in"}>
                                <GoogleIcon
                                    sizeStyle={"size-16"}
                                />
                            </button>
                            <button type={"button"} className={"button zoom-in"}>
                                <YahooIcon
                                    sizeStyle={"size-16"}
                                />
                            </button>
                            <button type={"button"} className={"button zoom-in"}>
                                <OutlookIcon
                                    sizeStyle={"size-16"}
                                />
                            </button>
                            <button type={"button"} className={"button zoom-in"}>
                                <MailIcon
                                    sizeStyle={"size-16"}
                                />
                            </button>
                        </div>
                    </div>

                </div>
               <form onSubmit={handleSubmitMailServer} method={'post'}>
                   <div className={"space-y-5 py-2"}>
                       <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                           <div className={"bg-white dark:bg-themeDarkSalt700 border p-2 shadow dark:border-themeDarkColorLine space-y-3"}>
                               <div className={"p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                                   <h2 className={"text-xs text-center dark:text-gray-300"}>{t("IncomingServer")}</h2>
                               </div>
                               <div className={"px-2 space-y-2"}>
                                   <div class="space-y-1">
                                       <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                                       <InputText name={'Name'}  className={"input"}/>
                                   </div>
                                   <div class="space-y-1">
                                       <label className="text-xs dark:text-gray-300">{t("CommunicationType")}</label>
                                       <Select
                                           options={initialSource.connectionTypes}
                                           isMulti={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={(data)=>handleSelectValue(data,'connectionType')}
                                           name={'type'}
                                           placeholder={t("selection")}
                                           value={mailAccount.connectionType}
                                           className={"select-group"}
                                           classNamePrefix={"h_multi_select"}
                                           menuPosition={'fixed'}
                                       />
                                   </div>

                                   <div class="space-y-1">
                                       <label className="text-xs dark:text-gray-300">{t("EmailAddress")}</label>
                                       <InputText name={'email'} value={mailAccount.email} onInput={(e)=>handleValue(e)} className={"input"}/>
                                   </div>
                                   <div class="space-y-1">
                                       <label className="text-xs dark:text-gray-300">{t("Password")}</label>
                                       <InputText name={'password'} value={mailAccount.password} onInput={(e)=>handleValue(e)} className={"input"}/>
                                   </div>
                                   <div className={"flex items-center gap-2"}>
                                       <div class="space-y-1 flex-1">
                                           <label className="text-xs dark:text-gray-300">{t("server")}</label>
                                           <InputText name={'incomingServer'}  onInput={(e)=>handleValue(e)} value={mailAccount.incomingServer} className={"input"}/>
                                       </div>
                                       <div class="space-y-1 flex-shrink-0 w-12">
                                           <label className="text-xs dark:text-gray-300">{t("Port")}</label>
                                           <InputNumber value={mailAccount.incomingPort} onChange={(e)=>handleNumberValue(e.value,'incomingPort')} name={'incomingPort'} />
                                       </div>
                                       <div class="space-y-1 flex-shrink-0 w-12">
                                           <InputSwitch value={mailAccount.isDefault} OnValueSelection={handleSelectValue} lable={t("isDefault")} name={'isDefault'}/>
                                       </div>

                                   </div>
                                   <div class="space-y-1">
                                       <label className="text-xs dark:text-gray-300">{t("EncryptionMethod")}</label>
                                       <Select
                                           options={initialSource.encryptionMethods}
                                           isMulti={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={(data)=>handleSelectValue(data,'incomingEncryptionMethod')}
                                           name={'type'}
                                           placeholder={t("selection")}
                                           value={mailAccount.incomingEncryptionMethod}
                                           className={"select-group"}
                                           classNamePrefix={"h_multi_select"}
                                           menuPosition={'fixed'}
                                       />
                                   </div>
                               </div>
                           </div>
                           <div className={"bg-white dark:bg-themeDarkSalt700 border p-2 shadow dark:border-themeDarkColorLine space-y-3"}>
                               <div className={"p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                                   <h2 className={"text-xs text-center dark:text-gray-300"}>{t("OutgoingServer")}</h2>
                               </div>
                               <div className={"px-2 space-y-2"}>
                                   <div className={"flex items-center gap-2"}>
                                       <div class="space-y-1 flex-1">
                                           <label className="text-xs dark:text-gray-300">{t("server")}</label>
                                           <InputText name={'outgoingServer'} onInput={(e)=>handleValue(e)} value={mailAccount.outgoingServer} className={"input"}/>
                                       </div>
                                       <div class="space-y-1 flex-shrink-0 w-12">
                                           <label className="text-xs dark:text-gray-300">{t("Port")}</label>
                                           <InputNumber value={mailAccount.outgoingPort} onChange={(e)=>handleNumberValue(e.value,'outgoingPort')} name={'incomingPort'} />
                                       </div>
                                   </div>
                                   <div class="space-y-1">
                                       <label className="text-xs dark:text-gray-300">{t("EncryptionMethod")}</label>
                                       <Select
                                           options={initialSource.encryptionMethods}
                                           isMulti={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={(data)=>handleSelectValue(data,'outgoingEncryptionMethod')}
                                           name={'type'}
                                           placeholder={t("selection")}
                                           value={mailAccount.outgoingEncryptionMethod}
                                           className={"select-group"}
                                           classNamePrefix={"h_multi_select"}
                                           menuPosition={'fixed'}
                                       />
                                   </div>
                                   <div>
                                       <button type={'button'}  className="button text-white w-full bg-themeInformation dark:bg-themeDarkInformation">
                                           تست اتصال
                                       </button>
                                   </div>

                               </div>
                           </div>
                       </div>
                       <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation place-content-center text-white relative"} >
                           {t("insertion")}
                           {IsLoading ? (
                               <TinyLoading
                               />
                           ) : (
                               null
                           )}
                       </button>
                   </div>
               </form>
            </FantasyModal>
        </div>
    )
}