const handleGetIsApproved=(roadMap)=>{
    if(roadMap.parentId === null){
        return false;
    }
    else{
        return roadMap.isApproved === null || roadMap.isApproved === undefined?null:roadMap.isApproved.value
    }
}
export const handleCreateRoadMapTree=(roadMap,roadMapId)=>{
    const data = {
        roadMapId:roadMapId,
        title:roadMap.title === roadMap.title === undefined?null:roadMap.title,
        description:roadMap.description === roadMap.description === undefined?null:roadMap.description,
        operation:roadMap.operation === null || roadMap.operation === undefined?null:roadMap.operation.value,
        access:roadMap.access === null || roadMap.access === undefined?null:roadMap.access.value,
        personEntity:roadMap.personEntity === null || roadMap.personEntity === undefined?null:roadMap.personEntity.value,
        personEntityId:roadMap.personEntityId === null || roadMap.personEntityId === undefined?null:roadMap.personEntityId.value,
        isApproved:handleGetIsApproved(roadMap),
        parentId:roadMap.parentId === null || roadMap.parentId === undefined?null:roadMap.parentId.value
    }
    return data
}
export const handleEditRoadMapTree=(roadMap)=>{
    const data = {
        roadMapStepId:roadMap.roadMapStepId,
        title:roadMap.title === roadMap.title === undefined?null:roadMap.title,
        description:roadMap.description === roadMap.description === undefined?null:roadMap.description,
        operation:roadMap.operation === null || roadMap.operation === undefined?null:roadMap.operation.value,
        access:roadMap.access === null || roadMap.access === undefined?null:roadMap.access.value,
        personEntity:roadMap.personEntity === null || roadMap.personEntity === undefined?null:roadMap.personEntity.value,
        personEntityId:roadMap.personEntityId === null || roadMap.personEntityId === undefined?null:roadMap.personEntityId.value,
        isApproved:handleGetIsApproved(roadMap),
        parentId:roadMap.parentId === null || roadMap.parentId === undefined?null:roadMap.parentId.value
    }
    return data
}




export const handleConvertRoadMapTree = (data) => {
    const tree = [];
    const map = data.reduce((acc, obj) => {
        obj.children = [];
        acc[obj.roadMapStepId] = obj;
        obj.expanded = true;
        obj.type = obj.personEntity === 0?'user':obj.personEntity === 2?'userGroup':obj.personEntity === 3?'role':null;
        obj.data = obj.personEntityObject;
        obj.className = '';
        obj.lineRight=true
        return acc;
    }, {});
    data.forEach((obj) => {
        const parent = map[obj.parentId];
        if (parent) {
            parent.children.push(obj);
        }
        else {
            tree.push(obj);
        }
    });
    return tree;
};

export const handleExpandAllNodes = (nodes,value) => {
    return nodes.map(node => {
        node.expanded = value;
        if (node.children && node.children.length > 0) {
            node.children = handleExpandAllNodes(node.children);
        }
        return node;
    });
};