import React, {useState} from "react";

export const useScript=()=>{
    const [code,setCode] = useState({header:null,body:null,concurrencyStamp:null});
    const handleEditorChange = (data,name) => {
        setCode((prevState)=>({...prevState,[name]:data}));
    };
    const options = {
        autoIndent: 'full',
        contextmenu: true,
        fontFamily: 'monospace',
        fontSize: 13,
        lineHeight: 24,
        hideCursorInOverviewRuler: true,
        matchBrackets: 'always',
        minimap: {
            enabled: true,
        },
        scrollbar: {
            horizontalSliderSize: 4,
            verticalSliderSize: 18,
        },
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,

    };

    return {
        code,
        setCode,
        handleEditorChange,
        options
    }
}