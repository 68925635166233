export const ipMask = ()=>{
    const mask = [/\d/,
        /\d?/,
        /\d?/,
        '.',
        /\d/,
        /\d?/,
        /\d?/,
        '.',
        /\d/,
        /\d?/,
        /\d?/,
        '.',
        /\d/,
        /\d?/,
        /\d?/
        , /^(?!0)(?!.*\.[0]{1,3}\.)(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|[1-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|[0-9])$/];
    return mask;
}
export const regexSetting = {
    Ip:/^(?!0)(?!.*\.[0]{1,3}\.)(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|[1-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|[0-9])$/
}

export const validDomainPattern = /^(?!:\/\/)(?!www\.)([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}$/;
export const IsValidUrlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/
