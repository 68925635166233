import React, { useState, useEffect } from "react";

import { GET } from "../../../../../Services/AxiosService/AxiosApiService";
import { convertSelectDataHandler } from "../../../../CMS/Contents/ContentService";
import { handleApiError } from "../../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const LocationElement = ({
                                    index,
                                    element,
                                    selectedElementIndex,
                                    handlePropertyChange,
                                    handleSelectTabAfterElementSelection,
                                    deleteElement,
                                    SortDescending,
                                    SortAccending,
                                    itemCount,
    handleDefaultValueChange
                                }) => {
    // const [localCountries, setLocalCountries] = useState([]);
    // const [localStates, setLocalStates] = useState([]);
    // const [localCities, setLocalCities] = useState([]);

    const showSpinner = () => {
        console.log("Spinner shown");
    };

    const {t} = useTranslation();

    const hideSpinner = () => {
        console.log("Spinner hidden");
    };

    useEffect(() => {
        // Fetch countries when the component mounts
        fetchCountries();
    }, []);

    const fetchCountries = async () => {
        try {
            showSpinner();

            // Always fetch countries
            const response = await GET(`/Countries/Selection`, {}, "IEnumerable");

            let statesData = [];
            let citiesData = [];

            // Fetch states if stateProvinceId is not null
            if (element?.properties?.defaultValue?.stateProvinceId) {
                const responseStates = await GET(`/Countries/${element.properties.defaultValue.countryId}/StateProvinces/Selection`);
                if (responseStates.status === 200) {
                    statesData = convertSelectDataHandler(responseStates.data);
                } else {
                    handleApiError(responseStates);
                }
            }

            // Fetch cities if cityId is not null
            if (element?.properties?.defaultValue?.cityId) {
                const responseCities = await GET(`/Countries/${element.properties.defaultValue.stateProvinceId}/Cities/Selection`);
                if (responseCities.status === 200) {
                    citiesData = convertSelectDataHandler(responseCities.data);
                } else {
                    handleApiError(responseCities);
                }
            }
            // Process countries data if the response was successful
            if (response.status === 200) {
                const countryData = convertSelectDataHandler(response.data);
                handlePropertyChange(index, "countries", countryData);
                handlePropertyChange(index, "states", statesData);
                handlePropertyChange(index, "cities", citiesData);
            } else {
                handleApiError(response);
            }

        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    const fetchStates = async (countryId) => {
        try {
            showSpinner();

            const response = await GET(`/Countries/${countryId}/StateProvinces/Selection`, {}, "IEnumerable");
            const stateData = convertSelectDataHandler(response.data);
            if (response.status === 200) {
                // setLocalStates(stateData);
                handlePropertyChange(index, "states", stateData);
                handlePropertyChange(index, "cities", []); // Clear cities when a new state is selected
            } else {
                handleApiError(response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    const fetchCities = async (stateId) => {
        try {
            showSpinner();
            const response = await GET(
                `/Countries/${stateId}/Cities/Selection`,
                {},
                "IEnumerable"
            );
            const cityData = convertSelectDataHandler(response.data);
            if (response.status === 200) {
                // setLocalCities(cityData);
                handlePropertyChange(index, "cities", cityData);
            } else {
                handleApiError(response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    const handleCountryChange = async (event) => {
        const countryId = event.target.value;
        handleDefaultValueChange(index, "countryId", countryId);
        handleDefaultValueChange(index, "stateProvinceId", null);
        handleDefaultValueChange(index, "cityId", null);
        if (countryId) {
            await fetchStates(countryId);
        } else {
            // setLocalStates([]);
            // setLocalCities([]);
            handlePropertyChange(index, "states", []);
            handlePropertyChange(index, "cities", []);
        }
    };

    const handleStateChange = async (event) => {
        const stateId = event.target.value;
        handleDefaultValueChange(index, "stateProvinceId", stateId);
        handleDefaultValueChange(index, "cityId", null);
        if (stateId) {
            await fetchCities(stateId);
        } else {
            // setLocalCities([]);
            handlePropertyChange(index, "cities", []);
        }
    };

    const handleCityChange = (event) => {
        const cityId = event.target.value;
        handleDefaultValueChange(index, "cityId", cityId);
    };

    return (
        <>
            <div className={`harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-3 harmony-gap-2 ${
                    index === selectedElementIndex ? 'border-themeInformation' : ''
                }`}
                onClick={() => handleSelectTabAfterElementSelection(index)}
            >
                <div className="harmony-space-y-1">
                    <label className="dark:text-gray-300 harmony-text-xs">{t("country")}</label>
                    <select
                        value={element?.properties?.defaultValue?.countryId || ""}
                        onChange={handleCountryChange}
                        disabled={element?.properties?.isReadOnly}
                        className="harmony-input harmony-w-full"
                        id={element.properties.id +'-'+'country'}
                        name={element?.properties?.name + 'country'}
                        required={element?.properties?.isRequired}
                        placeholder={element?.properties?.description}

                    >
                        <option value="" disabled>
                            {t("SelectCountry")}
                        </option>
                        {element.properties.countries.map((country) => (
                            <option key={country.value} value={country.value}>
                                {country.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="harmony-space-y-1">
                    <label className="dark:text-gray-300 harmony-text-xs">{t("province")}</label>
                    <select
                        value={element?.properties?.defaultValue?.stateProvinceId || ""}
                        onChange={handleStateChange}
                        disabled={element?.properties?.isReadOnly}
                        className="harmony-input harmony-w-full"
                        id={element.properties.id +'-'+'province'}
                        name={element?.properties?.name + 'province'}
                        required={element?.properties?.isRequired}
                        placeholder={element?.properties?.description}
                    >
                        <option value="" disabled>
                            {t("SelectProvince")}
                        </option>
                        {element.properties.states.map((state) => (
                            <option key={state.value} value={state.value}>
                                {state.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="harmony-space-y-1">
                    <label className="dark:text-gray-300 harmony-text-xs">{t("city")}</label>
                    <select
                        value={element?.properties?.defaultValue?.cityId || ""}
                        onChange={handleCityChange}
                        disabled={element?.properties?.isReadOnly}
                        name={element?.properties?.name + 'city'}
                        className="harmony-input harmony-w-full"
                        placeholder={element?.properties?.description}
                        required={element?.properties?.isRequired}
                        id={element.properties.id +'-'+'city'}
                    >
                        <option value="" disabled>
                            {t("SelectCity")}
                        </option>
                        {element.properties.cities.map((city) => (
                            <option key={city.value} value={city.value}>
                                {city.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>


            <div className={"harmony_body_button"}>
                <button
                    type="button"
                    className="button bg-themeDanger text-white harmony_button_form"
                    onClick={() => deleteElement(index)}
                >
                    <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
                </button>
                {index === 0 ? null : (
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortAccending(index)}
                    >
                        <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                    </button>
                )}


                {index < itemCount - 1 ? (
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortDescending(index)}
                    >
                        <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                    </button>
                ) : null}

            </div>


        </>
    );
};
