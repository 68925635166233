import React, {useState} from "react";
export const useCustomCartItem=(setErrors)=>{
     const [cart,setCart] = useState({id:null,userId:null,priceType:null,creationDate:null})
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setCart({...cart,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setCart({...cart,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setCart({...cart,[name]:data});
    }
    const handleCreateDateTime=(dateTime)=>{
        setCart({...cart,creationDate: dateTime});
    }


    return {
        cart,
        setCart,
        handleValue,
        handleNumberValue,
        handleSelectValue,
        handleCreateDateTime
    }
}