import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DELETE, GET, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {AlertSetting, GridSetting} from "../../../../../Services/Globals/Public/AppSetting";

import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const useSecurityGrid=(showSpinner,hideSpinner,setSecurity,refresh,Refresh,setInitialSource)=>{

    const {t} = useTranslation();
    const [rowData, setRowData] = useState([]);


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("AuthorizedIPAddresses"),
            cellRenderer:parameters=>
                <>
                {parameters.data.ip}
                </>
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button className="text-themeDanger" ><HPrimeIcon  icon={'pi-trash'}  /></button>
                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }

    const handleResetFilters = ()=>{
        setFilter(({...filter,search: ''}));
        setSearch('');
    }


    useEffect(() => {


        OnGridReady();

    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize]);

    const OnGridReady = async () => {
        try {
            const response =  await GET(`/Settings/UpanelSecurity`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search}, 'single');
            const responseAuthMode = await  GET(`/Enumerations/EnAuthenticationMode`, {}, 'IEnumerable');

            if (response.data.blockIps.items !== null && responseAuthMode.status === 200) {
                setPagination({...pagination, totalPages: response.data.blockIps.totalPages, hasNextPage: response.data.blockIps.hasNextPage, hasPreviousPage: response.data.blockIps.hasPreviousPage, page: response.data.blockIps.page, pageSize: response.data.blockIps.pageSize, totalCount: response.data.blockIps.totalCount});
                var idSequence = 1;
                const modifiedItems = response.data.blockIps.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
               const authenticationModesData =  convertSelectDataHandler(responseAuthMode.data)
                setInitialSource({
                    authenticationModes:authenticationModesData
                })
                setSecurity((prevState) => {
                    const authMode = authenticationModesData ? authenticationModesData.find((item) => item.value === response.data.authenticationMode) : null;
                    return {
                        ...prevState,
                        authenticationMode: authMode ?? null,
                    };
                });
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            hideSpinner()
        } finally {
            hideSpinner()
        }
    };





    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
    }
}