import React, {useState} from "react";

import {InputTextarea} from "primereact/inputtextarea";
import {TextEditor} from "../../Commans/UIParts/TextEditor";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {TabMenu} from "../../Commans/UIParts/TabMenu";
import {FileUploader} from "../../Commans/Uploaders/FileUploader";


export const HandleAddRoom = () => {

    const [activeTab, setActiveTab] = useState("tab_one");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
    const tabs = [
        {
            id: "tab_one",
            label: "توضیحات",
            icon: "file-text",
        },
        {
            id: "tab_two",
            label: "حسابداری",
            icon: "code",
        },
        {
            id: "tab_three",
            label: "امکانات",
            icon: "layout",
        },
        {
            id: "tab_four",
            label: "گالری",
            icon: "image",
        },
    ];



  return(
      <div className="  space-y-5">
          <div class="grid grid-cols-12 gap-5 ">
              <div class=" col-span-12 lg:col-span-8">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                      <div class="space-y-2">
                          <label className="dark:text-gray-300 text-xs">عنوان اتاق:</label>
                          <InputText className={"input w-full"} />
                      </div>
                      <div class="space-y-2">
                          <label className="dark:text-gray-300 text-xs">تعداد:</label>
                          <InputText className={"input w-full"} />
                      </div>

                  </div>
                  <div class="post box bg-white mt-5 dark:bg-themeDarkSalt700">
                      <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                      <div class="post__content tab-content">
                          <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                              <div className="space-y-5">
                                  <div className="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                                      <label className="text-xs dark:text-gray-300">خلاصه متن:</label>
                                      <InputTextarea rows={3} cols={30} className={"input w-full"} />
                                  </div>
                                  <div>
                                      <TextEditor />
                                  </div>
                              </div>
                          </div>
                          <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                              <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                                  <div class="space-y-2">
                                      <label className="text-xs dark:text-gray-300">مبلغ اولیه:</label>
                                      <InputText  className="input w-full" />
                                  </div>
                                  <div class="space-y-2">
                                      <label className="text-xs dark:text-gray-300"> تخفیف:</label>
                                      <InputText className="input w-full" />
                                  </div>
                                  <div class="space-y-2 ">
                                      <label className="text-xs dark:text-gray-300">قابل پرداخت:</label>
                                      <InputText  className="input w-full" />
                                  </div>
                              </div>
                          </div>
                          <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_three" ? "active" : "" }`} id="tab_three">
                              <div className="space-y-5">
                                  <div class="post-input dark:bg-themeDarkPostInput space-y-3 rounded-md  border dark:border-themeDarkColorLine bg-themeDarkPostInput">
                                      <div class="p-2 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md">
                                          <p>امکانات</p>
                                      </div>
                                      <div className="grid grid-cols-3 xl:grid-cols-6 p-2 gap-2">
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  اینترنت
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  یخچال
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  تلویزیون
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  سیستم سرمایش
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  سیستم گرمایش
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  تخت اضافه
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkSalt700 rounded -intro-x">
                                              <label className="text-xs dark:text-gray-300">
                                                  تخت اضافه
                                              </label>
                                                  <InputText className={"input w-full"} />
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkSalt700 rounded -intro-x xl:col-span-2">
                                              <label className="text-xs dark:text-gray-300">
                                                  سایر:
                                              </label>
                                                  <InputText className={"input w-full"} />
                                          </div>
                                      </div>
                                  </div>
                                  <div class="post-input dark:bg-themeDarkPostInput space-y-3 rounded-md overflow-hidden border dark:border-themeDarkColorLine">
                                      <div class="p-2 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md">
                                          <p> منوی غذایی</p>
                                      </div>
                                      <div class="grid grid-cols-3 xl:grid-cols-6 p-2 gap-2">
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  صبحانه
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  میان وعده
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  ناهار
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  عصرانه
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  شام
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                          <div className="p-1 box bg-white dark:bg-themeDarkPostInput rounded -intro-x">
                                              <label className="flex flex-col items-center gap-y-2 justify-center text-xs dark:text-gray-300">
                                                  منو انتخابی
                                                  <input className="input input--switch bg-white" type="checkbox"/>
                                              </label>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_four" ? "active" : "" }`} id="tab_four">
                              <FileUploader message={ "فایل ها را اینجا رها کنید یا برای آپلود کلیک کنید" } mode={"image"} accept={"images/*"} />
                          </div>
                      </div>
                  </div>
              </div>
          <div class="col-span-12 lg:col-span-4">
              <div class="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 gap-y-5">
                      <div class="space-y-2 -intro-x">
                          <label className="text-xs dark:text-gray-300">نام ساختمان:</label>
                          <MultiDataSelect />

                      </div>
                      <div class="space-y-2 -intro-x">
                          <label className="text-xs dark:text-gray-300">قالب نمایش:</label>
                          <MultiDataSelect />

                      </div>
                      <div class="space-y-2 -intro-x">
                          <label className="text-xs dark:text-gray-300"> متصدی:</label>
                          <MultiDataSelect />
                      </div>
                      <div class="space-y-3 -intro-x">
                          <div>
                              <label className="flex gap-2 text-xs dark:text-gray-300">
                                  کد اتاق:
                                  <a href="#" className="block text-themeInformation dark:text-themeDarkInformation ">( ایجاد کد )</a>
                              </label>
                          </div>
                          <InputText className={"input w-full"} />
                      </div>
                  </div>
                  <div class="col-span-1 sm:col-span-2 grid grid-cols-4 post-input dark:bg-themeDarkPostInput p-3 rounded">
                      <div class="col-span-2 space-y-2">
                          <label className="text-xs dark:text-gray-300">وضعیت نمایش</label>
                          <div class="">
                              <input className="input input--switch border bg-white" type="checkbox"/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          <div class="flex items-center justify-center md:justify-end gap-3">
              <button className="button text-white bg-themeDanger  w-24">انصراف</button>
              <button className="button text-white bg-themeInformation dark:bg-themeDarkInformation w-24">ثبت</button>
          </div>
      </div>
  )
}