export function handleVerify(data){
    const request = {
        verified:data.IsVerified,
        withdrawalWalletRequestId:data.withdrawalWalletRequestId,
        transactionCode:data.transactionCode === null || data.transactionCode === ''?null:data.transactionCode.toString(),
        bankAccountId:data.bankAccountId === null || data.bankAccountId === undefined?null:data.bankAccountId.value
    }
    return request;
}

export function handleReject(data){
    const request = {
        verified:data.IsVerified,
        withdrawalWalletRequestId:data.withdrawalWalletRequestId,
        banReason:data.description
    }
    return request;
}

