import React from "react";

export const BadgeNotification = ({ classStyle, count,billStyle }) => {
    return (
        <>
            <span className={`bill_notification bg-themeDanger z-10 ${!classStyle?' H-min-width-5 H-height-5 text-black':classStyle} ${count === 0 || count < 0 ? 'hidden' : ''} ${billStyle} `}>
                {count}
            </span>
        </>
    );
};
