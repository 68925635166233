import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    handleSuccessMessage,
    handleWarningMessage
} from "../../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../../Contents/ContentService";
import queryString from "query-string";
import {TemplateSchema} from "../../../GeneralManagement/TemplateMasters/TemplateSchema";
import Swal from "sweetalert2";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";

import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {
    convertAddTemplate,
    convertEditTemplate,
    CreateTemplateFormData
} from "../TemplateManagerService";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {validate} from "../../../../Services/Globals/validate";
import {Return} from "../../../Commans/Globals/Return";
import {AlertSetting, fileManager, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {Image} from "primereact/image";
import {ModalButtonOperations} from "../../../Commans/UIParts/ModalButtonOperations";
import {BrowserDownloader} from "../../../../ApiServices/Public/FtpController";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";

import {useSetting} from "../../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {GridButtonOprations} from "../../../Commans/Grid/GridButtonOprations";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
export const HandleAddTemplate=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const[spinner,setSpinner] = useState(false);
    const[result,setResult] = useState(0)
    const [errors, setErrors] = useState({});
    const[templateMasterId,setTemplateMasterId] = useState(null);

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const [selectOptions,setSelectOptions] = useState({templateTypes:null,DefaultTemplates:[{label:t("isActive"),value:true},{label:t("isNotActive"),value:false}]})
    const [isEdit,setIsEdit] = useState(false);

    const [template,setTemplate] = useState({Id:null,Name:null,Banner:null,Type:null,RootFolder:null,DefaultTemplate:null,File:null})
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setTemplate({...template,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }


    const handleSelectValue=(data,name)=>{
        setTemplate({ ...template, [name]: data });
    }

    const handleFile =(data,name)=>{
        setTemplate((prevContent) => ({ ...prevContent, [name]: data }));
    }


    const handleFetchData = async ()=>{
        try {
            setSpinner(true);
            const responseTemplateType = await GET('/Enumerations/EnTypeTemplate',{},'IEnumerable');
            if (responseTemplateType.status === 200 )
            {
                const templateTypes = convertSelectDataHandler(responseTemplateType.data);
                setSelectOptions((prevOptions) => ({
                    ...prevOptions,
                    templateTypes: templateTypes,
                }));
                setTemplate({Type:templateTypes[0],DefaultTemplate: {label:t("isActive"),value:true}})
                setSpinner(false)
            }
            else {
                handleApiError(responseTemplateType,onApiError);
            }
        }
        catch (error) {
            onApiError()        }
    }

    const handleEditRecord = async(recordId) => {
        try {
            setIsEdit(true);
            setSpinner(true);
            const response = await GET(`/Templates/GetTemplateById`,{Id:recordId},'single');
            if(response.status === 200){
                setTemplate({
                    Id: response.data.Id,
                    Name: response.data.name,
                    Type: selectOptions.templateTypes.find((item) => item.value === response.data.type),
                    RootFolder: response.data.rootFolder,
                    DefaultTemplate: selectOptions.DefaultTemplates.find((item) => item.value === response.data.defaultTemplate),
                    Banner: response.data.banner
                });
                redirectTo(`/CMS/Template/Add?id=${recordId}`);
                setSpinner(false);
                setModal({AddTemplateMater: true})
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            onApiError()
        }
    };


    const handleAddTemplateSubmit =async (e)=>{
        e.preventDefault();
        if(isEdit){
            const isValid = validate(TemplateSchema,template,onSetErrors);
            if (!isValid) {
                return;
            }
            setSpinner(true)
            const queryParams = queryString.parse(window.location.search);
            const convertedTemplate = convertEditTemplate(template, queryParams.id);
            const formData = CreateTemplateFormData(convertedTemplate);
            const response = await PUT(`/Templates/EditTemplate`,formData,true);
            if (response.status === 200) {
                if(response.data.failedFiles){
                    handleWarningMessage(`  کاربر گرامی تعداد با مشکلاتی مواجه شد${response.data.failedFiles}`)
                }

                setSpinner(false);
                setIsEdit(false);
                setResult({...response.status});
                redirectTo('/CMS/Template/Add');
                handleCloseMaster();
                handleSuccessMessage(t("Templatehasbeensuccessfullyedited"));
            } else {
                handleApiError(response,onApiError)
            }
        }else{
            const isValid = validate(TemplateSchema,template,onSetErrors);
            if(!isValid){
                return;
            }
            const convertedTemplate = convertAddTemplate(template);
            const formData = CreateTemplateFormData(convertedTemplate);
            setSpinner(true);
            const response = await POST(`/Templates`,formData,true);
            if(response.status === 200){
                if(response.data.failedFiles){
                    handleWarningMessage(`  کاربر گرامی تعداد با مشکلاتی مواجه شد${response.data.failedFiles}`)
                }

                setSpinner(false);
                setResult({...response.status});
                handleCloseMaster();
                handleSuccessMessage(t("Templatehasbeensuccessfullyadded"))
            }else{
                handleApiError(response,onApiError)
            }
        }

    }














    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const handleChangedefaultTemplate = async(templateId,rowName)=>{
        setWait(true);
        setHeaderName(rowName)
        const response = await PUT(`/Templates/EditTemplateActivation`,{id:templateId},false);
        if (response.status === 200) {
            setWait(false)
            setResult({...response.status})
        }
        else{
            setWait(false);
            handleApiError(response,setWait(false))
        }
    }


    const [isMenuOpen,setMenuOpen] = useState(false);
    const [templateId,setTemplateId] = useState(null);
    const [rootFolder,setRootFolder] = useState("");

    const handleMenuOpen = (templateId,name,templateMasterId)=>{
        setMenuOpen(true);
        setRootFolder(name);
        setTemplateId(templateId);
        setTemplateMasterId(templateMasterId)
    }
    const handleCloseMenu = ()=>{
        setMenuOpen(!isMenuOpen)
    }

    const removeTemplateHandler = async (templateId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                setSpinner(true)
                const response = await DELETE(`/Templates/RemoveTemplate/${templateId}`, {headers: { 'Content-Type': 'application/json' }})
                if (response.status === 200) {
                    setSpinner(false)
                    setResult({...response.status});
                    handleSuccessMessage(t("Templatehasbeensuccessfullydeleted"));
                }
                else {
                    handleApiError(response,onApiError)
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const handleUpdateTemplate=(templateId,templateMasterId)=>{
        handleCloseMenu();
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
               await handleUp_to_DateTemplate(templateId,templateMasterId)
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const handleUp_to_DateTemplate=async(templateId,templateMasterId)=>{
        try {
          const response = await PUT( `/Templates/UpdateTemplate`,{templateId:templateId,templateMasterId:templateMasterId});
          if(response.status === 200){
                setResult({...response.status});
                handleSuccessMessage(t("Templatehasbeensuccessfullyupdated"));
          }
          else{
              handleApiError(response,onApiError);
          }
        }
        catch (e) {
            onApiError();
        }
    }




    const ConvertTemplateType = (type) => {
        if (!selectOptions.templateTypes) {
            return;
        }
        const data = selectOptions.templateTypes.find((item) => item.value === type);
        return data ? data.label : '';
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},
        { headerName: t("Banner"),field: 'banner', maxWidth: 130,
            cellRenderer:params => {
                return params.data.banner !== null?
                    <Image src={params.data.banner} zoomSrc={params.data.banner} preview />:
                    <HPrimeIcon
                    icon={"pi-times-circle"}
                    />
            }
        },
        { headerName: t("TemplateTitle"),field: 'name', minWidth: 140},

        { headerName: t("TemplateType"),field: 'type', minWidth: 140,cellRenderer:params => {
                return ConvertTemplateType(params.data.type)
        }},


        { headerName: t("DisplayStatus"),field: 'defaultTemplate',maxWidth: 130, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading />
                    ) : params.data.defaultTemplate ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                           onClick={async () => await handleChangedefaultTemplate(params.data.id,params.colDef.headerName)}
                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangedefaultTemplate(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>

        },


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={() => handleEditRecord(params.data.id)}
                    />

                    {
                        params.data.templateMasterId === null?
                            <GridButtonDelete
                                onClick={()=>removeTemplateHandler(params.data.id)}
                            />

                            :null
                    }

                    <GridButtonOprations
                        onClick={()=>handleMenuOpen(params.data.id,params.data.rootFolder,params.data.templateMasterId)}
                    />
                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',IsActive:null,templateType:null,IsDeActive:null,HasDiscount:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));

    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const filterSelectValue=(data,name)=>{
            setFilter(({...filter,[name]: data.value}));
    }
    const resetFilters = ()=>{
        setFilter(({...filter,search: '', HasDiscount: null,templateType: null,IsActive: null,IsDeActive: null}));
        setSearch('');
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [result,currentPage,data.pageSize,filter.templateType,filter.search,filter.IsActive,filter.IsDeActive,filter.HasDiscount]);

    const [rowData, setRowData] = useState([]);

    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/Templates/GetTemplateList`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search, IsActive: filter.IsActive, IsDeActive: filter.IsDeActive,HasDiscount:filter.HasDiscount,Type:filter.templateType}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };





    const [modal, setModal] = useState({ AddTemplateMater: false });
    const handleCloseMaster=()=>{
        setModal({AddTemplateMater: false});
        setTemplate({Type:selectOptions.templateTypes[0],DefaultTemplate: {label:'فعال',value:true},Name: '',RootFolder: ''});
        redirectTo('/CMS/Template/Add');
        setIsEdit(false);
    }


    const handleDownloadTemplate=async()=>{
        const filePath = `${fileManager.separator}${rootFolder}`;
        await BrowserDownloader(filePath,'/Templates/DownloadTemplate')
    }



    return (
        <>
            <div class=" space-y-5 relative">
                {
                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("SearchByNameTemplate")}</label>
                            <InputText  onChange={(e)=>setSearch(e.target.value)}  value={search} type="text" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full"  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("TemplateType")}</label>
                            <Select
                                options={selectOptions.templateTypes}
                                isMulti={false}
                                isSearchable={true}
                                onChange={(data)=>filterSelectValue(data,'templateType')}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => setModal({ AddTemplateMater: true })}
                                            tooltipTitle={t("TemplateCreation")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/CMS/Template/Choose")}
                                            title={t("Buy")}
                                            tooltipTitle={t("BuyATemplate")}
                                            icon={"pi-cart-arrow-down"}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/CMS/Template/ShortCode")}
                                            title={t("ShortCode")}
                                            tooltipTitle={t("CreatingAShortCode")}
                                            icon={"pi-code"}
                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitch OnValueSelection={filterValueHandler} name={'IsActive'} lable={t("isActive")} />
                                        <InputSwitch OnValueSelection={filterValueHandler} name={'IsDeActive'} lable={t("isNotActive")} />
                                        <InputSwitch OnValueSelection={filterValueHandler} name={'RTL'} lable={t("Personal")} />
                                        <InputSwitch OnValueSelection={filterValueHandler} name={'LTR'} lable={t("Bought")} />
                                    </>
                                }
                    />


                </div>
                <div className=" space-y-2 H-body-ag-grid" >

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <ToastContainer />
                <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu}>
                    <div className="p-1 grid grid-cols-3 md:grid-cols-4 gap-3">
                        <ModalButtonOperations
                            onClick={()=>redirectTo(`/CMS/Template/Add/page?templateMasterId=${templateId}&rootFolder=${rootFolder}`)}
                            title={t("PageCreation")}
                            icon={"pi-objects-column"}
                        />
                        <ModalButtonOperations
                            title={t("Download")}
                            icon={"pi-download"}
                            onClick={()=>handleDownloadTemplate(rootFolder)}

                        />

                        {
                            templateMasterId !== null? <ModalButtonOperations onClick={()=>handleUpdateTemplate(templateId,templateMasterId)} title={"Update"} icon={"layout"}/>:
                                null


                        }

                    </div>
                </FantasyModal>
                <FantasyModal openModal={modal.AddTemplateMater}  closeModal={handleCloseMaster} title={t("TemplateCreation")}>
                    <form method={'post'} onSubmit={handleAddTemplateSubmit}  className="space-y-5">
                        <div className="grid grid-cols-2 gap-3 ">
                            <div class="space-y-1 col-span-2">
                                    <div className="flex justify-between">
                                        <label className="text-xs dark:text-gray-300">
                                            {t("TemplateTitle")}
                                        <RequiredIcon/>
                                        </label>
                                        <DisplayError message={errors.Name}/>
                                    </div>
                                    <InputText placeholder={t("Title")} className="input w-full" name={'Name'} value={template.Name} onInput={(e)=>handleValue(e)}/>
                                </div>
                            <div class=" space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("TemplateType")}</label>
                                    <Select
                                        options={selectOptions.templateTypes}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'Type')}
                                        placeholder={t("selection")}
                                        value={template.Type}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                            <div class=" space-y-2">
                                    <div className="flex justify-between">
                                        <label className="text-xs dark:text-gray-300">{t("TemplateFolderName")}</label>
                                    </div>
                                    <InputText placeholder={t("TemplateFolderName")} className="input w-full" name={'RootFolder'} value={template.RootFolder} onInput={(e)=>handleValue(e) }/>
                                </div>
                            <div class=" space-y-1 ">
                                    <label className="text-xs dark:text-gray-300">{t("Status")}</label>
                                    <Select
                                        options={selectOptions.DefaultTemplates}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'DefaultTemplate')}
                                        placeholder={t("selection")}
                                        value={template.DefaultTemplate}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                        </div>
                        <div className={"grid grid-cols-2 gap-3"}>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("WatermarkText")}
                                        />

                                        {t("TemplateImage")}
                                    </label>
                                    <div className="w-full h-32 ">
                                        <UploadImage accept={'image/*'} OnValueSelection={handleFile} value={template.Banner ? template.Banner:null} name={'Banner'} />
                                    </div>
                                </div>

                                {/*<div>*/}
                                {/*    {template.File ?template.File.name:null}*/}
                                {/*    <label className="text-xs dark:text-gray-300">بارگزاری فایل ها</label>*/}
                                {/*    <div class="w-full h-32 bg-white dark:bg-themeDarkPostInput">*/}
                                {/*        <UploadImage onChange={(e)=>handleFile(e.target.files[0],"File")} className="button "/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("WatermarkText")}
                                        />
                                        {t("FileUpload")}</label>
                                    <div class="w-full h-32">
                                        <UploadImage accept={'.zip'} OnValueSelection={handleFile} name={'File'} onChange={(e)=>handleFile(e.target.files[0],"File")} value={template.File ? template.File:null} className="button "/>
                                    </div>
                                    <div>
                                        <h2 className={"line-clamp-1"}> {template.File ?template.File.name:null}</h2>
                                    </div>
                                </div>

                            </div>
                        <button type={'submit'} className="button text-white w-full bg-themeInformation dark:bg-themeDarkInformation">
                            {t("register")}
                        </button>
                        </form>
                </FantasyModal>

                <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                    <div>
                        {
                            setting === 'GeneralSetting'?
                                <HandleGeneralSetting/>:null
                        }
                    </div>
                </FantasyModal>
            </div>
        </>
    )
}