import {InputText} from "primereact/inputtext";
import {Slider} from "primereact/slider";
import React from "react";
import {useTranslation} from "react-i18next";
import {InputNumber} from "primereact/inputnumber";

export const Range=({rangeIndex,rangeItem,handleChangeSurveyItems,item,handleAddSurveyItem,handleRemoveSurveyItem})=>{
    const { t } = useTranslation();
    return (
        <div key={rangeIndex} className="space-y-5 p-2 shaow relative harmony_survey_box ">
            <div className="px-5">
                <div className={"flex justify-between items-center gap-4"}>
                    <label className="text-xs dark:text-gray-300 text-nowrap">{t("Rate")}</label>
                    <Slider
                        value={rangeItem.surveyItemValue}
                        onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,rangeItem.fakeId,rangeItem.id, 'surveyItemValue', e.value)}
                        className="w-full"
                        min={0}
                        max={10}
                    />
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-5 justify-center">
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("nextquestion")}</label>
                    <div className={"p-inputgroup number_input"}>
                        <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">{t("Xmax")}</span>

                        <InputNumber
                                value={rangeItem.nextLinkedSurveyRaw}
                                onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,rangeItem.fakeId,rangeItem.id, 'nextLinkedSurveyRaw', e.value)}
                                // realSurveyId,surveyFakeId, surveyItemFakeId, surveyItemId, field, value
                                name={'nextLinkedSurveyRaw'}
                                min={0}
                                type={'text'}
                                showButtons={true}

                                // placeholder={t("Question")}
                            />


                    </div>
                </div>
                <div className={"space-y-1"}>
                    <label className="text-xs dark:text-gray-300">X</label>
                    <div className="p-inputgroup number_input">
                        <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">X= </span>
                            <InputNumber
                                value={rangeItem.surveyItemValue}
                                name={'surveyItemValue'}
                                onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,rangeItem.fakeId,rangeItem.id, 'surveyItemValue', e.value)}
                                min={0}
                                type={'text'}
                                showButtons={true}
                                max={10}
                            />
                    </div>

                </div>
                <div className={"flex gap-1"}>
                    <div class="flex-1 space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("nextquestion")}</label>
                        <div className={"p-inputgroup number_input"}>
                            <span className="p-inputgroup-addon border dark:border-themeDarkColorLine" >{t("Xmin")}</span>

                                <InputNumber
                                    value={rangeItem.linkedSurveyRaw}
                                    onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,rangeItem.fakeId,rangeItem.id, 'linkedSurveyRaw', e.value)}
                                    name={'linkedSurveyRaw'}
                                    min={0}
                                    type={'text'}
                                    showButtons={true}
                                />
                        </div>

                    </div>
                    {/*<div className="flex  gap-2">*/}
                    {/*    {*/}
                    {/*        rangeIndex === item.surveyItems.length - 1?*/}
                    {/*            <>*/}
                    {/*                <button*/}
                    {/*                    type="button"*/}
                    {/*                    className="button text-white rounded-full bg-themeInformation dark:bg-themeDarkInformation flex justify-center items-center"*/}
                    {/*                    onClick={() => handleAddSurveyItem(item.fakeId,rangeIndex + 2)}*/}
                    {/*                >*/}

                    {/*                </button>*/}

                    {/*                <button*/}
                    {/*                    type="button"*/}
                    {/*                    className="button text-white rounded-full bg-themeDanger flex justify-center items-center"*/}
                    {/*                    onClick={() => handleRemoveSurveyItem(item.id,item.fakeId,rangeItem.fakeId, rangeItem.id,true)}*/}
                    {/*                >*/}

                    {/*                </button>*/}
                    {/*            </>:null*/}
                    {/*    }*/}

                    {/*</div>*/}
                </div>
            </div>


        </div>
    )
}