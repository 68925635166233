import 'primeicons/primeicons.css';
import React from "react";
import {useTranslation} from "react-i18next";
import {TinyLoading} from "../Loadings/TinyLoading";

export const ModalSubmitButton=({type,TextButton,className,style,ButtonStyle,IsBtnLoading,ButtonBgClass})=>{
    const { t } = useTranslation();
    return (
        <>
            <button
                type={type ? type : "submit"}
                className={`button hbtn-save w-full relative ${style} ${!ButtonBgClass ? `bg-themeInformation dark:bg-themeDarkInformation` : ButtonBgClass}  ${!ButtonStyle ? ' text-white' : ButtonStyle}`}
            >
                {TextButton ? TextButton : t("register")}

                {IsBtnLoading ? (
                    <TinyLoading
                    />
                ) : null}
            </button>

        </>
    )
}