import React, {useState} from "react";

export const useLogError=()=>{
    const [logError,setLogError]=useState([]);
    const [logData,setLogData]=useState(null)

    const [logId,setLogId]=useState('');
    const handleSelectLogId=(logId)=>{
        setLogId(logId)
    }

    const handleSelectLogData=(logId,logData)=>{
        setLogId(logId);
        setLogData(logData)
    }


    return {
        logError,
        setLogError,
        handleSelectLogId,
        logId,
        handleSelectLogData,
        logData

    }
}