import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import PasswordStrengthBar from "react-password-strength-bar";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {validate} from "../../../Services/Globals/validate";
import {PasswordSchema} from "../../Authentication/ForgetPassword/forgetPasswordSchema";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {LoadingSpinner} from "../../Commans/Loadings/LoadingSpinner";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const UserChangePassword=({authData,onSetErrors,errors,handleValue,handleChangeState})=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsShowSpinner, setIsShowSpinner] = useState(false);
    const [IsPlay,setIsPlay] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const ChangeSubmitPasswordApi = async (e) => {
        e.preventDefault();
        try {
            const isValid = validate(PasswordSchema,authData,onSetErrors);
            if (!isValid) return;
            showSpinner();
            const { id, code, password, confirmPassword } = authData;
            const response = await POST('Authentication/ForgetPassword/ChangePassword', { id: id, code: code, newPassword: password, confirmNewPassword: confirmPassword });
            if (response.status === 200) {
                hideSpinner();
                handleChangeState('userAuthentication');
            } else {
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    };









    return (<>



        <form method={'post'} onSubmit={ChangeSubmitPasswordApi}  className={'relative'}>
            {IsShowSpinner ? <LoadingSpinner/> : null}

            <div className="p-2 flex justify-between items-center ">
                <div className="flex justify-items-start">
                    <h2 className="font-bold text-gray-700 ">
                        {t('Enter_Your_Password')}
                    </h2>
                </div>
            </div>
            <div className="space-y-5">
                <span className="p-input-icon-left">
                    <HPrimeIconSmall
                        iconClass={"cursor-pointer"}
                        icon={showPassword?'pi-eye':'pi-eye-slash'}
                        onClick={()=>setShowPassword(!showPassword)}></HPrimeIconSmall>
                    <InputText type={showPassword?'text':'password'}   className="input" placeholder={t('Password')}  name='password' value={authData.password} onInput={(e)=>handleValue(e)} />
                </span>
                <DisplayError message={errors.password}/>

                <PasswordStrengthBar password={authData.password}/>
                <span className="p-input-icon-left ">
                    <HPrimeIconSmall
                        iconClass={"cursor-pointer"}
                        icon={showPassword?' pi-eye':'pi-eye-slash'} onClick={()=>setShowPassword(!showPassword)}>
                    </HPrimeIconSmall>
                    <InputText type={showPassword?'text':'password'}  className="input" placeholder={t('RepeatPassword')} name='confirmPassword' value={authData.confirmPassword} onInput={(e)=>handleValue(e)} />
                </span>
                <DisplayError message={errors.confirmPassword}/>

                <div className="flex gap-2 items-center">

                    <button onClick={()=>handleChangeState('userAuthentication')}  type={'passwordRecovery'}  className={'button w-full flex-1 bg-themeDanger text-white'} >{t('PreviousLevel')} </button>

                    <button className="button w-full flex-1 user_login_gradiant text-white" type="submit">
                        {t("Register")}
                    </button>

                </div>

            </div>
        </form>

    </>)
}