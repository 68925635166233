import React, {useState} from "react";
import queryString from "query-string";
import {useTranslation} from "react-i18next";
import ArrowLeftIcon from "../../../HIcon/ArrowLeftIcon";

export const EmojiQuestion=({question,emojiItems,index,surveyId,handleAddSurveyQuestion})=>{
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState(null);
    const handleEmojiChange = (surveyItemId) => {
        setSelectedItem(surveyItemId); // ذخیره آیتم انتخاب شده
        handleAddSurveyQuestion(
                {
                    surveyItemId: surveyItemId,
                    surveyId:surveyId,
                    textSurvey:''
                },
            index
        );
    };

    return (
        <>
            <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                    {index+1}
                </div>
                <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                    <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">
                        {question}
                    </h2>
                </div>
                <div>
                    <span className={"arrow_design_add_site"}>
                         <ArrowLeftIcon/>
                    </span>
                </div>
            </div>
            <div className={"grid grid-cols-1"}>
                <div className={"space-y-5"}>
                    <div className={"post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                        <p className="dark:text-gray-300">{t("ChooseTheOptionYouWant")}</p>
                    </div>
                    <div>
                        <div className={"flex flex-wrap gap-5 px-2"}>
                            {emojiItems.map((emojiItem, index) => (
                                <div key={index}>
                                    <label htmlFor={emojiItem.id} className={`flex items-center gap-2 py-3 px-2 border min-w-20 post-input post-input-design  cursor-pointer dark:border-themeDarkColorLine ${selectedItem === emojiItem.id ? 'border-themeInformation bg-themeLightGray' : 'dark:bg-themeDarkPostInput'} `}>
                                        <input
                                            type="radio"
                                            name={"emoji-options"}
                                            id={emojiItem.id}
                                            onChange={() => handleEmojiChange(emojiItem.id)}
                                            className={"flex-shrink-0"}
                                        />

                                        <h2  style={{lineHeight:"normal",fontSize:"2rem"}}>{emojiItem.surveyItemName}</h2>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}