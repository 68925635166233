import React, { createContext, useState } from "react";
export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [lang,SetLanguage] = useState(localStorage.getItem('language'));
  return (
    <StateContext.Provider value={{ lang, SetLanguage }}>
      {children}
    </StateContext.Provider>
  );
};
