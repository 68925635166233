import React, {useState} from "react";


export const useModal=()=>{
    const [modal, setModal] = useState({ HandleAddFilter: false });
    const [spinner,setSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const handleCloseFilter=()=>{
        setModal(prevState => ({...prevState,HandleAddFilter: false}))
    }
    const onApiError = () => {
        setSpinner(false);
    };
    const onSetErrors=(error)=>{
        setErrors(error);
    }
return {
    onApiError,
    setErrors,
    errors,
    spinner,
    setSpinner,
    handleCloseFilter,
    setModal,
    modal,
    onSetErrors,
}
}