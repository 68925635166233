import React, {useMemo, useState} from "react";

import {GridTable} from "../Commans/Grid/GridTable";

import {useTranslation} from "react-i18next";

import {PriceFormatterComponent} from "../Commans/Accounting/PriceFormatterComponent";
import {GridSetting} from "../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../Services/Globals/Translations/Resources";

import {Tooltip} from "react-tooltip";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";

export const EntryAndExitListPersonnel=({rowData,GetCurrencyType})=>{
    const { t } = useTranslation();

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("FirstNameAndLastName"),minWidth: 160,
            cellRenderer:parameters=>
                <>{parameters.data.userFullName}</>
        },
        { headerName: "تاریخ", minWidth: 100,
            cellRenderer:parameters=>
                <>{parameters.data.productItemCount}</>
        },
        { headerName: 'ساعت ورود', minWidth: 160,
            cellRenderer:parameters=>
                <>{parameters.data.trackingCode}</>
        },
        { headerName: 'ساعت خروج',minWidth: 170,
            cellRenderer:parameters=>
                <> {<PriceFormatterComponent price={parameters.data.amount.amount}/>}{GetCurrencyType(parameters.data.amount.currency)}</>
        },

        { headerName: t("settings"),width: 110, maxWidth: 130,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>
                </div>
            }

        },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);



    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };




    return(
        <>
            <>

                <div className={"space-y-2 H-body-ag-grid"}>
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                </div>
            </>

        </>

    )
}