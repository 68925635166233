import Joi from 'joi';

export const roleSchema = Joi.object({
    name: Joi.string().required().messages({
        'any.required': 'عنوان نقش الزامیست',
        'string.empty': 'عنوان نقش الزامیست',
        'string.base':'عنوان نقش الزامیست',
    }),
}).options({ abortEarly: false });

