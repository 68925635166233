import {InputText} from "primereact/inputtext";

import React from "react";
import {useTranslation} from "react-i18next";
import {InputNumber} from "primereact/inputnumber";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";

export const Multi=({multiIndex,multiItem,handleChangeSurveyItems,item,handleAddSurveyItem,handleRemoveSurveyItem})=>{
    const { t } = useTranslation();
    return (
        <div key={multiIndex} className="flex flex-col sm:flex-row gap-2 shaow relative p-2 harmony_survey_box  pt-5">
            <div className={"absolute end-2 z-10 top-0 -translate-y-1/2"}>
                <div className="flex items-center gap-2">
                        {
                            multiIndex === item.surveyItems.length - 1?
                                <>
                                    <button type="button" className="button text-white bg-themeInformation dark:bg-themeDarkInformation " onClick={() => handleAddSurveyItem(item.fakeId,multiIndex+2)} >
                                        <HPrimeIconSmall icon={'pi-plus'} sizeIcon={"primIcon_FontSize_xxs"} />
                                    </button>

                                </>:null
                        }
                        <button type="button" className="button text-white bg-themeDanger" onClick={() => handleRemoveSurveyItem(item.id,item.fakeId,multiItem.fakeId, multiItem.id,true)}>
                            <HPrimeIconSmall icon={'pi-minus'} sizeIcon={"primIcon_FontSize_xxs"}/>
                        </button>
                    </div>
            </div>
            <div className={"flex-1 w-full flex gap-2 items-center flex-col sm:flex-row"}>
                <div className="space-y-1 flex-shrink-0">
                    <div>
                        <label className="text-xs dark:text-gray-300">
                            {t("Value")}
                            <RequiredIcon/>
                        </label>
                    </div>
                    <div className={"w-full md:w-20"}>
                        <InputNumber
                            value={multiItem.surveyItemValue}
                            name={'surveyItemName'}
                            onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,multiItem.fakeId,multiItem.id, 'surveyItemValue', e.value)}
                            min={0}
                            type={'text'}
                            showButtons={true}

                        />
                    </div>

                </div>
                <div className="space-y-1 flex-1">
                    <label className="text-xs dark:text-gray-300">
                        {t("Optiontext")}
                        <RequiredIcon/>
                    </label>
                    <InputText
                        value={multiItem.surveyItemName}
                        name={'surveyItemName'}
                        onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,multiItem.fakeId,multiItem.id, 'surveyItemName', e.target.value)}
                        className="input w-full"
                        placeholder={t("Optiontext")} />
                </div>
            </div>
            <div className={"flex-shrink-0"}>
                <div className="space-y-1">
                    <div>
                        <label className="text-xs dark:text-gray-300">{t("nextquestion")}</label>
                    </div>
                    <div className={"w-full md:w-20"}>
                        <InputNumber
                            name={'linkedSurveyRaw'}
                            onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,multiItem.fakeId,multiItem.id, 'linkedSurveyRaw', e.value)}
                            value={multiItem.linkedSurveyRaw}
                            min={0}
                            type={'text'}
                            showButtons={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}