import React, { useMemo, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";

import { FantasyModal } from "../../Commans/UIParts/FantasyModal";
import { GridTable } from "../../Commans/Grid/GridTable";
import { useTranslation } from "react-i18next";
import { ListSearch } from "../../Commans/Grid/ListSearch";
import { ListFilter } from "../../Commans/Grid/ListFilter";
import Select from "react-select";
import { Export } from "../../Commans/Grid/Export";
import { ButtonPlus } from "../../Commans/UIParts/ButtonPlus";
import { SettingButton } from "../../Commans/UIParts/SettingButton";
import { DateTimePicker } from "../../Commans/Dates/DateTimePicker";
import { useSetting } from "../../Commans/Hooks/useSetting";
import { HandleGeneralSetting } from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import { HandlePicturesSettings } from "../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import { ButtonSearchList } from "../../Commans/UIParts/ButtonSearchList";
import { useUserInsuranceGrid } from "./useUserInsuranceGrid";
import { GridSetting } from "../../../Services/Globals/Public/AppSetting";
import { AgGridEnglish } from "../../../Services/Globals/Translations/Resources";
import { Pagination } from "../../Commans/Grid/Pagination";
import { useParams } from "react-router-dom";
import { LoadingHarmony } from "../../Commans/Loadings/LoadingHarmony";
import { convertSelectDataHandler } from "../../CMS/Contents/ContentService";
import { GET, POST, PUT } from "../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import { useInsurance } from "./useInsurance";
import { handleSuccessMessage } from "../../../Services/Globals/Errors/NotificationService";
import { ModalSubmitButton } from "../../Commans/UIParts/ModalSubmitButton";
import { InputTextarea } from "primereact/inputtextarea";
import { convertInsuranceData, convertInsurancePutData } from "./InsuranceServices";
import { insuranceSchema } from "./insuranceSchema";
import { validate } from "../../../Services/Globals/validate";
import { DisplayError } from "../../Commans/Errors/DisplayError";
import { RequiredIcon } from "../../Commans/Errors/RequiredIcon";
import { ToastContainer } from "react-toastify";

export const HandleUserInsurance = () => {
  const { t } = useTranslation();
  const [IsShowModalInformation, setIsShowModalInformation] = useState(false);
  const [IsShowSpinner, setIsShowSpinner] = useState(false);
  const { setting, handleSetting, handleSettingClose, IsSettingOpen } = useSetting();
  const [initialSource, setInitialSource] = useState({ insuranceTypes: [] }); // and ...
  const [errors, setErrors] = useState({})
  const [IsBtnLoading, setIsBtnLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { userId, fullName } = useParams();


  const { insurance, setInsurance, handleInsuranceStartDate, handleInsuranceEndDate, handleSelectValue, handleValue } = useInsurance(setErrors);

  // hide and show btn loading
  const showBtnLoading = () => setIsBtnLoading(true)
  const hideBtnLoading = () => setIsBtnLoading(false)


  const onSetErrors = (errors) => {
    setErrors(errors)
  }
  const handleResetErros = () => {
    setErrors({});
  }

  const Refresh = () => {
    setRefresh((prevstate) => !prevstate);
  }

  // show and hide modal
  const showFamilyInformation = () => {
    setIsShowModalInformation(true);
    setInsurance(
      {
        ...insurance,
        userId: userId,
        insurancePosition: '',
        insuranceType: initialSource.insuranceTypes[0],
        insuranceNumber: '',
        startDate: new Date(),
        endDate: null,
        description: '',
      }
    );
  }
  const hideFamilyInformation = () => {
    setIsShowModalInformation(false);
    setIsEdit(false);
    handleResetErros();
    setInsurance(
      {
        userId: userId,
        insurancePosition: '',
        insuranceType: null,
        insuranceNumber: '',
        startDate: null,
        endDate: null,
        description: '',
      }
    );
  }


  // hide and show spinner
  const showSpinner = () => {
    setIsShowSpinner(true);
  }
  const hideSpinner = () => {
    setIsShowSpinner(false);
  }


  // get data selection and set in the InitialSource state
  const handleInitialDataSource = (insuranceTypes) => {
    const insuranceTypesData = convertSelectDataHandler(insuranceTypes);
    setInitialSource({ insuranceTypes: insuranceTypesData });
  }
  const fetchData = async () => {
    try {
      showSpinner();
      const res = await GET(`/Enumerations/EnInsuranceType`, {}, 'IEnumerable');
      if (res.status === 200) {
        handleInitialDataSource(res.data);
        hideSpinner();
      } else {
        handleApiError(res, hideBtnLoading);
      }
    } catch {
      hideSpinner();
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  // post data
  const handleSubmitInsurance = async (e) => {
    e.preventDefault();
    const isValid = validate(insuranceSchema, insurance, onSetErrors);
    if (!isValid) {
      return;
    }
    showBtnLoading();
    try {
      if (isEdit) {
        const insurancePutData = convertInsurancePutData(insurance);
        const res = await PUT('/Users/Insurance', insurancePutData, false);
        if (res.status === 200) {
          hideBtnLoading();
          hideFamilyInformation();
          Refresh();
          handleSuccessMessage(t('SuccessDone'))
        } else {
          handleApiError(res, hideBtnLoading);
        }
      } else {
        const insuranceData = convertInsuranceData(insurance);
        const res = await POST('/Users/Insurance', insuranceData, false);
        if (res.status === 200) {
          hideBtnLoading();
          hideFamilyInformation();
          Refresh();
          handleSuccessMessage(t('SuccessDone'))
        } else {
          handleApiError(res, hideBtnLoading);
        }
      }
    } catch {
      hideSpinner();
    }
  }

  const { columnDefs,
    handleSelectFilterValue,
    handleFilterInsuranceEndDate,
    handleFilterInsuranceStartDate,
    defaultColDef,
    getRowHeight,
    autoGroupColumnDef,
    rowData,
    pagination,
    handlePreviousPage,
    handleNextPage,
    handleCurrentPage,
    handlePageSizeCount,
    filter,
    setFilter,
    handleSearch,
    handleResetFilters } = useUserInsuranceGrid(showSpinner, hideSpinner, insurance, setInsurance, refresh, Refresh, initialSource, setIsEdit, setIsShowModalInformation);

  return (
    <>

      <div className="relative space-y-5">
        {
          IsShowSpinner ?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony />
              </div>
            </div>
            :
            null
        }
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
          <ListSearch label={null} icon={null} >
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("search")}</label>
              <InputText onChange={(e) => setFilter(() => ({ ...filter, searchValue: e.target.value }))} value={filter.searchValue} onKeyDown={(e) => e.key === 'Enter' ? setFilter(() => ({ ...filter, search: e.target.value })) : null} type={"search"} className=" input w-full" />
            </div>
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                <SettingButton
                  onClick={() => handleSetting('GeneralSetting')}
                  tooltipTitle={t("Calendarsettings")}
                />
                {t("نوع بیمه")}</label>
              <Select
                options={initialSource.insuranceTypes}
                placeholder={t("selection")}
                classNamePrefix={"h_multi_select"}
                menuPosition={"fixed"}
                value={filter.insuranceType}
                onChange={((data) => handleSelectFilterValue(data, 'insuranceType'))}
              />
            </div>
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                <SettingButton
                  onClick={() => handleSetting('GeneralSetting')}
                  tooltipTitle={t("Calendarsettings")}
                />
                {t("FromDate")}</label>
              <DateTimePicker format={'YYYY/MM/DD'} handleDateTime={handleFilterInsuranceStartDate} value={filter.startDate} />
            </div>
            <div className="space-y-2 ">
              <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                <SettingButton
                  onClick={() => handleSetting('GeneralSetting')}
                  tooltipTitle={t("Calendarsettings")}
                />
                {t("ToDate")}</label>
              <DateTimePicker format={'YYYY/MM/DD'} handleDateTime={handleFilterInsuranceEndDate} value={filter.endDate} />
            </div>
            <ButtonSearchList
              onClickReset={() => handleResetFilters()}
              onClickSearch={() => handleSearch()}
            />
          </ListSearch>
          <ListFilter label={null} icon={null}
            showChildrenTwo={false}
            childrenOne={
              <>
                <ButtonPlus
                  onClick={() => showFamilyInformation()}
                  tooltipTitle={t("FamilyInformation")}
                />

              </>
            }
          />


        </div>
        <div className={"box bg-white dark:bg-themeDarkSalt700 p-2"}>
          <div className={"post-input post-input-design p-2 flex items-center gap-2 dark:bg-themeDarkPostInput"}>
            <div className="w-12 h-12 bg-white dark:bg-themeDarkSalt700 flex-shrink-0 rounded-full overflow-hidden">
              <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="w-full h-full object-cover" />
            </div>
            <div className={"flex gap-3 items-center flex-1 w-full flex-wrap"}>
              <div className={"flex gap-1 items-center"}>
                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Name")}:</h3>
                <h2 className="line-clamp-1 text-xs dark:text-gray-300">
                  {fullName}
                </h2>
              </div>

            </div>

          </div>
        </div>
        <div className={"H-body-ag-grid space-y-2"}>
          <GridTable
            columnDefs={columnDefs}
            rowSelection={GridSetting.rowSelection}
            rowData={rowData}
            style={GridSetting.gridStyle}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={GridSetting.pagination}
            enableRtl={GridSetting.enableRtl}
            domLayout={GridSetting.domLayout}
            paginationPageSize={GridSetting.pageSize}
            getRowHeight={getRowHeight}
            localeText={AgGridEnglish}
          />
          <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount} />

        </div>
        <div className={"grid grid-cols-1 lg:grid-cols-12 bg-white dark:bg-themeDarkSalt700"}>
          <div className={"lg:col-span-9"}>
            <Export />
          </div>
          <div className="lg:col-span-3  flex gap-1 items-center  justify-center p-2">
            <div className={"flex-1 w-full"}>
              <Select
                placeholder={t("DeterminingVerdict")}
                classNamePrefix={"h_multi_select"}
                menuPosition={"fixed"}
              />
            </div>

            <button type={"button"} className="button bg-themeInformation text-white dark:bg-themeDarkInformation">
              {t("Actions")}
            </button>
          </div>

        </div>
      </div>

      <FantasyModal openModal={IsShowModalInformation} closeModal={hideFamilyInformation} title={t("FamilyInformation")} >
        <form onSubmit={handleSubmitInsurance} method="post" className={"space-y-5"}>
          <div className={"space-y-3"}>
            <div className="space-y-1">
              <label className="text-xs dark:text-gray-300">{t("سمت در لیست بیمه")}</label>
              <InputText type="text" className=" input" name="insurancePosition" onInput={(e) => handleValue(e)} value={insurance.insurancePosition} />
            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 "}>
              <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("نوع بیمه")}</label>
                <Select
                  options={initialSource.insuranceTypes}
                  placeholder={t("selection")}
                  classNamePrefix={"h_multi_select"}
                  menuPosition={"fixed"}
                  value={insurance.insuranceType}
                  onChange={((data) => handleSelectValue(data, 'insuranceType'))}
                />
              </div>
              <div className="space-y-1">
                <div className="flex items-center justify-between gap-2">
                  <label className="text-xs dark:text-gray-300">{t("کد بیمه")}
                    <RequiredIcon />
                  </label>
                  <DisplayError message={errors.insuranceNumber} />
                </div>
                <InputText type="text" className={(errors.insuranceNumber ? "hValue-border-danger" : "") + " input"} name="insuranceNumber" onInput={(e) => handleValue(e)} value={insurance.insuranceNumber} disabled={isEdit ? 'disable' : ''} />
              </div>
              <div className="space-y-1">
                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                  <SettingButton
                    onClick={() => handleSetting('GeneralSetting')}
                    tooltipTitle={t("Calendarsettings")}
                  />
                  {t("StartDate")}
                </label>
                <DateTimePicker isContainsTime={false} handleDateTime={handleInsuranceStartDate} format={'YYYY/MM/DD hh:mm:ss'} value={insurance.startDate} />
              </div>
              <div className="space-y-1">
                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                  <SettingButton
                    onClick={() => handleSetting('GeneralSetting')}
                    tooltipTitle={t("Calendarsettings")}
                  />
                  {t("EndDate")}
                </label>
                <DateTimePicker isContainsTime={false} handleDateTime={handleInsuranceEndDate} format={'YYYY/MM/DD hh:mm:ss'} value={insurance.endDate} />
              </div>
            </div>
            <div className="space-y-1">
              <label className="text-xs dark:text-gray-300">{t("توضیحات")}</label>
              <InputTextarea col={5}
                className={"input w-full"}
                name={'description'}
                value={insurance.description}
                onInput={(e) => handleValue(e)}
              />
            </div>
          </div>

          <div>
            <ModalSubmitButton
              IsBtnLoading={IsBtnLoading}
            />
          </div>
        </form>
      </FantasyModal>

      <FantasyModal openModal={IsSettingOpen} closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
        <div>
          {
            setting === 'GeneralSetting' ?
              <HandleGeneralSetting /> :
              setting === 'waterMark' ?
                <HandlePicturesSettings /> : null
          }
        </div>
      </FantasyModal>

      <FantasyModal openModal={IsSettingOpen} closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
        <div>
          {
            setting === 'GeneralSetting' ?
              <HandleGeneralSetting /> :
              setting === 'waterMark' ?
                <HandlePicturesSettings /> : null
          }
        </div>
      </FantasyModal>

      <ToastContainer />
    </>

  )
}