import Joi from 'joi';

export const bankSchema = Joi.object({

    // accountNumber: Joi.string().allow('').allow(null)
    //     .pattern(/^\d+([\/.-]\d+)*$/)
    //     .messages({
    //         'string.pattern.base': 'فرمت شماره حساب  صحیح نمیباشد',
    //     }),
    //
    // cardNumber: Joi.string().allow('').allow(null)
    //     .pattern(/^\d{5}-\d{5}$/)
    //     .messages({
    //         'string.pattern.base': 'فرمت شماره کارت صحیح نمیباشد',
    //     }),
    // shebaNumber: Joi.string().allow('').allow(null)
    //     .pattern(/^[IR]{2}[0-9]{24}$/)
    //     .messages({
    //         'string.pattern.base': 'فرمت شماره شبا صحیح نمیباشد',
    //     }),
    name: Joi.string()
        .required().messages({
            'any.required': 'نام بانک الزامیست',
            'string.empty': 'نام بانک الزامیست',
        })


}).options({ abortEarly: false });

