import {InputNumber} from "primereact/inputnumber";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import React, {useEffect, useState} from "react";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {Checkbox} from "primereact/checkbox";

export const NumberInputFields = ({ index, element, handlePropertyChange, t }) => {

    return (
        <>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>
                    <InputNumber
                        type="text"
                        min={0}
                        value={element.properties.min}
                        onChange={(e) => handlePropertyChange(index, 'min', e.value)}
                        showButtons
                    />
                </div>
                <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>
                    <InputNumber
                        type="text"
                        min={0}
                        value={element.properties.max}
                        onChange={(e) => handlePropertyChange(index, 'max', e.value)}
                        showButtons
                    />
                </div>
                {
                    element.typeName === 'Email'?
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Pattern")}</label>
                            <InputText
                                type="text"
                                value={element.properties.regex}
                                className={"input w-full"}
                                onChange={(e) => handlePropertyChange(index, 'regex', e.target.value)}
                            />
                        </div>
                        :null
                }
                {
                    element.typeName === 'Price'?
                        <>
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("format")}</label>
                                <InputText
                                    type="text"
                                    className={"input w-full"}
                                    value={element.properties.format}
                                    onChange={(e) => handlePropertyChange(index, 'format', e.target.value)}
                                />
                            </div>
                            <div className={"my-auto"}>
                                <label className="flex gap-2 items-center text-xs dark:text-gray-300 cursor-pointer">
                                    <input
                                        type={"checkbox"}
                                           checked={element.properties.useGrouping}
                                           onChange={(e) => handlePropertyChange(index, 'useGrouping', e.target.checked)}
                                    />
                                    {t("useGrouping")}
                                </label>
                            </div>
                        </>

                        :null
                }
            </div>


        </>
    )
}



