import React, {useState} from "react";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SocialMediaPlatformAccount} from "./SocialMediaPlatformAccount";
import TelegramIcon from "../Messengers/SocialIcon/TelegramIcon";
import FacebookIcon from "../Messengers/SocialIcon/FacebookIcon";
import LinkedInIcon from "../Messengers/SocialIcon/LinkedInIcon";
import TwitterIcon from "../Messengers/SocialIcon/TwitterIcon";
import YouTubeIcon from "../Messengers/SocialIcon/YouTubeIcon";
import WhatsAppIcon from "../Messengers/SocialIcon/WhatsAppIcon";
import InstagramIcon from "../Messengers/SocialIcon/InstagramIcon";
import {TelegramAccount} from "./TelegramAccount";
import {useSocialAccount} from "./useSocialAccount";
import {handleGetTelegramAccount} from "./SocialAccountService";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../Services/Globals/validate";
import {socialAccountSchema} from "./socialAccountSchema";
import {ToastContainer} from "react-toastify";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";

export const HandleConnectAccounts = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [IsOpen,setIsOpen] = useState(false);
    const [platform,setPlatform]=useState(null);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [errors,setErrors]=useState({});
    const {handleUpdateSocialAccount,socialAccount,setSocialAccount}=useSocialAccount(setErrors);
    const [refresh,setRefresh]=useState(false);
    const socialMediaData = [
        { imgIcon:  <FacebookIcon sizeStyle={"size-16"}/>,platform:5, title: t("Facebook"),platformName:'faceBook',  handleOpenConnectAccount:(platform)=>handleOpenConnectAccount(platform)},
        { imgIcon:  <TelegramIcon sizeStyle={"size-16"} />,platform:0, title: t("Telegram"), subtitle: "Account",platformName:'telegram', handleOpenConnectAccount:(platform)=>handleOpenConnectAccount(platform),  },
        { imgIcon: <YouTubeIcon sizeStyle={"size-16"} />,platform:7, title: t("YouTube"),platformName:'youtube', subtitle: "Channel", handleOpenConnectAccount:(platform)=>handleOpenConnectAccount(platform),        },
        { imgIcon: <WhatsAppIcon sizeStyle={"size-16"}   />,platform:1, title: t("WhatsApp"),platformName:'whatsApp', subtitle: "Account",  handleOpenConnectAccount:(platform)=>handleOpenConnectAccount(platform),      },
        { imgIcon: <LinkedInIcon sizeStyle={"size-16"}  />,platform:6, title: t("LinkedIn"),platformName:'linkedIn' ,subtitle: "Page or Profile",   handleOpenConnectAccount:(platform)=>handleOpenConnectAccount(platform),},
        { imgIcon: <TwitterIcon sizeStyle={"size-16"} />,platform:9, title: t("Twitter"),platformName:'twitter', subtitle: "Profile",  handleOpenConnectAccount:(platform)=>handleOpenConnectAccount(platform),  },
        { imgIcon: <InstagramIcon sizeStyle={"size-16"} />,platform:4, title: t("Instagram"),platformName:'instagram',subtitle: "Profile",   handleOpenConnectAccount:(platform)=>handleOpenConnectAccount(platform),  },
        { imgIcon: <InstagramIcon sizeStyle={"size-16"} />,platform:2, title: t("Ita"), platformName:'ita',subtitle: "Profile",   handleOpenConnectAccount:(platform)=>handleOpenConnectAccount(platform),  },
    ]
const Refresh=()=>{
    setRefresh((prevState)=>!prevState)
}
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const handleOpenConnectAccount=(platform)=>{
        setPlatform(platform)
        setIsOpen(true);
    }
    const handleCloseConnectAccount=()=>{
        setIsOpen(false);
        setPlatform(null)
        setSocialAccount({
            telegram: {botToken: '',IsExclusiveToken: false,chatId: ''}
        })
        setErrors({});
    }

    const handleGetPlatform=(platform)=>{
        switch (platform) {
            case 0:
                return (<>
                    <TelegramAccount
                        handleUpdateSocialAccount={handleUpdateSocialAccount}
                        socialAccount={socialAccount}
                        errors={errors}
                    />
                </>)
            break;
        }
    }

    const handleConnectAccount=async(e)=>{
        e.preventDefault();
        const isValid = validate(socialAccountSchema,socialAccount.telegram,onSetErrors)
        if(!isValid){
            return;
        }
        try {
            showSpinner();
           switch (platform) {
               case 0:
                   const socialAccountData = handleGetTelegramAccount(platform,socialAccount);
                   const response = await POST(`/SocialAccounts`,socialAccountData,false);
                   if(response.status === 200){
                       Refresh();
                       hideSpinner();
                       handleCloseConnectAccount();
                       redirectTo('/SocialMarketing/Messengers/List')
                   }
                   else{
                       handleApiError(response,hideSpinner);
                   }
               break;
           }
        }
        catch (e) {
            hideSpinner();
        }
    }

    return (
        <>
            <div className="space-y-5 ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Accounts")}
                                            icon={"pi-link"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/List")}
                                            tooltipTitle={t("Accounts")}

                                        />

                                        <ButtonPlus
                                            title={t("list")}
                                            icon={"pi-list"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublicationList")}
                                            tooltipTitle={t("PublicationList")}

                                        />
                                        <ButtonPlus
                                            title={t("Publishing")}
                                            icon={"pi-share-alt"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublishingContent")}
                                            tooltipTitle={t("PublishingContent")}

                                        />
                                        <ButtonPlus
                                            title={t("comments")}
                                            icon={"pi-comments"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/Comments")}
                                            tooltipTitle={t("comments")}
                                            notification={true}
                                        />

                                        <ButtonPlus
                                            title={t("Draft")}
                                            icon={"pi-file-edit"}
                                            onClick={()=>redirectTo("/SocialMarketing/Draft")}
                                            tooltipTitle={t("Draft")}

                                        />

                                    </>
                                }
                    />

                </div>
                <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 box bg-white dark:bg-themeDarkSalt700 p-3">
                    {socialMediaData.map((data, index) => (
                        <SocialMediaPlatformAccount key={index} {...data}  />
                    ))}
                </div>
            </div>

            <FantasyModal openModal={IsOpen}  closeModal={handleCloseConnectAccount} title={t("AddAccount")}>
               <form onSubmit={handleConnectAccount} method={'post'}>
                   <div class="space-y-2 ">


                       {handleGetPlatform(platform)}


                       <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation place-content-center text-white relative"} >
                           {t("insertion")}
                           {IsShowSpinner ? (
                               <TinyLoading
                               />
                           ) : (
                               null
                           )}
                       </button>
                   </div>
               </form>
            </FantasyModal>


            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>

            <ToastContainer/>


        </>
    );
};
