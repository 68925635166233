import client, {BasicUrl} from "../../Services/AxiosService/axiosClient";
import {ExceptionHandlerService} from "../../Services/Globals/Errors/ExceptionHandlerService";
import {fetchAccessToken} from "../../Services/AxiosService/JwtService";

//checking the files existing or not
export const ExistFileHandler = async (paths)=>{
    try {
        const response = await client.post('/Ftp/Files/Exist',{paths:paths})
        if(response.status === 200){
            return {data:response.data,status:response.status};
        }
    }catch (error) {
        return ExceptionHandlerService(error);
    }
}

//Download a file
export const DownloadFileHandler = async (url,fileName) => {

        const link = document.createElement('a');
        link.href = url;
         link.target = '_blank';
        link.download = fileName;
        link.click();
};



export const DownloadFile =async (filters,name,Url)=>{
    try {

        const response = await client.get(Url, {params:filters,responseType: 'blob' });
        //const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            const matches = /filename\*?=UTF-8''(.+)/.exec(contentDisposition);
            if (matches && matches.length > 1) {
                name = matches[1];
            }
        }
        let filename = "downloaded_file";
        if (contentDisposition) {
            const matches = /filename\*?=UTF-8''(.+)/.exec(contentDisposition);
            if (matches && matches[1]) {
                // Decoding the UTF-8 filename
                filename = decodeURIComponent(matches[1]);
            }
        }

        // Creating a Blob for the file data
        const blob = new Blob([response.data], {
            type: response.headers["content-type"],
        });

        // Creating a temporary link element for downloading the file
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response;
    }catch (e) {
        return ExceptionHandlerService(e)
    }
}


// export downloadFile = async () => {
//     try {
//         // API call using Axios
//         const response = await axios.get(
//             "https://core.harmonycms.ir/api/Ftp/DownloadFolder?FilePath=%2Bads%2BArian Team",
//             {
//                 responseType: "blob", // Important to process the file stream
//             }
//         );
//
//         // Getting the filename from content-disposition header
//         const contentDisposition = response.headers["content-disposition"];
//         let filename = "downloaded_file";
//         if (contentDisposition) {
//             const matches = /filename\*?=UTF-8''(.+)/.exec(contentDisposition);
//             if (matches && matches[1]) {
//                 // Decoding the UTF-8 filename
//                 filename = decodeURIComponent(matches[1]);
//             }
//         }
//
//         // Creating a Blob for the file data
//         const blob = new Blob([response.data], {
//             type: response.headers["content-type"],
//         });
//
//         // Creating a temporary link element for downloading the file
//         const link = document.createElement("a");
//         link.href = URL.createObjectURL(blob);
//         link.download = filename;
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     } catch (error) {
//         console.error("Download error:", error);
//     }
// };
//




// export const Download =async (Url,params,fileName)=>{
//     try {
//         try {
//             const response = await client.get(Url, { params:params,responseType: 'blob' });
//             const blob = new Blob([response.data], { type: response.headers['content-type'] });
//             const contentDisposition = response.headers['content-disposition'];
//             if (contentDisposition) {
//                 const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
//                 if (fileNameMatch && fileNameMatch.length > 1) {
//                     fileName = fileNameMatch[1];
//                 }
//             }
//             const url = window.URL.createObjectURL(blob);
//             const a = document.createElement('a');
//             a.href = url;
//             a.download = fileName;
//             a.click();
//             return response;
//         }catch (e) {
//             return ExceptionHandlerService(e)
//         }
//
//     }catch (e) {
//         return ExceptionHandlerService(e)
//     }
// }


// export const Download = async (url,params) => {
//     client.get(url,{ params:params,responseType: 'blob' })
//         .then((response) => {
//             // Try to extract the filename from the Content-Disposition header
//             const contentDisposition = response.headers["content-disposition"];
//             let filename = "downloaded_file";
//             if (contentDisposition) {
//                 const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
//                 let matches = filenameRegex.exec(contentDisposition);
//                 if (matches != null && matches[1]) {
//                     filename = matches[1].replace(/['"]/g, ""); // Remove any surrounding quotes
//                 }
//             }
//             const fileURL = window.URL.createObjectURL(new Blob([response.data]));
//             const fileLink = document.createElement("a");
//             fileLink.href = fileURL;
//             fileLink.setAttribute("download", filename);
//             document.body.appendChild(fileLink);
//             fileLink.click();
//             fileLink.remove();
//             return response;
//         })
//         .catch((error) => {
//             console.error("Download error:", error);
//         });
// };



export const BrowserDownloader = async(filepath,url) => {
    const encodedFilePath = encodeURIComponent(filepath);
    const token = fetchAccessToken();
    window.location.href = `${BasicUrl.baseURL}${url}?FilePath=${encodedFilePath}&Token=${token}`;
};

export const ExportDownloader =(url,params) => {
    const formattedParams = {};

    Object.entries(params).forEach(([key, value]) => {
        if (value instanceof Date) {
            formattedParams[key] = value.toISOString(); // Format date to string
        } else {
            formattedParams[key] = String(value); // Convert value to string
        }
    });

    const queryString = Object.entries(formattedParams).map(([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
    const fullUrl = queryString ? `${url}?${queryString}` : url;
    window.location.href = fullUrl;
};







//Delete file physically.
export const DeleteFileHandler = async (filePath)=>{

    try {
        const response = await client.delete('/Ftp/File', {data: { path: filePath }, headers: { 'Content-Type': 'application/json' }});

        if(response.status === 200){
            return {data:response.data,status:response.status};
        }
    }catch (error) {
        return ExceptionHandlerService(error);
    }

}
//deletes directory physically
export const DeleteDirectoryHandler = async (filePath)=>{
    try {
        const response = await client.delete('/Ftp/Directory', {
            data: { path: filePath },
            headers: { 'Content-Type': 'application/json' }
        });
        if(response.status === 200){
            return {data:response.data,status:response.status};
        }
    }catch (error) {
        return ExceptionHandlerService(error);
    }
}

//Deletes files Logically
export const DeleteFileLogically = async(filePath)=>{
    try {
        const response = await client.delete('/Ftp/File/Delete/Logically', {
            data: { path: filePath },
            headers: { 'Content-Type': 'application/json' }
        });

        if(response.status === 200){
            return {data:response.data,status:response.status};
        }
    }catch (error) {
        return ExceptionHandlerService(error);
    }
}
//Deletes Directory Logically
export const DeleteDirectoryLogically=async(filePath)=>{
    try {
        const response = await client.delete('/Ftp/Directory/Delete/Logically', {
            data: { path: filePath },
            headers: { 'Content-Type': 'application/json' }
        });

        if(response.status === 200){
            return {data:response.data,status:response.status};
        }
    }catch (error) {
        return ExceptionHandlerService(error);
    }
}

export const DeleteAllFilesOrFoldersLogically = async(path)=>{
    try {
        const response = await client.delete('/Ftp/DeleteAllFilesInPathLogically', {data: { path: path }, headers: { 'Content-Type': 'application/json' }});
        if(response.status === 200){
            return {data:response.data,status:response.status};
        }
    }catch (error) {
        return ExceptionHandlerService(error);
    }
}

export const DeleteAllFilesOrFoldersLogicallyPhyzically = async(path)=>{
    try {
        const response = await client.delete('/Ftp/DeleteAllFilesInPath', {data: { path: path }, headers: { 'Content-Type': 'application/json' }});
        if(response.status === 200){
            return {data:response.data,status:response.status};
        }
    }catch (error) {
        return ExceptionHandlerService(error);
    }
}