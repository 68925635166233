import React from "react";

export const ServiceItem=({id,logo,title,description,upgradeType,handleSelectServiceItem})=>{

    const handleChange = () => {
        handleSelectServiceItem(upgradeType);
    };

    return (<>
        <div  className="add_web border-animation post-input dark:bg-themeDarkPostInput post-input-design">
            <label htmlFor={upgradeType} className="cursor-pointer">
                <input type="checkbox" className="checkbox-input hidden"
                       onChange={handleChange}
                       id={upgradeType} />
                <div className="checkbox-tile checkbox-wrapper size-full flex gap-2 p-2 items-center">
                    <div className="size-16 flex-shrink-0 rounded-md overflow-hidden">
                        <img src={logo} alt="" className="size-full object-cover" />
                    </div>
                    <div className="flex-1 p-1 checkbox-label">
                        <h2>{title}</h2>
                        <p className="text-xs">{description}</p>
                    </div>
                </div>
            </label>
        </div>
    </>)
}