import React, {useEffect, useMemo, useState} from "react";

import {Tooltip} from "react-tooltip";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {DELETE, GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Image} from "primereact/image";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import Swal from "sweetalert2";

export const useInformationGrid=(showSpinner,hideSpinner,refresh,Refresh)=>{

    const { t } = useTranslation();
    const [rowData,setRowData] = useState([]);
    const redirectTo = useNavigate();
    const { formId } = useParams();

    const handleDeleteFormDataGroup = async (formDataGroupId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Forms/DataGroups/${formDataGroupId}`,);
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110,hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("Title"),
            cellRenderer:parameters=>
                <>
                {parameters.data.name}
                </>
        },
        { headerName: t("User"), minWidth: 150,
            cellRenderer:parameters=>
                <>
                    {parameters.data.user.fullName}
                </>
        },
        { headerName: t("Image"),field: 'coverImage', minWidth: 140,
            cellRenderer:params => {
                return params.data.user.picture !== null?
                    <Image src={ params.data.user.picture } zoomSrc={ params.data.user.picture } preview />:
                    <HPrimeIcon icon={'pi-times'} className={"text-themeDanger"} />
            }
        },

        { headerName: t("FormCirculationPath"),minWidth: 200,
            cellRenderer:parameters=>
                <>
                    {parameters.data.roadMap === null ? (
                        <div>
                            <HPrimeIcon icon={'pi-times-circle'} iconClass={"text-themeDanger"} />
                        </div>
                    ) : (
                        parameters.data.roadMap
                    )}

                </>
        },


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >

                    <Link to={`/Form/List/Information/Details/${params.data.formDataGroupId}`}>
                        <HPrimeIcon
                        icon={"pi-info-circle"}
                        iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                        />
                    </Link>
                    <GridButtonDelete
                        onClick={()=>handleDeleteFormDataGroup(params.data.formDataGroupId)}
                    />
                    <GridButtonEdit
                        onClick={()=>redirectTo(`/Form/Answer/${params.data.formId}/${params.data.formDataGroupId}`)}
                    />
                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    const [filter,setFilter]=useState({search:'',searchValue:'',isInProgress:null,isCompleted:null,isInternal:null,isExternal:null,});
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:prevState.searchValue}));
    }
    const handleResetFilters=()=>{
        setFilter((prevState)=>({...prevState,search: '',searchValue: ''}));
    }

    const handleSwitchFilters = (data, name) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: data ? data : null
        }));
    }

    const switchFilters = [
        {name:'isInProgress',lable:t("isInProgress"),value:filter.isInProgress},
        {name:'isCompleted',lable:t("isCompleted"),value:filter.isCompleted},
        {name:'isInternal',lable:t("isInternal"),value:filter.isInternal},
        {name:'isExternal',lable:t("isExternal"),value:filter.isExternal},
    ]







    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }


    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.isInternal,filter.isInProgress,filter.isExternal,filter.isCompleted]);


    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Forms/DataGroups`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search:filter.search,FormId:formId,IsInProgress:filter.isInProgress,IsInternal:filter.isInternal,IsExternal:filter.isExternal,IsCompleted:filter.isCompleted}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const items = Paginate(response.items);
                setRowData(items);
            }
            else {
                handleApiError(response,hideSpinner);
            }
        } catch (error) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
        filter,
        setFilter,
        switchFilters,
        handleSwitchFilters

    }



}