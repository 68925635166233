import React, {useState} from "react";

export const useDisplayServiceItem=(setUpgrade,Refresh,initialSource)=>{
    const [display,setDisplay] = useState({IsOpenExtraHost:false,IsOpenExtraMailHost:false});
    const handleSelectServiceItem = (upgradeType) => {
        if (upgradeType === 7) {
            setDisplay((prevState) => ({...prevState, IsOpenExtraHost: !prevState.IsOpenExtraHost}))
            setUpgrade(prevStates=>(
                {...prevStates,
                    extraHost: {
                    ...prevStates.extraHost,
                    extraHost: 100,
                    price: 0,
                },}))
            if(display.IsOpenExtraHost){
                setUpgrade(prevStates=>(
                    {...prevStates,
                        extraHost: {
                            ...prevStates.extraHost,
                            extraHost: 100,
                            price: 0,
                        },}))
            }
           // Refresh()
        }
        else if(upgradeType === 8){

            setDisplay((prevState) => ({...prevState, IsOpenExtraMailHost: !prevState.IsOpenExtraMailHost}))

            setUpgrade(prevStates=>(
                {...prevStates,
                    extraMailHost: {
                        ...prevStates.extraMailHost,
                        extraMailHost: 100,
                        price: 0,
                    },}))
            if(display.IsOpenExtraMailHost){
                setUpgrade(prevStates=>(
                    {...prevStates,
                        extraMailHost: {
                            ...prevStates.extraMailHost,
                            extraMailHost: 100,
                            price: 0,
                        },}))
            }
        }
    };
    return {
        handleSelectServiceItem,
        display,
    }
}