import React from "react";

import {useTranslation} from "react-i18next";
import FieldCreator from "./FieldCreator";

export const Fields=({addElement,fields})=>{
    const { t } = useTranslation();


    return (<>

        <div>

            <div className={"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-1 xl:grid-cols-2 gap-2 "}>
                    {fields.map((element, index) => (
                       <>
                           {
                               !element.isButton?
                                   <div className="col-span-2 md:col-span-3 lg:col-span-1 xl:col-span-2 p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                       <p className={"dark:text-gray-300"}>{element.categoryName}</p>
                                   </div>:
                                   null

                           }
                           <FieldCreator
                               key={index}
                               type={element.type}
                               label={element.label}
                               typeName={element.typeName}
                               addElement={addElement}
                               isButton={element.isButton}
                               t={t}
                           />
                       </>

                    ))}
            </div>
        </div>

    </>)
}