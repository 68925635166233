import React from "react";
import {InputText} from "primereact/inputtext";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
export const UrlElement=({item,index,handleChangeData})=>{

    return (
        <>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
                <label className="dark:text-gray-300 harmony-text-xs">
                    {item.properties.caption}
                </label>
                <InputText
                    type={"text"}
                    onChange={(e) => handleChangeData(index,'value',e.target.value)}
                    value={item.data?.value}
                    placeholder={item.properties.description}
                    readOnly={item.properties.isReadOnly}
                    disabled={item.properties.isReadOnly}
                    name={item.properties.name}
                    required={item.properties.isReadOnly}
                />
            </div>

        </>
    )
}