import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {GridTable} from "../../../Commans/Grid/GridTable";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {useNavigate} from "react-router-dom";
import { handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../../Contents/ContentService";
// import 'primereact/dialog/dialog.min.css';
import {Dialog} from "primereact/dialog";

import Swal from "sweetalert2";
import {codeSchema} from "./codeSchema";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import Select from "react-select";

import {Pagination} from "../../../Commans/Grid/Pagination";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {Export} from "../../../Commans/Grid/Export";
import {ShortCode} from "../../../Commans/Globals/ShortCode";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {Tooltip} from "react-tooltip";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {useShortCode} from "./useShortCode";
import {useShortCodeGrid} from "./useShortCodeGrid";
import {handleCreateShortCode, handleEditShortCode} from "./shortCodeService";
import Editor from "@monaco-editor/react";

export const HandleTemplateShortCode=()=>{
    const { t } = useTranslation();
    const [IsOpen,setIsOpen] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [errors, setErrors] = useState({});
    const[IsshowSpinner,setIsShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({templates:[]});
    const redirectTo = useNavigate();
    const[refresh,setRefresh] = useState(false);


    const {handleIsHtml,handleValue,handleSelectValue,setShortCode,shortCode,handleEditorChange}=useShortCode(setErrors);
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState)
    }

    const handleOpenShortCode=()=>{
        setShortCode((prevState)=>({...prevState,templateId:initialSource.templates[0]}));
        setIsOpen(true)
    }

    const handleCloseShortCode=()=>{
        setIsOpen(false);
        setShortCode({name: '',type: 0,value:'',id: null,templateId: initialSource.templates[0]})
        setIsEdit(false);
        setErrors({});
    }

    const handleInitialDataSource=(templates)=>{
        const templatesData = convertSelectDataHandler(templates);
        setInitialSource({templates: templatesData});
        return {
            templatesData
        }
    }


    const fetchData =async ()=>{
        try {
            showSpinner();
            const responseTemplate = await GET('/Templates/GetAllTemplatesSelect',{},'IEnumerable');
            if(responseTemplate.status === 200){
                handleInitialDataSource(responseTemplate.data)
                hideSpinner();
            }
        }
        catch (e) {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData()
    },[])





    const handleAddCodeSnippet = async(e)=>{
        e.preventDefault();
        const isValid = validate(codeSchema,shortCode,onSetErrors);
        if (!isValid) {
            return;
        }
        try {
          if(isEdit){
              showSpinner();
              const codes = handleEditShortCode(shortCode);
              const response = await PUT(`/Templates/EditSnippetCode`,codes,false);
              if (response.status === 200) {
                  hideSpinner();
                  Refresh();
                  handleCloseShortCode();
                  handleSuccessMessage(t("Shortcodehasbeensuccessfullyedited"));
              } else {
                  handleApiError(response,hideSpinner);
              }
          }
          else{
              showSpinner()
              const codes = handleCreateShortCode(shortCode);
              const response = await POST('/Templates/CreateSnippetCode',codes,false);
              if (response.status === 200) {
                  hideSpinner()
                  Refresh();
                  handleCloseShortCode();
                  handleSuccessMessage(t("Shortcodehasbeensuccessfullycreated"));
              } else {
                  handleApiError(response,hideSpinner);
              }
          }
        }
        catch (e) {
            hideSpinner();
        }
    }

    const {currentPage,filter,search,setSearch,visible,message,setFilter,handleShowMessage,defaultColDef,rowData,data,setVisible,handleSearch,handleCurrentPage,handleNextPage,handlePreviousPage,handlePageSizeCount,columnDefs,autoGroupColumnDef,getRowHeight,resetFilters,footerContent, filterValueHandler}=useShortCodeGrid(showSpinner,hideSpinner,refresh,initialSource,setShortCode,setIsEdit,setIsOpen,Refresh)



    const options = {
        autoIndent: 'full',
        contextmenu: true,
        fontFamily: 'monospace',
        fontSize: 13,
        lineHeight: 24,
        hideCursorInOverviewRuler: true,
        matchBrackets: 'always',
        minimap: {
            enabled: true,
        },
        scrollbar: {
            horizontalSliderSize: 4,
            verticalSliderSize: 18,
        },
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,
    };




    return(
        <>

            <Dialog header={t("TagContent")} visible={visible} footer={footerContent} className={"Dialog-ShowText"} onHide={() => setVisible(false)} >
                {message}
            </Dialog>

            <div class=" space-y-5 relative">
                {

                    IsshowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <div class="rounded-md flex items-center p-3 bg-themeBgAlert  gap-2 text-xs">
                        <div>
                            <span class="relative size-3">
                                <span class="animate-ping absolute size-full rounded-full bg-themeDanger opacity-75"></span>
                                <span class="relative block rounded-full size-3 bg-themeDanger"></span>
                            </span>
                        </div>
                        <div class="space-y-1">
                        <p>{t("TitleShortCodeTwo")}</p>
                    </div>
                </div>
                    <ListSearch label={null} icon={null} >
                        <div className="space-y-2">
                            <label className="dark:text-gray-300 text-xs">{t("SearchByTitle")}</label>
                            <InputText type="text" value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("Basedonthetemplate")}</label >
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                    </ListSearch>
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => handleOpenShortCode()}
                                        tooltipTitle={t("CreatingAShortCode")}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/Template/Add`)}
                                        title={t("template")}
                                        tooltipTitle={t("Template")}
                                        icon={"pi-objects-column"}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo("/CMS/Template/Choose")}
                                        title={t("Buy")}
                                        tooltipTitle={t("BuyATemplate")}
                                        icon={"pi-cart-arrow-down"}
                                    />
                                </>
                            }

                />

            </div>
            <div class="space-y-2 H-body-ag-grid" >
                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridPersion}
                />
                <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />

            </div>
            <Export/>

        </div>

            {/*<FantasyModal openModal={modal.see}  closeModal={seeOnHide} title={"ایجاد کد کوتاه"}>*/}
            {/*    <div class=" p-3">*/}
            {/*        <div class="flex items-center justify-center border-b p-2">*/}
            {/*            <h3 className="text-xs text-themeInformation dark:bg-themeDarkInformation">(tagfooter)</h3>*/}
            {/*        </div>*/}
            {/*        <div className="p-2 space-y-2">*/}
            {/*            <div className="max-h-28 overflow-y-auto">*/}
            {/*                <p class="text-xs dark:text-gray-300 ">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A alias aliquid assumenda atque beatae commodi deleniti ducimus error, esse iure labore laudantium maiores nam non optio provident saepe sequi unde ut voluptas. Eaque illo ipsam itaque nostrum velit vero vitae. Autem dolor dolorem, ea excepturi explicabo illum libero possimus quos similique soluta. Deleniti deserunt dolor ea expedita facilis fugiat id iste, modi molestiae non nostrum omnis possimus quis repellat similique sit soluta! Aut consectetur, delectus, dolore earum et facere fugiat id inventore natus nesciunt nulla optio porro rem reprehenderit sapiente sequi sit tempore temporibus! Adipisci fuga placeat sunt veniam voluptatum.</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</FantasyModal>*/}


            <FantasyModal openModal={IsOpen}  closeModal={handleCloseShortCode} title={t("CreatingAShortCode")} customeClassName={"modal-xl"}>
                <ShortCode
                 shortCode={shortCode}
                 handleAddCodeSnippet={handleAddCodeSnippet}
                 errors={errors}
                 handleValue={handleValue}
                 handleEditorChange={handleEditorChange}
                 options={initialSource}
                 handleSelectValue={handleSelectValue}
                 handleIsHtml={handleIsHtml}
                />

            </FantasyModal>
            {console.log(shortCode)}


            <ToastContainer/>
        </>

    )
}