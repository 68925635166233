import {useState} from "react";

export const useDataClean=()=>{

    const [cleanData,setClean] = useState({section:[],fromDate:null,toDate:null})

    // handles dateTime for fromDate
    const handleFromDate=(dateTime)=>{
        setClean(prevState => ({...prevState,fromDate: dateTime}));
    }
    //handles dateTime for ToDate
    const handleToDate=(dateTime)=>{
        setClean(prevState => ({...prevState,toDate: dateTime}));
    }
    // updating sections
    const handleChangeSection = (selectedItem) => {
        setClean((prevState) => {
            const isItemExist = prevState.section.some((item) => item.value === selectedItem.value);
            if (isItemExist) {
                return {
                    ...prevState,
                    section: prevState.section.filter((item) => item.value !== selectedItem.value)
                };
            } else {
                return {
                    ...prevState,
                    section: [...prevState.section, selectedItem]
                };
            }
        });
    };
    // handles domainTokens

    //resets data initially
    const ResetCleanData=()=>{
        setClean({toDate: null,fromDate: null,section: []})
    }




    return {
        cleanData,
        setClean,
        handleFromDate,
        handleToDate,
        handleChangeSection,
        ResetCleanData
    }
}