import React, {useState} from "react";
import {TabMenu} from "../../Commans/UIParts/TabMenu";

import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";


export const HandleDetailsProject = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("tab_one");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
  const tabs = [
    {
      id: "tab_one",

      label: "گفتگو",
      // subLabel:"مشخصات",
      icon: "file-text",
    },
    {
      id: "tab_two",

      subLabel:"وظایف",
      // label: "اضافه",
      icon: "plus-square",
    },
    {
      id: "tab_three",

      label: "گزارش",
      // subLabel:"گزارش",
      icon: "settings",
    },
    {
      id: "tab_four",

      label: "تقویم",
      // subLabel:"گالری",
      icon: "image",
    },


  ];




  return(

      <div className="space-y-5">
        <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
          <ListFilter label={null} icon={null}
                      childrenOne={
                        <>


                        </>
                      }
                      childrenTwo={
                        <>

                        </>
                      }
          />

        </div>
        <div class="post box  bg-white dark:bg-themeDarkSalt700">
          <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
            <div class="post__content tab-content">
              <div className={`tab-content__pane p-1 md:p-3  ${ activeTab === "tab_one" ? "active" : "" }`}  id="tab_one">
                <div class="chat gap-2 space-y-5">
                  <div class="grid grid-cols-1 lg:grid-cols-12 gap-3 post-input dark:bg-themeDarkPostInput p-2 rounded">
                    <div class="lg:col-span-2 p-2 bg-themeInformation dark:bg-themeDarkInformation rounded-md">
                      <div class=" cursor-pointer relative flex gap-2  p-2">
                        <div class="w-20 flex items-center justify-center">
                          <div class="relative w-16 h-16">
                            <img alt="harmony" className="rounded-full w-16 h-16" src="/Assets/Image/user/person1.png"/>
                              <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>
                          </div>
                        </div>
                        <div class="">
                          <div class="flex flex-col gap-2 h-full justify-between text-xs ">
                            <div class="flex gap-2">
                              <h2 class="whitespace-no-wrap text-white dark:text-gray-300">نام پروژه:</h2>
                              <p class="text-amber-500">فرهنگیان </p>
                            </div>
                            <div>
                              <h2 class="text-white">تالار گفتگو</h2>
                            </div>
                            <div class="flex justify-between">
                              <h2 class="text-white">تعدادآنلاین: </h2>
                              <h3 class="text-themeSuccess">(10)</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="lg:col-span-10">
                      <div className="flex overflow-x-auto gap-2 chat-user h-full">
                        <div class="w-32 cursor-pointer bg-themeNotif rounded flex flex-col justify-center gap-3  items-center">
                          <div class="w-12 h-12 mx-auto relative">
                              <img alt="harmony" className="rounded-full"
                                   src="/Assets/Image/user/person1.png"/>
                              <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>
                            </div>
                          <div class="text-center">
                              <h2 class="line-clamp-1 text-xs">مسعود احمدی </h2>
                            </div>
                        </div>
                        <div class="w-32 cursor-pointer box bg-white rounded flex flex-col justify-center gap-3  items-center">
                          <div class="w-12 h-12 mx-auto relative">
                              <img alt="harmony" className="rounded-full"
                                   src="/Assets/Image/user/person1.png"/>
                              <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>
                            </div>
                          <div class="text-center">
                              <h2 class="line-clamp-1 text-xs">مسعود احمدی </h2>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box bg-white dark:bg-themeDarkSalt700 border border-gray-300 dark:border-themeDarkColorLine rounded-md ">
                    <div class=" flex justify-between items-center post-input dark:bg-themeDarkPostInput gap-2 p-2 rounded-t-md">
                          <div class="flex items-center gap-2">
                            <div class="w-10 h-10 sm:w-12 sm:h-12 flex-none relative">
                              <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                            </div>
                            <div className={"space-y-2 relative"}>
                                <div class="dark:text-gray-300">مسعود احمدی</div>
                              <div className={"flex "}>
                                <p className="text-xs dark:text-gray-300">سلام,</p>
                                <h2 className={"text-xs text-themeSuccess"}> من آنلاین هستم</h2>
                              </div>
                            </div>
                          </div>
                          <div class="flex items-center justify-end gap-3">
                            <a href="javascript:;" className="w-5 h-5 dark:text-gray-300">
                              <HPrimeIcon icon={'search'} size={20}/>
                            </a>
                            <a href="javascript:;" className="w-5 h-5 dark:text-gray-300">
                              <HPrimeIcon icon={'user-plus'} size={20}/>
                            </a>
                            <div class="dropdown relative">
                              <a href="javascript:;" className="dropdown-toggle w-5 h-5 dark:text-gray-300">
                                <HPrimeIcon icon={'more-vertical'} size={20}/>
                              </a>
                              <div class="dropdown-box mt-6 absolute w-40 top-0 end-0 z-20">
                                <div class="dropdown-box__content box p-2 bg-themeInformation dark:bg-themeDarkInformation">
                                  <a href="" className="flex items-center gap-2 p-2 rounded-md hover:bg-themeHoverInformation text-white dark:text-gray-300">
                                    <HPrimeIcon icon={'share-2'} size={20}/>
                                    اشتراک گزاشتن
                                  </a>
                                  <a href="" className="flex items-center gap-2 p-2 rounded-md text-white dark:text-gray-300 hover:bg-themeHoverInformation">
                                    <HPrimeIcon icon={'settings'} size={20}/>
                                    تنظیمات
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    <div>
                          <ul class="overflow-y-auto chat-user px-4 pt-5 flex flex-col space-y-5 h-screen">
                            <li className="sent-message flex justify-start">
                              <div class="chat__box__text-box flex">
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-end ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="hidden sm:block dropdown relative ms-2 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box absolute w-24 top-5 start-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="received-message flex justify-end">
                              <div class="chat__box__text-box flex">
                                <div class="hidden sm:block dropdown relative ms-3 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box  absolute w-24 top-5 end-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-start ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                              </div>
                            </li>
                            <li className="sent-message flex justify-start">
                              <div class="chat__box__text-box flex">
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-end ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="hidden sm:block dropdown relative ms-2 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box absolute w-24 top-5 start-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="received-message flex justify-end">
                              <div class="chat__box__text-box flex">
                                <div class="hidden sm:block dropdown relative ms-3 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box  absolute w-24 top-5 end-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-start ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                              </div>
                            </li>
                            <li className="sent-message flex justify-start">
                              <div class="chat__box__text-box flex">
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-end ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="hidden sm:block dropdown relative ms-2 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box absolute w-24 top-5 start-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="received-message flex justify-end">
                              <div class="chat__box__text-box flex">
                                <div class="hidden sm:block dropdown relative ms-3 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box  absolute w-24 top-5 end-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-start ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                              </div>
                            </li>
                            <li className="sent-message flex justify-start">
                              <div class="chat__box__text-box flex">
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-end ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="hidden sm:block dropdown relative ms-2 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box absolute w-24 top-5 start-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="received-message flex justify-end">
                              <div class="chat__box__text-box flex">
                                <div class="hidden sm:block dropdown relative ms-3 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box  absolute w-24 top-5 end-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-start ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                              </div>
                            </li>
                            <li className="sent-message flex justify-start">
                              <div class="chat__box__text-box flex">
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-end ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="hidden sm:block dropdown relative ms-2 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box absolute w-24 top-5 start-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="received-message flex justify-end">
                              <div class="chat__box__text-box flex">
                                <div class="hidden sm:block dropdown relative ms-3 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box  absolute w-24 top-5 end-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-start ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                              </div>
                            </li>
                            <li className="sent-message flex justify-start">
                              <div class="chat__box__text-box flex">
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-end ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="hidden sm:block dropdown relative ms-2 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box absolute w-24 top-5 start-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="received-message flex justify-end">
                              <div class="chat__box__text-box flex">
                                <div class="hidden sm:block dropdown relative ms-3 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box  absolute w-24 top-5 end-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-start ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                              </div>
                            </li>
                            <li className="sent-message flex justify-start">
                              <div class="chat__box__text-box flex">
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-end ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="hidden sm:block dropdown relative ms-2 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box absolute w-24 top-5 start-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li className="received-message flex justify-end">
                              <div class="chat__box__text-box flex">
                                <div class="hidden sm:block dropdown relative ms-3 my-auto">
                                  <a href="javascript:;" className="dropdown-toggle w-4 h-4 dark:text-gray-300">
                                    <HPrimeIcon icon={"more-vertical"} size={20}/>
                                  </a>
                                  <div class="dropdown-box  absolute w-24 top-5 end-0 z-20 shadow-md">
                                    <div class="dropdown-box__content box bg-themeInformation dark:bg-themeInformation p-1 space-y-1">
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"corner-up-left"} size={20}/>
                                        پاسخ
                                      </a>
                                      <a href="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-white dark:text-gray-300">
                                        <HPrimeIcon icon={"trash"} size={20}/>
                                        حذف
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div class="post-input dark:bg-themeDarkPostInput p-3 dark:text-gray-300 rounded-md text-xs space-y-2">
                                  <p className="leading-7">
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                    با سلام خدمت قیمت فرش 1000 شانه چنده
                                  </p>
                                  <div class="text-xs text-themeInformation dark:text-themeDarkInformation flex justify-start ">
                                    <h3>2دقیقه قبل</h3>
                                  </div>
                                </div>
                                <div class="w-10 h-10 hidden sm:block flex-none my-auto m-2">
                                  <img alt="harmony" className="rounded-full object-cover" src="/Assets/Image/user/person1.png"/>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                    <div class=" flex items-center flex-col lg:flex-row  p-2 relative">
                      <div class="w-full flex items-center">
                            <textarea className="input w-full h-16 resize-none post-input px-5 py-3 focus:shadow-none"
                                rows="1" placeholder="پیام خود را وارد کنید..."></textarea>
                        <button type={"button"}  className="w-8 h-8 sm:w-10 sm:h-10  bg-themeInformation text-white rounded-md  flex items-center justify-center">
                          <HPrimeIcon icon={"send"}/>
                        </button>
                      </div>
                      <div class="w-full lg:w-auto flex justify-end ">
                        <div class="dropdown relative mr-3 sm:mr-5">
                          <button type={"button"} className="dropdown-toggle w-4 h-4 sm:w-5 sm:h-5 block text-gray-500">
                            <HPrimeIcon icon={"smile"}/>
                          </button>
                        </div>
                        <div class="w-5 h-5 relative text-gray-500 ">
                          <HPrimeIcon icon={"paperclip"}/>
                          <input type="file" className="w-full h-full top-0 end-0 absolute opacity-0"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`tab-content__pane p-1 md:p-3  ${ activeTab === "tab_two" ? "active" : "" }`}  id="tab_two">
                <div class="grid grid-cols-12 post-input dark:bg-themeDarkSalt700 p-3 rounded gap-3 ">
                  <div class="col-span-12 lg:col-span-4 flex gap-5 items-center justify-center">
                    <div class="flex flex-1 gap-2 items-center justify-center">
                      <label  className="text-nowrap text-xs dark:text-gray-300">ایجاد وظیفه:</label>
                      <MultiDataSelect placeholder={"انتخاب"} />
                    </div>
                    <div>
                      <button type={"button"}  className="button bg-themeInformation dark:bg-themeDarkInformation text-white">
                        <HPrimeIcon icon={"plus"}/>
                      </button>
                    </div>
                  </div>

                  <div class="min-h-screen col-span-12">

                  </div>
                </div>
              </div>
              <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_three" ? "active" : "" }`}  id="tab_three">
                  <div class="  grid grid-cols-12 gap-3">
                    <div class="col-span-12 lg:col-span-3">
                      <div class=" p-2 post-input dark:bg-themeDarkPostInput rounded  ">
                        <div class=" border-b py-3 border-gray-300">
                          <div class="space-y-2">
                            <label className={"text-xs dark:text-gray-300"}>اعضاء:</label>
                            <div class="flex gap-3 items-center">
                              <MultiDataSelect placeholder={"انتخاب"} />
                              <div>
                                <button type={"button"} className="button bg-themeInformation text-white">
                                  <HPrimeIcon icon={"plus"}/>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class=" py-3 space-y-2 border-b border-gray-300">
                          <div class="text-center text-xs dark:text-gray-300">
                            <h2>
                              وضعیت کلی پروژه:
                              ((
                              <span>تعداد باقیمانده:1</span>
                              ))
                            </h2>
                          </div>
                          <canvas className="" id="report-donut-chart" height="200"></canvas>
                          <div class=" space-y-2">
                            <div class="flex items-center gap-1">
                              <div class="w-2 h-2 bg-themeInformation dark:bg-themeDarkInformation rounded-full"></div>
                              <span class="text-xs dark:text-gray-300">انجام شده</span>
                              <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine"></div>
                              <span class="text-xs dark:text-gray-300">62%</span>
                            </div>
                            <div class="flex items-center gap-1">
                              <div class="w-2 h-2 bg-themeInformation dark:bg-themeDarkInformation rounded-full"></div>
                              <span class="text-xs dark:text-gray-300">درحال انجام</span>
                              <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine"></div>
                              <span class="text-xs dark:text-gray-300 ">33%</span>
                            </div>
                            <div class="flex items-center gap-1">
                              <div class="w-2 h-2 bg-themeSuccess rounded-full"></div>
                              <span class="text-xs dark:text-gray-300">پایان یافته</span>
                              <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine "></div>
                              <span class="text-xs dark:text-gray-300">10%</span>
                            </div>
                          </div>
                        </div>
                        <div class=" border-b py-3 border-gray-300">
                          <div class="space-y-2">
                            <div class="text-center text-xs dark:text-gray-300">
                              <h2>
                                کارهای من:
                                ((
                                <span>1</span>
                                ))
                              </h2>
                            </div>
                            <div class=" space-y-2">
                              <div class="flex items-center gap-1">
                                <div class="w-2 h-2 bg-themeSuccess rounded-full"></div>
                                <span class="text-xs dark:text-gray-300">انجام شده:</span>
                                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine "></div>
                                <span class="text-xs dark:text-gray-300">8</span>
                              </div>
                              <div class="flex items-center gap-1">
                                <div class="w-2 h-2 bg-themeSuccess rounded-full"></div>
                                <span class="text-xs dark:text-gray-300">درحال انجام:</span>
                                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine"></div>
                                <span class="text-xs dark:text-gray-300">2</span>
                              </div>
                              <div class="flex items-center gap-1">
                                <div class="w-2 h-2 bg-themeSuccess rounded-full"></div>
                                <span class="text-xs dark:text-gray-300">پایان یافته:</span>
                                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine"></div>
                                <span class="text-xs dark:text-gray-300">20</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class=" border-b py-3 border-gray-300">
                          <div class="space-y-2">
                            <div class="text-center text-xs dark:text-gray-300">
                              <h2>
                                کارهای دیگران:

                              </h2>
                            </div>
                            <div class="space-y-2">
                              <div class="flex items-center gap-1">
                                <div class="w-2 h-2 bg-themeSuccess rounded-full"></div>
                                <span class="text-xs dark:text-gray-300">انجام شده:</span>
                                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine "></div>
                                <span class="text-xs dark:text-gray-300">8</span>
                              </div>
                              <div class="flex items-center gap-1">
                                <div class="w-2 h-2 bg-themeSuccess rounded-full"></div>
                                <span class="text-xs dark:text-gray-300">درحال انجام:</span>
                                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine"></div>
                                <span class="text-xs dark:text-gray-300">2</span>
                              </div>
                              <div class="flex items-center gap-1">
                                <div class="w-2 h-2 bg-themeSuccess rounded-full"></div>
                                <span class="text-xs dark:text-gray-300">پایان یافته:</span>
                                <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-2 dark:border-themeDarkColorLine"></div>
                                <span class="text-xs dark:text-gray-300">20</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-span-12 lg:col-span-9 post-input dark:bg-themeDarkPostInput p-3 rounded">
                      <div class="grid grid-cols-12 post-input dark:bg-themeDarkSalt700 p-3 rounded gap-3">
                        <div class="col-span-12 lg:col-span-4 flex gap-5 items-center justify-center">
                          <div class="flex flex-1 gap-2 items-center justify-center">
                            <label  className="text-nowrap text-xs dark:text-gray-300"> نمایش:</label>
                            <MultiDataSelect placeholder={"انتخاب"} />
                          </div>
                        </div>
                        <div class="col-span-12 lg:col-span-3 lg:col-start-10 relative">
                          <input type="search" className="input w-full  "
                                 placeholder="جستجو وظیفه..."/>

                        </div>

                      </div>
                      <div class=" datatable-wrapper box p-3">
                        <table className="table table-report table-report--bordered display datatable w-full">
                          <thead>
                          <tr>
                            <th className="border-b-2 whitespace-no-wrap">ردیف</th>
                            <th className="border-b-2 text-center whitespace-no-wrap"> پروژه</th>
                            <th className="border-b-2 text-center whitespace-no-wrap">عنوان پروژه</th>
                            <th className="border-b-2 text-center whitespace-no-wrap">تاریخ شروع</th>
                            <th className="border-b-2 text-center whitespace-no-wrap">تاریخ پایان</th>

                            <th className="border-b-2 text-center whitespace-no-wrap">عملیات</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td className="border-b ">
                              <div class="flex justify-center items-center gap-2">
                                <input type="checkbox"/>
                                  <h3>1</h3>
                              </div>
                            </td>
                            <td className="text-center border-b w-24">
                              <a href="#" className="line-clamp-1 block text-theme-20">کاوه کویر</a>
                            </td>
                            <td className="text-center border-b">
                              <h3>
                                پیگیری های صورت گرفته در خصوص پرداخت های ریالی
                              </h3>
                            </td>
                            <td className="text-center border-b">
                              <h3>
                                1401/21/01
                              </h3>
                            </td>
                            <td className="text-center border-b space-y-2">
                              <h3>1401/21/01</h3>
                            </td>
                            <td className="text-center border-b">
                              <div class="flex justify-center items-center gap-2 text-xs">
                                <a className="flex items-center bg-theme-9 text-white gap-1 px-2 py-1 text-white rounded"
                                   href="#"> <i data-feather="check-square" class="w-4 h-4"></i>ویرایش</a>
                                <a className="flex items-center bg-theme-6 text-white gap-1 px-2 py-1 text-white rounded"
                                   href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف </a>
                              </div>
                            </td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col sm:flex-row items-center  justify-end">

                </div>
              </div>
              <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_four" ? "active" : "" }`}  id="tab_four">
                <div class="post">
                  <div class="post__tabs nav-tabs flex bg-gray-200 text-gray-600">
                    <a title="Use search keywords" data-toggle="tab2" data-target="#setting5" href="javascript:"
                       className="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center md:gap-2 active">
                      <i data-feather="align-left" class="w-4 h-4"></i>تقویم جلالی</a>
                    <a title="Use search keywords" data-toggle="tab2" data-target="#setting6" href="javascript:"
                       className="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center md:gap-2">
                      <i data-feather="align-left" class="w-4 h-4"></i>تقویم میلادی </a>
                    <a title="Use search keywords" data-toggle="tab2" data-target="#setting7" href="javascript:"
                       className="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center md:gap-2">
                      <i data-feather="align-left" class="w-4 h-4"></i> تقویم هجری </a>
                  </div>
                </div>
                <div class="post__content2 tab-content2">
                  <div class="tab-content__pane2 p-1 md:p-3 active" id="setting5">
                    <div id="demo-jalali"></div>
                  </div>
                  <div class="tab-content__pane2 p-1 md:p-3" id="setting6">
                    <div id="demo-gregorian"></div>
                  </div>
                  <div class="tab-content__pane2 p-1 md:p-3" id="setting7">
                    <div id="demo-hijri"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="modal" id="project-dashboard">
          <div class="modal__content modal__content--lg">
            <div class="space-y-3 w-full rounded-md post-input p-3">
              <div class="post box">
                <div class="post__tabs nav-tabs flex bg-gray-200 text-gray-600">
                  <a title="Use search keywords" data-toggle="tab" data-target="#setting-1" href="javascript:"
                     className="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center md:ap-2 active">
                    <i data-feather="align-left" class="w-4 h-4"></i>
                    برچسب
                  </a>
                  <a title="Use search keywords" data-toggle="tab" data-target="#setting-2" href="javascript:"
                     className="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center md:ap-2">
                    <i data-feather="align-left" class="w-4 h-4"></i>فایل
                    ضمیمه</a>
                </div>
                <div class="post__content tab-content">
                  <div class="tab-content__pane p-1 md:p-3 space-y-2 active" id="setting-1">
                    <div class="post-input p-2 rounded">
                      <label> پروژه:</label>
                      <div class="mt-1">
                        <MultiDataSelect placeholder={"انتخاب"} />
                      </div>
                    </div>
                    <div class="post-input p-2 rounded">
                      <label>عنوان پروژه:</label>
                      <input type="text" name="" id="" className="input w-full border"/>
                    </div>
                    <div class="grid grid-cols-12 gap-2 post-input p-2 rounded">
                      <div class="col-span-4 lg:col-span-5 space-y-1">
                        <label htmlFor="">تاریخ شروع:</label>
                        <input type="text" name="" id="" className="input w-full border rounded hdate"/>
                      </div>
                      <div class="col-span-4 lg:col-span-5 space-y-1">
                        <label htmlFor="">تاریخ پایان:</label>
                        <input type="text" name="" id="" className="input w-full border rounded hdate"/>
                      </div>
                      <div class="col-span-4 lg:col-span-2 space-y-1">
                        <label htmlFor="">درصد پروژه:</label>
                        <input type="text" name="" id="" className="input w-full border rounded"/>
                      </div>
                    </div>
                    <div class="grid grid-cols-12 gap-2 post-input p-2 rounded">
                      <div class="col-span-9 lg:col-span-10">
                        <label>انتخاب اعضاء:</label>
                        <div class="mt-1">
                          <MultiDataSelect placeholder={"انتخاب"} />
                        </div>
                      </div>
                      <div class="col-span-3 lg:col-span-2 flex items-end gap-2">
                        <button className="p-1 bg-theme-9 rounded-md text-white mb-2">
                          <i class="w-4 h-4" data-feather="plus"></i>
                        </button>
                        <button className="p-1 bg-theme-6 rounded-md text-white mb-2">
                          <i class="w-4 h-4" data-feather="trash"></i>
                        </button>
                      </div>
                    </div>
                    <div class="flex gap-2 post-input p-2 rounded">
                      <div class="flex-1 space-y-2">
                        <label> چک لیست:</label>
                        <ul class="CloneData space-y-2">
                          <li className="CloneRow">
                            <div class="relative">
                              <div
                                  class="absolute rounded-r w-10 h-full flex items-center justify-center border text-gray-600 AddClone hover:bg-theme-9 hover:text-white cursor-pointer z-50  ">
                                +
                              </div>
                              <div
                                  class="absolute rounded-l end-0 w-10 h-full flex items-center justify-center border text-gray-600">
                                <span>60%</span>
                              </div>
                              <input type="text" className="input px-12 w-full border col-span-4 hover:bg-theme-17"
                                     placeholder="نام یا عنوان وظیفه"/>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="grid grid-cols-12 gap-2 post-input p-2 rounded">
                      <div class="col-span-9 lg:col-span-10">
                        <label>انتخاب اعضاء:</label>
                        <div class="mt-1">
                          <MultiDataSelect placeholder={"انتخاب"} />
                        </div>
                      </div>
                      <div class="col-span-3 lg:col-span-2 flex items-end gap-2">
                        <button className="p-1 bg-theme-9 rounded-md text-white mb-2">
                          <i class="w-4 h-4" data-feather="plus"></i>
                        </button>
                        <button className="p-1 bg-theme-6 rounded-md text-white mb-2">
                          <i class="w-4 h-4" data-feather="trash"></i>
                        </button>
                      </div>
                    </div>
                    <div class="grid grid-cols-12 gap-2 post-input p-2 rounded">
                      <div class="col-span-9 lg:col-span-10">
                        <label> دستوالعمل:</label>
                        <div class="mt-1">
                          <MultiDataSelect placeholder={"انتخاب"} />
                        </div>
                      </div>
                      <div class="col-span-3 lg:col-span-2 flex items-end gap-2">
                        <button className="p-1 bg-theme-9 rounded-md text-white mb-2">
                          <i class="w-4 h-4" data-feather="plus"></i>
                        </button>
                        <button className="p-1 bg-theme-6 rounded-md text-white mb-2">
                          <i class="w-4 h-4" data-feather="trash"></i>
                        </button>
                      </div>
                    </div>
                    <div class="flex gap-3 justify-center post-input py-2">
                      <button className="button w-24 bg-theme-6 text-white shadow-md" data-dismiss="modal">
                        انصراف
                      </button>
                      <button className="button w-24 text-white bg-theme-9 shadow-md">
                        ایجاد
                      </button>
                    </div>
                  </div>
                  <div class="tab-content__pane p-1 md:p-3 min-h-min" id="setting-2">
                    <form action="/file-upload" className="dropzone border-gray-200 border-dashed">
                      <div class="fallback">
                        <input name="file" type="file" multiple/>
                      </div>
                      <div class="dz-message" data-dz-message>
                        <div class="text-lg ">
                          فایل ها را اینجا رها کنید یا برای آپلود کلیک کنید.
                        </div>
                      </div>
                    </form>
                    <div class="flex gap-3 justify-center post-input py-2">
                      <button className="button w-24 bg-theme-6 text-white shadow-md" data-dismiss="modal">
                        انصراف
                      </button>
                      <button className="button w-24 text-white bg-theme-9 shadow-md">
                        ایجاد
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


  )
}