import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE, GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GetDynamicPropertiesFromServer} from "../AddingNumbers/NumbersService";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";

export const useSecretaryGrid=(showSpinner,hideSpinner,refresh,Refresh,setIsEdit,setSec,initialSource,setIsOpen)=>{
    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const [rowData, setRowData] = useState([]);


    const [visible, setVisible] = useState(false);
    const [message,setMessage] = useState('');

    const handleShowMessage =(visible,message)=>{
        setVisible(visible);
        setMessage(message);
    }

    const handleGetSecretary= async(secId)=>{
        try {
             showSpinner()
             setIsEdit(true);
            const response = await GET(`/Messaging/Secretaries/${secId}`,{},'single');
            if(response.status === 200){
                setSec({
                    smsSecretaryId:response.data.smsSecretaryId,
                    message:response.data.message,
                    pattern:response.data.pattern,
                    accountId:initialSource.accounts.find((item)=>item.value === response.data.accountId),
                })
                setIsOpen(true)
                 hideSpinner();

            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleDeleteSecretary = async (secId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner()
                    const response = await DELETE(`/Messaging/Secretaries/${secId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        hideSpinner()
                        Refresh(response.status)
                        handleSuccessMessage(t("AccountDeletedSuccessfully"))
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("MessageCode"),maxWidth: 150,
            cellRenderer:parameters=>
                <>
                {parameters.data.pattern}
                </>
        },
        { headerName: t("DefaultText"),
            cellRenderer:parameters=>
                <div >
                    <button type={"button"} onClick={() => handleShowMessage(true,parameters.data.message)}>
                        <p className={"line-clamp-1 underline hover:text-themeInformation dark:hover:text-themeDarkInformation"}> {parameters.data.message}</p>
                    </button>

                </div>

        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetSecretary(params.data.smsSecretaryId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteSecretary(params.data.smsSecretaryId)}
                    />
                </div>
            }
        },

    ];



    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize: GridSetting.pageSize || 10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage,  pagination.pageSize]);





    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Messaging/Secretaries`, {PageNumber: pagination.currentPage, PageSize:pagination.pageSize}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPreviousPage: response.hasPreviousPage,
                    page: response.page,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount
                }));
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
                hideSpinner()
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };


    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
        handleShowMessage,
        visible,
        message,
        setVisible,
    }



}