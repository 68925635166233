import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {InputTextarea} from "primereact/inputtextarea";
import {useNavigate} from "react-router-dom";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import queryString from "query-string";
import { Accordion, AccordionTab } from 'primereact/accordion';
import {serverSchema} from "./serverSchema";
import {convertServer} from "./ServerService";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import Select from "react-select";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {Pagination} from "../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {validate} from "../../../Services/Globals/validate";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {Export} from "../../Commans/Grid/Export";
import {ConnectionStringBuilder} from "../../Commans/Globals/ConnectionStringBuilder";
import {Return} from "../../Commans/Globals/Return";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {useCustomServer} from "./useCustomServer";
import {useCustomServerGrid} from "./useCustomServerGrid";
import {Server} from "./Server";
import {NoDataHarmony} from "../../Commans/NoDataHarmony";
import {Tooltip} from "react-tooltip";
import {WebsiteServer} from "./WebsiteServer";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {NsServer} from "./NsServer";
export const HandleAddServer = () => {

  const { t } = useTranslation();
  const [IsOpen,setIsOpen] = useState(false);
  const redirectTo = useNavigate();
  const [IsShowSpinner,setIsShowSpinner] = useState(false);
  const [showPassword,setShowPassword] = useState(true);
  const [refresh,setRefresh] = useState(false)
  const [initialSource,setInitialSource] = useState({serverTypes:[],privacyTypes:[],countries:[],provinces:[]})
  const [errors,setErrors] = useState({});
  const [IsEdit,setIsEdit] = useState(false);
  const [IsOpenWebsite,setIsOpenWebsite] = useState(false);

  const [IsCsOpen,setIsCsOpen] = useState(false);
  const {setIpValid,setTimer,server,handleValue,handleSelectValue,setServer,handleStateShow,handleNumberValue,handleGetInitialCountry,OnConnectionStringBuilderChangeValue,isIpValid,handleIpValue,handleDataTimer,timer,timerArray} = useCustomServer(setErrors,initialSource)

  const Refresh=()=>{
    setRefresh(prevState => !prevState);
  }
  const showSpinner=()=>{
    setIsShowSpinner(true);
  }
  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }
  const onSetErrors = (errors)=>{
    setErrors(errors);
  }
  const handleOpenWebsites=()=>{
    setIsOpenWebsite(true);
  }
  const handleCloseWebsites=()=>{
    setIsOpenWebsite(false);
  }

  const handleInitialData=(countries,serverTypes,privacyTypes)=>{
    const countryData = convertSelectDataHandler(countries);
    const serverTypeData = convertSelectDataHandler(serverTypes);
    const privacyTypeData = convertSelectDataHandler(privacyTypes);
    setInitialSource({
      countries: countryData,
      serverTypes: serverTypeData,
      privacyTypes: privacyTypeData
    })
  }
  const fetchData = async ()=>{
    try {
      showSpinner();
      const responseCountry = await GET('/Countries/Selection',{},'IEnumerable');
      const responseServerType = await GET('/Enumerations/EnServerType',{},'IEnumerable');
      const responsePrivacyType = await GET('/Enumerations/EnPrivacyType',{},'IEnumerable');
      if ( responseServerType.status === 200 && responsePrivacyType.status === 200 && responseCountry.status === 200)
      {
        handleInitialData(responseCountry.data,responseServerType.data,responsePrivacyType.data);
        setTimer(prevState => ({...prevState,isActive: false}))
        hideSpinner();
      }
      else {
        handleApiError(responseServerType,hideSpinner)
        handleApiError(responsePrivacyType,hideSpinner)
      }
    } catch (error) {
      hideSpinner()
    }
  }

  useEffect(()=>{
    fetchData();
  },[])

  const handleOpenServer=async()=>{
    try {
      showSpinner();
      const defaultCountry = handleGetInitialCountry('Iran');
      const responseProvince = await GET(`/Countries/${defaultCountry.value}/StateProvinces/Selection`,{},'IEnumerable');
      if(responseProvince.status == 200){
        const provinces = convertSelectDataHandler(responseProvince.data);
        setServer((prevState)=>({...prevState,countryId: defaultCountry,stateProvinceId: provinces[0],type: initialSource.serverTypes[0],privacy: initialSource.privacyTypes[0],bandwidth: 0,cpu: 0,hdd: 0,ram: 0,ssd: 0,stateShow: true,capacity: 0,isConfigured: false,ns1: '',ns2: '',ns4: '',ns3: ''}));
        setInitialSource(prevState => ({
          ...prevState,
          provinces: provinces
        }))
        hideSpinner();
        setIsOpen(true);
      }
      else{
        handleApiError(responseProvince,hideSpinner);
      }
    }
    catch (e) {
      console.log(e);
     hideSpinner();
    }
  }

  const handleCloseServer=()=>{
    setIsOpen(false);
    setIpValid(false)
    setErrors({});
    setServer({type: initialSource.serverTypes[0],privacy: initialSource.privacyTypes[0],bandwidth: 0,cpu: 0,hdd: 0,ram: 0,ssd: 0,id: null,userName: '',password: '',stateShow: true,name: '',description: '',connectionString: '',ip: '',capacity: 0,isConfigured: false,ns1: '',ns2: '',ns4: '',ns3: ''})
    setIsEdit(false);
    redirectTo('/Admin/Server/Add');
  }

  const handleOpenConnectionBuilder = ()=>{
    setIsCsOpen(true);
  }
  const handleCloseConnectionBuilder = ()=>{
    setIsCsOpen(false);
  }









  const handleAddServer =async (e)=> {
    e.preventDefault();
    if(IsEdit){
      const isValid = validate(serverSchema,server,onSetErrors);
      if (!isValid) {
        return;
      }
      try {
        if(server.stateProvinceId === null){
          handleFailureMessage(t("Pleaseselectprovince"));
          return;
        }
        if(!isIpValid){
          handleFailureMessage(t("PleaseenterIPformat"));
          return;
        }
        if(server.type.value === 0 && (server.connectionString === null || server.connectionString === '')){
          handleFailureMessage(t("Enterthedatabaseconnectionstring"));
          return;
        }
       showSpinner()
        const convertedServers = convertServer(server,true);
        const response = await PUT('/Servers',convertedServers,false);
        if (response.status === 200) {
          hideSpinner();
          Refresh();
          handleCloseServer();
          handleSuccessMessage(t("Yourserverhasbeensuccessfullyedited"));
        } else {
          handleApiError(response,hideSpinner)
        }
      }catch (error) {
        hideSpinner();

      }
    }
    else{
      const isValid = validate(serverSchema,server,onSetErrors);
      if (!isValid) {
        return;
      }
      try {
        if(server.stateProvinceId === null){
          handleFailureMessage(t("Pleaseselectprovince"));
          return;
        }
        if(!isIpValid){
          handleFailureMessage(t("PleaseenterIPformat"));
          return;
        }
        if(server.type.value === 0 && server.connectionString === null || server.connectionString === ''){
          handleFailureMessage(t("ConnectionStringIsRequired"));
          return;
        }
       showSpinner();
        const convertedServer = convertServer(server, false);
        const response = await POST('/Servers',convertedServer ,false);
        if (response.status === 200) {
          hideSpinner();
          Refresh();
          handleCloseServer();
          handleSuccessMessage(t("Yourserverhasbeensuccessfullyregistered"));
        } else {
          handleApiError(response,hideSpinner)
        }
      }catch (error) {
        hideSpinner();

      }
    }
  }



const handleSelectCountry=async(data)=>{
  try {
    showSpinner()
    const response = await GET(`/Countries/${data.value}/StateProvinces/Selection`,{},'IEnumerable');
    if(response.status === 200){
      const provinces = convertSelectDataHandler(response.data);
      setInitialSource(prevState => ({...prevState,provinces:provinces}));
      setServer(prevState => ({...prevState,countryId: data,stateProvinceId: provinces[0]??null}))
      hideSpinner();
    }
    else{
      handleApiError(response,hideSpinner);
    }
  }
  catch (e) {
    hideSpinner();
  }
}








  const {handleRestartIis,handleRestartServer,handleChangeActivation,handleGetServer,handleGetServerType,handleGetPrivacyType,handleDeleteServer,filter,handleCurrentPage,handleNextPage,handleSearch,search,setFilter,setSearch,handlePreviousPage,handlePageSizeCount,handleSelectFilter,handleResetFilters,handleToggleFilter,SwitchFilters,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination,handleServerPin,handleGetAllWebsites} = useCustomServerGrid(showSpinner,hideSpinner,server,setServer,initialSource,setInitialSource,setIsEdit,refresh,Refresh,setIsOpen,timer,setIpValid,handleOpenWebsites,handleCloseWebsites);





  const nsArrays = [
    { label: 'سرور 1',   handleValue: (e) => handleValue(e), name: 'ns1', value: server.ns1 },
    { label: 'سرور 2',   handleValue: (e) => handleValue(e), name: 'ns2', value: server.ns2 },
    { label: 'سرور 3',  handleValue: (e) => handleValue(e), name: 'ns3', value: server.ns3 },
    { label: 'سرور 4',   handleValue: (e) => handleValue(e), name: 'ns4', value: server.ns4 }
  ];


  return(
      <>
        <div class="space-y-5 relative">
          {

            IsShowSpinner?
                <div className=" huploader_loading" >
                  <div className="relative">
                    <LoadingHarmony/>
                  </div>
                </div>
                :
                null
          }

            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
              <ListSearch label={null} icon={null} >
                <div class="space-y-2 ">
                  <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                  <InputText  onChange={(e)=>setSearch(e.target.value)} value={search} type="search" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full"  />
                </div>
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("country")}</label>
                  <Select
                      options={initialSource.countries}
                      isClearable={false}
                      onChange={(data) => handleSelectFilter(data, 'countryId','countryValue',true)}
                      isSearchable={true}
                      placeholder={t("selection")}
                      value={filter.countryValue}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}
                  />
                </div>
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("province")}</label >
                  <Select
                      options={initialSource.provinces}
                      isClearable={false}
                      onChange={(data) => handleSelectFilter(data, 'stateProvinceId','stateProvinceValue',false)}
                      isSearchable={true}
                      placeholder={t("selection")}
                      value={filter.stateProvinceValue}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}
                  />
                </div>
                <ButtonSearchList
                    onClickSearch={()=>handleSearch()}
                    onClickReset={()=>handleResetFilters()}
                />
              </ListSearch>
              <ListFilter
                  label={null} icon={null}
                          childrenOne={
                            <>
                              <ButtonPlus
                                  onClick={() => handleOpenServer()}
                                  tooltipTitle={t("CreateAServer")}

                              />
                            </>
                          }
                          childrenTwo={
                            <>
                              <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                            </>
                          }
              />


            </div>

            <div className={" bg-white dark:bg-themeDarkSalt700 p-2 box space-y-3"}>
              <div className={"p-2 flex items-center gap-3 post-input dark:bg-themeDarkPostInput post-input-design"}>
                <div className={"tooltip_class_Update"}>
                  <input
                      checked={timer.isActive}
                      className="input input--switch "
                      onChange={(e) => handleDataTimer(e.target.checked, 'isActive')}
                      type="checkbox"

                    />
                  <Tooltip anchorSelect={".tooltip_class_Update"} content={t("Update")} positionStrategy={"fixed"} />
                </div>

                {
                  timer.isActive?
                      <>
                         <span className={` dark:text-gray-300 animate-refresh-${!timer.time?'1':timer.time.value.toString()}`}>
                           <HPrimeIcon
                           icon={"pi-sync"}
                           />
                        </span>
                        <Select
                            options={timerArray}
                            isSearchable={true}
                            onChange={(data)=>handleDataTimer(data,'time')}
                            placeholder={t("selection")}
                            value={timer.time}
                            menuPosition={"fixed"}
                            className={"w-32 z-100"}
                            classNamePrefix={"h_multi_select"}
                        />
                      </>

                      : null
                }

              </div>
              <div>

                {rowData.length === 0 ?
                    <NoDataHarmony/>
                    :
                    <div className={"grid grid-cols-1 xl:grid-cols-2 gap-3 H-direction-ltr "}>

                      {rowData.length > 0 ? rowData.map((item, index) => (
                              <Server item={item}
                                      handleChangeActivation={handleChangeActivation}
                                      handleGetServer={handleGetServer}
                                      handleGetServerType={handleGetServerType}
                                      handleGetPrivacyType={handleGetPrivacyType}
                                      handleDeleteServer={handleDeleteServer}
                                      handleRestartServer={handleRestartServer}
                                      handleServerPin={handleServerPin}
                                      handleRestartIis={handleRestartIis}
                                      handleGetAllWebsites={handleGetAllWebsites}

                              />
                          )):

                          <NoDataHarmony/>
                      }


                    </div>

                }


              </div>

              {rowData.length !==0 ?
                  <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                  :
                  null


              }

            </div>
            <Export filters={filter} apiUrl={'/Servers/ServerListExport'}/>

            <ToastContainer />

        </div>
        <FantasyModal openModal={IsOpen}  closeModal={handleCloseServer} title={t("CreateAServer")} customeClassName={"modal-xl"}>
          <div>
            <form method={'post'} onSubmit={handleAddServer}  className="space-y-5">
              <div class="grid grid-cols-2 lg:grid-cols-4 gap-3 ">
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <label className="text-xs dark:text-gray-300">
                      {t("ServerName")}
                      <RequiredIcon/>
                    </label>
                    <DisplayError message={errors.name}/>
                  </div>
                  <InputText value={server.name} name={'name'} onInput={(e)=>handleValue(e)} className="input w-full" />

                </div>
                <div className="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("ServerType")}</label>
                  <Select
                      options={initialSource.serverTypes}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'type')}
                      placeholder={t("selection")}
                      value={server.type}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("Access")}</label>
                  <Select
                      options={initialSource.privacyTypes}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'privacy')}
                      placeholder={t("selection")}
                      value={server.privacy}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("IP")}
                    <RequiredIcon/>
                  </label>
                  {/*<InputText value={server.ip} placeholder={'ip'} name={'ip'} onInput={(e)=>handleValue(e)} className="input w-full" />*/}
                  {/*<InputMask value={server.ip}  name={'ip'} onChange={(e)=>handleIpValue(e.value)}  id="ip" mask="999.999.999.999" placeholder="192.168.0.1"/>*/}
                  <InputText type={'text'} autoComplete={'off'} autoSave={'off'} className={`input w-full ${isIpValid?'h-border-success':'h-border-danger'}`}  onChange={(e)=>handleIpValue(e.target.value)} value={server.ip} placeholder="192.168.101.115"   />
                </div>
                <div className="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("userName")}</label>
                  <div className="p-inputgroup">
                   <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                     <HPrimeIconSmall
                     icon={"pi-user"}
                     />
                   </span>
                    <InputText   autoComplete={'off'} autoSave={'off'} className="input w-full" onInput={(e)=>handleValue(e)} name={'userName'} value={server.userName}/>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between items-center">
                    <label className="text-xs dark:text-gray-300">
                      {t("Password")}
                    <RequiredIcon/>
                    </label>
                    <DisplayError message={errors.password}/>
                  </div>

                  <div className="p-inputgroup relative">
                   <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                     <HPrimeIconSmall icon={"pi-key"}/>
                   </span>
                    <HPrimeIconSmall iconClass={`absolute top-1/2 -translate-y-1/2 end-2 dark:text-gray-300`}
                                     icon={`${ showPassword === true? "pi pi-eye cursor-pointer" : "pi pi-eye-slash cursor-pointer" }`}
                       onClick={() => setShowPassword(!showPassword)} >
                    </HPrimeIconSmall>
                    <InputText
                        type={showPassword ? "text" : "password"}
                        className=" input w-full"
                        autoComplete={'off'}
                        autoSave={'off'}
                        value={server.password}
                        onInput={(e) => handleValue(e)}
                        name="password"
                        menuPosition={"fixed"}
                    />
                  </div>
                </div>
                <div className="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">{t("country")}</label>
                  <Select
                      options={initialSource.countries}
                      isSearchable={true}
                      onChange={(data)=>handleSelectCountry(data)}
                      placeholder={t("selection")}
                      value={server.countryId}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">{t("province")}</label>
                  <Select
                      options={initialSource.provinces}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'stateProvinceId')}
                      placeholder={t("selection")}
                      value={server.stateProvinceId}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>

                {
                  server.type &&  server.type.value === 2?
                      nsArrays.map((item, index) => (
                          <NsServer
                              {...item}
                              key={index}
                          />
                      ))
                      :null
                }


              </div>

             <div className={"space-y-3"}>
               <div className={"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 items-center"}>
                 {

                   server.type &&  server.type.value === 0?
                       <div class="space-y-1 col-span-2 lg:col-span-4">
                         <label className="text-xs dark:text-gray-300 ">{t("DatabaseConnectionString")}</label>
                         <div>
                           <InputText type={'text'}  value={server.connectionString} name={'connectionString'} onInput={(e)=>handleValue(e)} className=" w-full input"  onClick={()=>handleOpenConnectionBuilder()} />
                         </div>
                       </div>
                       :
                       null
                 }
                 <div class=" col-span-2 md:col-span-1 flex gap-5">
                   <InputSwitch value={server.stateShow} OnValueSelection={handleStateShow}  name={'stateShow'} lable={t("DisplayStatus")} />

                   <InputSwitch value={server.isConfigured} OnValueSelection={handleStateShow}  name={'isConfigured'} lable={t("isConfigured")} />

                 </div>
               </div>
               <Accordion activeIndex={1} className={"bg-white dark:bg-themeDarkSalt700"}>
                 <AccordionTab header={t("تنظیم منابع")}>
                   <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3 items-center post-input dark:bg-themeDarkPostInput post-input-design p-2">
                     <div class="space-y-1 ">
                       <label className="text-xs dark:text-gray-300 ">{t("RAM")}</label>
                       <div>
                         <InputNumber name={'ram'} useGrouping={false}  placeholder="GB" min={0} max={100000} onChange={(e)=>handleNumberValue(e.value,'ram')} value={server.ram}  />
                       </div>
                     </div>
                     <div class="space-y-1">
                       <label className="text-xs dark:text-gray-300 ">{t("HDD")}</label>
                       <div>
                         <InputNumber name={'hdd'} useGrouping={false}  placeholder="GB" min={0} max={100000} onChange={(e)=>handleNumberValue(e.value,'hdd')} value={server.hdd}  />
                       </div>
                     </div>
                     <div class=" space-y-1 ">
                       <label className="text-xs dark:text-gray-300 ">{t("SSD")}</label>
                       <div>
                         <InputNumber name={'ssd'} useGrouping={false} placeholder="GB" min={0} max={100000} onChange={(e)=>handleNumberValue(e.value,'ssd')} value={server.ssd}  />
                       </div>
                     </div>
                     <div class=" space-y-1 ">
                       <label className="text-xs dark:text-gray-300">{t("BandWidth")}</label>
                       <div>
                         <InputNumber useGrouping={false} name={'bandwidth'}  placeholder="GB" min={0} max={100000} onChange={(e)=>handleNumberValue(e.value,'bandwidth')} value={server.bandwidth}  />
                       </div>
                     </div>
                     <div class="space-y-1 ">
                       <label className="text-xs dark:text-gray-300">{t("CPU")}</label>
                       <div>
                         <InputNumber useGrouping={false} name={'cpu'}  placeholder="Core"   min={0} max={100000}  onChange={(e)=>handleNumberValue(e.value,'cpu')} value={server.cpu}  />
                       </div>
                     </div>
                     <div class="space-y-1 ">
                       <label className="text-xs dark:text-gray-300">{t("UserCapacity")}</label>
                       <div>
                         <InputNumber useGrouping={false} name={'capacity'}  placeholder="capacity"   min={0} max={100000}  onChange={(e)=>handleNumberValue(e.value,'capacity')} value={server.capacity}  />
                       </div>
                     </div>
                   </div>
                 </AccordionTab>
               </Accordion>
               <div className={""}>
                 <div class="space-y-1 col-span-2 md:col-span-3 lg:col-span-6">
                   <InputTextarea name={'description'} value={server.description} onInput={(e)=>handleValue(e)} rows={3} cols={30} className={"input w-full"} placeholder={t("Explanation")}/>
                 </div>
               </div>

             </div>


              <div >
                <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation w-full text-white ">{t("creation")}</button>
              </div>
            </form>
          </div>
        </FantasyModal>


        <FantasyModal openModal={IsCsOpen} closeModal={handleCloseConnectionBuilder} title="sql-server-connection-string" customeClassName={"dir-ltr modal-x"}>
          <ConnectionStringBuilder
              OnValueChange={OnConnectionStringBuilderChangeValue}
              name={'connectionString'}
              handleCloseModal={handleCloseConnectionBuilder}
          />
        </FantasyModal>


        <FantasyModal openModal={IsOpenWebsite} closeModal={handleCloseWebsites} title={t("Websites")}>
          <div className={"flex flex-wrap gap-3 H-direction-ltr"}>
            { server.websites && server.websites.length > 0 ? server.websites.map((item, index) => (
                    <WebsiteServer
                        siteName={item.siteName}
                        serverId={server.serverId}
                        handleRestartIis={handleRestartIis}
                    />
                )):

                <NoDataHarmony/>
            }
          </div>


        </FantasyModal>

      </>


  )
}