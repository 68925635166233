
import React from "react";
import {InputMask} from "primereact/inputmask";
import {InputText} from "primereact/inputtext";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const InputUrlElement = ({item,index,handleChangeData }) => (
    <>

        <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
            <label className="dark:text-gray-300 harmony-text-xs">
                {item.properties.caption}
            </label>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <InputText
                type="text"
                placeholder={item.properties.regex}
                readOnly={item.properties.isReadOnly}
                id={item.properties.id}
                value={item.data?.value}
                onChange={(e) => handleChangeData(index, 'value', e.target.value)}
            />
        </div>

    </>
);