import React from "react";
import HyperModal from "react-hyper-modal";

import {useTranslation} from "react-i18next";
export const FantasyModal = (props)=>{
    const { t } = useTranslation();
    return (
        <div className={`modal ${props.openModal?'show':''}`} >
            <div className={props.customeClassName}>
                <HyperModal isOpen={props.openModal} requestClose={props.closeModal}>
                    <fieldset className={`rounded-md  ${!props.fieldse_Class_Name?'border border-themeInformation dark:border-themeDarkColorLine  pt-5':props.fieldse_Class_Name} ${!props.fieldse_bg_Class?'post-input dark:bg-themeDarkPostInput':props.fieldse_bg_Class} `}>
                            <legend className={`border dark:border-themeDarkColorLine py-2 px-3 flex gap-1 items-center bg-themeInformation dark:bg-themeDarkPostInput text-white rounded-md  ${props.legend_Class_Name}`}>
                                <i className={"pi pi-check-square text-xs dark:text-gray-300"}></i>
                                <p className={"text-xs dark:text-gray-300"}>
                                    {props.title ? props.title : t("operations")}
                                </p>

                            </legend>
                            <div className={"p-2 max_height_modal"}>
                                {props.children}
                            </div>
                        </fieldset>
                </HyperModal>
            </div>
         </div>
    )
}