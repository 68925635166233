import React from 'react';
import Loading from 'react-loading-components';

export const TinyLoading = (props)=>{

    const{type,height,width} = props;
    return (
        // <div className="[&_svg]:fill-themeInformation [&_svg]:dark:fill-themeDarkInformation">
        //     <Loading type={type} width={width} height={height} />
        // </div>
        <div class="loader">
            <div class="loaderRectangle">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )

}