import React from "react";
import {InputSwitch} from "./InputSwitch";

export const InputSwitchListFilter=(props)=>{

    const handleValueSelection = (e, name) => {
        const isChecked = e.target.checked;
        props.OnValueSelection(isChecked, name);
    };

    return (

        <>
                    {props.switchData.map((data, index) => (
                        <div key={index} className="flex flex-col gap-2 items-center">
                            <label className="text-xs dark:text-gray-300 text-nowrap">{data.lable}</label>
                            <div>
                                <input
                                    checked={data.value}
                                    className="input input--switch border bg-white"
                                    onChange={(e) => handleValueSelection(e, data.name)}
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    ))}

        </>

    )
}
