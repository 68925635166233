import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {convertSelectDataHandler} from "../../Contents/ContentService";
import {InputText} from "primereact/inputtext";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import Select from "react-select";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {useCustomChooseTemplateGrid} from "./useCustomChooseTemplateGrid";
import {ChooseTemplate} from "./ChooseTemplate";
import {ToastContainer} from "react-toastify";
import {usePurchaseTemplate} from "./usePurchaseTemplate";
export const HandleChooseTemplate=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const[showSpinner,setShowSpinner] = useState(false);
    const[refresh,setRefresh] = useState(false);
    const [initialSource,setInitialSource] = useState({types:[],currencies:[]});
    const [templates,setTemplates] = useState([]);
    const {handleSelectValue,setUpdate,upgrade}=usePurchaseTemplate()
    // calling callback for api response handler
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // refreshes component after api call
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    // Initial dataSource
    const handleInitialDataSource=(templateTypes,currencies)=>{
        const types = convertSelectDataHandler(templateTypes);
        const currenciesData = convertSelectDataHandler(currencies);
        setInitialSource({types: types,currencies: currenciesData});
        return {types,currenciesData}
    }

// fetching initial data while calling api in first render
const fetchData = async()=>{
    try {
        setShowSpinner(true)
        const responseTypes = await GET('/Enumerations/EnTypeTemplate',{},'IEnumerable');
        const responseCurrencies =  await GET(`/Upgrades/ActiveCurrency`, {}, 'IEnumerable');
            if( responseTypes.status === 200 && responseCurrencies.status === 200){
            setShowSpinner(false);
            const {currenciesData,types}=handleInitialDataSource(responseTypes.data,responseCurrencies.data);
            setUpdate(prevState => ({...prevState,currencyType: currenciesData[0]}));
        }
        else{
          handleApiError(responseTypes,onApiError)
        }
    }catch (e) {
        setShowSpinner(false);
    }
}
useEffect(()=>{
    fetchData();
},[])

// Choosing selected template to pay for it .

  // passing template type to get its label as its name



    // handles all process of pagination
    const {handlePageSizeCount,handlePreviousPage,handleNextPage,handleSearch,handleCurrentPage,filterValueHandler,filterSelectValue,resetFilters,switchData,search,data,setSearch,currentPage,filter,setFilter} = useCustomChooseTemplateGrid(fetchData,setShowSpinner,onApiError,refresh,setTemplates,handleSelectValue,setUpdate,upgrade,Refresh)





    return (
        <div class="space-y-5">

            <div className={"box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5"}>
                <ListSearch label={null} icon={null} >
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("SearchByNameTemplate")}</label>
                        <div>
                            <InputText type="text" value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full"  />
                        </div>
                    </div>
                    <div class=" space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("TemplateType")}</label>
                        <Select
                            options={initialSource.types}
                            placeholder={t("selection")}
                            value={filter.templateTypeValue}
                            onChange={(data)=>filterSelectValue(data,'type')}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                        />
                    </div>

                    <ButtonSearchList
                        onClickSearch={()=>handleSearch()}
                        onClickReset={()=>resetFilters()}
                    />
                </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/Template/Add`)}
                                        icon={"pi-objects-column"}
                                        title={t("template")}
                                        tooltipTitle={t("Template")}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo("/CMS/Template/Choose")}
                                        title={t("Buy")}
                                        tooltipTitle={t("BuyATemplate")}
                                        icon={"pi-cart-arrow-down"}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo("/CMS/Template/ShortCode")}
                                        title={t("ShortCode")}
                                        tooltipTitle={t("CreatingAShortCode")}
                                        icon={"pi-code"}
                                    />
                                </>
                            }
                            childrenTwo={
                                <>
                                    <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                </>
                            }
                />


            </div>
            <div className={"p-2 box bg-white dark:bg-themeDarkSalt700 "}>
                <div className={"grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 post-input dark:bg-themeDarkPostInput post-input-design p-2"}>
                    <div class=" space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("CurrencyType")}</label>
                        <Select
                            options={initialSource.currencies}
                            placeholder={t("selection")}
                            value={upgrade.currencyType}
                            onChange={(data)=>handleSelectValue(data,'currencyType')}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                        />
                    </div>
                </div>

            </div>
            <div className={"box bg-white box dark:bg-themeDarkSalt700 p-3 relative space-y-5"}>
                {

                    showSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 ">

                    {
                        templates && templates.length > 0 ?
                            templates.map((item,index)=>{return (

                                <ChooseTemplate
                                    upgrade={upgrade}
                                    setUpdate={setUpdate}
                                    item={item}
                                    initialSource={initialSource}
                                    onApiError={onApiError}
                                    Refresh={Refresh}
                                    setShowSpinner={setShowSpinner}
                                />

                            )}):
                            t("Loading")
                    }
                </div>
                <Pagination length={templates.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
            </div>

            <ToastContainer/>
        </div>
    )
}