import React, {useState} from "react";
export const useFormSignature=()=>{
    const [signature,setSignature]=useState({note:null,operation:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSignature({...signature,[name]:value});
    }

    const handleSelectValue=(data,name)=>{
        setSignature(()=>({...signature,[name]:data}));
    }
    return {
        signature,
        setSignature,
        handleValue,
        handleSelectValue
    }

}