import React, {useEffect, useState} from "react";
import Select from "react-select";
import {InputText} from "primereact/inputtext";
import {useTranslation} from "react-i18next";
import {useEmployee} from "./useEmployee";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {ModalSubmitButton} from "../../../Commans/UIParts/ModalSubmitButton";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleCreateEmployee} from "./employeeService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {Image} from "primereact/image";
import {signal} from "codemirror/src/util/event";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const Employee=({user,handleClosePersonalInformation})=>{
    const { t } = useTranslation();
    const [IsBtnLoading,setIsBtnLoading] = useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);

    const {handleSelectValue,handleValue, handleChangeMarriageDateTime, setEmployee, employee,handleSignatureFile,handleRemoveSignature}=useEmployee();
    const [initialSource,setInitialSource]=useState({
        AttendanceTypes:[],
        bloodTypes:[],
        militaryServiceTypes:[],
        hosingStatusTypes:[],
        marriageStatusTypes:[],
    })
    const showBtnLoading=()=>setIsBtnLoading(true)
    const hideBtnLoading=()=>setIsBtnLoading(false)

    const showSpinner=()=>setIsShowSpinner(true)
    const hideSpinner=()=>setIsShowSpinner(false);

    const handleInitialSource = (responseAttendanceType,responseBloodType,responseMilitary,responseHousing,responseMaritalType) => {
        const sources = {
            AttendanceTypes: responseAttendanceType,
            bloodTypes: responseBloodType,
            militaryServiceTypes: responseMilitary,
            hosingStatusTypes: responseHousing,
            marriageStatusTypes: responseMaritalType,
        };
        const sourceData = Object.keys(sources).reduce((acc, key) => {
            acc[key] = convertSelectDataHandler(sources[key]);
            return acc;
        }, {});
        setInitialSource(sourceData);
        return {sourceData};
    };

    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Users/Employee?UserId=${user?.userId}`,{},'single')
            if(response.status !== 200){
                handleApiError(response,hideSpinner);
            }

            const [

                responseAttendanceType,
                responseBloodType,
                responseMilitary,
                responseHousing,
                responseMaritalType,

            ] = await Promise.all([
                GET('/Enumerations/EnAttendanceType', {}, 'IEnumerable'),
                GET('/Enumerations/EnBloodType', {}, 'IEnumerable'),
                GET('/Enumerations/EnMilitaryServiceStatus', {}, 'IEnumerable'),
                GET('/Enumerations/EnHousingStatus', {}, 'IEnumerable'),
                GET('/Enumerations/EnMaritalStatus', {}, 'IEnumerable'),

            ]);

            console.log(response)


            const allStatusOk = [
                responseAttendanceType,
                responseBloodType,
                responseMilitary,
                responseHousing,
                responseMaritalType,

            ].every(res => res.status === 200);

            if (allStatusOk) {
                const {sourceData}=handleInitialSource(responseAttendanceType.data,responseBloodType.data,responseMilitary.data,responseHousing.data,responseMaritalType.data);
                setEmployee((prevState) => ({
                    ...prevState,
                    attendanceType: sourceData.AttendanceTypes.find((item) => item.value === response.data.attendanceType) ?? null,
                    bloodType: sourceData.bloodTypes.find((item) => item.value === response.data.bloodType) ?? null,
                    militaryServiceStatus: sourceData.militaryServiceTypes.find((item) => item.value === response.data.militaryServiceStatus) ?? null,
                    housingStatus: sourceData.hosingStatusTypes.find((item) => item.value === response.data.housingStatus) ?? null,
                    maritalStatus: sourceData.marriageStatusTypes.find((item) => item.value === response.data.maritalStatus) ?? null,
                    insurancePosition: response.data.insurancePosition ?? '',
                    marriageDate: response.data.marriageDate,
                    userId: response.data.userId,
                    signature: response.data.signature, // Ensure signature is being set
                }));

                hideSpinner();
            }
            else {
                allStatusOk.forEach(resp => {
                    handleApiError(resp, hideSpinner);
                });
            }

        }
        catch (e) {
            hideSpinner();
        }
    }
    useEffect(()=>{
        fetchData();
    },[])

    const handleSubmitEmployee=async(e)=>{
          e.preventDefault();
        try {
          showBtnLoading();
          const createdEmployee = handleCreateEmployee(employee,user?.userId);
          const response = await POST(`/Users/Employee`,createdEmployee,true);
          if(response.status === 200){

              handleClosePersonalInformation();
              handleSuccessMessage(t('SuccessDone'));

          }
          else{
            handleApiError(response,hideBtnLoading);
          }
       }
       catch (e) {
           hideBtnLoading();
       }
    }




    const handleShowImage=(propName,pathName)=>{
        return (<>
                {

                    employee[pathName] !== null ?
                        <Image src={employee[pathName]}   zoomSrc={employee[pathName]} alt="Image" preview/>
                        :
                        <Image src={employee[propName]}   zoomSrc={employee[propName]} alt="Image"   preview/>
                }
            </>

        )
    }

    return (
        <>
         <form method={'post'} onSubmit={handleSubmitEmployee}>
             {
                 console.log(employee)
             }
             <div className={"space-y-5"}>
                 <div className={"bg-themeLightGray p-2 flex items-center gap-2 box"}>
                     <div className=" size-12 bg-white dark:bg-themeDarkPostInput flex-shrink-0 rounded-full overflow-hidden">
                         <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="w-full h-full object-cover" />
                     </div>
                     <div className={"flex gap-3 items-center justify-between flex-1 w-full flex-wrap"}>
                         <div className={"flex gap-1 items-center"}>
                             <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Name")}:</h3>
                             <h2 className="line-clamp-1 text-xs dark:text-gray-300">
                                 {user?.fullName}
                             </h2>
                         </div>
                     </div>

                 </div>
                 <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 "}>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("InsurancePosition")}</label>
                         <InputText  type="text" onInput={(e)=>handleValue(e)} value={employee.insurancePosition} name={'insurancePosition'}  className=" input"  />
                     </div>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("AttendanceType")}</label>
                         <Select
                             options={initialSource.AttendanceTypes}
                             isClearable={true}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'attendanceType')}
                             name={'parentId'}
                             placeholder={t("selection")}
                             value={employee.attendanceType}
                             menuPosition={"fixed"}
                             classNamePrefix={"h_multi_select"}
                         />
                     </div>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("bloodType")}</label>
                         <Select
                             options={initialSource.bloodTypes}
                             isClearable={true}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'bloodType')}
                             name={'parentId'}
                             placeholder={t("selection")}
                             value={employee.bloodType}
                             menuPosition={"fixed"}
                             classNamePrefix={"h_multi_select"}
                         />
                     </div>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("HousingSituation")}</label>
                         <Select
                             options={initialSource.hosingStatusTypes}
                             isClearable={true}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'housingStatus')}
                             name={'parentId'}
                             placeholder={t("selection")}
                             value={employee.housingStatus}
                             menuPosition={"fixed"}
                             classNamePrefix={"h_multi_select"}
                         />
                     </div>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("MaritalStatus")}</label>
                         <Select
                             options={initialSource.marriageStatusTypes}
                             isClearable={true}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'maritalStatus')}
                             name={'parentId'}
                             placeholder={t("selection")}
                             value={employee.maritalStatus}
                             menuPosition={"fixed"}
                             classNamePrefix={"h_multi_select"}
                         />
                     </div>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("DateMarriage")}</label>
                         <DateTimePicker handleDateTime={handleChangeMarriageDateTime} value={employee.marriageDate} format={'YYYY/MM/DD'} />
                     </div>

                 </div>
                 <div class="bg-white dark:bg-themeDarkSalt700 flex flex-col sm:flex-row gap-2 items-center p-2 box">
                     <div className={"size-20 flex-shrink-0 post-input border-2 border-dashed setting-img-default dark:bg-themeDarkPostInput dark:border-themeDarkColorLine"}>
                         {
                             handleShowImage('signature','signatureSrc')
                         }
                     </div>
                     <div className="space-y-1 flex-1 w-full">
                         <div className={'flex justify-between item-center gap-2'}>
                             <label className="text-xs dark:text-gray-300">{t("تصویر امضاء")}</label>
                             <button type={'button'} onClick={()=>handleRemoveSignature()} >
                                 <HPrimeIcon icon={'pi-trash'}  iconClass={"text-themeDanger"}  />
                             </button>
                         </div>
                         <input type="file" onChange={(e)=>handleSignatureFile(e,'signature','signatureSrc')}  accept=".jpeg,.png"  className="block w-full post-input dark:bg-themeDarkPostInput overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white dark:border-themeDarkColorLine file:border-none file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 text-xs"/>
                     </div>
                 </div>
                 <ModalSubmitButton
                     IsBtnLoading={IsBtnLoading}

                 />
             </div>

         </form>

        </>
    )
}