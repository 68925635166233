import React, {useMemo, useState} from "react";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {ListFilter} from "../Commans/Grid/ListFilter";


import {useTranslation} from "react-i18next";

import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useSetting} from "../Commans/Hooks/useSetting";
import {SettingButton} from "../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";


import {InputText} from "primereact/inputtext";
import Select from "react-select";

import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {ListSearch} from "../Commans/Grid/ListSearch";

import {GridTable} from "../Commans/Grid/GridTable";
import {Export} from "../Commans/Grid/Export";
import {HandlePicturesSettings} from "../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";

export const HandleAttendanceListWorkGroup = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 110},
        { headerName:t("GroupName"), minWidth: 140,
            cellRenderer:parameters=>
                <>

                </>

        },

        { headerName:t("GroupCode"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>

        },

        { headerName:t("BenchmarkDate"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>

        },

        { headerName:t("PeriodInterval"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    تهران
                </>

        },
        { headerName: t("State"),minWidth: 140,
            cellRenderer:parameters=>
                <>
                    65654
                </>
        },

        { headerName: t("NumberEmployees"),minWidth: 140,
            cellRenderer:parameters=>
                <>
                    65654
                </>
        },



        { headerName: t("operations"),width: 110, maxWidth: 120,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>
                </div>
            }

        },

    ];

    const rowData = [

        { id:1, date:'1402/10/3', },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };



    return (
        <>
            <div className="space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByName")}</label>
                            <InputText  type="text"  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("AccountType")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <ButtonSearchList
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Attendance/WorkGroup")}
                                            tooltipTitle={t("AddWorkgroup")}

                                        />

                                        <ButtonPlus
                                            title={t("Personnel")}
                                            onClick={()=>redirectTo(`/Attendance/Personnel`)}
                                            tooltipTitle={t("Personnel")}
                                            icon={"users"}
                                        />

                                        <ButtonPlus
                                            icon={"plus-square"}
                                            title={t("Device")}
                                            onClick={()=>redirectTo("/Attendance/Add")}
                                            tooltipTitle={t("RegisterDevice")}

                                        />

                                        <ButtonPlus
                                            title={t("FinancialRules")}
                                            icon={"book"}
                                            onClick={()=>redirectTo("/Attendance/FinancialRules")}
                                            tooltipTitle={t("FinancialRules")}

                                        />

                                        <ButtonPlus
                                            title={t("Leave")}
                                            primIcon={"pi-calendar-clock"}
                                            onClick={()=>redirectTo("/Attendance/LeaveAndMission")}
                                            tooltipTitle={t("LeaveAndMission")}

                                        />

                                        <ButtonPlus
                                            title={t("Shift")}
                                            icon={"layers"}
                                            onClick={()=>redirectTo("/Attendance/ShiftWork/List")}
                                            tooltipTitle={t("ShiftWork")}

                                        />

                                        <ButtonPlus
                                            title={t("WorkGroup")}
                                            icon={"users"}
                                            onClick={()=>redirectTo("/Attendance/WorkGroup/List")}
                                            tooltipTitle={t("WorkGroup")}

                                        />

                                        <ButtonPlus
                                            title={t("archive")}
                                            icon={"archive"}
                                            onClick={()=>redirectTo("/Attendance/Archive")}
                                            tooltipTitle={t("ArchiveLeaveAndMission")}

                                        />


                                        <ButtonPlus
                                            title={t("Reports")}
                                            primIcon={"pi-chart-bar"}
                                            onClick={()=>redirectTo("/Attendance/List/Reports")}
                                            tooltipTitle={t("GeneralReport")}

                                        />




                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>

                <div className={"H-body-ag-grid space-y-2 relative"}>
                    <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                               enableRtl={true}
                               pagination={true}
                               getRowHeight={getRowHeight}
                               onGridReady={onGridReady}
                               quickFilterText={quickFilterText}
                    />
                </div>
                <Export  />

            </div>


            <FantasyModal openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>


        </>
    );
};
