import React, {useEffect} from "react";
import {useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDashboard} from "../useDashboard";
import {LogErrorsBody} from "../../GeneralManagement/LogError/LogErrorsBody";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {useDeveloperDashboardWidget} from "./useDeveloperDashboardWidget";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandleDashboardDeveloperManagement=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const {dashboard,handleGetWidgets,setDashboard,handleSelectLogData,logId,logData, logError} = useDeveloperDashboardWidget(setIsLoading);
    const {handleToggleDashboard,classesToggled}=useDashboard();




    return (

        <div className={"relative min-h-screen"}>
            {
                IsLoading?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                        <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                        </button>
                        <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                            <div className="space-y-10">
                                <div>
                                    <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                        <h2 className="text-base dark:text-gray-300 -intro-x">
                                            {t("GeneralReport")}
                                        </h2>
                                        <Link to={"#"} className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                            <HPrimeIcon
                                                icon={"pi-sync"}
                                            />
                                            {t("Reload")}
                                        </Link>
                                    </div>
                                    <div className={`grid gap-x-5 gap-y-10 py-3 grid-cols-2 sm:grid-cols-2  ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                        <div className={"-intro-x"}>
                                            <div className="report-box zoom-in ">
                                                <Link to={"#"} className="box bg-white p-5 dark:bg-themeDarkSalt700 block" >
                                                    <div className={"space-y-2"}>
                                                        <div className={"sm:flex justify-between items-center"}>
                                                            <div>
                                                                <h2 className={"dark:text-gray-300 text-center text-base"}>
                                                                    تعداد نشست های فعال
                                                                </h2>
                                                            </div>
                                                            <div className={"hidden sm:block"}>
                                                                <HPrimeIcon
                                                                    icon={"pi-users"}
                                                                    iconClass={"dark:text-gray-300 "}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={"-intro-x"}>
                                            <div className="report-box zoom-in ">
                                                <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                                    <div className={"space-y-2"}>
                                                        <div className={"sm:flex justify-between items-center"}>
                                                            <div>
                                                                <h2 className={"dark:text-gray-300 text-center text-base"}>
                                                                    داکیومنت ها
                                                                </h2>
                                                            </div>
                                                            <div className={"hidden sm:block"}>
                                                                <HPrimeIcon
                                                                    icon={"pi-file-check"}
                                                                    iconClass={"dark:text-gray-300 "}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>
                                                                10
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={"-intro-x"}>
                                            <div className="report-box zoom-in ">
                                                <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                                    <div className={"space-y-2"}>
                                                        <div className={"sm:flex justify-between items-center"}>
                                                            <div>
                                                                <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                                    {t("Tickets")}
                                                                </h2>
                                                            </div>
                                                            <div className={"hidden sm:block"}>
                                                                <HPrimeIcon
                                                                    icon={"pi-users"}
                                                                    iconClass={"dark:text-gray-300 "}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={"-intro-x"}>
                                            <div className="report-box zoom-in ">
                                                <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                                    <div className={"space-y-2"}>
                                                        <div className={"sm:flex justify-between items-center"}>
                                                            <div>
                                                                <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                                    {t("tasks")}
                                                                </h2>
                                                            </div>
                                                            <div className={"hidden sm:block"}>
                                                                <HPrimeIcon
                                                                    icon={"pi-clock"}
                                                                    iconClass={"dark:text-gray-300 "}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <LogErrorsBody
                                        handleSelectLogData={handleSelectLogData}
                                        logData={logData}
                                        logError={logError}
                                        logId={logId}
                                    />
                                </div>
                            </div>
                        </div>

                        {/*<div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>*/}
                        {/*    <div>*/}
                        {/*        <div>*/}
                        {/*            <h2 className="text-lg dark:text-gray-300">*/}
                        {/*                {t("Notes")}*/}
                        {/*            </h2>*/}
                        {/*        </div>*/}
                        {/*        <div className={"space-y-3 py-3"}>*/}
                        {/*            {*/}
                        {/*                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (*/}
                        {/*                    <UserNote*/}
                        {/*                        key={index}*/}
                        {/*                        fullName={item.fullName}*/}
                        {/*                        id={item.id}*/}
                        {/*                        message={item.message}*/}
                        {/*                        createdAt={item.createdAt}*/}
                        {/*                    />*/}
                        {/*                ))*/}
                        {/*            }*/}
                        {/*            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div>*/}
                        {/*        <div>*/}
                        {/*            <h2 className="text-lg dark:text-gray-300">*/}
                        {/*                {t("UserComments")}*/}
                        {/*            </h2>*/}
                        {/*        </div>*/}
                        {/*        <div className={"space-y-3 py-3"}>*/}
                        {/*            <div className={"space-y-3 dashboard-note h-height300 overflow-y-auto scrollbar-hidden "}>*/}
                        {/*                {*/}
                        {/*                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (*/}
                        {/*                        <UserComment*/}
                        {/*                            key={index}*/}
                        {/*                            fullName={item.fullName}*/}
                        {/*                            id={item.id}*/}
                        {/*                            message={item.message}*/}
                        {/*                            createdAt={item.createdAt}*/}
                        {/*                            state={item.state}*/}
                        {/*                        />*/}
                        {/*                    ))*/}
                        {/*                }*/}
                        {/*            </div>*/}
                        {/*            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div>*/}
                        {/*        <div className="flex items-center ">*/}
                        {/*            <h2 className="text-lg dark:text-gray-300">*/}
                        {/*                {t("ReleaseQueue")}*/}
                        {/*                <span className={"text-xs dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>*/}
                        {/*            </h2>*/}
                        {/*        </div>*/}
                        {/*        <div className={"space-y-3 py-3"}>*/}
                        {/*            <div className={"space-y-3 h-height300 overflow-y-auto scrollbar-hidden"}>*/}
                        {/*                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">*/}
                        {/*                    <div class="w-12 h-12 flex-none relative border dark:border-themeDarkColorLine shadow rounded-full">*/}
                        {/*                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>*/}
                        {/*                        <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>*/}
                        {/*                    </div>*/}
                        {/*                    <div class="flex-1">*/}
                        {/*                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>*/}
                        {/*                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>*/}
                        {/*                    </div>*/}
                        {/*                </Link>*/}
                        {/*                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">*/}
                        {/*                    <div class="w-12 h-12 flex-none relative border dark:border-themeDarkColorLine shadow rounded-full">*/}
                        {/*                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>*/}
                        {/*                        <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>*/}
                        {/*                    </div>*/}
                        {/*                    <div class="flex-1">*/}
                        {/*                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>*/}
                        {/*                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>*/}
                        {/*                    </div>*/}
                        {/*                </Link>*/}
                        {/*                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">*/}
                        {/*                    <div class="w-12 h-12 flex-none relative border dark:border-themeDarkColorLine shadow rounded-full">*/}
                        {/*                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>*/}
                        {/*                        <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>*/}
                        {/*                    </div>*/}
                        {/*                    <div class="flex-1">*/}
                        {/*                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>*/}
                        {/*                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>*/}
                        {/*                    </div>*/}
                        {/*                </Link>*/}
                        {/*                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">*/}
                        {/*                    <div class="w-12 h-12 flex-none relative border dark:border-themeDarkColorLine shadow rounded-full">*/}
                        {/*                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>*/}
                        {/*                        <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>*/}
                        {/*                    </div>*/}
                        {/*                    <div class="flex-1">*/}
                        {/*                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>*/}
                        {/*                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>*/}
                        {/*                    </div>*/}
                        {/*                </Link>*/}
                        {/*                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">*/}
                        {/*                    <div class="w-12 h-12 flex-none relative border dark:border-themeDarkColorLine shadow rounded-full">*/}
                        {/*                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>*/}
                        {/*                        <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>*/}
                        {/*                    </div>*/}
                        {/*                    <div class="flex-1">*/}
                        {/*                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>*/}
                        {/*                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>*/}
                        {/*                    </div>*/}
                        {/*                </Link>*/}
                        {/*                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">*/}
                        {/*                    <div class="w-12 h-12 flex-none relative border dark:border-themeDarkColorLine shadow rounded-full">*/}
                        {/*                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>*/}
                        {/*                        <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>*/}
                        {/*                    </div>*/}
                        {/*                    <div class="flex-1">*/}
                        {/*                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>*/}
                        {/*                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>*/}
                        {/*                    </div>*/}
                        {/*                </Link>*/}
                        {/*                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">*/}
                        {/*                    <div class="w-12 h-12 flex-none relative border dark:border-themeDarkColorLine shadow rounded-full">*/}
                        {/*                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>*/}
                        {/*                        <div class="w-3 h-3 bg-themeSuccess absolute end-0 bottom-0 rounded-full border-2 border-white"></div>*/}
                        {/*                    </div>*/}
                        {/*                    <div class="flex-1">*/}
                        {/*                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>*/}
                        {/*                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>*/}
                        {/*                    </div>*/}
                        {/*                </Link>*/}
                        {/*            </div>*/}
                        {/*            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("Draft")}</Link>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

            }
        </div>

    )
}