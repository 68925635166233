import React, {useEffect, useState} from "react";
import {FantasyModal} from "../../UIParts/FantasyModal";
import {DisplayError} from "../../Errors/DisplayError";
import {InputText} from "primereact/inputtext";
import {InputSwitch} from "../../Filters/InputSwitch";
import {TinyLoading} from "../../Loadings/TinyLoading";
import {useTranslation} from "react-i18next";
import {useCustomBank} from "./useCustomBank";
import {POST} from "../../../../Services/AxiosService/AxiosApiService";
import {CreateBank} from "./bankService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {gatewaySchema} from "../../../GeneralManagement/GateWay/GateWayAdd/gatewaySchema";
import {bankSchema} from "../../../CRM/PersonsManagement/Banks/bankSchema";
import {LoadingHarmony} from "../../Loadings/LoadingHarmony";
import {RequiredIcon} from "../../Errors/RequiredIcon";

export const BankCreation=(props)=>{
    const {t}=useTranslation();
    const [IsshowSpinner,setIsShowSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [refresh,setRefresh] = useState(false);
    const [butLoading,setBtnLoading] = useState(false);
    const {handleDefaultBank,handleValue,bank,setBank} = useCustomBank(setErrors);
    const handleClearErrors=()=>{
        setErrors({});
    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const fetchData=(IsOpen)=>{
        if(IsOpen){
            setBank({name:'',description: '',isDefault: false});
            handleClearErrors();
        }
    }
    useEffect(()=>{
        fetchData(props.IsOpen);
    },[refresh,props.IsOpen]);




    const handleCreateBank=async(e)=>{
        e.preventDefault();
        const isValid=validate(bankSchema,bank,onSetErrors);
        if(!isValid){
            return;
        }
        try {
            showSpinner();
            const bankData = CreateBank(bank);
            const response = await POST(`/Banks`,bankData,false);
            if(response.status === 200){
                props.handleSuccess(true);
                hideSpinner();
            }
            else{
                handleApiError(response);
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    return (
        <>


                <form method="post" onSubmit={handleCreateBank}>
                    {/*{*/}
                    {/*    IsshowSpinner?*/}
                    {/*        <div className=" huploader_loading" >*/}
                    {/*            <div className="relative">*/}
                    {/*                <LoadingHarmony/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        :*/}
                    {/*        null*/}
                    {/*}*/}
                    <div className="space-y-3">
                        <div className="space-y-2">
                            <div className={"flex items-center gap-2 justify-between"}>
                                <label className="dark:text-gray-300 text-xs">
                                    {t("BankName")}
                                <RequiredIcon/>
                                </label>
                                <DisplayError message={errors.name}/>
                            </div>

                            <InputText onInput={(e) => handleValue(e)}
                                       value={bank.name}
                                       type="text"
                                       className="input w-full"
                                       name={'name'}
                            />
                        </div>

                        <div className="space-y-2">
                            <label className="dark:text-gray-300 text-xs">{t("additionalDetails")}</label>
                            <input onInput={(e) => handleValue(e)}
                                   value={bank.description}
                                   type="text"
                                   className="input w-full"
                                   name={'description'}
                            />
                        </div>
                        <div className="space-y-2">
                            <InputSwitch value={bank.isDefault} OnValueSelection={handleDefaultBank} name={'isDefault'} lable={t("default")} />
                        </div>

                        <div>
                            <button type="submit" className="button bg-themeInformation dark:bg-themeDarkInformation w-full text-white relative">
                                {t("register")}
                                {butLoading ? (
                                    <TinyLoading/>
                                ) : (
                                    null
                                )}
                            </button>
                        </div>
                    </div>
                </form>

        </>
    )
}