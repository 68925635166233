import {useTranslation} from "react-i18next";

import React, {useEffect, useState} from "react";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useCustomConfirmedRequestGrid} from "./useCustomConfirmedRequestGrid";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {TransactionItem} from "../TransactionItem";
import {NoDataHarmony} from "../../../Commans/NoDataHarmony";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const ConfirmedRequest=()=>{

    const { t } = useTranslation();
    const [errors,setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({currencies:[],paymentStates:[]});
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(false)

    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }

    const handleInitialSource=(currencies,paymentStates)=>{
        const currencyData = convertSelectDataHandler(currencies);
        const paymentStateData = convertSelectDataHandler(paymentStates);
        setInitialSource({
            currencies: currencyData,
            paymentStates: paymentStateData,
        })
    }
    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
            const responsePaymentState = await GET(`/Enumerations/EnPaymentStatus`,{},'IEnumerable');
            if(response.status === 200 && responsePaymentState.status === 200){
                handleInitialSource(response.data,responsePaymentState.data);
                hideSpinner();
            }
            else {
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[refresh])



    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }
    const GetTransactionType=(type)=>{
        return (<>
            {
                type === 1?
                    t('Deposit'):
                    t('Withdrawal')

            }
        </>)
    }
    const GetTransactionIcon=(type)=>{
        return (<>
            {
                type === 1?
                    <div className={"flex-shrink-0 bg-themeSuccess h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-check"} iconClass="text-white"/>
                    </div>
                    :
                    type === 2?
                    <div className={"flex-shrink-0 bg-themeDanger h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-times"} iconClass="text-white"/>
                    </div>
                        :
                     type === 0?
                         <div className={"flex-shrink-0 bg-themeSecondary h-full grid place-content-center w-12"}>
                             <HPrimeIcon icon={"pi-clock"} iconClass="text-white"/>
                         </div>:
                         null

            }

        </>)
    }
    const GetPaymentState = (paymentStatus) => {
        if (!initialSource.paymentStates) {
            return;
        }
        const data = initialSource.paymentStates.find((item) => item.value === paymentStatus);
        return data ? data.label : '';
    }


    const {autoGroupColumnDef,defaultColDef,getRowHeight,rowData,handleCurrentPage,handleNextPage,handlePreviousPage,handlePageSizeCount,pagination} = useCustomConfirmedRequestGrid(showSpinner,hideSpinner,refresh)



    return (<>
        <div className={'relative'}>
            {
                IsShowSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            {rowData.length > 0 ? rowData.map((item, index) => (
                <TransactionItem key={index} item={item} GetTransactionIcon={GetTransactionIcon} GetTransactionType={GetTransactionType} GetPaymentState={GetPaymentState} GetCurrencyType={GetCurrencyType} showSpinner={showSpinner} hideSpinner={hideSpinner} Refresh={Refresh} />
            )):
                <NoDataHarmony/>
            }
        </div>
        {rowData.length !== 0 ?
            <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                :
            null
        }

        <ToastContainer/>


    </>)
}