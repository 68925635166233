import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";


import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Select from "react-select";
import {InputTextarea} from "primereact/inputtextarea";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";

import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {validate} from "../../../Services/Globals/validate";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {InputNumber} from "primereact/inputnumber";
import {commissionSchema2} from "./commissionSchema2";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {
    convertAddCommissionGroup,
    convertAddCommissionRequest,
    convertCommissions,
    convertEditCommissionGroup,
    convertEditCommissionRequest
} from "./CommissionService2";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";


export const HandleCommission2=()=>{


    const { t } = useTranslation();
    const [modal, setModal] = useState({ Commision: false });
    const  redirectTo = useNavigate();
    const [result,setResult] = useState(0);
    const [isEdit,setIsEdit] = useState(false);
    const [spinner,setSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [options,setOptions] = useState({discounts:[],discountOrCommissions:[],quantityAmounts:[],computations:[],filters:[]})
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [IsOpenSimple, setOpenSimple] = useState(false);



    const [commision,setCommision] = useState([]);
    const [commisionsGroup,setCommisionGroup] = useState({commissionGroupId:null,caption:null,description:null,stateShow:true,fromDate:null,toDate:null,computationBase:null,targetTypeId:null, commisions:[]})

    const openSimple=()=>{
        setOpenSimple(true)
    }

    const closeSimple=()=>{
        setOpenSimple(false)
    }



    const handleCloseCommision = () => {
        setModal((prevState) => ({ ...prevState, Commision: !modal.Commision }));
        setErrors({});
        setCommisionGroup({caption:'',description:'',stateShow:true,fromDate:new Date(),toDate:new Date(),computationBase: options.computations[0],targetTypeId: null});
        setCommision([]);
        redirectTo('/Commission');
        setIsEdit(false);
    };

    const handleOpenCommision=()=>{
        setModal({ Commision: true });
        if (commision.length === 0) {
            handleAddRow();
        }
        setCommisionGroup((prevState)=>({...prevState,toDate: new Date(),fromDate: new Date(),stateShow: true,computationBase: options.computations[0],targetTypeId: null}))
    }
    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setCommisionGroup({...commisionsGroup,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setCommisionGroup({ ...commisionsGroup, [name]: data });
    }
    const handleFromDate = (dateTime)=>{
        setCommisionGroup({...commisionsGroup,fromDate: dateTime})
    }
    const handleToDate = (dateTime)=>{
        setCommisionGroup({...commisionsGroup,toDate: dateTime})
    }
    const handleFetchData =async()=>{
        try {
            const responseDiscount = await GET('/Enumerations/EnDiscountOrCommissionType',{},'IEnumerable');
            const responseQuantity = await GET('/Enumerations/EnQuantityAndAmount',{},'IEnumerable');
            const responseComputationBase = await GET('/Enumerations/EnComputationBase',{},'IEnumerable');
            const responseFilter = await GET(`/Filters/Selection?Table=${1}`,{},'IEnumerable');

            if(responseDiscount.status === 200 && responseQuantity.status === 200 && responseComputationBase.status === 200 && responseFilter.status === 200){
                const discounts = convertSelectDataHandler(responseDiscount.data);
                const quantities = convertSelectDataHandler(responseQuantity.data);
                const computationBases = convertSelectDataHandler(responseComputationBase.data);
                const filters = convertSelectDataHandler(responseFilter.data);
                setOptions((prevState)=>({...prevState,discountOrCommissions: discounts,quantityAmounts: quantities,computations: computationBases,filters: filters}));
            }else{
                handleApiError(responseDiscount,onApiError);
                handleApiError(responseQuantity,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        handleFetchData();
    },[])

    const handleEditRecord=async (recordId)=>{
        try {
            setSpinner(true);
            setIsEdit(true);
            setModal(prevState => ({...prevState,Commision: true}));
            const response = await GET(`/CommissionGroups/${recordId}`,{},'single');
            if(response.status === 200){

                const commissions = convertCommissions(response.data.commissions,options.discountOrCommissions,options.quantityAmounts);
                setCommision(commissions);
                setCommisionGroup({
                    commissionGroupId: response.data.commissionGroupId,
                    caption: response.data.caption,
                    description: response.data.description,
                    fromDate: response.data.fromDate,
                    toDate: response.data.toDate,
                    stateShow: response.data.stateShow,
                    computationBase: options.computations.find((item)=>item.value === response.data.computationBase),
                    targetTypeId: options.filters.find((item)=>item.value === response.data.targetTypeId)
                })
                setSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const handleRemoveCommisionGroup = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`CommisionGroups/${id}`, {headers: { 'Content-Type': 'application/json' }},)
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                        handleSuccessMessage(t("Discountcampaignhasbeensuccessfullydeleted"));
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const handleAddRow = () => {
        const newRow = {
            id: new Date().getTime(),
            commisionId: null,
            discountOrCommissionType:options.discountOrCommissions[0],
            quantityOrAmount:options.quantityAmounts[0],
            commissionValue: 0,
            stepValue: 0,
            minValue: 0,
            maxValue:0,
        };
        setCommision((prevList) => [...prevList, newRow]);
    };

    const handleRemoveRow = (id, commissionId) => {
        if (commision.length === 1) {
            return;
        }
        setCommision((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.commissionId === commissionId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };


    const handleChange = (id, field, value, commisionId) => {
        setCommision((prevList) => {
            const updatedList = prevList.map((row) => {
                if ((commisionId && row.commisionId === commisionId) || (!commisionId && id && row.id === id)) {
                    const updatedRow = { ...row, [field]: value };
                    return updatedRow;
                }
                return { ...row, isDefault: false };
            });

            return updatedList;
        });
    };


    const handleCommissionGroupSubmit= async (e)=>{
        e.preventDefault();
        try {
           if(isEdit){
               const isValid = validate(commissionSchema2,commisionsGroup,onSetErrors);
               if(!isValid){
                   return;
               }
               setSpinner(true);
               const commissionRequests = convertEditCommissionRequest(commision);
               const convertedEditCommissionGroup=convertEditCommissionGroup(commisionsGroup,commissionRequests);
               const {commissionGroupId,caption,stateShow,fromDate,toDate,targetTypeId,description,computationBase,commissions}=convertedEditCommissionGroup;
               const response = await PUT(`/CommissionGroups`,{commissionGroupId,caption,stateShow,fromDate,toDate,targetTypeId,description,computationBase,commissions},false);
               if(response.status === 200){
                   setSpinner(false);
                   setIsEdit(false);
                   handleCloseCommision()
                   setResult({...response.status});
                   handleSuccessMessage(t("Successfullyedited"));
               }else{
                   handleApiError(response,onApiError);
               }
           }else{
               const isValid = validate(commissionSchema2,commisionsGroup,onSetErrors);
               if(!isValid){
                   return;
               }
               const commissionRequests = convertAddCommissionRequest(commision);
               const convertedCommissionGroup = convertAddCommissionGroup(commisionsGroup,commissionRequests);
               const {caption,stateShow,fromDate,toDate,targetTypeId,description,computationBase,commissions}=convertedCommissionGroup;
               const response = await POST('/CommissionGroups', {caption,stateShow,fromDate,toDate,targetTypeId,description,computationBase,commissions},false);
               if(response.status === 200){
                   setResult({...response.status});
                   setSpinner(false);
                   setIsEdit(false);
                   handleCloseCommision();
                   handleSuccessMessage(t("Successfullyregistered"));
               }else{
                   handleApiError(response,onApiError);
               }
           }
        }
        catch (e) {
            console.log(e);
        }
    }











    const [isMenuOpen,setMenuOpen] = useState(false);
    const handleOpenMenu = () => {
        setMenuOpen(true);
    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }

    const handleChangeStatus=async(id,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            const response = await PUT(`/CommissionGroups/EditCommissionGroupActivation`,{id:id},false);
            if (response.status === 200) {
                setWait(false)
                setResult({...response.status})
            }
            else{
                setWait(false)
                handleApiError(response,onApiError)
            }
        }catch (e) {
            console.log(e);

        }
    }




    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("Title"),field: 'caption', minWidth: 140},
        { headerName: t("TargetAudience"),field: 'targetTypeId', minWidth: 140},


        { headerName: t("FromDate"), minWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.fromDate} /></>
            }
        },
        { headerName:t("ToDate"), minWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.toDate} /></>
            }
        },
        { headerName: t("DisplayStatus"),field: 'stateShow', minWidth: 115, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading  />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeStatus(params.data.commissionGroupId,params.colDef.headerName)}
                                     icon={'pi-check-square'}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeStatus(params.data.commissionGroupId,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'pi-times-circle'}

                        />
                    )}
                </div>
        },
        { headerName:t("description"),field: 'description', minWidth: 140},
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={() => handleEditRecord(params.data.commissionGroupId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleRemoveCommisionGroup(params.data.commissionGroupId)}
                    />
                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',fromDate:null,toDate:null,isActive:null,isDeActive:null,isExpired:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search: '',fromDate: null,toDate: null,isActive: null,isDeActive: null,isExpired: null}));
        setSearch('');
    }


    const handleRegisterFromDate=(dateTime)=>{
        setFilter((prevState)=>({...prevState,fromDate: dateTime}))
    }
    const handleRegisterToDate=(dateTime)=>{
        setFilter((prevState)=>({...prevState,toDate: dateTime}))
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData();
                } else {
                    handleApiError(response,onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [result,currentPage,data.pageSize,filter.search,filter.toDate,filter.fromDate,filter.isActive,filter.isDeActive,filter.isExpired]);


    const [rowData, setRowData] = useState([]);

    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/CommissionGroups`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,FromDate:filter.fromDate,ToDate:filter.toDate,IsActive:filter.isActive,IsDeActive:filter.isDeActive,IsExpired:filter.isExpired}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };



    const switchData = [
        {name:'isActive',lable:t("isActive"),value:filter.isActive},
        {name:'isDeActive',lable:t("isNotActive"),value:filter.isDeActive},
        {name:'isExpired',lable:t("expired"),value:filter.isExpired},
    ]



    return(
        <>
            <div class="space-y-5 relative">
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByNameCode")}</label>
                                <InputText  onChange={(e)=>setSearch(e.target.value)}  value={search} type="text" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                            </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleRegisterFromDate} value={filter.fromDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}
                            </label>
                            <DateTimePicker value={filter.toDate} handleDateTime={handleRegisterToDate} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Simple")}
                                            onClick={() => openSimple()}
                                            tooltipTitle={t("SimpleCommission")}
                                            icon={"thumbs-up"}
                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />


                </div>
                <div className="space-y-2 H-body-ag-grid">

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export/>
            </div>

            <FantasyModal openModal={modal.Commision}  closeModal={handleCloseCommision} title={t("DeterminationCustomCommission")} customeClassName={" modal-x"}>
               <form onSubmit={handleCommissionGroupSubmit} method={'post'}>
                   <div className="space-y-4">
                       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 ">
                           <div class="space-y-2 ">
                               <div className={"flex justify-between items-center gap-2"}>
                                   <label className="text-xs dark:text-gray-300">{t("CampaignTitle")}</label>
                                   <DisplayError message={errors.caption}/>
                               </div>
                               <InputText type={"text"} name={'caption'}  value={commisionsGroup.caption} onInput={(e)=>handleValue(e)} className={" input w-full"} />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("SetInterval")}</label>
                               <Select
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                               />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">
                                   <SettingButton
                                       onClick={()=>handleSetting('GeneralSetting')}
                                       tooltipTitle={t("Calendarsettings")}
                                   />
                                   {t("FromDate")}
                               </label>
                               <DateTimePicker value={commisionsGroup.fromDate} handleDateTime={handleFromDate} format={'YYYY/MM/DD'}  />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">
                                   <SettingButton
                                       onClick={()=>handleSetting('GeneralSetting')}
                                       tooltipTitle={t("Calendarsettings")}
                                   />
                                   {t("ToDate")}
                               </label>
                               <DateTimePicker value={commisionsGroup.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'}  />
                           </div>
                           <div class="space-y-2 md:col-span-2 lg:col-span-4">
                               <InputTextarea name={"description"} value={commisionsGroup.description} placeholder={t("Explanation")} onInput={(e)=>handleValue(e)}  rows={3} cols={30} className={" w-full input"} />
                           </div>
                           <div>
                               <InputSwitch OnValueSelection={handleSelectValue} value={commisionsGroup.stateShow} name={'stateShow'} lable={t("Active")} />
                           </div>
                       </div>
                       <div className="bg-white box p-2 space-y-2 dark:bg-themeDarkSalt700">
                           <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                               <p className={"text-xs dark:text-gray-300"}>{t("TargetAudience")}</p>
                           </div>
                           <div className={"px-2"}>
                               <div className="grid grid-cols-1 md:grid-cols-2 gap-3 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                   <div class="space-y-1">
                                       <label className="text-xs dark:text-gray-300">{t("CommissionFor")}</label>
                                       <Select
                                           options={options.filters}
                                           isMulti={false}
                                           isClearable={true}
                                           isSearchable={true}
                                           onChange={(data) => handleSelectValue(data,'targetTypeId')}
                                           placeholder={t("selection")}
                                           value={commisionsGroup.targetTypeId}
                                           classNamePrefix={"h_multi_select"}
                                       />
                                   </div>
                                   <div class="space-y-1 ">
                                       <label className="text-xs dark:text-gray-300">{t("Unit")}</label>
                                       <Select
                                           options={options.computations}
                                           isMulti={false}
                                           isClearable={false}
                                           isSearchable={true}
                                           onChange={(data) => handleSelectValue(data,'computationBase')}
                                           placeholder={t("selection")}
                                           value={commisionsGroup.computationBase}
                                           classNamePrefix={"h_multi_select"}
                                       />
                                   </div>
                               </div>
                           </div>

                       </div>
                       <div>
                           <Accordion className="bg-white box dark:bg-themeDarkSalt700 ">
                               <AccordionTab header={t("Conditions")}>
                                   <div className={"space-y-3 max_height20"}>
                                           {commision.map((row, index) => (
                                               <div className="grid grid-cols-2 lg:grid-cols-6 items-end gap-2 post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                                                   <div class="space-y-1 w-full">
                                                       <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>
                                                       <InputNumber type={"text"}  name={'minValue'} onValueChange={(e)=>handleChange(row.id,'minValue',e.value,row.commissionId)} value={row.minValue} />
                                                   </div>
                                                   <div class="space-y-1 w-full">
                                                       <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>
                                                       <InputNumber type={"text"}  name={'maxValue'} onValueChange={(e)=>handleChange(row.id,'maxValue',e.value,row.commissionId)} value={row.maxValue} />
                                                   </div>
                                                   <div class="space-y-1 w-full">
                                                       <label className="text-xs dark:text-gray-300">{t("StepIncrease")}</label>
                                                       <InputNumber type={"text"} name={'stepValue'} onValueChange={(e)=>handleChange(row.id,'stepValue',e.value,row.commissionId)}     value={row.stepValue} />
                                                   </div>
                                                   <div class="space-y-1">
                                                       <label className="text-xs dark:text-gray-300">{t("Unit")}</label>
                                                       <Select
                                                           options={options.quantityAmounts}
                                                           isMulti={false}
                                                           isClearable={false}
                                                           isSearchable={true}
                                                           onChange={(data) => handleChange(row.id, 'quantityOrAmount',data,row.commissionId)}
                                                           placeholder={t("selection")}
                                                           value={row.quantityOrAmount}
                                                           menuPosition={'fixed'}
                                                           classNamePrefix={"h_multi_select"}
                                                       />
                                                   </div>

                                                   <div class="space-y-1">
                                                       <label className="text-xs dark:text-gray-300">{t("TypeOfCommission")}</label>
                                                       <Select
                                                           options={options.discountOrCommissions}
                                                           isMulti={false}
                                                           isClearable={true}
                                                           isSearchable={true}
                                                           onChange={(data) => handleChange(row.id, 'discountOrCommissionType',data,row.commissionId)}
                                                           placeholder={t("selection")}
                                                           value={row.discountOrCommissionType}
                                                           menuPosition={'fixed'}
                                                           classNamePrefix={"h_multi_select"}
                                                       />
                                                   </div>
                                                   <div className={"flex items-end justify-between gap-2"}>
                                                       <div className="space-y-1">
                                                           <label className="text-xs dark:text-gray-300">{t("Commission")}</label>
                                                           <InputNumber type={"text"}  name={'commissionValue'} onValueChange={(e)=>handleChange(row.id,'commissionValue',e.value,row.commissionId)} value={row.commissionValue} />
                                                       </div>

                                                       <div className="flex gap-1">
                                                               <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white flex items-center gap-1 text-xs" onClick={handleAddRow}>
                                                                   <HPrimeIcon icon={'pi-plus'}  />
                                                               </button>
                                                               <button type="button" className="button small_button bg-themeDanger text-white flex items-center gap-1 text-xs" onClick={() => handleRemoveRow(row.id,row.commissionId)}>
                                                                   <HPrimeIcon icon={'pi-minus'}  />
                                                               </button>
                                                           </div>

                                                   </div>


                                               </div>
                                           ))}
                                   </div>
                               </AccordionTab>
                           </Accordion>
                       </div>
                       <div>
                           <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                       </div>
                   </div>
               </form>
            </FantasyModal>
            <FantasyModal openModal={IsOpenSimple}  closeModal={closeSimple} title={t("SimpleCommission")} customeClassName={" modal-x"}>
                <div className="space-y-4">
                       <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 ">
                           <div class="space-y-2 ">
                               <div className={"flex justify-between items-center gap-2"}>
                                   <label className="text-xs dark:text-gray-300">{t("CampaignTitle")}</label>
                                   <DisplayError message={errors.caption}/>
                               </div>
                               <InputText type={"text"} name={'caption'}  value={commisionsGroup.caption} onInput={(e)=>handleValue(e)} className={" input w-full"} />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("SetInterval")}</label>
                               <Select
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                               />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">
                                   <SettingButton
                                       onClick={()=>handleSetting('GeneralSetting')}
                                       tooltipTitle={t("Calendarsettings")}
                                   />
                                   {t("FromDate")}
                               </label>
                               <DateTimePicker value={commisionsGroup.fromDate} handleDateTime={handleFromDate} format={'YYYY/MM/DD'}  />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">
                                   <SettingButton
                                       onClick={()=>handleSetting('GeneralSetting')}
                                       tooltipTitle={t("Calendarsettings")}
                                   />
                                   {t("ToDate")}
                               </label>
                               <DateTimePicker value={commisionsGroup.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'}  />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("******")}</label>
                               <Select
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                               />
                           </div>
                           {/*براساس انتخاب */}
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("FirstNameAndLastName")}</label>
                               <Select
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                               />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("UserGroup")}</label>
                               <Select
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                               />
                           </div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("UserRole")}</label>
                               <Select
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                               />
                           </div>
                           {/*براساس انتخاب */}
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("TypeOfCommission")}</label>
                               <Select
                                   options={options.discountOrCommissions}
                                   isMulti={false}
                                   isClearable={true}
                                   isSearchable={true}
                                   placeholder={t("selection")}
                                   menuPosition={'fixed'}
                                   classNamePrefix={"h_multi_select"}
                               />
                           </div>
                           <div className="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("Commission")}</label>
                               <InputNumber type={"text"}  name={'commissionValue'}  />
                           </div>

                       </div>
                        <div>
                            <InputTextarea name={"description"} value={commisionsGroup.description} placeholder={t("Explanation")} onInput={(e)=>handleValue(e)}  rows={3} cols={30} className={" w-full input"} />
                        </div>
                        <div className={"flex justify-items-start"}>
                            <InputSwitch OnValueSelection={handleSelectValue} value={commisionsGroup.stateShow} name={'stateShow'} lable={t("Active")} />
                        </div>
                       <div>
                           <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                       </div>
                   </div>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
            {console.log(commisionsGroup)}
            {console.log(commision)}
        </>
    )
}