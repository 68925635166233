import React from "react";

import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../UIParts/HPrimeIcon";

export const SlugGenerator=({data,setData,showSpinner,hideSpinner})=>{
    const { t } = useTranslation();

    const handleGetAutomatedSlug=async()=>{
        try {
            if(data.title === null || data.title === undefined || data.title === ""){
                handleFailureMessage(t('PleaseEnterSlug'))
                return;
            }
            showSpinner();
            const response = await GET(`/Ai/Slug`,{InputText:data.slug},false);
            if(response.status === 200){
                const {responseText}=response.data;
                setData((prevState)=>({...prevState,slug: responseText}));
                handleSuccessMessage(t('SuccessDone'));
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }

    return (<>

        <button type={"button"} onClick={()=>handleGetAutomatedSlug()}>
            <HPrimeIcon icon={`pi-arrow-right-arrow-left`} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
        </button>

        </>
    )
}