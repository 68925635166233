import React from "react";
import {GridDateFomartter} from "../Commans/Grid/GridDateFomartter";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const TopVisitedPage=({pageUrl,pageViews})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className="flex items-center justify-between  H-direction-ltr">
                    <div className={"max_width85"}>
                        <Link to={pageUrl} target={"_blank"} className="text-themeInformation hover:underline line-clamp-1 text-xs dark:text-themeDarkInformation">{pageUrl}</Link>
                    </div>
                    <div className="flex items-center flex-shrink-0">
                        <p className=" text-xs dark:text-gray-300">({pageViews})</p>
                    </div>

            </div>
        </>
    )
}