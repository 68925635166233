import React, { useEffect, useRef } from "react";
import { DisplayError } from "../Errors/DisplayError";
import { InputText } from "primereact/inputtext";
import Select from "react-select";
import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import {RequiredIcon} from "../Errors/RequiredIcon";

import { Controlled as CodeMirror } from 'react-codemirror2'; // Ensure this import is correct
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css'; // Theme for colorization
import 'codemirror/mode/xml/xml'; // XML mode for HTML
import 'codemirror/mode/javascript/javascript'; // JavaScript mode
import 'codemirror/mode/css/css'; // CSS mode
import 'codemirror/theme/solarized.css'
import 'codemirror/mode/markdown/markdown'

import {InputTextarea} from "primereact/inputtextarea"; // For HTML mode support


export const ShortCode = (props) => {
    const { t } = useTranslation();
    const editorRef = useRef(null);

    const options = {
        autoIndent: 'full',
        contextmenu: true,
        fontFamily: 'monospace',
        fontSize: 'inherits',
        lineHeight: 24,
        hideCursorInOverviewRuler: true,
        matchBrackets: 'always',
        minimap: {
            enabled: true,
        },
        scrollbar: {
            horizontalSliderSize: 4,
            verticalSliderSize: 18,
        },
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,
    };

    // This function will be called when the editor is mounted
    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
        // Force layout calculation to ensure proper rendering
        setTimeout(() => {
            editor.layout();
        }, 100);
    };

    useEffect(() => {
        // Call the layout method when the component is updated to ensure the editor resizes correctly
        if (editorRef.current) {
            editorRef.current.layout();
        }
    }, [props.shortCode.type]);

    return (
        <>
            <form method={'post'} onSubmit={props.handleAddCodeSnippet}>
                <div className="space-y-4 ">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                        <div className="flex gap-2 items-center">
                            <div className="flex-1">
                                <div className="space-y-2">
                                    <div className="flex justify-between gap-3">
                                        <label className="text-xs dark:text-gray-300 text-nowrap">
                                            {t("TagTitle")}
                                        <RequiredIcon/>
                                        </label>
                                        <DisplayError message={props.errors.name} />
                                    </div>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon border dark:border-themeDarkColorLine dark:text-gray-300">(</span>
                                        <InputText name={'name'} value={props.shortCode.name} onInput={(e) => props.handleValue(e)} placeholder="Tag" className="input" />
                                        <span className="p-inputgroup-addon dark:text-gray-300 text-xs border dark:border-themeDarkColorLine">Tag )</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="flex gap-2 items-end">
                                <div className="flex-1 space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("template")}</label>
                                    <Select
                                        options={props.options.templates}
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data) => props.handleSelectValue(data, 'templateId')}
                                        placeholder={t("selection")}
                                        value={props.shortCode.templateId}
                                        menuPosition={'fixed'}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="flex flex-col items-center gap-y-2">
                                    <label className="text-xs dark:text-gray-300 ">{t("HTML")}</label>
                                    <Checkbox onChange={e => props.handleIsHtml(e.checked)} checked={props.shortCode.type !== 0}></Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            props.shortCode.type === 1 ?
                                <div className="ShortCode_CodeEditor_body " style={{direction:'ltr'}}>
                                    <CodeMirror
                                        value={props.shortCode.value} // Bind the editor value to props
                                        options={{
                                            mode: 'htmlmixed', // HTML mode that supports mixed content (HTML, CSS, JS)
                                            theme: 'material', // Choose a theme that supports colorization
                                            lineNumbers: true, // Display line numbers
                                            tabSize: 2, // Set tab size for indentation
                                            indentUnit: 2, // Set indentation unit
                                            lineWrapping: true, // Enable line wrapping for better readability
                                            color:'red'
                                        }}
                                        onBeforeChange={(editor, data, value) => {
                                            const simulatedEvent = { target: { name: 'value', value } }; // Simulate the event object
                                            props.handleValue(simulatedEvent); // Pass the simulated event to the handler
                                        }}
                                    />
                                </div>
                                :
                                <InputTextarea onInput={(e) => props.handleValue(e)} name={'value'} value={props.shortCode.value} rows={15} cols={30} className={"input w-full H-direction-ltr"} />
                        }

                    </div>
                    <div>
                        <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation w-full text-white">{t("register")}</button>
                    </div>
                </div>
            </form>
        </>
    )
}
