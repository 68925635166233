import React from "react";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";
import {InputText} from "primereact/inputtext";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";

export const WebsiteBox=({title,domain})=>{
    const { t } = useTranslation();
    const handleOpenWebsite = (url) => {
        if (url) {
            window.open(url, '_blank'); // Opens the URL in a new tab
        }
    };
    const handleCopyToClipboard = async (text) => {
        if (text) {
            try {
                handleSuccessMessage(' کپی شد')
                await navigator.clipboard.writeText(text);
            }
            catch (error) {
              console.log(error)
            }
        }
    };
    return (
        <>
            <div className={"post-input p-2 post-input-design dark:bg-themeDarkPostInput space-y-3"}>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 flex gap-2 items-center"}>
                    <HPrimeIconSmall icon={"pi-globe"} iconClass={"dark:text-gray-300"} size={16}/>
                    <p className={"text-xs dark:text-gray-300"}>{t("Websites")}</p>
                </div>
                <div className={"max_height10 space-y-2 p-2"}>
                    <div className={"flex gap-2 items-center "}>
                        <div className={"min-w-16"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("Website")}</p>
                        </div>
                        <div className={"flex-1"}>
                            <InputText className={"input "} value={domain} disabled={true}/>
                        </div>
                        <div className={"flex flex-shrink-0 gap-2 items-center"}>
                            <button onClick={()=>handleCopyToClipboard(domain)} className={"button bg-themeSuccess"}>
                                <HPrimeIconSmall
                                    icon={"pi-copy"}
                                    iconClass={"text-white"}
                                />
                            </button>
                            <button  onClick={()=>handleOpenWebsite(domain)} className={"button  bg-themeInformation dark:bg-themeDarkInformation"}>
                                <HPrimeIconSmall
                                    icon={"pi-globe"}
                                    iconClass={"text-white"}
                                />
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}