import {InputText} from "primereact/inputtext";

import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const ItemFields = ({index,element,t ,addCheckBoxItem,deleteCheckBoxItem,handleItemPropertyChange}) => {

    return (<>

        <div className={"max_height20 space-y-2 px-2"}>
            {element.properties?.items && element.properties.items.length > 0 ? element.properties.items.map((item, itemIndex) => (
                <div key={itemIndex} className="grid grid-cols-1 gap-2">
                    <div className={"flex gap-2 items-center"}>
                        <div className="number_pluse text-white">
                            {itemIndex + 1}
                        </div>
                        <div className="flex-1 space-y-1">
                            <div className="flex items-center gap-2">
                                <InputText
                                    value={item.label}
                                    onChange={(e) => handleItemPropertyChange(index, itemIndex, 'label', e.target.value,'items')}
                                    className="input w-full"
                                    placeholder={t("Text")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"flex gap-2 items-center "}>
                        <div className="flex-1 space-y-1 ">
                            <div className="flex items-center gap-2">
                                <InputText
                                    className="input w-full"
                                    value={item.value}
                                    onChange={(e) => handleItemPropertyChange(index, itemIndex, 'value', e.target.value,'items')}
                                    placeholder={t("Value")}
                                />
                            </div>
                        </div>
                        <div className="flex-shrink-0 flex gap-2 items-center">
                            <button
                                onClick={() => addCheckBoxItem(index,'items')}
                                type={"button"}
                                className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                <HPrimeIcon
                                    icon={"pi-plus"}
                                />
                            </button>
                            <button
                                type={"button"}
                                onClick={() => deleteCheckBoxItem(index, itemIndex,'items')}
                                className="button small_button bg-themeDanger text-white">
                                <HPrimeIcon
                                    icon={"pi-minus"}
                                />
                            </button>
                        </div>
                    </div>

                </div>
            )) : null}
        </div>
    </>)
}