import React, {useEffect, useState} from "react";

import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {useTranslation} from "react-i18next";
import {TransactionItem} from "../TransactionItem";
import {useNavigate} from "react-router-dom";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {GetSpecialProperty} from "../../../../../Services/AxiosService/JwtService";
import Select from "react-select";
import {useCustomWithdraw} from "./useCustomWithdraw";
import {useWithdrawGrid} from "./useWithdrawGrid";
import {LoadingHarmony} from "../../../Loadings/LoadingHarmony";
import {GridDateFomartter} from "../../../Grid/GridDateFomartter";
import {Pagination} from "../../../Grid/Pagination";
import {DisplayError} from "../../../Errors/DisplayError";
import {validate} from "../../../../../Services/Globals/validate";

import {withdrawSchema} from "./withdrawSchema";
import {handleGetDefaultBank, handleGetUserBanks, handleGetWithdraw} from "./withdrawService";
import {ToastContainer} from "react-toastify";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {PriceFormatterComponent} from "../../../Accounting/PriceFormatterComponent";
import {NoDataHarmony} from "../../../NoDataHarmony";
import {HPrimeIcon} from "../../../UIParts/HPrimeIcon";
import {RequiredIcon} from "../../../Errors/RequiredIcon";
import {HPrimeIconSmall} from "../../../UIParts/HPrimeIconSmall";

export const WithDraw=({currentPath,handleActiveTab})=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh,setRefresh] = useState(false);
    const [errors,setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({users:[],currencies:[],userBanks:[]});
    const [IsShowSpinner,setIsShowSpinner] = useState(false);

    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }


    const fetchData = async () => {
        try {
            showSpinner();
            const responseCurrency = await GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable');
            const responseUserBank = await GET(`/Users/Banks/Selection`);
            if (responseCurrency.status === 200 && responseUserBank.status) {
                const currencyData = convertSelectDataHandler(responseCurrency.data);
                const userBanks = handleGetUserBanks(responseUserBank.data);
                const defaultBank = handleGetDefaultBank(userBanks);
                setWallet({userBankId: defaultBank});
                setInitialSource({ currencies: currencyData,userBanks: userBanks });
            } else {
                handleApiError(responseCurrency, hideSpinner);
                return;
            }
            // get all userBanks from server .

            if (currentPath === '/Profile/Wallet') {
                const responseBalance = await GET(`/Wallets/Balance`, {}, 'single');
                if (responseBalance.status === 200) {
                    const { balance } = responseBalance.data;
                    setWallet(prevState => ({...prevState, amount: 0, description: '', walletBalance: balance.amount, currencyType: balance.currency }));
                } else {
                    handleApiError(responseBalance, hideSpinner);
                }
            }
            else {
                const responseUser = await GET(`/Users/Selection`, {}, 'IEnumerable');
                if (responseUser.status === 200) {
                    const userData = convertSelectDataHandler(responseUser.data);
                    setInitialSource({ users: userData });
                    const responseBalance = await GET(`/Wallets/${responseUser.data[0].key}/Balance`, {}, 'single');
                    if (responseBalance.status === 200) {
                        const { balance } = responseBalance.data;
                        setWallet({ amount: 0, userId: userData[0], description: '', walletBalance: balance.amount, currencyType: balance.currency });
                    } else {
                        handleApiError(responseBalance, hideSpinner);
                    }
                } else {
                    handleApiError(responseUser, hideSpinner);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[refresh]);

    const ShowTargetUser = () => {
        return (
            <>
                {currentPath === '/Profile/Wallet' ? (
                    <h2 className={"text-white dark:text-gray-300"}>{GetSpecialProperty('DisplayName')}</h2>
                ) : (
                    <div className=" gap-2 items-center">
                        <Select
                            options={initialSource.users}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(data) => handleSelectValue(data, 'userId')}
                            placeholder={t("selection")}
                            value={wallet.userId}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                        />
                    </div>
                )}
            </>
        );
    };

    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }
    const GetTransactionType=(type)=>{
        return (<>
            {
                type === 1?
                    t('Deposit'):
                    t('Withdrawal')

            }
        </>)
    }
    const GetTransactionIcon=(type)=>{
        return (<>
            {
                type === 1?
                    <div className={"flex-shrink-0 bg-themeSuccess h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-arrow-right"} iconClass="text-white"/>
                    </div>
                    :
                    <div className={"flex-shrink-0 bg-themeDanger h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-arrow-left"} iconClass="text-white"/>
                    </div>
            }

        </>)
    }





    const handleWithdraw=async (e)=>{
        e.preventDefault();
        const isValid = validate(withdrawSchema,wallet,onSetErrors);
        if(!isValid){
            return
        }
        if(wallet.amount === 0){
            return;
        }
        try {
                showSpinner();
                const withdrawData = handleGetWithdraw(wallet);
                const response = await POST('/Wallets/Withdraw',withdrawData,false);
                if(response.status === 200){
                    hideSpinner();
                    Refresh();
                    handleSuccessMessage(t('SuccessDone'));
                    handleActiveTab('MyRequests')

                }
                else{
                    handleApiError(response,hideSpinner);
                }
        }
        catch (e) {
            hideSpinner();
            console.log(e);
        }
    }

    const handleDisplayWithdrawalForm=()=>{
        return (<>
            {
                currentPath === '/Profile/Wallet'?
                    <form onSubmit={handleWithdraw} method={'post'}>
                        <div className="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                            <div className=" space-y-1">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">
                                        {t("Amount")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.amount}/>
                                </div>
                                <InputNumber value={wallet.amount} name={'amount'}
                                             onChange={(e) => handleNumberValue(e.value, 'amount')}/>
                            </div>
                            <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("BankAccount")}</label>
                                <Select
                                    options={initialSource.userBanks}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data) => handleSelectValue(data, 'userBankId')}
                                    placeholder={t("selection")}
                                    value={wallet.userBankId}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div className="space-y-1">
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                    <InputText name={'description'} value={wallet.description}
                                               onInput={(e) => handleValue(e)} type="text" className="input w-full"/>
                                </div>
                            </div>
                            <div>
                                <button
                                    className="bg-themeInformation dark:bg-themeDarkInformation text-white button w-full flex items-center justify-center gap-2 text-lg hover:bg-themeHoverInformation">
                                    <HPrimeIcon icon={'pi-bookmark'}/>
                                    {t("register")}
                                </button>
                            </div>
                        </div>
                    </form>
                    :
                    null
            }

        </>)
    }
    const {
        handleNumberValue,
        handleValue,
        setWallet,
        wallet,
        handleSelectValue
    } = useCustomWithdraw(setErrors, hideSpinner, showSpinner);
    const {
        rowData,
        pagination,
        getRowHeight,
        autoGroupColumnDef,
        defaultColDef,
        handleNextPage,
        handlePreviousPage,
        handleCurrentPage,
        handlePageSizeCount
    } = useWithdrawGrid(showSpinner,hideSpinner,wallet,currentPath);

    return (
        <>
            <div className="grid grid-cols-12 gap-4">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="col-span-12 lg:col-span-4 p-2 box space-y-2 bg-white dark:bg-themeDarkSalt700">
                    <div class="box bg-white dark:bg-themeDarkSalt700 space-y-5">
                        <div class="space-y-3">
                            <div class="Withdraw card_bank max-w-sm mx-auto p-5">
                                <div className={"flex justify-between items-center gap-5"}>
                                    <div className={"flex-1"}>
                                        {
                                            ShowTargetUser()
                                        }
                                    </div>
                                    <div class="chip flex-shrink-0">
                                        <div class="chip-line"></div>
                                        <div class="chip-line"></div>
                                        <div class="chip-line"></div>
                                        <div class="chip-line"></div>
                                        <div class="chip-main"></div>
                                    </div>
                                </div>
                                <div className={"space-y-5"}>
                                    <div class="card-number">
                                        <div class="flex justify-between">
                                            <p className={"text-xs text-white dark:text-gray-300"}>{t("Remaining")}</p>
                                            <p className={"text-white text-xs dark:text-gray-300"}>
                                                <PriceFormatterComponent
                                                price={wallet.walletBalance}/> {GetCurrencyType(wallet.currencyType)}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="flex justify-between">
                                        <p className={"text-xs text-white dark:text-gray-300"}>
                                            {t("CreationDate")}
                                        </p>
                                        <span className={"text-white dark:text-gray-300 font-light"}>
                                            <GridDateFomartter date={new Date()} hasTime={true}/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="rounded relative">
                                <button type={"button"} className="change-money absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-white" onClick={() => handleActiveTab('deposite')}>
                                    <HPrimeIcon
                                        icon={"pi-sort-alt"}
                                    />
                                </button>
                                <div
                                    class="h-12 rounded-t-md flex justify-between items-center px-3 py-5 bg-themeInformation dark:bg-themeDarkInformation text-white">
                                    <div>
                                        {t("ToWallet")}
                                    </div>
                                    <div>
                                        <HPrimeIconSmall icon={'pi-credit-card'} iconClass="text-white "/>
                                    </div>
                                </div>
                                <div class="h-12 rounded-b-md flex justify-between items-center px-3 py-5 bg-themeDanger text-white">
                                    <div>
                                        {t("From")}:
                                        {t("BankAccount")}
                                    </div>
                                    <div>
                                        <HPrimeIconSmall icon={'pi-credit-card'} iconClass="text-white "/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {handleDisplayWithdrawalForm()}
                </div>
                <div className="col-span-12 lg:col-span-8 bg-white dark:bg-themeDarkSalt700 box flex flex-col justify-between">
                    {
                        rowData.length === 0 ?
                            <NoDataHarmony/>
                            :
                    <div>
                        <div className={"p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                            <h2 className={"text-xs dark:text-gray-300"}>{t("TodaysWithdrawals")}</h2>
                        </div>
                        <div className={"p-2"}>
                            <ul className={"grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 gap-5"}>
                                {rowData.length > 0 ? rowData.map((transaction, index) => (
                                    <TransactionItem key={index} GetTransactionType={GetTransactionType}
                                                     GetTransactionIcon={GetTransactionIcon}
                                                     transaction={transaction}
                                                     GetCurrencyType={GetCurrencyType}/>
                                )) : ''}
                            </ul>

                        </div>
                    </div>
                    }
                    {
                        rowData.length !== 0 ?
                            <div className={"p-2"}>
                                <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage}
                                            hasNextPage={pagination.hasNextPage} locale={null}
                                            totalCount={pagination.totalCount} totalPages={pagination.totalPages}
                                            handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage}
                                            handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage}
                                            handlePageSizeCount={handlePageSizeCount}/>
                            </div>
                            :
                            null
                    }

                </div>
            </div>


            <ToastContainer/>
        </>
    )
}