import React, {useEffect, useMemo, useState} from "react";
import {GridTable} from "../../../../Commans/Grid/GridTable";
import {useTranslation} from "react-i18next";
import {NoteBox} from "../UserNotes/NoteBox";

export const Calls=({contacts,type})=>{
    const { t } = useTranslation();

    return (
        <>
            {contacts && contacts.length > 0 ? (
                contacts.map((item, index) =>

                    item.type === type?
                        <NoteBox key={index} index={index} {...item} />
                        :null
                )
            ) : null}
        </>
    )
}