
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DELETE, GET, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {PriceFormatterComponent} from "../../../../Commans/Accounting/PriceFormatterComponent";
import {TinyLoading} from "../../../../Commans/Loadings/TinyLoading";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";
import {GridButtonEdit} from "../../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../../Commans/Grid/GridButtonDelete";

export const useProductTransportGrid=(showSpinner,hideSpinner,setIsEdit,setTransport,transport,setIsOpenTransport,Refresh,refresh,initialSource,handleGetCurrencyType,websiteCurrency)=>{

    const { t } = useTranslation();

    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");

    const hideWaitingLoading=()=>{
        setWait(false);
    }
    const handleChangeActivation = async(productTransportId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            const response = await PUT(`/ProductTransports/Activation`,{productTransportId:productTransportId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh(response.status)
            }
            else{
                handleApiError(response,hideWaitingLoading)
            }
        }catch (e) {
            hideWaitingLoading()
        }
    }

    const handleGetTransport= async(transportId)=>{
        try {
            showSpinner()
            setIsEdit(true);
            const response = await GET(`/ProductTransports/${transportId}`,{},'single');
            if(response.status === 200){
                if(response.data.type === 1){
                    setTransport({
                        id:response.data.id,
                        description:response.data.description,
                        title:response.data.title,
                        fixedAmount:response.data.fixedAmount ?? 0,
                        transportType: 1,
                        shippingMethod:initialSource.shippingMethods.find((item)=>item.value === response.data.shippingMethod),
                        calculationType:initialSource.calculationTypes.find((item)=>item.value === response.data.calculationType)
                    })
                }
                else{
                    setTransport({
                        id:response.data.id,
                        description:response.data.description,
                        title:response.data.title,
                        fixedAmount:response.data.fixedAmount ?? 0,
                        transportType: 0,
                        shippingMethod:response.data.shippingMethod,
                        calculationType:initialSource.calculationTypes.find((item)=>item.value === response.data.calculationType)
                    })
                }
                setIsOpenTransport(true)
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner()
        }
    }

    const handleDeleteProductTransport = async (transportId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/ProductTransports/${transportId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        hideSpinner()
                        Refresh()
                        handleSuccessMessage(t('SuccessfullyDeleted'))
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }






    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t('Title'),minWidth: 140,
            cellRenderer:parameters=>
                <>
                    {parameters.data.title === null || parameters.data.title === ''?'-':parameters.data.title}
                </>
        },
        { headerName: t('type'), maxWidth: 150,
            cellRenderer:parameters=>
                <>
                    {parameters.data.type === 0?t('manual'):t('api')}
                </>
        },
        { headerName: t('Price'), minWidth: 160,maxWidth: 200,
            cellRenderer:parameters=>
                <>{ parameters.data.fixedAmount !== null? <PriceFormatterComponent price={parameters.data.fixedAmount}/>:0} {handleGetCurrencyType(websiteCurrency,initialSource.currencies)}</>
        },
        { headerName: t("Status"),field: 'stateShow', maxWidth: 140, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading />
                    ) : params.data.stateShow ? (

                        <HPrimeIcon iconClass={'cursor-pointer text-themeSuccess'}
                                    onClick={async () => await handleChangeActivation(params.data.id,params.colDef.headerName)}
                                    icon={'pi-check-square'}
                        />

                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeActivation(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'pi-times-circle'}
                        />
                    )}
                </div>
        },

        { headerName: t('description'), minWidth: 160,maxWidth: 200,
            cellRenderer:parameters=>
                <>{parameters.data.description === null || parameters.data.description === ''?'-':parameters.data.description}</>
        },
        { headerName: t('settings'), maxWidth: 120,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetTransport(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteProductTransport(params.data.id)}
                    />
                </div>
            }

        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({IsActive:null,IsInActive:null,search:'',IsApiAmount:null,IsFixedAmount:null});

    const SwitchFilters = [
        {name:'IsActive',lable:t("isActive"),value:filter.IsActive},
        {name:'IsInActive',lable:t("isNotActive"),value:filter.IsInActive},
        {name:'IsApiAmount',lable:t("IsApiAmount"),value:filter.IsApiAmount},
        {name:'IsFixedAmount',lable:t("IsFixedAmount"),value:filter.IsFixedAmount},
    ]

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,IsActive: null,IsInActive: null,IsApiAmount: null,IsFixedAmount: null}));
        setSearch('');
    }




    useEffect(() => {

        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.IsActive,filter.IsInActive,filter.IsApiAmount,filter.IsFixedAmount]);

    const [rowData, setRowData] = useState([]);



    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/ProductTransports`, {PageNumber: pagination.currentPage, PageSize:pagination.pageSize,title: filter.search,IsFixedAmount:filter.IsFixedAmount,IsApiAmount:filter.IsApiAmount,IsActive:filter.IsActive,IsInActive:filter.IsInActive}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                hideSpinner();
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };




    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        SwitchFilters,
    }




}