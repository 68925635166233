import React, {useEffect, useMemo, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {useNavigate} from "react-router-dom";
import { handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import queryString from "query-string";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {convertPackage, createPackageFormData} from "./PackageService";
import {packageSchema} from "./packageSchema";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import Select from "react-select";
import {GridTable} from "../../Commans/Grid/GridTable";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";



import {ListFilter} from "../../Commans/Grid/ListFilter";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../Services/Globals/validate";
import {Export} from "../../Commans/Grid/Export";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import { GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {useCustomPackages} from "./useCustomPackages";
import {useCustomePackageGrid} from "./useCustomePackageGrid";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {ModalSubmitButton} from "../../Commans/UIParts/ModalSubmitButton";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";

export const HandlePackageAdd = () => {

  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [modal, setModal] = useState({ AddPackage: false });
  const [isEdit,setIsEdit] = useState(false);
  const [spinner,setSpinner] = useState(false);
  const [refresh,setRefresh] = useState(0);
  const [errors, setErrors] = useState({});
  const [initialSource,setInitialSource] = useState({packageGroups:[]})
  const [disable,setDisable] = useState(false);
  const [IsBtnLoading,setIsBtnLoading] = useState(false);



  const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
  const {handleSelectValue,resetImages,handleDiscountTo,handleValue,handleSelectFile,removeFile,handleDiscountFrom,handleNumberValue,setPackage,Package} = useCustomPackages(setErrors,setDisable);

  const showBtnLoading=()=>setIsBtnLoading(true)
  const hideBtnLoading=()=>setIsBtnLoading(false)
  const handleClosePackage=()=>{
    setModal({AddPackage: false});
    handleClearErrors();
    handleResetPackages();
    setIsEdit(false);
  }
  const handleOpenPackage=()=>{
    setModal({AddPackage: true});
    setPackage({PackageGroupId: initialSource.packageGroups[0]})
  }
  const handleResetPackages=()=>{
    setDisable(true)
    setPackage({Description: '',Id:null,Name:'',ThumbnailImage:null,BannerImage: null,BannerImagePath: null,ThumbnailImagePath: null,PackageGroupId:initialSource.packageGroups[0]});
  }
  const handleClearErrors=()=>{
    setErrors({})
  }
  const onApiError = () => {
    setSpinner(false);
  };
  const onSetErrors=(errors)=>{
    setErrors(errors)
  }
  const Refresh=(state)=>{
    setRefresh({...state});
  }
  const handleInitialSource=(packageGroups)=>{
    const packageGroupsData = convertSelectDataHandler(packageGroups);
    setInitialSource((prevOptions) => ({
      ...prevOptions,
      packageGroups: packageGroupsData
    }));
  }
  const fetchData = async ()=>{
    try {
      setSpinner(true)
      const responsePackageGroup = await GET(`/PackageGroups/Selection`,{},'IEnumerable');
      if (  responsePackageGroup.status ===200 )
      {
        handleInitialSource(responsePackageGroup.data)
        setSpinner(false)
      }
      else {
        handleApiError(responsePackageGroup,onApiError);
      }
    } catch (error) {
      onApiError();
    }
  }
  useEffect(()=>{
    fetchData()
  },[])


  const handleAddPackageSubmit =async (e)=> {
    e.preventDefault();
    const isValid = validate(packageSchema,Package,onSetErrors);
    if (!isValid) {
      return;
    }
    if(isEdit){
      try {
        showBtnLoading()
        const convertedPackages = convertPackage(Package);
        const formData = createPackageFormData(convertedPackages);
        const response = await PUT('/Packages',formData,true);
        if (response.status === 200) {
          hideBtnLoading();
          Refresh(response.status)
          handleClosePackage();
          handleSuccessMessage(t("Yourpackagesuccessfullyedited"));
        } else {
          handleApiError(response,hideBtnLoading);
        }
      }catch (error) {
        hideBtnLoading()
      }
    }
    else{
      try {
        showBtnLoading()
        const convertedPackages = convertPackage(Package);
        const formData = createPackageFormData(convertedPackages);
        const response =await POST('/Packages',formData,true);
        if (response.status === 200) {
          hideBtnLoading();
          handleClosePackage();
          Refresh(response.status)
          handleSuccessMessage(t("Yourpackagehasregistered"));
        } else {
          handleApiError(response,hideBtnLoading);
        }
      }
      catch (error) {
        hideBtnLoading();
      }
    }
  }

  const {switchData,handleRemovePackage,handleEditPackage,data,handleSearch,search,setSearch,rowData,columnDefs,autoGroupColumnDef,defaultColDef,filter,getRowHeight,handleCurrentPage,handleNextPage,handlePreviousPage,currentPage,handlePageSizeCount,setFilter,resetFilters,filterValueHandler} = useCustomePackageGrid(setDisable,setSpinner,refresh,onApiError,initialSource,setPackage,setModal,setIsEdit,Refresh)

  return(
      <>
        <div className="space-y-5 relative">
          {
            spinner?
                <div className=" huploader_loading" >
                  <div className="relative">
                    <LoadingHarmony/>
                  </div>
                </div>
                :
                null
          }
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
            <ListSearch label={null} icon={null}>
              <div class="space-y-2 ">
                <label className="text-xs dark:text-gray-300">{t("BasedOnPackageName")}</label>
                <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" />
              </div>
              <ButtonSearchList
                  onClickSearch={()=>handleSearch()}
                  onClickReset={()=>resetFilters()}
              />
            </ListSearch>
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>
                            <ButtonPlus
                                onClick={() => handleOpenPackage()}
                                tooltipTitle={t("CreateAPackage")}
                                shortcutClass={'hbtn-modal'}
                            />
                          </>
                        }
                        childrenTwo={
                          <>
                            <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler}/>
                          </>
                        }
            />

          </div>


          <div className={"space-y-2 H-body-ag-grid"} >

            <GridTable
                columnDefs={columnDefs}
                rowSelection={GridSetting.rowSelection}
                rowData={rowData}
                style={GridSetting.gridStyle}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                pagination={GridSetting.pagination}
                enableRtl={GridSetting.enableRtl}
                domLayout={GridSetting.domLayout}
                paginationPageSize={GridSetting.pageSize}
                getRowHeight={getRowHeight}
                localeText={AgGridEnglish}
            />
            <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
          </div>


          <Export filters={filter} apiUrl={'/Packages/PackageListExport'}/>


        </div>
        <ToastContainer />

        <FantasyModal openModal={modal.AddPackage}  closeModal={handleClosePackage} title={t("CreateAPackage")}>

          <form method={'post'} onSubmit={handleAddPackageSubmit} >
            <div className="space-y-5">
              <div className={'grid grid-cols-2 gap-3'}>
                <div class=" space-y-2">
                  <div className="flex justify-between">
                    <label className="text-xs dark:text-gray-300">
                      {t("PackageName")}
                     <RequiredIcon/>
                    </label>
                    <DisplayError message={errors.Name}/>
                  </div>
                  <InputText   name={'Name'} onInput={(e)=>handleValue(e)} value={Package.Name} type={"text"} className={" input w-full"} />
                </div>
                <div className="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("Packagerole")}</label>
                  <Select
                      options={initialSource.packageGroups ?? []}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'PackageGroupId')}
                      placeholder={t("selection")}
                      value={Package.PackageGroupId}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className="col-span-2">
                  <InputText name={'Description'} value={Package.Description} onInput={(e)=>handleValue(e)} placeholder={t('description')} type={"text"} className={"input w-full "} />
                </div>
                <div class="space-y-1">
                  <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                    <SettingButton
                        tooltipTitle={t("WatermarkText")}
                        onClick={()=>handleSetting('GeneralSetting')}
                    />
                    {t("Icon")}
                  </label>
                  <div className="w-full h-36 ">
                    <UploadImage isEdit={isEdit}  removeFile={removeFile} removeName={'ThumbnailImagePath'} accept={'image/*'} OnValueSelection={handleSelectFile} value={Package.ThumbnailImage ? Package.ThumbnailImage :null} name={'ThumbnailImage'} />
                  </div>
                </div>
                <div class="space-y-1">
                  <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                    <SettingButton
                        tooltipTitle={t("WatermarkText")}
                        onClick={()=>handleSetting('GeneralSetting')}
                    />

                    {t("Banner")}
                  </label>
                  <div className="w-full h-36 ">
                    <UploadImage isEdit={isEdit} removeFile={removeFile} removeName={'BannerImagePath'} accept={'image/*'} resetImages={resetImages} OnValueSelection={handleSelectFile} value={Package.BannerImage ? Package.BannerImage:null} name={'BannerImage'} />
                  </div>
                </div>
                <div className={"flex justify-start "}>
                  <InputSwitch value={Package.StateShow}  OnValueSelection={handleSelectValue} lable={t("DisplayStatus")} name={'StateShow'}/>
                </div>
              </div>
              <ModalSubmitButton
               IsBtnLoading={IsBtnLoading}

              />

            </div>
          </form>

        </FantasyModal>


        <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
          <div>
            {
              setting === 'GeneralSetting'?
                  <HandleGeneralSetting/>:null
            }
          </div>
        </FantasyModal>

      </>



  )
}
