


export const handleGetJsonElementFromServer = (
    formItems,
    calendarLanguagesData,
    calendarTypesData,
    calendarViewsData,
    sizeParameters
) => {
    return formItems.map(item => {
        const typeMap = {
            0: 'TextBox', 1: 'TextArea', 2: 'Numeric', 3: 'MultiSelect',
            4: 'Select', 5: 'Radio', 6: 'CheckBox', 7: 'SelectionBreak',
            8: 'File', 9: 'Location', 10: 'Email', 11: 'Phone',
            12: 'Image', 13: 'Video', 14: 'Audio', 15: 'Price',
            16: 'Slider', 17: 'Date', 18: 'Time', 19: 'DateTime',
            20: 'Url', 21: 'Color', 22: 'Password',
        };

        const typeName = typeMap[item.type] || '';
        if (!typeName) {
            console.error(`Unknown type for item: ${item.type}`);
            return null; // Skip unknown types
        }

        const exclusiveProperties = handleGetExclusiveProperties(typeName);

        const retrievedSize = sizeParameters?.find(sizeParam => sizeParam.value === item.size) || exclusiveProperties.size;
        const mappedElement = {
            type: item.type,
            typeName: typeName,
            formItemId: item.formItemId,
            data: item.defaultValue,
            properties: {
                ...exclusiveProperties,
                max: item.max ?? exclusiveProperties.max,
                min: item.min ?? exclusiveProperties.min,
                name: item.name ?? exclusiveProperties.name,
                caption: item.caption ?? exclusiveProperties.caption,
                description: item.description ?? exclusiveProperties.description,
                className: item.className ?? exclusiveProperties.className,
                defaultValue: item.defaultValue ?? exclusiveProperties.defaultValue,
                size: retrievedSize,
                order: item.order ?? exclusiveProperties.order,
                isReadOnly: item.isReadOnly ?? exclusiveProperties.isReadOnly,
                isRequired: item.isRequired ?? exclusiveProperties.isRequired,
                id: item.id || exclusiveProperties.id,
            }
        };

        switch (typeName) {
            case 'Select':
            case 'Radio':
                mappedElement.properties.items = (item.items || exclusiveProperties.items).map(i => ({
                    label: i.key || i.label || '',
                    value: i.value || '',
                    parentId: i.parentId || null,
                    order: i.order || 1
                }));

                if (item.defaultValue && item.defaultValue.value !== undefined) {
                    const foundItem = item.items?.find(x => x.value === item.defaultValue.value) || item.defaultValue;
                    const transformedItem = {
                        label: foundItem.key,
                        value: foundItem.value,
                        parentId: foundItem.parentId,
                        order: foundItem.order
                    };
                    mappedElement.properties.defaultValue = { value: transformedItem };
                    mappedElement.data = { value: transformedItem };
                }
                break;

            case 'MultiSelect':
            case 'CheckBox':
                mappedElement.properties.items = (item.items || exclusiveProperties.items).map(i => ({
                    label: i.key || i.label || '',
                    value: i.value || '',
                    parentId: i.parentId || null,
                    order: i.order || 1
                }));

                if (Array.isArray(item.defaultValue?.items)) {
                    const transformedItems = item.defaultValue.items.map(value => {
                        const foundItem = item.items.find(i => i.value === value);
                        return {
                            label: foundItem?.key || value,
                            value: value,
                            parentId: foundItem?.parentId || null,
                            order: foundItem?.order || 1
                        };
                    });

                    mappedElement.properties.defaultValue = { value: transformedItems };
                    mappedElement.data = { items: transformedItems};
                } else {
                    mappedElement.properties.defaultValue = exclusiveProperties.defaultValue;
                }
                break;

            case 'File':
            case 'Image':
            case 'Video':
            case 'Audio':
                mappedElement.properties.extensions = item.extensions || exclusiveProperties.extensions;
                mappedElement.properties.folderSource = item.folderSource || exclusiveProperties.folderSource;
                mappedElement.data = {value:null}
                break;

            case 'Location':
                mappedElement.properties.stateProvinceId = item.stateProvinceId ?? exclusiveProperties.stateProvinceId;
                mappedElement.properties.countryId = item.countryId ?? exclusiveProperties.countryId;
                mappedElement.properties.cityId = item.cityId ?? exclusiveProperties.cityId;
                mappedElement.properties.address = item.address ?? exclusiveProperties.address;
                mappedElement.data.countries = []
                mappedElement.data.states = []
                mappedElement.data.cities = []
                break;

            case 'Price':
                mappedElement.properties.mask = item.regex ?? exclusiveProperties.regex;
                break;

            case 'Email':
            case 'Url':
            case 'Phone':
                mappedElement.properties.regex = item.regex ?? exclusiveProperties.regex;
                mappedElement.preNumbers = item.preNumbers;
                break;

            case 'Date':
            case 'Time':
            case 'DateTime':
                mappedElement.properties.calendarView = calendarViewsData?.find(x => x.value === item.calendarView) || exclusiveProperties.calendarView;
                mappedElement.properties.calendarType = calendarTypesData?.find(x => x.value === item.calendarType) || exclusiveProperties.calendarType;
                mappedElement.properties.calendarLanguage = calendarLanguagesData?.find(x => x.value === item.calendarLanguage) || exclusiveProperties.calendarLanguage;
                mappedElement.properties.format = item.format ?? exclusiveProperties.format;
                break;

            default:
                break;
        }

        return mappedElement;
    }).filter(Boolean); // Filter out nulls for unknown types
};






//version1
// export const handleGetJsonFromElementToAnswer = (
//     formItems,
//     calendarLanguagesData,
//     calendarTypesData,
//     calendarViewsData,
//     sizeParameters
// ) => {
//     return formItems.map(item => {
//         const typeMap = {
//             0: 'TextBox', 1: 'TextArea', 2: 'Numeric', 3: 'MultiSelect',
//             4: 'Select', 5: 'Radio', 6: 'CheckBox', 7: 'SelectionBreak',
//             8: 'File', 9: 'Location', 10: 'Email', 11: 'Phone',
//             12: 'Image', 13: 'Video', 14: 'Audio', 15: 'Price',
//             16: 'Slider', 17: 'Date', 18: 'Time', 19: 'DateTime',
//             20: 'Url', 21: 'Color', 22: 'Password',
//         };
//
//         const typeName = typeMap[item.type] || '';
//         if (!typeName) {
//             console.error(`Unknown type for item: ${item.type}`);
//             return null; // Skip unknown types
//         }
//
//         const exclusiveProperties = handleGetExclusiveProperties(typeName);
//
//         const retrievedSize = sizeParameters?.find(sizeParam => sizeParam.value === item.size) || exclusiveProperties.size;
//         const mappedElement = {
//             type: item.type,
//             typeName: typeName,
//             formItemId: item.formItemId,
//             data: item.defaultValue,
//             properties: {
//                 ...exclusiveProperties,
//                 max: item.max ?? exclusiveProperties.max,
//                 min: item.min ?? exclusiveProperties.min,
//                 name: item.name ?? exclusiveProperties.name,
//                 caption: item.caption ?? exclusiveProperties.caption,
//                 description: item.description ?? exclusiveProperties.description,
//                 className: item.className ?? exclusiveProperties.className,
//                 defaultValue: item.defaultValue ?? exclusiveProperties.defaultValue,
//                 size: retrievedSize,
//                 order: item.order ?? exclusiveProperties.order,
//                 isReadOnly: item.isReadOnly ?? exclusiveProperties.isReadOnly,
//                 isRequired: item.isRequired ?? exclusiveProperties.isRequired,
//                 id: item.id || exclusiveProperties.id,
//             }
//         };
//
//         switch (typeName) {
//             case 'Select':
//             case 'Radio':
//                 mappedElement.properties.items = (item.items || exclusiveProperties.items).map(i => ({
//                     label: i.key || i.label || '',
//                     value: i.value || '',
//                     parentId: i.parentId || null,
//                     order: i.order || 1
//                 }));
//
//                 if (item.defaultValue && item.defaultValue.value !== undefined) {
//                     const foundItem = item.items?.find(x => x.value === item.defaultValue.value) || item.defaultValue;
//                     const transformedItem = {
//                         label: foundItem.key,
//                         value: foundItem.value,
//                         parentId: foundItem.parentId,
//                         order: foundItem.order
//                     };
//                     mappedElement.properties.defaultValue = { value: transformedItem };
//                     mappedElement.data = { value: transformedItem };
//                 }
//                 break;
//
//             case 'MultiSelect':
//             case 'CheckBox':
//                 mappedElement.properties.items = (item.items || exclusiveProperties.items).map(i => ({
//                     label: i.key || i.label || '',
//                     value: i.value || '',
//                     parentId: i.parentId || null,
//                     order: i.order || 1
//                 }));
//
//                 if (Array.isArray(item.defaultValue?.items)) {
//                     const transformedItems = item.defaultValue.items.map(value => {
//                         const foundItem = item.items.find(i => i.value === value);
//                         return {
//                             label: foundItem?.key || value,
//                             value: value,
//                             parentId: foundItem?.parentId || null,
//                             order: foundItem?.order || 1
//                         };
//                     });
//
//                     mappedElement.properties.defaultValue = { value: transformedItems };
//                     mappedElement.data = { items: transformedItems};
//                 } else {
//                     mappedElement.properties.defaultValue = exclusiveProperties.defaultValue;
//                 }
//                 break;
//
//             case 'File':
//             case 'Image':
//             case 'Video':
//             case 'Audio':
//                 mappedElement.properties.extensions = item.extensions || exclusiveProperties.extensions;
//                 mappedElement.properties.folderSource = item.folderSource || exclusiveProperties.folderSource;
//                 mappedElement.data = {file:null}
//                 break;
//
//             case 'Location':
//                 mappedElement.properties.stateProvinceId = item.stateProvinceId ?? exclusiveProperties.stateProvinceId;
//                 mappedElement.properties.countryId = item.countryId ?? exclusiveProperties.countryId;
//                 mappedElement.properties.cityId = item.cityId ?? exclusiveProperties.cityId;
//                 mappedElement.properties.address = item.address ?? exclusiveProperties.address;
//                 mappedElement.data.countries = []
//                 mappedElement.data.states = []
//                 mappedElement.data.cities = []
//                 break;
//
//             case 'Price':
//                 mappedElement.properties.mask = item.regex ?? exclusiveProperties.regex;
//                 break;
//
//             case 'Email':
//             case 'Url':
//             case 'Phone':
//                 mappedElement.properties.regex = item.regex ?? exclusiveProperties.regex;
//                 mappedElement.preNumbers = item.preNumbers;
//                 break;
//
//             case 'Date':
//             case 'Time':
//             case 'DateTime':
//                 mappedElement.properties.calendarView = calendarViewsData?.find(x => x.value === item.calendarView) || exclusiveProperties.calendarView;
//                 mappedElement.properties.calendarType = calendarTypesData?.find(x => x.value === item.calendarType) || exclusiveProperties.calendarType;
//                 mappedElement.properties.calendarLanguage = calendarLanguagesData?.find(x => x.value === item.calendarLanguage) || exclusiveProperties.calendarLanguage;
//                 mappedElement.properties.format = item.format ?? exclusiveProperties.format;
//                 break;
//
//             default:
//                 break;
//         }
//
//         return mappedElement;
//     }).filter(Boolean); // Filter out nulls for unknown types
// };
//


//version2
export const handleGetJsonFromElementToAnswer = (
    formItems,
    calendarLanguagesData,
    calendarTypesData,
    calendarViewsData,
    sizeParameters,
    formData // New parameter for form data
) => {
    // Create a map of formData by formItemId for quick lookups
    const formDataMap = new Map(
        (formData || []).map(data => [
            data.formItemId,
            Object.fromEntries(
                Object.entries(data.value).map(([key, value]) => [key.toLowerCase(), value])
            )
        ])
    );

    return formItems.map(item => {
        const typeMap = {
            0: 'TextBox', 1: 'TextArea', 2: 'Numeric', 3: 'MultiSelect',
            4: 'Select', 5: 'Radio', 6: 'CheckBox', 7: 'SelectionBreak',
            8: 'File', 9: 'Location', 10: 'Email', 11: 'Phone',
            12: 'Image', 13: 'Video', 14: 'Audio', 15: 'Price',
            16: 'Slider', 17: 'Date', 18: 'Time', 19: 'DateTime',
            20: 'Url', 21: 'Color', 22: 'Password',
        };

        const typeName = typeMap[item.type] || '';
        if (!typeName) {
            console.error(`Unknown type for item: ${item.type}`);
            return null; // Skip unknown types
        }

        const exclusiveProperties = handleGetExclusiveProperties(typeName);
        const retrievedSize = sizeParameters?.find(sizeParam => sizeParam.value === item.size) || exclusiveProperties.size;

        // Initialize mappedElement
        const mappedElement = {
            type: item.type,
            typeName: typeName,
            formItemId: item.formItemId,
             data: item.defaultValue, // Keep defaultValue as is
            properties: {
                ...exclusiveProperties,
                max: item.max ?? exclusiveProperties.max,
                min: item.min ?? exclusiveProperties.min,
                name: item.name ?? exclusiveProperties.name,
                caption: item.caption ?? exclusiveProperties.caption,
                description: item.description ?? exclusiveProperties.description,
                className: item.className ?? exclusiveProperties.className,
               // defaultValue: item.defaultValue ?? exclusiveProperties.defaultValue,
                size: retrievedSize,
                order: item.order ?? exclusiveProperties.order,
                isReadOnly: item.isReadOnly ?? exclusiveProperties.isReadOnly,
                isRequired: item.isRequired ?? exclusiveProperties.isRequired,
                id: item.id || exclusiveProperties.id,
            }
        };

        // Update data from formData if available


        switch (typeName) {
            case 'Select':
            case 'Radio':
                mappedElement.properties.items = (item.items || exclusiveProperties.items).map(i => ({
                    label: i.key || i.label || '',
                    value: i.value || '',
                    parentId: i.parentId || null,
                    order: i.order || 1
                }));
                    const selectAndRadio = formDataMap.get(item.formItemId);
                    const foundItem = item.items?.find(x => x.value === selectAndRadio.value) || item.defaultValue;
                    const transformedItem = {
                        label: foundItem.key,
                        value: foundItem.value,
                        parentId: foundItem.parentId,
                        order: foundItem.order
                    };
                    mappedElement.data = {value:transformedItem}

                break;

            case 'MultiSelect':
            case 'CheckBox':
                const multiSelectAndCheckbox = formDataMap.get(item.formItemId);
                mappedElement.properties.items = (item.items || exclusiveProperties.items).map(i => ({
                    label: i.key || i.label || '',
                    value: i.value || '',
                    parentId: i.parentId || null,
                    order: i.order || 1
                }));
                    const transformedItems = multiSelectAndCheckbox.items.map(value => {
                        const foundItem = item.items.find(i => i.value === value);
                        return {
                            label: foundItem?.key || value,
                            value: value,
                            parentId: foundItem?.parentId || null,
                            order: foundItem?.order || 1
                        };
                    });
                    mappedElement.data = { items: transformedItems };
                break;

            case 'File':
            case 'Image':
            case 'Video':
            case 'Audio':
                const fileData = formDataMap.get(item.formItemId);
                mappedElement.properties.extensions = item.extensions || exclusiveProperties.extensions;
                mappedElement.properties.folderSource = item.folderSource || exclusiveProperties.folderSource;
                mappedElement.data = fileData;
                break;

            case 'Location':
                const location = formDataMap.get(item.formItemId);
                const locMap = {
                    countryId:location.countryid,
                    stateProvinceId: location.stateprovinceid,
                    cityId:location.cityid,
                    address:location.address
                }
                mappedElement.data = locMap
                mappedElement.properties.defaultValue = {
                    stateProvinceId:locMap.stateProvinceId,
                    countryId:locMap.countryId,
                    cityId:locMap.cityId,
                    address:locMap.address
                }
                mappedElement.properties.stateProvinceId = locMap.stateProvinceId ?? exclusiveProperties.stateProvinceId;
                mappedElement.properties.countryId = locMap.countryId?? exclusiveProperties.countryId;
                mappedElement.properties.cityId = locMap.cityId ?? exclusiveProperties.cityId;
                mappedElement.properties.address = locMap.address ?? exclusiveProperties.address;
                mappedElement.data.countries = [];
                mappedElement.data.states = [];
                mappedElement.data.cities = [];

                break;

            case 'Price':
                const priceFormData = formDataMap.get(item.formItemId);
                mappedElement.properties.regex = item.regex ?? exclusiveProperties.regex;
                mappedElement.data = priceFormData
                break;
            case 'TextBox':
                const TextBox = formDataMap.get(item.formItemId);
                mappedElement.data = TextBox;
                break;
            case 'Email':
                const emailBox = formDataMap.get(item.formItemId);
                mappedElement.data = emailBox;
                break;
            case 'Url':
                const urlBox = formDataMap.get(item.formItemId);
                mappedElement.data = urlBox;
                break;
            case 'Phone':
                const phoneData = formDataMap.get(item.formItemId);
                const data = {
                    preNumber:phoneData.prenumber,
                    value:phoneData.value
                }
                mappedElement.data = data;
                mappedElement.properties.regex = item.regex ?? exclusiveProperties.regex;
                mappedElement.preNumbers = item.preNumbers;
                break;
            case 'Date':
            case 'Time':
            case 'DateTime':
                const dateTime = formDataMap.get(item.formItemId);
                mappedElement.data = dateTime;
                mappedElement.properties.calendarView = calendarViewsData?.find(x => x.value === item.calendarView) || exclusiveProperties.calendarView;
                mappedElement.properties.calendarType = calendarTypesData?.find(x => x.value === item.calendarType) || exclusiveProperties.calendarType;
                mappedElement.properties.calendarLanguage = calendarLanguagesData?.find(x => x.value === item.calendarLanguage) || exclusiveProperties.calendarLanguage;
                mappedElement.properties.format = item.format ?? exclusiveProperties.format;
                break;

            default:
                break;
        }

        return mappedElement;
    }).filter(Boolean); // Filter out nulls for unknown types
};









const handleGetExclusiveProperties = (typeName) => {
    switch (typeName) {
        case 'TextBox':
            return {
                min: 0,
                max: 0,
                order: 0,
                name: '',
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: '',
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'TextArea':
            return {
                min: 0,
                max: 0,
                order: 0,
                name: '',
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: '',
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Numeric':
            return {
                min: 0,
                max: 0,
                order: 0,
                name: '',
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: 0,
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'MultiSelect':
        case 'Select':
        case 'CheckBox':
        case 'Radio':
            return {
                order: 0,
                name: '',
                items: [{ label: null, value: null, parentId: null, order: 1 }],
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: null,
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'SelectionBreak':
            return {
                isReadOnly: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: null,
                size: null,
                id: ''
            };
        case 'File':
        case 'Image':
        case 'Video':
        case 'Audio':
            return {
                isReadOnly: false,
                extensions: '',
                folderSource: '',
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: null,
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Location':
            return {
                order: 0,
                isReadOnly: false,
                latitude: 0,
                longitude: 0,
                isShowMap: false,
                countries: [],
                states: [],
                cities: [],
                stateProvinceId: null,
                countryId: null,
                cityId: null,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: null,
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Email':
        case 'Phone':
        case 'Url':
            return {
                order: 0,
                isReadOnly: false,
                regex: '',
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: '',
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Price':
            return {
                order: 0,
                isReadOnly: false,
                mask: '',
                max: 0,
                min: 0,
                currency: null,
                useGrouping: false,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: 0,
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        case 'Slider':
            return {
                order: 0,
                isReadOnly: false,
                max: 0,
                min: 0,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: null,
                size: null,
                id: ''
            };
        case 'Date':
        case 'Time':
        case 'DateTime':
            return {
                order: 0,
                calendarView: null,
                calendarType: null,
                calendarLanguage: null,
                isReadOnly: false,
                max: 0,
                min: 0,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: null,
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: '',
                name: ''
            };
        case 'Color':
        case 'Password':
            return {
                order: 0,
                showPassword:false,
                isReadOnly: false,
                max: 0,
                min: 0,
                isRequired: false,
                caption: 'Title',
                description: '',
                className: '',
                defaultValue: null,
                size: { value: 0, label: 'Small', className: 'harmony-col-span-1' },
                id: ''
            };
        default:
            return {};
    }
};



// export const handleCreateAnswerItems = (formId, elements) => {
//     const data = {
//         formId: formId,
//         formItemAnswers: elements.map((item) => {
//             let formItem = {};
//             switch (item.type) {
//                 case 0: // TextBox
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 1: // TextArea
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 2: // Numeric
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 3: // MultiSelect
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 4: // Select
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 5: // Radio
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 6: // CheckBox
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 7: // SelectionBreak
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 8: // File
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 9: // Location
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 10: // Email
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 11: // Phone
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 12: // Image
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 13: // Video
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//
//                 case 14: // Audio
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//                 case 15: //price
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//                 case 16: //Slider Range
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//                 case 17: // Date
//
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//                 case 18: //Time
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//                 case 19: //DateTime
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//                 case 20:  //Url
//                     formItem = {
//
//                         data:item.data
//                     };
//                     break;
//                 case 21 : //Color
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//                 case 22: //Password
//                     formItem = {
//                         data:item.data
//                     };
//                     break;
//             }
//
//             return {
//                 formItemId: item.formItemId,
//             };
//         }),
//     };
//     return data;
// };
//

export const handleCreateAnswerItems = (formId, elements) => {
    const data = {
        formId:formId,
        formItemAnswers: elements.map((item) => {

            let formItem = {};

            switch (item.type) {
                case 0: // TextBox
                    formItem = {
                        data: item.data
                    };
                    break;
                case 1: // TextArea
                    formItem = {
                        data: item.data
                    };
                    break;
                case 2: // Numeric
                    formItem = {
                        data: item.data
                    };
                    break;
                case 3: // MultiSelect
                    formItem = {
                        data:{value: item.data.items ? item.data.items.map((x) => x.value) : [item.data.value]}
                    };
                    break;
                case 4: // Select
                    formItem = {
                        data:{value: item.data.value.value }
                    };
                    break;
                case 5: // Radio
                    formItem = {
                        data:{value: item.data.value.value }
                    };
                    break;
                case 6: // CheckBox
                    formItem = {
                        data:{value: item.data.items ? item.data.items.map((x) => x.value) : [item.data.value]}
                    };
                    break;
                case 9: // Location
                    formItem = {
                        data:{
                            countryId: item.data.countryId,
                            stateProvinceId:item.data.stateProvinceId,
                            cityId:item.data.cityId,
                            address:item.data.address
                        }
                    };
                    break;
                case 10: // Email
                    formItem = {
                        data: item.data
                    };
                    break;
                case 11: // Phone
                    formItem = {
                        data: item.data
                    };
                    break;
                case 8: // File
                case 12: // Image
                case 13: // Video
                case 14: // Audio
                    formItem = {
                        data: item.data
                    };
                    break;
                case 15: // Price
                    formItem = {
                        data: item.data
                    };

                    break;
                case 16: // Slider Range
                    formItem = {
                        data: item.data
                    };
                    break;
                case 17: // Date
                    formItem = {
                        data: item.data
                    };
                    break;
                case 18: // Time
                    formItem = {
                        data: item.data
                    };
                    break;
                case 19: // DateTime
                    formItem = {
                        data: item.data
                    };
                    break;
                case 20: // Url
                    formItem = {
                        data: item.data
                    };
                    break;
                case 21: // Color
                    formItem = {
                        data: item.data
                    };
                    break;
                case 22: // Password
                    formItem = {
                        data: item.data
                    };
                    break;
                default:
                    formItem = {
                        data: {}
                    };
                    break;
            }
            // Return the formItemAnswer with both formItemId and data
            return {
                formItemId: item.formItemId,
                data: formItem.data
            };
        }),
    };
    return data;
};

export const hasFilesInElements = (elements) => {
    const fileTypes = [8, 12, 13, 14];
    return elements.some(item =>
        fileTypes.includes(item.type) && item.data && item.data.value instanceof File
    );
};

export const updateElementsWithFileData = (elements,formItemAnswers) => {
    const updatedElements = elements.map((element) => {
        const answer = formItemAnswers.find((ans) => ans.formItemId === element.formItemId);
        console.log(answer)
        if (answer) {
            return {
                ...element,
                data: { value: answer.file },
            };
        }
        return element;
    });
   return updatedElements
};

export const handleCreateFileAnswerItems = (formId, elements) => {
    // Create a new FormData object
    const formData = new FormData();

    // Append the formId
    formData.append('formId', formId);

    // Filter and map file-related form items
    elements
        .filter(item => [8, 12, 13, 14].includes(item.type)) // Filter for file-related types
        .forEach((item, index) => {
            if (item.data && item.data.value instanceof File) {
                // Append the formItemId and file as part of the FormItemAnswers array
                formData.append(`formItemAnswers[${index}].formItemId`, item.formItemId);
                formData.append(`formItemAnswers[${index}].file`, item.data.value);
            }
        });

    return formData;
};
