import React from "react";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";

export const HomeAddres=({user})=>{
    const { t } = useTranslation();
    const handleCopyToClipboard = async (text) => {
        if (text) {
            try {
                handleSuccessMessage(t("Yourtexthasbeencopied"))
                await navigator.clipboard.writeText(text);
            }
            catch (error) {
                console.log(error)
            }
        }
    };
    return (
        <>
            <div className={"post-input p-2 post-input-design dark:bg-themeDarkPostInput space-y-3"}>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 flex gap-2 items-center"}>
                    <HPrimeIconSmall
                        icon={"pi-map-marker"}
                        iconClass={"dark:text-gray-300"}
                    />
                    <p className={"text-xs dark:text-gray-300"}>{t("HomeAddress")}</p>
                </div>
                <div className={"grid grid-cols-1 md:grid-cols-3 gap-2"}>
                    <div className={"space-y-1"}>
                        <label className={"text-xs dark:text-gray-300"}>{t("country")}</label>
                        <InputText className={"input  w-full "} value={user?.country ?? '-'} disabled={true} />
                    </div>
                    <div className={"space-y-1"}>
                        <label className={"text-xs dark:text-gray-300"}>{t("province")}</label>
                        <InputText className={"input  w-full "} value={user?.stateProvince ?? '-'} disabled={true} />
                    </div>
                    <div className={"space-y-1"}>
                        <label className={"text-xs dark:text-gray-300"}>{t("city")}</label>
                        <InputText className={"input  w-full "} value={user?.city ?? '-'} disabled={true} />
                    </div>
                </div>
                <div className={"max_height10 scrollbar space-y-2 p-2"}>
                    <div className={"flex gap-1 items-center "}>
                        <div className={"min-w-16"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("HomeAddress")}</p>
                        </div>
                        <div className={"flex-1"}>
                            <InputTextarea rows={2} disabled={true} value={user?.address ?? '-'} className={"input text-xs"} />
                        </div>
                        <div className={"flex items-center flex-shrink-0"}>
                            <button onClick={()=>handleCopyToClipboard(user?.address)} className={"button bg-themeSuccess"}>
                                <HPrimeIconSmall icon={"pi-copy"} iconClass={"text-white"} />
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}