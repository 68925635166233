import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";

export const usePatternGrid=(showSpinner,hideSpinner,refresh,Refresh,initialAccountId)=>{

    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const [rowData, setRowData] = useState([]);


    const [visible, setVisible] = useState(false);
    const [message,setMessage] = useState('');

    const handleShowMessage =(visible,message)=>{
        setVisible(visible);
        setMessage(message);
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Title"),maxWidth: 150,
            cellRenderer:parameters=>
                <>
                    {parameters.data.title}
                </>
        },
        { headerName: t("CreationDate"),maxWidth: 150,
            cellRenderer:parameters=>
                <>
                    {parameters.data.insertDate}
                </>
        },

        { headerName: t("DefaultText"),
            cellRenderer:parameters=>
                <div onClick={()=>handleShowMessage(true,parameters.data.body)} className={"cursor-pointer"}>
                    <p className={"line-clamp-1 underline hover:text-themeInformation dark:hover:text-themeDarkInformation"} >
                        {parameters.data.body}
                    </p>
                </div>
        },

        { headerName: t("Status"), maxWidth: 210,
            cellRenderer:parameters=>
                <>
                    {parameters.data.bodyStatus}
                </>
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit

                    />
                    <GridButtonDelete

                    />

                </div>
            }
        },

    ];


    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize: GridSetting.pageSize || 10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',operator:'',operatorValue:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',operator:'',operatorValue: null}));
        setSearch('');
    }

    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data.value,operatorValue: data}));
    }




    useEffect(() => {

        if (initialAccountId) {
            OnGridReady(initialAccountId);
        }
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.operator,initialAccountId]);




    const OnGridReady = async (initialAccountId) => {
        try {
            showSpinner();
            const accountIdToUse = filter.operator || initialAccountId; // Use filter.operator if it has data, otherwise use accountId
            const response =  await GET(`/Messaging/${accountIdToUse}/Patterns`, {PageNumber: pagination.currentPage, PageSize:pagination.pageSize, Search: filter.search}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPreviousPage: response.hasPreviousPage,
                    page: response.page,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount
                }));
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
                hideSpinner()
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };


    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleShowMessage,
        visible,
        message,
        setVisible,
        handleSelectFilter
    }



}