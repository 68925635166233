import React, { useEffect, useState } from "react";

import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { handleFailureMessage } from "../../../../../Services/Globals/Errors/NotificationService";
import Select from "react-select";

import { TagsInput } from "react-tag-input-component";
import { FantasyModal } from "../../../../Commans/UIParts/FantasyModal";
import { handleApiError } from "../../../../../Services/Globals/Errors/handleApiError";
import { ConnectionStringBuilder } from "../../../../Commans/Globals/ConnectionStringBuilder";
import { GET } from "../../../../../Services/AxiosService/AxiosApiService";
import {regexSetting } from "../../../../../Services/Globals/Public/Regexes";
import { convertSelectDataHandler } from "../../../../CMS/Contents/ContentService";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "../../../../HIcon/ArrowLeftIcon";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const Servers = ({ handleChangeTab, values, onValueChange, toggles, OnToggleChange }) => {

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [selectedOptions,setSelectOption] = useState({ hosts: [], webservers: [], dbServers: [],adminPanels:[],mails:[],dedicateds:[],coreServers:[]});
    const ipRegex = regexSetting.Ip;
    const isValidIP = (ip) => ipRegex.test(ip);
    const handleChangeViewTab = (tabId) => {
        let errorMessage = "";
        const {DbServerIp,ConnectionString, WebServerIp, HostServerIp,AdminPanelServerIp,MailServerIp,DedicatedServerIp,CoreServerIp } = values;
        const isSqlValid = dataHidden.isDbServer && (!DbServerIp || !ConnectionString || !isValidIP(DbServerIp));
        const isWebserverValid = dataHidden.isWebserver && (!WebServerIp || !isValidIP(WebServerIp));
        const isHostValid = dataHidden.isHost && (!HostServerIp || !isValidIP(HostServerIp));
        const isAdminPanelValid = dataHidden.isAdminPanel && (!AdminPanelServerIp || !isValidIP(AdminPanelServerIp))
        const isMailServerIpValid = dataHidden.isMail && (!MailServerIp || !isValidIP(MailServerIp));
        const isDedicatedServerIpValid = dataHidden.isDedicated && (!DedicatedServerIp || !isValidIP(DedicatedServerIp));
        const isCoreServerIpValid = dataHidden.isCoreServer && (!CoreServerIp || !isValidIP(CoreServerIp));

        if (isSqlValid || isWebserverValid || isHostValid || isAdminPanelValid || isMailServerIpValid || isDedicatedServerIpValid || isCoreServerIpValid) {
            errorMessage = t("PleaseenteravalidserverIP");
            handleFailureMessage(errorMessage);
        }else {
            handleChangeTab(tabId);
        }

    };

    const GoToLastPage = (tabId) => {
        handleChangeTab(tabId);
    };

    const onApiError = () => {
        setSpinner(false);
    };

    const handleFetchData = async () => {
        try {
            setSpinner(true);
            const fetchData = async (type) => {
                const response = await GET(`/Servers/Selection?Type=${type}`, {}, "IEnumerable");
                return { status: response.status, data: convertSelectDataHandler(response.data) };
            };

            const [responseHost, responseSqlserver, responseWebserver,responseAdminPanel,responseMail,responseDedicated,responseCoreServer] = await Promise.all([
                fetchData(1),
                fetchData(0),
                fetchData(2),
                fetchData(3),
                fetchData(4),
                fetchData(5),
                fetchData(6),
            ]);

            if (responseHost.status === 200 && responseSqlserver.status === 200 && responseWebserver.status === 200 && responseAdminPanel.status === 200 && responseMail && responseDedicated.status === 200 && responseCoreServer.status === 200) {

                if(values.HostServerId === null){
                    const hostServers = responseHost.data;
                    onValueChange(hostServers[0], "HostServerId");
                }
                else{
                    const hostServers = responseHost.data;
                    const filteredHost = hostServers.find((item) => item.value === values.HostServerId.value);
                    onValueChange(filteredHost, "HostServerId");
                }
                if(values.DbServerId === null){
                    const sqlServers = responseSqlserver.data;
                    onValueChange(sqlServers[0], "DbServerId");
                }else{
                    const sqlServers = responseSqlserver.data;
                    const filteredSql = sqlServers.find((item) => item.value === values.DbServerId.value);
                    onValueChange(filteredSql, "DbServerId");

                }

                if(values.WebServerId === null){
                    const webServers = responseWebserver.data;
                    onValueChange(webServers[0], "WebServerId");
                }
                else{
                    const webServers = responseWebserver.data;
                    const filteredWebServer = webServers.find((item) => item.value === values.WebServerId.value);
                    onValueChange(filteredWebServer, "WebServerId");
                }
                if(values.AdminPanelServerId === null){
                    const adminPanels = responseAdminPanel.data;
                    onValueChange(adminPanels[0],"AdminPanelServerId");
                }
                else{
                    const adminPanels = responseAdminPanel.data;
                    const filteredAdminPanel = adminPanels.find((item) => item.value === values.AdminPanelServerId.value);
                    onValueChange(filteredAdminPanel,"AdminPanelServerId");
                }

                if(values.MailServerId === null){
                    const mails = responseMail.data;
                    onValueChange(mails[0],"MailServerId");
                }
                else{
                    const mails = responseMail.data;
                    const filteredMail = mails.find((item) => item.value === values.MailServerId.value);
                    onValueChange(filteredMail,"MailServerId");
                }
                if(values.CoreServerId === null){
                    const coreServers = responseCoreServer.data;
                    onValueChange(coreServers[0],"CoreServerId");
                }
                else{
                    const coreServers = responseCoreServer.data;
                    const filteredCoreServer = coreServers.find((item) => item.value === values.CoreServerId.value);
                    onValueChange(filteredCoreServer,"CoreServerId");
                }
                setSelectOption({ hosts: responseHost.data, dbServers: responseSqlserver.data, webservers: responseWebserver.data, adminPanels:responseAdminPanel.data,mails: responseMail.data,dedicateds:responseDedicated.data,coreServers: responseCoreServer.data});
                setSpinner(false);
            } else {
                handleApiError(responseHost, onApiError);
                handleApiError(responseSqlserver, onApiError);
                handleApiError(responseWebserver, onApiError);
                handleApiError(responseAdminPanel, onApiError);
                handleApiError(responseMail, onApiError);
                handleApiError(responseDedicated, onApiError);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        handleFetchData();
    }, []);

    const [isHostIpValid, setHostIpValid] = useState(false);
    const [isWebServerIpValid, setWebserverIpValid] = useState(false);
    const [isServerIpValid, setIsServerIpValid] = useState(false);
    const [isAdminPanelIpValid, setIsAdminPanelIpValid] = useState(false);
    const [isMailIpValid, setIsMailIpValid] = useState(false);
    const [isDedicatedIpValid, setIsDedicatedIpValid] = useState(false);
    const [IsCoreServerValidIp,setCoreServerValidIp] = useState(false);

    const [dataHidden, setDataHidden] = useState({ isHost: toggles.isHost, isWebserver: toggles.isWebserver, isDbServer: toggles.isDbServer,isAdminPanel:toggles.isAdminPanel,isMail:toggles.isMail,isDedicated:toggles.isDedicated,isCoreServer:toggles.isCoreServer,isPublic:toggles.isPublic});

    const handleHidden = (data,name) => {

        if(name ==='isPublic' && data === false){
            onValueChange(null,'HostServerId')
            onValueChange(null,'HostServerIp')
            onValueChange(null,'DbServerId')
            onValueChange(null,'DbServerIp')
            onValueChange(null,'WebServerId')
            onValueChange(null,'WebServerIp')
            onValueChange(null,'AdminPanelServerId')
            onValueChange(null,'AdminPanelServerIp')
            onValueChange(null,'MailServerId')
            onValueChange(null,'MailServerIp');
            onValueChange('', 'ConnectionString');
            onValueChange(null,'CoreServerId')
            onValueChange(null,'CoreServerIp')
            onValueChange(selectedOptions.dedicateds[0],'DedicatedServerId')
        }else if(name === 'isPublic' && data === true){
            onValueChange(selectedOptions.hosts[0],'HostServerId')
            onValueChange(selectedOptions.dbServers[0],'DbServerId')
            onValueChange(selectedOptions.webservers[0],'WebServerId')
            onValueChange(selectedOptions.adminPanels[0],'AdminPanelServerId')
            onValueChange(selectedOptions.mails[0],'MailServerId')
            onValueChange(selectedOptions.coreServers[0],'CoreServerId')
            onValueChange(null,'DedicatedServerId');
            onValueChange('', 'ConnectionString');
        }

        if(name === 'isHost' && data === true && values.HostServerId !== null){
            onValueChange(null,'HostServerId')
        }else if(name === 'isHost' && data === false && (values.HostServerIp !== null || values.HostServerIp !== '')){
            onValueChange(null,'HostServerIp');
            onValueChange(selectedOptions.hosts[0],'HostServerId')
        }
        if(name === 'isDbServer' && data === true && values.DbServerId !== null){
            onValueChange(null,'DbServerId')
        } else if(name === 'isDbServer' && data === false && (values.DbServerIp !== null || values.DbServerIp !== '')){
            onValueChange(null,'DbServerIp');
            onValueChange(selectedOptions.dbServers[0],'DbServerId')
        }

        if(name === 'isWebserver' && data === true && values.WebServerId !== null){
            onValueChange(null,'WebServerId')
        } else if(name === 'isWebserver' && data === false && (values.WebServerIp !== null || values.WebServerIp !== '')){
            onValueChange(null,'WebServerIp');
            onValueChange(selectedOptions.webservers[0],'WebServerId')
        }

        if(name === 'isAdminPanel' && data === true && values.AdminPanelServerId !== null){
            onValueChange(null,'AdminPanelServerId')
        } else if(name === 'isAdminPanel' && data === false && (values.AdminPanelServerIp !== null || values.AdminPanelServerIp !== '')){
            onValueChange(null,'AdminPanelServerIp');
            onValueChange(selectedOptions.adminPanels[0],'AdminPanelServerId')
        }

        if(name === 'isMail' && data === true && values.MailServerId !== null){
            onValueChange(null,'MailServerId')
        } else if(name === 'isMail' && data === false && (values.MailServerIp !== null || values.MailServerIp !== '')){
            onValueChange(null,'MailServerIp');
            onValueChange(selectedOptions.mails[0],'MailServerId')
        }


        if(name === 'isCoreServer' && data === true && values.CoreServerId !== null){
            onValueChange(null,'CoreServerId')
        } else if(name === 'isCoreServer' && data === false && (values.CoreServerIp !== null || values.CoreServerIp !== '')){
            onValueChange(null,'CoreServerIp');
            onValueChange(selectedOptions.coreServers[0],'CoreServerIp')
        }



        if(name === 'isDedicated' && data === true && values.DedicatedServerId !== null){
            onValueChange(null,'DedicatedServerId');
            onValueChange('', 'ConnectionString');
        } else if(name === 'isDedicated' && data === false && (values.DedicatedServerIp !== null || values.DedicatedServerIp !== '')){
            onValueChange(null,'DedicatedServerIp');
            onValueChange(null,'HostServerIp');
            onValueChange(null,'DbServerIp');
            onValueChange(null,'WebServerIp');
            onValueChange(null,'AdminPanelServerIp');
            onValueChange(null,'MailServerIp');
            onValueChange(null,'CoreServerId');
            onValueChange(null,'CoreServerIp');
            onValueChange('','ConnectionString');
            onValueChange(selectedOptions.dedicateds[0],'DedicatedServerId')
        }
        OnToggleChange(name,data);


        setHostIpValid(values.HostServerIp !== null && isValidIP(values.HostServerIp));
        setWebserverIpValid(values.WebServerIp !== null && isValidIP(values.WebServerIp));
        setIsServerIpValid(values.DbServerIp !== null && isValidIP(values.DbServerIp));
        setIsAdminPanelIpValid(values.AdminPanelServerIp !== null && isValidIP(values.AdminPanelServerIp));
        setIsMailIpValid(values.MailServerIp !== null && isValidIP(values.MailServerIp));
        setIsDedicatedIpValid(values.DedicatedServerIp !== null && isValidIP(values.DedicatedServerIp));
        setCoreServerValidIp(values.CoreServerIp !== null && isValidIP(values.CoreServerIp));
        setDataHidden((prevSite) => ({ ...prevSite, [name]: data }));
    };


    const handleValueChange = (data, name) => {
        const ipRegex = regexSetting.Ip;
        if (name.includes("Ip") && ipRegex.test(data)) {
            setHostIpValid(true);
            setWebserverIpValid(true);
            setIsServerIpValid(true);
            setIsAdminPanelIpValid(true);
            setIsMailIpValid(true);
            setIsDedicatedIpValid(true);
            setCoreServerValidIp(true);
        } else {
            setHostIpValid(false);
            setWebserverIpValid(false);
            setIsServerIpValid(false);
            setIsAdminPanelIpValid(false);
            setIsMailIpValid(false);
            setIsDedicatedIpValid(false);
            setCoreServerValidIp(false);
        }
        if (name.includes("DbServerId")) {
            onValueChange(data, name);
        }
        else if (name === "ConnectionString" && data === "") {
            onValueChange(null, name);
        }
        else{
            onValueChange(data,name);
        }

    };

    const [isOpen, setOpen] = useState(false);

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <>

            <div>
                <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                    <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                        5
                    </div>
                    <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                        <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">{t("SelectServer")}</h2>
                    </div>
                    <div>
                        <span className={"arrow_design_add_site"}>
                             <ArrowLeftIcon/>
                        </span>
                    </div>
                </div>
            </div>
            <div className={"mt-5 space-y-5 px-2"}>
                <div className={"grid grid-cols-1 lg:grid-cols-2"}>
                    <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-2 space-y-5"}>
                        <div>
                            <div class="can-toggle demo-rebrand-1 text-xs w-32">
                                <input id="pub" type="checkbox" checked={toggles.isPublic} onChange={(e)=>handleHidden(e.target.checked,'isPublic')} />
                                <label htmlFor="pub">
                                    <div class="can-toggle__switch" data-checked={t("Public")} data-unchecked={t("Private")}></div>
                                </label>
                            </div>
                        </div>
                        {
                            dataHidden.isPublic?
                                <>
                                    <div class="col-span-12 lg:col-span-6 space-y-5">
                                        <div className="grid grid-cols-1 gap-4">



                                            <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                {
                                                    dataHidden.isCoreServer?
                                                        <div className="md:col-span-3 ">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                               <HPrimeIcon
                                                                   icon={"pi-server"}
                                                               />
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>{t("core")}</span>
                                                                </div>

                                                                {/*<InputText value={values.HostServerIp} placeholder={'IP-سرور HOST'} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'HostServerIp')}  />*/}
                                                                {/*<MaskedInput  className="input w-full"  onChange={(e)=>handleValueChange(e.target.value,'HostServerIp')} value={values.HostServerIp}  mask={ipMask()} placeholder={'IP-سرور HOST'} guide={false}  />*/}
                                                                <InputText  className={`input w-full ${IsCoreServerValidIp?'h-border-success':'h-border-danger'}`}   onChange={(e)=>handleValueChange(e.target.value,'CoreServerIp')} value={values.CoreServerIp}  placeholder={t("CoreServerIp")}   />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="md:col-span-3">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 w-20 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon
                                                                  icon={"pi-server"}
                                                              />
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>{t("core")}</span>
                                                                </div>
                                                                <Select
                                                                    options={selectedOptions.coreServers}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    onChange={(data)=>handleValueChange(data,'CoreServerId')}
                                                                    placeholder={t("CoreServer")}
                                                                    value={values.CoreServerId}
                                                                    className={'w-full multi_rounded_none'}
                                                                    classNamePrefix={"h_multi_select"}
                                                                    menuPosition={"fixed"}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                                <div className="md:col-span-1">
                                                    <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                        <input id="swich6" type="checkbox" checked={toggles.isCoreServer} onChange={(e)=>handleHidden(e.target.checked,'isCoreServer')} />
                                                        <label htmlFor="swich6">
                                                            <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>




                                            <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                {
                                                    dataHidden.isHost?
                                                        <div className="md:col-span-3 ">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20  flex-shrink-0 flex items-center justify-between gap-1"}>
                                                                <span>
                                                                    <HPrimeIcon icon={"pi-server"}/>
                                                                </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>host</span>
                                                           </div>

                                                                {/*<InputText value={values.HostServerIp} placeholder={'IP-سرور HOST'} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'HostServerIp')}  />*/}
                                                                {/*<MaskedInput  className="input w-full"  onChange={(e)=>handleValueChange(e.target.value,'HostServerIp')} value={values.HostServerIp}  mask={ipMask()} placeholder={'IP-سرور HOST'} guide={false}  />*/}
                                                                <InputText  className={`input w-full ${isHostIpValid?'h-border-success':'h-border-danger'}`}   onChange={(e)=>handleValueChange(e.target.value,'HostServerIp')} value={values.HostServerIp}  placeholder={t("IPServerHOST")}   />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="md:col-span-3">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>host</span>
                                                                </div>
                                                                <Select
                                                                    options={selectedOptions.hosts}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    onChange={(data)=>handleValueChange(data,'HostServerId')}
                                                                    placeholder={t("ServerHOST")}
                                                                    value={values.HostServerId}
                                                                    className={'w-full multi_rounded_none'}
                                                                    classNamePrefix={"h_multi_select"}
                                                                    menuPosition={"fixed"}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                                <div className="md:col-span-1">
                                                    <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                        <input id="swich20" type="checkbox" checked={toggles.isHost} onChange={(e)=>handleHidden(e.target.checked,'isHost')} />
                                                        <label htmlFor="swich20">
                                                            <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                {
                                                    dataHidden.isAdminPanel?
                                                        <div className="md:col-span-3 ">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>cpanel</span>
                                                                </div>
                                                                {/*<InputText value={values.HostServerIp} placeholder={'IP-سرور HOST'} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'HostServerIp')}  />*/}
                                                                {/*<MaskedInput  className="input w-full"  onChange={(e)=>handleValueChange(e.target.value,'HostServerIp')} value={values.HostServerIp}  mask={ipMask()} placeholder={'IP-سرور HOST'} guide={false}  />*/}
                                                                <InputText  className={`input w-full ${isAdminPanelIpValid?'h-border-success':'h-border-danger'}`}   onChange={(e)=>handleValueChange(e.target.value,'AdminPanelServerIp')} value={values.AdminPanelServerIp}  placeholder={t("AdminPanelServerIp")}   />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="md:col-span-3">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>cpanel</span>
                                                                </div>
                                                                <Select
                                                                    options={selectedOptions.adminPanels}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    onChange={(data)=>handleValueChange(data,'AdminPanelServerIp')}
                                                                    placeholder={t("AdminPanelServer")}
                                                                    value={values.AdminPanelServerIp}
                                                                    className={'w-full multi_rounded_none'}
                                                                    classNamePrefix={"h_multi_select"}
                                                                    menuPosition={"fixed"}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                                <div className="md:col-span-1">
                                                    <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                        <input id="adminPanel" type="checkbox" checked={toggles.isAdminPanel} onChange={(e)=>handleHidden(e.target.checked,'isAdminPanel')} />
                                                        <label htmlFor="adminPanel">
                                                            <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                {
                                                    dataHidden.isMail?
                                                        <div className="md:col-span-3 ">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>mail</span>
                                                                </div>
                                                                {/*<InputText value={values.HostServerIp} placeholder={'IP-سرور HOST'} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'HostServerIp')}  />*/}
                                                                {/*<MaskedInput  className="input w-full"  onChange={(e)=>handleValueChange(e.target.value,'HostServerIp')} value={values.HostServerIp}  mask={ipMask()} placeholder={'IP-سرور HOST'} guide={false}  />*/}
                                                                <InputText  className={`input w-full ${isMailIpValid?'h-border-success':'h-border-danger'}`}   onChange={(e)=>handleValueChange(e.target.value,'MailServerIp')} value={values.MailServerIp}  placeholder={t("MailServer")}   />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="md:col-span-3">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>mail</span>
                                                                </div>
                                                                <Select
                                                                    options={selectedOptions.mails}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    onChange={(data)=>handleValueChange(data,'MailServerId')}
                                                                    placeholder={t("MailServer")}
                                                                    value={values.MailServerId}
                                                                    className={'w-full multi_rounded_none'}
                                                                    classNamePrefix={"h_multi_select"}
                                                                    menuPosition={"fixed"}
                                                                />
                                                            </div>
                                                        </div>
                                                }
                                                <div className="md:col-span-1">
                                                    <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                        <input id="mail" type="checkbox" checked={toggles.isMail} onChange={(e)=>handleHidden(e.target.checked,'isMail')} />
                                                        <label htmlFor="mail">
                                                            <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">

                                                {
                                                    dataHidden.isWebserver?
                                                        <div className="md:col-span-3">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>site</span>
                                                                </div>
                                                                {/*<InputText value={values.SiteServerIp}  placeholder={'IP-سرور SITE'} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'SiteServerIp')}  />*/}
                                                                <InputText  className={`input w-full ${isWebServerIpValid?'h-border-success':'h-border-danger'}`}   onChange={(e)=>handleValueChange(e.target.value,'SiteServerIp')} value={values.SiteServerIp}  placeholder={t("IPServerSITE")}  />

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="md:col-span-3">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>site</span>
                                                                </div>
                                                                <Select
                                                                    options={selectedOptions.webservers}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    onChange={(data)=>handleValueChange(data,'WebServerId')}
                                                                    placeholder={t("SeverSITE")}
                                                                    value={values.WebServerId}
                                                                    className={'w-full multi_rounded_none'}
                                                                    classNamePrefix={"h_multi_select"}
                                                                    menuPosition={"fixed"}

                                                                />
                                                            </div>
                                                        </div>

                                                }
                                                <div className="md:col-span-1">
                                                    <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                        <input id="swich30" type="checkbox" checked={toggles.isWebserver} onChange={(e)=>handleHidden(e.target.checked,'isWebserver')} />
                                                        <label htmlFor="swich30">
                                                            <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="grid grid-cols-1 md:grid-cols-4 items-center gap-2">
                                                {
                                                    dataHidden.isDbServer?
                                                        <div className="md:col-span-3 ">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine w-20 flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>db</span>
                                                                </div>

                                                                {/*<InputText value={values.SqlServerIp}   placeholder={'IP-سرور SQL'} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'SqlServerIp')}  />*/}
                                                                <InputText  className={`input w-full ${isServerIpValid?'h-border-success':'h-border-danger'}`}  onChange={(e)=>handleValueChange(e.target.value,'DbServerIp')} value={values.DbServerIp}    placeholder={t("IPServerSQL")} />

                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="md:col-span-3 ">
                                                            <div className="p-inputgroup">
                                                                <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 flex w-20 items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                    <span className={"text-xs dark:text-gray-300"}>db</span>
                                                                </div>
                                                                <Select
                                                                    options={selectedOptions.dbServers}
                                                                    isClearable={false}
                                                                    isSearchable={true}
                                                                    onChange={(data)=>handleValueChange(data,'DbServerId')}
                                                                    placeholder={'Server Sql'}
                                                                    value={values.DbServerId}
                                                                    className={'w-full multi_rounded_none'}
                                                                    classNamePrefix={"h_multi_select"}
                                                                    menuPosition={"fixed"}
                                                                />
                                                            </div>
                                                        </div>
                                                }


                                                <div className="md:col-span-1">
                                                    <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                        <input id="swich10" checked={toggles.isDbServer} type="checkbox" onChange={(e)=>handleHidden(e.target.checked,'isDbServer')} />
                                                        <label htmlFor="swich10">
                                                            <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        {
                                            dataHidden.isDbServer?
                                                <div className="grid grid-cols-1">
                                                    <div className="space-y-2">
                                                        <div className="p-inputgroup">
                                                            <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                                <span className={"text-xs dark:text-gray-300"}>{t("ConnectionSQL")}</span>
                                                            </div>
                                                            <InputText value={values.ConnectionString} placeholder={t("ServerHOST")} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'ConnectionString')} onClick={() => handleOpenModal()} />
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        <div className="grid grid-cols-1">
                                            <div className="space-y-2">
                                                {/*<label className="text-xs dark:text-gray-300">{t("ValidIPs")}</label>*/}
                                                <div className="p-inputgroup w-full ">
                                                    <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 flex items-center justify-between gap-1"}>
                                                <span>
                                                    <HPrimeIcon icon={"pi-server"}/>
                                                </span>
                                                        <span className={"text-xs dark:text-gray-300"}>allowed ip</span>
                                                    </div>
                                                    <TagsInput value={values.AllowIps} onChange={(data)=>handleValueChange(data,'AllowIps')} name={"AllowIps"} placeHolder={t("ValidIPs")}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>

                                :

                                <div class="col-span-12 lg:col-span-6 space-y-5 ">
                                    <div className={"grid grid-cols-3 lg:grid-cols-4 items-center gap-2"}>
                                        {
                                            dataHidden.isDedicated?
                                                <div className="col-span-3">
                                                    <div className="p-inputgroup">
                                                        <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 w-20 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                            <span className={"text-xs dark:text-gray-300"}>server</span>
                                                        </div>
                                                        {/*<InputText value={values.HostServerIp} placeholder={'IP-سرور HOST'} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'HostServerIp')}  />*/}
                                                        {/*<MaskedInput  className="input w-full"  onChange={(e)=>handleValueChange(e.target.value,'HostServerIp')} value={values.HostServerIp}  mask={ipMask()} placeholder={'IP-سرور HOST'} guide={false}  />*/}
                                                        <InputText  className={`input w-full ${isDedicatedIpValid?'h-border-success':'h-border-danger'}`}   onChange={(e)=>handleValueChange(e.target.value,'DedicatedServerIp')} value={values.DedicatedServerIp}  placeholder={t("DedicatedServer")}   />
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-span-3">
                                                    <div className="p-inputgroup">
                                                        <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 w-20 flex items-center justify-between gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                            <span className={"text-xs dark:text-gray-300"}>server</span>
                                                        </div>
                                                        <Select
                                                            options={selectedOptions.dedicateds}
                                                            isClearable={false}
                                                            isSearchable={true}
                                                            onChange={(data)=>handleValueChange(data,'DedicatedServerId')}
                                                            placeholder={t("MailServer")}
                                                            value={values.DedicatedServerId}
                                                            className={'w-full multi_rounded_none'}
                                                            classNamePrefix={"h_multi_select"}
                                                            menuPosition={"fixed"}
                                                        />
                                                    </div>
                                                </div>
                                        }
                                        <div className="col-span-1">
                                            <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                                <input id="dedicated" type="checkbox" checked={toggles.isDedicated} onChange={(e)=>handleHidden(e.target.checked,'isDedicated')} />
                                                <label htmlFor="dedicated">
                                                    <div class="can-toggle__switch" data-checked={t("External")} data-unchecked={t("Internal")}></div>
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            dataHidden.isDedicated?
                                                <div className="col-span-3 lg:col-span-4">
                                                    <div className="p-inputgroup">
                                                        <div className={"p-inputgroup-addon border dark:border-themeDarkColorLine flex-shrink-0 flex items-center gap-1"}>
                                                          <span>
                                                              <HPrimeIcon icon={"pi-server"}/>
                                                          </span>
                                                            <span className={"text-xs dark:text-gray-300"}>{t("ConnectionSQL")}</span>
                                                        </div>
                                                        <InputText value={values.ConnectionString} placeholder={t("ServerHOST")} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'ConnectionString')} onClick={() => handleOpenModal()} />
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                </div>

                        }

                    </div>
                    <div class="hidden lg:grid place-content-center">
                        <img src="/Assets/Image/template/Host.png" alt="design" className="max-w-3/4 mx-auto"/>
                    </div>

                </div>
                <div class="flex items-center justify-center md:justify-end  gap-2">
                    <button type={'button'} onClick={()=>GoToLastPage('domains')} className="block button w-28  bg-themeDanger text-white">{t("previous")}</button>
                    <button type={'button'} onClick={()=>handleChangeViewTab('templates')} className="block button w-28 bg-themeInformation dark:bg-themeDarkInformation text-white">{t("Next")}</button>
                </div>
            </div>


            <FantasyModal openModal={isOpen} closeModal={handleCloseModal} title="sql-server-connection-string" customeClassName={"dir-ltr modal-x"}>
                <ConnectionStringBuilder
                    OnValueChange={onValueChange}
                    name={'ConnectionString'}
                    handleCloseModal={handleCloseModal}
                />
            </FantasyModal>
        </>
    )

};
