import { GET } from "../../../Services/AxiosService/AxiosApiService";
import { convertSelectDataHandler } from "../../CMS/Contents/ContentService";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import { useEffect, useState } from "react";

export const useFetchData = (refresh, onApiError, setTicket, ticket) => {
    const [options, setOptions] = useState({ ticketTypes: [], users: [] });
    const [setting, setSetting] = useState([]);


    // Define a utility function to handle API responses
    const handleResponse = async (url, type) => {
        const response = await GET(url, {}, type);
        if (response.status !== 200) {
            handleApiError(response, onApiError);
            return null;
        }
        return response.data;
    };

    const handleFetchData = async () => {
        try {
            const [ticketTypesData, userSelectionData, settingData] = await Promise.all([
                handleResponse('/Enumerations/EnTicketType', 'IEnumerable'),
                handleResponse('/Users/Selection', 'List'),
                handleResponse('/Settings?Name=MaximumFileUploadSize', 'IEnumerable')
            ]);

            if (ticketTypesData && userSelectionData && settingData) {
                const ticketTypes = convertSelectDataHandler(ticketTypesData);
                const userSelection = convertSelectDataHandler(userSelectionData);
                const { settings } = settingData;

                setSetting(settings);
                setOptions(prevState => ({
                    ...prevState,
                    ticketTypes,
                    users: userSelection
                }));
                setTicket(prevState => ({
                    ...prevState,
                    type: ticketTypes[0]
                }));
            }
        } catch (e) {
            console.log(e);
        }
    }

    const handleValidateFileSize = (maxSize) => {
        //const maxSize = 10;
        var fileSize = 0;
        if (Array.isArray(ticket.files) && ticket.files.length > 0) {
            ticket.files.forEach(file => { fileSize += file.size; });
        } else {
            fileSize = 0;
        }
        var selectedSize = fileSize / (1024 * 1024);
        if (selectedSize > maxSize) {
            return false;
        }
        return true;
    }





    useEffect(() => {
        handleFetchData();
    }, [refresh]);
    return {
        handleFetchData,
        options,
        setOptions,
        handleValidateFileSize,
        setting

    }

}