import React, {useState} from "react";


import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";

// this is a TabMenu component .
// handling just a tags as its menu .
// receives an object of data based on counts of contents .
let tooltipCounter = 0; // Global counter for tooltips
export const TabMenuTwo = ({ tabs, activeTab, handleTabClick,className,tooltipTitle}) => {
    const { t } = useTranslation();
    const [tooltipId] = useState(() => {
        tooltipCounter += 1;
        return `${tooltipCounter}`;
    });


    return (
        <>
            {tabs.map((tab,index) => (
                <div className={`tabs_two flex justify-center post-input post-input-design dark:bg-themeDarkPostInput cursor-pointer items-center gap-2 ${className}  ${ activeTab === tab.id ? "active" : ""} ${`tooltip_class_${index}_${tooltipId}`}`}
                     key={tab.id} title={tab.title}
                     onClick={() => handleTabClick(tab.id)}
                >
                    <Tooltip anchorSelect={`.tooltip_class_${index}_${tooltipId}`} content={tab.tooltipTitle ? tab.tooltipTitle : t("New")} positionStrategy={"fixed"} />
                    <h2 className={"text-xs"}>
                        {tab.label}
                    </h2>
                </div>
            ))}

        </>

    );
};

