import React, {useState} from "react";

import {InputText} from "primereact/inputtext";

import {ListFilter} from "../Commans/Grid/ListFilter";

import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";


import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../Commans/UIParts/ButtonSubmitAndCancel";
import {Accordion, AccordionTab} from "primereact/accordion";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";



export const HandleAttendanceFinancialRulesAdd = () => {
    const { t } = useTranslation();

    const redirectTo = useNavigate();
    const [isOpen,setOpen] = useState(false);
    const handleOpenModal = ()=>{
        setOpen(true);
    }
    const handleCloseModal = ()=>{
        setOpen(false);
    }

    const [dates, setDates] = useState([]);


    const [value, setValue] = useState(new Date());



    return(
        <>
            <div class="space-y-5 ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("list")}
                                            onClick={()=>redirectTo(`#`)}
                                            icon={"list"}
                                            tooltipTitle={t("ListSourcesInformation")}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>

                <div className={"box bg-white p-2 dark:bg-themeDarkSalt700"}>
                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 post-input dark:bg-themeDarkPostInput post-input-design p-2"}>
                        <div className="space-y-1">
                        <div class="space-y-1">
                            <label className={"text-xs dark:text-gray-300"}>{t("عنوان حکم")}</label>
                            <InputText className="input w-full" />
                        </div>
                    </div>
                        <div className="space-y-1">
                            <div class="space-y-1">
                                <label className={"text-xs dark:text-gray-300"}>{t("سال مالی")}</label>
                                <InputText className="input w-full" placeholder={"فقط سال بگیرد"} />
                            </div>
                        </div>
                    </div>

                </div>

                <div className={"space-y-5"}>
                    <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 "}>
                        <div className={"space-y-3 p"}>
                            <div class="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation"><h2 class="dark:text-gray-300 text-xs">تعیین احکام</h2></div>
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 post-input dark:bg-themeDarkPostInput post-input-design px-2">
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("DailyWage")}</label>
                                    <InputText className="input w-full" />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("HourlyWage")}</label>
                                    <InputText className="input w-full"  />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("SeverancePay")}</label>
                                    <InputText className="input w-full"  />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("ChildAllowance")}</label>
                                    <InputText className="input w-full"  />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("PersonnelInsuranceContribution")}</label>
                                    <InputText className="input w-full" />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("EmployersInsuranceContribution")}</label>
                                    <InputText className="input w-full" />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("UnemploymentInsuranceContribution")}</label>
                                    <InputText className="input w-full"  />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("SupplementaryInsuranceContribution")}</label>
                                    <InputText className="input w-full"  />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("SalaryTax")}</label>
                                    <InputText className="input w-full"  />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("ManagementFee")}</label>
                                    <InputText className="input w-full"  />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("SupervisorFee")}</label>
                                    <InputText className="input w-full"  />
                                </div>
                                <div class="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("Efficiency")}</label>
                                    <InputText className="input w-full"  />
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 "}>
                        <Accordion activeIndex={0} className={"bg-white dark:bg-themeDarkSalt700"}>
                            <AccordionTab
                                header={
                                    <div className="flex items-center gap-2">
                                        <HPrimeIcon icon={'pi-user'} />
                                        <p>{t("DefineCustomRules")}</p>
                                    </div>
                                }
                            >
                                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 max_height85vh">
                                    {/*این قسمت از تنظیمات خوانده و نمایش داده میشود*/}
                                    <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                        <div class="space-y-1">
                                            <label className={"text-xs dark:text-gray-300"}>{t("DailyWage")}</label>
                                            <InputText className="input w-full" />
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>


                <ButtonSubmitAndCancel
                />
            </div>

        </>

  )
}