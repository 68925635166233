import React, {useState} from "react";

export const useStatistics=(Refresh)=>{
    const [statistics,setStatistics]=useState({
        dailyVisitors: {
            uniqueVisitors: 0,
            uniquePages: 0,
            averageViews: 0,
            totalViews:0
        },
        weeklyVisitors: {
            uniqueVisitors: 0,
            uniquePages: 0,
            averageViews: 0,
            totalViews:0,
        },
        monthlyVisitors: {
            uniqueVisitors: 0,
            uniquePages: 0,
            averageViews: 0,
            totalViews:0
        },
        annualVisitors: {
            uniqueVisitors: 0,
            uniquePages: 0,
            averageViews: 0,
            totalViews:0
        },
        topVisitedPages: [
            {
                pageUrl: "string",
                pageViews: 0
            }
        ],
        topReferrerUrls: [
            {
                referrerUrl: "string",
                referrals: 0
            }
        ],
        browserStatistics: [
            {
                browser: "string",
                visits: 0
            }
        ],
        countryStatistics: [
            {
                country: "string",
                visits: 0
            }
        ],
        platformStatistics: [
            {
                platform: "string",
                visits: 0
            }
        ],
        cityStatistics: [
            {
                city: "string",
                visits: 0
            }
        ],
        dailyVisitorsStats: [
            {
                date: "string",
                uniqueVisitors: 0
            }
        ],
        uniqueVisitorsInRange: 0,
        totalPageViews: 0,
        numberOfDaysInRange: 0,
        averagePageViews: 0
    });
    const [report,setReport]=useState({fromDate:null,toDate:null});
    const handleFromDate=fromDate=>setReport((prevState)=>({...prevState,fromDate: fromDate}))
    const handleToDate=toDate=>setReport((prevState)=>({...prevState,toDate:toDate}));
    const handleResetDates=()=>{
        setReport((prevState)=>({...prevState,fromDate: null,toDate: null}));
        Refresh()
    }
    return {
        report,
        handleFromDate,
        handleToDate,
        handleResetDates,
        statistics,
        setStatistics
    }
}