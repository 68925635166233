import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {Return} from "../../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import Select from "react-select";
import {TabMenu} from "../../../../Commans/UIParts/TabMenu";
import {validate} from "../../../../../Services/Globals/validate";
import {passwordSchema} from "../../../Profile/ProfileEdit/Passwords/passwordSchema";
import {decodeAccessToken, saveAccessToken, saveRefreshToken} from "../../../../../Services/AxiosService/JwtService";
import {POST, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {DisplayError} from "../../../../Commans/Errors/DisplayError";
import {useChangePass} from "./useChangePass";
import {settingPasswordSchema} from "./settingPasswordSchema";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {ToastContainer} from "react-toastify";

export const HandleChangePassword = () => {
    const { t } = useTranslation();


    const [errors, setErrors] = useState({});
    const[IsShowSpinner,setIsShowSpinner] = useState(false);
    const {handleValue,setSetting,setting} = useChangePass(setErrors)
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmNewPassword] = useState(false);


    const showSpinner = () => {
        setIsShowSpinner(true);
    };
    const hideSpinner=()=>{
        setIsShowSpinner(false);

    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }


    const handleSubmitPassword=async (e)=>{
        e.preventDefault();
        const isValid = validate(settingPasswordSchema,setting,onSetErrors);
        if(!isValid){
            return;
        }
        try {
            showSpinner();
            const response = await POST(`/Authentication/ChangePassword`,{
                    currentPassword:setting.currentPassword,
                    newPassword:setting.newPassword,
                    confirmNewPassword:setting.confirmPassword
            },
                false);
            if(response.status === 200){
                saveAccessToken(response.data.accessToken);
                saveRefreshToken(response.data.refreshToken);
                window.location = '/DashBoard';
                hideSpinner();
                handleSuccessMessage(t("Passwordchangesuccessful"));
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e){
            hideSpinner();
        }

    }




    return (
        <>
            <div className=" space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className="huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>

                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />
                </div>
                <div className={"p-2 space-y-5 bg-white dark:bg-themeDarkSalt700 grid grid-cols-1 md:grid-cols-2"}>
                    <form onSubmit={handleSubmitPassword} method={'post'} className={""}>
                        <div class="grid grid-cols-1 gap-3 p-2 rounded-md post-input dark:bg-themeDarkPostInput">
                            <div class=" space-y-2">
                                <div className={"relative float-input p-float-label"}>
                                    <div className={"absolute -top-2 end-2"}>
                                        <DisplayError message={errors.currentPassword}/>
                                    </div>
                                    <i className={
                                        showPassword
                                            ? "pi pi-eye cursor-pointer absolute dark:text-gray-300 end-2 top-1/2 -translate-y-1/2"
                                            : "pi pi-eye-slash cursor-pointer dark:text-gray-300 absolute end-2 top-1/2 -translate-y-1/2"
                                    }
                                       onClick={() => setShowPassword(!showPassword)}>

                                    </i>
                                    <InputText type={showPassword ? "text" : "password"}
                                               className=" input w-full input-float-label"
                                               value={setting.currentPassword}
                                               onInput={(e) => handleValue(e)}
                                               name="currentPassword"
                                    />
                                    <label className="text-xs dark:text-gray-300">{t("OldPassword")}</label>
                                </div>




                                <div className={"relative float-input p-float-label"}>
                                    <div className={"absolute -top-2 end-2"}>
                                        <DisplayError message={errors.newPassword}/>
                                    </div>
                                    <i className={
                                        showNewPassword
                                            ? "pi pi-eye cursor-pointer absolute dark:text-gray-300 end-2 top-1/2 -translate-y-1/2"
                                            : "pi pi-eye-slash cursor-pointer dark:text-gray-300 absolute end-2 top-1/2 -translate-y-1/2"
                                    }
                                       onClick={() => setShowNewPassword(!showNewPassword)}>

                                    </i>
                                    <InputText type={showNewPassword ? "text" : "password"}
                                               className=" input w-full input-float-label"
                                               value={setting.newPassword}
                                               onInput={(e) => handleValue(e)}
                                               name="newPassword"
                                    />
                                    <label className="text-xs dark:text-gray-300">{t("NewPassword")}</label>
                                </div>

                            </div>



                            <div className={"relative float-input p-float-label"}>
                                <div className={"absolute -top-2 end-2"}>
                                    <DisplayError message={errors.confirmPassword}/>
                                </div>
                                <i className={
                                    showConfirmPassword
                                        ? "pi pi-eye cursor-pointer absolute dark:text-gray-300 end-2 top-1/2 -translate-y-1/2"
                                        : "pi pi-eye-slash cursor-pointer dark:text-gray-300 absolute end-2 top-1/2 -translate-y-1/2"
                                }
                                   onClick={() => setShowConfirmNewPassword(!showConfirmPassword)}>

                                </i>
                                <InputText type={showConfirmPassword ? "text" : "password"}
                                           className=" input w-full input-float-label"
                                           value={setting.confirmPassword}
                                           onInput={(e) => handleValue(e)}
                                           name="confirmPassword"
                                />
                                <label className="text-xs dark:text-gray-300">{t("ConfirmPassword")}</label>
                            </div>


                            <div>
                                <button type={'submit'} className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("ChangePassword")}</button>
                            </div>
                        </div>
                    </form>
                    <div>
                        <div className="card grid place-content-center">
                            <img src="/Assets/Image/bg-img/Securepassword.svg" alt="backup" className={"w-full h-full object-contain"}/>
                        </div>
                    </div>
                </div>
            </div>
<ToastContainer/>

        </>




    );
};