import React, {useState} from "react";

export const usePrintPrice=()=>{
    const [print,setPrint]=useState({GroupIds:[],retailPrice:false,wholeSalePrice:false});
    const handleSelectPrintPriceValue=(data,name)=>{
        setPrint({...print,[name]:data});
    }
    return {
        print,
        setPrint,
        handleSelectPrintPriceValue
    }
}