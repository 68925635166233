import React, {useEffect, useState} from "react";
import { useNavigate} from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import PasswordStrengthBar from "react-password-strength-bar";
import {codeSchema, emailSchema, mobileSchema, PasswordSchema} from "./forgetPasswordSchema";
import {ToastContainer} from "react-toastify";
import { useTranslation } from "react-i18next";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {useForgetPassword} from "./useForgetPassword";
import {validate} from "../../../Services/Globals/validate";
import Select from "react-select";
import {DisplayError} from "../../Commans/Errors/DisplayError";

export function HandleForgetPassword(){

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const [show,SetShow] = useState(false);
    const [isPlay,Setplay] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [IsMethodHidden, SetMethodHidden] = useState(false);
    const [IsCodeHidden, SetCodeHidden] = useState(true);
    const [IsShowPassHidden, SetShowPassHidden] = useState(true);
    const [errors, setErrors] = useState({});
    const [initialSource,setInitialSource]=useState({methods:[{ label: t("Mobile"),value: '1' },{ label: t("Email"), value: '0' }]});
    const {handleSelectValue,handleValue,setAuthData, authData}=useForgetPassword(setErrors)



    const fetchData=()=>{
        setAuthData((prevState)=>({...prevState,method: initialSource.methods[0]}));
    }
    useEffect(()=>{
        fetchData();
    },[])


    const hideSpinner=()=>{
        setSpinner(false);
    }
    const showSpinner=()=>{
        setSpinner(true);
    }
    const onSetErrors=(erros)=>{
        setErrors(erros);
    }


    const ResendCode = async () => {
     try {
         showSpinner();
         const { username,method } = authData;
         const response = await POST('/Authentication/ForgetPassword', { username:username,method: parseInt(method.value) });
         if (response.status === 200) {
             const { id, code } = response.data;
             setAuthData((prevState)=>({...prevState,code: code,id:id}));
         } else {
             handleApiError(response, hideSpinner);
         }
     }catch (e) {
         hideSpinner();
     }
    };

    const SubmitMethodHandlerApi = async (e) => {
        e.preventDefault();
       try {
           showSpinner();
           const schema = authData.method.value === '1' ? mobileSchema : emailSchema;
           const isValid = validate(schema,authData,onSetErrors);
           if (!isValid) return;
           const { username,method } = authData;
           const response = await POST('/Authentication/ForgetPassword', { username: username, method: parseInt(method.value) }, false);
           if (response.status === 200) {
               const { id, code } = response.data;
               setAuthData((prevState)=>({...prevState,code: code,id:id}));
               SetMethodHidden(!IsMethodHidden);
               SetCodeHidden(!IsCodeHidden);
           } else {
               handleApiError(response, hideSpinner);
           }
       }catch (e) {
           hideSpinner();
       }
    };

    const ValidateSubmitOtp = async (e) => {
        e.preventDefault();
        try {
            showSpinner();
           const isValid = validate(codeSchema,authData,onSetErrors);
           if (!isValid) return;
           const { id, otpCode } = authData;
           const response = await POST('/Authentication/ForgetPassword/Submit', { id: id, code: otpCode }, false);
           if (response.status === 200) {
               const { id, code } = response.data;
               setAuthData((prevState)=>({...prevState,code: code,id:id}));
               SetCodeHidden(!IsCodeHidden);
               SetShowPassHidden(!IsShowPassHidden);
           } else {
                handleApiError(response, hideSpinner);
           }
       }catch (e) {
           hideSpinner();
       }
    };

    const ChangeSubmitPasswordApi = async (e) => {
        e.preventDefault();
        try {
            const isValid = validate(PasswordSchema,authData,onSetErrors);
            if (!isValid) return;
         const { id, code, password, confirmPassword } = authData;
         const response = await POST('Authentication/ForgetPassword/ChangePassword', { id: id, code: code, newPassword: password, confirmNewPassword: confirmPassword });
         if (response.status === 200) {
             redirectTo('/');
         } else {
              handleApiError(response, hideSpinner);
         }
     }catch (e) {
         hideSpinner();
     }
    };
    const Resend = async () => {
        await ResendCode();
        Setplay(true);
        SetShow(false);
    };

    const Message = () => <span className="cursor-pointer text-white bg-themeInformation p-1 rounded shadow" onClick={Resend}>{t('Resend')}</span>;

    const Timer = () => (
        <CountdownCircleTimer
            isPlaying={isPlay}
            strokeWidth={5}
            size={50}
            duration={3}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            onComplete={() => SetShow(true)}
        >
            {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
    );

    const Goto = () => {
        SetCodeHidden(!IsCodeHidden);
        SetMethodHidden(!IsMethodHidden);
    };


    return (
        <>
            {/*{*/}
            {/*    spinner?*/}
            {/*        <div className=" huploader_loading" >*/}
            {/*            <div className="relative">*/}
            {/*                <LoadingHarmony/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        :*/}
            {/*        null*/}
            {/*}*/}
            <div className="flex flex-col items-center justify-center gap-5 ">
                <div className="flex items-center xl:hidden">
                    <img alt="harmony2023" className="w-48 h-auto" src="http://cpanel.harmonycms.ir/ArmDark.svg"/>
                </div>
                <div className="lg:w-[23rem] space-y-5 bg-white p-5 dark:bg-themeDarkSalt700 border border-gray-300 rounded-lg max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] ">
                    <div className={"space-y-2"}>
                        <div className="flex justify-items-start">
                            <h2 className="text-2xl xl:text-3xl dark:text-gray-300">
                                {t('passwordRecovery')}
                            </h2>
                        </div>
                        <div className="text-justify">
                            <p className={"text-neutral-500 text-xs dark:text-gray-300"}>
                                {t('SignInTitle')}
                            </p>
                        </div>
                    </div>

                    {
                        !IsMethodHidden?(
                            <form method={'post'}  onSubmit={SubmitMethodHandlerApi} className="space-y-5">
                                <div className="space-y-2 ">
                                    <label className="text-xs dark:text-gray-300">{t('passwordRecieveMethod')}</label>
                                    <Select
                                            options={initialSource.methods}
                                            isSearchable={true}
                                            onChange={(data) => handleSelectValue(data, 'method')}
                                            placeholder={t("selection")}
                                            value={authData.method}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                </div>
                                <div className="relative float-input p-float-label">
                                    <InputText className={"input w-full input-float-label"} onInput={(e)=>handleValue(e)} name={'username'} value={authData.username}/>
                                    <label className={"text-xs dark:text-gray-300"}>{t("MobileEmail")}</label>
                                    <div className={"absolute end-2 -top-2"}>
                                        <DisplayError message={errors.username}/>
                                    </div>
                                </div>
                                <div className="flex gap-3 items-center">
                                    <button type={'button'} onClick={()=>redirectTo('/')} className={'button flex-1 text-white bg-themeDanger'}  >{t('Return')}</button>
                                    <button type={'submit'} className={'button flex-1 text-white bg-themeInformation'}  >{t('OtpReceive')}</button>
                                </div>
                            </form>

                        ):(<></>)
                    }
                    {
                        !IsCodeHidden?(
                            <form method={'post'} onSubmit={ValidateSubmitOtp} className="space-y-2">
                                <div className=" p-2 flex justify-between items-center ">
                                    <div className="flex justify-items-start">
                                        <h2 className="text-gray-700 ">
                                            {t('Enter_Your_Code')}
                                        </h2>
                                    </div>
                                    <div>
                                        {!show && <Timer />}
                                        {show && <Message />}
                                    </div>
                                </div>

                                <div className="space-y-5">
                                    <InputText onInput={(e)=>handleValue(e)} name={'otpCode'} value={authData.otpCode}  placeholder={t('OTP')} className={"input"}/>
                                    <div className="flex gap-2 items-center">
                                        <button onClick={Goto}  type={'button'}  className={'button w-full flex-1 text-white bg-themeDanger'} >{t('PreviousLevel')} </button>
                                        <button type={'submit'} className={'button  w-full flex-1 text-white bg-themeInformation'} >{t('codeConfirmtion')}</button>
                                    </div>
                                    <DisplayError message={errors.otpCode}/>
                                </div>

                            </form>
                        ):(<></>)
                    }
                    {
                        !IsShowPassHidden?(
                            <form method={'post'} onSubmit={ChangeSubmitPasswordApi} >
                            <div className="p-2 flex justify-between items-center ">
                                <div className="flex justify-items-start">
                                    <h2 className="font-bold text-gray-700 ">
                                        {t('Enter_Your_Password')}
                                    </h2>
                                </div>
                            </div>
                            <div className="space-y-5">
                                <span className="p-input-icon-left">
                                       <i className={showPassword?'pi pi-eye cursor-pointer':'pi pi-eye-slash cursor-pointer'} onClick={()=>setShowPassword(!showPassword)}></i>
                                    <InputText type={showPassword?'text':'password'}   className="input" placeholder={t('Password')}  name='password' value={authData.password} onInput={(e)=>handleValue(e)} />
                                </span>
                                <DisplayError message={errors.password}/>

                                <PasswordStrengthBar password={authData.password}/>
                                <span className="p-input-icon-left ">
                                    <i className={showConfirmPassword?'pi pi-eye cursor-pointer':'pi pi-eye-slash cursor-pointer'} onClick={()=>setShowConfirmPassword(!showConfirmPassword)}></i>
                                    <InputText type={showConfirmPassword?'text':'password'}  className="input" placeholder={t('RepeatPassword')} name='confirmPassword' value={authData.confirmPassword} onInput={(e)=>handleValue(e)} />
                                </span>
                                <DisplayError message={errors.confirmPassword}/>

                                <div className="flex gap-2">
                                    <button type={'submit'} className={'button  w-full text-white bg-themeInformation '}>{t('register')}</button>
                                </div>

                            </div>
                        </form>):(<></>)
                    }
                </div>
            </div>
            <div className="hidden lg:flex flex-col items-center justify-center gap-5 ">
                <img alt="harmony2023" className=" w-1/2" src="/Assets/Image/Design/illustration.svg"/>
                <div className={"max-w-[27rem] lg:min-w-[23rem] space-y-2"}>
                    <p className={" text-4xl  text-white dark:text-gray-300"}>
                        {t("Right_SideSignInTitle")}
                    </p>
                    <p className={"text-4xl text-white dark:text-gray-300"}>
                        {t("Right_SideSignInTitleTwo")}
                    </p>
                    <div className=" text-white dark:text-gray-300">{t("SignInTitle")}</div>
                </div>
            </div>

            <ToastContainer/>

        </>
    )
}