import React, {useState} from "react";
import {validate} from "../../../Services/Globals/validate";

export const useFormValidation=()=>{
    const [isValid,setIsValid] =useState(false);
    const ValidateForm=(schema,model,onSetError)=>{
        var isValid = validate(schema,model,onSetError);
        return isValid
    }
    return {isValid,ValidateForm}
}