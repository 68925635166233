import React from "react";
import { HPrimeIconSmall } from "../../../Commans/UIParts/HPrimeIconSmall";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { handleSuccessMessage } from "../../../../Services/Globals/Errors/NotificationService";

export const Contacts = ({ user, company }) => {
    const { t } = useTranslation();

    // Function to copy text to the clipboard
    const handleCopyToClipboard = async (text) => {
        if (text) {
            try {
                await navigator.clipboard.writeText(text);
                handleSuccessMessage(t("Yourtexthasbeencopied")); // Show success message after copying
            } catch (error) {
                console.error('Failed to copy:', error); // Log any errors that occur
            }
        }
    };

    return (
        <div className={"post-input p-2 post-input-design dark:bg-themeDarkPostInput space-y-3"}>
            <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 flex gap-2 items-center"}>
                <HPrimeIconSmall
                    icon={"pi-mobile"}
                    iconClass={"dark:text-gray-300"}
                />
                <p className={"text-xs dark:text-gray-300"}>{t("PhoneNumber")}</p>
            </div>
            <div className={"max_height10 scrollbar space-y-2 p-2"}>
                <div className={"flex gap-1 items-center"}>
                    <div className={"min-w-16"}>
                        <p className={"text-xs dark:text-gray-300"}>{t("LandlinePhone")}</p>
                    </div>
                    <div className={"flex-1"}>
                        <InputText className={"input"} value={user?.homePhoneNumber ?? '-'} disabled={true} />
                    </div>
                    <div className={"flex gap-2 items-center"}>
                        <button onClick={() => handleCopyToClipboard(user?.homePhoneNumber)} className={"button bg-themeSuccess"}>
                            <HPrimeIconSmall
                                icon={"pi-copy"}
                                iconClass={"text-white"}
                            />
                        </button>
                        <a href={`tel:${user?.homePhoneNumber ?? '#'}`} className={"button bg-themeInformation dark:bg-themeDarkInformation"}>
                            <HPrimeIconSmall icon={"pi-phone"} iconClass={"text-white"} />
                        </a>
                    </div>
                </div>
                <div className={"flex gap-1 items-center"}>
                    <div className={"min-w-16"}>
                        <p className={"text-xs dark:text-gray-300"}>{t("MobilePhone")}</p>
                    </div>
                    <div className={"flex-1"}>
                        <InputText value={user?.phoneNumber ?? '-'} className={"input"} disabled={true} />
                    </div>
                    <div className={"flex gap-2 items-center"}>
                        <button onClick={() => handleCopyToClipboard(user?.phoneNumber)} className={"button bg-themeSuccess"}>
                            <HPrimeIconSmall
                                icon={"pi-copy"}
                                iconClass={"text-white"}
                            />
                        </button>
                        <a href={`tel:${user?.phoneNumber ?? '#'}`} className={"button bg-themeInformation dark:bg-themeDarkInformation"}>
                            <HPrimeIconSmall icon={"pi-phone"} iconClass={"text-white"} />
                        </a>
                    </div>
                </div>
                <div className={"flex gap-1 items-center"}>
                    <div className={"min-w-16"}>
                        <p className={"text-xs dark:text-gray-300"}>{t("CompanyPhone")}</p>
                    </div>
                    <div className={"flex-1"}>
                        <InputText className={"input"} value={company?.registrationNumber ?? '-'} disabled={true} />
                    </div>
                    <div className={"flex gap-2 items-center"}>
                        <button onClick={() => handleCopyToClipboard(company?.registrationNumber)} className={"button bg-themeSuccess"}>
                            <HPrimeIconSmall
                                icon={"pi-copy"}
                                iconClass={"text-white"}
                            />
                        </button>
                        <a href={`tel:${company?.registrationNumber ?? '#'}`} className={"button bg-themeInformation dark:bg-themeDarkInformation"}>
                            <HPrimeIconSmall icon={"pi-phone"} iconClass={"text-white"} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
