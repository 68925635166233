import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import React from "react";
import {useTranslation} from "react-i18next";
import {PriceFormatterComponent} from "../../Commans/Accounting/PriceFormatterComponent";

export const TransactionItem=({item,GetCurrencyType,GetTransactionType,GetTransactionIcon,GetPaymentState })=>{
    const { t } = useTranslation();

    return (
        <>
            <div className="post-input dark:bg-themeDarkSalt700 post-input-design">
                <div>
                    <ul className={"grid grid-cols-1 xl:grid-cols-2 gap-5 p-2"}>
                        <li className={" box bg-white dark:bg-themeDarkPostInput  rounded-md overflow-hidden"}>
                            <div className={"flex flex-col lg:flex-row h-full "}>
                                {/*{GetTransactionIcon(item.amount.moneyType)}*/}
                                {GetTransactionIcon(item.paymentStatus)}
                                <div className={"flex-1 grid grid-cols-1 md:grid-cols-2  border dark:border-themeDarkColorLine "}>
                                    <div className={" flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>{item.userFullName}</p>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("BankAccountNumber")}:</p>
                                        <span className={"line-clamp-1 text-xs  dark:text-gray-300"}>{item.userAccountNumber}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("cardNumber")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{item.userCardNumber}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("BankID")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300 "}>{item.userShebaNumber}</span>
                                    </div>

                                    <div className={"flex gap-2 flex-col md:flex-row border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("TrackingCode")}:</p>
                                        <span className={"line-clamp-1 text-xs  dark:text-gray-300"}>{item.trackingCode}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionDate")}:</p>
                                        <span className={"text-xs dark:text-gray-300"}>{<GridDateFomartter date={item.creationDate} hasTime={true}/>}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("description")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{item.description}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row items-center gap-2 border p-1 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionAmount")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}><PriceFormatterComponent price={item.amount.amount}/> {GetCurrencyType(item.amount.currency)}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row items-center gap-2 border p-1 dark:border-themeDarkColorLine"}>
                                        {
                                            item.paymentStatus === 1?
                                                <>
                                                    <p className={"text-xs dark:text-gray-300"}>{t("TransactionNumber")}:</p>
                                                    <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{item.transactionCode}</span>
                                                </>
                                                :
                                                item.paymentStatus === 2?
                                                    <>
                                                        <p className={"text-xs dark:text-gray-300"}>{t("Reason")}:</p>
                                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{item.banReason}</span>
                                                    </>:null
                                        }

                                    </div>
                                    <div className={"flex flex-col md:flex-row items-center gap-2 border p-1 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("Status")}:</p>
                                        <span className={`line-clamp-1 text-xs ${item.paymentStatus === 2?'text-themeDanger':item.paymentStatus === 1?'text-themeSuccess':item.paymentStatus === 0?'text-themeSecondary':''}`}>{GetPaymentState(item.paymentStatus)}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}