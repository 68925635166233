import React, {useState} from "react";

export const useCustomWebsiteUpgrade=()=>{
    const [payment,setPayment] = useState({upgradeId:null,creationDate:null,expirationDate:null,applyTax:false});
    const handleChangeToggle=(name,value)=>{
        setPayment({...payment,[name]:value});
    }
    const handleFromDateTime=(value)=>{
        setPayment({...payment, creationDate:value});
    }
    const handleToDateTime=(value)=>{
        setPayment({...payment,expirationDate: value});
    }
return {
    handleChangeToggle,
    handleFromDateTime,
    handleToDateTime,
    payment,
    setPayment
}

}