import React, {useEffect, useState} from "react";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {Return} from "../../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Timeline} from "primereact/timeline";
import queryString from "query-string";
import {Pagination} from "../../../../Commans/Grid/Pagination";
import {useWebsiteUpgradeDetailGrid} from "./useWebsiteUpgradeDetailGrid";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {GridDateFomartter} from "../../../../Commans/Grid/GridDateFomartter";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {PriceFormatterComponent} from "../../../../Commans/Accounting/PriceFormatterComponent";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const HandleWebsiteUpgradeDetails = () => {
    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const[IsShowSpinner,setIsShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(false)
    const [IsOpen,setIsOpen] = useState(false);
    const [errors,setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({upgradeTypes:[],currencies:[],discountTypes:{}});

    const Refresh=()=>{
        setRefresh(prevState => !prevState)
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }


    const handleInitialData=(currencies,upgradeTypes,discounts)=>{
        const currencyData = convertSelectDataHandler(currencies);
        const upgradeTypeData = convertSelectDataHandler(upgradeTypes);
        const discountData = convertSelectDataHandler(discounts);
        return {currencyData,upgradeTypeData,discountData}
    }
    const fetchData = async () => {
        try {
            showSpinner();

            const [response, responseCurrency,responseDiscount] = await Promise.all([
                GET(`/Enumerations/EnUpgradeType`, {}, 'IEnumerable'),
                GET(`/Upgrades/ActiveCurrency`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnDiscountOrCommissionType`, {}, 'IEnumerable'),
            ]);

            if (
                response.status === 200 &&
                responseCurrency.status === 200
            ) {
                const { currencyData,upgradeTypeData,discountData} = handleInitialData(responseCurrency.data,response.data,responseDiscount.data);

                setInitialSource({
                    upgradeTypes: upgradeTypeData,
                    currencies: currencyData,
                    discountTypes: discountData
                });
                hideSpinner();
            } else {
                handleApiError(response, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };

    const handleGetUpgradeType=(type)=>{
        const upgrade =  initialSource.upgradeTypes.find((item)=>item.value === type);
        if(upgrade){
            return upgrade.label;
        }
        return  ''
    }
    const handleGetCurrency=(currencyType)=>{
        const currency =  initialSource.currencies.find((item)=>item.value === currencyType);
        if(currency){
            return currency.label;
        }
        return ''
    }
    const handleGetDiscountType=(type)=>{
        const discount =  initialSource.discountTypes.find((item)=>item.value === type);
        if(discount){
            return discount.label;
        }
        return ''
    }

    const renderProperties = (properties) => {
        return Object.entries(properties).map(([key, value]) => (
            <div key={key}>
                <strong>{key}:</strong> {value} MB
            </div>
        ));
    };

useEffect(()=>{
   fetchData()
},[])










const {rowData,handleCurrentPage,handleNextPage,handleFromDate,handleToDate,handlePageSizeCount,handleSearch,handlePreviousPage,handleResetFilters, handleToggleFilter,pagination} = useWebsiteUpgradeDetailGrid(showSpinner,hideSpinner,refresh,Refresh)


    const events = [
        { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
        { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
        { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
        { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
    ];


    const customizedMarker = (item) => {
        return (
            <span className="flex size-10 items-center justify-center text-white border dark:border-themeDarkColorLine bg-white dark:bg-themeDarkPostInput z-1 box">
                <HPrimeIcon icon={item.icon}></HPrimeIcon>
            </span>
        );
    };



    const customizedContent = (item) => {
        return (
            <Card title={()=>handleGetUpgradeType(item.upgradeType)}>
                <div className={"space-y-3"}>
                    <div className={"grid p-3 grid-cols-1 rounded-md border dark:border-themeDarkColorLine shadow md:grid-cols-2 gap-2 post-input dark:bg-themeDarkPostInput"}>
                        <div className={"flex items-center gap-2"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("Price")}:</p>
                            <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>{<PriceFormatterComponent price={item.price.amount}/>} {handleGetCurrency(item.price.currency)}</p>
                        </div>
                        <div className={"flex items-center gap-2"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("CreationDate")}:</p>
                            <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}><GridDateFomartter date={item.creationDate} hasTime={true} /></p>
                        </div>
                        <div className={"flex items-center gap-2"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("discount")}:</p>
                            <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>{<PriceFormatterComponent price={item.discount.amount}/>} {handleGetCurrency(item.price.currency)}</p>
                        </div>
                        <div className={"flex items-center gap-2"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("DiscountType")}:</p>
                            <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>{handleGetDiscountType(item.discount.discountType)}</p>
                        </div>
                        <div className={"flex items-center gap-2"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("FromDate")}:</p>
                            <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}><GridDateFomartter date={item.discount.from} hasTime={true} /></p>
                        </div>

                        <div className={"flex items-center gap-2"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("ToDate")}:</p>
                            <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}><GridDateFomartter date={item.discount.to} hasTime={true} /></p>
                        </div>

                    </div>
                    <div className={"rounded-md p-3 text-xs border dark:border-themeDarkColorLine shadow post-input dark:bg-themeDarkPostInput"}>
                        <p className={"text-xs dark:text-gray-300"}>
                            {renderProperties(item.properties)}
                        </p>
                    </div>
                    <div className={"rounded-md p-3 text-xs border dark:border-themeDarkColorLine shadow post-input dark:bg-themeDarkPostInput"}>
                        <p className={"text-xs dark:text-gray-300"}>
                            {item.description}
                        </p>
                    </div>

                </div>
            </Card>
        );
    };

    return (
        <>
            <div class=" space-y-4">

                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 ">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo('/Admin/Website/List/Payment/Upgrade')}
                                            title={t("Upgrade")}
                                            tooltipTitle={t("Upgrade")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo('/Admin/Website/List')}
                                            title={t("list")}
                                            tooltipTitle={t("WebsiteList")}
                                            icon={"pi-list"}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                 <div className={"relative space-y-5"}>
                     {
                         IsShowSpinner?
                             <div className=" huploader_loading" >
                                 <div className="relative">
                                     <LoadingHarmony/>
                                 </div>
                             </div>
                             :
                             null
                     }

                     <div class={"box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5"}>
                         <fieldset className="border dark:border-themeDarkColorLine post-input post-input-design dark:bg-themeDarkPostInput p-5  mt-3">
                             <legend className="border dark:border-themeDarkColorLine p-1 px-3 flex gap-1 items-center bg-themeSecondary dark:bg-themeDarkPostInput text-white rounded-md text-xs dark:text-gray-300">{t("WebsiteInformation")}</legend>
                             <div className="grid grid-cols-22 md:grid-cols-3 gap-5">
                                 <div class="flex gap-2 items-center">
                                     <label className="dark:text-gray-300 text-xs">{t("FirstNameAndLastName")}:</label>
                                     <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1"></h2>
                                 </div>
                                 <div class="flex gap-2 items-center ">
                                     <label className="dark:text-gray-300 text-xs">{t("NationalEconomicCode")}:</label>
                                     <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1"></h2>
                                 </div>
                                 <div class=" flex gap-2 items-center ">
                                     <label className=" dark:text-gray-300 text-xs">{t("PhoneNumber")}:</label>
                                     <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1"></h2>
                                 </div>
                                 <div class=" flex gap-2 items-center ">
                                     <label className=" dark:text-gray-300 text-xs">{t("MobilePhone")}:</label>
                                     <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1"></h2>
                                 </div>
                                 <div class="flex gap-2 items-center">
                                     <label className=" dark:text-gray-300 text-xs">{t("Address")}:</label>
                                     <h2 className="text-themeInformation dark:text-themeDarkInformation text-xs line-clamp-1"></h2>
                                 </div>
                             </div>
                         </fieldset>

                     </div>

                     <div className={"space-y-2 H-body-ag-grid relative"} >

                         <div>
                             <Timeline value={rowData} align="alternate" className="customized-timeline h-timline" marker={customizedMarker} content={customizedContent} />
                         </div>

                         <div className="flex items-center justify-between flex-wrap gap-2">
                             <div className="flex gap-2 flex-wrap">
                                 <div className="p-2 rounded bg-themeSecondary text-white text-xs">{t("ShippingCost")}: </div>
                                 <div className="p-2 rounded bg-themeInformation dark:bg-themeDarkInformation text-white text-xs">{t("TotalPurchaseAmount")}: </div>
                             </div>
                             <div className="p-2 rounded bg-themeSuccess text-white text-xs">{t("PaidAmount")}: </div>
                         </div>

                         <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />

                     </div>
                 </div>

            </div>
            <ToastContainer/>

        </>
    );
};
