import React from "react";
import {useTranslation} from "react-i18next";

export const FilterType=({item,index,siteMap,handleCheckValue})=>{
    const { t } = useTranslation();

    return (<>
        <div>
            <label htmlFor={index+10} className={"block"} key={index}>
                <input
                    type="radio"
                    name="slugFilterType"
                    className="input-BackUp hidden"
                    id={index+10}
                    value={item.value}
                    checked={siteMap.slugFilterType === item.value}
                    onChange={()=>handleCheckValue(item.value,'slugFilterType')}
                />
                <div className="flex items-center gap-2 p-2 post-input dark:bg-themeDarkPostInput post-input-design cursor-pointer connect-BackUp dark:border-themeDarkColorLine">
                    <div className="flex items-center gap-1">
                        <span className="input-BackUp-span"></span>
                    </div>
                    <div>
                        <h2 className="text-xs dark:text-gray-300">{item.label}</h2>
                    </div>
                </div>
            </label>
        </div>

    </>)
}