import {useTranslation} from "react-i18next";

export const ButtonSubmitAndCancel = ({ onCancelClick, onRegisterClick,cancelText,registerText,type,StyleCancel,StyleRegister,ButtonStyleCancel,ButtonStyleRegister,GroupStyle,showCancelButton, showRegisterButton,}) => {
    const { t } = useTranslation();

    return (
        <>

            <div className={`flex justify-center gap-3 lg:justify-end post-input dark:bg-themeDarkPostInput post-input-design p-3 ${GroupStyle}`}>
                {!showCancelButton && ( // اگر شرط نمایش دکمه کنسل برقرار باشد
                    <button
                        type={type ? type : "button"}
                        onClick={onCancelClick}
                        className={`button hbtn-cancel ${StyleCancel} ${!ButtonStyleCancel ? 'w-24 bg-themeDanger text-white' : ButtonStyleCancel}`}
                    >
                        {cancelText ? cancelText : t("Cancel")}
                    </button>
                )}
                {!showRegisterButton && ( // اگر شرط نمایش دکمه ثبت نام برقرار باشد
                    <button
                        type={type ? type : "submit"}
                        onClick={onRegisterClick}
                        className={`button hbtn-save ${StyleRegister} ${!ButtonStyleRegister ? 'w-24 text-white bg-themeInformation dark:bg-themeDarkInformation' : ButtonStyleRegister}`}
                    >
                        {registerText ? registerText : t("register")}
                    </button>
                )}
            </div>
        </>
    );
};