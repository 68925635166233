export const CreateConvertPicture=(picture,files)=>{
    const data = {
        logoHeader: picture.logoHeader ?? null,
        logoFooter: picture.logoFooter ?? null,
        icon: picture.favicon ?? null,
        defaultImage: picture.defaultImage ?? null,
        man: picture.man ?? null,
        woman: picture.woman??null,
        none: picture.none,
        files:files??null,
        concurrencyStamp:picture.concurrencyStamp??null
    }
    return data;
}

export const EditFormDataPictureSettings=(obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('LogoHeaderFile',obj.logoHeader);
    appendIfNotNull('LogoFooterFile',obj.logoFooter);
    appendIfNotNull('Icon',obj.icon);
    appendIfNotNull('DefaultImage',obj.defaultImage);
    appendIfNotNull('man',obj.man);
    appendIfNotNull('woman',obj.woman);
    appendIfNotNull('none',obj.none);
    appendIfNotNull('concurrencyStamp',obj.concurrencyStamp);


    if (obj.files) {
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file === null  &&  obj.files[i].bannerId === null){

            }
            else{
                if(obj.files[i].file !== null && obj.files[i].bannerId !== null  ){
                    formData.append(`Sliders[${i}].FilePath`,obj.files[i].filePath);
                }
                formData.append(`Sliders[${i}].file`,obj.files[i].file);
                formData.append(`Sliders[${i}].caption`, obj.files[i]?.caption );
                formData.append(`Sliders[${i}].referenceUrl`, obj.files[i]?.referenceUrl );
                formData.append(`Sliders[${i}].order`, obj.files[i]?.order );
                formData.append(`Sliders[${i}].bannerId`, obj.files[i].bannerId );
            }
        }
    }


    // if (obj.files) {
    //     for (let i = 0; i < obj.files.length; i++) {
    //         if (obj.files[i].file !== null && obj.files[i].file !== undefined) {
    //             if (obj.files[i].bannerId !== null) {
    //                 formData.append(`Sliders[${i}].FilePath`, obj.files[i].filePath);
    //             }
    //             formData.append(`Sliders[${i}].file`, obj.files[i].file);
    //         }
    //         if (obj.files[i].caption !== null && obj.files[i].caption !== undefined) {
    //             formData.append(`Sliders[${i}].caption`, obj.files[i].caption);
    //         }
    //         if (obj.files[i].referenceUrl !== null && obj.files[i].referenceUrl !== undefined) {
    //             formData.append(`Sliders[${i}].referenceUrl`, obj.files[i].referenceUrl);
    //         }
    //         if (obj.files[i].order !== null && obj.files[i].order !== undefined) {
    //             formData.append(`Sliders[${i}].order`, obj.files[i].order);
    //         }
    //         if (obj.files[i].bannerId !== null && obj.files[i].bannerId !== undefined) {
    //             formData.append(`Sliders[${i}].bannerId`, obj.files[i].bannerId);
    //         }
    //     }
    // }

    return formData
}