import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";


import {Button} from "primereact/button";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const HandleContentCreationMap=()=>{

    const { t } = useTranslation();

    const [modal, setModal] = useState({ Keywords: false, });

    const KeywordsOnHide = () => {
        setModal((prevState) => ({ ...prevState, Keywords: !modal.Keywords }));
    };

    const [isMenuOpen,setMenuOpen] = useState(false);
    const [userId,setUserId] = useState('');

    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }


    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("Title"),
            cellRenderer:parameters=>
                <>
                   masoud
                </>
        },

        { headerName: t("Count"),

            cellRenderer:parameters=>
                   <>3</>
        },
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button className="text-themeSuccess" ><HPrimeIcon size={20} icon={'edit-3'} /></button>
                    <button className="text-themeDanger" ><HPrimeIcon size={20} icon={'trash-2'}  /></button>
                </div>
            }
        },

    ];

    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };


    const redirectTo = useNavigate();




    return(<>
        <div class=" space-y-5">
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                            <InputText type={"search"} className={" input w-full search"} placeholder={t("search")} />
                        </div>
                    <ButtonSearchList
                    />
                    </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => setModal({ Keywords: true })}
                                        tooltipTitle={t("Keywords")}
                                        tooltipId={2}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/SEO/GoogleSiteMap`)}
                                        icon={"pie-chart"}
                                        title={t("introductionToGoogle")}
                                        tooltipTitle={t("introductionToGoogle")}
                                        tooltipId={3}
                                    />

                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/SEO/Redirect/Add`)}
                                        icon={"external-link"}
                                        title={t("redirect")}
                                        tooltipTitle={t("redirect")}
                                        tooltipId={2}
                                    />




                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />

            </div>
            <div>
                <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                           enableRtl={true}
                           pagination={true}
                           getRowHeight={getRowHeight}
                           onGridReady={onGridReady}
                           quickFilterText={quickFilterText}
                           style={{ width: '100%', height: '100%' ,position:'relative' }}
                />
            </div>
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                            <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                <span className="bg_Pdf"></span>
                                <h2>خروجی (Pdf)</h2>
                            </Button>
                            <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                <span className="bg_Excel"></span>
                                <h2>خروجی (Exel)</h2>
                            </Button>
                            <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                <span className="bg_Word"></span>
                                <h2>خروجی (Word)</h2>
                            </Button>
                        </div>
            </div>
        </div>
        <FantasyModal openModal={modal.Keywords}  closeModal={KeywordsOnHide} title={t("Keywords")}>
            <div className="space-y-5">
                <div class="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                    <InputText type={"text"} className={"input w-full"} />
                </div>
                <button className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
            </div>
        </FantasyModal>

       </>)
}