export function handleAddExchangeRate(obj){
    const data={
        currency:obj.currency === null || obj.currency === undefined ?null:obj.currency.value,
        rateOnDollar:obj.rateOnDollar === null || obj.rateOnDollar === undefined?null:obj.rateOnDollar,
        symbol:obj.symbol === '' || obj.symbol === null?null:obj.symbol,
        fee:obj.fee === null || obj.fee === undefined?0:obj.fee,
        feeCurrency:obj.feeCurrency === null || obj.feeCurrency === undefined?null:obj.feeCurrency.value
    }
    return data;
}
export function handleEditExchangeRate(obj){
    const data={
        exchangeMoneyId:obj.exchangeMoneyId,
        currency:obj.currency === null || obj.currency === undefined ?null:obj.currency.value,
        rateOnDollar:obj.rateOnDollar === null || obj.rateOnDollar === undefined?null:obj.rateOnDollar,
        symbol:obj.symbol === '' || obj.symbol === null?null:obj.symbol,
        fee:obj.fee === null || obj.fee === undefined?0:obj.fee,
        feeCurrency:obj.feeCurrency === null || obj.feeCurrency === undefined?null:obj.feeCurrency.value
    }
    return data;
}