import React from 'react';

const NumberIcon = ({sizeStyle,fillStyle}) => (

    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="bold" className={`${sizeStyle ? sizeStyle:"w-4 h-4"} ${fillStyle ? fillStyle:""} dark:text-gray-300 `} role="img">
        <g id="SVGRepo_iconCarrier">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-382.000000, -7721.000000)" >
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path fill="currentColor" d="M332,7571 L336,7571 L336,7567 L332,7567 L332,7571 Z M342,7567 L342,7565 L338,7565 L338,7561 L336,7561 L336,7565 L332,7565 L332,7561 L330,7561 L330,7565 L326,7565 L326,7567 L330,7567 L330,7571 L326,7571 L326,7573 L330,7573 L330,7577 L332,7577 L332,7573 L336,7573 L336,7577 L338,7577 L338,7573 L342,7573 L342,7571 L338,7571 L338,7567 L342,7567 Z"> </path>
                    </g>
                </g>
            </g>
        </g>
    </svg>


);

export default NumberIcon;
