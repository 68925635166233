import React, {useState} from "react";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import TextArea from "../../Commans/UIParts/TextArea";
import {useTranslation} from "react-i18next";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {Accordion, AccordionTab} from "primereact/accordion";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import InstagramIcon from "./SocialIcon/InstagramIcon";
import TelegramIcon from "./SocialIcon/TelegramIcon";
import FacebookIcon from "./SocialIcon/FacebookIcon";
import {InputTextarea} from "primereact/inputtextarea";
import MagicIcon from "./SocialIcon/MagicIcon";

import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {Tooltip} from "react-tooltip";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandleMessengerComments = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [IsConnected,setIsConnected] = useState(false);
    const [IsSendMessage,setIsSendMessage] = useState(false);


     const [IsOpenFileManager, setOpenFileManager] = useState(false);
     const [IsOpenAutomaticCaption, setOpenAutomaticCaption] = useState(false);
     const [IsOpenCaption, setOpenCaption] = useState(false);
     const [IsOpenGlassButton, setOpenGlassButton] = useState(false);
     const [IsOpenSurvey, setOpenSurvey] = useState(false);
     const [IsOpenCreateCaption, setOpenCreateCaption] = useState(false);


    const openFileManager=()=>{
        setOpenFileManager(true)
    }

    const closeFileManager=()=>{
        setOpenFileManager(false)
    }



    const openAutomaticCaption=()=>{
        setOpenAutomaticCaption(true)
    }

    const closeCreateCaption=()=>{
        setOpenCreateCaption(false)
    }

    const openCreateCaption=()=>{
        setOpenCreateCaption(true)
    }


    const closeGlassButton=()=>{
        setOpenGlassButton(false)
    }
    const openGlassButton=()=>{
        setOpenGlassButton(true)
    }


    const closeCaption=()=>{
        setOpenCaption(false)
    }

    const openCaption=()=>{
        setOpenCaption(true)
    }

    const closeAutomaticCaption=()=>{
        setOpenAutomaticCaption(false)
    }

    const openSurvey=()=>{
        setOpenSurvey(true)
    }

    const closeSurvey=()=>{
        setOpenSurvey(false)
    }


    const handleOpenConnect=()=>{
        setIsConnected(true);
    }
    const handleCloseConnect=()=>{
        setIsConnected(false);
    }
    const handleOpenSendMessage=()=>{
        setIsSendMessage(true);
    }
    const handleCloseSendMessage=()=>{
        setIsSendMessage(false);
    }



    // const socialMediaData = [
    //     { iconClass: "bg_facebook", title: "Facebook", subtitle: "Page or Group", connectText: "Connect", modalHandler: () => handleOpenSendMessage(),connectHandler:()=>handleOpenConnect(), },
    //     { iconClass: "bg_instagram", title: t("Instagram"), subtitle: "Account", connectText: "Connect", modalHandler: () => handleOpenSendMessage(), connectHandler:()=>handleOpenConnect(), },
    //     { iconClass: "bg_telegram", title: t("Telegramchannel"), subtitle: "Account", connectText: "Connect", modalHandler: () =>handleOpenSendMessage(),connectHandler:()=>handleOpenConnect(),  },
    //     { iconClass: "bg_telegram", title: t("Telegramgrouop"), subtitle: "Account", connectText: "Connect", modalHandler: () =>handleOpenSendMessage(),connectHandler:()=>handleOpenConnect(),  },
    //     { iconClass: "bg_youtube", title: "YouTube", subtitle: "Channel", connectText: "Connect", modalHandler: () => handleOpenSendMessage(),connectHandler:()=>handleOpenConnect(),        },
    //     { iconClass: "bg_whatsapp", title: "WhatsApp", subtitle: "Account", connectText: "Connect", modalHandler: () => handleOpenSendMessage(),connectHandler:()=>handleOpenConnect(),      },
    //     { iconClass: "bg_linkedin", title: "LinkedIn", subtitle: "Page or Profile", connectText: "Connect", modalHandler: () => handleOpenSendMessage(), connectHandler:()=>handleOpenConnect(),},
    //     { iconClass: "bg_twitter", title: "Twitter", subtitle: "Profile", connectText: "Connect", modalHandler: () => handleOpenSendMessage(), connectHandler:()=>handleOpenConnect(),  },
    // ]





    return (
        <>
            <div className="space-y-5">

                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByNameCode")}</label>
                            <InputText  type="text"  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("AccountType")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}</label>
                            <DateTimePicker  format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}</label>
                            <DateTimePicker  format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Accounts")}
                                            icon={"pi-link"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/List")}
                                            tooltipTitle={t("Accounts")}

                                        />

                                        <ButtonPlus
                                            title={t("list")}
                                            icon={"pi-list"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublicationList")}
                                            tooltipTitle={t("PublicationList")}

                                        />
                                        <ButtonPlus
                                            title={t("Publishing")}
                                            icon={"pi-share-alt"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublishingContent")}
                                            tooltipTitle={t("PublishingContent")}

                                        />

                                        <ButtonPlus
                                            title={t("comments")}
                                            icon={"pi-comments"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/Comments")}
                                            tooltipTitle={t("comments")}
                                            notification={true}
                                        />

                                        <ButtonPlus
                                            title={t("Draft")}
                                            icon={"pi-file-edit"}
                                            onClick={()=>redirectTo("/SocialMarketing/Draft")}
                                            tooltipTitle={t("Draft")}

                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("Read")}</lable>
                                            <div>
                                                <input  className="input input--switch "  type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("UnRead")}</lable>
                                            <div>
                                                <input  className="input input--switch "  type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("Answered")}</lable>
                                            <div>
                                                <input  className="input input--switch"  type="checkbox"/>
                                            </div>
                                        </div>

                                    </>
                                }
                    />

                </div>

                <div class="grid grid-cols-12 gap-3 ">
                    <div className={"col-span-12 lg:col-span-3 xl:col-span-2 p-2 space-y-2 bg-white dark:bg-themeDarkSalt700 max-h-screen"}>
                        <div className={"overflow-x-auto scrollbar"}>
                            <div className={"flex lg:flex-col gap-2"}>
                                <div className="messenger_accounting post-input rounded border dark:border-themeDarkColorLine shadow overflow-hidden dark:bg-themeDarkPostInput">
                                    <label htmlFor={"content"}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-input hidden"
                                            id="content"
                                        />
                                        <div className="checkbox-tile checkbox-wrapper size-full flex gap-1 px-1 py-4 items-center">
                                            <div className={"relative"}>
                                                <div className="size-14 flex-shrink-0 border border-themeInformation rounded-full bg-white dark:bg-themeDarkSalt700 overflow-hidden">
                                                    <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
                                                </div>
                                                <div className={"absolute bottom-0 end-0 rounded-full border border-themeInformation p-1 bg-gray-200 dark:bg-themeDarkPostInput"}>
                                                    <InstagramIcon
                                                        sizeStyle={"size-5"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-1 space-y-1 checkbox-label">
                                                <h2 className="text-xs line-clamp-1 ">Harmony ErpNews</h2>
                                                <p className={"text-xs line-clamp-1 font-light"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam cum cumque delectus dolores error fuga harum incidunt maiores necessitatibus nihil officia optio possimus quis quo quos repellendus reprehenderit, voluptatibus?</p>
                                                <span className={"text-xs font-light"}>21.10.10</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="messenger_accounting post-input post-input-design overflow-hidden dark:bg-themeDarkPostInput">
                                    <label htmlFor={"content1"}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-input hidden"
                                            id="content1"
                                        />
                                        <div className="checkbox-tile checkbox-wrapper size-full flex gap-1 px-1 py-4 items-center">
                                            <div className={"relative"}>
                                                <div className="size-14 flex-shrink-0  border border-themeInformation rounded-full bg-white dark:bg-themeDarkSalt700 overflow-hidden">
                                                    <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
                                                </div>
                                                <div className={"absolute bottom-0 end-0 rounded-full border border-themeInformation p-1 bg-gray-200 dark:bg-themeDarkPostInput"}>
                                                    <TelegramIcon
                                                        sizeStyle={"size-5"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex-1 space-y-1 checkbox-label">
                                                <h2 className="text-xs line-clamp-1 ">Harmony ErpNews</h2>
                                                <p className={"text-xs line-clamp-1 font-light"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam cum cumque delectus dolores error fuga harum incidunt maiores necessitatibus nihil officia optio possimus quis quo quos repellendus reprehenderit, voluptatibus?</p>
                                                <span className={"text-xs font-light"}>21.10.10</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="messenger_accounting post-input rounded border dark:border-themeDarkColorLine shadow overflow-hidden dark:bg-themeDarkPostInput">
                                    <label htmlFor={"content2"}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-input hidden"
                                            id="content2"
                                        />
                                        <div className="checkbox-tile checkbox-wrapper size-full flex gap-1 px-1 py-4 items-center">
                                            <div className={"relative"}>
                                                <div className="size-14 flex-shrink-0  border border-themeInformation rounded-full bg-white dark:bg-themeDarkSalt700 overflow-hidden">
                                                    <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
                                                </div>
                                                <div className={"absolute bottom-0 end-0 rounded-full border border-themeInformation p-1 bg-gray-200 dark:bg-themeDarkPostInput"}>
                                                    <FacebookIcon
                                                        sizeStyle={"w-5 h-5"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex-1 space-y-1 checkbox-label">
                                                <h2 className="text-xs line-clamp-1 ">Harmony ErpNews</h2>
                                                <p className={"text-xs line-clamp-1 font-light"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam cum cumque delectus dolores error fuga harum incidunt maiores necessitatibus nihil officia optio possimus quis quo quos repellendus reprehenderit, voluptatibus?</p>
                                                <span className={"text-xs font-light"}>21.10.10</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-span-12 lg:col-span-9 xl:col-span-10 p-2 bg-white dark:bg-themeDarkSalt700 "}>
                        <div className={"chat__box box overflow-hidden rounded"}>
                            <div className={"flex h-full flex-col justify-between"}>
                                <div class="border-b-2 p-2 dark:border-themeDarkColorLine ">
                                    <div className={"post-input flex justify-between gap-10 overflow-x-auto rounded-md p-2 shadow-xl dark:bg-themeDarkPostInput"}>
                                        <div className={"flex gap-2 "}>
                                            <div class="relative hidden md:block size-16 flex-shrink-0 rounded-md bg-white shadow-md dark:border-themeDarkInformation">
                                                <img className="size-full object-cover" src="/Assets/Image/Avatar/man.svg" alt={"harmony"}/>
                                            </div>
                                            <div className="flex flex-1 flex-col justify-center gap-y-2">
                                                <div className={"flex items-center gap-1"}>
                                                    <h2 className={"text-nowrap dark:text-gray-300 "}>{t("comments")}:</h2>
                                                    <p class={"line-clamp-1 text-xs text-themeInformation dark:text-themeDarkInformation "}>این پست جهت رفع</p>
                                                </div>
                                                <div className={"flex items-center gap-3"}>
                                                    <div class="flex items-center gap-1">
                                                        <h2 className={"text-nowrap text-xs  dark:text-gray-300"}>{t("PublicationDate")}:</h2>
                                                        <p className={"text-nowrap text-xs font-light dark:text-themeDarkInformation"}>565656</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"relative overflow-hidden"}>
                                    <div className={"chat-user flex h-screen flex-col gap-y-8 overflow-y-scroll overflow-x-hidden p-2 duration-500 "}>
                                        <div className="send-message">
                                            <div className={"space-y-2"}>
                                                <div class="chat__box__text-box flex">
                                                    <div class="m-2 my-auto  size-10 ">
                                                        <img className="size-full rounded-full border object-cover shadow-xl" src={"/Assets/Image/Avatar/man.svg"} title={"harmony"}/>
                                                    </div>
                                                    <div class="post-input send__box__message relative box-border border shadow-xl dark:border-themeDarkColorLine dark:bg-themeDarkPostInput">
                                                        <div className={"space-y-1 p-2"}>
                                                            <div>
                                                                <h2 className={"text-xs leading-7 text-gray-700 dark:text-gray-300"}> masoud_ahmadiiii</h2>
                                                            </div>
                                                            <div>
                                                                <p className="text-xs leading-7 text-gray-700 dark:text-gray-300 socila_text-blur">مسعود احمدی محمدآبادی</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={"reply-send-message m-2 my-auto tooltip_class_reply"}>
                                                        <a href={'#reply'} className=" arrow-reply-message size-7 block"></a>
                                                        <Tooltip anchorSelect={".tooltip_class_reply"} content={t("Replytothismessage")} />
                                                    </div>
                                                </div>
                                                <div className={"flex gap-2 items-center flex-wrap"}>
                                                     <p className={"send-time-message w-max text-black dark:text-gray-300 contents"}>
                                                        {<GridDateFomartter hasTime={true} />}
                                                     </p>
                                                    <button type={"button"}>
                                                        <HPrimeIconSmall icon={"pi-eye"} className={"dark:text-gray-300"}/>
                                                    </button>
                                                    <button type={"button"}>
                                                        <HPrimeIconSmall icon={"pi-trash"} className={"text-themeDanger"} />
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="received-message">
                                            <div className={"space-y-2"}>
                                                <div class="chat__box__text-box flex">
                                                    <div className={"received-reply-send-message tooltip_class_received m-2 my-auto"}>
                                                        <a href={'#reply'} className="block arrow-reply-message size-7"></a>
                                                        <Tooltip anchorSelect={".tooltip_class_received"} content={t("Replytothismessage")} />
                                                    </div>
                                                    <div class="post-input received__box__message relative box-border border shadow-xl  dark:border-themeDarkColorLine dark:bg-themeDarkSalt700">
                                                        <div className={"space-y-2 p-2"}>
                                                            <div>
                                                                <h2 className={"text-xs leading-7 text-gray-700 dark:text-gray-300"}> masoud_ahmadiiii</h2>
                                                            </div>
                                                            <div>
                                                                <p className="text-xs leading-7 text-gray-700 dark:text-gray-300">مسعود احمدی محمدآبادی</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="m-2 my-auto hidden size-10 flex-none sm:block">
                                                        <img className="size-full rounded-full border object-cover shadow-xl shadow-black" src={"/Assets/Image/Avatar/man.svg"} title={"harmony"}/>
                                                    </div>
                                                </div>

                                                <div className={"received-time-message"}>
                                                    <button type={"button"}>
                                                        <HPrimeIconSmall
                                                            icon={"pi-trash"}
                                                            iconClass={"mx-2"}
                                                            sizeIcon={"t"}
                                                        />
                                                    </button>
                                                    <span>{<GridDateFomartter hasTime={true} />}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={'reply'} className={"reply-all-message show-reply items-center bg-white dark:bg-themeDarkSalt700 flex px-2 gap-2"}>
                                        <button  type={"button"} className={"flex-shrink-0"}>
                                            <HPrimeIconSmall
                                            icon={"pi-times"}
                                            iconClass={"text-themeDanger"}
                                            />
                                        </button>
                                        <div className={"flex-1"}>
                                            <a href={""} className=" block reply-message rounded-e border-s-2 border-themeInformation dark:border-themeDarkInformation bg-themeReplayMessageSend p-2">
                                                <p className="line-clamp-1 text-xs">789</p>
                                            </a>
                                            <a href="#" className={"reply-message block rounded-s border-e-2 border-themeInformation bg-themeReplayMessageReceived p-2"}>
                                                <p className={"line-clamp-1 text-xs"}>321</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="border-t-2 dark:border-themeDarkColorLine p-2 ">
                                        <div className={"flex flex-col items-center gap-5 rounded-md bg-white dark:bg-themeDarkSalt700 p-2 md:flex-row"}>
                                            <div className={"w-full flex-1"}>
                                                <DisplayError />
                                                <textarea
                                                    rows={3}
                                                    name={"message"}
                                                    cols={30}
                                                    className={"input w-full text-xs"}
                                                    placeholder={t("Enteryourmessage")}
                                                />
                                            </div>

                                            <div className={"flex items-center gap-3"}>
                                                <button className="button flex-shrink-0 bg-themeInformation dark:bg-themeDarkInformation ">
                                                    <MagicIcon
                                                        sizeStyle={"size-6"}
                                                        fillStyle={"text-white"}
                                                    />
                                                </button>
                                                <button  className={"button rounded bg-themeSecondary text-white dark:bg-themeDarkInformation"}>
                                                    <HPrimeIcon
                                                    icon={"pi-send"}
                                                    />
                                                </button>
                                            </div>

                                        </div>
                                        {/*<textarea onInput={(e)=> handleValue(e)} name={'message'} value={ticket.message} className="chat__box__input input w-full h-16 resize-none border-transparent post-input px-5 py-3 focus:shadow-none" rows="1" placeholder="پیام خود را وارد کنید..."></textarea>*/}
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                </div>
            </div>

            <FantasyModal openModal={IsOpenFileManager} closeModal={closeFileManager} title={t("FileManagement")} customeClassName={"modal-xl"}>
            </FantasyModal>
            <FantasyModal openModal={IsOpenAutomaticCaption} closeModal={closeAutomaticCaption} title={t("AutomaticCaption")} >
                <div className={"space-y-5"}>
                    <div className={"space-y-3"}>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>{t("Language")}</label>
                                <Select
                                    isMulti={false}
                                    isSearchable={true}
                                    placeholder={t("selection")}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>{t("Tone")}</label>
                                <Select
                                    isMulti={false}
                                    isSearchable={true}
                                    placeholder={t("selection")}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                        </div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Subject")}</label>
                            <InputTextarea name={"description"}  rows={5} cols={30} className={"input"} />
                        </div>
                    </div>


                    <div>
                        <button  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenCaption} closeModal={closeCaption} title={t("Captions")} >
                <div className={"space-y-5"}>
                    <div className={"max_height85vh space-y-3"}>

                        <div className={"relative flex p-2 items-center gap-2 box bg-white dark:bg-themeDarkSalt700"}>
                            <div className={"space-y-1 flex-1"}>
                                <span className={"text-xs dark:text-themeDarkInformation"}>مسعود احمدی</span>
                                <p className={"line-clamp-1 text-xs"}>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است</p>
                            </div>
                            <button type="button" className={"border dark:border-themeDarkColorLine shadow button bg-themeInformation"}>
                                <HPrimeIconSmall icon={"pi-trash"} className={"text-white"} />
                            </button>
                        </div>

                    </div>
                    <div>
                        <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} onClick={()=>openCreateCaption()}>{t("AddCaptions")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenCreateCaption} closeModal={closeCreateCaption} title={t("CreateCaption")} >
                <div className={"space-y-5"}>
                    <div className={"space-y-2"}>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Name")}</label>
                            <InputText  className={"input"} />
                        </div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Caption")}</label>
                            <InputTextarea name={"description"}  rows={5} cols={30} className={"input"} />
                        </div>
                    </div>
                    <div>
                        <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}>{t("Register")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenGlassButton} closeModal={closeGlassButton} title={t("AddTelegramGlassButton")} >
                <div className={"space-y-5"}>
                    <div className={"space-y-2"}>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Title")}</label>
                            <InputText  className={"input"} />
                        </div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Link")}</label>
                            <InputText  className={"input"} />
                        </div>
                    </div>
                    <div>
                        <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}>{t("Register")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenSurvey} closeModal={closeSurvey} title={t("CreatingASurvey")}>
                <div className={"space-y-5"}>
                    <div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("TitleSurvey")}</label>
                            <InputText className="input w-full"/>
                        </div>
                    </div>
                    <div className={"space-y-3"}>
                        <div className="flex gap-2 items-end">
                            <div className={"space-y-1 flex-1"}>
                                <InputText className="input w-full" placeholder={t("Response")}/>
                            </div>
                            <div className="flex-shrink-0 flex gap-2">
                                <button  type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                    <HPrimeIcon icon={"pi-plus"} />
                                </button>
                                <button type="button" className="button bg-themeDanger text-white">
                                    <HPrimeIcon icon={"pi-minus"} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsConnected}  closeModal={handleCloseConnect} title={t("AddAccount")}>
                <div class="space-y-2 ">
                    <div className={"space-y-2"}>
                        {/*facebook*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("ChooseYourAccountType")}</h2>
                        </div>
                        <div className={"space-y-2"}>
                            <label htmlFor="1" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="1"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("FacebookPage")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("FaceBookPageTitle")}</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="2" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="2"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("FacebookGroup")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("FaceBookGroupTitle")}</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={"space-y-2"}>
                        {/*Linkedin*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("ChooseYourAccountType")}</h2>
                        </div>
                        <div className={"space-y-2"}>
                            <label htmlFor="3" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="3"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("LinkedInPage")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("LinkedInTitle")}</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="4" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="4"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("LinkedInProfile")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("LinkedInProfileTitle")}</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={"space-y-2 rounded box bg-white dark:bg-themeDarkSalt700 p-2"}>
                        {/*instagram*/}
                        <div className={"space-y-2 "}>
                            <p className={"dark:text-gray-300 text-xs leading-7"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ex hic modi, odio quis voluptatum! Adipisci doloremque facere libero nihil.</p>
                        </div>
                        <div>
                            <img src="/Assets/Image/Design/Online-connection.svg" alt="Online-connection.svg"/>
                        </div>
                    </div>
                    <div className={"space-y-2 "}>
                        {/*telegram*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("DataSource")}</h2>
                        </div>
                        <div className={"rounded box bg-white dark:bg-themeDarkSalt700 p-2 space-y-2"}>
                            <p className={"dark:text-gray-300 text-xs leading-7"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquid architecto asperiores aut expedita maiores nam odit quasi voluptate. Odio!</p>
                            <div className={"grid grid-cols-1 sm:grid-cols-2 gap-3 "}>
                                <div>
                                    <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    {t("ID")}
                                </span>
                                        <InputText placeholder={t("ID")} className="input w-full" name={'fullName'} />
                                    </div>
                                </div>
                                <div>
                                    <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <HPrimeIconSmall icon={"pi-at"} />
                                </span>
                                        <InputText placeholder={t("Botfather")} className="input w-full" name={'fullName'} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ButtonSubmitAndCancel
                        registerText={t("Connect")}
                    />
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsSendMessage}  closeModal={handleCloseSendMessage} title={t("PostArticle")} customeClassName={"modal-x"}>
                <div class="relative">
                    <span className="bg_facebook block w-10 h-10 absolute top-0 end-2"></span>
                    <div className={"mt-2 space-y-3"}>
                        <div className={"space-y-2"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("MessageText")}</label>
                            <TextArea className={"input w-full"}  />
                        </div>
                        <Accordion className="bg-white rounded-md dark:bg-themeDarkSalt700">
                            <AccordionTab header={t("AttachmentFile")}>
                                <div className="space-y-4 max_height20">
                                    <div className="h-32 w-32">
                                        <UploadImage  removeName={'ThumbnailImagePath'} accept={'image/*'}  name={'ThumbnailImage'} />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <div className="post-input dark:bg-themeDarkSalt700 rounded-md border dark:border-themeDarkColorLine">
                            <div className="p-2 bg-gray-200 dark:bg-themeDarkPostInput rounded-t-md dark:text-gray-300">
                                <p className={"dark:text-gray-300"}>{t("CreationDateAndTime")}</p>
                            </div>
                            <div className={"grid grid-cols-1 md:grid-cols-3 lg:col-span-3 p-2 gap-3"}>
                                <div className={"space-y-1"}>
                                    <label className="dark:text-gray-300 text-xs">
                                        {t("SendingType")}
                                    </label>
                                    <Select
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                </div>
                                <div className="gap-y-2 flex flex-col">
                                    <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("SendingDate")}
                                    </label>
                                    <DateTimePicker isContainsTime={true}  format={'YYYY/MM/DD hh:mm:ss'} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                        </div>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
        </>
    );
};
