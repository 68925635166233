import React, {useState} from "react";

export const useCustomField=(setErrors)=>{
    const [filed,setField]=useState({communicationId:null,title:null,description:null,stateShow:true,order:0});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setField({...filed,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setField({...filed,[name]:value});
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));

    }
    const handleSelectValue=(data,name)=>{
        setField({ ...filed, [name]: data });
    }
    return {
        handleValue,
        handleNumberValue,
        handleSelectValue,
        filed,
        setField
    }
}