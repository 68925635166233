import React from "react";

import {useTranslation} from "react-i18next";
import {DELETE, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {useNavigate} from "react-router-dom";
import {handleCreateTemplatePurchase} from "./TemplatePhurchaseService";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";

export const ChooseTemplate=({item,initialSource,onApiError,setShowSpinner,Refresh,upgrade,setUpdate})=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();

    const GetTemplateTypeName = (templateType) => {
        if (!initialSource.types) {
            return;
        }
        const data = initialSource.types.find((item) => item.value === templateType);
        return data ? data.label : '';
    }

    const handleGetCurrencyType=(currency)=>{
        if(currency === null){
            return null;
        }
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === currency);
        return data ? data.label : '';
    }

        const handlePurchaseTemplate=async (templateId)=>{
            try {
            setShowSpinner(true)
              const data = handleCreateTemplatePurchase(upgrade,templateId);
             const response = await POST(`/Upgrades`,data,false);
            if(response.status === 200){
                redirectTo('/Profile/InvoiceRequest');
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            setShowSpinner(false)
        }
    }


    const Purchase = async (templateId)=>{


        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:'button text-white bg-themeSuccess',
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:t("AreYouSureAboutBuyingTheTemplate"),
            text: AlertSetting.TailWindButton.text,
            icon:'success',
            showCancelButton:t("CancellationPurchase"),
            confirmButtonText:t("TemplatePurchaseConfirmation"),
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                await handlePurchaseTemplate(templateId);
            }
            else if (result.dismiss === Swal.DismissReason.cancel)
            {
                return;
            }
        })
    }



    return (

        <>


            <div class="-intro-x template box bg-white rounded-md overflow-hidden dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine">
                <div class="template_preview H_aspect_15">
                    <img alt="harmony" className=" absolute inset-0 size-full" src={item.banner}/>
                    <div class="absolute bottom-5 start-3 text-white space-y-3 z-10">
                        <a href="src/Components/CMS/TemplateManagement#" className="blog__category px-2 py-1 text-xs rounded font-light">{GetTemplateTypeName(item.type)}</a>
                        <h2  className="font-light">
                            {item.name}
                        </h2>
                    </div>
                </div>
                <div class="p-3">
                    <h2 className="line-clamp-2 text-gray-600 dark:text-gray-300">{t("ChooseTemplateTitle")}</h2>
                </div>
                <del className={'text-themeDanger'}>{item.firstPrice !== null?<PriceFormatterComponent price={item.firstPrice.amount}/>:null} {handleGetCurrencyType(item.firstPrice !== null?item.firstPrice.currency:null)}</del>
                {item.finalPrice !== null ? <PriceFormatterComponent price={item.finalPrice.amount}/>:null} {handleGetCurrencyType(item.finalPrice !== null?item.finalPrice.currency:null)}
                <div class="flex items-center justify-between px-5 py-3 border-t border-gray-200 dark:border-themeDarkColorLine">
                    <div class="flex items-center justify-between gap-2">
                        <div class="size-8">
                            <img alt="harmony" className="rounded-full border border-themeInformation  object-cover size-full" src="/Assets/Image/template/bootstrap.png" title="bootstrap"/>
                        </div>
                        <div class="size-8">
                            <img alt="harmony" className="rounded-full border border-themeInformation  object-cover size-full" src="/Assets/Image/template/JS.png" title="Js"/>
                        </div>
                        <div class="size-8">
                            <img alt="harmony" className="rounded-full border border-themeInformation  object-cover size-full" src="/Assets/Image/template/jquery.png" title="jquery"/>
                        </div>
                        <div class="size-8">
                            <img alt="harmony" className="rounded-full border border-themeInformation  object-cover size-full" src="/Assets/Image/template/tailwindcss.svg" title="tailwindcss"/>
                        </div>
                    </div>
                </div>
                <div class=" p-2 border-t border-gray-200 dark:border-themeDarkColorLine">
                    <div class="flex items-center justify-center gap-2">
                        <button className="button bg-themeSecondary text-white w-full flex-1">
                            {t("Preview")}
                        </button>
                        {
                            item.finalPrice === null?
                                null:
                                <button onClick={()=>Purchase(item.id)} className="button bg-themeInformation text-white dark:bg-themeDarkInformation flex-1">
                                    {t("BuyATemplate")}
                                </button>
                        }

                    </div>
                </div>
            </div>


        </>


    )


}