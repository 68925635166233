import React, { useState, useEffect } from "react";

import { InputNumber } from "primereact/inputnumber";
import queryString from "query-string";
import { ToastContainer} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";

import Select from "react-select";
import {
    convertSelectDataHandler,
    handleCreateMetaGroupMetas,
    handleFilterContentMetas, handleValidateMetaData
} from "../Contents/ContentService";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {handleFailureMessage} from "../../../Services/Globals/Errors/NotificationService";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandleMetaCreateProperty = ({url}) => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const location = useLocation();
    const queryParams = queryString.parse(window.location.search);

    const [contentMeta, setContentMeta] = useState([]);
    const[spinner,setSpinner] = useState(false)
    const [options,setOptions] = useState({metaTypes:[]});

    const onApiError=()=>{
        setSpinner(false);
    }
    const handleFetchData= async()=>{
        try {
            setSpinner(true);
            const responseMetaGroup = await GET(`/MetaDataGroup/${queryParams.metaId}/MetaData`,{},'IEnumerable');
            const response = await GET('/MetaDataTypes/Selection',{},'IEnumerable');
            if(response.status === 200 && responseMetaGroup.status === 200){
                const types = convertSelectDataHandler(response.data);
                setOptions((prevState)=>({...prevState,metaTypes: types}));
                const filteredContentMetas = handleFilterContentMetas(responseMetaGroup.data,types);
                if(filteredContentMetas.length === 0){
                    setContentMeta([{id:new Date().getTime(),name:null,metaDataId:null,type:types[0],value:null,order:0}]);
                }
                else{
                    setContentMeta(filteredContentMetas);
                }

                setSpinner(false);
            }else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleAddRow=()=>{
        const newRow = {
            id: new Date().getTime(),
            type: options.metaTypes[0],
            name:null,
            value: null,
            order:0,
            metaDataId:null
        };
        setContentMeta(prevList => [...prevList, newRow]);
    }
    const handleRemoveRow = (id,metaDataId) => {
        if(contentMeta.length === 1){
            const newRow = {
                id: new Date().getTime(),
                metaDataId: null, // Use the default value from options
                type: options.metaTypes[0],
                value: null,
                name:null,
                order:0
            };
            setContentMeta(prevList => [newRow]);
            return;
        }
        setContentMeta((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.metaDataId === metaDataId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleChange = (id, field, value, metaDataId) => {
        setContentMeta((prevRelations) => {
            const updatedRelations = prevRelations.map((meta) => {
                if (
                    (metaDataId && meta.metaDataId === metaDataId) || (!metaDataId && id && meta.id === id)
                ) {
                    return { ...meta, [field]: value };
                } else {
                    return meta;
                }
            });
            return updatedRelations;
        });
    };



    useEffect(()=>{
        handleFetchData();
    },[])

    useEffect(() => {
        if (contentMeta.length === 0) {
            handleAddRow();
        }
    }, [contentMeta]);


    const handleSubmit= async()=>{
        try {
                setSpinner(true);
                var metas = handleCreateMetaGroupMetas(queryParams.metaId,contentMeta);
                    const response = await PUT(`/MetaDataGroup/SetMetaData`,metas,false);
                    if(response.status === 200){
                        setSpinner(false);

                        if(location.pathname === '/CMS/MetaData/Add/AddProperty'){
                            redirectTo('/CMS/MetaData/Add');
                        }
                        else{
                            redirectTo('/Shop/MetaData/Add');
                        }
                    }
                    else{
                        handleApiError(response,onApiError)
                    }
        }
        catch (e) {
            setSpinner(false);
        }
    }


    return (
        <div className="space-y-5">
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={

                                <>
                                    <ButtonPlus
                                        onClick={() => redirectTo("/CMS/Content/Add")}
                                        tooltipTitle={t("InsertingInformation")}
                                        title={t("insertion")}
                                        icon={"pi-pen-to-square"}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/Content/Group`)}
                                        icon={"pi-th-large"}
                                        title={t("grouping")}
                                        tooltipTitle={t("grouping")}
                                    />

                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/MetaData/Add`)}
                                        icon={"pi-star"}
                                        title={t("feature")}
                                        tooltipTitle={t("CreatingAFeature")}
                                    />

                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/Content/List`)}
                                        icon={"pi-list"}
                                        title={t("list")}
                                        tooltipTitle={t("ListOfInformation")}

                                    />

                                </>
                            }
                />

            </div>
            <div className="relative">
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className={"box bg-white dark:bg-themeDarkSalt700 p-3 space-y-3"}>
                    <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                        <h2 className={"dark:text-gray-300 text-xs"}>{t("features")}</h2>
                    </div>
                    <div className={"px-2 space-y-3 max_height85vh"}>
                        {contentMeta.map((row,index) => (
                            <div key={row.id} className="post-input post-input-design dark:bg-themeDarkPostInput p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                <div className="flex gap-2 items-center">
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("row")}</label>
                                        <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                                            {index+1}
                                        </div>
                                    </div>
                                    <div className="space-y-1 flex-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("Featurename")}</label>
                                        <input type="text" className="input w-full"
                                               value={row.name}
                                               onChange={(e) => handleChange(row.id, 'name', e.target.value,row.metaDataId)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("Featuretype")}</label>
                                    <Select
                                        options={options.metaTypes}
                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data) => handleChange(row.id, 'type', data,row.metaDataId)}
                                        placeholder={t("selection")}
                                        value={row.type}
                                        menuPosition={'fixed'}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("Featurevalue")}</label>
                                    <input type="text" className="input w-full"  value={row.value} onChange={(e) => handleChange(row.id, 'value', e.target.value,row.metaDataId)}/>
                                </div>
                                <div className={" flex gap-2 items-end"}>
                                    <div className="space-y-1 flex-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("Featurerank")}</label>
                                        <InputNumber className="input_number_plus" inputId={`display-order-${row.id}`} value={row.order} onValueChange={(e) =>
                                            handleChange(row.id,'order',e.value,row.metaDataId) }
                                                     placeholder={t("DisplayPriority")}
                                                     mode="decimal"
                                                     showButtons
                                                     min={0}
                                                     max={100}
                                        />

                                    </div>
                                    <div className="flex gap-2 flex-shrink-0">
                                        <button type="button"
                                                className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white"
                                                onClick={handleAddRow}>
                                            <HPrimeIcon icon={'pi-plus'}  />
                                        </button>

                                        <button type="button" className="button small_button bg-themeDanger text-white "
                                                onClick={() => handleRemoveRow(row.id,row.metaDataId)}>
                                            <HPrimeIcon icon={'pi-minus'}  />
                                        </button>

                                        {/*{row.id === relations.length && (*/}
                                        {/*   */}
                                        {/*)}*/}

                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>

            </div>
            <ButtonSubmitAndCancel
                onRegisterClick={()=>handleSubmit()}
            />
            <ToastContainer />
        </div>

    );
};