import React, {useState} from "react";

export const useDataHighliter=(setErrors)=>{
    const [setting, setSetting] = useState({
        webSiteMetaTagId:null,
        title: null,
        data:null,
        type:null,
        targetPage:null,
        isActive:true,
        message:null
    });

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }))
    }
    const handleSelectValue=(data,name)=>{
        setSetting({...setting,[name]: data});
    }



    return {
        setting,
        setSetting,
        handleValue,
        handleNumberValue,
        handleSelectValue,
    }
}