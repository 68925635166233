import React, {useEffect, useState} from "react";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {useLogError} from "../../GeneralManagement/LogError/useLogError";

export const useDeveloperDashboardWidget=( setIsLoading)=>{
   const [dashboard,setDashboard]=useState({ErrorList:[]})
    const {logError,setLogError,logId,handleSelectLogData,logData}=useLogError();

    const handleGetWidgets=(dashboard,widgetGroupName,widgetName)=>{
        var widgetGroup = dashboard.widgetGroups.find(group => group.name === widgetGroupName);
        if (!widgetGroup) {
            return null;
        }
        var widget = widgetGroup.widgets.find(widget => widget.name === widgetName);
        if (!widget || !widget.isDataLoaded) {
            return null;
        }
        return widget.data;
    }

    const GetDashboardData = async () => {
        setIsLoading(true)
        try {
            const [response,responseCurrency] = await Promise.all([
                GET(`/Dashboard`, { }, 'IEnumerable'),
                GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable')
            ]);
            if (response.status === 200 && responseCurrency.status === 200) {
                const errorList = handleGetWidgets(response.data.dashboard, 'Developer', 'ErrorList');
                setLogError(errorList);
                const lastError = errorList[errorList.length - 1];
                handleSelectLogData(lastError.id,lastError);

                setIsLoading(false);
            } else {
                setIsLoading(false)
            }
        } catch (e) {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        GetDashboardData();
    }, []);


    return {handleGetWidgets,setDashboard,dashboard,logData,logError,logId,handleSelectLogData}
}