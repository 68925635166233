import React, {useState} from "react";
export const useCustomShopGeneralSetting=(setErrors)=>{
    const [setting, setSetting] = useState({
        productCartLifeTime: {
            timeUnit: 0,
            value: 0
        },
        paymentLifeTime: {
            timeUnit: 0,
            value: 0
        },
        maximumPurchaseLimitation: {
            valueType: 0,
            value: 0
        },
        minimumPurchaseLimitation: {
            valueType: 0,
            value: 0
        },
        productReturnPeriod:{
            timeUnit: 0,
            value: 0
        },
        productCodeDigitCount: 0,
        freeTransportations: [
            {
                countryId:null,
                stateProvinceId: null,
                cityId: null
            }
        ],
        isTaxActive: true,
        tax: 0,
        toll: 0
    }
    );
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue = (data, name) => {
        setSetting(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                ...data
            }
        }));
    }
    const handleNumberValue = (value, name) => {
        setSetting(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                ...value
            }
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumber = (value,name)=>{
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleCheckBox = (value,name)=>{
        setSetting({...setting,[name]:value});
    }

    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency)
        {
            return '-'
        }
        return currency.label;
    }

    return {
        handleValue,
        handleNumberValue,
        setting,
        setSetting,
        handleSelectValue,
        handleNumber,
        handleCheckBox,
        handleGetCurrencyType
    }

}