import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";

import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const useReminderGrid=(setIsEdit,setShowSpinner,setDisable,setReminders,setIsOpen,hideSpinner,initialSource,Refresh,refresh)=>{


    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const queryParams = queryString.parse(window.location.search);
    const [rowData, setRowData] = useState([]);
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [coldId,setColId]=useState(null);



    const handleGetReminder = async(reminderId) => {
        try {
            setIsEdit(true);
            setShowSpinner(true)
            const response = await GET(`/UserNotes/${reminderId}`,{},'single');

            if(response.status === 200){
                if(response.data.type === 1 || response.data.type ===2){
                    setDisable(false)
                }else{
                    setDisable(true);
                }
                setReminders({
                    id: response.data.id,
                    description: response.data.description,
                    enSendingStates: response.data.enSendingStates ? response.data.enSendingStates.map((value) => initialSource.sendingStates.find((item) => item.value === value)) : null,
                    title: response.data.title,
                    type: initialSource.types? initialSource.types.find((item)=>item.value === response.data.type):null,
                    userId: initialSource.types? initialSource.users.find((item)=>item.value ===  response.data.userId):null,
                    message: response.data.message,
                    reminderDateUtc: response.data.reminderDateUtc,
                });
                setShowSpinner(false);
                setIsOpen(true)
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }catch (e) {
            hideSpinner()
        }
    };






















    const handleDeleteReminder = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setShowSpinner(true)
                    const response = await DELETE(`/UserNotes/${id}`,{headers: { 'Content-Type': 'application/json' }});
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh()
                        handleSuccessMessage(t("Reminderhasbeensuccessfullydeleted"))
                    }
                    else {
                        handleApiError(response,hideSpinner);
                    }
                }catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const GetSendingStates = (data)=>{
        if(data === null){
            return '';
        }
        const filteredLabels = initialSource.sendingStates
            .filter(item => data.includes(item.value))
            .map(filteredItem => filteredItem.label);
        return filteredLabels.join(',');

    }

    const GetReminderState = (reminderState) => {
        if (!initialSource.reminderStates) {
            return;
        }
        const data = initialSource.reminderStates.find((item) => item.value === reminderState);
        return data ? data.label : '';
    }

    const activateReminder = async(reminderId,rowName,isActive)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            setColId(reminderId);
            const response = await PUT(`/UserReminders/ReminderState`,{activeReminder:isActive,userNoteId:reminderId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh()
            }
            else{
                handleApiError(response,setWait(false))
            }
        }catch (e) {
            setWait(false)
        }
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},
        { headerName: t("Title"),field: 'title', minWidth: 170},
        { headerName: t("Notification"),field: 'enSendingStates', minWidth: 200,cellRenderer:params => {
                return GetSendingStates(params.data.enSendingStates);
            }},
        { headerName: t("Date"), minWidth: 150,
            cellRenderer:params => {
                return <><GridDateFomartter date={params.data.reminderDateUtc} hasTime={true} /></>
            }
        },
        { headerName: t("Status"),minWidth: 170,
            cellRenderer:params => {
                return GetReminderState(params.data.reminderState)
            }
        },

        { headerName: t("ReminderStatus"),field: 'hasReminder',maxWidth: 115, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && coldId ===  params.data.id ? (
                        <TinyLoading  />
                    ) : params.data.hasReminder ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            onClick={async () => await activateReminder(params.data.id,params.colDef.headerName,!params.data.hasReminder)}
                            icon={"pi-check-square"}
                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => activateReminder(params.data.id,params.colDef.headerName,!params.data.hasReminder)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>
        },


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={() => handleGetReminder(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteReminder(params.data.id)}
                    />
                </div>
            }
        },
    ];





    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }




    const [filter,setFilter] = useState({IsSent:null,IsNote:null,search:'',IsReminder:null,IsInQueue:null});

    const SwitchFilters = [
        {name:'IsSent',lable:t("Sent"),value:filter.IsSent},
        {name:'IsNote',lable:t("Note"),value:filter.IsNote},
        {name:'IsReminder',lable:t("Reminder"),value:filter.IsReminder},
        {name:'IsInQueue',lable:t("IsInSendingQueue"),value:filter.IsInQueue},
    ]

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,IsSent: null,IsInQueue: null,IsNote: null,IsReminder: null}));
        setSearch('');
    }

    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems;
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh,pagination.currentPage, filter.search, pagination.pageSize,filter.NotSent,filter.IsSent,filter.IsReminder,filter.IsNote,filter.IsInQueue]);

    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/UserNotes`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search:filter.search,IsSent:filter.IsSent,IsInQueue:filter.IsInQueue,IsReminder:filter.IsReminder,IsNote:filter.IsNote,UserId:null}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const modifiedItems = Paginate(response.items);
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };


    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
    }
}