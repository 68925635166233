import React, {useState} from "react";
import ReactQuill from 'react-quill';
import '../../../Styles/dist/css/Editors/quill.snow.css';

// this is a such a CkEditor from prime react .
export const TextEditor = (props)=>{
    const {content} = props;

    const onValueChangeHandler = (data)=>{
        props.handleEditorContent(data,props.name)
    }
    const formats = [
        'fonts',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
    ];


    return (
        <div>
            <ReactQuill formats={formats} value={content} onChange={onValueChangeHandler}  />
            {/*onChange={onValueChangeHandler}*/}
        </div>
    )
}