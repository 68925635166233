import React, { useRef, useEffect } from 'react';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "./HPrimeIcon";

const Fancybox = ({ children }) => {

    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;

        NativeFancybox.bind(container, '[data-fancybox]', {});

        return () => {
            NativeFancybox.unbind(container);
            NativeFancybox.close();
        };
    }, []);

    return <div ref={containerRef}>{children}</div>;
};

export const ButtonVideo = ({ content, title, icon, iconClass, spanClass, classStyle, videoUrl ,sizeIcon}) => {
    const { t } = useTranslation();

    return (

        <div className="space-y-1">
            <Fancybox>
                <button
                    type={'button'}
                    className={`button box zoom-in block tooltip_play-video rounded-full play-video hbtn-help ${!classStyle ? 'bg-themeInformation text-white hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkInformation dark:text-gray-300' : classStyle}`}
                    data-fancybox
                    data-src={`<div class="video-container"><video controls autoplay><source src="http://cpanel.harmonycms.ir/video.mp4" type="video/mp4" /></video></div>`}
                    data-type="html"
                >
                <Tooltip anchorSelect=".tooltip_play-video" content={t("Viewthetrainingvideo")}  />
                    <span className={`flex size-7 items-center justify-center ${spanClass}`}>
                        <HPrimeIcon icon={`dark:text-gray-300  ${icon ? icon:"pi-youtube"}`} iconClass={iconClass} sizeIcon={sizeIcon} />
                        {/*<i className={"pi pi-youtube"} style={{ fontSize: '1.5rem' }}></i>*/}
                    </span>
                    {content}
                </button>
            </Fancybox>
            <p className="text-xs text-center dark:text-gray-300 text-nowrap">{title ? title :t("Tutorial")}</p>
        </div>
    );
};

export default ButtonVideo;
