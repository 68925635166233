import React, {useEffect, useMemo} from "react";
import {useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useDefaultDashboardWidgets} from "../../../Dashboard/DefaultDashboards/useDefaultDashboardWidgets";
import {useDashboard} from "../../../Dashboard/useDashboard";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {UserComment} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserComment";
import {UserNote} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserNote";

export const HandleDashboardProductionManagement=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();


    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: 'نام محصول',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: 'خط تولید',minWidth: 140,

            cellRenderer:parameters=>

                <>مدیریت</>

        },
        { headerName: 'شیفت',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    صبح
                </>

        },

        {
            headerName: 'گروه تولید', maxWidth: 110,
            cellRenderer: parameters =>
                <>
                    1
                </>

        },
        { headerName: 'زمان', maxWidth: 130,
            cellRenderer:parameters =>

                <>


                </>

        },
        { headerName: 'تاریخ شروع', minWidth: 130,
            cellRenderer:parameters =>

                <>


                </>

        },
        { headerName: 'تاریخ پایان', minWidth: 130,
            cellRenderer:parameters =>

                <>


                </>

        },

        { headerName: t("settings"), maxWidth: 130,
            cellRenderer:parameters =>
                <div className={'flex justify-center items-center gap-3 '} >
                    <button className="text-themeInformation dark:text-themeDarkInformation" ><HPrimeIcon icon={'pi-eye'} /></button>
                </div>
        },

    ];

    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };


    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };


    const rowDataTwo = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const columnDefsTwo = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: 'مدیریت',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: 'سفارش دهنده',minWidth: 140,

            cellRenderer:parameters=>

                <>مدیریت</>

        },
        { headerName: 'تعداد',maxWidth: 140,
            cellRenderer:parameters=>
                <>
                    10
                </>

        },

        { headerName: 'تاریخ درخواست', minWidth: 130,
            cellRenderer:parameters =>

                <>

                </>

        },
        {
            headerName: 'کد درخواست', minWidth: 110,
            cellRenderer: parameters =>
                <>
                    1
                </>

        },
        { headerName: t("settings"), maxWidth: 130,
            cellRenderer:parameters =>
                <div className={'flex justify-center items-center gap-3 '} >
                    <button className="text-themeInformation dark:text-themeDarkInformation" ><HPrimeIcon icon={'pi-eye'} /></button>
                </div>

        },

    ];

    return (

        <div className={"relative min-h-screen"}>
            <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                </button>
                <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                    <div className="space-y-5">
                        <div>
                            <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    {t("GeneralReport")}
                                </h2>
                                <Link to="#" className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                    <HPrimeIcon icon="refresh-cw"/>
                                    {t("Reload")}
                                </Link>
                            </div>
                            <div className={`grid gap-x-5 gap-y-10 py-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        راندمان شیفت
                                                    </h2>
                                                </div>
                                                <div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        راندمان خط تولید
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>
                                                            10
                                                        </p>
                                                    </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                       راندمان شخصی
                                                    </h2>
                                                </div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        راندمان گروهی
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`grid gap-x-5 py-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 ${classesToggled ? 'xl:grid-cols-4' : ''}`}>
                            <div>
                                <div className="sell report-box">
                                    <div className="box bg-themeInformation  dark:bg-themeDarkSalt700 px-2 py-3 space-y-2">
                                        <h2 className={"text-xs text-white dark:text-gray-300 text-center"}>تعداد شیفت کاری</h2>
                                        <h2 className="text-white dark:text-gray-300 text-center">10</h2>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="sell report-box">
                                    <div className="box bg-themeInformation dark:bg-themeDarkSalt700 px-2 py-3 space-y-2">
                                        <h2 className={"text-xs text-white dark:text-gray-300 text-center"}>مقدار خط تولید</h2>
                                        <h2 className="text-white dark:text-gray-300 text-center">10</h2>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="sell report-box">
                                    <div className="box bg-themeInformation dark:bg-themeDarkSalt700 px-2 py-3 space-y-2">
                                        <h2 className={"text-xs text-white dark:text-gray-300 text-center"}>تعداد اشخاص</h2>
                                        <h2 className="text-white dark:text-gray-300 text-center">10</h2>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <div className="sell report-box">
                                    <div className="box bg-themeInformation dark:bg-themeDarkSalt700 px-2 py-3 space-y-2">
                                        <h2 className={"text-xs text-white dark:text-gray-300 text-center"}>گروه کاری</h2>
                                        <h2 className="text-white dark:text-gray-300 text-center">10</h2>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={""}>
                            <div>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>صف تولید</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className="space-y-3 p-2 box bg-white dark:bg-themeDarkSalt700">
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                                            <div className={"post-input dark:bg-themeDarkPostInput p-1 box border dark:border-themeDarkColorLine"}>
                                                <Select
                                                    placeholder={t("ProductionLine")}
                                                    menuPosition={"fixed"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>
                                            <div className={"post-input dark:bg-themeDarkPostInput p-1 box border dark:border-themeDarkColorLine"}>
                                                <Select
                                                    placeholder={t("ShiftWork")}
                                                    menuPosition={"fixed"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>
                                            <div className={"post-input dark:bg-themeDarkPostInput p-1 box border dark:border-themeDarkColorLine"}>
                                                <Select
                                                    placeholder={t("Group")}
                                                    menuPosition={"fixed"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>
                                            <div className={"post-input dark:bg-themeDarkPostInput p-1 box border dark:border-themeDarkColorLine"}>
                                                <Select
                                                    placeholder={t("Orderer")}
                                                    menuPosition={"fixed"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>

                                        </div>
                                        <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                                                   enableRtl={true}
                                                   pagination={true}
                                                   getRowHeight={getRowHeight}
                                                   onGridReady={onGridReady}
                                                   quickFilterText={quickFilterText}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"-intro-x"}>
                            <div className="">
                                <h2 className="text-lg dark:text-gray-300">
                                   درخواست از انبار
                                </h2>
                            </div>
                            <div className={"py-3"}>
                                <GridTable rowData={rowDataTwo} columnDefs={columnDefsTwo} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                                           enableRtl={true}
                                           pagination={true}
                                           getRowHeight={getRowHeight}
                                           onGridReady={onGridReady}
                                           quickFilterText={quickFilterText}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("Notes")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            {
                                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                    <UserNote
                                        key={index}
                                        fullName={item.fullName}
                                        id={item.id}
                                        message={item.message}
                                        createdAt={item.createdAt}
                                    />
                                ))
                            }
                            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("UserComments")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 dashboard-note height_21rem overflow-y-auto scrollbar-hidden "}>
                                {
                                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                        <UserComment
                                            key={index}
                                            fullName={item.fullName}
                                            id={item.id}
                                            message={item.message}
                                            createdAt={item.createdAt}
                                            state={item.state}
                                        />
                                    ))
                                }
                            </div>
                            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center ">
                            <h2 className="text-lg dark:text-gray-300">
                                {t("ReleaseQueue")}
                                <span className={"text-xs dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                    <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                    </div>
                                    <div class="flex-1">
                                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation">{t("Draft")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}