import React, {useState} from "react";

export const useSecurityPrivacy=(setErrors)=>{
    const [managerPrivacy,setManagerPrivacy]=useState({
        authenticationMode:null,
        allowedIPs:[],
        blockIps:[],
        firstSMSOtpAccount:null,
        secondSMSOtpAccount:null,
        firstEmailOtpAccount:null,
        secondEmailOtpAccount:null,
        smsOtpAccountActivation:false,
        emailOtpAccountActivation:false,
        IsMemberShip:true
    });
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setManagerPrivacy({...managerPrivacy,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setManagerPrivacy({...managerPrivacy,[name]:data});
    }
    const handleChangeIps = (allowedIPs) => {
        setManagerPrivacy((prevState) => ({ ...prevState,allowedIPs: allowedIPs}));
    };
    const handleCheckBox = (value,name)=>{
        setManagerPrivacy({...managerPrivacy,[name]:value});
    }
    return {
        handleSelectValue,
        handleValue,
        managerPrivacy,
        setManagerPrivacy,
        handleChangeIps,
        handleCheckBox
    }
}