import React from "react";
import {ReminderBox} from "./ReminderBox";

export const Reminders = ({ notes,type }) => {
    return (
        <>
            {notes && notes.length > 0 ? (
                notes.map((item, index) =>

                    item.type === type?
                        <ReminderBox key={index} index={index} {...item} />
                        :null
                )
            ) : null}
        </>
    );
};