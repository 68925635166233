import React, {useEffect, useMemo, useState} from "react"
import {GridTable} from "../../../Commans/Grid/GridTable";
import {Chart} from "primereact/chart";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {useProductChartGrid} from "./useProductChartGrid";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {useProductChart} from "./useProductChart";
import queryString from "query-string";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {
    handleConvertGregoriToJalili,
} from "../../../../Services/Globals/Public/TimeServiceManager";
import commaNumber from "comma-number";
import {useNavigate} from "react-router-dom";

export const HandleChartProduct=()=>{
    const queryParams = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [errors,setErrors]=useState({})
    const [initialSource,setInitialSource] = useState({timeIntervals:[],currencies:[]});
    const[IsShowSpinner,setIsShowSpinner]=useState(false);
    const {handleNumberValue,handleSelectFilterValue,handleResetFilters, filter,setProductChart,productChart,handleSelectValue,handleDate,handleFilterFromDate,handleFilterToDate,handleGetCurrencyType}=useProductChart(setErrors)
    const[rowData,setRowData]=useState([]);
    const [IsOpen,setIsOpen]=useState(false);
    const [refresh,setRefresh]=useState(false);

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});


    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleIsOpen=()=>{
        setProductChart(prevState => ({...prevState,date: new Date(),price: 0}));
        setIsOpen(true)
    }
    const handleCloseIsOpen=()=>{
        setProductChart(prevState => ({...prevState,date: new Date(),price: 0}));
        setIsOpen(false)
    }
    const handleDataInitialSource=(responseTimeIntervals,responseCurrencies)=>{
        const timeIntervals = convertSelectDataHandler(responseTimeIntervals);
        const currencies = convertSelectDataHandler(responseCurrencies);
        setInitialSource({
            timeIntervals: timeIntervals,
            currencies: currencies
        })
        return {timeIntervals,currencies}
    }

    const fetchData = async () => {
        try {
            showSpinner();
            const timeInterval = filter.timeInterval === null ? 0 : filter.timeInterval.value;
            const [responseInterval, responseCurrency, responseCurrencies, response] = await Promise.all([
                GET(`/Enumerations/EnTimeInterval`, {}, 'IEnumerable'),
                GET(`/Websites/Currency`, {}, 'single'),
                GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable'),
                GET(`/Products/PriceChart`, {
                    FromDate: productChart.fromDate,
                    ToDate: productChart.toDate,
                    TimeInterval: timeInterval,
                    ProductId: queryParams.productId
                }, 'IEnumerable')
            ]);

            if (responseInterval.status === 200 && responseCurrency.status === 200 && responseCurrencies.status === 200 && response.status === 200) {
               const {currencies}= handleDataInitialSource(responseInterval.data, responseCurrencies.data);
                setProductChart((prevState)=>({...prevState,currency:responseCurrency.data.currencyType,productName:response.data.name}));


                const documentStyle = getComputedStyle(document.documentElement);
                const textColor = documentStyle.getPropertyValue('--text-color');
                const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
                const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

                const dates = response.data.priceCharts.map((item)=>handleConvertGregoriToJalili(item.date,false));
                const prices = response.data.priceCharts.map((item)=>item.price);
                const data = {
                    labels: dates,
                    datasets: [
                        {
                            label:t("PriceChanges"),
                            fill: false,
                            borderColor: documentStyle.getPropertyValue('--blue-500'),
                            yAxisID: 'y',
                            //tension: 0.4,
                            data: prices
                        },
                    ]
                };
                const options = {
                    maintainAspectRatio: false,
                    aspectRatio: 0.6,
                    plugins: {
                        legend: {
                            labels: {
                                color: textColor
                            },
                        },
                        tooltip: {
                            callbacks: {

                                label: function(context) {
                                    let label = '';
                                    if (context.parsed.y !== null) {
                                        label += commaNumber(context.parsed.y) + ' ' + handleGetCurrencyType(responseCurrency.data.currencyType, currencies);
                                        label += '\nتعداد محصول: ' + 4// Add the product count on a new line
                                    }
                                    return label;
                                },
                                usePointStyle: true,

                                labelColor: function(context) {
                                    return {
                                        borderColor: 'rgb(0, 0, 255)',
                                        backgroundColor: 'rgb(255, 0, 0)',
                                        borderWidth: 2,
                                        borderDash: [2, 2],
                                        borderRadius: 2,
                                    };
                                },
                                font:function () {
                                    return 'bold'
                                }
                            }
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: textColorSecondary,
                            },
                            grid: {
                                color: surfaceBorder
                            }
                        },
                        y: {
                            ticks: {
                                color: textColorSecondary,
                                callback: function(value, index, ticks) {
                                    return commaNumber(value)  + ' ' +  handleGetCurrencyType(responseCurrency.data.currencyType,currencies)
                                }
                            },
                            grid: {
                                color: surfaceBorder,
                            }
                        }
                    }
                };

                setChartData(data);
                setChartOptions(options);
                setRowData(response.data.priceCharts);

            } else {
                handleApiError(responseInterval, hideSpinner);
                handleApiError(responseCurrency, hideSpinner);
                handleApiError(responseCurrencies, hideSpinner);
                handleApiError(response, hideSpinner);
            }

            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(()=>{
        fetchData()
    },[filter.toDate,filter.fromDate,filter.timeInterval,refresh])






    const handleSubmitProductPrice=async(e)=>{
        e.preventDefault();
        try {
            if(productChart.date === null){
                handleFailureMessage(t(''))
                return;
            }
            showSpinner();
            const response = await POST(`/Products/Price`,{productId:queryParams.productId,date:productChart.date,price:productChart.price},false);
            if(response.status === 200){
              handleCloseIsOpen();
              Refresh();
              handleSuccessMessage(t('SuccessDone'))
            }
            else{
                handleApiError(response);
            }

        }catch (e) {
            hideSpinner();
        }
    }


    const {columnDefs,autoGroupColumnDef,getRowHeight, defaultColDef}=useProductChartGrid(handleGetCurrencyType,productChart,initialSource.currencies,filter);
    return(
        <>
            <div class=" space-y-5 relative">
                {

                    IsShowSpinner?
                        <div className="huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("Time")}</label>
                            <Select
                                options={initialSource.timeIntervals}
                                isMulti={false}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleSelectFilterValue(data,'timeInterval')}
                                name={'surveyGroupId'}
                                placeholder={t("selection")}
                                value={filter.timeInterval}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={'fixed'}
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleFilterFromDate} value={filter.fromDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleFilterToDate} value={filter.toDate} format={'YYYY/MM/DD'} />
                        </div>

                        <ButtonSearchList
                            onClickReset={()=>handleResetFilters()}
                        />

                    </ListSearch>

                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() =>handleIsOpen()}
                                            tooltipTitle={t("AddingAChart")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/MetaData/Add")}
                                            icon="pi-star"
                                            title={t("feature")}
                                            tooltipTitle={t("feature")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Group")}
                                            icon="pi-objects-column"
                                            title={t("grouping")}
                                            tooltipTitle={t("CreatingAFeature")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/List")}
                                            icon="pi-list"
                                            title={t("list")}
                                            tooltipTitle={t("ListOfProducts")}
                                        />

                                    </>
                                }

                    />


                </div>
                <div className={"H-body-ag-grid space-y-2"}>
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                </div>


                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-3">
                    <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation flex gap-2 items-center">
                        <span className={"text-xs"}>{t("ProductName")}:</span>
                        <span className="text-xs text-themeInformation dark:text-themeInformation">{productChart.productName}</span>
                    </div>
                    <Chart type="line" data={chartData} options={chartOptions} />
                </div>
            </div>
            <FantasyModal openModal={IsOpen}  closeModal={handleCloseIsOpen} title={t("AddingAChart")}>
             <form onSubmit={handleSubmitProductPrice}>
                 <div className=" space-y-4">
                     <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
                         <div class=" space-y-1">
                             <label className="text-xs dark:text-gray-300">{t("Value")}</label>
                             <InputNumber onChange={(e)=>handleNumberValue(e.value,'price')} min={0} suffix={handleGetCurrencyType(productChart.currency,initialSource.currencies)} value={productChart.price} type={"text"}  />
                         </div>
                         <div class=" space-y-1">
                             <label className="text-xs dark:text-gray-300">
                                 <SettingButton
                                     onClick={()=>handleSetting('GeneralSetting')}
                                     tooltipTitle={t("Calendarsettings")}
                                 />
                                 {t("Date")}
                             </label>
                             <DateTimePicker handleDateTime={handleDate} value={productChart.date} format={'YYYY/MM/DD'} />
                         </div>
                     </div>

                     <button type={'submit'} className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("register")}</button>

                 </div>
             </form>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>
            <ToastContainer/>
        </>

    )
}