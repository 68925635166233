import React from "react";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {InputNumber} from "primereact/inputnumber";

export const TransportTab=({transportTypes,handleNumberValue,handleSelectValue,product,handleGetCurrencyType,currencies,handleOpenTransport})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                    <p className={"dark:text-gray-300 text-xs"}>{t("SendingType")}</p>
                </div>
                <div className="px-2">
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">
                            {t("SendingMethod")}
                            <button type="button" onClick={()=>handleOpenTransport()} className="text-themeInformation dark:text-themeDarkInformation text-xs ms-2">({t("Add")})</button>
                        </label>
                        <Select
                            options={transportTypes}
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(data)=>handleSelectValue(data,'productTransportIds')}
                            name={'productTransportIds'}
                            placeholder={t("selection")}
                            value={product.productTransportIds}
                            className={"select-group"}
                            classNamePrefix={"h_multi_select"}
                        />
                    </div>
                </div>
            </div>
            <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                    <p className={"text-xs dark:text-gray-300"}>{t("SendingSettings")}</p>
                </div>
                <div className={"px-2"}>
                    <div class="grid grid-cols-2 lg:grid-cols-5 gap-2">
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("ProductInsuranceValue")}</label>
                            <InputNumber
                                onChange={(e) => handleNumberValue(e.value, 'transportInsuranceConst')}
                                suffix={handleGetCurrencyType(product.currency, currencies)}
                                min={0}
                                value={product.transportInsuranceCost}
                                type={"text"}
                            />

                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("Weight")}</label>
                            <InputNumber type={"text"} min={0}  onChange={(e)=>handleNumberValue(e.value,'transportWeight')} value={product.transportWeight}/>
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("Length")}</label>
                            <InputNumber type={"text"} min={0}  onChange={(e)=>handleNumberValue(e.value,'transportLength')} value={product.transportLength}/>
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">{t("Width")}</label>
                            <InputNumber type={"text"} min={0}  onChange={(e)=>handleNumberValue(e.value,'transportWidth')} value={product.transportWidth}/>
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Height")}</label>
                            <InputNumber type={"text"} min={0}  onChange={(e)=>handleNumberValue(e.value,'transportHeight')} value={product.transportHeight}/>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}