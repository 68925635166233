import React from "react";

export const Payments = ({handleChangeTab})=>{

    const handleChangeViewTab = (tabId) => {

        handleChangeTab(tabId)
    };
   const GoToLastPage=(tabId)=>{
        handleChangeTab(tabId)
    }
    return (<>

        <div class=" p-3">
            <div class="flex items-center justify-between gap-5">
                <div class=" rounded-full w-16 h-16 lg:h-20 lg:w-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                    7
                </div>
                <div class="flex-1 md:border-4 border-dashed lg:p-5 ">
                    <h2 class="lg:text-xl text-themeInformation dark:text-gray-300">مشاهده قرارداد</h2>
                    <p class="line-clamp-1 text-gray-600 text-xs hidden lg:block dark:text-gray-300">لورم ایپسوم متن
                        ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک
                        ی باشد، ته حال.
                    </p>
                </div>
                <div>
                    <span>
                        <svg viewBox="0 -0.5 17 17" version="1.1" className="h-10 lg:h-20 fill-themeInformation dark:fill-themeDarkInformation"><defs></defs><g stroke-width="1"  fill-rule="evenodd"><path d="M10.978,1.162 C10.978,1.387 10.916,1.612 10.782,1.812 L6.626,8.041 L10.823,14.078 C11.182,14.619 11.036,15.348 10.495,15.707 C9.956,16.068 9.224,15.922 8.865,15.382 L4.235,8.694 C3.971,8.3 3.969,7.786 4.233,7.39 L8.822,0.51 C9.182,-0.032 9.914,-0.178 10.454,0.181 C10.795,0.409 10.978,0.782 10.978,1.162 L10.978,1.162 Z " ></path></g></svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="border rounded-md box bg-white dark:bg-themeDarkSalt700 space-y-4 dark:border-themeDarkColorLine">
            <div class=" grid grid-cols-12 p-2 lg:p-5 border-b-2 border-dashed gap-5">
                <div class="col-span-12 lg:col-span-4 flex justify-between items-center ">
                    <ul class="space-y-3 w-full">
                        <li className="flex justify-between lg:hidden border-b border-dashed">
                            <h2 class="w-20 text-xs dark:text-gray-300">
                                تاریخ:
                            </h2>
                            <h3 className="dark:text-gray-300">1401/01/21</h3>
                        </li>
                        <li className="flex justify-between lg:justify-start">
                            <h2 class="w-20 dark:text-gray-300">
                                نام:
                            </h2>
                            <h3 className="dark:text-gray-300">مجتبی غلامپور</h3>
                        </li>
                        <li className="flex justify-between lg:justify-start">
                            <h2 class="w-20 dark:text-gray-300">برند:</h2>
                            <h3 className="dark:text-gray-300">سایت گروه آرین-دموی سیستم</h3>
                        </li>
                        <li className="flex justify-between lg:justify-start">
                            <h2 class="w-20 dark:text-gray-300">نوع سایت:</h2>
                            <h3 className="dark:text-gray-300">gold</h3>
                        </li>
                        <li className="flex justify-between lg:justify-start">
                            <h2 class="w-20 dark:text-gray-300">دامین ها:</h2>
                            <h3 className="dark:text-gray-300"> arianteam.com</h3>
                        </li>
                    </ul>
                </div>
                <div class="col-span-12 lg:col-span-4 flex flex-col justify-center items-center gap-5 ">
                    <div class=" p-2 div-logo">
                        <img src="/Assets/Image/images/logo/xs_logo.png" alt="" className="w-full h-full"/>
                    </div>
                    <div>
                        <h2 class="title-arian text-lg dark:text-gray-300">گروه نرم افزاری آرین تیم</h2>
                    </div>
                </div>
                <div class="hidden col-span-12 lg:col-span-4 lg:flex items-enter lg:justify-end ">
                    <ul class="flex flex-col justify-center w-max space-y-3 ">
                        <li className="flex gap-2">
                            <h2 className="dark:text-gray-300">
                                تاریخ:
                            </h2>
                            <h3 className="dark:text-gray-300">1401/01/21</h3>
                        </li>
                        <li className="flex gap-2">
                            <h2 className="dark:text-gray-300">
                                شماره:
                            </h2>
                            <h3 className="dark:text-gray-300">ندارد</h3>
                        </li>
                        <li className="flex gap-2 ">
                            <h2 className="dark:text-gray-300">
                                پیوست:
                            </h2>
                            <h3 className="dark:text-gray-300">ندارد</h3>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="">
                <h2 class="text-center py-3 bg-white dark:bg-themeDarkPostInput dark:text-gray-300 rounded-md">در صورت بروز هرگونه مشکل در پرداخت
                    آنلاین مبلغ صورتحساب خود را به شماره کارت 6104337757414527 بانک ملت بنام مجتبی
                    غلامپورعصاری واریز کنید</h2>
            </div>
            <div class=" min-h-screen p-3">
                <ul class="ul-table-factor rounded-b-md overflow-x-auto">
                    <li>
                        <div class="flex justify-evenly table-factor">
                            <div class="text-white w-full text-center">ردیف</div>
                            <div class="text-white w-full text-center">عنوان</div>
                            <div class="text-white w-full text-center">شرح محصول</div>
                            <div class="text-white w-full text-center">هزینه کل (ریال)</div>
                        </div>
                    </li>
                    <li className="flex justify-evenly border-b text-gray-700 cursor-pointer">
                        <span class="ul-li-span-one"></span>
                        <span class="ul-li-span-two"></span>
                        <div class="w-full text-center p-3">1</div>
                        <div class="w-full text-center p-3">هزینه هاست</div>
                        <div class="w-full text-center p-3">Use:4860MB Need:4900MB</div>
                        <div class="w-full text-center p-3">73,500,000</div>
                    </li>
                    <li className="flex justify-evenly border-b text-gray-700 cursor-pointer">
                        <span class="ul-li-span-one"></span>
                        <span class="ul-li-span-two"></span>
                        <div class="w-full text-center p-3">1</div>
                        <div class="w-full text-center p-3">هزینه هاست</div>
                        <div class="w-full text-center p-3">Use:4860MB Need:4900MB</div>
                        <div class="w-full text-center p-3">73,500,000</div>
                    </li>
                    <li className="flex justify-evenly border-b text-gray-700 cursor-pointer">
                        <span class="ul-li-span-one"></span>
                        <span class="ul-li-span-two"></span>
                        <div class="w-full text-center p-3">1</div>
                        <div class="w-full text-center p-3">هزینه هاست</div>
                        <div class="w-full text-center p-3">Use:4860MB Need:4900MB</div>
                        <div class="w-full text-center p-3">73,500,000</div>
                    </li>
                    <li className="flex justify-evenly border-b text-gray-700 cursor-pointer">
                        <span class="ul-li-span-one"></span>
                        <span class="ul-li-span-two"></span>
                        <div class="w-full text-center p-3">1</div>
                        <div class="w-full text-center p-3">هزینه هاست</div>
                        <div class="w-full text-center p-3">Use:4860MB Need:4900MB</div>
                        <div class="w-full text-center p-3">73,500,000</div>
                    </li>
                    <li className="flex justify-evenly border-b text-gray-700 cursor-pointer">
                        <span class="ul-li-span-one"></span>
                        <span class="ul-li-span-two"></span>
                        <div class="w-full text-center p-3">1</div>
                        <div class="w-full text-center p-3">هزینه هاست</div>
                        <div class="w-full text-center p-3">Use:4860MB Need:4900MB</div>
                        <div class="w-full text-center p-3">73,500,000</div>
                    </li>
                </ul>
                <div class="py-5 text-center text-lg font-bold text-green-800"><h2>قیمت نهایی :
                    95,000,000 ریال</h2></div>
                <div class="py-5 text-center ">
                    <p>توضیح: هزینه لایسنس حق اشتراک نرم افزار به همراه خدمات پشتیبانی و هزینه های
                        نگهداری سالیانه می باشد</p>
                </div>
            </div>
            <div class="flex items-center justify-end gap-2 p-3">
                <button type={'button'} onClick={()=>GoToLastPage('templates')}  className="block button w-24  bg-themeSecondary text-white">قبلی</button>
                <button type={'button'} className="block button w-24 bg-themeInformation dark:bg-themeDarkInformation text-white"> پرداخت</button>
            </div>
        </div>
    </>)
}