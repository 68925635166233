import React, { useState } from "react";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { POST } from "../../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import { messageShema } from "./messageShema";
import { useDataBinders } from "./useDataBinders";
import { useFetchData } from "./useFetchData";
import { UseDepartmentSelector } from "./useDepartmentSelector";
import { LoadingHarmony } from "../../../Commans/Loadings/LoadingHarmony";
import { DisplayError } from "../../../Commans/Errors/DisplayError";
import {
    handleCreateSupportToAdmin
} from "./TicketService";
import { handleFailureMessage, handleSuccessMessage } from "../../../../Services/Globals/Errors/NotificationService";
import { validate } from "../../../../Services/Globals/validate";
import { DisplayFiles } from "./DisplayFiles";
import { useNavigate } from "react-router-dom";
import { ListFilter } from "../../../Commans/Grid/ListFilter";
import { ButtonPlus } from "../../../Commans/UIParts/ButtonPlus";
import { FastDepartment } from "../../../OnlineSupport/Departments/FastDepartment";
import { FantasyModal } from "../../../Commans/UIParts/FantasyModal";
import { HPrimeIcon } from "../../../Commans/UIParts/HPrimeIcon";
import { ToastContainer } from "react-toastify";
import { FormSubmit } from "../../../Commans/UIParts/FormSubmit";

export const HarmonyTickets = () => {

    const redirectTo = useNavigate();
    const { t } = useTranslation();
    const [IsShowSpinner, setIsShowSpinner] = useState(false);
    const [refresh, setRefresh] = useState();
    const [IsDepartmentOpen, setDepartmentOpen] = useState();
    const [btnLoading, setBtnLoading] = useState(false);

    // hide and show spinner
    const showSpinner = () => {
        setIsShowSpinner(true);
    }
    const hideSpinner = () => {
        setIsShowSpinner(false);
    }




    const updateData = () => {
        setRefresh((prevstate) => !prevstate);
    }


    const { errors, handleSelectValue, onSetErros, handleValue, handleEmoji, onApiError, ticket, setErrors, handleItemDepartment, activeItem, handleDeActive, setTicket, handleChangeFile, handleRemoveFile, setActiveItem } = useDataBinders(hideSpinner);
    const { options, setOptions, handleValidateFileSize, setting } = useFetchData(refresh, onApiError, setTicket, ticket);
    const { department } = UseDepartmentSelector(setOptions, onApiError, refresh, showSpinner, hideSpinner, setTicket, setActiveItem);


    const handleSubmitMessage = async (e) => {
        e.preventDefault();
        //SiteAdminToMember
        try {
            const isValid = validate(messageShema, ticket, onSetErros);
            if (!isValid) {
                return;
            }
            if (ticket.departmentId === null) {
                handleFailureMessage(t("Pleaseselectdepartment"));
                return;
            }
            const isAllowed = handleValidateFileSize(setting[0].value);
            if (!isAllowed) {
                handleFailureMessage(t("Thefilesizeexceedstheallowedlimit"));
                return;
            }
            showSpinner();
            const formData = handleCreateSupportToAdmin(ticket);
            const response = await POST(`/Tickets/SupportToAdmin`, formData, true);
            if (response.status === 200) {
                hideSpinner();
                updateData();
                handleDeActive(null);
                setTicket((prevState) => ({ ...prevState, message: '', topic: '', type: options.ticketTypes[0], memberId: options.users[0], files: null }));
                redirectTo('/Harmony/Support/Ticket/List');
                handleSuccessMessage(t("Yourtickethasbeensuccessfullysubmitted"));
            }
            else {
                handleApiError(response, onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    return (<>

        <div class="relative space-y-5" >
            {
                IsShowSpinner ?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony />
                        </div>
                    </div>
                    :
                    null
            }
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={

                        <>
                            <ButtonPlus
                                onClick={() => redirectTo(``)}
                                title={t("Tickets")}
                                tooltipTitle={t("NewDepartment")}
                                icon={'pi-list'}
                            />

                            <ButtonPlus
                                onClick={() => redirectTo("")}
                                title={t("بایگانی")}
                                tooltipTitle={t("ExpiredTickets")}
                                icon={"pi-check-square"}
                            />


                        </>
                    }

                />

            </div>
            <div className="space-y-5">

                <div className="box bg-white dark:bg-themeDarkSalt700 p-2 space-y-5">
                    {
                        <>
                            <div>
                                <div className={"p-3 bg-gray-200 rounded-t-md dark:bg-themeDarkPostInput flex gap-2"}>
                                    <h2 className={"text-xs dark:text-gray-300"}>{t("OnlineTicketdepartmentTitle")}</h2>
                                </div>
                                <div className="space-y-5 p-2">
                                    {
                                        //departmentId
                                        <>
                                            {
                                                <div className="flex justify-start items-center gap-5 flex-wrap">
                                                    {department.map((item, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => handleItemDepartment(item.id)}
                                                            onDoubleClick={() => handleDeActive(null)}
                                                            className={`post-input dark:bg-themeDarkPostInput p-2 rounded border dark:border-themeDarkColorLine  zoom-in relative ticket ${item.id === activeItem ? 'active' : ''} overflow-hidden`}
                                                        >
                                                            <span className="ticket_span">
                                                                <HPrimeIcon icon={"pi-check-square"} iconClass="text-white" />
                                                            </span>
                                                            <div className={"space-y-3"}>
                                                                <div className="w-full h-16 mx-auto">
                                                                    <img className="size-full object-scale-down" src={item.picture} alt={item.value} />
                                                                </div>
                                                                <div>
                                                                    <h2 className="text-xs dark:text-gray-300">{item.value}</h2>
                                                                </div>
                                                            </div>

                                                        </button>
                                                    ))}
                                                </div>

                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </>

                    }

                </div>
                {
                    activeItem &&
                    <>

                        <form onSubmit={handleSubmitMessage} method={'post'} >
                            <div className={"box bg-white dark:bg-themeDarkSalt700"}>
                                <div className={"p-3 bg-gray-200 rounded-t-md dark:bg-themeDarkPostInput  flex gap-2"}>
                                    <h2 className={"text-xs dark:text-gray-300"}>{t("SendNewTicket")}</h2>
                                </div>
                                <div className={"p-2"}>
                                    <div className="post-input dark:bg-themeDarkPostInput p-2 rounded-md  ">
                                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-5"} >
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">{t("ImportanceLevel")}</label>
                                                <Select
                                                    options={options.ticketTypes}
                                                    isSearchable={true}
                                                    onChange={(data) => handleSelectValue(data, 'type')}
                                                    placeholder={t("selection")}
                                                    value={ticket.type}
                                                    classNamePrefix={"h_multi_select"}
                                                    menuPosition={"fixed"}
                                                />
                                            </div>
                                            <div class="space-y-2">
                                                <div className={"flex justify-between items-center"}>
                                                    <label className="text-xs dark:text-gray-300">{t("TicketSubject")}</label>
                                                    <DisplayError message={errors.topic} />
                                                </div>

                                                <InputText type={"text"} value={ticket.topic} onInput={(e) => handleValue(e)} name={'topic'} className={" input w-full"} />

                                            </div>
                                            <div class="md:col-span-2">
                                                <InputTextarea rows={10} onInput={(e) => handleValue(e)} name={'message'} value={ticket.message} cols={30} className={"input w-full text-xs"} placeholder={t("Text")} />
                                            </div>
                                        </div>
                                        <div className={"flex items-center justify-between"}>
                                            <div class="">
                                                <label className="cursor-pointer  relative  flex gap-1 items-center p-2 " htmlFor={'file'}>
                                                    <HPrimeIcon icon={'pi-paperclip'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                                                    <span className="text-xs dark:text-gray-300">{t("Upto10MB")}</span>
                                                    <span className="text-xs dark:text-gray-300">  {`حداکثر اپلود فایل تا ${'MB' + setting[0]?.value}`}</span>
                                                    <input type="file" accept=".jpg, .jpeg, .png, .zip, .rar" multiple={true} onChange={handleChangeFile} className="hidden" id={'file'} />
                                                </label>
                                            </div>
                                            <FormSubmit
                                                TextButton={t("sending")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>


                        {
                            ticket.files !== null && ticket.files.length > 0 ?

                                <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 box bg-white dark:bg-themeDarkSalt700 p-2"}>
                                    {
                                        ticket.files !== null && ticket.files.length > 0 ?
                                            ticket.files.map((item, index) => (
                                                <DisplayFiles key={index} file={item} index={index} handleRemoveFile={handleRemoveFile} />
                                            )) : null
                                    }
                                </div> : null

                        }

                    </>
                }
            </div>
        </div>

        <ToastContainer />

    </>)
}