import Joi from 'joi';

export const personalDataSchema = Joi.object({
    // PostalCode: Joi.string().allow('').allow(null)
    //     .pattern(/^\d{5}-\d{5}$/)
    //     .messages({
    //         'string.pattern.base': 'فرمت کد پستی صحیح نمیباشد',
    //     }),
}).options({ abortEarly: false });

