import React, {useEffect, useState} from "react";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";

export const useDefaultDashboardWidgets=(initialSource, setInitialSource, setIsLoading)=>{
    const [dashboard,setDashboard] = useState({userWallet:null,todayDeposits:null,withdrawalRequestStatistics:null,SmsAccountCredit:null,SmsAccountInboxCount:null,SmsAccountOutputCount:null,users:[],
        sales:{totalSales:null,currentYearSales:null,threeMonthsSales:null,currentMonthSales:null,currentWeekSales:null,todaySales:null},SalesData:[],userComments:[],userNotes:[],
        statistics:{daily:0,weekly:0,monthly:0,ViewStatisticsChart:[]}});

    const [chartData,setChartData]=useState({
        xAxis: {
            type: 'category',
            data: ['شنبه', 'یکشنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنج شنبه', '05/01']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'line',
                smooth: true
            }
        ]
    })



    const handleGetWidgets=(dashboard,widgetGroupName,widgetName)=>{
        var widgetGroup = dashboard.widgetGroups.find(group => group.name === widgetGroupName);
        if (!widgetGroup) {
            return null;
        }
        var widget = widgetGroup.widgets.find(widget => widget.name === widgetName);
        if (!widget || !widget.isDataLoaded) {
            return null;
        }
        return widget.data;
    }
    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }

    const GetDashboardData = async () => {
        setIsLoading(true)
        try {
            const [response,responseCurrency] = await Promise.all([
                GET(`/Dashboard`, { }, 'IEnumerable'),
                GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable')
            ]);
            //
            if (response.status === 200 && responseCurrency.status === 200) {
                const userWallet = handleGetWidgets(response.data.dashboard, 'Statistics', 'UserWallet');
                const todayDeposits = handleGetWidgets(response.data.dashboard, 'Statistics', 'TodayDeposits');
                const WithdrawalRequestStatistics = handleGetWidgets(response.data.dashboard, 'Statistics', 'WithdrawalRequestStatistics');
                const lastestUsers = handleGetWidgets(response.data.dashboard, 'LatestUsers', 'LatestUsers');
                const SmsAccountCredit = handleGetWidgets(response.data.dashboard, 'Statistics', 'SmsAccountCredit');
                const SmsAccountInboxCount = handleGetWidgets(response.data.dashboard, 'Statistics', 'SmsAccountInboxCount');
                const SmsAccountOutputCount = handleGetWidgets(response.data.dashboard, 'Statistics', 'SmsAccountOutputCount');

                const totalSales = handleGetWidgets(response.data.dashboard, 'TodaySales', 'TotalSales');
                const currentYearSales = handleGetWidgets(response.data.dashboard, 'TodaySales', 'CurrentYearSales');
                const threeMonthSales = handleGetWidgets(response.data.dashboard, 'TodaySales', 'ThreeMonthsSales');
                const currentMonthSales = handleGetWidgets(response.data.dashboard, 'TodaySales', 'CurrentMonthSales');
                const currentWeekSales = handleGetWidgets(response.data.dashboard, 'TodaySales', 'CurrentWeekSales');
                const todaySales = handleGetWidgets(response.data.dashboard, 'TodaySales', 'TodaySales');
                let salesDataSource = handleGetWidgets(response.data.dashboard, 'SalesData', 'TotalSalesData');
                salesDataSource = salesDataSource.map((item, index) => ({ ...item, idSequence: index + 1 }));
                const currenciesData = convertSelectDataHandler(responseCurrency.data);
                const userComments = handleGetWidgets(response.data.dashboard, 'Comments', 'NewestComment');
                const userNotes = handleGetWidgets(response.data.dashboard, 'UserNotes', 'UserNotes');

                const dailyVisits = handleGetWidgets(response.data.dashboard, 'Visits', 'ViewStatisticsDaily');
                const weeklyVisits = handleGetWidgets(response.data.dashboard, 'Visits', 'ViewStatisticsWeekly');
                const monthlyVisits = handleGetWidgets(response.data.dashboard, 'Visits', 'ViewStatisticsMonthly');

                const viewStatisticsChart = handleGetWidgets(response.data.dashboard, 'Visits', 'ViewStatisticsChart');
                const dates = viewStatisticsChart.map((item)=>item.date)
                const data = viewStatisticsChart.map((item)=>item.uniqueVisitors);

                setChartData({
                    xAxis: {
                        type: 'category',
                        data: dates
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                       {
                            data: data,
                            type: 'line',
                            smooth: true
                        }
                    ]
                });

                setDashboard({ userWallet: userWallet,todayDeposits: todayDeposits, withdrawalRequestStatistics: WithdrawalRequestStatistics,SmsAccountCredit: SmsAccountCredit,SmsAccountInboxCount: SmsAccountInboxCount,SmsAccountOutputCount: SmsAccountOutputCount,users: lastestUsers,
                    sales: {totalSales:totalSales,currentYearSales: currentYearSales,threeMonthsSales: threeMonthSales,currentMonthSales: currentMonthSales,currentWeekSales: currentWeekSales,todaySales: todaySales},SalesData: salesDataSource
                ,userComments: userComments,userNotes: userNotes,statistics: {daily: dailyVisits.views,weekly: weeklyVisits.views,monthly: monthlyVisits.views,ViewStatisticsChart: viewStatisticsChart}});
                setInitialSource({ currencies: currenciesData });
                setIsLoading(false);

            } else {
                setIsLoading(false)
            }
        } catch (e) {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        GetDashboardData();
    }, []);


    return {handleGetWidgets,GetCurrencyType,setDashboard,dashboard,chartData}
}