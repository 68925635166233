export const convertPersonalData=(obj,id)=>{
    const newData={
        Id:id,
        Group:obj.Group !== null || true? obj.Group.map(item => item.value):null,
        FatherName:obj.FatherName === null || obj.FatherName === undefined?null:obj.FatherName,
        Nationality:obj.Nationality === null || obj.Nationality === undefined?null:obj.Nationality,
        CityId: obj.CityId === null|| obj.CityId === undefined ? null:obj.CityId.value,
        Education: obj.Education === null || obj.Education === undefined?null:obj.Education,
        Job: obj.Job === null || obj.Job === undefined?null:obj.Job,
        Picture: obj.Picture === null || obj.Picture === undefined?null:obj.Picture,
        PicturePath: obj.PicturePath === null || obj.PicturePath === undefined?null:obj.PicturePath,
        Description: obj.Description === null || obj.Description === undefined?null:obj.Description,
        PostalCode: obj.PostalCode === null || obj.PostalCode === undefined?null:obj.PostalCode,
        Address:obj.Address === null || obj.Address === undefined?null:obj.Address,
        ConcurrencyStamp:obj.ConcurrencyStamp === null || obj.ConcurrencyStamp === undefined?null:obj.ConcurrencyStamp
    }
    return newData;
}
export const CreatePersonalFormData = (obj)=>{
    const formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Id',obj.Id);
    appendIfNotNull('PostalCode',obj.PostalCode);
    appendIfNotNull('FatherName',obj.FatherName);
    appendIfNotNull('CityId', obj.CityId);
    appendIfNotNull('Nationality', obj.Nationality);
    appendIfNotNull('Education', obj.Education);
    appendIfNotNull('Job', obj.Job);
    appendIfNotNull('Picture', obj.Picture);
    appendIfNotNull('Description', obj.Description);
    appendIfNotNull('Address', obj.Address);
    appendIfNotNull('ConcurrencyStamp', obj.ConcurrencyStamp);
    if(obj.Group){
        obj.Group.forEach((word, index) => {
            appendIfNotNull('Group',word);
        });
    }
    return formData;
}
