import React from "react";
import {useTranslation} from "react-i18next";

export const useCustomRoutes=()=>{

    const { t } = useTranslation();

    const UsersFacilities = {
        submenuKey:"UsersFacilities",
        icon:"pi-users",
        title:t("UsersFacilities"),
        links:[
        { to: "/UserPanel/Cartable", icon: "pi-box", title: t("Cartable") },
        { to: "/UserPanel/Website/List", icon: "pi-list", title: t("WebsiteList") },
        { to: "/UserPanel/FavoriteProduct", icon: "pi-thumbs-up", title: t("Favoriteproducts") },
        { to: "/UserPanel/Basket", icon: "pi-shopping-cart", title: t("Salesbasket") },
        { to: "/UserPanel/Edit", icon: "pi-user", title: t("Profile") },
        { to: "/UserPanel/Wallet", icon: "pi-wallet", title: t("IncreaseBalance") },
        { to: "/UserPanel/ShipmentTracking", icon: "pi-barcode", title: t("ShipmentTracking") },

    ]

        }
    const manager = {
        submenuKey:"manager",
        icon:"pi-crown",
        title:t("generalManager"),
        links:[
        { to: "/Admin/Package/Group", icon: "pi-objects-column", title: t("PackageGroup") },
        { to: "/Admin/Package/Add", icon: "pi-box", title: t("CreateAPackage") },
        { to: "/Admin/Server/Add", icon: "pi-server", title: t("CreateAServer") },
        { to: "/Admin/MasterTemplate/Add", icon: "pi-th-large", title: t("generalTemplates") },
        { to: "/Admin/Website/Add", icon: "pi-sitemap", title: t("websiteBuilder") },
        { to: "/Admin/Website/List", icon: "pi-list", title: t("WebsiteList") },
        { to: "/Admin/Add/BankAccount", icon:"pi-credit-card", title: t("AddBankAccount") },
        { to: "/Admin/PaymentGateways", icon:"pi-credit-card", title: t("OnlineGateways") },
        { to: "/Admin/BaseTariff/Add", icon: "pi-dollar", title: t("BaseTariff") },
        { to: "/Admin/ExchangeRate/Add", icon:"pi-dollar", title: t("ExchangeRate") },
        { to: "/CRM/User/WithdrawalRequest", icon: "pi-arrow-right-arrow-left", title: t("WithdrawalRequest") },
        { to: '/Admin/LogError', icon: "pi-wrench",target:false,isInRole:"SuperAdmin",title: t("Elmah") },
        { to: "/Admin/Cleaner", icon: "pi-trash", title: t("DataCleanup") },
        { to: "/Admin/Backup", icon: "pi-cloud-upload", title: t("BackupVersion") },
        { to: "/Admin/ProjectDocument", icon: "pi-file-check", title: t("ProjectDocument") },
        { to: "/Admin/ProjectDocument/List", icon: "pi-list", title: t("ProjectDocumentList") },
        { to: "/Admin/Guide", icon: "pi-question-circle", title: t("Guide") },
        { to: "/Admin/Guide/List", icon: "pi-list", title: t("ListGuide") },
        { to: "/Admin/Settings", icon: "pi-cog", title: t("generalManagerSettings")},
    ]

        }
    // const filteredManager = filterMenuItems(manager);



    const PersonManagement = {
        submenuKey: "PersonManagement",
        icon: "pi-users",
        title: t("PersonManagement"),
        links: [
        { to: "/CRM/User/Add", icon: "pi-user-plus", title: t("UsersRegistration") },
        { to: "/CRM/User/Group", icon: "pi-objects-column", title: t("PersonGroup") },
        { to: "/CRM/User/Role", icon: "pi-star", title: t("Roles") },
        { to: "/CRM/User/List", icon: "pi-list", title: t("PersonList") },
        // { to: "/Bank/Transaction/list", icon: "list", title: t("ListOfTransactions") },
        { to: "/CRM/User/Reminder/List", icon: "pi-file-check", title: t("operations") },
        { to: "/CRM/User/List/Log", icon: "pi-wave-pulse", title: t("activities") },
        { to: "/CRM/User/CustomField", icon: "pi-sort-amount-down", title: t("communications") },
    ]
    }




    const CMS = {
        submenuKey:"SendInfo",
        icon:"pi-file-export",
        title:t("CMS"),
        links: [
            {to: "/CMS/Content/Add", icon: "pi-pen-to-square", title: t("InsertingInformation")},
            {to: "/CMS/Content/Group", icon: "pi-objects-column", title: t("grouping")},
            {to: "/CMS/MetaData/Add", icon: "pi-star", title: t("CreatingAFeature")},
            {to: "/CMS/Content/List", icon: "pi-list", title: t("ListOfInformation")},
        ]
    }
    // const filteredCMS = filterMenuItems(CMS);


    const Comment = {
        submenuKey:"comment",
        icon:"pi-comments",
        title:t("UserComments"),
        links:[
        { to: "/CMS/Comment/Inbox", icon: "pi-inbox", title: t("receivingBox") },
        { to: "/CMS/Comment/Confirmed", icon: "pi-check-square", title: t("ApprovedComments") },
        { to: "/CMS/Comment/NoConfirmed", icon: "pi-times-circle", title: t("UnapprovedComments") },
        { to: "/CMS/Comment/Trash", icon: "pi-trash", title: t("trash") },
    ]
    }
    // const filteredComment = filterMenuItems(Comment);


    const template = {
        submenuKey:"template",
        icon:"pi-slack",
        title:t("Template"),
        links: [
            {to: "/CMS/Template/Add", icon: "pi-objects-column", title: t("MyTemplates")},
            {to: "/CMS/Template/Choose", icon: "pi-cart-arrow-down", title: t("BuyATemplate")},
            {to: "/CMS/Template/ShortCode", icon: "pi-code", title: t("CreatingAShortCode")},

        ]
    }
    // const filteredTemplate = filterMenuItems(template);


    const poll = {
        submenuKey:"poll",
        icon:"pi-gauge",
        title:t("Survey"),
       links: [
        { to: "/CMS/Surveys/Add", icon: "pi-pen-to-square", title: t("CreatingASurvey") },
        { to: "/CMS/Surveys/List", icon: "pi-list", title: t("ListOfSurveys") },
    ]
    }
    // const filteredPoll = filterMenuItems(poll);


    const advertise = {
        submenuKey:"Advertising",
        icon:"pi-desktop",
        title:t("Advertisement"),
        links:[
        { to: "/CMS/Advertise/Add", icon: "pi-pen-to-square", title: t("NewAdvertisements") },
        { to: "/CMS/Advertise/List", icon: "pi-list", title: t("ListAdvertisements") },
    ]
    }
    // const filteredAdvertise = filterMenuItems(advertise);


    const form = {
        submenuKey:"form",
        icon:"pi-objects-column",
        title:t("FormBuilder"),
        links:[
            { to: "/UserPanel/Cartable", icon: "pi-box", title: t("Cartable") },
            { to: "/Form/Create", icon: "pi-pen-to-square", title: t("FormManagement") },
            { to: "/Form/RoadMap", icon: "pi-sync", title: t("FormWorkflow") },
        // { to: "/Form/DataSource", icon: "server", title: t("DataSource") },
            { to: "/Form/Organize", icon: "pi-sort-alpha-down", title: t("OrganizeForms") },
        // { to: "/Form/DataSource/List", icon: "list", title: t("ListSourcesInformation") },
    ]
    }
    // const filteredForm = filterMenuItems(form);

    const shop = {

        submenuKey:"shop",
        icon:"pi-shopping-cart",
        title:t("OnlineStore"),
        links: [
            {to: "/Shop/Product/Add", icon: "pi-pen-to-square", title: t("NewProduct")},
            {to: "/Shop/MetaData/Add", icon: "pi-star", title: t("CreatingAFeature")},
            {to: "/Shop/Product/Group", icon: "pi-objects-column", title: t("ProductCategorization")},
            {to: "/Shop/Product/List", icon: "pi-list", title: t("ListOfProducts")},
            {to: "/Shop/Product/Cart", icon: "pi-shopping-bag", title: t("ShoppingCart")},
            {to: "/InvoiceRequest", icon: "pi-file-edit", title: t("Bills")},
            {to: "/Shop/Shipping", icon: "pi-gift", title: t("PackagingAndShipping")},
            // { to: "/Shop/Product/PriceChange", icon: "edit", title: t("QuickPriceChange") },

        ]
    }
    // const filteredShop = filterMenuItems(shop);




    const Guarantee = {
        submenuKey:"guaranty",
        icon:"pi-verified",
        title:t("Guarantee"),
        links: [
            {to: "/Guarantee/Add", icon: "pi-pen-to-square", title: t("NewGuaranteeCode")},
            {to: "/Guarantee/Customer", icon: "pi-user-plus", title: t("CustomersGuarantee")},
        ]
    }
    // const filteredGuarantee = filterMenuItems(Guarantee);

    const DiscountCode = {
        submenuKey:"DiscountCode",
        icon:"pi-money-bill",
        title:t("DiscountsAndPrizes"),
        links: [
            {to: "/Discount/Add", icon: "pi-pen-to-square", title: t("CreatingDiscountCode")},
            {to: "/Discount", icon: "pi-percentage", title: t("DiscountCampaign")},
        ]
    }
    // const filteredDiscountCode = filterMenuItems(DiscountCode);


    const Commission = {
        submenuKey:"Commission",
        icon:"pi-thumbs-up",
        title:t("Commission"),
        links: [
            {to: "/Commission", icon: "pi-pen-to-square", title: t("DeterminingCommission")},
            {to: "/Commission/IndividualClaims", icon: "pi-users", title: t("IndividualClaims")},

        ]
    }


    const SocialMarketing = {
        submenuKey:"SocialMarketing",
        icon:"pi-bullseye",
        title:t("SocialMarketing"),
        links: [
            {to: "/SocialMarketing/Messengers/List", icon: "pi-link", title: t("AddUserAccount")},
            {to: "/SocialMarketing/Messengers/PublicationList", icon: "pi-list", title: t("PublicationList")},
            // { to: "/SocialMarketing/Messengers/List/Add", icon: "radio", title: t("Media") },
            {to: "/SocialMarketing/Messengers/PublishingContent", icon: "pi-share-alt", title: t("PublishingContent")},
            {to: "/SocialMarketing/Messengers/Comments", icon: "pi-comments", title: t("comments")},
            {to: "/SocialMarketing/Draft", icon: "pi-file-edit", title: t("Draft")},
        ]
    }
    // const filteredDigitalMarketing = filterMenuItems(digitalMarketing);

    const EmailMarketing = {
        submenuKey:"EmailMarketing",
        icon:"pi-envelope",
        title:t("EmailMarketing"),
        links: [
            {to: "/EmailMarketing/Email/Add", icon: "pi-pen-to-square", title: t("AddEmail")},
            {to: "/EmailMarketing/Compose", icon: "pi-send", title: t("SendEmail")},
            {to: "/EmailMarketing/Draft", icon: "pi-file-edit", title: t("Draft")},
            {to: "/EmailMarketing/Inbox", icon: "pi-inbox", title: t("receivingBox")},
            {to: "/EmailMarketing/SendBox", icon: "pi-inbox", title: t("sendingBox")},
            {to: "/EmailMarketing/Trash", icon: "pi-trash", title: t("trash")},

        ]
    }


    const shortMessageService = {
        submenuKey:"sms",
        icon:"pi-envelope",
        title:t("SMS"),
        links: [
            {to: "/SmsManagment/AddSmsNumber", icon: "pi-plus-circle", title: t("addingATextMessageLine")},
            {to: "/SmsManagment/Send", icon: "pi-send", title: t("sendingMessage")},
            {to: "/SmsManagment/Inbox", icon: "pi-inbox", title: t("receivingBox")},
            {to: "/SmsManagment/SendBoxSms", icon: "pi-inbox", title: t("sendingBox")},
            {to: "/SmsManagment/SmsSecretery", icon: "pi-headphones", title: t("smsSecretary")},
            {to: "/SmsManagment/SmsPattern", icon: "pi-code", title: t("creatingATemplate")},
            {to: "/SmsManagment/FrequentlyNumbers", icon: "pi-address-book", title: t("FrequentlyNumbers")},
            {to: "/SmsManagment/AddSmsNumber", icon: "pi-bolt", title: t("SMSPanelRecharge")},
        ]
    }

    // const filteredShortMessageService = filterMenuItems(shortMessageService);


    const seo = {
        submenuKey:"SEO",
        icon:"pi-google",
        title:t("SEO"),
        links: [
            // {to: "/Harmony/Setting/Main/Schema", icon: "pi-key", title: t("CodeSchema")},
            // {to: "/Harmony/Setting/Main/MetaTag", icon: "pi-key", title: t("MetaTag")},
            {to: "/SEO/GoogleSiteMap", icon: "pi-globe", title: t("introductionToGoogle")},
            {to: "/SEO/Redirect/Add", icon: "pi-external-link", title: t("redirect")},
            // {to: "/SEO/MonthlyReport", icon: "pi-chart-bar", title: t("MonthlyReport")},
            {to: "/SEO/MetaTagextra", icon: "pi-code", title: t("MetaTagSchema")},
        ]
    }
    // const filteredSeo = filterMenuItems(seo);


    const support = {
        submenuKey:"Tickets",
        icon:"pi-ticket",
        title:t("Tickets"),
        links:[
        // { to: "/OnlineConversation/OnlineTicket", icon: "message-square", title: t("Tickets") },
        //{ to: "/OnlineConversation/Ticket/TicketGrid", icon: "message-square", title: t("Tickets") },
            { to: "/Support/Department", icon: "pi-sitemap", title: t("Department") },
            { to: "/Support/Ticket", icon: "pi-pen-to-square", title: t("NewTicket") },
            { to: "/Support/Ticket/List", icon: "pi-list", title: t("TicketList") },
            { to: "/Support/ExpiredTickets", icon: "pi-calendar-times", title: t("ExpiredTickets") },
    ]
    }


     const transformRoutes = (routes) => {
        return routes.links.map(route => {
            return { label: route.title, value: route.to,icon:route.icon };
        });
    };
    const allRoutes = [
        ...transformRoutes(UsersFacilities),
        ...transformRoutes(manager),
        ...transformRoutes(PersonManagement),
        ...transformRoutes(CMS),
        ...transformRoutes(Comment),
        ...transformRoutes(template),
        ...transformRoutes(poll),
        ...transformRoutes(advertise),
        ...transformRoutes(form),
        ...transformRoutes(shop),
        ...transformRoutes(Guarantee),
        ...transformRoutes(DiscountCode),
        ...transformRoutes(SocialMarketing),
        ...transformRoutes(shortMessageService),
        ...transformRoutes(seo),
        ...transformRoutes(support)
    ];





    return {
        UsersFacilities,
        manager,
        PersonManagement,
        CMS,
        Comment,
        template,
        poll,
        advertise,
        Commission,
        form,
        shop,
        Guarantee,
        DiscountCode,
        SocialMarketing,
        EmailMarketing,
        shortMessageService,
        seo,
        support,
        allRoutes
    }
}