import React, {useEffect, useState} from "react";

import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {useNavigate} from "react-router-dom";
import {GET, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {useDomains} from "./useDomains";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {Domains} from "../../../../GeneralManagement/WebSite/WebsiteEdit/Domains/Domains";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {ToastContainer} from "react-toastify";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {handleCreateDomainsSettings} from "./domainService";
import {validDomainPattern} from "../../../../../Services/Globals/Public/Regexes";

export const HandleDomainMainSetting = () => {
    const queryParams = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsSpinnerShow,setIsShowSpinner] = useState(false);
    const [refresh,setRefresh]=useState(false);
    const [concurrencyStamp,setConcurrencyStamp]=useState(null);
    const onApiError=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const ShowSpinner=()=>setIsShowSpinner(true);
    const hideSpinner=()=>setIsShowSpinner(false);

    const {handleAddRow,domains,setDomains,handleRemoveRow,handleChange,handleChangeDateTime,validateDomains,validateMasterDomains,validateNotEmptyDomains} = useDomains();

    const handleSubmitDomains=async()=>{
        const count = domains.filter((item) => item.domain === null || item.domain === '').length;
        if (count > 0) {
            handleFailureMessage(t("Dearuserpleaseenterthedomainname"));
            return;
        }

        const domainArray = domains.map((item) => validDomainPattern.test(item.domain));
        const domainArrayCount = domainArray.filter((item) => item === false).length;
        if (domainArrayCount > 0) {
            handleFailureMessage(
                t("Enteravaliddomainname")
            );
            return;
        }

        const masterCount = domains.filter((item) => item.isMaster === true).length;
        if(masterCount !== 1){
            handleFailureMessage(t("Pleasechooseadefaultdomain"));
            return;
        }

        try {
            ShowSpinner();
            const createdDomains = handleCreateDomainsSettings(domains,concurrencyStamp);
            const response = await PUT(`/Settings/Domains`,createdDomains,false);
            if(response.status === 200){
                hideSpinner();
                Refresh();
                handleSuccessMessage(t('SuccessDone'));
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            hideSpinner();
        }
    }

    const fetchData =async()=>{
        try {
            ShowSpinner();
            const response = await GET(`/Settings/Domains`,{},'single');
            if(response.status === 200){
                hideSpinner();
                if(response.data.domains.length === 0){
                    handleAddRow();
                    setConcurrencyStamp(response.data.concurrencyStamp)
                }
                else{
                    setDomains(response.data.domains);
                    setConcurrencyStamp(response.data.concurrencyStamp)
                }
            }else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e){
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData()
    },[refresh])


    return (
        <>
            <div className="space-y-5">
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-3 relative"}>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                            icon={"pi-cog"}
                                            title={t("settings")}
                                            tooltipTitle={t("SettingsPage")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Script`)}
                                            icon={"pi-code"}
                                            title={t("Script")}
                                            tooltipTitle={t("AdditionalScript")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/ShortcutKey`)}
                                            icon={"pi-key"}
                                            title={t("Shortcut")}
                                            tooltipTitle={t("ShortcutKey")}
                                        />


                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Security`)}
                                            icon={"pi-shield"}
                                            title={t("Security")}
                                            tooltipTitle={t("SecurityAndPrivacy")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/BackUp`)}
                                            icon={"pi-server"}
                                            title={t("support")}
                                            tooltipTitle={t("SupportAndRecovery")}
                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>


                <div className="box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3">
                    <div className="p-2 post-input post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                        <h2 className={"text-xs dark:text-gray-300"}>{t("domains")}</h2>
                    </div>
                    <div className="bg-white dark:bg-themeDarkSalt700 px-2 relative space-y-3">
                        {

                            IsSpinnerShow?
                                <div className="huploader_loading" >
                                    <div className="relative">
                                        <LoadingHarmony/>
                                    </div>
                                </div>
                                :
                                null
                        }


                        {domains.map((item, index) => (
                            <Domains
                                handleAddRow={handleAddRow}
                                item={item}
                                index={index}
                                handleChange={handleChange}
                                handleDeleteRow={handleRemoveRow}
                                handleChangeDateTime={handleChangeDateTime}
                            />
                        ))}


                    </div>
                </div>
                <ButtonSubmitAndCancel
                    onCancelClick={()=>redirectTo('/Harmony/Master/setting')}
                    onRegisterClick={()=>handleSubmitDomains()}
                />
            </div>
<ToastContainer/>
        </>

    )
};