import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";

import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";
import {GridTable} from "../Commans/Grid/GridTable";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import Select from "react-select";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";


export const HandleCommissionIndividualClams=()=>{

    const { t } = useTranslation();
    const [IsOpenClaims, setOpenClaims] = useState(false);

    const closeClaims=()=>{
        setOpenClaims(false)
    }

    const openClaims=()=>{
        setOpenClaims(true)
    }

    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("نام شخص"), minWidth: 150,
            cellRenderer:parameters=>
                <>مسعود احمدی محمدآبادی</>
        },
        { headerName: t("GroupName"),minWidth: 150,
            cellRenderer:parameters=>
                <></>
        },

        { headerName: t("نقش"),minWidth: 150,
            cellRenderer:parameters=>
                <></>
        },

        { headerName: t("گروه"),minWidth: 150,
            cellRenderer:parameters=>
                <></>
        },

        { headerName: t("کیف پول"),minWidth: 150,
            cellRenderer:parameters=>
                <></>
        },

        { headerName: t("میزان پورسانت"),minWidth: 150,
            cellRenderer:parameters=>
                <></>
        },


        { headerName: t("operations"), maxWidth: 190,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <span className="table_Btn bg-themeSuccess text-white" >
                        <HPrimeIcon  icon={'credit-card'}  />
                        <p className={"text-xs text-nowrap"}>واریز وجه</p>
                    </span>
                    <span className="table_Btn bg-themeSecondary text-white" >
                        <HPrimeIcon  icon={'calendar'}  />
                        <p className={"text-xs text-nowrap"}>تاریخچه</p>
                    </span>

                </div>
            }

        },

    ];

    const rowData = [

        { id:1, orderReciever: 'Camry',date:'1402/10/3',code:'84732085', Totalprice: 25000,comment:'راضی ام'},
        { id:2, orderReciever: 'Camry',date:'1402/10/3',code:'84732085', Totalprice: 25000,comment:'راضی ام'},
        { id:3, orderReciever: 'Camry',date:'1402/10/3',code:'40000', Totalprice: 25000,comment:'راضی ام'},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };


    const [visible, setVisible] = useState(false);


    const handleShowMessage =(visible,message)=>{
        setVisible(visible);

    }






    return(
        <>
            <div class="space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByNameCode")}</label>
                                <InputText   className=" input w-full" placeholder={t("search")}  />
                            </div>

                        <ButtonSearchList

                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>openClaims()}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                    </>
                                }
                    />


                </div>
                <div className={"H-body-ag-grid space-y-2 relative"}>
                    <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                               enableRtl={true}
                               pagination={true}
                               getRowHeight={getRowHeight}
                               onGridReady={onGridReady}
                               quickFilterText={quickFilterText}
                    />
                </div>
            </div>


            <FantasyModal openModal={IsOpenClaims} closeModal={closeClaims} title={t("Captions")} >
                <div className={"space-y-5"}>
                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Name")}</label>
                            <Select
                                placeholder={t("selection")}
                                className={"w-full "}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("GroupName")}</label>
                            <Select
                                placeholder={t("selection")}
                                className={"w-full "}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Roles")}</label>
                            <Select
                                placeholder={t("selection")}
                                className={"w-full "}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("کیف پول (بستانکاری)")}</label>
                            <InputText className={"input "}/>
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("میزان پورسانت")}</label>
                            <InputText className={"input "}/>
                        </div>

                    </div>
                    <div>
                        <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("AddCaptions")}</button>
                    </div>

                </div>
            </FantasyModal>

        </>
    )
}