import React from "react";

import {ListFilter} from "../../../Commans/Grid/ListFilter";



export const HandleGeneralPeperlessSetting=()=>{

    return(

        <>
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>

                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />

            </div>


            نداریم


        </>

    )

}