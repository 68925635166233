import React from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import {TagsInput} from "react-tag-input-component";
import Select from "react-select";

export const MetaTagTab=({product,relatedProducts,handleValue, handleSelectValue,handleKeywordsChange})=>{
    const { t } = useTranslation();

    return (<>
        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
            <label className="dark:text-gray-300 text-xs">
                {t("WebsiteTitle")}
            </label>
            <InputText onInput={(e)=>handleValue(e)} value={product.seoTitle} name={'seoTitle'} type={"text"} className={"input w-full"} />
        </div>
        {/*<div className=" post-input dark:bg-themeDarkPostInput space-y-2 rounded-md p-2">*/}
        {/*    <label  className="dark:text-gray-300 text-xs">*/}
        {/*        {t("Keywords")}*/}
        {/*    </label>*/}
        {/*    <TagsInput  name={"seoKeyWords"} onChange={(data)=>handleKeywordsChange(data)} value={product.seoKeyWords} placeHolder={t("Keywords")}/>*/}
        {/*</div>*/}

        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
            <label className="dark:text-gray-300 text-xs" >{t("RelatedProducts")}</label>
            <Select
                options={relatedProducts}
                isMulti={true}
                isClearable={false}
                isSearchable={true}
                name={'productSuggestionIds'}
                placeholder={t("selection")}
                onChange={(data)=>handleSelectValue(data,'productSuggestionIds')}
                value={product.productSuggestionIds}
                classNamePrefix={"h_multi_select"}
                className={"select-group"}
            />
        </div>
        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
            <label className="dark:text-gray-300 text-xs">
                {t("WebsiteDescription")}
            </label>
            <InputTextarea onInput={(e)=>handleValue(e)} value={product.seoDescription} name={"seoDescription"} rows={10} cols={40} className={"input w-full"} />
        </div>

    </>)
}