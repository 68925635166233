import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Accordion, AccordionTab} from "primereact/accordion";
import {useTranslation} from "react-i18next";
import {ServiceItem} from "./ServiceItem";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {ExtraHostTab} from "./UpgradeTabs/ExtraHostTab";
import {PackageItem} from "./PackageItem";
import {ExtraMailHost} from "./UpgradeTabs/ExtraMailHost";
import Select from "react-select";
import {NoDataHarmony} from "../../Commans/NoDataHarmony";
import {useDisplayServiceItem} from "./Hooks/useDisplayServiceItem";
import {convertSelectDataHandler, createSettingsUrl, handleGetSettingValue} from "../../CMS/Contents/ContentService";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {useCustomUpgrade} from "./Hooks/useCustomUpgrade";
import {handleAddUpgradeService, handleAdminAddUpgradeService} from "./upgradeService";
import {InputText} from "primereact/inputtext";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import queryString from "query-string";
import {PackageTab} from "./UpgradeTabs/PackageTab";
import {usePackageService} from "./Hooks/usePackageService";
import {ToastContainer} from "react-toastify";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";

import {TemplateTab} from "./UpgradeTabs/TemplateTab";
import {useTemplateService} from "./Hooks/useTemplateService";
import {TemplateItem} from "./TemplateItem";

export const HandleServiceUpgrade = ({currentPath}) => {
  const queryParams = queryString.parse(window.location.search);

  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const[IsShowSpinner,setIsShowSpinner] = useState(false);
  const [errors,setErrors] = useState({});
  const [initialSource,setInitialSource] = useState({services:[],packages:[],currencies:[],discountTypes:[],paymentMethods:[],templates:[]});
  const [refresh,setRefresh] = useState(false);
  const {upgrade,handleValue,handleSelectValue,setUpgrade,handleChangePackageId,handleDateTime,handleGetCurrentType,price,handleUpdateTotalPrice}=useCustomUpgrade()
  const {handleChangePackage,handleAddOrDeletePackage,handleChangePackageDateTime} = usePackageService(setUpgrade,initialSource);
  const {handleChangeTemplate,handleAddOrDeleteTemplateMaster} = useTemplateService(setUpgrade)
  const Referesh=()=>{
    setRefresh(prevState => !prevState);
  }
  const onSetErrors=(errors)=>{
    setErrors(errors)
  }
  const showSpinner=()=>{
    setIsShowSpinner(true)
  }
  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }
  const handleInitialData=(currencies,paymentMethods,templates)=>{
    const currencyData = convertSelectDataHandler(currencies);
    const paymentMethodData = convertSelectDataHandler(paymentMethods);
    const templateData = convertSelectDataHandler(templates);
    return {currencyData,paymentMethodData,templateData}
  }
  const fetchData = async () => {
    try {
      showSpinner();
      const settingNames = [
        'HostPurchaseLimit_IncrementStep',
        'MailHostPurchaseLimit_IncrementStep',
        'HostPurchaseLimit_Maximum',
        'HostPurchaseLimit_Minimum',
        'MailHostPurchaseLimit_Minimum',
        'MailHostPurchaseLimit_Maximum'
      ];
      const settingsUrl = createSettingsUrl('/Settings', settingNames);

      // قالب های عمومی باید از وب سرویس لیست خرید قالب گرفته بشه که پیج بندی داره
      const [response, responseCurrency, responsePackage,responsePaymentMethod,responseTemplate,responseSetting] = await Promise.all([
        GET(`/Upgrades/Items`, {}, 'IEnumerable'),
        GET(`/Upgrades/ActiveCurrency`, {}, 'IEnumerable'),
        GET(`/Websites/Packages/NotInWebsite`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnPaymentMethod`, {}, 'IEnumerable'),
        GET(`/TemplateMasters/WebSite?ItemCount=${3}`,{},'IEnumerable'),
        GET(settingsUrl,{},'single')
    ]);
      if (
          response.status === 200 &&
          responseCurrency.status === 200 &&
          responsePackage.status === 200 &&
          responseTemplate.status === 200 &&
          responseSetting.status === 200
      ) {
        const { currencyData ,paymentMethodData,templateData} = handleInitialData(responseCurrency.data,responsePaymentMethod.data,responseTemplate.data);
        setInitialSource({
          services: response.data,
          currencies: currencyData,
          packages: responsePackage.data,
          paymentMethods: paymentMethodData,
          templates: responseTemplate.data
        });

        setUpgrade(prevState => ({
          ...prevState,
          currencyType: currencyData[0],
          extraHost: {
            ...prevState.extraHost,
            extraHost:handleGetSettingValue('HostPurchaseLimit_Minimum',responseSetting.data.settings) ,
            price: 0,
            minValue:handleGetSettingValue('HostPurchaseLimit_Minimum',responseSetting.data.settings),
            maxValue:handleGetSettingValue('HostPurchaseLimit_Maximum',responseSetting.data.settings),
            step:handleGetSettingValue('HostPurchaseLimit_IncrementStep',responseSetting.data.settings)
          },
          extraMailHost: {
            ...prevState.extraMailHost,
            extraMailHost: handleGetSettingValue('MailHostPurchaseLimit_Minimum',responseSetting.data.settings),
            extraHost: 100,
            price: 0,

            minValue:handleGetSettingValue('MailHostPurchaseLimit_Minimum',responseSetting.data.settings),
            maxValue:handleGetSettingValue('MailHostPurchaseLimit_Maximum',responseSetting.data.settings),
            step:handleGetSettingValue('MailHostPurchaseLimit_IncrementStep',responseSetting.data.settings)

          },
          extraPackages: {
            ...prevState.extraPackages,
            packageIds: []
          },
          extraTemplates: {
            ...prevState.extraTemplates,
            templateMasterIds: []
          },
          creationDate: new Date()
        }));

        hideSpinner();
      } else {
        handleApiError(response, hideSpinner);
      }
    } catch (e) {
      hideSpinner();
    }
  };

  useEffect(()=>{
    fetchData();
  },[refresh])
  const {display,handleSelectServiceItem} = useDisplayServiceItem(setUpgrade,Referesh,initialSource)


  const handleSubmitUpgrade=async ()=>{
    try {
      showSpinner()
      if(currentPath === '/Admin/ServiceUpgrade'){
        const upgradeData = handleAdminAddUpgradeService(upgrade,display);
        const response = await POST(`/Upgrades/${queryParams.websiteId}`,upgradeData,false);
        if(response.status === 200){
          redirectTo(`/Admin/Website/List/Payment/Upgrade?websiteId=${queryParams.websiteId}`);
          hideSpinner();
        }
        else {
          handleApiError(response,hideSpinner);
        }
      }
      else{
        const upgradeData = handleAddUpgradeService(upgrade,display);
        const response = await POST(`/Upgrades`,upgradeData,false);
        if(response.status === 200){
          redirectTo('/InvoiceRequest');
          hideSpinner();
        }
        else {
          handleApiError(response,hideSpinner);
        }
      }
    }catch (e) {
      hideSpinner();
    }
  }




const handleShowPanel=(isPanel)=>{
    if((display.IsOpenExtraHost ||
        display.IsOpenExtraMailHost
        )
        ||
        (upgrade.extraPackages.packageIds.length !== 0 || upgrade.extraTemplates.templateMasterIds.length !== 0)){
      return isPanel ? 'show_panel':'side-content-full'
    }else{
      return ''
    }
}

const [IsToggle,setIsToggle] = useState(false);
const handleShowShowPanelMobile=()=>{
  setIsToggle(prevState => !prevState);
}





  return(
      <div className={"space-y-5"}>
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
          <ListFilter label={null} icon={null}
                      showChildrenTwo={false}
                      childrenOne={
                        <>
                          <ButtonPlus
                              onClick={()=>redirectTo('/Admin/Website/List/Payment/Upgrade')}
                              title={t("Upgrade")}
                              tooltipTitle={t("Upgrade")}
                              icon={"pi-sparkles"}
                          />

                          <ButtonPlus
                              onClick={()=>redirectTo('/Admin/Website/List')}
                              title={t("list")}
                              tooltipTitle={t("WebsiteList")}
                              icon={"pi-list"}
                          />

                        </>
                      }

          />

        </div>
        <div class="box bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine relative min-h-screen">
          {
            IsShowSpinner?
                <div className="huploader_loading" >
                  <div className="relative">
                    <LoadingHarmony/>
                  </div>
                </div>
                :
                null
          }
          <div class="bg-themeInformation dark:bg-themeDarkInformation p-3 text-white text-center rounded-t-md ">
            <h2 class="text-xl">{t("ChooseYourPrograms")}</h2>
            <p class="text-xs ">{t("UptoDateAccesstoOurServices")}</p>
          </div>
          <div class="grid grid-cols-12 relative overflow-hidden p-2 gap-3 py-5">
            <div className={`panel-content side-content ${handleShowPanel(false)}`}>
              <div class="space-y-5 p-2">
                <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 lg:gap-5">
                  <div className="col-span-1 md:col-span-2 xl:col-span-3">
                    <h2 class="text-xl dark:text-gray-300">{t("Modules")}</h2>
                  </div>
                  {
                    initialSource.packages && initialSource.packages.length > 0 ?
                        initialSource.packages.map((item,index)=>{return (
                            <PackageItem packageId={item.packageId}  logo={item.logo} name={item.name} description={item.description} item={item} index={index}
                                         handleAddOrDeletePackage={handleAddOrDeletePackage}
                            />
                        )}):
                        <NoDataHarmony classStyle={"col-span-1 md:col-span-2 xl:col-span-3"}/>
                  }



                  <div className="col-span-1 md:col-span-2 xl:col-span-3">
                    <div className={"flex justify-between gap-2 items-center"}>
                      <h2 class="text-xl dark:text-gray-300">{t("Templates")}</h2>
                      <Link to={"/CMS/Template/Choose"} className={"text-themeInformation dark:text-themeDarkInformation"}>
                        ({t("More")})
                      </Link>
                    </div>
                  </div>
                  {
                    initialSource.templates && initialSource.templates.length > 0 ?
                        initialSource.templates.map((item,index)=>{return (
                            <TemplateItem
                                banner={item.banner}
                                name={item.name}
                                templateMasterId={item.id}
                                description={item.description}
                                item={item}
                                index={index}
                                handleAddOrDeleteTemplateMaster={handleAddOrDeleteTemplateMaster}
                            />
                        )}):
                        <NoDataHarmony classStyle={"col-span-1 md:col-span-2 xl:col-span-3"}/>
                  }
                </div>

                <h2 class="text-xl dark:text-gray-300">{t("OurServices")}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 lg:gap-5">
                  <div className="col-span-1 md:col-span-2 xl:col-span-3">
                  </div>
                  {
                    initialSource.services.length > 0 ? initialSource.services.map((item, index) => (
                        <ServiceItem id={index+1} upgradeType={item.upgradeType} handleSelectServiceItem={handleSelectServiceItem} title={item.title} description={item.description} logo={item.logo} />
                    )) : null
                  }
                </div>

              </div>
            </div>
            <div  className={`panel-side hide-side ${handleShowPanel(true)} ${IsToggle?'top-panel':''} `}>
              <div class="post-input dark:bg-themeDarkPostInput post-input-design box">
                <div onClick={()=>handleShowShowPanelMobile()} className={`flex justify-between items-center p-2 bg-themeInformation dark:bg-themeDarkInformation text-white `}>
                  <div>
                    <p className={"text-xs dark:text-gray-300"}>{t("SelectedProgram")}</p>
                  </div>
                  <div className="arrow-up lg:hidden px-2">
                    <span class="m_scroll_arrows arrow-one"></span>
                    <span class="m_scroll_arrows arrow-two"></span>
                    <span class="m_scroll_arrows arrow-three"></span>
                  </div>
                </div>
                <div className={`max_height85vh  ${IsToggle?'panel-content-show':'panel-content'}`}>
                  <Accordion activeIndex={0} className={"space-y-3 p-2"}>

                    {
                      display.IsOpenExtraMailHost ?
                          <AccordionTab  className="post-input dark:bg-themeDarkSalt700 "
                                         header={
                                           <div className="w-full">
                                             <span>{t("IncreasingEmailServerSpace")}</span>
                                           </div>
                                         }
                          >
                            <ExtraMailHost
                                paymentMethods={initialSource.paymentMethods}
                                currencies={initialSource.currencies}
                                handleValue={handleValue}
                                hideSpinner={hideSpinner}
                                showSpinner={showSpinner}
                                upgrade={upgrade}
                                currentPath={currentPath}
                                handleDateTime={handleDateTime}
                                handleSelectValue={handleSelectValue}

                            />
                          </AccordionTab>
                          :null

                    }







                    {
                          display.IsOpenExtraHost?
                              <AccordionTab  className="post-input dark:bg-themeDarkSalt700 "
                                             header={
                                               <div className="w-full">
                                                 <span>{t("YourWebsiteSpace")}</span>
                                               </div>
                                             }
                              >
                                <ExtraHostTab
                                    paymentMethods={initialSource.paymentMethods}
                                    currencies={initialSource.currencies}
                                    showSpinner={showSpinner}
                                    hideSpinner={hideSpinner}
                                    upgrade={upgrade}
                                    handleValue={handleValue}
                                    handleDateTime={handleDateTime}
                                    handleSelectValue={handleSelectValue}
                                    currentPath={currentPath}
                                    setUpgrade={setUpgrade}
                                />
                              </AccordionTab>:
                              null
                        }








                        <AccordionTab  className="post-input dark:bg-themeDarkSalt700 "

                                       header={
                                         <div>
                                           <span>{t("AddLanguage")}</span>
                                         </div>
                                       }
                        >
                          <div className="space-y-2">
                            <div className="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("WebsiteTitle")}</label>
                              <InputText
                                  className={"input w-full"}
                              />
                            </div>
                            <div className="space-y-1">
                              <label className="text-xs dark:text-gray-300 text-nowrap">{t("SiteAddress")}</label>
                              <div className="flex items-center gap-1">
                                <span className="text-xs dark:text-gray-300 H-direction-ltr">.harmonysystem.org</span>
                                <InputText name={'name'} placeholder="فقط متن و عدد آرش" className="input text-xs" />
                                <span className=" dark:text-gray-300 text-xs H-direction-ltr">https://</span>
                              </div>
                            </div>
                            <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                              <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("country")}</label>
                                <Select
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                    placeholder={t("selection")}
                                />
                              </div>
                              <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>{t("MonetaryUnit")}</label>
                                <Select
                                    options={initialSource.currencies}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'currencyType')}
                                    placeholder={t("selection")}
                                    value={upgrade.currencyType}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                              </div>
                              <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("CalendarType")}</label>
                                <Select
                                    placeholder={t("selection")}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                              </div>
                              <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>{t("Language")}</label>
                                <Select
                                    options={initialSource.currencies}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'currencyType')}
                                    placeholder={t("selection")}
                                    value={upgrade.currencyType}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />

                              </div>
                              <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("Orientation")}</label>
                                <Select
                                    placeholder={t("selection")}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                              </div>
                              <div className="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("Host")}</label>
                                <InputText
                                    className={"input w-full"}
                                />
                              </div>
                            </div>
                            <div className="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("TemplateSelection")}</label>
                              <Select
                                  placeholder={t("selection")}
                                  menuPosition={"fixed"}
                                  classNamePrefix={"h_multi_select"}
                              />
                            </div>
                            <div className="p-2 bg-themeSuccess flex justify-between items-center rounded dark:bg-themeDarkInputBackground">
                              <h2 className="text-white">
                                {t("Price")}
                              </h2>
                              <span className="text-themeInformation text-white ">
                              2.000.000.000 تومان
                            </span>
                            </div>
                          </div>

                        </AccordionTab>
                        {
                          upgrade.extraPackages.packageIds.length !== 0
                              ? upgrade.extraPackages.packageIds.map((item, index) => (
                                  <AccordionTab
                                      key={index} // It's important to add a key when mapping over elements
                                      className="post-input dark:bg-themeDarkSalt700"
                                      header={
                                        <div className="w-full flex justify-between">
                                          <span className="vertical-align-middle">{t("Package")}{item.name}</span>
                                        </div>
                                      }
                                  >
                                    <PackageTab
                                        item={item}
                                        paymentMethods={initialSource.paymentMethods}
                                        currencies={initialSource.currencies}
                                        handleChangePackageDateTime={handleChangePackageDateTime}
                                        handleChangePackage={handleChangePackage}
                                        currentPath={currentPath}
                                        discountTypes={initialSource.discountTypes}
                                        hideSpinner={hideSpinner}
                                        showSpinner={showSpinner}
                                        handleUpdateTotalPrice={handleUpdateTotalPrice}
                                    />
                                  </AccordionTab>
                              ))
                              : null
                        }


                    {
                      upgrade.extraTemplates.templateMasterIds.length !== 0
                          ? upgrade.extraTemplates.templateMasterIds.map((item, index) => (
                              <AccordionTab
                                  key={index} // It's important to add a key when mapping over elements
                                  className="post-input dark:bg-themeDarkSalt700"
                                  header={
                                    <div className="w-full flex justify-between">
                                      <span className="vertical-align-middle">{t("Template")}{item.name}</span>
                                    </div>
                                  }
                              >
                                <TemplateTab
                                    item={item}
                                    paymentMethods={initialSource.paymentMethods}
                                    currencies={initialSource.currencies}
                                    handleChangeTemplate={handleChangeTemplate}
                                    currentPath={currentPath}
                                    hideSpinner={hideSpinner}
                                    showSpinner={showSpinner}
                                />
                              </AccordionTab>
                          ))
                          : null
                    }
                      </Accordion>
                  <div className="space-y-2 p-2">
                    <div className={"space-y-2"}>
                      <label className={"text-xs dark:text-gray-300"}>{t("MonetaryUnit")}</label>
                      <Select
                          options={initialSource.currencies}
                          isMulti={false}
                          isSearchable={true}
                          onChange={(data)=>handleSelectValue(data,'currencyType',true)}
                          placeholder={t("selection")}
                          value={upgrade.currencyType}
                          menuPosition={"fixed"}
                          classNamePrefix={"h_multi_select"}
                      />
                    </div>

                    {currentPath === '/Admin/ServiceUpgrade' && (
                        <>
                          <label className="text-xs dark:text-gray-300">{t("CreationDate")}</label>
                          <DateTimePicker
                              value={upgrade.creationDate}
                              handleDateTime={(dateTime) => handleDateTime('creationDate', dateTime, true)}
                              format={'YYYY/MM/DD'}
                          />
                        </>
                    )}

                    <div className={"flex justify-between items-center rounded-md p-2 bg-themeSuccess dark:bg-themeDarkInputBackground"}>
                      <h2 className={"text-white dark:text-gray-300"}>{t("TotalAmount")}</h2>
                      <span className="text-white dark:text-gray-300">
                       {/*{price.price}{handleGetCurrentType(initialSource.currencies,price.currency)}*/}
                        {2000}
                      </span>
                    </div>
                    <div className="space-y-2">
                      <button type="button" onClick={()=>handleSubmitUpgrade()} className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("Billcreation")}</button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <ToastContainer/>
        </div>
      </div>

  )
}
