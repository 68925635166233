import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {useBackup} from "./useBackup";
import {CheckBoxSection} from "../../Commans/Globals/CheckBoxSection";
import Select from "react-select";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {handleProcessBackupData} from "./backupService";
export const HandleBackup=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [initialSource,setInitialSource] = useState({sections:[],domainTokens:[]});
    const queryParams = queryString.parse(window.location.search);
    const [showSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(0)
    const {handleChangeSection,handleChangeDomainToken,cleanData,ResetCleanData} = useBackup(initialSource.domainTokens);

    // handles loading spinner in api request
    const onApiError=()=>{
        setShowSpinner(false);
    }

    const Refresh=(state)=>{
        setRefresh({...state});
    }

    const handleInitialData=(sections,domainTokens)=>{
        const sectionData = convertSelectDataHandler(sections);
        const domainTokensData = convertSelectDataHandler(domainTokens);
        domainTokensData.unshift({ label: '*', value: '*' });
        setInitialSource({
            domainTokens: domainTokensData,
            sections: sectionData
        })
    }

    // fetches all data from database in initial time of component
    const fetchData=async()=>{
        try {
            setShowSpinner(true)
            const responseSection = await GET(`/Enumerations/EnWebsiteFilesSections`,{},'IEnumerable');
            const responseDomainToken = await GET(`/Websites/WebSitesDomainToken`,{},'IEnumerable');
            if(responseSection.status === 200 && responseDomainToken.status === 200){
                handleInitialData(responseSection.data,responseDomainToken.data);
                setShowSpinner(false);
            }
            else{
                handleApiError(responseSection,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        fetchData()
    },[refresh])


    const handleSubmitBackup=async()=>{
        try {
            setShowSpinner(true)
            if(cleanData.section.length === 0){
                handleFailureMessage(t("PleaseSelectSection"));
                setShowSpinner(false)
                return;
            }
            if(cleanData.domainTokens.length === 0){
                handleFailureMessage(t("PleaseSelectDomain"));
                setShowSpinner(false)
                return;
            }
            const dataJson = handleProcessBackupData(cleanData);
            const response = await POST(`/Management/BackupData`,dataJson,false);
            if(response.status === 200){
                handleSuccessMessage(t("DataBackupWasDoneSuccessfully"));
                ResetCleanData();
                Refresh(response.status);
                redirectTo('/Dashboard');
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError();
            console.log(e);
        }
    }















    return(
        <div className={"space-y-5"}>

            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>


                                </>
                            }

                />

            </div>
            <div className={"box bg-white dark:bg-themeDarkSalt700 p-3 relative"}>
                {

                    showSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-y-5"}>
                    <div className={"space-y-4"}>
                        <div className={"space-y-3"}>
                            <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                                <h2 className={"dark:text-gray-300 text-xs"}>{t("Sectionselection")}</h2>
                            </div>
                            <CheckBoxSection
                                data={initialSource.sections}
                                onChange={handleChangeSection}
                            />
                        </div>

                        <div className={"space-y-3"}>
                            <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                                <h2 className={"dark:text-gray-300 text-xs"}>{t("Domain")}</h2>
                            </div>
                            <div className={"px-2"}>
                                <div className={"space-y-1 post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                                    <label
                                        className={"dark:text-gray-300 text-xs"}>{t("Desireddomain")}</label>
                                    <div>
                                        <Select
                                            options={initialSource.domainTokens ?? []}
                                            isMulti={true}
                                            isSearchable={true}
                                            onChange={(data) => handleChangeDomainToken(data)}
                                            placeholder={t("selection")}
                                            value={cleanData.domainTokens}
                                            className={"select-group"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type={"button"} onClick={() => handleSubmitBackup()}
                                className={"button bg-themeInformation dark:bg-themeDarkInformation w-full text-white"}>
                            {t("Register")}
                        </button>
                    </div>
                    <div>
                        <img src="/Assets/Image/bg-img/Backup.svg" alt="backup"
                             className={" max-w-3/4 mx-auto"}/>
                    </div>
                </div>
            </div>
        </div>


    )
}