import React from "react";
import arabic_ar from "react-date-object/locales/arabic_ar";
import indian_hi from "react-date-object/locales/indian_hi";
import persian_fa from "react-date-object/locales/persian_fa";
import persian from "react-date-object/calendars/persian";
import arabic from "react-date-object/calendars/arabic";
import indian from "react-date-object/calendars/indian";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import InputIcon from "react-multi-date-picker/components/input_icon";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const DateTimeElement=({item,index,handleChangeData})=>{
    const handleCalendarView=()=>{
        switch (item.properties.calendarView?.value) {
            case 0:
                return false
            case 1 :
                return true
            case 2:
                return true
        }
    }

    const handleCalendarLanguage=()=>{
        switch (item.properties.calendarLanguage?.value) {
            case 0:
                return null
            case 1 :
                return arabic_ar
            case 2:
                return indian_hi
            case 3 :
                return  persian_fa
        }
    }
    const handleCalendarType=()=>{
        switch (item.properties.calendarType?.value) {
            case 0:
                return persian
            case 1 :
                return arabic
            case 2:
                return null
            case 3:
                return indian
        }
    }

    return (
        <>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <div>
                <DatePicker
                    calendar={handleCalendarType()}
                    locale={handleCalendarLanguage()}
                    editable={handleCalendarView()}
                    format={item.properties.format}
                    name={item.properties.name}
                    value={new Date(item?.data?.value)}
                    disabled={item.properties.isReadOnly}
                    placeholder={item?.properties?.description}
                    id={item.properties.id}
                    type={'Calendar'}
                    onChange={(value) => handleChangeData(index, 'value', value)}
                    plugins={[<DatePanel position="top" disabled={true} />]}
                    render={<InputIcon/>}
                />
            </div>

        </>
    )

}