import React, {useEffect, useRef, useState} from "react";
import "../../Styles/dist/css/Editors/quill.snow.css"
import Editor from "@monaco-editor/react";

import queryString from "query-string";
import { handleSuccessMessage} from "../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../Commans/Loadings/LoadingHarmony";
import {ToastContainer} from "react-toastify";
import Swal from "sweetalert2";
import {handleApiError} from "../../Services/Globals/Errors/handleApiError";
import {GET, POST, PUT} from "../../Services/AxiosService/AxiosApiService";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {ShortCode} from "../Commans/Globals/ShortCode";
import {validate} from "../../Services/Globals/validate";
import {codeSchema} from "../CMS/TemplateManagement/ShortCodes/codeSchema";
import {convertSelectDataHandler} from "../CMS/Contents/ContentService";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {AlertSetting, fileManager} from "../../Services/Globals/Public/AppSetting";
import {useTranslation} from "react-i18next";
import {handleCreateShortCode} from "../CMS/TemplateManagement/ShortCodes/shortCodeService";

export const HandleSourceCodePage=()=>{
    const { t } = useTranslation();
    const[spinner,setSpinner] = useState(false);
    const[result,setResult] = useState(0)
    const [code, setCode] = useState("");
    const[modal,setModal] = useState(false);

    const [fileExtension,setFileExtension] = useState("");
    const [fixedOptions,setFixOptions] = useState({templates:[]});

    const onApiError=()=>{
        setSpinner(false);
    }

//........
    const queryParams = queryString.parse(window.location.search);

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
//     const tabs = [
//         {
//             id: "tab_one",
//             target: "#tab_one",
//             label: queryParams.extension,
//             icon: "x",
//         },
//     ];

    // const handleGeoTabClick = (tabId) => {
    //     setGeoActiveTab(tabId);
    // };
    // اطلاعات tab نقشه رو برای tabMenu فراهم میکنیم
    // const geoTab = [
    //     {
    //         id: "tab_Browser",
    //         target: "tab_Browser",
    //
    //         label: "Browser",
    //         //subLabel:''
    //         icon: "globe",
    //     },
    //
    // ];


    const options = {
        autoIndent: 'full',
        contextmenu: true,
        fontFamily: 'monospace',
        fontSize: 13,
        lineHeight: 24,
        hideCursorInOverviewRuler: true,
        matchBrackets: 'always',
        minimap: {
            enabled: true,
        },
        scrollbar: {
            horizontalSliderSize: 4,
            verticalSliderSize: 18,
        },
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,
    };



    const handleEditorChange = (newValue) => {
        setCode(newValue);
    };
    const runCode = (language) => {

        if (language === "html") {
           // document.getElementById('tab_Browser').innerHTML = code;
        } else if (language === "javascript") {
            var result = eval(code);
            console.log(result);
        }
        const newTab = window.open('about:blank', '_blank');
        const resultHTML = `
            <!DOCTYPE html>
            <html>
                <head>
                    <title></title>
                </head>
                <body>
                <div class="app main-page dark:bg-themeDarkApp">
                 <div id="react-root"></div>
                    ${code}
                </div>
                   
                </body>
            </html>
        `;
        newTab.document.write(resultHTML);
    };

    const handleGetFileContent = async ()=>{
        try {
            setSpinner(true);
            const responseTemplates = await GET('/Templates/GetAllTemplatesSelect',{},'IEnumerable');
            if(responseTemplates.status === 200){
                const templates = convertSelectDataHandler(responseTemplates.data);
                setFixOptions({templates: templates});
                setShortCode((prevState)=>({...prevState,templateId: templates[0]}))
            }else{
                handleApiError(responseTemplates,onApiError)
            }
            const queryParams = queryString.parse(window.location.search);
            const fileExtension = queryParams.extension.split(".").pop();
            setFileExtension(fileExtension);
            console.log(queryParams.filePath);
            const parts = queryParams.filePath.split('/');
            const path = parts.join(fileManager.separator) + fileManager.separator;
            const response = await GET(`/Public/GetFileContent`,{FilePath:path},'single');
            if(response.status === 200){
                setSpinner(false);
                setCode(response.data.fileContent);
            }else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const GetPreviousFileContent = async()=>{
        try {
            setSpinner(true);
            const queryParams = queryString.parse(window.location.search);
            const fileExtension = queryParams.extension.split(".").pop();
            setFileExtension(fileExtension);
            const parts = queryParams.filePath.split('/');
            const path = parts.join(fileManager.separator) + fileManager.separator;
            //const newPath = path.replace(/\+TemplateMasters|Templates/g, '');
            const response = await GET(`/Public/GetPreviousFileContent`, {FilePath:path},'single');
            if(response.status === 200){
                setSpinner(false);
                setCode(response.data.previousContent);
                handleSuccessMessage(t("PreviousversionRevertedsuccessfully"));
            }else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleEditFileContent = async()=>{
       try {
           setSpinner(true);
           const queryParams = queryString.parse(window.location.search);
           const parts = queryParams.filePath.split('/');
           const path = parts.join(fileManager.separator) + fileManager.separator;
           const response = await PUT(`/Public/EditFileContent`, {filePath:path,fileContent:code,rootFolder:parts[2]},false);
           if(response.status === 200){
               setSpinner(false);
               setResult({...response.status});
               handleSuccessMessage(t("Filehasbeensuccessfullyedited"));
           }else{
               handleApiError(response,onApiError)
           }
       }catch (e) {
           console.log(e);
       }
    }

    useEffect( ()=>{
         handleGetFileContent();
    },[result])



    const GetFileBack = async ()=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                await GetPreviousFileContent()
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    const [shortCode,setShortCode] = useState({id:null,name:null,value:null,type:0,templateId:null})

    const [errors, setErrors] = useState({});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setShortCode({...shortCode,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleEditorValueChange=(value)=>{
        setShortCode({ ...shortCode, value:value});

    }

    const handleSelectValue=(data,name)=>{
        setShortCode({ ...shortCode, [name]: data });
    }
    const handleCloseShortCode=()=>{
        setModal(false);
        setShortCode({name: '',type: 0,value:'',id: null,templateId: null});
        setErrors({})
    }

    const onSetErrors = (errors)=>{
        setErrors(errors);
    }
   const handleAddCodeSnippet = async (e)=>{
        e.preventDefault();
       const isValid = validate(codeSchema,shortCode,onSetErrors);
       if (!isValid) {
           return;
       }

       try {
           setSpinner(true)
           const codes = handleCreateShortCode(shortCode);
           const {name, type,value,templateId} = codes;
           const response = await POST('/Templates/CreateSnippetCode',{name:name,type:type,value:value,templateId:templateId},false);
           if (response.status === 200) {
               setSpinner(false);
               setResult({...response.status});
               handleCloseShortCode();
               handleSuccessMessage(t("Shortcodehasbeencreated"));
           } else {
               handleApiError(response,onApiError);
           }
       }catch (e) {
           console.log(e);
       }
   }
    const handleIsHtml = (data)=>{
        if(data === true){
            setShortCode({...shortCode,type: 1});
        }else{
            setShortCode({...shortCode,type: 0});
        }
    }









    let x = queryParams.filePath.split("/");




    return (
        <>
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3" >
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>handleEditFileContent()}
                                        icon={"pi-save"}
                                        title={t("register")}
                                        tooltipTitle={t("DataRegistration")}
                                    />

                                    {

                                        x[1]==="TemplateMasters" || x[1]==="Templates" ?
                                            <ButtonPlus
                                                onClick={()=>GetFileBack()}
                                                icon={"pi-sync"}
                                                title={t("Recovery")}
                                                tooltipTitle={t("RollbackPreviousVersion")}

                                            />
                                            :null

                                    }

                                    <ButtonPlus
                                        onClick={()=>runCode(fileExtension)}
                                        icon={"pi-play-circle"}
                                        title={t("Run")}
                                        tooltipTitle={t("RunCode")}
                                    />
                                    <ButtonPlus
                                        onClick={()=>setModal(true)}
                                        icon={"pi-code"}
                                        title={t("ShortCode")}
                                        tooltipTitle={t("CreatingAShortCode")}
                                    />


                                </>
                            }
                />

            </div>
            <div class="relative" >

                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="containter_OnlineEditor relative " style={{direction:'ltr'}}>
                    <Editor  language={"JavaScript"} theme="vs-dark" className="online_editor"
                             value={code}
                             onChange={handleEditorChange}
                             options={options}
                    />

                </div>
            </div>
            <ToastContainer />

            <FantasyModal openModal={modal}  closeModal={handleCloseShortCode} title={t("CreatingAShortCode")} customeClassName={"modal-xl"}>
                <ShortCode
                    shortCode={shortCode}
                    handleAddCodeSnippet={handleAddCodeSnippet}
                    errors={errors}
                    handleValue={handleValue}
                    options={fixedOptions}
                    handleSelectValue={handleSelectValue}
                    handleEditorChange={handleEditorValueChange}
                    handleIsHtml={handleIsHtml}
                />
            </FantasyModal>


        </>

    )
}