import React, {useState} from "react";
import {TabMenu} from "../Commans/UIParts/TabMenu";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {MultiDataSelect} from "../Commans/UIParts/MultiDataSelect";
export const HandleReplayPaperLess = () => {

  const [activeTab, setActiveTab] = useState("tab_one");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
  const tabs = [
    {
      id: "tab_one",
      label: "محتویات اصلی",
      icon: "help-circle",
    },
    {
      id: "tab_two",
      label: "فایل ضمیمه",
      icon: "divide",
    },
  ];



  return(

      <>
        <div className=" space-y-5">
          <div class="grid grid-cols-12 gap-5">
            <div class="col-span-12 lg:col-span-8">
              <div>
                <InputText className={"input w-full"} placeholder="عنوان پیام..."/>
              </div>
              <div class="post box  mt-5 bg-white dark:bg-themeDarkSalt700">
                <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                {/*<div class="post__tabs nav-tabs flex bg-gray-200 text-gray-600">*/}
                {/*  <a title="Fill in the article content" data-toggle="tab" data-target="#content" href="javascript:"*/}
                {/*     className="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center md:gap-2 active">*/}
                {/*    <i data-feather="file-text" class="w-4 h-4"></i> محتویات <span class="hidden md:block">اصلی</span>*/}
                {/*  </a>*/}
                {/*  <a title="Adjust the meta title" data-toggle="tab" data-target="#attached-file" href="javascript:"*/}
                {/*     className="tooltip w-full sm:w-40 py-4 text-center flex justify-center items-center md:gap-2"> <i*/}
                {/*      data-feather="code" class="w-4 h-4"></i><span class="hidden md:block"></span> فایل ضمیمه</a>*/}
                {/*</div>*/}
                <div class="post__content tab-content">
                  <div className={`tab-content__pane p-1 md:p-5 space-y-5 ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                    <div className="space-y-5 post-input dark:bg-themeDarkPostInput p-3 rounded-md">
                      <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300"> محتوای نامه:</label>
                        <InputTextarea name={"excerpt"} rows={10} cols={30} className={"input w-full"} />
                      </div>
                      <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300"> پاسخ نامه:</label>
                          <InputTextarea name={"excerpt"} rows={10} cols={30} className={"input w-full"} />
                      </div>
                    </div>
                  </div>
                  <div className={`tab-content__pane p-1 md:p-5 space-y-5 ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                    <div class="space-y-2">
                      <h3 className="text-xs dark:text-gray-300">فایل ضمیمه:</h3>
                      <ul class=" flex flex-col space-y-1">
                        <li className=" grid grid-cols-3 items-center p-2 border rounded-md dark:border-themeDarkColorLine">
                          <div>
                            <h3 className="text-xs dark:text-gray-300">نام فایل:</h3>
                          </div>
                          <div>
                            <h3 className="text-xs dark:text-gray-300"> حجم فایل:</h3>
                          </div>
                          <div class="flex justify-between items-center">
                            <h3 className="text-xs dark:text-gray-300">دانلود:</h3>
                            <a href="#" className="button bg-themeInformation w-24 text-white dark:bg-themeDarkInformation">دانلود</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-12 lg:col-span-4">
              <div class="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700 ">
                <div className="grid grid-cols-2 gap-5">
                  <div class=" space-y-2">
                    <label className="text-xs dark:text-gray-300">تنظیم کننده</label>
                    <div>
                      <MultiDataSelect placeholder={"انتخاب"} className={""} />
                      {/*<select className="select2 w-full">*/}
                      {/*  <option value="1">خانم مازندرانی</option>*/}
                      {/*  <option value="2">آقای رضا کهریزی</option>*/}
                      {/*  <option value="3">آقای مسعود احمدی</option>*/}
                      {/*</select>*/}
                    </div>
                  </div>
                  <div class="space-y-2">
                    <label className="text-xs dark:text-gray-300">تاریخ پیام</label>
                    <InputText className={" input w-full"} />
                  </div>
                  <div class="space-y-2">
                    <label className="text-xs dark:text-gray-300">زمان </label>
                    <InputText className={" input w-full"} />
                  </div>
                  <div class="space-y-2">
                    <label className="text-xs dark:text-gray-300">اندیکاتور </label>
                    <InputText className={" input w-full"} />
                  </div>
                  <div class=" space-y-2">
                    <label className="text-xs dark:text-gray-300"> عملیات</label>
                    <div>
                      <MultiDataSelect placeholder={"انتخاب"} className={""} />
                      {/*<select className="select2 w-full">*/}
                      {/*  <option value="1">ارجاع</option>*/}
                      {/*  <option value="2">دستور</option>*/}
                      {/*  <option value="3">پاراف</option>*/}
                      {/*</select>*/}
                    </div>

                  </div>
                  <div class=" space-y-2">
                    <label className="text-xs dark:text-gray-300">گیرنده</label>
                    <div class="">
                      <MultiDataSelect placeholder={"انتخاب"} className={""} />
                      {/*<select className="select2 w-full">*/}
                      {/*  <option value="1">...</option>*/}
                      {/*  <option value="2">محمد اکبری</option>*/}
                      {/*  <option value="3">فرم پرداخت</option>*/}
                      {/*  <option value="3">فرم نمایندگی</option>*/}
                      {/*</select>*/}
                    </div>
                  </div>
                  <div class=" space-y-2">
                    <label className="text-xs dark:text-gray-300"> فرم</label>
                    <div class="">
                      <MultiDataSelect placeholder={"انتخاب"} className={""} />
                      {/*<select className="select2 w-full">*/}
                      {/*  <option value="1">...</option>*/}
                      {/*  <option value="2">فرم استخدام</option>*/}
                      {/*  <option value="3">فرم پرداخت</option>*/}
                      {/*  <option value="3">فرم نمایندگی</option>*/}
                      {/*</select>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center lg:justify-end gap-3">
            <div>
              <button className=" button w-24 text-white bg-themeDanger ">انصراف</button>
            </div>
            <div>
              <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation">ارسال</button>
            </div>
          </div>
        </div>
      </>

  )
}