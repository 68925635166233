import React, {useContext, useEffect, useState} from "react";
import '../../../Styles/Internal/error.css';
import {useTranslation} from "react-i18next";
import {StateContext} from "../StateProvider";

export const NotPermitted = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();
    const [index, setIndex] = useState(0);
    const { lang } = useContext(StateContext);


    useEffect(() => {
        const body = document.body;
        if (lang === "en-US") {
            // import("../../../Styles/dist/css/En_app.css");
            body.classList.add("h-ltr");
        } else {
            body.classList.remove("h-ltr");
        }

        return () => {
            body.classList.remove("h-ltr");
        };
    }, [lang]);



    useEffect(() => {
        const originalMessage = ` <p>> <span>${t("ErrorCode")}</spn> : " <i>HTTP 403 Forbidden</i> "</p>
        <p>> <span>${t("ErrorDescription")}</span> : " <i>${t("ErrorTitle")}</i>" </p>
        <p>> <span>${t("ErrorPossiblyCausedBy")}</span> : [ <b>${t("ErrorPossiblyTitle")}</b> ]</p>
        <p>> <span>${t("SomePage")}</span> : [ <a href="/src/Components/Public">Home Page</a>, <a href="/src/Components/Public">About Us</a>, <a href="/src/Components/Public">Contact Us</a>, <a href="/src/Components/Public">Blog</a>... ]</p>
        <p>> <span>${t("GoodDay")}</span></p>`;


        const se = setInterval(() => {
            setIndex((prevIndex) => {
                const newIndex = prevIndex + 1;
                setErrorMessage(originalMessage.slice(0, newIndex) + "|");

                if (newIndex === originalMessage.length) {
                    clearInterval(se);
                    setErrorMessage(originalMessage);
                }

                return newIndex;
            });
        }, 20);

        return () => clearInterval(se);
    }, []);

    return (
        <>
            <div className="bg-color ltr-direction not-found">
                <h2>403</h2>
                <div className="not-found-div space-y-5">
                    <div className={"space-y-5"} dangerouslySetInnerHTML={{ __html: errorMessage }} />
                    <div className="flex items-center">
                        <a className="rounded-md block p-2 text-white  border border-white " href="/Dashboard" title="harmony">
                            {t("dashboard")}
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotPermitted;
