import React, {useState} from "react";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";

export const useUserLocations=(showSpinner,hideSpinner)=>{
    const [countries,setCountries] = useState([]);
    const [locationList, setLocationList] = useState([
        { userAddressId:null,countryId: null, stateProvinceId: null, cityId: null, states: [], cities: [],postalCode:null,address:null,detailsOfAddress:null},
    ]);

    const fetchStates = async (data, index) => {
        try {
            showSpinner();
            const countryId = data.value;
            const response = await GET(`/Countries/${countryId}/StateProvinces/Selection`,{},'IEnumerable');
            const stateData = convertSelectDataHandler(response.data);
            if(response.status === 200){
                const newLocationList = [...locationList];
                newLocationList[index].states = stateData;
                newLocationList[index].cities = []; // Clear cities when a new state is selected
                setLocationList(newLocationList);
                hideSpinner();
            }
        } catch (error) {
            hideSpinner();
        }
    };
    const fetchCities = async (data, index) => {
        try {
            showSpinner();
            const stateId = data.value;
            const response = await GET(`/Countries/${stateId}/Cities/Selection`,{},'IEnumerable');
            const cityData = convertSelectDataHandler(response.data);
            if(response.status === 200){
                const newLocationList = [...locationList];
                newLocationList[index].cities =cityData;
                setLocationList(newLocationList);
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }

        } catch (error) {
            hideSpinner();
        }
    };

    const handleCountryChange = (countryId, index) => {
        const newLocationList = [...locationList];
        newLocationList[index].countryId = countryId;
        newLocationList[index].stateProvinceId = null;
        newLocationList[index].cityId = null;
        setLocationList(newLocationList);
        if (countryId) fetchStates(countryId, index);
    };

    const handleStateChange = (stateId, index) => {
        const newLocationList = [...locationList];
        newLocationList[index].stateProvinceId = stateId;
        newLocationList[index].cityId = null;
        setLocationList(newLocationList);
        if (stateId) fetchCities(stateId, index);
    };

    const handleCityChange = (cityId, index) => {
        const newLocationList = [...locationList];
        newLocationList[index].cityId = cityId;
        setLocationList(newLocationList);
    };

    const handleAddLocation = () => {
        setLocationList([
            ...locationList,
            {
                countryId:null,
                stateProvinceId: null,
                cityId: null,
                states: [],
                cities: [],
                address: null,
                userAddressId: null,
                postalCode: null,
                detailsOfAddress: null
            },
        ]);
    };
    const handleRemoveLocation = (index) => {
        if (locationList.length === 1) {
            setLocationList([
                { countryId: null, stateProvinceId: null, cityId: null, states: [], cities: [],address: '',detailsOfAddress: '',postalCode: ''},
            ]);
        } else {
            const newLocationList = locationList.filter((_, i) => i !== index);
            setLocationList(newLocationList);
        }
    };
    const handleChangeField = (field, value, index) => {
        const newLocationList = [...locationList];
        newLocationList[index][field] = value;
        setLocationList(newLocationList);
    };


    const handleGetSubmittedLocations = async (data, countries) => {
        showSpinner();
        const newLocationList = await Promise.all(
            data.map(async (loc) => {
                const countryId = loc.countryId;
                const stateProvinceId = loc.stateProvinceId;
                const cityId = loc.cityId;

                let stateData = [];
                let cityData = [];

                if (countryId) {
                    const responseStates = await GET(`/Countries/${countryId}/StateProvinces/Selection`, {}, "IEnumerable");
                    stateData = convertSelectDataHandler(responseStates.data);
                }

                if (stateProvinceId) {
                    const responseCities = await GET(`/Countries/${stateProvinceId}/Cities/Selection`, {}, "IEnumerable");
                    cityData = convertSelectDataHandler(responseCities.data);
                }

                const country = countries.find((item) => item.value === countryId);
                const state = stateData.find((item) => item.value === stateProvinceId);
                const city = cityData.find((item) => item.value === cityId);

                return {
                    countryId: country ? { value: country.value, label: country.label } : null,
                    stateProvinceId: state ? { value: state.value, label: state.label } : null,
                    cityId: city ? { value: city.value, label: city.label } : null,
                    states: stateData,
                    cities: cityData,
                    userAddressId:loc.userAddressId,
                    address:loc.address,
                    detailsOfAddress:loc.detailsOfAddress,
                    postalCode:loc.postalCode
                };
            })
        );
        setLocationList(newLocationList);
        hideSpinner();
    };
    return {
        countries,
        setCountries,
        locationList,
        setLocationList,
        handleAddLocation,
        handleRemoveLocation,
        handleCountryChange,
        handleStateChange,
        handleCityChange,
        handleGetSubmittedLocations,
        handleChangeField
    }
}