import React from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import {UserGroupBox} from "./UserGroupBox";
import {UserRoleBox} from "./UserRoleBox";

export const NaturalTab=({user})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className={"space-y-3"}>
                {/*اطلاعات مشتری*/}
                <div className={"space-y-3"}>
                    <div className={"bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x p-2 border-themeInformation dark:border-themeDarkInformation"}>
                        <p className={"text-xs dark:text-gray-300"}> {t("CustomerInformation")}</p>
                    </div>
                    <div className={"space-y-5 px-2"}>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-5"}>
                            <div className={"space-y-3"}>
                                <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("fullName")}</p>
                                    </div>
                                    <div className={"flex-1 flex gap-2"}>
                                        <InputText value={user?.fullName} className={"input w-full"} disabled={true}/>
                                    </div>
                                </div>
                                <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p  className={"text-xs dark:text-gray-300"}>{t("DateofBirth")}</p>
                                    </div>
                                    <div className={"flex-1 "}>
                                        <InputText value={user?.dateOfBirth ?? '-'} className={"input w-full"} disabled={true} />
                                    </div>
                                </div>
                                <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("NationalIdNumber")}</p>
                                    </div>
                                    <div className={"flex-1 "}>
                                        <InputText className={"input w-full"} disabled={true} value={user?.nationalCode??'-'} />
                                    </div>
                                </div>
                            </div>
                            <div className={"space-y-3"}>
                                <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("methodOfIntroduction")}</p>
                                    </div>
                                    <InputText className={"input w-full "} value={user?.introductionMethod ?? '-'} disabled={true} />
                                </div>
                                <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("occupation")}</p>
                                    </div>
                                    <div className={"flex-1"}>
                                        <InputText value={user?.job ?? '-'}  className={"input  w-full "}  disabled={true} />
                                    </div>
                                </div>
                                <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("Referrer")}</p>
                                    </div>
                                    <div className={"flex-1"}>
                                        <InputText value={user?.presenterUserName ??'-'} className={"input  w-full "}  disabled={true} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"space-y-3"}>
                            <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-2 flex flex-col md:flex-row md:items-center gap-2"}>
                                <p className={"text-xs dark:text-gray-300 text-nowrap flex-shrink-0"}>{t("Roles")}:</p>
                                <div className={"flex gap-2 items-center flex-wrap px-2 max_height10"}>
                                    {user?.roles.length > 0 ? (
                                        user.roles.map((item, index) => (
                                            <UserRoleBox key={index} name={item.name} />
                                        ))
                                    ) : null}
                                </div>
                            </div>
                            <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-2 flex flex-col md:flex-row md:items-center gap-2"}>
                                <p className={"text-xs dark:text-gray-300 text-nowrap flex-shrink-0"}>{t("Groups")}</p>
                                <div className={"flex gap-2 items-center flex-wrap px-2 max_height10"}>
                                    {user?.groups.length > 0 ? (
                                        user.groups.map((item, index) => (
                                            <UserGroupBox key={index} title={item.title} />
                                        ))
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*اطلاعات مشتری*/}
                <div>
                    <div className={"space-y-3 md:col-span-2"}>
                        <div className={"flex gap-2 post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                            <div className={"min-w-20"}>
                                <p className={"text-xs dark:text-gray-300"}>{t("description")}</p>
                            </div>
                            <div className={"flex-1"}>
                                <InputTextarea disabled={true} row={5} value={user?.description ?? '-'} className={"input w-full"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}