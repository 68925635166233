import React, { useState } from 'react';

const ProgressBar = ({ initialProgress = 40 }) => {
    const [progress, setProgress] = useState(initialProgress); // مقدار اولیه درصد پیشرفت

    const getProgressStyle = () => {
        const percentage = Math.min((progress / 100) * 100, 100);
        return {
            width: `${percentage}%`,
        };
    };

    const getProgressBarClass = () => {
        return progress < 50 ? 'bg-themeDanger' : 'bg-themeSuccess';
    };

    return (
        <>
            <div className={`absolute h-full rounded-full progress-bar-striped  ${getProgressBarClass()}`}
                style={getProgressStyle()}
            ></div>
            <span className="absolute text-xs end-1/2 top-1/2 -translate-y-1/2 text-white z-10 font-light">
                {progress}%
            </span>
        </>
    );
};

export default ProgressBar;
