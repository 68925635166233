export function handleConvertAddNumber(obj,properties){
    const data = {
        name:obj.name === null || obj.name === undefined?null:obj.name,
        fromNumber:obj.fromNumber === null || obj.fromNumber === undefined?null:obj.fromNumber,
        panelUrl:obj.panelUrl === null || obj.panelUrl === undefined?null:obj.panelUrl,
        operator:obj.operator === null || obj.operator === undefined?null:obj.operator.value,
        stateShow:obj.stateShow,
        properties:properties
    }
    return data;
}
export function handleConvertEditNumbers(obj,properties){
    const data = {
        id:obj.id,
        name:obj.name === null || obj.name === undefined?null:obj.name,
        fromNumber:obj.fromNumber === null || obj.fromNumber === undefined?null:obj.fromNumber,
        panelUrl:obj.panelUrl === null || obj.panelUrl === undefined?null:obj.panelUrl,
        operator:obj.operator === null || obj.operator === undefined?null:obj.operator.value,
        stateShow:obj.stateShow,
        properties:properties
    }
    return data;
}


export function GetOperatorsData(operators) {
    const operator = operators.map(item => ({
        label: item.value,
        value: item.key,
        pspType:item.pspType,
        fields: item.fields.map((field,index) => ({...field, fakeId: new Date().getTime() + index}))}));
    return operator;
}

export function GetInitialProperties(fields){
    const properties = fields.map((fieldItem) => ({
        id:null,
        fakeId: fieldItem.fakeId,
        [fieldItem.name]: null,
        type:fieldItem.type,
        name:fieldItem.name,
        isRequired:fieldItem.isRequired
    }));
    return properties;
}
export function CreateDynamicProperties(properties){
    const dynamicProperties = {};
    properties.forEach(item => {
        dynamicProperties[item.name] = item.value;
    });
    return dynamicProperties;
}
export function GetDynamicPropertiesFromServer(data){
    const initialProperties = Object.keys(data|| {}).map((key) => ({
        name: key,
        value: data[key]
    }));
    return initialProperties;
}


