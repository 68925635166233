import React from "react";

import {RecieptSaver} from "../../Commans/Accounting/Banks/RecieptSaver";
import {useTranslation} from "react-i18next";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const SuccessPay = ({trackingCode,transactionCode,amount,transactionDate,userAvatar,userFullName,currencyName,websiteName,webSiteLogo,})=>{
    const { t } = useTranslation();

    return (<>
        <div className={"p-3 space-y-5"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-5"}>
                <div className="card">
                    <img src="/Assets/Image/bg-img/passed.svg" alt="backup" className={"size-full object-contain"}/>
                </div>

                <div className={"space-y-2 flex items-center justify-center"}>
                    <div className="space-y-5 w-full">
                        <div className={"space-y-5"}>
                            <HPrimeIcon icon={"pi-check-circle"} iconClass={"text-themeSuccess mx-auto"} />
                            <h2 className=" text-2xl font-bold xl:text-3xl text-themeSuccess text-center">
                                {t("SuccessfulTransaction")}
                            </h2>
                        </div>
                        <div className="space-y-4 bg-white dark:bg-themeDarkSalt700 shadow-md after_design_pay">
                            <div className={"flex items-center justify-around gap-5 p-5"}>
                                <div className={"space-y-2"}>
                                    <span className={"size-12 border-2 border-black bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine p-1 rounded-full overflow-hidden block mx-auto"}>
                                        <img src={userAvatar} alt="website" className={"size-full object-center "}/>
                                    </span>
                                    <h2 className={"text-themeInformation text-center dark:text-themeDarkInformation line-clamp-1 text-xs"}>{userFullName}</h2>
                                </div>
                                <span>
                                    <HPrimeIcon icon={"pi-arrow-left"} iconClass={"text-themeSuccess"}/>
                                </span>
                                <div className={"space-y-2"}>
                                    <span className={"size-12 border-2 border-black bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine p-1 rounded-full overflow-hidden block mx-auto"}>
                                        <img src={webSiteLogo} alt="website" className={"size-full object-center "}/>
                                    </span>
                                    <h2 className={"text-themeInformation text-center dark:text-themeDarkInformation line-clamp-1 text-xs"}>{websiteName}</h2>
                                </div>
                            </div>
                            <div className={"flex items-center justify-between px-5"}>
                                <p className={"text-xs dark:text-gray-300"}>{t("Amount")} ({currencyName})</p>
                                <span className={"text-xl dark:text-gray-300"}>{amount !== null?amount.amount:0}</span>
                            </div>
                            <div className={"border-t-2 border-themeSuccess border-dashed p-5 space-y-4"}>
                                <div className={"flex items-center justify-between"}>
                                    <p className={"text-xs dark:text-gray-300"}>{t("TransactionTime")}</p>
                                    <span className={"text-xl dark:text-gray-300"}><GridDateFomartter hasTime={true} date={transactionDate}/></span>
                                </div>
                                <div className={"flex items-center justify-between"}>
                                    <p className={"text-xs dark:text-gray-300"}>{t('FactorNumber')}</p>
                                    <span className={"text-xl text-green-500"}>{trackingCode}</span>
                                </div>
                                <div className={"flex items-center justify-between"}>
                                    <p className={"text-xs dark:text-gray-300"}>{t('BankTrackingNumber')}</p>
                                    <span className={"text-xl text-themeSuccess"}>{transactionCode}</span>
                                </div>
                            </div>

                        </div>
                        <RecieptSaver/>
                    </div>
                </div>

            </div>

        </div>


    </>)
}