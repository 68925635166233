import React, {useState} from "react";
import {toggleExpandedForAll} from "@nosferatu500/react-sortable-tree";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useTranslation} from "react-i18next";

export const useProductGroupTree=(showSpinner,hideSpinner,setIsOpen,initialSource,setInitialSource,setProductGroup,productGroup,setIsEdit,Refresh)=>{
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState("");
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [searchFoundCount, setSearchFoundCount] = useState(null);
    const [treeData, setTreeData] = useState([]);

    function updateTreeData(treeData) {
        setTreeData(treeData);
    }
    function expand(expanded) {
        setTreeData(
            toggleExpandedForAll({
                treeData,
                expanded,
            })
        );
    }
    function expandAll() {
        expand(true);
    }
    function collapseAll() {
        expand(false);
    }
    const selectPrevMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
                : searchFoundCount - 1
        );
    };
    const selectNextMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
        );
    };

    const getNodeKey = ({ treeIndex }) => treeIndex;
    const customSearchMethod = ({ node, searchQuery }) => {
        return node.title.toLowerCase().includes(searchQuery.toLowerCase());
    };







    const handleGetNode = async(rowInfo)=>{
       try {
           showSpinner();
           const response = await GET(`/ProductGroups/${rowInfo.node.id}`,{},'single');
           if(response.status === 200){
               hideSpinner();
               setIsEdit(true)
               const {productGroupId,title,slug,excerpt,parentId,order,seoTitle,seoDescription,type,stateShow,stateGoogle} = response.data;
               const parent = initialSource.productGroups.find((item) => item.value === parentId)??null;
               const productType = initialSource.productTypes.find((item) => item.value === type)??null;
               setProductGroup(({productGroupId:productGroupId,title:title,slug: slug,order: order,excerpt: excerpt,seO_Title: seoTitle,parentId:parent,seO_Description: seoDescription,type:productType,stateShow:stateShow,stateGoogle:stateGoogle??false}))
               setIsOpen(true)
           }else{
               handleApiError(response,hideSpinner);
           }
       }catch (e) {
           hideSpinner();
       }
    }
    function handleAddNodeChild(rowInfo) {
        const parent = initialSource.productGroups.find((item) => item.value === rowInfo.node.id)??null;
        setProductGroup({ ...productGroup, parentId: parent ,type:initialSource.productTypes[0]});
        setIsOpen(true);
    }

    const handleDeleteProductGroup = async (id)=>{
        const response = await GET(`/ProductGroups/HasChildren?Id=${id}`,{},'single');
        if(response.status === 200){
            if(response.data.hasBranch){
                const TailWindButton = Swal.mixin({
                    customClass: {
                        confirmButton: AlertSetting.customClass.confirmButton,
                        cancelButton: AlertSetting.customClass.cancelButton
                    },
                    buttonsStyling: false
                })
                TailWindButton.fire({
                    title:AlertSetting.TailWindButton.title,
                    text: AlertSetting.TailWindButton.text,
                    icon:AlertSetting.TailWindButton.icon,
                    showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                    confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                    cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                    reverseButtons: AlertSetting.TailWindButton.reverseButtons
                }).then( async (result) =>{
                    if (result.isConfirmed) {
                        showSpinner()
                        const response =await DELETE(`/ProductGroups/${id}`,{headers: { 'Content-Type': 'application/json' }})
                        if(response.status === 200){
                           hideSpinner();
                            expandAll();
                            Refresh()
                            handleSuccessMessage(t("Grouphasbeensuccessfullydeleted"));
                        }else{
                            handleApiError(response,hideSpinner);
                        }
                    }
                    else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        return;
                    }
                })
            }else{
                const TailWindButton = Swal.mixin({
                    customClass: {
                        confirmButton: AlertSetting.customClass.confirmButton,
                        cancelButton: AlertSetting.customClass.cancelButton
                    },
                    buttonsStyling: false
                })
                TailWindButton.fire({
                    title:AlertSetting.TailWindButton.title,
                    text: AlertSetting.TailWindButton.text,
                    icon:AlertSetting.TailWindButton.icon,
                    showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                    confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                    cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                    reverseButtons: AlertSetting.TailWindButton.reverseButtons
                }).then( async (result) =>{
                    if (result.isConfirmed) {
                        showSpinner()
                        const response  = await DELETE(`/ProductGroups/${id}`,{headers: { 'Content-Type': 'application/json' }});
                        if(response.status === 200){
                            hideSpinner();
                            Refresh();
                            handleSuccessMessage(t("Grouphasbeensuccessfullydeleted"));
                        }else{
                            handleApiError(response,hideSpinner);
                        }
                    }
                    else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        return;
                    }
                })
            }
        }
        else{
            handleApiError(response,hideSpinner)
        }
    }
    const handleMoveNode = async(e) => {
        try {
            const Id = e.nextParentNode?.id;
            e.node.parentId = Id !== undefined ? Id : null;
            showSpinner()
            const response = await PUT('/ProductGroups/Parent', { productGroupId: e.node.id,parentId:e.node.parentId });
            if(response.status === 200){
                hideSpinner();
                expandAll();
                Refresh();
                handleSuccessMessage(t("Editingwassuccessfullydone"))
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }catch (e) {
            hideSpinner();
        }
    }
    const handleActivationProductGroup=async (productGroupId)=>{
        try {
            showSpinner();
            const response = await PUT('/ProductGroups/Activation',{productGroupId:productGroupId},false);
            if(response.status === 200){
                Refresh();
                handleSuccessMessage(t("Statushasbeensuccessfullychanged"))
            }else{
                handleApiError(response,hideSpinner)
            }
        }
        catch (e) {
            hideSpinner();
        }
    }




    return{
        searchString,
        setSearchString,
        searchFocusIndex,
        setSearchFocusIndex,
        searchFoundCount,
        setSearchFoundCount,
        treeData,
        setTreeData,
        updateTreeData,
        expandAll,
        collapseAll,
        selectPrevMatch,
        selectNextMatch,
        getNodeKey,
        handleAddNodeChild,
        handleDeleteProductGroup,
        handleMoveNode,
        customSearchMethod,
        handleGetNode,
        handleActivationProductGroup
    }
}