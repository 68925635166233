import React, {useEffect, useState} from "react";

import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {RangeQuestion} from "./RangeQuestion";
import {SingleQuestion} from "./SingleQuestion";
import {MultiQuestion} from "./MultiQuestion";
import {EmojiQuestion} from "./EmojiQuestion";
import queryString from "query-string";
import {convertSelectDataHandler} from "../../Contents/ContentService";
import {DELETE, GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {useCustomParticipation} from "../SurveyHooks/useCustomParticipation";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";

import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";


export const HandleDataEntrancePoll = () => {
    const queryParams = queryString.parse(window.location.search);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [showSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(0);
    const [initialSource,setInitialSource] = useState({types:[],title:null});
    const {survey, setSurvey,handleFilterSurveys,handleAddSurveyQuestion,surveyQuestion,activeSurveyIndex,handleNextButtonClick,ConvertSurveyQuestions,handlePreviousButtonClick} = useCustomParticipation();




    //refreshes component again
    const Refresh=(state)=>{
        setRefresh({...refresh});
    }
    // callback for handleApiError function to handle response status of api
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // handle initial dataSource
    const handleInitialDataSource=(surveyTypes,survey)=>{
        const types = convertSelectDataHandler(surveyTypes);
        const {title} = survey;
        setInitialSource({
            types: types,
            title: title
        })
        return {types}
    }

    //gets data from server in first initial render of component
    const fetchData= async()=>{
        try {
           setShowSpinner(true);
           const responseSurveyType = await GET(`/Enumerations/EnSurveyType`,{},'IEnumerable');
           const responseSurvey = await GET(`/Surveys/${queryParams.surveyGroupId}`,{},'single');
           if(responseSurveyType.status === 200 && responseSurvey.status === 200){
               const {types} =  handleInitialDataSource(responseSurveyType.data,responseSurvey.data);
               if(responseSurvey.data.surveys.length !== 0){
                   const surveys = handleFilterSurveys(responseSurvey.data.surveys,types);
                   setSurvey((prevSurvey) => ({
                       ...prevSurvey,
                       surveys: surveys.map((item) => ({
                           ...item,
                           surveyItems: item.surveyItems.map((surveyItemInner) => ({
                               ...surveyItemInner,
                           })),
                       })),
                   }));
               }

              setShowSpinner(false);
           }
           else{
               handleApiError(responseSurveyType,onApiError);
           }
        }
        catch (e) {
            onApiError();
            console.log(e);
        }
    }

    useEffect(()=>{
       fetchData();
    },[refresh]);


    //submits questions
    const handleSubmitQuestion=async ()=>{
        try {
          const questions = ConvertSurveyQuestions(surveyQuestion);
          questions.surveyGroupId=queryParams.surveyGroupId;
          const response = await  POST(`/Surveys/Results`,questions,false);
          if(response.status === 200){
             setShowSpinner(false);
             handleSuccessMessage(t("YourAnswersHaveBeenSuccessfullyRegistered"));
             redirectTo('/CMS/Surveys/List')
          }
          else{
              handleApiError(response,onApiError);
          }
        }
        catch (e) {
            console.log(e);
        }
    }



    //checks to ensure user for putting end to questions
    const handleEnsureSurveyCompletion = async ()=>{
        const questions = ConvertSurveyQuestions(surveyQuestion);
        if( questions.SurveyResults === null || questions.SurveyResults.length === 0){
                handleFailureMessage(t("NoQuestionHasBeenRegistered"));
                return;
        }
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:t("AreYouSureToRegisterTheQuestions"),
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText:t("RecordQuestions"),
            cancelButtonText: t("CancellationRegistration"),
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    await handleSubmitQuestion();
                }catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    //shows previous button
    const showPreviousButton=(index)=>{
        return (
            <>
                {index > 0 && // Check if there is data in the previous step
                    <button type={"button"} onClick={handlePreviousButtonClick} className={"button w-28 bg-themeInformation dark:bg-themeDarkInformation text-white flex gap-2 items-center justify-center"}>
                        <HPrimeIconSmall
                            icon={"pi-angle-right"}
                        />
                        {t("previous")}

                    </button>
                }
            </>
        )
    }
    // shows next button
    const showNextButton=(index)=>{
        return (<>
            {index < survey.surveys.length - 1 && // Check if there is data in the next step
                <button type={"button"} onClick={handleNextButtonClick} className={"button w-32 bg-themeInformation dark:bg-themeDarkInformation text-white flex gap-2 items-center justify-center"}>
                    {t("nextquestion")}
                    <HPrimeIconSmall
                        icon={"pi-angle-left"}
                    />
                </button>
            }
        </>)
    }

    return(
        <div class="space-y-5 relative">
            {

                showSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }


            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/Surveys/Add`)}
                                        title={t("Survey")}
                                        tooltipTitle={t("CreatingASurvey")}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/Surveys/List`)}
                                        icon={"pi-list"}
                                        title={t("list")}
                                        tooltipTitle={t("ListOfSurveys")}

                                    />
                                </>
                            }

                />

            </div>

            <div className={"space-y-4"}>
                <div className={"p-2 box bg-white dark:bg-themeDarkSalt700"}>
                    <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation flex items-center gap-2"}>
                        <div className={"flex-shrink-0"} >
                            <span className={"text-xs dark:text-gray-300"}>{t("TitleSurvey")} :</span>
                        </div>
                        <span className={" text-themeInformation dark:text-themeDarkInformation text-nowrap"}>{initialSource.title}</span>
                    </div>
                </div>

                <div className={"post__content tab-content relative box bg-white dark:bg-themeDarkSalt700 p-2"}>
                    {survey.surveys.map((item, index) => (
                        <div key={index} className={`tab-content__pane space-y-5 ${index === activeSurveyIndex ? 'active' : ''}`}>
                            {item.type !== null && item.type.value === 1 ? (
                                item.surveyItems.map((singleItem, singleIndex) => (
                                    <div key={singleIndex} className={`tab-content__pane space-y-5 ${index === activeSurveyIndex ? 'active' : ''} `}>
                                        {/* Render your SingleQuestion component */}
                                        <SingleQuestion
                                            index={index}
                                            surveyId={item.id}
                                            question={item.question}
                                            singleItems={item.surveyItems}
                                            handleAddSurveyQuestion={handleAddSurveyQuestion}
                                        />
                                    </div>
                                ))
                            ) : item.type !== null && item.type.value === 0 ? (
                                <div key={index} className={`tab-content__pane space-y-5 ${index === activeSurveyIndex ? 'active' : ''} `}>
                                    <MultiQuestion
                                        index={index}
                                        surveyId={item.id}
                                        question={item.question}
                                        multiItems={item.surveyItems}
                                        handleAddSurveyQuestion={handleAddSurveyQuestion}
                                    />
                                </div>
                            ) : item.type !== null && item.type.value === 3 ? (
                                <div
                                    key={index}
                                    className={`tab-content__pane space-y-5  ${index === activeSurveyIndex ? 'active' : ''} `}
                                >
                                    {/* Render your EmojiQuestion component */}
                                    <EmojiQuestion
                                        index={index}
                                        surveyId={item.id}
                                        question={item.question}
                                        emojiItems={item.surveyItems}
                                        handleAddSurveyQuestion={handleAddSurveyQuestion}
                                    />
                                </div>
                            ) : item.type !== null && item.type.value === 2 ? (

                                <div key={index} className={`tab-content__pane space-y-5  ${index === activeSurveyIndex ? 'active' : ''}  `}>

                                    <RangeQuestion
                                        index={index}
                                        surveyId={item.id}
                                        question={item.question}
                                        rangeItems={item.surveyItems}
                                        handleAddSurveyQuestion={handleAddSurveyQuestion}
                                    />
                                </div>

                            ) : null}
                            {
                                index === activeSurveyIndex?
                                    <div className="flex  gap-3 lg:justify-end">
                                        {
                                            showPreviousButton(index)
                                        }
                                        <button onClick={() => redirectTo('/CMS/Surveys/List')} className={"button w-28 bg-themeDanger text-white"}>
                                            {t("Cancel")}
                                        </button>
                                        <button onClick={() => handleEnsureSurveyCompletion()} className={"button w-28  bg-themeSuccess text-white "}>

                                            {t("Register")}
                                        </button>


                                        {
                                            showNextButton(index)
                                        }

                                    </div>
                                    :null
                            }

                        </div>
                    ))}
                </div>

            </div>
            <ToastContainer/>

        </div>
  )
}