export const handleConvertAddSecretary=(sec)=>{
    const data = {
        pattern:sec.pattern === null || sec.pattern === undefined?null:sec.pattern,
        message:sec.message === null || sec.message === undefined?null:sec.message,
        accountId:sec.accountId === null || sec.accountId === undefined?null:sec.accountId.value
    }
    return data;
}

export const handleConvertEditSecretary=(sec)=>{
    const data = {
        pattern:sec.pattern === null || sec.pattern === undefined?null:sec.pattern,
        message:sec.message === null || sec.message === undefined?null:sec.message,
        accountId:sec.accountId === null || sec.accountId === undefined?null:sec.accountId.value,
        smsSecretaryId:sec.smsSecretaryId
    }
    return data;
}