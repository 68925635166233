import {useState} from "react";
import {validDomainPattern} from "../../../../../Services/Globals/Public/Regexes";
import {handleFailureMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {useTranslation} from "react-i18next";

export const useCustomDomains=()=>{
    const { t } = useTranslation();

    const [domains,setDomains] = useState([
        // {
        //     id:null,
        //     fakeId:new Date().getTime(),
        //     domain: '',
        //     expirationDate: new Date(),
        //     protocol:1,
        //     isMaster: true,
        //     domainServer: 0,
        //     cdn: true,
        //     compression: false,
        // },
        ]
    );

    const handleAddRow = () => {
        const newRow = {
            fakeId: new Date().getTime(), // Unique identifier using timestamp
            id:null,
            domain: '',
            expirationDate: new Date(),
            protocol: 1,
            isMaster: false,
            cdn: true,
            compression: false,
            domainServer: 0,
        };
        setDomains((prevList) => [...prevList, newRow]);
    };

    const handleRemoveRow = (fakeId,id) => {
        if(domains.length === 1){
            const newRow = {
                fakeId: new Date().getTime(), // Unique identifier using timestamp
                domain: '',
                expirationDate: new Date(),
                protocol: 1,
                isMaster: false,
                cdn: true,
                compression: false,
                domainServer: 0,
            };
            setDomains(prevList => [newRow]);
            return;
        }
        setDomains((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.fakeId === fakeId && row.id === id
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0,indexToRemove),...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleChange = (fakeId, field, value, id) => {
        setDomains((prevList) => {
            const updatedDomains = prevList.map((meta) => {
                if (
                    (id && meta.id === id) || (!id && fakeId && meta.fakeId === fakeId)
                ) {
                    const updatedValue =
                        field === 'isMaster' && value === true
                            ? true
                            : value === ''
                                ? null
                                : value;

                    return { ...meta, [field]: updatedValue };
                } else {
                    return meta;
                }
            });
            return updatedDomains;
        });
    };
    const handleChangeDateTime = (value,fakeId,field,id) => {
        setDomains((prevList) => {
            const updatedDomains = prevList.map((meta) => {
                if (
                    (id && meta.id === id) || (!id && fakeId && meta.fakeId === fakeId)
                ) {
                    return { ...meta, [field]: value };
                } else {
                    return meta;
                }
            });
            return updatedDomains;
        });
    };


    const validateNotEmptyDomains=(domains)=>{
        const count = domains.filter((item) => item.domain === null).length;
        if (count > 0) {
            handleFailureMessage(t("Dearuserpleaseenterthedomainname"));
            return;
        }
    }
    const validateMasterDomains=(domains)=>{
        const masterCount = domains.filter((item) => item.isMaster === true).length;
        if(masterCount !== 1){
            handleFailureMessage(t("Pleasechooseadefaultdomain"));
            return;
        }
        return masterCount
    }
    const validateDomains=(domains)=>{
        const domainArray = domains.map((item) => validDomainPattern.test(item.domain));
        const count = domainArray.filter((item) => item === false).length;
        if (count > 0) {
            handleFailureMessage(
                t("Enteravaliddomainname")
            );
            return;
        }
    }


    return {
        domains,
        setDomains,
        handleRemoveRow,
        handleChange,
        handleAddRow,
        handleChangeDateTime,
        validateDomains,
        validateNotEmptyDomains,
        validateMasterDomains
    }


}