import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {GridTable} from "../../Commans/Grid/GridTable";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import { useNavigate} from "react-router-dom";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import queryString from "query-string";
import {templatePageSchema} from "./templatePageSchema";
import Swal from "sweetalert2";
import Select from "react-select";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {DisplayError} from "../../Commans/Errors/DisplayError";

import {convertAddTemplatePage, convertEditTemplatePage} from "./TemplateService";
import {AgGridPersion} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {ToastContainer} from "react-toastify";
import {BrowserDownloader} from "../../../ApiServices/Public/FtpController";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../Services/Globals/validate";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {AlertSetting, fileManager, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {Export} from "../../Commans/Grid/Export";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";

import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {ModalFileUpload} from "../../Commans/UIParts/ModalbuttonUpload";
import {FilterFileUpload} from "../../Commans/UIParts/FilterbuttonUpload";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {GridButtonOprations} from "../../Commans/Grid/GridButtonOprations";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";

export const HandleAddPageMasterTemplate = () => {

  const { t } = useTranslation();

  const redirectTo = useNavigate();
  const dispatch = useDispatch();

  const [modal, setModal] = useState({ AddPage: false });
  const [isDisable,setDisable] = useState(false);
  const[spinner,setSpinner] = useState(false);
  const[result,setResult] = useState(0)
  const [errors, setErrors] = useState({});

  const [selectOptions,setSelectOptions] = useState({pageType:null,defaults:null,layouts:null,directions:[]})
  const [isEdit,setIsEdit] = useState(false);

  const [tempMasterId,setTempMasterId] = useState(null);
  const [rootFolder,setRootFolder] = useState(null);
  const [wait,setWait] = useState(false);
  const [headerName,setHeaderName] = useState("");
  const [coldId,setColId]=useState(null);

  const [isMenuOpen,setMenuOpen] = useState(false);
  const [filePath,setFilePath] = useState("");
  const [pageId,setPageId] = useState(null);
  const [extention,setExtension] = useState("");
  const[fileName,setFileName]= useState('');

  const [page,setPage] = useState({id:null,name:null,slug:null,type:null,stateShow:false,isMain:false,templateMasterId:null,masterLayoutId:null,fileName:null,folderName:null,direction:null})


  const handleClosePage=()=>{
    setModal({AddPage: false});
    setErrors({})
    setPage({type: selectOptions.pageType[13],fileName:'',masterLayoutId: null,stateShow:false,isMain: {label:t("support"),value:1},name: '',slug: '',id: null,templateMasterId: null,direction: selectOptions.directions[0]})
    redirectTo(`/Admin/MasterTemplate/Page/Add?templateMasterId=${tempMasterId}&rootFolder=${rootFolder}`);
    setIsEdit(false);
  }


  const handleValue=(e)=>{
    const {name,value} = e.target;
    setPage({...page,[name]:value});
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  }


  const onApiError=()=>{
    setSpinner(false);
  }
  const onSetErrors = (errors)=>{
    setErrors(errors);
  }



  const handleOpen=()=>{
    setModal({ AddPage: true });
    const queryParams = queryString.parse(window.location.search);
    setPage((prevState)=>({...prevState,folderName: queryParams.rootFolder}));
  }



  const handleSelectValue=(data,name)=>{
    if(name === 'type'){
       if(data.value === 0){
         setDisable(true);
         setPage({ ...page, masterLayoutId: null });
       }else{
         setDisable(false);
       }
    }
      setPage({ ...page, [name]: data });
  }



  const handleFetchData = async ()=>{
    try {
      setSpinner(true);
      const responseTemplatePage = await GET('/Enumerations/EnTypeTemplatePage',{},'IEnumerable');
      const responseStatus = await GET('/Enumerations/EnTemplatePageStatus',{},'IEnumerable');
      const responseLayouts = await GET('/TemplateMasters/GetMasterLayoutPages',{},'IEnumerable');
      const responseDirection = await GET('/Enumerations/EnTemplatePageDirection',{},'IEnumerable');

      if ( responseTemplatePage.status === 200 && responseStatus.status === 200 && responseLayouts.status == 200 && responseDirection.status === 200)
      {
        const pageTypes = convertSelectDataHandler(responseTemplatePage.data);
        const defaults = convertSelectDataHandler(responseStatus.data);
        const layouts = convertSelectDataHandler(responseLayouts.data);
        const directions = convertSelectDataHandler(responseDirection.data);
        setSelectOptions((prevOptions) => ({...prevOptions, pageType: pageTypes,defaults: defaults,layouts: layouts,directions: directions}));
        setPage({type: pageTypes[13],fileName:'',masterLayoutId: null,direction: directions[0],stateShow:true,isMain: {label:t("Main"),value:0}})
        setSpinner(false)
        const queryParams = queryString.parse(window.location.search);
        setTempMasterId(queryParams.templateMasterId);
        setRootFolder(queryParams.rootFolder);
        if(queryParams.id){
         try {
           setIsEdit(true)
           const response = await GET(`/TemplateMasterPages/GetTemplateMasterPageById?Id=${queryParams.id}`,{},'single');
           if(response.status === 200){
             setPage({
               name: response.data.name,
               fileName: response.data.fileName,
               slug: response.data.slug,
               isMain:  response.data.isMain?defaults.find((item) => item.value === 0):defaults.find((item) => item.value === 1),
               stateShow: response.data.stateShow,
               id: response.data.id,
               type: pageTypes.find((item) => item.value === response.data.type),
               masterLayoutId: layouts && layouts.length > 0 ? layouts.find((item) => item.value === response.data.type):null,
               direction: directions && directions.length >0? directions.find((item)=>item.value === response.data.direction):null
             });
           }else{
             handleApiError(response,onApiError)
           }
         }catch (e) {
           onApiError()
         }
        }
      }
      else {
        handleApiError(responseTemplatePage,onApiError)
        handleApiError(responseStatus,onApiError)
        handleApiError(responseLayouts,onApiError)
        handleApiError(responseDirection,onApiError)
      }
    } catch (error) {
      onApiError()
    }
  }


  const handleEditRecord = async(recordId) => {

    try {
      setIsEdit(true)
      const response = await GET(`/TemplateMasterPages/GetTemplateMasterPageById?Id=${recordId}`,{},'single');
      setSpinner(true);
      if(response.status === 200){
        setPage({
          name: response.data.name,
          fileName: response.data.fileName,
          slug: response.data.slug,
          isMain:  response.data.isMain?selectOptions.defaults.find((item) => item.value === 0):selectOptions.defaults.find((item) => item.value === 1),
          stateShow: response.data.stateShow,
          id: response.data.id,
          type: selectOptions.pageType.find((item) => item.value === response.data.type),
          masterLayoutId: selectOptions.layouts && selectOptions.layouts.length > 0 ? selectOptions.layouts.find((item) => item.value === response.data.type):null,
          direction: selectOptions.directions && selectOptions.directions.length > 0 ? selectOptions.directions.find((item) => item.value === response.data.direction):null,
        });
        setModal({AddPage: true});
        setSpinner(false)
        redirectTo(`/Admin/MasterTemplate/Page/Add?templateMasterId=${tempMasterId}&rootFolder=${rootFolder}&id=${recordId}`);
      }else{
        handleApiError(response,onApiError)
      }
    }catch (e) {
      onApiError()
    }
  };



  const handleTemplatePageAdd =async (e)=> {
    e.preventDefault();
    if(isEdit){
      const isValid = validate(templatePageSchema,page,onSetErrors);
      if (!isValid) {
        return;
      }
     try {
       setSpinner(true);
       const queryParams = queryString.parse(window.location.search);
       const convertedPages = convertEditTemplatePage(page,queryParams.templateMasterId,queryParams.id);
       const {id,name,type,slug,fileName,isMain,stateShow,templateMasterId,masterLayoutId,direction} = convertedPages;
       const response = await PUT('/TemplateMasterPages/EditTemplate',{id,name,direction,type,slug,fileName,isMain,stateShow,templateMasterId,masterLayoutId},false);
       if (response.status === 200) {
         setSpinner(false);
         setResult({...response.status});
         handleClosePage()
         handleSuccessMessage(t("Pagehasbeensuccessfullyedited"));
       } else {
         handleApiError(response,onApiError)
       }
     }catch (e) {
       onApiError()
     }
    }
    else{
      const isValid = validate(templatePageSchema,page,onSetErrors);
      if (!isValid) {
        return;
      }
      try {
        setSpinner(true);
        const queryParams = queryString.parse(window.location.search);
        const convertedPages = convertAddTemplatePage(page,queryParams.templateMasterId);
       const {name,type,slug,fileName,isMain,stateShow,templateMasterId,masterLayoutId,direction} = convertedPages;
        const response = await POST('/TemplateMasterPages',{name,direction,type,slug,fileName,isMain,stateShow,templateMasterId,masterLayoutId},false);
        if (response.status === 200) {
          setSpinner(false);
          setResult({...response.status});
          handleSuccessMessage(t("Pagehasbeensuccessfullyregistered"));
          handleClosePage()
        } else {
          handleApiError(response,onApiError)
        }
      }catch (e) {
        onApiError()
      }
    }
  }








  const handleChangeStateShow = async(pageId,rowName)=>{
    try {
      setWait(true);
      setHeaderName(rowName)
      setColId(pageId);
      const response = await PUT(`/TemplateMasterPages/EditTemplateMasterPageActivation`,{id:pageId},false);
      if (response.status === 200) {
        setWait(false)
        setResult({...response.status})
      }
      else{
        setWait(false);
        handleApiError(response,onApiError)
      }
    }
    catch (e) {
      setWait(false)
    }
  }
  const handleChangeStatus = async(pageId,rowName)=>{
    try {
      setWait(true);
      setHeaderName(rowName);
      setColId(pageId);
      const response = await PUT(`/TemplateMasterPages/EditTemplateMasterPageStatus`,{id:pageId},false);
      if (response.status === 200) {
        setWait(false)
        setResult({...response.status})
      }
      else{
        setWait(false);
        handleApiError(response,onApiError)
      }
    }catch (e) {
      setWait(false)
    }
  }

  const handleMenuOpen = (pageId,fileName,rootFolder)=>{
    setMenuOpen(true);
    setPageId(pageId);
    const path =`/TemplateMasters/${rootFolder}/${fileName}`;
    setFilePath(path);
    setExtension(fileName);
    setFileName(fileName);
  }



  const handleCloseMenu = ()=>{
    setMenuOpen(!isMenuOpen)
  }

  const removePage = async (id,hasChild)=>{
    if(hasChild){
      const TailWindButton = Swal.mixin({
        customClass: {
          confirmButton: AlertSetting.customClass.confirmButton,
          cancelButton: AlertSetting.customClass.cancelButton
        },
        buttonsStyling: false
      })
      TailWindButton.fire({
        title:AlertSetting.TailWindButton.title,
        text: AlertSetting.TailWindButton.text,
        icon:AlertSetting.TailWindButton.icon,
        showCancelButton:AlertSetting.TailWindButton.showCancelButton,
        confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
        cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
        reverseButtons: AlertSetting.TailWindButton.reverseButtons
      }).then( async (result) =>{
        if (result.isConfirmed) {
        try {
          setSpinner(true)
          const response = await DELETE(`/TemplateMasterPages/${id}`, {headers: { 'Content-Type': 'application/json' }});
          if (response.status === 200) {
            setSpinner(false)
            setResult({...response.status});
            handleSuccessMessage(t("Pagehasbeensuccessfullydeleted"))
          }
          else {
            handleApiError(response,onApiError)
          }
        }catch (e) {
          onApiError()
        }
        }
        else if (
            result.dismiss === Swal.DismissReason.cancel
        ) {
          return;
        }
      })
    }else{
      const TailWindButton = Swal.mixin({
        customClass: {
          confirmButton: AlertSetting.customClass.confirmButton,
          cancelButton: AlertSetting.customClass.cancelButton
        },
        buttonsStyling: false
      })
      TailWindButton.fire({
        title:AlertSetting.TailWindButton.title,
        text: AlertSetting.TailWindButton.text,
        icon:AlertSetting.TailWindButton.icon,
        showCancelButton:AlertSetting.TailWindButton.showCancelButton,
        confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
        cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
        reverseButtons: AlertSetting.TailWindButton.reverseButtons
      }).then( async (result) =>{
        if (result.isConfirmed) {
          setSpinner(true)
          const response = await DELETE(`/TemplateMasterPages/${id}`, {headers: { 'Content-Type': 'application/json' }});
          if (response.status === 200) {
            setSpinner(false)
            setResult({...response.status});
            handleSuccessMessage(t("Yourpackagehasdeleted"))
          }
          else {
            handleApiError(response,onApiError)
          }
        }
        else if (
            result.dismiss === Swal.DismissReason.cancel
        ) {
          return;
        }
      })
    }
  }

  const convertTemplatePageType = (type) => {
    if (!selectOptions.pageType) {
      return;
    }
    const data = selectOptions.pageType.find((item) => item.value === type);
    return data ? data.label : '';
  }
  const handleGetDirection=(direction)=>{
    const directionType = selectOptions.directions.find((item)=>item.value === direction);
    if(!directionType){
      return ;
    }
    return directionType?.label;
  }







  const columnDefs = [
    { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
    { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},
    { headerName: t("PageTitle"),field: 'name', minWidth: 140},
    { headerName: t("PageAddress"),field: 'slug', minWidth: 140},
    { headerName: t("PageType"),field: 'type', minWidth: 140,cellRenderer:params => {
       return convertTemplatePageType(params.data.type)
    }},
    { headerName: t("WebsiteDirection"),field: 'direction', minWidth: 140,cellRenderer:params => {
        return handleGetDirection(params.data.direction)
      }},

    { headerName: t("FileName"),field: 'fileName', minWidth: 140},


    { headerName: t("Status"),field: 'isMain', maxWidth: 130, cellRenderer:params=>
          <div className={"relative"}>
            {wait && headerName === params.colDef.headerName && coldId === params.data.id? (
                <TinyLoading  />
            ) : params.data.isMain ? (
                <HPrimeIcon
                    onClick={async () => await handleChangeStatus(params.data.id,params.colDef.headerName)}
                    iconClass={'cursor-pointer text-themeSuccess'}
                    icon={"pi-check-square"}
                />
            ) : (
                <HPrimeIcon
                    onClick={() => handleChangeStatus(params.data.id,params.colDef.headerName)}
                    iconClass={'cursor-pointer text-themeDanger'}
                    icon={"pi-times-circle"}
                />
            )}
          </div>
    },
    { headerName: t("Active"),field: 'stateShow', maxWidth: 140, cellRenderer:params=>
          <div className={"relative"}>
            {wait && headerName === params.colDef.headerName && coldId === params.data.id? (
                <TinyLoading />
            ) : params.data.stateShow ? (
                <HPrimeIcon
                    onClick={async () => await handleChangeStateShow(params.data.id,params.colDef.headerName)}
                    iconClass={'cursor-pointer text-themeSuccess'}
                    icon={"pi-check-square"}
                />
            ) : (
                <HPrimeIcon
                    onClick={() => handleChangeStateShow(params.data.id,params.colDef.headerName)}
                    iconClass={'cursor-pointer text-themeDanger'}
                    icon={"pi-times-circle"}
                />
            )}
          </div>
    },

    { headerName: t("ParentTheme"),field: 'masterLayoutId', maxWidth: 140,cellRenderer:params => {
        return params.data.masterLayoutId !== null?
            params.data.masterLayoutName:
            <div>
              <HPrimeIcon
              icon={"pi-times"}
              iconClass={"text-themeDanger"}
              />
            </div>

    }},


    { headerName: t("operations"), maxWidth: 140,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
      cellRenderer:params => {
        return<div className={'flex justify-center items-center gap-3'} >
          <GridButtonEdit
              onClick={() => handleEditRecord(params.data.id)}
          />
          {
             params.data.masterLayoutId === null?
                 <GridButtonDelete
                     onClick={()=>removePage(params.data.id,false)}
                 />
                 :
                 <GridButtonDelete
                     onClick={()=>removePage(params.data.id,true)}
                 />
          }
          <GridButtonOprations
              onClick={()=>handleMenuOpen(params.data.id,params.data.fileName,params.data.rootFolder)}
          />
        </div>
      }
    },
  ];

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);
  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      resizable: true,
      // editable: true,
      filter: true,
    };
  }, []);


  const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
  const [currentPage, setCurrentPage] = useState(1);
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCurrentPage=(page)=>{
    setCurrentPage(page);
  }
  const handlePageSizeCount = (value)=>{
    setCurrentPage(1);

    setData(()=>({...data,pageSize: value}))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const [filter,setFilter] = useState({search:'',isMain:null,StateShow:null,Type:null,masterLayoutId:null,IsActive:null,IsDeActive:null,rtl:null,ltr:null});
  const [search,setSearch] = useState('');
  const handleSearch = ()=>{
    setFilter((prevState)=>({...prevState,search: search}));
  }
  const filterValueHandler= (data,name)=>{
    if(data === false){
      setFilter(({...filter,[name]: null}));
    }
    else{
      if(name === "isMain"){
        setFilter(({...filter,[name]: data.value === 0?true:false}));
      }else if(name === "StateShow"){
        setFilter(({...filter,[name]: data}));
      }
      else if(name === 'IsActive' || name === 'IsDeActive'){
        setFilter(({...filter,[name]: data}));
      }else if(name === 'ltr' || name === 'rtl'){
        setFilter(({...filter,[name]: data}));
      }
      else{
        setFilter(({...filter,[name]: data.value}));
      }
    }
  }

  const resetFilters = ()=>{
    setFilter(({...filter,masterLayoutId: null, Type: null,StateShow: null,isMain: null,search: '',IsActive: null,IsDeActive: null,ltr: null,rtl: null}));
    setSearch('');
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        setSpinner(true);
        const response = await OnGridReady();
        if (response && response.items !== null ) {
          handleFetchData(); // Remove await if handleFetchData is not asynchronous
        } else {
          handleApiError(response, onApiError);
        }
      } catch (error) {
        console.error("Error in loadData:", error);
      } finally {
        setSpinner(false);
      }
    };
    loadData();
  }, [result,currentPage,filter.search,data.pageSize,filter.Type,filter.isMain,filter.StateShow,filter.masterLayoutId,filter.IsActive,filter.IsDeActive,filter.ltr,filter.rtl]);


  const [rowData, setRowData] = useState([]);

  const OnGridReady = async () => {
    try {
      setSpinner(true);
      const queryParams = queryString.parse(window.location.search);
      const response =  await GET(`/TemplateMasterPages/GetTemplateMasterPageList`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search, Type: filter.Type, IsMain: filter.isMain,StateShow:filter.StateShow,MasterLayoutId:filter.masterLayoutId,Id:queryParams.templateMasterId,IsActive:filter.IsActive,IsDeActive:filter.IsDeActive,LTR:filter.ltr,RTL:filter.rtl}, 'PagedList');
      if (response.items !== null ) {
        setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
        var idSequence = 1;
        const modifiedItems = response.items.map((item) => ({
          ...item,
          idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
        }));
        setRowData(modifiedItems);
      }
      else {
        handleApiError(response,onApiError);
      }
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setSpinner(false);
    }
  };


  const handleFilePathes = (files) => {
    let fileArr = Array.from(files);
    var paths = fileArr.map((file) => {
      return `+TemplateMasters+${rootFolder}+${file.name}`;
    });
    return paths;
  };

  const handleUploadFile= async (files)=>{
   try {
     setSpinner(true);
     const paths = handleFilePathes(files);
     const pathString = paths.map(path => `Path=${encodeURIComponent(path)}`).join('&');
     const url = `/Public/IsFileOrDirectoryExist?${pathString}&FileOrDirectory=${0}`;
     const response = await GET(url,{},'single');
     try{
       if(response.status === 200){
         setSpinner(false);
         if(response.data.isExisted){
           const TailWindButton = Swal.mixin({
             customClass: {
               confirmButton: AlertSetting.customClass.confirmButton,
               cancelButton: AlertSetting.customClass.cancelButton
             },
             buttonsStyling: false
           })
           TailWindButton.fire({
             title:AlertSetting.TailWindButton.title,
             text: AlertSetting.TailWindButton.text,
             icon:AlertSetting.TailWindButton.icon,
             showCancelButton:AlertSetting.TailWindButton.showCancelButton,
             confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
             cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
             reverseButtons: AlertSetting.TailWindButton.reverseButtons
           }).then( async (result) =>{
             if (result.isConfirmed) {
               await handleAddFileTemplatePage(files);
             }
             else if (
                 result.dismiss === Swal.DismissReason.cancel
             ) {
               return;
             }
           })
         }else{
           await handleAddFileTemplatePage(files);
         }
       }else{
         handleApiError(response,onApiError);
       }
     }
     catch (e) {
       console.log(e);
     }
   }catch (e) {
     console.log(e);
   }
  }


  const handleAddFileTemplatePage = async(files)=>{
    const formData = new FormData();
    const queryParams = queryString.parse(window.location.search);
    formData.append("Id",queryParams.templateMasterId);
    for (let i = 0; i < files.length; i++) {
      formData.append("Files", files[i]);
    }
    const response = await POST('/TemplateMasters/UploadFileForTemplatePaging',formData,true);
    if(response.status === 200){
      setResult({...response.status});
      setSpinner(false)
      handleSuccessMessage(t("Filehasbeensuccessfullyuploaded"));
    }else{
      handleApiError(response,onApiError);
    }
  }







  const UploadTemplateMasterPage = async(file,id,rootFolder)=>{
    setSpinner(true);
    handleCloseMenu();
    const paths = [`+TemplateMasters+${rootFolder}+${file.name}`]
    const pathString = paths.map(path => `Path=${encodeURIComponent(path)}`).join('&');
    const url = `/Public/IsFileOrDirectoryExist?${pathString}&FileOrDirectory=${0}`;
    const response = await GET(url,{},'single');
    try {
      if(response.status === 200){
        setSpinner(false)
        if(response.data.isExisted)
        {
          const TailWindButton = Swal.mixin({
            customClass: {
              confirmButton: AlertSetting.customClass.confirmButton,
              cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
          })
          TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
          }).then( async (result) =>{
            if (result.isConfirmed) {
              await handleUploadTemplateMaseterPage(file,id);
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
              return;
            }
          })
        }
        else{
          await handleUploadTemplateMaseterPage(file,id);
        }
      }
      else{
        handleApiError(response,onApiError);
      }
    }catch (e) {
      onApiError()
    }
  }

  const handleUploadTemplateMaseterPage = async(file,id)=>{
   try {
     setSpinner(true)
     const formData = new FormData();
     const queryParams = queryString.parse(window.location.search);
     formData.append("Id",id);
     formData.append("TemplateMasterId",queryParams.templateMasterId);
     formData.append("File",file);
     const response = await POST('/TemplateMasters/UploadTemplateMasterPages',formData,true);
     if(response.status === 200){
       setResult({...response.status});
       setSpinner(false);
       handleSuccessMessage(t("Filehasbeensuccessfullyuploaded"));
     }else{
       handleApiError(response,onApiError);
     }
   }catch (error) {
     onApiError()
   }
  }


const handleDownloadTemplateMasterPage= async (rootFolder,fileName)=>{
  const filePath = `${fileManager.separator}TemplateMasters${fileManager.separator}${rootFolder}${fileManager.separator}${fileName}`
  await BrowserDownloader(filePath,'/Public/DownloadFile')

 }




 const handleDownloadTemplateMaster=async(rootFolder)=>{

   const filePath = `${fileManager.separator}${rootFolder}`;
   await BrowserDownloader(filePath,'/TemplateMasters/DownloadTemplateMaster')

 }




  const switchData = [
    {name:'IsActive',lable:t("Active"),value:filter.IsActive},
    {name:'IsDeActive',lable:t("Inactive"),value:filter.IsDeActive},
    {name:'ltr',lable:t("LTR"),value:filter.ltr},
    {name:'rtl',lable:t("RTL"),value:filter.rtl},
  ]



  return(
      <>
        <div class=" space-y-5 relative">
          {
            spinner?
                <div className=" huploader_loading" >
                  <div className="relative">
                    <LoadingHarmony/>
                  </div>
                </div>
                :
                null
          }
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
              <ListSearch label={null} icon={null} >
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                  <InputText type="search" value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className="input w-full"  />
                </div>
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("Status")}</label>
                  <Select
                      options={selectOptions.defaults}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(data)=>filterValueHandler(data,'isMain')}
                      placeholder={t("selection")}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("ParentTheme")}</label>
                  <Select
                      options={selectOptions.layouts}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(data)=>filterValueHandler(data,'masterLayoutId')}
                      placeholder={t("selection")}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("PageType")}</label>
                  <Select
                      options={selectOptions.pageType}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(data)=>filterValueHandler(data,'Type')}
                      placeholder={t("selection")}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <ButtonSearchList
                    onClickSearch={()=>handleSearch()}
                    onClickReset={()=>resetFilters()}
                />
              </ListSearch>
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>
                            <ButtonPlus
                                onClick={() => handleOpen()}
                                tooltipTitle={t("PageCreation")}

                            />
                            <ButtonPlus
                                onClick={()=>redirectTo(`/Admin/MasterTemplate/Add`)}
                                title={t("Public")}
                                tooltipTitle={t("generalTemplates")}
                                icon={"pi-pencil"}
                            />
                            <FilterFileUpload
                                id="uploadTwo"
                                icon={"pi-upload"}
                                multiple={true}
                                type="file"
                                onChange={(e)=>handleUploadFile(e.target.files)}
                                accept=".html"
                                title={t("Upload")}
                                tooltipTitle={t("UploadTemplate")}
                            />

                            <ButtonPlus
                                onClick={()=>handleDownloadTemplateMaster(rootFolder)}
                                icon={"pi-download"}
                                title={t("Download")}
                                tooltipTitle={t("DownloadTemplate")}
                            />

                          </>
                        }
                        childrenTwo={
                          <>
                            <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                          </>
                        }
            />

          </div>
          <div className={"space-y-2 H-body-ag-grid"}>
            <GridTable
                columnDefs={columnDefs}
                rowSelection={GridSetting.rowSelection}
                rowData={rowData}
                style={GridSetting.gridStyle}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                pagination={GridSetting.pagination}
                enableRtl={GridSetting.enableRtl}
                domLayout={GridSetting.domLayout}
                paginationPageSize={GridSetting.pageSize}
                getRowHeight={getRowHeight}
                localeText={AgGridPersion}
            />
            <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
          </div>
          <Export/>
        </div>


        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
          <div className="p-1 grid grid-cols-3 md:grid-cols-4 gap-3">
                <ModalButtonOperations
                onClick={()=>redirectTo(`/OnlineEditor?templateMasterId=${tempMasterId}&rootFolder=${rootFolder}&filePath=${filePath}&extension=${extention}`)}
                icon={"pi-code"}
                title={t("OnlineEditor")}
                />

                <ModalFileUpload
                id={"upload"}
                type={"file"}
                title={t("Upload")}
                onChange={(e)=>UploadTemplateMasterPage(e.target.files[0],pageId,rootFolder)}
                accept={".html"}
                />

            <ModalButtonOperations
                onClick={()=>handleDownloadTemplateMasterPage(rootFolder,fileName)}
                icon={"pi-download"}
                title={t("Download")}
            />

          </div>
        </FantasyModal>


        <FantasyModal openModal={modal.AddPage}  closeModal={handleClosePage} title={t("PageCreation")}>
          <div className="">
            <form method={'post'} onSubmit={handleTemplatePageAdd} className={"space-y-5"}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div class="space-y-2">
                  <div className={"flex justify-between items-center"}>
                    <label className="text-xs dark:text-gray-300">
                      {t("PageTitle")}
                    <RequiredIcon/>
                    </label>
                    <DisplayError message={errors.name} />
                  </div>
                  <InputText name={'name'} onInput={(e)=>handleValue(e)} value={page.name}  type={"text"} className={" input w-full"} />
                </div>
                <div class=" space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                  <InputText onInput={(e)=>handleValue(e)} value={page.slug} name={'slug'}  type={"text"} className={" input w-full"} />
                </div>
                <div class=" space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("PageType")}</label>
                  <Select
                      options={selectOptions.pageType}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'type')}
                      placeholder={t("selection")}
                      value={page.type}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}

                  />
                </div>
                <div class=" space-y-2">
                  <div className={"flex justify-between items-center"}>
                    <label className="text-xs dark:text-gray-300">{t("PageFileName")}</label>
                  </div>
                  <InputText disabled={isEdit} onInput={(e)=>handleValue(e)} value={page.fileName} name={'fileName'} type={"text"} className={" input w-full"} />
                </div>
                <div class=" space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("ParentTheme")}</label>
                  <Select
                      options={selectOptions.layouts}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'masterLayoutId')}
                      placeholder={t("selection")}
                      value={page.masterLayoutId}
                      isDisabled={isDisable}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}
                  />
                </div>
                <div class=" space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("Direction")}</label>
                  <Select
                      options={selectOptions.directions}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'direction')}
                      placeholder={t("selection")}
                      value={page.direction}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}
                  />
                </div>


                <div class=" space-y-2">
                  <div className={"flex justify-between items-center"}>
                    <label className="text-xs dark:text-gray-300">{t("TemplateFolderName")}</label>
                  </div>
                  <InputText disabled={true} onInput={(e)=>handleValue(e)} value={page.folderName} name={'folderName'}  type={"text"} className={" input w-full"} />
                </div>

                <div class=" space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("Status")}</label>
                  <Select
                      options={selectOptions.defaults}
                      isMulti={false}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'isMain')}
                      placeholder={t("selection")}
                      value={page.isMain}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}
                  />
                </div>
                <div>
                    <InputSwitch OnValueSelection={handleSelectValue} value={page.stateShow} name={'stateShow'} lable={t("Active")} />
                </div>
              </div>
              <div>
                <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("PageCreation")}</button>
              </div>
            </form>
          </div>
        </FantasyModal>

        <ToastContainer />
      </>

  )
}