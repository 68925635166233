import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../../../Commans/UIParts/MultiDataSelect";

import {TextEditor} from "../../../Commans/UIParts/TextEditor";
import {InputTextarea} from "primereact/inputtextarea";
import {TabMenu} from "../../../Commans/UIParts/TabMenu";

export const HandleAddClass = () => {

  const [activeTab, setActiveTab] = useState("tab_one");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
  const tabs = [
    {
      id: "tab_one",
      label: "توضیحات",
      icon: "file-text",
    },
    {
      id: "tab_two",
      label: "حسابداری",
      icon: "dollar-sign",
    },
    {
      id: "tab_three",
      label: "تنظیمات",
      icon: "settings",
    },
  ];



  return(
      <div class=" space-y-5">
        <div class="grid grid-cols-12 gap-5 ">
          <div class="col-span-12 lg:col-span-8">
            <div class="grid grid-cols-1 gap-2 md:grid-cols-2 ">
              <div>
                <InputText placeholder="عنوان کلاس ..." className="input w-full" />
                {/*{errors.title && <span style={{color:'red'}} >{errors.title}</span>}*/}
              </div>
              <div >
                  <MultiDataSelect placeholder={"انتخاب استاد"}/>
                  {/*<select name="" id="" className="select2 w-full">*/}
                  {/*  <option value="1">انتخاب استاد</option>*/}
                  {/*  <option value="1">استاد احمدی</option>*/}
                  {/*  <option value="1">استاد مازندرانی</option>*/}
                  {/*  <option value="1">استاد کهریزی</option>*/}
                  {/*</select>*/}
                </div>
            </div>
            <div class="post box bg-white mt-5 dark:bg-themeDarkSalt700">
              <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
              <div class="post__content tab-content">
                <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_one" ? "active" : "" }`}  id="tab_one">
                  <div class="rounded-md  space-y-5">
                    <div className="space-y-2 post-input dark:bg-themeDarkPostInput p-2 rounded-md">
                      <label className="text-xs dark:text-gray-300">خلاصه توضیح:</label>
                      <InputTextarea rows={3} cols={30} className={"input w-full"} />
                    </div>
                    <div>
                      <TextEditor />
                    </div>
                  </div>
                </div>
                <div className={`tab-content__pane p-1 md:p-3 ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                  <div class="grid grid-cols-1 md:grid-cols-2 post-input dark:bg-themeDarkPostInput p-2 gap-3 rounded-md">
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">
                        هزینه برگزاری:
                      </label>
                      <InputText className={"input w-full"} />
                    </div>
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">
                        شهریه استاد:
                      </label>
                      <InputText className={"input w-full"} />
                    </div>
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">
                        شهریه دانشجو:
                      </label>
                      <InputText className={"input w-full"} />
                    </div>
                  </div>
                </div>
                <div className={`tab-content__pane p-1 md:p-5 ${ activeTab === "tab_three" ? "active" : "" }`} id="tab_three">
                  <div className="post-input dark:bg-themeDarkPostInput rounded-md border dark:border-themeDarkColorLine">
                    <div class=" p-3 bg-gray-200 dark:bg-themeDarkPostInput rounded-t-md">
                      <p className="text-xs dark:text-gray-300">تغییر ساعت کلاس</p>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-5 p-1 md:p-3 ">
                      <div class=" space-y-2">
                        <label className="text-xs dark:text-gray-300">ازساعت:</label>
                        <InputText className={"input w-full"} />
                      </div>
                      <div className=" space-y-2">
                        <label className="text-xs dark:text-gray-300">به ساعت:</label>
                        <InputText className={"input w-full"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 lg:col-span-4">
            <div class="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
              <div class="grid grid-cols-2 gap-2 gap-y-3">
                <div class="col-span-2 space-y-2">
                  <label className="text-xs dark:text-gray-300">سال جاری:</label>
                  <InputText className="input w-full"/>
                </div>
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">تاریخ شروع:</label>
                  <InputText className="input w-full" />
                </div>
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">تاریخ پایان:</label>
                  <InputText className="input w-full" />
                </div>
                <div class="space-y-2 col-span-2">
                  <label className="text-xs dark:text-gray-300">روزهای برگزاری کلاس:</label>
                  <MultiDataSelect placeholder={"انتخاب استاد"}/>
                </div>
                <div class="space-y-2 col-span-2">
                  <label className="text-xs dark:text-gray-300">ساعت برگزاری:</label>
                  <MultiDataSelect placeholder={"انتخاب استاد"}/>

                </div>
                <div class="space-y-2 col-span-2">
                  <label className="text-white dark:text-gray-300"> گروه کلاسی:</label>
                  <MultiDataSelect placeholder={"انتخاب استاد"}/>
                </div>
                <div class="col-span-2 grid grid-cols-4 post-input  dark:bg-themeDarkPostInput p-3 rounded">
                  <div class="col-span-2 space-y-2">
                    <label className="text-xs dark:text-gray-300">ثبت بدهی استادان</label>
                    <div>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                  </div>
                  <div class="col-span-2 space-y-2">
                    <label className="text-xs dark:text-gray-300">ثبت بدهی شاگردان</label>
                    <div>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" flex justify-center lg:justify-end gap-3">
          <button className="button w-24 bg-themeDanger  text-white"> انصراف</button>
          <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation"> ثبت کلاس</button>
        </div>
      </div>

  )
}