import React from "react";
import {useTranslation} from "react-i18next";

import {HPrimeIconSmall} from "../Commans/UIParts/HPrimeIconSmall";

export const GeneralStatistics=({data,label,color,icon})=>{

    const { t } = useTranslation();

    return (<>

        <div className={`${color} p-4 shadow-lg`}>
            <div class="flex items-center justify-center">
                <div className={"space-y-3"}>
                    <div className={"flex gap-2"}>
                        <HPrimeIconSmall icon={icon} iconClass={"text-white"} />
                        <h2 class="flex gap-2 items-center justify-center text-white">{t(label)}</h2>
                    </div>
                    <h2 className={"text-center text-white"}>{data}</h2>
                </div>
            </div>
        </div>

    </>)
}