import Joi from "joi";
//this is schema validation of login to be used for yup and reat-hook-form

export const templatePageSchema = Joi.object({
    name: Joi.string().required().messages({
        'any.required': 'عنوان قالب الزامیست',
        'string.empty': 'عنوان قالب الزامیست',
        'string.base': 'عنوان قالب الزامیست',
    }),

});