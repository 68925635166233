import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../Commans/Grid/GridTable";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Select from "react-select";
import {InputTextarea} from "primereact/inputtextarea";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Export} from "../../../Commans/Grid/Export";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {InputNumber} from "primereact/inputnumber";
import {validate} from "../../../../Services/Globals/validate";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

import {AddDiscountGroupSchema2} from "./AddDiscountGroupSchema2";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import Swal from "sweetalert2";
import {ToastContainer} from "react-toastify";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {
    convertAddDiscountGroup,
    convertAddRequest,
    convertDiscounts,
    convertEditDiscountGroup,
    convertEditRequest
} from "./discountCampaginService2";
import {GetCurrentDateTime} from "../../../../Services/Globals/Public/TimeServiceManager";
import {Filter} from "../../../Commans/Globals/Filter";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const HandleDiscount2=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [modal, setModal] = useState({ DiscountCode: false, AddFilter:false });
    const [result,setResult] = useState(0);
    const [isEdit,setIsEdit] = useState(false);
    const[spinner,setSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [options,setOptions] = useState({discounts:[],products:[],discountOrCommissions:[],quantityAmounts:[],userFilters:[],productFilters:[]})

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const [discount,setDiscount] = useState([]);
    const [discountGroup,setDiscountGroup] = useState({discountGroupId:null,caption:null,description:null,userTargetTypeId:null,productTargetTypeId:null,stateShow:true,fromDate:null,toDate:null,priority:0, discounts:[]});

    const [IsOpenSimple, setOpenSimple] = useState(false);



    const openSimple=()=>{
        setOpenSimple(true)
    }

    const closeSimple=()=>{
        setOpenSimple(false)
    }


    const handleCloseDiscount = () => {
        setModal((prevState) => ({ ...prevState, DiscountCode: !modal.DiscountCode }));
        setErrors({});
        setDiscountGroup({caption:'',description:'',stateShow:true,fromDate:new Date(),toDate:new Date(),priority:0,userTargetTypeId:null,productTargetTypeId:null});
        setDiscount([]);
        redirectTo('/Discount');
        setIsEdit(false);
    };
    const handleCloseFilter = () => {
        setModal((prevState) => ({ ...prevState, AddFilter: !modal.AddFilter }));
    };
    const handleOpenFilter=()=>{
        setModal((prevState) => ({ ...prevState, AddFilter: !modal.AddFilter }));
    }

    const handleOpenDiscount=()=>{
        setModal({ DiscountCode: true });
        if (discount.length === 0) {
            handleAddRow();
        }
        const toDate = GetCurrentDateTime(false,1)
        setDiscountGroup((prevState)=>({...prevState,toDate: toDate,fromDate: new Date(),priority: 0,stateShow: true,userTargetTypeId:options.userFilters[0],productTargetTypeId:options.productFilters[0] }))
    }

    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setDiscountGroup({...discountGroup,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue=(value,name)=>{
        setDiscountGroup({...discountGroup,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue=(data,name)=>{
        setDiscountGroup({ ...discountGroup, [name]: data });
    }

    const handleFromDate = (dateTime)=>{
        setDiscountGroup({...discountGroup,fromDate: dateTime})
    }
    const handleToDate = (dateTime)=>{
        setDiscountGroup({...discountGroup,toDate: dateTime})
    }
    const handleFetchData =async()=>{
        try {
            const responseDiscount = await GET('/Enumerations/EnDiscountOrCommissionType',{},'IEnumerable');
            const responseQuantity = await GET('/Enumerations/EnQuantityAndAmount',{},'IEnumerable');
            const responseProduct = await GET('/Products/Selection',{},'IEnumerable');
            const responseUserFilters = await GET(`/Filters/Selection?Table=${1}`,{},'IEnumerable');
            const responseProductFilters = await GET(`/Filters/Selection?Table=${3}`,{},'IEnumerable');

            if(responseDiscount.status === 200 && responseQuantity.status === 200 && responseProduct.status === 200 && responseUserFilters.status === 200 && responseProductFilters.status === 200){
                const discounts = convertSelectDataHandler(responseDiscount.data);
                const quantities = convertSelectDataHandler(responseQuantity.data);
                const products = convertSelectDataHandler(responseProduct.data);
                const userFilters = convertSelectDataHandler(responseUserFilters.data);
                const productFilters = convertSelectDataHandler(responseProductFilters.data);
                setOptions((prevState)=>({...prevState,discountOrCommissions: discounts,quantityAmounts: quantities,products: products,userFilters: userFilters,productFilters: productFilters}));
            }else{
                handleApiError(responseDiscount,onApiError);
                handleApiError(responseQuantity,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        handleFetchData();
    },[])

    // useEffect(()=>{
    //     if (discount.length === 0) {
    //         handleAddRow();
    //     }
    // },[discount])

    const handleEditRecord=async (recordId)=>{
        try {
            setSpinner(true);
            setIsEdit(true);
            setModal(prevState => ({...prevState,DiscountCode: true}));
            const response = await GET(`/Discounts/${recordId}`,{},'sinle');
            if(response.status === 200){

                const discounts = convertDiscounts(response.data.discounts,options.discountOrCommissions,options.products,options.quantityAmounts);
                setDiscount(discounts);
                setDiscountGroup({
                    discountGroupId: response.data.discountGroupId,
                    caption: response.data.caption,
                    description: response.data.description,
                    fromDate: response.data.fromDate,
                    toDate: response.data.toDate,
                    priority: response.data.priority,
                    stateShow: response.data.stateShow,
                    userTargetTypeId: options.userFilters.find((item) => item.value === response.data.userTargetTypeId),
                    productTargetTypeId: options.productFilters.find((item) => item.value === response.data.productTargetTypeId),
                })
                setSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }

        }
        catch (e) {
            console.log(e);
        }
    }


    const handleRemoveDiscountGroup = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`DiscountGroups/${id}`, {headers: { 'Content-Type': 'application/json' }},)
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                        handleSuccessMessage(t("Discountcampaignhasbeensuccessfullydeleted"));
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }













    const handleAddRow = () => {
        const newRow = {
            id: new Date().getTime(),
            discountId: null,
            discountOrCommissionType:options.discountOrCommissions[0],
            quantityOrAmount:options.quantityAmounts[0],
            discountValue: 0,
            offerProductId: null,
            offerProductCount:0,
            stepValue: 0,
            minValue: 0,
            maxValue:0,
        };
        setDiscount((prevList) => [...prevList, newRow]);
    };

    const handleRemoveRow = (id, discountId) => {
        if (discount.length === 1) {
            const newRow = {
                id: new Date().getTime(),
                discountId: null,
                discountOrCommissionType:options.discountOrCommissions[0],
                quantityOrAmount:options.quantityAmounts[0],
                discountValue: 0,
                offerProductId: null,
                offerProductCount:0,
                stepValue: 0,
                minValue: 0,
                maxValue:0,
            };
            setDiscount(() => [newRow]);
        }
        setDiscount((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.discountId === discountId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleChange = (id, field, value, discountId) => {
        setDiscount((prevList) => {
            const updatedList = prevList.map((row) => {
                if ((discountId && row.discountId === discountId) || (!discountId && id && row.id === id)) {
                    const updatedRow = { ...row, [field]: value };
                    return updatedRow;
                }
                return { ...row, isDefault: false };
            });

            return updatedList;
        });
    };
    const handleDiscountGroupSubmit= async (e)=>{
        e.preventDefault();
        if(isEdit){
            const isValid = validate(AddDiscountGroupSchema2,discountGroup,onSetErrors);
            if(!isValid){
                return;
            }
            setSpinner(true)
            const newEditDiscount = convertEditRequest(discount);
            const convertedEditDiscountGroup=convertEditDiscountGroup(discountGroup,newEditDiscount);
            const {discountGroupId,caption,stateShow,fromDate,toDate,userTargetTypeId,productTargetTypeId,description,priority,discounts}=convertedEditDiscountGroup;
            const response = await PUT(`/DiscountGroups`,{discountGroupId,caption,stateShow,fromDate,toDate,userTargetTypeId,productTargetTypeId,description,priority,discounts},false);
            if(response.status === 200){
                  setSpinner(false);
                  setIsEdit(false);
                  handleCloseDiscount()
                  setResult({...response.status});
                  handleSuccessMessage(t("Discountcampaignhasbeensuccessfullyedited"));
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        else{
            const isValid = validate(AddDiscountGroupSchema2,discountGroup,onSetErrors);
            if(!isValid){
                return;
            }
            try {
                const newDiscount = convertAddRequest(discount);
                const convertedDiscountGroup = convertAddDiscountGroup(discountGroup,newDiscount);
                const {caption,stateShow,fromDate,toDate,userTargetTypeId,productTargetTypeId,description,priority,discounts}=convertedDiscountGroup;
                const response = await POST('/DiscountGroups', {caption,stateShow,fromDate,toDate,description,priority,discounts,userTargetTypeId,productTargetTypeId},false);
                if(response.status === 200){
                 setResult({...response.status});
                 setSpinner(false);
                 setIsEdit(false);
                 handleCloseDiscount();
                 handleSuccessMessage(t("Discountcampaignhasbeensuccessfullyregistered"));
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                console.log(e);
            }
        }
    }





    const [isMenuOpen,setMenuOpen] = useState(false);
    const handleOpenMenu = () => {
        setMenuOpen(true);
    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }



    const handleChangeStatus=async(id,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            const response = await PUT(`/DiscountGroups/EditDiscountGroupActivation`,{id:id},false);
            if (response.status === 200) {
                setWait(false)
                setResult({...response.status})
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            console.log(e);

        }
    }









    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("Title"),field: 'caption', minWidth: 140},
        { headerName: t("FromDate"), minWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.fromDate} /></>
            }
        },
        { headerName: t("ToDate"), minWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.toDate} /></>
            }
        },
        { headerName: t("description"),field: 'description', minWidth: 140},
        { headerName: t("TargetAudience"),field: 'targetTypeId', minWidth: 140},

        { headerName: t("DisplayStatus"),field: 'stateShow', minWidth: 115, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeStatus(params.data.discountGroupId,params.colDef.headerName)}
                                     icon={'pi-check-square'}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeStatus(params.data.discountGroupId,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'pi-times-circle'}

                        />
                    )}
                </div>
        },
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={() => handleEditRecord(params.data.discountGroupId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleRemoveDiscountGroup(params.data.discountGroupId)}
                    />
                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',fromDate:null,toDate:null,isActive:null,isDeActive:null,isExpired:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search: '',fromDate: null,toDate: null,isActive: null,isDeActive: null,isExpired: null}));
        setSearch('');
    }


    const handleRegisterFromDate=(dateTime)=>{
        setFilter((prevState)=>({...prevState,fromDate: dateTime}))
    }
    const handleRegisterToDate=(dateTime)=>{
        setFilter((prevState)=>({...prevState,toDate: dateTime}))
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData();
                } else {
                    handleApiError(response,onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [result,currentPage,data.pageSize,filter.search,filter.toDate,filter.fromDate,filter.isActive,filter.isDeActive,filter.isExpired]);


    const [rowData, setRowData] = useState([]);

    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/Discounts`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,FromDate:filter.fromDate,ToDate:filter.toDate,IsActive:filter.isActive,IsDeActive:filter.isDeActive,IsExpired:filter.isExpired}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };









    const switchData = [
        {name:'isActive',lable:t("isActive"),value:filter.isActive},
        {name:'isDeActive',lable:t("isNotActive"),value:filter.isDeActive},
        {name:'isExpired',lable:t("expired"),value:filter.isExpired},
    ]



    //
    // const [isMenuOpen,setMenuOpen] = useState(false);
    // const handleOpenMenu = () => {
    //     setMenuOpen(true);
    //
    // };
    // const handleCloseMenu = ()=>{
    //     setMenuOpen(false);
    // }
    //
    // // مشابه AddDiscount


    const handleSuccess=async(isSuccess)=>{
        if(isSuccess){
            const response = await GET(`/Filters/Selection?Table=${3}`,{},'IEnumerable');
            if(response.status === 200){
                const filters = convertSelectDataHandler(response.data);
                setOptions(prevState => ({...prevState,productFilters: filters}));
                handleCloseFilter();
            }
            else{
                handleApiError(response,onApiError);
            }
        }
    }



    return(
        <>
            {console.log(discount)}
            <div class="space-y-5 relative">
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                        <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                                <InputText  onChange={(e)=>setSearch(e.target.value)}  value={search} type="text" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                            </div>
                            <div class=" space-y-2">
                                <label className="text-xs dark:text-gray-300">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("FromDate")}
                                </label>
                                <DateTimePicker handleDateTime={handleRegisterFromDate} value={filter.fromDate} format={'YYYY/MM/DD'} />
                            </div>
                            <div class=" space-y-2">
                                <label className="text-xs dark:text-gray-300">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("ToDate")}
                                </label>
                                <DateTimePicker value={filter.toDate} handleDateTime={handleRegisterToDate} format={'YYYY/MM/DD'} />
                            </div>
                            <ButtonSearchList
                                onClickSearch={()=>handleSearch()}
                                onClickReset={()=>resetFilters()}
                            />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={

                                    <>

                                        <ButtonPlus
                                            title={t("Simple")}
                                            onClick={() => openSimple()}
                                            tooltipTitle={t("SimpleCampaign")}
                                            primIcon={"pi-money-bill"}

                                        />



                                        <ButtonPlus
                                            onClick={() => redirectTo('/Discount/Add')}
                                            icon={"edit"}
                                            title={t("DiscountCode")}
                                            tooltipTitle={t("CreatingDiscountCode")}

                                        />
                                        <ButtonPlus
                                            onClick={() => handleOpenFilter()}
                                            icon={"filter"}
                                            title={t("Filter")}
                                            tooltipTitle={t("CreateFilter")}

                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />

                </div>
                <div className="space-y-2 H-body-ag-grid">
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export/>
            </div>
            {/*<FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} title={"عملیات"}>*/}
            {/*    <div className="p-1 grid grid-cols-3 md:grid-cols-4 gap-3">*/}
            {/*    </div>*/}
            {/*</FantasyModal>*/}

            <FantasyModal openModal={modal.DiscountCode}  closeModal={handleCloseDiscount} title={t("AdvancedCampaign")} customeClassName={"modal-x"}>
                <form onSubmit={handleDiscountGroupSubmit} method={'post'}>
                    <div className="space-y-3">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
                            <div className={"flex gap-2 items-center"}>
                                <div class="space-y-2 flex-1">
                                    <div className={"flex justify-between items-center"}>
                                        <label className="text-xs dark:text-gray-300">{t("CampaignTitle")}</label>
                                        <DisplayError message={errors.caption}/>
                                    </div>
                                    <InputText type={"text"} name={'caption'}  value={discountGroup.caption} onInput={(e)=>handleValue(e)} className={" input w-full"} />

                                </div>
                                <div class="space-y-1 flex-1">
                                    <label className="text-xs dark:text-gray-300">{t("Priority")}</label>
                                    <InputNumber type={"text"}  name={'priority'} onValueChange={(e)=>handleNumberValue(e.value,'priority')} value={discountGroup.priority} />
                                </div>
                            </div>

                            <div className={"flex gap-2 items-center"}>
                                <div class="space-y-1">
                                    <label className="text-xs dark:text-gray-300">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("FromDate")}</label>
                                    <DateTimePicker value={discountGroup.fromDate} handleDateTime={handleFromDate} format={'YYYY/MM/DD'}  />
                                </div>
                                <div class="space-y-1">
                                    <label className="text-xs dark:text-gray-300">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("ToDate")}</label>
                                    <DateTimePicker value={discountGroup.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'}  />
                                </div>
                                <div>
                                    <InputSwitch OnValueSelection={handleSelectValue} value={discountGroup.stateShow} name={'stateShow'} lable={t("Active")} />
                                </div>
                            </div>

                            <div class="space-y-2 md:col-span-2 lg:col-span-4">
                                <InputTextarea name={"description"} placeholder={t("Explanation")} value={discountGroup.description} onInput={(e)=>handleValue(e)}  rows={3} cols={30} className={" w-full input"} />
                            </div>
                        </div>
                        <div className="bg-white space-y-2 p-2 box dark:bg-themeDarkSalt700">
                            <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                <p className={"text-xs dark:text-gray-300"}>{t("TargetAudience")}</p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 px-2">
                                <div class="space-y-2 post-input p-2 dark:bg-themeDarkPostInput post-input-design">
                                    <div className={"flex gap-2 items-center"}>
                                        <label className="text-xs dark:text-gray-300">{t("DiscountFor")}</label>
                                        <button type="button" className="text-themeInformation text-xs dark:text-themeDarkInformation" onClick={() => redirectTo('/Form/Report/Builder')}>({t("CreateFilter")}) </button>
                                    </div>
                                    <Select
                                        options={options.userFilters}
                                        isMulti={false}
                                        isClearable={false}
                                        isSearchable={true}
                                        onChange={(data) => handleSelectValue(data,'userTargetTypeId')}
                                        placeholder={t("selection")}
                                        value={discountGroup.userTargetTypeId}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div class="space-y-2 post-input p-2 dark:bg-themeDarkPostInput post-input-design">
                                    <div className={"flex gap-2 items-center"}>
                                        <label className="text-xs dark:text-gray-300">{t("DiscountOn")}</label>
                                        <button type="button" className="text-themeInformation text-xs dark:text-themeDarkInformation" onClick={() => redirectTo('/Form/Report/Builder')}>({t("CreateFilter")}) </button>
                                    </div>

                                    <Select
                                        options={options.productFilters}
                                        isMulti={false}
                                        isClearable={false}
                                        isSearchable={true}
                                        onChange={(data) => handleSelectValue(data,'productTargetTypeId')}
                                        placeholder={t("selection")}
                                        value={discountGroup.productTargetTypeId}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                            </div>
                        </div>
                        <Accordion className="bg-white box dark:bg-themeDarkSalt700 ">
                            <AccordionTab header={t("Conditions")}>
                                <div className={"space-y-4 max_height20"}>
                                        {discount.map((row, index) => (
                                                  <>
                                                  <div className="grid grid-cols-2 lg:grid-cols-4 items-end gap-2 post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                                                      <div class="space-y-1">
                                                          <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>
                                                          <InputNumber type={"text"}  name={'minValue'} onChange={(e)=>handleChange(row.id,'minValue',e.value,row.discountId)} value={row.minValue} />
                                                      </div>
                                                      <div class="space-y-1">
                                                          <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>
                                                          <InputNumber type={"text"}  name={'maxValue'} onChange={(e)=>handleChange(row.id,'maxValue',e.value,row.discountId)} value={row.maxValue} />
                                                      </div>
                                                      <div class="space-y-1">
                                                          <label className="text-xs dark:text-gray-300">{t("StepIncrease")}</label>
                                                          <InputNumber type={"text"} name={'stepValue'} onChange={(e)=>handleChange(row.id,'stepValue',e.value,row.discountId)}     value={row.stepValue} />
                                                      </div>
                                                      <div class="space-y-1">
                                                          <label className="text-xs dark:text-gray-300">{t("Unit")}</label>
                                                          <Select
                                                              options={options.quantityAmounts}
                                                              isMulti={false}
                                                              isClearable={false}
                                                              isSearchable={true}
                                                              onChange={(data) => handleChange(row.id, 'quantityOrAmount',data,row.discountId)}
                                                              placeholder={t("selection")}
                                                              value={row.quantityOrAmount}
                                                              menuPosition={'fixed'}
                                                              classNamePrefix={"h_multi_select"}
                                                          />
                                                      </div>
                                                      <div class="space-y-1">
                                                          <label className="text-xs dark:text-gray-300">{t("DiscountType")}</label>
                                                          <Select
                                                              options={options.discountOrCommissions}
                                                              isMulti={false}
                                                              isClearable={true}
                                                              isSearchable={true}
                                                              onChange={(data) => handleChange(row.id, 'discountOrCommissionType',data,row.discountId)}
                                                              placeholder={t("selection")}
                                                              value={row.discountOrCommissionType}
                                                              menuPosition={'fixed'}
                                                              classNamePrefix={"h_multi_select"}
                                                          />
                                                      </div>
                                                      <div className="space-y-1 ">
                                                          <label className="text-xs dark:text-gray-300">{t("discount")}</label>
                                                          <InputNumber type={"text"}  name={'discountValue'} onChange={(e)=>handleChange(row.id,'discountValue',e.value,row.discountId)} value={row.discountValue} />
                                                      </div>
                                                      <div className={"col-span-2 flex gap-2 items-end"}>
                                                          <div class="space-y-1 flex-1">
                                                              <label className="text-xs dark:text-gray-300">{t("Products")}</label>
                                                              <Select
                                                                  options={options.products}
                                                                  isMulti={false}
                                                                  isClearable={true}
                                                                  isSearchable={true}
                                                                  onChange={(data) => handleChange(row.id, 'offerProductId',data,row.discountId)}
                                                                  placeholder={t("selection")}
                                                                  value={row.offerProductId}
                                                                  menuPosition={'fixed'}
                                                                  classNamePrefix={"h_multi_select"}
                                                              />
                                                          </div>
                                                          <div className="space-y-1 w-20">
                                                              <label className="text-xs dark:text-gray-300">{t("count")}</label>
                                                              <InputNumber type={"text"}  name={'offerProductCount'} onChange={(e)=>handleChange(row.id,'offerProductCount',e.value,row.discountId)} value={row.offerProductCount} />
                                                          </div>

                                                          <div class="space-y-1 flex-shrink-0">
                                                              <div className="flex justify-end gap-2">
                                                                  <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white " onClick={handleAddRow}>
                                                                      <HPrimeIcon icon={'pi-plus'}  />
                                                                  </button>
                                                                  <button type="button" className="button small_button bg-themeDanger text-white " onClick={() => handleRemoveRow(row.id,row.discountId)}>
                                                                      <HPrimeIcon icon={'pi-minus'} />
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  </>
                                        ))}

                                </div>
                            </AccordionTab>
                        </Accordion>
                        <div className="">
                            <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                        </div>
                    </div>

                </form>
            </FantasyModal>

            <FantasyModal openModal={modal.AddFilter} closeModal={handleCloseFilter} title={t("GroupCreation")} customeClassName={"modal-x"}>
                <div>
                    <Filter
                        IsOpen={modal.AddFilter}
                        handleSuccess={handleSuccess}
                    />
                </div>

            </FantasyModal>

            <FantasyModal openModal={IsOpenSimple}  closeModal={closeSimple} title={t("SimpleCampaign")} customeClassName={" modal-x"}>
                <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 ">
                        <div class="space-y-2 ">
                            <div className={"flex justify-between items-center gap-2"}>
                                <label className="text-xs dark:text-gray-300">{t("CampaignTitle")}</label>
                                <DisplayError message={errors.caption}/>
                            </div>
                            <InputText type={"text"} name={'caption'}   className={" input w-full"} />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("SetInterval")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}
                            </label>
                            <DateTimePicker  handleDateTime={handleFromDate} format={'YYYY/MM/DD'}  />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}
                            </label>
                            <DateTimePicker  handleDateTime={handleToDate} format={'YYYY/MM/DD'}  />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("*******")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>

                        {/*براساس انتخاب از select بالا نوع نمایش رو تغییر میدهیم*/}
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("FirstNameAndLastName")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("UserGroup")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("UserRole")}</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        {/*براساس انتخاب از select بالا نوع نمایش رو تغییر میدهیم*/}


                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("DiscountType")}</label>
                            <Select

                                isMulti={false}
                                isClearable={true}
                                isSearchable={true}

                                placeholder={t("selection")}
                                menuPosition={'fixed'}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("discount")}</label>
                            <InputNumber type={"text"}  name={'commissionValue'}  />
                        </div>

                    </div>
                    <div>
                        <InputTextarea name={"description"}  placeholder={t("Explanation")} onInput={(e)=>handleValue(e)}  rows={3} cols={30} className={" w-full input"} />
                    </div>
                    <div className={"flex justify-items-start"}>
                        <InputSwitch OnValueSelection={handleSelectValue}  name={'stateShow'} lable={t("Active")} />
                    </div>
                    <div>
                        <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                    </div>
                </div>
            </FantasyModal>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>


            <ToastContainer/>

        </>
    )
}