import client, {BasicUrl} from "./axiosClient";
import {ExceptionHandlerService} from "../Globals/Errors/ExceptionHandlerService";
import {handleApiError} from "../Globals/Errors/handleApiError";
import {fetchAccessToken, fetchRefreshToken, saveAccessToken, saveRefreshToken} from "./JwtService";
import axios from "axios";
export async function GET(url, params = {}, responseType = 'single') {
    try {
        // await CallRefreshToken()

        const formattedParams = {};

        // Iterate through params to format dates
        Object.entries(params).forEach(([key, value]) => {
            if (value instanceof Date) {
                formattedParams[key] = value.toISOString(); // Format date to string
            } else {
                formattedParams[key] = value;
            }
        });
        const queryString = Object.entries(formattedParams).map(([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
        const fullUrl = queryString ? `${url}?${queryString}` : url;

        const response = await client.get(fullUrl);
            if(responseType === 'single'){
                return response
            }
            else if( responseType === 'IEnumerable'){
                return  response
            }else if(responseType === 'PagedList'){
                return {
                    items: response.data.items,
                    page: response.data.page,
                    pageSize: response.data.pageSize,
                    totalCount: response.data.totalCount,
                    hasNextPage: response.data.hasNextPage,
                    totalPages: response.data.totalPages,
                    hasPreviousPage: response.data.hasPreviousPage
                };
            }else{
                return response
            }
    } catch (error) {
       return ExceptionHandlerService(error);
    }
}


export async function login(url, data, isFormData = false) {
    try {
        if (isFormData) {
            return await client.post(url,data);
        }
        else {
            return await client.post(url,data);
        }
    } catch (error) {
        return ExceptionHandlerService(error)
    }
}

export async function POST(url, data, isFormData = false) {
    try {
        if (isFormData) {
            // await CallRefreshToken()

            return await client.post(url,data);
        }
        else {
            // await  CallRefreshToken()

            return await client.post(url,data);
        }
    } catch (error) {
       return ExceptionHandlerService(error)
    }
}



export async function PUT(url, data, isFormData = false) {
    try {
        if (isFormData) {
            // await CallRefreshToken()

            return await client.put(url,data);
        }
        else {
            // await CallRefreshToken()

            return await client.put(url, data);
        }
    } catch (error) {
        return ExceptionHandlerService(error)
    }
}

export async function DELETE(url, data = null) {
    try {
        if (data) {
            // await  CallRefreshToken()
            return await client.delete(url, { data });
        }
        else {
            // await CallRefreshToken()

            return await client.delete(url);
        }
    } catch (error) {
        return ExceptionHandlerService(error)
    }
}



async function CallRefreshToken() {
    try {
        const accessToken = fetchAccessToken();
        const refreshToken = fetchRefreshToken();
        const language = localStorage.getItem('language');
        // Update the client instance headers
        client.defaults.headers.Authorization = accessToken ? ` ${accessToken}` : undefined;
        client.defaults.headers['Accept-Language'] = language || undefined;

        const response = await client.post(`/authentications/RefreshToken`, {
            accessToken: accessToken,
            refreshToken: refreshToken
        });

        if (response.status === 200) {
            saveAccessToken(response.data.accessToken);
            saveRefreshToken(response.data.refreshToken);
        }
    } catch (error) {
        return ExceptionHandlerService(error);
    }
}