import React, {useState} from "react";
import {StepMenu} from "../../Commans/UIParts/StepMenu";
import {HandleFastRegister} from "./UserRegisteration/HandleFastRegister";
import {HandlePersonalData} from "./Informations/HandlePersonalData";
import {HandleCompany} from "./Company/HandleCompany";
import {HandleDataBank} from "./Banks/HandleDataBank";
import {HandleRelationshipData} from "./Communications/HandleRelationshipData";
import {HandleEventData} from "./Events/HandleEventData";
import {HandleDocument} from "./Documents/HandleDocument";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {handleFailureMessage} from "../../../Services/Globals/Errors/NotificationService";
import {useNavigate} from "react-router-dom";

export const HandleHrmLayout=({mode})=>{
    const { t } = useTranslation();

    const getTabFromQueryString = () => {
        const params = new URLSearchParams(window.location.search);
        return params.get('tabId') || 'register';
    };

    const [activeTab, setActiveTab] = useState(getTabFromQueryString());
    const handleTabClick = (tabId) => {
        const queryParams = queryString.parse(window.location.search);
        if(!queryParams.id){
            handleFailureMessage('Pleaseinformationfirsttab');
            return;
        }
        else{
            setActiveTab(tabId);
        }
    }

    const tabs = [
        {
            id: "register",
            title: t("QuickRegistration"),
            step: "1",
            tooltipTitle:t("QuickUserRegistration"),
        },
        {
            id: "personal",
            title: t("PrivetData"),
            step: "2",
            tooltipTitle:t("CompleteDataRegistration"),
        },
        {
            id: "company",
            title: t("CompanyData"),
            step: "3",
            tooltipTitle:t("CompanyUserDataRegistration"),
        },
        {
            id: "bank",
            title: t("BankData"),
            step: "4",
            tooltipTitle:t("UserBankDataRegistration"),
        },
        {
            id: "communication",
            title: t("communications"),
            // title: t("CustomField"),
            step:"5",
            tooltipTitle:t("communications"),
        },
        {
            id: "event",
            title: t("events"),
            step:"6",
            tooltipTitle:t("UserEventsRegistration"),
        },
        {
            id: "document",
            title: t("Documents"),
            step: "7",
            tooltipTitle:t("SendUserDataandDocuments"),

        },
    ];

    const redirectTo = useNavigate();


    return(<>

        <StepMenu  activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />

        <div>
            <div className="post__content tab-content bg-white dark:bg-themeDarkSalt700 p-2">
                <div className={`tab-content__pane space-y-5  ${ activeTab === "register" ? "active" : "" }`} id="register"  >
                    {
                        activeTab === "register"?  <HandleFastRegister mode={mode} IsDefault={true} handleTabClick={handleTabClick}/> :null
                    }
                </div>

                <div className={`tab-content__pane space-y-5 ${ activeTab === "personal" ? "active" : "" }`} id="personal">
                    {
                        activeTab === "personal"?   <HandlePersonalData  handleTabClick={handleTabClick}/> :null
                    }
                </div>

                <div className={`tab-content__pane space-y-5  ${ activeTab === "company" ? "active" : "" }`} id="company">
                    {
                        activeTab === "company"?   <HandleCompany  handleTabClick={handleTabClick} />:null
                    }
                </div>

                <div className={`tab-content__pane space-y-5  ${ activeTab === "bank" ? "active" : "" }`} id="bank">
                    {
                        activeTab === "bank"?     <HandleDataBank handleTabClick={handleTabClick} />: null
                    }
                </div>

                <div className={`tab-content__pane space-y-5 ${ activeTab === "communication" ? "active" : "" }`} id="communication">
                    {
                        activeTab === "communication"?   <HandleRelationshipData handleTabClick={handleTabClick} />: null
                    }
                </div>

                <div className={`tab-content__pane space-y-5  ${ activeTab === "event" ? "active" : "" }`} id="event">
                    {
                        activeTab === "event"?     <HandleEventData handleTabClick={handleTabClick} /> :null
                    }
                </div>

                <div className={`tab-content__pane space-y-5 ${ activeTab === "document" ? "active" : "" }`} id="document">
                    {
                        activeTab ==="document"?     <HandleDocument handleTabClick={handleTabClick} />: null
                    }
                </div>
            </div>
        </div>




    </>)
}