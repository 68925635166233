import React, {useState} from "react";

export const useBackup=(domainTokens)=>{

    const [cleanData,setClean] = useState({section:[],domainTokens:[]})

    // updating sections
    const handleChangeSection = (selectedItem) => {
        setClean((prevState) => {
            const isItemExist = prevState.section.some((item) => item.value === selectedItem.value);
            if (isItemExist) {
                return {
                    ...prevState,
                    section: prevState.section.filter((item) => item.value !== selectedItem.value)
                };
            } else {
                return {
                    ...prevState,
                    section: [...prevState.section, selectedItem]
                };
            }
        });
    };
    // handles domainTokens
    const handleChangeDomainToken=(data)=>{
        if (data.some((item) => item.value === '*')) {
            const updatedDomainTokens = domainTokens
                .filter((item) => item.value !== '*') // Exclude item with value '*'
                .map((item) => ({
                    ...item,
                    // Update other properties as needed
                }));
            setClean((prevState) => ({
                ...prevState,
                domainTokens: updatedDomainTokens
            }));
            return;
        }
        setClean((prevState)=>({...prevState,domainTokens: data}))
    }

    //resets data initially
    const ResetCleanData=()=>{
        setClean({domainTokens: [],section: []})
    }




    return {
        cleanData,
        setClean,
        handleChangeDomainToken,
        handleChangeSection,
        ResetCleanData
    }

}