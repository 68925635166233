import React, {useState} from "react";
import {handleWarningMessage} from "../../../../../../Services/Globals/Errors/NotificationService";
import {useTranslation} from "react-i18next";

export const useTab=()=>{
    const { t } = useTranslation();

    const [tabId,setTabId] = useState("content");
    const handleChangeTabId = (tabId) => {
        setTabId(tabId);
    };
    const tabs = [
        {
            id: "content",
            label: t("Main"),
            icon: "pi-file-edit",
        },
        {
            id: "settings",
            label: t("settings"),
            icon: "pi-cog",
        },
    ];

    return {
        tabId,
        handleChangeTabId,
        tabs
    }
}