import 'primeicons/primeicons.css';
import React from "react";
import {useTranslation} from "react-i18next";
import {TinyLoading} from "../Loadings/TinyLoading";

export const FormSubmit=({type,TextButton,style,ButtonStyle,ButtonBgClass})=>{
    const { t } = useTranslation();
    return (
        <>
            <button
                type={type ? type : "submit"}
                className={`button w-24 hbtn-save relative ${style} ${!ButtonBgClass ? `bg-themeInformation dark:bg-themeDarkInformation` : ButtonBgClass}  ${!ButtonStyle ? ' text-white' : ButtonStyle}`}
            >
                {TextButton ? TextButton : t("register")}
            </button>

        </>
    )
}