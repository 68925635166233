import React, { useEffect, useMemo, useState } from "react";
import { DELETE, GET } from "../../../../Services/AxiosService/AxiosApiService";
import { useTranslation } from "react-i18next";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import { GridButtonEdit } from "../../../Commans/Grid/GridButtonEdit";
import { GridButtonDelete } from "../../../Commans/Grid/GridButtonDelete";
import Swal from "sweetalert2";
import { AlertSetting, GridSetting } from "../../../../Services/Globals/Public/AppSetting";
import { useNavigate, useParams } from "react-router-dom";
import { HPrimeIcon } from "../../../Commans/UIParts/HPrimeIcon";
import { GridDateFomartter } from "../../../Commans/Grid/GridDateFomartter";

export const useUserFamilyGrid = (showSpinner, hideSpinner, userFamilyInfo, setUserFamilyInfo, refresh, Refresh, initialSource, setIsEdit, setIsShowModalInformation) => {
    const { t } = useTranslation();
    const { insuranceId } = useParams();
    const [rowData, setRowData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 0, page: 0, pageSize: GridSetting.pageSize, totalCount: 0, hasNextPage: false, hasPreviousPage: false, lastPage: 0, currentPage: 1 })
    const [filter, setFilter] = useState({
        search: '',
        searchValue: '',
        isChild: null,
        isSpouse: null
    });

    // checkBox filter
    const switchData = [
        { name: 'isChild', lable: t("فرزندان"), value: filter.isChild },
        { name: 'isSpouse', lable: t("همسران"), value: filter.isSpouse },
    ]




    const handleGetInsurance = async (insuranceMemberId) => {
        try {
            showSpinner();
            const res = await GET(`/Users/Insurance/Member/${insuranceMemberId}`, {}, 'single');
            if (res.status === 200) {
                hideSpinner();
                setUserFamilyInfo({
                    ...userFamilyInfo,
                    insuranceMemberId: res.data.insuranceMemberId,
                    insuranceRelation: initialSource.insuranceRelations.find(item => item.value === res.data.insuranceRelation),
                    genderType: initialSource.genderTypes.find(item => item.value === res.data.genderType),
                    fullName: res.data.fullName,
                    nationalCode: res.data.nationalCode,
                    mobilePhoneNumber: res.data.mobilePhoneNumber,
                    homePhoneNumber: res.data.homePhoneNumber,
                    description: res.data.description
                });
                setIsEdit(true);
                setIsShowModalInformation(true);

            } else {
                handleApiError(res, hideSpinner);
                hideSpinner();
            }
        } catch {
            hideSpinner();
        }
    }


    const handelDeleteDataGrid = async (insuranceMemberId) => {
        // show sweetalert
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const res = await DELETE(`/Users/Insurance/Member/${insuranceMemberId}`);
                    if (res.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(res, hideSpinner)
                    }
                } catch {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    // Display data to the user
    // const handleSelection = (obj,type) => {
    //     const userType = obj.find((item) => item.value === type);
    //     if (!userType) {
    //         return;
    //     }
    //     return userType.label ?? '-'
    // }
    // For example
    {/* {handleSelection(initialSource.insuranceRelations,parameters.data.insuranceRelation)} */ }
    const columnDefs = [
        { headerName: t("row"), field: 'id', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110, hide: true },
        { headerName: t("row"), field: 'idSequence', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110 },
        {
            headerName: t("userType"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.insuranceRelation}
                </>

        },
        {
            headerName: t("MrMiss"), minWidth: 100,
            cellRenderer: parameters =>
                <>
                    {parameters.data.genderType}

                </>

        },

        {
            headerName: t("FirstNameAndLastName"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.fullName}
                </>

        },

        {
            headerName: t("NationalIdNumber"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.nationalCode}
                </>

        },
        {
            headerName: t("MobilePhone"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.mobilePhoneNumber}
                </>

        },
        {
            headerName: t("LandlinePhone"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.homePhoneNumber}
                </>

        },
        {
            headerName: t("description"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.description}
                </>

        },


        {
            headerName: t("operations"), width: 110, maxWidth: 120, pinned: "left", lockPinned: true, cellClass: "lock-pinned",
            cellRenderer: parameters => {
                return <div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit onClick={() => handleGetInsurance(parameters.data.insuranceMemberId)} />
                    <GridButtonDelete onClick={() => handelDeleteDataGrid(parameters.data.insuranceMemberId)} />
                </div>
            }

        },

    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    // Pagination handel
    const Paginate = (items) => {
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }

    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({ ...pagination, pageSize: pageSize }))
    }


    // Search handel
    const handleSearch = () => {
        setFilter((prevState) => ({ ...prevState, search: prevState.searchValue }));
    }
    const handleResetFilters = () => {
        setFilter((prevState) => ({ ...prevState, search: '', searchValue: '' }));
    }
    const handleSwitchFilters = (data, name) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            [name]: data ? data : null
        }));
    }



    useEffect(() => {
        OnGridReady();
        console.log(filter.isChild)
    }, [refresh, filter.search, filter.isChild, filter.isSpouse, pagination.currentPage, pagination.pageSize])

    const OnGridReady = async () => {
        try {
            showSpinner();
            const res = await GET(`/Users/Insurance/Members`, {
                InsuranceId: insuranceId,
                Search: filter.search,
                isSpouse: filter.isSpouse,
                isChild: filter.isChild,
                PageNumber: pagination.currentPage,
                PageSize: pagination.pageSize,
            }, 'PagedList');
            if (res && res.items !== null) {
                setPagination({ ...pagination, totalPages: res.totalPages, hasNextPage: res.hasNextPage, hasPreviousPage: res.hasPreviousPage, page: res.page, pageSize: res.pageSize, totalCount: res.totalCount });
                const items = Paginate(res.items);
                setRowData(items);
            }
            else {
                handleApiError(res, hideSpinner);
            }
        } catch {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };

    return {
        columnDefs,
        defaultColDef,
        getRowHeight,
        autoGroupColumnDef,
        rowData,
        pagination,
        handlePreviousPage,
        handleNextPage,
        handleCurrentPage,
        handlePageSizeCount,
        filter,
        setFilter,
        handleSearch,
        handleResetFilters,
        switchData,
        handleSwitchFilters
    }
}