import React from "react";

import {Link, useNavigate} from "react-router-dom";
import {HPrimeIconSmall} from "../../UIParts/HPrimeIconSmall";
export const QuickAccessItem=({title,icon,slug,handleDeleteSpeedLink,speedLinkId,target})=>{
    const navigateTo = useNavigate();

    const redirectTo=(url,target)=>{
        if(target === 0){
            navigateTo(url)
        }else if(target === 1){
            window.open(url,'_blank');
        }
    }

    return (<>
        <div key={title}>
            <div className={"flex items-center text-nowrap gap-1 border border-dashed dark:border-themeDarkColorLine zoom-in box bg-white dark:bg-themeDarkSalt700 justify-center p-1 relative"}>
                <button onClick={()=>redirectTo(slug,target)} className={""}>
                    <div className={"flex gap-1 items-center"}>
                        <HPrimeIconSmall icon={icon} sizeIcon={10}/>
                        <span className={"text-xs dark:text-themeDarkInformation"}>  {title}</span>
                    </div>
                </button>

                <button onClick={()=>handleDeleteSpeedLink(speedLinkId)} >
                    <HPrimeIconSmall icon={"pi-times"}  iconClass={"dark:text-themeDarkInformation"} />
                </button>

            </div>
        </div>
    </>)
}