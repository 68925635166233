import Joi from "joi";
export const serverSchema = Joi.object({
    name: Joi.string().required().messages({
        'any.required': 'عنوان سرور الزامیست',
        'string.empty': 'عنوان سرور الزامیست',
        'string.base': 'عنوان سرور الزامیست',
    }),

    password: Joi.string()
        .min(6)
        .max(16)
        .allow(null, '')
        .optional()
        .messages({
            "string.min": "کلمه عبور باید حداقل ۶ کاراکتر باشد",
            "string.max": "کلمه عبور باید حداکثر ۱۶ کاراکتر باشد",
        }),
});