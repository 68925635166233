import React, {useState} from "react";
import {useTranslation} from "react-i18next";
export const useCartableFilters=()=>{
    const [filter,setFilter] = useState({today:null,thisWeek:null,thisMonth:null,search:''});
    const { t } = useTranslation();

    const SwitchFilters = [
        {name:'today',lable:t("امروز"),value:filter.today},
        {name:'thisWeek',lable:t("این هفته"),value:filter.thisWeek},
        {name:'thisMonth',lable:t("این ماه"),value:filter.thisMonth},
    ]
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,today: null,thisWeek: null,thisMonth: null}));
        setSearch('');
    }
    return {
        filter,
        setFilter,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        handleSearch,
        setSearch,
        search
    }
}