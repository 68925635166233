import React, {useEffect, useState} from "react";

import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {Return} from "../../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import Editor from "@monaco-editor/react";
import {useScript} from "./useScript";
import {useNavigate} from "react-router-dom";
import {GET, POST, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {InputTextarea} from "primereact/inputtextarea";
import {TabMenu} from "../../../../Commans/UIParts/TabMenu";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
export const HandleScriptSetting = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsSpinnerShow,setIsShowSpinner] = useState(false);
    const [refresh,setRefresh]=useState(false);
    const {handleEditorChange,code,setCode,options}=useScript()
    const [activeTab, setActiveTab] = useState("tab_one");


    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const showSpinner=()=>setIsShowSpinner(true);
    const hideSpinner=()=>setIsShowSpinner(false);


    const fetchData=async()=>{
        try {
           showSpinner();
           const response = await GET(`/Websites/Scripts`,{},'single');
           if(response.status === 200){
             setCode((prevState)=>(
                 {...prevState
                     ,header: response.data.headScript
                     ,body: response.data.bodyScript
                     ,concurrencyStamp: response.data.concurrencyStamp
                 }))
           }
           else{
               handleApiError(response,hideSpinner);
           }
            hideSpinner();
        }catch (e) {
            hideSpinner();
        }
    }

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


    const tabs = [
        {
            id: "tab_one",
            label: "Header Script",
            icon: "code",
        },
        {
            id: "tab_two",
            label: "Body Script",
            icon: "code",
        },

    ];



    const handleSubmitScript=async(e)=>{
        e.preventDefault();
        try {
           showSpinner();
          const response = await PUT(`/Websites/Scripts`,{
              headScript:code.header,
              bodyScript:code.body,
              concurrencyStamp:code.concurrencyStamp
          },false);
          if(response.status === 200){
              hideSpinner();
              Refresh();
              handleSuccessMessage(t('SuccessDone'));
          }else{
             handleApiError(response,hideSpinner);
          }
        }
        catch (e) {
            hideSpinner();
        }
    }




    useEffect(()=>{
        fetchData();
    },[])




    return(
      <div className="space-y-5">
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
            <ListFilter label={null} icon={null}
                        childrenOne={
                            <>
                                <ButtonPlus
                                    onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                    icon={"pi-cog"}
                                    title={t("settings")}
                                    tooltipTitle={t("SettingsPage")}
                                />

                                <ButtonPlus
                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/Domain`)}
                                    icon={"pi-globe"}
                                    title={t("Domain")}
                                    tooltipTitle={t("Domain")}
                                />

                                <ButtonPlus
                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/ShortcutKey`)}
                                    icon={"pi-key"}
                                    title={t("Shortcut")}
                                    tooltipTitle={t("ShortcutKey")}
                                />


                                <ButtonPlus
                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/Security`)}
                                    icon={"pi-shield"}
                                    title={t("Security")}
                                    tooltipTitle={t("SecurityAndPrivacy")}
                                />
                                <ButtonPlus
                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/BackUp`)}
                                    icon={"pi-server"}
                                    title={t("support")}
                                    tooltipTitle={t("SupportAndRecovery")}
                                />


                            </>
                        }
                        childrenTwo={
                            <>

                            </>
                        }
            />

        </div>
         <form onSubmit={handleSubmitScript} method={'post'} className={"relative"}>
             {
                 IsSpinnerShow?
                     <div className="huploader_loading" >
                         <div className="relative">
                             <LoadingHarmony/>
                         </div>
                     </div>
                     :
                     null
             }
             <div className={"space-y-5"}>
                 <div className="post box bg-white dark:bg-themeDarkSalt700">
                     <TabMenu activeTab={activeTab} handleTabClick={handleTabClick}  tabs={tabs} />
                     <div className={"post__content tab-content "}>
                         <div className={`tab-content__pane p-2  ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                             <div className="containter_OnlineEditor relative " style={{direction:'ltr'}}>
                                 <Editor  language={"JavaScript"} theme="vs-dark" className="online_editor"
                                          value={code.header}
                                          onChange={(data)=>handleEditorChange(data,'header')}
                                          options={options}
                                 />

                             </div>
                         </div>
                         <div className={`tab-content__pane space-y-3 p-2  ${ activeTab === "tab_two" ? "active" : ""}`} id="tab_two">
                             <div className="containter_OnlineEditor relative " style={{direction:'ltr'}}>
                                 <Editor  language={"JavaScript"} theme="vs-dark" className="online_editor"
                                          value={code.body}
                                          onChange={(data)=>handleEditorChange(data,'body')}
                                          options={options}
                                 />

                             </div>
                         </div>
                     </div>

                 </div>
                 <ButtonSubmitAndCancel
                     onCancelClick={()=>redirectTo('/Harmony/Master/setting')}
                 />
             </div>

         </form>
      </div>
  )
}