import React, {useEffect, useMemo, useState} from "react";

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {DELETE, GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {Image} from "primereact/image";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
export const useOrganizationGroupGrid=(showSpinner, hideSpinner, setIsOpen, setIsEdit, refresh, Refresh, setOrganizationGroup, organizationGroup)=>{
    const { t } = useTranslation();
    const [rowData, setRowData] = useState([]);
    const redirectTo = useNavigate();

    const handleGetOrganizationGroup= async(linkOrganizationGroupId)=>{
        try {
            showSpinner();
            setIsEdit(true)
            const response = await GET(`/Forms/LinkOrganizationGroups/${linkOrganizationGroupId}`,{},'single');
            if(response.status === 200){
                setOrganizationGroup({
                    linkOrganizationGroupId:response.data.linkOrganizationGroupId,
                    caption:response.data.caption,
                    icon:response.data.icon,
                    iconPath:response.data.iconPath,
                    rank:response.data.rank
                })
                hideSpinner();
                setIsOpen(true);
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner()
        }
    }

    const handleDeleteOrganizationGroup = async (linkOrganizationGroupId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Forms/LinkOrganizationGroups?LinkOrganizationGroupId=${linkOrganizationGroupId}`,);
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            )
            {
                return;
            }
        })
    }



    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110,hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: t("Image"),field: 'icon', maxWidth: 130,
            cellRenderer:params => {
                return params.data.icon !== null?
                    <Image src={params.data.icon} zoomSrc={params.data.icon} preview />:
                    <HPrimeIcon icon={'pi-times'} iconClass={"text-themeDanger"} />
            }
        },
        { headerName: t("Title"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.caption}
                </>
        },

        { headerName: t("settings"), maxWidth: 120,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button className="text-themeSuccess" onClick={()=>redirectTo(`/Form/Organize/Items?linkOrganizationGroupId=${params.data.id}`)}><HPrimeIcon icon={'pi-list'} /></button>
                    <GridButtonEdit
                        onClick={()=>handleGetOrganizationGroup(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteOrganizationGroup(params.data.id)}
                    />
                </div>
            }

        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    const [filter,setFilter]=useState({search:'',searchValue:''});
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: prevState.searchValue}));
    }
    const handleResetFilters=()=>{
        setFilter((prevState)=>({...prevState,search: '',searchValue: ''}));
    }
    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }


    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize]);


    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Forms/LinkOrganizationGroups`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search:filter.search}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const items = Paginate(response.items);
                setRowData(items);
                hideSpinner();
            }
            else {
                handleApiError(response,hideSpinner);
            }
        } catch (error) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };


    return {
        handleGetOrganizationGroup,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteOrganizationGroup,
        handleSearch,
        handleResetFilters,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
        filter,
        setFilter,
    }




}