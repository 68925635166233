import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";

export const useInboxSms=(setShowSpinner,fetchData,refresh,onApiError,initialSource,Refresh,handleShowMessage)=>{

    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const [rowData, setRowData] = useState([]);


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},


        { headerName: t("Text"),
            cellRenderer:parameters=>
                <div>
                    <button type={"button"} onClick={() => handleShowMessage(true,parameters.data.message,parameters.data.messageId,parameters.data.isRead)} >
                        <p className={"line-clamp-1 underline hover:text-themeInformation dark:hover:text-themeDarkInformation"}>{parameters.data.message}</p>
                    </button>
                </div>

        },


        { headerName: t("Sender"), minWidth: 150, maxWidth: 170,
            cellRenderer:parameters=>
                <>
                    {parameters.data.sender}
                </>
        },
        { headerName: t("Receiver"), minWidth: 150, maxWidth: 170,
            cellRenderer:parameters=>
                <>
                    {parameters.data.receiver}
                </>
        },



        { headerName: t("SendingTime"),width: 130, minWidth: 140,field: 'sendDate',cellRenderer:params => {
                return <GridDateFomartter date={params.data.sendDate} hasTime={true} />
            }},


    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize: GridSetting.pageSize || 10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',operator:'',operatorValue:null,fromDate:null,toDate:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',operator:'',operatorValue: null,fromDate: null,toDate: null}));
        setSearch('');
    }

    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data.value,operatorValue: data}));
    }

    const handleFromDateTime = (dateTime)=> setFilter((prevState)=>({...prevState,fromDate: dateTime}));

    const handleToDateTime = (dateTime)=> setFilter((prevState)=>({...prevState,toDate: dateTime}));




    useEffect(() => {


            OnGridReady();

    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.operator,filter.fromDate,filter.toDate]);


    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            // Use filter.operator if it has data, otherwise use accountId
            const response =  await GET(`/Messaging/Inbox`, {Page: pagination.currentPage, PageSize:pagination.pageSize, Search: filter.search,AccountId: filter.operator,MessageType:'1',FromDate:filter.fromDate,ToDate:filter.toDate}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPreviousPage: response.hasPreviousPage,
                    page: response.page,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount
                }));
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };




    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter,
        handleFromDateTime,
        handleToDateTime
    }

}