import React, {useState , useEffect} from "react";
import {Link ,useLocation } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useCustomRoutes} from "../Hooks/useCustomRoutes";
import 'primeicons/primeicons.css'
import {HPrimeIconSmall} from "../UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";


//this is sidebar and handling other components inside of it .
export const MenuBar=({classesToggled})=>{
    const { t } = useTranslation();
    //const {setWebsite,handleSelectUserWebsite,website}=useSideBar(userWebsites);

    const location = useLocation();



const {UsersFacilities,seo,advertise,CMS,Comment,SocialMarketing,EmailMarketing,DiscountCode,Commission,form,Guarantee,poll,shop,manager,shortMessageService,PersonManagement,support,template}=useCustomRoutes()


    const [activeSubmenu, setActiveSubmenu] = useState("");

    const handleSubmenuClick = (submenuId) => {
        setActiveSubmenu(prevActiveSubmenu => prevActiveSubmenu === submenuId ? "" : submenuId);
    };

    const SidebarLink = ({ to, icon, title, target = false, index}) => {
        const location = useLocation();
        const [isActive, setIsActive] = useState(false);

        useEffect(() => {
            setIsActive(location.pathname === to);
        }, [location, to]);

        return (
            <li>
                <Link
                    to={to}
                    target={target ? '_blank' : null}
                    className={`side-menu ${isActive ? 'menu-active-link' : ''}`}
                    onClick={() => window.scrollTo(0, 0)}
                    style={{ animationDelay: `${(index + 1) * 0.05}s` }}
                >
                    <div className="side-menu__icon">
                        <HPrimeIconSmall icon={`${icon}`}></HPrimeIconSmall>
                    </div>
                    <div className="side-menu__title">{title}</div>
                </Link>
            </li>
        );
    };



    const SubMenu = ({ submenuKey, icon, title, links, index }) => (
        <li>
            <div className={`side-menu cursor-pointer ${activeSubmenu === submenuKey ? "side-menu--active" : ""}`}
                onClick={() => handleSubmenuClick(submenuKey)}
            >
                <div className="side-menu__icon side-menu_header_icon">
                    <HPrimeIcon icon={`${icon}`}></HPrimeIcon>
                </div>
                <div className="side-menu__title side-menu_header_title flex">
                    {title}
                    <HPrimeIcon icon={activeSubmenu === submenuKey ? 'pi-angle-down' : 'pi-angle-up'} />
                </div>
            </div>
            <ul className={`side-menu__sub ${activeSubmenu === submenuKey ? "side-menu__sub-open" : "side-menu--active"}`}>
                {links.map((link, index) => (
                    <SidebarLink key={index} index={index} {...link} />
                ))}
            </ul>
        </li>
    );


    return (
        <>
            <nav className={`side-nav ${classesToggled ? '' : 'hide_side'}`}>
                <div className="space-y-2 side-top-logo">
                    <Link to="https://cpanel.harmonycms.ir/Dashboard" className="intro-x flex justify-center items-center pt-4">
                         <span className="text-white block p-3">
                            <img src="https://cpanel.harmonycms.ir/ArmDark.svg" className="size-full object-contain" alt="Harmony" title="Harmony"/>
                         </span>
                    </Link>
                </div>

                <div className="side-nav__devider my-3"></div>
                <ul>
                    <li>
                        <Link
                            to="/Dashboard"
                            className={`side-menu ${location.pathname === "/Dashboard" ? "side-menu--active side-menu--rounded" : ""}`}
                            onClick={() => {
                                handleSubmenuClick("dashboard")
                                window.scrollTo(0, 0)
                            }}
                        >
                            <div className="side-menu_header_icon"><HPrimeIcon icon="pi-desktop" /></div>
                            <div className="side-menu__title side-menu_header_title">{t("Dashboard")}</div>
                        </Link>
                    </li>
                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={UsersFacilities.submenuKey}
                        icon={UsersFacilities.icon}
                        title={UsersFacilities.title}
                        links={UsersFacilities.links} // Define or pass manager links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={manager.submenuKey}
                        icon={manager.icon}
                        title={manager.title}
                        links={manager.links} // Define or pass manager links
                    />

                    <li className="side-nav__devider"></li>


                    <SubMenu
                        submenuKey={PersonManagement.submenuKey}
                        icon={PersonManagement.icon}
                        title={PersonManagement.title}
                        links={PersonManagement.links}
                    />


                    <li className="side-nav__devider"></li>

                    <li>
                        <Link
                            to="/Public/MainMenu/List"
                            className={`side-menu ${location.pathname === "/Public/MainMenu/List" ? "side-menu--active side-menu--rounded" : ""}`}
                            onClick={() =>{ handleSubmenuClick("MainMenu")
                                window.scrollTo(0, 0)
                            }}
                        >
                            <div className="side-menu_header_icon"><HPrimeIcon icon="pi-bars" /></div>
                            <div className="side-menu__title side-menu_header_title">{t("MenuManagement")}</div>
                        </Link>
                    </li>

                    <SubMenu
                        submenuKey={CMS.submenuKey}
                        icon={CMS.icon}
                        title={CMS.title}
                        links={CMS.links} // Define or pass CMS links
                    />


                    <SubMenu
                        submenuKey={Comment.submenuKey}
                        icon={Comment.icon}
                        title={Comment.title}
                        links={Comment.links} // Define or pass Comment links
                    />

                    <SubMenu
                        submenuKey={template.submenuKey}
                        icon={template.icon}
                        title={template.title}
                        links={template.links} // Define or pass template links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={poll.submenuKey}
                        icon={poll.icon}
                        title={poll.title}
                        links={poll.links} // Define or pass poll links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={advertise.submenuKey}
                        icon={advertise.icon}
                        title={advertise.title}
                        links={advertise.links} // Define or pass advertise links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={form.submenuKey}
                        icon={form.icon}
                        title={form.title}
                        links={form.links} // Define or pass form links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={shop.submenuKey}
                        icon={shop.icon}
                        title={shop.title}
                        links={shop.links} // Define or pass shop links
                    />

                    <li className="side-nav__devider"></li>


                    <SubMenu
                        submenuKey={Guarantee.submenuKey}
                        icon={Guarantee.icon}
                        title={Guarantee.title}
                        links={Guarantee.links} // Define or pass Guarantee links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={DiscountCode.submenuKey}
                        icon={DiscountCode.icon}
                        title={DiscountCode.title}
                        links={DiscountCode.links} // Define or pass DiscountCode links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={Commission.submenuKey}
                        icon={Commission.icon}
                        title={Commission.title}
                        links={Commission.links} // Define or pass DiscountCode links
                    />


                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={SocialMarketing.submenuKey}
                        icon={SocialMarketing.icon}
                        title={SocialMarketing.title}
                        links={SocialMarketing.links} // Define or pass digitalMarketing links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={EmailMarketing.submenuKey}
                        icon={EmailMarketing.icon}
                        title={EmailMarketing.title}
                        links={EmailMarketing.links} // Define or pass digitalMarketing links
                    />
                    <li className="side-nav__devider"></li>
                    <SubMenu
                        submenuKey={shortMessageService.submenuKey}
                        icon={shortMessageService.icon}
                        title={shortMessageService.title}
                        links={shortMessageService.links} // Define or pass shortMessageService links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={seo.submenuKey}
                        icon={seo.icon}
                        title={seo.title}
                        links={seo.links} // Define or pass seo links
                    />

                    <li className="side-nav__devider"></li>

                    <SubMenu
                        submenuKey={support.submenuKey}
                        icon={support.icon}
                        title={support.title}
                        links={support.links} // Define or pass support links
                    />

                    <li className="side-nav__devider"></li>

                    <li>
                        <Link
                            to="/Authentication/FileManager"
                            className={`side-menu ${location.pathname === "/Authentication/FileManager" ? "side-menu--active side-menu--rounded" : ""}`}
                            onClick={() =>{
                                handleSubmenuClick("FileManager")
                                window.scrollTo(0, 0)
                            }}
                        >
                            <div className="side-menu_header_icon"><HPrimeIcon icon="pi-folder" /></div>
                            <div className="side-menu__title side-menu_header_title">{t("FileManagement")}</div>
                        </Link>
                    </li>


                    <li className="side-nav__devider"></li>


                    <li>
                        <Link
                            to="/Statistics"
                            className={`side-menu ${location.pathname === "/Statistics" ? "side-menu--active side-menu--rounded" : ""}`}
                            onClick={() =>{
                                handleSubmenuClick("Statistics")
                                window.scrollTo(0, 0)
                            }}
                        >
                            <div className="side-menu_header_icon"> <i className={`pi pi-chart-bar `} style={{ fontSize: '1.125rem'}}></i></div>
                            <div className="side-menu__title side-menu_header_title">{t("statisticsAndVisits")}</div>
                        </Link>
                    </li>

                </ul>
            </nav>
        </>


    )
}