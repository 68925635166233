export function handleConvertAddGateways(gateways){
    const gateway={
        name:gateways.name === null || gateways.name === undefined ?null:gateways.name,
        currencyType:gateways.currencyType === null || gateways.currencyType === undefined?null:gateways.currencyType.value,
        pspType:gateways.gatewayType === null || gateways.gatewayType === undefined?null:gateways.gatewayType.value,
        isActive:gateways.isActive,
        icon:gateways.icon === null || gateways.icon === undefined?null:gateways.icon
    }
    return gateway;
}
export function handleConvertEditGateways(gateways){
    const gateway={
        pSPId:gateways.id,
        name:gateways.name === null || gateways.name === undefined ?null:gateways.name,
        currencyType:gateways.currencyType === null || gateways.currencyType === undefined?null:gateways.currencyType.value,
        pspType:gateways.gatewayType === null || gateways.gatewayType === undefined?null:gateways.gatewayType.value,
        isActive:gateways.isActive,
        icon:gateways.icon === null || gateways.icon === undefined?null:gateways.icon
    }
    return gateway;
}

export const handleCreateGateway=(obj)=>{
    const formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key,value);
        }
    }
    for (const key in obj) {
        appendIfNotNull(key,obj[key]);
    }
    return formData;
}