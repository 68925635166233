import React, {useEffect, useState} from "react";
import {ListSearch} from "../../../Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ButtonSearchList} from "../../../UIParts/ButtonSearchList";
import {ListFilter} from "../../../Grid/ListFilter";
import {TransactionItem} from "../TransactionItem";
import {useTranslation} from "react-i18next";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";

import {useTransactionGrid} from "./useTransactionGrid";
import {UseCustomTransaction} from "./useCustomTransaction";
import {DateTimePicker} from "../../../Dates/DateTimePicker";
import {InputSwitchListFilter} from "../../../Filters/InputSwitchListFilter";
import {LoadingHarmony} from "../../../Loadings/LoadingHarmony";
import {GetSpecialProperty} from "../../../../../Services/AxiosService/JwtService";
import Select from "react-select";
import {Pagination} from "../../../Grid/Pagination";
import {NoDataHarmony} from "../../../NoDataHarmony";
import {SettingButton} from "../../../UIParts/SettingButton";
import {useSetting} from "../../../Hooks/useSetting";
import {FantasyModal} from "../../../UIParts/FantasyModal";
import {HandleGeneralSetting} from "../../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HPrimeIcon} from "../../../UIParts/HPrimeIcon";

export const Transaction=({currentPath})=>{
    const { t } = useTranslation();
    const [refresh,setRefresh] = useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [errors,setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({users:[],currencies:[]})
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleInitialSource=(currencies)=>{
        const currencyData = convertSelectDataHandler(currencies);
        setInitialSource({
            currencies: currencyData,
        })
    }
    const fetchData=async()=>{
        try {
            showSpinner();

            const response = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
            if(response.status === 200){
                handleInitialSource(response.data);
                hideSpinner();
            }
            else {
                handleApiError(response,hideSpinner);
            }
            if(currentPath === '/Profile/Wallet'){

            }
            else{
                const responseUser = await GET(`/Users/Selection`, {}, 'IEnumerable');
                if (responseUser.status === 200) {
                    const userData = convertSelectDataHandler(responseUser.data);
                    setInitialSource({ users: userData });
                    setWallet({userId: userData[0]});
                } else {
                    handleApiError(responseUser, hideSpinner);
                }
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[])

    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }
    const GetTransactionType=(type)=>{
        return (<>
            {
                type === 1?
                    t('Deposit'):
                    t('Withdrawal')

            }
        </>)
    }
    const GetTransactionIcon=(type)=>{
        return (<>
            {
                type === 1?
                    <div className={"flex-shrink-0 bg-themeSuccess h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-arrow-right"} iconClass="text-white"/>
                    </div>
                    :
                    <div className={"flex-shrink-0 bg-themeDanger h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-arrow-left"} iconClass="text-white"/>
                    </div>
            }

        </>)
    }

    const ShowTargetUser = () => {
        return (
            <>
                {currentPath === '/Profile/Wallet' ? (
                    <InputText disabled={true}  value={GetSpecialProperty('DisplayName')} type="text"   className=" text-center input w-full" />
                    ) : (
                    <div className=" gap-2 items-center">
                        <Select
                            options={initialSource.users}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(data) => handleSelectValue(data, 'userId')}
                            placeholder={t("selection")}
                            value={wallet.userId}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                        />
                    </div>
                )}
            </>
        );
    };




    const {handleNumberValue,handleValue,setWallet,wallet,handleSelectValue} = UseCustomTransaction(setErrors,hideSpinner,showSpinner);

    const {rowData,pagination,getRowHeight,autoGroupColumnDef,defaultColDef,handleNextPage,handlePreviousPage,handleCurrentPage,handlePageSizeCount,toggleFilters,handleToggleFilter,handleToDate,handleSearchTrackingCode,handleFromDate,handleResetAllFilters,filter,setFilter,trackingCode,setTrackingCode} = useTransactionGrid(showSpinner,hideSpinner,wallet,currentPath)


    return (
        <>
            <div class=" p-2 box bg-white dark:bg-themeDarkSalt700 space-y-5">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            {ShowTargetUser()}
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("TransactionCode")}</label>
                            <InputText  onChange={(e)=>setTrackingCode(e.target.value)}  value={trackingCode} type="text" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,trackingCode: e.target.value})):null}  className=" input w-full" />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}

                            </label>
                            <DateTimePicker handleDateTime={handleFromDate} value={filter.fromDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}
                            </label>
                            <DateTimePicker value={filter.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearchTrackingCode()}
                            onClickReset={()=>handleResetAllFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenOne={false}
                                childrenTwo={
                                <>
                                    <InputSwitchListFilter switchData={toggleFilters} OnValueSelection={handleToggleFilter} />
                                </>
                                }

                    />

                </div>
                <div className={"p-2 space-y-5"}>
                    { rowData.length === 0 ?
                        <NoDataHarmony/>
                        :
                        <div>
                            <ul className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5"}>
                                {rowData.length > 0 ? rowData.map((transaction, index) => (
                                    <TransactionItem key={index} GetTransactionType={GetTransactionType} GetTransactionIcon={GetTransactionIcon}  GetCurrencyType={GetCurrencyType} transaction={transaction} />
                                )):''}
                            </ul>
                        </div>
                    }

                </div>
                {rowData.length !== 0 ?
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    :
                    null
                }

            </div>


            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>

        </>
    )
}