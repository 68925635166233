import React from "react";
import {TabMenu} from "../../Commans/UIParts/TabMenu";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import SortableTree from "@nosferatu500/react-sortable-tree";

export const MainMenuTab=({activeTab,handleChangeTab,tabs,tabId,mainMenuSlugs,handleChangeSlug,initialSlug,onChangeSource,source})=>{
    const { t } = useTranslation();



    return (

        <>
            <div class="space-y-3">
                <div class="post  dark:bg-themeDarkSalt700">
                    <TabMenu activeTab={activeTab} handleTabClick={handleChangeTab} tabs={tabs} />
                    <div className="post__content tab-content bg-white box p-2">
                        {
                            tabId === 'content' ?
                                <div className={`tab-content__pane ${activeTab === "content" ? "active" : ""}`} id="content">
                                    <div className="space-y-5 post-input post-input-design dark:bg-themeDarkPostInput h-screen">
                                        <SortableTree
                                            treeData={source}
                                            onChange={(dataSource) =>onChangeSource(dataSource)}
                                            dndType={'yourNodeType'}
                                            shouldCopyOnOutsideDrop={false}
                                        />
                                    </div>
                                </div>
                            :
                            tabId === 'shop'    ?
                                <div className={`tab-content__pane ${activeTab === "shop" ? "active" : ""}`} id="shop">
                                    <div className="space-y-5 post-input post-input-design dark:bg-themeDarkPostInput h-screen">
                                        {/*تکراری*/}
                                        <SortableTree
                                            treeData={source}
                                            onChange={(dataSource) =>onChangeSource(dataSource)}
                                            dndType={'yourNodeType'}
                                            shouldCopyOnOutsideDrop={false}
                                        />
                                        {/*تکراری*/}
                                    </div>
                                </div>
                            :
                             tabId === 'survey' ?
                                 <div className={`tab-content__pane ${activeTab === "survey" ? "active" : ""}`} id="survey">
                                     <div className="space-y-5 post-input rounded-md dark:bg-themeDarkPostInput p-2 h-screen">


                                         <SortableTree
                                             treeData={source}
                                             onChange={(dataSource) =>onChangeSource(dataSource)}
                                             dndType={'yourNodeType'}
                                             shouldCopyOnOutsideDrop={false}
                                         />
                                     </div>
                                 </div>
                            :
                              tabId === 'form'?
                                  <div className={`tab-content__pane ${activeTab === "form" ? "active" : ""}`} id="form">
                                      <div className="space-y-5 post-input post-input-design dark:bg-themeDarkPostInput p-2 h-screen">
                                          <SortableTree
                                              treeData={source}
                                              onChange={(dataSource) =>onChangeSource(dataSource)}
                                              dndType={'yourNodeType'}
                                              shouldCopyOnOutsideDrop={false}
                                          />
                                      </div>
                                  </div>
                            :null
                        }
                    </div>
                </div>

            </div>

        </>


    )
}