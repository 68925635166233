import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {Link, useNavigate} from "react-router-dom";

import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {PriceFormatterComponent} from "../../Commans/Accounting/PriceFormatterComponent";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const usePaymentGrid=(handleOpenPayment,setShowSpinner,hideSpinner,fetchData,refresh,Refresh,initialSource,handleOpenManualPayment,currentPath,handleOpenUser)=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const queryParams = queryString.parse(window.location.search);
    const [rowData, setRowData] = useState([]);




    const handleCancelPayment = async (paymentId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:t('SureToCancel'),
            text:t('ThereIsNowayback'),
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: t('YesCancelit'),
            cancelButtonText:t('Close'),
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setShowSpinner(true)
                    const response = await PUT(`/Payments/Cancel`,{paymentId:paymentId},false);
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh()
                        handleSuccessMessage(t('PaymentCanceledSuccess'))
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }













    const GetServiceType = (type) => {
        if (!initialSource.serviceTypes) {
            return;
        }
        const data = initialSource.serviceTypes.find((item) => item.value === type);
        return data ? data.label : '';
    }

    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }

    const GetPaymentState = (paymentStatus) => {
        if (!initialSource.paymentStates) {
            return;
        }
        const data = initialSource.paymentStates.find((item) => item.value === paymentStatus);
        return data ? data.label : '';
    }

    const GetTransactionState = (transactionState) => {
        if (!initialSource.transactionStates) {
            return;
        }
        const data = initialSource.transactionStates.find((item) => item.value === transactionState);
        return data ? data.label : '';
    }

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},


        {
            headerName: t("Party"),
            minWidth: 150,
            hide: currentPath === '/Profile/InvoiceRequest',
            cellRenderer: parameters => (

                    <div className={'cursor-pointer'} onClick={()=>handleOpenUser(parameters.data.userId)}>{parameters.data.userFullName}</div>
            )
        },
        {
            headerName: t("Name"),
            minWidth: 150,
            hide: currentPath === '/Profile/InvoiceRequest',
            cellRenderer: parameters => (
                <>
                    {parameters.data.title}
                </>
            )
        },
        { headerName: t("TrackingCode"), minWidth: 120,

            cellRenderer:parameters=>
                <>
                    {parameters.data.trackingCode}
                </>
        },
        { headerName: t("RegistrationDate"), minWidth: 120,

            cellRenderer:parameters=>
                <>
                    <GridDateFomartter date={parameters.data.creationDate} />
                </>
        },
        { headerName: t("ValidityDate"), minWidth: 120,

            cellRenderer:parameters=>
                <>
                    <GridDateFomartter date={parameters.data.expirationDate} />
                </>
        },
        { headerName: t("TypeofServices"), minWidth: 160,
            cellRenderer:parameters=>
                <>
                    {GetServiceType(parameters.data.checkout)}
                </>

        },

        { headerName: t("Price"), minWidth: 150,
            cellRenderer:parameters=>
                <>
                    <PriceFormatterComponent price={parameters.data.amount.amount}/>  {GetCurrencyType(parameters.data.payableAmount.currency)}
                </>

        },
        // { headerName: t("type"),width: 130, minWidth: 140,
        //     cellRenderer:parameters=>
        //         <>
        //         {GetCurrencyType(parameters.data.payableAmount.currency)}
        //
        //         </>
        //
        // },
        { headerName: t("PaymentStatus"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    {GetPaymentState(parameters.data.paymentStatus)}

                </>
        },
        { headerName: t("TransactionState"), minWidth: 180,
            cellRenderer:parameters=>
                <>
                    {GetTransactionState(parameters.data.transactionState)}

                </>

        },
        { headerName: t("details"), maxWidth: 140,
            cellRenderer:parameters=>
                <div>
                    <Link to={`/Shop/Product/Cart/Items`} className="table_Btn bg-themeSecondary tooltip_class_InvoiceDetail text-white text-xs" >
                        <HPrimeIcon  icon={'pi-file-edit'}  />
                        {t("details")}
                        <Tooltip anchorSelect={".tooltip_class_InvoiceDetail"} content={t("InvoiceDetail")} positionStrategy={"fixed"}	 />
                    </Link>
                </div>
        },
        { headerName: t("transactions"),width: 130, maxWidth: 120,
            cellRenderer:parameters=>
                <div>
                    <Link to={`/Bank/Transaction/list?paymentId=${parameters.data.paymentId}`} className="table_Btn bg-themeInformation dark:bg-themeDarkInformation text-white text-xs tooltip_class_ListOfTransactions" >
                        <HPrimeIcon  icon={'pi-eye'}  />
                        {t("viewing")}
                    </Link>

                    <Tooltip anchorSelect={".tooltip_class_ListOfTransactions"} content={t("ListOfTransactions")} positionStrategy={"fixed"}	 />
                </div>
        },
        {
            headerName: t("operations"),
            minWidth: 300,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer: params => {
                return (
                    <div className={'flex justify-center items-center gap-2'}>
                        {params.data.paymentStatus === 0 && params.data.creationDate && (!params.data.expirationDate || params.data.creationDate < params.data.expirationDate) ?
                            <>
                                {
                                    currentPath === '/Profile/InvoiceRequest'?
                                        null
                                        :
                                        <button className="table_Btn bg-themeInformation dark:bg-themeDarkInformation text-white dark:bg" onClick={() => handleOpenManualPayment(params.data.payableAmount.amount, params.data.amount.currency, params.data.paymentId, params.data.userId, params.data.checkout)}>
                                            <HPrimeIcon  icon={'pi-credit-card'} />
                                            <h2 className={"text-xs text-nowrap"}>{t("ManualPayment")}</h2>
                                        </button>
                                }



                                <button className="table_Btn bg-themeSuccess text-white" onClick={() => handleOpenPayment(params.data.payableAmount.amount, params.data.amount.currency, params.data.paymentId, params.data.userId, params.data.checkout)}>
                                    <HPrimeIcon icon={'pi-credit-card'} />
                                    <h2 className={"text-xs text-nowrap"}>{t("onlinePayment")}</h2>
                                </button>
                                <button className="table_Btn bg-themeDanger text-white" onClick={() => handleCancelPayment(params.data.paymentId)}>
                                    <HPrimeIcon icon={'pi-bolt'} />
                                    <h2 className={"text-xs text-nowrap"}>{t("Cancel")}</h2>
                                </button>
                            </>
                            :
                            null
                        }

                    </div>
                );
            }
        }

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',fromDate:null,toDate:null,isPaid:null,isPending:null,isCancelled:null});

    const SwitchFilters = [
        {name:'isPaid',lable:t("Paid"),value:filter.isPaid},
        {name:'isPending',lable:t("isPending"),value:filter.isPending},
        {name:'isCancelled',lable:t("Ordercancellation"),value:filter.isCancelled}
    ]

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',fromDate:null,toDate:null,isPaid:null,isPending:null,isCancelled:null}));
        setSearch('');
    }

    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data.value}));
    }

    const handleFromDate=(dateTime)=>{
        setFilter(({...filter,fromDate:dateTime}));
    }
    const handleToDate=(dateTime)=>{
        setFilter(({...filter,toDate:dateTime}));
    }


    useEffect(() => {
        const loadData = async () => {
            try {
                setShowSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    // fetchData(); // Remove await if handleFetchData is not asynchronous
                 } else {
                    handleApiError(response, hideSpinner);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setShowSpinner(false);
            }
        };

        loadData();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.isCancelled,filter.isPaid,filter.isPending,filter.fromDate,filter.toDate]);

    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const url = currentPath === '/Profile/InvoiceRequest'?'/Payments/Profile':'/Payments';
            const response =  await GET(url, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,IsPaid:filter.isPaid,IsPending:filter.isPending,IsCancelled:filter.isCancelled,FromDate:filter.fromDate,ToDate:filter.toDate}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter,
        handleFromDate,
        handleToDate
    }
}