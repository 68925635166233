import React, {useEffect, useMemo, useState} from "react";

import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {Tooltip} from "react-tooltip";
import {Image} from "primereact/image";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const useGatewayGrid=(setShowSpinner,fetchData,refresh,onApiError,initialSource,setGateway,setIsOpen,setIsEdit,Refresh)=>{
    const { t } = useTranslation();
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [coldId,setColId]=useState(null);


    const handleGetGateway= async(gatewayId)=>{
        try {
            setShowSpinner(true);
            setIsOpen(true);
            setIsEdit(true);
            const response = await GET(`/Payments/PSP/${gatewayId}`,{},'single');
            if(response.status === 200){
                setGateway({
                    id:response.data.pspId,
                    name:response.data.name,
                    currencyType:initialSource.currencies.find((item)=>item.value === response.data.currencyType),
                    gatewayType:initialSource.gatewayTypes.find((item)=>item.value === response.data.pspType),
                    isActive:response.data.isActive
                })
                setShowSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError();
            console.log(e);
        }
    }
    const handleDeleteGateway = async (gatewayId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setShowSpinner(true)
                    const response = await DELETE(`/Payments/PSP/${gatewayId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh()
                        handleSuccessMessage(t("RemovedSuccessfully"))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const handleGetCurrenyTypeName = (currency) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === currency);
        return data ? data.label : '';
    }
    const handleGatewayTypeName = (gatewayType) => {
        if (!initialSource.gatewayTypes) {
            return;
        }
        const data = initialSource.gatewayTypes.find((item) => item.value === gatewayType);
        return data ? data.label : '';
    }

    const handleChangeActivation = async(gatewayId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            setColId(gatewayId)
            const response = await PUT(`/Payments/PSP/Activation`,{pspId:gatewayId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh(response.status);
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            setWait(false);
            onApiError();
        }
    }



    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("Icon"),maxWidth: 150,
            cellRenderer:params => {
                return params.data.icon !== null?
                    <Image src={params.data.icon} zoomSrc={params.data.icon} alt="Image" preview/>:
                    <HPrimeIcon icon={'pi-times'} iconClass={"text-themeDanger"} />
            }
        },

        { headerName: t("OperatorName"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.name}
                </>
        },

        { headerName: t("CurrencyUnit"), maxWidth: 150,
            cellRenderer:parameters=>
                <>{handleGetCurrenyTypeName(parameters.data.currencyType)}</>
        },
        { headerName: t("GatewayType"), minWidth: 180,
            cellRenderer:parameters=>
                <>{handleGatewayTypeName(parameters.data.pspType)}</>
        },

        { headerName: t("Status"),field: 'isActive', maxWidth: 115, cellRenderer:params=>
                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && coldId === params.data.id ? (
                        <TinyLoading />
                    ) : params.data.isActive ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeActivation(params.data.pspId,params.colDef.headerName)}
                            icon={"pi-check-square"}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeActivation(params.data.pspId,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}

                        />
                    )}
                </div>
        },

        { headerName: t("Gateways"), maxWidth: 120,
            cellRenderer:parameters=>
                <div>
                    <Link to={`/Admin/PaymentGateways/PaymentPort?pspId=${parameters.data.pspId}&pspType=${parameters.data.pspType}`} className="text-themeInformation block dark:text-themeDarkInformation tooltip_class_add" >
                        <HPrimeIcon icon={'pi-plus-circle'}/>
                        <Tooltip anchorSelect={".tooltip_class_add"} content={t("CreateBankGateway")} positionStrategy={"fixed"}	 />
                    </Link>
                </div>
        },


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetGateway(params.data.pspId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteGateway(params.data.pspId)}
                    />
                </div>
            }
        },

    ];



    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }

    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }




    useEffect(() => {
        const loadData = async () => {
            try {
                setShowSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    fetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setShowSpinner(false);
            }
        };
        loadData();
    }, [refresh, pagination.currentPage, pagination.pageSize]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/Payments/PSP`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };



    return {
        handleGetGateway,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteGateway,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
    }


}