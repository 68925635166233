import Joi from "joi";
//this is schema validation of login to be used for yup and reat-hook-form

export const TemplateSchema = Joi.object({
    Name: Joi.string().required().messages({
        'any.required': 'عنوان قالب الزامیست',
        'string.empty': 'عنوان قالب الزامیست',
        'string.base': 'عنوان قالب الزامیست',
    }),
    RootFolder: Joi.string().required().messages({
        'any.required': ' مسیر فولدر الزامیست',
        'string.empty': 'مسیر فولدر الزامیست',
        'string.base': 'مسیر فولدر الزامیست',
    }),
});