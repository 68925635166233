import React from "react";

import {ButtonSubmitAndCancel} from "../Commans/UIParts/ButtonSubmitAndCancel";
import {useTranslation} from "react-i18next";
import {UploadImage} from "../Commans/Uploaders/UploadImage";
import Select from "react-select";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";


export const HandleInventoryCommunications = () => {

  const { t } = useTranslation();
  const redirectTo = useNavigate();


  return(
      <div class="space-y-5 ">
        <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
          <ListFilter label={null} icon={null}
                      childrenOne={
                        <>

                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/Goods/Add")}
                              title={t("Inventory")}
                              tooltipTitle={t("CreateInventory")}

                          />
                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/Goods/Add")}
                              title={t("GoodsRegistration")}
                              tooltipTitle={t("GoodsRegistration")}

                              icon={"edit"}
                          />
                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/EntryDocument")}
                              title={t("DocumentRegistration")}
                              tooltipTitle={t("DocumentRegistration")}
                              icon={"file-plus"}
                          />
                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/ProductionSeries")}
                              title={t("ProductionSeries")}
                              tooltipTitle={t("ProductionSeries")}
                              icon={"edit"}
                          />

                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/List/Requests")}
                              title={t("list")}
                              tooltipTitle={t("ListOfRequests")}
                              icon={"list"}
                          />

                          <ButtonPlus
                              onClick={()=>redirectTo(`/Inventory/Report`)}
                              icon={"pie-chart"}
                              title={t("Report")}
                              functionClass={"harmony-return-list"}
                              tooltipTitle={t("InventoryStockReport")}
                          />
                        </>
                      }
                      childrenTwo={
                        <>

                        </>
                      }
          />

        </div>
        <div class="box bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded-md">
          <div className="p-2 space-y-5 max_height85vh">
                <div  className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 items-end gap-2 post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                  <div className={"flex gap-2"}>
                    <div className="mt-auto">
                      <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                        1
                      </div>
                    </div>
                    <div className="space-y-1 flex-1">
                      <label className="text-xs dark:text-gray-300">{ t("CommunicationType")}</label>
                      <Select
                          isMulti={false}
                          isClearable={true}
                          isSearchable={true}

                          placeholder={t("selection")}
                          menuPosition={"fixed"}
                          classNamePrefix={"h_multi_select"}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("TypeIndividualLegal")}</label>
                    <Select

                        isMulti={false}
                        isClearable={true}
                        isSearchable={true}
                        placeholder={t("selection")}
                        menuPosition={"fixed"}
                        classNamePrefix={"h_multi_select"}
                    />
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("ContactAddress")}</label>
                    <input type="text" className="input w-full"  />
                  </div>
                  <div className={"lg:col-span-2 flex gap-2 items-end"}>
                    <div className="space-y-1 flex-1">
                      <label className="text-xs dark:text-gray-300">{t("description")}</label>
                      <input type="text" className="input w-full"
                      />
                    </div>
                    <div className="flex gap-2" >
                      <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white ">
                        <HPrimeIcon icon={'pi-plus'}  />
                      </button>

                      <button type="button" className="button small_button bg-themeDanger text-white">
                        <HPrimeIcon icon={'pi-minus'}  />
                      </button>
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <ButtonSubmitAndCancel/>
      </div>
  )
}