import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";

import {Button} from "primereact/button";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import { InputNumber } from 'primereact/inputnumber';
import {TabMenu} from "../../Commans/UIParts/TabMenu";
import {TabMenuTwo} from "../../Commans/UIParts/TabMenuTwo";
import {Return} from "../../Commans/Globals/Return";
import ButtonVideo from "../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import {UnConfirmedRequest} from "./UnConfirmed/UnConfirmedRequest";
import {ConfirmedRequest} from "./Confirmed/ConfirmedRequest";
import {WithdrawTransaction} from "./WithdrawalTransactions/WithdrawTransaction";



export const HandleWithdrawalRequest = () => {

  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [activeTab, setActiveTab] = useState("unconfirmed");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = [
    {
      id: "unconfirmed",
      label:t("AwaitingConfirmation"),
      tooltipTitle:t("Pendingwithdrawalconfirmationlist"),
    },

    {
      id: "confirmed",
      label:t("Completed"),
      tooltipTitle:t("Approvedwithdrawals"),
    },

    {
      id: "transaction",
      label:t("transactions"),
      tooltipTitle:t("ListOfTransactions"),
    },

  ];



  return(
      <div className="space-y-5">
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
          <ListFilter label={null} icon={null}
                      showChildrenTwo={false}
                      childrenOne={
                        <>
                          <ButtonPlus
                              onClick={()=>redirectTo(`/CRM/User/Wallet`)}
                              title={t("Wallet")}
                              tooltipTitle={t("Wallet")}
                          />

                        </>
                      }

          />

        </div>
        <div class="space-y-2">
          <div class="relative grid grid-cols-2 lg:grid-cols-7 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-3">
              <TabMenuTwo activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
          </div>
          <div className="post__content tab-content bg-white p-2 dark:bg-themeDarkSalt700 box">
            <div className={`tab-content__pane ${ activeTab === "unconfirmed" ? "active" : "" }`}  id="unconfirmed">
              {
                activeTab === 'unconfirmed'?
                    <UnConfirmedRequest/>
                    :null
              }
            </div>
            <div className={`tab-content__pane ${ activeTab === "confirmed" ? "active" : "" }`}  id="confirmed">
              {
                activeTab === 'confirmed'?
                        <ConfirmedRequest/>
                        :null
              }
            </div>
            <div className={`tab-content__pane ${ activeTab === "transaction" ? "active" : "" }`}  id="transaction">
              {
                activeTab === 'transaction'?
                    <WithdrawTransaction/>
                    :null
              }
            </div>
          </div>
        </div>
      </div>
  )
}