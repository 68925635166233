import { useState } from "react";
export const usePackageGroup=(setErrors)=>{
    const [packageGroup,setPackageGroup] = useState({
        packageGroupId:null,
        name:null,
        concurrencyStamp:null
    });


    const handleValue=(e)=>{
        const {name,value} = e.target;
        setPackageGroup({...packageGroup,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    return {
        packageGroup,
        setPackageGroup,
        handleValue
    }
}