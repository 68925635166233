import React, {useEffect, useMemo, useRef, useState} from "react";

import { InputText } from "primereact/inputtext";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../../../../Commans/Grid/ListSearch";
import Select from "react-select";
import {ButtonSearchList} from "../../../../Commans/UIParts/ButtonSearchList";
import {GridTable} from "../../../../Commans/Grid/GridTable";
import {Button} from "primereact/button";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {InputTextarea} from "primereact/inputtextarea";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {GridButtonEdit} from "../../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const HandleMetaTagSettings=()=>{
    const { t } = useTranslation();

    const [modal, setModal] = useState({ AddReminder: false,See:false, });
    const [IsOpenAddMetaTag, setOpenAddMetaTag] = useState(false);


    const openAddMetaTag=()=>{
        setOpenAddMetaTag(true)
    }

    const closeAddMetaTag=()=>{
        setOpenAddMetaTag(false)
    }


    const SeeOnHide = () => {
        setModal((prevState) => ({ ...prevState, See: !modal.See }));
    };


    const [isMenuOpen,setMenuOpen] = useState(false);
    const [userId,setUserId] = useState('');
    const handleOpenMenu = () => {
        setMenuOpen(true);

    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }


    const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("نوع محتوا"),
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: t("صفحه هدف"),

            cellRenderer:parameters=>

                <>مدیریت</>

        },
        { headerName: t("نوع متاتگ"),
            cellRenderer:parameters=>
                <>با سلام، احتراما باستحضار میرساند جهت تمدید فاکتور سالیانه فروشگاه اکسیرمن به آدرس exirman.com هرچه سریعتر اقدام نمایید. در صورت عدم پیگیری و انقضای هاست و دامین های ذکر شده در فاکتور فوق گروه نرم افزاری آرین هیچ مسئولیتی در قبال از دست رفتن اطلاعات وب سایت شما ندارد. با تشکر
                </>

        },

        {
            headerName: t("اسکریپت"),
            cellRenderer: parameters =>
                <div>
                    <button type="button" className="table_Btn text-xs bg-themeInformation dark:bg-themeDarkInformation text-white zoom-in" onClick={() => setModal({See: true})}>
                        <HPrimeIcon icon={"pi-eye"} />
                        مشاهده
                    </button>
                </div>

        },
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit

                    />
                    <GridButtonDelete

                    />

                </div>
            }
        },

    ];

    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };

    const [columnApi, setColumnApi] = useState(null);
    const [gridApi, setGridApi] = useState(null);

    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const updateColumnDefinitionsBasedOnViewport = () => {
        if (columnApi) {
            const isMobile = window.innerWidth <= 768; // نقطه شکست برای موبایل

            const colDef = columnApi.getColumnState();

            colDef.forEach(col => {
                if (col.colId === 'settingsColumn') {
                    col.pinned = isMobile ? null : 'left';
                    col.lockPinned = !isMobile;
                }
            });

            columnApi.applyColumnState({ state: colDef, applyOrder: true });
        }
    };

    useEffect(() => {
        if (gridApi && columnApi) {
            updateColumnDefinitionsBasedOnViewport();

            window.addEventListener('resize', updateColumnDefinitionsBasedOnViewport);
            return () => window.removeEventListener('resize', updateColumnDefinitionsBasedOnViewport);
        }
    }, [gridApi, columnApi]);



    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setColumnApi(params.columnApi);
        updateColumnDefinitionsBasedOnViewport(params);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };





    return (
        <>

            <div class=" space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">جستجو براساس (****)</label>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>

                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">نوع متاتگ</label>
                            <Select    placeholder={t("selection")}
                                       classNamePrefix={"h_multi_select"}
                                       menuPosition={"fixed"}
                            />
                        </div>

                        <ButtonSearchList />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>openAddMetaTag()}
                                            tooltipTitle={t("ایجاد متاتگ")}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />


                </div>
                <div>
                    <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                               enableRtl={true}
                               pagination={true}
                               getRowHeight={getRowHeight}
                               onGridReady={onGridReady}
                               quickFilterText={quickFilterText}

                    />
                </div>

            </div>

            <FantasyModal openModal={modal.See} closeModal={SeeOnHide} title={t("event")}>
                <div>

                    <div className="space-y-2">
                        <label className={"text-xs dark:text-gray-300"}>اسکریپت</label>
                        <div>
                            <InputTextarea rows={15} cols={30} className={"input"} value="منابع موزیک بدون کپی رایت، جهت اینسب منابع موزیک بدون کپی رایت، جهت اینسب منابع موزیک بدون کپی رایت، جهت اینسب"/>
                        </div>
                    </div>
                    <div>
                        <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("edit")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenAddMetaTag} closeModal={closeAddMetaTag} title={t("ایجاد متاتگ")} customeClassName={""}>
                <div className={"space-y-5"}>
                    <div className={"space-y-3"}>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>انتخاب متاتگ</label>
                            <Select
                                placeholder={t("")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 "}>
                            <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>نوع محتوا</label>
                                <Select
                                    placeholder={t("DeterminingVerdict")}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                                />
                            </div>
                            <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>صفحه هدف</label>
                                {/*در صورت نوع انتخاب یکی از این موارد نماش داده میشود*/}
                                <InputText className={"input hidden"}/>
                                <Select
                                    placeholder={t("")}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                                />
                            </div>

                        </div>
                        <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3"}>
                            <div className={"p-2 bg-themeLightGray post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                                <h2 className={"text-xs "}>پارمتر ها</h2>
                            </div>
                            <div>
                                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 p-2 post-input dark:bg-themeDarkPostInput post-input-design"}>
                                    <div className={"space-y-1"}>
                                        <label className={"text-xs dark:text-gray-300"}>پارامتر</label>
                                        <InputText className={"input "} disabled={true}/>
                                    </div>
                                    <div className={"space-y-1"}>
                                        <div className={"flex gap-2 items-center "}>
                                            <label className={"text-xs dark:text-gray-300"}>مقدار</label>
                                            <div class={`can-toggle demo-rebrand-1 w-28 text-xs`}>
                                                <input id={1} type="checkbox" />
                                                <label htmlFor={1}>
                                                    <div class="can-toggle__switch" data-checked={"ثابت"} data-unchecked={"پویا"}></div>
                                                </label>
                                            </div>
                                        </div>
                                        {/*درصورت انتخاب ثابت textbox*/}

                                        <InputText className={"input hidden"}/>

                                        {/*درصورت انتخاب پویا selectbox که با توجه به نوع صفحه هدف پر خواهد شد*/}
                                        <Select
                                            placeholder={t("")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <lable className="text-xs dark:text-white">وضعبت</lable>
                                        <div>
                                            <input className="input input--switch " type="checkbox"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type={"button"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("register")}</button>
                </div>
            </FantasyModal>
        </>

    )
}
