import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import React from "react";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";


export const Return=(props)=>{
    const { t } = useTranslation();

    const redirectTo=useNavigate();

    const ReturnBack=()=>{
        window.history.back();

        // alert(url);
        // redirectTo(url);
        // dispatch(setSelectedOption(t(props.dispatchLocale)));
    }

    return (<>
        <div className="space-y-1 flex flex-col items-center justify-center">
            <button type={'button'} onClick={ReturnBack} className="button tooltip_return-back box zoom-in  bg-themeInformation rounded-full hover:bg-themeHoverInformation text-white dark:bg-themeDarkInformation hbtn-return">
                <Tooltip anchorSelect=".tooltip_return-back" content={t("Returnprevious")} positionStrategy={"fixed"} />
                <span class=" flex size-7 items-center justify-center" >
                    <HPrimeIcon icon={`dark:text-gray-300  ${props.icon ? props.icon:"pi-reply"}`} iconClass={props.iconClass} sizeIcon={props.sizeIcon} />
                   {/*<i className="pi pi-reply" style={{ fontSize: '1.125rem' }}></i>*/}
            </span>
            </button>
            <p className="text-xs text-center dark:text-gray-300 text-nowrap">{t("Return")}</p>
        </div>
    </>)
}
