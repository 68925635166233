import {GridDateFomartter} from "../../../../Commans/Grid/GridDateFomartter";
import React from "react";

export const ReminderBox = ({ index, title, description, message, type, reminderDateUtc }) => {
    return (
        <div key={index} className="zoom-in overflow-hidden post-input post-input-design dark:bg-themeDarkPostInput block">
            <div className="flex">
                <div className="p-3 bg-themeInformation grid place-content-center dark:bg-themeDarkInformation">
                    <span className="text-white">{index + 1}</span>
                </div>
                <div className="space-y-2 p-2">
                    <div>
                        <p className="text-xs line-clamp-1 text-themeInformation dark:text-themeDarkInformation">{title}</p>
                    </div>
                    <div>
                        <h2 className="text-xs dark:text-gray-300">{description}</h2>
                        <p className="font-light dark:text-gray-300">{message}</p>
                        <p className="font-light dark:text-gray-300">{type}</p>
                    </div>
                    <div className="flex justify-between items-center gap-2">
                        <span className="block text-xs dark:text-themeDarkInformation">
                            تاریخ یادآور {reminderDateUtc && <GridDateFomartter date={reminderDateUtc} hasTime={true} />}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
