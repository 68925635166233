import React, {useEffect, useRef, useState} from "react";
import {Notification} from "./Notification";
import {AccountMenu} from "./AccountMenu";

import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from 'react-redux';
import {Link, Route, useLocation, useNavigate} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import {FantasyModal} from "../UIParts/FantasyModal";
import Select from "react-select";
import {setSelectedTheme} from "../../../Services/Redux/SettingReducer";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";
import {useDynamicDispatch} from "../../../Services/Redux/useDynamicaDispatch";


// we have an topbar compoent which handling 2 other components , Notification and account menu
export const TopBar=({notes})=>{

    const { t } = useTranslation();
    const {handleDispatch,handleGetReduxDispatch} = useDynamicDispatch();
    const IsThemeSetting =  handleGetReduxDispatch('setting','selectedTheme')

    useEffect(()=>{
        const body = document.body
        const toggle = document.querySelector('.toggle-inner')
        if( IsThemeSetting === true ) {
            body.classList.add('dark')
            toggle.classList.add('toggle-active')
        } else {
            body.classList.remove('dark')
            toggle.classList.remove('toggle-active')
        }
    },[IsThemeSetting])




    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);




    const [modal, setModal] = useState({ HandleOpen: false });


    const HandleOpenOnHide = () => {
        setModal((prevState) => ({ ...prevState, HandleOpen: !modal.HandleOpen }));
    };

    // تابع renderBreadcrumb که عنوان صفحه و آیتم‌های breadcrumb را دریافت کرده و JSX مربوطه را ایجاد می‌کند
    const renderBreadcrumb = (pageTitle, items) => (
        <div className={"flex items-center"} alt={pageTitle}>
            {t(pageTitle)}
            {items.map((item, index) => (
                <React.Fragment key={index}>
                    <HPrimeIcon icon={"pi-angle-left"} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                    {t(item)}
                </React.Fragment>
            ))}
        </div>
    );

    const routes = {
        /*dashboard*/
        "/Dashboard": " ",
        /*dashboard*/


        /*dashboard2*/
        //اInventory
        "/Inventory/Type":renderBreadcrumb("InventoryManagement", ["InventoryTypes"]),
        "/Inventory/Add":renderBreadcrumb("InventoryManagement", ["CreateInventory"]),
        "/Inventory/Add/Communications":renderBreadcrumb("InventoryManagement", ["CreateInventory","communications"]),
        "/Inventory/ProductionSeries":renderBreadcrumb("InventoryManagement", ["ProductionSeries"]),
        "/Inventory/Goods/Add":renderBreadcrumb("InventoryManagement", ["CreateGoods"]),
        "/Inventory/Goods/ReturnRequest":renderBreadcrumb("InventoryManagement", ["ReturnPurchase"]),
        "/Inventory/TransferBetween":renderBreadcrumb("InventoryManagement", ["TransferBetweenInventory"]),
        "/Inventory/Goods/ExitRequest":renderBreadcrumb("InventoryManagement", ["ExitRequest"]),
        "/Inventory/EntryDocument":renderBreadcrumb("InventoryManagement", ["EntryDocument"]),
        "/Inventory/ExitDocument":renderBreadcrumb("InventoryManagement", ["ExitDocument"]),
        "/Inventory/ReturnDocument":renderBreadcrumb("InventoryManagement", ["ReturnDocument"]),
        "/Inventory/AdditionsDocument":renderBreadcrumb("InventoryManagement", ["AdditionsDocument"]),
        "/Inventory/List/Requests":renderBreadcrumb("InventoryManagement", ["ListOfRequests"]),
        "/Inventory/Report":renderBreadcrumb("InventoryManagement", ["InventoryStockReport"]),
        //اInventory

        //سیستم حضور غیاب

        "/Attendance/Personnel":renderBreadcrumb("AttendanceSystem", ["Personnel"]),
        "/Attendance/Personnel/Add":renderBreadcrumb("AttendanceSystem", ["UsersRegistration"]),
        "/Attendance/Personnel/Rules":renderBreadcrumb("AttendanceSystem", ["UsersRegistration","LegalProvisions"]),
        "/Attendance/Add":renderBreadcrumb("AttendanceSystem", ["RegisterDevice"]),
        "/Attendance/FinancialRules":renderBreadcrumb("AttendanceSystem", ["FinancialRules"]),
        "/Attendance/FinancialRules/Add":renderBreadcrumb("AttendanceSystem", ["FinancialRules","AddingFinancialRules"]),
        "/Attendance/FinancialRules/Personnel":renderBreadcrumb("AttendanceSystem", ["FinancialRules","DeterminingTheRules"]),
        "/Attendance/LeaveAndMission":renderBreadcrumb("AttendanceSystem", ["LeaveAndMission"]),
        "/Attendance/ShiftWork/List":renderBreadcrumb("AttendanceSystem", ["ShiftWork"]),
        "/Attendance/WorkGroup/List":renderBreadcrumb("AttendanceSystem", ["WorkGroup"]),
        "/Attendance/WorkGroup":renderBreadcrumb("AttendanceSystem", ["WorkGroup","AddWorkgroup"]),
        "/Attendance/Archive":renderBreadcrumb("AttendanceSystem", ["ArchiveLeaveAndMission"]),
        "/Attendance/List/IndividualReport":renderBreadcrumb("AttendanceSystem", ["IndividualReport"]),
        "/Attendance/List/MonthlyReport":renderBreadcrumb("AttendanceSystem", ["MonthlyReport"]),
        "/Attendance/List/Reports":renderBreadcrumb("AttendanceSystem", ["GeneralReport"]),

        //سیستم حضور غیاب



        /*dashboard2*/


        //امکانات کاربران
        "/UserPanel/Cartable":renderBreadcrumb("UsersFacilities", ["Cartable"]),
        "/UserPanel/Website/List":renderBreadcrumb("UsersFacilities", ["WebsiteList"]),
        "/UserPanel/FavoriteProduct":renderBreadcrumb("UsersFacilities", ["Favoriteproducts"]),
        "/UserPanel/Basket":renderBreadcrumb("UsersFacilities", ["Salesbasket"]),
        "/UserPanel/Edit":renderBreadcrumb("UsersFacilities", ["Profile"]),
        "/UserPanel/Wallet":renderBreadcrumb("UsersFacilities", ["IncreaseBalance"]),
        "/UserPanel/ShipmentTracking":renderBreadcrumb("UsersFacilities", ["ShipmentTracking"]),

        //امکانات کاربران


        //مدیریت کل
        "/Admin/Package/Add":renderBreadcrumb("generalManager",["CreateAPackage"]),
        "/Admin/Package/List/Role":renderBreadcrumb("generalManager",["CreateAPackage","AddRole"]),
        // renderBreadcrumb("", ["", ""]),
        // "/Admin/Package/Add/Role": "1",
        "/Admin/Server/Add":renderBreadcrumb("generalManager", ["CreateAServer"]),
        "/Admin/MasterTemplate/Add":renderBreadcrumb("generalManager", ["generalTemplates"]),
        "/Admin/MasterTemplate/Page/Add":renderBreadcrumb("generalTemplates", ["PageCreation"]),
        "/OnlineEditor": renderBreadcrumb("OnlineEditor", []),
        "/Admin/Website/Add": renderBreadcrumb("generalManager", ["websiteBuilder"]),
        "/Admin/Website/List":renderBreadcrumb("generalManager", ["WebsiteList"]),
        "/Admin/Website/List/Payment/Upgrade/Details":renderBreadcrumb("generalManager", ["WebsiteList","UpgradeHistory"] ),
        "/Admin/Website/List/Payment/Upgrade":renderBreadcrumb("generalManager", ["WebsiteList","Upgrade"]),
        "/Admin/Website/List/Template/Add":renderBreadcrumb("generalManager", ["WebsiteList","AllowedTemlpates"]),
        "/Admin/Website/List/Packages":renderBreadcrumb("generalManager", ["WebsiteList","SelectPackage"]),
        "/Admin/Website/List/Domain":renderBreadcrumb("WebsiteList", ["domains"]),
        "/Admin/Website/List/Server":renderBreadcrumb("WebsiteList", ["servers"]),
        "/Admin/PaymentGateways":renderBreadcrumb("generalManager", ["OnlineGateways"]),
        "/Admin/PaymentGateways/PaymentPort":renderBreadcrumb("generalManager", ["OnlineGateways","BankGateway"]),
        "/Admin/PaymentGateways/Add":renderBreadcrumb("generalManager", ["OnlineGateways", "CreateBankGateway"]),
        "/Admin/BaseTariff/Add": renderBreadcrumb("generalManager", ["BaseTariff"]),
        "/Admin/ExchangeRate/Add": renderBreadcrumb("generalManager", ["ExchangeRate"]),
        "/Admin/Cleaner": renderBreadcrumb("generalManager", ["DataCleanup"]),
        "/Admin/Backup":renderBreadcrumb("generalManager", ["BackupVersion"]),
        "/Admin/Add/BankAccount":renderBreadcrumb("generalManager", ["AddBankAccount"]),
        "/Admin/ProjectDocument":renderBreadcrumb("generalManager", ["ProjectDocument"]),
        "/Admin/ProjectDocument/List":renderBreadcrumb("generalManager", ["ProjectDocumentList"]),
        "/Admin/ProjectDocument/List/History":renderBreadcrumb("generalManager", ["ProjectDocumentList" ,"History"]),
        "/Admin/Guide":renderBreadcrumb("generalManager", ["Guide"]),
        "/Admin/Guide/List":renderBreadcrumb("generalManager", ["Guide","ListGuide"]),
        "/Admin/Settings":renderBreadcrumb("generalManager", ["generalManagerSettings"]),

        //مدیریت کل



        //مدیریت اشخاص
        "/CRM/User/Add":renderBreadcrumb("CRM", ["UsersRegistration"]),
        "/CRM/User/Group":renderBreadcrumb("CRM", ["CustomerGrouping"]),
        "/CRM/User/Role": renderBreadcrumb("CRM", ["CreatingRole"]),
        "/CRM/User/List":renderBreadcrumb("CRM", ["CustomerList"]),
        "/CRM/User/List/Address":renderBreadcrumb("CRM", ["Addresses"]),
        "/CRM/User/List/Log":renderBreadcrumb("CRM", ["activities"]),
        "/CRM/User/Role/Permission":renderBreadcrumb("CRM", ["Accesses"]),
        "/CRM/User/List/Access":renderBreadcrumb("CRM", ["Accesses"]),
        "/CRM/User/List/UploadDocument":renderBreadcrumb("CRM", ["DocumentsandRecords"]),
        "/CRM/User/List/UserInteractions":renderBreadcrumb("CRM", ["UserInteractions"]),
        "/CRM/User/Wallet":renderBreadcrumb("Wallet", []),
        "/CRM/User/WithdrawalRequest":t("WithdrawalRequest") ,
        "/CRM/User/Reminder/List": renderBreadcrumb("CRM", ["ReminderList"]),
        "/CRM/User/List/Reminder/Add": renderBreadcrumb("CRM", ["ReminderList", "Reminders"]),
        "/CRM/User/CustomField": renderBreadcrumb("CRM", ["communications"]),


        //مدیریت منو
        "/Public/MainMenu/List":renderBreadcrumb("MenuManagement", ["CreatingMenu"]),
        //مدیریت منو
        //CMS// //مدیریت محتوا

        "/Public/MainMenu/List/Add": renderBreadcrumb("MenuManagement", ["CreatingMenu", "SubMenu"]),
        "/CMS/Content/Add": renderBreadcrumb("CMSManagement", ["InsertingInformation"]),
        "/CMS/Content/Group": renderBreadcrumb("CMSManagement", ["grouping"]),
        "/CMS/Content/Group/Detail": renderBreadcrumb("CMSManagement", ["grouping" ,"details"]),
        "/CMS/MetaData/Add": renderBreadcrumb("CMSManagement", ["CreatingAFeature"]),
        "/CMS/MetaData/Add/AddProperty": renderBreadcrumb("CMSManagement", ["CreatingAFeature", "SetValue"]),
        "/CMS/Content/List":renderBreadcrumb("CMSManagement", ["ListOfInformation"]),
        "/CMS/Content/List/AddProperty":renderBreadcrumb("CMSManagement", ["ListOfInformation", "SetValue"]),
        "/CMS/Comment/Inbox":renderBreadcrumb("UserComments", ["receivingBox", ""]),
        "/CMS/Comment/Confirmed":renderBreadcrumb("UserComments", ["ApprovedComments",]),
        "/CMS/Comment/NoConfirmed":renderBreadcrumb("UserComments", ["UnapprovedComments"]),
        "/CMS/Comment/Trash": renderBreadcrumb("UserComments", ["trash"]),
        "/CMS/Template/Add":renderBreadcrumb("Template", ["MyTemplates"]),
        "/CMS/Template/Choose":renderBreadcrumb("Template", ["BuyATemplate"]),
        "/CMS/Template/Add/page":renderBreadcrumb("Template", ["PageCreation"]),
        "/CMS/Template/ShortCode":renderBreadcrumb("Template", ["CreatingAShortCode"]),
        "/CMS/Template/MetaTagextra":renderBreadcrumb("Template", ["Highlighter"]),
        "/CMS/Surveys/Add":renderBreadcrumb("Survey", ["CreatingASurvey"]),
        "/CMS/Surveys/List":renderBreadcrumb("Survey", ["ListOfSurveys"]),
        "/CMS/Surveys/List/DataSource":renderBreadcrumb("Survey", ["ListOfSurveys", "AnswerToTheSurvey"]),
        "/CMS/Surveys/List/Result":renderBreadcrumb("Survey", ["ListOfSurveys", "ViewStatistics"]),
        "/CMS/Surveys/List/ResultDetails":renderBreadcrumb("CMSManagement", ["DetailedComments"]),
        "/CMS/Advertise/Add":renderBreadcrumb("Advertisement", ["NewAdvertisements"]),
        "/CMS/Advertise/List":renderBreadcrumb("Advertisement", ["ListAdvertisements"]),

        //CMS// //مدیریت محتوا


        //مدیریت فرم
        "/Form/Create":renderBreadcrumb("FormBuilder", ["CreatingForm"]),
        "/Form/Process":renderBreadcrumb("FormBuilder", ["FormWorkflow"]),
        "/Form/Process/RodMap": renderBreadcrumb("1", []),
        "/Form/Organize":renderBreadcrumb("FormBuilder", ["OrganizeForms"]),
        "/Form/DataSource":renderBreadcrumb("FormBuilder", ["DataSource"]),
        "/Form/DataSource/List":renderBreadcrumb("FormBuilder", ["ListSourcesInformation", ""]),
        "/Form/List/DataEntrance":renderBreadcrumb("FormBuilder", ["CreatingForm","DataEntry"]),
        "/Form/List/Information": renderBreadcrumb("1", []),
        "/Form/Report/Builder":renderBreadcrumb("FormBuilder", ["ReportBuilder"]),

        //مدیریت فرم

        //shop
        "/Shop/Product/Add":renderBreadcrumb("StoreManagement", ["NewProduct"]),
        "/Shop/MetaData/Add":renderBreadcrumb("StoreManagement", ["CreatingAFeature"]),
        "/Shop/MetaData/Add/AddProperty":renderBreadcrumb("StoreManagement", ["CreatingAFeature","SetValue"]),
        "/Shop/Product/Group":renderBreadcrumb("", ["ProductCategorization"]),
        "/Shop/Product/List":renderBreadcrumb("StoreManagement", ["ListOfProducts"]),
        "/Shop/Product/List/ProductMeta":renderBreadcrumb("StoreManagement", ["ListOfProducts","CreatingAFeature"]),
        "/Shop/Product/Type":renderBreadcrumb("StoreManagement", ["ListOfProducts","SizeAndDimensions"]),
        "/Shop/Product/List/Chart":renderBreadcrumb("StoreManagement", ["ListOfProducts","Chart"]),
        "/Shop/Product/Cart":renderBreadcrumb("StoreManagement", ["ShoppingCart"]),
        "/Shop/Product/Cart/Items":renderBreadcrumb("StoreManagement", ["ShoppingCart","InvoiceDetail"]),
        "/Shop/Product/PriceChange":renderBreadcrumb("StoreManagement", ["QuickPriceChange"]),
        "/Shop/PackageTracking":renderBreadcrumb("StoreManagement", ["PackageTracking"]),
        //shop





        // Guarantee
        "/Guarantee/Add":renderBreadcrumb("Guarantee", ["NewGuaranteeCode"]),
        "/Guarantee/Customer":renderBreadcrumb("Guarantee", ["CustomersGuarantee"]),
        "/Guarantee/Customer/History":renderBreadcrumb("Guarantee", ["CustomersGuarantee","History"]),

        // Guarantee

        // Discount $ Commission
        "/Discount/Add":renderBreadcrumb("DiscountsAndPrizes", ["CreatingDiscountCode"]),
        "/Discount":renderBreadcrumb("DiscountsAndPrizes", ["DiscountCampaign"]),

        "/Commission":renderBreadcrumb("Commission", ["DeterminingCommission"]),
        "/Commission/IndividualClaims":renderBreadcrumb("Commission", ["IndividualClaims"]),
        // Discount $ Commission

        // DigitalMarketing


        "/SocialMarketing/Messengers/List":renderBreadcrumb("SocialMarketing", ["AddUserAccount"]),
        "/SocialMarketing/Messengers/List/ConnectAccounts":renderBreadcrumb("SocialMarketing", ["ConnectAccounts"]),
        "/SocialMarketing/Messengers/List/Page":renderBreadcrumb("SocialMarketing", ["AccountSelection"]),
        "/SocialMarketing/Messengers/PublicationList":renderBreadcrumb("SocialMarketing", ["PublicationList"]),
        "/SocialMarketing/Messengers/PublishingContent":renderBreadcrumb("SocialMarketing", ["PublishingContent"]),
        "/SocialMarketing/Messengers/Comments":renderBreadcrumb("SocialMarketing", ["comments"]),
        "/SocialMarketing/Draft":renderBreadcrumb("SocialMarketing", ["Draft"]),

        // DigitalMarketing

        // EmailMarketing

        "/EmailMarketing/Email/Add":renderBreadcrumb("EmailMarketing", ["AddEmail"]),
        "/EmailMarketing/Compose":renderBreadcrumb("EmailMarketing", ["SendEmail"]),
        "/EmailMarketing/Draft":renderBreadcrumb("EmailMarketing", ["Draft"]),
        "/EmailMarketing/Inbox":renderBreadcrumb("EmailMarketing", ["receivingBox"]),
        "/EmailMarketing/SendBox":renderBreadcrumb("EmailMarketing", ["sendingBox"]),
        "/EmailMarketing/Trash":renderBreadcrumb("EmailMarketing", ["trash"]),

        // EmailMarketing


        //SmsMangment
        "/SmsManagment/AddSmsNumber":renderBreadcrumb("SMS", ["addingATextMessageLine"]),
        "/SmsManagment/Send":renderBreadcrumb("SMS", ["sendingMessage"]),
        "/SmsManagment/Inbox":renderBreadcrumb("SMS", ["receivingBox"]),
        "/SmsManagment/SendBoxSms":renderBreadcrumb("SMS", ["sendingBox"]),
        "/SmsManagment/SmsSecretery":renderBreadcrumb("SMS", ["smsSecretary"]),
        "/SmsManagment/SmsPattern":renderBreadcrumb("SMS", ["creatingATemplate"]),
        "/SmsManagment/FrequentlyNumbers":renderBreadcrumb("SMS", ["FrequentlyNumbers"]),

        //SmsMangment

        //SEO
        "/SEO/KeyWords":renderBreadcrumb("SEO", ["SelectedKeywords"]),
        "/SEO/ReviewKeyWords":renderBreadcrumb("SEO", ["ReviewWordsCompetitors"]),
        "/SEO/CreationMap":renderBreadcrumb("SEO", ["CreateContentCreationMap"]),
        "/SEO/GoogleSiteMap":renderBreadcrumb("SEO", ["introductionToGoogle"]),
        "/SEO/Redirect/Add":renderBreadcrumb("SEO", ["redirect"]),
        "/SEO/MonthlyReport":renderBreadcrumb("SEO", ["MonthlyReport"]),
        "/SEO/MetaTagextra":renderBreadcrumb("SEO", ["MetaTagSchema"]),
        //SEO

        //OnlineConversation
        "/Support/Ticket":renderBreadcrumb("support", ["Tickets"]),
        "/Support/Ticket/List":renderBreadcrumb("support", ["TicketList"]),
        "/Support/OnlineSupport": t("onlineChat") ,
        "/Support/OnlineTicket": renderBreadcrumb("support", ["onlineChat"]),
        "/Support/Department":renderBreadcrumb("support", ["Department"]),
        "/Support/Department/User":renderBreadcrumb("support", ["Department","Accesses"]),
        "/Support/Ticket/Conversation":renderBreadcrumb("support", ["Tickets","onlineChat"]),
        //OnlineConversation


        //Statistics
        "/Statistics":renderBreadcrumb("statisticsAndVisits", []),
        //Statistics

        // Transaction
        "/Bank/Transaction/list": renderBreadcrumb("ListOfTransactions", []),

        // Transaction

        // FileManager
        "/Authentication/FileManager":renderBreadcrumb("FileManagement", []),
        // FileManager


        // settings
        "/Harmony/Master/setting": t("settings") ,

        /*Public*/
        "/Harmony/Setting/Main/Dashboard":renderBreadcrumb("settings", ["Public","Personal"]),
        "/Harmony/Setting/Main/General":renderBreadcrumb("settings", ["Public","Public"]),
        "/Harmony/Setting/Main/Pictures":renderBreadcrumb("settings", ["Public","Images"]),
        "/Harmony/Setting/Main/Routing":renderBreadcrumb("settings", ["Public","Routing"]),
        "/Harmony/Setting/Main/CleanTemp":renderBreadcrumb("settings", ["Public","TemporaryDataCleanup"]),

        /*Public*/


        /*professional*/
        "/Harmony/Setting/Main/Domain":renderBreadcrumb("settings", ["Professional","Domain"]),
        "/Harmony/Setting/Main/Script":renderBreadcrumb("settings", ["Professional","AdditionalScript"]),
        "/Harmony/Setting/Main/ShortcutKey":renderBreadcrumb("settings", ["Professional","ShortcutKey"]),
        "/Harmony/Setting/Main/Security":renderBreadcrumb("settings", ["Professional","SecurityAndPrivacy"]),
        "/Harmony/Setting/Main/BackUp":renderBreadcrumb("settings", ["Professional","SupportAndRecovery"]),
        /*professional*/

        /*shop*/
        "/Harmony/Setting/Shop/General":renderBreadcrumb("settings", ["shop","Public"]),
        "/Harmony/Setting/Shop/ApiPost":renderBreadcrumb("settings", ["shop","ShippingMethods"]),
        "/Harmony/Setting/Shop/PaymentPort":renderBreadcrumb("settings", ["shop","BankGateway"]),
        /*shop*/

        "/Harmony/Setting/Main/SmartNotification":renderBreadcrumb("settings", ["SmartNotification"]),


        "/Harmony/Setting/Main/RemoteManagement":renderBreadcrumb("settings", ["Remotemanagement"]),


        "/ServiceUpgrade":renderBreadcrumb("Upgrade", []),
        "/InvoiceRequest":renderBreadcrumb("Bill", []),

        "/Harmony/Setting/Attendance":renderBreadcrumb("settings", ["AttendanceSystem","Basicsettings"]),

    };




    const location = useLocation();
    // const currentUrl = location.pathname;

    // const location = useLocation();
    const currentPage = routes[location.pathname] || t("Undefined"); // اگر مسیر معتبر نباشد، نامشخص را نمایش دهید

    // console.log(currentUrl);
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleWatchWebsite=()=>{

    }
    const redirectTo = useNavigate();


    return (
        <>
            <div className="top-bar md:border-b flex min-h-12 flex-col md:flex-row justify-between items-center ">
                <div className="flex dark:text-gray-300 items-center w-full overflow-y-auto order-2 md:order-none py-2">
                    <Link to="/Dashboard" className="text-themeInformation dark:text-themeDarkInformation ">{t("Dashboard")}</Link>
                    <HPrimeIcon icon={"pi-angle-left"} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                    <div className={"text-themeInformation dark:text-themeDarkInformation text-nowrap text-xs md:text-sm"}>
                        {currentPage}
                    </div>
                </div>
                <div className="flex gap-3 border-b py-2 dark:border-themeDarkColorLine md:border-none items-center justify-end w-full">
                    <button onClick={()=>handleWatchWebsite()} className={"hidden hbtn-website tooltip_class_site md:flex place-content-center"}>
                        <HPrimeIcon icon={"pi-microsoft"} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                        <Tooltip anchorSelect={".tooltip_class_site"} content={t("Viewthesite")} place={"right-start"} />
                    </button>
                    <div className="flex gap-3 items-center">
                        <button className={"md:hidden "} onClick={() => setModal({ HandleOpen: true })}>
                            <HPrimeIcon icon={"pi-sliders-h"} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                        </button>
                        <div>
                            <Notification
                                notes={notes}
                            />
                        </div>
                        <div>
                            <button type={'button'} className={"flex place-content-center hbtn-generalSetting"} onClick={()=>redirectTo('/Harmony/Master/setting')}>
                                <HPrimeIcon icon={"pi-cog"} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                            </button>
                        </div>
                        {/*dark_mood*/}
                        <div>
                            <div id="toggle" className="relative" onClick={()=>handleDispatch('setSelectedTheme',!IsThemeSetting)} >
                                <div className="toggle-inner"/>
                                <i className="sun"></i>
                                <i className="moon"></i>
                            </div>
                        </div>
                        {/*dark_mood*/}

                        <AccountMenu/>
                    </div>
                </div>
            </div>
            <FantasyModal openModal={modal.HandleOpen} closeModal={HandleOpenOnHide} title={t("CreateFilter")} legend_Class_Name={"hidden"} >
                <div className="space-y-3">
                    <div className="flex modal_fast_button ">
                        <div className={"grid place-content-center p-2 w-12 bg-themeInformation dark:bg-themeDarkInformation rounded-s-md"}>
                            <HPrimeIcon
                            iconClass={"text-white"}
                            icon={"pi-microsoft"}
                            />
                        </div>
                        <div className={"flex-1 input_rounded-s"}>
                            <Select
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                    </div>
                    <Link to="/ServiceUpgrade" className="flex modal_fast_button">
                        <div className={"grid place-content-center p-2 w-12 bg-themeInformation dark:bg-themeDarkInformation rounded-s-md"}>
                            <HPrimeIcon icon={"pi-sparkles"} iconClass={"text-white"}/>
                        </div>
                        <div className={"flex-1 p-2 bg-white dark:bg-themeDarkInputBackground dark:text-themeDarkInputColor rounded-e-md flex items-center"}>
                            {t("ServicesUpgrades")}
                        </div>
                    </Link>
                    <Link to="/InvoiceRequest" className="flex modal_fast_button">
                        <div className={"grid place-content-center p-2 w-12 bg-themeInformation dark:bg-themeDarkInformation rounded-s-md"}>
                            <HPrimeIcon icon={"pi-file-edit"} iconClass={"text-white"}/>
                        </div>
                        <div className={"flex-1 p-2 bg-white dark:bg-themeDarkInputBackground dark:text-gray-300 rounded-e-md flex items-center"}>
                            <h2 className={"dark:text-themeDarkInputColor text-xs"}>{t("Bill")}</h2>
                        </div>
                    </Link>
                    <Link to="#" className="flex modal_fast_button">
                        <div className={"grid place-content-center p-2 w-12 bg-themeInformation dark:bg-themeDarkInformation rounded-s-md"}>
                            <HPrimeIcon icon={"pi-code"} iconClass={"text-white"}/>
                        </div>
                        <div className={"flex-1 p-2 bg-white dark:bg-themeDarkInputBackground rounded-e-md flex items-center"}>
                            <h2 className={"dark:text-themeDarkInputColor text-xs"}>{t("ShortcutKey")}</h2>
                        </div>
                    </Link>
                    <Link to="#" className="bg-themeInformation dark:bg-themeDarkInformation block button text-white">
                        {t("OtherSettings")}
                    </Link>

                </div>
            </FantasyModal>
        </>
    )
}