import React, {useState} from "react";

export const useCustomPayment=(setErrors)=>{
    const [payment,setPayment] = useState({amount:0,totalAmount:0,currencyType:0,pspAccountId:null,bankAccountId:null,paymentId:null,walletAmount:0,discountCode:null,bankId:null,userId:null,walletBalance:0,walletCurrency:0,billDate:null,recieptCode:null,description:null,restrications:[]});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setPayment({...payment,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleNumberValue = (value,name)=>{
        if(name === 'amount'){
            setPayment({...payment,walletAmount: payment.totalAmount - value,[name]:value});
        }
        else if( name === 'walletAmount'){
            setPayment({...payment,amount: payment.totalAmount - value,[name]:value});
        }
        else {
            setPayment({...payment,[name]:value});
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }
    }
    const handleSelectValue=(data,name)=>{
        setPayment({...payment,[name]:data});
    }
    const handleBillDate=(dateTime)=>{
        setPayment({...payment,billDate:dateTime});
    }
    return {
        handleValue,
        handleNumberValue,
        handleSelectValue,
        setPayment,
        payment,
        handleBillDate
    }

}