export const convertImportantEventsHandler = (obj,id,concurrencyStamp)=>{
    const newData = {
        id:id,
        concurrencyStamp:concurrencyStamp,
        importantOccasions: obj.map(item => ({
            type:item.type !== null? item.type.value:null,
            eventDate: item.eventDate,
            caption: item.caption,
            description: item.description,
            reminderOffsetUnit:item.reminderOffsetUnit !== null?item.reminderOffsetUnit.value:null,
            reminderOffsetValue:item.reminderOffsetValue,
            hasReminder:item.hasReminder,
            userOccasionId:item.userOccasionId,
        }))
    };
    return newData
}

