import React, {useState} from "react";
import {validate} from "../../../../../Services/Globals/validate";
import {passwordSchema} from "./passwordSchema";
import {decodeAccessToken} from "../../../../../Services/AxiosService/JwtService";
import {PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {DisplayError} from "../../../../Commans/Errors/DisplayError";
import {ToastContainer} from "react-toastify";

import {useTranslation} from "react-i18next";
import {RequiredIcon} from "../../../../Commans/Errors/RequiredIcon";

export const PasswordChanger=()=>{
    const { t } = useTranslation();

    const [security,setSecurity] = useState({id:null,concurrencyStamp:null,currentPassword:null,newPassword:null,confirmPassword:null});
    const [errors, setErrors] = useState({});
    const[spinner,setSpinner] = useState(false);
    const handleValue=(e)=> {
        const {name, value} = e.target;
        setSecurity({...security, [name]: value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const onApiError = () => {
        setSpinner(false);
    };
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }


    const handleSubmitPassword=async (e)=>{
        e.preventDefault();
        const isValid = validate(passwordSchema,security,onSetErrors);
        if(!isValid){
            return;
        }
        try {
            setSpinner(true);
            const token = decodeAccessToken();
            const {id,concurrencyStamp} = token;
            const considerations = {id:token['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'],concurrencyStamp:concurrencyStamp,currentPassword:security.currentPassword,newPassword:security.newPassword};

            const response = await PUT(`/Users/EditUserPassword`,considerations,false);
            if(response.status === 200){
                setSpinner(false);

                handleSuccessMessage(t("Passwordchangesuccessful"));
            }
            else{
                handleApiError(response,onApiError);
            }

        }
        catch (e){
            console.log(e);
        }

    }


    return (<>

        <div className={"relative"}>

            {
                spinner?
                    <div className="huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div className={"space-y-3"}>
                <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                    <p className={"dark:text-gray-300 text-xs"}>{t("ChangePassword")}</p>
                </div>
                <div className={"px-2 space-y-5 grid grid-cols-1 md:grid-cols-2"}>
                    <form onSubmit={handleSubmitPassword} method={'post'}>
                        <div class="grid grid-cols-1 gap-3 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                            <div class=" space-y-2">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">
                                        {t("OldPassword")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.currentPassword}/>
                                </div>
                                <input type="password" onInput={(e)=>handleValue(e)} value={security.currentPassword} name={'currentPassword'}  className="input w-full "/>
                            </div>
                            <div class=" space-y-2">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">
                                        {t("NewPassword")}
                                        <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.newPassword}/>
                                </div>
                                <div class="relative ">
                                    <input type="password" name={'newPassword'} value={security.newPassword} onInput={(e)=>handleValue(e)}  className="input w-full "/>
                                </div>
                            </div>
                            <div class=" space-y-2">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">
                                        {t("ConfirmPassword")}
                                        <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.confirmPassword}/>
                                </div>
                                <div>
                                    <input type="password" name={'confirmPassword'} value={security.confirmPassword} onInput={(e)=>handleValue(e)}  className="input w-full "/>
                                </div>
                            </div>
                            <div>
                                <button type={'submit'} className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("ChangePassword")}</button>
                            </div>
                        </div>
                    </form>
                    <div>
                        <div className="card grid place-content-center">
                            <img src="/Assets/Image/bg-img/Securepassword.svg" alt="backup" className={"w-full h-full object-contain"}/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <ToastContainer/>

    </>)
}