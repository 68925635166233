import React, {useState} from "react";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

export const useCustomParticipation=()=>{

    // surveys which we load in it
    const [survey,setSurvey] = useState({surveys: []});
    // filters  all surveys based on type in render time of component
    const handleFilterSurveys = (surveys, surveyTypes) => {
        return surveys.map((survey) => {
            // For other types, keep the original mapping logic
            const mappedSurvey = {
                id: survey.id === null || survey.id === undefined ? null : survey.id,
                question: survey.question,
                type: surveyTypes.find((item) => item.value === survey.type),
                row: survey.row,
                surveyItems: survey.surveyItems.map((surveyItem) => ({
                    id:
                        surveyItem.id === null || surveyItem.id === undefined
                            ? null
                            : surveyItem.id,
                    surveyItemName:
                        surveyItem.surveyItemName === null ||
                        surveyItem.surveyItemName === undefined ||
                        surveyItem.surveyItemName === ''
                            ? null
                            : surveyItem.surveyItemName,
                    surveyItemValue:
                        surveyItem.surveyItemValue === null ||
                        surveyItem.surveyItemValue === undefined ||
                        surveyItem.surveyItemName === ''
                            ? null
                            : surveyItem.surveyItemValue.toString(),
                    linkedSurveyRaw:
                        surveyItem.linkedSurveyRaw === null ||
                        surveyItem.linkedSurveyRaw === undefined ||
                        surveyItem.surveyItemName === ''
                            ? null
                            : surveyItem.linkedSurveyRaw,
                    row: surveyItem.row,
                })),
            };

            return mappedSurvey;
        });
    };

    // request model which poll creator is creating to send server its all data
    const [surveyQuestion, setSurveyQuestions] = useState({
        surveyGroupId: null,
        surveys: [], // Array to store surveys and their surveyItems
    });

    // this one changes surveyQuestions and categorizes different answers based on their index as a separate survey
    const handleAddSurveyQuestion = (data, index) => {
        setSurveyQuestions((prevQuestions) => {
            const updatedSurveys = [...prevQuestions.surveys];

            // Check if the survey at the specified index exists, if not initialize it
            if (!updatedSurveys[index]) {
                updatedSurveys[index] = { surveyResults: [] };
            }

            // Push the data to the surveyResults array at the specified index
            updatedSurveys[index].surveyResults.push(data);

            return {
                ...prevQuestions,
                surveys: updatedSurveys,
            };
        });
    };


    //This trasnforms data object of categorized surveys in to a standard api model
    // in that categorized object rapid changes are stored and here we get accessed to last change of object to map it in to api model
    const ConvertSurveyQuestions=(originalObject)=>{
        const transformedObject = {
            surveyGroupId: originalObject.surveyGroupId,
            SurveyResults: originalObject.surveys.map((survey) => {
                // Get the last object from surveyResults array
                const lastSurveyResult = survey.surveyResults[survey.surveyResults.length - 1];
                return {
                    surveyItemId: lastSurveyResult.surveyItemId,
                    surveyId: lastSurveyResult.surveyId,
                    textSurvey: lastSurveyResult.textSurvey,
                };
            }),
        };
        return transformedObject;
    }






    //this is used for next Surveys ..
    const [activeSurveyIndex, setActiveSurveyIndex] = useState(0);
    const handleNextButtonClick = () => {
        if (activeSurveyIndex < survey.surveys.length - 1) {
            setActiveSurveyIndex((prevIndex) => prevIndex + 1);
        }
    };
    const handlePreviousButtonClick = () => {
        if (activeSurveyIndex > 0) {
            setActiveSurveyIndex((prevIndex) => prevIndex - 1);
        }
    };





    return {
       handleFilterSurveys,
       setSurvey,
       survey,
       handleAddSurveyQuestion,
       surveyQuestion,
        handleNextButtonClick,
        activeSurveyIndex,
        ConvertSurveyQuestions,
        handlePreviousButtonClick

   }
}