import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {Link, useNavigate} from "react-router-dom";
import ButtonVideo from "../../../Commans/UIParts/ButtonVideo";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import Select from "react-select";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {Export} from "../../../Commans/Grid/Export";
import {useDocumentHistoryGrid} from "./useDocumentHistoryGrid";

export const HandleHistoryProjectDocument = () => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [IsShowSpinner,setIsShowSpinner] = useState(false);
  const [refresh,setRefresh] = useState(0);
  const [visible, setVisible] = useState(false);
  const [initialSource,setInitialSource]=useState({documentGroups:[]});
  const showSpinner=()=>{
    setIsShowSpinner(true);
  }
  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }
  const Refresh=()=>{
    setRefresh(prevState => !prevState)
  }

  const handleInitialData=(documentGroups)=>{
    const docGroups = convertSelectDataHandler(documentGroups);
    setInitialSource({
      documentGroups: docGroups
    })
    return docGroups
  }
  const fetchData=async ()=>{
    try {
      showSpinner();
      const responseGroup = await GET(`HarmonyDocumentGroups`,{},'IEnumerable');
      if(responseGroup.status === 200){
        handleInitialData(responseGroup.data);
      }
      else{
        handleApiError(responseGroup,hideSpinner);
      }
    }
    catch (e) {
      hideSpinner();
    }
  }

  useEffect(()=>{
    fetchData();
  },[])
  const {filter,handleCurrentPage,handleNextPage,handleSearch,search,setFilter,setSearch,handlePreviousPage,handlePageSizeCount,handleSelectFilter,handleResetFilters,handleToggleFilter,SwitchFilters,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination} = useDocumentHistoryGrid(showSpinner,hideSpinner,refresh,Refresh,initialSource)

  return(
      <>
        <div class="space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 ">
            <div className="space-y-5">
              <ListSearch label={null} icon={null} >
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                  <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                </div>
                <div className="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                  <Select
                      options={initialSource.documentGroups}
                      isSearchable={true}
                      onChange={(data)=>handleSelectFilter(data,'harmonyDocumentGroupId')}
                      placeholder={t("selection")}
                      value={filter.harmonyDocumentGroupIdValue}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <ButtonSearchList
                    onClickSearch={()=>handleSearch()}
                    onClickReset={()=>handleResetFilters()}
                />
              </ListSearch>
              <ListFilter label={null} icon={null}
                          childrenOne={
                            <>
                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Admin/ProjectDocument`)}
                                  functionClass={"creation"}
                                  tooltipTitle={t("UsersRegistration")}
                              />

                            </>
                          }
                          childrenTwo={
                            <>
                              <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                            </>
                          }
              />

            </div>
          </div>
          <div className="H-body-ag-grid space-y-2 relative">
            {
              IsShowSpinner?
                  <div className=" huploader_loading" >
                    <div className="relative">
                      <LoadingHarmony/>
                    </div>
                  </div>
                  :
                  null
            }

            <GridTable
                columnDefs={columnDefs}
                rowSelection={GridSetting.rowSelection}
                rowData={rowData}
                style={GridSetting.gridStyle}
                defaultColDef={defaultColDef}
                autoGroupColumnDef={autoGroupColumnDef}
                pagination={GridSetting.pagination}
                enableRtl={GridSetting.enableRtl}
                domLayout={GridSetting.domLayout}
                paginationPageSize={GridSetting.pageSize}
                getRowHeight={getRowHeight}
                localeText={AgGridEnglish}
            />
            <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
          </div>
          <Export apiUrl={''} filters={filter}/>

        </div>
      </>
  )
}