import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {Button} from "primereact/button";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {GridTable} from "../Commans/Grid/GridTable";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {Image} from "primereact/image";
import {GridButtonOprations} from "../Commans/Grid/GridButtonOprations";
import {ModalButtonOperations} from "../Commans/UIParts/ModalButtonOperations";

export const HandleInventoryReport = () => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [isMenuOpen,setMenuOpen] = useState(false);
  const [userId,setUserId] = useState('');
  const handleOpenMenu = () => {
    setMenuOpen(true);

  };
  const handleCloseMenu = ()=>{
    setMenuOpen(false);
  }


  const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

  const columnDefs = [
    { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

    { headerName: t("ProductImage"), minWidth: 160,
      cellRenderer:parameters=>
          <>
            <Image src={""}  alt="Image" />

          </>
    },

    { headerName: t("ProductName"),minWidth: 160,
      cellRenderer:parameters=>
          <>
            شلوار جین
          </>
    },
    { headerName: t("ProductBarcode"), minWidth: 150,
      cellRenderer:parameters=>
          <>
            12121212121
          </>
    },

    { headerName: t("IranCode"), minWidth: 170,
      cellRenderer:parameters=>
          <>
            12131311323123132131
          </>
    },
    { headerName: t("QrCode"), minWidth: 150,
      cellRenderer:parameters=>
          <>
          12121
          </>
    },


    { headerName: t("Remaining"), minWidth: 130,
      cellRenderer:parameters=>
          <>200</>

    },
    { headerName: t("MinimumInventory"), minWidth: 120,
      cellRenderer:parameters=>
          <>10</>
    },
    { headerName: t("MaximumInventory"), minWidth: 130,
      cellRenderer:parameters=>
          <>2000</>
    },

    { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
      cellRenderer:params => {
        return<div className={'flex justify-center items-center gap-3 '} >
          <GridButtonOprations
              onClick={()=>handleOpenMenu()}
          />
        </div>
      }

    },

  ];

  const rowData = [

    { id:1, orderReciever: 'Camry',date:'1402/10/3',code:'84732085', Totalprice: 25000,comment:'راضی ام'},

  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      filter: true,
      sortable: true,
      wrapText: true,
      resizable: true,
      // resizable: true,
      // editable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const onSelectionChanged=(data)=>{
    console.log(data);
  }
  const onCellValueChanged = (data)=>{
    console.log(data);
  }


  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };



  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = (event) => {
    setQuickFilterText(event.target.value);
  };





  return(
      <>
        <div class="space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 ">
            <div className="space-y-5">
              <ListSearch label={null} icon={null} >
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("SearchByNameProductCode")}</label>
                  <InputText type={"text"} className={" input w-full"} />
                </div>
                <div className="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("InventoryType")}</label>
                  <Select
                      placeholder={t("selection")}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}

                  />
                </div>
                <ButtonSearchList/>
              </ListSearch>
              <ListFilter label={null} icon={null}
                          childrenOne={
                            <>
                              <ButtonPlus
                                  onClick={() => redirectTo("/Inventory/Add")}
                                  title={t("Inventory")}
                                  tooltipTitle={t("CreateInventory")}

                              />
                              <ButtonPlus
                                  onClick={() => redirectTo("/Inventory/Goods/Add")}
                                  title={t("GoodsRegistration")}
                                  tooltipTitle={t("GoodsRegistration")}
                                  icon={"edit"}
                              />




                            </>
                          }
                          childrenTwo={
                            <>
                              <div class="space-y-2 text-center">
                                <lable className="text-xs dark:text-gray-300">{t("SixMonthsToExpiry")}</lable>
                                <div>
                                  <input className="input input--switch " type="checkbox"/>
                                </div>
                              </div>
                              <div class=" space-y-2 text-center">
                                <lable className="text-xs dark:text-gray-300">{t("Available")}</lable>
                                <div>
                                  <input className="input input--switch " type="checkbox"/>
                                </div>
                              </div>
                              <div class="space-y-2 text-center">
                                <lable className="text-xs dark:text-gray-300">{t("Unavailable")}</lable>
                                <div>
                                  <input className="input input--switch " type="checkbox"/>
                                </div>

                              </div>
                              <div class=" space-y-2 text-center">
                                <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                <div>
                                  <input className="input input--switch " type="checkbox"/>
                                </div>
                              </div>
                              <div class=" space-y-2 text-center">
                                <lable className="text-xs dark:text-gray-300">{t("PurchaseAlert")}</lable>
                                <div>
                                  <input className="input input--switch " type="checkbox"/>
                                </div>
                              </div>
                              <div class=" space-y-2 text-center">
                                <lable className="text-xs dark:text-gray-300">{t("EndInventory")}</lable>
                                <div>
                                  <input className="input input--switch " type="checkbox"/>
                                </div>
                              </div>
                            </>
                          }
              />

            </div>
          </div>
          <div>
            <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                       enableRtl={true}
                       pagination={true}
                       getRowHeight={getRowHeight}
                       onGridReady={onGridReady}
                       quickFilterText={quickFilterText}
                       style={{ width: '100%', height: '100%' ,position:'relative' }}
            />
          </div>
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
              <div className="sm:col-span-2">
                <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                  <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Pdf"></span>
                    <h2>خروجی (Pdf)</h2>
                  </Button>
                  <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Excel"></span>
                    <h2>خروجی (Exel)</h2>
                  </Button>
                  <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Word"></span>
                    <h2>خروجی (Word)</h2>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
              <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
                <ModalButtonOperations
                icon={"pi-print"}
                title={"پرینت"}
                />

              </div>
        </FantasyModal>
      </>
)
}