import React, {useEffect, useState} from "react";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE} from "../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";

export const useSurvey=(options,setResult,onApiErrors)=>{

    const {t} = useTranslation();

    const [survey,setSurvey] = useState({
        surveys: [
            // {
            //     id: null,
            //     question: null,
            //     type: null,
            //     row: 0,
            //     fakeId: new Date().getTime(),
            //     surveyItems: [
            //         {
            //             fakeId: new Date().getTime(),
            //             id: null,
            //             surveyItemName: null,
            //             surveyItemValue: null,
            //             linkedSurveyRaw: 0,
            //             row: 0,
            //         },
            //     ],
            // },
        ],
    });


    const handleShowEmoji = (realSurveyId, surveyFakeId, surveyItemFakeId, surveyItemId, field, value) => {
        setSurvey((prevSurvey) => {
            const updatedSurveys = prevSurvey.surveys.map((survey) => {
                const surveyIdToCheck = realSurveyId !== null ? realSurveyId : surveyFakeId;
                if (
                    (realSurveyId !== null && survey.id === surveyIdToCheck) ||
                    (realSurveyId === null && survey.fakeId === surveyIdToCheck)
                ) {
                    const updatedSurveyItems = survey.surveyItems.map((item) => {
                        // Use item.fakeId if surveyItemId is null or undefined
                        const itemIdToCheck = surveyItemId !== null ? surveyItemId : item.fakeId;

                        if (
                            (surveyItemId !== null && item.id === itemIdToCheck) ||
                            (surveyItemId === null && item.fakeId === surveyItemFakeId)
                        ) {
                            return { ...item, [field]: !value };
                        } else {
                            return item; // Keep other rows unchanged
                        }
                    });

                    return {
                        ...survey,
                        surveyItems: updatedSurveyItems,
                    };
                } else {
                    return survey; // Keep other rows unchanged
                }
            });

            return { surveys: updatedSurveys };
        });
    };
    const handleAddSurvey = (index) => {
        const newRow = {
            fakeId: new Date().getTime(),
            type: options.surveyTypes[0], // Set your default value
            question: null,
            row: index,
            id: null,
            surveyItems: [
                {
                    fakeId: new Date().getTime(),
                    id: null,
                    surveyItemName: null,
                    surveyItemValue: null,
                    linkedSurveyRaw: 0,
                    nextLinkedSurveyRaw: 0,
                    IsShowEmoji:false,
                    row: 1,
                },
            ],
        };
        setSurvey((prevList) => ({ surveys: [...prevList.surveys, newRow] }));
    };



    const handleDeleteSurvey= async (groupId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    const response = await DELETE(`/Surveys/${groupId}`,{headers: { 'Content-Type': 'application/json' }});
                    if(response.status === 200){
                        setResult({...response.status});
                        handleSuccessMessage("گروه نظرسنجی با موفقیت حذف شد");
                    }
                    else{
                        handleApiError(response,onApiErrors);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    //updates row property based on any remove trigger to sort all  sur
    const handleRemoveSurvey = async(fakeId,id,isClient) => {
        if(isClient && !id){
            if (survey.surveys.length === 1) {
                setSurvey( { surveys: [ {id: null, question: '', type: options.surveyTypes[0], row: 1, fakeId: new Date().getTime(), surveyItems: [{fakeId: new Date().getTime(), id: null, surveyItemName: '', surveyItemValue: '', linkedSurveyRaw: 0, row: 1,IsShowEmoji:false},],},]});

                return;
            }
            // setSurvey((prevList) => {
            //     const updatedList = prevList.surveys.filter(
            //         (surveyItem) => surveyItem.fakeId !== fakeId || surveyItem.id !== id
            //     );
            //     return { surveys: updatedList };
            // });
            setSurvey((prevList) => {
                const updatedList = prevList.surveys.filter(
                    (surveyItem) => surveyItem.fakeId !== fakeId || surveyItem.id !== id
                ).map((surveyItem, index) => ({ ...surveyItem, row: index + 1 }));
                return { surveys: updatedList };
            });
        }
        else{
            await handleDeleteSurvey(id)
        }
    };

    // const handleChangeSurvey = (fakeId, field, value, id) => {
    //     setSurvey((preSurvey) => {
    //         const updatedSurvey = {
    //             surveys: preSurvey.surveys.map((survey) => {
    //                 if (
    //                     (id && survey.id === id) ||
    //                     (!id && fakeId && survey.fakeId === fakeId)
    //                 ) {
    //                     return { ...survey, [field]: value };
    //                 } else {
    //                     return survey; // Keep other surveys unchanged
    //                 }
    //             }),
    //         };
    //         return updatedSurvey;
    //     });
    // };

    const handleChangeSurvey = (fakeId, field, value, id) => {
        setSurvey((preSurvey) => {
            const updatedSurvey = {
                surveys: preSurvey.surveys.map((survey) => {
                    if (
                        (id && survey.id === id) ||
                        (!id && fakeId && survey.fakeId === fakeId)
                    ) {
                        let updatedSurveyData = { ...survey, [field]: value };

                        // Check if the field being updated is 'type', reset surveyItems
                        if (field === 'type') {
                            updatedSurveyData.surveyItems = [
                                {
                                    fakeId: new Date().getTime(),
                                    id: null,
                                    surveyItemName: null,
                                    surveyItemValue: null,
                                    linkedSurveyRaw: 0,
                                    IsShowEmoji:false,
                                    row: 1,
                                },
                            ];
                        }

                        return updatedSurveyData;
                    } else {
                        return survey; // Keep other surveys unchanged
                    }
                }),
            };
            return updatedSurvey;
        });
    };

    const handleChangeSurveyItems = (realSurveyId, surveyFakeId, surveyItemFakeId, surveyItemId, field, value) => {

        setSurvey((prevSurvey) => {
            const updatedSurveys = prevSurvey.surveys.map((survey) => {
                const surveyIdToCheck = realSurveyId !== null ? realSurveyId : surveyFakeId;
                if (
                    (realSurveyId !== null && survey.id === surveyIdToCheck) ||
                    (realSurveyId === null && survey.fakeId === surveyIdToCheck)
                ) {
                    const updatedSurveyItems = survey.surveyItems.map((item) => {
                        // Use item.fakeId if surveyItemId is null or undefined
                        const itemIdToCheck = surveyItemId !== null ? surveyItemId : item.fakeId;

                        if (
                            (surveyItemId !== null && item.id === itemIdToCheck) ||
                            (surveyItemId === null && item.fakeId === surveyItemFakeId)
                        ) {
                            if (survey.type === 3 && field === 'surveyItemName') {
                                return { ...item, IsShowEmoji:false,[field]:value };
                            }
                            else {
                                return { ...item, [field]: value,IsShowEmoji:false };
                            }

                        } else {
                            return item; // Keep other rows unchanged
                        }
                    });

                    return {
                        ...survey,
                        surveyItems: updatedSurveyItems,
                    };
                } else {
                    return survey; // Keep other rows unchanged
                }
            });

            return { surveys: updatedSurveys };
        });
    };










    const handleAddSurveyItem = (fakeId,index) => {
        setSurvey((prevSurvey) => {
            const updatedSurveys = prevSurvey.surveys.map((survey) => {
                if (survey.fakeId === fakeId) {
                    const newSurveyItem = {
                        fakeId: new Date().getTime(),
                        id: null,
                        surveyItemName: null,
                        surveyItemValue: 0,
                        linkedSurveyRaw: 0,
                        nextLinkedSurveyRaw:0,
                        IsShowEmoji:false,
                        row:index
                    };
                    return {
                        ...survey,
                        surveyItems: [...survey.surveyItems, newSurveyItem],
                    };
                }
                return survey;
            });

            return { surveys: updatedSurveys };
        });
    };




    const handleDeleteSurveyItem = async (surveyItemId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    const response = await DELETE(`/SurveyItems/${surveyItemId}`,{headers: { 'Content-Type': 'application/json' }});
                    if(response.status === 200){
                        setResult({...response.status});
                        handleSuccessMessage("نظرسنجی با موفقیت حذف شد");
                    }
                    else{
                        handleApiError(response,onApiErrors);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    // const handleRemoveSurveyItem = async (realSurveyId,surveyFakeId,surveyItemFakeId,surveyItemId,isClient) => {
    //     if(isClient && !surveyItemId){
    //         setSurvey((prevSurvey) => {
    //             const updatedSurveys = prevSurvey.surveys.map((survey) => {
    //                 // Use survey.fakeId if realSurveyId is null or undefined
    //                 const surveyIdToCheck = realSurveyId !== null ? realSurveyId : surveyFakeId;
    //
    //                 if ((realSurveyId !== null && survey.id === surveyIdToCheck) || survey.fakeId === surveyIdToCheck) {
    //                     // Check if there is more than one survey item
    //                     if (survey.surveyItems.length > 1) {
    //                         const updatedSurveyItems = survey.surveyItems.filter(
    //                             (item) => (surveyItemId !== null ? item.id !== surveyItemId : item.fakeId !== surveyItemFakeId)
    //                         );
    //                         return {
    //                             ...survey,
    //                             surveyItems: updatedSurveyItems,
    //                         };
    //                     }
    //                 }
    //                 return survey;
    //             });
    //
    //             return { surveys: updatedSurveys };
    //         });
    //     }
    //     else{
    //       await handleDeleteSurveyItem(surveyItemId);
    //     }
    // };


    const handleRemoveSurveyItem = async (realSurveyId, surveyFakeId, surveyItemFakeId, surveyItemId, isClient) => {
        if (isClient && !surveyItemId) {
            setSurvey((prevSurvey) => {
                const updatedSurveys = prevSurvey.surveys.map((survey) => {
                    const surveyIdToCheck = realSurveyId !== null ? realSurveyId : surveyFakeId;

                    if ((realSurveyId !== null && survey.id === surveyIdToCheck) || survey.fakeId === surveyIdToCheck) {
                        if (survey.surveyItems.length > 1) {

                            //updates row property based on any remove trigger to sort all  surveyItems based on index
                            const updatedSurveyItems = survey.surveyItems
                                .filter((item) => (surveyItemId !== null ? item.id !== surveyItemId : item.fakeId !== surveyItemFakeId))
                                .map((item, index) => ({ ...item, row: index + 1 }));
                            //updates row property based on any remove trigger to sort all  surveyItems based on index

                            return {
                                ...survey,
                                surveyItems: updatedSurveyItems,
                            };
                        }
                    }
                    return survey;
                });

                return { surveys: updatedSurveys };
            });
        } else {
            await handleDeleteSurveyItem(surveyItemId);
        }
    };




    const handleValidateSurvey = (surveys) => {
        const isValid = surveys.every((survey) => {
            const hasQuestion = survey.question && survey.question.trim().length > 0;

            const surveyItemsValid = survey.surveyItems.every((item) => {
                if (survey.type === 1) {
                    return true;
                }
                if (survey.type === 2) {
                    // Allow null or empty string surveyItemName for type === 2
                    return (
                        item.surveyItemValue &&
                        item.surveyItemValue.toString().trim().length > 0
                    );
                } else {
                    // For other types, enforce non-empty surveyItemName
                    return (
                        item.surveyItemName &&
                        item.surveyItemName.trim().length > 0 &&
                        item.surveyItemValue &&
                        item.surveyItemValue.trim().length > 0
                    );
                }
            });

            return hasQuestion && surveyItemsValid;
        });
        return isValid;
    };

    // updates any survey with type 2
    const handleUpdateRangeSurvey=(surveyGroup)=>{
        const updatedSurveyGroup = surveyGroup.map(group => {
            if (group.type === 2) {

                const maxSurveyItemValue = group.surveyItems.reduce(
                    (maxValue, item) => Math.max(maxValue, item.surveyItemValue),
                    0
                );
                const maxRow = group.surveyItems.reduce(
                    (maxRowValue, item) => Math.max(maxRowValue, item.row),
                    0
                );
                const nextLinkedSurveyRaw = group.surveyItems.reduce(
                    (maxRowValue, item) => Math.max(maxRowValue, item.nextLinkedSurveyRaw),
                    0
                );

                const newSurveyItem = {
                    id: null,
                    surveyItemName: null,
                    surveyItemValue: (maxSurveyItemValue + 1).toString(),
                    linkedSurveyRaw: nextLinkedSurveyRaw,
                    IsShowEmoji:false,
                    row: maxRow + 1
                };

                return {
                    ...group,
                    surveyItems: [...group.surveyItems, newSurveyItem]
                };
            }
            return group;
        });
        return updatedSurveyGroup

    }



    const validateSurveyItems = (surveyGroup) => {
        const rowArray = surveyGroup.map(item => item.row);
        if (!rowArray.includes(0)) {
            rowArray.push(0);
        }
        const linkedSurveyRaws = surveyGroup.flatMap(item => item.surveyItems.map(item => item.linkedSurveyRaw));
        const existInRowArray = linkedSurveyRaws.some(raw => rowArray.includes(raw));
        return existInRowArray;
    };



    return {
        survey,
        setSurvey,
        handleAddSurvey,
        handleRemoveSurvey,
        handleChangeSurvey,
        handleChangeSurveyItems,
        handleAddSurveyItem,
        handleRemoveSurveyItem,
        handleValidateSurvey,
        handleUpdateRangeSurvey,
        handleShowEmoji,
        validateSurveyItems
    }
}