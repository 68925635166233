import {useState} from "react";

export const useDataBinders=(hideSpinner)=>{

    const [errors, setErrors] = useState({});

    const [ticket,setTicket] = useState({ topic: '',departmentId:null,type:null,message:'',memberId:null,files:null,
    siteAdminId:null

    });
    const [activeItem, setActiveItem] = useState(null);


    const handleRemoveFile = (indexToRemove) => {
        const updatedFiles = [...ticket.files];
        updatedFiles.splice(indexToRemove, 1);
        setTicket(prevState => ({...prevState,files:updatedFiles}));
    };





    const onApiError = () => {
        hideSpinner();
    };
    const onSetErros=(error)=>{
        setErrors(error);
    }

    const handleEmoji = (emoji) => {
        setTicket((prevState) => ({ ...prevState, message: prevState.message + emoji }));
    };
    const handleValue = (e) => {
        const { name, value } = e.target;
        setTicket((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const handleSelectValue = (data, name) => {
        setTicket((prevState)=>({...prevState,[name]:data}))
    };


    const handleChangeFile=(event)=>{
        const files = event.target.files;
        const fileArr = Array.from(files);
        setTicket(prevStates=>({...prevStates,files: fileArr}));
        event.target.value = '';
    }

    const handleItemDepartment=(id)=>{
        setTicket((prevState)=>({...prevState,departmentId:id}));
        setActiveItem(id);
    }
    const handleDeActive=(data)=>{
        //null
        setTicket((prevState)=>({...prevState,departmentId:null}));
        setActiveItem(null);
    }

    return {
        errors,
        setErrors,
        ticket,
        onApiError,
        handleEmoji,
        handleValue,
        onSetErros,
        handleSelectValue,
        handleItemDepartment,
        activeItem,
        handleDeActive,
        setTicket,
        handleChangeFile,
        handleRemoveFile,
        setActiveItem
    }
}