import Joi from "joi";
export const formSchema = Joi.object({
    name: Joi.string().required().messages({
        'any.required': 'عنوان الزامیست',
        'string.empty': 'عنوان  الزامیست',
        'string.base': 'عنوان  الزامیست',
    }),
    slug: Joi.string().required().messages({
        'any.required': 'آدرس صفحه الزامیست',
        'string.empty': 'آدرس صفحه  الزامیست',
        'string.base': 'آدرس صفحه  الزامیست',
    }),
    msgSuccess: Joi.string().required().messages({
        'any.required': 'پیام موفقیت الزامیست',
        'string.empty': 'پیام موفقیت الزامیست',
        'string.base': 'پیام موفقیت الزامیست ',
    }),
    msgError: Joi.string().required().messages({
        'any.required': 'پیام خطا الزامیست',
        'string.empty': 'پیام خطا الزامیست',
        'string.base': 'پیام خطا الزامیست ',
    }),

});