import React, {useMemo, useState} from "react";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

import {GridTable} from "../../Commans/Grid/GridTable";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {useGrid} from "./SurveyHooks/useGrid";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {useSetting} from "../../Commans/Hooks/useSetting";


export const HandleListSurveys=()=>{
    const [spinner,setSpinner] = useState(false);
    const [result,setResult] = useState(false);
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const onApiError = () => {
        setSpinner(false);
    };
    const {pollId,handleCloseIsOpen,handleFromDateTime,handleToDateTime,IsOpen,autoGroupColumnDef,rowData,columnDefs,data,switchData,setSearch,handleSearch,resetFilters,filter,getRowHeight,currentPage,filterValueHandler,defaultColDef,setFilter,setData,search,setCurrentPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount,handleNextPage}=useGrid(setSpinner,result,setResult,onApiError);



    return(
        <>
            <div class="space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2 ">
                                <label className="text-xs dark:text-gray-300">{t("SurveyTitle")}</label>
                                <InputText value={search} onChange={(e)=>setSearch(e.target.value)} type="search" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className="input w-full" />
                            </div>
                            <div class=" space-y-2">
                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("FromDate")}
                                </label>
                                <DateTimePicker handleDateTime={handleFromDateTime} value={filter.fromDate} format={'YYYY/MM/DD'} />
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("ToDate")}
                                </label>
                                <DateTimePicker value={filter.toDate} handleDateTime={handleToDateTime} format={'YYYY/MM/DD'} />
                            </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            functionClass={"harmony-creation"}
                                            onClick={()=>redirectTo(`/CMS/Surveys/Add`)}
                                            tooltipTitle={t("CreatingASurvey")}
                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />


                </div>

                <div class="relative space-y-2 H-body-ag-grid">
                    {

                        spinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export filters={filter} apiUrl={''} />
            </div>


            <FantasyModal openModal={IsOpen} closeModal={handleCloseIsOpen} >
                <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
                    <ModalButtonOperations
                        onClick={()=>redirectTo(`/CMS/Surveys/List/Result?surveyGroupId=${pollId}`)}
                        title={t("ViewStatistics")}
                        icon={"pi-chart-bar"}
                    />
                    <ModalButtonOperations
                        onClick={()=>redirectTo(`/CMS/Surveys/List/ResultDetails?surveyGroupId=${pollId}`)}
                        title={t("DetailedComments")}
                        icon={"pi-comments"}
                    />
                </div>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>
        </>

    )

}