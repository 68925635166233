import React, {useState} from "react";

export const useMenuBinder=()=>{
    const [nodeData,setNodeData] = useState({id:'',title:'',slug:'',targetType:null,parentId:''});
    const [errors, setErrors] = useState({});

    const handleValue = (e) => {
        const { name, value } = e.target;
        setNodeData({ ...nodeData, [name]: value });
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const handleSelectValue=(data)=>{
        setNodeData((prevState)=>({...prevState,targetType: data}));
    }



    // gets tree json data of target from api to convert it in to tree format


    return {
        handleValue,
        handleSelectValue,
        nodeData,
        errors,
        setErrors,
        setNodeData,
    }


}