import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {handleFailureMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {useTranslation} from "react-i18next";
import {validDomainPattern} from "../../../../../Services/Globals/Public/Regexes";
import {DateTimePicker} from "../../../../Commans/Dates/DateTimePicker";
import {GetCurrentDateTime} from "../../../../../Services/Globals/Public/TimeServiceManager";
import {RequiredIcon} from "../../../../Commans/Errors/RequiredIcon";
import ArrowLeftIcon from "../../../../HIcon/ArrowLeftIcon";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const Domains =({handleChangeTab,onValueChange,onChangeDomain,values})=>{
    const { t } = useTranslation();


    const IsDomainValid = (domain) => validDomainPattern.test(domain);

    const handleChangeViewTab = (tabId) => {
        const count = domains.filter((item) => item.domain === null).length;
        const masterCount = domains.filter((item) => item.isMaster === true).length;
        if (count > 0) {
            handleFailureMessage(t("Dearuserpleaseenterthedomainname"));
            return;
        }
        else if(masterCount !== 1){
            handleFailureMessage(t("Pleasechooseadefaultdomain"));
            return;
        }
        // else {
        //     const Valids = domains.map((item) => IsDomainValid(item.domain));
        //     const count = Valids.filter((item) => item === false).length;
        //     if (count > 0) {
        //         handleFailureMessage(
        //             t("Enteravaliddomainname")
        //         );
        //         return;
        //     }
        // }
        onChangeDomain(domains);
        handleChangeTab(tabId);
    };

    const GoToLastPage=(tabId)=>{
        onChangeDomain(domains);
        handleChangeTab(tabId);
    }


    const [domains, setDomains] = useState(values.Domains || [
        {
            id: 1,
            domain: null,
            expirationDate: GetCurrentDateTime(false,1),
            protocol:1,
            isMaster: true,
            domainServer: 0,
            cdn: true,
            compression: false,
        },
    ]);

    const handleAddRow = () => {
        const newRow = {
            id: new Date().getTime(), // Unique identifier using timestamp
            domain: null,
            expirationDate: GetCurrentDateTime(false,1),
            protocol: 1,
            isMaster: false,
            cdn: true,
            compression: false,
            domainServer: 0,
        };
        setDomains((prevList) => [...prevList, newRow]);
    };

    const handleRemoveRow = (id) => {
        if (domains.length === 1) {
            return;
        }
        setDomains((prevList) => prevList.filter((row) => row.id !== id));
    };

    const handleChange = (id,field, value) => {
        setDomains((prevList) =>
            prevList.map((row) =>
                row.id === id
                    ? {...row, [field]: field === 'isMaster' && value === true ? true : value === '' ? null : value}
                    : {...row, [field]: field === 'isMaster' ? false : row[field],}
            )
        );
    };
    // const handleChange = (id, field, value) => {
    //     setDomains((prevList) =>
    //         prevList.map((row) =>
    //             row.id === id
    //                 ? {
    //                     ...row,
    //                     [field]:
    //                         field === 'isMaster' && value === true
    //                             ? true
    //                             : field === 'domainServer'
    //                                 ? value === 1
    //                                 : value === ''
    //                                     ? null
    //                                     : value,
    //                 }
    //                 : { ...row, [field]: field === 'isMaster' ? false : row[field] }
    //         )
    //     );
    // };


    const DateTimeHandler = (value, id, field) => {
        setDomains((prevList) =>
            prevList.map((row) =>
                row.id === id ? { ...row, [field]: value } : row
            )
        );
    };

    const handleValueChange = (data, name) => {
        if (name === "SiteSpace" && data === null) {
            onValueChange(0, name);
            return;
        } else if (name === "MailSpace" && data === null) {
            onValueChange(0, name);
            return;
        }
        onValueChange(data, name);
    };

    useEffect(() => {
        if (domains.length === 0) {
            handleAddRow();
        }
    }, [domains]);


    return (<>
        {console.log(values)}
        <div>
            <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                    4
                </div>
                <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                    <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">{t("domains")}</h2>
                </div>
                <div>
                    <span className={"arrow_design_add_site"}>
                       <ArrowLeftIcon/>
                    </span>
                </div>
            </div>
        </div>
        <div className={"mt-5 space-y-5 px-2"}>
            <div class="space-y-5">
                <div className={"grid grid-cols-1 md:grid-cols-2 p-2 lg:grid-cols-4 gap-4 post-input dark:bg-themeDarkPostInput post-input-design"}>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("HostingSpaceAmount")}</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                    <HPrimeIcon
                                    icon={"pi-cloud"}
                                    />
                                </span>
                                <InputNumber name={'SiteSpace'}   min={0}  onValueChange={(e)=>handleValueChange(e.target.value,'SiteSpace')} value={values.SiteSpace}  />
                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">MB</span>
                            </div>
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("MailServerStorageSpace")}</label>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                    <HPrimeIcon
                                        icon={"pi-cloud"}
                                    />
                                </span>
                                <InputNumber name={'MailSpace'}  min={0} onValueChange={(e)=>handleValueChange(e.target.value,'MailSpace')} value={values.MailSpace}  />
                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">MB</span>
                            </div>
                        </div>
                    </div>
                <div className="grid grid-cols-1 gap-4 max_height30">

                    {domains.map((row, index) => (
                        <div key={row.id} >
                            <div className="p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                <div className="w-full flex flex-col xl:flex-row items-end gap-2">
                                    <div className="flex-1 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-3 items-end">
                                        <div className="flex gap-2 items-center">
                                            <div className={"mt-auto"}>
                                                <div className="number_pluse bg-themeInformation dark:bg-themeDarkInformation text-white dark:text-gray-300">
                                                    {index + 1}
                                                </div>
                                            </div>
                                            <div className={"space-y-1 flex-1 w-full"}>
                                                <label className="text-xs dark:text-gray-300">
                                                    {t("Domain")}
                                                    <RequiredIcon/>
                                                </label>
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                        <HPrimeIcon
                                                        icon={"pi-server"}
                                                        />
                                                    </span>
                                                    <InputText onChange={(e) => handleChange(row.id, 'domain', e.target.value)}
                                                               className="input w-full"
                                                               value={row.domain}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"space-y-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("ExpirationDate")}</label>
                                            <DateTimePicker
                                                handleDateTime={(value) => DateTimeHandler(value, row.id, 'expirationDate')}
                                                fakeId={row.id}
                                                name={'expirationDate'}
                                                format={'YYYY/MM/DD'}
                                                value={row.expirationDate}

                                            />
                                        </div>
                                        <div className={"md:col-span-2"}>
                                            <div className="flex flex-col md:flex-row gap-5 items-center">
                                                <div className="space-y-2">
                                                    <div className="flex gap-3">
                                                        <div className="can-toggle demo-rebrand-1 text-xs w-28">
                                                            <input id={`swich1_${row.id}`}
                                                                   onChange={(e) => handleChange(row.id, 'protocol', e.target.checked ? 1 : 0)}
                                                                   type="checkbox"
                                                                   checked={row.protocol}
                                                            />
                                                            <label htmlFor={`swich1_${row.id}`}>
                                                                <div class="can-toggle__switch" data-checked="https" data-unchecked="http"></div>
                                                            </label>
                                                        </div>
                                                        <div className="can-toggle demo-rebrand-1 text-xs w-36">
                                                            <input id={`swich3_${row.id}`}
                                                                   onChange={(e) => handleChange(row.id, 'domainServer', e.target.checked ? 1 : 0)}
                                                                   type="checkbox"
                                                                   checked={row.domainServer}
                                                            />
                                                            <label htmlFor={`swich3_${row.id}`}>
                                                                <div class="can-toggle__switch" data-checked={t("Internal")} data-unchecked={t("External")}></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-3">
                                                        <div className="can-toggle demo-rebrand-1 text-xs w-28">
                                                            <input id={`swich2_${row.id}`}
                                                                   onChange={(e) => handleChange(row.id, 'cdn', e.target.checked)}
                                                                   type="checkbox"
                                                                   checked={row.cdn}

                                                            />
                                                            <label htmlFor={`swich2_${row.id}`}>
                                                                <div class="can-toggle__switch" data-checked="CDN" data-unchecked="Simple"></div>
                                                            </label>
                                                        </div>
                                                        <div className="can-toggle demo-rebrand-1 text-xs w-36">
                                                            <input id={`swich4_${row.id}`} onChange={(e) => handleChange(row.id, 'compression', e.target.checked)}
                                                                   type="checkbox"
                                                                   checked={row.compression}
                                                            />
                                                            <label htmlFor={`swich4_${row.id}`}>
                                                                <div class="can-toggle__switch" data-checked={t("compress")} data-unchecked={t("Simple")}></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <label className="text-xs dark:text-gray-300">
                                                        {t("default")}

                                                    </label>
                                                    <div>
                                                        <input checked={row.isMaster} className="input input--switch border bg-white" onChange={(e) => handleChange(row.id, 'isMaster', e.target.checked)}
                                                               type="checkbox"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="flex justify-center items-end gap-3 md:justify-end">
                                            <button onClick={() => handleAddRow()} type="button" className="button bg-themeInformation dark:bg-themeDarkInformation text-white small_button">
                                                <HPrimeIcon
                                                icon={"pi-plus"}
                                                />
                                            </button>
                                            <button onClick={() => handleRemoveRow(row.id)} type="button" className="button bg-themeDanger text-white small_button">
                                                <HPrimeIcon icon={"pi-minus"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
            <div class="flex items-center justify-center md:justify-end gap-2">
                <button type={'button'} onClick={()=>GoToLastPage('license')} className="block button w-28  bg-themeDanger text-white">{t("previous")}</button>
                <button type={'button'} onClick={()=>handleChangeViewTab('servers')} className="block button w-28 bg-themeInformation dark:bg-themeDarkInformation text-white">{t("Next")}</button>
            </div>
        </div>



    </>)
}

