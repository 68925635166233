export const convertCompanyHandler = (obj,id)=>{
    const newObj = {
        id:id,
        name: obj.name === null || obj.name === undefined ?null:obj.name,
        brand: obj.brand === null || obj.brand === undefined?null:obj.brand,
        hasBrandConfirmed: obj.hasBrandConfirmed === null || obj.hasBrandConfirmed === undefined?null:obj.hasBrandConfirmed,
        type: obj.type === null || obj.type === undefined?null: obj.type.value,
        postalCode: obj.postalCode === null || obj.postalCode === undefined? null:obj.postalCode,
        address: obj.address === null || obj.address === undefined?null:obj.address,
        registrationNumber:obj.registrationNumber === null || obj.registrationNumber === undefined?null:obj.registrationNumber,
        nationalCode:obj.nationalCode === null || obj.nationalCode === undefined?null:obj.nationalCode,
        description:obj.description === null || obj.description === undefined ? null:obj.description,
        concurrencyStamp:obj.concurrencyStamp === null || obj.concurrencyStamp === undefined ? null:obj.concurrencyStamp,
        cityId:obj.cityId === null || obj.cityId === undefined? null: obj.cityId.value,
    }
    return newObj
}