import React, {useEffect, useMemo, useState} from "react";
import {Chart} from "primereact/chart";
import queryString from "query-string";
import {useCleanBinder} from "./useCleanBinder";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../Commans/Grid/ListFilter";

import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {CheckBoxSection, checkBoxSection} from "../../Commans/Globals/CheckBoxSection";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import Select from "react-select";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {handleAddCleanDataFormat} from "./DataCleanService";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {useSetting} from "../../Commans/Hooks/useSetting";

export const HandleAdminCleaner = () => {

    const [chartData,setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['A', 'B', 'C'],
            datasets: [
                {
                    data: [300, 50, 100],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'),
                        documentStyle.getPropertyValue('--yellow-500'),
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'),
                        documentStyle.getPropertyValue('--yellow-400'),
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        };
        const options = {
            cutout: '80%'
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [initialSource,setInitialSource] = useState({sections:[],domainTokens:[]});
    const queryParams = queryString.parse(window.location.search);
    const [showSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(0)
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {handleChangeSection,handleToDate,handleChangeDomainToken,handleFromDate,setClean,cleanData,ResetCleanData} = useCleanBinder(initialSource.domainTokens);

    // handles loading spinner in api request
    const onApiError=()=>{
        setShowSpinner(false);
    }

    const Refresh=(state)=>{
        setRefresh({...state});
    }

    const handleInitialData=(sections,domainTokens)=>{
        const sectionData = convertSelectDataHandler(sections);
        const domainTokensData = convertSelectDataHandler(domainTokens);
        domainTokensData.unshift({ label: '*', value: '*' });
        setInitialSource({
            domainTokens: domainTokensData,
            sections: sectionData
        })
    }

    // fetches all data from database in initial time of component
    const fetchData=async()=>{
        try {
            setShowSpinner(true)
            const responseSection = await GET(`/Enumerations/EnWebsiteFilesSections`,{},'IEnumerable');
            const responseDomainToken = await GET(`/Websites/WebSitesDomainToken`,{},'IEnumerable');
            if(responseSection.status === 200 && responseDomainToken.status === 200){
                handleInitialData(responseSection.data,responseDomainToken.data);
                setShowSpinner(false);
            }
            else{
                handleApiError(responseSection,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        fetchData()
    },[refresh])


    const handleSubmitDelete=async()=>{
      try {
          setShowSpinner(true)
          if(cleanData.section.length === 0){
              handleFailureMessage(t("PleaseSelectSection"));
              setShowSpinner(false)
              return;
          }
          if(cleanData.domainTokens.length === 0){
              handleFailureMessage(t("PleaseSelectDomain"));
              setShowSpinner(false)
              return;
          }
          const dataJson = handleAddCleanDataFormat(cleanData);
          const response = await POST(`/Management/CleanTempFiles`,dataJson,false);
          if(response.status === 200){
              handleSuccessMessage(t("DataCleanupCompletedSuccessfully"));
              ResetCleanData();
              Refresh(response.status);
              redirectTo('/Dashboard');
          }
          else{
              handleApiError(response,onApiError);
          }
      }
      catch (e) {
          onApiError();
          console.log(e);
      }
    }





    return (
        <>

            {console.log(cleanData)}
            <div class=" relative space-y-5">
                {

                    showSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>


                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-3"}>
                    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-y-5"}>
                        <div className={"space-y-4"}>
                            <div className={"space-y-3"}>
                                <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                                    <h2 className={"dark:text-gray-300 text-xs"}>{t("Sectionselection")}</h2>
                                </div>
                                <CheckBoxSection
                                    data={initialSource.sections}
                                    onChange={handleChangeSection}
                                />
                            </div>
                            <div className={"space-y-3"}>
                                <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                                    <h2 className={"dark:text-gray-300 text-xs"}>{t("TimeInterval")}</h2>
                                </div>
                                <div className={"px-2"}>
                                    <div className={'flex gap-2 item-center post-input dark:bg-themeDarkPostInput p-2 post-input-design'}>
                                        <div className="flex-1 space-y-1">
                                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                                <SettingButton
                                                    onClick={()=>handleSetting('GeneralSetting')}
                                                    tooltipTitle={t("Calendarsettings")}
                                                />
                                                {t("FromDate")}
                                            </label>
                                            <DateTimePicker value={cleanData.fromDate} handleDateTime={handleFromDate}
                                                            format={'YYYY/MM/DD'}/>
                                        </div>
                                        <div className="flex-1 space-y-1">
                                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                                <SettingButton
                                                    onClick={()=>handleSetting('GeneralSetting')}
                                                    tooltipTitle={t("Calendarsettings")}
                                                />
                                                {t("ToDate")}
                                            </label>
                                            <DateTimePicker value={cleanData.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'} zIndex={"1000"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"space-y-3"}>
                                <div className={"p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation"}>
                                    <h2 className={"dark:text-gray-300 text-xs"}>{t("Domain")}</h2>
                                </div>
                            </div>
                            <div className={"px-2"}>
                                <div className={"post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                                    <div className={"space-y-1"}>
                                        <label className={"text-nowrap dark:text-gray-300 text-xs"}>{t("Desireddomain")}</label>
                                        <div className={'flex-1'}>
                                            <Select
                                                options={initialSource.domainTokens ?? []}
                                                isMulti={true}
                                                isSearchable={true}
                                                onChange={(data) => handleChangeDomainToken(data)}
                                                placeholder={t("selection")}
                                                value={cleanData.domainTokens}
                                                className={"select-group"}
                                                menuPosition={"fixed"}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <button type={"button"} onClick={() => handleSubmitDelete()}
                                    className={"button bg-themeDanger  w-full text-white"}>
                                {t("DataCleanup")}
                            </button>
                        </div>
                        <div>
                            <img src="/Assets/Image/bg-img/deleteData.svg" alt="backup" className={" max-w-3/4 mx-auto"}/>
                        </div>
                    </div>
                </div>
            </div>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>
                            :
                            null
                    }
                </div>
            </FantasyModal>
        </>

    );
};