
import {InputTextarea} from "primereact/inputtextarea";
import {InputText} from "primereact/inputtext";
import React from "react";

export const InputTextAreaElement = ({ item,index,handleChangeData}) => (
    <>
        <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
            <label className="dark:text-gray-300 harmony-text-xs">
                {item.properties.caption}
            </label>
            <InputTextarea
                maxLength={item.properties.max}
                minLength={item.properties.min}
                placeholder={item.properties.description}
                readOnly={item.properties.isReadOnly}
                disabled={item.properties.isReadOnly}
                id={item.properties.id}
                onChange={(e) => handleChangeData(index, 'value', e.target.value)}
                value={item.data?.value}
            />
        </div>


    </>
);