import React from "react";
import {useTranslation} from "react-i18next";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const Sale=({icon, data, title,handleGetCurrency})=>{
    const { t } = useTranslation();

    return (<>

        <div>
            <div className="sell report-box">
                <div className="box bg-themeInformation p-2 dark:bg-themeDarkSalt700 flex justify-between items-center  gap-3">
                    <div>
                        <HPrimeIcon
                        icon={icon}
                        iconClass={"text-white dark:text-gray-300"}
                        />
                    </div>
                    <div className="flex-1">
                        <h2 className={"text-xs text-white dark:text-gray-300"}>{t(title)}</h2>
                        <h2 className="text-white dark:text-gray-300"><PriceFormatterComponent price={data && data.salesAmount.amount}/> { data && handleGetCurrency(data.salesAmount.currency)}</h2>
                    </div>
                </div>
            </div>
        </div>

    </>)
}