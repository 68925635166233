import React, {useEffect, useState} from "react";
import Select from "react-select";

import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {useNavigate} from "react-router-dom";
import {handleFailureMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import { convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HandleFastRegister} from "../../../../CRM/PersonsManagement/UserRegisteration/HandleFastRegister";
import ArrowLeftIcon from "../../../../HIcon/ArrowLeftIcon";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const Users = ({UserId,onChangeUser,handleChangeTab})=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const[spinner,setSpinner] = useState(false);
    const [refresh,setIsRefresh] = useState(false);
    const [isOpen,setOpen] = useState(false);
    const handleOpenModal = ()=>{
        setOpen(true);
    }

    const onApiError = ()=>{
        setSpinner(false);
    }





    const handleCloseModal = () => {
        setOpen(false);
    };
    const handleSuccessUserFastRegister=(isSuccess)=>{
        if(isSuccess){
            handleCloseModal();
            setIsRefresh(prevState => !prevState);
        }
    }

    const handleResetModal = () => {
        setOpen(true);
    };




    const [users,setUser] = useState({code:null,fullName:null,gender:null,phoneNumber:null,nationalCode:null,homePhone:null,email:null,introType:null});


    const [options,setOptions] = useState({users:[],genders:[],intro:[],roleTypes:[]});
    const handleUserChange = async(data,name) => {
        if(!data){
            return;
        }
      try {
          onChangeUser(data,name);
          setSpinner(true);
          const response = await GET(`/Users/Information/${data.value}`);
          if(response.status === 200){
              setSpinner(false)
              setUser({
                  code: response.data.presenterCode || '',
                  fullName: response.data.fullName || '',
                  gender: options.genders.find((item) => item.value === response.data.sex) || '',
                  phoneNumber: response.data.mobilePhoneNumber || '',
                  nationalCode: response.data.nationalCode || '',
                  homePhone: response.data.homePhoneNumber || '',
                  email: response.data.email || ' ',
                  introType: options.intro.find((item) => item.value === response.data.introductionMethod) || '',
              })
          }else{
              handleApiError(response,onApiError)
          }
      } catch (e) {
          console.log(e);
      }
    };
    const handleChangeViewTab = (tabId) => {
        if(UserId === null){
            handleFailureMessage(t("Pleaseselectuser"));
            return;
        }
        handleChangeTab(tabId)
    };

    const GoToLastPage=(tabId)=>{
        handleChangeTab(tabId)
    }
    const handleFetchData = async ()=>{
       try {
           setSpinner(true)
           const responseUser = await GET('/Users/Selection',{},'IEnumerable');
           const responseGenders = await GET('/Enumerations/EnGenderType',{},'IEnumerable');
           const responseIntro = await GET('/Enumerations/EnIntroductionMethod',{},'IEnumerable');
           const responseRole = await GET('/Roles/Selection',{},'IEnumerable');

           if(responseUser.status === 200 && responseGenders.status === 200 && responseIntro.status === 200 && responseRole.status === 200){
               const users = convertSelectDataHandler(responseUser.data);
               const genders = convertSelectDataHandler(responseGenders.data);
               const intros = convertSelectDataHandler(responseIntro.data);
               const roleTypes = convertSelectDataHandler(responseRole.data);
               //const defaultRole =  [roleTypes.find((item) => item.value === "a690388f-dd28-422a-abd9-7993be0f832d")]
               // setCustomar({...customar,roles:roleTypes[0],sex: genders[2],introductionMethod: intros[1]});

               setOptions({users: users,genders: genders,intro: intros,roleTypes: roleTypes});

               if(UserId){
                   const response = await GET(`/Users/Information/${UserId.value}`,{},'single');
                   if(response.status === 200){
                       setSpinner(false)
                       setUser((prevState)=>({...prevState,fullName:response.data.fullName, gender: genders.find((item) => item.value === response.data.sex), phoneNumber: response.data.mobilePhoneNumber, nationalCode: response.data.nationalCode, homePhone: response.data.homePhoneNumber, email: response.data.email, introType:intros.find((item) => item.value === response.data.introductionMethod), code: response.data.presenterCode}));
                   }else{
                       handleApiError(response,onApiError)
                   }
               }
               setSpinner(false);


           }
           else{
               handleApiError(responseUser,onApiError) ;
               handleApiError(responseGenders,onApiError);
               handleApiError(responseIntro,onApiError);
           }
       }catch (e) {
          console.log(e);
       }
    }

    useEffect(()=>{
        handleFetchData();
    },[refresh])






    return (
       <>

           <div>
               <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                   <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                       2
                   </div>
                   <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                       <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">{t("SelectCustomer")}</h2>
                   </div>
                   <div>
                       <span className={"arrow_design_add_site"}>
                            <ArrowLeftIcon/>
                       </span>
                   </div>
               </div>
           </div>
           <div className={"space-y-5 mt-5 px-2"}>
               <div className="grid grid-cols-1 lg:grid-cols-2">
                   {

                       spinner?
                           <div className=" huploader_loading" >
                               <div className="relative">
                                   <LoadingHarmony/>
                               </div>
                           </div>
                           :
                           null
                   }
                   <div class="post-input post-input-design dark:bg-themeDarkPostInput p-2">
                       <div className="grid grid-cols-1 p-2 ">
                           <div class="flex items-end gap-2">
                               <div className="flex-1 space-y-2">
                                   <label className="text-xs dark:text-gray-300">{t("SelectCustomer")}</label>
                                   <Select
                                       options={options.users}
                                       isSearchable={true}
                                       onChange={(data)=>handleUserChange(data,'UserId')}
                                       placeholder={t("selection")}
                                       value={UserId}
                                       classNamePrefix={"h_multi_select"}
                                   />
                               </div>
                               <div>
                                   <button type={'button'} className="button bg-themeInformation tooltip_fast_user_register dark:bg-themeDarkInformation text-white small_button" onClick={() => handleOpenModal()}>
                                       <HPrimeIcon
                                       icon={"pi-plus"}
                                       />
                                       <Tooltip anchorSelect=".tooltip_fast_user_register" content={t("QuickUserRegistration")} />
                                   </button>
                               </div>
                           </div>
                       </div>
                       <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-2">
                           <div class="space-y-2">
                               <label className="text-xs dark:text-gray-300">{t("MrMiss")}</label>
                               <Select
                                   options={options.genders}
                                   isSearchable={true}
                                   //onChange={(data)=>handleSelectValue(data,'gender')}
                                   isDisabled={true}
                                   placeholder={t("selection")}
                                   value={users.gender}
                                   classNamePrefix={"h_multi_select"}
                               />
                           </div>
                           <div class="space-y-2">
                               <label className="text-xs dark:text-gray-300">{t("FirstNameAndLastName")}</label>
                               <div className="p-inputgroup">
                               <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                   <HPrimeIcon
                                   icon={"pi-user"}
                                   />
                               </span>
                                   <InputText value={users.fullName} className="input w-full" disabled/>
                               </div>
                           </div>
                           <div class=" space-y-2">
                               <label className="text-xs dark:text-gray-300">{t("MobilePhone")}</label>
                               <div className="p-inputgroup">
                               <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                    <HPrimeIcon
                                        icon={"pi-mobile"}
                                    />
                               </span>
                                   <InputText value={users.phoneNumber} className="input w-full" disabled/>
                               </div>
                           </div>
                           <div class="space-y-2">
                               <label className="text-xs dark:text-gray-300">{t("NationalIdNumber")}</label>
                               <div className="p-inputgroup">
                               <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                   <HPrimeIcon
                                       icon={"pi-id-card"}
                                   />
                               </span>
                                   <InputText value={users.nationalCode}  className="input w-full" disabled />
                               </div>
                           </div>
                           <div class="space-y-2">
                               <label className="text-xs dark:text-gray-300">{t("LandlinePhone")}</label>
                               <div className="p-inputgroup">
                               <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                   <HPrimeIcon
                                       icon={"pi-phone"}
                                   />
                               </span>
                                   <InputText value={users.homePhone} className="input w-full" disabled/>
                               </div>
                           </div>
                           <div class="space-y-2">
                               <label className="text-xs dark:text-gray-300">{t("Email")}</label>
                               <div className="p-inputgroup">
                               <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                    <HPrimeIcon
                                        icon={"pi-envelope"}
                                    />
                               </span>
                                   <InputText value={users.email} className="input w-full" disabled/>
                               </div>
                           </div>
                           <div class="space-y-2">
                               <label className="text-xs dark:text-gray-300">{t("methodOfIntroduction")}</label>
                               <Select
                                   options={options.intro}
                                   isSearchable={true}
                                   isDisabled={true}
                                   // onChange={(data)=>handleSelectValue(data,'introType')}
                                   placeholder={t("selection")}
                                   value={users.introType}
                                   classNamePrefix={"h_multi_select"}
                               />
                           </div>
                           <div class="space-y-2">
                               <label className="text-xs dark:text-gray-300">{t("Referrer")}</label>
                               <div className="p-inputgroup">
                               <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                   <HPrimeIcon
                                       icon={"pi-user"}
                                   />
                               </span>
                                   <InputText value={users.code} className="input w-full" disabled/>
                               </div>
                           </div>
                       </div>
                   </div>
                   <div class="hidden lg:grid place-content-center">
                       <img src="/Assets/Image/template/Host.png" alt="" className=" max-w-3/4 mx-auto"/>
                   </div>
               </div>
               <div class=" flex items-center justify-center md:justify-end gap-2">
                   <button type={'button'} onClick={()=>GoToLastPage('packages')} className="block button bg-themeDanger text-white w-28">{t("previous")}</button>
                   <button type={'button'} onClick={()=>handleChangeViewTab('license')} className="block button w-28 bg-themeInformation dark:bg-themeDarkInformation text-white">{t("Next")}</button>
               </div>
           </div>

           <FantasyModal openModal={isOpen} closeModal={handleCloseModal} customeClassName={"modal-xl"} title={t("fastRegistration")} fieldse_bg_Class={"bg-white dark:bg-themeDarkSalt700"}>

               <HandleFastRegister
                   IsDefault={false}
                   handleSuccess={handleSuccessUserFastRegister}
                   IsOpen={isOpen}
               />

           </FantasyModal>
       </>
    )
}

