import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useLogError} from "./useLogError";
import {LogErrorsBody} from "./LogErrorsBody";
import {useLogErrorsTab} from "./useLogErrorsTab";

export const LogErrors=({})=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh,setRefresh] = useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const {logError,setLogError,logId,handleSelectLogData,logData}=useLogError();
    const {setTabs}=useLogErrorsTab();


    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/public/ErrorLogs`,{},'single');
            if(response.status === 200){
                setLogError(response.data);
                const lastError = response.data[response.data.length - 1];
                handleSelectLogData(lastError.id,lastError);

                const newTab = {
                    id: "innerException",
                    target: "#innerException",
                    label: 'innerException',
                };

                if (lastError?.innerExceptionStackTrace !== null && lastError?.innerExceptionMessage !== null) {
                    setTabs(prevTabs => {
                        // Check if the tab already exists by its id
                        const tabExists = prevTabs.some(tab => tab.id === newTab.id);
                        if (!tabExists) {
                            return [...prevTabs, newTab];
                        }
                        return prevTabs;
                        // Return the previous state if tab already exists
                    });
                } else {
                    // Remove the tab if innerExceptionStackTrace and innerExceptionMessage are null
                    setTabs(prevTabs => {
                        return prevTabs.filter(tab => tab.id !== newTab.id);
                    });
                }
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[])





    return <>
        <div class="space-y-5 ">
            {

                IsShowSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListFilter label={null} icon={null}/>
            </div>

            <LogErrorsBody
              handleSelectLogData={handleSelectLogData}
              logData={logData}
              logError={logError}
              logId={logId}
            />



        </div>

    </>
}