import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

export const useModal=(setErrors,options,setComments,setCommunication,setIsEdit,url)=>{

    const redirectTo = useNavigate();
    const [modal, setModal] = useState({ communication: false,commentAdd:false});

    const handleOpenComment=()=>{
        setComments((prevState)=>({...prevState,state: options.states[0],dislikeCounts: 0,rateCommenting: 0,likeCounts: 0,slug: options.slugs[0],adminDislikeCounts: 0,adminLikeCounts: 0,adminReplyRateCommenting: 0,parentId: null,fullName:'',message:'',adminReply: ''}));
        setModal((prevState) => ({ ...prevState, commentAdd:true }));
        setCommunication([{value: null, fakeId: new Date().getTime(), communicationMode: options.modes[0], description: null, communicationId: options.types[0],id:null}])
    }
    const handleCloseComment=()=>{
        setModal((prevState) => ({ ...prevState, commentAdd:false }));
        setComments((prevState)=>({...prevState,state: options.states[0],dislikeCounts: 0,rateCommenting: 0,likeCounts: 0}));
        setCommunication([{value: null, fakeId: new Date().getTime(), communicationMode: options.modes[0], description: null, communicationId: options.types[0],id:null}])
        redirectTo(url);
        setIsEdit(false);
        setErrors({})
    }
    const handleOpenCommunication=()=>{
        setModal((prevState) => ({ ...prevState, communication: true }));
    }
    const handleCloseCommunication=()=>{
        setModal((prevState) => ({ ...prevState, communication: false }));
        setCommunication([])
    }

    return {
        handleOpenComment,
        handleCloseComment,
        handleOpenCommunication,
        handleCloseCommunication,
        modal,
        setModal

    }
}