import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {FastDepartment} from "../Departments/FastDepartment";
import {useNavigate} from "react-router-dom";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";



export const MenuController = (props)=>{

    const redirectTo = useNavigate();
    const {t} = useTranslation();
    const [IsDepartmentOpen,setDepartmentOpen] = useState();
    const[btnLoading,setBtnLoading] = useState(false);
    const handleBtnLoading=(data)=>{
        setBtnLoading(data);
    }
    const onApiError = ()=>{
        setBtnLoading(false);
    }

    const handleOpenDepartment=()=>{
        setDepartmentOpen(true);
    }
    const handleCloseDepartment =()=>{
        setDepartmentOpen(false);
    }
    const handleResetModal = () => {
        setDepartmentOpen(true);
    };




    return <>

        <div className={"col-span-12 lg:col-span-3 "} >
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-3">
                <div className="flex justify-between items-center">
                    <h2 className="dark:text-gray-300">{t("Ticket")}</h2>
                    <button onClick={()=>redirectTo('/Support/Ticket')} className="button bg-themeInformation dark:bg-themeDarkInformation hover:bg-themeHoverInformation tooltip_class_Ticket">
                        <HPrimeIcon icon={'pi-comments'} iconClass="text-white"/>
                        <Tooltip anchorSelect={".tooltip_class_Ticket"} content={t("Ticket")} />
                    </button>
                </div>
                <div className="flex justify-between items-center">
                    <h2 className="dark:text-gray-300">{t("TicketList")}</h2>
                    <button onClick={()=>redirectTo('/Support/Ticket/List')} className="button bg-themeInformation dark:bg-themeDarkInformation hover:bg-themeHoverInformation tooltip_class_List">
                        <HPrimeIcon icon={'pi-list'} iconClass="text-white"/>
                        <Tooltip anchorSelect={".tooltip_class_List"} content={t("TicketList")} />
                    </button>
                </div>
                <div className="flex justify-between items-center ">
                    <h2 className="dark:text-gray-300">{t("Department")}</h2>
                    <button className="button bg-themeInformation dark:bg-themeDarkInformation tooltip_class_Department hover:bg-themeHoverInformation" onClick={() => handleOpenDepartment()}>
                        <HPrimeIcon icon={'pi-plus'} className="text-white"/>
                        <Tooltip anchorSelect={".tooltip_class_Department"} content={t("NewDepartment")} />
                    </button>
                </div>
                <div class="border-t border-themeDarkColorLine space-y-4 py-4">
                    <div className="space-y-5 rounded-md">
                        <div  className="flex items-center flex-wrap gap-3 justify-between p-3 post-input dark:bg-themeDarkPostInput rounded-md">
                            <div className="flex gap-2 items-center text-gray-500 text-xs dark:text-gray-300">
                                <HPrimeIcon icon={'pi-check-square'} />
                                {t("Answered")}
                            </div>
                            <div className="min-w-5 h-5 rounded-full bg-themeInformation text-xs dark:bg-themeDarkInformation text-white grid place-content-center">
                                <span>{props.answeredCount}</span>
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-3 items-center justify-between p-3 post-input dark:bg-themeDarkPostInput rounded-md">
                            <div className="flex gap-2 items-center text-gray-500 dark:text-gray-300 text-xs">
                                <HPrimeIcon icon={'pi-sync'} />
                                {t("UnderReview")}
                            </div>
                            <div className="min-w-5 h-5 rounded-full shadow bg-themeInformation text-xs dark:bg-themeDarkInformation text-white grid place-content-center">
                                <span>{props.progressCount}</span>
                            </div>
                        </div>
                    </div>
                    <div  className="hidden lg:flex items-center justify-center p-3 post-input dark:bg-themeDarkPostInput rounded-md">
                        <img src="/Assets/Image/ticket/01.svg" className="rounded-full"/>
                    </div>
                    <div  className="flex items-center justify-between p-3 post-input dark:bg-themeDarkPostInput rounded-md">
                        <div className="flex gap-2 items-center text-gray-500 dark:text-gray-300">
                            <HPrimeIcon icon={'pi-envelope'}/>
                            {t("support")}
                        </div>
                        <a href="tel:091010510640" className="text-xs p-2 text-center rounded bg-themeInformation dark:bg-themeDarkInformation text-white flex items-center gap-1 ">
                            <h2>{t("Call")}</h2>
                            <HPrimeIcon icon={'pi-headphones'} />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <FantasyModal openModal={IsDepartmentOpen}  closeModal={handleCloseDepartment} title={t("NewDepartment")} customeClassName={"modal-sm"}>
           <FastDepartment
               // reload={handleReload}
               onApiError={onApiError}
               handleLoading={handleBtnLoading}
               handleCloseModal={handleCloseDepartment}
               handleResetModal={handleResetModal}
               isOpen={IsDepartmentOpen}
           />
        </FantasyModal>

    </>
}