import React, {useEffect, useRef, useState} from "react";

import {useTranslation} from "react-i18next";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePackageAdd} from "../../GeneralManagement/Packages/HandlePackageAdd";
import {HandleTelegramQuickPurchase} from "../Messengers/HandleTelegramQuickPurchase";
import {HandlePicturesSettings} from "../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {HandleInstagramQuickPurchase} from "../Messengers/HandleInstagramQuickPurchase";
import FacebookIcon from "../Messengers/SocialIcon/FacebookIcon";
import {Tooltip} from "react-tooltip";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const SocialMediaPlatformAccount=({ imgIcon, title,handleOpenConnectAccount,platform})=>{
    const { t } = useTranslation();
    const handleConnectAccount=()=>{
        switch (platform) {
            case 0:
                handleOpenConnectAccount(platform);
            break;
            case 4:
                window.open('https://www.google.com','_blank')
        }
    }

    return (
        <>
            <div className={"flex flex-col justify-between post-input dark:bg-themeDarkPostInput messenger_accounts overflow-hidden post-input-design"}>
                <div className="flex flex-col items-center gap-5 px-8 py-8">
                    <div class="flex items-center">
                        {imgIcon}
                    </div>
                    <div className={"space-y-1 text-center"}>
                        <h2 class="dark:text-themeDarkInformation">{title}</h2>
                        <button className={"flex gap-1 items-center dark:text-gray-300 font-light"}>
                            <HPrimeIconSmall icon={"pi-question-circle"}/>
                            {t("ConnectionTraining")}
                        </button>
                    </div>
                </div>
                <div class=" w-full p-1">
                    <button className={"w-full button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation"} onClick={handleConnectAccount}>
                        {t("Connect")}
                    </button>
                </div>
            </div>

        </>


    );
}