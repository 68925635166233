import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {FantasyModal} from "../UIParts/FantasyModal";
import {Return} from "../Globals/Return";
import {ButtonPlus} from "../UIParts/ButtonPlus";
import ButtonVideo from "../UIParts/ButtonVideo";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";
import {InputTextarea} from "primereact/inputtextarea";

export const ListFilter =({ icon, label,childrenOne,childrenTwo,children, fieldsetClassName, legendClassName,iconClass,sizeIcon,divClassName,showChildrenOne = true,showChildrenTwo = true,showReturn =true ,showVideo=true ,Questions=true,FeedBack=true}) => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const location = useLocation();
    const [IsOpen,setIsOpen] = useState(false);
    const [IsOpenFeedBack,setIsOpenFeedBack] = useState(false);

    const handleIsOpenFeedBack=()=>{
        setIsOpenFeedBack(true);
    }
    const handleIsCloseFeedBack=()=>{
        setIsOpenFeedBack(false);
    }

    const handleIsOpen=()=>{
        setIsOpen(true);
    }
    const handleIsOpenClose=()=>{
        setIsOpen(false);
    }

    const GoToDocument=(IsNew)=>{
        const {pathname} = location;
        const newPathname = pathname.replaceAll('/','-').slice(1);
        IsNew?
            redirectTo(`/Admin/ProjectDocument?slug=${newPathname}`)
            :
           redirectTo(`/Admin/ProjectDocument/List/History?slug=${newPathname}`);
    }

    const GoToHelp=()=>{
        const {pathname} = location;
        const newPathname = pathname.replaceAll('/','-').slice(1);
        redirectTo(`/Harmony/Help?slug=${newPathname}`);
    }




  return(
      <>

          <fieldset className={`post-input dark:bg-themeDarkPostInput post-input-design pt-5 pb-2 mt-3 grid ${fieldsetClassName}`}>
              <button  onClick={()=>handleIsOpen()} className={"H_Helper border dark:border-themeDarkColorLine p-1 px-3 flex gap-1 items-center bg-themeInformation dark:bg-themeDarkInformation text-white rounded-md"}>
                  <HPrimeIcon icon={` text-xs dark:text-gray-300  ${icon ? icon:"pi-code"}`} iconClass={iconClass} sizeIcon={sizeIcon} />
                  <p className={"text-xs dark:text-gray-300"}>{t('ProjectDocument')}</p>
              </button>
              <legend className={`border dark:border-themeDarkColorLine p-1 px-3 flex gap-1 items-center dark:bg-themeDarkPostInput text-white rounded-md  H_legend_class ${legendClassName}`}>
                  <HPrimeIcon icon={` text-xs dark:text-gray-300  ${icon ? icon:"pi-bolt"}`} iconClass={iconClass} sizeIcon={sizeIcon} />
                  <p className={"text-xs dark:text-gray-300"}>
                      {
                          label?label:t("QuickAccess")
                      }
                  </p>
              </legend>
              <div className={`flex gap-2 flex-col md:flex-row ${divClassName}`}>
                  {showChildrenOne && (
                      <div className="flex items-center justify-start flex-wrap gap-3 min-w-[9rem] px-3">
                          {showReturn && (
                              <Return />
                          )}

                          {childrenOne}
                          {showVideo && (
                              <ButtonVideo />
                          )}
                          {Questions && (
                              <ButtonPlus
                                  onClick={()=>GoToHelp()}
                              icon={"pi-question"}
                              title={t("Guide")}
                              tooltipTitle={t("FrequentlyQuestions")}
                              />
                          )}

                          {FeedBack && (
                              <ButtonPlus
                                  onClick={()=>handleIsOpenFeedBack()}
                                  icon={"pi-thumbs-up"}
                                  title={t("Feedback")}
                                  tooltipTitle={t("Feedback")}
                              />
                          )}

                      </div>
                  )}
                  {/*<div className="flex items-center justify-start flex-wrap gap-3 min-w-[9rem] px-3">*/}
                  {/*    <Return />*/}
                  {/*    {childrenOne}*/}
                  {/*    <ButtonVideo/>*/}
                  {/*</div>*/}
                  {showChildrenTwo && (
                      <div className="grid flex-1 grid-cols-12">
                          <div className="overflow-x-auto scrollbar col-span-12">
                              <div className="flex gap-5 filter_min_w_7 p-2">
                                  {childrenTwo}
                              </div>
                          </div>
                      </div>
                  )}

              </div>
          </fieldset>

          <FantasyModal openModal={IsOpen} closeModal={handleIsOpenClose} title={t("Guide")} >
              <div>
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-2 ">
                      <div>
                          <button type={'button'} onClick={()=>GoToDocument(false)} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("WatchDocument")}
                          </button>
                      </div>
                      <div>
                          <button type={'button'} onClick={()=>GoToDocument(true)}  className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("NewDocument")}
                          </button>
                      </div>
                  </div>
              </div>
          </FantasyModal>
          <FantasyModal openModal={IsOpenFeedBack} closeModal={handleIsCloseFeedBack} title={t("Feedback")} >
              <div className={"space-y-5"}>
                  <p className={"text-xs dark:text-gray-300"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis corporis cum eius eum, hic in, itaque, magnam mollitia non pariatur perferendis perspiciatis possimus quia quisquam rem ullam ut velit voluptatum?</p>

                  <InputTextarea className={"input w-full"} rows={5}/>
                  <div class="md:col-span-2">
                      <button type={'button'} onClick={()=>GoToDocument(true)}  className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("Register")}
                      </button>
                  </div>

              </div>
          </FantasyModal>

  </>)
}