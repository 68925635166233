import React, {useEffect, useRef, useState} from "react";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {InputTextarea} from "primereact/inputtextarea";
import { handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";

import {useLocation, useNavigate} from "react-router-dom";

import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import queryString from "query-string";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {personalDataSchema} from "./PersonalDataSchema";

import {useTranslation} from "react-i18next";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {convertPersonalData, CreatePersonalFormData} from "./informationService";
export const HandlePersonalData = (props)=>{
    const { t } = useTranslation();
const redirectTo = useNavigate();
    const location = useLocation();
    const [selectOptions,setSelectOptions] = useState({Groups:[],Countries:[],Provinces:[],Cities:[],cityValue:null,provinceValue:null,countryValue:null})
    const [errors, setErrors] = useState({});
    const queryParams = queryString.parse(window.location.search);

    const [result,setResult] = useState(0);
    const initial = {Id:null ,ConcurrencyStamp:null,Group:[],FatherName:null,Nationality:null,CityId:null, Education:null,Job:null,Picture:null, Description:null,PostalCode:null,Address:null,PicturePath:null}
    const [privateData,setPrivateData] = useState(initial);
    const [spinner,setSpinner] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const handleFileValue = (file,name)=>{
        setChange(true);
        setPrivateData({...privateData,[name]:file});
    }
    const[isChanged,setChange] = useState(false);
    const handleValue=(e)=>{
        const {name,value} = e.target;
        const newValue = value.trim() === "" ? null : value;
        setPrivateData({...privateData,[name]:newValue});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
       setChange(true);
    }

    const onApiError = () => {
        setSpinner(false);
    };
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }




    const selectValueHandler=(data,name)=>{
        setPrivateData({ ...privateData, [name]: data });
        setChange(true)
    }

    const removeFile=(path,name)=>setPrivateData((prevState)=>({...prevState,[name]:path}))

    const handleFetchData = async () => {
        try {
            setSpinner(true);
            const responseGroup = await GET(`/UserGroups/Selection`,{},'IEnumerable');
            const responseCountry = await GET(`/Countries/Selection`,{},'IEnumerable');
            if (responseGroup.status === 200 && responseCountry.status === 200) {
                const Groups = convertSelectDataHandler(responseGroup.data);
                const countries = convertSelectDataHandler(responseCountry.data);
                setSelectOptions((prevOptions) => ({
                    ...prevOptions,
                    Groups: Groups,
                    Countries: countries,
                    countryValue: countries[0]
                }));

                const queryParams = queryString.parse(window.location.search);

                if (queryParams.id) {
                    setIsEdit(true);
                     const response = await GET(`/Users/PersonalData/${queryParams.id}`,{},'single');
                       if (response.status === 200) {

                           if(response.data.stateProvinceId){
                               const filteredCountry = countries.find((item) => item.value === response.data.countryId);
                               setSelectOptions((prevOptions) => ({...prevOptions, countryValue: filteredCountry,}));
                               const responseProvince = await GET(`/Countries/${response.data.countryId}/StateProvinces/Selection`);
                               const provinces = convertSelectDataHandler(responseProvince.data);
                               const filteredProvince = provinces.find((item) => item.value === response.data.stateProvinceId);

                               setSelectOptions((prevOptions) => ({...prevOptions, provinceValue: filteredProvince,}));

                               const responseCity = await GET(`/Countries/${response.data.stateProvinceId}/Cities/Selection`);
                               const cities = convertSelectDataHandler(responseCity.data);
                               const filteredCity = cities.find((item) => item.value === response.data.cityId);

                               setPrivateData({
                                   Id:response.data.id,
                                   Job: response.data.job,
                                   Nationality: response.data.nationality,
                                   Education: response.data.education,
                                   FatherName: response.data.fatherName,
                                   Description: response.data.description,
                                   CityId: filteredCity,
                                   Address: response.data.address,
                                   Picture: response.data.picture,
                                   PostalCode: response.data.postalCode,
                                   ConcurrencyStamp:response.data.concurrencyStamp,
                                   Group: response.data.groupIds.map((value) => Groups.find((item) => item.value === value)),
                               });
                           }
                           else{
                               setPrivateData({
                                   Id:response.data.id,
                                   Job: response.data.job,
                                   Nationality: response.data.nationality,
                                   Education: response.data.education,
                                   FatherName: response.data.fatherName,
                                   Description: response.data.description,
                                   CityId:null,
                                   Address: response.data.address,
                                   Picture: response.data.picture,
                                   PostalCode: response.data.postalCode,
                                   ConcurrencyStamp:response.data.concurrencyStamp,
                                   Group: response.data.groupIds.map((value) => Groups.find((item) => item.value === value)),
                               });
                           }

                       }else {
                           handleApiError(response,onApiError);
                       }
                }
            } else {
                handleApiError(responseGroup,onApiError);
                handleApiError(responseCountry,onApiError);
            }
        } catch (e) {
            console.log(e)
        } finally {
            setSpinner(false);
        }
    };

    useEffect(()=>{
        handleFetchData();
    },[])


    const formRef = useRef(null);
    const [nextTab,setNextTab] = useState('');
     const handlePersonalDataSubmit = async (e)=>{
         e.preventDefault();
         const isValid = validate(personalDataSchema,privateData,onSetErrors);
         if(!isValid){
             return;
         }
         if(isChanged === true){
             try {
                 const convertedData = convertPersonalData(privateData,queryParams.id || null);
                 const formData = CreatePersonalFormData(convertedData)
                 setSpinner(true);
                 const response = await POST('/Users/PersonalData/Add',formData,true);
                 if(response.status === 200){
                     setIsEdit(false);
                     setResult({...response.status})
                     setSpinner(false)
                     handleSuccessMessage(t("Informationhassuccessfully"));
                     if(document.activeElement === formRef.current.elements.register){
                         if(location.pathname === '/CRM/User/Add'){
                             redirectTo('/CRM/User/List');
                             return;
                         }
                     }
                     if(document.activeElement === formRef.current.elements.previous){
                         props.handleTabClick(nextTab);
                         return;
                     }
                     if(document.activeElement === formRef.current.elements.next){
                         props.handleTabClick(nextTab);
                         return;
                     }
                 }
                 else{
                     props.handleTabClick("personal");
                     handleApiError(response,onApiError);
                 }
             }
             catch (e) {
                 props.handleTabClick("personal");
                 onApiError();
                 console.log(e);
             }
         }
         else{
             if(document.activeElement === formRef.current.elements.previous){
                props.handleTabClick(nextTab);
                return;
             }
             if(document.activeElement === formRef.current.elements.next){
                 props.handleTabClick(nextTab);
                 return;
             }
             if(document.activeElement === formRef.current.elements.register){
                 redirectTo('/CRM/User/List');
                 return;
             }
         }
         props.handleTabClick(nextTab);
     }

     const handleChangeCounty= async (data)=>{
         if (!data) {
             return;
         }
       try {
           setSpinner(true)
           const response = await GET(`/Countries/${data.value}/StateProvinces/Selection`,{},'IEnumerable');
           const provinces = convertSelectDataHandler(response.data)
           if(response.status === 200){
               setSelectOptions((prevOptions) => ({...prevOptions,Provinces: provinces }));
               setSpinner(false);
               setResult({...response.status})
           }else{
               handleApiError(response,onApiError);
           }
           setChange(true)
           setSelectOptions((prevOptions) => ({
               ...prevOptions,
               countryValue: data,
           }));
       }catch (e) {
           console.log(e);
       }
     }
     const handleChangeProvince=async (data)=>{
         if (!data) {
             return;
         }
       try {
           setSpinner(true);
           const response = await GET(`/Countries/${data.value}/Cities/Selection`,{},'IEnumerable');
           const cities = convertSelectDataHandler(response.data)
           if(response.status === 200){
               setSelectOptions((prevOptions) => ({...prevOptions, Cities: cities }));
               setSpinner(false);
               setResult({...response.status})
           }else{
               handleApiError(response,onApiError);
           }
           setChange(true)
           setSelectOptions((prevOptions) => ({
               ...prevOptions,
               provinceValue: data,
           }));
       }catch (e) {
           console.log(e);
       }
     }


    return (
        <>

            <form onSubmit={handlePersonalDataSubmit} className={'relative'} ref={formRef}>
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className={"space-y-5"}>
                    <div class="grid grid-cols-12 gap-4 p-2">
                        <div class="col-span-12 lg:col-span-3 post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                            <div class="flex justify-center items-center  h-48 w-full lg:h-full">
                                <div className="size-full bg-white dark:bg-themeDarkPostInput">
                                    <div className="size-full">
                                        <UploadImage  isEdit={isEdit} removeFile={removeFile} removeName={'PicturePath'}   accept={'image/*'} OnValueSelection={handleFileValue} value={privateData.Picture} name={'Picture'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-span-12 lg:col-span-9 space-y-2">
                            <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                <h2 className={"dark:text-gray-300"}>
                                    {t("UsersInformation")}
                                </h2>
                            </div>
                            <div className={"px-2"}>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 p-2 post-input post-input-design dark:bg-themeDarkPostInput">
                                    <div className="space-y-1 col-span-1 md:col-span-2 lg:col-span-4">
                                        <label className="dark:text-gray-300 text-xs">{t("Group")}</label>
                                        <Select
                                            options={selectOptions.Groups}
                                            isClearable={false}
                                            isMulti={true}
                                            isSearchable={true}
                                            onChange={(data)=>selectValueHandler(data,'Group')}
                                            name={'Group'}
                                            placeholder={t("selection")}
                                            value={privateData.Group}
                                            className={"select-group"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div className="space-y-1 ">
                                        <label className="dark:text-gray-300 text-xs">{t("fatherName")}</label>
                                        <InputText className="input w-full" value={privateData.FatherName} name={'FatherName'} onInput={(e)=>handleValue(e)} />
                                    </div>
                                    <div className="space-y-1 ">
                                        <label className="dark:text-gray-300 text-xs">{t("occupation")}</label>
                                        <InputText className="input w-full" name={'Job'} onInput={(e)=>handleValue(e)}  value={privateData.Job} />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="dark:text-gray-300 text-xs">{t("nationality")}</label>
                                        <InputText className="input w-full" name={'Nationality'} onInput={(e)=>handleValue(e)}  value={privateData.Nationality} />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="dark:text-gray-300 text-xs">{t("country")}</label>
                                        <Select
                                            options={selectOptions.Countries}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleChangeCounty(data)}
                                            placeholder={t("selection")}
                                            value={selectOptions.countryValue}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="dark:text-gray-300 text-xs">{t("province")}</label>
                                        <Select
                                            options={selectOptions.Provinces}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleChangeProvince(data,'Provinces')}
                                            name={'Provinces'}
                                            placeholder={t("selection")}
                                            value={selectOptions.provinceValue}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="dark:text-gray-300 text-xs">{t("city")}</label>
                                        <Select
                                            options={selectOptions.Cities}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>selectValueHandler(data,'CityId')}
                                            name={'CityId'}
                                            placeholder={t("selection")}
                                            value={privateData.CityId}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="dark:text-gray-300 text-xs">{t("education")}</label>
                                        <InputText className="input w-full" name={'Education'} onInput={(e)=>handleValue(e)}  value={privateData.Education} />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="dark:text-gray-300 text-xs">{t("MaritalStatus")}</label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>

                                    <div class="space-y-1 md:col-span-2 lg:col-span-3">
                                        <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                                        <InputText className="input w-full"  name={'Address'} value={privateData.Address} onInput={(e)=>handleValue(e)} />
                                    </div>
                                    <div className="space-y-1 lg:col-span-1">
                                        <label className="dark:text-gray-300 text-xs">{t("postalCode")}</label>
                                        <InputText type={'text'} className="input w-full" name={'PostalCode'} onInput={(e)=>handleValue(e)}  value={privateData.PostalCode} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={"space-y-3"}>
                        <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                            <h2 className={"dark:text-gray-300"}>
                                {t("additionalDetails")}
                            </h2>
                        </div>
                        <div class="px-2">
                            <div className={"p-2 post-input dark:bg-themeDarkPostInput post-input-design"}>
                                <InputTextarea name={"Description"} value={privateData.Description} onInput={(e) => handleValue(e)} rows={3} cols={30} className={"input w-full "}/>
                            </div>
                        </div>
                    </div>
                    <div class=" flex flex-wrap items-center justify-center lg:justify-end  gap-2">
                        <button name={'previous'}  onClick={()=>setNextTab("register")} className="button w-24 bg-themeDanger text-white">{t("previous")}</button>
                        <button type={'submit'} name={'register'} className="button w-24  text-white bg-themeSuccess">{t("register")}</button>
                        <button type={'submit'} name={'next'} onClick={()=>setNextTab('company')} className="button text-white bg-themeInformation dark:bg-themeDarkInformation">{t("registerAndNext")}</button>
                    </div>
                </div>

            </form>
        </>
    )
}