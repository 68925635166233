import React, {useState} from "react";
// import '../../../../Styles/Internal/setting.css'



import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";


const SettingLink = ({ to, title }) => (
    <Link to={to} className="-intro-x dark:text-gray-300 flex items-center gap-2 setting-hover p-1">
        <span className="w-3 h-3 block rounded-full border bg-themeDarkInformation dark:border-themeDarkColorLine"></span>
        <h2>{title}</h2>
    </Link>
);

const SettingSection = ({ title, links }) => (
    <div className="space-y-1">
        <div>
            <h2 className="text-[1rem] text-themeInformation dark:text-themeDarkInformation">{title}</h2>
        </div>
        <div className="space-y-1">
            {links.map((link, index) => (
                <SettingLink key={index} {...link} />
            ))}
        </div>
    </div>
);


export const HandleMasterSetting2 = () => {
    const { t } = useTranslation();





    const Attendance = [
        {to: '/Harmony/Setting/Attendance', title: t("Basicsettings") },
    ];

    const Public = [

        {to: '/Harmony/Setting/Main/Schema', title: t("CodeSchema")},
        {to: '/Harmony/Setting/Main/MetaTag',title: t("MetaTag")},

    ];


  return(
     <div className="box bg-white dark:bg-themeDarkSalt700">
         <div className="p-3 bg-gray-200 rounded-t-md dark:bg-themeDarkPostInput dark:text-gray-300 flex gap-2">
             <HPrimeIcon icon={'pi-cog'} />
             <h2>{t("settings")}</h2>
         </div>
         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 p-5">
             <div className="space-y-2">
                 <SettingSection title={t("AttendanceSystem")} links={Attendance} />
                 <SettingSection title={t("GeneralSettings")} links={Public} />
             </div>
         </div>
     </div>
  )
}