import React, { useEffect, useState } from "react";

export const useShortCut = (initialSource) => {
    const [hotkeys, setHotkeys] = useState([]);

    const disallowedKeys = [
        "CapsLock", "Backspace", "-", "+", "=", "_", "~", "`", "Enter", "Fn", "Tab", "Escape", "NumLock", "Esc", "Pause", "Meta", "÷",
    ];

    const mapKeyCodeToEnglishKey = (keyCode, key) => {
        const keyCodeMap = {
            KeyA: "A", KeyB: "B", KeyC: "C", KeyD: "D", KeyE: "E", KeyF: "F", KeyG: "G", KeyH: "H", KeyI: "I", KeyJ: "J", KeyK: "K",
            KeyL: "L", KeyM: "M", KeyN: "N", KeyO: "O", KeyP: "P", KeyQ: "Q", KeyR: "R", KeyS: "S", KeyT: "T", KeyU: "U", KeyV: "V",
            KeyW: "W", KeyX: "X", KeyY: "Y", KeyZ: "Z",
        };
        return keyCodeMap[keyCode] || key;
    };


    const handleInitialHotkeys = (processData) => {
        setHotkeys(prevHotkeys => [
            ...prevHotkeys,
            {
                value: '',
                isRecording: false,
                keyCombo: [],
                process: processData[0],
                to:'',
                key:"",
                isShift:false
            },
        ]);
    };




    const handleKeyDown = (event, index) => {
        const currentHotkey = hotkeys[index];
        if (!currentHotkey.isRecording || currentHotkey.keyCombo.length >= 3) return;

        setHotkeys(prevHotkeys =>
            prevHotkeys.map((hotkey, idx) => {
                if (idx === index) {
                    return { ...hotkey, guide: true, errorMessage: false };
                }
                return hotkey;
            })
        );

        event.preventDefault();

        const keyMapped = mapKeyCodeToEnglishKey(event.code, event.key);

        let keyNormalized = keyMapped === " " ? "Space" : keyMapped;
        keyNormalized = keyNormalized.length === 1 ? keyNormalized.toUpperCase() : keyNormalized;

        if (currentHotkey.keyCombo.length === 0) {
            if (!["Control", "Alt", "Shift"].includes(keyNormalized)) {
                setHotkeys(prevHotkeys =>
                    prevHotkeys.map((hotkey, idx) => {
                        if (idx === index) {
                            return { ...hotkey, errorMessage: true, guide: false };
                        }
                        return hotkey;
                    })
                );
                return;
            }
        }

        if (keyNormalized === "Control") keyNormalized = "Ctrl";
        if (disallowedKeys.includes(keyNormalized)) {
            setHotkeys(prevHotkeys =>
                prevHotkeys.map((hotkey, idx) => {
                    if (idx === index) {
                        return { ...hotkey, errorMessage: false, guide: true };
                    }
                    return hotkey;
                })
            );
            return;
        }

        if (
            currentHotkey.keyCombo.includes(keyNormalized) ||
            (currentHotkey.keyCombo.includes("Ctrl") && keyNormalized === "Alt") ||
            (currentHotkey.keyCombo.includes("Alt") && keyNormalized === "Ctrl")
        ) {
            setHotkeys(prevHotkeys =>
                prevHotkeys.map((hotkey, idx) => {
                    if (idx === index) {
                        return { ...hotkey, errorMessage: true, guide: false };
                    }
                    return hotkey;
                })
            );
            return;
        }

        if (
            currentHotkey.keyCombo.length === 1 &&
            currentHotkey.keyCombo[0] === "Shift" &&
            (keyNormalized === "Ctrl" || keyNormalized === "Alt")
        ) {
            currentHotkey.keyCombo[0] = keyNormalized;
            keyNormalized = "Shift";
        }

        if (
            currentHotkey.keyCombo.length === 1 &&
            currentHotkey.keyCombo[0] === "Shift" &&
            (keyNormalized !== "Ctrl" || keyNormalized !== "Alt")
        ) {
            setHotkeys(prevHotkeys =>
                prevHotkeys.map((hotkey, idx) => {
                    if (idx === index) {
                        return { ...hotkey, errorMessage: true, guide: false };
                    }
                    return hotkey;
                })
            );
            return;
        }

        const newKeyCombo = [...currentHotkey.keyCombo, keyNormalized];
        setHotkeys(prevHotkeys =>
            prevHotkeys.map((hotkey, idx) => {
                if (idx === index) {
                    return { ...hotkey, keyCombo: newKeyCombo, value: newKeyCombo.join(" + ") };
                }
                return hotkey;
            })
        );

        if (
            currentHotkey.keyCombo.length === 1 &&
            currentHotkey.keyCombo[0] !== "Shift" &&
            !["Ctrl", "Alt", "Shift"].includes(keyNormalized)
        ) {
            setHotkeys(prevHotkeys =>
                prevHotkeys.map((hotkey, idx) => {
                    if (idx === index) {
                        return { ...hotkey, isRecording: false, errorMessage: false, guide: false };
                    }
                    return hotkey;
                })
            );
        } else if (
            currentHotkey.keyCombo.length === 2 &&
            currentHotkey.keyCombo[1] === "Shift" &&
            !["Ctrl", "Alt", "Shift"].includes(keyNormalized)
        ) {
            setHotkeys(prevHotkeys =>
                prevHotkeys.map((hotkey, idx) => {
                    if (idx === index) {
                        return { ...hotkey, isRecording: false, errorMessage: false, guide: false };
                    }
                    return hotkey;
                })
            );
        }
    };

    const handleClick = (index) => {
        setHotkeys(prevHotkeys =>
            prevHotkeys.map((hotkey, idx) => {
                if (idx === index) {
                    return { ...hotkey, isRecording: true, errorMessage: false, guide: false, value: "", keyCombo: [],to:'',key:"",isShift:false };
                }
                return hotkey;
            })
        );
        document.getElementById(`input-${index}`).focus();
    };

    const handleChange = (index, data, fieldName) => {
        setHotkeys((prevHotkeys) =>
            prevHotkeys.map((hotkey, idx) => {
                if (idx === index) {
                    return { ...hotkey, [fieldName]: data };
                }
                return hotkey;
            })
        );
    };

    const handleBlur = (index) => {
        setHotkeys(prevHotkeys =>
            prevHotkeys.map((hotkey, idx) => {
                if (idx === index) {
                    const updatedHotkey = { ...hotkey, isRecording: false, errorMessage: false, guide: false };
                    // Ensure `key` property is updated in case `handleChange` did not run
                    const keyComboArray = updatedHotkey.value.split(" + ");
                    updatedHotkey.key = keyComboArray[keyComboArray.length - 1];
                    updatedHotkey.isShift = updatedHotkey.value.includes("Shift");

                    return updatedHotkey;
                }
                return hotkey;
            })
        );

        const currentHotkey = hotkeys[index];

        if (currentHotkey.keyCombo.length === 1) {
            setHotkeys(prevHotkeys =>
                prevHotkeys.map((hotkey, idx) => {
                    if (idx === index) {
                        return { ...hotkey, keyCombo: [], value: "" };
                    }
                    return hotkey;
                })
            );
        }

        if (
            currentHotkey.keyCombo.length === 2 &&
            currentHotkey.keyCombo.every(key => ["Ctrl", "Alt", "Shift"].includes(key))
        ) {
            setHotkeys(prevHotkeys =>
                prevHotkeys.map((hotkey, idx) => {
                    if (idx === index) {
                        return { ...hotkey, keyCombo: [], value: "" };
                    }
                    return hotkey;
                })
            );
        }
    };

    useEffect(() => {
        const handleWindowKeyDown = (event) => {
            hotkeys.forEach((hotkey, index) => {
                if (hotkey.isRecording) {
                    handleKeyDown(event, index);
                }
            });
        };

        window.addEventListener("keydown", handleWindowKeyDown);

        return () => {
            window.removeEventListener("keydown", handleWindowKeyDown);
        };
    }, [hotkeys]);

    const addNewHotkey = () => {
        setHotkeys(prevHotkeys => [...prevHotkeys, { value: "", isRecording: false, keyCombo: [], process: initialSource.processes[0],to:'',key:"",isShift:false }]);
    };

    const removeHotkey = (index) => {
        if (hotkeys.length === 1) {
            setHotkeys([{ value: "", isRecording: false, keyCombo: [], process:initialSource.processes[0],to:'' ,key:"",isShift:false }]);
            return;
        }
        setHotkeys(prevHotkeys => prevHotkeys.filter((_, idx) => idx !== index));
    };

    return {
        removeHotkey,
        addNewHotkey,
        handleBlur,
        handleClick,
        mapKeyCodeToEnglishKey,
        hotkeys,
        setHotkeys,
        handleChange,
        handleInitialHotkeys
    };
};
