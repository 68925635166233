import React, {useState} from "react";


export const useGeneralSettings=(setErrors)=>{

    const handleGetValueTheme=(theme)=>{
        switch (theme) {
            case 0:
                return false
            case 1:
                return true
            default:
                return false
        }
    }
    const [setting, setSetting] = useState({
        title: null,
        description: null,
        keywords: [],
        currency: 0,
        countryId:null,
        culture: 0,
        direction: 0,
        fontFamily: null,
        fontSize: 0,
        calendarType: 0,
        calendarLanguage: 0,
        firstDayOfWeek: 0,
        calendarView: 0,
        editor: 0,
        watermarkText: null,
        watermarkPosition: 0,
        theme: 0,
        watermarkFontSize:0,
        concurrencyStamp:null,
        watermarkActivation:false,
        watermarkFontColor:'6466f1',
        watermarkBackgroundColor:'121212'
    });

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setSetting({...setting,[name]:value});
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }))
    }
    const handleSelectValue=(data,name)=>{
        setSetting({...setting,[name]: data});
    }
    const handleKeywordsChange = (keywords) => {
        setSetting((prevState) => ({ ...prevState,keywords: keywords }));
    };

    return {
        setting,
        setSetting,
        handleValue,
        handleNumberValue,
        handleSelectValue,
        handleKeywordsChange,
        handleGetValueTheme
    }
}