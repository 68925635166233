import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../UIParts/HPrimeIconSmall";


export const  GridButtonDelete =({ icon, onClick,iconClass,className}) => {


    const { t } = useTranslation();



    return(
      <>

          <button className={` hbtn_delete grid place-content-center ${className} `}
              onClick={onClick}
          >
              <HPrimeIcon icon={`${icon ? icon:"pi-trash"}`} iconClass={"text-themeDanger"}  />
          </button>


  </>)
}