export const convertEditTemplate = (obj,id)=>{
    const newData = {
        Id:id !== null?id:null,
        Name:obj.Name === null || obj.Name === undefined?null:obj.Name,
        Type: obj.Type === null || obj.Type === undefined ?null: obj.Type.value,
        DefaultTemplate: obj.DefaultTemplate === null || obj.DefaultTemplate === undefined?null: obj.DefaultTemplate.value,
        RootFolder:obj.RootFolder === null || obj.RootFolder === undefined?null:obj.RootFolder,
        Banner:obj.Banner === null || obj.Banner === undefined ?null:obj.Banner,
        File:obj.File === null || obj.File === undefined ?null:obj.File
    };
    return newData
}

export const convertAddTemplate = (obj)=>{
    const newData = {
        Name:obj.Name === null || obj.Name === undefined?null:obj.Name,
        Type: obj.Type === null || obj.Type === undefined ?null: obj.Type.value,
        DefaultTemplate: obj.DefaultTemplate === null || obj.DefaultTemplate === undefined?null: obj.DefaultTemplate.value,
        RootFolder:obj.RootFolder === null || obj.RootFolder === undefined?null:obj.RootFolder,
        Banner:obj.Banner === null || obj.Banner === undefined ?null:obj.Banner,
        File:obj.File === null || obj.File === undefined ?null:obj.File
    };
    return newData
}

export const CreateTemplateFormData =(obj)=>{
    const formData = new FormData();
    for (const key in obj) {
        const value = obj[key];
        if(value !== null && value !== undefined){
                formData.append(key,value);
            }
        }
       return formData;
}

export const convertAddPage = (obj,templateId)=>{
    const newData = {
        name:obj.name === null || obj.name === undefined ? null:obj.name,
        type:obj.type === null || obj.type === undefined?null:obj.type.value,
        stateShow: obj.stateShow,
        slug:obj.slug === null || obj.slug === undefined?null:obj.slug,
        fileName:obj.fileName === null || obj.fileName === undefined ? null : obj.fileName,
        isMain:obj.isMain.value === 0?true:false,
        templateId:templateId,
        masterLayoutId: obj.masterLayoutId === null || obj.masterLayoutId === undefined? null: obj.masterLayoutId.value,
        direction: obj.direction === null || obj.direction === undefined? null: obj.direction.value,
    };
    return newData
}

export const convertEditPage = (obj,templateId,id)=>{
    const newData = {
        name:obj.name === null || obj.name ===  undefined?null:obj.name,
        type:obj.type === null?null:obj.type.value,
        stateShow: obj.stateShow ,
        slug:obj.slug === null || obj.slug === undefined?null:obj.slug,
        fileName:obj.fileName === null || obj.fileName === undefined ? null : obj.fileName,
        isMain:obj.isMain.value === 0?true:false,
        templateId:templateId,
        id:id,
        masterLayoutId: obj.masterLayoutId === null || obj.masterLayoutId === undefined? null: obj.masterLayoutId.value,
        direction: obj.direction === null || obj.direction === undefined? null: obj.direction.value,
    };
    return newData
}


