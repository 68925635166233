import React, {useState} from "react";

export const useCustomUpgrade=()=>{
    const [upgrade,setUpgrade]=useState(
        {currencyType:0
            ,extraHost:{extraHost:0,price:0,step:0,maxValue:0,minValue:0}
            ,extraLanguage:{extraLanguage:0}
            ,extraMailHost:{extraMailHost:0,price:0,step:0,maxValue:0,minValue:0}
            ,extraPackages: {packageIds:[]},
            extraTemplates: {templateMasterIds:[]},
            creationDate:null})
   // handleUpgradeValue('extraHost', { extraHost: newValue });
    const handleValue = (name, value) => {
        setUpgrade(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                ...value
            }
        }));
    };
    const handleSelectValue = (data,name,isMain) => {
        if(isMain){
            setUpgrade({...upgrade,[name]:data});
        }
        else{
            setUpgrade(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    ...data
                }
            }));
        }
    };
    const handleChangePackageId=(packageId)=>{
        const { extraPackages } = upgrade;
        const packageIds = extraPackages.packageIds || [];
        const index = packageIds.indexOf(packageId);
        if (index !== -1) {
            packageIds.splice(index, 1);
        } else {
            packageIds.push(packageId);
        }
        setUpgrade(prevState => ({
            ...prevState,
            extraPackages: {
                ...prevState.extraPackages,
                packageIds: packageIds
            }
        }));
    }

    const handleDateTime = (name, dateTime,isMain) => {
        if(isMain){
            setUpgrade({...upgrade,[name]:dateTime});
        }
        else{
            setUpgrade(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    ...dateTime
                }
            }));
        }

    };


    const handleGetCurrentType=(currencies,currencyType)=>{
        if(currencies.length === 0){
            return '-'
        }
        const currency =  currencies.find((item) => item.value === currencyType);
        return currency.label;
    }

    const [price,setTotalPrice]=useState({price:0,currency:0});
    const handleUpdateTotalPrice = (currency,upgrade) => {
        if(upgrade.extraPackages.packageIds.length === 0){
            return 0;
        }
        const totalPackagePrice = upgrade.extraPackages.packageIds.reduce((acc, pkg) => acc + pkg.price, 0);
        setTotalPrice(prevState => ({
            price: totalPackagePrice,
            currency: currency
        }));
    };
    return {upgrade,handleValue,setUpgrade,handleSelectValue,handleChangePackageId,handleDateTime,handleGetCurrentType,price,handleUpdateTotalPrice}
}