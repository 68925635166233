export const convertFastRegisterHandler = (obj)=>{
    const newObj = {
        id:obj.id !== null ? obj.id:null,
        userCode: obj.userCode === null ||obj.userCode == undefined?null:obj.userCode,
        registerDate: obj.registerDate??null,
        renewalDate: obj.renewalDate??null,
        sex: obj.sex === null || obj.sex === undefined?null: obj.sex.value,
        leadsType: obj.leadsType === null || obj.leadsType === undefined?null: obj.leadsType.value,
        fullName: obj.fullName === null || obj.fullName === undefined? null:obj.fullName,
        mobilePhoneNumber: obj.mobilePhoneNumber === null || obj.mobilePhoneNumber === undefined?null:obj.mobilePhoneNumber,
        homePhoneNumber:obj.homePhoneNumber === null || obj.homePhoneNumber === undefined?null:obj.homePhoneNumber,
        email:obj.email === null || obj.email === undefined ? null:obj.email,
        introductionMethod: obj.introductionMethod === null || obj.introductionMethod === undefined?null: obj.introductionMethod.value,
        username: obj.username === null || obj.username === undefined?null:obj.username,
        password: obj.password === null || obj.password === undefined?null:obj.password,
        roles:obj.roles !== null? obj.roles.map(item => item.value):null,
        presenterCode:obj.presenterCode === null || obj.presenterCode === undefined?null:obj.presenterCode,
        nationalCode:obj.nationalCode === null || obj.nationalCode === undefined?null:obj.nationalCode,
        concurrencyStamp:obj.concurrencyStamp === null || obj.concurrencyStamp === undefined? null:obj.concurrencyStamp
    }
    return newObj
}

export const validatePresenterCode = (code) => {
    var isValid = /^\d+$/.test(code); // Use a regular expression to test code
    return isValid;
}
export const handleConvertUserRoles = (data)=>{
    const options  = data.map(item => ({label: item.value, value: item.key,roleType:item.roleType}));
    return options;
}
