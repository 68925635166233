import React from "react";


const FieldCreator = ({type,label,addElement,typeName,t,isButton}) => {
    return (
        <>
            {
                isButton?
                    <button
                        className=" text-white button bg-themeInformation dark:bg-themeDarkInformation text-xs"
                        onClick={() => addElement(type,typeName)}
                    >
                        {label}
                    </button>
                    :null
            }

        </>

    );
};

export default FieldCreator;
