import React, { useState } from "react";
import {useRef} from "react";
import {useEffect} from "react";

import {decodeAccessToken, GetSpecialProperty, GetSpecialRole, RemoveAccessToken} from "../../../Services/AxiosService/JwtService";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {useDispatch} from "react-redux";
import {setIsLoading} from "../../../Services/Redux/loadingReducer";
export const AccountMenu = () => {
    const { t } = useTranslation();
    // this one is used to handle opening and closing menu
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const redirectTo = useNavigate();
    const dispatch = useDispatch();

    // this props driling and we gotta pass the name of user to this component

    // this function toggling the menu
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    // this one handles whereever you click on any part of screen , it toggles up .
    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);



    // logs user out and clear jwt token
    const logout = async () => {
        try {
            setIsOpen(false);
            dispatch(setIsLoading(true));
            const response = await POST('/Authentication/Logout',{},false);
            if(response.status === 200){
                //dispatch(setIsLoading(false));
                RemoveAccessToken();
                redirectTo('/');
            }else{
                handleApiError(response)
            }
        }catch (e) {
            console.log(e);
            dispatch(setIsLoading(false));
        }
    };

    return (
        <div className="dropdown relative" ref={menuRef}>
            <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden user-profile cursor-pointer" onClick={toggleMenu} >
                <img src={GetSpecialProperty('Avatar')} className={"w-full h-full"} alt="Profile" onClick={()=>setIsOpen(!isOpen)} />
            </div>
            <div className={`dropdown-box absolute w-56 top-10 end-0 z-20  ${isOpen === true ? "show" : ''}`}>
                <div className="dropdown-box__content text-white box bg-themeInformation dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine overflow-hidden">
                    <div className="p-2 border-b dark:border-themeDarkColorLine flex gap-2">
                        <div className="w-8 h-8 rounded-full overflow-hidden shadow-lg "  >
                            <img src={GetSpecialProperty('Avatar')} className={"w-full h-full"} alt="Profile" />
                        </div>
                        <div>
                            <div className="text-xs line-clamp-1">{GetSpecialProperty('DisplayName')}</div>
                            <div className="text-xs line-clamp-1">{GetSpecialRole()}</div>
                        </div>

                    </div>
                    <div className="p-2">
                        <Link to="/Support/OnlineTicket" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-xs" >
                            <i className={"pi pi-ticket primIcon_FontSize"}></i>
                            <p className={"text-xs"}>{t("Tickets")}</p>
                        </Link>
                        <Link to="/CRM/User/Wallet" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-xs" >
                            <i className={"pi pi-wallet primIcon_FontSize"}></i>
                            <p className={"text-xs"}>{t("Wallet")}</p>

                        </Link>

                        <Link to="/Harmony/Setting/Main/ChangePassword" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-xs">
                            <i className={"pi pi-lock primIcon_FontSize"}></i>
                            <p className={"text-xs"}>{t("ChangePassword")}</p>

                        </Link>
                        <Link to="/Harmony/Master/setting" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-xs">
                            <i className={"pi pi-cog primIcon_FontSize"}></i>
                            <p className={"text-xs"}>{t("settings")}</p>

                        </Link>
                        <Link to="#" className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 text-xs" >
                            <i className={"pi pi-info-circle primIcon_FontSize"}></i>
                            <p className={"text-xs"}> {t("Guide")}</p>

                        </Link>
                    </div>
                    <div className="p-2 border-t dark:border-themeDarkColorLine">
                        <button onClick={()=>logout()} className="flex items-center p-2 hover:bg-themeHoverInformation rounded-md gap-2 w-full">
                            <i className={"pi pi-power-off text-xl"}></i>
                            <p> {t("Logout")}</p>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
};