import React from "react";

import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {useNavigate} from "react-router-dom";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const LoginRequiredAccount=({name,createdAt,profilePicture,platformName})=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();

    return (
        <>
            <div className={" flex flex-col justify-between post-input dark:bg-themeDarkPostInput post-input-design messenger_accounts overflow-hidden"}>
                <div className=" flex flex-col items-center gap-5 p-8">
                    <div class=" flex items-center">
                        <div className={"size-20 flex-shrink-0 rounded-full border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine overflow-hidden"}>
                            <img className="size-full object-cover" alt="harmony" src={profilePicture}/>
                        </div>
                    </div>
                    <div className={"space-y-1 text-center"}>
                        <h2 class="dark:text-themeDarkInformation line-clamp-1">{name}</h2>
                        <p class="text-xs text-gray-500 dark:text-gray-300 line-clamp-1">{platformName}</p>
                        <p class="text-xs text-gray-500 dark:text-gray-300 font-light line-clamp-1">
                            <GridDateFomartter date={createdAt} hasTime={false}/>
                        </p>
                    </div>
                </div>
                <div class="w-full p-1">
                    <button className={"w-full button bg-white dark:bg-themeDarkSalt700 flex items-center justify-center gap-2 border border-themeInformation dark:border-themeDarkInformation text-xs dark:text-gray-300"} onClick={() => redirectTo("/SocialMarketing/Messengers/List/Page")}>
                        <HPrimeIconSmall
                        icon={"pi-info-circle"}
                        />
                        {t("SelectPage")}
                        <HPrimeIconSmall
                            icon={"pi-chevron-left"}
                        />
                    </button>
                </div>
            </div>


        </>
    )
}