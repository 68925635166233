import React, {useState} from "react";


export const useCleanBinder=(domainTokens)=>{

    const [cleanData,setClean] = useState({section:[],domainTokens:[],fromDate:null,toDate:null})

    // handles dateTime for fromDate
    const handleFromDate=(dateTime)=>{
        setClean(prevState => ({...prevState,fromDate: dateTime}));
    }
    //handles dateTime for ToDate
    const handleToDate=(dateTime)=>{
        setClean(prevState => ({...prevState,toDate: dateTime}));
    }
    // updating sections
    const handleChangeSection = (selectedItem) => {
        setClean((prevState) => {
            const isItemExist = prevState.section.some((item) => item.value === selectedItem.value);
            if (isItemExist) {
                return {
                    ...prevState,
                    section: prevState.section.filter((item) => item.value !== selectedItem.value)
                };
            } else {
                return {
                    ...prevState,
                    section: [...prevState.section, selectedItem]
                };
            }
        });
    };
    // handles domainTokens
    const handleChangeDomainToken=(data)=>{
        if (data.some((item) => item.value === '*')) {
            const updatedDomainTokens = domainTokens
                .filter((item) => item.value !== '*') // Exclude item with value '*'
                .map((item) => ({
                    ...item,
                    // Update other properties as needed
                }));
            setClean((prevState) => ({
                ...prevState,
                domainTokens: updatedDomainTokens
            }));
            return;
        }
        setClean((prevState)=>({...prevState,domainTokens: data}))
    }

    //resets data initially
    const ResetCleanData=()=>{
        setClean({toDate: null,fromDate: null,domainTokens: [],section: []})
    }




    return {
        cleanData,
        setClean,
        handleFromDate,
        handleToDate,
        handleChangeDomainToken,
        handleChangeSection,
        ResetCleanData
    }
}