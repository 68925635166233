import React, {useState} from "react";
import {convertSlugHandler} from "../../Contents/ContentService";
import {useTranslation} from "react-i18next";


export const useDataBinders=(setErrors)=>{

    const {t} = useTranslation();
    const [surveyData, setSurveyData] = useState({
        id: null,
        filterId: null,
        title: null,
        slug: null,
        access: null,
        limitationType: null,
        limitVote: null,
        fromDate: null,
        toDate: null,
        sendingStates: [],
        stateAndroid: false,
        stateGoogle: false,
        stateShow: false,
        stateShowResult: false,
        templateId: null,
    });






    const handleSelectValue = (data, name) => {
        setSurveyData({ ...surveyData, [name]: data });
    };

    const handleFromDate = (dateTime)=> setSurveyData({...surveyData,fromDate: dateTime})

    const handleToDate=(dateTime)=>setSurveyData({...surveyData,toDate: dateTime})

    const InputSwitchValueHandler = (data,name)=>{
        setSurveyData((prevState) => ({
            ...prevState,
            [name]: data,
        }))
    }

    const handleValue = (e) => {
        const {name,value } = e.target;
        if (name === "title") {
            const slugValue = convertSlugHandler(value);
            setSurveyData((prevContent) => ({ ...prevContent, slug: slugValue }));
        }
        setSurveyData((prevContent) => ({ ...prevContent, [name]: value }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };



    const switchData = [
        { name: 'stateGoogle', label: t("GoogleMap"), value: surveyData.stateGoogle,customClass:'' },
        { name: 'stateShow', label: t("DisplayStatus"), value: surveyData.stateShow,customClass: '' },
        { name: 'stateAndroid', label: t("SendToAndroid"), value: surveyData.stateAndroid,customClass: '' },
        { name: 'stateShowResult', label: t("ShowResult"), value: surveyData.stateShowResult,customClass: '' },
    ];

    return {
        handleFromDate,
        handleValue,
        handleToDate,
        handleSelectValue,
        InputSwitchValueHandler,
        surveyData,
        setSurveyData,
        switchData
    }

}