import {InputText} from "primereact/inputtext";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {InputTextarea} from "primereact/inputtextarea";

import {Dialog} from "primereact/dialog";
import {ButtonNotification} from "../../../Commans/UIParts/ButtonNotification";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {InputNumber} from "primereact/inputnumber";




export const Single=({singleIndex,singleItem,handleChangeSurveyItems,item})=>{
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const handleShowMessage =(visible,message)=>{
        setVisible(visible);
    }

    const headerElement = (
        <div className="flex items-center  gap-2">
            <HPrimeIcon icon={"pi-exclamation-circle"} iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
            <span className=" white-space-nowrap">{t("Quickguide")}</span>
        </div>
    );

    return (
        <>
            <Dialog header={headerElement} visible={visible}  className={"Dialog-NotifText"} onHide={() => setVisible(false)} >
                <p>{t("ThisItemWillBePolledInWritingByTheUser")}</p>
            </Dialog>

            <div key={singleIndex} className=" p-2 dark:bg-themeDarkSalt700 items-center space-y-3 shaow relative harmony_survey_box">
                <div className="space-y-1 flex gap-3 items-center">
                    <div className={"w-full md:w-20"}>
                        <InputNumber
                            className=""
                            type={"text"}
                            value={singleItem.linkedSurveyRaw}
                            onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,singleItem.fakeId,singleItem.id, 'linkedSurveyRaw', e.value)}
                            name={'linkedSurveyRaw'}
                            showButtons
                        />
                    </div>
                    <div>
                        <p className="text-xs dark:text-gray-300 flex items-center gap-2">
                            {t("nextquestion")}
                            <ButtonNotification
                                onClick={() => handleShowMessage(true)}
                            />
                        </p>
                    </div>
                </div>
                <div className="space-y-1">
                    <InputTextarea
                        className="input w-full text-xs"
                        name={'surveyItemName'}
                        disabled={true}
                        placeholder={t("Gettingwrittentext")}
                    />
                </div>

            </div>
        </>

    )
}
