import React, {useState} from "react";

export const useCustomWebsite=(setErrors)=>{
    const [website,setWebsite] = useState({type:null,id:null,domainToken:null,password:null,userId:null,ownerName:'',newPassword:null,concurrencyStamp:null});
    const [IsShowPassword,setShowPassword] = useState(false);
    const [IsShowCurrentPassword,setCurrentShowPassword] = useState(false);
    const handleSelectValue=(data,name)=>{
        setWebsite({ ...website, [name]: data });
    }

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setWebsite({...website,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    return {
        website,
        setWebsite,
        handleSelectValue,
        handleValue,
        IsShowPassword,
        setShowPassword,
        IsShowCurrentPassword,
        setCurrentShowPassword
    }
}