import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const useCartableTab=(handleResetFilters)=>{
    const { t } = useTranslation();
    const [tabId, setTabId] = useState("form");
    const handleChangeTabId = (tabId) => {
        setTabId(tabId);
        handleResetFilters();
    };

    const tabs = [
        {
            id: "form",
            label:t("MyForms"),
            tooltipTitle:t("MyForms"),

        },
        {
            id: "notes",
            label:t("Note"),
            tooltipTitle:t("Note"),
        },
        {
            id: "reminder",
            label:t("isReminder"),
            tooltipTitle:t("isReminder"),
        },

        {
            id: "Complaint",
            label:t("Complaint"),
            tooltipTitle:t("Complaint"),
        },
        {
            id: "Feedback",
            label:t("Feedback"),
            tooltipTitle:t("Feedback"),
        },

    ];
    return {
        tabs,
        tabId,
        handleChangeTabId
    }


}