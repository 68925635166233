import React, {useMemo, useState} from "react";
import {MultiDataSelect} from "../Commans/UIParts/MultiDataSelect";
import {InputText} from "primereact/inputtext";
import {ListSearch} from "../Commans/Grid/ListSearch";

import {ListFilter} from "../Commans/Grid/ListFilter";
import {GridTable} from "../Commans/Grid/GridTable";
import {Button} from "primereact/button";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../Commans/Grid/GridButtonOprations";
export const HandleSendboxPaperLess = () => {
  const { t } = useTranslation();

  const [isMenuOpen,setMenuOpen] = useState(false);
  const [userId,setUserId] = useState('');
  const handleOpenMenu = () => {
    setMenuOpen(true);

  };
  const handleCloseMenu = ()=>{
    setMenuOpen(false);
  }


  const [modal, setModal] = useState({ seeLetter: false });


  const seeLetterOnHide = () => {
    setModal((prevState) => ({ ...prevState, seeLetter: !modal.seeLetter }));
  };


  const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

  const columnDefs = [
    { headerName: 'ردیف', field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
    { headerName: 'عنوان نامه',
      cellRenderer:parameters=>
          <h2 class="line-clamp-1 cursor-pointer" onClick={() => setModal({ seeLetter: true })}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto aspernatur autem consequatur esse expedita, facilis ipsa iusto minus odio omnis pariatur ratione. Aliquam animi assumenda commodi debitis dolores eum eveniet id inventore ipsa laudantium libero modi molestiae natus non quidem sapiente sit, vel velit vero voluptatibus. At blanditiis consequuntur doloremque expedita maxime natus nihil odit, officiis pariatur quas velit, vero! Ab adipisci aliquam deserunt ducimus enim excepturi expedita facere harum impedit inventore ipsam iusto labore laudantium maiores nam, neque nihil obcaecati odio officiis omnis, porro quae quam ratione reiciendis sed soluta vel velit veritatis vitae voluptatum. Impedit modi qui repellendus!
          </h2>
    },
    { headerName: 'گیرنده-فرستنده', maxWidth: 140,
      cellRenderer:parameters=>
          <h2 className="line-clamp-1">
            ارسال شده
          </h2>
    },


    { headerName: 'تاریخ ', field: 'date',width: 130, maxWidth: 130,},

    { headerName: 'شماره اندیکاتور', maxWidth: 160,
      cellRenderer:parameters=>
          <h2 className="line-clamp-1">44334433</h2>
    },

    { headerName: 'پاسخ ', maxWidth: 140,
      cellRenderer:parameters=>
          <a href="/PaperLess/Inbox/Replay" className="table_Btn zoom-in bg-themeSuccess dark:bg-themeSecondary text-white gap-1 text-xs justify-center" > <HPrimeIcon size={20} icon={'corner-right-up'} />پاسخ</a>
    },

    { headerName: t("settings"),width: 110, maxWidth: 140,
      cellRenderer:params => {
        return<div className={'flex justify-center items-center gap-2 '} >
          <GridButtonEdit/>
          <GridButtonDelete/>
          <GridButtonOprations
              onClick={()=>handleOpenMenu()}
          />
        </div>
      }

    },

  ];

  const rowData = [

    { id:1, date:'1402/10/3'},
    { id:2, date:'1402/10/3'},
    { id:3, date:'1402/10/3'},
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      filter: true,
      sortable: true,
      wrapText: true,
      resizable: true,
      // resizable: true,
      // editable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const onSelectionChanged=(data)=>{
    console.log(data);
  }
  const onCellValueChanged = (data)=>{
    console.log(data);
  }


  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };



  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = (event) => {
    setQuickFilterText(event.target.value);
  };


  return(

      <>
        <div class=" space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 ">
            <div className="space-y-5">

              <ListFilter label={null} icon={null} divClassName={"grid space-y-2"}>
                <div className="overflow-x-auto scrollbar ">
                  <div className="flex gap-5 p-3 filter_min_w_7">
                    <div className=" space-y-2 flex flex-col items-center">
                      <lable className="text-xs dark:text-gray-300">شش ماه به انقضاء</lable>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                    <div class=" space-y-2 flex flex-col items-center">
                      <lable className="text-xs dark:text-gray-300">یک سال به انقضاء</lable>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                    <div class=" space-y-2 flex flex-col items-center">
                      <lable className="text-xs dark:text-gray-300">موجود</lable>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                    <div class="space-y-2 flex flex-col items-center">
                      <lable className="text-xs dark:text-gray-300">ناموجود</lable>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                    <div class=" space-y-2 flex flex-col items-center">
                      <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                    <div class=" space-y-2 flex flex-col items-center">
                      <lable className="text-xs dark:text-gray-300">محصولات پرفروش</lable>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                    <div class=" space-y-2 flex flex-col items-center">
                      <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                      <input className="input input--switch border bg-white" type="checkbox"/>
                    </div>
                  </div>
                </div>
              </ListFilter>
            </div>
          </div>
          <div>
            <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                       enableRtl={true}
                       pagination={true}
                       getRowHeight={getRowHeight}
                       onGridReady={onGridReady}
                       quickFilterText={quickFilterText}
                       style={{ width: '100%', height: '100%' ,position:'relative' }}
            />
          </div>
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
              <div className="sm:col-span-2">
                <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                  <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Pdf"></span>
                    <h2>خروجی (Pdf)</h2>
                  </Button>
                  <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Excel"></span>
                    <h2>خروجی (Exel)</h2>
                  </Button>
                  <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Word"></span>
                    <h2>خروجی (Word)</h2>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
            <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
              <a href="#" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem] justify-center" > <HPrimeIcon size={20} icon={'printer'} />ارجاع</a>
              <a href="#" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem] justify-center" > <HPrimeIcon size={20} icon={'printer'} />مسیر نامه</a>
            </div>
        </FantasyModal>
        <FantasyModal openModal={modal.seeLetter}  closeModal={seeLetterOnHide} >
          <div>
            <div class="flex items-center justify-between border-b border-dashed dark:border-themeDarkColorLine">
              <div>
                <h2 >فرستنده: <span class="text-themeInformation dark:text-themeDarkInformation">خانم مازندرانی</span></h2>
              </div>
              <div>
                <p class="text-xs dark:text-gray-300">1400/01/21</p>
              </div>
            </div>
            <div className="p-2">
              <p class="text-xs leading-7 text-justify dark:text-gray-300">با سلام، احتراما باستحضار میرساند جهت تمدید فاکتور سالیانه فروشگاه اکسیرمن به آدرس
                exirman.com هرچه سریعتر اقدام نمایید. در صورت عدم پیگیری و انقضای هاست و دامین های ذکر شده در فاکتور
                فوق گروه نرم افزاری آرین هیچ مسئولیتی در قبال از دست رفتن اطلاعات وب سایت شما ندارد. </p>
            </div>
          </div>
        </FantasyModal>
      </>
  )

}