import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import { useNavigate} from "react-router-dom";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {useExchangeBinder} from "./useExchangeBinder";
import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
import {validate} from "../../../Services/Globals/validate";
import {exchangeSchema} from "./exchangeSchema";
import {handleAddExchangeRate, handleEditExchangeRate} from "./exchageService";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {ToastContainer} from "react-toastify";
import {useExchangeGridRate} from "./useExchangeGridRate";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";

import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

// import 'react-tooltip/dist/react-tooltip.css'

export const HandleExchangeRate=()=>{


    const redirectTo = useNavigate();
    const { t } = useTranslation();
    const [IsEdit,setIsEdit] = useState(false);
    const [showSpinner,setShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({currencies:[],destinationCurrencies:[]});
    const [IsExchange, setIsExchange] = useState({ isRate: false});
    const [refresh,setRefresh] = useState(0);
    const [errors,setErrors] = useState({});

    const {setExchange,exchange,handleSelectValue,handleValue,handleNumberValue}=useExchangeBinder(setErrors)

    // handles spinner in api response
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // handles errors
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    //refreshes component when it is called
    const Refresh=(state)=>{
        setRefresh({...state});
    }
    // resets all errors to nothing as initial state
    const handleClearErrors=()=>{
        setErrors({});
    }
    //separates dollar from currencies for destination currency
    const handleGetTargetCurrency=(sourceCurrency)=>{
        const currency = sourceCurrency.find((item)=>item.label === 'دلار' || item.label === 'dollar');
        return currency;
    }
    // opens modal
    const handleOpenExchangeRate=()=>{
        setIsExchange({isRate: true});
        const targetCurrency = handleGetTargetCurrency(initialSource.currencies);
        setExchange((prevState)=>({...prevState,currency: initialSource.currencies[0],destinationCurrency:targetCurrency,feeCurrency:initialSource.currencies[0]}));
    }
    // closes modal
    const handleCloseExchangeRate=()=>{
        setIsExchange({isRate:false});
        handleClearErrors();
        const targetCurrency = handleGetTargetCurrency(initialSource.currencies);
        setExchange((prevState)=>({...prevState,currency: initialSource.currencies[0],destinationCurrency:targetCurrency,feeCurrency:initialSource.currencies[0],fee: 0,rateOnDollar: 0,symbol: ''}));
        setIsEdit(false);
        redirectTo('/Admin/ExchangeRate/Add');
    }
    //handle initial data
    const handleInitialDate=(currencies)=>{
        const currencyData = convertSelectDataHandler(currencies);
        setInitialSource({currencies:currencyData,destinationCurrencies:currencyData});
    }

    // fetches all from server in initial time of component
    const fetchData=async()=>{
        try {
            setShowSpinner(true)
            const response = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
            if(response.status === 200){
                handleInitialDate(response.data);
                setShowSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError();
            console.log(e);
        }
    }

    useEffect(()=>{
        fetchData();
    },[])


    const handleSubmitExchangeRate= async (e)=>{
        e.preventDefault();
        try {
            var isValid = validate(exchangeSchema,exchange,onSetErrors);
            if(!isValid){
                return;
            }
            const isValidRate = exchange.feeCurrency.value === exchange.currency.value || exchange.feeCurrency.value === exchange.destinationCurrency.value;
            if(!isValidRate){
                handleFailureMessage(t('TheCommissionRateMustBeBetweenTheSourceORDestinationRate'));
                return;
            }
            const isValidCurrency = exchange.destinationCurrency.value !== exchange.currency.value;
            if(!isValidCurrency){
                handleFailureMessage(t("Thesourceanddestinationunitscannotbethesame"));
                return;
            }

            if(IsEdit){
                setShowSpinner(true);
                const exchangeData = handleEditExchangeRate(exchange);
                const response = await PUT(`/ExchangeMoney`,exchangeData,false);
                if(response.status === 200){
                    setShowSpinner(false);
                    handleSuccessMessage(t('ExchangeRateEditedSuccessfully'));
                    handleCloseExchangeRate();
                    Refresh(response.status);
                }
            }
            else{
              setShowSpinner(true);
              const exchangeData = handleAddExchangeRate(exchange);
              const response = await POST(`/ExchangeMoney`,exchangeData,false);
              if(response.status === 200){
                  setShowSpinner(false);
                  handleSuccessMessage(t('ExchangeRateRegisteredSuccessfully'));
                  handleCloseExchangeRate();
                  Refresh(response.status);
              }
              else{
                  handleApiError(response,onApiError);
              }

            }
        }
        catch (e) {
            onApiError();
            console.log(e);
        }
    }

    const {autoGroupColumnDef,columnDefs,currentPage,data,defaultColDef,handleCurrentPage,handleNextPage,handleSearch,handlePreviousPage,handlePageSizeCount,handleEditExchange,handleRemoveExchange,handleFilterValue,filterValueHandler,setFilter,resetFilters,SwitchFilters,filter,rowData,setSearch,search,getRowHeight} = useExchangeGridRate(setShowSpinner,fetchData,refresh,onApiError,initialSource,setExchange,setIsExchange,setIsEdit,Refresh)





    return(<>

        <div class=" space-y-5 relative">
            {
                showSpinner?
                    <div className="huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }


            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("CurrencyUnit")}</label>
                            <Select
                                options={initialSource.currencies}
                                isSearchable={true}
                                onChange={(data)=>handleFilterValue(data,'currency')}
                                placeholder={t("selection")}
                                value={filter.currencyValue}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                    <ButtonSearchList
                        onClickSearch={()=>handleSearch()}
                        onClickReset={()=>resetFilters()}
                    />
                </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => handleOpenExchangeRate()}
                                        tooltipTitle={t("ExchangeRate")}
                                    />

                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />

            </div>
            <div className={"space-y-2 H-body-ag-grid "} >
                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridEnglish}
                />
                <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
            </div>
            <Export apiUrl={''} filters={filter}/>
        </div>

        <FantasyModal openModal={IsExchange.isRate}  closeModal={handleCloseExchangeRate} title={t("ExchangeRate")} >
         <form onSubmit={handleSubmitExchangeRate} method={'post'}>
             <div className="space-y-3">
                 <div class="flex items-end gap-3 ">
                     <div class="space-y-1 flex-1">
                         <label className="text-xs dark:text-gray-300">{t("SourceCurrency")}</label>
                         <Select
                             options={initialSource.currencies}
                             isMulti={false}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'currency')}
                             placeholder={t("selection")}
                             value={exchange.currency}
                             classNamePrefix={"h_multi_select"}
                             menuPosition={"fixed"}
                         />
                     </div>
                     <div className={"pb-2 flex-shrink-0"}>
                         <HPrimeIcon icon={"pi-arrow-right-arrow-left"} iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
                     </div>
                     <div class="space-y-1 flex-1">
                         <label className="text-xs dark:text-gray-300">{t("DestinationCurrency")}</label>
                         <Select
                             options={initialSource.currencies}
                             isMulti={false}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'destinationCurrency')}
                             placeholder={t("selection")}
                             isDisabled={true}
                             value={exchange.destinationCurrency}
                             classNamePrefix={"h_multi_select"}
                             menuPosition={"fixed"}
                         />
                     </div>
                 </div>
                 <div className={"grid grid-cols-1 md:grid-cols-2 gap-2 "}>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("ExchangeRate")}</label>
                         <InputNumber type={"text"}  name={'rateOnDollar'} min={0} value={exchange.rateOnDollar} onChange={(e)=>handleNumberValue(e.value,'rateOnDollar')} />
                     </div>
                     <div class="space-y-2">
                         <div className={"flex justify-between items-center "}>
                             <label className="text-xs dark:text-gray-300">
                                 {t("Symbol")}
                                 <RequiredIcon/>
                             </label>
                             <DisplayError message={errors.symbol}/>
                         </div>
                         <InputText value={exchange.symbol} name={'symbol'} onInput={(e)=>handleValue(e)} type={"text"} className={"input w-full"} />
                     </div>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t("CommissionRate")}</label>
                         <InputNumber type={"text"} name={'fee'}  min={0} value={exchange.fee} onChange={(e)=>handleNumberValue(e.value,'fee')} />
                     </div>
                     <div class="space-y-1">
                         <label className="text-xs dark:text-gray-300">{t('commisionCurrency')}</label>
                         <Select
                             options={initialSource.currencies}
                             isMulti={false}
                             isSearchable={true}
                             onChange={(data)=>handleSelectValue(data,'feeCurrency')}
                             placeholder={t("selection")}
                             value={exchange.feeCurrency}
                             classNamePrefix={"h_multi_select"}
                             menuPosition={"fixed"}
                         />
                     </div>
                 </div>
                 <button className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
             </div>
         </form>
        </FantasyModal>
        <ToastContainer/>

       </>)
}