import React, {useState} from "react";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";

export const useCustomWithdraw=(setErrors,hideSpinner,showSpinner)=>{
    const [wallet,setWallet] = useState({amount:0,userId:null,description:null,walletBalance:0,currencyType:0,userBankId:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setWallet({...wallet,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setWallet({...wallet,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=async (data,name)=>{
        if(name === 'userBankId'){
            setWallet({...wallet,userBankId: data});
            return;
        }
        try {
            showSpinner();
            const responseBalance = await GET(`/Wallets/${data.value}/Balance`,{},'single');
            if(responseBalance.status === 200){
                const {balance} = responseBalance.data;
                setWallet({ ...wallet, [name]: data,walletBalance: balance.amount,currencyType: balance.currency });
                hideSpinner();
            }
            else{
                handleApiError(responseBalance,hideSpinner)
            }

        }catch (e) {
            console.log(e);
        }

    }

    return {
        wallet,
        setWallet,
        handleValue,
        handleNumberValue,
        handleSelectValue
    }
}