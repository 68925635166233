import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useLogErrorsTab=()=>{
    const { t } = useTranslation();

    const [tabId, setTabId] = useState("StackTrace");
    const handleChangeTabId = (tabId) => {
        setTabId(tabId);
    };
    const [tabs,setTabs]=useState([
        {
        id: "StackTrace",
        target: "#StackTrace",
        label: 'StackTrace',
        },
        {
            id: "Header",
            target: "#Header",
            label: 'Header',

        },
        {
            id: "Connection",
            target: "#Connection",
            label: 'Connection',
        },

        {
            id: "Variables",
            target: "#Variables",
            label: 'Server Variables',

        },

    ])


    return {
        tabs,
        setTabs,
        tabId,
        handleChangeTabId

    }


}