import React, {useEffect, useState} from "react";
import {DefaultDashboard} from "./DefaultDashboards/DefaultDashboard";
import {GET} from "../../Services/AxiosService/AxiosApiService";
import {createSettingsUrl, handleGetSettingValue} from "../CMS/Contents/ContentService";
import {HandleDashboardDeveloperManagement} from "./DashboardDeveloperManagement/HandleDashboardDeveloperManagement";
import {useSelector} from "react-redux";

export const Dashboard=()=>{

    const [dashboardId,setDashboardId]=useState(null);
    const dashboardType = useSelector((state) => state.setting.dashboardType);

    const settingNames = [
        'Dashboard',
    ];
    const fetchData=async ()=>{

        if(dashboardType === null){
            const settingsUrl = createSettingsUrl('/Settings', settingNames);
            try {
                const response = await GET(settingsUrl,{},'single');
                const dashboardValue = handleGetSettingValue('Dashboard',response.data.settings);
                setDashboardId(dashboardValue);
            }
            catch (e) {
                console.log(e);
            }
        }
        else{
            setDashboardId(dashboardType);
        }

    }
    useEffect(()=>{
        fetchData();
    },[])

    return (
        <>
            {
                dashboardId === 3?
                    <DefaultDashboard/>:
                dashboardId === 8?
                <HandleDashboardDeveloperManagement/>
                : null

            }
        </>



    )
}