import React, {useEffect, useMemo, useState} from "react";
import {GridTable} from "../../Commans/Grid/GridTable";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";

import {menuSchema} from "./menuSchema";
import Swal from "sweetalert2";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {AgGridPersion} from "../../../Services/Globals/Translations/Resources";
import {useTranslation} from "react-i18next";
import {Pagination} from "../../Commans/Grid/Pagination";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {Return} from "../../Commans/Globals/Return";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {validate} from "../../../Services/Globals/validate";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandleMenuManagement = ()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const[result,setResult] = useState(0);
    const [spinner,setSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [modalMenu,setModalMenu] = useState(false);
    const [menu,setMenu] = useState({id:null,name:null});

    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }


    const handleCloseMenu = ()=>{
        setModalMenu(false);
        setErrors({})
    }

    const handleValue = (e) => {
        const { name, value } = e.target;
        setMenu({ ...menu, [name]: value });
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const handleSubmitGroupMenu=async (e)=>{
        e.preventDefault();
        const isValid = validate(menuSchema,menu,onSetErrors);
        if (!isValid) {
            return;
        }
        try {
          setSpinner(true);
          const response = await POST(`/MainMenuGroups`,{name:menu.name},false);
          if(response.status === 200){
              setResult({...response.status});
              setSpinner(false);
              setModalMenu(false);
              setMenu({name: ''});
              handleSuccessMessage(t("Grouphasbeensuccessfullyadded"));
          }else {
              handleApiError(response,onApiError)
          }
        }
        catch (e) {
            console.log(e);
        }
    }
    const onCellValueChanged = async (row)=>{
        try {
            const{id,name} = row;
            const response = await PUT(`/MainMenuGroups`,{id:id,name:name},false);
            if(response.status === 200){
                setResult({...response.status});
            }
        }catch (e) {
            console.log(e);
        }
    }



    const handleRemoveMainMenuGroup = async (groupId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`/MainMenuGroups/${groupId}`);
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("Title"), minWidth: 140,field:'name',editable:true},
        { headerName: t("Serial"), minWidth: 140,field:'number'},
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <button className={'text-themeInformation dark:text-themeDarkInformation'} onClick={()=>redirectTo(`/Public/MainMenu/List/Add?mainMenuGroupId=${params.data.id}`)}><HPrimeIcon icon={'pi-th-large'}  /></button>
                    <GridButtonDelete
                        onClick={()=>handleRemoveMainMenuGroup(params.data.id)}
                    />
                </div>
            }
        },

    ];





    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);



    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };


    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,

        };
    }, []);



    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:''})
    const[search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }


    const resetFilters = ()=>{
        setFilter(({...filter,search:''}));
        setSearch('');
    }


    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    // handleFetchData(); // Remove await if handleFetchData is not asynchronous
                }
                else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [result,filter.search,currentPage,data.pageSize]);


    const [rowData,setRowData] = useState([]);

    const OnGridReady = async () => {
        setSpinner(true)

        try {
            const response =  await GET(`/MainMenuGroups`, {PageNumber: currentPage, PageSize: data.pageSize,Search:filter.search}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    }















    return (



        <>

                <div class=" space-y-5 relative">
                    {

                        spinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }

                    <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                        <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                    <label className="text-xs dark:text-gray-300">{t("SearchByNameGroup")}</label>
                                    <InputText type="search" value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className="input w-full"  />
                                </div>
                            <ButtonSearchList
                                onClickSearch={()=>handleSearch()}
                                onClickReset={()=>resetFilters()}
                            />
                        </ListSearch>
                        <ListFilter label={null} icon={null}
                                    showChildrenTwo={false}
                                    childrenOne={
                                        <>
                                            <ButtonPlus
                                                onClick={() =>setModalMenu(true)}
                                                tooltipTitle={t("CreatingMenu")}
                                            />
                                        </>
                                    }
                        />

                    </div>
                    <div class="space-y-2 H-body-ag-grid">
                        <GridTable
                            columnDefs={columnDefs}
                            rowSelection={GridSetting.rowSelection}
                            onCellValueChanged={(row) => onCellValueChanged(row)}
                            rowData={rowData}
                            style={GridSetting.gridStyle}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            pagination={GridSetting.pagination}
                            enableRtl={GridSetting.enableRtl}
                            domLayout={GridSetting.domLayout}
                            paginationPageSize={GridSetting.pageSize}
                            getRowHeight={getRowHeight}
                            localeText={AgGridPersion}
                        />
                        <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    </div>


                </div>

            <FantasyModal openModal={modalMenu}  closeModal={handleCloseMenu} title={t("CreatingMenu")} customeClassName={"modal-sm"}>
                <form method={'post'} onSubmit={handleSubmitGroupMenu}>
                    <div className="space-y-5">
                        <div class="space-y-1 ">
                            <div className={"flex justify-between items-center "}>
                                <label className="text-xs dark:text-gray-300">
                                    {t("MenuTitle")}
                                    <RequiredIcon/>
                                </label>
                                <DisplayError message={errors.name}/>
                            </div>
                            <InputText  type="text" name={'name'} value={menu.name}  onInput={(e)=>handleValue(e)} className="input w-full"  />
                        </div>
                        <div>
                            <button type={'submit'} className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("Register")}</button>
                        </div>
                    </div>
                </form>
            </FantasyModal>


        </>
    )
}