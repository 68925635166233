
import React from "react";
import {ItemFile} from "./ItemFile";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const BackupFiles = ({ ref, handleFileChange, handleRemove, recovery, handleRemoveFiles, contentType,t,key,label, }) => (
    <div className="space-y-5">
        <div className={"post-input dark:bg-themeDarkPostInput post-input-design p-2 grid grid-cols-1 md:grid-cols-2 items-end"}>
            <div key={key} class={"space-y-1"}>
                <label className={"text-xs  dark:text-gray-300"}>
                    {t(label)}
                </label>
                    <input
                        name="file"
                        id={"file"}
                        ref={ref}
                        multiple={true}
                        type="file"
                        accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                        onChange={(e) => handleFileChange(e, contentType)}
                        className={"block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white file:border-none dark:border-themeDarkColorLine file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 "}
                    />

            </div>
            {
                recovery[contentType].length > 0?
                    <div className={"flex justify-end"}>
                        <button type={'button'} onClick={()=>handleRemoveFiles(contentType,ref)} className="button flex justify-center gap-2 items-center bg-themeDanger text-white">
                            <HPrimeIcon icon={'pi-trash'} />
                            {t("DeleteAll")}
                        </button>
                    </div>

                    : null
            }
        </div>
        {recovery[contentType] && recovery[contentType].length > 0 && (
            <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 max_height20 post-input dark:bg-themeDarkPostInput post-input-design p-2"}>
                {recovery[contentType].map((file, index) => (
                    <ItemFile
                        contentRef={ref}
                        file={file}
                        index={index}
                        handleRemove={handleRemove}
                        contentType={contentType}
                    />
                ))}
            </div>
        )}

    </div>

);