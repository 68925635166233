import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {GridTable} from "../../Commans/Grid/GridTable";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {useNavigate} from "react-router-dom";
import { handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import queryString from "query-string";

import Swal from "sweetalert2";
import {redirectSchema} from "./redirectSchema";
import {convertAddSeoRedirect, convertEditSeoRedirect} from "./redirectService";
import Select from "react-select";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {ToastContainer} from "react-toastify";
import {Pagination} from "../../Commans/Grid/Pagination";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {Return} from "../../Commans/Globals/Return";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../Services/Globals/Translations/Resources";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../Services/Globals/validate";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";


export const HandleAddRedirect =()=>{
    const { t } = useTranslation();
    const [modal, setModal] = useState({ redirect: false ,AddRedirect:false });
    const [errors, setErrors] = useState({});
    const [options,setOptions] = useState({types:[]})
    const [isEdit,setIsEdit] = useState(false);
    const redirectTo = useNavigate();
    const[spinner,setSpinner] = useState(false);
    const[result,setResult] = useState(0)
    const [seo,setSeo] = useState({id:null,newUrl:null,oldUrl:null,type:0})

    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const handleCloseRedirect=()=>{
        setModal({redirect: false});
        setSeo({id: null,type: options.types[0],newUrl: '',oldUrl: ''});
        redirectTo('/SEO/Redirect/Add');
        setIsEdit(false);
    }


    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSeo({...seo,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setSeo({ ...seo, [name]: data });
    }

    const handleFetchData = async ()=>{
        try {
            setSpinner(true)
            const responseRedirect = await GET('/Enumerations/EnRedirectType',{},'IEnumerable');
            if ( responseRedirect.status === 200 )
            {
                const redirectTypes = convertSelectDataHandler(responseRedirect.data);
                setOptions((prevOptions) => ({...prevOptions,types: redirectTypes}));
                setSeo({type: redirectTypes[0]});
                setSpinner(false)
            }
            else {
                handleApiError(responseRedirect,onApiError)
            }
        } catch (error) {
            onApiError()
            console.error('Error fetching data:', error);
        }
    }

    const handleEditRecord = async(recordId) => {
        try {
            setIsEdit(true);
            setSpinner(true)
            const response = await GET(`/SeoRedirects/${recordId}`,{},'single');
            if(response.status === 200){
                setSeo({
                    id:response.data.id,
                    newUrl: response.data.newUrl,
                    oldUrl: response.data.oldUrl,
                    type: options.types.find((item) => item.value === response.data.type),
                });
                redirectTo(`/SEO/Redirect/Add?id=${recordId}`);
                setSpinner(false)
                setModal({AddRedirect: true})
            }else{
              handleApiError(response,onApiError)
            }
        }catch (error) {
            onApiError()
            redirectTo('/')
        }

    };


    const handleAddSeoRedirect =async (e)=> {
        e.preventDefault();
        if(isEdit){
            const isValid = validate(redirectSchema,seo,onSetErrors);
            if (!isValid) {
                return;
            }
          try {
              setSpinner(true)
              const queryParams = queryString.parse(window.location.search);
              const redirects = convertEditSeoRedirect(seo,queryParams.id);
              const response = await PUT('/SeoRedirects',redirects,false);
              if (response.status === 200) {
                  setSpinner(false);
                  setResult({...response.status});
                  handleCloseRedirect();
                  handleSuccessMessage(t("Linkeditedsuccessfully"));
              } else {
                  handleApiError(response,onApiError)
              }
          }catch (error) {
              redirectTo('/')
          }
        }
        else{
            const isValid = validate(redirectSchema,seo,onSetErrors);
            if (!isValid) {
                return;
            }
          try {
              setSpinner(true)
              const redirects = convertAddSeoRedirect(seo);
              const response = await POST('/SeoRedirects',redirects,false)
              if (response.status === 200) {
                  setSpinner(false);
                  handleCloseRedirect();
                  setResult({...response.status});
                  handleSuccessMessage(t("Yourlinkhassuccessfullyregistered"));
              } else {
                  handleApiError(response,onApiError)
              }
          }catch (error) {
              redirectTo('/')
          }
        }
    }

    const [isMenuOpen,setMenuOpen] = useState(false);
    const [seoId,setSeoId] = useState("");
    const handleMenuOpen = (id)=>{
        setMenuOpen(true);
        setSeoId(id);
    }
    const handleCloseMenu = ()=>{
        setMenuOpen(!isMenuOpen)
    }

    const handleRemoveSeoRedirect = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
              try {
                  setSpinner(true)
                  const response = await DELETE(`/SeoRedirects/${id}`);
                  if (response.status === 200) {
                      setSpinner(false)
                      setResult({...response.status});
                      handleSuccessMessage(t("Yourlinkhassuccessfullyremoved"))
                  }
                  else {
                      handleApiError(response,onApiError)
                  }
              }catch (error) {
                  redirectTo('/')
              }

            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},
        { headerName: t("OriginalLink"),field: 'oldUrl', minWidth: 140},
        { headerName: t("Linkredirect"),field: 'newUrl', minWidth: 140},
        { headerName: t("Typeredirect"),field: 'type', minWidth: 140},

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={() => handleEditRecord(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleRemoveSeoRedirect(params.data.id)}
                    />

                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setData(()=>({...data,pageSize: value}))
        setCurrentPage(1);

    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',IsPermanent:null,IsTemporary:null})
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,IsTemporary: null,IsPermanent: null,search: ''}));
        setSearch('');
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true)
                const response = await OnGridReady();
                if(response.status === 200){
                  await handleFetchData();
                    setSpinner(false)
                }
                else{
                    handleApiError(response,onApiError)
                }
            } catch (error) {
                redirectTo('/')
            }
        }
        loadData();
    }, [result,currentPage,filter.search,data.pageSize,filter.IsPermanent,filter.IsTemporary]);


    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            const response = await GET('/SeoRedirects',{PageSize:data.pageSize,PageNumber:currentPage,IsPermanent:filter.IsPermanent,IsTemporary:filter.IsTemporary,Search:filter.search},'IEnumerable');
            if (response.status === 200) {
                const totalPages = Math.ceil(response.data.totalCount / response.data.pageSize);
                setData(({...data,totalPages: totalPages,hasNextPage: response.data.hasNextPage,hasPreviousPage: response.data.hasPreviousPage,page: response.data.page,pageSize: response.data.pageSize,totalCount: response.data.totalCount}));
                setRowData(response.data.items);
                var idSequence = 1;
                response.data.items.forEach(function (item) {
                    item.idSequence = ((currentPage - 1 ) * data.pageSize) + idSequence++;
                });
                return response;
            }
            else {
                handleApiError(response,onApiError)
            }

        }catch (error) {
            redirectTo('/')
        }
    }


    return(
        <>
            <div class="space-y-5 relative">
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByAddress")}</label>
                                <InputText type="search" value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className="input w-full" />
                            </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />

                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => setModal({ AddRedirect: true })}
                                            tooltipTitle={t("Newredirect")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SEO/GoogleSiteMap`)}
                                            icon={"pi-google"}
                                            title={t("introductionToGoogle")}
                                            tooltipTitle={t("introductionToGoogle")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SEO/KeyWords`)}
                                            icon={"pi-book"}
                                            title={t("Keywords")}
                                            tooltipTitle={t("Keywords")}

                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitch OnValueSelection={filterValueHandler} name={'IsPermanent'} lable={t("Permanent")} />
                                        <InputSwitch OnValueSelection={filterValueHandler} name={'IsTemporary'} lable={t("Temporary")} />
                                    </>
                                }
                    />

                </div>
                <div className="space-y-2 H-body-ag-grid">

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
              <ToastContainer/>
            </div>


            <FantasyModal openModal={modal.AddRedirect} closeModal={handleCloseRedirect} title={t("Newredirect")}>
                <form method={'post'} onSubmit={handleAddSeoRedirect}>
                    <div class="space-y-5">
                        <div className="grid grid-cols-1 gap-3">
                            <div className="space-y-2">
                                <div className="flex justify-between">
                                    <label className="text-xs dark:text-gray-300">
                                        {t("OriginalLink")}
                                        <RequiredIcon/>
                                        </label>
                                    <DisplayError message={errors.oldUrl}/>
                                </div>
                                <InputText placeholder={t("PreviousLink")} name={'oldUrl'} value={seo.oldUrl} onInput={(e)=>handleValue(e)} type={"text"} className={" input w-full"} />
                            </div>
                            <div className="space-y-2">
                                <div className="flex justify-between">
                                    <label className="text-xs dark:text-gray-300">
                                        {t("Linkredirect")}
                                        <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.newUrl}/>
                                </div>
                                <InputText placeholder={t("NewLink")} name={'newUrl'} value={seo.newUrl} onInput={(e)=>handleValue(e)} type={"text"} className={" input w-full"} />
                            </div>
                            <div className="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("TitleLink")}</label>
                                <Select
                                    options={options.types}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'type')}
                                    placeholder={t("selection")}
                                    value={seo.type}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                        </div>
                        <div>
                            <button type={'submit'} className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("register")}</button>
                        </div>
                    </div>
                </form>
            </FantasyModal>
        </>


    )
}