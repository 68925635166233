import React, {useState} from "react";

export const useDataEntrance=()=>{

    const [elements,setElements]=useState([]);
    const handleChangeData = (index, property, value) => {
        setElements(prevElements => {
            const newElements = [...prevElements];
            newElements[index] = {
                ...newElements[index],
                data: {
                    ...newElements[index].data,
                    [property]:value,
                },
            };
            return newElements;
        });
    };

    const handlePropertyChange = (index, property, value) => {
        setElements(prevElements => {
            const newElements = [...prevElements];
            newElements[index] = {
                ...newElements[index],
                properties: {
                    ...newElements[index].properties,
                    [property]:value,
                },
            };
            return newElements;
        });
    };


     return {
         elements,
         setElements,
         handleChangeData,
         handlePropertyChange


     }
}