import Joi from "joi";

export const numbersSchema = Joi.object({
    fromNumber: Joi.string().required().trim().regex(/^\d+$/).messages({
        'any.required': 'شماره الزامیست',
        'string.empty': 'شماره الزامیست',
        'string.base':  ' شماره الزامیست',
        'string.pattern.base': 'شماره باید فقط شامل ارقام باشد'
    }),
    // username: Joi.string().required().trim().messages({
    //     'any.required': 'نام کاربری الزامیست ',
    //     'string.empty': 'نام کاربری الزامیست ',
    //     'string.base':  ' نام کاربری الزامیست '
    // }),
    // password: Joi.string().required().trim().messages({
    //     'any.required': 'کلمه عبور الزامیست ',
    //     'string.empty': 'کلمه عبور الزامیست ',
    //     'string.base':  ' کلمه عبور الزامیست '
    // }),
    name: Joi.string().required().trim().messages({
        'any.required': 'نام الزامیست ',
        'string.empty': 'نام الزامیست ',
        'string.base':  ' نام الزامیست '
    }),
});
