import React, {useEffect, useMemo, useState} from "react";

import {ListSearch} from "../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {GridTable} from "../../Commans/Grid/GridTable";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {Link, redirect, useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {Image} from "primereact/image";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {ToastContainer} from "react-toastify";
import {Export} from "../../Commans/Grid/Export";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {InputNumber} from "primereact/inputnumber";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {InputTextarea} from "primereact/inputtextarea";
import {useFormValidation} from "../../Commans/Hooks/useFormValidation";
import {departmentSchema} from "./departmentSchema";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {
  handleCreateFormDataDepartment,
  handleEditFormDataDepartment
} from "./DepartmentService";
import Swal from "sweetalert2";
import {validate} from "../../../Services/Globals/validate";
import {packageSchema} from "../../GeneralManagement/Packages/packageSchema";
import {Tooltip} from "react-tooltip";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";


export const HandleDepartment = () => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const[IsEdit,setIsEdit] = useState(false);
  const[spinner,setSpinner] = useState(false);
  const [IsDepartmentOpen,setDepartmentOpen] = useState(false);
  const [errors,setErrors] = useState([])
  const [wait,setWait] = useState(false);
  const [headerName,setHeaderName] = useState('');
  const[result,setResult] = useState(0);
  const {isValid,ValidateForm}= useFormValidation();
  const [department,setDepartment] = useState({id:null,name:null,description:null,picture:null,order:0,stateShow:false});
  const handleOpenMenu = () => {
    setDepartmentOpen(true);
    setDepartment((prevState)=>({...prevState,order: 0,stateShow: true}))
  };
  const handleCloseMenu = ()=>{
    setErrors({})
    setDepartmentOpen(false);
    setDepartment((prevState)=>({...prevState,picture: null,order: 0,stateShow: true,name:'',description: ''}))
  }
  const onApiError=()=>{
    setSpinner(false);
  }
  const onSetErrors = (errors)=>{
    setErrors(errors);
  }
  const handleValue=(e)=>{
    const {name,value} = e.target;
    setDepartment({...department,[name]:value});
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

  }
  const handleNumberValue = (value,name)=>{
    setDepartment({...department,[name]:value});
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  }
  const removeFile=(path,name)=>setDepartment((prevState)=>({...prevState,[name]:path}))
  const handleValueSelection =(data,name)=>{
    setDepartment((prevState) => ({ ...prevState, [name]: data }));
  }

  const handleDepartmentSubmit=async (e) => {
    e.preventDefault();
    if (IsEdit) {
      const isValid = validate(departmentSchema,department,onSetErrors);
      if (!isValid) {
        return;
      }
     try {
        setSpinner(true)
       const formData = handleEditFormDataDepartment(department);
       const response = await PUT(`/Tickets/Departments`,formData,true);
       if(response.status === 200){
         setSpinner(false);
         setIsEdit(false);
         handleCloseMenu();
         setResult({...response.status});
         handleSuccessMessage(t("Thedepartmentbeensuccessfullyedited"));
       }
       else{
         handleApiError(response,onApiError);
       }
     }catch (e) {
       console.log(e);
     }

    }
    else {
      try {
        const isValid = validate(departmentSchema,department,onSetErrors);
        if (!isValid) {
          return;
        }
        setSpinner(true);
        const formData = handleCreateFormDataDepartment(department);
        const response = await POST(`/Tickets/Departments`,formData,true);
        if(response.status === 200){
          setSpinner(false);
          handleSuccessMessage(t("Thedepartmentbeensuccessfullyregistered"));
          setResult({...response.status});
          handleCloseMenu();
        }
        else{
          handleApiError(response,onApiError);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }


  const handleDepartmentEdit=async (departmentId)=>{
    try {
        setSpinner(true);
        setIsEdit(true);
        const response=await GET(`/Tickets/Departments/${departmentId}`);
        if(response.status === 200){
          setDepartment({
            id:response.data.id,
            name: response.data.name,
            description: response.data.description,
            order: response.data.order,
            picture: response.data.icon,
            stateShow: response.data.stateShow
          })
          setDepartmentOpen(true);
          setSpinner(false);
        }else{
          handleApiError(response,onApiError);
        }
    }
    catch (e) {
      console.log(e);
    }
  }



  const handleDepartmentRemove = async (departmentId)=>{
    const TailWindButton = Swal.mixin({
      customClass: {
        confirmButton:AlertSetting.customClass.confirmButton,
        cancelButton: AlertSetting.customClass.cancelButton
      },
      buttonsStyling: false
    })
    TailWindButton.fire({
      title:AlertSetting.TailWindButton.title,
      text: AlertSetting.TailWindButton.text,
      icon:AlertSetting.TailWindButton.icon,
      showCancelButton:AlertSetting.TailWindButton.showCancelButton,
      confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
      cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
      reverseButtons: AlertSetting.TailWindButton.reverseButtons
    }).then( async (result) =>{
      if (result.isConfirmed) {
        try {
          setSpinner(true)
          const response = await DELETE(`/Tickets/Departments/${departmentId}`,{headers: { 'Content-Type': 'application/json' }})
          if (response.status === 200) {
            setSpinner(false)
            setResult({...response.status});
            handleSuccessMessage(t("Thedepartmentbeensuccessfullydeleted"))
          }
          else {
            handleApiError(response,onApiError)
          }
        }catch (error) {
          console.error('Error fetching data:', error);

        }
      }
      else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
      ) {
        return;
      }
    })
  }





  const handleChangeActivation = async(departmentId,rowName)=>{
    try {
      setWait(true);
      setHeaderName(rowName)
      const response = await PUT(`/Tickets/Departments/EditTicketDepartmentStateShow`,{departmentId:departmentId},false);
      if (response.status === 200) {
        setWait(false)
        setResult({...response.status})
      }
      else{
        handleApiError(response,onApiError)
      }
    }catch (e) {
      console.log(e);

    }
  }

  const columnDefs = [
    { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
    { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},

    { headerName: t("Image"),field: 'icon', maxWidth: 130,
      cellRenderer:params =>
        <>
        <div>
          { params.data.icon !== null?
          <Image src={params.data.icon} zoomSrc={params.data.icon}  preview />:
          <HPrimeIcon icon={'pi-times'} iconClass={"text-themeDanger"} />
          }
        </div>
        </>

    },

    { headerName:  t("DepartmentTitle"),field: 'name', minWidth: 150},


    { headerName: t("Explanation"),field: 'description',minWidth: 150,
      cellRenderer:params=>
          <>
            {params.data.description}
          </>
    },

    { headerName:  t("Order"),field: 'order', maxWidth: 120},

    { headerName: t("CreationDate"), minWidth: 140,
      cellRenderer:params => {
        return <> <GridDateFomartter date={params.data.creationDate} /></>
      }
    },


    { headerName: t("Status"),field: 'stateShow', maxWidth: 120, cellRenderer:params=>

          <div className={"relative"}>
            {wait && headerName === params.colDef.headerName ? (
                <TinyLoading  />
            ) : params.data.stateShow ? (
                <HPrimeIcon
                    iconClass={'cursor-pointer text-themeSuccess'}
                    onClick={async () => await handleChangeActivation(params.data.id,params.colDef.headerName)}
                    icon={"pi-check-square"}
                />
            ) : (
                <HPrimeIcon
                    onClick={() => handleChangeActivation(params.data.id,params.colDef.headerName)}
                    iconClass={'cursor-pointer text-themeDanger'}
                    icon={"pi-times-circle"}
                />
            )}
          </div>
    },

    { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
      cellRenderer:params => {
        return<div className={'flex justify-center items-center gap-3'} >
          <Link to={`/Support/Department/User?departmentId=${params.data.id}`} className="text-themeInformation dark:text-themeDarkInformation tooltip_class_Department" >
            <HPrimeIcon
            icon={"pi-th-large"}
            />
            <Tooltip anchorSelect={".tooltip_class_Department"} content={t("Accesses")} positionStrategy={"fixed"}	 />
          </Link>
          <GridButtonEdit
              onClick={()=>handleDepartmentEdit(params.data.id)}
          />
          <GridButtonDelete
              onClick={()=>handleDepartmentRemove(params.data.id)}
          />
        </div>
      }
    },



  ];

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);
  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      resizable: true,
      // editable: true,
      filter: true,
    };
  }, []);


  const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
  const [currentPage, setCurrentPage] = useState(1);
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleCurrentPage=(page)=>{
    setCurrentPage(page);
  }
  const handlePageSizeCount = (value)=>{
    setCurrentPage(1);

    setData(()=>({...data,pageSize: value}))
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const [filter,setFilter] = useState({search:'',IsActive:null,IsDeActive:null});

  const [search,setSearch] = useState('');
  const handleSearch=()=>{
    setFilter((prevState)=>({...prevState,search: search}));
  }

  const filterValueHandler= (data,name)=>{
    if(data === false){
      setFilter(({...filter,[name]: null}));
    }
    else{
      setFilter(({...filter,[name]: data}));
    }
  }

  const resetFilters = ()=>{
    setFilter(({...filter,search: '',IsDeActive: null,IsActive: null}));
    setSearch('');
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        setSpinner(true);
        const response = await OnGridReady();
        if (response && response.items !== null ) {
          //handleFetchData(); // Remove await if handleFetchData is not asynchronous
        }
        else {
          handleApiError(response,onApiError);
        }
      } catch (error) {
        console.error("Error in loadData:", error);
      } finally {
        setSpinner(false);
      }
    };
    loadData();
  }, [result, currentPage, filter.search, data.pageSize, filter.IsActive, filter.IsDeActive]);




  const [rowData, setRowData] = useState([]);
  const OnGridReady = async () => {
    try {
      setSpinner(true);
      const response =  await GET(`/Tickets/Departments`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search, IsActive: filter.IsActive, IsDeActive: filter.IsDeActive}, 'PagedList');
      if (response && response.items !== null ) {
        setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
        var idSequence = 1;
        const modifiedItems = response.items.map((item) => ({
          ...item,
          idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
        }));
        setRowData(modifiedItems);
      }
      else {
        handleApiError(response,onApiError);
      }
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      setSpinner(false);
    }
  };




  const switchData = [
    {name:'IsActive',lable:t("isActive"),value:filter.IsActive},
    {name:'IsDeActive',lable:t("isNotActive"),value:filter.IsDeActive}
  ]



  return(<>
    <div class="space-y-5 relative">
      {
        spinner?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony/>
              </div>
            </div>
            :
            null
      }

      <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
          <ListSearch label={null} icon={null} >
            <div class="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("SearchByDepartmentTitle")}</label>
              <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
            </div>
            <ButtonSearchList
                onClickSearch={()=>handleSearch()}
                onClickReset={()=>resetFilters()}
            />
          </ListSearch>
        <ListFilter label={null} icon={null}
                    childrenOne={
                      <>
                        <ButtonPlus
                            onClick={()=>handleOpenMenu()}
                            tooltipTitle={t("NewDepartment")}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo("/Support/Ticket")}
                            title={t("Ticket")}
                            tooltipTitle={t("NewTicket")}
                            icon={"pi-ticket"}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo("/Support/Ticket/List")}
                            title={t("list")}
                            tooltipTitle={t("TicketList")}
                            icon={"pi-list"}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo("/Support/ExpiredTickets")}
                            title={t("archive")}
                            tooltipTitle={t("ExpiredTickets")}
                            icon={"pi-database"}
                        />


                      </>
                    }
                    childrenTwo={
                      <>
                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                      </>
                    }
        />

      </div>
      <div className={"space-y-2 H-body-ag-grid "} >
             <GridTable
            columnDefs={columnDefs}
            rowSelection={GridSetting.rowSelection}
            rowData={rowData}
            style={GridSetting.gridStyle}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={GridSetting.pagination}
            enableRtl={GridSetting.enableRtl}
            domLayout={GridSetting.domLayout}
            paginationPageSize={GridSetting.pageSize}
            getRowHeight={getRowHeight}
            localeText={AgGridEnglish}
        />
        <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
      </div>
      <ToastContainer />

      <Export filters={filter} apiUrl={'/Packages/PackageListExport'}/>

    </div>


    <FantasyModal openModal={IsDepartmentOpen} closeModal={handleCloseMenu} title={t("NewDepartment")} customeClassName={"modal-sm"}>
    <form onSubmit={handleDepartmentSubmit} method={'post'}>
      <div className="space-y-5">
        <div className={"space-y-3"}>
          <div className={"flex flex-col items-center gap-3"}>
            <div className={"w-full h-36 tooltip_class_icon"}>
              <UploadImage isEdit={IsEdit} removeFile={removeFile} removeName={'picturePath'} accept={'image/*'} OnValueSelection={handleValueSelection} value={department.picture ? department.picture :null} name={'picture'} />
              <Tooltip anchorSelect={".tooltip_class_icon"} content={t("Icon")} positionStrategy={"fixed"}	 />
            </div>
            <div class="flex-1 w-full flex items-center gap-2">
              <div className={"space-y-2 w-full flex-1"}>
                <div className={"flex justify-between items-center"}>
                  <label className="text-xs dark:text-gray-300">
                    {t("Department")}
                    <RequiredIcon/>
                  </label>
                  <DisplayError message={errors.name}/>
                </div>
                <InputText  name={'name'} value={department.name} onInput={(e)=>handleValue(e)} type={"text"} className={" input w-full"} />
              </div>
              <div class="space-y-1 flex-shrink-0 w-20">
                <label className="text-xs dark:text-gray-300">{t("rank")}</label>
                <div>
                  <InputNumber className="input_number_plus w-20"  value={department.order} onValueChange={(e) => handleNumberValue(e.value,'order')}
                               placeholder={t("DisplayPriority")}
                               mode="decimal"
                               showButtons
                               min={0}
                               max={100}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <InputTextarea value={department.description} onInput={(e) => handleValue(e)} name={'description'} rows={5} cols={30} className="input w-full" placeholder={t("Explanation")} />
          </div>
          <div className={"flex items-center justify-start"}>
            <InputSwitch OnValueSelection={handleValueSelection} value={department.stateShow} name={'stateShow'} lable={t("DisplayStatus")} />
          </div>
        </div>

        <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>

      </div>
    </form>
    </FantasyModal>

  </>)
}