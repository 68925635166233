import { configureStore } from '@reduxjs/toolkit';
import loadingReducer from "./loadingReducer";
import settingReducer from "./SettingReducer";
import permissionsReducer from "./PermissionsReducer";
export const Store = configureStore({
    reducer: {
        loading:loadingReducer,
        setting:settingReducer,
        permissions:permissionsReducer
    },
});

