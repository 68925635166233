import React, {useEffect, useState} from "react";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {InputText} from "primereact/inputtext";
import {useNavigate, useParams} from "react-router-dom";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useMapTree} from "./useMapTree";
import {useRoadMap} from "./useRoadMap";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import queryString from "query-string";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {validate} from "../../../../Services/Globals/validate";
import {roadMapTreeSchema} from "./roadMapTreeSchema";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {
    handleConvertRoadMapTree,
    handleCreateRoadMapTree,
    handleEditRoadMapTree,
} from "../RoadMapTreeService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {OrganizationChart} from "primereact/organizationchart";

import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {ToastContainer} from "react-toastify";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";

export const HandleRoadMapTree = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {roadMapId}=useParams();
    const [IsOpen,setIsOpen] = useState(false);
    const [errors,setErrors]=useState(false);
    const [initialSource,setInitialSource]=useState({entityTypes:[],dataAccess:[],operations:[],types:[],parents:[],confirmationStates:[]});
    const [IsEdit,setIsEdit]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const queryParams = queryString.parse(window.location.search);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [IsLoading,setIsLoading]=useState(false);

    const showLoading=()=>{
        setIsLoading(true);
    }
    const hideLoading=()=>{
        setIsLoading(false);
    }



    const {setRoadMapTree,roadMapTree,handleValue,handleNumberValue,handleSelectValue}=useRoadMap(setErrors,setInitialSource,showLoading,hideLoading);

    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const handleResetErrors=()=>{
        setErrors({});
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const handleOpenRoadmap = () => {
        setRoadMapTree(prevState => ({...prevState,title: '',access: initialSource.dataAccess[0],description: '',isApproved: null,operation: initialSource.operations[0],parentId: null,personEntity: null,personEntityId: null,roadMapId:queryParams.roadMapId}))
        setIsOpen(true);
    }
    const handleCloseRoadmap = ()=>{
        setRoadMapTree(prevState => ({...prevState,title: '',access: initialSource.dataAccess[0],description: '',isApproved: null,operation: initialSource.operations[0],parentId: null,personEntity: null,personEntityId: null,roadMapId:null,roadMapStepId: null}))
        handleResetErrors();
        setIsOpen(false);
        setIsEdit(false);
    }

    const handleInitialSource=(entityTypes,dataAccesses,operations,parents)=>{
        const entityTypesData = convertSelectDataHandler(entityTypes)
        const dataAccessesData = convertSelectDataHandler(dataAccesses)
        const operationsData = convertSelectDataHandler(operations);
        const parentsData = parents.map((item)=>({label:item.title,value:item.roadMapStepId}));
        setInitialSource({
            entityTypes: entityTypesData,
            dataAccess: dataAccessesData,
            operations: operationsData,
            parents: parentsData,
            confirmationStates:[{label:'confirm',value:true},{label:'reject',value:false}]
        })
        return {
            entityTypesData,
            dataAccessesData,
            operationsData
        }
    }

    const fetchData = async () => {
        try {
            showSpinner()
            const [responseEntityType,responseDataAccess,responseOperation,responseParents,response] = await Promise.all([
                GET('/Enumerations/EnPersonEntity', {}, 'IEnumerable'),
                GET('/Enumerations/EnDataAccess', {}, 'IEnumerable'),
                GET('/Enumerations/EnFormOperation', {}, 'IEnumerable'),
                GET(`/Forms/RoadMaps/${roadMapId}/RoadMapSteps/Selection`, {}, 'IEnumerable'),
                GET(`/Forms/RoadMaps/${roadMapId}`, {}, 'single'),
            ]);
            if (responseEntityType.status === 200 && responseDataAccess.status === 200 && responseOperation.status && responseParents.status === 200 )
            {
                const {entityTypesData,dataAccessesData,operationsData} = handleInitialSource(responseEntityType.data,responseDataAccess.data,responseOperation.data,responseParents.data);
                //
                const tree = handleConvertRoadMapTree(response.data.roadMapSteps);
                setTreeData(tree);
            }
            else {
                handleApiError(responseEntityType,hideSpinner);
                handleApiError(responseDataAccess,hideSpinner);
                handleApiError(responseOperation,hideSpinner);
                handleApiError(response, hideSpinner);
            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };


    useEffect(()=>{
        fetchData()
    },[refresh])







    const {setTreeData,treeData,handleGetNode,handleDeleteNode,handleAddNodeChild,handleSetExpandedTrue,handleSetExpandedFalse}=useMapTree(showSpinner,hideSpinner,setIsOpen,initialSource,setInitialSource,setRoadMapTree,roadMapTree,setIsEdit,Refresh,handleCloseRoadmap);


    const nodeTemplate = (node) => {
        if(node.type === 'user'){
            return (
                <div className={'bg-themeInformation p-2 rounded-md dark:bg-themeDarkPostInput space-y-3 max-w-sm'}>
                    <div className="space-y-3">
                        <div className={"space-y-2"}>
                            {
                                !node.data || !node.type?null:
                                    <div className={"size-16 rounded-full bg-white dark:bg-themeDarkSalt700 overflow-hidden mx-auto border"}>
                                        <img src={ node.data && node.data.image} className="size-full object-cover" />
                                    </div>
                            }
                            <div>
                                <span className="text-xs text-white dark:text-gray-300">{node.data && node.data.title}</span>
                            </div>
                        </div>
                        <div>
                            <p className={"text-white text-xs dark:text-gray-300"}>{node.title}</p>
                        </div>
                    </div>
                    <div className={"flex gap-2 items-center post-input post-input-design dark:bg-themeDarkSalt700 p-1 w-max mx-auto"}>
                        <button type={'button'} onClick={ ()=> handleAddNodeChild(node)} className="button bg-themeInformation dark:bg-themeDarkInformation">
                            <HPrimeIconSmall icon={'pi-plus-circle'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async ()=>await handleGetNode(node)} className="button bg-themeSuccess ">
                            <HPrimeIconSmall icon={'pi-pen-to-square'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async ()=>await handleDeleteNode(node.roadMapStepId)} className="button bg-themeDanger">
                            <HPrimeIconSmall icon={'pi-trash'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                    </div>
                </div>
            );
        }else if(node.type === 'userGroup'){
            return (
                <div className={'bg-themeInformation p-2 rounded-md dark:bg-themeDarkPostInput space-y-3 max-w-sm'}>
                    <div  className="space-y-3">
                        <div className={"space-y-2"}>
                            {
                                !node.data || !node.type?null:
                                    <div className={"size-16 bg-white dark:bg-themeDarkSalt700 rounded-full overflow-hidden mx-auto border"}>
                                        <img  src={ node.data && node.data.image} className="size-full object-cover" />
                                    </div>

                            }
                            <span className="text-xs text-white dark:text-gray-300">{ node.data && node.data.title}</span>
                        </div>
                        <div>
                            <p className={"text-white text-xs dark:text-gray-300"}>{node.title}</p>
                        </div>
                    </div>
                    <div className={"flex gap-2 items-center post-input post-input-design dark:bg-themeDarkSalt700 p-1 w-max mx-auto"}>
                        <button type={'button'} onClick={ ()=> handleAddNodeChild(node)} className="button bg-themeInformation dark:bg-themeDarkInformation">
                            <HPrimeIconSmall icon={'pi-plus-circle'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async ()=>await handleGetNode(node)} className="button bg-themeSuccess">
                            <HPrimeIconSmall icon={'pi-pen-to-square'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async ()=>await handleDeleteNode(node.roadMapStepId)} className="button bg-themeDanger">
                            <HPrimeIconSmall icon={'pi-trash'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                    </div>
                </div>
            );
        }else if(node.type === 'role'){
            return (
                <div className={'bg-themeInformation p2-3 rounded-md dark:bg-themeDarkPostInput space-y-3 max-w-sm'}>
                    <div  className="space-y-3">
                        <div className={"space-y-2"}>
                            {
                                !node.data || !node.type?null:
                                    <div className={"size-16 rounded-full bg-white dark:bg-themeDarkSalt700 overflow-hidden mx-auto border"}>
                                        <img  src={ node.data && node.data.image} className="size-full object-cover" />
                                    </div>

                            }
                            <span className="text-xs text-white dark:text-gray-300 line-clamp-2">{ node.data && node.data.title}</span>
                        </div>

                        <div>
                            <p className={"text-white text-xs dark:text-gray-300 line-clamp-1"}>{node.title}</p>
                        </div>
                    </div>
                    <div className={"flex gap-2 items-center post-input post-input-design dark:bg-themeDarkSalt700 p-1 w-max mx-auto"}>
                        <button type={'button'} onClick={ ()=> handleAddNodeChild(node)} className="button bg-themeInformation dark:bg-themeDarkInformation">
                            <HPrimeIconSmall icon={'pi-plus-circle'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async ()=>await handleGetNode(node)} className="button bg-themeSuccess">
                            <HPrimeIconSmall icon={'pi-pen-to-square'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async ()=>await handleDeleteNode(node.roadMapStepId)} className="button bg-themeDanger">
                            <HPrimeIconSmall icon={'pi-trash'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                    </div>

                </div>
            );
        }else{
            return (
                <div className={'bg-themeInformation p-2 rounded-md dark:bg-themeDarkPostInput space-y-3 max-w-sm'}>
                    <div  className="space-y-3">
                        <div className={"space-y-2"}>
                            {
                                !node.data || !node.type?null:
                                    <div className={"size-16 bg-white dark:bg-themeDarkSalt700 rounded-full overflow-hidden mx-auto border"}>
                                        <img  src={ node.data && node.data.image} className="size-full object-cover" />
                                    </div>
                            }
                            <span className="text-white text-xs dark:text-gray-300 line-clamp-1">{ node.data && node.data.title}</span>
                        </div>

                    <div>
                        <span className={"text-xs text-white dark:text-gray-300 line-clamp-2"}>{node.title}</span>
                    </div>

                    </div>
                    <div className={"flex gap-2 items-center post-input post-input-design dark:bg-themeDarkSalt700 p-1 w-max mx-auto"}>
                        <button type={'button'} onClick={ ()=> handleAddNodeChild(node)} className="button bg-themeInformation dark:bg-themeDarkInformation text-white">
                            <HPrimeIconSmall icon={'pi-plus-circle'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async ()=>await handleGetNode(node)} className="button bg-themeSuccess">
                            <HPrimeIconSmall icon={'pi-pen-to-square'} iconClass={"text-white dark:text-gray-300"} />
                        </button>
                        <button type={'button'} onClick={async ()=>await handleDeleteNode(node.roadMapStepId)} className="button bg-themeDanger">
                            <HPrimeIconSmall icon={'pi-trash'} iconClass={"text-white dark:text-gray-300"} />
                        </button>

                    </div>

                    </div>
            );
        }
    };



    const handleSubmitRoadMapTree=async(e)=>{
        e.preventDefault();
        const isValid = validate(roadMapTreeSchema,roadMapTree,onSetErrors);
        if(!isValid)return ;
        try {
           if(IsEdit){
               showSpinner();
               const data = handleEditRoadMapTree(roadMapTree);
               const response = await PUT(`/Forms/RoadMapSteps`,data,false);
               if(response.status === 200){
                   hideSpinner();
                   Refresh();
                   handleSuccessMessage(t('SuccessDone'));
                   handleCloseRoadmap();
               }
               else{
                   handleApiError(response,hideSpinner);
               }
           }
           else{
               showSpinner();
               const data = handleCreateRoadMapTree(roadMapTree,queryParams.roadMapId);
               const response = await POST(`/Forms/RoadMapSteps`,data,false);
               if(response.status === 200){
                 hideSpinner();
                 Refresh();
                 handleSuccessMessage(t('SuccessDone'));
                 handleCloseRoadmap();
               }
               else{
                   handleApiError(response,hideSpinner);
               }
           }
        }
        catch (e) {
            hideSpinner()
        }
    }






    return (
        <>

            <div className="space-y-5 ">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 ">
                    <ListFilter label={null} icon={null}
                                childrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>handleOpenRoadmap()}
                                            tooltipTitle={t("OrganisationRoute")}
                                        />
                                        <ButtonPlus
                                            icon={"pi-arrow-up-right-and-arrow-down-left-from-center"}
                                            title={t("View")}
                                            onClick={()=>handleSetExpandedTrue()}
                                        />
                                        <ButtonPlus
                                        icon={"pi-arrow-down-left-and-arrow-up-right-to-center"}
                                        onClick={()=>handleSetExpandedFalse()}
                                        title={t("Close")}
                                    />
                                    </>
                                }

                    />
                </div>

                <div className={"relative"}>
                    {
                        IsShowSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className="grid h-screen overflow-auto scrollbar bg-white dark:bg-themeDarkSalt700 box p-2">
                        {
                            treeData.length === 0?null:
                                <OrganizationChart    value={treeData} nodeTemplate={nodeTemplate}  />
                        }
                    </div>
                </div>


            </div>


            <FantasyModal openModal={IsOpen} closeModal={handleCloseRoadmap} title={t("OrganisationRoute")} >
                <form onSubmit={handleSubmitRoadMapTree} method={'post'}>
                    <div className={"space-y-5 relative"}>

                    {
                        IsLoading?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className="space-y-2">
                        <div className="space-y-1">
                            <div className={"flex justify-between items-center"}>
                                <label className="text-xs dark:text-gray-300">
                                    {t("StepName")}
                                <RequiredIcon/>
                                </label>
                                <DisplayError message={errors.title}/>
                            </div>
                            <InputText name={'title'} value={roadMapTree.title} onInput={(e)=>handleValue(e)} type={"text"} className={"input w-full"} />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className="space-y-1 ">
                                <label className="text-xs dark:text-gray-300">{t("Access")}</label>
                                <Select
                                    options={initialSource.dataAccess}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'access')}
                                    placeholder={t("selection")}
                                    value={roadMapTree.access}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class="space-y-1 ">
                                <label className="text-xs dark:text-gray-300">{t("operations")}</label>
                                <Select
                                    options={initialSource.operations}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'operation')}
                                    placeholder={t("selection")}
                                    value={roadMapTree.operation}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className={"space-y-1"}>
                                <label className="text-xs dark:text-gray-300">{t("ReviewUnit")}</label>
                                <Select
                                    options={initialSource.entityTypes}
                                    isMulti={false}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(data) => handleSelectValue(data, 'personEntity')}
                                    placeholder={t("selection")}
                                    value={roadMapTree.personEntity}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div>
                                {
                                    roadMapTree.personEntity === null?
                                        null:
                                        <div className="space-y-1">
                                            <label className="text-xs dark:text-gray-300">{t("ResponsibleReview")}</label>
                                            <Select
                                                options={initialSource.types}
                                                isMulti={false}
                                                isSearchable={true}
                                                onChange={(data) => handleSelectValue(data, 'personEntityId')}
                                                placeholder={t("selection")}
                                                value={roadMapTree.personEntityId}
                                                menuPosition={"fixed"}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                }
                            </div>

                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            {
                                treeData.length > 0?
                                    <div class="space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{'PreviousStation'}</label>
                                        <Select
                                            options={
                                                roadMapTree.roadMapStepId === null?
                                                    initialSource.parents:
                                                    initialSource.parents.filter((s)=>s.value !== roadMapTree.roadMapStepId
                                            )}
                                            isMulti={false}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'parentId')}
                                            placeholder={t("selection")}
                                            value={roadMapTree.parentId}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    :null
                            }

                            {
                                roadMapTree.parentId === null?null:
                                    <div class="space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{'ApprovalDisapproval'}</label>
                                        <Select
                                            options={initialSource.confirmationStates}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'isApproved')}
                                            placeholder={t("selection")}
                                            value={roadMapTree.isApproved}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>

                            }

                        </div>
                    </div>
                    <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative"}> {t("insertion")}</button>
                </div>
                </form>
            </FantasyModal>
            <ToastContainer/>
        </>

    );
};
