import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const usePersonTabs=()=>{

    const { t } = useTranslation();
    const [personTabId, setPersonTabId] = useState("natural");
    const handleChangePersonTab = (tabId) => {
        setPersonTabId(tabId);
    };
    const personTabs = [
        {
            id: "natural",
            label:t("حقیقی"),
            tooltipTitle:t("حقیقی"),
        },
        {
            id: "legal",
            label:t("حقوقی"),
            tooltipTitle:t("حقوقی"),
        },
    ];





    const [userTabId,setUserTabId] = useState("contacts");
    const handleChangeUserTabId = (tabId) => {
        setUserTabId(tabId);
    };
    const userTabs = [
        {
            id: "contacts",
            label:t("Contacts"),
            tooltipTitle:t("Contacts"),
        },
        {
            id: "reminder",
            label:t("Reminder"),
            tooltipTitle:t("Reminder"),
        },
        {
            id: "note",
            label:t("Note"),
            tooltipTitle:t("Note"),
        },
        {
            id: "complaints",
            label:t("Complaint"),
            tooltipTitle:t("Complaint"),
        },
        {
            id: "feedbacks",
            label:t("Feedback"),
            tooltipTitle:t("Feedback"),
        },
        {
            id: "contracts",
            label:t("Contracts"),
            tooltipTitle:t("Contracts"),
        },
    ];








    return {
       personTabId,
        handleChangePersonTab,
        personTabs,

        userTabId,
        setUserTabId,
        handleChangeUserTabId,
        userTabs
    }
}