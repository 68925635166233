import React, { useEffect, useMemo, useState } from "react";
import { GET } from "../../../../Services/AxiosService/AxiosApiService";
import { GridSetting } from "../../../../Services/Globals/Public/AppSetting";
import { useTranslation } from "react-i18next";
import { GridDateFomartter } from "../../../Commans/Grid/GridDateFomartter";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";

export const useHandelExpiredTickets = (showSpinner, hideSpinner, refresh, Refresh) => {
    const { t } = useTranslation();
    const [rowData, setRowData] = useState([]);
    const [pagination, setPagination] = useState({ totalPages: 0, page: 0, pageSize: GridSetting.pageSize, totalCount: 0, hasNextPage: false, hasPreviousPage: false, lastPage: 0, currentPage: 1 })
    const [filter, setFilter] = useState({ search: '', searchValue: '', IsNormal: null, IsImportant: null, IsCritical: null, DepartmentId: null });




    // Display data to the user
    const columnDefs = [
        { headerName: t("Identifier"), field: 'id', filter: 'agTextColumnFilter', hide: true },
        { headerName: t("row"), field: 'idSequence', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110 },

        {
            headerName: t("TicketTitle"), minWidth: 200,
            cellRenderer: parameters =>
                <>
                    {parameters.data.name}
                </>
        },
        {
            headerName: t("Receiver"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    مسعود؟؟؟؟؟؟؟
                </>
        },

        {
            headerName: t("TicketNumber"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.ticketCode}
                </>
        },

        {
            headerName: t("ImportanceLevel"), minWidth: 150,
            cellRenderer: parameters =>
                <>
                    {/*از entickettype میاد*/}
                    {parameters.data.type}
                </>
        },
        {
            headerName: t("Date"), minWidth: 120,
            cellRenderer: params => {
                return <> <GridDateFomartter date={params.data.createdOn} /></>
            }
        },
    ];
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    // Pagination handel
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({ ...pagination, pageSize: pageSize }))
    }


    // Search handel
    const handleSearch = () => {
        setFilter((prevState) => ({ ...prevState, search: prevState.searchValue }));
    }
    const handleResetFilters = () => {
        setFilter((prevState) => ({ ...prevState, search: '', searchValue: '' }));
    }
    const Paginate = (items) => {
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }


    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize, filter.IsCritical, filter.IsImportant, filter.IsNormal, filter.DepartmentId]);


    const OnGridReady = async () => {
        try {
            showSpinner();
            const response = await GET(`/Tickets/Closed`, {
                DepartmentId: filter.DepartmentId !== null ? filter.DepartmentId.value : filter.DepartmentId,
                IsNormal: filter.IsNormal,
                IsImportant: filter.IsImportant,
                IsCritical: filter.IsCritical,
                PageNumber: pagination.currentPage,
                PageSize: pagination.pageSize,
                Search: filter.search
            },
                'PagedList');

            if (response && response.items !== null) {
                setPagination({ ...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount });
                const items = Paginate(response.items);
                setRowData(items);
            }
            else {
                handleApiError(response, hideSpinner);
            }
        } catch {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };


    // checkBox filter
    const switchData = [
        { name: 'IsNormal', lable: t("Normal"), value: filter.IsNormal },
        { name: 'IsImportant', lable: t("Important"), value: filter.IsImportant },
        { name: 'IsCritical', lable: t("Urgent"), value: filter.IsCritical },
    ]
    const checkBoxFilterValueHandler = (data, name) => {
        if (data === false) {
            setFilter(({ ...filter, [name]: null }));
        }
        else {
            setFilter(({ ...filter, [name]: data }));
        }
    }

    // other filter
    const handleFilterValue = (data, name) => {
        setFilter(({ ...filter, [name]: data }));
    }
    const resetFilters = () => {
        setFilter((prevState) => ({ ...prevState,search: '', searchValue: '', IsNormal: null, IsCritical: null, IsImportant: null, DepartmentId: null }))
}

return {
    handleCurrentPage,
    handleNextPage,
    handlePageSizeCount,
    handlePreviousPage,
    handleSearch,
    handleResetFilters,
    columnDefs,
    autoGroupColumnDef,
    getRowHeight,
    defaultColDef,
    rowData,
    pagination,
    filter,
    setFilter,
    switchData,
    checkBoxFilterValueHandler,
    handleFilterValue,
    resetFilters
}

}