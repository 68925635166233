import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {Button} from "primereact/button";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {GridTable} from "../Commans/Grid/GridTable";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {Return} from "../Commans/Globals/Return";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import ButtonVideo from "../Commans/UIParts/ButtonVideo";
import Select from "react-select";
import {Image} from "primereact/image";
import {InputTextarea} from "primereact/inputtextarea";
import {ToggleSwitch} from "../Commans/UIParts/ToggleSwitch";
import {ModalButtonOperations} from "../Commans/UIParts/ModalButtonOperations";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../Commans/Grid/GridButtonOprations";

export const HandleAddInventory = () => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [isMenuOpen,setMenuOpen] = useState(false);
  const [userId,setUserId] = useState('');

  const [modal, setModal] = useState({See:false, });

  const handleOpenMenu = () => {
    setMenuOpen(true);

  };
  const handleCloseMenu = ()=>{
    setMenuOpen(false);
  }


  const SeeOnHide = () => {
    setModal((prevState) => ({ ...prevState, See: !modal.See }));
  };


  const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

  const columnDefs = [
    { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

    { headerName: t("InventoryName"), minWidth: 160,
      cellRenderer:parameters=>
          <>
            انبار ملزومات اداری
          </>
    },
    { headerName: t("InventoryCode"), minWidth: 160,
      cellRenderer:parameters=>
          <>
            انبار ملزومات اداری
          </>
    },

    { headerName: t("PurchaseManager"), minWidth: 150,
      cellRenderer:parameters=>
          <>
            انبار ملزومات اداری
          </>
    },

    { headerName: t("InventoryType"),minWidth: 150,
      cellRenderer:parameters=>
          <>
            دارایی ثابت
          </>
    },
    { headerName: t("Branch"), minWidth: 150,
      cellRenderer:parameters=>
          <>
            کاشان
          </>
    },

    { headerName: t("InventoryManager"), minWidth: 160,
      cellRenderer:parameters=>
          <>
           مسعود احمدی
          </>
    },

    { headerName: t("default"), maxWidth: 120,
      cellRenderer:parameters=>
          <>
            فعال غیر فعال
          </>
    },

    { headerName: t("State"), minWidth: 160,
      cellRenderer:parameters=>
          <>
            مسعود احمدی
          </>
    },

    { headerName: t("description"), minWidth: 160,
      cellRenderer:parameters=>
          <>
           -----
          </>
    },

    { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
      cellRenderer:params => {
        return<div className={'flex justify-center items-center gap-3 '} >
          <GridButtonEdit/>
          <GridButtonDelete/>
          <GridButtonOprations
              onClick={()=>handleOpenMenu()}
          />
        </div>
      }

    },

  ];

  const rowData = [

    { id:1, orderReciever: 'Camry',date:'1402/10/3',code:'84732085', Totalprice: 25000,comment:'راضی ام'},

  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      filter: true,
      sortable: true,
      wrapText: true,
      resizable: true,
      // resizable: true,
      // editable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const onSelectionChanged=(data)=>{
    console.log(data);
  }
  const onCellValueChanged = (data)=>{
    console.log(data);
  }


  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };



  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = (event) => {
    setQuickFilterText(event.target.value);
  };





  return(
      <>
        <div class="space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 ">
            <div className="space-y-5">
              <ListSearch label={null} icon={null} >
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("SearchByName")}</label>
                  <InputText type={"text"} className={" input w-full"} />
                </div>
                <div className="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("InventoryType")}</label>
                  <Select
                      placeholder={t("selection")}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}
                  />
                </div>
                <div className="space-y-2">
                  <label className="text-xs dark:text-gray-300">{t("Branch")}</label>
                  <Select
                      placeholder={t("selection")}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}
                  />
                </div>
                <ButtonSearchList/>
              </ListSearch>
              <ListFilter label={null} icon={null}
                          childrenOne={
                            <>
                              <ButtonPlus
                                  onClick={() => setModal({See: true})}
                                  tooltipTitle={t("CreateInventory")}
                              />
                              <ButtonPlus
                                  onClick={() => redirectTo("/Inventory/Goods/Add")}
                                  title={t("GoodsRegistration")}
                                  tooltipTitle={t("GoodsRegistration")}
                                  icon={"edit"}
                              />
                              <ButtonPlus
                                  onClick={() => redirectTo("/Inventory/EntryDocument")}
                                  title={t("DocumentRegistration")}
                                  tooltipTitle={t("DocumentRegistration")}
                                  icon={"file-plus"}
                              />
                              <ButtonPlus
                                  onClick={() => redirectTo("/Inventory/ProductionSeries")}
                                  title={t("ProductionSeries")}
                                  tooltipTitle={t("ProductionSeries")}
                                  icon={"edit"}
                              />

                              <ButtonPlus
                                  onClick={() => redirectTo("/Inventory/List/Requests")}
                                  title={t("list")}
                                  tooltipTitle={t("ListOfRequests")}
                                  icon={"list"}
                              />

                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Inventory/Report`)}
                                  icon={"pie-chart"}
                                  title={t("Report")}
                                  functionClass={"harmony-return-list"}
                                  tooltipTitle={t("InventoryStockReport")}
                              />

                            </>
                          }
                          childrenTwo={
                            <>
                              <div className="space-y-2">
                                <lable className="text-xs dark:text-gray-300">{t("Active")}</lable>
                                <div>
                                  <input className="input input--switch border bg-white" type="checkbox"/>
                                </div>
                              </div>
                              <div className="space-y-2">
                                <lable className="text-xs dark:text-gray-300">{t("Inactive")}</lable>
                                <div>
                                  <input className="input input--switch border bg-white" type="checkbox"/>
                                </div>
                              </div>
                            </>
                          }
              />

            </div>
          </div>
          <div>
            <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                       enableRtl={true}
                       pagination={true}
                       getRowHeight={getRowHeight}
                       onGridReady={onGridReady}
                       quickFilterText={quickFilterText}
            />
          </div>
        </div>


        <FantasyModal openModal={modal.See} closeModal={SeeOnHide} title={t("CreateInventory")}>
          <div className={"space-y-5"}>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
              <div className={"space-y-1"}>
                  <label className="text-xs dark:text-gray-300">{t("InventoryName")}</label>
                  <InputText type={"text"}  className={"input w-full "}/>
              </div>
              <div className={"space-y-1"}>
                <div className="flex items-center gap-2">
                  <label className="text-xs dark:text-gray-300">{t("InventoryCode")}</label>

                  <ToggleSwitch tag={'auto'}  name={'applyAuto'} customClass={"w-32"} checked={t("automatic")} unchecked={t("manual")} />

                </div>
                <InputText type={"text"} name={'productCode'} className={"input w-full "}/>
              </div>
              <div className={"space-y-1"}>
                <label className="text-xs dark:text-gray-300">{t("InventoryType")}</label>
                <Select
                    placeholder={t("selection")}
                    classNamePrefix={"h_multi_select"}
                    menuPosition={'fixed'}
                />
              </div>
              <div className={"space-y-1"}>
                <label className="text-xs dark:text-gray-300">{t("InventoryManager")}</label>
                <Select
                    placeholder={t("selection")}
                    classNamePrefix={"h_multi_select"}
                    menuPosition={'fixed'}
                />
              </div>
              <div className={"space-y-1"}>
                <label className="text-xs dark:text-gray-300">{t("PurchaseManager")}</label>
                <Select
                    placeholder={t("selection")}
                    classNamePrefix={"h_multi_select"}
                    menuPosition={'fixed'}
                />
              </div>
              <div className={"space-y-2"}>
                <div className={"flex justify-between items-center"}>
                  <label className="text-xs dark:text-gray-300">{t("Branch")}</label>
                  <button type="button" className="text-themeInformation text-xs dark:text-themeDarkInformation">({t("AddBranch")})</button>
                </div>
                <Select
                    placeholder={t("selection")}
                    classNamePrefix={"h_multi_select"}
                    menuPosition={'fixed'}
                />
              </div>
              <div className={"space-y-1"}>
                  <label className="text-xs dark:text-gray-300">{t("description")}</label>
                <InputText type={"text"} className={"input w-full "}/>
              </div>
              <div className=" space-y-2 text-center">
                <lable className="text-xs dark:text-gray-300">{t("default")}</lable>
                <div>
                  <input  className="input input--switch border bg-white" type="checkbox"/>
                </div>
              </div>

            </div>
            <div>
              <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("register")}</button>
            </div>
          </div>
        </FantasyModal>


        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
          <div className={"p-1 grid grid-cols-3 md:grid-cols-4 gap-3"}>
            <ModalButtonOperations
                icon={"info"}
                title={t("communications")}
                onClick={()=>redirectTo(`/Inventory/Add/Communications`)}
            />
          </div>

        </FantasyModal>
      </>
)
}