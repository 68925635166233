import React, {useEffect, useState} from "react";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import queryString from "query-string";

export const useWebsiteUpgradeDetailGrid=(showSpinner,hideSpinner,refresh,Refresh)=>{

    const [rowData,setRowData] = useState([]);
    const queryParams = queryString.parse(window.location.search);



    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',fromDate:null,toDate:null});



    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',fromDate:null,toDate:null}));
        setSearch('');
    }


    const handleFromDate=(dateTime)=>{
        setFilter(({...filter,fromDate:dateTime}));
    }
    const handleToDate=(dateTime)=>{
        setFilter(({...filter,toDate:dateTime}));
    }


    useEffect(() => {

        OnGridReady();

    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.fromDate,filter.toDate]);

    const OnGridReady = async () => {
        try {
            showSpinner()
            const response =  await GET(`/Upgrades/${queryParams.websiteId}/Logs`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize,FromDate:filter.fromDate,ToDate:filter.toDate}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };


    return {
        rowData,
        handleFromDate,
        handleToDate,
        handleSearch,
        handleToggleFilter,
        handleResetFilters,
        handlePageSizeCount,
        handleCurrentPage,
        handleNextPage,
        handlePreviousPage,
        pagination
    }
}