
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import React from "react";
import {useLocation} from "react-router-dom";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const ImageElement = ({item,index,handleChangeData}) => {
    const location = useLocation();
    const isInAnswer = location.pathname.includes('/Form/Answer/')
    const handleDownload = (url) => {
        // Create an anchor element
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank'; // Open in a new tab
        link.download = ''; // Leave this empty to open the file instead of forcing download
        // Trigger the click event on the anchor element
        console.log(url)
        link.click();
    };

    return (
        <>
            {
                isInAnswer?
                    <>
                        <div className={'cursor-pointer'} onClick={()=>handleDownload(item.data.value)}>
                            <HPrimeIcon icon={'pi-download'} sizeIcon={16} />
                        </div>
                    </>
                    :
                    <>
                        <div className={`harmony-space-y-1 ${item.properties.size !== null ? item.properties.size.className : ''}`}>

                        {
                            item.properties.isRequired === true?
                                <RequiredIcon/>
                                :null
                        }
                        <input
                            type="file"
                            placeholder={item?.properties?.description}
                            accept={item.properties.extensions}
                            onChange={(e)=>handleChangeData(index,'file',e.target.files[0])}
                            className={`block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white file:border-none dark:border-themeDarkColorLine file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 
                   ${item?.properties?.className}
                `}            maxLength={item.properties.max}
                            minLength={item.properties.min}
                            readOnly={item.properties.isReadOnly}
                            disabled={item.properties.isReadOnly}
                            id={item.properties.id}

                        />
                        </div>
                    </>




            }




        </>
    )
}



