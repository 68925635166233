import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {useDataEntrance} from "./useDataEntrance";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {
    handleCreateAnswerItems,
    handleGetJsonElementFromServer,
    handleGetJsonFromElementToAnswer
} from "./dataEntranceService";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {InputTextElement} from "./Elements/InputTextElement";
import {InputTextAreaElement} from "./Elements/InputTextAreaElement";
import {BreakLineElement} from "./Elements/BreakLineElement";
import {InputNumberElement} from "./Elements/InputNumberElement";
import {InputUrlElement} from "./Elements/InputUrlElement";
import {ColorElement} from "./Elements/ColorElement";
import {SliderRange} from "./Elements/SliderRange";
import {PriceElement} from "./Elements/PriceElement";
import {PasswordElement} from "./Elements/PasswordElement";
import {EmailElement} from "./Elements/EmailElement";
import {UrlElement} from "./Elements/UrlElement";
import {PhoneElement} from "./Elements/PhoneElement";
import {DateElement} from "./Elements/DateElement";
import {DateTimeElement} from "./Elements/DateTimeElement";
import {TimeElement} from "./Elements/TimeElement";
import {CheckBoxElement} from "./Elements/CheckBoxElement";
import {RadioElement} from "./Elements/RadioElement";
import {SelectElement} from "./Elements/SelectElement";
import {MultiSelectElement} from "./Elements/MultiSelectElement";
import {Location} from "./Elements/Location";
import {FileElement} from "./Elements/FileElement";
import {ImageElement} from "./Elements/ImageElement";
import {AudioElement} from "./Elements/AudioElement";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {useTranslation} from "react-i18next";
import {VideoElement} from "./Elements/VideoElement";
export const HandleFormAnswer = () => {

    const { t } = useTranslation();
    const redirectTo = useNavigate();

    const {formId,formDataGroupId} = useParams();
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [initialSource,setInitialSource]=useState({
        sizeParameters:[{value:0,label:'کوچک',className:'harmony-col-span-1'},{value:1,label:'متوسط',className:'harmony-col-span-1 harmony-md-col-span-2'},{value:2,label:'تقریبا بزرگ',className:'harmony-col-span-1 harmony-md-col-span-3'},{value:3,label:'بزرگ',className:'harmony-col-span-1 harmony-md-col-span-4'}],
        currencies:[],
        calendarViews:[],
        calendarTypes:[],
        calendarLanguages:[],
    })

    const {handleChangeData,setElements,elements,handlePropertyChange} = useDataEntrance();
    const [refresh,setRefresh]=useState(false);
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleInitialDataSource=(calendarTypes,calendarLanguages,calendarViews)=>{
        const calendarTypesData = convertSelectDataHandler(calendarTypes)
        const calendarLanguagesData = convertSelectDataHandler(calendarLanguages)
        const calendarViewsData = convertSelectDataHandler(calendarViews)
        const sizeParameters = [{value:0,label:'کوچک',className:'harmony-col-span-1'},{value:1,label:'متوسط',className:'harmony-col-span-1 harmony-md-col-span-2'},{value:2,label:'تقریبا بزرگ',className:'harmony-col-span-1 harmony-md-col-span-3'},{value:3,label:'بزرگ',className:'harmony-col-span-1 harmony-md-col-span-4'}];
        setInitialSource({
            calendarTypes: calendarTypesData,
            calendarLanguages: calendarLanguagesData,
            calendarViews: calendarViewsData,
            sizeParameters: sizeParameters
        });
        return {
            calendarTypesData,
            calendarLanguagesData,
            calendarViewsData,
            sizeParameters
        }
    }


    const fetchData = async () => {
        try {
            showSpinner();
            const [
                responseCalendarType,
                responseCalendarLanguage,
                responseCalendarView,
                responseForm
            ] = await Promise.all([
                GET(`/Enumerations/EnCalendarType`),
                GET(`/Enumerations/EnCalendarLangType`),
                GET(`/Enumerations/EnCalendarView`),
                GET(`/Forms/${formId}`, {}, 'single')
            ]);
            if (
                responseCalendarType.status === 200 &&
                responseCalendarLanguage.status === 200 &&
                responseCalendarView.status === 200 &&
                responseForm.status === 200
            ) {
                const {
                    calendarLanguagesData,
                    calendarTypesData,
                    calendarViewsData,
                    sizeParameters
                } = handleInitialDataSource(
                    responseCalendarType.data,
                    responseCalendarLanguage.data,
                    responseCalendarView.data
                );

                const responseFormDataGroup = await GET(`/Forms/DataGroups/${formDataGroupId}`,{},'single');
                const {formItems}=responseForm.data;
                const {formData}=responseFormDataGroup.data


                const retrievedElements =  handleGetJsonFromElementToAnswer(
                    formItems,
                    calendarLanguagesData,
                    calendarTypesData,
                    calendarViewsData,
                    sizeParameters,
                    formData
                );
                setElements(retrievedElements);
            }
            else {
                handleApiError(responseCalendarType,hideSpinner);
                handleApiError(responseCalendarLanguage,hideSpinner);
                handleApiError(responseCalendarView,hideSpinner);
                handleApiError(responseForm,hideSpinner);
            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };



    useEffect(()=>{
        fetchData();
    },[refresh])




    const handleSubmitAnswers=async()=>{
        try {
            showSpinner();
            const answers = handleCreateAnswerItems(formId,elements);
            const response = await POST(`/Forms/Answer`,answers,false);
            if(response.status === 200){
                hideSpinner();
                redirectTo('/Form/Create')
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }





    return(



        <div class="space-y-5 relative">
            {
                IsShowSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            {console.log(elements)}

            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                    />
                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />

            </div>
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5 min-h-screen">

                <div className="dark:bg-themeDarkPostInput harmony-form" >
                    <div class={"harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-4 harmony-gap-3 harmony-add-form"}>

                        {
                            elements.map((item, index) =>
                                item.typeName === 'TextBox' ?
                                    <InputTextElement
                                        index={index}
                                        item={item}
                                        handleChangeData={handleChangeData}
                                    />
                                    : item.typeName === 'TextArea'?
                                        <InputTextAreaElement
                                            index={index}
                                            item={item}
                                            handleChangeData={handleChangeData}
                                        />
                                        :
                                        item.typeName === 'SelectionBreak'?
                                            <BreakLineElement
                                                index={index}
                                                item={item}
                                            />:
                                            item.typeName === 'Numeric'?
                                                <InputNumberElement
                                                    index={index}
                                                    item={item}
                                                    handleChangeData={handleChangeData}
                                                />:
                                                item.typeName === 'Url'?
                                                    <InputUrlElement
                                                        index={index}
                                                        item={item}
                                                        handleChangeData={handleChangeData}
                                                    />:
                                                    item.typeName === 'Color'?
                                                        <ColorElement
                                                            index={index}
                                                            item={item}
                                                            handleChangeData={handleChangeData}
                                                        />
                                                        :
                                                        item.typeName === 'Slider'?
                                                            <SliderRange
                                                                index={index}
                                                                item={item}
                                                                handleChangeData={handleChangeData}
                                                            />:
                                                            item.typeName === 'Price'?
                                                                <PriceElement
                                                                    index={index}
                                                                    item={item}
                                                                    handleChangeData={handleChangeData}
                                                                />
                                                                :
                                                                item.typeName === 'Password'?
                                                                    <PasswordElement
                                                                        index={index}
                                                                        item={item}
                                                                        handleChangeData={handleChangeData}
                                                                        handlePropertyChange={handlePropertyChange}
                                                                    />
                                                                    :
                                                                    item.typeName === 'Email'?
                                                                        <EmailElement
                                                                            index={index}
                                                                            item={item}
                                                                            handleChangeData={handleChangeData}
                                                                        />
                                                                        :
                                                                        item.typeName === 'Url'?
                                                                            <UrlElement
                                                                                index={index}
                                                                                item={item}
                                                                                handleChangeData={handleChangeData}
                                                                            />
                                                                            :
                                                                            item.typeName === 'Phone'?
                                                                                <PhoneElement
                                                                                    index={index}
                                                                                    item={item}
                                                                                    handleChangeData={handleChangeData}
                                                                                />
                                                                                :
                                                                                item.typeName === 'Date'?
                                                                                    <DateElement
                                                                                        index={index}
                                                                                        item={item}
                                                                                        handleChangeData={handleChangeData}
                                                                                    />
                                                                                    :
                                                                                    item.typeName === 'DateTime'?
                                                                                        <DateTimeElement
                                                                                            index={index}
                                                                                            item={item}
                                                                                            handleChangeData={handleChangeData}
                                                                                        />
                                                                                        :
                                                                                        item.typeName === 'Time'?
                                                                                            <TimeElement
                                                                                                index={index}
                                                                                                item={item}
                                                                                                handleChangeData={handleChangeData}
                                                                                            />
                                                                                            :
                                                                                            item.typeName === 'CheckBox'?
                                                                                                <CheckBoxElement
                                                                                                    index={index}
                                                                                                    item={item}
                                                                                                    handleChangeData={handleChangeData}
                                                                                                />
                                                                                                :
                                                                                                item.typeName === 'Radio'?
                                                                                                    <RadioElement
                                                                                                        index={index}
                                                                                                        item={item}
                                                                                                        handleChangeData={handleChangeData}
                                                                                                    />
                                                                                                    :
                                                                                                    item.typeName === 'Select'?
                                                                                                        <SelectElement
                                                                                                            index={index}
                                                                                                            item={item}
                                                                                                            handleChangeData={handleChangeData}
                                                                                                        />
                                                                                                        :
                                                                                                        item.typeName === 'MultiSelect'?
                                                                                                            <MultiSelectElement
                                                                                                                index={index}
                                                                                                                item={item}
                                                                                                                handleChangeData={handleChangeData}
                                                                                                            />
                                                                                                            :
                                                                                                            item.typeName === 'Location'?
                                                                                                                <Location
                                                                                                                    index={index}
                                                                                                                    item={item}
                                                                                                                    handleChangeData={handleChangeData}
                                                                                                                />
                                                                                                                :
                                                                                                                item.typeName === 'File'?
                                                                                                                    <FileElement
                                                                                                                        index={index}
                                                                                                                        item={item}
                                                                                                                        handleChangeData={handleChangeData}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    item.typeName === 'Image'?
                                                                                                                        <ImageElement
                                                                                                                            index={index}
                                                                                                                            item={item}
                                                                                                                            handleChangeData={handleChangeData}
                                                                                                                        />
                                                                                                                        :
                                                                                                                        item.typeName === 'Audio'?
                                                                                                                            <AudioElement
                                                                                                                                index={index}
                                                                                                                                item={item}
                                                                                                                                handleChangeData={handleChangeData}
                                                                                                                            />
                                                                                                                            :
                                                                                                                            item.typeName === 'Video'?
                                                                                                                                <VideoElement
                                                                                                                                    index={index}
                                                                                                                                    item={item}
                                                                                                                                    handleChangeData={handleChangeData}
                                                                                                                                />
                                                                                                                            :
                                                                                                                            null


                            )
                        }



                        {/*/!*پاراگراف*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*        تک خط :*/}
                        {/*    </label>*/}
                        {/*    <textarea rows={5} cols={2}  className={"harmony-input harmony-w-full"} />*/}
                        {/*</div>*/}
                        {/*/!*پاراگراف*!/*/}

                        {/*/!*چند گزینه ای*!/*/}
                        {/*/!*4تایی*!/*/}
                        {/*<div className={"harmony-space-y-3 harmony-col-span-1 harmony-md-col-span-4"}>*/}
                        {/*    <h2 className="harmony-text-xs ">  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</h2>*/}
                        {/*    <div className="harmony-grid harmony-grid-cols-2 harmony-md-grid-cols-4 harmony-gap-2">*/}
                        {/*        <label htmlFor={"1"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"checkbox"} id={"1"} ></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"2"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"checkbox"} id={"2"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"3"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"checkbox"} id={"3"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"4"} className="harmony-flex harmony-items-center harmoy-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"checkbox"} id={"4"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*/!*4تایی*!/*/}
                        {/*/!*2تایی*!/*/}
                        {/*<div className={"harmony-space-y-3 harmony-col-span-1 harmony-md-col-span-4"}>*/}
                        {/*    <h2 className="harmony-text-xs ">  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</h2>*/}
                        {/*    <div className="harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-2 harmony-gap-2">*/}
                        {/*        <label htmlFor={"5"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"checkbox"} id={"5"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"6"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"checkbox"} id={"6"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"7"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"checkbox"} id={"7"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"8"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"checkbox"} id={"8"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*/!*2تایی*!/*/}
                        {/*/!*چند گزینه ای*!/*/}

                        {/*/!*یک گزینه ای*!/*/}
                        {/*/!*4تایی*!/*/}
                        {/*<div className={"harmony-space-y-3 harmony-col-span-1 harmony-md-col-span-4"}>*/}
                        {/*    <h2 className="harmony-text-xs ">  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</h2>*/}
                        {/*    <div className="harmony-grid harmony-grid-cols-2 harmony-md-grid-cols-4 harmony-gap-2">*/}
                        {/*        <label htmlFor={"60"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"radio"} id={"60"} name={"3"} className={"harmony-input"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"70"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"radio"} id={"70"} name={"3"} className={"harmony-input"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"80"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"radio"} id={"80"} name={"3"} className={"harmony-input"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"90"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"radio"} id={"90"} name={"3"} className={"harmony-input"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*/!*4تایی*!/*/}
                        {/*/!*2تایی*!/*/}
                        {/*<div className={"harmony-space-y-3 harmony-col-span-1 harmony-md-col-span-4"}>*/}
                        {/*    <h2 className="harmony-text-xs ">  لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</h2>*/}
                        {/*    <div className="harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-2 harmony-gap-x-2 harmony-gap-y-3">*/}
                        {/*        <label htmlFor={"10"}  className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"radio"} id={"10"} name={"2"} className={"harmony-input"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"20"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"radio"} id={"20"} name={"2"} className={"harmony-input"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"30"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"radio"} id={"30"} name={"2"} className={"harmony-input"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*        <label htmlFor={"40"} className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer">*/}
                        {/*            <input type={"radio"} id={"40"} name={"2"} className={"harmony-input"}></input>*/}
                        {/*            <h2>لورم ایپسوم متن ساختگی???</h2>*/}
                        {/*        </label>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*/!*2تایی*!/*/}
                        {/*/!*یک گزینه ای*!/*/}

                        {/*/!*انتخاب چنتایی از لیست*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*        چند نفر را انتخاب کنید :*/}
                        {/*    </label>*/}
                        {/*    <select name="" id="" className={"harmony-input harmony-w-full"} multiple={true}>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*/!*انتخاب چنتایی از لیست*!/*/}

                        {/*/!*انتخاب  از لیست*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*        تک خط :*/}
                        {/*    </label>*/}
                        {/*    <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*        تک خط :*/}
                        {/*    </label>*/}
                        {/*    <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony--md-col-span-3">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*        تک خط :*/}
                        {/*    </label>*/}
                        {/*    <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">*/}
                        {/*        تک خط :*/}
                        {/*    </label>*/}
                        {/*    <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*        <option value="">مسعود</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}
                        {/*/!*انتخاب از لیست*!/*/}

                        {/*/!*عددی*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">تعداد</label>*/}
                        {/*    <input type={"number"} min={0} max={100} className={"harmony-input harmony-w-full"} />*/}

                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">تعداد</label>*/}
                        {/*    <input type={"number"} min={0} max={100} className={"harmony-input harmony-w-full"} />*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">تعداد</label>*/}
                        {/*    <input type={"number"} min={0} max={100} className={"harmony-input harmony-w-full"} />*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">تعداد</label>*/}
                        {/*    <input type={"number"} min={0} max={100} className={"harmony-input harmony-w-full"} />*/}
                        {/*</div>*/}
                        {/*/!*عددی*!/*/}
                        {/*/!*جدا کننده*!/*/}
                        {/*<hr className={"harmony-my-2 harmony-bg-color harmony-col-span-1 harmony-md-col-span-4 harmony-h-1 "}/>*/}
                        {/*<h4 className="harmony-px-2 harmony-border-b-2 harmony-border-color harmony-col-span-1 harmony-md-col-span-4"> آدرس</h4>*/}
                        {/*/!*جدا کننده*!/*/}

                        {/*/!*فایل*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs" >فایل</label>*/}
                        {/*    <input type={"file"} className={"harmony-input harmony-w-full harmony-border-gray-300 harmony-shadow"}></input>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">فایل</label>*/}
                        {/*    <div>*/}
                        {/*        <input type={"file"} className="harmony-input harmony-w-full harmony-border-gray-300 harmony-shadow"></input>*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">فایل</label>*/}
                        {/*    <div>*/}
                        {/*        <input type={"file"} className={"harmony-input harmony-w-full harmony-border-gray-300 harmony-shadow"}></input>*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        {/*/!*فایل*!/*/}

                        {/*/!*تاریخ*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 ">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">تاریخ</label>*/}
                        {/*    <input type={"date"} className={"harmony-input harmony-w-full"}></input>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">تاریخ</label>*/}
                        {/*    <div>*/}
                        {/*        <input type={"date"} className="harmony-input harmony-w-full"></input>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*/!*تاریخ*!/*/}

                        {/*/!*ساعت*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 ">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">ساعت</label>*/}
                        {/*    <input type={"time"} className={"harmony-input harmony-w-full"}></input>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">ساعت</label>*/}
                        {/*    <div>*/}
                        {/*        <input type={"time"} className="harmony-input harmony-w-full"></input>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*/!*ساعت*!/*/}

                        {/*/!*تاریخ ساعت*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 ">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">تاریخ ساعت</label>*/}
                        {/*    <input type={"time"} className={"harmony-input harmony-w-full"}></input>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">تاریخ ساعت</label>*/}
                        {/*    <div>*/}
                        {/*        <input type={"time"} className="harmony-input harmony-w-full"></input>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*/!*تاریخ و ساعت*!/*/}

                        {/*/!*لینک وب سایت*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">لینک</label>*/}
                        {/*    <input type={"url"} className={"input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">لینک</label>*/}
                        {/*    <input type={"url"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">لینک</label>*/}
                        {/*    <input type={"url"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">لینک</label>*/}
                        {/*    <input type={"url"} className={"input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*/!*لینک وب سایت*!/*/}

                        {/*/!*ایمیل*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">ایمیل</label>*/}
                        {/*    <input type={"email"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">ایمیل</label>*/}
                        {/*    <input type={"email"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">ایمیل</label>*/}
                        {/*    <input type={"email"} className={"input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">ایمیل</label>*/}
                        {/*    <input type={"email"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*/!*ایمیل*!/*/}

                        {/*/!*ایمیل*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">تلفن</label>*/}
                        {/*    <input type={"tel"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">تلفن</label>*/}
                        {/*    <input type={"tel"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">تلفن</label>*/}
                        {/*    <input type={"tel"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">تلفن</label>*/}
                        {/*    <input type={"tel"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*/!*ایمیل*!/*/}


                        {/*/!*رنج*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">رنج</label>*/}
                        {/*    <div className="">*/}
                        {/*        <input type="range"  min="1" max="100"  className="harmony-w-full" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">رنج</label>*/}
                        {/*    <div className="px-2">*/}
                        {/*        <input type="range"  min="1" max="100" className="harmony-w-full" />*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label className="dark:text-gray-300 harmony-text-xs">رنج</label>*/}
                        {/*    <div>*/}
                        {/*        <input type="range"  min="1" max="100" className="harmony-w-full" />*/}
                        {/*    </div>*/}

                        {/*</div>*/}
                        {/*/!*رنج*!/*/}

                        {/*/!*رنگ*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">رنگ</label>*/}
                        {/*    <div>*/}
                        {/*        <input type={"color"} className={"harmony-form-x"}/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*/!*رنگ*!/*/}

                        {/*/!*پسورد*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">پسورد</label>*/}
                        {/*    <input type={"password"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">پسورد</label>*/}
                        {/*    <input type={"password"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">پسورد</label>*/}
                        {/*    <input type={"password"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">پسورد</label>*/}
                        {/*    <input type={"password"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*/!*پسورد*!/*/}

                        {/*/!*قیمت*!/*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">قیمت</label>*/}
                        {/*    <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-2">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">قیمت</label>*/}
                        {/*    <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">قیمت</label>*/}
                        {/*    <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <label  className="dark:text-gray-300 harmony-text-xs">قیمت</label>*/}
                        {/*    <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*</div>*/}
                        {/*/!*قیمت*!/*/}

                        {/*/!*آدرس*!/*/}
                        {/*<h4 className="harmony-px-2 harmony-border-b-2 harmony-border-color harmony-col-span-1 harmony-md-col-span-4  "> ادرس خود را وارد کنید</h4>*/}
                        {/*<div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-4">*/}
                        {/*    <div className="harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-3 harmony-gap-2">*/}
                        {/*        <div className="harmony-space-y-1">*/}
                        {/*            <label  className="dark:text-gray-300 harmony-text-xs">کشور</label>*/}
                        {/*            <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*                <option value="" selected>ایران</option>*/}
                        {/*                <option value="">امریکا</option>*/}
                        {/*                <option value="">المان</option>*/}
                        {/*            </select>*/}
                        {/*        </div>*/}
                        {/*        <div className="harmony-space-y-1">*/}
                        {/*            <label  className="dark:text-gray-300 harmony-text-xs">استان</label>*/}
                        {/*            <select name="" id="" className={"input w-full"} >*/}
                        {/*                <option value="" selected>ایران</option>*/}
                        {/*                <option value="">امریکا</option>*/}
                        {/*                <option value="">المان</option>*/}
                        {/*            </select>*/}
                        {/*        </div>*/}
                        {/*        <div className="harmony-space-y-1">*/}
                        {/*            <label  className="dark:text-gray-300 harmony-text-xs">شهر</label>*/}
                        {/*            <select name="" id="" className={"harmony-input harmony-w-full"} >*/}
                        {/*                <option value="" selected>ایران</option>*/}
                        {/*                <option value="">امریکا</option>*/}
                        {/*                <option value="">المان</option>*/}
                        {/*            </select>*/}
                        {/*        </div>*/}
                        {/*        <div className="harmony-space-y-1 harmony-col-span-1 harmony-md-col-span-3">*/}
                        {/*            <label  className="dark:text-gray-300 harmony-text-xs">آدرس تکمیلی</label>*/}
                        {/*            <input type={"text"} className={"harmony-input harmony-w-full"}/>*/}
                        {/*        </div>*/}

                        {/*    </div>*/}

                        {/*</div>*/}
                        {/*/!*آدرس*!/*/}










                    </div>

                </div>
                <ButtonSubmitAndCancel
                    onRegisterClick={()=>handleSubmitAnswers()}
                    onCancelClick={()=>redirectTo('/Form/Create')}
                />
            </div>
        </div>
    )
}