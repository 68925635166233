import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {Button} from "primereact/button";
import {GridTable} from "../../Components/Commans/Grid/GridTable";
import {FantasyModal} from "../../Components/Commans/UIParts/FantasyModal";
import {ListSearch} from "../../Components/Commans/Grid/ListSearch";
import {ListFilter} from "../../Components/Commans/Grid/ListFilter";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Components/Commans/UIParts/ButtonSearchList";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {InputNumber} from "primereact/inputnumber";
import {ToggleSwitch} from "../Commans/UIParts/ToggleSwitch";
import Select from "react-select";
import {SettingButton} from "../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../Commans/Dates/DateTimePicker";
import {useSetting} from "../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";


export const HandleInventoryProductionSeries = ()=>{
    const { t } = useTranslation();


    const [modal, setModal] = useState({ AddReminder: false,See:false, });

    const [IsOpenProductionSeries, setOpenProductionSeries] = useState(false);
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const openProductionSeries=()=>{
        setOpenProductionSeries(true)
    }

    const closeProductionSeries=()=>{
        setOpenProductionSeries(false)
    }


    const SeeOnHide = () => {
        setModal((prevState) => ({ ...prevState, See: !modal.See }));
    };




    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("product"),minWidth: 160,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },

        { headerName: t("Title"),minWidth: 120,

            cellRenderer:parameters=>

                <>
                    1
                </>

        },


        { headerName: t("DateManufacture"),minWidth: 130,
            cellRenderer:parameters=>
                <>
                    1400
                </>

        },

        {
            headerName: t("ExpirationDate") ,minWidth: 150,
            cellRenderer: parameters =>
                <>
                    1400
                </>

        },



        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>

                </div>
            }
        },

    ];

    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };





    return (

        <>
            <div class=" space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">جستجو براساس (****)</label>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}

                                />
                                {t("FromDate")}
                            </label>
                            <DateTimePicker  format={'YYYY/MM/DD'} />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}

                                />
                                {t("ToDate")}
                            </label>
                            <DateTimePicker   format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>openProductionSeries()}
                                            tooltipTitle={t("ProductionSeries")}

                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                    </>
                                }
                    />


                </div>
                <div>
                    <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                               enableRtl={true}
                               pagination={true}
                               getRowHeight={getRowHeight}
                               onGridReady={onGridReady}
                               quickFilterText={quickFilterText}
                    />
                </div>

                <FantasyModal openModal={modal.See} closeModal={SeeOnHide} title={t("event")}>
                    <div>
                        <div class="flex items-center border-b dark:border-themeDarkColorLine">
                            <div>
                                <ul class="flex gap-2 items-center justify-center p-2">
                                    <li>
                                        <img src="/Assets/Image/user/person1.png"
                                             className="w-10 h-10 block rounded-full overflow-hidden object-contain"
                                             alt="user" title="user"/>
                                    </li>
                                    <li className="flex gap-1">
                                        <h3 className="text-xs dark:text-gray-300">مسعود احمدی</h3>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div>
                                <InputTextarea name={"excerpt"} rows={15} cols={30} className={"input w-full text-xs"} value="منابع موزیک بدون کپی رایت، جهت اینسب منابع موزیک بدون کپی رایت، جهت اینسب منابع موزیک بدون کپی رایت، جهت اینسب"/>
                            </div>
                        </div>
                        <div>
                            <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("edit")}</button>
                        </div>
                    </div>



                </FantasyModal>
            </div>

            <FantasyModal openModal={IsOpenProductionSeries} closeModal={closeProductionSeries} title={t("ProductionSeries")} >

                <form method="post" >
                    <div className="space-y-2">
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className="space-y-1 md:col-span-2">
                                <label className="dark:text-gray-300 text-xs">{t("product")}</label>
                                <Select
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={'fixed'}
                                />
                            </div>

                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}

                                    />
                                    {t("DateManufacture")}</label>
                                <DateTimePicker   format={'YYYY/MM/DD'} />
                            </div>
                            <div class="space-y-2 ">
                                <label className="text-xs dark:text-gray-300">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}

                                    />
                                    {t("ExpirationDate")}</label>
                                <DateTimePicker  format={'YYYY/MM/DD'} />
                            </div>

                            <div className="space-y-1 md:col-span-2">
                                <label className="dark:text-gray-300 text-xs">{t("Title")}</label>
                                <InputText
                                    className={"input"}
                                />
                            </div>

                        </div>

                        <div className="">
                            <button type={"submit"} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative"}>
                                {t("Register")}
                            </button>
                        </div>
                    </div>
                </form>

            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>

        </>



    )
}