import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {GridTable} from "../../Commans/Grid/GridTable";
import {InputTextarea} from "primereact/inputtextarea";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {Image} from "primereact/image";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {AlertSetting, fileManager, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET} from "../../../Services/AxiosService/AxiosApiService";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {Export} from "../../Commans/Grid/Export";
import queryString from "query-string";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import Select from "react-select";
import {BrowserDownloader} from "../../../ApiServices/Public/FtpController";
import {BasicUrl} from "../../../Services/AxiosService/axiosClient";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import Swal from "sweetalert2";

export const HandleUploadDocumentUser = ()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [result,setResult] = useState(0);
    const[spinner,setSpinner] = useState(false);
    const [attachTypes,setAttachTypes] = useState({attaches:[]});
    const [isMenuOpen,setMenuOpen] = useState(false);
    const [fullName,setFullName] = useState('');
    const handleOpenMenu = () => {
        setMenuOpen(true);
    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }
    const onApiError=()=>{
        setSpinner(false);
    }
    const queryParams = queryString.parse(window.location.search);


    const handleDownloadDocument= async(filePath)=>{
        try {
            await BrowserDownloader(filePath,'/Ftp/DownloadFile');
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleDownloadAllDocuments=()=>{
        const url = `/Users/DownloadUserDocuments?Id=${queryParams.userId}`;
        window.location.href = `${BasicUrl.baseURL}${url}`
    }

    const handleDeleteDocument = async (attachId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`/Users/Documents/${attachId}`,);
                    if (response.status === 200) {
                        setSpinner(false);
                        setResult(prevState => prevState +1);
                    }
                    else {
                        handleApiError(response,setSpinner(false));
                    }
                }catch (e) {
                    setSpinner(false)
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    const handleFetchData=async()=>{
        try {
            setSpinner(true);
            const responseAttach = await GET('/Enumerations/EnAttachmentType',{},'IEnumerable');
            if (responseAttach.status === 200) {
                const attachTypes = convertSelectDataHandler(responseAttach.data);
                setAttachTypes((prevOptions) => ({ ...prevOptions, attaches: attachTypes }));
                if(queryParams.userId){
                    try {
                        const response = await GET(`/Users/Information/${queryParams.userId}`,{},'single');
                        if(response.status === 200){
                            const {fullName} = response.data;
                            setFullName(fullName);
                        }else {
                            handleApiError(response,onApiError);
                        }
                    }catch (e) {
                        onApiError()
                    }

                }
                setSpinner(false);
            }
        }
        catch (e) {
            onApiError()
        }
    }

    useEffect(()=>{
        handleFetchData();
    },[])

    const writeFileType = (type) => {
        if (!attachTypes.attaches) {
            return;
        }
        const data = attachTypes.attaches.find((item) => item.value === type);
        return data ? data.label : '';
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("FileName"),field: 'fileName', minWidth: 150},

        { headerName: t("FileType"),field: 'fileType', minWidth: 140,cellRenderer:params => {
                return writeFileType(params.data.type)
            }},

        { headerName:t("Uploaddate"), minWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.creationDate} /></>
            }
        },


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <button onClick={()=>handleDownloadDocument(params.data.filePath)} className="text-themeInformation">
                        <HPrimeIcon icon={'pi-download'}/>
                    </button>
                    <GridButtonDelete
                        onClick={()=>handleDeleteDocument(params.data.id)}
                    />
                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };



    const [filter,setFilter] = useState({search:'',Type:null,IsVisa:null,IsOther:null,IsEducationCertificate:null,IsInsurance:null,IsMarriageCertificate:null,IsBirthCertificate:null,IsIdCard:null,IsResidencePermit:null,isPassport:null,IsNationalCard:null,IsDrivingLicense:null,IsContract:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const filterSelectValue = (data,name)=>{
        setFilter(({...filter,[name]: data.value}));
    }
    const resetFilters = ()=>{
        setFilter(({...filter,search: '',Type:null,IsBirthCertificate: null,IsContract: null,IsDrivingLicense: null,IsEducationCertificate: null,IsIdCard: null,IsInsurance: null,IsMarriageCertificate:null,IsNationalCard: null,IsOther: null,isPassport: null,IsResidencePermit: null,IsVisa: null}));
        setSearch('');
    }

    useEffect(() => {
        OnGridReady();
    }, [result, currentPage, filter.search, data.pageSize,filter.IsBirthCertificate,filter.IsContract,filter.IsDrivingLicense,filter.IsEducationCertificate,filter.IsIdCard,filter.IsInsurance,filter.IsMarriageCertificate,filter.IsNationalCard,filter.IsOther,filter.isPassport,filter.IsResidencePermit,filter.IsVisa,filter.Type]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/Users/GetUserDocumentsList`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,IsBirthCertificate:filter.IsBirthCertificate,IsContract:filter.IsContract,IsDrivingLicense:filter.IsDrivingLicense,IsEducationCertificate:filter.IsEducationCertificate,IsIdCard:filter.IsIdCard,IsInsurance:filter.IsInsurance,IsMarriageCertificate:filter.IsMarriageCertificate,IsNationalCard:filter.IsNationalCard,IsOther:filter.IsOther,IsPassport:filter.isPassport,IsResidencePermit:filter.IsResidencePermit,IsVisa:filter.IsVisa,Id:queryParams.userId,Type:filter.Type}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };

    const switchData = [
        {name:'isPassport',lable:t("Passport"),value:filter.isPassport},
        {name:'IsNationalCard',lable:t("NationalIDCard"),value:filter.IsNationalCard},
        {name:'IsDrivingLicense',lable:t("DriversLicense"),value:filter.IsDrivingLicense},
        {name:'IsContract',lable:t("Contract"),value:filter.IsContract},
        {name:'IsVisa',lable:t("Visa"),value:filter.IsVisa},
        {name:'IsResidencePermit',lable:t("ResidencePermit"),value:filter.IsResidencePermit},
        {name:'IsIdCard',lable:t("IdentificationCard"),value:filter.IsIdCard},
        {name:'IsBirthCertificate',lable:t("BirthCertificate"),value:filter.IsBirthCertificate},
        {name:'IsMarriageCertificate',lable:t("MarriageCertificate"),value:filter.IsMarriageCertificate},
        {name:'IsInsurance',lable:t("InsurancePolicy"),value:filter.IsInsurance},
        {name:'IsEducationCertificate',lable:t("EducationalCertificate"),value:filter.IsEducationCertificate},
        {name:'IsOther',lable:t("other"),value:filter.IsOther},
    ]

    return (
        <>

            <div class=" space-y-5 relative">
                {
                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("NameCompany")}</label>
                            <InputText type={"text"} className={" input w-full"} value={fullName} disabled="true"/>
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("search")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full"  />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("FileType")}</label>
                            <Select
                                options={attachTypes.attaches}
                                isMulti={false}
                                isSearchable={true}
                                onChange={(data)=>filterSelectValue(data,'Type')}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                         <ButtonSearchList
                                onClickSearch={()=>handleSearch()}
                                onClickReset={()=>resetFilters()}
                         />
                    </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        functionClass={"harmony-creation"}
                                        onClick={()=>redirectTo(`/CRM/User/Add?id=${queryParams.userId}&tabId=document`)}
                                        tooltipTitle={t("UsersRegistration")}
                                    />
                                    <ButtonPlus
                                        icon={"pi-download"}
                                        title={t("Download")}
                                        onClick={()=>handleDownloadAllDocuments()}
                                        tooltipTitle={t("Download")}

                                    />
                                </>
                            }
                            childrenTwo={
                                <>
                                    <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                </>
                            }
                />


            </div>
                <div className={"space-y-2 H-body-ag-grid"} >
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>

                <ToastContainer />

                <Export/>

        </div>

        </>

    )
}