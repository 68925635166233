import {NumberInputFields} from "./NumberInputFields";
import {ItemFields} from "./ItemFields";
import {FileUploadFields} from "./FileUploadFields";
import {LocationFields} from "./LocationFields";
import {DateFields} from "./DateFields";
import {PhoneItemFields} from "./PhoneItemFields";
import {PasswordFields} from "./PasswordFields";
import {UrlFields} from "./UrlFields";

export const SelectedElementProperties = ({ handleDefaultValueChange,selectedElementIndex, elements, fields, handlePropertyChange, t,addCheckBoxItem,deleteCheckBoxItem ,handleItemPropertyChange}) => (

    selectedElementIndex !== null && (
        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
            <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                <span className={"dark:text-gray-300 line-clamp-1 text-xs"}>{t(elements[selectedElementIndex]?.typeName)}</span>
            </div>
            {
                elements[selectedElementIndex]?.typeName === 'TextBox' || elements[selectedElementIndex]?.typeName === 'TextArea' ||  elements[selectedElementIndex]?.typeName === 'Numeric' ||  elements[selectedElementIndex]?.typeName === 'Price' || elements[selectedElementIndex]?.typeName === 'Slider'?

                <NumberInputFields
                    index={selectedElementIndex}
                    element={elements[selectedElementIndex]}
                    handlePropertyChange={handlePropertyChange}
                    t={t}
                />
                    :
            elements[selectedElementIndex]?.typeName === 'Url' ||  elements[selectedElementIndex]?.typeName === 'Email'?
                <UrlFields
                    index={selectedElementIndex}
                    element={elements[selectedElementIndex]}
                    handlePropertyChange={handlePropertyChange}
                    t={t}
                />
            : elements[selectedElementIndex]?.typeName === 'CheckBox' ||  elements[selectedElementIndex]?.typeName === 'MultiSelect' || elements[selectedElementIndex]?.typeName === 'Select' ||
                elements[selectedElementIndex]?.typeName === 'Radio' ?
                    <ItemFields
                        element={elements[selectedElementIndex]}
                        t={t}
                        index={selectedElementIndex}
                        addCheckBoxItem={addCheckBoxItem}
                        deleteCheckBoxItem={deleteCheckBoxItem}
                        handleItemPropertyChange={handleItemPropertyChange}
                    />
                    :
                 elements[selectedElementIndex]?.typeName === 'Phone'?
                     <PhoneItemFields
                         element={elements[selectedElementIndex]}
                         t={t}
                         index={selectedElementIndex}
                         addCheckBoxItem={addCheckBoxItem}
                         deleteCheckBoxItem={deleteCheckBoxItem}
                         handleItemPropertyChange={handleItemPropertyChange}
                         handlePropertyChange={handlePropertyChange}
                     />
                    :
                elements[selectedElementIndex]?.typeName === 'File' ||  elements[selectedElementIndex]?.typeName === 'Image' ||  elements[selectedElementIndex]?.typeName === 'Video' || elements[selectedElementIndex]?.typeName === 'Audio'?
                      <FileUploadFields
                          element={elements[selectedElementIndex]}
                          t={t}
                          index={selectedElementIndex}
                          addCheckBoxItem={addCheckBoxItem}
                          deleteCheckBoxItem={deleteCheckBoxItem}
                          handleItemPropertyChange={handleItemPropertyChange}
                          handlePropertyChange={handlePropertyChange}
                      />
                    :
                    elements[selectedElementIndex]?.typeName === 'Location'?
                        <LocationFields
                            element={elements[selectedElementIndex]}
                            t={t}
                            index={selectedElementIndex}
                            addCheckBoxItem={addCheckBoxItem}
                            deleteCheckBoxItem={deleteCheckBoxItem}
                            handleItemPropertyChange={handleItemPropertyChange}
                            handlePropertyChange={handlePropertyChange}
                            handleDefaultValueChange={handleDefaultValueChange}

                        />
                    :
                        elements[selectedElementIndex]?.typeName === 'Date' || elements[selectedElementIndex]?.typeName === 'DateTime' || elements[selectedElementIndex]?.typeName === 'Time'?
                            <DateFields
                                element={elements[selectedElementIndex]}
                                t={t}
                                index={selectedElementIndex}
                                addCheckBoxItem={addCheckBoxItem}
                                deleteCheckBoxItem={deleteCheckBoxItem}
                                handleItemPropertyChange={handleItemPropertyChange}
                                handlePropertyChange={handlePropertyChange}
                            />
                            :
                            elements[selectedElementIndex]?.typeName === 'Password'?
                                <PasswordFields
                                    index={selectedElementIndex}
                                    element={elements[selectedElementIndex]}
                                    handlePropertyChange={handlePropertyChange}
                                    t={t}
                                />
                                :
                        null
            }
        </div>
    )
);
