import React from "react";
import {useTranslation} from "react-i18next";
import {MenuController} from "./MenuController";
import {HandleTicketRegistration} from "./NewTicketRegistration/HandleTicketRegistration";
import {ToastContainer} from "react-toastify";

export const HandlePublicOnlineTicket = () => {
  const { t } = useTranslation();


  return(
      <>

      <div class="space-y-5">
        <div className="grid grid-cols-12 gap-5 relative">
          <MenuController />
          <HandleTicketRegistration />
        </div>
      </div>
      <ToastContainer/>



  </>
  )
}