export const handleCreateFormDataDepartment = (obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Name', obj.name);
    appendIfNotNull('Order', obj.order);
    appendIfNotNull('Picture', obj.picture);
    appendIfNotNull('StateShow', obj.stateShow);
    appendIfNotNull('Description', obj.description);
    return formData
}

export const handleEditFormDataDepartment = (obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Id', obj.id);
    appendIfNotNull('Name', obj.name);
    appendIfNotNull('Order', obj.order);
    appendIfNotNull('Picture', obj.picture);
    appendIfNotNull('StateShow', obj.stateShow);
    appendIfNotNull('Description', obj.description);
    return formData
}