import React, {useEffect, useMemo, useState} from "react";

import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import { SelectButton } from 'primereact/selectbutton';
import Select from "react-select";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {useOrganization} from "../useOrganization";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {InputNumber} from "primereact/inputnumber";
import {validate} from "../../../../Services/Globals/validate";
import {organizationSchema} from "../organizationSchema";
import {Export} from "../../../Commans/Grid/Export";
import {useOrganizationGroup} from "./useOrganizationGroup";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {Tooltip} from "react-tooltip";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {Image} from "primereact/image";
import {Link} from "react-router-dom";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {handleCreateOrganizationGroup, handleEditOrganizationGroup} from "./linkOrganizationGroupService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useOrganizationGroupGrid} from "./useOrganizationGroupGrid";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";

export const  HandleFormOrganize = () => {
    const { t } = useTranslation();
    const [errors,setErrors]=useState({});
    const [isLoading,setIsLoading] = useState(false);
    const {setOrganizationGroup,organizationGroup,handleValueOrganizationGroup,handleNumberValueOrganizationGroup, handleRemoveImagePath,handleChangeImage, handleResetImages}=useOrganizationGroup(setErrors)
    const [IsEdit,setIsEdit]=useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const [IsOpenGroupOrganization,setIsOpenGroupOrganizaion]=useState(false);
    const handleResetError=()=> setErrors({});
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }

    const onSetErrors=(errors)=>{
        setErrors(errors);
    }

    const showLoading=()=>{
        setIsLoading(true)
    }
    const hideLoading=()=>{
        setIsLoading(false);
    }
    const showSpinner=()=> setIsShowSpinner(true);
    const hideSpinner=()=> setIsShowSpinner(false);


    const handleOpenOrganizationGroup=()=>{
        setOrganizationGroup((prevState)=>({...prevState,rank: 0}))
        setIsOpenGroupOrganizaion(true)
    }
    const handleCloseOrganizationGroup=()=>{
        setOrganizationGroup((prevState)=>({...prevState,rank: 0,caption: '',icon: null,iconPath:''}))
        setIsOpenGroupOrganizaion(false);
        handleResetError();
        setIsEdit(false);
    }



    const handleSubmitOrganizationGroup=async(e)=>{
        e.preventDefault();
        const isValid = validate(organizationSchema,organizationGroup,onSetErrors);
        if(!isValid)return;
        try {
            if(IsEdit){
                showLoading();
                const linkOrganizationGroup = handleEditOrganizationGroup(organizationGroup);
                const response = await PUT(`/Forms/LinkOrganizationGroups`,linkOrganizationGroup,true);
                if(response.status === 200){
                    hideLoading();
                    Refresh();
                    handleSuccessMessage(t('SuccessDone'));
                    handleCloseOrganizationGroup();
                }
                else{
                    handleApiError(response,hideLoading);
                }
            }
            else{
                showLoading();
                const linkOrganizationGroup = handleCreateOrganizationGroup(organizationGroup);
                const response = await POST(`/Forms/LinkOrganizationGroups`,linkOrganizationGroup,true);
                if(response.status === 200){
                  hideLoading();
                  Refresh();
                  handleSuccessMessage(t('SuccessDone'));
                  handleCloseOrganizationGroup();
                }
                else{
                    handleApiError(response,hideLoading);
                }
            }
        }catch (e) {
            hideLoading();
        }
    }

    const {handleDeleteOrganizationGroup,handleGetOrganizationGroup,rowData,autoGroupColumnDef,columnDefs,defaultColDef,handleSearch,getRowHeight,handleCurrentPage,handleNextPage,handlePreviousPage,handlePageSizeCount,handleResetFilters,setFilter,filter,pagination}=useOrganizationGroupGrid(showSpinner,hideSpinner,setIsOpenGroupOrganizaion,setIsEdit,refresh,Refresh,setOrganizationGroup,organizationGroup);







    return(
        <>
            {console.log(organizationGroup)}
            <div class="space-y-5 ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                            <InputText onChange={(e)=>setFilter(({...filter,searchValue:e.target.value}))} value={filter.searchValue} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <ButtonSearchList
                            onClickReset={()=>handleResetFilters()}
                            onClickSearch={()=>handleSearch()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleOpenOrganizationGroup()}
                                            tooltipTitle={"CreatingMenu"}
                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />


                </div>
                <div className={"H-body-ag-grid space-y-2"}>
                    {
                        IsShowSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div>
                        <GridTable
                            columnDefs={columnDefs}
                            rowSelection={GridSetting.rowSelection}
                            rowData={rowData}
                            style={GridSetting.gridStyle}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            pagination={GridSetting.pagination}
                            enableRtl={GridSetting.enableRtl}
                            domLayout={GridSetting.domLayout}
                            paginationPageSize={GridSetting.pageSize}
                            getRowHeight={getRowHeight}
                            localeText={AgGridEnglish}
                        />
                        <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    </div>
                </div>
              <Export filters={''} apiUrl={''}/>
            </div>




            <FantasyModal openModal={IsOpenGroupOrganization}  closeModal={handleCloseOrganizationGroup} title={t("CreatingMenu")} customeClassName={"modal-sm"}>
                <form method={'post'} onSubmit={handleSubmitOrganizationGroup}>
                    <div className="space-y-5">
                        <div className={"w-full h-36 tooltip_class_icon"}>
                            <UploadImage isEdit={IsEdit} removeFile={handleRemoveImagePath} removeName={'iconPath'} accept={'image/*'} resetImages={handleResetImages} OnValueSelection={handleChangeImage} value={organizationGroup.icon ? organizationGroup.icon:null} name={'icon'} />
                            <Tooltip anchorSelect={".tooltip_class_icon"} content={t("Icon")} positionStrategy={"fixed"}	 />
                        </div>
                        <div className={"flex gap-1"}>
                            <div class="space-y-2 flex-1">
                                <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                                <DisplayError message={errors.caption}/>
                                <InputText name={'caption'} onInput={(e)=>handleValueOrganizationGroup(e)} value={organizationGroup.caption} type={"text"} className={" input w-full"} />
                            </div>
                            <div class="space-y-2 ">
                                <label className="text-xs dark:text-gray-300">{t("rank")}</label>
                                <div className={"w-16"}>
                                    <InputNumber value={organizationGroup.rank} min={0} name={'rank'} onChange={(e)=>handleNumberValueOrganizationGroup(e.value,'rank')} showButtons type={"text"}  />
                                </div>
                            </div>
                        </div>
                        <div>

                            <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation place-content-center text-white relative"} >
                                {t("insertion")}
                                {isLoading ? (
                                    <TinyLoading
                                    />
                                ) : (
                                    null
                                )}
                            </button>
                        </div>
                    </div>

                </form>

            </FantasyModal>
        </>
    )
};
