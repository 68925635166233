import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";

import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const useGoogleSiteMapGrid=(showSpinner,hideSpinner,refresh,Refresh,setIsEdit,setIsOpen,setSiteMap,initialSource)=>{
    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const queryParams = queryString.parse(window.location.search);
    const [rowData, setRowData] = useState([]);
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");


    const handleGetSiteMapPattern= async(siteMapId)=>{
        try {
            showSpinner();
            setIsEdit(true);
            const response = await GET(`/Seo/SiteMapPattern/${siteMapId}`,{},'single');
            if(response.status === 200){
                setSiteMap({
                    siteMapId:response.data.siteMapId,
                    name:response.data.name,
                    isDefault:response.data.isDefault,
                    siteMapType:response.data.siteMapType,
                    slugFilterType:response.data.slugFilterType,
                    scheduleFrequency:initialSource.ScheduleFrequencies.find((item)=>item.value === response.data.scheduleFrequency),

                    items: initialSource.slugs.map((item) => {
                        const matchingResponseItem = response.data.items.find((responseItem) => responseItem.slugType === item.value);
                        return {
                            siteMapItemId: matchingResponseItem ? matchingResponseItem.siteMapItemId : null,
                            slugType: item.value,
                            siteMapFrequency: matchingResponseItem ? initialSource.siteMapFrequencies.find((fre)=>fre.value === matchingResponseItem.siteMapFrequency  )  : initialSource.siteMapFrequencies[0],
                            priority: matchingResponseItem ? matchingResponseItem.priority : 0.8,
                            isChecked: !!matchingResponseItem,
                            label : item.label
                        };
                    })

                })
                hideSpinner();
                setIsOpen(true);
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    const handleDeleteSiteMapPattern = async (siteMapId) => {
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    showSpinner()
                    const response = await DELETE(`/Seo/SiteMapPattern/${siteMapId}`, {headers: {'Content-Type': 'application/json'}},)
                    if (response.status === 200) {
                        Refresh()
                        hideSpinner()
                        handleSuccessMessage(t("SuccessDone"));
                    } else {
                        handleApiError(response, hideSpinner)
                    }
                } catch (e) {
                    hideSpinner();
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const handleChangeActivation = async(siteMapId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            const response = await PUT(`/Seo/SiteMapActivation`,{siteMapId:siteMapId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh()
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }catch (e) {
            setWait(false)
        }
    }


    const ExecuteSiteMap=async(siteMapId)=>{
        try {
           showSpinner();
           const response = await POST(`/Seo/SiteMap`,{siteMapId:siteMapId},false);
           if(response.status === 200){
               Refresh();
               handleSuccessMessage(t('SuccessDone'));
           }
           else{
               handleApiError(response,hideSpinner);
           }
        }
        catch (e) {
            hideSpinner();
        }
    }

    const handleGetSchedule = (schedule) => {
        if (!initialSource.ScheduleFrequencies) {
            return;
        }
        const data = initialSource.ScheduleFrequencies.find((item) => item.value === schedule);
        return data ? data.label : '';
    }

    const handleGetSiteMapType = (type) => {
        if (!initialSource.siteMapTypes) {
            return;
        }
        const data = initialSource.siteMapTypes.find((item) => item.value === type);
        return data ? data.label : '';
    }


    const columnDef = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("Name"),
            cellRenderer:parameters=>
                <>{parameters.data.name}</>
        },
        { headerName:t("Timing") ,

            cellRenderer:parameters=>
                <>
                    {handleGetSchedule(parameters.data.scheduleFrequency)}
                </>

        },
        { headerName: t("Status"),field: 'isDefault', maxWidth: 120, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading  />
                    ) : params.data.isDefault ? (
                        <HPrimeIcon iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeActivation(params.data.siteMapId,params.colDef.headerName)}
                                     icon={'pi-check-square'}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeActivation(params.data.siteMapId,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'pi-times-circle'}

                        />
                    )}
                </div>
        },
        { headerName: t("Run"), maxWidth: 120,
            cellRenderer:parameters=>
                <div >
                    <HPrimeIcon onClick={async()=> await ExecuteSiteMap(parameters.data.siteMapId) } iconClass={'cursor-pointer'}  icon={"pi-code"}/>
                </div>
        },


        { headerName: t("Manufacturingmethod"),
            cellRenderer:parameters=>
                <>
                    {handleGetSiteMapType(parameters.data.type)}
                </>
        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetSiteMapPattern(params.data.siteMapId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteSiteMapPattern(params.data.siteMapId)}
                    />
                </div>
            }
        },

    ];




    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination, setPagination] = useState({
        totalPages: 0,
        page: 0,
        pageSize: GridSetting.pageSize,
        totalCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        lastPage: 0,
        currentPage: 1
    })
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({...pagination, pageSize: pageSize}))
    }


    const [filter, setFilter] = useState({
        search: '',

    });

    const SwitchFilters = [
        {name:'IsPrivate',lable:t("Private"),value:filter.IsPrivate},
        {name:'IsPublic',lable:t("Public"),value:filter.IsPublic},
    ]


    const [search, setSearch] = useState('');
    const handleSearch = () => {
        setFilter((prevState) => ({...prevState, search: search}));
    }
    const handleToggleFilter = (data, name) => {
        data ? setFilter(({...filter, [name]: data})) : setFilter(({...filter, [name]: null}));
    }
    const handleResetFilters = () => {
        setFilter(({
            ...filter,
            search: '',
        }));
        setSearch('');
    }




    useEffect(() => {
        OnGridReady();
    }, [refresh,pagination.currentPage, filter.search, pagination.pageSize]);


    const OnGridReady = async () => {
        try {
            showSpinner()
            const response = await GET(`/Seo/SiteMapPattern`, {
                PageNumber: pagination.currentPage,
                PageSize: pagination.pageSize,
                Search: filter.search,

            }, 'PagedList');
            if (response && response.items !== null) {
                setPagination({
                    ...pagination,
                    totalPages: response.totalPages,
                    hasNextPage: response.hasNextPage,
                    hasPreviousPage: response.hasPreviousPage,
                    page: response.page,
                    pageSize: response.pageSize,
                    totalCount: response.totalCount
                });
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            } else {
                handleApiError(response, hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    }
    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        handleToggleFilter,
        columnDef,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        SwitchFilters,
    }



}