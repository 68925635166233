
import {StatusMessage} from "./StatusMessage";
import {handleErrorResponse} from "./handleErrorResponse";
import {handleFailureMessage} from "./NotificationService"; // Import your utility functions
export const handleApiError = (response,onApiError) => {
    if (response.status === 400) {
        handleErrorResponse(response.error);
        onApiError && onApiError();
    }
    else if(response.status === 204){
        console.log('204');
    }
    else if (response.status === 500) {
        handleFailureMessage(StatusMessage.status_serverError);
        onApiError && onApiError();
    } else {
        handleFailureMessage(StatusMessage.status_serverError);
        onApiError && onApiError();
    }
};
