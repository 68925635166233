import React, {useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const HandleAddAutomatic=()=>{
    const { t } = useTranslation();

    const [modal, setModal] = useState({ addCheque: false, addProject: false , addBank:false , addFinanceBox:false  });

    const addProjectOnHide = () => {
        setModal((prevState) => ({ ...prevState, addProject: !modal.addProject }));
    };

    const addBankOnHide = () => {
        setModal((prevState) => ({ ...prevState, addBank: !modal.addBank }));
    };

    const addChequeOnHide = () => {
        setModal((prevState) => ({ ...prevState, addCheque: !modal.addCheque }));
    };
    const addFinanceBoxOnHide = () => {
        setModal((prevState) => ({ ...prevState, addFinanceBox: !modal.addFinanceBox }));
    };


    return(

        <div>
            <div class=" space-y-5">
                <div className="space-y-5">
                    <div class="flex gap-2 items-center box bg-white dark:bg-themeDarkSalt700 p-3">
                        <div className="dark:text-gray-300">نوع تراکنش</div>
                        <div class="pos">
                            <div class=" pos__tabs nav-tabs justify-center flex gap-3 post-input dark:bg-themeDarkSalt600 px-2 py-1 rounded">
                                <a data-toggle="tab" data-target="#tab_one" href="javascript:;" className="lg:w-24 px-2 py-2 rounded-md flex justify-center items-center dark:text-gray-300 active">دریافت</a>
                                <a data-toggle="tab" data-target="#tab_two" href="javascript:;" className="lg:w-24 px-2 py-2 rounded-md flex justify-center items-center dark:text-gray-300">پرداخت</a>
                                <a data-toggle="tab" data-target="#tab_three" href="javascript:;" className="lg:w-24 px-2 py-2 rounded-md flex justify-center items-center dark:text-gray-300">درآمد</a>
                                <a data-toggle="tab" data-target="#tab_four" href="javascript:;" className="lg:w-24 px-2 py-2 rounded-md flex justify-center items-center dark:text-gray-300">هزینه</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-content">
                    <div class="tab-content__pane  space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700 active" id="tab_one">
                        <div class="grid grid-cols-12 gap-5  ">
                            <div class="col-span-12 lg:col-span-8 space-y-5">
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded-md bg-green-200 dark:bg-themeDarkPostInput border dark:border-themeDarkColorLine p-3">
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">عنوان:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">تاریخ ثبت:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">پروژه:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addProject: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="پروژه">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">مبلغ کل:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded-md bg-green-200 dark:bg-themeDarkPostInput border dark:border-themeDarkColorLine p-3 ">
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">کارتخوان:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="col-span-1 space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">نام بانک:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBank: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="بانک">*/}
                                        {/*    <option value="1">ملت</option>*/}
                                        {/*    <option value="2">تجارت</option>*/}
                                        {/*    <option value="3">پارسیان</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">نقد:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">نام صندوق:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFinanceBox: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="صندوق">*/}
                                        {/*    <option value="1">مهر مسعود</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="text-xs dark:text-gray-300">چک</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addCheque: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">مبلغ تخفیف:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">مانده بدهی</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class=" flex gap-5 items-end">
                                        <div>
                                            <input type="checkbox"  checked/>
                                                <label className=" dark:text-gray-300">(%3) عوارض </label>
                                        </div>
                                        <div>
                                            <input type="checkbox"  checked/>
                                                <label className=" dark:text-gray-300">(%6) مالیات </label>
                                        </div>
                                        <div>
                                            <h3 className=" dark:text-gray-300"> 9% مالیات برارزش افزوده</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4 box bg-white dark:bg-themeDarkSalt700 rounded-md ">
                                <div class=" space-y-5">
                                    <div class=" space-y-3 border border-themeDarkColorLine">
                                        <div className="p-2 bg-gray-200 dark:bg-themeDarkPostInput rounded-t-md dark:text-gray-300">
                                            <h2>لیست اسناد</h2>
                                        </div>
                                        <div>
                                            <ul class="space-y-3 List_documents py-4 px-3">
                                                <li>
                                                    <div class="bg-yellow-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIconSmall icon={'pi-trash'} />
                                                        </button>
                                                        <div class="col-span-2 text-center">
                                                            <h3>بانک پارسیان</h3>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                شماره چک:
                                                                1234567891234567
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="bg-green-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} />
                                                        </button>
                                                        <div class="col-span-2">
                                                            <h2 class="text-center">پرداخت با کارتخوان</h2>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                بانک:
                                                                ملت
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="bg-red-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} size={18}/>
                                                        </button>
                                                        <div class="col-span-2">
                                                            <h2 class="text-center">پرداخت نقدی </h2>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                صندوق:
                                                                مهر مسعود
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <button className=" bg-themeInformation dark:bg-themeDarkInformation text-white button w-full rounded-md flex items-center justify-center gap-2  ">
                                            <HPrimeIcon icon={'bookmark'}/>
                                            ثبت
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content__pane  space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700 " id="tab_two">
                        <div class="grid grid-cols-12 gap-5 relative ">
                            <div class="col-span-12 lg:col-span-8 space-y-5">
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded shadow bg-pink-100 dark:bg-themeDarkPostInput border p-3">
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">عنوان:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">تاریخ ثبت:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300 text-xs">پروژه:</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addProject: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="پروژه">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">مبلغ کل:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded bg-pink-100 dark:bg-themeDarkPostInput border shadow p-3 ">
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">کارتخوان:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="col-span-1 space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300 text-xs">نام بانک:</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBank: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}

                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="بانک">*/}
                                        {/*    <option value="1">ملت</option>*/}
                                        {/*    <option value="2">تجارت</option>*/}
                                        {/*    <option value="3">پارسیان</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">نقد:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300 text-xs">نام صندوق:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFinanceBox: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="صندوق">*/}
                                        {/*    <option value="1">مهر مسعود</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300 text-xs">چک</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addCheque: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>

                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">مبلغ تخفیف:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300 text-xs">مانده بدهی</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class=" flex gap-5 items-end">
                                        <div>
                                            <input type="checkbox"  checked/>
                                            <label className=" dark:text-gray-300">(%3) عوارض </label>
                                        </div>
                                        <div>
                                            <input type="checkbox"  checked/>
                                            <label className=" dark:text-gray-300">(%6) مالیات </label>
                                        </div>
                                        <div>
                                            <h3 className=" dark:text-gray-300"> 9% مالیات برارزش افزوده</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4 box bg-white dark:bg-themeDarkSalt700 rounded-md overflow-hidden">
                                <div class=" space-y-5">
                                    <div class=" space-y-3 border">
                                        <div className=" p-2 bg-gray-200 flex items-center gap-2">
                                            <h2>لیست اسناد</h2>
                                        </div>
                                        <div class="p-3">
                                            <ul class="space-y-3 List_documents py-4">
                                                <li>
                                                    <div class="bg-yellow-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} size={18}/>
                                                        </button>
                                                        <div class="col-span-2 text-center">
                                                            <h3>بانک پارسیان</h3>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                شماره چک:
                                                                1234567891234567
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="bg-green-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} size={18}/>
                                                        </button>
                                                        <div class="col-span-2">
                                                            <h2 class="text-center">پرداخت با کارتخوان</h2>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                بانک:
                                                                ملت
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="bg-red-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} />
                                                        </button>
                                                        <div class="col-span-2">
                                                            <h2 class="text-center">پرداخت نقدی </h2>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                صندوق:
                                                                مهر مسعود
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <button className=" bg-themeInformation text-white py-3 w-full rounded-md flex items-center justify-center gap-2 text-lg ">
                                            <HPrimeIcon icon={'bookmark'}/>
                                            ثبت
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content__pane  space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700 " id="tab_three">
                        <div class="grid grid-cols-12 gap-5 relative ">
                            <div class="col-span-12 lg:col-span-8 space-y-5">
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded shadow bg-blue-100 dark:bg-themeDarkPostInput border p-3">
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">عنوان:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">تاریخ ثبت:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300">پروژه:</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addProject: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}

                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="پروژه">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">مبلغ کل:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded bg-blue-100 dark:bg-themeDarkPostInput border shadow p-3 ">
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">کارتخوان:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="col-span-1 space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300">نام بانک:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBank: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}

                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="بانک">*/}
                                        {/*    <option value="1">ملت</option>*/}
                                        {/*    <option value="2">تجارت</option>*/}
                                        {/*    <option value="3">پارسیان</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">نقد:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300">نام صندوق:</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFinanceBox: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="صندوق">*/}
                                        {/*    <option value="1">مهر مسعود</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300">چک</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addCheque: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>

                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">مبلغ تخفیف:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">مانده بدهی</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class=" flex gap-5 items-end">
                                        <div>
                                            <input type="checkbox"  checked/>
                                            <label className=" dark:text-gray-300">(%3) عوارض </label>
                                        </div>
                                        <div>
                                            <input type="checkbox"  checked/>
                                            <label className=" dark:text-gray-300">(%6) مالیات </label>
                                        </div>
                                        <div>
                                            <h3 className=" dark:text-gray-300"> 9% مالیات برارزش افزوده</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4 box bg-white dark:bg-themeDarkSalt700 rounded-md overflow-hidden">
                                <div class=" space-y-5">
                                    <div class=" space-y-3 border">
                                        <div className=" p-2 bg-gray-200 flex items-center gap-2">
                                            <h2>لیست اسناد</h2>
                                        </div>
                                        <div class="p-3">
                                            <ul class="space-y-3 List_documents py-4">
                                                <li>
                                                    <div class="bg-yellow-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} size={18}/>
                                                        </button>
                                                        <div class="col-span-2 text-center">
                                                            <h3>بانک پارسیان</h3>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                شماره چک:
                                                                1234567891234567
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="bg-green-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} />
                                                        </button>
                                                        <div class="col-span-2">
                                                            <h2 class="text-center">پرداخت با کارتخوان</h2>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                بانک:
                                                                ملت
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="bg-red-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} />
                                                        </button>
                                                        <div class="col-span-2">
                                                            <h2 class="text-center">پرداخت نقدی </h2>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                صندوق:
                                                                مهر مسعود
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <button className=" bg-themeInformation text-white py-3 w-full rounded-md flex items-center justify-center gap-2 text-lg ">
                                            <HPrimeIcon icon={'bookmark'}/>
                                            ثبت
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="tab-content__pane  space-y-5 p-1 md:p-3 box bg-white dark:bg-themeDarkSalt700 " id="tab_four">
                        <div class="grid grid-cols-12 gap-5 relative ">
                            <div class="col-span-12 lg:col-span-8 space-y-5">
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded shadow bg-blue-100 dark:bg-themeDarkPostInput border p-3">
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">عنوان:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">تاریخ ثبت:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300">پروژه:</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addProject: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="پروژه">*/}
                                        {/*    <option value="">حسن</option>*/}
                                        {/*    <option value="">عباس</option>*/}
                                        {/*    <option value="">رضا</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">مبلغ کل:</label>
                                        <InputText type={"text"} className={" input w-full"} />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 rounded bg-blue-100 dark:bg-themeDarkPostInput border shadow p-3 ">
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">کارتخوان:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="col-span-1 space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300">نام بانک:</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBank: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="بانک">*/}
                                        {/*    <option value="1">ملت</option>*/}
                                        {/*    <option value="2">تجارت</option>*/}
                                        {/*    <option value="3">پارسیان</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">نقد:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300">نام صندوق:</label>
                                            <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addFinanceBox: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <Select
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}


                                        />
                                        {/*<select name="" className="select2 w-full" data-placeholder="صندوق">*/}
                                        {/*    <option value="1">مهر مسعود</option>*/}
                                        {/*</select>*/}
                                    </div>
                                    <div class="space-y-2">
                                        <div class="flex justify-between">
                                            <label className="dark:text-gray-300">چک</label>
                                            <button className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addCheque: true })}>
                                                + افزودن
                                            </button>
                                        </div>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>

                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">مبلغ تخفیف:</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="dark:text-gray-300">مانده بدهی</label>
                                        <InputText type={"text"} className={"input w-full"} />
                                    </div>
                                    <div class=" flex gap-5 items-end">
                                        <div>
                                            <input type="checkbox"  checked/>
                                            <label className=" dark:text-gray-300">(%3) عوارض </label>
                                        </div>
                                        <div>
                                            <input type="checkbox"  checked/>
                                            <label className=" dark:text-gray-300">(%6) مالیات </label>
                                        </div>
                                        <div>
                                            <h3 className=" dark:text-gray-300"> 9% مالیات برارزش افزوده</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-12 lg:col-span-4 box bg-white dark:bg-themeDarkSalt700 rounded-md overflow-hidden">
                                <div class=" space-y-5">
                                    <div class=" space-y-3 border">
                                        <div className=" p-2 bg-gray-200 flex items-center gap-2">
                                            <h2>لیست اسناد</h2>
                                        </div>
                                        <div class="p-3">
                                            <ul class="space-y-3 List_documents py-4">
                                                <li>
                                                    <div class="bg-yellow-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} size={18}/>
                                                        </button>
                                                        <div class="col-span-2 text-center">
                                                            <h3>بانک پارسیان</h3>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                شماره چک:
                                                                1234567891234567
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="bg-green-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} size={18}/>
                                                        </button>
                                                        <div class="col-span-2">
                                                            <h2 class="text-center">پرداخت با کارتخوان</h2>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                بانک:
                                                                ملت
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="bg-red-300 grid grid-cols-2 px-3 py-1 gap-y-5 relative border shadow rounded-md max-w-sm mx-auto">
                                                        <button className="absolute top-2 end-2 ">
                                                            <HPrimeIcon icon={'trash'} size={18}/>
                                                        </button>
                                                        <div class="col-span-2">
                                                            <h2 class="text-center">پرداخت نقدی </h2>
                                                        </div>
                                                        <div className={" flex-wrap flex justify-center items-center col-span-2 gap-2"}>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ صدور:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                            <div className="text-xs flex justify-center items-center gap-1">
                                                                <h2>تاریخ سر رسید:</h2>
                                                                <h2>1401/01/21</h2>
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2 text-center bg-gray-700 rounded text-white flex items-center justify-between text-xs p-1">
                                                            <div>
                                                                صندوق:
                                                                مهر مسعود
                                                            </div>
                                                            <div>
                                                                مبلغ:
                                                                10.000.000
                                                            </div>
                                                        </div>
                                                        <div class="col-span-2">
                                                            <button type={'button'} className="flex w-full items-center justify-center button gap-1 text-white bg-themeInformation dark:bg-themeDarkInformation text-xs hover:bg-themeHoverInformation" href="#">
                                                                <HPrimeIcon icon={'check-square'}/>
                                                                ویرایش سند
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <button className=" bg-themeInformation text-white py-3 w-full rounded-md flex items-center justify-center gap-2 text-lg ">
                                            <HPrimeIcon icon={'bookmark'}/>
                                            ثبت
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <FantasyModal openModal={modal.addProject}  closeModal={addProjectOnHide}>
                <div class="space-y-3" >
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">نام پروژه:</label>
                        <InputText type={"text"} className={"input w-full "} />
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ شروع:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ پایان:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                    </div>
                    <div class="space-y-2">
                        <div class="flex flex-wrap gap-2 items-center">
                            <label className="text-xs dark:text-gray-300">رنگ دلخواه:</label>
                            <span class="bg-red-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-red-700 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-blue-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-gray-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            <span class="bg-yellow-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                        </div>
                    </div>
                    <div class="space-y-2">
                        <div class="flex justify-between items-center p-2 box shadow rounded">
                            <div class="flex flex-row items-center">
                                <label className="text-xs dark:text-gray-300">تصویر دلخواه:</label>
                                <input type="file" className="huploader"/>
                            </div>
                            <div>
                                <button className="button p-1  bg-theme-6 text-white"><span class="w-5 h-5 flex items-center justify-center"> <i data-feather="trash"class="w-4 h-4"></i> </span>
                                </button>
                                <button className="button p-1  bg-theme-9 text-white"><span class="w-5 h-5 flex items-center justify-center"> <i data-feather="eye" class="w-4 h-4"></i> </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label className="text-xs dark:text-gray-300">انتخاب اعضاء:</label>
                        <div >
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                            {/*<select className="select2 w-full" multiple>*/}
                            {/*    <option value="1">حسن</option>*/}
                            {/*    <option value="1">عباس</option>*/}
                            {/*    <option value="1">رضا</option>*/}
                            {/*    <option value="1">حسین</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>
                    <div class=" flex gap-3 justify-center">
                        <button className="button w-24 bg-themeDanger  text-white  "> انصراف </button>
                        <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation ">ایجاد</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addBank}  closeModal={addBankOnHide}>
                <div class="space-y-3" >
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div class="space-y-2">
                            <div class="flex gap-2">
                                <h2 className="text-xs dark:text-gray-300">کد حسابداری:</h2>
                                <div class="can-toggle demo-rebrand-1 text-xs">
                                    <input id="swich2" type="checkbox"/>
                                    <label htmlFor="swich2">
                                        <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                    </label>
                                </div>
                            </div>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">نام بانک:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">شماره حساب:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">شماره کارت:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300"> شماره شبا:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300"> واحد پول:</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                            {/*<select name="" className="select2 w-full">*/}
                            {/*    <option value="1">ریال</option>*/}
                            {/*    <option value="1">تومان</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>
                    <div class="flex items-center justify-center gap-2">
                        <button className="button w-24 bg-themeDanger  text-white">انصراف</button>
                        <button className="button w-24 bg-themeInformation dark:bg-themeDarkInformation text-white">ثبت</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addCheque}  closeModal={addChequeOnHide}>
                <div class="space-y-2" >
                    <div class="grid grid-cols-2 md:grid-cols-3 gap-3">
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ صدور:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ سررسید:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">پشت نمره:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">نام شعبه:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">کد شعبه:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">نام بانک:</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}

                            />
                            {/*<select name="" className="select2 w-full" data-placeholder="بانک">*/}
                            {/*    <option value="1">ملت</option>*/}
                            {/*    <option value="2">تجارت</option>*/}
                            {/*    <option value="3">پارسیان</option>*/}
                            {/*</select>*/}
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">شماره چک:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">شماره صیاد:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">مبلغ:</label>
                            <InputText type={"text"} className={"input w-full"} />
                        </div>
                        <div class="col-span-2 md:col-span-3 space-y-2">
                            <label className="text-xs dark:text-gray-300">توضیح:</label>
                            <InputTextarea rows={3} cols={30} className={"input w-full "}/>
                        </div>
                    </div>
                    <div class="flex justify-end">
                            <button className="button w-24 text-white bg-themeSuccess">ثبت</button>
                    </div>
                    <div class="flex flex-col">
                        <div class="overflow-x-auto addcheque">
                            <div class="inline-block min-w-full py-1 sm:px-3">
                                <div class="">
                                    <table className="min-w-full overflow-x-auto text-xs border table-add box">
                                        <thead class="border-b  dark:border-neutral-500 bg-gray-200">
                                        <tr>
                                                <th className="px-2 p1 whitespace-no-wrap border">#</th>
                                                <th className="px-2 p1 whitespace-no-wrap border">تاریخ صدور</th>
                                                <th className="px-2 p1 whitespace-no-wrap border">تاریخ سررسید</th>
                                                <th className="px-2 p1 whitespace-no-wrap border">مبلغ</th>
                                                <th className="px-2 p1 whitespace-no-wrap border">بانک</th>
                                                <th className="px-2 p1 whitespace-no-wrap border">شماره چک</th>
                                                <th className="px-2 p whitespace-no-wrap border">عملیات</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                                <td className="text-center p-2  border">1</td>
                                                <td className="p-2 border">
                                                    <h3 class="text-xs">
                                                        1401/01/21
                                                    </h3>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <p class=" text-xs">1401/01/21</p>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <h3>20.000.000.000</h3>
                                                </td>
                                                <td className="p-2 border">
                                                    <h3>
                                                        پارسیان
                                                    </h3>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <div>
                                                        1234567891234567
                                                    </div>
                                                </td>
                                                <td className="p-2 border">
                                                    <div class="flex justify-center items-center gap-2 text-xs">
                                                        <a className="flex items-center bg-theme-9 text-white gap-1 px-2 py-1 text-white rounded"
                                                           href="#"> <i data-feather="check-square" class="w-4 h-4"></i>ویرایش</a>
                                                        <a className="flex items-center bg-theme-6 text-white gap-1 px-2 py-1 text-white rounded removeTwo"
                                                           href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        <tr>
                                                <td className="text-center p-2  border">1</td>
                                                <td className="p-2 border">
                                                    <h3 class="text-xs">
                                                        1401/01/21
                                                    </h3>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <p class=" text-xs">1401/01/21</p>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <h3>20.000.000.000</h3>
                                                </td>
                                                <td className="p-2 border">
                                                    <h3>
                                                        پارسیان
                                                    </h3>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <div>
                                                        1234567891234567
                                                    </div>
                                                </td>
                                                <td className="p-2 border">
                                                    <div class="flex justify-center items-center gap-2 text-xs">
                                                        <a className="flex items-center bg-theme-9 text-white gap-1 px-2 py-1 text-white rounded"
                                                           href="#"> <i data-feather="check-square" class="w-4 h-4"></i>ویرایش</a>
                                                        <a className="flex items-center bg-theme-6 text-white gap-1 px-2 py-1 text-white rounded removeTwo"
                                                           href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        <tr>
                                                <td className="text-center p-2  border">1</td>
                                                <td className="p-2 border">
                                                    <h3 class="text-xs">
                                                        1401/01/21
                                                    </h3>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <p class=" text-xs">1401/01/21</p>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <h3>20.000.000.000</h3>
                                                </td>
                                                <td className="p-2 border">
                                                    <h3>
                                                        پارسیان
                                                    </h3>
                                                </td>
                                                <td className="text-center p-2 border">
                                                    <div>
                                                        1234567891234567
                                                    </div>
                                                </td>
                                                <td className="p-2 border">
                                                    <div class="flex justify-center items-center gap-2 text-xs">
                                                        <a className="flex items-center bg-theme-9 text-white gap-1 px-2 py-1 text-white rounded"
                                                           href="#"> <i data-feather="check-square" class="w-4 h-4"></i>ویرایش</a>
                                                        <a className="flex items-center bg-theme-6 text-white gap-1 px-2 py-1 text-white rounded removeTwo"
                                                           href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-center gap-2">
                        <button className="button w-24 bg-themeDanger  text-white">انصراف</button>
                        <button className="button w-24 bg-themeInformation dark:bg-themeDarkInformation text-white">ثبت</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addFinanceBox}  closeModal={addFinanceBoxOnHide}>
                <div class="space-y-3" >
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div class="space-y-2">
                            <div class="flex gap-3">
                                <h2 className="text-xs dark:text-gray-300">کد حسابداری:</h2>
                                <div class="can-toggle demo-rebrand-1 text-xs">
                                    <input id="swich2" type="checkbox"/>
                                    <label htmlFor="swich2">
                                        <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                    </label>
                                </div>
                            </div>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">نام صندوق:</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}

                            />
                            {/*<select name="" className="select2 w-full">*/}
                            {/*    <option value="">مسعود</option>*/}
                            {/*</select>*/}
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">واحد پول:</label>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">توضیحات:</label>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                    </div>
                    <div class="flex items-center justify-center gap-2">
                        <button className="button w-24 bg-themeDanger text-white">انصراف</button>
                        <button className="button w-24 bg-themeInformation dark:bg-themeDarkInformation text-white">ثبت</button>
                    </div>
                </div>
            </FantasyModal>
        </div>
    )

}
