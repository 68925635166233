import React from "react";
import {SettingButton} from "../../../../Commans/UIParts/SettingButton";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";
import {DateTimePicker} from "../../../../Commans/Dates/DateTimePicker";
import {InputNumber} from "primereact/inputnumber";
import {InputSwitch} from "../../../../Commans/Filters/InputSwitch";
import Select from "react-select";

export const SettingsTab=({handleSetting,handleReleaseDateTime,handleSelectValue,discountOrCommissionTypes,handleDiscountFrom,handleNumberValue,handleDiscountTo,handleValue,product,handleWholeSaleDiscountToDate, handleWholeSaleDiscountFromDate,handleGetCurrencyType,currencies})=>{
    const { t } = useTranslation();

    return (<>

        <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
            <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                <p className={"dark:text-gray-300 text-xs"}>{t("Publishing")}</p>
            </div>
            <div className={"px-2"}>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 items-center">
                    <div className="space-y-1 col-span-2">
                        <label className="text-xs dark:text-gray-300">{t("CreationDateAndTime")}</label>
                        <DateTimePicker isContainsTime={true}  handleDateTime={handleReleaseDateTime} format={'YYYY/MM/DD hh:mm:ss'} value={product.releaseDateTime}/>
                    </div>
                    <div>
                        <InputSwitch value={product.displayOrderMaster} OnValueSelection={handleSelectValue} lable={t("PagePriority")} name={'displayOrderMaster'}/>
                    </div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("PageRank")}</label>
                        <InputNumber  onChange={(e)=>handleNumberValue(e.value,'displayOrderSlave')} showButtons min={0} value={product.displayOrderSlave} type={"text"}  />
                    </div>
                </div>
            </div>
        </div>
        <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
            <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                <p className={"dark:text-gray-300 text-xs"}>{t("InventoryStock")}</p>
            </div>
            <div className={"px-2"}>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
                    {/*<div className="space-y-2">*/}
                    {/*    <label className="text-xs dark:text-gray-300">جایگاه:</label>*/}
                    {/*    <InputText type={"text"} className={"input  w-full"} />*/}
                    {/*</div>*/}
                    {/*<div className="space-y-2">*/}
                    {/*    <label className="text-xs dark:text-gray-300">موجودی:</label>*/}
                    {/*    <InputText type={"text"} className={"input  w-full"} />*/}
                    {/*</div>*/}
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>
                        <InputNumber onChange={(e)=>handleNumberValue(e.value,'inventoryMin')} showButtons={true} min={0} value={product.inventoryMin} type={"text"}  />
                    </div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>
                        <InputNumber onChange={(e)=>handleNumberValue(e.value,'inventoryMax')} showButtons={true} min={0} value={product.inventoryMax} type={"text"}  />
                    </div>
                    {/*<div className="space-y-2">*/}
                    {/*    <label className="text-xs dark:text-gray-300">وزن:</label>*/}
                    {/*    <InputText type={"text"} className={"input  w-full"} />*/}
                    {/*</div>*/}
                    {/*<div className="space-y-2">*/}
                    {/*    <label className="text-xs dark:text-gray-300">طول:</label>*/}
                    {/*    <InputText type={"text"} className={"input  w-full"} />*/}
                    {/*</div>*/}
                    {/*<div className="space-y-2">*/}
                    {/*    <label className="text-xs dark:text-gray-300">عرض:</label>*/}
                    {/*    <InputText type={"text"} className={"input  w-full"} />*/}
                    {/*</div>*/}
                    {/*<div className="space-y-2">*/}
                    {/*    <label className="text-xs dark:text-gray-300">ارتفاع:</label>*/}
                    {/*    <InputText type={"text"} className={"input  w-full"} />*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
        <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
            <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                <p className={"dark:text-gray-300 text-xs"}>{t("SingleDiscount")}</p>
            </div>
            <div className={"px-2"}>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("type")}</label>
                        <Select
                            options={discountOrCommissionTypes}
                            isMulti={false}
                            isClearable={false}
                            isSearchable={true}
                            onChange={(data)=>handleSelectValue(data,'discountOrCommissionType')}
                            name={'productTransportIds'}
                            placeholder={t("selection")}
                            value={product.discountOrCommissionType}
                            className={"select-group"}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={'fixed'}
                        />
                    </div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("Value")}</label>
                        {
                            product.discountOrCommissionType && product.discountOrCommissionType.value === 0?
                                <InputNumber
                                    onChange={(e) => handleNumberValue(e.value, 'discountAmount')}
                                    prefix={'%'}
                                    min={0}
                                    max={100}
                                    showButtons={true}
                                    value={product.discountAmount}
                                    type={"text"}

                                />
                                :
                                <InputNumber
                                    onChange={(e) => handleNumberValue(e.value, 'discountAmount')}
                                    suffix={handleGetCurrencyType(product.currency, currencies)}
                                    min={0}
                                    value={product.discountAmount}
                                    type={"text"}
                                />

                        }

                    </div>

                    <div className=" space-y-1">
                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                            <SettingButton
                                tooltipTitle={t("Calendarsettings")}
                                onClick={()=>handleSetting('GeneralSetting')}
                            />

                            {t("FromDate")}
                        </label>
                        <DateTimePicker handleDateTime={handleDiscountFrom} name={'discountFrom'} value={product.discountFrom} />
                    </div>
                    <div className=" space-y-1">
                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                            <SettingButton
                                tooltipTitle={t("Calendarsettings")}
                                onClick={()=>handleSetting('GeneralSetting')}
                            />
                            {t("ToDate")}
                        </label>
                        <DateTimePicker handleDateTime={handleDiscountTo} name={'discountTo'} value={product.discountTo} />
                    </div>
                </div>
            </div>
        </div>
        <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
            <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                <p className={"dark:text-gray-300 text-xs"}>{t("MajorDiscount")}</p>
            </div>
            <div className={"px-2"}>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("type")}</label>
                        <Select
                            options={discountOrCommissionTypes}
                            isMulti={false}
                            isClearable={false}
                            isSearchable={true}
                            onChange={(data)=>handleSelectValue(data,'wholeSaleDiscountOrCommissionType')}
                            name={'wholeSaleDiscountOrCommissionType'}
                            placeholder={t("selection")}
                            value={product.wholeSaleDiscountOrCommissionType}
                            className={"select-group"}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={'fixed'}
                        />
                    </div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("Value")}</label>
                        {
                            product.wholeSaleDiscountOrCommissionType && product.wholeSaleDiscountOrCommissionType.value === 0?
                                <InputNumber
                                    onChange={(e) => handleNumberValue(e.value, 'wholeSaleDiscountAmount')}
                                    prefix={'%'}
                                    min={0}
                                    max={100}
                                    showButtons={true}
                                    value={product.wholeSaleDiscountAmount}
                                    type={"text"}
                                />
                                :
                                <InputNumber
                                    onChange={(e) => handleNumberValue(e.value, 'wholeSaleDiscountAmount')}
                                    suffix={handleGetCurrencyType(product.currency, currencies)}
                                    min={0}
                                    value={product.wholeSaleDiscountAmount}
                                    type={"text"}
                                />

                        }
                    </div>

                    <div className=" space-y-1">
                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                            <SettingButton
                                tooltipTitle={t("Calendarsettings")}
                                onClick={()=>handleSetting('GeneralSetting')}
                            />

                            {t("FromDate")}
                        </label>
                        <DateTimePicker handleDateTime={handleWholeSaleDiscountFromDate} name={'wholeSaleDiscountFrom'} value={product.wholeSaleDiscountFrom} />
                    </div>
                    <div className=" space-y-1">
                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                            <SettingButton
                                tooltipTitle={t("Calendarsettings")}
                                onClick={()=>handleSetting('GeneralSetting')}
                            />
                            {t("ToDate")}
                        </label>
                        <DateTimePicker handleDateTime={handleWholeSaleDiscountToDate} name={'wholeSaleDiscountTo'} value={product.wholeSaleDiscountTo} />
                    </div>
                </div>
            </div>
        </div>
        <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
            <div className="bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                <p className={"dark:text-gray-300 text-xs"}> {t("SalesCollaboration")}</p>
            </div>
            <div className={"px-2"}>
                <div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("SalesCollaborationScript")}</label>
                        <InputTextarea name={"affiliateScript"} value={product.affiliateScript} onInput={(e)=>handleValue(e)} rows={3} cols={30} className={"input w-full"} />
                    </div>
                </div>
            </div>

        </div>
    </>)
}