export const handleCreateProductDetail=(obj)=>{
    const formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }
    for (const key in obj) {
        if (key === 'seoKeywords') {
            obj[key].forEach((keyword) => {
                appendIfNotNull(key, keyword);
            });
        }
        else {
            appendIfNotNull(key,obj[key]);
            //formData.append(key,obj[key]);
        }
    }
    return formData;
}