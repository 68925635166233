import {InputText} from "primereact/inputtext";

import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import EmojiPicker from 'emoji-picker-react';
import {InputNumber} from "primereact/inputnumber";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const Emoji=({emojiIndex,emojiItem,handleChangeSurveyItems,item,handleAddSurveyItem,handleRemoveSurveyItem,handleShowEmoji})=>{



    const { t } = useTranslation();
    return (
        <div key={emojiIndex} className="flex flex-col md:flex-row gap-2 shaow relative p-2 harmony_survey_box pt-5">
            <div className={"absolute end-2 z-10 top-0 -translate-y-1/2"}>
                <div className="flex gap-2 items-center">
                    {
                        emojiIndex === item.surveyItems.length - 1?
                            <>
                                <button type="button"
                                        className="button text-white bg-themeInformation dark:bg-themeDarkInformation "
                                        onClick={() => handleAddSurveyItem(item.fakeId,emojiIndex + 2)}
                                >
                                    <HPrimeIcon icon={'pi-plus'} sizeIcon={"primIcon_FontSize_xxs"} />
                                </button>
                            </>:null
                    }
                    <button
                        type="button"
                        className="button text-white bg-themeDanger"
                        onClick={() => handleRemoveSurveyItem(item.id,item.fakeId,emojiItem.fakeId, emojiItem.id,true)}
                    >
                        <HPrimeIcon icon={'pi-minus'} sizeIcon={"primIcon_FontSize_xxs"} />
                    </button>

                </div>
            </div>

            <div className="space-y-1 flex-shrink-0">
                <div>
                    <label className="text-xs dark:text-gray-300">{t("Emoji")}</label>
                </div>

                <InputText
                    value={emojiItem.surveyItemName}
                    name={'surveyItemName'}
                    onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,emojiItem.fakeId,emojiItem.id, 'surveyItemName', e.target.value)}
                    className="input w-12 text-center"
                    onClick={(e) => handleShowEmoji(item.id,item.fakeId,emojiItem.fakeId,emojiItem.id, 'IsShowEmoji',emojiItem.IsShowEmoji)}

                />
                {

                    emojiItem.IsShowEmoji?
                        <EmojiPicker style={{position:'absolute'}}
                                     open={emojiItem.IsShowEmoji}
                                     onEmojiClick={(data) => handleChangeSurveyItems(item.id,item.fakeId,emojiItem.fakeId,emojiItem.id, 'surveyItemName',data.emoji)}

                        />
                        :null
                }

            </div>
            <div className="space-y-1 flex-1">
                <div>
                    <label className="text-xs dark:text-gray-300">{t("amountCaption")}</label>
                </div>
                <InputText
                    value={emojiItem.surveyItemValue}
                    name={'surveyItemValue'}
                    onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,emojiItem.fakeId,emojiItem.id, 'surveyItemValue', e.target.value)}
                    className="input w-full"

                />
                {/*<div className={"absolute end-2 top-1/2"}>*/}
                {/*    <img*/}
                {/*        className="emoji-icon  cursor-pointer"*/}
                {/*        src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"*/}
                {/*    />*/}
                {/*</div>*/}



            </div>
            <div className="flex items-end gap-1">
                <div className="flex-1 space-y-1">
                    <div>
                        <label className="text-xs dark:text-gray-300">{t("nextquestion")}</label>
                    </div>
                    <div className={"w-full md:w-20"}>
                        <InputNumber
                            value={emojiItem.linkedSurveyRaw}
                            onChange={(e) => handleChangeSurveyItems(item.id,item.fakeId,emojiItem.fakeId,emojiItem.id, 'linkedSurveyRaw', e.value)}
                            name={'linkedSurveyRaw'}
                            type={'text'}
                            showButtons={true}
                            min={0}
                        />
                    </div>

                </div>

            </div>
        </div>
    )
}