import React from "react";

export const HandleAddStudent=()=>{

    return(<>

    نداریم
    </>)


}