import React, {useState} from "react";
import {ListFilter} from "../../Grid/ListFilter";
import {TabMenuTwo} from "../../UIParts/TabMenuTwo";
import {Return} from "../../Globals/Return";
import ButtonVideo from "../../UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {Transaction} from "./Transactions/Transaction";
import {WithDraw} from "./Withdrawals/WithDraw";
import {Deposite} from "./Deposits/Deposite";
import {WithdrawRequest} from "./withdrawalRequests/WithdrawRequest";
import {ButtonPlus} from "../../UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";


export const Wallet = ({currentPath}) => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [activeTab, setActiveTab] = useState("deposite");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = [
    {
      id: "deposite",
      label:t("Deposit"),
      tooltipTitle:t("Depositrequest"),

    },
    {
      id: "withdraw",
      label:t("Withdrawal"),
      tooltipTitle:t("WithdrawalRequest"),
    },
    {
      id: "transaction",
      label:t("transaction"),
      tooltipTitle:t("Viewtransactions"),
    },
    {
      id: "MyRequests",
      label:t("MyRequests"),
      tooltipTitle:t("MyRequests"),
    },
  ];
  const handleActiveTab=(tabId)=>{
    setActiveTab(tabId);
  }
  return(
      <>
        <div className=" space-y-5 relative">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
            <ListFilter label={null} icon={null}
                        showChildrenTwo={false}
                        childrenOne={
                          <>
                            <ButtonPlus
                                onClick={()=>redirectTo(`/Bank/Transaction/list`)}
                                icon={"pi-credit-card"}
                                title={t("transactions")}
                                tooltipTitle={t("ListOfTransactions")}
                            />
                            <ButtonPlus
                                onClick={()=>redirectTo(`/CRM/User/WithdrawalRequest`)}
                                icon={"pi-arrow-right-arrow-left"}
                                title={t("Withdrawal")}
                                tooltipTitle={t("ListOfTransactions")}
                            />

                          </>
                        }
            />

          </div>
          <div class="space-y-2">
            <div class="relative grid grid-cols-2 lg:grid-cols-7 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-3">
              <TabMenuTwo activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
            </div>
            <div className="post__content tab-content">

              <div className={`tab-content__pane ${ activeTab === "deposite" ? "active" : "" }`}  id="deposite">
                {
                  activeTab === 'deposite'? <Deposite currentPath={currentPath} handleActiveTab={handleActiveTab}  /> : null
                }

              </div>

              <div className={`tab-content__pane ${ activeTab === "withdraw" ? "active" : "" }`}  id="withdraw">
                {
                  activeTab === 'withdraw'?<WithDraw  currentPath={currentPath} handleActiveTab={handleActiveTab} />:null
                }
              </div>
              <div className={`tab-content__pane ${ activeTab === "transaction" ? "active" : "" }`}  id="transaction">
                {
                  activeTab === 'transaction'?<Transaction currentPath={currentPath} />:null
                }
              </div>

              <div className={`tab-content__pane ${ activeTab === "MyRequests" ? "active" : "" }`}  id="MyRequests">
                {
                  activeTab === 'MyRequests'?<WithdrawRequest currentPath={currentPath} />:null
                }
              </div>

            </div>
          </div>
        </div>

      </>

  )
}