export const convertFamilyData = (obj) => {
    const familyData = {
        insuranceId: obj.insuranceId ?? null,
        insuranceRelation: obj.insuranceRelation === null || obj.insuranceRelation === undefined ? null : obj.insuranceRelation.value,
        genderType: obj.genderType === null || obj.genderType === undefined ? null : obj.genderType.value,
        fullName: obj.fullName === null || obj.fullName === undefined || obj.fullName === '' ? null : obj.fullName,
        nationalCode: obj.nationalCode === null || obj.nationalCode === undefined || obj.nationalCode === '' ? null : obj.nationalCode,
        mobilePhoneNumber: obj.mobilePhoneNumber === null || obj.mobilePhoneNumber === undefined || obj.mobilePhoneNumber === '' ? null : obj.mobilePhoneNumber,
        homePhoneNumber: obj.homePhoneNumber === null || obj.homePhoneNumber === undefined || obj.homePhoneNumber === '' ? null : obj.homePhoneNumber,
        description: obj.description === null || obj.description === undefined || obj.description === '' ? null : obj.description
    };
    return familyData;
};
export const convertFamilyPutData = (obj) => {
    const familyData = {
        insuranceMemberId: obj.insuranceMemberId ?? null,
        insuranceRelation: obj.insuranceRelation === null || obj.insuranceRelation === undefined ? null : obj.insuranceRelation.value,
        genderType: obj.genderType === null || obj.genderType === undefined ? null : obj.genderType.value,
        fullName: obj.fullName === null || obj.fullName === undefined || obj.fullName === '' ? null : obj.fullName,
        nationalCode: obj.nationalCode === null || obj.nationalCode === undefined || obj.nationalCode === '' ? null : obj.nationalCode,
        mobilePhoneNumber: obj.mobilePhoneNumber === null || obj.mobilePhoneNumber === undefined || obj.mobilePhoneNumber === '' ? null : obj.mobilePhoneNumber,
        homePhoneNumber: obj.homePhoneNumber === null || obj.homePhoneNumber === undefined || obj.homePhoneNumber === '' ? null : obj.homePhoneNumber,
        description: obj.description === null || obj.description === undefined || obj.description === '' ? null : obj.description
    };
    return familyData;
};