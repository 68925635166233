import Joi from "joi";
export const guranteeShema = Joi.object({
    number: Joi.number().integer().required().messages({
        'any.required': 'تعداد سریال الزامیست',
        'number.base': 'تعداد سریال الزامیست',
        'number.integer': 'تعداد سریال الزامیست'
    }),
    timeValue: Joi.number().integer().required().messages({
        'any.required': 'مدت اعتبار الزامیست',
        'number.base': 'مدت اعتبار الزامیست',
        'number.integer': 'مدت اعتبار الزامیست'
    }),
});