import Joi from "joi";
export const passwordSchema = Joi.object({
    currentPassword: Joi.string()
        .required()
        .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)
        .messages({
            "any.required": "کلمه عبور الزامیست",
            "any.empty": "کلمه عبور الزامیست",
            'string.base': 'کلمه عبور الزامیست',
            "string.pattern.base":
                "پسورد شامل یک حرف بزرگ، یک حرف کوچیک، یک کاراکتر خاص و عدد میباشد",
        }),
    newPassword: Joi.string()
        .required()
        .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/)
        .messages({
            "any.required": "تکرار کلمه عبور الزامیست",
            "string.empty": "تکرار کلمه عبور الزامیست",
            'string.base': 'کلمه عبور الزامیست',
            "string.pattern.base":
                "پسورد شامل یک حرف بزرگ، یک حرف کوچیک، یک کاراکتر خاص و عدد میباشد",
        }),
    confirmPassword: Joi.string()
        .required()
        .valid(Joi.ref("newPassword"))
        .messages({
            "any.required": "تکرار کلمه عبور الزامیست",
            "string.empty": "تکرار کلمه عبور الزامیست",
            'string.base': 'تکرار کلمه عبور الزامیست',
            "any.only": "تکرار کلمه عبور با کلمه عبور مطابقت ندارد",
        }),


});
