import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {DELETE, GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import queryString from "query-string";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";
import {PickList} from "primereact/picklist";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import Select from "react-select";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const Roles=()=>{
    const {t} = useTranslation();
    const [roleSource, setRoleSource] = useState([]);
    const [roleTarget, setRoleTarget] = useState([]);
    const redirectTo = useNavigate();
    const[roleStamp,setRoleStamp] = useState(null);
    const [result, setResult] = useState(0);
    const [spinner,setSpinner] = useState(false);

    const onApiError = ()=>{
        setSpinner(false);
    }

    const handleFetchData=async()=>{
        setSpinner(true);
        try {

            const queryParams = queryString.parse(window.location.search);
            const allRoles = await GET(`Roles/Selection`,{},'IEnumerable');
            const userRole = await GET(`/Users/${queryParams.userId}/Roles`);
            if( allRoles.status === 200 && userRole.status === 200 ){
                setRoleStamp(userRole.data.concurrencyStamp);
                const rolesList = allRoles.data.map((role) => ({name: role.value, id: role.key,}));
                const selectedRoles = userRole.data.roles.length > 0 ? rolesList.filter((item) => userRole.data.roles.some((sourceItem) => sourceItem.roleId === item.id)) : [];
                const filteredRoles = rolesList.filter(role => !selectedRoles.some(selectedRole => selectedRole.id === role.id));
                setRoleSource(filteredRoles);
                setRoleTarget(selectedRoles);
                setSpinner(false)
            }
        }
        catch (e) {
            onApiError();
        }
    }

    const handleChangeData = (event) => {
        setRoleSource(event.source);
        setRoleTarget(event.target);
    };


    useEffect(()=>{
        handleFetchData();
    },[result])




    const roleTemplate = (item) => {
        return (
            <div className="flex items-center gap-1 border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkInformation p-2 rounded ">
                <div>
                    <div className={"p-2 rounded bg-themeSecondary"}>
                        <HPrimeIcon icon={'pi-user'} iconClass={'text-white'}/>
                    </div>
                </div>
                <span className="font-bold line-clamp-1 w-full">{item.name}</span>
                <div className={"flex gap-3 items-center flex-shrink-0"}>
                    <button type={"button"}>
                        <HPrimeIcon icon={"pi-list"} size={18} iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
                    </button>
                </div>
            </div>
        );
    };
    const handleRoleSubmit = async()=>{
        if(roleTarget.length === 0){
            handleFailureMessage(t("Chooseyourdesiredroles"));
            return;
        }
        try {
            setSpinner(true)
            const queryParams = queryString.parse(window.location.search);

            const roles = {
                userId:queryParams.userId,
                roleIds:roleTarget.map((item) => item.id),
                concurrencyStamp:roleStamp
            }

            const response  = await POST(`Authentications/User/Roles`,roles,false);
            if(response.status === 200){
                setSpinner(false)
                redirectTo('/CRM/User/List');
                handleSuccessMessage(t("Permissionsbeensuccessfully"))
            }else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
          onApiError();
        }
    }







    return <>
        {spinner ? (
            <div className="huploader_loading">
                <div className="relative">
                    <LoadingHarmony />
                </div>
            </div>
        ) : null}
        <div style={{direction:"ltr"}}>
            <PickList
                source={roleSource}
                target={roleTarget}
                onChange={handleChangeData}
                itemTemplate={roleTemplate}
                filter filterBy="name"
                sourceHeader={t("Role")}
                targetHeader={t("AuthorizedRole")}
                sourceStyle={{ height: '30rem' }}
                targetStyle={{ height: '30rem' }}
                sourceFilterPlaceholder={t("Accesses")}
                targetFilterPlaceholder={t("AuthorizedAccess")}

            />
        </div>
        <ButtonSubmitAndCancel
      onRegisterClick={()=>handleRoleSubmit()}
      onCancelClick={() => redirectTo('/CRM/User/List')}

        />
    </>
}