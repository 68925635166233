import Joi from "joi";

export const loginSchema = Joi.object({
    password: Joi.string().required().messages({
        'any.required': 'کلمه عبور الزامیست',
        'string.empty': 'کلمه عبور نمی‌تواند خالی باشد',
    }),
    username: Joi.string()
        .required().messages({
            'any.required': 'نام کاربری الزامیست',
            'string.empty': 'نام کاربری نمی‌تواند خالی باشد',
        })
        .label('نام کاربری'),
});