import React, {useRef, useState} from "react";

export const useRecovery=()=>{

    const [recovery,setRecovery]=useState({products:[],contents:[],users:[],backupSection:null})
    const productRef = useRef(null);
    const contentRef = useRef(null);
    const userRef = useRef(null);

    const handleSelectValue=(data,name)=>{
        setRecovery({...recovery,[name]: data});
    }
    const handleFileChange = (event,name) => {
        const files = Array.from(event.target.files);
        setRecovery(prevState => ({
            ...prevState,
            [name]: files
        }));
    };
    const handleRemoveFiles = (name, ref) => {
        if (recovery[name] && recovery[name].length > 0) {
            setRecovery(prevState => ({
                ...prevState,
                [name]: []
            }));
            if (ref && ref.current) {
                ref.current.value = null;
            }
        }
    };
    const handleRemove = (index, listName, ref) => {
        setRecovery(prevState => {
            const updatedList = prevState[listName]?.filter((_, i) => i !== index) || [];
            return { ...prevState, [listName]: updatedList };
        });
        if (recovery[listName]?.length === 1 && ref && ref.current) {
            ref.current.value = null; // Clear the file input
        }
    };

    return {
        productRef,
        contentRef,
        userRef,
        handleFileChange,
        handleRemoveFiles,
        recovery,
        setRecovery,
        handleRemove,
        handleSelectValue
    }
}