import React from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {useTranslation} from "react-i18next";

export const LegalTab=({company})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className={"space-y-3"}>
                {/*اطلاعات شرکت*/}
                <div className={"space-y-3"}>
                    <div className={"bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x p-2 border-themeInformation dark:border-themeDarkInformation"}>
                        <p className={"text-xs dark:text-gray-300"}> {t("CompanyData")}</p>
                    </div>
                    <div className={"px-2"}>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("CompanyType")}</p>
                                    </div>
                                    <div className={"flex-1 "}>
                                        <InputText value={company?.type ?? '-'} className={"input w-full"} disabled={true} />
                                    </div>
                                </div>
                            <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("companyName")}</p>
                                    </div>
                                    <div className={"flex-1 "}>
                                        <InputText value={company?.name ?? '-'} className={"input w-full"} disabled={true} />
                                    </div>
                                </div>
                            <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("BrandName")}</p>
                                    </div>
                                    <div className={"flex-1"}>
                                        <InputText value={company?.brand ?? '-'} className={"input  w-full "}  disabled={true} />
                                    </div>
                                </div>
                            <div className={"flex gap-2 items-center post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                                    <div className={"min-w-20"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("companyNationalIdNumber")}</p>
                                    </div>
                                    <div className={"flex-1 "}>
                                        <InputText value={company?.nationalCode ?? '-'} className={"input w-full"} disabled={true}  />
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                {/*اطلاعات شرکت*/}
                <div>
                    <div className={"space-y-3 md:col-span-2"}>
                        <div className={"flex gap-2 post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                            <div className={"min-w-20"}>
                                <p className={"text-xs dark:text-gray-300"}>{t("description")}</p>
                            </div>
                            <div className={"flex-1"}>
                                <InputTextarea value={company?.description ?? '-'} row={5} disabled={true} className={"input w-full"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}