import React, {useState} from "react";

export const useSetting=()=>{
    const [IsSettingOpen,setSettingOpen] = useState(false);
    const handleSettingClose=()=>{
        setSettingOpen(false);
    }
    const handleOpenSetting=()=>{
        setSettingOpen(true);
    }
    const [setting,setSetting] = useState('');
    const handleSetting=(settingId)=>{
        setSetting(settingId);
        handleOpenSetting();
    }
    return {
        IsSettingOpen,handleSetting,handleSettingClose,setting
    }
}