import React, {useMemo, useState} from "react";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";


import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";


export const HandleListReservationHotel = () => {

    const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

    const columnDefs = [
        { headerName: 'ردیف', field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: 'نام ساختمان',minWidth: 130,
            cellRenderer:parameters=>
                <h2 class="line-clamp-1">
                   هتل اسپسناس پالاس بهرود
                </h2>
        },
        { headerName: 'استان', minWidth: 110,
            cellRenderer:parameters=>
                <h2 className="line-clamp-1">
                    کاشان
                </h2>
        },
        { headerName: 'نام کاربر', minWidth: 160,
            cellRenderer:parameters=>
                <h2 className="line-clamp-1">مسعود احمدی محمدابادی</h2>
        },
        { headerName: 'شماره همراه', minWidth: 140,
            cellRenderer:parameters=>
                <h2 className="line-clamp-1">0910382261625</h2>
        },
        { headerName: 'نام/شماره اتاق',maxWidth: 130,
            cellRenderer:parameters=>
                <h2>
                    1500
                </h2>
        },
        { headerName: 'تاریخ ورود', field: 'date', maxWidth: 130,},
        { headerName: 'تاریخ خروج', field: 'date', maxWidth: 130,},
        { headerName: 'نام/شماره اتاق',minWidth: 170,
            cellRenderer:parameters=>
                <h2 className="line-clamp-1">
                    2.000.000.000 تومان
                </h2>
        },
        { headerName: 'پرداخت',maxWidth: 110,
            cellRenderer:parameters=>
                <h2 className="line-clamp-1">
                   موفق
                </h2>
        },
        { headerName: 'تنظیمات',width: 110, maxWidth: 120,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-2 '} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>

                    <button className="text-themeSecondary" ><HPrimeIcon icon={'help-circle'} /></button>


                </div>
            }

        },

    ];

    const rowData = [

        { id:1, date:'1402/10/3'},
        { id:2, date:'1402/10/3'},
        { id:3, date:'1402/10/3'},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };





    return(
      <>
          <div class="space-y-5">
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                  <ListSearch label={null} icon={null} >
                          <div class="space-y-2">
                              <label className="text-xs dark:text-gray-300">نام ساختمان:</label>
                              <MultiDataSelect placeholder={'انتخاب'} />
                          </div>
                          <div class="space-y-2">
                              <label className="text-xs dark:text-gray-300"> نام/کد اشتراک:</label>
                              <InputText type={"text"} className={" input w-full"} />
                          </div>

                      <div class="space-y-2">
                                  <label className="text-xs dark:text-gray-300">ازتاریخ:</label>
                                  <InputText type={"text"} className={" input w-full"} />
                              </div>
                      <div class=" space-y-2">
                                  <label className="text-xs dark:text-gray-300"> تا تاریخ:</label>
                                  <InputText type={"text"} className={" input w-full"} />
                              </div>

                      <div class="flex items-end gap-3  ">
                          <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
                                <span class=" flex h-6 w-6 items-center justify-center">
                                    <HPrimeIcon icon={"search"}/>
                                </span>
                          </div>
                          <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
                                   <span class=" flex h-6 w-6 items-center justify-center">
                                        <HPrimeIcon icon={"list"}/>
                                    </span>
                          </div>
                      </div>
                      </ListSearch>
                  <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                      <ListFilter label={null} icon={null}
                                  childrenOne={
                                      <>


                                      </>
                                  }
                                  childrenTwo={
                                      <>
                                          <div className=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">شش ماه به انقضاء</lable>
                                              <input className="input input--switch border bg-white" type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">یک سال به انقضاء</lable>
                                              <input className="input input--switch border bg-white" type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">موجود</lable>
                                              <input className="input input--switch border bg-white" type="checkbox"/>
                                          </div>
                                          <div class="space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">ناموجود</lable>
                                              <input className="input input--switch border bg-white" type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                              <input className="input input--switch border bg-white" type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">محصولات پرفروش</lable>
                                              <input className="input input--switch border bg-white" type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                              <input className="input input--switch border bg-white" type="checkbox"/>
                                          </div>
                                      </>
                                  }
                      />

                  </div>


              </div>
              <div>
                  <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                             enableRtl={true}
                             pagination={true}
                             getRowHeight={getRowHeight}
                             onGridReady={onGridReady}
                             quickFilterText={quickFilterText}
                             style={{ width: '100%', height: '100%' ,position:'relative' }}
                  />
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
                      <div className="sm:col-span-2">
                          <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                              <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Pdf"></span>
                                  <h2>خروجی (Pdf)</h2>
                              </Button>
                              <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Excel"></span>
                                  <h2>خروجی (Exel)</h2>
                              </Button>
                              <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Word"></span>
                                  <h2>خروجی (Word)</h2>
                              </Button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

      </>

  )
}