import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';

import reportWebVitals from './reportWebVitals';

import App from "./App";
import {RoutesPath} from "./Services/Globals/Routings/RoutesPath";
import i18n from "i18next";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <RoutesPath/>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
