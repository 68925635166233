import React, {useState} from "react";


import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {BadgeNotification} from "../BadgeNotification";
import {BadgeButtonNotification} from "../BadgeButtonNotification";
import {HPrimeIcon} from "./HPrimeIcon";


 // Global counter for tooltips
let tooltipCounter = 0; // Global counter for tooltips
export const  ButtonPlus =({ icon, onClick, classStyle,shortcutClass,title,spanClass,iconClass,sizeIcon,tooltipTitle,notification,positionClass ,isTitleHidden=false}) => {

    const { t } = useTranslation();

    const [tooltipId] = useState(() => {
        tooltipCounter += 2;
        return `tooltip_${tooltipCounter}`;
    });


    return(
      <>
          <div className={`flex flex-col items-center gap-1  w-max ${positionClass ? positionClass:"relative"} `}>
              <button  type={'button'} className={`button box  ${tooltipTitle ? `tooltip_class_${tooltipId}`:'tooltip_plus'}  zoom-in block rounded-full ${shortcutClass} ${!classStyle?'bg-themeInformation text-white hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkInformation dark:text-gray-300':classStyle}`}
                   onClick={onClick}
              >
                  {notification && <BadgeButtonNotification />}
                  <Tooltip anchorSelect={tooltipTitle ? `.tooltip_class_${tooltipId}` : ".tooltip_plus"} content={tooltipTitle ? tooltipTitle : t("New")} positionStrategy={"fixed"}	 />
                <span className={`grid size-7 place-content-center ${spanClass}`}>
                    {/*<i className={`pi ${icon?icon:"pi-plus"}  ${sizeIcon?sizeIcon:"primIcon_FontSize"}  ${iconClass}`} ></i>*/}
                    <HPrimeIcon icon={`${icon ? icon:"pi-plus"}`} iconClass={iconClass} sizeIcon={sizeIcon} />
                </span>
              </button>
              {isTitleHidden ? null :
                  <p className="text-xs text-center dark:text-gray-300 text-nowrap">{title ? title : t("Add")}</p>
              }

          </div>

  </>)
}