import React, {useEffect, useState} from "react";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import queryString from "query-string";
import {useProductTypeAttach} from "./useProductTypeAttach";
import {ProductTypeAttach} from "./ProductTypeAttach";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {handleCreateProductTypeFormData} from "./productTypeService";

export const HandleAddTypeProduct=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [initialSource,setInitialSource]=useState({users:[],propertyTypes:[],SendingStates:[],filters:[],currencies:[]});
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [concurrencyStamp,setConcurrencyStamp] = useState(null)
    const [currency,setCurrency]=useState(0);


    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const {addNewCard,deleteCard,files,setFiles,handleFileChange,handleFieldChange,handleInitialCards,handleGetAllCards,triggerFileInput,totalSize}=useProductTypeAttach(initialSource)
    const queryParams = queryString.parse(window.location.search);

    const handleInitialSourceData=(responseUser,responsePropertyType,responseSendingState,responseFilter,responseCurrency)=>{
        const users = convertSelectDataHandler(responseUser)
        const propertyTypes = convertSelectDataHandler(responsePropertyType)
        const sendingStates = convertSelectDataHandler(responseSendingState)
        const filters = convertSelectDataHandler(responseFilter)
        const currencies = convertSelectDataHandler(responseCurrency)
        setInitialSource({
            users: users,
            propertyTypes: propertyTypes,
            SendingStates: sendingStates,
            filters: filters,
            currencies: currencies
        })
        return {users,propertyTypes,sendingStates,filters}
    }
    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency)
        {
            return '-'
        }
        return currency.label;
    }

    const fetchData=async ()=>{
        try {
           showSpinner();
            const [
                responseUser,
                responsePropertyType,
                responseSendingState,
                responseFilter,
                responseCurrency,
                responseProductChildren,
                responseWebsiteCurrency
            ] = await Promise.all([
                GET('/Users/Selection', {}, 'IEnumerable'),
                GET(`/Enumerations/EnProductProperty`, {}, 'IEnumerable'),
                GET('/EnSendingStates', {}, 'IEnumerable'),
                GET(`/Filters/Selection?Table=${1}`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable'),
                GET(`/Products/Children?parentId=${queryParams.productId}`, {}, 'IEnumerable'),
                GET(`/Websites/Currency`, {}, 'IEnumerable'),
            ]);
            if(responseUser.status === 200 &&
                responsePropertyType.status === 200 &&
                responseSendingState.status === 200 &&
                responseFilter.status === 200 &&
                responseCurrency.status === 200)
            {
                const {propertyTypes, filters,sendingStates,users} = handleInitialSourceData(responseUser.data
                    ,responsePropertyType.data
                    ,responseSendingState.data
                    ,responseFilter.data
                    ,responseCurrency.data
                )
                setCurrency(responseWebsiteCurrency.data.currencyType ?? null)
                setConcurrencyStamp(responseProductChildren.data.concurrencyStamp)
                if(responseProductChildren.data.products.length === 0){
                    handleInitialCards(propertyTypes,users,sendingStates);
                }
                else{
                    const fileCards = handleGetAllCards(responseProductChildren.data.products,propertyTypes,users,sendingStates,filters);
                    setFiles(fileCards);
                }
                hideSpinner();
            }
        }
        catch (e) {
            hideSpinner();
        }
    }
    useEffect(()=>{
       fetchData()
    },[])


    const handleSubmit= async ()=>{
        if(files.length === 0){
            return;
        }
        try {
            showSpinner();
            const productData = handleCreateProductTypeFormData(queryParams.productId,concurrencyStamp,files);
            const response = await POST(`/Products/Children`,productData,true);
            if(response.status === 200){
                 hideSpinner();
                 redirectTo('/Shop/Product/List');
            }
            else{
                hideSpinner()
            }
        }
        catch (e) {
            hideSpinner();
        }
    }


    return(
        <>
            <div className="space-y-5 relative">
                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Shop/Product/Add`)}
                                            tooltipTitle={t("NewProduct")}
                                            title={t("product")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/MetaData/Add")}
                                            icon="pi-star"
                                            title={t("feature")}
                                            tooltipTitle={t("CreatingAFeature")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Group")}
                                            icon="pi-objects-column"
                                            title={t("grouping")}
                                            tooltipTitle={t("grouping")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/List")}
                                            icon="pi-list"
                                            title={t("list")}
                                            tooltipTitle={t("ListOfProducts")}
                                        />
                                    </>
                                }

                    />

                </div>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-2"}>
                    <div className={"post-input post-input-design p-2 flex items-center gap-2 dark:bg-themeDarkPostInput"}>
                        <div className="size-12 bg-white dark:bg-themeDarkSalt700 flex-shrink-0 rounded-full overflow-hidden">
                            <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
                        </div>
                        <div className={"flex gap-3 items-center flex-1 w-full flex-wrap"}>
                            <div className={"flex gap-1 items-center"}>
                                <p className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("ProductName")}:</p>
                                <span className="line-clamp-1 text-xs dark:text-gray-300">
                                    مسعود احمدی محمد آبادی
                                </span>
                            </div>
                            <div className={"flex gap-1 items-center"}>
                                <p className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("ProductCode")}:</p>
                                <span className="line-clamp-1 text-xs dark:text-gray-300">
                                    12346546548674
                                </span>
                            </div>

                        </div>

                    </div>
                </div>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-2"}>

                    <ProductTypeAttach
                        data={files}
                        handleGetCurrencyType={handleGetCurrencyType}
                        IsEdit={true}
                        showSpinner={showSpinner}
                        currency={currency}
                        hideSpinner={hideSpinner}
                        triggerFileInput={triggerFileInput}
                        setCards={setFiles}
                        cards={files}
                        deleteCard={deleteCard}
                        addNewCard={addNewCard}
                        handleFieldChange={handleFieldChange}
                        handleFileChange={handleFileChange}
                        initialSource={initialSource}
                        totalSize={totalSize}
                    />



                </div>

                <ButtonSubmitAndCancel
                   onRegisterClick={()=>handleSubmit()}
                   onCancelClick={()=>redirectTo('/Shop/Product/List')}
                />

            </div>

        </>
    )
}