import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";



export const PhoneElement = ({item,index,handleChangeData })=>{
    const { t } = useTranslation();
    const [initialSource,setInitialSource]=useState({
        preNumbers:[]
    })
    const fetchData=()=>{
        try {
          const preNumbers = convertSelectDataHandler(item.preNumbers);
          setInitialSource({
              preNumbers: preNumbers
          })
            console.log(item.properties)
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(()=>{
        fetchData();
    },[])


    return (
        <>
            <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
                <label className="dark:text-gray-300 harmony-text-xs">
                    {item.properties.caption}
                </label>
                {
                    item.properties.isRequired === true?
                        <RequiredIcon/>
                        :null
                }
                <div className={"harmony-flex"}>
                    <InputText
                        type='text'
                        placeholder={item?.properties?.description}
                        readOnly={item.properties.isReadOnly}
                        value={item?.data?.value}
                        disabled={item.properties.isReadOnly}
                        id={item.properties.id}
                        onChange={(e) => handleChangeData(index, 'value', e.target.value)}
                    />
                    <Select
                        options={initialSource.preNumbers}
                        isSearchable={true}
                        onChange={(data)=>handleChangeData(index,'preNumber',data)}
                        name={'metaDataGroupIds'}
                        placeholder={t("selection")}
                        value={initialSource.preNumbers.find((x) => x.value === item.data?.preNumber)}
                        className={"select-group"}
                        classNamePrefix={"h_multi_select"}
                    />
                </div>

            </div>

        </>
    )
}

