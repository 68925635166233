export const CreateConvertDocument=(document,links,files)=>{
    const data = {
        harmonyDocumentId: document.harmonyDocumentId ?? null,
        title: document.title ?? null,
        slug: document.slug !== null ?document.slug.replace(/\//g, "-"): null,
        content: document.content ?? null,
        description: document.description ?? null,
        keywords: document.keywords ?? null,
        documentGroupId: document.documentGroupId?.value ?? null,
        programmingSection: document.programmingSection?.value ?? null,
        harmonyDocumentType: document.harmonyDocumentType.map(item => item.value),
        image:document.image ?? null,
        links:links??null,
        files:files??null,
        order:document.order??null,
    }
    return data;
}
export const EditConvertDocument=(document,links,files)=>{
    const data = {
        harmonyDocumentId: document.harmonyDocumentId ?? null,
        title: document.title ?? null,
        slug: document.slug !== null ?document.slug.replace(/\//g, "-"): null,
        content: document.content ?? null,
        description: document.description ?? null,
        keywords: document.keywords ?? null,
        documentGroupId: document.documentGroupId?.value ?? null,
        programmingSection: document.programmingSection?.value ?? null,
        harmonyDocumentType: document.harmonyDocumentType.map(item => item.value),
        image:document.image ?? null,
        imagePath:document.imagePath,
        links:links??null,
        files:files??null,
        order:document.order??null,
    }
    return data;
}




export const CreateFormDataDocument=(obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('Title',obj.title);
    appendIfNotNull('Slug',obj.slug);
    appendIfNotNull('Description',obj.description);
    appendIfNotNull('Content',obj.content);
    appendIfNotNull('Order',obj.order);
    appendIfNotNull('DocumentGroupId',obj.documentGroupId);
    appendIfNotNull('ProgrammingSection',obj.programmingSection);
    appendIfNotNull('Image',obj.image);
    if (obj.keywords) {
        obj.keywords.forEach((keyword, index) => {
            appendIfNotNull(`keywords[${index}]`,keyword);
        });
    }
    if (obj.harmonyDocumentType) {
        obj.harmonyDocumentType.forEach((suggestion, index) => {
            appendIfNotNull(`HarmonyDocumentType[${index}]`, suggestion);
        });
    }
    if (obj.files) {
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file !== null){
                formData.append(`Files[${i}].file`,obj.files[i].file)
                formData.append(`Files[${i}].attachType`, obj.files[i].attachType.value);
                formData.append(`Files[${i}].caption`, obj.files[i]?.caption);
                formData.append(`Files[${i}].groupOrder`, obj.files[i]?.groupOrder);
            }else{

            }
        }
    }

    if (obj.links) {
        for (let i = 0; i < obj.links.length; i++) {
            const link = obj.links[i];
            appendIfNotNull(`Links[${i}].title`, link.title);
            appendIfNotNull(`Links[${i}].url`, link.url);
            appendIfNotNull(`Links[${i}].description`, link.description);
        }
    }
    return formData
}

export const EditFormDataDocument=(obj)=>{
    var formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }
    appendIfNotNull('HarmonyDocumentId',obj.harmonyDocumentId);
    appendIfNotNull('Title',obj.title);
    appendIfNotNull('Slug',obj.slug);
    appendIfNotNull('Description',obj.description);
    appendIfNotNull('Content',obj.content);
    appendIfNotNull('Order',obj.order);
    appendIfNotNull('DocumentGroupId',obj.documentGroupId);
    appendIfNotNull('ProgrammingSection',obj.programmingSection);
    appendIfNotNull('Image',obj.image);
    appendIfNotNull('ImagePath',obj.imagePath);
    if (obj.keywords) {
        obj.keywords.forEach((keyword, index) => {
            appendIfNotNull(`keywords[${index}]`,keyword);
        });
    }
    if (obj.harmonyDocumentType) {
        obj.harmonyDocumentType.forEach((suggestion, index) => {
            appendIfNotNull(`HarmonyDocumentType[${index}]`, suggestion);
        });
    }
    if (obj.files) {
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file === null && obj.files[i].documentFileId === null){

            }
            else{
                if(obj.files[i].file !== null && obj.files[i].documentFileId !== null ){
                    formData.append(`Files[${i}].FilePath`,obj.files[i].filePath);
                }
                formData.append(`Files[${i}].file`,obj.files[i].file);
                formData.append(`Files[${i}].attachType`,  obj.files[i].attachType.value);
                formData.append(`Files[${i}].caption`, obj.files[i]?.caption );
                formData.append(`Files[${i}].groupOrder`, obj.files[i]?.groupOrder );
                formData.append(`Files[${i}].documentFileId`, obj.files[i].documentFileId );
            }

        }
    }

    if (obj.links) {
        for (let i = 0; i < obj.links.length; i++) {
            const link = obj.links[i];
            appendIfNotNull(`Links[${i}].title`, link.title);
            appendIfNotNull(`Links[${i}].url`, link.url);
            appendIfNotNull(`Links[${i}].description`, link.description);
            appendIfNotNull(`Links[${i}].documentLinkId`, link.documentLinkId);
        }
    }
    return formData
}