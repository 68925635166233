import React, { useState, useEffect } from "react";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {InputTextarea} from "primereact/inputtextarea";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
export const Location = ({item,index,handleChangeData}) => {
    const showSpinner = () => {
        console.log("Spinner shown");
    };

    const hideSpinner = () => {
        console.log("Spinner hidden");
    };

    useEffect(() => {
        // Fetch countries when the component mounts
        fetchCountries();
    }, []);

    const fetchCountries = async () => {
        try {
            showSpinner();

            // Always fetch countries
            const response = await GET(`/Countries/Selection`, {}, "IEnumerable");

            let statesData = [];
            let citiesData = [];

            // Fetch states if stateProvinceId is not null
            if (item?.properties?.defaultValue?.stateProvinceId) {
                const responseStates = await GET(`/Countries/${item.properties.defaultValue.countryId}/StateProvinces/Selection`);
                if (responseStates.status === 200) {
                    statesData = convertSelectDataHandler(responseStates.data);
                } else {
                    handleApiError(responseStates);
                }
            }
            // Fetch cities if cityId is not null
            if (item?.properties?.defaultValue?.cityId) {
                const responseCities = await GET(`/Countries/${item.properties.defaultValue.stateProvinceId}/Cities/Selection`);
                if (responseCities.status === 200) {
                    citiesData = convertSelectDataHandler(responseCities.data);
                } else {
                    handleApiError(responseCities);
                }
            }
            // Process countries data if the response was successful
            if (response.status === 200) {
                const countryData = convertSelectDataHandler(response.data);
                handleChangeData(index, "countries", countryData);
                handleChangeData(index, "states", statesData);
                handleChangeData(index, "cities", citiesData);
            } else {
                handleApiError(response);
            }

        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    const fetchStates = async (countryId) => {
        try {
            showSpinner();

            const response = await GET(`/Countries/${countryId}/StateProvinces/Selection`, {}, "IEnumerable");
            const stateData = convertSelectDataHandler(response.data);
            if (response.status === 200) {
                // setLocalStates(stateData);
                handleChangeData(index, "states", stateData);
                handleChangeData(index, "cities", []); // Clear cities when a new state is selected
            } else {
                handleApiError(response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    const fetchCities = async (stateId) => {
        try {
            showSpinner();
            const response = await GET(
                `/Countries/${stateId}/Cities/Selection`,
                {},
                "IEnumerable"
            );
            const cityData = convertSelectDataHandler(response.data);
            if (response.status === 200) {
                // setLocalCities(cityData);
                handleChangeData(index, "cities", cityData);
            } else {
                handleApiError(response);
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    const handleCountryChange = async (event) => {
        const countryId = event.target.value;
        handleChangeData(index, "countryId", countryId);
        handleChangeData(index, "stateProvinceId", null);
        handleChangeData(index, "cityId", null);
        if (countryId) {
            await fetchStates(countryId);
        } else {
            // setLocalStates([]);
            // setLocalCities([]);
            handleChangeData(index, "states", []);
            handleChangeData(index, "cities", []);
        }
    };

    const handleStateChange = async (event) => {
        const stateId = event.target.value;
        handleChangeData(index, "stateProvinceId", stateId);
        handleChangeData(index, "cityId", null);
        if (stateId) {
            await fetchCities(stateId);
        } else {
            // setLocalCities([]);
            handleChangeData(index, "cities", []);
        }
    };

    const handleCityChange = (event) => {
        const cityId = event.target.value;
        handleChangeData(index, "cityId", cityId);
    };

    return (
        <>
            <div className={`  harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-3   harmony-gap-3 harmony-items-center ${item.properties.size !== null ? item.properties.size.className : ''}`}>
                <div className="harmony-space-y-1">
                    {
                        item.properties.isRequired === true?
                            <RequiredIcon/>
                            :null
                    }
                    <label className="dark:text-gray-300 harmony-text-xs">کشور</label>
                    <select
                        value={item?.data?.countryId || ""}
                        onChange={handleCountryChange}
                        disabled={item?.properties?.isReadOnly}
                        className="harmony-input harmony-w-full"
                        id={item.properties.id}
                        name={item?.properties?.name}
                        required={item?.properties?.isRequired}
                        placeholder={item?.properties?.description}

                    >
                        <option value="" disabled>
                            انخاب کشور
                        </option>
                        {item?.data?.countries.map((country) => (
                            <option key={country.value} value={country.value}>
                                {country.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="harmony-space-y-1">
                    <label className="dark:text-gray-300 harmony-text-xs">استان</label>
                    <select
                        value={item?.data?.stateProvinceId || ""}
                        onChange={handleStateChange}
                        disabled={item?.properties?.isReadOnly}
                        className="input w-full"
                        id={item.properties.id +'-'+'province'}
                        name={item?.properties?.name + 'province'}
                        required={item?.properties?.isRequired}
                        placeholder={item?.properties?.description}
                    >
                        <option value="" disabled>
                            انتخاب استان
                        </option>
                        {item?.data?.states.map((state) => (
                            <option key={state.value} value={state.value}>
                                {state.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="harmony-space-y-1">
                    <label className="dark:text-gray-300 harmony-text-xs">شهر</label>
                    <select
                        value={item?.data?.cityId || ""}
                        onChange={handleCityChange}
                        disabled={item?.properties?.isReadOnly}
                        name={item?.properties?.name + 'city'}
                        className="harmony-input harmony-w-full"
                        placeholder={item?.properties?.description}
                        required={item?.properties?.isRequired}
                        id={item.properties.id}
                    >
                        <option value="" disabled>
                            انتخاب شهر
                        </option>
                        {item?.data?.cities.map((city) => (
                            <option key={city.value} value={city.value}>
                                {city.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={'harmony-space-1 harmony-md-col-span-3'}>
                    <InputTextarea
                        type="text"
                        value={item?.data?.address}
                        onChange={(e) => handleChangeData(index, 'address', e.target.value)}
                    />

                </div>



            </div>






        </>
    );
};
