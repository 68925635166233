//برای ارسال درخواست های api  از کتابخونه axios استفاده میکنیم
// یک ابجکت بیس با کانفیگ از پیش زده شده برای این ابجکت از اکسیوس میسازیم که هر دفعه تکرار نشه
// به طور پیش فرض هرجا لازم باشه که توکن رو در هدر بذاریم از همین کانفیگ استفاده میکنیم و لازم به تکرار هر دفعه نیست
import axios from "axios";
import {fetchAccessToken,fetchRefreshToken} from "./JwtService";

const accessToken  = fetchAccessToken();
const refreshToken = fetchRefreshToken();
const language = localStorage.getItem('language');

export const BasicUrl= {
     baseURL: 'https://core.harmonycms.ir/api',
    //     baseURL: 'http://192.168.168.22:45455/api',
    //       baseURL:'http://192.168.168.42/api', // - برا سیستم سینا واسه پ// - برا سیستم دانیال واسه پابلیش
       //    baseURL:'http://192.168.168.22/api', // - برا سیستم سینا واسه پ// - برا سیستم دانیال واسه پابلیش
    // baseURL:'http://192.168.168.39:45457/api',//برا سیستم دانیال واسه پابلیش

    // baseURL:'http://192.168.168.39:45456/api',//برا سیستم دانیال واسه پابلیش
    // baseURL:'http://192.168.168.39/api',//برا سیستم دانیال واسه پابلیش
}

const client = axios.create({
    baseURL: BasicUrl.baseURL,
     //  baseURL: 'http://192.168.168.26:45455/api',
     //   baseURL:'http://192.168.168.24/api', // - برا سیستم سینا واسه پابلیش
     //   baseURL:'http://192.168.168.42:45455/api',//برا سیستم دانیال واسه پابلیش
    headers: {
         Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        'Accept-Language': language || undefined,
    },
});

// client.interceptors.response.use(
//     (response) => {
//         // You can modify the response data here if needed
//       console.log(response);
//     },
//     (error) => {
//         // Handle response error
//         return Promise.reject(ExceptionHandlerService(error));
//     }
// );

// client.interceptors.response.use(null,error => {
//     if(error.response && error.response.status === 403)
//     {
//         return <Navigate to={'/'} replace={true}/>
//     }
//     if(error.response && error.response.status === 404)
//     {
//         toast.error('Not Found');
//     }
//     if(error.response && error.response.status === 400){
//          handleFailureMessage('خطا داری');
//     }
//     if(error.response && error.response.status === 401){
//        return <Navigate to={'/'} replace={true}/>
//     }
//     else{
//         toast.error('Error');
//     }
// })



export default client;