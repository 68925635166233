import React, {useState, useEffect, useContext, useCallback, Children, cloneElement, isValidElement} from "react";
import { MenuBarMobile } from "../SideBar/MenuBar-Mobile";
import { MenuBar } from "../SideBar/MenuBar";
import { TopBar } from "../NavBar/TopBar";

import 'react-toastify/dist/ReactToastify.css'
//استایل های اصلی
import "primereact/resources/primereact.css"
import "primereact/resources/themes/nano/theme.css"
//استایل های اصلی


import "primeicons/primeicons.css";

import 'react-tooltip/dist/react-tooltip.css'
import "../../../Styles/dist/css/IR_app.css";
import "../../../Styles/dist/css/En_app.css";

import "../../../Styles/dist/css/output.css";
import { StateContext } from "../../Authentication/StateProvider";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";

import {Tooltip} from "react-tooltip";
import {FantasyModal} from "../UIParts/FantasyModal";
import "ag-grid-community/styles/ag-grid.css"
import Select from "react-select";
import {QuickAccess} from "../NavBar/QuickAccess/QuickAccess";
import {QuickAccessItem} from "../NavBar/QuickAccess/QuickAccessItem";
import {useCustomRoutes} from "../Hooks/useCustomRoutes";
import {useCustomQuickAccess} from "../NavBar/QuickAccess/useCustomQuickAccess";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {
  convertSelectDataHandler,
  createSettingsUrl,
  handleGetSettingValue
} from "../../CMS/Contents/ContentService";
import {handleConvertQuickAccess} from "../NavBar/QuickAccess/quickAccessService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {BadgeNotification} from "../BadgeNotification";
import UserSettingIcon from "../SVGComponent/UserSettingIcon";

import {HPrimeIcon} from "../UIParts/HPrimeIcon";
import {useDynamicDispatch} from "../../../Services/Redux/useDynamicaDispatch";

export const MasterLayout = (props) => {
  const {lang} = useContext(StateContext);
  const {handleDispatch} = useDynamicDispatch();

  // const IsLoading = useSelector((state) => state.loading.IsLoading);
  // const dispatch = useDispatch();

  const {t} = useTranslation();
  const {children} = props;
  const [IsOpen, setIsOpen] = useState(false);
  const {allRoutes} = useCustomRoutes();
  const [initialSource, setInitialSource] = useState({
    targetTypes: [],
    listItems: [],
    notes: [],
    paymentNotification: 0,
  });


  const [IsUpgradeOpen, setUpgradeOpen] = useState(false);

  const handleCloseServicesUpgrades = () => {
    setUpgradeOpen(false);
  }
  const handleOpenServicesUpgrades = () => {
    setUpgradeOpen(true);
  }



  const handleDispatchSetting = (theme, calendarView, calendarLanguage, calendarType,editor, fontSize) => {
    handleDispatch('setSelectedTheme',theme);
    handleDispatch('setCalendarView',calendarView);
    handleDispatch('setCalendarLanguage',calendarLanguage);
    handleDispatch('setEditorType',editor);
    handleDispatch('setCalendarType',calendarType);

    if (fontSize === 'harmony-default-font') {
      document.documentElement.removeAttribute('class');
    } else {
      handleDispatch('setFontSize',fontSize);
      document.documentElement.classList.add(fontSize);
    }
  }
  const handleGetValueTheme = (theme) => {
    switch (theme) {
      case 0:
        return false
      case 1:
        return true
      default:
        return false
    }
  }

  const {handleSelectValue, quickAccess, setQuickAccess, handleDeleteSpeedLink} = useCustomQuickAccess(setInitialSource)

  const handleIsOpen = () => {
    setIsOpen(true)
  }
  const handleCloseIsOpen = () => {
    setIsOpen(false);
    setQuickAccess({target: initialSource.targetTypes[0], slug: allRoutes[0], icon: '', title: ''});
  }

  const fetchData = async () => {
    try {
      const responsePermission = await GET(`/WebSite/Users/Permissions`,{},'single');
      if(responsePermission.status !== 200){
        handleApiError(responsePermission,null)
      }
      handleDispatch('setWebsitePermissions',responsePermission.data);

      const settingNames = [
        'Theme',
        'CalendarView',
        'CalendarLanguage',
        'CalendarType',
        'FontSize',
        'Editor'
      ];
      const settingsUrl = createSettingsUrl('/Settings', settingNames);
      const responseSetting = await GET(settingsUrl, {}, 'single');
      if(responseSetting.status !== 200){
        handleApiError(responseSetting,null)
      }
      const theme = handleGetSettingValue('Theme', responseSetting.data.settings);
      const themeValue = handleGetValueTheme(theme);
      const calendarView = handleGetSettingValue('CalendarView', responseSetting.data.settings);
      const calendarLanguage = handleGetSettingValue('CalendarLanguage', responseSetting.data.settings);
      const calendarType = handleGetSettingValue('CalendarType', responseSetting.data.settings);
      const fontSize = handleGetSettingValue('FontSize', responseSetting.data.settings);
      const editor = handleGetSettingValue('Editor', responseSetting.data.settings);
      handleDispatchSetting(themeValue, calendarView, calendarLanguage, calendarType,editor, fontSize);



      const responseMainlayout = await GET(`/MainLayout`, {}, 'single');
      if(responseMainlayout.status !== 200){
        handleApiError(responseMainlayout,null);
      }


      const [response, responseSpeedlinK] = await Promise.all([
        GET(`/Enumerations/EnTargetType`, {}, 'IEnumerable'),
        GET(`/UserSpeedLink/Links`, {}, 'IEnumerable'),
      ]);
      if (response.status !== 200 || responseSpeedlinK.status !== 200) {
        handleApiError(response ,null);
        handleApiError(responseSpeedlinK ,null);
        return; // Early exit if either fetch fails
      }

      const targets = convertSelectDataHandler(response.data);
      const { notes, paymentNotification } = responseMainlayout.data;


      setInitialSource({
        targetTypes: targets,
        listItems: responseSpeedlinK.data,
        notes,
        paymentNotification,
      });

      setQuickAccess({target: targets[0], slug: allRoutes[0], title: allRoutes[0].label, icon: allRoutes[0].icon});
      // hideSpinner();
    } catch (e) {
      // hideSpinner();
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  const handleSubmitQuickAccess = async () => {
    try {
      // showSpinner();
      const quickAccessData = handleConvertQuickAccess(quickAccess)
      const response = await POST(`/UserSpeedLink`, quickAccessData, false);
      if (response.status === 200) {
        handleCloseIsOpen();
        //hideSpinner();
        const responseSpeedlinK = await GET(`/UserSpeedLink/Links`, {}, 'IEnumerable');
        if (responseSpeedlinK.status === 200) {
          setInitialSource(prevState => ({...prevState, listItems: responseSpeedlinK.data}))
        } else {
          handleApiError(responseSpeedlinK,null);
        }
      } else {
        handleApiError(response);
      }

    } catch (e) {
      // hideSpinner();
    }
  }




  // useEffect(() => {
  //   if (lang === "fa-IR") {
  //     import("../../../Styles/dist/css/IR_app.css");
  //   } else if (lang === "en-US") {
  //     import("../../../Styles/dist/css/En_app.css");
  //   }
  // }, [lang]);


  useEffect(() => {
    const body = document.body;
    if (lang === "en-US") {
      // import("../../../Styles/dist/css/En_app.css");
      body.classList.add("h-ltr");
    } else {
      body.classList.remove("h-ltr");
    }

    return () => {
      body.classList.remove("h-ltr");
    };
  }, [lang]);


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'A') {
        // Trigger your action here
        alert("Success");
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []); //Empty dependency array to ensure the effect runs once on mount


  const [classesToggled, setClassesToggled] = useState(true);
  const handleClick = () => {
    setClassesToggled(!classesToggled);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);







  const [shortcuts, setShortcuts] = useState([]);
  useEffect(() => {
    const fetchShortcuts = async () => {
      const response = await GET(`/Settings/UserShortcuts`, {}, 'IEnumerable');
      if (response.status === 200) {
        setShortcuts(response.data); // Assuming response.data contains the array of shortcuts
      }
    };
    fetchShortcuts();
  }, []);
  useEffect(() => {
    const handleKeyDown = (event) => {
      const pressedKeys = [];
      if (event.ctrlKey) pressedKeys.push('Ctrl');
      if (event.shiftKey) pressedKeys.push('Shift');
      if (event.altKey) pressedKeys.push('Alt');



      pressedKeys.push(event?.key?.toUpperCase());


      const pressedKeysString = pressedKeys.join('+');
      for (const shortcut of shortcuts) {
        const shortcutKeysString = shortcut.keyMap.join('+');
        if (pressedKeysString === shortcutKeysString) {
          if (shortcut.process === 0) {
            event.preventDefault();
            document.querySelectorAll('.hbtn_add').forEach(button => {
              button.click();
            });
          }
          else if (shortcut.process === 1) {
            event.preventDefault();
            document.querySelectorAll('.hbtn_save').forEach(button => {
              button.click();
            });
          }
          break;
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [shortcuts]);

  const redirectTo = useNavigate();



  return (
      <>

        <div className={"harmony_body"} >
          <div className="app dark:bg-themeDarkApp">
            {windowWidth < 1280 && <MenuBarMobile />}
            <div className="main-page flex">
              <MenuBar classesToggled={classesToggled} />
              <div className="content dark:bg-themeDarkContent md:space-y-4">
                <div className={"space-y-2 p-2 relative"}>
                  <TopBar
                      notes={initialSource.notes}
                  />
                  { windowWidth > 767 && <>
                  <div className="flex justify-end items-center min-h-12 gap-3  py-1">

                    { windowWidth > 1280 && <>
                      <div className={"flex-shrink-0 flex gap-2 items-center"}>
                        <button type={"button"} className={`menu-side-hide hbtn-side-menu text-white hidden xl:block ${classesToggled ? 'rounded-md bg-themeInformation dark:bg-themeDarkInformation duration-300 p-1 hover:bg-themeHoverInformation tooltip_class_hide':'menu-side-show tooltip_class_show'}`} onClick={handleClick}>
                          <span className={"size-7 grid place-content-center"}>
                            <HPrimeIcon icon={` ${classesToggled ? 'pi-window-maximize':'pi-angle-double-left'} `}></HPrimeIcon>
                          </span>
                          <Tooltip anchorSelect={` ${classesToggled ? '.tooltip_class_hide':'.tooltip_class_show'}`} content={` ${classesToggled ? t("CloseMenu"):t("ViewMenu")}`} positionStrategy={"fixed"} />
                        </button>
                        <button onClick={()=>redirectTo('/Harmony/Setting/Main/Dashboard')} className={"rounded-md hbtn-personalSetting bg-themeInformation dark:bg-themeDarkInformation text-white p-1 tooltip_class_PersonalSettings hover:bg-themeHoverInformation duration-300"} >
                         <UserSettingIcon fillStyle={"fill-white"}/>
                          <Tooltip anchorSelect={".tooltip_class_PersonalSettings"} content={t("PersonalSettings")} positionStrategy={"fixed"} />
                        </button>
                        <button type={"button"} className={"rounded-md bg-themeInformation dark:bg-themeDarkInformation text-white p-1 hbtn-quickAccess tooltip_class_quick_access hover:bg-themeHoverInformation duration-300"} onClick={() => handleIsOpen()}>

                          <span className={"size-7 grid place-content-center"}>
                            <HPrimeIcon icon={"pi-plus"}></HPrimeIcon>
                          </span>
                          <Tooltip anchorSelect={".tooltip_class_quick_access"} content={t("QuickAccess")} positionStrategy={"fixed"} />
                        </button>
                      </div>
                      <div className={"flex-1 overflow-y-auto scrollbar-hidden scrollbar py-1 grid grid-cols-12"}>
                        <div className={"flex gap-1 items-center col-span-12"}>
                          {initialSource.listItems.length > 0 ? initialSource.listItems.map((item) => (
                              <QuickAccessItem handleDeleteSpeedLink={handleDeleteSpeedLink} target={item.target} speedLinkId={item.speedLinkId} icon={item.icon} slug={item.slug} title={item.title}/>
                          )):null
                          }
                        </div>
                      </div>
                      {console.log(initialSource)}
                    </>

                    }

                    <div className={"flex gap-2 items-center"}>

                      <Link to={"/QuickSetup"} className="flex items-center gap-2 justify-center button bg-themeInformation dark:bg-themeDarkInformation text-white" >
                        <HPrimeIcon icon="pi-sparkles"></HPrimeIcon>
                        <p className={"text-xs "}>{t("پیکربندی")}</p>
                      </Link>
                      <button type={"button"} className="flex items-center gap-2 justify-center button bg-themeInformation dark:bg-themeDarkInformation text-white" onClick={() => handleOpenServicesUpgrades()}>
                        <HPrimeIcon icon="pi-sparkles"></HPrimeIcon>
                        <p className={"text-xs "}>{t("ServicesUpgrades")}</p>
                      </button>
                      <Link to="/InvoiceRequest" className="flex items-center gap-2 justify-center button bg-themeInformation dark:bg-themeDarkInformation text-white">
                          <BadgeNotification
                          count={initialSource.paymentNotification}
                          />
                        <HPrimeIcon icon="pi-file-edit"></HPrimeIcon>
                        <p className={"text-xs"}>{t("Bill")}</p>
                      </Link>
                    </div>
                  </div>
                  </>
                  }
                </div>
                {children}
              </div>
            </div>
          </div>
        </div>

        <FantasyModal openModal={IsOpen} closeModal={handleCloseIsOpen} title={t("QuickAccess")}>
          <div className="space-y-3">
            <div className={"flex items-center gap-2"}>
              <div class=" space-y-1 flex-1">
                <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                <Select
                    options={allRoutes}
                    isMulti={false}
                    isSearchable={true}
                    onChange={(data)=>handleSelectValue(data,'slug')}
                    placeholder={t("selection")}
                    value={quickAccess.slug}
                    menuPosition={"fixed"}
                    classNamePrefix={"h_multi_select"}
                />

              </div>
              <div class=" space-y-1 flex-1">
                <label className="text-xs dark:text-gray-300">{t("DisplayStatus")}</label>
                <Select
                    options={initialSource.targetTypes}
                    isMulti={false}
                    isSearchable={true}
                    onChange={(data)=>handleSelectValue(data,'target')}
                    placeholder={t("selection")}
                    value={quickAccess.target}
                    menuPosition={"fixed"}
                    classNamePrefix={"h_multi_select"}
                />
              </div>
            </div>
            <button type={'button'} onClick={()=>handleSubmitQuickAccess()} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("register")}</button>
          </div>
        </FantasyModal>

        <FantasyModal openModal={IsUpgradeOpen} closeModal={handleCloseServicesUpgrades} title={t("ServicesUpgrades")} customeClassName={"modal-xx"} fieldse_bg_Class={"bg-white"}>
            <div>
              <div className={" flex flex-col md:flex-row gap-5 items-center"}>
                <div className={"box border w-full flex-1 px-2 py-5 dark:border-themeDarkColorLine space-y-3 post-input-design"}>
                  <div className={"p-2 space-y-2 mx-auto text-center "}>
                    <span className={"size-20 rounded-full post-input grid place-content-center mx-auto"}>
                      <HPrimeIcon icon="pi-sitemap"></HPrimeIcon>
                    </span>
                    <h2 className={"text-xl text-center dark:text-gray-300 font-bold"}>{t("خدمات پشتیبانی")}</h2>
                  </div>
                  <div className={"px-5 "}>
                    <ul className={"list-disc list-inside text-xs leading-7 space-y-1 "}>
                      <li>
                        پاسخ به سوالات متداول شما
                      </li>
                      <li>
                        آموزش بخش های مختلف سیستم
                      </li>
                      <li>
                        ارسال تیکت به پشتیبانی
                      </li>
                      <li>
                        لیست تیکت های قبلی
                      </li>

                    </ul>
                  </div>
                  <div>
                    <Link to={"/Harmony/Support/Ticket"} className={"button bg-themeInformation w-32 mx-auto   text-white block"} onClick={()=>handleCloseServicesUpgrades()}>ادامه</Link>
                  </div>
                </div>
                <div className={"flex-shrink-0"}>
                  <p>یا</p>
                </div>
                <div className={"bg-themeLightGray w-full flex-1 dark:bg-themeDarkSalt700 box border px-2 py-5 dark:border-themeDarkColorLine space-y-3 post-input-design "}>
                  <div className={"p-2 space-y-2 mx-auto text-center"}>
                    <span className={"size-20 rounded-full bg-white  grid place-content-center mx-auto"}>
                      <HPrimeIcon icon="pi-sitemap"></HPrimeIcon>
                    </span>
                    <h2 className={"text-xl text-center dark:text-gray-300 font-bold"}>{t("Upgrade")}</h2>
                  </div>
                  <div className={"px-5 "}>
                    <ul className={"list-disc list-inside text-xs leading-7 space-y-1 "}>
                      <li>
                       ارتقاء منابع سیستم
                      </li>
                      <li>
                        خریداری ماژول های جدید
                      </li>
                      <li>
                        اضافه سازی زبان جدید برای وبسایت
                      </li>
                      <li>
                        خرید انواع قالب های وبسایت
                      </li>

                    </ul>
                  </div>
                  <div>
                    <Link to={"/ServiceUpgrade"} className={"button bg-themeInformation w-32 mx-auto text-white block"} onClick={()=>handleCloseServicesUpgrades()}>ادامه</Link>
                  </div>
                </div>
              </div>
            </div>

        </FantasyModal>

      </>

  );
};