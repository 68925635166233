export const handleCreateUserAddress = (userAddresses, userId,concurrencyStamp) => {

    const addresses =  userAddresses.filter(loc =>
        loc.countryId !== null &&
        loc.stateProvinceId !== null &&
        loc.cityId !== null &&
        loc.address !== null
    );

    const userAddressesData = addresses.map((loc) => ({
        countryId: loc.countryId.value,
        stateProvinceId: loc.stateProvinceId.value,
        cityId: loc.cityId.value,
        userAddressId: loc.userAddressId,
        address: loc.address,
        detailsOfAddress: loc.detailsOfAddress,
        postalCode: loc.postalCode,
    }));

    const data = {
        userId: userId,
        addresses: userAddressesData,
        concurrencyStamp: concurrencyStamp
    };
    return data;
}
export function validateCountry(userAddresses) {
    return userAddresses.every(obj => obj.countryId !== null );
}

export function validateStateProvince(userAddresses) {
    return userAddresses.every(obj => obj.stateProvinceId !== null );
}
export function validateCity(userAddresses) {
    return userAddresses.every(obj => obj.cityId !== null );
}
export function validateAddress(userAddresses) {
    return userAddresses.every(obj => obj.address !== null  && obj.address !== '');
}