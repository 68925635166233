import React, {useEffect, useState} from "react";

import {InputText} from "primereact/inputtext";
import {handleFailureMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {TagsInput} from "react-tag-input-component";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {UploadImage} from "../../../../Commans/Uploaders/UploadImage";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";
import {useSetting} from "../../../../Commans/Hooks/useSetting";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {HandleGeneralSetting} from "../../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SettingButton} from "../../../../Commans/UIParts/SettingButton";
import {RequiredIcon} from "../../../../Commans/Errors/RequiredIcon";
import ArrowLeftIcon from "../../../../HIcon/ArrowLeftIcon";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const License = ({handleChangeTab,onValueChange,values})=>{
    const { t } = useTranslation();

    const redirectTo = useNavigate();
    const[spinner,setSpinner] = useState(false);
    const [selectedOptions,setSelectOption] = useState({cultures:[],currencies:[],countries:[],calendars:[],licenseTimes:[],licenseValue:null,weekDays:[]})

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();


    const handleChangeViewTab = (tabId) => {
        if(values.Title === null || values.Title ===""){
            handleFailureMessage(t("Pleaseenterwebsitetitle"));
            return;
        }

        handleChangeTab(tabId);
    };

    const GoToLastPage=(tabId)=>{
        handleChangeTab(tabId)
    }

    const onApiError = ()=>{
        setSpinner(false);
    }



    const handleFetchData = async () => {
        try {
            setSpinner(true);
            const [
                responseCulture,
                responseCurrency,
                responseLicense,
                responseCountry,
                responseCalendar,
                responseDayOfWeek
            ] = await Promise.all([
                GET('/Enumerations/EnCultureType', {}, 'IEnumerable'),
                GET('/Enumerations/EnCurrencyType', {}, 'IEnumerable'),
                GET('/Enumerations/EnLicenseTime', {}, 'IEnumerable'),
                GET('/Countries/Selection', {}, 'IEnumerable'),
                GET('/Enumerations/EnCalendarType', {}, 'IEnumerable'),
                GET('/Enumerations/EnDayOfWeek', {}, 'IEnumerable')
            ]);

            if (
                responseCulture.status === 200 &&
                responseCurrency.status === 200 &&
                responseLicense.status === 200 &&
                responseCountry.status === 200 &&
                responseCalendar.status === 200 &&
                responseDayOfWeek.status === 200
            ) {
                const cultures = convertSelectDataHandler(responseCulture.data);
                const currencies = convertSelectDataHandler(responseCurrency.data);
                const licenseTimes = convertSelectDataHandler(responseLicense.data);
                const countries = convertSelectDataHandler(responseCountry.data);
                const calendars = convertSelectDataHandler(responseCalendar.data);
                const weekDays = convertSelectDataHandler(responseDayOfWeek.data);
                if (
                    values.Culture !== null &&
                    values.Culture !== undefined &&
                    values.Currency !== null &&
                    values.Currency !== undefined &&
                    values.LicenseTime !== null &&
                    values.LicenseTime !== undefined &&
                    values.CountryId !== null &&
                    values.CountryId !== undefined &&
                    values.CalendarType !== null &&
                    values.CalendarType !== undefined &&
                    values.dayOfWeek !== null &&
                    values.dayOfWeek !== undefined
                ) {
                    const filteredCulture = cultures.find((item) => item.value === values.Culture.value);
                    onValueChange(filteredCulture, 'Culture');

                    const filteredCurrency = currencies.find((item) => item.value === values.Currency.value);
                    onValueChange(filteredCurrency, 'Currency');

                    const filteredLicenseTime = licenseTimes.find((item) => item.value === values.LicenseTime.value);
                    onValueChange(filteredLicenseTime, 'LicenseTime');

                    const filteredCountryId = countries.find((item) => item.value === values.CountryId.value);
                    onValueChange(filteredCountryId, 'CountryId');

                    const filteredCalendarType = calendars.find((item) => item.value === values.CalendarType.value);
                    onValueChange(filteredCalendarType, 'CalendarType');

                    const filteredDayOfWeek = weekDays.find((item) => item.value === values.dayOfWeek.value);
                    onValueChange(filteredDayOfWeek, 'dayOfWeek');
                } else {
                    onValueChange(cultures[0], 'Culture');
                    onValueChange(currencies[0], 'Currency');
                    onValueChange(licenseTimes[0], 'LicenseTime');
                    onValueChange(countries[0], 'CountryId');
                    onValueChange(calendars[0], 'CalendarType');
                    onValueChange(weekDays[0], 'dayOfWeek');
                }

                setSelectOption({
                    cultures: cultures,
                    currencies: currencies,
                    countries: countries,
                    calendars: calendars,
                    licenseTimes: licenseTimes,
                    weekDays: weekDays
                });

                setSpinner(false);
            } else {
                handleApiError(responseCulture, onApiError);
                handleApiError(responseCurrency, onApiError);
                handleApiError(responseCountry, onApiError);
                handleApiError(responseCalendar, onApiError);
                handleApiError(responseDayOfWeek, onApiError);
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(()=>{
        handleFetchData();
    },[])


    const handleValueChange = (data,name) => {
        onValueChange(data,name)
    };



    return (<>

        <div>
            <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                    3
                </div>
                <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                    <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">
                        {t("LicenseInformation")}
                    </h2>
                </div>
                <div>
                    <span className={"arrow_design_add_site"}>
                        <ArrowLeftIcon/>
                    </span>
                </div>
            </div>
        </div>
        <div className={"mt-5 space-y-5"}>
            <div className="grid grid-cols-1 lg:grid-cols-2 ">
                <div class="space-y-5 post-input post-input-design dark:bg-themeDarkPostInput p-2">
                    <div className="grid grid-cols-1 gap-4 ">
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">
                                {t("Title")}
                                <RequiredIcon/>
                            </label>
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                <HPrimeIcon
                                icon={"pi-pen-to-square"}
                                />
                            </span>
                                <InputText  className="input w-full" value={values.Title} onInput={(e)=>handleValueChange(e.target.value,'Title')}  />
                            </div>
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("Keywords")}</label>
                            <div className="p-inputgroup input_rounded">
                            <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                <HPrimeIcon
                                icon={"pi-key"}
                                />
                            </span>
                                <TagsInput value={values.KeyWords} onChange={(data)=>handleValueChange(data,'KeyWords')} name={"KeyWords"} />
                            </div>
                        </div>
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("description")}</label>
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                <HPrimeIcon
                                icon={"pi-pen-to-square"}
                                />
                            </span>
                                <InputText  value={values.Description} className="input w-full" onInput={(e)=>handleValueChange(e.target.value,'Description')}  />
                            </div>
                        </div>
                    </div>
                    <div class=" grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("LicenseDuration")}</label>
                            <Select
                                options={selectedOptions.licenseTimes}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleValueChange(data,'LicenseTime')}
                                placeholder={t("selection")}
                                value={values.LicenseTime}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("LanguageType")}</label>
                            <Select
                                options={selectedOptions.cultures}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleValueChange(data,'Culture')}
                                placeholder={t("selection")}
                                value={values.Culture}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("CurrencyUnit")}</label>
                            <Select
                                options={selectedOptions.currencies}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleValueChange(data,'Currency')}
                                placeholder={t("selection")}
                                value={values.Currency}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("country")}</label>
                            <Select
                                options={selectedOptions.countries}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleValueChange(data,'CountryId')}
                                placeholder={t("selection")}
                                value={values.CountryId}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("CalendarType")}</label>
                            <Select
                                options={selectedOptions.calendars}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleValueChange(data,'CalendarType')}
                                placeholder={t("selection")}
                                value={values.CalendarType}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{'DayOfWeek'}</label>
                            <Select
                                options={selectedOptions.weekDays}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleValueChange(data,'dayOfWeek')}
                                placeholder={t("selection")}
                                value={values.dayOfWeek}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 justify-between gap-2">
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("WatermarkText")}
                                />

                                {t("AddLogo")}</label>
                            <div className=" h-36 w-full ">
                                <UploadImage accept={'image/*'} OnValueSelection={handleValueChange} value={values.LogoHeader ? values.LogoHeader:null} name={'LogoHeader'} />
                            </div>
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("WatermarkText")}

                                />
                                {t("AddIcon")}</label>
                            <div className="h-36 w-full ">
                                <UploadImage accept={'image/*'} OnValueSelection={handleValueChange} value={values.LogoFooter ? values.LogoFooter:null} name={'LogoFooter'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="hidden lg:grid place-content-center">
                    <img src="/Assets/Image/template/Host.png" alt="" className="max-w-3/4 mx-auto"/>
                </div>
            </div>
            <div class="flex items-center justify-center md:justify-end gap-2">
                <button type={'button'} onClick={()=>GoToLastPage('users')} className="block button w-28 bg-themeDanger text-white">{t("previous")}</button>
                <button type={'button'} onClick={()=>handleChangeViewTab('domains')} className="block button w-28  bg-themeInformation dark:bg-themeDarkInformation text-white">{t("Next")}</button>
            </div>
        </div>


        <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
            <div>
                {
                    setting === 'GeneralSetting'?
                        <HandleGeneralSetting/>:null
                }
            </div>
        </FantasyModal>

    </>)
}