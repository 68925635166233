import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {ListFilter} from "../../../Commans/Grid/ListFilter";

import {GridTable} from "../../../Commans/Grid/GridTable";
import {Button} from "primereact/button";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {InputTextarea} from "primereact/inputtextarea";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {Export} from "../../../Commans/Grid/Export";
import {Pagination} from "../../../Commans/Grid/Pagination";


export const HandleFrequentlyUsedNumbers = ()=>{
    const { t } = useTranslation();



    const [IsOpen,setIsOpen] = useState(false);
    const handleOpenModal = () => {
        setIsOpen(true);

    };
    const handleCloseModal = ()=>{
        setIsOpen(false);
    }


    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("Title"),minWidth: 120,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: t("شماره ها"),minWidth: 140,

            cellRenderer:parameters=>

                <>0910040،0655406</>

        },

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>
                </div>
            }
        },

    ];








    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };

    const [columnApi, setColumnApi] = useState(null);
    const [gridApi, setGridApi] = useState(null);

    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');


    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setColumnApi(params.columnApi);
    };



    return (
        <>
            <div class=" space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">جستجو براساس (****)</label>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                            <Select
                                placeholder={t("selection")}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}

                            />
                        </div>
                        <ButtonSearchList />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleOpenModal()}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <div class=" space-y-2">
                                            <lable className="text-xs dark:text-gray-300">*****</lable>
                                            <div>
                                                <input className="input input--switch " type="checkbox"/>
                                            </div>
                                        </div>
                                    </>
                                }
                    />


                </div>
                <div className={"space-y-2 H-body-ag-grid "}>
                    <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                               enableRtl={true}
                               pagination={true}
                               getRowHeight={getRowHeight}
                               onGridReady={onGridReady}
                               quickFilterText={quickFilterText}

                    />
                    <Pagination/>
                </div>
                <Export/>

            </div>
            <FantasyModal openModal={IsOpen} closeModal={handleCloseModal} >
                <div className={"space-y-5"}>
                    <div class="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                        <InputText type={"text"} className={"input w-full"} />
                    </div>
                    <div className="space-y-2">
                        <div>
                            <InputTextarea name={"excerpt"} rows={15} cols={30} className={"input w-full"}/>
                        </div>
                    </div>
                    <div>
                        <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("register")}</button>
                    </div>
                </div>
            </FantasyModal>
        </>

    )
}