import React, {useState} from "react";
import {InputText} from "primereact/inputtext";

import {InputTextarea} from "primereact/inputtextarea";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const HandleAddDocument=()=>{
    const { t } = useTranslation();

    const [modal, setModal] = useState({ addBranch: false, addProject: false , addAccDocument:false });

    const addProjectOnHide = () => {
        setModal((prevState) => ({ ...prevState, addProject: !modal.addProject }));
    };

    const addBranchOnHide = () => {
        setModal((prevState) => ({ ...prevState, addBranch: !modal.addBranch }));
    };

    const addAccDocumentOnHide = () => {
        setModal((prevState) => ({ ...prevState, addAccDocument: !modal.addAccDocument }));
    };


    return(
        <div className=" space-y-5">
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="post-input  space-y-2 rounded-md border dark:border-themeDarkColorLine">
                    <div className="p-3 bg-gray-200 dark:bg-themeDarkPostInput rounded-t-md dark:text-gray-300">
                        <p>اطلاعات سند</p>
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 p-2 gap-5">
                        <div class="space-y-2">
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div class=" space-y-2">
                                    <div class="flex gap-2 items-center">
                                        <label className="text-xs dark:text-gray-300">کد حسابداری:</label>
                                        <div class="can-toggle demo-rebrand-1 text-xs">
                                            <input id="swich2" type="checkbox"/>
                                            <label htmlFor="swich2">
                                                <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                                            </label>
                                        </div>
                                    </div>
                                    <InputText type={"text"} className={"input w-full"} />
                                </div>
                                <div class="space-y-2">
                                    <label className="text-xs dark:text-gray-300">تاریخ:</label>
                                    <InputText type={"text"} className={"input w-full "} />
                                </div>
                                <div class="space-y-2">
                                    <div class="flex justify-between px-2">
                                        <label className="text-xs dark:text-gray-300">شعبه:</label>
                                        <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addBranch: true })}>
                                            + افزودن
                                        </button>
                                    </div>
                                    <Select
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                    {/*<select name="" className="select2 w-full" data-placeholder="شعبه">*/}
                                    {/*    <option value="">حسن</option>*/}
                                    {/*    <option value="">عباس</option>*/}
                                    {/*    <option value="">رضا</option>*/}
                                    {/*</select>*/}
                                </div>
                                <div class=" space-y-2">
                                    <div class="flex justify-between px-2">
                                        <label className="text-xs dark:text-gray-300">پروژه:</label>
                                        <button type="button" className="text-themeInformation dark:text-themeDarkInformation" onClick={() => setModal({ addProject: true })}>
                                            + افزودن
                                        </button>
                                    </div>
                                    <Select
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                    {/*<select name="" className="select2 w-full" data-placeholder="پروژه">*/}
                                    {/*    <option value="">حسن</option>*/}
                                    {/*    <option value="">عباس</option>*/}
                                    {/*    <option value="">رضا</option>*/}
                                    {/*</select>*/}
                                </div>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">توضیحات:</label>
                            <div>
                                <InputTextarea rows={5}  className={"input w-full h-full"} />
                            </div>
                        </div>
                    </div>
                    <div class="col-span-2 flex justify-center lg:justify-end p-5">
                        <button type="button" className=" w-24 bg-themeInformation dark:bg-themeDarkInformation text-white button" onClick={() => setModal({ addAccDocument: true })}>ایجاد ردیف</button>
                    </div>
                </div>
            </div>
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div class="overflow-x-auto">
                        <div class="inline-block min-w-full py-1 ">
                            <div class="">
                                <table className="min-w-full overflow-x-auto text-sm font-light CloneDataTwo">
                                    <thead class="border-b  dark:border-neutral-500 bg-gray-200">
                                    <tr>
                                        <th scope="col" className="px-4 p1 whitespace-no-wrap border">#</th>
                                        <th scope="col" className="px-4 p1 whitespace-no-wrap border">حساب</th>
                                        <th scope="col" className="px-4 p1 whitespace-no-wrap border">تفصیل</th>
                                        <th scope="col" className="px-4 p1 whitespace-no-wrap border">شرح</th>
                                        <th scope="col" className="px-4 p1 whitespace-no-wrap border">بدهکار</th>
                                        <th scope="col" className="px-4 p1 whitespace-no-wrap border">بستانکار</th>
                                        <th scope="col" className="px-4 p whitespace-no-wrap border">عملیات</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="text-center p-2  border">1</td>
                                        <td className="p-2 border">
                                            <h3 class="text-center text-xs">
                                                1002501023350545454
                                            </h3>
                                        </td>
                                        <td className="td-tbody p-2 border">
                                            <p class="line-clamp-1 text-xs"> درجه یک</p>
                                        </td>
                                        <td className="td-tbody p-2 border">
                                            <h3>
                                                درجه یک
                                            </h3>
                                        </td>
                                        <td className="text-center p-2 border">
                                            <h3>2000.000000.000.000</h3>
                                        </td>
                                        <td className="text-center p-2 border">
                                            <h3>2000.000000.000.000</h3>
                                        </td>
                                        <td className="p-2 border">
                                            <div class="flex justify-center items-center gap-2 text-xs">
                                                <a className="flex items-center bg-theme-9  gap-1 px-2 py-1 text-white rounded"
                                                   href="#"> <i data-feather="check-square"
                                                                class="w-4 h-4"></i>ویرایش</a>
                                                <a className="flex items-center bg-theme-6  gap-1 px-2 py-1 text-white rounded Remove"
                                                   href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف </a>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 box bg-white dark:bg-themeDarkSalt700 p-3">
                <div class="post-input dark:bg-themeDarkPostInput space-y-2 rounded-md p-3 flex items-center">
                    <div class="flex justify-between w-full">
                        <h3 className="dark:text-gray-300">بستانکار:</h3>
                        <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                        <h3 className="dark:text-gray-300">0</h3>
                    </div>
                </div>
                <div class="post-input dark:bg-themeDarkPostInput space-y-2 rounded-md  p-3 flex items-center">
                    <div class="flex justify-between w-full">
                        <h3 className="dark:text-gray-300">بدهکار:</h3>
                        <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                        <h3 className="dark:text-gray-300">2</h3>
                    </div>
                </div>
                <div class="post-input dark:bg-themeDarkPostInput space-y-2 rounded-md overflow-hidden p-3 flex items-center">
                    <div class="flex justify-between w-full">
                        <h3 className="dark:text-gray-300">تفاوت:</h3>
                        <div class="flex-1 m-3 border-b border-dashed border-gray-400 h-px"></div>
                        <h3 className="dark:text-gray-300">0</h3>
                    </div>
                </div>
                <div class="p-3 rounded ">
                    <button className=" bg-themeInformation dark:bg-themeDarkInformation text-white w-full button flex items-center justify-center gap-2 ">
                        <HPrimeIcon icon={'bookmark'}/>
                        ذخیره سند حسابداری
                    </button>
                </div>
            </div>

            <FantasyModal openModal={modal.addBranch}  closeModal={addBranchOnHide} title={"نام شعبه"}>
                <div class="space-y-5">
                    <div className="space-y-2">
                        <label className="text-xs dark:text-gray-300">نام شعبه:</label>
                        <InputText type={"text"} className={"input w-full "} />
                    </div>
                    <div class=" flex gap-3 justify-center">

                        <button className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation ">ایجاد</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addProject}  closeModal={addProjectOnHide} title={"ایجاد پروژ]"}>
                <div class="space-y-3" >
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">نام پروژه:</label>
                        <InputText type={"text"} className={"input w-full "} />
                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ شروع:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">تاریخ پایان:</label>
                            <InputText type={"text"} className={"input w-full "} />
                            </div>
                        </div>
                    <div class="space-y-2">
                            <div class="flex flex-wrap gap-2 items-center">
                                <label className="text-xs dark:text-gray-300">رنگ دلخواه:</label>
                                <span class="bg-red-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                                <span class="bg-red-700 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                                <span class="bg-blue-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                                <span class="bg-gray-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                                <span class="bg-yellow-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                            </div>
                        </div>
                    <div class="space-y-2">
                            <div class="flex justify-between items-center p-2 box shadow rounded">
                                <div class="flex flex-row items-center">
                                    <label className="text-xs dark:text-gray-300">تصویر دلخواه:</label>
                                    <input type="file" className="huploader"/>
                                </div>
                                <div>
                                    <button className="button p-1  bg-theme-6 text-white"><span class="w-5 h-5 flex items-center justify-center"> <i data-feather="trash"class="w-4 h-4"></i> </span>
                                    </button>
                                    <button className="button p-1  bg-theme-9 text-white"><span class="w-5 h-5 flex items-center justify-center"> <i data-feather="eye" class="w-4 h-4"></i> </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    <div>
                        <label className="text-xs dark:text-gray-300">انتخاب اعضاء:</label>
                        <div >
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}

                            />
                                {/*<select className="select2 w-full" multiple>*/}
                                {/*    <option value="1">حسن</option>*/}
                                {/*    <option value="1">عباس</option>*/}
                                {/*    <option value="1">رضا</option>*/}
                                {/*    <option value="1">حسین</option>*/}
                                {/*</select>*/}
                        </div>
                    </div>
                    <div class=" flex gap-3 justify-center">
                        <button className="button w-24 bg-themeDanger  text-white  "> انصراف </button>
                        <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation ">ایجاد</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={modal.addAccDocument}  closeModal={addAccDocumentOnHide}>
                <div>
                    <h2 class="p-2 border-b dark:border-themeDarkColorLine text-xs dark:text-gray-300">مشخصات محصول را وارد کنید</h2>
                    <div class="grid col-span-1 md:grid-cols-2 gap-2 p-3">
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">کد معین:</label>
                            <div>
                                <Select
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                                />
                            </div>
                            {/*<select name="" className=" select2 w-full">*/}
                            {/*    <option value="1">کد اصلی</option>*/}
                            {/*    <option value="2">کد تفصیلی</option>*/}
                            {/*</select>*/}
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">کد تفضیلی:</label>
                            <div>
                                <Select
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                                />
                            </div>
                            {/*<select name="" className=" select2 w-full">*/}
                            {/*    <option value="1">کد اصلی</option>*/}
                            {/*    <option value="2">کد تفصیلی</option>*/}
                            {/*</select>*/}
                        </div>
                    </div>
                    <div class="grid col-span-1 md:grid-cols-2 gap-2 p-3">
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">بدهکار:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">بستانکار:</label>
                            <InputText type={"text"} className={"input w-full "} />
                        </div>
                    </div>
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">شرح:</label>
                        <InputTextarea rows={5}  className={"input w-full h-full"} />
                    </div>
                    <div class="flex justify-center gap-2 p-3">
                        <button className="button w-24  bg-themeDanger  text-white" > انصراف
                        </button>
                        <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation shadow-md AddCloneTwo"> ثبت</button>
                    </div>
                </div>
            </FantasyModal>
        </div>
    )

}
