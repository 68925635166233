import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";


import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {GridTable} from "../Commans/Grid/GridTable";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import Select from "react-select";
import {useSetting} from "../Commans/Hooks/useSetting";
import {Accordion, AccordionTab} from "primereact/accordion";
import {InputNumber} from "primereact/inputnumber";
import {ToggleSwitch} from "../Commans/UIParts/ToggleSwitch";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";

export const HandleTransferBetweenInventory = ()=>{
  const { t } = useTranslation();

  const redirectTo = useNavigate();
  const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
  const [visible, setVisible] = useState(false);
  const [IsOpen, setIsOpen] = useState(false);


  const handleOpenReminder=()=>{

    setIsOpen(true);
  }

  const handleCloseReminder=()=>{
    setIsOpen(false);
  }


  const [userId,setUserId] = useState('');


  const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

  const columnDefs = [
    { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 110},

    { headerName:t("Party"), minWidth: 140,
      cellRenderer:parameters=>
          <>
            مسعود احمدی
          </>

    },

    { headerName:t("Branch"), minWidth: 140,
      cellRenderer:parameters=>
          <>
            تهران
          </>

    },
    { headerName: t("Inventory"),
      cellRenderer:parameters=>
          <>
            65654
          </>
    },

    { headerName: t("TransactionType"), minWidth: 140,
      cellRenderer:parameters=>
          <>
          5454545
          </>
    },
    { headerName: t("FactorNumber"), maxWidth: 160,
      cellRenderer:parameters=>
          <>
          54545454545
          </>

    },
    {
      headerName: t("DocumentNumber"), maxWidth: 110,
      cellRenderer: parameters =>
          <>
           14121
          </>

    },
    { headerName: t("NumberItems"), maxWidth: 130,
      cellRenderer: parameters =>
          <>
            14
          </>

    },

    { headerName: t("Referral"), minWidth: 140,
      cellRenderer: parameters =>
          <div>
            <Link to={"#"} className={"table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 text-xs"}>ارجاع به حسابداری</Link>
          </div>

    },


    { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
      cellRenderer:params => {
        return<div className={'flex justify-center items-center gap-3'} >
          <GridButtonEdit/>
          <GridButtonDelete/>
        </div>
      }

    },

  ];

  const rowData = [

    { id:1, date:'1402/10/3', },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      filter: true,
      sortable: true,
      wrapText: true,
      resizable: true,
      // resizable: true,
      // editable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const onSelectionChanged=(data)=>{
    console.log(data);
  }
  const onCellValueChanged = (data)=>{
    console.log(data);
  }


  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };



  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = (event) => {
    setQuickFilterText(event.target.value);
  };




  return (
      <>



        <div class=" space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
            <ListSearch label={null} icon={null} >
              <div class="space-y-2 ">
                <label className="text-xs dark:text-gray-300"> {t("SearchByTitle")}</label>
                <InputText type={"text"} className={" input w-full"} />
              </div>
              <ButtonSearchList />
            </ListSearch>
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>
                            <ButtonPlus
                                onClick={() => handleOpenReminder()}
                                tooltipTitle={t("DocumentRegistration")}

                            />

                            <ButtonPlus
                                onClick={() => redirectTo("/Inventory/Goods/Add")}
                                title={t("Inventory")}
                                tooltipTitle={t("CreateInventory")}

                                icon={"edit"}
                            />
                            <ButtonPlus
                                onClick={() => redirectTo("/Inventory/Goods/Add")}
                                title={t("GoodsRegistration")}
                                tooltipTitle={t("GoodsRegistration")}

                                icon={"file-plus"}
                            />
                            <ButtonPlus
                                onClick={() => redirectTo("/Inventory/EntryDocument")}
                                title={t("DocumentRegistration")}
                                tooltipTitle={t("DocumentRegistration")}

                                icon={"file-plus"}
                            />
                            <ButtonPlus
                                onClick={() => redirectTo("/Inventory/ProductionSeries")}
                                title={t("ProductionSeries")}
                                tooltipTitle={t("ProductionSeries")}

                                icon={"edit"}
                            />

                            <ButtonPlus
                                onClick={() => redirectTo("/Inventory/List/Requests")}
                                title={t("list")}
                                tooltipTitle={t("ListOfRequests")}

                                icon={"list"}
                            />

                            <ButtonPlus
                                onClick={()=>redirectTo(`/Inventory/Report`)}
                                icon={"pie-chart"}
                                title={t("Report")}
                                functionClass={"harmony-return-list"}
                                tooltipTitle={t("InventoryStockReport")}

                            />

                          </>
                        }
                        childrenTwo={
                          <>
                            <div className=" space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("LogIn")}</lable>
                              <div>
                                <input className="input input--switch border bg-white" type="checkbox"/>
                              </div>
                            </div>
                            <div className=" space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("Exit")}</lable>
                              <div>
                                <input className="input input--switch border bg-white" type="checkbox"/>
                              </div>
                            </div>
                            <div className="space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("Returned")}</lable>
                              <div>
                                <input className="input input--switch border bg-white" type="checkbox"/>
                              </div>
                            </div>
                            <div className="space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("ReturnFromPurchase")}</lable>
                              <div>
                                <input className="input input--switch border bg-white" type="checkbox"/>
                              </div>
                            </div>
                          </>
                        }
            />


          </div>
          <div>
            <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                       enableRtl={true}
                       pagination={true}
                       getRowHeight={getRowHeight}
                       onGridReady={onGridReady}
                       quickFilterText={quickFilterText}
            />
          </div>

        </div>

        <FantasyModal openModal={IsOpen}  closeModal={handleCloseReminder} title={t("DocumentRegistration")} customeClassName={"modal-xl"}>
          <div className={"space-y-5"}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-3">
                <div className="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("Group")}</label>
                  <Select
                      placeholder={t("selection")}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">{t("Party")}</label>
                  <Select
                      placeholder={t("selection")}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">{t("Inventory")}</label>
                  <Select
                      placeholder={t("selection")}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("TransactionType")}</label>
                  <Select
                      placeholder={t("selection")}
                      menuPosition={"fixed"}
                      classNamePrefix={"h_multi_select"}
                  />
                </div>
                <div className="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">{t("FactorNumber")}</label>
                  <InputText type={"text"} className={" input w-full"}/>
                </div>
                <div className="space-y-1 ">
                  <div className={"flex justify-between items-center gap-2"}>
                    <label className="text-xs dark:text-gray-300">{t("DocumentNumber")}</label>
                    <ToggleSwitch  customClass={"w-28"} name={'applyDiscount'} checked={t("automatic")} unchecked={t("manual")} />
                  </div>
                  <InputText  className={" input w-full"}/>
                </div>
              </div>
            <Accordion className={"bg-white dark:bg-themeDarkSalt700"}>
              <AccordionTab header={<div>{t("Products")}</div>}>
                <div className={"space-y-5"}>
                  <div className="post-input p-2 rounded-md dark:bg-themeDarkPostInput grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    <div className={"flex w-full items-center gap-1"}>
                      <div className={"flex-1 flex gap-1 "}>
                        <div className="mt-auto flex-shrink-0">
                          <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                            1
                          </div>
                        </div>
                        <div className="space-y-1 flex-1">
                          <label className="text-xs dark:text-gray-300">{t("product")}</label>
                          <Select
                              isMulti={false}
                              isSearchable={true}
                              placeholder={t("selection")}
                              menuPosition={'fixed'}
                              classNamePrefix={"h_multi_select"}
                          />
                        </div>
                      </div>
                      <div className="space-y-1 w-14">
                        <label className="text-xs dark:text-gray-300">{t("Count")}</label>
                        <InputNumber type={"text"}   name={'quantity'}  />
                      </div>
                    </div>
                    <div className="space-y-1 flex-1 w-full">
                      <label className="text-xs dark:text-gray-300">{t("AvailableBuildSeries")}</label>
                      <Select
                          isMulti={false}
                          isSearchable={true}
                          placeholder={t("selection")}
                          menuPosition={'fixed'}
                          classNamePrefix={"h_multi_select"}
                      />
                    </div>
                    <div className={"flex items-end flex-1 gap-2 w-full"}>
                      <div class="flex-1 space-y-1 w-full">
                        <label className="text-xs dark:text-gray-300">{t("description")}</label>
                        <InputText type={"text"}  name={'description'}  />
                      </div>
                      <div className="flex gap-2 flex-shrink-0">
                        <button  type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                          <HPrimeIcon icon={'pi-plus'}  />
                        </button>
                        <button   type="button" className="button small_button bg-themeDanger text-white ">
                          <HPrimeIcon icon={'pi-minus'}  />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
              <button type={'submit'}  className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("register")}</button>
          </div>
        </FantasyModal>


      </>

  )
}