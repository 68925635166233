import React, {useEffect, useState} from "react";
import {extractPackages} from "../websiteService";
import {handleFailureMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {useNavigate} from "react-router-dom";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";
import {NoDataHarmony} from "../../../../Commans/NoDataHarmony";
import ArrowLeftIcon from "../../../../HIcon/ArrowLeftIcon";

export const Packages = ({onChangePackage,handleChangeTab,values}) => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const[spinner,setSpinner] = useState(false);
    const [selectedPackages, setSelectedPackages] = useState( []); // To store selected packages
    const [packages,setPackages] = useState([]);


    const handleCheckboxChange = (id,isChecked) => {
        onChangePackage(id,isChecked);
        if (isChecked) {
            setSelectedPackages((prevSelected) => [...prevSelected, id]);
        } else {
            setSelectedPackages((prevSelected) =>
                prevSelected.filter((selectedId) => selectedId !== id)
            );
        }
    };

    const onApiError = ()=>{
        setSpinner(false);
    }

    const handleChangeViewTab = (tabId) => {
        if (values.PackageIds.length === 0) {
            handleFailureMessage(t("Dearuserpleaseselectapackage"));
            return;
        }
        handleChangeTab(tabId);
    };



    const handleFetchData = async ()=>{
        try {
            setSpinner(true);
             const response = await GET(`/Packages/Selection`,{},'IEnumerable')
            if(response.status === 200){
                setSpinner(false)
                const packages = extractPackages(response.data);
                setPackages(packages);
            }else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            console.log(e)
        }
    }
    useEffect(()=>{
        handleFetchData();
    }, []);


    const handleIsPackageChecked=(id)=>values.PackageIds.includes(id);

    return (
        <div>
            <div>
                <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                    <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                        1
                    </div>
                    <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                        <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">
                            {t("SelectPackage")}
                        </h2>
                    </div>
                    <div>
                        <span className={"arrow_design_add_site"}>
                            <ArrowLeftIcon/>
                        </span>
                    </div>
                </div>
            </div>
            <div className={"space-y-5"}>
                <div class="mt-5 relative px-2">
                    {

                        spinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-5 ">
                        {
                            packages && packages.length > 0 ?
                                packages.map((item,index)=>{return (

                                    <div key={index} className="add_web_package post-input border-animation overflow-hidden dark:bg-themeDarkPostInput">
                                        <label htmlFor={`content-${item.id}`}>
                                            <input
                                                type="checkbox"
                                                className="checkbox-input hidden"
                                                onChange={(e) => handleCheckboxChange(item.id,e.target.checked)}
                                                data-hm="website"
                                                id={`content-${item.id}`} // Unique id for each checkbox
                                                checked={handleIsPackageChecked(item.id)}
                                            />
                                            <div className="checkbox-tile checkbox-wrapper size-full flex p-2 items-center">
                                                <div className=" size-16 flex-shrink-0 bg-white dark:bg-themeDarkSalt700 border border-themeInformation rounded-full overflow-hidden">
                                                    <img src={item.imageSrc} alt="" className="size-full object-cover " />
                                                </div>
                                                <div className="flex justify-center items-center flex-1 p-1 checkbox-label">
                                                    <h2 className="text-center py-2 dark:text-gray-300">{item.name}</h2>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                )}):
                                <NoDataHarmony
                                    classStyle={"sm:col-span-2 md:col-span-3 lg:col-span-5 bg-white dark:bg-themeDarkSalt700"}
                                />
                        }
                    </div>
                </div>
                <div class="flex md:justify-end justify-center">
                    <button type={'button'} onClick={()=>handleChangeViewTab('users')} className="block button w-24 bg-themeInformation dark:bg-themeDarkInformation text-white">{t("Next")}</button>
                </div>
            </div>

        </div>


    );
};

