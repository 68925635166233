import React, {useState} from "react";
import queryString from "query-string";
import {useTranslation} from "react-i18next";
import ArrowLeftIcon from "../../../HIcon/ArrowLeftIcon";

export const MultiQuestion = ({ question, multiItems, index, handleAddSurveyQuestion, surveyId }) => {

    const { t } = useTranslation();
    const queryParams = queryString.parse(window.location.search);

    const [selectedItem, setSelectedItem] = useState(null);
    const handleChangeMulti = (surveyItemId) => {
        setSelectedItem(surveyItemId); // ذخیره آیتم انتخاب شده
        handleAddSurveyQuestion(
            {
                surveyItemId: surveyItemId,
                surveyId: surveyId,
                textSurvey: ''
            },
            index
        );
    };





    return (
        <>


            <div className="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                <div className="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                    {index + 1}
                </div>
                <div className="flex-1 md:border-4 border-dashed lg:p-5 rounded">
                    <h2 className="lg:text-xl text-themeInformation dark:text-themeDarkInformation">
                        {question}
                    </h2>
                </div>
                <div>
                    <span className="arrow_design_add_site">
                         <ArrowLeftIcon/>
                    </span>
                </div>
            </div>
            <div className={"px-2"}>
                <div className="space-y-5">
                    <div className={"post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                        <p className="dark:text-gray-300 text-xs">{t("ChooseTheOptionYouWant")}</p>
                    </div>
                    <div className="flex flex-wrap gap-5 px-2">
                        {multiItems.map((surveyItem, surveyItemIndex) => (
                            <div key={surveyItemIndex} className={"flex items-center justify-center gap-2"}>
                                <div className="underline dark:text-gray-300">
                                    {surveyItemIndex + 1}
                                </div>
                                <label htmlFor={surveyItem.id} className={`flex items-center gap-2 py-3 px-2 border min-w-20 post-input post-input-design  cursor-pointer dark:border-themeDarkColorLine ${selectedItem === surveyItem.id ? 'border-themeInformation bg-themeLightGray' : 'dark:bg-themeDarkPostInput'}`}>
                                    <input
                                        className={"flex-shrink-0"}
                                        type="radio"
                                        name={`multiOptions_${index}`}
                                        id={surveyItem.id}
                                        onChange={() => handleChangeMulti(surveyItem.id)}
                                    />

                                        <h2 className={`${selectedItem === surveyItem.id ? '' : 'dark:text-gray-300'}`}>{surveyItem.surveyItemName}</h2>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
