export const validate = (schema,apiModel,OnSetErrors)=>{
    const { error } = schema.validate(apiModel, {
        abortEarly: false,
        stripUnknown: true,
    });
    if (error) {
        const validationErrors = {};
        error.details.forEach((detail) => {
            validationErrors[detail.path[0]] = detail.message;
        });
        OnSetErrors(validationErrors);
        return false;
    }else{
        return true;
    }
}
