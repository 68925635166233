import React from "react";
import Select from "react-select";

import {useTranslation} from "react-i18next";
import {InputNumber} from "primereact/inputnumber";
import {InputText} from "primereact/inputtext";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";

export const Product=({index,initialSource,item,handleRemoveRow,handleChange,handleAddRow,handleOpenTransport})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className="post-input p-2 dark:bg-themeDarkPostInput grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 post-input-design">
                <div className={"flex md:col-span-2 w-full items-center gap-1"}>
                    <div className={"flex-1 flex gap-1 "}>
                            <div className="mt-auto flex-shrink-0">
                                <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                                    {index+1}
                                </div>
                            </div>
                            <div className="space-y-1 flex-1">
                                <label className="text-xs dark:text-gray-300">{t("product")}</label>
                                <Select
                                    options={initialSource.productTypes}
                                    isMulti={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleChange(item.fakeId,'productId',data,item.id)}
                                    placeholder={t("selection")}
                                    value={item.productId}
                                    menuPosition={'fixed'}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                        </div>
                    <div className="space-y-1 w-14">
                            <label className="text-xs dark:text-gray-300">{t("Count")}</label>
                            <InputNumber type={"text"} name={'quantity'} onValueChange={(e)=>handleChange(item.fakeId,'quantity',e.value,item.id)} value={item.quantity} />
                        </div>
                </div>
                <div className="space-y-1 flex-1 w-full">
                    <label className="text-xs dark:text-gray-300">{t("type")}</label>
                    <Select
                        options={initialSource.cartTypes}
                        isMulti={false}
                        isSearchable={true}
                        onChange={(data)=>handleChange(item.fakeId,'cartItemType',data,item.id)}
                        placeholder={t("selection")}
                        value={item.cartItemType}
                        menuPosition={'fixed'}
                        classNamePrefix={"h_multi_select"}
                    />
                </div>
                <div class="space-y-2">
                    <div className={"flex justify-between items-center gap-2"}>
                        <label className="text-xs dark:text-gray-300">
                            {t('SendingMethod')}
                        <RequiredIcon/>
                        </label>
                        <button type="button" onClick={()=>handleOpenTransport(index)} className="text-themeInformation dark:text-themeDarkInformation text-xs">({t("Add")})</button>
                    </div>

                    <Select
                        options={initialSource.productTransports}
                        isMulti={false}
                        isSearchable={true}
                         onChange={(data)=>handleChange(item.fakeId,'productTransportId',data,item.id)}
                         placeholder={t("selection")}
                         value={item.productTransportId}
                         menuPosition={'fixed'}
                        classNamePrefix={"h_multi_select"}
                    />
                </div>
                <div className={"flex items-end flex-1 gap-2 w-full"}>
                    <div class="flex-1 space-y-1 w-full">
                        <label className="text-xs dark:text-gray-300">{t("description")}</label>
                        <InputText type={"text"}  name={'description'} onChange={(e)=>handleChange(item.fakeId,'description',e.target.value,item.id)} value={item.description} />
                    </div>
                    <div className="flex gap-2 flex-shrink-0">
                        <button onClick={()=>handleAddRow()} type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white ">
                            <HPrimeIcon icon={'pi-plus'}  />
                        </button>
                        <button  onClick={() => handleRemoveRow(item.fakeId, item.id)} type="button" className="button small_button bg-themeDanger text-white">
                            <HPrimeIcon icon={'pi-minus'}  />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}