import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {GridTable} from "../../Commans/Grid/GridTable";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import ButtonVideo from "../../Commans/UIParts/ButtonVideo";
import Select from "react-select";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {usePaymentGrid} from "./usePaymentGrid";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {useCustomPayment} from "./useCustomPayment";
import {InputNumber} from "primereact/inputnumber";
import {
    handleCreateManualPayment,
    handleCreatePayment,
    handleValidateAmount,
    handleValidateWallet
} from "./paymentService";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
// import {HandleGeneralSetting} from "../HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {Tooltip} from "react-tooltip";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {BankCreation} from "../../Commans/Accounting/Banks/BankCreation";
import {PriceFormatterComponent} from "../../Commans/Accounting/PriceFormatterComponent";
import {User} from "../../Commans/Globals/User";



export const HandleShipping = ({currentPath}) => {

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsOpen,setIsOpen] = useState(false);
    const [IsOpenSwitching,setIsOpenSwitching] = useState(false);
    const [IsOpenManualPayment,setOpenManualPayment] = useState(false);
    const [IsShowSpinner,setShowSpinner] = useState(false);
    const [errors,setErrors] = useState({});
    const [refresh,setRefresh] = useState(false);
    const [butLoading,setButLoading] = useState(false);
    const [IsBankOpen,setIsBankOpen] = useState(false);
    const [initialSource,setInitialSource] = useState({gateways:[],banks:[],boxes:[],currencies:[],serviceTypes:[],transactionStates:[],paymentStates:[]});
    const {handleValue,handleSelectValue,handleNumberValue,payment,setPayment,handleBillDate} = useCustomPayment(setErrors);



    const handleOpenRequestStatus = () => {
        // const sendType = initialSource.sendTypes.find((item)=>item.value === sendTypeStatus);
        // setSendTypes(prevState => ({...prevState,productCartId:productCartItemId,sendType: sendType}));
        // setModal((prevState) => ({ ...prevState, RequestStatus: true }));
        setIsOpenSwitching(true);
    };

    // closes modals of RequestStatus of user
    const handleCloseRequestStatus=()=>{
        setIsOpenSwitching(false);
    }


    const handleOpenPayment=async(amount,currency,paymentId,userId,checkout)=>{
        //setPayment((prevState)=>({...prevState,totalAmount: amount,amount: amount,currencyType:currency,paymentId: paymentId,restrications: [...prevState.restrications, checkout],walletAmount: 0,walletCurrency: bala }));
        try {
            showSpinner();
            const response = await GET(`/Payments/PSPAccount/Selection?CurrencyType=${currency}`,{},'IEnumerable');
            const responseWallet = await GET(`/Wallets/${userId}/Balance`,{},'single');
            if(response.status === 200 && responseWallet.status === 200){
                const gatewayAccounts = convertSelectDataHandler(response.data);
                setInitialSource(prevState => ({...prevState,gateways: gatewayAccounts}));
                const {balance} = responseWallet.data;
                setPayment((prevState)=>({...prevState,totalAmount: amount,amount: amount,currencyType:currency,paymentId: paymentId,restrications: [...prevState.restrications, checkout],walletAmount: 0,walletCurrency: balance.currency,walletBalance: balance.amount,pspAccountId:gatewayAccounts[0] }));

                //setPayment((prevState)=>({...prevState,bankAccountId: gatewayAccounts[0],walletBalance:balance.amount,walletCurrency: balance.currency,walletAmount: 0}));
                setIsOpen(true);
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
        }

    }


    const handleResetOnlinePayment=()=>{
        setPayment({discountCode: '',totalAmount: 0,amount: 0,walletAmount: 0,description: '',recieptCode: 0,billDate: new Date(), pspAccountId: initialSource.gateways[0],restrications:[]});
    }
    const handleResetManualPayment=()=>{
        setPayment({discountCode: '',totalAmount: 0,amount: 0,walletAmount: 0,description: '',recieptCode: 0,billDate: new Date(), pspAccountId: initialSource.gateways[0],bankId: initialSource.banks[0],restrications: []});

    }

    const handleClosePayment=()=>{
        setIsOpen(false);
        handleResetOnlinePayment();
    }

    const handleOpenManualPayment=async(amount,currency,paymentId,userId,checkout)=>{
        try {
            showSpinner();
            const response = await GET(`/Accountings/BankAccounts/Selection`,{},'IEnumerable');
            const responseBank = await GET(`/Banks`,{},'IEnumerable');
            const responseWallet = await GET(`/Wallets/${userId}/Balance`,{},'single');
            if(response.status === 200 && responseBank.status === 200 && responseWallet.status === 200){
                const gatewayAccounts = convertSelectDataHandler(response.data);
                const banks = convertSelectDataHandler(responseBank.data);
                setInitialSource(prevState => ({...prevState,gateways: gatewayAccounts,banks: banks}));
                const {balance} = responseWallet.data;
                setPayment((prevState)=>({...prevState,totalAmount: amount,amount:amount,currencyType:currency,paymentId: paymentId,restrications: [...prevState.restrications, checkout],description: '',recieptCode: 0,billDate: new Date(),walletCurrency:balance.currency,walletBalance:balance.amount,bankAccountId: gatewayAccounts[0],bankId: banks[0],walletAmount: 0  }));

                setOpenManualPayment(true);
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleCloseManualPayment=()=>{
        setOpenManualPayment(false);
        handleResetManualPayment();
    }

    const handleSuccess=async(isSuccess)=>{
        if(isSuccess){
            const response = await GET(`/Banks`,{},'IEnumerable');
            if(response.status === 200){
                const banks = convertSelectDataHandler(response.data);
                setPayment((prevState)=>({...prevState,bankId: banks[0] }));
                setInitialSource(prevState => ({...prevState, banks:banks}));
                handleCloseBank();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
    }

    const handleOpenBank=()=>{
        setIsBankOpen(true);
    }
    const handleCloseBank=()=>{
        setIsBankOpen(false);
    }




    const showSpinner=()=>{
        setShowSpinner(true);
    }
    const hideSpinner=()=>{
        setShowSpinner(false);
    }
    const showBtnLoading=()=>{
        setButLoading(true);
    }
    const hideBtnLoading=()=>{
        setButLoading(false);
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }

    const handleInitialSource=(serviceTypes,currencies,paymentStatuses,transactionStatuses)=>{
        const serviceTypeData = convertSelectDataHandler(serviceTypes);
        const currencyData = convertSelectDataHandler(currencies);
        const paymentStateData = convertSelectDataHandler(paymentStatuses);
        const transactionStatusData = convertSelectDataHandler(transactionStatuses);
        setInitialSource({
            serviceTypes: serviceTypeData,
            currencies: currencyData,
            paymentStates:paymentStateData,
            transactionStates: transactionStatusData
        })
    }
    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }

    const fetchData=async()=>{
        try {
            const responseService = await GET(`/Enumerations/EnCheckout`,{},'IEnumerable');
            const responseCurrency = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
            const responsePaymentState = await GET(`/Enumerations/EnPaymentStatus`,{},'IEnumerable');
            const responseTransaction = await GET(`/Enumerations/EnTransactionState`,{},'IEnumerable');
            if(responseService.status === 200 && responseCurrency.status === 200 && responsePaymentState.status === 200 && responseTransaction.status === 200){
                handleInitialSource(responseService.data,responseCurrency.data,responsePaymentState.data,responseTransaction.data);
            }
            else{
                handleApiError(responseService,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(()=>{
        fetchData();
    },[])


    const Balance=()=>{
        if(payment.walletBalance >= payment.totalAmount){
            setPayment(prevState => ({...prevState,walletAmount: payment.totalAmount,amount: 0}));
        }
        else{
            setPayment(prevState => ({...prevState,walletAmount: payment.walletBalance,amount: payment.totalAmount - payment.walletBalance}));
        }
    }



    //considering discount for this payment
    const handleApplyDiscount=async()=>{
        try {

            if(payment.discountCode === null || payment.discountCode === ""){
                return;
            }
            showBtnLoading();
            showSpinner();
            const response = await POST(`/Payments/ApplyDiscountCode`,{paymentId:payment.paymentId,code:payment.discountCode},false);
            if(response.status === 200){
                hideSpinner();
                Refresh();
                setPayment(prevState => ({...prevState,discountCode: ''}));
                handleSuccessMessage(t('SuccessDone'));
                hideBtnLoading();
                const {amount} = response.data.payableAmount;
                setPayment(prevState => ({...prevState,totalAmount:amount,amount: amount }));
            }else{
                hideBtnLoading();
                handleApiError(response,hideSpinner);

            }
        }
        catch (e) {
            console.log(e);
        }
    }


    const handleOnlinePaymentSubmit=async(e)=>{
        e.preventDefault();
        const isValidAmount=handleValidateAmount(payment.amount,payment.walletAmount,payment.totalAmount);
        console.log(payment);
        if(!isValidAmount){
            handleFailureMessage(t('NotValidBillAmount'));
            return;
        }
        const isValidWallet = handleValidateWallet(payment.walletBalance,payment.walletAmount);
        if(!isValidWallet){
            handleFailureMessage(t('NotEnoughWalletBalance'));
            return;
        }

        try {
            showSpinner();
            const createdPayment = handleCreatePayment(payment);
            const response = await POST(`/Payments/Pay`,createdPayment,false);
            if(response.status === 200){
                if(response.data.url){
                    hideSpinner();
                    Refresh();
                    handleClosePayment();
                    const {url} = response.data;
                    window.open(url, "_blank");
                }
                else{
                    hideSpinner();
                    Refresh();
                    handleClosePayment();
                }
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    const handleManualPaymentSubmit=async(e)=>{
        e.preventDefault();
        const isValidAmount=handleValidateAmount(payment.amount,payment.walletAmount,payment.totalAmount);
        if(!isValidAmount){
            handleFailureMessage(t('NotValidBillAmount'));
            return;
        }
        const isValidWallet = handleValidateWallet(payment.walletBalance,payment.walletAmount);
        if(!isValidWallet){
            handleFailureMessage(t('NotEnoughWalletBalance'));
            return;
        }
        if( payment.bankId === null || payment.bankId.length === 0 ){
            handleFailureMessage(t('PleaseSelectBank'));
            return;
        }
        try {
            showSpinner();
            const createdPayment = handleCreateManualPayment(payment);
            const response = await POST(`/Payments/ManualPayPayment`,createdPayment,false);
            if(response.status === 200){
                hideSpinner();
                Refresh();
                handleCloseManualPayment();
                handleSuccessMessage(t('SuccessDone'))
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    const [IsUserOpen,setUserOpen] = useState(false);
    const handleOpenUser=async (userId)=>{
        setUserOpen(true);
        setPayment((prevState)=>({...prevState,userId: userId}));
    }
    const handleCloseUser=()=>{
        setUserOpen(false);
    }






    const {handleToDate,handleFromDate,autoGroupColumnDef,columnDefs,defaultColDef,handleToggleFilter,handleResetFilters,handleSearch,filter,handleCurrentPage,handleNextPage,handlePreviousPage,handleSelectFilter,handlePageSizeCount,setFilter,SwitchFilters,search,setSearch,rowData,getRowHeight,pagination} = usePaymentGrid(handleOpenPayment,setShowSpinner,hideSpinner,fetchData,refresh,Refresh,initialSource,handleOpenManualPayment,currentPath,handleOpenUser,handleOpenRequestStatus);










    return(
        <>
            {console.log(payment)}
            <div class="space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 space-y-5 p-3">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 ">{t("SearchByBill")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}</label>
                            <DateTimePicker value={filter.fromDate} handleDateTime={handleFromDate}  format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}</label>
                            <DateTimePicker value={filter.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>handleResetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            icon={"pi-wallet"}
                                            title={t("Wallet")}
                                            tooltipTitle={t("Wallet")}
                                            onClick={()=>redirectTo("/CRM/User/Wallet")}
                                        />
                                        <ButtonPlus
                                            icon={"pi-shopping-bag"}
                                            title={t("ShoppingCart")}
                                            tooltipTitle={t("ShoppingCart")}
                                            onClick={()=>redirectTo("/Shop/Product/Cart")}
                                        />
                                        <ButtonPlus
                                            icon={"pi-sparkles"}
                                            title={t("Upgrade")}
                                            tooltipTitle={t("Upgrade")}
                                            onClick={()=>redirectTo("/Admin/Website/List/Payment/Upgrade")}
                                        />
                                        <ButtonPlus
                                            icon={"pi-list"}
                                            title={t("list")}
                                            tooltipTitle={t("ListOfTransactions")}
                                            onClick={()=>redirectTo("/Bank/Transaction/list")}
                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                                    </>
                                }
                    />

                </div>
                <div className={"space-y-2 H-body-ag-grid relative"} >
                    {
                        IsShowSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export apiUrl={''} filters={filter}/>
            </div>

            <FantasyModal openModal={IsOpen}  closeModal={handleClosePayment} title={t("onlinePayment")}>
                <form onSubmit={handleOnlinePaymentSubmit} method={'post'}>
                    <div class="space-y-3">
                        <div class="p-2 flex justify-between items-center rounded shadow bg-themeSecondary">
                            <p className="text-xs text-white">{t("BillAmount")}</p>
                            <h2 className={"text-xs text-white"}><PriceFormatterComponent price={payment.totalAmount}/> {GetCurrencyType(payment.currencyType)}</h2>
                        </div>
                        <div className={"grid grid-cols-1 sm:grid-cols-2 gap-3"}>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("BankGateway")}</label>
                                <Select
                                    options={initialSource.gateways}
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'pspAccountId')}
                                    placeholder={t("selection")}
                                    value={payment.pspAccountId}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("BankPaymentAmount")}</label>
                                <InputNumber type="text"  value={payment.amount} onChange={(e)=>handleNumberValue(e.value,'amount')}/>
                            </div>


                            {
                                payment.restrications && !payment.restrications.includes(3) ?
                                    <>
                                        <div className="space-y-2">
                                            <div className="flex justify-between items-center">
                                                <label className="text-xs dark:text-gray-300">{t("WithdrawFromTheWallet")}</label>
                                                <p onClick={()=>Balance()} className=" text-xs cursor-pointer text-themeInformation dark:text-themeDarkInformation">{payment.walletBalance} {GetCurrencyType(payment.walletCurrency)}</p>
                                            </div>
                                            <InputNumber type="text"  value={payment.walletAmount} onChange={(e) => handleNumberValue(e.value, 'walletAmount')} />
                                        </div>
                                        <div className="space-y-2">
                                            {butLoading ? (
                                                <div className="flex justify-between items-center relative">
                                                    <label className="text-xs dark:text-gray-300">{t("DiscountCode")}</label>
                                                    <TinyLoading  />
                                                </div>
                                            ) : (
                                                <div className="flex justify-between items-center">
                                                    <label className="text-xs dark:text-gray-300">{t("DiscountCode")}</label>
                                                    <button type="button" onClick={() => handleApplyDiscount()} className="text-xs text-themeInformation dark:text-themeDarkInformation">{t("applyCode")}</button>
                                                </div>
                                            )}
                                            <InputText name="discountCode" value={payment.discountCode} onInput={(e) => handleValue(e)} type="text" className="input w-full" />
                                        </div>
                                    </>:null

                            }





                        </div>
                        {/*<Link to={"/Bank/Transaction/list"} className="button block w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("BillPayment")}</Link>*/}
                        <button type={'submit'} className="button block w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("BillPayment")}</button>
                    </div>

                </form>
            </FantasyModal>

            <FantasyModal openModal={IsOpenManualPayment}  closeModal={handleCloseManualPayment} title={t("ManualPayment")}>
                <form onSubmit={handleManualPaymentSubmit} method={'post'}>
                    <div class="space-y-3">
                        <div class="p-2 flex justify-between items-center rounded shadow bg-themeSecondary">
                            <p className="text-xs text-white ">{t("BankReceiptAmount")}</p>
                            <h2 className={"text-xs text-white"}><PriceFormatterComponent price={payment.totalAmount}/>  {GetCurrencyType(payment.currencyType)}</h2>
                        </div>
                        <div className={"grid grid-cols-1 sm:grid-cols-2 gap-3"}>
                            <div class="space-y-2">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">{t("SourceBank")}</label>
                                    <button type="button" onClick={()=>handleOpenBank()}  className="text-themeInformation dark:text-themeDarkInformation text-xs tooltip_class_Add_Bank">
                                        ({t("BankCreation")})
                                        <Tooltip anchorSelect={".tooltip_class_Add_Bank"} content={t("AddNewBanktoList")} />
                                    </button>
                                </div>
                                <Select
                                    options={initialSource.banks}
                                    isMulti={false}
                                    isClearable={true}
                                    isDisabled={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'bankId')}
                                    placeholder={t("selection")}
                                    value={payment.bankId}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("destinationBank")}</label>
                                <Select
                                    options={initialSource.gateways}
                                    isMulti={false}
                                    isClearable={true}
                                    isDisabled={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'bankAccountId')}
                                    placeholder={t("selection")}
                                    value={payment.bankAccountId}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("PaidAmount")}</label>
                                <InputNumber type="text"  value={payment.amount} onChange={(e)=>handleNumberValue(e.value,'amount')}/>
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("ReceiptNumber")}</label>
                                <InputNumber type="text"  useGrouping={false} value={payment.recieptCode} onChange={(e)=>handleNumberValue(e.value,'recieptCode')}/>
                            </div>

                            {
                                payment.restrications && !payment.restrications.includes(3) ?
                                    <>
                                        <div className="space-y-1">
                                            <div className="flex justify-between items-center">
                                                <label className="text-xs dark:text-gray-300">{t("WithdrawFromTheWallet")}</label>
                                                <p onClick={()=>Balance()} className=" text-xs cursor-pointer text-themeInformation dark:text-themeDarkInformation">{payment.walletBalance} {GetCurrencyType(payment.walletCurrency)}</p>
                                            </div>
                                            <InputNumber type="text"  value={payment.walletAmount} onChange={(e) => handleNumberValue(e.value, 'walletAmount')} />
                                        </div>
                                        <div className="space-y-1">
                                            {butLoading ? (
                                                <div className="flex justify-between items-center relative">
                                                    <label className="text-xs dark:text-gray-300">{t("DiscountCode")}</label>
                                                    <TinyLoading  />
                                                </div>
                                            ) : (
                                                <div className="flex justify-between items-center">
                                                    <label className="text-xs dark:text-gray-300">{t("DiscountCode")}</label>
                                                    <button type="button" onClick={() => handleApplyDiscount()} className="text-xs text-themeInformation dark:text-themeDarkInformation">{t("applyCode")}</button>
                                                </div>
                                            )}
                                            <InputText name="discountCode" value={payment.discountCode} onInput={(e) => handleValue(e)} type="text" className="input w-full" />
                                        </div>
                                    </>:null

                            }

                            <div className="space-y-2">
                                <label className="text-xs dark:text-gray-300">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("dateOfReceipt")}
                                </label>

                                <DateTimePicker isContainsTime={true}   handleDateTime={handleBillDate} format={'YYYY/MM/DD hh:mm:ss'} value={payment.billDate}/>

                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("Explanation")}</label>
                                <InputText name={'description'} value={payment.description} onInput={(e)=>handleValue(e)} type="text" className="input w-full" />
                            </div>
                        </div>
                        {/*<Link to={"/Bank/Transaction/list"} className="button block w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("BillPayment")}</Link>*/}
                        <button type={'submit'} className="button block w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("BillPayment")}</button>
                    </div>

                </form>
            </FantasyModal>

            <FantasyModal openModal={IsOpenSwitching}  closeModal={handleCloseRequestStatus} title={t("Switching")}>
                {/*<form method={'post'} onSubmit={handleEditSendType} className={"space-y-5"}>*/}
                    <div>
                        <Select
                            options={initialSource.sendTypes}
                            isMulti={false}
                            isSearchable={true}
                            // onChange={(data)=>handleChangeSendType(data,'sendType')}
                            placeholder={t("selection")}
                            // value={sendTypes.sendType}
                            menuPosition={"fixed"}
                            classNamePrefix={"h_multi_select"}
                        />
                    </div>
                    <button type={"submit"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("register")}</button>
                {/*</form>*/}
            </FantasyModal>

            {/*<FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >*/}
            {/*    <div>*/}
            {/*        {*/}
            {/*            setting === 'GeneralSetting'?*/}
            {/*                <HandleGeneralSetting/>:null*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</FantasyModal>*/}

            <FantasyModal  openModal={IsBankOpen}  closeModal={handleCloseBank}  title={t("Fastsettings")} >

                <BankCreation
                    IsOpen={IsBankOpen}
                    handleSuccess={handleSuccess}
                />

            </FantasyModal>

            <FantasyModal openModal={IsUserOpen} closeModal={handleCloseUser} title={t("user")}>
                <User
                    IsOpen={IsUserOpen}
                    userId={payment.userId}
                />
            </FantasyModal>



            <ToastContainer/>
        </>
    )
}