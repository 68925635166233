import React from "react";
import {ContractRow} from "./ContractRow";

export const Contracts=({contracts})=>{

    return (
        <>
            {
                contracts && contracts.length > 0 ? (
                    contracts.map((item, index) => (
                        <ContractRow index={index} {...item} />
                    ))
                ) : null
            }


        </>
    )
}