import React, {useEffect, useState} from "react";
import Select from "react-select";

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSecurityPrivacy} from "./useSecurityPrivacy";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {TagsInput} from "react-tag-input-component";
import {handleCreateManagerPrivacy} from "./ManagerPrivacyService";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {useSecurityGrid} from "./useSecurityGrid";
import {GridTable} from "../../../../Commans/Grid/GridTable";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../../Commans/Grid/Pagination";
import {Export} from "../../../../Commans/Grid/Export";
import {ToastContainer} from "react-toastify";
import {Checkbox} from "primereact/checkbox";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const UserPrivacy=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [errors,setErrors]=useState({});
    const {handleValue,handleSelectValue,setManagerPrivacy,managerPrivacy,handleChangeIps,handleCheckBox}=useSecurityPrivacy(setErrors)
    const [IsShowSpinner,setShowSpinner]=useState(false)
    const [initialSource,setInitialSource]=useState({
        authenticationModes:[]
    });
    const [refresh,setRefresh]=useState(false);
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }
    const showSpinner=()=>{
        setShowSpinner(true)
    }
    const hideSpinner=()=>{
        setShowSpinner(false);
    }







    const handleSubmit=async ()=>{
        try {
            showSpinner();
            const response = await POST(`/Settings/UpanelSecurity`,{
                blockIps:managerPrivacy.allowedIPs,
                authenticationMode:managerPrivacy.authenticationMode === null || managerPrivacy.authenticationMode === undefined?null:managerPrivacy.authenticationMode.value,
                IsMemberShip:managerPrivacy.IsMemberShip
            },false);
            if(response.status === 200){
                hideSpinner();
                handleSuccessMessage(t('SuccessDone'));
                setManagerPrivacy((prevState)=>({...prevState,
                  blockIps: []
                }))
                Refresh();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }



const {autoGroupColumnDef,columnDefs,defaultColDef,filter,setFilter,getRowHeight,rowData,handleNextPage, handleCurrentPage,handlePreviousPage,handlePageSizeCount,handleSearch,handleResetFilters,handleSelectFilter,handleToggleFilter,setSearch,search,pagination}=useSecurityGrid(showSpinner,hideSpinner,setManagerPrivacy,refresh,Refresh,setInitialSource);





    return (
        <>
            <div class={"relative"}>
                {
                    IsShowSpinner?
                        <div className="huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className={"space-y-5"}>
                    <div className={"grid grid-cols-1 lg:grid-cols-2 gap-5 "}>
                        <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-2">
                            <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                                <h2 className="text-xs dark:text-gray-300">
                                    {t("PasswordType")}
                                </h2>
                            </div>
                            <div class="space-y-1">
                                <label className="text-xs dark:text-gray-300">{t("ChoosePassword")}</label>
                                <Select
                                    options={initialSource.authenticationModes}
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                                    value={managerPrivacy.authenticationMode}
                                    onChange={(data)=>handleSelectValue(data,'authenticationMode')}
                                />
                            </div>
                            <div>
                                <label className={"text-xs dark:text-gray-300 flex gap-2 items-center cursor-pointer"}>
                                    <input type={"checkbox"} checked={managerPrivacy.IsMemberShip} name={'IsMemberShip'} onChange={(e)=>handleCheckBox(e.target.checked,'IsMemberShip')}></input>
                                    {t("PossibilityMembership")}
                                </label>
                            </div>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-2">
                            <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                                <h2 class="dark:text-gray-300 text-xs">
                                    {t("IPRestriction")}
                                </h2>
                            </div>
                            <div>
                                <div class="space-y-2">
                                    <div className={"flex items-end gap-2 "}>
                                        <div className={"space-y-1 flex-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("UnauthorizedIPs")}</label>
                                            <TagsInput  onChange={handleChangeIps}  classNames={"input"} value={managerPrivacy.blockIps} name={"blockIps"} placeHolder={'آی پی های غیرمجاز'}/>
                                        </div>
                                        <button onClick={()=>handleSubmit()} type={"button"} className={"button small_button bg-themeInformation dark:bg-themeDarkInformation text-white"}>
                                            <HPrimeIcon icon={"pi-plus"}/>
                                        </button>
                                    </div>
                                    <p className="text-xs dark:text-gray-300">
                                        {t("StaticIPTitle")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"space-y-2"}>
                        <GridTable
                            columnDefs={columnDefs}
                            rowSelection={GridSetting.rowSelection}
                            rowData={rowData}
                            style={GridSetting.gridStyle}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            pagination={GridSetting.pagination}
                            enableRtl={GridSetting.enableRtl}
                            domLayout={GridSetting.domLayout}
                            paginationPageSize={GridSetting.pageSize}
                            getRowHeight={getRowHeight}
                            localeText={AgGridEnglish}
                        />
                        <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    </div>
                </div>
            </div>
             <ToastContainer/>


        </>
    )
}