import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {TabMenu} from "../../../Commans/UIParts/TabMenu";
import Select from "react-select";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {useTabs} from "./Tabs/useTabs";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {validate} from "../../../../Services/Globals/validate";
import {productSchema} from "./productSchema";
import {useProduct} from "./useProduct";
import {SpecificationTab} from "./Tabs/SpecificationTab";
import {MetaTagTab} from "./Tabs/MetaTagTab";
import {SettingsTab} from "./Tabs/SettingsTab";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {InputNumber} from "primereact/inputnumber";
import {ToggleSwitch} from "../../../Commans/UIParts/ToggleSwitch";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {ProductGroup} from "../Groups/ProductGroup";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {TransportTab} from "./Tabs/TransportTab";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {useProductAttach} from "./useProductAttach";
import queryString from "query-string";
import {ProductAttach} from "./ProductAttach";
import {handleCreateProduct, handleCreateProductFormData} from "./productService";
import Swal from "sweetalert2";

import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {ToastContainer} from "react-toastify";
import {handleFailureMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {TagsInput} from "react-tag-input-component";
import {ProductTransport} from "../../../Public/HarmonySetting/ShopManagement/ProductTransports/ProductTransport";
import {ModalButtonOperations} from "../../../Commans/UIParts/ModalButtonOperations";
import {SlugGenerator} from "../../../Commans/Globals/AiFeatures/SlugGenerator";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const HandleAddProduct=()=>{
    const queryParams = queryString.parse(window.location.search);

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [errors,setErrors] = useState({});
    const[IsEdit,setIsEdit]=useState(false);
    const [IsAction,setIsAction]=useState(false);
    const [refresh,setRefresh]=useState(false);

    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }


    const handleOpenAction=()=>{
        setIsAction(true);
    }
    const handleCloseAction=()=>{
        setIsAction(false);
    }



    const [initialSource,setInitialSource]=useState({
         relatedProducts:[]
        ,discountOrCommissionTypes:[]
        ,productGroups:[]
        ,productTypes:[]
        ,metaData:[]
        ,users:[]
        ,templates:[]
        ,filters:[]
        ,sendingStates:[]
        ,transportTypes:[]
        ,attachTypes:[]
        ,currencies:[]
        ,surveyGroups:[]
    });
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {tabs,handleChangeTab,tabId}=useTabs();
    const [IsGroupOpen,setIsGroupOpen] = useState(false);
    const [IsTransportOpen,setIsTransportOpen]=useState(false);
    const [isMetaDataDisable,setMetaDataDisable] = useState(false);
    const [IsSameInsert,setSameInsert]=useState(false);

    const {addNewCard,deleteCard,files,setFiles,handleFileChange,handleFieldChange,handleInitialCards,handleGetAllCards,triggerFileInput,totalSize}=useProductAttach(initialSource)

    const {setProduct,product,handleChangeContent,handleChangeImage,handleKeywordsChange,handleSelectValue,handleNumberValue,handleRemoveImagePath,handleResetImages,handleValue,handleReleaseDateTime,handleDiscountToDate,handleDiscountFromDate,handleApplyIsAuto,handleWholeSaleDiscountToDate,handleWholeSaleDiscountFromDate,handleGetCurrencyType}=useProduct(setErrors,IsEdit);

    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const handleInitialDataSource = (
        responseDiscountOrCommissionType,
        responseProductGroup,
        responseProductType,
        responseMetaDataGroup,
        responseProductTransport,
        responseSendingState,
        responseTemplate,
        responseUsers,
        responseRelatedProducts,
        responseFilters,
        responseAttachType,
        responseCurrency,
        responseSurveyGroups
    ) => {
        const sources = {
            discountOrCommissionTypes: responseDiscountOrCommissionType,
            productGroups: responseProductGroup,
            productTypes: responseProductType,
            metaData: responseMetaDataGroup,
            transportTypes: responseProductTransport,
            sendingStates: responseSendingState,
            templates: responseTemplate,
            users: responseUsers,
            relatedProducts: responseRelatedProducts,
            filters: responseFilters,
            attachTypes: responseAttachType,
            currencies: responseCurrency,
            surveyGroups: responseSurveyGroups
        };
        const sourceData = Object.keys(sources).reduce((acc, key) => {
            acc[key] = convertSelectDataHandler(sources[key]);
            return acc;
        }, {});
        setInitialSource(sourceData);
        return {sourceData}
    };

    const fetchData = async () => {
        try {
            showSpinner();
            const [
                responseDiscountOrCommissionType,
                responseProductGroup,
                responseProductType,
                responseMetaDataGroup,
                responseProductTransport,
                responseSendingState,
                responseTemplate,
                responseUsers,
                responseRelatedProducts,
                responseFilters,
                responseAttachType,
                responseCurrency,
                responseWebsiteCurrency,
                responseSurveyGroup
            ] = await Promise.all([
                GET('/Enumerations/EnDiscountOrCommissionType', {}, 'IEnumerable'),
                GET(`/productGroups/Selection`, {}, 'IEnumerable'),
                GET('/Enumerations/EnProductType', {}, 'IEnumerable'),
                GET(`/MetaDataGroup/Selection`, {}, 'IEnumerable'),
                GET(`productTransports/Selection`, {}, 'IEnumerable'),
                GET(`/EnSendingStates`, {}, 'IEnumerable'),
                GET(`/TemplatePages/selection?Type=${1}&Type=${13}`, {}, 'IEnumerable'),
                GET(`/Users/Selection`, {}, 'IEnumerable'),
                GET(`/Products/Selection`, {}, 'IEnumerable'),
                GET(`/Filters/Selection?Table=${1}`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnProductAttachType`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable'),
                GET(`/Websites/Currency`, {}, 'IEnumerable'),
                GET(`/Surveys/Selection`, {}, 'IEnumerable'),
            ]);

            if (
                responseDiscountOrCommissionType.status === 200 &&
                responseProductGroup.status === 200 &&
                responseProductType.status === 200 &&
                responseMetaDataGroup.status === 200 &&
                responseProductTransport.status === 200 &&
                responseSendingState.status === 200 &&
                responseTemplate.status === 200 &&
                responseUsers.status === 200 &&
                responseRelatedProducts.status === 200 &&
                responseFilters.status === 200 &&
                responseAttachType.status === 200 &&
                responseCurrency.status === 200 &&
                responseWebsiteCurrency.status === 200 &&
                responseSurveyGroup.status === 200

            ) {
                const {
                    sourceData
                } = handleInitialDataSource(
                    responseDiscountOrCommissionType.data,
                    responseProductGroup.data,
                    responseProductType.data,
                    responseMetaDataGroup.data,
                    responseProductTransport.data,
                    responseSendingState.data,
                    responseTemplate.data,
                    responseUsers.data,
                    responseRelatedProducts.data,
                    responseFilters.data,
                    responseAttachType.data,
                    responseCurrency.data,
                    responseSurveyGroup.data
                );

                if (queryParams.productId) {
                    try {
                        setIsEdit(true);
                        setMetaDataDisable(true);
                        const response = await GET(`/Products/${queryParams.productId}`, {}, 'single');
                        if (response.status === 200) {
                            if (response.data.productAttaches.length === 0) {
                                handleInitialCards(sourceData.attachTypes);
                            } else {
                                const fileCards = handleGetAllCards(response.data.productAttaches, sourceData.attachTypes);
                                setFiles(fileCards);
                            }
                            setProduct((prevState) => ({
                                ...prevState,
                                productId: response.data.id ?? null,
                                name: response.data.title ?? null,
                                slug: response.data.slug ?? null,
                                affiliateScript: response.data.affiliateScript ?? null,
                                editor: response.data.editor ?? null,
                                excerpt: response.data.excerpt ?? null,
                                seoTitle: response.data.seoTitle ?? null,
                                seoDescription: response.data.seoDescription ?? null,
                                barCode: response.data.barcode ?? null,
                                iranCode: response.data.iranCode ?? null,
                                image: response.data.image ?? null,
                                seoKeyWords:response.data.seoKeywords ?? [],
                                wholeSaleDiscountAmount: response.data.wholeSaleDiscount?.amount ?? null,
                                wholeSaleDiscountFrom: response.data.wholeSaleDiscount?.from ?? null,
                                wholeSaleDiscountTo: response.data.wholeSaleDiscount?.to ?? null,
                                wholeSaleDiscountOrCommissionType: sourceData.discountOrCommissionTypes.find((item) => item.value === response.data.wholeSaleDiscount?.discountType) ?? null,
                                discountAmount: response.data.userDiscount?.amount ?? null,
                                discountFrom: response.data.userDiscount?.from ?? null,
                                discountTo: response.data.userDiscount?.to ?? null,
                               discountOrCommissionType: sourceData.discountOrCommissionTypes.find((item) => item.value === response.data.userDiscount?.discountType) ?? null,
                                price: response.data.userPrice?.amount ?? null,
                                wholeSalePrice: response.data.wholeSalePrice?.amount ?? null,
                                currency: responseWebsiteCurrency.data.currencyType ?? null,
                                type: sourceData.productTypes.find((item) => item.value === response.data.type) ?? null,
                                sendingStates: response.data.sendingStates !== null ?response.data.sendingStates.map((value) => sourceData.sendingStates.find((item) => item.value === value) ):[],
                                releaseDateTime: response.data.releaseDateTime ?? null,
                                productCode: response.data.productCode ?? null,
                                maxContent: response.data.maxContent ?? null,
                                templatePage: sourceData.templates.find((item) => item.value === response.data.templatePageId) ?? null,
                                displayOrderMaster: response.data.displayOrderMaster ?? null,
                                displayOrderSlave: response.data.displayOrderSlave ?? null,
                                filterId: sourceData.filters.find((item) => item.value === response.data.filterId) ?? null,
                                inventoryMax: response.data.inventoryMax ?? null,
                                inventoryMin: response.data.inventoryMin ?? null,
                                isInventory: response.data.isInventory ?? false,
                                stateAndroid: response.data.stateAndroid ?? false,
                                stateShow: response.data.stateShow ?? false,
                                stateGoogle: response.data.stateGoogle ?? false,
                                stateFreePost: response.data.stateFreePost ?? false,
                                producerId: sourceData.users.find((item) => item.value === response.data.producerId) ?? null,
                                supplierId: sourceData.users.find((item) => item.value === response.data.supplierId) ?? null,
                                productGroupIds: response.data.productGroupIds.map((value) => sourceData.productGroups.find((item) => item.value === value) ?? value) ?? [],
                                productSuggestionIds: response.data.productSuggestionIds.map((value) => sourceData.relatedProducts.find((item) => item.value === value) ?? value) ?? [],
                                productTransportIds: response.data.productTransportIds.map((value) => sourceData.productTransports.find((item) => item.value === value) ?? value) ?? [],
                                transportWidth: response.data.transportWidth ?? 0,
                                transportHeight: response.data.transportHeight ?? 0,
                                transportLength: response.data.transportLength ?? 0,
                                transportWeight: response.data.transportWeight ?? 0,
                                transportInsuranceCost: response.data.transportInsuranceCost ?? 0.00,
                                concurrencyStamp: response.data.concurrencyStamp ?? null,
                            }));
                        } else {
                            handleApiError(response, hideSpinner);
                        }
                    } catch (e) {
                        hideSpinner();
                    }
                }
                else {
                    setProduct(prevState => ({
                        ...prevState,
                        sendingStates: Array.isArray(sourceData.sendingStates) && sourceData.sendingStates.length > 0 ? [sourceData.sendingStates[0]] : null,
                        type: Array.isArray(sourceData.productTypes) && sourceData.productTypes.length > 0 ? sourceData.productTypes[0] : null,
                        discountOrCommissionType: Array.isArray(sourceData.discountOrCommissions) && sourceData.discountOrCommissions.length > 0 ? sourceData.discountOrCommissions[0] : null,
                        wholeSaleDiscountOrCommissionType: Array.isArray(sourceData.discountOrCommissions) && sourceData.discountOrCommissions.length > 0 ? sourceData.discountOrCommissions[0] : null,
                        applyAuto: true,
                        stateShow: true,
                        discountAmount: 0,
                        wholeSaleDiscountAmount: 0,
                        currency: responseWebsiteCurrency && responseWebsiteCurrency.data && responseWebsiteCurrency.data.currencyType != null
                            ? responseWebsiteCurrency.data.currencyType
                            : null,
                    }));
                    if (files.length === 0) {
                        handleInitialCards(sourceData.attachTypes);
                    }
                }
                if(queryParams.IsSameInsert){
                    setSameInsert(true);
                    setProduct((prevState)=>({...prevState,slug: '',image: null,concurrencyStamp:null}))
                    handleInitialCards(sourceData.attachTypes);
                }
                hideSpinner();
            } else {
                handleApiError(responseDiscountOrCommissionType, hideSpinner);
                handleApiError(responseProductGroup, hideSpinner);
                handleApiError(responseProductType, hideSpinner);
                handleApiError(responseMetaDataGroup, hideSpinner);
                handleApiError(responseProductTransport, hideSpinner);
                handleApiError(responseSendingState, hideSpinner);
                handleApiError(responseTemplate, hideSpinner);
                handleApiError(responseUsers, hideSpinner);
                handleApiError(responseRelatedProducts, hideSpinner);
                handleApiError(responseFilters, hideSpinner);
                handleApiError(responseAttachType, hideSpinner);
                handleApiError(responseWebsiteCurrency, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(() => {
        fetchData();
    }, [refresh]);


    useEffect(()=>{
        fetchData();
    },[])

    const handleSubmitProduct=async(e)=>{
        e.preventDefault();
        const isValid = validate(productSchema,product,onSetErrors);
        if(!isValid){
            return;
        }
        try {

            if(IsSameInsert){
                if(product.productGroupIds.length === 0){
                    handleFailureMessage(t("Selectyourarticlegroup"));
                    return;
                }
                showSpinner();
                product.productId = null;
                const productData = handleCreateProduct(product,files);
                const formData = handleCreateProductFormData(productData,false);
                const response = await POST(`/Products/CreateProduct`,formData,true);
                if(response.status === 200){
                    hideSpinner();
                    redirectTo('/Shop/Product/List')
                }
                else{
                    handleApiError(response,hideSpinner);
                }
                return;
            }


              if(IsEdit)
              {
                  if(product.productGroupIds.length === 0){
                      handleFailureMessage(t("Selectyourarticlegroup"));
                      return;
                  }
                  showSpinner();
                  const productData = handleCreateProduct(product,files);
                  const formData = handleCreateProductFormData(productData,true);
                  const response = await PUT(`/Products/EditProduct`,formData,true);
                  if(response.status === 200){
                      hideSpinner();
                      setIsAction(true);
                      setProduct((prevState)=>({...prevState,productId:response.data.productId}));
                      Refresh();
                      redirectTo(`/Shop/Product/Add?productId=${response.data.productId}`);

                  }
                  else{
                      handleApiError(response,hideSpinner);
                  }
              }
              else{
                  if(product.productGroupIds.length === 0){
                      handleFailureMessage(t("Selectyourarticlegroup"));
                      return;
                  }
                  showSpinner();
                  const productData = handleCreateProduct(product,files);
                  const formData = handleCreateProductFormData(productData,false);
                  const response = await POST(`/Products/CreateProduct`,formData,true);
                  if(response.status === 200){
                      hideSpinner();
                      setIsAction(true);
                      setProduct((prevState)=>({...prevState,productId:response.data.productId}));
                      Refresh();
                      redirectTo(`/Shop/Product/Add?productId=${response.data.productId}`);
                  }
                  else{
                      handleApiError(response,hideSpinner);
                  }
              }
        }
        catch (e) {
            hideSpinner();
        }
    }






    const handleOpenGroup=()=>{
        setIsGroupOpen(true);
    }
    const handleCloseGroup=()=>{
        setIsGroupOpen(false);
    }
    const handleCreateGroupSuccess=async(isSuccess)=>{
        try {
            if(isSuccess){
                const response = await GET('/ProductGroups/Selection',{},'IEnumerable');
                if(response.status === 200){
                    const productGroups = convertSelectDataHandler(response.data);
                    setInitialSource(prevState => ({...prevState,productGroups: productGroups}));
                    handleCloseGroup()
                }
                else{
                    handleApiError(response,hideSpinner);
                }
            }
        }catch (e) {
            hideSpinner();
        }
    }



    const handleOpenTransport=()=>{
        setIsTransportOpen(true);
    }
    const handleCloseTransport=()=>{
        setIsTransportOpen(false);
    }
    const handleCreateSuccessTransport=async(isSuccess)=>{
        try {
            if(isSuccess){
                const response = await GET('/productTransports/Selection',{},'IEnumerable');
                if(response.status === 200){
                    const transports = convertSelectDataHandler(response.data);
                    setInitialSource(prevState => ({...prevState,transportTypes: transports}));
                    handleCloseTransport()
                }
                else{
                    handleApiError(response,hideSpinner);
                }
            }
        }catch (e) {
            hideSpinner();
        }
    }



    const handleShowMetaDataChangeAlert =()=>{
        const {isMetaDataGroupChanged} = product;
        if(isMetaDataGroupChanged === false && isMetaDataDisable){
            const TailWindButton = Swal.mixin({
                customClass: {
                    confirmButton: AlertSetting.customClass.confirmButton,
                    cancelButton: AlertSetting.customClass.cancelButton,
                },
                buttonsStyling: false,
            });
            TailWindButton.fire({
                title: t("Areyousureyouwanttomakethechange"),
                text: t("Thereisnopossibilitytoreverttopreviousdataandthenewinformationwillreplacetheolddata"),
                icon: AlertSetting.TailWindButton.icon,
                showCancelButton: true,
                confirmButtonText: t("Yeschangeit"),
                cancelButtonText: t("Nodontchangeit"),
                reverseButtons: AlertSetting.TailWindButton.reverseButtons,
            }).then((result) => {
                if (result.isConfirmed) {
                    setMetaDataDisable(false);
                    setProduct({ ...product,isMetaDataGroupChanged:true });
                    return;
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    setMetaDataDisable(true);
                }
            });
        }
    }






    return(
        <>
            <div className="space-y-5 relative">

                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}

                    childrenOne={
                     <>
                         <ButtonPlus
                             onClick={()=>redirectTo("/Shop/MetaData/Add")}
                             icon="pi-star"
                             title={t("feature")}
                             tooltipTitle={t("CreatingAFeature")}
                         />
                         <ButtonPlus
                             onClick={()=>redirectTo("/Shop/Product/Group")}
                             icon="pi-objects-column"
                             title={t("grouping")}
                             tooltipTitle={t("ProductCategorization")}
                         />
                         <ButtonPlus
                             onClick={()=>redirectTo("/Shop/Product/List")}
                             icon="pi-list"
                             title={t("list")}
                             tooltipTitle={t("ListOfProducts")}
                         />

                         <ButtonPlus
                             onClick={()=>redirectTo("/InvoiceRequest")}
                             icon="pi-file-edit"
                             title={t("Bill")}
                             tooltipTitle={t("Bills")}
                         />

                         <ButtonPlus
                             onClick={()=>redirectTo("/Shop/Product/Cart")}
                             icon="pi-shopping-bag"
                             title={t("ShoppingCart")}
                             tooltipTitle={t("ShoppingCart")}
                         />

                     </>
                    }
                    >

                    </ListFilter>
                </div>
                <form onSubmit={handleSubmitProduct} method={'post'} className={"space-y-5"}>
                    <div class="grid grid-cols-12 gap-5">
                        <div class="col-span-12 lg:col-span-8 space-y-3">
                            <div class="flex flex-col sm:flex-row gap-3 items-center ">
                                <div className={"flex-1 space-y-2 w-full"}>
                                    <div className={"float-input p-float-label relative"}>
                                        <InputText name="name" value={product.name} onInput={(e)=>handleValue(e)}  type="text" className="input w-full input-float-label" />
                                        <label className={"text-xs dark:text-gray-300"}>
                                            {t("Name")}
                                            <RequiredIcon/>
                                        </label>
                                        <div className={"absolute end-2 -top-2"}>
                                            <DisplayError message={errors.name} />
                                        </div>
                                    </div>
                                </div>

                               <SlugGenerator
                                 data={product}
                                 showSpinner={showSpinner}
                                 hideSpinner={hideSpinner}
                                 setData={setProduct}
                               />

                                <div className={"flex-1 space-y-2 w-full"}>
                                    <div className="float-input p-float-label relative">
                                        <InputText type="text" name="slug" value={product.slug} onInput={(e)=>handleValue(e)}  className="input w-full input-float-label" />
                                        <label className={"text-xs dark:text-gray-300"}>
                                            {t("Address")}
                                        <RequiredIcon/>
                                        </label>
                                        <div className={"absolute end-2 -top-2"}>
                                            <DisplayError message={errors.slug} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="post ">
                                <TabMenu activeTab={tabId} handleTabClick={handleChangeTab} tabs={tabs} />
                                <div class="post__content tab-content box bg-white dark:bg-themeDarkSalt700 p-2">
                                    <div className={`tab-content__pane space-y-4  ${ tabId === "specification" ? "active" : "" }`}  id="specification">
                                        <SpecificationTab
                                           handleValue={handleValue}
                                           handleChangeContent={handleChangeContent}
                                           product={product}
                                        />
                                    </div>
                                    <div className={`tab-content__pane space-y-4 ${ tabId === "metaTag" ? "active" : "" }`}  id="metaTag">
                                       <MetaTagTab
                                           handleValue={handleValue}
                                           product={product}
                                           handleSelectValue={handleSelectValue}
                                           relatedProducts={initialSource.relatedProducts}
                                           handleKeywordsChange={handleKeywordsChange}
                                       />

                                        <div className={"post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2"}>
                                            <label className={"dark:text-gray-300 text-xs"}>{t("Keywords")}</label>
                                            <TagsInput  name={"seoKeyWords"} onChange={handleKeywordsChange} value={product.seoKeyWords} />
                                        </div>

                                    </div>
                                    <div className={`tab-content__pane space-y-5 ${ tabId === "settings" ? "active" : "" }`}  id="settings">
                                        <SettingsTab
                                          handleSetting={handleSetting}
                                          handleValue={handleValue}
                                          handleNumberValue={handleNumberValue}
                                          product={product}
                                          handleReleaseDateTime={handleReleaseDateTime}
                                          handleDiscountFrom={handleDiscountFromDate}
                                          handleDiscountTo={handleDiscountToDate}
                                          discountOrCommissionTypes={initialSource.discountOrCommissionTypes}
                                          handleSelectValue={handleSelectValue}
                                          handleWholeSaleDiscountToDate={handleWholeSaleDiscountToDate}
                                          handleWholeSaleDiscountFromDate={handleWholeSaleDiscountFromDate}
                                          handleGetCurrencyType={handleGetCurrencyType}
                                          currencies={initialSource.currencies}

                                        />
                                    </div>
                                    <div className={`tab-content__pane space-y-2 ${ tabId === "attach" ? "active" : "" }`}  id="attach">
                                        <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                            <h2 className={"dark:text-gray-300 text-xs"}>
                                                {t("AttachmentFile")}
                                            </h2>
                                        </div>
                                        {

                                            tabId === 'attach'?
                                                <ProductAttach
                                                    data={files}
                                                    IsEdit={IsEdit}
                                                    showSpinner={showSpinner}
                                                    hideSpinner={hideSpinner}
                                                    triggerFileInput={triggerFileInput}
                                                    setCards={setFiles}
                                                    cards={files}
                                                    deleteCard={deleteCard}
                                                    addNewCard={addNewCard}
                                                    handleFieldChange={handleFieldChange}
                                                    handleFileChange={handleFileChange}
                                                    initialSource={initialSource}
                                                    totalSize={totalSize}
                                                />
                                                :null
                                        }
                                    </div>
                                    <div className={`tab-content__pane space-y-5 ${ tabId === "transport" ? "active" : "" }`}  id="transport">
                                        <TransportTab
                                          handleNumberValue={handleNumberValue}
                                          product={product}
                                          handleSelectValue={handleSelectValue}
                                          transportTypes={initialSource.transportTypes}
                                          handleGetCurrencyType={handleGetCurrencyType}
                                          currencies={initialSource.currencies}
                                          handleOpenTransport={handleOpenTransport}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-4">
                            <div className="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                                <div className="space-y-3">
                                    <div className="w-full h-48">
                                        <UploadImage isEdit={IsEdit} hpBgPosition={"hpcontain"} removeFile={handleRemoveImagePath} removeName={'imagePath'} accept={'image/*'} resetImages={handleResetImages} OnValueSelection={handleChangeImage} value={product.image ? product.image:null} name={'image'} />
                                    </div>
                                </div>
                                <div class="grid grid-cols-2 gap-3 ">
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("UnitPrice")}</label>
                                        <InputNumber type={"text"} min={0} useGrouping={true} onChange={(e)=>handleNumberValue(e.value,'price')} suffix={handleGetCurrencyType(product.currency,initialSource.currencies)} value={product.price}/>
                                    </div>
                                    <div className=" space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("WholesalePrice")}</label>
                                        <InputNumber type={"text"} min={0} useGrouping={true} onChange={(e)=>handleNumberValue(e.value,'wholeSalePrice')}  suffix={handleGetCurrencyType(product.currency,initialSource.currencies)} value={product.wholeSalePrice}/>
                                    </div>
                                    <div className="space-y-1 ">
                                        {
                                            !IsEdit?
                                                <div className="flex items-center gap-2">
                                                    <label className="text-xs dark:text-gray-300">{t("ProductCode")}</label>
                                                    <div>
                                                        <ToggleSwitch tag={'auto'} customClass={"w-32"} value={product.applyAuto} handleToggleSwitchValue={handleApplyIsAuto} name={'applyAuto'} checked={t("automatic")} unchecked={t("manual")} />
                                                    </div>
                                                </div>
                                                :null
                                        }
                                        <div>
                                            <InputText type={"text"} name={'productCode'} value={product.productCode} className={"input w-full "} onInput={(e)=>handleValue(e)} disabled={IsEdit?true: product.applyAuto}/>
                                        </div>
                                    </div>
                                    <div className=" space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("امتیاز خرید")}</label>
                                        <InputText type={"text"} className={"input w-full "}   />
                                    </div>
                                    <div className=" space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("IranCode")}</label>
                                        <InputText type={"text"} name={'iranCode'} value={product.iranCode} className={"input w-full "} onInput={(e)=>handleValue(e)}  />
                                    </div>
                                    <div className=" space-y-2 ">
                                        <div className={"flex justify-between gap-2 items-center "}>
                                            <label className="text-xs dark:text-gray-300">{t("ProductBarcode")}</label>
                                            <button type={"button"}>
                                                <HPrimeIcon
                                                    icon={"pi-barcode"}
                                                    iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                                                />
                                            </button>
                                        </div>
                                        <InputText type={"text"} name={'barCode'} value={product.barCode} className={"input w-full "} onInput={(e)=>handleValue(e)}  />
                                    </div>
                                    <div className=" space-y-2 col-span-2">
                                        <div className={"flex gap-2 items-center justify-between"}>
                                            <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                                            <button type="button" onClick={() =>handleOpenGroup()}  className="text-themeInformation text-xs dark:text-themeDarkInformation">({t("GroupCreation")})</button>
                                        </div>
                                        <Select
                                            options={initialSource.productGroups}
                                            isMulti={true}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'productGroupIds',true)}
                                            name={'contentGroupIds'}
                                            placeholder={t("selection")}
                                            value={product.productGroupIds}
                                            className={"select-group"}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={'fixed'}
                                        />
                                    </div>
                                    <div className="space-y-2">
                                        <div className={"flex items-center gap-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("FeatureTable")} </label>
                                            {/*<button type="button" className="text-themeInformation text-xs  dark:text-themeDarkInformation">({t("CreatingAFeature")})</button>*/}
                                        </div>
                                        <div onClick={()=>handleShowMetaDataChangeAlert()}>
                                            <Select
                                                options={initialSource.metaData}
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(data)=>handleSelectValue(data,'metaDataGroupIds',true)}
                                                name={'type'}
                                                placeholder={t("selection")}
                                                value={product.metaDataGroupIds}
                                                className={"select-group"}
                                                isDisabled={isMetaDataDisable}
                                                classNamePrefix={"h_multi_select"}
                                                onBlur={()=>handleShowMetaDataChangeAlert()}
                                                menuPosition={'fixed'}

                                            />
                                        </div>

                                    </div>
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">
                                            {t("type")}
                                        <RequiredIcon/>
                                        </label>
                                        <Select
                                            options={initialSource.productTypes}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'type')}
                                            name={'type'}
                                            placeholder={t("selection")}
                                            value={product.type}
                                            className={"select-group"}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={'fixed'}

                                        />
                                    </div>
                                    <div className=" space-y-1 col-span-2">
                                        <label className="text-xs dark:text-gray-300">
                                            {t("MaximumWordDisplay")}
                                        </label>
                                        <InputNumber name={'maxContent'} disabled={product.type !== null && product.type.value === 0} onChange={(e)=>handleNumberValue(e.value,'maxContent')} value={product.maxContent} placeholder={t("ItwillBeDisplayedIfTheDisplayTypeIsSelected")} className={"w-full"}  />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("Supplier")}</label>
                                        <Select
                                            options={initialSource.users}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'supplierId')}
                                            name={'type'}
                                            placeholder={t("selection")}
                                            value={product.supplierId}
                                            className={"select-group"}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={'fixed'}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("Manufacturer")}</label>
                                        <Select
                                            options={initialSource.users}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'producerId')}
                                            name={'type'}
                                            placeholder={t("selection")}
                                            value={product.producerId}
                                            className={"select-group"}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={'fixed'}

                                        />
                                    </div>
                                    <div className="space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("template")}</label>
                                        <Select
                                            options={initialSource.templates}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'templatePage')}
                                            name={'type'}
                                            placeholder={t("selection")}
                                            value={product.templatePage}
                                            className={"select-group"}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={'fixed'}
                                        />
                                    </div>
                                    <div className="space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("Survey")}</label>
                                        <Select
                                            options={initialSource.surveyGroups}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'surveyGroupId')}
                                            name={'surveyGroupId'}
                                            placeholder={t("selection")}
                                            value={product.surveyGroupId}
                                            className={"select-group"}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={'fixed'}
                                        />
                                    </div>
                                    <div class="post-input col-span-2 grid grid-cols-3 gap-2 post-input-design p-2 dark:bg-themeDarkPostInput">
                                        <div className="space-y-1 col-span-3">
                                            <label className="text-xs dark:text-gray-300">{t("ReleaseFor")}</label>
                                            <Select
                                                options={initialSource.filters}
                                                isMulti={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(data)=>handleSelectValue(data,'filterId')}
                                                name={'filterId'}
                                                placeholder={t("selection")}
                                                value={product.filterId}
                                                className={"select-group"}
                                                classNamePrefix={"h_multi_select"}
                                                menuPosition={'fixed'}

                                            />
                                        </div>
                                        <div className="space-y-1 col-span-3">
                                            <label className="text-xs dark:text-gray-300">{t("SocialMediaSharing")}</label>
                                            <Select
                                                options={initialSource.sendingStates}
                                                isMulti={true}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(data)=>handleSelectValue(data,'sendingStates')}
                                                name={'sendingState'}
                                                placeholder={t("selection")}
                                                value={product.sendingStates}
                                                className={"select-group"}
                                                classNamePrefix={"h_multi_select"}
                                                menuPosition={'fixed'}

                                            />
                                        </div>
                                        <div className=" space-y-1">
                                            <InputSwitch value={product.stateShow} OnValueSelection={handleSelectValue} lable={t("DisplayStatus")} name={'stateShow'}/>
                                        </div>
                                        <div className=" space-y-1">
                                            <InputSwitch value={product.stateAndroid} OnValueSelection={handleSelectValue} lable={t("SendToAndroid")} name={'stateAndroid'}/>
                                        </div>
                                        <div className=" space-y-1">
                                            <InputSwitch value={product.stateGoogle} OnValueSelection={handleSelectValue} lable={t("GoogleMap")} name={'stateGoogle'}/>
                                        </div>
                                        <div className=" space-y-1">
                                            <InputSwitch value={product.stateFreePost} OnValueSelection={handleSelectValue} lable={t("FreeShipping")} name={'stateFreePost'}/>
                                        </div>
                                        <div className=" space-y-1 col-span-2">
                                            <InputSwitch value={product.isInventory} OnValueSelection={handleSelectValue} lable={t("SalesBasedOnInventory")} name={'isInventory'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ButtonSubmitAndCancel
                        onCancelClick={()=>redirectTo('/Shop/Product/List')}

                    />
                </form>

            </div>

            <FantasyModal openModal={IsGroupOpen} closeModal={handleCloseGroup} title={t("GroupCreation")}>

                <ProductGroup
                    IsOpen={IsGroupOpen}
                    handleSuccess={handleCreateGroupSuccess}
                />
            </FantasyModal>


            <FantasyModal openModal={IsTransportOpen} closeModal={handleCloseTransport} title={t("SendingMethod")}>

                <ProductTransport
                    IsOpen={IsTransportOpen}
                    handleSuccess={handleCreateSuccessTransport}
                    handleGetCurrencyType={handleGetCurrencyType}
                    currencies={initialSource.currencies}
                    websiteCurrency={product.currency}
                />
            </FantasyModal>



            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>


            <FantasyModal openModal={IsAction} closeModal={handleCloseAction} >
                <div className=" grid grid-cols-3 md:grid-cols-4 gap-3">
                    <ModalButtonOperations
                        title={t("ListOfProducts")}
                        icon={"pi-list"}
                        onClick={() => redirectTo(`/Shop/Product/List`)}
                    />
                    <ModalButtonOperations
                        title={t("NewProduct")}
                        icon={"pi-plus-circle"}
                        onClick={() =>window.location.reload()}
                    />
                    <ModalButtonOperations
                        title={t("SizeAndDimensions")}
                        icon={'pi-window-minimize'}
                        onClick={()=>redirectTo(`/Shop/Product/Type?productId=${product.productId}`)}
                    />
                    <ModalButtonOperations
                        title={t("feature")}
                        icon={'pi-star'}
                        onClick={()=>redirectTo(`/Shop/Product/List/ProductMeta?productId=${product.productId}`)}
                    />

                </div>
            </FantasyModal>

            <ToastContainer />

        </>
    )
}