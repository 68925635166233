import React, {useState} from "react";

export const useCustomPackages=(setErrors,setDisable)=>{

    const [Package,setPackage] = useState({Id:null,Name:null,Description:null,ThumbnailImage:null,BannerImage:null,ThumbnailImagePath:null,BannerImagePath:null,PackageGroupId:null,StateShow:false})

    const resetImages = () => {
        setPackage((prevState)=>({...prevState,BannerImage: null, ThumbnailImage: null,ThumbnailImagePath: null,BannerImagePath: null}));
    }

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setPackage({...Package,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleNumberValue = (value,name)=>{
        if(name === "Price"){
            if(value === 0 ){
                setDisable(true);
                setPackage({...Package,[name]:value});
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: undefined,
                }));
            }
            else{
                setDisable(false);
                setPackage({...Package,[name]:value});
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: undefined,
                }));
            }
        }
        else{
            setPackage({...Package,[name]:value});
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }

    }

    const handleSelectValue=(data,name)=>{
        setPackage({ ...Package, [name]: data });
    }

    const handleDiscountFrom = (dateTime)=>{
        setPackage({...Package,DiscountFrom:dateTime})
    }
    const handleDiscountTo = (dateTime)=>{
        setPackage({...Package,DiscountTo:dateTime})
    }

    const removeFile=(path,name)=>setPackage((prevState)=>({...prevState,[name]:path}))
    const handleSelectFile =(data,name)=>{
        setPackage((prevContent) => ({ ...prevContent, [name]: data }));
    }


    return {
        handleSelectFile,
        handleValue,
        handleNumberValue,
        handleDiscountFrom,
        handleDiscountTo,
        removeFile,
        resetImages,
        handleSelectValue,
        Package,
        setPackage
    }
}