import React, {useEffect, useMemo, useState} from "react";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import Select from "react-select";
import { useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {useForm} from "./useForm";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {handleAddForm, handleEditForm} from "./formService";
import {ToastContainer} from "react-toastify";
import {GridTable} from "../../Commans/Grid/GridTable";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {useFormGrid} from "./useFormGrid";
import {formSchema} from "./formSchema";
import {validate} from "../../../Services/Globals/validate";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {FileManager} from "../../Public/FtpManager/FileManager";
import {useRequirement} from "./useRequirement";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
export const HandleCreateForm = () => {
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [errors,setErrors]=useState({});
    const [IsOpen, setIsOpen] = useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [IsShowLoading,setIsShowLoading]=useState(false);
    const [isLoading,setIsLoading]=useState(false);
    const [initialSource,setInitialSource]=useState({templates:[],dataAccess:[],banks:[],roadMaps:[]});
    const [refresh,setRefresh]=useState(false);
    const [IsEdit,setIsEdit]=useState(false);
    const [IsOpenMenu,setIsOpenMenu]=useState(false);
    const [IsFtpOpen,setFtpOpen]=useState(false);
    const {handleRequirement,require,setRequire}=useRequirement();

    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const onSetErrors=(erros)=>{
        setErrors(erros)
    }
    const handleResetErros=()=>{
        setErrors({});
    }
    const showLoading=()=>{
        setIsLoading(true)
    }
    const hideLoading=()=>{
        setIsLoading(false);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleOpenFtp=()=>{
        setFtpOpen(true)
    }
    const handleCloseFtp=()=>{
        setFtpOpen(false);
    }

    const handleOpenForm=()=>{
        setForm((prevState)=>({...prevState,access: initialSource.dataAccess[0]??null,templateId: initialSource.templates[0]??null,roadMapId: initialSource.roadMaps[0]??null,startDate: new Date(),eBankIds: [],rootFolder: '/Form'}))
        setIsOpen(true)
    }
    const handleCloseForm=()=>{
        setForm((prevState)=>({...prevState,access: initialSource.dataAccess[0]??null,templateId: initialSource.templates[0]??null,roadMapId: initialSource.roadMaps[0]??null,startDate:null,description: '',eBankIds: [],expirationDate:null,slug: '',msgSuccess: '',msgError: '',name: '',rootFolder: '/Form'}))
        handleResetErros();
        setIsEdit(false);
        setIsOpen(false);
        handleRequirement(true,'isRootFolderValid')
    }

    const handleDataInitialSource=(templates,dataAccess,banks,roadMaps)=>{
        const templatesData = convertSelectDataHandler(templates);
        const accessData = convertSelectDataHandler(dataAccess);
        const banksData = convertSelectDataHandler(banks);
        const roadMapsData = convertSelectDataHandler(roadMaps);
        setInitialSource({
            templates: templatesData,
            dataAccess: accessData,
            banks: banksData,
            roadMaps: roadMapsData
        })
        return {
            templatesData,
            accessData,
            banksData,
            roadMapsData
        }
    }
    const fetchData = async () => {
        try {
            showSpinner();
            const responseCurrency = await GET(`/Websites/Currency`, {}, 'IEnumerable');
            if(responseCurrency.status !== 200){
                handleApiError(responseCurrency,hideSpinner);
                return;
            }
            const [responseTemplate, responseDataAccess, responseBanks,responseRoadMap] = await Promise.all([
                GET(`/TemplatePages/selection?Type=${19}`, {}, 'IEnumerable'),
                GET('/Enumerations/EnAccess', {}, 'IEnumerable'),
                GET(`/Payments/PspAccount/Selection?currencyType=${responseCurrency.data.currencyType}`, {}, 'IEnumerable'),
                GET(`/Forms/RoadMaps/Selection`, {}, 'IEnumerable'),
            ]);
            if (responseTemplate.status === 200 && responseDataAccess.status === 200 && responseBanks.status === 200 && responseRoadMap.status === 200) {
                handleDataInitialSource(responseTemplate.data,responseDataAccess.data,responseBanks.data,responseRoadMap.data);
            }
            else {
               handleApiError(responseTemplate,hideSpinner);
               handleApiError(responseDataAccess,hideSpinner);
               handleApiError(responseBanks,hideSpinner);
            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };


    useEffect(()=>{
        fetchData();
    },[])

    const handleSubmitForm=async(e)=>{
        e.preventDefault();
        const isValid = validate(formSchema,form,onSetErrors)
        if(!isValid){
            handleRequirement(false,'isRootFolderValid')
            return;
        }
        try {
           if(IsEdit){
               if(form.rootFolder === null || form.rootFolder === ''){
                   handleFailureMessage(t("FolderPathRequired"));
                   handleRequirement(false,'isRootFolderValid')
                   return;
               }
               showLoading();
               const formsData = handleEditForm(form);
               const response = await PUT(`/Forms`,formsData,false);
               if(response.status === 200){
                   hideLoading();
                   handleSuccessMessage(t('SuccessDone'));
                   Refresh();
                   handleCloseForm();
               }
               else{
                   handleApiError(response,hideLoading);
               }
           }
           else
           {
               if(form.rootFolder === null || form.rootFolder === ''){
                   handleFailureMessage(t("FolderPathRequired"));
                   handleRequirement(false,'isRootFolderValid')
                   return;
               }
               showLoading();
               const formsData = handleAddForm(form);
               const response = await POST(`/Forms`,formsData,false);
               if(response.status === 200){
                   hideLoading();
                   handleSuccessMessage(t('SuccessDone'));
                   Refresh();
                   handleCloseForm();
               }
               else{
                   handleApiError(response,hideLoading);
               }
           }
        }catch (e) {
            hideLoading();
        }
    }




    const handleOpenMenu=(formId)=>{
        setForm({...form,formId: formId});
        setIsOpenMenu(true)
    }
    const handleCloseMenu=()=>{
        setIsOpenMenu(false);
    }



    const {handleStartDate,handleValue,form, handleNumberValue,handleExpireDate, setForm,handleSelectValue,handleGetFolderSource}=useForm(setErrors,setRequire,setFtpOpen,IsEdit,setIsShowLoading,handleCloseForm);



const {handleDeleteForm,handleGetForm,handleNextPage,handleCurrentPage,handleSearch,handlePreviousPage,handlePageSizeCount,handleResetFilters,filter,setFilter,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination}=useFormGrid(showSpinner,hideSpinner,setIsOpen,setIsEdit,refresh,Refresh,setForm,form,initialSource,handleOpenMenu);







    return(
        <>

            <div class="space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                                <InputText className="input w-full"/>
                            </div>
                        <ButtonSearchList

                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>handleOpenForm()}
                                            tooltipTitle={t("CreatingForm")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Form/RoadMap`)}
                                            tooltipTitle={t("FormWorkflow")}
                                            title={t("Workflow")}
                                            icon={"pi-sync"}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Form/Organize`)}
                                            tooltipTitle={t("OrganizeForms")}
                                            title={t("Organize")}
                                            icon={"pi-sort-alpha-down"}
                                        />
                                    </>
                                }

                    />

                </div>
                <div className="H-body-ag-grid space-y-2">
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
            </div>


            <FantasyModal openModal={IsOpen}  closeModal={handleCloseForm} title={t("CreatingForm")}>
                <form onSubmit={handleSubmitForm} method={'post'} className={'relative'}>
                    {
                        IsShowLoading?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className={"space-y-5"}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                            <div className="space-y-1">
                                <div className={"flex justify-between items-center gap-2"}>
                                    <label  className="dark:text-gray-300 text-xs">
                                        {t("FormTitle")}
                                        <RequiredIcon />
                                    </label>
                                    <DisplayError message={errors.name}/>
                                </div>
                                <InputText name={'name'} value={form.name} onInput={(e)=>handleValue(e)} type={"text"} className={"input  w-full"} />
                            </div>
                            <div className="space-y-1">
                                <div className={"flex justify-between items-center gap-2"}>
                                    <label  className="dark:text-gray-300 text-xs">
                                        {t("PageAddress")}
                                        <RequiredIcon />
                                    </label>
                                    <DisplayError message={errors.slug}/>
                                </div>

                                <InputText name={'slug'} value={form.slug} onInput={(e)=>handleValue(e)} type={"text"} className={"input  w-full"} />
                            </div>
                            <div className="space-y-1">
                                <label className="dark:text-gray-300 text-xs">
                                    {t("FormType")}
                                </label>
                                <Select
                                    options={initialSource.dataAccess}
                                    isMulti={false}
                                    isClearable={false}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'access')}
                                    name={'type'}
                                    placeholder={t("selection")}
                                    value={form.access}
                                    className={"select-group"}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={'fixed'}

                                />
                            </div>
                            <div className="space-y-1">
                                <label className="dark:text-gray-300 text-xs">
                                    {t("template")}
                                </label>
                                <Select
                                    options={initialSource.templates}
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'templateId')}
                                    name={'type'}
                                    placeholder={t("selection")}
                                    value={form.templateId}
                                    className={"select-group"}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={'fixed'}

                                />
                            </div>
                            <div className="space-y-1">
                                <div className={"flex justify-between items-center gap-2"}>
                                    <label  className="dark:text-gray-300 text-xs">
                                        {t("SuccessfulRegistrationMessage")}
                                        <RequiredIcon />
                                    </label>
                                    <DisplayError message={errors.msgSuccess}/>
                                </div>
                                <InputText name={'msgSuccess'} onInput={(e)=>handleValue(e)} value={form.msgSuccess}  type={"text"} className={"input  w-full"} />
                            </div>
                            <div className="space-y-1 ">
                                <div className={"flex gap-2 justify-between items-center"}>
                                    <label  className="dark:text-gray-300 text-xs">
                                        {t("RegistrationFailedMessage")}
                                        <RequiredIcon />
                                    </label>
                                    <DisplayError message={errors.msgError}/>
                                </div>
                                <InputText name={'msgError'} onInput={(e)=>handleValue(e)} value={form.msgError} type={"text"} className={"input  w-full"} />
                            </div>
                            <div className="space-y-2">
                                <div className={"flex justify-between items-center gsp-2 "}>
                                    <label  className="dark:text-gray-300 text-xs">
                                        {t("FolderAddress")}
                                        <RequiredIcon />
                                    </label>

                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <button type={"button"} className={"button small_button bg-themeInformation flex-shrink-0"} onClick={()=>handleOpenFtp()}>
                                        <HPrimeIconSmall
                                            icon={"pi-folder-open"}
                                            iconClass={'text-white'}
                                        />
                                    </button>

                                    <InputText name={'rootFolder'}  onInput={(e)=>handleValue(e)} value={form.rootFolder}  type={"text"} className={`input H-direction-ltr flex-1 w-full ${require.isRootFolderValid === false ?'hValue-border-danger':''}`} />
                                </div>
                            </div>
                            <div className="space-y-1">
                                <label  className="dark:text-gray-300 text-xs">
                                    {t("BankSelection")}
                                </label>
                                <Select
                                    options={initialSource.banks}
                                    isMulti={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'eBankIds')}
                                    name={'type'}
                                    placeholder={t("selection")}
                                    value={form.eBankIds}
                                    className={"select-group"}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={'fixed'}
                                />
                            </div>
                            <div className="space-y-1">
                                <label className="dark:text-gray-300 text-xs">
                                    {t("FormCirculationPath")}
                                </label>
                                <Select
                                    options={initialSource.roadMaps}
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'roadMapId')}
                                    name={'type'}
                                    placeholder={t("selection")}
                                    value={form.roadMapId}
                                    className={"select-group"}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={'fixed'}

                                />
                            </div>
                            <div className={"flex gap-2 items-center"}>
                                <div class="space-y-2 flex-1 w-full">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("StartDate")}
                                    </label>
                                    <DateTimePicker isContainsTime={true}  handleDateTime={handleStartDate} format={'YYYY/MM/DD'} value={form.startDate}/>
                                </div>
                                <div class="space-y-2 flex-1 w-full">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("EndDate")}
                                    </label>
                                    <DateTimePicker isContainsTime={true}  handleDateTime={handleExpireDate} format={'YYYY/MM/DD'} value={form.expirationDate}/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation place-content-center text-white relative"} >
                                {t("insertion")}
                                {isLoading ? (
                                    <TinyLoading
                                    />
                                ) : (
                                    null
                                )}
                            </button>
                        </div>
                    </div>

                </form>
            </FantasyModal>

            <FantasyModal openModal={IsOpenMenu} closeModal={handleCloseMenu} >
                <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
                    <ModalButtonOperations
                        onClick={()=>redirectTo(`/Form/List/DataEntrance/${form.formId}`)}
                        title={t("DataEntry")}
                        icon={"pi-cloud-download"}
                    />
                    <ModalButtonOperations
                    onClick={()=>redirectTo(`/Form/List/Information/${form.formId}`)}
                    title={t("information")}
                    icon={"pi-database"}
                    />

                </div>
            </FantasyModal>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>

            <FantasyModal openModal={IsFtpOpen} closeModal={handleCloseFtp} customeClassName={'modal-xl'} class title={t("FileManagement")}>
                <div className={"relative"}>
                    {
                        IsFtpOpen?
                            <FileManager handleGetFolderSource={handleGetFolderSource} inUse={true} handleCloseFtp={handleCloseFtp}/>:null
                    }
                </div>

            </FantasyModal>

            <ToastContainer/>
        </>
    )
};
