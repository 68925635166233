import React, {useState} from "react";
// import '../../../../Styles/Internal/setting.css'



import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


const SettingLink = ({ to, title }) => (
    <Link to={to} className="-intro-x dark:text-gray-300 flex items-center gap-2 setting-hover p-1">
        <span className="w-3 h-3 block rounded-full border bg-themeDarkInformation dark:border-themeDarkColorLine"></span>
        <h2>{title}</h2>
    </Link>
);

const SettingSection = ({ title, links }) => (
    <div className="space-y-1">
        <div>
            <h2 className="text-[1rem] text-themeInformation dark:text-themeDarkInformation">{title}</h2>
        </div>
        <div className="space-y-1">
            {links.map((link, index) => (
                <SettingLink key={index} {...link} />
            ))}
        </div>
    </div>
);


export const HandleMasterSetting = () => {
    const { t } = useTranslation();


    const mainSettings = [
        { to: '/Harmony/Setting/Main/Dashboard', title: t("PersonalSettings") },
        { to: '/Harmony/Setting/Main/Business', title: t("Business") },
        { to: '/Harmony/Setting/Main/General', title: t("Website") },
        { to: '/Harmony/Setting/Main/Pictures', title: t("Images") },
        { to: '/Harmony/Setting/Main/Routing', title: t("Routing") },
        { to: '/Harmony/Setting/Main/CleanTemp', title: t("TemporaryDataCleanup") },
    ];

    const Professional = [
        { to: '/Harmony/Setting/Main/Domain', title:t("Domain") },
        { to: '/Harmony/Setting/Main/Script', title: t("AdditionalScript") },
        { to: '/Harmony/Setting/Main/ShortcutKey', title: t("ShortcutKey") },
        { to: '/Harmony/Setting/Main/Security', title: t("SecurityAndPrivacy") },
        { to: '/Harmony/Setting/Main/BackUp', title: t("SupportAndRecovery") },
        { to: '/Harmony/Setting/Filter/Builder', title: t("Filter") },
    ];
    const shop = [
        { to: '/Harmony/Setting/Shop/General', title: t("GeneralSettings") },
        { to: '/Harmony/Setting/Shop/ApiPost', title: t("ShippingMethods") },
        { to: '/Harmony/Setting/Shop/PaymentPort', title: t("BankGateway") },
    ];



    const CustomerManagement = [
        // { to: '/Harmony/Setting/CRM/General', title: t("BulkTransfer") },
        { to: '/Harmony/Setting/Main/SmartNotification', title: t("SmartNotification") },

    ];

    // const Attendance = [
    //
    //     { to: '/Harmony/Setting/Attendance', title: t("Basicsettings") },
    //
    // ];


  return(
     <div className="box bg-white dark:bg-themeDarkSalt700 p-2 space-y-2">
         <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation flex items-center gap-2">
             <HPrimeIcon icon={"pi-cog"} iconClass={"dark:text-gray-300"}/>
             <h2 className={"text-xs dark:text-gray-300"}>{t("settings")}</h2>
         </div>
         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 px-3">
             <div className="space-y-3">
                 <SettingSection title={t("Public")} links={mainSettings} />
             </div>
             <div className="space-y-3">
                 <SettingSection title={t("Professional")} links={Professional} />
             </div>
             <div className="space-y-3">
                 <SettingSection title={t("shop")} links={shop} />
             </div>
             <div className="space-y-2">
                 <SettingSection title={t("CustomerManagement")} links={CustomerManagement} />
             </div>


         </div>
     </div>
  )
}