import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {InputTextarea} from "primereact/inputtextarea";
import {Dialog} from "primereact/dialog";
import {usePattern} from "./usePattern";
import {usePatternGrid} from "./usePatternGrid";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {Export} from "../../../Commans/Grid/Export";
import Select from "react-select";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleConvertAddPattern} from "./PatternService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {validate} from "../../../../Services/Globals/validate";
import {patternSchema} from "./patternSchema";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {ToastContainer} from "react-toastify";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const HandleSmsPattern=()=>{

  const redirectTo = useNavigate();
  const { t } = useTranslation();
  const [IsEdit,setIsEdit] = useState(false);
  const [IsShowSpinner,setIsShowSpinner] = useState(false);
  const [IsOpen,setIsOpen] = useState(false);
  const [refresh,setRefresh] = useState(false);
  const [errors,setErrors] = useState({});
  const [initialSource,setInitialSource] = useState({accounts:[]})
  const {handleValue,pattern,handleSelectValue,setPattern} = usePattern(setErrors);
  const [AccountId,setAccountId] = useState(null);
  const [IsOpenParameters, setOpenParameters] = useState(false);

  const openParameters=()=>{
    setOpenParameters(true)
  }

  const closeParameters=()=>{
    setOpenParameters(false)
  }


  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }
  const showSpinner=()=>{
    setIsShowSpinner(true);
  }
  // handles errors
  const onSetErrors=(errors)=>{
    setErrors(errors);
  }
  //refreshes component when it is called
  const Refresh=()=>{
    setRefresh(prevState => !prevState);
  }
  // resets all errors to nothing as initial state
  const handleClearErrors=()=>{
    setErrors({});
  }
  const handleOpenPattern=()=>{
    setIsOpen(true);
    setPattern({accountId: initialSource.accounts[0]});
  }
  const handleClosePattern=()=>{
    setIsOpen(false);
    setIsEdit(false);
    handleClearErrors();
    setPattern({accountId: initialSource.accounts[0], body: '',title: ''});
  }

  const handleInitialData=(accounts)=>{
    const accountData = convertSelectDataHandler(accounts);

    return {accountData}
  }




  const fetchData=async ()=>{
    try {

      const responseAccount = await GET(`/Messaging/Accounts/ActiveAccounts`,{},'IEnumerable');
      if(responseAccount.status === 200){
        const {accountData}=  handleInitialData(responseAccount.data);
        setInitialSource({
          accounts: accountData
        })
        setAccountId(accountData[0].value);
      }
    }catch (e) {
      hideSpinner();
    }
  }
  useEffect(()=>{
    fetchData()
  },[])



  const handleSubmitPattern=async(e)=>{
    e.preventDefault();
    const isValid = validate(patternSchema,pattern,onSetErrors);
    if(!isValid){
      return;
    }
    try {
       showSpinner();
       const patternData = handleConvertAddPattern(pattern)
       const response = await POST(`/Messaging/Patterns`,patternData,false);
       if(response.status === 200){
            handleClosePattern();
            handleSuccessMessage(t('SuccessDone'));
            Refresh();
       }
       else{
         handleApiError(response,hideSpinner);
       }
    }
    catch (e) {
      hideSpinner();
    }
  }





  const headerElement = (
      <div className={"text-xs"}>
        <span>{t("Quickguide")}</span>
      </div>
  );



  const {autoGroupColumnDef,handleShowMessage,message,visible,setVisible,pagination,handleResetFilters,filter,columnDefs,handleCurrentPage,handlePreviousPage,handleSearch,handleNextPage,handlePageSizeCount,defaultColDef,setFilter,search,setSearch,rowData,getRowHeight,handleSelectFilter} = usePatternGrid(showSpinner,hideSpinner,refresh,Refresh,AccountId)


  return(<>

    <Dialog header={headerElement} visible={visible}  className={"Dialog-NotifText"} onHide={() => setVisible(false)} >
      {message}
    </Dialog>


    <div class=" space-y-5 relative">
      {
        IsShowSpinner?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony/>
              </div>
            </div>
            :
            null
      }
      <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
        <ListSearch label={null} icon={null} >
          <div class="space-y-2">
            <label className="text-xs dark:text-gray-300">{t("SearchByText")}</label>
            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" />
          </div>
          <div className="space-y-2">
            <label className="text-xs dark:text-gray-300">{t("senderNumber")}</label>
            <Select
                options={initialSource.accounts}
                isSearchable={true}
                onChange={(data)=>handleSelectFilter(data,'operator')}
                placeholder={t("selection")}
                value={filter.operatorValue}
                menuPosition={"fixed"}
                classNamePrefix={"h_multi_select"}
            />
          </div>
          <ButtonSearchList
              onClickSearch={()=>handleSearch()}
              onClickReset={()=>handleResetFilters()}
          />
        </ListSearch>
        <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={
                      <>
                        <ButtonPlus
                            onClick={() => handleOpenPattern()}
                            tooltipTitle={t("creatingATemplate")}
                            title={t("Pattern")}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/Send`)}
                            tooltipTitle={t("sendingMessage")}
                            title={t("sending")}
                            icon={"pi-send"}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/Inbox`)}
                            icon={"pi-inbox"}
                            title={t("Receive")}
                            tooltipTitle={t("receivingBox")}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SendBoxSms`)}
                            icon={"pi-inbox"}
                            title={t("sending")}
                            tooltipTitle={t("sendingBox")}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                            icon={"pi-bolt"}
                            title={t("Charge")}
                            tooltipTitle={t("PanelRecharge")}
                        />

                        <ButtonPlus
                            onClick={()=>redirectTo(`/Harmony/Setting/Main/SmartNotification`)}
                            icon={"pi-bell"}
                            title={t("Smart")}
                            tooltipTitle={t("SmartNotification")}
                        />


                      </>
                    }

        />

      </div>
      <div className={"space-y-2 H-body-ag-grid"} >


        <GridTable
            columnDefs={columnDefs}
            rowSelection={GridSetting.rowSelection}
            rowData={rowData}
            style={GridSetting.gridStyle}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={GridSetting.pagination}
            enableRtl={GridSetting.enableRtl}
            domLayout={GridSetting.domLayout}
            paginationPageSize={GridSetting.pageSize}
            getRowHeight={getRowHeight}
            localeText={AgGridEnglish}
        />
        <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
      </div>
      <Export filters={filter} apiUrl={''}/>
    </div>



    <FantasyModal openModal={IsOpen}  closeModal={handleClosePattern} title={t("creatingATemplate")}>
     <form onSubmit={handleSubmitPattern}>
       <div class="space-y-5">
          <div className={"grid grid-cols-1 gap-3 md:grid-cols-2"}>
            <div className="space-y-1">
              <label className="text-xs dark:text-gray-300">{t("senderNumber")}</label>
              <Select
                  options={initialSource.accounts}
                  isSearchable={true}
                  onChange={(data)=>handleSelectValue(data,'accountId')}
                  placeholder={t("selection")}
                  value={pattern.accountId}
                  classNamePrefix={"h_multi_select"}
              />
            </div>
            <div class="space-y-2">
              <div className={"flex justify-between items-center"}>
                <label className="text-xs dark:text-gray-300">
                  {t("TemplatePattern")}
                <RequiredIcon/>
                </label>
                <DisplayError message={errors.title}/>
              </div>

              <InputText type={"text"} onInput={(e)=>handleValue(e)} value={pattern.title} name={'title'} className={"input w-full"} />
            </div>
          </div>
           <div class="space-y-2">
             <div className={"flex gap-2 items-center justify-between"}>
               <div className={"flex gap-1 items-center "}>
                 <label className="text-xs dark:text-gray-300">
                   {t("TemplateText")}
                 <RequiredIcon/>
                 </label>
                 <DisplayError message={errors.body}/>
               </div>
                <div>
                  <button type={"button"} className="text-themeInformation text-xs dark:text-themeDarkInformation cursor-pointer" onClick={() => openParameters()}>({t("Parameters")})</button>
                </div>
             </div>
             <InputTextarea onInput={(e)=>handleValue(e)}  name={'body'} value={pattern.body} rows={3} cols={30} className={"input w-full"}/>
           </div>
         <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
       </div>
     </form>
    </FantasyModal>


  <FantasyModal openModal={IsOpenParameters} closeModal={closeParameters} title={t("Parameters")} customeClassName={"modal-sm"}>
    <div className={"space-y-5"}>
      <div className="grid grid-cols-2 border dark:border-themeDarkColorLine bg-white dark:bg-themeDarkSalt700 rounded-md">
        <div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-center">عنوان</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">جنسیت</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">نام و نام خانوادگی</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">تلفن</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">موبایل</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">آدرس</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">کد ملی</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">کد پرسنلی</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">نام کاربری</h2>
          </div>
          <div className="border-e border-b dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">پسورد</h2>
          </div>
          <div className="border-e dark:border-themeDarkColorLine p-2">
            <h2 className="dark:text-gray-300 text-xs">سایت</h2>
          </div>
        </div>
        <div className={"H-direction-ltr"}>
          <div className="p-2 border-b dark:border-themeDarkColorLine ">
            <h2  className="dark:text-gray-300 text-center" >کد متناظر</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[gender]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[name]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[phone]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[mobile]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[address]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[nnode]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[pcode]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[username]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[password]</h2>
          </div>
          <div className="p-2 border-b dark:border-themeDarkColorLine">
            <h2  className="dark:text-gray-300 text-xs">[site]</h2>
          </div>
        </div>
      </div>
    </div>
  </FantasyModal>
  <ToastContainer/>

  </>)
}