import React, {useState} from "react";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../Services/Globals/Public/AppSetting";
import {useNavigate} from "react-router-dom";

export const useForm=(setErrors,setRequire,setFtpOpen,IsEdit,setIsShowLoading,handleCloseForm)=>{
    const redirectTo = useNavigate();

    const  [form,setForm]=useState({
        formId:null,
        name:null,
        slug:null,
        templateId:null,
        msgSuccess:null,
        msgError:null,
        description:null,
        access:null,
        roadMapId:null,
        eBankIds:[],
        rootFolder:null,
        startDate:null,
        expirationDate:null
    });
    const handleValue=(e)=>{
        const {name,value} = e.target;
        if(name === 'rootFolder'){
            const hasDataNoData = value === null || value === '' || value === undefined
            setRequire((prevStates)=>({...prevStates,isRootFolderValid:!hasDataNoData}))
        }
        setForm({...form,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleGetFolderSource=(url)=>{
        const text = url.replace(/\s+/g, '').trim()
        setForm((prevState)=>({...prevState,rootFolder: text}));
        setFtpOpen(false)
    }

    const handleNumberValue=(value,name)=>{
        setForm({...form,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }





    async function showConfirmationDialog(formId) {
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        });

        const result = await TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        });

        if (result.isConfirmed) {
            redirectTo(`/Form/List/Information/${formId}`);
        }
    }
    async function handleRoadMapSelection(data, name) {
        try {
            setIsShowLoading(true);
            const response = await GET(`/Forms/HasDataGroup?formId=${form.formId}`, {}, 'single');
            if (response.status === 200) {
                setIsShowLoading(false);
                if (response.data.hasFormDataGroup) {
                    handleCloseForm();
                    await showConfirmationDialog(form.formId);
                } else {
                    setForm({ ...form, [name]: data });
                }
            } else {
                handleApiError(response, setIsShowLoading(false));
            }
        } catch (e) {
            setIsShowLoading(false);
        }
    }

    const handleSelectValue = async (data, name) => {
        if (!IsEdit) {
            setForm({ ...form, [name]: data });
            return;
        }
        if (name === 'roadMapId') {
            await handleRoadMapSelection(data, name);
        } else {
            setForm({ ...form, [name]: data });
        }
    };

    const handleStartDate = (dateTime)=>{
        setForm({...form,startDate: dateTime})
    }
    const handleExpireDate = (dateTime)=>{
        setForm({...form,expirationDate: dateTime})
    }

    return {
        form,
        setForm,
        handleExpireDate,
        handleNumberValue,
        handleValue,
        handleStartDate,
        handleSelectValue,
        handleGetFolderSource

    }


}