import React, {useEffect, useMemo} from "react";
import {useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDefaultDashboardWidgets} from "../../../Dashboard/DefaultDashboards/useDefaultDashboardWidgets";
import {useDashboard} from "../../../Dashboard/useDashboard";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ChartHarmony} from "../../../Dashboard/ChartHarmony";
import {UserNote} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserNote";
import {UserComment} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserComment";

export const HandleDashboardInventoryManagement=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();


    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: 'شماره سند',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: 'تاریخ سند',minWidth: 140,

            cellRenderer:parameters=>

                <>مدیریت</>

        },
        { headerName: 'فرستنده',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    صبح
                </>

        },

        {
            headerName: 'گیرنده', maxWidth: 110,
            cellRenderer: parameters =>
                <>
                    1
                </>

        },
        { headerName: 'تعداد اقلام', maxWidth: 130,
            cellRenderer:parameters =>

                <>


                </>

        },


        { headerName: t("settings"), maxWidth: 130,
            cellRenderer:parameters =>
                <div className={'flex justify-center items-center gap-2 '} >
                    <button type={"button"}><HPrimeIcon icon={'pi-eye'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} /></button>
                </div>

        },

    ];



    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };


    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };


    const rowDataTwo = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const columnDefsTwo = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: 'مدیریت',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: 'سفارش دهنده',minWidth: 140,

            cellRenderer:parameters=>

                <>مدیریت</>

        },
        { headerName: 'تعداد',maxWidth: 140,
            cellRenderer:parameters=>
                <>
                    10
                </>

        },

        { headerName: 'تاریخ درخواست', minWidth: 130,
            cellRenderer:parameters =>

                <>

                </>

        },
        {
            headerName: 'کد درخواست', minWidth: 110,
            cellRenderer: parameters =>
                <>
                    1
                </>

        },
        { headerName: t("settings"), maxWidth: 130,
            cellRenderer:parameters =>
                <div className={'flex justify-center items-center gap-3 '} >
                    <button type={"button"}><HPrimeIcon icon={'pi-eye'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} /></button>
                </div>

        },

    ];

    const columnDefsThree = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: 'شماره سند',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: 'تاریخ سند',minWidth: 140,

            cellRenderer:parameters=>

                <>مدیریت</>

        },
        { headerName: 'فرستنده',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    صبح
                </>

        },

        {
            headerName: 'گیرنده', maxWidth: 110,
            cellRenderer: parameters =>
                <>
                    1
                </>

        },
        { headerName: 'تعداد اقلام', maxWidth: 130,
            cellRenderer:parameters =>

                <>


                </>

        },


        { headerName: t("settings"), maxWidth: 130,
            cellRenderer:parameters =>
                <div className={'flex justify-center items-center gap-3 '} >
                    <button type={"button"} ><HPrimeIcon  icon={'pi-eye'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} /></button>
                </div>

        },

    ];

    const columnDefsFour = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: 'نام محصول',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: 'کد محصول',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },

        { headerName: 'سری ساخت',minWidth: 140,

            cellRenderer:parameters=>

                <>مدیریت</>

        },


        { headerName: 'تعداد فعلی', maxWidth: 130,
            cellRenderer:parameters =>

                <>


                </>

        },

        { headerName: 'حداقل موجودی', maxWidth: 130,
            cellRenderer:parameters =>

                <>


                </>

        },


        { headerName: t("settings"), maxWidth: 130,
            cellRenderer:parameters =>
                <div className={'flex justify-center items-center gap-3 '} >
                    <button type={"button"} ><HPrimeIcon  icon={'pi-eye'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} /></button>
                </div>

        },

    ];
    const columnDefsFive = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: 'نام محصول',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: 'کد محصول',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },

        { headerName: 'سری ساخت',minWidth: 140,

            cellRenderer:parameters=>

                <>مدیریت</>

        },
        { headerName: 'تاریخ تولید',minWidth: 140,
            cellRenderer:parameters=>
                <>
                    صبح
                </>

        },

        {
            headerName: 'تاریخ انقضاء', maxWidth: 110,
            cellRenderer: parameters =>
                <>
                    1
                </>

        },
        { headerName: 'تعداد اقلام', maxWidth: 130,
            cellRenderer:parameters =>

                <>


                </>

        },


        { headerName: t("settings"), maxWidth: 130,
            cellRenderer:parameters =>
                <div className={'flex justify-center items-center gap-3 '} >
                    <button type={"button"} ><HPrimeIcon  icon={'pi-eye'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} /></button>
                </div>

        },

    ];


    const monthlySalesData = {
        months: ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'],
        products: {
            'محصول 1': [120, 132, 101, 134, 90, 230, 210, 180, 150, 200, 240, 260],
            'محصول 2': [220, 182, 191, 234, 290, 330, 310, 260, 230, 280, 300, 320],
            'محصول 3': [150, 232, 201, 154, 190, 330, 410, 380, 350, 400, 420, 450],
            'محصول 4': [200, 220, 205, 214, 210, 340, 510, 680, 350, 400, 420, 450],
            'محصول 5': [250, 282, 291, 264, 290, 430, 510, 480, 450, 500, 520, 550],
            'محصول 6': [180, 192, 221, 214, 240, 380, 450, 420, 390, 410, 430, 460],
            'محصول 7': [300, 312, 301, 314, 310, 440, 510, 580, 550, 600, 620, 650],
            'محصول 8': [280, 282, 271, 284, 280, 420, 490, 580, 550, 600, 620, 650],
            'محصول 9': [210, 222, 231, 224, 220, 360, 430, 500, 470, 500, 520, 550],
            'محصول 10': [320, 332, 321, 334, 330, 460, 530, 600, 570, 600, 620, 650],
        }
    };

    const monthlySales = {

        tooltip: {
            trigger: 'axis'
        },

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: monthlySalesData.months
        },
        yAxis: {
            type: 'value'
        },
        series: Object.keys(monthlySalesData.products).map(product => ({
            name: product,
            type: 'line',
            data: monthlySalesData.products[product]
        }))
    };



    return (

        <div className={"relative min-h-screen"}>
            <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                </button>
                <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                    <div className="space-y-5">
                        <div>
                            <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    {t("GeneralReport")}
                                </h2>
                                <Link to="#" className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                    <HPrimeIcon
                                        icon={"pi-sync"}
                                    />
                                    {t("Reload")}
                                </Link>
                            </div>
                            <div className={`grid gap-x-5 gap-y-10 py-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("ExitRequest")}
                                                    </h2>
                                                </div>
                                                <div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("ReturnPurchase")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>
                                                            10
                                                        </p>
                                                    </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("ProductionRequest")}
                                                    </h2>
                                                </div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("RequestRawMaterials")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"space-y-3"}>
                            <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>آخرین ورودی ها</h2>
                                </h2>
                            <div className="H-body-ag-grid space-y-2">
                                        <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                                                   enableRtl={true}
                                                   pagination={true}
                                                   getRowHeight={getRowHeight}
                                                   onGridReady={onGridReady}
                                                   quickFilterText={quickFilterText}
                                        />
                                    </div>
                        </div>
                        <div className={"space-y-3"}>
                            <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>آخرین خروجی ها</h2>
                                </h2>
                            <div className="H-body-ag-grid space-y-2">
                                        <GridTable rowData={rowData} columnDefs={columnDefsThree} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                                                   enableRtl={true}
                                                   pagination={true}
                                                   getRowHeight={getRowHeight}
                                                   onGridReady={onGridReady}
                                                   quickFilterText={quickFilterText}
                                        />
                                    </div>
                        </div>
                        <div className={"space-y-3"}>
                            <h2 className="text-lg dark:text-gray-300 -intro-x">
                                نزدیک به انقضاء
                            </h2>
                            <div className={"H-body-ag-grid space-y-2"}>
                                <GridTable rowData={rowDataTwo} columnDefs={columnDefsFour} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                                           enableRtl={true}
                                           pagination={true}
                                           getRowHeight={getRowHeight}
                                           onGridReady={onGridReady}
                                           quickFilterText={quickFilterText}
                                />
                            </div>
                        </div>
                        <div className={"space-y-3"}>
                            <h2 className="text-lg dark:text-gray-300 -intro-x">
                                اخطار کمبود موجودی
                            </h2>
                            <div className={"H-body-ag-grid space-y-2"}>
                                <GridTable rowData={rowDataTwo} columnDefs={columnDefsFive} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                                           enableRtl={true}
                                           pagination={true}
                                           getRowHeight={getRowHeight}
                                           onGridReady={onGridReady}
                                           quickFilterText={quickFilterText}
                                />
                            </div>
                        </div>
                        <div className={"space-y-3"}>
                            <h2 className="text-lg dark:text-gray-300 -intro-x">
                                <h2>موجودی انبار</h2>
                            </h2>
                            <div>
                                <div className={"bg-white box dark:bg-themeDarkSalt700 p-2"}>
                                    <ChartHarmony options={monthlySales}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px', }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("Notes")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            {
                                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                    <UserNote
                                        key={index}
                                        fullName={item.fullName}
                                        id={item.id}
                                        message={item.message}
                                        createdAt={item.createdAt}
                                    />
                                ))
                            }
                            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("UserComments")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 dashboard-note height_21rem overflow-y-auto scrollbar-hidden "}>
                                {
                                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                        <UserComment
                                            key={index}
                                            fullName={item.fullName}
                                            id={item.id}
                                            message={item.message}
                                            createdAt={item.createdAt}
                                            state={item.state}
                                        />
                                    ))
                                }
                            </div>
                            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-between items-center gap-2">
                            <p className={"text-lg dark:text-gray-300"}> {t("ReleaseQueue")}</p>
                            <span className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                    <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                    </div>
                                    <div class="flex-1">
                                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation">{t("Draft")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}