
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const SelectionBreak = ({ itemCount,SortDescending,SortAccending,index, element, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement }) => (
    <>

        <hr className={" harmony-h-1 harmony-hr "}
            onClick={() => handleSelectTabAfterElementSelection(index)}
            readOnly={element.properties.isReadOnly}
            disabled={element.properties.isReadOnly}
        />

        <div className={"harmony_body_button"}>
            <button
                type="button"
                className="button bg-themeDanger text-white harmony_button_form"
                onClick={() => deleteElement(index)}
            >
                <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
            </button>
            {
                index===0?null:
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortAccending(index)}

                    >
                        <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                    </button>

            }

            {
                index <itemCount - 1?
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortDescending(index)}

                    >
                        <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                    </button>:
                    null
            }

        </div>


    </>
);