export const handleCreateBankAccount=(obj)=>{
    const data = {
        cardNumber:obj.cardNumber === null || obj.cardNumber === undefined?null:obj.cardNumber,
        accountNumber:obj.accountNumber === null || obj.accountNumber === undefined?null:obj.accountNumber,
        shebaNumber:obj.shebaNumber === null || obj.shebaNumber === undefined?null:obj.shebaNumber,
        bankId:obj.bankId === null || obj.bankId === undefined?null:obj.bankId.value,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        title:obj.title === null || obj.title === undefined?null:obj.title
    }
    return data;
}

export const handleEditBankAccount=(obj)=>{
    const data = {
        bankAccountId:obj.bankAccountId,
        cardNumber:obj.cardNumber === null || obj.cardNumber === undefined?null:obj.cardNumber,
        accountNumber:obj.accountNumber === null || obj.accountNumber === undefined?null:obj.accountNumber,
        shebaNumber:obj.shebaNumber === null || obj.shebaNumber === undefined?null:obj.shebaNumber,
        bankId:obj.bankId === null || obj.bankId === undefined?null:obj.bankId.value,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        title:obj.title === null || obj.title === undefined?null:obj.title
    }
    return data;
}