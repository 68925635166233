import React, {useState} from "react";


export const  HPrimeIcon =({ icon, iconClass,sizeIcon,onClick}) => {




    return(
      <>
          <i className={`pi ${icon?icon:"pi-question-circle"}  ${sizeIcon?sizeIcon:"primIcon_FontSize"}  ${iconClass}`}
          onClick={onClick}
          ></i>
  </>)
}