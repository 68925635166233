
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useLocation, useNavigate} from "react-router-dom";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import queryString from "query-string";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {useTranslation} from "react-i18next";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {convertCommunicationHandler} from "../../../CMS/Advertisement/Add/AdvertiseService";
import {convertCommunicationDataHandler} from "./communicationService";
import {Tooltip} from "react-tooltip";
import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {InputNumber} from "primereact/inputnumber";
import {ToggleSwitch} from "../../../Commans/UIParts/ToggleSwitch";
import {useCustomCommunication} from "./useCustomCommunication";
import {validate} from "../../../../Services/Globals/validate";
import {communicationSchema} from "./communicationSchema";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const HandleRelationshipData = (props)=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const location = useLocation();
    const[result,setResult] = useState(0)
    const [relations, setRelations] = useState([{id:new Date().getTime(),communicationId:null,mode:null,value:null,description:null}]);
    const [selectOptions,setSelectOptions] = useState({communicationTypes:[],Modes:[]})
    const[spinner,setSpinner] = useState(false);
    const [stamp,setStamp] = useState(null);
    const [isDefault,setIsDefault] = useState(false);

    const [errors,setErrors]=useState({});
    const [IsOpen,setIsOpen] = useState(false);
    const {handleValue,handleNumberValue,handleSelectValue,setCommunication,comminicatiion,handleStateShow}=useCustomCommunication(setErrors)
    const [IsBtnLoading,setBtnLoading]=useState(false);
    const handleShowLoading=()=>{
        setBtnLoading(true);
    }
    const handleHideLoading=()=>{
        setBtnLoading(false);
    }
    const handleOpenModal=()=>{
        setCommunication(prevState =>({...prevState,order: 0,stateShow: true}))
        setIsOpen(true);
    }
    const handleCloseModal = ()=>{
        setCommunication(prevState =>({...prevState,order: 0,stateShow: true,title: '',description: ''}))
        setErrors({});
        setIsOpen(false);
    }
    const onSetErrros=(errors)=>{
        setErrors(errors);
    }



    const handleAddRow = () => {
        const newRow = {
            id: new Date().getTime(),
            communicationId: selectOptions.communicationTypes[0], // Use the default value from options
            mode: selectOptions.Modes[0],
            value: null,
            description: null
        };
        setIsDefault(false);
        setRelations(prevList => [...prevList, newRow]);
    };

    const handleRemoveRow = (id,userCommunicationId) => {
        if(relations.length === 1){
            const newRow = {
                id: new Date().getTime(),
                communicationId: selectOptions.communicationTypes[0], // Use the default value from options
                mode: selectOptions.Modes[0],
                value: null,
                description: null
            };
            setIsDefault(true);
            setRelations(prevList => [newRow]);
            return;
        }
        setRelations((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.userCommunicationId === userCommunicationId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
        setChanged(true); // Set changed to true when a row is removed
    };

    const [isChanged,setChanged] = useState(false);
    const handleChange = (id, field, value, userCommunicationId) => {
        setRelations((prevRelations) => {
            const updatedRelations = prevRelations.map((relation) => {
                if (
                    (userCommunicationId && relation.userCommunicationId === userCommunicationId) || (!userCommunicationId && id && relation.id === id)
                ) {
                    return { ...relation, [field]: value };
                } else {
                    return relation; // Keep other rows unchanged
                }
            });
            return updatedRelations;
        });
        if(value === null || value === ""){
            setChanged(false);
        }else{
            setChanged(true)
        }
        setIsDefault(false);
    };
    const onApiError = () => {
        setSpinner(false);
    };
    // const onSetErrors=(errors)=>{
    //     setErrors(errors)
    // }


    const convertComTypeHandler = (requestObject,requiredObject,noneRequired)=>{
        const filteredMeta = requestObject.map(item => ({
            ...item,
            communicationId: requiredObject.find(typeItem => typeItem.value === item.type),
            mode: noneRequired.find(typeItem => typeItem.value === item.mode)
        }));
        return filteredMeta;
    }


    const handleFetchData=async()=>{
        try {
            setSpinner(true)
            const responseCommunicationType= await GET('/Communications/Selection',{},'IEnumerable');
            const responseMode = await GET('/Enumerations/EnCommunicationMode')

            if(responseCommunicationType.status === 200 && responseMode.status === 200){
                const communicationType = convertSelectDataHandler(responseCommunicationType.data);
                const modes = convertSelectDataHandler(responseMode.data);
                setSelectOptions((prevOptions) => ({...prevOptions, communicationTypes: communicationType,Modes: modes}));
                setRelations([{ ...relations[0], communicationId: communicationType[0], mode: modes[0] }])
                setSpinner(false)
                const queryParams = queryString.parse(window.location.search);
                if(queryParams.id){
                    try {
                        //setSpinner(true)
                        const response = await GET(`/Users/Communications/${queryParams.id}`,{},'IEnumerable');
                        if(response.status === 200){
                            setStamp(response.data.concurrencyStamp);
                            const filteredCommunications = convertCommunicationHandler(response.data.communications,communicationType,modes);
                            setRelations(filteredCommunications)

                            // setSpinner(false);
                            if(filteredCommunications.length === 0){
                                setRelations([{ ...relations[0], communicationId: communicationType[0], mode: modes[0] }])
                            }
                        }
                        else{
                            handleApiError(response,onApiError);
                        }
                    }catch (e) {
                        console.log(e)

                    }
                }
            }
            else {
                handleApiError(responseCommunicationType,onApiError);
                handleApiError(responseMode,onApiError);
            }
        } catch (e) {
            console.log(e)
        }
    }
    useEffect(()=>{
        handleFetchData();
    },[result])

    useEffect(() => {
        if (relations.length === 0) {
            handleAddRow();
        }
    }, [relations]);



    const handleCommunicationSubmit= async (type,nextTab)=>{

        if(isDefault === true){
            try {
                setSpinner(true);
                const queryParams = queryString.parse(window.location.search);
                const convertedData = convertCommunicationDataHandler([],queryParams.id || null,stamp);
                const response = await POST(`/Users/Communications/Add`,convertedData,false);
                if(response.status === 200){
                    setSpinner(false);
                    handleSuccessMessage(t("Informationhassuccessfully"));
                    setResult({...response.status});
                    if(type === 'register'){
                        if(location.pathname === '/CRM/User/Add'){
                            redirectTo('/CRM/User/List');
                        }
                    }
                    if(type === 'previous'){
                        props.handleTabClick(nextTab);
                        return;
                    }
                    if(type === 'next'){
                        props.handleTabClick(nextTab);
                        return;
                    }
                    // props.handleTabClick('event');
                }else{
                    handleApiError(response,onApiError)

                }
            }catch (e) {
                console.log(e);
            }
        }
        else{
            const CommunicationValues = relations.filter((item)=>item.value === null);
            if(CommunicationValues.length !== 0){
                handleFailureMessage(t("Communicationaddressismandatory"));
                return;
            }
            const nonNullRows = relations.filter((row) => (row.mode && row.communicationId && row.value) || row.description !== undefined);
            if(isChanged === true && nonNullRows.length > 0){
                try {
                    setSpinner(true);
                    const queryParams = queryString.parse(window.location.search);
                    const convertedData = convertCommunicationDataHandler(nonNullRows,queryParams.id || null,stamp);
                    const response = await POST(`/Users/Communications/Add`,convertedData,false);
                    if(response.status === 200){
                        setSpinner(false);
                        handleSuccessMessage(t("Informationhassuccessfully"));
                        setResult({...response.status});
                        if(type === 'register'){
                            if(location.pathname === '/CRM/User/Add'){
                                redirectTo('/CRM/User/List');
                            }
                        }
                        if(type === 'previous'){
                            props.handleTabClick(nextTab);
                            return;
                        }
                        if(type === 'next'){
                            props.handleTabClick(nextTab);
                            return;
                        }
                        // props.handleTabClick('event');
                    }else{
                        handleApiError(response,onApiError)

                    }
                }catch (e) {
                    console.log(e);

                }
            }else{
                if(type === 'register'){
                    redirectTo('/CRM/User/List');
                    return;
                }
                if(type === 'previous'){
                    props.handleTabClick(nextTab);
                    return;
                }
                if(type === 'next'){
                    props.handleTabClick(nextTab);
                    return;
                }
            }
            props.handleTabClick(nextTab);
        }



    }



    const handleSubmitCommunication=async(e)=>{
        e.preventDefault();
        const isValid = validate(communicationSchema,comminicatiion,onSetErrros);
        if(!isValid){
            return;
        }
        try {
            handleShowLoading();
            const response = await POST(`/Communications`,{
                title:comminicatiion.title === null || comminicatiion.title === undefined?null:comminicatiion.title,
                description:comminicatiion.description === null || comminicatiion.description === undefined?null:comminicatiion.description,
                stateShow:comminicatiion.stateShow,
                order:comminicatiion.order === null || comminicatiion.order === undefined?0:comminicatiion.order,
            },false);
            if(response.status === 200){
                handleHideLoading();
                handleCloseModal();
                handleSuccessMessage(t('SuccessDone'));
                const responseCommunicationType= await GET('/Communications/Selection',{},'IEnumerable');
                if(responseCommunicationType.status === 200){
                    const communicationTypes = convertSelectDataHandler(responseCommunicationType.data);
                    setSelectOptions((prevState)=>({...prevState,communicationTypes: communicationTypes}));
                }
                else{
                    handleApiError(response,handleHideLoading)
                }

            }else{
                handleApiError(response,handleHideLoading)
            }
        }
        catch (e) {
            handleHideLoading();
        }
    }


    return (
        <>
            <div className={"space-y-5"}>
                    {
                        spinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className="space-y-2">
                        <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                            <h2 className={"dark:text-gray-300"}>
                                {t("communications")}
                            </h2>
                        </div>
                        <div className="p-2 space-y-5 max_height85vh">
                            {relations.map((row,index) => (
                                <div key={row.id} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 items-end gap-2 post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                                    <div className={"flex gap-2"}>
                                        <div className="mt-auto">
                                            <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                                                {index+1}
                                            </div>
                                        </div>
                                        <div className="space-y-1 flex-1">
                                            <div className={"flex justify-between gap-2 items-center"}>
                                                <label className="text-xs dark:text-gray-300">{ t("CommunicationType")}</label>
                                                <button type="button" onClick={()=>handleOpenModal()} className="text-themeInformation dark:text-themeDarkInformation text-xs tooltip_class_Add_Bank">
                                                    ( {t("Create")} )
                                                    <Tooltip anchorSelect={".tooltip_class_Add_Bank"} content={t("UserContactDataRegistration")} />
                                                </button>
                                            </div>
                                            <Select
                                                options={selectOptions.communicationTypes}
                                                isMulti={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(data) => handleChange(row.id, 'communicationId', data,row.userCommunicationId)}
                                                placeholder={t("selection")}
                                                value={row.communicationId}
                                                menuPosition={"fixed"}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("TypeIndividualLegal")}</label>
                                        <Select
                                            options={selectOptions.Modes}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data) => handleChange(row.id, 'mode',data,row.userCommunicationId)}
                                            placeholder={t("selection")}
                                            value={row.mode}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("ContactAddress")}</label>
                                        <input type="text" className="input w-full" value={row.value} onChange={(e) => handleChange(row.id, 'value', e.target.value,row.userCommunicationId)}/>
                                    </div>
                                    <div className={"lg:col-span-2 flex gap-2 items-end"}>
                                        <div className="space-y-1 flex-1">
                                            <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                            <input type="text" className="input w-full"
                                                   value={row.description}
                                                   onChange={(e) => handleChange(row.id, 'description', e.target.value,row.userCommunicationId)}
                                            />
                                        </div>
                                        <div className="flex gap-2" >
                                            <button type="button" className="button bg-themeInformation dark:bg-themeDarkInformation text-white small_button"
                                                    onClick={handleAddRow}>
                                                <HPrimeIcon icon={'pi-plus'}  />
                                            </button>

                                            <button type="button" className="button bg-themeDanger text-white small_button "
                                                    onClick={() => handleRemoveRow(row.id,row.userCommunicationId)}>
                                                <HPrimeIcon icon={'pi-minus'}  />
                                            </button>

                                            {/*{row.id === relations.length && (*/}
                                            {/*   */}
                                            {/*)}*/}

                                        </div>
                                    </div>


                                </div>
                            ))}
                        </div>
                    </div>
                <div className=" flex flex-wrap items-center justify-center lg:justify-end gap-2">
                    <button onClick={() => handleCommunicationSubmit('previous','bank')}
                            className="button w-24 text-white bg-themeDanger">
                        {t("previous")}
                    </button>
                    <button onClick={() => handleCommunicationSubmit('register')}
                            className="button w-24 text-white bg-themeSuccess">
                        {t("register")}
                    </button>
                    <button onClick={() => handleCommunicationSubmit('next',"event")}
                            className="button  text-white bg-themeInformation dark:bg-themeDarkInformation">
                        {t("registerAndNext")}
                    </button>
                </div>
            </div>


            <FantasyModal openModal={IsOpen} closeModal={handleCloseModal} title={t("communications")}>
                <form method="post" onSubmit={handleSubmitCommunication}>
                    <div className="space-y-5">
                        <div className={"space-y-3"}>
                            <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                                <div className={"flex gap-2 items-center"}>
                                    <div className="w-20 flex-shrink-0 space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("Priority")}</label>
                                        <InputNumber className="w-full " value={comminicatiion.order} onChange={(e)=>handleNumberValue(e.value,'order')} name={'order'}   showButtons min={0} />
                                    </div>
                                    <div className="space-y-1 flex-1 w-full">
                                        <div className={"flex justify-between gap-1 items-center"}>
                                            <label className="dark:text-gray-300 text-xs">
                                                {t("Title")}
                                                <RequiredIcon/>
                                            </label>
                                            <DisplayError message={errors.title}/>
                                        </div>

                                        <InputText
                                            type="text"
                                            className="input w-full"
                                            name={'title'}
                                            value={comminicatiion.title}
                                            onInput={(e)=>handleValue(e)}
                                        />

                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <div className={"flex justify-between items-center gap-2"}>
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("type")}
                                            <RequiredIcon/>
                                        </label>
                                        <DisplayError message={errors.title}/>
                                    </div>

                                    <Select

                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t("selection")}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />

                                </div>
                            </div>
                            <div>
                                <InputText
                                    type="text"
                                    name={'description'}
                                    value={comminicatiion.description}
                                    onInput={(e)=>handleValue(e)}
                                    className="input w-full"
                                    placeholder={t("description")}
                                />
                            </div>
                            <div className="flex-shrink-0">
                                <ToggleSwitch tag={'stateShow'} value={comminicatiion.stateShow} handleToggleSwitchValue={handleStateShow} name={'stateShow'}    customClass={"w-32"}  checked={t("isActive")} unchecked={t("isNotActive")} />
                            </div>
                        </div>



                        <div >
                            <button type={"submit"} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative"}>
                                {t("insertion")}
                                {IsBtnLoading ? (
                                    <TinyLoading
                                    />
                                ) : (
                                    null
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </FantasyModal>
        </>
    )
}