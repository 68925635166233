import React, {useState} from "react";
import {convertSlugHandler} from "../ContentService";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

export const useCustomContentGroup=(setErrors,hideSpinner)=>{
    const [group,setGroup] = useState({excerpt: '',slug: '',seoTitle: '',title: '',type: null,parentId: null,displayOrder: 0});
    const [IsSlugValid, setIsSlugValid] = useState(false);

    const GetContentGroupSlug = (e) => {
        const { value } = e.target;
        const slug = convertSlugHandler(value);
        setGroup((prevState) => ({ ...prevState, slug: slug }));
    };
    const handleValue = (e) => {
        const { name, value } = e.target;
        if (name === "title") {
            GetContentGroupSlug(e);
            setGroup((prevState) => ({ ...prevState, seoTitle: value }));
            setGroup((prevState) => ({ ...prevState, excerpt: value }));
        }
        setGroup((prevState) => ({ ...prevState, [name]: value }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const handleSelectValue = (data,name)=>{
        setGroup({ ...group, [name]: data });
    }


    const validateSlug = async (e) => {
        const { name,value } = e.target;
        if (name === "title") {
            try {
                const response = await GET('/Public/SlugValidation',{NewSlug:value,OldSlug:null},'single')
                if(response.status === 200){
                    const {isSlugValid} = response.data
                    setIsSlugValid(isSlugValid);
                }
                else{
                    handleApiError(response,hideSpinner);
                }

            }catch (e) {
                console.log(e);
                hideSpinner();
            }
        }
        else{
            try {
                const response = await GET('/Public/SlugValidation',{NewSlug:value,OldSlug:null},'single')
                if(response.status === 200){
                    const {isSlugValid} = response.data
                    setIsSlugValid(isSlugValid);
                }else{
                    handleApiError(response,hideSpinner);
                }
            }catch (e) {
                console.log(e);
                hideSpinner();
            }
        }
    };



    return {
        handleValue,
        group,
        setGroup,
        validateSlug,
        handleSelectValue
    }
}