export const handleConvertToTree = (data) => {
    const tree = [];
    const map = data.reduce((acc, obj) => {
        obj.children = [];
        acc[obj.id] = obj;
        obj.subtitle = obj.slug;
        obj.expanded = true;
        return acc;
    }, {});
    data.forEach((obj) => {
        const parent = map[obj.parentId];
        if (parent) {
            parent.children.push(obj);
        }
        else {
            tree.push(obj);
        }
    });
    return tree;
};
export const setExpandedById = (treeData, parentId) => {
    for (let node of treeData) {
        if (node.id === parentId) {
            node.expanded = true;
            return true;
        }
        if (node.children) {
            if (setExpandedById(node.children, parentId)) {
                node.expanded = true; // Expand the parent node
                return true;
            }
        }
    }
    return false;
};

export const handleEditConvertProductGroup = (obj)=>{
    const data = {
        productGroupId:obj.productGroupId ??null,
        title: obj.title === null || obj.title === undefined ? null:obj.title,
        slug: obj.slug === null || obj.slug === undefined ? null:obj.slug,
        seoTitle: obj.seO_Title === null || obj.seO_Title === undefined? null:obj.seO_Title,
        seoDescription: obj.seO_Description === null || obj.seO_Description === undefined? null:obj.seO_Description,
        parentId: obj.parentId === null || obj.parentId === undefined?null: obj.parentId.value,
        order:  obj.order === null || obj.order === undefined ? 0 : obj.order,
        excerpt: obj.excerpt === null || obj.excerpt === undefined ? null : obj.excerpt,
        stateShow: obj.stateShow === null || obj.stateShow === undefined ? null : obj.stateShow,
        stateGoogle: obj.stateGoogle === null || obj.stateGoogle === undefined ? null : obj.stateGoogle,
        type: obj.type === null || obj.type === undefined? null : obj.type.value,

    }
    return data
}
export const handleAddConvertProductGroup = (obj)=>{
    const data = {
        title: obj.title === null || obj.title === undefined ? null:obj.title,
        slug: obj.slug === null || obj.slug === undefined ? null:obj.slug,
        seO_Title: obj.seO_Title === null || obj.seO_Title === undefined? null:obj.seO_Title,
        seO_Description: obj.seO_Description === null || obj.seO_Description === undefined? null:obj.seO_Description,
        parentId: obj.parentId === null || obj.parentId === undefined?null: obj.parentId.value,
        order:  obj.order === null || obj.order === undefined ? 0 : obj.order,
        excerpt: obj.excerpt === null || obj.excerpt === undefined ? null : obj.excerpt,
        type: obj.type === null || obj.type === undefined? null : obj.type.value,
        stateShow: obj.stateShow === null || obj.stateShow === undefined ? null : obj.stateShow,
        stateGoogle: obj.stateGoogle === null || obj.stateGoogle === undefined ? null : obj.stateGoogle,
    }
    return data
}