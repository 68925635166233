import React from "react";


export const HandleReportOther=()=>{

    return(
        <div class="space-y-5">

            <div class="flex flex-wrap px-2 py-1 rounded text-xs lg:text-base gap-2">
                    <a href="#1" className="px-1 lg:px-2 py-2 rounded-md flex justify-center items-center bg-white hover:bg-themeHoverInformation hover:text-white">مالی</a>
                    <a href="#2" className="px-1 lg:px-2 py-2 rounded-md flex justify-center items-center bg-white hover:bg-themeHoverInformation hover:text-white">حسابداری</a>
                    <a href="#3" className="px-1 lg:px-2 py-2 rounded-md flex justify-center items-center bg-white hover:bg-themeHoverInformation hover:text-white">اشخاص</a>
                    <a href="#4" className="px-1 lg:px-2 py-2 rounded-md flex justify-center items-center bg-white hover:bg-themeHoverInformation hover:text-white">کالاها
                        و خدمات</a>
                    <a href="#5" className="px-1 lg:px-2 py-2 rounded-md flex justify-center items-center bg-white hover:bg-themeHoverInformation hover:text-white"> خرید
                        و فروش</a>
                    <a href="#6" className="px-1 lg:px-2 py-2 rounded-md flex justify-center items-center bg-white hover:bg-themeHoverInformation hover:text-white">بانکداری</a>
                </div>
            <div className="space-y-5">
                <div id="1">
                    <h2 class="text-xl dark:text-gray-300">مالی</h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-6 gap-5">
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                            <div>
                                <div class="border-2 p-2 bg-gray-200 rounded-full">
                                    <img src="/Assets/Image/Acc_Report/balance.svg" alt="" className="w-16 h-16"/>
                                </div>
                            </div>
                            <div>
                                <h3 className="dark:text-gray-300">ترازنامه</h3>
                            </div>
                        </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                            <div>
                                <div class="border-2 p-2 bg-gray-200 rounded-full">
                                    <img src="/Assets/Image/Acc_Report/profit_and_debts.svg" alt="" className="w-16 h-16"/>
                                </div>
                            </div>
                            <div>
                                <h3 className="dark:text-gray-300">صورت سود وزیان</h3>
                            </div>
                        </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/fund.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-gray-300">صورت حساب سرمایه</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div className="space-y-5">
                <div id="2">
                    <h2 class="text-xl dark:text-gray-300">حسابداری</h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-6 gap-5">
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/notebook.svg" alt="" className="w-16 h-16" />
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-gray-300">دفتر روزنامه</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/account_list.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-gray-300">دفتر حساب</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/hammer.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-gray-300">تراز آزمایشی</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/account_review.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-whitedark:text-gray-300">مرور حساب</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div className="space-y-5">
                <div>
                    <h2 class="text-xl dark:text-white" id="3">اشخاص</h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-6 gap-5">
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/creditors_and_debtors.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">بدهکاران و بستانکاران</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                            <div>
                                <div class="border-2 p-2 bg-gray-200 rounded-full">
                                    <img src="/Assets/Image/Acc_Report/personAccounting.svg" alt="" className="w-16 h-16"/>
                                </div>
                            </div>
                            <div>
                                <h3 className="dark:text-white">کارت حساب اشخاص</h3>
                            </div>
                        </a>
                </div>
            </div>
            <div className="space-y-5">
                <div>
                    <h2 class="text-xl dark:text-white" id="4">کالاها و خدمات</h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-6 gap-5">
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/productAccounting.svg" alt=""/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">کارت حساب کالا</h3>
                        </div>
                    </a>
                </div>
                </div>
            <div className="space-y-5">
                <div >
                    <h2 class="text-xl dark:text-white" id="5">خریدو فروش</h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-6 gap-5">
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/sale_by_product.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">فروش به تفکیک کالا</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/buy_by_product.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">خرید به تفکیک کالا</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/buy_by_product.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">فروش به تفکیک فاکتور</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/debtors.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">خرید به تفکیک فاکتور</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/tax_list.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">گزارش مالیات</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/checkout_income.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">سود فاکتور</h3>
                        </div>
                    </a>
                </div>

                </div>
            <div class="space-y-5">
                <div>
                    <h2 class="text-xl dark:text-white" id="6">بانکداری</h2>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-6 gap-5">
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/turnover.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">گردش حساب بانک</h3>
                        </div>
                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/turnover.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">گردش حساب صندوق</h3>
                        </div>

                    </a>
                    <a href="#" className="box bg-white dark:bg-themeDarkSalt700 p-3 flex flex-col justify-between items-center gap-2 zoom-in">
                        <div>
                            <div class="border-2 p-2 bg-gray-200 rounded-full">
                                <img src="/Assets/Image/Acc_Report/slanderer_turnover.svg" alt="" className="w-16 h-16"/>
                            </div>
                        </div>
                        <div>
                            <h3 className="dark:text-white">گردش حساب تنخواه گردان</h3>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )

}
