
export const handleCreateProductTypeFormData = (parentId, concurrencyStamp, products) => {
    const formData = new FormData();

    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }

    appendIfNotNull('parentId', parentId);
    appendIfNotNull('concurrencyStamp', concurrencyStamp);

    products.forEach((product, index) => {
        for (const key in product) {
            if (product.hasOwnProperty(key)) {
                if (key === 'sendingStates' && Array.isArray(product[key])) {
                    product[key].forEach((state, stateIndex) => {
                        appendIfNotNull(`products[${index}].${key}[${stateIndex}]`, state.value);
                    });
                } else if (['supplierId', 'producerId', 'filterId', 'propertyType'].includes(key)) {
                    appendIfNotNull(`products[${index}].${key}`, product[key]?.value);
                } else {
                    appendIfNotNull(`products[${index}].${key}`, product[key]);
                }
            }
        }
    });

    return formData;
};
export const handleCreateSlug = (title) => {
    const slug = title
        .replace(/[^\p{Letter}\d\s]/gu, "-") // غیر حرف و غیر عددی رو با dash جایگزین میکنه
        .replace(/\s+/g, "-") // فضای خالی رو با dash عوض میکنه
        .replace(/-+/g, "-"); // چندین dash  رو با یک dash  جابجا میکنه

    return slug;
};