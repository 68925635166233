import React, { useEffect, useState } from "react";

import Select from "react-select";
import { useTranslation } from "react-i18next";
import {Tooltip} from "react-tooltip";
import {InputNumber} from "primereact/inputnumber";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const PictureAttachCard = ({totalSize, IsEdit, showSpinner, hideSpinner, setCards, deleteCard, addNewCard, handleFieldChange, handleFileChange, triggerFileInput, cards, initialSource, handleDeleteDocumentAttachment}) => {
    const { t } = useTranslation();

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const getFileIcon = (fileType,filePath) => {
        if (!fileType) {
            return (
                <img
                    src="/Assets/Image/bg-img/add-photo.png"
                    alt="Default"
                    className="size-full object-cover"
                />
            );
        }
        else {
            if (fileType.startsWith('image/')) return filePath;
            if (fileType.startsWith('video/')) return '/Assets/Image/bg-img/video.svg';
            if (fileType.startsWith('audio/')) return '/Assets/Image/bg-img/Audio.svg';
            if (fileType === 'application/pdf') return '/Assets/Image/bg-img/Pdf.svg';
            if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return '/Assets/Image/bg-img/Word.svg';
            if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return '/Assets/Image/bg-img/Excel.svg';
            if (fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return '/Assets/Image/bg-img/Ppt.svg';
            if (fileType === 'text/plain') return '/Assets/Image/bg-img/Txt.svg';
            if (fileType === 'application/zip' || fileType === 'application/x-rar-compressed') return '/Assets/Image/bg-img/zip.svg';
            if(fileType === "")return "/Assets/Image/bg-img/Other.svg"
            return '/Assets/Image/bg-img/Other.svg';
        }
    };

    const displayFile=(filePath)=>{
        window.open(filePath);
    }



    return (
        <>
            <div className={"space-y-5"}>
                {cards.map((card, index) => (

                    <div className="post-input dark:bg-themeDarkPostInput post-input-design flex flex-col sm:flex-row items-center p-2 gap-3" key={index}>
                        <div className={"flex size-48 flex-shrink-0"}>
                            <div className="size-full relative border-2 bg-white dark:bg-themeDarkPostInput overflow-hidden rounded-md border-dashed border-gray-300 dark:border-themeDarkColorLine ">
                                <input
                                    type="file"
                                    id={`file-input-${index}`}
                                    style={{ display: "none" }}
                                    onChange={(e) => handleFileChange(index, e)}
                                />
                                <label
                                    htmlFor={`file-input-${index}`}
                                    className="size-full flex items-center justify-center cursor-pointer relative"
                                >
                                    {card.filePath ? (
                                        <>

                                            <img
                                                src={getFileIcon(card.fileType,card.filePath)}
                                                alt="File preview"
                                                className="size-full object-cover"
                                            />

                                        </>
                                    ) : (
                                        <>

                                            <img
                                                src="/Assets/Image/bg-img/add-photo.png"
                                                alt="Default"
                                                className="size-full object-cover"
                                            />

                                        </>
                                    )}
                                    { card.filePath  ?
                                        <div className={"absolute w-full bottom-0 end-0 start-0 flex items-center justify-between  bg-themeInformation dark:bg-themeDarkInformation p-1"}>
                                            <button
                                                onClick={()=>displayFile(card.filePath)}
                                                type={"button"}
                                            >
                                                <HPrimeIconSmall icon={"pi-eye"} iconClass={`text-white tooltip_class_${index}`}  />
                                                <Tooltip anchorSelect={`.tooltip_class_${index}`} content={t("ViewFile")}  positionStrategy={"fixed"} />
                                            </button>

                                            <div>
                                                {
                                                    IsEdit && card.bannerId !== null && card.file === null?
                                                        <div className="text-xs text-white">{formatBytes(card.fileSize)}</div>
                                                        :null
                                                }

                                                {card.file && (
                                                    <div className="text-xs text-white ">{formatBytes(card.file.size)}</div>
                                                )}
                                            </div>

                                        </div>
                                        :null
                                    }

                                </label>

                            </div>
                        </div>
                        <div className={"flex-1 w-full"}>
                            <div className={"space-y-4"}>
                                <div className={"flex flex-col md:flex-row gap-1"}>
                                    <div className={"flex-1 flex gap-1"}>
                                        <div className="mt-auto">
                                            <div className="number_pluse text-white">
                                                {index+1}
                                            </div>
                                        </div>
                                        <div className={"flex-1 space-y-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                                            <input
                                                type="text"
                                                value={card.referenceUrl}
                                                onChange={(e) =>
                                                    handleFieldChange(index, "referenceUrl", e.target.value)
                                                }
                                                className="input w-full"
                                            />
                                        </div>
                                        <div className="space-y-1 w-20 flex-shrink-0">
                                            <label className="text-xs dark:text-gray-300">{t("DisplayOrder")}</label>
                                            <InputNumber
                                                type={"text"}
                                                name={"order"}
                                                onChange={(e) => handleFieldChange(index,'order',e.value)}
                                                showButtons
                                                min={0}
                                                value={card.order}
                                                placeholder="Group Order"
                                                className=" w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={"flex gap-1"}>
                                    <div className={"flex-1 space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                        <input
                                            type="text"
                                            value={card.caption}
                                            onChange={(e) =>
                                                handleFieldChange(index, "caption", e.target.value)
                                            }
                                            className="input w-full"
                                        />
                                    </div>

                                    <div className={"flex-shrink-0 mt-auto flex gap-2"}>
                                        <div>
                                            <button
                                                className="button small_button bg-themeInformation text-white dark:bg-themeDarkInformation"
                                                onClick={addNewCard}
                                                type={"button"}
                                            >
                                                <HPrimeIcon icon={"pi-plus"} />
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type={"button"}
                                                className="button small_button bg-themeDanger text-white"
                                                onClick={() => deleteCard(index)}>
                                                <HPrimeIcon icon={"pi-minus"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                ))}

            </div>
        </>
    );
};
