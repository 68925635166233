import React from "react";

import {useTranslation} from "react-i18next";
import {HPrimeIconSmall} from "./HPrimeIconSmall";


export const ModalButtonOperations = ({ onClick, icon, title,functionClassName }) => {
    const {t} = useTranslation();
    return (
        <>
            <button onClick={onClick}
                    className={`Modal_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-2 flex-col min-w-[6.5rem] min-h-[6.5rem] justify-center  ${functionClassName}`}>
                <HPrimeIconSmall
                    icon={icon}
                />
                <span className={"text-xs"}>
                    {title}
                </span>
            </button>
        </>)


}