import React, {useState} from "react";
export const useSocialAccount=(setErrors)=>{
    const [socialAccount,setSocialAccount]=useState({
        telegram:{
            chatId:null,
            botToken:null,
            IsExclusiveToken:false,
        },
    })
    const handleUpdateSocialAccount = (platformName, fieldName, value) => {
        setSocialAccount(prevState => ({
            ...prevState,
            [platformName]: {
                ...prevState[platformName],
                [fieldName]: value
            }
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: undefined,
        }));
    };
    return {
        handleUpdateSocialAccount,
        socialAccount,
        setSocialAccount
    }
}
