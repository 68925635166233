// ... other imports

import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {StateContext} from "../../Authentication/StateProvider";
import Select from "react-select";

export const LanguageChanger = () => {
    const { t } = useTranslation();

    const [options, setOptions] = useState([
        { value: "fa-IR", label: t("Persian"), icon: <div className={'bg_Fa'} /> },
        { value: "en-US", label: t("English"), icon: <div className={'bg_En'} /> },
    ]);

    const { i18n } = useTranslation();
    const { lang, SetLanguage } = useContext(StateContext);
    const [selectedLanguage, setSelectedLanguage] = useState(lang);

    const handleLanguageChange = (locale) => {
        if (locale) {
            localStorage.setItem("language", locale);
            SetLanguage(locale);
            i18n.changeLanguage(locale);
            // window.location = '';
            setSelectedLanguage(locale);
        }
    };

    useEffect(() => {
        const prevLocale = localStorage.getItem("language");
        if (prevLocale) {
            SetLanguage(prevLocale);
            setSelectedLanguage(prevLocale);
        } else {
            SetLanguage("en-US");
            localStorage.setItem("language", "en-US");
            setSelectedLanguage("en-US");
        }
    }, []);

    // useEffect(() => {
    //     if (lang === "fa-IR") {
    //         import("../../../Styles/dist/css/IR_app.css");
    //     } else if (lang === "en-US") {
    //         import("../../../Styles/dist/css/En_app.css");
    //     }
    // }, [lang]);


    /*افزودن LTR به BODY*/
    useEffect(() => {
        const body = document.body;
         if (lang === "en-US") {
            // import("../../../Styles/dist/css/En_app.css");
            body.classList.add("h-ltr");
        } else {
            body.classList.remove("h-ltr");
        }

        return () => {
            body.classList.remove("h-ltr");
        };
    }, [lang]);


    return (
        <>
            <Select
                options={options.map((option) => ({
                    ...option,
                    label: (
                        <div className={"flex items-center gap-2"}>
                            {option.icon}
                            <span>{option.label}</span>
                        </div>
                    ),
                }))}
                isMulti={false}
                isClearable={false}
                isSearchable={true}
                onChange={(data) => handleLanguageChange(data.value)}
                value={{
                    value: selectedLanguage,
                    label: (
                        <div className={"flex items-center gap-2"}>
                            {options.find((opt) => opt.value === selectedLanguage)?.icon}
                            <span>
                                {options.find((opt) => opt.value === selectedLanguage)?.label}
                            </span>
                        </div>
                    ),
                }}
                classNamePrefix={"h_multi_select"}
                // menuPosition={'fixed'}
            />
        </>
    );
};
