import React, { useState, useEffect } from "react";

import { ButtonSubmitAndCancel } from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {useShortCut} from "./useShortCut";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {useTranslation} from "react-i18next";
import {
    handleCreateShortcuts,
    handleGetShortCuts,
    validateAreValuesUnique, validateSlugsShouldNotBeEmpty,
    validateValueShouldNotBeEmpty
} from "./shortcutService";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const HandleShortcutKeySetting = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh,setRefresh]=useState(false)
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [initialSource,setInitialSource] =useState({processes:[]});
    const {setHotkeys,addNewHotkey,handleClick,handleBlur,hotkeys,mapKeyCodeToEnglishKey,removeHotkey,handleChange,handleInitialHotkeys}=useShortCut(initialSource);

    const Refresh=()=>{
        setRefresh((prevState)=>!prevState)
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleInitialDataSource=(respProcess)=>{
        const processData = convertSelectDataHandler(respProcess);
        setInitialSource({
            processes: processData
        })
        return {
            processData
        }
    }
    const fetchData = async () => {
        try {
            showSpinner();
            const [responseProcess, response] = await Promise.all([
                GET(`/Enumerations/EnProcess`, {}, 'IEnumerable'),
                GET(`/Settings/UserShortcuts`, {}, 'IEnumerable'),
            ]);

            if (responseProcess.status === 200 && response.status === 200) {
                const {processData} = handleInitialDataSource(responseProcess.data);
                if(response.data.length === 0){
                    handleInitialHotkeys(processData);
                }
                else{
                    const shortCuts = handleGetShortCuts(response.data,processData);
                    setHotkeys(shortCuts)
                }
                hideSpinner();
            } else {
                handleApiError(responseProcess, hideSpinner);
                handleApiError(response, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(() => {
        fetchData();
    }, [refresh]);



    const handleSubmitShortCuts=async()=>{
        try {

            const isEmptyValue = validateValueShouldNotBeEmpty(hotkeys)
            if(!isEmptyValue){
                handleFailureMessage(t("TheShortcutKeyValueIsEmpty"));
                return ;
            }
            const isUniqueValue = validateAreValuesUnique(hotkeys);
            if(!isUniqueValue){
                handleFailureMessage(t("DuplicateShortcutKeyValueEntered"));
                return ;
            }
            const isSlugEmpty = validateSlugsShouldNotBeEmpty(hotkeys);
            if(!isSlugEmpty){
                handleFailureMessage(t("EnteringIsRequired"));
                return ;
            }

            showSpinner();
            const shortCuts = handleCreateShortcuts(hotkeys);
            const response = await POST(`/Settings/UserShortcuts`,shortCuts,false);
            if(response.status === 200){
                Refresh();
                handleSuccessMessage(t('SuccessDone'))
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }




    return (

        <>
            <div  className={'space-y-5 relative'}>
                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                            icon={"pi-cog"}
                                            title={t("settings")}
                                            tooltipTitle={t("SettingsPage")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Domain`)}
                                            icon={"pi-globe"}
                                            title={t("Domain")}
                                            tooltipTitle={t("Domain")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Script`)}
                                            icon={"pi-code"}
                                            title={t("Script")}
                                            tooltipTitle={t("AdditionalScript")}
                                        />


                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Security`)}
                                            icon={"pi-shield"}
                                            title={t("Security")}
                                            tooltipTitle={t("SecurityAndPrivacy")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/BackUp`)}
                                            icon={"pi-server"}
                                            title={t("support")}
                                            tooltipTitle={t("SupportAndRecovery")}
                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                <div>
                    <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3"}>
                        <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                            <h2 className={"text-xs dark:text-gray-300"}>{t("ShortcutKey")}</h2>
                        </div>
                        <div className={"px-2 relative space-y-3"}>
                            {hotkeys.map((hotkey, index) => (
                                <div key={index} className={"p-3 post-input post-input-design dark:bg-themeDarkPostInput grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 items-center"} >
                                    <div className={"flex gap-1 items-center"}>
                                        <div className="number_pluse bg-themeInformation text-white text-center dark:bg-themeDarkInformation mt-auto">
                                            {index+1}
                                        </div>
                                        <div className={"flex-1 space-y-1 w-full flex gap-2 items-center"}>
                                            <div className={"flex-1 w-full relative"}>
                                                <input
                                                    id={`input-${index}`}
                                                    type="text"
                                                    value={hotkey.value}
                                                    onBlur={() => handleBlur(index)}
                                                    placeholder={t("CtrlShift")}
                                                    readOnly
                                                    className={"input text-center w-full"}
                                                />
                                                <div className={"absolute start-2 -top-2"}>
                                                    <p className={"text-xs text-themeDanger"}>
                                                        {hotkey.isRecording ? (hotkey.guide ? t("TypeALetter") : "") : ""}
                                                    </p>
                                                    <p className={"text-xs text-themeDanger"}>
                                                        {hotkey.isRecording ? (hotkey.errorMessage ? t("UseEitherCtrlOrAlt") : "") : ""}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={"flex-shrink-0"}>
                                                <button type={"button"}  onClick={() => handleClick(index)} >
                                                    <HPrimeIcon icon={"pi-pen-to-square"} iconClass={"dark:text-gray-300"} />
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                    <div onClick={(e) => e.stopPropagation()}>
                                        <Select
                                            options={initialSource.processes}
                                            placeholder={t("selection")}
                                            classNamePrefix={"h_multi_select"}
                                            menuPosition={"fixed"}
                                            value={hotkey.process}
                                            onChange={(data) => handleChange(index, data, 'process')}
                                        />
                                    </div>
                                    <div className={"flex gap-2 items-center lg:col-span-2"}>
                                        <div className={"flex-1 w-full"}>
                                            <input type="text"
                                                   value={hotkey.to}
                                                   onChange={(e) => handleChange(index, e.target.value, 'to')}
                                                   className="input w-full"
                                            />
                                        </div>
                                        <div className={"flex-shrink-0 flex gap-2 items-center"}>
                                            <button type={"button"} onClick={addNewHotkey} className={"button small_button bg-themeInformation dark:bg-themeDarkInformation text-white"}>
                                                <HPrimeIcon icon={"pi-plus"}  />
                                            </button>
                                            <button type={"button"} className={"button bg-themeDanger text-white small_button"} onClick={() => removeHotkey(index)}>
                                                <HPrimeIcon icon={"pi-minus"} />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <ButtonSubmitAndCancel
                    onCancelClick={() => redirectTo('/Harmony/Master/setting')

                    }
                    onRegisterClick={()=>handleSubmitShortCuts()}
                />
                <ToastContainer/>
            </div>
        </>

    );
};