


export const convertAddRequest = (discount) => {
    return discount.map(item => ({
        discountOrCommissionType: item.discountOrCommissionType === null || item.discountOrCommissionType === undefined  ? null: item.discountOrCommissionType.value,
        quantityOrAmount: item.quantityOrAmount === null || item.quantityOrAmount === undefined ?null: item.quantityOrAmount.value,
        discountValue: item.discountValue,
        offerProductId: item.offerProductId === null || item.offerProductId === undefined ? null : item.offerProductId.value,
        stepValue: item.stepValue,
        minValue: item.minValue,
        maxValue: item.maxValue,
        offerProductCount:item.offerProductCount === null || item.offerProductCount === undefined?0:item.offerProductCount
    }));
};

export const convertEditRequest = (discount) => {
    return discount.map(item => ({
        discountId:item.discountId,
        discountOrCommissionType: item.discountOrCommissionType === null || item.discountOrCommissionType === undefined  ? null: item.discountOrCommissionType.value,
        quantityOrAmount: item.quantityOrAmount === null || item.quantityOrAmount === undefined ?null: item.quantityOrAmount.value,
        discountValue: item.discountValue,
        offerProductId: item.offerProductId === null || item.offerProductId === undefined ? null : item.offerProductId.value,
        stepValue: item.stepValue,
        minValue: item.minValue,
        maxValue: item.maxValue,
        offerProductCount:item.offerProductCount === null || item.offerProductCount === undefined?0:item.offerProductCount

    }));
};



export const convertAddDiscountGroup=(obj,discount)=>{
    const newObj={
        caption:obj.caption === null || obj.caption === undefined?null:obj.caption,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        fromDate:obj.fromDate === null || obj.fromDate === undefined ? null:obj.fromDate,
        toDate:obj.toDate === null || obj.toDate === undefined ? null:obj.toDate,
        stateShow:obj.stateShow === null || obj.stateShow === undefined ? null:obj.stateShow,
        userTargetTypeId:obj.userTargetTypeId === null || obj.userTargetTypeId === undefined ? null:obj.userTargetTypeId.value,
        productTargetTypeId:obj.productTargetTypeId === null || obj.productTargetTypeId === undefined ? null:obj.productTargetTypeId.value,
        priority:obj.priority === null || obj.priority === undefined?null:obj.priority,
        discounts:discount
    }
    return newObj;
}
export const convertEditDiscountGroup=(obj,discount)=>{
    const newObj={
        discountGroupId:obj.discountGroupId === null || obj.discountGroupId === undefined?null:obj.discountGroupId,
        caption:obj.caption === null || obj.caption === undefined?null:obj.caption,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        fromDate:obj.fromDate === null || obj.fromDate === undefined ? null:obj.fromDate,
        toDate:obj.toDate === null || obj.toDate === undefined ? null:obj.toDate,
        stateShow:obj.stateShow === null || obj.stateShow === undefined ? null:obj.stateShow,
        userTargetTypeId:obj.userTargetTypeId === null || obj.userTargetTypeId === undefined ? null:obj.userTargetTypeId.value,
        productTargetTypeId:obj.productTargetTypeId === null || obj.productTargetTypeId === undefined ? null:obj.productTargetTypeId.value,
        priority:obj.priority === null || obj.priority === undefined?null:obj.priority,
        discounts:discount
    }
    return newObj;
}

export const convertDiscounts = (requestObject,discountOrCommissionType,offerProductId,quantityOrAmount)=>{
    const filteredDiscounts = requestObject.map(item => ({
        ...item,
        discountOrCommissionType:discountOrCommissionType.find(typeItem => typeItem.value === item.discountOrCommissionType),
        offerProductId:offerProductId.find(typeItem => typeItem.value === item.offerProductId),
        quantityOrAmount:quantityOrAmount.find(typeItem => typeItem.value === item.quantityOrAmount),
    }));
    return filteredDiscounts;
}




