import React, {useEffect, useState} from "react";
import {Accordion, AccordionTab} from "primereact/accordion";

import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {InputTextarea} from "primereact/inputtextarea";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {SelectedElementProperties} from "../ElementProperties/SelectedElementProperties";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePicker from "react-multi-date-picker";
import arabic_ar from "react-date-object/locales/arabic_ar";
import indian_hi from "react-date-object/locales/indian_hi";
import persian_fa from "react-date-object/locales/persian_fa";
import persian from "react-date-object/calendars/persian";
import arabic from "react-date-object/calendars/arabic";
import indian from "react-date-object/calendars/indian";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import InputIcon from "react-multi-date-picker/components/input_icon";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const Properties=({elements,handlePropertyChange,handleDefaultValueChange,deleteElement,fields,selectedElementIndex, addCheckBoxItem,deleteCheckBoxItem,handleItemPropertyChange})=>{
    const { t } = useTranslation();
    const [initialSource,setInitialSource]=useState({
        sizeParameters:[{value:0,label:t("Small"),className:'harmony-col-span-1'},{value:1,label:t("Medium"),className:'harmony-col-span-1 harmony-md-col-span-2'},{value:2,label:t("AlmostBig"),className:'harmony-col-span-1 harmony-md-col-span-3'},{value:3,label:t("Large"),className:'harmony-col-span-1 harmony-md-col-span-4'}]
    });


    // const fetchData=()=>{
    //     if(selectedElementIndex !== null){
    //         handlePropertyChange(selectedElementIndex, "size",initialSource.sizeParameters[0]);
    //     }
    // }
    //
    // useEffect(()=>{
    //     fetchData();
    // },[])

    const handleCalendarView=()=>{
        switch (elements[selectedElementIndex].properties.calendarView?.value) {
            case 0:
                return false
            case 1 :
                return true
            case 2:
                return true
        }
    }

    const handleCalendarLanguage=()=>{
        switch (elements[selectedElementIndex].properties.calendarLanguage?.value) {
            case 0:
                return null
            case 1 :
                return arabic_ar
            case 2:
                return indian_hi
            case 3 :
                return  persian_fa
        }
    }
    const handleCalendarType=()=>{
        switch (elements[selectedElementIndex].properties.calendarType?.value) {
            case 0:
                return persian
            case 1 :
                return arabic
            case 2:
                return null
            case 3:
                return indian
        }
    }




    return (
        <>

            <Accordion activeIndex={0}>
                <AccordionTab
                    header={
                    <div className="flex items-center gap-2">
                        <HPrimeIconSmall icon={'pi-pen-to-square'} />
                        <p>{t("Public")}</p>
                    </div>
                }
                >
                    {
                        selectedElementIndex !== null?
                            <>
                                <div class="post-input dark:bg-themeDarkPostInput post-input-design grid grid-cols-1 md:grid-cols-2 gap-3 p-2">
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("ID")}</label>
                                        <InputText
                                            className="input w-full"
                                            onChange={(e) => handlePropertyChange(selectedElementIndex, 'id', e.target.value)}
                                            value={elements[selectedElementIndex]?.properties?.id}
                                        />
                                    </div>
                                    <div class="flex justify-between items-center gap-2 flex-wrap">
                                        <label className="flex gap-2 items-center text-xs dark:text-gray-300 cursor-pointer">
                                            <input
                                                type={'checkbox'}
                                                onChange={(e) => handlePropertyChange(selectedElementIndex, 'isRequired',e.target.checked)}
                                                checked={elements[selectedElementIndex]?.properties?.isRequired}
                                            />
                                            {t("Mandatory")}
                                        </label>
                                        <label className="flex gap-2 items-center text-xs dark:text-gray-300 cursor-pointer">
                                            <input
                                                type={'checkbox'}
                                                onChange={(e) => handlePropertyChange(selectedElementIndex, 'isReadOnly',e.target.checked)}
                                                checked={elements[selectedElementIndex]?.properties?.isReadOnly}
                                            />
                                            {t("ReadOnly")}
                                        </label>
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                                        <InputText
                                            className="input w-full"
                                            onChange={(e) => handlePropertyChange(selectedElementIndex, 'caption', e.target.value)}
                                            value={elements[selectedElementIndex]?.properties?.caption}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                        <InputText
                                            className="input w-full"
                                            onChange={(e) => handlePropertyChange(selectedElementIndex, 'description', e.target.value)}
                                            value={elements[selectedElementIndex]?.properties?.description}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("Name")}</label>
                                        <InputText
                                            className="input w-full"
                                            onChange={(e) => handlePropertyChange(selectedElementIndex, 'name', e.target.value)}
                                            value={elements[selectedElementIndex]?.properties?.name}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("DefaultValue")}</label>
                                        {
                                            elements[selectedElementIndex]?.typeName === 'CheckBox' || elements[selectedElementIndex]?.typeName === 'MultiSelect'?
                                                <Select
                                                    options={elements[selectedElementIndex]?.properties?.items || []}
                                                    onChange={(data) => handleDefaultValueChange(selectedElementIndex, "value", data)}
                                                    placeholder={t("selection")}
                                                    className={"w-full"}
                                                    isMulti={true}
                                                    menuPosition={"fixed"}
                                                    // value={
                                                    //     elements[selectedElementIndex]?.properties?.defaultValue?.value?.map(
                                                    //         selectedValue =>
                                                    //             elements[selectedElementIndex]?.properties?.items?.find(
                                                    //                 item => item.value === selectedValue
                                                    //             )
                                                    //     ).filter(Boolean) || []
                                                    // }
                                                    value={elements[selectedElementIndex]?.properties?.defaultValue?.value || []}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                                :
                                                elements[selectedElementIndex]?.typeName === 'Select' || elements[selectedElementIndex]?.typeName === 'Radio' ?
                                                    <Select
                                                        options={elements[selectedElementIndex]?.properties?.items || []}
                                                        onChange={(data) => handleDefaultValueChange(selectedElementIndex, "value", data)}
                                                        placeholder={t("selection")}
                                                        className={"w-full"}
                                                        isMulti={false}
                                                        menuPosition={"fixed"}
                                                        value={
                                                        elements[selectedElementIndex]?.properties?.items?.find(
                                                            (ex) => ex.value === elements[selectedElementIndex]?.properties?.defaultValue?.value?.value
                                                        ) || null
                                                    }
                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                    :
                                                    elements[selectedElementIndex]?.typeName === 'Price'?
                                                        <InputNumber
                                                            placeholder={elements[selectedElementIndex]?.properties?.description}
                                                            value={elements[selectedElementIndex]?.properties?.defaultValue?.value}
                                                            onChange={(e) => handleDefaultValueChange(selectedElementIndex, 'value', e.value)}
                                                            useGrouping={elements[selectedElementIndex].properties.useGrouping}
                                                        />
                                                        :
                                                        elements[selectedElementIndex]?.typeName === 'Time'?
                                                            <DatePicker
                                                                format={elements[selectedElementIndex].properties.format}
                                                                value={elements[selectedElementIndex].properties?.defaultValue?.value}
                                                                buttons={false}
                                                                disableDayPicker={true}
                                                                type={'Calendar'}
                                                                onChange={(value) => handleDefaultValueChange(selectedElementIndex, 'value', value)}
                                                                plugins={[
                                                                    <TimePicker />
                                                                ]}
                                                            />
                                                            :
                                                            elements[selectedElementIndex]?.typeName === 'Date'? <DatePicker
                                                                 calendar={handleCalendarType()}
                                                                 locale={handleCalendarLanguage()}
                                                                 editable={handleCalendarView()}
                                                                 format={elements[selectedElementIndex].properties.format}
                                                                 value={elements[selectedElementIndex].properties?.defaultValue?.value}
                                                                 type={'Calendar'}
                                                                 onChange={(value) => handleDefaultValueChange(selectedElementIndex, 'value', value)}
                                                                 plugins={[<DatePanel position="top" disabled={true} />]}
                                                                 //zIndex={props.zIndex}
                                                                 />
                                                             :
                                                             elements[selectedElementIndex]?.typeName === 'DateTime'?
                                                                 <DatePicker
                                                                     calendar={handleCalendarType()}
                                                                     locale={handleCalendarLanguage()}
                                                                     editable={handleCalendarView()}
                                                                     format={elements[selectedElementIndex].properties.format}
                                                                     value={elements[selectedElementIndex].properties?.defaultValue?.value}
                                                                     type={'Calendar'}
                                                                     onChange={(value) => handleDefaultValueChange(selectedElementIndex, 'value', value)}
                                                                     plugins={[<DatePanel position="top" disabled={true} />]}
                                                                 />
                                                                 :
                                                        elements[selectedElementIndex]?.typeName === 'File' ||   elements[selectedElementIndex]?.typeName === 'Audio' ||  elements[selectedElementIndex]?.typeName === 'Image' ||  elements[selectedElementIndex]?.typeName === 'Video' || elements[selectedElementIndex]?.typeName === 'Location'
                                                            ?
                                                            null:
                                                        elements[selectedElementIndex]?.typeName === 'Numeric'?
                                                                <InputNumber
                                                                    onChange={(e) => handleDefaultValueChange(selectedElementIndex, 'value', e.value)}
                                                                    value={elements[selectedElementIndex]?.properties?.defaultValue?.value}
                                                                    showButtons={true}
                                                                    useGrouping={false}
                                                                    min={0}

                                                                 />
                                                            :
                                                            elements[selectedElementIndex]?.typeName === 'Phone'?
                                                               <>
                                                                   <Select
                                                                       options={elements[selectedElementIndex]?.properties?.preNumbers || []}
                                                                       onChange={(data) => handleDefaultValueChange(selectedElementIndex, "preNumber", data)}
                                                                       placeholder={t("selection")}
                                                                       className={"w-full"}
                                                                       isMulti={false}
                                                                       menuPosition={"fixed"}
                                                                       value={
                                                                           elements[selectedElementIndex]?.properties?.preNumbers?.find(
                                                                               (ex) => ex.value === elements[selectedElementIndex]?.properties?.defaultValue?.preNumber?.value
                                                                           ) || null
                                                                       }
                                                                       classNamePrefix={"h_multi_select"}
                                                                   />

                                                                   <InputText
                                                                       className="input w-full"
                                                                       onChange={(e) => handleDefaultValueChange(selectedElementIndex, 'value', e.target.value)}
                                                                       value={elements[selectedElementIndex]?.properties?.defaultValue?.value}
                                                                   />

                                                               </>
                                                                :
                                                        <InputText
                                                            className="input w-full"
                                                            onChange={(e) =>handleDefaultValueChange(selectedElementIndex,'value',e.target.value)}
                                                            value={elements[selectedElementIndex]?.properties?.defaultValue?.value}
                                                            maxLength={elements[selectedElementIndex]?.properties?.max}
                                                            minLength={elements[selectedElementIndex]?.properties?.min}
                                                            readOnly={elements[selectedElementIndex]?.properties?.isReadOnly}
                                                        />
                                                }
                                            </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("ClassName")}</label>
                                        <InputText
                                            className="input w-full"
                                            onChange={(e) => handlePropertyChange(selectedElementIndex, 'className', e.target.value)}
                                            value={elements[selectedElementIndex]?.properties?.className}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("Size")}</label>
                                        <Select
                                            options={initialSource?.sizeParameters === undefined?[]:initialSource?.sizeParameters}
                                            onChange={(data) => handlePropertyChange(selectedElementIndex, "size", data)}
                                            placeholder={t("selection")}
                                            className={"w-full"}
                                            menuPosition={"fixed"}
                                            value={elements[selectedElementIndex]?.properties?.size}
                                            classNamePrefix={"h_multi_select"}
                                        />

                                    </div>

                                </div>
                                        {/*<div class="space-y-1">*/}
                                        {/*    <label className="text-xs dark:text-gray-300">{t("Pattern")}</label>*/}
                                        {/*    <Select*/}
                                        {/*        menuPosition={"fixed"}*/}
                                        {/*        placeholder={t("selection")}*/}
                                        {/*        classNamePrefix={"h_multi_select"}*/}
                                        {/*    />*/}
                                        {/*    /!*<select className="select2 w-full">*!/*/}
                                        {/*    /!*    <option value="1">کوچک</option>*!/*/}
                                        {/*    /!*    <option value="2">متوسط</option>*!/*/}
                                        {/*    /!*    <option value="3">بزرگ</option>*!/*/}
                                        {/*    /!*</select>*!/*/}
                                        {/*</div>*/}
                                        {/*<div class="space-y-1">*/}
                                        {/*    <label className="text-xs dark:text-gray-300">{t("type")}</label>*/}
                                        {/*    <Select*/}
                                        {/*        menuPosition={"fixed"}*/}
                                        {/*        placeholder={t("selection")}*/}
                                        {/*        classNamePrefix={"h_multi_select"}*/}
                                        {/*    />*/}
                                        {/*    /!*<select className="select2 w-full">*!/*/}
                                        {/*    /!*    <option value="1">کوچک</option>*!/*/}
                                        {/*    /!*    <option value="2">متوسط</option>*!/*/}
                                        {/*    /!*    <option value="3">بزرگ</option>*!/*/}
                                        {/*    /!*</select>*!/*/}
                                        {/*</div>*/}
                                    </>:null

                            }

                        </AccordionTab>
                    </Accordion>

            <SelectedElementProperties
                selectedElementIndex={selectedElementIndex}
                elements={elements}
                fields={fields}
                handlePropertyChange={handlePropertyChange}
                deleteCheckBoxItem={deleteCheckBoxItem}
                addCheckBoxItem={addCheckBoxItem}
                handleItemPropertyChange={handleItemPropertyChange}
                handleDefaultValueChange={handleDefaultValueChange}
                t={t}
            />



            {/*پاراگراف*/}

            {/*انتخاب چند گزینه*/}

            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2  ">*/}
            {/*    <div class="flex items-center gap-2  box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>{t("MultipleChoice")}</p>*/}
            {/*    </div>*/}
            {/*    <div class="space-y-2">*/}
            {/*        <div class="can-toggle demo-rebrand-1 text-xs w-36">*/}
            {/*            <input id="swich" type="checkbox"/>*/}
            {/*            <label htmlFor="swich">*/}
            {/*                <div class="can-toggle__switch" data-checked={t("Simple")} data-unchecked={t("DataSource")}></div>*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*        <div className={"max_height20 space-y-2"}>*/}
            {/*            <div className="flex gap-2 ">*/}
            {/*                <div className="number_pluse text-white">*/}
            {/*                    1*/}
            {/*                </div>*/}
            {/*                <div class="flex-1 space-y-1">*/}
            {/*                    <div className="flex items-center gap-2">*/}
            {/*                        <input type="radio"></input>*/}
            {/*                        <InputText className="input w-full" placeholder={t("title")}/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="flex gap-2">*/}
            {/*                    <button type={"button"} className="button bg-themeInformation dark:bg-themeDarkInformation text-white">*/}

            {/*                    </button>*/}
            {/*                    <button type={"button"} className="button bg-themeDanger  text-white">*/}

            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        /!*در صورت عوض شدن بالا نمایش و مخفی میشود*!/*/}
            {/*        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2"}>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("DataSource")}</label>*/}
            {/*                <Select*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("DisplayText")}</label>*/}
            {/*                <Select*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("Value")}</label>*/}
            {/*                <Select*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*انتخاب چند گزینه*/}

            {/*انتخاب یک گزینه*/}

            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2  ">*/}
            {/*    <div class="flex items-center gap-2  box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>{t("AnOption")}</p>*/}
            {/*    </div>*/}
            {/*    <div class="space-y-2">*/}
            {/*        <div class="can-toggle demo-rebrand-1 text-xs w-36">*/}
            {/*            <input id="swich2" type="checkbox"/>*/}
            {/*            <label htmlFor="swich2">*/}
            {/*                <div class="can-toggle__switch" data-checked={t("Simple")} data-unchecked={t("DataSource")}></div>*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*        <div className=" max_height20 space-y-2">*/}
            {/*            <div className="flex gap-2 ">*/}
            {/*                <div className="number_pluse text-white">*/}
            {/*                    1*/}
            {/*                </div>*/}
            {/*                <div class="flex-1 space-y-1">*/}
            {/*                    <div className="flex items-center gap-2">*/}
            {/*                        <input type="radio" ></input>*/}
            {/*                        <InputText className="input w-full" placeholder={t("title")}/>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="flex gap-2">*/}
            {/*                    <button type={"button"} className="button bg-themeInformation dark:bg-themeDarkInformation text-white">*/}

            {/*                    </button>*/}
            {/*                    <button type={"button"} className="button bg-themeDanger  text-white">*/}

            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        /!*در صورت عوض شدن بالا نمایش و مخفی میشود*!/*/}
            {/*        <div className={"grid grid-cols-1 md:grid-cols-3 gap-2"}>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("DataSource")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("DisplayText")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("type")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*انتخاب یک گزینه*/}

            {/*تاریخ*/}

            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2  ">*/}
            {/*    <div class="flex items-center gap-2 box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>{t("Date")}</p>*/}
            {/*    </div>*/}
            {/*    <div className=" space-y-2">*/}
            {/*        <div class="can-toggle demo-rebrand-1 text-xs w-36">*/}
            {/*            <input id="swich3" type="checkbox"/>*/}
            {/*            <label htmlFor="swich3">*/}
            {/*                <div class="can-toggle__switch" data-checked={t("Simple")} data-unchecked={t("DataSource")}></div>*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*        <div className={" max_height20"}>*/}
            {/*            <div className={"grid grid-cols-1 md:grid-cols-3 gap-2"}>*/}
            {/*                <div class="space-y-1">*/}
            {/*                    <label className="text-xs dark:text-gray-300">{t("DataSource")}</label>*/}
            {/*                    <Select*/}
            {/*                        placeholder={t("selection")}*/}
            {/*                        classNamePrefix={"h_multi_select"}*/}
            {/*                        menuPosition={"fixed"}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div class="space-y-1">*/}
            {/*                    <label className="text-xs dark:text-gray-300">{t("DisplayText")}</label>*/}
            {/*                    <Select*/}
            {/*                        placeholder={t("selection")}*/}
            {/*                        classNamePrefix={"h_multi_select"}*/}
            {/*                        menuPosition={"fixed"}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*                <div class="space-y-2">*/}
            {/*                    <label className="text-xs dark:text-gray-300">{t("value")}</label>*/}
            {/*                    <Select*/}
            {/*                        placeholder={t("selection")}*/}
            {/*                        classNamePrefix={"h_multi_select"}*/}
            {/*                        menuPosition={"fixed"}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div class="grid grid-cols-1 md:grid-cols-2 gap-3  ">*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("DisplayFormat")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("CalendarType")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("CalendarLanguage")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("DisplayMode")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*تاریخ*/}

            {/*انتخاب از لیست*/}

            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">*/}
            {/*    <div class="flex items-center gap-2 box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>{t("SelectFromTheList")}</p>*/}
            {/*    </div>*/}
            {/*    <div class="space-y-2">*/}
            {/*        <div class="can-toggle demo-rebrand-1 text-xs w-36">*/}
            {/*            <input id="swich4" type="checkbox"/>*/}
            {/*            <label htmlFor="swich4">*/}
            {/*                <div class="can-toggle__switch" data-checked={t("Simple")} data-unchecked={t("DataSource")}></div>*/}
            {/*            </label>*/}
            {/*        </div>*/}
            {/*        <div className="max_height20  space-y-2">*/}
            {/*            <label className="flex items-center gap-2 cursor-pointer text-xs dark:text-gray-300">*/}
            {/*                <input type="checkbox"/>*/}
            {/*                    {t("TheFirstOptionEmpty")}*/}
            {/*            </label>*/}
            {/*            <div className="flex gap-2">*/}
            {/*                <div className="number_pluse text-white">*/}
            {/*                    1*/}
            {/*                </div>*/}
            {/*                <div class="flex-1">*/}
            {/*                    <InputText className="input w-full" placeholder={t("Title")}/>*/}
            {/*                </div>*/}
            {/*                <div className="flex gap-2">*/}
            {/*                    <button type={"button"} className="button bg-themeInformation dark:bg-themeDarkInformation text-white">*/}

            {/*                    </button>*/}
            {/*                    <button type={"button"} className="button bg-themeDanger text-white">*/}

            {/*                    </button>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className={"grid grid-cols-1 md:grid-cols-3 gap-2"}>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("DataSource")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("DisplayText")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div class="space-y-1">*/}
            {/*                <label className="text-xs dark:text-gray-300">{t("Value")}</label>*/}
            {/*                <Select*/}
            {/*                    placeholder={t("selection")}*/}
            {/*                    classNamePrefix={"h_multi_select"}*/}
            {/*                    menuPosition={"fixed"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*انتخاب از لیست*/}

            {/*ساعت*/}

            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">*/}
            {/*    <div class="flex items-center gap-2 box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>{t("hour")}</p>*/}
            {/*    </div>*/}
            {/*    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("Format")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*ساعت*/}

            {/*عددی*/}

            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">*/}
            {/*    <div class="flex items-center gap-2 box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>{t("Numeric")}</p>*/}
            {/*    </div>*/}
            {/*    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*عددی*/}


            {/*رنج*/}
            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">*/}
            {/*    <div class="flex items-center gap-2 box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>رنج</p>*/}
            {/*    </div>*/}
            {/*    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>*/}
            {/*            <InputText className="input  w-full"/>*/}
            {/*        </div>*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*رنج*/}


            {/*آدرس*/}

            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">*/}
            {/*    <div class="flex items-center gap-2 box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>{t("Address")}</p>*/}
            {/*    </div>*/}
            {/*    <div class="flex items-center gap-2 p-2">*/}
            {/*        <input type="checkbox"/>*/}
            {/*        <div>*/}
            {/*            <h2 className={"text-xs dark:text-gray-300"}>{t("ShowOnTheMapDefaultLocation")}</h2>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div class="grid grid-cols-1 md:grid-cols-2 p-2 gap-2">*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("Length")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("Width")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*آدرس*/}

            {/*فایل*/}

            {/*<div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ">*/}
            {/*    <div class="flex items-center gap-2 box bg-white dark:bg-themeDarkSalt700 p-2 text-xs dark:text-gray-300">*/}
            {/*        <p>{t("File")}</p>*/}
            {/*    </div>*/}
            {/*    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">*/}
            {/*        <div class="space-y-1 md:col-span-2">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("StorageLocation")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("MinimumSize")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*        <div class="space-y-1">*/}
            {/*            <label className="text-xs dark:text-gray-300">{t("MaximumSize")}</label>*/}
            {/*            <InputText className="input w-full"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*فایل*/}


        </>
    )
}