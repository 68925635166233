import React, {useEffect, useRef,useContext, useState} from "react";

import {FileContext} from "./FileProvider";
import {
    BrowserDownloader,
    DeleteDirectoryHandler, DeleteDirectoryLogically,
    DeleteFileHandler, DeleteFileLogically

} from "../../../ApiServices/Public/FtpController";
import {useNavigate} from "react-router-dom";
import { handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";

import Swal from "sweetalert2";
import {AlertSetting, fileManager} from "../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {ExceptionHandlerService} from "../../../Services/Globals/Errors/ExceptionHandlerService";
import {useTranslation} from "react-i18next";
import {decodeAccessToken} from "../../../Services/AxiosService/JwtService";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
export const FileDisplayer = (props)=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [disable,setDisable] = useState(true);
    const [name,setName] = useState('');

    const menuOptionRef = useRef(null);
    const [showMenuOption,SetMenuOption] = useState(false);
    const inputRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (menuOptionRef.current && !menuOptionRef.current.contains(event.target)) {
                SetMenuOption(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [disable]);


    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setDisable(true);
            }
        };
        document.addEventListener("mousedown", handleDocumentClick);
        return () => {
            document.removeEventListener("mousedown", handleDocumentClick);
        };
    }, [disable]);




    // ....

    const onApiError=()=>{
        setSpinner(false);
    }


    //....

    //.....


    // برای کار هریک به fileProvider  مراجعه کنید
    const {clipBoard,setClipBoard,setPath,path,setResult,setDestination,setVisible,visible,setFileCollectionName,setAction,setSpinner,setMission} = useContext(FileContext)

    //برای دانلود فایل هست

    // const handleDownloadFile =async(name,url)=>{
    //     try {
    //         const filePath = path+fileManager.separator+name;
    //         const response = await DownloadFile(filePath,name,url);
    //         if(response.status === 200){
    //             handleSuccessMessage('فایل با موفقیت دانلود شد');
    //         }
    //         else{
    //             handleApiError(response,onApiError)
    //         }
    //     }catch (e) {
    //         console.log(e)
    //     }
    // }
    //


    const handleDownloadFile=async(name,url)=>{
        const filePath = path+fileManager.separator+name;
        BrowserDownloader(filePath,url)
    }








    // ....
    const copyContent = async (text)=>{
        await navigator.clipboard.writeText(text);
        setClipBoard(text);
        handleSuccessMessage(t("Yourlinkhasbeencopied"));
    }

    // برای اشتراک گذاری فایل یا فولدر استفاده میشه
    // مسیره روت با مسیره جاری الصاق و داخل clipboard کپی میشع
    const ShareFileHandler = async (name)=>{
        SetMenuOption(false);
        const token = decodeAccessToken();
        const {DomainToken,MasterDomain} = token;
        const filePath = path +fileManager.separator+ name;
        const hostUrl =  filePath.replace(/\+/g, '/').replace(/\//g, '/');
        const url = `https://host.${MasterDomain}/${DomainToken}/${hostUrl}`;
        await copyContent(url);
    }

    // ی سری محتوا ها برای کلیپ بورد هندل میکنه
    //
    const clipboardHandler = async(name,isDirectory,opt)=>{
        SetMenuOption(false)
        if(opt === 'move'){
            const filePath = path+fileManager.separator+ name;
            setFileCollectionName(name);
            //await copyContent(filePath);
            setClipBoard(filePath)
            setDestination(isDirectory?'Directory':'File');
            setAction(opt);
            setMission(opt)
        }
        //copy
        else{
            const filePath = path+fileManager.separator+ name;
            setFileCollectionName(name);
            //await copyContent(filePath);
            setClipBoard(filePath)
            setDestination(isDirectory?'Directory':'File');
            setAction(opt)
            setMission(opt)
        }
    }

    // مودال confirm delete رو برای سناریو حذف لود میکنه
    const DeleteApiHandler = async (name,isDirectory)=>{


        if(path.includes(`${fileManager.separator}Trash`)){
            const TailWindButton = Swal.mixin({
                customClass: {
                    confirmButton: AlertSetting.customClass.confirmButton,
                    cancelButton: AlertSetting.customClass.cancelButton
                },
                buttonsStyling: false
            })
            TailWindButton.fire({
                title:AlertSetting.TailWindButton.title,
                text: AlertSetting.TailWindButton.text,
                icon:AlertSetting.TailWindButton.icon,
                showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                reverseButtons: AlertSetting.TailWindButton.reverseButtons
            }).then( async (result) =>{
                if (result.isConfirmed) {
                    setSpinner(true)
                    if(isDirectory){
                        try {
                            const filePath = path+fileManager.separator+name;
                            const response = await DeleteDirectoryHandler(filePath);
                            if(response.status === 200){
                                setSpinner(false)
                                handleSuccessMessage(t("Filesuccessfullydeleted"));
                                setResult({ ...response.status });
                            }
                            else{
                                handleApiError(response,onApiError);
                            }
                        }catch (error) {
                            return ExceptionHandlerService(error);
                        }
                    }
                    else{
                        try {
                            const filePath = path+fileManager.separator+name;
                            const response = await DeleteFileHandler(filePath);
                            if(response.status === 200){
                                setSpinner(false)
                                handleSuccessMessage(t("Filesuccessfullydeleted"));
                                setResult({ ...response.status });
                            }
                            else{
                                handleApiError(response,onApiError);
                            }
                        }catch (error) {
                            return ExceptionHandlerService(error);
                        }
                    }
                }
                else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    return;
                }
            })
        }
        else{
            const TailWindButton = Swal.mixin({
                customClass: {
                    confirmButton: AlertSetting.customClass.confirmButton,
                    cancelButton: AlertSetting.customClass.cancelButton
                },
                buttonsStyling: false
            })
            TailWindButton.fire({
                title:AlertSetting.TailWindButton.title,
                text: AlertSetting.TailWindButton.text,
                icon:AlertSetting.TailWindButton.icon,
                showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                reverseButtons: AlertSetting.TailWindButton.reverseButtons
            }).then( async (result) =>{
                if (result.isConfirmed) {
                    setSpinner(true)
                    if(isDirectory){
                        try {
                            const filePath = path+fileManager.separator+name;
                            const response = await DeleteDirectoryLogically(filePath);
                            if(response.status === 200){
                                setSpinner(false)
                                handleSuccessMessage(t("Filesuccessfullydeleted"));
                                setResult({ ...response.status });
                            }
                            else{
                                handleApiError(response,onApiError);
                            }
                        }catch (error) {
                            return ExceptionHandlerService(error);
                        }
                    }
                    else{
                        try {
                            const filePath = path+fileManager.separator+name;
                            const response = await DeleteFileLogically(filePath);
                            if(response.status === 200){
                                setSpinner(false)
                                handleSuccessMessage(t("Filesuccessfullydeleted"));
                                setResult({ ...response.status });
                            }
                            else{
                                handleApiError(response,onApiError);
                            }
                        }catch (error) {
                            return ExceptionHandlerService(error);
                        }
                    }
                }
                else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    return;
                }
            })
        }
    }


    //بازیابی فایل های حذف شده
    const restore= async (name)=>{
        try {
            setSpinner(true);
            const filePath = path+fileManager.separator+name;
            const response = await POST(`/Ftp/RestoreFileAndFolder`,{path:filePath},false);
            if(response.status === 200){
                   handleSuccessMessage(t("Filessuccessfullyrecovered"));
                   setResult({ ...response.status });
                   setSpinner(false);
                   SetMenuOption(!showMenuOption);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }




    //هندل کردن سناریو تغییر نام فایل یا فولدر



    const renameHandler = async (name)=>{
        SetMenuOption(false)
        setDisable(prevDisable => !prevDisable);
        setName(name);
        const filePath = path+fileManager.separator+name;
        console.log();
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
                inputRef.current.select();
            }
        }, 0);
        // await navigator.clipboard.writeText(filePath);
        setClipBoard(filePath)
        //await copyContent(filePath)
    }
    const renameApiHandler = async (isDirectory)=>{
        if(isDirectory){
            try {
                // const content = await navigator.clipboard.readText();
                // const response = await RenameDirectoryHandler(clipBoard,name);
                const response = await PUT('/Ftp/Directory',{ path: clipBoard,newName:name },false);
                if(response.status === 200){
                    setDisable(prevDisable => !prevDisable);
                    handleSuccessMessage(t("Filenamesuccessfullychanged"));
                    setResult({ ...response.status });
                }
                else{
                    handleApiError(response,onApiError);
                }
            }catch (e) {
                console.log(e);
            }
        }
        else{
            try {
                // const content = await navigator.clipboard.readText();
                // const response = await RenameFileHandler(clipBoard,name);
                const response = await PUT('/Ftp/File',{ path: clipBoard,newName:name },false);

                if(response.status === 200){
                    setDisable(prevDisable => !prevDisable);
                    handleSuccessMessage(t("Filenamesuccessfullychanged"));
                    setResult({ ...response.status });
                }
                else{
                    handleApiError(response,onApiError);
                }
            }catch (e) {
                console.log(e);
            }
        }
    }

    //فشرده سازی فایل یافولدر
    const handleCheckFileExists = async(name)=>{
        Swal.fire({
            title: t("Selectoneofthefollowingoperations"),
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: t("rename"),
            denyButtonText: t("Replace"),
            cancelButtonText:t("Reject")
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                const response = await POST('/Ftp/Zip',{path:name,exists:0},false)
                if(response.status === 200){
                    handleSuccessMessage(t("Thecompressedfilewascreatedsuccessfully"));
                    setResult({ ...response.status });
                    return;
                }
            } else if (result.isDenied) {
                const response = await POST('/Ftp/Zip',{path:name,exists:1},false)
                if(response.status === 200){
                    handleSuccessMessage(t("Thecompressedfilewascreatedsuccessfully"));
                    setResult({ ...response.status });
                    return;
                }else {
                    handleApiError(response,onApiError)
                }
            }
            else{
                const response = await POST('/Ftp/Zip',{path:name,exists:2},false)
                if(response.status === 200){
                    handleSuccessMessage(t("Thecompressedfilewascreatedsuccessfully"));
                    setResult({ ...response.status });
                    return;
                }else{
                    handleApiError(response,onApiError)

                }
            }
        })
    }
    const ZipHandler = async(name)=>{
        try {
            SetMenuOption(false);
            const newPath = name.replace('.txt','.zip');
            //const checkPath = [`${path +fileManager.separator+newPath}`];
            const filePath = path+fileManager.separator+name;
           // const existResp = await POST('/Ftp/Files/Exist',{path:[checkPath]},false);
           // if(existResp.status === 200){
           //      if(existResp.data[0].isExisted){
           //          await handleCheckFileExists(filePath);
           //      }
               // else{
                    const response = await POST('/Ftp/Zip',{path:filePath,exists:0},false)
                    if(response.status === 200){
                        setResult({ ...response.status });
                        return;
                    }
                    else{
                        handleApiError(response,onApiError);
                    }
            //    }
       //     }
        }catch (e) {
            console.log(e);
        }
    }






    //فایل های فشرده رو از حالت فشرده استخراج و unzip  میکنه
    const UnZipHandler = async(name)=>{
        try {
            SetMenuOption(false);
            const filePath = path+fileManager.separator+name;
            const response = await POST('/Ftp/Unzip',{sourcePath:filePath},false)
            if(response.status === 200){
                handleSuccessMessage(t("Yourfilehasbeensuccessfullyextracted"));
                setResult({ ...response.status });
                return;
            }else{
                handleApiError(response,onApiError);
            }
        }catch (e) {
            console.log(e);
        }
    }














// فراخوانی  api که جزییات فایل یا فولدر بگیره
    const DetailHandlerApi = async(isDirectory,name)=>{
        SetMenuOption(false);
        setSpinner(true);
        if(isDirectory){
            try {
                const filePath = path+fileManager.separator+name;
                const response = await GET('/Ftp/Directory', {Path:filePath},'single')
                if(response.status === 200){
                    setSpinner(false);
                    setVisible(!visible);
                    props.handleDetail(response.data);
                }
            }catch (e) {
                console.log(e);
            }
        }
        else{
            try {
                const filePath = path+fileManager.separator+name;
                const response = await  GET('/Ftp/File', {Path:filePath},'single')
                if(response.status === 200){
                    setSpinner(false);
                    setVisible(!visible);
                    props.handleDetail(response.data);
                }
            }catch (e) {
                console.log(e);
            }
        }
    }

    const handleOnlineEditor=(name,extension)=>{
        const p = path.replace(fileManager.separator,'');
        const filePath = p+'/'+name;
        const modifiedFilePath = filePath.replace(/\+/g, '/');
        redirectTo(`/OnlineEditor?filePath=${modifiedFilePath}&extension=${extension}`);
    }

    const Round=(size)=>{
         const unitOfMg = 1048576;
        if(size < unitOfMg) {
            var result = size / 1024;
            return parseFloat(result.toFixed(1))+'KB';
        }
        else{
            var result = (size / unitOfMg);
            return parseFloat(result.toFixed(1))+'MB';
        }
    }

    return (

        <div  className=" hfile_uplader -h-intro-x border-2 hover:bg-themeHoverFileUploader post-input post-input-design dark:bg-themeDarkPostInput dark:border-themeDarkColorLine" style={{ animationDelay: `${(props.index + 1) * 0.05}s` }}  key={props.key} ref={menuOptionRef}>
            <div className="file py-8 px-3 sm:px-5 relative ">
                <div className="absolute top-2 start-2 dropdown">
                    <button type={"button"}  onClick={()=>SetMenuOption(!showMenuOption)}>
                        <HPrimeIconSmall
                        icon={"pi-ellipsis-v"}
                        iconClass={"text-gray-500 dark:text-gray-300"}
                        />
                    </button>

                    <div  className={`dropdown-box mt-5 absolute w-40 top-0 start-0 z-10  ${showMenuOption? 'show' : ''}`} >

                        <div className="dropdown-box__content box p-2 bg-themeInformation dark:bg-themeDarkSalt700 space-y-2 border dark:border-themeDarkColorLine">
                            <button type={"button"} onClick={()=>clipboardHandler(props.name,!!props.isDirectory,'move')} className=" flex items-center w-full gap-3 p-2 text-white dark:text-gray-300 transition duration-300 ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                <HPrimeIconSmall
                                icon={"pi-clipboard"}
                                />

                                {t('cut')}
                            </button>
                            <button type={"button"} onClick={()=>clipboardHandler(props.name,!!props.isDirectory,'copy')} className=" flex items-center w-full gap-3 p-2 text-white dark:text-gray-300 transition duration-300 ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                <HPrimeIconSmall
                                icon={"pi-copy"}
                                />
                                {t('copy')}
                            </button>

                            <button type={"button"}  onClick={()=>renameHandler(props.name)} className=" flex items-center gap-3 p-2 text-white dark:text-gray-300 transition w-full duration-300 ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                <HPrimeIconSmall
                                icon={"pi-pencil"}
                                />
                                {t('rename')}
                            </button>
                            {
                                props.extension === '.zip'?
                                    <div  onClick={()=>UnZipHandler(props.name)} className=" flex items-center gap-3 p-2 text-white dark:text-gray-300 transition duration-300 w-full ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                        <HPrimeIconSmall
                                        icon={"pi-box"}
                                        />
                                        {t('extract')}
                                    </div>:
                                    null
                            }

                            {
                                props.extension !== '.zip'?
                                    <button type={"button"}  onClick={()=>ZipHandler(props.name)} className=" flex items-center gap-3 p-2 text-white dark:text-gray-300 transition duration-300 w-full ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                        <HPrimeIconSmall
                                            icon={"pi-box"}
                                        />

                                        {t('compress')}
                                    </button>:
                                    null
                            }



                            <button type={"button"} onClick={()=>ShareFileHandler(props.name)} className=" flex items-center gap-3 p-2 text-white dark:text-gray-300 transition duration-300 w-full ease-in-out rounded-md hover:bg-themeHoverInformation">
                                <HPrimeIconSmall
                                icon={"pi-share-alt"}
                                />

                                {t('Share')}
                            </button>


                            <button type={"button"} onClick={()=>DeleteApiHandler(props.name, !!props.isDirectory)}  className=" flex items-center gap-3 p-2 text-white dark:text-gray-300 w-full transition duration-300 ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                <HPrimeIconSmall
                                icon={"pi-trash"}

                                />
                                {t("delete")}
                            </button>

                            {
                                path.includes(`${fileManager.separator}Trash`)?
                                    <button type={"button"} onClick={()=>restore(props.name)}  className=" flex items-center gap-3 p-2 text-white dark:text-gray-300 transition duration-300 w-full ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                        <HPrimeIconSmall
                                        icon={"pi-sync"}
                                        />
                                        {t("Recovery")}
                                    </button>:
                                    null
                            }

                            <button type={"button"}  onClick={()=>DetailHandlerApi(!!props.isDirectory,props.name)} className=" flex items-center gap-3 p-2 text-white dark:text-gray-300 w-full transition duration-300 ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                <HPrimeIconSmall
                                icon={"pi-info-circle"}
                                />
                                {t("details")}
                            </button>
                            {
                                !props.isDirectory && props.extension === '.html' || props.extension === '.js' || props.extension === '.css' || props.extension === '.txt'?
                                    <button type={"button"}  onClick={()=>handleOnlineEditor(props.name,props.extension)} className=" flex items-center gap-3 p-2 text-white dark:text-gray-300 transition duration-300 ease-in-out rounded-md hover:bg-themeHoverInformation ">
                                        <HPrimeIcon iconClass="text-white " icon={'pi-file-check'} />{t("onlineEditor")}
                                    </button>:
                                    null
                            }

                        </div>
                    </div>
                </div>

                <div  className={'absolute top-2 end-2 cursor-pointer '}>
                    {
                        !props.isDirectory?
                            <HPrimeIconSmall
                            icon={"pi-download"}
                            onClick={()=>handleDownloadFile(props.name,'/Ftp/DownloadFile')}
                            iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                            />
                            :
                            <HPrimeIconSmall
                                icon={"pi-download"}
                                onClick={()=>handleDownloadFile(props.name,'/Ftp/DownloadFolder')}
                                iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                            />

                    }
                </div>

                {
                    props.isDirectory ? (
                        <div onClick={() => setPath(prevState => prevState + fileManager.separator + props.name)} className={'cursor-pointer w-3/5 file__icon file__icon--directory mx-auto'}>
                            <div className="file__icon__file-name"></div>
                        </div>
                    ) : (
                        (fileManager.permittedFiles.includes(props.extension.replace('.', ''))) ? (
                            <div className={`cursor-pointer w-3/5 file__icon file__icon--${props.extension.replace('.', '')} mx-auto`}>
                                <div className="file__icon__file-name"></div>
                            </div>
                        ) : (
                            <div className={` cursor-pointer w-3/5 file__icon file__icon--none mx-auto`}>
                                <div className=" cursor-pointer file__icon__file-name"></div>
                            </div>
                        )
                    )
                }

                <div className="block  mt-4 text-center truncate">

                    {disable ? (
                        <span className="dark:text-gray-300 block text-center">{props.name}</span>
                    ) : (
                        <input
                            type="text"
                            className="w-full text-center input"
                            value={name}
                            ref={inputRef}
                            onChange={(e) => setName(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    renameApiHandler(!!props.isDirectory);
                                } else if (e.key === 'Escape') {
                                    setDisable(true);
                                }
                            }}
                        />
                    )}

                </div>

                {
                    props.isDirectory ?
                        null
                        :
                        <span className="text-themeInformation text-xs text-center block">{Round(props.size)}</span>
                }


            </div>
        </div>
    )
}