import { useState, useEffect } from "react";
export const UseRememberMe=()=>{
    const [rememberMe, setRememberMe] = useState(false);
    const [savedCredentials, setSavedCredentials] = useState(null);


    useEffect(() => {
        const savedUsername = localStorage.getItem('rememberedUsername');
        const savedPassword = localStorage.getItem('rememberedPassword');

        if (savedUsername) {
            setSavedCredentials({ username: savedUsername, password: savedPassword });
            setRememberMe(true);
        }
    }, []);

    const handleRememberMe = () => {
        setRememberMe(!rememberMe);
    };

    const saveCredentials = (username, password) => {
        if (rememberMe) {
            localStorage.setItem('rememberedUsername', username);
            localStorage.setItem('rememberedPassword', password);
        } else {
            localStorage.removeItem('rememberedUsername');
            localStorage.removeItem('rememberedPassword');
        }
    };

    return {
        rememberMe,
        handleRememberMe,
        saveCredentials,
        savedCredentials
    };
}