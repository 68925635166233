

export const convertTemplateMaster = (obj,id)=>{
    const newData = {
        Id:id !== null?id:null,
        Name:obj.Name === null || obj.Name === undefined?null:obj.Name,
        DemoUrl:obj.DemoUrl === null || obj.DemoUrl === undefined?"#":obj.DemoUrl,
        Type: obj.Type === null || obj.Type === undefined?null: obj.Type.value,
        StateShow: obj.StateShow === null || obj.StateShow === undefined?null: obj.StateShow.value,
        RootFolder:obj.RootFolder === null || obj.RootFolder === undefined?null:obj.RootFolder,
        Price:obj.Price,
        Banner:obj.Banner === null || obj.Banner === undefined ?null:obj.Banner,
        BannerPath:obj.BannerPath === null || obj.BannerPath === undefined ?null:obj.BannerPath,
        File:obj.File === null || obj.File === undefined?null:obj.File,
        Keywords:obj.Keywords
    };
    return newData
}

export const convertAddTemplateMaster = (obj)=>{
    const newData = {
        Name:obj.Name === null || obj.Name === undefined?null:obj.Name,
        DemoUrl:obj.DemoUrl === null || obj.DemoUrl === undefined?"#":obj.DemoUrl,
        Type: obj.Type === null || obj.Type === undefined?null: obj.Type.value,
        StateShow: obj.StateShow === null || obj.StateShow === undefined?null: obj.StateShow.value,
        RootFolder:obj.RootFolder === null || obj.RootFolder === undefined?null:obj.RootFolder,
        Price:obj.Price,
        Banner:obj.Banner === null || obj.Banner === undefined ?null:obj.Banner,
        File:obj.File === null || obj.File === undefined?null:obj.File,
        Keywords:obj.Keywords
    };
    return newData
}

export const convertAddTemplatePage = (obj,templateMasterId)=>{
    const newData = {
        name:obj.name === null || obj.name === undefined ? null:obj.name,
        type:obj.type === null || obj.type ===undefined?null:obj.type.value,
        stateShow: obj.stateShow,
        slug:obj.slug === null || obj.slug === undefined?null:obj.slug,
        fileName:obj.fileName === null || obj.fileName === undefined ? null : obj.fileName,
        isMain:obj.isMain.value === 0?true:false,
        templateMasterId:templateMasterId,
        masterLayoutId: obj.masterLayoutId === null || obj.masterLayoutId === undefined? null: obj.masterLayoutId.value,
        direction: obj.direction === null || obj.direction === undefined? null: obj.direction.value,

    };
    return newData
}

export const convertEditTemplatePage = (obj,templateMasterId,id)=>{
    const newData = {
        id:id,
        name:obj.name === null || obj.name === undefined ? null:obj.name,
        type:obj.type === null || obj.type ===undefined?null:obj.type.value,
        stateShow: obj.stateShow,
        slug:obj.slug === null || obj.slug === undefined?null:obj.slug,
        fileName:obj.fileName === null || obj.fileName === undefined ? null : obj.fileName,
        isMain:obj.isMain.value === 0?true:false,
        templateMasterId:templateMasterId,
        masterLayoutId: obj.masterLayoutId === null || obj.masterLayoutId === undefined? null: obj.masterLayoutId.value,
        direction: obj.direction === null || obj.direction === undefined? null: obj.direction.value,
    };
    return newData
}

export const createTemplateFormData = (obj) => {
    const formData = new FormData();
    for (const key in obj) {
        if (obj[key] !== null && obj[key] !== undefined) {
            if (key === "DiscountFrom" || key === "DiscountTo") {
                const date = new Date(obj[key]).toISOString();
                formData.append(key, date);
            } else if (key === 'Keywords' && Array.isArray(obj.Keywords) && obj.Keywords.length > 0) {
                obj.Keywords.forEach((keyword, index) => {
                    formData.append(`Keywords[${index}]`, keyword);
                });
            } else {
                formData.append(key, obj[key]);
            }
        }
    }
    return formData;
}
