import React, {useState} from "react";
import {InputText} from "primereact/inputtext";


import {Button} from "primereact/button";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const HandleAddAccount = () => {
  const { t } = useTranslation();

  return(
      <div className=" space-y-5">
        <div class="space-y-2">
          <div class="flex gap-2 items-center box bg-white dark:bg-themeDarkSalt700 py-3">
            <div class="px-3 dark:text-gray-300">نوع حساب:</div>
            <div class="pos">
              <div class="pos__tabs nav-tabs justify-center flex post-input dark:bg-themeDarkSalt600 px-2 py-1 rounded">
                <a data-toggle="tab" data-target="#tab_one" href="javascript:;" className="lg:w-24 px-3 py-2 rounded-md flex justify-center items-center dark:text-gray-300 active">بانک</a>
                <a data-toggle="tab" data-target="#tab_two" href="javascript:;" className="lg:w-24 px-3 py-2 rounded-md flex justify-center items-center dark:text-gray-300 ">تنخواه گردان</a>
                <a data-toggle="tab" data-target="#tab_two" href="javascript:;" className="lg:w-24 px-3 py-2 rounded-md flex justify-center items-center dark:text-gray-300">صندوق</a>
              </div>
            </div>
          </div>
          <div className="post__content tab-content ">
            <div className="tab-content__pane active p-1 md:p-3 space-y-5" id="tab_one">
              <div className="grid grid-cols-12 gap-4 ">
                <div className="col-span-12 lg:col-span-8 p-2 box bg-white dark:bg-themeDarkSalt700 ">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                      <div className=" space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                        <div class="flex items-center gap-2">
                            <label className="text-xs dark:text-gray-300">کد محصول:</label>
                            <div class="can-toggle demo-rebrand-1 text-xs">
                          <input id="swich" type="checkbox"/>
                          <label htmlFor="swich">
                            <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                          </label>
                        </div>
                          </div>
                        <div>
                        <input className="p-inputtext p-component input w-full input " type="text" data-pc-name="inputtext" data-pc-section="root"/>
                      </div>
                      </div>
                      <div className=" space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                        <label className="text-xs dark:text-gray-300">نام بانک:</label>
                        <InputText type={"text"} className={"input w-full"} />
                      </div>
                      <div className=" space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                        <label className="text-xs dark:text-gray-300">شماره حساب:</label>
                        <InputText type={"text"} className={"input w-full"} />
                      </div>
                      <div className=" space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                        <label className="text-xs dark:text-gray-300">شماره کارت:</label>
                        <InputText type={"text"} className={"input w-full"} />
                      </div>
                      <div className=" space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                        <label className="text-xs dark:text-gray-300"> شماره شبا:</label>
                        <InputText type={"text"} className={"input w-full"} />
                      </div>
                      <div className=" space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                        <label className="text-xs dark:text-gray-300"> واحد پول:</label>
                        <Select
                            placeholder={t("selection")}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                        />
                        {/*<select name="" className="select2 w-full">*/}
                        {/*  <option value="1">ریال</option>*/}
                        {/*  <option value="1">تومان</option>*/}
                        {/*</select>*/}
                      </div>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-4 p-3 box space-y-5 bg-white dark:bg-themeDarkSalt700">
                    <div className="space-y-5 ">
                      <div className="card_bank max-w-sm mx-auto">
                          <div class="front">
                            <div class="investor">شرکت نرم افزاری آرین</div>
                            <div class="chip">
                              <div class="chip-line"></div>
                              <div class="chip-line"></div>
                              <div class="chip-line"></div>
                              <div class="chip-line"></div>
                              <div class="chip-main"></div>
                            </div>
                            <div class="card-number">
                              <div class="flex flex-row-reverse text-base">
                                <h3>-IR</h3>
                                <div class="section">0000</div>
                                <div class="section">1111</div>
                                <div class="section">2222</div>
                                <div class="section">3333</div>
                                <div class="section">4444</div>
                                <div class="section">5555</div>
                              </div>
                              <div class="flex justify-between text-xl flex-row-reverse">
                                <div class="section">000</div>
                                -
                                <div class="section">0000</div>
                                -
                                <div class="section">555</div>
                                -
                                <div class="section">555</div>
                              </div>
                            </div>
                            <div class="text-xs px-5">تاریخ انقضاء:<span> 11/22</span></div>
                          </div>
                        </div>
                      <div className="grid grid-cols-2 p-3 post-input rounded-md -intro-x dark:bg-themeDarkPostInput">
                        <div className="space-y-2">
                          <label className="text-xs dark:text-gray-300">وضعیت</label>
                          <div>
                            <input className="input input--switch " type="checkbox"/>
                          </div>
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">پیش فرض:</label>
                            <div className="can-toggle demo-rebrand-1 text-xs">
                              <input id="swich1" type="checkbox"/>
                              <label htmlFor="swich1">
                                <div className="can-toggle__switch" data-checked="بله" data-unchecked="خیر"></div>
                              </label>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div >
                      <button className="bg-themeInformation dark:bg-themeDarkInformation text-white  py-3 w-full rounded-md flex items-center justify-center gap-2 text-lg hover:bg-themeHoverInformation">
                        <HPrimeIcon icon={'bookmark'}/>
                        ثبت
                      </button>
                    </div>
                </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="space-y-5">
                  <ListSearch label={null} icon={null} >
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">جستجو براساس (شماره کارت)</label>
                       <InputText type={"search"} className={" input w-full"}  />
                    </div>
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">نوع حساب</label>
                      <Select
                          placeholder={t("selection")}
                          classNamePrefix={"h_multi_select"}
                          menuPosition={"fixed"}

                      />
                      {/*<select className="select2 w-full" multiple>*/}
                      {/*  <option value="0" selected>همه</option>*/}
                      {/*  <option value="1">بانک</option>*/}
                      {/*  <option value="2">تنخواه گردان</option>*/}
                      {/*  <option value="3">صندوق</option>*/}
                      {/*</select>*/}
                    </div>
                    <div class="flex items-end gap-3  ">
                      <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
            <HPrimeIcon icon={"search"}/>
        </span>
                      </div>
                      <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
              <HPrimeIcon icon={"list"}/>
        </span>
                      </div>
                    </div>

                  </ListSearch>
                  <ListFilter label={null} icon={null}
                              childrenOne={
                                <>

                                </>
                              }
                              childrenTwo={
                                <>
                                  <div className="space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">شش ماه به انقضاء</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">یک سال به انقضاء</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">موجود</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class="space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">ناموجود</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">محصولات پرفروش</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                </>
                              }
                  />

                </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div class=" datatable-wrapper box p-3 overflow-x-auto">
                  <table className="hdatatable table table-report table-report--bordered display datatable w-full">
                    <thead>
                    <tr>
                      <th className="border-b-2 whitespace-no-wrap">ردیف</th>
                      <th className="border-b-2 whitespace-no-wrap">کد حسابداری</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">نوع حساب</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">وضعیت</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">واحد پول</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">توضیحات</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">عملیات</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td className="border-b">
                        <div class="flex justify-center items-center gap-2">
                          <h3>1</h3>
                          <input type="checkbox"/>
                        </div>
                      </td>
                      <td className="border-b">
                        <h3>121231321231</h3>
                      </td>
                      <td className="border-b">
                        <h3>بانک</h3>
                      </td>
                      <td className="text-center border-b">
                        <input type="image" src="dist/images/true-false/True.png" className="input-img"/>
                      </td>
                      <td className="text-center border-b">
                        <h3>ریال</h3>
                      </td>
                      <td className="text-center border-b">
                        <h3>پرداختی های فروردین ماه</h3>
                      </td>
                      <td className="text-center border-b">
                        <div class="flex justify-center items-center gap-2 text-xs">
                          <a className="flex items-center bg-theme-9  gap-1 px-2 py-1 text-white rounded"
                             href="#"> <i data-feather="check-square" class="w-4 h-4"></i>ویرایش</a>
                          <a className="flex items-center bg-theme-6  gap-1 px-2 py-1 text-white rounded"
                             href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف </a>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
                  <div className="sm:col-span-2">
                    <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                      <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Pdf"></span>
                        <h2>خروجی (Pdf)</h2>
                      </Button>
                      <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Excel"></span>
                        <h2>خروجی (Exel)</h2>
                      </Button>
                      <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Word"></span>
                        <h2>خروجی (Word)</h2>

                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-content__pane p-1 md:p-3 space-y-5" id="tab_two">
              <div className="grid grid-cols-12 gap-5">
                <div class="col-span-12 lg:col-span-8 p-2 box bg-white dark:bg-themeDarkSalt700">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-3 rounded-md ">
                    <div className="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <div class="flex items-center gap-2">
                        <label className="text-xs dark:text-gray-300">کد حسابداری:</label>
                        <div class="can-toggle demo-rebrand-1 text-xs">
                          <input id="swich2" type="checkbox"/>
                          <label htmlFor="swich2">
                            <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                          </label>
                        </div>
                      </div>
                      <div>
                        <InputText type={"text"} className={" input w-full"} />
                      </div>
                    </div>
                    <div className="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <label className="text-xs dark:text-gray-300">نام تنخواه گردان:</label>
                      <Select
                          placeholder={t("selection")}
                          classNamePrefix={"h_multi_select"}
                          menuPosition={"fixed"}
                      />
                      {/*<select name="" className="select2 w-full">*/}
                      {/*  <option value="">مسعود</option>*/}
                      {/*</select>*/}
                    </div>
                    <div className="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <label className="text-xs dark:text-gray-300">واحد پول:</label>
                      <Select
                          placeholder={t("selection")}
                          classNamePrefix={"h_multi_select"}
                          menuPosition={"fixed"}

                      />
                      {/*<select name="" className="select2 w-full" data-hide-search="true">*/}
                      {/*  <option value="1">ریال</option>*/}
                      {/*  <option value="1">تومان</option>*/}
                      {/*</select>*/}
                    </div>
                    <div className="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <label className="text-xs dark:text-gray-300">توضیحات:</label>
                      <InputText type={"text"} className={" input w-full"} />
                    </div>
                  </div>
                </div>
                <div class="col-span-12 lg:col-span-4 p-3 box space-y-5 bg-white dark:bg-themeDarkSalt700">
                  <div class="space-y-5 ">
                      <div class="cardTwo max-w-sm mx-auto">
                        <div class="front">
                          <div class="investor text-xs">شرکت نرم افزاری آرین - (تنخواه)</div>
                          <div class="chip">
                              <span>
                                <HPrimeIcon icon={'user'} className="text-black"/>
                              </span>
                          </div>
                          <div class="card-numberTwo">
                            <div class="flex bg-gray-300 rounded text-black">
                              <h3 class="border-l-2 border-gray-600 p-2">نام: </h3>
                              <h3 class="flex-1 text-center p-2">مسعود</h3>
                            </div>
                          </div>
                          <div class="p-5 text-center text-xs">تاریخ ایجاد: <span>1401/21/01</span></div>
                        </div>
                      </div>
                      <div class=" post-input p-3 rounded-md flex justify-center items-center dark:bg-themeDarkPostInput">
                        <div className="space-y-2">
                          <label className="text-xs dark:text-gray-300">وضعیت</label>
                          <div>
                            <input className="input input--switch " type="checkbox"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="">
                    <button className="bg-themeInformation dark:bg-themeDarkInformation text-white py-3 w-full rounded-md flex items-center justify-center gap-2 text-lg hover:bg-themeHoverInformation">
                      <HPrimeIcon icon={"bookmark"}></HPrimeIcon>
                      ثبت
                    </button>
                  </div>
                </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <div className="space-y-5">
                  <ListSearch label={null} icon={null} >
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">جستجو براساس (شماره کارت)</label>
                        <InputText type={"search"} className={" input w-full"} />
                    </div>
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">نوع حساب</label>
                      <Select
                          placeholder={t("selection")}
                          classNamePrefix={"h_multi_select"}
                          menuPosition={"fixed"}


                      />
                      {/*<select className="select2 w-full" multiple>*/}
                      {/*  <option value="0" selected>همه</option>*/}
                      {/*  <option value="1">بانک</option>*/}
                      {/*  <option value="2">تنخواه گردان</option>*/}
                      {/*  <option value="3">صندوق</option>*/}
                      {/*</select>*/}
                    </div>
                    <div class="flex items-end gap-3  ">
                      <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
            <HPrimeIcon icon={"search"}/>
        </span>
                      </div>
                      <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
              <HPrimeIcon icon={"list"}/>
        </span>
                      </div>
                    </div>
                  </ListSearch>
                  <ListFilter label={null} icon={null}
                              childrenOne={
                                <>

                                </>
                              }
                              childrenTwo={
                                <>
                                  <div className=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">شش ماه به انقضاء</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">یک سال به انقضاء</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">موجود</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class="space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">ناموجود</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">محصولات پرفروش</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                </>
                              }
                  />


                </div>

              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div class=" datatable-wrapper box p-3 overflow-x-auto">
                  <table className="hdatatable table table-report table-report--bordered display datatable w-full">
                    <thead>
                    <tr>
                      <th className="border-b-2 whitespace-no-wrap">ردیف</th>
                      <th className="border-b-2 whitespace-no-wrap">کد حسابداری</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">نوع حساب</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">وضعیت</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">واحد پول</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">توضیحات</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">عملیات</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td className="border-b">
                        <div class="flex justify-center items-center gap-2">
                          <h3>1</h3>
                          <input type="checkbox"/>
                        </div>
                      </td>
                      <td className="border-b">
                        <h3>121231321231</h3>
                      </td>
                      <td className="border-b">
                        <h3>بانک</h3>
                      </td>
                      <td className="text-center border-b">
                        <input type="image" src="dist/images/true-false/True.png" className="input-img"/>
                      </td>
                      <td className="text-center border-b">
                        <h3>ریال</h3>
                      </td>
                      <td className="text-center border-b">
                        <h3>پرداختی های فروردین ماه</h3>
                      </td>
                      <td className="text-center border-b">
                        <div class="flex justify-center items-center gap-2 text-xs">
                          <a className="flex items-center bg-theme-9  gap-1 px-2 py-1 text-white rounded"
                             href="#"> <i data-feather="check-square" class="w-4 h-4"></i>ویرایش</a>
                          <a className="flex items-center bg-theme-6  gap-1 px-2 py-1 text-white rounded"
                             href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف </a>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
                  <div className="sm:col-span-2">
                    <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                      <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Pdf"></span>
                        <h2>خروجی (Pdf)</h2>
                      </Button>
                      <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Excel"></span>
                        <h2>خروجی (Exel)</h2>
                      </Button>
                      <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Word"></span>
                        <h2>خروجی (Word)</h2>

                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-content__pane  p-1 md:p-3 space-y-5" id="tab_three">
              <div className=" grid grid-cols-12 gap-5 ">
                <div className="col-span-12 lg:col-span-8 p-2 box bg-white dark:bg-themeDarkSalt700">
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div class="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <div class="flex items-center gap-2">
                        <label className="text-xs dark:text-gray-300">کد حسابداری:</label>
                        <div class="can-toggle demo-rebrand-1 text-xs">
                          <input id="swich3" type="checkbox"/>
                          <label htmlFor="swich3">
                            <div class="can-toggle__switch" data-checked="اتومات" data-unchecked="دستی"></div>
                          </label>
                        </div>
                      </div>
                      <div>
                        <input className="p-inputtext p-component input w-full input " type="text" data-pc-name="inputtext" data-pc-section="root"/>
                      </div>
                    </div>
                    <div class="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <label className="text-xs dark:text-gray-300">نام صندوق:</label>
                      <Select
                          placeholder={t("selection")}
                          classNamePrefix={"h_multi_select"}
                          menuPosition={"fixed"}

                      />
                      {/*<select name="" className="select2 w-full">*/}
                      {/*  <option value="">مسعود</option>*/}
                      {/*</select>*/}
                    </div>
                    <div class="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <label className="text-xs dark:text-gray-300">واحد پول:</label>
                      <Select
                          placeholder={t("selection")}
                          classNamePrefix={"h_multi_select"}
                          menuPosition={"fixed"}


                      />
                      {/*<select name="" className="select2 w-full" data-hide-search="true">*/}
                      {/*  <option value="1">ریال</option>*/}
                      {/*  <option value="1">تومان</option>*/}
                      {/*</select>*/}
                    </div>
                    <div class="space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">
                      <label className="text-xs dark:text-gray-300">توضیحات:</label>
                      <InputText type={"text"} className={" input w-full"} />
                    </div>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-4 p-2 box space-y-5 bg-white dark:bg-themeDarkSalt700">
                  <div className="space-y-5 ">
                    <div className="cardThree max-w-sm mx-auto">
                      <div className="front">
                        <div className="investor text-xs dark:text-gray-300">شرکت نرم افزاری آرین - (صندوق)</div>
                        <div className="chip">
                          <span>
                            <HPrimeIcon icon={'briefcase'} className="text-black"/>
                          </span>
                        </div>
                        <div className="card-numberTwo">
                          <div className="flex bg-gray-300 rounded text-black">
                            <h3 className="border-l-2 border-gray-600 p-2">نام صندوق: </h3>
                            <h3 className="flex-1 text-center p-2">مهر مسعود</h3>
                          </div>
                        </div>
                        <div className="p-5 text-center text-xs dark:text-gray-300">تاریخ ایجاد: <span>1401/21/01</span></div>
                      </div>
                    </div>
                    <div class="post-input p-3 rounded-md flex items-center justify-center dark:bg-themeDarkPostInput">
                        <div className="space-y-2">
                          <label className="text-xs dark:text-gray-300"> وضعیت</label>
                          <div>
                            <input className="input input--switch " type="checkbox"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div>
                      <button className="bg-themeInformation text-white py-3 w-full rounded-md flex items-center justify-center gap-2 text-lg hover:bg-themeHoverInformation">
                        <HPrimeIcon icon={'bookmark'}/>
                        ثبت
                      </button>
                    </div>
                </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="space-y-5">
                  <ListSearch label={null} icon={null} >
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">جستجو براساس (شماره کارت)</label>
                      <div className="">
                        <InputText type={"search"} className={" input w-full"} />
                      </div>
                    </div>
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">نوع حساب</label>
                      <Select
                          placeholder={t("selection")}
                          classNamePrefix={"h_multi_select"}
                          menuPosition={"fixed"}
                      />
                      {/*<select className="select2 w-full" multiple>*/}
                      {/*  <option value="0" selected>همه</option>*/}
                      {/*  <option value="1">بانک</option>*/}
                      {/*  <option value="2">تنخواه گردان</option>*/}
                      {/*  <option value="3">صندوق</option>*/}
                      {/*</select>*/}
                    </div>
                    <div class="flex items-end gap-3  ">
                      <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
            <HPrimeIcon icon={"search"}/>
        </span>
                      </div>
                      <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
              <HPrimeIcon icon={"list"}/>
        </span>
                      </div>
                    </div>
                  </ListSearch>
                  <ListFilter label={null} icon={null}
                              childrenOne={
                                <>

                                </>
                              }
                              childrenTwo={
                                <>
                                  <div className=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">شش ماه به انقضاء</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">یک سال به انقضاء</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">موجود</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class="space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">ناموجود</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">محصولات پرفروش</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                  <div class=" space-y-2 flex flex-col items-center">
                                    <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                    <input className="input input--switch " type="checkbox"/>
                                  </div>
                                </>
                              }
                  />


                </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div class=" datatable-wrapper box p-3 overflow-x-auto">
                  <table className="hdatatable table table-report table-report--bordered display datatable w-full">
                    <thead>
                    <tr>
                      <th className="border-b-2 whitespace-no-wrap">ردیف</th>
                      <th className="border-b-2 whitespace-no-wrap">کد حسابداری</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">نوع حساب</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">وضعیت</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">واحد پول</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">توضیحات</th>
                      <th className="border-b-2 text-center whitespace-no-wrap">عملیات</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td className="border-b">
                        <div class="flex justify-center items-center gap-2">
                          <h3>1</h3>
                          <input type="checkbox"/>
                        </div>
                      </td>
                      <td className="border-b">
                        <h3>121231321231</h3>
                      </td>
                      <td className="border-b">
                        <h3>بانک</h3>
                      </td>
                      <td className="text-center border-b">
                        <input type="image" src="dist/images/true-false/True.png" className="input-img"/>
                      </td>
                      <td className="text-center border-b">
                        <h3>ریال</h3>
                      </td>
                      <td className="text-center border-b">
                        <h3>پرداختی های فروردین ماه</h3>
                      </td>
                      <td className="text-center border-b">
                        <div class="flex justify-center items-center gap-2 text-xs">
                          <a className="flex items-center bg-theme-9  gap-1 px-2 py-1 text-white rounded"
                             href="#"> <i data-feather="check-square" class="w-4 h-4"></i>ویرایش</a>
                          <a className="flex items-center bg-theme-6  gap-1 px-2 py-1 text-white rounded"
                             href="#"> <i data-feather="trash-2" class="w-4 h-4"></i> حذف </a>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
                  <div className="sm:col-span-2">
                    <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                      <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Pdf"></span>
                        <h2>خروجی (Pdf)</h2>
                      </Button>
                      <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Excel"></span>
                        <h2>خروجی (Exel)</h2>
                      </Button>
                      <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Word"></span>
                        <h2>خروجی (Word)</h2>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}