import {useTranslation} from "react-i18next";
import queryString from "query-string";
import React, {useEffect, useMemo, useState} from "react";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";
import {Link} from "react-router-dom";

import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";

export const useTransactionGrid=(showSpinner,hideSpinner,initialSource)=>{
    const { t } = useTranslation();
    const queryParams = queryString.parse(window.location.search);
    const [rowData, setRowData] = useState([]);


    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }

    const GetPaymentState = (paymentStatus) => {
        if (!initialSource.paymentStates) {
            return;
        }
        const data = initialSource.paymentStates.find((item) => item.value === paymentStatus);
        return data ? data.label : '';
    }

    const GetTransactionState = (transactionState) => {
        if (!initialSource.transactionStates) {
            return;
        }
        const data = initialSource.transactionStates.find((item) => item.value === transactionState);
        return data ? data.label : '';
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Party"),minWidth: 150,
            cellRenderer:parameters=>
                <>
                    {parameters.data.userFullName}
                </>
        },

        { headerName: t("TrackingCode"),width: 130, minWidth: 120,

            cellRenderer:parameters=>
                <>
                    {parameters.data.trackingCode}
                </>
        },

        { headerName: t("TransactionCode"),width: 130, minWidth: 120,

            cellRenderer:parameters=>
                <>
                    {parameters.data.transactionCode}
                </>
        },




        { headerName: t("RegistrationDate"),width: 130, minWidth: 120,

            cellRenderer:parameters=>
                <>
                    <GridDateFomartter date={parameters.data.creationDate} />
                </>
        },
        { headerName: t("Price"),width: 130, minWidth: 150,
            cellRenderer:parameters=>
                <>
                    <PriceFormatterComponent price={parameters.data.amount.amount}/>
                    {GetCurrencyType(parameters.data.amount.currency)}

                </>
        },
        { headerName: t("PaymentStatus"),width: 130, minWidth: 140,
            cellRenderer:parameters=>
                <>
                    {GetPaymentState(parameters.data.paymentStatus)}

                </>
        },
        { headerName: t("TransactionState"),width: 130, minWidth: 140,
            cellRenderer:parameters=>
                <>
                    {GetTransactionState(parameters.data.transactionState)}

                </>

        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: false,
            cellRendererParams: {
                checkbox: false,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',fromDate:null,toDate:null,isPaid:null,isPending:null,isCancelled:null});

    const SwitchFilters = [
        {name:'isPaid',lable:t("Paid"),value:filter.isPaid},
        {name:'isPending',lable:t("isPending"),value:filter.isPending},
        {name:'isCancelled',lable:t("Ordercancellation"),value:filter.isCancelled}
    ]

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',fromDate:null,toDate:null,isPaid:null,isPending:null,isCancelled:null}));
        setSearch('');
    }

    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data.value}));
    }

    const handleFromDate=(dateTime)=>{
        setFilter(({...filter,fromDate:dateTime}));
    }
    const handleToDate=(dateTime)=>{
        setFilter(({...filter,toDate:dateTime}));
    }


    useEffect(() => {

        OnGridReady();

    }, [pagination.currentPage, filter.search, pagination.pageSize,filter.isCancelled,filter.isPaid,filter.isPending,filter.fromDate,filter.toDate]);

    const OnGridReady = async () => {
        try {
            showSpinner();
            const queryParams = queryString.parse(window.location.search);

            const response =  await GET(`/Payments/${queryParams.paymentId}/Transactions`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,IsPaid:filter.isPaid,IsPending:filter.isPending,IsCancelled:filter.isCancelled,FromDate:filter.fromDate,ToDate:filter.toDate}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter,
        handleFromDate,
        handleToDate
    }
}