import React, {useState} from "react";

export const useProductGroup=(setErrors)=>{
    const [productGroup,setProductGroup] = useState({productGroupId:null,excerpt: '',slug:'',seO_Title: '',seO_Description:'',title: '',parentId:null,order:0,type:null,stateGoogle:false,stateShow:false,templateId:null});
    const handleSelectValue=(data,name)=>{
        setProductGroup({ ...productGroup,[name]: data });
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setProductGroup({...productGroup,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setProductGroup({...productGroup,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
return {productGroup,setProductGroup,handleSelectValue,handleValue,handleNumberValue}
}