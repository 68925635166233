import React from "react";
import {InputNumber} from "primereact/inputnumber";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const InputNumberElement = ({ item,index,handleChangeData}) => (
    <>

        <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
            <label className="dark:text-gray-300 harmony-text-xs">
                {item.properties.caption}
            </label>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <InputNumber
                max={item.properties.max}
                placeholder={item.properties.description}
                min={item.properties.min}
                disabled={item.properties.isReadOnly}
                id={item.properties.id}
                value={item.data?.value}
                onChange={(e) => handleChangeData(index,'value',e.value)}
            />
        </div>

    </>
);