import React, {useState, useEffect, useRef} from 'react';
import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'
import {UploadImage} from "./Components/Commans/Uploaders/UploadImage";

export const AgTest=()=>{


    return (
        <>
            <UploadImage/>
        </>
    );
}