import {StatusMessage} from "./StatusMessage";
export const ExceptionHandlerService=(error)=>{
    if(error.response && error.response.status === 400){
        return {status:error.response.status,error:error.response.data}
    }
    else if(error.response.status === 401){
      window.location.href = '/';
         return {status:error.response.status,error:error.response.data}
    }
    else if(error.response && error.response.status === 403){
       window.location.href = '/NotPermitted';
        return {status:error.response.status,error:error.response.data}
    }
    else if(error.response && error.response.status === 500){
        return {message:StatusMessage.status_serverError,status:error.response.status};
    }
    else{
        return {message:StatusMessage.status_unexpected,status:error.response.status};
    }
}