import Joi from "joi";
export const advertiseSchema = Joi.object({
    Title: Joi.string().required().trim().messages({
        'any.required': 'عنوان مطلب الزامیست',
        'string.empty': 'عنوان مطلب الزامیست',
        'string.base': 'عنوان مطلب الزامیست'
    }),
    Slug: Joi.string().required().trim().messages({
        'any.required': 'ادرس مطلب الزامیست',
        'string.empty': 'ادرس مطلب الزامیست',
        'string.base': 'ادرس مطلب الزامیست'
    }),
});
