import React from "react";
import {useTranslation} from "react-i18next";

import {ToastContainer} from "react-toastify";

import {MenuController} from "../../../OnlineSupport/Tickets/MenuController";
import {HarmonyTickets} from "./HarmonyTickets";

export const HandleHarmonyOnlineTicket = () => {
  const { t } = useTranslation();


  return(
      <>

      <div class="space-y-5">
        <div className="grid grid-cols-12 gap-5 relative">
          <MenuController />
          <HarmonyTickets/>
        </div>
      </div>
      <ToastContainer/>
  </>
  )
}