import React from "react";
import {useTranslation} from "react-i18next";
import {WebsiteBox} from "./WebsiteBox";

export const Websites=({websites})=>{
    const { t } = useTranslation();
    return (
        <>
            {websites && websites.length > 0 ? (
                websites.map((item,index) =>
                    <WebsiteBox key={index} index={index} {...item} />
                )
            ) : null}
        </>
    )
}