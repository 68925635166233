import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DELETE, GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";

import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
export const useDataHighliterGrid=(showSpinner,hideSpinner,setIsOpen,setIsEdit,initialSource,setting,setSetting,Refresh,refresh,handleOpenView)=>{
    const {t} = useTranslation();
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [rowData, setRowData] = useState([]);
    const showLoader=()=>{
        setWait(true)
    }
    const hideLoader=()=>{
        setWait(false)
    }

    const handleDataTypeName=(type)=>{
        const data = initialSource.metaTypes.find((item)=>item.value === type);
        if(!data){
            return '-'
        }
        return data.label;
    }
    const handleGetMetaTag= async(webSiteMetaTagId)=>{
        try {
            showSpinner();
            setIsOpen(true);
            setIsEdit(true);
            const response = await GET(`/Settings/MetaTag?WebSiteMetaTagId=${webSiteMetaTagId}`,{},'single');
            if(response.status === 200){
                setSetting({
                    webSiteMetaTagId:response.data.webSiteMetaTagId,
                    type:initialSource.metaTypes.find((item)=>item.value === response.data.type),
                    title:response.data.title,
                    data:response.data.data,
                    targetPage:response.data.targetPage,
                    isActive:response.data.isActive,
                })
                hideSpinner()
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner()
        }
    }


    const handleDeleteMetaTag = async (webSiteMetaTagId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Settings/MetaTag/${webSiteMetaTagId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                       hideSpinner();
                        Refresh()
                        handleSuccessMessage(t('SuccessDone'))
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    hideSpinner();
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }
    const handleChangeActivation = async(webSiteMetaTagId,rowName)=>{
        try {
            showLoader()
            setHeaderName(rowName)
            const response = await PUT(`/Settings/MetaTagActivation`,{webSiteMetaTagId:webSiteMetaTagId},false);
            if (response.status === 200) {
                hideLoader();
                Refresh(response.status);
            }
            else{
                handleApiError(response,hideLoader)
            }
        }catch (e) {
            hideLoader()
        }
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("Title"),
            cellRenderer:parameters=>
                <h2 class="line-clamp-1">
                    {parameters.data.title}
                </h2>
        },
        { headerName: t("type"),
            cellRenderer:parameters=>
                <h2 className="">
                    {handleDataTypeName(parameters.data.type)}
                </h2>
        },
        { headerName: t("Status"),field: 'isActive',width: 115, minWidth: 115, cellRenderer:params=>
                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading  />
                    ) : params.data.isActive ? (
                        <HPrimeIcon iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeActivation(params.data.webSiteMetaTagId,params.colDef.headerName)}
                                     icon={'pi-check-square'}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={async() => await  handleChangeActivation(params.data.webSiteMetaTagId,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'x-circle'}

                        />
                    )}
                </div>
        },
        { headerName: t("viewing"),
            cellRenderer:parameters=>
                <div>
                    <button type="button" onClick={()=>handleOpenView(parameters.data.data)} className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white text-xs" >
                        <HPrimeIcon icon={'pi-eye'} />
                        مشاهده
                    </button>
                </div>
        },
        { headerName: t("settings"), maxWidth: 120,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-2 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetMetaTag(params.data.webSiteMetaTagId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteMetaTag(params.data.webSiteMetaTagId)}
                    />
                </div>
            }

        },

    ];
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);








    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',isActive:null,isDeActive:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',isActive: null,isDeActive: null}));
        setSearch('');
    }




    useEffect(() => {
         OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.isActive,filter.isDeActive]);

    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Settings/MetaTags`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,IsActive:filter.isActive,IsDeActive:filter.isDeActive}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            hideSpinner()
        } catch (error) {
            hideSpinner()
        } finally {
            hideSpinner()
        }
    };


    const SwitchFilters = [
        {name:'isActive',lable:t("isActive"),value:filter.isActive},
        {name:'isDeActive',lable:t("isNotActive"),value:filter.isDeActive}
    ]
    return {
        handleGetMetaTag,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteMetaTag,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
    }

}