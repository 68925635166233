import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {GridTable} from "../Commans/Grid/GridTable";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {DELETE, GET, POST, PUT} from "../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../CMS/Contents/ContentService";
import {handleFailureMessage, handleSuccessMessage} from "../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../Services/Globals/Errors/handleApiError";
import Select from "react-select";
import {convertGurantee} from "./GuranteeService";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../Commans/Loadings/LoadingHarmony";
import {DisplayError} from "../Commans/Errors/DisplayError";
import {guranteeShema} from "./guranteeShema";
import {useTranslation} from "react-i18next";
import {Pagination} from "../Commans/Grid/Pagination";
import {DateTimePicker} from "../Commans/Dates/DateTimePicker";
import Swal from "sweetalert2";
import {InputNumber} from "primereact/inputnumber";
import {BrowserDownloader, DownloadFileHandler} from "../../ApiServices/Public/FtpController";
import {InputSwitchListFilter} from "../Commans/Filters/InputSwitchListFilter";
import {validate} from "../../Services/Globals/validate";
import {GridDateFomartter} from "../Commans/Grid/GridDateFomartter";
import {Return} from "../Commans/Globals/Return";
import {AlertSetting, GridSetting} from "../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../Services/Globals/Translations/Resources";
import {ButtonVideo} from "../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {FilterFileUpload} from "../Commans/UIParts/FilterbuttonUpload";
import {ModalButtonOperations} from "../Commans/UIParts/ModalButtonOperations";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import {useSetting} from "../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SettingButton} from "../Commans/UIParts/SettingButton";

import {Tooltip} from "react-tooltip";
import {GridButtonDelete} from "../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../Commans/Grid/GridButtonOprations";
import {RequiredIcon} from "../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";
import {fetchAccessToken} from "../../Services/AxiosService/JwtService";
import {BasicUrl} from "../../Services/AxiosService/axiosClient";



export const HandleGuarrantyProduct=()=>{

    const { t } = useTranslation();

    const redirectTo = useNavigate();
    const [spinner,setSpinner] = useState(false);
    const [modal, setModal] = useState(false);
    const [isMenuOpen,setMenuOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [result,setResult] = useState(0);
    const [options,setOptions] = useState({products:[],timeValues:[],guranteeStatus:[]});
    const [symbol,setSymbol] = useState('');
    const handleCloseSerial = ()=>{
        setModal(false);
        setErrors({})
        setGurantee({batchNumber: '',timeUnit:options.timeValues[1],number: '',productId: options.products[0],timeValue: ''})
    }

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const [downloadData,setDownloadData] = useState({productGuranteeGroupId:null});

    const handleOpenMenu = (id) => {
        setMenuOpen(true);
        setDownloadData({productGuranteeGroupId: id});
    };

    const handleExport= async (exportType)=>{
        try {

            const url= `${BasicUrl.baseURL}/ProductGuarantees/DownloadProductGuarantees?ProductGuaranteeGroupId=${downloadData.productGuranteeGroupId}&ExportType=${parseInt(exportType)}`;
            await DownloadFileHandler(url,'codes');
            setMenuOpen(false);
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleCloseMenu = ()=>{setMenuOpen(false);}
    const onApiError = ()=>{
        setSpinner(false);
    }

    const [gurantee,setGurantee] = useState({productId:null,batchNumber:null,timeUnit:null,timeValue:null,number:null});

    const handleFetchData = async()=>{
        try {
            const responseStatus = await GET('/Enumerations/EnGuaranteeStatus',{},'IEnumerable');
            const responseProduct = await GET('Products/Selection',{},'IEnumerable');
            const responseTime = await GET(`Enumerations/EnTimeUnit?keys=${5}&keys=${6}`,{},'IEnumerable',onApiError);
            if(responseProduct.status === 200 && responseTime.status === 200){
                const products = convertSelectDataHandler(responseProduct.data);
                const times = convertSelectDataHandler(responseTime.data);
                const statuses = convertSelectDataHandler(responseStatus.data);
                setOptions({products: products,timeValues: times,guranteeStatus: statuses});
                setGurantee({timeUnit: times[1],productId: products[0]});
                setSymbol(times[1].label);
            }else{
                handleApiError(responseProduct,onApiError)
                handleApiError(responseTime,onApiError)
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(()=>{
        handleFetchData();
    },[])


const onSetErrors = (errors)=>{
        setErrors(errors)
}




    const handleValue=(e)=>{
        const {name,value} = e.target;
        setGurantee({...gurantee,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setGurantee({...gurantee,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue=(data,name)=>{
        if(name === 'timeUnit'){
            setGurantee({ ...gurantee, [name]: data });
            setSymbol(data.label);
            console.log(data);
        }else{
            setGurantee({ ...gurantee, [name]: data });
        }
    }



    const handleSubmitGurantee = async (e)=>{
        e.preventDefault();
        const isValid = validate(guranteeShema,gurantee,onSetErrors);
        if(!isValid){
            return ;
        }
        if(gurantee.productId === null){
            handleFailureMessage(t("Productnameismandatory"));
            return;
        }
        if(gurantee.timeUnit === null){
            handleFailureMessage(t("Timeselectionismandatory"));
            return;
        }
        const convertedGurantee = convertGurantee(gurantee);
        const {number,productId,batchNumber,timeUnit,timeValue} = convertedGurantee;
        setSpinner(true)
        const response = await POST('ProductGuarantees',{number: number,productId: productId,batchNumber: batchNumber,timeUnit: timeUnit,timeValue: timeValue},false,onApiError);
        if (response.status === 200) {
            setSpinner(false);
            handleCloseSerial();
            handleSuccessMessage(t("Guaranteecodehasbeensuccessfullyregistered"));
            setResult({...response.status});
        }else{
            handleApiError(response,onApiError)
        }
    }




    const handleStatus = (status)=>{
        if (!options.guranteeStatus) {
            return;
        }
        const data = options.guranteeStatus.find((item) => item.value === status);
        return data ? data.label : '';

    }



    const handleCreditTime = (timeUnit,value) => {
        if (!options.timeValues) {
            return;
        }
        const data = options.timeValues.find((item) => item.value === timeUnit);
        return data ?   value + '  '+ data.label : '';
    }

    const handleStatusToPending = async (id)=>{
        try {
            setSpinner(true);
           const response = await PUT('/ProductGuarantees/ProductGuaranteeToPending',{productGuaranteeGroupId:id},false);
           if(response.status === 200){
               setResult({...response.status});
               handleSuccessMessage(t("Guaranteestatushasbeensuccessfullychanged"));
           }else{
               handleApiError(response,onApiError);
           }
        }
        catch (e) {

        }
    }

    const handlePending = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:t("SentToProductionLine"),
            text: AlertSetting.TailWindButton.text,
            icon:'info',
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: t("Sendtoproduction"),
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
              await handleStatusToPending(id);
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110,hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 110},
        { headerName: t("ProductName"), field: 'productName', },
        { headerName: t("NumberofGuaranteeCodes") , field: 'codesCount', },
        { headerName: t("Date"), field: 'creationDate', maxWidth: 150,cellRenderer:params => {
                return  <GridDateFomartter date={params.data.creationDate} hasTime={false} />
            }},
        { headerName: t("ValidityPeriod"), field: 'timeUnit', maxWidth:150,cellRenderer:params => {
                {return handleCreditTime(params.data.timeUnit,params.data.timeValue)}
        }},

        { headerName: t("ProductionSeries"), field: 'batchNumber', minWidth: 200,},


        { headerName: t("Status"), field: 'status', maxWidth: 150,cellRenderer:params => {
                {return handleStatus(params.data.status)}
        }},


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    {
                        params.data.status !== 1?
                            <>
                                <button type={"button"} className="text-themeInformation dark:text-themeDarkInformation tooltip_class_Line" onClick={()=>handlePending(params.data.id)}>
                                    <HPrimeIcon icon={'pi-truck'} />
                                    <Tooltip anchorSelect={".tooltip_class_Line"} content={t("SentToProductionLine")} positionStrategy={"fixed"} />
                                </button>
                            </>
                        :
                        null
                    }

                    <GridButtonDelete
                        onClick={()=>handleRemoveGurantee(params.data.id)}
                    />


                    <GridButtonOprations
                        onClick={() => handleOpenMenu(params.data.id)}
                    />
                </div>
            }
        },

    ];

    const handleRemoveGurantee = async (id)=>{

        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`/ProductGuarantees?ProductGuaranteeGroupId=${id}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                        handleSuccessMessage(t("Guaranteehasbeensuccessfullydeleted"))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    redirectTo('/')
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',fromData:null,toData:null,isCreated:null,isPending:null})
    const [search,setSearch]=useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:search}));
    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,toData: null,fromData: null,isPending: null,isCreated: null,search:''}))
        setSearch('');
    }

    const handleRegisterToDateTime = (dateTime)=>{
        setFilter({...filter,toData: dateTime})
    }
    const handleRegisterFromDateTime = (dateTime)=>{
        setFilter({...filter,fromData: dateTime})
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };

        loadData();
    }, [result, currentPage, filter.search, data.pageSize,filter.isCreated,filter.isPending,filter.fromData,filter.toData]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/ProductGuarantees/GetAllProductGuaranteeGroups`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search, IsCreated: filter.isCreated, IsPending: filter.isPending,FromDate:filter.fromData,ToDate:filter.toData}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };





    const handleUploadGuranteeFiles = async(files)=>{
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("Files", files[i]);
        }
        const response = await POST('/ProductGuarantees/CreateProductGuaranteesViaFileUpload',formData,true)
        if(response.status === 200){
            setResult({...response.status});
            setSpinner(false);
            handleSuccessMessage(t("Filehasbeensuccessfullyuploaded"));
        }else{
            handleApiError(response,onApiError);
        }
    }






    const switchData = [
        {name:'isCreated',lable:t("Created"),value:filter.isCreated},
        {name:'isPending',lable:t("ProductionLine"),value:filter.isPending},
    ]



    return(
        <>
            <div className=" space-y-5 relative">
                {
                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("search")}</label>
                            <InputText type="search" value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className="input w-full"  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}</label>
                            <DateTimePicker handleDateTime={handleRegisterFromDateTime} value={filter.fromData} format={'YYYY/MM/DD'} />
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300 flex items-center gap-1">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}</label>
                            <DateTimePicker value={filter.toData} handleDateTime={handleRegisterToDateTime} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => setModal({ AddSerial: true })}
                                            tooltipTitle={t("SerialCreation")}
                                        />
                                        <ButtonPlus
                                            onClick={() => redirectTo("/Guarantee/Customer")}
                                            tooltipTitle={t("CustomersGuarantee")}
                                            icon={"pi-verified"}
                                            title={t("Guarantee")}
                                        />
                                        <ButtonPlus
                                            title={t("Download")}
                                            icon={"pi-download"}
                                            tooltipTitle={t("DownloadSampleFile")}
                                        />
                                        <FilterFileUpload
                                            id={"inExel"}
                                            icon={"pi-upload"}
                                            type={"file"}
                                            onChange={(e)=>handleUploadGuranteeFiles(e.target.files)}
                                            title={t("Upload")}
                                            tooltipTitle={t("UploadGuaranteeCodes")}
                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />

                </div>

                <div className="space-y-2 H-body-ag-grid">

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />

                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <ToastContainer/>
            </div>


            <FantasyModal openModal={modal}  closeModal={handleCloseSerial} title={t("SerialCreation")}>
              <form method={'post'} onSubmit={handleSubmitGurantee}>
                  <div className="space-y-5">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 gap-y-3 ">
                          <div class="space-y-1 md:col-span-2">
                              <label className="text-xs dark:text-gray-300">{t("ProductName")}</label>
                              <Select
                                  options={options.products}
                                  isMulti={false}
                                  isSearchable={true}
                                  onChange={(data)=>handleSelectValue(data,'productId')}
                                  placeholder={t("selection")}
                                  value={gurantee.productId}
                                  menuPosition={"fixed"}
                                  classNamePrefix={"h_multi_select"}
                              />
                          </div>
                          <div class="space-y-2 ">
                              <div class="flex justify-between items-center">
                                  <label className="text-xs dark:text-gray-300">
                                      {t("RequiredSerialQuantity")}
                                      <RequiredIcon/>
                                  </label>
                                  <DisplayError message={errors.number}/>
                              </div>
                              <InputNumber name={'number'} min={0}  onChange={(e)=>handleNumberValue(e.value,'number')} value={gurantee.number}  />
                          </div>
                          <div class="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("SeriesGeneration")}</label>
                              <InputText onInput={(e)=>handleValue(e)}  name={'batchNumber'} value={gurantee.batchNumber}/>
                          </div>
                          <div className="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("Calculation")}</label>
                              <Select
                                  options={options.timeValues}
                                  isMulti={false}
                                  isSearchable={true}
                                  onChange={(data)=>handleSelectValue(data,'timeUnit')}
                                  placeholder={t("selection")}
                                  value={gurantee.timeUnit}
                                  menuPosition={"fixed"}
                                  classNamePrefix={"h_multi_select"}
                              />
                          </div>
                          <div className={'space-y-2'}>
                              <div className={'flex justify-between items-center'}>
                                  <label className="text-xs dark:text-gray-300">
                                      {t("ValidityPeriod")}
                                      <RequiredIcon/>
                                  </label>
                                  <DisplayError message={errors.timeValue}/>
                              </div>
                              <InputNumber name={'timeValue'} useGrouping={false} suffix={ symbol } min={1}  onChange={(e)=>handleNumberValue(e.value,'timeValue')} value={gurantee.timeValue}  />
                          </div>
                      </div>
                      <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("SerialProduction")}</button>
                  </div>
              </form>

            </FantasyModal>
            <FantasyModal openModal={isMenuOpen}  closeModal={handleCloseMenu} >
                <div className="p-1 grid grid-cols-3 md:grid-cols-4 gap-3">
                    <ModalButtonOperations
                        onClick={()=>handleExport(0)}
                        title={t("ExcelDownload")}
                        icon={"pi-download"}
                    />
                    <ModalButtonOperations
                        onClick={()=>handleExport(3)}
                        title={t("DownloadText")}
                        icon={"pi-download"}
                    />
                </div>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
        </>

    )
}