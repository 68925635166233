import React, {useState} from "react";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

export const useSlugValidation=(surveyData,onApiError)=>{
    const [oldSlug,setOldSlug] = useState('');
    const [isContentSlugValid, setContentIsSlugValid] = useState(null);
    const handleCheckContentSlug = async (e) => {
        const { name, value } = e.target;
        if (name === "title") {
            if(surveyData.id === null){
                const response = await GET(`/Public/SlugValidation`,{NewSlug:surveyData.slug,OldSlug:null},'single');
                if(response.status === 200){
                    setContentIsSlugValid(response.data.isSlugValid);
                }else{
                    handleApiError(response,onApiError);
                }
            }
            else{
                const response = await GET(`/Public/SlugValidation`,{NewSlug:surveyData.slug,OldSlug:oldSlug},'single');
                if(response.status === 200){
                    setContentIsSlugValid(response.data.isSlugValid);
                }else{
                    handleApiError(response,onApiError);
                }
            }
        }
        else{
            if(surveyData.id === null){
                const response = await GET(`/Public/SlugValidation`,{NewSlug:value.slug,OldSlug:null},'single');

                if(response.status === 200){
                    setContentIsSlugValid(response.data.isSlugValid);
                }else{
                    handleApiError(response,onApiError);
                }
            }
            else{
                const response = await GET(`/Public/SlugValidation`,{NewSlug:value.slug,OldSlug:oldSlug},'single');
                if(response.status === 200){
                    setContentIsSlugValid(response.data.isSlugValid);
                }else{
                    handleApiError(response,onApiError);
                }
            }
        }
    };

    return {
        setContentIsSlugValid,
        oldSlug,setOldSlug,
        handleCheckContentSlug,
        isContentSlugValid

    }


}