import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";

import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";



export const useReminderTabGrid=(filter,initialSource,refresh,Refresh,showSpinner,hideSpinner)=>{

    const { t } = useTranslation();
    const [reminders,setReminders] = useState([]);
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");

    const GetSendingStates = (data)=>{
        if(data === null){
            return '';
        }
        const filteredLabels = initialSource.sendingStates
            .filter(item => data.includes(item.value))
            .map(filteredItem => filteredItem.label);
        return filteredLabels.join(',');
    }

    const GetReminderState = (reminderState) => {
        if (!initialSource.reminderStates) {
            return;
        }
        const data = initialSource.reminderStates.find((item) => item.value === reminderState);
        return data ? data.label : '';
    }

    const handleChangeReminderState = async(reminderId,rowName,isActive)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            const response = await PUT(`/UserReminders/ReminderState`,{activeReminder:isActive,userNoteId:reminderId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh()
            }
            else{
                handleApiError(response,setWait(false))
            }
        }catch (e) {
            setWait(false)
        }
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},
        { headerName: t("Title"),field: 'title', minWidth: 170},
        { headerName: t("Notification"),field: 'enSendingStates', minWidth: 200,cellRenderer:params => {
                return GetSendingStates(params.data.enSendingStates);
            }},
        { headerName: t("Date"), minWidth: 150,
            cellRenderer:params => {
                return <><GridDateFomartter date={params.data.reminderDateUtc} hasTime={true} /></>
            }
        },
        { headerName: t("Status"),minWidth: 170,
            cellRenderer:params => {
                return GetReminderState(params.data.reminderState)
            }
        },

        { headerName: t("ReminderStatus"),field: 'hasReminder',maxWidth: 115, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading  />
                    ) : params.data.hasReminder ? (
                        <HPrimeIcon iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeReminderState(params.data.id,params.colDef.headerName,!params.data.hasReminder)}
                                     icon={'pi-check-square'}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeReminderState(params.data.id,params.colDef.headerName,!params.data.hasReminder)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'pi-times-circle'}

                        />
                    )}
                </div>
        },


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <button type={"button"} className="text-themeSuccess" ><HPrimeIcon  icon={'pi-list'} /></button>
                </div>
            }
        },
    ];





    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };

    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems;
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh,pagination.currentPage,pagination.pageSize,filter.today,filter.thisWeek,filter.thisMonth,filter.search]);

    const OnGridReady = async () => {
        try {
            showSpinner(true);
            const response =  await GET(`/UserNotes`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize,IsReminder:true,search:filter.search}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const modifiedItems = Paginate(response.items);
                setReminders(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };


    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        pagination,
        reminders
    }
}