import React, {useEffect, useState} from "react";

import {Link, redirect, useNavigate} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {Pagination} from "../../Commans/Grid/Pagination";
import Select from "react-select";
import {UserLevel} from "./UserLevel";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {ProfileData} from "./ProfileData";
import {ProfileMedia} from "./ProfileMedia";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {ModalButtonOperations} from "../../Commans/UIParts/ModalButtonOperations";



export const HandleProfile = () => {


    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [isMenuOpen,setMenuOpen] = useState(false);
    const handleOpenMenu = () => {
        setMenuOpen(true);
    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }

const notification = [
    {date:"1403/12/1",text:"تخفیف باور نکردنی"},
    {date:"1403/12/1",text:"تخفیف باور نکردنی"}

]


        return(
        <>
            <div class="space-y-5">
                <div className="space-y-5">
                    <div className="text-themeInformation dark:text-themeDarkInformation border-b-2 border-themeInformation dark:border-themeDarkInformation">{t("PersonalDetails")}</div>
                    <div className="flex flex-col md:flex-row gap-5 ">
                        <div className={"space-y-3"}>
                            <div class=" relative profile-edit light-shadow">
                                <UploadImage accept={'image/*'}/>
                            </div>
                            <div>
                                <button type={"button"} onClick={()=>redirectTo("/UserPanel/Edit")} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}> {t("ProfileEdit")}</button>
                            </div>
                        </div>
                        <div className="flex-1 space-y-5">
                            <ProfileMedia t={t}/>
                            <ProfileData t={t}/>
                            <UserLevel t={t}/>
                        </div>
                    </div>
                </div>
                <div className="space-y-5">
                    <div className="text-themeInformation dark:text-themeDarkInformation border-b-2 border-themeInformation dark:border-themeDarkInformation">{t("Tickets")}</div>
                    <div className="box p-2 space-y-3 bg-white dark:bg-themeDarkSalt700">
                        <div className={"flex flex-col md:flex-row justify-between gap-3" }>
                            <div className="flex gap-2 items-center ">
                                <div>
                                    <input type={"checkbox"}>
                                    </input>
                                </div>
                                <div>
                                    <button type={"button"} className="button small_button bg-themeInformation dark:bg-themeDarkInformation">
                                        <HPrimeIconSmall icon={"pi-plus"} iconClass={"text-white"} />
                                    </button>
                                </div>
                                <div>
                                    <button type={"button"} className="button small_button bg-themeInformation dark:bg-themeDarkInformation">
                                        <HPrimeIconSmall icon={"pi-box"} iconClass={"text-white"} />
                                    </button>
                                </div>
                                <div>
                                    <button type={"button"} className="button small_button bg-themeInformation dark:bg-themeDarkInformation">
                                        <HPrimeIconSmall icon={"pi-trash"} iconClass={"text-white"} />
                                    </button>
                                </div>
                                </div>
                            <div className="flex-1">
                                <Pagination/>
                            </div>
                        </div>
                        <div class=" max_height20 space-y-2 ">
                                    <div className="w-full overflow-x-auto space-y-2 ">
                                        {notification.map((item, index) => (
                                            <div class="line">
                                                <div className="post-input dark:bg-themeDarkPostInput p-2 post-input-design w-full ">
                                                    <div className="flex items-center gap-1 justify-between">
                                                        <div className={"flex flex-shrink-0 gap-2"}>
                                                            <div className="flex gap-1">
                                                                <input type={"checkbox"}/>
                                                                <button type={"button"}>
                                                                    <HPrimeIconSmall icon={"pi-star"} iconClass={"dark:text-gray-300"}/>
                                                                </button>
                                                            </div>
                                                            <div className={"flex-1"}>
                                                                <p className="text-xs line-clamp-1 dark:text-gray-300">{item.text}</p>
                                                            </div>
                                                        </div>
                                                        <div className="p-2 flex justify-between items-center gap-4 ">
                                                            <p className="text-xs text-themeInformation dark:text-themeDarkInformation">{item.date}</p>
                                                            <div className="dropdown" >
                                                                <div className="dropdown-toggle block cursor-pointer" onClick={()=>handleOpenMenu()}>
                                                                    <HPrimeIcon icon={'pi-ellipsis-v'} iconClass="primIcon_FontSize_xs dark:text-gray-300" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    </div>
                    </div>
                </div>
            </div>
            <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
                <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
                    <ModalButtonOperations
                        title={t("حذف")}
                        icon={"pi-trash"}
                    />
                    <ModalButtonOperations
                        title={t("پاسخ")}
                        icon={"pi-reply"}
                    />
                    <ModalButtonOperations
                        title={t("آرشیو")}
                        icon={"pi-box"}
                    />
                </div>
            </FantasyModal>

        </>

  )
}