import React, {useEffect, useState} from "react";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";

export const useFetchData=()=>{
    const [options,setOptions] = useState({slugs:[],states:[],modes:[],types:[]});
    const [spinner,setSpinner] = useState(false);
    const onApiError = () => {
        setSpinner(false);
    };


    const handleFetchData=async()=>{
        try {
            const responseState=await GET(`/Enumerations/EnCommentType`,{},'IEnumerable');
            const responseSlug=await GET(`/public/GetAllSlugs`,{},'IEnumerable');
            const responseCommunicationType= await GET('/Communications/Selection',{},'IEnumerable');
            const responseCommunicationMode= await GET('/Enumerations/EnCommunicationMode',{},'IEnumerable');

            if(responseState.status === 200 && responseSlug.status === 200 && responseCommunicationType.status === 200 && responseCommunicationMode.status === 200){
                const states=convertSelectDataHandler(responseState.data);
                const slugs=convertSelectDataHandler(responseSlug.data);
                const types=convertSelectDataHandler(responseCommunicationType.data);
                const modes=convertSelectDataHandler(responseCommunicationMode.data);
                setOptions((prevState)=>({...prevState,states: states,slugs: slugs,types: types,modes: modes}));
            }
            else{
                handleApiError(responseState,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }



    return {
      options,
        setOptions,
        handleFetchData,
        spinner,
        setSpinner,
        onApiError
  }
}