import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {InputText} from "primereact/inputtext";
import {useSecurityPrivacy} from "./useSecurityPrivacy";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {TagsInput} from "react-tag-input-component";
import {InputSwitch} from "../../../../Commans/Filters/InputSwitch";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {handleCreateManagerPrivacy, validateIPs} from "./ManagerPrivacyService";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";

export const ManagerPrivacy=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();

    const [errors,setErrors]=useState({});
    const {handleValue,handleSelectValue,setManagerPrivacy, managerPrivacy,handleChangeIps}=useSecurityPrivacy(setErrors)
    const [IsShowSpinner,setShowSpinner]=useState(false)
    const [initialSource,setInitialSource]=useState({
         authenticationModes:[]
        ,firstSmsOtpAccounts:[]
        ,secondSmsOtpAccounts:[]
        ,firstEmailOtpAccounts:[]
        ,secondEmailOtpAccount:[]
    });
    const [refresh,setRefresh]=useState(false);
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }
    const showSpinner=()=>{
        setShowSpinner(true)
    }
    const hideSpinner=()=>{
        setShowSpinner(false);
    }
    const handleInitialDataSource=(authenticationModes,firstSmsOtpAccounts,firstEmailOtpAccounts)=>{
        const authenticationModesData = convertSelectDataHandler(authenticationModes)
        const firstSmsOtpAccountsData = convertSelectDataHandler(firstSmsOtpAccounts)
        const firstEmailOtpAccountsData = convertSelectDataHandler(firstEmailOtpAccounts)
        setInitialSource({
            authenticationModes: authenticationModesData,
            firstSmsOtpAccounts: firstSmsOtpAccountsData,
            secondSmsOtpAccounts: firstSmsOtpAccountsData,
            firstEmailOtpAccounts: firstEmailOtpAccountsData,
            secondEmailOtpAccount: firstEmailOtpAccountsData
        })
        return {
            authenticationModesData,
            firstSmsOtpAccountsData,
            firstEmailOtpAccountsData
        }
    }

    const fetchData = async () => {
        try {

            showSpinner();
            const [responseAuthentication, responseAccounts, responseEmailAccounts
               ,responseSetting
            ] = await Promise.all([
                GET(`/Enumerations/EnAuthenticationMode`, {}, 'IEnumerable'),
                GET(`/Messaging/Accounts/ActiveAccounts`, {}, 'IEnumerable'),
                GET(`/MailAccounts/Selection`, {}, 'IEnumerable'),
                GET(`/Settings/CpanelSecurity`, {}, 'single')
            ]);
            const allResponses = [responseAuthentication, responseAccounts,responseEmailAccounts,
                responseSetting
            ];
            const allResponseStatusAre200 = allResponses.every(response => response.status === 200);
            if(allResponseStatusAre200){
                const {firstEmailOtpAccountsData,authenticationModesData,firstSmsOtpAccountsData}=handleInitialDataSource(responseAuthentication.data,responseAccounts.data,responseEmailAccounts.data)
                setManagerPrivacy((prevState) => {
                    const authMode = authenticationModesData ? authenticationModesData.find((item) => item.value === responseSetting.data.authenticationMode) : null;
                    const firstSmsOtp = firstSmsOtpAccountsData && firstSmsOtpAccountsData ? firstSmsOtpAccountsData.find((item) => item.value === responseSetting.data.firstSMSOtpAccount) : null;
                    const secondSmsOtp = firstSmsOtpAccountsData && firstSmsOtpAccountsData ? firstSmsOtpAccountsData.find((item) => item.value === responseSetting.data.secondSMSOtpAccount) : null;
                    const firstEmailOtp = firstEmailOtpAccountsData && firstEmailOtpAccountsData ? firstEmailOtpAccountsData.find((item) => item.value === responseSetting.data.firstEmailOtpAccount) : null;
                    const secondEmailOtp = firstEmailOtpAccountsData && firstEmailOtpAccountsData ? firstEmailOtpAccountsData.find((item) => item.value === responseSetting.data.secondEmailOtpAccount) : null;

                    return {
                        ...prevState,
                        authenticationMode: authMode ?? null,
                        firstSMSOtpAccount: responseSetting.data.firstSMSOtpAccount !== null ? firstSmsOtp : null,
                        allowedIPs: responseSetting.data.allowedIPs ?? [],
                        smsOtpAccountActivation: responseSetting.data.smsOtpAccountActivation,
                        emailOtpAccountActivation: responseSetting.data.emailOtpAccountActivation,
                        secondSMSOtpAccount: responseSetting.data.secondSMSOtpAccount !== null ? secondSmsOtp : null,
                        firstEmailOtpAccount: responseSetting.data.firstEmailOtpAccount !== null ? firstEmailOtp : null,
                        secondEmailOtpAccount: responseSetting.data.secondEmailOtpAccount !== null ? secondEmailOtp : null,
                    };
                });
            }
            else{
                allResponses.forEach((response)=>{
                    handleApiError(response,hideSpinner);
                })
            }
        } catch (e) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[refresh])

    const handleSubmit=async (e)=>{
        e.preventDefault();
        try {
            showSpinner();
            const privacyData = handleCreateManagerPrivacy(managerPrivacy);
            const response = await POST(`/Settings/CpanelSecurity`,privacyData,false);
            if(response.status === 200){
               hideSpinner();
               handleSuccessMessage(t('SuccessDone'))
               Refresh();
            }
            else{
                handleApiError(response,hideSpinner);
            }

        }catch (e) {
            hideSpinner();
        }
    }

    return (<>


       <form onSubmit={handleSubmit} method={'post'} className={"relative"}>
           {
               IsShowSpinner?
                   <div className="huploader_loading" >
                       <div className="relative">
                           <LoadingHarmony/>
                       </div>
                   </div>
                   :
                   null
           }
           <div className={"space-y-5"}>
               <div className={"grid grid-cols-1 lg:grid-cols-2 gap-5 relative"}>
                   <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-2">
                       <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 border-themeInformation dark:border-themeDarkInformation">
                           <h2 className="dark:text-gray-300 text-xs">
                               {t("PasswordType")}
                           </h2>
                       </div>
                       <div class="space-y-1">
                           <label className="text-xs dark:text-gray-300">{t("ChoosePassword")}</label>
                           <Select
                               options={initialSource.authenticationModes}
                               placeholder={t("selection")}
                               classNamePrefix={"h_multi_select"}
                               menuPosition={"fixed"}
                               value={managerPrivacy.authenticationMode}
                               onChange={(data)=>handleSelectValue(data,'authenticationMode')}
                           />
                       </div>
                   </div>
                   <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-2">
                       <div class="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 border-themeInformation dark:border-themeDarkInformation flex">
                           <InputSwitch value={managerPrivacy.smsOtpAccountActivation} OnValueSelection={handleSelectValue}  name={'smsOtpAccountActivation'} lable={t("DefaultLineForOTPCode")} className="flex-row" />
                       </div>
                       <div className={"grid grid-cols-1 md:grid-cols-2 gap-2"}>
                           <div class="space-y-1 ">
                               <label className="dark:text-gray-300 text-xs">{t("FirstLine")}</label>
                               <Select
                                   options={initialSource.firstSmsOtpAccounts}
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                                   value={managerPrivacy.firstSMSOtpAccount}
                                   onChange={(data)=>handleSelectValue(data,'firstSMSOtpAccount')}
                               />
                           </div>
                           <div class="space-y-1">
                               <label className="dark:text-gray-300 text-xs">{t("BackupLine")}</label>
                               <Select
                                   options={initialSource.secondSmsOtpAccounts}
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                                   value={managerPrivacy.secondSMSOtpAccount}
                                   onChange={(data)=>handleSelectValue(data,'secondSMSOtpAccount')}
                               />
                           </div>
                       </div>
                   </div>
                   <div className="post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-2">
                       <div class="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 border-themeInformation dark:border-themeDarkInformation">
                           <h2 class="text-xs dark:text-gray-300">
                               {t("IPRestriction")}
                           </h2>
                       </div>
                       <div>
                           <div class="space-y-1">
                               <label className="text-xs dark:text-gray-300">{t("AuthorizedIPAddresses")}</label>
                               <TagsInput   onChange={handleChangeIps}  classNames={"input"} value={managerPrivacy.allowedIPs} name={"allowedIPs"} placeHolder={t("AuthorizedIPAddresses")}/>
                               <p className="text-xs dark:text-gray-300">
                                   {t("StaticIPTitle")}
                               </p>
                           </div>
                       </div>
                   </div>
                   <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-2">
                       <div class="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 border-themeInformation dark:border-themeDarkInformation">
                           <InputSwitch value={managerPrivacy.emailOtpAccountActivation} OnValueSelection={handleSelectValue}  name={'emailOtpAccountActivation'} lable={t("DefaultEmailForOTPCode")} className="flex-row" />
                       </div>
                       <div className={"grid grid-cols-1 md:grid-cols-2 gap-2"}>
                           <div class="space-y-1 flex-1">
                               <label className="dark:text-gray-300 text-xs">{t("Email")}</label>
                               <Select
                                   options={initialSource.firstEmailOtpAccounts}
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                                   value={managerPrivacy.firstEmailOtpAccount}
                                   onChange={(data)=>handleSelectValue(data,'firstEmailOtpAccount')}
                               />
                           </div>
                           <div class="space-y-1 flex-1">
                               <label className="dark:text-gray-300 text-xs">{t("BackupEmail")}</label>
                               <Select
                                   options={initialSource.secondEmailOtpAccount}
                                   placeholder={t("selection")}
                                   classNamePrefix={"h_multi_select"}
                                   menuPosition={"fixed"}
                                   value={managerPrivacy.secondEmailOtpAccount}
                                   onChange={(data)=>handleSelectValue(data,'secondEmailOtpAccount')}
                               />
                           </div>
                       </div>
                   </div>
               </div>
               <ButtonSubmitAndCancel
                   onCancelClick={()=>redirectTo('/Harmony/Master/setting')}
               />
           </div>


       </form>
        <ToastContainer/>
    </>)

}