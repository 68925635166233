import React from "react";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {InputText} from "primereact/inputtext";
export const ColorElement=({item,index,handleChangeData})=>{

    return (
        <>
            <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
                <label className="dark:text-gray-300 harmony-text-xs harmony-line-clamp-1">
                    {item.properties.caption}
                </label>
                {
                    item.properties.isRequired === true?
                        <RequiredIcon/>
                        :null
                }
                <input
                    type={'color'}
                    onChange={(e) => handleChangeData(index,'value',e.target.value)}
                    value={item.data?.value}
                    placeholder={item.properties.description}
                    minLength={item.properties.min}
                    maxLength={item.properties.max}
                    readOnly={item.properties.isReadOnly}
                    required={item.properties.isReadOnly}
                    className={"harmony-form-color"}

                />
            </div>
        </>
    )
}