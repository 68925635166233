import React from "react";
import {InputText} from "primereact/inputtext";
import {useTranslation} from "react-i18next";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {TimeSetting} from "../../../../Services/Globals/Public/AppSetting";
import moment from "moment-jalaali";

export const Subscription=({user})=>{
    const { t } = useTranslation();
    const handleGetFormattedDate = (date)=>{
        return moment(date).format(TimeSetting.dateFormat);
    };

    return (
        <>
            <div className={"space-y-3 post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 flex gap-2 items-center"}>
                        <HPrimeIconSmall
                            icon={"pi-credit-card"}
                            iconClass={"dark:text-gray-300"}
                        />
                        <p className={"text-xs dark:text-gray-300"}>{t("Share")}</p>
                    </div>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 px-2"}>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("DateMembership")}</label>
                            <InputText className={"input  w-full "} value={handleGetFormattedDate(user?.registerDate)} disabled={true} />
                        </div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("SubscriptionExpirationDate")}</label>
                            <InputText className={"input  w-full "} value={handleGetFormattedDate(user?.renewalDate)} disabled={true} />
                        </div>
                    </div>
            </div>
        </>
    )
}