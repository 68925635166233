import Joi from 'joi';

export const FastRegisterSchema = Joi.object({
    mobilePhoneNumber: Joi.string()
        .required()
        .pattern(/^(\+98|0)?9\d{9}$/)
        .messages({
            'any.required': 'شماره موبایل الزامیست',
            'string.empty': 'شماره موبایل الزامیست',
            'string.base': 'شماره موبایل الزامیست',
            'string.pattern.base': 'فرمت شماره موبایل صحیح نمیباشد',
        }),
    fullName: Joi.string()
        .required()
        .messages({
            'any.required': 'نام و نام خانوادگی الزامیست',
            'string.empty': 'نام و نام خانوادگی الزامیست',
            'string.base': 'نام و نام خانوادگی الزامیست',
        }),
    email: Joi.string().allow('').allow(null)
        .pattern(/^(?:[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})?[\s\S]*$/)
        .messages({
            'string.pattern.base': 'فرمت ایمیل وارد شده صحیح نمیباشد',
        }),
    homePhoneNumber: Joi.string().allow('').allow(null)
        .pattern(/^0[0-9]{2,}[0-9]{7,}$/)
        .messages({
            'string.pattern.base': 'فرمت شماره ثابت صحیح نمیباشد',
        }),

    // nationalCode: Joi.string().allow('').allow(null)
    //     .pattern(/^[0-9]{10}$/)
    //     .messages({
    //         'string.pattern.base': 'فرمت کد ملی صحیح نمیباشد',
    //     }),

    presenterCode: Joi.string().allow('').allow(null)
        .pattern(/^\d+$/)
        .messages({
            'string.pattern.base': 'فقط عدد مورد قبول است',
        }),

    // userCode: Joi.string().allow('').allow(null)
    //     .pattern(/^\d+$/)
    //     .messages({
    //         'string.pattern.base': 'فقط عدد مورد قبول است',
    //     })
    // password: Joi.string().required().messages({
    //     'any.required': 'کلمه عبور الزامیست',
    //     'string.empty': 'کلمه عبور نمی‌تواند خالی باشد',
    //     'string.base':'کلمه عبور الزامیست',
    // }),
    // username: Joi.string()
    //     .required().messages({
    //         'any.required': 'نام کاربری الزامیست',
    //         'string.empty': 'نام کاربری الزامیست',
    //         'string.base':'نام کاربری الزامیست',
    //     })

}).options({ abortEarly: false });

