import React, {useEffect} from "react";

import { InputText } from "primereact/inputtext";
import { useState } from "react";

import { InputTextarea } from "primereact/inputtextarea";


import { TabMenu } from "../../../Commans/UIParts/TabMenu";
import {  ToastContainer } from "react-toastify";
import {useNavigate} from "react-router-dom";
import { TagsInput } from "react-tag-input-component";

import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import queryString from "query-string";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {
  handleFailureMessage,
} from "../../../../Services/Globals/Errors/NotificationService";
import {
  convertSelectDataHandler,
  convertSlugHandler, handleContentAttaches,
  handleCreateContentConvertor,
  handleCreateContentFormData,
  handleEditContentConvertor,
  handleEditContentFormData
} from "../ContentService";
import {contentJoiSchema} from "./ContentSchema";

import Select from "react-select";

import Swal from "sweetalert2";

import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {useTranslation} from "react-i18next";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {AddFilter} from "../../../Commans/Filters/AddFilter";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {TinyEditor} from "../../../Commans/UIParts/TinyEditor";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
// import 'primereact/dialog/dialog.min.css';
import {Dialog} from "primereact/dialog";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {Tooltip} from "react-tooltip";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {ButtonNotification} from "../../../Commans/UIParts/ButtonNotification";
import {ContentGroup} from "../ContentGroups/ContentGroup";
import {useContentAttach} from "./useContentAttach";
import {ContentAttach} from "./ContentAttach";
import {SlugGenerator} from "../../../Commans/Globals/AiFeatures/SlugGenerator";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";



export const HandleContentAdd = () => {

  const [isDraft,setIsDraft] = useState(false);
  const [isReset,setIsReset] = useState(false);
  const [result,setResult] = useState(0);
  const [contentAttaches,setContentAttaches] = useState([])
  const { t } = useTranslation();
  const queryParams = queryString.parse(window.location.search);
  const [modal, setModal] = useState({ AddFilter: false });
  const redirectTo = useNavigate();
  const [spinner,setSpinner] = useState(false);
  const [activeTab, setActiveTab] = useState("tab_one");
  const [geoActiveTab, setGeoActiveTab] = useState("location");
  const [IsOpen,setIsOpen] = useState(false);
  const [IsEdit,setIsEdit] = useState(false)
  const [selectOptions,setSelectOptions] = useState({
    contentSuggestionIds:[],
    authorId:null
    ,type:null,contentGroupIds:[],formId:null,
    templateId:null,sendingStates:[],metaDataGroupIds:[],
    contentGroupTypes:null,contentTypes:null,attachTypes:[],
    filters:[],
    surveyTypes:[]
  })

  const {addNewCard,deleteCard,files,setFiles,handleFileChange,handleFieldChange,handleInitialCards,handleGetAllCards,triggerFileInput,totalSize}=useContentAttach(selectOptions)



  const [content,setContent] = useState({id:null,concurrencyStamp:null,thumbnailPath:null,title: null, slug: null, excerpt: null, editor: null, seo_Title: null, seo_Keywords: [], lead: null, suggestionContentIds: [], metaData:[], releaseDateTime:null, writingDate: null, longitude: "0", latitude: "0", thumbnail: null, authorId: null, type: null, contentGroupIds: [], formId: null, templateId: null, iFrame: null,filterId:null, contentPermission: null, metaDataGroupIds:[], isMetaDataGroupChanged:false, stateShow: true, stateAndroid: true, stateGoogle: true, sendingStates: [], contentImages:[], contentAttaches:[],surveyGroupId:null});

  const [errors, setErrors] = useState({});
  const [isDisable, setDisable] = useState(false);
  const [oldSlug,setOldSlug] = useState('');
  const [isContentSlugValid,setContentIsSlugValid] = useState(null);

  const handleChangeDraft=(data)=>{
    setIsDraft(data);
  }

  const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

  const GoToFilterPage=()=>{
    //window.open('/Form/Report/Builder','_blank','noopener,noreferrer');
    redirectTo('/Form/Report/Builder');
  }

  const handleGeoTabClick = (tabId) => {
    setGeoActiveTab(tabId);
  };
  const AddFilterOnHide = () => {
    setModal((prevState) => ({ ...prevState, AddFilter: !modal.AddFilter }));
    setIsReset(true);
  };
  const handleIsReset=()=>{
    setIsReset(false);
  }

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const onApiError=()=>{
    setSpinner(false);
  }
  const onSetErrors=(errors)=>{
    setErrors(errors);
  }

  const resetImages = () => {
    setContent((prevState)=>({...prevState,thumbnailPath: null, thumbnail: null}));
  }
  const handleSelectFile =(data,name)=>{
    setContent((prevContent) => ({ ...prevContent, [name]: data }));
  }


  const handleValue = (e) => {
    if(content.id === null || isDraft === true){
      const { name, value } = e.target;
      if (name === "title") {
        handleSlugValue(e);
        setContent((prevState) => ({ ...prevState, seo_Title: value }));
      }
      setContent((prevContent) => ({ ...prevContent, [name]: value }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
    else{
      const { name, value } = e.target;
      setContent((prevContent) => ({ ...prevContent, [name]: value }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }
  };

  const superValueHandler = (data, name) => {
    setContent({ ...content, [name]: data });
  };

  const removeFile=(path,name)=>setContent((prevState)=>({...prevState,[name]:path}))

  const normalValueHandler =(data,name)=>{
    setContent((prevContent) => ({ ...prevContent, [name]: data }));
  }

  const releaseDateTimeHandler = (dateTime)=>{
    setContent({...content,releaseDateTime: dateTime})
  }
  const writingDateTimeHandler = (dateTime)=>{
    setContent({...content,writingDate: dateTime})
  }

  // دیتای slug مارو میسازه و مدل رو اپدیت میکنه
  const handleSlugValue = (e) => {
    const { value } = e.target;
    const slugValue = convertSlugHandler(value);
    setContent((prevContent) => ({ ...prevContent, slug: slugValue }));
  };

  // هندل کردن اطلاعات کلمات کیلیدی
  const handleKeywordsChange = (keywords) => {
    setContent((prevContent) => ({ ...prevContent, seo_Keywords: keywords }));
  };

  const InputSwitchValueHandler = (data,name)=>{
    setContent((prevState) => ({
      ...prevState,
      [name]: data,
    }))
  }

  const tabs = [
    {
      id: "tab_one",
      label: t("Contents"),
      icon: "pi-file-edit",
    },
    {
      id: "tab_two",
      label: t("MetaTag"),
      icon: "pi-tag",
    },
    {
      id: "tab_three",
      label: t("settings"),
      icon: "pi-cog",
    },
    {
      id: "tab_four",
      label: t("File"),
      icon: "pi-paperclip",
    },
  ];

  const geoTab = [
    {
      id: "location",
      label:t("LatitudeAndLongitude"),
      icon: "pi-map-marker",
    },
    {
      id: "address",
      label: t("IFrame"),
      icon: "pi-code",
    },
  ];

  const handleFetchData = async () => {
    try {
      setSpinner(true);
      const responseAuthor = await GET('/Contents/Authors',{},'IEnumerable');
      const responseType = await GET('/EnContentType',{},'IEnumerable');
      const responseGroup = await GET('/ContentGroups/Selection',{},'IEnumerable');
      const responseForm = await GET('/Forms/Selection',{},'IEnumerable');
      const responseTemplate = await GET(`/TemplatePages/selection?Type=${1}&Type=${13}`,{},'IEnumerable');
      const responseSendingState = await GET('/EnSendingStates',{},'IEnumerable');
      const responseMetaDataGroup = await GET('/MetaDataGroup/Selection',{},'IEnumerable');
      const responseSuggestionIds = await GET('/Contents/GetAllSuggestionContents',{},'IEnumerable');
      const responseAttach = await GET('/Enumerations/EnContentAttachType',{},'IEnumerable');
      const responseFilters = await GET(`/Filters/Selection?Table=${1}`,{},'IEnumerable');
      const responseSurvey = await GET(`/Filters/Selection?Table=${1}`,{},'IEnumerable');

      if(responseAuthor.status === 200 && responseType.status === 200 && responseGroup.status === 200&& responseForm.status === 200 && responseTemplate.status === 200 &&
       responseSendingState.status === 200 && responseMetaDataGroup.status === 200 && responseAttach.status=== 200 && responseFilters.status === 200 && responseSurvey.status === 200
      )
      {
        const authorId = convertSelectDataHandler(responseAuthor.data);
        const contentType = convertSelectDataHandler(responseType.data);
        const groupIds = convertSelectDataHandler(responseGroup.data);
        const formId = convertSelectDataHandler(responseForm.data);
        const templateId = convertSelectDataHandler(responseTemplate.data);
        const sendingStates = convertSelectDataHandler(responseSendingState.data);
        const metadataGroupIds= convertSelectDataHandler(responseMetaDataGroup.data);
        const attachTypes = convertSelectDataHandler(responseAttach.data);
        const suggestions = convertSelectDataHandler(responseSuggestionIds.data);
        const filters = convertSelectDataHandler(responseFilters.data);
        const surveys = convertSelectDataHandler(responseSurvey.data);
        setSelectOptions((prevOptions) => ({
          ...prevOptions,
          authorId: authorId,
          type: contentType,
          contentTypes: contentType,
          contentGroupIds: groupIds,
          formId: formId,
          templateId: templateId,
          sendingStates: sendingStates,
          metaDataGroupIds: metadataGroupIds,
          contentSuggestionIds: suggestions,
          filters: filters,
          attachTypes: attachTypes,
          surveyTypes: surveys
        }));
        setContent((prevOptions) => ({
          ...prevOptions,
          authorId: authorId[0],
          type: contentType[0],
          releaseDateTime:new Date(),
          writingDate: new Date(),
          //formId: formId[0],
          sendingStates: [sendingStates[0]]
        }));

        if(files.length === 0){
          handleInitialCards(attachTypes);
        }


        setSpinner(false)

        if(queryParams.contentId){
          try {
            setIsEdit(true)
            const response = await GET(`/Contents/${queryParams.contentId}`,{},'single');
            if(response.status === 200){
              setOldSlug(response.data.slug);
              if(response.data.contentAttaches.length === 0){
                handleInitialCards(attachTypes);
              }
              else{
                const fileCards = handleGetAllCards(response.data.contentAttaches,attachTypes);
                setFiles(fileCards);
              }
              setContent({...content,
                id: response.data.id,
                releaseDateTime: response.data.releaseDateTime,
                writingDate: response.data.writingDate,
                templateId: templateId.find((item)=>item.value === response.data.templateId),
                formId:formId.find((item)=>item.value === response.data.formId),
                title: response.data.title,
                slug: response.data.slug,
                sendingStates:  response.data.sendingStates && sendingStates ? response.data.sendingStates.map((value) => sendingStates.find((item) => item.value === value)) : [],
                suggestionContentIds: response.data.suggestionContentIds && suggestions ? response.data.suggestionContentIds.map((value) => suggestions.find((item) => item.value === value)) : [],
                latitude: response.data.latitude,
                longitude: response.data.longitude,
                stateShow: response.data.stateShow,
                iFrame: response.data.iFrameMap,
                stateAndroid: response.data.stateAndroid,
                stateGoogle: response.data.stateGoogle,
                thumbnail: response.data.thumbnail,
                lead: response.data.lead,
                authorId: authorId.find((item)=>item.value === response.data.authorId),
                type:contentType && contentType.find((item)=>item.value === response.data.type),
                excerpt: response.data.excerpt,
                seo_Title: response.data.seoTitle,
                seo_Keywords: response.data.seoKeywords ?? [],
                contentGroupIds: response.data.contentGroups && groupIds ? response.data.contentGroups.map((value) => groupIds.find((item) => item.value === value)) : [],
                contentImages: response.data.contentImages,
                concurrencyStamp:response.data.concurrencyStamp,
                editor:response.data.editor,
                filterId: response.data.filterId? filters.find((item)=>item.value === response.data.filterId):null,
                surveyGroupId: response.data.surveyGroupId? surveys.find((item)=>item.value === response.data.surveyGroupId):null,
              });
             const contentAttaches = handleContentAttaches(response.data.contentAttaches,attachTypes);
             setContentAttaches(contentAttaches);
            }
          }catch (e) {
            console.log(e);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.contentId) {
      setDisable(true);
      handleFetchData();
    }else{
      handleFetchData();
    }
  }, [result]);


const handleChangeResult=(status,contentId)=>{
  setResult({...status});
  redirectTo(`/CMS/Content/Add?contentId=${contentId}`);
  setContent((prevState)=>({...prevState,id:contentId}));
}



  const handleValueMetaChanged =()=>{
    const {isMetaDataGroupChanged} = content;
    if(isMetaDataGroupChanged === false && isDisable){
      const TailWindButton = Swal.mixin({
        customClass: {
          confirmButton: AlertSetting.customClass.confirmButton,
          cancelButton: AlertSetting.customClass.cancelButton,
        },
        buttonsStyling: false,
      });
      TailWindButton.fire({
        title: t("Areyousureyouwanttomakethechange"),
        text: t("Thereisnopossibilitytoreverttopreviousdataandthenewinformationwillreplacetheolddata"),
        icon: AlertSetting.TailWindButton.icon,
        showCancelButton: true,
        confirmButtonText: t("Yeschangeit"),
        cancelButtonText: t("Nodontchangeit"),
        reverseButtons: AlertSetting.TailWindButton.reverseButtons,
      }).then((result) => {
        if (result.isConfirmed) {
          setDisable(false);
          setContent({ ...content,isMetaDataGroupChanged:true });
          return;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          setDisable(true);
        }
      });
    }
  }

  const handleCheckContentSlug = async (e) => {
    const { name, value } = e.target;
    if (name === "title") {
     try {
       if(content.id === null){
         const response = await GET('/Public/SlugValidation',{NewSlug:content.slug,OldSlug:null},'single');
         if(response.status === 200){
           setContentIsSlugValid(response.data.isSlugValid);
         }else{
           handleApiError(response,onApiError);
         }
       }
       else{
         const response = await GET('/Public/SlugValidation',{NewSlug:content.slug,OldSlug:oldSlug},'single');
         if(response.status === 200){
           setContentIsSlugValid(response.data.isSlugValid);
         }else{
           handleApiError(response,onApiError);
         }
       }
     }catch (e) {
       console.log(e);
     }
    }
    else{
      try {
        if(content.id === null){
          const response = await GET('/Public/SlugValidation',{NewSlug:content.slug,OldSlug:null},'single');

          if(response.status === 200){
            setContentIsSlugValid(response.data.isSlugValid);
          }else{
            handleApiError(response,onApiError);
          }
        }
        else{
          const response = await GET('/Public/SlugValidation',{NewSlug:value,OldSlug:oldSlug},'single');
          if(response.status === 200){
            setContentIsSlugValid(response.data.isSlugValid);
          }else{
            handleApiError(response,onApiError);
          }
        }
      }catch (e) {
        console.log(e);
      }
    }
  };



  const contentSubmitHandler =   async(e) => {

    e.preventDefault();
    var isValid = validate(contentJoiSchema,content,onSetErrors);
    if(!isValid)return;

    try {

      if(content.id !== null){
        if(content.contentGroupIds.length === 0){
              handleFailureMessage(t("Selectyourarticlegroup"));
              return;
        }
        setSpinner(true);
        const jsonContent = handleEditContentConvertor(content,files);
        const formContent = handleEditContentFormData(jsonContent);
        const response = await PUT(`/Content/EditContent`,formContent,true);
        if(response.status === 200){
          setSpinner(false);
          redirectTo(`/CMS/Content/List?isSuccessEdit=${true}`);
        }
        else{
          handleApiError(response,onApiError)
        }
      }
      else{
        if(content.contentGroupIds.length === 0){
          handleFailureMessage(t("Selectyourarticlegroup"));
          return;
        }
        setSpinner(true);
        const jsonContent = handleCreateContentConvertor(content,files);
        const formContent = handleCreateContentFormData(jsonContent);
        const response = await POST(`/Contents/CreateContent`,formContent,true);
        if(response.status === 200){
          setSpinner(false);
          redirectTo(`/CMS/Content/List?isSuccess=${true}`);
        }
        else{
          handleApiError(response,onApiError);
        }
      }
    }
    catch (e) {
      console.log(e);
    }
  };



  const handleIsOpen=()=>{
    setIsOpen(true);
  }
const handleCloseIsOpen=()=>{
  setIsOpen(false);
}
  const handleSuccess=async(isSuccess)=>{
    if(isSuccess){
      const response = await GET('/ContentGroups/Selection',{},'IEnumerable');
      if(response.status === 200){
        const contentGroups = convertSelectDataHandler(response.data);
        setSelectOptions(prevState => ({...prevState,contentGroupIds: contentGroups}));
        handleCloseIsOpen()
      }
      else{
        handleApiError(response,onApiError);
      }
    }
  }



  const [visible, setVisible] = useState(false);
  const[message,setMessage]=useState('');
  const handleShowMessage =(visible,message)=>{
    setVisible(visible);
  }


  const headerElement = (
      <div className="flex items-center  gap-2">
        <HPrimeIconSmall
        icon={"pi-info-circle"}
        iconClass={"text-themeInformation dark:text-themeDarkInformation"}
        />
        <span className={"text-xs"}>{t("Quickguide")}</span>
      </div>
  );


  return (
    <div className={"space-y-5"}>

      <Dialog header={headerElement} visible={visible}  className={"Dialog-NotifText"} onHide={() => setVisible(false)} >
        <p className={"text-sm"}>تمام مطالب مرتبط بر اساس کلمات کلیدی به صورت اتوماتیک متصل میشود در صورتی که میخواهید محتوای اختصاصی را برای این بخش انتخاب کنید آن را از باکس زیر معرفی کنید</p>
      </Dialog>

      <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
        <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={
                      <>
                        <ButtonPlus
                            onClick={()=>redirectTo(`/CMS/Content/Group`)}
                            icon={"pi-users"}
                            title={t("grouping")}
                            tooltipTitle={t("grouping")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/CMS/MetaData/Add`)}
                            icon={"pi-star"}
                            title={t("feature")}
                            tooltipTitle={t("CreatingAFeature")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/CMS/Content/List`)}
                            icon={"pi-list"}
                            title={t("list")}
                            tooltipTitle={t("ListOfInformation")}
                        />

                      </>
                    }

        />

      </div>
      <form onSubmit={contentSubmitHandler} >
        <div className="space-y-5 relative">
          {

            spinner?
                <div className=" huploader_loading" >
                  <div className="relative">
                    <LoadingHarmony/>
                  </div>
                </div>
                :
                null
          }
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-8">
                  <div className="flex flex-col sm:flex-row gap-3 items-center ">
                    <div className={"flex-1 w-full"}>
                      <div className="float-input p-float-label relative">
                        <InputText onInput={handleValue}  name={'title'} value={content.title} onBlur={handleCheckContentSlug} type="text" className="input w-full input-float-label" />
                        <label className={"text-xs dark:text-gray-300"}>
                          {t("Title")}
                        <RequiredIcon/>
                        </label>
                        <div className={"absolute end-2 -top-2"}>
                          <DisplayError message={errors.title}/>
                        </div>
                      </div>
                    </div>
                    <SlugGenerator
                        data={content}
                        showSpinner={()=>setSpinner(true)}
                        hideSpinner={()=>setSpinner(false)}
                        setData={setContent}
                    />
                    <div className={"flex-1 w-full"}>
                      <div className="float-input p-float-label relative">
                        <InputText onBlur={handleCheckContentSlug} name={'slug'} value={content.slug} onInput={handleValue} type="text" className={`input w-full input-float-label ${
                            isContentSlugValid && isContentSlugValid === false ? "InputError" : ""}`} />
                        <label className={"text-xs dark:text-gray-300"}>
                          {t("Address")}
                          <RequiredIcon/>
                        </label>
                        <div className={"absolute end-2 -top-2"}>
                          <DisplayError message={errors.slug}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="post mt-5">
                    <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                    <div className="post__content tab-content box bg-white dark:bg-themeDarkSalt700 p-2">
                      <div className={`tab-content__pane ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                        <div className="space-y-4">
                          <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                            <label className="dark:text-gray-300 text-xs">
                              {t("TextSummary")}
                            </label>
                            <InputTextarea name={"excerpt"} value={content.excerpt} onInput={(e) => handleValue(e)} rows={3} cols={30} className={"input w-full "}/>
                          </div>
                          <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                            <div >
                              <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("EditorType")}
                                />
                                {t("FullText")}
                              </label>
                            </div>
                            <div className={"custom-editor-class"}>
                              <TinyEditor content={content.editor} name={'editor'}  handleEditorContent={normalValueHandler}/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`tab-content__pane space-y-3  ${ activeTab === "tab_two" ? "active" : ""}`} id="tab_two">
                        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                          <label className="dark:text-gray-300 text-xs"> {t("WindowTitle")}</label>
                          <InputText name={"seo_Title"} value={content.seo_Title} onInput={(e) => handleValue(e)} type={"text"} className={"input w-full"} />
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                          <label className="dark:text-gray-300 text-xs"> {t("Keywords")}</label>
                          <TagsInput value={content.seo_Keywords} onChange={handleKeywordsChange} name={"seo_Keywords"} placeHolder={t("Keywords")}/>
                        </div>
                        <div className=" post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                          <label className="dark:text-gray-300 text-xs">{t("Lead")}</label>
                          <InputText name={"lead"} value={content.lead} onInput={(e) => handleValue(e)} type={"text"} className={"input w-full"}/>
                        </div>
                        <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                          <div className={"flex gap-1 items-center"}>
                            <ButtonNotification
                                onClick={() => handleShowMessage(true)}
                            />
                            <label className="dark:text-gray-300 text-xs" >
                              {t("RelatedContent")}
                            </label>

                          </div>


                          <Select
                              options={selectOptions.contentSuggestionIds}
                              isMulti={true}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(data)=>superValueHandler(data,'suggestionContentIds',true)}
                              name={'suggestionContentIds'}
                              placeholder={t("selection")}
                              value={content.suggestionContentIds}
                              className={"select-group"}
                              menuPosition={'fixed'}
                              classNamePrefix={"h_multi_select"}
                          />


                        </div>
                      </div>
                      <div className={`tab-content__pane space-y-3  ${activeTab === "tab_three" ? "active" : ""}`} id="tab_three" >
                        <div className="post-input dark:bg-themeDarkPostInput grid grid-cols-1 md:grid-cols-2 gap-2 post-input-design p-2 ">
                          <div className="gap-y-2 flex flex-col">
                            <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                              <SettingButton
                                  onClick={()=>handleSetting('GeneralSetting')}
                                  tooltipTitle={t("Calendarsettings")}
                              />
                              {t("PublicationDate")}
                            </label>
                            {/*<InputMask*/}
                            {/*  name={"releaseDateTime"}*/}
                            {/*  value={content.releaseDateTime}*/}
                            {/*  onChange={(e) => handleValue(e)}*/}
                            {/*  mask="9999/99/99 99:99:99"*/}
                            {/*  placeholder={*/}
                            {/*    new Date().toLocaleDateString("fa-IR") +*/}
                            {/*    " " +*/}
                            {/*    new Date().toLocaleTimeString("fa-IR")*/}
                            {/*  }*/}
                            {/*  slotChar="----/--/--"*/}
                            {/*  className={"input w-full"}*/}
                            {/*/>*/}

                            <DateTimePicker isContainsTime={true}  handleDateTime={releaseDateTimeHandler} format={'YYYY/MM/DD hh:mm:ss'} value={content.releaseDateTime}/>

                          </div>
                          <div className="space-y-2">
                            <div class={"flex items-center gap-2"}>
                              <label className="text-xs dark:text-gray-300">{t("ReleaseFor")}</label>
                              <button type="button" className="text-themeInformation text-xs dark:text-themeDarkInformation tooltip_class_CreateFilter" onClick={() => GoToFilterPage()}>({t("CreateFilter")})
                                <Tooltip anchorSelect={".tooltip_class_CreateFilter"} content={t("CreateFilter")} positionStrategy={"fixed"} />
                              </button>
                            </div>

                            <Select
                                options={selectOptions.filters}
                                isMulti={null}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>superValueHandler(data,'filterId',false)}
                                name={'filterId'}
                                placeholder={t("selection")}
                                value={content.filterId}
                                menuPosition={'fixed'}
                                classNamePrefix={"h_multi_select"}
                            />

                          </div>

                        </div>
                        <div className={"post "}>
                          <TabMenu activeTab={geoActiveTab} handleTabClick={handleGeoTabClick} tabs={geoTab}/>
                          <div className={"post__content tab-content p-2 box bg-white dark:bg-themeDarkSalt700"}>
                            <div className={`tab-content__pane ${geoActiveTab === "location" ? "active" : ""}`}
                                 id="location">
                              <div className="post-input dark:bg-themeDarkPostInput grid grid-cols-1 gap-5 post-input-design p-2 md:grid-cols-2 ">
                                <div className="space-y-2">
                                  <label className="dark:text-gray-300 text-xs">
                                    {t("Length")}
                                  </label>
                                  <InputText value={content.longitude} name={"longitude"} onInput={(e) => handleValue(e)} type={"text"} className={"input w-full"} placeholder={t("Length")}/>
                                </div>
                                <div className="space-y-2">
                                  <label className="dark:text-gray-300 text-xs">
                                    {t("Width")}
                                  </label>
                                  <InputText value={content.latitude} name={"latitude"} onInput={(e) => handleValue(e)} type={"text"} className={"input w-full "} placeholder={t("Width")}/>
                                </div>
                              </div>
                            </div>
                            <div className={`tab-content__pane space-y-5 ${geoActiveTab === "address" ? "active" : ""}`} id="address">
                              <div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                                <label className="dark:text-gray-300 text-xs">
                                  {t("Address")}
                                </label>
                                <InputText name={"iFrame"} value={content.iFrame} onInput={(e) => handleValue(e)} type={"text"} className={"ipnut w-full "} placeholder={t("Address")}/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`tab-content__pane  max_height85vh ${activeTab === "tab_four" ? "active" : ""}`} id="tab_four" >

                        <ContentAttach
                            data={files}
                            IsEdit={IsEdit}
                            triggerFileInput={triggerFileInput}
                            setCards={setFiles}
                            cards={files}
                            deleteCard={deleteCard}
                            addNewCard={addNewCard}
                            handleFieldChange={handleFieldChange}
                            handleFileChange={handleFileChange}
                            initialSource={selectOptions}
                            totalSize={totalSize}
                        />
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <div className="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                  <div>
                    <div class="space-y-1">
                      <div className="w-full h-48 ">
                        <UploadImage isEdit={queryParams.contentId?true:false} removeFile={removeFile} removeName={'thumbnailPath'} accept={'image/*'} resetImages={resetImages} OnValueSelection={handleSelectFile} value={content.thumbnail ? content.thumbnail:null} name={'thumbnail'} />
                      </div>
                    </div>

                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="space-y-1 ">
                      <label className="text-xs dark:text-gray-300">
                        {t("Author")}
                      </label>
                      <div>
                        <Select
                            options={selectOptions.authorId}
                            isMulti={null}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(data)=>superValueHandler(data,'authorId',false)}
                            name={'authorId'}
                            placeholder={t("selection")}
                            value={content.authorId}
                            classNamePrefix={"h_multi_select"}
                        />
                      </div>
                    </div>
                    <div className="space-y-1 ">
                      <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                        <SettingButton
                            onClick={()=>handleSetting('GeneralSetting')}
                            tooltipTitle={t("Calendarsettings")}
                        />
                        {t("DisplayDateForThePost")}
                      </label>
                      <DateTimePicker handleDateTime={writingDateTimeHandler} name={'writingDate'} value={content.writingDate} />
                    </div>
                    <div className="md:col-span-2 space-y-1">
                      <label className="text-xs dark:text-gray-300"> {t("type")}</label>
                      <div>
                        <Select
                            options={selectOptions.type}
                            isMulti={null}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(data) =>superValueHandler(data, 'type', false)}
                            name={'type'}
                            placeholder={t("selection")}
                            value={content.type}
                            classNamePrefix={"h_multi_select"}
                        />

                      </div>
                    </div>
                    <div className="md:col-span-2 space-y-1">
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 ">
                          <label className="text-xs dark:text-gray-300">
                            {t("grouping")}
                            <RequiredIcon/>
                          </label>
                          <button type="button" onClick={() =>handleIsOpen()} className="cursor-pointer text-xs text-themeInformation dark:text-themeDarkInformation tooltip_class_GroupCreation">
                            ({t("GroupCreation")})
                            <Tooltip anchorSelect={".tooltip_class_GroupCreation"} content={t("GroupCreation")} />
                          </button>
                        </div>
                        <div>
                          <Select
                              options={selectOptions.contentGroupIds}
                              isMulti={true}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(data)=>superValueHandler(data,'contentGroupIds',true)}
                              name={'contentGroupIds'}
                              placeholder={t("selection")}
                              value={content.contentGroupIds}
                              className={"select-group"}
                              classNamePrefix={"h_multi_select"}
                          />
                        </div>

                     </div>
                    </div>
                    <div className="space-y-1">
                      <label className="text-xs dark:text-gray-300">
                        {t("FeatureTable")}
                      </label>
                      <div onClick={()=>handleValueMetaChanged()}>
                        <Select
                            options={selectOptions.metaDataGroupIds}
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(data)=>superValueHandler(data,'metaDataGroupIds',true)}
                            name={'metaDataGroupIds'}
                            placeholder={t("selection")}
                            value={content.metaDataGroupIds}
                            isDisabled={isDisable}
                            className={"select-group"}
                            classNamePrefix={"h_multi_select"}
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label className="text-xs dark:text-gray-300">
                        {t("Survey")}
                      </label>
                      <div >
                        <Select
                            isMulti={false}
                            options={selectOptions.surveyTypes}
                            isClearable={true}
                            isSearchable={true}
                            name={'surveyGroupId'}
                            placeholder={t("selection")}
                            onChange={(data)=>superValueHandler(data,'surveyGroupId',false)}
                            value={content.surveyGroupId}
                            className={"select-group"}
                            classNamePrefix={"h_multi_select"}
                        />
                      </div>
                    </div>
                    <div className="space-y-1 ">
                      <label className="text-xs dark:text-gray-300">{t("Form")}</label>
                      <div>
                        <Select
                            options={selectOptions.formId}
                            isMulti={null}
                            isClearable={false}
                            isSearchable={true}
                            onChange={(data)=>superValueHandler(data,'formId',false)}
                            name={'formId'}
                            placeholder={t("selection")}
                            value={content.formId}
                            classNamePrefix={"h_multi_select"}
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label className="text-xs dark:text-gray-300">{t("template")}</label>
                      <div>
                        <Select
                            options={selectOptions.templateId}
                            isMulti={null}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(data)=>superValueHandler(data,'templateId',false)}
                            name={'templateId'}
                            placeholder={t("selection")}
                            value={content.templateId}
                            classNamePrefix={"h_multi_select"}
                        />
                      </div>
                    </div>
                  </div>
                    <div className="post-input grid grid-cols-3 gap-x-2 gap-y-5 post-input-design p-2 dark:bg-themeDarkPostInput">
                      {/*<div className="space-y-2 col-span-2 md:col-span-3">*/}
                      {/*  <div class={"flex items-center gap-2"}>*/}
                      {/*    <label className="text-xs dark:text-gray-300">{t("ReleaseFor")}</label>*/}
                      {/*      <button type="button" className="text-themeInformation text-xs dark:text-themeDarkInformation tooltip_class_CreateFilter" onClick={() => GoToFilterPage()}>({t("CreateFilter")})*/}
                      {/*        <Tooltip anchorSelect={".tooltip_class_CreateFilter"} content={t("CreateFilter")} />*/}
                      {/*      </button>*/}
                      {/*  </div>*/}

                      {/*  <Select*/}
                      {/*      options={selectOptions.filters}*/}
                      {/*      isMulti={null}*/}
                      {/*      isClearable={true}*/}
                      {/*      isSearchable={true}*/}
                      {/*      onChange={(data)=>superValueHandler(data,'filterId',false)}*/}
                      {/*      name={'filterId'}*/}
                      {/*      placeholder={t("selection")}*/}
                      {/*      value={content.filterId}*/}
                      {/*  />*/}

                      {/*</div>*/}
                      <div className="col-span-3">
                        <div className="space-y-2">
                          <label className="text-xs dark:text-gray-300">
                            {t("SocialMediaSharing")}
                          </label>
                          <div className="space-y-1">
                            <Select
                                options={selectOptions.sendingStates}
                                isMulti={true}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>superValueHandler(data,'sendingStates',true)}
                                name={'sendingStates'}
                                placeholder={t("selection")}
                                value={content.sendingStates}
                                className={"select-group"}
                                classNamePrefix={"h_multi_select"}
                            />
                          </div>
                        </div>
                      </div>
                          <InputSwitch value={content.stateShow} OnValueSelection={InputSwitchValueHandler} lable={t("DisplayStatus")} name={'stateShow'}/>
                          <InputSwitch OnValueSelection={InputSwitchValueHandler} value={content.stateAndroid} lable={t("SendToAndroid")} name={'stateAndroid'}/>
                         <InputSwitch OnValueSelection={InputSwitchValueHandler} value={content.stateGoogle} name={'stateGoogle'} lable={t("GoogleMap")}/>

                    </div>
                </div>
              </div>
            </div>
          <ButtonSubmitAndCancel

          onCancelClick={()=>redirectTo('/CMS/Content/List')}
          />
          {/*<div className="flex justify-center gap-3 lg:justify-end shadow rounded border dark:border-themeDarkColorLine post-input dark:bg-themeDarkPostInput p-3 ">*/}
          {/*  <button type={'button'}  onClick={()=>redirectTo('/CMS/Content/List')} className="button w-24 bg-themeDanger text-white">{t("Cancel")}</button>*/}
          {/*  <button type={'submit'} className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>*/}
          {/*</div>*/}
        </div>
      </form>

      <FantasyModal openModal={IsOpen} closeModal={handleCloseIsOpen} title={t("GroupCreation")}>

        <ContentGroup
            IsOpen={IsOpen}
            handleSuccess={handleSuccess}
        />

      </FantasyModal>


      <FantasyModal openModal={modal.AddFilter} closeModal={AddFilterOnHide} title={t("CreateFilter")} customeClassName={"modal-xl"}>

          <AddFilter isReset={isReset} handleReset={handleIsReset}/>

      </FantasyModal>
      <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
        <div>
          {
            setting === 'GeneralSetting'?
                <HandleGeneralSetting/>:null
          }
        </div>
      </FantasyModal>

      <ToastContainer />
    </div>
  );
};
