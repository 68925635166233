import React from "react";
import {InputText} from "primereact/inputtext";
import {TagsInput} from "react-tag-input-component";
import {useTranslation} from "react-i18next";

export const Settings=({handleValue, handleChangeKeywords,productGroupDetail})=>{
    const { t } = useTranslation();


    return (
        <>
            {/*<div className={`tab-content__pane space-y-5 ${ tabId === "settings" ? "active" : ""}`} id="settings">*/}
                <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                    <label className="dark:text-gray-300 text-xs">{t("WindowTitle")}</label>
                    <InputText name={'seoTitle'}  value={productGroupDetail.seoTitle} onInput={(e)=>handleValue(e)}  type={"text"} className={"input w-full"} />
                </div>
                <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                    <label className="dark:text-gray-300 text-xs">{t("Keywords")}</label>
                    <TagsInput value={productGroupDetail.seoKeywords} onChange={handleChangeKeywords}   name={"seoKeywords"} placeHolder={t("Keywords")}/>
                </div>
                <div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                    <label className="dark:text-gray-300 text-xs">{t("description")}</label>
                    <InputText name={"SeoDescription"} onInput={(e)=>handleValue(e)} value={productGroupDetail.seoDescription}  type={"text"} className={"input w-full"}/>
                </div>
            {/*</div>*/}
        </>
    )
}