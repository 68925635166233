
import { Slider } from "primereact/slider";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";


export const SliderRange = ({ itemCount,handleDefaultValueChange,SortDescending,SortAccending,index, element, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement }) => (
    <>
        <Slider value={element.properties?.defaultValue?.value}
                onChange={(e) => handleDefaultValueChange(index,'value',e.value)}
                step={element.properties.max}
                min={element.properties.min}
                name={element.properties.name}
                placeholder={element?.properties?.description}
                disabled={element.properties.isReadOnly}
                className={` harmony-w-full ${index === selectedElementIndex ? 'border-themeInformation' : ''}`}
                id={element.properties.id}
                onClick={() => handleSelectTabAfterElementSelection(index)}

        />

        <div className={"harmony_body_button"}>
            <button
                type="button"
                className="button bg-themeDanger text-white harmony_button_form"
                onClick={() => deleteElement(index)}
            >
                <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
            </button>
            {
                index===0?null:
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortAccending(index)}

                    >
                        <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                    </button>

            }

            {
                index <itemCount - 1?
                    <button
                        type="button"
                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                        onClick={() => SortDescending(index)}

                    >
                        <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                    </button>:
                    null
            }

        </div>






    </>
);