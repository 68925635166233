export const resources =  {
    en: {
        translation: {
            IsClosed:'IsClosed',
            cut: 'Cut',
            copy: 'Copy',
            ShareFile: 'Share file',
            Share: 'Share',
            rename: 'Rename',
            extract: 'Extract',
            Compression: 'Compression',
            compress: 'Compress',
            Simple: 'Simple',
            SimpleId:'Simple Id',
            SimpleSlug:'Simple Slug',
            GroupGRid:'GroupGRid',
            GroupSlug:'Group Slug',
            delete: 'Delete',
            Deleted: 'Deleted',
            DeleteAll: 'Delete all',
            details: 'Details',
            fileEdit: 'Edit file',
            TotalNumber: 'Total number',
            Count: 'Count',
            StartOfFactorNumber:'StartOfFactorNumber',
            VoteCount: 'Vote count',
            Receiver:'Receiver',
            DemoAddress: 'Demo address',
            DomainAddress: 'Domain address',
            Updating: 'Updating',
            CommunicationMedium: 'Communication medium',
            isText: 'Text',
            isActive: 'Active',
            isNotActive: 'Inactive',
            Mobile: 'Mobile',
            From: 'From',
            To: 'To',
            CreationDateAndTime: 'Creation date and time',
            search: 'Search',
            Email: 'Email',
            Answer: 'Answer',
            Answers: 'Answers',
            ReturnBack: 'Return to previous step',
            userName: 'Username',
            register: 'Register',
            BankRegistration: 'Bank registration',
            RegisterAndNew: 'Register and new',
            PreviousLevel: 'Previous level',
            Name: 'Name',
            LastName: 'Last name',
            list: 'List',
            Report: 'Report',
            Reports: 'Reports',
            users: 'Users',
            creation: 'Creation',
            server: 'Server',
            servers: 'Servers',
            Package: 'Package',
            Numbers:'Numbers',
            NewDocument:'New document',
            WatchDocument:'Registered Documents',
            Status: 'Status',
            Read:'Read',
            UnRead:'UnRead',
            ReminderStatus:'ReminderStatus',
            NoteStatus:'Note Status',
            ManagementResponse: 'Management response',
            DatabaseConnectionString: 'Database connection string',
            ConnectionString: 'Connection string',
            personnel: 'Personnel',
            grouping: 'Grouping',
            Homepage: 'Homepage',
            WithForm: 'With form',
            WithTemplate: 'With template',
            WithTable: 'With table',
            StandalonePage: 'Standalone page',
            ReleaseQueue: 'Release queue',
            Published: 'Published',
            Role: 'Role',
            Weaknesses: 'Weaknesses',
            Strengths: 'Strengths',
            AuthorizedRole: 'Authorized role',
            activities: 'Activities',
            transaction: 'Transaction',
            transactions: 'Transactions',
            TransactionState:'TransactionState',
            Date: 'Date',
            special: 'Special',
            feature: 'Feature',
            features: 'Features',
            viewing: 'Watch',
            tasks: 'Tasks',
            event: 'Event',
            events: 'Events',
            menu: 'Menu',
            sending: 'Sending',
            information: 'Information',
            insertion: 'Insertion',
            comments: 'Comments',
            Inbox: 'Inbox',
            confirmed: 'Confirmed',
            notConfirmed: 'Not confirmed',
            trash: 'Trash',
            template: 'Template',
            ChangeOfOwnership: 'Change of ownership',
            CurrentOwner: 'Current owner',
            NewOwner: 'New owner',
            SiteStatus: 'Site status',
            MetaTag: 'Meta tag',
            DataHighlight: 'Data highlight',
            Slider: 'Slider',
            selection: 'Selection',
            edit: 'Edit',
            Contents: 'Contents',
            short: 'Short',
            Survey: 'Survey',
            Surveys: 'Surveys',
            Advertisement: 'Advertisement',
            Form: 'Form',
            Forms: 'Forms',
            shop: 'Shop',
            internet: 'Internet',
            product: 'Product',
            Products: 'Products',
            GatewayType:'GatewayType',
            discount: 'Discount',
            Guarantee: 'Guarantee',
            activation: 'Activation',
            Chat: 'Chat',
            ShoppingCart: 'Shopping cart',
            customers: 'Customers',
            services: 'Services',
            document: 'Document',
            Documents: 'Documents',
            invoice: 'Invoice',
            automatic: 'Automatic',
            accounting: 'Accounting',
            financial: 'Financial',
            debtors: 'Debtors',
            creditors: 'Creditors',
            other: 'Other',
            Inventory: 'Inventory',
            letterWriting: 'Letter writing',
            sendingALetter: 'Sending a letter',
            receivingBox: 'Receiving box',
            sendingBox: 'Sending box',
            archive: 'Archive',
            project: 'Project',
            projects: 'Projects',
            institution: 'Institution',
            reservation: 'Reservation',
            room: 'Room',
            rooms: 'Rooms',
            Telegram: 'Telegram',
            Telegramgrouop:'TelegramAccount Group',
            Telegramchannel: 'TelegramAccount channel',
            Instagram: 'Instagram',
            WhatsApp: 'WhatsApp',
            sendingMessage: 'Sending a message',
            smsSecretary: 'SMS secretary',
            SMS:'SMS',
            support: 'OnlineConversation',
            Department: 'Department',
            DepartmentList:'DepartmentList',
            onlineChat: 'Online chat',
            PaymentCanceledSuccess:'Payment cancelled successfully',
            file: 'File',
            manual: 'Manual',
            IranCode: 'Iran Code',
            signup: 'Signup',
            extension: 'Extension',
            Extended: 'Extended',
            Mr: 'Mr',
            Miss: 'Miss',
            MrMiss: 'Mr/Miss',
            FirstNameAndLastName: 'First name and last name',
            MobilePhone: 'Mobile phone',
            Save: 'Save',
            LandlinePhone: 'Landline phone',
            PhoneNumber: 'Phone number',
            ReferralCode: 'Referral code',
            Referrer: 'Referrer',
            NationalIdNumber: 'National ID number',
            landlineNumber: 'Landline number',
            Password: 'Password',
            LanguageType: 'Language type',
            country: 'Country',
            CalendarType: 'Calendar type',
            AddLogo: 'Add logo',
            AddIcon: 'Add icon',
            Icon: 'Icon',
            CreationDate: 'Creation Date',
            Addition: 'Addition',
            Tutorial: 'Tutorial',
            Add: 'Add',
            userType: 'User type',
            fastRegistration: 'Fast registration',
            Interactions: 'interactions',
            user: 'User',
            PrivetData: 'Privet Data',
            QuickRegistration: 'Quick Registration',
            CompanyData: 'Company Data',
            BankData: 'Bank Data',
            companyName: 'Company Name',
            ServerName: 'Server Name',
            companyNationalIdNumber: 'Company National ID Number',
            communications: 'Communications',
            GeneralSettings: 'General Settings',
            AdvancedSettings: 'Advanced Settings',
            SendingSettings: 'Sending Settings',
            SupportType: 'OnlineConversation Type',
            UserSuggestion: 'User Suggestion',
            CreateInvoice: 'Create Invoice',
            Group: 'Group',
            fatherName: 'Father Name',
            occupation: 'Occupation',
            nationality: 'Nationality',
            province: 'Province',
            city: 'City',
            education: 'Education',
            postalCode: 'Postal Code',
            Address: 'Address',
            PageAddress: 'Page Address',
            Like: 'Like',
            Dislike: 'Dislike',
            Parameter: 'Parameter',
            Parameters: 'Parameters',
            Browser: 'Browser',
            Browsers: 'Browsers',
            TypeOfRequest: 'Type of Request',
            additionalDetails: 'Additional Details',
            WebsiteDescription: 'Website Description',
            previous: 'Previous',
            PaymentRegistration: 'Payment Registration',
            Next: 'Next',
            registerAndNext: 'Register and Next',
            name: 'Name',
            brand: 'Brand',
            type: 'Type',
            MaximumWordDisplay: 'Maximum Word Display',
            ItwillBeDisplayedIfTheDisplayTypeIsSelected: 'It will be displayed if the display type is selected',
            company: 'Company',
            Supplier: 'Supplier',
            Manufacturer: 'Manufacturer',
            registrationNumber: 'Registration Number',
            confirmation: 'Confirmation',
            ConfirmAll: 'Confirm All',
            Disapprove: 'Disapprove',
            bank: 'Bank',
            banks: 'Banks',
            BankAccountNumber: 'Bank Account Number',
            cardNumber: 'Card Number',
            Passport: 'Passport',
            Sponsor: 'Sponsor',
            NationalIDCard: 'National ID Card',
            DriversLicense: 'Driver\'s License',
            Visa: 'Visa',
            ResidencePermit: 'Residence Permit',
            Contract: 'Contract',
            IdentificationCard: 'Identification Card',
            BirthCertificate: 'Birth Certificate',
            MarriageCertificate: 'Marriage Certificate',
            OtherDocuments: 'Other Documents',
            EducationalCertificate: 'Educational Certificate',
            InsurancePolicy: 'Insurance Policy',
            ProductInsuranceValue: 'Product Insurance Value',
            BankID: 'BankID',
            default: 'Default',
            row: 'Row',
            listOfContactMethods: 'List of Contact Methods',
            Comment: 'Comment',
            CommentText: 'Comment Text',
            Rating: 'Rating',
            description: 'Description',
            Title: 'Title',
            finalRegistration: 'Final Registration',
            membership: 'Membership',
            FromDate: 'From Date',
            ToDate: 'To Date',
            operations: 'Operations',
            viewAll: 'View All',
            MonthToExpired: 'Month to Expired',
            OneMonthAgo: 'One Month Ago',
            ThreeMonthsAgo: 'Three Months Ago',
            Suspended: 'Suspended',
            Blocked: 'Blocked',
            expired: 'Expired',
            incompleteFile: 'Incomplete File',
            phoneNumberVerification: 'Phone Number Verification',
            output: 'Output',
            Weekly:'Weekly',
            Close: 'Close',
            all: 'All',
            rank: 'Rank',
            PageRank: 'Page Rank',
            settings: 'Settings',
            Gallery: 'Gallery',
            Today: 'Today',
            Weight: 'Weight',
            Yesterday: 'Yesterday',
            SevenDaysAgo: 'Seven Days Ago',
            CurrentWeek: 'Current Week',
            CurrentMonth: 'Current Month',
            Important: 'Important',
            Contacts: 'Contacts',
            Explanation: 'Explanation',
            Operator: 'Operator',
            CompletionInformation: 'Completion Information',
            RightToLeft: 'Right to Left',
            LeftToRight: 'Left to Right',
            RightAlign: 'Right Align',
            LeftAlign: 'Left Align',
            Discounted: 'Discounted',
            TemplateImage: 'Template Image',
            Image: 'Image',
            ParentTheme: 'Parent Theme',
            PageType: 'Page Type',
            OnlineEditor: 'Online Editor',
            Continue: 'Continue',
            HostingSpaceAmount: 'Hosting Space Amount',
            MailServerStorageSpace: 'Mail Server Storage Space',
            SelectServer: 'Select Server',
            Return: 'Return',
            SignIn: 'Sign In',
            Reason:'reason',
            LogIn: 'Log In',
            rememberMe: 'Remember Me',
            forgetPassword: 'Forgot OtpAuthentication?',
            SignupConfirmtion: 'By registering, you agree to',
            PrivacyPolicy: 'Privacy Policy',
            conditions: 'Terms and Conditions',
            privacyPolicyDescription: '',
            logoAltTitle: 'Harmony 2023',
            SignInTitle: 'Manage all your e-commerce accounts in one place',
            Right_SideSignInTitle: 'A few more clicks',
            Right_SideSignInTitleTwo: 'to log into your account',
            PasswordConfirmtion: 'OtpAuthentication Confirmation',
            passwordRecovery: 'OtpAuthentication Recovery',
            passwordRecieveMethod: 'OtpAuthentication Receive Method',
            OtpReceive: 'Receive Code',
            TimeOver: 'Your time is over',
            Resend: 'Resend',
            Enter_Your_Code: 'Enter Your Code',
            OTP: 'One-Time Code',
            Enter_Your_Password: 'Enter Your OtpAuthentication',
            codeConfirmtion: 'Code Confirmation',
            RepeatPassword: 'Repeat OtpAuthentication',
            SafePassword: 'What is a Safe OtpAuthentication?',
            TermsAcceptance: 'I agree to the terms',
            methodOfIntroduction: 'Method of Introduction',
            Dashboard: 'Dashboard',
            generalManager: 'General Manager',
            IsInSendingQueue:'IsInSendingQueue',
            CreateAPackage: 'Create a Package',
            SelectPackage: 'Select a Package',
            PackageName: 'Package Name',
            domains: 'Domains',
            Domain: 'Domain',
            DomainOne: 'Domain 1',
            CreateAServer: 'Create a Server',
            websiteBuilder: 'Website Builder',
            generalTemplates: 'General Templates',
            WebsiteList: 'Website List',
            UserReports: 'User Reports',
            ServerStatusReport: 'Server Status Report',
            CRM: 'CRM',
            UsersRegistration: 'Users Registration',
            CustomerGrouping: 'Customer Grouping',
            CreatingRole: 'Creating a Role',
            CustomerList: 'Customer list',
            ListOfTransactions: 'List of Transactions',
            SpecialDate: 'Special Date',
            ViewingTasks: 'Viewing Tasks',
            ViewingEvents: 'Viewing Events',
            MenuManagement: 'Menu Management',
            CMS: 'CMS',
            InsertingInformation: 'Inserting Information',
            CreatingAFeature: 'Creating a Feature',
            ListOfInformation: 'List of Information',
            UserComments: 'User Comments',
            UserComment: 'User Comment',
            DetailedComments: 'Detailed Comments',
            ApprovedComments: 'Approved Comments',
            UnapprovedComments: 'Unapproved Comments',
            PrintComments: 'Print Comments',
            QuestionTitle: 'Question Title',
            Template: 'Template',
            TemplateSelection: 'Template Selection',
            Preview: 'Preview',
            Category: 'Category',
            SelectCustomer: 'Select Customer',
            SelectWebsiteCustomers: 'Select Website Customers',
            TemplateEditing: 'Template Editing',
            CreatingAShortCode: 'Creating a Short Code',
            ShortCode: 'Short Code',
            ProductCode: 'Product Code',
            CreatingDiscountCode: 'New Discount Code',
            DiscountCampaign: 'Discount Campaign',
            CreatingASurvey: 'Creating a Surveys',
            ListOfSurveys: 'List of Surveys',
            NewAdvertisements: 'New Advertisements',
            SimilarInsertion: 'Similar Insertion',
            ListAdvertisements: 'List Advertisements',
            FormManagement: 'Form Management',
            CreatingForm: 'Creating Form',
            FormWorkflow: 'Form Workflow',
            OrganizeForms: 'Organize Forms',
            ListOfSourcesInformation: 'List of Sources Information',
            DataSource: 'Data Source',
            ListOfForms: 'List of Forms',
            Of: 'of',
            ReportBuilder: 'Report Builder',
            OnlineStore: 'Online Store',
            NewProduct: 'NewProduct',
            Discounts: 'Discounts',
            NewGuaranteeCode: 'New Guarantee Code',
            CustomersGuarantee: 'Customers Guarantee',
            DiscountsAndPrizes: 'Discounts and Prizes',
            ProductCategorization: 'Product Categorization',
            ListOfProducts: 'List of Products',
            ProductBarcode: 'Product Barcode',
            InventoryStockReport: 'Inventory Stock Report',
            InventoryStock: 'Inventory Stock',
            GuarantyReport: 'Guaranty Report',
            CustomerReport: 'Customer Report',
            SalesCollaboration: 'Sales Collaboration',
            ServiceRegistration: 'Service Registration',
            ProductList: 'Product List',
            SalesCollaborationScript: 'Sales Collaboration Script',
            ServiceList: 'Service List',
            DocumentManagement: 'Document Management',
            DocumentRegistration: 'Document Registration',
            DocumentList: 'Document List',
            InvoiceRegistration: 'Invoice Registration',
            InvoiceList: 'Invoice List',
            AutomaticRegistration: 'Automatic Registration',
            AccountingManagement: 'Accounting Management',
            FinancialReports: 'Financial Reports',
            FinancialReport: 'Financial Report',
            AccountReceivableReport: 'Account Receivable Report',
            InventoryManagement: 'Inventory Management',
            GoodsRegistration: 'Goods Registration',
            CorrespondenceManagement: 'Correspondence Management',
            ProjectManagement: 'Project Management',
            ProjectCreation: 'Project Creation',
            ProjectReports: 'Project Reports',
            SchoolManagement: 'School Management',
            ClassCreation: 'Class Creation',
            ListOfClasses: 'List of Classes',
            CenterCreation: 'Center Creation',
            ListOfCenters: 'List of Centers',
            ListOfReservations: 'List of Reservations',
            ListOfRooms: 'List of Rooms',
            FileManagement: 'File Management',
            AttachmentFile: 'Attachment File',
            UserCode: 'User Code',
            RegistrationDate: 'Registration Date',
            RenewalDate: 'Renewal Date',
            ExpirationDate: 'Expiration Date',
            AdditionalInformation: 'Additional Information',
            BrandName: 'Brand Name',
            ServerType: 'Server Type',
            CompanyType: 'Company Type',
            DiscountType: 'Discount Type',
            BankName: 'Bank Name',
            ListOfBanks: 'List of Banks',
            BankCreation: 'Bank Creation',
            BankAccountCreation:'bank account creation',
            CommunicationType: 'Communication Type',
            SendingType: 'Sending Type',
            TypeIndividualLegal: 'Type Individual/Legal',
            ContactAddress: 'Contact Address',
            EventManagement: 'Event Management',
            EventDate: 'Event Date',
            ReminderDate: 'Reminder Date',
            StartDate: 'Start Date',
            EndDate: 'End Date',
            DocumentUpload: 'Document Upload',
            MembershipSinceDate: 'Membership Since Date',
            MembershipUntilDate: 'Membership Until Date',
            Individuals: 'Individuals',
            LegalEntities: 'Legal Entities',
            Public: 'Public',
            Private: 'Private',
            RoleTitle: 'Role Title',
            SurveyTitle: 'Survey Title',
            Elmah: 'Error Page',
            TagTitle: 'Tag Title',
            WriteYourMetaGroup: 'Write Your Meta Group',
            TotalVote: 'Total Yes Votes',
            RelevantVote: 'Relevant Yes Votes',
            Agree: 'Agree',
            Disagree: 'Disagree',
            RoleWorkgroup: 'Role Workgroup',
            AccessToInformation: 'Access to Information',
            ViewList: 'View List',
            CommonFilters: 'Common Filters',
            Access: 'Access',
            Accesses: 'Accesses',
            AuthorizedAccess: 'Authorized Access',
            GroupCreation: 'Group Creation',
            GroupEdit: 'Group Edit',
            TemplateCreation: 'Template Creation',
            FileUpload: 'File Upload',
            Upload: 'Upload',
            IP: 'IP',
            ID: 'ID',
            CloseAll: 'Close All',
            Cancel: 'Cancel',
            SureToCancel:'Are you sure to cancel ? ',
            YesCancelit:'Yes Cancel it',
            Authorized: 'Authorized',
            Unauthorized: 'Unauthorized',
            SpecialAccess: 'Special Access',
            SixMonthsToExpiry: 'Six Months to Expiry',
            OneYearToExpiry: 'One Year to Expiry',
            Available: 'Available',
            Unavailable: 'Unavailable',
            PurchaseAlert: 'Purchase Alert',
            BestsellingProducts: 'Bestselling Products',
            RelatedProducts: 'Related Products',
            DataRegistration: 'Data Registration',
            NoteTitle: 'Note Title',
            DateTime: 'Date/Time',
            ReminderDataTime:'Reminder date/time',
            NotificationMethod: 'Notification Method',
            NoteText: 'Note Text',
            SendingMethod: 'Sending Method',
            SelectSendingMethod:'SelectSendingMethod',
            CreateNote: 'Create note',
            Active: 'Active',
            Inactive: 'Inactive',
            Cyclic: 'Cyclic',
            Sent: 'Sent',
            NotSent: 'Not Sent',
            FollowUpPriority: 'Follow-Up Priority',
            DisplayPriority: 'Display Priority',
            PagePriority: 'Page Priority',
            Time: 'Time',
            SearchByTitle: 'Search By (Title)',
            SearchByNameMessage: 'Search By (Name-Message)',
            SearchByNameTemplate: 'Search By (Name-Template)',
            SearchByNameAdvertise: 'Search By (Name-Advertisement)',
            BasedOnPrice: 'Based On Price',
            BasedOnDiscount: 'Based On Discount',
            ReactProject: 'React Project',
            TailwindCSSProject: 'TailwindCSS Project',
            BootstrapProject: 'Bootstrap Project',
            ArticleTitle: 'Article Title',
            EditArticleTitle: 'Edit Article Title',
            ArticleAddress: 'Article Address',
            SaveEdit: 'Save Edit',
            MapAddress: 'Map Address',
            TextSummary: 'Text Summary',
            SummaryExplanation: 'Summary Explanation',
            FullText: 'Full Text',
            Main: 'Main',
            WindowTitle: 'Window Title',
            Keywords: 'Keywords',
            MetaTagDescriptions: 'Meta Tag Descriptions',
            Lead: 'Lead',
            RelatedContent: 'Related Content',
            Conversations: 'Conversations',
            TagName: 'Tag Name',
            ProductName: 'Product Name',
            Note: 'Note',
            Notes: 'Notes',
            Reminder: 'Reminder',
            Reminders: 'Reminders',
            Website: 'Website',
            Websites: 'Websites',
            NameCompany: 'Name/Company',
            TypeOfInformation: 'Type of Information',
            Text: 'Text',
            Price: 'Price',
            DiscountAmount: 'Discount Amount',
            DiscountDate: 'Discount Date',
            FileType: 'File Type',
            FileGroup: 'File Group',
            Location: 'Location',
            TemplateType: 'Template Type',
            OnlineSupport: 'Online OnlineConversation',
            LicenseInformation: 'License Information',
            LicenseDuration: 'License Duration',
            ValidityPeriod: 'Validity Period',
            BuyATemplate: 'Buy a Template',
            CommonCases: 'Common Cases',
            SearchByAccountingCode: 'Search By (Accounting Code)',
            BasedOnPackageName: 'Search By (Package Name)',
            Origin: 'Origin',
            Destination: 'Destination',
            Commission: 'Commission',
            PublicationDate: 'Publication Date',
            ReleaseFor: 'Release For',
            Publishing: 'Publishing',
            Length: 'Length',
            Width: 'Width',
            Height: 'Height',
            // Author: 'Author',
            DisplayDateForThePost: 'Display Date for the Post',
            FeatureTable: 'Feature Table',
            Table: 'Table',
            Display: 'Display',
            CreateFilter: 'Create Filter',
            SocialMediaSharing: 'Social Media Sharing',
            page: 'Page',
            DisplayStatus: 'Display Status',
            isConfigured:'Configured',
            SendToAndroid: 'Send to Android',
            GoogleMap: 'Google Map',
            ShowResult: 'Show Result',
            VotingLimit: 'Voting Limit',
            FreeShipping: 'Free Shipping',
            SalesBasedOnInventory: 'Sales Based on Inventory',
            NotEnoughWalletBalance:'The wallet balance is not enough',
            Minimum: 'Minimum',
            Maximum: 'Maximum',
            TemplateTitle: 'Template Title',
            WebsiteDirection: 'Website Direction',
            TemplateFolderName: 'Template Folder Name',
            PageFileName: 'Page File Name',
            FileName: 'File Name',
            File: 'File',
            Direction: 'Direction',
            LatitudeAndLongitude: 'Latitude and Longitude',
            Internal: 'Internal',
            External: 'External',
            PleaseSelectBank:'please select bank',
            AuthorizedIPAddresses: 'Authorized IP Addresses',
            WebsiteTitle: 'Website Title',
            FilterTitle: 'Filter Title',
            Part: 'Part',
            Field: 'Field',
            Condition: 'Condition',
            Value: 'Value',
            Banner: 'Banner',
            Photo: 'Photo',
            PageCreation: 'Page Creation',
            Download: 'Download',
            PageTitle: 'Page Title',
            Recovery: 'Recovery',
            Run: 'Run',
            CurrencyUnit: 'Currency Unit',
            CurrencyType:'CurrencyType',
            ReminderList: 'Reminder List',
            groupPriceChange: 'Group Price Change',
            UnitPrice: 'Unit Price',
            WholesalePrice: 'Wholesale Price',
            basicInformation: 'Basic Information',
            introductionOfBankAccounts: 'Introduction of Bank Accounts',
            financialChapters: 'Financial Chapters',
            interbankTransfer: 'Interbank Transfer',
            registeredChecks: 'Registered Checks',
            checkCollection: 'Check Collection',
            checkReminders: 'Check Reminders',
            interbankTransactions: 'Interbank Transactions',
            financialBalance: 'Financial Balance',
            ProcedureCreation: 'Procedure Creation',
            rackConstruction: 'Rack Construction',
            hotelReservation: 'Hotel Reservation',
            digitalMarketing: 'Digital Marketing',
            shortMessageService: 'Short Message Service',
            creatingATemplate: 'Creating a Template',
            addingATextMessageLine: 'Adding a Text Message Line',
            SEO: 'SEO',
            introductionToGoogle: 'Introduction to Google',
            redirect: 'Redirect',
            Typeredirect:'Type redirect',
            statisticsAndVisits: 'Statistics and Visits',
            ViewStatistics: 'View Statistics',
            IPRestriction: 'IP Restriction',
            dropFilesHereOrClickToUpload: 'Drop Files Here or Click to Upload',
            UploadedFiles: 'Uploaded Files',
            Specifications: 'Specifications',
            QuickAccess: 'Quick Access',
            SearchByNameProductCode: 'Search By (Name-Product Code)',
            SearchByNameGroup: 'Search By (Name-Group)',
            SearchByNameCode: 'Search By (Name-Code)',
            SixMonthsToExpiration: 'Six Months to Expiration',
            OneYeartoExpiration: 'One Month to Expiration',
            Draft: 'Draft',
            SizeAndDimensions: 'Size and Dimensions',
            ChartManagement: 'Chart Management',
            ProductGroup: 'Product Group',
            SubcategoryType: 'Subcategory Type',
            ExampleColorOrSize: 'Example: Color or Size',
            ExampleSizeXLOrColorCode: 'Example: Size XL or Color Code',
            Monthly: 'Monthly',
            ThreeMonths: 'Three Months',
            SixMonths: 'Six Months',
            OneYear: 'One Year',
            AddingAChart: 'Adding a Chart',
            SuccessfulPayment: 'Successful Payment',
            UnsuccessfulPayment: 'Unsuccessful Payment',
            TodaySales: 'Today Sales',
            ViewInvoice: 'View Invoice',
            HasPrize:'HasPrize',
            websiteSelection:'websiteSelection',
            NoPrize:'NoPrize',
            IsWholeSale:'IsWholeSale',
            IsRetail:'IsRetail',
            CustomerName: 'Customer Name',
            RequestStatus: 'Request Status',
            ShippingStatus: 'Shipping Status',
            PaymentType: 'Payment Type',
            BankSelection: 'Bank Selection',
            OnlinePaymentAmount: 'Online Payment Amount',
            WalletDeduction: 'WalletCharge Deduction',
            DiscountCode: 'Discount Code',
            ProductImage: 'Product Image',
            TotalAmount: 'Total Amount',
            MainPrice:'MainPrice',
            NotValidBillAmount:'The amount of bill is not valid ',
            DiscountedPrice:'DiscountedPrice',
            StoreName: 'Store Name',
            NationalEconomicCode: 'National Economic Code',
            AddProduct: 'Add Product',
            ShippingCost: 'Shipping Cost',
            Toman: 'Toman',
            Rial: 'Rial',
            TotalPurchaseAmount: 'Total Purchase Amount',
            PaidAmount: 'Paid Amount',
            ShippingOrder: 'Shipping Order',
            ContactOrder: 'Contact Order',
            InventoryReceiptPrinting: 'Inventory Receipt Printing',
            AccountingDocumentPrinting: 'Accounting Document Printing',
            SerialCreation: 'Serial Creation',
            RequiredSerialQuantity: 'Required Serial Quantity',
            SeriesGeneration: 'Series Generation',
            Series: 'Series',
            Calculation: 'Calculation',
            SerialProduction: 'Serial Production',
            ExcelDownload: 'Excel Download',
            DownloadText: 'Download Text',
            Notification: 'Notification',
            GuaranteeRegistration: 'Guarantee Registration',
            BuyersName: 'Buyers Name',
            GuaranteeCode: 'Guarantee Code',
            GuaranteePeriod: 'Guarantee Period',
            PurchaseDate: 'Purchase Date',
            History: 'History',
            BasedOnTheDate: 'Based on the Date',
            SentToProductionLine: 'Sent to Production Line',
            Sendtoproduction:'Send to production',
            Filter: 'Filter',
            DiscountFor: 'Discount For',
            DiscountOn:'Discount On',
            SpecifyDiscountCode: 'Specify Discount Code',
            UsageCount: 'Usage Count',
            MaximumDiscount: 'Maximum Discount',
            MinimumPurchase: 'Minimum Purchase',
            CampaignTitle: 'Campaign Title',
            SetInterval: 'Set Interval',
            Priority: 'Priority',
            TargetAudience: 'Target Audience',
            Conditions: 'Conditions',
            StepIncrease: 'Step Increase',
            Unit: 'Unit',
            Law: 'Law',
            CreateCommissionLaw: 'Create Commission Law',
            CommissionFor: 'Commission For',
            TypeOfCommission: 'Type Of Commission',
            CreateAField: 'Create A Field',
            Attributes: 'Attributes',
            FormSettings: 'Form Settings',
            Standard: 'Standard',
            SingleLineText: 'Single Line Text',
            Paragraph: 'Paragraph',
            Upgrade: 'Upgrade',
            Profile: 'Profile',
            Tickets: 'Tickets',
            Guide: 'Guide',
            Logout: 'Logout',
            Images: 'Images',
            SocialMedia: 'Social Media',
            AdditionalScript: 'Additional Script',
            ShortcutKey: 'Shortcut Key',
            Shortcut:'Shortcut',
            SecurityAndPrivacy: 'Security and Privacy',
            SupportAndRecovery: 'OnlineConversation and Recovery',
            TemporaryDataCleanup: 'Temporary Data Cleanup',
            Routing: 'Routing',
            SmartMessaging: 'Smart Messaging',
            ShippingMethods: 'Shipping Methods',
            Tax: 'Tax',
            BulkTransfer: 'Bulk Transfer',
            CreateRule: 'Create Rule',
            Highlighter: 'Highlighter',
            BankGateway: 'Bank Gateway',
            StoreManagement: 'Store Management',
            CustomerManagement: 'Customer Management',
            GeographicRegion: 'Geographic Region',
            Language: 'Language',
            Orientation: 'Orientation',
            FontSize: 'Font Size',
            ChooseColorTheme: 'Choose Color Theme',
            SelectFont: 'Select Font',
            CalendarLanguage: 'Calendar Language',
            DisplayMode: 'Display Mode',
            EditorType: 'Editor Type',
            WatermarkText: 'Watermark Text',
            AddFont: 'Add Font',
            FontFile: 'Font File',
            DefaultImages: 'Default Images',
            DefaultTemplate: 'Default Template',
            ValidIPs:'Valid IPs',
            OwnerCompany:'Owner/Company',
            MailServerSpace:'MailServer Space',
            HostingSpace:'Hosting Space',
            DataNoteFound:'Data Note Found',
            DisplayOrder:'Display Order',
            SuccessfulPurchase:'Successful Purchase',
            Amount:'Amount',
            TrackingCode:'Tracking Code',
            TransactionDate:'Transaction Date',
            TransactionReference:'TransactionReference',
            PaymentStatus:'Payment Status',
            Sender:'Sender',
            Responsibility:'Responsibility',
            Serial:'Serial',
            MenuTitle:'MenuTitle',
            GroupTitle:'Group Title',
            VisitCount:'VisitCount',
            // Author:'Author',
            ApprovalDisapproval:'Approval/Disapproval',
            TagContent:'Tag Content',
            LastEdit:'Last Edit',
            // TitleShortCode:'Note: Esteemed colleagues, this section has been designed to prevent the repetition of common codes across all pages of the website.',
            TitleShortCodeTwo:'Please note that to create a shortcode, use English words.',
            Question:'Question',
            QuestionText:'Question Text',
            NumberofParticipants:'Number of Participants',
            AdvertisingFile:'Advertising File',
            GeographicLongitudeandLatitude:'Geographic Longitude and Latitude',
            Right:'Right',
            WebsiteName:'Website Name',
            Media:'Media',
            Marketing:'Marketing',
            ListSourcesInformation:'List Sources Information',
            AvailableUnavailable:'Available/Unavailable',
            FinalPrice:'Final Price',
            CreateFile:'Create File',
            Orderer:'Orderer',
            UserDescriptions:'User Descriptions',
            OnlinePayment:'Online Payment',
            PackageDispatch:'Package Dispatch',
            Fee:'Fee',
            BuyerInformation:'Buyers Information',
            SellerInformation:'Seller Information',
            Created:'Created',
            ProductionLine:'Production Line',
            ProductionSeries:'Production Series',
            NumberofGuaranteeCodes:'Number of Guarantee Codes',
            NumberofExtensions:'Number of Extensions',
            ActivationDate:'Activation Date',
            GuaranteeStatus:'Guarantee Status',
            OperationDate:'Operation Date',
            ExtensionDate:'Extension Date',
            GuaranteeExtensionDuration:'Guarantee/Extension Duration',
            OperationType:'Operation Type',
            DiscountTitle:'Discount Title',
            CreateCampaign:'Create Campaign',
            EmailText:'Email Text',
            Marked:'Marked',
            CreateEmail:'Create Email',
            SendtoGroup:'Send to Group',
            SendtoIndividuals:'Send to Individuals',
            Facebook:'Facebook',
            PageorGroup:'Page or Group',
            Connect:'Connect',
            PostArticle:'Post Article',
            AddAccount:'Add Account',
            Account:'Account',
            YouTube:'YouTube',
            LinkedIn:'LinkedIn',
            Channel:'Channel',
            PageorProfile:'Page or Profile',
            Twitter:'Twitter',
            ChooseYourAccountType:'Choose Your Account Type',
            FacebookPage:'Facebook Page',
            FaceBookPageTitle:'A public profile that Facebook users can "Like".',
            FaceBookGroupTitle:'A forum where Facebook users join as members.',
            LinkedInTitle:'A company profile that LinkedIn users can follow.',
            FacebookGroup:'Facebook Group',
            LinkedInPage:'LinkedIn Page',
            LinkedInProfile:'LinkedIn Profile',
            LinkedInProfileTitle:'A profile that showcases an individual\'s professional experience.',
            MessageText:'Message Text',
            stateGoogle:'stateGoogle',
            SendingDate:'Sending Date',
            TextType:'Text Type',
            SearchByText:'Search By (Text)',
            MediaType:'Media Type',
            Week:'Week',
            Month:'Month',
            Youonthedate:'You on the date',
            Youcreatedamessage:'You created a message',
            Yourregistrationfor:'Your registration for',
            SuccessfullyDeleted:'Successfully deleted',
            hasbeenset:'has been set',
            SystemNumber:'System Number',
            SMSCharge:'SMS Charge',
            SMSNote1:'Note 1: To send bulk SMS, first send a test SMS to your own phone. Otherwise, you are responsible for the SMS sending process.',
            SMSNote2:'Note 2: In the list of sent numbers, each number must be separated by Enter.',
            EnteringNumbers:'Entering Numbers',
            Configurator:'Configurator',
            SendTo:'Send to',
            NumberofSMS:'Number of SMS',
            NumberofCharacters:'Number of Characters',
            Guidance:'Guidance',
            SendingTime:'Sending Time',
            UserID:'User ID',
            OriginalLink:'Original Link',
            SearchByAddress:'Search By (Address)',
            SearchByDepartmentTitle:'Search By (Department Title)',
            Permanent:'Permanent',
            Temporary:'Temporary',
            Newredirect:'Newredirect',
            Linkredirect:'Link Redirect',
            PreviousLink:'Previous Link',
            NewLink:'New Link',
            TitleLink:'Title Link',
            Persian:'Persian',
            English:'English',
            MonthlyReport:'Monthly Report',
            DailyVisits:'Daily Visits',
            TrafficConsumption:'Traffic Consumption',
            SixMonthReport:'Six-Month Report',
            Quarterlyreport:'Quarterly report',
            AnnualReport:'Annual Report',
            AccountSummary:'Account Summary',
            DomainType:'Domain Type',
            ConsumedVolume:'Consumed Volume',
            OverallStatistics:'Overall Statistics',
            TotalVisitors:'Total Visitors',
            NumberofComments:'Number of Comments',
            NewVisitors:'New Visitors',
            AverageVisitDuration:'Average Visit Duration',
            Friends:'Friends',
            TicketNumber:'Ticket Number',
            TicketTitle:'Ticket Title',
            ViewConversation:'View Conversation',
            Ticket:'Ticket',
            TicketList:'Ticket List',
            Answered:'Answered',
            UnderReview:'Under Review',
            Call:'Call',
            OnlineTicketTitle:'Please select the ticket type first.',
            OnlineTicketdepartmentTitle:'Please select the desired department first.',
            Content:'Content',
            Financial:'Financial',
            HotelRestaurant:'Hotel,Restaurant',
            TypeofServices:'Type of ServiceItem',
            TicketSubject:'Ticket Subject',
            TicketDate:'Ticket Date',
            Subject:'Subject',
            TicketDepartment:'Ticket Department',
            Normal:'Normal',
            Urgent:'Urgent',
            DepartmentTitle:'Department Title',
            NewDepartment:'New Department',
            Paste:'Paste',
            CreateFolder:'Create Folder',
            AllItems:'All Items',
            Videos:'Videos',
            Video:'Video',
            Yourtexthasbeencopied:'Your text has been copied',
            CopyAddress:'Copy Address',
            Noinformationfound:'No information found...',
            Detailsoftheselectedfolder:'Details of the selected folder',
            FileSize:'File Size',
            LastModifiedDate:'Last Modified Date',
            FileDetails:'File Details',
            Chooseanameforyourfolder:'Choose a name for your folder',
            FolderName:'Folder Name',
            DuplicateFile:'DuplicateFile',
            DuplicateFileTitle:'Dear user, your selected file is duplicated. Please choose one of the following options:',
            ReplaceFile:'Replace File',
            RenameFile:'Rename File',
            FiletoPath:'File to Path',
            Thefileisduplicated:'The file is duplicated',
            commisionCurrency:'CommissionCurrency',
            HeaderLogo:'Header Logo',
            FooterLogo:'Footer Logo',
            SiteIcon:'Site Icon',
            DefaultArticleImage:'Default Article Image',
            ProfilePicture:'Profile Picture',
            Gender:'Gender',
            sliderSettingTitle:'Please use an image that suits your template.',
            BannerTitle:'Banner Title',
            ExternalLink:'External Link',
            CommunicationMethod:'CommunicationMethod',
            AddressLinkIDNumber:'Address,Link,ID,Number',
            ChangePassword:'Change OtpAuthentication',
            OldPassword:'Old OtpAuthentication',
            NewPassword:'New OtpAuthentication',
            ConfirmPassword:'Confirm OtpAuthentication',
            PasswordType:'OtpAuthentication Type',
            ChoosePassword:'Choose OtpAuthentication',
            StaticIP:'Static IP',
            StaticIPTitle:'By configuring this section, website management will only be accessible from the entered IP.',
            AuthenticatiorTitle:'To enable two-factor authentication, configure its settings once and log in.',
            AuthenticatiorTitleTwo:'Use authenticator apps to receive one-time codes.',
            InitialSetup:'Initial Setup',
            SecurityKeyTitle:'By using security keys, such as Yubikey, or setting up keys on your mobile phone, you can enhance the security of your account.',
            SecurityKeyTwo:'To enable and use two-factor authentication, configure its settings once.',
            AddingSecurityKey:'Adding Security Key',
            PhoneCallorTextMessage:'Phone Call or Text Message',
            PhoneCallorTextMessageTitle:'You will receive a two-factor authentication code via phone call or text message to the number: 01321321452.',
            SecurityEmailTitle:'An email containing the two-factor authentication code will be sent to your email address.',
            Backup:'Backup',
            DownloadLink:'Download Link',
            FileTitle:'File Title',
            FileSelection:'File Selection',
            hour:'hour',
            minute:'minute',
            second:'second',
            DayandName:'Day and Name',
            MonthandName:'Month and Name',
            Numeric:'Numeric',
            PostName:'Post Name',
            CustomStructure:'Custom Structure',
            AvailableTags:'Available Tags',
            CreateSMS:'Create SMS',
            ChooseTemplate:'Choose Template',
            DataType:'Data Type',
            LandingPage:'Landing Page',
            CodeText:'Code Text',
            DispatchManagement:'Dispatch Management',
            TypeofDispatch:'Type of Dispatch',
            SetAmount:'Set Amount',
            ServiceAddress:'Set Amount',
            TaxTitle:'Value Added Tax (VAT) for Tax Application',
            Duties:'Duties',
            TypeofLaw:'Type of Law',
            UserGroup:'User Group',
            Personal:'Personal',
            SalesAmountinRange:'Sales Amount in Range',
            Staircase:'Staircase',
            Fixed:'Fixed',
            SearchByGatewayName:'Search By (Gateway Name)',
            Yuan:'Yuan',
            Dollar:'Dollar',
            Euro:'Euro',
            Pound:'Pound',
            CreateBankGateway:'Create Bank Gateway',
            SecurityInformation:'Security Information',
            BulkEmail:'Bulk Email',
            GmailAddress:'GmailAddress',
            WebsiteManagement:'Website Management',
            WebsiteManagementTitle:'One-Year Management of Your System',
            SocialMediaManagement:'Social Media Management',
            SocialMediaManagementTitle:'One-Year Management of Your Social Media',
            BrandRegistration:'BrandRegistration',
            BrandRegistrationTitle:'In the Intellectual Property Registration System',
            YourWebsiteSpace:'Your Website Space',
            YourWebsiteSpaceTitle:'Increasing Your Hosting Capacity',
            IncreasingEmailServerSpace:'Increasing Email Server Space',
            IncreasingEmailServerSpaceTitle:'Purchase Additional Space for Your Emails',
            PurchaseSMSLine:'Purchase SMS Line',
            PurchaseSMSLineTitle:'Choose Your Own System Line',
            SMSPanelRecharge:'SMSPanelRecharge',
            PanelRecharge:'Panel Recharge',
            SMSPanelRechargeTitle:'Directly Recharge Your Lines',
            AddLanguage:'SMSPanelRecharge',
            AddLanguageTitle:'Create a New Language for the Website',
            ProductPhotography:'Product Photography',
            ProductPhotographyTitle:'Exclusive Photography of Your Products',
            Editing:'Editing',
            EditingTitle:'Editing Your Existing Archive Videos',
            Clip:'Clip',
            ClipTitle:'Creating Various Advertising Clips',
            TelevisionCommercial:'Television Commercial',
            TelevisionCommercialTitle:'Creating Various Television Teasers',
            VirtualTour:'Virtual Tour',
            VirtualTourTitle:'360° Virtual Tour of Your Location',
            CustomTemplateDesign:'Custom Template Design',
            CustomTemplateDesignTitle:'Designing and Building Your Custom Template',
            OrderingLogoDesign:'Ordering Logo Design',
            OrderingLogoDesignTitle:'Creating Your Exclusive Logo',
            OrderingCatalog:'Ordering Catalog',
            OrderingCatalogDesignTitle:'Creating a Digital Catalog for Your Products',
            DesigningBanners:'Designing Banners',
            DesigningFestiveBannerstitle:'Design for Various Occasions',
            WebsiteBannerDesign:'Website Banner Design',
            WebsiteBannerDesignTitle:'Homepage Banner Design',
            BillboardDesign:'Billboard Design',
            BillboardDesignTitle:'Designing Various City Billboards',
            PostDesign:'Post Design',
            PostDesignTitle:'Designing Various Instagram/TelegramAccount Posts',
            Consultation:'Consultation',
            ConsultationTitle:'Creating Your Exclusive Logo',
            SymbolRequest:'Symbol Request',
            SymbolRequesttitle:'Trust Symbol, Accreditation, or Internet License',
            RequestforBankGateway:'Request for Bank Gateway',
            RequestforBankGatewayTitle:'Acquiring Your Bank Gateway',
            ChooseYourPrograms:'Choose Your Programs',
            UptoDateAccesstoOurServices:'Immediate and Up-to-Date Access to Our ServiceItem',
            Modules:'Modules',
            SmartManagementServices:'Smart Management ServiceItem',
            Resources:'Resources',
            MediaServices:'Media ServiceItem',
            OurServices:'Our ServiceItem',
            OtherServices:'Other ServiceItem',
            SelectedProgram:'Selected Program',
            OnlineGateways:'Online Gateways',
            WithdrawalRequest:'Withdrawal Request',
            BaseTariff:'Base Tariff',
            TariffPrice:'Base Tariff',
            Tariffcreation:'Tariff creation',
            Wallet:'Wallet',
            OperatorName:'Operator Name',
            APIAddress:'API Address',
            ReturnPageAddress:'Return Page Address',
            Gateways:'Gateways',
            StaticData:'Static Data',
            VariableName:'Variable Name',
            CreateOperator:'Create Operator',
            GeneralReport:'General Report',
            Reload:'Reload',
            ReceptionCashBox:'Reception Cash Box',
            MonthlyStatistics:'Monthly Statistics',
            QuarterlyStatistics:'Quarterly Statistics',
            SixMonthStatistics:'Six-Month Statistics',
            ConnectedOperatingSystems:'Connected-OS',
            Loading:'Loading',
            TimeInterval:'Time Interval',
            AdvancePayment:'Advance Payment',
            FullPayment:'Full Payment',
            Paytitledisceriotion:'In this section, we provide you with capabilities',
            NumberofUniqueRandomNumbers:'Number of Unique/Random Numbers',
            LanguageRequest:'Language Request',
            TypeofPhotography:'Type of Photography',
            RawFileTime:'Raw File Time',
            OutputFileTime:'Output File Time',
            NumberofClips:'Number of Clips',
            AverageOutputTime:'Average Output Time',
            NumberofLocations:'Number of Locations',
            MainColor:'Main Color',
            SecondaryColor:'Secondary Color',
            ThirdColor:'Third Color',
            Whatsitesdoyoulike:'What sites do you like?',
            Gateway:'Gateway',
            NumberofPages:'Number of Pages',
            NumberofCover:'Number of Covers',
            AwaitingConfirmation:'Awaiting Confirmation',
            Completed:'Completed',
            AccountType:'Account Type',
            TransactionNumber:'Transaction Number',
            TransactionType:'Transaction Type',
            TransactionAmount:'Transaction Amount',
            WithdrawalConfirmation:'Withdrawal Confirmation',
            PaymentDetails:'Payment Details',
            TransactionCode:'Transaction Code',
            ThisMonth:'This month',
            thisYear:'This year',
            ThisWeek:'This week',
            Receive:'Receive',
            Payment:'Payment',
            BackupVersion:'Backup Version',
            ReceiveTemplate:'Receive Template',
            Settingdigitalmarketingexample:'Sending a Smart Message Upon Membership',
            SettingdigitalmarketingTitle:'By specifying a user-requested phrase,',
            SettinginstagramTitleRequest:'Purchase Code or Purchase Request',
            SettinginstagramTitleexample:'For example: Product code = *',
            SettingtelegramTitle:'Note:first add the Arian bot as an admin in your channel (@ariandeveloperbot).',
            ChannelID:'Channel ID',
            SupportChannelID:'OnlineConversation Channel ID',
            SettingtelegramTitletwo:'Sending a Smart Message Upon Joining',
            Viewthetrainingvideo:'View the training video',
            Returnprevious:'Return to the previous page',
            ExportPDF:'Export To PDF',
            ExportExcel:'Export To Excel',
            ExportWord:'Export To Word',
            TextOutput:'Export To Text',
            ExportCsv:'Export To CSV',
            New:'New',
            Searchformenu:'Search in menu',
            Deposit:'Deposit',
            Withdrawal:'Withdrawal',
            Remaining:'Remaining',
            BankAccount:'Bank Account',
            Praise:'Praise',
            ToWallet:'To WalletCharge',
            PaymentMethod:'Payment Method',
            TodaysDeposits:'Today\'s Deposits',
            DestinationAddress:'Destination Address',
            TodaysWithdrawals:'Today\'s Withdrawals',
            EventRegistration:'Event Registration',
            UploadTemplate:'Upload Template',
            DownloadTemplate:'Download Template',
            RollbackPreviousVersion:'Rollback to Previous Version',
            RunCode:'Run Code',
            ViewSubcategories:'View Subcategories',
            CloseSubcategories:'Close Subcategories',
            EditUser:'Edit User',
            DeleteUser:'Delete User',
            UserInteractions:'User Interactions',
            AddComment:'Add Comment',
            ApproveSelectedComments:'Approve Selected Comments',
            DisapproveSelectedComments:'Disapprove Selected Comments',
            QuickUserRegistration:'Quick User Registration',
            CompleteDataRegistration:'Complete User ِData Registration',
            CompanyUserDataRegistration:'Company User Data Registration',
            UserBankDataRegistration:'User Bank Data Registration',
            UserContactDataRegistration:'User Contact Data Registration',
            UserEventsRegistration:'User Events Registration',
            SendUserDataandDocuments:'SendUser Data and Documents',
            AddNewBanktoList:'Add a New Bank',
            ApplySearch:'Apply Search',
            ResetSearch:'Reset Search',
            ReturntoHomePage:'Return to Home Page',
            CopyPageURL:'Copy Current Page URL',
            Viewtheform:'View the form',
            Person:'Person',
            TypePerson:'TypeofPerson',
            SourceTitle:'Source Title',
            DefaultValue:'Default Value',
            DisplayName:'Display Name',
            TitleParameter:'Title Parameter',
            ValueParameter:'ValueParameter',
            GroupParameter:'Group Parameter',
            FormSelection:'Form Selection',
            Grouped:'Grouped',
            PositionCategory:'Position (Category)',
            SelectReport:'Select Report',
            FormAddress:'Form Address',
            ExternalFrame:'External Frame',
            RecordCount:'Record Count',
            FormName:'Form Name',
            InternalForm:'InternalForm',
            ExternalForm:'External Form',
            InputData:'Input Data',
            ReportTitleFilter:'Report Title (Filter)',
            Custom:'Custom',
            UserRegistration:'User Registration',
            DownloadSampleFile:'Download Sample File',
            UploadGuaranteeCodes:'Upload Guarantee Codes',
            EmailSettings:'Email Settings',
            DataCleanup:'Data Cleanup',
            Pleaseselectpermissions:'Please select your desired access permissions.',
            Pleaseselectprovince:'Please select province',
            PleaseenterIPformat:'Please enter a valid IP format.',
            Yourserverhasbeensuccessfullyedited:'Your server has been successfully edited.',
            Yourserverhasbeensuccessfullyregistered:'Your server has been successfully registered.',
            Yourpackagesuccessfullyedited:'Your package has been successfully edited.',
            Yourpackagehasregistered:'Your package has been successfully registered.',
            Yourpackagehasdeleted:'Your package has been successfully deleted.',
            UserCapacity:'User Capacity',
            RAM:'RAM',
            HDD:'HDD',
            SSD:'SSD',
            CurrentPassword:'CurrentPassword',
            Capacity:'Capacity',
            BandWidth:'BandWidth',
            CPU:'CPU',
            GeneratedConnectionString:'Generated Connection String',
            Reset:'Reset',
            BasicSettings:'Basic Settings',
            DataSourceEn:'Data Source',
            Database:'Database',
            UserIDEn:'User ID',
            Instance:'Instance',
            ApplicationName:'Application Name',
            PasswordEN:'Password',
            Port:'Port',
            ConnectionTimeout:'Connection Timeout',
            SQLServerAuthentication:'SQL Server Authentication',
            AzureActiveDirectoryPassword:'Azure Active Directory-OtpAuthentication',
            GenerateConnectionString:'Generate Connection String',
            Templatehasbeensuccessfullyedited:'Your template has been successfully edited.',
            Templatehasbeensuccessfullyregistered:'Your template has been successfully registered.',
            Templatehasbeensuccessfullydeleted:'Your template has been successfully deleted.',
            Pagehasbeensuccessfullyedited:'Your page has been successfully edited.',
            Pagehasbeensuccessfullyregistered:'Your page has been successfully registered.',
            Identifier:'Identifier',
            Uploaddate:'Upload date',
            Pagehasbeensuccessfullydeleted:'Your page has been successfully deleted.',
            Filehasbeensuccessfullyuploaded:'The file has been successfully uploaded.',
            PreviousversionRevertedsuccessfully:'previous version Reverted successfully.',
            Filehasbeensuccessfullyedited:'Your file has been successfully edited.',
            Shortcodehasbeencreated:'A short code has been created.',
            Dearuserpleaseselectapackage:'Dear user, please select a package.',
            DearuserpleaseselectTemplate:'Dear user, please select a template.',
            SelectPackageTitle:'lorem',
            SelectCustomerTitle:'lorem',
            LicenseInformationTitle:'lorem',
            LicenseInformationDomain:'lorem',
            Userwassuccessfullyadded:'The user was successfully added.',
            Dearuserpleaseenterthedomainname:'Dear user, please enter the domain name.',
            Pleasechooseadefaultdomain:'Please choose a default domain.',
            Enteravaliddomainname:'Enter a valid domain name.',
            Paid:'Paid',
            Unpaid:'Unpaid',
            InvoiceDetail:'Invoice Detail',
            IncreaseBalance:'Increase Balance',
            ViewClub:'View Club',
            Bill:'Bill',
            Bills:'Bills',
            Salesbasket:'Sales basket',
            Favoriteproducts:'Favorite products',
            DocumentsandRecords:'Documents and Records',
            PersonalDetails:'Personal Details',
            ChangePhoto:'Change Photo',
            DateofBirth:'Date of Birth',
            UserLevel:'User Level',
            RegularUser:'Regular User',
            ProfileCompletion:'Profile Completion',
            GoldUser:'Gold User',
            SpecialUser:'Special User',
            CustomerClub:'Customer Club',
            UsersInformation:'Users Information',
            NotificationBox:'Notification Box',
            SearchByBill:'SearchBy(Bill)',
            ConnectionSQL:'Connection SQL',
            IPServerSQL:'IP-Server SQL',
            ServerSQL:'ServerSQL',
            ServerHOST:'Server HOST',
            AdminPanelServer: 'AdminPanelServer',
            AdminPanelServerIp:'Ip-AdminPanelServer',
            IPServerHOST:'IP-Server HOST',
            SeverSITE:'Sever Site',
            IPServerSITE:'IPServerSITE',
            Serverhasbeensuccessfullydeleted:'Your server has been successfully deleted',
            Havingthecapacity:'Having the capacity',
            WebServer:'Web server',
            DedicatedServer:'Dedicated Server',
            Capacitydepletion:'Capacity depletion',
            CPUUsage:'CPU Usage',
            RAMUsage:'RAM Usage',
            SSDUsage:'SSD Usage',
            HHDUsage:'HHD Usage',
            ConnectionStringIsRequired:'connection string is required',
            DiskUsage:'Disk Usage',
            NetworkTraffic:'Network Traffic',
            UpTime:'UpTime',
            MemoryUsage:'MemoryUsage',
            BandWidthUsage:'BandWidth Usage',
            Sectionselection:'Section selection',
            Hosting:'Hosting',
            TimeRange:'Time range',
            Desireddomain:'Desired domain',
            SQL:'SQL',
            PleaseSelectNotificationMethod:'Please select the notification method',
            PleaseSelectDateTime:'Please select datetime',
            contenttext:'contents',
            Pleaseinformationfirsttab:'Dear user, please enter the information for the first tab.',
            Thereferralcodeisempty:'The referral code is empty.',
            Referralcodemustnumericalvalue:'The referral code must be a numerical value.',
            Referralcodenotvalid:'Your referral code is not valid.',
            Specifyusertype:'Please specify the user type.',
            Informationhassuccessfully:'Information has been successfully recorded.',
            Iran:'Iran',
            Pleasechoosecompanyname:'Please choose the company name.',
            Pleaseselectbrandname:'Please select the brand name.',
            Banknamehasbeensuccessfullyregistered:'Your bank name has been successfully registered.',
            Choosethebankname:'Please choose the bank name.',
            Selectdefaultbank:'Please select a default bank.',
            Communicationaddressismandatory:'Communication address is mandatory.',
            Thetitleismandatory:'Dear user, the title is mandatory.',
            Fileshavebeensuccessfullydeleted:'The files have been successfully deleted.',
            UserGrouphasbeensuccessfullyedited:'Your User group has been successfully edited.',
            Usergrouphasbeensuccessfullycreated:'Your user group has been successfully created.',
            Grouphasbeensuccessfullydeleted:'Your group has been successfully deleted.',
            Editingwassuccessfullydone:'Editing was successfully done.',
            Rolehasbeensuccessfullyedited:'Your role has been successfully edited.',
            Rolehasbeensuccessfullyregistered:'Your role has been successfully registered.',
            Rolehasbeensuccessfullydeleted:'The role has been successfully deleted.',
            Roleshasbeensuccessfullydeleted:'The roles has been successfully deleted.',
            Permissionsbeensuccessfully:'Your permissions have been successfully recorded.',
            Chooseyourdesiredroles:'Choose your desired roles.',
            Reminderhasbeensuccessfullyedited:'Your reminder has been successfully edited.',
            Reminderhasbeensuccessfullyrecorded:'Your reminder has been successfully recorded.',
            Reminderhasbeensuccessfullydeleted:'Your reminder has been successfully deleted.',
            Grouphasbeensuccessfullyadded:'Your group has been successfully added.',
            Subcategoryinsertion:'Subcategory insertion',
            Selectyourarticlegroup:'Dear user, please select your article group.',
            FillinthearticletitleorURL:'Please fill in the article title or URL.',
            Contentgrouphasbeensuccessfullysubmitted:'Your content group has been successfully submitted.',
            Contenthasbeensuccessfullyadded:'Your content has been successfully added.',
            Contentbeensuccessfullyedited:'Your content has been successfully added.',
            Successfullyedited:'Successfully edited.',
            Grouphasbeensuccessfullyedited:'Your group has been successfully edited.',
            Contentgrouphasbeensuccessfullycreated:'Your content group has been successfully created.',
            Contentgrouphasbeensuccessfullyedited:'Your content group has been successfully edited.',
            Presstheuploadbuttonafterselectingthefile:'Dear user, please press the upload button after selecting the file.',
            Templatehasbeensuccessfullyselected:'Your template has been successfully selected.',
            ChooseTemplateTitle:'lorem',
            Templatehasbeensuccessfullyadded:'Your template has been successfully added.',
            Templatehasbeensuccessfullyupdated:'Your template has been successfully updated.',
            Shortcodehasbeensuccessfullyedited:'Your shortcode has been successfully edited.',
            Shortcodehasbeensuccessfullycreated:'Your shortcode has been successfully created.',
            Participants:'Participants',
            Advertisementhasbeensuccessfullydeleted:'Your advertisement has been successfully deleted.',
            Advertisementshavebeensuccessfullyedited:'Your advertisements have been successfully edited.',
            Advertisementshavebeensuccessfullyregistered:'Your advertisements have been successfully registered.',
            Advertisementshavebeensuccessfullyadded:'Your advertisements have been successfully added.',
            Featuregrouphasbeensuccessfullyedited:'Your feature group has been successfully edited.',
            Metagrouphasbeensuccessfullyadded:'Your meta-group has been successfully added.',
            Numberoffeatures:'Number of features',
            Statushasbeensuccessfullychanged:'The status has been successfully changed.',
            Featurename:'Feature name',
            Featuretype:'Feature type',
            Featurevalue:'Feature value',
            Featurerank:'Feature rank',
            Billcreation:'Bill creation',
            Productnameismandatory:'Product name is mandatory.',
            Timeselectionismandatory:'Time selection is mandatory.',
            Guaranteecodehasbeensuccessfullyregistered:'Your Guarantee code has been successfully registered.',
            Guaranteestatushasbeensuccessfullychanged:'The Guarantee status has been successfully changed.',
            Guaranteehasbeensuccessfullydeleted:'Your Guarantee has been successfully deleted.',
            Guaranteecodeismandatory:'Guarantee code is mandatory.',
            Sixteencharactersarerequired:'Sixteen characters are required.',
            ProductGuaranteehasbeensuccessfullyadded:'ProductGuaranteehasbeensuccessfullyadded.',
            Guaranteehasbeensuccessfullyedited:'The warranty has been successfully edited.',
            Guaranteeextensionhasbeensuccessfullycompleted:'The warranty extension has been successfully completed.',
            Day:'Day',
            Discountcodehasbeensuccessfullyedited:'The discount code has been successfully edited.',
            Discountcodehasbeensuccessfullyregistered:'The discount code has been successfully registered.',
            Discountcodehasbeensuccessfullydeleted:'Your discount code has been successfully deleted.',
            Discountcampaignhasbeensuccessfullydeleted:'Your discount campaign has been successfully deleted.',
            Discountcampaignhasbeensuccessfullyedited:'The discount campaign has been successfully edited.',
            Discountcampaignhasbeensuccessfullyregistered:'The discount campaign has been successfully registered.',
            Successfullyregistered:'Successfully registered.',
            PleaseEnterCaptcha:'Please enter captcha',
            CaptchadosenotMatch:'Captcha dose not match',
            CaptchaConfirmed:'Captcha is Valid',
            CaptchaValue:'Captcha value',
            validate:'validate',
            regenerate:'again',
            ProfileEdit:'Profile Edit',
            Managementpanel:'Management panel',
            Viewpanel:'View panel',
            Startconversation:'Start conversation',
            Numberonlinepeople:'Number of online people',
            Depositrequest:'Deposit request',
            Viewtransactions:'View transactions',
            Passwordchangesuccessful:'OtpAuthentication change successful',
            Addinginformation:'Adding information',
            Calendarsettings:'Calendar settings',
            Fastsettings:'Fast settings',
            CommentEditSuccess:'comment changed successfully',
            CommentAddSuccess:'comment Added successfully',
            AreyouSuretoRejectAll:'Are you sure to reject all ?',
            ThereIsNowayback:'There is no way back',
            Reject:'Reject',
            Confirm:'confirm',
            ProductCounts:'Product counts',
            CommentsRejectedSuccessfully:'Comments rejected successfully',
            AreyouSuretoConfirmAll:'Are you sure to confirm all ? ',
            CommentsConfirmedSuccessfully:'Comments confirmed successfully',
            CommentsDeletedSuccessfully:'Comments deleted successfully',
            CommentDeletedSuccessful:'Comment deleted successful',
            Response:'response',
            ThreeMonth:'Three monthes ago',
            CommentsRestoredSuccessfully:'Comments restored successfully',
            AreyouSureToRestoreAll:'Are you sure to restore all ?',
            State:'State',
            Backgroundcolor:'Background color',
            Textcolor:'Text color',
            Pleaseselectuser:'Please select a user.',
            Pleaseenterwebsitetitle:'Please enter the website title.',
            SelectServerTitle:'lorem',
            Pleaseselectleasttemplate:'Please select at least one template.',
            Templateselected:'Template selected.',
            QuickPriceChange:'Quick Price Change',
            Ticketsuccessfullysubmitted:'Ticket successfully submitted.',
            Tickettype:'Ticket type',
            Ticketstatus:'Ticket status',
            Enteryourmessage:'Enter your message...',
            DiscountPercentage:'Discount Percentage',
            BacktoHome:'Back to Home',
            Pleaseselectstatus:'Please select a status.',
            Pendingwithdrawalconfirmationlist:'Pending withdrawal confirmation list.',
            Approvedwithdrawals:'Approved withdrawals.',
            Shuttingdowntheserver:'Shutting down the server.',
            Editingserver:'Editing the server.',
            CreatingMenu:'Creating Menu',
            Copyingthetext:'Copying text',
            Theoperationwassuccessfully:'The operation was completed successfully.',
            Thefolderisduplicated:'The folder is duplicated.',
            Thefolderhasbeencreatedsuccessfully:'The folder has been created successfully.',
            Yourclipboardisempty:'Your clipboard is empty.',
            Theinformationsuccessfullydeleted:'The information has been successfully deleted.',
            Yourlinkhasbeencopied:'Your link has been copied.',
            Filesuccessfullydeleted:'The file has been successfully deleted.',
            Filessuccessfullyrecovered:'The files have been successfully recovered.',
            Filenamesuccessfullychanged:'The file name has been successfully changed.',
            Replace:'Replace',
            Selectoneofthefollowingoperations:'Select one of the following operations',
            Thecompressedfilewascreatedsuccessfully:'The compressed file was created successfully',
            Yourfilehasbeensuccessfullyextracted:'Your file has been successfully extracted.',
            Thedepartmentbeensuccessfullyedited:'The department has been successfully edited.',
            Thedepartmentbeensuccessfullyregistered:'The department has been successfully registered.',
            Thedepartmentbeensuccessfullydeleted:'The department has been successfully deleted.',
            Order:'Order',
            Ordercancellation:'Ordercancellation',
            Quickguide:'Quick guide',
            Replytothismessage:'Reply to this message',
            ContactUs:'Contact Us',
            Pleaseselectdepartment:'Please select a department.',
            Thefilesizeexceedstheallowedlimit:'The file size exceeds the allowed limit.',
            Yourtickethasbeensuccessfullysubmitted:'Your ticket has been successfully submitted.',
            Tickettothecustomer:'Ticket to the customer',
            Ticketsformembers:'Tickets for members',
            Supportticket:'Tickets for members',
            Tickettoadmin:'Ticket to admin',
            Edit:'Edit',
            Userselection:'User selection',
            ImportanceLevel:'Importance Level',
            Upto10MB:'Up to 10 MB',
            PleaseenteravalidserverIP:'Please enter a valid server IP.',
            Areyousureyouwanttomakethechange:'Are you sure you want to make the change?',
            Thereisnopossibilitytoreverttopreviousdataandthenewinformationwillreplacetheolddata:'There is no possibility to revert to previous data, and the new information will replace the old data.',
            Yeschangeit:'Yes, change it!',
            Nodontchangeit:'No, don\'t change it!',
            Manufacturingmethod:'Manufacturing method.',
            Onefile:'One file',
            Eachsectiononefile:'Each section, one file',
            Averagechanges:'Average changes',
            Always:'Always',
            Daily:'Daily',
            Annually:'Annually',
            Between0and1:'Between 0 and 1',
            Linkeditedsuccessfully:'Link edited successfully',
            Yourlinkhassuccessfullyregistered:'Your link has been successfully registered',
            Yourlinkhassuccessfullyremoved:'Your link has been successfully removed',
            Thesurveyquestiontextoroptionsareempty:'The survey question, text, or options are empty.',
            Thesurveyhasbeensuccessfullyedited:'The survey has been successfully edited.',
            Thesurveyhasbeensuccessfullysubmitted:'The survey has been successfully submitted.',
            Gettingwrittentext:'Getting a written text',
            hasStatistics:'hasStatistics',
            hasCycle:'hasCycle',
            hasIpLimitation:'hasIpLimitation',
            nextquestion:'next question',
            Optiontext:'Option text',
            Emoji:'Emoji',
            Rate:'Rate',
            CodeHEX:'Code HEX',
            Packagerole:'Package role',
            Quickpurchase:'Quick purchase',
            Remotemanagement:'Remote management',
            Managementbot:'Management bot',
            Enterthedatabaseconnectionstring:'Enter the database connection string',
            HTML:'HTML',
            Deposittoday:'Deposit today',
            Latestusers:'Latest users',
            totalsales:'total sales',
            Thisyearsales:'This year\'s sales',
            Quarterlysales:'Quarterly sales',
            Currentmonthsales:'Current month\'s sales',
            Thisweeksales:'This week\'s sales',
            Botfather:'Bot Father',
            DocumentGroupIsRequired:'Document group is required',
            Enterthesurveyenddate:'Enter the survey end date',
            Yourwebsitehasbeencreated:'Your website has been created',
            SlugIsNotValid:'Slug is not valid',
            Xmin:'X<=',
            Xmax:'X>',
            Yourcurrentspace:'Your current space',
            Suggestedspace:'Suggested space',
            Theamountofpurchases:'The amount of purchases',
            SampleFile:'Sample File',
            CMSManagement:'CMS',
            SetValue:'Set Value',
            AnswerToTheSurvey:'Answer to the survey',
            Chart:'Chart',
            Viewthesite:'View the site',
            Basedonthetemplate:'Based on the template',
            ImageSettings:'Image settings',
            Professional:'Professional',
            SubMenu:'SubMenu',
            ExchangeRate:'Exchange Rate',
            DocumentTypeIsRequired:'Document type is required',
            CommissionRate:'Commission rate',
            Symbol:'Symbol',
            DestinationCurrency:'Destinationcurrency',
            SourceCurrency:'Source currency',
            MessageCode:'Message Code',
            DefaultText:'Defaulttext',
            TemplateText:'Template Text',
            SMSExampleName:'{name}',
            SMSExampleSex:'{sex}',
            SMSExampleMobile:'{mobile}',
            Undefined:'Undefined',
            Register:'Register',
            SingleDiscount:'Single Discount',
            MajorDiscount:'Major Discount',
            PriceType:'WholeSale',
            VideoUrl:'videoUrl',
            DocumentUrl:'documentUrl',
            ExchangeRateRemovedSuccessfully:'Exchange Rate removed successfully',
            TheCommissionRateMustBeBetweenTheSourceORDestinationRate:'The commission rate must be between the source or destination rate',
            ExchangeRateEditedSuccessfully:'The exchange rate edited successfully',
            ExchangeRateRegisteredSuccessfully:'Exchange rate registered succssfully',
            TariffRegisteredSuccessfully:'Tariff Registered Successfully',
            TariffEditedSuccessfully:'Tariff edited successfully',
            TariffRemovedSuccessfully:'Tariff removed successfully',
            BankPaymentAmount:'Bank payment amount',
            FundSelection:'Fund selection',
            CashAmount:'Cash amount',
            WithdrawFromTheWallet:'Withdraw from the wallet',
            BillPayment:'bill payment',
            ManualShoppingCart:'Manual shopping cart',
            BillingDate:'Billing date',
            InvoiceType:'Invoice type',
            Update:'Update',
            isPending:'isPending',
            MyRequests:'My Requests',
            Thesourceanddestinationunitscannotbethesame:'The source and destination units cannot be the same',
            TitleSurvey:'Title Survey',
            SurveyAddress:'SurveyAddress',
            MenuHasBeenSuccessfullyRegistered:'The menu has been successfully registered',
            FlashMessage:'FlashMessage',
            CostComputation:'CostComputation',
            PleaseEnterTextMessage:'Please enter the message text',
            PleaseEnterNumbers:'Please enter numbers',
            SmsSentSuccessfully:'Message sent successfully',
            TitleAdvertisement:'Title Advertisement',
            AdvertisementAddress:'Advertisement Address',
            ProductAddress:'Product Address',
            ReminderInterval:'Reminder interval',
            OtherSettings:'Other Settings',
            InvoiceValidityPeriod:'Invoice Validity Period',
            BillValidityPeriod:'Bill validity period',
            amountCaption:'amount(caption)',
            applyCode: 'apply Code',
            RemovedSuccessfully: 'Removed successfully',
            PermissionsEditedSuccessfully: 'Permissions edited successfully',
            PermissionsRemovedSuccessfully: 'Permissions removed successfully',
            SuccessfullyCreated: 'Successfully created',
            PleaseSelectSection: 'Please select a section',
            PleaseSelectDomain: 'Please Select a Domain',
            DataCleanupCompletedSuccessfully: 'Data cleanup completed successfully',
            DataBackupWasDoneSuccessfully: 'Data backup was done successfully',
            TextReminder: 'Text reminder',
            Duty: 'Duty',
            ChooseTheNextQuestionsFromTheAvailableQuestions: 'Choose the next questions from the available questions',
            ThisItemWillBePolledInWritingByTheUser: 'This item will be polled in writing by the user',
            YourAnswersHaveBeenSuccessfullyRegistered: 'Your answers have been successfully registered',
            NoQuestionHasBeenRegistered: 'No question has been registered',
            AreYouSureToRegisterTheQuestions: 'Are you sure to register the questions?',
            RecordQuestions: 'Record questions',
            CancellationRegistration: 'CancellationRegistration',
            QuestionType:'Question type',
            PleaseSelectFile:'Please select a file',
            SuccessDone:'Successfully Done',
            YourFilesHaveBeenSentSuccessfully:'Your files have been sent successfully',
            ShareTheReceipt:'Share the receipt',
            UnsuccessfulTransaction:'Unsuccessful transaction',
            SuccessfulTransaction:'Successful transaction',
            TransactionTime:'Transaction time',
            ShipmentTrackingNumber:'Shipment tracking number',
            FactorNumber:'FactorNumber',
            BankTrackingNumber:'Bank tracking number',
            PhotoSharing:'Photo sharing',
            TextSharing:'Text sharing',
            MessageToMembers:'Message to members',
            MessageToSiteManager:'Message to site manager',
            onlinePayment:'online payment',
            ManualPayment:'Manual payment',
            BillAmount:'Bill amount',
            BankReceiptAmount:'Bank receipt amount',
            SourceBank:'Source bank',
            destinationBank:'destination bank',
            ReceiptNumber:'Receipt number',
            dateOfReceipt:'date of receipt',
            Party:'Party',
            ValidityDate:'Validity date',
            NumberProductsCannotBe0:'The number of products cannot be 0',
            EnterTheBillingDate:'Enter the billing date',
            EnterTheInvoiceValidityDate:'Enter the invoice validity date',
            NoRegistration:'No registration',
            SalesInvoice:'Sales Invoice',
            ProformaInvoice:'Proforma invoice',
            InvoiceValidityDate:'Invoice validity date',
            SystemUpgradeFactor:'System upgrade factor',
            TariffTable:'Tariff table',
            TypeOfService:'Type of service',
            BrandConfirmation:'Brand confirmation',
            UpgradeType:'UpgradeType',
            lastWeek:'last week',
            FilterRegisteredSuccessfully:'Filter Registered successfully',
            FilterRemovedSuccessfully:'Filter removed successfully',
            FieldConditionValueIsEmpty:'The field or condition or value is empty',
            QrCode:'QrCode',
            PleaseEnterSlug:'PleaseEnterSlug',
            MinimumInventory:'Minimum inventory',
            MaximumInventory:'Maximum inventory',
            GoodsName:'Goods Name',
            EndInventory:'End of inventory',
            ProjectDocument:'Project document',
            CreateReminder:'Create Reminder',
            ServiceModule:'Service/Module',
            NameDepartment:'Name Department',
            AddBankAccount:'Add account',
            WeeklySettings:'Weekly settings',
            FirstDayOfTheWeek:'first day of the week',
            Links:'Links',
            ProjectDocumentList:'Project document list',
            UpdateDate:'Update date',
            ListGuide:'List Guide',
            Documentation:'Documentation',
            TrainingVideoLink:'Training video link',
            AuxiliaryLink:'Auxiliary link',
            StoreSettings:'Store settings',
            Titles:'titles',
            YourGuide:'Your guide',
            // count:'count',
            MinimumMaximumPurchase:'Minimum and maximum purchase',
            CalculationMethod:'Calculation method',
            ByCountry:'by country',
            Refresh:'refresh',
            Switching:'Switching',
            WithdrawalPart:'Withdrawal part',
            title:'Title',
            Country:'Country',
            Province:'Province',
            ip:'IP',
            Restart:'Restart',
            IIS:'IIS',
            DeleteServer:'DeleteServer',
            Service:'Service',
            MonetaryUnit:'Monetary unit',
            ViewDocument:'View the document',
            Document:'Document',
            senderNumber:'sender number',
            SiteAddress:'Site address',
            Host:'Host',
            DashboardSettings:'Dashboard settings',
            generalManagerSettings:'Manager settings',
            HighLoadCpu:'HighLoadCpu',
            HighLoadMemory:'HighLoadMemory',
            HighLoadDisk:'HighLoadDisk',
            AccessLevel:'Access level',
            DeleteSite:'Delete the site',
            Timing:'Timing',
            ContentGroup:'Content group',
            ShopGroup:'Content group',
            SurveyGroup:'Survey group',
            InformationFilter:'Information filter',
            AllInformation:'All information',
            CloseMenu:'close menu',
            ViewMenu:'view menu',
            ViewFile:'View the file',
            CreateDocument:'Create Document',
            WebsiteInformation:'Website information',
            UpgradeHistory:'Upgrade history',
            capacity:'capacity',
            RequestAndUpgrade:'Request And Upgrade',
            ChargeRate:'Charge rate',
            NumberVisitors:'Number of Visitors',
            PagesVisited:'Pages visited',
            AverageVisit:'Average visit',
            WeeklyVisit:'Weekly visit',
            MonthlyVisit:'Monthly visit',
            AnnualVisit:'Annual visit',
            MostVisitedPages:'Most visited pages',
            SelectedReferrers:'Selected referrers',
            BrowserStatistics:'Browser statistics',
            StatisticsCountries:'Statistics of countries',
            reportFrom:'report from',
            Visitors:'visitors',
            PageViews:'page views',
            NumberDays:'number of days',
            ViewText:'View text',
            Secretary:'Secretary',
            Pattern:'Pattern',
            Charge:'charge',
            CreateSecretary:'Create a secretary',
            AccountEditedSuccessfully:'Account edited successfully',
            AccountCreatedSuccessfully:'Account created successfully',
            StatisticsCities:'Statistics of cities',
            Platform:'Platform',
            OriginalTemplate:'Original template',
            TheURLIsNotValid:'The URL is not valid',
            PriceChanges:'Price changes',
            QuickRegistrationPeople:'Quick registration of people',
            MB:'MB',
            TheAmountOfPurchasesIsAllowed:'The amount of purchases is allowed',
            TheAllowedAmountOfBuyingMailHostIs:'The allowed amount of buying mail host is',
            CalculationOfWebsiteLanguagePriceTariff:'Calculation of website language price tariff',
            ComparedToThePriceOfThePackage:'compared to the price of the package',
            RenewalBillReminderDate:'Renewal bill reminder date',
            ToExpiration:'To expiration',
            View:'View',
            Buy:'Buy',
            RegisterYourComment:'Register your comment',
            ChooseTheOptionYouWant:'Choose the option you want',
            TheNumberOfProductCodeDigits:'The number of product code digits',
            Folder:'Folder',
            Statistics:'statistics',
            PreviousAddress:'Previous address',
            NewAddress:'new address',
            AddConnection:'Add connection',
            InitialSurveySettings:'Initial survey settings',
            SelectMultipleOptions:'Select multiple options',
            ChooseAnOption:'Choose an option',
            SelectSelectionFromList:'Select a selection from the list',
            SelectFromTheList:'Select from the list',
            Separator:'Separator',
            DateAndTime:'date and time',
            WebsiteLink:'Website link',
            Voice:'Voice',
            Period:'period',
            Color:'color',
            ReadyTemplates:'Ready templates',
            ProfileFormat:'Profile format',
            RecruitmentTemplate:'Recruitment template',
            RepresentationTemplate:'RepresentationTemplate',
            CooperationFormatSales:'Cooperation format in sales',
            DepositReceiptForm:'Deposit receipt form',
            Mandatory:'Mandatory',
            ReadOnly:'Read Only',
            ClassName:'Class name',
            Size:'size',
            ShowFor:'ShowFor',
            DetermineHeight:'Determine the height',
            MultipleChoice:'MultipleChoice',
            DisplayText:'Display text',
            AnOption:'an option',
            DisplayFormat:'Display format',
            TheFirstOptionEmpty:'The first option is empty',
            Format:'format',
            ShowOnTheMapDefaultLocation:'Show on the map (default location)',
            StorageLocation:'Storage location',
            MinimumSize:'Minimum size',
            MaximumSize:'Maximum size',
            SuccessfulRegistrationMessage:'Successful registration message',
            RegistrationFailedMessage:'Registration failed message',
            ShowPlace:'Show place',
            FormType:'Form type',
            FormCirculationPath:'Form circulation path',
            ErrorCode:'ERROR CODE',
            ErrorDescription:'ERROR DESCRIPTION' ,
            ErrorTitle:'Access Denied. You Do Not Have The Permission To Access This Page On This Server',
            ErrorPossiblyCausedBy:'ERROR POSSIBLY CAUSED BY',
            ErrorPossiblyTitle:'execute access forbidden, read access forbidden, write access forbidden, ssl required, ssl 128 required, ip address rejected, client certificate required, site access denied, too many users, invalid configuration, password change, mapper denied access, client certificate revoked, directory listing denied, client access licenses exceeded, client certificate is untrusted or invalid, client certificate has expired or is not yet valid, passport logon failed, source access denied, infinite depth is denied, too many requests from the same client ip',
            ChangeRepresentative:'Change of representative',
            CurrentRepresentative:'Current representative',
            NewRepresentative:'CurrentRepresentative',
            SomePage:'SOME PAGES ON THIS SERVER THAT YOU DO HAVE PERMISSION TO ACCESS',
            GoodDay:'HAVE A NICE DAY SIR HARMONY :-)',
            NotFoundTitle:'You seem to be lost in space',
            BackToThePage:'Back to the page',
            UserRegisteredSuccessfully:'User registered successfully',
            UserDeletedSuccessfully:'User deleted successfully',
            SendNewTicket:'Send a new ticket',
            MaximumPurchase:'Maximum purchase',
            SearchByCenter:'Search By (Center)',
            InsuranceParameter:'Insurance parameter',
            ParameterWeight:'Parameter weight',
            LengthParameter:'Length Parameter',
            WidthParameter:'Width parameter',
            HeightParameter:'Height parameter',
            NumberParameter:'number parameter',
            UserAddresses:'User addresses',
            SaveAddressTitle:'Select to register the address in the system',
            MobileEmail:'Mobile-Email',
            ServiceSelection:'Service selection',
            Possibilities:'Possibilities',
            Tag:'tag',
            Campaign:'Campaign',
            ProductDescription:'Product description',
            Print:'print',
            PrintPriceList:'Print price list',
            CreateInventory:'Create inventory',
            InventoryName:'Inventory name',
            InventoryType:'Inventory type',
            InventoryManager:'Inventory manager',
            Branch:'Branch',
            InventoryCode:'Inventory code',
            AddBranch:'Add branch',
            SearchByName:'Search By (Name)',
            Goods:'Goods',
            AvailableBuildSeries:'Available build series',
            Exit:'Exit',
            ReturnFromPurchase:'Return from purchase',
            Returned:'returned',
            ListOfRequests:'List of requests',
            AutomaticDocumentRegistration:'Automatic document registration',
            SmartNotification:'Smart notification',
            PurchaseManager:'purchase manager',
            TotalViews:'total views',
            YesterdayViews:'Yesterday views',
            LastWeek:'last week',
            LastMonth:'Last month',
            LastYear:'Last year',
            TaxRate:'tax rate',
            TollRates:'Toll rates',
            ServiceFee:'service fee',
            UnitMeasurement:'Unit of measurement',
            PurchasePrice:'Purchase price',
            DisplayInTheOnlineStore:'Display in the online store',
            Producer:'Producer',
            DocumentNumber:'document number',
            NumberItems:'Number of items',
            Referral:'referral',
            Accounts:'accounts',
            DeleteAccount:'delete account',
            Reentry:'re-entry',
            ViewAccount:'View account',
            SelectPage:'Select page',
            PublishingContent:'Publishing content',
            AccountSearch:'Account search',
            EntryDocument:'entry document',
            ExitDocument:'exit document',
            ReturnDocument:'return document',
            AdditionsDocument:'Additions document',
            SocialMarketing:'Social marketing',
            EmailMarketing:'email marketing',
            AddEmail:'Add email',
            EmailAddress:'email address',
            UsernameRobot:'Username of the robot',
            RobotAPICode:'Robot API code',
            DedicatedRobot:'Dedicated robot',
            PersonalSettings:'Personal settings',
            EnterTheText:'Enter the text.....',
            AutomaticCaption:'automatic caption',
            Tone:'tone',
            Files:'files',
            Captions:'captions',
            Caption:'caption',
            AddCaptions:'add captions',
            CreateCaption:'create a caption',
            PublicationList:'Publication list',
            IncomingServer:'incoming server',
            OutgoingServer:'outgoing server',
            StatisticsAnalysis:'Statistics and analysis',
            Option:'option',
            CorrectAnswer:'correct answer',
            CancellationPurchase:'Cancellation of purchase',
            TemplatePurchaseConfirmation:'Template purchase confirmation',
            AreYouSureAboutBuyingTheTemplate:'Are you sure about buying the template?',
            ConnectionTraining:'ConnectionTraining',
            AccountSelection:'Account selection',
            ConnectAccounts:'Connect accounts',
            CustomField:'Custom field',
            DateManufacture:'Date of manufacture',
            Addresses:'Addresses',
            Smart:'Smart',
            Messengers:'Messengers',
            DeterminationPersonalCommission:'Determination of personal commission',
            DeterminationGroupCommission:'Determination of personal commission',
            UserRole:'User role',
            DeterminationCommissionBasedRole:'Determination of commission based on role',
            DeterminationCustomCommission:'Determination of custom commission',
            DeterminationPersonalDiscount:'Determination of personal discount',
            DeterminationGroupDiscount:'Determination of group discount',
            DeterminationDiscountBasedRole:'Determination of discount based on role',
            LatestCustomersWebsites:'Latest customers websites',
            LastCustomers:'Last customers',
            Agent:'Agent',
            Seller:'Seller',
            Marketer:'marketer',
            ShiftWork:'ShiftWork',
            PublicationStatus:'Publication status',
            CreationTime:'Creation time',
            Album:'Album',
            TimingFor:'Timing for',
            Scheduled:'Scheduled',
            Repeating:'repeating',
            Unpublished:'Unpublished',
            Canceled:'Canceled',
            Customization:'Customization',
            Post:'Post',
            Story:'story',
            AddHashtag:'Add a hashtag',
            AddHashtagTitle:'Hashtags are added to the bottom of the text in the caption of social networks that support the hashtag',
            TelegramSettings:'TelegramAccount settings',
            PinThePostOnTheChannel:'Pin the post on the channel',
            ProtectedPost:'Protected post',
            AddTheChannelUsernameToCaption:'Add the channel\'s username to the end of the caption',
            DisplayTheDescriptionAboveThePost:'Display the description above the post',
            HiddenContent:'Hidden content',
            GlassButton:'Glass button',
            InstagramRails:'Instagram rails',
            PostToFeed:'Post to feed',
            ImageCover:'Image cover',
            ChangingTheTitleTheRailsSound:'Changing the title of the Rails sound',
            ProfileTagInTheVideo:'Profile tag in the video',
            FirstComment:'first comment',
            SharedPost:'SharedPost',
            InstantDelivery:'Instant delivery',
            RepetitiveContent:'Repetitive content',
            RepeatAs:'Repeat as',
            TheNumberRepetitions:'The number of repetitions',
            SaveTheDraft:'Save the draft',
            AddTelegramGlassButton:'Add TelegramAccount glass button',
            Link:'Link',
            Quiz:'quiz',
            TheShortcutKeyValueIsEmpty:'The shortcut key value is empty',
            DuplicateShortcutKeyValueEntered:'Duplicate shortcut key value entered',
            EnteringIsRequired:'Entering is required',
            CtrlShift:'Ctr+Shift',
            UseEitherCtrlOrAlt:'Use Either Ctrl or Alt',
            TypeALetter:'Type a letter',
            DoesItHaveAWatermark:'Does it have a watermark?',
            SelectDashboard:'Select dashboard',
            ShellSettings:'Shell settings',
            ManagementPanelLanguage:'Management panel language',
            DefaultValues:'default values',
            TheStatusOfContracts:'The status of contracts',
            FileExtension:'file extension',
            TimeOfLastRevision:'Time of last revision',
            SelectionProducts:'Selection of products',
            ContentSelection:'Content selection',
            CheckBox:'CheckBox',
            ChoiceUsers:'Choice of users',
            ExitRequest:'Exit request',
            ReturnPurchase:'Return Purchase',
            ProductionRequest:'Production request',
            RequestRawMaterials:'Request raw materials',
            DeductionsDocument:'Deductions document',
            CustomerRegistration:'Customer registration',
            AttendanceSystem:'Attendance system',
            RegisterDevice:'Register device',
            ConnectionTest:'Connection Test',
            Configs:'Configs',
            Personnel:'Personnel',
            LegalProvisions:'Legal provisions',
            Attendees:'Attendees',
            Absent:'Absent',
            IndividualReport:'Individual Report',
            AddShift:'Add Shift',
            DeviceName:'Device Name',
            DeviceModel:'Device Model',
            DeviceNumber:'Device Number',
            DetermineSystemManagement:'Determine System Management',
            SystemManager:'System Manager',
            CalculationType:'Calculation Type',
            DetermineTheLimit:'Determine The Limit',
            Rules:'Rules',
            WeeklyVacation:'Weekly Vacation',
            AnnualCalendar:'Annual Calendar',
            DefaultProvisions:'Default Provisions',
            SmartReport:'Smart report',
            AnHour:'An Hour',
            HourlyWages:'Hourly Wages',
            Shift:'Shift',
            StartTime:'Start Time',
            EndTime:'End Time',
            RestTime:'Rest Time',
            Beginning:'Beginning',
            End:'End',
            MaximumPaidLeave:'Maximum Paid Leave',
            MaximumLeaveWithoutPay:'Maximum Leave Without Pay',
            MaximumHourlyLeavePerMonth:'Maximum Leave Without Pay',
            MaximumSickLeavePerMonth:'Maximum Sick Leave Per Month',
            MaximumOvertimePerMonth:'Maximum Overtime Per Month',
            MaximumMissionPerMonth:'Maximum Mission Per Month',
            PaidShiftOvertimeConditions:'Paid Shift Overtime Conditions',
            CalculationRateOfOvertimeWithSalary:'Calculation Rate Of Overtime With Salary',
            PaidHourlyOvertimeConditions:'Paid Hourly Overtime Conditions',
            HourlyOvertimeCalculationRateWithSalary:'Hourly Overtime Calculation Rate With Salary',
            MissionConditions:'Mission Conditions',
            MissionCalculationRate:'Mission Calculation Rate',
            ShiftAbsenceConditions:'Shift Absence Conditions',
            CalculationRateOfShiftAbsence:'The Calculation Rate Of Shift Absence',
            NightShiftConditions:'Night Shift Conditions',
            NightShiftCalculationRate:'Night shift calculation rate',
            HolidaysOfTheWeek:'Holidays Of The Week',
            TypeOfVacation:'Type Of Vacation',
            OfficialCalendar:'Official Calendar',
            DailyWage:'Daily Wage',
            HourlyWage:'Hourly Wage',
            SeverancePay:'SeverancePay',
            ChildAllowance:'Child Allowance',
            PersonnelInsuranceContribution:'Personnel Insurance Contribution',
            EmployersInsuranceContribution:'Employer\'s insurance contribution',
            UnemploymentInsuranceContribution:'Unemployment Insurance Contribution',
            SupplementaryInsuranceContribution:'Supplementary Insurance Contribution',
            SalaryTax:'Salary Tax',
            DefineCustomRules:'Define Custom Rules',
            WorkGroup:'Work Group',
            EarlyCheckinTime:'Early check-in time',
            EarlyDepartureTime:'Early Departure Time',
            Actions:'Actions',
            FinancialRules:'Financial Rules',
            LeaveAndMission:'Leave And Mission',
            PersonnelName:'Personnel Name',
            ArchiveLeaveAndMission :'Archive Leave And Mission',
            MaritalStatus :'Marital Mtatus',
            Leave:'Leave',
            Device:'Device',
            NameRules:'Name Rules',
            FiscalYear:'Fiscal Year',
            ManagementFee:'Management Fee',
            Efficiency:'Efficiency',
            NumberEmployees:'Number of Employees',
            DeterminingTheRules:'Determining The Rules',
            ConfirmRequest:'ConfirmRequest',
            RejectionRequest:'Rejection Request',
            Applicant:'Applicant',
            GroupName:'Group Name',
            GroupCode:'Group Code',
            BenchmarkDate:'Denchmark Date',
            PersonnelCode:'Personnel Code',
            ArrivalTime:'Arrival Time',
            DepartureTime:'Departure Time',
            Delay:'Delay',
            CompulsoryOvertime:'Compulsory Overtime',
            TaskWork:'Task Work',
            Hastening:'Hastening',
            LowTimeWork:'Low Time Work',
            UnauthorizedOvertime:'Unauthorized Overtime',
            TotalOvertime:'Total Overtime',
            HourlyLeave:'Hourly Leave',
            HourlyMission:'Hourly Mission',
            NightWork:'Night Work',
            DelayService:'Delay In Service',
            ShiftName:'Shift Name',
            ShiftCode:'Shift Code',
            ProductionManager:'Production Manager',
            Supervisor:'Supervisor',
            ShiftType:'Shift Type',
            ColorCode:'Color Code',
            ShiftStartTime:'Shift Start Time',
            EndShiftTime:'End of shift time',
            BreakTime:'Break Time',
            EndTimeRest:'End time of rest',
            AllowedEntryPeriod:'Allowed Entry Period',
            AllowedExitPeriod:'Allowed Exit Period',
            PeriodInterval:'Period Interval',
            AddWorkgroup:'Add a Workgroup',
            businessDays:'Business Days',
            PersonnelAllocation:'Personnel Allocation',
            Presence:'Presence',
            Closed:'Closed',
            WorkingHoliday:'working holiday',
            FreelancePersonnel:'Freelance personnel',
            ActivePersonnelTheGroup:'Active Personnel Of The Group',
            RecordedPerformance:'Recorded performance',
            CompleteRegistration:'Complete Registration',
            IncompleteRegistration:'Incomplete Registration',
            Mission:'Mission',
            Absence:'Absence',
            DeterminingVerdict:'Determining The Verdict',
            NumberDaysAttendance:'Number Of Days Of Attendance',
            NumberDaysAbsence:'Number Of Days Of Absence',
            Overtime:'Overtime',
            AllowedHourlyLeave:'Allowed Hourly Leave',
            DailyMission:'Daily Mission',
            WorkFriday:'Work Friday',
            Year:'Year',
            Enteries:'Enteries',
            LeaveConditions:'Leave Conditions',
            SupervisorFee:'Supervisor Fee',
            SendingCheckinAndCheckoutTimesForPersonnel:'Sending check-in and check-out times for personnel',
            PaidLeave:'Paid Leave',
            HourlyPaidLeave:'Hourly Paid Leave',
            LeaveWithoutPay:'Leave Without Pay',
            HourlyLeaveWithoutPay:'Hourly leave without pay',
            SendingCheckinAndCheckoutTimesForDeviceManager:'Sending Checkin And Checkout Times For Device Manager',
            SendingCheckinAndCheckoutTimesForProductionManager:'Sending Checkin And Checkout Times For Production Manager',
            SendingCheckinAndCheckoutTimesForHumanResourcesManager:'Sending Checkin And Checkout Times For Human Resources Manager',
            SendingCheckinAndCheckoutTimesForShiftManager:'Sending Checkin And Checkout Times For Shift Manager',
            SendingCheckinAndCheckoutTimesForProductionLine:'Sending Checkin And Checkout Times For Production Line',
            SendingCheckinAndCheckoutTimesForGeneralManager:'Sending Checkin And Checkout Times For General Manager',
            BackupList:'Backup List',
            AccountDeletedSuccessfully:'Account Deleted Successfully',
            FirstLine:'First Line',
            BackupLine:'BackupLine',
            DefaultLineForOTPCode:'Default Line For OTP Code',
            DefaultEmailForOTPCode:'Default Email For OTP Code',
            BackupEmail:'BackupEmail',
            PossibilityMembership:'Possibility Membership',
            UnauthorizedIPs:'Unauthorized IPs',
            AdminPrivacy:'Admin Privacy',
            UserPrivacy:'User Privacy',
            AddingFinancialRules:'Adding Financial Rules',
            SiteLanguage:'Site Language',
            Basicsettings:'Basic Settings',
            FormTitle:'Form Title',
            MensPhotos:'Men\'s Photos',
            WomensPhotos:'Women\'s photos',
            OtherGenders:'Other Genders',
            SettingsPage:'Settings Page',
            Script:'Script',
            Security:'Security',
            AllowedReturnPeriod:'Allowed Return Period',
            Target:'Target',
            FormBuilder:'Form Builder',
            CommonlyUsedFilters:'Commonly Used Filters',
            Bought:'Bought',
            MyTemplates:'My Templates',
            PersonalInformation:'Personal Information',
            JobTitle:'Job Title',
            TypeInsurance:'Type Of Insurance',
            InsurancePosition:'Insurance Position',
            bloodType:'blood Type',
            AttendanceType:'Attendance Type',
            MilitaryServiceStatus:'Military Service Status',
            HousingSituation:'HousingSituation',
            DateMarriage:'Date Of Marriage',
            Contracts:'Contracts',
            ContractType:'Contract Type',
            ContractFile:'Contract File',
            ContractDescription:'Contract Description',
            FamilyInformation:'Family Information',
            EncryptionMethod:'Encryption Method',
            InventoryTypes:'Inventory Types',
            CreateGoods:'Create Goods',
            SelectedKeywords:'Selected Keywords',
            ReviewWordsCompetitors:'Review The Words Of Competitors',
            CreateContentCreationMap:'Create a Content Creation Map',
            CodeSchema:'Code Schema',
            PreviewLink:'Preview Link',
            DisableComment:'Disable Comment',
            CustomFields:'Custom Fields',
            MemberManagement:'Member Management',
            NewMember:'New Member',
            ListMembers:'List Members',
            Roles:'Roles',
            HRM:'HRM',
            PersonManagement:'Personal Management',
            PersonGroup:'Person Group',
            PersonList:'Person List',
            Sitemap:'Sitemap',
            IFrame:'IFrame',
            CustomerInformation:'Customer Information',
            Legal:'Legal',
            Natural:'Natural',
            Women:'Women',
            Man:'Man',
            Position:'Position',
            ContactDetails:'Contact Details',
            CompanyPhone:'Company Phone',
            CompanyAddress:'Company Address',
            HomeAddress:'Home Address',
            User:'User',
            ReceiveEncryption:'Receive Encryption',
            SendEncryption:'Send Encryption',
            ReceivingPort:'Receiving Port',
            SendPort:'Send Port',
            MailServer:'Mail Server',
            IMAP:'IMAP',
            POP:'POP',
            AddUserAccount:'Add User Account',
            FrequentlyNumbers:'Frequently Used Numbers',
            DeterminingCommission:'Determining The Commission',
            IndividualClaims:'IndividualClaims',
            Advanced:'Advanced',
            AdvancedCampaign:'Advanced Campaign',
            SimpleCampaign:'Simple Campaign',
            SimpleCommission:'Simple Commission',
            ExpiredTickets:'Expired Tickets',
            ServicesUpgrades:'Services And Upgrades',
            NewTicket:'New Ticket',
            Terminated:'Terminated',
            Cartable:'Cartable',
            Complaint:'Complaint',
            Feedback:'Feedback',
            MyForms:'My Forms',
            TransferBetweenInventory :'Transfer Between Inventory',
            ShipmentTracking :'Shipment Tracking',
            Comments :'Comments',
            core :'core',
            EnterTitleAddressTheSurvey:'Enter the title and address of the survey',
            UsersFacilities:'Users Facilities',
            BrowserType:'Browser Type',
            MetaTagSchema:'MetaTag And Schema',
            QuickFilter:'Quick filter',
            SendEmail:'Send Email',
            DayOfWeek:'Day Of Week',
            Templates:'Templates',
            AddRole:'Add Role',
            Groups:'Groups',
            SelectTheCountry:'Select The Country',
            SelectTheProvince:'Select The Province',
            SelectTheCity:'Select The City',
            EnterTheAddress:'Enter The Address',
            PackagingAndShipping:'Packaging And Shipping',
            Folders:'Folders',
            PackageGroup:'Package Group',
            AddCategory:'Add Category',
            CategoryName:'Category Name',
            DateMembership:'Date of Membership',
            SubscriptionExpirationDate:'Subscription Expiration Date',
            TemplatePattern:'Template Pattern',
            Questions:'Questions',
            FrequentlyQuestions:'Frequently Asked Questions',
            Follow:'Follow',
            TicketsTracking:'Tickets Tracking',
            More:'More',
            ActiveWebsite:'Active website',
            Sellers:'Sellers',
            Expiring:'Expiring',
            ActiveServers:'Active Servers',
            HardSpace:'Hard Space',
            Requests:'Requests',
            ExpiringDomains:'Expiring Domains',
            ExpiringWebsites:'Expiring Websites',
            LatestWebsites:'Latest Websites',
            FolderPathRequired:'The Folder Path Is Required',
            FolderAddress:'Folder Address',
            OrganisationRoute:'Organisation Route',
            StepName:'Step Name',
            ReviewUnit:'Review Unit',
            ResponsibleReview:'Responsible Review',
            PreviousStation:'Previous Station',
            Small:'Small',
            Medium:'Medium',
            AlmostBig:'Almost Big',
            Large:'Large',
            SelectCountry:'Select Country',
            SelectProvince:'Select Province',
            SelectCity:'Select City',
            NumberCustomers:'Number of Customers',
            Marketers:'Marketers',
            ApplicationEmployment:'Application for Employment',
            Drivers:'Drivers',
            UsernameOrEmail:'Username Or Email',
            CreateAccount:'Create Account',
            LoginTheUserPanel:'Login To User Panel',
            TryAgain:'Try Again',
            Spouses:'Spouses',
            SignatureImage:'Signature Image',
            PleaseEnterYourTrackingNumber:'Please Enter Your Tracking Number',
            AddComplaint:'Add Complaint',
            NumberTracking:'Number Tracking',
            TitleComplaint:'Title Complaint',
            SubjectComplaint:'Subject Complaint',
            TextComplaint:'Text Complaint',
            Packaging:'Packaging',
            PostDelivery:'Post Delivery',
            NewQuestion:'New Question',
            DeleteQuestion:'Delete Question',
            Workflow:'Workflow',
            Organize:'Organize',
            DataEntry:'Data Entry',
            OfficialRegistration:'Official Registration',
            Business:'Business',
            Create:'create',
            MaximumAllowedUpload:'Maximum Allowed Upload',


},
    },
    fa: {
        translation: {
            MaximumAllowedUpload:'حداکثر مجاز آپلود',
            Create:'ایجاد',
            Business:'کسب وکار',
            OfficialRegistration:'ثبت رسمی',
            DataEntry:'ورود اطلاعات',
            Organize:'سازماندهی',
            Workflow:'گردش کار',
            DeleteQuestion:'حذف سوال',
            NewQuestion:'سوال جدید',
            PostDelivery:'تحویل پست',
            Packaging:'بسته بندی',
            TextComplaint:'متن شکایت',
            TitleComplaint:'عنوان شکایت',
            NumberTracking:'شماره مرسوله',
            AddComplaint:'ثبت شکایت',
            PleaseEnterYourTrackingNumber:'لطفا شماره مرسوله خود را وارد نمایید',
            SignatureImage:'تصویر امضا',
            InsurancePosition:'عنوان شغلی',
            Spouses:'همسران',
            TryAgain:'تلاش مجدد',
            LoginTheUserPanel:'ورود به پنل کاربری',
            CreateAccount:'ایجاد حساب کاربری',
            UsernameOrEmail:'نام کاربری یا ایمیل',
            Drivers:'رانندگان',
            ApplicationEmployment:'درخواست استخدام',
            Marketers:'بازاریابان',
            NumberCustomers:'تعداد مشتریان',
            SelectCity:'انتخاب شهر',
            SelectProvince:'انتخاب استان',
            SelectCountry:'انتخاب کشور',
            Large:'بزرگ',
            AlmostBig:'تقریبا بزرگ',
            Medium:'متوسط',
            Small:'کوچک',
            PreviousStation:'ایستگاه قبلی',
            ResponsibleReview:'مسئول بررسی',
            ReviewUnit:'واحد بررسی',
            StepName:'نام مرحله',
            OrganisationRoute:'مسیر سازمانی',
            FolderAddress:'آدرس فولدر',
            FolderPathRequired:'مسیر فولدر الزامیست',
            LatestWebsites:'آخرین وب سایت ها',
            ExpiringWebsites:'وب سایت های درحال انقضاء',
            ExpiringDomains:'دامین های درحال انقضاء',
            Requests:'درخواست ها',
            HardSpace:'فضای سخت افزار',
            ActiveServers:'سرورهای فعال',
            Expiring:'در حال انتقضاء',
            Sellers:'فروشندگان',
            ActiveWebsite:'وب سایت فعال',
            More:'بیشتر',
            TicketsTracking:'پیگیری تیکت ها',
            Follow:'پیگیری',
            FrequentlyQuestions:'سوالات متداول',
            Questions:'سوالات',
            TemplatePattern:'عنوان الگو',
            SubscriptionExpirationDate:'تاریخ انقضای اشتراک',
            DateMembership:'تاریخ عضویت',
            CategoryName:'نام دسته بندی',
            AddCategory:'ایجاد دسته بندی',
            PackageGroup:'گروه بندی پکیج',
            Folders:'فولدرها',
            PackagingAndShipping:'دسته بندی و ارسال',
            EnterTheAddress:'آدرس را وارد کنید',
            SelectTheCity:'شهر را انتخاب کنید',
            SelectTheProvince:'استان را انتخاب کنید',
            SelectTheCountry:'کشور را انتخاب کنید',
            Groups:'گروه ها',
            AddRole:'افزودن رل',
            Templates:'قالب ها',
            DayOfWeek:'روز هفته',
            SendEmail:'ارسال ایمیل',
            QuickFilter:'فیلتر سریع',
            MetaTagSchema:'متاتگ و اسکیما',
            BrowserType:'نوع مرورگر',
            UsersFacilities:'امکانات کاربران',
            EnterTitleAddressTheSurvey:'عنوان و آدرس نظر سنجی را وارد کنید',
            core :'core',
            TemplateCreation: 'ایجاد پوسته',
            Comments :'کامنت ها',
            ShipmentTracking :'پیگیری مرسوله',
            TransferBetweenInventory :'انتقال بین انبار',
            MyForms:'فرم های من',
            Feedback:'بازخورد',
            Complaint:'شکایت',
            Cartable:'کارتابل',
            Terminated:'خاتمه یافته',
            NewTicket:'تیکت جدید',
            ServicesUpgrades:'خدمات و ارتقاء',
            ExpiredTickets:'تیکت های خاتمه یافته',
            SimpleCommission:'کمیسون ساده',
            SimpleCampaign:'کمپین ساده',
            AdvancedCampaign:'کمپین پیشرفته',
            Advanced:'پیشرفته',
            IndividualClaims:'مطالبات اشخاص',
            DeterminingCommission:'تعیین پورسانت',
            FrequentlyNumbers:'شماره های پرکاربرد',
            AddUserAccount:'افزودن حساب کاربری',
            POP:'POP',
            IMAP:'IMAP',
            MailServer:'میل سرور',
            SendPort:'پورت ارسال',
            ReceivingPort:'پورت دریافت',
            SendEncryption:'رمزنگاری ارسالی',
            ReceiveEncryption:'رمزنگاری دریافتی',
            User:'کاربر',
            HomeAddress:'آدرس منزل',
            CompanyAddress:'آدرس شرکت',
            CompanyPhone:'تلفن شرکت',
            ContactDetails:'مشخصات تماس',
            Position:'سمت',
            Man:'مرد',
            Women:'زن',
            Natural:'حقوقی',
            Legal:'حقوقی',
            CustomerInformation:'اطلاعات مشتری',
            IFrame:'IFrame',
            Sitemap:'نقشه سایت',
            PersonList:'لیست اشخاص',
            PersonGroup:'گروه بندی اشخاص',
            PersonManagement:'مدیریت اشخاص',
            HRM:'مدیریت منابع انسانی',
            Roles:'نقش ها',
            ListMembers:'لیست اعضاء',
            NewMember:'عضو جدید',
            MemberManagement:'مدیریت اعضاء',
            CustomFields:'فیلدهای سفارشی',
            DisableComment:'غیرفعال کرد کامنت',
            PreviewLink:'لینک پیش نمایش ',
            CodeSchema:'اسکیما کد',
            CreateContentCreationMap:'ایجاد نقشه تولید محتوا',
            ReviewWordsCompetitors:'بررسی کلمات رقبا',
            SelectedKeywords:'کلمات کلیدی منتخب',
            CreateGoods:'معرفی کالا',
            InventoryTypes:'انواع انبار',
            EncryptionMethod:'متد رمزنگاری',
            FamilyInformation:'اطلاعات خانواده',
            ContractDescription:'توضیح قرارداد',
            ContractFile:'فایل قرارداد',
            ContractType:'نوع قرارداد',
            Contracts:'قراردادها',
            DateMarriage:'تاریخ ازدواج',
            HousingSituation:'وضعیت مسکن',
            MilitaryServiceStatus:'وضعیت نظام وظیفه',
            AttendanceType:'نوع حضور و غیاب',
            bloodType:'گروه خونی',
            JobTitle:'عنوان شغلی',
            TypeInsurance:'نوع بیمه',
            InsuranceCode:'کد بیمه',
            PersonalInformation:'اطلاعات پرسنلی',
            MyTemplates:'پوسته های من',
            Bought:'خریداری شده',
            CommonlyUsedFilters:'فیلتر های پرکاربرد',
            FormBuilder:'فرم ساز',
            Target:'نحوه باز شدن صفحه',
            AllowedReturnPeriod:'مدت مجاز مرجوعی کالا',
            Security:'امنیت',
            Script:'اسکریپت',
            SettingsPage:'صفحه تنظیمات',
            OtherGenders:'سایر جنسیت ها',
            WomensPhotos:'تصاویر خانم ها',
            MensPhotos:'تصاویر آقایان',
            FormTitle:'عنوان فرم',
            Basicsettings:'تنظیمات پایه',
            SiteLanguage:'زبان سایت',
            port:'Port',
            AddingFinancialRules:'افزودن احکام مالی',
            UserPrivacy:'حریم خصوصی کاربران',
            AdminPrivacy:'حریم خصوصی مدیران',
            UnauthorizedIPs:'آی پی های غیرمجاز',
            PossibilityMembership:'امکان عضویت',
            BackupEmail:'ایمیل پشتیبان',
            DefaultEmailForOTPCode:'ایمیل پیش فرض OTP',
            BackupLine:'خط پشتیبان',
            DefaultLineForOTPCode:'خط پیش فرض OTP',
            FirstLine:'خط اول',
            AccountDeletedSuccessfully:'حساب با موفقیت حذف شد',
            BackupList:'لیست بکاپ',
            SendingCheckinAndCheckoutTimesForGeneralManager:'ارسال زمان ورود و خروج مدیر کل',
            SendingCheckinAndCheckoutTimesForProductionLine:'ارسال زمان ورود و خروج سرپرست خط تولید',
            SendingCheckinAndCheckoutTimesForShiftManager :'ارسال زمان ورود و خروج مدیر شیفت',
            SendingCheckinAndCheckoutTimesForHumanResourcesManager:'ارسال زمان ورود و خروج مدیر منابع انسانی',
            SendingCheckinAndCheckoutTimesForProductionManager:'ارسال زمان ورود و خروج مدیر تولید',
            SendingCheckinAndCheckoutTimesForDeviceManager:'ارسال زمان ورود و خروج مدیر دستگاه',
            HourlyLeaveWithoutPay:'مرخصی ساعتی بدون حقوق',
            LeaveWithoutPay:'مرخصی بدون حقوق',
            HourlyPaidLeave:'مرخصی ساعتی با حقوق',
            PaidLeave:'مرخصی با حقوق',
            SendingCheckinAndCheckoutTimesForPersonnel:'ارسال زمان ورود و خروج برای پرسنل',
            SupervisorFee:'حق سرپرستی',
            LeaveConditions:'شرایط مرخصی',
            Enteries:'ورودی ها',
            Year:'سال',
            WorkFriday:'جمعه کاری',
            DailyMission:'ماموریت روزانه',
            AllowedHourlyLeave:'مرخصی ساعتی مجاز',
            Overtime:'اضافه کاری',
            NumberDaysAbsence:'تعداد روزهای غیبت',
            NumberDaysAttendance:'تعداد روزهای حضور',
            DeterminingVerdict:'تعیین حکم',
            Absence:'غیبت',
            Mission:'ماموریت',
            IncompleteRegistration:'ثبت ناقص',
            CompleteRegistration:'ثبت کامل',
            RecordedPerformance:'کارکرد ثبتی',
            ActivePersonnelTheGroup:'پرسنل فعال گروه',
            FreelancePersonnel:'پرسنل آزاد',
            WorkingHoliday:'تعطیل کاری',
            Closed:'تعطیل',
            Presence:'حضور',
            PersonnelAllocation:'اختصاص پرسنل',
            businessDays:'روزهای کاری',
            AddWorkgroup:'افزودن گروه کاری',
            PeriodInterval:'بازه دوره',
            AllowedExitPeriod:'بازه مجاز خروج',
            AllowedEntryPeriod:'بازه مجاز ورود',
            EndTimeRest:'ساعت پایان استراحت',
            BreakTime:'ساعت شروع استراحت',
            EndShiftTime:'ساعتن شروع شیفت',
            ShiftStartTime:'ساعت شروع شیفت',
            ColorCode:'کد رنگ',
            ShiftType:'نوع شیفت',
            Supervisor:'سرپرست',
            ProductionManager:'مدیر تولید',
            ShiftCode:'کد شیفت',
            ShiftName:'نام شیفت',
            DelayService:'تاخیر سرویس',
            NightWork:'شب کاری',
            HourlyMission:'ماموریت ساعتی',
            HourlyLeave:'مرخصی ساعتی',
            TotalOvertime:'مجموع اضافه کاری',
            UnauthorizedOvertime:'اضافه کاری غیر مجاز',
            LowTimeWork:'کسر کار',
            Hastening:'تعجیل',
            TaskWork:'کارکرد موظفی',
            CompulsoryOvertime:'اضافه کاری اجباری',
            Delay:'تاخیر',
            DepartureTime:'ساعت خروج',
            ArrivalTime:'ساعت ورود',
            PersonnelCode:'کد پرسنلی',
            BenchmarkDate:'تاریخ معیار',
            GroupCode:'کد گروه',
            GroupName:'نام گروه',
            Applicant:'درخواست کننده',
            RejectionRequest:'رد درخواست',
            ConfirmRequest:'تایید درخواست',
            DeterminingTheRules:'انتصاب حکم',
            NumberEmployees:'تعداد پرسنل',
            Efficiency:'راندمان',
            ManagementFee:'حق مدیریت',
            FiscalYear:'سال مالی',
            NameRules:'نام حکم',
            Device:'دستگاه',
            Leave:'مرخصی',
            MaritalStatus :'وضعیت تاهل',
            ArchiveLeaveAndMission :'بایگانی مرخصی و ماموریت',
            PersonnelName:'نام پرسنل',
            LeaveAndMission:'مرخصی و ماموریت',
            FinancialRules:'احکام مالی',
            Actions:'اعمال',
            EarlyDepartureTime:'زمان خروج مجاز در ماه',
            EarlyCheckinTime:'زمان تعجیل مجاز در ماه',
            WorkGroup:'گروه کاری',
            DefineCustomRules:'تعیین احکام سفارشی',
            SalaryTax:'مالیات برحقوق',
            SupplementaryInsuranceContribution:'سهم بیمه تکمیلی',
            UnemploymentInsuranceContribution:'سهم بیمه بیکاری',
            EmployersInsuranceContribution:'سهم بیمه کارفرما',
            PersonnelInsuranceContribution:'سهم بیمه پرسنل',
            ChildAllowance:'حق اولاد',
            SeverancePay:'پایه سنواتی',
            HourlyWage:'حقوق ساعتی',
            DailyWage:'حقوق روزانه',
            OfficialCalendar:'تقویم رسمی',
            TypeOfVacation:'نوع تعطیلات',
            HolidaysOfTheWeek:'روزهای تعطیل هفته',
            NightShiftCalculationRate:'نرخ محاسبه شیفت شب',
            NightShiftConditions:'شرایط شیفت شب',
            CalculationRateOfShiftAbsence:'نرخ محاسبه غیبت شیفتی',
            ShiftAbsenceConditions:'شرایط غیبت شیفتی',
            MissionCalculationRate:'نرخ محاسبه ماموریت',
            MissionConditions:'شرایط ماموریت',
            HourlyOvertimeCalculationRateWithSalary:'نرخ محاسبه اضافه کاری ساعتی با حقوق',
            PaidHourlyOvertimeConditions:'شرایط اضافه کاری ساعتی با حقوق',
            CalculationRateOfOvertimeWithSalary:'نرخ محاسبه اضافه کاری شیفتی با حقوق',
            PaidShiftOvertimeConditions:'شرایط اضافه کاری شیفتی با حقوق',
            MaximumMissionPerMonth:'حداکثر ماموریت در ماه',
            MaximumOvertimePerMonth:'حداکثر اضافه کاری در ماه',
            MaximumSickLeavePerMonth:'حداکثر استعلاجی در ماه',
            MaximumHourlyLeavePerMonth:'حداکثر مرخصی ساعتی در ماه',
            MaximumLeaveWithoutPay:'حداکثر مرخصی بدون حقوق',
            MaximumPaidLeave:'حداکثر مرخصی با حقوق',
            End:'پایان',
            Beginning:'استارت',
            RestTime:'زمان استراحت',
            EndTime:'ساعت پایان',
            StartTime:'ساعت شروع',
            Shift:'شیفت',
            HourlyWages:'دستمزد ساعتی',
            AnHour:'ساعتی',
            SmartReport:'گزارش هوشمند',
            DefaultProvisions:'احکام پیش فرض',
            AnnualCalendar:'تقویم سالانه',
            WeeklyVacation:'تعطیلات هفتگی',
            Rules:'قوانین',
            DetermineTheLimit:'تعیین محدودیت',
            CalculationType:'نوع محاسبه',
            SystemManager:'مسئول سیستم',
            DetermineSystemManagement:'تعیین مدیریت سیستم',
            DeviceNumber:'شماره دستگاه',
            DeviceModel:'مدل دستگاه',
            DeviceName:'نام دستگاه',
            AddShift:'افزودن شیفت',
            IndividualReport:'گزارش فردی',
            Absent:'غایبین',
            Attendees:'حاضرین',
            LegalProvisions:'احکام قانونی',
            Personnel:'پرسنل',
            Configs:'پیکربندی',
            ConnectionTest:'تست اتصال',
            RegisterDevice:'ثبت دستگاه',
            AttendanceSystem:'سیستم حضور وغیاب',
            CustomerGrouping:'گروه بندی مشتری',
            CustomerRegistration:'ثبت مشتری',
            DeductionsDocument:'سند کسورات',
            RequestRawMaterials:'درخواست مواد اولیه',
            ProductionRequest:'درخواست تولید',
            ReturnRequest:'درخواست مرجوعی',
            ReturnPurchase:'درخواست مرجوعی',
            ExitRequest:'درخواست خروج',
            ChoiceUsers:'انتخاب کاربران',
            ContentSelection:'انتخاب محتوا',
            SelectionProducts:'انتخاب محصولات',
            TimeOfLastRevision:'زمان آخرین ویرایش',
            FileExtension:'پسوند فایل',
            TheStatusOfContracts:'وضعیت قراردادها',
            DefaultValues:'مقادیر پیش فرض',
            ManagementPanelLanguage:'زبان پنل مدیریت',
            ShellSettings:'تنظیمات پوسته',
            SelectDashboard:'انتخاب داشبورد',
            DoesItHaveAWatermark:'واتر مارک دارد؟',
            TypeALetter:'یک حرف تایپ کنید',
            UseEitherCtrlOrAlt:'از Ctrl یا Alt استفاده کنید',
            CtrlShift:'Ctr+Shift',
            EnteringIsRequired:'وارد کردن الزامیست',
            DuplicateShortcutKeyValueEntered:'مقدار کلید میانبر تکراری وارد شده',
            TheShortcutKeyValueIsEmpty:'The shortcut key value is empty',
            Quiz:'مسابقه',
            Link:'لینک',
            AddTelegramGlassButton:'افزودن دکمه شیشه ای تلگرام',
            SaveTheDraft:'ذخیره پیش نویس',
            TheNumberRepetitions:'تعداد تکرار',
            RepeatAs:'تکرار به صورت',
            RepetitiveContent:'محتوای تکرار شونده',
            InstantDelivery:'ارسال آنی',
            SharedPost:'پست مشترک',
            FirstComment:'نظر اول',
            ProfileTagInTheVideo:'تگ پروفایل در ویدیو',
            ChangingTheTitleTheRailsSound:'تغییر عنوان صدای ریلز',
            ImageCover:'تصویر کاور',
            PostToFeed:'ارسال به فید',
            InstagramRails:'ریلز اینستاگرام',
            GlassButton:'دکمه شیشه ای',
            HiddenContent:'محتوای مخفی',
            DisplayTheDescriptionAboveThePost:'نمایش توضیحات بالای پست',
            AddTheChannelUsernameToCaption:'اضافه کردن یوزرنیم کانال به انتهای کپشن',
            ProtectedPost:'پست محافظت شده',
            PinThePostOnTheChannel:'پین کردن پست در کانال',
            TelegramSettings:'تنظیمات تلگرام',
            AddHashtagTitle:'هشتگ‌ها به پایین متن در کپشن شبکه‌های اجتماعی که از هشتگ پشتیبانی می‌کنند اضافه می‌شوند',
            AddHashtag:'اضافه کردن هشتگ',
            Story:'استوری',
            Post:'پست',
            Customization:'سفارشی سازی',
            Canceled:'لغو',
            Unpublished:'منتشر نشده',
            Repeating:'تکرار شونده',
            Scheduled:'زمان بندی شده',
            TimingFor:'زمان بندی برای',
            Album:'آلبوم',
            CreationTime:'زمان ایجاد',
            PublicationStatus:'وضعیت انتشار',
            ShiftWork:'شیفت کاری',
            Marketer:'بازاریاب',
            Seller:'فروشنده',
            Agent:'نماینده',
            LastCustomers:'آخرین مشتریان',
            LatestCustomersWebsites:'آخرین مشتریان وب سایت ها',
            DeterminationDiscountBasedRole:'تعیین تخفیف براساس نقش',
            DeterminationGroupDiscount:'تعیین تخفیف گروهی',
            DeterminationPersonalDiscount:'تعیین تخفیف شخصی',
            DeterminationCustomCommission:'تعیین پورسانت سفارشی',
            DeterminationCommissionBasedRole:'تعیین پورسانت براساس نقش',
            UserRole:'نقش کاربری',
            DeterminationGroupCommission:'تعیین پورسانت گروهی',
            DeterminationPersonalCommission:'تعیین پورسانت شخصی',
            Messengers:'پیام رسان ها',
            Smart:'هوشمند',
            Addresses:'آدرس ها',
            DateManufacture:'تارخ تولید',
            CustomField:'فیلد سفارشی',
            ConnectAccounts:'اتصال اکانت ها',
            AccountSelection:'انتخاب اکانت',
            Facebook:'فیس بوک',
            ConnectionTraining:'آموزش اتصال',
            AreYouSureAboutBuyingTheTemplate:'اطمینان از خرید قالب دارید ؟',
            TemplatePurchaseConfirmation:'تایید خرید قالب',
            CancellationPurchase:'انصراف از خرید',
            CorrectAnswer:'گزینه صحیح',
            Option:'گزینه',
            StatisticsAnalysis:'آمارو تحلیل',
            OutgoingServer:'سرور ارسال',
            IncomingServer:'سرور دریافت',
            PublicationList:'لیست انتشار',
            CreateCaption:'ایجاد کپشن',
            AddCaptions:'افزودن کپشن',
            Captions:'کپشن ها',
            Caption:'کپشن',
            Files:'فایل ها',
            Tone:'لحن',
            AutomaticCaption:'کپشن خودکار',
            EnterTheText:'متن را وارد کنید...',
            PersonalSettings:'تنظیمات شخصی',
            DedicatedRobot:'ربات اختصاصی',
            RobotAPICode:'کد ای پی آی ربات',
            UsernameRobot:'نام کاربری ربات',
            EmailAddress:'آدرس ایمیل',
            AddEmail:'افزودن ایمیل',
            EmailMarketing:'ایمیل مارکتینگ',
            SocialMarketing:'شبکه های اجتماعی',
            AdditionsDocument:'سند اضافات',
            ReturnDocument:'سند مرجوعی',
            ExitDocument:'سند خروج',
            EntryDocument:'سند ورود',
            AccountSearch:'جستجوی اکانت',
            PublishingContent:'انتشار محتوا',
            SelectPage:'انتخاب صفحه',
            ViewAccount:'مشاهده اکانت',
            Reentry:'ورود مجدد',
            DeleteAccount:'حذف اکانت',
            Accounts:'اکانت ها',
            Referral:'ارجاع',
            NumberItems:'تعداد اقلام',
            DocumentNumber:'شماره سند',
            Producer:'تولید کننده',
            DisplayInTheOnlineStore:'در فروشگاه آنلاین نمایش دهیم',
            PurchasePrice:'قیمت خرید',
            UnitMeasurement:'واحد اندازه گیری',
            ServiceFee:'هزینه خدمت',
            TollRates:'نرخ عوارض',
            TaxRate:'نرخ مالیات',
            LastYear:'بازید سال قبل',
            LastMonth:'بازدید ماه قبل',
            LastWeek:'بازدید هفته قبل',
            YesterdayViews:'بازدید روز قبل',
            TotalViews:'بازدید کل',
            PurchaseManager:'مدیر خرید',
            SmartNotification:'اعلان هوشمند',
            AutomaticDocumentRegistration:'ثبت سند اتوماتیک',
            ListOfRequests:'لیست درخواست ها',
            Returned:'مرجوع',
            ReturnFromPurchase:'بازگشت از خرید',
            Exit:'خروج',
            AvailableBuildSeries:'سری ساخت موجود',
            Goods:'کالا',
            SearchByName:'براساس (نام)',
            AddBranch:'ایجاد شعبه',
            InventoryCode:'کد انبار',
            Branch:'شعبه',
            InventoryManager:'مسئول انبار',
            InventoryType:'نوع انبار',
            InventoryName:'نام انبار',
            CreateInventory:'ایجاد انبار',
            PrintPriceList:'چاپ لیست قیمت',
            Print:'چاپ',
            ProductDescription:'توضیح کالا',
            Campaign:'کمپین',
            Tag:'تگ',
            Possibilities:'امکانات',
            ServiceSelection:'انتخاب سرویس',
            MobileEmail:'موبایل-ایمیل',
            SaveAddressTitle:'جهت ثبت آدرس در سیستم انتخاب کنید',
            UserAddresses:'آدرس های کاربر',
            NumberParameter:'تعداد پارامتر',
            HeightParameter:'ارتفاع پارامتر',
            WidthParameter:'عرض پارامتر',
            LengthParameter:'طول پارامتر',
            ParameterWeight:'وزن پارامتر',
            InsuranceParameter:'پارامتر بیمه',
            SearchByCenter:'براساس ( نام مرکز )',
            MaximumPurchase:'حداکثر خرید',
            SendNewTicket:'ارسال تیکت جدید',
            UserDeletedSuccessfully:'کاربر با موفقیت حذف شد',
            UserRegisteredSuccessfully:'کاربر با موفقیت ثبت شد',
            BackToThePage:'برگشت به صفحه',
            NotFoundTitle:'به نظر می رسد در فضا گم شده اید',
            GoodDay:'روز خوبی داشته باشید جناب Harmony :-)',
            SomePage:'صفحات دیگری از این سرور که شما اجازه دسترسی به آن‌ها را دارید',
            NewRepresentative:'نماینده جدید',
            CurrentRepresentative:'نماینده فعلی',
            ChangeRepresentative:'تغییر نماینده',
            ErrorPossiblyTitle:'دسترسی اجرایی ممنوع، دسترسی خواندن ممنوع، دسترسی نوشتن ممنوع، نیاز به SSL، نیاز به SSL 128، آدرس IP رد شده، نیاز به گواهی کاربر، دسترسی به سایت ممنوع، کاربران بیش از حد، پیکربندی نامعتبر، تغییر رمز عبور، دسترسی توسط نقشه‌بردار رد شده، گواهی کاربر لغو شده، نمایش لیست دایرکتوری ممنوع، مجوزهای دسترسی کاربر بیش از حد، گواهی کاربر غیر معتبر یا نامعتبر، گواهی کاربر منقضی شده یا هنوز معتبر نیست، ورود به سیستم پاسپورت ناموفق، دسترسی منبع رد شده، درخواست‌های بیش از حد از یک IP...',
            ErrorPossiblyCausedBy:'علت خطای احتمالی',
            ErrorTitle:'دسترسی رد شد. شما اجازه دسترسی به این صفحه در این سرور را ندارید.',
            ErrorDescription:'توضیحات خطا',
            ErrorCode:'کد خط',
            FormCirculationPath:'مسیر گردش فرم',
            FormType:'نوع فرم',
            ShowPlace:'محل نمایش',
            RegistrationFailedMessage:'پیام ثبت ناموفق',
            SuccessfulRegistrationMessage:'پیام ثبت موفق',
            MaximumSize:'حداکثر اندازه',
            MinimumSize:'حداقل اندازه',
            StorageLocation:'محل ذخیره سازی',
            ShowOnTheMapDefaultLocation:'نمایش در نقشه-(مکان پیشفرض)',
            Format:'فرمت',
            TheFirstOptionEmpty:'اولین گزینه خالی باشد',
            DisplayFormat:'فرمت نمایش',
            AnOption:'یک گزینه ای',
            DisplayText:'متن نمایشی',
            MultipleChoice:'چند گرینه ای',
            DetermineHeight:'تعیین ارتفاع',
            ShowFor:'نمایش برای',
            Size:'اندازه',
            ClassName:'نام کلاس',
            ReadOnly:'فقط خواندنی',
            Mandatory:'اجباری',
            DepositReceiptForm:'فرم دریافت بیعانه',
            CooperationFormatSales:'قالب همکاری در فروش',
            RepresentationTemplate:'قالب نمایندگی',
            RecruitmentTemplate:'قالب استخدام',
            ProfileFormat:'قالب مشخصات',
            ReadyTemplates:'قالب های آماده',
            Color:'رنگ',
            Period:'بازه',
            Voice:'صدا',
            WebsiteLink:'لینک وبسایت',
            DateAndTime:'تاریخ و ساعت',
            Separator:'جداکننده',
            SelectFromTheList:'انتخاب از لیست',
            SelectSelectionFromList:'انتخاب چنتایی از لیست',
            ChooseAnOption:'انتخاب یک گزینه',
            SelectMultipleOptions:'انتخاب چند گزینه',
            InitialSurveySettings:'تنظیمات اولیه نظرسنجی',
            AddConnection:'افزودن ارتباط',
            NewAddress:'آدرس جدید',
            PreviousAddress:'آدرس قبلی',
            Statistics:'آمار',
            Folder:'فولدر',
            TheNumberOfProductCodeDigits:'تعداد ارقام کد محصول',
            ChooseTheOptionYouWant:'گزینه مورد نظر خود را انتخاب کنید',
            RegisterYourComment:'نظر خود را ثبت کنید',
            Buy:'خرید',
            View:'مشاهده',
            ToExpiration:'مانده تا انقضاء',
            RenewalBillReminderDate:'تاریخ یادآوری صورتحساب تمدید',
            ComparedToThePriceOfThePackage:'نسبت به قیمت پکیج',
            CalculationOfWebsiteLanguagePriceTariff:'محاسبه تعرفه قیمت زبان وبسایت',
            TheAllowedAmountOfBuyingMailHostIs:'میزان مجاز خرید میل هاست',
            TheAmountOfPurchasesIsAllowed:'میزان مجاز خرید هاست',
            MB:'MB',
            QuickRegistrationPeople:'ثبت سریع اشخاص',
            PriceChanges:'تغییرات قیمت',
            TheURLIsNotValid:'آدرس اینترنتی معتبر نیست',
            OriginalTemplate:'قالب اصلی',
            Platform:'پلتفرم',
            StatisticsCities:'آمار شهرها',
            AccountCreatedSuccessfully:'حساب با موفقیت ایجاد شد',
            AccountEditedSuccessfully:'حساب با موفقیت ویرایش شد',
            CreateSecretary:'ایجاد منشی',
            Charge:'شارژ',
            Pattern:'الگو',
            Secretary:'منشی',
            ViewText:'مشاهده متن',
            NumberDays:'تعداد روز',
            PageViews:'بازدید صفحات',
            Visitors:'بازدید کنندگان',
            reportFrom:'گزارش از',
            StatisticsCountries:'آمار کشور ها',
            BrowserStatistics:'آمار مرورگرها',
            SelectedReferrers:'ارجاع دهنده های منتخب',
            MostVisitedPages:'صفحات پربازدید',
            AnnualVisit:'بازدید سالانه',
            MonthlyVisit:'بازدید ماهیانه',
            WeeklyVisit:'بازدید هفتگی',
            AverageVisit:'میانگین بازدید',
            PagesVisited:'صفحات بازدید شده',
            NumberVisitors:'تعداد بازدیدکنندگان',
            ChargeRate:'میزان شارژ',
            RequestAndUpgrade:'درخواست و ارتقاء',
            capacity:'capacity',
            UpgradeHistory:'تاریخچه ارتقاء',
            WebsiteInformation:'مشخصات وبسایت',
            CreateDocument:'افزودن داکیومنت',
            ViewFile:'مشاهده فایل',
            ViewMenu:'مشاهده منو',
            CloseMenu:'بستن منو',
            AllInformation:'همه اطلاعات',
            InformationFilter:'فیلتر اطلاعات',
            SurveyGroup:'گروه نظرسنجی',
            ShopGroup:'گروه فروشگاه',
            ContentGroup:'گروه مطالب',
            Timing:'زمان بندی',
            DeleteSite:'حذف سایت',
            AccessLevel:'سطح دسترسی',
            HighLoadDisk:'بار زیاد-Disk',
            HighLoadMemory:'بار زیاد-Memory',
            HighLoadCpu:'بار زیاد-CPU',
            generalManagerSettings:'تنظیمات مدیر کل',
            DashboardSettings:'تنظیمات داشبورد',
            Host:'هاست',
            SiteAddress:'آدرس سایت',
            senderNumber:'شماره فرستنده',
            Document:'داکیومنت',
            ViewDocument:'مشاهده داکیومنت',
            MonetaryUnit:'واحد پولی',
            DeleteServer:'حذف سرور',
            IIS:'IIS',
            Restart:'راه اندازی مجدد',
            ip:'IP',
            Province:'Province',
            Country:'Country',
            title:'Title',
            WithdrawalPart:'بخش برداشت',
            Switching:'تغییر وضعیت',
            Refresh:'تازه کردن',
            ByCountry:'براساس کشور',
            CalculationMethod:'نحوه محاسبه',
            MinimumMaximumPurchase:'حداقل و حداکثر خرید',
            YourGuide:'راهنمای شما',
            Titles:'عناوین',
            StoreSettings:'تنظیمات فروشگاه',
            AuxiliaryLink:'لینک کمکی',
            TrainingVideoLink:'لینک ویدیو آموزش',
            Documentation:'مستندات',
            ListGuide:'لیست راهنما',
            UpdateDate:'تاریخ بروزرسانی',
            ProjectDocumentList:'لیست داکیومنت',
            Links:'لینک ها',
            FirstDayOfTheWeek:'روز اول هفته',
            WeeklySettings:'تنظیمات هفته',
            AddBankAccount:'افزودن حساب',
            NameDepartment:'نام دپارتمان',
            Weekly:'هفتگی',
            CreateReminder:'افزودن یادآور',
            ProjectDocument:'داکیومنت پروژه',
            EndInventory:'اتمام موجودی',
            GoodsName:'نام کالا',
            MaximumInventory:'حداکثر موجودی',
            MinimumInventory:'حداقل موجودی',
            QrCode:'QrCode',
            FieldConditionValueIsEmpty:'فیلد  یا شرط یا مقدار خالی هست',
            FilterRegisteredSuccessfully:'فیلتر  با موفقیت ثبت شد',
            FilterRemovedSuccessfully:'فیلتر با موفقیت حذف شد',
            lastWeek:'هفته قبل',
            UpgradeType:'نوع ارتقاء',
            BrandConfirmation:'تائید برند',
            TypeOfService:'نوع خدمات',
            TariffTable:'جدول تعرفه',
            count:'تعداد',
            SystemUpgradeFactor:'فاکتور ارتقاء سیستم',
            InvoiceValidityDate:'تاریخ اعتبار صورتحساب',
            ProformaInvoice:'پیش فاکتور',
            SalesInvoice:'فاکتور فروش',
            NoRegistration:'بدون ثبت',
            EnterTheInvoiceValidityDate:'تاریخ اعتبار صورت حساب را وارد کنید',
            EnterTheBillingDate:'تاریخ صدور صورتحساب را وارد کنید',
            NumberProductsCannotBe0:'تعداد محصولات نمیتواند 0 باشد',
            ValidityDate:'تاریخ اعتبار',
            Party:'طرف حساب',
            dateOfReceipt:'تاریخ فیش',
            ReceiptNumber:'شماره رسید',
            destinationBank:'بانک مقصد',
            SourceBank:'بانک مبداء',
            BankReceiptAmount:'مبلغ فیش بانکی',
            BillAmount:'مبلغ صورتحساب',
            ManualPayment:'پرداخت دستی',
            onlinePayment:'پرداخت آنلاین',
            MessageToSiteManager:'پیام به مدیر سایت',
            MessageToMembers:'پیام به اعضاء',
            TextSharing:'اشتراک متن',
            PhotoSharing:'اشتراک عکس',
            ShipmentTrackingNumber:'شماره رهگیری مرسوله',
            BankTrackingNumber:'شماره پیگیری بانکی',
            FactorNumber:'شماره فاکتور',
            TransactionTime:'زمان تراکنش',
            SuccessfulTransaction:'تراکنش موفق',
            UnsuccessfulTransaction:'تراکنش ناموفق',
            ShareTheReceipt:'اشتراک گذاری رسید',
            YourFilesHaveBeenSentSuccessfully:'فایل های شما با موفقیت ارسال شد',
            PleaseSelectFile:'لطفا فایل را انتخاب کنید',
            QuestionType:'نوع سوال',
            CancellationRegistration: 'انصراف از ثبت',
            RecordQuestions: 'ثبت سوالات',
            AreYouSureToRegisterTheQuestions: 'اطمینان از ثبت سوالات دارید؟',
            NoQuestionHasBeenRegistered: 'سوالی ثبت نشده است',
            YourAnswersHaveBeenSuccessfullyRegistered: 'جواب های شما با موفقیت ثبت شد',
            ThisItemWillBePolledInWritingByTheUser: 'این آیتم به صورت کتبی از طرف کاربر نظرسنجی خواهد شد',
            ChooseTheNextQuestionsFromTheAvailableQuestions: 'سوالات بعدی را از بین سوال های موجود انتخاب کنید',
            Duty: 'وظیفه',
            TextReminder: 'یادآور متنی',
            DataBackupWasDoneSuccessfully: 'بکاپ اطلاعات با موفقیت انجام شد',
            DataCleanupCompletedSuccessfully: 'پاکسازی داده با موفقیت انجام شد',
            PleaseSelectDomain: 'لطفا دامین را انتخاب کنید',
            PleaseSelectSection: 'لطفا یک بخش را انتخاب کنید',
            SuccessfullyCreated: 'با موفقیت ایجاد شد',
            SuccessDone:'با موفقیت انجام شد',
            PermissionsRemovedSuccessfully: 'دسترسی شما با موفقیت حذف شد',
            PermissionsEditedSuccessfully: 'دسترسی با موفقیت ویرایش شد',
            RemovedSuccessfully: 'با موفقیت حذف شد',
            applyCode: 'اعمال کد',
            amountCaption:'مقدار(کپشن)',
            BillValidityPeriod:'مدت زمان اعتبار صورتحساب',
            InvoiceValidityPeriod:'مدت زمان اعتبار فاکتور',
            Remindervalue:'مقدار یادآور',
            OtherSettings:'سایر تنظمات',
            ReminderInterval:'فاصله یادآوری',
            PleaseEnterTextMessage:'لطفا متن پیام را وارد کنید',
            SmsSentSuccessfully:'پیام با موفقیت ارسال شد',
            CostComputation:'محاسبه هزینه',
            ProductAddress:'آدرس محصول',
            AdvertisementAddress:'آدرس تبلیغات',
            TitleAdvertisement:'عنوان تبلیغات',
            MenuHasBeenSuccessfullyRegistered:'منو با موفقیت ثبت شد',
            SurveyAddress:'آدرس نظرسنجی',
            FlashMessage:'پیام فلش',
            TitleSurvey:'عنوان نظرسنجی',
            Thesourceanddestinationunitscannotbethesame:'واحد مبدا و مقصد نمیتواند یکسان باشند',
            Update:'بروزرسانی',
            AllowedTemlpates:'قالب های مجاز',
            AdminPanel:'Admin Panel',
            PaymentCanceledSuccess:'پرداخت با موفقیت لغو شد',
            FromAccount:'از حساب',
            InvoiceType:'نوع فاکتور',
            NotValidBillAmount:'مبلغ صورت حساب حساب معتبر نیست ',
            BillingDate:'تاریخ صدور صورتحساب',
            ManualShoppingCart:'سبد خرید دستی',
            BillPayment:'پرداخت صورتحساب',
            WithdrawFromTheWallet:'برداشت از کیف پول',
            CashAmount:'مبلغ نقدی',
            FundSelection:'انتخاب صندوق',
            BankPaymentAmount:'مبلغ پرداخت بانکی',
            TariffRemovedSuccessfully:'تعرفه با موفقیت حذف شد',
            TariffRegisteredSuccessfully:'TariffEditedSuccessfully',
            TariffEditedSuccessfully:'تعرفه با موفقیت ویرایش شد',
            ExchangeRateRegisteredSuccessfully:'نرخ تبدیل با موفقیت ثبت شد',
            ExchangeRateEditedSuccessfully:'نرخ تبدیل با موفقیت ویرایش شد',
            TheCommissionRateMustBeBetweenTheSourceORDestinationRate:'نرخ کارمزد باید بین نرخ مبدا یا مقصد باشد',
            MajorDiscount:'تخفیف عمده',
            ExchangeRateRemovedSuccessfully:'نرخ تبدیل با موفقیت حذف شد',
            SingleDiscount:'تخفیف تکی',
            Register:'ثبت',
            CurrencyType:'نوع ارز',
            TransactionState:'وضعیت تراکنش',
            Undefined:'نامشخص',
            SMSExampleMobile:'{mobile}',
            SMSExampleSex:'{sex}',
            SMSExampleName:'{name}',
            TemplateText:'متن الگو',
            DefaultText:'متن پیش فرض',
            MessageCode:'کد پیام',
            SourceCurrency:'واحد پولی مبداء',
            DestinationCurrency:'واحد پولی مقصد',
            Symbol:'علامت اختصاری',
            CommissionRate:'نرخ کارمزد',
            ExchangeRate:'نرخ تبدیل',
            SubMenu:'ایجاد زیر منو',
            Professional:'حرفه ای',
            ImageSettings:'تنظیمات تصاویر',
            Basedonthetemplate:'براساس قالب',
            Viewthesite:'مشاهده سایت',
            Chart:'چارت',
            AnswerToTheSurvey:'پاسخ به نظرسنجی',
            SetValue:'تعیین مقادیر',
            CMSManagement:'مدیریت محتوا',
            SampleFile:'فایل نمونه',
            Theamountofpurchases:'میزان خرید هاست',
            Suggestedspace:'فضای پیشنهادی',
            Yourcurrentspace:'فضای فعلی شما',
            Xmin:'X<=',
            Xmax:'X>',
            Yourwebsitehasbeencreated:'وبسایت شما ساخته شد',
            Enterthesurveyenddate:'تاریخ پایان نظرسنجی را وارد کنید',
            Botfather:'ربات پدر',
            Thisweeksales:'فروش هفته جاری',
            Currentmonthsales:'فروش ماه جاری',
            Quarterlysales:'فروش سه ماهه',
            Thisyearsales:'فروش امسال',
            thisYear:'امسال',
            totalsales:'فروش کل',
            Latestusers:'آخرین کاربران',
            HTML:'HTML',
            Deposittoday:'واریزی امروز',
            Enterthedatabaseconnectionstring:'رشته اتصال دیتابیس را وارد کنید',
            Managementbot:'بات مدیریت',
            Remotemanagement:'کنترل از راه دور',
            Quickpurchase:'خرید سریع',
            Packagerole:'نقش پکیج',
            CodeHEX:'کد هگس',
            Rate:'تعیین امتیاز',
            Emoji:'ایموجی',
            Optiontext:'متن گزینه',
            nextquestion:'سوال بعدی',
            hasCycle:'دارای چرخه',
            hasIpLimitation:'محدودیت آی پی',
            Gettingwrittentext:'گرفتن متن کتبی',
            Thesurveyhasbeensuccessfullysubmitted:'نظرسنجی با موفقیت ثبت شد',
            Thesurveyhasbeensuccessfullyedited:'نظرسنجی با موفقیت ویرایش شد',
            Thesurveyquestiontextoroptionsareempty:'سوال نظرسنجی یا متن یا مقدار موارد نظرسنجی خالی می باشند',
            Yourlinkhassuccessfullyremoved:'لینک شما با موفقیت حذف شد',
            Yourlinkhassuccessfullyregistered:'لینک شما با موفقیت ثبت شد',
            Linkeditedsuccessfully:'لینک با موفقیت ویرایش  شد',
            Between0and1:'بین 0 تا 1',
            Annually:'سالیانه',
            Daily:'روزانه',
            Always:'همیشه',
            Averagechanges:'میانگین تغییرات',
            Eachsectiononefile:'هربخش یک فایل',
            Onefile:'یک فایل',
            Manufacturingmethod:'روش ساخت',
            Nodontchangeit:'نه تغییر نده',
            Yeschangeit:'اره تغییر بده!',
            Thereisnopossibilitytoreverttopreviousdataandthenewinformationwillreplacetheolddata:'امکان برگشت اطلاعات قبلی وجود ندارد و اطلاعات جدید جایگزین اطلاعات قبلی میشود',
            Areyousureyouwanttomakethechange:'اطمینان از تغییر دارید؟',
            PleaseenteravalidserverIP:'لطفا IP سرور معتبر وارد کنید',
            Upto10MB:'حداکثر تا 10 مگابایت',
            ImportanceLevel:'درجه اهمیت',
            Userselection:'انتخاب کاربر',
            Tickettoadmin:'تیکت به ادمین',
            Supportticket:'تیکت پشتیبانی',
            Ticketsformembers:'تیکت به اعضاء',
            Tickettothecustomer:'تیکت به مشتری',
            Yourtickethasbeensuccessfullysubmitted:'تیکت شما با موفقیت ارسال شد',
            Thefilesizeexceedstheallowedlimit:'سایز فایل بیشتر از حد مجاز است',
            Pleaseselectdepartment:'لطفا دپارتمان انتخاب کنید',
            ContactUs:'ارتباط با ما',
            Replytothismessage:'پاسخ به این پیام',
            IsClosed:'بسته شده',
            Quickguide:'راهنمای کوتاه',
            Ordercancellation:'لغو سفارش',
            Order:'ترتیب',
            Thedepartmentbeensuccessfullydeleted:'دپارتمان با موفقیت حذف شد',
            Thedepartmentbeensuccessfullyregistered:'دپارتمان با موفقیت ثبت شد',
            Thedepartmentbeensuccessfullyedited:'دپارتمان با موفقیت ویرایش شد',
            Yourfilehasbeensuccessfullyextracted:'فایل شما با موفقیت استخراج شد',
            Thecompressedfilewascreatedsuccessfully:'فایل فشرده با موفقیت ایجاد شد',
            Selectoneofthefollowingoperations:'انتخاب یکی از عملیات های زیر',
            Replace:'جایگزین',
            Filenamesuccessfullychanged:'نام فایل  با موفقیت تغییر کرد',
            Filessuccessfullyrecovered:'فایل ها با موفقیت بازیابی شدند',
            filesuccessfullydeleted:'فایل با موفقیت حذف شد',
            Yourlinkhasbeencopied:'لینک شما کپی شد',
            Theinformationsuccessfullydeleted:'اطلاعات با موفقیت پاک شد',
            Yourclipboardisempty:'هیچ مسیری در کلیپ بورد شما نیست',
            Thefolderhasbeencreatedsuccessfully:'فولدر با موفقیت ایجاد شد',
            Thefolderisduplicated:'فولدر تکراری می باشد',
            Theoperationwassuccessfully:'عملیات با موفقیت انجام شد',
            Copyingthetext:'کپی متن',
            CreatingMenu:'ایجاد منو',
            Editingserver:'ویرایش سرور',
            Shuttingdowntheserver:'خاموش کردن سرور',
            Approvedwithdrawals:'برداشت های تایید شده',
            Pendingwithdrawalconfirmationlist:'لیست انتظار تایید برداشت',
            Pleaseselectstatus:'لطفا یک وضعیت انتخاب کنید',
            BacktoHome:'بازگشت به خانه',
            DiscountPercentage:'درصد تخفیف',
            Enteryourmessage:'پیام خود را وارد کنید...',
            Ticketstatus:'وضعیت تیکت',
            Tickettype:'نوع تیکت',
            NoteStatus:'نوع یادداشت',
            Ticketsuccessfullysubmitted:'تیکت با موفقیت ارسال شد',
            QuickPriceChange:'تغییر قیمت سریع',
            Templateselected:'قالب انتخاب شد',
            Pleaseselectleasttemplate:'حداقل یک قالب انتخاب کنید',
            Pleaseenterwebsitetitle:'لطفا عنوان سایت را وارد کنید',
            Pleaseselectuser:'لطفا یک کاربر انتخاب کنید',
            SelectCustomerTitle:'لورم',
            SelectServerTitle:'لورم',
            LicenseInformationTitle:'لورم',
            LicenseInformationDomain:'لورم',
            Textcolor:'رنگ متن',
            Backgroundcolor:'رنگ پس زمینه',
            isPending:'درانتظار',
            State:'وضعیت',
            CommentsRestoredSuccessfully:'کامنت ها با موفقیت برگردانده شدند',
            AreyouSureToRestoreAll:'اطمینان از برگرداندن همه دارید؟',
            ThreeMonth:'سه ماه پیش',
            Response:'پاسخ ',
            CommentsDeletedSuccessfully:'کامنت ها با موفقیت حذف شدند',
            CommentDeletedSuccessful:'کامنت با موفقیت حذف شد',
            CommentsConfirmedSuccessfully:'کامنت ها با موفقیت تایید شدند',
            AreyouSuretoConfirmAll:'اطمینان از تایید همه دارید؟',
            CommentsRejectedSuccessfully:'کامنت ها با موفقیت رد شدند',
            Reject:'رد کن',
            Confirm:'تایید کن',
            Edit:'ویرایش',
            ThereIsNowayback:'امکان برگشت وجود ندارد',
            AreyouSuretoRejectAll:'اطمینان از رد کردن همه دارید؟',
            CommentEditSuccess:'کامنت با موفقیت ویرایش شد',
            CommentAddSuccess:'کامنت با موفقیت ثبت شد',
            Fastsettings:'تنظیمات سریع',
            Calendarsettings:'تنظیمات تقویم',
            Addinginformation:'افزودن اطلاعات',
            Passwordchangesuccessful:'تغییر کلمه عبور با موفقیت انجام شد',
            Viewtransactions:'مشاهده تراکنش ها',
            Depositrequest:'درخواست واریز وجه',
            MyRequests:'درخواست های من',
            Numberonlinepeople:'تعداد افراد آنلاین',
            Startconversation:'شروع گفتگو',
            Viewpanel:'مشاهده پنل',
            Managementpanel:'پنل مدیریت',
            ProfileEdit:'ویرایش پروفایل',
            Successfullyregistered:'با موفقیت ثبت شد',
            PleaseEnterCaptcha:'لطفا کپچا را وارد کنید',
            CaptchadosenotMatch:'کپچای وارد شده مغایرت دارد',
            CaptchaConfirmed:'کپچا تایید شد',
            CaptchaValue:"مقدار کپچا",
            validate:'اعتبار سنجی',
            regenerate:'دوباره',
            Discountcampaignhasbeensuccessfullyregistered:'کمپین تخفیف با موفقیت ثبت شد',
            Discountcampaignhasbeensuccessfullyedited:'کمپین تخفیف با موفقیت ویرایش شد',
            Discountcampaignhasbeensuccessfullydeleted:'کمپین تخفیف شما با موفقیت حذف شد',
            Discountcodehasbeensuccessfullydeleted:'کد تخفیف شما با موفقیت حذف شد',
            Discountcodehasbeensuccessfullyregistered:'کد تخفیف با موفقیت ثبت شد',
            Day:'روز',
            Discountcodehasbeensuccessfullyedited:'کد تخفیف با موفقیت ویرایش شد',
            Guaranteeextensionhasbeensuccessfullycompleted:'تمدید گارانتی با موفقیت انجام شد',
            Guaranteehasbeensuccessfullyedited:'گارانتی با موفقیت ویرایش شد',
            ProductGuaranteehasbeensuccessfullyadded:'گارانتی محصول با موفقیت اضافه شد',
            Sixteencharactersarerequired:'شانزده کارکتر لازم است',
            Guaranteecodeismandatory:'کد گارانتی الزامیست',
            Guaranteehasbeensuccessfullydeleted:'گارانتی شما با موفقیت حذف شد',
            Guaranteestatushasbeensuccessfullychanged:'وضعیت گارانتی با موفقیت تغییر کرد',
            Guaranteecodehasbeensuccessfullyregistered:'کد گارانتی شما شما با موفقیت ثبت  شد',
            Timeselectionismandatory:'انتخاب زمان الزامیست',
            Productnameismandatory:'نام محصول الزامیست',
            Billcreation:'ایجاد صورتحساب',
            Featurerank:'رتبه ویژگی',
            Featurevalue:'مقدار ویژگی',
            Featuretype:'نوع ویژگی',
            Featurename:'نام ویژگی',
            Statushasbeensuccessfullychanged:'وضعیت با موفقیت تغییر کرد',
            Numberoffeatures:'تعداد ویژگی',
            Metagrouphasbeensuccessfullyadded:'متا گروه شما با موفقیت اضافه شد',
            Featuregrouphasbeensuccessfullyedited:'گروه ویژگی شما با موفیت ویرایش شد',
            Advertisementshavebeensuccessfullyadded:'تبلیغات شما با موفقیت اضافه شد',
            Advertisementshavebeensuccessfullyregistered:'تبلیغات شما با موفقیت ثبت شد',
            Advertisementshavebeensuccessfullyedited:'تبلیغات شما با موفقیت ویرایش شد',
            Advertisementhasbeensuccessfullydeleted:'تبلیغ شما با موفقیت حذف شد',
            Participants:'شرکت کنندگان',
            Shortcodehasbeensuccessfullycreated:'کد کوتاه با موفقیت ایجاد شد',
            Shortcodehasbeensuccessfullyedited:'کد کوتاه با موفقیت ویرایش شد',
            Templatehasbeensuccessfullyupdated:'بروزرسانی قالب شما با موفقیت انجام شد',
            Templatehasbeensuccessfullyadded:'قالب شما با موفقیت اضافه شد',
            ChooseTemplateTitle:'لورم',
            Templatehasbeensuccessfullyselected:'قالب شما با موفقیت انتخاب شد',
            Presstheuploadbuttonafterselectingthefile:'کاربر گرامی . لطفا بعد از انتخاب فایل دکمه اپلود را بزنید',
            Contentgrouphasbeensuccessfullyedited:'گروه محتوای شما با موفقیت ویرایش  شد',
            Contentgrouphasbeensuccessfullycreated:'گروه محتوای شما با موفقیت ساخته شد',
            Grouphasbeensuccessfullyedited:'گروه  شما با موفقیت ویرایش شد',
            Successfullyedited:'با موفقیت ویرایش شد',
            Contentbeensuccessfullyedited:'محتوای شما با موفقیت ویرایش شد',
            Contenthasbeensuccessfullyadded:'محتوای شما با موفقیت اضافه شد',
            Contentgrouphasbeensuccessfullysubmitted:'گروه محتوای شما با موفقیت ارسال شد',
            FillinthearticletitleorURL:'لطفا عنوان مطلب یا آدرس مطلب را پر کنید',
            Selectyourarticlegroup:'کاربر گرامی لطفا گروه مطلب خود را انتخاب کنید',
            Subcategoryinsertion:'درج زیرشاخه',
            Grouphasbeensuccessfullyadded:'گروه شما با موفقیت اضافه شد',
            Reminderhasbeensuccessfullydeleted:'یادآور شما با موفقیت حذف شد',
            Reminderhasbeensuccessfullyrecorded:'یادآور شما با موفقیت ثبت شد',
            Reminderhasbeensuccessfullyedited:'یادآور شما با موفقیت ویرایش  شد',
            Chooseyourdesiredroles:'نقش های مورد نظر خود را انتخاب کنید',
            Permissionsbeensuccessfully:'دسترسی های شما با موفقیت ثبت شد',
            Rolehasbeensuccessfullydeleted:'نقش با موفقیت حذف شد',
            Roleshasbeensuccessfullydeleted:'نقش ها با موفقیت حذف شد',
            Rolehasbeensuccessfullyregistered:'نقش با موفقیت ثبت شد',
            Rolehasbeensuccessfullyedited:'نقش  با موفقیت ویرایش شد',
            Editingwassuccessfullydone:'ویرایش با موفقیت انجام شد',
            Grouphasbeensuccessfullydeleted:'گروه شما با موفقیت حذف شد',
            Usergrouphasbeensuccessfullycreated:'گروه کاربر شما با موفقیت ساخته شد',
            UserGrouphasbeensuccessfullyedited:'گروه کاربر شما با موفقیت ویرایش شد',
            Fileshavebeensuccessfullydeleted:'فایل ها با موفقیت حذف شدند',
            Thetitleismandatory:'کاربر گرامی عنوان الزامیست',
            Communicationaddressismandatory:'آدرس ارتباطات الزامیست',
            Selectdefaultbank:'لطفا یک بانک را پیش فرض انتخاب کنید',
            Choosethebankname:'لطفا نام بانک را انتخاب کنید',
            Banknamehasbeensuccessfullyregistered:'نام بانک شما با موفقیت  ثبت شد',
            Pleaseselectbrandname:'لطفا نام برند را انتخاب کنید',
            Pleasechoosecompanyname:'لطفا نام شرکت را انتخاب کنید',
            Iran:'ایران',
            Informationhassuccessfully:'اطلاعات با موفقیت ثبت شد',
            Specifyusertype:'لطفا نوع کاربری را مشخص کنید',
            Referralcodenotvalid:'کد معرف شما معتبر نمیباشد',
            Referralcodemustnumericalvalue:'کد معرف باید عددی باشد',
            Thereferralcodeisempty:'کد معرف خالی میباشد',
            Pleaseinformationfirsttab:'کاربر گرامی لطفا اطلاعات تب اول را وارد کنید',
            contenttext:'مطالب',
            Desireddomain:'دامین مورد نظر',
            TimeRange:'محدوده زمانی',
            Database:'Database',
            MainPrice:'قیمت اصلی',
            DiscountedPrice:'قیمت تخفیف خورده',
            Hosting:'هاستینگ',
            AdminPanelServer:'AdminPanelServer',
            AdminPanelServerIp:'Ip-AdminPanelServer',
            Sectionselection:'انتخاب بخش',
            BandWidthUsage:'BandWidth Usage',
            HHDUsage:'HHD Usage',
            SSDUsage:'SSD Usage',
            RAMUsage:'RAM Usage',
            CPUUsage:'CPU Usage',
            DiskUsage:'Disk Usage',
            NetworkTraffic:'Network Traffic',
            UpTime:'UpTime',
            MemoryUsage:'Memory Usage',
            Capacitydepletion:'اتمام ظرفیت',
            DedicatedServer:'سرور اختصاصی',
            WebServer:'Web Server',
            Havingthecapacity:'دارای ظرفیت',
            Serverhasbeensuccessfullydeleted:'سرور شما با موفقیت حذف شد',
            SuccessfullyDeleted:'با موفقیت حذف شد',
            IPServerSITE:'IPServerSITE',
            SeverSITE:'Sever SITE',
            IPServerHOST:'IP-Server HOST',
            ServerHOST:'Server HOST',
            ServerSQL:'ServerSQL',
            IPServerSQL:'IP-Server SQL',
            ConnectionSQL:'Connection SQL',
            NotificationBox:'صندوق اعلانات',
            CustomerClub:'باشگاه مشتریان',
            SpecialUser:'کاربر ویژه',
            GoldUser:'کاربر طلایی',
            ProfileCompletion:'تکمیل پروفایل',
            RegularUser:'کاربر عادی',
            UserLevel:'سطح کاربری',
            DateofBirth:'تاریخ تولد',
            ChangePhoto:'تغییر عکس',
            PersonalDetails:'جزئیات شخصی',
            DocumentsandRecords:'اسناد و مدارک',
            Favoriteproducts:'محصولات مورد علاقه',
            Salesbasket:'سبد فروش',
            Bill:'صورتحساب',
            Bills:'صورتحساب ها',
            ViewClub:'مشاهده باشگاه',
            IncreaseBalance:'افزایش موجودی',
            InvoiceDetail:'ریز فاکتور',
            Unpaid:'پرداخت نشده',
            Paid:'پرداخت شده',
            Enteravaliddomainname:'نام دامین را معتبر وارد نمایید',
            Pleasechooseadefaultdomain:'یک دامین را پیش فرض انتخاب کنید',
            Dearuserpleaseenterthedomainname:'کاربر گرامی لطفا نام دامین را وارد نمایید',
            Userwassuccessfullyadded:'کاربر با موفقت اضافه شد',
            SelectPackageTitle:'لورم',
            Dearuserpleaseselectapackage:'کاربر گرامی لطفا پکیج انتخاب نمایید',
            DearuserpleaseselectTemplate:'کاربر گرامی لطفا قالب انتخاب کنید',
            Shortcodehasbeencreated:'کد کوتاه ایجاد شد',
            Filehasbeensuccessfullyedited:'فایل شما با موفقیت ویرایش شد',
            PreviousversionRevertedsuccessfully:'بازگشت به نسخه قبل انجام شد',
            Filehasbeensuccessfullyuploaded:'فایل  با موفقیت آپلود شد',
            Pagehasbeensuccessfullydeleted:'پیج شما با موفقیت حذف شد',
            Uploaddate:'تاریخ آپلود',
            Identifier:'شناسه',
            NewDocument:'ثبت داکیونت جدید',
            WatchDocument:'مشاهده داکیومنت های ثبت شده',
            HasPrize:'جایزه دار',
            NoPrize:'بدون جایزه',
            IsWholeSale:'عمده',
            IsRetail:'تکی',
            Pagehasbeensuccessfullyregistered:'صفحه شما با موفقیت ثبت شد',
            Pagehasbeensuccessfullyedited:'صفحه شما با موفقیت ویرایش شد',
            Templatehasbeensuccessfullydeleted:'قالب شما با موفقیت حذف شد',
            Templatehasbeensuccessfullyregistered:'قالب شما با موفقیت ثبت شد',
            Templatehasbeensuccessfullyedited:'قالب شما با موفقیت ویرایش شد',
            GenerateConnectionString:'Generate Connection String',
            AzureActiveDirectoryPassword:'Azure Active Directory-OtpAuthentication',
            SQLServerAuthentication:'SQL Server Authentication',
            ConnectionTimeout:'Connection Timeout',
            Port:'پورت',
            PasswordEn:'Password',
            ApplicationName:'Application Name',
            Instance:'Instance',
            UserIDEn:'User ID',
            DataSourceEn:'Data Source',
            BasicSettings:'Basic Settings',
            Reset:'Reset',
            GeneratedConnectionString:'Generated Connection String',
            CPU:'CPU',
            BandWidth:'BandWidth',
            SSD:'SSD',
            HDD:'HDD',
            RAM:'RAM',
            Capacity:'ظرفیت',
            UserCapacity:'ظرفیت کاربر',
            Yourpackagehasdeleted:'پکیج شما با موفقیت حذف شد',
            Yourpackagehasregistered:'پکیج شما با موفقیت ثبت شد',
            Yourpackagesuccessfullyedited:'پکیج شما با موفقیت ویرایش  شد',
            Yourserverhasbeensuccessfullyregistered:'سرور شما با موفقیت ثبت  شد',
            Yourserverhasbeensuccessfullyedited:'سرور شما با موفقیت ویرایش  شد',
            PleaseenterIPformat:'لطفا فرمت آی پی صحیح وارد کنید',
            Pleaseselectprovince:'لفطا استان را انتخاب کنید',
            Pleaseselectpermissions:'دسترسی های مورد نظر خود را انتخاب کنید',
            EmailSettings:'تنظیمات ایمیل',
            UploadGuaranteeCodes:'آپلود کدهای گارانتی',
            DownloadSampleFile:'دانلود فال نمونه',
            UserRegistration:'ثبت کاربران',
            Custom:'سفارشی',
            ReportTitleFilter:'عنوان گزارش (فیلتر)',
            InputData:'دیتا ورودی',
            ExternalForm:'برون سازمانی',
            InternalForm:'درون سازمانی',
            FormName:'نام فرم',
            RecordCount:'تعداد رکورد',
            ExternalFrame:'فریم خارجی',
            FormAddress:'آدرس فریم',
            SelectReport:'انتخاب گزارش',
            PositionCategory:'جایگاه(دسته)',
            Grouped:'گروهی',
            FormSelection:'انتخاب فرم',
            GroupParameter:'پارامتر گروه',
            ValueParameter:'پارامتر مقدار',
            TitleParameter:'پارامتر عنوان',
            DisplayName:'نام نمایشی',
            DefaultValue:'مقدار پیش فرض',
            SourceTitle:'عنوان منبع',
            TypePerson:'نوع شخص',
            Person:'شخص',
            Viewtheform:'مشاهده فرم',
            CopyPageURL:'کپی مسیر صفحه جاری',
            ReturntoHomePage:'برگشت به صفحه اصلی',
            ResetSearch:'پیشفرض جستجو',
            ApplySearch:'اعمال جستجو',
            AddNewBanktoList:'افزودن بانک جدید',
            SendUserDataandDocuments:'ارسال اطلاعات و مدارک کاربران',
            UserEventsRegistration:'ثبت رویدادهای کاربران',
            UserContactDataRegistration:'ثبت راه های ارتباطی کاربران',
            UserBankDataRegistration:'ثبت اطلاعات بانکی کاربران',
            CompanyUserDataRegistration:'ثبت اطلاعات شرکت کاربران',
            CompleteDataRegistration:'ثبت اطلاعات تکمیلی کاربران',
            QuickUserRegistration:'ثبت سریع کاربران',
            DisapproveSelectedComments:'عدم تائید کامنت های انتخابی',
            ApproveSelectedComments:'تائید کامنت های انتخابی',
            AddComment:'افزودن کامنت',
            UserInteractions:'تعاملات کاربر',
            DeleteUser:'حذف کاربر',
            EditUser:'ویرایش کاربر',
            CloseSubcategories:'بستن زیر مجوعه ها',
            ViewSubcategories:'مشاهده زیر مجوعه ها',
            RunCode:'اجراء کد',
            RollbackPreviousVersion:'بازگشت به نسخه قبل',
            DownloadTemplate:'دانلود قالب',
            UploadTemplate:'آپلود قالب',
            EventRegistration:'ثبت رویداد',
            TodaysWithdrawals:'برداشت های امروز',
            DestinationAddress:'آدرس مقصد',
            TodaysDeposits:'واریزهای امروزی',
            PaymentMethod:'شیوه پرداخت',
            ToWallet:'به کیف پول',
            BankAccount:'حساب بانکی',
            Remaining:'موجودی',
            Withdrawal:'برداشت',
            Deposit:'واریز',
            Searchformenu:'جستجو در منو',
            New:'جدید',
            ExportWord:'خروجی WORD',
            TextOutput:'خروجی متن',
            ExportCsv:'خروجی CSV',
            ExportExcel:'خروجی Excel',
            ExportPDF:'خروجی PDF',
            Returnprevious:'بازگشت به صفحه قبل',
            Viewthetrainingvideo:'مشاهده ویدیو آموزشی',
            SupportChannelID:'آی دی کانال پشتیبان',
            ChannelID:'آی دی کانال',
            SettingtelegramTitle:'توجه:ابتدا ربات آرین را در کانال خود ادمین کنید(ariandeveloperbot@)',
            SettinginstagramTitleexample:'برای مثال :کد محصول=*',
            Settingdigitalmarketingexample:'ارسال پیام هوشمند در هنگام عضویت',
            SettinginstagramTitleRequest:'کد خرید یا درخواست خرید',
            SettinginstagramTitle:'ارسال پیام هوشمند در هنگام عضویت',
            SettingdigitalmarketingTitle:'با تعیین یک عبارت کاربران درخواست کننده را داشته باشید',
            ReceiveTemplate:'دریافت الگو',
            BackupVersion:'نسخه پشتیبان',
            Payment:'پرداخت',
            Receive:'دریافت',
            ThisWeek:'این هفته',
            ThisMonth:'این ماه',
            TransactionCode:'کد تراکنش',
            PaymentDetails:'جزئیات پرداخت',
            WithdrawalConfirmation:'تائید برداشت',
            TransactionAmount:'مبلغ تراکنش',
            TransactionType:'نوع تراکنش',
            PleaseSelectBank:'لطفا بانک انتخاب کنید',
            TransactionNumber:'شماره تراکنش',
            AccountType:'نوع حساب',
            Completed:'انجام شده',
            AwaitingConfirmation:'در انتظار تایید',
            NumberofCover:'تعداد کاور',
            NumberofPages:'تعداد صفحات',
            Whatsitesdoyoulike:'چه سایت هایی را می پسندید',
            ThirdColor:'رنگ سوم',
            SecondaryColor:'رنگ دوم',
            MainColor:'رنگ اصلی',
            NumberofLocations:'تعداد لوکیشن',
            AverageOutputTime:'میانگین زمان خروجی',
            NumberofClips:'تعداد کلیپ',
            OutputFileTime:'زمان فایل خروجی',
            RawFileTime:'زمان فایل خام',
            TypeofPhotography:'نوع عکاسی',
            LanguageRequest:'درخواست زبان',
            NumberofUniqueRandomNumbers:'تعداد شماره اختصاصی/رندوم',
            Paytitledisceriotion:'در این قسمت ما به شما امکانات میدهیم',
            FullPayment:'پرداخت کل',
            Numbers:'شماره ها',
            AdvancePayment:'پیش پرداخت',
            TimeInterval:'بازه زمانی',
            Loading:'درحال بارگیری...',
            ConnectedOperatingSystems:'سیستم عامل های متصل',
            SixMonthStatistics:'آمار شش ماهانه',
            QuarterlyStatistics:'آمار سه ماهانه',
            MonthlyStatistics:'آمار ماهانه',
            ReceptionCashBox:'صندوق دریافت وجه',
            Reload:'بارگذاری',
            GeneralReport:'گزارش کلی',
            CreateOperator:'ایجاد اپراتور',
            VariableName:'نام متغییر',
            StaticData:'داده های ثابت',
            ReturnPageAddress:'آدرس صفحه بازگشت',
            APIAddress:'آدرس API',
            OperatorName:'نام اپراتور',
            Wallet:'کیف پول',
            BaseTariff:'تعرفه پایه',
            Tariffcreation:'ایجاد تعرفه',
            WithdrawalRequest:'درخواست برداشت وجه',
            OnlineGateways:'درگاه های اینترنتی',
            Gateways:'درگاه ها',
            SelectedProgram:'برنامه انتخاب شده',
            OtherServices:'سایر خدمات',
            PleaseSelectNotificationMethod:'نحوه اطلاع رسانی را انتخاب کنید',
            PleaseSelectDateTime:'تاریخ و زمان را انتخاب کنید',
            OurServices:'خدمات ما',
            MediaServices:'خدمات مدیا',
            Resources:'منابع',
            SmartManagementServices:'خدمات مدیریت هوشمند',
            Modules:'ماژول ',
            UptoDateAccesstoOurServices:'دسترسی فوری و به روز به خدمات ما',
            ChooseYourPrograms:'برنامه های خود را انتخاب کنید',
            RequestforBankGateway:'در خواست درگاه بانکی',
            RequestforBankGatewayTitle:'اخذ درگاه بانکی شما',
            PleaseEnterSlug:'لطفا آدرس صفحه را وارد کنید',
            SymbolRequest:'درخواست نماد',
            SymbolRequesttitle:'نماد اعتماد،ساماندهی یا جواز اینترنتی',
            Consultation:'مشاوره',
            ConsultationTitle:'طراحی لوگوی انحصاری شما',
            PostDesign:'طراحی پست',
            PostDesignTitle:'طراحی انواع پست اینستاگرام/تلگرام',
            BillboardDesign:'طراحی بیلبورد',
            BillboardDesignTitle:'طراحی انواع بیلبردهای شهری',
            WebsiteBannerDesign:'طراحی بنر سایت',
            WebsiteBannerDesignTitle:' طراحی بنر صفحه اول سایت',
            DesigningBanners:'طراحی بنر',
            DesigningFestiveBannerstitle:' طراحی برای مناسبت های مختلف',
            OrderingCatalog:'سفارش کاتالوگ',
            OrderingCatalogDesignTitle:'ساخت کاتالوگ دیجیتال محصولات شما',
            OrderingLogoDesign:'سفارش طراحی لوگو',
            OrderingLogoDesignTitle:'ساخت لوگوی انحصاری شما',
            CustomTemplateDesign:'طراحی قالب سفارشی',
            CustomTemplateDesignTitle:'طراحی وساخت قالب دلخواه شما',
            VirtualTour:'تور مجازی',
            Reason:'دلیل',
            VirtualTourTitle:'تورمجازی °360 از لوکیشن شما',
            TelevisionCommercial:'تیزر تلویزیونی',
            TelevisionCommercialTitle:'ساخت انواع تیزرهای تلویزیونی',
            Clip:'کلیپ',
            ClipTitle:'ساخت انواع کلیپ تبلیغاتی',
            Editing:'تدوین',
            EditingTitle:'تدوین فیلم های آرشیوی موجود شما',
            ProductPhotography:'عکاسی محصول',
            ProductPhotographyTitle:'عکاسی اختصاصی از محصولات شما',
            AddLanguage:'اضافه سازی زبان',
            AddLanguageTitle:'ایجاد زبان جدید به وبسایت',
            SMSPanelRecharge:'شارژ پنل پیامک',
            PanelRecharge:'شارژ پنل',
            GatewayType:'نوع درگاه',
            SMSPanelRechargeTitle:'خطهای خود را به صورت مستقیم شارژ کنید',
            PurchaseSMSLine:'خرید خط پیامک',
            PurchaseSMSLineTitle:'خط دلخواه سامانه خود را انتخاب کنید',
            IncreasingEmailServerSpace:'افزایش فضای میل سرور',
            IncreasingEmailServerSpaceTitle:'خرید فضای اضافه برای میل های شما',
            YourWebsiteSpace:'فضای وبسایت شما',
            YourWebsiteSpaceTitle:'افزایش میزان هاستینگ شما',
            BrandRegistration:'ثبت برند',
            BrandRegistrationTitle:'در سامانه ثبت مالکیت های معنوی',
            SocialMediaManagement:'مدیریت شبکه های اجتماعی',
            SocialMediaManagementTitle:'مدیریت یکساله شبکه های اجتماعی شما',
            WebsiteManagement:'مدیریت سایت',
            WebsiteManagementTitle:'مدیریت یکساله سیستم شما',
            GmailAddress:'آدرس جیمیل',
            BulkEmail:'ایمیل انبوه',
            SecurityInformation:'اطلاعات امنیتی',
            CreateBankGateway:'ایجاد درگاه بانک',
            Pound:'پوند',
            CurrentPassword:'پسورد جاری',
            Euro:'یورو',
            Dollar:'دلار',
            Yuan:'یوان',
            Staircase:'پلکانی',
            SalesAmountinRange:'میزان فروش در بازه',
            Personal:'شخصی',
            UserGroup:'گروه کاربری',
            TypeofLaw:'نوع قانون',
            Duties:'عوارض',
            TaxTitle:'مالیات بر ارزش افزوده(جهت اعمال مالیات)',
            ServiceAddress:'آدرس سرویس',
            SetAmount:'تعیین مبلغ',
            Fixed:'ثابت',
            TypeofDispatch:'نوع ارسال',
            DispatchManagement:'مدیریت ارسال',
            CodeText:'متن کد',
            LandingPage:'صفحه هدف',
            ChooseTemplate:'انتخاب الگو',
            DataType:'نوع دیتا',
            CreateSMS:'ایجاد پیامک',
            AvailableTags:'برچسب های موجود',
            CustomStructure:'ساختار دلخواه',
            PostName:'نام نوشته',
            Numeric:'عددی',
            CheckBox:'چک باکس',
            MonthandName:'ماه و نام',
            DayandName:'روز و نام',
            FileSelection:'انتخاب فایل',
            DownloadLink:'لینک دانلود',
            FileTitle:'عنوان فایل',
            Backup:'بکاپ',
            SecurityEmailTitle:' ایمیلی حاوی کد ورود دو مرحله ای به آدرس ایمیل ارسال خواهد شد.',
            PhoneCallorTextMessageTitle:'کد ورود دو مرحله ای از طریق تماس تلفنی یا پیامک به  شماره:01321321452 دریافت خواهید کرد',
            PhoneCallorTextMessage:'تماس تلفنی یا ارسال پیامک',
            AddingSecurityKey:'اضافه کردن SecurytKey',
            SecurityKeyTitle:'به کمک کلیدامنیتی میتوانید دسترسی به حساب را امن تر کنید.برای این  کار میتوانید از کلید های فیزیکی (مثل Yubikey) یا از گوشی موبایل تان برای تنظیم کلید استفاده کنید.',
            SecurityKeyTwo:'برای فعالسازی و ورود دو مرحله ای  تنظیمات آن را انجام دهید ',
            InitialSetup:'تنظیم اولیه',
            AuthenticatiorTitleTwo:'از برنامه های authenticatior برای دریافت کد یکبار مصرف استفاده کنید.',
            AuthenticatiorTitle:'برای فعالسازی و ورود دو مرحله ای یک بار تنظیمات آن را انجام دهید',
            StaticIPTitle:'با تنظیم این قسمت مدیریت وبسایت تنها از آی پی وارد شده قابل دسترسی می باشد.',
            StaticIP:'آی پی استاتیک',
            ChoosePassword:'انتخاب پسورد',
            PasswordType:'نوع پسورد',
            ConfirmPassword:'تکرار پسورد',
            NewPassword:'پسورد جدید',
            OldPassword:'پسورد قدیمی',
            ChangePassword:'تغییر رمز عبور',
            AddressLinkIDNumber:'آدرس،شماره،لینک،آی دی',
            CommunicationMethod:'طریقه ارتباط',
            ExternalLink:'لینک ارجاء',
            BannerTitle:'عنوان بنر',
            sliderSettingTitle:'لطفا بسته به قالب خود یک عکس متناسب استفاده کنید.',
            Gender:'جنسیت',
            ProfilePicture:'تصویر پروفایل',
            DefaultArticleImage:'تصویر پیش فرض مطالب',
            HeaderLogo:'لوگوی بالای سایت',
            FooterLogo:'لوگوی پایین سایت',
            SiteIcon:'آیکون سایت',
            Thefileisduplicated:'فایل تکراری می باشد',
            FiletoPath:'فایل به مسیر',
            RenameFile:'تغییر نام فایل',
            ReplaceFile:'جابگزین فایل',
            DuplicateFileTitle:'کاربر گرامی فایل انتخابی شما تکراری است. لطفا یکی از گزینه های زیر را انتخاب کنید',
            DuplicateFile:'فایل تکراری',
            FolderName:'نام فولدر',
            Chooseanameforyourfolder:' یک نام برای فولدر خود انتخاب کنید',
            LastModifiedDate:'تاریخ آخرین تغییرات',
            FileDetails:'جزئیات فایل',
            FileSize:'اندازه فایل',
            Detailsoftheselectedfolder:'جزئیات فولدر انتخابی',
            Noinformationfound:'اطلاعاتی یافت نشد ...',
            CopyAddress:'کپی آدرس',
            Yourtexthasbeencopied:'متن شما کپی شد',
            Video:'ویدیو',
            Videos:'ویدیوها',
            AllItems:'همه موارد',
            CreateFolder:'ایجاد فولدر',
            Paste:'جایگذاری',
            CreationDate:'تاریخ ایجاد',
            NewDepartment:'ایجاد دپارتمان',
            DepartmentTitle:'عنوان دپارتمان',
            Urgent:'ضروری',
            Normal:'عادی',
            TariffPrice:'قیمت تعرفه',
            TicketDepartment:'دپارتمان تیکت',
            TicketDate:'تاریخ تیکت',
            TicketSubject:'موضوع تیکت',
            Subject:'موضوع',
            TypeofServices:'نوع سرویس',
            HotelRestaurant:'هتل،رستوران',
            Financial:'مالی',
            Content:'محتوا',
            OnlineTicketTitle:'ابتدا نوع تیکت را انتخاب کنید',
            OnlineTicketdepartmentTitle:'ابتدا دپارتمان مورد نظر را انتخاب کنید',
            Call:'تماس',
            UnderReview:'در حال بررسی',
            websiteSelection:'انتخاب وبسایت',
            Answered:'پاسخ داده شده',
            TicketList:'لیست تیکت ها',
            Gateway:'درگاه اینترنتی',
            Ticket:'تیکت',
            ViewConversation:'مشاهده گفتگو',
            TicketTitle:'عنوان تیکت',
            TicketNumber:'شماره تیکت',
            Friends:'دوستان',
            NumberofComments:'تعداد کامنت ها',
            AverageVisitDuration:'متوسط میزان بازدید',
            NewVisitors:'بازدید کنندگان جدید',
            TotalVisitors:'کل بازدید کننده ها',
            OverallStatistics:'آمار کلی',
            ConsumedVolume:'حجم مصرفی',
            DomainType:'نوع دامین',
            AccountSummary:'خلاصه حساب',
            AnnualReport:'گزارش سالانه',
            SixMonthReport:'گزارش شش ماهه',
            Quarterlyreport:'گزارش فصلی',
            TrafficConsumption:'ترافیک مصرفی',
            DailyVisits:'بازدید روزانه',
            MonthlyReport:'گزارش ماهانه',
            English:'انگلیسی',
            Persian:'فارسی',
            Temporary:'موقت',
            Permanent:'دائمی',
            SearchByAddress:'براساس(آدرس)',
            SearchByDepartmentTitle:'براساس(عنوان دپارتمان)',
            NewLink:'لینک جدید',
            OriginalLink:'لینک اولیه',
            TitleLink:'نوع لینک',
            PreviousLink:'لینک قبلی',
            UserID:'شماره کاربری',
            SearchByNumber:'براساس (شماره)',
            SendingTime:'زمان ارسال',
            Guidance:'هدایت',
            NumberofCharacters:'تعداد حروف',
            NumberofSMS:'تعداد پیامک',
            SendTo:'ارسال به',
            Configurator:'تنظیم کننده',
            EnteringNumbers:'وارد کردن شماره ها',
            SMSNote2:'نکته 2: در قسمت لیست شماره های ارسالی هر شماره باید با اینتر از هم جدا شود',
            SMSNote1:'نکته 1: برای ارسال انبوه پیامک,ابتدا یک پیامک به گوشی خود ارسال کنید در غیر اینصورت مسئولیت ارسال پیامک با خود شماست',
            SMSCharge:'شارژ پیامک',
            SystemNumber:'شماره سامانه',
            hasbeenset:'تنظیم شده است',
            Yourregistrationfor:'ثبت شما برای',
            Youcreatedamessage:'یک پیغام ایجاد کردید',
            Youonthedate:'شما در تاریخ',
            Month:'ماه',
            Week:'هفته',
            MediaType:'نوع رسانه',
            SearchByText:'براساس (متن)',
            TextType:'نوع متن',
            SendingDate:'تاریخ ارسال',
            MessageText:'متن پیام',
            LinkedInProfile:'پروفایل لینکدین',
            LinkedInProfileTitle:'نمایه ای که تجربه حرفه ای یک فرد را به نمایش می گذارد',
            FacebookGroup:'گروه فیسبوک',
            FaceBookGroupTitle:'انجمنی که کاربران فیسبوک به عنوان عضو به آن می پیوندند',
            FaceBookPageTitle:'یک نمایه عمومی که کاربران فیسبوک آن را "لایک" می کنند',
            FacebookPage:'پیج فیسبوک',
            Twitter:'توییتر',
            PageorProfile:'پیج یا پروفایل',
            LinkedIn:'لینکدین',
            LinkedInTitle:'نمایه شرکتی که کاربران لینکدین می توانند آن را دنبال کنند',
            LinkedInPage:'صفحه لینکدین',
            Channel:'کانال',
            YouTube:'یوتوب',
            Account:'حساب',
            ChooseYourAccountType:'نوع حساب خود را انتخاب کنید',
            AddAccount:'افزودن اکانت',
            PostArticle:'ارسال مطلب',
            Connect:'اتصال',
            PageorGroup:'حساب یا گروه',
            CreateEmail:'ایجاد ایمیل',
            SendtoIndividuals:'ارسال به اشخاص',
            SendtoGroup:'ارسال به گروه',
            Marked:'علامت گذاری شده',
            CreateCampaign:'ایجاد کمپین',
            EmailText:'متن ایمیل',
            OperationType:'نوع عملیات',
            DiscountTitle:'عنوان تخفیف',
            GuaranteeExtensionDuration:'مدت گارانتی/تمدید',
            ExtensionDate:'تاریخ تمدید',
            OperationDate:'تاریخ عملیات',
            GuaranteeStatus:'وضعیت گارانتی',
            ActivationDate:'تاریخ فعالسازی',
            NumberofGuaranteeCodes:'تعداد کد گارانتی',
            NumberofExtensions:'تعداد تمدید',
            ProductionSeries:'سری ساخت',
            SellerInformation:'مشخصات فروشنده',
            ProductionLine:'خط تولید',
            Created:'ایجاد شده',
            BuyerInformation:'مشخصات خریدار',
            Fee:'فی',
            commisionCurrency:'واحد پولی کارمزد',
            PackageDispatch:'ارسال بسته',
            PriceType:'پرداخت عمده',
            UserDescriptions:'توضیحات کاربر',
            Orderer:'سفارش دهنده',
            CreateFile:'ایجاد پرونده',
            FinalPrice:'قیمت نهایی',
            AvailableUnavailable:'موجود/عدم موجودی',
            Media:'رسانه ها',
            cut:'انتقال',
            copy:'کپی',
            stateGoogle:'نمایش در گوگل',
            ShareFile:'اشتراک فایل',
            Share:'اشتراک ',
            rename:'تغییر نام',
            extract:'استخراج',
            Compression:'فشرده سازی',
            compress:'فشرده',
            Simple:'ساده',
            SimpleId:' ID-ساده',
            SimpleSlug:'slug-ساده',
            GroupSlug:'گروه-slug',
            GroupGRid:'گروه-Grid',
            delete:'حذف',
            Receiver:'دریافت کننده',
            Deleted:'حذف شده',
            DeleteAll:'حذف همه',
            details:'جزئیات',
            fileEdit:'ویرایش فایل',
            TotalNumber:'تعداد کل',
            Count:'تعداد',
            VoteCount:'تعداد رای',
            DemoAddress:'آدرس دمو',
            DomainAddress:'آدرس دامین',
            Updating:'درحال بروزرسانی',
            CommunicationMedium:'رسانه اتباطی',
            isText:'متنی',
            isActive:'فعال',
            isNotActive:'غیر فعال',
            Mobile:'موبایل',
            From:'از',
            To:'تا',
            CreationDateAndTime:'تاریخ و زمان انتشار',
            search:'جستجو',
            Email:'ایمیل',
            Answer:'پاسخ',
            Answers:'پاسخ ها',
            ReturnBack:'برگشت به مرحله قبل',
            userName:'نام کاربری',
            register:'ثبت',
            BankRegistration:'ثبت بانک',
            RegisterAndNew:'ثبت و جدید',
            PreviousLevel:'مرحله قبل',
            Name:'نام',
            LastName:'فامیلی',
            list:'لیست',
            Report:'گزارش',
            Reports:'گزارشات',
            users:'کاربران',
            creation:'ایجاد',
            server:'سرور',
            servers:'سرورها',
            Package:'پکیج',
            Status:'وضعیت',
            ManagementResponse:'جواب مدیریت',
            DatabaseConnectionString:'رشته اتصال به دیتابیس',
            ConnectionStringIsRequired:'رشته اتصال الزامیست',
            ConnectionString:'رشته اتصال',
            personnel:'اشخاص',
            grouping:'گروه بندی',
            Homepage:'صفحه اصلی',
            WithForm:'دارای فرم',
            WithTemplate:'دارای قالب',
            WithTable:'دارای جدول',
            StandalonePage:'صفحه مستقل',
            ReleaseQueue:'صف انتشار',
            Published:'منتشر شده',
            Role:'نقش',
            Weaknesses:'نقاط ضعف',
            Strengths:'نقاط قوت',
            AuthorizedRole:'نقش مجاز',
            activities:'فعالیت ها',
            transaction:'تراکنش',
            transactions:'تراکنش ها',
            Date:'تاریخ',
            special:'ویژه',
            feature:'ویژگی',
            features:'ویژگی ها',
            viewing:'مشاهده',
            tasks:'وظایف',
            event:'رویداد',
            events:'رویدادها',
            menu:'منو',
            sending:'ارسال',
            information:'اطلاعات',
            insertion:'درج',
            comments:'نظرات',
            Inbox:'صندوق',
            confirmed:'تائید شده',
            notConfirmed:'تائید نشده',
            trash:'سطل زباله',
            template:'پوسته',
            ChangeOfOwnership:'تغییر مالکیت',
            CurrentOwner:'مالک فعلی',
            NewOwner:'مالک جدید',
            SiteStatus:'وضعیت سایت',
            MetaTag:'متاتگ',
            DataHighlight:'دیتاهایلایتر',
            Slider:'اسلایدر',
            selection:'انتخاب',
            edit:'ویرایش',
            Contents:'محتویات',
            short:'کوتاه',
            Survey:'نظرسنجی',
            Surveys:'نظر سنجی ها',
            Advertisement:'تبلیغات',
            NewAdvertisements:' ایجاد تبلیغات',
            ListAdvertisements:'لیست تبلیغات',
            Form:'فرم',
            Forms:'فرم ها',
            shop:'فروشگاه',
            internet:'اینترنتی',
            product:'محصول',
            Products:'محصولات',
            discount:'تخفیف',
            Guarantee:'گارانتی',
            activation:'فعالسازی',
            Chat:'چت',
            ShoppingCart:'سبد خرید',
            customers:'مشتریان',
            services:'خدمات',
            document:'سند',
            Documents:'اسناد',
            invoice:'فاکتور',
            automatic:'اتومات',
            accounting:'حسابداری',
            financial:'مالی',
            debtors:'بدهکاران',
            hour:'ساعت',
            minute:'دقیقه',
            second:'ثانیه',
            creditors:'بستانکاران',
            other:'سایر',
            Inventory:'انبار',
            letterWriting:'نامه نگاری',
            sendingALetter:'ارسال نامه',
            receivingBox:'صندوق دریافت',
            sendingBox:'صندوق ارسال',
            archive:'بایگانی',
            project:'پروژه',
            ReminderStatus:'وضعیت یادآور',
            projects:'پروژه ها',
            institution:'آموزشگاه',
            reservation:'رزرواسیون',
            room:'اتاق',
            isConfigured:'پیکربندی شده',
            rooms:'اتاق ها',
            Telegram:'تلگرام',
            Telegramgrouop:'گروه تلگرام',
            Telegramchannel: 'کانال تلگرام',
            Instagram:'اینستاگرام',
            WhatsApp:'واتساپ',
            sendingMessage:'ارسال پیام',
            smsSecretary:'منشی پیامک',
            support:'پشتیبانی',
            Department:'دپارتمان',
            DepartmentList:'لیست دپارتمان',
            onlineChat:'گفتگوی آنلاین',
            file:'فایل',
            manual:'دستی',
            IranCode:'ایران کد',
            signup:'ثبت نام',
            extension:'تمدید',
            Extended:'تمدید شده',
            Mr:'آقای',
            Miss:'خانم',
            MrMiss:'آقای/خانم',
            FirstNameAndLastName:'نام و نام خانوادگی',
            MobilePhone:'تلفن همراه',
            Save:'ذخیره',
            LandlinePhone:'شماره ثابت',
            PhoneNumber:'شماره تماس',
            ReferralCode:'کد معرف',
            Referrer:'معرف',
            NationalIdNumber:'کد ملی',
            landlineNumber:'شماره ثابت',
            Password:'پسورد',
            LanguageType:'نوع زبان',
            country:'کشور',
            CalendarType:'نوع تقویم',
            AddLogo:'افزودن لوگو',
            AddIcon:'افزودن آیکون',
            Icon:'آیکون',
            Addition:'اضافه',
            Tutorial:'آموزش',
            Add:'افزودن',
            userType:'نوع کاربری',
            fastRegistration:'ثبت سریع',
            Interactions:'تعاملات',
            user:'کاربر',
            UsersInformation:'اطلاعات شخصی',
            QuickRegistration:'ثبت نام سریع',
            CompanyData:'اطلاعات شرکت',
            BankData:'اطلاعات بانکی',
            companyName:'نام شرکت',
            ServerName:'نام سرور',
            companyNationalIdNumber:'کد ملی شرکت',
            communications:'ارتباطات',
            GeneralSettings:'تنظیمات عمومی',
            AdvancedSettings:'تنظیمات پیشرفته',
            SendingSettings:'تنظیمات ارسال',
            ServiceModule:'خدمات/ماژول',
            SupportType:'نوع پشتیبانی',
            UserSuggestion:'پیشنهاد کاربر',
            Read:'خوانده شده',
            UnRead:'خوانده نشده',
            CreateInvoice:'ایجاد فاکتور',
            Group:'گروه',
            fatherName:'نام پدر',
            occupation:'شغل',
            nationality:'ملیت',
            province:'استان',
            city:'شهر',
            education:'تحصیلات',
            postalCode:'کد پستی',
            Address:'آدرس',
            PageAddress:'آدرس صفحه',
            SlugIsNotValid:'آدرس صفحه معتبر نیست',
            Like:'پسند',
            Dislike:'ناپسند',
            Parameter:'پارامتر',
            Parameters:'پارامترها',
            Browser:'مرورگر',
            Browsers:'مرورگرها',
            TypeOfRequest:'نوع درخواست',
            additionalDetails:'توضیحات تکمیلی',
            WebsiteDescription:'توضیحات سایت',
            previous:'قبل',
            PaymentRegistration:'ثبت و پرداخت',
            Next:'بعد',
            registerAndNext:'ثبت و بعدی',
            name:'نام',
            brand:'برند',
            type:'نوع',
            MaximumWordDisplay:'حداکثر نمایش کلمات',
            ItwillBeDisplayedIfTheDisplayTypeIsSelected:'درصورت انتخاب نوع نمایش داده میشود',
            company:'شرکت',
            Supplier:'تامین کننده',
            Manufacturer:'تولید کننده',
            registrationNumber:'شماره ثبت',
            confirmation:'تائید',
            ConfirmAll:'تائید همه',
            Disapprove:'عدم تائید',
            bank:'بانک',
            banks:'بانک ها',
            BankAccountNumber:'شماره حساب',
            cardNumber:'شماره کارت',
            Passport:'پاسپورت',
            Sponsor:'اسپانسر',
            NationalIDCard:'کارت ملی',
            DriversLicense:'گواهینامه',
            Visa:'ویزا',
            ResidencePermit:'اقامت',
            Contract:'قرارداد',
            IdentificationCard:'کارت شناسایی',
            BirthCertificate:'شناسنامه',
            MarriageCertificate:'عقدنامه',
            OtherDocuments:'سایر مدارک',
            EducationalCertificate:'مدرک تحصیلی',
            InsurancePolicy:'بیمه نامه',
            ProductInsuranceValue:'ارزش بیمه محصول',
            BankID:'شماره شباء',
            default:'پیش فرض',
            row:'ردیف',
            listOfContactMethods:'لیست راه های ارتباطی',
            Comment:'کامنت',
            CommentText:'متن کامنت',
            Rating:'امتیاز',
            description:'توضیحات',
            Title:'عنوان',
            finalRegistration:'ثبت نهایی',
            membership:'عضویت',
            FromDate:'از تاریخ',
            ToDate:'تا تاریخ',
            operations:'عملیات',
            viewAll:'مشاهده همه',
            MonthToExpired:'یک ماه به انقضاء',
            OneMonthAgo:'یک ماه قبل',
            ThreeMonthsAgo:'سه ماه قبل',
            Suspended:'درحال تعلیق',
            StartOfFactorNumber:'شروع شماره فاکتور',
            Blocked:'مسدود',
            expired:'منقضی',
            hasStatistics:'دارای آمار',
            incompleteFile:'پرونده ناقص',
            phoneNumberVerification:'تایید شماره تماس',
            output:'خروجی',
            Close:'بستن' ,
            all:'همه',
            rank:'رتبه',
            PageRank:'رتبه صفحه',
            PleaseEnterNumbers:'لطفا شماره ها را وارد کنید',
            settings:'تنظیمات',
            Gallery:'گالری',
            Today:'امروز',
            Weight:'وزن',
            Yesterday:'دیروز',
            SevenDaysAgo:'هفت روز قبل',
            CurrentWeek:'هفته جاری',
            CurrentMonth:'ماه جاری',
            Important:'مهم',
            Contacts:'تماس ها',
            Explanation:'توضیح',
            Operator:'اپراتور',
            CompletionInformation:'تکمیل اطلاعات',
            WebsiteName:'نام سایت',
            RightToLeft:'راست به چپ',
            LeftToRight:'چپ به راست',
            RTL:'راست چین',
            Right:'راست',
            LTR:'چپ چین',
            Discounted:'تخفیف دار',
            TemplateImage:'تصویر پوسته',
            Image:'تصویر',
            ParentTheme:'تم والد',
            PageType:'نوع صفحه',
            OnlineEditor:'ادیتورآنلاین',
            Continue:'ادامه',
            HostingSpaceAmount:'میزان فضای هاستینگ',
            MailServerStorageSpace:'میزان فضای میل سرور',
            SelectServer:'انتخاب سرور',
            Return:'بازگشت',
            SignIn:'ورود',
            LogIn:'ورود',
            rememberMe:'مرا به خاطر بسپار',
            forgetPassword:'رمز عبور را فراموش کرده اید ؟ ',
            SignupConfirmtion:'با ثبت نامِ  با ما موافقت کنیدِ',
            PrivacyPolicy :' سیاست حفط حریم خصوصی',
            conditions :'شرایط و ضوابط ',
            privacyPolicyDescription:'',
            logoAltTitle:'harmony 2023',
            SignInTitle:' تمام حساب های تجارت الکترونیک خود را در یک مکان مدیریت کنید',
            Right_SideSignInTitle:'چند کلیک دیگر ',
            Right_SideSignInTitleTwo:'به حساب خود وارد شوید',
            PasswordConfirmtion:'تایید کلمه عبور',
            passwordRecovery:'بازیابی کلمه عبور',
            passwordRecieveMethod:'نوع دریافت رمز',
            OtpReceive:'دریافت کد',
            TimeOver:'زمان شما به پایان رسید',
            Resend:'ارسال مجدد',
            Enter_Your_Code:'کد دریافتی خود را وارد کنید',
            OTP:'کد یک بار مصرف',
            Enter_Your_Password:'کلمه عبور خود را وارد کنید',
            codeConfirmtion:'تایید کد',
            RepeatPassword:'تکرار کلمه عبور',
            SafePassword:'رمز عبور امن چیست ? ',
            TermsAcceptance:'با شرایط موافقم',
            methodOfIntroduction:'طریقه آشنایی',
            Dashboard:'میزکار',
            generalManager:'مدیر کل',
            CreateAPackage:'ایجاد پکیج',
            SelectPackage:'انتخاب پکیج',
            PackageName:'نام پکیج',
            domains:'دامین ها',
            Domain:'دامین',
            DomainOne:'دامین1',
            CreateAServer:'ایجاد سرور',
            websiteBuilder:'سایت ساز',
            generalTemplates:'پوسته های عمومی',
            WebsiteList:'لیست سایت ها',
            UserReports:'گزارش کاربران',
            ServerStatusReport:'گزارش وضعیت سرور',
            CRM:'مدیریت مشتریان',
            UsersRegistration:'ثبت اشخاص',
            CreatingRole:'ایجاد نقش',
            CustomerList:'لیست مشتریان',
            ListOfTransactions:'لیست تراکنش ها',
            SpecialDate:'تاریخ ویژه',
            ViewingTasks:'مشاهده وظایف',
            ViewingEvents:'مشاهده رویدادها',
            MenuManagement:'مدیریت منو',
            CMS:'ارسال اطلاعات',
            InsertingInformation:'درج اطلاعات',
            CreatingAFeature:'ایجاد ویژگی',
            ListOfInformation:'لیست اطلاعات',
            UserComments:'نظرات کاربران',
            UserComment:'نظر کاربر',
            DetailedComments:'ریز نظرات',
            ApprovedComments:'نظرات تایید شده',
            UnapprovedComments:'نظرات تایید نشده',
            PrintComments:'چاپ نظرات',
            QuestionTitle:'عنوان سوال',
            Question:'سوال',
            Template:'مدیریت قالب',
            TemplateSelection:'انتخاب قالب',
            Preview:'پیش نمایش',
            Category:'دسته بندی',
            SelectCustomer:'انتخاب مشتری',
            SelectWebsiteCustomers:'انتخاب مشتریان وبسایت',
            TemplateEditing:'ویرایش قالب',
            CreatingAShortCode:'ایجاد کد کوتاه',
            ShortCode:'کد کوتاه',
            ProductCode:'کد محصول',
            DocumentTypeIsRequired:'نوع داکیومنت الزامیست',
            DocumentGroupIsRequired:'گروه داکیومنت الزامیست',
            CreatingDiscountCode:'ایجاد کد تخفیف',
            DiscountCampaign:'کمپین تخفیف',
            CreatingASurvey:'ایجاد نظر سنجی',
            ListOfSurveys:'لیست نظر سنجی ها',
            NewAdvertising:'درج آگهی',
            SimilarInsertion:'درج مشابه',
            ListAdvertisings:'لیست آگهی ها',
            FormManagement:'مدیریت فرم',
            CreatingForm:'ایجاد فرم',
            FormWorkflow:'گردش کار فرم',
            OrganizeForms:'سازماندهی فرم ها',
            ListSourcesInformation:'لیست منبع اطلاعات',
            DataSource:'منبع اطلاعات',
            ListOfForms:'لیست فرم ها',
            Of:'از',
            ReportBuilder:'گزارش ساز',
            OnlineStore:'فروشگاه اینترنتی',
            NewProduct:'ثبت محصول',
            Discounts:'تخفیفات',
            Praise:'جایزه',
            NewGuaranteeCode:'ایجاد کد گارانتی',
            CustomersGuarantee:'گارانتی مشترکین',
            DiscountsAndPrizes:'تخفیفات و جایزه',
            ProductCategorization:'گروه بندی محصول',
            ListOfProducts:'لیست محصولات',
            ProductBarcode:'بارکد محصول',
            InventoryStockReport:'گزارش موجودی انبار',
            InventoryStock:'موجودی انبار',
            GuarantyReport:'گزارش گارانتی',
            CustomerReport:'گزارش مشتریان',
            NotEnoughWalletBalance:'موجودی کیف پول کافی نیست',
            SalesCollaboration:'همکاری در فروش',
            ServiceRegistration:'ثبت خدمات',
            ProductList:'لیست کالا',
            SalesCollaborationScript:'اسکریپت همکاری در فروش',
            ServiceList:'لیست خدمات',
            DocumentManagement:'مدیریت اسناد',
            DocumentRegistration:'ثبت سند',
            DocumentList:'لیست اسناد',
            InvoiceRegistration:'ثبت فاکتور',
            InvoiceList:'لیست فاکتور',
            AutomaticRegistration:'ثبت اتومات',
            AccountingManagement:'مدیریت حسابداری',
            FinancialReports:'گزارشات مالی',
            FinancialReport:'گزارش حسابداری',
            AccountReceivableReport:'گزارش بستانکاری',
            InventoryManagement:'مدیریت انبار',
            GoodsRegistration:'ثبت کالا',
            CorrespondenceManagement:'مدیریت نامه نگاری',
            ProjectManagement:'مدیریت پروژه',
            ProjectCreation:'ایجاد پروژه',
            ProjectReports:'گزارش پروژه ها',
            SchoolManagement:'مدیریت آموزشگاه',
            ClassCreation:'ایجاد کلاس',
            ListOfClasses:'لیست کلاس ها',
            CenterCreation:'ایجاد مرکز',
            ListOfCenters:'لیست مراکز',
            ListOfReservations:'لیست رزروها',
            ListOfRooms:'لیست اتاق ها',
            FileManagement:'مدیریت فایل',
            AttachmentFile:'فایل ضمیمه',
            UserCode:'کد کاربر',
            RegistrationDate:'تاریخ ثبت',
            RenewalDate:'تاریخ تمدید',
            ExpirationDate:'تاریخ انقضاء',
            AdditionalInformation:'اطلاعات تکمیلی',
            BrandName:'نام برند',
            ServerType:'نوع سرور',
            CompanyType:'نوع شرکت',
            DiscountType:'نوع تخفیف',
            BankName:'نام بانک',
            ListOfBanks:'لیست بانک ها',
            BankCreation:'ایجاد بانک',
            CommunicationType:'نوع ارتباط',
            SendingType:'نوع ارسال',
            TypeIndividualLegal:'نوع حقیقی/حقوقی',
            ContactAddress:'آدرس ارتباطات',
            EventManagement:'مدیریت رویدادها',
            EventDate:'تاریخ رویداد',
            ReminderDate:'تاریخ یادآور',
            StartDate:'تاریخ شروع',
            BankAccountCreation:'ایجاد حساب بانکی',
            EndDate:'تاریخ پایان',
            DocumentUpload:'آپلود اسناد و مدارک',
            MembershipSinceDate:'عضویت از تاریخ',
            MembershipUntilDate:'عضویت تا تاریخ',
            Individuals:'اشخاص حقیقی',
            LegalEntities:'اشخاص حقوقی',
            Public:'عمومی',
            Private:'خصوصی',
            RoleTitle:'عنوان نقش',
            SurveyTitle:'عنوان نظرسنجی',
            Elmah:'صفحه خطا',
            TagTitle:'عنوان تگ',
            WriteYourMetaGroup:'متا گروه خود را بنویسید',
            TotalVote:'کل آری',
            RelevantVote:'آری مربوطه',
            Agree:'موافق',
            Disagree:'مخالف',
            RoleWorkgroup:'گروه کاری نقش',
            AccessToInformation:'دسترسی به اطلاعات',
            ViewList:'مشاهده لیست',
            CommonFilters:'فیلتر های پرکاربرد',
            Access:'دسترسی',
            Accesses:'دسترسی ها',
            AuthorizedAccess:'دسترسی مجاز',
            GroupCreation:'ایجاد گروه',
            GroupEdit:'ویرایش گروه',
            FileUpload:'آپلود فایل',
            Upload:'آپلود',
            IP:'آی پی',
            ID:'آی دی',
            CloseAll:'بستن همه',
            Cancel:'انصراف',
            YesCancelit:'اره انصراف میدم',
            SureToCancel:'از انصراف اطمینان دارید ؟ ',
            Authorized:'مجاز',
            Unauthorized:'غیر مجاز',
            SpecialAccess:'دسترسی خاص',
            SixMonthsToExpiry:'شش ماه به انقضاء',
            OneYearToExpiry:'یک سال به انقضاء',
            Available:'موجود',
            Unavailable:'ناموجود',
            PurchaseAlert:'هشدار خرید',
            BestsellingProducts:'محصولات پرفروش',
            RelatedProducts:'محصولات مرتبط',
            DataRegistration:'ثبت اطلاعات',
            NoteTitle:'عنوان یادداشت',
            DateTime:'تاریخ/زمان',
            ReminderDataTime:'تاریخ/زمان یادآور',
            NotificationMethod:'نحوه اطلاع رسانی',
            NoteText:'متن یادداشت',
            SendingMethod:'نحوه ارسال',
            SelectSendingMethod:"نحوه ارسال را انتخاب کنید",
            CreateNote:'ایجاد یادداشت',
            Active:'فعال',
            Inactive:'غیر فعال',
            Cyclic:'دارای چرخه',
            Sent:'ارسال شده',
            NotSent:'ارسال نشده',
            FollowUpPriority:'اولویت پیگیری',
            DisplayPriority:'اولویت نمایش',
            PagePriority:'اولویت صفحه',
            Time:'زمان',
            SearchByTitle:'براساس(عنوان)',
            SearchByNameMessage:'براساس(نام-پیام)',
            SearchByBill:'براساس(صورحساب)',
            SearchByNameTemplate:'براساس(نام-قالب)',
            SearchByNameAdvertise:'براساس(نام-تبلیغ)',
            SearchByGatewayName:'براساس(نام درگاه)',
            BasedOnPrice:'براساس (قیمت)',
            BasedOnDiscount:'براساس (تخفیف)',
            ReactProject:'React Project',
            TailwindCSSProject:'TailwindCSS Project',
            BootstrapProject:'Bootstrap Project',
            ArticleTitle:'عنوان مطلب',
            EditArticleTitle:'ویراش عنوان مطلب',
            ArticleAddress:'آدرس مطلب',
            SaveEdit:'ثبت ویرایش',
            MapAddress:'آدرس نقشه',
            TextSummary:'خلاصه متن',
            SummaryExplanation:'خلاصه توضیح',
            FullText:'متن اصلی',
            Main:'اصلی',
            WindowTitle:'عنوان پنجره',
            Keywords:'کلمات کلیدی',
            VideoUrl:'آدرس فیلم',
            DocumentUrl:'آدرس سند',
            MetaTagDescriptions:'توضیحات متا تگ',
            Lead:'لید',
            RelatedContent:'مطالبت مرتبط',
            Conversations:'گفتگوها',
            TagName:'نام تگ',
            ProductName:'نام محصول',
            Note:'یادداشت',
            Notes:'یادداشت ها',
            Reminder:'یادآور',
            Reminders:'یادآورها',
            IsInSendingQueue:'در صف ارسال',
            Website:'وب سایت',
            Websites:'وب سایت ها',
            NameCompany:'نام/شرکت',
            TypeOfInformation:'نوع اطلاعات',
            Text:'متن',
            Price:'قیمت',
            DiscountAmount:'میزان تخفیف',
            DiscountDate:'تاریخ تخفیف',
            FileType:'نوع فایل',
            FileGroup:'گروه فایل',
            Location:'مکان قرارگیری',
            TemplateType:'نوع قالب',
            OnlineSupport:'پشتیبانی آنلاین',
            PrivetData :'اطلاعات کاربر',
            LicenseInformation :'اطلاعات لایسنس',
            LicenseDuration :'مدت لایسنس',
            ValidityPeriod :'مدت اعتبار',
            BuyATemplate :'خرید قالب',
            CommonCases :'موارد پرکاربرد',
            SearchByAccountingCode :'براساس (کد حسابداری)',
            BasedOnPackageName :'براساس (نام پکیج)',
            Origin :'مبدا',
            Destination :'مقصد',
            Commission :'پورسانت',
            PublicationDate :'تاریخ انتشار',
            ReleaseFor :'انتشار برای',
            Publishing :'انتشار',
            Length:'طول',
            Width :'عرض',
            Height :'ارتفاع',
            Author :'نگارنده ',
            DisplayDateForThePost :'تاریخ نمایشی پست',
            FeatureTable :'جدول ویژگی',
            Table :'جدول',
            Display :'نمایش',
            CreateFilter :'ایجاد فیلتر',
            SocialMediaSharing :'ارسال در شبکه های اجتماعی',
            page:'صفحه',
            DisplayStatus :'وضعیت نمایش',
            SendToAndroid :'ارسال به اندروید',
            GoogleMap :'نقشه گوگل',
            ShowResult :'نمایش نتیجه',
            VotingLimit :'محدودیت رای',
            FreeShipping :'ارسال رایگان',
            SalesBasedOnInventory :'فروش بر اساس موجودی',
            Minimum :'حداقل',
            Maximum :'حداکثر',
            TemplateTitle :'عنوان قالب',
            WebsiteDirection :'جهت سایت',
            TemplateFolderName :'نام فولدر قالب',
            PageFileName :'نام فایل صفحه',
            FileName :'نام فایل',
            File :'فایل',
            Direction :'جهت',
            LatitudeAndLongitude :'طول و عرض جغرافیایی',
            Internal :'داخلی',
            External :'خارجی',
            AuthorizedIPAddresses :'آی پی های مجاز',
            WebsiteTitle:'عنوان سایت',
            FilterTitle:'عنوان فیلتر',
            Part:'بخش',
            Field:'فیلد',
            Condition:'شرط',
            Value:'مقدار',
            Banner:'بنر',
            Photo:'عکس',
            PageCreation:'ساخت صفحه',
            Download:'دانلود',
            PageTitle:'عنوان صفحه',
            Recovery:'بازیابی',
            Run:'اجرا',
            CurrencyUnit:'واحد پول',
            ReminderList:'لیست یادآوری',
            groupPriceChange:'تغییر قیمت گروهی',
            UnitPrice:'قیمت تکی',
            WholesalePrice:'قیمت عمده',
            basicInformation:'اطلاعات پایه',
            introductionOfBankAccounts:'معرفی حسابهای بانکی',
            financialChapters:'سر فصل های مالی',
            interbankTransfer:'انتقال بین بانکی',
            registeredChecks:'چک های ثبت شده',
            checkCollection:'وصول چک',
            checkReminders:'یادآور چک ها',
            interbankTransactions:'تراکنش های بین بانکی',
            financialBalance:'تراز مالی',
            ProcedureCreation:'ایجاد دستورالعمل',
            rackConstruction:'ساخت رک',
            hotelReservation:'رزرو هتل',
            digitalMarketing:'دیجیتال مارکتینگ',
            Marketing:'مارکتینگ',
            SMS :'سامانه پیام کوتاه',
            creatingATemplate :'ایجاد الگو',
            addingATextMessageLine :'افزودن خط پیامک',
            SEO:'سئو',
            introductionToGoogle :'معرفی به گوگل',
            redirect:'ریدایرکت',
            Typeredirect:'نوع ریدایرکت',
            Newredirect:'افزودن ریدایرکت',
            Linkredirect:'لینک ریدایرکت',
            statisticsAndVisits:'آمار و بازدید',
            ViewStatistics:'مشاهده آمار',
            IPRestriction:'محدودیت آی پی',
            dropFilesHereOrClickToUpload:'فایل ها را اینجا رها کنید',
            UploadedFiles:'فایل های آپلود شده',
            Specifications:'مشخصات',
            QuickAccess:'دسترسی سریع',
            SearchByNameProductCode:'براساس(نام-کدمحصول)',
            SearchByNameGroup:'براساس(نام-گروه)',
            SearchByNameCode:'براساس(نام-کد)',
            SixMonthsToExpiration:'شش ماه به انقضاء',
            OneYeartoExpiration:'یک ماه به انقضاء',
            Draft:'پیش نویس',
            SizeAndDimensions:'سایز و اندازه',
            ChartManagement:'مدیریت چارت',
            ProductGroup:'گروه محصول',
            SubcategoryType:'نوع زیر مجموعه',
            ExampleColorOrSize:'مثال:رنگ یا اندازه',
            ExampleSizeXLOrColorCode:'مثال:اندازه xl یا کد رنگ',
            Monthly:'ماهیانه',
            ThreeMonths:'سه ماه',
            SixMonths:'شش ماه',
            OneYear:'یک سال',
            AddingAChart:'افزودن چارت',
            SuccessfulPayment:'پرداخت موفق',
            UnsuccessfulPayment:'پرداخت ناموفق',
            TodaySales:'فروش امروز',
            ViewInvoice:'مشاهده فاکتور',
            CustomerName:'نام مشتری',
            RequestStatus:'وضعیت درخواست',
            ShippingStatus:'وضعیت ارسال',
            PaymentType:'نوع پرداخت',
            BankSelection:'انتخاب بانک',
            OnlinePaymentAmount:'مبلغ پرداخت آنلاین',
            WalletDeduction:'کسر از کیف پول',
            DiscountCode:'کد تخفیف',
            ProductImage:'تصویر محصول',
            TotalAmount:'جمع کل',
            StoreName:'نام فروشگاه',
            NationalEconomicCode:'کد ملی /اقتصادی',
            AddProduct:'افزودن محصول',
            ShippingCost:'هزینه ارسال',
            Toman:'تومان',
            Rial:'ریال',
            TotalPurchaseAmount:'مبلغ کل خرید',
            PaidAmount:'مبلغ پرداختی',
            ShippingOrder:'دستور ارسال',
            ContactOrder:'دستور تماس',
            InventoryReceiptPrinting:'چاپ رسیدانبار',
            AccountingDocumentPrinting:'چاپ سند حسابداری',
            SerialCreation:'ایجاد سریال',
            RequiredSerialQuantity:'تعداد سریال موردنیاز',
            ProductCounts:'تعداد محصولات',
            SeriesGeneration:'سری ایجاد',
            Series:'سری',
            Calculation:'واحد محاسبه',
            SerialProduction:'ساخت سریال',
            ExcelDownload:'دانلود اکسل',
            DownloadText:'دانلود متن',
            Notification:'اطلاع رسانی',
            GuaranteeRegistration:'ثبت گارانتی',
            BuyersName:'نام خریدار',
            GuaranteeCode:'کد گارانتی',
            GuaranteePeriod:'مدت زمان گارانتی',
            PurchaseDate:'تاریخ خرید',
            History:'تاریخچه',
            BasedOnTheDate:'براساس (تاریخ)',
            SentToProductionLine:'ارسال به خط تولید',
            Sendtoproduction:'ارسال به تولید',
            Filter:'فیلتر',
            DiscountFor:'تخفیف برای',
            DiscountOn:'تخفیف بر روی',
            SpecifyDiscountCode:'تعیین کد تخفیف',
            UsageCount:'تعداد استفاده',
            MaximumDiscount:'حداکثر تخفیف',
            MinimumPurchase:'حداقل خرید',
            CampaignTitle:'عنوان کمپین',
            SetInterval:'تعیین بازه',
            Priority:'اولویت',
            TargetAudience:'جامعه هدف',
            Conditions:'شرط ها',
            StepIncrease:'گام افزایش',
            Unit:'واحد',
            Law:'قانون',
            CreateCommissionLaw:'ایجاد قانون پورسانت',
            CommissionFor:'پورسانت برای',
            TypeOfCommission:'نوع پورسانت',
            CreateAField:'ایجاد فیلد',
            Attributes:'خصوصیات',
            FormSettings:'تنظیمات فرم',
            Standard:'استاندارد',
            SingleLineText:'متن تک خط',
            Paragraph:'پاراگراف',
            Upgrade:'ارتقاء',
            Profile:'پروفایل',
            Tickets:'تیکت ها',
            Guide:'راهنما',
            Logout:'خروج از حساب',
            Images:'تصاویر',
            SocialMedia:'شبکه های اجتماعی',
            AdditionalScript:'اسکریپت اضافه',
            ShortcutKey:'کلید میانبر',
            Shortcut:'میانبر',
            SecurityAndPrivacy:'امنیت و حریم خصوصی',
            SupportAndRecovery:'پشتیبانی و بازیابی',
            TemporaryDataCleanup:'پاکسازی داده های موقت',
            DataCleanup:'پاکسازی داده',
            Routing:'مسیردهی',
            SmartMessaging:'پیامک هوشمند',
            ShippingMethods:'روش های ارسال مرسوله',
            Tax:'مالیات',
            BulkTransfer:'انتقالی گروهی',
            CreateRule:'ایجاد قانون',
            Highlighter:'هایلایتر',
            BankGateway:'درگاه بانک',
            StoreManagement:'مدیریت فروشگاه',
            CustomerManagement:'مدیریت مشتریان',
            GeographicRegion:'منطقه جغرافیایی',
            Language:'زبان',
            Orientation:'جهت نمایش',
            FontSize:'سایز فونت',
            ChooseColorTheme:'انتخاب تم رنگ',
            SelectFont:'انتخاب فونت',
            CalendarLanguage:'زبان تقویم',
            DisplayMode:'نحوه نمایش',
            EditorType:'نوع ادیتور',
            WatermarkText:'متن واتر مارک',
            AddFont:'افزودن فونت',
            FontFile:'فایل فونت',
            DefaultImages:'تصاویر پیشفرض',
            DefaultTemplate:'قالب پیشفرض',
            ValidIPs:'آی پی های مجاز',
            OwnerCompany:'مالک/شرکت',
            MailServerSpace:'فضای میل سرور',
            HostingSpace:'فضای هاستینگ',
            DataNoteFound:'اطلاعاتی یافت نشد',
            DisplayOrder:'ترتیب نمایش',
            SuccessfulPurchase:'خرید موفق',
            Amount:'مبلغ',
            TrackingCode:'کد رهگیری',
            TransactionDate:'تاریخ تراکنش',
            TransactionReference:'مرجع تراکنش',
            PaymentStatus:'وضعیت پرداخت',
            Sender:'فرستنده',
            Responsibility:'مسئولیت',
            Serial:'سریال',
            MenuTitle:'عنوان منو',
            GroupTitle:'عنوان گروه',
            VisitCount:'تعداد بازدید',
             //Author:'نویسنده',
            ApprovalDisapproval:'تایید/عدم تایید',
            TagContent:'محتوای تگ',
            LastEdit:'آخرین ویرایش',

            // TitleShortCode:'توجه :همکاران محترم این بخش جهت جلوگیری از تکرار کدهای مشترک در تمام صفحات سایت طراحی گردیده است',
            TitleShortCodeTwo:' برای ایجاد کد کوتاه از کلمات انگلیسی استفاده نمائید.',
            QuestionText:'متن سوال',
            NumberofParticipants:'تعداد شرکت کننده',
            AdvertisingFile:'فایل تبلیغات',
            GeographicLongitudeandLatitude:'طول عرض جغرافیایی',

        },
    },
}

export const AgGridPersion = {
    noRowsToShow: 'هیچ سطری برای نشان دادن نیست',
    loadingOoo: 'درحال بارگیری',
    selectedAll: 'همه انتخاب شده',
    columnDefs: 'ستون ها',
    sorting: 'مرتب سازی',
    applyFilter: 'اعمال فیلتر',
    equals: 'مساوی',
    notEqual: 'مساوی نباشد',
    lessThanOrEqual: 'کمتر یا مساوی',
    greaterThan: 'بزرگ تر از',
    greaterThanOrEqual: 'بزرگ تر از یا مساوی',
    inRange: 'در رنج',
    contains: 'شامل میشود',
    notContains: 'شامل نمیشود',
    startsWith: 'شروع میشود با',
    endsWith: 'تمام میشود با',
    selectAll: 'انتخاب همه',
    search: 'جستجو',
    blanks: 'خالی',
    AND:'و',
    OR:'یا',
    blank:'خالی',
    filter:'جستجو',
    notBlank:'خالی نباشد',
    noMatches: 'گزینه مشابهی یافت نشد',
    pagination:'صفحه بندی',
    page:'صفحه',
    of:'از',
    to:'به',

    //new


}

export const AgGridEnglish = {
    noRowsToShow: 'there is no row to show',
    loadingOoo: 'Loading',
    selectedAll: 'all selected',
    columnDefs: 'columns',
    sorting: 'sorting',
    applyFilter: 'Apply filter',
    equals: 'equals',
    notEqual: 'not equal',
    lessThanOrEqual: 'lessThanOrEqual',
    greaterThan: 'greeterThan',
    greaterThanOrEqual: 'greaterThanOrEqual',
    inRange: 'inRange',
    contains: 'contains',
    notContains: 'notContains',
    startsWith: 'startsWith',
    endsWith: 'endsWith',
    selectAll: 'selectAll',
    // searchOoo: 'search',
    blanks: 'blanks',
    AND:'AND',
    OR:'OR',
    blank:'blank',
    filter:'filter',
    notBlank:'notBlank',
    noMatches: 'noMatches',
    pagination:'pagination',
    page:'page',
    of:'of',
    to:'to'
}

