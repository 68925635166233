import React, {useEffect, useMemo, useRef, useState} from "react";
// import '../../../../Styles/Internal/setting.css'

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ListSearch} from "../../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../../Commans/Grid/GridTable";
import {Return} from "../../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {GET, POST, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {
  CreateDynamicProperties,
  GetInitialProperties,
  GetPaymentGatewaysData,
  handleConvertPaymentGateways, handleEditConvertPaymentGateways,
  handleGetDynamicProperties, validateDynamicRequiredProperties
} from "./paymentGatewayService";
import {useCustomPaymentGateway} from "./useCustomPaymentGateway";
import {Properties} from "./Properties";
import {useProperties} from "./useProperties";
import {InputNumber} from "primereact/inputnumber";
import {ToggleSwitch} from "../../../../Commans/UIParts/ToggleSwitch";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {usePaymentGatewayGrid} from "./usePaymentGatewayGrid";
import {Export} from "../../../../Commans/Grid/Export";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../../Commans/Grid/Pagination";
import {InputSwitchListFilter} from "../../../../Commans/Filters/InputSwitchListFilter";
import {ButtonSearchList} from "../../../../Commans/UIParts/ButtonSearchList";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {Tooltip} from "react-tooltip";
import {BankCreation} from "../../../../Commans/Accounting/Banks/BankCreation";
import {BankAccountCreation} from "../../../../Commans/Accounting/BankAccounts/BankAccountCreation";
export const HandlePaymentPortSetting = () => {
  const queryParams = queryString.parse(window.location.search);
  const redirectTo = useNavigate();
  const { t } = useTranslation();
  const [IsEdit,setIsEdit] = useState(false);
  const [showSpinner,setShowSpinner] = useState(false);
  const [initialSource,setInitialSource] = useState({paymentGateways:[],bankAccounts:[]});
  const [IsOpen, setIsOpen] = useState(false);
  const [refresh,setRefresh] = useState(false);
  const [errors,setErrors] = useState({});
  const [IsOpenBankAccount,setIsOpenBankAccount] = useState(false);
  const {handleChange,setFields,setProperties,fields,properties,handleChangeProperty} = useProperties();

  const handleOpenModal=()=>{
    setGateway({accountNumber: '',shebaNumber: '',properties: {},order: 0,cardNumber: '',isActive:true,name: '',pspId:initialSource.paymentGateways[0],bankAccountId:initialSource.bankAccounts[0]})
    const initialProperties= GetInitialProperties(initialSource.paymentGateways[0].fields)
    setFields(initialProperties)
    setIsOpen(true);
  }
  function handleResetGatewayAccount(){
    setGateway({accountNumber: '',shebaNumber: '',properties: {},order: 0,cardNumber: '',isActive:true,name: '',pspId:initialSource.paymentGateways[0] })
    // const initialProperties= GetInitialProperties(initialSource.paymentGateways[1].fields);
    // console.log(initialProperties);
    // setFields(initialProperties);
  }

  const handleCloseModal=()=>{
    setFields([]);
    setGateway({pspAccountId: '',accountNumber: '',shebaNumber: '',properties: {},order: 0,cardNumber: '',isActive:true,name: '',pspId:initialSource.paymentGateways[0],bankAccountId: initialSource.bankAccounts[0] })
    setIsEdit(false);
    setIsOpen(false);
    setProperties([]);
    handleClearErrors();
  }

  const handleOpenBankAccount=()=>{
    setIsOpenBankAccount(true);

  }
  const handleCloseBankAccount=()=>{
    setIsOpenBankAccount(false);
  }

  const handleSuccess=async(isSuccess)=>{
    if(isSuccess){
      const response = await GET(`/Accountings/BankAccounts/Selection`,{},'IEnumerable');
      if(response.status === 200){
        const bankAccounts = convertSelectDataHandler(response.data);
        setGateway((prevState)=>({...prevState,bankAccountId: bankAccounts[bankAccounts.length-1] }));
        setInitialSource(prevState => ({...prevState, bankAccounts:bankAccounts}));
       handleCloseBankAccount();
      }
      else{
        handleApiError(response,onApiError);
      }
    }
  }

  // handles spinner in api response
  const onApiError=()=>{
    setShowSpinner(false);
  }
  // handles errors
  const onSetErrors=(errors)=>{
    setErrors(errors);
  }
  //refreshes component when it is called
  const Refresh=()=>{
    setRefresh(prevState => !prevState);
  }
  // resets all errors to nothing as initial state
  const handleClearErrors=()=>{
    setErrors({});
  }
  // handles initial data
  const handleInitialData=(paymentGateways,bankAccounts)=>{
    const paymentGatewaysData = GetPaymentGatewaysData(paymentGateways);
    const bankAccountData = convertSelectDataHandler(bankAccounts);
    setInitialSource({
      paymentGateways: paymentGatewaysData,
      bankAccounts: bankAccountData
    })
  }




  const fetchData = async () => {
    try {
       setShowSpinner(true);
      const response = await GET(`/Accountings/BankAccounts/Selection`,{},'IEnumerable');
      const responseGateway = await GET(`/Payments/PSP/Selection`, {}, 'IEnumerable')
      if(response.status === 200 && responseGateway.status === 200  ){
        handleInitialData(responseGateway.data,response.data);
        setShowSpinner(false)
      }
      else{
        handleApiError(responseGateway,onApiError);
      }
    }
    catch (e) {
      onApiError();
      console.error(e);
    }
  };

  useEffect(()=>{
    fetchData();
  },[])

  const handleGatewayAccountSubmit=async(e)=>{
    e.preventDefault();
    try {
      if(IsEdit){
        setShowSpinner(true);
        if(gateway.bankAccountId === null){
          return;
        }
        const dynamicProperties = CreateDynamicProperties(properties);
        // var isValid = validateDynamicRequiredProperties(properties);
        // if(!isValid){
        //   handleFailureMessage('تنظیمات اجباری هستن');
        //   return;
        // }
        const paymentGateways = handleEditConvertPaymentGateways(gateway,dynamicProperties);
        const response = await PUT(`/Payments/PSPAccount`,paymentGateways,false);
        if(response.status === 200){
          setShowSpinner(false);
          handleCloseModal();
          Refresh();
        }
        else{
          handleApiError(response,onApiError);
        }
      }
      else{
        setShowSpinner(true);
        if(gateway.bankId === null){
          return;
        }
        const dynamicProperties = handleGetDynamicProperties(fields);
        // var isValid = validateDynamicRequiredProperties(fields);
        // if(!isValid){
        //   handleFailureMessage('تنظیمات اجباری هستن');
        //   return;
        // }
        const paymentGateways = handleConvertPaymentGateways(gateway,dynamicProperties);
        const response = await POST(`/Payments/PSPAccount`,paymentGateways,false);
        if(response.status === 200){
          setShowSpinner(false);
          handleCloseModal();
          handleSuccessMessage(t("Successfullyregistered"));
          Refresh();
        }
        else{
          handleApiError(response,onApiError);
        }
      }
    }
    catch (e) {
      onApiError();
      console.log(e);
    }
  }

  const {gateway,setGateway,handleValue,handleSelectValue,handleChangeToggle,handleNumberValue} = useCustomPaymentGateway(setErrors,setFields,setProperties);

  const {autoGroupColumnDef,columnDefs,defaultColDef,filter,setFilter,getRowHeight,handleFromDate,handleToDate,handleSearch,handleNextPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount,handleResetFilters,handleSelectFilter,handleToggleFilter,SwitchFilters,rowData, setSearch,search, pagination}=usePaymentGatewayGrid(setShowSpinner,fetchData,refresh,onApiError,initialSource,setGateway,setIsOpen,setIsEdit,Refresh,setProperties);






  return(
      <>
        <div class="space-y-5">
          {console.log(gateway)}
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                  <div class="space-y-2">
                    <label className="text-xs dark:text-white">{t("SearchByGatewayName")}</label>
                    <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                  </div>
                  <ButtonSearchList
                      onClickSearch={()=>handleSearch()}
                      onClickReset={()=>handleResetFilters()}
                  />
                </ListSearch>
              <ListFilter label={null} icon={null}
                          childrenOne={
                            <>
                              <ButtonPlus
                                  onClick={() =>handleOpenModal()}
                                  tooltipTitle={t("CreateBankGateway")}
                              />
                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                  icon={"pi-cog"}
                                  title={t("settings")}
                                  tooltipTitle={t("SettingsPage")}
                              />

                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Setting/Shop/General`)}
                                  icon={"pi-objects-column"}
                                  title={t("Public")}
                                  tooltipTitle={t("GeneralSettings")}
                              />

                              <ButtonPlus
                                  onClick={()=>redirectTo(`/Harmony/Setting/Shop/ApiPost`)}
                                  icon={"pi-truck"}
                                  title={t("sending")}
                                  tooltipTitle={t("ShippingMethods")}
                              />

                            </>
                          }
                          childrenTwo={
                            <>
                              <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                            </>
                          }
              />


            </div>

            <div class="space-y-2 H-body-ag-grid relative">
              {
                showSpinner?
                    <div className=" huploader_loading" >
                      <div className="relative">
                        <LoadingHarmony/>
                      </div>
                    </div>
                    :
                    null
              }

              <GridTable
                  columnDefs={columnDefs}
                  rowSelection={GridSetting.rowSelection}
                  rowData={rowData}
                  style={GridSetting.gridStyle}
                  defaultColDef={defaultColDef}
                  autoGroupColumnDef={autoGroupColumnDef}
                  pagination={GridSetting.pagination}
                  enableRtl={GridSetting.enableRtl}
                  domLayout={GridSetting.domLayout}
                  paginationPageSize={GridSetting.pageSize}
                  getRowHeight={getRowHeight}
                  localeText={AgGridEnglish}
              />
              <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
            </div>
             <Export apiUrl={''}/>
          </div>

        <FantasyModal openModal={IsOpen}  closeModal={handleCloseModal} title={t("CreateBankGateway")}>
          <form onSubmit={handleGatewayAccountSubmit} method={'post'}>
            <div className={"space-y-2"}>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div class="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                  <InputText className={"input w-full"} value={gateway.name} name={'name'} onInput={(e)=>handleValue(e)} />
                </div>
                <div className={"space-y-1"}>
                  <div class="space-y-1 flex-1">
                    <label className="text-xs dark:text-gray-300">{t("OnlineGateways")}</label>
                    <Select
                        options={initialSource.paymentGateways}
                        isMulti={false}
                        isSearchable={true}
                        onChange={(data)=>handleSelectValue(data,'pspId')}
                        placeholder={t("selection")}
                        value={gateway.pspId}
                        classNamePrefix={"h_multi_select"}
                        menuPosition={"fixed"}
                    />
                  </div>
                </div>
                <div class="space-y-2">
                  <div className={"flex justify-between items-center"}>
                    <label className="text-xs dark:text-gray-300">{t("BankAccount")}</label>
                    <button type="button" onClick={()=>handleOpenBankAccount()}  className="text-themeInformation dark:text-themeDarkInformation text-xs tooltip_class_BankAccountCreation">
                      ({t("BankAccountCreation")})
                      <Tooltip anchorSelect={".tooltip_class_BankAccountCreation"} content={t("BankAccountCreation")} positionStrategy={"fixed"} />
                    </button>
                  </div>
                  <Select
                      options={initialSource.bankAccounts}
                      isMulti={false}
                      isClearable={true}
                      isSearchable={true}
                      onChange={(data)=>handleSelectValue(data,'bankAccountId')}
                      placeholder={t("selection")}
                      value={gateway.bankAccountId}
                      classNamePrefix={"h_multi_select"}
                      menuPosition={"fixed"}
                  />
                </div>
                {/*<div className="space-y-1">*/}
                {/*  <label className="text-xs dark:text-gray-300">{t("BankAccountNumber")}</label>*/}
                {/*  <InputText type={"text"} className={"input w-full"} value={gateway.accountNumber} name={'accountNumber'} onInput={(e)=>handleValue(e)} />*/}
                {/*</div>*/}
                {/*<div className="space-y-1">*/}
                {/*  <label className="text-xs dark:text-gray-300">{t("cardNumber")}</label>*/}
                {/*  <InputText value={gateway.cardNumber} name={'cardNumber'} onInput={(e)=>handleValue(e)} type={"text"} className={"input w-full"} />*/}
                {/*</div>*/}
                {/*<div class="space-y-1">*/}
                {/*  <label className="text-xs dark:text-gray-300">{t("BankID")}</label>*/}
                {/*  <InputText type={"text"} value={gateway.shebaNumber} name={'shebaNumber'} onInput={(e)=>handleValue(e)} className={"input w-full"} />*/}
                {/*</div>*/}
                <div class="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("rank")}</label>
                  <InputNumber className="w-full " name={'order'}  onValueChange={(e)=>handleNumberValue(e.target.value,'order')} value={gateway.order} placeholder={t("selection")} mode="decimal" showButtons min={0} max={100}/>
                </div>
                <div class="space-y-1">
                  <label className="text-xs dark:text-gray-300">{t("isActive")}</label>
                  <ToggleSwitch  customClass={"w-32"} tag={'isActive'} value={gateway.isActive} handleToggleSwitchValue={handleChangeToggle} name={'isActive'} checked={t("Active")} unchecked={t("Inactive")} />
                </div>
              </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {IsEdit ? (
                      properties.map((item,index) => (
                          <Properties
                              IsEdit={IsEdit}
                              key={index}
                              index={index}
                              handleChange={handleChangeProperty}
                              item={item}
                          />
                      ))
                  ) : (
                      fields && fields.length > 0 ? (
                          fields.map((item, index) => (
                              <Properties
                                  IsEdit={IsEdit}
                                  key={index}
                                  index={index}
                                  handleChange={handleChange}
                                  item={item}
                              />
                          ))
                      ) : null
                  )}
                </div>
                <div>
                  <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                </div>
              </div>

          </form>
        </FantasyModal>


        <FantasyModal  openModal={IsOpenBankAccount}  closeModal={handleCloseBankAccount}  title={t("BankAccountCreation")} >

          <BankAccountCreation
              IsOpen={IsOpenBankAccount}
              handleSuccess={handleSuccess}
          />

        </FantasyModal>


        <ToastContainer/>

      </>

  )
}