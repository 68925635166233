import React from "react";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";

export const useTabServices=()=>{






    const handleShowPrice=(price,currencies,currencyType)=>{
       const currency =  currencies.find((item) => item.value === currencyType);

        return (<>
            <PriceFormatterComponent price={price} /> {currency.label}
        </>)
    }
    const handleGetCurrentType=(currencies,currencyType)=>{
        const currency =  currencies.find((item) => item.value === currencyType);
        return currency.label;
    }

    const handleGetPaymentMethod=(paymentMethods,paymentMethod)=>{
        const currency =  paymentMethods.find((item) => item.value === paymentMethod);
        return currency.label;
    }







    return {handleShowPrice,handleGetCurrentType,handleGetPaymentMethod}
}