import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useCustomTariffBinder=(setErrors,initialSource)=>{
    const { t } = useTranslation();
    const [tariff,setTariff] = useState({tariffId:null,upgradeType:null,price:0,currencyType:null,countryId:null,discountFrom:null,discountTo:null,discountType:null,discountAmount:0,paymentMethod:null,packageId:null,templateMasterId:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setTariff({...tariff,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const [valueService, setValueService] = useState(null);
    const services = [
        { name: t("Upgrade"), value: 1},
        { name: t("Package"), value:2 },
        { name: t("OriginalTemplate"), value: 3 }
    ];
    const handleChangeService=(value)=>{
        if(value === 1){
            handleSelectValue(initialSource.upgradeTypes[0],'upgradeType');
        }else if(value === 2){
            handleSelectValue(initialSource.packages[0],'packageId');
        }else if(value === 3){
            handleSelectValue(initialSource.templateMasters[0],'templateMasterId');
        }
        setValueService(value);
    }


    const handleNumberValue = (value,name)=>{
        setTariff({...tariff,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleSelectValue=(data,name)=>{
        setTariff({...tariff,[name]:data});
    }

    const handleFromDate = (dateTime)=>{
        setTariff({...tariff,discountFrom:dateTime})
    }
    const handleToDate = (dateTime)=>{
        setTariff({...tariff,discountTo:dateTime})
    }
    const handleGetInitialCountry=(name)=>{
        const country = initialSource.countries.find((item)=>item.label === name);
        return country;
    }

















    return {
        tariff,
        setTariff,
        handleValue,
        handleNumberValue,
        handleSelectValue,
        handleToDate,
        handleFromDate,
        handleGetInitialCountry
        ,handleChangeService,
        valueService,
        setValueService,
        services

    }

}