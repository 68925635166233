import React, {useState} from "react";

export const usePicture=()=>{
    const [picture,setPicture] = useState({
         logoHeader:null
        ,logoFooter:null
        ,icon:null
        ,defaultImage:null
        ,man:null
        ,woman:null
        ,none:null
        ,concurrencyStamp:null
        ,bannerImages:[],

        logoHeaderPath:null,
        logoFooterPath:null,
        iconPath:null,
        defaultImagePath:null,
        manPath:null,
        womanPath:null,
        nonePath:null
    })

    const handleFileChange = (event,name,pathField) => {
        const file = event.target.files[0];
        const newFilePath = URL.createObjectURL(file);
        console.log(newFilePath)
        setPicture({...picture,[name]: file,[pathField]:newFilePath});
    };
    const displayFile=(filePath)=>{
        window.open(filePath);
    }
    return {
        picture,setPicture,
        handleFileChange,
        displayFile,
    }

}