import React, {useEffect, useState} from "react";
import {Knob} from "primereact/knob";

import ArrowLeftIcon from "../../../HIcon/ArrowLeftIcon";

export const RangeQuestion=({question,rangeItems,handleAddSurveyQuestion,surveyId,index})=>{
    // State variables to store limit, survey items, and current value
    const [limit, setLimit] = useState({ min: 0, max: 0 });
    const [row1Item, setRow1Item] = useState(null);
    const [row2Item, setRow2Item] = useState(null);
    const [value, setValue] = useState(0);

    // Effect to find and set survey items based on rows in rangeItems
    useEffect(() => {
        // Helper function to find and set survey items and limits
        const findAndSetItem = (row, setItem, setLimitKey) => {
            const item = rangeItems.find((item) => item.row === row);
            if (item) {
                setItem(item);
                // Update the limit state based on the specified key (min or max)
                setLimit((prevState) => ({ ...prevState, [setLimitKey]: Number(item.surveyItemValue) }));
            }
        };

        // Find and set items for row 1 and row 2
        findAndSetItem(1, setRow1Item, "min");
        findAndSetItem(2, setRow2Item, "max");
    }, [rangeItems]);

    // Handler for knob value change
    const onChange = (val) => {
        setValue(val);

        // Determine the surveyItemId based on the value and limits
        const surveyItemId = val <= limit.min ? row1Item.id : row2Item.id;

        // Call handleAddSurveyQuestion with the updated survey item
        handleAddSurveyQuestion({
            surveyItemId: surveyItemId,
            surveyId: surveyId,
            textSurvey: '',
        }, index);
    };

    return (

        <>

            <div class="flex items-center justify-between gap-5 p-3 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                <div class="rounded-full size-16 lg:size-20 border-2 border-white dark:border-themeDarkColorLine bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white flex justify-center items-center text-2xl shadow-xl">
                    {index+1}
                </div>
                <div class="flex-1 md:border-4 border-dashed lg:p-5 rounded ">
                    <h2 class="lg:text-xl text-themeInformation dark:text-themeDarkInformation">
                        {question}
                    </h2>
                </div>
                <div>
                        <span className={"arrow_design_add_site"}>
                             <ArrowLeftIcon/>
                        </span>
                </div>
            </div>
            <div className={"grid grid-cols-1 bg-white dark:bg-themeDarkSalt700 rounded-md shadow border dark:border-themeDarkColorLine p-3"}>
                <div>
                    <div className="gap-2 flex flex-col items-center">
                        <Knob value={value} min={0} showValue={true}   max={10} size={150}
                              onChange={(data)=>onChange(data.value)}
                        />

                    </div>
                </div>
            </div>

        </>
    )
}