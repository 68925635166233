import { GET } from "../../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import { convertSelectDataHandler } from "../../../CMS/Contents/ContentService";
import { useEffect, useState } from "react";

export const UseDepartmentSelector = (setOptions, onApiError, refresh, showSpinner, hideSpinner, setTicket, setActiveItem) => {

    const [isChange, setIsChange] = useState(false);
    const [department, setDepartment] = useState([]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [isShow, setShow] = useState(true);

    const handleLoadPreviousData = async () => {
        showSpinner();
        const UserDepartment = await GET(`/Tickets/User/Departments`, {}, 'IEnumerable');
        if (UserDepartment.status === 200) {
            setDepartment(UserDepartment.data);
            hideSpinner();
        }
        else {
            handleApiError(UserDepartment, onApiError);
        }
    }
    useEffect(() => {
        handleLoadPreviousData();
    }, [refresh])


    return {
        isChange,
        setIsChange,
        department,
        setDepartment,
        activeIndex,
        setActiveIndex,
        isShow,
        setShow,
    }
}