import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {Link, useNavigate} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import Select from "react-select";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {InputTextarea} from "primereact/inputtextarea";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {userReminderSchema} from "./userReminderSchema";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {convertAddReminder, convertEditReminder} from "./ReminderService";
import {useReminderGrid} from "./useReminderGrid";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {Export} from "../../../Commans/Grid/Export";
import {useReminder} from "./useReminder";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const HandleReminderManagement = ()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsShowSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [errors, setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({sendingStates:[],types:[],reminderStates:[],users:[]});
    const [disable,setDisable] = useState(true);
    const [IsOpen, setIsOpen] = useState(false);

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {handleSelectNoteType,handleValue,reminder,setReminders,handleSelectSendingState,handleDateReminder,handleSelectValue} = useReminder(setErrors,setDisable);

    const showSpinner=()=>{
        setShowSpinner(true);
    }
    const hideSpinner=()=>{
        setShowSpinner(false);
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }

    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const handleClearErrors=()=>{
        setErrors({})
    }


    const handleOpenReminder=()=>{
        setReminders((prevState)=>({...prevState,enSendingStates:null,description: null,type:initialSource.types[0],message: '',title: '',reminderDateUtc: null,userId: initialSource.users[0]}));
        setIsOpen(true);
    }

    const handleCloseReminder=()=>{
        setIsOpen(false);
        setIsEdit(false);
        setDisable(true)
        handleClearErrors();
        setReminders((prevState)=>({...prevState,enSendingStates:null,description: null,type:initialSource.types[0],message: '',title: '',reminderDateUtc: null,userId: initialSource.users[0]}));
    }


    const handleInitialSource=(sendingStates,noteTypes,jobStates,users)=>{
        const sendingStatesData = convertSelectDataHandler(sendingStates);
        const noteTypesData = convertSelectDataHandler(noteTypes);
        const jobStatesData = convertSelectDataHandler(jobStates);
        const usersData = convertSelectDataHandler(users);
        setInitialSource({
            sendingStates: sendingStatesData,
            types: noteTypesData,
            reminderStates: jobStatesData,
            users: usersData
        })
    }

    const fetchData = async () => {
        try {
            showSpinner();
            const [
                responseSendingStates,
                reminderResponse,
                stateResponse,
                responseUser
            ] = await Promise.all([
                GET(`/Enumerations/EnMessengers`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnNoteType`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnBackgroundJobState`),
                GET(`/Users/Selection`, {}, 'IEnumerable')
            ]);
            if (responseSendingStates.status === 200 &&
                reminderResponse.status === 200 &&
                stateResponse.status === 200 &&
                responseUser.status === 200)
            {
                handleInitialSource(
                    responseSendingStates.data,
                    reminderResponse.data,
                    stateResponse.data,
                    responseUser.data
                );
                hideSpinner();
            } else {
                handleApiError(responseSendingStates, hideSpinner);
            }
        } catch (error) {
            hideSpinner();
        }
    };



    const handleAddReminder =async (e)=> {
        e.preventDefault();
        const currentUtcTime = new Date().toISOString(); // Get the current UTC time in ISO format
        const isValid = validate(userReminderSchema,reminder,onSetErrors);
        if (!isValid) {
            return;
        }
        try {
            if(isEdit){
                if((reminder.type.value ===1 || reminder.type.value === 2) && reminder.enSendingStates === null ){
                    handleFailureMessage(t('PleaseSelectNotificationMethod'))
                    return;
                }
                if((reminder.type.value ===1 || reminder.type.value === 2) && reminder.reminderDateUtc === null ){
                    handleFailureMessage(t('PleaseSelectDateTime'))
                    return;
                }
                if (reminder.type.value ===1  && new Date(reminder.reminderDateUtc) < new Date(currentUtcTime)) {
                    handleFailureMessage(t('ReminderDateCannotBeInThePast'));
                    return;
                }
                showSpinner();
                const reminders = convertEditReminder(reminder);
                const response = await PUT(`/UserNotes`,reminders,false);
                if (response.status === 200) {
                    hideSpinner();
                    Refresh();
                    handleCloseReminder();
                    handleSuccessMessage(t("Reminderhasbeensuccessfullyedited"));
                } else {
                    handleApiError(response,hideSpinner)
                }
            }
            else{
                if((reminder.type.value ===1 || reminder.type.value === 2) && reminder.enSendingStates === null ){
                    handleFailureMessage(t('PleaseSelectNotificationMethod'))
                    return;
                }
                if((reminder.type.value ===1 || reminder.type.value === 2) && reminder.reminderDateUtc === null ){
                    handleFailureMessage(t('PleaseSelectDateTime'))
                    return;
                }
                if (reminder.type.value ===1 && new Date(reminder.reminderDateUtc) < new Date(currentUtcTime)) {
                    handleFailureMessage(t('ReminderDateCannotBeInThePast'));
                    return;
                }
                showSpinner()
                const reminders = convertAddReminder(reminder);
                const response = await POST(`/UserNotes`,reminders,false);
                if (response.status === 200) {
                    hideSpinner();
                    Refresh()
                    handleCloseReminder();
                    handleSuccessMessage(t("Reminderhasbeensuccessfullyrecorded"));
                } else {
                    handleApiError(response,onSetErrors)
                }
            }
        }
        catch (e) {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[])

    const {filter,handleNextPage,handleCurrentPage,handleSearch,search,handlePreviousPage,setFilter,setSearch,handlePageSizeCount,handleResetFilters,handleToggleFilter,SwitchFilters,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination}=useReminderGrid(setIsEdit,setShowSpinner,setDisable,setReminders,setIsOpen,hideSpinner,initialSource,Refresh,refresh)








    return (
        <>
            <div class="space-y-5 relative">

                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleOpenReminder()}
                                            tooltipTitle={t("CreateReminder")}
                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                                    </>
                                }
                    />


                </div>
                <div class="space-y-2 H-body-ag-grid">

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export/>

            </div>

            <FantasyModal openModal={IsOpen}  closeModal={handleCloseReminder} title={t("CreateNote")}>
                <form onSubmit={handleAddReminder} method={'post'} >
                    <div className={"space-y-5"}>
                        <div className={"space-y-3"}>
                            <div class="space-y-1 ">
                                <div className="flex justify-between items-center">
                                    <label className="text-xs dark:text-gray-300">
                                        {t("NoteTitle")}
                                        <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.title}/>
                                </div>
                                <InputText type={"text"} value={reminder.title} onInput={(e)=>handleValue(e)} name={'title'} className={" input w-full"} />
                            </div>
                            <div>
                                <InputTextarea value={reminder.message} onInput={(e)=>handleValue(e)} placeholder={t("NoteText")} name={'message'} rows={5} cols={30} className={"input w-full"} />
                            </div>
                            <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                                <div className="space-y-1 ">
                                    <label className="text-xs dark:text-gray-300">{t("OperationType")}</label>
                                    <Select
                                        options={initialSource.types}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectNoteType(data)}
                                        placeholder={t("selection")}
                                        value={reminder.type}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className={"space-y-1"}>
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("ReminderDataTime")}
                                    </label>
                                    <DateTimePicker isContainsTime={true}  disabled={disable} handleDateTime={handleDateReminder} format={'YYYY/MM/DD hh:mm:ss'} value={reminder.reminderDateUtc} />
                                </div>
                                <div class="space-y-1 ">
                                    <label className="text-xs dark:text-gray-300">{t("NotificationMethod")}</label>
                                    <Select
                                        options={initialSource.sendingStates}
                                        isMulti={true}
                                        isClearable={true}
                                        isSearchable={true}
                                        isDisabled={disable}
                                        onChange={(data)=>handleSelectSendingState(data)}
                                        name={'enSendingStates'}
                                        placeholder={t("selection")}
                                        value={reminder.enSendingStates}
                                        className={"select-group"}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div class="space-y-1">
                                    <div className="space-y-1 ">
                                        <label className="text-xs dark:text-gray-300">{t("Userselection")}</label>
                                        <Select
                                            options={initialSource.users}
                                            isMulti={false}
                                            isSearchable={true}
                                            onChange={(data)=>handleSelectValue(data,'userId')}
                                            placeholder={t("selection")}
                                            value={reminder.userId}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label htmlFor={"2"} className={"cursor-pointer text-xs flex gap-2 items-center w-max"}>
                                <input type="checkbox" id={"2"}/>
                                یادآوری به خودم
                            </label>
                        </div>
                        <div>
                            <button type={'submit'} className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("register")}</button>
                        </div>
                    </div>

                    </form>
            </FantasyModal>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
            <ToastContainer />
        </>

    )
}