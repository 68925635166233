import React, { useEffect, useState } from "react";
import { PickList } from "primereact/picklist";
import queryString from "query-string";
import { LoadingHarmony } from "../../../../Commans/Loadings/LoadingHarmony";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {useNavigate, useRouteError} from "react-router-dom";

import {ToastContainer} from "react-toastify";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {Return} from "../../../../Commans/Globals/Return";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, POST, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../../Commans/UIParts/ButtonVideo";

import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const HandleWebsiteTemplate = () => {
    const { t } = useTranslation();
    const [templateSource,setTemplateSource] = useState([]);
    const [templateTarget,setTemplateTarget] = useState([]);
    const queryParams = queryString.parse(window.location.search);
    const redirectTo = useNavigate();
    const[domainToken,setDomainToken] = useState(null);
    const [IsRefresh,setIsRefresh] = useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);

    //shows spinner
    const showSpinner=()=>setIsShowSpinner(true);
    //hides spinner
    const hideSpinner=()=>setIsShowSpinner(false);

    // changes data to left side and right side
    const onChange = (event) => {
        setTemplateSource(event.source);
        setTemplateTarget(event.target);
    };

    // filters selected packages in all packages not to show repeated data in sides
    const handleFilterSelectedPackagesInAllPackages=(allTemlates,websiteTemlates)=>{
        const templateList = allTemlates.data.map((pck) => ({name: pck.value, id: pck.key,}));
        const selectedTemplate = websiteTemlates.data.length > 0 ? templateList.filter((item) => websiteTemlates.data.some((sourceItem) => sourceItem.key === item.id)) : [];
        const filteredTemplates = templateList.filter(pck => !selectedTemplate.some(selectedRole => selectedRole.id === pck.id));
        return {selectedTemplate,filteredTemplates}
    }

    // fetches all data from server
    const fetchData=async()=>{
        showSpinner();
        try {
            const allTemplates = await GET(`/TemplateMasters/GetTemplateMastersSelection`,{},'IEnumerable');
            const websiteTemplates = await GET(`/Websites/GetAllWebsiteTemplates?WebsiteId=${queryParams.websiteId}`);
            if(allTemplates.status === 200 && websiteTemplates.status === 200){
                const {selectedTemplate,filteredTemplates}=handleFilterSelectedPackagesInAllPackages(allTemplates,websiteTemplates);
                setTemplateSource(filteredTemplates);
                setTemplateTarget(selectedTemplate);
                hideSpinner();
            }
            else{
                handleApiError(allTemplates,hideSpinner);
                handleApiError(websiteTemplates,hideSpinner)
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(()=>{
        fetchData();
    },[IsRefresh])



    const handleSubmitWebsiteTemplate=async()=>{
        if(templateTarget.length === 0){
            handleFailureMessage(t("DearuserpleaseselectTemplate"));
            return;
        }
        try {
            showSpinner();
            const templates = {websiteId:queryParams.websiteId,templateMasterIds:templateTarget.map(item=>item.id)}
            const response = await PUT(`/Websites/Template`,templates,false);
            if(response.status === 200){
                hideSpinner();
                redirectTo('/Admin/Website/List');
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner();
            console.log(e);
        }
    }



    const itemTemplate = (item) => {

        return (
            <div className="flex items-center gap-1 border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkInformation p-2 rounded ">
                <div>
                    <div className={"p-2 rounded bg-themeSecondary"}>
                        <HPrimeIconSmall
                            icon={'pi-user'}
                            iconClass={"text-white"}
                        />

                    </div>
                </div>
                <span className="font-bold line-clamp-1 w-full">{item.name}</span>
                <div className={"flex gap-3 items-center flex-shrink-0"}>
                    <button type={"button"}>
                        <HPrimeIcon icon={"pi-list"} iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
                    </button>
                </div>
            </div>
        );
    };


    return (
        <>

            <div className=" space-y-5  ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo('/Admin/Website/List')}
                                            icon={"pi-list"}
                                            tooltipTitle={t("WebsiteList")}
                                            title={t("list")}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                <div class="relative">
                    {IsShowSpinner ? (
                        <div className="huploader_loading">
                            <div className="relative">
                                <LoadingHarmony />
                            </div>
                        </div>
                    ) : null}
                    <div style={{direction:"ltr"}} class="p-5 box bg-white dark:bg-themeDarkSalt700">
                        <PickList
                            source={templateSource}
                            target={templateTarget}
                            onChange={onChange}
                            filter
                            filterBy="name"
                            sourceHeader={t("Unauthorized")}
                            targetHeader={t("Authorized")}
                            sourceStyle={{ height: "30rem" }}
                            targetStyle={{ height: "30rem" }}
                            sourceFilterPlaceholder={t("search")}
                            targetFilterPlaceholder={t("search")}
                            itemTemplate={itemTemplate}
                        />
                    </div>
                </div>
                <ButtonSubmitAndCancel
                    onCancelClick={()=>redirectTo('/Admin/Website/List')}
                    onRegisterClick={()=>handleSubmitWebsiteTemplate()}
                />
            </div>

            <ToastContainer/>

        </>

    );
};
