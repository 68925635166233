import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {InputTextarea} from "primereact/inputtextarea";

export const LocationFields = ({ index, element, handlePropertyChange, t,handleDefaultValueChange }) => (
    <div className="grid grid-cols-1">
        <div className="space-y-1">
            <label className="text-xs dark:text-gray-300">{t("Address")}</label>
            <InputTextarea
                type="text"
                value={element.properties?.defaultValue?.address}
                onChange={(e) => handleDefaultValueChange(index, 'address', e.target.value)}
            />
        </div>
    </div>
);