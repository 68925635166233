import React, {useState} from "react";

export const useProperties=()=>{

    const [properties, setProperties] = useState([]);
    const [fields,setFields] = useState([]);

    const handleChangeProperty = (name, value) => {
        setProperties((prevProperties) =>
            prevProperties.map((prop) =>
                prop.name === name ? { ...prop, value: value } : prop
            )
        );
    };

    const handleChange = (fakeId, field, value, id) => {
        setFields((prevFields) => {
            // Check if the field exists based on fakeId or id
            const existingFieldIndex = prevFields.findIndex(
                (meta) => (id && meta.id === id) || (!id && meta.fakeId === fakeId)
            );

            if (existingFieldIndex !== -1) {
                // Field exists, update the value
                return prevFields.map((meta, index) => {
                    if (index === existingFieldIndex) {
                        return { ...meta, [field]: value };
                    } else {
                        return meta;
                    }
                });
            } else {
                // Field does not exist, add it to the fields array
                const newField = {
                    id: id || null,
                    fakeId: fakeId || null,
                    [field]: value
                };
                return [...prevFields, newField];
            }
        });
    };
    return {
        handleChange,
        setProperties,
        setFields,
        properties,
        fields,
        handleChangeProperty
    }

}