
import React, {useContext} from "react";
import {FileContext} from "./FileProvider";
import {useTranslation} from "react-i18next";

// این کامپوننت سرچ فایل یا فولدر هست
// از setPattern  هم برای ارسال فرمت فیلتر فایل ها داخل فولدر استفاده میکنیم
// هم به عنوان پارامتر سرچی که برای اسم فولدر یا فایل مییباشد

export const FileSearch = ()=>{
    const { t } = useTranslation();


    const {setPattern,pattern} = useContext(FileContext)


    return (

            <div class="space-y-2">
                <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                <div>
                    <input placeholder={t("search")} value={pattern} onChange={(e)=>setPattern(e.target.value)}  type="search" className="w-full input " />
                </div>
            </div>

    )
}