import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {useNavigate} from "react-router-dom";
import {useFormGrid} from "./useFormGrid";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";

export const FormsTab=({filter})=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh,setRefresh]=useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);

    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=> setRefresh((prevState)=>!prevState);



    const {pagination,forms,autoGroupColumnDef,columnDefs,defaultColDef,getRowHeight,handleNextPage,handlePreviousPage,handleCurrentPage, handlePageSizeCount}=useFormGrid(filter,refresh,showSpinner,hideSpinner)





    return (

        <>
            <div className={"space-y-2 H-body-ag-grid relative"}>
                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={forms}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridPersion}
                />
                <Pagination length={forms.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />

            </div>
        </>

    )
}