import React from "react";
import {useTranslation} from "react-i18next";

export const UserLevel=()=>{

    const { t } = useTranslation();


    return (

        <>

            <div className={"box bg-white px-2 py-3 overflow-x-auto light-shadow dark:border-themeDarkColorLine dark:bg-themeDarkSalt700"}>
                <div class="process-wrap active-step1 ">
                        <div class="process-main">
                            <div class="col-3">
                                <div class="process-step-cont">
                                    <div class="process-step step-1"></div>
                                    <span class="process-label">
                                        <h2 className={"text-xs dark:text-gray-300"}>{t("RegularUser")}</h2>
                                    </span>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="process-step-cont">
                                    <div class="process-step step-2"></div>
                                    <span class="process-label">
                                        <h2 className={"text-xs dark:text-gray-300"}>{t("ProfileCompletion")}</h2>
                                    </span>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="process-step-cont">
                                    <div class="process-step step-3"></div>
                                    <span class="process-label">
                                        <h2 className={"text-xs dark:text-gray-300"}>{t("GoldUser")}</h2>
                                    </span>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="process-step-cont">
                                    <div class="process-step step-4"></div>
                                    <span class="process-label">
                                        <h2 className={"text-xs dark:text-gray-300"}>{t("SpecialUser")}</h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        </>

    )
}