
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const CheckBoxElement = ({ deleteCheckBoxItem,itemCount,SortDescending,SortAccending,index, element, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement })=>{

    return (
        <>

            {element.properties.items.map((item, itemIndex) => (

                <div key={itemIndex}>
                    <label>{item.label}</label>
                    <input
                        type="checkbox"
                        placeholder={element?.properties?.description}
                        className={`${index === selectedElementIndex ? 'border-themeInformation' : ''}`}
                        onClick={() => handleSelectTabAfterElementSelection(index)}
                        readOnly={element.properties.isReadOnly}
                        disabled={element.properties.isReadOnly}
                        id={itemIndex + item.label}
                        name={element.properties.name}
                        checked={element.properties.defaultValue?.value?.some((defaultItem) => defaultItem.value === item.value) || false}
                    />
                </div>
            ))}

            <div className={"harmony_body_button"}>

                <button
                    type="button"
                    className="button bg-themeDanger text-white harmony_button_form"
                    onClick={() => deleteElement(index)}
                >
                    <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
                </button>

                {
                    index===0?null:
                        <button
                            type="button"
                            className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                            onClick={() => SortAccending(index)}
                        >
                            <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                        </button>

                }
                {
                    index <itemCount - 1?
                        <button
                            type="button"
                            className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                            onClick={() => SortDescending(index)}

                        >
                            <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                        </button>:
                        null
                }


            </div>





        </>
    )
}
