import React, {useState} from "react";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

export const useCustomSendSms=(setErrors,onApiError,setInitialSource,handleGetCharCount,setShowSpinner,setCost)=>{
    const [sms,setSms] = useState({id:'',message:null,to:[],isFlash:false,sendingDate:null});

    const handleValue=(e)=>{
        const {name,value} = e.target;
        if(name === 'message'){
            setSms({...sms,[name]:value});
            handleGetCharCount(value.length);
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
            setCost({amount:'-',currency:'-'});
            return;
        }
        setSms({...sms,[name]:value});
        handleGetCharCount(value.length);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setSms({...sms,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=async(data,name)=>{
        if(name === 'to'){
            try {
               setShowSpinner(true);
               const response = await GET(`/Messaging/UserGroupNumbers?UserGroupId=${data.value}`,{},'IEnumerable');
               if(response.status === 200){
                   const listOfNumbers = response.data.map((item)=>item.value);
                   setSms((prevState) => ({
                       ...prevState,
                       to:listOfNumbers,
                   }));
                   setCost({amount:'-',currency:'-'});
                   setShowSpinner(false);
               }
               else{
                   handleApiError(response,onApiError)
               }
            }
            catch (e){
                onApiError();
                console.log(e);
            }
            return;
        }
        setSms({...sms,[name]:data});
    }
    const handleDateTime=(dateTime)=>{
        setSms({...sms,sendingDate:dateTime});
    }
    const handleChangeNumbers = (numbers) => {
        setSms((prevState) => ({ ...prevState,to: numbers}));
        setCost({amount:'-',currency:'-'});
    };

    return {
        handleNumberValue,
        handleValue,
        handleSelectValue,
        handleDateTime,
        handleChangeNumbers,
        sms,
        setSms
    }

}