import React, {useState} from "react";

import {InputText} from "primereact/inputtext";
import { Calendar } from "react-multi-date-picker";
import Select from "react-select";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {Accordion, AccordionTab} from "primereact/accordion";
import {DateTimePicker} from "../../../../Commans/Dates/DateTimePicker";
import {TabMenuTwo} from "../../../../Commans/UIParts/TabMenuTwo";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";


export const HandleAttendanceSettings = () => {
    const { t } = useTranslation();

    const redirectTo = useNavigate();

    const [dates, setDates] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState(null);

    const handleRadioChange = (radioId) => {
        setSelectedRadio(radioId);
    };

    const [activeTab, setActiveTab] = useState("tab_one");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };
//........

// اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
    const tabs = [
        {
            id: "tab_one",
            label: t("CalculationType"),
            tooltipTitle:t("CalculationType"),
        },
        {
            id: "tab_two",
            label: t("DetermineTheLimit"),
            tooltipTitle:t("DetermineTheLimit"),

        },
        {
            id: "tab_three",
            label: t("Rules"),
            tooltipTitle:t("Rules"),
        },
        {
            id: "tab_four",
            label: t("WeeklyVacation"),
            tooltipTitle:t("WeeklyVacation"),
        },
        {
            id: "tab_five",
            label: t("AnnualCalendar"),
            tooltipTitle:t("AnnualCalendar"),

        },
        {
            id: "tab_six",
            label: t("DefaultProvisions"),
            tooltipTitle:t("DefaultProvisions"),

        },
        {
            id: "tab_seven",
            label: t("SmartReport"),
            tooltipTitle:t("SmartReport"),

        },
    ];
    const [value, setValue] = useState(new Date());



    return(
        <>
            <div class="space-y-5 ">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>

                                    </>
                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                <div>
                    <div class="relative grid grid-cols-2 lg:grid-cols-7 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-3">
                        <TabMenuTwo activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                    </div>
                    <div class="post__content tab-content ">
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2 ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div className={`post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ${selectedRadio === "hour" ? "border-themeInformation dark:border-themeDarkInformation" : "dark:border-themeDarkColorLine"} `}>
                                    <div>
                                        <label htmlFor={"hour"} className="flex items-center gap-2 cursor-pointer bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                            <input name={"1"} type="radio" id={"hour"} onChange={() => handleRadioChange("hour")}></input>
                                            <h2 className={"dark:text-gray-300 text-xs"}>{t("AnHour")}</h2>
                                        </label>
                                    </div>
                                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3"}>
                                        <div class="space-y-1">
                                            <label className="text-xs dark:text-gray-300">{t("Minimum")}</label>
                                            <DateTimePicker
                                                onChange={setValue}
                                                value={value}
                                                format="HH:mm"
                                                disableClock={true} // مخفی کردن آیکون ساعت
                                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                                isClockOpen={false} // نمایش انتخابگر ساعت
                                            />
                                        </div>
                                        <div class="space-y-1">
                                            <label className="text-xs dark:text-gray-300">{t("Maximum")}</label>
                                            <DateTimePicker
                                                onChange={setValue}
                                                value={value}
                                                format="HH:mm"
                                                disableClock={true} // مخفی کردن آیکون ساعت
                                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                                isClockOpen={false} // نمایش انتخابگر ساعت
                                            />
                                        </div>
                                        <div class="space-y-1">
                                            <label className="text-xs dark:text-gray-300">{t("HourlyWages")}</label>
                                            <InputText className="input w-full"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={`post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2 ${selectedRadio === "shift" ? "border-themeInformation dark:border-themeDarkInformation" : "dark:border-themeDarkColorLine"} `}>
                                    <div>
                                        <label htmlFor={"shift"} className="flex items-center gap-2 cursor-pointer bg-themeLightGray post-input-design-x dark:bg-themeDarkSalt700 p-2 border-themeInformation dark:border-themeDarkInformation">
                                            <input name={"1"} type="radio" id={"shift"} onChange={() => handleRadioChange("shift")}></input>
                                            <h2 className={"dark:text-gray-300 text-xs"}>{t("Shift")}</h2>
                                        </label>
                                    </div>
                                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2"}>
                                        <div class="space-y-1">
                                            <label className="text-xs dark:text-gray-300">{t("حداقل ساعت")}</label>
                                            <DateTimePicker
                                                onChange={setValue}
                                                value={value}
                                                format="HH:mm"
                                                disableClock={true} // مخفی کردن آیکون ساعت
                                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                                isClockOpen={false} // نمایش انتخابگر ساعت
                                            />
                                        </div>
                                        <div class="space-y-1">
                                            <label className="text-xs dark:text-gray-300">{t("حداکثر ساعت")}</label>
                                            <DateTimePicker
                                                onChange={setValue}
                                                value={value}
                                                format="HH:mm"
                                                disableClock={true} // مخفی کردن آیکون ساعت
                                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                                isClockOpen={false} // نمایش انتخابگر ساعت
                                            />
                                        </div>
                                        <div className={"space-y-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("حداقل استراحت")}</label>
                                            <DateTimePicker
                                                onChange={setValue}
                                                value={value}
                                                format="HH:mm"
                                                disableClock={true} // مخفی کردن آیکون ساعت
                                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                                isClockOpen={false} // نمایش انتخابگر ساعت
                                            />
                                        </div>
                                        <div className={"space-y-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("حداکثر استراحت")}</label>
                                            <DateTimePicker
                                                onChange={setValue}
                                                value={value}
                                                format="HH:mm"
                                                disableClock={true} // مخفی کردن آیکون ساعت
                                                clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                                isClockOpen={false} // نمایش انتخابگر ساعت
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2 ${ activeTab === "tab_two" ? "active" : "" }`} id="tab_two">
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("EarlyCheckinTime")}</label>
                                        <InputText className="input w-full" placeholder={t("minute")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("EarlyDepartureTime")}</label>
                                        <DateTimePicker
                                            onChange={setValue}
                                            value={value}
                                            format="HH:mm"
                                            disableClock={true} // مخفی کردن آیکون ساعت
                                            clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                            isClockOpen={false} // نمایش انتخابگر ساعت
                                        />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumPaidLeave")}</label>
                                        <InputText className="input w-full" placeholder={t("Day")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumLeaveWithoutPay")}</label>
                                        <InputText className="input w-full" placeholder={t("Day")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumHourlyLeavePerMonth")}</label>
                                        <DateTimePicker
                                            onChange={setValue}
                                            value={value}
                                            format="HH:mm"
                                            disableClock={true} // مخفی کردن آیکون ساعت
                                            clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                            isClockOpen={false} // نمایش انتخابگر ساعت
                                        />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumSickLeavePerMonth")}</label>
                                        <InputText className="input w-full" placeholder={t("Day")} />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumOvertimePerMonth")}</label>
                                        <DateTimePicker
                                            onChange={setValue}
                                            value={value}
                                            format="HH:mm"
                                            disableClock={true} // مخفی کردن آیکون ساعت
                                            clearIcon={null}    // مخفی کردن آیکون پاک کردن
                                            isClockOpen={false} // نمایش انتخابگر ساعت
                                        />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MaximumMissionPerMonth")}</label>
                                        <InputText className="input w-full" placeholder={t("Day")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2 ${activeTab === "tab_three" ? "active" : ""}`} id="tab_three" >
                            <div class=" grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("PaidShiftOvertimeConditions")}</label>
                                        <Select
                                            placeholder={t("selection")}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("CalculationRateOfOvertimeWithSalary")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("PaidHourlyOvertimeConditions")}</label>
                                        <Select
                                            placeholder={t("selection")}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("HourlyOvertimeCalculationRateWithSalary")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MissionConditions")}</label>
                                        <Select
                                            placeholder={t("selection")}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("MissionCalculationRate")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("ShiftAbsenceConditions")}</label>
                                        <Select
                                            placeholder={t("selection")}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("CalculationRateOfShiftAbsence")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("NightShiftConditions")}</label>
                                        <Select
                                            placeholder={t("selection")}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("NightShiftCalculationRate")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 grid grid-cols-1 md:grid-cols-2 gap-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("LeaveConditions")}</label>
                                        <Select
                                            placeholder={t("selection")}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2  ${activeTab === "tab_four" ? "active" : ""}`} id="tab_four" >
                            <div class=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                                <div className="p-3 post-input dark:bg-themeDarkPostInput post-input-design flex gap-3 items-end">
                                    <div className={"flex gap-1 items-center flex-1 w-full"}>
                                        <div className="number_pluse bg-themeInformation text-white text-center dark:bg-themeDarkInformation mt-auto">
                                            1
                                        </div>
                                        <div className={"flex-1 space-y-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("HolidaysOfTheWeek")}</label>
                                            <Select
                                                placeholder={t("selection")}
                                                menuPosition={"fixed"}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="flex items-end gap-2 justify-end">
                                            <button  type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                                <HPrimeIcon icon={"pi-plus"} />
                                            </button>
                                            <button  type="button" className="button small_button bg-themeDanger text-white">
                                                <HPrimeIcon icon={"pi-minus"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2  space-y-5 ${activeTab === "tab_five" ? "active" : ""}`} id="tab_five" >
                            <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                <div className="flex items-center gap-2">
                                    <label className="text-xs dark:text-gray-300">{t("TypeOfVacation")}</label>
                                    <div class="can-toggle demo-rebrand-1 text-xs w-40">
                                        <input id="swich" type="checkbox"/>
                                        <label htmlFor="swich">
                                            <div class="can-toggle__switch" data-checked={t("OfficialCalendar")} data-unchecked={t("Custom")}></div>
                                        </label>
                                    </div>
                                </div>
                                {/*درصورت انتخاب کشور نمایش داده شود*/}
                                <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3"}>
                                    <div class="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("country")}</label>
                                        <Select
                                            placeholder={t("selection")}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className={"overflow-x-auto grid grid-cols-1 scrollbar p-2 post-input dark:bg-themeDarkPostInput post-input-design"}>
                                <Calendar
                                    multiple
                                    value={dates}
                                    onChange={setDates}
                                    calendar={persian}
                                    locale={persian_fa}
                                    className="p-component dark:bg-themeDarkPostInput"
                                    numberOfMonths={6}
                                    disableMonthPicker
                                    disableYearPicker
                                    shadow={false}
                                />

                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2 space-y-5 ${ activeTab === "tab_six" ? "active" : "" }`} id="tab_six" >
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("DailyWage")}</label>
                                        <InputText className="input w-full" />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("HourlyWage")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("SeverancePay")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("ChildAllowance")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("PersonnelInsuranceContribution")}</label>
                                        <InputText className="input w-full" />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("EmployersInsuranceContribution")}</label>
                                        <InputText className="input w-full" />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("UnemploymentInsuranceContribution")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("SupplementaryInsuranceContribution")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("SalaryTax")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("ManagementFee")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("SupervisorFee")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div class="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("Efficiency")}</label>
                                        <InputText className="input w-full"  />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Accordion  className={"bg-white dark:bg-themeDarkSalt700"}>
                                    <AccordionTab
                                        header={
                                            <div className="flex items-center gap-2">
                                                <HPrimeIcon icon={'pi-user'} />
                                                <p>{t("DefineCustomRules")}</p>
                                            </div>
                                        }
                                    >
                                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                            <div className={"post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2"}>
                                                <div class="space-y-1">
                                                    {/*<label className="text-xs dark:text-gray-300">عنوان</label>*/}
                                                    <InputText className={"input w-full"} placeholder={t("Title")}/>
                                                </div>
                                                <div className={"flex gap-2 items-end"}>
                                                    <div class="space-y-1 flex-1 w-full">
                                                        <InputText className={"input w-full"} placeholder={t("Amount")}/>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div className="flex  gap-2">
                                                            <button  type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                                                <HPrimeIcon icon={"pi-plus"} />
                                                            </button>
                                                            <button  type="button" className="button small_button bg-themeDanger text-white">
                                                                <HPrimeIcon icon={"pi-minus"} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </AccordionTab>
                                </Accordion>

                            </div>
                        </div>
                        <div class={`tab-content__pane box bg-white dark:bg-themeDarkSalt700  p-2  ${activeTab === "tab_seven" ? "active" : ""}`} id="tab_seven" >
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div>
                                        <label htmlFor={"1"} className="flex items-center gap-2 cursor-pointer box bg-white space-y-1 p-2 dark:bg-themeDarkSalt700">
                                            <input type="checkbox" id={"1"}></input>
                                            <h2 className={"text-xs dark:text-gray-300"}>{t("SendingCheckinAndCheckoutTimesForGeneralManager")}</h2>
                                        </label>
                                    </div>
                                    <div className={"flex flex-wrap items-center gap-3"}>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Normal")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Mission")}
                                            </label>
                                        </div>
                                        <div >
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyMission")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("PaidLeave")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyPaidLeave")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("LeaveWithoutPay")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyLeaveWithoutPay")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div>
                                        <label htmlFor={"2"} className="flex items-center gap-2 cursor-pointer box bg-white space-y-1 p-2 dark:bg-themeDarkSalt700">
                                            <input type="checkbox" id={"2"}></input>
                                            <h2 className={"text-xs dark:text-gray-300"}>{t("SendingCheckinAndCheckoutTimesForHumanResourcesManager")}</h2>
                                        </label>
                                    </div>
                                    <div className={"flex flex-wrap items-center gap-3"}>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Normal")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Mission")}
                                            </label>
                                        </div>
                                        <div >
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyMission")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("PaidLeave")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyPaidLeave")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("LeaveWithoutPay")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyLeaveWithoutPay")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div>
                                        <label htmlFor={"3"} className="flex items-center gap-2 cursor-pointer box bg-white space-y-1 p-2 dark:bg-themeDarkSalt700">
                                            <input type="checkbox" id={"3"}></input>
                                            <h2 className={"text-xs dark:text-gray-300"}>{t("SendingCheckinAndCheckoutTimesForProductionManager")}</h2>
                                        </label>
                                    </div>
                                    <div className={"flex flex-wrap items-center gap-3"}>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Normal")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Mission")}
                                            </label>
                                        </div>
                                        <div >
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyMission")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("PaidLeave")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyPaidLeave")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("LeaveWithoutPay")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyLeaveWithoutPay")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div>
                                        <label htmlFor={"4"} className="flex items-center gap-2 cursor-pointer box bg-white space-y-1 p-2 dark:bg-themeDarkSalt700">
                                            <input type="checkbox" id={"4"}></input>
                                            <h2 className={"text-xs dark:text-gray-300"}>{t("SendingCheckinAndCheckoutTimesForShiftManager")}</h2>
                                        </label>
                                    </div>
                                    <div className={"flex flex-wrap items-center gap-3"}>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Normal")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Mission")}
                                            </label>
                                        </div>
                                        <div >
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyMission")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("PaidLeave")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyPaidLeave")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("LeaveWithoutPay")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyLeaveWithoutPay")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div >
                                        <label htmlFor={"5"} className="flex items-center gap-2 cursor-pointer box bg-white space-y-1 p-2 dark:bg-themeDarkSalt700">
                                            <input type="checkbox" id={"5"}></input>
                                            <h2 className={"text-xs dark:text-gray-300"}>{t("SendingCheckinAndCheckoutTimesForDeviceManager")}</h2>
                                        </label>
                                    </div>
                                    <div className={"flex flex-wrap items-center gap-3"}>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Normal")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Mission")}
                                            </label>
                                        </div>
                                        <div >
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyMission")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("PaidLeave")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyPaidLeave")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("LeaveWithoutPay")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyLeaveWithoutPay")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div>
                                        <label htmlFor={"6"} className="flex items-center gap-2 cursor-pointer box bg-white space-y-1 p-2 dark:bg-themeDarkSalt700">
                                            <input type="checkbox" id={"6"}></input>
                                            <h2 className={"text-xs dark:text-gray-300"}>{t("SendingCheckinAndCheckoutTimesForProductionLine")}</h2>
                                        </label>
                                    </div>
                                    <div className={"flex flex-wrap items-center gap-3"}>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Normal")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Mission")}
                                            </label>
                                        </div>
                                        <div >
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyMission")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("PaidLeave")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyPaidLeave")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("LeaveWithoutPay")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyLeaveWithoutPay")}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-2">
                                    <div>
                                        <label htmlFor={"7"} className="flex items-center gap-2 cursor-pointer box bg-white space-y-1 p-2 dark:bg-themeDarkSalt700">
                                            <input type="checkbox" id={"7"}></input>
                                            <h2 className={"text-xs dark:text-gray-300"}>{t("SendingCheckinAndCheckoutTimesForPersonnel")}</h2>
                                        </label>
                                    </div>
                                    <div className={"flex flex-wrap items-center gap-3"}>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Normal")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("Mission")}
                                            </label>
                                        </div>
                                        <div >
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyMission")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("PaidLeave")}
                                            </label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyPaidLeave")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("LeaveWithoutPay")}</label>
                                        </div>
                                        <div>
                                            <label className={"text-xs dark:text-gray-300 flex items-center gap-2 bg-white p-2 box cursor-pointer dark:bg-themeDarkSalt700"}>
                                                <input type="checkbox"></input>
                                                {t("HourlyLeaveWithoutPay")}</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <ButtonSubmitAndCancel
                />
            </div>

        </>

  )
}