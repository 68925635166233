import React, {useEffect, useState} from "react";

import {InputNumber} from "primereact/inputnumber";
import {useTranslation} from "react-i18next";
import {TransactionItem} from "../TransactionItem";
import {useCustomDeposite} from "./useCustomDeposite";
import {InputText} from "primereact/inputtext";
import {LoadingHarmony} from "../../../Loadings/LoadingHarmony";
import {DisplayError} from "../../../Errors/DisplayError";
import {validate} from "../../../../../Services/Globals/validate";
import {depositeSchema} from "./depositeSchema";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {ConvertChargeWallet} from "./depositeService";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {GetSpecialProperty} from "../../../../../Services/AxiosService/JwtService";
import {GridDateFomartter} from "../../../Grid/GridDateFomartter";
import {useDepositeGrid} from "./useDepositeGrid";
import {Pagination} from "../../../Grid/Pagination";
import {PriceFormatterComponent} from "../../../Accounting/PriceFormatterComponent";
import {Tooltip} from "react-tooltip";
import {NoDataHarmony} from "../../../NoDataHarmony";
import {HPrimeIcon} from "../../../UIParts/HPrimeIcon";

export const Deposite=({currentPath,handleActiveTab})=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [errors,setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({users:[],currencies:[]});
    const [IsShowSpinner,setIsShowSpinner] = useState(false);

    const showSpinner=()=>{
      setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }



    const fetchData = async () => {
        try {
            showSpinner();
            const responseCurrency = await GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable');
            if (responseCurrency.status === 200) {
                const currencyData = convertSelectDataHandler(responseCurrency.data);
                setInitialSource({ currencies: currencyData });
            } else {
                handleApiError(responseCurrency, hideSpinner);
                return;
            }

            if (currentPath === '/Profile/Wallet') {
                const responseBalance = await GET(`/Wallets/Balance`, {}, 'single');
                if (responseBalance.status === 200) {
                    const { balance } = responseBalance.data;
                    setWallet({ amount: 0, description: '', walletBalance: balance.amount, currencyType: balance.currency });
                }
                else {
                    handleApiError(responseBalance, hideSpinner);
                }
            } else {
                const responseUser = await GET(`/Users/Selection`, {}, 'IEnumerable');
                if (responseUser.status === 200) {
                    const userData = convertSelectDataHandler(responseUser.data);
                    setInitialSource({ users: userData });
                    const responseBalance = await GET(`/Wallets/${responseUser.data[0].key}/Balance`, {}, 'single');
                    if (responseBalance.status === 200) {
                        const { balance } = responseBalance.data;
                        setWallet({ amount: 0, userId: userData[0], description: '', walletBalance: balance.amount, currencyType: balance.currency });
                    } else {
                        handleApiError(responseBalance, hideSpinner);
                    }
                } else {
                    handleApiError(responseUser, hideSpinner);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[]);





    const ShowTargetUser = () => {
        return (
            <>
                {currentPath === '/UserPanel/Wallet' ? (
                    <h2 className={"text-white dark:text-gray-300"}>{GetSpecialProperty('DisplayName')}</h2>
                ) : (
                    <div className=" gap-2 items-center">
                        <Select
                            options={initialSource.users}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(data) => handleSelectValue(data, 'userId')}
                            placeholder={t("selection")}
                            value={wallet.userId}
                            classNamePrefix={"h_multi_select"}
                            menuPosition={"fixed"}
                        />
                    </div>
                )}
            </>
        );
    };


    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }
    const GetTransactionType=(type)=>{
        return (<>
            {
                type === 1?
                    t('Deposit'):
                    t('Withdrawal')

            }
        </>)
    }
    const GetTransactionIcon=(type)=>{
        return (<>
            {
                type === 1?
                    <div className={"flex-shrink-0 bg-themeSuccess h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-arrow-right"} iconClass="text-white"/>
                    </div>
                    :
                    <div className={"flex-shrink-0 bg-themeDanger h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-arrow-left"} className="text-white"/>
                    </div>
            }

        </>)
    }




    const handleDeposit=async (e)=>{
     e.preventDefault();
     const isValid = validate(depositeSchema,wallet,onSetErrors);
     if(!isValid){
         return
     }
     if(wallet.amount === 0){
         return;
     }
     try {
         if(currentPath === '/UserPanel/Wallet'){
            showSpinner();
           const depositeData = ConvertChargeWallet(wallet);
           const response = await POST('/Wallets/Charge',depositeData,false);
           if(response.status === 200){
               redirectTo('/Profile/InvoiceRequest');
           }
           else{
                handleApiError(response,hideSpinner);
           }
         }
         else{
             showSpinner();
             const depositeData = ConvertChargeWallet(wallet);
             const response = await POST(`/Wallets/${wallet.userId.value}/Charge`,depositeData,false);
             if(response.status === 200){
                 redirectTo('/InvoiceRequest');
             }
             else{
                 handleApiError(response,hideSpinner);
             }
         }
     }
     catch (e) {
         hideSpinner();
         console.log(e);
     }
 }
    const {handleNumberValue,handleValue,setWallet,wallet,handleSelectValue} = useCustomDeposite(setErrors,hideSpinner,showSpinner);

    const {rowData,pagination,getRowHeight,autoGroupColumnDef,defaultColDef,handleNextPage,handlePreviousPage,handleCurrentPage,handlePageSizeCount} = useDepositeGrid(showSpinner,hideSpinner,wallet,currentPath)

    return (
        <>
            <div className="grid grid-cols-12 gap-4">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="col-span-12 lg:col-span-4 p-2 box space-y-2 bg-white dark:bg-themeDarkSalt700">
                    <div class="space-y-5">
                        <div class="space-y-3 ">
                            <div class="Deposit card_bank max-w-sm mx-auto p-5">
                                <div className={"space-y-3"}>
                                    <div className={"flex justify-between items-center gap-5"}>
                                        <div className={"flex-1"}>
                                            {
                                                ShowTargetUser()
                                            }

                                        </div>
                                        <div class="chip flex-shrink-0">
                                            <div class="chip-line"></div>
                                            <div class="chip-line"></div>
                                            <div class="chip-line"></div>
                                            <div class="chip-line"></div>
                                            <div class="chip-main"></div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between ">
                                        <p className={"text-xs text-white dark:text-gray-300"}>{t("Remaining")}</p>
                                        <p className={"text-white text-xs dark:text-gray-300"}>
                                            <PriceFormatterComponent
                                                price={wallet.walletBalance}/> {GetCurrencyType(wallet.currencyType)}
                                        </p>
                                    </div>
                                </div>
                                <div className={"space-y-3 "}>
                                    <div class="card-number">
                                        <div>
                                            <div className={"tooltip_class_SetAmount"}>
                                                {/*<label className="text-xs dark:text-gray-300">{t("Amount")}</label>*/}
                                                <DisplayError message={errors.amount}/>
                                                <InputNumber value={wallet.amount} name={'amount'}
                                                             onChange={(e) => handleNumberValue(e.value, 'amount')}
                                                             placeholder={t("Amount")}

                                                />
                                                <Tooltip anchorSelect={".tooltip_class_SetAmount"} content={t("SetAmount")} positionStrategy={"fixed"}	 />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between">
                                        <p className={"text-xs text-white dark:text-gray-300"}>{t("CreationDate")}</p>
                                        <span className={"text-white dark:text-gray-300 font-light"}>
                                            <GridDateFomartter
                                                date={new Date()} hasTime={true}/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="relative">
                                 <button type={"button"} className="change-money absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
                                       onClick={() => handleActiveTab('withdraw')}>
                                     <HPrimeIcon icon="pi-sort-alt" iconClass={"text-white"} />
                                </button>
                                <div class="h-12 rounded-t-md flex justify-between items-center px-3 py-5 bg-themeInformation  dark:bg-themeDarkInformation">
                                    <div>
                                        <h2 className={"text-white dark:text-gray-300"}>
                                            {t("From")}:
                                            {t("BankAccount")}
                                        </h2>
                                    </div>
                                    <div>
                                        <HPrimeIcon icon={'pi-credit-card'} iconClass="text-white dark:text-gray-300"/>
                                    </div>
                                </div>
                                <div class="h-12 rounded-b-md flex justify-between items-center px-3 py-5 bg-themeSuccess text-white">
                                    <div>
                                        <h2 className={"text-white dark:text-gray-300"}>{t("ToWallet")}</h2>
                                    </div>
                                    <div>
                                        <HPrimeIcon icon={'pi-credit-card'} iconClass="text-white dark:text-gray-300"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <form method={'post'} onSubmit={handleDeposit}>
                        <div className={"space-y-2"}>
                            {/*<div className="space-y-2">*/}
                            {/*    <div className=" space-y-2 post-input dark:bg-themeDarkPostInput rounded-md p-2">*/}
                            {/*        <label className="text-xs dark:text-gray-300">{t("Amount")}</label>*/}
                            {/*        <DisplayError message={errors.amount}/>*/}
                            {/*        <InputNumber  value={wallet.amount} name={'amount'} onChange={(e) =>handleNumberValue(e.value,'amount')}  />*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="space-y-2">
                                <div className="space-y-2 post-input dark:bg-themeDarkPostInput post-input-design p-2">
                                    <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                    <InputText name={'description'} value={wallet.description} onInput={(e)=>handleValue(e)} type="text" className="input w-full" />
                                </div>
                            </div>
                            <div>
                                <button type={'submit'} className="bg-themeInformation dark:bg-themeDarkInformation text-white button w-full flex items-center justify-center gap-2 ">
                                    <HPrimeIcon icon={'pi-credit-card'} />
                                    {t('IncreaseBalance')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="col-span-12 lg:col-span-8 bg-white dark:bg-themeDarkSalt700 box flex flex-col justify-between p-2">
                    {
                        rowData.length===0 ?
                            <NoDataHarmony/>
                            :
                    <div className={"space-y-3"}>
                        <div className={"p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                            <h2 className={"text-xs dark:text-gray-300"}>{t("TodaysDeposits")}</h2>
                        </div>
                        <div className={"px-2"}>
                            <div className={"grid grid-cols-1 md:grid-cols-3 lg:grid-cols-2 gap-5"}>
                                {rowData.length > 0 ? rowData.map((transaction, index) => (
                                    <TransactionItem key={index} GetTransactionType={GetTransactionType} GetTransactionIcon={GetTransactionIcon}  GetCurrencyType={GetCurrencyType} transaction={transaction} />
                                )):''}
                            </div>
                        </div>
                    </div>

                    }
                    {
                        rowData.length !==0 ?
                            <div className={"p-2"}>
                                <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                            </div>
                            :
                            null
                    }

                </div>

            </div>


        </>
    )
}