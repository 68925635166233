import React from "react";
import {InputText} from "primereact/inputtext";
import Select from "react-select";

import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const Link=({row,handleAddRow,handleChange,handleRemoveRow,index})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 grid grid-cols-1 md:grid-cols-3 gap-2">
                <div className={"flex gap-1"}>
                    <div className="mt-auto">
                        <span className={"number_pluse text-white"}>
                                  {index + 1}
                        </span>
                    </div>
                    <div className={"flex-1 space-y-1"}>
                        <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                        <InputText
                            type="text"
                            className="input w-full"
                            value={row.title}
                            onChange={(e) => handleChange(row.fakeId, 'title', e.target.value,row.documentLinkId)}
                        />
                    </div>
                </div>
                <div className={"space-y-1"}>
                    <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                    <InputText
                        type="text"
                        className="input w-full"
                        placeholder="https://example.com"
                        value={row.url}
                        onChange={(e) => handleChange(row.fakeId, 'url', e.target.value,row.documentLinkId)}
                    />
                </div>
                <div>
                    <div className={"flex gap-1"}>
                        <div className={"space-y-1 flex-1"}>
                            <label className="text-xs dark:text-gray-300">{t("Explanation")}</label>
                            <InputText
                                type="text"
                                className="input w-full"
                                value={row.description}
                                onChange={(e) => handleChange(row.fakeId, 'description', e.target.value,row.documentLinkId)}
                            />
                        </div>
                        <div className="mt-auto flex gap-2">
                            <button type="button" className="button bg-themeInformation dark:bg-themeDarkInformation text-white small_button " onClick={handleAddRow}>
                                <HPrimeIcon
                                    icon={'pi-plus'}
                                />
                            </button>
                            <button type="button" className="button bg-themeDanger text-white small_button " onClick={() => handleRemoveRow(row.fakeId,row.documentLinkId)}>
                                <HPrimeIcon
                                    icon={'pi-minus'}
                                />
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}