import React, {useEffect, useState} from "react";

import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";


export const  AddFilter =(props) => {

    const { t } = useTranslation();
    const [filter,setFilter] = useState({table:0,title:null})
    const [filterItems,setFilterItems] = useState([]);
    const [result,setResult] = useState(0);
    const [options,setOptions] = useState({tables:[],operations:[],previousOperations:[]});
    const [isDefault,setIsDefault]= useState(false);

    const handleFetchData=()=>{

    }

    const handleAddRow=()=>{
        const newRow = {
            id: new Date().getTime(),
            table: options.tables[0],
            operation:options.operations[0],
            previousOperation:options.previousOperations[0],
            field:null,
            value: null,
            order:0,
            filterId:null
        };
        setIsDefault(false);
        setFilterItems(prevList => [...prevList, newRow]);
    }
    const handleRemoveRow = (id,filterId) => {
        if(filterItems.length === 1){
            const newRow = {
                id: new Date().getTime(),
                table: options.tables[0],
                operation:options.operations[0],
                previousOperation:options.previousOperations[0],
                field:null,
                value: null,
                order:0,
                filterId:null
            };
            setIsDefault(true);
            setFilterItems(prevList => [newRow]);
            return;
        }
        setFilterItems((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.filterId === filterId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleChange = (id, field, value, filterId) => {
        setFilterItems((prevRelations) => {
            const updatedRelations = prevRelations.map((meta) => {
                if (
                    (filterId && meta.filterId === filterId) || (!filterId && id && meta.id === id)
                ) {
                    return { ...meta, [field]: value };
                } else {
                    return meta;
                }
            });
            return updatedRelations;
        });
        setIsDefault(false);
    };



    useEffect(()=>{
        handleFetchData();
    },[result])

    useEffect(() => {
        if (filterItems.length === 0) {
            handleAddRow();
        }
    }, [filterItems]);








    return(
      <>
          <div className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                  <div class="space-y-1">
                      <label className="text-xs dark:text-gray-300">{t("FilterTitle")}</label>
                      <div>
                          <InputText  className="input w-full" />
                      </div>
                  </div>
                  <div class="space-y-1">
                      <label className="text-xs dark:text-gray-300">{t("Part")}</label>
                      <div>
                          <Select
                              classNamePrefix={"h_multi_select"}
                              placeholder={t("selection")}
                          />
                      </div>
                  </div>
              </div>
              <div className="box bg-white dark:bg-themeDarkPostInput p-2 space-y-3">
                  {filterItems.map((row,index) => (
                      <div className="post-input dark:bg-themeDarkPostInput rounded-md p-2 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-2">
                          <div className="flex gap-1">
                              <div className={"mt-auto"}>
                                  <span className="number_pluse text-white">{index}</span>
                              </div>
                              <div class="space-y-2 flex-1">
                                  <label className="text-xs dark:text-gray-300">{t("Operator")}</label>
                                  <Select placeholder={t("Operator")}  menuPosition={'fixed'} classNamePrefix={"h_multi_select"} />
                              </div>
                          </div>
                          <div class="space-y-2 ">
                              <label className="text-xs dark:text-gray-300">{t("Table")}</label>
                              <Select placeholder={t("selection")} menuPosition={'fixed'} classNamePrefix={"h_multi_select"} />
                          </div>
                          <div class="space-y-2 ">
                              <label className="text-xs dark:text-gray-300">{t("Field")}</label>
                              <Select placeholder={t("selection")} menuPosition={'fixed'} classNamePrefix={"h_multi_select"} />
                          </div>
                          <div class="space-y-2 ">
                              <label className="text-xs dark:text-gray-300">{t("Condition")}</label>
                              <Select placeholder={t("selection")} menuPosition={'fixed'} classNamePrefix={"h_multi_select"} />
                          </div>
                          <div className="flex gap-1">
                              <div className="space-y-2 flex-1">
                                  <label className="text-xs dark:text-gray-300">{t("Value")}</label>
                                  <InputText className="input w-full" />
                              </div>
                              <div className={"mt-auto flex gap-2"}>
                                  <button onClick={()=>handleAddRow()} type="button" className="button small_button bg-themeInformation text-white dark:bg-themeDarkInformation">
                                      <HPrimeIcon icon="pi-plus" />
                                  </button>
                                  <button onClick={()=>handleRemoveRow(row.id,row.filterId)} type="button" className="button small_button bg-themeDanger text-white">
                                      <HPrimeIcon icon="pi-minus" />
                                  </button>
                              </div>
                          </div>

                      </div>
                  ))}

              </div>
          </div>

  </>)
}