import React, {useState} from "react";

import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandlePublicOnlineSupport = () => {
  const { t } = useTranslation();


  return(
      <>
        <div class="space-y-5">
          <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>


                          </>
                        }
                        childrenTwo={
                          <>

                          </>
                        }
            />

          </div>
          <div className={"grid grid-cols-1"}>
            <div className=" chat__box overflow-hidden  post-input post-input-design dark:bg-themeDarkSalt700 ">
              <div className=" flex h-full flex-col justify-between">
                <div className="border-b-2 p-2 dark:border-themeDarkColorLine ">
                  <div className={"post-input flex justify-between gap-2 overflow-x-auto rounded-md p-2 shadow-xl dark:bg-themeDarkPostInput"}>
                    <div class="overflow-x-auto scrollbar  px-1 rounded-md  w-full">
                      <div class="flex gap-3">
                        <a href="#" className="w-10 space-y-2">
                          <div class="w-10 h-10 flex-none rounded-full relative border shadow">
                            <img className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                            <div class="w-3 h-3 bg-themeNotif absolute end-0 bottom-0 rounded-full border-2 border-white"></div>
                          </div>
                          <div class="text-xs text-gray-600 truncate text-center dark:text-gray-300">مسعود</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"relative overflow-hidden"}>
                    <div class="online_support chat-user flex flex-col gap-y-8 overflow-y-scroll overflow-x-hidden p-2 duration-500 ">
                      <div id={"#"} className="send-message">
                        <div className={"space-y-2"}>
                          <div class="chat__box__text-box flex">
                            <div class="m-2 my-auto hidden h-10 w-10 flex-none">
                              <img className="h-full w-full rounded-full border object-cover shadow-xl" src="/Assets/Image/Avatar/man.svg"/>
                            </div>
                            <div class="post-input send__box__message relative box-border post-input-design dark:bg-themeDarkSalt700">
                              <div className={"space-y-2 p-2"}>
                               hi
                                <div>
                                  <p className="text-xs leading-7 text-gray-700 dark:text-gray-300">123</p>
                                </div>

                                <div className={"w-max"}>
                                        <button  type="button" className={`button rounded-full bg-themeInformation dark:bg-themeDarkInformation tooltip_class_1`}>
                                          <HPrimeIcon icon={"paperclip"} size={16} className={"text-white"}/>
                                          <Tooltip anchorSelect={`.tooltip_class_1`} content={"1"} />
                                        </button>
                                      </div>

                              </div>
                            </div>
                            <div className={"reply-send-message m-2 my-auto tooltip_class_reply"}>
                              <a href={'#reply'}  className=" arrow-reply-message size-7 block"></a>
                              <Tooltip anchorSelect={".tooltip_class_reply"} content={t("Replytothismessage")} />
                            </div>
                          </div>
                          <span className={"send-time-message block w-full text-black"}>{<GridDateFomartter hasTime={true} />}</span>
                        </div>
                      </div>
                      <div className="received-message">
                        <div className={"space-y-2"}>
                          <div class="chat__box__text-box flex">
                            <div className={"received-reply-send-message tooltip_class_received m-2 my-auto"}>
                              <a href={'#reply'}  className="block arrow-reply-message size-7"></a>
                              <Tooltip anchorSelect={".tooltip_class_received"} content={t("Replytothismessage")} />
                            </div>
                            <div class="post-input received__box__message relative box-border border shadow-xl  dark:border-themeDarkColorLine dark:bg-themeDarkSalt700">

                              <div className={"space-y-2 p-2"}>
                                12121
                                <div className="">
                                  <p className="text-xs leading-7 text-gray-700 dark:text-gray-300">saaS</p>
                                </div>
                                      <div className={"w-max "}>
                                        <button  type="button" className="button rounded-full bg-themeInformation dark:bg-themeDarkInformation">
                                          <HPrimeIcon icon={"paperclip"} size={16} className={"text-white"}/>
                                          <Tooltip anchorSelect={`.tooltip_class_2`} content={"1"} />
                                        </button>
                                      </div>
                              </div>
                            </div>
                            <div class="m-2 my-auto hidden h-10 w-10 flex-none sm:block">
                              <img className="h-full w-full rounded-full border object-cover shadow-xl shadow-black" src="/Assets/Image/Avatar/man.svg"/>
                            </div>
                          </div>
                          <span className={"received-time-message text-black dark:text-gray-300 block w-full"}>{<GridDateFomartter hasTime={true} />}</span>
                        </div>

                      </div>
                    </div>
                  <div id={'reply'} className={"reply-all-message show-reply items-center bg-white dark:bg-themeDarkSalt700  flex px-2 gap-2"}>
                    <button type={"button"} className={"flex-shrink-0 "}>
                      <HPrimeIcon icon={"x"} className={"text-themeInformation dark:text-themeDarkInformation"} size={20}/>
                    </button>
                    <div className={"flex-1"}>
                      <a href={"#"} className=" block reply-message rounded-e border-s-2 border-themeInformation bg-themeReplayMessageSend p-2">
                        <p className="line-clamp-1 text-xs">65665</p>
                      </a>
                      <a href={"#"} className={"reply-message block rounded-s border-e-2 border-themeInformation bg-themeReplayMessageReceived p-2"}></a>
                    </div>
                  </div>
                </div>
                <div class=" border-t-2  p-2 ">
                  <div className={"flex flex-col items-center gap-5 rounded-md bg-white dark:bg-themeDarkSalt700  p-2 md:flex-row"}>
                    <div className={"w-full flex-1"}>

                      <textarea
                          rows={3}
                          name={"message"}
                          cols={30}
                          className={"input w-full text-xs"}
                          placeholder={t("Enteryourmessage")}
                      />
                    </div>

                    <div className={"flex items-center gap-3"}>
                      <label className="cursor-pointer block relative  " htmlFor={'file'}>
                        <HPrimeIcon icon={'paperclip'}  className={"text-themeInformation dark:text-themeDarkInformation"}/>
                        <input type="file"  accept=".jpg, .jpeg, .png, .zip, .rar" multiple={true}  className="hidden" id={'file'}/>
                      </label>
                      <button className={"button rounded-full  bg-themeSuccess p-2 text-white dark:bg-themeDarkInformation"}>
                        <HPrimeIcon icon={"send"}/>
                      </button>
                    </div>

                  </div>
                  {/*<textarea onInput={(e)=> handleValue(e)} name={'message'} value={ticket.message} className="chat__box__input input w-full h-16 resize-none border-transparent post-input px-5 py-3 focus:shadow-none" rows="1" placeholder="پیام خود را وارد کنید..."></textarea>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}