

export const convertAddReminder =(obj,userId)=>{
    const newObj = {
        description:obj.description === null || obj.description === undefined?null:obj.description,
        title:obj.title === null || obj.title === undefined ?null:obj.title,
        message:obj.message === null || obj.message === undefined?null:obj.message,
        reminderDateUtc:obj.reminderDateUtc === null || obj.reminderDateUtc === undefined ? null:obj.reminderDateUtc,
        type:obj.type === null || obj.type === undefined ? null : obj.type.value,
        userId:userId,
        enSendingStates: obj.enSendingStates !== null?obj.enSendingStates.map(item => item.value):[],
    }
    return newObj;
}

export const convertEditReminder = (obj,userId)=>{
    const newObj = {
        id:obj.id,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        title:obj.title === null || obj.title === undefined ?null:obj.title,
        message:obj.message === null || obj.message === undefined?null:obj.message,
        reminderDateUtc:obj.reminderDateUtc === null || obj.reminderDateUtc === undefined ? null:obj.reminderDateUtc,
        type:obj.type === null || obj.type === undefined ? null : obj.type.value,
        userId:userId,
        enSendingStates: obj.enSendingStates !== null ?obj.enSendingStates.map(item => item.value):[],
    }
    return newObj;
}

