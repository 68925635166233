import React, {useState} from "react";
import {IsValidUrlPattern, validDomainPattern} from "../../../../Services/Globals/Public/Regexes";
import {handleFailureMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useTranslation} from "react-i18next";

export const useCustomLink=()=>{
    const { t } = useTranslation();
    const [link,setLink] = useState([]);
    const handleAddRow = () => {
        const newRow = {
            fakeId: new Date().getTime(),
            documentLinkId: null, // Set bankId to null initially
            title: null,
            url: null,
            description: null,
            isDefault:false
        };
        setLink((prevList) => [...prevList, newRow]);
    };

    const handleRemoveRow = (fakeId, documentLinkId) => {
        if (link.length === 1) {
            const newRow = {
                fakeId: new Date().getTime(),
                documentLinkId: null, // Set bankId to null initially
                title: '',
                url: '',
                description: '',
                isDefault:true
            };
            setLink((prevList) => [newRow]);
            return;
        }
        setLink((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.fakeId === fakeId && row.documentLinkId === documentLinkId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };


    const handleChange = (fakeId, field, value, documentLinkId) => {
        setLink((prevList) => {
            const updatedList = prevList.map((row) => {
                if ((documentLinkId && row.documentLinkId === documentLinkId) || (!documentLinkId && fakeId && row.fakeId === fakeId)) {
                    // If the current row is being updated
                    row.isDefault = false;
                    return { ...row, [field]: value};
                }
                // If it's not the current row, reset isDefault to false
                //return { ...row, isDefault: false };
                return { ...row };
            });

            return updatedList;
        });
    };

    const handleInitialLinkData=()=>{
        setLink([{ fakeId: new Date().getTime(), documentLinkId: null, title: null, url: null, description: null,isDefault:true}])
    }


    const ValidateUrls = (links) => {
        if (links.some((item) => !item.isDefault && (!item.url || !item.title || item.url.trim() === '' || item.title.trim() === ''))) {
            handleFailureMessage(t(""));
            return false; // Return false if any url or title is missing or empty and isDefault is false
        }

        if (links.some((item) => !item.isDefault)) {
            const Urls = links.map((item) => {
                if (!item.isDefault) {
                    return IsValidUrlPattern.test(item.url); // Validate URL format
                }
                return true; // If isDefault is true, consider URL valid
            });
            const validationCount = Urls.filter((item) => item === false).length;
            if (validationCount > 0) {
                handleFailureMessage(t("Enteravaliddomainname"));
                return false;
            }
        }

        return true;
    }
    return {
        handleAddRow,
        handleChange,
        handleRemoveRow,
        link,
        setLink,
        handleInitialLinkData,
        ValidateUrls
    }
}