import React from "react";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const CheckBoxElement = ({ item, index, handleChangeData }) => {

    // Function to handle checkbox state change
    const handleCheckboxChange = (value) => {
        // Check if the value is already selected
        const isChecked = item.data.items.some(itemValue => itemValue.value === value);
        let updatedItems;

        // Update the list of selected items based on the current state
        if (isChecked) {
            updatedItems = item.data.items.filter((itemValue) => itemValue.value !== value);
        } else {
            updatedItems = [...item.data.items, { value, label: value, parentId: null, order: item.properties.items.find(item => item.value === value).order }];
        }

        // Call the parent handler with the updated items
        handleChangeData(index, "items", updatedItems);
    };

    return (
        <div key={index} className={`${item.properties.size !== null ? item.properties.size.className : ''}`}>
            <h2 className="harmony-text-xs">
                {item.properties.caption}
            </h2>
            {
                item.properties.isRequired === true?
                    <RequiredIcon/>
                    :null
            }
            <div className="harmony-grid harmony-grid-cols-2 harmony-md-grid-cols-4 harmony-gap-2">
                {item.properties.items.map((checkboxItem, checkboxIndex) => (
                    <label
                        key={checkboxIndex}
                        htmlFor={`checkbox-${index}-${checkboxIndex}`}
                        className="harmony-flex harmony-items-center harmony-gap-2 harmony-text-xs dark:text-gray-300 harmony-cursor-pointer"
                    >
                        <input
                            type="checkbox"
                            id={`checkbox-${index}-${checkboxIndex}`}
                            value={checkboxItem.value}
                            checked={item.data.items.some(itemValue => itemValue.value === checkboxItem.value)}
                            onChange={() => handleCheckboxChange(checkboxItem.value)}
                            placeholder={item?.properties?.description}
                            readOnly={item.properties.isReadOnly}
                            disabled={item.properties.isReadOnly}
                            name={item.properties.name}
                        />
                        <h2>{checkboxItem.label}</h2>
                    </label>
                ))}
            </div>
        </div>
    );
}
