import { createSlice } from '@reduxjs/toolkit';

const settingSlice = createSlice({
    name: 'setting',
    initialState: {
        selectedTheme: false,
        calendarType: null,  // Default calendar type
        calendarLanguage: null,     // Default language
        calendarView: null  ,     // Default view
        dashboardType:null,
        editorType:null,
        fontSize:''
    },
    reducers: {
        setSelectedTheme: (state, action) => {
            state.selectedTheme = action.payload;
        },
        setCalendarType: (state, action) => {
            state.calendarType = action.payload;
        },
        setCalendarLanguage: (state, action) => {
            state.calendarLanguage = action.payload;
        },
        setCalendarView: (state, action) => {
            state.calendarView = action.payload;
        },
        setDashboardType: (state, action) => {
            state.dashboardType = action.payload;
        },
        setFontSize: (state, action) => {
            state.fontSize = action.payload;
        },
        setEditorType: (state, action) => {
            state.editorType = action.payload;
        }
    },
});

export const {
    setSelectedTheme,
    setCalendarType,
    setCalendarLanguage,
    setCalendarView,
    setDashboardType,
    setFontSize,
    setEditorType
} = settingSlice.actions;

export default settingSlice.reducer;
