import React from "react";
import {toast} from "react-toastify";
import {toasts} from "../Public/AppSetting";
export const handleSuccessMessage = (successMessage)=>{
    toast.success(successMessage, {
        position: toasts.position,
        autoClose:toasts.notifySuccess,
        hideProgressBar: toasts.hideProgressBar,
        closeOnClick:toasts.closeOnClick,
        pauseOnHover:toasts.pauseOnHover,
        draggable:toasts.draggable,
        progress:toasts.progress,
        theme:toasts.theme,
        rtl:toasts.rtl

    });
}
export const handleFailureMessage = (errMessage)=>{
    toast.error(errMessage, {
        position:toasts.position,
        autoClose:toasts.notifyError,
        hideProgressBar:toasts.hideProgressBar,
        closeOnClick: toasts.closeOnClick,
        pauseOnHover:toasts.pauseOnHover,
        draggable:toasts.draggable,
        progress:toasts.progress,
        theme:toasts.theme,
        rtl:toasts.rtl
    });
}
export const handleWarningMessage = (warningMessage)=>{
    toast.warn(warningMessage, {
        position:toasts.position,
        autoClose:toasts.notifyWarning,
        hideProgressBar:toasts.hideProgressBar,
        closeOnClick:toasts.closeOnClick,
        pauseOnHover:toasts.pauseOnHover,
        draggable:toasts.draggable,
        progress:toasts.progress,
        theme:toasts.theme,
        rtl:toasts.rtl
    });
}

