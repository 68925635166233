import {useTranslation} from "react-i18next";

import {InputText} from "primereact/inputtext";
import React from "react";
import {DateTimePicker} from "../../../../Commans/Dates/DateTimePicker";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const Domains=({item,index,handleAddRow,handleDeleteRow,handleChange,handleChangeDateTime})=>{

    const { t } = useTranslation();

    return (<>

        <div  key={index}>
            <div className="p-3 post-input post-input-design dark:bg-themeDarkPostInput grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 items-end">
                <div className={"flex gap-1 items-center"}>
                    <div className="number_pluse bg-themeInformation text-white text-center dark:bg-themeDarkInformation mt-auto">
                        {index+1}
                    </div>
                    <div className={"flex-1 space-y-1"}>
                        <label className="text-xs dark:text-gray-300">{t("Domain")}</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <HPrimeIconSmall icon={"pi-server"}/>
                            </span>
                            <InputText onChange={(e) => handleChange(item.fakeId,'domain',e.target.value,item.id)}
                                       className="input w-full"
                                       value={item.domain}
                            />
                        </div>
                    </div>
                </div>
                <div className={"space-y-1"}>
                    <label className="text-xs dark:text-gray-300">{t("ExpirationDate")}</label>
                    <DateTimePicker
                        handleDateTime={(value) => handleChangeDateTime(value,item.fakeId,'expirationDate',item.id)}
                        fakeId={item.fakeId}
                        realId={item.id}
                        name={'expirationDate'}
                        format={'YYYY/MM/DD'}
                        value={item.expirationDate}
                    />
                </div>
                <div className={"flex gap-2 flex-col md:flex-row md:col-span-2"}>
                    <div className="flex-1 flex flex-col sm:flex-row gap-2 items-center">
                        <div className="space-y-2 ">
                            <div className="flex  gap-2">
                                <div className="can-toggle demo-rebrand-1 text-xs w-28">
                                    <input id={`swich1_${item.id === null?item.fakeId:item.id}`}
                                           onChange={(e) => handleChange(item.fakeId,'protocol',e.target.checked ? 1 : 0,item.id)}
                                           type="checkbox"
                                           checked={item.protocol}
                                    />
                                    <label htmlFor={`swich1_${item.id === null?item.fakeId:item.id}`}>
                                        <div class="can-toggle__switch" data-checked="https" data-unchecked="http"></div>
                                    </label>
                                </div>
                                <div className="can-toggle demo-rebrand-1 text-xs w-36">
                                    <input id={`swich3_${item.id === null?item.fakeId:item.id}`}
                                           onChange={(e) => handleChange(item.fakeId, 'domainServer', e.target.checked ? 1 : 0,item.id)}
                                           type="checkbox"
                                           checked={item.domainServer}
                                    />
                                    <label htmlFor={`swich3_${item.id === null?item.fakeId:item.id}`}>
                                        <div class="can-toggle__switch" data-checked={t("Internal")} data-unchecked={t("External")}></div>
                                    </label>
                                </div>
                            </div>
                            <div className="flex  gap-2 ">
                                <div className="can-toggle demo-rebrand-1 text-xs w-28">
                                    <input id={`swich2_${item.id === null?item.fakeId:item.id}`}
                                           onChange={(e) => handleChange(item.fakeId, 'cdn', e.target.checked,item.id)}
                                           type="checkbox"
                                           checked={item.cdn}
                                    />
                                    <label htmlFor={`swich2_${item.id === null?item.fakeId:item.id}`}>
                                        <div class="can-toggle__switch" data-checked="CDN" data-unchecked="Simple"></div>
                                    </label>
                                </div>
                                <div className="can-toggle demo-rebrand-1 text-xs w-36">
                                    <input id={`swich4_${item.id === null?item.fakeId:item.id}`} onChange={(e) => handleChange(item.fakeId, 'compression', e.target.checked,item.id)}
                                           type="checkbox"
                                           checked={item.compression}
                                    />
                                    <label htmlFor={`swich4_${item.id === null?item.fakeId:item.id}`}>
                                        <div class="can-toggle__switch" data-checked={t("compress")} data-unchecked={t("Simple")}></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <label className="text-xs dark:text-white">{t("isActive")}</label>
                            <div>
                                <input checked={item.isMaster} className="input input--switch border bg-white" onChange={(e) => handleChange(item.fakeId, 'isMaster', e.target.checked,item.id)} type="checkbox"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex-shrink-0">
                        <div className="flex items-end gap-3 justify-end">
                            <button onClick={() => handleAddRow()} type="button" className="button bg-themeInformation dark:bg-themeDarkInformation text-white small_button">
                                <HPrimeIcon
                                icon={"pi-plus"}
                                />
                            </button>
                            <button onClick={() => handleDeleteRow(item.fakeId,item.id)} type="button" className="button bg-themeDanger text-white small_button">
                                <HPrimeIcon
                                    icon={"pi-minus"}
                                />
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
    )
}