import React, {useEffect, useMemo, useRef, useState} from "react";
import {ListSearch} from "../../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ButtonSearchList} from "../../../../Commans/UIParts/ButtonSearchList";
import {GridTable} from "../../../../Commans/Grid/GridTable";
import {useTranslation} from "react-i18next";
import {useBackGrid} from "./useBackGrid";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../../Commans/Grid/Pagination";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {useRecovery} from "./useRecovery";
import {handleFailureMessage, handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {handleCreateRecovery} from "./RecoveryService";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {BackupFiles} from "./BackUpFiles";
import {ProgressLoading} from "../../../../Commans/Loadings/ProgressLoading";
import HarmonyProgressBar from "../../../../Commans/HarmonyProgressBar";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import Select from "react-select";

export const BackupGridTab=()=>{
    const { t } = useTranslation();
    const [IsShowSpinner,setShowSpinner] = useState(false);
    const [IsLoading,setIsLoading]=useState(false);
    const [refresh,setRefresh]=useState(false);
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef(null);
    const [initialSource,setInitialSource]=useState({backupSections:[]})

    const {handleFileChange,contentRef,productRef,userRef,handleRemoveFiles,setRecovery,recovery,handleRemove, handleSelectValue}=useRecovery()
    const showSpinner=()=>{
        setShowSpinner(true)
    }
    const hideSpinner=()=>{
        setShowSpinner(false)
    }
    const showLoading=()=>{
        setIsLoading(true)
    }
    const hideLoading=()=>{
        setIsLoading(false)
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }
    const handleInitialData=(sections)=>{
        const sectionData = convertSelectDataHandler(sections);
        const filteredData = sectionData.filter(item => item.value >= 3);
        setInitialSource({
            backupSections: filteredData
        })
        return {filteredData}
    }

    const fetchData=async()=>{
        try {
            showSpinner()
            const responseSection = await GET(`/Enumerations/EnWebsiteFilesSections`,{},'IEnumerable');
            if(responseSection.status === 200 ){
                const {filteredData}=handleInitialData(responseSection.data);
                setRecovery(prevState => ({...prevState,backupSection: filteredData[0]}))
            }
            else{
                handleApiError(responseSection,hideSpinner);
            }
            hideSpinner()
        }
        catch (e) {
            hideSpinner()
        }
    }

    useEffect(()=>{
        fetchData();
    },[])








    const updateProgress = () => {
        setProgress((prev) => {
            const newProgress = prev + 10;
            if (newProgress >= 100) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            return Math.min(newProgress, 100); // Ensure progress doesn't exceed 100
        });
    };

    const handleSubmitRecovery=async(e)=>{
        e.preventDefault();
        try {
            if (recovery.contents.length === 0 && recovery.products.length === 0 && recovery.users.length === 0) {
                handleFailureMessage(t("PleaseSelectFile"));
                return;
            }
            showLoading();
            setProgress(1);

            intervalRef.current = setInterval(updateProgress, 1000);

            const formData =  handleCreateRecovery(recovery);
            const response = await POST(`/Settings/RestoreData`,formData,true);
            if(response.status === 200){
                Refresh();
                handleSuccessMessage(t('SuccessDone'));
                setRecovery((prevState)=>({...prevState,products: [],contents: [],users: []}))
                if (productRef && productRef.current) {
                    productRef.current.value = null;
                }
                if (contentRef && contentRef.current) {
                    contentRef.current.value = null;
                }
                if (userRef && userRef.current) {
                    userRef.current.value = null;
                }
                hideLoading();
            }
            else{
                handleApiError(response,hideLoading);
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }catch (e) {
            hideLoading();
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }





    const backupFileConfigs = [
        {
            ref: productRef,
            handleFileChange,
            handleRemove,
            recovery,
            handleRemoveFiles,
            contentType: 'products',
            label:'SelectionProducts',
            t:t,
            section:3

        },
        {
            ref: contentRef,
            handleFileChange,
            handleRemove,
            recovery,
            handleRemoveFiles,
            contentType: 'contents',
            label:'ContentSelection',
            t:t,
            section:4
        },
        {
            ref: userRef,
            handleFileChange,
            handleRemove,
            recovery,
            handleRemoveFiles,
            contentType: 'users',
            label:'ChoiceUsers',
            t:t,
            section:5
        }
    ];



    return (
        <>

        <div className="space-y-5">
            <div className={"relative"}>
                {
                    IsLoading?
                        <ProgressLoading initialProgress={progress}/>:
                        null
                }
                <form onSubmit={handleSubmitRecovery} method={'post'} className={"space-y-5"}>
                    <div className={"space-y-2 post-input dark:bg-themeDarkPostInput post-input-design p-2"}>
                        <div class={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4"}>
                            <div class={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>نوع فایل</label>
                                <Select
                                    options={initialSource.backupSections}
                                    isMulti={false}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectValue(data,'backupSection')}
                                    name={'backupSection'}
                                    placeholder={t("selection")}
                                    value={recovery.backupSection}
                                    className={"select-group"}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={'fixed'}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"space-y-2"}>
                        <div >
                            {backupFileConfigs.map((config, index) => (
                                recovery.backupSection !== null && config.section === recovery.backupSection.value && ( // Use && for conditional rendering
                                    <BackupFiles key={index} {...config} />
                                )
                            ))}
                        </div>

                    </div>
                    <div className={"flex justify-end"}>
                        <button className="button bg-themeInformation dark:bg-themeDarkInformation w-32 text-white ">{t("Recovery")}</button>
                    </div>
                </form>
            </div>
        </div>

        <ToastContainer/>
    </>)
}