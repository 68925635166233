import React from "react";
import {useSelector} from "react-redux";
import persian_fa from "react-date-object/locales/persian_fa";
import arabic_ar from "react-date-object/locales/arabic_ar";
import arabic from "react-date-object/calendars/arabic";
import persian from "react-date-object/calendars/persian";

import indian from "react-date-object/calendars/indian"
import indian_hi from "react-date-object/locales/indian_hi"
import {useDynamicDispatch} from "../../../Services/Redux/useDynamicaDispatch";

export const useDateTimePicker=()=>{

    const {handleGetReduxDispatch}=useDynamicDispatch();
    const handleCalendarView=()=>{
       const calendarView =  handleGetReduxDispatch('setting','calendarView')
        switch (calendarView){
            case 0:
                return false
            case 1 :
                return true
            case 2:
                return true
        }
    }

    const handleCalendarLanguage=()=>{
        const calendarLanguage =  handleGetReduxDispatch('setting','calendarLanguage')
        console.log(calendarLanguage);
        switch (calendarLanguage){
            case 0:
                return null
            case 1 :
                return arabic_ar
            case 2:
                return indian_hi
            case 3 :
                return  persian_fa
        }
    }
    const handleCalendarType=()=>{
        const calendarType =  handleGetReduxDispatch('setting','calendarType')
        console.log(calendarType);
        switch (calendarType) {
            case 0:
                return persian
            case 1 :
                return arabic
            case 2:
                return null
            case 3:
                return indian
        }
    }

    return {
        handleCalendarLanguage,
        handleCalendarType,
        handleCalendarView
    }
}