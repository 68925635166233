import React, {useState} from "react";

export const useProductTransport=(setErrors)=>{
    const [transport,setTransport]=useState({
        title:null,
        description:null,
        transportType:false,
        fixedAmount:0,
        id:null,
        calculationType:null,
        shippingMethod:null
    });
    const handleValue = (e) => {
        const { name, value } = e.target;
        setTransport((prevState) => ({ ...prevState, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
    };
    const handleNumberValue = (value,name)=>{
        setTransport({...transport,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleApplyTransport=(name,value)=>{
        setTransport({...transport,[name]:value});
    }
    const handleSelectValue=(data,name)=>{
        setTransport({ ...transport, [name]: data });
    }

    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency)
        {
            return '-'
        }
        return currency.label;
    }

    return {
        handleValue,
        handleNumberValue,
        transport,
        setTransport,
        handleApplyTransport,
        handleGetCurrencyType,
        handleSelectValue
    }
}