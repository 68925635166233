import React from "react";
import moment from "moment-jalaali";
import {TimeSetting} from "../../../Services/Globals/Public/AppSetting";

    export const GridDateFomartter = (props)=>{
        const handleGetDateFormat=(props)=>{
            if(props.format){
                return <>
                    {
                        props.date === null
                            ?'* * * * *':
                            <>{moment(props.date).format(props.format)}</>
                    }
                </>
            }
            else{
                return <>
                    {
                        props.date === null
                            ?'* * * * *':
                            <>{moment(props.date).format(props.hasTime?TimeSetting.dateTimeFormat:TimeSetting.dateFormat)}</>
                    }
                </>
            }
        }
        return (
            <>

                {
                    handleGetDateFormat(props)
                }

            </>
        )
    }