import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {DELETE, GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";

import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {GetSpecialRole} from "../../../Services/AxiosService/JwtService";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const useGrid=()=>{
    const [spinner,setSpinner] = useState(false);
    const {t} = useTranslation();
    const redirectTo = useNavigate();




    const handleDispatchChangeResult =(result)=>{
       // dispatch(setResult(result));
    }







    const queryParams = queryString.parse(window.location.search);
    const[options,setOptions] = useState({departments:[]});
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState('');
    const [concurrencyStamp,SetConcurrency] = useState('');

    async function handleChangeState(depId,stamp,rowName){
        try {
           setSpinner(true);
            setWait(true);
            setHeaderName(rowName)
           const response =await PUT(`/Tickets/Close`,{ConcurrencyStamp:stamp,TicketId:depId},false);
           if(response.status === 200){
               setSpinner(false);
               setWait(false)
               handleDispatchChangeResult(response.status);

           }else{
               handleApiError(response,onApiError);
           }
        }
        catch (e) {
            console.log(e);
        }
    }



    const onApiError = ()=>{
        setSpinner(false);
    }
    const role = GetSpecialRole();


    const handleFetchData=async ()=>{
        try {

           const response = await GET(`/Tickets/User/Departments`,{},'IEnumerable');
           if(response.status === 200){
               const departs = response.data.length>0? response.data.map(item=>({label:item.value,value:item.id})):[];
               setOptions(prevState => ({...prevState,departments: departs}));
           }
           else {
               handleApiError(response,onApiError);
           }
        }
        catch (e) {
          console.log(e);
        }
    }






    const columnDefs = [

        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},



        { headerName: t("TicketTitle"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.topic}
                </>
        },

        { headerName:t("Receiver"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    {/* {parameters.data} */}
                </>
        },

        { headerName:t("TicketNumber"), minWidth: 140,
            cellRenderer:parameters=>
                <>
                    {parameters.data.ticketCode}
                </>
        },

        { headerName:t("ImportanceLevel"), minWidth: 150,
            cellRenderer:parameters=>
                <>
                    {/*از entickettype میاد*/}
                    {parameters.data.type}
                </>
        },

        { headerName: t("Date"), minWidth:120,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.createdOn} /></>
            }
        },


        { headerName: t("Status"),maxWidth:140,
            cellRenderer:params=>
                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading  />
                    ) : params.data.status === 0 || params.data.status === 1 || params.data.status === 2 ? (
                        <HPrimeIcon iconClass={'cursor-pointer text-themeSuccess'}
                                     onClick={async () => await handleChangeState(params.data.id,params.data.concurrencyStamp,params.colDef.headerName)}
                                     icon={'pi-check-square'}

                        />
                    ) : (
                        <>{t("IsClosed")}</>
                    )}
                </div>
        },


        { headerName: t("ViewConversation"),maxWidth:150,
            cellRenderer:parameters=>
                <div>
                    <button type="button" onClick={()=>redirectTo(`/Support/Ticket/Conversation?ticketId=${parameters.data.id}`)} className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white text-xs">
                        <HPrimeIcon icon={'pi-eye'} />
                        {t("viewing")}
                    </button>
                </div>
        },


    ];
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);

    const [selectedRows, setSelectedRows] = useState([]);
    const onSelectionChanged = (row) => {
        setSelectedRows(row.api.getSelectedRows());
    };


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',IsNormal:null,IsImportant:null,IsCritical:null,IsClosed:null,DepartmentId:""});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const handleFilterValue=(data,name)=>{
        setFilter(({...filter,[name]: data.value}));
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search:'',IsCritical: null, IsImportant: null,IsNormal: null,DepartmentId:"",IsClosed: null}));
        setSearch('');
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                   handleFetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [currentPage,filter.search, data.pageSize,filter.IsNormal,filter.IsClosed,filter.IsImportant,filter.IsCritical,filter.DepartmentId]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/Tickets`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,IsNormal:filter.IsNormal,IsImportant:filter.IsImportant,IsCritical:filter.IsCritical,IsClosed:filter.IsClosed,DepartmentId:filter.DepartmentId}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };




    const switchData = [
        {name:'IsNormal',lable:t("Normal"),value:filter.IsNormal},
        {name:'IsImportant',lable:t("Important"),value:filter.IsImportant},
        {name:'IsCritical',lable:t("Urgent"),value:filter.IsCritical},
        {name:'IsClosed',lable:t("IsClosed"),value:filter.IsClosed},
    ]



    return {
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        data,
        setData,
        currentPage,
        setCurrentPage,
        handlePreviousPage,
        handleCurrentPage,
        handlePageSizeCount,
        handleNextPage,
        handleSearch,
        filterValueHandler,
        resetFilters,
        rowData,
        switchData,
        filter,
        selectedRows,
        onSelectionChanged,
        search,
        setSearch,
        setFilter,
        handleFilterValue,
        options,
        spinner
    }




}