import React from "react";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";


export const ServerPin=({handleServerPin,serverId,isPin})=>{

    return (
        <>
            <button type={"button"} onClick={()=>handleServerPin(serverId)} className={"dark:text-gray-300  absolute end-1 top-1"}>
                <HPrimeIcon icon={` ${isPin?'pi-bookmark-fill ':'pi-bookmark'}  `} iconClass={` ${isPin?'text-themeInformation dark:text-themeDarkInformation':''}  `}></HPrimeIcon>
            </button>

        </>
    )
}