import {useTranslation} from "react-i18next";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {ListFilter} from "../../../Commans/Grid/ListFilter";

import React, {useEffect, useState} from "react";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useCustomWithdrawTransactionGrid} from "./useCustomWithdrawTransactionGrid";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {TransactionItem} from "../TransactionItem";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {NoDataHarmony} from "../../../Commans/NoDataHarmony";
import {SettingButton} from "../../../Commans/UIParts/SettingButton";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandleFastRegister} from "../../../CRM/PersonsManagement/UserRegisteration/HandleFastRegister";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const WithdrawTransaction=()=>{
    const { t } = useTranslation();
    const [errors,setErrors] = useState({});
    const [initialSource,setInitialSource] = useState({currencies:[],paymentStates:[]});
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [refresh,setRefresh] = useState(false)

    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }

    const handleInitialSource=(currencies,paymentStates)=>{
        const currencyData = convertSelectDataHandler(currencies);
        const paymentStateData = convertSelectDataHandler(paymentStates);
        setInitialSource({
            currencies: currencyData,
            paymentStates: paymentStateData,
        })
    }
    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
            const responsePaymentState = await GET(`/Enumerations/EnPaymentStatus`,{},'IEnumerable');
            if(response.status === 200 && responsePaymentState.status === 200){
                handleInitialSource(response.data,responsePaymentState.data);
                hideSpinner();
            }
            else {
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[refresh])



    const GetCurrencyType = (type) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === type);
        return data ? data.label : '';
    }
    const GetTransactionType=(type)=>{
        return (<>
            {
                type === 1?
                    t('Deposit'):
                    t('Withdrawal')

            }
        </>)
    }
    const GetTransactionIcon=(type)=>{
        return (<>
            {
                type === 1?
                    <div className={"flex-shrink-0 bg-themeSuccess h-full grid place-content-center w-12"}>
                        <HPrimeIcon icon={"pi-check-square"} iconClass="text-white"/>
                    </div>
                    :
                    type === 2?
                        <div className={"flex-shrink-0 bg-themeDanger h-full grid place-content-center w-12"}>
                            <HPrimeIcon icon={"pi-times"} iconClass="text-white"/>
                        </div>
                        :
                        type === 0?
                            <div className={"flex-shrink-0 bg-themeSecondary h-full grid place-content-center w-12"}>
                                <HPrimeIcon icon={"pi-clock"} iconClass="text-white"/>
                            </div>:
                            null

            }
        </>)
    }
    const GetPaymentState = (paymentStatus) => {
        if (!initialSource.paymentStates) {
            return;
        }
        const data = initialSource.paymentStates.find((item) => item.value === paymentStatus);
        return data ? data.label : '';
    }

    const {rowData,pagination,getRowHeight,autoGroupColumnDef,defaultColDef,handleNextPage,handlePreviousPage,handleCurrentPage,handlePageSizeCount,toggleFilters,handleToggleFilter,handleToDate,handleSearchTrackingCode,handleFromDate,handleResetAllFilters,filter,setFilter,trackingCode,setTrackingCode} = useCustomWithdrawTransactionGrid(showSpinner,hideSpinner);

    return (
        <>
            <div class="box bg-white dark:bg-themeDarkSalt700 space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("TransactionCode")}</label>
                            <InputText  onChange={(e)=>setTrackingCode(e.target.value)}  value={trackingCode} type="text" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,trackingCode: e.target.value})):null}  className=" input w-full" />
                        </div>
                        <div class="space-y-2">
                            <div>
                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />

                                    {t("FromDate")}

                                </label>
                            </div>

                            <DateTimePicker handleDateTime={handleFromDate} value={filter.fromDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2">
                            <div>
                                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                    <SettingButton
                                        onClick={()=>handleSetting('GeneralSetting')}
                                        tooltipTitle={t("Calendarsettings")}
                                    />
                                    {t("ToDate")}
                                </label>
                            </div>

                            <DateTimePicker value={filter.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearchTrackingCode()}
                            onClickReset={()=>handleResetAllFilters()}
                        />
                    </ListSearch>
                    <ListFilter

                    childrenOne={
                        <>

                        </>
                    }
                    childrenTwo={
                        <>
                            <InputSwitchListFilter switchData={toggleFilters} OnValueSelection={handleToggleFilter} />
                        </>
                    }

                    >
                    </ListFilter>
                </div>

                {rowData.length > 0 ? rowData.map((item, index) => (
                    <TransactionItem key={index} GetPaymentState={GetPaymentState} item={item} GetTransactionType={GetTransactionType} GetTransactionIcon={GetTransactionIcon}  GetCurrencyType={GetCurrencyType}  />
                )):
                    <NoDataHarmony/>

                }


            </div>
            {
                rowData.length !== 0 ?
            <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    :
                    null
            }


            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>
                            :
                            'FastRegister'?
                                <HandleFastRegister

                                />
                                :null
                    }
                </div>
            </FantasyModal>


        </>
    )
}