import React, { useEffect, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { fetchAccessToken } from "./Services/AxiosService/JwtService";
import { BasicUrl } from "./Services/AxiosService/axiosClient";
import {ListFilter} from "./Components/Commans/Grid/ListFilter";
import {ButtonPlus} from "./Components/Commans/UIParts/ButtonPlus";
import {InputSwitchListFilter} from "./Components/Commans/Filters/InputSwitchListFilter";

const Test = () => {
    const [message, setMessage] = useState('');
    const token = fetchAccessToken();
    const [connection,setConnection] = useState(null);
    const [serverData,setServerData] = useState('');


    useEffect(() => {

        function handleIsJoiningHubAsync(userId) {
            console.log(userId + " joined....");
            alert(userId);
        }

        function handleSendMessageAsync(message){
            setServerData(message);
        }

        function SetIsTypingAsync(userId,IsTyping){

        }
        function IsLeavingHubAsync(userId,exception=null){

        }


        const connection = new signalR.HubConnectionBuilder()
            .withUrl("http://192.168.168.42:45455/Chat", {
                withCredentials: true,
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .configureLogging(signalR.LogLevel.Information)
            .build();
        setConnection(connection);

        connection.start().then(() => {
            console.log("Connected to SignalR hub!");
        }).catch((err) => {
            console.error(err.toString());
        });

        connection.on("IsJoiningHubAsync",handleIsJoiningHubAsync);
        connection.on("SendMessageAsync",handleSendMessageAsync);



        return () => {
            connection.stop().then(() => {
                console.log("Connection to SignalR hub closed.");
            }).catch((err) => {
                console.error(err.toString());
            });
        };
    }, [token]);


    const SendMessage= async()=>{
        try {
          if(connection){
              await connection.invoke("SendAllMessage",message)
              setMessage(message)
          }
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <>
            <ListFilter label={null} icon={null}
                  childrenOne={
                     <>


                     </>
                  }
                     childrenTwo={
                            <>

                            </>
                        }
            />

            <input onChange={(e) => setMessage(e.target.value)} />
            <button onClick={SendMessage}>Chat</button>

            <h1 style={{background:'red'}}>{serverData}</h1>
        </>
    );
}

export default Test;
