

import React, { createContext, useState } from 'react';

export const FileContext = createContext();

export const FileProvider = ({children})=>{
    const [path, setPath] = useState(''); //مسیر روت اصلی رو برای جابجایی بین فولدر میسازه
    const [pattern, setPattern] = useState(''); // یک pattern رو برای فیلتر فایل داخل فولدر ها هندل میکنه
    const [result,setResult] = useState(0); // بر اساس تغییر value  این در سنایو های status code 200  ما کامپوننت رو دوباره رندر میکنیم
    const [destination,setDestination] = useState(''); // مشخص میکنه ما داریم رو فایل کار میکنیم یا رو فولدر
    const [visible,setVisible] = useState(false); // برای نمایش مودال ها استفاده میشه
    const [fileColletionName,setFileCollectionName] = useState('');//اسم فایل یا فولدر رو به عنوان یک ادرس جدید در عملیات های کپی یا انتقال هندل میکنه
    const [action,setAction] = useState(''); //اسم اکشن مورد نظر کپی یا انتقال رو برای فایل یا فولدر هندل میکنه
    const[mission,setMission] = useState('');//اسم اکشن مورد نظر کپی یا انتقال رو برای فایل یا فولدر هندل میکنه
    const [spinner,setSpinner] = useState(true); //یک loader spinner رو داخل فرم ها هندل میکنه
    const [clipBoard,setClipBoard] = useState("");
    return (
        <FileContext.Provider value={{clipBoard,setClipBoard,mission,setMission,path,setPath,pattern,setPattern,result,setResult,destination,setDestination,visible,setVisible,fileColletionName,setFileCollectionName,action,setAction,spinner,setSpinner}}>
            {children}
        </FileContext.Provider>
    );
}