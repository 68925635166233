import React, {useEffect, useMemo, useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {DELETE, GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting,GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {GridButtonOprations} from "../../Commans/Grid/GridButtonOprations";
import queryString from "query-string";

export const useFormGrid=(showSpinner,hideSpinner,setIsOpen,setIsEdit,refresh,Refresh,setForm,form,initialSource,handleOpenMenu)=>{
    const { t } = useTranslation();
    const queryParams = queryString.parse(window.location.search);

    const [rowData, setRowData] = useState([]);
    const redirectTo = useNavigate();
    const [filter,setFilter]=useState({search:'',searchValue:''});
    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})

    
    // Operations
    const handleGetForm= async(formId)=>{
        try {
            showSpinner();
            setIsEdit(true);
            const response = await GET(`/Forms/${formId}`,{},'single');
            if(response.status === 200){
                setForm({
                    formId:response.data.formId,
                    name:response.data.name,
                    description:response.data.description,
                    slug:response.data.slug,
                    templateId:initialSource.templates.find((item)=>item.value === response.data.templateId)??null,
                    access:initialSource.dataAccess.find((item)=>item.value === response.data.access)??null,
                    roadMapId:initialSource.roadMaps.find((item)=>item.value === response.data.roadMapId)??null,
                    msgSuccess:response.data.msgSuccess,
                    msgError:response.data.msgError,
                    rootFolder:response.data.rootFolder.replace(/\+/g, '/'),
                    startDate:response.data.startDate,
                    expirationDate:response.data.expirationDate,
                    eBankIds: response.data.eBankIds ? response.data.eBankIds.map((value) => initialSource.banks.find((item) => item.value === value)) : [],
                })
                hideSpinner();
                setIsOpen(true);
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner()
        }
    }
    const handleDeleteForm = async (formId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Forms/${formId}`,);
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }



    // Display data to the user
    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110,hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: t("FormType"),minWidth: 150,
            cellRenderer:parameters=>
                <>
                    {parameters.data?.access}
                </>
        },
        { headerName: t("Title"),minWidth: 200,
            cellRenderer:parameters=>
                <>
                    {parameters.data.name}
                </>
        },
        { headerName: t("Address"),minWidth: 200,
            cellRenderer:parameters=>
                <>
                    {parameters.data.slug}
                </>
        },

        { headerName: t("FolderAddress"),minWidth: 200,
            cellRenderer:parameters=>
                <>
                    {parameters.data.rootFolder.replace(/\+/g, '/')}
                </>
        },
        { headerName: t("FormCirculationPath"),minWidth: 200,
            cellRenderer:parameters=>
                <>
                    {parameters.data.roadMap === null ? (
                        <div>
                            <HPrimeIcon icon={'pi-times-circle'} iconClass={"text-themeDanger"} />
                        </div>
                    ) : (
                        parameters.data.roadMap
                    )}

                </>
        },

        {
            headerName: t("StartDate"),minWidth: 200,
            cellRenderer: parameters => (
                <>
                    {parameters.data.startDate === null ? (
                        <div>
                            <HPrimeIcon icon={'pi-times-circle'} iconClass={"text-themeDanger"} />
                        </div>
                    ) : (
                        <GridDateFomartter date={parameters.data.startDate} hasTime={true} />
                    )}
                </>
            ),
        },
        {
            headerName: t("EndDate"),minWidth: 200,
            cellRenderer: parameters => (
                <>
                    {parameters.data.expirationDate === null ? (
                        <div>
                            <HPrimeIcon icon={'pi-times-circle'} iconClass={"text-themeDanger"} />
                        </div>
                    ) : (
                        <GridDateFomartter date={parameters.data.expirationDate} hasTime={true} />
                    )}
                </>
            ),
        },

        { headerName: t("operations"), maxWidth: 150,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button onClick={()=>redirectTo(`/Form/Create/Build/${encodeURIComponent(params.data.id)}`)} className="text-themeInformation dark:bg-themeDarkInformation" >
                        <HPrimeIcon icon={'pi-objects-column'} />
                    </button>
                    <GridButtonEdit
                        onClick={()=>handleGetForm(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteForm(params.data.id)}
                    />
                    <GridButtonOprations
                        onClick={()=>handleOpenMenu(params.data.id)}
                    />
                </div>
            }
        },
    ];
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    // Pagination handel
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    // Search handel
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: prevState.searchValue}));
    }
    const handleResetFilters=()=>{
        setFilter((prevState)=>({...prevState,search: '',searchValue: ''}));
    }
    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }

    useEffect(() => {
        OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize]);


    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Forms`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search:filter.search,roadMapId:queryParams.roadMapId??null}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const items = Paginate(response.items);
                setRowData(items);
            }
            else {
                handleApiError(response,hideSpinner);
            }
        } catch (error) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };


    return {
        handleGetForm,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteForm,
        handleSearch,
        handleResetFilters,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
        filter,
        setFilter,
    }




}