import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";


import {GridTable} from "../../Components/Commans/Grid/GridTable";
import {FantasyModal} from "../../Components/Commans/UIParts/FantasyModal";
import {ListSearch} from "../../Components/Commans/Grid/ListSearch";
import {ListFilter} from "../../Components/Commans/Grid/ListFilter";

import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Components/Commans/UIParts/ButtonSearchList";
import Select from "react-select";
import {GridButtonEdit} from "../../Components/Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Components/Commans/Grid/GridButtonDelete";
import {Pagination} from "../../Components/Commans/Grid/Pagination";
import {Export} from "../../Components/Commans/Grid/Export";
import {ButtonPlus} from "../../Components/Commans/UIParts/ButtonPlus";
import {GridButtonOprations} from "../Commans/Grid/GridButtonOprations";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";

export const HarmonyHelp = ()=>{
    const { t } = useTranslation();



    const [IsOpenMenu,setIsOpenMenu] = useState(false);

    const handleOpenMenu = () => {
        setIsOpenMenu(true);

    };
    const handleCloseMenu = ()=>{
        setIsOpenMenu(false);
    }




    const [IsOpen,setIsOpen] = useState(false);
    const handleOpenModal = () => {
        setIsOpen(true);

    };
    const handleCloseModal = ()=>{
        setIsOpen(false);
    }


    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},

        { headerName: t("Sender"),maxWidth: 140,
            cellRenderer:parameters=>
                <>
                    مسعود احمدی
                </>
        },
        { headerName: t("Responsibility"),maxWidth: 140,

            cellRenderer:parameters=>

                    <>مدیریت</>

        },
        { headerName: t("Text"),
            cellRenderer:parameters=>
                <>با سلام، احتراما باستحضار میرساند جهت تمدید فاکتور سالیانه فروشگاه اکسیرمن به آدرس exirman.com هرچه سریعتر اقدام نمایید. در صورت عدم پیگیری و انقضای هاست و دامین های ذکر شده در فاکتور فوق گروه نرم افزاری آرین هیچ مسئولیتی در قبال از دست رفتن اطلاعات وب سایت شما ندارد. با تشکر

                </>

        },

        {
            headerName: t("viewing"), width: 120, maxWidth: 110,
            cellRenderer: parameters =>
                <>
                   1221212
                </>

        },
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit/>
                    <GridButtonDelete/>
                    <GridButtonOprations
                        onClick={()=>handleOpenMenu()}
                    />
                </div>
            }
        },

    ];








    const rowData = [

        { id:1, },
        { id:2, },
        { id:3, },
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };

    const [columnApi, setColumnApi] = useState(null);
    const [gridApi, setGridApi] = useState(null);

    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');





    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        setColumnApi(params.columnApi);

    };





    return (

        <>
            <div class=" space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">جستجو براساس (****)</label>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                            <Select
                                placeholder={t("selection")}
                                menuPosition={"fixed"}
                                classNamePrefix={"h_multi_select"}

                            />
                        </div>
                        <ButtonSearchList />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleOpenModal()}
                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <div class=" space-y-2">
                                            <lable className="text-xs dark:text-gray-300">*****</lable>
                                            <div>
                                                <input className="input input--switch " type="checkbox"/>
                                            </div>
                                        </div>
                                    </>
                                }
                    />


                </div>
                <div className={"space-y-2 H-body-ag-grid "}>
                    <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                               enableRtl={true}
                               pagination={true}
                               getRowHeight={getRowHeight}
                               onGridReady={onGridReady}
                               quickFilterText={quickFilterText}

                    />
                    <Pagination/>
                </div>
                <Export/>

            </div>
            <FantasyModal openModal={IsOpenMenu} closeModal={handleCloseMenu} >
                <div className=" grid grid-cols-3 md:grid-cols-4 gap-3">
                    <button type="button" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem]  justify-center" > <HPrimeIcon  icon={'plus-square'} />*****</button>
                    <button type="button" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem]  justify-center" > <HPrimeIcon  icon={'trending-up'} />*****</button>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpen} closeModal={handleCloseModal} >
                <div>
                    <div>
                        <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("edit")}</button>
                    </div>
                </div>
            </FantasyModal>
        </>

    )
}