import React, {useEffect, useState} from "react";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DisplayError} from "../Errors/DisplayError";

import {InputText} from "primereact/inputtext";
import {useTranslation} from "react-i18next";
import {LoadingHarmony} from "../Loadings/LoadingHarmony";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";

export const User=(props)=>{
    const [users,setUser]=useState({fullName:null,phoneNumber:null,email:null,address:null});
    const [IsshowSpinner,setIsShowSpinner] = useState(false);
    const {t}=useTranslation();
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const fetchData = async (IsOpen) => {
        if (IsOpen) {
            try {
                showSpinner();
                const apiCalls = [
                    GET(`/Users/Information/${props.userId}`,{},'single'),
                    GET(`/Users/PersonalData/${props.userId}`,{},'single')
                ];
                const [responseUser,responsePersonalData] = await Promise.all(apiCalls);
                if (responseUser.status === 200 && responsePersonalData.status === 200) {
                    setUser({
                        fullName: responseUser.data.fullName,
                        phoneNumber: responseUser.data.mobilePhoneNumber,
                        email: responseUser.data.email,
                        address:responsePersonalData.data.address
                    })
                    hideSpinner();
                } else {
                    handleApiError(responseUser, hideSpinner);
                    handleApiError(responsePersonalData, hideSpinner);
                }

            } catch (e) {
                hideSpinner();
            }
        }
    };

    useEffect(()=>{
        fetchData(props.IsOpen);
    },[props.IsOpen]);



    return (<>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 flex-1 relative">
            {

                IsshowSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }
            <div class=" space-y-2">
                <div className="flex justify-between">
                    <label className="dark:text-gray-300 text-xs">{t("FirstNameAndLastName")}</label>
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                        <HPrimeIcon icon={"pi-user"} />
                    </span>
                    <InputText  className="input w-full" name={'fullName'} value={users.fullName} disabled={true} />
                </div>
            </div>
            <div className="space-y-2">
                <div className="flex justify-between">
                    <label className="dark:text-gray-300 text-xs">{t("MobilePhone")}</label>
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                        <HPrimeIcon icon={"pi-mobile"} />
                    </span>
                    <InputText className="input w-full"  value={users.phoneNumber} disabled={true} name={'phoneNumber'}  />
                </div>
            </div>
            <div className="space-y-2">
                <div className="flex justify-between">
                    <label className="dark:text-gray-300 text-xs">{t("Email")}</label>
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                        <HPrimeIcon icon={"pi-mobile"}/>
                    </span>
                    <InputText className="input w-full"  value={users.email} disabled={true} name={'email'}  />
                </div>
            </div>
            <div className="space-y-2">
                <div className="flex justify-between">
                    <label className="dark:text-gray-300 text-xs">{t("Address")}</label>
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                        <HPrimeIcon icon={"pi-mobile"}/>
                    </span>
                    <InputText className="input w-full"  value={users.address} name={'address'} disabled={true}  />
                </div>
            </div>

        </div>


    </>)

}