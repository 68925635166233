import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";


import {Button} from "primereact/button";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../../../Commans/Grid/GridButtonOprations";

export const HandlePassingListChegue = () => {
    const { t } = useTranslation();

    const [modal, setModal] = useState({ connections: false, });

    const connectionsOnHide = () => {
        setModal((prevState) => ({ ...prevState, connections: !modal.connections }));
    };


    const [isMenuOpen,setMenuOpen] = useState(false);
    const [userId,setUserId] = useState('');
    const handleOpenMenu = () => {
        setMenuOpen(true);

    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }


    const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

    const columnDefs = [
        { headerName: 'ردیف', field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: 'شماره چک',minWidth: 170,
            cellRenderer:parameters=>
                <>
                    ere-565e-565665324-42-234-
                </>
        },
        { headerName: 'نام بانک', minWidth: 160,
            cellRenderer:parameters=>
                <>
                    بانک پارسیان
                </>
        },
        { headerName: 'مبلغ',minWidth: 160,
            cellRenderer:parameters=>
                <>
                    2.000.000.000 ریال
                </>
        },


        { headerName: 'دریافت کننده', minWidth: 160,
            cellRenderer:parameters=>
                <>مسعود احمدی محمدابادی</>
        },
        { headerName: 'تاریخ صدور', field: 'date',width: 130, maxWidth: 130,},
        { headerName: 'تاریخ سررسید', field: 'date',width: 130, maxWidth: 130,},

        { headerName: 'وضعیت', minWidth: 150,
            cellRenderer:parameters=>
                <>وصول شده</>
        },


        { headerName: t("settings"),width: 110, minWidth: 120,
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button className="text-themeInformation dark:text-themeDarkInformation" onClick={()=>connectionsOnHide()} >
                        <HPrimeIcon  icon={'pi-phone'} />
                    </button>
                    <GridButtonEdit/>
                    <GridButtonDelete/>
                    <GridButtonOprations
                        onClick={()=>handleOpenMenu()}
                    />
                </div>
            }

        },

    ];

    const rowData = [

        { id:1, date:'1402/10/3'},
        { id:2, date:'1402/10/3'},
        { id:3, date:'1402/10/3'},
    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    const onSelectionChanged=(data)=>{
        console.log(data);
    }
    const onCellValueChanged = (data)=>{
        console.log(data);
    }


    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };



    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const onQuickFilterChanged = (event) => {
        setQuickFilterText(event.target.value);
    };



    return(

    <>
          <div class=" space-y-5">
                  <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                      <ListSearch label={null} icon={null} fieldsetClassName={""}>
                          <div className="space-y-2 ">
                              <label className="text-xs dark:text-gray-300">جستجو براساس-(شماره چک):</label>
                              <InputText type={"search"} className={"input w-full"} />
                          </div>
                          <div className="space-y-2 ">
                              <label className="text-xs dark:text-gray-300">نوع چک:</label>
                              <Select
                                  placeholder={t("selection")}
                                  classNamePrefix={"h_multi_select"}
                                  menuPosition={"fixed"}
                              />
                              {/*<select name="" id="" className="select2 w-full">*/}
                              {/*  <option value="">درجریان</option>*/}
                              {/*  <option value="">وصولی</option>*/}
                              {/*</select>*/}
                          </div>
                          <div className="flex gap-2">
                              <div class="space-y-2 flex-1">
                                  <label className="text-xs dark:text-gray-300">از تاریخ:</label>
                                  <InputText type={"text"} className={" input w-full"} />
                              </div>
                              <div class="space-y-2 flex-1">
                                  <label className="text-xs dark:text-gray-300">تا تاریخ:</label>
                                  <InputText type={"text"} className={" input w-full"} />
                              </div>
                          </div>
                          <div className="">
                              <div class="flex gap-1 items-end">
                                  <button className="button text-white bg-themeInformation dark:bg-themeDarkInformation w-16">جستجو</button>
                                  <button className="button text-white bg-themeSecondary text-nowrap">مشاهده لیست</button>
                                  <button className="button text-white bg-themeDanger">عودت</button>
                                  <button className="button text-white bg-themeSuccess">وصول</button>
                              </div>
                          </div>
                      </ListSearch>
                      <ListFilter label={null} icon={null}
                                  childrenOne={
                                      <>
                                          <Return/>
                                          <ButtonPlus/>
                                          <ButtonVideo/>

                                      </>
                                  }
                                  childrenTwo={
                                      <>
                                          <div className=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">شش ماه به انقضاء</lable>
                                              <input className="input input--switch " type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">یک سال به انقضاء</lable>
                                              <input className="input input--switch " type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">موجود</lable>
                                              <input className="input input--switch " type="checkbox"/>
                                          </div>
                                          <div class="space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">ناموجود</lable>
                                              <input className="input input--switch " type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                              <input className="input input--switch " type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">محصولات پرفروش</lable>
                                              <input className="input input--switch " type="checkbox"/>
                                          </div>
                                          <div class=" space-y-2 flex flex-col items-center">
                                              <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                                              <input className="input input--switch " type="checkbox"/>
                                          </div>
                                      </>
                                  }
                      />

                  </div>
              <div>
                  <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                             enableRtl={true}
                             pagination={true}
                             getRowHeight={getRowHeight}
                             onGridReady={onGridReady}
                             quickFilterText={quickFilterText}
                             style={{ width: '100%', height: '100%' ,position:'relative' }}
                  />
              </div>
              <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
                      <div className="sm:col-span-2">
                          <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                              <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Pdf"></span>
                                  <h2>خروجی (Pdf)</h2>
                              </Button>
                              <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Excel"></span>
                                  <h2>خروجی (Exel)</h2>
                              </Button>
                              <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                                  <span className="bg_Word"></span>
                                  <h2>خروجی (Word)</h2>

                              </Button>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >

                <div className=" grid grid-cols-3 md:grid-cols-4 gap-3">
                        <a href="#" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem] justify-center" > <HPrimeIcon  icon={'printer'} />عودت</a>
                        <a href="#" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem] justify-center" > <HPrimeIcon  icon={'printer'} />وصول</a>
                    </div>

        </FantasyModal>
        <FantasyModal openModal={modal.connections}  closeModal={connectionsOnHide} title={"راه های ارتباطی"}>
            <div class="">
                <div class="flex items-center border-b dark:border-themeDarkColorLine">
                    <div>
                        <ul class="flex gap-2 items-center justify-center p-2">
                            <li>
                                <img src="/Assets/Image/user/person1.png" className="w-10 h-10 block rounded-full overflow-hidden object-contain"
                                     alt="user" title="user"/>
                            </li>
                            <li className="flex gap-1">
                                <h3 className="text-xs dark:text-gray-300">مسعود احمدی</h3>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-2 space-y-2 ">
                    <a href="#" className="flex items-center zoom-in gap-2 border dark:border-themeDarkColorLine p-3 bg-white dark:bg-themeDarkSalt700 rounded-md">
                        <div className="w-20">
                            <h3 class=" border-e dark:border-themeDarkColorLine text-xs dark:text-gray-300">تلفن ثابت:</h3>
                        </div>
                        <div class="flex-1 ">
                            <p class="text-xs text-themeInformation dark:text-themeDarkInformation">021-555555555</p>
                        </div>
                    </a>
                    <a href="#" className="flex items-center zoom-in gap-2 border dark:border-themeDarkColorLine p-3 bg-white dark:bg-themeDarkSalt700 rounded-md">
                        <div className="w-20">
                            <h3 class=" border-e dark:border-themeDarkColorLine text-xs dark:text-gray-300">شماره موبایل:</h3>
                        </div>
                        <div class="flex-1 ">
                            <p class="text-xs text-themeInformation dark:text-themeDarkInformation">09103814542261625</p>
                        </div>
                    </a>
                    <a href="#" className="flex items-center zoom-in gap-2 border dark:border-themeDarkColorLine p-3 bg-white dark:bg-themeDarkSalt700 rounded-md">
                        <div className="w-20">
                            <h3 class=" border-e dark:border-themeDarkColorLine text-xs dark:text-gray-300">ایمیل:</h3>
                        </div>
                        <div class="flex-1 ">
                            <p class="text-xs text-themeInformation dark:text-themeDarkInformation">masoudahmadi441@gmail.com</p>
                        </div>
                    </a>
                    <a href="#" className="flex items-center zoom-in gap-2 border dark:border-themeDarkColorLine p-3 bg-white dark:bg-themeDarkSalt700 rounded-md">
                        <div className="w-20">
                            <h3 class="border-e dark:border-themeDarkColorLine text-xs dark:text-gray-300">اینستاگرام:</h3>
                        </div>
                        <div class="flex-1">
                            <p class="text-xs text-themeInformation dark:text-themeDarkInformation">masoudahmadi441@gmail.com</p>
                        </div>
                    </a>
                    <a href="#" className="flex items-center zoom-in gap-2 border dark:border-themeDarkColorLine p-3 bg-white dark:bg-themeDarkSalt700 rounded-md">
                        <div className="w-20">
                            <h3 class="border-e dark:border-themeDarkColorLine text-xs dark:text-gray-300">تلگرام:</h3>
                        </div>
                        <div class="flex-1">
                            <p class="text-xs text-themeInformation dark:text-themeDarkInformation">masoudahmadi441@gmail.com</p>
                        </div>
                    </a>
                    <a href="#" className="flex items-center zoom-in gap-2 border dark:border-themeDarkColorLine p-3 bg-white dark:bg-themeDarkSalt700 rounded-md">
                        <div className="w-20">
                            <h3 class="border-e dark:border-themeDarkColorLine text-xs dark:text-gray-300">وب سایت:</h3>
                        </div>
                        <div class="flex-1">
                            <p class="text-xs text-themeInformation dark:text-themeDarkInformation">masoudahmadi441@gmail.com</p>
                        </div>
                    </a>
                    <a href="#" className="flex items-center zoom-in gap-2 border dark:border-themeDarkColorLine p-3 bg-white dark:bg-themeDarkSalt700 rounded-md">
                        <div className="w-20">
                            <h3 class="border-e dark:border-themeDarkColorLine text-xs dark:text-gray-300">یوتوب:</h3>
                        </div>
                        <div class="flex-1">
                            <p class="text-xs text-themeInformation dark:text-themeDarkInformation">masoudahmadi441@gmail.com</p>
                        </div>
                    </a>
                </div>
            </div>
        </FantasyModal>
      </>

      )
}