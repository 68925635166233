import React, {useState} from "react";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE} from "../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";

export const useCustomDocument=(initialSource,showSpinner,hideSpinner,Refresh)=>{
    const[files,setFiles] = useState([]);
    const { t } = useTranslation();
    const [totalSize,setTotalSize] = useState(0); // State to track total size of files





    const fileTypes = {
        fileTypeIcons: {
            'application/pdf': 'fa-file-pdf', // Font Awesome class for PDF files
            'image/jpeg': 'fa-file-image', // Font Awesome class for JPEG images
            'image/png': 'fa-file-image', // Font Awesome class for PNG images
            'application/msword': 'fa-file-word', // Font Awesome class for Word documents
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word', // Font Awesome class for Word documents
            'text/plain': 'fa-file-alt', // Font Awesome class for text files
            'default': 'fa-file' // Default Font Awesome class for other file types
        }
    };
    const getFileTypeIcon = (fileType) => {
        const { fileTypeIcons } = fileTypes;
        return fileTypeIcons[fileType] || fileTypeIcons['default'];
    };





    // Function to calculate total size of files
    const calculateTotalSize = (files) => {
        let total = 0;
        files.forEach((file) => {
            if (file.file) {
                total += file.file.size;
            }
        });
        return total;
    };
    const handleFileChange = (index, event) => {
        const newFile = event.target.files[0];
        if (newFile) {
            const updatedCards = [...files];
            const newFilePath = URL.createObjectURL(newFile);
            updatedCards[index] = {
                ...updatedCards[index],
                file: newFile,
                fileUrl: newFilePath,
                // fileType:newFile.name.split('.').pop().toLowerCase()
                fileType:newFile.type
            };
            setFiles(updatedCards);
            setTotalSize(calculateTotalSize(updatedCards)); // Update total size
        }
    };
    const triggerFileInput = (index) => {
        document.getElementById(`file-input-${index}`).click();
    };

    const handleFieldChange = (index, field, value) => {
        const updatedCards = [...files];
        updatedCards[index][field] = value;
        setFiles(updatedCards);
    };

    const deleteCard = (index) => {
        if (files.length === 1) {
            handleInitialCards(initialSource.attachTypes)
            return;
        }
        const updatedCards = files.filter((_, idx) => idx !== index);
        setFiles(updatedCards);
        setTotalSize(calculateTotalSize(updatedCards)); // Update total size
    };

    const toggleEdit = (index) => {
        const updatedCards = [...files];
        updatedCards[index].isEditing = !updatedCards[index].isEditing;
        setFiles(updatedCards);
    };

    const addNewCard = () => {
        setFiles([
            ...files,
            {
                userAttachId: null,
                attachmentType: initialSource.attachTypes[0],
                file: null,
                fileUrl: null,
                fileType:null,
            },
        ]);
    };
    const handleDeleteDocumentAttachment = async (documentAttachId) => {
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    showSpinner()
                    const response = await DELETE(`/HarmonyDocumentAttachment/${documentAttachId}`, {headers: {'Content-Type': 'application/json'}},)
                    if (response.status === 200) {
                        hideSpinner()
                        Refresh()
                        handleSuccessMessage(t("SuccessDone"));
                    } else {
                        handleApiError(response, hideSpinner)
                    }
                } catch (e) {
                    console.log(e);
                }
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const handleInitialCards=(attachTypes)=>{

        setFiles([
            {
                userAttachId: null,
                attachmentType: attachTypes[0],
                file: null,
                fileUrl: null,
                caption: "",
                fileType:null,
            },
        ]);

    }

    const handleGetAllCards=(cards,attachTypes)=>{
        const documentCards = cards.map((item)=>({
            userAttachId: item.document.userDocumentId,
            fileUrl: item.document.fileUrl,
            fileSize: item.document.fileSize,
            fileType: item.document.fileType,
            caption:item.document.caption,
            file:item.document.file,
            attachmentType: attachTypes.find((attachType) => attachType.value === item.attachmentType),
        }))
        return documentCards
    }

    return {
        addNewCard,
        deleteCard,
        handleFieldChange,
        handleFileChange,
        triggerFileInput,
        files,
        setFiles,
        handleInitialCards,
        handleGetAllCards,
        handleDeleteDocumentAttachment,
        totalSize
    }
}