import React, {useState} from "react";


export const  HPrimeIconSmall =({ icon, iconClass,sizeIcon,onClick}) => {




    return(
      <>
          <i className={`pi ${icon?icon:"pi-question-circle"}  ${sizeIcon?sizeIcon:"primIcon_FontSize_xs"}  ${iconClass}`}
             onClick={onClick}

          ></i>

  </>)
}