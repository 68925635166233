import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {InputNumber} from "primereact/inputnumber";

import Select from "react-select";
import {
    convertSelectDataHandler,
    handleCreateContentMetas,
    handleFilterContentMetas
} from "./ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import ButtonVideo from "../../Commans/UIParts/ButtonVideo";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
export const HandleCreateProperty =()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const queryParams = queryString.parse(window.location.search);

    const [contentMeta, setContentMeta] = useState([{id:new Date().getTime(),name:null,metaDataId:null,type:null,value:null,order:0}]);
    const [result,setResult] = useState(0)
    const[spinner,setSpinner] = useState(false)
    const [options,setOptions] = useState({metaTypes:[]});
    const [isDefault,setIsDefault] = useState(false);


    const onApiError=()=>{
        setSpinner(false);
    }


    const handleFetchData= async()=>{
        try {
            setSpinner(true);
            const response = await GET('/MetaDataTypes/Selection',{},'IEnumerable');
            if(response.status === 200){
                const types = convertSelectDataHandler(response.data);
                setOptions((prevState)=>({...prevState,metaTypes: types}));
                setContentMeta([{ ...contentMeta[0], type: types[0] }])
                if(queryParams.contentId){
                    const response = await GET(`/Contents/GetAllContentMetas?Id=${queryParams.contentId}`,{},'IEnumerable');
                    const filteredContentMetas = handleFilterContentMetas(response.data,types);
                    setContentMeta(filteredContentMetas);
                    if(filteredContentMetas.length === 0){
                        setContentMeta([{ ...contentMeta[0], type: types[0]}])
                    }
                }
                setSpinner(false);
            }else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleAddRow=()=>{
        const newRow = {
            id: new Date().getTime(),
            type: options.metaTypes[0],
            name:null,
            value: null,
            order:0,
            contentMetaId:null
        };
        setIsDefault(false);
        setContentMeta(prevList => [...prevList, newRow]);
    }
    const handleRemoveRow = (id,contentMetaId) => {
        if(contentMeta.length === 1){
            const newRow = {
                id: new Date().getTime(),
                contentMetaId: null, // Use the default value from options
                type: options.metaTypes[0],
                value: null,
                name:null,
                order:0
            };
            setIsDefault(true);
            setContentMeta(prevList => [newRow]);
            return;
        }
        setContentMeta((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.contentMetaId === contentMetaId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleChange = (id, field, value, contentMetaId) => {
        setContentMeta((prevRelations) => {
            const updatedRelations = prevRelations.map((meta) => {
                if (
                    (contentMetaId && meta.contentMetaId === contentMetaId) || (!contentMetaId && id && meta.id === id)
                ) {
                    return { ...meta, [field]: value };
                } else {
                    return meta;
                }
            });
            return updatedRelations;
        });
        setIsDefault(false);
    };



    useEffect(()=>{
        handleFetchData();
    },[result])

    useEffect(() => {
        if (contentMeta.length === 0) {
            handleAddRow();
        }
    }, [contentMeta]);


    const handleSubmit= async()=>{
        try {

            if(isDefault){
                //redirectTo('/CMS/Content/List');
                return;
            }else{
                setSpinner(true);
                var metas = handleCreateContentMetas(queryParams.contentId,queryParams.stamp,contentMeta);
                    const response = await PUT(`/Contents/ContentMetas`,metas,false);
                    if(response.status === 200){
                        setSpinner(false);
                        redirectTo('/CMS/Content/List');
                    }
                    else{
                        handleApiError(response,onApiError)
                    }
            }
        }
        catch (e) {
            console.log(e);
        }
    }





    return (
        <div className=" space-y-5">
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        functionClass={"harmony-creation"}
                                        onClick={()=>redirectTo(`/CMS/Content/Add`)}
                                        tooltipTitle={t("InsertingInformation")}
                                    />
                                    {/*<ButtonPlus*/}
                                    {/*    onClick={()=>handleSubmit()}*/}
                                    {/*    icon={"save"}*/}
                                    {/*    title={t("register")}*/}
                                    {/*    functionClass={"harmony-register"}*/}
                                    {/*    classStyle={"bg-themeSuccess"}*/}
                                    {/*    iconClass={"text-white"}*/}
                                    {/*    tooltipTitle={t("DataRegistration")}*/}
                                    {/*    tooltipId={4}*/}

                                    {/*/>*/}
                                </>
                            }

                />


            </div>

            <div className={"relative"}>
                {

                    spinner?
                        <div className="huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3">

                    <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                        <p className={"text-xs dark:text-gray-300"}>{t("CreatingAFeature")}</p>
                    </div>
                    <div className={"px-2 max_height85vh"}>
                        <div className="space-y-5  ">
                            {contentMeta.map((row,index) => (
                                <div key={row.id} className="post-input dark:bg-themeDarkPostInput p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 post-input-design">
                                        <div className={"flex items-center gap-2"}>
                                            <div className={"space-y-1"}>
                                                <label className="text-xs dark:text-gray-300">{t("row")}</label>
                                                <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                                                    {index+1}
                                                </div>
                                            </div>

                                            <div className="flex-1 space-y-1">
                                                <label className={"text-xs dark:text-gray-300"}>{t("Featurename")}</label>
                                                <input type="text" className="input w-full"
                                                       value={row.name}
                                                       onChange={(e) => handleChange(row.id, 'name', e.target.value,row.contentMetaId)}
                                                />
                                            </div>
                                        </div>
                                        <div className="space-y-1">
                                            <label className={"text-xs dark:text-gray-300"}>{t("Featuretype")}</label>
                                            <Select
                                                options={options.metaTypes}
                                                isMulti={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(data) => handleChange(row.id, 'type', data,row.contentMetaId)}
                                                placeholder={t("selection")}
                                                value={row.type}
                                                menuPosition={'fixed'}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <label className={"text-xs dark:text-gray-300"}>{t("Featurevalue")}</label>
                                            <input type="text" className="input w-full"  value={row.value} onChange={(e) => handleChange(row.id, 'value', e.target.value,row.contentMetaId)}/>
                                        </div>
                                        <div className={"flex gap-2 items-end"}>
                                            <div className="flex-1 space-y-1">
                                                <label className={"text-xs dark:text-gray-300"}>{t("Featurerank")}</label>
                                                <InputNumber className="input_number_plus" inputId={`display-order-${row.id}`} value={row.order} onValueChange={(e) =>
                                                    handleChange(row.id,'order',e.value,row.contentMetaId) }
                                                             placeholder={t("DisplayPriority")}
                                                             mode="decimal"
                                                             showButtons
                                                             min={0}
                                                             max={100}
                                                />


                                            </div>
                                            <div className="flex gap-2">
                                                <button type="button" className="button bg-themeDanger text-white small_button"
                                                        onClick={() => handleRemoveRow(row.id,row.contentMetaId)}>
                                                    <HPrimeIcon icon={'pi-minus'}  />
                                                </button>
                                                <button type="button"
                                                        className="button bg-themeInformation dark:bg-themeDarkInformation text-white small_button"
                                                        onClick={handleAddRow}>
                                                    <HPrimeIcon icon={'pi-plus'}  />
                                                </button>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <ButtonSubmitAndCancel
                onRegisterClick={()=>handleSubmit()}
            />
            <ToastContainer />
        </div>
    );
}