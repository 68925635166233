import React, {useEffect, useMemo, useRef, useState} from "react";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {InputText} from "primereact/inputtext";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import { ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {convertSelectDataHandler} from "../ContentService";

import {contentJoiSchema} from "../ContentAdd/ContentSchema";
import Select from "react-select";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {Export} from "../../../Commans/Grid/Export";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {ModalButtonOperations} from "../../../Commans/UIParts/ModalButtonOperations";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {useContent} from "./useContent";
import {useNavigate} from "react-router-dom";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {ModalSubmitButton} from "../../../Commans/UIParts/ModalSubmitButton";
import {useContentGrid} from "./useContentGrid";
export const HandleContentList=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [initialSource,setInitialSource] = useState({contentGroups:[],filters:[]});
    const [IsBtnLoading,setBtnLoading] = useState(false);
    const [refresh,setRefresh]=useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [IsOpen, setIsOpen] = useState(false);
    const [isMenuOpen,setMenuOpen] = useState(false);
    const showSpinner=()=>setIsShowSpinner(true);
    const hideSpinner=()=>setIsShowSpinner(false);
    const showBtnLoading=()=>setBtnLoading(true);
    const hideBtnLoading=()=>setBtnLoading(false);
    const Refresh=()=>setRefresh(prevState => !prevState);
    const {content,handleValue,errors,onSetErrors,setErrors,setContent} = useContent();

    const handleOpenContent=(id,title,slug,concurrencyStamp)=>{
        setContent(({...content,id: id,title: title,slug: slug,concurrencyStamp:concurrencyStamp,oldSlug: slug}));
        setIsOpen(true);
    }
    const handleCloseContent=()=>{
        setIsOpen(false);
        setContent({slug: '',oldSlug: '',concurrencyStamp: '',id: '',title: ''})
        setErrors({})
    }

    const handleOpenMenu = (id,concurrencyStamp) => {
        setMenuOpen(true);
        setContent({id:id,concurrencyStamp: concurrencyStamp})
    };

    const handleCloseMenu = () => {
        setMenuOpen(false);
        setContent({slug: '',oldSlug: '',concurrencyStamp: '',id: '',title: ''})
    };


const handleInitialDataSource=(contentGroups,filters)=>{
    const contentGroupsData = convertSelectDataHandler(contentGroups);
    const filtersData = convertSelectDataHandler(filters);
    setInitialSource({
        contentGroups: contentGroupsData,
        filters: filtersData
    })
}

    const fetchData = async () => {
        try {
            showSpinner();
            const [responseContentGroup, responseFilter] = await Promise.all([
                GET('/ContentGroups/Selection', {}, 'IEnumerable'),
                GET(`/Filters/Selection?Table=${4}`, {}, 'IEnumerable')
            ]);
            if (responseContentGroup.status === 200 && responseFilter.status === 200) {
                handleInitialDataSource(responseContentGroup.data, responseFilter.data);
            } else {
                handleApiError(responseContentGroup, hideSpinner);
                handleApiError(responseFilter, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };
    useEffect(() => {
        fetchData();
    }, []);




    const handleSubmitContent = async (e)=>{
        e.preventDefault();
        var isValid =  validate(contentJoiSchema,content,onSetErrors)
        if(!isValid)return ;
      try {
          showBtnLoading();
          const response =await PUT('Contents/EditTitle', { id: content.id,title:content.title,slug:content.slug,concurrencyStamp:content.concurrencyStamp },false);
          if (response.status === 200) {
              hideBtnLoading();
              handleCloseContent();
              Refresh();
              handleSuccessMessage(t("Successfullyedited"))
          }
          else{
              handleApiError(response,hideBtnLoading)
          }
      }catch (e) {
          hideBtnLoading();
      }
    }



const {autoGroupColumnDef, columnDefs,defaultColDef,handleDeleteContent,handleSearch,rowContents, getRowHeight,handleSelectFilterContentGroups,handleResetFilters, handleSwitchFilters,handleCurrentPage,handleNextPage,handlePageSizeCount,handlePreviousPage, handleChangeOrderSlave,handleSelectFilterValue,setFilter,switchFilters,filter,pagination}=useContentGrid(showSpinner,hideSpinner,refresh,Refresh,handleOpenMenu,handleOpenContent);



    return (

        <>
            <div className=" space-y-5 relative">
                {

                    IsShowSpinner?
                        <div className="huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2 ">
                                <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                                <InputText onChange={(e) => setFilter(() => ({ ...filter, searchValue: e.target.value }))} value={filter.searchValue} onKeyDown={(e) => e.key === 'Enter' ? setFilter(() => ({ ...filter, search: e.target.value })) : null} type={"search"} className={" input w-full"} />
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                                <Select
                                    options={initialSource.contentGroups}
                                    isMulti={true}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectFilterContentGroups(data)}
                                    name={'contentGroupIds'}
                                    placeholder={t("selection")}
                                    value={filter.contentGroupIds}
                                    className={"select-group"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("Filter")}</label>
                                <Select
                                    options={initialSource.filters}
                                    isMulti={false}
                                    isClearable={false}
                                    value={filter.filterId}
                                    isSearchable={true}
                                    onChange={(data)=>handleSelectFilterValue(data,'filterId')}
                                    name={'filterId'}
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>handleResetFilters()}
                        />



                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("insertion")}
                                            onClick={()=>redirectTo(`/CMS/Content/Add`)}
                                            tooltipTitle={t("InsertingInformation")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/Content/Group`)}
                                            icon={"pi-users"}
                                            title={t("grouping")}
                                            tooltipTitle={t("grouping")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/MetaData/Add`)}
                                            icon={"pi-star"}
                                            title={t("feature")}
                                            tooltipTitle={t("CreatingAFeature")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/Content/List`)}
                                            icon={"pi-list"}
                                            title={t("list")}
                                            tooltipTitle={t("ListOfInformation")}
                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchFilters} OnValueSelection={handleSwitchFilters} />

                                    </>
                                }
                    />


                </div>

                <div className="relative space-y-2 H-body-ag-grid" >


                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowContents}
                        onCellValueChanged={handleChangeOrderSlave}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowContents.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount} />
                </div>
                <div className={"grid grid-cols-1 lg:grid-cols-12 bg-white dark:bg-themeDarkSalt700"}>
                    <div className={"lg:col-span-9"}>
                        <Export filters={filter} apiUrl={'/Content/ContentListExport'}/>
                    </div>
                    <div className="lg:col-span-3  flex gap-1 items-center justify-center p-2">
                        <div className={"flex-1 w-full"}>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>

                        <button type={"button"} className="button bg-themeInformation text-white dark:bg-themeDarkInformation">
                            {t("sending")}
                        </button>
                    </div>

                </div>

            </div >

            <ToastContainer />
            <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
                <div className="p-1 grid grid-cols-3 md:grid-cols-4 gap-3">
                    <ModalButtonOperations
                        onClick={()=>redirectTo(`/CMS/Content/List/AddProperty?contentId=${content.id}&stamp=${content.concurrencyStamp}`)}
                        title={t("feature")}
                        icon={"pi-star"}

                    />
                    <ModalButtonOperations
                        title={t("Preview")}
                        icon={"pi-eye"}
                    />

                    <ModalButtonOperations
                        onClick={()=>redirectTo(`/CMS/Content/Add?contentId=${content.id}&isEdit=${false}`)}
                        title={t("SimilarInsertion")}
                        icon={"pi-pen-to-square"}
                    />

                </div>
            </FantasyModal>




            <FantasyModal openModal={IsOpen} closeModal={handleCloseContent} title={t("EditArticleTitle")} >

                <form method="post" onSubmit={handleSubmitContent} >
                        <div className="space-y-5 ">
                            <div className="space-y-3">
                                <div className="space-y-2">
                                    <div className={"flex gap-2 items-center justify-between"}>
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("ArticleTitle")}
                                        <RequiredIcon/>
                                        </label>
                                        <DisplayError
                                            message={errors.title}
                                        />
                                    </div>
                                    <InputText onInput={(e) => handleValue(e)}
                                               value={content.title}
                                               type="text"
                                               className="input w-full"
                                               name="title"
                                               />

                                    <span className="text-themeDanger text-xs">{}</span>
                                </div>
                                <div className="space-y-2">
                                    <div className={"flex gap-2 items-center justify-between"}>
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("ArticleAddress")}
                                            <RequiredIcon/>
                                        </label>
                                        <DisplayError
                                            message={errors.slug}
                                        />
                                    </div>
                                    <InputText onInput={(e) => handleValue(e)}
                                               value={content.slug}
                                               type="text"
                                               className="input w-full"
                                               name="slug"

                                    />

                                </div>
                            </div>
                            <div>
                                <ModalSubmitButton
                                    IsBtnLoading={IsBtnLoading}
                                />

                            </div>
                        </div>
                    </form>

            </FantasyModal>

        </>

    )

}