import React, {useState} from "react";

export const useRequirement=()=>{
    const [require,setRequire]=useState({isRootFolderValid:true})
    const handleRequirement=(data,name)=>{
        setRequire(()=>({...require,[name]:data}))
    }
    return {
        require,
        handleRequirement,
        setRequire
    }
}