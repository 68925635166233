export const handleCreateDataHighLight=(obj,IsEdit)=>{
    if(IsEdit){
        const data = {
            webSiteMetaTagId:obj.webSiteMetaTagId,
            title:obj.title === null || obj.title === undefined?null:obj.title,
            type:obj.type === null || obj.type === undefined?null:obj.type.value,
            data:obj.data === null || obj.data === undefined?null:obj.data,
            targetPage:obj.targetPage === null || obj.targetPage === undefined?null:obj.targetPage,
            isActive:obj.isActive
        }
        return data
    }
    else{
        const data = {
            title:obj.title === null || obj.title === undefined?null:obj.title,
            type:obj.type === null || obj.type === undefined?null:obj.type.value,
            data:obj.data === null || obj.data === undefined?null:obj.data,
            targetPage:obj.targetPage === null || obj.targetPage === undefined?null:obj.targetPage,
            isActive:obj.isActive
        }
        return data
    }
}