import React, {useState} from "react";
import {convertSlugHandler} from "../../../../CMS/Contents/ContentService";
import {GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {handleCreateSlug} from "../../Add/productService";
export const  useCutomProductGroup=(setErrors)=>{
    const [group,setGroup] = useState({productGroupId:null,excerpt: '',slug:'',seO_Title: '',seO_Description:'',title: '',parentId:null,order:0,type:null,stateGoogle:false,stateShow:false,templateId:null});

    const handleUpdateSlug = (e) => {
        const { value } = e.target;
        const slug = handleCreateSlug(value);
        setGroup((prevState) => ({ ...prevState, slug: slug }));
    };
    const handleValue = (e) => {
            const { name, value } = e.target;
            if (name === "title") {
                handleUpdateSlug(e);
                setGroup((prevState) => ({ ...prevState, seO_Title: value }));
            }
        setGroup((prevState) => ({ ...prevState, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
    };

    const handleSelectValue = (data,name)=>{
        setGroup({ ...group, [name]: data });
    }

    return {
        handleValue,
        group,
        setGroup,
        handleSelectValue
    }
}