import React, { useContext, useEffect, useRef, useState } from "react";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useTranslation } from "react-i18next";
import { TicketImage } from "../TicketImage";
import Select from "react-select";

import { GET, POST } from "../../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import { useFormValidation } from "../../../Commans/Hooks/useFormValidation";
import { messageShema } from "../messageShema";
import { useDataBinders } from "../useDataBinders";
import { useFetchData } from "../useFetchData";
import { UseDepartmentSelector } from "./useDepartmentSelector";
import { LoadingHarmony } from "../../../Commans/Loadings/LoadingHarmony";
import { DisplayError } from "../../../Commans/Errors/DisplayError";
import {
    handleCreateMembershipToSiteAdmin,
    handleCreateSiteAdminToMember, handleCreateSiteAdminToSuperAdmin,
    handleCreateSuperAdminToSiteAdmin
} from "../TicketService";
import { handleFailureMessage, handleSuccessMessage } from "../../../../Services/Globals/Errors/NotificationService";
import { validate } from "../../../../Services/Globals/validate";
import { TicketButton } from "../TicketButton";
import { GetDomainToken, GetParentDomainToken, GetSpecialRole } from "../../../../Services/AxiosService/JwtService";
import { DisplayFiles } from "../DisplayFiles";
import { useNavigate } from "react-router-dom";
import { ListFilter } from "../../../Commans/Grid/ListFilter";
import { Return } from "../../../Commans/Globals/Return";
import { ButtonPlus } from "../../../Commans/UIParts/ButtonPlus";
import { useDispatch, useSelector } from "react-redux";
import { FastDepartment } from "../../Departments/FastDepartment";
import { FantasyModal } from "../../../Commans/UIParts/FantasyModal";
import { HPrimeIcon } from "../../../Commans/UIParts/HPrimeIcon";
import { ToastContainer } from "react-toastify";
import { FormSubmit } from "../../../Commans/UIParts/FormSubmit";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {Tooltip} from "react-tooltip";

export const HandleTicketRegistration = () => {

    const redirectTo = useNavigate();
    const { t } = useTranslation();
    const [IsShowSpinner, setIsShowSpinner] = useState(false);
    const [refresh, setRefresh] = useState();
    const [IsDepartmentOpen, setDepartmentOpen] = useState();
    const [btnLoading, setBtnLoading] = useState(false);


    const handleBtnLoading = (data) => {
        setBtnLoading(data);
    }

    const handleOpenDepartment = () => {
        setDepartmentOpen(true);
    }
    const handleCloseDepartment = () => {
        setDepartmentOpen(false);
    }
    const handleResetModal = () => {
        setDepartmentOpen(true);
    };


    // hide and show spinner
    const showSpinner = () => {
        setIsShowSpinner(true);
    }
    const hideSpinner = () => {
        setIsShowSpinner(false);
    }




    const updateData = () => {
        setRefresh((prevstate) => !prevstate);
    }


    const { errors, handleSelectValue, onSetErros, handleValue, handleEmoji, onApiError, ticket, setErrors, handleItemDepartment, activeItem, handleDeActive, setTicket, handleChangeFile, handleRemoveFile, setActiveItem } = useDataBinders(hideSpinner);
    const { handleFetchData, options, setOptions, handleValidateFileSize, setting } = useFetchData(refresh, onApiError, setTicket, ticket);
    const { setDepartment, department } = UseDepartmentSelector(setOptions, onApiError, refresh, showSpinner, hideSpinner, setTicket, setActiveItem);


    const handleSubmitMessage = async (e) => {
        e.preventDefault();
        //SiteAdminToMember
        try {
            const isValid = validate(messageShema, ticket, onSetErros);
            if (!isValid) {
                return;
            }
            if (ticket.departmentId === null) {
                handleFailureMessage(t("Pleaseselectdepartment"));
                return;
            }
            const isAllowed = handleValidateFileSize(setting[0].value);
            if (!isAllowed) {
                handleFailureMessage(t("Thefilesizeexceedstheallowedlimit"));
                return;
            }
            showSpinner();
            const formData = handleCreateSiteAdminToMember(ticket);
            const response = await POST(`/Tickets/TicketToMember`, formData, true);
            if (response.status === 200) {
                hideSpinner();
                updateData();
                handleDeActive(null);
                setTicket((prevState) => ({ ...prevState, message: '', topic: '', type: options.ticketTypes[0], memberId: options.users[0], files: null }));
                redirectTo('/Support/Ticket/List');
                handleSuccessMessage(t("Yourtickethasbeensuccessfullysubmitted"));
            }
            else {
                handleApiError(response, onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    return (<>

        <div class="relative space-y-5" >
            {
                IsShowSpinner ?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony />
                        </div>
                    </div>
                    :
                    null
            }
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={

                        <>
                            <ButtonPlus
                                onClick={() => redirectTo('/Support/Department')}
                                title={t("دپارتمان")}
                                tooltipTitle={t("DepartmentList")}
                                icon={"pi-objects-column"}
                            />

                            <ButtonPlus
                                onClick={()=>redirectTo("/Support/Ticket/List")}
                                title={t("تیکت ها")}
                                tooltipTitle={t("TicketList")}
                                icon={"pi-list"}
                            />


                            <ButtonPlus
                                onClick={()=>redirectTo("/Support/ExpiredTickets")}
                                title={t("archive")}
                                tooltipTitle={t("ExpiredTickets")}
                                icon={"pi-database"}
                            />

                        </>
                    }

                />

            </div>
            <div className="space-y-5">

                <div className="box bg-white dark:bg-themeDarkSalt700 p-2">
                    <div className={"space-y-3"}>
                        <div className={"p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                            <h2 className={"text-xs dark:text-gray-300"}>{t("OnlineTicketdepartmentTitle")}</h2>
                        </div>
                        <div className="space-y-5 px-2">

                            {
                                <div className="flex justify-start items-center gap-4 flex-wrap">
                                    <button
                                        onClick={() => handleOpenDepartment()}
                                        className={`box border border-dashed border-themeInformation dark:border-themeDarkInformation zoom-in grid place-content-center ticket overflow-hidden hover:bg-themeLightGray`}>
                                        <div className="space-y-3 flex justify-center items-center flex-col">
                                            <HPrimeIcon icon={"pi-plus"} iconClass="text-dark dark:text-gray-300"/>
                                            <p className="text-xs dark:text-gray-300">{t('NewDepartment')}</p>
                                        </div>
                                    </button>

                                    {department.map((item, index) => (
                                                        <button
                                                            key={index}
                                                            onClick={() => handleItemDepartment(item.id)}
                                                            onDoubleClick={() => handleDeActive(null)}
                                                            className={`post-input dark:bg-themeDarkPostInput post-input-design zoom-in hover:bg-themeLightGray ticket ${item.id === activeItem ? 'active border border-themeInformation dark:border-themeInformation' : ''} overflow-hidden`}
                                                        >

                                                            {
                                                                item.id === activeItem &&
                                                                <span className="ticket_span bg-themeInformation dark:bg-themeDarkInformation">
                                                                </span>
                                                            }
                                                            <div className={"space-y-3"}>
                                                                <div className="w-full h-16 mx-auto">
                                                                    <img className="size-full object-scale-down" src={item.picture} alt={item.value} />
                                                                </div>
                                                                <div>
                                                                    <h2 className={`text-xs  ${item.id === activeItem ? 'text-themeInformation dark:text-themeDarkInformation' : 'dark:text-gray-300'} `}>{item.value}</h2>
                                                                </div>
                                                            </div>

                                                        </button>
                                                    ))}

                                </div>
                            }

                        </div>
                    </div>

                </div>
                <div>
                    {
                        activeItem &&
                        <div className={"box bg-white dark:bg-themeDarkSalt700 p-2"}>
                            <div className={"p-2 post-input dark:bg-themeDarkPostInput post-input-design "}>
                                <div className={"space-y-1"}>
                                    <label className={"text-xs dark:text-gray-300"}>
                                        {t("Userselection")}
                                    <RequiredIcon/>
                                    </label>
                                    <Select
                                        options={options.users}
                                        isSearchable={true}
                                        className={'w-full'}
                                        onChange={(data) => handleSelectValue(data, 'memberId')}
                                        placeholder={t("selection")}
                                        value={ticket.memberId}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {
                    activeItem &&
                    <>

                        <form onSubmit={handleSubmitMessage} method={'post'} >
                            <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3"}>
                                <div className={"p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                                    <h2 className={"text-xs dark:text-gray-300"}>{t("SendNewTicket")}</h2>
                                </div>
                                <div className={"px-2"}>
                                    <div className="post-input dark:bg-themeDarkPostInput p-2 post-input-design  ">
                                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-5"} >
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">
                                                    {t("ImportanceLevel")}
                                                <RequiredIcon/>
                                                </label>
                                                <Select
                                                    options={options.ticketTypes}
                                                    isSearchable={true}
                                                    onChange={(data) => handleSelectValue(data, 'type')}
                                                    placeholder={t("selection")}
                                                    value={ticket.type}
                                                    classNamePrefix={"h_multi_select"}
                                                    menuPosition={"fixed"}
                                                />
                                            </div>
                                            <div class="space-y-2">
                                                <div className={"flex justify-between items-center"}>
                                                    <label className="text-xs dark:text-gray-300">
                                                        {t("TicketSubject")}
                                                        <RequiredIcon/>
                                                    </label>
                                                    <DisplayError message={errors.topic} />
                                                </div>
                                                <InputText type={"text"} value={ticket.topic} onInput={(e) => handleValue(e)} name={'topic'} className={" input w-full"} />
                                            </div>
                                            <div class="md:col-span-2">
                                                <InputTextarea rows={10} onInput={(e) => handleValue(e)} name={'message'} value={ticket.message} cols={30} className={"input w-full text-xs"} placeholder={t("Text")} />
                                            </div>
                                        </div>
                                        <div className={"flex items-center justify-between"}>
                                            <div class="">
                                                <label className="cursor-pointer relative text-xs flex gap-1 items-center p-2 tooltip_class_file" htmlFor={'file'}>
                                                    <HPrimeIcon icon={'pi-paperclip'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                                                    {t("AttachmentFile")}
                                                    <input type="file" accept=".jpg, .jpeg, .png, .zip, .rar" multiple={true} onChange={handleChangeFile} className="hidden" id={'file'} />
                                                    <Tooltip anchorSelect={".tooltip_class_file"} content={` ${t("MaximumAllowedUpload")} ${setting[0]?.value + 'MB'}`} positionStrategy={"fixed"}	 />
                                                </label>
                                            </div>
                                            <FormSubmit
                                                TextButton={t("sending")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>


                        {
                            ticket.files !== null && ticket.files.length > 0 ?

                                <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 box bg-white dark:bg-themeDarkSalt700 p-2"}>
                                    {
                                        ticket.files !== null && ticket.files.length > 0 ?
                                            ticket.files.map((item, index) => (
                                                <DisplayFiles key={index} file={item} index={index} handleRemoveFile={handleRemoveFile} />
                                            )) : null
                                    }
                                </div> : null

                        }

                    </>
                }
            </div>
        </div>

        <FantasyModal openModal={IsDepartmentOpen} closeModal={handleCloseDepartment} title={t("NewDepartment")} customeClassName={"modal-sm"}>
            <FastDepartment
                // reload={handleReload}
                onApiError={onApiError}
                handleLoading={handleBtnLoading}
                updateDataDepartment={updateData}
                handleCloseModal={handleCloseDepartment}
                handleResetModal={handleResetModal}
                isOpen={IsDepartmentOpen}
            />
        </FantasyModal>

        <ToastContainer />

    </>)
}