import React, {useEffect, useMemo, useState} from "react";

import {useTranslation} from "react-i18next";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {useNavigate} from "react-router-dom";
import {handleFilterCommunication} from "./CommentService";
import queryString from "query-string";
import {Dialog} from "primereact/dialog";
// import 'primereact/dialog/dialog.min.css';
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {Tooltip} from "react-tooltip";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const useGrid=(setSpinner,handleFetchData,onApiError,result,setResult,setIsEdit,setModal,setComments,options,setCommunication,url,state,changeStatusUrl,deleteUrl)=>{
const {t} = useTranslation();
const redirectTo = useNavigate();
    const queryParams = queryString.parse(window.location.search);

    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState('');
    const [message,setMessage] = useState('');
    const handleChangeStatus = async(commentId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            const response = await PUT(changeStatusUrl,{commentIds:[commentId]},false);
            if (response.status === 200) {
                setWait(false)
                setResult({...response.status})
            }
            else{
                setWait(false)
                handleApiError(response,onApiError)
            }
        }catch (e) {
            console.log(e);
        }
    }

    const handleRemoveComment = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`/comments/${id}${deleteUrl}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                        handleSuccessMessage(t('CommentsDeletedSuccessfully'))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);

                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const handleEditComment=async (commentId) => {
        try {
            setSpinner(true);
            setIsEdit(true);
            const response = await GET(`/comments/${commentId}`,{},'single');
            if(response.status === 200){

                const communications = handleFilterCommunication(response.data.communications,options.types,options.modes);
                setCommunication(communications);
                 setComments({
                     id:response.data.id,
                     fullName:response.data.fullName,
                     message:response.data.message,
                     slug:response.data.slug === null ? [] : options.slugs.find((item) => item.value === response.data.slug),
                     state:response.data.state === null ? [] : options.states.find((item) => item.value === response.data.state),
                     parentId:response.data.parentId??null,
                     rateCommenting:response.data.rateCommenting,
                     likeCounts:response.data.likeCounts,
                     dislikeCounts:response.data.dislikeCounts,
                 })
                setModal((prevState) => ({ ...prevState,commentAdd:true }));
                redirectTo(`${url}?commentId=${commentId}`);
                setSpinner(false)
            }
            else{
                handleApiError(response,onApiError);
            }
        } catch (e) {
            console.log(e);
        }
    }



    const [contact,setContact] = useState(null);
    const handleGetContactInformation=async(commentId)=>{
        try {
             setSpinner(true);
             const response = await GET(`/comments/ContactInformation`,{commentId:commentId},'single');
            if (response.status === 200) {
                setSpinner(false);
                setModal((prevState) => ({ ...prevState,communication:true }));
                const communications = response.data.communications.map((communication) => {
                    const matchingType = options.types.find((type) => type.value === communication.communicationId);
                    return {
                        type: communication.type,
                        value: communication.value,
                        label: matchingType ? matchingType.label : null,
                    };
                });
                const updatedUserContact = {
                    name: response.data.name,
                    picture: response.data.picture,
                    communications: communications,
                };
                setContact(updatedUserContact);
            } else {
                handleApiError(response,onApiError);
            }
        }
        catch (e) {

        }

    }



    const [visible, setVisible] = useState(false);
    const handleCloseMessage = ()=>{
        setVisible(false);
    }
    const handleShowMessage =(visible,message)=>{
        setVisible(visible);
        setMessage(message)
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        // { headerName: t("Image"), maxWidth: 100,
        //     cellRenderer:parameters=>
        //         <span class="w-10 h-10 block rounded-full overflow-hidden mx-auto">
        //             <img src="/Assets/Image/user/person1.png" className="w-full h-full object-contain" alt="user" title="user"/>
        //         </span>
        // },
        { headerName: t("FirstNameAndLastName"), field: 'fullName',  minWidth: 180,maxWidth: 180,
            cellRenderer:parameters=>
                <>
                    {parameters.data.fullName}
                </>
        },

        { headerName: t("UserComments"),
            cellRenderer:parameters=>

                <button type={"button"} className={'cursor-pointer line-clamp-1 tooltip_class_See'}
                     onClick={() => handleShowMessage(true,parameters.data.message)} >{parameters.data.message}
                    <Tooltip anchorSelect={".tooltip_class_See"} content={t("viewing")} positionStrategy={"fixed"}	 />
                </button>

        },

        { headerName: t("Date"),width: 120, maxWidth: 140,
            cellRenderer:params => {
                return <> <GridDateFomartter date={params.data.createdAt} /></>
            }
        },

        { headerName: t("communications"), maxWidth: 140,
            cellRenderer:parameters=>
                <div>
                    <button className=" tooltip_class_communications" onClick={()=>handleGetContactInformation(parameters.data.id)} >
                        <HPrimeIcon
                        icon={"pi-phone"}
                        iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                        />
                    </button>
                    <Tooltip anchorSelect={".tooltip_class_communications"} content={t("listOfContactMethods")} positionStrategy={"fixed"}	 />
                </div>
        },



        { headerName: t("ApprovalDisapproval"),field: 'state',width: 115, maxWidth: 140, cellRenderer:params=>




        <div className={"relative"}>
            {wait && headerName === params.colDef.headerName ? (
                <TinyLoading  />
            ) : (
                //0
                params.data.state === 0 ? (
                    <HPrimeIcon
                        onClick={async () => await handleChangeStatus(params.data.id, params.colDef.headerName)}
                        iconClass={'cursor-pointer text-themeSuccess'}
                        icon={"pi-check-square"}
                    />
                    //1
                ) : params.data.state === 1 ? (
                    <HPrimeIcon
                        onClick={() => handleChangeStatus(params.data.id, params.colDef.headerName)}
                        iconClass={'cursor-pointer text-themeSuccess'}
                        icon={"pi-check-square"}
                    />
                    //2
                ) : params.data.state === 2 ? (
                    <HPrimeIcon
                        onClick={() => handleChangeStatus(params.data.id, params.colDef.headerName)}
                        iconClass={'cursor-pointer text-themeDanger'}
                        icon={"pi-times-circle"}
                    />
                ) : null
            )}
        </div>

        },







        { headerName: t("Answers"), minWidth: 120,maxWidth: 130,
            cellRenderer:parameters=>
                <div>
                    <button type="button" onClick={()=>redirectTo(`${url}?commentId=${parameters.data.id}`)} className="table_Btn text-xs bg-themeInformation dark:bg-themeDarkInformation text-white" >
                        <HPrimeIcon
                        icon={"pi-folder"}
                        />
                        {t('Response')}
                    </button>
                </div>
        },


        { headerName: t("operations"), maxWidth: 150,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleEditComment(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleRemoveComment(params.data.id)}
                    />
                </div>
            }
        },
    ];


    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);

    const [selectedRows, setSelectedRows] = useState([]);
    const onSelectionChanged = (row) => {
        setSelectedRows(row.api.getSelectedRows());
    };


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',today:null,yesterday:null,thisWeek:null,thisMonth:null,threeMonth:null,state:null,commentId:null,slug:""});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const handleFilterValue=(data,name)=>{
        setFilter(({...filter,[name]: data.value}));
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search:'',today:null,yesterday:null,thisWeek:null,thisMonth:null,threeMonth:null,state:null,commentId:null,slug:""}));
        setSearch('');
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [result, currentPage,filter.search, data.pageSize, filter.today, filter.yesterday,filter.thisMonth,filter.state,filter.threeMonth,filter.thisWeek,queryParams.commentId,filter.slug]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/comments`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search, Today: filter.today, Yesterday: filter.yesterday,ThisWeek:filter.thisWeek,ThisMonth:filter.thisMonth,ThreeMonths:filter.threeMonth,CommentId:queryParams.commentId?queryParams.commentId:null,Slug:filter.slug,State:state}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };




    const switchData = [
        {name:'today',lable:t('Today'),value:filter.IsActive},
        {name:'yesterday',lable:t('Yesterday'),value:filter.IsActive},
        {name:'thisWeek',lable:t('ThisWeek'),value:filter.IsActive},
        {name:'thisMonth',lable:t('ThisMonth'),value:filter.IsActive},
        {name:'threeMonth',lable:t('ThreeMonth'),value:filter.IsActive},
    ]





    return {
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        data,
        setData,
        currentPage,
        setCurrentPage,
        handlePreviousPage,
        handleCurrentPage,
        handlePageSizeCount,
        handleNextPage,
        handleSearch,
        filterValueHandler,
        resetFilters,
        rowData,
        switchData,
        filter,
        selectedRows,
        onSelectionChanged,
        search,
        setSearch,
        setFilter,
        handleFilterValue,
        contact,
        visible,
        setVisible,
        handleCloseMessage,
        message
    }
}