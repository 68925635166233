import React, {useEffect, useMemo, useState} from "react";

import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET} from "../../../Services/AxiosService/AxiosApiService";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";

export const useCustomBankAccountGrid=(showSpinner,hideSpinner,refresh,Refresh,setIsOpen,setBankAccount,setIsEdit,initialSource)=>{
    const { t } = useTranslation();
    const handleGetBankAccount= async(bankAccountId)=>{
        try {
            showSpinner()
            setIsOpen(true)
            setIsEdit(true);
            const response = await GET(`/Accountings/BankAccounts/${bankAccountId}`,{},'single');
            if(response.status === 200){
                setBankAccount({
                    bankAccountId:response.data.bankAccountId,
                    title:response.data.title,
                    bankId:initialSource.banks.find((item)=>item.value === response.data.bankId),
                    accountNumber:response.data.accountNumber,
                    cardNumber:response.data.cardNumber,
                    shebaNumber:response.data.shebaNumber,
                    description:response.data.description
                })
               hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleDeleteBankAccount = async (bankAccountId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/Accountings/BankAccounts/${bankAccountId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        hideSpinner()
                        Refresh()
                        handleSuccessMessage(t('SuccessfullyDeleted'))
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("Title"), minWidth: 160,
            cellRenderer:parameters=>
                <>
                    {parameters.data.title}
                </>
        },

        { headerName: t("BankName"), minWidth: 140,
            cellRenderer:parameters=>

                <>{parameters.data.bankName}</>
        },
        { headerName: t("BankAccountNumber"), minWidth: 160,
            cellRenderer:parameters=>
                <>{parameters.data.accountNumber}</>
        },
        { headerName: t("cardNumber"), minWidth: 180,
            cellRenderer:parameters=>
                <>{parameters.data.cardNumber}</>
        },
        { headerName: t("BankID"), minWidth: 150,
            cellRenderer:parameters=>
                <>{parameters.data.shebaNumber}</>
        },

        { headerName: t("operations"), maxWidth: 120,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetBankAccount(params.data.bankAccountId)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteBankAccount(params.data.bankAccountId)}
                    />
                </div>
            }

        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:''});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: ''}));
        setSearch('');
    }

    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data.value}));
    }



    useEffect(() => {

       OnGridReady();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize]);

    const [rowData, setRowData] = useState([]);



    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Accountings/BankAccounts`, {PageNumber: pagination.currentPage, PageSize:pagination.pageSize, Search: filter.search}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                hideSpinner();
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };




    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter,
    }





}