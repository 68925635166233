import React, {useState} from "react";
import {validate} from "../../../../Services/Globals/validate";
import {documentGroupSchema} from "./documentGroupSchema";
import {GetModifiedDocumentGroup, handleGetDocumentGroup} from "./documentGroupService";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";

import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const useCustomDocGroup=(showSpinner,hideSpinner,handleCloseIsOpen,handleSelectValue,setIsOpen,setDocument,setInitialSource)=>{
    const {t}=useTranslation();
    const [GroupErrors,setGroupErrors] = useState({});
    const [IsGroupEdit,setIsGroupEdit] = useState(false);
    const [group,setGroup] = useState({title:'',description:'',order:0,harmonyDocumentGroupId:null});

    const handleGroupValue = (e) => {
        const { name, value } = e.target;
        setGroup((prevState) => ({ ...prevState, [name]: value }));
        setGroupErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };
    const handleGroupNumberValue = (value,name)=>{
        setGroup({...group,[name]:value});
        setGroupErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const onSetErrors=(errors)=>{
        setGroupErrors(errors)
    }

    const handleSubmitDocumentGroup=async(e)=>{
        e.preventDefault();
        const isValid = validate(documentGroupSchema,group,onSetErrors)
        if(!isValid){
            return;
        }
        try {
            if(IsGroupEdit){
                showSpinner()
                const docGroup = GetModifiedDocumentGroup(group);
                const response = await PUT(`/HarmonyDocumentGroups`,docGroup,false);
                if(response.status === 200){
                    hideSpinner();
                    handleCloseIsOpen();
                    handleSuccessMessage(t('SuccessDone'));
                    await handleAllDocumentGroupFromServer()

                }
                else{
                    handleApiError(response,hideSpinner)
                }
            }
            else
            {
                showSpinner()
                const docGroup = handleGetDocumentGroup(group);
                const response = await POST(`/HarmonyDocumentGroup`,docGroup,false);
                if(response.status === 200){
                    hideSpinner();
                    handleCloseIsOpen();
                    handleSuccessMessage(t('SuccessDone'));
                    await handleAllDocumentGroupFromServer()

                }
                else{
                    handleApiError(response,hideSpinner)
                }
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    const CustomOption = (props) => {
        return (
            <div  className={'flex justify-between p-2 hover:bg-themeInformation hover:text-white items-center'} onClick={() => handleSelectValue(props.data,'documentGroupId')}>
                <div  className={"flex items-center gap-2"}>
                    <button onClick={()=>handleDeleteDocumentGroup(props.data.value)}><HPrimeIcon iconClass={'text-themeDanger'} icon={'pi-trash'} /></button>
                    <button onClick={()=>GetDocumentGroup(props.data.value)}><HPrimeIcon iconClass={'text-themeSuccess'} icon={'pi-pen-to-square'} /></button>
                </div>
                <div className={'cursor-pointer'}>{props.label}</div>
            </div>
        );
    };

    const GetDocumentGroup=async (groupId)=>{
        try {
            showSpinner();
            const response =await GET(`/HarmonyDocumentGroup/${groupId}`,{},'single');
            if(response.status === 200){
                setGroup({
                    title:response.data.title,
                    description:response.data.description,
                    order:response.data.order,
                    harmonyDocumentGroupId:response.data.harmonyDocumentGroupId
                })
                setIsOpen(true);
                hideSpinner();
                setIsGroupEdit(true)
            }else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner();
        }
    }

    const handleDeleteDocumentGroup = async (groupId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner()
                    const response = await DELETE(`/HarmonyDocumentGroups/${groupId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        hideSpinner()
                        handleSuccessMessage(t("RemovedSuccessfully"))
                        await handleAllDocumentGroupFromServer()
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const handleAllDocumentGroupFromServer=async()=>{
        const responseGroup = await GET(`HarmonyDocumentGroups`,{},'IEnumerable');
        if(responseGroup.status === 200){
            const groups=convertSelectDataHandler(responseGroup.data);
            setInitialSource(prevState => ({...prevState,documentGroups: groups}));
            setDocument(prevStates=>({...prevStates,documentGroupId: groups[0]}));
            hideSpinner()
        }
        else{
            handleApiError(responseGroup,hideSpinner)
        }
    }

    return {
        handleGroupValue,
        group,
        setGroup,
        handleGroupNumberValue,
        handleSubmitDocumentGroup,
        CustomOption,
        setIsGroupEdit,
        setGroupErrors,
        GroupErrors
    }
}