import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";



export const  GridButtonOprations =({onClick,icon}) => {

    const { t } = useTranslation();


    return(
      <>

          <button type={"button"}
                  className={"grid place-content-center"}
                  onClick={onClick}
          >
              <HPrimeIcon icon={"pi-ellipsis-v"} iconClass={"dark:text-gray-300"} sizeIcon={"primIcon_FontSize_xs"} />
          </button>


  </>)
}