import React from "react";

import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {ButtonSubmitAndCancel} from "../Commans/UIParts/ButtonSubmitAndCancel";
import {useTranslation} from "react-i18next";
import {UploadImage} from "../Commans/Uploaders/UploadImage";
import Select from "react-select";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";


export const HandleAddGoodsInventory = () => {

  const { t } = useTranslation();
  const redirectTo = useNavigate();


  return(
      <div class="space-y-5 ">
        <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
          <ListFilter label={null} icon={null}
                      childrenOne={
                        <>
                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/Goods/Add")}
                              title={t("Inventory")}
                              tooltipTitle={t("CreateInventory")}
                          />
                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/EntryDocument")}
                              title={t("DocumentRegistration")}
                              tooltipTitle={t("DocumentRegistration")}
                              icon={"file-plus"}
                          />
                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/ProductionSeries")}
                              title={t("ProductionSeries")}
                              tooltipTitle={t("ProductionSeries")}
                              icon={"edit"}
                          />

                          <ButtonPlus
                              onClick={() => redirectTo("/Inventory/List/Requests")}
                              title={t("list")}
                              tooltipTitle={t("ListOfRequests")}
                              icon={"list"}
                          />

                          <ButtonPlus
                              onClick={()=>redirectTo(`/Inventory/Report`)}
                              icon={"pie-chart"}
                              title={t("Report")}
                              functionClass={"harmony-return-list"}
                              tooltipTitle={t("InventoryStockReport")}

                          />

                        </>
                      }
                      childrenTwo={
                        <>

                        </>
                      }
          />

        </div>
        <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-3">
            <div class=" p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
              <h2 className={"dark:text-gray-300"}>{t("GoodsRegistration")}</h2>
            </div>
            <div class="space-y-5">
              <div class="grid grid-cols-12 gap-5 items-center ">
                <div class="col-span-12 lg:col-span-3">
                    <div class="w-40 h-40 relative  mx-auto">
                      <UploadImage  removeName={'ThumbnailImagePath'} accept={'image/*'}  name={'ThumbnailImage'} />
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-9 space-y-4 post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <div class="space-y-1">
                      <label className="text-xs dark:text-gray-300">{t("GoodsName")}</label>
                      <InputText className="input w-full" />
                    </div>
                    <div class="space-y-1">
                      <label className="text-xs dark:text-gray-300">{t("ProductDescription")}</label>
                      <InputText className="input w-full" />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div class="space-y-2">
                      <div className="flex items-center gap-2">
                        <label className="text-xs dark:text-gray-300">{t("ProductCode")}</label>
                        <div class="can-toggle demo-rebrand-1 text-xs w-28">
                          <input id="swich" type="checkbox"/>
                          <label htmlFor="swich">
                            <div class="can-toggle__switch" data-checked={t("automatic")} data-unchecked={t("manual")}></div>
                          </label>
                        </div>
                      </div>
                      <div>
                        <InputText type={"text"} className={"input w-full "} />
                      </div>
                    </div>
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">{t("ProductBarcode")}</label>
                        <InputText className="input w-full"/>
                    </div>
                    <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">{t("IranCode")}</label>
                        <InputText className="input w-full"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="space-y-5 ">
                <div className="grid grid-cols-1 md:grid-cols-4 p-3 gap-5 post-input dark:bg-themeDarkPostInput post-input-design">
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("TaxRate")}</label>
                    <div className="p-inputgroup ">
                      <InputText className="input w-full" />
                      <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">%</span>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("TollRates")}</label>
                    <div className="p-inputgroup">
                      <InputText className="input w-full" />
                      <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">%</span>
                    </div>
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("ServiceFee")}</label>
                    <div className="p-inputgroup">
                      <InputText className="input w-full" />
                      <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">$</span>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 p-3 gap-5 post-input dark:bg-themeDarkPostInput post-input-design">
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("UnitMeasurement")}</label>
                    <InputText className="input w-full" />
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("PurchasePrice")}</label>
                      <InputText className="input w-full" />
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("UnitPrice")}</label>
                      <InputText className="input w-full" />
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("WholesalePrice")}</label>
                      <InputText className="input w-full" />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4 p-3 gap-5 post-input dark:bg-themeDarkPostInput post-input-design">
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("Producer")}</label>
                    <Select
                        placeholder={t("selection")}
                        classNamePrefix={"h_multi_select"}
                        menuPosition={'fixed'}
                    />
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("Supplier")}</label>
                    <Select
                        placeholder={t("selection")}
                        classNamePrefix={"h_multi_select"}
                        menuPosition={'fixed'}
                    />
                  </div>
                  <div className="space-y-1 col-span-1 md:col-span-2">
                    <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
                    <Select
                        placeholder={t("selection")}
                        classNamePrefix={"h_multi_select"}
                        menuPosition={'fixed'}

                    />
                  </div>
                </div>
                <div className="p-3 gap-5 post-input dark:bg-themeDarkPostInput post-input-design">
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("description")}</label>
                    <InputTextarea name={"excerpt"} rows={3} cols={30} className={"input w-full"} />
                  </div>
                </div>
                <div className="p-3 gap-5 post-input dark:bg-themeDarkPostInput post-input-design">
                  <div className="space-y-1 flex items-center gap-2">
                    <input className="input input--switch " type="checkbox" checked/>
                    <label className="text-xs dark:text-gray-300">{t("DisplayInTheOnlineStore")}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <ButtonSubmitAndCancel/>
      </div>
  )
}