import React, { useEffect, useState } from 'react';
import { UserPin } from "./UserPin";

import { Tooltip } from "react-tooltip";
import { GridDateFomartter } from "../../../Commans/Grid/GridDateFomartter";


const UserRow = ({ row, index, handleUserPin, handleOpenMenu, t, itemOperations }) => {
    const [updatedOperations, setUpdatedOperations] = useState([]);

    // بر اساس این سرویس ما میتونیم رول مورد نظر رو چک کنیم و اپشن هایی ک میخایم رو نشون بدیم یا نشون ندیم
    const handleContainsRole = (roles, roleName) => {
        const isContains = roles.some((item) => item.name === roleName);
        const updatedOperations = itemOperations.map(operation => {
            if (operation.tooltip === t("PersonalInformation") && isContains) {
                return { ...operation, isShow: true };
            }
            else if (
                operation.tooltip === t("InsurancePolicy") && isContains
            ) {
                return { ...operation, isShow: true };
            }
            return operation;
        });
        setUpdatedOperations(updatedOperations);
    };

    useEffect(() => {
        if (row.roles) {
            handleContainsRole(row.roles, 'EnRoleType_HrmEmployee')
        }
    }, [row.roles]);



    return (
        <div className="post-input dark:bg-themeDarkPostInput post-input-design flex gap-2 overflow-hidden zoom-in relative">

            <UserPin handleUserPin={handleUserPin} id={row.id} isPin={row.isPin} />
            <div className="w-24 h-32 bg-white dark:bg-themeDarkPostInput">
                <img src={row.picture} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="flex-1 p-1 flex flex-col justify-between">
                <div className="space-y-2">
                    <div className="flex items-center gap-1">
                        <div>
                            <div className="size-3 bg-themeSuccess rounded-full border-2 border-white"></div>
                            <div className="size-3 bg-themeDanger rounded-full border-2 border-white hidden"></div>
                        </div>
                        <div className={"flex gap-1 items-center"}>
                            <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Name")}:</h3>
                            <h2 className="line-clamp-1 text-xs dark:text-gray-300">
                                {row.fullName + (row.companyName ? ` - (${row.companyName})` : '')}
                            </h2>
                        </div>
                    </div>
                    <div className={"flex gap-1 items-center"}>
                        <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("PhoneNumber")}:</h3>
                        <span className="text-xs dark:text-gray-300 block">
                            {row.phoneNumber}
                        </span>
                    </div>
                    <div className={"flex gap-1 items-center"}>
                        <h2 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("ExpirationDate")}:</h2>
                        <h3 className="text-xs dark:text-gray-300 block">
                            {row.renewalDate === null ? null : <GridDateFomartter date={row.renewalDate} />}
                        </h3>
                    </div>
                </div>
                <div className={'flex justify-between items-center bg-white p-1 rounded dark:bg-themeDarkSalt700'}>
                    <div className={"flex gap-2 items-center flex-wrap"}>
                        {updatedOperations.map((btn, idx) =>
                            btn.isShow && (
                                <button key={idx} className={`flex zoom-in ${btn.iconClass ? btn.iconClass : "text-themeInformation dark:text-themeDarkInformation"} tooltip_${idx}_${index}`} onClick={() => btn.onClick(row.id, row.fullName)}>
                                    <i className={`pi primIcon_FontSize_xs ${btn.icon}`}></i>
                                    <Tooltip anchorSelect={`.tooltip_${idx}_${index}`} content={btn.tooltip} />
                                </button>
                            )
                        )}

                    </div>
                    <div>
                        <div>
                            <div onClick={() => handleOpenMenu(row.id, row.fullName)} className=" grid place-content-center cursor-pointer">
                                <i className="dark:text-gray-300 pi pi-ellipsis-v" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserRow;
