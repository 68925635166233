//سرویس های مربوط به jwtService
import jwtDecode from "jwt-decode";
//توکن رو در local storage ذخیره میکنه
export const saveAccessToken=(accessToken)=>{
    localStorage.setItem('accessToken',accessToken);
}
export const saveRefreshToken=(refreshToken)=>{
    localStorage.setItem('refreshToken',refreshToken);
}
export const fetchRefreshToken=()=>{
    const refreshToken = localStorage.getItem('refreshToken');
    if(!refreshToken)
        return undefined;
    return refreshToken;
}
// reads token from local storage
export const fetchAccessToken=()=>{
    const token = localStorage.getItem('accessToken');
    if(!token)
        return undefined;
    return token;
}
// removing token from local storage in favour of logging out .
export const RemoveAccessToken=()=>{
    localStorage.removeItem('accessToken');
}
// decoding our token to get accessed to its objects
export const decodeAccessToken=()=>{
   const accessToken = localStorage.getItem('accessToken');
   if(!accessToken)
       return undefined;
   return jwtDecode(accessToken);
}
// checking out that user is still authenticated or not based on token expire time
export const IsAuthenticated=()=>{
    const accessToken=decodeAccessToken()
    if(!accessToken)
        return false;
    var date=Math.ceil(new Date().getTime()/1000);
    if(accessToken.exp<date)
        return false;
    return true;
}
export const hasSpecialPermission = (permission)=>{
    const stacks = decodeAccessToken();
    const permissionsArray = stacks.Permissions.split('-');
    const permissionsDict = {};
    permissionsArray.forEach((permission) => {
        permissionsDict[permission] = true; // You can set any value you want here, such as true or null, to indicate the presence of the permission.
    });
    if (permissionsDict.hasOwnProperty(permission)) {
       return true
    } else {
       return false
    }
}

export const GetSpecialRole=()=>{
   try {
       const token = decodeAccessToken();
       var role = token["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
       if(!role){
// <<<<<<< HEAD
        //    window.location = '/';
// =======
           //window.location = '/';
// >>>>>>>
//            6f63073a4bcb3d337360929c4225b1e950af017e
           return '';
       }
       return role;
   }catch (e) {
       //window.location = '/';
// <<<<<<< HEAD
       console.log(e)
// =======
       console.log(e);
// >>>>>>> 6f63073a4bcb3d337360929c4225b1e950af017e
   }
}

export const GetSpecialProperty=(propName)=>{
    try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            window.location = '/';
        }
        const jwtPayload = jwtDecode(token);
        if (jwtPayload.hasOwnProperty(propName)) {
            const specialProperty = jwtPayload[propName];
            return specialProperty;
        }
    }
    catch (e) {
        window.location = '/';
    }
}


export const GetAuthenticatedUser=()=>{
    try {
        const token = decodeAccessToken();
        var userId = token["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]
        if(userId  === undefined){
            window.location = '/';
            return;
        }
        return userId;
    }catch (e) {
        window.location = '/';
    }
}

export const GetDomainToken=()=>{
    try {
        const token = localStorage.getItem('accessToken');
        if(!token)
            window.location = '/';
        const Jwt =  jwtDecode(token);
        const {DomainToken} = Jwt;
        return DomainToken;
    }
    catch (e) {
        window.location = '/';

    }
}

export const GetParentDomainToken=()=>{
 try {
     const token = localStorage.getItem('accessToken');
     if(!token)
         window.location = '/';
     const Jwt =  jwtDecode(token);
     const {ParentDomainToken} = Jwt;
     return ParentDomainToken;
 }catch (e) {
     window.location = '/';
 }

}