import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
export const PasswordElement=({item,index,handleChangeData,handlePropertyChange})=>{
    const [loading, setLoading] = useState(true);
    const [password,setPassword]=useState({difficultyType:null})

    const fetchData = async () => {
        try {
            setLoading(true);
            const responseDifficulty = await GET(`/Enumerations/EnDifficulty`, {}, "IEnumerable")
            if (
                responseDifficulty.status === 200
            ) {
                const difficultyTypes = convertSelectDataHandler(responseDifficulty.data);
                const diffType = difficultyTypes.find((dif)=>dif.value === item.difficulty.value )
                setPassword({
                    difficultyType: diffType
                })
            }
            else{
                handleApiError(responseDifficulty, setLoading(false))
            }
        } catch (e) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div key={index} className={`    ${item.properties.size !== null ? item.properties.size.className : ''}`}>

                <label className="dark:text-gray-300 harmony-text-xs">
                    {item.properties.caption}
                </label>
                {
                    item.properties.isRequired === true?
                        <RequiredIcon/>
                        :null
                }
                <div className={'relative'}>
                        <span className={'absolute dark:text-gray-300 end-2 top-1/2 -translate-y-1/2 cursor-pointer'}>
                    <i className={
                        item.properties.showPassword
                            ? "pi pi-eye   dark:text-gray-300 "
                            : "pi pi-eye-slash  dark:text-gray-300 "
                    }
                       onClick={() => handlePropertyChange(index,'showPassword',!item.properties.showPassword)}>

                </i>
           </span>
                    <InputText
                        type={item.properties.showPassword ? "text" : "password"}
                        onChange={(e) => handleChangeData(index,'value',e.target.value)}
                        value={item.data?.value}
                        placeholder={item.properties.description}
                        minLength={item.properties.min}
                        maxLength={item.properties.max}
                        readOnly={item.properties.isReadOnly}
                        required={item.properties.isReadOnly}
                        name={item.properties.name}
                    />
                </div>


            </div>
        </>
    )
}