import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";

import {Button} from "primereact/button";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {InputTextarea} from "primereact/inputtextarea";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import queryString from "query-string";
import {useFetchData} from "./useFetchData";
import {useDataBinder} from "./useDataBinder";
import {useCommunication} from "./useCommunication";
import {useModal} from "./useModal";
import {POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {Export} from "../../Commans/Grid/Export";
import {useTrashGrid} from "./useTrashGrid";
import {useNavigate} from "react-router-dom";
import {ContactUs} from "../../Commans/Globals/ContactUs";

import {Dialog} from "primereact/dialog";
// import 'primereact/dialog/dialog.min.css';

export const HandleTrashComment=()=>{

    const [IsEdit,setIsEdit] = useState(false);
    const queryParams = queryString.parse(window.location.search);
    const redirectTo = useNavigate();
    const { t } = useTranslation();
    const [result,setResult] = useState(0);
    const {options,setOptions,handleFetchData,spinner,setSpinner,onApiError} = useFetchData();
    const {comments,setComments,errors,handleNumberValue,handleValue,setErrors,handleSelectValue,onSetErrors}=useDataBinder();
    const {setCommunication,communication,handleAddCommunication,handleRemoveCommunication,handleCommunicationChange} = useCommunication(options,setComments);
    const {handleCloseComment,handleOpenComment,handleOpenCommunication,handleCloseCommunication,modal,setModal} = useModal(setErrors,options,setComments,setCommunication,setIsEdit,'/CMS/Comment/Trash');
    const {autoGroupColumnDef,switchData,rowData,resetFilters,data,handleSearch,filterValueHandler,handleNextPage,handlePageSizeCount,handleCurrentPage,handlePreviousPage,setCurrentPage,currentPage,setData,defaultColDef,getRowHeight,columnDefs,filter,selectedRows,onSelectionChanged,search,setFilter,setSearch,handleFilterValue,contact,message,visible,setVisible} = useTrashGrid(setSpinner,handleFetchData,onApiError,result,setResult,setIsEdit,setModal,setComments,options,setCommunication);

    const handleRestoreAll = async ()=>{
        if(selectedRows.length === 0){
            return;
        }
        const data = {commentIds: selectedRows.map((item) => item.id),};



        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:t('AreyouSureToRestoreAll'),
            text: t('ThereIsNowayback'),
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText:t('Confirm'),
            cancelButtonText:t('Cancel'),
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true);

                    const response = await PUT(`/comments/restore`,data,false);
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                        handleSuccessMessage(t('CommentsRestoredSuccessfully'))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }









    return(
        <>


            <Dialog header="Header" visible={visible}  className={"Dialog-ShowText"} onHide={() => setVisible(false)}>
                {message}
            </Dialog>

            <div className=" space-y-5 relative">

                {
                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("SearchByNameMessage")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("ArticleAddress")}</label>
                            <Select
                                options={options.slugs??[]}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={false}
                                onChange={(data)=>handleFilterValue(data,'slug')}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>

                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t('State')}</label>
                            <Select
                                options={options.states??[]}
                                isMulti={false}
                                isSearchable={true}
                                isClearable={false}
                                onChange={(data)=>handleFilterValue(data,'state')}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>

                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />

                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        {
                                            queryParams.commentId?
                                                <ButtonPlus
                                                    onClick={()=>redirectTo('/CMS/Comment/Trash')}
                                                    icon={"pi-home"}
                                                    title={t("BacktoHome")}
                                                    tooltipTitle={t("BacktoHome")}

                                                />:
                                                null

                                        }

                                        <ButtonPlus
                                            onClick={()=>handleRestoreAll()}
                                            icon={"pi-check-square"}
                                            title={t("ConfirmAll")}
                                            tooltipTitle={t("ApproveSelectedComments")}

                                        />


                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />


                </div>
                <div className={"space-y-2 H-body-ag-grid"} >

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        onSelectionChanged={onSelectionChanged}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}

                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <ToastContainer />
                <Export filters={filter} apiUrl={'/Packages/PackageListExport'}/>

            </div>


            <FantasyModal openModal={modal.communication}  closeModal={handleCloseCommunication} title={t("listOfContactMethods")}>

                <ContactUs userContact={contact}/>

            </FantasyModal>



        </>

    )
}