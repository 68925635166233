import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {
    convertSelectDataHandler,
} from "../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {handleCreateProductMeta, handleGetAllProductMeta} from "./productMetaService";
import {useProductMeta} from "./useProductMeta";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";

import Select from "react-select";
import {InputNumber} from "primereact/inputnumber";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {ToastContainer} from "react-toastify";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const HandleProductMeta=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const queryParams = queryString.parse(window.location.search);
    const [productMeta, setProductMeta] = useState([]);
    const [refresh,setRefresh] = useState(false)
    const[IsShowSpinner,setIsShowSpinner] = useState(false)
    const [initialSource,setInitialSource] = useState({metaTypes:[]});
    const [concurrencyStamp,setConcurrencyStamp] = useState(null)
    const {handleRemoveRow,handleAddRow,handleChange}=useProductMeta(productMeta,setProductMeta,initialSource);
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const handleInitialSource=(responseMetaData)=>{
        const types = convertSelectDataHandler(responseMetaData);
        setInitialSource({
            metaTypes: types
        });
        return {types}
    }

    const fetchData= async()=>{
        try {
            showSpinner();
            const responseMetaData = await GET('/MetaDataTypes/Selection',{},'IEnumerable');
            const response = await GET(`/Products/${queryParams.productId}/Metas`,{},'single');
            if(responseMetaData.status === 200 && response.status === 200){
                const {types} = handleInitialSource(responseMetaData.data);
                setConcurrencyStamp(response.data.concurrencyStamp)
                if(response.data.productMetas.length === 0){
                    handleAddRow(types);
                }
                else{
                    const filteredProductMetas = handleGetAllProductMeta(response.data.productMetas,types);
                    setProductMeta(filteredProductMetas);
                }

            }
            else{
                handleApiError(responseMetaData,hideSpinner)
            }
            hideSpinner()
        }
        catch (e) {
            hideSpinner()
        }
    }


    useEffect(()=>{
        fetchData();
    },[refresh])




    const handleSubmit= async()=>{
        try {
              showSpinner()
                var metas = handleCreateProductMeta(queryParams.productId,concurrencyStamp,productMeta);
                const response = await POST(`/Products/SetProductMetaToProduct`,metas,false);
                if(response.status === 200){
                    hideSpinner();
                    redirectTo('/Shop/Product/List');
                }
                else{
                    handleApiError(response,hideSpinner)
                }
        }
        catch (e) {
            hideSpinner()
        }
    }




    return (<>

        <div className="space-y-5">
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Shop/Product/Add`)}
                                        tooltipTitle={t("NewProduct")}
                                    />
                                </>
                            }
                />


            </div>

            <div className={"relative"}>
                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-3">
                    <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                        <h2 className={"dark:text-gray-300 text-xs"}>{t("CreatingAFeature")}</h2>
                    </div>
                    <div className={"p-2 max_height85vh space-y-5"}>
                        {productMeta.map((row,index) => (
                            <div key={row.id} className="post-input post-input-design dark:bg-themeDarkPostInput p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                                    <div className={"flex items-center gap-2"}>
                                        <div className={"space-y-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("row")}</label>
                                            <div className="number_pluse bg-themeInformation text-white dark:text-gray-300 dark:bg-themeDarkInformation">
                                                {index+1}
                                            </div>
                                        </div>

                                        <div className="flex-1 space-y-1">
                                            <label className={"text-xs dark:text-gray-300"}>{t("Featurename")}</label>
                                            <input type="text" className="input w-full"
                                                   value={row.name}
                                                   onChange={(e) => handleChange(row.id, 'name', e.target.value,row.productMetaId)}
                                            />
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("Featuretype")}</label>
                                        <Select
                                            options={initialSource.metaTypes}
                                            isMulti={false}
                                            isClearable={true}
                                            isSearchable={true}
                                            onChange={(data) => handleChange(row.id, 'type', data,row.productMetaId)}
                                            placeholder={t("selection")}
                                            value={row.type}
                                            menuPosition={'fixed'}
                                            classNamePrefix={"h_multi_select"}
                                        />
                                    </div>
                                    <div className="space-y-1">
                                        <label className={"text-xs dark:text-gray-300"}>{t("Featurevalue")}</label>
                                        <input type="text" className="input w-full"  value={row.value} onChange={(e) => handleChange(row.id, 'value', e.target.value,row.C)}/>
                                    </div>
                                    <div className={"flex gap-2 items-end"}>
                                        <div className="flex-1 space-y-1">
                                            <label className={"text-xs dark:text-gray-300"}>{t("Featurerank")}</label>
                                            <InputNumber className="input_number_plus" inputId={`display-order-${row.id}`} value={row.order} onValueChange={(e) =>
                                                handleChange(row.id,'order',e.value,row.productMetaId) }
                                                         placeholder={t("DisplayPriority")}
                                                         mode="decimal"
                                                         showButtons
                                                         min={0}
                                                         max={100}
                                            />


                                        </div>
                                        <div className="flex gap-2 items-center">
                                            <button type="button"
                                                    className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white"
                                                    onClick={()=>handleAddRow(initialSource.metaTypes)}>
                                                <HPrimeIcon icon={'pi-plus'}  />
                                            </button>

                                            <button type="button" className="button small_button bg-themeDanger text-white "
                                                    onClick={() => handleRemoveRow(row.id,row.productMetaId)}>
                                                <HPrimeIcon icon={'pi-minus'}  />
                                            </button>

                                        </div>
                                    </div>
                                </div>
                        ))}
                    </div>
                </div>
            </div>
            <ButtonSubmitAndCancel
                onRegisterClick={()=>handleSubmit()}
                onCancelClick={()=>redirectTo('/Shop/Product/List')}
            />
            <ToastContainer />
        </div>

    </>)
}