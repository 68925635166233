import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const DisplayFiles = ({ file, index, handleRemoveFile }) => {
    return (

        <div key={index} className={"post-input dark:bg-themeDarkPostInput dark:border-themeDarkColorLine p-1 rounded-md shadow  flex gap-1 items-center"}>
            <span class="number_pluse text-white flex-shrink-0">{index+1}</span>
            <div className={"flex-1 flex items-center justify-between gap-"} >
                <span className="block text-xs dark:text-gray-300 line-clamp-1">{file.name}</span>
                <button className="text-themeDanger bg-themeDanger button" onClick={() => handleRemoveFile(index)} type={"button"}>
                    <HPrimeIcon icon="pi-trash"  iconClass={"text-white"}></HPrimeIcon>
                </button>
            </div>
        </div>
    );
};