import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../../../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ButtonSearchList} from "../../../../Commans/UIParts/ButtonSearchList";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {Return} from "../../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {GridTable} from "../../../../Commans/Grid/GridTable";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import ButtonVideo from "../../../../Commans/UIParts/ButtonVideo";
import {useSetting} from "../../../../Commans/Hooks/useSetting";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {useWebsiteUpgradeGrid} from "./useWebsiteUpgradeGrid";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../../Commans/Grid/Pagination";
import {Export} from "../../../../Commans/Grid/Export";
import {SettingButton} from "../../../../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../../../../Commans/Dates/DateTimePicker";
import {ToggleSwitch} from "../../../../Commans/UIParts/ToggleSwitch";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import queryString from "query-string";
import {useCustomWebsiteUpgrade} from "./useCustomWebsiteUpgrade";
import {GetCurrentDateTime} from "../../../../../Services/Globals/Public/TimeServiceManager";
import {HandleGeneralSetting} from "../../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";



export const HandleWebsitetUpgrade = () => {
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const queryParams = queryString.parse(window.location.search);

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsOpen,setIsOpen] = useState(false);
    const [IsShowSpinner,setShowSpinner] = useState(false);
    const [refresh,setRefresh] = useState(false);
    const [initialSource,setInitialSource] = useState({paymentStates:[],transactionStates:[]});
    const {handleChangeToggle,handleFromDateTime,handleToDateTime,payment,setPayment} = useCustomWebsiteUpgrade()
    const showSpinner=()=>{
        setShowSpinner(true);
    }
    const hideSpinner=()=>{
        setShowSpinner(false)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const handleInitialData=(paymentStates,transactionStates)=>{
        const paymentStatesData = convertSelectDataHandler(paymentStates)
        const transactionStatesData = convertSelectDataHandler(transactionStates)
        setInitialSource({
            paymentStates: paymentStatesData,
            transactionStates: transactionStatesData,
        });
    }
    const handleOpenPayment=(upgradeId)=>{
        setIsOpen(true);
        const expirationDate = GetCurrentDateTime(false,1);
        setPayment({
            upgradeId: upgradeId,
            creationDate: new Date(),
            applyTax: false,
            expirationDate: expirationDate
        })
    }
    const handleClosePayment=()=>{
        setIsOpen(false);
    }


    const fetchData=async()=>{
        try {
           showSpinner();
            const responsePaymentState = await GET(`/Enumerations/EnPaymentStatus`,{},'IEnumerable');
            const responseTransaction = await GET(`/Enumerations/EnTransactionState`,{},'IEnumerable');
            if(responsePaymentState.status === 200 && responseTransaction.status === 200){
               handleInitialData(responsePaymentState.data,responseTransaction.data)
            }
            else{
                handleApiError(responsePaymentState,hideSpinner)
                handleApiError(responseTransaction,hideSpinner)
            }
        }
        catch (e) {
            hideSpinner()
        }
    }
    useEffect(()=>{
        fetchData();
    },[])

const {handleToDate,handleFromDate,autoGroupColumnDef,columnDefs,defaultColDef,handleResetFilters,handleSearch,filter,handleCurrentPage,handleNextPage,handlePreviousPage,handlePageSizeCount,setFilter,search,setSearch,rowData,getRowHeight,pagination} = useWebsiteUpgradeGrid(handleOpenPayment,showSpinner,hideSpinner,refresh,initialSource);



    const handleSubmitWebsiteBill=async(e)=>{
        e.preventDefault();
        try {
           showSpinner()
           const response = await POST(`/Upgrades/ConvertToPayment`,payment,false);
           if(response.status === 200){
               redirectTo('/InvoiceRequest');
           }
           else{
               handleApiError(response,hideSpinner);
           }

        }
        catch (e) {
            hideSpinner();
        }

    }





    return(
    <>
            <div class="space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 space-y-5 p-3">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 ">{t("SearchByBill")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}</label>
                            <DateTimePicker value={filter.fromDate} handleDateTime={handleFromDate}  format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}</label>
                            <DateTimePicker value={filter.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>handleResetFilters()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            icon={'pi-plus'}
                                            title={t("Upgrade")}
                                            tooltipTitle={t("Upgrade")}
                                            onClick={()=>redirectTo(`/Admin/ServiceUpgrade?websiteId=${queryParams.websiteId}`)}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo('/Admin/Website/List')}
                                            title={t("list")}
                                            tooltipTitle={t("WebsiteList")}
                                            icon={"pi-list"}
                                        />
                                    </>

                                }
                                childrenTwo={
                                    <>

                                    </>
                                }
                    />

                </div>
                <div className={"space-y-2 H-body-ag-grid"} >


                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export apiUrl={''} filters={filter}/>
            </div>

            <FantasyModal openModal={IsOpen}  closeModal={handleClosePayment} title={t("Payment")}>
            <form method={'post'} onSubmit={handleSubmitWebsiteBill}>
                <div class="space-y-5">
                    <div class={"grid grid-cols-1 md:grid-cols-2  gap-2"}>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">
                                <SettingButton
                                    tooltipTitle={t("Calendarsettings")}
                                    tooltipId={2}
                                    onClick={()=>handleSetting('GeneralSetting')}
                                />
                                {t("BillingDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleFromDateTime} value={payment.creationDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300">
                                <SettingButton
                                    tooltipTitle={t("Calendarsettings")}
                                    tooltipId={2}
                                    onClick={()=>handleSetting('GeneralSetting')}
                                />
                                تاریخ اعتبار صورتحساب
                            </label>
                            <DateTimePicker handleDateTime={handleToDateTime} value={payment.expirationDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div className="flex items-center gap-2">
                            <label className="text-xs dark:text-gray-300">{t("DiscountCampaign")}</label>
                            <ToggleSwitch tag={'campagin'} customClass={"w-28"} value={payment.applyTax} handleToggleSwitchValue={handleChangeToggle} name={'applyTax'} checked={t("Active")} unchecked={t("Inactive")} />
                        </div>
                    </div>
                    <button type={"submit"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("Billcreation")}</button>
                </div>
            </form>
            </FantasyModal>



            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>


    </>
  )
}