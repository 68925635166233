import React, {useState} from "react";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import TextArea from "../../Commans/UIParts/TextArea";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {useTranslation} from "react-i18next";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {Accordion, AccordionTab} from "primereact/accordion";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {SocialMediaPlatformAccount} from "../SocialAccounts/SocialMediaPlatformAccount";
import {InputText} from "primereact/inputtext";

import Select from "react-select";
import {Tooltip} from "react-tooltip";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import TelegramIcon from "./SocialIcon/TelegramIcon";
import InstagramIcon from "./SocialIcon/InstagramIcon";
import FacebookIcon from "./SocialIcon/FacebookIcon";
import LinkedInIcon from "./SocialIcon/LinkedInIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandleMessengerPublicationList = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [IsConnected,setIsConnected] = useState(false);
    const [IsSendMessage,setIsSendMessage] = useState(false);


    const handleOpenConnect=()=>{
        setIsConnected(true);
    }
    const handleCloseConnect=()=>{
        setIsConnected(false);
    }
    const handleOpenSendMessage=()=>{
        setIsSendMessage(true);
    }
    const handleCloseSendMessage=()=>{
        setIsSendMessage(false);
    }


    return (
        <>
            <div className="space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByName")}</label>
                            <InputText  type="text"  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("PublicationStatus")}</label>
                            <Select
                                    placeholder={t("selection")}
                                    classNamePrefix={"h_multi_select"}
                                    menuPosition={"fixed"}
                            />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}</label>
                            <DateTimePicker format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}</label>
                            <DateTimePicker  format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublishingContent")}
                                            tooltipTitle={t("PublishingContent")}

                                        />

                                        <ButtonPlus
                                            title={t("Accounts")}
                                            icon={"pi-link"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/List")}
                                            tooltipTitle={t("Accounts")}
                                        />


                                        <ButtonPlus
                                            title={t("list")}
                                            icon={"pi-list"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublicationList")}
                                            tooltipTitle={t("PublicationList")}

                                        />

                                        <ButtonPlus
                                            title={t("comments")}
                                            icon={"pi-comments"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/Comments")}
                                            tooltipTitle={t("comments")}
                                            notification={true}
                                        />

                                        <ButtonPlus
                                            title={t("Draft")}
                                            icon={"pi-file-edit"}
                                            onClick={()=>redirectTo("/SocialMarketing/Draft")}
                                            tooltipTitle={t("Draft")}

                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("Instagram")}</lable>
                                            <div>
                                                <input  className="input input--switch "  type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("Facebook")}</lable>
                                            <div>
                                                <input  className="input input--switch "  type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("LinkedIn")}</lable>
                                            <div>
                                                <input  className="input input--switch "  type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("Telegram")}</lable>
                                            <div>
                                                <input  className="input input--switch "  type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("CreationTime")}</lable>
                                            <div>
                                                <input  className="input input--switch "  type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className=" space-y-2 text-center">
                                            <lable className="text-xs dark:text-gray-300">{t("PublicationDate")}</lable>
                                            <div>
                                                <input  className="input input--switch"  type="checkbox"/>
                                            </div>
                                        </div>

                                    </>
                                }
                    />

                </div>

                <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 box bg-white dark:bg-themeDarkSalt700 p-2">
                    <div className={"flex flex-col justify-between post-input dark:bg-themeDarkPostInput messenger_PublicationList overflow-hidden post-input-design"}>
                        <div className={"H_aspect_15 bg-white dark:bg-themeDarkSalt700"}>
                            <img src="/Assets/Image/Avatar/man.svg" alt="" className={"absolute inset-0 size-full"}/>
                            <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                                <HPrimeIconSmall icon={"pi-file-edit"}/>
                                {t("Text")}
                            </div>
                            <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                                <HPrimeIconSmall icon={"pi-image"}/>
                                {t("Photo")}
                            </div>
                            <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                                <HPrimeIconSmall icon={"pi-video"}/>
                                {t("Video")}
                            </div>
                            <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                                <HPrimeIconSmall icon={"pi-images"}/>
                                {t("Album")}
                            </div>
                        </div>
                        <div className="flex flex-col  gap-5 p-3 ">
                            <div class=" flex items-center">
                                <h2 className={"text-xs line-clamp-1 dark:text-gray-300"}>لورم ایپسوم متن ساختگی لورم لورم ایپسوم متن ساختگی</h2>
                            </div>
                            <div className={"space-y-2"}>
                                <div className={"flex justify-between items-center"}>
                                    <h2 className={"text-xs dark:text-gray-300"}>{t("TimingFor")}</h2>
                                    <h2 className={"text-xs dark:text-gray-300 font-light"}>18 تیر 1403 11:15</h2>
                                </div>
                                <div className={"flex justify-between items-center"}>
                                    <h2 className={"text-xs dark:text-gray-300 "}>{t("CreationDate")}</h2>
                                    <h2 className={"text-xs dark:text-gray-300 font-light"}>18 تیر 1403 11:15</h2>
                                </div>
                            </div>
                            <div className={"flex flex-wrap gap-2 justify-center"}>
                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                    <p className={"text-themeSuccess flex items-center gap-1"}>
                                        <HPrimeIconSmall icon={"check"} />
                                        {t("Published")}
                                    </p>
                                </div>
                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                    <p className={"text-themeInformation dark:text-themeDarkInformation flex items-center gap-1"}>
                                        <HPrimeIconSmall
                                            icon={"pi-check"}
                                        />

                                        {t("Scheduled")}
                                    </p>
                                </div>
                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                    <p className={"text-themeInformation dark:text-themeDarkInformation flex items-center gap-1"}>
                                        <HPrimeIconSmall
                                            icon={"pi-arrow-right-arrow-left"}
                                        />
                                        {t("Repeating")}
                                    </p>
                                </div>
                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                    <p className={"text-themeSecondary flex items-center gap-1"}>
                                        <HPrimeIconSmall
                                            icon={"pi-pause-circle"}
                                        />
                                        {t("Unpublished")}
                                    </p>
                                </div>
                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                    <p className={"text-themeInformation dark:text-themeDarkInformation flex items-center gap-1"}>
                                        <HPrimeIconSmall
                                            icon={"pi-hourglass"}
                                        />
                                        {t("AwaitingConfirmation")}
                                    </p>
                                </div>
                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                    <p className={"text-themeDanger flex items-center gap-1"}>
                                        <HPrimeIconSmall
                                            icon={"pi-times-circle"}
                                        />
                                        {t("Canceled")}
                                    </p>
                                </div>
                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                    <p className={"text-themeInformation dark:text-themeDarkInformation flex items-center gap-1"}>
                                        <HPrimeIconSmall
                                            icon={"pi-refresh"}
                                        />
                                        {t("PublishingContent")}
                                    </p>
                                </div>
                            </div>
                            <div className={"flex flex-wrap gap-2 justify-center"}>
                                <TelegramIcon
                                sizeStyle={"size-8"}
                                />
                                <InstagramIcon
                                    sizeStyle={"size-8"}
                                />
                                <LinkedInIcon
                                    sizeStyle={"size-8"}
                                />
                                <FacebookIcon
                                    sizeStyle={"size-8"}
                                />
                            </div>
                        </div>
                        <div class=" w-full flex items-center p-1 gap-1 bgwhite dark:bg-themeDarkPostInput">
                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded  zoom-in tooltip_class_edit"}>
                                <HPrimeIconSmall
                                icon={"pi-pen-to-square"}
                                />
                                <Tooltip anchorSelect={".tooltip_class_edit"} content={t("Edit")} positionStrategy={"fixed"}/>
                            </button>
                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded  zoom-in tooltip_class_comment"}>
                                <HPrimeIconSmall
                                    icon={"pi-comments"}
                                />
                                <Tooltip anchorSelect={".tooltip_class_comment"} content={t("comments")} positionStrategy={"fixed"}/>
                            </button>
                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded zoom-in tooltip_class_again"}>
                                <HPrimeIconSmall
                                    icon={"pi-arrow-right-arrow-left"}
                                />
                                <Tooltip anchorSelect={".tooltip_class_again"} content={t("Resend")} positionStrategy={"fixed"}/>
                            </button>
                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded  zoom-in tooltip_class_statistics"}>
                                <HPrimeIconSmall icon={"pi-chart-line\n"}/>
                                <Tooltip anchorSelect={".tooltip_class_statistics"} content={t("StatisticsAnalysis")} positionStrategy={"fixed"}/>
                            </button>

                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded zoom-in tooltip_class_cancel"}>
                                <HPrimeIconSmall icon={"pi-times-circle"}/>
                                <Tooltip anchorSelect={".tooltip_class_cancel"} content={"Canceled"} positionStrategy={"fixed"}/>
                            </button>
                            <button className={"flex-1 button grid place-content-center bg-themeInformation dark:bg-themeDarkInformation text-white rounded zoom-in tooltip_class_delete"}>
                                <HPrimeIconSmall icon={"pi-trash"}/>
                                <Tooltip anchorSelect={".tooltip_class_delete"} content={t("DeleteAccount")} positionStrategy={"fixed"}/>
                            </button>

                        </div>
                    </div>
                </div>

            </div>

            <FantasyModal openModal={IsConnected}  closeModal={handleCloseConnect} title={t("AddAccount")}>
                <div class="space-y-2 ">
                    <div className={"space-y-2"}>
                        {/*facebook*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("ChooseYourAccountType")}</h2>
                        </div>
                        <div className={"space-y-2"}>
                            <label htmlFor="1" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="1"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("FacebookPage")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("FaceBookPageTitle")}</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="2" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="2"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("FacebookGroup")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("FaceBookGroupTitle")}</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={"space-y-2"}>
                        {/*Linkedin*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("ChooseYourAccountType")}</h2>
                        </div>
                        <div className={"space-y-2"}>
                            <label htmlFor="3" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="3"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("LinkedInPage")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("LinkedInTitle")}</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="4" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="4"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("LinkedInProfile")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("LinkedInProfileTitle")}</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={"space-y-2 rounded box bg-white dark:bg-themeDarkSalt700 p-2"}>
                        {/*instagram*/}
                        <div className={"space-y-2"}>
                            <p className={"dark:text-gray-300 text-xs leading-7"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ex hic modi, odio quis voluptatum! Adipisci doloremque facere libero nihil.</p>
                        </div>
                        <div>
                            <img src="/Assets/Image/Design/Online-connection.svg" alt="Online-connection.svg"/>
                        </div>
                    </div>
                    <div className={"space-y-2 "}>
                        {/*telegram*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("DataSource")}</h2>
                        </div>
                        <div className={"rounded box bg-white dark:bg-themeDarkSalt700 p-2 space-y-2"}>
                            <p className={"dark:text-gray-300 text-xs leading-7"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquid architecto asperiores aut expedita maiores nam odit quasi voluptate. Odio!</p>
                            <div className={"grid grid-cols-1 sm:grid-cols-2 gap-3 "}>
                                <div>
                                    <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    {t("ID")}
                                </span>
                                        <InputText placeholder={t("ID")} className="input w-full" name={'fullName'} />
                                    </div>
                                </div>
                                <div>
                                    <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <HPrimeIcon icon={"at-sign"} />
                                </span>
                                        <InputText placeholder={t("Botfather")} className="input w-full" name={'fullName'} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ButtonSubmitAndCancel
                        registerText={t("Connect")}
                    />
                </div>
            </FantasyModal>

            <FantasyModal openModal={IsSendMessage}  closeModal={handleCloseSendMessage} title={t("PostArticle")} customeClassName={"modal-x"}>
                <div class=" relative">
                    <span className="bg_facebook block w-10 h-10 absolute top-0 end-2"></span>
                    <div className={"mt-2 space-y-3"}>
                        <div className={"space-y-2"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("MessageText")}</label>
                            <TextArea className={"input w-full"}  />
                        </div>
                        <Accordion className="bg-white rounded-md dark:bg-themeDarkSalt700">
                            <AccordionTab header={t("AttachmentFile")}>
                                <div className="space-y-4 max_height20">
                                    <div className="h-32 w-32">
                                        <UploadImage  removeName={'ThumbnailImagePath'} accept={'image/*'}  name={'ThumbnailImage'} />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <div className="post-input dark:bg-themeDarkSalt700 rounded-md border dark:border-themeDarkColorLine">
                            <div className="p-2 bg-gray-200 dark:bg-themeDarkPostInput rounded-t-md dark:text-gray-300">
                                <p className={"dark:text-gray-300"}>{t("CreationDateAndTime")}</p>
                            </div>
                            <div className={"grid grid-cols-1 md:grid-cols-3 lg:col-span-3 p-2 gap-3"}>
                                <div className={"space-y-1"}>
                                    <label className="dark:text-gray-300 text-xs">
                                        {t("SendingType")}
                                    </label>
                                    <Select
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                </div>
                                <div className="gap-y-2 flex flex-col">
                                    <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("SendingDate")}
                                    </label>
                                    <DateTimePicker isContainsTime={true}  format={'YYYY/MM/DD hh:mm:ss'} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                        </div>
                    </div>
                </div>
            </FantasyModal>


            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
        </>
    );
};
