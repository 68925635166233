import React, {useState} from "react";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import TextArea from "../../Commans/UIParts/TextArea";
import {useTranslation} from "react-i18next";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {Accordion, AccordionTab} from "primereact/accordion";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import InstagramIcon from "./SocialIcon/InstagramIcon";
import TelegramIcon from "./SocialIcon/TelegramIcon";
import FacebookIcon from "./SocialIcon/FacebookIcon";
import TextEditor from "./TextEditor";
import {InputTextarea} from "primereact/inputtextarea";
import LinkedInIcon from "./SocialIcon/LinkedInIcon";
import SharedPostIcon from "./SocialIcon/SharedPostIcon";
import NumberIcon from "./SocialIcon/NumberIcon";
import MagicIcon from "./SocialIcon/MagicIcon";
import {TagsInput} from "react-tag-input-component";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const HandleMessengerPublishContent = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [IsConnected,setIsConnected] = useState(false);
    const [IsSendMessage,setIsSendMessage] = useState(false);
     const [IsOpenFileManager, setOpenFileManager] = useState(false);
     const [IsOpenAutomaticCaption, setOpenAutomaticCaption] = useState(false);
     const [IsOpenCaption, setOpenCaption] = useState(false);
     const [IsOpenGlassButton, setOpenGlassButton] = useState(false);
     const [IsOpenSurvey, setOpenSurvey] = useState(false);
     const [IsOpenCreateCaption, setOpenCreateCaption] = useState(false);


    const openFileManager=()=>{
        setOpenFileManager(true)
    }

    const closeFileManager=()=>{
        setOpenFileManager(false)
    }



    const openAutomaticCaption=()=>{
        setOpenAutomaticCaption(true)
    }

    const closeCreateCaption=()=>{
        setOpenCreateCaption(false)
    }

    const openCreateCaption=()=>{
        setOpenCreateCaption(true)
    }


    const closeGlassButton=()=>{
        setOpenGlassButton(false)
    }
    const openGlassButton=()=>{
        setOpenGlassButton(true)
    }


    const closeCaption=()=>{
        setOpenCaption(false)
    }

    const openCaption=()=>{
        setOpenCaption(true)
    }

    const closeAutomaticCaption=()=>{
        setOpenAutomaticCaption(false)
    }

    const openSurvey=()=>{
        setOpenSurvey(true)
    }

    const closeSurvey=()=>{
        setOpenSurvey(false)
    }


    const handleOpenConnect=()=>{
        setIsConnected(true);
    }
    const handleCloseConnect=()=>{
        setIsConnected(false);
    }
    const handleOpenSendMessage=()=>{
        setIsSendMessage(true);
    }
    const handleCloseSendMessage=()=>{
        setIsSendMessage(false);
    }




    return (
        <>
            <div className="space-y-5">

                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            title={t("Accounts")}
                                            icon={"pi-link"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/List")}
                                            tooltipTitle={t("Accounts")}

                                        />

                                        <ButtonPlus
                                            title={t("list")}
                                            icon={"pi-list"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublicationList")}
                                            tooltipTitle={t("PublicationList")}

                                        />
                                        <ButtonPlus
                                            title={t("Publishing")}
                                            icon={"pi-share-alt"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/PublishingContent")}
                                            tooltipTitle={t("PublishingContent")}

                                        />
                                        <ButtonPlus
                                            title={t("comments")}
                                            icon={"pi-comments"}
                                            onClick={()=>redirectTo("/SocialMarketing/Messengers/Comments")}
                                            tooltipTitle={t("comments")}
                                            notification={true}
                                        />

                                        <ButtonPlus
                                            title={t("Draft")}
                                            icon={"pi-file-edit"}
                                            onClick={()=>redirectTo("/SocialMarketing/Draft")}
                                            tooltipTitle={t("Draft")}

                                        />


                                    </>
                                }

                    />

                </div>

                <div class="grid grid-cols-12 gap-3 ">
                    <div className={"col-span-12 xl:col-span-2 p-2 space-y-2 bg-white dark:bg-themeDarkSalt700"}>
                        <div className={"overflow-x-auto"}>
                            <div className={"flex xl:flex-col gap-2"}>
                                <div className="messenger_accounting post-input post-input-design overflow-hidden dark:bg-themeDarkPostInput">
                                    <label htmlFor={"content1"}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-input hidden"
                                            id="content1"
                                        />
                                        <div className="checkbox-tile checkbox-wrapper size-full flex gap-1 px-1 py-4 items-center">
                                            <div className={"relative flex-shrink-0"}>
                                                <div className="size-14 flex-shrink-0 border border-themeInformation rounded-full bg-white dark:bg-themeDarkSalt700 overflow-hidden">
                                                    <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
                                                </div>
                                                <div className={"absolute bottom-0 end-0 rounded-full border border-themeInformation p-1 bg-gray-200 dark:bg-themeDarkPostInput"}>
                                                    <InstagramIcon
                                                        sizeStyle={"size-6"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex-1 space-y-1 checkbox-label">
                                                <h2 className="text-xs line-clamp-1">Harmony ErpNews</h2>
                                                <p className={"text-xs line-clamp-1"}>صفحه اینستاگرام</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="messenger_accounting post-input post-input-design overflow-hidden dark:bg-themeDarkPostInput">
                                    <label htmlFor={"content2"}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-input hidden"
                                            id="content2"
                                        />
                                        <div className="checkbox-tile checkbox-wrapper size-full flex gap-1 px-1 py-4 items-center">
                                            <div className={"relative"}>
                                                <div className="size-14 flex-shrink-0  border border-themeInformation rounded-full bg-white dark:bg-themeDarkSalt700 overflow-hidden">
                                                    <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
                                                </div>
                                                <div className={"absolute bottom-0 end-0 rounded-full border border-themeInformation p-1 bg-gray-200 dark:bg-themeDarkPostInput"}>
                                                    <TelegramIcon
                                                        sizeStyle={"size-6"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex-1 space-y-1 checkbox-label">
                                                <h2 className="text-xs line-clamp-1">Harmony ErpNews</h2>
                                                <p className={"text-xs line-clamp-1"}>صفحه تلگرام</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="messenger_accounting post-input post-input-design overflow-hidden dark:bg-themeDarkPostInput">
                                    <label htmlFor={"content3"}>
                                        <input type="checkbox" className="checkbox-input hidden" id="content3" />
                                        <div className="checkbox-tile checkbox-wrapper size-full flex gap-1 px-1 py-4 items-center">
                                            <div className={"relative"}>
                                                <div className="size-14 flex-shrink-0  border border-themeInformation rounded-full bg-white dark:bg-themeDarkSalt700 overflow-hidden">
                                                    <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
                                                </div>
                                                <div className={"absolute bottom-0 end-0 rounded-full border border-themeInformation p-1 bg-gray-200 dark:bg-themeDarkPostInput"}>
                                                    <FacebookIcon
                                                        sizeStyle={"size-6"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex-1 space-y-1 checkbox-label">
                                                <h2 className="text-xs line-clamp-1">Harmony ErpNews</h2>
                                                <p className={"text-xs line-clamp-1"}>صفحه فیسبوک</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-span-12 lg:col-span-9 xl:col-span-7 p-2 bg-white dark:bg-themeDarkSalt700 "}>
                        <div className={"space-y-3"}>
                            <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-3 box flex justify-between items-center"}>
                                <div className="flex gap-2 items-center">
                                        <input className="input input--switch" type="checkbox"/>
                                        <lable className="text-xs dark:text-gray-300 flex gap-1 items-center" >
                                            <HPrimeIconSmall icon={"pi-question-circle"} iconClass={"dark:text-gray-300"} />
                                            {t("Customization")}
                                        </lable>
                                </div>
                                <div className={"flex items-center gap-2"}>
                                    <div className="flex gap-1 items-center">
                                        <input type="checkbox"  id="x1" className={""}/>
                                        <label htmlFor="x1" className="text-xs cursor-pointer dark:text-gray-300">{t("Post")}</label>
                                    </div>
                                    <div className="flex gap-1 items-center">
                                        <input type="checkbox" id="x2"/>
                                        <label htmlFor="x2" className="text-xs cursor-pointer dark:text-gray-300">{t("Story")}</label>
                                    </div>

                                </div>
                            </div>
                            <div className={"space-y-5"}>
                                <div>
                                    <div className={"overflow-x-auto"}>
                                        <div className={"px-3 flex gap-2 items-end dark:border-themeDarkColorLine"}>
                                            <button className={"social_button active"}>
                                                {t("Main")}
                                            </button>
                                            <button className={"social_button"}>
                                                <InstagramIcon
                                                    sizeStyle={"size-7"}
                                                />
                                            </button>
                                            <button className={"social_button"}>
                                                <TelegramIcon
                                                    sizeStyle={"size-7"}
                                                />
                                            </button>
                                            <button className={"social_button"}>
                                                <LinkedInIcon
                                                    sizeStyle={"size-7"}
                                                />
                                            </button>
                                            <button className={"social_button"}>
                                                <FacebookIcon
                                                    sizeStyle={"size-7"}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <TextEditor
                                            openFileManager={openFileManager}
                                            openAutomaticCaption={openAutomaticCaption}
                                            openCaption={openCaption}
                                            openSurvey={openSurvey}
                                        />
                                    </div>
                                </div>

                                <div>


                                    <Accordion className={"bg-white dark:bg-themeDarkSalt700 space-y-3"}>
                                        {/*عمومی*/}
                                        <AccordionTab
                                            header={
                                                <div className="flex items-center gap-2">
                                                    <HPrimeIconSmall
                                                    icon={"pi-cog"}
                                                    />
                                                    <p className={"text-xs"}>{t("GeneralSettings")}</p>
                                                </div>
                                            }
                                        >
                                            {/*اضافه کردن هشتک*/}
                                            <div className={"space-y-3"}>
                                                <div className="flex gap-2 items-center">
                                                    <NumberIcon
                                                        sizeStyle={"size-7"}
                                                    />
                                                    <lable className="text-xs dark:text-gray-300 flex gap-1 items-center" >
                                                        {t("AddHashtag")}
                                                    </lable>
                                                    <input className="input input--switch" type="checkbox"/>
                                                </div>
                                                <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-3 flex items-end gap-2"}>
                                                    <div className="space-y-1 flex-1">
                                                        <label className={"text-xs dark:text-gray-300"}>{t("AddHashtagTitle")}</label>
                                                        <InputText className={"input "}/>
                                                    </div>
                                                    <button className="button flex-shrink-0 bg-themeInformation dark:bg-themeDarkInformation ">
                                                        <MagicIcon
                                                            fillStyle={"text-white"}
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                            {/*اضافه کردن هشتک*/}
                                        </AccordionTab>
                                        {/*عمومی*/}

                                        {/*تلگرام*/}
                                        <AccordionTab
                                            header={
                                                <div className="flex items-center gap-2">
                                                    <TelegramIcon
                                                        sizeStyle={"size-6"}
                                                        fillStyle={"text-white"}
                                                    />
                                                    <p className={"text-xs"}>{t("Telegram")}</p>
                                                </div>
                                            }
                                        >
                                            <div className={"space-y-3"}>
                                                <div className={"space-y-3"}>
                                                    <div className="flex gap-2 items-center">
                                                        <TelegramIcon
                                                            sizeStyle={"size-7"}
                                                        />
                                                        <lable className="text-xs dark:text-gray-300 flex gap-1 items-center" >
                                                            {t("TelegramSettings")}
                                                        </lable>
                                                        <input className="input input--switch" type="checkbox"/>
                                                    </div>
                                                    <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-3 space-y-5"}>
                                                        <div className="flex gap-1 items-center">
                                                            <input type="checkbox"  id="y1" />
                                                            <label htmlFor="y1" className="text-xs cursor-pointer dark:text-gray-300">{t("PinThePostOnTheChannel")}</label>
                                                            <HPrimeIconSmall
                                                            icon={"pi-question-circle"}
                                                            />

                                                        </div>
                                                        <div className="flex gap-1 items-center">
                                                            <input type="checkbox"  id="y10" />
                                                            <label htmlFor="y10" className="text-xs cursor-pointer dark:text-gray-300">{t("ProtectedPost")}</label>
                                                            <HPrimeIconSmall
                                                                icon={"pi-question-circle"}
                                                            />
                                                        </div>
                                                        <div className="flex gap-1 items-center">
                                                            <input type="checkbox"  id="y2" />
                                                            <label htmlFor="y2" className="text-xs cursor-pointer dark:text-gray-300">{t("AddTheChannelUsernameToCaption")}</label>
                                                            <HPrimeIconSmall
                                                                icon={"pi-question-circle"}
                                                            />
                                                        </div>
                                                        <div className="flex gap-1 items-center">
                                                            <input type="checkbox" id="y3" />
                                                            <label htmlFor="y3" className="text-xs cursor-pointer dark:text-gray-300">{t("DisplayTheDescriptionAboveThePost")}</label>
                                                            <HPrimeIconSmall
                                                                icon={"pi-question-circle"}
                                                            />
                                                        </div>
                                                        <div className="flex gap-1 items-center">
                                                            <input type="checkbox"  id="y4" />
                                                            <label htmlFor="y4" className="text-xs cursor-pointer dark:text-gray-300">{t("HiddenContent")}</label>
                                                            <HPrimeIconSmall
                                                                icon={"pi-question-circle"}
                                                            />
                                                        </div>
                                                        <div className="space-y-1">
                                                            <div className={"flex gap-2 items-center"}>
                                                                <label className="text-xs dark:text-gray-300">
                                                                    {t("GlassButton")}
                                                                </label>
                                                                <button type={"button"} className={"text-xs text-themeInformation dark:text-themeDarkInformation"} onClick={()=>openGlassButton()}>(افزودن)</button>
                                                            </div>
                                                            <div className="space-y-1">
                                                                <Select
                                                                    isMulti={true}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    name={'sendingStates'}
                                                                    placeholder={t("selection")}
                                                                    className={"select-group"}
                                                                    classNamePrefix={"h_multi_select"}
                                                                />
                                                            </div>
                                                            <div className="space-y-1">
                                                                <label className="text-xs dark:text-gray-300">
                                                                    {t("PreviewLink")}
                                                                </label>
                                                                <InputText className={"input w-full"}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionTab>
                                        {/*تلگرام*/}


                                        {/*اینستاگرام*/}
                                        <AccordionTab
                                            header={
                                                <div className="flex items-center gap-2">
                                                    <InstagramIcon
                                                        sizeStyle={"size-6"}
                                                        fillStyle={"text-white"}
                                                    />
                                                    <p className={"text-xs"}>{t("Instagram")}</p>
                                                </div>
                                            }
                                        >
                                            <div className={"space-y-3"}>
                                                <div className={"space-y-3"}>
                                                    <div className="flex gap-2 items-center">
                                                        <InstagramIcon
                                                            sizeStyle={"size-7"}
                                                        />
                                                        <lable className="text-xs dark:text-gray-300 flex gap-1 items-center" >
                                                            {t("DisableComment")}
                                                        </lable>
                                                        <input className="input input--switch" type="checkbox"/>
                                                    </div>
                                                    <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-3 space-y-5"}>
                                                        <div className="flex gap-1 items-center">
                                                            <input type="radio"  id="Q1" />
                                                            <label htmlFor="Q1" className="text-xs cursor-pointer dark:text-gray-300">{t("PostToFeed")}</label>
                                                            <HPrimeIconSmall
                                                                icon={"pi-question-circle"}
                                                            />

                                                        </div>
                                                        <div className="flex gap-1 items-center">
                                                            <input type="radio"  id="Q2" />
                                                            <label htmlFor="Q2" className="text-xs cursor-pointer dark:text-gray-300">{t("ImageCover")}</label>
                                                            <HPrimeIconSmall
                                                                icon={"pi-question-circle"}
                                                            />
                                                        </div>
                                                        <div className="size-32">
                                                            <UploadImage  removeName={'thumbnailPath'} accept={'image/*'} name={'thumbnail'} />
                                                        </div>
                                                        <div className="space-y-1">
                                                            <label className={"text-xs dark:text-gray-300"}>{t("ChangingTheTitleTheRailsSound")}</label>
                                                            <InputText className={"input "}/>
                                                        </div>
                                                        <div className="space-y-1">
                                                            <label className={"text-xs dark:text-gray-300 flex gap-1 items-center"}>
                                                                {t("ProfileTagInTheVideo")}
                                                                <HPrimeIconSmall
                                                                    icon={"pi-question-circle"}
                                                                />
                                                            </label>
                                                            <InputText className={"input "}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*ریلز اینستاگرام*/}
                                                {/*نظر اول*/}
                                                <div className={"space-y-3"}>
                                                    <div className="flex gap-2 items-center">
                                                        <InstagramIcon sizeStyle={"size-7"}/>
                                                        <lable className="text-xs dark:text-gray-300 flex gap-1 items-center" >
                                                            {t("FirstComment")}
                                                        </lable>
                                                        <input className="input input--switch" type="checkbox"/>
                                                    </div>
                                                    <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-3 space-y-5"}>
                                                        <div className="space-y-1">
                                                            <label className={"text-xs dark:text-gray-300"}> {t("FirstComment")}</label>
                                                            <InputText className={"input "}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*نظر اول*/}
                                                {/*پست مشترک*/}
                                                <div className={"space-y-3"}>
                                                    <div className="flex gap-2 items-center">
                                                        <SharedPostIcon
                                                            sizeStyle={"size-7"}
                                                        />
                                                        <lable className="text-xs dark:text-gray-300 flex gap-1 items-center" >
                                                            {t("SharedPost")}
                                                        </lable>
                                                        <input className="input input--switch" type="checkbox"/>
                                                    </div>
                                                    <div className={"post-input post-input-design dark:bg-themeDarkPostInput p-3 space-y-5"}>
                                                        <div className="space-y-1">
                                                            <label className={"text-xs dark:text-gray-300"}>{t("userName")}</label>
                                                            <InputText className={"input "}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*پست مشترک*/}

                                            </div>
                                        </AccordionTab>
                                        {/*اینستاگرام*/}

                                    </Accordion>

                                </div>

                            </div>
                        </div>


                    </div>
                    <div className={"col-span-12 lg:col-span-3 bg-white dark:bg-themeDarkSalt700 p-2"}>
                        <div className={"space-y-3"}>
                            <div className="flex gap-2 items-center p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                <input type="radio" name="2" id="Z1" />
                                <label htmlFor="Z1" className="cursor-pointer dark:text-gray-300">{t("InstantDelivery")}</label>
                            </div>
                            <div className={"p-2 post-input dark:bg-themeDarkPostInput post-input-design space-y-3"}>
                                <div className="flex gap-2 items-center ">
                                    <input type="radio" name="2" id="C2"/>
                                    <label htmlFor="C2" className="cursor-pointer dark:text-gray-300">{t("Timing")}</label>
                                </div>
                                <div className="space-y-1 p-2">
                                    <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("PublicationDate")}
                                    </label>
                                    <div>
                                        <DateTimePicker isContainsTime={true}  format={'YYYY/MM/DD hh:mm:ss'} />
                                    </div>
                                </div>
                                <div className="flex gap-2 items-center ">
                                    <input type="radio" name="2" id="M2"/>
                                    <label htmlFor="M2" className="cursor-pointer dark:text-gray-300">{t("RepetitiveContent")}</label>
                                </div>
                                <div className={"p-2 space-y-2"}>
                                    <div className="space-y-1">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("RepeatAs")}
                                        </label>
                                        <div>
                                            <Select
                                                placeholder={t("selection")}
                                                menuPosition={'fixed'}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>
                                    <div className="space-y-1">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("TheNumberRepetitions")}
                                        </label>
                                        <div>
                                            <Select
                                                placeholder={t("selection")}
                                                menuPosition={'fixed'}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="flex gap-2 items-center">
                                <input type="radio" name="2" id="V2"/>
                                <label htmlFor="V2" className="cursor-pointer dark:text-gray-300">{t("SaveTheDraft")}</label>
                            </div>
                            <ButtonSubmitAndCancel
                                showCancelButton={true}
                            />

                        </div>

                    </div>
                </div>
            </div>

            <FantasyModal openModal={IsOpenFileManager} closeModal={closeFileManager} title={t("FileManagement")} customeClassName={"modal-xl"}>
            </FantasyModal>

            <FantasyModal openModal={IsOpenAutomaticCaption} closeModal={closeAutomaticCaption} title={t("AutomaticCaption")} >
                <div className={"space-y-5"}>
                    <div className={"space-y-3"}>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>{t("Language")}</label>
                                <Select
                                    isMulti={false}
                                    isSearchable={true}
                                    placeholder={t("selection")}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                            <div className={"space-y-1"}>
                                <label className={"text-xs dark:text-gray-300"}>{t("Tone")}</label>
                                <Select
                                    isMulti={false}
                                    isSearchable={true}
                                    placeholder={t("selection")}
                                    menuPosition={"fixed"}
                                    classNamePrefix={"h_multi_select"}
                                />
                            </div>
                        </div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Subject")}</label>
                            <InputTextarea name={"description"}  rows={5} cols={30} className={"input"} />
                        </div>
                    </div>


                    <div>
                        <button  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenCaption} closeModal={closeCaption} title={t("Captions")} >
                <div className={"space-y-5"}>
                    <div className={"max_height85vh space-y-3"}>
                        <div className={"relative flex p-2 items-center gap-2 box bg-white dark:bg-themeDarkSalt700"}>
                            <div className={"space-y-1 flex-1"}>
                                <span className={"text-xs dark:text-themeDarkInformation"}>مسعود احمدی</span>
                                <p className={"line-clamp-1 text-xs"}>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است</p>
                            </div>
                            <button type="button" className={"border dark:border-themeDarkColorLine shadow button bg-themeInformation"}>
                                <HPrimeIconSmall icon={"pi-trash"} className={"text-white"} />
                            </button>
                        </div>

                    </div>
                    <div>
                        <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} onClick={()=>openCreateCaption()}>{t("AddCaptions")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenCreateCaption} closeModal={closeCreateCaption} title={t("CreateCaption")} >
                <div className={"space-y-5"}>
                    <div className={"space-y-2"}>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Name")}</label>
                            <InputText  className={"input"} />
                        </div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Caption")}</label>
                            <InputTextarea name={"description"}  rows={5} cols={30} className={"input"} />
                        </div>
                    </div>
                    <div>
                        <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}>{t("Register")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenGlassButton} closeModal={closeGlassButton} title={"AddTelegramGlassButton"} >
                <div className={"space-y-5"}>
                    <div className={"space-y-2"}>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Title")}</label>
                            <InputText  className={"input"} />
                        </div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("Link")}</label>
                            <InputText  className={"input"} />
                        </div>
                    </div>
                    <div>
                        <button className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}>{t("Register")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsOpenSurvey} closeModal={closeSurvey} title={t("CreatingASurvey")}>
                <div className={"space-y-5"}>
                    <div className={"flex items-center gap-3"}>
                        <div className="flex gap-1 items-center">
                            <input type="radio" name="1" id="S1" />
                            <label htmlFor="S1" className="text-xs cursor-pointer dark:text-gray-300">{t("Simple")}</label>
                        </div>
                        <div className="flex gap-1 items-center">
                            <input type="radio" name="1" id="S3"/>
                            <label htmlFor="S3" className="text-xs cursor-pointer dark:text-gray-300">{t("Quiz")}</label>
                        </div>
                        <div className="flex gap-1 items-center">
                            <input type="radio" name="1" id="S2"/>
                            <label htmlFor="S2" className="text-xs cursor-pointer dark:text-gray-300">{t("SelectMultipleOptions")}</label>
                        </div>
                    </div>
                    <div>
                        <div className={"space-y-1"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("TitleSurvey")}</label>
                            <InputText className="input w-full"/>
                        </div>
                    </div>
                    <div className={"space-y-3"}>
                        <div className="flex gap-2 items-center">
                            <div className={"space-y-1 flex-1 flex items-center gap-2"}>
                                <InputText className="input w-full" placeholder={t("Option")}/>
                            </div>
                            <div className={"flex-shrink-0"}>
                                <label htmlFor="j2" className="text-xs cursor-pointer dark:text-gray-300 flex gap-2 items-center">
                                    <input type="radio" name="2" id="j2"/>
                                    {t("CorrectAnswer")}
                                </label>
                            </div>
                            <div className="flex-shrink-0 flex gap-2">
                                <button  type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                    <HPrimeIcon icon={"pi-plus"} />
                                </button>
                                <button type="button" className="button small_button bg-themeDanger text-white">
                                    <HPrimeIcon icon={"pi-minus"} />
                                </button>
                            </div>
                        </div>
                        <div className="flex gap-2 items-center">
                            <div className={"space-y-1 flex-1 flex items-center gap-2"}>
                                <InputText className="input w-full" placeholder={t("Option")}/>
                            </div>
                            <div className={"flex-shrink-0"}>
                                <label htmlFor="j2" className="text-xs cursor-pointer dark:text-gray-300 flex gap-2 items-center">
                                    <input type="radio" name="2" id="j2"/>
                                    {t("CorrectAnswer")}
                                </label>
                            </div>
                            <div className="flex-shrink-0 flex gap-2 items-center">
                                <button  type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                    <HPrimeIcon icon={"pi-plus"} />
                                </button>
                                <button type="button" className="button bg-themeDanger text-white">
                                    <HPrimeIcon icon={"pi-minus"} />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <button  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsConnected}  closeModal={handleCloseConnect} title={t("AddAccount")}>
                <div class="space-y-2 ">
                    <div className={"space-y-2"}>
                        {/*facebook*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("ChooseYourAccountType")}</h2>
                        </div>
                        <div className={"space-y-2"}>
                            <label htmlFor="1" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="1"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("FacebookPage")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("FaceBookPageTitle")}</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="2" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="2"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("FacebookGroup")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("FaceBookGroupTitle")}</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={"space-y-2"}>
                        {/*Linkedin*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("ChooseYourAccountType")}</h2>
                        </div>
                        <div className={"space-y-2"}>
                            <label htmlFor="3" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="3"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("LinkedInPage")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("LinkedInTitle")}</p>
                                    </div>
                                </div>
                            </label>
                            <label htmlFor="4" className={"block"}>
                                <input type="radio" name="facebook" className={"input-connect-social hidden"} id="4"/>
                                <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                    <span className={"connect-social-span"}></span>
                                    <div>
                                        <span className={"dark:text-gray-300"}>{t("LinkedInProfile")}</span>
                                        <p className={"text-xs text-gray-500"}>{t("LinkedInProfileTitle")}</p>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className={"space-y-2 rounded box bg-white dark:bg-themeDarkSalt700 p-2"}>
                        {/*instagram*/}
                        <div className={"space-y-2 "}>
                            <p className={"dark:text-gray-300 text-xs leading-7"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque ex hic modi, odio quis voluptatum! Adipisci doloremque facere libero nihil.</p>
                        </div>
                        <div>
                            <img src="/Assets/Image/Design/Online-connection.svg" alt="Online-connection.svg"/>
                        </div>
                    </div>
                    <div className={"space-y-2 "}>
                        {/*telegram*/}
                        <div>
                            <h2 className={"dark:text-gray-300"}>{t("DataSource")}</h2>
                        </div>
                        <div className={"rounded box bg-white dark:bg-themeDarkSalt700 p-2 space-y-2"}>
                            <p className={"dark:text-gray-300 text-xs leading-7"}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquid architecto asperiores aut expedita maiores nam odit quasi voluptate. Odio!</p>
                            <div className={"grid grid-cols-1 sm:grid-cols-2 gap-3 "}>
                                <div>
                                    <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    {t("ID")}
                                </span>
                                        <InputText placeholder={t("ID")} className="input w-full" name={'fullName'} />
                                    </div>
                                </div>
                                <div>
                                    <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                    <HPrimeIcon icon={"pi-at"}/>
                                </span>
                                        <InputText placeholder={t("Botfather")} className="input w-full" name={'fullName'} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <ButtonSubmitAndCancel
                        registerText={t("Connect")}
                    />
                </div>
            </FantasyModal>
            <FantasyModal openModal={IsSendMessage}  closeModal={handleCloseSendMessage} title={t("PostArticle")} customeClassName={"modal-x"}>
                <div class="relative">
                    <span className="bg_facebook block w-10 h-10 absolute top-0 end-2"></span>
                    <div className={"mt-2 space-y-3"}>
                        <div className={"space-y-2"}>
                            <label className={"text-xs dark:text-gray-300"}>{t("MessageText")}</label>
                            <TextArea className={"input w-full"}  />
                        </div>
                        <Accordion className="bg-white rounded-md dark:bg-themeDarkSalt700">
                            <AccordionTab header={t("AttachmentFile")}>
                                <div className="space-y-4 max_height20">
                                    <div className="h-32 w-32">
                                        <UploadImage  removeName={'ThumbnailImagePath'} accept={'image/*'}  name={'ThumbnailImage'} />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        <div className="post-input dark:bg-themeDarkSalt700 rounded-md border dark:border-themeDarkColorLine">
                            <div className="p-2 bg-gray-200 dark:bg-themeDarkPostInput rounded-t-md dark:text-gray-300">
                                <p className={"dark:text-gray-300"}>{t("CreationDateAndTime")}</p>
                            </div>
                            <div className={"grid grid-cols-1 md:grid-cols-3 lg:col-span-3 p-2 gap-3"}>
                                <div className={"space-y-1"}>
                                    <label className="dark:text-gray-300 text-xs">
                                        {t("SendingType")}
                                    </label>
                                    <Select
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                </div>
                                <div className="gap-y-2 flex flex-col">
                                    <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("SendingDate")}
                                    </label>
                                    <DateTimePicker isContainsTime={true}  format={'YYYY/MM/DD hh:mm:ss'} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"} >{t("register")}</button>
                        </div>
                    </div>
                </div>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
        </>
    );
};
