import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";


import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../../../Commans/Grid/GridButtonOprations";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const HandleListClass = () => {
  const { t } = useTranslation();

  const [isMenuOpen,setMenuOpen] = useState(false);
  const [userId,setUserId] = useState('');
  const handleOpenMenu = () => {
    setMenuOpen(true);

  };
  const handleCloseMenu = ()=>{
    setMenuOpen(false);
  }


  const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

  const columnDefs = [
    { headerName: 'ردیف', field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
    { headerName: 'عنوان کلاس',
      cellRenderer:parameters=>
          <h2 class="line-clamp-1">
            دوره پیش رفته html css
          </h2>
    },
    { headerName: 'گروه آموزشی', maxWidth: 140,
      cellRenderer:parameters=>
          <h2 className="line-clamp-1">
            فنی حرفه ای
          </h2>
    },
    { headerName: 'سال جاری',maxWidth: 110,
      cellRenderer:parameters=>
          <h2>
            1402
          </h2>
    },
    { headerName: 'تاریخ شروع', field: 'date',width: 130, maxWidth: 130,},
    { headerName: 'تاریخ پایان', field: 'date',width: 130, maxWidth: 130,},


    { headerName: 'نام استاد', minWidth: 160,maxWidth: 200,
      cellRenderer:parameters=>
          <h2 className="line-clamp-1">مسعود احمدی محمدابادی</h2>
    },


    { headerName: t("settings"),width: 110, maxWidth: 120,
      cellRenderer:params => {
        return<div className={'flex justify-center items-center gap-2 '} >
          <GridButtonEdit/>
          <GridButtonDelete/>
          <GridButtonOprations
              onClick={()=>handleOpenMenu()}
          />
        </div>
      }

    },

  ];

  const rowData = [

    { id:1, date:'1402/10/3'},
    { id:2, date:'1402/10/3'},
    { id:3, date:'1402/10/3'},
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      filter: true,
      sortable: true,
      wrapText: true,
      resizable: true,
      // resizable: true,
      // editable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const onSelectionChanged=(data)=>{
    console.log(data);
  }
  const onCellValueChanged = (data)=>{
    console.log(data);
  }


  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };



  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = (event) => {
    setQuickFilterText(event.target.value);
  };




  return(
      <>
        <div class="  space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">

              <ListSearch label={null} icon={null} >
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">جستجو براساس (عنوان کلاس)</label>
                  <InputText type={"search"} className={" input w-full"} />
                </div>
                <div class="flex gap-2">
                  <div class="space-y-2">
                    <label className="text-xs dark:text-gray-300">تاریخ شروع</label>
                    <InputText type={"text"} className={" input w-full"} />
                  </div>
                  <div class="space-y-2">
                    <label className="text-xs dark:text-gray-300">تاریخ پایان</label>
                    <InputText type={"text"} className={" input w-full"} />
                  </div>
                </div>
                <div class="space-y-2">
                  <div class="flex gap-2 items-end">
                    <button className="button w-24 text-white bg-themeInformation dark:bg-themeDarkInformation">جستجو</button>
                    <button className="button text-white bg-themeSecondary">مشاهده لیست</button>
                  </div>
                </div>
              </ListSearch>
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>

                          </>
                        }
                        childrenTwo={
                          <>
                            <div className=" space-y-2 flex flex-col items-center">
                              <lable className="text-xs dark:text-gray-300">شش ماه به انقضاء</lable>
                              <input className="input input--switch border bg-white" type="checkbox"/>
                            </div>
                            <div class=" space-y-2 flex flex-col items-center">
                              <lable className="text-xs dark:text-gray-300">یک سال به انقضاء</lable>
                              <input className="input input--switch border bg-white" type="checkbox"/>
                            </div>
                            <div class=" space-y-2 flex flex-col items-center">
                              <lable className="text-xs dark:text-gray-300">موجود</lable>
                              <input className="input input--switch border bg-white" type="checkbox"/>
                            </div>
                            <div class="space-y-2 flex flex-col items-center">
                              <lable className="text-xs dark:text-gray-300">ناموجود</lable>
                              <input className="input input--switch border bg-white" type="checkbox"/>
                            </div>
                            <div class=" space-y-2 flex flex-col items-center">
                              <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                              <input className="input input--switch border bg-white" type="checkbox"/>
                            </div>
                            <div class=" space-y-2 flex flex-col items-center">
                              <lable className="text-xs dark:text-gray-300">محصولات پرفروش</lable>
                              <input className="input input--switch border bg-white" type="checkbox"/>
                            </div>
                            <div class=" space-y-2 flex flex-col items-center">
                              <lable className="text-xs dark:text-gray-300">هشدار خرید</lable>
                              <input className="input input--switch border bg-white" type="checkbox"/>
                            </div>
                          </>
                        }
            />


          </div>
          <div>
            <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                       enableRtl={true}
                       pagination={true}
                       getRowHeight={getRowHeight}
                       onGridReady={onGridReady}
                       quickFilterText={quickFilterText}
                       style={{ width: '100%', height: '100%' ,position:'relative' }}
            />

          </div>
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
              <div className="sm:col-span-2">
                <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                  <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Pdf"></span>
                    <h2>خروجی (Pdf)</h2>
                  </Button>
                  <Button class="text-gray-700  shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Excel"></span>
                    <h2>خروجی (Exel)</h2>
                  </Button>
                  <Button class="text-gray-700 shadow text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                    <span className="bg_Word"></span>
                    <h2>خروجی (Word)</h2>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
          <div className="grid grid-cols-3 md:grid-cols-4 gap-3">
            <a href="#" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem] justify-center" > <HPrimeIcon size={20} icon={'printer'} />جزئیات کلاس</a>
            <a href="/LMS/Student/List/Add" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem] justify-center" > <HPrimeIcon size={20} icon={'printer'} />افزودن دانشجو</a>
            <a href="#" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem] justify-center" > <HPrimeIcon size={20} icon={'printer'} />ثبت نمرات</a>
          </div>
        </FantasyModal>
      </>



  )
}