import React, {useState} from "react";

export const useRole=(setErrors)=>{
    const [role,setRole] = useState({id:null,name:null,parentId:null,concurrencyStamp:null});
    const handleSelectValue=(data,name)=>{
        setRole({ ...role,[name]: data });
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setRole({...role,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setRole({...role,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    return {role,setRole,handleSelectValue,handleValue,handleNumberValue}
}