import React, {useEffect, useMemo, useState} from "react";

import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET} from "../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";

export const useExchangeGridRate=(setShowSpinner,fetchData,refresh,onApiError,initialSource,setExchange,setIsChange,setIsEdit,Refresh)=>{

    const {t} = useTranslation();

    const handleEditExchange= async(exchangeId)=>{
        try {
            setShowSpinner(true);
            setIsChange({ isRate: true });
            setIsEdit(true);
            const response = await GET(`/ExchangeMoney/${exchangeId}`,{},'single');
            console.log(initialSource);
            console.log(response.data);
            if(response.status === 200){
                setExchange({
                    exchangeMoneyId:response.data.id,
                    currency:initialSource.currencies.find((item)=>item.value === response.data.currency),
                    destinationCurrency:initialSource.currencies.find((item)=>item.value === response.data.destinationCurrency),
                    feeCurrency:initialSource.currencies.find((item)=>item.value === response.data.feeCurrencyType),
                    fee:response.data.fee,
                    symbol:response.data.symbol,
                    rateOnDollar:response.data.rateOnDollar,
                })
                setShowSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleRemoveExchange = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setShowSpinner(true)
                    const response = await DELETE(`/ExchangeMoney/${id}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh(response.status)
                        handleSuccessMessage(t('ExchangeRateRemovedSuccessfully'))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    const handleGetCurrencyName = (currency) => {
        if (!initialSource.currencies) {
            return;
        }
        const data = initialSource.currencies.find((item) => item.value === currency);
        return data ? data.label : '';
    }



    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("SourceCurrency"),maxWidth: 150,
            cellRenderer:parameters=>
                <>
                    {handleGetCurrencyName(parameters.data.currency)}
                </>
        },
        {headerName:t("DestinationCurrency"),maxWidth: 150,

            cellRenderer:parameters=>
                <>
                    {handleGetCurrencyName(parameters.data.destinationCurrency)}
                </>
        },

        { headerName:t("ExchangeRate"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.rateOnDollar}
                </>
        },


        { headerName:t("Symbol"),maxWidth: 150,
            cellRenderer:parameters=>
                <>
                    {parameters.data.symbol}
                </>
        },

        { headerName:t("CommissionRate"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.fee}
                </>

        },
        {headerName:t("commisionCurrency"),maxWidth: 150,

            cellRenderer:parameters=>
                <>
                    {handleGetCurrencyName(parameters.data.feeCurrencyType)}
                </>
        },



        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleEditExchange(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleRemoveExchange(params.data.id)}
                    />
                </div>
            }
        },

    ];





    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({currency:'',search:'',currencyValue:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search: '',currency:'',currencyValue: null}));
        setSearch('');
    }

    const handleFilterValue=(data,name)=>{
        setFilter(({...filter,[name]:data.value,currencyValue: data}));
    }


    useEffect(() => {
        const loadData = async () => {
            try {
                setShowSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    fetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setShowSpinner(false);
            }
        };
        loadData();
    }, [refresh, currentPage, filter.search, data.pageSize,filter.currency]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/ExchangeMoney`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,Currency:filter.currency}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };
    const SwitchFilters = [
        {name:'IsActive',lable:t("isActive"),value:filter.IsActive},
        {name:'IsDeActive',lable:t("isNotActive"),value:filter.IsDeActive}
    ]


    return {
        handleEditExchange,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleRemoveExchange,
        handleSearch,
        SwitchFilters,
        resetFilters,
        filterValueHandler,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        data,
        filter,
        currentPage,
        setFilter,
        handleFilterValue

    }




}