
import React, {useEffect, useState} from "react";
import DatePicker, {DateObject} from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const TimeElement = ({ itemCount,handleDefaultValueChange,SortDescending,SortAccending,index, element, selectedElementIndex, handlePropertyChange, handleSelectTabAfterElementSelection, deleteElement }) =>{




    return (
        <>
            <>
                <div onClick={() => handleSelectTabAfterElementSelection(index)}>
                    <DatePicker
                        format={element.properties.format}
                        name={element.properties.name}
                        className={`harmony-input harmony-w-full ${ index === selectedElementIndex ? 'border-themeInformation' : ''}`}
                        value={element.properties?.defaultValue?.value}
                        disabled={element.properties.isReadOnly}
                        placeholder={element?.properties?.description}
                        id={element.properties.id}
                        buttons={false}
                        disableDayPicker={true}
                        type={'Calendar'}
                        onChange={(value) => handleDefaultValueChange(index, 'value', value)}
                        plugins={[<TimePicker/>]}
                    />
                </div>

                <div className={"harmony_body_button"}>
                    <button
                        type="button"
                        className="button bg-themeDanger text-white harmony_button_form"
                        onClick={() => deleteElement(index)}
                    >
                        <HPrimeIconSmall icon="pi-minus" sizeIcon={"text-xs"} />
                    </button>
                    {
                        index===0?null:
                            <button
                                type="button"
                                className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                                onClick={() => SortAccending(index)}

                            >
                                <HPrimeIconSmall icon="pi-arrow-up" sizeIcon={"text-xs"} />
                            </button>

                    }

                    {
                        index <itemCount - 1?
                            <button
                                type="button"
                                className="button bg-themeInformation dark:bg-themeDarkInformation text-white harmony_button_form"
                                onClick={() => SortDescending(index)}

                            >
                                <HPrimeIconSmall icon="pi-arrow-down" sizeIcon={"text-xs"} />
                            </button>:
                            null
                    }

                </div>


            </>

        </>
    )
}

