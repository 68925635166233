import React, {useEffect, useState} from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {useCustomSendSms} from "./useCustomSendSms";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {TagsInput} from "react-tag-input-component";
import {handleConvertSendMessage} from "./SendingMessageService";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {ToastContainer} from "react-toastify";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import queryString from "query-string";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";

export const HandleSendSms = () => {
  const queryParams = queryString.parse(window.location.search);
  const redirectTo = useNavigate();
  const { t } = useTranslation();
  const [IsEdit,setIsEdit] = useState(false);
  const [showSpinner,setShowSpinner] = useState(false);
  const [initialSource,setInitialSource] = useState({accounts:[],userGroups:[],userNumbers:[],currencies:[]});
  const [refresh,setRefresh] = useState(0);
  const [errors,setErrors] = useState({});
  const [charCount,setCharCount] = useState(0);
  const [messageCount,setMessageCount] = useState(0);
  const [locale,setLocale] = useState('');
  const [cost,setCost] = useState({amount:0,currency:0});
  const [butLoading,setButtonLoading] = useState(false)

  //refresh component
  const Refresh=(state)=>{
    setRefresh({...state});
  }
  // initialize data from server in first request .
  const handleInitialSource=(accounts,userGroup,responseCurrency)=>{
    const accountData = convertSelectDataHandler(accounts);
    const userGroupData = convertSelectDataHandler(userGroup);
    const currencies = convertSelectDataHandler(responseCurrency);

    setInitialSource({
      accounts: accountData,
      userGroups: userGroupData,
      currencies: currencies
    })
    return {accountData}
  }
  const onApiError=()=>{
    setShowSpinner(false)
  }

  // gets the char count of message
  // gets the count of messages based on char count .
  // for example every 5 char is 1 message
  const handleGetCharCount=(count)=>{
    setCharCount(count);
    const lang = handleGetMessageLocale(sms.message);
    if(lang === "English"){
      const increment = Math.floor(count / 145);
      setMessageCount(increment);
    }else{
      const increment = Math.floor(count / 63);
      setMessageCount(increment);
    }
  }
  //gets the locale of message
  const handleGetMessageLocale=(text)=>{
    const englishRegex = /^[a-zA-Z]+$/;
    if(text === '' || text === null){
      return 'NoLanguage'
    }
    if (englishRegex.test(text)) {
      return 'English';
    }else{
      return 'Persian';
    }
  }

  // resets all data
  const handleResetData=()=> {
    setSms({id: initialSource.accounts[0],isFlash: true,sendingDate:null,to: [],message: ''});
    setCharCount(0);
    setMessageCount(0);
  }
  const fetchData=async ()=>{
    try {

      //setShowSpinner(true)
      const responseAccount = await GET(`/Messaging/Accounts/ActiveAccounts`,{},'IEnumerable');
      const userGroup = await GET(`/UserGroups`,{},'IEnumerable');
      const responseCurrency= await GET(`/Enumerations/EnCurrencyType`,'IEnumerable');
      if(responseAccount.status === 200 && userGroup.status === 200 &&responseCurrency.status === 200){
        const {accountData}=handleInitialSource(responseAccount.data,userGroup.data, responseCurrency.data);
        setSms({id: accountData[0],isFlash:false,sendingDate: null,to: [],message: ''});
        setCost({amount:'-',currency:'-'});
        //setShowSpinner(false);
      }
      else{
        handleApiError(responseAccount,onApiError)
      }
    }
    catch (e) {
      console.log(e);
    }
  }

useEffect(()=>{
  fetchData();
},[refresh])


  const {sms,setSms,handleNumberValue,handleSelectValue,handleValue,handleDateTime,handleChangeNumbers}=useCustomSendSms(setErrors,onApiError,setInitialSource,handleGetCharCount,setShowSpinner,setCost)

  //gets the cost of messages
  const GetCurrencyType = (type) => {
    if(type === '-'){
      return '-'
    }
    if (!initialSource.currencies) {
      return;
    }
    const data = initialSource.currencies.find((item) => item.value === type);
    return data ? data.label : '';
  }



  const handleGetCostMessage=async ()=>{
    try {

      if(sms.message === '' || sms.message === null){
        handleFailureMessage(t('PleaseEnterTextMessage'));
        return;
      }
      if(sms.to.length === 0){
        handleFailureMessage(t('PleaseEnterNumbers'));
        return;
      }
      setButtonLoading(true);
      const response = await POST(`/Messaging/Sms/Tariff`,{numbers:sms.to.map(item=>item),smsAccountId:sms.id.value,message:sms.message},'single');
      if(response.status === 200){
        const{amount,currency} = response.data.amount;
        setCost({amount: amount,currency: currency});
        setButtonLoading(false);
      }
      else{
        setButtonLoading(false);
        handleApiError(response,onApiError);
      }
    }
    catch (e) {
      setButtonLoading(false);
    }
  }


  //sending messages
const handleSendMessage=async(e)=>{
    e.preventDefault();
    try {
      setShowSpinner(true);
      if(sms.message === '' || sms.message === null){
        handleFailureMessage(t('PleaseEnterTextMessage'));
        setShowSpinner(false);
        return;
      }
      const smsData = handleConvertSendMessage(sms);
      const response = await POST(`/Messaging/SendMessage`,smsData,false);
      if(response.status === 200){
          handleSuccessMessage(t('SmsSentSuccessfully'));
          setShowSpinner(false);
          handleResetData();
          Refresh();
      }
      else{
        handleApiError(response,onApiError);
      }
    }
    catch (e) {
      onApiError();
      console.log(e);
    }

}

  return(<>
    <div className="relative space-y-5">
      {
        showSpinner?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony/>
              </div>
            </div>
            :
            null
      }
      <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
        <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={
                      <>
                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/Inbox`)}
                            icon={"pi-inbox"}
                            title={t("Receive")}
                            tooltipTitle={t("receivingBox")}

                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SendBoxSms`)}
                            icon={"pi-inbox"}
                            title={t("sending")}
                            tooltipTitle={t("sendingBox")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                            icon={"pi-user"}
                            title={t("Secretary")}
                            tooltipTitle={t("smsSecretary")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                            icon={"pi-code"}
                            title={t("Pattern")}
                            tooltipTitle={t("creatingATemplate")}
                        />
                        <ButtonPlus
                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                            icon={"pi-bolt"}
                            title={t("Charge")}
                            tooltipTitle={t("PanelRecharge")}
                        />
                      </>
                    }

        />

      </div>

      <form method={'post'} onSubmit={handleSendMessage}>
        <div className={"space-y-5"}>
          <div className="grid grid-cols-12 gap-5 ">
            <div className="col-span-12 lg:col-span-8 space-y-3 box bg-white p-3 dark:bg-themeDarkSalt700">
              <div className="flex items-center p-3 bg-themeInformation dark:bg-themeDarkInformation gap-2 post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                <div>
                  <span class="relative">
                    <span class="animate-ping absolute size-full rounded-full bg-themeDanger opacity-75"></span>
                    <span class="relative block rounded-full size-4 bg-themeDanger"></span>
                  </span>
                </div>
                <p class={"text-xs text-white"}>
                  {t("SMSNote1")}
                </p>
              </div>
              <div className={"px-2"}>
                <div className={"p-2 post-input dark:bg-themeDarkSalt700 post-input-design"}>
                  <InputTextarea name={'message'} onInput={(e)=>handleValue(e)} value={sms.message}  rows={20} className={"text-xs"}  placeholder={t("MessageText")}/>
                </div>
              </div>
              <div className="flex items-center p-3 bg-themeInformation dark:bg-themeDarkInformation gap-2 post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                <div>
                  <span class="relative">
                    <span class="animate-ping absolute size-full rounded-full bg-themeDanger opacity-75"></span>
                    <span class="relative block rounded-full size-4 bg-themeDanger"></span>
                  </span>
                </div>
                <p className="text-xs text-white">
                  {t("SMSNote2")}
                </p>
              </div>
              <div className={"px-2"}>
                <div className={"p-2 post-input dark:bg-themeDarkSalt700 post-input-design"}>
                  <TagsInput  onChange={handleChangeNumbers}  classNames={"input"} value={sms.to} name={"to"} placeHolder={t("Numbers")}/>
                </div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-4 box bg-white p-3 dark:bg-themeDarkSalt700 space-y-3">
              <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-3">
                  <div className="space-y-1 ">
                    <label className="text-xs dark:text-gray-300">{t("senderNumber")}</label>
                    <Select
                        options={initialSource.accounts}
                        isSearchable={true}
                        isClearable={false}
                        onChange={(data)=>handleSelectValue(data,'id')}
                        placeholder={t("selection")}
                        value={sms.id}
                        classNamePrefix={"h_multi_select"}
                    />
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("SendTo")}</label>
                    <Select
                        options={initialSource.userGroups}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={false}
                        onChange={(data)=>handleSelectValue(data,'to')}
                        placeholder={t("selection")}
                        classNamePrefix={"h_multi_select"}
                    />
                  </div>
                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">{t("SendingDate")}</label>
                    <DateTimePicker isContainsTime={true} value={sms.sendingDate}  handleDateTime={handleDateTime} format={'YYYY/MM/DD hh:mm:ss'} />
                  </div>
                  <div>
                    <InputSwitch value={sms.isFlash} OnValueSelection={handleSelectValue}  name={'isFlash'} lable={t("FlashMessage")} />
                  </div>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between items-center py-1 px-2 post-input dark:bg-themeDarkPostInput post-input-design">
                  <span className="text-xs dark:text-gray-300">{t("LanguageType")}</span>
                  <p className="p-2 bg-themeInformation dark:bg-themeDarkInformation rounded text-white text-xs text-center">{handleGetMessageLocale(sms.message)}</p>
                </div>
                <div className="flex justify-between items-center py-1 px-2 post-input dark:bg-themeDarkPostInput post-input-design">
                  <h2 className="text-xs dark:text-gray-300">{t("NumberofSMS")}</h2>
                  <p className="p-2 bg-themeInformation dark:bg-themeDarkInformation rounded text-white text-xs min-w-8 text-center">{messageCount}</p>
                </div>
                <div>
                  <div className="flex justify-between items-center py-1 px-2 post-input dark:bg-themeDarkPostInput post-input-design">
                    <h2 className="text-xs dark:text-gray-300">{t("NumberofCharacters")}</h2>
                    <p className="p-2 bg-themeInformation dark:bg-themeDarkInformation rounded text-white text-xs min-w-8 text-center">{charCount}</p>
                  </div>
                </div>
                <div className="flex justify-between items-center p-2 post-input dark:bg-themeDarkPostInput rounded-md shadow border dark:border-themeDarkColorLine">
                  <button
                      type={'button'}
                      className={`button text-white bg-themeInformation dark:bg-themeDarkInformation relative text-xs`}
                      onClick={async ()=>handleGetCostMessage()}
                  >
                    {t("CostComputation")}
                    {butLoading ? (
                        <TinyLoading
                        />
                    ) : (
                        null
                    )}
                  </button>
                  <p className="p-2 bg-themeInformation dark:bg-themeDarkInformation rounded text-white text-xs min-w-8 text-center">{<PriceFormatterComponent price={cost.amount}/>} {GetCurrencyType(cost.currency)}</p>
                </div>

              </div>
            </div>
          </div>
          <ButtonSubmitAndCancel
              onCancelClick={()=>redirectTo('/SmsManagment/SendBoxSms')}
          />
        </div>
      </form>
      <ToastContainer/>

      {/*<div className="flex justify-center lg:justify-end gap-3">*/}
      {/*    <button className="button bg-themeDanger text-white w-24">{t("Cancel")}</button>*/}
      {/*    <button className="button text-white bg-themeInformation dark:bg-themeDarkInformation w-24 ">{t("sending")}</button>*/}
      {/*</div>*/}
    </div>
  </>)
}