import React, {useEffect, useState} from "react";
import { InputText } from "primereact/inputtext";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {UseAddNumbersDataBinders} from "./useAddNumbersDataBinders";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import Select from "react-select";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {ToastContainer} from "react-toastify";
import {validate} from "../../../../Services/Globals/validate";
import {numbersSchema} from "./numbersSchema";
import {GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GetOperatorsData, handleConvertAddNumber, handleConvertEditNumbers} from "./NumbersService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {useAddNumbersGrid} from "./useAddNumbersGrid";
import {InputSwitchListFilter} from "../../../Commans/Filters/InputSwitchListFilter";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {Export} from "../../../Commans/Grid/Export";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {Properties} from "./Properties";
import {useProperties} from "./useProperties";
import {
    CreateDynamicProperties,
    GetInitialProperties
} from "../../HarmonySetting/ShopManagement/Gateway/paymentGatewayService";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";

export const HandleAddSmsNumber = () => {
    const redirectTo = useNavigate();
    const { t } = useTranslation();
    const [IsEdit,setIsEdit] = useState(false);
    const [showSpinner,setShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({operators:[],currencies:[]});
    const [IsNumberOpen,SetIsNumberOpen] = useState(false);
    const [refresh,setRefresh] = useState(0);
    const [errors,setErrors] = useState({});
    const {handleChange,fields,handleChangeProperty,setProperties,properties,setFields} = useProperties();
    const {setNumbers,numbers,handleSelectValue,handleValue,handleNumberValue}=UseAddNumbersDataBinders(setErrors,setFields,setProperties);

    // handles spinner in api response
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // handles errors
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    //refreshes component when it is called
    const Refresh=(state)=>{
        setRefresh({...state});
    }
    // resets all errors to nothing as initial state
    const handleClearErrors=()=>{
        setErrors({});
    }
    // opens modal
    const handleOpenNumber=()=>{
        SetIsNumberOpen(true);
        setNumbers((prevState)=>({...prevState,operator: initialSource.operators[0],stateShow: true}));
        const initialProperties= GetInitialProperties(initialSource.operators[0].fields)
        setFields(initialProperties)
    }
    // closes modal
    const handleCloseNumbers=()=>{
        SetIsNumberOpen(false);
        handleClearErrors();
        setNumbers((prevState)=>({...prevState,operator: initialSource.operators[0],fromNumber: '',name: '', password: '',username: '',stateShow: false}));
        setIsEdit(false);
        setFields([]);
        setProperties([]);
    }
    //handle initial data
    const handleInitialDate=(operators,currencies)=> {
        const operatorsData = GetOperatorsData(operators);
        const currenciesData = convertSelectDataHandler(currencies);
        setInitialSource({operators:operatorsData,currencies: currenciesData});
    }

    const fetchData=async ()=>{
        try {
           const response = await GET(`/Messaging/Operators`,{},'IEnumerable');
           const responseCurrencies = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable')
           if(response.status === 200 && responseCurrencies.status === 200){
               handleInitialDate(response.data,responseCurrencies.data);
           }
           else{
               handleApiError(response,onApiError);
           }
        }
        catch (e) {
            console.log(e);
        }
    }


    const handleSubmitNumbers=async (e)=>{
        e.preventDefault();
        try {
           const isValid = validate(numbersSchema,numbers,onSetErrors);
           if(!isValid){
               return;
           }
           if(IsEdit){
               setShowSpinner(true);
               const dynamicProperties = CreateDynamicProperties(properties);

               const numberData = handleConvertEditNumbers(numbers,dynamicProperties);
               const response = await PUT(`/Messaging/Accounts`,numberData,false);
               if(response.status === 200){
                   handleCloseNumbers();
                   handleSuccessMessage(t("AccountEditedSuccessfully"));
                   Refresh(response.status);
               }
               else{
                   handleApiError(response,onApiError);
               }
           }
           else{
               setShowSpinner(true);
               const dynamicProperties = CreateDynamicProperties(properties);
               const numbersData = handleConvertAddNumber(numbers,dynamicProperties);
              const response = await POST(`/Messaging/Accounts`,numbersData,false);
              if(response.status === 200){
                      setShowSpinner(false);
                      handleCloseNumbers();
                      handleSuccessMessage(t("AccountCreatedSuccessfully"));
                      Refresh(response.status);
              }
              else{
                  handleApiError(response,onApiError);
              }
           }
        }
        catch (e) {
            console.log(e);
        }
    }

    const {autoGroupColumnDef,handleSelectFilter,handleToggleFilter,SwitchFilters,pagination,handleResetFilters,handleDeleteAccount,handleGetAccount,filter,columnDefs,handleCurrentPage,handlePreviousPage,handleSearch,handleNextPage,handlePageSizeCount,defaultColDef,setFilter,search,setSearch,rowData,getRowHeight} = useAddNumbersGrid(setShowSpinner,fetchData,refresh,onApiError,initialSource,setNumbers,SetIsNumberOpen,setIsEdit,Refresh,setProperties)




    return (
        <>
            <div className=" space-y-5 relative ">
                {
                    showSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("OperatorName")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <div className="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("OperatorName")}</label>
                            <Select
                                options={initialSource.operators}
                                isSearchable={true}
                                onChange={(data)=>handleSelectFilter(data,'operator')}
                                placeholder={t("selection")}
                                value={filter.operatorValue}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>handleResetFilters()}
                        />
                    </ListSearch>

                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>handleOpenNumber()}
                                            tooltipTitle={t("addingATextMessageLine")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/Send`)}
                                            tooltipTitle={t("sendingMessage")}
                                            title={t("sending")}
                                            icon={"pi-send"}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/Inbox`)}
                                            icon={"pi-inbox"}
                                            title={t("Receive")}
                                            tooltipTitle={t("receivingBox")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/SendBoxSms`)}
                                            icon={"pi-inbox"}
                                            title={t("sending")}
                                            tooltipTitle={t("sendingBox")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                                            icon={"pi-code"}
                                            title={t("Pattern")}
                                            tooltipTitle={t("creatingATemplate")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                                            icon={"pi-user"}
                                            title={t("Secretary")}
                                            tooltipTitle={t("smsSecretary")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/SmsManagment/SmsSecretery`)}
                                            icon={"pi-bolt"}
                                            title={t("Charge")}
                                            tooltipTitle={t("PanelRecharge")}

                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                                    </>
                                }
                    />


                </div>
                <div className={"space-y-2 H-body-ag-grid"} >

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export apiUrl={''} filters={filter}/>
            </div>

            <FantasyModal openModal={IsNumberOpen}  closeModal={handleCloseNumbers} title={t("addingATextMessageLine")} >
                <form onSubmit={handleSubmitNumbers} method={'post'}>
                    <div className="space-y-2 ">
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-2 "}>
                            <div class="space-y-1">
                                <div className={"flex items-center justify-between"}>
                                    <label className="text-xs dark:text-gray-300">
                                        {t("Name")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.name}/>
                                </div>

                                <InputText type={"text"} name={'name'} value={numbers.name} onInput={(e)=>handleValue(e)} className={" input w-full"} />
                            </div>
                            <div class="space-y-1">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">
                                        {t("UserID")}
                                        <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.fromNumber}/>
                                </div>
                                <InputText value={numbers.fromNumber} name={'fromNumber'} onInput={(e)=>handleValue(e)} type={"text"} className={" input w-full"} />
                            </div>
                            <div class="space-y-1">
                                <div className={"flex justify-between items-center"}>
                                    <label className="text-xs dark:text-gray-300">{t("PanelUrl")}</label>
                                </div>
                                <InputText value={numbers.panelUrl} name={'panelUrl'} onInput={(e)=>handleValue(e)} type={"text"} className={" input w-full"} />
                            </div>
                            {IsEdit ? (
                                properties.map((item,index) => (
                                    <Properties
                                        IsEdit={IsEdit}
                                        key={index}
                                        index={index}
                                        handleChange={handleChangeProperty}
                                        item={item}
                                    />
                                ))
                            ) : (
                                fields && fields.length > 0 ? (
                                    fields.map((item, index) => (
                                        <Properties
                                            IsEdit={IsEdit}
                                            key={index}
                                            index={index}
                                            handleChange={handleChange}
                                            item={item}
                                        />
                                    ))
                                ) : null
                            )}
                        </div>
                        <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Operator")}</label>
                            <Select
                                options={initialSource.operators}
                                isMulti={false}
                                isSearchable={true}
                                onChange={(data)=>handleSelectValue(data,'operator')}
                                placeholder={t("selection")}
                                value={numbers.operator}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <div>
                            <InputSwitch value={numbers.stateShow} OnValueSelection={handleSelectValue}  name={'stateShow'} lable={t("DisplayStatus")} />
                        </div>
                        <div>
                            <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                        </div>
                    </div>
                </form>
            </FantasyModal>
            <ToastContainer/>
        </>

    );
};