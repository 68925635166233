import React, {useState} from "react";
import {toggleExpandedForAll} from "@nosferatu500/react-sortable-tree";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useTranslation} from "react-i18next";

export const useRoleTree=(showSpinner,hideSpinner,setIsOpen,initialSource,setInitialSource,setRole,role,setIsEdit,Refresh)=>{
    const { t } = useTranslation();
    const [searchString, setSearchString] = useState("");
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [searchFoundCount, setSearchFoundCount] = useState(null);
    const [treeData, setTreeData] = useState([]);

    function updateTreeData(treeData) {
        setTreeData(treeData);
    }
    function expand(expanded) {
        setTreeData(
            toggleExpandedForAll({
                treeData,
                expanded,
            })
        );
    }
    function expandAll() {
        expand(true);
    }
    function collapseAll() {
        expand(false);
    }
    const selectPrevMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
                : searchFoundCount - 1
        );
    };
    const selectNextMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
        );
    };

    const getNodeKey = ({ treeIndex }) => treeIndex;
    const customSearchMethod = ({ node, searchQuery }) => {
        return node.name.toLowerCase().includes(searchQuery.toLowerCase());
    };







    const handleGetNode = async(nodeId)=>{
        try {
            showSpinner();
            const response = await GET(`/Roles/${nodeId}`,{},'single');
            if(response.status === 200){
                hideSpinner();
                setIsEdit(true)
                const {id,concurrencyStamp,name,parentId} = response.data;
                const parent = initialSource.roles.find((item) => item.value === parentId)??null;
                setRole(({id:id,name:name,parentId:parent,concurrencyStamp:concurrencyStamp}))
                setIsOpen(true)
            }else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }
    function handleAddNodeChild(nodeId) {
        const parent = initialSource.roles.find((item) => item.value === nodeId)??null;
        setRole({ ...role, parentId: parent,name:''});
        setIsOpen(true);
    }

    const handleDeleteRole = async (nodeId)=>{
        showSpinner();
        const response = await GET(`/Roles/${nodeId}/Children`,{},'single');
        if(response.status === 200){
            if(response.data.hasChildren){
                hideSpinner();
                handleFailureMessage('این نقش دارای زیر مجموعه میباشد و امکان حذف وجود ندارد');
            }else{
                hideSpinner();
                const TailWindButton = Swal.mixin({
                    customClass: {
                        confirmButton: AlertSetting.customClass.confirmButton,
                        cancelButton: AlertSetting.customClass.cancelButton
                    },
                    buttonsStyling: false
                })
                TailWindButton.fire({
                    title:AlertSetting.TailWindButton.title,
                    text: AlertSetting.TailWindButton.text,
                    icon:AlertSetting.TailWindButton.icon,
                    showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                    confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                    cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                    reverseButtons: AlertSetting.TailWindButton.reverseButtons
                }).then( async (result) =>{
                    if (result.isConfirmed) {
                        showSpinner()
                        const response  = await DELETE(`/Roles/${nodeId}`,{headers: { 'Content-Type': 'application/json' }});
                        if(response.status === 200){
                            hideSpinner();
                            Refresh();
                            handleSuccessMessage(t("SuccessDone"));
                        }else{
                            handleApiError(response,hideSpinner);
                        }
                    }
                    else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        return;
                    }
                })
            }
        }
        else{
            handleApiError(response,hideSpinner)
        }
    }
    const handleMoveNode = async(e) => {
        try {
            const Id = e.nextParentNode?.id;
            e.node.parentId = Id !== undefined ? Id : null;
            showSpinner()
            const response = await PUT('/Roles/Parent', { Id: e.node.id,parentId:e.node.parentId });
            if(response.status === 200){
                hideSpinner();
                expandAll();
                Refresh();
                handleSuccessMessage(t("SuccessDone"))
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }
        catch (e) {
            hideSpinner();
        }
    }




    return{
        searchString,
        setSearchString,
        searchFocusIndex,
        setSearchFocusIndex,
        searchFoundCount,
        setSearchFoundCount,
        treeData,
        setTreeData,
        updateTreeData,
        expandAll,
        collapseAll,
        selectPrevMatch,
        selectNextMatch,
        getNodeKey,
        handleAddNodeChild,
        handleDeleteRole,
        handleMoveNode,
        customSearchMethod,
        handleGetNode,
    }
}