import React, {useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {useTranslation} from "react-i18next";
import Select from "react-select";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const HandleFinancialHeadline = () => {

  const { t } = useTranslation();
  const [isMenuOpen,setMenuOpen] = useState(false);

  const handleOpenMenu = () => {
    setMenuOpen(true);

  };
  const handleCloseMenu = ()=>{
    setMenuOpen(false);
  }




  return(
      <>
        <div class="">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3  ">

            <div className="post-input  p-2 rounded-md flex items-end relative">
              <div className="flex-1">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 ">
                  <div className="relative">
                    <InputText type="text" className="input w-full " placeholder="جستجو"/>
                    <HPrimeIcon icon="pi-search"  className="absolute end-1 text-gray-500 top-0 translate-y-1/2" />
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex gap-3 items-center">
                      {/*<button  className="button bg-themeInformation dark:bg-themeDarkInformation text-white flex gap-2"  ><HPrimeIcon icon={'check-square'} />باز کردن همه</button>*/}
                      {/*<button  className="button bg-themeDanger dark:bg-themeSecondary text-white flex gap-2"  ><HPrimeIcon  icon={'minimize-2'} />بستن همه</button>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <button type="button" onClick={() => handleOpenMenu()} className="bg-themeInformation dark:bg-themeDarkInformation button text-white flex gap-1 items-center justify-center">
                  <HPrimeIcon icon="pi-plus" />
                  ایجاد گروه
                </button>
              </div>
            </div>
          </div>
        </div>

        <FantasyModal openModal={isMenuOpen}   closeModal={handleCloseMenu} >
          <div class="space-y-3 ">
            <div class="grid grid-cols-1 md:grid-cols-2 items-end gap-2">
              <div class="space-y-1">
                    <label className="text-xs dark:text-gray-300">نوع حساب</label>
                    <Select
                        placeholder={t("selection")}
                        classNamePrefix={"h_multi_select"}
                        menuPosition={"fixed"}

                    />
                    {/*<select name="" id="" className="select2 w-full">*/}
                    {/*  <option value="1">کل</option>*/}
                    {/*  <option value="1">معین</option>*/}
                    {/*  <option value="">تفضیلی</option>*/}
                    {/*</select>*/}
                  </div>
              <div class="space-y-1">
                    <label className="text-xs dark:text-gray-300">حساب سرشاخه</label>
                    <Select
                        placeholder={t("selection")}
                        classNamePrefix={"h_multi_select"}
                        menuPosition={"fixed"}
                    />
                    {/*<select name="" id="" className="select2 w-full">*/}
                    {/*  <option value="1">کل</option>*/}
                    {/*  <option value="1">معین</option>*/}
                    {/*  <option value="">تفضیلی</option>*/}
                    {/*</select>*/}
                  </div>
              <div class="space-y-1 ">
                <label className="text-xs dark:text-gray-300">کد</label>
                <InputText type={"text"} className={" input w-full"} />
              </div>
              <div class="space-y-1 ">
                <label className="text-xs dark:text-gray-300">عنوان حساب</label>
                <InputText type={"text"} className={" input w-full"} />
              </div>
              <div class="space-y-1 ">
                <label className="text-xs dark:text-gray-300">عنوان حساب(2)</label>
                <InputText type={"text"} className={" input w-full"} />
              </div>
              <div class="space-y-1">
                <label className="text-xs dark:text-gray-300">وضعیت</label>
                <div class="can-toggle demo-rebrand-1 text-xs w-28">
                        <input id="swich" type="checkbox"/>
                        <label htmlFor="swich">
                          <div class="can-toggle__switch" data-checked="غیر فعال" data-unchecked="فعال"></div>
                        </label>
                      </div>
              </div>
            </div>
            <div class="space-y-2 rounded-md border dark:border-themeDarkColorLine bg-white dark:bg-themeDarkSalt700 overflow-hidden">
                  <div class=" p-1 bg-gray-200 dark:bg-themeDarkPostInput rounded-t-md dark:text-gray-300">
                    <h3>ماهیت</h3>
                  </div>
                  <div className="p-2 flex justify-around ">
                    <div className="flex gap-2 items-center">
                      <input type="checkbox" name="" id="x1"/>
                      <label htmlFor="x1" className="text-xs dark:text-gray-300">بدهکار</label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input type="checkbox" name="" id="x2"/>
                      <label htmlFor="x2" className="text-xs dark:text-gray-300">بستانکار</label>
                    </div>
                    <div className="flex gap-2 items-center">
                      <input type="checkbox" name="" id="x3"/>
                      <label htmlFor="x3" className="text-xs dark:text-gray-300">مهم نیست</label>
                    </div>
                  </div>
                </div>
            <div class="space-y-2 rounded-md border dark:border-themeDarkColorLine bg-white dark:bg-themeDarkSalt700 overflow-hidden">
              <div class=" p-1 bg-gray-200 dark:bg-themeDarkPostInput rounded-t-md dark:text-gray-300">
                <h3>ویژگی های حساب</h3>
              </div>
              <div className="p-2 flex justify-around ">
                <div className="flex gap-2 items-center">
                  <input type="checkbox" name="" id="x4"/>
                  <label htmlFor="x4" className="text-xs dark:text-gray-300">کنترل ماهیت دوره</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input type="checkbox" name="" id="x5"/>
                  <label htmlFor="x5" className="text-xs dark:text-gray-300">ارزی</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input type="checkbox" name="" id="x6"/>
                  <label htmlFor="x6" className="text-xs dark:text-gray-300">تسعیر پذیر</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input type="checkbox" name="" id="x7"/>
                  <label htmlFor="x7" className="text-xs dark:text-gray-300"> تفضیلی پذیر</label>
                </div>
                <div className="flex gap-2 items-center">
                  <input type="checkbox" name="" id="x8"/>
                  <label htmlFor="x8" className="text-xs dark:text-gray-300">پیگیری</label>
                </div>
              </div>
            </div>
            <div class=" flex items-center justify-center gap-2">
              <button className="button w-24 bg-themeDanger  text-white"> انصراف</button>
              <button className="button w-24 text-white bg-themeInformation dark:bg-themeInformation"> ثبت</button>
            </div>
          </div>
        </FantasyModal>

      </>

  )
}