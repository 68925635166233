
export const handleConvertWebsiteServer=(server)=>{

    if(server.dedicatedServerId === null && (server.adminPanelServerIp === null || server.adminPanelServerIp === '')){
        return {
            dbServerId:server.dbServerId === null || server.dbServerId === undefined ?null : server.dbServerId.value,
            dbServerIp:server.dbServerIp === null ?null : server.dbServerIp,
            connectionString:server.connectionString === null || server.connectionString === '' ? null : server.connectionString,
            hostServerId:server.hostServerId === null || server.hostServerId === undefined? null: server.hostServerId.value,
            hostServerIp:server.hostServerIp === null ? null : server.hostServerIp,
            webServerId:server.webServerId === null || server.webServerId === undefined ?null: server.webServerId.value,
            webServerIp:server.webServerIp === null? null:server.webServerIp,
            adminPanelServerId:server.adminPanelServerId === null || server.adminPanelServerId === undefined ?null: server.adminPanelServerId.value,
            adminPanelServerIp:server.adminPanelServerIp === null? null:server.adminPanelServerIp,
            mailServerId:server.mailServerId === null || server.mailServerId === undefined ?null: server.mailServerId.value,
            mailServerIp:server.mailServerIp === null? null:server.mailServerIp,
            coreServerId:server.coreServerId === null || server.coreServerId === undefined? null:server.coreServerId,
            coreServerIp:server.coreServerIp === null || server.coreServerIp === undefined? null:server.coreServerIp,

            allowIps:server.allowIps ?? [],
            concurrencyStamp:server.concurrencyStamp,
            webSiteId:server.websiteId

        }
    }
    else{
        return {
            dedicatedServerId:server.dedicatedServerId === null || server.dedicatedServerId === undefined? null:server.dedicatedServerId.value,
            dedicatedServerIp:server.dedicatedServerIp === null || server.dedicatedServerIp === undefined? null:server.dedicatedServerIp,
            allowIps:server.allowIps ?? [],
            concurrencyStamp:server.concurrencyStamp,
            webSiteId:server.websiteId
        }
    }
}