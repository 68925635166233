import React, {useState} from "react";

export const useGoogleSiteMap=(setErrors,initialSource)=>{
    const [siteMap,setSiteMap]= useState({siteMapId:null,name:null,isDefault:false,siteMapType:0,slugFilterType:0,items:[],scheduleFrequency:0})

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSiteMap({...siteMap,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleCheckValue=(value,name)=>{
        setSiteMap({...siteMap,[name]:value});
    }
    const handleSelectValue=(name,value)=>{
        setSiteMap({...siteMap,[name]:value});
    }




    const handleCheckboxChange = (slugType,isChecked) => {
        setSiteMap((prevSiteMap) => {
            const updatedItems = prevSiteMap.items.map(item => {
                if (item.slugType === slugType) {
                    return { ...item, isChecked: isChecked };
                }
                return item;
            });
            return { ...prevSiteMap, items: updatedItems };
        });
    };

    const handleFieldChange = (slugType, field, value) => {
        setSiteMap((prevSiteMap) => {
            const updatedItems = prevSiteMap.items.map(i =>
                i.slugType === slugType ? { ...i, [field]: value } : i
            );
            return { ...prevSiteMap, items: updatedItems };
        });
    };


    return {handleValue,handleCheckValue,siteMap,setSiteMap,handleCheckboxChange,handleFieldChange,handleSelectValue}

}