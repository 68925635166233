import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { GET } from "../../../../../Services/AxiosService/AxiosApiService";
import { convertSelectDataHandler } from "../../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";

export const DateFields = ({ index, element, handlePropertyChange, t }) => {
    const [initialSource, setInitialSource] = useState({
        calendarTypes: [],
        calendarLanguages: [],
        calendarViews: [],
    });
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        if(element.typeName !== 'Time'){
            try {
                setLoading(true);
                const [responseCalendarType, responseCalendarLanguage, responseCalendarView] = await Promise.all([
                    GET(`/Enumerations/EnCalendarType`, {}, "IEnumerable"),
                    GET(`/Enumerations/EnCalendarLangType`, {}, "IEnumerable"),
                    GET(`/Enumerations/EnCalendarView`, {}, "IEnumerable"),
                ]);

                if (
                    responseCalendarType.status === 200 &&
                    responseCalendarLanguage.status === 200 &&
                    responseCalendarView.status === 200
                ) {
                    const calendarTypes = convertSelectDataHandler(responseCalendarType.data);
                    const calendarLanguages = convertSelectDataHandler(responseCalendarLanguage.data);
                    const calendarViews = convertSelectDataHandler(responseCalendarView.data);

                    setInitialSource({
                        calendarTypes,
                        calendarLanguages,
                        calendarViews,
                    });

                    // handlePropertyChange(index, "calendarType", calendarTypes[0]);
                    // handlePropertyChange(index, "calendarLanguage", calendarLanguages[0]);
                    // handlePropertyChange(index, "calendarView", calendarViews[0]);
                } else {
                    handleApiError(responseCalendarType, setLoading(false))
                    handleApiError(responseCalendarLanguage,setLoading(false))
                    handleApiError(responseCalendarView,setLoading(false))
                }
            } catch (e) {
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }else{
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }



    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {
                element.typeName === 'Time'?
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("Format")}</label>
                        <InputText
                            type="text"
                            value={element.properties.format}
                            onChange={(e) => handlePropertyChange(index, 'format', e.target.value)}
                            className={"input w-full"}
                        />
                    </div>
                    :
                    <>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("calendarType")}</label>
                            <Select
                                options={initialSource.calendarTypes}
                                isClearable={false}
                                isSearchable={true}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                                value={element.properties.calendarType}
                                onChange={(data) => handlePropertyChange(index, "calendarType", data)}
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("calendarLanguage")}</label>
                            <Select
                                options={initialSource.calendarLanguages}
                                isClearable={false}
                                isSearchable={true}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                                value={element.properties.calendarLanguage}
                                onChange={(data) => handlePropertyChange(index, "calendarLanguage", data)}
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("calendarView")}</label>
                            <Select
                                options={initialSource.calendarViews}
                                isClearable={false}
                                isSearchable={true}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                                value={element.properties.calendarView}
                                onChange={(data) => handlePropertyChange(index, "calendarView", data)}
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("mask")}</label>
                            <InputText
                                type="text"
                                value={element.properties.format}
                                onChange={(e) => handlePropertyChange(index, 'format', e.target.value)}
                            />
                        </div>
                    </>
            }


        </div>
    );
};
