import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";

export const Statistics=({title,data,icon,url,name,handleGetCurrency,objName,SmsAccountOutputCount,SmsAccountInboxCount})=>{

    const { t } = useTranslation();
    const handleGetPriceColor=(name)=>{
        if(name === 'userWallet'){
            return 'text-themeInformation'
        }
        else if(name === 'todayDeposits'){
            return 'text-themeSuccess'
        }
        else if(name === 'WithdrawalRequestStatistics'){
            return 'text-themeDanger'
        }else{
            return ''
        }
    }

    return (
        <>
            <div className="report-box zoom-in ">
                <Link to={url}
                      className="box bg-white p-3 lg:p-5 dark:bg-themeDarkSalt700 block space-y-4">
                    <div className="flex justify-between items-center">
                        <h2 className={"dark:text-gray-300"}>{title}</h2>
                        <span className={"text-xs dark:text-gray-300"}>{name === 'SmsAccountCredit'? data && data.smsAccountFromNumber:null}</span>
                        <HPrimeIcon
                        icon={icon}
                        iconClass={"dark:text-themeDarkInformation"}
                        />
                    </div>

                    <div className={`${name === 'SmsAccountCredit' || name === 'WithdrawalRequestStatistics'?'flex justify-between items-center':''}`}>
                        <div className={"space-y-2"}>
                            <div className={`text-xl lg:text-3xl ${handleGetPriceColor(name)} dark:text-gray-300`}>
                                <PriceFormatterComponent price={data && data[objName].amount}/>
                            </div>
                            <div className="text-gray-500  dark:text-gray-300">{ data && handleGetCurrency(data[objName].currency)}</div>

                        </div>
                        {
                            name === 'WithdrawalRequestStatistics'?
                                <div className="text-gray-500 dark:text-gray-300">{t("Count")}:
                                    {data && data.totalWithdrawalRequests}
                                </div>
                                :null
                        }
                        {
                            name === 'SmsAccountCredit'?
                                <div>
                                    <div className={"flex justify-between items-center px-1 rounded-ss rounded-se gap-1 text-white bg-themeSuccess "}>
                                        {SmsAccountInboxCount === null?0:SmsAccountInboxCount.inboxCount}
                                        <HPrimeIconSmall
                                        icon={"pi-angle-up"}
                                        />
                                    </div>
                                    <div className={"flex justify-between items-center text-xs px-1 rounded-ee rounded-es gap-1 text-white bg-themeDanger "}>
                                        {SmsAccountOutputCount === null?0:SmsAccountOutputCount.inboxCount}
                                        <HPrimeIconSmall
                                            icon={"pi-angle-down\n"}
                                        />
                                    </div>
                                </div>
                                :null
                        }

                    </div>

                </Link>
            </div>
        </>
    )
}