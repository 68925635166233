import {useTranslation} from "react-i18next";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";

import React, {useEffect, useMemo, useState} from "react";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {GetDynamicPropertiesFromServer} from "./NumbersService";
import {Link} from "react-router-dom";
import {PriceFormatterComponent} from "../../../Commans/Accounting/PriceFormatterComponent";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";

export const useAddNumbersGrid=(setShowSpinner,fetchData,refresh,onApiError,initialSource,setNumbers,setIsNumberOpen,setIsEdit,Refresh,setProperties)=>{

    const {t} = useTranslation();
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [colId,setColId] = useState("");
    const [currency,setCurrency]=useState(0)

    const showLoader=()=>{
        setWait(true)
    }
    const hideLoader=()=>{
        setWait(false)
    }

    const handleGetCurrencyType=(currency)=>{
        const data = initialSource.currencies.find((item)=>item.value === currency);
        console.log(data);
        if(!data){
            return '-'
        }
        return data.label;
    }

    const handleGetAccount= async(accountId)=>{
        try {
            setShowSpinner(true);
            setIsNumberOpen(true);
            setIsEdit(true);
            const response = await GET(`/Messaging/Accounts/${accountId}`,{},'single');
            if(response.status === 200){
                const properties = GetDynamicPropertiesFromServer(response.data.properties)
                setProperties(properties);
                setNumbers({
                    id:response.data.id,
                    operator:initialSource.operators.find((item)=>item.value === response.data.operator),
                    fromNumber:response.data.fromNumber,
                    name:response.data.name,
                    stateShow:response.data.stateShow,
                    username:response.data.username,
                    password:response.data.password,
                    panelUrl:response.data.panelUrl
                })
                setShowSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            setShowSpinner(false);
        }
    }


    const handleDeleteAccount = async (accountId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setShowSpinner(true)
                    const response = await DELETE(`/Messaging/Accounts/${accountId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh(response.status)
                        handleSuccessMessage('حساب با موفقیت حذف شد')
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

const handleGoTo=(panelUrl)=>{
    window.open(panelUrl, '_blank');
}

    const handleChangeActivation = async(accountId,rowName)=>{
        try {
            showLoader()
            setHeaderName(rowName);
            setColId(colId)
            const response = await PUT(`/Messaging/Accounts/Activation`,{smsAccountId:accountId},false);
            if (response.status === 200) {
                hideLoader();
                Refresh(response.status);
            }
            else{
                handleApiError(response,hideLoader)
            }
        }catch (e) {
            hideLoader()
        }
    }
    const handleGetAccountCredit = async (accountId) => {
        try {
            showLoader();
            const response = await GET(`/Messaging/${accountId}/Credit`, {}, 'single');
            if (response.status === 200) {
                const account = rowData.find(item => item.id === accountId);
                if (account) {
                    account.credit = response.data.amount.amount;
                }
                setCurrency(response.data.amount.currency)
                setRowData([...rowData]);
            } else {
                handleApiError(response, hideLoader);
            }
        } catch (e) {
            hideLoader();
        }
    }


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},

        { headerName: t("UserID"),
            cellRenderer:parameters=>
                <>
                    {parameters.data.fromNumber}
                </>
        },
        {headerName:t("Operator"),minWidth: 200,

            cellRenderer:parameters=>
           <>
               {parameters.data.operator.displayName}
           </>

        },

        { headerName:t("Name"),minWidth: 120,
            cellRenderer:parameters=>
                <>
                    {parameters.data.name}
                </>

        },

        {
            headerName: t("ChargeRate"),minWidth: 140,
            cellRenderer: parameters => (
                <>
                    {
                        !parameters.data.credit ? (
                            <div className="cursor-pointer" onClick={() => handleGetAccountCredit(parameters.data.id)}>
                                {t('ChargeRate')}
                            </div>
                        ) : (
                            <>
                                <PriceFormatterComponent price={parameters.data.credit} />  {handleGetCurrencyType(currency)}
                            </>
                        )
                    }
                </>
            )
        },

        { headerName:t("IncreaseBalance"),maxWidth: 140,
            cellRenderer:parameters=>
                <div className={'cursor-pointer'}>
                    {
                        parameters.data.panelUrl?
                            <button type={"button"} onClick={()=>handleGoTo(parameters.data.panelUrl)} className="table_Btn bg-themeInformation dark:bg-themeDarkInformation text-white text-xs">
                                <HPrimeIconSmall icon={"pi-bolt"} />
                                {t("SMSCharge")}
                            </button>:
                            "-"
                    }
                </div>

        },


        { headerName: t("Status"),field: 'stateShow', maxWidth: 115, cellRenderer:params=>
                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && colId === params.data.id ? (
                        <TinyLoading  />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                            onClick={async () => await handleChangeActivation(params.data.id,params.colDef.headerName)}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeActivation(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>
        },


        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetAccount(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteAccount(params.data.id)}
                    />
                </div>
            }
        },

    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',operator:'',isActive:null,isDeActive:null,operatorValue:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const SwitchFilters = [
        {name:'isActive',lable:t("isActive"),value:filter.isActive},
        {name:'isDeActive',lable:t("isNotActive"),value:filter.isDeActive}
    ]

    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',isActive: null,isDeActive: null,operator:'',operatorValue: null}));
        setSearch('');
    }


    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data.value,operatorValue: data}));
    }



    useEffect(() => {
        const loadData = async () => {
            try {
                setShowSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    fetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
            } finally {
                setShowSpinner(false);
            }
        };
        loadData();
    }, [refresh, pagination.currentPage, filter.search, pagination.pageSize,filter.operator,filter.isActive,filter.isDeActive]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/Messaging/Accounts`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,Operator:filter.operator,IsActive:filter.isActive,IsDeActive:filter.isDeActive}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };




    return {
        handleGetAccount,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteAccount,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter
    }
}