import React, {useEffect, useMemo, useState} from "react";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import { SelectButton } from 'primereact/selectbutton';
import Select from "react-select";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {useOrganization} from "./useOrganization";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {InputNumber} from "primereact/inputnumber";
import {validate} from "../../../Services/Globals/validate";
import {organizationSchema} from "./organizationSchema";
import {Export} from "../../Commans/Grid/Export";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {Tooltip} from "react-tooltip";
import {GridTable} from "../../Commans/Grid/GridTable";
import {handleCreateOrganization, handleEditOrganization} from "./organizationService";
import queryString from "query-string";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {useOrganizationGrid} from "./useOrganizationGrid";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";

export const  HandleFormOrganizeItems = () => {
    const { t } = useTranslation();
    const [errors,setErrors]=useState({});
    const [initialSource,setInitialSource]=useState({organizationGroups:[],targets:[],forms:[],reports:[]})
    const {setOrganization,organization,handleSelectValue, handleValue,targetItems,handleNumberValue,handleChangeImage,handleResetImages,handleRemoveImagePath}=useOrganization(setErrors,initialSource);
    const [IsEdit,setIsEdit]=useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [IsOpenOrganization,setIsOpenOrganization]=useState(false);
    const queryParams = queryString.parse(window.location.search);
    const [isLoading,setIsLoading] = useState(false);

    const [refresh,setRefresh]=useState(false);
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }


    const showLoading=()=>{
        setIsLoading(true)
    }
    const hideLoading=()=>{
        setIsLoading(false);
    }


    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    const handleResetError=()=> setErrors({});
    const showSpinner=()=> setIsShowSpinner(true);
    const hideSpinner=()=> setIsShowSpinner(false);

    const handleOpenOrganization=()=>{
        setOrganization((prevState)=>({...prevState,rank: 0,caption: '',linkOrganizationGroupId:queryParams.linkOrganizationGroupId,target: initialSource.targets[0], link:initialSource.forms[0]??null, icon: null,linkOrganizationType: 0}))
        setIsOpenOrganization(true);
    }
    const handleCloseOrganization=()=>{
        setIsOpenOrganization(false);
        setIsEdit(false);
        setOrganization((prevState)=>({...prevState,rank: 0,caption: '',linkOrganizationGroupId:queryParams.linkOrganizationGroupId,target:initialSource.targets[0], link: initialSource.forms[0]??null, icon: null,linkOrganizationType: 0}))
        handleResetError();
    }

    const handleInitialDataSource=(targets,forms,reports)=>{
        const targetsData = convertSelectDataHandler(targets);
        const formsData = convertSelectDataHandler(forms);
        const reportsData = convertSelectDataHandler(reports);
        setInitialSource({
            targets: targetsData,
            forms: formsData,
            reports: reportsData
        })
    }

    const fetchData=async()=>{
        try {
            try {
                showSpinner()
                const [responseTarget,responseForms] = await Promise.all([
                    GET('/Enumerations/EnTargetType', {}, 'IEnumerable'),
                    GET('/Forms/Selection', {}, 'IEnumerable'),
                ]);
                if (responseTarget.status === 200 && responseForms.status === 200)
                {
                    handleInitialDataSource(responseTarget.data,responseForms.data,[]);
                }
                else {
                    handleApiError(responseTarget,hideSpinner);
                }
                hideSpinner();
            } catch (e) {
                hideSpinner();
            }
        }
        catch (e){
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData();
    },[])

    const handleSubmitOrganization=async(e)=>{
        e.preventDefault();
        const isValid = validate(organizationSchema,organization,onSetErrors);
        if(!isValid)return;
        try {
            if(IsEdit){
                showLoading();
                const organizationData = handleEditOrganization(organization);
                const response = await PUT(`/Forms/LinkOrganization`,organizationData,true);
                if(response.status === 200){
                    hideLoading();
                    handleCloseOrganization();
                    handleSuccessMessage(t('SuccessDone'));
                    Refresh();
                }
                else{
                    handleApiError(response,hideLoading);
                }
            }
            else{
                showLoading();
                const organizationData = handleCreateOrganization(organization);
                const response = await POST(`/Forms/LinkOrganization`,organizationData,true);
                if(response.status === 200){
                    hideLoading();
                    handleCloseOrganization();
                    handleSuccessMessage(t('SuccessDone'));
                    Refresh();
                }
                else{
                    handleApiError(response,hideLoading);
                }
            }
        }catch (e) {
            hideSpinner();
        }
    }



    const {switchFilters,handleToggleFilter,handleDeleteOrganization,handleGetOrganization,pagination,handleSearch,handleResetFilters,handlePageSizeCount,handlePreviousPage,handleNextPage,setFilter,handleCurrentPage,filter,getRowHeight,defaultColDef,columnDefs,autoGroupColumnDef,rowData}=useOrganizationGrid(showSpinner,hideSpinner,setIsOpenOrganization,setIsEdit,refresh,Refresh,setOrganization,initialSource,organization)








    return(
        <>
            <div class="space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                            <InputText onChange={(e)=>setFilter(({...filter,searchValue:e.target.value}))} value={filter.searchValue} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <ButtonSearchList
                            onClickReset={()=>handleResetFilters()}
                            onClickSearch={()=>handleSearch()}
                        />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => handleOpenOrganization()}
                                            tooltipTitle={"CreatingMenu"}
                                        />
                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchFilters} OnValueSelection={handleToggleFilter} />
                                    </>
                                }
                    />


                </div>
                <div className={"H-body-ag-grid space-y-2"}>
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
              <Export filters={''} apiUrl={''}/>
            </div>
            <FantasyModal openModal={IsOpenOrganization}  closeModal={handleCloseOrganization} title={t("NewLink")} >
              <form method={'post'} onSubmit={handleSubmitOrganization}>
                  <div className="space-y-5">
                      <div className="space-y-2">
                          <div className={"w-full h-36 tooltip_class_icon"}>
                              <UploadImage isEdit={IsEdit} removeFile={handleRemoveImagePath} removeName={'iconPath'} accept={'image/*'} resetImages={handleResetImages} OnValueSelection={handleChangeImage} value={organization.icon ? organization.icon:null} name={'icon'} />
                              <Tooltip anchorSelect={".tooltip_class_icon"} content={t("Icon")} positionStrategy={"fixed"}	 />
                          </div>
                          <div className={"flex items-center gap-2"}>
                              <div class="space-y-1 flex-1">
                                  <label className="text-xs dark:text-gray-300">{t("Title")}:</label>
                                  <DisplayError message={errors.caption}/>
                                  <InputText name={'caption'} value={organization.caption} onInput={(e)=>handleValue(e)} type={"text"} className={" input w-full"} />
                              </div>
                              <div class="space-y-1 w-20">
                                  <label className="text-xs dark:text-gray-300">{t("Rank")}</label>
                                  <InputNumber name={'rank'} value={organization.rank} useGrouping={false} min={0}  showButtons={true} onChange={(e)=>handleNumberValue(e.value,'rank')}  type={"text"} />
                              </div>
                          </div>

                          <div class="flex justify-content-center  h-select-button ">
                              <SelectButton value={organization.linkOrganizationType} onChange={(e) => handleSelectValue(e.value,'linkOrganizationType')} optionLabel="name" options={targetItems} className="flex text-xs" />
                          </div>
                          {
                              organization.linkOrganizationType === 0?
                                  <div class="space-y-1 ">
                                      <label className="text-xs dark:text-gray-300">{t("FormSelection")}</label>
                                      <Select
                                          options={initialSource.forms }
                                          isMulti={false}
                                          isSearchable={true}
                                          onChange={(data)=>handleSelectValue(data,'link')}
                                          placeholder={t("selection")}
                                          value={organization.link}
                                          menuPosition={"fixed"}
                                          classNamePrefix={"h_multi_select"}
                                      />
                                  </div>:
                                  organization.linkOrganizationType === 1?
                                      <div class="space-y-1 ">
                                          <label className="text-xs dark:text-gray-300">{t("SelectReport")}</label>

                                          <Select
                                              options={initialSource.reports}
                                              isMulti={false}
                                              isSearchable={true}
                                              onChange={(data)=>handleSelectValue(data,'link')}
                                              placeholder={t("selection")}
                                              value={organization.link}
                                              menuPosition={"fixed"}
                                              classNamePrefix={"h_multi_select"}
                                          />
                                      </div>
                                      :
                                      organization.linkOrganizationType === 2?
                                          <div class="space-y-1">
                                              <label className="text-xs dark:text-gray-300">{t("FormAddress")}</label>
                                              <InputText name={'link'} value={organization.link} onInput={(e)=>handleValue(e)} type={"text"} className={" input w-full"}  />
                                          </div>:null

                          }
                          <div class="space-y-1 ">
                              <label className="text-xs dark:text-gray-300">{t("Target")}</label>
                              <Select
                                  options={initialSource.targets}
                                  isMulti={false}
                                  isSearchable={true}
                                  onChange={(data) => handleSelectValue(data, 'target')}
                                  placeholder={t("selection")}
                                  value={organization.target}
                                  menuPosition={"fixed"}
                                  classNamePrefix={"h_multi_select"}
                              />
                          </div>
                      </div>
                      <div>
                          <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation place-content-center text-white relative"} >
                              {t("insertion")}
                              {isLoading ? (
                                  <TinyLoading
                                  />
                              ) : (
                                  null
                              )}
                          </button>
                      </div>
                  </div>
              </form>
            </FantasyModal>



        </>
    )
};
