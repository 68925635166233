import {InputText} from "primereact/inputtext";

import {InputNumber} from "primereact/inputnumber";
import React from "react";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const PhoneItemFields = ({index,element,t ,addCheckBoxItem,deleteCheckBoxItem,handleItemPropertyChange,handlePropertyChange}) => {

    return (<>

        <div className={"max_height20 space-y-2"}>
            <div className="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("Pattern")}</label>
                <InputText
                    type="text"
                    className={"input w-full"}
                    value={element.properties.regex}
                    onChange={(e) => handlePropertyChange(index, 'regex', e.target.value)}
                />
            </div>
            {element.properties?.preNumbers && element.properties.preNumbers.length > 0 ? element.properties.preNumbers.map((item, itemIndex) => (
                <div key={itemIndex} className="flex gap-2">
                    <div className="number_pluse text-white">
                        {itemIndex + 1}
                    </div>
                    <div className="flex-1 space-y-1">
                        <div className="flex items-center gap-2">
                            <InputText
                                className="input w-full"
                                value={item.value}
                                onChange={(e) => handleItemPropertyChange(index, itemIndex, 'value', e.target.value,'preNumbers')}
                                placeholder={t("Value")}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <button
                            onClick={() => addCheckBoxItem(index,'preNumbers')}
                            type={"button"}
                            className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                            <HPrimeIcon icon="pi-plus" />
                        </button>
                        <button
                            type={"button"}
                            onClick={() => deleteCheckBoxItem(index, itemIndex,'preNumbers')}
                            className="button small_button bg-themeDanger text-white">
                            <HPrimeIcon icon="pi-minus" />
                        </button>
                    </div>
                </div>
            )) : null}
        </div>
    </>)
}