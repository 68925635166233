import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useProductTransport} from "./useProductTransport";
import {GET, POST, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {handleCreateTransport} from "./transportService";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {ToggleSwitch} from "../../../../Commans/UIParts/ToggleSwitch";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {UploadImage} from "../../../../Commans/Uploaders/UploadImage";
import {Tooltip} from "react-tooltip";
import Select from "react-select";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {validate} from "../../../../../Services/Globals/validate";
import {productTransportSchema} from "./productTransportSchema";
import {DisplayError} from "../../../../Commans/Errors/DisplayError";
import {RequiredIcon} from "../../../../Commans/Errors/RequiredIcon";

export const ProductTransport=(props)=>{
    const {t}=useTranslation();
    const [IsshowSpinner,setIsShowSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [refresh,setRefresh] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[],calculationTypes:[],shippingMethods:[]});

    const handleClearErrors=()=>{
        setErrors({});
    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const {setTransport,handleValue,transport,handleNumberValue,handleApplyTransport,handleSelectValue}=useProductTransport(setErrors);

    const handleInitialSource=(calculationTypes,shippingMethods)=>{
        const calculationTypesData = convertSelectDataHandler(calculationTypes);
        const shippingMethodsData = convertSelectDataHandler(shippingMethods);
        setInitialSource({
            calculationTypes: calculationTypesData,
            shippingMethods: shippingMethodsData
        })
        return {
            calculationTypesData,
            shippingMethodsData
        }
    }

    const fetchData = async (IsOpen) => {
        if (IsOpen) {
            try {
                const apiCalls = [
                    GET('/Enumerations/EnCalculationType', {}, 'IEnumerable'),
                    GET('/Enumerations/EnShippingMethod', {}, 'IEnumerable')
                ];
                const [responseCalculation,responseShipping] = await Promise.all(apiCalls);
                if (responseCalculation.status === 200 && responseShipping.status === 200) {
                    const {calculationTypesData,shippingMethodsData}=handleInitialSource(responseCalculation.data,responseShipping.data);
                    setTransport({
                        fixedAmount: 0,
                        title: '',
                        description: '',
                        transportType: false,
                        calculationType:calculationTypesData[0],
                        shippingMethod:shippingMethodsData[0],
                        id: ''
                    });
                } else {
                    handleApiError(responseCalculation, hideSpinner);
                    handleApiError(responseShipping, hideSpinner);
                }

                    handleClearErrors();

            } catch (e) {
                hideSpinner();
            }
        }
    };
    useEffect(()=>{
        fetchData(props.IsOpen);
    },[refresh,props.IsOpen]);

    const handleSubmitTransport=async(e)=>{
        e.preventDefault();
        const isValid = validate(productTransportSchema,transport,onSetErrors);
        if(!isValid){
            return;
        }
        try {
                showSpinner();
                const transportData = handleCreateTransport(transport);
                const response = await POST(`/ProductTransports`,transportData,false);
                if(response.status === 200){
                    hideSpinner()
                    props.handleSuccess(true);
                    handleSuccessMessage(t("SuccessDone"));
                }
                else{
                    handleApiError(response,hideSpinner)
                }
        }
        catch (e) {
            hideSpinner();
        }
    }

    return (<>

        <form onSubmit={handleSubmitTransport} method={'post'}>
            <div className="space-y-5">
                <div className={"space-y-4"}>
                    <div className={"flex flex-col items-center gap-3"}>
                        <div className={"w-full h-36 tooltip_class_icon"}>
                            <UploadImage isEdit={true}  removeName={'picturePath'} accept={'image/*'} name={'picture'} />
                            <Tooltip anchorSelect={".tooltip_class_icon"} content={t("Icon")} positionStrategy={"fixed"}	 />
                        </div>
                    </div>
                    <div className={"space-y-3"}>
                        <div>
                            <ToggleSwitch tag={'transportType'} customClass={"w-32"} value={transport.transportType} handleToggleSwitchValue={handleApplyTransport} name={'transportType'} checked={t("API")} unchecked={t("manual")} />
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div className="space-y-2">
                                <div className={"flex gap-2 items-center justify-between"}>
                                    <label className="text-xs dark:text-gray-300">
                                        {t("Title")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.title}/>
                                </div>
                                <InputText name={'title'} onInput={(e)=>handleValue(e)} value={transport.title} className="input w-full"/>
                            </div>
                            {
                                !transport.transportType ?

                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("SetAmount")}</label>
                                        <InputNumber min={0} value={transport.fixedAmount} suffix={props.handleGetCurrencyType(props.websiteCurrency,props.currencies)} name={'fixedAmount'} onChange={(e)=>handleNumberValue(e.value,'fixedAmount')}  />
                                    </div>

                                    :null
                            }
                            {
                                transport.transportType?
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("ServiceSelection")}</label>
                                        <Select
                                            options={initialSource.shippingMethods}
                                            isClearable={false}
                                            isSearchable={true}
                                            name={'timeUnit'}
                                            placeholder={t("selection")}
                                            className="w-full"
                                            value={transport.shippingMethod}
                                            onChange={(data)=>handleSelectValue(data,'shippingMethod')}
                                            menuPosition={'fixed'}
                                            classNamePrefix={"h_multi_select"}

                                        />
                                    </div>
                                    :
                                    null
                            }
                            <div className={"space-y-1"}>
                                <label className="text-xs dark:text-gray-300">{t("CalculationType")}</label>
                                <Select
                                    options={initialSource.calculationTypes}
                                    isClearable={false}
                                    isSearchable={true}
                                    name={'timeUnit'}
                                    placeholder={t("selection")}
                                    className="w-full"
                                    value={transport.calculationType}
                                    onChange={(data)=>handleSelectValue(data,'calculationType')}
                                    menuPosition={'fixed'}
                                    classNamePrefix={"h_multi_select"}

                                />
                            </div>


                        </div>
                    </div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("description")}</label>
                        <InputText name={'description'} value={transport.description} onInput={(e)=>handleValue(e)} className="input w-full"/>
                    </div>
                </div>
                <div>
                    <button type="submit" className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">
                        {t("Register")}
                    </button>
                </div>
            </div>
        </form>
    </>)

}