import React from "react";
import commaNumber from 'comma-number';

export const PriceFormatterComponent=({ price })=>{
    const formattedPrice = commaNumber(price);

    return (
        <>
            <>{formattedPrice}</>
        </>
    )
}