import Joi from "joi";
export const redirectSchema = Joi.object({
    oldUrl: Joi.string().required().trim().messages({
        'any.required': 'لینک جدید الزامیست',
        'string.empty': 'لینک جدید الزامیست',
        'string.base': 'لینک جدید الزامیست'
    }),

    newUrl: Joi.string().required().trim().messages({
        'any.required': 'لینک قبلی الزامیست',
        'string.empty': 'لینک قبلی الزامیست',
        'string.base': 'لینک قبلی الزامیست'
    }),

});
