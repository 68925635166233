export const extractPackages = (data)=>{
    const packages  = data.map(item => ({
        id: item.key,
        name: item.value,
        description: item.description,
        imageSrc: item.thumbnailImage,
    }));
    return packages;
}
export const extractUsers = (data)=>{
    const users  = data.map(item => ({
        label: item.fullName,
        value: item.id
    }));
    return users;
}
export const extractTemplateMaster = (data)=>{
    const templates  = data.map(item => ({
        id: item.id,
        name: item.name,
        thumbnail:item.thumbnail,
        type:item.type
    }));
    return templates;
}

export const convertAddWebsite=(obj)=>{
    const newObj = {
        PackageIds: obj.PackageIds,
        UserId: obj.UserId === null?null:obj.UserId.value,
        Title: obj.Title === null ?null : obj.Title,
        KeyWords:  obj.KeyWords === null?null: obj.KeyWords ,
        Description: obj.Description === null? null : obj.Description,
        Culture:obj.Culture === null?null:obj.Culture.value,
        LicenseTime:obj.LicenseTime === null ? null : obj.LicenseTime.value,
        Currency:obj.Currency === null?null: obj.Currency.value,
        CountryId: obj.CountryId === null ? null: obj.CountryId.value,
        CalendarType:obj.CalendarType === null?null:obj.CalendarType.value,
        LogoHeader:obj.LogoHeader === null? null : obj.LogoHeader,
        LogoFooter: obj.LogoFooter ===null?null: obj.LogoFooter,
        Domains:obj.Domains === null ? null : obj.Domains,
        SiteSpace:obj.SiteSpace,
        MailSpace:obj.MailSpace,
        TemplateMasterId:obj.TemplateMasterId,
        DbServerId:obj.DbServerId === null || obj.DbServerId === undefined ?null : obj.DbServerId.value,
        DbServerIp:obj.DbServerIp === null ?null : obj.DbServerIp,
        ConnectionString:obj.ConnectionString === null || obj.ConnectionString === '' ? null : obj.ConnectionString,
        HostServerId:obj.HostServerId === null || obj.HostServerId === undefined? null: obj.HostServerId.value,
        HostServerIp:obj.HostServerIp === null ? null : obj.HostServerIp,
        WebServerId:obj.WebServerId === null || obj.WebServerId === undefined ?null: obj.WebServerId.value,
        WebServerIp:obj.WebServerIp === null? null:obj.WebServerIp,
        AdminPanelServerId:obj.AdminPanelServerId === null || obj.AdminPanelServerId === undefined ?null: obj.AdminPanelServerId.value,
        AdminPanelServerIp:obj.AdminPanelServerIp === null? null:obj.AdminPanelServerIp,
        MailServerId:obj.MailServerId === null || obj.MailServerId === undefined ?null: obj.MailServerId.value,
        dayOfWeek:obj.dayOfWeek === null || obj.dayOfWeek === undefined ?null: obj.dayOfWeek.value,
        MailServerIp:obj.MailServerIp === null? null:obj.MailServerIp,

        CoreServerIp:obj.CoreServerIp === null || obj.CoreServerIp === undefined? null:obj.CoreServerIp,
        CoreServerId:obj.CoreServerId === null || obj.CoreServerId === undefined? null:obj.CoreServerId,

        DedicatedServerId:obj.DedicatedServerId === null || obj.DedicatedServerId === undefined? null:obj.DedicatedServerId,

        DedicatedServerIp:obj.DedicatedServerIp === null || obj.DedicatedServerIp === undefined? null:obj.DedicatedServerIp,

        AllowIps:obj.AllowIps === null ? null:obj.AllowIps,
    }
    return newObj
}
export const convertAddWebsiteFormData = (obj) => {
    const formData = new FormData();
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined && value !== '') {
            formData.append(key, value);
        }
    }

    if(obj.DedicatedServerId === null && (obj.DedicatedServerIp === null || obj.DedicatedServerIp === "")){
        appendIfNotNull('WebServerId', obj.WebServerId === null?null:obj.WebServerId.value);
        appendIfNotNull('MailServerId', obj.MailServerId === null?null:obj.MailServerId.value);
        appendIfNotNull('HostServerId', obj.HostServerId === null?null:obj.HostServerId.value);
        appendIfNotNull('HostServerIp', obj.HostServerIp );
        appendIfNotNull('UserId', obj.UserId);
        appendIfNotNull('CountryId', obj.CountryId);
        appendIfNotNull('DbServerId', obj.DbServerId);
        appendIfNotNull('Culture', obj.Culture);
        appendIfNotNull('LicenseTime', obj.LicenseTime);
        appendIfNotNull('ConnectionString', obj.ConnectionString);
        appendIfNotNull('MailSpace', obj.MailSpace);
        appendIfNotNull('Currency', obj.Currency);
        appendIfNotNull('CalendarType',obj.CalendarType);
        appendIfNotNull('WebServerIp', obj.WebServerIp);
        appendIfNotNull('AdminPanelServerId', obj.AdminPanelServerId === null?null:obj.AdminPanelServerId.value);
        appendIfNotNull('AdminPanelServerIp', obj.AdminPanelServerIp);
        appendIfNotNull('CoreServerIp', obj.CoreServerIp);
        appendIfNotNull('CoreServerId', obj.CoreServerId === null?null:obj.CoreServerId.value);

        appendIfNotNull('DedicatedServerId', obj.DedicatedServerId === null?null:obj.DedicatedServerId.value);
        appendIfNotNull('DedicatedServerIp', obj.DedicatedServerIp);

        appendIfNotNull('TemplateMasterId', obj.TemplateMasterId );
        appendIfNotNull('Title', obj.Title);
        appendIfNotNull('DbServerIp', obj.DbServerIp);
        appendIfNotNull('DbServerIp', obj.DbServerIp);
        appendIfNotNull('DayOfWeek', obj.dayOfWeek);
        appendIfNotNull('MailServerIp', obj.MailServerIp);
        appendIfNotNull('Description', obj.Description);
        appendIfNotNull('SiteSpace', obj.SiteSpace);
        appendIfNotNull('LogoFooter',obj.LogoFooter);
        appendIfNotNull('LogoHeader', obj.LogoHeader);

        if(obj.KeyWords){
            obj.KeyWords.forEach((keyword, index) => {
                appendIfNotNull(`KeyWords[${index}]`, keyword);
            });
        }
        if(obj.PackageIds){
            obj.PackageIds.forEach((packageId, index) => {
                appendIfNotNull(`PackageIds[${index}]`, packageId);
            });
        }
        if(obj.Domains){
            obj.Domains.forEach((domain, index) => {
                appendIfNotNull(`Domains[${index}].domain`, domain.domain);
                appendIfNotNull(`Domains[${index}].expirationDate`, domain.expirationDate.toISOString());
                appendIfNotNull(`Domains[${index}].protocol`, domain.protocol);
                appendIfNotNull(`Domains[${index}].domainServer`, domain.domainServer);
                appendIfNotNull(`Domains[${index}].isMaster`, domain.isMaster);
                appendIfNotNull(`Domains[${index}].cdn`, domain.cdn);
                appendIfNotNull(`Domains[${index}].compression`, domain.compression);
            });
        }
        if(obj.AllowIps){
            obj.AllowIps.forEach((ip, index) => {
                appendIfNotNull(`AllowIps[${index}]`, ip);
            });
        }
    }
    else{
        // formData.append('WebServerId',null);
        // formData.append('MailServerId', null);
        //  formData.append('HostServerId', null);
        //   formData.append('HostServerIp', null );
        appendIfNotNull('UserId', obj.UserId);
        appendIfNotNull('CountryId', obj.CountryId);
        //   formData.append('DbServerId', null);
        appendIfNotNull('Culture', obj.Culture);
        appendIfNotNull('LicenseTime', obj.LicenseTime);
        //   formData.append('ConnectionString', obj.ConnectionString);
        appendIfNotNull('MailSpace', obj.MailSpace);
        appendIfNotNull('Currency', obj.Currency);
        appendIfNotNull('CalendarType',obj.CalendarType);
        // formData.append('WebServerIp', null);
        //  formData.append('AdminPanelServerId', null);
        ///   formData.append('AdminPanelServerIp', null);
        //   formData.append('CoreServerIp', null);
        //   formData.append('CoreServerId', null);

        appendIfNotNull('DedicatedServerId', obj.DedicatedServerId === null?null:obj.DedicatedServerId.value);
        appendIfNotNull('DedicatedServerIp', obj.DedicatedServerIp);
        appendIfNotNull('DayOfWeek', obj.dayOfWeek);
        appendIfNotNull('TemplateMasterId', obj.TemplateMasterId );
        appendIfNotNull('Title', obj.Title);
        // formData.append('DbServerIp', null);
        // formData.append('MailServerIp',null);
        appendIfNotNull('Description', obj.Description);
        appendIfNotNull('SiteSpace', obj.SiteSpace);
        appendIfNotNull('LogoFooter',obj.LogoFooter);
        appendIfNotNull('LogoHeader', obj.LogoHeader);

        if(obj.KeyWords){
            obj.KeyWords.forEach((keyword, index) => {
                appendIfNotNull(`KeyWords[${index}]`, keyword);
            });
        }
        if(obj.PackageIds){
            obj.PackageIds.forEach((packageId, index) => {
                appendIfNotNull(`PackageIds[${index}]`, packageId);
            });
        }
        if(obj.Domains){
            obj.Domains.forEach((domain, index) => {
                appendIfNotNull(`Domains[${index}].domain`, domain.domain);
                appendIfNotNull(`Domains[${index}].expirationDate`, domain.expirationDate.toISOString());
                appendIfNotNull(`Domains[${index}].protocol`, domain.protocol);
                appendIfNotNull(`Domains[${index}].domainServer`, domain.domainServer);
                appendIfNotNull(`Domains[${index}].isMaster`, domain.isMaster);
                appendIfNotNull(`Domains[${index}].cdn`, domain.cdn);
                appendIfNotNull(`Domains[${index}].compression`, domain.compression);
            });
        }
        if(obj.AllowIps){
            obj.AllowIps.forEach((ip, index) => {
                appendIfNotNull(`AllowIps[${index}]`, ip);
            });
        }
    }

    return formData
}
