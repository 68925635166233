import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {GridTable} from "../Commans/Grid/GridTable";
import {useTranslation} from "react-i18next";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import Select from "react-select";
import {useSetting} from "../Commans/Hooks/useSetting";
import {SettingButton} from "../Commans/UIParts/SettingButton";
import {DateTimePicker} from "../Commans/Dates/DateTimePicker";
import {HandleGeneralSetting} from "../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {Export} from "../Commans/Grid/Export";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";

export const HandleAttendanceIndividualReport = ()=>{
  const { t } = useTranslation();

  const redirectTo = useNavigate();
  const [IsOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState({ AddReminder: false,See:false, });

  const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

  const SeeOnHide = () => {
    setModal((prevState) => ({ ...prevState, See: !modal.See }));
  };


  const [userId,setUserId] = useState('');


  const [IsDone, SetDone] = useState(false); // Corrected the usage of useState

  const columnDefs = [
    { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 110},
    { headerName:t("FirstNameAndLastName"), minWidth: 140,
      cellRenderer:parameters=>
          <>
            مسعود احمدی
          </>

    },

    { headerName:t("PersonnelCode"), minWidth: 140,
      cellRenderer:parameters=>
          <>

          </>

    },

    { headerName:t("Date"), minWidth: 140,
      cellRenderer:parameters=>
          <>

          </>

    },

    { headerName:t("ArrivalTime"), minWidth: 140,
      cellRenderer:parameters=>
          <>

          </>

    },
    { headerName: t("ArrivalTime"),minWidth: 140,
      cellRenderer:parameters=>
          <>
            65654
          </>
    },

    { headerName: t("TaskWork"), minWidth: 140,
      cellRenderer:parameters=>
          <>

          </>
    },
    { headerName: t("RecordedPerformance"), maxWidth: 160,
      cellRenderer:parameters=>
          <>

          </>

    },
    {
      headerName: t("Delay"), maxWidth: 110,
      cellRenderer: parameters =>
          <>
          </>

    },
    { headerName: t("Hastening"), maxWidth: 130,
      cellRenderer: parameters =>
          <>

          </>

    },

    { headerName: t("LowTimeWork"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },
    { headerName: t("CompulsoryOvertime"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },

    { headerName: t("UnauthorizedOvertime"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },

    { headerName: 'اضافه کار اول وقت', minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },

    { headerName: 'اضافه کار آخر وقت', minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },

    { headerName: t("TotalOvertime"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },

    { headerName: t("HourlyLeave"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },
    { headerName: t("HourlyMission"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },

    { headerName: t("NightWork"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },
    { headerName: t("HourlyMission"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },

    { headerName: t("description"), minWidth: 140,
      cellRenderer: parameters =>
          <>

          </>

    },


    { headerName: t("operations"),width: 110, maxWidth: 120,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
      cellRenderer:params => {
        return<div className={'flex justify-center items-center gap-3 '} >
          <GridButtonEdit
              onClick={() => setModal({See: true})}
          />

        </div>
      }

    },

  ];

  const rowData = [

    { id:1, date:'1402/10/3', },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      filter: true,
      sortable: true,
      wrapText: true,
      resizable: true,
      // resizable: true,
      // editable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const onSelectionChanged=(data)=>{
    console.log(data);
  }
  const onCellValueChanged = (data)=>{
    console.log(data);
  }


  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };



  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onQuickFilterChanged = (event) => {
    setQuickFilterText(event.target.value);
  };




  return (
      <>



        <div class=" space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
            <ListSearch label={null} icon={null} >
              <div class="space-y-2 ">
                <label className="text-xs dark:text-gray-300">{t("PersonnelName")}</label>
                <Select
                    isMulti={false}
                    isSearchable={true}
                    placeholder={t("selection")}
                    classNamePrefix={"h_multi_select"}
                />
              </div>
              <div class="space-y-2">
                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                  <SettingButton
                      onClick={()=>handleSetting('GeneralSetting')}
                      tooltipTitle={t("Calendarsettings")}

                  />
                  {t("FromDate")}
                </label>
                <DateTimePicker  format={'YYYY/MM/DD'} />
              </div>
              <div class=" space-y-2">
                <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                  <SettingButton
                      onClick={()=>handleSetting('GeneralSetting')}
                      tooltipTitle={t("Calendarsettings")}
                  />
                  {t("ToDate")}
                </label>
                <DateTimePicker format={'YYYY/MM/DD'} />
              </div>
              <ButtonSearchList />
            </ListSearch>
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>
                            <ButtonPlus
                                title={t("Personnel")}
                                onClick={()=>redirectTo(`/Attendance/Personnel`)}
                                tooltipTitle={t("Personnel")}
                                icon={"users"}
                            />

                            <ButtonPlus
                                icon={"plus-square"}
                                title={t("Device")}
                                onClick={()=>redirectTo("/Attendance/Add")}
                                tooltipTitle={t("RegisterDevice")}
                            />

                            <ButtonPlus
                                title={t("FinancialRules")}
                                icon={"book"}
                                onClick={()=>redirectTo("/Attendance/FinancialRules")}
                                tooltipTitle={t("FinancialRules")}
                            />

                            <ButtonPlus
                                title={t("Leave")}
                                primIcon={"pi-calendar-clock"}
                                onClick={()=>redirectTo("/Attendance/LeaveAndMission")}
                                tooltipTitle={t("LeaveAndMission")}
                            />

                            <ButtonPlus
                                title={t("Shift")}
                                icon={"layers"}
                                onClick={()=>redirectTo("/Attendance/ShiftWork/List")}
                                tooltipTitle={t("ShiftWork")}
                            />

                            <ButtonPlus
                                title={t("WorkGroup")}
                                icon={"users"}
                                onClick={()=>redirectTo("/Attendance/WorkGroup/List")}
                                tooltipTitle={t("WorkGroup")}
                            />

                            <ButtonPlus
                                title={t("archive")}
                                icon={"archive"}
                                onClick={()=>redirectTo("/Attendance/Archive")}
                                tooltipTitle={t("ArchiveLeaveAndMission")}
                            />


                            <ButtonPlus
                                title={t("Reports")}
                                primIcon={"pi-chart-bar"}
                                onClick={()=>redirectTo("/Attendance/List/Reports")}
                                tooltipTitle={t("GeneralReport")}
                            />



                          </>
                        }
                        childrenTwo={
                          <>
                            <div className=" space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("CompleteRegistration")}</lable>
                              <div>
                                <input className="input input--switch  " type="checkbox"/>
                              </div>
                            </div>
                            <div className=" space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("IncompleteRegistration")}</lable>
                              <div>
                                <input className="input input--switch hite" type="checkbox"/>
                              </div>
                            </div>
                            <div className=" space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("Leave")}</lable>
                              <div>
                                <input className="input input--switch " type="checkbox"/>
                              </div>
                            </div>
                            <div className=" space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("Mission")}</lable>
                              <div>
                                <input className="input input--switch" type="checkbox"/>
                              </div>
                            </div>
                            <div className=" space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("Absence")}</lable>
                              <div>
                                <input className="input input--switch" type="checkbox"/>
                              </div>
                            </div>
                            <div className="space-y-2 ">
                              <lable className="text-xs dark:text-gray-300">{t("HourlyLeave")}</lable>
                              <div>
                                <input className="input input--switch" type="checkbox"/>
                              </div>
                            </div>
                          </>
                        }
            />


          </div>
          <div className={"H-body-ag-grid space-y-2"}>
            <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                       enableRtl={true}
                       pagination={true}
                       getRowHeight={getRowHeight}
                       onGridReady={onGridReady}
                       quickFilterText={quickFilterText}
            />

          </div>
          <div className={"grid grid-cols-1 lg:grid-cols-12 bg-white dark:bg-themeDarkSalt700"}>
            <div className={"lg:col-span-9"}>
              <Export/>
            </div>
            <div className="lg:col-span-3  flex gap-1 items-center  justify-center p-2">
              <div className={"flex-1 w-full"}>
                <Select
                    placeholder={t("DeterminingVerdict")}
                    classNamePrefix={"h_multi_select"}
                    menuPosition={"fixed"}
                />
              </div>

              <button type={"button"} className="button bg-themeInformation text-white dark:bg-themeDarkInformation">
                {t("Actions")}
              </button>
            </div>

          </div>
        </div>

        <FantasyModal openModal={modal.See} closeModal={SeeOnHide} title={t("ریز ساعات")}>
          <div className={"space-y-5"}>
            <div className={"flex items-end gap-2"}>
              <div className={"grid grid-cols-2 gap-2"}>
                <div className="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">{t("Enteries")}</label>
                  <InputText
                      className={"input w-fll"}
                  />
                </div>
                <div className="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">{t("Exit")}</label>
                  <InputText
                      className={"input w-fll"}
                  />
                </div>
              </div>
              <div className="flex-shrink-0">
                <div className="flex  gap-2">
                  <button  type="button" className="button bg-themeInformation dark:bg-themeDarkInformation text-white">
                    <HPrimeIcon  />
                  </button>
                  <button  type="button" className="button bg-themeDanger text-white">
                    <HPrimeIcon  />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("Register")}</button>
            </div>
          </div>
        </FantasyModal>
        <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
          <div>
            {
              setting === 'GeneralSetting'?
                  <HandleGeneralSetting/>:
                  setting === 'waterMark'?
                      <HandlePicturesSettings/>:null
            }
          </div>
        </FantasyModal>


      </>

  )
}