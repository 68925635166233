import React, { useState, useEffect, useRef } from "react";
import { GET, POST, PUT } from "../../../../Services/AxiosService/AxiosApiService";
import { FantasyModal } from "../../../Commans/UIParts/FantasyModal";
import { GridTable } from "../../../Commans/Grid/GridTable";
import { useTranslation } from "react-i18next";
import { ListSearch } from "../../../Commans/Grid/ListSearch";
import { ListFilter } from "../../../Commans/Grid/ListFilter";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Export } from "../../../Commans/Grid/Export";
import { ButtonPlus } from "../../../Commans/UIParts/ButtonPlus";
import { SettingButton } from "../../../Commans/UIParts/SettingButton";
import { DateTimePicker } from "../../../Commans/Dates/DateTimePicker";
import { useSetting } from "../../../Commans/Hooks/useSetting";
import { HandleGeneralSetting } from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import { HandlePicturesSettings } from "../../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import { ButtonSearchList } from "../../../Commans/UIParts/ButtonSearchList";
import { useContractGrid } from "./useContractGrid";
import { AgGridEnglish } from "../../../../Services/Globals/Translations/Resources";
import { GridSetting } from "../../../../Services/Globals/Public/AppSetting";
import { Pagination } from "../../../Commans/Grid/Pagination";
import { LoadingHarmony } from "../../../Commans/Loadings/LoadingHarmony";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import { convertSelectDataHandler } from "../../../CMS/Contents/ContentService";
import { useContract } from "./useContract";
import { ModalSubmitButton } from "../../../Commans/UIParts/ModalSubmitButton";
import { convertContractData, handleCreacteContractFormData, handleEditContractFormData } from "./contractServices";
import { InputTextarea } from "primereact/inputtextarea";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {useContractAttach} from "./ContracAttaches/useContractAttach";
import {ContractAttach} from "./ContracAttaches/ContractAttach";

export const HandelUserContract = () => {
  const { t } = useTranslation();
  const [IsShowModalContracts, setIsShowModalContracts] = useState(false);
  const [IsShowSpinner, setIsShowSpinner] = useState(false);
  const { setting, handleSetting, handleSettingClose, IsSettingOpen } = useSetting();
  const [initialSource,setInitialSource] = useState({ contractTypes: [] }); // and ...
  const [errors, setErrors] = useState({})
  const [IsBtnLoading, setIsBtnLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const fileInputRef = useRef(null); // تعریف useRef برای ورودی فایل
  const [isEdit, setIsEdit] = useState(false);
  const { userId, fullName } = useParams();
  const { contract,setContract, handleContractStartDate, handleContractEndDate, handleSelectValue, handleValue,handleRemoveContractAttachment } = useContract(setErrors);
  const {addNewCard,deleteCard,files,setFiles,handleFileChange,handleFieldChange, handleGetAllCards, handleInitialCards,triggerFileInput,totalSize}=useContractAttach();
  // hide and show btn loading
  const showBtnLoading = () => setIsBtnLoading(true)
  const hideBtnLoading = () => setIsBtnLoading(false)
  const Refresh = () => {
    setRefresh((prevstate) => !prevstate);
  }



  // 
  const showModalContracts = () => {
    setContract({ ...contract, userId: userId, contractType: initialSource.contractTypes[0],startContract: new Date(),contractAttachPath:null,contractId:null});
    setIsShowModalContracts(true);
    handleInitialCards();
  }
  const hideContracts = () => {

    setContract({
      userId: userId,
      description: '',
      contractType: null,
      contractAttach: null,
      startContract: null,
      endContract: null,
      contractAttachPath: null,
      contractId: null
    });
    setIsShowModalContracts(false);
    setFiles([]);
    setIsEdit(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // خالی کردن مقدار input فایل
    }
  }

  // hide and show spinner
  const showSpinner = () => {
    setIsShowSpinner(true);
  }
  const hideSpinner = () => {
    setIsShowSpinner(false);
  }

  // get data selection and set in the InitialSource state
  const handleInitialDataSource=(contractTypes)=>{
    const contractTypesData = convertSelectDataHandler(contractTypes);
    setInitialSource({ contractTypes: contractTypesData });
  }
  const fetchData = async () => {
    try {
      showSpinner();
      const res = await GET(`/Enumerations/EnContractType`, {}, 'IEnumerable');
      if (res.status === 200) {
        handleInitialDataSource(res.data);
        hideSpinner();
      } else {
        handleApiError(res, hideSpinner);
      }
    } catch {
      hideSpinner();
    }
  }

  useEffect(() => {
    fetchData();
  }, [])




  const handleSubmitContract = async (e) => {
    e.preventDefault();
    showBtnLoading();
    try {
      if(isEdit){
        setIsShowModalContracts(true);
        const contractData = convertContractData(contract,files);
        const formData = handleEditContractFormData(contractData);
        const res = await PUT('/Users/Contracts', formData, true);
        if (res.status === 200) {
          hideBtnLoading();
          hideContracts();
          Refresh();
          handleSuccessMessage(t('SuccessDone'))
        }
        else{
          handleApiError(res,hideBtnLoading);
        }
      }
      else{
        const contractData = convertContractData(contract,files);
        const formData = handleCreacteContractFormData(contractData);
        const res = await POST('/Users/Contracts', formData, true);
        if (res.status === 200) {
          hideBtnLoading();
          hideContracts();
          Refresh();
          handleSuccessMessage(t('SuccessDone'))

        }
        else{
          handleApiError(res,hideBtnLoading);
        }
      }
    }
    catch (e) {
      hideBtnLoading();
    }
  }





  const { columnDefs,handleSelectFilterValue,handleFilterContractEndDate,handleFilterContractStartDate, defaultColDef, getRowHeight, autoGroupColumnDef, rowData, pagination, handlePreviousPage, handleNextPage, handleCurrentPage, handlePageSizeCount, filter, setFilter, handleSearch, handleResetFilters} = useContractGrid(showSpinner, hideSpinner, setContract, refresh, Refresh, initialSource, setIsEdit,setIsShowModalContracts,setFiles,handleInitialCards,handleGetAllCards);

  return (
    <>
      <div class="relative space-y-5">
        {
          IsShowSpinner ?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony />
              </div>
            </div>
            :
            null
        }
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
          <ListSearch label={null} icon={null} >
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("ContractType")}</label>
              <Select
                options={initialSource.contractTypes}
                placeholder={t("selection")}
                classNamePrefix={"h_multi_select"}
                menuPosition={"fixed"}
                value={filter.contractType}
                onChange={(data)=>handleSelectFilterValue(data,'contractType')}
              />
            </div>
            <div class="space-y-2">
              <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                <SettingButton
                  onClick={() => handleSetting('GeneralSetting')}
                  tooltipTitle={t("Calendarsettings")}
                  tooltipId={2}
                />
                {t("FromDate")}</label>
              <DateTimePicker isContainsTime={true} handleDateTime={handleFilterContractStartDate} format={'YYYY/MM/DD hh:mm:ss'} value={filter.startContract} />
            </div>
            <div class="space-y-2 ">
              <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                <SettingButton
                  onClick={() => handleSetting('GeneralSetting')}
                  tooltipTitle={t("Calendarsettings")}
                  tooltipId={3}
                />
                {t("ToDate")}</label>
              <DateTimePicker isContainsTime={true} handleDateTime={handleFilterContractEndDate} format={'YYYY/MM/DD hh:mm:ss'} value={filter.endContract} />
            </div>
            <ButtonSearchList
              onClickSearch={handleSearch}
              onClickReset={handleResetFilters}
            />
          </ListSearch>
          <ListFilter label={null} icon={null}
                      childrenTwo={false}
            childrenOne={
              <>
                <ButtonPlus
                  onClick={() => showModalContracts()}
                  title={t("Contracts")}

                  tooltipTitle={t("Contracts")}
                />

              </>
            }

          />


        </div>
        <div className={"box bg-white dark:bg-themeDarkSalt700 p-2"}>
          <div className={"post-input post-input-design p-2 flex items-center gap-2 dark:bg-themeDarkPostInput"}>
            <div className="size-12 bg-white dark:bg-themeDarkSalt700 border-2 flex-shrink-0 rounded-full overflow-hidden">
              <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
            </div>
            <div className={"flex gap-3 items-center flex-1 w-full flex-wrap"}>
              <div className={"flex gap-1 items-center"}>
                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Name")}:</h3>
                <h2 className="line-clamp-1 text-xs dark:text-gray-300">
                  {fullName}
                </h2>
              </div>

            </div>

          </div>
        </div>
        <div className={"H-body-ag-grid space-y-2"}>
          <GridTable
            columnDefs={columnDefs}
            rowSelection={GridSetting.rowSelection}
            rowData={rowData}
            style={GridSetting.gridStyle}
            defaultColDef={defaultColDef}
            autoGroupColumnDef={autoGroupColumnDef}
            pagination={GridSetting.pagination}
            enableRtl={GridSetting.enableRtl}
            domLayout={GridSetting.domLayout}
            paginationPageSize={GridSetting.pageSize}
            getRowHeight={getRowHeight}
            localeText={AgGridEnglish}
          />
          <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount} />

        </div>
        <div className={"grid grid-cols-1 lg:grid-cols-12 bg-white dark:bg-themeDarkSalt700"}>
          <div className={"lg:col-span-9"}>
            <Export />
          </div>
          <div className="lg:col-span-3  flex gap-1 items-center  justify-center p-2">
            <div className={"flex-1 w-full"}>
              <Select
                placeholder={t("DeterminingVerdict")}
                classNamePrefix={"h_multi_select"}
                menuPosition={"fixed"}
              />
            </div>

            <button type={"button"} className="button bg-themeInformation text-white dark:bg-themeDarkInformation">
              {t("Actions")}
            </button>
          </div>

        </div>
      </div>

      <FantasyModal openModal={IsShowModalContracts} closeModal={hideContracts} title={t("PersonalInformation")} >
        <div className={"space-y-5"}>
          <div className={"bg-themeLightGray p-2 flex items-center gap-2 box"}>
            <div className="size-12 bg-white dark:bg-themeDarkPostInput flex-shrink-0 rounded-full overflow-hidden">
              <img src={"/Assets/Image/Avatar/man.svg"} alt="" className="size-full object-cover" />
            </div>
            <div className={"flex gap-3 items-center justify-between flex-1 w-full flex-wrap"}>
              <div className={"flex gap-1 items-center"}>
                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Name")}:</h3>
                <h2 className="line-clamp-1 text-xs dark:text-gray-300">
                  {fullName}
                </h2>
              </div>

            </div>

          </div>

          <form method={'post'} onSubmit={handleSubmitContract}>
            <div className={"space-y-3"}>
              <div class="space-y-1">
                <label className="text-xs dark:text-gray-300">{t("ContractType")}</label>
                <Select
                  options={initialSource.contractTypes}
                  placeholder={t("selection")}
                  isSearchable={true}
                  classNamePrefix={"h_multi_select"}
                  menuPosition={"fixed"}
                  value={contract.contractType}
                  onChange={((data) => handleSelectValue(data, 'contractType'))}
                />
              </div>
              <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 "}>
                <div class="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">
                    <SettingButton
                      onClick={() => handleSetting('GeneralSetting')}
                      tooltipTitle={t("Calendarsettings")}
                    />
                    {t("StartDate")}
                  </label>
                  <DateTimePicker isContainsTime={false} handleDateTime={handleContractStartDate} format={'YYYY/MM/DD hh:mm:ss'} value={contract.startContract} />

                </div>
                <div class="space-y-1 ">
                  <label className="text-xs dark:text-gray-300">
                    <SettingButton
                      onClick={() => handleSetting('GeneralSetting')}
                      tooltipTitle={t("Calendarsettings")}
                    />
                    {t("EndDate")}
                  </label>
                  <DateTimePicker isContainsTime={false} handleDateTime={handleContractEndDate} format={'YYYY/MM/DD hh:mm:ss'} value={contract.endContract} />
                </div>
              </div>

              <div class={"space-y-1"}>
                <label className={"text-xs  dark:text-gray-300"}>
                  {t("ContractFile")}
                </label>
                {/*<input*/}
                {/*  name="file"*/}
                {/*  ref={fileInputRef}*/}
                {/*  id={"file"}*/}
                {/*  multiple={false}*/}
                {/*  onChange={(e) => handleSelectValue(e.target.files[0], 'contractAttach')}*/}
                {/*  type="file"*/}
                {/*  accept=".xlsx,.xls,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"*/}
                {/*  className={"block w-full bg-white dark:bg-themeDarkSalt700 overflow-clip rounded-md border file:cursor-pointer file:text-base file:text-white file:border-none dark:border-themeDarkColorLine file:bg-themeInformation file:dark:bg-themeDarkInformation file:px-4 file:py-2 cursor-pointer dark:text-gray-300 "} />*/}
                {/*{*/}
                {/*  isEdit && !contract.contractAttachPath?*/}
                {/*      <div className={'cursor-pointer'} onClick={()=>handleRemoveContractAttachment()} >*/}
                {/*        <HPrimeIcon icon={'pi-trash'}  iconClass={"text-themeDanger"}  sizeIcon={16} />*/}
                {/*      </div>*/}
                {/*      :null*/}
                {/*}*/}



                <ContractAttach
                    data={files}
                    IsEdit={isEdit}
                    showSpinner={showSpinner}
                    hideSpinner={hideSpinner}
                    triggerFileInput={triggerFileInput}
                    setCards={setFiles}
                    cards={files}
                    deleteCard={deleteCard}
                    addNewCard={addNewCard}
                    handleFieldChange={handleFieldChange}
                    handleFileChange={handleFileChange}
                    initialSource={initialSource}
                    totalSize={totalSize}
                />

              </div>
              <div class="space-y-1 ">
                <label className="text-xs dark:text-gray-300">{t("ContractDescription")}</label>
                <InputTextarea col={5} className={"input"} onInput={(e) => handleValue(e)} name={'description'} value={contract.description} />
              </div>

            </div>
            <div>
              <ModalSubmitButton
                IsBtnLoading={IsBtnLoading}
              />
            </div>
          </form>

        </div>
      </FantasyModal>

      <FantasyModal openModal={IsSettingOpen} closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
        <div>
          {
            setting === 'GeneralSetting' ?
              <HandleGeneralSetting isInUse={IsSettingOpen} handleCloseSetting={handleSettingClose} /> :
              setting === 'waterMark' ?
                <HandlePicturesSettings isInUse={IsSettingOpen} handleCloseSetting={handleSettingClose} /> : null
          }
        </div>
      </FantasyModal>
      <ToastContainer/>
    </>

  )
}