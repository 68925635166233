import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const TopReferrerUrls=({referrerUrl,referrals})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className="flex items-center justify-between  H-direction-ltr">
                <div className="max_width85">
                    <Link to={referrerUrl} className="text-themeInformation hover:underline line-clamp-1 text-xs dark:text-themeDarkInformation">{referrerUrl}</Link>
                </div>
                <div className="flex items-center flex-shrink-0">
                    <p className=" text-xs dark:text-gray-300">({referrals})</p>
                </div>
            </div>
        </>
    )
}