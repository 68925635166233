import React from "react";
import {HandleFileManager} from "./HandleFileManager";
import {FileProvider} from "./FileProvider";
export const FileManager = ({handleGetFolderSource,inUse,handleCloseFtp})=>{
    return (

        <FileProvider>
            <HandleFileManager handleGetFolderSource={handleGetFolderSource} inUse={inUse} handleCloseFtp={handleCloseFtp}/>
        </FileProvider>
    )
}