import React, {useState} from "react";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {BrowserDownloader, Download, DownloadFile, ExportDownloader} from "../../../../ApiServices/Public/FtpController";

import {AddFilter} from "../../Filters/AddFilter";
import {FantasyModal} from "../../UIParts/FantasyModal";
import {useNavigate} from "react-router-dom";
import {HPrimeIcon} from "../../UIParts/HPrimeIcon";


export const RecieptSaver = ({apiUrl,filters})=>{

    const [modal, setModal] = useState({ RecieptSaver: false });
    const redirectTo = useNavigate();

    const RecieptSaverOnHide = () => {
        setModal((prevState) => ({ ...prevState, RecieptSaver: !modal.RecieptSaver }));
    };

    const handleSubmitExport=  async (operation,ExportType)=>{

        if(apiUrl === null || apiUrl === undefined || apiUrl === ''){
            return;
        }

        const query = { ...filters,ExportType};
        switch (operation) {
            case 0:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
            case 1:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
            case 2:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
            case 4:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
            default:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
        }
    }
    const {t} = useTranslation()
    return (
        <>
            <div className="shadow-md bg-white dark:bg-themeDarkSalt700 p-2 box">
                <div className="flex flex-wrap gap-3 items-center justify-around">
                    <Button onClick={()=>redirectTo('/InvoiceRequest')} class=" tooltip_Try text-xs hover:text-themeInformation dark:text-gray-300 flex gap-2 items-center justify-center">
                        <HPrimeIcon
                        icon={"pi-sync"}
                        />
                        <h2>{t("TryAgain")}</h2>
                        <Tooltip anchorSelect=".tooltip_Try" content={t("TryAgain")} positionStrategy={"fixed"} />
                    </Button>

                    <Button onClick={()=>handleSubmitExport(1,1)} class=" tooltip_PDF  text-xs hover:text-themeInformation dark:text-gray-300 flex gap-2 items-center justify-center">
                      <span className="bg_Pdf"></span>
                      <h2>{t("ExportPDF")}</h2>
                        <Tooltip anchorSelect=".tooltip_PDF" content={t("ExportPDF")} />
                    </Button>

                    <Button onClick={()=>handleSubmitExport(2,2)} class=" tooltip_Word text-xs hover:text-themeInformation dark:text-gray-300 flex gap-2 items-center justify-center  ">
                      <span className="bg_Word"></span>
                      <h2>{t("TextOutput")}</h2>
                        <Tooltip anchorSelect=".tooltip_Word" content={t("TextOutput")} />
                    </Button>
                    <Button class=" tooltip_jpg text-xs hover:text-themeInformation dark:text-gray-300 flex gap-2 items-center justify-center  ">
                      <span>
                          <HPrimeIcon icon={"pi-share-alt"}/>
                      </span>
                      <span className={"text-xs"} onClick={() => setModal({ RecieptSaver: true })}>{t("ShareTheReceipt")}</span>
                        <Tooltip anchorSelect=".tooltip_jpg" content={t("ShareTheReceipt")} />
                    </Button>
                  </div>
            </div>

            <FantasyModal openModal={modal.RecieptSaver} closeModal={RecieptSaverOnHide} title={t("Share")} >
                <div className="p-3 space-y-5">
                    <div className={"flex gap-3 items-center"}>
                        <label htmlFor="3" className={"block flex-1"}>
                            <input type="radio" name="facebook" className={"input-connect-social hidden"} id="3"/>
                            <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                <span className={"connect-social-span"}></span>
                                <div>
                                    <span className={"dark:text-gray-300"}>{t("PhotoSharing")}</span>
                                </div>
                            </div>
                        </label>
                        <label htmlFor="4" className={"block flex-1"}>
                            <input type="radio" name="facebook" className={"input-connect-social hidden"} id="4"/>
                            <div className={"flex gap-2 px-2 py-3 border rounded box bg-white cursor-pointer connect-social dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine"}>
                                <span className={"connect-social-span"}></span>
                                <div>
                                    <span className={"dark:text-gray-300"}>{t("TextSharing")}</span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className={"grid grid-cols-1 sm:grid-cols-3 gap-3 items-center "}>
                        <button className={"border box bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine p-2 flex gap-2 items-center hover:bg-themeHoverInformation group"}>
                            <span className="flex-shrink-0">
                                <span className={`block bg_telegram w-12 h-12`}></span>
                            </span>
                            <span className={"flex-1"}>
                                <h2 className={"dark:text-gray-300 group-hover:text-white"}>{t("Telegram")}</h2>
                            </span>
                        </button>
                        <button className={"border box bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine p-2 flex gap-2 items-center hover:bg-themeHoverInformation group"}>
                            <div className="flex-shrink-0">
                                <span className={`block bg_whatsapp w-12 h-12`}></span>
                            </div>
                            <div className={"flex-1"}>
                                <h2 className={"dark:text-gray-300 group-hover:text-white"}>{t("WhatsApp")}</h2>
                            </div>
                        </button>
                        <button className={"border box bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine p-2 flex gap-2 items-center hover:bg-themeHoverInformation group"}>
                            <div className="flex-shrink-0">
                                <span className={`block bg_Sms w-12 h-12`}></span>
                            </div>
                            <div className={"flex-1"}>
                                <h2 className={"dark:text-gray-300 group-hover:text-white"}>{t("sendingMessage")}</h2>
                            </div>
                        </button>
                    </div>
                </div>
            </FantasyModal>
        </>
    )
}