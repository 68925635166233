import React from "react";

import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {DELETE, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {GridDateFomartter} from "../../../Grid/GridDateFomartter";
import {PriceFormatterComponent} from "../../../Accounting/PriceFormatterComponent";
import {HPrimeIcon} from "../../../UIParts/HPrimeIcon";

export const RequestItem=({item,GetTransactionType,GetTransactionIcon,GetCurrencyType,GetPaymentState,GetUserBank,GetUserBankAccount,showSpinner,hideSpinner,Refresh})=>{
    const { t } = useTranslation();

    const handleCancelRequestWithdraw = async (withdrawRequestId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:t('SureToCancel'),
            text:t('ThereIsNowayback'),
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: t('YesCancelit'),
            cancelButtonText:t('Close'),
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await POST(`/Wallets/Withdraw/Requests/Cancel`,{withdrawalWalletRequestId:withdrawRequestId},false)
                    if (response.status === 200) {
                        hideSpinner()
                        Refresh()
                        handleSuccessMessage(t("RemovedSuccessfully"))
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                    hideSpinner();
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    return (<>

        <ul className={"grid grid-cols-1 xl:grid-cols-2 gap-5 p-2"}>
            <li className={" box bg-white dark:bg-themeDarkPostInput overflow-hidden"}>
                <div className={"flex flex-col lg:flex-row h-full "}>
                    <div className={"flex-shrink-0 bg-themeDanger h-12 w-full grid place-content-center lg:w-12 lg:h-full"}>
                        <HPrimeIcon icon={"pi-arrow-right"} iconClass="text-white"/>
                    </div>
                    <div className={"flex-1 grid grid-cols-1 md:grid-cols-2  border dark:border-themeDarkColorLine "}>
                        <div className={" flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                            <p className={"text-xs dark:text-gray-300"}>{item.userFullName}</p>
                        </div>
                        <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("bank")}:</p>
                            <span className={"line-clamp-1 text-xs dark:text-gray-300 "}>{GetUserBank(item.userBankId)}</span>
                        </div>
                        {
                            //failed
                            item.paymentStatus === 2?
                             <>
                                 <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                     <p className={"text-xs dark:text-gray-300"}>{t("BankAccountNumber")}:</p>
                                     <span className={"line-clamp-1 text-xs dark:text-gray-300 "}>{item.adminFullName}</span>
                                 </div>
                                 <div className={"flex gap-2 flex-col md:flex-row border px-1 py-2 dark:border-themeDarkColorLine"}>
                                     <p className={"text-xs dark:text-gray-300"}>{t("TransactionNumber")}:</p>
                                     <span className={"line-clamp-1 text-xs  dark:text-gray-300"}>{item.banReason??'-'}</span>
                                 </div>
                             </>:null
                        }
                        {
                            //paid
                            item.paymentStatus === 1?
                                <>
                                    <div className={"flex gap-2 flex-col md:flex-row border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionNumber")}:</p>
                                        <span className={"line-clamp-1 text-xs  dark:text-gray-300"}>{item.transactionCode??'-'}</span>
                                    </div>
                                    <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                                        <p className={"text-xs dark:text-gray-300"}>{t("BankAccountNumber")}:</p>
                                        <span className={"line-clamp-1 text-xs dark:text-gray-300 "}>{item.adminFullName}</span>
                                    </div>
                                </>:null
                        }
                        <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("BankAccountNumber")}:</p>
                            <span className={"line-clamp-1 text-xs dark:text-gray-300 "}>{!item.bankAccountId?'-':GetUserBankAccount(item.bankAccountId)}</span>
                        </div>

                        <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("TransactionDate")}:</p>
                            <span className={"text-xs dark:text-gray-300"}>{<GridDateFomartter date={item.creationDate} hasTime={true}/>}</span>

                        </div>
                        <div className={"flex flex-col md:flex-row gap-2 border px-1 py-2 dark:border-themeDarkColorLine"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("TransactionType")}:</p>
                            <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{GetTransactionType(item.amountTransfer.moneyType)}</span>
                        </div>
                        <div className={"flex flex-col md:flex-row items-center gap-2 border p-1 dark:border-themeDarkColorLine"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("TransactionAmount")}:</p>
                            <span className={"line-clamp-1 text-xs  text-themeDanger"}><PriceFormatterComponent price={item.amountTransfer.amount}/>{GetCurrencyType(item.amountTransfer.currency)}</span>
                        </div>
                        <div className={"flex flex-col md:flex-row items-center gap-2 border p-1 dark:border-themeDarkColorLine"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("Status")}:</p>
                            <span className={"line-clamp-1 text-xs  text-themeDanger"}>{GetPaymentState(item.paymentStatus)}</span>
                        </div>
                    </div>
                    {
                        item.paymentStatus === 0?
                            <div className={"flex cursor-pointer flex-col md:flex-row items-center gap-2 border p-1 dark:border-themeDarkColorLine"}>
                                <button onClick={()=>handleCancelRequestWithdraw(item.withdrawalWalletRequestId)} className={"line-clamp-1  text-xs  text-themeDanger"}>{t('Cancel')}</button>
                            </div>:null
                    }

                </div>
            </li>
        </ul>

    </>)
}