import React from "react";
import {Button} from "primereact/button";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {BrowserDownloader, Download, DownloadFile, ExportDownloader} from "../../../ApiServices/Public/FtpController";
export const Export = ({apiUrl,filters})=>{

    const handleSubmitExport=  async (operation,ExportType)=>{

        if(apiUrl === null || apiUrl === undefined || apiUrl === ''){
            return;
        }

        const query = { ...filters,ExportType};
        switch (operation) {
            case 0:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
            case 1:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
            case 2:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
            case 4:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
            default:
                // ExportDownloader(`${BasicUrl.baseURL}${apiUrl}`, query);
                await DownloadFile(query,null,apiUrl);
                break;
        }
    }
    const {t} = useTranslation()
    return (
        <>
            <div className="box bg-white dark:bg-themeDarkSalt700 p-2">
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                    <Button onClick={()=>handleSubmitExport(0,0)} class="shadow border tooltip_Excel dark:border-themeDarkColorLine text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                        <span className="bg_Excel"></span>
                        <h2>{t("output")} (Excel)</h2>
                        <Tooltip anchorSelect=".tooltip_Excel" content={t("ExportExcel")} />
                    </Button>
                    <Button onClick={()=>handleSubmitExport(1,1)} class="shadow tooltip_PDF border dark:border-themeDarkColorLine text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                      <span className="bg_Pdf"></span>
                      <h2>{t("output")} (Pdf)</h2>
                        <Tooltip anchorSelect=".tooltip_PDF" content={t("ExportPDF")} />
                    </Button>

                    <Button onClick={()=>handleSubmitExport(2,2)} class="shadow border tooltip_Word dark:border-themeDarkColorLine text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                      <span className="bg_Word"></span>
                      <h2>{t("output")} (Word)</h2>
                        <Tooltip anchorSelect=".tooltip_Word" content={t("ExportWord")} />
                    </Button>
                    <Button onClick={()=>handleSubmitExport(4,4)} class="shadow border tooltip_Csv dark:border-themeDarkColorLine text-xs zoom-in dark:text-gray-300 flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                      <span className="bg_Csv"></span>
                      <h2>{t("output")} (CSV)</h2>
                        <Tooltip anchorSelect=".tooltip_Csv" content={t("ExportCsv")} />
                    </Button>
                  </div>
            </div>

        </>
    )
}