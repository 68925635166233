import React from "react";

import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";
import {PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {ServerPin} from "./ServerPin";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const Server=({item,handleRestartIis,handleGetAllWebsites,handleGetServer,handleGetServerType,handleGetPrivacyType,handleChangeActivation,handleDeleteServer,handleRestartServer,handleServerPin})=>{
    const { t } = useTranslation();
    const handleDisplayStatus=(stateShow)=>{
      return (<>
          {
              <input
                  checked={stateShow}
                  className="input input--switch"
                  onClick={async (e) =>  handleChangeActivation(item.id)}
                  type="checkbox"
              />
          }
      </>)
    }




    return (
        <>
            <div className="post-input dark:bg-themeDarkPostInput post-input-design flex flex-col md:flex-row gap-2  overflow-hidden relative intro-x">
                <ServerPin serverId={item.id} isPin={item.isPin} handleServerPin={handleServerPin}/>
                <div className={"flex flex-col gap-2"}>
                    <div className="w-full md:w-24 h-32">
                        <img src="/Assets/Image/bg-img/server.png"  alt="server" title="server" className={"size-full object-contain"} />
                    </div>
                    <div>
                        <div>
                            <p class="text-xs text-center dark:text-themeDarkInformation">{t("ServerHOST")}</p>
                        </div>
                        <div class=" space-y-1 text-center flex-shrink-0">
                            <lable className="text-xs dark:text-gray-300">{t("DisplayStatus")}</lable>
                            <div>
                                {
                                    handleDisplayStatus(item.stateShow)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 p-1 flex flex-col justify-between gap-y-2">
                    <div className="space-y-2">
                        <div className={"flex flex-wrap items-center gap-2 pe-4"}>
                            <div>
                                <div className={"flex gap-1 items-center"}>
                                    <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("title")}:</h3>
                                    <h3 className="line-clamp-1 text-xs dark:text-gray-300">
                                        {item.name}
                                    </h3>
                                </div>
                            </div>
                            <div className={"flex gap-1 items-center"}>
                                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Country")}:</h3>
                                <span className="text-xs dark:text-gray-300 block">
                                    {item.countryName}
                                </span>
                            </div>
                            <div className={"flex gap-1 items-center"}>
                                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("Province")}:</h3>
                                <h3 className="text-xs dark:text-gray-300 block">
                                    {item.stateProvinceName}
                                </h3>
                            </div>
                            <div className={"flex gap-1 items-center"}>
                                <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("ip")}:</h3>
                                <h3 className="text-xs dark:text-gray-300 block">
                                    {item.ip}
                                </h3>
                            </div>
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-2"}>
                            <div className={"flex gap-3 flex-wrap md:flex-col items-center md:items-start"}>
                                <div className={"flex gap-1 items-center"}>
                                    <span className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("CPU")} :</span>
                                    <span className="text-xs dark:text-gray-300 block">
                                        {item.cpu}
                                    </span>
                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <span className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("RAM")} :</span>
                                    <span className="text-xs dark:text-gray-300 block">
                                        {item.ram}
                                    </span>
                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <span className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("capacity")}:</span>
                                    <span className="text-xs dark:text-gray-300 block">
                                        {item.ssd}
                                    </span>
                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <span className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("HDD")} :</span>
                                    <span className="text-xs dark:text-gray-300 block">
                                        {
                                            item.hdd
                                        }
                                    </span>
                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <span className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("BandWidth")} :</span>
                                    <span className="text-xs dark:text-gray-300 block">
                                        {item.bandwidth}
                                     </span>
                                </div>
                            </div>
                            <div className={"flex gap-3 flex-wrap md:flex-col items-center md:items-start"}>
                                <div className={"flex gap-1 items-center"}>
                                    <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("CPUUsage")} :</h3>
                                    <h3 className="text-xs dark:text-gray-300 block">
                                        {item.serverMetrics.cpuUsage}
                                    </h3>
                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("MemoryUsage")} :</h3>
                                    <h3 className="text-xs dark:text-gray-300 block">
                                        {item.serverMetrics.memoryUsage}
                                    </h3>
                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("DiskUsage")} :</h3>
                                    <h3 className="text-xs dark:text-gray-300 block">
                                        {item.serverMetrics.diskUsage}

                                    </h3>
                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("NetworkTraffic")} :</h3>
                                    <h3 className="text-xs dark:text-gray-300 block">
                                        {item.serverMetrics.networkTraffic}
                                    </h3>
                                </div>
                                <div className={"flex gap-1 items-center"}>
                                    <h3 className={"text-themeInformation dark:text-themeDarkInformation text-xs"}>{t("UpTime")} :</h3>
                                    <h3 className="text-xs dark:text-gray-300 block">
                                        {item.serverMetrics.systemUptime}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"flex justify-between items-center bg-whit py-1 rounded-3xl dark:bg-themeDarkSalt700 px-3 border-2 border-themeInformation dark:border-themeDarkInformation"} >
                        <div className={"flex gap-2 items-center"}>
                            <button onClick={()=>handleGetServer(item.id)} className={`text-themeSuccess  tooltip_edit_${item.id}`} >
                                <HPrimeIconSmall
                                    icon={"pi-pencil"}
                                />
                                <Tooltip anchorSelect={`.tooltip_edit_${item.id}`} content={t("Editingserver")} />
                            </button>
                            <button onClick={()=>handleDeleteServer(item.id)} className={`text-themeDanger  tooltip_delete_${item.id}`} >
                                <HPrimeIconSmall
                                icon={"pi-trash"}
                                />
                                <Tooltip anchorSelect={`.tooltip_delete_${item.id}`} content={t("DeleteServer")} />
                            </button>
                        </div>
                        <div className={"flex gap-2 items-center"}>
                            <button onClick={()=>handleGetAllWebsites(item.id)} className={`text-themeInformation dark:text-themeDarkInformation  bg-iis tooltip_IIS_${item.id} `}>
                                <Tooltip anchorSelect={`.tooltip_IIS_${item.id}`} content={t("IIS")}/>
                            </button>
                            <button onClick={()=>handleRestartServer(item.id)} className={`text-themeInformation dark:text-themeDarkInformation tooltip_refresh_${item.id}`}>
                                <HPrimeIconSmall
                                icon={"pi-sync"}
                                />
                                <Tooltip anchorSelect={`.tooltip_refresh_${item.id}`} content={t("Restart")}/>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>)
}