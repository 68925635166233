import React, { useEffect, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import Select from "react-select";
import { DateTimePicker } from "../../../Commans/Dates/DateTimePicker";
import { useTranslation } from "react-i18next";
import { useTabServices } from "../Hooks/useTabServices";
import { GET } from "../../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../../Services/Globals/Errors/handleApiError";
import queryString from "query-string";

export const PackageTab = ({
                               showSpinner,
                               hideSpinner,
                               item,
                               handleChangePackage,
                               currentPath,
                               currencies,
    handleUpdateTotalPrice,
    paymentMethods
                           }) => {
    const { t } = useTranslation();
    const [extraPackage, setExtraPackage] = useState({
        packageId: null,
        paymentMethod:0,
        finalPrice: { amount: 0, currency: 0, moneyType: 0 },
        discount: { amount: 0, discountType: 0, from: null, to: null },
        firstPrice: { amount: 0, currency: 0, moneyType: 0 },
        siteSpace: 0,
        proposedSpace: 0
    });
    const { handleShowPrice, handleGetCurrentType,handleGetPaymentMethod, } = useTabServices();
    const queryParams = queryString.parse(window.location.search);

    const fetchData = async () => {
        try {
            showSpinner();
            const url =
                currentPath === '/Admin/ServiceUpgrade'
                    ? `/Upgrades/ExtraPackages/${item.packageId}?WebSiteId=${queryParams.websiteId}`
                    : `/Upgrades/ExtraPackages/${item.packageId}`;
            const response = await GET(url, {}, 'single');
            if (response.status === 200) {
                setExtraPackage(response.data);
                handleChangePackage(item.packageId,'price',response.data.firstPrice.amount);
                hideSpinner();
            } else {
                handleApiError(response, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const renderPriceInput = () => {
        if (currentPath === '/Admin/ServiceUpgrade') {
            return (
                <h2 className="dark:text-gray-300">
                    {t("Price")}:
                    <InputNumber
                        type={"text"}
                        name={'price'}
                        min={0}
                        value={item.price}
                        onChange={(e) => handleChangePackage(item.packageId, 'price', e.value)}
                        suffix={handleGetCurrentType(currencies,extraPackage.firstPrice.currency)}
                    />
                </h2>
            );
        } else {
            return (
                <h2 className="dark:text-gray-300">
                    {t("Price")}:
                    <span className="text-themeInformation dark:text-themeDarkInformation">
                        {handleShowPrice(extraPackage.finalPrice.amount, currencies, extraPackage.finalPrice.currency)}
                    </span>
                </h2>
            );
        }
    };

    return (
        <>
            <div className="space-y-2">
                <div className={"flex justify-between items-center"}>
                    <h2 className={"dark:text-gray-300 text-xs"}>{t("TariffPrice")} {handleGetPaymentMethod(paymentMethods,extraPackage.paymentMethod)}:</h2>
                    <del  className={"text-themeDanger text-xs"}>{handleShowPrice(extraPackage.firstPrice.amount,currencies,extraPackage.firstPrice.currency)}</del>
                    <span className={"text-gray-300 text-xs"}>{handleShowPrice(extraPackage.finalPrice.amount,currencies,extraPackage.finalPrice.currency)}</span>
                </div>

                <div className="p-2">
                    {renderPriceInput()}
                </div>
            </div>
        </>
    );
};
