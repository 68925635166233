import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useCustomTab=()=>{
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("content");
    const handleChangeTabId = (tabId) => {
        setActiveTab(tabId);
    };
    const tabs = [
        {
            id: "content",
            target: "#content",
            label: t("Contents"),
            icon: "file-text",
        },
    ];
    return {tabs,activeTab,setActiveTab,handleChangeTabId}
}