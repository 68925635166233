import React, {useState} from "react";

export const useCustomRoadMap=(setErrors)=>{
    const [roadMap,setRoadMap]=useState({name:null,description:null,roadmapId:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setRoadMap({...roadMap,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=async (data,name)=>{
        setRoadMap({ ...roadMap, [name]: data});
    }
    return {
        handleValue,
        handleSelectValue,
        roadMap,
        setRoadMap
    }
}