import React, {useEffect, useState} from "react";
import {UseRememberMe} from "../../Commans/Hooks/UseRememberMe";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useUserLogin} from "./useUserLogin";
import {validate} from "../../../Services/Globals/validate";
import {loginSchema} from "../../Authentication/Login/loginSchema";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {saveAccessToken, saveRefreshToken} from "../../../Services/AxiosService/JwtService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {InputText} from "primereact/inputtext";
import {LoadingSpinner} from "../../Commans/Loadings/LoadingSpinner";

export const Login=({handleChangeState})=>{

    const {rememberMe, handleRememberMe, saveCredentials,savedCredentials} = UseRememberMe();
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const redirectTo = useNavigate();
    const {setUsers,users,handleValue,authenticationModes}=useUserLogin(setErrors)

    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors = (errors) => {
        setErrors(errors);
    }



    useEffect(() => {
        if (rememberMe && savedCredentials) {
            setUsers((prevUsers) => ({
                ...prevUsers,
                username: savedCredentials.username,
                password: savedCredentials.password,
            }));
        }
    }, [rememberMe, savedCredentials]);



    const handleLoginUser = async (e) => {
        e.preventDefault();
        var isValid = validate(loginSchema,users,onSetErrors)
        if(!isValid){
            return;
        }
        try {
            saveCredentials(users.username,users.password);
            showSpinner()
            const {username, password} = users;
            const response = await POST('/authentication/login', {username, password}, false);
            if (response.status === 200) {
                saveAccessToken(response.data.accessToken);
                saveRefreshToken(response.data.refreshToken);
                window.location = '/DashBoard';
            }
            else {
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner();
        }
    };

    return  (<>


                <form onSubmit={handleLoginUser} method={'post'} className={'relative'}>
                    {IsShowSpinner ? <LoadingSpinner/> : null}
                    <div className={"space-y-4 relative"}>

                        <p class="">لطفا وارد حساب کاربری خود شوید</p>
                        <div class="relative" >
                            <div className="relative float-input p-float-label ">
                                <div className={"absolute -top-2 end-2"}>
                                    <DisplayError message={errors.username}/>
                                </div>
                                <i className="pi pi-user dark:text-gray-300 absolute end-2 top-1/2 -translate-y-1/2 " />
                                <InputText onInput={(e) => handleValue(e)}
                                           value={users.username}
                                           type="text"
                                           className={" input w-full input-float-label"}
                                           name="username"
                                />
                                <label className={"text-xs "}>{t("userName")}</label>
                            </div>
                        </div>
                        <div className={"relative float-input p-float-label"}>
                            <div className={"absolute -top-2 end-2"}>
                                <DisplayError message={errors.password}/>
                            </div>
                            <i className={
                                showPassword
                                    ? "pi pi-eye cursor-pointer absolute dark:text-gray-300 end-2 top-1/2 -translate-y-1/2"
                                    : "pi pi-eye-slash cursor-pointer dark:text-gray-300 absolute end-2 top-1/2 -translate-y-1/2"
                            }
                               onClick={() => setShowPassword(!showPassword)}>

                            </i>
                            <InputText type={showPassword ? "text" : "password"}
                                       className=" input w-full input-float-label"
                                       value={users.password}
                                       onInput={(e) => handleValue(e)}
                                       name="password"
                            />
                            <label className={"text-xs "}>{t("Password")}</label>
                        </div>
                        <button onClick={()=>handleChangeState('passwordRecovery')} className={"text-de text-xs "}>فراموشی پسورد</button>

                        <div class="text-center">

                            <button className="button w-full  user_login_gradiant text-white" type="submit">
                                ورود
                            </button>
                        </div>
                    </div>
                </form>




    </>)

}