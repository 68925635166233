import React, {useState} from "react";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {DELETE, GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTranslation} from "react-i18next";
export const useCustomQuickAccess=(setInitialSource)=>{

    const { t } = useTranslation();

    const [quickAccess,setQuickAccess] = useState({title:null,slug:null,icon:null,target:0});
    const handleSelectValue=(data,name)=>{
        if(name === 'slug'){

            setQuickAccess({ ...quickAccess, [name]: data,title: data.label,icon:data.primIcon,});
        }else{
            setQuickAccess({ ...quickAccess, [name]: data });
        }
    }

    const handleDeleteSpeedLink = async (speedLinkId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    // showSpinner();
                    const response = await DELETE(`/UserSpeedLink/${speedLinkId}`,{headers: { 'Content-Type': 'application/json' }})
                    if (response.status === 200) {
                        // hideSpinner()
                        const responseSpeedlinK = await GET(`/UserSpeedLink/Links`,{},'IEnumerable');
                        if(responseSpeedlinK.status === 200){
                            setInitialSource(prevState => ({...prevState,listItems: responseSpeedlinK.data}))
                        }else {
                           // handleApiError(responseSpeedlinK,hideSpinner);
                        }

                    }
                    else {
                       // handleApiError(response,hideSpinner)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                  //  hideSpinner()
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }


    return {handleSelectValue,quickAccess,setQuickAccess,handleDeleteSpeedLink}
}