import React from "react";

export const useProductMeta=(productMeta,setProductMeta,initialSource)=>{
    const handleAddRow=(metaTypes)=>{
        const newRow = {
            id: new Date().getTime(),
            type: metaTypes[0],
            name:null,
            value: null,
            order:0,
            productMetaId: null,
            productId:null
        };
        setProductMeta(prevList => [...prevList, newRow]);
    }
    const handleRemoveRow = (id,productMetaId) => {
        if(productMeta.length === 1){
            const newRow = {
                id: new Date().getTime(),
                productMetaId: null, // Use the default value from options
                type: initialSource.metaTypes[0],
                value: null,
                name:null,
                order:0
            };
            setProductMeta(prevList => [newRow]);
            return;
        }
        setProductMeta((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.productMetaId === productMetaId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
    };

    const handleChange = (id, field, value, productMetaId) => {
        setProductMeta((prevRelations) => {
            const updatedRelations = prevRelations.map((meta) => {
                if (
                    (productMetaId && meta.productMetaId === productMetaId) || (!productMetaId && id && meta.id === id)
                ) {
                    return { ...meta, [field]: value };
                } else {
                    return meta;
                }
            });
            return updatedRelations;
        });
    };
return {
    handleChange,
    handleAddRow,
    handleRemoveRow
}
}