export const convertServer = (obj,isEdit)=>{
    if(isEdit){
        const newObj = {
            name:obj.name === null || obj.name === undefined?null:obj.name,
            ssd:obj.ssd,
            ram:obj.ram,
            cpu:obj.cpu,
            hdd:obj.hdd,
            capacity:obj.capacity,
            bandwidth:obj.bandwidth,
            userName:obj.userName === null || obj.userName === undefined ? null:obj.userName,
            stateShow:obj.stateShow,
            password:obj.password === null || obj.password === undefined?null:obj.password,
            ip:obj.ip === null || obj.ip === undefined?null:obj.ip,
            connectionString:obj.connectionString === null || obj.connectionString === undefined ? null:obj.connectionString,
            type: obj.type === null || obj.type === undefined?null: obj.type.value,
            stateProvinceId:obj.stateProvinceId === null || obj.stateProvinceId === undefined? null: obj.stateProvinceId.value,
            privacy:obj.privacy === null || obj.privacy === undefined? null: obj.privacy.value,
            description:obj.description === null || obj.description === undefined ? null:obj.description,
            isConfigured:obj.isConfigured,
            ns1:obj.ns1 === null || obj.ns1 === undefined ||  obj.ns1 === '' ? null:obj.ns1,
            ns2:obj.ns2 === null || obj.ns2 === undefined ||  obj.ns1 === '' ? null:obj.ns2,
            ns3:obj.ns3 === null || obj.ns3 === undefined  ||  obj.ns1 === '' ? null:obj.ns3,
            ns4:obj.ns4 === null || obj.ns4 === undefined  || obj.ns1 === '' ? null:obj.ns4,
        }
        return newObj;
    }
    else{
        const newObj = {
            id:obj.id,
            name:obj.name === null || obj.name === undefined?null:obj.name,
            ssd:obj.ssd,
            ram:obj.ram,
            cpu:obj.cpu,
            hdd:obj.hdd,
            bandwidth:obj.bandwidth,
            capacity:obj.capacity,
            userName:obj.userName === null || obj.userName === undefined ? null:obj.userName,
            stateShow:obj.stateShow,
            password:obj.password === null || obj.password === undefined?null:obj.password,
            ip:obj.ip === null || obj.ip === undefined?null:obj.ip,
            connectionString:obj.connectionString === null || obj.connectionString === undefined ? null:obj.connectionString,
            type: obj.type === null || obj.type === undefined?null: obj.type.value,
            stateProvinceId:obj.stateProvinceId === null || obj.stateProvinceId === undefined? null: obj.stateProvinceId.value,
            privacy:obj.privacy === null || obj.privacy === undefined? null: obj.privacy.value,
            description:obj.description === null || obj.description === undefined ? null:obj.description,
            isConfigured:obj.isConfigured,
            ns1:obj.ns1 === null || obj.ns1 === undefined ||  obj.ns1 === '' ? null:obj.ns1,
            ns2:obj.ns2 === null || obj.ns2 === undefined ||  obj.ns1 === '' ? null:obj.ns2,
            ns3:obj.ns3 === null || obj.ns3 === undefined  ||  obj.ns1 === '' ? null:obj.ns3,
            ns4:obj.ns4 === null || obj.ns4 === undefined  || obj.ns1 === '' ? null:obj.ns4,

        }
        return newObj;
    }
}

export const convertServerData = (data,type) => {
    const filteredData = data
        .filter(item => item.type === type)
        .map(({ id, name }) => ({ label: name, value: id }));
    return filteredData;
};
