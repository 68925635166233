import React, {useEffect, useMemo, useState} from "react";

import {ListSearch} from "../../Commans/Grid/ListSearch";
import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {GridTable} from "../../Commans/Grid/GridTable";
import {Button} from "primereact/button";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {InputTextarea} from "primereact/inputtextarea";
import Select from "react-select";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {GridButtonOprations} from "../../Commans/Grid/GridButtonOprations";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
export const HandleUnansweredTicket = () => {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [modal, setModal] = useState({ AddReminder: false,See:false, });


  useEffect(() => {
    // Function to update the state with the new window width
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this effect runs once after the initial render


  const SeeOnHide = () => {
    setModal((prevState) => ({ ...prevState, See: !modal.See }));
  };


  const [isMenuOpen,setMenuOpen] = useState(false);
  const [userId,setUserId] = useState('');
  const handleOpenMenu = () => {
    setMenuOpen(true);

  };
  const handleCloseMenu = ()=>{
    setMenuOpen(false);
  }


  const [IsDone, SetDone] = useState(false); // Corrected the usage of useState
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    // Update column definitions based on window width
    if (windowWidth <= 1280) {
      setColumnDefs([
        { headerName: "Name", field: "name"},
        { headerName: "Age", field: "age" },
        { headerName: t("operations"), maxWidth: 130,
          cellRenderer:params => {
            return<div className={'flex justify-center items-center gap-3'} >
              <GridButtonEdit/>
              <GridButtonDelete/>
              <GridButtonOprations
                  onClick={()=>handleOpenMenu()}
              />

            </div>
          }
        },

      ]);
    } else {
      setColumnDefs([
        { headerName: "Name", field: "name" },
        { headerName: "Age", field: "age" },
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
          cellRenderer:params => {
            return<div className={'flex justify-center items-center gap-3'} >
              <GridButtonEdit/>
              <GridButtonDelete/>
              <GridButtonOprations
                  onClick={()=>handleOpenMenu()}
              />
            </div>
          }
        },
      ]);
    }
  }, [windowWidth]);








  const rowData = [

    { id:1, },
    { id:2, },
    { id:3, },
  ];

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 140,
      filter: true,
      sortable: true,
      wrapText: true,
      resizable: true,
      // resizable: true,
      // editable: true,
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerCheckboxSelection: true,
      cellRendererParams: {
        checkbox: true,
      },
    };
  }, []);

  const onSelectionChanged=(data)=>{
    console.log(data);
  }
  const onCellValueChanged = (data)=>{
    console.log(data);
  }


  const getRowHeight = (params) => {
    // Specify your custom logic to determine the row height based on row data or other conditions
    return 45; // Return the desired row height in pixels
  };

  const [columnApi, setColumnApi] = useState(null);
  const [gridApi, setGridApi] = useState(null);

  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState('');

  const updateColumnDefinitionsBasedOnViewport = () => {
    if (columnApi) {
      const isMobile = window.innerWidth <= 768; // نقطه شکست برای موبایل

      const colDef = columnApi.getColumnState();

      colDef.forEach(col => {
        if (col.colId === 'settingsColumn') {
          col.pinned = isMobile ? null : 'left';
          col.lockPinned = !isMobile;
        }
      });

      columnApi.applyColumnState({ state: colDef, applyOrder: true });
    }
  };

  useEffect(() => {
    if (gridApi && columnApi) {
      updateColumnDefinitionsBasedOnViewport();

      window.addEventListener('resize', updateColumnDefinitionsBasedOnViewport);
      return () => window.removeEventListener('resize', updateColumnDefinitionsBasedOnViewport);
    }
  }, [gridApi, columnApi]);



  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setColumnApi(params.columnApi);
    updateColumnDefinitionsBasedOnViewport(params);
  };

  const onQuickFilterChanged = (event) => {
    setQuickFilterText(event.target.value);
  };








  return (
      <div class=" space-y-5">
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
          <ListSearch label={null} icon={null} >
            <div class="space-y-2">
              <label className="text-xs dark:text-gray-300">جستجو براساس (****)</label>
              <InputText type={"text"} className={" input w-full"} />
            </div>
            <div className="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("grouping")}</label>
              <Select
                  placeholder={t("selection")}
                  menuPosition={"fixed"}
                  classNamePrefix={"h_multi_select"}

              />
            </div>
            <ButtonSearchList />
          </ListSearch>
          <ListFilter label={null} icon={null}
                      childrenOne={
                        <>

                        </>
                      }
                      childrenTwo={
                        <>
                          <div class=" space-y-2">
                            <lable className="text-xs dark:text-gray-300">*****</lable>
                            <div>
                              <input className="input input--switch " type="checkbox"/>
                            </div>
                          </div>
                          <div class=" space-y-2">
                            <lable className="text-xs dark:text-gray-300">****</lable>
                            <div>
                              <input className="input input--switch " type="checkbox"/>
                            </div>
                          </div>
                          <div class="space-y-2">
                            <lable className="text-xs dark:text-white">****</lable>
                            <div>
                              <input className="input input--switch " type="checkbox"/>
                            </div>
                          </div>
                        </>
                      }
          />


        </div>
        <div>
          <GridTable rowData={rowData} columnDefs={columnDefs} defaultColDef={defaultColDef} autoGroupColumnDef={autoGroupColumnDef} onSelectionChanged={onSelectionChanged} onCellValueChanged={onCellValueChanged} rowSelection={'single'}
                     enableRtl={true}
                     pagination={true}
                     getRowHeight={getRowHeight}
                     onGridReady={onGridReady}
                     quickFilterText={quickFilterText}

          />
        </div>
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-5">
            <div className="sm:col-span-2">
              <div className="flex flex-wrap sm:flex-nowrap gap-2 ">
                <Button class="text-gray-700 shadow text-xs zoom-in dark:text-white flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                  <span className="bg_Pdf"></span>
                  <h2>خروجی (Pdf)</h2>
                </Button>
                <Button class="text-gray-700  shadow text-xs zoom-in dark:text-white flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                  <span className="bg_Excel"></span>
                  <h2>خروجی (Exel)</h2>
                </Button>
                <Button class="text-gray-700 shadow text-xs zoom-in dark:text-white flex gap-2 items-center justify-center post-input button dark:bg-themeDarkPostInput ">
                  <span className="bg_Word"></span>
                  <h2>خروجی (Word)</h2>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <FantasyModal openModal={isMenuOpen} closeModal={handleCloseMenu} >
          <div className=" grid grid-cols-3 md:grid-cols-4 gap-3">
            <button type="button" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem]  justify-center" > <HPrimeIcon size={20} icon={'plus-square'} />*****</button>
            <button type="button" className="table_Btn zoom-in bg-themeInformation dark:bg-themeDarkInformation text-white gap-1 flex-col  min-w-[6.5rem] min-h-[6.5rem]  justify-center" > <HPrimeIcon size={20} icon={'trending-up'} />*****</button>
          </div>
        </FantasyModal>
        <FantasyModal openModal={modal.See} closeModal={SeeOnHide} title={t("event")}>
          <div>
            <div class="flex items-center border-b dark:border-themeDarkColorLine">
              <div>
                <ul class="flex gap-2 items-center justify-center p-2">
                  <li>
                    <img src="/Assets/Image/user/person1.png"
                         className="w-10 h-10 block rounded-full overflow-hidden object-contain"
                         alt="user" title="user"/>
                  </li>
                  <li className="flex gap-1">
                    <h3 className="text-xs dark:text-gray-300">مسعود احمدی</h3>
                  </li>
                </ul>
              </div>
            </div>
            <div className="space-y-2">
              <div>
                <InputTextarea name={"excerpt"} rows={15} cols={30} className={"input w-full text-xs"} value="منابع موزیک بدون کپی رایت، جهت اینسب منابع موزیک بدون کپی رایت، جهت اینسب منابع موزیک بدون کپی رایت، جهت اینسب"/>
              </div>
            </div>
            <div>
              <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("edit")}</button>
            </div>
          </div>
        </FantasyModal>
      </div>
  )
}