import React from "react";

export const LogDetails = ({ details }) => {
    return (
        <div className="flex flex-col items-start p-4">
            <ul className="space-y-2">
                {Object.entries(details).map(([key, value]) => (
                    <li key={key} className="flex space-x-2">
                        <span className="font-semibold text-themeInformation">{key}:</span>
                        <span>{value}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}
