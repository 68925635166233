import React, { useEffect, useMemo, useState } from "react";
import { DELETE, GET } from "../../../Services/AxiosService/AxiosApiService";
import { useTranslation } from "react-i18next";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import { GridButtonEdit } from "../../Commans/Grid/GridButtonEdit";
import { GridButtonDelete } from "../../Commans/Grid/GridButtonDelete";
import Swal from "sweetalert2";
import { AlertSetting, GridSetting } from "../../../Services/Globals/Public/AppSetting";
import { useNavigate, useParams } from "react-router-dom";
import { HPrimeIcon } from "../../Commans/UIParts/HPrimeIcon";
import { GridDateFomartter } from "../../Commans/Grid/GridDateFomartter";

export const useUserInsuranceGrid = (showSpinner, hideSpinner, insurance, setInsurance, refresh, Refresh, initialSource, setIsEdit, setIsShowModalInformation) => {
    const { t } = useTranslation();
    const { userId,fullName } = useParams();
    const [rowData, setRowData] = useState([]);
    const redirectTo = useNavigate();
    const [pagination, setPagination] = useState({ totalPages: 0, page: 0, pageSize: GridSetting.pageSize, totalCount: 0, hasNextPage: false, hasPreviousPage: false, lastPage: 0, currentPage: 1 })
    const [filter, setFilter] = useState({
        search: '',
        searchValue: '',
        insuranceType: null,
        startDate: null,
        endDate: null,
    });




    const handleGetInsurance = async (insuranceId) => {
        try {
            showSpinner();
            const res = await GET(`/Users/Insurance/${insuranceId}`, {}, 'single');
            if (res.status === 200) {
                hideSpinner();
                setInsurance({
                    ...insurance,
                    insuranceId: res.data.insuranceId,
                    employeeId: res.data.employeeId,
                    userId: userId,
                    insurancePosition: res.data.insurancePosition,
                    insuranceType: initialSource.insuranceTypes[res.data.insuranceType],
                    insuranceNumber: res.data.insuranceNumber,
                    startDate: res.data.startDate,
                    endDate: res.data.endDate,
                    description: res.data.description,
                });
                setIsEdit(true);
                setIsShowModalInformation(true);
                
            } else {
                handleApiError(res,hideSpinner);
                hideSpinner();
            }
        } catch {
            hideSpinner();
        }
    }

    const handelDeleteDataGrid = async (insuranceId) => {
        // show sweetalert
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title: AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon: AlertSetting.TailWindButton.icon,
            showCancelButton: AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const res = await DELETE(`/Users/Insurance/${insuranceId}`);
                    if (res.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(res, hideSpinner)
                    }
                } catch {
                    hideSpinner();
                }
            }
            else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    // Display data to the user
    const handleGetInsuranceType = (type) => {
        const contractType = initialSource.insuranceTypes.find((item) => item.value === type);
        if (!contractType) {
            return;
        }
        return contractType.label ?? '-'
    }
    const columnDefs = [
        { headerName: t("row"), field: 'id', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110, hide: true },
        { headerName: t("row"), field: 'idSequence', filter: 'agTextColumnFilter', headerCheckboxSelection: true, checkboxSelection: true, width: 110, maxWidth: 110 },
        {
            headerName: t("سمت در لیست بیمه"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.insurancePosition}
                </>

        },
        {
            headerName: t("نوع بیمه"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {handleGetInsuranceType(parameters.data.insuranceType)}
                </>

        },

        {
            headerName: t("کد بیمه"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.insuranceNumber}
                </>

        },

        {
            headerName: t("تاریخ شروع"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {<GridDateFomartter date={parameters.data.startDate} hasTime={true} />}
                </>

        },

        {
            headerName: t("تاریخ پایان"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {<GridDateFomartter date={parameters.data.endDate} hasTime={true} />}
                </>

        },

        {
            headerName: t("توضیحات"), minWidth: 140,
            cellRenderer: parameters =>
                <>
                    {parameters.data.description}
                </>

        },


        {
            headerName: t("operations"), width: 110, maxWidth: 120, pinned: "left", lockPinned: true, cellClass: "lock-pinned",
            cellRenderer: parameters => {
                return <div className={'flex justify-center items-center gap-3 '} >
                    <button type={"button"} ><HPrimeIcon icon={'pi-user-plus'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} onClick={() => redirectTo(`/CRM/User/List/Insurance/Family/${parameters.data.insuranceId}/${fullName}`)} /></button>
                    <GridButtonEdit onClick={() => handleGetInsurance(parameters.data.insuranceId)} />
                    <GridButtonDelete onClick={() => handelDeleteDataGrid(parameters.data.insuranceId)} />
                </div>
            }

        },

    ];

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 140,
            filter: true,
            sortable: true,
            wrapText: true,
            resizable: true,
            // resizable: true,
            // editable: true,
        };
    }, []);

    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);

    // Pagination handel
    const Paginate = (items) => {
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems
    }

    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(() => ({ ...pagination, pageSize: pageSize }))
    }


    // Search handel
    const handleSearch = () => {
        setFilter((prevState) => ({ ...prevState, search: prevState.searchValue }));
    }
    const handleResetFilters = () => {
        setFilter((prevState) => ({ ...prevState, search: '', searchValue: '', insuranceType: null, startDate: null, endDate: null }));
    }
    const handleFilterInsuranceStartDate = (dateTime) => {
        setFilter({ ...filter, startDate: dateTime })
    }
    const handleFilterInsuranceEndDate = (dateTime) => {
        setFilter({ ...filter, endDate: dateTime })
    }
    const handleSelectFilterValue = (data, name) => {
        if (!data) {
            return;
        }
        setFilter(({ ...filter, [name]: data }));
    }




    useEffect(() => {
        OnGridReady();
    }, [refresh, filter.search, filter.insuranceType, filter.startDate, filter.endDate, pagination.currentPage, pagination.pageSize])

    const OnGridReady = async () => {
        try {
            showSpinner();
            const res = await GET(`/Users/Insurances`, {
                Search: filter.search,
                StartDate: filter.startDate,
                EndDate: filter.endDate,
                InsuranceType: filter.insuranceType?filter.insuranceType.value:filter.insuranceType,
                UserId: userId,
                PageNumber: pagination.currentPage,
                PageSize: pagination.pageSize,
            }, 'PagedList');
            if (res && res.items !== null) {
                setPagination({ ...pagination, totalPages: res.totalPages, hasNextPage: res.hasNextPage, hasPreviousPage: res.hasPreviousPage, page: res.page, pageSize: res.pageSize, totalCount: res.totalCount });
                const items = Paginate(res.items);
                setRowData(items);
            }
            else {
                handleApiError(res, hideSpinner);
            }
        } catch {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };

    return {
        columnDefs,
        defaultColDef,
        getRowHeight,
        autoGroupColumnDef,
        rowData,
        pagination,
        handlePreviousPage,
        handleNextPage,
        handleCurrentPage,
        handlePageSizeCount,
        handleFilterInsuranceStartDate,
        handleFilterInsuranceEndDate,
        filter,
        setFilter,
        handleSelectFilterValue,
        handleSearch,
        handleResetFilters,
    }
}