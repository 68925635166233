import React from "react";

import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../Services/Globals/Public/AppSetting";
import {DELETE} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const NoLoginRequiredAccount=({name,accountAddress,createdAt,profilePicture,platformName,platform,showSpinner,hideSpinner,Refresh,socialMediaAccountId})=>{
    const { t } = useTranslation();
    const actionButtons = [
        {
            condition: platform !== 0,
            icon: "pi-arrow-right-arrow-left",
            tooltip: t("Reentry"),
            tooltipClass: "tooltip_class_again",
            bgColor: "bg-themeInformation dark:bg-themeDarkInformation",
        },
        {
            icon: "pi-eye",
            tooltip: t("ViewAccount"),
            tooltipClass: "tooltip_class_see",
            bgColor: "bg-themeInformation dark:bg-themeDarkInformation",
            onAction:()=>handleWatchProfile()
        },
        {
            icon: "pi-trash",
            tooltip: t("DeleteAccount"),
            tooltipClass: "tooltip_class_delete",
            bgColor: "bg-themeDanger",
            onAction: () => handleDeleteSocialAccount(socialMediaAccountId), // Attach delete function
        },
    ];
    const handleDeleteSocialAccount = async (socialMediaAccountId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner();
                    const response = await DELETE(`/SocialAccounts/${socialMediaAccountId}`,);
                    if (response.status === 200) {
                        hideSpinner();
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const handleWatchProfile = () => {
       window.open(accountAddress,'_blank')
    };


    return (
        <>

            <div className={"border flex flex-col justify-between post-input dark:bg-themeDarkPostInput post-input-design messenger_accounts overflow-hidden"}>
                <div className=" flex flex-col items-center gap-5 px-8 py-8">
                    <div class=" flex items-center">
                        <div className={"size-20 flex-shrink-0 rounded-full border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkColorLine overflow-hidden"}>
                            <img className="size-full object-cover" alt="harmony" src={profilePicture}/>
                        </div>
                    </div>
                    <div className={"space-y-1 text-center"}>
                        <h2 class="dark:text-themeDarkInformation">{name}</h2>
                        <p class="text-xs text-gray-500 dark:text-gray-300">{platformName}</p>
                        <p class="text-xs text-gray-500 dark:text-gray-300 font-light">
                            <GridDateFomartter date={createdAt} hasTime={false}/>
                        </p>
                    </div>
                </div>
                <div class=" w-full flex items-center p-1 gap-1 bgwhite dark:bg-themeDarkPostInput">
                    {actionButtons.map((btn, index) =>
                        btn.condition !== false ? (
                            <button
                                key={index}
                                className={`flex-1 button grid place-content-center text-white ${btn.bgColor} rounded zoom-in ${btn.tooltipClass + socialMediaAccountId + index}`}
                                onClick={btn.onAction} // Set onClick if provided
                            >
                                <HPrimeIconSmall icon={btn.icon}

                                />
                                <Tooltip
                                    anchorSelect={`.${btn.tooltipClass + socialMediaAccountId + index}`}
                                    content={btn.tooltip}
                                    positionStrategy="fixed"
                                />
                            </button>
                        ) : null
                    )}
                </div>
            </div>

        </>
    )
}