import React, {useState} from "react";
import {regexSetting} from "../../../../../Services/Globals/Public/Regexes";

export const useCustomServers=(initialSource)=>{
    const [servers,setServer] = useState({websiteId:null,concurrencyStamp:null,coreServerId:null,coreServerIp:null,dbServerId:null,dbServerIp:null,connectionString:null,hostServerId:null,hostServerIp:null,webServerId:null,webServerIp:null,adminPanelServerId:null,adminPanelServerIp:null,mailServerId:null,mailServerIp:null,dedicatedServerId:null,dedicatedServerIp:null,allowIps:[]})

    const ipRegex = regexSetting.Ip;
    const isValidIp = (ip) => ipRegex.test(ip);

    const [ipValidationStatus, setIpValidationStatus] = useState({
        isDedicatedServerIpValid:false,
        isDbServerIpValid:false,
        isWebServerIpValid:false,
        isMailServerIpValid:false,
        isAdminPanelServerIpValid:false,
        isHostServerIpValid:false,
        isCoreServerIpValid:false
    });
    const [toggle,setToggle] = useState({
        isHost: false,
        isWebserver: false,
        isDbServer: false,
        isMail: false,
        isDedicated: false,
        isAdminPanel: false,
        isPublic: true,
        isCoreServer: false
    });
    const resetServers = () => ({
        hostServerId: null, hostServerIp: '', dbServerId: null, dbServerIp: '',
        webServerId: null, webServerIp: '', adminPanelServerId: null, adminPanelServerIp: '',
        mailServerId: null, mailServerIp: '', coreServerId: null, coreServerIp: '',
        dedicatedServerId: null, connectionString: ''
    });


    const handleToggleChange = (value, name) => {
        setToggle((prevState) => ({ ...prevState, [name]: value }));
        let updatedServers = { ...servers };
        console.log(value)
        if (name === 'isPublic') {
            updatedServers = value ? {
                hostServerId: initialSource.hosts ? initialSource.hosts[0] : null,
                dbServerId: initialSource.dbServers ? initialSource.dbServers[0] : null,
                webServerId: initialSource.webservers ? initialSource.webservers[0] : null,
                adminPanelServerId: initialSource.adminPanels ? initialSource.adminPanels[0] : null,
                mailServerId: initialSource.mails ? initialSource.mails[0] : null,
                coreServerId: initialSource.coreServers ? initialSource.coreServers[0] : null,
                dedicatedServerId: initialSource.dedicatedServers ? initialSource.dedicatedServers[0] : null,
                connectionString: ''
            } : resetServers();
        }

        const serverKeys = {
            isHost: 'hostServer',
            isWebserver: 'webServer',
            isDbServer: 'dbServer',
            isAdminPanel: 'adminPanelServer',
            isMail: 'mailServer',
            isDedicated: 'dedicatedServer',
            isCoreServer: 'coreServer'
        };

        if (name in serverKeys) {
            const serverKey = serverKeys[name];
            if (value) {
                updatedServers[`${serverKey}Id`] = null;
            } else {
                updatedServers[`${serverKey}Ip`] = null;
                updatedServers[`${serverKey}Id`] = initialSource[serverKey.toLowerCase() + 's'] ? initialSource[serverKey.toLowerCase() + 's'][0] : null;
            }
            setServer(updatedServers);
            if (!value) {
                setIpValidationStatus({
                    isDedicatedServerIpValid: false,
                    isDbServerIpValid: false,
                    isWebServerIpValid: false,
                    isMailServerIpValid: false,
                    isAdminPanelServerIpValid: false,
                    isHostServerIpValid: false,
                    isCoreServerIpValid: false
                });
            }
        }
    };



    const handleSelectValue=(data,name)=>{
        setServer({...servers,[name]:data});
    }




    const handleValue = (e) => {
        const { name, value } = e.target;
        const isValidIp = name.includes("Ip") && ipRegex.test(value);
        const validationKey = `is${name.charAt(0).toUpperCase() + name.slice(1)}Valid`;
        setIpValidationStatus((prevState) => ({
            ...prevState,
            [validationKey]: isValidIp
        }));
        if (name === "connectionString" && value === '') {
            setServer((prevState) => ({ ...prevState, [name]: null }));
        } else {
            setServer((prevState) => ({ ...prevState, [name]: value }));
        }
    };


    return {
        servers,
        handleValue,
        handleSelectValue,
        setServer,
        ipValidationStatus,
        setIpValidationStatus,
        toggle,
        setToggle,
        handleToggleChange,
        isValidIp
    }
}