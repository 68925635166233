import React from "react";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";


export const HandleReservationHotel = () => {
  return(
      <div class="  space-y-5">
          <div className="box bg-white dark:bg-themeDarkSalt700 p-1 md:p-5">
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 p-3 rounded-md post-input dark:bg-themeDarkPostInput">
                  <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">نام ساختمان:</label>
                      <MultiDataSelect placeholder={'انتخاب'} />
                  </div>
                  <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">نام اتاق:</label>
                      <MultiDataSelect placeholder={'انتخاب'} />
                  </div>
                  <div class="space-y-2 ">
                      <label className="text-xs dark:text-gray-300">
                          کد اتاق:
                      </label>
                      <MultiDataSelect placeholder={'انتخاب'} />
                  </div>
                  <div class="space-y-2 ">
                      <label className="text-xs dark:text-gray-300">مشتری:</label>
                      <MultiDataSelect placeholder={'انتخاب'} />
                  </div>
                  <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300"> کداشتراک:</label>
                      <InputText type={"text"} className={" input w-full"} />
                  </div>
                  <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300"> شماره همراه:</label>
                      <InputText type={"text"} className={" input w-full"} />
                  </div>
                  <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">تاریخ امروز</label>
                      <InputText type={"text"} className={" input w-full"}/>
                  </div>
                  <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">تاریخ شروع:</label>
                      <InputText type={"text"} className={" input w-full"} />
                  </div>
                  <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">تاریخ پایان:</label>
                      <InputText type={"text"} className={" input w-full"} />
                  </div>
                  <div class="space-y-2">
                      <label className="text-xs dark:text-gray-300">مبلغ قابل پرداخت:</label>
                      <InputText type={"text"} className={" input w-full"} />
                  </div>
                  <div class="space-y-2 sm:col-span-2 md:col-span-3 xl:col-span-5">
                      <label className="text-xs dark:text-gray-300"> توضیحات:</label>
                      <InputTextarea  rows={5} cols={30} className={"input w-full"} />
                  </div>
              </div>
          </div>
          <div class="flex items-center justify-end gap-3">
              <button className="button text-white bg-themeDanger  w-24">انصراف</button>
              <button className="button text-white bg-themeInformation dark:bg-themeDarkInformation w-24">ثبت</button>
          </div>
      </div>
  )
}