import React, {useState} from "react";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleGetUserAddress} from "./CartService";
import {handleFailureMessage} from "../../../Services/Globals/Errors/NotificationService";

export const useCustomCart=(setErrors,setInitialSource,initialSource)=>{
    const [cart,setCart] = useState({id:null,userId:null,priceType:null,creationDate:null,productCartItems:[],IsAddressSaved:true,userAddressId:null,countryId:null,stateProvinceId:null,cityId:null,postalCode:null,address:null,detailsOfAddress:null,productTransportId:null,
    index:null



    });
    const [productCartItems,setProductCartItems] = useState([]);
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setCart({...cart,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setCart({...cart,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency)
        {
            return '-'
        }
        return currency.label;
    }
    const handleSelectValue=async(data,name)=>{
        if(name === 'userId'){
            try {
                const response = await GET(`/Users/Addresses/Selection`, {userId:data.value}, 'IEnumerable')
                if(response.status === 200){
                    const userAddresses = handleGetUserAddress(response.data);
                    setInitialSource((prevStates)=>({...prevStates,addresses:userAddresses}));
                    setCart((prevState)=>({...prevState,userAddressId: userAddresses[0]}));
                    // continues filtering for country,stateProvinceId,cityId
                    setCart({...cart,[name]:data});
                }
                else{

                }
            }catch (e) {
                console.log(e);
            }
        }
        else if (name === 'countryId') {
            try {
                const responseStates = await GET(`/Countries/${data.value}/StateProvinces/Selection`, {}, "IEnumerable");

                // Check if the response is successful and contains data
                if (responseStates.status === 200 && responseStates.data.length > 0) {
                    const stateProvinceId = responseStates.data[0].key;
                    const responseCities = await GET(`/Countries/${stateProvinceId}/Cities/Selection`, {}, "IEnumerable");

                    // Check if the response is successful and contains data
                    if (responseCities.status === 200 && responseCities.data.length > 0) {
                        const stateData = convertSelectDataHandler(responseStates.data);
                        const citiesData = convertSelectDataHandler(responseCities.data);

                        setInitialSource((prevStates) => ({
                            ...prevStates,
                            stateProvinces: stateData,
                            cities: citiesData,
                        }));
                        setCart({
                            ...cart,
                            [name]: data,
                            stateProvinceId: stateData[0],
                            cityId: citiesData[0],
                        });
                    } else {
                        handleFailureMessage('شهری وجود ندارد');
                    }
                } else {
                    handleFailureMessage('ااستانی وجود ندارد');
                }
            } catch (e) {
                console.log(e);
            }
        }
        else if (name === 'stateProvinceId') {
            try {
                const responseCities = await GET(`/Countries/${data.value}/Cities/Selection`, {}, "IEnumerable");

                // Check if the response is successful and contains data
                if (responseCities.status === 200 && responseCities.data.length > 0) {
                    const citiesData = convertSelectDataHandler(responseCities.data);

                    setInitialSource((prevStates) => ({
                        ...prevStates,
                        cities: citiesData,
                    }));

                    setCart({
                        ...cart,
                        [name]: data,
                        cityId: citiesData[0],
                    });
                } else {
                    handleFailureMessage('شهری وجود ندارد');
                }
            } catch (e) {
                console.log(e);
            }
        }
        else if (name === 'userAddressId') {
            try {
                const { countryId, stateProvinceId, cityId, postalCode, address } = data;
                const selectedCountry = initialSource.countries.find((item) => item.value === countryId);

                const responseStates = await GET(`/Countries/${countryId}/StateProvinces/Selection`, {}, "IEnumerable");
                const responseCities = await GET(`/Countries/${stateProvinceId}/Cities/Selection`, {}, "IEnumerable");

                if (responseStates.status === 200 && responseStates.data.length > 0 &&
                    responseCities.status === 200 && responseCities.data.length > 0) {

                    const stateData = convertSelectDataHandler(responseStates.data);
                    const citiesData = convertSelectDataHandler(responseCities.data);

                    const selectedStateProvince = stateData.find((item) => item.value === stateProvinceId);
                    const selectedCity = citiesData.find((item) => item.value === cityId);

                    setInitialSource((prevStates) => ({
                        ...prevStates,
                        stateProvinces: stateData,
                        cities: citiesData,
                    }));

                    setCart({
                        ...cart,
                        [name]: data,
                        countryId: selectedCountry,
                        stateProvinceId: selectedStateProvince,
                        cityId: selectedCity,
                        address: address,
                        postalCode: parseInt(postalCode),
                    });
                } else {
                    handleFailureMessage('شهری یا استانی وجود ندارد');
                }
            } catch (e) {
                console.log(e);
            }
        }
        else{
            setCart({...cart,[name]:data});
        }
    }
    const handleCheckBox = (value,name)=>{
        setCart({...cart,[name]:value});
    }


    const handleCreateDateTime=(dateTime)=>{
        setCart({...cart,creationDate: dateTime});
    }
    const handleApplyIsPrev=(name,value)=>{
        setCart({...cart,[name]:value});
    }



    const[sendTypes,setSendTypes] = useState({productCartId:null,sendType:null});
    const handleChangeSendType=(data,name)=>{
        setSendTypes({...sendTypes,[name]:data});
    }


    const [bill,setBill] = useState({productCartId:null,priceType:null,creationDate:null,expirationDate:null,applyDiscount:false,applyTax:false,applyOffers:false})
    const handleChangeToggle=(name,value)=>{
        setBill({...bill,[name]:value});
    }
    const handleCreationBillDateTime=(dateTime)=>{
        setBill({...bill,creationDate: dateTime});
    }
    const handleExpireBillDateTime=(dateTime)=>{
        setBill({...bill,expirationDate: dateTime});
    }



    return {
        handleNumberValue,
        handleValue,
        handleSelectValue,
        cart,
        setCart,
        productCartItems,
        setProductCartItems,
        handleCreateDateTime,
        sendTypes,
        setSendTypes,
        handleChangeSendType,
        bill,
        setBill,
        handleChangeToggle,
        handleCreationBillDateTime,
        handleExpireBillDateTime,
        handleApplyIsPrev,
        handleCheckBox,
        handleGetCurrencyType
    }


}