import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {InputText} from "primereact/inputtext";
import {TabMenuTwo} from "../../../Commans/UIParts/TabMenuTwo";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {usePersonTabs} from "./Tabs/usePersonTabs";
import {NaturalTab} from "./Tabs/NaturalTab";
import {LegalTab} from "./Tabs/LegalTab";
import {Subscription} from "./Subscription";
import {Contacts} from "./Contacts";
import {CompanyAddress} from "./CompanyAddress";
import {HomeAddres} from "./HomeAddres";
import {Emails} from "./Emails";
import {Websites} from "./UserWebsites/Websites";
import {Calls} from "./UserCalls/Calls";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {Contracts} from "./UserContracts/Contracts";
import {Notes} from "./UserNotes/Notes";
import {Reminders} from "./UserReminders/Reminders";
import {Complaint} from "./Complaint";

export const HandleUserDetails = ({searchType,searchValue,IsOpen})=>{
    const { t } = useTranslation();
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const {handleChangePersonTab,personTabId,personTabs,setUserTabId,userTabId,handleChangeUserTabId,userTabs}=usePersonTabs();
    const showSpinner=()=>setIsShowSpinner(true);
    const hideSpinner=()=>setIsShowSpinner(false);
    const [profile,setProfile]=useState(null);

    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Users/Profile?SearchValue=${searchValue}&SearchType=${searchType}`,{},'single');
            if(response.status === 200){
                hideSpinner();

                setProfile(response.data);
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }
    useEffect(()=>{
        fetchData();
    },[])


    return (
        <>
            <div className="space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                {
                    IsOpen?null:
                        <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                            <ListSearch label={null} icon={null} >
                                <div class=" col-span-1 md:col-span-2 lg:col-span-1 space-y-2">
                                    <label className="text-xs dark:text-gray-300">{t("براساس(نام-تلفن-کد ملی)")}</label>
                                    <InputText type="text"  className=" input w-full" />
                                </div>
                                <ButtonSearchList
                                />
                            </ListSearch>
                            <ListFilter label={null} icon={null}
                                        showChildrenTwo={false}
                                        childrenOne={
                                            <>
                                                <ButtonPlus
                                                    title={t("Accounts")}
                                                    icon={"pi-link"}
                                                    tooltipTitle={t("Accounts")}
                                                />

                                            </>
                                        }
                            />

                        </div>

                }

                <div class="grid grid-cols-12 gap-3">
                    <div className={"col-span-12 lg:col-span-8 "}>
                        <div className={"space-y-4"}>
                            <div className={"space-y-2"}>
                                <div class="relative grid grid-cols-2 lg:grid-cols-6 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-2">
                                    <TabMenuTwo activeTab={personTabId} handleTabClick={handleChangePersonTab} tabs={personTabs} />
                                </div>

                                <div className="post__content tab-content p-2 bg-white dark:bg-themeDarkSalt700">
                                    <div className={`tab-content__pane ${ personTabId === "natural" ? "active" : "" }`}  id="natural">
                                        {
                                            personTabId === 'natural'?
                                                <NaturalTab
                                                  user={profile?.user}
                                                />
                                                :
                                                null
                                        }

                                    </div>

                                    <div className={`tab-content__pane ${ personTabId === "legal" ? "active" : "" }`}  id="legal">
                                        {
                                            personTabId === 'legal'?
                                                <LegalTab
                                                    company={profile?.company}
                                                />
                                                :
                                                null
                                        }

                                    </div>

                                </div>

                            </div>



                            <div className={"space-y-2"}>
                                <div class="relative grid grid-cols-2 lg:grid-cols-6 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-2">
                                    <TabMenuTwo activeTab={userTabId} handleTabClick={handleChangeUserTabId} tabs={userTabs} />
                                </div>
                                <div className="post__content tab-content p-2 bg-white dark:bg-themeDarkSalt700">
                                    <div className={`tab-content__pane ${ userTabId === "contacts" ? "active" : "" }`}  id="contacts">
                                        <div>
                                            {
                                                userTabId === 'contacts'?
                                                    <Calls  type={'EnNoteType_Conversation'} contacts={profile?.notes}/>
                                                    :null
                                            }

                                        </div>
                                    </div>
                                    <div className={`  p-2 ${ userTabId === "reminder" ? "reminder" : "" }`}  id="reminder">
                                        {
                                            userTabId === 'reminder'?
                                                <Reminders type={'EnNoteType_Reminder'} notes={profile?.notes}/>
                                                :null
                                        }
                                    </div>
                                    <div className={`p-2 ${ userTabId === "note" ? "note" : "" }`}  id="note">
                                        {
                                            userTabId === 'note'?
                                                <Notes type={'EnNoteType_Text'} notes={profile?.notes}/>
                                                :null
                                        }
                                    </div>
                                    <div className={`tab-content__pane ${ userTabId === "complaints" ? "complaints" : "" }`}  id="complaints">
                                        {
                                            userTabId === 'complaints'?
                                                <Complaint type={'EnNoteType_Complaint'} notes={profile?.notes}/>
                                                :null
                                        }
                                    </div>
                                    <div className={`tab-content__pane ${ userTabId === "feedbacks" ? "feedbacks" : "" }`}  id="feedbacks">
                                        {
                                            userTabId === 'feedbacks'?
                                                <Complaint type={'EnNoteType_FeedBack'} notes={profile?.notes}/>
                                                :null
                                        }
                                    </div>
                                    <div className={` tab-content__pane ${ userTabId === "contracts" ? "contracts" : "" }`}  id="contracts">

                                        {
                                            userTabId === 'contracts'?
                                                <Contracts contracts={profile?.contracts}/>
                                                :null
                                        }

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                    <div className={"col-span-12 lg:col-span-4 bg-white dark:bg-themeDarkSalt700 p-2"}>
                        <div>
                            <div className={"space-y-4"}>
                                <Subscription user={profile?.user}/>
                                <div className={"space-y-3"}>
                                    <div className={"space-y-3 px-2"}>
                                        <Contacts user={profile?.user} company={profile?.company}/>
                                        <CompanyAddress company={profile?.company} />
                                        <HomeAddres user={profile?.user}/>
                                        <Emails user={profile?.user} />
                                        <Websites websites={profile?.webSites}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
