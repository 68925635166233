import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DELETE, GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import Select from "react-select";
import TextArea from "../../../Commans/UIParts/TextArea";
import {useTranslation} from "react-i18next";
import {useDataEntrance} from "../../../Form/DataEntrance/useDataEntrance";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {
    handleCreateAnswerItems,
    handleGetJsonFromElementToAnswer
} from "../../../Form/DataEntrance/dataEntranceService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {InputTextElement} from "../../../Form/DataEntrance/Elements/InputTextElement";
import {InputTextAreaElement} from "../../../Form/DataEntrance/Elements/InputTextAreaElement";
import {BreakLineElement} from "../../../Form/DataEntrance/Elements/BreakLineElement";
import {InputNumberElement} from "../../../Form/DataEntrance/Elements/InputNumberElement";
import {InputUrlElement} from "../../../Form/DataEntrance/Elements/InputUrlElement";
import {ColorElement} from "../../../Form/DataEntrance/Elements/ColorElement";
import {SliderRange} from "../../../Form/DataEntrance/Elements/SliderRange";
import {PriceElement} from "../../../Form/DataEntrance/Elements/PriceElement";
import {PasswordElement} from "../../../Form/DataEntrance/Elements/PasswordElement";
import {EmailElement} from "../../../Form/DataEntrance/Elements/EmailElement";
import {UrlElement} from "../../../Form/DataEntrance/Elements/UrlElement";
import {PhoneElement} from "../../../Form/DataEntrance/Elements/PhoneElement";
import {DateElement} from "../../../Form/DataEntrance/Elements/DateElement";
import {DateTimeElement} from "../../../Form/DataEntrance/Elements/DateTimeElement";
import {TimeElement} from "../../../Form/DataEntrance/Elements/TimeElement";
import {CheckBoxElement} from "../../../Form/DataEntrance/Elements/CheckBoxElement";
import {RadioElement} from "../../../Form/DataEntrance/Elements/RadioElement";
import {SelectElement} from "../../../Form/DataEntrance/Elements/SelectElement";
import {MultiSelectElement} from "../../../Form/DataEntrance/Elements/MultiSelectElement";
import {Location} from "../../../Form/DataEntrance/Elements/Location";
import {FileElement} from "../../../Form/DataEntrance/Elements/FileElement";
import {ImageElement} from "../../../Form/CreateForm/FormBuilders/Elements/ImageElement";
import {AudioElement} from "../../../Form/CreateForm/FormBuilders/Elements/AudioElement";
import {VideoElement} from "../../../Form/CreateForm/FormBuilders/Elements/VideoElement";
import Swal from "sweetalert2";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {useFormSignature} from "./useFormSignature";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/primereact.all.esm";
export const HandleUserSignatureForm = () => {

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const {formId,formDataGroupId} = useParams();
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [initialSource,setInitialSource]=useState({
        sizeParameters:[{value:0,label:'کوچک',className:'harmony-col-span-1'},{value:1,label:'متوسط',className:'harmony-col-span-1 harmony-md-col-span-2'},{value:2,label:'تقریبا بزرگ',className:'harmony-col-span-1 harmony-md-col-span-3'},{value:3,label:'بزرگ',className:'harmony-col-span-1 harmony-md-col-span-4'}],
        currencies:[],
        calendarViews:[],
        calendarTypes:[],
        calendarLanguages:[],
    })
    const {handleChangeData,setElements,elements,handlePropertyChange} = useDataEntrance();
    const {handleValue,setSignature,signature,handleSelectValue}=useFormSignature();
    const [refresh,setRefresh]=useState(false);

    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const handleInitialDataSource=(calendarTypes,calendarLanguages,calendarViews)=>{
        const calendarTypesData = convertSelectDataHandler(calendarTypes)
        const calendarLanguagesData = convertSelectDataHandler(calendarLanguages)
        const calendarViewsData = convertSelectDataHandler(calendarViews)
        const sizeParameters = [{value:0,label:'کوچک',className:'harmony-col-span-1'},{value:1,label:'متوسط',className:'harmony-col-span-1 harmony-md-col-span-2'},{value:2,label:'تقریبا بزرگ',className:'harmony-col-span-1 harmony-md-col-span-3'},{value:3,label:'بزرگ',className:'harmony-col-span-1 harmony-md-col-span-4'}];
        setInitialSource({
            calendarTypes: calendarTypesData,
            calendarLanguages: calendarLanguagesData,
            calendarViews: calendarViewsData,
            sizeParameters: sizeParameters
        });
        return {
            calendarTypesData,
            calendarLanguagesData,
            calendarViewsData,
            sizeParameters
        }
    }
    const fetchData = async () => {
        try {
            showSpinner();
            const [
                responseCalendarType,
                responseCalendarLanguage,
                responseCalendarView,
                responseForm
            ] = await Promise.all([
                GET(`/Enumerations/EnCalendarType`),
                GET(`/Enumerations/EnCalendarLangType`),
                GET(`/Enumerations/EnCalendarView`),
                GET(`/Forms/${formId}`, {}, 'single')
            ]);
            if (
                responseCalendarType.status === 200 &&
                responseCalendarLanguage.status === 200 &&
                responseCalendarView.status === 200 &&
                responseForm.status === 200
            ) {
                const {
                    calendarLanguagesData,
                    calendarTypesData,
                    calendarViewsData,
                    sizeParameters
                } = handleInitialDataSource(
                    responseCalendarType.data,
                    responseCalendarLanguage.data,
                    responseCalendarView.data
                );

                const responseFormDataGroup = await GET(`/Forms/DataGroups/${formDataGroupId}`,{},'single');
                const {formItems}=responseForm.data;
                const {formData,roadMapStep}=responseFormDataGroup.data
                setSignature({operation: roadMapStep?.operation,note: null})


                const retrievedElements =  handleGetJsonFromElementToAnswer(
                    formItems,
                    calendarLanguagesData,
                    calendarTypesData,
                    calendarViewsData,
                    sizeParameters,
                    formData
                );
                setElements(retrievedElements);
            }
            else {
                handleApiError(responseCalendarType,hideSpinner);
                handleApiError(responseCalendarLanguage,hideSpinner);
                handleApiError(responseCalendarView,hideSpinner);
                handleApiError(responseForm,hideSpinner);
            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };



    useEffect(()=>{
        fetchData();
    },[refresh])








    const handleSubmitSignature=async(confirmtionStatus)=>{
        if(confirmtionStatus){
            const TailWindButton = Swal.mixin({
                customClass: {
                    confirmButton: AlertSetting.customClass.confirmButton,
                    cancelButton: AlertSetting.customClass.cancelButton
                },
                buttonsStyling: false
            })
            TailWindButton.fire({
                title:AlertSetting.TailWindButton.title,
                text: AlertSetting.TailWindButton.text,
                icon:AlertSetting.TailWindButton.icon,
                showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                reverseButtons: AlertSetting.TailWindButton.reverseButtons
            }).then( async (result) =>{
                if (result.isConfirmed) {
                    try {
                        showSpinner();
                        const response = await POST(`/Forms/DataGroups/Sign`,{
                            confirmationTypeOperation:confirmtionStatus,
                             note:signature.note === null || signature.note === undefined?'':signature.note,
                            formDataGroupId:formDataGroupId
                        });
                        if (response.status === 200) {
                            hideSpinner();
                            Refresh();
                            redirectTo('/UserPanel/Cartable')
                        }
                        else {
                            handleApiError(response,hideSpinner)
                        }
                    }catch (e) {
                        hideSpinner();
                    }
                }
                else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    return;
                }
            })
        }
        else{
            const TailWindButton = Swal.mixin({
                customClass: {
                    confirmButton: AlertSetting.customClass.confirmButton,
                    cancelButton: AlertSetting.customClass.cancelButton
                },
                buttonsStyling: false
            })
            TailWindButton.fire({
                title:AlertSetting.TailWindButton.title,
                text: AlertSetting.TailWindButton.text,
                icon:AlertSetting.TailWindButton.icon,
                showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                reverseButtons: AlertSetting.TailWindButton.reverseButtons
            }).then( async (result) =>{
                if (result.isConfirmed) {
                    try {
                        showSpinner();
                        const response = await POST(`/Forms/DataGroups/Sign`,{
                            confirmationTypeSignature:confirmtionStatus,
                            note:signature.note === null || signature.note === undefined?'':signature.note,
                            formDataGroupId:formDataGroupId
                        });
                        if (response.status === 200) {
                            hideSpinner();
                            Refresh();
                            redirectTo('/UserPanel/Cartable')
                        }
                        else {
                            handleApiError(response,hideSpinner)
                        }
                    }catch (e) {
                        hideSpinner();
                    }
                }
                else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    return;
                }
            })
        }
    }




    return(



        <div class="space-y-5 relative">
            {
                IsShowSpinner?
                    <div className=" huploader_loading" >
                        <div className="relative">
                            <LoadingHarmony/>
                        </div>
                    </div>
                    :
                    null
            }

            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                </>
                            }
                />

            </div>
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5 min-h-screen">

                <div className="dark:bg-themeDarkPostInput harmony-form post-input post-input-design" >
                    <div class={"harmony-grid harmony-grid-cols-1 harmony-md-grid-cols-4 harmony-gap-3 harmony-add-form"}>

                        {
                            elements.map((item, index) =>
                                item.typeName === 'TextBox' ?
                                    <InputTextElement
                                        index={index}
                                        item={item}
                                        handleChangeData={handleChangeData}
                                    />
                                    : item.typeName === 'TextArea'?
                                        <InputTextAreaElement
                                            index={index}
                                            item={item}
                                            handleChangeData={handleChangeData}
                                        />
                                        :
                                        item.typeName === 'SelectionBreak'?
                                            <BreakLineElement
                                                index={index}
                                                item={item}
                                            />:
                                            item.typeName === 'Numeric'?
                                                <InputNumberElement
                                                    index={index}
                                                    item={item}
                                                    handleChangeData={handleChangeData}
                                                />:
                                                item.typeName === 'Url'?
                                                    <InputUrlElement
                                                        index={index}
                                                        item={item}
                                                        handleChangeData={handleChangeData}
                                                    />:
                                                    item.typeName === 'Color'?
                                                        <ColorElement
                                                            index={index}
                                                            item={item}
                                                            handleChangeData={handleChangeData}
                                                        />
                                                        :
                                                        item.typeName === 'Slider'?
                                                            <SliderRange
                                                                index={index}
                                                                item={item}
                                                                handleChangeData={handleChangeData}
                                                            />:
                                                            item.typeName === 'Price'?
                                                                <PriceElement
                                                                    index={index}
                                                                    item={item}
                                                                    handleChangeData={handleChangeData}
                                                                />
                                                                :
                                                                item.typeName === 'Password'?
                                                                    <PasswordElement
                                                                        index={index}
                                                                        item={item}
                                                                        handleChangeData={handleChangeData}
                                                                        handlePropertyChange={handlePropertyChange}
                                                                    />
                                                                    :
                                                                    item.typeName === 'Email'?
                                                                        <EmailElement
                                                                            index={index}
                                                                            item={item}
                                                                            handleChangeData={handleChangeData}
                                                                        />
                                                                        :
                                                                        item.typeName === 'Url'?
                                                                            <UrlElement
                                                                                index={index}
                                                                                item={item}
                                                                                handleChangeData={handleChangeData}
                                                                            />
                                                                            :
                                                                            item.typeName === 'Phone'?
                                                                                <PhoneElement
                                                                                    index={index}
                                                                                    item={item}
                                                                                    handleChangeData={handleChangeData}
                                                                                />
                                                                                :
                                                                                item.typeName === 'Date'?
                                                                                    <DateElement
                                                                                        index={index}
                                                                                        item={item}
                                                                                        handleChangeData={handleChangeData}
                                                                                    />
                                                                                    :
                                                                                    item.typeName === 'DateTime'?
                                                                                        <DateTimeElement
                                                                                            index={index}
                                                                                            item={item}
                                                                                            handleChangeData={handleChangeData}
                                                                                        />
                                                                                        :
                                                                                        item.typeName === 'Time'?
                                                                                            <TimeElement
                                                                                                index={index}
                                                                                                item={item}
                                                                                                handleChangeData={handleChangeData}
                                                                                            />
                                                                                            :
                                                                                            item.typeName === 'CheckBox'?
                                                                                                <CheckBoxElement
                                                                                                    index={index}
                                                                                                    item={item}
                                                                                                    handleChangeData={handleChangeData}
                                                                                                />
                                                                                                :
                                                                                                item.typeName === 'Radio'?
                                                                                                    <RadioElement
                                                                                                        index={index}
                                                                                                        item={item}
                                                                                                        handleChangeData={handleChangeData}
                                                                                                    />
                                                                                                    :
                                                                                                    item.typeName === 'Select'?
                                                                                                        <SelectElement
                                                                                                            index={index}
                                                                                                            item={item}
                                                                                                            handleChangeData={handleChangeData}
                                                                                                        />
                                                                                                        :
                                                                                                        item.typeName === 'MultiSelect'?
                                                                                                            <MultiSelectElement
                                                                                                                index={index}
                                                                                                                item={item}
                                                                                                                handleChangeData={handleChangeData}
                                                                                                            />
                                                                                                            :
                                                                                                            item.typeName === 'Location'?
                                                                                                                <Location
                                                                                                                    index={index}
                                                                                                                    item={item}
                                                                                                                    handleChangeData={handleChangeData}
                                                                                                                />
                                                                                                                :
                                                                                                                item.typeName === 'File'?
                                                                                                                    <FileElement
                                                                                                                        index={index}
                                                                                                                        item={item}
                                                                                                                        handleChangeData={handleChangeData}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    item.typeName === 'Image'?
                                                                                                                        <ImageElement
                                                                                                                            index={index}
                                                                                                                            item={item}
                                                                                                                            handleChangeData={handleChangeData}
                                                                                                                        />
                                                                                                                        :
                                                                                                                        item.typeName === 'Audio'?
                                                                                                                            <AudioElement
                                                                                                                                index={index}
                                                                                                                                item={item}
                                                                                                                                handleChangeData={handleChangeData}
                                                                                                                            />
                                                                                                                            :
                                                                                                                            item.typeName === 'Video'?
                                                                                                                                <VideoElement
                                                                                                                                    index={index}
                                                                                                                                    item={item}
                                                                                                                                    handleChangeData={handleChangeData}
                                                                                                                                />
                                                                                                                            :
                                                                                                                            null


                            )
                        }

                    </div>
                </div>

                <div className={"post-input dark:bg-themeDarkPostInput post-input-design p-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3"}>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("عملیات")}</label>
                        <InputText disabled={true} className={"input w-full"} value={signature.operation} />
                    </div>
                    <div className="space-y-1 lg:col-span-3">
                        <label className="text-xs dark:text-gray-300">{t("توضیحات اضافی")}</label>
                        <InputTextarea className={"input w-full"} onInput={(e)=>handleValue(e)}  name={'note'} value={signature.note}  rows={2} />
                    </div>
                </div>


                <div className={`flex justify-center gap-3 lg:justify-end post-input dark:bg-themeDarkPostInput post-input-design p-3`}>
                    <button type={'button'}  onClick={async ()=>handleSubmitSignature(true)} className="button w-24 bg-themeSuccess text-white">{t("Confirm")}</button>
                    <button type={'button'}  onClick={async ()=>handleSubmitSignature(false)}  className="button w-24  text-white bg-themeDanger ">{t("Reject")}</button>
                    <button type={'button'}  onClick={()=>redirectTo('/UserPanel/Cartable')}  className="button text-white bg-themeDanger  dark:bg-themeDarkInformation">{t("Cancel")}</button>
                </div>








            </div>
        </div>
    )
}