import React, {useState} from "react";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

export const useCustomDocument=(setErrors)=>{

    const [document,setDocument] = useState({harmonyDocumentId:null,title:null,slug:null,description:null,keywords:[],content:'',documentGroupId:null,harmonyDocumentType:null,image:null,imagePath:null,files:[],links:[],order:0,programmingSection:null,author:null,oldSlug:null});
    const [IsSlugValid,setIsSlugValid] = useState(true);
    const [IsMenuOpen,setIsMenuOpen] = useState(false);
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setDocument({...document,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setDocument({...document,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        if(name === 'documentGroupId'){
            setDocument({...document,[name]: data});
            setIsMenuOpen(false);
        }
        setDocument({...document,[name]: data});
    }

    const handleRemoveImagePath=(path,name)=>setDocument((prevState)=>({...prevState,[name]:path}))
    const handleResetImages = () => {
        setDocument((prevState)=>({...prevState,image: null, imagePath: null}));
    }
    const handleChangeImage =(data,name)=>{
        setDocument((prevState) => ({ ...prevState, [name]: data }));
    }

    const validateSlug = async (e,oldSlug,IsEdit) => {
        const { value } = e.target;
            try {

                const previousSlug = oldSlug && IsEdit?oldSlug:null;

                if(IsEdit && (oldSlug === previousSlug)){
                    setIsSlugValid(true);
                }

                const response = await GET('/Public/SlugValidation',{NewSlug:value,OldSlug:previousSlug},'single')
                if(response.status === 200){
                    const {isSlugValid} = response.data
                    setIsSlugValid(isSlugValid);
                }
            }catch (e) {
                console.log(e);
            }
    };

    const handleKeywordsChange = (keywords) => {
        setDocument((prevState) => ({ ...prevState,keywords: keywords }));
    };

    const handleChangeContent=(content)=>{
        setDocument({...document,content: content});
    }
    const handleChangeDocumentAttachment=(files)=>{
        setDocument({...document,files: files});
    }
    const handleEmptyDocumentAttachment=()=>{
        setDocument({...document,files:[]});
    }

    return {
        document,
        setDocument,
        handleValue,
        handleSelectValue,
        IsSlugValid,
        validateSlug,
        handleNumberValue,
        IsMenuOpen,
        setIsMenuOpen,
        handleChangeContent,
        handleChangeDocumentAttachment,handleEmptyDocumentAttachment,
        handleKeywordsChange,
        handleRemoveImagePath,
        handleChangeImage,
        handleResetImages
    }

}