import React, {useEffect, useState} from "react";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {Card} from "primereact/card";
import {Timeline} from "primereact/timeline";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {Image} from "primereact/image";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";

export const HandleFormDetails = () => {
    const {t} = useTranslation();
    const redirectTo = useNavigate();
    const[IsShowSpinner,setIsShowSpinner] = useState(false);
    const [rowData,setRowData]=useState({operations:[],roadMapName:null});
    const {formDataGroupId}=useParams();
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const fetchData = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Forms/DataGroups/${formDataGroupId}/Sign`, {}, 'IEnumerable');
            if (response.status === 200)
            {
                const {operations,formRoadMap}=response.data;
                setRowData({
                    operations: operations,
                    roadMapName:formRoadMap?.name
                })
                hideSpinner();
            } else {
                handleApiError(response, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        }
    };




useEffect(()=>{
   fetchData()
},[])














    const customizedMarker = (item) => {
        return (
            <span className="flex size-10 items-center justify-center text-white border dark:border-themeDarkColorLine bg-white dark:bg-themeDarkPostInput z-1 box">
                <Image src={item.user?.profileImage} zoomSrc={item.user?.profileImage}  preview />:
            </span>
        );
    };



    const customizedContent = (item) => {
        return (
            <Card >
                <div className={"space-y-3"}>

                    <div className={`p-2 ${item.confirmationTypeOperation?'bg-themeSuccess':'bg-themeDanger'} box border text-white`}>
                        {item.roadMapStep?.title}
                    </div>
                    <div className={"grid p-3 grid-cols-1 rounded-md border dark:border-themeDarkColorLine shadow md:grid-cols-2 gap-2 post-input dark:bg-themeDarkPostInput"}>
                        <div className={"flex items-center gap-2"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("userName")}:</p>
                            <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>{item.user?.userName}</p>
                        </div>
                    </div>

                    <div className={"rounded-md p-3 text-xs border dark:border-themeDarkColorLine shadow post-input dark:bg-themeDarkPostInput"}>
                        <p className={"text-xs dark:text-gray-300"}>{t("description")}:</p>

                        <p className={"text-xs dark:text-gray-300"}>
                            {item.note}
                        </p>
                    </div>

                    <div className={"rounded-md p-3 text-xs border dark:border-themeDarkColorLine shadow post-input dark:bg-themeDarkPostInput"}>
                        <p className={"text-xs dark:text-gray-300"}>{t("operation")}:</p>
                        <p className={"text-xs dark:text-gray-300"}>
                            {item.operation}
                        </p>
                    </div>

                    <div className={"rounded-md p-3 text-xs border dark:border-themeDarkColorLine shadow post-input dark:bg-themeDarkPostInput"}>
                        <Image src={''} zoomSrc={''}  preview />
                    </div>

                </div>
            </Card>
        );
    };

    return (
        <>
            <div class=" space-y-4 relative">

                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }


                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 ">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Form/Create`)}
                                            tooltipTitle={t("CreatingForm")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Form/RoadMap`)}
                                            tooltipTitle={t("FormWorkflow")}
                                            title={t("Workflow")}
                                            icon={"pi-sync"}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Form/Organize`)}
                                            tooltipTitle={t("OrganizeForms")}
                                            title={t("Organize")}
                                            icon={"pi-sort-alpha-down"}
                                        />
                                    </>
                                }

                    />

                </div>

                 <div className={"relative space-y-5"}>
                     {rowData.roadMapName}
                     <div className={" H-body-ag-grid "} >
                         <Timeline value={rowData.operations} align="alternate" className="customized-timeline h-timline" marker={customizedMarker} content={customizedContent} />
                     </div>
                 </div>

            </div>


        </>
    );
};
