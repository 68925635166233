export const convertContractData = (obj,files) => {
    const contract = {
        userId: obj.userId ?? null,
        contractId:obj.contractId??null,
        contractType: obj.contractType === null || obj.contractType === undefined?null:obj.contractType.value,
        startContract: obj.startContract === null || obj.startContract === undefined?null:obj.startContract,
        endContract: obj.endContract === null || obj.endContract === undefined?null:obj.endContract,
        description: obj.description === null || obj.description === undefined || obj.description === ''?null:obj.description,
        contractAttach: obj.contractAttach === null || obj.contractAttach === undefined?null:obj.contractAttach,
        contractAttachPath:obj.contractAttachPath === null || obj.contractAttachPath === undefined?null:obj.contractAttachPath,
        files:files??[]
    };
    return contract;
};

export const handleCreacteContractFormData = (obj) => {
    const formData = new FormData();
    createFormData(formData, obj);
    return formData;
};

export const handleEditContractFormData = (obj) => {
    const formData = new FormData();
    createFormData(formData, obj);
    return formData;
};

// 
const createFormData = (formData, obj) => {
    function appendIfNotNull(key, value) {
        if (value !== null && value !== undefined) {
            formData.append(key, value);
        }
    }

    if(obj.contractId){
        appendIfNotNull('ContractId', obj.contractId);
    }



    appendIfNotNull('UserId', obj.userId);

    appendIfNotNull('ContractType',obj.contractType);

    if (obj.startContract) {
        const startDate = new Date(obj.startContract).toISOString();
        appendIfNotNull('StartContract', startDate);
    }

    if (obj.endContract) {
        const endDate = new Date(obj.endContract).toISOString();
        appendIfNotNull('EndContract', endDate);
    }

    appendIfNotNull('Description', obj.description);

    if (obj.contractAttach) {
        appendIfNotNull('ContractAttach', obj.contractAttach);
    }



    if(obj.contractId){
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file === null && obj.files[i].contractAttachId === null){

            }
            else
            {
                if(obj.files[i].file !== null && obj.files[i].contractAttachId !== null ){
                    formData.append(`Files[${i}].filePath`,obj.files[i].filePath);
                }
                appendIfNotNull(`Files[${i}].file`,obj.files[i].file);
                appendIfNotNull(`Files[${i}].caption`, obj.files[i]?.caption );
                appendIfNotNull(`Files[${i}].contractAttachId`, obj.files[i].contractAttachId );
            }
        }
    }
    else{
        for (let i = 0; i < obj.files.length; i++) {
            if(obj.files[i].file !== null){
                appendIfNotNull(`Files[${i}].file`,obj.files[i].file)
                appendIfNotNull(`Files[${i}].caption`, obj.files[i]?.caption);
            }
            else{

            }
        }
    }




}