export const convertSelectFormDataHandler = (data)=>{
    const options  = data.map(item => ({label: item.value, value: item.key,fields:item.fields}));
    return options;
}


export const handleAddFilter = (filters,filterItems) => {
    const getValue = (item) => {
        if (item.value && typeof item.value === 'object' && item.value.value !== null && item.value.value !== undefined) {
            return item.value.value;
        }
        return item.value;
    };
    const data = {
        title: filters.title ?? null,
        table: filters.table?.value ?? null,
        filterItems: filterItems.map((item) => ({
            field: (item.foreignKey?.value ? item.field.value + '.' + item.foreignKey.value : item.field.value) ?? null,
            value: getValue(item),
            operation: item.operation?.value ?? null,
            previousOperation: item.previousOperation?.value ?? 0,
            order: item.order,
        })),
    };
    return data;
};

export const handleConvertFilterItems = (requestObject,fields,conditions,previousOperations)=>{
    const filteredItems = requestObject.map(item => ({
        ...item,
        field:fields.find(typeItem => typeItem.value === item.field),
        operation:conditions.find((typeItem)=>typeItem.value === parseInt(item.operation)),
        previousOperation:previousOperations.find(typeItem => typeItem.value === item.previousOperation),
    }));
    return filteredItems;
}
