import React from "react";
import {useTranslation} from "react-i18next";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {InputText} from "primereact/inputtext";
import {Link} from "react-router-dom";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";

export const Emails=({user})=>{
    const { t } = useTranslation();
    const handleCopyToClipboard = async (text) => {
        if (text) {
            try {
                handleSuccessMessage(t("Yourtexthasbeencopied"))
                await navigator.clipboard.writeText(text);
            }
            catch (error) {
                console.log(error)
            }
        }
    };
    return (
        <>
            <div className={"post-input p-2 post-input-design dark:bg-themeDarkPostInput space-y-3"}>
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-2 flex gap-2 items-center"}>
                    <HPrimeIconSmall icon={"pi-envelope"} iconClass={"dark:text-gray-300"} />
                    <p className={"text-xs dark:text-gray-300"}>{t("Email")}</p>
                </div>
                <div className={"max_height10 scrollbar space-y-2 p-2"}>
                    <div className={"flex gap-1 items-center "}>
                        <div className={"min-w-16"}>
                            <p className={"text-xs dark:text-gray-300"}>{t("Email")}</p>
                        </div>
                        <div className={"flex-1"}>
                            <InputText className={"input w-full"} value={user?.email ?? '-'} disabled={true}/>
                        </div>
                        <div className={"flex gap-2 items-center"}>
                            <button onClick={()=>handleCopyToClipboard(user?.email)} className={"button bg-themeSuccess"}>
                                <HPrimeIconSmall
                                    icon={"pi-copy"}
                                    iconClass={"text-white"}
                                />
                            </button>
                            <Link to={"/EmailMarketing/Compose"} className={"button bg-themeInformation dark:bg-themeDarkInformation "}>
                                <HPrimeIconSmall icon={"pi-envelope"} iconClass={"text-white"} />
                            </Link>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}