export const handleGetDocumentGroup=(group)=>{
    const data = {
        title:group.title === null || group.title === undefined?null:group.title,
        description:group.description === null || group.description === undefined?null:group.description,
        order:group.order === null || group.order === undefined?0:group.order
    }
    return data
}

export const GetModifiedDocumentGroup=(group)=>{
    const data = {
        title:group.title === null || group.title === undefined?null:group.title,
        description:group.description === null || group.description === undefined?null:group.description,
        order:group.order === null || group.order === undefined?0:group.order,
        harmonyDocumentGroupId:group.harmonyDocumentGroupId
    }
    return data
}
