import QRCode from "react-qr-code";
import React, {useState} from "react";
import SortableTree from "@nosferatu500/react-sortable-tree";
export const QrCode=()=>{
    const [treeData1, setTreeData1] = useState([
        { title: "node1", children: [{ title: "Child node" }] },
        { title: "node2" },
    ]);

    const [treeData2, setTreeData2] = useState([
        { title: "node3" },
        { title: "node4" },
    ]);
    const [shouldCopyOnOutsideDrop, setShouldCopyOnOutsideDrop] = useState(false);
    const externalNodeType = "yourNodeType";

    return (
        <>
            {console.log(treeData1)}
            <SortableTree
                style={{
                    height: 350,
                    width: 350,
                    float: "left",
                    border: "solid black 1px",
                }}
                treeData={treeData1}
                onChange={(updatedTreeData) => setTreeData1(updatedTreeData)}
                dndType={externalNodeType}
                shouldCopyOnOutsideDrop={shouldCopyOnOutsideDrop}
            />

            <SortableTree
                style={{
                    height: 350,
                    width: 350,
                    float: "left",
                    border: "solid black 1px",
                }}
                treeData={treeData2}
                onChange={(updatedTreeData) => setTreeData2(updatedTreeData)}
                dndType={externalNodeType}
                shouldCopyOnOutsideDrop={shouldCopyOnOutsideDrop}
            />
        </>
    );
}