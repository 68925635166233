import React, {useState} from "react";
export const useGeneralSettings=(setErrors)=>{
    const [setting, setSetting] = useState({
        minimumHostAllowedPurchaseAmount: 0,
        maximumHostAllowedPurchaseAmount: 0,
        incrementStepHostPurchaseAmount: 0,
        minimumMailHostAllowedPurchaseAmount: 0,
        maximumMailHostAllowedPurchaseAmount: 0,
        incrementStepMailHostPurchaseAmount: 0,
        packagePricePercentage: 0,
        webSiteExpirationNotificationTime: 0,
        webSiteExpirationNotificationTimeInterval: 0,
        defaultHostPurchaseAmount:0,
        defaultMailHostPurchaseAmount:0
    });

    const handleSelectValue=(data,name)=>{
        setSetting((prevState)=>({...prevState,[name]:data}));
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue = (value,name)=>{
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    return {
        handleSelectValue,
        handleNumberValue,
        handleValue,
        setSetting,
        setting
    }
}