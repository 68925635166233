import React, { useEffect, useState } from "react";

import "../../../Styles/Internal/Login.css";
import "../../../Styles/dist/css/IR_app.css";

import "../../../Styles/dist/css/output.css";
export const MasterAuth = (props) => {
  const { children } = props;

  return (
    <div className="login dark:bg-themeDarkApp">
      <div className={" main-page"}>
        <div className="relative flex h-screen flex-col justify-center">
          <div className="absolute end-5 top-5 hidden xl:inline-block">
            <img alt="harmony2023" className="-intro-x w-32" src="http://cpanel.harmonycms.ir/ArmDark.svg" />
          </div>
          <div className="grid place-content-center gap-4 xl:grid-cols-2 px-5">
            {children}
          </div>
        </div>
      </div>

    </div>
  );
};
