import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import TelegramIcon from "./SocialIcon/TelegramIcon";
import InstagramIcon from "./SocialIcon/InstagramIcon";
import LinkedInIcon from "./SocialIcon/LinkedInIcon";
import FacebookIcon from "./SocialIcon/FacebookIcon";
import {Tooltip} from "react-tooltip";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";



export const HandleDraft = () => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();



  return(
      <div className="space-y-5">
        <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
          <ListSearch label={null} icon={null} >
            <div class="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("SearchByNameCode")}</label>
              <InputText  type="text"  className=" input w-full" placeholder={t("search")}  />
            </div>
            <div class="space-y-2">
              <label className="text-xs dark:text-gray-300">{t("AccountType")}</label>
              <Select
                  placeholder={t("selection")}
                  classNamePrefix={"h_multi_select"}
                  menuPosition={"fixed"}
              />
            </div>


            <ButtonSearchList
            />
          </ListSearch>
          <ListFilter label={null} icon={null}
                      childrenOne={
                        <>
                          <ButtonPlus
                              title={t("Connect")}
                              onClick={()=>redirectTo("/SocialMarketing/Messengers/List/ConnectAccounts")}
                              tooltipTitle={t("ConnectAccounts")}
                          />

                          <ButtonPlus
                              title={t("Accounts")}
                              icon={"pi-link"}
                              onClick={()=>redirectTo("/SocialMarketing/Messengers/List")}
                              tooltipTitle={t("Accounts")}
                          />



                          <ButtonPlus
                              title={t("list")}
                              icon={"pi-list"}
                              onClick={()=>redirectTo("/SocialMarketing/Messengers/PublicationList")}
                              tooltipTitle={t("PublicationList")}
                          />
                          <ButtonPlus
                              title={t("Publishing")}
                              icon={"pi-share-alt"}
                              onClick={()=>redirectTo("/SocialMarketing/Messengers/PublishingContent")}
                              tooltipTitle={t("PublishingContent")}

                          />
                          <ButtonPlus
                              title={t("comments")}
                              icon={"pi-comments"}
                              onClick={()=>redirectTo("/SocialMarketing/Messengers/Comments")}
                              tooltipTitle={t("comments")}
                              notification={true}
                          />

                          <ButtonPlus
                              title={t("Draft")}
                              icon={"pi-file-edit"}
                              onClick={()=>redirectTo("/SocialMarketing/Draft")}
                              tooltipTitle={t("Draft")}

                          />


                        </>
                      }
                      childrenTwo={
                        <>
                          <div className="space-y-2 text-center">
                            <lable className="text-xs dark:text-gray-300">{t("Instagram")}</lable>
                            <div>
                              <input className="input input--switch border bg-white"  type="checkbox"/>
                            </div>
                          </div>


                        </>
                      }
          />

        </div>

        <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 box bg-white dark:bg-themeDarkSalt700 p-2">
          <div className={"border flex flex-col instagram-hover justify-between post-input dark:bg-themeDarkPostInput shadow messenger_PublicationList overflow-hidden dark:border-themeDarkColorLine"}>
            <div className={"H_aspect_15 bg-white dark:bg-themeDarkSalt700"}>
              <img src="/Assets/Image/Avatar/man.svg" alt="" className={"absolute inset-0 size-full"}/>
              <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                <HPrimeIconSmall icon={"pi-file-edit"}/>
                {t("Text")}
              </div>
              <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                <HPrimeIconSmall icon={"pi-image"}/>
                {t("Photo")}
              </div>
              <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                <HPrimeIconSmall icon={"pi-video"}/>
                {t("Video")}
              </div>
              <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                <i className="pi pi-images"></i>
                {t("Album")}
              </div>
            </div>
            <div className="flex flex-col  gap-5 p-3 ">
              <div class=" flex items-center">
                <h2 className={"text-xs line-clamp-1 dark:text-gray-300"}>لورم ایپسوم متن ساختگی لورم لورم ایپسوم متن ساختگی</h2>
              </div>
              <div className={"space-y-2"}>
                <div className={"flex justify-between items-center"}>
                  <h2 className={"text-xs dark:text-gray-300"}>{t("TimingFor")}</h2>
                  <h2 className={"text-xs dark:text-gray-300 font-light"}>18 تیر 1403 11:15</h2>
                </div>
                <div className={"flex justify-between items-center"}>
                  <h2 className={"text-xs dark:text-gray-300 "}>{t("CreationDate")}</h2>
                  <h2 className={"text-xs dark:text-gray-300 font-light"}>18 تیر 1403 11:15</h2>
                </div>
              </div>
              <div className={"flex flex-wrap gap-2 justify-center"}>
                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                  <p className={"text-themeSecondary flex items-center gap-1"}>
                    <HPrimeIconSmall
                    icon={"pi-file-edit"}
                    />
                    {t("Draft")}
                  </p>
                </div>

              </div>
              <div className={"flex flex-wrap gap-2 justify-center"}>
                <TelegramIcon/>
                <InstagramIcon/>
                <LinkedInIcon/>
                <FacebookIcon/>
              </div>
            </div>
            <div class=" w-full flex items-center p-1 gap-1 bgwhite dark:bg-themeDarkPostInput">
              <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded  zoom-in tooltip_class_edit"}>
                <HPrimeIconSmall
                    icon={"pi-file-edit"}
                />
                <Tooltip anchorSelect={".tooltip_class_edit"} content={t("Edit")} positionStrategy={"fixed"}	 />
              </button>
              <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded zoom-in tooltip_class_again"}>
                <HPrimeIconSmall
                    icon={"pi-arrow-right-arrow-left"}
                />
                <Tooltip anchorSelect={".tooltip_class_again"} content={t("Resend")} positionStrategy={"fixed"}	 />
              </button>

              <button className={"flex-1 button grid place-content-center bg-themeInformation dark:bg-themeDarkInformation text-white rounded zoom-in tooltip_class_delete"}>
                <HPrimeIconSmall
                    icon={"pi-trash"}
                />
                <Tooltip anchorSelect={".tooltip_class_delete"} content={t("delete")} positionStrategy={"fixed"}	 />
              </button>

            </div>
          </div>
        </div>

      </div>
  )
}