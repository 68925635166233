import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSetting} from "../../../../Commans/Hooks/useSetting";
import {handleWarningMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {useTab} from "./Tabs/useTab";
import {useGroupDetail} from "./useGroupDetail";
import queryString from "query-string";
import {GET, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {Return} from "../../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import ButtonVideo from "../../../../Commans/UIParts/ButtonVideo";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {InputText} from "primereact/inputtext";
import {TabMenu} from "../../../../Commans/UIParts/TabMenu";
import {InputTextarea} from "primereact/inputtextarea";
import {SettingButton} from "../../../../Commans/UIParts/SettingButton";
import {TinyEditor} from "../../../../Commans/UIParts/TinyEditor";
import {TagsInput} from "react-tag-input-component";
import {UploadImage} from "../../../../Commans/Uploaders/UploadImage";
import {InputSwitch} from "../../../../Commans/Filters/InputSwitch";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {ToastContainer} from "react-toastify";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {HandleGeneralSetting} from "../../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {DisplayError} from "../../../../Commans/Errors/DisplayError";
import {Settings} from "./Tabs/Settings";
import {Content} from "./Tabs/Content";
import {validate} from "../../../../../Services/Globals/validate";
import {productGroupDetailSchema} from "./productGroupDetailSchema";
import {handleCreateProductDetail} from "./productGroupDetailService";
import {RequiredIcon} from "../../../../Commans/Errors/RequiredIcon";

export const HandleProductGroupDetail=()=>{

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const queryParams  = queryString.parse(window.location.search);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {tabs,handleChangeTabId,tabId}=useTab();
    const {handleChangeKeywords,handleSelectValue,handleChangeAttach,handleResetAttach,handleValue,productGroupDetail,handleRemoveAttach,setProductGroupDetail}=useGroupDetail(setErrors)
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const onSetErrors=(error)=>{
        setErrors(error);
    }

    const fetchData = async()=>{
        try {
            showSpinner();
            const response = await GET(`/ProductGroups/${queryParams.productGroupId}`,{},'single');
            if(response.status === 200){
                setProductGroupDetail({...productGroupDetail,
                    seoDescription: response.data.SeoDescription,
                    ProductGroupId: response.data.productGroupId,
                    title:response.data.title,
                    slug:response.data.slug,
                    excerpt:response.data.excerpt,
                    editor:response.data.editor,
                    seoTitle: response.data.seoTitle,
                    seoKeywords: response.data.seoKeywords??[],
                    image: response.data.image,
                    banner:response.data.banner,
                    icon:response.data.icon,
                    stateShow: response.data.stateShow,
                    order:response.data.order,
                    type:response.data.type,
                    parentId: response.data.parentId,
                    stateGoogle: response.data.stateGoogle??false
                })
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner()
        }
    }
    useEffect(()=>{
        fetchData();
    },[])



    const handleSubmitGroupDetail=async(e)=>{
        e.preventDefault();
        const isValid = validate(productGroupDetailSchema,productGroupDetail,onSetErrors);
        if(!isValid){
            return;
        }
        try {
           showSpinner();
           const formData = handleCreateProductDetail(productGroupDetail);
           const response = await PUT(`/ProductGroups/Details`,formData,true);
           if(response.status === 200){
               hideSpinner();
               redirectTo('/Shop/Product/Group');
           }
           else{
               handleApiError(response,hideSpinner);
           }
        }
        catch (e) {
            hideSpinner();
        }
    }




    return (
        <>
            <div className={"space-y-5"}>
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/Content/Add`)}
                                            tooltipTitle={t("InsertingInformation")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Add")}
                                            icon="pi-pen-to-square"
                                            title={t("product")}
                                            tooltipTitle={t("NewProduct")}
                                        />


                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/MetaData/Add")}
                                            icon="pi-star"
                                            title={t("feature")}
                                            tooltipTitle={t("CreatingAFeature")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Group")}
                                            icon="pi-objects-column"
                                            title={t("grouping")}
                                            tooltipTitle={t("ProductCategorization")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/List")}
                                            icon="pi-list"
                                            title={t("list")}
                                            tooltipTitle={t("ListOfProducts")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/InvoiceRequest")}
                                            icon="pi-file-edit"
                                            title={t("Bill")}
                                            tooltipTitle={t("Bills")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Cart")}
                                            icon="pi-shopping-bag"
                                            title={t("ShoppingCart")}
                                            tooltipTitle={t("ShoppingCart")}

                                        />

                                    </>
                                }

                    />

                </div>
                <form  onSubmit={handleSubmitGroupDetail} method={'post'} className="relative">
                    {

                        IsShowSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className={"space-y-5 "}>
                        <div class="grid grid-cols-12 gap-5">
                            <div class="col-span-12 lg:col-span-8">
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 items-center ">
                                    <div>
                                        <div className={"float-input p-float-label relative"}>
                                            <InputText name={'title'} value={productGroupDetail.title} onInput={(e)=>handleValue(e)} type="text"  className="input w-full" />
                                            <label className={"text-xs dark:text-gray-300"}>
                                                {t("WebsiteTitle")}
                                                <RequiredIcon/>
                                            </label>
                                            <div className="absolute end-2 -top-2">
                                                <DisplayError message={errors.title}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"float-input p-float-label relative"}>
                                        <InputText  name={'slug'} value={productGroupDetail.slug} onInput={(e)=>handleValue(e)} type="text"  className="input w-full" />
                                        <label className={"text-xs dark:text-gray-300"}>
                                            {t("ArticleAddress")}
                                            <RequiredIcon/>
                                        </label>
                                        <div className="absolute end-2 -top-2">
                                            <DisplayError message={errors.slug}/>
                                        </div>
                                    </div>
                                </div>

                                <div class="post  mt-5 ">
                                    <TabMenu activeTab={tabId} handleTabClick={handleChangeTabId} tabs={tabs} />
                                    <div class="post__content tab-content box bg-white dark:bg-themeDarkSalt700 p-2">
                                        <div className={`tab-content__pane space-y-5 ${ tabId === "content" ? "active" : "" }`} id="content">
                                            <Content

                                                handleValue={handleValue}
                                                settingId={'GeneralSetting'}
                                                handleSetting={handleSetting}
                                                handleSelectValue={handleSelectValue}
                                                productGroupDetail={productGroupDetail}
                                            />
                                        </div>

                                        <div className={`tab-content__pane space-y-5 ${ tabId === "settings" ? "active" : ""}`} id="settings">
                                            <Settings
                                                handleValue={handleValue}
                                                handleChangeKeywords={handleChangeKeywords}
                                                productGroupDetail={productGroupDetail}
                                            />

                                        </div>


                                    </div>
                                </div>

                            </div>
                            <div class="col-span-12 lg:col-span-4">
                                <div class="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                                    <div class="space-y-1 post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                            <SettingButton
                                                onClick={()=>handleSetting('GeneralSetting')}
                                                tooltipTitle={t("WatermarkText")}
                                            />
                                            {t("Image")}</label>
                                        <div class="h-36 w-full ">
                                            <UploadImage isEdit={true} removeFile={handleRemoveAttach} removeName={'imagePath'} accept={'image/*'} resetImages={handleResetAttach} OnValueSelection={handleChangeAttach} value={productGroupDetail.image ? productGroupDetail.image:null} name={'image'} />
                                        </div>
                                    </div>
                                    <div class="space-y-1 post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                            <SettingButton
                                                onClick={()=>handleSetting('GeneralSetting')}
                                                tooltipTitle={t("WatermarkText")}
                                            />
                                            {t("Banner")}</label>
                                        <div class="h-36 w-full ">
                                            <UploadImage isEdit={true} removeFile={handleRemoveAttach} removeName={'bannerPath'} accept={'image/*'} resetImages={handleResetAttach} OnValueSelection={handleChangeAttach} value={productGroupDetail.banner ? productGroupDetail.banner:null} name={'banner'} />
                                        </div>
                                    </div>
                                    <div class="space-y-1 post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                            <SettingButton
                                                onClick={()=>handleSetting('GeneralSetting')}
                                                tooltipTitle={t("WatermarkText")}
                                            />
                                            {t("Icon")}</label>
                                        <div class="h-36 w-full">
                                            <UploadImage isEdit={true} removeFile={handleRemoveAttach} removeName={'iconPath'} accept={'image/*'} resetImages={handleResetAttach} OnValueSelection={handleChangeAttach} value={productGroupDetail.icon ? productGroupDetail.icon:null} name={'icon'} />

                                        </div>
                                    </div>
                                    <div class="flex items-center justify-around post-input dark:bg-themeDarkPostInput p-3 post-input-design">
                                        <div>
                                            <InputSwitch value={productGroupDetail.stateShow} OnValueSelection={handleSelectValue} lable={t("DisplayStatus")} name={'stateShow'}/>
                                        </div>
                                        <div>
                                            <InputSwitch value={productGroupDetail.stateGoogle} OnValueSelection={handleSelectValue} lable={t("stateGoogle")} name={'stateGoogle'}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ButtonSubmitAndCancel
                            onCancelClick={()=>redirectTo('/Shop/Product/Group')}
                        />
                    </div>

                </form>
            </div>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
            <ToastContainer/>
        </>

    )


}