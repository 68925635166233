import React, {useEffect, useState} from "react";

import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import {useNavigate} from "react-router-dom";

import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";

import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {ToastContainer} from "react-toastify";

import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import Select from "react-select";
import {useUserLocations} from "./useUserLocations";
import {GET, POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {
    handleCreateUserAddress,
    validateAddress,
    validateCity,
    validateCountry,
    validateStateProvince
} from "./userAddressService";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const HandleUserAddress = () => {

    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [initialSource,setInitialSource]=useState({countries:[]})
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const queryParams = queryString.parse(window.location.search);
    const [concurrencyStamp,setConcurrencyStamp]=useState(null);
    const [refresh,setRefresh]=useState(false);
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }

    const handleInitialDataSource=(responseCountry)=>{
        const countries = convertSelectDataHandler(responseCountry)
        setInitialSource({
            countries: countries,
        })
        return {
            countries,
        }
    }


    const {locationList, setCountries,countries,setLocationList,handleRemoveLocation,handleAddLocation,handleCountryChange, handleStateChange,handleCityChange,handleGetSubmittedLocations,handleChangeField}=useUserLocations(showSpinner,hideSpinner);



    const fetchData = async () => {
        try {
            showSpinner();

            // First, fetch the countries
            const responseCountry = await GET('/Countries/Selection', {}, 'IEnumerable');
            if (responseCountry.status !== 200) {
                handleApiError(responseCountry, hideSpinner);
                return;
            }

            const {
                countries,
            } = handleInitialDataSource(responseCountry.data,);
            const response = await GET(`/Users/${queryParams.userId}/Addresses`,{},'single');
            if(response.status === 200){

                if(response.data.userAddresses.length === 0){
                    const countriesData = convertSelectDataHandler(responseCountry.data);
                    setCountries(countriesData);
                    setConcurrencyStamp(response.data.concurrencyStamp)
                }
                else{
                    await handleGetSubmittedLocations(response.data.userAddresses,countries);
                    setConcurrencyStamp(response.data.concurrencyStamp);
                }
            }
            else{
                handleApiError(response, hideSpinner);
            }

            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(()=>{
        fetchData();
    },[refresh])



    const handleSubmitUserAddress=async()=>{

        try {
            showSpinner();
            const IsContainsCountryId = validateCountry(locationList);
            if(!IsContainsCountryId){
                handleFailureMessage(t("SelectTheCountry"));
                hideSpinner();
                return
            }
            const isContainsStateProvinceId = validateStateProvince(locationList);
            if(!isContainsStateProvinceId){
                handleFailureMessage(t("SelectTheProvince"));
                hideSpinner();
                return
            }
            const isContainsCity = validateCity(locationList);
            if(!isContainsCity){
                handleFailureMessage(t("SelectTheCity"));
                hideSpinner();
                return
            }
            const isContainsAddress = validateAddress(locationList);
            if(!isContainsAddress){
                handleFailureMessage(t("EnterTheAddress"));
                hideSpinner();
                return
            }
            const userAddressesData = handleCreateUserAddress(locationList,queryParams.userId,concurrencyStamp);
            const response = await POST(`/Users/Addresses`,userAddressesData,false);
            if(response.status === 200){
                hideSpinner();
                Refresh();
                handleSuccessMessage(t('SuccessDone'));
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }









    return (
        <>
            <div className="space-y-5 relative">
                {
                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className={"box bg-white dark:bg-themeDarkSalt700 p-3 relative"}>
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => redirectTo(`/CRM/User/ListAdmin/Website/List`)}
                                            icon={"pi-list"}
                                            tooltipTitle={t("ViewList")}
                                            title={t("list")}
                                        />
                                    </>
                                }

                    />
                </div>

                <div className="bg-white dark:bg-themeDarkSalt700 p-2 box space-y-3">
                    <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                        <h2 className={"dark:text-gray-300"}>{t("Addresses")}</h2>
                    </div>

                    <div className="space-y-3 px-2">

                        {locationList.map((location, index) => (
                                <div key={index} className="p-2 post-input post-input-design dark:bg-themeDarkPostInput grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 items-end">
                                    <div className={"flex gap-1 items-center"}>
                                        <div className="number_pluse bg-themeInformation text-white text-center dark:bg-themeDarkInformation mt-auto">
                                            {index + 1}
                                        </div>
                                        <div className={"flex-1 space-y-1"}>
                                            <label className="text-xs dark:text-gray-300">{t("country")}</label>
                                            <Select
                                                options={initialSource.countries}
                                                isClearable={false}
                                                isSearchable={true}
                                                name={'countryId'}
                                                placeholder={t("selection")}
                                                menuPosition={'fixed'}
                                                className="w-full"
                                                classNamePrefix={"h_multi_select"}
                                                value={location.countryId}
                                                onChange={(data) => handleCountryChange(data, index)}
                                            />
                                        </div>
                                    </div>
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("province")}</label>
                                        <Select
                                            options={location.states}
                                            isClearable={false}
                                            isSearchable={true}
                                            name={'stateProvinceId'}
                                            placeholder={t("selection")}
                                            menuPosition={'fixed'}
                                            className="w-full"
                                            classNamePrefix={"h_multi_select"}
                                            value={location.stateProvinceId}
                                            onChange={(data) => handleStateChange(data, index)}
                                            isDisabled={!location.countryId}
                                        />
                                    </div>
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("city")}</label>
                                        <Select
                                            options={location.cities}
                                            isClearable={false}
                                            isSearchable={true}
                                            name={'cityId'}
                                            placeholder={t("selection")}
                                            menuPosition={'fixed'}
                                            className="w-full"
                                            classNamePrefix={"h_multi_select"}
                                            value={location.cityId}
                                            onChange={(data) => handleCityChange(data, index)}
                                        />
                                    </div>
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("postalCode")}</label>
                                        <InputText pattern="\d*" inputMode="numeric" className="input w-full" value={location.postalCode}
                                                   onChange={(e) => {
                                                       const value = e.target.value.replace(/\D/g, ''); // Removes non-digit characters
                                                       handleChangeField('postalCode', value, index);
                                                   }}
                                        />
                                    </div>
                                    <div className={"flex gap-2 items-center md:col-span-2 lg:col-span-4 flex-col md:flex-row"}>
                                        <div className={"flex-1 w-full"}>
                                            <InputTextarea className="input" rows={2} placeholder={t("Address")} value={location.address} onChange={(e) => handleChangeField('address', e.target.value, index)} />
                                        </div>
                                        <div className={"flex-1 w-full"}>
                                            <InputTextarea className="input" rows={2} placeholder={t("description")} value={location.detailsOfAddress} onChange={(e) => handleChangeField('detailsOfAddress', e.target.value, index)} />
                                        </div>
                                        <div className="flex items-end gap-3 justify-end flex-shrink-0">
                                            <button type="button" onClick={handleAddLocation} className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white">
                                                <HPrimeIcon
                                                icon={"pi-plus"}
                                                />
                                            </button>
                                            <button type="button" onClick={() => handleRemoveLocation(index)} className="button small_button bg-themeDanger text-white">
                                                <HPrimeIcon icon={"pi-minus"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}

                    </div>
                </div>
                <ButtonSubmitAndCancel
                  onRegisterClick={()=>handleSubmitUserAddress()}
                  onCancelClick={()=>redirectTo('/CRM/User/List')}
                />
            </div>

            <ToastContainer/>
        </>

    )
};