import React from "react";


    export const handleGetSlugData=(slugData,siteMapFrequencies)=>{
        const data = slugData.map((item) => ({
            priority: 0.8,
            slugType: item.value,
            siteMapFrequency: siteMapFrequencies[0],
            siteMapItemId: null,
            isChecked: item.value === 2 || item.value === 6?false:true,
            label : item.label
        }));

        return data;
    }


export const handleConvertAddSiteMap = (siteMap) => {
    const data = {
        name: siteMap.name === null || siteMap.name === undefined ? null : siteMap.name,
        isDefault: siteMap.isDefault,
        siteMapType: siteMap.siteMapType,
        slugFilterType: siteMap.slugFilterType,
        items: siteMap.items
            .filter(item => item.isChecked)  // Filter items where isChecked is true
            .map((item) => ({
                siteMapItemId: item.siteMapItemId,
                slugType: item.slugType,
                siteMapFrequency: item.siteMapFrequency.value,
                priority: item.priority
            })),
        scheduleFrequency: siteMap.scheduleFrequency === null || siteMap.scheduleFrequency === undefined ? null : siteMap.scheduleFrequency.value
    };

    return data;
}

export const handleConvertEditSiteMap = (siteMap) => {
    const data = {
        siteMapId:siteMap.siteMapId,
        name: siteMap.name === null || siteMap.name === undefined ? null : siteMap.name,
        isDefault: siteMap.isDefault,
        siteMapType: siteMap.siteMapType,
        slugFilterType: siteMap.slugFilterType,
        items: siteMap.items
            .filter(item => item.isChecked)
            .map((item) => ({
                siteMapItemId: item.siteMapItemId,
                slugType: item.slugType,
                siteMapFrequency: item.siteMapFrequency.value,
                priority: item.priority
            })),
        scheduleFrequency: siteMap.scheduleFrequency === null || siteMap.scheduleFrequency === undefined ? null : siteMap.scheduleFrequency.value
    };
    return data;
}

