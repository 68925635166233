
import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {useLocation, useNavigate} from "react-router-dom";
import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import queryString from "query-string";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {bankSchema} from "./bankSchema";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {useTranslation} from "react-i18next";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {Tooltip} from "react-tooltip";
import {convertBankDataHandler} from "./bankService";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const HandleDataBank = (props)=>{

    const redirectTo = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [butLoading,setBtnLoading] = useState(false);
    const[result,setResult] = useState(0)
    const [errors, setErrors] = useState({});
    const [isChanged,setChanged] = useState(false);
    const [selectOptions,setSelectOptions] = useState({Banks:[]})
    const[spinner,setSpinner] = useState(false);
    const [stamp,SetStamp] = useState(null);
    const[isDefault,setIsDefault] = useState(false);
    const [bankList,setBankList] = useState([{ id:new Date().getDate(), bankName: null, accountNumber: null, cardNumber: null, shebaNumber: null,isDefault:false }]);
    const [bank,setBank] = useState({name:null,description:null,isDefault:false});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setBank({...bank,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleDefaultBank=(data,name)=>{
        setBank(({...bank,[name]: data}));
    }

    const onApiError = () => {
        setSpinner(false);
    };
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }



    const [openModal,setOpenModal] = useState(false);
    const handleOpenModal=()=>{
        setOpenModal(true);
    }
    const closeModal = ()=>{
        setOpenModal(!openModal);
        setErrors({})
    }



    const handleAddRow = () => {
        const newRow = {
            id: new Date().getTime(),
            userBankId: null, // Set bankId to null initially
            bankName:selectOptions.Banks[0],
            accountNumber: null,
            cardNumber: null,
            shebaNumber: null,
            isDefault: bankList.length === 0,
        };
        setIsDefault(false);
        setBankList((prevList) => [...prevList, newRow]);
    };

    const handleRemoveRow = (id, userBankId) => {
        if (bankList.length === 1) {
            const newRow = {
                id: new Date().getTime(),
                userBankId: null, // Set bankId to null initially
                bankName:selectOptions.Banks[0],
                accountNumber: null,
                cardNumber: null,
                shebaNumber: null,
                isDefault: bankList.length === 0,
            };
            setIsDefault(true);
            setBankList((prevList) => [newRow]);
            return;
        }
        setBankList((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.userBankId === userBankId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
        setChanged(true); // Set changed to true when a row is removed
    };


    const handleChange = (id, field, value, userBankId) => {
        setBankList((prevList) => {
            const updatedList = prevList.map((row) => {
                if ((userBankId && row.userBankId === userBankId) || (!userBankId && id && row.id === id)) {
                    // If the current row is being updated
                    if (field === 'isDefault' && value === true) {
                        // Set isDefault to true only for the current row and false for others
                        return { ...row, [field]: true };
                    } else {
                        return { ...row, [field]: value };
                    }
                }
                // If it's not the current row, reset isDefault to false
                //return { ...row, isDefault: false };
                return { ...row };
            });

            return updatedList;
        });
        setChanged(true);
        setIsDefault(false);
    };






    const convertBankHandler = (requestObject,requiredObject)=>{
        const filteredMeta = requestObject.map(item => ({
            ...item,
            bankName: requiredObject.find(typeItem => typeItem.value === item.bankId)
        }));
        return filteredMeta;
    }

    const handleFetchData = async () => {
        try {
            setSpinner(true);
            const responseBank = await GET(`/Banks`,{},'IEnumerable');
            if (responseBank.status === 200) {
                const Banks = convertSelectDataHandler(responseBank.data);
                setSelectOptions((prevOptions) => ({ ...prevOptions, Banks: Banks }));
                setSpinner(false);
                const queryParams = queryString.parse(window.location.search);
                try {
                    if (queryParams.id) {
                        const response = await GET(`/Users/${queryParams.id}/Banks`,{},'single');
                        if (response.status === 200) {
                            SetStamp(response.data.concurrencyStamp);
                            const filteredData = convertBankHandler(response.data.userBanks,Banks);
                            setBankList(filteredData.map((row) => ({ ...row, userBankId:row.userBankId })));
                            // setSpinner(false);
                            if (bankList.length === 0) {
                                handleAddRow();
                            }
                        } else {
                            handleApiError(response,onApiError);
                        }
                    }
                }catch (e) {
                    console.log(e);
                }
            } else {
                handleApiError(responseBank,onApiError);
            }
        } catch (error) {
           console.log(error)
        }
    };

    useEffect(()=>{
        handleFetchData();
    },[result])

   useEffect(()=>{
       if (bankList.length === 0) {
           handleAddRow();
       }
   },[bankList])

const handleSubmitBank= async (e)=>{
   e.preventDefault();
   var isValid = validate(bankSchema,bank,onSetErrors);
   if(!isValid){return;}
  try {
      setBtnLoading(true);
      const {name,description,isDefault} = bank;
      const response = await POST(`/Banks`,{name,description,isDefault},false);
      if(response.status === 200){
          closeModal();
          setBtnLoading(false)
          setResult({...response.status});
          setBank({name: '',isDefault: false,description: ''});
          handleSuccessMessage(t("Banknamehasbeensuccessfullyregistered"));
      }else{
          handleApiError(response,onSetErrors);
      }
  }catch (e) {
      console.log(e)
  }

}

    const BankDataSubmitHandler = async (type,nextTab)=>{

        if(isDefault){
            try {
                setSpinner(true);
                const queryParams = queryString.parse(window.location.search);
                const convertedData = convertBankDataHandler([],queryParams.id || null,stamp);
                const response = await POST(`/Users/${queryParams.id}/Banks`,convertedData,false);
                if(response.status === 200){
                    setSpinner(false);
                    setResult({...response.status});
                    handleSuccessMessage(t("Informationhassuccessfully"));
                    if(type === 'register'){
                        if(location.pathname === '/CRM/User/Add'){
                            redirectTo('/CRM/User/List');
                        }
                    }
                    if(type === 'previous'){
                        props.handleTabClick(nextTab);
                        return;
                    }
                    if(type === 'next'){
                        props.handleTabClick(nextTab);
                        return;
                    }
                    //props.handleTabClick('communication');

                }else{
                    handleApiError(response,onSetErrors)
                }
            }catch (e) {
                console.log(e)
            }
        }
        else{
            const nonNullRows = bankList.filter((row) => row.shebaNumber && row.cardNumber && row.accountNumber && row.bankName);
            const defaultRows = bankList.filter((item) => item.isDefault === true);
            const defaultBanks = bankList.filter((item)=>item.bankName === null);
            if(defaultBanks.length !== 0){
                handleFailureMessage(t("Choosethebankname"));
                return;
            }
            if (defaultRows.length !== 1) {
                handleFailureMessage(t("Selectdefaultbank"));
                return;
            }
            if(isChanged === true && nonNullRows.length > 0){
                try {
                    setSpinner(true);
                    const queryParams = queryString.parse(window.location.search);
                    const convertedData = convertBankDataHandler(nonNullRows,queryParams.id || null,stamp);
                    const response = await POST(`/Users/${queryParams.id}/Banks`,convertedData,false);
                    if(response.status === 200){
                        setSpinner(false);
                        setResult({...response.status});
                        handleSuccessMessage(t("Informationhassuccessfully"));
                        if(type === 'register'){
                            if(location.pathname === '/CRM/User/Add'){
                                redirectTo('/CRM/User/List');
                            }
                        }
                        if(type === 'previous'){
                            props.handleTabClick(nextTab);
                            return;
                        }
                        if(type === 'next'){
                            props.handleTabClick(nextTab);
                            return;
                        }
                        //props.handleTabClick('communication');

                    }else{
                        handleApiError(response,onSetErrors)
                    }
                }catch (e) {
                    console.log(e)
                }
            }
            else{
                if(type === 'register'){
                    redirectTo('/CRM/User/List');
                    return;
                }
                if(type === 'previous'){
                    props.handleTabClick(nextTab);
                    return;
                }
                if(type === 'next'){
                    props.handleTabClick(nextTab);
                    return;
                }
            }
            props.handleTabClick(nextTab);
        }

    }


    return (
        <>
                <div className="relative">
                    {
                        spinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className="space-y-3">
                        <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation ">
                            <h2 className={"dark:text-gray-300"}>
                                {t("ListOfBanks")}
                            </h2>

                        </div>
                        <div className="px-2 space-y-5 max_height85vh">
                            {bankList.map((row,index) => (
                                <div key={row.id} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 items-end gap-3 post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                                    <div className={"flex gap-2 "}>
                                        <div className={"mt-auto"}>
                                            <div className="number_pluse bg-themeInformation text-white dark:bg-themeDarkInformation">
                                                {index+1}
                                            </div>
                                        </div>
                                        <div className={"flex-1 space-y-1"}>
                                            <div className={"flex justify-between items-center gap-2"}>
                                                <label className="text-xs dark:text-gray-300">{t("BankName")}</label>
                                                <button type="button" onClick={()=>handleOpenModal()} className="text-themeInformation dark:text-themeDarkInformation text-xs tooltip_class_Add_Bank">
                                                    ({t("Create")})
                                                    <Tooltip anchorSelect={".tooltip_class_Add_Bank"} content={t("AddNewBanktoList")} />
                                                </button>
                                            </div>
                                            <Select
                                                options={selectOptions.Banks}
                                                isMulti={false}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(data) => handleChange(row.id, 'bankName',data,row.userBankId)}
                                                placeholder={t("selection")}
                                                value={row.bankName}
                                                menuPosition={"fixed"}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("BankAccountNumber")}</label>
                                        <input
                                            type="text"
                                            className="input w-full"
                                            value={row.accountNumber}
                                            onChange={(e) => handleChange(row.id, 'accountNumber', e.target.value,row.userBankId)}
                                        />
                                    </div>
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("cardNumber")}</label>
                                        <input
                                            type="text"
                                            className="input w-full"
                                            value={row.cardNumber}
                                            onChange={(e) => handleChange(row.id, 'cardNumber', e.target.value,row.userBankId)}
                                        />
                                    </div>
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("BankID")}</label>
                                        <input
                                            type="text"
                                            className="input w-full"
                                            value={row.shebaNumber}
                                            onChange={(e) => handleChange(row.id, 'shebaNumber', e.target.value,row.userBankId)}
                                        />
                                    </div>
                                    <div className={"md:col-span-2 lg:col-span-1 flex gap-2 items-end justify-between"}>
                                        <div className={"space-y-1"}>
                                            <div className=" gap-2 flex flex-col items-center">
                                                <lable className="text-xs dark:text-gray-300">{t("default")}</lable>
                                                <input checked={row.isDefault} className="input input--switch " onChange={(e)=>handleChange(row.id,'isDefault',e.target.checked,row.userBankId)} type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className="flex gap-2 items-center">
                                            <button type="button" className="button bg-themeInformation dark:bg-themeDarkInformation text-white small_button " onClick={handleAddRow}>
                                                <HPrimeIcon icon={'pi-plus'}  />
                                            </button>
                                            <button type="button" className="button bg-themeDanger text-white small_button " onClick={() => handleRemoveRow(row.id,row.userBankId)}>
                                                <HPrimeIcon icon={'pi-minus'}  />
                                            </button>

                                            {/*{row.id === bankList.length && (*/}
                                            {/*    */}
                                            {/*)}*/}

                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className=" flex flex-wrap items-center justify-center lg:justify-end gap-2">
                    <button onClick={() =>BankDataSubmitHandler('previous',"company")}
                            className="button w-24 text-white bg-themeDanger" >
                        {t("previous")}
                    </button>
                    <button onClick={()=>BankDataSubmitHandler('register')}
                            className="button w-24 text-white bg-themeSuccess">
                        {t("register")}
                    </button>
                    <button onClick={()=>BankDataSubmitHandler('next',"communication")}
                            className="button  text-white bg-themeInformation dark:bg-themeDarkInformation">
                        {t("registerAndNext")}
                    </button>
                </div>


                <FantasyModal openModal={openModal} closeModal={closeModal} title={t("BankRegistration")}>
                <form method="post" onSubmit={handleSubmitBank} className="relative">
                    <div className="space-y-3">
                        <div className="space-y-1">
                            <div className={"flex items-center gap-2"}>
                                <label className="dark:text-gray-300 text-xs">{t("BankName")}</label>
                                <DisplayError message={errors.name}/>
                            </div>

                            <InputText onInput={(e) => handleValue(e)}
                                       value={bank.name}
                                       type="text"
                                       className="input w-full"
                                       name={'name'}
                                       placeholder={t("BankName")}
                            />
                        </div>

                        <div className="space-y-1">
                            <label className="dark:text-gray-300 text-xs">{t("additionalDetails")}</label>
                            <input onInput={(e) => handleValue(e)}
                                       value={bank.description}
                                       type="text"
                                       className="input w-full"
                                       name={'description'}
                                       placeholder={t("additionalDetails")}
                            />
                        </div>
                        <div className="space-y-1">
                            <InputSwitch value={bank.isDefault} OnValueSelection={handleDefaultBank} name={'isDefault'} lable={t("default")} />
                        </div>

                        <div>
                            <button type="submit" className="button bg-themeInformation dark:bg-themeDarkInformation w-full text-white relative">
                                {t("register")}
                                {butLoading ? (
                                    <TinyLoading  />
                                ) : (
                                    null
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </FantasyModal>
        </>
    )
}