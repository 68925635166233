import React, {useEffect, useState} from "react";
import "../../Styles/dist/css/QuickInstall.css";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {UploadImage} from "../Commans/Uploaders/UploadImage";
import {HPrimeIconSmall} from "../Commans/UIParts/HPrimeIconSmall";
import {Link} from "react-router-dom";
import {RequiredIcon} from "../Commans/Errors/RequiredIcon";
import ArrowLeftIcon from "../HIcon/ArrowLeftIcon";
import {InputSwitch} from "../Commans/Filters/InputSwitch";


export const HandleQuickSetup = () => {
    const {t} = useTranslation();
    const [showIntro, setShowIntro] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [introFade, setIntroFade] = useState('fade-in');
    const [loginFade, setLoginFade] = useState('');

    const [currentDiv, setCurrentDiv] = useState(1); // شروع از div1

    const handleNext = () => {
        if (currentDiv < 9) {
            setCurrentDiv(currentDiv + 1); // به div بعدی برو
        }
    };

    const handlePrevious = () => {
        if (currentDiv > 1) {
            setCurrentDiv(currentDiv - 1); // به div قبلی برگرد
        }
    };

    const [selectedItem, setSelectedItem] = useState(null);

    const handleChangeMulti = (surveyItemId) => {
        setSelectedItem(surveyItemId); // ذخیره آیتم انتخاب شده
    };



    // پس از 8 ثانیه، انیمیشن fade out برای کامپوننت معرفی و fade in برای کامپوننت ورود اجرا می‌شود
    useEffect(() => {
        const timer = setTimeout(() => {
            setIntroFade('fade-out'); // شروع fade out برای کامپوننت معرفی
            setLoginFade('fade-in'); // شروع fade in برای کامپوننت ورود
        }, 11000);

        const showLoginTimer = setTimeout(() => {
            setShowIntro(false); // مخفی کردن کامپوننت معرفی پس از انیمیشن
            setShowLogin(true);  // نمایش کامپوننت ورود پس از انیمیشن
        }, 12000); // یک ثانیه تأخیر برای اتمام انیمیشن

        return () => {
            clearTimeout(timer);
            clearTimeout(showLoginTimer);
        };
    }, []);



    return (
        <>
            {showIntro && (
                <div className={`harmony-intro ${introFade}`}>
                    <div className="intro-fill">
                        <span className="harmony-welcome-1 title">Enjoyable</span>
                        <span className="harmony-welcome-2 title">Smart</span>
                        <span className="harmony-welcome-3 title">Integrated Business Management</span>
                    </div>
                </div>
            )}


            {/* نمایش کامپوننت ورود */}
            {showLogin && (
                <div className={"quick_setup"}>
                    <div className={`main-page min-h-screen flex items-center justify-center ${loginFade}`}>
                        <div className="absolute end-5 top-5">
                            <img alt="harmony2023" className=" w-48" src="http://cpanel.harmonycms.ir/ArmDark.svg" />
                        </div>
                        <div className="quick_setup_width overflow-hidden post-input border border-gray-300 rounded-lg shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)]">
                            {currentDiv === 1 && (
                                <>
                                    <div className="flex flex-col gap-y-5 justify-between p-5">
                                        <div>
                                            <div class="flex items-center justify-between gap-3 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    1
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        اطلاعات کسب وکار
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"max-w-fit mx-auto"}>
                                            <img src="/Assets/Image/bg-img/company.png" alt="" className={"size-full"}/>
                                        </div>
                                        <div>
                                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3">
                                                <div className="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("CompanyType")}</label>
                                                    <Select

                                                        isClearable={true}
                                                        isSearchable={true}

                                                        name={'type'}
                                                        placeholder={t("selection")}

                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                </div>
                                                <div className="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("companyName")}</label>
                                                    <InputText className="input w-full"  />
                                                </div>
                                                <div className="space-y-2">
                                                    <div className="flex justify-between">
                                                        <label className="text-xs dark:text-gray-300">{t("companyNationalIdNumber")}</label>
                                                        {/*<DisplayError message={errors.nationalCode}/>*/}
                                                    </div>
                                                    <InputText name={'nationalCode'} className="input w-full"   />
                                                </div>
                                                <div className="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("BrandName")}</label>
                                                    <InputText className="input w-full"  />
                                                </div>

                                                <div className="space-y-1">
                                                    <label className="text-xs dark:text-gray-300">{t("LandlinePhone")}</label>
                                                    <InputText type={'text'} className="input w-full" />
                                                </div>
                                                <div className="space-y-1">
                                                    <label className="dark:text-gray-300 text-xs">{t("country")}</label>
                                                    <Select

                                                        isClearable={true}
                                                        isSearchable={true}

                                                        placeholder={t("selection")}

                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                </div>
                                                <div className="space-y-1">
                                                    <label className="dark:text-gray-300 text-xs">{t("province")}</label>
                                                    <Select

                                                        isClearable={true}
                                                        isSearchable={true}

                                                        name={'Provinces'}
                                                        placeholder={t("selection")}

                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                </div>
                                                <div className="space-y-1">
                                                    <label className="dark:text-gray-300 text-xs">{t("تعداد پرسنل")}</label>
                                                    <Select

                                                        isClearable={true}
                                                        isSearchable={true}

                                                        name={'CityId'}
                                                        placeholder={t("selection")}

                                                        classNamePrefix={"h_multi_select"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"flex justify-between items-center px-5 "}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )}

                            {currentDiv === 2 && (
                                <>
                                    <div className="flex flex-col gap-y-10 justify-between p-5">
                                        <div>
                                            <div class="flex items-center justify-between gap-5 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    2
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        اطلاعات وب سایت
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"max-w-fit mx-auto"}>
                                            <img src="/Assets/Image/bg-img/Information.png" alt="" className={"size-full"}/>
                                        </div>
                                        <div className="space-y-3">
                                            <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                                                <div className={"space-y-1"}>
                                                    <label className="text-xs dark:text-gray-300">
                                                        {t("WebsiteTitle")}
                                                        <RequiredIcon/>
                                                    </label>
                                                    <InputText  type="text"  className=" input"  />
                                                </div>
                                                <div className={"space-y-1"}>
                                                    <label className="text-xs dark:text-gray-300">
                                                        {t("Explanation")}
                                                        <RequiredIcon/>
                                                    </label>
                                                    <InputText  type="text"  className=" input"  />
                                                </div>
                                            </div>
                                            <div className={"space-y-1"}>
                                                <label className="text-xs dark:text-gray-300">
                                                    {t("Keywords")}
                                                    <RequiredIcon/>
                                                </label>
                                                <InputText  type="text"  className=" input"  />
                                            </div>
                                        </div>
                                        <div className={"flex justify-between items-center px-5"}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )}


                            {/* Div 2 */}
                            {currentDiv === 3 && (
                                <>
                                    <div className="flex flex-col gap-y-5 justify-between relative p-5">
                                        <div className={"absolute end-0 top-0 h-full"}>
                                            <img src="/Assets/Image/bg-img/SideWaves.png" alt="" className={""}/>
                                        </div>
                                        <div className={"z-10"}>
                                            <div class="flex items-center justify-between gap-5 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    3
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        تصاویر پیش فرض
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"z-10"}>
                                            <div className="space-y-5">
                                                <div class="flex gap-5 items-center ">
                                                    <div className="w-28 h-20 flex-shrink-0">
                                                        <UploadImage  removeName={'BannerImagePath'} accept={'image/*'}  name={'BannerImage'}
                                                                      bgStyle={'false'}
                                                        />
                                                    </div>
                                                    <div className={"space-y-2"}>
                                                        <h2 className={"text-xl text-themeInformation dark:text-themeDarkInformation"}>لوگوی روشن</h2>
                                                        <p className={"text-xs line-clamp-2 dark:text-gray-300"}>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ</p>
                                                    </div>
                                                </div>
                                                <div class="flex gap-5 items-center ">
                                                    <div className="w-28 h-20 flex-shrink-0">
                                                        <UploadImage  removeName={'BannerImagePath'} accept={'image/*'}  name={'BannerImage'}
                                                                      bgStyle={'false'}

                                                        />
                                                    </div>
                                                    <div className={"space-y-2"}>
                                                        <h2 className={"text-xl text-themeInformation dark:text-themeDarkInformation"}>لوگوی تیره</h2>
                                                        <p className={"text-xs line-clamp-2 dark:text-gray-300"}>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ</p>
                                                    </div>
                                                </div>
                                                <div class="flex gap-5 items-center ">
                                                    <div className="w-28 h-20 flex-shrink-0">
                                                        <UploadImage  removeName={'BannerImagePath'} accept={'image/*'}  name={'BannerImage'}
                                                                      bgStyle={'false'}

                                                        />
                                                    </div>
                                                    <div className={"space-y-2"}>
                                                        <h2 className={"text-xl text-themeInformation dark:text-themeDarkInformation"}>آیکون سایت</h2>
                                                        <p className={"text-xs line-clamp-2 dark:text-gray-300"}>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ</p>
                                                    </div>
                                                </div>
                                                <div class="flex gap-5 items-center ">
                                                    <div className="w-28 h-20 flex-shrink-0">
                                                        <UploadImage  removeName={'BannerImagePath'} accept={'image/*'}  name={'BannerImage'}
                                                                      bgStyle={'false'}
                                                        />
                                                    </div>
                                                    <div className={"space-y-2"}>
                                                        <h2 className={"text-xl text-themeInformation dark:text-themeDarkInformation"}>تصویر پیش فرض مطالب</h2>
                                                        <p className={"text-xs line-clamp-2 dark:text-gray-300"}>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ</p>
                                                    </div>
                                                </div>
                                                <div class="flex gap-5 items-center ">
                                                    <div className="w-28 h-20 flex-shrink-0">
                                                        <UploadImage  removeName={'BannerImagePath'} accept={'image/*'}  name={'BannerImage'}
                                                                      bgStyle={'false'}
                                                        />
                                                    </div>
                                                    <div className={"space-y-2"}>
                                                        <h2 className={"text-xl text-themeInformation dark:text-themeDarkInformation"}>تصویر پروفایل</h2>
                                                        <p className={"text-xs line-clamp-2 dark:text-gray-300"}>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"flex justify-between items-center px-5 z-10"}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Div 3 */}
                            {currentDiv === 4 && (
                                <>
                                    <div className="flex flex-col gap-y-10 justify-between p-5">
                                        <div>
                                            <div class="flex items-center justify-between gap-5 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    4
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        زمان و مکان
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"max-w-fit mx-auto"}>
                                            <img src="/Assets/Image/bg-img/earth.png" alt="" className={"size-full"}/>
                                        </div>

                                        <div className={" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3"}>
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">{t("کشور")}</label>
                                                <Select
                                                    name={'parentId'}
                                                    placeholder={t("selection")}
                                                    menuPosition={"fixed"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">{t("منطقه زمانی")}</label>
                                                <Select
                                                    name={'parentId'}
                                                    placeholder={t("selection")}
                                                    menuPosition={"fixed"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">{t("نوع تقویم")}</label>
                                                <Select
                                                    name={'parentId'}
                                                    placeholder={t("selection")}
                                                    menuPosition={"fixed"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>
                                            <div class="space-y-1">
                                                <label className="text-xs dark:text-gray-300">{t("روز اول هفته")}</label>
                                                <Select
                                                    name={'parentId'}
                                                    placeholder={t("selection")}
                                                    menuPosition={"fixed"}
                                                    classNamePrefix={"h_multi_select"}
                                                />
                                            </div>
                                        </div>
                                        <div className={"flex justify-between items-center px-5"}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Div 4 */}
                            {currentDiv === 5 && (
                                <>
                                    <div className="flex flex-col gap-y-5 justify-between p-5">
                                        <div>
                                            <div class="flex items-center justify-between gap-5 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    5
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        دامین اصلی
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"max-w-fit mx-auto"}>
                                            <img src="/Assets/Image/bg-img/domain.png" alt="" className={"size-full"}/>
                                        </div>
                                        <div className="space-y-3">
                                                <div>
                                                    <div class="space-y-1">
                                                        <label className="text-xs dark:text-gray-300">
                                                            {t("دامین اصلی")}
                                                        </label>
                                                        <div className="p-inputgroup">
                                                             <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                                <HPrimeIcon
                                                                    icon={"pi-globe"}
                                                                />
                                                            </span>
                                                            <InputText  className="input w-full" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                                                    <div className={"relative"}>
                                                        <InputText className={"input w-full"}  disabled={true}/>
                                                        <div className={"absolute top-1/2 -translate-y-1/2 end-2"}>
                                                            <button  className={"button bg-themeInformation"}>
                                                                <HPrimeIconSmall
                                                                    icon={"pi-copy"}
                                                                    iconClass={"text-white"}
                                                                />
                                                            </button>
                                                        </div>

                                                    </div>
                                                    <div className={"relative"}>
                                                        <InputText className={"input w-full"}  disabled={true}/>
                                                        <div className={"absolute top-1/2 -translate-y-1/2 end-2"}>
                                                            <button  className={"button bg-themeInformation"}>
                                                                <HPrimeIconSmall
                                                                    icon={"pi-copy"}
                                                                    iconClass={"text-white"}
                                                                />
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p className={"text-xs text-themeInformation dark:text-themeDarkInformation"}>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز</p>
                                                </div>
                                            </div>
                                        <div className={"flex justify-between items-center px-5"}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {currentDiv === 6 && (
                                <>
                                    <div className="flex flex-col gap-y-10 justify-between p-5">
                                        <div>
                                            <div class="flex items-center justify-between gap-5 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    6
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        اطلاعات کاربری
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={" grid grid-cols-2"}>
                                            <div className={"max-w-sm mx-auto"}>
                                                <img src="/Assets/Image/bg-img/password.png" alt="" className={"size-full"}/>
                                            </div>
                                            <div className={"w-full h-margin-auto space-y-3"}>
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                        <HPrimeIcon
                                                            icon={"pi-key"}
                                                        />
                                                    </span>
                                                        <InputText  className="input w-full" placeholder={t("پسورد قبلی")} />
                                                    </div>
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                        <HPrimeIcon
                                                            icon={"pi-key"}
                                                        />
                                                    </span>
                                                    <InputText  className="input w-full" placeholder={t("پسورد جدید")} />
                                                    </div>
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                        <HPrimeIcon
                                                            icon={"pi-key"}
                                                        />
                                                    </span>
                                                        <InputText  className="input w-full" placeholder={"تکرار پسورد"} />
                                                    </div>
                                            </div>
                                        </div>
                                        <div className={"flex justify-between items-center px-5 "}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {currentDiv === 7 && (
                                <>
                                    <div className="flex flex-col gap-y-10 justify-between p-5">
                                        <div>
                                            <div class="flex items-center justify-between gap-5 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    7
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        شخصی سازی
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="space-y-5">
                                            <div className="grid grid-cols-1 md:grid-cols-3  gap-10">
                                                <label
                                                    htmlFor={`2`}
                                                    className={`box bg-white dark:bg-themeDarkPostInput overflow-hidden cursor-pointer ${selectedItem === "2" ? ' border border-themeInformation bg-themeLightGray' : ''} `}>
                                                    <input
                                                        type="radio"
                                                        className="hidden"
                                                        data-hm="website"
                                                        name="jj"
                                                        id={`2`}
                                                        value="2"
                                                        checked={selectedItem === "2"} // تنظیم وضعیت انتخاب برای نشان دادن انتخاب
                                                        onChange={(e) => handleChangeMulti(e.target.value)}
                                                    />
                                                    <div className="w-full H_aspect_15 ">
                                                        <img src={"/Assets/Image/bg-img/2222.jpg"} alt="" className="size-full  absolute inset-0" />
                                                    </div>
                                                    <div className="p-2">
                                                        <h2 className="text-themeInformation dark:text-gray-300">masoud</h2>
                                                    </div>
                                                </label>
                                                <label
                                                    htmlFor={`3`}
                                                    className={`box bg-white dark:bg-themeDarkPostInput overflow-hidden cursor-pointer ${selectedItem === "3" ? ' border border-themeInformation bg-themeLightGray' : ''} `}>
                                                    <input
                                                        type="radio"
                                                        className="hidden"
                                                        data-hm="website"
                                                        name="jj"
                                                        id={`3`}
                                                        value="3"
                                                        checked={selectedItem === "3"} // تنظیم وضعیت انتخاب برای نشان دادن انتخاب
                                                        onChange={(e) => handleChangeMulti(e.target.value)}
                                                    />
                                                    <div className="w-full H_aspect_15 ">
                                                        <img src={"/Assets/Image/bg-img/2222.jpg"} alt="" className="size-full  absolute inset-0" />
                                                    </div>
                                                    <div className="p-2">
                                                        <h2 className="text-themeInformation dark:text-gray-300">masoud</h2>
                                                    </div>
                                                </label>
                                                <label
                                                    htmlFor={`4`}
                                                    className={`box bg-white dark:bg-themeDarkPostInput overflow-hidden cursor-pointer ${selectedItem === "4" ? ' border border-themeInformation bg-themeLightGray' : ''} `}>
                                                    <input
                                                        type="radio"
                                                        className="hidden"
                                                        data-hm="website"
                                                        name="jj"
                                                        id={`4`}
                                                        value="4"
                                                        checked={selectedItem === "4"} // تنظیم وضعیت انتخاب برای نشان دادن انتخاب
                                                        onChange={(e) => handleChangeMulti(e.target.value)}
                                                    />
                                                    <div className="w-full H_aspect_15 ">
                                                        <img src={"/Assets/Image/bg-img/2222.jpg"} alt="" className="size-full  absolute inset-0" />
                                                    </div>
                                                    <div className="p-2">
                                                        <h2 className="text-themeInformation dark:text-gray-300">masoud</h2>
                                                    </div>
                                                </label>


                                            </div>
                                        </div>
                                        <div className={"flex justify-between items-center px-5 "}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {currentDiv === 8 && (
                                <>
                                    <div className="flex flex-col space-y-5 justify-between p-5">
                                        <div>
                                            <div class="flex items-center justify-between gap-5 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    8
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        انتخاب قالب
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"flex gap-2 items-center justify-center"}>
                                            <label htmlFor={20} className={`p-3 text-center min-w-20 bg-white box dark:bg-themeDarkSalt700 border cursor-pointer ${selectedItem === "20" ? '  border-themeInformation bg-themeLightGray shadow' : ''} `}>
                                                <input
                                                    className={"hidden"}
                                                    type="radio"
                                                    id={`20`}
                                                    value="20"
                                                    name="ss"
                                                    checked={selectedItem === "20"} // تنظیم وضعیت انتخاب برای نشان دادن انتخاب
                                                    onChange={(e) => handleChangeMulti(e.target.value)}
                                                />
                                                <h2 className={`text-xs`}>شرکتی</h2>
                                            </label>
                                            <label htmlFor={30} className={`p-3 text-center min-w-20 bg-white box dark:bg-themeDarkSalt700 border cursor-pointer ${selectedItem === "30" ? '  border-themeInformation bg-themeLightGray shadow' : ''} `}>
                                                <input
                                                    className={"hidden"}
                                                    type="radio"
                                                    id={`30`}
                                                    value="30"
                                                    name="ss"
                                                    checked={selectedItem === "30"} // تنظیم وضعیت انتخاب برای نشان دادن انتخاب
                                                    onChange={(e) => handleChangeMulti(e.target.value)}
                                                />
                                                <h2 className={`text-xs`}>فروشگاهی</h2>
                                            </label>
                                            <label htmlFor={40} className={`p-3 min-w-20 text-center bg-white box dark:bg-themeDarkSalt700 border cursor-pointer ${selectedItem === "40" ? '  border-themeInformation bg-themeLightGray shadow' : ''} `}>
                                                <input
                                                    className={"hidden"}
                                                    type="radio"
                                                    id={`40`}
                                                    value="40"
                                                    name="ss"
                                                    checked={selectedItem === "40"} // تنظیم وضعیت انتخاب برای نشان دادن انتخاب
                                                    onChange={(e) => handleChangeMulti(e.target.value)}
                                                />
                                                <h2 className={`text-xs`}>خبری</h2>
                                            </label>

                                        </div>
                                        <div className={"grid grid-cols-1 md:grid-cols-3 gap-5"}>
                                                <div class="box bg-white rounded-md overflow-hidden dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine">
                                                    <div class="H_aspect_15">
                                                        <img alt="harmony" className="object-cover absolute inset-0 size-full" src={"/Assets/Image/bg-img/2222.jpg"}/>
                                                    </div>
                                                    <div class="p-2">
                                                        <h2 className="line-clamp-1 text-gray-600 dark:text-gray-300">{t("قالب3")}</h2>
                                                    </div>
                                                    <div class="p-2 border-t border-gray-200 dark:border-themeDarkColorLine flex gap-2 items-center">

                                                        <button className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">
                                                            {t("TemplateSelection")}
                                                        </button>
                                                        <button className="button bg-themeSecondary text-white flex-shrink-0">
                                                            <HPrimeIcon
                                                                icon={"pi-eye"}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="box bg-white rounded-md overflow-hidden dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine">
                                                    <div class="H_aspect_15">
                                                        <img alt="harmony" className="object-cover absolute inset-0 size-full" src={"/Assets/Image/bg-img/2222.jpg"}/>
                                                    </div>
                                                    <div class="p-2">
                                                        <h2 className="line-clamp-1 text-gray-600 dark:text-gray-300">{t("قالب3")}</h2>
                                                    </div>
                                                    <div class="p-2 border-t border-gray-200 dark:border-themeDarkColorLine flex gap-2 items-center">

                                                        <button className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">
                                                            {t("TemplateSelection")}
                                                        </button>
                                                        <button className="button bg-themeSecondary text-white flex-shrink-0">
                                                            <HPrimeIcon
                                                                icon={"pi-eye"}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="box bg-white rounded-md overflow-hidden dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine">
                                                    <div class="H_aspect_15">
                                                        <img alt="harmony" className="object-cover absolute inset-0 size-full" src={"/Assets/Image/bg-img/2222.jpg"}/>
                                                    </div>
                                                    <div class="p-2">
                                                        <h2 className="line-clamp-1 text-gray-600 dark:text-gray-300">{t("قالب3")}</h2>
                                                    </div>
                                                    <div class="p-2 border-t border-gray-200 dark:border-themeDarkColorLine flex gap-2 items-center">

                                                        <button className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">
                                                            {t("TemplateSelection")}
                                                        </button>
                                                        <button className="button bg-themeSecondary text-white flex-shrink-0">
                                                            <HPrimeIcon
                                                                icon={"pi-eye"}
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        <div className={"flex justify-between items-center px-5"}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {currentDiv === 9 && (
                                <>
                                    <div className=" flex flex-col space-y-10 justify-between p-5">
                                        <div>
                                            <div class="flex items-center justify-between gap-5 p-2 bg-white dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation rounded-full">
                                                <div class="rounded-full size-10 bg-themeInformation dark:bg-themeDarkInformation flex-shrink-0 text-white grid place-content-center border border-dashed text-2xl shadow-xl">
                                                    9
                                                </div>
                                                <div class="flex-1 p-2">
                                                    <h2 class=" text-themeInformation dark:text-themeDarkInformation">
                                                        پایان
                                                    </h2>
                                                </div>
                                                <div>
                                                <span className={"arrow_design_add_site"}>
                                                    <ArrowLeftIcon
                                                        sizeStyle={"size-10"}
                                                    />
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-2">
                                            <div className={"h-margin-auto"}>
                                                <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده</p>
                                            </div>
                                            <div className={"max-w-fit mx-auto"}>
                                                <img src="/Assets/Image/bg-img/done.svg" alt="" className={"size-full"}/>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={"flex items-center justify-center"}>
                                                <Link to={"/dashboard"} className={"button w-32 bg-themeInformation text-white"}>
                                                    lets go
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={"flex justify-between items-center px-5 z-10"}>
                                            <div>
                                                <button type={"button"} className={""} onClick={handlePrevious}>
                                                    <HPrimeIcon
                                                        icon={"pi-angle-double-right"}
                                                    />
                                                </button>
                                            </div>
                                            <div className={"flex gap-2 items-center"}>
                                                <button type={"button"} className={"w-28 text-themeSecondary"}>ردکردن</button>
                                                <button type={"button" } className={"button w-28 bg-themeInformation text-white"} onClick={handleNext}>بعدی</button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>

            )}
        </>
    );
};
