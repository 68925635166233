import React, {useEffect, useState} from "react";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridTable} from "../../../Commans/Grid/GridTable";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {useComplaintGrid} from "./useComplaintGrid";

export const ComplaintsTab=({filter})=>{
    const [initialSource,setInitialSource]=useState({sendingStates:[],reminderStates:[]});
    const [refresh,setRefresh]=useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);

    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=> setRefresh((prevState)=>!prevState);

    const handleInitialSource=(sendingStates,noteTypes,jobStates)=>{
        const sendingStatesData = convertSelectDataHandler(sendingStates);
        const noteTypesData = convertSelectDataHandler(noteTypes);
        const jobStatesData = convertSelectDataHandler(jobStates);
        setInitialSource({
            sendingStates: sendingStatesData,
            types: noteTypesData,
            reminderStates: jobStatesData,
        })
    }

    const fetchData = async () => {
        try {
            showSpinner();
            const [
                responseSendingStates,
                reminderResponse,
                stateResponse,
            ] = await Promise.all([
                GET(`/Enumerations/EnMessengers`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnNoteType`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnBackgroundJobState`),
            ]);
            if (responseSendingStates.status === 200 &&
                reminderResponse.status === 200 &&
                stateResponse.status === 200
            )
            {
                handleInitialSource(
                    responseSendingStates.data,
                    reminderResponse.data,
                    stateResponse.data,

                );
                hideSpinner();
            } else {
                handleApiError(responseSendingStates, hideSpinner);
                handleApiError(reminderResponse, hideSpinner);
                handleApiError(stateResponse, hideSpinner);
            }
        } catch (error) {
            hideSpinner();
        }
    };
    useEffect(()=>{
        fetchData();
    },[])
    const {pagination,complaints,autoGroupColumnDef,columnDefs,defaultColDef,getRowHeight,handleNextPage,handlePreviousPage,handleCurrentPage, handlePageSizeCount}=useComplaintGrid(filter,initialSource,refresh,Refresh,showSpinner,hideSpinner)


    return (

        <>

            <div className={"space-y-2 H-body-ag-grid relative"}>
                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={complaints}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridPersion}
                />
                <Pagination length={complaints.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />

            </div>


        </>
    )
}