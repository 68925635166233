import React, {useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {GridTable} from "../../../Components/Commans/Grid/GridTable";
import {FantasyModal} from "../../../Components/Commans/UIParts/FantasyModal";
import {ListSearch} from "../../../Components/Commans/Grid/ListSearch";
import {ListFilter} from "../../../Components/Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonSearchList} from "../../../Components/Commans/UIParts/ButtonSearchList";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {InputNumber} from "primereact/inputnumber";
import {ToggleSwitch} from "../../Commans/UIParts/ToggleSwitch";
import {useCustomField} from "./useCustomField";
import {validate} from "../../../Services/Globals/validate";
import {communicationSchema} from "../PersonsManagement/Communications/communicationSchema";
import {POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {ToastContainer} from "react-toastify";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {useFieldGrid} from "./useFieldGrid";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";


export const HandleCustomField = ()=>{
    const { t } = useTranslation();
    const [errors,setErrors]=useState({});
    const {setField,filed,handleSelectValue, handleNumberValue,handleValue}=useCustomField(setErrors)
    const [IsOpenCustomField, setOpenCustomField] = useState(false);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [IsEdit,setIsEdit]=useState(false);
    const[refresh,setRefresh]=useState(false);


    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }
    const OnSetErrors=(errors)=>{
        setErrors(errors);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true)
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const openCustomField=()=>{
        setField(prevState => ({...prevState,stateShow: true, order: 0}))
        setOpenCustomField(true)
    }
    const closeCustomField=()=>{
        setOpenCustomField(false);
        setErrors({});
        setIsEdit(false);
        setField(prevState => ({...prevState,title: '',description: '',stateShow: true, order: 0}))
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        const isValid=validate(communicationSchema,filed,OnSetErrors);
        if(!isValid){
            return
        }
        try {
            showSpinner();
            if(IsEdit){
                const response = await PUT(`/Communications`,{
                    title:filed.title === null || filed.title === undefined?null:filed.title,
                    description:filed.description === null || filed.description === undefined?null:filed.description,
                    stateShow:filed.stateShow,
                    communicationId:filed.communicationId,
                    order:filed.order === null || filed.order === undefined?0:filed.order,
                },false);
                if(response.status === 200){
                    hideSpinner();
                    closeCustomField();
                    Refresh();
                }
                else{
                    handleApiError(response,hideSpinner);
                }
            }
            else{
                const response = await POST(`/Communications`,{
                    title:filed.title === null || filed.title === undefined?null:filed.title,
                    description:filed.description === null || filed.description === undefined?null:filed.description,
                    stateShow:filed.stateShow,
                    order:filed.order === null || filed.order === undefined?0:filed.order,
                },false);
                if(response.status === 200){
                    hideSpinner();
                    closeCustomField();
                    Refresh();
                }
                else{
                    handleApiError(response,hideSpinner);
                }
            }

        }catch (e) {
            hideSpinner();
        }
    }


    const {handleDeleteCommunication,handleGetCommunication,handleSearch,search,setSearch,columnDefs,autoGroupColumnDef, defaultColDef,handleCurrentPage, handleNextPage,handlePreviousPage,handlePageSizeCount,handleResetFilters,handleToggleFilter,setFilter,filter,SwitchFilters,rowData,getRowHeight,pagination}=useFieldGrid(showSpinner,hideSpinner,Refresh,refresh,setRefresh,setIsEdit,setOpenCustomField,setField);








    return (

        <>
            <div class=" space-y-5 relative">

                {

                    IsShowSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }


                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByTitle")}</label>
                            <InputText type={"text"} className={" input w-full"} />
                        </div>
                        <ButtonSearchList />
                    </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>openCustomField()}
                                            tooltipTitle={t("CustomField")}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                                    </>
                                }
                    />


                </div>
                <div>
                    <div className={"space-y-2 H-body-ag-grid"} >

                        <GridTable
                            columnDefs={columnDefs}
                            rowSelection={GridSetting.rowSelection}
                            rowData={rowData}
                            style={GridSetting.gridStyle}
                            defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            pagination={GridSetting.pagination}
                            enableRtl={GridSetting.enableRtl}
                            domLayout={GridSetting.domLayout}
                            paginationPageSize={GridSetting.pageSize}
                            getRowHeight={getRowHeight}
                            localeText={AgGridEnglish}
                        />
                        <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                    </div>
                    <Export apiUrl={''} filters={filter}/>
                </div>

                {/*<FantasyModal openModal={IsOpenCustomField} closeModal={closeCustomField} title={t("event")}>*/}
                {/*    <div>*/}
                {/*        <div class="flex items-center border-b dark:border-themeDarkColorLine">*/}
                {/*            <div>*/}
                {/*                <ul class="flex gap-2 items-center justify-center p-2">*/}
                {/*                    <li>*/}
                {/*                        <img src="/Assets/Image/user/person1.png"*/}
                {/*                             className="w-10 h-10 block rounded-full overflow-hidden object-contain"*/}
                {/*                             alt="user" title="user"/>*/}
                {/*                    </li>*/}
                {/*                    <li className="flex gap-1">*/}
                {/*                        <h3 className="text-xs dark:text-gray-300">مسعود احمدی</h3>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className="space-y-2">*/}
                {/*            <div>*/}
                {/*                <InputTextarea name={"excerpt"} rows={15} cols={30} className={"input w-full text-xs"} value="منابع موزیک بدون کپی رایت، جهت اینسب منابع موزیک بدون کپی رایت، جهت اینسب منابع موزیک بدون کپی رایت، جهت اینسب"/>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div>*/}
                {/*            <button type="button" className="button w-full bg-themeInformation dark:bg-themeDarkInformation text-white">{t("edit")}</button>*/}
                {/*        </div>*/}
                {/*    </div>*/}



                {/*</FantasyModal>*/}
            </div>





            <FantasyModal openModal={IsOpenCustomField} closeModal={closeCustomField} title={t("CustomField")} >

                <form method="post" onSubmit={handleSubmit} >
                    <div className="space-y-5">
                        <div className={"space-y-3"}>
                            <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                                <div className="space-y-2">
                                    <div className={"flex justify-between items-center gap-2"}>
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("Title")}
                                        <RequiredIcon/>
                                        </label>
                                        <DisplayError message={errors.title}/>
                                    </div>
                                    <InputText
                                        type="text"
                                        className="input w-full"
                                        name={'title'}
                                        onInput={(e)=>handleValue(e)}
                                        value={filed.title}

                                    />

                                </div>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("DisplayOrder")}</label>
                                    <InputNumber className="w-full" name={'order'} value={filed.order} onChange={(e)=>handleNumberValue(e
                                        .value,'order')}  showButtons min={0} />
                                </div>
                            </div>
                            <div className="flex-shrink-0">
                                <ToggleSwitch tag={'stateShow'} customClass={"w-32"} value={filed.stateShow} handleToggleSwitchValue={handleSelectValue} name={'stateShow'} checked={t("Active")} unchecked={t("Inactive")} />
                            </div>
                            <div>
                                <InputText
                                    type="text"
                                    name={'description'}
                                    className="input w-full"
                                    placeholder={t("description")}
                                    title={filed.description}
                                    onInput={(e)=>handleValue(e)}
                                    value={filed.description}
                                />
                            </div>
                        </div>

                        <div >
                            <button type={"submit"} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative"}>
                                {t("Register")}
                            </button>
                        </div>
                    </div>
                </form>

            </FantasyModal>


            <ToastContainer/>
        </>



    )
}