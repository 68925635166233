import React from "react";
import {useTranslation} from "react-i18next";

export const useCustomTabs=()=>{
    const { t } = useTranslation();

    const tabs=[

        {
            id:'content',
            target:'#tab_one',
            label:t("contenttext"),
            //subLabel:'اصلی',
            icon:'file-text'
        },
        {
            id:'shop',
            target:'#tab_two',
            label:t("shop"),
            //  subLabel:'تگ',
            icon:'shopping-cart'
        },
        {
            id:'survey',
            target:'#tab_three',
            label:t("Survey"),
            //subLabel:'اصلی',
            icon:'trending-up'
        },
        {
            id:'form',
            target:'#tab_four',
            label:t("Form"),
            //  subLabel:'تگ',
            icon:'file-text'
        },
    ]
    return {tabs}
}