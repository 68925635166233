import {useTranslation} from "react-i18next";
import {useState} from "react";

export const useUserSignup=(setErrors)=>{

    const {t} = useTranslation();
    const [users, setUsers] = useState({userName: null, password: null,email:null,phoneNumber:null,fullName:null});
    const handleValue = (e) => {
        const {name, value} = e.target;
        setUsers({...users, [name]: value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };


    return {
        handleValue,
        users,
        setUsers
    }
}