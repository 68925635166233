import React, {useState} from "react";
import {InputText} from "primereact/inputtext";
import {useTranslation} from "react-i18next";

export const ConnectionStringBuilder =(props)=>{
    const { t } = useTranslation();
    const handleValueChange = (data,name)=>{
      props.OnValueChange(data,name);
    }
    const initialFormData = {
        dataSource: '',
        database: '',
        userId: '',
        password: '',
        instance: '',
        appName: '',
        port: '',
        connectionTimeout: '',
        authenticationType: 'Windows Authentication',
    };
    const [formData, setFormData] = useState({...initialFormData});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRadioChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, authenticationType: value });

        // Clear userId and password when Windows Authentication or Azure Active Directory - Integrated is selected
        if (value === 'Windows Authentication' || value === 'Azure Active Directory - Integrated') {
            setFormData({ ...formData, userId: '', password: '' });
        }
    };

    const generateConnectionString = () => {
        const {
            dataSource,
            database,
            userId,
            password,
            instance,
            appName,
            port,
            connectionTimeout,
            authenticationType,
        } = formData;

        let connectionString = `Data Source=${dataSource};Initial Catalog=${database};`;

        if (authenticationType === 'Windows Authentication') {
            connectionString += 'Integrated Security=SSPI;';
        } else if (authenticationType === 'SQL Server Authentication') {
            connectionString += `User ID=${userId};OtpAuthentication=${password};`;
        }

        if (instance) {
            connectionString += `Server=${instance};`;
        }

        if (appName) {
            connectionString += `Application Name=${appName};`;
        }

        if (port) {
            connectionString += `Port=${port};`;
        }

        if (connectionTimeout) {
            connectionString += `Connect Timeout=${connectionTimeout};`;
        }

        return connectionString;
    };
    const resetForm = () => {
        setFormData({ ...initialFormData });
    };

    const GenerateConnection = ()=>{
        const connectionString = generateConnectionString();
        handleValueChange(connectionString,props.name);
        props.handleCloseModal();
        resetForm();
    }


    return (
        <>

            <div className="bg-white p-2 box dark:bg-themeDarkSalt700">
                <div className={"space-y-5 "}>
                    <div className="space-y-3">
                        <div className={"p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation"}>
                            <p className="dark:text-gray-300 text-xs">{t("GeneratedConnectionString")}</p>
                        </div>
                        <div className={"px-2"}>
                            <div className={"post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-3"}>
                                <code className="iput dark:text-gray-300 bg-white dark:bg-themeDarkSalt700 w-full block p-2">{generateConnectionString()}</code>
                                <button onClick={()=>resetForm()} className="button w-28 text-white bg-themeDanger">{t("Reset")}</button>
                            </div>
                        </div>
                    </div>
                    <div className={"space-y-3"}>
                        <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                            <p className={"dark:text-gray-300 text-xs"}>{t("BasicSettings")}</p>
                        </div>
                        <div className={"px-2"}>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 p-2 gap-3 post-input dark:bg-themeDarkPostInput post-input-design">
                                <div className="space-y-1">
                                    <label className={"dark:text-gray-300 text-xs"}>{t("DataSourceEn")}</label>
                                    <InputText
                                        type="text"
                                        name="dataSource"
                                        className={'input w-full'}
                                        value={formData.dataSource}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("Database")}</label>
                                    <InputText
                                        type="text"
                                        name="database"
                                        className={'input w-full'}
                                        value={formData.database}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("UserIDEn")}</label>
                                    <InputText
                                        type="text"
                                        name="userId"
                                        className={'input w-full'}
                                        value={formData.userId}
                                        onChange={handleInputChange}
                                        disabled={
                                            formData.authenticationType === 'Windows Authentication' ||
                                            formData.authenticationType === 'Azure Active Directory - Integrated'
                                        }
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("Instance")}</label>
                                    <InputText
                                        type="text"
                                        name="instance"
                                        className={'input w-full'}
                                        value={formData.instance}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("ApplicationName")}</label>
                                    <InputText
                                        type="text"
                                        name="appName"
                                        className={'input w-full'}
                                        value={formData.appName}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("PasswordEN")}</label>
                                    <InputText
                                        type="password"
                                        name="password"
                                        className={'input w-full'}
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        disabled={
                                            formData.authenticationType === 'Windows Authentication' ||
                                            formData.authenticationType === 'Azure Active Directory - Integrated'
                                        }
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("port")}</label>
                                    <InputText
                                        type="text"
                                        name="port"
                                        className={'input w-full'}
                                        value={formData.port}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("ConnectionTimeout")}</label>
                                    <InputText
                                        type="text"
                                        name="connectionTimeout"
                                        className={'input w-full'}
                                        value={formData.connectionTimeout}
                                        onChange={handleInputChange}
                                    />
                                </div>


                                <div className="space-y-2 md:col-span-2 lg:col-span-1">
                                    <div>
                                        <label className="dark:text-gray-300 text-xs flex items-center gap-2 cursor-pointer" htmlFor="2">
                                            <input
                                                type="radio"
                                                id="2"
                                                name="authenticationType"
                                                value="SQL Server Authentication"
                                                checked={formData.authenticationType === 'SQL Server Authentication'}
                                                onChange={handleRadioChange}
                                            />
                                            {t("SQLServerAuthentication")}
                                        </label>
                                    </div>
                                    <div>
                                        <label className="dark:text-gray-300 text-xs flex items-center gap-2 cursor-pointer" htmlFor="3">
                                            <input
                                                type="radio"
                                                id="3"
                                                name="authenticationType"
                                                value="Azure Active Directory - OtpAuthentication"
                                                checked={formData.authenticationType === 'Azure Active Directory - OtpAuthentication'}
                                                onChange={handleRadioChange}
                                            />
                                            {t("AzureActiveDirectoryPassword")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <button
                        className="button text-white w-full bg-themeInformation dark:bg-themeDarkInformation"
                        onClick={() =>GenerateConnection()}>
                        {t("GenerateConnectionString")}
                    </button>
                </div>

            </div>

        </>

    )

}