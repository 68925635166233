import React from "react";

import {Tooltip} from "react-tooltip";
import {useTranslation} from "react-i18next";

export const StepMenu = ({ tabs, activeTab, handleTabClick,tooltipTitle, tooltipId })=>{

    const { t } = useTranslation();
    return (
        <>
            <ul class="relative grid grid-cols-2 lg:grid-cols-7 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-3">
                {tabs.map((tab) => (
                    <>
                <li  key={tab.id} data-toggle="tab" data-target={tab.target} className={`tabs_index__li  ${ activeTab === tab.id ? "active" : ""} ${tab.tooltipTitle ? `tooltip_class_${tab.id}`:'tooltip_plus'}  `}>
                    <div class="flex justify-center text-xs cursor-pointer items-center gap-2 tabs_index__li_div" onClick={() => handleTabClick(tab.id)}>
                        {/*<div class="block">*/}
                        {/*<span class="h-8 w-8 grid place-content-center rounded-full text-xs">*/}
                        {/*    {tab.step}*/}
                        {/*</span>*/}
                        {/*</div>*/}
                        <h6 className="lg:ms-7 xl:ms-0">
                            {tab.title}
                        </h6>
                    </div>
                    <Tooltip anchorSelect={tab.tooltipTitle ? `.tooltip_class_${tab.id}` : ".tooltip_plus"} content={tab.tooltipTitle ? tab.tooltipTitle : t("New")} />
                </li>
                    </>
                ))}
            </ul>

        </>

    )
}
