import React, {useState} from "react";

import {InputText} from "primereact/inputtext";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import ButtonVideo from "../../../Commans/UIParts/ButtonVideo";
import {TabMenuTwo} from "../../../Commans/UIParts/TabMenuTwo";
import {MultiDataSelect} from "../../../Commans/UIParts/MultiDataSelect";
import {InputNumber} from "primereact/inputnumber";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {PasswordChanger} from "./Passwords/PasswordChanger";
import {Tooltip} from "react-tooltip";
import Select from "react-select";
import {Banks} from "./UserBanks/Banks";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {Accordion, AccordionTab} from "primereact/accordion";
import {ToggleSwitch} from "../../../Commans/UIParts/ToggleSwitch";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";
import {InputTextarea} from "primereact/inputtextarea";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const HandleEditProfile = () => {
    const { t } = useTranslation();

    const [value1, setValue1] = useState(20);


    const [activeTab, setActiveTab] = useState("one");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = [
        {
            id: "one",
            label:t("PrivetData"),
            tooltipTitle:t("PrivetData"),
        },
        {
            id: "two",
            label:t("CompanyData"),
            tooltipTitle:t("CompanyData"),
        },

        {
            id: "three",
            label:t("BankData"),
            tooltipTitle:t("BankData"),

        },

        {
            id: "four",
            label:t("transactions"),
            tooltipTitle:t("ListOfTransactions"),
        },
        {
            id: "five",
            label:t("ChangePassword"),
            tooltipTitle:t("ChangePassword"),
        },


    ];


    return(
        <div className=" space-y-5">
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            childrenTwo={false}
                            childrenOne={
                                <>

                                </>
                            }
                />


            </div>
            <div class="space-y-2">
                <div class="relative grid grid-cols-2 lg:grid-cols-7 gap-2 tabs_index box bg-white dark:bg-themeDarkSalt700 p-3">
                    <TabMenuTwo activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                </div>
                <div className="post__content tab-content bg-white box dark:bg-themeDarkSalt700 p-2">
                    <div className={`tab-content__pane ${ activeTab === "one" ? "active" : "" }`}   id="one">
                        <div className="space-y-3">
                            <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation flex gap-2 items-center">
                                <p className={"dark:text-gray-300"}>{t("UserCode")}:</p>
                                <span className={"text-xs text-themeInformation dark:text-gray-300"}>(123132154564564)</span>
                            </div>
                            <div className={"px-2"}>
                                <div class="post-input dark:bg-themeDarkPostInput post-input-design p-2 space-y-4">
                                    <div className="flex flex-col md:flex-row gap-3">
                                        <div class="space-y-1 w-full md:w-36 flex-shrink-0">
                                            <label className="dark:text-gray-300 text-xs">{t("Mr") +"/"+ t("Miss")}</label>
                                            <Select
                                                isSearchable={true}
                                                name={'sex'}
                                                placeholder={t("selection")}
                                                menuPosition={"fixed"}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 flex-1">
                                            <div class=" space-y-2">
                                                <div className="flex justify-between">
                                                    <label className="dark:text-gray-300 text-xs">
                                                        {t("FirstNameAndLastName")}
                                                        <RequiredIcon/>
                                                    </label>
                                                    {/*<DisplayError message={errors.fullName}/>*/}
                                                </div>
                                                <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-user"} />
                                                </span>
                                                    <InputText  className="input w-full" name={'fullName'}  />
                                                </div>
                                            </div>
                                            <div className="space-y-2">
                                                <div className="flex justify-between">
                                                    <label className="dark:text-gray-300 text-xs">
                                                        {t("MobilePhone")}
                                                        <RequiredIcon/>
                                                    </label>
                                                    {/*<DisplayError message={errors.mobilePhoneNumber}/>*/}
                                                </div>
                                                <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-mobile"} />
                                                </span>
                                                    <InputText className="input w-full"   name={'mobilePhoneNumber'}  />
                                                </div>
                                            </div>
                                            <div className="space-y-2">
                                                <div className="flex justify-between">
                                                    <label className="dark:text-gray-300 text-xs">
                                                        {t("LandlinePhone")}
                                                    </label>
                                                    {/*<DisplayError message={errors.homePhoneNumber}/>*/}
                                                </div>
                                                <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                    <HPrimeIcon icon={"pi-phone"} />
                                                </span>
                                                    <InputText className="input w-full" name={'homePhoneNumber'}  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-3">
                                        <div class="space-y-1 w-full md:w-36 flex-shrink-0">
                                            <label className="dark:text-gray-300 text-xs">{t("methodOfIntroduction")}</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                name={'introductionMethod'}
                                                placeholder={t("selection")}
                                                menuPosition={"fixed"}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div className={"grid grid-cols-1 md:grid-cols-3 gap-3 flex-1"}>
                                            <div class="space-y-1">
                                                <div class="relative space-y-2">
                                                    <div className="flex justify-between">
                                                        <label className="dark:text-gray-300 text-xs">{t("ReferralCode")}</label>
                                                        {/*<DisplayError message={errors.presenterCode}/>*/}
                                                    </div>
                                                    <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                        <HPrimeIcon icon={"pi-code"} />
                                                    </span>
                                                        {/*<InputText  className={` input_number_plus w-full ${ isPresentorCodeValid === false ? "border-2 border-red-500" : '' }`}    name={'presenterCode'} onInput={(e)=>handleValue(e)} value={users.presenterCode} />*/}
                                                        <InputText  className={" input_number_plus w-full "} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="space-y-2">
                                                <div className="flex justify-between">
                                                    <label className="dark:text-gray-300 text-xs">{t("Email")}</label>
                                                    {/*<DisplayError message={errors.email}/>*/}
                                                </div>
                                                <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                     <HPrimeIcon icon={"pi-envelope"}/>
                                                </span>
                                                    <InputText className="input w-full"  autoComplete={'off'} autoSave={'off'}  name={'email'}  />
                                                </div>
                                            </div>
                                            <div class="space-y-2">
                                                <div class="relative space-y-2">
                                                    <div className="flex justify-between">
                                                        <label className="dark:text-gray-300 text-xs">{t("NationalIdNumber")}</label>
                                                        {/*<DisplayError message={errors.nationalCode}/>*/}
                                                    </div>
                                                    <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                                         <HPrimeIcon icon={"pi-id-card"}/>
                                                    </span>
                                                        <InputText className="input w-full" name={'nationalCode'}  />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-content__pane ${ activeTab === "two" ? "active" : "" }`}   id="two">
                        <div className={"space-y-3"}>
                            <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                <p className={"text-xs dark:text-gray-300"}>{t("CompanyData")}</p>
                            </div>
                            <div className={"space-y-3"}>
                                <div className={"px-2"}>
                                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 p-2 post-input post-input-design dark:bg-themeDarkPostInput">
                                        <div className="space-y-1 ">
                                            <label className="dark:text-gray-300 text-xs">{t("fatherName")}</label>
                                            <InputText className="input w-full"  name={'FatherName'}  />
                                        </div>
                                        <div className="space-y-1 ">
                                            <label className="dark:text-gray-300 text-xs">{t("occupation")}</label>
                                            <InputText className="input w-full" name={'Job'} />
                                        </div>
                                        <div className="space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("MaritalStatus")}</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder={t("selection")}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("nationality")}</label>
                                            <InputText className="input w-full" name={'Nationality'}  />
                                        </div>
                                        <div className="space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("country")}</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder={t("selection")}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("province")}</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                name={'Provinces'}
                                                placeholder={t("selection")}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("city")}</label>
                                            <Select
                                                isClearable={true}
                                                isSearchable={true}
                                                name={'CityId'}
                                                placeholder={t("selection")}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <label className="dark:text-gray-300 text-xs">{t("education")}</label>
                                            <InputText className="input w-full" name={'Education'}  />
                                        </div>
                                        <div class="space-y-1 col-span-2 lg:col-span-3">
                                            <label className="text-xs dark:text-gray-300">{t("Address")}</label>
                                            <InputText className="input w-full"  name={'Address'}  />
                                        </div>
                                        <div className="space-y-1 lg:col-span-1">
                                            <label className="dark:text-gray-300 text-xs">{t("postalCode")}</label>
                                            <InputText type={'text'} className="input w-full" name={'PostalCode'}  />
                                        </div>
                                    </div>
                                </div>
                                <div className={"space-y-3"}>
                                    <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                        <h2 className={"dark:text-gray-300 text-xs"}>
                                            {t("additionalDetails")}
                                        </h2>
                                    </div>
                                    <div className={"px-2"}>
                                        <div className={"p-2 post-input dark:bg-themeDarkPostInput post-input-design"}>
                                            <InputTextarea name={"Description"} rows={3} cols={30} className={"input w-full "}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-content__pane ${ activeTab === "three" ? "active" : "" }`} id="three">
                        {
                            activeTab === 'three'?
                                <Banks/>:null
                        }
                    </div>
                    <div className={`tab-content__pane ${ activeTab === "four" ? "active" : "" }`}  id="four">
                        <div class="space-y-5">
                            <div>
                                <ListSearch label={null} icon={null} >
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">{t("TransactionCode")}</label>
                                        <InputText type={"search"} className={" input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">{t("FromDate")}</label>
                                        <InputText type={"search"} className={" input w-full"} />
                                    </div>
                                    <div class="space-y-2">
                                        <label className="text-xs dark:text-gray-300">{t("ToDate")}</label>
                                        <InputText type={"search"} className={" input w-full"} />
                                    </div>
                                    <ButtonSearchList/>
                                </ListSearch>
                            </div>
                            <div className={"px-2 space-y-5"}>
                                <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5"}>
                                    <div className={"post-input dark:bg-themeDarkPostInput post-input-design overflow-hidden"}>
                                        <div className={"flex h-full"}>
                                            <div className={"flex-shrink-0 bg-themeDanger h-full grid place-content-center w-12"}>
                                                <HPrimeIcon icon={"pi-arrow-right"} iconClass="text-white"/>
                                            </div>
                                            <div className={"flex-1 flex flex-col justify-between"}>
                                                <div className={"flex gap-2 border-b p-1 dark:border-themeDarkColorLine"}>
                                                    <p className={"text-xs dark:text-gray-300"}>{t("TransactionNumber")}:</p>
                                                    <span className={"line-clamp-1 text-xs text-themeInformation dark:text-themeDarkInformation"}>2656565656565656565</span>
                                                </div>
                                                <div className={"flex gap-2 border-b p-1 dark:border-themeDarkColorLine"}>
                                                    <p className={"text-xs dark:text-gray-300"}>{t("TransactionDate")}:</p>
                                                    <span className={"text-xs dark:text-gray-300"}>1400.02.31</span>
                                                </div>
                                                <div className={"flex gap-2 border-b p-1 dark:border-themeDarkColorLine"}>
                                                    <p className={"text-xs dark:text-gray-300"}>{t("TransactionAmount")}:</p>
                                                    <span className={"line-clamp-1 text-xs dark:text-gray-300"}>10.000.000 تومان</span>
                                                </div>
                                                <div className={" flex gap-2 p-1"}>
                                                    <p className={"text-xs dark:text-gray-300"}>{t("TransactionType")}:</p>
                                                    <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{t("Deposit")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"post-input dark:bg-themeDarkPostInput post-input-design overflow-hidden"}>
                                            <div className={"flex h-full"}>
                                                <div className={"flex-shrink-0 bg-themeSuccess h-full grid place-content-center w-12"}>
                                                    <HPrimeIcon icon={"pi-arrow-left"} iconClass="text-white"/>
                                                </div>
                                                <div className={"flex-1 flex flex-col justify-between border dark:border-themeDarkColorLine"}>
                                                    <div className={"flex gap-2 border-b p-1 dark:border-themeDarkColorLine"}>
                                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionNumber")}:</p>
                                                        <span className={"line-clamp-1 text-xs  text-themeInformation dark:text-themeDarkInformation"}>2656565656565656565</span>
                                                    </div>
                                                    <div className={"flex gap-2 border-b p-1 dark:border-themeDarkColorLine"}>
                                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionDate")}:</p>
                                                        <span className={"text-xs dark:text-gray-300"}>1400.02.31</span>
                                                    </div>
                                                    <div className={"flex gap-2 border-b p-1 dark:border-themeDarkColorLine"}>
                                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionAmount")}:</p>
                                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}>10.000.000 تومان</span>
                                                    </div>
                                                    <div className={" flex gap-2 p-1"}>
                                                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionType")}:</p>
                                                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{t("Deposit")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-content__pane ${ activeTab === "five" ? "active" : "" }`}  id="five">

                        {
                            activeTab === 'five'?
                                <PasswordChanger/>
                                :null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}