import React, {useEffect, useMemo, useState} from "react";

import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {GridTable} from "../../../../Commans/Grid/GridTable";
import {ListSearch} from "../../../../Commans/Grid/ListSearch";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {InputNumber} from "primereact/inputnumber";
import {useProductTransport} from "./useProductTransport";
import {ToggleSwitch} from "../../../../Commans/UIParts/ToggleSwitch";
import {handleCreateTransport, handleEditTransport} from "./transportService";
import {GET, POST, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {useProductTransportGrid} from "./useProductTransportGrid";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../../Commans/Grid/Pagination";
import {ToastContainer} from "react-toastify";
import {convertSelectDataHandler} from "../../../../CMS/Contents/ContentService";
import {UploadImage} from "../../../../Commans/Uploaders/UploadImage";
import {Tooltip} from "react-tooltip";
import Select from "react-select";
import {InputSwitchListFilter} from "../../../../Commans/Filters/InputSwitchListFilter";
import {ButtonSearchList} from "../../../../Commans/UIParts/ButtonSearchList";
import {validate} from "../../../../../Services/Globals/validate";
import {productTransportSchema} from "./productTransportSchema";
import {DisplayError} from "../../../../Commans/Errors/DisplayError";
import {useNavigate} from "react-router-dom";
export const HandleAPIPostSetting = () => {
    const { t } = useTranslation();
    const [IsOpenTransport,setIsOpenTransport] = useState(false);
    const redirectTo = useNavigate();
    const [initialSource,setInitialSource]=useState({currencies:[],calculationTypes:[],shippingMethods:[]});
    const[websiteCurrency,setWebsiteCurrency]=useState(0);
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const [IsEdit,setIsEdit]=useState(false);
    const [errors,setErrors]=useState({});
    const[refresh,setRefresh]=useState(false);
    const {setTransport,handleValue,transport,handleNumberValue,handleApplyTransport,handleGetCurrencyType,handleSelectValue}=useProductTransport(setErrors);
    const handleOpenTransport=()=>{
        setIsOpenTransport(true);
        setTransport(prevState => ({...prevState,fixedAmount: 0,transportType:false, calculationType: initialSource.calculationTypes[0],shippingMethod: initialSource.shippingMethods[0]}))
    }
    const handleCloseTransport=()=>{
        setIsOpenTransport(false);
        setIsEdit(false)
        setErrors({});
        setTransport((prevState)=>({...prevState,transportType: false,description: '',fixedAmount: 0,title: '',calculationType: initialSource.calculationTypes[0],shippingMethod: initialSource.shippingMethods[0]}))
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }
    const onSetErrors=errors=>setErrors(errors);

    const handleInitialSource=(responseCurrency,calculationTypes,shippingMethods)=>{
        const currencyData = convertSelectDataHandler(responseCurrency);
        const calculationTypesData = convertSelectDataHandler(calculationTypes);
        const shippingMethodsData = convertSelectDataHandler(shippingMethods);
        setInitialSource({
            currencies: currencyData,
            calculationTypes: calculationTypesData,
            shippingMethods: shippingMethodsData
        })
        return {
            currencyData
        }
    }
    const fetchData = async () => {
        try {
            showSpinner();

            // Create an array of promises for the API calls
            const apiCalls = [
                GET('/Enumerations/EnCurrencyType', {}, 'IEnumerable'),
                GET('/Websites/Currency', {}, 'single'),
                GET('/Enumerations/EnCalculationType', {}, 'IEnumerable'),
                GET('/Enumerations/EnShippingMethod', {}, 'IEnumerable')
            ];
            const [responseCurrency, responseWebsiteCurrency, responseCalculation, responseShipping] = await Promise.all(apiCalls);
            if (responseCurrency.status === 200 && responseWebsiteCurrency.status === 200 && responseCalculation.status === 200 && responseShipping.status === 200) {
                handleInitialSource(responseCurrency.data, responseCalculation.data, responseShipping.data);
                setWebsiteCurrency(responseWebsiteCurrency.data.currencyType);
            } else {
                handleApiError(responseCurrency, hideSpinner);
                handleApiError(responseWebsiteCurrency, hideSpinner);
                handleApiError(responseCalculation, hideSpinner);
                handleApiError(responseShipping, hideSpinner);
            }
            hideSpinner();
        } catch (e) {
            hideSpinner();
        }
    };

    useEffect(()=>{
        fetchData();
    },[])


    const handleSubmitTransport=async(e)=>{
        e.preventDefault();
        const isValid = validate(productTransportSchema,transport,onSetErrors);
        if(!isValid){
            return;
        }
        try {
           if(IsEdit){
               showSpinner();
               const transportData = handleEditTransport(transport);
               const response = await PUT(`/ProductTransports`,transportData,false);
               if(response.status === 200){
                   handleCloseTransport();
                   handleSuccessMessage(t('SuccessDone'));
                   hideSpinner();
                   Refresh();
               }
               else{
                   handleApiError(response,hideSpinner)
               }
           }
           else
           {
               showSpinner();
               const transportData = handleCreateTransport(transport);
               const response = await POST(`/ProductTransports`,transportData,false);
               if(response.status === 200){
                   handleCloseTransport();
                   handleSuccessMessage(t('SuccessDone'));
                   hideSpinner();
                   Refresh();
               }
               else{
                   handleApiError(response,hideSpinner)
               }
           }
        }
        catch (e) {
            hideSpinner();
        }
    }





    const {autoGroupColumnDef,columnDefs,defaultColDef,filter,setFilter,getRowHeight,handleSearch, setSearch,search,handleNextPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount, handleResetFilters,handleToggleFilter, rowData,pagination,SwitchFilters}=useProductTransportGrid(showSpinner,hideSpinner,setIsEdit,setTransport,transport,setIsOpenTransport,Refresh,refresh,initialSource,handleGetCurrencyType,websiteCurrency)

    return(
        <div class="space-y-5">
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                    <div class="space-y-2">
                        <label className="text-xs dark:text-gray-300">{t("SearchByCenter")}</label>
                        <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                    </div>
                    <ButtonSearchList
                        onClickSearch={()=>handleSearch()}
                        onClickReset={()=>handleResetFilters()}
                    />
                </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={() => handleOpenTransport()}
                                        tooltipTitle={t("ShippingMethods")}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                        icon={"settings"}
                                        title={t("settings")}
                                        tooltipTitle={t("SettingsPage")}
                                    />

                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Harmony/Setting/Shop/General`)}
                                        icon={"layers"}
                                        title={t("Public")}
                                        tooltipTitle={t("GeneralSettings")}
                                    />

                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Harmony/Setting/Shop/PaymentPort`)}
                                        icon={"key"}
                                        title={t("Gateways")}
                                        tooltipTitle={t("BankGateway")}
                                    />

                                </>
                            }
                            childrenTwo={
                                <>
                                    <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                                </>
                            }
                />


            </div>

            <div className={"space-y-2 H-body-ag-grid relative"}>
                {
                    IsShowSpinner?
                        <div className="huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridEnglish}
                />
                <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />

            </div>

            <FantasyModal openModal={IsOpenTransport}   closeModal={handleCloseTransport} title={t("ShippingMethods")} customeClassName={"modal-xs"} >
                {/*در حالت دستی عنوان تعیین مبلغ وتوضیحات نمایش داده میشود   وقتی api انتخاب شد آدرس سرویس و  ارزش بیمه و مواردش نمایش داده میشود*/}
            <form onSubmit={handleSubmitTransport} method={'post'}>
                <div className="space-y-3">
                    <div className={"flex flex-col items-center gap-3"}>
                        <div className={"w-full h-36 tooltip_class_icon"}>
                            <UploadImage isEdit={IsEdit}  removeName={'picturePath'} accept={'image/*'} name={'picture'} />
                            <Tooltip anchorSelect={".tooltip_class_icon"} content={t("Icon")} positionStrategy={"fixed"}	 />
                        </div>
                    </div>
                    <div className={"space-y-3"}>
                            <div className="flex items-center gap-2">
                                <div class="can-toggle demo-rebrand-1 text-xs w-32">
                                    <ToggleSwitch tag={'transportType'} customClass={"w-28"} value={transport.transportType} handleToggleSwitchValue={handleApplyTransport} name={'transportType'} checked={t("API")} unchecked={t("manual")} />
                                </div>
                            </div>
                            <div className="space-y-1">
                                <div className={"flex justify-between items-center gap-2"}>
                                    <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                                    <DisplayError message={errors.title}/>
                                </div>
                                <InputText name={'title'} onInput={(e)=>handleValue(e)} value={transport.title} className="input w-full"/>
                            </div>
                            {
                                !transport.transportType ?

                                    <div className="space-y-1">
                                        <label className="text-xs dark:text-gray-300">{t("SetAmount")}</label>
                                        <InputNumber min={0} value={transport.fixedAmount} suffix={handleGetCurrencyType(websiteCurrency,initialSource.currencies)} name={'fixedAmount'} onChange={(e)=>handleNumberValue(e.value,'fixedAmount')}  />
                                    </div>

                                    :null
                            }
                            {
                                transport.transportType?
                                    <div className={"space-y-1"}>
                                        <label className="text-xs dark:text-gray-300">{t("ServiceSelection")}</label>
                                        <Select
                                            options={initialSource.shippingMethods}
                                            isClearable={false}
                                            isSearchable={true}
                                            name={'timeUnit'}
                                            placeholder={t("selection")}
                                            className="w-full"
                                            value={transport.shippingMethod}
                                            onChange={(data)=>handleSelectValue(data,'shippingMethod')}

                                            menuPosition={'fixed'}
                                            classNamePrefix={"h_multi_select"}

                                        />
                                    </div>
                                    :
                                    null
                            }
                            <div className={"space-y-1"}>
                                <label className="text-xs dark:text-gray-300">{'نوع محاسبه'}</label>
                                <Select
                                    options={initialSource.calculationTypes}
                                    isClearable={false}
                                    isSearchable={true}
                                    name={'timeUnit'}
                                    placeholder={t("selection")}
                                    className="w-full"
                                    value={transport.calculationType}
                                    onChange={(data)=>handleSelectValue(data,'calculationType')}

                                    menuPosition={'fixed'}
                                    classNamePrefix={"h_multi_select"}

                                />
                            </div>


                        </div>
                    <div className="space-y-1">
                        <label className="text-xs dark:text-gray-300">{t("description")}</label>
                        <InputText name={'description'} value={transport.description} onInput={(e)=>handleValue(e)} className="input w-full"/>
                    </div>
                    <div>
                        <button type="submit" className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full relative">
                            {t("Register")}
                        </button>
                    </div>
                </div>
            </form>
            </FantasyModal>
            {console.log(transport)}
<ToastContainer/>
        </div>
    )
}