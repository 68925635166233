import Joi from "joi";
export const contentJoiSchema = Joi.object({
    title: Joi.string().required().trim().messages({
        'any.required': 'عنوان مطلب الزامیست',
        'string.empty': 'عنوان مطلب الزامیست',
        'string.base': 'عنوان مطلب الزامیست'
    }),
    slug: Joi.string().required().trim().messages({
        'any.required': 'ادرس مطلب الزامیست',
        'string.empty': 'ادرس مطلب الزامیست',
        'string.base': 'ادرس مطلب الزامیست'
    }),
});
