import React from 'react';

const UsersettingIcon = ({ sizeStyle, fillStyle }) => (
    <svg version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 83.848 83.848"
        xmlSpace="preserve"
        className={`${sizeStyle ? sizeStyle : "size-7"} ${fillStyle ? fillStyle : "fill-black"} dark:fill-black`}
        aria-hidden="true"
        focusable="false"
        role="img"
    >
        <path d="M81.792,72.176l-2.95-12.72c-0.905-0.93-1.97-1.707-3.202-2.342c-6.184-3.168-12.582-5.798-19.051-8.28 c-0.482-0.183-1.061-0.552-1.26-0.974c-0.334-0.737-0.596-1.503-0.844-2.273c-0.414,0.466-0.918,0.849-1.5,1.117l-2.961,1.365 c-0.604,0.277-1.248,0.421-1.912,0.421c-1.785,0-3.42-1.047-4.166-2.666l-0.551-1.195c-1.07,0.127-2.147,0.173-3.228,0.123 L39.71,45.99c-0.423,1.147-1.269,2.062-2.381,2.574c-0.605,0.278-1.25,0.421-1.914,0.421c-0.542,0-1.077-0.097-1.589-0.285 l-3.058-1.129c-0.818-0.303-1.508-0.825-2.027-1.498c-0.196,0.577-0.404,1.148-0.629,1.715c-0.141,0.352-0.492,0.712-0.838,0.87 c-2.003,0.868-4.049,1.675-6.071,2.521c-2.149,0.905-4.322,1.77-6.422,2.767c-2.165,1.038-4.269,2.2-6.387,3.34 c-1.248,0.667-2.414,1.407-3.388,2.324l-2.95,12.564c14.37,0,21.874,10.393,36.826,11.673l-5.509-7.604l8.544-23.369l-2.643-5.677 h5.293l-2.65,5.677l8.545,23.369l-5.366,7.419C55.983,81.842,73.362,72.176,81.792,72.176z"></path>
        <path d="M19.699,19.872l2.418,0.894c-0.19,1.762-0.128,3.561,0.204,5.349l-2.336,1.075c-1.458,0.672-2.098,2.405-1.426,3.862 l1.364,2.962c0.671,1.458,2.404,2.098,3.862,1.426l2.336-1.076c1.143,1.415,2.469,2.631,3.934,3.632l-0.893,2.416 c-0.271,0.729-0.24,1.52,0.086,2.227c0.324,0.706,0.906,1.245,1.636,1.516l3.058,1.129c0.729,0.27,1.52,0.239,2.227-0.086 s1.244-0.908,1.512-1.636l0.893-2.419c1.763,0.19,3.562,0.129,5.349-0.204l1.076,2.336c0.672,1.458,2.404,2.099,3.861,1.427 l2.961-1.364c1.459-0.672,2.099-2.404,1.427-3.863l-1.076-2.336c1.414-1.144,2.63-2.469,3.631-3.932l2.418,0.893 c0.729,0.271,1.521,0.238,2.228-0.088c0.707-0.324,1.244-0.906,1.514-1.635l1.129-3.059c0.557-1.506-0.217-3.183-1.723-3.74 l-2.418-0.893c0.19-1.763,0.129-3.561-0.203-5.349l2.336-1.076c1.458-0.671,2.098-2.404,1.426-3.863l-1.363-2.96 c-0.672-1.458-2.404-2.099-3.861-1.427l-2.338,1.077c-1.143-1.415-2.469-2.631-3.933-3.632l0.894-2.417 c0.556-1.506-0.217-3.185-1.723-3.74l-3.058-1.13c-1.46-0.538-3.202,0.265-3.74,1.723l-0.894,2.418 c-1.762-0.19-3.561-0.128-5.348,0.203l-1.076-2.335c-0.671-1.459-2.405-2.099-3.863-1.427l-2.96,1.363 c-1.458,0.672-2.098,2.405-1.427,3.863l1.077,2.336c-1.414,1.144-2.63,2.47-3.632,3.934l-2.415-0.892 c-1.457-0.542-3.203,0.261-3.742,1.721l-1.129,3.059c-0.269,0.73-0.238,1.521,0.087,2.228 C18.39,19.067,18.972,19.605,19.699,19.872z M36.343,13.628c5.024-2.313,10.973-0.118,13.287,4.906s0.118,10.973-4.906,13.287 c-5.024,2.313-10.973,0.118-13.287-4.906C29.123,21.891,31.319,15.943,36.343,13.628z"></path>
    </svg>
);

export default UsersettingIcon;
