import React, {useState} from "react";

export const useCustomBackup=()=>{

    const [cleanData,setClean] = useState({section:[]})
    // updating sections
    const handleChangeSection = (selectedItem) => {
        setClean((prevState) => {
            const isItemExist = prevState.section.some((item) => item.value === selectedItem.value);
            if (isItemExist) {
                return {
                    ...prevState,
                    section: prevState.section.filter((item) => item.value !== selectedItem.value)
                };
            } else {
                return {
                    ...prevState,
                    section: [...prevState.section, selectedItem]
                };
            }
        });
    };
    // handles domainTokens


    //resets data initially
    const ResetCleanData=()=>{
        setClean({section: []})
    }


    return {
        cleanData,
        setClean,
        handleChangeSection,
        ResetCleanData
    }

}