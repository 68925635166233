import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { GET, POST } from "../../../Services/AxiosService/AxiosApiService";
import { validate } from "../../../Services/Globals/validate";
import { packageSchema } from "../../GeneralManagement/Packages/packageSchema";
import { ticketSchema } from "./ticketSchema";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import { handleSuccessMessage } from "../../../Services/Globals/Errors/NotificationService";
import { DisplayError } from "../../Commans/Errors/DisplayError";
import queryString from "query-string";
import { GetAuthenticatedUser } from "../../../Services/AxiosService/JwtService";
import { InputTextarea } from "primereact/inputtextarea";
import { ToastContainer } from "react-toastify";
import { convertSelectDataHandler } from "../../CMS/Contents/ContentService";
import { GridDateFomartter } from "../../Commans/Grid/GridDateFomartter";
import { BrowserDownloader, DownloadFileHandler } from "../../../ApiServices/Public/FtpController";
import { BasicUrl } from "../../../Services/AxiosService/axiosClient";
import { handleCreateReplyMessage } from "./TicketService";
import { DisplayFiles } from "./DisplayFiles";
import { Tooltip } from "react-tooltip";
import { ListSearch } from "../../Commans/Grid/ListSearch";
import { InputText } from "primereact/inputtext";
import { ButtonSearchList } from "../../Commans/UIParts/ButtonSearchList";
import { ListFilter } from "../../Commans/Grid/ListFilter";
import { ButtonPlus } from "../../Commans/UIParts/ButtonPlus";
import { LoadingHarmony } from "../../Commans/Loadings/LoadingHarmony";
import { GridTable } from "../../Commans/Grid/GridTable";
import { GridSetting } from "../../../Services/Globals/Public/AppSetting";
import { AgGridEnglish } from "../../../Services/Globals/Translations/Resources";
import { Pagination } from "../../Commans/Grid/Pagination";
import { Export } from "../../Commans/Grid/Export";
import { useLocation, useNavigate } from "react-router-dom";
import { HPrimeIconSmall } from "../../Commans/UIParts/HPrimeIconSmall";
import { HPrimeIcon } from "../../Commans/UIParts/HPrimeIcon";
import { TinyLoading } from "../../Commans/Loadings/TinyLoading";

export const Conversation = () => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [result, setResult] = useState(0);
  const [conversations, SetConversations] = useState([]);
  const [sender, setSender] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const [errors, setErrors] = useState({});
  const userId = GetAuthenticatedUser();
  const [ticket, setTicket] = useState({
    message: "",
    ticketId: null,
    concurrencyStamp: null,
    parentId: null,
    topic: null,
    number: null,
    type: null,
    status: null,
    createdOn: null,
    files: null,
  });
  const [IsShow, setIsShow] = useState(false);
  const [data, setData] = useState({ answered: 0, inProgress: 0 });
  const [IsShowSpinner, setIsShowSpinner] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  // hide and show spinner
  const showSpinner = () => {
    setIsShowSpinner(true);
    setRefreshPage(true);
  }
  const hideSpinner = () => {
    setIsShowSpinner(false);
  }
  // hide and show spinner
  const showBtnLoading = () => {
    setIsBtnLoading(true);
  }
  const hideBtnLoading = () => {
    setIsBtnLoading(false);
  }

  const chatContainerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [conversations]);
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };






  const [options, setOptions] = useState({ types: [], states: [] });

  const handleValue = (e) => {
    const { name, value } = e.target;
    setTicket({ ...ticket, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const onSetErrors = (errors) => {
    setErrors(errors);
  };
  const onApiError = () => {
    setSpinner(false);
  };

  const handleGetTicketType = (type) => {
    if (!options.types) {
      return;
    }
    const data = options.types.find((item) => item.value === type);
    return data ? data.label : "";
  };
  const handleGetTicketState = (state) => {
    if (!options.states) {
      return;
    }
    const data = options.states.find((item) => item.value === state);
    return data ? data.label : "";
  };

  const handleFetchData = async () => {
    !refreshPage ? showSpinner() : showBtnLoading();

    const queryParams = queryString.parse(window.location.search);
    try {
      const response = await GET(
        `/Tickets/${queryParams.ticketId}/Logs`,
        {},
        "IEnumerable"
      );
      const responseType = await GET(
        `/Enumerations/EnTicketType`,
        {},
        "IEnumerable"
      );
      const responseState = await GET(
        `/Enumerations/EnTicketStatus`,
        {},
        "IEnumerations"
      );

      if (
        response.status === 200 &&
        responseType.status === 200 &&
        responseState.status === 200
      ) {
        !refreshPage ? hideSpinner() : hideBtnLoading();
        const {
          ticketId,
          concurrencyStamp,
          topic,
          ticketCode,
          logs,
          sender,
          type,
          status,
          createdOn
        } = response.data;
        setTicket({
          ticketId: ticketId,
          concurrencyStamp: concurrencyStamp,
          topic: topic,
          number: ticketCode,
          type: type,
          status: status,
          createdOn: createdOn,
          files: null,
          message: null
        });
        SetConversations(logs);
        setSender(sender);
        const ticketTypes = convertSelectDataHandler(responseType.data);
        const states = convertSelectDataHandler(responseState.data);
        setOptions((prevState) => ({
          ...prevState,
          types: ticketTypes,
          states: states,
        }));
      } else {
        !refreshPage ? hideSpinner() : hideBtnLoading();
        hideSpinner();
        handleApiError(response, onApiError);
      }
    } catch (e) {
      hideSpinner();
    }
  };

  useEffect(() => {
    handleFetchData();
  }, [result]);

  const handleSubmitMessage = async (e) => {
    e.preventDefault();
    try {
      const isValid = validate(ticketSchema, ticket, onSetErrors);
      if (!isValid) {
        return;
      }
      setSpinner(true);
      const formData = handleCreateReplyMessage(ticket)
      const response = await POST(`/Tickets/Reply`, formData, true);
      if (response.status === 200) {
        setSpinner(false);
        setTicket({
          parentId: "",
          message: "",
          ticketId: "",
          concurrencyStamp: "",
          files: null
        });
        setResult({ ...response.status });
        handleSuccessMessage(t("Ticketsuccessfullysubmitted"));
        scrollToBottom();
        handleCancelReply(false)
      } else {
        handleApiError(response, onApiError);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = [...ticket.files];
    updatedFiles.splice(indexToRemove, 1);
    setTicket(prevState => ({ ...prevState, files: updatedFiles }));
  };


  const handleDownloadTicketAttach = async (logId) => {
    const url = `/Tickets/DownloadTicketAttachments?TicketLogId=${logId}`;
    window.location.href = `${BasicUrl.baseURL}${url}`
  }

  const handleChangeFile = (event) => {
    const files = event.target.files;
    const fileArr = Array.from(files);
    setTicket(prevStates => ({ ...prevStates, files: fileArr }));
    event.target.value = '';
  }


  const [reply, setIsReply] = useState({ isActive: false, replyMessage: null, senderId: null, logId: null });

  const handleReplyMessage = (logId, message, senderId, replyId) => {
    setTicket((prevState) => ({ ...prevState, parentId: logId }));
    setIsReply({ isActive: true, replyMessage: message, senderId: senderId, logId: logId });
    scrollToBottom();
  }
  const handleCancelReply = () => {
    setIsReply({ isActive: false, replyMessage: '' });
  }

  const handleShowReplyMessages = (parentId) => {
    const reply = conversations.find(log => log.ticketLogId === parentId);
    if (reply) {
      return (
        <div className="reply-message rounded-e border-s-2 border-themeInformation bg-themeReplayMessageSend p-2">
          <p className="line-clamp-1 text-xs">{reply.message}</p>
        </div>
      );
    } else {
      return null;
    }
  }

  const handleShowReplyRecieveMessages = (parentId) => {
    const reply = conversations.find(log => log.ticketLogId === parentId);
    if (reply) {
      return (
        <div className={"reply-message rounded-s border-e-2 border-themeInformation bg-themeReplayMessageReceived p-2"}>
          <p className={"line-clamp-1 text-xs"}>{reply.message}</p>
        </div>
      );
    } else {
      return null;
    }
  }
  const handleGetAttachNames = (attachments) => {
    var name = '';
    attachments.forEach((item) => { name += item.fileName + ' ' });
    return name;
  };





  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      await handleSubmitMessage(e);
    }
  };


  const handleIsShowOpen = (data) => {
    setIsShow(data);
  }
  const location = useLocation();
  const currentPath = location.pathname;

  const GetCountOfTickets = async () => {
    try {
      const response = await GET(`/Tickets/GetCountOfTickets`, {}, 'single');
      if (response.status === 200) {
        const { answered, inProgress } = response.data;
        setData({ answered: answered, inProgress: inProgress });
      }
    }
    catch (e) {
      console.log(e);
    }
  }


  useEffect(() => {
    GetCountOfTickets();
  }, [currentPath])


  return (
    <>
      <div className={"relative space-y-5"}>
        {
          IsShowSpinner ?
            <div className=" huploader_loading" >
              <div className="relative">
                <LoadingHarmony />
              </div>
            </div>
            :
            null
        }
        <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
          <ListFilter label={null} icon={null}
            childrenOne={
              <>
              </>
            }
            childrenTwo={
              <>

              </>
            }
          />

        </div>
        <div class=" box overflow-hidden rounded relative">
          <div class="flex h-full chat__box flex-col justify-between">
            <div class="border-b-2 p-2 dark:border-themeDarkColorLine ">
              <div className={"post-input flex justify-between gap-10 overflow-x-auto rounded-md p-2 shadow-xl dark:bg-themeDarkPostInput"}>
                <div className={"flex gap-2 "}>
                  <div class="relative hidden md:block h-16 w-16 flex-shrink-0 rounded-md border-e-2 border-themeInformation bg-white shadow-md dark:border-themeDarkInformation">
                    <img className="h-full w-full object-cover" src="/Assets/Image/Design/chat-bg-user.svg" alt={"harmony"} />
                    <div class="absolute top-1 end-1 ">
                      <span class="relative h-2 w-2">
                        <span class="animate-ping absolute h-full w-full rounded-full bg-themeSuccess opacity-75"></span>
                        <span class="relative block rounded-full h-2 w-2 bg-themeSuccess"></span>
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-center gap-y-2">
                    <div className={"flex items-center gap-1"}>
                      <h2 className={"text-nowrap text-xs dark:text-gray-300"}>{t("TicketTitle")}:</h2>
                      <p class={"line-clamp-2 text-xs text-themeInformation dark:text-themeDarkInformation "}>{ticket.topic}</p>
                    </div>
                    <div className={"flex items-center gap-3"}>
                      <div class="flex items-center gap-1">
                        <h2 className={"text-nowrap text-xs dark:text-gray-300"}>{t("TicketNumber")} :</h2>
                        <p className={"text-nowrap-nowrap text-xs text-themeSuccess"}>{ticket.number}</p>
                      </div>

                    </div>
                    <div class="flex items-center gap-1"><h2 className={"text-nowrap text-xs dark:text-gray-300"}>{t("Name")} :</h2>
                      <p className={"text-nowrap text-xs dark:text-gray-300"}>مسعود احمدی</p>
                    </div>

                  </div>
                </div>
                <div className={"flex  flex-shrink-0 flex-col justify-center gap-y-2"}>
                  <div class="flex items-center gap-1"><h2 className={"text-nowrap text-xs dark:text-gray-300"}>{t("TicketDate")} :</h2>
                    <p className={"text-nowrap text-xs dark:text-gray-300"}><GridDateFomartter date={ticket.createdOn} /></p>
                  </div>
                  <div class="flex items-center gap-1">
                    <h2 className={"text-nowrap text-xs dark:text-gray-300"}>
                      {t("Tickettype")}:
                    </h2><p className={"text-nowrap text-xs dark:text-gray-300"}>{handleGetTicketType(ticket.type)}</p>
                  </div>
                  <div class="flex items-center gap-1">
                    <h2 className={"text-nowrap text-xs dark:text-gray-300"}>{t("Ticketstatus")}:</h2>
                    <p className={"text-nowrap text-xs dark:text-gray-300"}>{handleGetTicketState(ticket.status)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={"relative  overflow-hidden"}>
              <div className={"chat-user flex h-screen flex-col gap-y-8 overflow-y-scroll overflow-x-hidden p-2 duration-500 "} ref={chatContainerRef}>
                {conversations.map((item, index) => (
                  <>
                    {
                      item.senderId === userId ? (
                        <>
                          <div id={item.ticketLogId} className="send-message">
                            <div className={"space-y-2"}>
                              <div class="chat__box__text-box flex">
                                <div class="m-2 my-auto hidden h-10 w-10 flex-none">
                                  <img className="h-full w-full rounded-full border object-cover shadow-xl" src={sender.avatar} title={"harmony"} />
                                </div>
                                <div class="post-input send__box__message relative box-border border shadow-xl dark:border-themeDarkColorLine dark:bg-themeDarkPostInput">
                                  <div className={"space-y-2 p-2"}>
                                    {handleShowReplyMessages(item.parentId)}
                                    <div>
                                      <p className="text-xs leading-7 text-gray-700 dark:text-gray-300">{item.message}</p>
                                    </div>
                                    {
                                      item.attachments.length > 0 ?
                                        <div className={"w-max "}>
                                          <button onClick={async () => await handleDownloadTicketAttach(item.ticketLogId)} type="button" className={`button rounded-full bg-themeInformation dark:bg-themeDarkInformation tooltip_class_${item.ticketLogId}`}>
                                            <HPrimeIconSmall icon={"pi-paperclip"} iconClass={"text-white"} />
                                            <Tooltip anchorSelect={`.tooltip_class_${item.ticketLogId}`} content={handleGetAttachNames(item.attachments)} />
                                          </button>
                                        </div> :
                                        null
                                    }
                                  </div>
                                </div>
                                <div className={"reply-send-message m-2 my-auto tooltip_class_reply"}>
                                  <a href={'#reply'} onClick={() => handleReplyMessage(item.ticketLogId, item.message, item.senderId)} className=" arrow-reply-message size-7 block"></a>
                                  <Tooltip anchorSelect={".tooltip_class_reply"} content={t("Replytothismessage")} />
                                </div>
                              </div>
                              <span className={"send-time-message block w-full text-black dark:text-gray-300"}>{<GridDateFomartter hasTime={true} date={item.createdOn} />}</span>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="received-message">
                            <div className={"space-y-2"}>
                              <div class="chat__box__text-box flex">
                                <div className={"received-reply-send-message tooltip_class_received m-2 my-auto"}>
                                  <a href={'#reply'} onClick={() => handleReplyMessage(item.ticketLogId, item.message, item.senderId)} className="block arrow-reply-message size-7"></a>
                                  <Tooltip anchorSelect={".tooltip_class_received"} content={t("Replytothismessage")} />
                                </div>
                                <div class="post-input received__box__message relative box-border border shadow-xl  dark:border-themeDarkColorLine dark:bg-themeDarkSalt700">

                                  <div className={"space-y-2 p-2"}>

                                    {
                                      handleShowReplyRecieveMessages(item.parentId)
                                    }
                                    <div className="">
                                      <p className="text-xs leading-7 text-gray-700 dark:text-gray-300">{item.message}</p>
                                    </div>

                                    {
                                      item.attachments.length > 0 ?
                                        <div className={"w-max "}>
                                          <button onClick={async () => await handleDownloadTicketAttach(item.ticketLogId)} type="button" className="button rounded-full bg-themeInformation dark:bg-themeDarkInformation">
                                            <HPrimeIconSmall icon={"pi-paperclip"} iconClass={"text-white"} />
                                            <Tooltip anchorSelect={`.tooltip_class_${item.ticketLogId}`} content={handleGetAttachNames(item.attachments)} />
                                          </button>
                                        </div> :
                                        null
                                    }
                                  </div>
                                </div>
                                <div class="m-2 my-auto hidden h-10 w-10 flex-none sm:block">
                                  <img className="h-full w-full rounded-full border object-cover shadow-xl shadow-black" src={sender.avatar} title={"harmony"} />
                                </div>
                              </div>
                              <span className={"received-time-message text-black dark:text-gray-300  block w-full"}>{<GridDateFomartter hasTime={true} date={item.createdOn} />}</span>
                            </div>

                          </div>
                        </>
                      )
                    }
                  </>
                ))}

              </div>
              <div id={'reply'} className={`reply-all-message ${reply.isActive === true ? 'show-reply' : ''} items-center bg-white dark:bg-themeDarkSalt700  flex px-2 gap-2`}>
                <button onClick={() => handleCancelReply(false)} type={"button"} className={"flex-shrink-0 "}>
                  <HPrimeIconSmall icon={"pi-times"} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                </button>
                <div className={"flex-1"}>
                  {
                    reply.senderId === userId ?
                      <a href={`#${reply.logId}`} className=" block reply-message rounded-e border-s-2 border-themeInformation dark:border-themeDarkInformation bg-themeReplayMessageSend p-2">
                        <p className="line-clamp-1 text-xs">{reply.replyMessage}</p>
                      </a>
                      :
                      <a href={`#${reply.logId}`} className={"reply-message block rounded-s border-e-2 border-themeInformation bg-themeReplayMessageReceived p-2"}>
                        <p className={"line-clamp-1 text-xs"}>{reply.replyMessage}</p>
                      </a>
                  }
                </div>
              </div>
            </div>
          </div>
          <form method={"post"} onSubmit={handleSubmitMessage}>
            <div class=" border-t-2  dark:border-themeDarkColorLine p-3 bg-themeLightGray ">
              <div className={"space-y-2"}>
                <div className={"w-full flex-1"}>
                  <DisplayError message={errors.message} />
                  <textarea
                      rows={3}
                      onInput={(e) => handleValue(e)}
                      name={"message"}
                      value={ticket.message}
                      cols={30}
                      className={"border-none focus:outline-none w-full bg-transparent"}
                      placeholder={t("Enteryourmessage")}
                      onKeyDown={handleKeyDown}
                  />
                </div>
                <div className={"flex items-center gap-3"}>
                  <label className="cursor-pointer block relative  " htmlFor={'file'}>
                    <HPrimeIcon icon={'pi-paperclip'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                    <input type="file" accept=".jpg, .jpeg, .png, .zip, .rar" multiple={true} onChange={handleChangeFile} className="hidden" id={'file'} />
                  </label>
                  <button disabled={ticket.message === '' || ticket.message === null || isBtnLoading} type={"submit"} className={`relative button w-32 text-center ${ticket.message === null || ticket.message === '' ? 'bg-themeSecondary' : 'bg-themeSuccess'} p-2 text-white dark:bg-themeDarkInformation`}>
                    <HPrimeIcon icon={"pi-send"} />
                    {isBtnLoading &&
                        <TinyLoading
                        />}
                  </button>
                </div>
              </div>
              {/*<textarea onInput={(e)=> handleValue(e)} name={'message'} value={ticket.message} className="chat__box__input input w-full h-16 resize-none border-transparent post-input px-5 py-3 focus:shadow-none" rows="1" placeholder="پیام خود را وارد کنید..."></textarea>*/}
            </div>

          </form>
        </div>
        {
          ticket.files !== null && ticket.files.length > 0 ?

            <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 box bg-white dark:bg-themeDarkSalt700 p-2"}>
              {
                ticket.files !== null && ticket.files.length > 0 ?
                  ticket.files.map((item, index) => (
                    <DisplayFiles key={index} file={item} index={index} handleRemoveFile={handleRemoveFile} />
                  )) : null
              }
            </div> : null

        }
      </div>


      <ToastContainer />
    </>
  );
};
