import React, {useState} from "react";

export const useChangePass=(setErrors)=>{
    const [setting,setSetting]=useState({currentPassword:null,newPassword:null,confirmPassword:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSetting({...setting,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    return {
        handleValue,
        setting,
        setSetting

    }
}