import React, {useEffect} from "react";
import {useState} from "react";


import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Tooltip} from "react-tooltip";
import {useDefaultDashboardWidgets} from "../../../Dashboard/DefaultDashboards/useDefaultDashboardWidgets";
import {useDashboard} from "../../../Dashboard/useDashboard";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import FacebookIcon from "../../../SocialMarketing/Messengers/SocialIcon/FacebookIcon";
import LinkedInIcon from "../../../SocialMarketing/Messengers/SocialIcon/BoldIcon";
import InstagramIcon from "../../../SocialMarketing/Messengers/SocialIcon/InstagramIcon";
import TelegramIcon from "../../../SocialMarketing/Messengers/SocialIcon/TelegramIcon";
import {UserNote} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserNote";
import {UserComment} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserComment";


export const HandleDashboardDigitalMarketing=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();



    return (

        <div className={"relative min-h-screen"}>
            <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                </button>
                <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                    <div className="space-y-10">
                        <div>
                            <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    {t("GeneralReport")}
                                </h2>
                                <Link to="#" className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                    <HPrimeIcon
                                        icon={"pi-sync"}
                                    />
                                    {t("Reload")}
                                </Link>
                            </div>
                            <div className={`grid gap-x-5 gap-y-10 py-3 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        تعداد شبکه های اجتماعی
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        تعداد پیامک ارسالی
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeDarkInformation dark:text-themeInformation text-xl text-center"}>
                                                            10
                                                        </p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                       تعداد ایمیل ارسالی
                                                    </h2>
                                                </div>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        تعداد محتوای سایت
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <div>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>وضعیت شبکه های فعال</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className="max_height_25rem overflow-y-auto scrollbar-hidden p-3 box bg-white dark:bg-themeDarkSalt700">
                                        <div className={"post-input dark:bg-themeDarkPostInput post-input-design"}>
                                            <div className="flex items-center justify-between border-b border-themeDarkColorLine">
                                                <div className={"max_width75 border-e border-themeDarkColorLine p-2"}>
                                                    <p className="line-clamp-1 dark:text-gray-300">نام سوشال</p>
                                                </div>
                                                <div className="text-center max_width25 border-e border-themeDarkColorLine p-2">
                                                    <p className="dark:text-gray-300">دنبال کننده</p>
                                                </div>
                                                <div className="text-center max_width25 p-2">
                                                    <p className="dark:text-gray-300">دنبال شونده</p>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between border-b border-themeDarkColorLine">
                                                <div className={"max_width75 border-e border-themeDarkColorLine p-2"}>
                                                    <Link to={"#"} className="text-themeInformation line-clamp-1 text-xs dark:text-themeDarkInformation">harmonyCms</Link>
                                                </div>
                                                <div className="text-center max_width25 border-e border-themeDarkColorLine p-2">
                                                    <p className="dark:text-gray-300">1000</p>
                                                </div>
                                                <div className="text-center max_width25 p-2">
                                                    <p className="dark:text-gray-300">100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>آخرین پست ها</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className="max_height_25rem overflow-y-auto scrollbar-hidden p-3 box bg-white dark:bg-themeDarkSalt700">
                                        <div className={"post-input post-input-design dark:bg-themeDarkPostInput"}>
                                            <div className="flex items-center justify-between border-b border-themeDarkColorLine">
                                                <div className={"max_width75 border-e border-themeDarkColorLine p-2"}>
                                                    <p className="line-clamp-1 dark:text-gray-300">پست</p>
                                                </div>
                                                <div className="text-center max_width20 border-e border-themeDarkColorLine p-2">
                                                    <p className="dark:text-gray-300">بازدید</p>
                                                </div>
                                                <div className="text-center max_width20 p-2">
                                                    <p className="dark:text-gray-300">تعداد لایک</p>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between border-b border-themeDarkColorLine">
                                                <div className={"max_width75 border-e border-themeDarkColorLine p-2"}>
                                                    <Link to={"#"} className="text-themeInformation line-clamp-1 text-xs dark:text-themeDarkInformation ">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores culpa cupiditate dolor doloremque magnam. Beatae blanditiis cum delectus et hic, maiores natus, nobis nostrum numquam, officia praesentium reiciendis repellat repudiandae?</Link>
                                                </div>
                                                <div className="text-center max_width20 border-e border-themeDarkColorLine p-2">
                                                    <p className="dark:text-gray-300">1000</p>
                                                </div>
                                                <div className="text-center max_width20 p-2">
                                                    <p className="dark:text-gray-300">100</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={"space-y-3"}>
                            <h2 className="text-lg dark:text-gray-300 -intro-x">
                                <h2>صف انتشار</h2>
                            </h2>
                            <div>
                                <div class="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 box bg-white dark:bg-themeDarkSalt700 p-3 max-h-screen overflow-y-auto scrollbar">
                                    <div className={"flex flex-col justify-between post-input dark:bg-themeDarkPostInput messenger_PublicationList overflow-hidden post-input-design"}>
                                        <div className={"H_aspect_15 bg-white dark:bg-themeDarkSalt700"}>
                                            <img src="/Assets/Image/Avatar/man.svg" alt="" className={"absolute inset-0 size-full"}/>
                                            <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                                                <HPrimeIconSmall icon={"pi-file-edit"}/>
                                                {t("Text")}
                                            </div>
                                            <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                                                <HPrimeIconSmall icon={"pi-image"}/>
                                                {t("Photo")}
                                            </div>
                                            <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                                                <HPrimeIconSmall icon={"pi-video"}/>
                                                {t("Video")}
                                            </div>
                                            <div className={"text-white dark:text-gray-300 messenger_PublicationList_type"}>
                                                <HPrimeIconSmall icon={"pi-images"}/>
                                                {t("Album")}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-5 p-3 ">
                                            <div class=" flex items-center">
                                                <h2 className={"text-xs line-clamp-1 dark:text-gray-300"}>لورم ایپسوم متن ساختگی لورم لورم ایپسوم متن ساختگی</h2>
                                            </div>
                                            <div className={"space-y-2"}>
                                                <div className={"flex justify-between items-center"}>
                                                    <h2 className={"text-xs dark:text-gray-300"}>{t("TimingFor")}</h2>
                                                    <h2 className={"text-xs dark:text-gray-300 font-light"}>18 تیر 1403 11:15</h2>
                                                </div>
                                                <div className={"flex justify-between items-center"}>
                                                    <h2 className={"text-xs dark:text-gray-300 "}>{t("CreationDate")}</h2>
                                                    <h2 className={"text-xs dark:text-gray-300 font-light"}>18 تیر 1403 11:15</h2>
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap gap-2 justify-center"}>
                                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                                    <p className={"text-themeSuccess flex items-center gap-1"}>
                                                        <HPrimeIcon icon={"check"} size={15}/>
                                                        {t("Published")}
                                                    </p>
                                                </div>
                                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation flex items-center gap-1"}>
                                                        <HPrimeIconSmall
                                                            icon={"pi-check"}
                                                        />

                                                        {t("Scheduled")}
                                                    </p>
                                                </div>
                                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation flex items-center gap-1"}>
                                                        <HPrimeIconSmall
                                                            icon={"pi-arrow-right-arrow-left"}
                                                        />
                                                        {t("Repeating")}
                                                    </p>
                                                </div>
                                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                                    <p className={"text-themeSecondary flex items-center gap-1"}>
                                                        <HPrimeIconSmall
                                                            icon={"pi-pause-circle"}
                                                        />
                                                        {t("Unpublished")}
                                                    </p>
                                                </div>
                                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation flex items-center gap-1"}>
                                                        <HPrimeIconSmall
                                                            icon={"pi-hourglass"}
                                                        />
                                                        {t("AwaitingConfirmation")}
                                                    </p>
                                                </div>
                                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                                    <p className={"text-themeDanger flex items-center gap-1"}>
                                                        <HPrimeIconSmall
                                                            icon={"pi-times-circle"}
                                                        />
                                                        {t("Canceled")}
                                                    </p>
                                                </div>
                                                <div className={"bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine rounded p-1 text-xs"}>
                                                    <p className={"text-themeInformation dark:text-themeDarkInformation flex items-center gap-1"}>
                                                        <HPrimeIconSmall
                                                            icon={"pi-refresh"}
                                                        />
                                                        {t("PublishingContent")}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={"flex flex-wrap gap-2 justify-center"}>
                                                <TelegramIcon
                                                    sizeStyle={"size-8"}
                                                />
                                                <InstagramIcon
                                                    sizeStyle={"size-8"}
                                                />
                                                <LinkedInIcon
                                                    sizeStyle={"size-8"}
                                                />
                                                <FacebookIcon
                                                    sizeStyle={"size-8"}
                                                />
                                            </div>
                                        </div>
                                        <div class=" w-full flex items-center p-1 gap-1 bgwhite dark:bg-themeDarkPostInput">
                                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded  zoom-in tooltip_class_edit"}>
                                                <HPrimeIconSmall
                                                    icon={"pi-pen-to-square"}
                                                />
                                                <Tooltip anchorSelect={".tooltip_class_edit"} content={t("Edit")} positionStrategy={"fixed"}/>
                                            </button>
                                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded  zoom-in tooltip_class_comment"}>
                                                <HPrimeIconSmall
                                                    icon={"pi-comments"}
                                                />
                                                <Tooltip anchorSelect={".tooltip_class_comment"} content={t("comments")} positionStrategy={"fixed"}/>
                                            </button>
                                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded zoom-in tooltip_class_again"}>
                                                <HPrimeIconSmall
                                                    icon={"pi-arrow-right-arrow-left"}
                                                />
                                                <Tooltip anchorSelect={".tooltip_class_again"} content={t("Resend")} positionStrategy={"fixed"}/>
                                            </button>
                                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded  zoom-in tooltip_class_statistics"}>
                                                <HPrimeIconSmall icon={"pi-chart-line\n"}/>
                                                <Tooltip anchorSelect={".tooltip_class_statistics"} content={t("StatisticsAnalysis")} positionStrategy={"fixed"}/>
                                            </button>

                                            <button className={"flex-1 button grid place-content-center text-white bg-themeInformation dark:bg-themeDarkInformation rounded zoom-in tooltip_class_cancel"}>
                                                <HPrimeIconSmall icon={"pi-times-circle"}/>
                                                <Tooltip anchorSelect={".tooltip_class_cancel"} content={"Canceled"} positionStrategy={"fixed"}/>
                                            </button>
                                            <button className={"flex-1 button grid place-content-center bg-themeInformation dark:bg-themeDarkInformation text-white rounded zoom-in tooltip_class_delete"}>
                                                <HPrimeIconSmall icon={"pi-trash"}/>
                                                <Tooltip anchorSelect={".tooltip_class_delete"} content={t("DeleteAccount")} positionStrategy={"fixed"}/>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("Notes")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            {
                                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                    <UserNote
                                        key={index}
                                        fullName={item.fullName}
                                        id={item.id}
                                        message={item.message}
                                        createdAt={item.createdAt}
                                    />
                                ))
                            }
                            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("UserComments")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 dashboard-note height_21rem overflow-y-auto scrollbar-hidden "}>
                                {
                                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                        <UserComment
                                            key={index}
                                            fullName={item.fullName}
                                            id={item.id}
                                            message={item.message}
                                            createdAt={item.createdAt}
                                            state={item.state}
                                        />
                                    ))
                                }
                            </div>
                            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center ">
                            <h2 className="text-lg dark:text-gray-300">
                                {t("ReleaseQueue")}
                                <span className={"text-xs dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                    <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                    </div>
                                    <div class="flex-1">
                                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("Draft")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}