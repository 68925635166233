export const handleGetWithdraw=(obj)=>{

    const data = {
        amount:obj.amount === null || obj.amount === undefined?0:obj.amount,
        description:obj.description === null || obj.description === undefined?null:obj.description,
        userBankId:obj.userBankId === null || obj.userBankId === undefined?null:obj.userBankId.value
    }
    return data;

}

export const handleGetUserBanks = (data)=>{
    const options  = data.map(item => ({label: item.value, value: item.key,isDefault:item.isDefault}));
    return options;
}

export const handleGetDefaultBank = (userBanks)=>{
    const defaultBank = userBanks.find((item)=>item.isDefault === true);
    return defaultBank;
}