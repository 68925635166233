import React, {useState} from "react";

export const usePurchaseTemplate=()=>{
    const [upgrade,setUpdate]=useState({currencyType:null,extraTemplates:{templateMasterIds:[]}});
    const handleSelectValue=(data,name)=>{
        setUpdate((prevState)=>({...prevState,[name]:data}));
    }
    return {
        setUpdate,
        upgrade,
        handleSelectValue
    }
}