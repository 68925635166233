import React, {useEffect, useMemo, useState} from "react";

import {GridTable} from "../../../Commans/Grid/GridTable";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTransactionGrid} from "./useTransactionGrid";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../../Commans/Grid/Pagination";


export const HandleListTransactionsBank=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsShowSpinner,setShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({currencies:[],transactionStates:[],paymentStates:[]});



    const handleInitialSource=(currencies,paymentStatuses,transactionStatuses)=>{
        const currencyData = convertSelectDataHandler(currencies);
        const paymentStateData = convertSelectDataHandler(paymentStatuses);
        const transactionStatusData = convertSelectDataHandler(transactionStatuses);
        setInitialSource({
            currencies: currencyData,
            paymentStates:paymentStateData,
            transactionStates: transactionStatusData
        })
    }
    const showSpinner=()=>{
        setShowSpinner(true);
    }
    const hideSpinner=()=>{
        setShowSpinner(false);
    }


    const fetchData=async()=>{
        try {
            showSpinner()
            const responseCurrency = await GET(`/Enumerations/EnCurrencyType`,{},'IEnumerable');
            const responsePaymentState = await GET(`/Enumerations/EnPaymentStatus`,{},'IEnumerable');
            const responseTransaction = await GET(`/Enumerations/EnTransactionState`,{},'IEnumerable');
            if( responseCurrency.status === 200 && responsePaymentState.status === 200 && responseTransaction.status === 200){
                handleInitialSource(responseCurrency.data,responsePaymentState.data,responseTransaction.data);
                hideSpinner()
            }
            else{
                handleApiError(responseCurrency,hideSpinner);
                handleApiError(responsePaymentState,hideSpinner);
                handleApiError(responseTransaction,hideSpinner);
            }
        }
        catch (e) {
            console.log(e);
            hideSpinner()
        }
    }

    useEffect(()=>{
        fetchData();
    },[])



    const {pagination,autoGroupColumnDef,columnDefs,defaultColDef,filter,setFilter,getRowHeight,handleFromDate,handleToDate,handleSearch,handleNextPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount,handleResetFilters, handleSelectFilter,handleToggleFilter,SwitchFilters,setSearch,search,rowData} = useTransactionGrid(showSpinner,hideSpinner,initialSource)

    return(
        <>
            <div class=" space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    {/*<ListSearch label={null} icon={null} >*/}
                    {/*        <div class="space-y-2">*/}
                    {/*            <label className="text-xs dark:text-gray-300">{t("search")}</label>*/}
                    {/*            <InputText type={"text"} className={" input w-full"} />*/}
                    {/*        </div>*/}
                    {/*    <ButtonSearchList*/}


                    {/*    />*/}
                    {/*</ListSearch>*/}
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/Wallet`)}
                                            icon={"pi-credit-card"}
                                            title={t("Wallet")}
                                            tooltipTitle={t("Wallet")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CRM/User/WithdrawalRequest`)}
                                            icon={"pi-arrow-right-arrow-left"}
                                            title={t("Withdrawal")}
                                            tooltipTitle={t("WithdrawalRequest")}
                                        />

                                    </>
                                }

                    />

                </div>
                <div className={"space-y-2 H-body-ag-grid relative"} >
                    {
                        IsShowSpinner?
                            <div className=" huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
            </div>
        </>

    )
}