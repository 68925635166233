import React, {useEffect, useState} from "react";
import {
    handleWarningMessage
} from "../../../Services/Globals/Errors/NotificationService";
import {TabMenu} from "../../Commans/UIParts/TabMenu";
import {InputText} from "primereact/inputtext";
import {TagsInput} from "react-tag-input-component";
import {InputTextarea} from "primereact/inputtextarea";
import {TextEditor} from "../../Commans/UIParts/TextEditor";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";

import queryString from "query-string";
import {useNavigate} from "react-router-dom";
import {
    convertSlugHandler,
    handleCreateContentDetailFormData
} from "./ContentService";
import {ToastContainer} from "react-toastify";
import {contentJoiSchema} from "./ContentAdd/ContentSchema";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";

import {useTranslation} from "react-i18next";
import {GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../Services/Globals/validate";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";

import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import ButtonVideo from "../../Commans/UIParts/ButtonVideo";

import {useSetting} from "../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
import {TinyEditor} from "../../Commans/UIParts/TinyEditor";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
export const HandleDetailContent=()=>{
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("tab_one");
    const redirectTo = useNavigate();
    const [spinner,setSpinner] = useState(false);
    const [errors, setErrors] = useState({});

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();


    const handleTabClick = (tabId) => {
        if (tabId === "gallery" || tabId === "attached") {
            handleWarningMessage(t("Presstheuploadbuttonafterselectingthefile"));
        }
        setActiveTab(tabId);
    };
    //........
    // اطلاعات tabs ها رو برای کامپوننت TabMenu فراهم میکنیم
    const tabs = [
        {
            id: "tab_one",
            label: t("Main"),
            icon: "file-text",
        },
        {
            id: "tab_two",
            label: t("settings"),
            icon: "settings",
        },
    ];

    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors=(error)=>{
        setErrors(error);
    }



    //..........
    const handleKeywordsChange = (keywords) => {
        SetContentGroupDetails((prevContent) => ({ ...prevContent, seo_Keywords: keywords }));
    };
    const normalValueHandler =(data,name)=>{
        SetContentGroupDetails((prevContent) => ({ ...prevContent, [name]: data }));
    }
    const InputSwitchValueHandler = (data,name)=>{
        SetContentGroupDetails((prevState) => ({
            ...prevState,
            [name]: data,
        }))
    }


    const handleValue = (e) => {
        const { name, value } = e.target;
        if(contentGroupDetails.id === null){
            if (name === "title") {
                handleSlugValue(e); // Update slug value when title changes
                SetContentGroupDetails((prevState) => ({ ...prevState, seoTitle: value }));
            }
            SetContentGroupDetails((prevContent) => ({ ...prevContent, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }else{
            SetContentGroupDetails((prevContent) => ({ ...prevContent, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }

    };

    const handleSlugValue = (e) => {
        const { value } = e.target;
        const slugValue = convertSlugHandler(value);
        SetContentGroupDetails((prevContent) => ({ ...prevContent, slug: slugValue }));
    };
    const[oldSlug,setOldSlug] = useState('');
    const handleCheckContenGrouptSlug = async (e) => {
        const { name, value } = e.target;
        try {
            if (name === "title") {
                const slug = contentGroupDetails.slug;
                const response = await GET('/Public/SlugValidation',{NewSlug:slug,OldSlug:oldSlug},'single')
                if(response.status === 200){
                    return;
                }
                handleApiError(response,onApiError);
            }
            else{
                const response = await GET('/Public/SlugValidation',{NewSlug:value,OldSlug:oldSlug},'single')
                if(response.status === 200){
                    return;
                }
                handleApiError(response,onApiError);
            }
        }catch (e) {
            redirectTo('/')
        }
    };

    const [contentGroupDetails,SetContentGroupDetails] = useState({
        id:null,
        title: null,
        slug: null,
        excerpt: null,
        editor: null,
        seoTitle: null,
        seoKeywords: [],
        seoDescription:null,
        image:null,
        banner:null,
        icon:null,
        stateShow:false,
        type:null,
        parentId:null,
        order:0,
        imagePath:null,
        bannerPath:null,
        iconPath:null,
        stateGoogle:false
    })

    const handleFetchData = async()=>{
        setSpinner(true)
        const queryParams  = queryString.parse(window.location.search);
        const response = await GET(`/ContentGroups/${queryParams.contentGroupId}`,{},'single');
        try {
            if(response.status === 200){
                setOldSlug(response.data.slug);
                SetContentGroupDetails({...contentGroupDetails,
                    seoDescription: response.data.SeoDescription,
                    id: response.data.id,
                    title:response.data.title,
                    slug:response.data.slug,
                    excerpt:response.data.excerpt,
                    editor:response.data.editor,
                    seoTitle: response.data.seoTitle,
                    seoKeywords: response.data.seoKeywords??[],
                    image: response.data.image,
                    banner:response.data.banner,
                    icon:response.data.icon,
                    stateShow: response.data.stateShow,
                    order:response.data.order,
                    type:response.data.type,
                    parentId: response.data.parentId,
                    stateGoogle: response.data.stateGoogle??false
                })
                setSpinner(false)
            }
            else{
                handleApiError(response,onApiError);
            }
        }catch (e) {
            console.log(e)
        }
    }
    useEffect(()=>{
        handleFetchData();
    },[])



const handleContentGroupDetailSubmitHandler =async (e)=>{
  e.preventDefault();
  setSpinner(true)
  var isValid = validate(contentJoiSchema,contentGroupDetails,onSetErrors);
  if(!isValid)return;
try {

    const formData = handleCreateContentDetailFormData(contentGroupDetails);
    const response = await PUT('/ContentGroups/EditContentGroupDetail',formData,false);
    if(response.status === 200){
        setSpinner(false)
        redirectTo(`/CMS/Content/Group?isEdit=${true}`);
    }
    else{
        handleApiError(response,onApiError)
    }
}catch (e) {
    console.log(e)
}

}

    const removeFile=(path,name)=>SetContentGroupDetails((prevState)=>({...prevState,[name]:path}))


    const resetImages = () => {
        SetContentGroupDetails((prevState)=>({...prevState,image:null,imagePath: null,icon: null,iconPath: null,banner: null,bannerPath: null}));
    }

    const handleChangeFile =(data,name)=>{
        SetContentGroupDetails((prevContent) => ({ ...prevContent, [name]: data }));
    }


    return(
        <>
            <div className={"space-y-5"}>
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => redirectTo("/CMS/Content/Add")}
                                            tooltipTitle={t("InsertingInformation")}
                                            title={t("insertion")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/Content/Group`)}
                                            icon={"pi-objects-column"}
                                            title={t("grouping")}
                                            tooltipTitle={t("grouping")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/MetaData/Add`)}
                                            icon={"pi-star"}
                                            title={t("feature")}

                                            tooltipTitle={t("CreatingAFeature")}
                                        />

                                        <ButtonPlus
                                            onClick={() => redirectTo("/CMS/Advertise/List")}
                                            tooltipTitle={t("ListOfInformation")}
                                            title={t("list")}
                                            icon={"pi-list"}
                                        />

                                    </>
                                }
                    />


                </div>
                <form method={'post'} onSubmit={handleContentGroupDetailSubmitHandler} className="space-y-5">
                    <div class="grid grid-cols-12 gap-5 relative">

                        {

                            spinner?
                                <div className=" huploader_loading" >
                                    <div className="relative">
                                        <LoadingHarmony/>
                                    </div>
                                </div>
                                :
                                null
                        }


                        <div class="col-span-12 lg:col-span-8">
                            <div class="grid grid-cols-1 gap-3 md:grid-cols-2 ">
                                <div className="float-input p-float-label relative">
                                    <InputText onBlur={handleCheckContenGrouptSlug} name={'title'} value={contentGroupDetails.title} onInput={(e)=>handleValue(e)} type="text" className="input w-full input-float-label" />
                                    <label className={"text-xs dark:text-gray-300"}>
                                        {t("WebsiteTitle")}
                                        <RequiredIcon/>
                                    </label>
                                    <div className={"absolute end-2 -top-2"}>
                                        <DisplayError
                                            message={errors.title}
                                        />
                                    </div>

                                </div>
                                <div className="float-input p-float-label relative">
                                    <InputText onBlur={handleCheckContenGrouptSlug} name={'slug'} value={contentGroupDetails.slug} onInput={(e)=>handleValue(e)} type="text" className="input w-full input-float-label" />
                                    <label className={"text-xs dark:text-gray-300"}>
                                        {t("ArticleAddress")}
                                        <RequiredIcon/>

                                    </label>
                                    <div className={"absolute end-2 -top-2"}>
                                        <DisplayError
                                            message={errors.slug}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="post mt-5 ">
                                <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                                <div class="post__content tab-content box bg-white dark:bg-themeDarkSalt700 p-2">
                                    <div className={`tab-content__pane ${ activeTab === "tab_one" ? "active" : "" }`} id="tab_one">
                                        <div className="space-y-5 ">
                                            <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                                                <label className="dark:text-gray-300 text-xs">
                                                    {t("TextSummary")}
                                                </label>
                                                <InputTextarea onInput={(e)=>handleValue(e)} name={"excerpt"} value={contentGroupDetails.excerpt}  rows={3} cols={30} className={"input w-full border "}/>
                                            </div>
                                            <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                                                <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                                                    <SettingButton
                                                        onClick={()=>handleSetting('GeneralSetting')}
                                                        tooltipTitle={t("EditorType")}
                                                    />
                                                    {t("FullText")}
                                                </label>
                                                <div className={"custom-editor-class"}>
                                                    <TinyEditor content={contentGroupDetails.editor} name={'editor'} handleEditorContent={normalValueHandler}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`tab-content__pane space-y-5 ${ activeTab === "tab_two" ? "active" : ""}`} id="tab_two">
                                        <div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                                            <label className="dark:text-gray-300 text-xs">{t("WindowTitle")}</label>
                                            <InputText name={'seoTitle'}  value={contentGroupDetails.seoTitle} onInput={(e)=>handleValue(e)}  type={"text"} className={"input w-full"} />
                                        </div>
                                        <div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                                            <label className="dark:text-gray-300 text-xs">{t("Keywords")}</label>
                                            <TagsInput value={contentGroupDetails.seoKeywords} onChange={handleKeywordsChange}   name={"seoKeywords"} placeHolder={t("Keywords")}/>
                                        </div>
                                        <div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">
                                            <label className="dark:text-gray-300 text-xs">{t("description")}</label>
                                            <InputText name={"SeoDescription"} onInput={(e)=>handleValue(e)} value={contentGroupDetails.seoDescription}  type={"text"} className={"input w-full"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-span-12 lg:col-span-4">
                            <div class="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                                <div class="space-y-2 post-input p-2 dark:bg-themeDarkPostInput post-input-design">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("WatermarkText")}
                                        />
                                        {t("Image")}
                                    </label>
                                    <div class="h-36 w-full ">
                                        {/*<UploadImage  OnValueSelection={normalValueHandler} accept={'image/*'}  value={contentGroupDetails.image ? contentGroupDetails.image : null} name={'image'} />*/}
                                        <UploadImage isEdit={true} removeFile={removeFile} removeName={'imagePath'} accept={'image/*'} resetImages={resetImages} OnValueSelection={handleChangeFile} value={contentGroupDetails.image ? contentGroupDetails.image:null} name={'image'} />
                                    </div>
                                </div>
                                <div class="space-y-2 post-input p-2 dark:bg-themeDarkPostInput post-input-design">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("WatermarkText")}
                                        />
                                        {t("Banner")}
                                    </label>
                                    <div class="h-36 w-full ">
                                        {/*<UploadImage OnValueSelection={normalValueHandler} accept={'image/*'} value={contentGroupDetails.banner ? contentGroupDetails.banner:null} name={'banner'} />*/}
                                        <UploadImage isEdit={true} removeFile={removeFile} removeName={'bannerPath'} accept={'image/*'} resetImages={resetImages} OnValueSelection={handleChangeFile} value={contentGroupDetails.banner ? contentGroupDetails.banner:null} name={'banner'} />

                                    </div>
                                </div>
                                <div class="space-y-2 post-input p-2 dark:bg-themeDarkPostInput post-input-design">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("WatermarkText")}
                                        />
                                        {t("Icon")}
                                    </label>
                                    <div class="h-36 w-full">
                                        {/*<UploadImage OnValueSelection={normalValueHandler} accept={'image/*'} value={contentGroupDetails.icon ? contentGroupDetails.icon:null} name={'icon'} />*/}
                                        <UploadImage isEdit={true} removeFile={removeFile} removeName={'iconPath'} accept={'image/*'} resetImages={resetImages} OnValueSelection={handleChangeFile} value={contentGroupDetails.icon ? contentGroupDetails.icon:null} name={'icon'} />

                                    </div>
                                </div>
                                <div class="grid grid-cols-2 post-input dark:bg-themeDarkPostInput p-3 post-input-design">
                                    <div>
                                        <InputSwitch value={contentGroupDetails.stateShow} OnValueSelection={InputSwitchValueHandler} lable={t("DisplayStatus")} name={'stateShow'}/>
                                    </div>
                                    <div>
                                        <InputSwitch value={contentGroupDetails.stateGoogle} OnValueSelection={InputSwitchValueHandler} lable={t("stateGoogle")} name={'stateGoogle'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ButtonSubmitAndCancel
                        onCancelClick={()=>redirectTo('/CMS/Content/Add')}
                    />
                    {/*<div class="flex justify-center lg:justify-end gap-3">*/}
                    {/*    <button onClick={()=>redirectTo('/CMS/Content/Add')} className="button text-white bg-themeDanger w-24">{t("Cancel")}</button>*/}
                    {/*    <button  type={'submit'} className="button text-white bg-themeInformation dark:bg-themeDarkInformation w-24">{t("register")}</button>*/}
                    {/*</div>*/}
                </form>
            </div>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }
                </div>
            </FantasyModal>
            <ToastContainer/>
        </>

    )
}


