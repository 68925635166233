import React, {useEffect, useState} from "react";
// import '../../../../Styles/Internal/setting.css'

import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {useNavigate} from "react-router-dom";
import {GET, POST} from "../../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler, handleGetSettingValue} from "../../../../CMS/Contents/ContentService";
import {useRoutingOption} from "./useRoutingOption";
import {ToastContainer} from "react-toastify";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {RoutingOption} from "./RoutingOption";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";

export const HandleRoutingSetting = () => {
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [refresh,setRefresh]=useState(false);
    const {handleSelectValue,setting,setSetting}=useRoutingOption()
    const[initialSource,setInitialSource]=useState({
        routingOptions:[]
    });
    const [IsShowSpinner,setIsShowSpinner]=useState(false);
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const Refresh=()=>{
        setRefresh((prevState)=>!prevState);
    }

    const handleInitialDataSource=(routingOptions)=> {
        const routingOptionsData = convertSelectDataHandler(routingOptions);
        setInitialSource({
            routingOptions: routingOptionsData,
        })
        return {
            routingOptionsData,
        }
    }

    const fetchData = async () => {
        try {
            showSpinner();
            const responseRoutingOptions = await GET(`/Enumerations/EnRoutingOptions`, {}, 'IEnumerable');
            const responseSettings = await GET(`/Settings?Name=${'RoutingOption'}`,{},'IEnumerable');
            if (responseRoutingOptions.status === 200 && responseSettings.status === 200 ) {
                handleInitialDataSource(responseRoutingOptions.data);
                setSetting({
                    value:handleGetSettingValue('RoutingOption',responseSettings.data.settings)
                })
                hideSpinner();
            } else {
                hideSpinner();
            }
        } catch (e) {
            hideSpinner();
        }
    }

    useEffect(()=>{
        fetchData()
    },[refresh])


    const handleSubmit=async (e)=>{
        e.preventDefault();
        try {
            showSpinner();
            const response = await POST(`/Settings`,{settings:[{name:'RoutingOption',value:setting.value}]},false);
            if(response.status === 200){
                hideSpinner();
                handleSuccessMessage(t('SuccessDone'))
                Refresh();
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }




    return (

        <>
            <div class="space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                    <ListFilter label={null} icon={null}
                                childrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Dashboard`)}
                                            icon={"pi-user"}
                                            title={t("Personal")}
                                            tooltipTitle={t("PersonalSettings")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/General`)}
                                            icon={"pi-objects-column"}
                                            title={t("Public")}
                                            tooltipTitle={t("GeneralSettings")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/CleanTemp`)}
                                            icon={"pi-trash"}
                                            title={t("DataCleanup")}
                                            tooltipTitle={t("TemporaryDataCleanup")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/Harmony/Setting/Main/Pictures`)}
                                            icon={"pi-image"}
                                            title={t("Images")}
                                            tooltipTitle={t("ImageSettings")}
                                        />
                                    </>
                                }

                    />
                </div>
                <form onSubmit={handleSubmit} method={'post'} className={"relative"}>
                    {
                        IsShowSpinner?
                            <div className="huploader_loading" >
                                <div className="relative">
                                    <LoadingHarmony/>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className={"space-y-5"}>
                        <div class="box bg-white dark:bg-themeDarkSalt700 p-2 space-y-3">
                            <div className="p-2 bg-themeLightGray post-input-design-x dark:bg-themeDarkPostInput border-themeInformation dark:border-themeDarkInformation">
                                <h2 className={"dark:text-gray-300 text-xs"}>{t("Routing")}</h2>
                            </div>
                            <div className={"px-2"}>
                                <div className="p-2 space-y-5 post-input dark:bg-themeDarkPostInput post-input-design">
                                    {initialSource.routingOptions.map(option => (
                                        <RoutingOption
                                            key={option.value}
                                            option={option}
                                            settingValue={setting.value}
                                            handleSelectValue={handleSelectValue}
                                        />
                                    ))}
                                </div>
                            </div>

                        </div>
                        <ButtonSubmitAndCancel
                            onCancelClick={()=>redirectTo('/Harmony/Master/setting')}
                        />
                    </div>

                </form>
            </div>
            <ToastContainer/>
        </>


    )
}
