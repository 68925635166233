import React, {useEffect, useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";

import SortableTree, {
    addNodeUnderParent,
    toggleExpandedForAll,
} from '@nosferatu500/react-sortable-tree';
import '../../../../Styles/dist/css/Treejs.css'
import {
    convertContentGroup,
    convertSelectDataHandler,
    convertSlugHandler,
    convertToTree
} from "../ContentService";
import {useNavigate} from "react-router-dom";
import { handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {contentGroupSchema} from "../contentGroupSchema";
import {ToastContainer} from "react-toastify";
import Select from "react-select";

import {InputNumber} from "primereact/inputnumber";
import Swal from "sweetalert2";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import queryString from "query-string";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../Commans/Grid/ListFilter";

import {Return} from "../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {DELETE, GET, POST, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {AlertSetting} from "../../../../Services/Globals/Public/AppSetting";
import {Tooltip} from "react-tooltip";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {HPrimeIconSmall} from "../../../Commans/UIParts/HPrimeIconSmall";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";



export const HandleContentGroup=()=>{



    const { t } = useTranslation();
    const [searchString, setSearchString] = useState("");
    const [searchFocusIndex, setSearchFocusIndex] = useState(0);
    const [searchFoundCount, setSearchFoundCount] = useState(null);
    const [treeData, setTreeData] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [butLoading,setBtnLoading] = useState(false);
    const [result,setResult] = useState(0);
    const [spinner,setSpinner] = useState(false);
    const redirectTo = useNavigate();
    const [contentGroup,setContentGroup] = useState({id:null,excerpt: '',slug: '',seoTitle: '',title: '',parentId:null,type:null,order:0,templateId:null,stateShow:false,stateGoogle:false});
    const [errors, setErrors] = useState({});

    const [options,setOptions] = useState({groups:[],types:[],templates:[]})

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search);
        if (queryParams.isEdit === "true") {
            handleSuccessMessage(t("Grouphasbeensuccessfullyedited"));
        }
    }, [window.location.search]);
    const onApiError=()=>{
        setSpinner(false);
    }
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }

    const superValueHandler=(data,name)=>{
        setContentGroup({ ...contentGroup, [name]: data });
    }

    useEffect(()=>{
        handleFetchData();
    },[result])

    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
        resetContentGroupForm();
    };


    const handleFetchData = async () => {
        try {
            setSpinner(true);

            // Use Promise.all to call all APIs concurrently
            const [responseType, responseTemplate, responseGroup] = await Promise.all([
                GET(`/EnContentType`, {}, 'IEnumerable'),
                GET(`/TemplatePages/selection?Type=${1}&Type=${13}`, {}, 'IEnumerable'),
                GET(`/ContentGroups/Selection`, {}, 'IEnumerable')
            ]);

            if (responseType.status === 200 && responseTemplate.status === 200 && responseGroup.status === 200) {
                const contentType = convertSelectDataHandler(responseType.data);
                const templates = convertSelectDataHandler(responseTemplate.data);
                const groupIds = convertSelectDataHandler(responseGroup.data);
                setOptions((prevOptions) => ({
                    ...prevOptions,
                    types: contentType,
                    templates: templates,
                    groups: groupIds
                }));
                setContentGroup((prevOptions) => ({
                    ...prevOptions,
                    type: contentType[0],
                    templateId: templates[0],
                    stateShow: true,
                    stateGoogle: false
                }));
                const response = await GET(`/ContentGroups`, {}, 'IEnumerable');
                if (response.status === 200) {
                    const tree = convertToTree(response.data);
                    setTreeData(tree);
                } else {
                    handleApiError(response, onApiError);
                }

                setSpinner(false);
            } else {
                handleApiError(responseType, onApiError);
                handleApiError(responseTemplate, onApiError);
                handleApiError(responseGroup, onApiError);
            }
        } catch (error) {
            onApiError()
        }
    };


    const handleContentGroupSlugValue = (e) => {
        const { value } = e.target;
        const slugValue = convertSlugHandler(value);
        setContentGroup((prevContent) => ({ ...prevContent, slug: slugValue }));
    };
    const handleValue = (e) => {
        const { name, value } = e.target;
        if(contentGroup.id === null){
            if (name === "title") {
                handleContentGroupSlugValue(e);
                setContentGroup((prevState) => ({ ...prevState, seoTitle: value }));
            }
            setContentGroup((prevState) => ({ ...prevState, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }else{
            setContentGroup((prevState) => ({ ...prevState, [name]: value }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: undefined,
            }));
        }

    };
    const orderValueHandler =(data)=>{
        setContentGroup((prevState) => ({ ...prevState, order: data }));
    }
    const resetContentGroupForm = ()=>{
        setContentGroup((prevState)=>({...prevState,id:null,excerpt: '',slug: '',seoTitle: '',title: '',type: null,parentId: null,order: 1,stateShow: true,stateGoogle: false}));
    }

    const [isContentGroupSlugValid, setContentGroupIsSlugValid] = useState(null);
    const [oldSlug,setOldSlug] = useState('');
    const handleCheckContenGrouptSlug = async (e) => {
        const { name, value } = e.target;
        if (name === "title") {
            if(contentGroup.id === null){
                const response = await GET(`/Public/SlugValidation`,{NewSlug:contentGroup.slug,OldSlug:null},'single');
                if(response.status === 200){
                    setContentGroupIsSlugValid(response.data.isSlugValid);
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            else{
                const response = await GET(`/Public/SlugValidation`,{NewSlug:contentGroup.slug,OldSlug:contentGroup.slug},'single');

                if(response.status === 200){
                    setContentGroupIsSlugValid(response.data.isSlugValid);
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
        }
        else{
            if(contentGroup.id === null){
                const response = await GET(`/Public/SlugValidation`,{NewSlug:value,OldSlug:null},'single');

                if(response.status === 200){
                    setContentGroupIsSlugValid(response.data.isSlugValid);
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            else{
                const response = await GET(`/Public/SlugValidation`,{NewSlug:value,OldSlug:oldSlug},'single');
                if(response.status === 200){
                    setContentGroupIsSlugValid(response.data.isSlugValid);
                }
                else{
                    handleApiError(response,onApiError);
                }
            }

        }
    };



    const groupSubmitApiHandler = async (e)=>{
        e.preventDefault();
        var isValid = validate(contentGroupSchema,contentGroup,onSetErrors);
        if(!isValid)return;
        try {
            if(contentGroup.id === null){
                const convertedContentGroup = convertContentGroup(contentGroup);
                setSpinner(true)
                setBtnLoading(true)
                const {title, seoTitle, slug, parentId, type, order, excerpt,templateId,stateShow,stateGoogle} = convertedContentGroup
                const response = await POST('/ContentGroups',{title,seoTitle,slug,parentId,type,order,excerpt,templateId,stateShow,stateGoogle},false);
                if (response.status === 200) {
                    //createNode(response.data.id);
                    setBtnLoading(false)
                    closeModal()
                    setSpinner(false)
                    handleSuccessMessage(t("Contentgrouphasbeensuccessfullycreated"));
                    setResult({...response.status})
                    resetContentGroupForm()
                }
                else{
                    handleApiError(response,onSetErrors);
                    setBtnLoading(false)

                }
            }else{
                setBtnLoading(true)
                var isValid = validate(contentGroupSchema,contentGroup,onSetErrors);
                if(!isValid)return;
                const convertedContentGroup = convertContentGroup(contentGroup);
                setSpinner(true);
                const {id,title, seoTitle, slug, parentId, type, order, excerpt,templateId,stateGoogle,stateShow} = convertedContentGroup;
                const response = await PUT('/ContentGroups',{id,title, seoTitle, slug, parentId, type, order, excerpt,templateId,stateShow,stateGoogle},false);
                if (response.status === 200) {
                    setBtnLoading(false)
                    setResult({...response.status})
                    closeModal()
                    setSpinner(false)
                    handleSuccessMessage(t("Contentgrouphasbeensuccessfullyedited"));
                    resetContentGroupForm()
                }
                else{
                    handleApiError(response,onApiError);
                    setBtnLoading(false)

                }
            }
        }catch (e) {
            setBtnLoading(false)

        }
    }


    function createNode(groupId) {

        // const newNode = {
        //     id:groupId,
        //     parentId: contentGroup.parentId !== null?contentGroup.parentId.value:null,
        //     seoTitle: contentGroup.seoTitle,
        //     title: contentGroup.title,
        //     subtitle: contentGroup.slug,
        //     type: contentGroup.type !== null? contentGroup.type.value:null,
        //     excerpt: contentGroup.excerpt,
        //     order: contentGroup.order,
        // };
        //
        // let newTree = addNodeUnderParent({
        //     treeData: treeData,
        //     parentKey: null,
        //     expandParent: true,
        //     getNodeKey,
        //     newNode: newNode,
        // });
        //
        // setTreeData(newTree.treeData);
        // //resetContentGroupForm();
    }

    function updateTreeData(treeData) {
        setTreeData(treeData);
    }

    function expand(expanded) {
        setTreeData(
            toggleExpandedForAll({
                treeData,
                expanded,
            })
        );
    }

    function expandAll() {
        expand(true);
    }

    function collapseAll() {
        expand(false);
    }

    const selectPrevMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null
                ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
                : searchFoundCount - 1
        );
    };

    const selectNextMatch = () => {
        setSearchFocusIndex(
            searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
        );
    };

    const getNodeKey = ({ treeIndex }) => treeIndex;



    const updateNode = async(rowInfo)=>{
        setSpinner(true);
        setBtnLoading(true);
        const response = await GET(`/ContentGroups/${rowInfo.node.id}`,{},'single');
        if(response.status === 200){
            setBtnLoading(false)
            setSpinner(false);
            const {id,title,slug,excerpt,parentId,type,order,seoTitle,templateId,stateShow,stateGoogle} = response.data;
            const parents = options.groups.find((item) => item.value === parentId)??null;
            const templates = options.templates.find((item) => item.value === templateId);
            const types = options.types.find((item) => item.value === type);
            setOldSlug(slug);
            setContentGroup(({id:id ,title:title,slug: slug,order: order,excerpt: excerpt,seoTitle: seoTitle,parentId:parents,type: types,templateId: templates,stateShow: stateShow,stateGoogle: stateGoogle}))
            openModal();
        }else{
            handleApiError(response,onApiError);
        }
        //const { node, path } = rowInfo;
        // const {type,order,title,slug,seoTitle,parentId} = contentGroup;
        //
        //  const updatedNode = {
        //      ...node,
        //      type,order,title,slug,seoTitle,parentId
        //  };
        //  setTreeData((prevTreeData) =>
        //      changeNodeAtPath({
        //          treeData: prevTreeData,
        //          path,
        //          getNodeKey,
        //          newNode: updatedNode,
        //      })
        //  );
        // resetContentGroupForm();
    }
    const groupUpdateSubmitHandler = async(e)=>{
        e.preventDefault();
        setBtnLoading(true)
        var isValid = validate(contentGroupSchema);
        if(!isValid)return;
        const convertedContentGroup = convertContentGroup(contentGroup);
        setSpinner(true)
        const response = await PUT('/ContentGroups',convertedContentGroup,false);
        if (response.status === 200) {
            setBtnLoading(false)
            setResult({...response.status})
            //createNode(response.data.id);
            closeModal()
            setSpinner(false)
            handleSuccessMessage(t("Contentgrouphasbeensuccessfullyedited"));
            resetContentGroupForm()
        }
        else{
            handleApiError(response,onApiError);
        }
    }



    function addNodeChild(rowInfo) {
        const parent = options.groups.find((item) => item.value === rowInfo.node.id);
        setContentGroup({ ...contentGroup, parentId: parent[0] });
        openModal();
    }
    function addNodeSibling(rowInfo) {
        const { path } = rowInfo;
        const { type, order, title, slug, seoTitle,templateId } = contentGroup;

        if (contentGroup === null) {
            return;
        }
        const newNode = {
            type,
            templateId,
            title,
            order,
            slug,
            seoTitle,
            parentId: path[path.length - 2],
        };
        setTreeData((prevTreeData) =>
            addNodeUnderParent({
                treeData: prevTreeData,
                parentKey: path[path.length - 2],
                expandParent: true,
                getNodeKey,
                newNode,
            }).treeData
        );
        resetContentGroupForm();
    }

    const removeContentGroupHandelr = async (id)=>{

        const response = await GET(`/ContentGroups/HasChildren?Id=${id}`,{},'single');
        if(response.status === 200){
            if(response.data.hasBranch){
                const TailWindButton = Swal.mixin({
                    customClass: {
                        confirmButton: AlertSetting.customClass.confirmButton,
                        cancelButton: AlertSetting.customClass.cancelButton
                    },
                    buttonsStyling: false
                })
                TailWindButton.fire({
                    title:AlertSetting.TailWindButton.title,
                    text: AlertSetting.TailWindButton.text,
                    icon:AlertSetting.TailWindButton.icon,
                    showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                    confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                    cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                    reverseButtons: AlertSetting.TailWindButton.reverseButtons
                }).then( async (result) =>{
                    if (result.isConfirmed) {
                        setSpinner(true);
                        const response =await DELETE(`/ContentGroups/${id}`,{headers: { 'Content-Type': 'application/json' }})
                        if(response.status === 200){
                            setSpinner(false);
                            setResult({...response.status});
                            handleSuccessMessage(t("Grouphasbeensuccessfullydeleted"));
                        }else{
                            handleApiError(response,onApiError);
                        }
                    }
                    else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        return;
                    }
                })
            }else{
                const TailWindButton = Swal.mixin({
                    customClass: {
                        confirmButton: AlertSetting.customClass.confirmButton,
                        cancelButton: AlertSetting.customClass.cancelButton
                    },
                    buttonsStyling: false
                })
                TailWindButton.fire({
                    title:AlertSetting.TailWindButton.title,
                    text: AlertSetting.TailWindButton.text,
                    icon:AlertSetting.TailWindButton.icon,
                    showCancelButton:AlertSetting.TailWindButton.showCancelButton,
                    confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
                    cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
                    reverseButtons: AlertSetting.TailWindButton.reverseButtons
                }).then( async (result) =>{
                    if (result.isConfirmed) {
                        setSpinner(true);
                        const response  = await DELETE(`/ContentGroups/${id}`,{headers: { 'Content-Type': 'application/json' }});
                        if(response.status === 200){
                            setSpinner(false);
                            setResult({...response.status});
                            handleSuccessMessage(t("Grouphasbeensuccessfullydeleted"));
                        }else{
                            handleApiError(response,onApiError);
                        }
                    }
                    else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        return;
                    }
                })
            }
        }
        else{
            handleApiError(response,onApiError)
        }
    }

    const removeNode = async (rowInfo)=>{
        // const { path } = rowInfo;

        await removeContentGroupHandelr(rowInfo.node.id);

        // setTreeData((prevTreeData) =>
        //     removeNodeAtPath({
        //         treeData: prevTreeData,
        //         path,
        //         getNodeKey,
        //     })
        // );
    }


    const MoveNodeHandler = async(e) => {
        const Id = e.nextParentNode?.id;
        e.node.parentId = Id !== undefined ? Id : null;
        console.log(e.node.parentId);
        setSpinner(true)
        const response = await PUT('/ContentGroups/SetParent', { id: e.node.id,parentId:e.node.parentId });
        if(response.status === 200){
            setSpinner(false);
            setResult({...response.status});
            handleSuccessMessage(t("Editingwassuccessfullydone"))
        }else{
            handleApiError(response,onApiError)
        }
    }

    const alertNodeInfo = ({ node, path, treeIndex }) => {
        const objectString = Object.keys(node)
            .map((k) => (k === "children" ? "children: Array" : `${k}: '${node[k]}'`))
            .join(",\n   ");

        window.alert(
            "Info passed to the icon and button generators:\n\n" +
            `node: {\n   ${objectString}\n},\n` +
            `path: [${path.join(", ")}],\n` +
            `treeIndex: ${treeIndex}`
        );
    };

    const customSearchMethod = ({ node, searchQuery }) => {
        return node.title.toLowerCase().includes(searchQuery.toLowerCase());
    };

    return (
        <>

            <div className=" space-y-5 relative">
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListFilter label={null} icon={null}
                                showChildrenTwo={false}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => openModal()}
                                            tooltipTitle={t("GroupCreation")}
                                            title={t("creation")}
                                        />
                                        <ButtonPlus
                                            onClick={() => redirectTo("/CMS/Content/Add")}
                                            tooltipTitle={t("InsertingInformation")}
                                            title={t("insertion")}
                                            icon={"pi-pen-to-square"}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo(`/CMS/MetaData/Add`)}
                                            icon={"pi-star"}
                                            title={t("feature")}
                                            tooltipTitle={t("CreatingAFeature")}
                                        />
                                        <ButtonPlus
                                            onClick={() => redirectTo("/CMS/Advertise/List")}
                                            tooltipTitle={t("ListOfInformation")}
                                            title={t("list")}
                                            icon={"pi-list"}
                                        />
                                        <ButtonPlus
                                            onClick={expandAll}
                                            icon={"pi-arrow-up-right-and-arrow-down-left-from-center"}
                                            title={t("View")}
                                            tooltipTitle={t("ViewSubcategories")}
                                        />
                                        <ButtonPlus
                                            onClick={collapseAll}
                                            icon={"pi-arrow-down-left-and-arrow-up-right-to-center"}
                                            title={t("Close")}
                                            tooltipTitle={t("CloseSubcategories")}
                                        />

                                    </>
                                }
                    />


                </div>

                {/*<div>*/}

                {/*    <form*/}
                {/*        onSubmit={(event) => {*/}
                {/*            event.preventDefault();*/}
                {/*        }}*/}
                {/*    >*/}

                {/*        <button type="button" disabled={!searchFoundCount}*/}
                {/*            onClick={selectPrevMatch}>*/}
                {/*        </button>*/}

                {/*        <button type="submit"*/}
                {/*            disabled={!searchFoundCount}*/}
                {/*            onClick={selectNextMatch} >*/}
                {/*        </button>*/}
                {/*    </form>*/}
                {/*</div>*/}



                <div class="p-2 box bg-white dark:bg-themeDarkSalt700 ">
                    <div className={"grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 post-input dark:bg-themeDarkPostInput post-input-design"}>
                        <div className={"p-2 flex gap-2 items-center"}>
                            <InputText type="search" className="input " placeholder={t("search")} value={searchString} onChange={(e) => setSearchString(e.target.value)}/>
                            <ButtonSearchList
                            />
                        </div>
                    </div>
                </div>
                <div className="h-screen bg-white rounded-md dark:bg-themeDarkSalt700">

                    <SortableTree
                        treeData={treeData}
                        onMoveNode={(e)=>MoveNodeHandler(e)}
                        onChange={(treeData) => updateTreeData(treeData)}
                        searchMethod={customSearchMethod}
                        searchQuery={searchString}
                        searchFocusOffset={searchFocusIndex}
                        searchFinishCallback={(matches) => {
                            setSearchFoundCount(matches.length);
                            setSearchFocusIndex(
                                matches.length > 0 ? searchFocusIndex % matches.length : 0
                            );
                        }}
                        canDrag={({ node }) => !node.dragDisabled}
                        generateNodeProps={(rowInfo) => ({
                            // title: rowInfo.node.label,
                            // subtitle: rowInfo.node.subTitle,
                            buttons: [
                                <div className="flex space-x-2 items-center">
                                    <button onClick={(event) => addNodeChild(rowInfo)} className="button tree_button tooltip_class_group bg-themeInformation dark:bg-themeDarkInformation text-white" >
                                        <HPrimeIconSmall
                                        icon={"pi-plus-circle"}
                                        />
                                        <Tooltip anchorSelect={".tooltip_class_group"} content={t("GroupCreation")} />
                                    </button>
                                    <button onClick={(event) => updateNode(rowInfo)} className="button tree_button tooltip_class_Edit bg-themeSuccess text-white"  >
                                        <HPrimeIconSmall  icon={'pi-pen-to-square'} />
                                        <Tooltip anchorSelect={".tooltip_class_Edit"} content={t("edit")} />
                                    </button>
                                    <button onClick={(event) => removeNode(rowInfo)} className="button tree_button tooltip_class_delete bg-themeDanger text-white "  >
                                        <HPrimeIconSmall icon={'pi-trash'} />
                                        <Tooltip anchorSelect={".tooltip_class_delete"} content={t("delete")} />
                                    </button>
                                    <button onClick={(event) => redirectTo(`/CMS/Content/Group/Detail?contentGroupId=${rowInfo.node.id}`)} className="button tree_button tooltip_class_details bg-themeSecondary text-white "  >
                                        <HPrimeIconSmall icon={'pi-external-link'} />
                                        <Tooltip anchorSelect={".tooltip_class_details"} content={t("details")} />
                                    </button>
                                    {/*<button label="Alert" className="dark:text-gray-300" onClick={(event) => alertNodeInfo(rowInfo)}>*/}
                                    {/*    Info*/}
                                    {/*</button>
                                    */}
                                </div>
                            ],
                            style: {
                                height: "50px"
                            }
                        })}
                    />
                </div>
            </div>


            {console.log(contentGroup)}

            <FantasyModal openModal={isModalOpen}   closeModal={closeModal} title={t("GroupCreation")}>
                <div>
                    <form method={'post'} onSubmit={groupSubmitApiHandler} className="space-y-4">
                        <div className="grid grid-cols-2 gap-3">
                            <div className="space-y-2">
                                <div className="flex justify-between items-center gap-2">
                                    <label className="text-xs dark:text-gray-300">
                                        {t("Title")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.title}/>
                                </div>
                                <InputText type={'text'} onBlur={handleCheckContenGrouptSlug} name={'title'} value={contentGroup.title} onInput={(e)=>handleValue(e)} className={'input w-full'}/>
                            </div>
                            <div class="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("WebsiteTitle")}</label>
                                    <InputText type={'text'} className={' input w-full '} value={contentGroup.seoTitle} name={'seoTitle'} onInput={(e)=>handleValue(e)}  />
                            </div>
                            <div className="space-y-2">
                                <div className="flex justify-between items-center gap-2">
                                    <label className="text-xs dark:text-gray-300">
                                        {t("ArticleAddress")}
                                    <RequiredIcon/>
                                    </label>
                                    <DisplayError message={errors.slug}/>
                                </div>
                                    <InputText type={'text'} onBlur={handleCheckContenGrouptSlug}  value={contentGroup.slug} name={'slug'} onInput={(e)=>handleValue(e)} className={' input w-full '}/>
                            </div>
                            <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("Group")}</label>
                                    <Select
                                        options={
                                        contentGroup.id === null?
                                        options.groups
                                            :
                                            options.groups.filter((item)=>item.value !== contentGroup.id)
                                    }
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data)=>superValueHandler(data,'parentId')}
                                        name={'parentId'}
                                        placeholder={t("selection")}
                                        value={contentGroup.parentId}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />

                                </div>
                            <div className="space-y-1 ">
                                    <label className="text-xs dark:text-gray-300">{t("template")}</label>
                                    <Select
                                        options={options.templates}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data)=>superValueHandler(data,'templateId')}
                                        name={'templateId'}
                                        placeholder={t("selection")}
                                        value={contentGroup.templateId}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                </div>
                            <div class="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("rank")}</label>
                                    <InputNumber className="w-full " name={'order'}  onValueChange={(e)=>orderValueHandler(e.target.value)} value={contentGroup.order} placeholder={t("selection")} mode="decimal" showButtons min={0} max={100}/>
                                </div>
                            <div class="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("type")}</label>
                                    <Select
                                        options={options.types}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data)=>superValueHandler(data,'type')}
                                        name={'type'}
                                        placeholder={t("selection")}
                                        value={contentGroup.type}
                                        classNamePrefix={"h_multi_select"}
                                        menuPosition={"fixed"}
                                    />
                                </div>
                            <div class="col-span-2">
                                    <InputTextarea name={'excerpt'} placeholder={t("Explanation")} value={contentGroup.excerpt} onInput={(e)=>handleValue(e)} rows={3} cols={30}  className={'input w-full'}/>
                            </div>
                            <InputSwitch value={contentGroup.stateShow} OnValueSelection={superValueHandler} lable={t("DisplayStatus")} name={'stateShow'}/>

                            <InputSwitch value={contentGroup.stateGoogle} OnValueSelection={superValueHandler} lable={t("stateGoogle")} name={'stateGoogle'}/>

                        </div>
                        <div >

                            <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation place-content-center text-white relative"} >
                                {t("insertion")}
                                {butLoading ? (
                                    <TinyLoading
                                    />
                                ) : (
                                    null
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </FantasyModal>


            <ToastContainer />

        </>

    )


}
