import React, {useEffect, useMemo, useState} from "react";
import {TabMenu} from "../../../../Commans/UIParts/TabMenu";
import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";

import {BackupTab} from "./BackupTab";
import {BackupGridTab} from "./BackupGridTab";
import {MultiDataSelect} from "../../../../Commans/UIParts/MultiDataSelect";
import Select from "react-select";
import {ToastContainer} from "react-toastify";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";

export const HandleBackUpSetting = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("backup");
    const redirectTo = useNavigate();

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = [
        {
            id: "backup",
            label: t("Backup"),
            icon: "hard-drive",
        },
        {
            id: "grid",
            label: t("BackupList"),
            icon: "list",
        },

    ];






    return (
        <div className="space-y-5">
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            childrenOne={

                                <>
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                        icon={"pi-cog"}
                                        title={t("settings")}
                                        tooltipTitle={t("SettingsPage")}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Harmony/Setting/Main/Domain`)}
                                        icon={"pi-globe"}
                                        title={t("Domain")}
                                        tooltipTitle={t("Domain")}
                                    />

                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Harmony/Setting/Main/Script`)}
                                        icon={"pi-code"}
                                        title={t("Script")}
                                        tooltipTitle={t("AdditionalScript")}
                                    />

                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Harmony/Setting/Main/ShortcutKey`)}
                                        icon={"pi-key"}
                                        title={t("Shortcut")}
                                        tooltipTitle={t("ShortcutKey")}
                                    />


                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/Harmony/Setting/Main/Security`)}
                                        icon={"pi-shield"}
                                        title={t("Security")}
                                        tooltipTitle={t("SecurityAndPrivacy")}
                                    />


                                </>
                            }
                            childrenTwo={
                                <>

                                </>
                            }
                />
            </div>
            <div class="post box bg-white dark:bg-themeDarkSalt700">
                <TabMenu activeTab={activeTab} handleTabClick={handleTabClick} tabs={tabs} />
                <div class="post__content tab-content">
                    <div className={`tab-content__pane p-2 ${ activeTab === "backup" ? "active" : "" }`}  id="backup">
                        {
                            activeTab === 'backup'?
                                <BackupTab
                                    handleTabClick={handleTabClick}
                                />:null
                        }
                    </div>

                    <div className={`tab-content__pane p-2 space-y-5  ${ activeTab === "grid" ? "active" : "" }`} id="grid">
                        {
                            activeTab === 'grid'?
                                <BackupGridTab/>:null
                        }

                    </div>
                </div>
            </div>
        </div>
    );
};