import React from "react";

import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";

export const UserNote=()=>{

    return (
        <>
            <div class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                <div>
                    <HPrimeIcon icon={"pi-file-edit"} iconClass={"dark:text-gray-300"}/>
                </div>
                <div class="flex-1">
                    <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                    <div class="text-gray-500 text-xs dark:text-gray-300">6 August 2022</div>
                </div>
            </div>
       </>
    )
}