import React, {useEffect, useState} from "react";

import {InputText} from "primereact/inputtext";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {ColorPicker} from "primereact/colorpicker";
import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import Select from "react-select";
import {Accordion, AccordionTab} from "primereact/accordion";
import {Dialog} from "primereact/dialog";
import {ButtonNotification} from "../../../../Commans/UIParts/ButtonNotification";
import {useGeneralSettings} from "./useGeneralSettings";
import {GET, POST, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler, fonts, fontSizes} from "../../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {TagsInput} from "react-tag-input-component";
import {InputNumber} from "primereact/inputnumber";
import {handleCreateGeneralSettings} from "./GeneralSettingsService";
import {handleSuccessMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {LoadingHarmony} from "../../../../Commans/Loadings/LoadingHarmony";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {useDynamicDispatch} from "../../../../../Services/Redux/useDynamicaDispatch";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";
export const HandleGeneralSetting = ({isInUse,handleCloseSetting}) => {
  const { t } = useTranslation();
  const redirectTo = useNavigate();
  const [refresh,setRefresh]=useState(false);
  const [errors,setErrors]=useState({});
  const {handleDispatch} = useDynamicDispatch();
  const[initialSource,setInitialSource]=useState({
    currencies:[]
    ,cultures:[],
    directions:[],
    calendarTypes:[],
    calendarLanguages:[],
    weekDays:[],
    calendarViewTypes:[],
    editors:[],
    positions:[],
    colorThemes:[],
    fonts:[],
    countries:[]
  });
  const [IsShowSpinner,setIsShowSpinner]=useState(false);
  const {handleKeywordsChange,handleGetValueTheme,setting,handleSelectValue,handleNumberValue,setSetting, handleValue}=useGeneralSettings(setErrors);

  const showSpinner=()=>{
    setIsShowSpinner(true);
  }
  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }
  const Refresh=()=>{
    setRefresh((prevState)=>!prevState);
  }
  const handleInitialDataSource=(currencies,cultures,directions,calendarTypes,calendarLanguages,weekDays,calendarViewTypes,editors,positions,colorThemes,fontsArray,countries)=>{
    const currenciesData = convertSelectDataHandler(currencies);
    const culturesData = convertSelectDataHandler(cultures);
    const directionsData = convertSelectDataHandler(directions);
    const calendarTypesData = convertSelectDataHandler(calendarTypes);
    const calendarLanguagesData = convertSelectDataHandler(calendarLanguages);
    const weekDaysData = convertSelectDataHandler(weekDays);
    const calendarViewTypesData = convertSelectDataHandler(calendarViewTypes);
    const editorsData = convertSelectDataHandler(editors);
    const positionsData = convertSelectDataHandler(positions);
    const colorThemesData = convertSelectDataHandler(colorThemes);
    const countriesData = convertSelectDataHandler(countries);
    setInitialSource({
      currencies: currenciesData,
      cultures: culturesData,
      directions: directionsData,
      calendarTypes: calendarTypesData,
      calendarLanguages: calendarLanguagesData,
      weekDays: weekDaysData,
      calendarViewTypes:calendarViewTypesData,
      editors: editorsData,
      positions: positionsData,
      colorThemes: colorThemesData,
      fonts: fontsArray,
      countries: countriesData
    })
   return {
     currenciesData,
     culturesData,
     directionsData,
     calendarTypesData,
     calendarLanguagesData,
     weekDaysData,
     calendarViewTypesData,
     editorsData,
     positionsData,
     colorThemesData,
     fontsArray,
     countriesData
   }
  }

  const fetchData=async ()=>{
    try {
      showSpinner();
      const [responseCurrency,responseCulture,responseDirection,responseCalendarType,responseCalendarLang,responseFirstDayOfWeek,responseCalendarView,
      responseEditor,responsePosition,responseColorTheme,responseCountry,responseSetting
      ] = await Promise.all([
        GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnCultureType`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnDirection`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnCalendarType`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnCalendarLangType`,{},'IEnumerable'),
        GET(`/Enumerations/EnDayOfWeek`,{},'IEnumerable'),
        GET(`/Enumerations/EnCalendarView`,{},'IEnumerable'),
        GET(`/Enumerations/EnEditor`,{},'IEnumerable'),
        GET(`/Enumerations/EnWatermarkPositions`,{},'IEnumerable'),
        GET(`/Enumerations/EnTheme`,{},'IEnumerable'),
        GET(`/Countries/Selection`,{},'IEnumerable'),
        GET(`/Settings/GeneralSettings`,{},'IEnumerable'),
      ]);
      const allResponses = [responseCurrency, responseCulture, responseDirection, responseCalendarType, responseCalendarLang, responseFirstDayOfWeek, responseCalendarView, responseEditor, responsePosition, responseColorTheme,responseCountry,responseSetting];
      const allResponseStatusAre200 = allResponses.every(response => response.status === 200);
      if(allResponseStatusAre200){
        const {directionsData,culturesData,calendarLanguagesData,calendarTypesData,positionsData,calendarViewTypesData, colorThemesData, currenciesData, editorsData, weekDaysData,countriesData,fontsArray} =
            handleInitialDataSource(responseCurrency.data,responseCulture.data,responseDirection.data,responseCalendarType.data,responseCalendarLang.data,responseFirstDayOfWeek.data,responseCalendarView.data,
            responseEditor.data,responsePosition.data,responseColorTheme.data,fonts,responseCountry.data
            )
        setSetting(prevState => ({...prevState,
          title: responseSetting.data.title,
          concurrencyStamp:responseSetting.data.concurrencyStamp,
          calendarLanguage: calendarLanguagesData.find((item)=>responseSetting.data.calendarLanguage === item.value),
          calendarType:  calendarTypesData.find((item)=>responseSetting.data.calendarType === item.value),
          calendarView:   calendarViewTypesData.find((item)=>responseSetting.data.calendarView === item.value),
          countryId: countriesData.find((item)=>responseSetting.data.countryId === item.value),
          culture:  culturesData.find((item)=>responseSetting.data.culture === item.value),
          currency: currenciesData.find((item)=>responseSetting.data.currency === item.value),
          description: responseSetting.data.description,
          direction: directionsData.find((item)=>item.value === responseSetting.data.direction ),
          editor:  editorsData.find((item)=>item.value === responseSetting.data.editor ),
          firstDayOfWeek: weekDaysData.find((item)=>item.value === responseSetting.data.firstDayOfWeek ),
          fontFamily: fonts.find((item)=>item.value === responseSetting.data.fontFamily ),
          fontSize:fontSizes.find((item)=>item.value === responseSetting.data.fontSize),
          keywords: responseSetting.data.keywords??[],
          theme: colorThemesData.find((item)=>item.value === responseSetting.data.theme ),
          watermarkActivation: responseSetting.data.watermarkActivation,
          watermarkBackgroundColor: responseSetting.data.watermarkBackgroundColor,
          watermarkFontColor: responseSetting.data.watermarkFontColor,
          watermarkFontSize: responseSetting.data.watermarkFontSize,
          watermarkPosition: positionsData.find((item)=>item.value === responseSetting.data.watermarkPosition),
          watermarkText: responseSetting.data.watermarkText
        }));
      }
      else{
        allResponses.forEach((response)=>{
          handleApiError(response,hideSpinner);
        })
      }
      hideSpinner();
    }catch (e) {
      hideSpinner();
    }
  }
  useEffect(()=>{
    fetchData();
  },[refresh])

  const handleGeneralSettingsSubmit=async(e)=>{
    e.preventDefault();
    try {
      showSpinner();
      const settingsData = handleCreateGeneralSettings(setting);
      const response = await PUT(`/Settings/GeneralSettings`,settingsData,false);
      if(response.status === 200){
        Refresh();
        handleSuccessMessage(t('SuccessDone'));
        const theme =  handleGetValueTheme(setting.theme.value);
        handleDispatch('setSelectedTheme',theme)
        handleDispatch('setCalendarType',setting.calendarType.value);
        handleDispatch('setCalendarLanguage',setting.calendarLanguage.value)
        handleDispatch('setCalendarView',setting.calendarView.value);
        handleDispatch('setEditorType',setting.editor.value);

        handleDispatch('setFontSize',setting.fontSize.value);
        document.documentElement.removeAttribute('class');
        document.documentElement.classList.add(setting.fontSize.value);
        if(isInUse){
          handleCloseSetting();
        }
      }
      else{
        hideSpinner();
      }
    }catch (e) {
      hideSpinner();
    }
  }














  const [visible, setVisible] = useState(false);

  const[message,setMessage]=useState('');
  const handleShowMessage =(visible,message)=>{
    setVisible(visible);
  }
  const headerElement = (
      <div className="flex items-center gap-2">
        <HPrimeIcon icon={"pi-exclamation-circle"}  iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
        <span className=" white-space-nowrap">{t("Quickguide")}</span>
      </div>
  );

  return(
      <>

        <Dialog header={headerElement} visible={visible}  className={"Dialog-NotifText"} onHide={() => setVisible(false)} >
          <p>با تغییر واحد پولی تمام قیمت ها تغییر میکند</p>
          <p className={"text-xs "}>زمانی که واحد پولی تغییر کرد نرخ تبدیل و واحد پولی گرفته شده و تمام قیمت ها تغییر میکند</p>
        </Dialog>


        <div class="space-y-5">
          {
            isInUse?
                null
                :
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                  <ListFilter label={null} icon={null}
                              showChildrenTwo={false}
                              childrenOne={
                                <>
                                  <ButtonPlus
                                      onClick={()=>redirectTo(`/Harmony/Master/setting`)}
                                      icon={"pi-cog"}
                                      title={t("settings")}
                                      tooltipTitle={t("SettingsPage")}
                                  />
                                  <ButtonPlus
                                      onClick={()=>redirectTo(`/Harmony/Setting/Main/Dashboard`)}
                                      icon={"pi-user"}
                                      title={t("Personal")}
                                      tooltipTitle={t("PersonalSettings")}
                                  />
                                  <ButtonPlus
                                      onClick={()=>redirectTo(`/Harmony/Setting/Main/Pictures`)}
                                      icon={"pi-image"}
                                      title={t("Images")}
                                      tooltipTitle={t("ImageSettings")}
                                  />
                                  <ButtonPlus
                                      onClick={()=>redirectTo(`/Harmony/Setting/Main/Routing`)}
                                      icon={"pi-code"}
                                      title={t("Routing")}
                                      tooltipTitle={t("Routing")}
                                  />
                                  <ButtonPlus
                                      onClick={()=>redirectTo(`/Harmony/Setting/Main/CleanTemp`)}
                                      icon={"pi-trash"}
                                      title={t("DataCleanup")}
                                      tooltipTitle={t("TemporaryDataCleanup")}
                                  />


                                </>
                              }
                              childrenTwo={
                                <>

                                </>
                              }
                  />

                </div>
          }

          <form method={'post'} onSubmit={handleGeneralSettingsSubmit} className={"relative"}>
            {
              IsShowSpinner?
                  <div className="huploader_loading" >
                    <div className="relative">
                      <LoadingHarmony/>
                    </div>
                  </div>
                  :
                  null
            }

            <div className={"space-y-5"}>
              <div class="p-2 bg-white box dark:bg-themeDarkPostInput">
                <Accordion  className={" space-y-3"} activeIndex={0}>
                  <AccordionTab className={"bg-white dark:bg-themeDarkSalt700"}
                                header={
                                  <div className="flex items-center gap-2">
                                    <HPrimeIcon icon={'pi-pen-to-square'} />
                                    <p>{t("Titles")}</p>
                                  </div>
                                }
                  >
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                      <div className={"md:col-span-2 grid grid-cols-1 md:grid-cols-3 gap-3 post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                        <div class="space-y-1">
                          <label className="text-xs dark:text-gray-300">
                            {t("نام شرکت")}
                          </label>
                          <InputText className="input w-full" />
                        </div>
                        <div class="space-y-1">
                          <label className="text-xs dark:text-gray-300">
                            {t("WebsiteTitle")}
                          </label>
                          <InputText className="input w-full" name={'title'} value={setting.title} onInput={(e)=>handleValue(e)}/>
                        </div>
                        <div class="space-y-1">
                          <label className="text-xs dark:text-gray-300">
                            {t("description")}
                          </label>
                          <InputText name={'description'} value={setting.description} onInput={(e)=>handleValue(e)} className="input w-full"/>
                        </div>
                      </div>
                      <div class="md:col-span-2 post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                        <div class="space-y-1">
                          <label className="text-xs dark:text-gray-300">
                            {t("Keywords")}
                          </label>
                          <TagsInput value={setting.keywords} onChange={handleKeywordsChange} name={"seo_Keywords"} placeHolder={t("Keywords")}/>
                        </div>
                      </div>
                      <div className={"grid grid-cols-1 md:grid-cols-2 gap-3 post-input post-input-design dark:bg-themeDarkPostInput p-2"}>
                        <div class="space-y-1 ">
                          <label className="text-xs dark:text-gray-300">
                            {t("SiteLanguage")}
                          </label>
                          <Select
                              options={initialSource.cultures}
                              placeholder={t("selection")}
                              classNamePrefix={"h_multi_select"}
                              menuPosition={"fixed"}
                              value={setting.culture}
                              onChange={(data)=>handleSelectValue(data,'culture')}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionTab>

                  <AccordionTab className={"bg-white dark:bg-themeDarkSalt700"}
                                header={
                                  <div className="flex items-center gap-2">
                                    <HPrimeIcon icon={'pi-clock'} />
                                    <p>{t("DateTime")}</p>
                                  </div>
                                }
                  >
                    <div
                        class="grid grid-cols-1 md:grid-cols-2 gap-2">
                      <div className={"flex gap-2 items-center post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                        <div class="space-y-1 flex-1">
                          <label className="text-xs dark:text-gray-300">
                            {t("CalendarType")}
                          </label>
                          <Select
                              options={initialSource.calendarTypes}
                              placeholder={t("selection")}
                              classNamePrefix={"h_multi_select"}
                              menuPosition={"fixed"}
                              value={setting.calendarType}
                              onChange={(data)=>handleSelectValue(data,'calendarType')}
                          />
                        </div>
                        <div class="space-y-1 flex-1">
                          <label className="text-xs dark:text-gray-300">
                            {t("CalendarLanguage")}
                          </label>
                          <Select
                              options={initialSource.calendarLanguages}
                              placeholder={t("selection")}
                              classNamePrefix={"h_multi_select"}
                              menuPosition={"fixed"}
                              value={setting.calendarLanguage}
                              onChange={(data)=>handleSelectValue(data,'calendarLanguage')}
                          />
                        </div>
                        <div class="space-y-1 flex-1">
                          <label className="text-xs dark:text-gray-300">
                            {t("CalendarView")}
                          </label>
                          <Select
                              options={initialSource.calendarViewTypes}
                              placeholder={t("selection")}
                              classNamePrefix={"h_multi_select"}
                              menuPosition={"fixed"}
                              value={setting.calendarView}
                              onChange={(data)=>handleSelectValue(data,'calendarView')}
                          />
                        </div>
                      </div>
                      <div className={"grid grid-cols-1 md:grid-cols-2 items-end gap-2 post-input dark:bg-themeDarkPostInput p-2 post-input-design"}>
                        <div className={"space-y-1"}>
                          <label className="text-xs dark:text-gray-300">{t("FirstDayOfTheWeek")}</label>
                          <div>
                            <Select
                                options={initialSource.weekDays}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                                value={setting.firstDayOfWeek}
                                onChange={(data)=>handleSelectValue(data,'firstDayOfWeek')}
                            />
                          </div>
                        </div>
                        <div class="space-y-1 ">
                          <label className="text-xs dark:text-gray-300">
                            {t("GeographicRegion")}
                          </label>
                          <Select
                              options={initialSource.countries}
                              placeholder={t("selection")}
                              classNamePrefix={"h_multi_select"}
                              menuPosition={"fixed"}
                              value={setting.countryId}
                              onChange={(data)=>handleSelectValue(data,'countryId')}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionTab>

                  <AccordionTab className={"bg-white dark:bg-themeDarkSalt700"}
                                header={
                                  <div className="flex items-center gap-2">
                                    <HPrimeIcon icon={'pi-image'} />
                                    <p>{t("Images")}</p>
                                  </div>
                                }
                  >
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                      <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-2">
                        <div className={"flex items-center gap-1"}>
                          <input type={"checkbox"} id={"watermarkActivation"} checked={setting.watermarkActivation} onChange={(e)=>handleSelectValue(e.target.checked,'watermarkActivation')} />
                          <label className="text-xs dark:text-gray-300 cursor-pointer" htmlFor={"watermarkActivation"}>{t("DoesItHaveAWatermark")}</label>
                        </div>
                        <div className={"flex items-center gap-2"}>
                          <div class="space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">{t("WatermarkText")}</label>
                            <InputText onInput={(e)=>handleValue(e)} name={'watermarkText'} value={setting.watermarkText} className="input w-full"/>
                          </div>
                          <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("FontSize")}</label>
                            <div className={"w-24"}>
                              <InputNumber value={setting.watermarkFontSize} min={0} name={'watermarkFontSize'} onChange={(e)=>handleNumberValue(e.value,'watermarkFontSize')}  showButtons />
                            </div>
                          </div>
                        </div>
                        <div class="space-y-1">
                          <label className="text-xs dark:text-gray-300">{t("Location")}</label>
                          <Select
                              options={initialSource.positions}
                              placeholder={t("selection")}
                              classNamePrefix={"h_multi_select"}
                              menuPosition={"fixed"}
                              value={setting.watermarkPosition}
                              onChange={(data)=>handleSelectValue(data,'watermarkPosition')}
                          />
                        </div>
                      </div>
                      <div className={"post-input dark:bg-themeDarkPostInput p-2 post-input-design space-y-2"}>
                        <div className={"space-y-1"}>
                          <label className="text-xs dark:text-gray-300">{t("Backgroundcolor")}</label>
                          <div className=" flex items-center justify-between p-1 bg-white box dark:bg-themeDarkSalt700">
                            <div className="flex gap-2 items-center">
                              <h2 className={"text-xs dark:text-gray-300 text-nowrap"}>{t("CodeHEX")}</h2>
                              <span className="text-themeInformation dark:text-themeDarkInformation text-xs">({setting.watermarkBackgroundColor})</span>
                            </div>
                            <div className={"flex gap-2 items-center"}>
                              <InputText value={setting.watermarkBackgroundColor} className={"w-32 input"} name={'watermarkBackgroundColor'} onInput={(e)=>handleValue(e)} maxLength="7" />
                              <ColorPicker  inputId="cp-hex" format={'hex'} value={setting.watermarkBackgroundColor} onChange={(e) => handleSelectValue(e.value,'watermarkBackgroundColor')} className="w-10 "/>
                            </div>
                          </div>
                        </div>
                        <div className={"space-y-1"}>
                          <label className="text-xs dark:text-gray-300">{t("Textcolor")}</label>
                          <div className=" flex items-center justify-between p-1 bg-white box dark:bg-themeDarkSalt700">
                            <div className="flex gap-2 items-center">
                              <h2 className={"text-xs dark:text-gray-300 text-nowrap"}>{t("CodeHEX")}</h2>
                              <span className="text-themeInformation dark:text-themeDarkInformation text-xs">({setting.watermarkFontColor})</span>
                            </div>
                            <div className={"flex gap-2 items-center"}>
                              <InputText value={setting.watermarkFontColor} className={"w-32  input"} name={'watermarkFontColor'} onInput={(e)=>handleValue(e)} maxLength="7" />
                              <ColorPicker inputId="cp-hex" format={'hex'} value={setting.watermarkFontColor} onChange={(e) => handleSelectValue(e.value,'watermarkFontColor')} className="w-10 "/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionTab>

                  <AccordionTab className={"bg-white dark:bg-themeDarkSalt700"}
                                header={
                                  <div className="flex items-center gap-2">
                                    <HPrimeIcon icon={'pi-globe'}/>
                                    <p>{t("CurrencyUnit")}</p>/<p>{t("Language")}</p>
                                  </div>
                                }
                  >
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                      <div className="post-input dark:bg-themeDarkPostInput post-input-design p-2">
                        <div class="space-y-1">
                          <div className={"flex gap-1 items-center"}>
                            <ButtonNotification
                                onClick={() => handleShowMessage(true)}
                            />
                            <label className="dark:text-gray-300 text-xs">
                              {t("CurrencyUnit")}
                            </label>
                          </div>
                          <Select
                              options={initialSource.currencies}
                              placeholder={t("selection")}
                              classNamePrefix={"h_multi_select"}
                              menuPosition={"fixed"}
                              value={setting.currency}
                              onChange={(data)=>handleSelectValue(data,'currency')}
                          />
                        </div>
                      </div>
                    </div>
                  </AccordionTab>

                  <AccordionTab className={"bg-white dark:bg-themeDarkSalt700"}
                                header={
                                  <div className="flex items-center gap-2">
                                    <HPrimeIcon icon={'pi-pen-to-square'}/>
                                    <p>{t("DefaultValues")}</p>
                                  </div>
                                }
                  >
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
                      <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                        <div className={"space-y-1"}>
                          <label className="text-xs dark:text-gray-300">
                            {t("EditorType")}
                          </label>
                          <Select
                              options={initialSource.editors}
                              placeholder={t("selection")}
                              classNamePrefix={"h_multi_select"}
                              menuPosition={"fixed"}
                              value={setting.editor}
                              onChange={(data)=>handleSelectValue(data,'editor')}
                          />
                        </div>
                      </div>
                      <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                        <div class="space-y-1 flex-1">
                          <label className="text-xs dark:text-gray-300">{t("ChooseColorTheme")}</label>
                          <Select
                              options={initialSource.colorThemes}
                              placeholder={t("selection")}
                              className={"w-full "}
                              classNamePrefix={"h_multi_select"}
                              value={setting.theme}
                              menuPosition={"fixed"}
                              onChange={(data)=>handleSelectValue(data,'theme')}
                          />
                        </div>
                      </div>
                      <div class="post-input dark:bg-themeDarkPostInput p-2 post-input-design">
                        <div className={"flex gap-2 items-center"}>
                          <div class="space-y-1 flex-1">
                            <label className="text-xs dark:text-gray-300">
                              {t("SelectFont")}
                            </label>
                            <div className={"flex gap-2 items-center"}>
                              <Select
                                  options={initialSource.fonts}
                                  placeholder={t("selection")}
                                  className={"w-full "}
                                  classNamePrefix={"h_multi_select"}
                                  value={setting.fontFamily}
                                  onChange={(data)=>handleSelectValue(data,'fontFamily')}
                                  menuPosition={"fixed"}
                              />
                            </div>
                          </div>
                          <div class="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("FontSize")}</label>
                            <Select
                                options={fontSizes}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                                value={setting.fontSize}
                                onChange={(data)=>handleSelectValue(data,'fontSize')}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionTab>

                </Accordion>
              </div>
              <ButtonSubmitAndCancel
                  onCancelClick={()=>
                  isInUse?
                      handleCloseSetting()
                      :
                    redirectTo('/Harmony/Master/setting')
              }
              />
            </div>

          </form>

        </div>

        <ToastContainer/>
      </>

  )
}