import React from "react";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";


export const UserPin=({handleUserPin,id,isPin})=>{
    return (
        <>
            <button type={"button"} onClick={()=>handleUserPin(id)} className={" cursor-pointer absolute end-1 top-1"}>
                <HPrimeIcon icon={` ${isPin?'pi-bookmark-fill text-themeInformation dark:text-themeDarkInformation':'pi-bookmark'} `} iconClass={`${isPin?'text-themeInformation dark:text-themeDarkInformation':''}`}></HPrimeIcon>
            </button>

        </>
    )
}