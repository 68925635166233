import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSettingsUrl, handleGetSettingValue } from "../../CMS/Contents/ContentService";
import { GET } from "../../../Services/AxiosService/AxiosApiService";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import { OtpAuthentication } from "./OtpAuthentication";
import { Login } from "./Login";
import { LoadingSpinner } from "../../Commans/Loadings/LoadingSpinner";
import {PasswordAndTowFactor} from "./PasswordAndTowFactor";

export const UserAuthentication = ({ handleChangeState }) => {
    const { t } = useTranslation();
    const [IsShowSpinner, setIsShowSpinner] = useState(false);

    const [authenticationState, setAuthenticationState] = useState(0);
    const handleChangeAuthenticationState = (authState) => {
        setAuthenticationState(authState);
    };

    const showSpinner = () => {
        setIsShowSpinner(true);
    };

    const hideSpinner = () => {
        setIsShowSpinner(false);
    };

    const fetchData = async () => {
        try {
            showSpinner();
            const responseSetting = await GET(`/Settings?Name=${'UPanelAuthenticationMode'}`, {}, 'single');
            if (responseSetting.status === 200) {
                const mode = handleGetSettingValue('UPanelAuthenticationMode', responseSetting.data.settings);
                handleChangeAuthenticationState(mode);
                hideSpinner();
            } else {
                handleApiError(responseSetting, hideSpinner);
            }
        } catch (e) {
            hideSpinner();
        } finally {
            hideSpinner();
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {IsShowSpinner ? (
                <div className="relative">
                    <LoadingSpinner />
                </div>
            ) : (
                (authenticationState === 0 || authenticationState === 1) ? (
                    <Login handleChangeState={handleChangeState} />
                ) : authenticationState === 2 ? (
                    <OtpAuthentication handleChangeState={handleChangeState} />
                ) : authenticationState === 3?(
                    <PasswordAndTowFactor/>
                ):null
            )}
        </>
    );
};
