
import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import Select from "react-select";


import {handleFailureMessage, handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useLocation, useNavigate} from "react-router-dom";
import queryString from "query-string";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {useTranslation} from "react-i18next";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {convertImportantEventsHandler} from "./eventService";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
export const HandleEventData = (props)=>{
    const redirectTo = useNavigate();
    const location = useLocation();
    const[result,setResult] = useState(0);

    const { t } = useTranslation();
    const [events,setEvent] = useState([{ id: new Date().getTime(), caption: null,userOccasionId:null, type: null, eventDate:null,reminderOffsetUnit:null,reminderOffsetValue:0,description:null,hasReminder:true }]);
    const [isChanged,setChanged] = useState(false);
    const [selectOptions,setSelectOptions] = useState({OffsetUnit:[],Types:[]})
    const[spinner,setSpinner] = useState(false);

    const onApiError = () => {
        setSpinner(false);
    };

    const handleAddRow = () => {
        const newRow = {
            id:new Date().getTime(),
            caption: null,
            type: selectOptions.Types[0],
            eventDate: new Date(),
            description:null,
            hasReminder:true,
            userOccasionId:null,
            reminderOffsetUnit:selectOptions.OffsetUnit[0],
            reminderOffsetValue:0
        };
        setChanged(true);
        setEvent(prevList => [...prevList,newRow]);
        setIsDefault(false);
    };


    const [isDefault,setIsDefault] = useState(false);
    const handleRemoveRow = (id,userOccasionId) => {
        if(events.length === 1){
            setChanged(true);
            setIsDefault(true);
            const newRow = {
                id:new Date().getTime(),
                caption: null,
                type: selectOptions.Types[0],
                eventDate: new Date(),
                description:null,
                hasReminder:true,
                // userOccasionId:null,
                reminderOffsetUnit:selectOptions.OffsetUnit[0],
                reminderOffsetValue:0
            };
            setEvent(prevList => [newRow]);
            return;
        }
        setEvent((prevList) => {
            const indexToRemove = prevList.findIndex(
                (row) => row.id === id && row.userOccasionId === userOccasionId
            );
            if (indexToRemove !== -1) {
                const updatedList = [...prevList.slice(0, indexToRemove), ...prevList.slice(indexToRemove + 1)];
                return updatedList;
            }
            return prevList;
        });
        setChanged(true);// Set changed to true when a row is removed
        setIsDefault(false);
    };

    const handleChange = (id,field, value, userOccasionId) => {
        setEvent((prevList) => {
            const updatedList = prevList.map((row) => {
                if   ((userOccasionId && row.userOccasionId === userOccasionId) || (!userOccasionId && id && row.id === id)) {
                    return { ...row, [field]: value };
                }
                return row;
            });
            return updatedList;
        });
        setChanged(true);
        setIsDefault(false)
    };



    const DateTimeHandler = (value,id,field,userOccasionId)=>{
        if(id){
            setEvent(prevList =>
                prevList.map(row => (row.id === id || userOccasionId === row.userOccasionId ? { ...row, [field]: value } : row))
            );
        }
        else if(userOccasionId){
            setEvent(prevList =>
                prevList.map(row => (userOccasionId === row.userOccasionId ? { ...row, [field]: value } : row))
            );
        }
        setChanged(true);
        setIsDefault(false);
    }
    const convertEventTypeHandler = (requestObject,requiredObject,noneRequired)=>{
        const filteredMeta = requestObject.map(item => ({
            ...item,
            reminderOffsetUnit: requiredObject.find(typeItem => typeItem.value === item.reminderOffsetUnit),
            type: noneRequired.find(typeItem => typeItem.value === item.type)
        }));
        return filteredMeta;
    }
const [stamp,setStamp] = useState("");
    const handleFetchData=async()=>{
        try {
            setSpinner(true)
            const responseOffsetUnit = await GET(`/Enumerations/EnTimeUnit`,{},'IEnumerable');
            const responseTypes= await GET('/Enumerations/EnOccasionType',{},'IEnumerable');
            if(responseOffsetUnit.status === 200 && responseTypes.status === 200){
                const timeUnit = convertSelectDataHandler(responseOffsetUnit.data);
                const types = convertSelectDataHandler(responseTypes.data);
                setSelectOptions((prevOptions) => ({...prevOptions, OffsetUnit: timeUnit,Types: types}));
                setSpinner(false)
                const queryParams = queryString.parse(window.location.search);
                if(queryParams.id){
                    // setSpinner(true)
                      const response = await GET(`/Users/ImportantOccasions/${queryParams.id}`,{},'IEnumerable');
                      if(response.status === 200){
                          setStamp(response.data.concurrencyStamp)
                          const filteredData = convertEventTypeHandler(response.data.importantOccasions,timeUnit,types);
                          setEvent(filteredData)
                          setEvent(filteredData.map((row) => ({ ...row,userOccasionId:row.userOccasionId })));
                          //setSpinner(false);
                      }else{
                          handleApiError(response,onApiError)
                      }
                }
            }
            else {
                handleApiError(responseOffsetUnit,onApiError);
                handleApiError(responseTypes,onApiError);
            }
        } catch (error) {
           console.log(error)
        }
    }

    useEffect(()=>{
        handleFetchData();
    },[result])

    useEffect(()=>{
        if (events.length === 0) {
            handleAddRow();
        }
    },[events])

    const HandleImportantEventSubmit =async (type,nextTab)=>{
        if(isDefault === true){
            try {
                setSpinner(true);
                const queryParams = queryString.parse(window.location.search);
                const convertedData = convertImportantEventsHandler([],queryParams.id || null,stamp);
                const response = await POST(`/Users/ImportantOccasions/Add`,convertedData,false);
                if(response.status === 200){
                    setSpinner(false);
                    handleSuccessMessage(t("Informationhassuccessfully"));
                    setResult({...response.status})
                    if(type === 'register'){
                        if(location.pathname === '/CRM/User/Add'){
                            redirectTo('/CRM/User/List');
                        }
                    }
                    if(type === 'previous'){
                        props.handleTabClick(nextTab);
                        return;
                    }
                    if(type === 'next'){
                        props.handleTabClick(nextTab);
                        return;
                    }
                    //props.handleTabClick('document');

                }else{
                    handleApiError(response,onApiError)
                }
            }catch (e) {
                console.log(e)
            }
        }
        else{
            const noneCaptions = events.filter((item)=>item.caption === null || item.caption === "" || item.caption === undefined);
            if(noneCaptions.length !== 0){
                handleFailureMessage(t("Thetitleismandatory"));
                return;
            }
            const nonNullRows = events.filter((row) => (row.caption && row.reminderOffsetUnit && row.reminderOffsetValue && row.type && row.eventDate) ||  row.description !== undefined );
            if(isChanged === true && nonNullRows.length > 0){
                try {
                    setSpinner(true);
                    const queryParams = queryString.parse(window.location.search);
                    const convertedData = convertImportantEventsHandler(nonNullRows,queryParams.id || null,stamp);
                    const response = await POST(`/Users/ImportantOccasions/Add`,convertedData,false);
                    if(response.status === 200){
                        setSpinner(false);
                        handleSuccessMessage(t("Informationhassuccessfully"));
                        setResult({...response.status})
                        if(type === 'register'){
                            if(location.pathname === '/CRM/User/Add'){
                                redirectTo('/CRM/User/List');
                            }
                        }
                        if(type === 'previous'){
                            props.handleTabClick(nextTab);
                            return;
                        }
                        if(type === 'next'){
                            props.handleTabClick(nextTab);
                            return;
                        }
                        //props.handleTabClick('document');

                    }else{
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    console.log(e)
                }
            }else{
                if(type === 'register'){
                    redirectTo('/CRM/User/List');
                    return;
                }
                if(type === 'previous'){
                    props.handleTabClick(nextTab);
                    return;
                }
                if(type === 'next'){
                    props.handleTabClick(nextTab);
                    return;
                }
            }
            props.handleTabClick(nextTab);
        }
    }


    return (
        <>
            <div className="relative space-y-5">
                {
                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div className={"space-y-3"}>
                    <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation flex items-center gap-2">
                        <h2 className={"dark:text-gray-300"}>
                            {t("EventManagement")}
                        </h2>
                    </div>
                    <div className="px-2 space-y-5 max_height85vh">
                        {events.map((row,index) => (
                            <div key={row.id}
                                 className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 items-end gap-2 post-input p-2 post-input-design dark:bg-themeDarkPostInput">
                                <div className={"flex gap-2 "}>
                                    <div className="mt-auto">
                                        <div className="number_pluse bg-themeInformation text-white">
                                            {index + 1}
                                        </div>
                                    </div>
                                    <div className="space-y-1 flex-1">
                                        <label className="text-xs dark:text-gray-300">{t("Title")}</label>
                                        <input type="text" className="input w-full"
                                               value={row.caption}
                                               onChange={(e) => handleChange(row.id, 'caption', e.target.value, row.userOccasionId)}
                                        />
                                    </div>
                                </div>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("type")}</label>
                                    <Select options={selectOptions.Types}
                                            isMulti={false}
                                            isClearable={false}
                                            isSearchable={true}
                                            onChange={(data) => handleChange(row.id, 'type', data, row.userOccasionId)}
                                            placeholder={t("selection")}
                                            value={row.type}
                                            menuPosition={"fixed"}
                                            classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("EventDate")}</label>
                                    <DateTimePicker isContainsTime={true} handleDateTime={DateTimeHandler}
                                                    fakeId={row.id} realId={row.userOccasionId} name={'eventDate'}
                                                    format={'YYYY/MM/DD hh:mm:ss'} value={row.eventDate}/>
                                </div>
                                <div>
                                    <div className="flex gap-2 items-center  ">
                                        <div>
                                            <label className="text-xs dark:text-gray-300">{t("Reminder")}</label>
                                            <div className=" space-y-2 flex flex-col items-center">
                                                <input checked={row.hasReminder}
                                                       className="input input--switch border bg-white"
                                                       onChange={(e) => handleChange(row.id, 'hasReminder', e.target.checked, row.userOccasionId)}
                                                       type="checkbox"/>
                                            </div>
                                        </div>
                                        <div className="flex-1 w-full">
                                            <label className="text-xs dark:text-gray-300">{t("Value")}</label>
                                            <InputText disabled={!row.hasReminder} className="input w-full"
                                                       value={row.reminderOffsetValue}
                                                       onChange={(e) => handleChange(row.id, 'reminderOffsetValue', e.target.value, row.userOccasionId)}/>
                                        </div>

                                    </div>
                                </div>
                                <div className="flex-1 flex flex-col lg:flex-row items-end gap-2">
                                    <div className="flex-1 w-full space-y-1">
                                        <label
                                            className="text-xs dark:text-gray-300">{t("ReminderInterval")}</label>
                                        {/*<EventDatePicker handleDateTime={DateTimeHandler} id={row.id} name={'reminderDate'} format={'YYYY/MM/DD hh:mm:ss'} value={row.reminderDate}/>*/}
                                        <div>
                                            <Select
                                                options={selectOptions.OffsetUnit}
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={(data) => handleChange(row.id, 'reminderOffsetUnit', data, row.userOccasionId)}
                                                name={'reminderOffsetUnit'}
                                                placeholder={t("selection")}
                                                value={row.reminderOffsetUnit}
                                                isDisabled={!row.hasReminder}
                                                className="w-full"
                                                menuPosition={"fixed"}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-1 md:col-span-2 xl:col-span-4">
                                    <label className="text-xs dark:text-gray-300">{t("description")}</label>
                                    <input type="text" className="input w-full" value={row.description}
                                           onChange={(e) => handleChange(row.id, 'description', e.target.value, row.userOccasionId)}
                                    />
                                </div>
                                <div className="flex items-end justify-end gap-2">
                                    <button type="button"
                                            className="button bg-themeInformation dark:bg-themeDarkInformation text-white small_button "
                                            onClick={handleAddRow}>
                                        <HPrimeIcon icon={'pi-plus'}/>
                                    </button>
                                    <button type="button"
                                            className="button bg-themeDanger text-white small_button "
                                            onClick={() => handleRemoveRow(row.id, row.userOccasionId)}>
                                        <HPrimeIcon icon={'pi-minus'}/>
                                    </button>
                                    {/*{row.id === events.length && (*/}
                                    {/*   */}
                                    {/*)}*/}

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap items-center justify-center lg:justify-end  gap-3">
                <button onClick={() => HandleImportantEventSubmit('previous', 'communication')}
                        className="button w-24 text-white bg-themeDanger">
                    {t("previous")}
                </button>
                <button onClick={() => HandleImportantEventSubmit('register')}
                    className="button w-24 text-white bg-themeSuccess">
                    {t("register")}
                </button>
                <button onClick={() =>HandleImportantEventSubmit('next',"document")}
                    className="button  text-white bg-themeInformation dark:bg-themeDarkInformation">
                    {t("registerAndNext")}
                </button>
            </div>
        </>
    )
}