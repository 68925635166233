import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../Commans/UIParts/FantasyModal";
import {GridTable} from "../Commans/Grid/GridTable";
import {ListSearch} from "../Commans/Grid/ListSearch";
import {ListFilter} from "../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {GET, PUT} from "../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../CMS/Contents/ContentService";
import {extractUsers} from "../GeneralManagement/WebSite/WebsiteCreate/websiteService";
import {handleApiError} from "../../Services/Globals/Errors/handleApiError";
import {DateTimePicker} from "../Commans/Dates/DateTimePicker";
import Select from "react-select";
import {DisplayError} from "../Commans/Errors/DisplayError";
import {handleFailureMessage, handleSuccessMessage} from "../../Services/Globals/Errors/NotificationService";
import {Export} from "../Commans/Grid/Export";
import {ToastContainer} from "react-toastify";
import {validate} from "../../Services/Globals/validate";
import {productGuranteeSchema} from "./productGuranteeSchema";
import {LoadingHarmony} from "../Commans/Loadings/LoadingHarmony";
import {Pagination} from "../Commans/Grid/Pagination";
import {InputSwitchListFilter} from "../Commans/Filters/InputSwitchListFilter";
import {useTranslation} from "react-i18next";
import {GridDateFomartter} from "../Commans/Grid/GridDateFomartter";
import {GridSetting} from "../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../Services/Globals/Translations/Resources";
import {ButtonPlus} from "../Commans/UIParts/ButtonPlus";
import {MultiDataSelect} from "../Commans/UIParts/MultiDataSelect";
import {ButtonSearchList} from "../Commans/UIParts/ButtonSearchList";
import queryString from "query-string";
import {useSetting} from "../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {SettingButton} from "../Commans/UIParts/SettingButton";
import {HandleFastRegister} from "../CRM/PersonsManagement/UserRegisteration/HandleFastRegister";
import {Tooltip} from "react-tooltip";
import {GridButtonEdit} from "../Commans/Grid/GridButtonEdit";
import {RequiredIcon} from "../Commans/Errors/RequiredIcon";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";

export const HandleAddGuarrantyCustomer=()=>{


    const { t } = useTranslation();

    const redirectTo = useNavigate();
    const [spinner,setSpinner] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [extend,setExtend] = useState(false);
    const [errors, setErrors] = useState({});
    const [result,setResult] = useState(0);
    const [productGuranteId,setProductGuranteeId] = useState(null);
    const [options,setOptions] = useState({timeUnits:[],buyers:[],guranteeStatus:[]});
    const [gurantee,setGurantee] = useState({active:true,buyerId:null,productGuranteeId:null,timeUnit:null,time:null,date:null,productCode:null,productName:null,guranteeCode:null});
    const [expand,setExpand] = useState({productGuranteeId:null,extendTime:null});
    const [refresh,setIsRefresh] = useState(false);
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const [isOpen,setOpen] = useState(false);
    const handleOpenModal = ()=>{
        setOpen(true);
    }

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleSuccessUserFastRegister=(isSuccess)=>{
        if(isSuccess){
            handleCloseModal();
            setIsRefresh(prevState => !prevState);
        }
    }




    const onApiError = ()=>{
        setSpinner(false);
    }
    const onSetErrors = (errors)=>{
        setErrors(errors);
    }
    const handleCloseExtend = ()=>{
        setExtend(false);
        setExpand((prevState)=>({...prevState,extendTime: null}));
    }

    const handleOpenExtend=(productGuranteeId,expireDate)=>{
        setExtend(true);
        setExpand({productGuranteeId: productGuranteeId,extendTime: expireDate});
    }

    const handleGuranteeDate = (dateTime)=>{
        setGurantee({...gurantee,date: dateTime})
    }

    const handleCloseProductGurantee = ()=>{
        redirectTo('/Guarantee/Customer');
        setErrors({})
        setGurantee((prevState)=>({...prevState,buyerId: options.buyers[0],guranteeCode: '',productGuranteeId: '',productCode: '',productName: '',date: new Date(),active: true}));
        setIsEdit(false);
        setModal(false);
    }
    const handleSelectValue=(data,name)=>{
            setGurantee({ ...gurantee, [name]: data });
    }

    const handleValue=(e)=>{
        const {name,value} = e.target;
        setGurantee({...gurantee,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleCreditTime = (timeUnit,value) => {
        if (!options.timeUnits) {
            return;
        }
        const data = options.timeUnits.find((item) => item.value === timeUnit);
        return data ?   value + '  '+ data.label : '';
    }

    const handleGetGurranteeData = async(e)=>{
        const {value} = e.target;
        if(value === null || value === undefined || value === ""){
            handleFailureMessage(t("Guaranteecodeismandatory"));
            return;
        }
        if(value.length !== 16){
            handleFailureMessage(t("Sixteencharactersarerequired"));
            return;
        }
        try {
           setSpinner(true);
          const response = await GET(`/ProductGuarantees/GetProductGuaranteeByGuaranteeCode?GuaranteeCode=${value}`);
          if(response.status === 200){
              var time = handleCreditTime(response.data.timeUnit,response.data.timeValue);
              setGurantee((prevState)=>({...prevState,time: time, productCode: response.data.productCode, productName: response.data.productName, productGuranteeId: response.data.productGuaranteeId}))
              setSpinner(false);
          }else{
              handleApiError(response,onApiError)
          }
        }
        catch (e) {
            console.log(e);
        }
    }


    const handleFetchData = async ()=>{
        try {
            setSpinner(true);
            const responseStatus = await GET('/Enumerations/EnGuaranteeStatus',{},'IEnumerable');
            const responseBuyer = await GET('/Users',{PageNumber:1,PageSize:1000},'IEnumerable');
            const responseTime = await GET(`Enumerations/EnTimeUnit?keys=${5}&keys=${6}`,{},'IEnumerable',onApiError);
            if(responseBuyer.status === 200 && responseTime.status === 200){
                const buyers = extractUsers(responseBuyer.data.items);
                const timeUnits = convertSelectDataHandler(responseTime.data);
                const statuses = convertSelectDataHandler(responseStatus.data);
                setOptions({timeUnits: timeUnits,buyers: buyers,guranteeStatus: statuses});
                setGurantee((prevState)=>({...prevState,date: new Date(),buyerId: buyers[0],active:true}));
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            setSpinner(false)
        }
    }
    const handleEditRecord =async(productGuranteeId)=>{
     try {
         setIsEdit(true);
         setSpinner(true);
         const response = await GET(`/ProductGuarantees/${productGuranteeId}`);
         if(response.status === 200){
             const buyer = options.buyers.find((item)=>item.value === response.data.buyerId);
             var time = handleCreditTime(response.data.timeUnit,response.data.timeValue);
             var timeUnit = options.timeUnits.find((item)=>item.value === response.data.timeUnit);
             var isActive = response.data.status === 2 ? true : (response.data.status === 4 ? false : 0);
             setGurantee({
                 buyerId: buyer,
                 productGuranteeId: response.data.productGuaranteeId,
                 date: new Date(),
                 productName: response.data.productName,
                 productCode: response.data.productCode,
                 guranteeCode: response.data.guaranteeCode,
                 time: time,
                 timeUnit: timeUnit,
                 active:isActive
             })
             setSpinner(false);
             setModal(true);
             redirectTo(`/Guarantee/Customer?id=${productGuranteeId}`);
         }
         else{
             handleApiError(response,onApiError);
         }
     }
     catch (e) {
         console.log(e);
     }
    }



    const handleGuranteeUpdate = async(e)=>{
        e.preventDefault();
        const isValid = validate(productGuranteeSchema,gurantee,onSetErrors);
        if(!isValid){
            return ;
        }
      try {
          setSpinner(true)
          var status = gurantee.active === true?2:4;
          const productGuranteeId = gurantee.productGuranteeId === null || gurantee.productGuranteeId === "" ? null : gurantee.productGuranteeId;
          const response = await PUT(`/ProductGuarantees/EditProductGuarantee`,{productGuaranteeId:productGuranteeId,buyerId:gurantee.buyerId.value,status:status},false);
          if(response.status === 200){
              setResult({...response.status});
              handleSuccessMessage(!isEdit? t("ProductGuaranteehasbeensuccessfullyadded"): t("Guaranteehasbeensuccessfullyedited"));
              handleCloseProductGurantee();
              setSpinner(false);
          }
          else{
              handleApiError(response,onApiError)
          }
      }
        catch (e) {
          console.log(e);
      }
    }
    useEffect(()=>{
        handleFetchData();
    },[])


    const handleExtendDateTime=(dateTime)=>{
        setExpand((prevState)=>({...prevState,extendTime: dateTime}));
    }

    const handleSubmitExtend = async(e)=>{
        e.preventDefault();
        if(expand.extendTime === null){
            handleFailureMessage(t("Timeselectionismandatory"));
            return ;
        }
        try {
           setSpinner(true)
           const response = await PUT(`/ProductGuarantees/ProductGuaranteesExtend`,{newExpireDate:expand.extendTime,productGuaranteeId:expand.productGuranteeId});
           if(response.status === 200){
             setResult({...response.status});
             setExtend(false);
             setSpinner(false)
             setExpand((prevState)=>({...prevState,extendTime: null}));
             handleSuccessMessage(t("Guaranteeextensionhasbeensuccessfullycompleted"));
           }
           else{
               handleApiError(response,onApiError);
           }
        }
        catch (e) {
            console.log(e);
        }
    }





    const handleStatus = (status)=>{
        if (!options.guranteeStatus) {
            return;
        }
        const data = options.guranteeStatus.find((item) => item.value === status);
        return data ? data.label : '';
    }
    const handleGuranteeTime = (timeUnit,value) => {
        if (!options.timeUnits) {
            return;
        }
        const data = options.timeUnits.find((item) => item.value === timeUnit);
        return data ?   value + '  '+ data.label : '';
    }



    const columnDefs = [
        { headerName: t("row"), field: 'id',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110,hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 110, maxWidth: 110},
        { headerName: t("CustomerName"), field: 'userName', minWidth:150,},
        { headerName: t("ProductName"), field: 'productName', minWidth:160,},
        { headerName: t("NumberofExtensions"), field: 'extendCount', minWidth: 170,},
        { headerName: t("GuaranteePeriod"), field: 'timeUnit', minWidth: 170,cellRenderer:params => {
                {return handleGuranteeTime(params.data.timeUnit,params.data.timeValue)}
        }},

        { headerName: t("ActivationDate"), field: 'redeemDate', minWidth: 160,cellRenderer:params => {
                return  <GridDateFomartter date={params.data.redeemDate} hasTime={false} />


            }},
        { headerName: t("ExpirationDate"), field: 'expirationDate', minWidth: 160,cellRenderer:params => {
                return <GridDateFomartter date={params.data.expirationDate} hasTime={false} />
            }},
        { headerName: t("GuaranteeStatus"), field: 'status', minWidth: 160,cellRenderer:params => {
                {return handleStatus(params.data.status)}
            }},



        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <button className=" relative tooltip_class_History" onClick={()=>redirectTo(`/Guarantee/Customer/History?productGuaranteeId=${params.data.productGuaranteeId}`)}>
                        <HPrimeIcon icon={'pi-calendar'}
                        iconClass={"text-themeInformation dark:text-themeDarkInformation"}
                        />
                        <Tooltip anchorSelect={".tooltip_class_History"} content={t("History")} positionStrategy={"fixed"}/>
                    </button>
                    <button className=" tooltip_class_extension relative" onClick={()=>handleOpenExtend(params.data.productGuaranteeId,params.data.expirationDate)}>
                        <HPrimeIcon icon={'refresh-ccw'}
                        iconClass={"text-themeInformation dark:text-themeDarkInformation "}
                        />
                        <Tooltip anchorSelect={".tooltip_class_extension"} content={t("extension")} positionStrategy={"fixed"}/>
                    </button>
                    <GridButtonEdit
                        onClick={()=>handleEditRecord(params.data.productGuaranteeId)}
                    />
                </div>
            }
        },

    ];



    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:10,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',fromData:null,toData:null,isActive:null,isDeActive:null,isExpired:null,isExtend:null,SearchOnActivationDate:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...filter,search: search}));
    }
    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,toData: null,fromData: null,isActive: null,isDeActive: null,search:'',isExpired:null,isExtend: null,SearchOnActivationDate: null}));
        setSearch('');
    }

    const handleRegisterToDateTime = (dateTime)=>{
        setFilter({...filter,toData: dateTime})
    }
    const handleRegisterFromDateTime = (dateTime)=>{
        setFilter({...filter,fromData: dateTime})
    }

    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };

        loadData();
    }, [result, currentPage, filter.search, data.pageSize,filter.isDeActive,filter.isExpired,filter.isExtend,filter.isActive,filter.fromData,filter.toData]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        const queryParams = queryString.parse(window.location.search);
        if(queryParams.userId){
            try {
                setSpinner(true);
                const response =  await GET(`/Users/GetUserProductGuarantees`, {PageNumber: currentPage, PageSize:data.pageSize, Search: filter.search,UserId:queryParams.userId}, 'PagedList');
                if (response && response.items !== null ) {
                    setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                    var idSequence = 1;
                    const modifiedItems = response.items.map((item) => ({
                        ...item,
                        idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                    }));
                    setRowData(modifiedItems);
                }
                else {
                    handleApiError(response,onApiError);
                }
                return response;
            }
            catch (error) {
                console.error(error);
            } finally {
                setSpinner(false);
            }
        }
        else{
            try {
                setSpinner(true);
                const response =  await GET(`/ProductGuarantees`, {PageNumber: currentPage, PageSize:data.pageSize, Search: filter.search,SearchOnActivationDate:filter.SearchOnActivationDate, IsActive: filter.isActive, IsDeActive: filter.isDeActive,IsExpired:filter.isExpired,IsExtended:filter.isExtend,FromDate:filter.fromData,ToDate:filter.toData}, 'PagedList');
                if (response && response.items !== null ) {
                    setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                    var idSequence = 1;
                    const modifiedItems = response.items.map((item) => ({
                        ...item,
                        idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                    }));
                    setRowData(modifiedItems);
                }
                else {
                    handleApiError(response,onApiError);
                }
                return response;
            }
            catch (error) {
                console.error(error);
            } finally {
                setSpinner(false);
            }
        }

    };














    const switchData = [
        {name:'isExtend',lable:t("Extended"),value:filter.isExtend},
        {name:'isExpired',lable:t("expired"),value:filter.isExpired},
        {name:'isActive',lable:t("Active"),value:filter.isActive},
        {name:'isDeActive',lable:t("Inactive"),value:filter.isDeActive},
    ]



const handleActivateChange =(data,name)=>{
        setGurantee((prevState)=>({...prevState,[name]:data}));
}



    const [isMenuOpen,setMenuOpen] = useState(false);

    const handleOpenMenu = (productGuranteeId) => {
        setMenuOpen(true);
        setProductGuranteeId(productGuranteeId);
    };
    const handleCloseMenu = ()=>{
        setMenuOpen(false);
    }








    return(
        <>

            <div class="space-y-5 relative">
                {
                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("search")}</label>
                                <InputText type="search" value={search} onChange={(e)=>setSearch(e.target.value)} onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full"  />
                            </div>
                            {/*<div class="space-y-2">*/}
                            {/*    <label className="text-xs dark:text-gray-300">براساس خریدار</label>*/}
                            {/*    <MultiDataSelect placeholder={"انتخاب کنید"} className={" w-full"} />*/}
                            {/*</div>*/}
                        <div class=" space-y-2 ">
                                    <div className={"flex justify-between items-center"}>
                                        <label className="text-xs dark:text-gray-300 text-nowrap flex gap-1 items-center">
                                            <SettingButton
                                                onClick={()=>handleSetting('GeneralSetting')}
                                                tooltipTitle={t("Calendarsettings")}
                                            />
                                            {t("FromDate")}
                                        </label>
                                        <div className="flex items-center gap-2">
                                            <div class="can-toggle demo-rebrand-1 text-xs w-32">
                                                <input id="swich" onChange={(e)=>setFilter(()=>({...filter,SearchOnActivationDate:e.target.checked}))} type="checkbox"/>
                                                <label htmlFor="swich">
                                                    <div class="can-toggle__switch" data-checked={t("activation")} data-unchecked={t("expired")}></div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <DateTimePicker handleDateTime={handleRegisterFromDateTime} value={filter.fromData} format={'YYYY/MM/DD'} />
                                </div>
                                <div class=" space-y-2">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            onClick={()=>handleSetting('GeneralSetting')}
                                            tooltipTitle={t("Calendarsettings")}
                                        />
                                        {t("ToDate")}
                                    </label>
                                    <DateTimePicker value={filter.toData} handleDateTime={handleRegisterToDateTime} format={'YYYY/MM/DD'} />
                                </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() => setModal({ AddGarranti: true })}
                                            tooltipTitle={t("GuaranteeRegistration")}
                                        />

                                        <ButtonPlus
                                            onClick={() => handleOpenModal()}
                                            tooltipTitle={t("fastRegistration")}
                                            title={t("UsersRegistration")}
                                            icon={"pi-user-plus"}
                                        />


                                        <ButtonPlus
                                            onClick={() => redirectTo("/Guarantee/Add")}
                                            tooltipTitle={t("NewGuaranteeCode")}
                                            title={t("GuaranteeCode")}
                                            icon={"pi-pen-to-square"}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />

                </div>
                <div className="space-y-2 H-body-ag-grid ">

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export/>
            </div>

            <FantasyModal openModal={extend}  closeModal={handleCloseExtend} title={t("extension")}>
              <form method={'post'} onSubmit={handleSubmitExtend}>
                  <div className="space-y-5">
                      <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                          <div class="space-y-1">
                              <label className="text-xs dark:text-gray-300">
                                      <SettingButton
                                          onClick={()=>handleSetting('GeneralSetting')}
                                          tooltipTitle={t("Calendarsettings")}
                                      />
                                      {t("DateTime")}
                                  </label>

                              <DateTimePicker  handleDateTime={handleExtendDateTime} value={expand.extendTime} format={'YYYY/MM/DD'} />
                          </div>
                          <div class="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("Notification")}</label>
                              <MultiDataSelect placeholder={t("selection")} />
                          </div>
                      </div>

                      <button className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("register")}</button>
                  </div>
              </form>
            </FantasyModal>
            <FantasyModal openModal={modal}  closeModal={handleCloseProductGurantee} title={t("GuaranteeRegistration")}>
              <form method={'post'} onSubmit={handleGuranteeUpdate}>
                  <div className={"space-y-5"}>
                      <div className="grid grid-cols-2 gap-3 ">
                          <div class="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("BuyersName")}</label>
                              <Select
                                  options={options.buyers}
                                  isMulti={false}
                                  isSearchable={true}
                                  onChange={(data)=>handleSelectValue(data,'buyerId')}
                                  placeholder={t("selection")}
                                  value={gurantee.buyerId}
                                  classNamePrefix={"h_multi_select"}
                              />
                          </div>
                          <div class="space-y-1">
                              <div className="flex justify-between items-center">
                                  <label className="text-xs dark:text-gray-300">
                                      {t("GuaranteeCode")}
                                  <RequiredIcon/>
                                  </label>
                                  <DisplayError message={errors.guranteeCode}/>
                              </div>

                              <InputText type={"text"} onInput={handleValue} onBlur={handleGetGurranteeData} name={'guranteeCode'} value={gurantee.guranteeCode}  className={" input w-full"} />
                          </div>
                          <div class="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("ProductName")}</label>
                              <InputText type={"text"}  name={'productName'} value={gurantee.productName} onClick={(e)=>handleValue(e)} disabled={true}  className={" input w-full"} onInput={(e)=>handleValue(e)} />
                          </div>

                          <div class="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("ProductCode")}</label>
                              <InputText type={"text"} disabled={true} value={gurantee.productCode} name={'productCode'} onInput={(e)=>handleValue(e)} className={" input w-full"} />
                          </div>

                          <div class="space-y-1">
                              <label className="text-xs dark:text-gray-300">{t("GuaranteePeriod")}</label>
                              <InputText type={"text"} disabled={true} onInput={(e)=>handleValue(e)} name={'time'} value={gurantee.time}  className={" input w-full"} />
                          </div>
                          <div class="space-y-1">
                              <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                  <SettingButton
                                      onClick={()=>handleSetting('GeneralSetting')}
                                      tooltipTitle={t("Calendarsettings")}
                                  />
                                  {t("PurchaseDate")}
                              </label>
                              <DateTimePicker disabled={true}  handleDateTime={handleGuranteeDate} value={gurantee.date} format={'YYYY/MM/DD'} />
                          </div>

                          {
                              isEdit?
                                  <div className="flex items-center gap-2">
                                      <div class="can-toggle demo-rebrand-1 text-xs w-28">
                                          <input checked={gurantee.active} id="case" onChange={(e)=>handleActivateChange(e.target.checked,'active')} type="checkbox"/>
                                          <label htmlFor="case">
                                              <div class="can-toggle__switch" data-checked={t("isActive")} data-unchecked={t("isNotActive")}></div>
                                          </label>
                                      </div>
                                  </div>:
                                  null
                          }




                          {/*<div class="space-y-2 col-span-2">*/}
                          {/*    <label className="text-xs dark:text-gray-300">اطلاع رسانی:</label>*/}
                          {/*    <MultiDataSelect placeholder={'انتخاب'} />*/}
                          {/*</div>*/}

                      </div>
                      <div>
                          <button className="button bg-themeInformation dark:bg-themeDarkInformation text-white w-full">{t("register")}</button>
                      </div>
                  </div>

              </form>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>
            <FantasyModal openModal={isOpen} closeModal={handleCloseModal} customeClassName={"modal-xl"} title={t("fastRegistration")} fieldse_bg_Class={"bg-white dark:bg-themeDarkSalt700"}>
                <div>
                    <HandleFastRegister
                        IsDefault={false}
                        handleSuccess={handleSuccessUserFastRegister}
                        IsOpen={isOpen}
                    />
                </div>


            </FantasyModal>
            <ToastContainer/>

        </>

    )
}