import React, { useEffect, useState } from "react";
import { PickList } from "primereact/picklist";
import { LoadingHarmony } from "../../../../Commans/Loadings/LoadingHarmony";

import {useNavigate} from "react-router-dom";

import {ToastContainer} from "react-toastify";

import {useTranslation} from "react-i18next";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {Return} from "../../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../../Commans/UIParts/ButtonVideo";
import queryString from "query-string";
import {GET, PUT} from "../../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";

import {ButtonSubmitAndCancel} from "../../../../Commans/UIParts/ButtonSubmitAndCancel";
import {handleFailureMessage} from "../../../../../Services/Globals/Errors/NotificationService";
import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";

export const HandleWebSitePackages = () => {
    const { t } = useTranslation();
    const [packageSource,setPackageSource] = useState([]);
    const [packageTarget, setPackageTarget] = useState([]);
    const queryParams = queryString.parse(window.location.search);
    const redirectTo = useNavigate();
    const[domainToken,setDomainToken] = useState(null);
    const [IsRefresh,setIsRefresh] = useState(false);
    const [IsShowSpinner,setIsShowSpinner] = useState(false);

    //shows spinner
    const showSpinner=()=>setIsShowSpinner(true);
    //hides spinner
    const hideSpinner=()=>setIsShowSpinner(false);

    // changes data to left side and right side
    const onChange = (event) => {
        setPackageSource(event.source);
        setPackageTarget(event.target);
    };

    // filters selected packages in all packages not to show repeated data in sides
    const handleFilterSelectedPackagesInAllPackages=(allPackages,websitePackages)=>{
        const packageList = allPackages.data.map((pck) => ({name: pck.value, id: pck.key,}));
        const selectedPackages = websitePackages.data.packageIds.length > 0 ? packageList.filter((item) => websitePackages.data.packageIds.some((sourceItem) => sourceItem === item.id)) : [];
        const filteredPackages = packageList.filter(pck => !selectedPackages.some(selectedRole => selectedRole.id === pck.id));
        return {selectedPackages,filteredPackages}
    }

    // fetches all data from server
    const fetchData=async()=>{
        showSpinner();
        try {
            const allPackages = await GET(`Packages/Selection`,{},'IEnumerable');
            const websitePackages = await GET(`/Websites/Packages?WebsiteId=${queryParams.websiteId}`);
            if(allPackages.status === 200 && websitePackages.status === 200){
                const {selectedPackages,filteredPackages}=handleFilterSelectedPackagesInAllPackages(allPackages,websitePackages);
                setPackageSource(filteredPackages);
                setPackageTarget(selectedPackages);
                hideSpinner();
            }
            else{
                handleApiError(allPackages,hideSpinner);
                handleApiError(websitePackages,hideSpinner)
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(()=>{
        fetchData();
    },[IsRefresh])



    const handleSubmitWebsitePackage=async()=>{
        if(packageTarget.length === 0){
            handleFailureMessage(t("Dearuserpleaseselectapackage"));
            return;
        }
        try {
           showSpinner();
           const pakckages = {websiteId:queryParams.websiteId,packageIds:packageTarget.map(item=>item.id)}
           const response = await PUT(`/Websites/Packages`,pakckages,false);
           if(response.status === 200){
               hideSpinner();
               redirectTo('/Admin/Website/List');
           }
           else{
              handleApiError(response,hideSpinner);
           }
        }
        catch (e) {
            console.log(e);
        }
    }



    const itemTemplate = (item) => {

        return (
            <div className="flex items-center gap-1 border bg-white dark:bg-themeDarkSalt700 dark:border-themeDarkInformation p-2 rounded ">
                <div>
                    <div className={"p-2 rounded bg-themeSecondary"}>
                        <HPrimeIconSmall icon={'pi-user'} iconClass={'text-white'}/>
                    </div>
                </div>
                <span className="font-bold line-clamp-1 w-full">{item.name}</span>
                <div className={"flex gap-3 items-center flex-shrink-0"}>
                    <button type={"button"}>
                        <HPrimeIconSmall icon={"pi-list"} iconClass={"text-themeInformation dark:text-themeDarkInformation"}/>
                    </button>
                </div>
            </div>
        );
    };


    return (
      <>

          <div className=" space-y-5  ">
              <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                  <ListFilter label={null} icon={null}
                              childrenOne={
                                  <>
                                      <ButtonPlus
                                          onClick={()=>redirectTo('/Admin/Website/List')}
                                          icon={"pi-list"}
                                          tooltipTitle={t("WebsiteList")}
                                          title={t("list")}
                                      />

                                  </>
                              }
                              childrenTwo={
                                  <>

                                  </>
                              }
                  />

              </div>
              <div class="relative">
                  {IsShowSpinner ? (
                      <div className="huploader_loading">
                          <div className="relative">
                              <LoadingHarmony />
                          </div>
                      </div>
                  ) : null}
                  <div style={{direction:"ltr"}} class="p-5 box bg-white dark:bg-themeDarkSalt700">
                      <PickList
                          source={packageSource}
                          target={packageTarget}
                          onChange={onChange}
                          filter
                          filterBy="name"
                          sourceHeader={t("Unauthorized")}
                          targetHeader={t("Authorized")}
                          sourceStyle={{ height: "30rem" }}
                          targetStyle={{ height: "30rem" }}
                          sourceFilterPlaceholder={t("search")}
                          targetFilterPlaceholder={t("search")}
                          itemTemplate={itemTemplate}
                      />
                  </div>
              </div>
              <ButtonSubmitAndCancel
                  onCancelClick={()=>redirectTo('/Admin/Website/List')}
                  onRegisterClick={()=>handleSubmitWebsitePackage()}
              />
          </div>

          <ToastContainer/>

      </>

  );
};
