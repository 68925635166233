import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {emailSchema, mobileSchema} from "../../Authentication/ForgetPassword/forgetPasswordSchema";
import {validate} from "../../../Services/Globals/validate";
import {POST} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import Select from "react-select";
import {InputText} from "primereact/inputtext";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {LoadingSpinner} from "../../Commans/Loadings/LoadingSpinner";


export const PasswordRecovery=({handleChangeState,handleValue,handleSelectValue, errors,onSetErrors,authData,setAuthData})=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsShowSpinner, setIsShowSpinner] = useState(false);

    const [initialSource,setInitialSource]=useState({methods:[{ label: t("Mobile"),value: '1' },{ label: t("Email"), value: '0' }]});

    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }

    useEffect(()=>{
        setAuthData((prevState)=>({...prevState,method:initialSource.methods[0]}));
    },[])

    const handleSubmitPasswordRecovery = async (e) => {
        e.preventDefault();
        try {

            const schema = authData.method.value === '1' ? mobileSchema : emailSchema;
            const isValid = validate(schema,authData,onSetErrors);
            if (!isValid) return;
            showSpinner();
            const { username,method } = authData;
            const response = await POST('/Authentication/ForgetPassword', { username: username, method: parseInt(method.value)}, false);
            if (response.status === 200) {
                const {id,code} = response.data;
                setAuthData((prevState)=>({...prevState,code: code,id:id}));
                handleChangeState('otpValidation')
            }
            else {
                handleApiError(response, hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    };

return (
    <>
        <form method={'post'}  onSubmit={handleSubmitPasswordRecovery} className="space-y-5 relative">
            {IsShowSpinner ? <LoadingSpinner/> : null}

            <div className="space-y-2 ">
                <label className="text-xs dark:text-gray-300">{t('passwordRecieveMethod')}</label>
                <Select
                    options={initialSource.methods}
                    isSearchable={true}
                    onChange={(data) => handleSelectValue(data, 'method')}
                    placeholder={t("selection")}
                    value={authData.method}
                    classNamePrefix={"h_multi_select"}
                     menuPosition={"fixed"}
                />
            </div>
            <div className="relative float-input p-float-label">
                <InputText className={"input w-full input-float-label"} onInput={(e)=>handleValue(e)} name={'username'} value={authData.username}/>
                <label className={"text-xs dark:text-gray-300"}>{t("MobileEmail")}</label>
                <div className={"absolute end-2 -top-2"}>
                    <DisplayError message={errors.username}/>
                </div>
            </div>
            <div className="text-center flex gap-3 ">
                <button type={'button'} onClick={()=>handleChangeState('userAuthentication')} className={'button flex-1 text-white bg-themeDanger'}  >{t('Return')}</button>
                <button type={'submit'} className={'button w-full flex-1 user_login_gradiant text-white'}  >{t('OtpReceive')}</button>
            </div>
        </form>

    </>
)



}