import React, { useEffect, useState } from "react";
import { LoadingHarmony } from "../../Commans/Loadings/LoadingHarmony";
import { PickList } from "primereact/picklist";
import { ToastContainer } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { handleFailureMessage, handleSuccessMessage } from "../../../Services/Globals/Errors/NotificationService";
import { handleApiError } from "../../../Services/Globals/Errors/handleApiError";
import { GET, POST } from "../../../Services/AxiosService/AxiosApiService";
import { ListFilter } from "../../Commans/Grid/ListFilter";
import { Return } from "../../Commans/Globals/Return";
import { ButtonPlus } from "../../Commans/UIParts/ButtonPlus";
import { ButtonVideo } from "../../Commans/UIParts/ButtonVideo";
import { useTranslation } from "react-i18next";
import { ButtonSubmitAndCancel } from "../../Commans/UIParts/ButtonSubmitAndCancel";
import { handelConverPackageGroupRoles } from "./packageGroupService";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";

export const HandlePackageGroupRole = () => {

    const { t } = useTranslation();
    const [spinner, setSpinner] = useState(false);
    const redirectTo = useNavigate();
    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [concurrencyStamp, setConcurrencyStamp] = useState(null);
    const { packageGroupId } = useParams();

    // filters target roles in sources
    const handleFilterTargetsInSource = (sourceRoles, targetRoles) => {
        // Convert source roles to the expected structure
        const convertedRoles = handelConverPackageGroupRoles(sourceRoles);

        // Extract target role IDs for comparison
        const targetRoleIds = targetRoles.roleIds;

        // Filter roles that are selected (i.e., exist in targetRoleIds)
        const selectedRoles = targetRoleIds.length > 0
            ? convertedRoles.filter(role => targetRoleIds.includes(role.id))
            : [];

        // Filter out the selected roles from the source
        const filteredRoles = convertedRoles.filter(role => !targetRoleIds.includes(role.id));

        return { selectedRoles, filteredRoles };
    }

    const fetchData = async () => {
        try {
            setSpinner(true);
            const allRoles = await GET(`/Roles/PackageGroups`, 'IEnumerable');
            const packageTargetRoles = await GET(`/PackageGroups/${packageGroupId}/Roles`, {}, 'IEnumerable');
            if (allRoles.status === 200 && packageTargetRoles.status === 200) {
                const { filteredRoles, selectedRoles } = handleFilterTargetsInSource(allRoles.data, packageTargetRoles.data);
                setSource(filteredRoles);
                setTarget(selectedRoles);

                // 
                setConcurrencyStamp(packageTargetRoles.data.concurrencyStamp);
            } else {
                handleApiError(allRoles, onApiError);
                handleApiError(packageTargetRoles, onApiError);
            }
        } catch (e) {
            console.error('Error fetching data:', e);
        } finally {
            setSpinner(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [refresh]);//refresh

    const onApiError = () => {
        setSpinner(false);
    };

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    };

    const handlePackageGroupRolesAdd = async () => {
        try {
            if (target.length === 0) {
                handleFailureMessage(t("Pleaseselectpermissions"));
                return;
            }
            setSpinner(true);
            const roles = target.map((item) => item.id);
            const roleIds = roles.map((item) => ({
                roleId: item
            }))

            const response = await POST(`/PackageGroups/Roles`, { packageGroupId, roleIds, concurrencyStamp }, false);
            if (response.status === 200) {
                handleSuccessMessage(t("Permissionsbeensuccessfully"));
                redirectTo('/Admin/Package/Group');
            } else {
                handleApiError(response, onApiError);
            }
        } catch (e) {
            onApiError()
            console.error('Error fetching data:', e);
        } finally {
            setSpinner(false);
        }
    };

    const itemTemplate = (item) => (
        <div className="flex flex-wrap items-center gap-3 bg-white dark:bg-themeDarkSalt700 p-2 box">
            <div className="flex-1 flex flex-column items-center gap-2 ">
                <HPrimeIconSmall icon={'pi-user'} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                <span className="font-bold dark:text-gray-300">{item.name}</span>
            </div>
        </div>
    );

    return (
        <div className={"space-y-5"}>
            <div className="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                    childrenOne={
                        <>
                            <ButtonPlus
                                onClick={() => redirectTo(`/Admin/Package/Group`)}
                                functionClass="creation"
                                tooltipTitle={t("PackageGroup")}
                            />

                        </>
                    }

                />

            </div>

            <div className=" space-y-5 relative ">
                {spinner ? (
                    <div className="huploader_loading">
                        <div className="relative">
                            <LoadingHarmony />
                        </div>
                    </div>
                ) : null}
                <div style={{ direction: "ltr" }} className="p-3 box bg-white dark:bg-themeDarkSalt700">
                    <PickList
                        target={target}
                        source={source}
                        onChange={onChange}
                        filter
                        filterBy="name"
                        sourceHeader={t("Unauthorized")}
                        targetHeader={t("Authorized")}
                        sourceStyle={{ height: "30rem" }}
                        targetStyle={{ height: "30rem" }}
                        sourceFilterPlaceholder={t("search")}
                        targetFilterPlaceholder={t("search")}
                        itemTemplate={itemTemplate}
                    />
                </div>
                <ToastContainer />
            </div>
            <ButtonSubmitAndCancel
                onRegisterClick={() => handlePackageGroupRolesAdd()}
                onCancelClick={() => redirectTo(`/Admin/Package/Group`)}
            />

        </div>

    )
}