import React, {useEffect, useRef} from "react";

import { InputText } from "primereact/inputtext";
import { useState } from "react";


import { TabMenu } from "../../../Commans/UIParts/TabMenu";
import {  ToastContainer } from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";

import Select from "react-select";



import {useTranslation} from "react-i18next";
import {FantasyModal} from "../../../Commans/UIParts/FantasyModal";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {Return} from "../../../Commans/Globals/Return";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {UploadImage} from "../../../Commans/Uploaders/UploadImage";

import {HandleGeneralSetting} from "../../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
// import 'primereact/dialog/dialog.min.css';
import {ButtonSubmitAndCancel} from "../../../Commans/UIParts/ButtonSubmitAndCancel";
import {useSetting} from "../../../Commans/Hooks/useSetting";
import {useCustomTabs} from "./useCustomTabs";
import {ContentTab} from "./ContentTab";
import {LinkTab} from "./LinkTab";
import {useCustomDocument} from "./useCustomDocument";
import {validate} from "../../../../Services/Globals/validate";
import {documentSchema} from "./documentSchema";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {InputNumber} from "primereact/inputnumber";
import {useCustomDocGroup} from "../DocumentGroup/useCustomDocGroup";
import {useCustomLink} from "./useCustomLink";
import queryString from "query-string";
import {handleFailureMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {
  CreateConvertDocument,
  CreateFormDataDocument,
  EditConvertDocument,
  EditFormDataDocument
} from "./documentService";
import {useCustomFiles} from "./useCustomFiles";
import {AttachCard} from "./AttachCard";
import {RequiredIcon} from "../../../Commans/Errors/RequiredIcon";


export const HandleProjectDocument = () => {
  const queryParams = queryString.parse(window.location.search);
  const { t } = useTranslation();
  const location = useLocation();

  const redirectTo = useNavigate();
  const {setting,handleSetting,handleSettingClose,IsSettingOpen}= useSetting();
  const {tabs,setActiveTab,activeTab,handleChangeTabId} = useCustomTabs();
  const [IsShowSpinner,setIsShowSpinner] = useState(false);
  const [errors,setErrors] = useState({});
  const [initialSource,setInitialSource] = useState({documentGroups:[],documentTypes:[],sections:[],attachTypes:[]});
  const [IsEdit,setIsEdit] = useState(false);
  const [IsOpen,setIsOpen] = useState(false);
  const [refresh,setRefesh] = useState(false);
  const {handleSelectValue,handleValue,setDocument,document,handleKeywordsChange,validateSlug,IsSlugValid,handleNumberValue,IsMenuOpen,setIsMenuOpen,handleChangeContent,handleChangeDocumentAttachment,handleEmptyDocumentAttachment,handleChangeImage,handleResetImages,handleRemoveImagePath} =  useCustomDocument(setErrors);
  const {setLink,link,handleRemoveRow,handleChange,handleAddRow,handleInitialLinkData,ValidateUrls} = useCustomLink();

  const Referesh=()=>{
    setRefesh(prevState => !prevState);
  }
  const onSetErrors=(errors)=>{
    setErrors(errors)
  }
  const showSpinner=()=>{
    setIsShowSpinner(true)
  }
  const hideSpinner=()=>{
    setIsShowSpinner(false);
  }

  const handleInitialSource=(documentGroups,documentSections,documentTypes,attachmentTypes)=>{
    const groups = convertSelectDataHandler(documentGroups);
    const sections = convertSelectDataHandler(documentSections);
    const documentType = convertSelectDataHandler(documentTypes);
    const attachTypes = convertSelectDataHandler(attachmentTypes);
    setInitialSource({
      documentGroups: groups,
      sections: sections,
      documentTypes: documentType,
      attachTypes: attachTypes
    })
    return {groups,sections,documentType,attachTypes}
  }
  const fetchData = async () => {
    try {
      showSpinner();

      // Execute all API calls concurrently
      const [
        responseGroup,
        responseSection,
        responseDocType,
        responseAttachType
      ] = await Promise.all([
        GET(`HarmonyDocumentGroups`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnProgrammingSection`, {}, 'IEnumerable'),
        GET(`/Enumerations/EnHarmonyDocumentType`, {}, 'IEnumerable'),
        GET('/Enumerations/EnHarmonyDocumentAttachType', {}, 'IEnumerable')
      ]);

      // Check if all responses are successful
      if (
          responseGroup.status === 200 &&
          responseSection.status === 200 &&
          responseDocType.status === 200 &&
          responseAttachType.status === 200
      ) {
        const { groups, sections, documentType, attachTypes } = handleInitialSource(
            responseGroup.data,
            responseSection.data,
            responseDocType.data,
            responseAttachType.data
        );

        // If documentId is present, fetch and bind document data
        if (queryParams.documentId) {
          try {
            const response = await GET(`/HarmonyDocument/${queryParams.documentId}`, {}, 'single');

            if (response.status === 200) {

              if(response.data.files.length === 0){
                handleInitialCards(attachTypes);
              }
              else{
                const fileCards = handleGetAllCards(response.data.files,attachTypes);
                setFiles(fileCards);
              }

              if(response.data.links.length === 0){
                handleInitialLinkData();
              }
              else{
                const linksData = {links: response.data.links.map(link => ({ ...link, isDefault: false }))};
                setLink(linksData.links);
              }

              setIsEdit(true);
              setDocument(prevState => ({
                ...prevState,
                harmonyDocumentId: response.data.harmonyDocumentId,
                title: response.data.title,
                slug: response.data.slug !== null ? response.data.slug.replace(/-/g, "/") : null,
                description: response.data.description,
                content: response.data.content,
                keywords: response.data.keywords ?? [],
                order: response.data.order,
                author: response.data.author,
                documentGroupId: groups.find(item => item.value === response.data.documentGroupId),
                programmingSection: sections.find(item => item.value === response.data.programmingSection),
                harmonyDocumentType: response.data.harmonyDocumentType && documentType
                    ? response.data.harmonyDocumentType.map(value => documentType.find(item => item.value === value))
                    : [],
                image: response.data.image,
                imagePath: response.data.imagePath,
                oldSlug: response.data.slug,
              }));


            } else {
              handleApiError(response, hideSpinner);
            }
          } catch (e) {
            hideSpinner();
          }
        } else {
          // If documentId is not present, only initialize the form if it hasn't been initialized
          setDocument(prevState => ({
            ...prevState,
            documentGroupId: prevState.harmonyDocumentId ? prevState.documentGroupId : (groups[0] ?? null),
            order: prevState.harmonyDocumentId ? prevState.order : 0,
            programmingSection: prevState.harmonyDocumentId ? prevState.programmingSection : sections[0],
            harmonyDocumentType: prevState.harmonyDocumentId ? prevState.harmonyDocumentType : [documentType[0]],
            keywords: prevState.harmonyDocumentId ? prevState.keywords : [],
            imagePath: prevState.harmonyDocumentId ? prevState.imagePath : null
          }));

          if (link.length === 0) {
            handleInitialLinkData();
          }

          if (files.length === 0) {
            handleInitialCards(attachTypes);
          }
        }

        hideSpinner();
      } else {
        handleApiError(responseGroup, hideSpinner);
      }
    } catch (e) {
      hideSpinner();
    }
  };


  useEffect(() => {
    fetchData();
  }, [refresh]);

  useEffect(()=>{
    fetchData();
  },[])

  const handleSubmitDocument=async(e)=>{
     e.preventDefault();
     const isValid = validate(documentSchema,document,onSetErrors)
     if(!isValid){
       return;
     }
     try {
       if(IsEdit){
         if(link.length !== 0){
           const isUrlValid = ValidateUrls(link);
           if (!isUrlValid){
             return;
           }
         }
         if(document.harmonyDocumentType.length === 0){
           handleFailureMessage(t('DocumentTypeIsRequired'));
           return;
         }
         if(document.documentGroupId === null){
           handleFailureMessage(t('DocumentGroupIsRequired'));
           return;
         }

         showSpinner();
         const documents = EditConvertDocument(document,link,files);
         const formDataDocument = EditFormDataDocument(documents);
         const response = await POST(`/HarmonyDocument/Edit`,formDataDocument,true);
         if(response.status === 200){
           hideSpinner();
           redirectTo('/Admin/ProjectDocument/List');
         }
         else{
           handleApiError(response,hideSpinner);
         }
       }
       else{

         if(link.length !== 0){
           const isUrlValid = ValidateUrls(link);
           if (!isUrlValid){
             return;
           }
         }
         if(document.harmonyDocumentType.length === 0){
           handleFailureMessage(t('DocumentTypeIsRequired'));
           return;
         }
         if(document.documentGroupId === null){
           handleFailureMessage(t('DocumentGroupIsRequired'));
           return;
         }
         showSpinner();
         const documents = CreateConvertDocument(document,link,files);
         const formDataDocument = CreateFormDataDocument(documents);
         const response = await POST(`/HarmonyDocument`,formDataDocument,true);
         if(response.status === 200){
           hideSpinner();
           redirectTo('/Admin/ProjectDocument/List');
         }
         else{
           handleApiError(response,hideSpinner);
         }
       }
     }catch (e) {
       hideSpinner()
     }
  }




  const handleIsOpen=()=>{
    setIsOpen(true);
    setGroup({title: '',description:'',order: 0});
  }
  const handleCloseIsOpen=()=>{
    setIsOpen(false);
    setGroup({title: '',description:'',order: 0});
    setIsGroupEdit(false)
    setGroupErrors({})
  }

  const {setGroup,group,handleSubmitDocumentGroup,handleGroupValue,handleGroupNumberValue,setIsGroupEdit,CustomOption,setGroupErrors,GroupErrors} = useCustomDocGroup(showSpinner,hideSpinner,handleCloseIsOpen,handleSelectValue,setIsOpen,setDocument,setInitialSource)
  const {triggerFileInput,setFiles,files,deleteCard,addNewCard,handleFieldChange,handleFileChange,handleInitialCards,handleGetAllCards,handleDeleteDocumentAttachment,totalSize} = useCustomFiles(initialSource,showSpinner,hideSpinner,Referesh);





  return (
    <div className={"space-y-5"}>
      <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
        <ListFilter label={null} icon={null}
                    showChildrenTwo={false}
                    childrenOne={
                      <>
                        <ButtonPlus
                            onClick={()=>redirectTo(`/Admin/ProjectDocument/List`)}
                            icon={"pi-list"}
                            title={t("list")}
                            tooltipTitle={t("ListOfInformation")}
                        />

                      </>
                    }
        />

      </div>

      <form onSubmit={handleSubmitDocument} method={'post'} className={"relative"}>
        {

          IsShowSpinner?
              <div className="huploader_loading" >
                <div className="relative">
                  <LoadingHarmony/>
                </div>
              </div>
              :
              null
        }
        <div className=" space-y-5">

            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-8">
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 ">
                    <div>
                      <div className="float-input p-float-label relative">
                        <InputText name={'title'} value={document.title} onInput={(e)=>handleValue(e)}  type="text" className="input w-full input-float-label" />
                        <label className={"text-xs dark:text-gray-300"}>
                          {t("Title")}
                        <RequiredIcon/>
                        </label>
                        <div className={"absolute end-2 -top-2"}>
                          <DisplayError message={errors.title}/>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="float-input p-float-label relative">
                        <InputText value={document.slug} onInput={(e)=>handleValue(e)} name={'slug'} className="input w-full input-float-label" />
                        <label className={"text-xs dark:text-gray-300"}>
                          {t("Address")}
                          <RequiredIcon/>
                        </label>
                        <div className={"absolute end-2 -top-2"}>
                          <DisplayError message={errors.slug}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="post box mt-5 bg-white dark:bg-themeDarkSalt700">
                    <TabMenu activeTab={activeTab} handleTabClick={handleChangeTabId} tabs={tabs} />
                    <div className="post__content tab-content ">
                      <div className={`tab-content__pane p-2 ${ activeTab === "content" ? "active" : "" }`} id="content">
                        <ContentTab handleSetting={handleSetting}
                                    handleValue={handleValue}
                                    document={document}
                                    handleChangeContent={handleChangeContent}
                        />
                      </div>
                      <div className={`tab-content__pane space-y-5 p-2 max_height85vh ${activeTab === "link" ? "active" : ""}`} id="link" >
                        <LinkTab
                            handleChange={handleChange}
                            handleRemoveRow={handleRemoveRow}
                            handleAddRow={handleAddRow}
                            links={link}
                        />
                      </div>
                      <div className={`tab-content__pane p-2 max_height85vh ${activeTab === "file" ? "active" : ""}`} id="file">
                        {

                            activeTab === 'file'?
                                <AttachCard
                                    data={files}
                                    IsEdit={IsEdit}
                                    showSpinner={showSpinner}
                                    hideSpinner={hideSpinner}
                                    triggerFileInput={triggerFileInput}
                                    setCards={setFiles}
                                    cards={files}
                                    deleteCard={deleteCard}
                                    addNewCard={addNewCard}
                                    handleFieldChange={handleFieldChange}
                                    handleFileChange={handleFileChange}
                                    initialSource={initialSource}
                                    handleDeleteDocumentAttachment={handleDeleteDocumentAttachment}
                                    totalSize={totalSize}
                                />
                                :null
                        }

                        {/*<div className="bg-white dark:bg-themeDarkSalt700 border dark:border-themeDarkColorLine max_height85vh rounded-t-md">*/}
                        {/*  <div*/}
                        {/*      class="p-3 bg-gray-200 dark:bg-themeDarkPostInput dark:text-gray-300 rounded-t-md flex justify-between items-center">*/}
                        {/*    <h2>{t("UploadedFiles")}</h2>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <div className="box space-y-3 bg-white p-3 dark:bg-themeDarkSalt700">
                  <div>
                    <div class="space-y-1">
                      <div className="w-full h-48 ">
                        <UploadImage isEdit={IsEdit}  removeFile={handleRemoveImagePath} removeName={'imagePath'} accept={'image/*'} resetImages={handleResetImages} OnValueSelection={handleChangeImage} value={document.image} name={'image'} />
                      </div>
                    </div>
                  </div>
                  <div className={"flex items-center gap-3"}>
                    <div className="space-y-2 flex-1">
                      <div className={"flex gap-2 items-center"}>
                        <label className="text-xs dark:text-gray-300">
                          {t('grouping')}
                        </label>
                        <span onClick={()=>handleIsOpen()} className={"cursor-pointer text-xs text-themeInformation dark:text-themeDarkInformation"}>
                         ({(t('GroupCreation'))})
                        </span>
                      </div>
                      <div>
                        <Select
                            options={initialSource.documentGroups}
                            isMulti={null}
                            isClearable={false}
                            isSearchable={true}
                            name={'documentGroupId'}
                            placeholder={t("selection")}
                            value={document.documentGroupId}
                            menuIsOpen={IsMenuOpen}
                            onMenuOpen={() => setIsMenuOpen(true)}
                            onMenuClose={() => setIsMenuOpen(false)}
                            components={{
                              Option: CustomOption,
                            }}

                            classNamePrefix={"h_multi_select"}
                        />

                      </div>
                    </div>
                    <div className="space-y-1 w-20 flex-shrink-0">
                      <label className="dark:text-gray-300 text-xs">{t("Order")}</label>
                      <InputNumber
                          type={"text"} name={"order"}
                          value={document.order}
                          onChange={(e) => handleNumberValue(e.value,'order')}
                          showButtons
                          min={0}
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                      <label className="text-xs dark:text-gray-300">
                        {t('Part')}
                      </label>
                      <div>
                        <Select
                            options={initialSource.sections}
                            isMulti={false}
                            isClearable={false}
                            isSearchable={true}
                            name={'programmingSection'}
                            placeholder={t("selection")}
                            onChange={(data)=>handleSelectValue(data,'programmingSection')}
                            value={document.programmingSection}
                            classNamePrefix={"h_multi_select"}
                        />
                      </div>
                    </div>

                  <div className="space-y-1">
                    <label className="text-xs dark:text-gray-300">
                      {t('type')}
                    </label>
                    <div>
                      <Select
                          options={initialSource.documentTypes}
                          isMulti={true}
                          isClearable={false}
                          isSearchable={true}
                          name={'harmonyDocumentType'}
                          placeholder={t("selection")}
                          onChange={(data)=>handleSelectValue(data,'harmonyDocumentType')}
                          value={document.harmonyDocumentType}
                          classNamePrefix={"h_multi_select"}
                          className={"select-group"}
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                      {
                        IsEdit?
                            <>
                              <label className="text-xs dark:text-gray-300">
                                {t('Author')}
                              </label>
                              <div>
                                <InputText name={'author'} value={document.author}  disabled={true} className="input w-full" />
                              </div>
                            </>
                            :null
                      }
                    </div>
                  {/*<div className="post-input dark:bg-themeDarkPostInput space-y-2 post-input-design p-2">*/}
                  {/*  <label className="dark:text-gray-300 text-xs"> {t("Keywords")}</label>*/}
                  {/*  <TagsInput  name={"keywords"} onChange={handleKeywordsChange} value={document.keywords} placeHolder={t("Keywords")}/>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>

          <ButtonSubmitAndCancel
              onCancelClick={() => redirectTo('/Admin/ProjectDocument/List')}
          />

        </div>
      </form>



      <FantasyModal openModal={IsOpen} closeModal={handleCloseIsOpen} title={t("GroupCreation")}>
        <form method={"post"} onSubmit={handleSubmitDocumentGroup} className={"relative space-y-5"}>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="space-y-2">
                <div className={"flex justify-between items-center"}>
                  <label className="dark:text-gray-300 text-xs">
                    {t("Title")}
                  <RequiredIcon/>
                  </label>
                  <DisplayError message={GroupErrors.title} />
                </div>
                <InputText type={"text"}  name={"title"}
                           value={group.title}
                           onInput={(e) => handleGroupValue(e)} className={" input w-full"}
                />

              </div>
              <div className="space-y-1">
                <label className="dark:text-gray-300 text-xs">{t("Order")}</label>
                <InputNumber
                    type={"text"} name={"order"}
                    value={group.order}
                    onChange={(e) => handleGroupNumberValue(e.value,'order')}
                    showButtons
                    min={0}
                />

              </div>
              <div className="space-y-1 col-span-2">
                <label className="dark:text-gray-300 text-xs">{t("description")}</label>
                <InputText type={"text"} name={"description"}
                           value={group.description}
                           onInput={(e) => handleGroupValue(e)}
                           className={" input w-full"} />
              </div>
            </div>
            <div>
              <button type={"submit"}  className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white"}>{t("insertion")}</button>
            </div>
          </form>
      </FantasyModal>


      <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
        <div>
          {
            setting === 'GeneralSetting'?
                <HandleGeneralSetting/>:null
          }
        </div>
      </FantasyModal>

      <ToastContainer />
    </div>
  );
};
