import {regexSetting} from "../../../../../Services/Globals/Public/Regexes";

export const handleCreateManagerPrivacy=(obj)=>{
    const data = {
        authenticationMode:obj.authenticationMode === null || obj.authenticationMode === undefined?null:obj.authenticationMode.value,
        allowedIPs:obj.allowedIPs,
        firstSMSOtpAccount:obj.firstSMSOtpAccount === null || obj.firstSMSOtpAccount === undefined?null:obj.firstSMSOtpAccount.value,
        secondSMSOtpAccount:obj.secondSMSOtpAccount === null || obj.secondSMSOtpAccount === undefined?null:obj.secondSMSOtpAccount.value,
        firstEmailOtpAccount:obj.firstEmailOtpAccount === null || obj.firstEmailOtpAccount === undefined?null:obj.firstEmailOtpAccount.value,
        secondEmailOtpAccount:obj.secondSMSOtpAccount === null || obj.secondEmailOtpAccount === undefined?null:obj.secondEmailOtpAccount.value,
        smsOtpAccountActivation:obj.smsOtpAccountActivation,
        emailOtpAccountActivation:obj.emailOtpAccountActivation
    }
    return data
}
export const validateIPs = (ips) => {
    return ips.every(ip => regexSetting.Ip.test(ip));
};