import React, {useEffect, useRef, useState} from "react";

import {BadgeNotification} from "../BadgeNotification";
import {GET} from "../../../Services/AxiosService/AxiosApiService";
import {GridDateFomartter} from "../Grid/GridDateFomartter";
import {useTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import {NoDataHarmony} from "../NoDataHarmony";
import {Link} from "react-router-dom";
import {HPrimeIcon} from "../UIParts/HPrimeIcon";

export const Notification = ({notes})=>{
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [currentMessage, setCurrentMessage] = useState('');
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const menuRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the clicked element is inside the notification menu or its toggle button
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                event.target.getAttribute("class") !== "dropdown-toggle"
            ) {
                setIsOpen(false);
            }
        };
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);


    const fetchData = async()=>{
        try {
            const response = await GET(``);
        }
        catch (e) {
            console.log(e);
        }
    }





    return (
        <>
            <Dialog header={t("Text")} visible={visible}  className={"Dialog-ShowText"} onHide={() => setVisible(false)}>
                {currentMessage}
            </Dialog>

            <div className="dropdown relative grid place-content-center"  ref={menuRef} >
                <div className={`cursor-pointer relative grid place-content-center ${notes.length > 0 ? 'animate_shake' : ''}`} >
                    <button onClick={toggleMenu}>
                        <HPrimeIcon icon={"pi-bell"} iconClass={"text-themeInformation dark:text-themeDarkInformation"} />
                    </button>
                    <BadgeNotification
                        count={notes.length}
                    />
                </div>
                <div className={`notification-content dropdown-box ${isOpen === true ? "show" : ''}`} >
                    <div className="notification-content__box dropdown-box__content bg-themeInformation dark:bg-themeDarkInformation box overflow-hidden p-2">
                        <div className="notification-content__title flex gap-2 items-center p-2 post-input post-input-design dark:bg-themeDarkSalt700 border-themeInformation dark:border-themeDarkInformation">
                            <i className={"pi pi-bell dark:text-gray-300"}></i>
                            <p className={"text-xs dark:text-gray-300"}>{t("NotificationBox")}</p>
                        </div>
                        <div className="space-y-2 ">

                            {
                                notes && notes.length > 0 ?
                                    notes.map((item,index)=>{return (
                                        <div className="cursor-pointer relative flex items-center hover:bg-themeHoverInformation p-1 rounded-md border post-input"
                                             onClick={() => {
                                                 setCurrentMessage(item.message);
                                                 setVisible(true);
                                             }}
                                            >
                                            <div className="space-y-2 w-full" key={index}>
                                                <div className="flex items-center justify-between">
                                                    <h2 className="text-xs text-themeInformation dark:text-themeDarkInformation line-clamp-1">{item.title}</h2>
                                                    <div className="text-xs text-themeInformation dark:text-themeDarkInformation">
                                                        <GridDateFomartter date={item.reminderDate} hasTime={true} />
                                                    </div>
                                                </div>
                                                <div className="w-full line-clamp-1 text-xs text-themeInformation dark:text-themeDarkInformation">
                                                    {item.message}
                                                </div>
                                            </div>
                                        </div>
                                    )}):
                                    <NoDataHarmony/>
                            }

                        </div>
                        <Link to={"/Cartable"} className={"button w-full bg-themeSuccess block" }>
                            مشاهده همه
                        </Link>
                    </div>
                </div>
            </div>

        </>

    )
}