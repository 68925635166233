import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DELETE, GET, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {handleFilterProductCartItems} from "./CartService";
import Swal from "sweetalert2";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";

import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";

export const useGridCart=(setShowSpinner,fetchData,refresh,onApiError,initialSource,setCart,setIsInvoiceOpen,setIsEdit,Refresh,setProductCartItems,handleOpenInvoice,handleOpenBill,handleOpenRequestStatus,handleOpenUser)=>{
    const redirectTo = useNavigate();
    const {t} = useTranslation();
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");

    const handleGetProductCart= async(productCartItemId)=>{
        try {
            setShowSpinner(true);
            setIsInvoiceOpen({invoiceAdd:true});
            setIsEdit(true);
            const response = await GET(`/ProductCarts/${productCartItemId}`,{},'single');
            if(response.status === 200){
                const cartItems = handleFilterProductCartItems(response.data.productItems,initialSource.productTypes,initialSource.cartTypes,initialSource.productTransports);
                setProductCartItems(cartItems);
                
                const responseProvince = await GET(`/Countries/${response.data.countryId}/StateProvinces/Selection`,{},'IEnumerable');
                const provinces = convertSelectDataHandler(responseProvince.data);
                const filteredProvince = provinces.find((item) => item.value === response.data.stateProvinceId);
                const filteredCountry = initialSource.countries.find((item)=>item.value === response.data.countryId);
                const responseCities = await GET(`/Countries/${filteredProvince.value}/Cities/Selection`,{},'IEnumerable');
                const cityData = convertSelectDataHandler(responseCities.data);
                const filteredCity = cityData.find((item) => item.value === response.data.cityId);

                setCart({
                    id:response.data.id,
                    userId:initialSource.users.find((item)=>item.value === response.data.userId),
                    creationDate:response.data.creationDate,
                    priceType:initialSource.factorTypes.find((item)=>item.value === response.data.priceType),
                    productTransportId:initialSource.productTransports.find((item)=>item.value === response.data.productTransportId),
                     address:response.data.address??null,
                     detailsOfAddress:response.data.detailsOfAddress??null,
                     postalCode:response.data.postalCode === null?null:parseInt(response.data.postalCode),
                     countryId:filteredCountry,
                     stateProvinceId:filteredProvince,
                     cityId:filteredCity,
                     isAddressSaved:response.data.IsAddressSaved,
                })
                setShowSpinner(false);
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleDeleteProductCartItem = async (productCartItemId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton:AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setShowSpinner(true)
                    const response = await DELETE(`/ProductCarts`,{productCartId:productCartItemId})
                    if (response.status === 200) {
                        setShowSpinner(false)
                        Refresh()
                        handleSuccessMessage(t("RemovedSuccessfully"))
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }










    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:'',HasPrize:null,NoPrize:null,IsWholeSale:null,IsRetail:null});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }
    const handleResetFilters = ()=>{
        setFilter(({...filter,search: '',HasPrize:null,NoPrize:null,IsWholeSale:null,IsRetail:null}));
        setSearch('');
    }

    const handleSelectFilter=(data,name)=>{
        setFilter(({...filter,[name]:data.value}));
    }





    // gets the text of send type
    const handleGetSendType = (type) => {
        if (!initialSource.sendTypes) {
            return;
        }
        const data = initialSource.sendTypes.find((item) => item.value === type);
        return data ? data.label : '';
    }

    const handleGetTransactionState = (transactionState) => {
        if (!initialSource.transactionStates) {
            return;
        }
        const data = initialSource.transactionStates.find((item) => item.value === transactionState);
        return data ? data.label : '';
    }
    const handleGetPaymentState = (paymentState) => {
        if (!initialSource.paymentStates) {
            return;
        }
        const data = initialSource.paymentStates.find((item) => item.value === paymentState);
        return data ? data.label : '';
    }

    //updates price Types
    const handleChangePriceType = async(cartItemId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            const response = await PUT(`/ProductCarts/PriceType`,{productCartId:cartItemId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh();
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            setWait(false);
            onApiError();
        }
    }

    // updates queue
    const handleChangeQueue = async(cartItemId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName)
            const response = await PUT(`/ProductCarts/Queue`,{productCartId:cartItemId},false);
            if (response.status === 200) {
                setWait(false)
                Refresh();
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            setWait(false);
            onApiError();
            console.log(e);
        }
    }

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("Orderer"),minWidth: 200,
            cellRenderer:parameters=>
                <div>
                    <button type={"button"} className={'underline line-clamp-1 hover:text-themeInformation dark:hover:text-themeDarkInformation'} onClick={()=>handleOpenUser(parameters.data.userId)}>

                        {parameters.data.userFullName}

                    </button>
                </div>

        },
        { headerName: t("ProductCounts"), minWidth: 130,
            cellRenderer:parameters=>
                <>{parameters.data.productItemCount}</>
        },
        { headerName: t("TrackingCode"), minWidth: 170,

            cellRenderer:parameters=>
                <>{parameters.data.trackingCode}</>
        },

        { headerName: t("TransactionState"), minWidth: 170,

            cellRenderer:parameters=>
                <>{handleGetTransactionState(parameters.data.transactionState)}</>
        },
        { headerName: t("PaymentStatus"), minWidth: 170,

            cellRenderer:parameters=>
                <>{handleGetPaymentState(parameters.data.paymentStatus)}</>
        },

        { headerName: t("ShippingStatus"),minWidth: 170,
            cellRenderer:parameters=>
                <button type={"button"} onClick={()=>handleOpenRequestStatus(parameters.data.id,parameters.data.sendType)} className={"cursor-pointer tooltip_class_Switching"}>
                    {handleGetSendType(parameters.data.sendType)}
                    <Tooltip anchorSelect={".tooltip_class_Switching"} content={t("Switching")} positionStrategy={"fixed"}	 />
                </button>
        },

        { headerName: t("PriceType"),field: 'priceType', minWidth: 115, cellRenderer:params=>
                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading  />
                    ) : params.data.priceType === 0 ? (
                        <HPrimeIcon
                            onClick={async () => await handleChangePriceType(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangePriceType(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                        />
                    )}
                </div>
        },

        { headerName: t("ReleaseQueue"),field: 'queue', minWidth: 115, cellRenderer:params=>
                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName ? (
                        <TinyLoading  />
                    ) : params.data.queue === 0 ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            onClick={async () => await handleChangeQueue(params.data.id,params.colDef.headerName)}
                            icon={"pi-check-square"}
                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeQueue(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                        />
                    )}
                </div>
        },

        { headerName: t("details"), minWidth: 200,

            cellRenderer:parameters=>
                <div className={"flex justify-center items-center gap-3"}>
                    <button type={"button"} className="table_Btn bg-themeSecondary tooltip_class_InvoiceDetail text-white text-xs" onClick={()=>redirectTo(`/Shop/Product/Cart/Items?productCartItemId=${parameters.data.id}`) }>
                        <HPrimeIconSmall
                        icon={"pi-file-check"}
                        />
                        {t("details")}
                        <Tooltip anchorSelect={".tooltip_class_InvoiceDetail"} content={t("InvoiceDetail")} positionStrategy={"fixed"}	 />
                    </button>
                    <button onClick={()=>handleOpenBill(parameters.data.id)} className="table_Btn bg-themeInformation dark:bg-themeDarkInformation tooltip_class_Billcreation text-white text-xs">
                        <HPrimeIconSmall
                            icon={"pi-file-edit"}
                        />
                        {t("Bill")}
                        <Tooltip anchorSelect={".tooltip_class_Billcreation"} content={t("Billcreation")} positionStrategy={"fixed"}	 />
                    </button>

                </div>

        },
        { headerName: t("operations"), maxWidth: 140,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3 '} >
                    <GridButtonEdit
                        onClick={()=>handleGetProductCart(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>handleDeleteProductCartItem(params.data.id)}
                    />
                </div>
            }

        },

    ];




    useEffect(() => {
         OnGridReady();
    }, [refresh,pagination.currentPage, filter.search, pagination.pageSize,filter.IsWholeSale,filter.IsRetail,filter.NoPrize,filter.HasPrize]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setShowSpinner(true);
            const response =  await GET(`/ProductCarts`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search,IsWholeSale:filter.IsWholeSale,IsRetail:filter.IsRetail,NoPrize:filter.NoPrize,HasPrize:filter.HasPrize}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setShowSpinner(false);
        }
    };


    const SwitchFilters = [
        {name:'HasPrize',lable:t("HasPrize"),value:filter.HasPrize},
        {name:'NoPrize',lable:t("NoPrize"),value:filter.NoPrize},
        {name:'IsRetail',lable:t("IsRetail"),value:filter.IsRetail},
        {name:'IsWholeSale',lable:t("IsWholeSale"),value:filter.IsWholeSale},
        {name:'Paid',lable:t("Paid"),value:filter.IsWholeSale},
        {name:'Unpaid',lable:t("Unpaid"),value:filter.IsWholeSale},
        {name:'Cancel',lable:t("Cancel"),value:filter.IsWholeSale},

    ]



    return {
        handleGetProductCart,
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleDeleteProductCartItem,
        handleSearch,
        SwitchFilters,
        handleResetFilters,
        handleToggleFilter,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
        handleSelectFilter
    }
}