import React, {useState} from "react";

import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {MultiDataSelect} from "../../Commans/UIParts/MultiDataSelect";
import {InputSwitch} from "../../Commans/Filters/InputSwitch";
import {UploadImage} from "../../Commans/Uploaders/UploadImage";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
export const HandleAddProject = () => {


  const [modal, setModal] = useState({ addProject: false});

  const addProjectOnHide = () => {
    setModal((prevState) => ({ ...prevState, addProject: !modal.addProject }));
  };

  return(
      <>
        <div class=" space-y-5">
          <div class="box bg-white dark:bg-themeDarkSalt700  p-3 space-y-5">
              <ListSearch label={null} icon={null} >
                <div class="space-y-2">
                  <label className="text-xs dark:text-gray-300">جستجو:</label>
                  <InputText type="text"  className="input w-full"   />
                </div>
                <div class="flex items-end gap-3  ">
                  <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
            <HPrimeIcon icon={"pi-search"}/>
        </span>
                  </div>
                  <div  class="button box zoom-in bg-themeInformation text-white rounded-md hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkSalt700 dark:text-gray-300">
        <span class=" flex h-6 w-6 items-center justify-center">
              <HPrimeIcon icon={"list"}/>
        </span>
                  </div>
                </div>
              </ListSearch>
            <ListFilter label={null} icon={null}
                        childrenOne={
                          <>


                          </>
                        }
                        childrenTwo={
                          <>
                            <InputSwitch  lable={'فعال'} />
                            <InputSwitch  lable={'غیر فعال'} />
                          </>
                        }
            />

          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 box bg-white dark:bg-themeDarkSalt700 p-5">
            <a href="#" className="-intro-x border project rounded-md p-3 flex flex-col justify-between post-input dark:bg-themeDarkPostInput relative">
              <div class="absolute top-2 start-0 dropdown">
                <div className="dropdown-toggle w-5 h-5 block" >
                  <HPrimeIcon icon={'more-vertical'} className="w-5 h-5 text-gray-500"/>
                </div>
                <div class="dropdown-box mt-5 absolute w-40 top-0 start-0 z-10 box bg-white dark:bg-themeDarkSalt700">
                  <div class="dropdown-box__content p-2">
                    <a href="#" className="flex items-center p-2  hover:bg-themeHoverInformation rounded-md">
                       Share File
                    </a>
                    <a href="#" className="flex items-center  p-2 hover:bg-themeHoverInformation rounded-md">
                       Delete
                    </a>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-center justify-center gap-3">
                <img src="/Assets/Image/images/food-beverage-6.jpg" className="w-20 rounded-full mx-auto h-20 flex-shrink-0 bg-gray-200" alt=""/>
                  <div class="flex justify-center items-center gap-2">
                    <span class=" w-5 h-5 rounded-full bg-themeGreen600 flex justify-center items-center">
                      <i class="w-3 h-3 text-white" data-feather="pocket"></i>
                    </span>
                    <h3 class="dark:text-gray-300">پروژه آرین </h3>
                  </div>
              </div>
              <div class="space-y-1">
                <div class="flex justify-between">
                  <h3 class="text-xs dark:text-gray-300">وضعیت کل پروژه:</h3>
                  <span class="text-themeGreen600">(60%)</span>
                </div>
                <div class="w-full h-2 bg-gray-400 rounded mt-3">
                  <div class="w-2/3 h-full bg-themeGreen600 rounded"></div>
                </div>
              </div>
              <div class="space-y-1">
                <div class="flex justify-between">
                  <h3 class="text-xs dark:text-gray-300">وضیفه من:</h3>
                  <span class="text-themeGreen600">(60%)</span>
                </div>
                <div class="w-full h-2 bg-gray-400 rounded">
                  <div class="w-2/3 h-full bg-themeGreen600 rounded"></div>
                </div>
              </div>
              <div class="flex justify-between border-t pt-3 ">
                <div class="flex-1 flex flex-col items-center justify-center gap-2">
                  <h3 class="text-xs dark:text-gray-300">تاریخ شروع:</h3>
                  <span class="text-xs dark:text-gray-300">1401/21/01</span>
                </div>
                <div class="flex-1 flex flex-col items-center justify-center gap-2">
                  <h3 class="text-xs dark:text-gray-300">تاریخ پایان:</h3>
                  <span class="text-xs dark:text-gray-300">1400/21/01</span>
                </div>
              </div>
              <div class="flex justify-between p-3 box bg-white dark:bg-themeDarkPostInput shadow-md">
                <h3 className="dark:text-gray-300 text-xs">آخرین ویرایش:</h3>
                <h3 className="dark:text-gray-300 text-xs">1401/21/01</h3>
              </div>
            </a>
            <a href="#" className="-intro-x border project rounded-md p-3 flex flex-col justify-between post-input dark:bg-themeDarkPostInput relative">
              <div class="absolute top-2 start-0 dropdown">
                <a className="dropdown-toggle w-5 h-5 block" href="javascript:;">
                  <HPrimeIcon icon={'more-vertical'} className="w-5 h-5 text-gray-500"/>
                </a>
                <div class="dropdown-box mt-5 absolute w-40 top-0 start-0 z-10 box bg-white dark:bg-themeDarkSalt700">
                  <div class="dropdown-box__content p-2">
                    <a href="#" className="flex items-center p-2  hover:bg-themeHoverInformation rounded-md">
                       Share File
                    </a>
                    <a href="#" className="flex items-center  p-2 hover:bg-themeHoverInformation rounded-md">
                       Delete
                    </a>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-center justify-center gap-3">
                <img src="/Assets/Image/images/food-beverage-6.jpg" className="w-20 rounded-full mx-auto h-20 flex-shrink-0 bg-gray-200" alt=""/>
                  <div class="flex justify-center items-center gap-2">
                    <span class=" w-5 h-5 rounded-full bg-themeGreen600 flex justify-center items-center">
                      <i class="w-3 h-3 text-white" data-feather="pocket"></i>
                    </span>
                    <h3 class="dark:text-gray-300">پروژه آرین </h3>
                  </div>
              </div>
              <div class="space-y-1">
                <div class="flex justify-between">
                  <h3 class="text-xs dark:text-gray-300">وضعیت کل پروژه:</h3>
                  <span class="text-themeGreen600">(60%)</span>
                </div>
                <div class="w-full h-2 bg-gray-400 rounded mt-3">
                  <div class="w-2/3 h-full bg-themeGreen600 rounded"></div>
                </div>
              </div>
              <div class="space-y-1">
                <div class="flex justify-between">
                  <h3 class="text-xs dark:text-gray-300">وضیفه من:</h3>
                  <span class="text-themeGreen600">(60%)</span>
                </div>
                <div class="w-full h-2 bg-gray-400 rounded">
                  <div class="w-2/3 h-full bg-themeGreen600 rounded"></div>
                </div>
              </div>
              <div class="flex justify-between border-t pt-3 ">
                <div class="flex-1 flex flex-col items-center justify-center gap-2">
                  <h3 class="text-xs dark:text-gray-300">تاریخ شروع:</h3>
                  <span class="text-xs dark:text-gray-300">1401/21/01</span>
                </div>
                <div class="flex-1 flex flex-col items-center justify-center gap-2">
                  <h3 class="text-xs dark:text-gray-300">تاریخ پایان:</h3>
                  <span class="text-xs dark:text-gray-300">1400/21/01</span>
                </div>
              </div>
              <div class="flex justify-between p-3 box bg-white dark:bg-themeDarkPostInput shadow-md">
                <h3 className="dark:text-gray-300 text-xs">آخرین ویرایش:</h3>
                <h3 className="dark:text-gray-300 text-xs">1401/21/01</h3>
              </div>
            </a>
            <button type="button" className="border group project border-dashed border-gray-300 flex items-center justify-center rounded-lg " onClick={() => setModal({ addProject: true })}>
              <div class="flex flex-col justify-center items-center">
                <h2 class="text-xl text-themeInformation dark:text-themeDarkInformation">ایجاد پروژه جدید</h2>
              </div>
            </button>
          </div>
        </div>
        <FantasyModal openModal={modal.addProject}  closeModal={addProjectOnHide} title={"ایجاد پروژه"}>
          <div class="space-y-3" >
            <div class="grid grid-cols-2 gap-3 ">
              <div class="space-y-2 col-span-2">
                <label className="text-xs dark:text-gray-300">نام پروژه:</label>
                <InputText type={"text"} className={"input w-full "} />
              </div>
              <div class="space-y-2">
                <label className="text-xs dark:text-gray-300">تاریخ شروع:</label>
                <InputText type={"text"} className={"input w-full "} />
              </div>
              <div class="space-y-2">
                <label className="text-xs dark:text-gray-300">تاریخ پایان:</label>
                <InputText type={"text"} className={"input w-full "} />
              </div>
              <div className="space-y-2">
                <label className="text-xs dark:text-gray-300">انتخاب اعضاء:</label>
                  <MultiDataSelect />
                  {/*<select className="select2 w-full" multiple>*/}
                  {/*    <option value="1">حسن</option>*/}
                  {/*    <option value="1">عباس</option>*/}
                  {/*    <option value="1">رضا</option>*/}
                  {/*    <option value="1">حسین</option>*/}
                  {/*</select>*/}
              </div>
              <div class="space-y-2 ">
                  <label className="text-xs dark:text-gray-300">رنگ دلخواه:</label>
                  <div className="flex gap-2">
                    <span class="bg-red-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                    <span class="bg-red-700 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                    <span class="bg-blue-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                    <span class="bg-gray-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                    <span class="bg-yellow-500 w-6 h-6 rounded-full cursor-pointer color-change"></span>
                  </div>
              </div>
              <div class="space-y-2 col-span-2">
                <label className="text-xs dark:text-gray-300">تصویر دلخواه:</label>
                <div className="w-full h-32 ">
                  <UploadImage  name={'ThumbnailImage'} />
                </div>
              </div>

            </div>
            <div>
              <button className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation ">ایجاد</button>
            </div>
          </div>
        </FantasyModal>
      </>

  )
}