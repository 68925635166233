import React from "react";
import {Link} from "react-router-dom";
import {GridDateFomartter} from "../../../../Commans/Grid/GridDateFomartter";

import {HPrimeIconSmall} from "../../../../Commans/UIParts/HPrimeIconSmall";
import {useTranslation} from "react-i18next";

export const ContractRow=({description,contractAttach,index,endContract,startContract})=>{
    const { t } = useTranslation();

    return (
        <>
            <div key={index} className=" zoom-in overflow-hidden post-input post-input-design  dark:bg-themeDarkPostInput block">
                <div class="flex">
                    <div class="p-3 bg-themeInformation grid place-content-center dark:bg-themeDarkInformation">
                        <span className={"text-white"}>{index +1}</span>
                    </div>
                    <div className={"space-y-2 p-2"}>
                        <div>
                            <p className="text-xs line-clamp-1 text-themeInformation dark:text-themeDarkInformation">{description}</p>
                        </div>
                        <div>
                            <h2 className={"text-xs dark:text-gray-300"}>{contractAttach}</h2>
                            {/*<p className={"font-light dark:text-gray-300"}>شرکت آرین</p>*/}
                        </div>
                        <div className={"flex justify-between items-center gap-2"}>
                            <span className={"block text-xs dark:text-themeDarkInformation"}>{t("StartDate")}{<GridDateFomartter date={startContract} hasTime={true} />}</span>
                            <span className={"block text-xs dark:text-themeDarkInformation"}>{t("EndDate")}{<GridDateFomartter date={endContract} hasTime={true} />}</span>
                            <HPrimeIconSmall
                                icon={"pi-download"}
                                iconClass={"dark:text-gray-300 text-themeInformation cursor-pointer"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}