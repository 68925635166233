import React from 'react';

 export const MailIcon = ({sizeStyle,fillStyle}) => (

     <svg
         height="200px"
         width="200px"
         viewBox="0 0 503.467 503.467"
         xmlns="http://www.w3.org/2000/svg"
         className={`${sizeStyle ? sizeStyle : "w-8 h-8"} ${fillStyle ? fillStyle : "text-themeCustom"}`}
         role="img"
         aria-hidden="true"
         focusable="false"
     >
         <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
         <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
         <g id="SVGRepo_iconCarrier">
             <path
                 style={{ fill: "#EAC282" }}
                 d="M321.707,256.853L498.347,396.8c-3.413,14.507-17.067,25.6-33.28,25.6H38.4 c-15.36,0-28.16-10.24-32.427-23.893L181.76,256l0,0l45.227,35.84c0,0,12.8,11.093,25.6,11.093s25.6-11.093,25.6-11.093 L321.707,256.853z M499.2,115.2L321.707,256.853l-43.52,34.987c0,0-12.8,11.093-25.6,11.093c-12.8,0-25.6-11.093-25.6-11.093 L181.76,256l0,0L4.267,115.2c0-18.773,15.36-34.133,34.133-34.133h426.667C483.84,81.067,499.2,96.427,499.2,115.2 M499.2,115.2 v273.067c0,3.413,0,5.973-0.853,8.533l-176.64-139.947L499.2,115.2z M180.907,256L5.12,398.507 c-0.853-3.413-1.707-6.827-1.707-10.24V115.2L180.907,256z"
             ></path>
             <path
                 style={{ fill: "#ffffff" }}
                 d="M465.067,426.667H38.4c-21.333,0-38.4-17.067-38.4-38.4v-230.4c0-2.56,1.707-4.267,4.267-4.267 s4.267,1.707,4.267,4.267v230.4c0,16.213,13.653,29.867,29.867,29.867h426.667c16.213,0,29.867-13.653,29.867-29.867v-230.4 c0-2.56,1.707-4.267,4.267-4.267c2.56,0,4.267,1.707,4.267,4.267v230.4C503.467,409.6,486.4,426.667,465.067,426.667z M465.067,375.467c-0.853,0-1.707,0-2.56-0.853L343.04,280.747c-0.853-0.853-1.707-1.707-1.707-3.413c0-0.853,0-1.707,0.853-2.56 c1.707-1.707,4.267-2.56,5.973-0.853l119.467,93.867c0.853,0.853,1.707,1.707,1.707,3.413c0,0.853,0,1.707-0.853,2.56 C467.627,374.613,465.92,375.467,465.067,375.467z M38.4,375.467c-0.853,0-2.56-0.853-3.413-1.707s-0.853-1.707-0.853-2.56 c0-0.853,0.853-2.56,1.707-3.413l119.467-93.867c1.707-1.707,4.267-0.853,5.973,0.853c0.853,0.853,0.853,1.707,0.853,2.56 c0,0.853-0.853,2.56-1.707,3.413L40.96,374.613C40.107,375.467,39.253,375.467,38.4,375.467z M251.733,307.2 c-13.653,0-28.16-11.947-28.16-11.947L1.707,118.613C0.853,117.76,0,116.907,0,115.2c0-21.333,17.067-38.4,38.4-38.4h426.667 c21.333,0,38.4,17.067,38.4,38.4c0,1.707-0.853,2.56-1.707,3.413L279.893,296.107C279.893,296.107,266.24,307.2,251.733,307.2z M8.533,113.493l220.16,175.787c0,0,11.947,10.24,23.04,10.24s23.04-9.387,23.04-9.387l220.16-175.787 c-0.853-15.36-13.653-28.16-29.867-28.16H38.4C22.187,85.333,9.387,97.28,8.533,113.493z"
             ></path>
         </g>
     </svg>


);


