export const convertCommunicationDataHandler = (obj,id,concurrencyStamp)=>{
    const newData = {
        id:id,
        concurrencyStamp:concurrencyStamp,
        communications: obj.map(item => ({
            communicationId: item.communicationId !== null? item.communicationId.value:null,
            mode: item.mode !== null? item.mode.value:null,
            value: item.value,
            description: item.description,
            userCommunicationId:item.userCommunicationId
        }))
    };
    return newData
}

