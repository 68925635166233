import React, {useEffect, useMemo, useState} from "react";

import {AlertSetting, GridSetting} from "../../../../../Services/Globals/Public/AppSetting";
import {handleApiError} from "../../../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET} from "../../../../../Services/AxiosService/AxiosApiService";
import {useTranslation} from "react-i18next";

import {GridDateFomartter} from "../../../../Commans/Grid/GridDateFomartter";
import Swal from "sweetalert2";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";
import {GridButtonDelete} from "../../../../Commans/Grid/GridButtonDelete";
export const useBackGrid=(showSpinner,hideSpinner,refresh,Refresh,sections)=>{
    const { t } = useTranslation();
    const [rowData, setRowData] = useState([]);


    const handleRoundFileSize=(size)=>{
        const unitOfMg = 1048576;
        if(size < unitOfMg) {
            var result = size / 1024;
            return parseFloat(result.toFixed(1))+'KB';
        }
        else{
            var result = (size / unitOfMg);
            return parseFloat(result.toFixed(1))+'MB';
        }
    }
    const handleGetSection=(section)=>{
        const sectionType = sections.find((item)=>item.value === section);
        if (!sectionType){
            return;
        }
        return sectionType?.label;
    }
    const handleDeleteBackup = async (filePath)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    showSpinner()
                    const response = await DELETE(`/Settings/DeleteBackups`,{filePath:filePath});
                    if (response.status === 200) {
                        hideSpinner()
                        Refresh();
                    }
                    else {
                        handleApiError(response,hideSpinner)
                    }
                }catch (e) {
                    hideSpinner();
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},

        { headerName: t("FileName"), minWidth: 170,
            cellRenderer:parameters=>
                <>
                    {parameters.data.name}
                </>
        },
        { headerName: t("Size"), maxWidth: 160,
            cellRenderer:parameters=>
                <>{handleRoundFileSize(parameters.data.size)}</>
        },
        { headerName: t("FileType"), maxWidth: 160,
            cellRenderer:parameters=>
                <>{handleGetSection(parameters.data.section)}</>
        },

        { headerName: t("TimeOfLastRevision"), maxWidth: 200,field: 'expirationDate',cellRenderer:params => {
                return <GridDateFomartter date={params.data.lastModified} hasTime={true} />
            }},


        { headerName: t("operations"), maxWidth: 120,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <button className="text-themeSuccess" onClick={() => window.open(params.data.filePath, '_blank')}>
                        <HPrimeIcon icon={'pi-download'} />
                    </button>
                    <GridButtonDelete
                        onClick={()=>handleDeleteBackup(params.data.filePath)}
                    />

                </div>
            }
        },
    ];



    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1})
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }


    const [filter,setFilter] = useState({search:''});

    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }

    const handleResetFilters = ()=>{
        setFilter(({...filter,search: ''}));
        setSearch('');
    }



    useEffect(() => {
         OnGridReady();
    }, [refresh,pagination.currentPage,filter.search,pagination.pageSize]);

    const OnGridReady = async () => {
        try {
           // showSpinner()
            const response =  await GET(`/Settings/GetAllBackups`, {PageNumber: pagination.currentPage, PageSize: pagination.pageSize, Search: filter.search}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
                // hideSpinner()
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            hideSpinner()
        } finally {
            hideSpinner()
        }
    };


    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        handleSearch,
        handleResetFilters,
        columnDefs,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        search,
        setSearch,
        rowData,
        pagination,
        filter,
        setFilter,
    }


}