import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";

import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {InputTextarea} from "primereact/inputtextarea";
import {AddFilter} from "../../Commans/Filters/AddFilter";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Return} from "../../Commans/Globals/Return";
import {ButtonVideo} from "../../Commans/UIParts/ButtonVideo";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useNavigate} from "react-router-dom";
import {DELETE, GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {Export} from "../../Commans/Grid/Export";
import {validate} from "../../../Services/Globals/validate";
import {addDiscountSchema} from "./AddDiscountSchema";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {InputNumber} from "primereact/inputnumber";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import Select from "react-select";
import {ToggleSwitch} from "../../Commans/UIParts/ToggleSwitch";
import {useTranslation} from "react-i18next";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {TinyLoading} from "../../Commans/Loadings/TinyLoading";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {AlertSetting, GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import queryString from "query-string";
import Swal from "sweetalert2";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";

import {useSetting} from "../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {HandlePicturesSettings} from "../../Public/HarmonySetting/Main/Images/HandlePicturesSettings";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import {convertAddDiscount, convertEditDiscount} from "./discountCodeService";
import {GridButtonEdit} from "../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";

export const HandleAddDiscount=()=>{

    const { t } = useTranslation();

    const redirectTo = useNavigate();
    const [modal, setModal] = useState({ DiscountCode: false, AddFilter:false });
    const [result,setResult] = useState(0);
    const [isEdit,setIsEdit] = useState(false);
    const[spinner,setSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [colId,setColId] = useState("");
    const [options,setOptions] = useState({discounts:[],filters:[],currencies:[]})
    const [discount,setDiscount] = useState({caption:null,description:null,code:null,isActive:true,fromDate:null,toDate:null,targetTypeId:null,discountOrCommissionType:null,discountValue:0,maxDiscountValue:0,minBuyValue:0,consumptionCount:0});
    const [websiteCurrency,setWebsiteCurrency]=useState(0)

    const handleCloseDiscountCode = () => {
        setModal((prevState) => ({ ...prevState, DiscountCode: !modal.DiscountCode }));
        setErrors({});
        setDiscount({code: '',caption: '',consumptionCount: 1,discountValue: 0,maxDiscountValue: 0,discountOrCommissionType: options[0],description: '',targetTypeId: null,isActive: true,minBuyValue: 0});
        redirectTo('/Discount/Add');
        setIsEdit(false);
    };
    const handleOpenDiscount=()=>{
        setModal({ DiscountCode: true });
        setDiscount((prevState) => ({
            ...prevState,
            minBuyValue: 0,
            isActive: true,
            targetTypeId: null,
            description: '',
            discountOrCommissionType: options.discounts[0],
            caption: '',
            discountValue: 0,
            maxDiscountValue: 0,
            toDate: null,
            fromDate: new Date(),
            code: ''
        }));    }

    const handleCloseFilter = () => {
        setModal((prevState) => ({ ...prevState, AddFilter: !modal.AddFilter }));
    };
    const onApiError=()=>{
        setSpinner(false);
    }

    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();

    const onSetErrors = (errors)=>{
        setErrors(errors);
    }
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setDiscount({...discount,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleNumberValue=(value,name)=>{
        setDiscount({...discount,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleToggleSwitchValue=active=> setDiscount({ ...discount,isActive: active });

    const handleSelectValue=(data,name)=>{
        setDiscount({ ...discount, [name]: data });
    }
    const handleFromDate = (dateTime)=>{
        setDiscount({...discount,fromDate: dateTime})
    }
    const handleToDate = (dateTime)=>{
        setDiscount({...discount,toDate: dateTime})
    }
    const handleFetchData = async () => {
        try {
            const [responseDiscount, responseFilters, responseCurrencies, responseWebsiteCurrency] = await Promise.all([
                GET('/Enumerations/EnDiscountOrCommissionType', {}, 'IEnumerable'),
                GET(`/Filters/Selection?Table=${1}`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnCurrencyType`, {}, 'IEnumerable'),
                GET(`/Websites/Currency`, {}, 'IEnumerable')
            ]);

            if (responseDiscount.status === 200 && responseFilters.status === 200 && responseCurrencies.status === 200 && responseWebsiteCurrency.status === 200) {
                const discounts = convertSelectDataHandler(responseDiscount.data);
                const filters = convertSelectDataHandler(responseFilters.data);
                const currencies = convertSelectDataHandler(responseCurrencies.data);
                setWebsiteCurrency(responseWebsiteCurrency.data.currencyType);
                setOptions((prevState) => ({
                    ...prevState,
                    discounts: discounts,
                    filters: filters,
                    currencies: currencies
                }));

            } else {
                handleApiError(responseDiscount, onApiError);
            }
        } catch (e) {
            onApiError();
        }
    };

    useEffect(()=>{
       handleFetchData();
    },[])

    const handleGetCurrencyType=(currencyType,currencies)=>{
        const currency = currencies.find((item)=>item.value === currencyType);
        if(!currency)
        {
            return '-'
        }
        return currency.label;
    }

    const handleEditRecord = async(recordId) => {
        try {
            setIsEdit(true);
            setSpinner(true);
            setModal({ DiscountCode: true });
            const response = await GET(`/DiscountCodes/${recordId}`,{},'single');
            if(response.status === 200){
                setDiscount({
                    id: response.data.id,
                    caption: response.data.caption,
                    description: response.data.description,
                    code: response.data.code,
                    isActive: response.data.isActive,
                    fromDate: response.data.fromDate,
                    toDate: response.data.toDate,
                    discountValue: response.data.discountValue,
                    maxDiscountValue: response.data.maxDiscountValue,
                    minBuyValue: response.data.minBuyValue,
                    consumptionCount: response.data.consumptionCount,
                    discountOrCommissionType: options.discounts.find((item) => item.value === response.data.discountOrCommissionType)??null,
                    targetTypeId: options.filters.find((item) => item.value === response.data.targetTypeId)??null,
                });
                redirectTo(`/Discount/Add?id=${recordId}`);
                setSpinner(false)
            }
            else{
                handleApiError(response,onApiError)
            }
        }
        catch (e) {
            onApiError();
        }

    };

    const handleSubmitDiscount= async (e)=>{
        e.preventDefault();
        const queryParams = queryString.parse(window.location.search);

        if(isEdit){
            const isValid = validate(addDiscountSchema,discount,onSetErrors);
            if(!isValid){
                return;
            }
            try {
                setSpinner(true);
                const convertedDiscounts = convertEditDiscount(discount,queryParams.id);
                const {id,caption,code,consumptionCount,description,discountValue,discountOrCommissionType,maxDiscountValue,fromDate,isActive,toDate,minBuyValue,targetTypeId} = convertedDiscounts;
                const response = await PUT('/DiscountCodes',{id,caption,code,consumptionCount,description,discountValue,discountOrCommissionType,maxDiscountValue,fromDate,isActive,toDate,minBuyValue,targetTypeId},false);
                if(response.status === 200){
                    setResult({...response.status});
                    handleCloseDiscountCode();
                    handleSuccessMessage(t("Discountcodehasbeensuccessfullyedited"));
                    setSpinner(false);
                    setIsEdit(false);
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                onApiError();
            }
        }
        else{
            const isValid = validate(addDiscountSchema,discount,onSetErrors);
            if(!isValid){
                return;
            }
            try {
                setSpinner(true)
                const convertedDiscounts = convertAddDiscount(discount);
                const {caption,code,consumptionCount,description,discountValue,discountOrCommissionType,maxDiscountValue,fromDate,isActive,toDate,minBuyValue,targetTypeId} = convertedDiscounts;
                const response = await POST('/DiscountCodes',{caption,code,consumptionCount,description,discountValue,discountOrCommissionType,maxDiscountValue,fromDate,isActive,toDate,minBuyValue,targetTypeId},false);
                if(response.status === 200){
                   setResult({...response.status});
                   handleCloseDiscountCode();
                   handleSuccessMessage(t("Discountcodehasbeensuccessfullyregistered"));
                   setSpinner(false);
                }
                else{
                    handleApiError(response,onApiError);
                }
            }
            catch (e) {
                onApiError();
            }
        }
    }

    const handleChangeStatus=async(id,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            setColId(id)
            const response = await PUT(`/DiscountCodes/${id}/Activation`,{},false);
            if (response.status === 200) {
                setWait(false)
                setResult({...response.status})
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            onApiError();

        }
    }


    const removeDiscount = async (id)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {
                    setSpinner(true)
                    const response = await DELETE(`DiscountCodes/${id}`, {headers: { 'Content-Type': 'application/json' }},)
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                        handleSuccessMessage(t("Discountcodehasbeensuccessfullydeleted"));
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }




    const [isMenuOpen,setMenuOpen] = useState(false);


    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true,width: 120, maxWidth: 120},
        { headerName: t("DiscountTitle"),field: 'caption', minWidth: 140},
        { headerName: t("TargetAudience"),field: 'targetTypeId', minWidth: 140},
        { headerName: t("DiscountCode"),field: 'code', minWidth: 140},

        { headerName: t("State"),field: 'stateShow', maxWidth: 120, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && colId === params.data.id ? (
                        <TinyLoading />
                    ) : params.data.isActive ? (
                        <HPrimeIcon
                            iconClass={'cursor-pointer text-themeSuccess'}
                            onClick={async () => await handleChangeStatus(params.data.id,params.colDef.headerName)}
                            icon={'pi-check-square'}

                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleChangeStatus(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={'pi-times-circle'}
                        />
                    )}
                </div>

        },
        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={() => handleEditRecord(params.data.id)}
                    />
                    <GridButtonDelete
                        onClick={()=>removeDiscount(params.data.id)}
                    />
                </div>
            }
        },
    ];

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',IsExtended:null,IsExpired:null,IsDeActive:null,IsActive:null,FromDate:null,ToDate:null});
    const [search,setSearch] = useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search: search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }

    const resetFilters = ()=>{
        setFilter(({...filter,search: '',FromDate: null,IsActive: null,IsDeActive: null,ToDate: null,IsExpired: null,IsExtended: null}));
        setSearch('');
    }
    const handleRegisterFromDate=(dateTime)=>{
        setFilter((prevState)=>({...prevState,FromDate: dateTime}))
    }
    const handleRegisterToDate=(dateTime)=>{
        setFilter((prevState)=>({...prevState,ToDate: dateTime}))
    }


    useEffect(() => {
        OnGridReady();
    }, [result,currentPage,data.pageSize,filter.search,filter.IsExtended,filter.IsExpired,filter.ToDate,filter.FromDate,filter.IsActive,filter.IsDeActive,filter]);


    const [rowData, setRowData] = useState([]);


    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/DiscountCodes/GetAllDiscountCodes`, {PageNumber: currentPage, PageSize: data.pageSize, Search: filter.search,IsActive:filter.IsActive, IsDeActive: filter.IsDeActive,IsExpired:filter.IsExpired,FromDate:filter.FromDate,ToDate:filter.ToDate,IsExtended:filter.IsExtended,}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };






    const switchData = [
        {name:'IsActive',lable:t("isActive"),value:filter.IsActive},
        {name:'IsDeActive',lable:t("isNotActive"),value:filter.IsDeActive},
        {name:'IsExpired',lable:t("expired"),value:filter.IsExpired},
        {name:'IsExtended',lable:t("Extended"),value:filter.IsExtended},
    ]

    return(
        <>
            <div class="space-y-5 relative">
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                            <div class="space-y-2">
                                <label className="text-xs dark:text-gray-300">{t("SearchByNameCode")}</label>
                                <InputText  onChange={(e)=>setSearch(e.target.value)}  value={search} type="text" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                            </div>

                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("FromDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleRegisterFromDate} value={filter.FromDate} format={'YYYY/MM/DD'} />
                        </div>
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    onClick={()=>handleSetting('GeneralSetting')}
                                    tooltipTitle={t("Calendarsettings")}
                                />
                                {t("ToDate")}
                            </label>
                            <DateTimePicker value={filter.ToDate} handleDateTime={handleRegisterToDate} format={'YYYY/MM/DD'} />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>resetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={() =>handleOpenDiscount()}
                                            tooltipTitle={t("CreatingDiscountCode")}
                                        />
                                        <ButtonPlus
                                            onClick={() => setModal({ AddFilter: true })}
                                            icon={"pi-filter"}
                                            title={t("Filter")}
                                            tooltipTitle={t("CreateFilter")}
                                        />
                                        <ButtonPlus
                                            onClick={() => redirectTo("/Discount")}
                                            icon={"pi-objects-column"}
                                            title={t("Campaign")}
                                            tooltipTitle={t("DiscountCampaign")}
                                        />

                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={switchData} OnValueSelection={filterValueHandler} />
                                    </>
                                }
                    />

                </div>
                <div className="space-y-2 H-body-ag-grid">
                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridPersion}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export/>
            </div>

            <FantasyModal openModal={modal.DiscountCode}   closeModal={handleCloseDiscountCode} title={t("CreatingDiscountCode")} >
             <form method={'post'} onSubmit={handleSubmitDiscount}>
                 <div className="space-y-5">
                     <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
                         <div class="space-y-1 ">
                             <div className={"flex justify-between items-center"}>
                                <label className="text-xs dark:text-gray-300">
                                    {t("Title")}
                                <RequiredIcon/>
                                </label>
                                 <DisplayError message={errors.caption}/>
                             </div>
                             <InputText type={"text"} name={'caption'}  value={discount.caption} onInput={(e)=>handleValue(e)} className={" input w-full"} />
                         </div>
                         <div class="space-y-1 ">
                             <div className="flex items-center justify-between">
                                 <label className="text-xs dark:text-gray-300">{t("DiscountFor")}</label>
                                 <h2 className="cursor-pointer text-themeInformation dark:text-themeDarkInformation text-xs" onClick={() => redirectTo('/Form/Report/Builder')}>
                                     ({t("Add")})
                                 </h2>
                             </div>
                             <Select
                                 options={options.filters}
                                 isMulti={false}
                                 isClearable={true}
                                 isSearchable={true}
                                 onChange={(data)=>handleSelectValue(data,'targetTypeId')}
                                 placeholder={t("selection")}
                                 value={discount.targetTypeId}
                                 classNamePrefix={"h_multi_select"}
                             />
                         </div>
                         <div class="space-y-1">
                             <div className={"flex justify-between items-center"}>
                                 <label className="text-xs dark:text-gray-300">
                                     {t("SpecifyDiscountCode")}
                                     <RequiredIcon/>
                                 </label>
                                 <DisplayError message={errors.code}/>
                             </div>
                             <InputText name={'code'} onInput={(e)=>handleValue(e)} value={discount.code}  className="input w-full"  />
                         </div>
                         <div class="space-y-1">
                             <label className="text-xs dark:text-gray-300">{t("UsageCount")}</label>
                             <InputNumber type={"text"}  name={'code'} onValueChange={(e)=>handleNumberValue(e.value,'consumptionCount')} value={discount.consumptionCount} />
                         </div>
                         <div class="space-y-1">
                             <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                 <SettingButton
                                     onClick={()=>handleSetting('GeneralSetting')}
                                     tooltipTitle={t("Calendarsettings")}
                                 />
                                 {t("FromDate")}
                             </label>
                             <DateTimePicker value={discount.fromDate} handleDateTime={handleFromDate} format={'YYYY/MM/DD'}  />
                         </div>
                         <div class="space-y-1">
                             <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                 <SettingButton
                                     onClick={()=>handleSetting('GeneralSetting')}
                                     tooltipTitle={t("Calendarsettings")}
                                 />
                                 {t("ToDate")}
                             </label>
                             <DateTimePicker value={discount.toDate} handleDateTime={handleToDate} format={'YYYY/MM/DD'}  />
                         </div>
                         <div class="space-y-1">
                             <label className="text-xs dark:text-gray-300">{t("DiscountType")}</label>
                             <Select
                                 options={options.discounts}
                                 isMulti={false}
                                 isSearchable={true}
                                 onChange={(data)=>handleSelectValue(data,'discountOrCommissionType')}
                                 placeholder={t("selection")}
                                 value={discount.discountOrCommissionType}
                                 classNamePrefix={"h_multi_select"}
                             />
                         </div>
                         <div class="space-y-1">
                             <label className="text-xs dark:text-gray-300">{t("DiscountAmount")}</label>
                             <InputNumber type={"text"}
                                          name={'discountValue'}
                                          onValueChange={(e)=>handleNumberValue(e.value,'discountValue')}
                                          value={discount.discountValue}
                                          suffix={
                                                  handleGetCurrencyType(websiteCurrency,options.currencies)
                                          }
                             />
                         </div>
                         <div class="space-y-1">
                             <label className="text-xs dark:text-gray-300">{t("MaximumDiscount")}</label>
                             <InputNumber type={"text"}
                                          name={'maxDiscountValue'}
                                          onValueChange={(e)=>handleNumberValue(e.value,'maxDiscountValue')}
                                          value={discount.maxDiscountValue}
                                          suffix={
                                              handleGetCurrencyType(websiteCurrency,options.currencies)
                                          }

                             />
                         </div>

                         <div class="space-y-2">
                             <label className="text-xs dark:text-gray-300">{t("MinimumPurchase")}</label>
                             <InputNumber type={"text"}  name={'minBuyValue'} onValueChange={(e)=>handleNumberValue(e.value,'minBuyValue')} value={discount.minBuyValue}

                                          suffix={
                                              handleGetCurrencyType(websiteCurrency,options.currencies)
                                          }
                             />
                         </div>

                         <div className="space-y-1 ">
                             <label className="text-xs dark:text-gray-300">{t("Status")}</label>
                             <ToggleSwitch checked={t("isActive")} unchecked={t("isNotActive")} handleToggleSwitchValue={handleToggleSwitchValue} value={discount.isActive} customClass={"w-32"}/>
                         </div>

                         <div class="space-y-1 md:col-span-2">
                             <InputTextarea name={"description"} placeholder={t("Explanation")} value={discount.description} onInput={(e)=>handleValue(e)}  rows={3} cols={30} className={" w-full input"} />
                         </div>
                     </div>
                     <button type={'submit'} className="button w-full text-white bg-themeInformation dark:bg-themeDarkInformation">{t("register")}</button>
                 </div>
             </form>
            </FantasyModal>
            <FantasyModal openModal={modal.AddFilter}  closeModal={handleCloseFilter} title={t("CreateFilter")} customeClassName={"modal-xl"}>
                <AddFilter/>
            </FantasyModal>
            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:
                            setting === 'waterMark'?
                                <HandlePicturesSettings/>:null
                    }
                </div>
            </FantasyModal>

        </>
    )
}