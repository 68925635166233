import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";

export const useCustomWithdrawTransactionGrid=(showSpinner,hideSpinner)=>{


    const { t } = useTranslation();

    const [rowData, setRowData] = useState([]);

    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);
    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,
        };
    }, []);


    const [pagination,setPagination] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0,currentPage:1});
    const handlePreviousPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    const handleNextPage = () => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: prevPagination.currentPage + 1,
        }));
    };
    const handleCurrentPage = (page) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: page,
        }));
    };
    const handlePageSizeCount = (pageSize)=>{
        setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: 1,
        }));
        setPagination(()=>({...pagination,pageSize:pageSize}))
    }

    const [filter,setFilter] = useState({trackingCode:'',fromDate:null,toDate:null,today:null,yesterday:null,thisWeek:null,thisMonth:null,thisYear:null});

    const [trackingCode,setTrackingCode] = useState('');
    const handleSearchTrackingCode=()=>{
        setFilter((prevState)=>({...prevState,trackingCode: trackingCode}));
    }
    const handleFromDate = (dateTime)=> setFilter((prevState)=>({...prevState,fromDate: dateTime}));

    const handleToDate = (dateTime)=> setFilter((prevState)=>({...prevState,toDate: dateTime}));

    const handleResetAllFilters=()=>{
        setFilter(({...filter,trackingCode: '',fromDate: null,toDate: null,thisMonth: null,thisWeek: null,thisYear: null,today: null,yesterday: null}));
        setTrackingCode('');
    }
    const toggleFilters = [
        {name:'today',lable:t("Today"),value:filter.today},
        {name:'yesterday',lable:t("Yesterday"),value:filter.yesterday},
        {name:'thisWeek',lable:t("ThisWeek"),value:filter.thisWeek},
        {name:'thisMonth',lable:t("ThisMonth"),value:filter.thisMonth},
        {name:'thisYear',lable:t("thisYear"),value:filter.thisYear},
    ]
    const handleToggleFilter= (data,name)=>{
        data ? setFilter(({...filter,[name]: data})):setFilter(({...filter,[name]: null}));
    }


    useEffect(() => {
        OnGridReady();
    }, [pagination.currentPage,pagination.pageSize,filter.trackingCode,filter.today,filter.yesterday,filter.thisYear,filter.thisWeek,filter.thisMonth,filter.thisYear,filter.toDate,filter.fromDate]);


    const Paginate=(items)=>{
        var idSequence = 1;
        const modifiedItems = items.map((item) => ({
            ...item,
            idSequence: ((pagination.currentPage - 1) * pagination.pageSize) + idSequence++
        }));
        return modifiedItems;
    }
    const OnGridReady = async () => {
        try {
            showSpinner();
            const response =  await GET(`/Wallets/Admin/Withdraw/Requests`, {PageNumber: pagination.currentPage, PageSize:pagination.pageSize,Search:filter.trackingCode,FromDate:filter.fromDate,ToDate:filter.toDate,Today:filter.today,Yesterday:filter.yesterday,ThisWeek:filter.thisWeek,ThisMonth:filter.thisMonth,ThisYear:filter.thisYear}, 'PagedList');
            if (response && response.items !== null ) {
                setPagination({...pagination, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                const modifiedItems = Paginate(response.items);
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,hideSpinner);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            hideSpinner();
        }
    };

    return {
        handleCurrentPage,
        handleNextPage,
        handlePageSizeCount,
        handlePreviousPage,
        autoGroupColumnDef,
        getRowHeight,
        defaultColDef,
        rowData,
        pagination,
        handleSearchTrackingCode,
        handleFromDate,
        handleToDate,
        handleResetAllFilters,
        toggleFilters,
        handleToggleFilter,
        filter,
        setFilter,
        trackingCode,
        setTrackingCode,

    }
}