import {HPrimeIcon} from "../../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";


export const DisplayFiles = ({ file, index, handleRemoveFile }) => {
    return (

        <div key={index} className={"flex gap-1 items-center"}>
            <span class="number_pluse text-white flex-shrink-0">{index+1}</span>
            <div className={"flex-1 flex items-center justify-between post-input dark:bg-themeDarkPostInput dark:border-themeDarkColorLine p-3 post-input-design"} >
                <span className="text-xs dark:text-gray-300 line-clamp-1">{file.name}</span>
                <button onClick={() => handleRemoveFile(index)} type={"button"}>
                    <HPrimeIconSmall icon="pi-trash"  iconClass={"text-themeDanger"}></HPrimeIconSmall>
                </button>
            </div>
        </div>
    );
};