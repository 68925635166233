import React from "react";


import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "./HPrimeIcon";



export const  ButtonSearchList =({ icon,onClickReset,onClickSearch, classStyleReset,classStyleSearch,functionClassReset,functionClassSearch,spanClassReset,spanClassSearch,iconClassReset,iconClassSearch,tooltipTitleReset,tooltipTitleSearch, tooltipIdReset,tooltipIdSearch,iconClass,sizeIcon}) => {

    const { t } = useTranslation();

    return(
      <>
          <div className="flex items-end gap-2">
              <button type={"button"} className={`button box small_button zoom-in rounded-md ${!classStyleSearch?'bg-themeInformation text-white hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkInformation':classStyleSearch} ${tooltipTitleSearch ? `tooltip_class_${tooltipIdSearch}`:'tooltip_Search'} ${functionClassSearch?functionClassSearch:"searching"} `}
                    onClick={onClickSearch}
              >
                   <span className={`flex items-center justify-center  ${spanClassSearch}`}>
                       <HPrimeIcon icon={` ${icon ? icon:"pi-search"}`} iconClass={iconClass} sizeIcon={sizeIcon} />
                   </span>
                  <Tooltip anchorSelect={tooltipTitleSearch ? `.tooltip_class_${tooltipIdSearch}` : ".tooltip_Search"} content={tooltipTitleSearch ? tooltipTitleSearch : t("ApplySearch")} positionStrategy={"fixed"} />
              </button>
              <button type={"button"} className={`button box small_button zoom-in ${!classStyleReset?'bg-themeInformation text-white hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkInformation':classStyleReset} ${tooltipTitleReset ? `tooltip_class_${tooltipIdReset}`:'tooltip_Reset'} ${functionClassReset?functionClassReset:"searching"} `}
                   onClick={onClickReset}
              >
                   <span className={`flex items-center justify-center ${spanClassReset}`}>
                       <HPrimeIcon icon={` ${icon ? icon:"pi-list"}`} iconClass={iconClass} sizeIcon={sizeIcon} />
                   </span>
                  <Tooltip anchorSelect={tooltipTitleReset ? `.tooltip_class_${tooltipIdReset}` : ".tooltip_Reset"} content={tooltipTitleReset ? tooltipTitleReset : t("ResetSearch")} positionStrategy={"fixed"} />
              </button>
          </div>

  </>)
}