import React, {useState} from "react";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import queryString from "query-string";

export const useCalculateService=(currentPath,upgrade,handleValue,hideSpinner)=>{
    const queryParams = queryString.parse(window.location.search);

    const [data,setData] = useState({upgradeType: 0, finalPrice: {amount: 0, currency: 0, moneyType: 0}, discount: {amount: 0, discountType: 0, from:null, to: null}})
    const handleCalculateExtraHostPrice=async(extraHostValue)=>{

        try {
            const url = currentPath === '/Admin/ServiceUpgrade'?
                `/Upgrades/ExtraHost/Price?ExtraHost=${extraHostValue === undefined?upgrade.extraHost.extraHost:extraHostValue}&WebSiteId=${queryParams.websiteId}`:
                `/Upgrades/ExtraHost/Price?ExtraHost=${extraHostValue === undefined?upgrade.extraHost.extraHost:extraHostValue}`
            const response = await GET(url,{},'single');
            if(response.status === 200){
                setData(response.data);
                handleValue('extraHost', { price: response.data.finalPrice.amount })
                handleValue('extraHost',{ extraHost: extraHostValue === undefined?upgrade.extraHost.extraHost:extraHostValue })
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }


    const handleCalculateExtraMailHostHostPrice=async(extraMailHostValue)=>{

        try {
            const url = currentPath === '/Admin/ServiceUpgrade'?
                `/Upgrades/ExtraMailHost/Price?ExtraMailHost=${extraMailHostValue === undefined?upgrade.extraMailHost.extraMailHost:extraMailHostValue}&WebSiteId=${queryParams.websiteId}`:
                `/Upgrades/ExtraMailHost/Price?ExtraMailHost=${extraMailHostValue === undefined?upgrade.extraMailHost.extraMailHost:extraMailHostValue}`
            const response = await GET(url,{},'single');
            if(response.status === 200){
                setData(response.data);
                handleValue('extraMailHost', { price: response.data.finalPrice.amount })
                handleValue('extraMailHost',{ extraMailHost: extraMailHostValue === undefined?upgrade.extraMailHost.extraMailHost:extraMailHostValue })
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }catch (e) {
            hideSpinner();
        }
    }



    return {
        data,
        setData,
        handleCalculateExtraHostPrice,
        handleCalculateExtraMailHostHostPrice
    }

}