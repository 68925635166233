import React, {useState} from "react";
import {ToastContainer} from "react-toastify";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {Link, useNavigate} from "react-router-dom";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";
import {HandleHrmLayout} from "./HandleHrmLayout";

export const HandlePersonManager = ()=>{
    const { t } = useTranslation();


    const redirectTo = useNavigate();


    return (
        <div class=" space-y-5">
            <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CRM/User/List`)}
                                        icon={"pi-list"}
                                        title={t("list")}
                                        tooltipTitle={t("CustomerList")}

                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CRM/User/Group`)}
                                        icon={"pi-users"}
                                        title={t("grouping")}
                                        tooltipTitle={t("CustomerGrouping")}

                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CRM/User/Role`)}
                                        icon={"pi-star"}
                                        title={t("CreatingRole")}
                                        tooltipTitle={t("CreatingRole")}
                                    />
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CRM/User/Reminder/List`)}
                                        icon={"pi-clock"}
                                        title={t("Reminders")}
                                        tooltipTitle={t("Reminders")}

                                    />

                                </>
                            }

                />

            </div>


            <HandleHrmLayout/>

            <ToastContainer/>
        </div>
    )
}