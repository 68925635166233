export const handleCreateAdminSettings=(obj)=>{
  const data = {
      packagePricePercentage: obj.packagePricePercentage ?? 0,
      incrementStepHostPurchaseAmount: obj.incrementStepHostPurchaseAmount ?? 0,
      incrementStepMailHostPurchaseAmount: obj.incrementStepMailHostPurchaseAmount ?? 0,
      maximumHostAllowedPurchaseAmount:obj.maximumHostAllowedPurchaseAmount ??0,
      maximumMailHostAllowedPurchaseAmount: obj.maximumMailHostAllowedPurchaseAmount??0,
      minimumHostAllowedPurchaseAmount: obj.minimumHostAllowedPurchaseAmount??0,
      minimumMailHostAllowedPurchaseAmount:obj.minimumMailHostAllowedPurchaseAmount ??0,
      webSiteExpirationNotificationTime: obj.webSiteExpirationNotificationTime??0,
      defaultHostPurchaseAmount:obj.defaultHostPurchaseAmount ??0,
      defaultMailHostPurchaseAmount:obj.defaultMailHostPurchaseAmount??0,
      webSiteExpirationNotificationTimeInterval: obj.webSiteExpirationNotificationTimeInterval === null || obj.webSiteExpirationNotificationTimeInterval === undefined?null:obj.webSiteExpirationNotificationTimeInterval.value
  }
  return data;
}