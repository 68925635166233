import { useState } from 'react';
import React from "react";


import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HPrimeIconSmall} from "./HPrimeIconSmall";


let tooltipCounter = 0; // Global counter for tooltips
export const  SettingButton =({ icon, onClick, classStyle,functionClass,iconClass,tooltipTitle,}) => {

    const { t } = useTranslation();
    const [tooltipId] = useState(() => {
        tooltipCounter += 1;
        return `tooltip_${tooltipCounter}`;
    });

    return(
        <div className={"inline-block"}>
            <div className={`${tooltipTitle ? `tooltip_class_date_${tooltipId}`:'tooltip_Setting'} cursor-pointer grid place-content-center  ${functionClass?functionClass:"open-Setting"}`}
                 onClick={onClick}
            >
                <Tooltip anchorSelect={tooltipTitle ? `.tooltip_class_date_${tooltipId}` : ".tooltip_Setting"} content={tooltipTitle ? tooltipTitle : t("settings")} positionStrategy={"fixed"} />
                <HPrimeIconSmall icon={icon ? icon :'pi-cog'} iconClass={` con-spin ${iconClass ? iconClass :  `text-themeInformation dark:text-themeDarkInformation`} `}></HPrimeIconSmall>
            </div>

        </div>)
}