import React from "react";
import { Badge } from "primereact/badge";

export const Log = ({ log, key, isActive, handleSelectLogData,setTabs }) => {

    const handleGetRequestColor = (request) => {
        switch (request) {
            case 'GET':
                return 'contrast';
            case 'POST':
                return 'success';
            case 'PUT':
                return 'warning';
            default:
                return 'danger';
        }
    }

    const handleClick = () => {
        handleSelectLogData(log.id, log);
        const newTab = {
            id: "innerException",
            target: "#innerException",
            label: 'innerException',
        };
        if (log?.innerExceptionStackTrace !== null && log?.innerExceptionMessage !== null) {
            setTabs(prevTabs => {
                // Check if the tab already exists by its id
                const tabExists = prevTabs.some(tab => tab.id === newTab.id);
                if (!tabExists) {
                    return [...prevTabs, newTab];
                }
                return prevTabs; // Return the previous state if tab already exists
            });
        } else {
            // Remove the tab if innerExceptionStackTrace and innerExceptionMessage are null
            setTabs(prevTabs => {
                return prevTabs.filter(tab => tab.id !== newTab.id);
            });
        }
        window.scrollTo({ top: 320, behavior: 'smooth' });  // Scroll to the top of the page
    }

    return (
        <div key={key} onClick={handleClick} className={`elmah min-w-elmah cursor-pointer ${isActive ? 'active' : ''} post-input  overflow-hidden dark:bg-themeDarkPostInput post-input-design`}>
            <div className="size-full border flex gap-2 px-1 py-4 items-center">
                <div className={"relative flex-shrink-0"}>
                    <div className={`size-10 flex-shrink-0 border border-themeInformation rounded-full bg-themeDanger dark:bg-themeDarkSalt700 grid place-content-center`}>
                        <p className={'text-white text-xs'}>{log.serverVariable && log.serverVariable.StatusCode ? log.serverVariable.StatusCode : null}</p>
                    </div>
                </div>
                <div className="flex-1 space-y-2 w-full ">
                    <p className="text-xs line-clamp-1">{log.name}</p>
                    <Badge value={log.serverVariable && log.serverVariable.RequestMethod} size={5} severity={handleGetRequestColor(log.serverVariable.RequestMethod)}></Badge>
                    <p className={"text-xs line-clamp-1"}>{log.serverVariable && log.serverVariable.RequestPath}</p>
                    <p className={"text-xs line-clamp-1"}>{log.message}</p>
                    <p className={"text-xs text-amber-500 line-clamp-1"}>{log.logDateRelational}</p>
                </div>
            </div>
        </div>
    );
}
