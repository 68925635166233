import React from "react";
import {useTranslation} from "react-i18next";


export const BrowserStatistics=({browser,visits})=>{
    const { t } = useTranslation();

    return (
        <>
            <div className="flex items-center justify-between  H-direction-ltr">
                <div className="flex items-center">
                    <p className="text-themeInformation text-xs dark:text-themeDarkInformation">{browser}</p>
                </div>
                <div className="border-b border-dashed border-gray-400 mx-2 h-px flex-1"></div>
                <div className="flex items-center">
                    <p className=" text-xs dark:text-gray-300">({visits})</p>
                </div>
            </div>
        </>
    )
}