
import React, {useEffect, useState} from "react";
import { InputNumber } from "primereact/inputnumber";
import { useTranslation } from "react-i18next";
import {GET} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {useTabServices} from "../Hooks/useTabServices";
import Select from "react-select";
import {DateTimePicker} from "../../../Commans/Dates/DateTimePicker";
import queryString from "query-string";
import {useCalculateService} from "../Hooks/useCalculateService";

export const ExtraHostTab = ({paymentMethods,showSpinner,hideSpinner,currencies,upgrade,handleValue,handleSelectValue,currentPath,handleDateTime,setUpgrade}) => {
    const { t } = useTranslation();
    const [extraHost,setExtraHost] = useState({upgradeType: 0,paymentMethod:0, finalPrice: {amount: 0, currency: 0, moneyType: 0}, discount: {amount: 0, discountType: 0, from:null, to: null}, firstPrice: {amount: 0, currency: 0, moneyType: 0}, siteSpace: 0, proposedSpace: 0});
    const {handleShowPrice,handleGetCurrentType,handleGetPaymentMethod} = useTabServices(currentPath)
    const queryParams = queryString.parse(window.location.search);
    const {handleCalculateExtraHostPrice,data,setData} = useCalculateService(currentPath,upgrade,handleValue,hideSpinner);
    const fetchData=async()=>{
        try {
            showSpinner();
            const response = await GET(`/Upgrades/ExtraHost`,{},'single');
            if(response.status === 200 ){
                setExtraHost(response.data);
                handleCalculateExtraHostPrice();
                hideSpinner();
            }
            else{
                handleApiError(response,hideSpinner)
            }
        }catch (e) {
            hideSpinner();
        }
    }





    useEffect(() => {
            fetchData();
    }, []); // I

    const handleKeyDown = (e) => {
        e.preventDefault();
    };

    return (
     <>
         <div className="space-y-2">
             <div className="space-y-3 ">
                 <div className={"box p-2 space-y-2 bg-white dark:bg-themeDarkSalt700"}>
                     <div className={"flex justify-between items-center"}>
                         <h2 className={"dark:text-gray-300 text-xs"}>{t("TariffPrice")} {handleGetPaymentMethod(paymentMethods,extraHost.paymentMethod)}:</h2>
                         <del  className={"text-themeDanger text-xs"}>{handleShowPrice(extraHost.firstPrice.amount,currencies,extraHost.firstPrice.currency)}</del>
                         <span className={"text-gray-300 text-xs"}>{handleShowPrice(extraHost.finalPrice.amount,currencies,extraHost.finalPrice.currency)}</span>
                     </div>
                     <div className={"flex justify-between items-center"}>
                         <h2 className={"dark:text-gray-300 text-xs"}>{t("Yourcurrentspace")}</h2>
                         <span className={"text-gray-300 text-xs"}>{extraHost.siteSpace} MB</span>
                     </div>
                     <div className={"flex justify-between items-center"}>
                         <h2 className={"dark:text-gray-300 text-xs"}>{t("Suggestedspace")}</h2>
                         <span className={"dark:text-gray-300 text-xs"}>{extraHost.proposedSpace}</span>
                     </div>
                     <div className={"p-2 post-input rounded dark:bg-themeDarkPostInput flex justify-between items-center"}>
                         <h2 className={"text-xs dark:text-gray-300"}>{t("Theamountofpurchases")}</h2>
                         <span className={"dark:text-gray-300 text-xs"}>{upgrade.extraHost.extraHost}MB</span>
                     </div>
                 </div>
                 <div className={"Service_Upgrade "}>
                     <InputNumber inputId="horizontal-buttons"  onKeyDown={handleKeyDown}  value={upgrade.extraHost.minValue} onChange={(e) => handleCalculateExtraHostPrice(e.value)}  showButtons min={upgrade.extraHost.minValue} max={upgrade.extraHost.maxValue} suffix={'MB'} buttonLayout="horizontal" step={upgrade.extraHost.step}
                                  decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                  mode="decimal"  />
                 </div>
             </div>

             <div>
                 {
                     currentPath === '/Admin/ServiceUpgrade'?
                         <div className={"space-y-1 border-2 border-red-500"}>
                             <label className="text-xs dark:text-gray-300"> {t("Price")} </label>
                             <InputNumber type={"text"} name={'price'}
                                          min={0}
                                          value={upgrade.extraHost.price}
                                          onChange={(e) => handleValue('extraHost', { price: e.value })}
                                          suffix={handleGetCurrentType(currencies,extraHost.firstPrice.currency)}
                             />
                         </div>

                         :
                         <div className={"flex justify-between items-center"}>
                             <h2 className="dark:text-gray-300 text-xs">
                                 {t("Price")}:
                             </h2>
                             <span className="text-themeInformation dark:text-themeDarkInformation">
                                    {handleShowPrice(data.finalPrice.amount,currencies,data.finalPrice.currency)}
                                </span>
                         </div>

                 }
             </div>
         </div>
     </>
    );
};

