
// adds order  +1
// map all slugs in to url

export function processJsonData(data, parentOrder = 1) {
    return data.map((item, index) => {
        const order = index + 1;
        const url = item.slug.toLowerCase(); // Assuming you want the URL to be in lowercase
        const processedItem = {
            ...item,
            order,
            url,
        };
        delete processedItem.slug;
        delete processedItem.parentId;
        delete processedItem.depth;
        delete processedItem.expanded;
        delete processedItem.subtitle;
        delete processedItem.id;
        if (item.children.length > 0) {
            processedItem.children = processJsonData(item.children, order);
        }
        return processedItem;
    });
}

//deletes node
export function deleteNode(data,nodeId) {
    return data.map(item => {
        if (item.id === nodeId) {
            // Node found, exclude it from the result
            return null;
        }

        if (item.children && item.children.length > 0) {
            // Recursively process children
            item.children = deleteNode(item.children, nodeId);
            // Remove empty children arrays
            item.children = item.children.filter(Boolean);
        }

        return item;
    }).filter(item => item !== null);
}

// adds type 0 as default type to json tree
export function handleAddDefaultType(data) {
    return data.map(item => ({
        ...item,
        targetType: 0,
        children: item.children ? handleAddDefaultType(item.children) : [], // Recursively add type property to children
    }));
}


// gets a specific row based on id
export function handleGetSpecificNode(data, nodeId) {
    let result = null;
    function traverse(tree) {
        for (const node of tree) {
            if (node.id === nodeId) {
                result = node;
                return; // Stop searching if the node is found
            }

            if (node.children && node.children.length > 0) {
                traverse(node.children);
            }
        }
    }
    traverse(data);
    return result;
}

// gets type , title,slug to update tree
export function handleUpdateNodeTree(data, nodeId, newTargetType, newTitle, newSlug) {
    function traverse(tree) {
        return tree.map(node => {
            if (node.id === nodeId) {
                // Node found, update the fields
                return {
                    ...node,
                    targetType: newTargetType,
                    title: newTitle,
                    slug: newSlug,
                    subtitle:newSlug,
                };
            }

            if (node.children && node.children.length > 0) {
                // Recursively update children
                return {
                    ...node,
                    children: traverse(node.children),
                };
            }

            // No changes, return the original node
            return node;
        });
    }
    return traverse(data);
}
// handle Add a new node


export function handleAddNodeToTree(parentId, data, targetType, newTitle, newSlug) {
    function generateUniqueId() {
        // Replace this with your method to generate unique IDs
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    if (parentId === null) {
        // Add a new node directly to the root
        const newNode = {
            id: generateUniqueId(),
            targetType: targetType,
            expanded: true,
            parentId: null,
            title: newTitle,
            slug: newSlug,
            subtitle: newSlug,
            children: [],
        };

        data.push(newNode);
        return data;
    }

    function findNodeById(tree, id) {
        for (const node of tree) {
            if (node.id === id) {
                return node;
            }

            if (node.children) {
                const foundNode = findNodeById(node.children, id);
                if (foundNode) {
                    return foundNode;
                }
            }
        }

        return null;
    }

    function traverseAndAdd(parentId, data) {
        const parentNode = findNodeById(data, parentId);

        if (parentNode) {
            const newNode = {
                id: generateUniqueId(),
                targetType: targetType,
                expanded: true,
                parentId: parentId,
                title: newTitle,
                slug: newSlug,
                subtitle: newSlug,
                children: [],
            };

            parentNode.children.push(newNode);
            return true;
        }

        for (const node of data) {
            if (node.children && traverseAndAdd(parentId, node.children)) {
                return true;
            }
        }

        return false;
    }

    traverseAndAdd(parentId, data);
    return data; // Return the updated tree
}
export function handleConvertKeyValuesToTreeSource(valueSource) {
    const data = valueSource.map((item) => ({
        id: item.key,
        title: item.value,
        slug: item.value,
        parentId: null,
        depth: 0
    }));
    return data;
}