import React from "react";
import {Link} from "./Link";

export const LinkTab=({links,handleAddRow,handleRemoveRow,handleChange})=>{

    return (
        <>
            {links.length > 0 ? links.map((item,index) => (
                <Link
                    index={index}
                    row={item}
                    handleChange={handleChange}
                    handleRemoveRow={handleRemoveRow}
                    handleAddRow={handleAddRow}
                />
            )) : null}
        </>
    )
}