import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {Packages} from "../Packages/Packages";
import {Users} from "../Users/Users";
import {License} from "../Licenses/License";
import {Domains} from "../Domains/Domains";
import {Servers} from "../Servers/Servers";
import {Templates} from "../Templates/Templates";
import {Payments} from "../Payments";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import {useTranslation} from "react-i18next";

export const HandleAddSite = ()=>{
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState("packages");
    const handleTabClick = (tabId) => setActiveTab(tabId);

    const [site,setSite] = useState({PackageIds:[], UserId:null, Title:null, KeyWords:null, Description:null, LicenseTime:null, Culture:null, Currency:null,dayOfWeek:null, CountryId:null, CalendarType:null, LogoHeader:null, LogoFooter:null, Domains:null, SiteSpace:100, MailSpace:100,TemplateMasterId:null,HostServerId:null,HostServerIp:null,DbServerId:null,DbServerIp:null,WebServerId:null,WebServerIp:null,AdminPanelServerId:null,AdminPanelServerIp:null,MailServerId:null,MailServerIp:null,DedicatedServerId:null,DedicatedServerIp:null,CoreServerId:null,CoreServerIp:null,ConnectionString:null,AllowIps:null});
    const [toggle,setToggle] = useState({isHost:false,isWebserver:false,isDbServer:false,isMail:false,isDedicated:false,isAdminPanel:false,isPublic:true,isCoreServer:false})
    const OnToggleChange=(name,value)=>{
        setToggle((prevState)=>({...prevState,[name]:value}));
    }
    const onChangePackage=(id,isChecked)=>{
        if (isChecked) {
            setSite((prevSite) => ({...prevSite, PackageIds: [...prevSite.PackageIds, id],}));
        }
        else {
            setSite((prevSite) => ({...prevSite, PackageIds: prevSite.PackageIds.filter((packageId) => packageId !== id),}));
        }
    }
    const onChangeTemplate=(id,isChecked)=>{
        if (isChecked) {
            setSite((prevSite) => ({ ...prevSite, TemplateMasterId: id }));
        } else {
            setSite((prevSite) => ({ ...prevSite, TemplateMasterId: null }));
        }
    }

    const onChangeUser = async (data,name)=>{
        setSite((prevSite) => ({...prevSite, [name]:data}));
    }
    const handleValueChange = (data,name) => {
        setSite((prevContent) => ({ ...prevContent, [name]: data }));
    };
    const hanldeChangeDomain =(domains)=>{
        setSite((prevState)=>({...prevState,Domains: domains}));
    }

    const redirectTo = useNavigate();


    return (
        <div className=" space-y-5">

            <div className={"box bg-white dark:bg-themeDarkSalt700 p-3"}>
                <ListFilter label={null} icon={null}
                            showChildrenTwo={false}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>redirectTo('/Admin/Website/List')}
                                        icon={"pi-list"}
                                        title={t("list")}
                                        tooltipTitle={t("WebsiteList")}
                                    />

                                </>
                            }
                />

            </div>


            <div className="post ">
                <div class="post__content tab-content relative box bg-white dark:bg-themeDarkSalt700 p-3">

                    <div className={`tab-content__pane ${ activeTab === "packages" ? "active" : "" }`} id="packages">
                            {
                                activeTab === "packages"?
                                    <Packages values={site} handleChangeTab={handleTabClick} onChangePackage={onChangePackage}/>:
                                    null
                            }
                        </div>

                    <div className={`tab-content__pane ${ activeTab === "users" ? "active" : "" }`} id="users">
                            {
                                activeTab === "users"?
                                    <Users onChangeUser={onChangeUser} UserId={site.UserId} handleChangeTab={handleTabClick} />
                                    :null
                            }
                        </div>

                    <div className={`tab-content__pane ${ activeTab === "license" ? "active" : "" }`} id="license">
                            {
                                activeTab ==="license"?
                                    <License onValueChange={handleValueChange} handleChangeTab={handleTabClick} values={site}   />:
                                    null
                            }
                        </div>

                    <div className={`tab-content__pane ${ activeTab === "domains" ? "active" : "" }`} id="domains">
                            {
                                activeTab === "domains"?
                                    <Domains values={site} handleChangeTab={handleTabClick} onChangeDomain={hanldeChangeDomain} onValueChange={handleValueChange}/>:
                                    null
                            }
                        </div>

                    <div className={`tab-content__pane ${ activeTab === "servers" ? "active" : "" }`} id="servers">
                            {
                                activeTab === "servers"?
                                    <Servers OnToggleChange={OnToggleChange} toggles={toggle} values={site} handleChangeTab={handleTabClick} onValueChange={handleValueChange} />:
                                    null
                            }
                        </div>
                    <div className={`tab-content__pane ${ activeTab === "templates" ? "active" : "" }`} id="templates">
                            {
                                activeTab === "templates"?
                                    <Templates handleChangeTab={handleTabClick} onChangeTemplate={onChangeTemplate} values={site}/>
                                    :null
                            }
                        </div>

                    <div className={`tab-content__pane p-3 post-input dark:bg-themeDarkPostInput   ${ activeTab === "payments" ? "active" : "" }`} id="payments">
                            {
                                activeTab === "payments"?
                                    <Payments handleChangeTab={handleTabClick}/>
                                    :null
                            }
                        </div>

                </div>
            </div>


            <ToastContainer/>
        </div>
    )
}