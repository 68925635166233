import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export const useTabs=()=>{
    const { t } = useTranslation();

    const [tabId, setTabId] = useState("specification");
    const handleChangeTab = (tabId) => {
        setTabId(tabId);
    };

    const tabs = [
        {
            id: "specification",
            subLabel:t("Specifications"),
            icon: "pi-file-check",
        },
        {
            id: "metaTag",
            subLabel:t("MetaTag"),
            icon: "pi-tag",
        },
        {
            id: "settings",
            subLabel:t("settings"),
            icon: "pi-cog",
        },
        {
            id: "attach",
            subLabel:t("AttachmentFile"),
            icon: "pi-paperclip",
        },
        {
            id: "transport",
            target: "#transport",
            subLabel:t("SendingMethod"),
            icon: "pi-truck",
        },

    ];
    return {
        tabs,tabId,handleChangeTab
    }

}