import React, {useState} from "react";
import {ListFilter} from "../../../../Commans/Grid/ListFilter";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {ButtonPlus} from "../../../../Commans/UIParts/ButtonPlus";
import Select from "react-select";
import {InputText} from "primereact/inputtext";
import {InputSwitch} from "../../../../Commans/Filters/InputSwitch";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";
import {FantasyModal} from "../../../../Commans/UIParts/FantasyModal";
import {InputNumber} from "primereact/inputnumber";
import {RequiredIcon} from "../../../../Commans/Errors/RequiredIcon";
import {DisplayError} from "../../../../Commans/Errors/DisplayError";
import {ToggleSwitch} from "../../../../Commans/UIParts/ToggleSwitch";
import {TinyLoading} from "../../../../Commans/Loadings/TinyLoading";

export const HandleBusinessSettings=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();

    const [IsOpenMenu,setIsOpenMenu] = useState(false);

    const handleOpenMenu = () => {
        setIsOpenMenu(true);

    };
    const handleCloseMenu = ()=>{
        setIsOpenMenu(false);
    }



    return(
        <>
            <div class="space-y-5">
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3">
                            <ListFilter label={null} icon={null}
                                        showChildrenTwo={false}
                                        childrenOne={
                                            <>
                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/Dashboard`)}
                                                    icon={"pi-image"}
                                                    title={t("Personal")}
                                                    tooltipTitle={t("PersonalSettings")}
                                                />

                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/General`)}
                                                    icon={"pi-objects-column"}
                                                    title={t("Public")}
                                                    tooltipTitle={t("GeneralSettings")}
                                                />


                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/Routing`)}
                                                    icon={"pi-code"}
                                                    title={t("Routing")}
                                                    tooltipTitle={t("Routing")}
                                                />
                                                <ButtonPlus
                                                    onClick={()=>redirectTo(`/Harmony/Setting/Main/CleanTemp`)}
                                                    icon={"pi-trash"}
                                                    title={t("DataCleanup")}
                                                    tooltipTitle={t("TemporaryDataCleanup")}
                                                />



                                            </>
                                        }

                            />

                        </div>

                <div className={"space-y-5 bg-white p-2 box dark:bg-themeDarkSalt700"}>
                    <div className="space-y-3">
                        <div class="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                            <h2 className={"dark:text-gray-300"}>
                                {t("CompanyData")}
                            </h2>
                        </div>
                        <div className={"px-2"}>
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("CompanyType")}</label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        name={'type'}
                                        placeholder={t("selection")}

                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("companyName")}</label>
                                    <InputText className="input w-full" name={'name'}  />
                                </div>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("companyNationalIdNumber")}</label>
                                    <InputText name={'nationalCode'}  className="input w-full" />
                                </div>
                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("BrandName")}</label>
                                    <InputText className="input w-full" name={'brand'}  />
                                </div>

                                <div className="space-y-1">
                                    <label className="text-xs dark:text-gray-300">{t("LandlinePhone")}</label>
                                    <InputText type={'text'} className="input w-full" name={'registrationNumber'}   />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("country")}</label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("province")}</label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        name={'Provinces'}
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="dark:text-gray-300 text-xs">{t("city")}</label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        name={'CityId'}
                                        placeholder={t("selection")}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div>
                                    <InputSwitch  name={'hasBrandConfirmed'} lable={t("OfficialRegistration")} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-3">
                        <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation flex gap-2 items-center">
                            <h2 className={"dark:text-gray-300"}>
                                {t("شبکه های اجتماعی")}
                            </h2>
                            <button type={"button"} className={"text-themeInformation dark:text-themeDarkInformation"} onClick={()=>handleOpenMenu()}>({t("Create")})</button>
                            <p>درحالت اول از api تمام ارتباطاط نشان داده میشود و در صورت نیاز از دکمه ایجاد میسازد درصورت نبود آیکون مورد نظر به صورت اتومات ایکون مشابه گزاشته شود</p>
                        </div>
                        <div className={"px-2"}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 ">
                                <div class="space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <label className="dark:text-gray-300 text-xs">
                                        {t("اینستاگرام")}
                                    </label>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-instagram"} />
                                        </span>
                                        <div className={"relative w-full"}>
                                            <InputText  className="input w-full" name={'fullName'} disabled={true}  />
                                            <div className={"absolute top-1/2 -translate-y-1/2 end-2 grid place-content-center"}>
                                                <button type={"button"}>
                                                    <HPrimeIcon
                                                        icon={"pi-copy"}
                                                        iconClass={"text-themeSecondary"}
                                                    />
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("Telegram")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-telegram"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("واتساپ")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-whatsapp"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("فیسبوک")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-facebook"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("توییتر")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-twitter"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("لینکدین")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-linkedin"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("یوتیوب")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-youtube"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("روبیکا")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-comments"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("آپارات")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-comments"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                                <div class=" space-y-1 p-2 post-input dark:bg-themeDarkPostInput post-input-design">
                                    <div className="flex justify-between">
                                        <label className="dark:text-gray-300 text-xs">
                                            {t("ایتا")}
                                        </label>
                                    </div>
                                    <div className="p-inputgroup">
                                       <span className="p-inputgroup-addon border dark:border-themeDarkColorLine">
                                            <HPrimeIcon icon={"pi-comments"} />
                                        </span>
                                        <InputText  className="input w-full" name={'fullName'}  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <FantasyModal openModal={IsOpenMenu} closeModal={handleCloseMenu} title={t("شبکه های اجتماعی")}>

                <div className="space-y-5">
                    <div className={"flex items-center gap-3"}>
                        <div className="space-y-1 w-48 flex-shrink-0">
                                <label className="dark:text-gray-300 text-xs">
                                    {t("type")}
                                    <RequiredIcon/>
                                </label>

                                    <Select

                                        isMulti={false}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t("selection")}
                                        menuPosition={"fixed"}
                                        classNamePrefix={"h_multi_select"}
                                    />

                                </div>
                        <div className="space-y-1 flex-1 w-full">
                            <label className="dark:text-gray-300 text-xs">
                                {t("آدرس")}
                                <RequiredIcon/>
                            </label>
                            <InputText
                                type="text"
                                className="input w-full"
                                name={'title'}
                            />
                        </div>

                    </div>
                    <div >
                        <button type={"submit"} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative"}>
                            {t("insertion")}
                        </button>
                    </div>
                </div>

            </FantasyModal>

        </>

    )
}