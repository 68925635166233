import React, {useContext, useEffect,useRef, useState} from "react";
import {FileDisplayer} from "./FileDisplayer";
import {FileContext} from "./FileProvider";



// کامپوننت پدر fileDisplayer هست
// فایل ها اینجا از طریف api لود میشن


import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {useNavigate} from "react-router-dom";
import {GridDateFomartter} from "../../Commans/Grid/GridDateFomartter";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import client from "../../../Services/AxiosService/axiosClient";
import {ExceptionHandlerService} from "../../../Services/Globals/Errors/ExceptionHandlerService";
import queryString from "query-string";
import {fileManager} from "../../../Services/Globals/Public/AppSetting";
import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {NoDataHarmony} from "../../Commans/NoDataHarmony";
import {HPrimeIconSmall} from "../../Commans/UIParts/HPrimeIconSmall";
import {ButtonSubmitAndCancel} from "../../Commans/UIParts/ButtonSubmitAndCancel";
export const FileHandler = ({handleGetFolderSource,inUse,handleCloseFtp})=>{
    const { t } = useTranslation();
    const pathRef = useRef(null);
    const redirectTo = useNavigate()
    const {setClipBoard,path,pattern,result,setVisible,visible,spinner,setSpinner,setPath} = useContext(FileContext)
    const [files,setFile] = useState(null)
    const onApiError=()=>{
        setSpinner(false);
    }


    // تابعی هست از api Service
    // برای فراخانی و لود فایل ها
    const getFilesApiHandler = async(urlPath)=>{
      try {
          setSpinner(true);
         // redirectTo(`/Authentication/FileManager?url=${encodeURIComponent(path)}`);
          const response = await client.get('/Ftp/Files',{params: {path:path, pattern:pattern}})
          if(response.status === 200) {
              setFile(response.data);
              setSpinner(false);
          }else{
              handleApiError(response,onApiError);
          }
      }catch (error) {
          return ExceptionHandlerService(error);
      }
    }

    useEffect( ()=>{
        const queryParams = queryString.parse(window.location.search);
        const urlPath = queryParams.url;

        getFilesApiHandler(urlPath);

    },[path,pattern,result])


    // مسیر فولدر یا فایل جاری را داخل clipboard  کپی میکنه
    const copyContent = async (text)=>{
        setClipBoard(text);
        handleSuccessMessage(t("Yourtexthasbeencopied"))
    }
    const handleGetPath=(text)=>{
        handleSuccessMessage(t("Yourtexthasbeencopied"))
        handleGetFolderSource(text)
    }


    // ....
    const HandleOnHide=()=>{
        setVisible(!visible)
    }

    // جزییات فایل یا فولدر رو داخل این ابجت هندل میکنیم
    const [detail,setDetail] = useState({name: '', created: '', lastModified: '', size: '', type: ''});
    // تابع اپدیت جزییات فایل یا فولدر هست
    const handleDetails = (data)=>{
        setDetail({ ...data });
    }


    // با فراخوانی هر بار این تابع یک کارکتر از سمت راست تا اولین کارکتر +  برمیداریم و حذف میکنیم
    const returnBack = () => {
        const pathArray = path.split(fileManager.separator);
        pathArray.pop();
        const updatedPath = pathArray.join(fileManager.separator);
        setPath(updatedPath);
    };



    return (
        <>

            <div className="box bg-white dark:bg-themeDarkSalt700 relative">
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }

                <div className={'p-2 flex justify-between items-center border-b dark:border-themeDarkColorLine gap-2'} style={{direction:'ltr'}}>
                    <div className={"flex-shrink-0"}>
                        <button type={"button"} className="button_back dark:border-themeDarkColorLine grid place-content-center bg-themeInformation dark:bg-themeDarkInformation hover:bg-themeHoverInformation text-white" onClick={() => returnBack()}>
                            <HPrimeIconSmall
                            icon={"pi-reply"}
                            />
                        </button>
                    </div>
                    <div className={"flex-1 flex items-center"}>
                        <div className={'flex items-center gap-1  dark:text-gray-300 flex-1'}>
                            <div className="flex-shrink-0">
                                <HPrimeIconSmall
                                icon={"pi-folder-open"}
                                />
                            </div>
                            <input className="w-full input_copy text-xs  dark:text-gray-300 dark:border-themeDarkColorLine" ref={pathRef}  value={path === ''?'/':path.replace(/\+/g, " / ")}  disabled={true} aria-disabled={true}/>
                        </div>
                        <button type={"button"} className="cursor-pointer button_copy flex tooltip_Copy gap-1 dark:border-themeDarkColorLine items-center bg-themeInformation dark:bg-themeDarkInformation hover:bg-themeHoverInformation text-white" onClick={() => copyContent(pathRef.current.value)}>
                            <p>{t("CopyAddress")}</p>
                            <HPrimeIconSmall
                            icon={"pi-copy"}
                            />
                            <Tooltip anchorSelect=".tooltip_Copy" content={t("CopyPageURL")} />
                        </button>
                    </div>
                </div>

                <div className={`overflow-auto scrollbar relative w-full ${inUse?'max_height30':'h-screen '}`}>

                    <div class=" grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full gap-3 sm:gap-5  p-2" >
                        {
                            files && files.length > 0 ?
                                files.map((item,index)=>{return(
                                    <FileDisplayer size={item.size} index={index}  handleDetail={handleDetails} key={index} extension={item.extension} name={item.name} isDirectory={item.isDirectory}  />
                                )}):
                                <NoDataHarmony
                                classStyle={"sm:col-span-3 lg:col-span-4 xl:col-span-5"}
                                />
                        }
                    </div>

                </div>
                {
                    inUse?
                        <ButtonSubmitAndCancel
                            onRegisterClick={() =>handleGetPath(pathRef.current.value)}
                            onCancelClick={() => handleCloseFtp()}
                            registerText={t('selection')}
                        />
                        :
                        null
                }
                <FantasyModal openModal={visible}  closeModal={HandleOnHide} title={t("FileDetails")}  >
                    <div className={'box bg-white dark:bg-themeDarkSalt700 overflow-hidden  p-2 space-y-2'}>

                        <div className="flex gap-2">
                                <div className=" size-20 flex-shrink-0 ">
                                    {
                                        detail.type === 'Directory'?
                                            <div className="size-full file__icon--directory"></div>
                                            :
                                            <div className="size-full file__icon--file"></div>
                                    }
                                </div>
                                <div className="flex-1 space-y-2">
                                    <div className="flex post-input dark:bg-themeDarkSalt600 p-2 items-center gap-2 post-input-design">
                                        <p className="text-xs dark:text-gray-300 text-nowrap">{t("FileName")} :</p>
                                        <span className="dark:text-gray-300 text-xs line-clamp-1">
                                            {detail.name}
                                        </span>
                                    </div>
                                    <div className="flex post-input dark:bg-themeDarkSalt600 p-2 items-center gap-2 post-input-design">
                                        <p className="dark:text-gray-300 text-xs text-nowrap">{t("FileType")} :</p>
                                        <span className="dark:text-gray-300 text-xs line-clamp-1">{detail.type}</span>
                                    </div>
                                </div>
                            </div>
                        <div className="flex post-input dark:bg-themeDarkSalt600 p-2 items-center gap-2 post-input-design ">
                                <p className="dark:text-gray-300 text-xs text-nowrap">{t("FileSize")} :</p>
                                <span className="dark:text-gray-300 text-xs line-clamp-1">
                                    {detail.size}
                                </span>
                            </div>
                        <div className="flex post-input dark:bg-themeDarkSalt600 p-2 items-center gap-2 post-input-design">
                                <p className="dark:text-gray-300 text-xs text-nowrap">{t("FileDetails")} :</p>
                                <span className="dark:text-gray-300 text-xs line-clamp-1">
                                    {detail.created}
                                </span>
                            </div>
                        <div className="flex post-input dark:bg-themeDarkSalt600 p-2 items-center gap-2 post-input-design">
                                <p className="dark:text-gray-300 text-xs text-nowrap">{t("LastModifiedDate")}:</p>
                                <span className="dark:text-gray-300 text-xs line-clamp-1">
                                    <GridDateFomartter date={detail.lastModified} hasTime={true} />
                                </span>
                            </div>

                    </div>
                </FantasyModal>

            </div>

        </>

    )
}