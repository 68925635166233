import React, {useEffect, useState} from "react";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {DisplayError} from "../../../Commans/Errors/DisplayError";
import {InputText} from "primereact/inputtext";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {convertSelectDataHandler} from "../../../CMS/Contents/ContentService";
import {GET, POST} from "../../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {validate} from "../../../../Services/Globals/validate";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useCutomProductGroup} from "./GroupDetails/useCutomProductGroup";
import {productGroupSchema} from "./productGroupSchema";
import {handleAddConvertProductGroup} from "./ProductGroupService";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";

export const ProductGroup=(props)=>{
    const {t}=useTranslation();
    const [IsshowSpinner,setIsShowSpinner] = useState(false);
    const [errors, setErrors] = useState({});
    const [refresh,setRefresh] = useState(false);
    const [initialSource,setInitialSource] = useState({productGroups:[],productTypes:[],templates:[]});

    const handleInitialSource=(productGroups,productTypes,templates)=>{
        const ProductGroupData = convertSelectDataHandler(productGroups)
        const ProductTypeData = convertSelectDataHandler(productTypes)
        const templatesData = convertSelectDataHandler(templates)
        setInitialSource({
            productGroups:ProductGroupData,
            productTypes: ProductTypeData,
            templates: templatesData
        })
        return {ProductGroupData,ProductTypeData,templatesData}
    }
    const handleClearErrors=()=>{
        setErrors({});
    }
    const onSetErrors=(errors)=>{
        setErrors(errors)
    }
    const Refresh=()=>{
        setRefresh(prevState => !prevState);
    }
    const showSpinner=()=>{
        setIsShowSpinner(true);
    }
    const hideSpinner=()=>{
        setIsShowSpinner(false);
    }

    const {group,setGroup,handleValue,handleSelectValue} = useCutomProductGroup(setErrors);

    const fetchData = async (IsOpen) => {
        if (IsOpen) {
            try {
                const [responseType, responseGroup, responseTemplate] = await Promise.all([
                    GET('/Enumerations/EnProductType', {}, 'IEnumerable'),
                    GET('/ProductGroups/Selection', {}, 'IEnumerable'),
                    GET('/TemplatePages/selection?Type=1&Type=13', {}, 'IEnumerable')
                ]);
                if (responseType.status === 200 && responseGroup.status === 200 && responseTemplate.status === 200) {
                    const { ProductGroupData, ProductTypeData, templatesData } = handleInitialSource(
                        responseGroup.data,
                        responseType.data,
                        responseTemplate.data
                    );
                    setGroup({
                        slug: '',
                        order: 1,
                        excerpt: '',
                        parentId: ProductGroupData[0],
                        type: ProductTypeData[0],
                        seO_Title: '',
                        title: '',
                        stateShow: true,
                        stateGoogle: true,
                        templateId: templatesData[0]
                    });
                    handleClearErrors();
                } else {
                    handleApiError(responseType, hideSpinner);
                    handleApiError(responseGroup, hideSpinner);
                    handleApiError(responseTemplate, hideSpinner);
                }
            } catch (e) {
                console.log(e);
                hideSpinner();
            }
        }
    };
    useEffect(()=>{
        fetchData(props.IsOpen);
    },[refresh,props.IsOpen]);

    const handleSubmitProductGroup=async (e)=>{
        e.preventDefault();
        const isValid = validate(productGroupSchema,group,onSetErrors);
        if(!isValid){
            return;
        }
        try {
            showSpinner();
            const productGroup = handleAddConvertProductGroup(group);
            const response = await POST(`/ProductGroups/CreateProductGroup`,productGroup,false);
            if(response.status === 200){
                hideSpinner()
                props.handleSuccess(true);
                handleSuccessMessage(t("SuccessDone"));
            }
            else{
                handleApiError(response,hideSpinner);
            }
        }
        catch (e) {
            hideSpinner();
        }
    }


    return (

        <>
            <div className="relative  ">
                <form method={"post"} onSubmit={handleSubmitProductGroup} className={"space-y-2"}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div className="space-y-1">
                            <div className={"flex justify-between items-center"}>
                                <label className="dark:text-gray-300 text-xs">{t("Title")}</label>
                                <DisplayError message={errors.title} />
                            </div>

                            <InputText type={"text"}  name={"title"} value={group.title} onInput={(e) => handleValue(e)} className={" input w-full"}/>
                        </div>
                        <div className="space-y-1">
                            <div className={"flex justify-between items-center"}>
                                <label className="dark:text-gray-300 text-xs">{t("ArticleAddress")}</label>
                                <DisplayError message={errors.slug} />
                            </div>
                            <InputText type={"text"} name={"slug"}
                                       value={group.slug}
                                       onInput={(e) => handleValue(e)}
                                       className={" input w-full"}
                                      />
                        </div>
                        <div className="space-y-1">
                            <label className="dark:text-gray-300 text-xs">{t("WebsiteTitle")}</label>
                            <InputText type={"text"} name={"seO_Title"} value={group.seO_Title} onInput={(e) => handleValue(e)}
                                       className={" input w-full"} />
                        </div>
                        <div className="space-y-1">
                            <label className="dark:text-gray-300 text-xs">{t("Group")}</label>
                            <div>
                                <Select options={initialSource.productGroups} // Use an empty array if selectOptions.type is null or undefined
                                        isMulti={null}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(data) =>handleSelectValue(data, 'parentId')}
                                        name={'parentId'}
                                        placeholder={t("selection")}
                                        value={group.parentId}
                                        menuPosition={'fixed'}
                                        classNamePrefix={"h_multi_select"}
                                />

                            </div>
                        </div>
                        <div className="space-y-">
                            <label className="dark:text-gray-300 text-xs">{t("type")}</label>
                            <Select options={initialSource.productTypes} // Use an empty array if selectOptions.type is null or undefined
                                    isMulti={null}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data) =>handleSelectValue(data, 'type')}
                                    name={'type'}
                                    placeholder={t("selection")}
                                    value={group.type}
                                    menuPosition={'fixed'}
                                    classNamePrefix={"h_multi_select"}

                            />
                        </div>
                        <div className="space-y-">
                            <label className="dark:text-gray-300 text-xs">{t("template")}</label>
                            <Select options={initialSource.templates} // Use an empty array if selectOptions.type is null or undefined
                                    isMulti={null}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(data) =>handleSelectValue(data, 'templateId')}
                                    name={'type'}
                                    placeholder={t("selection")}
                                    value={group.templateId}
                                    menuPosition={'fixed'}
                                    classNamePrefix={"h_multi_select"}

                            />
                        </div>
                    </div>
                    <div className={"grid grid-cols-2"}>
                        <div className={"grid-cols-1"}>
                            <InputSwitch value={group.stateShow} OnValueSelection={handleSelectValue} lable={t("DisplayStatus")} name={'stateShow'}/>
                        </div>
                        <div className={"grid-cols-1"}>
                            <InputSwitch value={group.stateGoogle} OnValueSelection={handleSelectValue} lable={t("stateGoogle")} name={'stateGoogle'}/>
                        </div>
                    </div>
                    <div>
                        <button type={"submit"} className={"button w-full bg-themeInformation dark:bg-themeDarkInformation text-white relative"} >
                            {t("insertion")}
                            {IsshowSpinner ? (
                                <TinyLoading/>
                            ) : (
                                null
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )

}