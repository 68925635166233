import React, { useState,useEffect } from 'react';
import HarmonyProgressBar from "../HarmonyProgressBar";

export const ProgressLoading = ({ initialProgress  }) => {
    const [progress, setProgress] = useState(initialProgress); // مقدار اولیه درصد پیشرفت

    useEffect(() => {
        const totalDuration = 0.5 * 60 * 1000; // 10 minutes in milliseconds
        const intervalTime = 1000; // update every second
        const totalSteps = totalDuration / intervalTime;
        const increment = (100 - initialProgress) / totalSteps;

        const interval = setInterval(() => {
            setProgress(prev => {
                const newProgress = prev + increment;
                if (newProgress >= 100) {
                    clearInterval(interval);
                    return 100;
                }
                return newProgress;
            });
        }, intervalTime);

        return () => clearInterval(interval);
    }, [initialProgress]);

    const getProgressStyle = () => {
        const percentage = Math.min((progress / 100) * 100, 100);
        return {
            width: `${percentage}%`,
        };
    };

    const getProgressBarClass = () => {
        return progress < 50 ? 'bg-themeInformation' : 'bg-themeSuccess';
    };

    return (
        <>
            <div className={"huploader_loading"}>
                <div className={" progress-Loading h-3 bg-gray-400 rounded relative"}>
                    <div className={` absolute h-full rounded-full progres-bar-striped  ${getProgressBarClass()}`} style={getProgressStyle()}></div>
                    <span className="absolute text-xs end-1/2 top-1/2 -translate-y-1/2 text-white z-10 font-light">
                {Math.round(progress)}%
            </span>
                </div>
            </div>

        </>
    );
};

