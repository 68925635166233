
import {useTranslation} from "react-i18next";
import {GridDateFomartter} from "../../Grid/GridDateFomartter";
import React from "react";
import {PriceFormatterComponent} from "../../Accounting/PriceFormatterComponent";

export const TransactionItem = ({ transaction,GetCurrencyType,GetTransactionType,GetTransactionIcon }) => {
    const { t } = useTranslation();

    return (

        <div className={"flex h-full post-input dark:bg-themeDarkPostInput post-input-design overflow-hidden"}>
                {GetTransactionIcon(transaction.amount.moneyType)}
                <div className={"flex-1 flex flex-col justify-between border dark:border-themeDarkColorLine"}>
                    <div className={"flex gap-2 py-2 p-1 border-b dark:border-themeDarkColorLine "}>
                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionNumber")}:</p>
                        <span className={"line-clamp-1 text-xs text-themeInformation dark:text-themeDarkInformation"}>{transaction.trackingCode}</span>
                    </div>
                    <div className={"flex gap-2 border-b py-2 p-1 dark:border-themeDarkColorLine"}>
                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionDate")}:</p>
                        <span className={"text-xs dark:text-gray-300"}>{<GridDateFomartter date={transaction.creationDate} hasTime={true}/>}</span>
                    </div>
                    <div className={"flex gap-2 py-2 p-1 border-b dark:border-themeDarkColorLine"}>
                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionAmount")}:</p>
                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}><PriceFormatterComponent price={transaction.amount.amount}/> {GetCurrencyType(transaction.amount.currency)}</span>
                    </div>
                    <div className={" flex gap-2 py-2 p-1"}>
                        <p className={"text-xs dark:text-gray-300"}>{t("TransactionType")}:</p>
                        <span className={"line-clamp-1 text-xs dark:text-gray-300"}>{GetTransactionType(transaction.amount.moneyType)}</span>
                    </div>
                </div>
            </div>

    );
};