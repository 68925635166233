import React, {useEffect, useState} from "react";
import {InputText} from "primereact/inputtext";
import {FantasyModal} from "../../Commans/UIParts/FantasyModal";
import {GridTable} from "../../Commans/Grid/GridTable";
import {ListSearch} from "../../Commans/Grid/ListSearch";
import {ListFilter} from "../../Commans/Grid/ListFilter";
import {useNavigate} from "react-router-dom";
import {Accordion, AccordionTab} from "primereact/accordion";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {ButtonPlus} from "../../Commans/UIParts/ButtonPlus";
import {ButtonSearchList} from "../../Commans/UIParts/ButtonSearchList";
import {useSetting} from "../../Commans/Hooks/useSetting";
import {HandleGeneralSetting} from "../../Public/HarmonySetting/Main/GeneralSettings/HandleGeneralSetting";
import {SettingButton} from "../../Commans/UIParts/SettingButton";
import { SelectButton } from 'primereact/selectbutton';
import {convertSelectDataHandler} from "../../CMS/Contents/ContentService";
import {useCustomCart} from "./useCustomCart";
import {useCartItems} from "./useCartItems";
import {Product} from "./Product";
import {DateTimePicker} from "../../Commans/Dates/DateTimePicker";
import {GET, POST, PUT} from "../../../Services/AxiosService/AxiosApiService";
import {handleApiError} from "../../../Services/Globals/Errors/handleApiError";
import {
    handleCheckProductTransportId,
    handleConvertAddCart,
    handleConvertEditCart,
    handleCreateBill,
    handleGetUserAddress,
    validateQuantity
} from "./CartService";
import {handleFailureMessage, handleSuccessMessage} from "../../../Services/Globals/Errors/NotificationService";
import {ToastContainer} from "react-toastify";
import {useGridCart} from "./useGridCart";
import {InputSwitchListFilter} from "../../Commans/Filters/InputSwitchListFilter";
import {LoadingHarmony} from "../../Commans/Loadings/LoadingHarmony";
import {GridSetting} from "../../../Services/Globals/Public/AppSetting";
import {AgGridEnglish} from "../../../Services/Globals/Translations/Resources";
import {Pagination} from "../../Commans/Grid/Pagination";
import {Export} from "../../Commans/Grid/Export";
import {HandleFastRegister} from "../../CRM/PersonsManagement/UserRegisteration/HandleFastRegister";
import {ToggleSwitch} from "../../Commans/UIParts/ToggleSwitch";
import {GetCurrentDateTime} from "../../../Services/Globals/Public/TimeServiceManager";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import {InputNumber} from "primereact/inputnumber";
import {ProductTransport} from "../../Public/HarmonySetting/ShopManagement/ProductTransports/ProductTransport";
import {productCartSchema} from "./productCartSchema";
import {validate} from "../../../Services/Globals/validate";
import {DisplayError} from "../../Commans/Errors/DisplayError";
import {User} from "../../Commans/Globals/User";
import {RequiredIcon} from "../../Commans/Errors/RequiredIcon";
import {HandleUserDetails} from "../../CRM/PersonsManagement/UserDetails/HandleUserDetails";

export const HandleCartProduct=()=>{
    const redirectTo = useNavigate();
    const { t } = useTranslation();
    const [IsEdit,setIsEdit] = useState(false);
    const [showSpinner,setShowSpinner] = useState(false);
    const [initialSource,setInitialSource] = useState({factorTypes:[],productTypes:[],users:[],cartTypes:[],transactionStates:[],paymentStates:[],sendTypes:[],addresses:[],countries:[],stateProvinces:[],cities:[],productTransports:[],currencies:[]});
    const [IsStatusOpen,setIsStatusOpen] = useState(false);
    const [refresh,setIsRefresh] = useState(false);
    const [errors,setErrors] = useState({});
    const [modal, setModal] = useState({ invoiceAdd:false,fastRegister:false,bill:false,RequestStatus:false });

    const [IsTransportOpen,setIsTransportOpen]=useState(false);
    const [websiteCurrency,setWebsiteCurrency]=useState(0);
    const {setting,handleSetting,handleSettingClose,IsSettingOpen}=useSetting();
    const {cart,setCart,handleValue,handleNumberValue,handleSelectValue,handleCreateDateTime,setProductCartItems,productCartItems,handleChangeSendType,setSendTypes,sendTypes,handleChangeToggle,bill,handleExpireBillDateTime,handleCreationBillDateTime,setBill,handleCheckBox,handleGetCurrencyType} = useCustomCart(setErrors,setInitialSource,initialSource);
    const {handleChange,handleRemoveRow,handleAddRow,handleResetProductCartItems} = useCartItems(initialSource,setProductCartItems,productCartItems);

    // handles spinner in api response
    const onApiError=()=>{
        setShowSpinner(false);
    }
    // handles errors
    const onSetErrors=(errors)=>{
        setErrors(errors);
    }
    //refreshes component when it is called
    const Refresh=()=>{
        setIsRefresh(prevState => !prevState)
    }
    // resets all errors to nothing as initial state
    const handleClearErrors=()=>{
        setErrors({});
    }
    const handleResetCart=()=>{
        setCart((prevState)=>({...prevState,userId: initialSource.users[0],priceType: initialSource.factorTypes[0],creationDate: new Date(),address: '',detailsOfAddress: '',IsAddressSaved: false,}))
    }
    //handle initial data
    const handleInitialData = (
        users, factors, productsTypes, cartTypes, transactionStates,
        responsePayments, responseProductTransport, SendTypes,
        responseCountries, responseCurrencies
    ) => {
        const dataMapping = {
            users: users,
            factorTypes: factors,
            productTypes: productsTypes,
            cartTypes: cartTypes,
            transactionStates: transactionStates,
            paymentStates: responsePayments,
            productTransports: responseProductTransport,
            sendTypes: SendTypes,
            countries: responseCountries,
            currencies: responseCurrencies
        };
        const initialSource = Object.fromEntries(
            Object.entries(dataMapping).map(([key, value]) => [key, convertSelectDataHandler(value)])
        );
        setInitialSource(initialSource);
    };
    // opens invoices
    const handleOpenInvoice = async() => {
        try {
            setShowSpinner(true);
            const userId = initialSource.users[0]
            const initialCountry = initialSource.countries.find((item)=>item.label === 'Iran' || item.label === 'ایران');
            const responseUserAddress = await GET(`/Users/Addresses/Selection`, {userId:userId.value}, 'IEnumerable');
            const responseStates = await GET(`/Countries/${initialCountry.value}/StateProvinces/Selection`, {}, "IEnumerable");
            const initialStateProvince = responseStates.data.find((item)=>item.value === 'تهران');
            const responseCities = await GET(`/Countries/${initialStateProvince.key}/Cities/Selection`, {}, "IEnumerable");

            if(responseUserAddress.status === 200 && responseStates.status === 200 && responseCities.status === 200){
                if(productCartItems.length === 0){
                    handleAddRow();
                }
                const userAddress = handleGetUserAddress(responseUserAddress.data);
                const statesData = convertSelectDataHandler(responseStates.data);
                const initialState = statesData.find((item)=>item.label === 'تهران');
                const citiesData = convertSelectDataHandler(responseCities.data);
                setInitialSource((prevState)=>({...prevState,addresses: userAddress,stateProvinces: statesData,cities: citiesData}));
                setCart({creationDate: new Date(),userId:userId,priceType: initialSource.factorTypes[0],IsAddressSaved: false,countryId: initialCountry,stateProvinceId:initialState,cityId: citiesData[0]})
                setModal((prevState) => ({ ...prevState, invoiceAdd:true }));

                onApiError();
            }
            else{
                handleApiError(responseUserAddress,onApiError);
                handleApiError(responseStates,onApiError);
                handleApiError(responseCities,onApiError);
            }
        }
        catch (e) {
            onApiError();
        }
    };

    //closes invoice
    const handleCloseInvoice=()=>{
        handleResetProductCartItems();
        handleResetCart()
        setIsEdit(false);
        redirectTo('/Shop/Product/Cart');
        handleClearErrors();
        setModal((prevState) => ({ ...prevState, invoiceAdd: false }));
    }

    // opens modals of fastRegister of user
    const handleOpenFastRegister = () => {
        setModal((prevState) => ({ ...prevState, fastRegister: true }));
    };
    // closes modals of fastRegister of user
    const handleCloseFastRegister=()=>{
        setModal((prevState) => ({ ...prevState, fastRegister: false }));
    }
    // closes user fast register
    const handleSuccessUserFastRegister=(isSuccess)=>{
        if(isSuccess){
            handleCloseFastRegister();
            handleResetCart()
            Refresh();
        }
    }


    // opens modals of RequestStatus of user
    const handleOpenRequestStatus = (productCartItemId,sendTypeStatus) => {
        const sendType = initialSource.sendTypes.find((item)=>item.value === sendTypeStatus);
        setSendTypes(prevState => ({...prevState,productCartId:productCartItemId,sendType: sendType}));
        setModal((prevState) => ({ ...prevState, RequestStatus: true }));
    };

    // closes modals of RequestStatus of user
    const handleCloseRequestStatus=()=>{
        setModal((prevState) => ({ ...prevState, RequestStatus: false }));
    }

    //initialize bills when its modal opens
    const handleInitialBill=(productCartId)=>{
        const expirationDate =  GetCurrentDateTime(false,1)
        setBill({productCartId:productCartId,applyOffers: true,applyTax: true,applyDiscount: true,creationDate: new Date(),priceType:null,expirationDate:expirationDate})
    }
    // opens modal of bills
    const handleOpenBill = (productCartId) => {
        setModal((prevState) => ({ ...prevState, bill: true}));
        setValue(1)
        handleInitialBill(productCartId);
    };
    // closes modal of bills
    const handleCloseBill=()=>{
        setModal((prevState) => ({ ...prevState, bill: false}));
    }

    // opens menu operation on grid

    // we want to change sending status of cartItems
    // we open its modal there .
    const handleOpenSendStatus=()=>{
        setIsStatusOpen(true);
    }
    // we close its modal there .
    const handleCloseSendStatus =()=>{
        setIsStatusOpen(false);
    }


    //fetches data from server in first request
    const fetchData = async () => {
        try {
            setShowSpinner(true);
            const [responseUser, responseFactor, responseCartItemTypes, responseProduct, responseTransaction,responsePaymentment, responseProductTransport,responseSendType,responseCountries,responseCurrencies,responseWebsiteCurrency] =
                await Promise.all([
                GET(`/Users/Selection`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnPriceType`, {}, 'IEnumerable'),
                GET(`/Enumerations/EnCartItemType`, {}, 'IEnumerable'),
                GET('/Products/Selection', {}, 'IEnumerable'),
                GET('/Enumerations/EnTransactionState', {}, 'IEnumerable'),
                GET('/Enumerations/EnPaymentStatus', {}, 'IEnumerable'),
                GET('/ProductTransports/Selection', {}, 'IEnumerable'),
                GET('/Enumerations/EnSendType', {}, 'IEnumerable'),
                GET(`/Countries/Selection`, {}, 'IEnumerable'),
                GET('/Enumerations/EnCurrencyType',{},'IEnumerable'),
                GET('/Websites/Currency',{},'single')
            ]);
            if (
                responseUser.status === 200 &&
                responseFactor.status === 200 &&
                responseCartItemTypes.status === 200 &&
                responseProduct.status === 200 &&
                responseTransaction.status === 200 &&
                responsePaymentment.status === 200 &&
                responseProductTransport.status === 200 &&
                responseSendType.status === 200 &&
                responseCountries.status === 200 &&
                responseCurrencies.status === 200
            ) {
                handleInitialData(
                    responseUser.data,
                    responseFactor.data,
                    responseProduct.data,
                    responseCartItemTypes.data,
                    responseTransaction.data,
                    responsePaymentment.data,
                    responseProductTransport.data,
                    responseSendType.data,
                    responseCountries.data,
                    responseCurrencies.data
                );
                setShowSpinner(false);
            } else {
                const responses = [
                    responseUser,
                    responseFactor,
                    responseCartItemTypes,
                    responseProduct,
                    responseTransaction,
                    responsePaymentment,
                    responseProductTransport,
                    responseSendType,
                    responseCountries,
                    responseCurrencies
                ];
                responses.forEach(response => handleApiError(response, onApiError));
            }
        } catch (e) {
            onApiError();
        }
    };

    useEffect(()=>{
        fetchData()
    },[])
    // submits and edits items of product cart
    const handleSubmit=async (e)=>{
        e.preventDefault();
        const isCartValid = validate(productCartSchema,cart,onSetErrors);
        if(!isCartValid){
            return;
        }
        try {
           if(IsEdit)
           {
               const IsProductCartNotValid = handleCheckProductTransportId(productCartItems);
               if(IsProductCartNotValid){
                   handleFailureMessage(t('SelectSendingMethod'));
                   return;
               }
               setShowSpinner(true);
               const cartData = handleConvertEditCart(cart,productCartItems);
               const response = await PUT(`/ProductCarts`,cartData,false);
               if(response.status === 200){
                   handleCloseInvoice();
                   Refresh(response.status);
                   handleSuccessMessage(t("Successfullyregistered"));
               }
               else
               {
                   handleApiError(response,onApiError);
               }
           }
           else{
               const IsProductCartNotValid = handleCheckProductTransportId(productCartItems);
               if(!IsProductCartNotValid){
                   handleFailureMessage(t('SelectSendingMethod'));
                   console.log(IsProductCartNotValid)
                   return;
               }
               setShowSpinner(true)
               const cartData = handleConvertAddCart(cart,productCartItems);
               var isValid = validateQuantity(cartData);
               if(!isValid){
                   setShowSpinner(false)
                   handleFailureMessage(t("NumberProductsCannotBe0"));
                   return;
               }
               const response = await POST(`/ProductCarts`,cartData,false);
               if(response.status === 200){
                   handleCloseInvoice();
                   Refresh();
                   handleSuccessMessage(t("Successfullyregistered"));
               }
               else
               {
                   handleApiError(response,onApiError);
               }
           }
        }
        catch (e) {
            onApiError();
            console.log(e);
        }
    }

    // edits sendType
    const handleEditSendType=async(e)=>{
        e.preventDefault();
        try {
            setShowSpinner(true);
           const response = await PUT(`/ProductCarts/SendType`,{productCartId:sendTypes.productCartId,sendType:sendTypes.sendType.value},false);
           if(response.status === 200){
               handleCloseRequestStatus();
               Refresh();
               handleSuccessMessage(t("Successfullyedited"));
           }
           else{
               handleApiError(response,onApiError);
           }
        }
        catch (e) {
            onApiError();
        }
    }


    // submits bills for product cart items

    const handleSubmitBill=async(e)=>{
        e.preventDefault();
        try {
            setShowSpinner(true);
            if(bill.creationDate === null){
                setShowSpinner(false);
                handleFailureMessage(t("EnterTheBillingDate"));
                return;
            }
            if(bill.expirationDate === null){
                setShowSpinner(false);
                handleFailureMessage(t("EnterTheInvoiceValidityDate"));
                return;
            }
            const bills = handleCreateBill(bill);
            const response = await POST(`/ProductCarts/ConvertToPayment`,bills,false);
            if(response.status === 200){
                  setShowSpinner(false);
                  handleCloseBill();
                  handleSuccessMessage(t("Successfullyedited"));
                  Refresh();
                  redirectTo('/InvoiceRequest')
            }
            else{
                handleApiError(response,onApiError);
            }
        }
        catch (e) {
            onApiError();
        }
    }





    const handleOpenTransport=(productCartIndex)=>{
        setIsTransportOpen(true);
        setCart((prevState)=>({...prevState,index: productCartIndex}));
    }
    const handleCloseTransport=()=>{
        setIsTransportOpen(false);
    }
    const handleCreateSuccessTransport = async (isSuccess) => {
        try {
            if (isSuccess) {
                const response = await GET('/productTransports/Selection', {}, 'IEnumerable');
                if (response.status === 200) {
                    const transports = convertSelectDataHandler(response.data);
                    setInitialSource(prevState => ({ ...prevState, productTransports: transports }));

                    if (transports.length > 0) {
                        setProductCartItems(prevCartItems =>
                            prevCartItems.map((item, i) =>
                                i === cart.index
                                    ? { ...item, productTransportId: transports[0] } // Update the row at the specified index
                                    : item // Keep other rows unchanged
                            )
                        );
                    }
                    handleCloseTransport();
                } else {
                    handleApiError(response, onApiError);
                }
            }
        } catch (e) {
            onApiError();
        }
    };


    const [IsUserOpen,setUserOpen] = useState(false);
    const handleOpenUser=async (userId)=>{
        setUserOpen(true);
        setCart((prevState)=>({...prevState,userId: userId}));
    }
    const handleCloseUser=()=>{
        setUserOpen(false);
    }






    const {handleDeleteProductCartItem,handleGetProductCart,filter,handleSearch,search,setFilter,setSearch,handleNextPage,handleCurrentPage,handlePreviousPage,handlePageSizeCount,handleSelectFilter,handleToggleFilter,handleResetFilters,SwitchFilters,columnDefs,autoGroupColumnDef,defaultColDef,rowData,getRowHeight,pagination} = useGridCart(setShowSpinner,fetchData,refresh,onApiError,initialSource,setCart,setModal,setIsEdit,Refresh,setProductCartItems,handleOpenInvoice,handleOpenBill,handleOpenRequestStatus,handleOpenUser)






    const [value, setValue] = useState(null);
    const items = [
        { name: t("NoRegistration"), value: 1 },
        { name: t("SalesInvoice"), value: 2 },
        { name: t("ProformaInvoice"), value: 3 }
    ];


    return(
        <>
            <div class=" space-y-5 relative">
                {
                    showSpinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <div class="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                    <ListSearch label={null} icon={null} >
                        <div class="space-y-2 ">
                            <label className="text-xs dark:text-gray-300">{t("OperatorName")}</label>
                            <InputText onChange={(e)=>setSearch(e.target.value)} value={search} type="search"  onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className=" input w-full" placeholder={t("search")}  />
                        </div>
                        <ButtonSearchList
                            onClickSearch={()=>handleSearch()}
                            onClickReset={()=>handleResetFilters()}
                        />
                        </ListSearch>
                    <ListFilter label={null} icon={null}
                                childrenOne={
                                    <>
                                        <ButtonPlus
                                            onClick={()=>handleOpenInvoice()}
                                            tooltipTitle={t("ManualShoppingCart")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo('/InvoiceRequest')}
                                            title={t("Bill")}
                                            icon={"pi-file-edit"}
                                            tooltipTitle={t("Bill")}
                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Add")}
                                            icon="pi-pen-to-square"
                                            title={t("product")}
                                            tooltipTitle={t("NewProduct")}
                                        />


                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/MetaData/Add")}
                                            icon="pi-star"
                                            title={t("feature")}
                                            tooltipTitle={t("CreatingAFeature")}
                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Group")}
                                            icon="pi-objects-column"
                                            title={t("grouping")}
                                            tooltipTitle={t("ProductCategorization")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/List")}
                                            icon="pi-list"
                                            title={t("list")}
                                            tooltipTitle={t("ListOfProducts")}

                                        />

                                        <ButtonPlus
                                            onClick={()=>redirectTo("/InvoiceRequest")}
                                            icon="pi-file-edit"
                                            title={t("Bill")}
                                            tooltipTitle={t("Bills")}

                                        />
                                        <ButtonPlus
                                            onClick={()=>redirectTo("/Shop/Product/Cart")}
                                            icon="pi-shopping-bag"
                                            title={t("ShoppingCart")}
                                            tooltipTitle={t("ShoppingCart")}

                                        />



                                    </>
                                }
                                childrenTwo={
                                    <>
                                        <InputSwitchListFilter switchData={SwitchFilters} OnValueSelection={handleToggleFilter} />
                                    </>
                                }
                    />

                </div>
                <div className={"H-body-ag-grid space-y-2"} >

                    <GridTable
                        columnDefs={columnDefs}
                        rowSelection={GridSetting.rowSelection}
                        rowData={rowData}
                        style={GridSetting.gridStyle}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        pagination={GridSetting.pagination}
                        enableRtl={GridSetting.enableRtl}
                        domLayout={GridSetting.domLayout}
                        paginationPageSize={GridSetting.pageSize}
                        getRowHeight={getRowHeight}
                        localeText={AgGridEnglish}
                    />
                    <Pagination length={rowData.length} hasPreviousPage={pagination.hasPreviousPage} hasNextPage={pagination.hasNextPage} locale={null} totalCount={pagination.totalCount} totalPages={pagination.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={pagination.currentPage} handlePageSizeCount={handlePageSizeCount}  />
                </div>
                <Export apiUrl={''} filters={filter}/>
            </div>


            <FantasyModal openModal={modal.invoiceAdd}  closeModal={handleCloseInvoice} title={t("ManualShoppingCart")} customeClassName={"modal-x"}>
                <form onSubmit={handleSubmit}>
                    <div class="space-y-5">
                        <div className={"space-y-3"}>
                            <div class={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3"}>
                                <div class="space-y-2 ">
                                    <div class="flex gap-2 items-center justify-between">
                                        <label className="text-xs dark:text-gray-300">{t("CustomerName")}</label>
                                        <button type={"button"} className="text-themeInformation dark:text-themeDarkInformation text-xs" onClick={()=>handleOpenFastRegister()}>({t("UserRegistration")})</button>
                                    </div>
                                    <Select
                                        options={initialSource.users}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'userId')}
                                        placeholder={t("selection")}
                                        value={cart.userId}
                                        menuPosition={'fixed'}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div class="space-y-1 ">
                                    <label className="text-xs dark:text-gray-300">{t("InvoiceType")}</label>
                                    <Select
                                        options={initialSource.factorTypes}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'priceType')}
                                        placeholder={t("selection")}
                                        value={cart.priceType}
                                        menuPosition={'fixed'}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div class="space-y-2 ">
                                    <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                        <SettingButton
                                            tooltipTitle={t("Calendarsettings")}
                                            onClick={()=>handleSetting('GeneralSetting')}
                                        />
                                        {t("Date")}
                                    </label>
                                    <DateTimePicker handleDateTime={handleCreateDateTime} value={cart.creationDate} format={'YYYY/MM/DD'} />
                                </div>
                            </div>
                            <div className={"space-y-3"}>
                                <div class=" flex-1 w-full space-y-1">
                                    <label className={"text-xs dark:text-gray-300"}>{t("UserAddresses")}</label>
                                    <Select
                                        options={initialSource.addresses}
                                        isMulti={false}
                                        isSearchable={true}
                                        onChange={(data)=>handleSelectValue(data,'userAddressId')}
                                        placeholder={t("selection")}
                                        value={cart.userAddressId}
                                        menuPosition={'fixed'}
                                        classNamePrefix={"h_multi_select"}
                                    />
                                </div>
                                <div className={"space-y-3 post-input rounded-md dark:bg-themeDarkPostInput"}>
                                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2"}>
                                        <div class="space-y-1 ">
                                            <label className="text-xs dark:text-gray-300">
                                                {t("country")}
                                            <RequiredIcon/>
                                            </label>
                                            <Select
                                                options={initialSource.countries}
                                                isMulti={false}
                                                isSearchable={true}
                                                onChange={(data)=>handleSelectValue(data,'countryId')}
                                                placeholder={t("selection")}
                                                value={cart.countryId}
                                                menuPosition={'fixed'}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div class="space-y-1 ">
                                            <label className="text-xs dark:text-gray-300">
                                                {t("province")}
                                            <RequiredIcon/>
                                            </label>
                                            <Select
                                                options={initialSource.stateProvinces}
                                                isMulti={false}
                                                isSearchable={true}
                                                onChange={(data)=>handleSelectValue(data,'stateProvinceId')}
                                                placeholder={t("selection")}
                                                value={cart.stateProvinceId}
                                                menuPosition={'fixed'}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div class="space-y-1 ">
                                            <label className="text-xs dark:text-gray-300">
                                                {t("city")}
                                                <RequiredIcon/>
                                            </label>
                                            <Select
                                                options={initialSource.cities}
                                                isMulti={false}
                                                isSearchable={true}
                                                onChange={(data)=>handleSelectValue(data,'cityId')}
                                                placeholder={t("selection")}
                                                value={cart.cityId}
                                                menuPosition={'fixed'}
                                                classNamePrefix={"h_multi_select"}
                                            />
                                        </div>
                                        <div class="space-y-1">
                                            <label className="text-xs dark:text-gray-300">
                                                {t("postalCode")}
                                                <RequiredIcon/>
                                            </label>
                                            <InputNumber
                                                name={'postalCode'}
                                                value={cart.postalCode}
                                                useGrouping={false}
                                                onChange={(e)=>handleNumberValue(e.value,'postalCode')}
                                            />
                                        </div>
                                    </div>
                                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2"}>
                                        <div className={"space-y-1 lg:col-span-3"}>
                                            <div className={"flex justify-between items-center gap-2"}>
                                                <label className={"text-xs dark:text-gray-300"}>
                                                    {t("NewAddress")}
                                                    <RequiredIcon/>
                                                </label>
                                                <DisplayError message={errors.address}/>
                                            </div>
                                            <InputTextarea
                                                className={"input w-full text-xs"}
                                                value={cart.address}
                                                name={'address'}
                                                onInput={(e)=>handleValue(e)}
                                                rows={5}
                                            />

                                        </div>
                                        <div className={"space-y-1 lg:col-span-1"}>
                                            <label className={"text-xs dark:text-gray-300"}>{t("description")}</label>
                                            <InputTextarea className={"input w-full text-xs"}
                                                           value={cart.detailsOfAddress}
                                                           name={'detailsOfAddress'}
                                                           onInput={(e)=>handleValue(e)}
                                                           rows={5}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"space-y-3"}>
                                <div className="p-2 bg-themeLightGray dark:bg-themeDarkPostInput post-input-design-x border-themeInformation dark:border-themeDarkInformation">
                                    <label name={"IsAddressSaved"} className={"flex items-center gap-2 cursor-pointer w-max"}>
                                        <input type={"checkbox"}  checked={cart.IsAddressSaved} name={'IsAddressSaved'} onChange={(e)=>handleCheckBox(e.target.checked,'IsAddressSaved')} />
                                        <p className={"text-xs dark:text-gray-300"}>{t("SaveAddressTitle")}</p>
                                    </label>
                                </div>
                                <Accordion className={"bg-white dark:bg-themeDarkSalt700"}>
                                    <AccordionTab header={<div>{t("Products")}</div>}>
                                        <div className={"space-y-5"}>
                                            {
                                                productCartItems.length > 0
                                                    ? productCartItems.map((item,index) => (
                                                        <Product
                                                            index={index} initialSource={initialSource} handleAddRow={handleAddRow}
                                                            handleRemoveRow={handleRemoveRow}
                                                            handleChange={handleChange}
                                                            item={item}
                                                            handleOpenTransport={handleOpenTransport}
                                                        />
                                                    ))
                                                    : null
                                            }
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div>

                        </div>
                        <button type={"submit"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("register")}</button>
                    </div>

                </form>
            </FantasyModal>

            <FantasyModal openModal={modal.bill}  closeModal={handleCloseBill} title={t("Billcreation")} >
             <form onSubmit={handleSubmitBill} method={'post'}>
                <div class="space-y-5">
                    <div className={"flex items-center gap-3"}>
                        <div class="space-y-1 ">
                        <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                            <SettingButton
                                tooltipTitle={t("Calendarsettings")}
                                onClick={()=>handleSetting('GeneralSetting')}
                            />
                            {t("BillingDate")}
                        </label>
                        <DateTimePicker handleDateTime={handleCreationBillDateTime} value={bill.creationDate} format={'YYYY/MM/DD'} />
                    </div>
                        <div class="space-y-1 ">
                            <label className="text-xs dark:text-gray-300 flex gap-1 items-center">
                                <SettingButton
                                    tooltipTitle={t("Calendarsettings")}
                                    onClick={()=>handleSetting('GeneralSetting')}
                                />
                                {t("InvoiceValidityDate")}
                            </label>
                            <DateTimePicker handleDateTime={handleExpireBillDateTime} value={bill.expirationDate} format={'YYYY/MM/DD'} />
                        </div>
                    </div>
                    <div className={"flex flex-wrap items-center justify-around gap-3"}>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("DiscountCampaign")}</label>
                            <ToggleSwitch tag={'campagin'} customClass={"w-28"} value={bill.applyDiscount} handleToggleSwitchValue={handleChangeToggle} name={'applyDiscount'} checked={t("Active")} unchecked={t("Inactive")} />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Tax")}</label>
                            <ToggleSwitch tag={'tax'} customClass={"w-28"} value={bill.applyTax} name={'applyTax'} handleToggleSwitchValue={handleChangeToggle}  checked={t("Active")} unchecked={t("Inactive")} />
                        </div>
                        <div className="space-y-1">
                            <label className="text-xs dark:text-gray-300">{t("Praise")}</label>
                            <ToggleSwitch tag={'Praise'} customClass={"w-28"} value={bill.applyOffers} handleToggleSwitchValue={handleChangeToggle} name={'applyOffers'} checked={t("Active")} unchecked={t("Inactive")} />
                        </div>
                    </div>
                    <div class={"flex items-center justify-center gap-3"}>
                        <div className={"md:col-span-2"}>
                            <div className=" flex justify-content-center text-xs">
                                <SelectButton value={value} onChange={(e) => setValue(e.value)} optionLabel="name" options={items}  />
                            </div>
                        </div>
                    </div>
                    <button type={"submit"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("Billcreation")}</button>
                </div>
            </form>
            </FantasyModal>


            <FantasyModal openModal={modal.RequestStatus}  closeModal={handleCloseRequestStatus} title={t("Switching")}>
                <form method={'post'} onSubmit={handleEditSendType} className={"space-y-5"}>
                    <div>
                        <Select
                            options={initialSource.sendTypes}
                            isMulti={false}
                            isSearchable={true}
                            onChange={(data)=>handleChangeSendType(data,'sendType')}
                            placeholder={t("selection")}
                            value={sendTypes.sendType}
                            menuPosition={"fixed"}
                            classNamePrefix={"h_multi_select"}
                        />
                    </div>
                    <button type={"submit"} className="button w-full bg-themeInformation text-white dark:bg-themeDarkInformation">{t("register")}</button>
                </form>
            </FantasyModal>

            <FantasyModal  openModal={modal.fastRegister}  closeModal={handleCloseFastRegister} title={t("UserRegistration")} customeClassName={"modal-xl"}>
                <>
                    {
                        modal.fastRegister?
                            <HandleFastRegister
                                IsDefault={false}
                                handleSuccess={handleSuccessUserFastRegister}
                                IsOpen={modal.fastRegister}

                            />
                            :null
                    }
                </>
            </FantasyModal>

            <FantasyModal  openModal={IsSettingOpen}  closeModal={handleSettingClose} customeClassName={"modal-settings"} title={t("Fastsettings")} >
                <div>
                    {
                        setting === 'GeneralSetting'?
                            <HandleGeneralSetting/>:null
                    }

                </div>
            </FantasyModal>


            <FantasyModal openModal={IsTransportOpen} closeModal={handleCloseTransport} title={t("SendingMethod")}>
                <ProductTransport
                    IsOpen={IsTransportOpen}
                    handleSuccess={handleCreateSuccessTransport}
                    handleGetCurrencyType={handleGetCurrencyType}
                    currencies={initialSource.currencies}
                    websiteCurrency={websiteCurrency}
                />
            </FantasyModal>

            <FantasyModal openModal={IsUserOpen} closeModal={handleCloseUser} title={t("AdditionalInformation")} customeClassName={"modal-xl"}>
                {
                    IsUserOpen?
                        <HandleUserDetails IsOpen={IsUserOpen} searchType={0} searchValue={cart.userId} />
                        :
                        null
                }
            </FantasyModal>

            <ToastContainer/>
        </>

    )
}