import React from "react";
import {InputTextarea} from "primereact/inputtextarea";
import {SettingButton} from "../../../../../Commans/UIParts/SettingButton";
import {TinyEditor} from "../../../../../Commans/UIParts/TinyEditor";
import {useTranslation} from "react-i18next";

export const Content=({handleSetting,handleValue,handleSelectValue,productGroupDetail,settingId})=>{

    const { t } = useTranslation();

    return (
        <>

            {/*<div className={`tab-content__pane space-y-5 ${ tabId === "content" ? "active" : "" }`} id="content">*/}
                <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                        <label className="dark:text-gray-300 text-xs">
                            {t("TextSummary")}
                        </label>
                        <InputTextarea onInput={(e)=>handleValue(e)} name={"excerpt"} value={productGroupDetail.excerpt}  rows={3} cols={30} className={"input w-full border "}/>
                    </div>
                <div className="post-input dark:bg-themeDarkPostInput space-y-1 post-input-design p-2">
                    <label className="dark:text-gray-300 text-xs flex gap-1 items-center">
                        <SettingButton
                            onClick={()=>handleSetting('GeneralSetting')}
                            tooltipTitle={t("EditorType")}
                        />
                        {t("FullText")}
                    </label>
                        <div className={"custom-editor-class"}>
                            <TinyEditor content={productGroupDetail.editor} name={'editor'} handleEditorContent={handleSelectValue}/>
                        </div>

                    </div>
            {/*</div>*/}

        </>
    )
}