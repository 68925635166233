import React, {useEffect} from "react";
import {useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDefaultDashboardWidgets} from "../../../Dashboard/DefaultDashboards/useDefaultDashboardWidgets";
import {useDashboard} from "../../../Dashboard/useDashboard";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
import {ChartHarmony} from "../../../Dashboard/ChartHarmony";
import {UserNote} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserNote";
import {UserComment} from "../../../Dashboard/DefaultDashboards/DefaultWidgets/UserComment";


export const HandleDashboardHRMManagement=()=>{
    const { t } = useTranslation();
    const redirectTo = useNavigate();
    const [IsLoading,setIsLoading] = useState(false);
    const [initialSource,setInitialSource]=useState({currencies:[]});

    const {handleGetWidgets,GetCurrencyType,dashboard,setDashboard,chartData} = useDefaultDashboardWidgets(initialSource,setInitialSource,setIsLoading);

    const {handleToggleDashboard,classesToggled}=useDashboard();


    const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '5%',
            left: 'center',
        },
            textStyle: {
                fontFamily: 'shabnam', // یا فونت دلخواه شما
            },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,

                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',  // رنگ حاشیه سفید
                    borderWidth: 2  // عرض حاشیه
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 20,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    { value: 500, name: 'دارای قرارداد' ,itemStyle: { color: 'green' } },
                    { value: 150, name: 'بدون قرارداد' ,itemStyle: { color: 'red' } },
                    { value: 200, name: 'درحال تمام' ,itemStyle: { color: 'orange' } },
                ]
            }
        ]
    };
    const personal = {

        tooltip: {
            trigger: 'item'
        },
        textStyle: {
            fontFamily: 'shabnam', // یا فونت دلخواه شما
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: '55%',

                data: [
                    { value: 200, name: 'کارمندان خانم' },
                    { value: 200, name: 'کارمندان آقا' },
                    { value: 10, name: 'پرونده ناقص' },
                ],

            }

        ]
    };
    const salary = {

        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    120,
                    {
                        value: 200,
                        itemStyle: {
                            color: '#a90000'
                        }
                    },
                    150,
                    80,
                    70,
                    110,
                    130
                ],
                type: 'bar'
            }
        ]
    };
    const Efficiency = {
        legend: {
            top: 'bottom',
        },
        tooltip: {
            trigger: 'item'
        },
        series: [
            {
                name: 'Nightingale Chart',
                type: 'pie',
                radius: [50, 150],
                center: ['50%', '50%'],
                roseType: 'area',
                itemStyle: {
                    borderRadius: 8,
                    borderColor: '#fff',  // رنگ حاشیه سفید
                    borderWidth: 2  // عرض حاشیه
                },
                data: [
                    { value: 40, name: 'rose 1' },
                    { value: 38, name: 'rose 2' },
                    { value: 32, name: 'rose 3' },
                    { value: 30, name: 'rose 4' },
                    { value: 28, name: 'rose 5' },
                    { value: 26, name: 'rose 6' },
                    { value: 22, name: 'rose 7' },
                    { value: 18, name: 'rose 8' }
                ]
            }
        ]
    };

    const productsData = [
        { name: 'محصول 1', value: 150 },
        { name: 'محصول 2', value: 250 },
        { name: 'محصول 3', value: 100 },
        { name: 'محصول 4', value: 75 },
        { name: 'محصول 5', value: 200 },
        { name: 'محصول 6', value: 150 },
        { name: 'محصول 7', value: 500 },
        { name: 'محصول 8', value: 40 },
        { name: 'محصول 9', value: 80 },
        { name: 'محصول 10', value: 30 },
        { name: 'محصول 11', value: 80 },
        { name: 'محصول 12', value: 20 },
        { name: 'محصول 13', value: 90 },
        { name: 'محصول 14', value: 10 },
        { name: 'محصول 15', value: 20 },
        { name: 'محصول 16', value: 40 },
        { name: 'محصول 17', value: 80 },
        { name: 'محصول 18', value: 90 },
        { name: 'محصول 19', value: 500 },
        { name: 'محصول 20', value: 600 },
    ];
    const sale = {

        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 10,
            top: 20,
            bottom: 20,
            data: productsData.map(product => product.name),
            textStyle: {
                fontFamily: 'shabnam', // یا فونت دلخواه شما
            }
        },
        emphasis: {
            label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold'
            }
        },
        series: [

            {
                type: 'pie',
                radius: '60%',
                center: ['40%', '40%'],
                data: productsData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                    }
                }
            }
        ]
    };


    const colors = ['#5470C6', '#EE6666'];
    const compareSales = {

        emphasis: {
            label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold'
            }
        },
        color: colors,
        tooltip: {
            trigger: 'none',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {},
        grid: {
            top: 70,
            bottom: 50
        },
        xAxis: [
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        color: colors[1]
                    }
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return (
                                'Precipitation  ' +
                                params.value +
                                (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                            );
                        }
                    }
                },
                // prettier-ignore
                data: ['2016-1', '2016-2', '2016-3', '2016-4', '2016-5', '2016-6', '2016-7', '2016-8', '2016-9', '2016-10', '2016-11', '2016-12']
            },
            {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                axisLine: {
                    onZero: false,
                    lineStyle: {
                        color: colors[0]
                    }
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return (
                                'Precipitation  ' +
                                params.value +
                                (params.seriesData.length ? '：' + params.seriesData[0].data : '')
                            );
                        }
                    }
                },
                // prettier-ignore
                data: ['2015-1', '2015-2', '2015-3', '2015-4', '2015-5', '2015-6', '2015-7', '2015-8', '2015-9', '2015-10', '2015-11', '2015-12']
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: 'Precipitation(2015)',
                type: 'line',
                xAxisIndex: 1,
                smooth: true,
                emphasis: {
                    focus: 'series'
                },
                data: [
                    2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                ]
            },
            {
                name: 'Precipitation(2016)',
                type: 'line',
                smooth: true,
                emphasis: {
                    focus: 'series'
                },
                data: [
                    3.9, 5.9, 11.1, 18.7, 48.3, 69.2, 231.6, 46.6, 55.4, 18.4, 10.3, 0.7
                ]
            }
        ]
    };

    const monthlySalesData = {
        months: ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'],
        products: {
            'محصول 1': [120, 132, 101, 134, 90, 230, 210, 180, 150, 200, 240, 260],
            'محصول 2': [220, 182, 191, 234, 290, 330, 310, 260, 230, 280, 300, 320],
            'محصول 3': [150, 232, 201, 154, 190, 330, 410, 380, 350, 400, 420, 450],
            'محصول 4': [200, 220, 205, 214, 210, 340, 510, 680, 350, 400, 420, 450],
            'محصول 5': [250, 282, 291, 264, 290, 430, 510, 480, 450, 500, 520, 550],
            'محصول 6': [180, 192, 221, 214, 240, 380, 450, 420, 390, 410, 430, 460],
            'محصول 7': [300, 312, 301, 314, 310, 440, 510, 580, 550, 600, 620, 650],
            'محصول 8': [280, 282, 271, 284, 280, 420, 490, 580, 550, 600, 620, 650],
            'محصول 9': [210, 222, 231, 224, 220, 360, 430, 500, 470, 500, 520, 550],
            'محصول 10': [320, 332, 321, 334, 330, 460, 530, 600, 570, 600, 620, 650],
        }
    };

    const monthlySales = {

        tooltip: {
            trigger: 'axis'
        },

        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: monthlySalesData.months
        },
        yAxis: {
            type: 'value'
        },
        series: Object.keys(monthlySalesData.products).map(product => ({
            name: product,
            type: 'line',
            data: monthlySalesData.products[product]
        }))
    };





    const brandSales = {

        tooltip: {
            trigger: 'axis'
        },
        legend: {
            data: ['Rainfall', 'Evaporation'],

                type: 'scroll',
                orient: 'horizontal',
                right: 10,
                top: 20,
                left: 20,
                textStyle: {
                    fontFamily: 'shabnam' // یا فونت دلخواه شما
                }

        },
        calculable: true,
        xAxis: [
            {
                type: 'category',
                data: ['فروردین', 'اردیبهشت', 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند']
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: 'Rainfall',
                type: 'bar',
                data: [
                    2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
                ],
                markPoint: {
                    data: [
                        { type: 'max', name: 'Max' },
                        { type: 'min', name: 'Min' }
                    ]
                },
                markLine: {
                    data: [{ type: 'average', name: 'Avg' }]
                }
            },
            {
                name: 'Evaporation',
                type: 'bar',
                data: [
                    2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
                ],
                markPoint: {
                    data: [
                        { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
                        { name: 'Min', value: 2.3, xAxis: 11, yAxis: 3 }
                    ]
                },
                markLine: {
                    data: [{ type: 'average', name: 'Avg' }]
                }
            }
        ]
    };


    const returnedItems = {
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    120,
                    {
                        value: 200,
                        itemStyle: {
                            color: '#a90000'
                        }
                    },
                    150,
                    80,
                    70,
                    110,
                    130
                ],
                type: 'bar'
            }
        ]
    };




    return (

        <div className={"relative min-h-screen"}>
            <div className="spacey-5 grid grid-cols-1 lg:grid-cols-12 gap-6 relative">
                <button type={"button"} className={"dashboard-side-hide hidden lg:block"} onClick={handleToggleDashboard}>
                    <span className={"size-7 grid place-content-center bg-themeInformation dark:bg-themeDarkSalt700 text-white"}>
                        <HPrimeIcon
                            icon={` ${classesToggled ? 'pi-angle-double-right':'pi-angle-double-left'}`}
                        />
                    </span>
                </button>
                <div className={`dashboard-content-side relative ${classesToggled ? 'lg:col-span-12' : ''}`}>
                    <div className="space-y-10">
                        <div>
                            <div className={`flex justify-between items-center ${classesToggled ? 'lg:me-7' : ''}`}>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    {t("GeneralReport")}
                                </h2>
                                <Link to="#" className="flex items-center gap-2 text-themeInformation -intro-x dark:text-themeDarkInformation">
                                    <HPrimeIcon
                                        icon={"pi-sync"}
                                    />
                                    {t("Reload")}
                                </Link>
                            </div>
                            <div className={`grid gap-x-2 gap-y-10 py-3 grid-cols-2  lg:grid-cols-4 ${classesToggled ? 'lg:grid-cols-4' : ''}`}>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Personnel")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Agent")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>
                                                            10
                                                        </p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div>
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Seller")}
                                                    </h2>
                                                </div>
                                                    <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Supplier")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Marketer")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("ApplicationEmployment")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        متقاضیان کار
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className={"-intro-x"}>
                                    <div className="report-box zoom-in ">
                                        <Link  className="box bg-white p-5 dark:bg-themeDarkSalt700 block" to={"#"}>
                                            <div className={"space-y-2"}>
                                                <div >
                                                    <h2 className={"dark:text-gray-300 text-base text-center"}>
                                                        {t("Drivers")}
                                                    </h2>
                                                </div>
                                                    <div>
                                                        <p className={"text-themeInformation dark:text-themeInformation text-xl text-center"}>10</p>
                                                    </div>
                                                </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"grid grid-cols-1 gap-5 md:grid-cols-2 "}>
                            <div >
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>{t("TheStatusOfContracts")}</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={"box bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={option}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px',fontFamily:'inherit' }} />
                                    </div>
                                </div>
                            </div>
                            <div >
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>پرسنل زن و مرد</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={" bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={personal}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px',fontFamily:'inherit' }} />
                                    </div>
                                </div>
                            </div>
                            <div >
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>متوسط حقوق</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={" bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={salary}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px', }} />
                                    </div>
                                </div>
                            </div>
                            <div >
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>متوسط راندمان</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={" bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={Efficiency}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px', }} />
                                    </div>
                                </div>
                            </div>
                            <div >
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>فروش برمبنای محصول</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={" bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={sale}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px', }} />
                                    </div>
                                </div>
                            </div>
                            <div >
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>مقایسه فروش براساس گزشته</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={" bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={compareSales}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px', }} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>فروش ماهیانه با تفکیک محصول</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={" bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={monthlySales}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px', }} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>فروش براساس گروه (برند)</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={" bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={brandSales}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px', }} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-lg dark:text-gray-300 -intro-x">
                                    <h2>کالاهای مرجوعی</h2>
                                </h2>
                                <div className={"py-3"}>
                                    <div className={" bg-white dark:bg-themeDarkSalt700 p-2"}>
                                        <ChartHarmony options={returnedItems}  apiUrl={''} className={'chartHarmony'}  style={{ width: '100%', height: '400px', }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`lg:col-span-3 dashboard-left-side space-y-2 hidden lg:block ${classesToggled ? 'hhidden' : ''}`}>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("Notes")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            {
                                dashboard && dashboard.userNotes.length > 0 && dashboard.userNotes.map((item,index) => (
                                    <UserNote
                                        key={index}
                                        fullName={item.fullName}
                                        id={item.id}
                                        message={item.message}
                                        createdAt={item.createdAt}
                                    />
                                ))
                            }
                            <Link to="/CRM/User/Reminder/List" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2 className="text-lg dark:text-gray-300">
                                {t("UserComments")}
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 dashboard-note height_21rem overflow-y-auto scrollbar-hidden "}>
                                {
                                    dashboard && dashboard.userComments.length > 0 && dashboard.userComments.map((item, index) => (
                                        <UserComment
                                            key={index}
                                            fullName={item.fullName}
                                            id={item.id}
                                            message={item.message}
                                            createdAt={item.createdAt}
                                            state={item.state}
                                        />
                                    ))
                                }
                            </div>
                            <Link to="/CMS/Comment/Inbox" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("viewAll")}</Link>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center ">
                            <h2 className="text-lg dark:text-gray-300">
                                {t("ReleaseQueue")}
                                <span className={"text-xs dark:text-themeDarkInformation"}>({t("digitalMarketing")})</span>
                            </h2>
                        </div>
                        <div className={"space-y-3 py-3"}>
                            <div className={"space-y-3 height_21rem overflow-y-auto scrollbar-hidden"}>
                                <Link to={"#"} class="box bg-white p-2 flex items-center gap-2 dark:bg-themeDarkSalt700 -intro-x">
                                    <div class="size-12 flex-shrink-0  post-input dark:bg-themeDarkPostInput post-input-design  rounded-full">
                                        <img alt="harmony" className="size-full object-cover" src="/Assets/Image/Avatar/man.svg"/>
                                    </div>
                                    <div class="flex-1">
                                        <div className="line-clamp-1 dark:text-gray-300">Leonardo DiCaprio</div>
                                        <div class="text-gray-500 text-xs dark:text-gray-300 font-light">6 August 2022</div>
                                    </div>
                                </Link>
                            </div>
                            <Link to="/SocialMarketing/Draft" className="w-full block text-center rounded-md py-3 border border-dashed border-themeInformation  dark:text-gray-300 dark:border-themeDarkInformation ">{t("Draft")}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}