import React, {useEffect, useMemo, useState} from "react";
import {InputText} from "primereact/inputtext";
import {GridTable} from "../../../Commans/Grid/GridTable";
import queryString from "query-string";
import {handleSuccessMessage} from "../../../../Services/Globals/Errors/NotificationService";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import {Pagination} from "../../../Commans/Grid/Pagination";
import {LoadingHarmony} from "../../../Commans/Loadings/LoadingHarmony";
import {ToastContainer} from "react-toastify";
import Select from "react-select";
import {useTranslation} from "react-i18next";
import {InputSwitch} from "../../../Commans/Filters/InputSwitch";
import {ListSearch} from "../../../Commans/Grid/ListSearch";
import {ListFilter} from "../../../Commans/Grid/ListFilter";
import {GridDateFomartter} from "../../../Commans/Grid/GridDateFomartter";
import {Return} from "../../../Commans/Globals/Return";
import {AlertSetting, GridSetting} from "../../../../Services/Globals/Public/AppSetting";
import {AgGridPersion} from "../../../../Services/Globals/Translations/Resources";
import {Export} from "../../../Commans/Grid/Export";
import {ButtonPlus} from "../../../Commans/UIParts/ButtonPlus";
import {ButtonVideo} from "../../../Commans/UIParts/ButtonVideo";
import {handleApiError} from "../../../../Services/Globals/Errors/handleApiError";
import {DELETE, GET, PUT} from "../../../../Services/AxiosService/AxiosApiService";
import {convertSelectDataHandler} from "../../Contents/ContentService";
import {TinyLoading} from "../../../Commans/Loadings/TinyLoading";
import {Image} from "primereact/image";
import {ButtonSearchList} from "../../../Commans/UIParts/ButtonSearchList";
import {GridButtonEdit} from "../../../Commans/Grid/GridButtonEdit";
import {GridButtonDelete} from "../../../Commans/Grid/GridButtonDelete";
import {HPrimeIcon} from "../../../Commans/UIParts/HPrimeIcon";
export const HandleListAdvertise =()=>{
    const { t } = useTranslation();


    const onApiError = () => {
        setSpinner(false);
    };

    const [wait,setWait] = useState(false);
    const [headerName,setHeaderName] = useState("");
    const [colId,setColdId] = useState(null);

    const [isModalOpen,setModalOpen] = useState(false);
    const handleCloseModal=()=>{
        setModalOpen(false);
    }

    const [spinner,setSpinner] = useState(false);

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search);
        if (queryParams.isSuccess === "true") {
            handleSuccessMessage(t("Advertisementshavebeensuccessfullyadded"));
        }
        if(queryParams.isSuccessEdit === "true"){
            handleSuccessMessage(t("Advertisementshavebeensuccessfullyedited"));
        }
    }, [window.location.search]);


    const [result,setResult] = useState(0);


    const redirectTo = useNavigate();





    // روی دکمه حذف از لیست که کزذ یک مدال باز میشه و اگه تایید کنه api حذف تکی رو صداش میزنه
    const removeAdvertise = async (advertiseId)=>{
        const TailWindButton = Swal.mixin({
            customClass: {
                confirmButton: AlertSetting.customClass.confirmButton,
                cancelButton: AlertSetting.customClass.cancelButton
            },
            buttonsStyling: false
        })
        TailWindButton.fire({
            title:AlertSetting.TailWindButton.title,
            text: AlertSetting.TailWindButton.text,
            icon:AlertSetting.TailWindButton.icon,
            showCancelButton:AlertSetting.TailWindButton.showCancelButton,
            confirmButtonText: AlertSetting.TailWindButton.confirmButtonText,
            cancelButtonText: AlertSetting.TailWindButton.cancelButtonText,
            reverseButtons: AlertSetting.TailWindButton.reverseButtons
        }).then( async (result) =>{
            if (result.isConfirmed) {
                try {

                    setSpinner(true)
                    const response = await DELETE(`/Advertisings/${advertiseId}`);
                    if (response.status === 200) {
                        setSpinner(false)
                        setResult({...response.status});
                    }
                    else {
                        handleApiError(response,onApiError)
                    }
                }catch (e) {
                    console.log(e);
                }
            }
            else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                return;
            }
        })
    }

    const handleAdvertiseActivation = async(advertiseId,rowName)=>{
        try {
            setWait(true);
            setHeaderName(rowName);
            setColdId(advertiseId);
            const response = await PUT(`/Advertisings/EditAdvertisingActivation/${advertiseId}`,{},false);
            if (response.status === 200) {
                setWait(false)
                setResult({...response.status})
            }
            else{
                handleApiError(response,onApiError)
            }
        }catch (e) {
            onApiError()
        }
    }

    const columnDefs = [
        { headerName: t("Identifier"), field: 'id',filter:'agTextColumnFilter',hide:true},
        { headerName: t("row"), field: 'idSequence',filter:'agTextColumnFilter',headerCheckboxSelection: true, checkboxSelection: true, maxWidth: 120},
        { headerName: t("Image"),field: 'coverImage', minWidth: 140,
            cellRenderer:params => {
                return params.data.coverImage !== null?
                    <Image src={params.data.coverImage} zoomSrc={params.data.coverImage} preview />:
                    <HPrimeIcon icon={'pi-times'} className={"text-themeDanger"} />
            }
        },
        { headerName: t("Title"), minWidth: 140,field:'title'},
        { headerName: t("WebsiteName"),minWidth: 140,field: 'slug',},
        { headerName: t("city"), minWidth: 140,field: 'cityName',},



        { headerName: t("ExpirationDate"), minWidth: 140,field: 'expirationDate',cellRenderer:params => {
            return <GridDateFomartter date={params.data.expirationDate} hasTime={false} />
        }},


        { headerName: t("Status"),field: 'stateShow',minWidth: 115, cellRenderer:params=>

                <div className={"relative"}>
                    {wait && headerName === params.colDef.headerName && colId === params.data.id ? (
                        <TinyLoading />
                    ) : params.data.stateShow ? (
                        <HPrimeIcon
                            onClick={async () => await handleAdvertiseActivation(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeSuccess'}
                            icon={"pi-check-square"}
                        />
                    ) : (
                        <HPrimeIcon
                            onClick={() => handleAdvertiseActivation(params.data.id,params.colDef.headerName)}
                            iconClass={'cursor-pointer text-themeDanger'}
                            icon={"pi-times-circle"}
                        />
                    )}
                </div>
        },

        { headerName: t("VisitCount"), minWidth: 140,field: 'visitCount',},

        { headerName: t("operations"), maxWidth: 130,pinned: "left",lockPinned: true,cellClass: "lock-pinned",
            cellRenderer:params => {
                return<div className={'flex justify-center items-center gap-3'} >
                    <GridButtonEdit
                        onClick={()=>redirectTo(`/CMS/Advertise/Add?id=${params.data.id}`)}
                    />
                    <GridButtonDelete
                        onClick={()=>removeAdvertise(params.data.id)}
                    />

                </div>
            }
        },

    ];





    const autoGroupColumnDef = useMemo(() => {
        return {
            headerCheckboxSelection: true,
            cellRendererParams: {
                checkbox: true,
            },
        };
    }, []);



    const getRowHeight = (params) => {
        // Specify your custom logic to determine the row height based on row data or other conditions
        return 45; // Return the desired row height in pixels
    };


    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 90,
            resizable: true,
            // editable: true,
            filter: true,

        };
    }, []);



    const [data,setData] = useState({totalPages:0,page:0,pageSize:GridSetting.pageSize,totalCount:0,hasNextPage:false,hasPreviousPage:false,lastPage:0})
    const [currentPage, setCurrentPage] = useState(1);
    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleCurrentPage=(page)=>{
        setCurrentPage(page);
    }
    const handlePageSizeCount = (value)=>{
        setCurrentPage(1);

        setData(()=>({...data,pageSize: value}))
    }

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };
    const [filter,setFilter] = useState({search:'',sponsorId:null,isExpired:null,stateShow:null,IsActive:null,IsDeActive:null});
    const [search,setSearch]= useState('');
    const handleSearch=()=>{
        setFilter((prevState)=>({...prevState,search:search}));
    }

    const filterValueHandler= (data,name)=>{
        if(data === false){
            setFilter(({...filter,[name]: null}));
        }
        else{
            setFilter(({...filter,[name]: data}));
        }
    }
    const handleSelectValue = (data,name)=>{
        if(!data){
            return;
        }
        console.log(data.value);
        setFilter(({[name]: data.value}));
    }


    const resetFilters = ()=>{
        setFilter(({...filter,search:'',stateShow: null,isExpired: null,sponsorId: null,IsActive: null,IsDeActive: null}));
        setSearch('');
    }
    const [options,setOptions] = useState({sponsors:[]})
    const handleFetchData = async()=>{
        try {
            const responseSponsor= await GET('/Users/Selection',{},'IEnumerable');
            if(responseSponsor.status === 200){
                const sponsors = convertSelectDataHandler(responseSponsor.data);
                setOptions({sponsors:sponsors})
            }else{
                handleApiError(responseSponsor,onApiError)
            }
        }
        catch (e) {
            console.log(e)

        }
    }





    useEffect(() => {
        const loadData = async () => {
            try {
                setSpinner(true);
                const response = await OnGridReady();
                if (response && response.items !== null ) {
                    handleFetchData(); // Remove await if handleFetchData is not asynchronous
                } else {
                    handleApiError(response, onApiError);
                }
            } catch (error) {
                console.error("Error in loadData:", error);
            } finally {
                setSpinner(false);
            }
        };
        loadData();
    }, [result, currentPage, filter.search, data.pageSize,filter.stateShow, filter.isExpired,filter.IsActive,filter.IsDeActive]);

    const [rowData, setRowData] = useState([]);
    const OnGridReady = async () => {
        try {
            setSpinner(true);
            const response =  await GET(`/Advertisings/GetAdvertisingsList`, {Search:search,PageSize:data.pageSize,PageNumber:currentPage,StateShow:filter.stateShow,IsExpired:filter.isExpired,SponsorId:filter.sponsorId,IsActive:filter.IsActive,IsDeActive:filter.IsDeActive}, 'PagedList');
            if (response && response.items !== null ) {
                setData({...data, totalPages: response.totalPages, hasNextPage: response.hasNextPage, hasPreviousPage: response.hasPreviousPage, page: response.page, pageSize: response.pageSize, totalCount: response.totalCount});
                var idSequence = 1;
                const modifiedItems = response.items.map((item) => ({
                    ...item,
                    idSequence: ((currentPage - 1) * data.pageSize) + idSequence++
                }));
                setRowData(modifiedItems);
            }
            else {
                handleApiError(response,onApiError);
            }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            setSpinner(false);
        }
    };


    return (
        <>
            <div class="space-y-5">
                <div className="box bg-white dark:bg-themeDarkSalt700 p-3 space-y-5">
                <ListSearch label={null} icon={null} >
                        <div class="space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("SearchByNameAdvertise")}</label>
                            <div >
                                <InputText value={search} onChange={(e)=>setSearch(e.target.value)} type="search" onKeyDown={(e)=>e.key ==='Enter'?setFilter(()=>({...filter,search: e.target.value})):null}  className="input w-full" placeholder={t("search")}  />
                            </div>
                        </div>
                        <div class=" space-y-2">
                            <label className="text-xs dark:text-gray-300">{t("Sponsor")}</label>
                            <Select
                                options={options.sponsors}
                                isClearable={true}
                                isSearchable={true}
                                onChange={(data)=>handleSelectValue(data,'sponsorId')}
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                            />
                        </div>
                    <ButtonSearchList
                        onClickSearch={()=>handleSearch()}
                        onClickReset={()=>resetFilters()}
                    />
                    </ListSearch>
                <ListFilter label={null} icon={null}
                            childrenOne={
                                <>
                                    <ButtonPlus
                                        onClick={()=>redirectTo(`/CMS/Advertise/Add`)}
                                        tooltipTitle={t("NewAdvertisements")}
                                    />

                                </>
                            }
                            childrenTwo={
                                <>
                                    <InputSwitch OnValueSelection={filterValueHandler} name={'IsActive'} lable={t("Active")} />
                                    <InputSwitch OnValueSelection={filterValueHandler} name={'IsDeActive'} lable={t("Inactive")} />
                                    <InputSwitch OnValueSelection={filterValueHandler} name={'isExpired'} lable={t("expired")} />
                                </>
                            }
                />

            </div>
                <div class="relative space-y-2 H-body-ag-grid">
                {

                    spinner?
                        <div className=" huploader_loading" >
                            <div className="relative">
                                <LoadingHarmony/>
                            </div>
                        </div>
                        :
                        null
                }
                <GridTable
                    columnDefs={columnDefs}
                    rowSelection={GridSetting.rowSelection}
                    rowData={rowData}
                    style={GridSetting.gridStyle}
                    defaultColDef={defaultColDef}
                    autoGroupColumnDef={autoGroupColumnDef}
                    pagination={GridSetting.pagination}
                    enableRtl={GridSetting.enableRtl}
                    domLayout={GridSetting.domLayout}
                    paginationPageSize={GridSetting.pageSize}
                    getRowHeight={getRowHeight}
                    localeText={AgGridPersion}
                />
                <Pagination length={rowData.length} hasPreviousPage={data.hasPreviousPage} hasNextPage={data.hasNextPage} locale={null} totalCount={data.totalCount} totalPages={data.totalPages} handleCurrentPage={handleCurrentPage} handleNextPage={handleNextPage} handlePreviousPage={handlePreviousPage} currentPage={currentPage} handlePageSizeCount={handlePageSizeCount}  />
            </div>
                <div className={"grid grid-cols-1 lg:grid-cols-12 bg-white dark:bg-themeDarkSalt700"}>
                    <div className={"lg:col-span-9"}>
                        <Export filters={filter} apiUrl={'/Advertisings/AdvertisingListExport'} />
                    </div>
                    <div className="lg:col-span-3 flex gap-1 items-center justify-center p-2">
                        <div className={"flex-1 w-full"}>
                            <Select
                                placeholder={t("selection")}
                                classNamePrefix={"h_multi_select"}
                                menuPosition={"fixed"}
                            />
                        </div>
                        <button type={"button"} className="button small_button bg-themeInformation text-white dark:bg-themeDarkInformation">
                            {t("sending")}
                        </button>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>

    )
}