import React, {useState} from "react";
import { useDispatch } from 'react-redux';


import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../Commans/UIParts/HPrimeIcon";
import {HPrimeIconSmall} from "../Commans/UIParts/HPrimeIconSmall";

//this is sidebar and handling other components inside of it .
export const MenuBar2=()=>{

    const dispatch = useDispatch();



    // for every menu , we have something separate like these states .
    // this one handles process of opening or closing menu and submenus .

    const [activeSubmenu, setActiveSubmenu] = useState("");
    const handleSubmenuClick = (submenuId) => {
        setActiveSubmenu(prevActiveSubmenu => prevActiveSubmenu === submenuId ? "" : submenuId);
    };


    const handleDispatchMenu =(text)=>{
        //dispatch(setSelectedOption(text));
    }
    const { t } = useTranslation();

    return (

        <nav className="side-nav">
            <Link to="https://cpanel.harmonycms.ir/DashBoard" className="intro-x flex justify-center items-center pt-4">
                <span className=" text-white block px-3">
                    <img src="http://cpanel.harmonycms.ir/ArmDark.svg" className="w-full h-full object-contain" alt="Harmony" title="Harmony"/>
                </span>
            </Link>
            <div className="side-nav__devider my-6"></div>
            <ul>
                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "Dashboard" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("Dashboard")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="tv" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex" >
                            {t("Dashboard")}
                            <HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "Dashboard"?'iconUp':'iconDown'}`}/> </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "Dashboard" ? "side-menu__sub-open side-menu--active" : ""}`} style={{ display: activeSubmenu === "Dashboard" ? "block" : "none" }}>
                        <li>
                            <Link to="/Dashboard" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="pie-chart" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("Dashboard")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/SuperAdmin/Arian2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("سوپر ادمین")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/Admin/Arian2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("ادمین آرین")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/CRM/Management2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("مدیریت اشخاص")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/Sales/Management2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("مدیریت فروش")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/Production/Management2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("مدیریت محصول")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/Commerce/Management2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("مدیریت فروشگاه")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/HRM/Management2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("مدیریت منابع انسانی")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/Support2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("پشتیبانی")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/SocialMedia2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("سوشال مارکتینگ")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Dashboard/Developer2" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("برنامه نویس")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "manager" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("manager")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="user" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex" >
                            {t("generalManager")}
                            <HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "manager"?'iconUp':'iconDown'}`}/> </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "manager" ? "side-menu__sub-open side-menu--active" : ""}`} style={{ display: activeSubmenu === "manager" ? "block" : "none" }}>

                        <li>
                            <Link to="#" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIconSmall icon="pie-chart" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("UserReports")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIconSmall icon="pie-chart" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("ServerStatusReport")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Admin/PaymentGateways/Add" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIconSmall icon="pie-chart" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("CreateBankGateway")}</div>
                            </Link>
                        </li>

                    </ul>
                </li>

                <li className="side-nav__devider "></li>
                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "HRM" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("HRM")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="user-check" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("HRM")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "report"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "HRM" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "HRM" ? "block" : "none" }}>
                        <li>
                            <Link to="/HRM/Person"   className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIconSmall icon="user" size={18} />
                                </div>
                                <div className="side-menu__title">{t("PersonManagement")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/Group" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="users" size={18} />
                                </div>
                                <div className="side-menu__title">{t("PersonGroup")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/Roles" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="star" size={18}  />
                                </div>
                                <div className="side-menu__title">{ t("Roles")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/List" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("PersonList")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/Reminder/List" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ReminderList")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/List/Log" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="activity" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("activities")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/CustomField" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="activity" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("communications")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/FinancialRules" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("FinancialRules")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/LeaveAndMission" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("LeaveAndMission")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/IndividualReport" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("IndividualReport")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/MonthlyReport" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("MonthlyReport")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/HRM/Person/Reports" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("GeneralReport")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>
                <li className="side-nav__devider "></li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "Attendance" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("Attendance")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="watch" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("AttendanceSystem")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "Attendance"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "Attendance" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "Attendance" ? "block" : "none" }}>
                        <li>
                            <Link to="/Attendance/Personnel"   className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="users" size={18} />
                                </div>
                                <div className="side-menu__title">{t("Personnel")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Attendance/Add" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="plus-square" size={18} />
                                </div>
                                <div className="side-menu__title">{t("RegisterDevice")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Attendance/FinancialRules" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{ t("FinancialRules")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Attendance/LeaveAndMission" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("LeaveAndMission")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Attendance/ShiftWork/List" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="layers" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ShiftWork")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Attendance/WorkGroup/List" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="users" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("WorkGroup")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Attendance/Archive" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="archive" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ArchiveLeaveAndMission")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Attendance/List/IndividualReport" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("IndividualReport")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Attendance/List/MonthlyReport" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("MonthlyReport")}</div>
                            </Link>
                        </li>

                        <li>
                            <Link to="/HRM/Person/Reports" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("GeneralReport")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "report" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("report")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="pie-chart" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("Reports")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "report"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "report" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "report" ? "block" : "none" }}>
                        <li>
                            <Link to="/CRM/Report/Reminder"  onClick={()=>handleDispatchMenu("صف یادآوری")} className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="clock" size={18} />
                                </div>
                                <div className="side-menu__title">{t("reminderQueue")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="calendar" size={18} />
                                </div>
                                <div className="side-menu__title">{t("SpecialDate")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="trello" size={18}  />
                                </div>
                                <div className="side-menu__title">{ t("ViewingTasks")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="#"  onClick={()=>handleDispatchMenu("مشاهده رویداد ها")} className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="eye" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ViewingEvents")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>

                <li>
                    <div  className={`side-menu cursor-pointer ${activeSubmenu === "MemberManagement" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("MemberManagement")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="file-text"/>
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("MemberManagement")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "MemberManagement"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={`side-menu__sub ${activeSubmenu === "MemberManagement" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "MemberManagement" ? "block" : "none" }}>

                        <li>
                            <Link to="/CMS/Member/Add"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="user" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("NewMember")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/CMS/Member/Add/List"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ListMembers")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/CMS/Member/Roles"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="star" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("Roles")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>

                <li>
                    <div  className={`side-menu cursor-pointer ${activeSubmenu === "Reservation" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("Reservation")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="file-text"/>
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("reservation")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "Reservation"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={`side-menu__sub ${activeSubmenu === "Reservation" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "Reservation" ? "block" : "none" }}>

                        <li>
                            <Link to="/Reservation/Center/Add"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("CenterCreation")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Reservation/Hotel/AddRoom"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="check-square" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("rackConstruction")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Reservation/Hotel/Reservation" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="calendar" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("hotelReservation")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Reservation/Center/List" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ListOfCenters")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Reservation/Hotel/ListReservation" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ListOfReservations")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Reservation/Hotel/ListRoom" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ListOfRooms")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>

                <li>
                    <div  className={`side-menu cursor-pointer ${activeSubmenu === "form" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("form")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="file-text"/>
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("Form")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "form"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={`side-menu__sub ${activeSubmenu === "form" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "form" ? "block" : "none" }}>

                        <li>
                            <Link to="/Form/DataSource"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("DataSource")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Form/Organize"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("OrganizeForms")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Form/DataSource/List"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ListSourcesInformation")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Form/List"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ListOfForms")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Form/Report/Builder"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ReportBuilder")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>

                <li>
                    <div  className={`side-menu cursor-pointer ${activeSubmenu === "Inventory" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("Inventory")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="package"/>
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("InventoryManagement")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "Inventory"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={`side-menu__sub ${activeSubmenu === "Inventory" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "Inventory" ? "block" : "none" }}>

                        <li>
                            <Link to="/Inventory/Type"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("InventoryTypes")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/TransferBetween"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="plus-square" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("TransferBetweenInventory")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/Add"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="plus-square" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("CreateInventory")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/ProductionSeries"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ProductionSeries")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/Goods/Add"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("CreateGoods")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/Goods/ReturnRequest"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ReturnPurchase")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/Goods/ExitRequest"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ExitRequest")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/EntryDocument"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("EntryDocument")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/ExitDocument"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ExitDocument")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Inventory/Report"  className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ReportBuilder")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>
                <li>
                    <Link to="/Discount2" className={`side-menu ${activeSubmenu === "Discount2" ? "side-menu--open side-menu--active side-menu--rounded" : ""}`} onClick={() => handleSubmenuClick("Discount2")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="settings" size={18} />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("کمپین تخفیف")}</div>
                    </Link>
                </li>
                <li>
                    <Link to="/Commission2" className={`side-menu ${activeSubmenu === "Commission2" ? "side-menu--open side-menu--active side-menu--rounded" : ""}`} onClick={() => handleSubmenuClick("Commission2")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="settings" size={18} />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("تعیین پورسانت")}</div>
                    </Link>
                </li>
                <li className="side-nav__devider "></li>

                <li>
                    <div  className={`side-menu cursor-pointer ${activeSubmenu === "Affilate" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("Affilate")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="users"  />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("SalesCollaboration")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "Affilate"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "Affilate" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "Affilate" ? "block" : "none" }}>
                        <li>
                            <Link to="#" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18}  />
                                </div>
                                <div className="side-menu__title">1</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>
                {/*حسابداری*/}

                <li>
                    <div  className={`side-menu cursor-pointer ${activeSubmenu === "accbasic" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("accbasic")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="database" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("basicInformation")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "accbasic"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "accbasic" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "accbasic" ? "block" : "none" }}>
                        <li>
                            <Link to="/ACC/Goods_Services/GoodsAdd" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ServiceRegistration")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Account/Add" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="credit-card" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("introductionOfBankAccounts")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Financial/Headline" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="bookmark" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("financialChapters")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Goods_Services/GoodsList" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ProductList")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Goods_Services/ServicesList" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ServiceList")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "accdocument" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("accdocument")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="file-text" size={22} />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{ t("DocumentManagement")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "accdocument"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "accdocument" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "accdocument" ? "block" : "none" }}>
                        <li>
                            <Link to="/ACC/Document/Add" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("DocumentRegistration")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Document/List" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("DocumentList")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "accinvoice" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("accinvoice")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="clipboard" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("invoice")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "accinvoice"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "accinvoice" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "accinvoice" ? "block" : "none" }}>
                        <li>
                            <Link to="/ACC/Invoice/Add" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("InvoiceRegistration")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Invoice/List" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("InvoiceList")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "accautomatic" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("accautomatic")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="pen-tool" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("AutomaticRegistration")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "accautomatic"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "accautomatic" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "accautomatic" ? "block" : "none" }}>
                        <li>
                            <Link to="/ACC/Document/AutomaticAdd" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("AutomaticRegistration")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/InterbankTransfer/Add" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("interbankTransfer")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "acc" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("acc")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="user" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("AccountingManagement")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "acc"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "acc" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "acc" ? "block" : "none" }}>
                        <li>
                            <Link to="/ACC/Cheque/Registered" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="clipboard" size={18} />
                                </div>
                                <div className="side-menu__title">{t("registeredChecks")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Cheque/Passing" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="check-circle" size={18} />
                                </div>
                                <div className="side-menu__title">{t("checkCollection")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Cheque/Reminder" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="calendar" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("checkReminders")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "accreport" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("accreport")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="pie-chart" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("FinancialReports")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "accreport"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "accreport" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "accreport" ? "block" : "none" }}>
                        <li>
                            <Link to="#" className="side-menu ">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="trending-up" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("FinancialReport")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="trending-down" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("AccountReceivableReport")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Report/InterbankTransactions/List" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="list" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("interbankTransactions")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="#" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="activity" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("financialBalance")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/ACC/Report/Other" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="activity" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("other")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                {/*حسابداری*/}

                <li className="side-nav__devider "></li>


                {/*مدیریت مکاتبات*/}
                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "commu" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("commu")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="file-text" />
                        </div>

                        <div className="side-menu__title side-menu_header_title flex">{ t("CorrespondenceManagement")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu ==="commu"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "commu" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "commu" ? "block" : "none" }}>
                        <li>
                            <Link to="/PaperLess/Compose" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="send" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("sendingALetter")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/PaperLess/Inbox" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="inbox" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("receivingBox")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/PaperLess/SendBox" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="package" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("sendingBox")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/PaperLess/Archive" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="archive" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("archive")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>
                {/*مدیریت مکاتبات*/}


                <li className="side-nav__devider "></li>

                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "SEO" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("SEO")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="award" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex" >
                            {t("SEO")}
                            <HPrimeIcon icon="chevron-up" className={`${activeSubmenu === "SEO"?'iconUp':'iconDown'}`}/> </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "SEO" ? "side-menu__sub-open side-menu--active" : ""}`} style={{ display: activeSubmenu === "SEO" ? "block" : "none" }}>
                        <li>
                            <Link to="/SEO/KeyWords" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="pie-chart" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("SelectedKeywords")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/SEO/ReviewKeyWords" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("ReviewWordsCompetitors")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/SEO/CreationMap" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("CreateContentCreationMap")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/SEO/Sitemap" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("Sitemap")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/SEO/CreationMap" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="tv" size={18}  />
                                </div>
                                <div className="side-menu__title"> {t("MonthlyReport")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>

                <li className="side-nav__devider "></li>

                {/* مدیریت پروژه*/}
                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "sys" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("sys")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="server" />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{ t("ProjectManagement")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu==="sys"?'iconUp':'iconDown'}`}/>
                        </div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "sys" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "sys" ? "block" : "none" }}>
                        <li>
                            <Link to="/Project/Add" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18}  />
                                </div>
                                <div className="side-menu__title">{ t("ProjectCreation")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/Project/ReportDetails" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="pie-chart" size={18}  />
                                </div>
                                <div className="side-menu__title">{ t("ProjectReports")}</div>
                            </Link>
                        </li>

                        <li>
                            <Link to="/Project/InstructionsProject" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="book-open" size={18}  />
                                </div>
                                <div className="side-menu__title">{t("ProcedureCreation")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>
                {/* مدیریت پروژه*/}

                <li className="side-nav__devider "></li>

                {/*مدیریت آموزشگاه*/}
                <li>
                    <div className={`side-menu cursor-pointer ${activeSubmenu === "ins" ? "side-menu--open side-menu--active" : ""}`} onClick={() => handleSubmenuClick("ins")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="briefcase" />
                        </div>

                        <div className="side-menu__title side-menu_header_title flex">{t("SchoolManagement")}<HPrimeIcon icon="chevron-up" className={`${activeSubmenu==="ins"?'iconUp':'iconDown'}`}/></div>
                    </div>
                    <ul className={` side-menu__sub ${activeSubmenu === "ins" ? "side-menu__sub-open" : ""}`} style={{ display: activeSubmenu === "ins" ? "block" : "none" }}>
                        <li>
                            <Link to="/LMS/Class/Add" className="side-menu">
                                <div className="side-menu__icon">
                                    <HPrimeIcon icon="edit" size={18} />
                                </div>
                                <div className="side-menu__title">{t("ClassCreation")}</div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/LMS/Class/List" className="side-menu">
                                <div className="side-menu__icon side-menu_header_icon">
                                    <HPrimeIcon icon="list" size={18} />
                                </div>
                                <div className="side-menu__title side-menu_header_title flex">{t("ListOfClasses")}</div>
                            </Link>
                        </li>
                    </ul>
                </li>
                {/*مدیریت آموزشگاه*/}

                <li className="side-nav__devider "></li>

                <li>
                    <Link to="/Support/OnlineSupport" className={`side-menu ${activeSubmenu === "OnlineSupport" ? "side-menu--open side-menu--active side-menu--rounded" : ""}`} onClick={() => handleSubmenuClick("OnlineSupport")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="message-circle" size={18} />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("onlineChat")}</div>
                    </Link>
                </li>

                <li className="side-nav__devider "></li>
                <li>
                    <Link to="/Harmony/Master/setting2" className={`side-menu ${activeSubmenu === "setting2" ? "side-menu--open side-menu--active side-menu--rounded" : ""}`} onClick={() => handleSubmenuClick("setting2")}>
                        <div className="side-menu__icon side-menu_header_icon">
                            <HPrimeIcon icon="settings" size={18} />
                        </div>
                        <div className="side-menu__title side-menu_header_title flex">{t("settings")}</div>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}