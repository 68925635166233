import React, {useState} from "react";
export const useSecretary=(setErrors)=>{
    const [sec,setSec] = useState({smsSecretaryId:null,pattern:null,message:null,accountId:null});
    const handleValue=(e)=>{
        const {name,value} = e.target;
        setSec({...sec,[name]:value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }
    const handleSelectValue=(data,name)=>{
        setSec({...sec,[name]:data});
    }
    return {setSec,sec,handleValue,handleSelectValue}

}