import React from "react";
import Select from "react-select";

import {useTranslation} from "react-i18next";
import {HPrimeIcon} from "../../../../Commans/UIParts/HPrimeIcon";

export const UserBank=({row,index,handleChange,handleRemoveRow,handleAddRow,initialSource})=>{
    const { t } = useTranslation();

    return (<>
        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 items-end gap-2 post-input p-2 post-input-design dark:bg-themeDarkPostInput"}>
            <div className={"flex gap-2"}>
                <div className={"mt-auto"}>
                    <div className="number_pluse bg-themeInformation text-white dark:bg-themeDarkInformation">
                        {index+1}
                    </div>
                </div>
                <div className={"flex-1 space-y-1"}>
                    <label className="text-xs dark:text-gray-300">{t("BankName")}</label>
                    <Select
                        options={initialSource.Banks}
                        isMulti={false}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(data) => handleChange(row.id, 'bankId',data,row.userBankId)}
                        placeholder={t("selection")}
                        value={row.bankId}
                        menuPosition={"fixed"}
                        classNamePrefix={"h_multi_select"}
                    />
                </div>
            </div>

            <div className={"space-y-1"}>
                <label className="text-xs dark:text-gray-300">{t("BankAccountNumber")}</label>
                <input
                    type="text"
                    className="input w-full"
                    value={row.accountNumber}
                    onChange={(e) => handleChange(row.id, 'accountNumber', e.target.value,row.userBankId)}
                />
            </div>
            <div className={"space-y-1"}>
                <label className="text-xs dark:text-gray-300">{t("cardNumber")}</label>
                <input
                    type="text"
                    className="input w-full"
                    value={row.cardNumber}
                    onChange={(e) => handleChange(row.id, 'cardNumber', e.target.value,row.userBankId)}
                />
            </div>
            <div className={"space-y-1"}>
                <label className="text-xs dark:text-gray-300">{t("BankID")}</label>
                <input
                    type="text"
                    className="input w-full"
                    value={row.shebaNumber}
                    onChange={(e) => handleChange(row.id, 'shebaNumber', e.target.value,row.userBankId)}
                />
            </div>
            <div className={"md:col-span-2 lg:col-span-1 flex gap-2 items-end justify-between"}>
                <div className={"space-y-1"}>
                    <lable className="text-xs dark:text-gray-300">{t("default")}</lable>
                    <div>
                        <input checked={row.isDefault} className="input input--switch " onChange={(e)=>handleChange(row.id,'isDefault',e.target.checked,row.userBankId)} type="checkbox"/>
                    </div>
                </div>
                <div className={"flex gap-2 items-center"}>
                    <button type="button" className="button small_button bg-themeInformation dark:bg-themeDarkInformation text-white " onClick={handleAddRow}>
                        <HPrimeIcon icon={'pi-plus'}  />
                    </button>
                    <button type="button" className="button small_button bg-themeDanger text-white " onClick={() => handleRemoveRow(row.id,row.userBankId)}>
                        <HPrimeIcon icon={'pi-minus'}  />
                    </button>
                </div>
            </div>
        </div>
    </>)
}