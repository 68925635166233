import React, {useEffect, useRef, useState} from "react";


import {useTranslation} from "react-i18next";
import {Tooltip} from "react-tooltip";
import {HPrimeIcon} from "./HPrimeIcon";



let tooltipCounter = 0; // Global counter for tooltips
export const  FilterFileUpload = ({ icon, onChange,key,classStyle, functionClass, title, accept, multiple, type, id,tooltipTitle }) => {
    const { t } = useTranslation();

    const fileInputRef = useRef(null);


    const [tooltipId] = useState(() => {
        tooltipCounter += 5;
        return `tooltip_${tooltipCounter}`;
    });

    const handleUploadFile = (e) => {
         onChange(e);

    };
    useEffect(() => {
        // Check if key is null (all items in the array are false), then reset the input
        if (key === null) {
            fileInputRef.current.value = '';
        }
    }, [key]);


    return (
        <div className="flex flex-col items-center gap-1">
            {console.log(key)}
            <label htmlFor={id} className={`button box zoom-in block ${tooltipTitle ? `tooltip_class_${tooltipId}`:'tooltip_upload'} rounded-full ${functionClass ? functionClass : "open-modal"} ${!classStyle ? 'bg-themeInformation text-white hover:bg-themeHoverInformation hover:text-white dark:bg-themeDarkInformation dark:text-gray-300' : classStyle}`}>
                <span className="grid size-7 place-content-center">
                    <HPrimeIcon icon={`${icon ? icon:"pi-upload"}`} />
                </span>
                <input
                    ref={fileInputRef}
                    multiple={multiple}
                    type={type}
                    id={id}
                    onChange={handleUploadFile}
                    accept={accept}
                    className="hidden"
                />
                <Tooltip anchorSelect={tooltipTitle ? `.tooltip_class_${tooltipId}` : ".tooltip_upload"} content={tooltipTitle ? tooltipTitle : t("New")} />
            </label>
            <h2 className="text-xs text-center dark:text-gray-300">{title ? title : t("Upload")}</h2>
        </div>
    );
};