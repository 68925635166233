import React, { useState } from "react";
import "../../Styles/dist/css/output.css";
import "../../Styles/dist/css/UserLogin.css";
import "../../Styles/dist/css/IR_app.css";
import "../../Styles/dist/css/En_app.css";
import { SignUp } from "./Signups/SignUp";
import { Verification } from "./Verifications/Verification";
import { PasswordRecovery } from "./ForgetPasswords/PasswordRecovery";
import { usePasswordRecovery } from "./ForgetPasswords/usePasswordRecovery";
import { OTPVerification } from "./ForgetPasswords/OTPVerification";
import { UserChangePassword } from "./ForgetPasswords/UserChangePassword";
import { ToastContainer } from "react-toastify";
import { UserAuthentication } from "./Authentications/UserAuthentication";
import {useTranslation} from "react-i18next";

export const HandleUserLogin = () => {
    const [stateName, setStateName] = useState('userAuthentication');
    const {t} = useTranslation();

    const handleChangeState = (stateName) => {
        setStateName(stateName);
    };

    const onSetErrors = (errors) => {
        setErrors(errors);
    };

    const [errors, setErrors] = useState({});
    const { handleSelectValue, handleValue, setAuthData, authData, handleNumberValue } = usePasswordRecovery(setErrors);

    return (
        <>
            <div className="flex h-screen justify-center bg_blur">
                <div className="user_login_width h-full">
                    <div className="flex h-full flex-wrap items-center justify-center">
                        <div className="w-full bg-white shadow-lg rounded-lg overflow-hidden grid grid-cols-1 md:grid-cols-2">
                            <div className="p-5 md:p-10">
                                <div className="space-y-10">
                                    <div className="space-y-1">
                                        <img className="mx-auto w-48" src="http://cpanel.harmonycms.ir/ArmLight.png" alt="logo" />
                                        <h2 className="text-xl text-center">ما تیم هارمونی هستیم</h2>
                                    </div>

                                    {stateName === 'userAuthentication' ? (
                                        <UserAuthentication handleChangeState={handleChangeState} />
                                    ) : stateName === 'signup' ? (
                                        <SignUp handleChangeState={handleChangeState} />
                                    ) : stateName === 'verification' ? (
                                        <Verification handleChangeState={handleChangeState} />
                                    ) : stateName === 'passwordRecovery' ? (
                                        <PasswordRecovery
                                            handleChangeState={handleChangeState}
                                            handleValue={handleValue}
                                            handleSelectValue={handleSelectValue}
                                            authData={authData}
                                            setAuthData={setAuthData}
                                            errors={errors}
                                            onSetErrors={onSetErrors}
                                        />
                                    ) : stateName === 'otpValidation' ? (
                                        <OTPVerification
                                            handleChangeState={handleChangeState}
                                            handleValue={handleValue}
                                            handleSelectValue={handleSelectValue}
                                            authData={authData}
                                            handleNumberValue={handleNumberValue}
                                            setAuthData={setAuthData}
                                            errors={errors}
                                            onSetErrors={onSetErrors}
                                        />
                                    ) : stateName === 'passwordChange' ? (
                                        <UserChangePassword
                                            authData={authData}
                                            handleChangeState={handleChangeState}
                                            onSetErrors={onSetErrors}
                                            handleValue={handleValue}
                                            errors={errors}
                                        />
                                    ) : null}

                                    <div className="flex items-center justify-between">
                                        {stateName === 'userAuthentication' ? (
                                            <button onClick={() => handleChangeState('signup')} type="button" className="text-themeInformation button text-white bg-themeInformation dark:bg-themeDarkInformation">
                                                {t("CreateAccount")}
                                            </button>
                                        ) : stateName === 'signup' ? (
                                            <button onClick={() => handleChangeState('userAuthentication')} type="button" className="text-themeInformation button text-white bg-themeInformation dark:bg-themeDarkInformation">
                                                {t("LoginTheUserPanel")}
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 md:p-10 user_login_gradiant flex items-center">
                                <div className="text-white space-y-5">
                                    <h2 className="text-xl">ما فراتر از یک شرکت هستیم</h2>
                                    <p className="leading-7">
                                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};
